import { Injectable } from '@angular/core';
import { IBase64Response, PrintService } from '@app/core/services/print.service';
import { Base64UrlService } from './base64url.service';
import { DataTaskService } from '@app/core/services/data-task.service';
import { decodeFromBase64DataUri, PageSizes, PDFAnnotation, PDFArray, PDFDict, PDFDocument, PDFFont, PDFName, PDFPage, PDFRef, PDFString, rgb, StandardFonts } from 'pdf-lib';

/**
 * Service for pdf-handling.
 */
@Injectable({
    providedIn: 'root',
})
export class PdfService {
    private A4_PAGE_WIDTH = PageSizes.A4[0];

    private A4_PAGE_HEIGHT = PageSizes.A4[1];

    constructor(private printService: PrintService, private dataTaskService: DataTaskService, private base64: Base64UrlService) {}

    public async preview(parameters: { reportKeyno: number, parameters: any }) {

        const p2ReportDef = (await this.loadP2ReportDef(parameters.reportKeyno))[0];
        const htmlReport = (await this.loadHtmlReportHtml(p2ReportDef.htmlreport_keyno, parameters.parameters))[0];

        htmlReport.filename = this.base64.urlDecode(htmlReport.filename);
        htmlReport.headerstring = this.base64.urlDecode(htmlReport.headerstring);
        htmlReport.htmlstring = this.base64.urlDecode(htmlReport.htmlstring);
        htmlReport.footerstring = this.base64.urlDecode(htmlReport.footerstring);

        const printParameters = {
            download: true,
            filename: htmlReport.filename,
            sourceHtml: htmlReport.htmlstring,
            printerProperties: {
                isLandscape: p2ReportDef.isLandscape,
                copies: 1,
                pageAutoSize: p2ReportDef.paper_size_id === 'standard',
                paperSize: {
                    height: p2ReportDef.height ?? 0,
                    width: p2ReportDef.width ?? 0
                }
            },
            pdfOptions: {
                marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                landscape: p2ReportDef.print_landscape == '1' ? true : false,
                outline: false,
                printBackground: true,
                displayHeaderFooter: true,
                headerTemplate: htmlReport.headerstring,
                footerTemplate: htmlReport.footerstring,
            }
        };
        if (p2ReportDef.paper_size_id !== 'standard' && p2ReportDef.height > 0 && p2ReportDef.width > 0) {
            //@ts-ignore
            printParameters.pdfOptions.height = `${p2ReportDef.height}mm`;
            //@ts-ignore
            printParameters.pdfOptions.width = `${p2ReportDef.width}mm`;

            printParameters.pdfOptions.marginOptions = { bottom: '5mm', top: '5mm', left: '5mm', right: '5mm' };
        }
        if (p2ReportDef.custom_margins == 1) {
            printParameters.pdfOptions.marginOptions = {
                top: `${p2ReportDef.margin_top}mm`,
                right: `${p2ReportDef.margin_right}mm`,
                bottom: `${p2ReportDef.margin_bottom}mm`,
                left: `${p2ReportDef.margin_left}mm`
            };
        }
        return URL.createObjectURL((await this.htmlToPdfBlob(htmlReport.htmlstring, printParameters)).binaryData);
    }

    public async getAttachment(parameters: { reportKeyno: number, parameters: any }) {

        const p2ReportDef = (await this.loadP2ReportDef(parameters.reportKeyno))[0];
        const htmlReport = (await this.loadHtmlReportHtml(p2ReportDef.htmlreport_keyno, parameters.parameters))[0];

        htmlReport.filename = this.base64.urlDecode(htmlReport.filename);
        htmlReport.headerstring = this.base64.urlDecode(htmlReport.headerstring);
        htmlReport.htmlstring = this.base64.urlDecode(htmlReport.htmlstring);
        htmlReport.footerstring = this.base64.urlDecode(htmlReport.footerstring);

        const printParameters = {
            download: true,
            filename: htmlReport.filename,
            saveToFileServer: true,
            sourceHtml: htmlReport.htmlstring,
            printerProperties: {
                isLandscape: p2ReportDef.isLandscape,
                copies: 1,
                pageAutoSize: p2ReportDef.paper_size_id === 'standard',
                paperSize: {
                    height: p2ReportDef.height ?? 0,
                    width: p2ReportDef.width ?? 0
                }
            },
            pdfOptions: {
                marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                landscape: p2ReportDef.print_landscape == '1' ? true : false,
                outline: false,
                printBackground: true,
                displayHeaderFooter: true,
                headerTemplate: htmlReport.headerstring,
                footerTemplate: htmlReport.footerstring,
            }
        };
        if (p2ReportDef.paper_size_id !== 'standard' && p2ReportDef.height > 0 && p2ReportDef.width > 0) {
            //@ts-ignore
            printParameters.pdfOptions.height = `${p2ReportDef.height}mm`;
            //@ts-ignore
            printParameters.pdfOptions.width = `${p2ReportDef.width}mm`;

            printParameters.pdfOptions.marginOptions = { bottom: '5mm', top: '5mm', left: '5mm', right: '5mm' };
        }
        if (p2ReportDef.custom_margins == 1) {
            printParameters.pdfOptions.marginOptions = {
                top: `${p2ReportDef.margin_top}mm`,
                right: `${p2ReportDef.margin_right}mm`,
                bottom: `${p2ReportDef.margin_bottom}mm`,
                left: `${p2ReportDef.margin_left}mm`
            };
        }
        return await this.htmlToPdfBlob(htmlReport.htmlstring, printParameters);
    }

    public async printPDF(parameters: {
        reportKeyno: number,
        printerKeyno: number,
        copies?: number,
        parameters: Record<string, any>
    }): Promise<Record<string, string | number>> {
        const p2ReportDef = (await this.loadP2ReportDef(parameters.reportKeyno))[0];
        if (p2ReportDef?.errorcode && p2ReportDef.errorcode != 0) {
            return p2ReportDef;
        }
        
        const htmlReport = (await this.loadHtmlReportHtml(p2ReportDef.htmlreport_keyno, parameters.parameters))[0];
        if (htmlReport?.errorcode && htmlReport.errorcode != 0) {
            return htmlReport;
        }
        htmlReport.filename = this.base64.urlDecode(htmlReport.filename);
        htmlReport.headerstring = this.base64.urlDecode(htmlReport.headerstring);
        htmlReport.htmlstring = this.base64.urlDecode(htmlReport.htmlstring);
        htmlReport.footerstring = this.base64.urlDecode(htmlReport.footerstring);

        const printParameters = {
            printerKeyno: parameters.printerKeyno,
            filename: htmlReport.filename,
            saveToFileServer: true,
            sourceHtml: htmlReport.htmlstring,
            printerProperties: {
                isLandscape: p2ReportDef.isLandscape,
                copies: parameters.copies ?? 1,
                pageAutoSize: true, //p2ReportDef.paper_size_id === 'standard',
                //paperSize: {
                //    height: p2ReportDef.height ?? 0,
                //    width: p2ReportDef.width ?? 0
                //}
            },
            pdfOptions: {
                marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                landscape: p2ReportDef.print_landscape == '1' ? true : false,
                outline: false,
                printBackground: true,
                displayHeaderFooter: true,
                headerTemplate: htmlReport.headerstring,
                footerTemplate: htmlReport.footerstring,
            }
        };
        if (p2ReportDef.paper_size_id !== 'standard' && p2ReportDef.height > 0 && p2ReportDef.width > 0) {
            //@ts-ignore
            printParameters.pdfOptions.height = `${p2ReportDef.height}mm`;
            //@ts-ignore
            printParameters.pdfOptions.width = `${p2ReportDef.width}mm`;

            printParameters.pdfOptions.marginOptions = { bottom: '5mm', top: '5mm', left: '5mm', right: '5mm' };
        }
        if (p2ReportDef.custom_margins == 1) {
            printParameters.pdfOptions.marginOptions = {
                top: `${p2ReportDef.margin_top}mm`,
                right: `${p2ReportDef.margin_right}mm`,
                bottom: `${p2ReportDef.margin_bottom}mm`,
                left: `${p2ReportDef.margin_left}mm`
            };
        }
        //console.dir(p2ReportDef);
        //console.dir(printParameters);


        await this.printService.HtmlToPdf(htmlReport.htmlstring, printParameters);
        return { errorcode: 0, errormessage: '' };
    }

    public async printPDFBook(parameters: {
        reportKeyno: number,
        printerKeyno: number,
        parameters: Record<string, any>[],
        saveToFileServer?: boolean
    }) {
        for (let parms of parameters.parameters) {
            await this.printPDF({
                printerKeyno: parameters.printerKeyno,
                reportKeyno: parameters.reportKeyno,
                parameters: parms
            });
        }
        //parameters.saveToFileServer = true;
        //const a = document.createElement('a');
        //const url = await (this.previewPDFBook(parameters));
        //a.href = url;
        //a.download = `htmlbook.pdf`;
        //a.click();
    }

    public async previewPDFBook(parameters: {
        reportKeyno: number,
        printerKeyno?: number,
        parameters: Record<string, any>[],
        saveToFileServer?: boolean
    }, progress?:any) {

        const p2ReportDef = (await this.loadP2ReportDef(parameters.reportKeyno))[0];

        
        const pdfsToMerge: ArrayBuffer[] = [];

        if (progress) {
            progress.show();
        }

        for (const parms of parameters.parameters) {

            const htmlReport = (await this.loadHtmlReportHtml(p2ReportDef.htmlreport_keyno, parms))[0];

            htmlReport.headerstring = this.base64.urlDecode(htmlReport.headerstring);
            htmlReport.htmlstring = this.base64.urlDecode(htmlReport.htmlstring);
            htmlReport.footerstring = this.base64.urlDecode(htmlReport.footerstring);

            const printParameters = {
                download: true,
                filename: htmlReport.filename,
                sourceHtml: htmlReport.htmlstring,
                saveToFileServer: true,
                printerProperties: {
                    isLandscape: p2ReportDef.isLandscape,
                    copies: 1,
                    pageAutoSize: p2ReportDef.paper_size_id === 'standard',
                    paperSize: {
                        height: p2ReportDef.height ?? 0,
                        width: p2ReportDef.width ?? 0
                    }
                },
                pdfOptions: {
                    marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                    landscape: p2ReportDef.print_landscape == '1' ? true : false,
                    outline: false,
                    printBackground: true,
                    displayHeaderFooter: true,
                    headerTemplate: htmlReport.headerstring,
                    footerTemplate: htmlReport.footerstring,
                }
            };

            if (p2ReportDef.paper_size_id !== 'standard' && p2ReportDef.height > 0 && p2ReportDef.width > 0) {
                //@ts-ignore
                printParameters.pdfOptions.height = `${p2ReportDef.height}mm`;
                //@ts-ignore
                printParameters.pdfOptions.width = `${p2ReportDef.width}mm`;

                printParameters.pdfOptions.marginOptions = { bottom: '5mm', top: '5mm', left: '5mm', right: '5mm' };
            }
            if (p2ReportDef.custom_margins == 1) {
                printParameters.pdfOptions.marginOptions = {
                    top: `${p2ReportDef.margin_top}mm`,
                    right: `${p2ReportDef.margin_right}mm`,
                    bottom: `${p2ReportDef.margin_bottom}mm`,
                    left: `${p2ReportDef.margin_left}mm`
                };
            }

            pdfsToMerge.push((await (await this.htmlToPdfBlob(htmlReport.htmlstring, printParameters)).binaryData.arrayBuffer()));

            if (progress) {
                if (progress?.instance?.closed?.$$state?.status && progress.instance.closed.$$state.status === 1) {
                    return '';
                } else {
                    progress.step();
                }
            }
        }
        
        const mergedPdf = await PDFDocument.create();
        for (const pdfBytes of pdfsToMerge) {
            
            const pdf = await PDFDocument.load(pdfBytes);
            const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => {
                mergedPdf.addPage(page);
            });
            
            if (progress) {
                if (progress?.instance?.closed?.$$state?.status && progress.instance.closed.$$state.status === 1) {
                    return '';
                } else {
                    progress.step();
                }
            }
        }
        
        const buf = await mergedPdf.saveAsBase64({ dataUri: true });
        const response = await fetch(buf);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        if (progress) {
            progress.hide();
        }
        return url;
    }

    public async loadP2ReportDef(p2_reportdef_keyno:number) {
        return await this.dataTaskService.Post(
            334,
            {
                p2_reportdef_keyno: p2_reportdef_keyno
            }
        );
    }

    public async loadHtmlReportHtml(htmlreport_keyno: number, parameters: Record<string,any>) {
        return this.dataTaskService.Post(
            3370,
            {
                htmlreport_keyno: htmlreport_keyno,
                jsonwhere: JSON.stringify(parameters)
            }
        );
    }


    /**
     * Converts the given html string to pdf.
     *
     * @param htmlString the html-string to convert to a pdf.
     * @returns a promise containg the base64 encoded of the pdf.
     */
    //@ts-ignore
    public convertHTMLToPDF(htmlString: string): Promise<string> {
        return this.printService.HtmlToPdfBase64(htmlString);
    }

    public htmlToPdf(
        htmlString: string,
        params: {
            sourceHtml?: string,
            sourceUrl?: string,
            filename?: string,
            saveToFileServer?: boolean,
            download?: boolean,
            getBase64Data?: boolean,
            urlEncodeBase64Data?: boolean,
            pdfOptions: Object
        }
    ): Promise<string> {
        return this.printService.HtmlToPdf(htmlString,params);
    }

    public htmlToPdfBlob(
        htmlString: string,
        params: {
            sourceHtml?: string,
            sourceUrl?: string,
            filename?: string,
            saveToFileServer?: boolean,
            download?: boolean,
            getBase64Data?: boolean,
            urlEncodeBase64Data?: boolean,
            pdfOptions: Object
        }
    ): Promise<IBase64Response> {
        return this.printService.HtmlToPdfBlob(htmlString, params);
    }

    private getTableOfContentsPageCount(annotations: number) {
        const pageHeight = 841.89;
        const firstPageTopMargin = 127;
        const subsequentPageTopMargin = 72;
        const bottomMargin = 72;
        const lineHeight = 20;

        const firstPageAvailableHeight = this.A4_PAGE_HEIGHT - firstPageTopMargin - bottomMargin;
        const linesOnFirstPage = Math.floor(firstPageAvailableHeight / lineHeight);
        const subsequentPageAvailableHeight = pageHeight - subsequentPageTopMargin - bottomMargin;
        const linesPerSubsequentPage = Math.floor(subsequentPageAvailableHeight / lineHeight);

        if (annotations <= linesOnFirstPage) {
            return 1;
        }

        const remainingLines = annotations - linesOnFirstPage;

        return 1 + Math.ceil(remainingLines / linesPerSubsequentPage);
    }

    /**
     * Merges the provided list of pdfs and/or images (only .jpeg, .jpg or .png.) to a single pdf document. The content can be provided with a base64 encoded string,
     * uint8array or array-buffer only.
     *
     * @param mergeDocuments list of pdf and/or images to merge together to a single pdf.
     * @returns a promise containing the base64 encoded string of the merged document.
     */
    public async mergePDFs(mergeDocuments: MergeDocument[]): Promise<string> {
        const document = await PDFDocument.create();

        for (let base64Pdf of mergeDocuments) {
            if (base64Pdf.contentType === 'image/jpg' || base64Pdf.contentType === 'image/jpeg' || base64Pdf.contentType === 'image/png') {
                const imagePdf = await this.createPDFFromImage(base64Pdf.content, base64Pdf.contentType);
                await this.addPDFToDocument(imagePdf, document, base64Pdf);
            } else if (base64Pdf.contentType === 'application/pdf') {
                await this.addPDFToDocument(base64Pdf.content, document, base64Pdf);
            }
        }

            await this.generateTableOfContents(document, mergeDocuments);
        
        return await document.saveAsBase64({ dataUri: true });
    }

    /**
     * Adds the provided pdf content to the provided pdf document.
     *
     * @param pdfContent the encoded pdf content to add to the pdf document. Will be added at the end of the document.
     * @param document the document to add the pdf content to.
     */
    private async addPDFToDocument(pdfContent: string | Uint8Array | ArrayBuffer, document: PDFDocument, mergeDocument: MergeDocument) {
        const pdf = await PDFDocument.load(pdfContent);
        mergeDocument.index = document.getPageCount();

        const copiedPages = pdf.getPageIndices().map(async (pageIndex, index) => {
            const pages = await document.copyPages(pdf, [pageIndex]);
            const [page] = pages;

            if (index === 0) {
                mergeDocument.pageRef = document.addPage(page);
            }
        });

        await Promise.all(copiedPages);
    }

    /**
     * Creates a pdf document from the provided base64 image of the given content-type. Returns a pdf document encoded in Uint8Array
     *
     * @param encodedImage the encoded image to create pdf from, can be either base64 encoded string, array-buffer or a uint8array.
     * @param contentType the content type of the image, needs to be either jpg, jpeg or png.
     * @returns a promise containing uint8array of the pdf document with the image.
     */
    private async createPDFFromImage(encodedImage: string | Uint8Array | ArrayBuffer, contentType: string): Promise<Uint8Array> {
        if (contentType === 'image/jpg' || contentType === 'image/jpeg' || contentType === 'image/png') {
            const document = await PDFDocument.create();
            let image;

            if (contentType === 'image/jpeg' || contentType === 'image/jpg') {
                image = await document.embedJpg(encodedImage);
            } else if (contentType === 'image/png') {
                image = await document.embedPng(encodedImage);
            }

            if (image) {
                const widthA4 = PageSizes.A4[0];
                const heightA4 = PageSizes.A4[1];
                const isLandscape = image.width > image.height;

                let imageWidth = image.width;
                let imageHeight = image.height;

                if (isLandscape) {
                    imageWidth = heightA4;
                    imageHeight = Math.round((image.height / image.width) * imageWidth);
                } else {
                    imageHeight = widthA4;
                    imageWidth = Math.round((image.height / image.width) * imageHeight);
                }

                const page = document.addPage([imageWidth, imageHeight]);
                page.drawImage(image, {
                    x: 0,
                    y: 0,
                    width: imageWidth,
                    height: imageHeight,
                });

                // const page = document.addPage([image.width, image.height]);
                // page.drawImage(image, {
                //     x: 0,
                //     y: 0,
                //     width: image.width,
                //     height: image.height,
                // });
            }

            return await document.save();
        }

        throw Error('Unsupported content type. Only .jpeg, .jpg and .png are supported image formats.');
    }

    /**
     * Generates a table of contents for the given pdf-document, indexing the given merge-documents.
     *
     * @param document the pdf-document to create a table of contents for.
     * @param mergeDocuments the merged documents to index in the table of contents.
     * @param indexHeading the heading to use for the first page of the table of contents page(s).
     */
    private async generateTableOfContents(document: PDFDocument, mergeDocuments: MergeDocument[], indexHeading: string = 'Table of Contents') {
        let pages = [document.insertPage(1, [this.A4_PAGE_WIDTH, this.A4_PAGE_HEIGHT])];
        pages[0].drawText(indexHeading, {
            x: 56,
            y: this.A4_PAGE_HEIGHT - 72,
            size: 18,
            color: rgb(0, 0, 0),
        });

        let top = this.A4_PAGE_HEIGHT - 127;
        let currentPageIndex = 0;

        for (let mergeDocument of mergeDocuments) {
            if (mergeDocument.pagetitle === 'Frontpage') {
                continue;
            }

            const linkAnnotationRef = await this.createAnnotations({
                document: document,
                page: pages[currentPageIndex],
                linkText: mergeDocument.pagetitle,
                pageNumber: mergeDocument.index + 1 + this.getTableOfContentsPageCount(mergeDocuments.length),
                top: top,
                level: mergeDocument.level,
                ref: mergeDocument.pageRef,
            });

            top -= 20;

            const annots = pages[currentPageIndex].node.lookup(PDFName.of('Annots'), PDFArray);
            annots.push(linkAnnotationRef);

            if (top < 72) {
                pages.push(document.insertPage(currentPageIndex + 2, [this.A4_PAGE_WIDTH, this.A4_PAGE_HEIGHT]));
                currentPageIndex++;
                top = this.A4_PAGE_HEIGHT - 72;
            }
        }
    }

    /**
     * Creates an annotation for the given document, on the given page. The annotation will be displayed using the given linktext and page-number. The given top
     * decides the y-coordinate on the page the annotation should be places (in PDF's 1 is bottom and the page height is the top). The annotation will be styled according to the provided
     * level. The annotation creates a destination link to the ref which is a pdf-page.
     *
     * @param param0 the annotation configuration to use for creating an annotation.
     * @returns a promise containing a pdf-reference to the created annotation.
     */
    private async createAnnotations({ document, page, linkText, pageNumber, top, level, ref }: CreateAnnotationsParameters): Promise<PDFRef> {
        const size = 10;
        let left = 72;
        let font = await document.embedFont(StandardFonts.HelveticaBold);

        if (Number(level) === 1) {
            left = 72;
        } else if (Number(level) === 2) {
            left = 72 + 12;
            font = await document.embedFont(StandardFonts.Helvetica);
        } else if (Number(level) === 3) {
            left = 72 + 24;
            font = await document.embedFont(StandardFonts.HelveticaOblique);
        }

        const right = this.A4_PAGE_WIDTH;
        const bottom = top + size;

        const linkAnnotation = document.context.obj({
            Type: 'Annot',
            Subtype: 'Link',
            Rect: [left, top, right, bottom],
            Border: [0, 0, 0],
            C: [0, 0, 0],
            Dest: [ref.ref, 'XYZ', null, this.A4_PAGE_HEIGHT, null],
            // Dest: [document.getPage(index).ref, 'XYZ', null, 1, null],
        });

        page.drawText(linkText, {
            x: left,
            y: top,
            size: size,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${pageNumber}`, {
            x: right - 72,
            y: top,
            size: size,
            font: font,
            color: rgb(0, 0, 0),
        });

        page.drawLine({
            start: { x: left, y: top - 2 },
            end: { x: right - 56, y: top - 3 },
            thickness: 1,
            color: rgb(0, 0, 1),
            opacity: 0.75,
        });

        return document.context.register(linkAnnotation);
    }
}

/**
 * Represents an object with properties required for merging.
 */
interface MergeDocument {
    /**
     * The base64 encoded content, or uint8array or arraybuffer, containing the pdf, jpeg og png.
     */
    content: string | Uint8Array | ArrayBuffer;

    /**
     * The content-type of the content, etiher pdf, jpeg or png.
     */
    contentType: 'image/jpg' | 'image/jpeg' | 'image/png' | 'application/pdf';

    /**
     * The page title of the document.
     */
    pagetitle: string;

    /**
     * The index where this document was added to the pdf-document. Does not include table of contents.
     */
    index: number;

    /**
     * The annotation level to use for the page title in the table of contents.
     */
    level: AnnotationLevel;

    /**
     * Reference to the page added to the pdf document.
     */
    pageRef: PDFPage;
}

/**
 * Represents paramaeters required for creating an annotation.
 */
interface CreateAnnotationsParameters {
    /**
     * The pdf document to add the annotation on.
     */
    document: PDFDocument;

    /**
     * The page to add the annotation to.
     */
    page: PDFPage;

    /**
     * The link text to display for the annotation.
     */
    linkText: string;

    /**
     * The actual page number (not zero-based and including table of contents) for the destination of the annotation. Displayed right side of the link text.
     */
    pageNumber: number;

    /**
     * The y-position to use for placing the annotation.
     */
    top: number;

    /**
     * The annotation level to use for the page title in the table of contents.
     */
    level: AnnotationLevel;

    /**
     * Reference to the page to navigate to.
     */
    ref: PDFPage;
}

/**
 * The level of the document (level 1, level 2 or level 3), relates to how the document is formatted in the table of contents (think heading 1, heading 2 etc. in a regular word document).
 */
type AnnotationLevel = '1' | '2' | '3';
