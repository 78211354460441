(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productUnit', {
        templateUrl: 'views/components/views/productUnit/productUnit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productUnitService', function ($stateParams, stateService, productUnitService) {
            let vm = this;
            let prodId = $stateParams.prod_id;
            let productUnitKeyno = $stateParams.productunit_keyno;
            
            vm.model = {
                edit: {},
                selectListNewProductUnits: []
            };
            
            let loadEdit = function () {
                productUnitService.getProductUnit({
                    prod_id: prodId,
                    productunit_keyno: productUnitKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            let loadNewProductUnits = function () {
                productUnitService.listNewProductUnits({ prod_id: prodId }).then(function (result) {
                    angular.copy(result, vm.model.selectListNewProductUnits);
                });
            };
            
            vm.saveChanges = function () {
                productUnitService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
            
            vm.deleteValues = function () {
                productUnitService.deleteItem(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadEdit();
                loadNewProductUnits();
            };
        }]
    });
})();
