(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("srReportMenuService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
		var service = {
			loadSrReportMenuGet: function (checklistdata_keyno) {
				return p2DataTaskService.call(1315, {
					checklistdata_keyno: checklistdata_keyno
				});
			},
			loadSrReportMenuList: function (checklistdata_keyno) {
				return p2DataTaskService.call(1630, {
                    checklistdata_keyno: checklistdata_keyno
                });
			},
			listResponsibleLabours: function (parms) {
				return $ihttp.post({
					method: 2144,
					parameters: parms || {}
				});
			},
            createNewResponsibleLabour: function (parms) {
                return $ihttp.post({
					method: 2145,
                    parameters: parms
                });
            },
            changeStatus: function (parms) {
                return $ihttp.post({
					method: 2108,
                    parameters: parms
                });
            }
		};

		return service;
	}]);
})();