(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srOperationAddr', {
        templateUrl: 'views/components/views/srOperationAddr/srOperationAddr.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'srOperationAddrService', function($stateParams, stateService, utilityService, modalService, srOperationAddrService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                custactNo: $stateParams.custact_no,
                address: {},
                selectListResources: [],
                selectListChecks: [],
                lockedSave: false
            };
    
            // ## IF CONDITIONS FUNCTIONS ##
    
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.address[parmWithValue]) === true) return false;
                if (vm.model.address[parmWithValue] === null) return false;
                if (vm.model.address[parmWithValue].trim().length < 1) return false;
    
                return true;
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srOperationAddrService.loadSrOperationAddrGet(vm.model.custactNo).then(function (data) {
                vm.model.address = angular.copy(data[0]);
    
                loadSrOperationAddrResources();
                loadChecks();
            });
    
            var loadSrOperationAddrResources = function () {
                if (validateParmsWithValue('custact_no') !== true) return;
    
                vm.model.selectListResources = [];
    
                srOperationAddrService.loadSrOperationAddrResourceList(vm.model.address.custact_no).then(function (data) {
                    angular.copy(data, vm.model.selectListResources);
                });
            };
    
            var loadChecks = function () {
                //if (utilityService.validateParmsValue(vm.model.address.resource_id) !== true) return;
                //if (utilityService.validateParmsValue(vm.model.address.note, true) !== true) return;
    
                vm.model.selectListChecks = [];
    
                srOperationAddrService.listChecks(vm.model.address).then(function (data) {
                    angular.copy(data, vm.model.selectListChecks);
                });
            };
    
            // ## BUTTON SAVE PROCEDURE FUNCTION CALL ##
    
            vm.saveSrOperationAddr = function () {
                vm.model.lockedSave = true;
                
                srOperationAddrService.saveObj(vm.model.address).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();