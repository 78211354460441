(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('emailEdit', {
        templateUrl: 'views/components/views/emailEdit/emailEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'emailEditService', 'emailInboxService', function($stateParams, emailEditService, emailInboxService) {
            var vm = this;

            vm.message = {};
            vm.messageHtml= '';
            vm.messageIds = [];
            vm.nextId = 0;
            vm.prevId = 0;
    
            vm.options = {
                item_id: 0,
                item_name: '',
                item_default: '0',
                item_selected: '1',
                records: [
                    { item_id: '1', item_name: 'email_edit_read', item_func: '1' },
                    { item_id: '2', item_name: 'email_edit_archive', item_func: '2' },
                    { item_id: '3', item_name: 'email_edit_action', item_func: '3' }
                ]
            };
            
            vm.loadMail = function (mail_id) {
                emailEditService.loadVvMessage(mail_id).then(function () {
                    vm.message = emailEditService.vvMessage;
                    //vm.messageHtml = $sce.trustAsHtml(vm.message.msgHtml);

                    var msgs = emailInboxService.vvMessage.records;

                    vm.nextId = 0;
                    vm.prevId = 0;

                    var noprev = false;
                    var nonext = true;

                    angular.forEach(msgs, function (item) {
                        if (!nonext) {
                            vm.nextId = item.msgUid;
                            nonext = true
                        }

                        if (item.msgUid == mail_id) {
                            noprev = true;
                            nonext = false;
                        }

                        if (!noprev) {
                            vm.prevId = item.msgUid;
                        }
                    });

                    $('#body').html(vm.message.msgHtml);
                });
            };
            
            vm.loadMail($stateParams.message_id);
            
            vm.selectStatus = function (selected_id) {
                vm.options.item_selected = selected_id;
            };
        }]
    });
})();    