import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WidgetService } from '../widget.service';
import { WidgetNumberVariable, WidgetVariable } from '../jlr-test.component';

@Component({
    selector: 'tt-widget-text',
    templateUrl: './widget-text.component.html',
    styleUrls: ['./widget-text.component.css'],
})
export class WidgetTextComponent implements OnChanges {
    @Input() ttModel?: string | WidgetTextLine[] = 'Test av [[variables]] i tekststreng';
    @Input() ttVariables?: { [key: string]: WidgetVariable | WidgetNumberVariable } | (() => { [key: string]: WidgetVariable | WidgetNumberVariable });
    viewValue?: string;
    constructor(public widgetService: WidgetService) {}

    private formatVariable(variable: WidgetVariable | WidgetNumberVariable) {
        let viewValue: string | number = variable.value;

        if (this.widgetService.isWidgetNumberVariable(variable)) {
            let append = '';
            let divisor = 1;

            if (variable.displayUnits !== null) {
                switch (variable.displayUnits) {
                    case 'thousands':
                        divisor = 1e3;
                        append = ' THOUSANDS';
                        break;
                    case 'millions':
                        divisor = 1e6;
                        append = ' MILL';
                        break;
                    case 'billions':
                        divisor = 1e9;
                        append = ' BILL';
                        break;
                    case 'trillions':
                        divisor = 1e12;
                        append = ' TRILL';
                        break;
                    default:
                        append = '';
                        divisor = 1;
                        break;
                }

                if (isNaN(Number(viewValue.toString())) === false) {
                    viewValue = Number(viewValue) / divisor;
                }
            }

            if (isNaN(Number(viewValue.toString())) === false && variable.format === 'thousand-separator') {
                let locale = navigator.languages?.[0];
                const decimals = variable?.decimals !== undefined && variable?.decimals >= 0 ? variable.decimals : 2;

                const formatter = Intl.NumberFormat(locale || 'nb', {
                    minimumFractionDigits: decimals,
                    maximumFractionDigits: decimals,
                });

                return formatter.format(Number(viewValue.toString())) + append;
            }
        }
        return variable.value || '';
    }

    private interpolateString(template: string, variables: { [key: string]: WidgetVariable | WidgetNumberVariable }): string {
        return template.replace(/\[\[(\w+)\]\]/g, (match, key) => this.formatVariable(variables[key]).toString() || match);
    }

    private setViewValues(ttModel: string | WidgetTextLine[], ttVariables: { [key: string]: WidgetVariable | WidgetNumberVariable } | (() => { [key: string]: WidgetVariable | WidgetNumberVariable })) {
        let variables = {};

        if (ttVariables instanceof Function) {
            variables = ttVariables();
        } else {
            variables = ttVariables;
        }

        console.log('variables :>> ', variables);

        if (typeof ttModel === 'string') {
            this.viewValue = this.interpolateString(ttModel, variables);
        } else if (this.widgetService.isWidgetTextLineArray(this.ttModel)) {
            this.viewValue = undefined;
            this.ttModel.forEach((textLine) => {
                textLine.viewValue = this.interpolateString(textLine.value, variables);
            });
        }
    }
    // private setViewValues(ttModel: string | WidgetTextLine[], ttVariables: { [key: string]: string } | (() => { [key: string]: string })) {
    //     let variables = {};

    //     if (ttVariables instanceof Function) {
    //         variables = ttVariables();
    //     } else {
    //         variables = ttVariables;
    //     }
    //     if (typeof ttModel === 'string') {
    //         this.viewValue = this.interpolateString(ttModel, variables);
    //     } else if (this.widgetService.isWidgetTextLineArray(this.ttModel)) {
    //         this.viewValue = undefined;
    //         this.ttModel.forEach((textLine) => {
    //             textLine.viewValue = this.interpolateString(textLine.value, variables);
    //         });
    //     }
    // }
    ngOnChanges(changes: SimpleChanges): void {
        console.log('text widget changes');
        console.dir(changes);

        if (changes?.['ttVariables']?.currentValue && changes?.['ttVariables']?.currentValue !== changes?.['ttVariables']?.previousValue && this.ttModel) {
            this.setViewValues(this.ttModel, changes?.['ttVariables']?.currentValue);
        }

        if (changes?.['ttModel']?.currentValue && changes?.['ttModel']?.currentValue !== changes?.['ttModel']?.previousValue && this.ttVariables) {
            this.setViewValues(changes?.['ttModel']?.currentValue, this.ttVariables);
        }
    }
}

export interface WidgetTextConfig {
    model: string | WidgetTextLine[];
    variables: { [key: string]: WidgetVariable | WidgetNumberVariable } | (() => { [key: string]: WidgetVariable | WidgetNumberVariable });
}

export interface WidgetTextLine {
    /** The parsed string from the value, after all variables have been replaced. */
    viewValue?: string;

    /** The string value to display, string interpolation are applied to variables surrounded with [[ ]] */
    value: string;

    /** Custom font-size of the text line. */
    fontSize?: string;

    /** Custom font-weight of the text line. */
    fontWeight?: FontWeight;

    /** Custom text-alignment of the text line. */
    textAlign?: 'left' | 'center' | 'right';
    alignSelf?: 'start' | 'center' | 'end';
    orderby?: number;
}

export type FontWeight = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | 'normal' | 'bold';
