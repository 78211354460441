(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("usergroupService", ['$ihttp', '$q', function ($ihttp, $q) {
        var listCache = {
            state: '',
            deferred: [],
            data: []
        };

        var service = {
            init: function () {
                // only run init once
                if (listCache.state !== '')
                    return;

                listCache.state = 'initializing';

                service.list(true).then(function (data) {
                    angular.copy(data, listCache.data);

                    var defers = listCache.deferred.splice(0);

                    listCache.state = 'ready';

                    angular.foreach(defers, function (def) {
                        if (angular.isFunction(def)) {
                            def.resolve(listCache.data);
                        }
                    });
                });
            },

            list: function (force) {
                force = force || false;

                var deferred = $q.defer();

                if (listCache.state !== 'ready' && force !== true) {
                    listCache.deferred.push(deferred);
                } else {
                    if (force === true) {
                        $ihttp.post({ method: 1896 }).then(function (data) {
                            angular.copy(data, listCache.data);

                            deferred.resolve(data);
                        });
                    } else {
                        if (listCache.state !== 'ready') {
                            listCache.deferred.push(deferred);
                        } else {
                            deferred.resolve(listCache.data);
                        }
                    }
                }

                return deferred.promise
            },

            toggleFieldInfoUsergroup: function (ttItemId, ttLayoutFieldinfoKeyno, usergroupKeyno) {
                return $ihttp.post({
                    method: 1897,
                    parameters: {
                        tt_item_id: ttItemId,
                        p2_tt_layout_fieldinfo_keyno: ttLayoutFieldinfoKeyno,
                        usergroup_keyno: usergroupKeyno
                    }
                });
            }
        };

        return service;
    }]);
})();