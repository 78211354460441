<div class="well well-sm col-xs-12" [ngClass]="ttClass">
    <div *ngIf="ttHeading !== null && ttHeading !== undefined" class="col-xs-12 sp-0">
        <div class="sp-5">
            <h3 style="display: flex; gap: 0.8rem; margin-top: 5px; margin-bottom: 5px">
                <span *ngIf="ttIcon !== null && ttIcon !== undefined" class="fa-fw" [ngClass]="ttIcon"></span>
                {{ ttHeading }}
            </h3>
        </div>
        <div class="k-hr col-xs-12 sp-0"></div>
    </div>
    <ng-content></ng-content>
</div>
