(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('component', {
        templateUrl: 'views/components/views/component/component.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'utilityService', 'modalService', 'translateService', 'componentService', function ($stateParams, $timeout, stateService, utilityService, modalService, translateService, componentService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            let vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                is_changed: false,
                change_enabled: false,
                parm2: $stateParams.comp_keyno,
                productSearchButtons: [
                    //{ id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.onClearProductSearch() },
                    //{ id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onSearch: () => vm.typeaheadSearch(value) },
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => stateService.go('productcreate', { argtype: 'prod_keyno', argvalue: vm.model.edit.prod_keyno }) }
                ],
                compIsProdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.comp_is_prod_id }) }
                ], 
                edit: {},
                p2WComponentProdChanged: {},
                p2ProductUnitListSelect: {
                    records: []
                },
                lockedSave: false
            };

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadP2ComponentGet = function () {
                componentService.genericFunction(707, {
                    prod_id: $stateParams.prod_id,
                    comp_keyno: $stateParams.comp_keyno
                }).then(function (data) {
                    vm.model.edit = angular.copy(data[0]);

                    loadP2ProductUnitListSelect();
                    
                    if (vm.model.edit.prod_id_new > '') {
                        prodChanged();
                    }

                    $timeout(function () {
                        vm.model.change_enabled = true;
                    }, 100);
                });
            };

            let loadP2ProductUnitListSelect = function () {
                if (vm.model.edit.comp_is_prod_id !== '' && angular.isDefined(vm.model.edit.comp_is_prod_id)) {
                    componentService.genericFunction(599, {
                        prod_id: vm.model.edit.comp_is_prod_id
                    }).then(function (data) {
                        angular.copy(data, vm.model.p2ProductUnitListSelect.records);
                    });
                }
            };

            // ########################################
            // SEARCH & UPDATE FUNCTION PROCEDURE CALLS
            // ########################################

            vm.typeaheadSearch = function () {
                return componentService.genericFunctionSearch(78, {
                    prod_name: vm.model.edit.product_search,
                    webpage_name: stateService.getCurrentName(),
                    parm1: $stateParams.prod_id,
                    parm2: $stateParams.comp_keyno,
                    supplier_no: '0'
                });
            };

            let prodChanged = function () {
                if (utilityService.validateParmsValue(vm.model.edit.comp_is_prod_id) !== true) return;

                componentService.genericFunction(708, {
                    comp_is_prod_id: vm.model.edit.comp_is_prod_id,
                    prod_id: $stateParams.prod_id
                }).then(function (response) {
                    vm.model.is_changed = true;

                    vm.model.edit.comp_is_prod_id = response[0].comp_is_prod_id;
                    vm.model.edit.comp_prod_name = response[0].comp_prod_name;
                    vm.model.edit.stock_unit_no = response[0].stock_unit_no;
                    vm.model.edit.comp_waste_pst = response[0].waste_pst;

                    $timeout(function () {
                        vm.model.change_enabled = true;
                    }, 100);

                    loadP2ProductUnitListSelect();
                });
            };

            let loadCalculation = function (id) {
                switch (id) {
                    case 'special_length':
                    case 'special_width':
                    case 'special_quantity':
                    case 'special_quantity_formula':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        let mergeObj = vm.model.edit;
                        mergeObj.changedcol = id;

                        componentService.calcObj(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.edit.quantity = response[0].quantity;
                            }
                        });
                        break;
                    default:
                        break;
                };
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state) {
                let go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'compvalidationset':
                        go({ comp_keyno: vm.model.edit.comp_keyno });
                        break;
                }
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                componentService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            vm.deleteValues = function () {
                componentService.genericFunction(720, vm.model.edit).then(function (data) {
                    stateService.back();
                });
            };

            vm.onProductSearchSelected = function (item) {
                console.log('selected');
                if (item?.item_id) {
                    vm.model.change_enabled = false;
                }
            }

            vm.onClearProductSearch = function () {
                vm.model.edit.product_search = '';
                vm.model.edit.comp_is_prod_id = '';
                vm.model.edit.comp_prod_name = '';
                vm.model.edit.stock_unit_no = '';
            }

            vm.onProductSearchChange = function (value) {
                vm.model.edit.product_search = value;

                let item = componentService.iRec[vm.model.edit.product_search];

                if (item) {
                    vm.model.change_enabled = false;
                    vm.model.edit.comp_is_prod_id = item.item_id;
                    
                    prodChanged();
                } else if (!value?.trim()) {
                    vm.onClearProductSearch();
                }
            }

            vm.onQuantityChanged = function (value) {
                if (vm.model.change_enabled == true && vm.model.edit.quantity !== value) {
                    vm.model.edit.quantity = value;
                    vm.model.is_changed = true;
                    console.log('changed');
                }
            }

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                switch (id) {
                    case 'special_length':
                    case 'special_width':
                    case 'special_quantity':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        loadCalculation(id);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.onBlur = function (id) {
                switch (id) {
                    case 'special_quantity_formula':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        loadCalculation(id);
                        break;
                    default:
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            vm.$onInit = function () {
                loadP2ComponentGet();
            }

        }]
    });
})();
