(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansDeliveryDriver', {
        templateUrl: 'views/components/views/consignmentPlansDeliveryDriver/consignmentPlansDeliveryDriver.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'consignmentPlansDeliveryDriverService', 'typeaheadService', 'rememberService', function ($stateParams, stateService, utilityService, modalService, consignmentPlansDeliveryDriverService, typeaheadService, rememberService) {
            var vm = this;
            var onDestroy = [];

            var variableNames = {
                equipment_id: ''
            };

            vm.model = {
                stockzone_keyno: '',
                deliverymethod_no: '',
                phone: '',
                equipment_id: '',
                equipment_name: '',
                deliverymethods: [],
                lockedUpdate: false
            };

            vm.typeaheadSearchEquipments = function () {
                return typeaheadService.lookUpEquipments({ equipment_name: vm.model.equipment_name });
            };

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'equipment_id':
                        if (utilityService.validateParmsValue(vm.model.equipment_id, true) !== true) return;

                        variableValue = vm.model.equipment_id;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            vm.handleDeliveryMethodChange = function (value) {
                vm.model.deliverymethod_no = value;

                angular.forEach(vm.model.deliverymethods, function (item) {
                    if (item.deliverymethod_no == value) {
                        vm.model.phone = item.phone;
                    }
                });
            };

            vm.updateDriver = function () {
                vm.model.lockedUpdate = true;

                consignmentPlansDeliveryDriverService.updateDriver({
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    deliverymethod_no: vm.model.deliverymethod_no,
                    delivery_phone: vm.model.phone,
                    equipment_id: vm.model.equipment_id
                }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedUpdate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedUpdate = false;
                        stateService.back();
                    }
                });
            };

            vm.onEquipmentClear = function () {
                vm.model.equipment_id = '';
                vm.model.equipment_name = '';
            }

            vm.onEquipmentSelected = function (item) {
                vm.model.equipment_name = item?.item_name ?? '';
                vm.model.equipment_id = item?.item_id ?? '';
            }

            vm.onEquipmentSearchChanged = function (value) {
                vm.model.equipment_name = value;

                if (!value || value.trim() === '') {
                    vm.onEquipmentClear();
                    rememberFunc('equipment_id');
                } else {
                    let equipmentFound = typeaheadService.selectEquipmentList.find((item) => item.item_name === value);

                    if (equipmentFound) {
                        vm.onEquipmentSelected(equipmentFound);
                        rememberFunc('equipment_id');
                    }
                }
            }

            vm.$onInit = function () {
                consignmentPlansDeliveryDriverService.loadSettings($stateParams.argtype, $stateParams.argvalue).then(function (data) {
                    vm.model.deliverymethod_no = data.deliverymethod_no;
                    vm.model.phone = data.phone.replace(': ', '');
                    vm.model.equipment_id = data.equipment_id;
                    vm.model.equipment_name = data.equipment_name;
                });

                consignmentPlansDeliveryDriverService.loadDeliveryMethods().then(function (data) {
                    angular.copy(data, vm.model.deliverymethods);
                });
            }

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
