(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('componentAlternatives', {
        templateUrl: 'views/components/views/componentAlternatives/componentAlternatives.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'componentAlternativesService', function ($stateParams, stateService, utilityService, componentAlternativesService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let prodId = $stateParams.prod_id;
            let compKeyno = $stateParams.comp_keyno;

            vm.model = {
                setting: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false,
                        readAfterSave: true
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                componentAlternativesService.getComponentAlternatives({
                    prod_id: prodId,
                    comp_keyno: compKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                        vm.grid.dataTask.loadData = {
                            method: vm.model.setting.p2_datatask_keyno,
                            parameters: vm.model.setting
                        };
                        vm.grid.dataTask.saveData.parameters = vm.model.setting;
                    }

                    vm.model.gridReady = true;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'componentalternative':
                        go({
                            prod_id: vm.model.setting.prod_keyno,
                            comp_keyno: vm.model.setting.comp_keyno,
                            componentalternative_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
