(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2ReportDefs', {
        templateUrl: 'views/components/views/p2ReportDefs/p2ReportDefs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'p2ReportDefsService', 'printService', 'rememberService', function (stateService, utilityService, p2ReportDefsService, printService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                filtervalue_reports: ''
            };

            vm.model = {
                bgReportDefinitions: {
                    selected: 'REPORT',
                    buttons: [
                        { id: 'REPORT', label: 'p2_reportdefs_reports', color: 'primary', onClick: () => vm.model.bgReportDefinitions.selected = 'REPORT' },
                        { id: 'PRINTER', label: 'p2_reportdefs_printers', color: 'primary', onClick: () => vm.model.bgReportDefinitions.selected = 'PRINTER' },
                        { id: 'PRINTCLIENT', label: 'p2_reportdefs_printclient', color: 'primary', onClick: () => vm.model.bgReportDefinitions.selected = 'PRINTCLIENT' }
                    ]
                },
                filterValueReportsButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue_reports') }
                ],
                setting: {},
                itemsListReports: [],
                itemsListPrinters: [],
                itemsListClients: [],
                updatingChanges: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
    		let loadSetting = function () {
                p2ReportDefsService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
    			});
    		};
            
            let loadReports = function () {
                vm.model.itemsListReports = [];

                p2ReportDefsService.listReports().then(function (result) {
                    vm.model.updatingChanges = false;
                    angular.copy(result, vm.model.itemsListReports);
                });
            };
            
            let loadPrinters = function () {
                vm.model.itemsListPrinters = [];

                p2ReportDefsService.listPrinters().then(function (result) {
                    vm.model.updatingChanges = false;
                    angular.copy(result, vm.model.itemsListPrinters);
                });
            };
            
            let loadClients = function () {
                vm.model.itemsListClients = [];

                p2ReportDefsService.listClients().then(function (result) {
                    vm.model.updatingChanges = false;
                    angular.copy(result, vm.model.itemsListClients);
                });
            };

            // #endregion LOAD PROCEDURE CALL
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'filtervalue_reports':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue_reports':
                        vm.model.setting.filtervalue_reports = '';

                        if (vm.model.setting.filtervalue_reports === '') rememberFunc('filtervalue_reports');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filtervalue_reports':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue_reports) !== true) return;

                        vm.model.setting.filtervalue_reports = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue_reports, true) !== true) return;

                        if (vm.model.setting.filtervalue_reports === '') rememberFunc('filtervalue_reports');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue_reports':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue_reports) !== true) return;

                        if (vm.model.setting.filtervalue_reports > '') rememberFunc('filtervalue_reports');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region BUTTON UPDATE PROCEDURES
    
            vm.updateChanges = function () {
                vm.model.updatingChanges = true;

                printService.getReports().then(function (data) {
                    p2ReportDefsService.updateReports(data).then(function () {
                        loadReports();
                    });
                });
                
                printService.getPrinters().then(function (data) {
                    p2ReportDefsService.updatePrinters(data).then(function () {
                        loadPrinters();
                    });
                });
                
                printService.getClients().then(function (data) {
                    p2ReportDefsService.updateClients(data).then(function () {
                        loadClients();
                    });
                });
                
                //printService.getParameters({ reportKeyno: 1 }).then(function (data) {
                //    //console.log('params');
                //    //console.dir(data);
                //    //console.log(angular.toJson(data));
                //});
            };

            // #endregion BUTTON UPDATE PROCEDURES

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgReportDefinitions = function (value) {
                vm.model.bgReportDefinitions.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2reportdef':
                        go({ p2_reportdef_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadReports();
                loadPrinters();
                loadClients();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
