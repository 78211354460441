(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('colorGrps', {
        templateUrl: 'views/components/views/colorGrps/colorGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'colorGrpsService', function (stateService, colorGrpsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filterval = '' }
                ],
                setting: {},
                itemsListColourGroupViews: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadSetting = function () {
                colorGrpsService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadColourGroupViews = function () {
                colorGrpsService.listColourGroupViews().then(function (list) {
                    angular.copy(list, vm.model.itemsListColourGroupViews);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'colorgrp':
                        go({ colorgrp_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadColourGroupViews();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
