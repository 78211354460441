(function () {
    'use strict';

    angular.module("imApp").factory("priceIndexService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 3221,
                    parameters: parms || {}
                });
            },
            listIntervalTypes: function (parms) {
                return $ihttp.post({
                    method: 3224,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 3222,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 3225,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
