(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockTransMenuService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadStockTransMenuGet: function (stocktrans_keyno) {
                return p2DataTaskService.call(1873, {
                    stocktrans_keyno: stocktrans_keyno
                });
            },
            loadStockTransMenuAllocList: function (stocktrans_keyno) {
                return p2DataTaskService.call(1874, {
                    stocktrans_keyno: stocktrans_keyno
                });
            }
        };

        return service;
    }]);
})();