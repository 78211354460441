(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('choiceProdPriceGrps', {
        templateUrl: 'views/components/views/choiceProdPriceGrps/choiceProdPriceGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'choiceProdPriceGrpsService', function ($stateParams, stateService, choiceProdPriceGrpsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodId = $stateParams.prod_id;

            vm.model = {
                itemsListPriceGroups: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadPriceGroups = function () {
                choiceProdPriceGrpsService.listPriceGroups({ prod_id: prodId }).then(function (list) {
                    angular.copy(list, vm.model.itemsListPriceGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'choiceprodpricegrp':
                        go({
                            choiceprodpricegrp_keyono: '0',
                            prod_id_choice: prodId
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadPriceGroups();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
