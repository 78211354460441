(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srEquipment', {
        templateUrl: 'views/components/views/srEquipment/srEquipment.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srEquipmentService', function($stateParams, stateService, utilityService, srEquipmentService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceId: $stateParams.resource_id,
                srEquipmentKeyNo: $stateParams.srequipment_keyno,
                equipment: {},
                selectListRefundTypes: [],
                selectListFuelTypes: [],
                selectListDroneTypes: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srEquipmentService.loadSrEquipmentGet(vm.model.resourceId, vm.model.srEquipmentKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.equipment);
            });
    
            srEquipmentService.loadSrRefundTypeIsEquipmentList(vm.model.resourceId).then(function (data) {
                angular.copy(data, vm.model.selectListRefundTypes);
            });
    
            srEquipmentService.loadSrEquipmentFuelTypeList().then(function (data) {
                angular.copy(data, vm.model.selectListFuelTypes);
            });
    
            srEquipmentService.loadSrEquipmentDroneTypeList().then(function (data) {
                angular.copy(data, vm.model.selectListDroneTypes);
            });
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrEquipment = function () {
                vm.model.lockedSave = true;
                
                srEquipmentService.save(vm.model.equipment).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();