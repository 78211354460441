(function() {
    'use strict';

    angular.module("imApp").factory("interbranchInvoicelineMultiApprovalService", ['$ihttp', function ($ihttp) {
        var service = {

            listShipmentContainer: function (parms) {
                return $ihttp.post({
                    method: 3257,
                    parameters: parms || {}
                });
            }

        };

        return service;
    }]);
})();
