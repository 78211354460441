(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('consignments', {
        templateUrl: 'views/components/views/consignments/consignments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'utilityService', 'consignmentsService', 'stateService', 'ttGridFactory', function ($q, $stateParams, utilityService, consignmentsService, stateService, ttGridFactory) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            let vm = this;

            vm.model = {
                parm: {
                    argType: $stateParams.argtype
                },
                consignmentStatusTabs: {
                    active: 'NOTAPPROVED',
                    buttons: [
                        { id: 'NOTAPPROVED', label: 'gb_consignment_status_not_approved', onClick: () => selectConsignmentStatusTab('NOTAPPROVED') },
                        { id: 'APPROVED', label: 'gb_consignment_status_approved', onClick: () => selectConsignmentStatusTab('APPROVED') },
                        { id: 'ALL', label: 'gb_consignment_status_all', onClick: () => selectConsignmentStatusTab('ALL') },
                    ]
                },
                consignmentShipmentButtons: [
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: addNewConsignmentShipment },
                    { id: 'list', icon: 'glyphicon glyphicon-align-justify', color: 'primary', type: 'primary', onClick: gotoConsignmentShipmentsList },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoConsignmentShipment }
                ],
                filterButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearFilterValue }
                ],
                p2WConsignmentsSettings: {},
                p2BusinessCoList: {
                    records: []
                },
                p2StockPlaceList: {
                    records: []
                },
                p2ConsignmentList: {
                    records: []
                },
                listStockTransferCreateTrolleySelect: [],
                listConsignmentShipmentSelect: [],
                p2PortalUserVariableSetWsBusinessCoNoFrom: {},
                p2PortalUserVariableSetWsStockPlaceIdFrom: {},
                p2PortalUserVariableSetWsDateFrom: {},
                p2PortalUserVariableSetWsDateTo: {},
                p2PortalUserVariableSetWsDateSelectorIndex: {},
                p2PortalUserVariableSetWsGbConsignmentStatus: {},
                p2PortalUserVariableSetWsFilterVal: {},
                loadP2ConsignmentListLoadedState: '',
                gridReady: false,
                mobileSize: window.matchMedia('(max-width: 992px)'),
            };

            vm.grid = new ttGridFactory({
                rememberId: 'consignments.grid'
            });

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            let loadGrid = function () {

                vm.grid.dataTask.loadData = {
                    method: 3444,
                    parameters: {
                        argtype: $stateParams.argtype,
                        argvalue: $stateParams.argvalue,
                        businessco_no_from: vm.model.p2WConsignmentsSettings.businessco_no_from,
                        stockplace_id_from: vm.model.p2WConsignmentsSettings.stockplace_id_from,
                        stocklocation_keyno_trolley: vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley,
                        date_fom: vm.model.p2WConsignmentsSettings.date_fom,
                        date_tom: vm.model.p2WConsignmentsSettings.date_tom,
                        gb_consignment_status: vm.model.p2WConsignmentsSettings.gb_consignment_status,
                        consignmentshipment_keyno: vm.model.p2WConsignmentsSettings.consignmentshipment_keyno
                    }
                }
                vm.model.gridReady = true;
                if (vm.grid?.gridfunc?.rebind) {
                    vm.grid.gridfunc.rebind();
                }
            }

            let loadP2WConsignmentsSettings = function () {
                return consignmentsService.genericFunction(831, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue
                }).then(function (data) {
                    vm.model.p2WConsignmentsSettings = angular.copy(data[0]);
                    vm.model.consignmentStatusTabs.active = vm.model.p2WConsignmentsSettings.gb_consignment_status;
                });
            };

            let loadP2BusinessCoList = function () {
                return consignmentsService.genericFunction(104, {}).then(function (data) {
                    angular.copy(data, vm.model.p2BusinessCoList.records);
                });
            };

            vm.onFocusLost = function (value) {
                console.log(value);
                console.log('focus lost');
                console.log(vm.model.p2WConsignmentsSettings.filterval);
            }


            let loadP2StockPlaceList = function () {
                if (angular.isUndefined(vm.model.p2WConsignmentsSettings.businessco_no_from) === true) return;
                if (vm.model.p2WConsignmentsSettings.businessco_no_from === null) return;
                if (vm.model.p2WConsignmentsSettings.businessco_no_from.trim().length < 1) return;

                return consignmentsService.genericFunction(622, {
                    businessco_no: vm.model.p2WConsignmentsSettings.businessco_no_from
                }).then((data) => angular.copy(data, vm.model.p2StockPlaceList.records));
            };

            let loadP2ConsignmentList = function () {
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.businessco_no_from) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.stockplace_id_from, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.gb_consignment_status) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.consignmentshipment_keyno, true) !== true) return;

                vm.model.p2ConsignmentList.records = [];

                consignmentsService.genericFunction(329, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    businessco_no_from: vm.model.p2WConsignmentsSettings.businessco_no_from,
                    stockplace_id_from: vm.model.p2WConsignmentsSettings.stockplace_id_from,
                    stocklocation_keyno_trolley: vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley,
                    date_fom: vm.model.p2WConsignmentsSettings.date_fom,
                    date_tom: vm.model.p2WConsignmentsSettings.date_tom,
                    gb_consignment_status: vm.model.p2WConsignmentsSettings.gb_consignment_status,
                    consignmentshipment_keyno: vm.model.p2WConsignmentsSettings.consignmentshipment_keyno
                }).then(function (data) {
                    angular.copy(data, vm.model.p2ConsignmentList.records);
                });
            };

            let loadConsignmentsBusinessCoNoChanged = function () {
                if (utilityService.validateParmsValue(vm.model.p2WConsignmentsSettings.businessco_no_from) !== true) return;

                consignmentsService.loadConsignmentsBusinessCoNoChanged(vm.model.p2WConsignmentsSettings.businessco_no_from).then(function (data) {
                    vm.model.p2WConsignmentsSettings.stockplace_id_from = data[0].stockplace_id_from;
                });
            };

            let loadStockTransferCreateTrolleySelectList = function () {
                if (validVariable(vm.model.p2WConsignmentsSettings.stockplace_id_from) !== true) return;

                return consignmentsService.loadStockTransferCreateTrolleySelectList(vm.model.p2WConsignmentsSettings.stockplace_id_from, '1').then((data) => angular.copy(data, vm.model.listStockTransferCreateTrolleySelect));
            };

            let loadConsignmentShipmentSelect = function (callback) {
                if (angular.isUndefined(vm.model.p2WConsignmentsSettings.stockplace_id_from) === true) return;
                if (vm.model.p2WConsignmentsSettings.stockplace_id_from === null) return;
                if (vm.model.p2WConsignmentsSettings.stockplace_id_from.trim().length < 1) return;

                consignmentsService.loadConsignmentShipmentSelectList(vm.model.p2WConsignmentsSettings.stockplace_id_from, '1', '1').then(function (data) {
                    angular.copy(data, vm.model.listConsignmentShipmentSelect);
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                });
            };

            let canRun616 = function (variableName, parm) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.p2WConsignmentsSettings[parm]) === true) return false;
                if (vm.model.p2WConsignmentsSettings[parm] === null) return false;

                return true;
            };

            let validVariable = function (variableName) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;

                return true;
            };

            let setP2PortalUserVariableSetWsBusinessCoNoFrom = function () {
                let variableNameBusinessCoNoFrom = 'consignments.businessco_no_from';

                if (canRun616(variableNameBusinessCoNoFrom, 'businessco_no_from') !== true) return;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameBusinessCoNoFrom,
                    variablevalue: vm.model.p2WConsignmentsSettings.businessco_no_from
                });
            };

            let setP2PortalUserVariableSetWsStockPlaceIdFrom = function () {
                let variableNameStockPlaceIdFrom = 'consignments.stockplace_id_from.businessco_no_from=' + vm.model.p2WConsignmentsSettings.businessco_no_from;

                if (canRun616(variableNameStockPlaceIdFrom, 'stockplace_id_from') !== true) return;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameStockPlaceIdFrom,
                    variablevalue: vm.model.p2WConsignmentsSettings.stockplace_id_from
                });
            };

            let loadP2PortalUserVariableSetWsDateFrom = function () {
                let variableNameDateFom = 'consignments.date_fom';

                if (canRun616(variableNameDateFom, 'date_fom') !== true) return;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameDateFom,
                    variablevalue: vm.model.p2WConsignmentsSettings.date_fom
                });
            };

            let loadP2PortalUserVariableSetWsDateTo = function () {
                let variableNameDateTo = 'consignments.date_tom';

                if (canRun616(variableNameDateTo, 'date_tom') !== true) return;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameDateTo,
                    variablevalue: vm.model.p2WConsignmentsSettings.date_tom
                });
            };

            let loadP2PortalUserVariableSetWsDateSelectorIndex = function () {
                let variableNameDateSelectorIndex = 'consignments.dateselector_index';

                if (canRun616(variableNameDateSelectorIndex, 'dateselector_index') !== true) return;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameDateSelectorIndex,
                    variablevalue: vm.model.p2WConsignmentsSettings.dateselector_index
                });
            };

            let loadP2PortalUserVariableSetWsGbConsignmentStatus = function () {
                let variableNameGbConsignmentStatus = 'consignments.gb_consignment_status';

                if (angular.isUndefined(variableNameGbConsignmentStatus) === true) return false;
                if (variableNameGbConsignmentStatus === null) return false;
                if (variableNameGbConsignmentStatus === '') return false;
                if (angular.isUndefined(vm.model.p2WConsignmentsSettings.gb_consignment_status) === true) return false;
                if (vm.model.p2WConsignmentsSettings.gb_consignment_status === null) return false;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameGbConsignmentStatus,
                    variablevalue: vm.model.p2WConsignmentsSettings.gb_consignment_status
                });
            };

            let loadP2PortalUserVariableSetWsFilterVal = function () {
                let variableNameFilterVal = 'consignments.filterval';

                if (canRun616(variableNameFilterVal, 'filterval') !== true) return;

                consignmentsService.genericFunction(616, {
                    variablename: variableNameFilterVal,
                    variablevalue: vm.model.p2WConsignmentsSettings.filterval
                });
            };

            let rememberTrolley = function () {
                if (validVariable(vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley) !== true) return;
                if (validVariable(vm.model.p2WConsignmentsSettings.stockplace_id_from) !== true) return;

                let variableName = 'w_consignments.stocklocation_keyno_trolley.stockplace_id=' + vm.model.p2WConsignmentsSettings.stockplace_id_from;
                if (validVariable(variableName) !== true) return;

                consignmentsService.remember(variableName, vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley);
            };

            let rememberConsignmentShipmentKeyNo = function () {
                let variableNameConsignmentShipmentKeyNo = 'w_consignments.consignmentshipment_keyno.stockplace_id=' + vm.model.p2WConsignmentsSettings.stockplace_id_from;

                if (canRun616(variableNameConsignmentShipmentKeyNo, 'consignmentshipment_keyno') !== true) return;

                consignmentsService.remember(variableNameConsignmentShipmentKeyNo, vm.model.p2WConsignmentsSettings.consignmentshipment_keyno);
            };

            let loadCurrentTrolley = function () {
                let deferred = $q.defer();

                consignmentsService.genericFunction(1094, {
                    stockplace_id_from: vm.model.p2WConsignmentsSettings.stockplace_id_from
                }).then(function (data) {
                    vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley = data[0].stocklocation_keyno_trolley;
                    vm.model.p2WConsignmentsSettings.consignmentshipment_keyno = data[0].consignmentshipment_keyno;

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // ########################
            // ## BUTTON LIST FUNCTIONS
            // ########################

            function addNewConsignmentShipment() {
                stateService.go('consignmentshipment', {
                    consignmentshipment_keyno: '0',
                    stockplace_id: vm.model.p2WConsignmentsSettings.stockplace_id_from
                });
            }

            function gotoConsignmentShipmentsList() {
                stateService.go('consignmentshipments');
            }

            function gotoConsignmentShipment() {
                if (vm.model.p2WConsignmentsSettings.consignmentshipment_keyno > 0) {
                    stateService.go('consignmentshipment', {
                        consignmentshipment_keyno: vm.model.p2WConsignmentsSettings.consignmentshipment_keyno,
                        stockplace_id: vm.model.p2WConsignmentsSettings.stockplace_id_from
                    });
                }
            }

            function clearFilterValue() {
                vm.model.p2WConsignmentsSettings.filterval = '';

                loadP2PortalUserVariableSetWsFilterVal();
            }

            function selectConsignmentStatusTab(id) {
                vm.model.consignmentStatusTabs.active = id;
                vm.model.p2WConsignmentsSettings.gb_consignment_status = vm.model.consignmentStatusTabs.active;

                if (vm.model.mobileSize.matches) {
                    loadP2ConsignmentList();
                } else {
                    loadGrid();
                }
                loadP2PortalUserVariableSetWsGbConsignmentStatus();
            }

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.p2WConsignmentsSettings.date_fom = startDate;
                vm.model.p2WConsignmentsSettings.date_tom = endDate;
                vm.model.p2WConsignmentsSettings.dateselector_index = dateInterval;

                if (vm.model.mobileSize.matches) {
                    loadP2ConsignmentList();
                } else {
                    loadGrid();
                }
                loadP2PortalUserVariableSetWsDateFrom();
                loadP2PortalUserVariableSetWsDateTo();
                loadP2PortalUserVariableSetWsDateSelectorIndex();
            }

            // Virksomhet
            vm.onBusinessCompanyFromChanged = async function (value) {
                vm.model.p2WConsignmentsSettings.businessco_no_from = value;

                await loadP2StockPlaceList();
                setP2PortalUserVariableSetWsBusinessCoNoFrom();
                loadConsignmentsBusinessCoNoChanged();
                if (vm.model.mobileSize.matches) {
                    loadP2ConsignmentList();
                } else {
                    loadGrid();
                }
            }

            // Lagersted
            vm.onStockplaceFromChanged = async function (value) {
                vm.model.p2WConsignmentsSettings.stockplace_id_from = value;

                setP2PortalUserVariableSetWsStockPlaceIdFrom();
                loadConsignmentShipmentSelect();
                await loadStockTransferCreateTrolleySelectList();
                await loadCurrentTrolley();
                if (vm.model.mobileSize.matches) {
                    loadP2ConsignmentList();
                } else {
                    loadGrid();
                }
                
                
            }

            // Plukker
            vm.onStocklocationTrolleyChanged = function (value) {
                vm.model.p2WConsignmentsSettings.stocklocation_keyno_trolley = value;

                rememberTrolley();
                if (vm.model.mobileSize.matches) {
                    loadP2ConsignmentList();
                } else {
                    loadGrid();
                }
            }

            // Transport
            vm.onConsignmentShipmentChanged = function (value) {
                vm.model.p2WConsignmentsSettings.consignmentshipment_keyno = value;

                rememberConsignmentShipmentKeyNo();
                if (vm.model.mobileSize.matches) {
                    loadP2ConsignmentList();
                } else {
                    loadGrid();
                }
            }

            vm.onFilterValueChanged = function (value) {
                vm.model.p2WConsignmentsSettings.filterval = value;
            }

            vm.onFilterValueBlur = function (value) {
                loadP2PortalUserVariableSetWsFilterVal();
            }

            vm.$onInit = async function () {
                await loadP2WConsignmentsSettings();
                loadP2BusinessCoList();

                loadP2StockPlaceList();
                await loadConsignmentsBusinessCoNoChanged();

                loadConsignmentShipmentSelect();
                loadStockTransferCreateTrolleySelectList();
                await loadCurrentTrolley();

                loadP2ConsignmentList();
                loadGrid();
            }
        }]
    });
})();
