(function () {
    'use strict';

    let module = angular.module('imApp');

    /**
     * @author JLR
     * @version 20231108
     */
    module.component('emailAccountSharedEdit', {
        templateUrl: 'views/components/views/emailAccountSharedEdit/emailAccountSharedEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'emailAccountSharedEditService', function ($stateParams, stateService, modalService, translateService, emailAccountSharedEditService) {
            let vm = this;

            vm.model = {
                emailaccount_shared_keyno: $stateParams.emailaccount_shared_keyno,
                sharedMailbox: {},
            };

            vm.locks = {
                save: false,
                delete: false,
            };

            let translations = {
                error: '',
                ok: '',
            };

            vm.saveSharedMailbox = async function () {
                vm.locks.save = true;

                let response = await emailAccountSharedEditService.saveSharedMailbox(vm.model.sharedMailbox);
                if (response[0]?.errorcode !== '0') {
                    await _showWarningModal(response[0].errormessage);
                    vm.locks.save = false;
                } else {
                    stateService.back();
                }
            };

            vm.deleteSharedMailbox = async function () {
                vm.locks.delete = true;

                let response = await emailAccountSharedEditService.deleteSharedMailbox(vm.model.sharedMailbox);
                if (response[0]?.errorcode !== '0') {
                    await _showWarningModal(response[0].errormessage);
                    vm.locks.delete = false;
                } else {
                    stateService.back();
                }
            };

            function _translate() {
                translateService.translateBatch(translations).then((data) => Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                }));
            }

            async function _showWarningModal(error) {
                return new Promise((resolve) => {
                    modalService.show({
                        type: 'warning',
                        title: translations.error,
                        message: error,
                        buttons: [{
                            label: translations.ok,
                            cssClass: 'btn-warning',
                            action: function (dialogItself) {
                                dialogItself.close();
                                resolve();
                            }
                        }]
                    });
                });
            };

            async function _getSharedMailbox() {
                vm.model.sharedMailbox = (await emailAccountSharedEditService.getSharedMailbox($stateParams.emailaccount_shared_keyno, $stateParams.current_account_email))[0];
            };

            vm.$onInit = async function () {
                _translate();
                _getSharedMailbox();
            };
        }]
    });
})();
