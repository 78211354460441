(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderShipmentStatus', {
        templateUrl: 'views/components/views/orderShipmentStatus/orderShipmentStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', function ($stateParams) {
            var vm = this;

            vm.grid = {
                dataTask: {
                    rememberId: 'ordershipmentstatus.grid',
                    loadSetupId: null,
                    loadData: {
                        method: 2417,
                        parameters: {
                            order_internal_no: $stateParams.order_internal_no
                        }
                    }
                },
                translations: [],
                config: {
                    editColumns: [],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };
        }]
    });
})();
