(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('pohCompAdd', {
        templateUrl: 'views/components/views/pohCompAdd/pohCompAdd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'pohCompAddService', function ($stateParams, stateService, pohCompAddService) {
            var vm = this;
            vm.p2_pohcompadd_get = pohCompAddService.p2_pohcompadd_get;
            
            pohCompAddService.loadP2PohCompAddGet($stateParams.poh_keyno, $stateParams.stock_keyno).then(function () {
                vm.p2_pohcompadd_get = pohCompAddService.p2_pohcompadd_get;
            });
            
            vm.savePohCompAdd = function () {
                pohCompAddService.savePohCompAdd().then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();