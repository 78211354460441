(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("shiftPlansService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            dataSource: {
                records: []
            },
            shiftPlansList: function () {
                var deferred = $q.defer();

                var parms_shiftPlansList = {
                    method: 512
                };

                $ihttp.post(parms_shiftPlansList).then(function (data) {
                    angular.copy(data, service.dataSource.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();