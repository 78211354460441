(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("timeRegisterService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            selectingDateStart: false,
            selectingDateEnd: false,
            selectingTimeStart: false,
            selectingTimeEnd: false,
            selectCustNameList: [],
            selectProductList: [],
            loadTimeRegisterGet: function (custact_no, powtime_keyno) {
                return p2DataTaskService.call(1345, {
                    custact_no: custact_no,
                    powtime_keyno: powtime_keyno
                });
            },
            loadTimeRegisterLabourList: function (businessco_no) {
                return p2DataTaskService.call(1531, {
                    businessco_no: businessco_no
                });
            },
            loadOperationList: function (custact_no) {
                return p2DataTaskService.call(14, {
                    custact_no: custact_no
                });
            },
            loadPstSelectList: function (custact_no) {
                return p2DataTaskService.call(34, {
                    custact_no: custact_no
                });
            },
            loadProjectList: function (bg_projects_activestatus, argtype, argvalue) {
                return p2DataTaskService.call(902, {
                    bg_projects_activestatus: bg_projects_activestatus,
                    argtype: argtype,
                    argvalue: argvalue
                });
            },
            loadProjectProcessList: function (project_keyno, custact_no, show_only_timeentry_allowed) {
                return p2DataTaskService.call(47, {
                    project_keyno: project_keyno,
                    custact_no: custact_no,
                    show_only_timeentry_allowed: show_only_timeentry_allowed
                });
            },
            loadSalaryTypeList: function () {
                return p2DataTaskService.call(481, {});
            },
            loadShiftList: function (only_active) {
                return p2DataTaskService.call(516, {
                    only_active: only_active
                });
            },
            listCustacts: function (parms) {
                return $ihttp.post({
                    method: 2726,
                    parameters: parms || {}
                });
            },
            listExtraPowTimes: function (parms) {
                return $ihttp.post({
                    method: 3423,
                    parameters: parms || {}
                });
            },
            loadBreakMinuteGet: function (edit) {
                return p2DataTaskService.call(32, edit);
            },
            getSalaryHoursToTimeAccount: function (edit) {
                return $ihttp.post({
                    method: 3445,
                    parameters: edit
                });
            },
            loadPricesGet: function (cust_no, custact_no, project_keyno, projectprosess_keyno, equipment_id, prod_id) {
                return p2DataTaskService.call(35, {
                    cust_no: cust_no,
                    custact_no: custact_no,
                    project_keyno: project_keyno,
                    projectprosess_keyno: projectprosess_keyno,
                    equipment_id: equipment_id,
                    prod_id: prod_id
                });
            },
            loadEquipmentProductGet: function (equipment_id ) {
                return p2DataTaskService.call(33, {
                    equipment_id: equipment_id
                });
            },
            searchCustName: function (cust_name, filtertype, filtervalue, custtype, lastselect) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 56, parameters: {
                        cust_name: cust_name,
                        filtertype: filtertype,
                        filtervalue: filtervalue,
                        custtype: custtype,
                        lastselect: lastselect
                    }
                }).then(function (data) {
                    angular.copy(data, service.selectCustNameList);

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            searchProdName: function (prod_name, webpage_name, parm1, parm2, supplier_no, search_only_this_supplier, businessco_no, onlyonstock, model_id) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 78, parameters: {
                        prod_name: prod_name,
                        webpage_name: webpage_name,
                        parm1: parm1,
                        parm2: parm2,
                        supplier_no: supplier_no,
                        search_only_this_supplier: search_only_this_supplier,
                        businessco_no: businessco_no,
                        onlyonstock: onlyonstock,
                        model_id: model_id
                    }
                }).then(function (data) {
                    angular.copy(data, service.selectProductList);

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            changeCustact: function (parms) {
                return $ihttp.post({
                    method: 2725,
                    parameters: parms
                });
            },
            save: function (time) {
                return p2DataTaskService.call(36, time);
            },
            approve: function (powtime_keyno) {
                return p2DataTaskService.call(1346, {
                    powtime_keyno: powtime_keyno
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 1522,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
