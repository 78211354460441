(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ttTemplate', {
        templateUrl: 'views/components/views/template/template.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['templateService', function (templateService) {
            var vm = this;

        }]
    });
})();
