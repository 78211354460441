(function () {
    'use strict';

    angular.module("imApp").factory("acSuppTransesService", ['$ihttp', function ($ihttp) {
        var service = {
            getAcSuppTranses: function (parms) {
                return $ihttp.post({
                    method: 2791,
                    parameters: parms || {}
                });
            },
            removeMatching: function (setting) {
                return $ihttp.post({
                    method: 2824,
                    parameters: setting
                });
            },
            matchSelected: function (mergeObj) {
                return $ihttp.post({
                    method: 2825,
                    parameters: mergeObj
                });
            },
            matchAuto: function (setting) {
                return $ihttp.post({
                    method: 2826,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();