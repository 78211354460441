(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('invoices', {
        templateUrl: 'views/components/views/invoices/invoices.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', '$uibModal', 'ieScreenBlockService', 'invoicesService', 'logisticService', 'rememberService', 'translateService', function ($q, $stateParams, $uibModal, ieScreenBlockService, invoicesService, logisticService, rememberService, translateService) {
            var vm = this;

            var rememberIdGrid = 'invoicesComponentGrid';
            var rememberIdSelect = 'invoicesComponentSelect';

            var translations = [
                'tt_all',
                'tt_errors',
                'tt_edi_completed',
                'tt_edi_not_completed',
                'tt_invoices_grid_msg01',
                'tt_invoices_grid_msg02',
                'tt_invoices_grid_msg03'
            ];

            var msg01 = '';
            var msg02 = '';
            var msg03 = '';

            vm.arg = {
                type: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                value: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
            };

            vm.select = {
                businesscoNo: 0,
                dates: {
                    from: null,
                    to: null,
                    bindex: 1
                },
                statusId: 10
            };

            vm.companies = [];

            vm.statuses = [
                { item_id: 10, item_name: '' }, // All
                { item_id: 20, item_name: '' }, // Errors
                { item_id: 30, item_name: '' }, // EDI Completed
                { item_id: 40, item_name: '' }, // EDI not completed
            ];

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2369,
                        parameters: function () {
                            return {
                                argtype: vm.arg.type,
                                argvalue: vm.arg.value,
                                businesscoNo: vm.select.businesscoNo,
                                dateFrom: vm.select.dates.from,
                                dateTo: vm.select.dates.to,
                                statusId: vm.select.statusId
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [{
                            name: 'resend',
                            text: '',
                            icon: 'fa-paper-plane',
                            func: function (data) {
                                console.log('data');
                                console.dir(data);

                                var message = msg03;

                                if (angular.isDefined(data.errormessage) && angular.isString(data.errormessage) && data.errormessage !== null && data.errormessage.length > 0) {
                                    message = msg01 + ' "' + data.errormessage + '". ' + msg02;
                                }
                                
                                var modalInstance = $uibModal.open({
                                    animation: true,
                                    backdrop: 'static',
                                    component: 'ttConfirm',
                                    resolve: {
                                        message: function () {
                                            return message;
                                        }
                                    }
                                });

                                modalInstance.result.then(function (confirmed) {
                                    if (confirmed !== true) return;

                                    ieScreenBlockService.start();

                                    invoicesService.resend(data.item_parms).then(function (result) {
                                        ieScreenBlockService.stop();

                                        vm.gridOptions.gridfunc.read();
                                    });
                                });
                            },
                            disabled: function (data) {
                                //return data.errorcode !== '0';
                                return false;
                            }
                        }]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: true
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) {  },
                gridfunc: null
            };

            var remember = function () {
                rememberService.remember(rememberIdSelect, vm.select);

                vm.gridOptions.gridfunc.read();
            };

            var load = function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdSelect, true));
                promises.push(logisticService.listBusinessCompanies(true));
                promises.push(translateService.translateBatch(translations))

                $q.all(promises).then(function (response) {
                    vm.select.businesscoNo = response[0].businesscoNo;
                    vm.select.dates.from = response[0].dates.from;
                    vm.select.dates.to = response[0].dates.to;
                    vm.select.dates.bindex = response[0].dates.bindex;
                    vm.select.statusId = response[0].statusId;

                    angular.copy(response[1], vm.companies);

                    vm.statuses[0].item_name = response[2].tt_all;
                    vm.statuses[1].item_name = response[2].tt_errors;
                    vm.statuses[2].item_name = response[2].tt_edi_completed;
                    vm.statuses[3].item_name = response[2].tt_edi_not_completed;

                    msg01 = response[2].tt_invoices_grid_msg01;
                    msg02 = response[2].tt_invoices_grid_msg02;
                    msg03 = response[2].tt_invoices_grid_msg03;

                    vm.gridOptions.gridfunc.rebind();
                });

                return deferred.promise;
            };

            vm.onSelectChanged = function (value, id) {
                switch (id) {
                    case 'vm.select.businesscoNo':
                        vm.select.businesscoNo = value;
                }
                switch (id) {
                    case 'vm.select.statusId':
                        vm.select.statusId = value;
                }

                remember();
            };

            vm.onSelectedDateChanged = function (fom, tom, bindex) {
                vm.select.dates.from = fom;
                vm.select.dates.to = tom;

                if (bindex >= 0) {
                    vm.select.dates.bindex = bindex;
                }

                remember();
            };

            vm.$onInit = function () {
                load();
            };
        }]
    });
})();
