(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('helpdocttChanged', {
        templateUrl: 'views/components/views/helpdocttChanged/helpdocttChanged.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'helpdocttChangedService', function (utilityService, helpdocttChangedService) {

    		// #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filterval = '' }
                ],
                bgHelpdocttChanged: {
                    selected: 'TODO',
                    buttons: [
                        { id: 'TODO', label: 'bg_helpdocttchanged_todo', color: 'primary', onClick: () => vm.model.bgHelpdocttChanged.selected = 'TODO' },
                        { id: 'CHANGED', label: 'bg_helpdocttchanged_changed', color: 'primary', onClick: () => vm.model.bgHelpdocttChanged.selected = 'CHANGED' }
                    ]
                },
                setting: {},
                selectListLanguages: [],
                itemsListUserDocuments: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS
            
    		// #region PROCEDURE FUNCTION CALLS
            
            var loadP2WHelpdocttChangedSettings = function () {
                helpdocttChangedService.genericFunction(837, {}).then(function (data) {
                    vm.model.setting = angular.copy(data[0]);
    
                    loadP2LanguageList();
                    loadP2WHelpdocttChangedList();
                });
            };
    
            loadP2WHelpdocttChangedSettings();
    
            var loadP2LanguageList = function () {
                helpdocttChangedService.genericFunction(57, {}).then(function (data) {
                    angular.copy(data, vm.model.selectListLanguages);
                });
            };
            
            var loadP2WHelpdocttChangedList = function () {
                if (angular.isDefined(vm.model.setting.language_id) && vm.model.setting.language_id !== '' && angular.isDefined(vm.model.setting.filterval) && angular.isDefined(vm.model.bgHelpdocttChanged.selected) && vm.model.bgHelpdocttChanged.selected !== '') {
                    vm.model.itemsListUserDocuments = [];
    
                    var sendObject = vm.model.setting;
                    sendObject.bg_helpdocttchanged = vm.model.bgHelpdocttChanged.selected;
    
                    helpdocttChangedService.genericFunction(838, sendObject).then(function (data) {
                        angular.copy(data, vm.model.itemsListUserDocuments);
                    });
                }
            };
            
    		// #endregion PROCEDURE FUNCTION CALLS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgHelpdocttChanged = function (value) {
                vm.model.bgHelpdocttChanged.selected = value;

                loadP2WHelpdocttChangedList();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'language_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.language_id) !== true) return;

                        vm.model.setting.language_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.language_id) !== true) return;

                        loadP2WHelpdocttChangedList();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();