(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('excelImport', {
        templateUrl: 'views/components/views/excelImport/excelImport.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['base64', 'utilityService', 'modalService', 'ieScreenBlockService', 'excelImportService', 'p2ImportProcsService', 'p2ExportProcsService', 'typeaheadService', 'watcherFactory', function (base64, utilityService, modalService, ieScreenBlockService, excelImportService, p2ImportProcsService, p2ExportProcsService, typeaheadService, watcherFactory) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
            var watcher = watcherFactory.$create(vm);
            
            vm.model = {
                showUpload: true,
                bgSource: {
                    selected: 'FILE',
                    buttons: [
                        { id: 'FILE', label: 'bg_excelimport_source_file', color: 'primary', onClick: () => vm.model.bgSource.selected = 'FILE' },
                        { id: 'DB', label: 'bg_excelimport_source_db', color: 'primary', onClick: () => vm.model.bgSource.selected = 'DB' }
                    ]
                },
                spreadsheetData: {
                    filename: '',
                    encoding: '',
                    records: []
                },
                spreadsheetHeaders: {},
                setting: {
                    p2_importproc_keyno: '0',
                    p2_exportproc_keyno: '0',
                    encodingtype: ''
                },
                selectListImportProcedures: [],
                selectListExportProcedures: [],
                exportProcedureData: {
                    json: '',
                    records: []
                },
                lockedVerify: true,
                //lockedExport: true,
                exporting: false,
                importing: false
            };
            
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
            
            let loadSetting = function () {
                excelImportService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };
            
            let loadImportProcedures = function () {
                p2ImportProcsService.listImportProcedures().then(function (list) {
                    angular.copy(list, vm.model.selectListImportProcedures);
                });
            };
            
            let loadExportProcedures = function () {
                p2ExportProcsService.listExportProcedures().then(function (list) {
                    angular.copy(list, vm.model.selectListExportProcedures);
                });
            };
            
            var loadP2ImportProcGet = function () {
                if (vm.model.spreadsheetData.filename !== '') {
                    var fc;
                    if (vm.model.spreadsheetData.filename.slice(-5) === '.xlsx') {
                        fc = '';
                    } else {
                        fc = base64.encode(angular.toJson(vm.model.spreadsheetData.records));
                    }
                    excelImportService.genericFunction(880, { filename: vm.model.spreadsheetData.filename, filecontent: fc, origin: 'FILE', getmethod: 'MANUAL' }).then(function (data) {
                        vm.model.setting.p2_importproc_keyno = data[0].p2_importproc_keyno;
                        vm.model.setting.encodingtype = data[0].encodingtype;
                    });
                }
            };

            let retrieveImportProcedure = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_importproc_keyno) !== true) return;

                return excelImportService.getImportProcedure({ p2_importproc_keyno: vm.model.setting.p2_importproc_keyno }).then(function (response) {
                    vm.model.setting.show_table_name_destination = response[0].show_table_name_destination;
                });
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'table_name_destination':
                    case 'table_name_export':
                        vm.model.setting[id] = value;

                        return typeaheadService.lookUpTable({ table_name: vm.model.setting[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'table_name_destination':
                        if (item) {
                            vm.model.setting.table_name_destination = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('table_name_destination');
                        }
                        break;
                    case 'table_name_export':
                        if (item) {
                            vm.model.setting.table_name_export = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('table_name_export');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'table_name_destination':
                        vm.model.setting.table_name_destination = value;

                        if (value) {
                            if (item?.item_name) {
                                vm.model.setting.table_name_destination = item.item_name;
                            }
                        } else {
                            vm.onTypeaheadClear('table_name_destination');
                        }
                        break;
                    case 'table_name_export':
                        vm.model.setting.table_name_export = value;

                        if (value) {
                            if (item?.item_name) {
                                vm.model.setting.table_name_export = item.item_name;
                            }
                        } else {
                            vm.onTypeaheadClear('table_name_export');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'table_name_destination':
                        vm.model.setting.table_name_destination = '';
                        break;
                    case 'table_name_export':
                        vm.model.setting.table_name_export = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgSource = function (value) {
                vm.model.bgSource.selected = value;

                if (vm.model.bgSource.selected === 'FILE') {
                    vm.model.showUpload = true;
                } else if (vm.model.bgSource.selected === 'DB') {
                    if (vm.model.selectListExportProcedures.length === 0) {
                        loadExportProcedures();
                    }

                    vm.model.showUpload = false;
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ######################
            // ## BUTTON FUNCTIONS
            // ######################
            
            vm.importData = function (verify) {
                vm.model.importing = true;
                
                if (vm.model.spreadsheetData.filename !== '') {
                    var stringified = '', order = [], keys = [], k;

                    angular.forEach(vm.model.spreadsheetHeaders, function (h) {
                        order.push(h.value);
                    });

                    keys = Object.keys(vm.model.spreadsheetData.records[0]);

                    keys.sort(function (a, b) {
                        return order.indexOf(a) - order.indexOf(b);
                    });

                    angular.forEach(vm.model.spreadsheetData.records, function (row) {
                        /**
                         * 20240123
                         *
                         * Some spreadsheets could have a full emtpy row, this returns, and removes
                         * this row, preventing the parse and encoding from erroring with undefined values
                         */
                        if (utilityService.isObjectEmpty(row)) {
                            return;
                        }

                        for (var i = 0; i < keys.length; i++) {
                            k = keys[i];
                            stringified += row[k] + '|§|';
                        }

                        stringified = stringified.slice(0, -3);
                        stringified += '|#|';
                    });

                    stringified = stringified.slice(0, -3);

                    excelImportService.genericFunction(876, {
                        p2_importproc_keyno: vm.model.setting.p2_importproc_keyno,
                        table_name_destination: vm.model.setting.table_name_destination,
                        show_table_name_destination: vm.model.setting.show_table_name_destination,
                        filename: vm.model.spreadsheetData.filename,
                        verify: verify,
                        headerdata: base64.encode(angular.toJson(order)),
                        records: base64.encode(stringified)
                        //records: base64.encode(angular.toJson(vm.model.spreadsheetData.records))
                        //records: vm.model.spreadsheetData.records
                    }).then(function (response) {
                        vm.model.importing = false;

                        var ok = 'OK';
                        //var cancel = 'Cancel';

                        if (angular.isDefined(response[0].exec_resultcode) && response[0].exec_resultcode === '-1') {
                            modalService.show({
                                type: 'danger',
                                title: 'Advarsel',
                                message: response[0].exec_message,
                                buttons: [{
                                    label: ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }/*, {
                                    label: cancel,
                                    close: true
                                }*/]
                            });
                        } else {
                            modalService.show({
                                type: 'primary',
                                title: 'Informasjon',
                                message: response[0].exec_message,
                                buttons: [{
                                    label: ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }/*, {
                                    label: cancel,
                                    close: true
                                }*/]
                            });
                        }
                    }, { escape: true, encodedParms: true }).then(function (data) {
                        if (verify === 0)
                            console.log('imported');
                        else if (verify === 1)
                            console.log('verified');
                        else
                            console.log('neither 1 or 0');
                    });
                }
            };
            
            vm.exportGet = function () {
                ieScreenBlockService.start();
                vm.model.exporting = true;
                vm.model.exportProcedureData.json = '';
                
                excelImportService.genericFunction(vm.model.setting.p2_datatask_keyno, {
                    p2_exportproc_keyno: vm.model.setting.p2_exportproc_keyno,
                    searchtext: vm.model.setting.searchtext,
                    show_table_name_export: vm.model.setting.show_table_name_export,
                    table_name_export: vm.model.setting.table_name_export
                }).then(function (data) {
                    angular.copy(data, vm.model.exportProcedureData.records);
                    vm.model.exportProcedureData.json = angular.toJson(vm.model.exportProcedureData.records);
                    //vm.model.exporting = false;
                    ieScreenBlockService.stop();
                });
            };
            
            var getExportDef = function () {
                excelImportService.genericFunction(892, { p2_exportproc_keyno: vm.model.setting.p2_exportproc_keyno }).then(function (data) {
                    vm.model.setting.p2_importproc_keyno = data[0].p2_importproc_keyno;
                    vm.model.setting.p2_datatask_keyno = data[0].p2_datatask_keyno;
                    vm.model.setting.show_table_name_export = data[0].show_table_name_export;
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'p2_importproc_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.p2_importproc_keyno) !== true) return;

                        vm.model.setting.p2_importproc_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.p2_importproc_keyno) !== true) return;

                        if (vm.model.spreadsheetData.records.length === 0 || vm.model.setting.p2_importproc_keyno === '' || vm.model.setting.p2_importproc_keyno === '0') {
                            vm.model.lockedVerify = true;
                        } else {
                            vm.model.lockedVerify = false;
                        }

                        retrieveImportProcedure();
                        break;
                    case 'p2_exportproc_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.p2_exportproc_keyno) !== true) return;

                        vm.model.setting.p2_exportproc_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.p2_exportproc_keyno) !== true) return;

                        if (vm.model.setting.p2_exportproc_keyno === '' || vm.model.setting.p2_exportproc_keyno === '0') {
                            //vm.model.lockedExport = true;
                            return;
                        } else {
                            //vm.model.lockedExport = false;
                            getExportDef();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // ######################
            // ## WATCHES
            // ######################
            
            watcher.$watch(function () {
                return vm.model.spreadsheetData;
            }, function(newValue, oldValue) {
                vm.model.exporting = false;
                if (angular.isUndefined(vm.model.spreadsheetData) || vm.model.spreadsheetData === null || newValue === oldValue) return;
                if (angular.isUndefined(newValue) || newValue === '') return;
                
                if (angular.isDefined(newValue.filename) && newValue.filename !== oldValue.filename && vm.model.bgSource.selected === 'FILE') {
                    loadP2ImportProcGet();
                }

                if (vm.model.spreadsheetData.records.length === 0 || vm.model.setting.p2_importproc_keyno === '' || vm.model.setting.p2_importproc_keyno === '0') {
                    vm.model.lockedVerify = true;
                } else {
                    vm.model.lockedVerify = false;
                }
            }, true);
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadImportProcedures();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
