(function () {
    'use strict';

    var module = angular.module('imApp');

    module.factory('backButtonModel', [function () {
        var hasAnyProperty = function (obj) {
            for (var prop in obj) {
                if (obj.hasOwnProperty(prop)) return true;
            }

            return false;
        }

        // constructor, with class name
        function backButton(data, stateParams) {
            if (angular.isFunction(data.translate)) {
                this.translate = data.translate(stateParams);
            } else {
                this.translate = angular.isDefined(data.translate) ? data.translate : true;
            }
            
            this.text = data.text || '';
            this.textOriginal = this.text;
            this.state = data.state || '';
            this.visible = angular.isDefined(data.visible) ? data.visible : true;
            this.path = data.path || '';
            this.hasParms = false;
            this.parms = angular.isObject(data.parms) ? data.parms : {};
            this.hasParms = hasAnyProperty(this.parms);

            if (this.text.length < 1) {
                if (this.state.length > 0) {
                    this.text = 'p2_back_' + this.state;
                } else {
                    this.text = 'p2_back_default';
                }
            }

            this.fallback = angular.isObject(data.fallback) ? data.fallback : {};
            this.fallback.state = this.fallback.state || 'mainmenu';
            this.fallback.text = this.fallback.text || 'p2_back_fb_' + this.fallback.state;
            this.fallback.parms = angular.isObject(this.fallback.parms) ? this.fallback.parms : {};
            this.fallback.hasParms = hasAnyProperty(this.fallback.parms);

            if (this.fallback.hasParms === true) {
                for (var prop in this.fallback.parms) {
                    if (this.fallback.parms.hasOwnProperty(prop)) {
                        if (angular.isDefined(stateParams[prop])) {
                            this.fallback.parms[prop] = stateParams[prop];
                        }
                    }
                }
            }

            if (this.state.length === 0) {
                this.state = this.fallback.state;
                this.parms = this.fallback.parms;
                this.hasParms = this.fallback.hasParms;
                this.text = this.textOriginal || this.fallback.text;
            }
        };

        // public method
        backButton.prototype.setCachedState = function (cachedState) {
            if (angular.isUndefined(cachedState)) return;
            if (cachedState === null) return;

            this.state = cachedState.name;
            this.path = cachedState.path;
        }

        backButton.build = function (data, stateParams) {
            return new backButton(data, stateParams);
        };

        return backButton;
    }]);
})();