<div class="tt-input__container col-xs-12 sp-0">
    <div class="tt-input__base col-xs-12 sp-0" [class]="class.base" [style]="style.base">
        <label [for]="id.input" class="tt-input__label" [class]="class.label" [style]="style.label">
            {{translations.ttLabel}}
            <span *ngIf="required === true" class="tt-input__label--required" [style]="style.label">*</span>
        </label>

        <div class="tt-input__group" [class]="class.input">
            <input class="tt-input__form-control" [name]="id.input" [id]="id.input" [type]="inputType" [placeholder]="translations.ttPlaceholder" (blur)="onBlur($event)" [inputMode]="inputMode" [maxLength]="maxLength" [style]="style.input" [readonly]="readonly" />
        </div>
    </div>
</div>
