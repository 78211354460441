// ##################################################################
// ## Author: ODL
// ## Description: beskrivelse
// ## Dependencies: 
// ## Mods:       
// ## Date: 15.10.2018
// ------------------------------------------------------------------
//  Modified:   
//   Date:  24. October 2018
//   By:    BJS
//   Desc:  Rewritten everything
// ##################################################################
(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("emailCreateService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            details: function (webpage, argtype, argvalue) {
                var deferred = $q.defer();

                p2DataTaskService.call(898, {
                    webpage: webpage,
                    argtype: argtype,
                    argvalue: argvalue
                }).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            getEmailCreate: function (parms) {
                return $ihttp.post({
                    method: 898,
                    parameters: parms || {}
                });
            },
            webpageReports: function (webpage, argtype, argvalue) {
                return p2DataTaskService.call(899, {
                    webpage: webpage,
                    argtype: argtype,
                    argvalue: argvalue
                });
            },
            listEmailTemplates: function (parms) {
                return $ihttp.post({
                    method: 2901,
                    parameters: parms || {}
                });
            },
            loadEmailCreateGetBefore: function () {
                return p2DataTaskService.call(1121, {});
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            sendEmail: function (parms) {
                return p2DataTaskService.call(3331, parms);
                //return p2DataTaskService.call(901, parms);
            }
        };

        return service;
    }]);
})();
