(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockOutgoing', {
        templateUrl: 'views/components/views/stockOutgoing/stockOutgoing.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockOutgoingService', function ($stateParams, stockOutgoingService) {
            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;
            
            vm.model = {
                itemsListStockOutgoings: []
            };

            let loadStockOutgoings = function () {
                stockOutgoingService.listStockOutgoings({ stock_keyno: stockKeyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockOutgoings)
                });
            };

            vm.$onInit = function () {
                loadStockOutgoings();
            };
        }]
    });
})();
