(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("checkInMenuService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2WCheckInOutPortalUser: {},
            p2WCheckInOutSetting: {},
            loadP2WCheckInOutPortalUser: function (inout, argtype, argvalue, latitude, longitude, accurancy) {
                var deferred = $q.defer();

                var parmsP2WCheckInOutPortalUser = {
                    method: 506,
                    parameters: {
                        inout: inout,
                        argtype: argtype,
                        argvalue: argvalue,
                        latitude: latitude,
                        longitude: longitude,
                        accurancy: accurancy
                    }
                };

                $ihttp.post(parmsP2WCheckInOutPortalUser).then(function (data) {
                    service.p2WCheckInOutPortalUser = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2WCheckInOutSetting: function (argtype, argvalue) {
                var deferred = $q.defer();

                var parmsP2WCheckInOutSetting = {
                    method: 507,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parmsP2WCheckInOutSetting).then(function (data) {
                    service.p2WCheckInOutSetting = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();