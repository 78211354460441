(function () {
    'use strict';

    var module = angular.module("imApp");

    // BJS 20160125 - Enkel service, kan utvides etter behov
    module.factory("modalService", ['$uibModal', '$q', function ($uibModal, $q) {
        var service = {
            preview: function () {

            },

            showNewWebpageModal: function () {
                var deferred = $q.defer();

                var instance = $uibModal.open({
                    component: 'newWebpageModal',
                    resolve: { },
                    size: 'pst-ninety',
                    backdrop: 'static'
                });

                instance.result.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.resolve(response);
                });

                return deferred.promise;
            },

            showNewWebpagelinkModal: function (p2_webpage_keyno_belongto, webpage_name_belongto) {
                var deferred = $q.defer();

                var instance = $uibModal.open({
                    component: 'newWebpagelinkModal',
                    resolve: {
                        p2_webpage_keyno_belongto: function () {
                            return p2_webpage_keyno_belongto || 0;
                        },
                        webpage_name_belongto: function () {
                            return webpage_name_belongto || '';
                        }
                    },
                    size: 'pst-ninety',
                    backdrop: 'static'
                });

                instance.result.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.resolve(response);
                });

                return deferred.promise;
            },

            show: function (config) {
                var deferred = $q.defer();

                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/modal/message.html?v=' + module.version,
                    controller: ['$scope', function ($scope) {
                        $scope.model = {
                            type: 'type-' + config.type,
                            title: config.title,
                            message: config.message,
                            buttons: config.buttons,
                            cssClass: config.cssClass
                        };

                        $scope.buttonClicked = function (index) {
                            if (angular.isDefined(config.buttons[index].close) && (config.buttons[index].close === true)) {
                                $scope.close();

                                deferred.resolve();
                            } else {
                                if (angular.isFunction(config.buttons[index].action)) {
                                    config.buttons[index].action(modalInstance);
                                }
                            }
                        };

                        $scope.close = function () {
                            modalInstance.close();

                            deferred.resolve();
                        };
                    }]
                });

                return deferred.promise;
            },

            confirm: function (config) {
                var deferred = $q.defer();

                config = config || {};
                config.title = config.title || 'Confirm';
                config.message = config.message || '';
                config.type = config.type || 'warning';
                config.okLabel = config.okLabel || 'Ok';
                config.cancelLabel = config.cancelLabel || 'Cancel';

                service.show({
                    title: config.title,
                    message: config.message,
                    type: config.type,
                    buttons: [
                        {
                            label: config.okLabel,
                            action: function (dialogInstance) {
                                deferred.resolve();
                                dialogInstance.close();
                            }
                        },
                        {
                            label: config.cancelLabel,
                            action: function (dialogInstance) {
                                deferred.reject();
                                dialogInstance.close();
                            }
                        }
                    ]
                });

                return deferred.promise;
            },

            // ###############################
            // #endregion base
            // ###############################

            // ###############################
            // #region spinner
            // ###############################

            //showSpinner: function (field) {
            //    var deferred = $q.defer();
            //    var modalInstance = $uibModal.open({
            //        backdrop: 'static',
            //        animation: false,
            //        template:
            //        //'<div class="well well-xs" style="height: 200px">' +
            //        //'<div class="well well-xs">' + //
            //        //'<div class="">' +
            //        '<div class="" style="text-align: center;background: transparent;border: none;box-shadow: none;position: relative;">' +
            //        //'<div class="col-xs-6">' +
            //            '<span class="glyphicon glyphicon-refresh glyphicon-refresh-animate" on-enter="model.onEnter" style="font-size: 50px"></span>' +
            //        '</div>',
            //        resolve: {
            //            field: function () {
            //                return field;
            //            }
            //        },
            //        controller: ['$scope', '$uibModalInstance', 'field', function ($scope, $uibModalInstance, field) {
            //            $scope.model = {
            //                //label: label,
            //                //plabel: plabel,
            //                field: field,
            //                //type: 'type-default',
            //                onEnter: function (value) {
            //                    $uibModalInstance.close();
            //                    deferred.resolve(value);
            //                }
            //            };
            //        }]
            //    });
            //    return deferred.promise;
            //},

            // ###############################
            // #endregion spinner
            // ###############################

            // ###############################
            // #region calculator
            // ###############################

            showCalculator: function (label, plabel, field) {
                var deferred = $q.defer();

                var modalInstance = $uibModal.open({
                    backdrop: true,
                    animation: true,
                    template:
                        '<div class="well well-xs">' +
                        '<im-numpad-directive label="model.label" plabel="model.plabel" field="model.field" on-enter="model.onEnter"></im-numpad-directive>' +
                        '</div>',
                    resolve: {
                        field: function () {
                            return field;
                        }
                    },
                    controller: ['$scope', '$uibModalInstance', 'field', function ($scope, $uibModalInstance, field) {
                        $scope.model = {
                            label: label,
                            plabel: plabel,
                            field: field,
                            type: 'type-default',
                            onEnter: function (value) {
                                $uibModalInstance.close();

                                deferred.resolve(value);
                            }
                        };
                    }]
                });

                return deferred.promise;
            },

            // ###############################
            // #endregion calculator
            // ###############################

            // ###############################
            // #region signature
            // ###############################

            showSignaturePad: function (field) {
                var deferred = $q.defer();

                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    keyboard: false,
                    template:
                        '<div class="well well-xs">' +
                            '<div class="modal-body">' +
                                '<signature-pad accept="accept" clear="clear" height="254" width="568" disabled="false" dataurl="model.field"></signature-pad>' +
                            '</div>' +
                            '<div class="modal-footer">' +
                                '<button class="btn btn-primary pull-left" ng-click="clear()"><i class="glyphicon glyphicon-erase"></i></button>' +
                                '<button class="btn btn-danger" ng-click="$dismiss()"><i class="glyphicon glyphicon-remove"></i></button>' +
                                '<button class="btn btn-success" ng-click="model.onDone()"><i class="glyphicon glyphicon-ok"></i></button>' +
                            '</div>' +
                        '</div>',
                    resolve: {
                        field: function () {
                            return field;
                        }
                    },
                    controller: ['$scope', '$uibModalInstance', 'field', function ($scope, $uibModalInstance, field) {
                        $scope.model = {
                            field: field,
                            onDone: function () {
                                var signature = $scope.accept();

                                if (angular.isUndefined(signature.dataUrl) || signature.isEmpty) {
                                    $uibModalInstance.dismiss();

                                    deferred.resolve('');
                                } else {
                                    $uibModalInstance.close(signature.dataUrl);

                                    deferred.resolve(signature.dataUrl);
                                }
                            }
                        };
                    }]
                });

                return deferred.promise;
            },

            // ###############################
            // #endregion signature
            // ###############################

            // ###############################
            // #region progress
            // ###############################

            progressInfo: {
                label: '',
                plabel: '',
                field: '',
                remField: '',
                min: 0,
                max: 100,
                step: 0,
                finishclose: ''
            },

            showProgress: function (progInfo) {
                var deferred = $q.defer();

                service.progressInfo.label = progInfo.label || '';
                service.progressInfo.plabel = progInfo.plabel || '';
                service.progressInfo.field = progInfo.field || '';
                service.progressInfo.remField = progInfo.remField || '';
                service.progressInfo.min = progInfo.min || 0;
                service.progressInfo.max = progInfo.max || 100;
                service.progressInfo.step = progInfo.step || 0;
                service.progressInfo.finishclose = progInfo.finishclose || '';
                service.progressInfo.abortclose = progInfo.abortclose || false;

                var modalInstance = $uibModal.open({
                    backdrop: 'static',
                    animation: true,
                    keyboard: false,
                    //canClose: false,
                    template:
                        '<div class="well well-xs">' +
                            '<im-progress-directive label="{{model.label}}" plabel="model.plabel" '+
                                                    'field="model.field" rem-field="model.remField" '+
                                                    'min="model.min" max="model.max" step="model.step" '+
                                                    'finishclose="model.finishclose" abortclose="model.abortclose" on-close="model.onClose">'+
                            '</im-progress-directive>' +
                        '</div>',
                    resolve: {
                        field: function () {
                            return progInfo.field;
                        }
                    },
                    controller: ['$scope', '$uibModalInstance', 'field', function ($scope, $uibModalInstance, field) {
                        $scope.model = service.progressInfo;
                        $scope.model.onClose = function (value) {
                            $uibModalInstance.close();

                            deferred.resolve(value);
                        };
                    }]
                });

                return deferred.promise;
            },

            // ###############################
            // #endregion progress
            // ###############################

            // ###############################
            // #region grid
            // ###############################

            gridColumnsModal: function (grid, mode) {
                var deferred = $q.defer();

                if (angular.isUndefined(mode)) mode = 'all';

                var modes = {
                    active: '',
                    simple: false,
                    all: false,
                    colShowHide: false,
                    colEdit: false,
                    colLock: false
                }

                if (angular.isDefined(modes[mode])) {
                    modes.simple = true;
                    modes.active = mode;
                    if(mode !== 'colShowHide')
                        modes[mode] = true;
                }

                //var simple = false;
                //if (mode === ('all' || 'showHide' || 'colEdit' || 'colLock')) {
                //    simple = true;
                //}

                var header = '';
                var cols = grid.getOptions().columns;

                //var model 
                //var colsDetails = [];
                var hiddenCols = [];
                var shownCols = [];
                var shownAll = true;

                angular.forEach(cols, function (col) {
                    //var visible = true;
                    if (angular.isDefined(col.hidden) && col.hidden === true) {
                        //if (mode !== 'colShowHide') {
                        //    visible = false;
                        //}
                        hiddenCols.push(col);
                        shownAll = false;
                    } else {
                        shownCols.push(col);
                    }
                    //colsDetails.push({ field: col.field, visible: visible, checked: visible });
                });

                //var backdrop = 'static';
                //var templateUrl = 'views/modal/showHideColumns.html';

                //var backdrop = mode === 'simple' ? 'views/modal/showHideColumnsSimple.html' : 'views/modal/showHideColumns.html';

                //if (mode === 'simple') {
                //    backdrop = 
                //}

                var modalInstance = $uibModal.open({
                    //backdrop: 'static',
                    //backdrop: true,
                    backdrop: modes.simple === true ? true : 'static',
                    animation: true,
                    keyboard: false,
                    //canClose: false,
                    //templateUrl: 'views/modal/showHideColumns.html',
                    templateUrl: modes.simple === true ? 'views/modal/gridColumnsModalSimple.html?v=' + module.version : 'views/modal/gridColumnsModalSwap.html?v=' + module.version,
                    //template:
                    //'<div class="well well-xs">' +
                    //    '<div on-close="model.onClose"></div>' +
                    //'</div>',
                    //resolve: {
                    //    cols: function () {
                    //        console.log('resolve');
                    //        return {
                    //            cols: cols,
                    //            shown: shownCols,
                    //            hidden: hiddenCols
                    //        };
                    //    }
                    //},
                    controller: ['$scope', '$uibModalInstance', 'translateService', function ($scope, $uibModalInstance, translateService) {
                        $scope.model = {
                            modes: modes,
                            header: header,
                            cols: cols,
                            shown: shownCols,
                            hidden: hiddenCols,
                            selected: { all: shownAll, shown: [], hidden: [] },
                            //details: colsDetails,
                            viewHidden: false
                        };
                        $scope.close = function () {
                            //console.log('close');
                            modalInstance.close();

                            deferred.resolve($scope.model);
                        };

                        $scope.model.filter = '';
                        $scope.model.filterplaceholder = 'filterplaceholder';

                        translateService.translate('filterplaceholder').then(function (response) {
                            $scope.model.filterplaceholder = response;
                        });

                        // #######################################
                        // #region functions gridColumns Simple
                        // #######################################

                        $scope.isVisible = function (col) {
                            if (modes.active === 'colShowHide') {
                                if (col.field === 'grid_empty_col' || col.field === 'grid_select_col') {
                                    return false;
                                } else 
                                    return true;
                            } else {
                                if ($scope.model.viewHidden === true) {
                                    return true;
                                } else {
                                    if (col.hidden === true) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                }
                            }
                        };

                        $scope.isFiltered = function (col) {
                            var fieldVal = angular.isDefined(col.field) ? col.field.toLowerCase() : '';
                            var titleVal = angular.isDefined(col.title) ? col.title.toLowerCase() : '';
                            var fltr = $scope.model.filter.toLowerCase();

                            if (fieldVal.includes(fltr) || titleVal.includes(fltr)) {
                                return true;
                            } else
                                return false;
                        };

                        $scope.isObscure = function (col) {
                            if (modes.active !== 'colShowHide') {
                                if ($scope.model.viewHidden === true) {
                                    if (col.hidden === true) {
                                        return {
                                            "opacity": "40%",
                                        }
                                    }
                                }
                            }
                        };

                        $scope.isListChecked = function (cols) {
                            var hasUnchecked = false;

                            angular.forEach(cols, function (col) {
                                if (hasUnchecked) return;
                                //if (angular.isDefined(col.hidden) && col.hidden === true) {
                                //    hasUnchecked = true;
                                //}
                                angular.forEach(col, function (value, key) {
                                    if (hasUnchecked || (key !== 'field' && key !== 'title') || typeof value !== 'string') return;
                                    //if (angular.isObject(value) || hasUnchecked || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                                    var val = value.toLowerCase();
                                    var fltr = $scope.model.filter.toLowerCase();

                                    if (val.includes(fltr)) {
                                        //if (angular.isUndefined(col.hidden) || col.hidden === true) {
                                        //    hasUnchecked = true;
                                        //}
                                        if (angular.isDefined(col.hidden) && col.hidden === true) {
                                            hasUnchecked = true;
                                        }
                                    }
                                });
                            });

                            //data.hasUnchecked = hasUnchecked;

                            return !hasUnchecked;
                        };

                        $scope.checkChecked = function (col) {
                            if (modes.active === 'colShowHide') {
                                if (col.hidden === true) {
                                    return false;
                                } else {
                                    return true;
                                }
                            } if (modes.active === 'colEdit') {
                                var fields = grid.getOptions().dataSource.schema.model.fields;
                                var editable = angular.isDefined(fields[col.field]) === true ? fields[col.field].editable : undefined;
                                if (angular.isUndefined(editable)) editable = false;
                                return editable;
                            } else {
                                return true;
                            }
                        };

                        $scope.viewHidden = function () {
                            $scope.model.viewHidden = !$scope.model.viewHidden;
                        };

                        $scope.rowClickedAllSimple = function () {
                            if (modes.active === 'colShowHide') {
                                if ($scope.model.selected.all) {
                                    angular.forEach(cols, function (col) {
                                        grid.hideColumn(col.field);
                                    });
                                    $scope.model.selected.all = false;
                                } else {
                                    angular.forEach(cols, function (col) {
                                        grid.showColumn(col.field);
                                    });
                                    $scope.model.selected.all = true;
                                }

                                $scope.model.cols = grid.getOptions().columns;
                            } else {
                                event.preventDefault();
                            }
                        };

                        $scope.rowClickedSimple = function (col) {
                            if (modes.active === 'colShowHide') {
                                if (col.hidden === true) {
                                    grid.showColumn(col.field);
                                } else {
                                    grid.hideColumn(col.field);
                                }

                                $scope.model.cols = grid.getOptions().columns;
                            } else {
                                event.preventDefault();
                            }
                        };

                        // #######################################
                        // #endregion functions gridColumns Simple
                        // #######################################

                        // #######################################
                        // #region functions gridColumns Swap
                        // #######################################

                        $scope.isSelected = function (index, shownSide) {
                            var allClass = '';

                            if (shownSide) {
                                for (var s = 0; s < $scope.model.selected.shown.length; s++) {
                                    if ($scope.model.selected.shown[s] === index) {
                                        allClass = 'im-grid-cols-modal-row-selected';
                                        break;
                                    }
                                }
                            } else {
                                for (var h = 0; h < $scope.model.selected.hidden.length; h++) {
                                    if ($scope.model.selected.hidden[h] === index) {
                                        allClass = 'im-grid-cols-modal-row-selected';
                                        break;
                                    }
                                }
                            }

                            return allClass;
                        };
                        $scope.rowClicked = function (index, shownSide) {
                            var containsIndex = false;
                            if (shownSide) {
                                for (var s = 0; s < $scope.model.selected.shown.length; s++) {
                                    if ($scope.model.selected.shown[s] === index) {
                                        $scope.model.selected.shown.splice(s, 1);
                                        containsIndex = true;
                                        break;
                                    }
                                }
                                if (!containsIndex) {
                                    $scope.model.selected.shown.push(index);
                                }
                            } else {
                                for (var h = 0; h < $scope.model.selected.hidden.length; h++) {
                                    if ($scope.model.selected.hidden[h] === index) {
                                        $scope.model.selected.hidden.splice(h, 1);
                                        containsIndex = true;
                                        break;
                                    }
                                }
                                if (!containsIndex) {
                                    $scope.model.selected.hidden.push(index);
                                }
                            }

                            //if (angular.isDefined(config.buttons[index].close) && (config.buttons[index].close === true)) {
                            //    $scope.close();
                            //} else {
                            //    if (angular.isFunction(config.buttons[index].action)) {
                            //        config.buttons[index].action(modalInstance);
                            //    }
                            //}
                        };
                        $scope.showAll = function () {
                            for (var i = $scope.model.hidden.length - 1; i >= 0; i--) {
                                grid.showColumn($scope.model.hidden[i].field);
                                $scope.model.shown.push($scope.model.hidden[i]);
                                $scope.model.hidden.splice(i, 1);
                            }
                            $scope.model.selected.shown.splice(0, $scope.model.selected.shown.length);
                            $scope.model.selected.hidden.splice(0, $scope.model.selected.hidden.length);
                        };
                        $scope.swap = function () {
                            $scope.model.selected.hidden.sort(function (a, b) { return b - a });
                            $scope.model.selected.shown.sort(function (a, b) { return b - a });
                            angular.forEach($scope.model.selected.hidden, function (i) {
                                grid.showColumn($scope.model.hidden[i].field);
                                $scope.model.shown.push($scope.model.hidden[i]);
                                $scope.model.hidden.splice(i, 1);
                            });
                            angular.forEach($scope.model.selected.shown, function (i) {
                                grid.hideColumn($scope.model.shown[i].field);
                                $scope.model.hidden.push($scope.model.shown[i]);
                                $scope.model.shown.splice(i, 1);
                            });
                            //$scope.model.hidden.sort();
                            //$scope.model.shown.sort();
                            $scope.model.selected.shown.splice(0, $scope.model.selected.shown.length);
                            $scope.model.selected.hidden.splice(0, $scope.model.selected.hidden.length);
                            //$scope.model.selected = { shown: [], hidden: [] };
                        };
                        $scope.hideAll = function () {
                            for (var i = $scope.model.shown.length - 1; i >= 0; i--) {
                                grid.showColumn($scope.model.shown[i].field);
                                $scope.model.hidden.push($scope.model.shown[i]);
                                $scope.model.shown.splice(i, 1);
                            }
                            $scope.model.selected.shown.splice(0, $scope.model.selected.shown.length);
                            $scope.model.selected.hidden.splice(0, $scope.model.selected.hidden.length);
                        };

                        // #######################################
                        // #endregion functions gridColumns Swap
                        // #######################################
                    }]
                });

                return deferred.promise;
            },

            // ###############################
            // #endregion grid
            // ###############################

            // ###############################
            // #region favorites
            // ###############################

            showFavorites: function (header, list) {
                var deferred = $q.defer();

                angular.forEach(list, function (item) {
                    item.willGetDeleted = false;
                });

                var modalInstance = $uibModal.open({
                    //backdrop: 'static',
                    backdrop: true,
                    animation: true,
                    keyboard: false,
                    //canClose: false,
                    templateUrl: 'views/modal/favoritesModal.html?v=' + module.version,
                    controller: ['$scope', '$uibModalInstance', 'translateService', 'stateService', function ($scope, $uibModalInstance, translateService, stateService) {
                        $scope.model = {
                            header: header || {},
                            list: list || [],
                            addedFavorite: false,
                            path: { }
                        };

                        if ($scope.model.header.translate === true) {
                            translateService.translate($scope.model.header.text).then(function (response) {
                                $scope.initializeValues(response);
                            });
                        } else {
                            $scope.initializeValues($scope.model.header.text);
                        }

                        $scope.initializeValues = function (displayText) {
                            $scope.model.header.displayText = displayText;
                            $scope.model.path.state = stateService.getCurrentName();
                            $scope.model.path.parms = stateService.getCurrentPath().slice($scope.model.path.state.length + 2);

                            for (var i = 0; i < $scope.model.list.length; i++) {
                                if ($scope.model.list[i].item_name === $scope.model.header.displayText) {
                                    $scope.model.addedFavorite = true;
                                    break;
                                }
                            }
                        }

                        $scope.close = function () {
                            modalInstance.close();

                            deferred.resolve($scope.model);
                        };

                        $scope.addToFavorites = function () {
                            $scope.model.addedFavorite = true;

                            $scope.model.list.unshift({
                                item_name: $scope.model.header.displayText,
                                item_state: $scope.model.path.state,
                                item_parms: $scope.model.path.parms,
                                orderby: 0,
                                willGetDeleted: false
                            });
                        };

                        $scope.toggleDeleteFromFavorites = function (e, item) {
                            item.willGetDeleted = !item.willGetDeleted;
                            e.stopPropagation();
                        };

                        $scope.goTo = function (e, item) {
                            stateService.go(item.item_state, item.item_parms);
                            $scope.close();
                        };

                        $scope.goTab = function (e, item) {
                            e.stopPropagation();

                            stateService.newTab(item.item_state, item.item_parms);
                            $scope.close();
                        };

                        $scope.$on('modal.closing', function (event, reason) {
                            switch (reason) {
                                case "backdrop click":
                                case "escape key press":
                                case "cancel":
                                    event.preventDefault();
                                    $scope.close();
                                    break;
                            }
                        });
                    }]
                });

                return deferred.promise;
            }

            // ###############################
            // #endregion favorites
            // ###############################
        };

        return service;
    }]);
})();
