(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('forecast', {
        templateUrl: 'views/components/views/forecast/forecast.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'forecastService', function($stateParams, stateService, forecastService) {
            var vm = this;
            
            vm.model = {
                p2ForecastGet: {},
                p2ForecastResolutionList: {
                    records: []
                },
                p2ForecastTypeList: {
                    records: []
                }
            };
            
            var loadP2ForecastGet = function () {
                forecastService.genericFunction(856, {
                    forecast_keyno: $stateParams.forecast_keyno
                }).then(function (data) {
                    vm.model.p2ForecastGet = angular.copy(data[0]);
                });
            };
            
            loadP2ForecastGet();
            
            var loadP2ForecastResolutionList = function () {
                forecastService.genericFunction(857, {}).then(function (data) {
                    angular.copy(data, vm.model.p2ForecastResolutionList.records);
                });
            };
            
            loadP2ForecastResolutionList();
            
            var loadP2ForecastTypeList = function () {
                forecastService.genericFunction(858, {}).then(function (data) {
                    angular.copy(data, vm.model.p2ForecastTypeList.records);
                });
            };
            
            loadP2ForecastTypeList();
            
            vm.saveP2Forecast = function () {
                forecastService.genericFunction(859, vm.model.p2ForecastGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();