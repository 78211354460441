(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businessS', {
        templateUrl: 'views/components/views/businessS/businessS.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['stateService', 'businessSService', function (stateService, businessSService) {
			let vm = this;

            vm.model = {
                itemsListBusinessViews: []
            };

            let loadBusinesses = function () {
                businessSService.listBusinessViews().then(function (result) {
                    angular.copy(result, vm.model.itemsListBusinessViews);
                });
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'business':
                        go({ business_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadBusinesses();
            };
		}]
	});
})();
