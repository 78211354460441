(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('consignmentDeliveryActivity', {
        templateUrl: 'views/components/views/consignmentDeliveryActivity/consignmentDeliveryActivity.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'stateService', 'fileService', 'docarcService', 'consignmentDeliveryActivityService', function ($q, $stateParams, stateService, fileService, docarcService, consignmentDeliveryActivityService) {
            var vm = this;

            function getSettings() {
                consignmentDeliveryActivityService.get($stateParams.custact_no, $stateParams.order_order_no).then(function (data) {
                    angular.copy(data[0], vm.model.setting);
                });
            }

            function getActivities() {
                consignmentDeliveryActivityService.load($stateParams.custact_no, $stateParams.order_order_no).then(function (data) {
                    angular.copy(data, vm.model.consignmentDeliveryActivity.list);
                });
            }

            function handleConfirm() {
                if (vm.model.setting.checklist_keyno > 0 && vm.model.setting.checklistdata_keyno == 0) {
                    consignmentDeliveryActivityService.confirm($stateParams.custact_no, vm.model.setting.checklist_keyno).then(function (data) {
                        angular.copy(data[0], vm.model.checklist);
                        stateService.go('consignmentdeliveryconfirm', {
                            checklistdata_keyno: vm.model.checklist.parms,
                            custact_no: $stateParams.custact_no, 
                            order_order_no: $stateParams.order_order_no
                        });
                    });
                } else {
                    stateService.go('consignmentdeliveryconfirm', {
                        checklistdata_keyno: vm.model.setting.checklistdata_keyno,
                        custact_no: $stateParams.custact_no, 
                        order_order_no: $stateParams.order_order_no
                    });
                }
            }

            function handleListClick() {
                // Not Implemented
            }

            function handlePictureClick() {
                stateService.go('pictures', {
                    argtype: 'custact_no',
                    argvalue: $stateParams.custact_no
                });
            }

            vm.model = {
                setting: {
                    heading: '',
                    checklist_keyno: 0,
                    checklistdata_keyno: 0,
                    is_confirmed: 0
                },
                checklist: {},
                consignmentDeliveryActivity: {
                    signature: '',
                    list: []
                },
                handleConfirm: handleConfirm,
                handleListClick: handleListClick,
                handlePictureClick: handlePictureClick
            }

            vm.uploadFile = function (file) {
                var addToDocarc = function (data) {
                    return docarcService.add({
                        contentType: data.contentType || '',
                        filename: data.fileName,
                        localFileName: data.localFileName || '',
                        originalFileName: data.originalFileName || '',
                        originalDateTime: data.originalDateTime || '',
                        argType: 'custact_no',
                        argValue: $stateParams.custact_no,
                        size: data.size || 0,
                        checksum: data.checksum || '',
                        latitude: data.latitude || 0,
                        longitude: data.longitude || 0,
                        altitude: data.altitude || 0,
                        heading: data.heading || 0,
                        checksum_md5: data.checksum_md5 || 0
                    }, {
                        checksum_md5: data.checksum_md5
                    });
                };

                fileService.uploadFiles({
                    files: [file],
                    preprocess: function (fileName, file) {
                        var ppDeferred1 = $q.defer();

                        fileService.hash(file).then(function (response) {
                            docarcService.exists(fileName, response.hash, '', '').then(function (data) {
                                ppDeferred1.resolve({ cancelUpload: data.exists });
                            });
                        });

                        return ppDeferred1.promise;
                    },
                    postprocess: function (data) {
                        var ppDeferred2 = $q.defer();

                        if (data.fileItemKeyno > 0) {
                            addToDocarc(data).then(function () {
                                ppDeferred2.resolve();
                            });
                        } else {
                            if (data.isError === true) {
                                // ØDL endra frå console til consoleService 28.08.2018
                                consoleService.log(data.message);
                                consoleService.dir(data);
                            }

                            ppDeferred2.resolve();
                        }

                        return ppDeferred2.promise;
                    }
                }).then(function(){
                    console.log('upload complete')    
                    stateService.go('consignmentdeliveryactivity',{
                        custact_no: $stateParams.custact_no,
                        order_order_no: $stateParams.order_order_no
                    },{ reload: true, inherit: false });
                }).catch(function(){
                    console.log('upload cancelled or')    
                    stateService.go('consignmentdeliveryactivity',{
                        custact_no: $stateParams.custact_no,
                        order_order_no: $stateParams.order_order_no
                    },{ reload: true, inherit: false });
                });
            };

            getSettings();
            getActivities();
        }]
    });
})();
