(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srRefundCreateService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrRefundCreateGet: function (custact_no, checklistdata_keyno) {
                return p2DataTaskService.call(1609, {
                    custact_no: custact_no,
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrRefundCreateResourceList: function (custact_no, checklistdata_keyno) {
                return p2DataTaskService.call(1611, {
                    custact_no: custact_no,
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrRefundCreateCustomerGet: function (resource_id, custact_no) {
                return p2DataTaskService.call(1612, {
                    resource_id: resource_id,
                    custact_no: custact_no
                });
            },
            loadSrRefundCreateItemList: function (createRepayment) {
                return p2DataTaskService.call(1610, createRepayment);
            },
            createSrRefund: function (createRepayment) {
                return p2DataTaskService.call(1608, createRepayment);
            }
        };

        return service;
    }]);
})();