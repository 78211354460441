(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businessSites', {
		templateUrl: 'views/components/views/businessSites/businessSites.template.html?v=' + module.version, 
		controllerAs: 'vm',
        controller: ['stateService', 'economyService', function (stateService, economyService) {

            // #region VARIABLES & DEFINITIONS

			const vm = this;

            vm.model = {
                itemsListBusinessSites: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadBusinessSites = function () {
                economyService.listBusinessSites().then(function (list) {
                    angular.copy(list, vm.model.itemsListBusinessSites);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'businesssite':
                        go({ businesssite_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadBusinessSites();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
