(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('payCheckBatch', {
        templateUrl: 'views/components/views/payCheckBatch/payCheckBatch.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'modalService', 'translateService', 'payCheckBatchService', 'logisticService', 'ttGridFactory', function ($stateParams, stateService, base64, modalService, translateService, payCheckBatchService, logisticService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            let translations = {
                error: '',
                ok: ''
            };

    		vm.model = {
    			payCheckBatchKeyno: $stateParams.paycheckbatch_keyno,
                bgPayCheckBatchMain: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'gb_paycheckbatch_main_basic', color: 'primary', onClick: () => setPayCheckBatchMainView('BASIC') },
                        { id: 'PAYCHECKS', label: 'gb_paycheckbatch_main_paychecks', color: 'primary', onClick: () => setPayCheckBatchMainView('PAYCHECKS') },
                        { id: 'SALARYTOEXCEL', label: 'gb_paycheckbatch_main_salarytoexcel', color: 'primary', onClick: () => setPayCheckBatchMainView('SALARYTOEXCEL') },
                        { id: 'EXCEL', label: 'gb_paycheckbatch_main_excel', color: 'primary', onClick: () => setPayCheckBatchMainView('EXCEL') },
                        { id: 'FUNCTIONS', label: 'gb_paycheckbatch_main_functions', color: 'primary', onClick: () => setPayCheckBatchMainView('FUNCTIONS') }
                    ]
                },
                edit: {},
    			selectListCompanies: [],
                itemsListPayChecks: [],
                gridSpecificationsReady: false,
                gridAccountingSystemsReady: false,
    			loadingExport: false,
    			updatingPayCheck: false,
    			lockedSave: false
    		};

            vm.gridSpecifications = new ttGridFactory({
                rememberId: 'paycheckbatch_spesification_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            vm.gridAccountingSystems = new ttGridFactory({
                rememberId: 'w_paycheckbatch_to_accountingsystem_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPayCheckBatchMainView = function (id) {
                vm.model.bgPayCheckBatchMain.selected = vm.model.edit.gb_paycheckbatch_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// ## LOAD PROCEDURE CALLS ##
            
    		payCheckBatchService.loadPayCheckBatchGet(vm.model.payCheckBatchKeyno).then(function (data) {
    			vm.model.edit = angular.copy(data[0]);
                setPayCheckBatchMainView(vm.model.edit.gb_paycheckbatch_main);
                
                loadPayChecks();
                loadGridSpecifications();
                loadGridAccountingSystems();
    		});
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
    		var loadPayChecks = function () {
    			if (angular.isUndefined(vm.model.edit.paycheckbatch_keyno) === true) return;
    			if (vm.model.edit.paycheckbatch_keyno === null) return;
    			if (vm.model.edit.paycheckbatch_keyno.trim().length < 1) return;
    
    			vm.model.itemsListPayChecks = [];
    
    			payCheckBatchService.loadPayCheckList(vm.model.edit.paycheckbatch_keyno).then(function (data) {
    				angular.copy(data, vm.model.itemsListPayChecks);
    			});
    		};
    
    		var updatePayCheck = function () {
    			if (angular.isUndefined(vm.model.edit.paycheckbatch_keyno) === true) return;
    			if (vm.model.edit.paycheckbatch_keyno === null) return;
    			if (vm.model.edit.paycheckbatch_keyno.trim().length < 1) return;
                
    			vm.model.updatingPayCheck = true;
                
                payCheckBatchService.update(vm.model.edit.paycheckbatch_keyno).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingPayCheck = false;
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        vm.model.updatingPayCheck = false;
                        loadPayChecks();
                    }
    			});
    		};

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridSpecifications = function () {
                vm.gridSpecifications.dataTask.loadData = {
                    method: 3442,
                    parameters: vm.model.edit
                };

                vm.model.gridSpecificationsReady = true;

                if (vm.gridSpecifications.gridfunc !== null) vm.gridSpecifications.gridfunc.read();
            };

            let loadGridAccountingSystems = function () {
                vm.gridAccountingSystems.dataTask.loadData = {
                    method: 3443,
                    parameters: vm.model.edit
                };

                vm.model.gridAccountingSystemsReady = true;

                if (vm.gridAccountingSystems.gridfunc !== null) vm.gridAccountingSystems.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

    		// ## REMEMBER VALUE FUNCTIONS ##
            
    		var canRun616 = function (variableName, parm) {
    			if (angular.isUndefined(variableName) === true) return false;
    			if (variableName === null) return false;
    			if (variableName === '') return false;
    			if (angular.isUndefined(vm.model.edit[parm]) === true) return false;
    			if (vm.model.edit[parm] === null) return false;
    
    			return true;
    		};
    
    		var rememberGbPayCheckBatchMain = function () {
    			var variableNameGbPayCheckBatchMain = 'w_paycheckbatch.gb_paycheckbatch_main';
    
    			if (canRun616(variableNameGbPayCheckBatchMain, 'gb_paycheckbatch_main') !== true) return;
    
    			payCheckBatchService.remember(variableNameGbPayCheckBatchMain, vm.model.edit.gb_paycheckbatch_main);
    		};
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPayCheckBatchMain = function (value) {
                vm.model.bgPayCheckBatchMain.selected = value;
                vm.model.edit.gb_paycheckbatch_main = vm.model.bgPayCheckBatchMain.selected;

                rememberGbPayCheckBatchMain();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// ## UPDATE BUTTON FUNCTION ##
            
    		vm.exportToPowerOffice = function () {
    			vm.model.loadingExport = true;
                
                payCheckBatchService.exportTo(vm.model.edit.paycheckbatch_keyno).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingExport = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.loadingExport = false;
                        var elem = document.getElementById('formPayCheckBatchLink');
                        var text = base64.urldecode(response[0].filecontent);

                        var txtData = new Blob([text], { type: 'text/txt' });
                        var txtUrl = URL.createObjectURL(txtData);

                        elem.setAttribute('href', txtUrl);
                        elem.setAttribute('download', response[0].filename);
                        elem.click();
                    }
                });
    		};
            
    		// ## UPDATE BUTTON FUNCTION ##
    
            vm.updateChanges = function () {
    			if (vm.model.lockedSave === true) {
    				return;
    			}
    			
    			vm.model.lockedSave = true;
                
                payCheckBatchService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        updatePayCheck();
                    }
                });
    		};

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'paycheckbatch_keyno',
                            argvalue: vm.model.edit.paycheckbatch_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                payCheckBatchService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
