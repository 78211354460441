(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poMenu', {
        templateUrl: 'views/components/views/poMenu/poMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'poMenuService', function ($stateParams, stateService, poMenuService) {
            var vm = this;
            var poKeyno = $stateParams.po_keyno;

            vm.model = {
                menu: {}
            };

            poMenuService.getProductionOrderMenu({ po_keyno: poKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.menu);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'pofinishprod':
                        stateService.go(state, {
                            po_keyno: vm.model.menu.po_keyno,
                            p2_pofinishprod_keyno: vm.model.menu.p2_pofinishprod_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();