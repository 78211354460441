(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('listDataLang', {
        templateUrl: 'views/components/views/listDataLang/listDataLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'listDataLangService', function ($stateParams, stateService, listDataLangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var listDataKeyno = $stateParams.listdata_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            listDataLangService.listDataLanguages({ listdata_keyno: listDataKeyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                listDataLangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();