(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('srExpenses', {
        templateUrl: 'views/components/views/srExpenses/srExpenses.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'utilityService', 'rememberService', 'srExpensesService', 'watcherFactory', function($stateParams, $timeout, $q, stateService, utilityService, rememberService, srExpensesService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
    		// ## VARIABLES & DEFINITIONS ##
    
    		vm.model = {
    			argType: $stateParams.argtype,
    			argValue: $stateParams.argvalue,
                bgSrExpensesMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '',
                    records: [
    					{ item_id: '0', item_name: 'bg_srexpenses_main_treatment', item_func: 'TREATMENT' },
    					{ item_id: '1', item_name: 'bg_srexpenses_main_waiting', item_func: 'WAITING' },
    					{ item_id: '2', item_name: 'bg_srexpenses_main_finished', item_func: 'FINISHED' }
                    ]
    			},
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
    			setting: {},
    			itemsListExpenses: []
    		};
    
    		var variableNames = {
    			bg_srexpenses_main: '',
    			searchtext: '',
    			date_fom: '',
    			date_tom: '',
    			dateselector_index: ''
    		};
    
    		var timer = {
    			searchtext: null
    		};
    
    		var initLoadDone = false;
    		var timerInitLoadDone = null;
    		var timerSrExpenses = null;
    
            // ## INIT LOAD FUNCTION ##
    
            var initLoadFunc = function () {
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }
    
                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		srExpensesService.loadSrExpensesGet(vm.model.argType, vm.model.argValue).then(function (data) {
    			vm.model.setting = angular.copy(data[0]);
    			vm.model.bgSrExpensesMain.item_default = utilityService.groupButtonIdOf(vm.model.bgSrExpensesMain, vm.model.setting.bg_srexpenses_main);
    
    			initLoadFunc();
    			loadExpenses();
    		});
    
    		var loadExpenses = function () {
    			if (angular.isDefined(timerSrExpenses)) {
    				$timeout.cancel(timerSrExpenses);
    			}
    
    			if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.bg_srexpenses_main) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.setting.date_fomtom) !== true) return;
    			
    			vm.model.itemsListExpenses = [];
    
    			timerSrExpenses = $timeout(function () {
    				srExpensesService.loadSrExpensesList(vm.model.setting.argtype, vm.model.setting.argvalue, vm.model.setting.bg_srexpenses_main, vm.model.setting.date_fom, vm.model.setting.date_tom, vm.model.setting.dateselector_index, vm.model.setting.date_fomtom).then(function (data) {
    					angular.copy(data, vm.model.itemsListExpenses);
    				});
    			}, 50);
    		};
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberFunc = function (id) {
                var deferred = $q.defer();
    
    			if (angular.isDefined(timer[id])) {
    				$timeout.cancel(timer[id]);
    			}
    
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
    
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
    
                var variableValue = null;
    
                switch (id) {
                    case 'bg_srexpenses_main':
                        if (initLoadDone !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.bg_srexpenses_main) !== true) return;
    
                        variableValue = vm.model.setting.bg_srexpenses_main;
    					break;
    				case 'searchtext':
    					if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
    
    					variableValue = vm.model.setting.searchtext;
    					break;
    				case 'date_fom':
    					if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
    
    					variableValue = vm.model.setting.date_fom;
    					break;
    				case 'date_tom':
    					if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
    
    					variableValue = vm.model.setting.date_tom;
    					break;
    				case 'dateselector_index':
    					if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
    
    					variableValue = vm.model.setting.dateselector_index;
    					break;
                    default:
                        break;
                }
    
    			if (vm.model.setting.searchtext > '') {
    				timer[id] = $timeout(function () {
    					rememberService.remember(variableNames[id], variableValue).then(function () {
    						deferred.resolve();
    					});
    				}, 500);
    			} else {
    				rememberService.remember(variableNames[id], variableValue).then(function () {
    					deferred.resolve();
    				});
                }
    
                return deferred.promise;
            };
    
            // ## BUTTON GROUP FUNCTIONS ##
    
    		vm.selectBgSrExpensesMain = function (item) {
    			vm.model.bgSrExpensesMain.item_selected = item;
    			vm.model.setting.bg_srexpenses_main = vm.model.bgSrExpensesMain.item_selected;
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.searchtext = '';
                        break;
                    default:
                        break;
                }
            };
    
    		// ## DIRECTION BUTTON FUNCTIONS ##
    
    		//vm.goTo = function (state) {
    		//	var go = function (parms) {
    		//		stateService.go(state, parms);
    		//	};
    
    		//	switch (state) {
    		//		case 'travelbill':
    		//			go({
    		//				argtype: vm.model.argType,
    		//				argvalue: vm.model.argValue,
    		//				travelbill_no: '0'
    		//			});
    		//			break;
    		//	}
    		//};
    
            // ## WATCH FUNCTIONS ##
    
    		watcher.$watch(function () { return vm.model.bgSrExpensesMain.item_selected; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			rememberFunc('bg_srexpenses_main');
    			loadExpenses();
    		});
    
    		watcher.$watch(function () { return vm.model.setting.searchtext; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			rememberFunc('searchtext');
    		});
    
    		watcher.$watch(function () { return vm.model.setting.date_fom; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
    			vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
    
    			rememberFunc('date_fom');
    		});
    
    		watcher.$watch(function () { return vm.model.setting.date_tom; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
    			vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
    
    			rememberFunc('date_tom');
    		});
    
    		watcher.$watch(function () { return vm.model.setting.dateselector_index; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
    			vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
    
    			rememberFunc('dateselector_index');
    		});
    
    		watcher.$watch(function () { return vm.model.setting.date_fomtom; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			loadExpenses();
    		});
    	}]
    });
})();