(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('eCommerceStatus', {
        templateUrl: 'views/components/views/eCommerceStatus/eCommerceStatus.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['eCommerceStatusService', 'modalService', function(eCommerceStatusService, modalService) {
            var vm = this;
            
            vm.updateWebShop = function () {
                eCommerceStatusService.genericFunction(724, {}).then(function (data) {
                    modalService.show({
                        type: 'success',
                        title: 'Oppdatering',
                        message: data[0].statusmessage,
                        buttons: [{
                            label: 'OK',
                            close: true
                        }]
                    });
                });
            };
        }]
    });
})();