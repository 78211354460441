import { Component, OnInit, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
//import { AuthBridgeService } from '../../../core/authentication/services/auth-bridge.service';
import { DataTaskService, IDataTaskOptions } from '../../../core/services/data-task.service';
import { ILanguageInfo, LanguageService } from '../../../core/services/language.service';
import { TranslateService } from '@app/core/services/translate.service';
import { PrintService } from '../../../core/services/print.service';
import { RememberService } from '../../../core/services/remember.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalService } from '../../../core/services/modal.service';

@Component({
  selector: 'app-bjs-test',
  templateUrl: './bjs-test.component.html',
  styleUrls: ['./bjs-test.component.css']
})
export class BjsTestComponent implements OnInit {
    htmlToPdfValue: string = '<h1>BJS TEST, BJS TEST, BJS TEST</h1>';
    htmlToPdfData: string = '';

    constructor(@Inject('stateService') private stateService: any, private dialog: MatDialog, private ms: ModalService, private dt: DataTaskService, private ts: TranslateService, private ls: LanguageService, private ps: PrintService, private rs: RememberService) { }

    ngOnInit() {

    }

    public async rememberTest() {
        await this.rs.remember('bjsRememberTest1', 'abcdefgh');

        const response1: string = await this.rs.getLastStatus('bjsRememberTest1');

        console.log(response1);

        await this.rs.remember('bjsRememberTest2', { test: 'ijklmnop' });

        const response2 = await this.rs.getLastStatus('bjsRememberTest2', true);

        console.dir(response2);
    }

    public async htmlToPdfTest() {
        this.ps.HtmlToPdf(this.htmlToPdfValue);
    }

    public async htmlToPdfTestBase64() {
        this.htmlToPdfData = await this.ps.HtmlToPdfBase64(this.htmlToPdfValue);
    }

    public async datataskTest() {
        let word1 = await this.ts.translate('sub_orderline_backorders_description');
        let word2 = await this.ts.translate('sub_orderline_backorders_description');

        console.log('word1: ' + word1);
        console.log('word2: ' + word2);

        //console.log('ls post');

        //let result: Array<ILanguageInfo> = await this.ls.List();
        
        //console.dir(result);

        //console.log('posting 1');

        ////let response1 = await this.dt.Post(3, {
        ////    timestamp: 0,
        ////    words: ["p2_m_bjstestview", "navbar_favorites", "navbar_home", "navbar_sing_out", "navbar_notifications", "navbar_helpdoc", "navbar_close_sidemenu", "navbar_open_sidemenu", "navbar_go_back"]
        ////});

        //console.log('response 1');
        //console.dir(response1);

        //console.log('posting 2');

        ////let response2 = await this.dt.Post(2992, {
        ////    timestamp: 0,
        ////    words: ["p2_m_bjstestview", "navbar_favorites", "navbar_home", "navbar_sing_out", "navbar_notifications", "navbar_helpdoc", "navbar_close_sidemenu", "navbar_open_sidemenu", "navbar_go_back"]
        ////}, { encodeParams: true, decodeResponse: true } as IDataTaskOptions);

        //console.log('response 2');
        //console.dir(response2);
    }

    public modalTranslateTest() {
        this.ts.showModal('abcd', false);
    }

    // KOMMENTAR: HSK - Denne har jeg gjort om til å passe til den nye
    //public async modalTest(): Promise<void> {
    //    await this.ms.show({
    //        title: 'Confirm',
    //        message: 'Are you sure you want to proceed?',
    //        buttons: [
    //            { label: 'Yes', close: true, cssClass: 'btn-primary' },
    //            { label: 'No', close: true, cssClass: 'btn-secondary' },
    //            {
    //                label: 'Ok',
    //                cssClass: 'btn-warning',
    //                action: function (dialogItself) {
    //                    console.log('action');

    //                    dialogItself.close();
    //                }
    //            }
    //        ]
    //    });
    //}

    // HER ER DEN NYE
    // IMPORTERTE MODALSERVICE
    // ANY QUESTIONS? Ask Janita, ligger i modal.service
    public async modalTest(): Promise<void> {
        const result = await this.ms.openConfirmDialog({ type: 'primary', title: 'Confirm', message: 'Are you sure you want to proceed?', ok: 'ttgrid_modal_save_ok', cancel: 'ttgrid_modal_save_cancel' });
    }

    public go() {
        this.stateService.go('mainmenu');
    }
}
