(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acReportLayoutLine', {
        templateUrl: 'views/components/views/acReportLayoutLine/acReportLayoutLine.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'acReportLayoutLineService', 'translateService', function ($stateParams, stateService, modalService, acReportLayoutLineService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var acReportLayoutKeyno = $stateParams.acreportlayout_keyno;
            var acReportLayoutLineKeyno = $stateParams.acreportlayoutline_keyno;

            var translations = {
                acreportlayoutline_save_show_title: 'Varsel',
                acreportlayoutline_save_show_ok_label: 'OK',
                acreportlayoutline_delete_show_title: 'Varsel',
                acreportlayoutline_delete_show_ok_label: 'OK'
            };

            vm.model = {
                acReportLayoutLineKeyno: acReportLayoutLineKeyno,
                edit: {},
                selectListLineTypes: [],
                lockedSave: false,
                lockedDelete: false
            };

            vm.options = {
                order: {
                    autoselect: true,
                    decimals: 0,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            acReportLayoutLineService.getAcReportLayoutLine({
                acreportlayout_keyno: acReportLayoutKeyno,
                acreportlayoutline_keyno: acReportLayoutLineKeyno
            }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            acReportLayoutLineService.listLineTypes().then(function (result) {
                angular.copy(result, vm.model.selectListLineTypes);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                acReportLayoutLineService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.acreportlayoutline_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.acreportlayoutline_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                acReportLayoutLineService.deleteItem(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.acreportlayoutline_delete_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.acreportlayoutline_delete_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
        }]
    });
})();