(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prodTmSizeStockplace', {
        templateUrl: 'views/components/views/prodTmSizeStockplace/prodTmSizeStockplace.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', function ($stateParams) {
            var vm = this;

            vm.textileModelId = $stateParams.textilemodel_id;

            vm.gridOptions = {
                dataTask: {
                    rememberId: 'prodTmSizeStockplaceGrid',
                    loadSetupId: null,
                    loadData: {
                        method: 2439,
                        parameters: function () {
                            return { textilemodel_id: vm.textileModelId };
                        }
                    }
                },
                translations: [],
                config: {
                    editColumns: [],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        edit: false,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };
        }]
    });
})();
