(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicService", ['$q', '$ihttp', 'base64', function($q, $ihttp, base64) {
        var service = {
            init: function (route) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initDynamic',
                        route_id: route
                    }
                }).then(function (response) {
                    function decodePropValue(dyn) {
                        dyn.remarks = base64.urldecode(dyn.remarks);

                        angular.forEach(dyn.properties, function (prop) {
                            if (prop.encoded === true && prop.value !== null) {
                                prop.value = base64.urldecode(prop.value);
                            }
                        });
                    };

                    if (angular.isDefined(response.dynamic)) {
                        decodePropValue(response.dynamic);
                    } else {
                        angular.forEach(response.dynamics, function (dyn) {
                            decodePropValue(dyn);
                        });
                    }

                    deferred.resolve(response);
                });

                return deferred.promise;
            },
            getDatataskList: function () {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'datatasks'
                    }
                });
            }
        }

        return service;
    }]);
})();
