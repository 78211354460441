(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicEditService", ['base64', '$q', '$ihttp', function(base64, $q, $ihttp) {
        var service = {
            componentChanged: function (ttDynamicKeyno, ttComponentKeyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'componentChanged',
                        keyno: ttDynamicKeyno,
                        component: ttComponentKeyno
                    }
                });
            },

            init: function (ttDynamicKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initDynamic',
                        keyno: ttDynamicKeyno
                    }
                }).then(function (response) {
                    function decodePropValues(dyn) {
                        angular.forEach(dyn.properties, function (prop) {
                            if (prop.encoded === true) {
                                prop.value = base64.urldecode(prop.value)
                            }

                            delete prop.encoded;
                        });
                    };

                    if (angular.isDefined(response.dynamic)) {
                        decodePropValues(response.dynamic);
                    } else {
                        angular.forEach(response.dynamics, function (dyn) {
                            decodePropValues(dyn);
                        });
                    }

                    deferred.resolve(response);
                });

                return deferred.promise;
            },

            update: function (dynamic, properties) {
                angular.forEach(properties, function (prop) {
                    if (angular.isDefined(prop.value) && angular.isString(prop.value) && prop.value !== null && prop.value.lenght > 0) {
                        prop.value = base64.urlencode(prop.value);
                        prop.encoded = true;
                    } else {
                        prop.encoded = false;
                    }
                });

                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'updateDynamic',
                        dynamic: dynamic,
                        properties: properties
                    }
                });
            }
        }

        return service;
    }]);
})();
