(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businessMenu', {
        templateUrl: 'views/components/views/businessMenu/businessMenu.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'businessMenuService', function ($stateParams, stateService, translateService, modalService, businessMenuService) {
            const vm = this;
            let businessId = $stateParams.business_id;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                businessNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('businesslang', { business_id: vm.model.menu.business_id }) }
                ],
                menu: {},
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadMenu = function () {
                businessMenuService.getMenu({ business_id: businessId }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    businessMenuService.deleteItem(vm.model.menu).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
		}]
	});
})();
