(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2ExportProcs', {
        templateUrl: 'views/components/views/p2ExportProcs/p2ExportProcs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'p2ExportProcsService', function (stateService, p2ExportProcsService) {
            var vm = this;
            
            vm.model = {
                itemsListExportProcedures: []
            };

            p2ExportProcsService.listExportProcedures().then(function (result) {
                angular.copy(result, vm.model.itemsListExportProcedures);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2exportproc':
                        stateService.go(state, {
                            p2_exportproc_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();