(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('operation', {
        templateUrl: 'views/components/views/operation/operation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'operationService', 'logisticService', 'typeaheadService', function ($stateParams, stateService, modalService, translateService, operationService, logisticService, typeaheadService) {
            let vm = this;
            let operationId = $stateParams.operation_id;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                operationId: operationId,
                prodIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                edit: {},
                selectListUnits: [],
                lockedSave: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                operationService.getOperation({ operation_id: operationId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadUnits = function () {
                logisticService.listUnits({ onlytimeunits: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListUnits);
                });
            };

            vm.typeaheadSearch = function (value) {
                var parms = {
                    prod_name: vm.model.edit.prod_name
                };

                return typeaheadService.searchProduct(parms);
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                operationService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };

            // 20231010 KLN - upgrade iminput with search to ttsearch
            vm.onProductNameCleared = function () {
                vm.model.edit.prod_id = '';
                vm.model.edit.prod_name = '';
            };

            vm.onProductNameSelected = function (item) {
                vm.model.edit.prod_id = item.item_id;
                vm.model.edit.prod_name = item.item_name;
            };

            vm.onProductNameChanged = function (value) {
                vm.model.edit.prod_name = value;
                if (!value) {
                    vm.onProductNameCleared();
                } else {
                    let product = typeaheadService.selectListProducts.find((item) => item.iten_name === vm.model.edit.prod_name);
                    if (product) {
                        vm.onProductNameSelected(product);
                    }
                }
            };

            vm.$onInit = function () {
                loadEdit();
                loadUnits();
            };
        }]
    });
})();
