(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('jobQueueMonitor', {
        templateUrl: 'views/components/views/jobQueueMonitor/jobQueueMonitor.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['jobQueueMonitorService', function (jobQueueMonitorService) {
            var vm = this;
            
            vm.model = {
                settingJobQueue: {},
                updatingJobQueue: false
            };
            
            var loadJobQueueMonitor = function () {
                jobQueueMonitorService.loadJobQueueMonitorGet().then(function (data) {
                    vm.model.updatingJobQueue = false;
                    angular.copy(data[0], vm.model.settingJobQueue);
                });
            };
            
            loadJobQueueMonitor();
            
            vm.updateJobQueue = function () {
                vm.model.updatingJobQueue = true;
                loadJobQueueMonitor();
            };
        }]
    });
})();