(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("translateListEditService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadTranslateEditList: function (word_id) {
                return p2DataTaskService.call(61, {
                    word_id: word_id
                });
            },

            getTranslateEdit: function (wordId) {
                return p2DataTaskService.call(2928, { word_id: wordId });
            },

            loadLanguageList: function (addDetect) {
                return p2DataTaskService.call(57, { add_detect: addDetect });
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(62, mergeObj);
            }
        };

        return service;
    }]);
})();
