(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDeliveryAddPurchLineService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadBatchDeliveryAddPurchLineGet: function (supplier_no, batchdelivery_keyno, purch_internal_no) {
                return p2DataTaskService.call(1558, {
                    supplier_no: supplier_no,
                    batchdelivery_keyno: batchdelivery_keyno,
                    purch_internal_no: purch_internal_no
                });
            },
            loadBatchDeliveryAddPurchLineManPlList: function (supplier_no, batchdelivery_keyno, purch_internal_no) {
                return p2DataTaskService.call(1561, {
                    supplier_no: supplier_no,
                    batchdelivery_keyno: batchdelivery_keyno,
                    purch_internal_no: purch_internal_no
                });
            },
            loadBatchDelSelectPurchLineList: function (supplier_no, batchdelivery_keyno, purch_internal_no) {
                return p2DataTaskService.call(284, {
                    supplier_no: supplier_no,
                    batchdelivery_keyno: batchdelivery_keyno,
                    purch_internal_no: purch_internal_no
                });
            },
            loadBatchDeliveryAddPurchLineLocList: function (stock_keyno) {
                return p2DataTaskService.call(1648, {
                    stock_keyno: stock_keyno
                });
            },
            loadBatchDeliveryStockQuantityPrColliGet: function (prodsize_keyno) {
                return p2DataTaskService.call(1591, {
                    prodsize_keyno: prodsize_keyno
                });
            },
            loadBatchDeliveryEanListGet: function (prod_id) {
                return p2DataTaskService.call(1592, {
                    prod_id: prod_id
                });
            },
            loadBatchDeliveryGetStockLocationInfo: function (stocklocation_keyno) {
                return p2DataTaskService.call(1677, {
                    stocklocation_keyno: stocklocation_keyno
                });
            },
            loadBatchDeliveryGetVolumeM3: function (prodsize_keyno) {
                return p2DataTaskService.call(1678, {
                    prodsize_keyno: prodsize_keyno
                });
            },
            scan: function (settingAddPurchLine) {
                return p2DataTaskService.call(1559, settingAddPurchLine);
            },
            saveScan: function (settingAddPurchLine) {
                return p2DataTaskService.call(1560, settingAddPurchLine);
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(286, mergeObj);
            }
        };

        return service;
    }]);
})();