(function () {
    'use strict';

    angular.module('imApp').component('ttGridPrintModal', {
        templateUrl: 'views/components/directives/ttGrid/ttGridPrintModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$localStorage', '$q', '$uibModal', 'base64', 'layoutService', 'utilityService', 'translateService', 'ttGridService', 'modalService', 'modalReportPreviewService', 'pdfService', function ($localStorage, $q, $uibModal, base64, layoutService, us, translateService, ttGridService, modalService, modalReportPreviewService, pdfService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];

            us.setDefaultValues($localStorage, {
                gridPrint: {
                    printer: {}
                }
            });

            vm.$storage = $localStorage.gridPrint;

            vm.style = {
                btn: {
                    paddingTop: '0px'
                },
                input: {
                },
                select: {
                    paddingLeft: '5px'
                }
            };
            vm.previewBook = '0';
            vm.selectedRows = [];
            vm.rowCount = 0;
            vm.reportList = [];
            vm.report = {};
            vm.reportListButtons = [{ id: 'preview', icon: 'fal fa-file-search', type: 'primary', onClick: () => vm.preview() }];
            //vm.p2ReportDef = {};

            //vm.fontSize = '14px';

            vm.translations = {
                tt_grid_print_title: '',
                tt_generic_modal_warning_title: '',
                tt_preview_unavailable: '',
                row_count: ''
            };

            // #endregion VARIABLES & DEFINITIONS

            vm.finish = function () {
                //console.log('print');

                //for (let i = 0; i < vm.reportList.length; i++) {
                //    if (vm.reportList[i].keyno === vm.report.data.report_key) {
                //        vm.report.data.report_name = vm.reportList[i].label;
                //        vm.report.data.print_server_report_key = vm.reportList[i].printServerKeyno;
                //        break;
                //    }
                //}

                vm.report.printer = angular.isDefined(vm.$storage.printer.item_id) ? vm.$storage.printer.item_id : '';

                vm.modalInstance.close(vm.report);
                //orderSpecialService.updateDriver({
                //    argtype: '',
                //    argvalue: '',
                //    deliverymethod_no: vm.delivery.data.deliverymethod_no,
                //    delivery_phone: vm.delivery.data.phone,
                //    equipment_id: vm.delivery.data.equipment_id,
                //    labour_no: vm.delivery.data.labour_no
                //}).then(function (data) {
                //    if (data[0].errorcode !== '0') {
                //        modalService.show({
                //            type: 'warning',
                //            title: vm.translations.tt_generic_modal_warning_title,
                //            message: data[0].errormessage,
                //            buttons: [{
                //                label: 'OK',
                //                cssClass: 'btn-warning',
                //                action: function (dialogItself) {
                //                    dialogItself.close();
                //                }
                //            }]
                //        });
                //    } else {
                //        vm.modalInstance.close(vm.delivery.data);
                //    }
                //});
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.preview = async function () {

                let rows = [];
                let selectedItem = vm.selectedRows.length > 0 ? vm.selectedRows[0] : {};

                if (vm.previewBook === '1') {
                    rows = vm.selectedRows;
                } else {
                    rows = [selectedItem]
                }
                
                if (vm.report.printServerKeyno !== -1 || vm.report.report_type_keyno == 2) {
                    const reportKeyno = vm.report.printServerKeyno > 0 ? vm.report.printServerKeyno : vm.report.p2_reportdef_keyno;
                    let parms = [];
                    let parameters = {};
                    let parametersList = [];
                    const modalParameters = {
                        useBookPreview: vm.previewBook === '1',
                        reportTypeKeyno: vm.report.report_type_keyno ?? 1,
                        reportKeyno: reportKeyno
                    };

                    if (vm.report.report_type_keyno == 2) {
                        parameters = vm.resolve.parameters.load.parms;
                        parms = Object.keys(selectedItem ?? {}).map(e => { return { parmname: e } });
                    } else if (vm.report.parms.length > 0) {
                        parms = vm.report.parms;
                    } else {
                        parms = await ttGridService.getReportParameters(vm.report.printServerKeyno);
                    };

                    angular.forEach(rows, function (item) {
                        selectedItem = item;
                        angular.forEach(parms, function (parm) {
                            const parmName = parm.parmname.charAt(0) === '@' ? parm.parmname.slice(1) : parm.parmname;
                            const value = angular.isDefined(selectedItem[parmName]) ? selectedItem[parmName] : '';
                            parameters[parmName] = value;
                        });
                        parametersList.push(angular.copy(parameters));
                    });

                    if (vm.report.report_type_keyno == 2) {
                        modalParameters.parameters = parametersList;
                    } else {
                        modalParameters.reports = [{
                            reportKeyno: reportKeyno,
                            parametersList: parametersList
                        }];
                    }
                    modalReportPreviewService.show(modalParameters);

                } else {
                    modalService.show({
                        type: 'warning',
                        title: vm.translations.tt_generic_modal_warning_title,
                        message: vm.translations.tt_preview_unavailable,
                        buttons: [{
                            label: 'OK',
                            cssClass: 'btn-warning',
                            action: function (dialogItself) {
                                dialogItself.close();
                            }
                        }]
                    });
                }
            };

            vm.handleReportChange = async function (value) {
                if (value !== vm.report.keyno) {
                    vm.report = vm.reportList.find(e => e.keyno == value);
                }
            };

            vm.onPrinterChanged = function (printer, changed) {
                if (changed !== true)
                    return;

                vm.$storage.printer = printer;
            };

            //// #region LOOKUPSEARCH FUNCTIONS

            //vm.selectItem = function (item, model) {
            //    if (angular.isDefined(item) && angular.isDefined(item.equipment_id) && item.equipment_id !== null && item.equipment_id !== '') {
            //        vm.delivery.selected.field = item.equipment_name;
            //        vm.delivery.data.equipment_id = item.equipment_id;
            //        vm.delivery.data.equipment_name = item.equipment_name;
            //    }
            //};

            //// #endregion LOOKUPSEARCH FUNCTIONS

            //function prepareReports() {
            //    let deferred = $q.defer();

            //    let promises = [];

            //    promises.push(orderSpecialService.getReports());

            //    $q.all(promises).then(function (response) {
            //        angular.copy(response[0], vm.delivery.methodsList);
            //        angular.copy(response[1], vm.delivery.equipmentList);
            //        //angular.copy(response[2], vm.delivery.labourersList);
            //        angular.forEach(response[2], function (data) {
            //            if (angular.isDefined(data) && angular.isDefined(data.item_id)) {
            //                vm.delivery.labourersList.push({ labour_no: data.item_id, labour_name: data.item_name });
            //                if (response[3][0].labour_no === data.item_id) {
            //                    vm.delivery.data.labour_no = data.item_id;
            //                    vm.delivery.data.labour_name = data.item_name;
            //                }
            //            }
            //        });

            //        deferred.resolve(response);
            //    }, function (error) {
            //        console.log('error! error! error! error! error!');

            //        if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
            //            console.log(error.data.message);

            //            if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
            //                console.log(error.data.data.message);
            //            }
            //        } else {
            //            if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
            //                console.log(error.data.data.message);
            //            }
            //        }

            //        console.dir(error);

            //        deferred.reject(error);
            //    });

            //    return deferred.promise;
            //}

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                //vm.fontSize = info.fontSizes.textSize;
                vm.style.btn.paddingTop = info.fontSizes.textSizeL;
            });

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                //prepareDelivery().then(function (data) {
                //ttGridService.getReports().then(function (data) {
                //    //console.dir(data);
                //    //for (let l = 0; l < vm.delivery.labourersList.length; l++) {
                //    //    if (vm.delivery.labourersList[l].labour_no === vm.delivery.data.labour_no) {
                //    //        break;
                //    //    }
                //    //}
                //});
                //angular.forEach(vm.resolve.parameters.reports, function (report) {
                //});

                vm.selectedRows = vm.resolve.parameters.selected.isSelected.length > 0 ? vm.resolve.parameters.selected.isSelected : vm.resolve.parameters.selected.rows;
                vm.rowCount = vm.selectedRows.length;
                vm.printBook = false;
                //vm.printBook = vm.resolve.parameters.selected.isSelected.length > 1;

                angular.copy(vm.resolve.parameters.reports, vm.reportList);

                angular.forEach(vm.reportList, function (item) {
                    item.label = base64.urldecode(item.label);
                });

                vm.reportList.sort(function (a, b) {
                    return a.keyno - b.keyno;
                });

                ttGridService.getPrintServerReports().then(function (result) {
                    angular.forEach(vm.reportList, function (item) {
                        item.printServerKeyno = -1;
                        for (let i = 0; i < result.length; i++) {
                            if (item.filename === result[i].reportId) {
                                item.printServerKeyno = result[i].reportKeyno;
                                break;
                            }
                        }
                    });
                    if (vm.reportList.length > 0) vm.handleReportChange(vm.reportList[0].keyno);
                });
                

            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
