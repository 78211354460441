(function () {
    'use strict';

    angular.module("imApp").factory("gridBookingColumnsModalService", ['$ihttp', function ($ihttp) {

        const service = {
            bookSubledgerWriteoff: function (parameters) {
                return $ihttp.post({
                    method: 3205,
                    parameters: parameters || {}
                });
            }
        };

        return service;
    }]);
})();
