(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('componentReplacement', {
        templateUrl: 'views/components/views/componentReplacement/componentReplacement.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['componentReplacementService', '$stateParams', 'stateService', 'modalService', 'translateService', '$q', '$uibModal', function (componentReplacementService, $stateParams, stateService, modalService, translateService, $q, $uibModal) {
            var vm = this;

            let bomComponentReplaceKeyno = $stateParams.bom_component_replace_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedSave: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                componentReplacementService.getComponentReplacement({ bom_component_replace_keyno: bomComponentReplaceKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                componentReplacementService.save(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            vm.delete = function () {
                vm.model.lockedSave = true;

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return '';
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        vm.model.lockedSave = false;
                        return;
                    }

                    componentReplacementService.delete(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedSave = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedSave = false;
                            stateService.back();
                        }
                    });
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };

            vm.prodSearch = function (value, searchparam) {
                return componentReplacementService.prodSearchKeyname(value, searchparam);
            };

            vm.prodSelectItem = function (item, model) {
                if (item?.prod_id && item.prod_id !== '') {
                    
                    vm.model.edit.prod_id = item.prod_id;
                    vm.model.edit.prod_name = item.prod_name;
                    vm.model.edit.prod_keyname = item.prod_keyname;
                }
            };
           

        }]
    });
})();
