(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prodGrpLangs', {
        templateUrl: 'views/components/views/prodGrpLangs/prodGrpLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'prodGrpLangsService', function ($stateParams, stateService, modalService, translateService, prodGrpLangsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodGrpId = $stateParams.prodgrp_id;

            let translations = {
                confirmation: '',
                error: '',
                ok: ''
            };

            vm.model = {
                inputListLanguages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadLanguages = function () {
                prodGrpLangsService.listLanguages({ prodgrp_id: prodGrpId }).then(function (result) {
            		angular.copy(result, vm.model.inputListLanguages);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                prodGrpLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function (response) {
                    //if (response[0].ws_result === '1') {
                    //	modalService.show({
                    //		type: 'success',
                    //		title: translations.confirmation,
                    //        message: response[0].ws_result_message,
                    //		buttons: [{
                    //			label: translations.ok,
                    //            cssClass: 'btn-success',
                    //			action: function (dialogItself) {
                    //				dialogItself.close();
                    //                vm.model.lockedSave = false;
                    //                stateService.back();
                    //			}
                    //		}]
                    //	});
                    //} else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    //}
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadLanguages();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
