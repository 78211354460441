import { Injectable } from '@angular/core';
import { AppConfigService } from '../../services';
import { DataTaskService } from './data-task.service';
import { UserService } from './user.service';

export interface ILanguageInfo {
    currentlanguage: boolean,
    google_language_id: string,
    item_id: string,
    item_name: string,
    orderby: number
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    constructor(private ds: DataTaskService, private aps: AppConfigService) { }

    public async List(): Promise<Array<ILanguageInfo>> {
        return (this.ds.Post(57) as unknown) as Array<ILanguageInfo>;
    }

    public GetCurrentLanguage() {
        return this.aps.settings.user?.languageId;
    }
}
