(function() {
    'use strict';

    angular.module("imApp").factory("invoicesService", ['$ihttp', function($ihttp) {
        var service = {
            resend: function (invoiceNo) {
                return $ihttp.post({
                    method: 2370,
                    parameters: {
                        invoice_no: invoiceNo
                    }
                });
            }
        }

        return service;
    }]);
})();
