(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('srResource', {
        templateUrl: 'views/components/views/srResource/srResource.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'srResourceService', 'srResourceViewService', function ($stateParams, stateService, utilityService, modalService, srResourceService, srResourceViewService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                resourceIdBelongTo: $stateParams.resource_id_belongto,
                resourceId: $stateParams.resource_id,
                edit: {},
                selectListSubResources: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            srResourceService.getSrResource({
                resource_id_belongto: vm.model.resourceIdBelongTo,
                resource_id: vm.model.resourceId
            }).then(function (data) {
                angular.copy(data[0], vm.model.edit);

                loadSrSubResources();
            });

            var loadSrSubResources = function () {
                if (utilityService.validateParmsValue(vm.model.edit.main_resource_id) !== true) return;

                vm.model.selectListSubResources = [];

                srResourceViewService.listSrSubResoures({ main_resource_id: vm.model.edit.main_resource_id }).then(function (data) {
                    angular.copy(data, vm.model.selectListSubResources);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            //vm.handleFieldChanged = function (value, id) {
            //    if (id.startsWith('vm.') !== true) return;

            //    var parts = id.split('.');

            //    switch (parts[parts.length - 1]) {
            //        case 'resource_id_belongto':
            //            vm.model.edit.resource_id_belongto = value;

            //            if (utilityService.validateParmsValue(vm.model.edit.resource_id_belongto) !== true) return;

            //            loadSrSubResources();
            //            break;
            //        default:
            //            utilityService.setValueToVariable(vm, parts, value);
            //            //eval(id + " = '" + value + "'");
            //            //break;
            //    }

            //};

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region SAVE PROCEDURE FUNCTION CALL

            vm.saveSrResource = function () {
                vm.model.lockedSave = true;
                
                srResourceService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
