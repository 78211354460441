(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockCorrCodeLangs', {
        templateUrl: 'views/components/views/stockCorrCodeLangs/stockCorrCodeLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'stockCorrCodeLangsService', function ($stateParams, stateService, translateService, modalService, stockCorrCodeLangsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockCorrCodeKeyno = $stateParams.stockcorrcode_keyno;

            let translations = {
                confirmation: '',
                ok: ''
            };

            vm.model = {
                inputListLanguages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadStockCorrectionCodeLanguages = function () {
                stockCorrCodeLangsService.listStockCorrectionCodeLanguages({ stockcorrcode_keyno: stockCorrCodeKeyno }).then(function (list) {
                    angular.copy(list, vm.model.inputListLanguages);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockCorrCodeLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function (response) {
                    if (response[0].ws_result === '1') {
                        modalService.show({
                            type: 'success',
                            title: translations.confirmation,
                            message: response[0].ws_result_message,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-success',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStockCorrectionCodeLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
