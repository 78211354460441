(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('usergroupTableEdit', {
        templateUrl: 'views/components/views/usergroupTableEdit/usergroupTableEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'usergroupTableEditService', 'bspMenuService', function ($stateParams, stateService, usergroupTableEditService, bspMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2UserGroupTableKeyno = $stateParams.p2_usergroup_table_keyno;
            
            vm.model = {
                recordRightsButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.recordright_no = '10' }
                ],
                edit: {},
                selectListRecordRights: [],
                lockedSave: false

            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                usergroupTableEditService.getEdit({ p2_usergroup_table_keyno: p2UserGroupTableKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadRecordRights = function () {
                bspMenuService.listRecordRights().then(function (list) {
                    angular.copy(list, vm.model.selectListRecordRights);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                usergroupTableEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadRecordRights();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
