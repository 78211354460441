(function () {
    'use strict';

    let module = angular.module('imApp');

    module.directive('ttBottomSheet', [function () {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: 'views/components/directives/ttBottomSheet/ttBottomSheet.template.html?v=' + module.version,
            scope: {
                ttMinHeight: '@',
            },
            link: function (scope, element) {
                scope.ttMinHeight ??= '8rem';
                //console.log(scope.ttMinHeight);
                scope.model = {
                    open: false,
                    activeAnchor: 'bottom',
                    dragOffset: '',
                    touchStartY: 0,
                    touchStartYDate: 0,
                    startScrollTop: 0,
                }

                scope.anchors = {
                    expanded: true,
                    half: true,
                    bottom: true,
                }

                scope.id = {
                    outer: uuid(),
                    container: uuid()
                }

                scope.style = {
                    sheet: {},
                };

                scope.closeExpandedSheet = function (event) {
                    snapToAnchor('half');
                }

                scope.onTouchStart = function (event) {
                    scope.model.touchStartY = event?.originalEvent?.targetTouches[0]?.clientY;
                    scope.model.touchStartYDate = Date.now();

                    const sheetStartPositionY = element[0]?.querySelector('.tt-bottom-sheet__container')?.getBoundingClientRect()?.top;
                    scope.model.startScrollTop = element[0].querySelector('.tt-bottom-sheet__content').scrollTop;

                    if ((scope.model.touchStartY || scope.model.touchStartY === 0) && (sheetStartPositionY || sheetStartPositionY === 0)) {
                        scope.model.dragOffset = (scope.model.touchStartY - sheetStartPositionY) + 'px';
                    }
                }

                scope.onTouchMove = function (event) {
                    if (scope.model.activeAnchor !== 'expanded' || (scope.model.activeAnchor === 'expanded' && scope.model.startScrollTop === 0 && !didSwipeUp(event.originalEvent.targetTouches[0].clientY))) {
                        event.preventDefault();
                        scope.style.sheet.transform = `translateY(calc(${event.originalEvent.targetTouches[0].clientY}px - ${scope.model.dragOffset}))`;
                    }
                }

                scope.onTouchEnd = function (event) {
                    const originalEvent = event?.originalEvent;

                    const touchLength = Math.abs(scope.model.touchStartY - originalEvent?.changedTouches[0]?.clientY);
                    //console.dir(touchLength);
                    if (scope.model.startScrollTop !== 0 || touchLength < 15) return;


                    if (shouldTotallyExpand(originalEvent)) {
                        snapToAnchor('expanded');
                    } else if (shouldAnchorHalf(originalEvent)) {
                        snapToAnchor('half')
                    } else {
                        snapToAnchor('bottom');
                    }
                }

                /**
                 * 
                 * 
                 * @param {TouchEvent} event 
                 * @returns
                 */
                function shouldTotallyExpand(event) {
                    const touchEndY = event?.changedTouches[0]?.clientY;
                    const sheetOnTopHalfOfScreen = element?.[0]?.querySelector('.tt-bottom-sheet__container')?.getBoundingClientRect()?.top < ((window.innerHeight / 2));

                    if ((touchEndY || touchEndY === 0) && didSwipeUp(touchEndY) && (didSwipeFast(touchEndY) || touchOnTopHalfOfScreen(touchEndY) || sheetOnTopHalfOfScreen)) {
                        return true;
                    }

                    return false;
                }

                /**
                 * 
                 * 
                 * @param {TouchEvent} event 
                 * @returns
                 */
                function shouldAnchorHalf(event) {
                    const touchEndY = event?.changedTouches[0]?.clientY;

                    if ((touchEndY || touchEndY === 0) && (didSwipeUp(touchEndY) && !didSwipeFast(touchEndY) && !touchOnTopHalfOfScreen(touchEndY) || !didSwipeUp() && scope.model.activeAnchor === 'expanded')) {
                        return true;
                    }

                    return false;
                }

                /**
                 * Checks if the touch position happens on the top half of the screen, taking into consideration the navbar.
                 * 
                 * @param {number} touchY the y position of the touch.
                 * @returns true if the touch position happened on the top half of the screen, false if not.
                 */
                function touchOnTopHalfOfScreen(touchY) {
                    const navbarHeight = document.querySelector('.tt-navbar').clientHeight;
                    //console.dir(navbarHeight);

                    if ((touchY - navbarHeight) > (window.innerHeight - navbarHeight)) {
                        return true;
                    }
                    return false;
                }

                /**
                 * Checks if the user swiped upwards.
                 * 
                 * @param {number} touchEndY the end y position of the user's touch event.
                 * @returns true if the user swiped up, false if not.
                 */
                function didSwipeUp(touchEndY) {
                    if (touchEndY < scope.model.touchStartY) {
                        return true;
                    }
                    return false;
                }

                /**
                 * Checks if the user swiped fast. Takes into account, time and length of swipe.
                 * 
                 * @param {number} touchEndY the end y position of the user's touch event.
                 * @returns true if the user swiped fast, false if not.
                 */
                function didSwipeFast(touchEndY) {
                    const touchLengthThreshold = 100;
                    const touchLength = Math.abs(scope.model.touchStartY - touchEndY);
                    //console.dir(touchLength);
                    const touchTimeYThreshold = 150;
                    const touchTimeY = Date.now() - scope.model.touchStartYDate;

                    if (touchTimeY < touchTimeYThreshold && touchLength > touchLengthThreshold) {
                        return true
                    }
                    return false;
                }

                /**
                 * Snaps the bottom sheet to the given anchor point.
                 * 
                 * @param {'expanded' | 'half' | 'bottom'} anchor the anchor point to snap the bottom-sheet to.
                 */
                function snapToAnchor(anchor) {
                    scope.style.sheet.transition = 'all 0.1s ease-in-out';

                    setTimeout(() => {
                        if (anchor === 'expanded' && scope.anchors.expanded === true) {
                            scope.style.sheet.transform = `translateY(5rem)`;
                            scope.model.activeAnchor = 'expanded';
                        } else if (anchor === 'half' && scope.anchors.half === true) {
                            scope.style.sheet.transform = `translateY(calc(50% - 5rem))`;
                            scope.model.activeAnchor = 'half';
                        } else if (anchor === 'bottom' && scope.anchors.bottom === true) {
                            scope.style.sheet.transform = `translateY(calc(100% - ${scope.ttMinHeight}))`;
                            scope.model.activeAnchor = 'bottom';
                        }

                        scope.style.sheet.transition = 'none';
                    }, 5);
                }

                (function initBottomSheet() {
                    angular.element(document.body).append(element);
                    document.querySelector('ui-view').querySelector('div').style.paddingBottom = scope.ttMinHeight;
                    setTimeout(() => {
                        scope.style.sheet.transform = `translateY(calc(100% - ${scope.ttMinHeight}))`;
                        scope.model.activeAnchor = 'bottom';
                    }, 50);
                })();

                scope.$on('$destroy', function () {
                    scope.$destroy();
                    element.remove();
                    //document.querySelector('ui-view')?.querySelector('div')?.style?.paddingBottom = '0';
                });
            }
        }
    }]);
})();
