(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderStatuses', {
        templateUrl: 'views/components/views/orderStatuses/orderStatuses.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['orderStatusesService', function (orderStatusesService) {
            var vm = this;

            vm.model = {
                itemsListOrderStatuses: []
            };

            orderStatusesService.listOrderStatuses().then(function (result) {
            	angular.copy(result, vm.model.itemsListOrderStatuses);
            });
        }]
    });
})();