(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryPlanning', {
        templateUrl: 'views/components/views/deliveryPlanning/deliveryPlanning.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'deliveryPlanningService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, modalService, translateService, deliveryPlanningService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                selecttype_id: '',
                stocklocation_keyno: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                selectTypeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('selecttype_id') }
                ],
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                bgNeedType: {
                    selected: 'OH',
                    buttons: [
                        { id: 'OH', label: 'bg_need_type_oh', color: 'primary', onClick: () => vm.model.bgNeedType.selected = 'OH' },
                        { id: 'RO', label: 'bg_need_type_ro', color: 'primary', onClick: () => vm.model.bgNeedType.selected = 'RO' },
                        { id: 'RS', label: 'bg_need_type_rs', color: 'primary', onClick: () => vm.model.bgNeedType.selected = 'RS' }
                    ]
                },
                bgDeliveryPlanningFunction: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_deliveryplanning_function_select', color: 'primary', onClick: () => vm.model.bgDeliveryPlanningFunction.selected = 'SELECT' },
                        { id: 'SHOW', label: 'bg_deliveryplanning_function_show', color: 'primary', onClick: () => vm.model.bgDeliveryPlanningFunction.selected = 'SHOW' }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                selectListDeliveryPlanningTypes: [],
                selectListStockLocations: [],
                selectListTrolleys: [],
                selectListConsignmentShipments: [],
                itemsListDeliveryPlannings: [],
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                deliveryPlanningService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadStockPlaces();
                    loadStockLocations();
                    loadTrolleys();
                    loadConsignmentShipments();
                    loadDeliveryPlannings();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadDeliveryPlanningTypes = function () {
                deliveryPlanningService.listDeliveryPlanningTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListDeliveryPlanningTypes);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };
            
            let loadStockLocations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                
                return logisticService.listStockLocations({ stockplace_id: vm.model.setting.stockplace_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockLocations);
                });
            };
            
            let loadTrolleys = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                
                return logisticService.listTrolleys({ stockplace_id: vm.model.setting.stockplace_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListTrolleys);
                });
            };
            
            let loadConsignmentShipments = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                
                return deliveryPlanningService.listConsignmentShipments({ stockplace_id: vm.model.setting.stockplace_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListConsignmentShipments);
                });
            };

            let loadDeliveryPlannings = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stocklocation_keyno, true) !== true) return;

                vm.model.itemsListDeliveryPlannings = [];

                return deliveryPlanningService.listDeliveryPlannings(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListDeliveryPlannings);
                });
            };

            let retrieveStockPlaceId = function (callback) {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                
                return deliveryPlanningService.changeStockPlaceId({ stockplace_id: vm.model.setting.stockplace_id }).then(function (response) {
                    vm.model.setting.floating_location = response[0].floating_location;
                    
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'businessco_no':
                            variableNames[key] = stateService.getCurrentName() + '.' + key;
                            break;
                        case 'stockplace_id':
                            variableNames[key] = stateService.getCurrentName() + '.' + key + '.' + 'businessco_no' + '=' + vm.model.setting.businessco_no;
                            break;
                        case 'selecttype_id':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                        case 'stocklocation_keyno':
                            variableNames[key] = stateService.getCurrentName() + '.' + key + '.' + 'stockplace_id' + '=' + vm.model.setting.stockplace_id;
                            break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                    case 'selecttype_id':
                    case 'stocklocation_keyno':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RETRIEVE LAST VALUE MULTI PROCEDURE FUNCTION CALLS

            let retrieveLastStatusFunc = function (id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        var variablename = 'deliveryplanning.stockplace_id.businessco_no=' + vm.model.setting.businessco_no;

                        return rememberService.getLastStatus(variablename).then(function (lastState) {
                            if (lastState[0].variablevalue === '') {
                                vm.model.setting.stockplace_id = '0';

                                loadStockLocations();
                                loadTrolleys();
                                loadConsignmentShipments();
                                loadDeliveryPlannings();
                            } else {
                                vm.model.setting.stockplace_id = lastState[0].variablevalue;

                                loadStockLocations();
                                loadTrolleys();
                                loadConsignmentShipments();
                                loadDeliveryPlannings();
                            }
                        });
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        var variablename = 'deliveryplanning.stocklocation_keyno.stockplace_id=' + vm.model.setting.stockplace_id;

                        return rememberService.getLastStatus(variablename).then(function (lastState) {
                            if (lastState[0].variablevalue === '') {
                                vm.model.setting.stocklocation_keyno = '0';
                            } else {
                                vm.model.setting.stocklocation_keyno = lastState[0].variablevalue;
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion RETRIEVE LAST VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'selecttype_id':
                        vm.model.setting.selecttype_id = 'ALL';

                        if (vm.model.setting.selecttype_id === 'ALL') {
                            rememberFunc('selecttype_id');
                            loadDeliveryPlannings();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON CREATE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;
                
                var mergeObj = {};
                angular.copy(vm.model.setting, mergeObj);
                mergeObj.records = [];
                angular.copy(vm.model.itemsListDeliveryPlannings, mergeObj.records);
    
                deliveryPlanningService.createObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCreate = false;
                                    loadDeliveryPlannings();
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCreate = false;
                        loadDeliveryPlannings();
                    }
                });
            };

            // #endregion BUTTON CREATE PROCEDURE FUNCTION CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgNeedType = function (value) {
                vm.model.bgNeedType.selected = value;
                vm.model.setting.bg_need_type = vm.model.bgNeedType.selected;

                if (vm.model.setting.bg_need_type > '') loadDeliveryPlannings();
            };

            vm.selectBgDeliveryPlanningFunction = function (value) {
                vm.model.bgDeliveryPlanningFunction.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        retrieveLastStatusFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        retrieveLastStatusFunc('stockplace_id');
                        loadStockLocations();
                        loadTrolleys();

                        retrieveStockPlaceId(function () {
                            loadConsignmentShipments();
                        });

                        loadDeliveryPlannings();
                        break;
                    case 'stocklocation_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stocklocation_keyno) !== true) return;

                        vm.model.setting.stocklocation_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stocklocation_keyno) !== true) return;

                        rememberFunc('stocklocation_keyno');
                        break;
                    case 'selecttype_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.selecttype_id) !== true) return;

                        vm.model.setting.selecttype_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.selecttype_id) !== true) return;

                        rememberFunc('selecttype_id');
                        loadDeliveryPlannings();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
                loadDeliveryPlanningTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
