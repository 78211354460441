import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Style } from '@app/core/services/core-component.service';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import SignaturePad from "signature_pad"

/**
 * SIGNATURE PAD MODAL COMPONENT
 * CREATED BY: HSK
 *
 * Modal component featuring a signature field
 * @Returns: svg data url
 *
 * Either user a <tt-signature-pad> or call on this component through the ModalService using
 * openSignaturePadDialog() -- THIS OPTION IS USED FOR BUTTONS OR OTHER NON SIGNATURE FIELDS
 */

@Component({
  selector: 'tt-signature-pad-modal',
  templateUrl: './signature-pad-modal.component.html',
  styleUrls: ['./signature-pad-modal.component.css']
})
export class SignaturePadModalComponent {
    
    @ViewChild('canvas')
    canvasElement!: ElementRef<HTMLCanvasElement>;
    signaturePad!: SignaturePad;
    public message: string = '';
    /**
     * Map over words to translate.
     */
    public translations: { [key: string]: string } = {
        error: 'error_occured',
        signature: 'signature'
    };

    /**
     * Styling for elements in component.
     */
    public style: Style = {
        title: {
            color: 'white',
        },
        button: {},
        invalidtext: {
            color: 'var(--tt-body-text-color)',
        },
    };

    ngAfterViewInit() {
        const canvas = this.canvasElement.nativeElement;
        this.signaturePad = new SignaturePad(canvas);
        this.resizeCanvas();
        window.addEventListener('resize', this.resizeCanvas.bind(this));
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeCanvas.bind(this));
    }

    constructor(public dialogRef: MatDialogRef<SignaturePadModalComponent>, private layoutService: LayoutService, private translateService: TranslateService, private datatask: DataTaskService) {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['title'].fontSize = info.fontSizes.textSizeXL;
                this.style['invalidtext'].fontSize = info.fontSizes.textSize;
                this.style['button'].height = info.height + 4 + 'px';
            }
            
        });
        this.loadModalData();
    }

     clear() {
        this.signaturePad.clear();
     }

    confirm() {
        const signatureDataUrl = this.getSignatureDataUrl();
        if (signatureDataUrl) {
            this.dialogRef.close(signatureDataUrl); // Close the dialog and return the data URL
        } else {
            console.log("No signature data to return.");
            this.dialogRef.close(null); // Close with null if there's no signature
        }
    }

    getSignatureDataUrl(): string | undefined {
        if (this.signaturePad.isEmpty()) {
            console.log("Signature pad is empty.");
            return undefined; 
        }
        const dataUrl = this.signaturePad.toDataURL("image/svg+xml"); 
        console.log("Signature Data URL:", dataUrl);
        return dataUrl; 
    }



    resizeCanvas() {
        console.log("resize");
        const canvas = this.canvasElement.nativeElement;

        // Set the canvas width and height attributes to match the size defined by CSS
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;

        // Adjust the drawing coordinate system for the canvas context
        const context = canvas.getContext('2d');
        if (context) {
            context.scale(ratio, ratio);
        }

        // After resizing, you might want to clear the canvas
        this.signaturePad.clear();

    }
    /**
     * Translates the words in the component.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.values(this.translations));

        for (let key of Object.keys(translations)) {
            Object.entries(this.translations).forEach(([prop, value]) => {
                if (value === key) {
                    this.translations[prop] = translations[key];
                }
            });
        }
    }

    /**
     * Loads data to the component.
     */
    private async loadModalData() {
        await this.translate();
    }
}
