import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import { AppModule } from './app/app.module';
import { UIRouter, UrlService } from "@uirouter/core";
import { environment } from './environments/environment';

import imAppModule from './ng-app/app.module.ajs';

const context = (import.meta as any).webpackContext('./ng-app/content',
  { recursive: true, regExp: /\.js$/ }
);

context.keys().forEach((file: any) => {
  try {
    context(file);
  } catch (err) {
    console.log(err, file);
  }
});

setAngularJSGlobal(angular);

imAppModule.config(['$urlServiceProvider', 'graphAuthServiceProvider', function ($urlService: UrlService) {
    // Using AngularJS config block, call `deferIntercept()`.
    // This tells UI-Router to delay the initial URL sync (until all bootstrapping is complete)
    $urlService.deferIntercept();
}]);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(platformRef => {
    // Intialize the Angular Module
    // get() the UIRouter instance from DI to initialize the router
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

    // Instruct UIRouter to listen to URL changes
    function startUIRouter() {
      urlService.listen();
      urlService.sync();
    }

    platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
  })
  .catch(err => console.log(err));
