(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderHeadDeleteOlService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadOrderHeadDeleteOlGet: function (order_internal_no) {
                return p2DataTaskService.call(1686, {
                    order_internal_no: order_internal_no
                });
            },
            loadOrderHeadDeleteOlList: function (settingDeletedOrderLines) {
                return p2DataTaskService.call(1687, settingDeletedOrderLines);
            },
            deleteItems: function (mergeObj) {
                return p2DataTaskService.call(1688, mergeObj);
            }
        };

        return service;
    }]);
})();