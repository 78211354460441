(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('person', {
        templateUrl: 'views/components/views/person/person.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['personService', '$stateParams', 'modalService', 'stateService', 'utilityService', 'translateService', function (personService, $stateParams, modalService, stateService, us, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            var custNo = $stateParams.cust_no;
            var persNo = $stateParams.pers_no;
            var newCustomer = us.toBoolean($stateParams.new_customer, false);

            var gotoEdiSetupAfterSavingNewPerson = false;

            var translations = {
                tt_ok: '',
                tt_cancel: ''
            };

            vm.phonesList = [];
            vm.inputListPersonFacts = [];

            // Keep for reference, are other properties than records needed?
            vm.phonesListOldObject = {
                phone_number: '',
                persphone_keyno: 0,
                phonetype_name: '',
                records: []
            };

            vm.model = {
                mobileSize: window.matchMedia('(min-width: 990px)'),
                bgPersonInfo: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'bg_personinfo_person', color: 'primary', onClick: () => vm.model.bgPersonInfo.selected = 'BASIC' },
                        { id: 'PERSFACTS', label: 'bg_personinfo_persfacts', color: 'primary', onClick: () => vm.model.bgPersonInfo.selected = 'PERSFACTS' },
                        { id: 'EMAIL', label: 'bg_personinfo_email', color: 'primary', onClick: () => vm.model.bgPersonInfo.selected = 'EMAIL' }
                    ]
                },
                custNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: custNo }) }
                ],
                //phoneNumberButtons: [
                //    //{ id: 'phone', icon: 'fas fa-mobile-alt', color: 'primary', type: 'primary', onClick: () => { return; } }
                //    { id: '$phone' }
                //],
                persEmailButtons: [
                    { id: 'envelope', icon: 'glyphicon glyphicon-envelope', color: 'primary', type: 'primary', onClick: function () { return; } }
                ],
                edit: {},
                locked: false
            };

            vm.options = {
                number: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

		    // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (response) {
                angular.forEach(response, function (value, key) {
                    translations[key] = value;
                });
            });

            personService.loadPersonGet(custNo, persNo).then(function (response) {
                response.cust_no = custNo;
                response.pers_no = persNo;

                angular.copy(response, vm.model.edit);

                gotoEdiSetupAfterSavingNewPerson = newCustomer && response.goto_edi_setup_after_saving_new_person === '1';

                loadPersonFacts();
            });
    
            personService.loadPersonPhonesList(persNo).then(function (response) {
                angular.copy(response, vm.phonesList);
            });

            let loadPersonFacts = function () {
                if (us.validateParmsValue(vm.model.edit.pers_no) !== true) return;

                personService.listPersonFacts({ pers_no: vm.model.edit.pers_no }).then(function (result) {
                    angular.copy(result, vm.inputListPersonFacts);
                });
            };

            vm.selectBgPersonInfo = function (value) {
                vm.model.bgPersonInfo.selected = value;
            };

            vm.goTo = function (state) {
                switch (state) {
                    case 'customersmenu':
                        stateService.go(state, { cust_no: custNo });
                        break;
                }
            };
    
            vm.savePerson = function () {
                if (vm.model.locked === true) return;

                vm.model.locked = true;

                // Added list of phone numbers to person
                vm.model.edit.phonenumbers = vm.phonesList;
                vm.model.edit.persfacts = vm.inputListPersonFacts;
    
                personService.savePerson(vm.model.edit).then(function () {
                    vm.model.locked = false;

                    if (gotoEdiSetupAfterSavingNewPerson === true) {
                        // BJS 20220930 - Go to edi setup
                        stateService.go('edisetup', { argtype: 'cust_no', argvalue: custNo, new_customer: true });
                    } else {
                        stateService.back();
                    }
                });
            };
    
            vm.deletePerson = function () {
                modalService.show({
                    type: 'danger',
                    title: 'Confirmation',
                    message: translations.tt_confirm_delete_person,
                    buttons: [{
                        label: translations.tt_ok,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
    
                            personService.deletePerson().then(function () {
                                stateService.back();
                            });
                        }
                    }, {
                        label: translations.tt_cancel,
                        close: true
                    }]
                });
            }
        }]
    });
})();
