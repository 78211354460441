(function () {
    'use strict';

    angular.module("imApp").factory("metaColumnService", ['$ihttp', function ($ihttp) {
        var service = {
            getMetaColumn: function (parms) {
                return $ihttp.post({
                    method: 2377,
                    parameters: parms || {}
                });
            },
            listElementTypes: function (parms) {
                return $ihttp.post({
                    method: 2378,
                    parameters: parms || {}
                });
            },
            getTranslatedColumnName: function (parms) {
                return $ihttp.post({
                    method: 2380,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2379,
                    parameters: edit
                });
            },
            deleteObj: function (edit) {
                return $ihttp.post({
                    method: 2384,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();