(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('deliveryTerm', {
        templateUrl: 'views/components/views/deliveryTerm/deliveryTerm.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'deliveryTermService', function($stateParams, stateService, modalService, deliveryTermService) {
            var vm = this;
            
            vm.model = {
                deliveryTermNo: $stateParams.deliveryterm_no,
                term: {},
                lockedSave: false
            };
            
            deliveryTermService.loadDeliveryTermGet(vm.model.deliveryTermNo).then(function (data) {
                vm.model.term = angular.copy(data[0]);
            });
            
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'deliverytermlangs':
                        go({
                            deliveryterm_no: vm.model.term.deliveryterm_no
                        });
                        break;
                }
            };
            
            vm.saveDeliveryTerm = function () {
                vm.model.lockedSave = true;
                
                deliveryTermService.save(vm.model.term).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();