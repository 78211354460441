(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customerInvoicing', {
        templateUrl: 'views/components/views/customerInvoicing/customerInvoicing.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'customerInvoicingService', function ($stateParams, stateService, utilityService, modalService, customerInvoicingService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                setting: {},
                gridReady: false,
                creatingInvoice: false,
                settingNotInvoice: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        { key: 'is_selected' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            customerInvoicingService.getCustomerInvoicing({
                argtype: vm.model.argType,
                argvalue: vm.model.argValue
            }).then(function (data) {
                angular.copy(data[0], vm.model.setting);

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
		    	    vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

		        if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
		    	    vm.grid.dataTask.loadData = {
		    		    method: vm.model.setting.p2_datatask_keyno,
		    		    parameters: vm.model.setting
		    	    };
		        }

		        vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.create = function () {
                vm.model.creatingInvoice = true;

                var allData = vm.grid.gridfunc.getAllRows().map(function (a) { return { "powtime_keyno": a.powtime_keyno, "is_selected": a.is_selected }; });
                var mergeObj = vm.model.setting;
                mergeObj.records = allData;

                customerInvoicingService.createInvoice(mergeObj).then(function (data) {
                    if (data[0].errorcode === '-1') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingInvoice = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.creatingInvoice = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON SET NOT PROCEDURE FUNCTION CALL

            vm.setNot = function () {
                vm.model.settingNotInvoice = true;

                var allData = vm.grid.gridfunc.getAllRows().map(function (a) { return { "powtime_keyno": a.powtime_keyno, "is_selected": a.is_selected }; });
                var mergeObj = vm.model.setting;
                mergeObj.records = allData;

                customerInvoicingService.setNotInvoice(mergeObj).then(function (data) {
                    if (data[0].errorcode === '-1') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.settingNotInvoice = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.settingNotInvoice = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SET NOT PROCEDURE FUNCTION CALL
        }]
    });
})();
