(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2ImportProc', {
        templateUrl: 'views/components/views/p2ImportProc/p2ImportProc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2ImportProcService', function($stateParams, stateService, p2ImportProcService) {
    
            var vm = this;
    
    
    
            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################
    
            vm.model = {
                edit: {},
                p2EncodingTypeListExt: {
                    records: []
                },
                lockedSave: false
            };
    
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
    
            vm.loadP2ImportProcGet = function () {
                p2ImportProcService.genericFunction(878, {
                    p2_importproc_keyno: $stateParams.p2_importproc_keyno
                }).then(function (data) {
                    vm.model.edit = angular.copy(data[0]);

                    vm.loadP2EncodingTypeListExt();
                });
            };
    
            vm.loadP2ImportProcGet();
            
            vm.loadP2EncodingTypeListExt = function () {
                //vm.model.p2EncodingTypeListExt.records = [];

                p2ImportProcService.genericFunction(886, {}).then(function (data) {
                    angular.copy(data, vm.model.p2EncodingTypeListExt.records);
                });
            };
    
            //vm.loadP2EncodingTypeListExt();
            
    		//#######################
    		//## SAVE BUTTON FUNCTION
    		//#######################
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2ImportProcService.saveObj(vm.model.edit).then(function (response) {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };
        }]
    });
})();
