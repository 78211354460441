(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDeliveryListService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            p2_batchdelivery_list: {
                item_glyphicon: '',
                item_id: '',
                item_name: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            p2_batchdelivery_create: {
                webpagename: '',
                parms: ''
            },
            loadStockLocationList: function (stockzoneclass_keyno, stockplace_id, add_all) {
                return p2DataTaskService.call(1029, {
                    stockzoneclass_keyno: stockzoneclass_keyno,
                    stockplace_id: stockplace_id,
                    add_all: add_all
                });
            },
            setPortalUserVariable: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            loadPortalUserVariableGet: function (variablename) {
                return p2DataTaskService.call(973, {
                    variablename: variablename
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2BatchDeliveryList: function (status, businessco_no, stockplace_id, stocklocation_keyno) {
                var deferred = $q.defer();

                var parms_p2_batchdelivery_list = {
                    method: 278,
                    parameters: {
                        status: status,
                        businessco_no: businessco_no,
                        stockplace_id: stockplace_id,
                        stocklocation_keyno: stocklocation_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelivery_list).then(function (data) {
                    angular.copy(data, service.p2_batchdelivery_list.records);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            createP2BatchDelivery: function (businessco_no, stockplace_id, stocklocation_keyno) {
                var deferred = $q.defer();

                var parms_p2_batchdelivery_create = {
                    method: 280,
                    parameters: {
                        businessco_no: businessco_no,
                        stockplace_id: stockplace_id,
                        stocklocation_keyno: stocklocation_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelivery_create).then(function (data) {
                    service.p2_batchdelivery_create = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
