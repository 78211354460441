(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ftpAccountService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2FtpAccountGet: function (ftpaccount_keyno) {
                return p2DataTaskService.call(969, {
					ftpaccount_keyno: ftpaccount_keyno
				});
			},
            saveP2FtpAccount: function (p2FtpAccountGet) {
                return p2DataTaskService.call(970, p2FtpAccountGet);
            },
            deleteP2FtpAccount: function (p2FtpAccountGet) {
                return p2DataTaskService.call(971, p2FtpAccountGet);
            }
        };

        return service;
    }]);
})();