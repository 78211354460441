(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('dataMining', {
        templateUrl: 'views/components/views/dataMining/dataMining.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$rootScope', '$stateParams', '$q', '$timeout', 'stateService', 'utilityService', 'dataMiningService', 'rememberService', 'ttGridFactory', function ($rootScope, $stateParams, $q, $timeout, stateService, utilityService, dataMiningService, rememberService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2StatSourceKeyno = $stateParams.p2_statsource_keyno;
            let levelNo = $stateParams.level_no;

            let variableNames = {
                chart_type: ''
            };

            vm.model = {
                gridReady: false,
                mobileSize: window.matchMedia('(max-width: 992px)'),
                numberColumnName2Buttons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('number_colname2') }
                ],
                numberColumnName3Buttons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('number_colname3') }
                ],
                bgDataminingSelected: 'GRAPH',

                bgDataMiningDesktop: {
                    buttons: [
                        { id: 'GRAPH', label: 'bg_datamining_graph', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'GRAPH' },
                        { id: 'GRID', label: 'bg_datamining_grid', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'GRID' },
                        //{ id: 'LIST', label: 'bg_datamining_list', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'LIST' },
                        { id: 'BOTH', label: 'bg_datamining_both', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'BOTH' }
                    ]
                },
                
                bgDataMiningMobile: {
                    buttons: [
                        { id: 'GRAPH', label: 'bg_datamining_graph', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'GRAPH' },
                        //{ id: 'GRID', label: 'bg_datamining_grid', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'GRID' },
                        { id: 'LIST', label: 'bg_datamining_list', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'LIST' },
                        { id: 'BOTH', label: 'bg_datamining_both', color: 'primary', onClick: () => vm.model.bgDataminingSelected = 'BOTH' }
                    ]
                },
                bgChartType: {
                    selected: 'column',
                    buttons: [
                        { id: 'column', label: 'bg_datamining_charttype_column', color: 'primary', onClick: () => setChartTypeView('column') },
                        { id: 'pie', label: 'bg_datamining_charttype_pie', color: 'primary', onClick: () => setChartTypeView('pie') }
                    ]
                },
                columnNameButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-file-excel',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('dataminingtable', {
                            p2_statsource_keyno: vm.model.setting.p2_statsource_keyno,
                            level_no: vm.model.setting.level_no
                        })
                    }
                ],
                setting: {},
                selectListGroupings: [],
                selectListNumbers: [],
                selectListNumbers2: [],
                selectListNumbers3: [],
                selectListNumberColname1: [],
                selectListNumberColname2: [],
                selectListNumberColname3: [],
                itemsListMiningDatas: {
                    orderby: 'orderby',
                    records: []
                }
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_datamining.grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: true,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data);
                    }, // Used for referencing functions in the current Component,
                gridfunc: null
            };

             //#endregion VARIABLES & DEFINITIONS

            const optionfunc = function (data) {
                if (data.func && data.func === 'CellClickHandler') {
                    var fetchObject = vm.model.setting;
                    fetchObject.item_name = data.clickedCell.dataItem[vm.model.setting.grouping_colname];
                    dataMiningService.addNextLevel(fetchObject).then(function (response) {
                        stateService.go(response[0].webpagename, response[0].parms);
                    });
                }
            };

            var loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3161,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
                console.log('gridReady: ' + vm.model.gridReady);
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setChartTypeView = function (id) {
                if (vm.model.setting.chart_type > '') {
                    vm.model.bgChartType.selected = vm.model.setting.chart_type = id;
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            let loadSetting = function () {
                let deferred = $q.defer();

                dataMiningService.getSetting({
                    p2_statsource_keyno: p2StatSourceKeyno,
                    level_no: levelNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setChartTypeView(vm.model.setting.chart_type);

                    $q.all([
                        loadGroupings(),
                        loadNumbers()
                    ]).then(() => {
                        loadMiningDatas();
                        loadGrid();
                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadGroupings = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_statsource_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.level_no) !== true) return;

                return dataMiningService.listGroupings({
                    p2_statsource_keyno: vm.model.setting.p2_statsource_keyno,
                    level_no: vm.model.setting.level_no
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListGroupings);
                });
            };

            let loadNumbers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_statsource_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.level_no) !== true) return;

                return dataMiningService.listNumbers({
                    p2_statsource_keyno: vm.model.setting.p2_statsource_keyno,
                    level_no: vm.model.setting.level_no
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListNumbers);
                    angular.copy(result, vm.model.selectListNumbers2);
                    angular.copy(result, vm.model.selectListNumbers3);

                    vm.onNumberColnameSelect();
                });
            };

            let loadMiningDatas = function () {
                if (utilityService.validateParmsValue(vm.model.setting.grouping_colname) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.number_colname1) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.number_colname2, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.number_colname3, true) !== true) return;

                vm.model.itemsListMiningDatas.records = [];

                return dataMiningService.listMiningDatas(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListMiningDatas.records);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'chart_type':
                            if (utilityService.validateParmsValue(vm.model.setting.grouping_colname) !== true) return;

                            variableNames[key] = stateService.getCurrentName() + '.' + vm.model.setting.grouping_colname;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            console.log(variableNames[key]);
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'chart_type':
                        if (utilityService.validateParmsValue(vm.model.setting.chart_type) !== true) return;

                        variableValue = vm.model.setting.chart_type;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                if (id === 'number_colname2') {
                    vm.model.setting.number_colname3 = '';
                }
                //vm.model.setting[id] = '';
                vm.onChanged('', id);
                //switch (id) {
                //    case 'number_colname2':
                //        vm.model.setting.number_colname2 = '';

                //        if (vm.model.setting.number_colname2 === '') vm.model.setting.number_colname3 = '';
                //        break;
                //    case 'number_colname3':
                //        vm.model.setting.number_colname3 = '';
                //        break;
                //    default:
                //        break;
                //}
                //vm.onNumberColnameSelect();
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgDataMining = function (value) {
                vm.model.bgDataminingSelected = value;

                $timeout(function () {
                    kendo.resize($("div.k-chart[data-role='chart']"));

                    $rootScope.$apply();
                }, 100);
            };

            vm.selectBgChartType = function (value) {
                vm.model.bgChartType.selected = value;
                vm.model.setting.chart_type = vm.model.bgChartType.selected;

                rememberFunc('chart_type');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'dataminingtable':
                    case 'dataminingcreate':
                        go({
                            p2_statsource_keyno: vm.model.setting.p2_statsource_keyno,
                            level_no: vm.model.setting.level_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            vm.fetchItemName = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                rememberFunc('chart_type');

                var fetchObject = vm.model.setting;
                fetchObject.item_name = item.item_name;

                if (item.gotolink === '0') {
                    dataMiningService.addNextLevel(fetchObject).then(function (response) {
                        stateService.go(response[0].webpagename, response[0].parms);
                    });
                } else {
                    stateService.go(item.item_state, item.item_parms);
                }
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'grouping_colname':
                        if (vm.model.setting.grouping_colname === undefined) return;

                        vm.model.setting.grouping_colname = value;

                        //if (utilityService.validateParmsValue(vm.model.setting.grouping_colname) !== true) return;

                        if (vm.model.setting.grouping_colname === undefined) return;

                        rememberFunc('chart_type');
                        loadMiningDatas();
                        if (vm.grid && vm.grid.gridfunc) vm.grid.gridfunc.rebind();
                        break;
                    case 'number_colname1':
                        if (vm.model.setting.number_colname1 === undefined) return;
                        vm.model.setting.number_colname1 = value;
                        if (vm.model.setting.number_colname1 === undefined) return;
                        vm.onNumberColnameSelect();
                        loadMiningDatas();
                        if (vm.grid && vm.grid.gridfunc) vm.grid.gridfunc.rebind();
                        break;
                    case 'number_colname2':
                    case 'number_colname3':
                        if (vm.model.setting[id] === undefined) return;
                        vm.model.setting[id] = value;
                        if (vm.model.setting[id] === undefined) return;
                        vm.onNumberColnameSelect();
                        loadMiningDatas();
                        if (vm.grid && vm.grid.gridfunc) vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.onNumberColnameSelect = function () {
                vm.model.selectListNumberColname1 = vm.model.selectListNumbers.filter(e => ![vm.model.setting.number_colname2, vm.model.setting.number_colname3].includes(e.grouping_colname));
                vm.model.selectListNumberColname2 = vm.model.selectListNumbers2.filter(e => ![vm.model.setting.number_colname1, vm.model.setting.number_colname3].includes(e.grouping_colname));
                vm.model.selectListNumberColname3 = vm.model.selectListNumbers3.filter(e => ![vm.model.setting.number_colname1, vm.model.setting.number_colname2].includes(e.grouping_colname));
            }

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
