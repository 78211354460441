(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('jobSchedule', {
        templateUrl: 'views/components/views/jobSchedule/jobSchedule.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'jobScheduleService', function ($stateParams, stateService, utilityService, modalService, translateService, jobScheduleService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                jobScheduleKeyNo: $stateParams.jobschedule_keyno,
                bgJobScheduleMain: {
                    selected: 'BASIS',
                    buttons: [
                        { id: 'BASIS', label: 'gb_jobschedule_main_basis', color: 'primary', onClick: () => vm.model.bgJobScheduleMain.selected = 'BASIS' },
                        { id: 'PROC', label: 'gb_jobschedule_main_proc', color: 'primary', onClick: () => vm.model.bgJobScheduleMain.selected = 'PROC' },
                        { id: 'STAT', label: 'gb_jobschedule_main_stat', color: 'primary', onClick: () => vm.model.bgJobScheduleMain.selected = 'STAT' }
                    ]
                },
                schedule: {},
                selectListRepeatEveryTypes: [],
                selectListRepeatEveryNumbers: [],
                addingToQueue: false,
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                jobScheduleService.loadJobScheduleGet(vm.model.jobScheduleKeyNo).then(function (data) {
                    angular.copy(data[0], vm.model.schedule);

                    vm.model.schedule.execute_at_time = moment.utc(vm.model.schedule.execute_at_time).local().format('YYYY-MM-DD HH:mm:ss');
                    vm.model.schedule.execute_from_time = moment.utc(vm.model.schedule.execute_from_time).local().format('YYYY-MM-DD HH:mm:ss');
                    vm.model.schedule.execute_to_time = moment.utc(vm.model.schedule.execute_to_time).local().format('YYYY-MM-DD HH:mm:ss');

                    vm.model.schedule.last_add_to_jobqueue_dt = moment.utc(vm.model.schedule.last_add_to_jobqueue_dt).local().format('YYYY-MM-DD HH:mm:ss');
                    vm.model.schedule.last_processing_end_dt = moment.utc(vm.model.schedule.last_processing_end_dt).local().format('YYYY-MM-DD HH:mm:ss');
                    vm.model.schedule.last_processing_start_dt = moment.utc(vm.model.schedule.last_processing_start_dt).local().format('YYYY-MM-DD HH:mm:ss');
                    vm.model.schedule.next_run_datetime = moment.utc(vm.model.schedule.next_run_datetime).local().format('YYYY-MM-DD HH:mm:ss');

                    loadRepeatEveryTypes();
                    loadRepeatEveryNumbers();
                });
            };
            
            var loadRepeatEveryTypes = function () {
                vm.model.selectListRepeatEveryTypes = [];
                
                jobScheduleService.loadRepeatEveryTypeList().then(function (data) {
                    angular.copy(data, vm.model.selectListRepeatEveryTypes);
                });
            };
            
            var loadRepeatEveryNumbers = function () {
                if (utilityService.validateParmsValue(vm.model.schedule.repeat_every_type) !== true) return;
                
                jobScheduleService.loadRepeatEveryNumberList(vm.model.schedule.repeat_every_type).then(function (data) {
                    angular.copy(data, vm.model.selectListRepeatEveryNumbers);
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgJobScheduleMain = function (value) {
                vm.model.bgJobScheduleMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.addToQueueChanges = function () {
                vm.model.addingToQueue = true;
                var parms = angular.copy(vm.model.schedule);

                let date_today = new Date().toISOString().slice(0, 10);

                parms.execute_at_time = date_today + ' ' + parms.execute_at_time;
                parms.execute_from_time = date_today + ' ' + parms.execute_from_time;
                parms.execute_to_time = date_today + ' ' + parms.execute_to_time;

                parms.execute_at_time = moment(parms.execute_at_time, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                parms.execute_from_time = moment(parms.execute_from_time, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                parms.execute_to_time = moment(parms.execute_to_time, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');

                jobScheduleService.saveObj(parms).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.addingToQueue = false;
                                }
                            }]
                        });
                    } else {
                        jobScheduleService.addToQueue(parms).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.addingToQueue = false;
                                        }
                                    }]
                                });
                            } else {
                                vm.model.addingToQueue = false;

                                loadEdit();
                            }
                        });
                    }
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var parms = angular.copy(vm.model.schedule);

                let date_today = new Date().toISOString().slice(0, 10);

                parms.execute_at_time = date_today + ' ' + parms.execute_at_time;
                parms.execute_from_time = date_today + ' ' + parms.execute_from_time;
                parms.execute_to_time = date_today + ' ' + parms.execute_to_time;

                parms.execute_at_time = moment(parms.execute_at_time, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                parms.execute_from_time = moment(parms.execute_from_time, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                parms.execute_to_time = moment(parms.execute_to_time, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');

                jobScheduleService.saveObj(parms).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'repeat_every_type':
                        if (utilityService.validateWatchValue(value, vm.model.schedule.repeat_every_type) !== true) return;

                        vm.model.schedule.repeat_every_type = value;

                        if (utilityService.validateParmsValue(vm.model.schedule.repeat_every_type) !== true) return;

                        loadRepeatEveryNumbers();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
