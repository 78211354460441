(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('metaTableData', {
        templateUrl: 'views/components/views/metaTableData/metaTableData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'utilityService', 'base64', 'stateService', 'modalService', 'metaTableDataService', 'metaColumnService', 'rememberService', 'modalDynamicViewFactory', function ($stateParams, $timeout, $q, utilityService, base64, stateService, modalService, metaTableDataService, metaColumnService, rememberService, modalDynamicViewFactory) {
            const vm = this;
            let metaTableKeyno = $stateParams.metatable_keyno;
            let metaTableViewKeyno = $stateParams.metatableview_keyno;

            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => searchAndRememberGrid() }
                ],
                columnNameButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('colname') }
                ],
                setting: {},
                selectListColumns: [],
                readyNewValueHE: true,
                gridReady: false,
                lockedSave: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: true,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'bulkEdit', text: 'tt_bulk_col_edit', func: function () { callBulkEditPopup(true); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { onColumnsChanged(); } }
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            { name: 'lineEdit', text: '', func: function (data) { callLineEditPopup(true, data); }, icon: 'far-pencil', disabled: function () { return false; } }
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        minHeight: '500px',
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #region BUTTON FUNCTIONS

            var getSortedEditableCols = function (key) {
                var gridCols = vm.grid.gridfunc.getGridColumns();
                var editableCols = [];
                var sortedEditableCols = [];

                angular.forEach(vm.grid.gridfunc.getColumnSchema(), function (col) {
                    if (col.from === 'is_selected') return;
                    
                    if (angular.isDefined(col.editable) && col.editable !== false) {
                        var editType = null;
                        switch (col.editType) {
                            case 'CB':
                                editType = 'checkbox';
                                break;
                            case 'T':
                                editType = 'input';
                            case 'TL':
                                editType = 'elastic';
                                break;
                            case 'HE':
                                editType = 'textarea';
                                break;
                            case 'DA':
                                editType = 'datetime';
                                break;
                            case 'N0':
                            case 'N1':
                            case 'N2':
                                editType = 'number' + col.editType.slice(1);
                                break;
                        }

                        if (editType !== null) {
                            var disabled = false;
                            for (var i = 0; i < gridCols.length; i++) {
                                if (col.from === gridCols[i].field) {
                                    if (angular.isUndefined(gridCols[i].hidden) && angular.isUndefined(gridCols[i].hide) || (gridCols[i].hidden !== true && gridCols[i].hide !== true)) break;
                                    disabled = 'hidden';
                                    break;
                                }
                            }

                            editableCols.push({
                                name: col.from,
                                info: {
                                    label: col.from,
                                    placeholder: '',
                                    type: editType,
                                    translate: false,
                                },
                                data: {
                                    field: '',
                                },
                                disabled: disabled
                            });
                        }
                    }
                });

                angular.forEach(gridCols, function (col) {
                    for (var c = 0; c < editableCols.length; c++) {
                        if (col.field === editableCols[c].name) {
                            sortedEditableCols.push(editableCols[c]);
                            break;
                        }
                    }
                });

                return sortedEditableCols;
            };

            var getIsSelectedItemsParm = function (key) {
                var dirtyItems = vm.grid.gridfunc.getDirtyRows();
                var isSelectedItems = [];

                //if (angular.isUndefined(key)) key = 'meta_product_keyno';

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(key)) {
                        if (angular.isDefined(item) && item.is_selected === true && angular.isDefined(item[key])) isSelectedItems.push(item[key]);
                    } else {
                        if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                    }
                });

                return isSelectedItems;
            };

            var hasDirty = function (data) {
                var parmItems = getIsSelectedItemsParm();

                if (parmItems.length > 0) return false;

                return true;
            };

            var callLineEditPopup = function (rebind, row) {
                var sortedEditableCols = getSortedEditableCols();

                angular.forEach(row, function (r, key) {
                    for (var s = 0; s < sortedEditableCols.length; s++) {
                        if (key === sortedEditableCols[s].name) {
                            if (sortedEditableCols[s].info.type === 'checkbox') {
                                if (r === true)
                                    sortedEditableCols[s].data.field = '1';
                                else
                                    sortedEditableCols[s].data.field = '0';
                            } else {
                                sortedEditableCols[s].data.field = r;
                            }

                            break;
                        }
                    }
                });

                initEditPopup(sortedEditableCols).then(function (config) {
                    angular.forEach(row, function (r, key) {
                        for (var c = 0; c < config.length; c++) {
                            if (key === config[c].name) {
                                if (config[c].info.type === 'checkbox') {
                                    if (config[c].data.field === '1')
                                        row[key] = true;
                                    else
                                        row[key] = false;
                                } else {
                                    row[key] = config[c].data.field;

                                    if (config[c].info.type === 'textarea') {
                                        console.dir(row[key]);
                                        console.dir(config[c].data.field);
                                    }
                                }

                                break;
                            }
                        }
                    });

                    vm.grid.gridfunc.refresh();
                });
            };

            // #endregion BUTTON FUNCTIONS

            // #region TOOLBAR BUTTONFUNC

            var callBulkEditPopup = function (rebind, remValue) {
                var sortedEditableCols = getSortedEditableCols();

                //remValue = angular.isDefined(remValue) ? remValue : null;
                initEditPopup(sortedEditableCols).then(function (config) {
                    var hasChanges = false;

                    angular.forEach(config, function (item) {
                        if (item.data.field !== '') {
                            hasChanges = true;
                        }
                    });

                    if (hasChanges) {
                        var dirtyRows = vm.grid.gridfunc.getDirtyRows();

                        angular.forEach(dirtyRows, function (row) {
                            if (angular.isDefined(row.is_selected) && row.is_selected === true) {
                                angular.forEach(config, function (item) {
                                    if (item.data.field === '') return;

                                    row[item.info.label] = item.data.field === ' ' ? '' : item.data.field;
                                });
                            }
                        });

                        vm.grid.gridfunc.refresh();
                    }
                });
            };

            var initEditPopup = function (remValue) {
                var deferred = $q.defer();

                var icbConfigPopup = modalDynamicViewFactory.$create({
                    cancel: true,
                    size: 'lg',
                    data: remValue
                });

                icbConfigPopup.show().then(function (config) {
                    return deferred.resolve(config);
                });

                return deferred.promise;
            };

            // #endregion TOOLBAR BUTTONFUNC

            let loadSetting = function () {
                const deferred = $q.defer();

                metaTableDataService.getMetaTableData({
                    metatable_keyno: metaTableKeyno,
                    metatableview_keyno: metaTableViewKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadMetaColumns();
                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadMetaColumns = function () {
                if (utilityService.validateParmsValue(vm.model.setting.metatable_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.metatableview_keyno) !== true) return;

                return metaTableDataService.listMetaColumns({
                    metatable_keyno: vm.model.setting.metatable_keyno,
                    metatableview_keyno: vm.model.setting.metatableview_keyno
                }).then(function (list) {
                    angular.copy(list, vm.model.selectListColumns);
                });
            };

            let retrieveMetaColumn = function () {
                if (utilityService.validateParmsValue(vm.model.setting.metatable_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.colname) !== true) return;

                return metaColumnService.getMetaColumn({
                    metatable_keyno: vm.model.setting.metatable_keyno,
                    colname: vm.model.setting.colname
                }).then(function (response) {
                    vm.model.setting.elementtype = response[0].elementtype;
                });
            };

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.metatable_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.metatableview_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                }

                vm.grid.dataTask.loadData = {
                    method: vm.model.setting.p2_datatask_keyno,
                    parameters: {
                        metatable_keyno: vm.model.setting.metatable_keyno,
                        metatableview_keyno: vm.model.setting.metatableview_keyno,
                        searchtext: vm.model.setting.searchtext
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'searchtext':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '_' + vm.model.setting.metatable_keyno + '_' + vm.model.setting.metatableview_keyno;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadGrid();
                        }
                        break;
                    case 'colname':
                        vm.model.setting.colname = '';
                        vm.model.setting.elementtype = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            let searchAndRememberGrid = function () {
                if (vm.model.setting.searchtext > '') {
                    rememberFunc('searchtext');
                    loadGrid();
                }
            };

            vm.searchGrid = function () {
                searchAndRememberGrid();
            };

            vm.retrieveChanges = function () {
                loadGrid();
            };

            vm.changeSelected = function () {
                var newValue = null;

                switch (vm.model.setting.elementtype) {
                    case 'CB':
                        newValue = vm.model.setting.new_value_CB;
                        break;
                    case 'DA':
                        newValue = vm.model.setting.new_value_DA;
                        break;
                    case 'DT':
                        newValue = vm.model.setting.new_value_DT;
                        break;
                    case 'HE':
                        newValue = vm.model.setting.new_value_HE;
                        break;
                    case 'N0':
                        newValue = vm.model.setting.new_value_N0;
                        break;
                    case 'N1':
                        newValue = vm.model.setting.new_value_N1;
                        break;
                    case 'N2':
                        newValue = vm.model.setting.new_value_N2;
                        break;
                    case 'T':
                        newValue = vm.model.setting.new_value_T;
                        break;
                    case 'TL':
                        newValue = vm.model.setting.new_value_TL;
                        break;
                    case 'TI':
                        newValue = vm.model.setting.new_value_TI;
                        break;
                    default:
                        break;
                }

                var allData = vm.grid.gridfunc.getAllRows();
                var colDataType = typeof allData[0][vm.model.setting.colname];
                var newValueRightType = null;

                switch (colDataType) {
                    case 'number':
                        newValueRightType = parseFloat(newValue);
                        break;
                    case 'boolean':
                        newValueRightType = newValue === '1';
                        break;
                    default:
                        newValueRightType = newValue;
                }

                angular.forEach(allData, function (item) {
                    if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                        item[vm.model.setting.colname] = newValueRightType;
                    }
                });

                vm.grid.gridfunc.refresh();
            };

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'Produkt ID: ' + items[step].prod_id;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        var parms = JSON.parse(JSON.stringify(items[step]));

                        angular.forEach(vm.model.selectListColumns, function (item) {
                            if (typeof parms[item.item_id] === 'string') {
                                parms[item.item_id] = base64.urlencode(parms[item.item_id]);
                            }
                        });

                        parms.metatable_keyno = vm.model.setting.metatable_keyno;

                        var mergeObj = {
                            rawdata: parms
                        };

                        metaTableDataService.saveSelected(mergeObj).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var dirtyRows = vm.grid.gridfunc.getDirtyRows();
                vm.model.progressInfo.max = dirtyRows.length;
                vm.activateProgress(dirtyRows);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.model.lockedSave = false;
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.model.lockedSave = false;
                        loadGrid();
                    }
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadGrid();
                        }
                        break;
                    case 'colname':
                        if (utilityService.validateWatchValue(value, vm.model.setting.colname) !== true) return;

                        vm.model.setting.colname = value;

                        if (utilityService.validateParmsValue(vm.model.setting.colname) !== true) return;

                        retrieveMetaColumn();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
