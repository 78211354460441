(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryOrderStatusError', {
        templateUrl: 'views/components/views/deliveryOrderStatusError/deliveryOrderStatusError.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['layoutService', function (layoutService) {
            var vm = this;

            var onDestroy = [];

            vm.errors = [];
            vm.translations = {};
            vm.fontSize = null;

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            vm.close = function () {
                vm.modalInstance.close();
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                var parameters = vm.resolve.parameters;

                angular.copy(parameters.errormessage.data, vm.errors);

                angular.forEach(parameters.errormessage.translations, function (val) {
                    vm.translations[val.id] = val.value;
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
