(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('usergroupSettingEdit', {
        templateUrl: 'views/components/views/usergroupSettingEdit/usergroupSettingEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'usergroupSettingEditService', 'stateService', function ($stateParams, usergroupSettingEditService, stateService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let itemId = $stateParams.item_id;

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                usergroupSettingEditService.getEdit({ p2_usergroup_setting_keyno: itemId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                usergroupSettingEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT LOAD FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT LOAD FUNCTION
        }]
    });
})();
