(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srRefundBasis', {
        templateUrl: 'views/components/views/srRefundBasis/srRefundBasis.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'rememberService', 'consignmentPlansService', 'srRefundBasisService', 'watcherFactory', function($stateParams, $q, $timeout, stateService, utilityService, modalService, rememberService, consignmentPlansService, srRefundBasisService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            var editing = false;
    
            var variableNames = {
                srcrew_keyno: '',
                srequipment_keyno: '',
                bg_srrefundbasis_main: ''
            };
    
            vm.model = {
                custactNo: $stateParams.custact_no,
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                bgSrRefundBasisMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_srrefundbasis_main_fromrefund', item_func: 'FROMREFUND' },
                        { item_id: '1', item_name: 'bg_srrefundbasis_main_fromreports', item_func: 'FROMREPORTS' }
                    ]
                },
                srCrewKeyNoBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                srEquipmentKeyNoBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                mconfig: {
                    enabled: true,		// Is the menu button enabled
                    show: false,		// Is the menu visible or not
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfig.records[0].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.resizeRefunds();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else {
                            vm.model.mconfig.records[0].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                editing = true;
                            } else {
                                vm.resizeRefunds();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '1', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                mconfigFromRefund: {
                    enabled: true,		// Is the menu button enabled
                    show: false,		// Is the menu visible or not
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfigFromRefund.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfigFromRefund.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfigFromRefund.records[0].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfigFromRefund.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.resizeFromRefunds();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfigFromRefund.records[item.item_id].isActive = false;
                        } else {
                            vm.model.mconfigFromRefund.records[0].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                editing = true;
                            } else {
                                vm.resizeFromRefunds();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '1', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                refund: {},
                selectListSrCrews: [],
                selectListSrEquipments: [],
                selectListResources: [],
                selectListCheckList: [],
                itemsListFromReports: [],
                itemsListFromRefunds: []
            };
    
            // ## INIT LOAD FUNCTION ##
    
            var initLoadDone = false;
            var timerInitLoadDone = null;
    
            var initLoadFunc = function () {
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }
    
                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srRefundBasisService.loadSrRefundBasisGet(vm.model.custactNo, vm.model.checkListDataKeyNo).then(function (data) {
                vm.model.refund = angular.copy(data[0]);
                vm.model.bgSrRefundBasisMain.item_default = utilityService.groupButtonIdOf(vm.model.bgSrRefundBasisMain, vm.model.refund.bg_srrefundbasis_main);
    
                initLoadFunc();
                
                loadSrRefundBasisCheckListItems();
                loadSrRefundBasisItems();
                loadSrRefundBasisRefundItems();
            });
    
            srRefundBasisService.loadSrRefundBasisSrCrewList(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.selectListSrCrews);
            });
    
            srRefundBasisService.loadSrRefundBasisSrEquipmentList(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.selectListSrEquipments);
            });
    
            srRefundBasisService.loadSrRefundBasisResourceList(vm.model.custactNo, vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.selectListResources);
            });
    
            var loadSrRefundBasisCheckListItems = function () {
                srRefundBasisService.loadSrRefundBasisCheckListItemList(vm.model.refund).then(function (data) {
                    angular.copy(data, vm.model.selectListCheckList);
                });
            };
    
            var loadSrRefundBasisItems = function () {
                if (utilityService.validateParmsValue(vm.model.refund.special_resource_id_from) !== true) return;
                if (utilityService.validateParmsValue(vm.model.refund.checklistitem_keyno, true) !== true) return;
    
                vm.model.itemsListFromReports = [];
    
                srRefundBasisService.loadSrRefundBasisItemList(vm.model.refund).then(function (data) {
                    angular.copy(data, vm.model.itemsListFromReports);
                });
            };
    
            var loadSrRefundBasisRefundItems = function () {
                if (utilityService.validateParmsValue(vm.model.refund.special_resource_id_from) !== true) return;
                if (utilityService.validateParmsValue(vm.model.refund.checklistitem_keyno, true) !== true) return;
    
                vm.model.itemsListFromRefunds = [];
    
                srRefundBasisService.loadSrRefundBasisRefundItemList(vm.model.refund).then(function (data) {
                    angular.copy(data, vm.model.itemsListFromRefunds);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberFunc = function (id) {
                var deferred = $q.defer();
    
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
    
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
    
                var variableValue = null;
    
                switch (id) {
                    case 'bg_srrefundbasis_main':
                        if (initLoadDone !== true) return;
                        if (utilityService.validateParmsValue(vm.model.refund.bg_srrefundbasis_main) !== true) return;
    
                        variableValue = vm.model.refund.bg_srrefundbasis_main;
                        break;
                    case 'srcrew_keyno':
                        if (utilityService.validateParmsValue(vm.model.refund.srcrew_keyno) !== true) return;
    
                        variableValue = vm.model.refund.srcrew_keyno;
                        break;
                    case 'srequipment_keyno':
                        if (utilityService.validateParmsValue(vm.model.refund.srequipment_keyno) !== true) return;
    
                        variableValue = vm.model.refund.srequipment_keyno;
                        break;
                    default:
                        break;
                }
    
                rememberService.remember(variableNames[id], variableValue).then(function () {
                    deferred.resolve();
                });
    
                return deferred.promise;
            };
    
            var rememberSpecialResourceIdFrom = function () {
                var variableNameSpecialResourceIdFrom = 'w_srrefundbasis.special_resource_id_from';
    
                if (utilityService.validateParmsValue(variableNameSpecialResourceIdFrom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.refund.special_resource_id_from) !== true) return;
    
                rememberService.remember(variableNameSpecialResourceIdFrom, vm.model.refund.special_resource_id_from);
            };
    
            var rememberCheckListItemKeyNo = function () {
                var variableNameCheckListItemKeyNo = 'w_srrefundbasis.checklistitem_keyno';
    
                if (utilityService.validateParmsValue(variableNameCheckListItemKeyNo) !== true) return;
                if (utilityService.validateParmsValue(vm.model.refund.checklistitem_keyno) !== true) return;
    
                rememberService.remember(variableNameCheckListItemKeyNo, vm.model.refund.checklistitem_keyno);
            };
    
            // ## RESIZE COLUMN WIDTH FUNCTION PROCEDURE CALLS ##
    
            vm.resizeRefunds = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(vm.model.itemsListFromReports[0]) && angular.isDefined(vm.model.itemsListFromReports[0].item_secondary)) {
                    for (var r = 0; r < vm.model.itemsListFromReports[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.itemsListFromReports[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.itemsListFromReports[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.itemsListFromReports[0].item_secondary[r].item_col_width })
                        }
                    }
                }
    
                var updateParms = {
                    view_id: 'w_srrefundbasis',
                    records: updateList
                };
    
                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
    
                    editing = false;
                });
            };
    
            vm.resizeFromRefunds = function () {
                for (var m = 0; m < vm.model.mconfigFromRefund.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfigFromRefund.records[m]) && vm.model.mconfigFromRefund.records[m].item_id === '99') {
                        vm.model.mconfigFromRefund.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(vm.model.itemsListFromRefunds[0]) && angular.isDefined(vm.model.itemsListFromRefunds[0].item_secondary)) {
                    for (var r = 0; r < vm.model.itemsListFromRefunds[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.itemsListFromRefunds[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.itemsListFromRefunds[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.itemsListFromRefunds[0].item_secondary[r].item_col_width })
                        }
                    }
                }
    
                var updateParms = {
                    view_id: 'w_srrefundbasis.refund',
                    records: updateList
                };
    
                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfigFromRefund.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfigFromRefund.records[m]) && vm.model.mconfigFromRefund.records[m].item_id === '99') {
                                vm.model.mconfigFromRefund.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            vm.model.mconfigFromRefund.records[m].animate = null;
                        }, 2000);
                    }
    
                    editing = false;
                });
            };
    
            // ## BUTTON GROUP FUNCTIONS ##
    
            vm.selectBgSrRefundBasisMain = function (item) {
                vm.model.bgSrRefundBasisMain.item_selected = item;
                vm.model.refund.bg_srrefundbasis_main = vm.model.bgSrRefundBasisMain.item_selected;
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.srCrewKeyNoBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.refund.srcrew_keyno = '0';
                        break;
                    default:
                        break;
                }
            };
    
            vm.srEquipmentKeyNoBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.refund.srequipment_keyno = '0';
                        break;
                    default:
                        break;
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srrefundcreate':
                        go({
                            custact_no: vm.model.refund.custact_no,
                            checklistdata_keyno: vm.model.refund.checklistdata_keyno
                        });
                        break;
                }
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.refund.bg_srrefundbasis_main; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                rememberFunc('bg_srrefundbasis_main');
            });
    
            watcher.$watch(function () { return vm.model.refund.srcrew_keyno; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrRefundBasisRefundItems();
                rememberFunc('srcrew_keyno');
            });
    
            watcher.$watch(function () { return vm.model.refund.srequipment_keyno; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrRefundBasisRefundItems();
                rememberFunc('srequipment_keyno');
            });
    
            watcher.$watch(function () { return vm.model.refund.special_resource_id_from; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrRefundBasisCheckListItems();
                loadSrRefundBasisItems();
                loadSrRefundBasisRefundItems();
                rememberSpecialResourceIdFrom();
            });
    
            watcher.$watch(function () { return vm.model.refund.checklistitem_keyno; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrRefundBasisItems();
                loadSrRefundBasisRefundItems();
                rememberCheckListItemKeyNo();
            });
        }]
    });
})();