(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectCityService", ['$ihttp', function ($ihttp) {
        var service = {
            // BJS 20190128
            current: {},

            selectedPostplace: {
                id: '',
                name: ''
            },

            searchPostPlaceList: function (webpage_name, parm1, postplace_name) {
                return $ihttp.post({
                    method: 103,
                    parameters: {
                        webpage_name: webpage_name,
                        parm1: parm1,
                        searchtext: postplace_name
                    }
                });
            }
        };

        return service;
    }]);
})();