(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("oeeStateEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_oeestates_get: {
                oeestate_keyno: '',
                oeestate_name: '',
                color_id: '',
                showremark: '',
                showfields: '',
                showprodid: ''
            },
            p2_bootstrap_colors_list: {
                color_id: '',
                color_name: '',
                records: []
            },
            loadP2OeeStatesGet: function (oeestate_keyno) {
                var deferred = $q.defer();

                var parms_p2_oeestates_get = {
                    method: 241,
                    parameters: {
                        oeestate_keyno: oeestate_keyno
                    }
                };

                $ihttp.post(parms_p2_oeestates_get).then(function (data) {
                    service.p2_oeestates_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2BootstrapColorsList: function () {
                var deferred = $q.defer();

                var parms_p2_bootstrap_colors_list = {
                    method: 255
                };

                $ihttp.post(parms_p2_bootstrap_colors_list).then(function (data) {
                    angular.copy(data, service.p2_bootstrap_colors_list.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2OeeStates: function () {
                var deferred = $q.defer();

                var parms_p2_oeestates_save = {
                    method: 242,
                    parameters: {
                        oeestate_keyno: service.p2_oeestates_get.oeestate_keyno,
                        oeestate_name: service.p2_oeestates_get.oeestate_name,
                        color_id: service.p2_oeestates_get.color_id,
                        showremark: service.p2_oeestates_get.showremark,
                        showfields: service.p2_oeestates_get.showfields,
                        showprodid: service.p2_oeestates_get.showprodid
                    }
                };

                $ihttp.post(parms_p2_oeestates_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();