(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockControlLocListService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadStockControlLocListGet: function (stocklocation_keyno) {
                return p2DataTaskService.call(1704, {
                    stocklocation_keyno: stocklocation_keyno
                });
            },

            loadStockControlLocListList: function (stocklocation_keyno) {
                return p2DataTaskService.call(1705, {
                    stocklocation_keyno: stocklocation_keyno
                });
            },

            scan: function (stocklocation_keyno, barcode) {
                return p2DataTaskService.call(1711, {
                    stocklocation_keyno: stocklocation_keyno,
                    barcode: barcode
                });
            },

            approve: function (stocklocation_keyno) {
                return p2DataTaskService.call(1706, {
                    stocklocation_keyno: stocklocation_keyno
                });
            }
        };

        return service;
    }]);
})();