(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderHeadCopy', {
        templateUrl: 'views/components/views/orderHeadCopy/orderHeadCopy.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'logisticService', 'orderHeadCopyService', function ($stateParams, stateService, utilityService, logisticService, orderHeadCopyService) {
            var vm = this;
            
            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                copyOrder: {},
                selectListOrderTypes: [],
                copyingOrderHead: false
            };
            
            orderHeadCopyService.loadOrderHeadCopyGet(vm.model.orderInternalNo).then(function (data) {
                angular.copy(data[0], vm.model.copyOrder);
                
                loadOrderTypes();
            });
            
            var loadOrderTypes = function () {
                if (utilityService.validateParmsValue(vm.model.copyOrder.ordertype_id_new) !== true) return;
                
                vm.model.selectListOrderTypes = [];
                
                logisticService.loadOrderTypeList(vm.model.copyOrder.ordertype_id_new).then(function (data) {
                    angular.copy(data, vm.model.selectListOrderTypes);
                });
            };
            
            vm.copyOrderHead = function () {
                vm.model.copyingOrderHead = true;
                
                orderHeadCopyService.copy(vm.model.copyOrder).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            if (data[0].follow > 0 && data[0].order_internal_no_new > 0) {
                                stateService.go('orderhead',{
                                    order_internal_no: data[0].order_internal_no_new
                                });
                            }

                            vm.model.copyingOrderHead = false;
                        });
                    } else {
                        if (data[0].follow > 0 && data[0].order_internal_no_new > 0) {
                            stateService.go('orderhead',{
                                order_internal_no: data[0].order_internal_no_new
                            });
                        } else {
                            stateService.back();
                        }

                        vm.model.copyingOrderHead = false;
                    }
                });
            };
        }]
    });
})();