(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("activityTypeService", ['$ihttp', function ($ihttp) {
        let service = {
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 402,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 403,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
