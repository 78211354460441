(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sirvSetup', {
        templateUrl: 'views/components/views/sirvSetup/sirvSetup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'modalService', 'sirvSetupService', 'sirvImageService', 'translateService', function (stateService, modalService, sirvSetupService, sirvImageService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                edit: {},
                lockedSave: false,
                testingConnection: false,
                uploadImage: [],
                selectedImageName: '', 
            };

            let translations = {
                sirv_save_warning: '',
                sirv_connection_established: '',
                sirv_connection_established_description: '',
                sirv_connection_failed: '',
                sirv_connection_failed_description: '',
                sirv_connection_established_ok: '',
                sirv_connection_failed_ok: '',
                sirv_save_warning_ok: '',
            };



            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            sirvSetupService.getSirvSetup().then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                sirvSetupService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.sirv_save_warning,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.sirv_save_warning_ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.testConnection = function () {
                vm.model.testingConnection = true;

                var clientInfo = {
                    clientId: vm.model.edit.sirv_client_id,
                    clientSecret: vm.model.edit.sirv_client_secret
                };


                sirvImageService.checkConnection(clientInfo).then((connectionOk) => {

                    if (connectionOk) {

                        modalService.show({
                            type: 'success',
                            title: translations.sirv_connection_established,
                            message: translations.sirv_connection_established_description,
                            buttons: [{
                                label: translations.sirv_connection_established_ok,
                                cssClass: 'btn-success',
                                action: function (dialogItself) {
                                    vm.model.testingConnection = false;
                                    dialogItself.close();
                                }
                            }]
                        }).finally(() => vm.model.testingConnection = false);

                    } else {

                        modalService.show({
                            type: 'warning',
                            title: translations.sirv_connection_failed,
                            message: translations.sirv_connection_failed_description,
                            buttons: [{
                                label: translations.sirv_connection_failed_ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    vm.model.testingConnection = false;
                                    dialogItself.close();
                                }
                            }]
                        }).finally(() => vm.model.testingConnection = false);

                    }
                }).catch((error) => {

                    modalService.show({
                        type: 'warning',
                        title: translations.sirv_connection_failed,
                        message: translations.sirv_connection_failed_description,
                        buttons: [{
                            label: translations.sirv_connection_failed_ok,
                            cssClass: 'btn-warning',
                            action: function (dialogItself) {
                                vm.model.testingConnection = false;
                                dialogItself.close();
                            }
                        }]
                    }).finally(() => vm.model.testingConnection = false);

                });
            };

            vm.uploadImage = function () {
                console.log("setup upload");
                var image = vm.model.uploadImage[0];
                //sirvImageService.searchImages("1668686339215.jpg");

                try {

                    sirvImageService.uploadImages(image);
                } catch (error) {
                    console.log("ERROR");
                }
            }
        }]
    });

})();