(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srEquipmentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrEquipmentGet: function (resource_id, srequipment_keyno) {
                return p2DataTaskService.call(1794, {
                    resource_id: resource_id,
                    srequipment_keyno: srequipment_keyno
                });
            },
            loadSrRefundTypeIsEquipmentList: function (resource_id) {
                return p2DataTaskService.call(1795, {
                    resource_id: resource_id
                });
            },
            loadSrEquipmentFuelTypeList: function () {
                return p2DataTaskService.call(1797, {});
            },
            loadSrEquipmentDroneTypeList: function () {
                return p2DataTaskService.call(1801, {});
            },
            save: function (equipment) {
                return p2DataTaskService.call(1796, equipment);
            }
        };

        return service;
    }]);
})();