(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttApprouteSelector', {
        templateUrl: 'views/components/dynamics/components/ttApprouteSelector/ttApprouteSelector.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttModel: '<',
            ttLabel: '@',
            ttHideLabel: '@',
            ttReadonly: '@',
            ttChange: '&',
            ttTextAlign: '@',
            ttPlaceholder: '@',
            ttRequired: '@',
            ttStyle: '<',
            ttLimit: '<'
        },
        controller: ['$q', '$uibModal', '$element', '$ihttp', 'translateService', 'layoutService', 'utilityService', 'ieScreenBlockService', 'modalService', function ($q, $uibModal, $element, $ihttp, translateService, layoutService, us, ieScreenBlockService, modalService) {
            let vm = this;

            let onDestroy = [];

            vm.approutes = [];

            vm.selectedApproute = {
                p2_approute_keyno: 0,
                route_id: ''
            };

            vm.id = uuid();

            vm.translations = {
                label: '',
                tt_confirm_delete_approute: ''
            };

            vm.style = {
                label: {},
                typeahead: {},
                select1: {},
                select2: {}
            };

            vm.class = {
                label: '',
                input: '',
                typeahead: ''
            }

            vm.buttons = [
                { id: 'delete', icon: 'glyphicon glyphicon-remove', color: 'danger', onClick: { click: deleteApproute } },
                { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'success', onClick: { click: newApproute } }
            ];

            function newApprouteAdded(response) {
                if (response.route_id.length < 1) return;

                $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'addnewapproute',
                        approute: response
                    }
                }).then(function (response) {
                    if (angular.isDefined(response.error) && response.error === true) {
                        modalService.show({
                            type: 'warning',
                            title: response.title,
                            message: response.message
                        });
                    } else {
                        loadApproutes().then(function () {
                            vm.selectedApproute.p2_approute_keyno = response.p2_approute_keyno;
                            vm.selectedApproute.route_id = response.route_id;
                        });
                    }
                });
            };

            function newApproute() {
                $uibModal.open({
                    component: 'ttApprouteModal',
                    resolve: {
                        parameters: function () {
                            return {
                                callback: newApprouteAdded
                            };
                        }
                    },
                    size: 'pst-ninety',
                    backdrop: true
                });
            };

            function deleteApproute(value) {
                let approutes = vm.approutes.filter(a => a.route_id === value);

                if (approutes.length < 1) return;

                let keyno = approutes[0].p2_approute_keyno;

                if (keyno < 1) return;

                let modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return vm.translations.tt_confirm_delete_approute;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        return;
                    }

                    ieScreenBlockService.start();

                    $ihttp.post({
                        method: 2416,
                        parameters: {
                            state: 'deleteapproute',
                            p2_approute_keyno: keyno
                        }
                    }).then(function () {
                        loadApproutes().then(function () {
                            vm.selectedApproute.p2_approute_keyno = 0;
                            vm.selectedApproute.route_id = '';

                            ieScreenBlockService.stop();

                            modalInstance.dismiss();
                        });
                    });
                });
            };

            function loadApproutes() {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'approutelist'
                    }
                }).then(function (response) {
                    angular.copy(response, vm.approutes);

                    if (angular.isDefined(vm.ttModel)) {
                        var key = parseInt(vm.ttModel);

                        let dss = vm.approutes.filter(function (ds) {
                            return ds.p2_approute_keyno === key;
                        });

                        if (dss.length > 0) {
                            angular.copy(dss[0], vm.selectedApproute);
                        }
                    }

                    deferred.resolve();
                });

                return deferred.promise;
            };

            vm.onApprouteChanged = function (item) {
                angular.copy(item, vm.selectedApproute);

                let dss = vm.approutes.filter(function (ds) {
                    return ds.p2_approute_keyno === vm.selectedApproute.p2_approute_keyno;
                });

                if (dss.length < 1 || angular.isObject(dss[0]) !== true) return;

                angular.copy(dss[0], vm.selectedApproute);

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: vm.selectedApproute, $modelId: us.getModelId($element) });
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;
                
                vm.style.label.fontSize = info.fontSizes.textSizeS;

                vm.style.select1.fontSize = info.fontSizes.textSize;
                vm.style.select1.height = info.height + 'px';
                vm.style.select1.paddingTop = info.padding.top + 'px';
                vm.style.select1.paddingBottom = info.padding.bottom + 'px';
                vm.style.select1.paddingLeft = info.padding.left + 'px';
                vm.style.select1.paddingRight = info.padding.right + 'px';

                vm.style.select2.fontSize = info.fontSizes.textSize;
                vm.style.select2.height = info.height + 'px';
                vm.style.select2.paddingTop = info.padding.top + 'px';
                vm.style.select2.paddingBottom = info.padding.bottom + 'px';
                vm.style.select2.paddingLeft = info.padding.left + 'px';
                vm.style.select2.paddingRight = info.padding.right + 'px';

                vm.class.label = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2';
                vm.class.input = vm.hideLabel
                    ? 'col-xs-12'
                    : info.labelAlwaysOnTop === true
                        ? 'col-xs-12'
                        : 'col-xs-12 col-sm-10';
            });

            vm.$onInit = function () {
                var setLabel = false;

                loadApproutes();

                if (angular.isString(vm.ttLabel) && angular.isUndefined(vm.translations[vm.ttLabel])) {
                    vm.translations[vm.ttLabel] = '';

                    setLabel = true;
                }

                translateService.translateBatch(vm.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.translations[key] = val;
                    });

                    if (setLabel === true) {
                        vm.translations.label = translations[vm.ttLabel];
                    }
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
