(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srTravelBillService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrTravelBillGet: function (travelbill_no) {
                return p2DataTaskService.call(1616, {
                    travelbill_no: travelbill_no
                });
            },
            loadSrExpensesSummaryList: function (travelbill_no, view_id) {
                return p2DataTaskService.call(1617, {
                    travelbill_no: travelbill_no,
                    view_id: view_id
                });
            },
            approve: function (travelbill_no ) {
                return p2DataTaskService.call(1621, {
                    travelbill_no: travelbill_no
                });
            },
            pay: function (travelbill_no) {
                return p2DataTaskService.call(1622, {
                    travelbill_no: travelbill_no
                });
            },
            update: function (travelbill_no) {
                return p2DataTaskService.call(1625, {
                    travelbill_no: travelbill_no
                });
            }
        };

        return service;
    }]);
})();