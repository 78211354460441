(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('labourSalaryType', {
        templateUrl: 'views/components/views/labourSalaryType/labourSalaryType.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'labourSalaryTypeService', function ($stateParams, stateService, labourSalaryTypeService) {
            var vm = this;
            
            vm.model = {
                labourSalaryTypeKeyNo: $stateParams.laboursalarytype_keyno,
                edit: {}
            };
            
            labourSalaryTypeService.loadLabourSalaryTypeGet(vm.model.labourSalaryTypeKeyNo).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
            });
            
            vm.saveChanges = function () {
                labourSalaryTypeService.save(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();
