<li *ngIf="ttHasDivider" class="tt-overflow-item tt-overflow-item__seperator" role="separator"></li>
<li [attr.id]="id.button" class="tt-overflow-item">
    <button
        [title]="ttTooltipText"
        [ngClass]="{ 'tt-overflow-item__button--danger': ttDanger }"
        class="tt-overflow-item__button"
        (click)="click($event)"
        [ngStyle]="style['button']"
        [disabled]="ttDisabled || false"
    >
        <span>{{ translations.ttText }}</span>
        <span *ngIf="ttIcon" [ngClass]="ttIcon" class="fa-fw far"></span>
    </button>
</li>
