(function () {
    'use strict';

    angular.module("imApp").factory("fossProductionService", ['$q', '$ihttp', function ($q, $ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var service = {

            rowEvent: function (page) {
                return call(2340, { gridpage_keyno: page });
            },

            initializePage: function (page, dtk, eventID, reportNumber, parm1, parm2, parm3) {
                return call(2242, { gridpage_keyno: page, p2_datatask_keyno: dtk, eventid: eventID, reportnumber: reportNumber, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3 });
            },

            info: function (page, dtk, parm1, parm2, parm3) {
                return call(2243, { gridpage_keyno: page, p2_datatask_keyno: dtk, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3 });
            },

            saveGridData: function (page, update, cname, cvalue, pname1, pvalue1, pname2, pvalue2) {
                return call(2245, {
                    gridpage_keyno: page,
                    update: update,
                    column_name: cname,
                    column_value: cvalue,
                    primarykey_name1: pname1,
                    primarykey_value1: pvalue1,
                    primarykey_name2: pname2,
                    primarykey_value2: pvalue2
                });
            },

            newRow: function (page) {
                return call(2303, { gridpage_keyno: page });
            },

            button: function (page, buttonname, menuID, pvalue1, pvalue2) {
                return call(2346, { gridpage_keyno: page, buttonname: buttonname, p2_webpagelink_keyno: menuID, primarykey_value1: pvalue1, primarykey_value2: pvalue2 });
            },

            lookup: function (page, cname, cvalue) {
                return call(2419, { gridpage_keyno: page, column_name: cname, column_value: cvalue });
            },

            scan: function (page, scan, eventID, menuID, parm1, parm2, parm3, dtk) {
                return call(2450, { gridpage_keyno: page, scantext: scan, eventid: eventID, p2_webpagelink_keyno: menuID, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3, p2_datatask_keyno: dtk });
            },

            dbsearch: function (page, eventID, reportNumber, menuID, parm1, parm2, parm3, dtk) {
                return call(2473, { gridpage_keyno: page, eventid: eventID, reportnumber: reportNumber, p2_webpagelink_keyno: menuID, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3, p2_datatask_keyno: dtk });
            },

            userVariableGet: function (name) {
                return call(973, { variablename: name });
            },

            userVariableSet: function (name, value) {
                return call(616, { variablename: name, variablevalue: value });
            },

            gridSql: function (page, parm1, parm2, parm3, eventID, searchname1, searchvalue1) {
                return call(2244, { gridpage_keyno: page, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3, eventid: eventID, search_column_name1: searchname1, search_column_value1: searchvalue1, showsql: true });
            },

        };

        return service;
    }]);
})();
