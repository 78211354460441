(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("actStatusService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1333,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1334,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
