(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderpickLines', {
        templateUrl: 'views/components/views/orderpickStatus/orderpickLines.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['orderpickStatusService', function (orderpickStatusService) {
            var vm = this;

            let rememberId = {
                grid: 'orderpicklines.grid'
            };

            let stocktransfer_keyno = 0;

            vm.grid = {
                dataTask: {
                    rememberId: rememberId.grid,
                    loadSetupId: null,
                    loadData: {
                        method: 2599,
                        parameters: function () {
                            return {
                                stocktransfer_keyno: stocktransfer_keyno
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve)) {
                    if (angular.isDefined(vm.resolve.parameters) && angular.isDefined(vm.resolve.parameters.stocktransfer_keyno)) {
                        stocktransfer_keyno = vm.resolve.parameters.stocktransfer_keyno;
                    } else {
                        if (angular.isDefined(vm.resolve.dataItem) && angular.isDefined(vm.resolve.dataItem.stocktransfer_keyno)) {
                            stocktransfer_keyno = vm.resolve.dataItem.stocktransfer_keyno;
                        }
                    }
                }
            };
        }]
    });
})();
