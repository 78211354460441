(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockZoneMenu', {
        templateUrl: 'views/components/views/stockZoneMenu/stockZoneMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'base64', 'stockZoneMenuService', 'ttGridFactory', function ($stateParams, $q, stateService, utilityService, base64, stockZoneMenuService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockZoneKeyno = $stateParams.stockzone_keyno;

            vm.model = {
                menu: {},
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: '',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'stockzonemenu_add',
                text: 'stockzonemenu_add',
                func: function () {
                    stateService.go('stocklocation', {
                        stockzone_keyno: vm.model.menu.stockzone_keyno,
                        stocklocation_keyno: '0'
                    });
                },
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                const deferred = $q.defer();

                stockZoneMenuService.getStockZoneMenu({ stockzone_keyno: stockZoneKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.menu.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.menu.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.menu.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.menu.p2_datatask_keyno,
                        parameters: vm.model.menu
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectreport':
                        let encodedStockZone = base64.urlencode(vm.model.menu.stockzone_keyno + '|' + vm.model.menu.filtervalue);

                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'stockzone_keyno',
                            argvalue: encodedStockZone
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
