(function() {
    'use strict';

    var module = angular.module('imApp');

    module.directive('imHeader', ['$animate', '$ihttp', 'stateService', 'imHeaderService', 'layoutService', 'p2NotificationsService', 'modalFavoritesFactory', 'modalService', function ($animate, $ihttp, stateService, imHeaderService, layoutService, p2NotificationsService, modalFavoritesFactory, modalService) {
            return {
                restrict: 'A',
                scope: {},
                template: '' +
                    '<nav ng-class="addNavClass()">' +
                    // backButton
                    '   <a href="" ng-show="model.header.backButton.visible === true" ng-click="goBack()" class="btn btn-default btn-md pull-left" style="margin-left: 5px; margin-top: 7px; padding-left: 7px; padding-right: 7px;">' +
                    '      <span class="glyphicon glyphicon-chevron-left"></span> <span ng-if="model.header.backButton.translate == true" im-translate="{{model.header.backButton.text}}" im-watch="true"></span>' +
                    '       <span ng-if="model.header.backButton.translate != true">{{model.header.backButton.text}}</span>' +
                    '   </a>' +

                    // signOutButton
                    '   <a href="" ng-if="model.header.backButton.visible !== true" ng-click="signOut()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;">' +
                    '       <span class="fa fa-sign-out"></span>' +
                    '   </a>' +

                    // homebutton
                    '   <a href="" ng-click="goHome()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;"ng-if="model.header.homeButton.visible == true">' +
                    '       <span class="glyphicon glyphicon-home"></span>' +
                    '   </a>' +

                    // notifybutton
                    //'   <a href="" ng-click="goNotify()" class="btn btn-default btn-md pull-right notification-container" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;"ng-show="model.notify == true">' +
                    '   <a href="" ng-click="goNotify()" class="btn btn-default btn-md pull-right notification-container" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;">' +  // ng-disabled="!model.notify">' +
                    '       <span class="glyphicon glyphicon-inbox"></span>' +
                    '       <span ng-if="model.counter > 0" class="notification-counter">{{model.counter}}</span>' +
                    '   </a>' +
                    //'   <a href="" ng-click="goNotify()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;" ng-show="model.notify != true" disabled>' +
                    //'       <span class="glyphicon glyphicon-inbox"></span>' +
                    //'   </a>' +

                    // helpdocbutton
                    //'   <a href="" ng-click="goHelpDoc()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;"ng-show="model.helpdoc == true">' +
                    '   <a href="" ng-click="goHelpDoc()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;" ng-disabled="!model.helpdoc">' +
                    '       <span class="fa fa-question" style="width: 14px"></span>' + // width is set as 14 to match other button (all should perhaps ideally be 19px)
                    '   </a>' +
                    //'   <a href="" ng-click="goHelpDoc()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;" ng-show="model.helpdoc != true" disabled>' +
                    //'       <span class="fa fa-question" style="width: 14px"></span>' +
                    //'   </a>' +

                    // favoritesbutton
                    '   <a href="" ng-click="goFavorites()" class="btn btn-default btn-md pull-right" style="margin-right: 5px; margin-top: 7px; padding-left: 8px; padding-right: 8px;" ng-disabled="!model.favorites">' +
                    '       <span class="fa fa-star" style="width: 14px"></span>' + // width is set as 14 to match other button (all should perhaps ideally be 19px)
                    '   </a>' +

                    // title
                    '   <div ng-show="model.header.visible">' +
                    '       <h4 class="text-center" style="margin-top: 15px; overflow: hidden; height: 19px;" ng-if="model.header.translate != true">{{model.header.text}}</h4>' +
                    '       <h4 class="text-center" style="margin-top: 15px; overflow: hidden; height: 19px;" ng-if="model.header.translate == true" im-translate="{{model.header.text}}" im-watch="true"></h4>' +
                    '   </div>' +
                    '</nav>',
                link: function ($scope, $element) {
                    const id = uuid();

                    $scope.model = {
                        header: {},
                        counter: 0,
                        notify: false,
                        helpdoc: true,
                        favorites: true,
                        fixed: true, //responsive.current === 'xs'
                        bgColor: null
                    };

                    $scope.goBack = function () {
                        stateService.back();
                    };

                    $scope.signOut = function () {
                        stateService.signOut();
                    };

                    angular.copy(imHeaderService.getCurrentHeader(), $scope.model.header);

                    $scope.addNavClass = function () {
                        var navClass = 'navbar';

                        if ($scope.model.bgColor !== null) {
                            switch ($scope.model.bgColor) {
                                case 'primary':
                                    navClass += ' navbar-alternate bg-primary';
                                    break;
                                case 'secundary':
                                    navClass += ' navbar-alternate bg-secundary';
                                    break;
                                case 'success':
                                    navClass += ' navbar-alternate bg-success';
                                    break;
                                case 'danger':
                                    navClass += ' navbar-alternate bg-danger navbar-danger';
                                    break;
                                case 'warning':
                                    navClass += ' navbar-alternate bg-warning';
                                    break;
                                case 'info':
                                    navClass += ' navbar-alternate bg-info';
                                    break;
                                case 'light':
                                    navClass += ' navbar-alternate bg-light';
                                    break;
                                case 'dark':
                                    navClass += ' navbar-alternate bg-dark';
                                    break;
                                default:
                                    navClass += ' navbar-default';
                            }
                        } else {
                            navClass += ' navbar-default';
                        }

                        if ($scope.model.fixed === true) {
                            navClass += ' navbar-fixed-top';
                        }

                        return navClass;
                    };

                    layoutService.onLayoutChanged($scope, function (info) {
                        if (angular.isUndefined(info)) return;

                        $scope.model.bgColor = info.windowheadingcolor;
                    });

                    // BJS 20241014 - Removed watch and added subscribe.
                    p2NotificationsService.subscribe(id, function (args) {
                        if (args.type === 'read') return;

                        $scope.model.counter = args.value;

                        if (args.value > 0) {
                            $scope.model.notify = true;

                            // BJS 20181213 - Modified to prevent "Cannot read property 'nodeType' of undefined" 
                            //                error message from angular-animate.
                            var notificationCounterElement = $element.find('.notification-counter')[0];

                            if (angular.isDefined(notificationCounterElement) === true) {
                                $animate.addClass(notificationCounterElement, 'notify-bounce').then(function () {
                                    notificationCounterElement = $element.find('.notification-counter')[0];

                                    if (angular.isDefined(notificationCounterElement) === true) {
                                        $animate.removeClass(notificationCounterElement, 'notify-bounce');
                                    }
                                });
                            }
                        } else if (newValue.read > 0) {
                            $scope.model.notify = true;
                        }
                        else {
                            $scope.model.notify = false;
                        }
                    });

                    //$scope.$watch(function () {
                    //    return p2NotificationsService.countNotifications();
                    //}, function (newValue) {
                    //    $scope.model.counter = newValue.unread;

                    //    if (newValue.unread > 0) {
                    //        $scope.model.notify = true;

                    //        // BJS 20181213 - Modified to prevent "Cannot read properyt 'nodeType' of undefined" 
                    //        //                error message from angular-animate.
                    //        var notificationCounterElement = $element.find('.notification-counter')[0];

                    //        if (angular.isDefined(notificationCounterElement) === true) {
                    //            $animate.addClass(notificationCounterElement, 'notify-bounce').then(function () {
                    //                notificationCounterElement = $element.find('.notification-counter')[0];

                    //                if (angular.isDefined(notificationCounterElement) === true) {
                    //                    $animate.removeClass(notificationCounterElement, 'notify-bounce');
                    //                }
                    //            });
                    //        }
                    //    } else if (newValue.read > 0) {
                    //        $scope.model.notify = true;
                    //    }
                    //    else
                    //        $scope.model.notify = false;
                    //}, true);

                    $scope.goNotify = function () {
                        //if ($scope.model.notify == true) {
                            stateService.go('p2notifications');
                        //}
                    };

                    $scope.goHelpDoc = function () {
                        if ($scope.model.helpdoc === true) {
                            stateService.go('helpdoc', 'webpage/' + stateService.getCurrentName());
                        }
                    };

                    $scope.goFavorites = function () {
                        if ($scope.model.favorites === true) {

                            //var progress = modalProgressFactory.$create({
                            //    label: wordlang.ttgrid_save_progress_label,
                            //    min: 0,
                            //    max: changeCount
                            //});

                            //progress.show();

                            var call = function (method, parameters) {
                                return $ihttp.post({
                                    method: method,
                                    parameters: parameters || {}
                                });
                            };

                            var getFavorites = function () {
                                return call(2235, { });
                            }

                            var setFavorites = function (list) {
                                angular.forEach(list, function (item) {
                                    call(2236, {
                                        myfavorites_keyno: item.myfavorites_keyno,
                                        item_name: item.item_name,
                                        item_state: item.item_state,
                                        item_parms: item.item_parms,
                                        orderby: item.orderby || 0,
                                        function: item.function
                                    });
                                });
                            };

                            getFavorites().then(function (result) {
                                var favoritesModal = modalFavoritesFactory.$create({
                                    //placeholder: wordlang.tt_modal_favorites_placeholder,
                                    //placeholderError: wordlang.tt_modal_favorites_placeholder_error,
                                    //placeholderRename: wordlang.tt_modal_favorites_placeholder_rename,
                                    header: $scope.model.header,
                                    list: result.length > 0 ? result : []
                                });

                                favoritesModal.show().then(function (value) {
                                    var favList = [];
                                    angular.forEach(value.list, function (item) {
                                        if (angular.isDefined(item.myfavorites_keyno) && item.willGetDeleted) {
                                            favList.push({
                                                myfavorites_keyno: item.myfavorites_keyno,
                                                item_name: item.item_name,
                                                item_state: item.item_state,
                                                item_parms: item.item_parms,
                                                orderby: item.orderby || 0,
                                                function: 'DELETE'
                                            });
                                        } else if (angular.isUndefined(item.myfavorites_keyno) && !item.willGetDeleted) {
                                            favList.push({
                                                myfavorites_keyno: 0,
                                                item_name: item.item_name,
                                                item_state: item.item_state,
                                                item_parms: item.item_parms,
                                                orderby: item.orderby || 0,
                                                function: 'NEW'
                                            });
                                        } else if (angular.isDefined(item.myfavorites_keyno) && !item.willGetDeleted) {
                                            if (item.hasUpdate) {
                                                favList.push({
                                                    myfavorites_keyno: item.myfavorites_keyno,
                                                    item_name: item.item_name,
                                                    item_state: item.item_state,
                                                    item_parms: item.item_parms,
                                                    orderby: item.orderby || 0,
                                                    function: 'UPDATE'
                                                });
                                            }
                                        }
                                    });

                                    setFavorites(favList);
                                });
                            });
                        }
                    };

                    $scope.goHome = function () {
                        $scope.model.isHome = true;
                        stateService.go('mainmenu');
                    };

                    var hsDereg = imHeaderService.on('stateChanged', function (header) {
                        if ($scope.model.header === null || header !== $scope.model.header) {
                            angular.copy(header, $scope.model.header);
                        }
                    });

                    $scope.$on('$destroy', function () {
                        if (angular.isFunction(hsDereg)) {
                            hsDereg();
                        }

                        p2NotificationsService.unsubscribe(id);
                    });
                }
            };
        }
    ]);
})();
