(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('invoiceMethods', {
        templateUrl: 'views/components/views/invoiceMethods/invoiceMethods.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['invoiceMethodsService', function (invoiceMethodsService) {
            let vm = this;
            
            vm.model = {
                itemsListInvoiceMethods: []
            };

            let loadInvoiceMethods = function () {
                invoiceMethodsService.listInvoiceMethods().then(function (result) {
                    angular.copy(result, vm.model.itemsListInvoiceMethods);
                });
            };
            
            vm.$onInit = function () {
                loadInvoiceMethods();
            };
        }]
    });
})();
