// https://beta.touchtime.app/#/externalshippingcompletion
(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epShipmentCompletion', {
        templateUrl: 'views/components/views/epShipmentCompletion/epShipmentCompletion.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'layoutService', function (stateService, layoutService) {
            var vm = this;

            vm.fontSize = null;

            vm.scan = function (e) {
                e.preventDefault();

                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                stateService.go('epshipmentcompletionscan', { shipmentContainerKeyno: dataItem.shipmentcontainer_keyno });
            };

            vm.gridOptions = {
                dataTaskKeyno: 1945,
                rememberId: 'epShipmentCompletion',
                translations: ['scan'],
                onSetup: function (schema, fields, columns, translations) {
                    var cols = [
                        { command: { text: translations.scan, click: vm.scan, className: 'col-xs-12 tt-btn-success-important' }, title: " ", width: "300px", attributes: { style: 'font-size: ' + vm.fontSize + ';' } },
                        columns[2],
                        columns[3],
                        columns[1]
                    ];

                    angular.copy(cols, columns);
                }
            };

            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSizeS;
            });

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            vm.go = function (shipmentContainerKeyno) {
                stateService.go('epshipmentcompletionscan', { shipmentContainerKeyno: shipmentContainerKeyno });
            };
        }]
    });
})();
