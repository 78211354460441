(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('valuta', {
        templateUrl: 'views/components/views/valuta/valuta.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'valutaService', 'logisticService', 'economyService', function ($stateParams, stateService, utilityService, valutaService, logisticService, economyService) {
            let vm = this;
            let valutaId = $stateParams.valuta_id;
            
            vm.model = {
                valutaId: valutaId,
                bgValuta: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'valuta_basic', color: 'primary', onClick: function () { vm.model.bgValuta.selected = 'BASIC'; } },
                        { id: 'OTHER', label: 'valuta_other', color: 'primary', onClick: function () { vm.model.bgValuta.selected = 'OTHER'; } }
                    ]
                },
                edit: {},
                selectListCountries: [],
                selectListRoundOff: []
            };
            
            let loadValutaEdit = function () {
                valutaService.getValutaEdit({ valuta_id: valutaId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadCountries = function () {
                logisticService.listCountries().then(function (result) {
                    angular.copy(result, vm.model.selectListCountries);
                });
            };

            let loadRoundOff = function () {
                economyService.listRoundOff().then(function (result) {
                    angular.copy(result, vm.model.selectListRoundOff);
                });
            };

            let recalcValuta = function () {
                vm.model.edit.valuta_exchangefactor = (utilityService.parseNumber(vm.model.edit.valuta_exchangerate) / utilityService.parseNumber(vm.model.edit.valuta_pr));
            };

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'valuta_exchangerate':
                        if (utilityService.validateWatchValue(value, vm.model.edit.valuta_exchangerate) !== true) return;

                        vm.model.edit.valuta_exchangerate = value;

                        if (utilityService.validateParmsValue(vm.model.edit.valuta_exchangerate, true) !== true) return;

                        recalcValuta();
                        break;
                    case 'valuta_pr':
                        if (utilityService.validateWatchValue(value, vm.model.edit.valuta_pr) !== true) return;

                        vm.model.edit.valuta_pr = value;

                        if (utilityService.validateParmsValue(vm.model.edit.valuta_pr, true) !== true) return;

                        recalcValuta();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };
            
            vm.selectBgValuta = function (item) {
                vm.model.bgValuta.selected = item;
            };

            vm.saveChanges = function () {
                valutaService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadValutaEdit();
                loadCountries();
                loadRoundOff();
            };
        }]
    });
})();
