(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('skillsCertificateType', {
        templateUrl: 'views/components/views/skillsCertificateType/skillsCertificateType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'skillsCertificateTypeService', function ($stateParams, stateService, utilityService, translateService, modalService, skillsCertificateTypeService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'selectglyphicon':
                        stateService.go(state, {});
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                skillsCertificateTypeKeyno: $stateParams.skillscertificatetype_keyno,
                glyphiconButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-left', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'selectglyphicon' } }
                ],
                edit: {},
                selectListGlyphicons: [],
                selectListHexColours: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            skillsCertificateTypeService.getSkillsCertificateType({ skillscertificatetype_keyno: vm.model.skillsCertificateTypeKeyno }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            skillsCertificateTypeService.listGlyphicons().then(function (data) {
                angular.copy(data, vm.model.selectListGlyphicons);
            });

            skillsCertificateTypeService.listHexColors().then(function (data) {
                angular.copy(data, vm.model.selectListHexColours);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SHOW GLYPHICON SYMBOL FUNCTION

            var showGlyphiconSymbol = function () {
                angular.forEach(vm.model.selectListGlyphicons, function (glyph) {
                    if (vm.model.edit.glyphicon === glyph.item_id) {
                        vm.model.edit.glyphicon = glyph.item_glyphicon;

                        showColour();
                    }
                });
            };

            // #endregion SHOW GLYPHICON SYMBOL FUNCTION

            // #region SHOW COLOUR FUNCTION

            var showColour = function () {
                angular.forEach(vm.model.selectListColours, function (hex) {
                    if (vm.model.edit.glyphicon_color === hex.hexcolor) {
                        vm.model.edit.glyphicon_color = hex.hexcolor;
                    }
                });
            };

		    // #endregion SHOW COLOUR FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'glyphicon':
                        vm.model.edit.glyphicon = value;

                        if (utilityService.validateParmsValue(vm.model.edit.glyphicon) !== true) return;

                        showGlyphiconSymbol();
                        break;
                    case 'glyphicon_color':
                        vm.model.edit.glyphicon_color = value;

                        if (utilityService.validateParmsValue(vm.model.edit.glyphicon_color) !== true) return;

                        showColour();
                        break;
                    default:
                        eval(id + " = '" + value + "'");
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                skillsCertificateTypeService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

        }]
    });
})();
