<h2
    mat-dialog-title
    [ngClass]="this.data.status !== 'invalid' ? 'tt-success-background' : 'tt-danger-background'"
    [ngStyle]="style['title']"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
>
    {{ this.data.status !== "invalid" ? translations["title"] : translations["error"] }}
</h2>
<mat-dialog-content style="padding: 1.8rem; padding-bottom: 1rem">
    <tt-progress-bar
        ttLabelView="top"
        ttLabel="ttgrid_save_progress_label"
        [ttModel]="data.value"
        [ttSublabel]="data.value + ' av ' + data.max + ' rader lagret'"
        [ttMax]="data.max"
        [ttStatus]="data.status"
        [ttInvalidText]="data.invalidtext || ''"
        [ttStyle]="style"
    ></tt-progress-bar>
    <tt-checkbox ttLabel="finishclose" ttLabelView="top" [(ttModel)]="this.data.finishclose" (ttModelChange)="onFinishCloseChanged($event)" [ttTrueValue]="true" [ttFalseValue]="false"></tt-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
    <tt-button class="col-xs-2 sp-0" ttText="close" [ttType]="this.data.status !== 'invalid' ? 'success' : 'danger'" (ttClick)="dialogRef.close()" [ttStyle]="style"></tt-button>
</mat-dialog-actions>
