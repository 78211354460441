(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockZones', {
        templateUrl: 'views/components/views/stockZones/stockZones.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'stockZonesService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, stockZonesService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                bg_stockzones: ''
            };

            let setMainView = function (id) {
                vm.model.bgStockZones.selected = vm.model.setting.bg_stockzones = id;
            };

            vm.model = {
                bgStockZones: {
                    selected: 'ZONES',
                    buttons: [
                        { id: 'ZONES', label: 'bg_stockzones_zones', color: 'primary', onClick: { click: setMainView, id: 'ZONES' } },
                        { id: 'STOCKLOCATIONS', label: 'bg_stockzones_stocklocations', color: 'primary', onClick: { click: setMainView, id: 'STOCKLOCATIONS' } },
                        { id: 'WAREHOUSES', label: 'bg_stockzones_warehouses', color: 'primary', onClick: { click: setMainView, id: 'WAREHOUSES' } },
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                sorting: false,
                retrieving: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_stockzones_stocklocations_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: 'multiple, row',
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            vm.whGrid = {
                dataTask: {
                    rememberId: 'w_stockzones_stocklocations_wh_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: true,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { whOptionfunc(data.data); },
                gridfunc: null
            };

            var whOptionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                    data['rdata']['stockplace_id'] = vm.model.setting.stockplace_id;
                    stockZonesService.saveWarehouse(data['rdata']);
                    vm.whGrid.gridfunc.rebind();
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            vm.szGrid = {
                dataTask: {
                    rememberId: 'w_stockzones_stocklocations_sz_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { szOptionfunc(data.data); },
                gridfunc: null
            };

            var szOptionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                    data['rdata']['stockplace_id'] = vm.model.setting.stockplace_id;
                    stockZonesService.saveStockZone(data['rdata']);
                    vm.szGrid.gridfunc.rebind();
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            vm.options = {
                sorting: {
                    autoselect: true,
                    decimals: 0,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                stockZonesService.getStockZonesSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setMainView(vm.model.setting.bg_stockzones);

                    $q.all([
                        loadStocks()
                    ]).then(() => {
                        loadGrid();
                        loadWhGrid();
                        loadSzGrid();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadStocks = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) === true) {
                    vm.grid.dataTask.loadData = {
                        method: '2643',
                        parameters: { stockplace_id: vm.model.setting.stockplace_id }
                    };
                }

                vm.model.gridReady = true;
            };

            let loadWhGrid = function () {
                vm.whGrid.dataTask.loadData = {
                    method: '2542',
                    parameters: { stockplace_id: vm.model.setting.stockplace_id }
                }
                vm.model.whGridReady = true;
            }

            let loadSzGrid = function () {
                vm.szGrid.dataTask.loadData = {
                    method: '2571',
                    parameters: { stockplace_id: vm.model.setting.stockplace_id }
                }
                vm.model.szGridReady = true;
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                        case 'stockplace_id':
                        case 'bg_stockzones':
                            variableNames[key] = stateService.getCurrentName() + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                    case 'bg_stockzones':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockZones = function (item) {
                vm.model.bgStockZones.selected = item;
                vm.model.setting.bg_stockzones = vm.model.bgStockZones.selected;

                rememberFunc('bg_stockzones');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockzone':
                        go({
                            stockplace_id: vm.model.setting.stockplace_id,
                            stockzone_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.sortChanges = function () {
                vm.model.sorting = true;
                //console.log("Trykket på sortering start");
                let sort = [];

                if (Object.hasOwn(vm.grid.gridfunc, 'getColumnState')) {
                    const sortedColumns = vm.grid.gridfunc.getColumnState().filter((columnState) => !!columnState.sort);

                    sort = sortedColumns.map((columnState) => ({ dir: columnState.sort, field: columnState.colId }));
                } else {
                    sort = vm.grid.gridfunc.getDataSource().sort();
                }


                let dirtyRows = vm.grid.gridfunc.getIsSelectedRows();
                let query = new kendo.data.Query(dirtyRows);
                let sortedRows = query.sort(sort).data;
                //console.log("progressFunc dirtyRows");
                //console.dir(dirtyRows);
                //console.dir(sortedRows);

                var var_start_nbr = parseInt(vm.model.setting.sorting_nbr_start);
                //console.log('var_start_nbr=' + var_start_nbr);

                angular.forEach(sortedRows, function (item) {
                    if (item.is_selected === true) {
                        //console.log(item.stocklocation_name + "var_start_nbr=" + var_start_nbr);
                        //console.log('sortorder: ', item.sortorder);
                        //console.log(item);
                        item.sortorder = var_start_nbr;
                        if (var_start_nbr !== 0) {
                            var_start_nbr = var_start_nbr + 1;
                        }
                    }

                    vm.grid.gridfunc.redrawRow(item);
                });
                vm.model.sorting = false;
                vm.grid.gridfunc.refresh();
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.retrieveDefaultLocations = function () {
                vm.model.retrieving = true;

                vm.grid.gridfunc.rebind().then(function () {
                    vm.model.retrieving = false;
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadStocks();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');

                        if (vm.model.bgStockZones.selected === 'ZONES') {
                            loadSzGrid();
                            vm.szGrid.gridfunc.rebind();
                        } else if (vm.model.bgStockZones.selected === 'STOCKLOCATIONS') {
                            loadGrid();
                            vm.grid.gridfunc.rebind();
                        } else if (vm.model.bgStockZones.selected === 'WAREHOUSES') {
                            loadWhGrid();
                            vm.whGrid.gridfunc.rebind();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
