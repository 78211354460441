(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentSplitLineService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadConsignmentSplitLineGet: function (consignmentline_keyno) {
                return p2DataTaskService.call(1454, {
                    consignmentline_keyno: consignmentline_keyno
                });
            },
            loadConsignmentSplitLineList: function (consignmentline_keyno) {
                return p2DataTaskService.call(1455, {
                    consignmentline_keyno: consignmentline_keyno
                });
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1456, mergeObj);
            }
        };

        return service;
    }]);
})();