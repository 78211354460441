(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('postPlaces', {
        templateUrl: 'views/components/views/postPlaces/postPlaces.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'postPlacesService', function (stateService, utilityService, postPlacesService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                setting: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',						// Id used to retrieve user configs for the grid
                    loadSetupId: null,					// Uses default if null or undefined
                    loadData: {						// Method id of the get data procedure
                        //method: '',
                        //parameters: {}
                    },
                    addRow: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    removeRow: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    saveData: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    }
                },
                config: {							// TouchTime parameters configuring the grid
                    editColumns: [
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {						// alternative -> toolbar: false,
                        hidden: false,					// If true, hides the toolbar, default is false
                        pdfExport: true,				// Adds a predefined btn for exporting grid to pdf
                        excelExport: true,				// Adds a predefined btn for exporting grid to excel
                        filter: true,					// Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,				// Adds a predefined btn for deciding which columns to show/hide
                        headers: true,					// Adds a predefined btn for switched between db column names and titles
                        edit: false,					// Adds a predefined btn for en-/disabling editing in columns
                        lock: false,					// NOT IMPLEMENTED
                        add: false,					// Adds predefined btns for running an add function     // Supports addRow      // NOT IMPLEMENTED
                        delete: false,					// Adds a predefined btn for running a delete function  // Supports removeRow   // NOT IMPLEMENTED
                        save: false,					// Adds a predefined btn for running a save function // Needs saveData
                        buttons: [					// List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,				// Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,				// Clears sorting on only is_selected checkbox if false
                    rowClick: false,					// Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // IN DEVELOPMENT
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,					// accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        minHeight: '400px'
                    }
                },
                kendo: {							// Kendo parameters configuring the grid
                    height: '100%',						// Sets the height of the grid component
                    aggregate: true,						// accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,						// NOT IMPLEMENTED
                    selectable: 'multiple, row',				// accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                    filterable: false					// Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); },		// Used for referencing functions in the current Component
                gridfunc: null							// Used for referencing functions in the Grid Component
            };

            var optionfunc = function (data) {
                console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    console.log('optionfunc - CellClickHandler');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    console.log('optionfunc - OnCellClose');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    console.log('optionfunc - LookupCellEditor');
                    console.dir(data);
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            postPlacesService.getPostPlaces().then(function (data) {
                angular.copy(data[0], vm.model.setting);

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
		        	vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

		        if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
		        	vm.grid.dataTask.loadData = {
		        		method: vm.model.setting.p2_datatask_keyno,
		        		parameters: {}
		        	};
		        }

		        vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'postplace':
                        go({
                            postplace_id: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();
