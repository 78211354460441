(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("blkRequisitionService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadSettings: function () {
				return p2DataTaskService.call(1670, {});
			},
			loadProducts: function (parms) {
				return p2DataTaskService.call(1671, parms);
			},
			loadOpenProducts: function () {
				return p2DataTaskService.call(1710, {});
			},
			updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
			},
			typeAheadSearch: function (searchString){
				return p2DataTaskService.call(1675, {search_string: searchString});
			},
			loadProductsFromShort: function (short_id){
				return p2DataTaskService.call(1752, {prod_id_short: short_id});
			},
			createRequisition: function (lines){
				return p2DataTaskService.call(1676, {records: lines});
			},
			updateRequisition: function (lines){
				return p2DataTaskService.call(1719, {records: lines});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			}
		};

		return service;
	}]);
})();