(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('holidaysView', {
        templateUrl: 'views/components/views/holidaysView/holidaysView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'holidaysViewService', 'logisticService', function ($q, stateService, utilityService, holidaysViewService, logisticService) {
            let vm = this;
            
            vm.model = {
                setting: {},
                selectListCountries: [],
                itemsListYears: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();
                
                holidaysViewService.getCurrentCountry().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadHolidayYears()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCountries = function () {
                logisticService.listCountries().then(function (result) {
                    angular.copy(result, vm.model.selectListCountries);
                });
            };

            let loadHolidayYears = function () {
                if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;

                return holidaysViewService.listHolidayYears({ country_id: vm.model.setting.country_id }).then(function (result) {
                    angular.copy(result, vm.model.itemsListYears);
                });
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'holiday':
                        go({
                            p2_holyday_keyno: '0',
                            country_id: vm.model.setting.country_id
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'country_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.country_id) !== true) return;

                        vm.model.setting.country_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;

                        loadHolidayYears();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
                loadCountries();
            };
        }]
    });
})();
