(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('listDatas', {
        templateUrl: 'views/components/views/listDatas/listDatas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'listDatasService', function ($stateParams, stateService, listDatasService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var listKeyno = $stateParams.list_keyno;
            
            vm.model = {
                itemsListDatas: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            listDatasService.listListDatas({ list_keyno: listKeyno }).then(function (result) {
            	angular.copy(result, vm.model.itemsListDatas);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'listdata':
                        stateService.go(state, {
                            listdata_keyno: '0',
                            list_keyno: listKeyno
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();