(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("userGroupTableService", ['$ihttp', function ($ihttp) {
        var service = {
            listUserGroupTables: function (parms) {
                return $ihttp.post({
                    method: 40,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();