import { IFloatingFilterAngularComp } from '@ag-grid-community/angular';
import { FilterChangedEvent, IFloatingFilterParams } from '@ag-grid-community/core';
import { formatDate } from '@angular/common';
import { Component, ViewEncapsulation } from '@angular/core';
import { DATE_FORMATS } from '@app/core/models/date-adapter';
import { TranslateService } from '@app/core/services/translate.service';
import { UserStore } from '@app/core/services/user.store';

/**
 * Floating filter for date/datetime columns.
 */
@Component({
    selector: 'tt-date-floating-filter',
    templateUrl: './date-floating-filter.component.html',
    styleUrls: ['./date-floating-filter.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateFloatingFilterComponent implements IFloatingFilterAngularComp {
    /**
     * The placeholder for the date filter input.
     */
    public placeholder: string = '';

    /**
     * The date to filter with.
     */
    public value: Date | null = null;

    /**
     * Text to display when filter is disabled, (controlled by parent with types not supported to be edited in floating filter component).
     */
    public text: string = '';

    /**
     * The filter parameters provided.
     */
    public params?: IFloatingFilterParams;

    constructor(private user: UserStore, private translate: TranslateService) {
        this.user.dateFormatChanged.subscribe(async (format) => (this.placeholder = await this.translate.translate(format)));
    }

    /**
     * Updates the filter value and applies the filters.
     */
    public onDateChanged(event: Date | null) {
        this.value = event;

        this.params?.parentFilterInstance((instance) => {
            if (this.value) {
                instance.onFloatingFilterChanged('equals', event);
            } else {
                instance.onFloatingFilterChanged(null, null);
            }
        });
    }

    /**
     * Formats the date on enter key.
     *
     * @param event the key event.
     */
    public onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.value = !!this.value ? new Date(this.value) : null;
        }
    }

    agInit(params: IFloatingFilterParams<any, any, any>): void {
        this.params = params;
    }

    async onParentModelChanged(parentModel: any, filterChangedEvent?: FilterChangedEvent | null): Promise<void> {
        if (!!parentModel) {
            if (this.parentModelHasMultipleConditions(parentModel)) {
                this.text = this.formatDate(parentModel.condition1.dateFrom) + ' ' + parentModel.operator + ' ' + this.formatDate(parentModel.condition2.dateFrom);
            } else if (this.parentModelHasType(parentModel, 'blank')) {
                this.text = await this.translate.translate('empty');
            } else if (this.parentModelHasType(parentModel, 'notBlank')) {
                this.text = await this.translate.translate('not_empty');
            } else if (this.parentModelHasType(parentModel, 'inRange')) {
                this.text = this.formatDate(parentModel.dateFrom) + ' - ' + this.formatDate(parentModel.dateTo);
            } else if (this.parentModelHasType(parentModel, ['equals', 'notEqual', 'lessThan', 'greaterThan'])) {
                this.text = '';

                if (!this.value || !parentModel.dateFrom || (!!this.value && this.value.toISOString().substring(0, 10) !== parentModel.dateFrom.substring(0, 10))) {
                    this.value = !!parentModel.dateFrom ? new Date(parentModel.dateFrom) : null;
                }
            } else {
                this.clearFilter();
            }
        } else {
            this.clearFilter();
        }
    }

    refresh(params: IFloatingFilterParams<any, any, any>): void {}

    /**
     * Clears the filter values of this filter component.
     */
    private clearFilter() {
        this.text = '';
        this.value = null;
    }

    /**
     * Formats the given date.
     *
     * @param date the date to format.
     * @returns a string representation of the date.
     */
    private formatDate(date: Date): string {
        return formatDate(date, DATE_FORMATS.display.dateInput.format, DATE_FORMATS.display.dateInput.language);
    }

    /**
     * Checks if the given parentModel has a type property and if that type property equals the given type parameter.
     * If given a list, checks if the parentModels type is in the list.
     *
     * @param parentModel the parent model to check type of.
     * @param type the filter options type, or list of filter option types to check for.
     * @returns `true` if the parent-model contains the given type, or any of the types in the last, `false` if parent-model doesn't have type property or does not match any given filter option types.
     */
    private parentModelHasType(parentModel: any, type: AgGridFilterOption | AgGridFilterOption[]): boolean {
        const parentModelHasTypeProperty = typeof parentModel === 'object' && Object.hasOwn(parentModel, 'type');

        if (type instanceof Array) {
            return parentModelHasTypeProperty && type.includes(parentModel.type);
        }
        return parentModelHasTypeProperty && parentModel.type === type;
    }

    /**
     * Checks if the given parent-model is of a type which handles multiple conditions.
     *
     * @param parentModel the parent-model to check.
     * @returns `true` if the parent-model has multiple conditions, `false` if not.
     */
    private parentModelHasMultipleConditions(parentModel: any): boolean {
        return Object.hasOwn(parentModel, 'operator') && (parentModel.operator === 'AND' || parentModel.operator === 'OR');
    }
}

/**
 * Different filter option types provided by ag-grid.
 */
type AgGridFilterOption = 'equals' | 'notEqual' | 'lessThan' | 'greaterThan' | 'inRange' | 'blank' | 'notBlank' | 'empty';
