import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { Observable, debounceTime, switchMap, shareReplay, filter } from 'rxjs';
import { GridService } from '../../grid.service';
import { LookupCellEditorParams } from '../../grid.types';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ListboxSelectEvent } from '@app/core/directives/listbox-popup/listbox-popup.directive';
import { ListboxStyle } from '@app/core/components/listbox/listbox.component';

/**
 * Custom cell-editor component for lookup (search) cells.
 */
@Component({
    selector: 'tt-lookup-cell-editor',
    templateUrl: './lookup-cell-editor.component.html',
    styleUrls: ['./lookup-cell-editor.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class LookupCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    /**
     * Cell editor params passed from ag-grid.
     */
    public params?: LookupCellEditorParams;

    /**
     * Form-control for the search field.
     */
    public searchControl: FormControl<string> = new FormControl();

    /**
     * Whether the value in the cell has been edited since editing started.
     */
    public hasBeenEdited: boolean = false;

    /**
     * Whether change-event emitting should be suppressed, like when selecting an option.
     */
    private suppressValueChanges = false;

    /**
     * List of options to display from the search.
     */
    public filteredOptions?: Observable<{ [key: string]: string }[]>;

    /**
     * Whether forced search was triggered since the last change.
     */
    public _forcedSearch = false;

    /**
     * Element reference to the input element.
     */
    @ViewChild('inputRef')
    public inputRef?: ElementRef;

    /**
     * Reference to the trigger for the listbox displaying search options.
     */
    @ViewChild(MatAutocompleteTrigger)
    public autoComplete?: MatAutocompleteTrigger;

    /**
     * Whether the edited value in the cell should be cancelled or not.
     */
    private cancelEdit = false;

    /**
     * Forces search on key arrow down, closes listbox on key arrow up. Cancels editing when escape key is pressed.

     * @param event the keydown event that happened on the component.
     */
    @HostListener('keydown', ['$event'])
    public keydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            if (this.autoComplete?.panelOpen) {
                this.autoComplete.closePanel();
            } else {
                this.params?.api.stopEditing(true);
                this.params?.api.setFocusedCell(this.params.rowIndex, this.params.field);
            }
        } else if (typeof this.searchControl?.value === 'string' && this.searchControl?.value.trim() === '' && event.key === 'ArrowDown') {
            this.forceSearch();
        } else if (event.ctrlKey && event.key === 'ArrowUp') {
            this.autoComplete?.closePanel();
        } else if (event.key === 'Enter') {
            event.preventDefault();
            event.stopImmediatePropagation();
            event.stopPropagation();
        } else if (event.key === 'Tab') {
            this.cancelEdit = true;
        }

        setTimeout(() => {
            this.params?.onKeyDown(event);
        });
    }

    /**
     * Styles for the elements in the template.
     */
    public style: Style = {
        input: {},
        listbox: {},
        option: {},
    };

    public listboxStyle: Partial<ListboxStyle> = {
        selectedOption: {
            background: 'var(--tt-primary-color)',
            color: 'var(--tt-primary-text-color)',
        },
    };

    public id = {
        wrapper: crypto.randomUUID(),
        input: crypto.randomUUID(),
    };

    constructor(layoutService: LayoutService, private gridService: GridService) {
        layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['input'].fontSize = info.fontSizes.textSize;
                this.style['option'].fontSize = info.fontSizes.textSize;
                this.style['option'].minHeight = `calc(${info.fontSizes.textSize} + 5px)`;
                this.style['listbox'].margin = 'var(--ag-grid-size) 0';
                this.style['listbox'].minWidth = '25rem';
            }
        });
    }

    /**
     * Sets the same value again to trigger a change and thus a search to be called.
     */
    private forceSearch() {
        if (this._forcedSearch === true) return;
        this._forcedSearch = true;

        setTimeout(() => this.searchControl.setValue(this.searchControl.value), 250);
    }

    /**
     * Performs a search with the values passed in params and the given search string.
     *
     * @param search the filter-text to use for the search request.
     * @returns a list containing the result of the search request.
     */
    private async lookupSearch(search: string): Promise<{ [key: string]: string }[]> {
        if (search === '' && !this._forcedSearch) {
            this.autoComplete?.closePanel();
        } else if (this.params?.method && !isNaN(Number(this.params.method)) && this.params.field && this.params?.node.data) {
            return this.gridService.lookupSearch(Number(this.params.method), this.params.field, search, this.params?.node.data);
        }

        this._forcedSearch = false;

        return [];
    }

    /**
     * Sets the selected value and stops the cell editing.
     *
     * @param event the event transmitted for the selected option.
     */
    public onOptionSelected(event: ListboxSelectEvent<{ [key: string]: string }>) {
        this.suppressValueChanges = true;

        this.searchControl.setValue(JSON.stringify(event.item), { onlySelf: true, emitEvent: false, emitModelToViewChange: false, emitViewToModelChange: false });

        setTimeout(() => {
            this.params?.api.stopEditing();
            this.params?.api.setFocusedCell(this.params.rowIndex, this.params.field);
            if (event.event.type === 'keydown') {
                this.params?.onKeyDown(event.event as KeyboardEvent);
            }
        });
    }

    public agInit(params: LookupCellEditorParams): void {
        this.params = params;
        this.style['input'].width = this.params.column.getActualWidth() + 'px';

        this.searchControl.setValue(this.params.value ?? '');
        this.searchControl.valueChanges.subscribe((value) => {
            if (!value && !this._forcedSearch) {
                this.autoComplete?.closePanel();
            } else if (!!value) {
                this._forcedSearch = false;
            }
        });

        this.filteredOptions = this.searchControl.valueChanges.pipe(
            filter(() => !this.suppressValueChanges),
            debounceTime(250),
            switchMap((result: string) => this.lookupSearch(result)),
            shareReplay(1)
        ) as Observable<{ [key: string]: string }[]>;
    }

    public setValue(value: string) {
        this.hasBeenEdited = true;
        this.searchControl.setValue(value, { emitEvent: false });
    }

    isCancelAfterEnd(): boolean {
        return this.cancelEdit;
    }

    public getValue() {
        return this.searchControl.value;
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.inputRef?.nativeElement.select(), 10);
    }
}
