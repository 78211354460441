(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactProcess', {
        templateUrl: 'views/components/views/custactProcess/custactProcess.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'custactProcessService', function($stateParams, stateService, utilityService, custactProcessService) {
            var vm = this;
            
            vm.model = {
                custactNo: $stateParams.custact_no,
                itemsListProcesses: [],
                lockedSave: false
            };
            
            custactProcessService.loadCustactProcessList(vm.model.custactNo).then(function (data) {
            	angular.copy(data, vm.model.itemsListProcesses);
            });
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
            
            vm.saveCustactProcess = function () {
                vm.model.lockedSave = true;
                
                var mergeObj = {
                    custact_no: vm.model.custactNo,
                    records: vm.model.itemsListProcesses.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; })
                };
                
                custactProcessService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();