(function () {
    'use strict';

    angular.module("imApp").factory("ttGridService", ['$q', '$ihttp', 'printService', function ($q, $ihttp, printService) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
            //return btoa(escape(encodeURIComponent(data)));
        }

        var atou = function (data) { // Utility function for base64decode with unicode support
            //return atob(unescape(decodeURIComponent(data)));
            return decodeURIComponent(escape(atob(data)));
            //return decodeURIComponent(unescape(atob(data)));
        }

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        let reports = [];

        var service = {
            activeGridId: null,         // keeps track of last 'active' grid, when several grids are in use
            remember: function (method, rememberId, settings, isBase) {
                return call(angular.isDefined(method) && method !== null ? method : 616, { // for use if need to have a different method
                    is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(settings) : utoa(angular.toJson(settings)) //defaults to true
                });
            },
            getRemember: function (method, variableName) {
                return call(angular.isDefined(method) && method !== null ? method : 973, { variablename: variableName }); // for use if need to have a different method
            },
            loadSetup: function (method, keyno, parms) {
                if (angular.isObject(parms) !== true) {
                    parms = {};
                }

                parms.p2_datatask_keyno = keyno;

                return call(angular.isDefined(method) && method !== null && method.length > 0 ? method : 1999, parms);
            },
            loadData: function (p2DataTaskKeyno, parameters) {
                return call(p2DataTaskKeyno, parameters || {});
            },
            saveData: function (p2DataTaskKeyno, model) {
                return call(p2DataTaskKeyno, model || {});
            },
            loadLookupSearch: function (options, method, searchfield, searchtext, row) {
                call(method, {
                    searchfield: searchfield,
                    searchtext: searchtext,
                    row: row
                }).then(function (result) {
                    return options.success(result);
                });
            },
            saveLayouts: function (p2DataTaskKeyno, parameters) {
                if (angular.isDefined(parameters.layout_schema)) {
                    parameters.is_base64 = 1;
                    parameters.layout_schema = utoa(parameters.layout_schema)
                }
                return call(angular.isDefined(p2DataTaskKeyno) && p2DataTaskKeyno !== null ? method : 2241, parameters || {});
            },
            loadLayouts: function (p2DataTaskKeyno, parameters) {
                return call(angular.isDefined(p2DataTaskKeyno) && p2DataTaskKeyno !== null ? method : 2240, parameters || {});
            },
            getPrintServerReports: function () {
                let deferred = $q.defer();

                if (reports.length === 0) {
                    printService.getReports().then(function (result) {
                        reports = result.items;
                        deferred.resolve(reports);
                    });
                } else { 
                    deferred.resolve(reports);
                }

                return deferred.promise;
            },
            getReportParameters: function (keyno) {
                return printService.getParameters({ reportKeyno: keyno });
            },
            gridPrint: function (model) {
                return call(3003, model);
            },
            getLoadMethodName: function (p2DataTaskKeyno) {
                return call(2530, {
                    p2_datatask_keyno: p2DataTaskKeyno
                });
            }
        }

        return service;
    }]);
})();
