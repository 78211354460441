(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('actStatusLangs', {
        templateUrl: 'views/components/views/actStatusLangs/actStatusLangs.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'actStatusLangsService', function ($stateParams, stateService, translateService, modalService, actStatusLangsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let actStatusKeyno = $stateParams.actstatus_keyno;

            let translations = {
                confirmation: '',
                error: '',
                ok: ''
            };

			vm.model = {
				inputListLanguages: [],
				lockedSave: false
			};

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadLanguages = function () {
                actStatusLangsService.listLanguages({ actstatus_keyno: actStatusKeyno }).then(function (result) {
                    angular.copy(result, vm.model.inputListLanguages);
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                actStatusLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function (response) {
                    if (response[0].ws_result === '1') {
                    	modalService.show({
                    		type: 'success',
                            title: translations.confirmation,
                            message: response[0].ws_result_message,
                    		buttons: [{
                    			label: translations.ok,
                                cssClass: 'btn-success',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                    			}
                    		}]
                    	});
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadLanguages();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
