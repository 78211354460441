(function() {
    'use strict';

    angular.module("imApp").factory("dispatchPlanningService", ['p2DataTaskService', function (p2DataTaskService) {
        let service = {
            loaded: false,
            getDeliveryMethods: function () {
                return p2DataTaskService.call(1557, {});
            },
            listOrders: function (parms) {
                return p2DataTaskService.call(1998, parms);
            },
            updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
            },
            loadPageSettings: function () {
                return p2DataTaskService.call(1562, {});
            },
            savePageSetting: function (parms) {
                return p2DataTaskService.call(616, parms);
            },
            consolidateSelected: function (parms) {
                return p2DataTaskService.call(1563, parms);
            },
            splitDispatch: function (dispatch_no) {
                return p2DataTaskService.call(1564, { dispatch_no: dispatch_no });
            }
        };

        return service;
    }]);
})();
