(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStateEdit', {
        templateUrl: 'views/components/views/oeeStateEdit/oeeStateEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'oeeStateEditService', 'stateService', function($stateParams, oeeStateEditService, stateService) {
            var vm = this;
            
            oeeStateEditService.loadP2OeeStatesGet($stateParams.oeestate_keyno).then(function () {
                vm.p2_oeestates_get = oeeStateEditService.p2_oeestates_get;
            });
            
            oeeStateEditService.loadP2BootstrapColorsList().then(function () {
                vm.p2_bootstrap_colors_list = oeeStateEditService.p2_bootstrap_colors_list;
            });
            
            vm.saveP2OeeStates = function () {
                oeeStateEditService.saveP2OeeStates().then(function () {
                    stateService.back();
                });
            };
            
            vm.goToOeeStateEquipments = function () {
                stateService.go('oeestate_equipments', {
                    argtype: 'oeestate_keyno',
                    argvalue: $stateParams.oeestate_keyno
                });
            };
            
            vm.goToOeeStateRemarks = function () {
                stateService.go('oeestateremarks', {
                    oeestate_keyno: $stateParams.oeestate_keyno
                });
            };
        }]
    });
})();