(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButtonGroup', {
        templateUrl: 'views/components/directives/ttButtonGroup/ttButtonGroup.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttItemId: '@?',         //Used if group should have a unique ID.
            label: '@?',            //Used for translation of label                                 
            plabel: '<?',           //Used instead of label when property is required
            autotranslate: '@?',    //Used if auto translation of item_name should be turned off        //Defaults to 'true', (translation is on by default)
            typevar: '@?',          //Used to add 'q' for required
            grpCssSize: '@?',       //Added as class and will determine the size of the grp with ability to add one or more sizes xs, sm, md, lg. Ex col-xs-8 col-lg-4
            divider: '=?',          //Used to manually set the divider. Number of buttons per group.
            listItems: '<',         //Contains the dataset for the buttons.                             //Required
            activeBtn: '<',         //Used to determine which is the active button.                     //Requires an index 'Number' (can be number in string form)
            optionfunc: '&',        //Used as reference to function in Controller
        },
        controller: ['eventService', 'stateService', 'utilityService', 'layoutService', 'ttDirectivesService', function (eventService, stateService, us, layoutService, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];
            vm.label = null;
            vm.translateLabel;

            vm.model = {
                mId: uuid(),
                runByList: false,
                activeBtn: { index: null, row: null },
                lines: [{ list: [], gwidth: '', row: '' }],
                buttonStyle: {       // BJS 20210522
                    fontSize: '',
                    height: ''
                }
            };

            vm.buttons = [];

            vm.style = {
                label: {},
                input: {},
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.activeState = function (index, row) {
                return vm.model.activeBtn.index === index + hiddenItems() && vm.model.activeBtn.row === row;
            };

            vm.addGroup = function () {
                var allClass = 'form-group'; //default

                if (vm?.grpCssSize) {
                    allClass += ' ' + vm.grpCssSize;
                }

                return allClass;
            };

            vm.addClass = function () {
                var allClass = 'btn-group col-xs-12 im-no-pad'; //default

                if ((vm?.label && vm.label !== '') || (vm?.plabel && vm.plabel !== '')) {
                    allClass = 'btn-group col-xs-12 col-sm-10 im-no-pad';
                }

                return allClass;
            };

            vm.addBtnClass = function (index, row) {
                var allClass = 'btn btn-block btn-lg im-btn'; //default

                if (vm?.buttonStyle) {
                    allClass = 'btn ' + vm.buttonStyle + ' btn-block btn-lg im-btn';
                }

                if (index === 0) {
                    allClass += ' im-group-btn-1';
                } else if (index === (vm.model.lines[row].list.length - 1)) {
                    allClass += ' im-group-btn-3';
                } else {
                    allClass += ' im-group-btn-2';
                }

                if (vm.model.activeBtn.index === index + hiddenItems() && vm.model.activeBtn.row === row) {
                    allClass += ' im-active-btn';
                }

                //console.log('index: ', index, ' class: ', allClass);

                return allClass;
            };

            vm.selectButton = function (index, row) {
                vm.model.activeBtn = { index: index + hiddenItems(), row: row };
            };

            vm.itemFunc = function (item) {
                vm.optionfunc({ item: item.item_func });
            };

            vm.fontSizes = {
                textSize: '',
                textSizeS: '',
                textSizeSs: ''
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                angular.copy(info.fontSizes, vm.fontSizes);
                vm.model.fontSize = info.fontSizes.textSize;

                vm.model.buttonStyle.fontSize = info.fontSizes.textSize;
                vm.model.buttonStyle.height = info.height + 'px';
                vm.model.buttonStyle.padding = '0px 5px'
            });

            var hiddenItems = function () {
                var hiddenItems = 0;
                var d = angular.isNumber(vm.model.activeBtn) ? vm.model.activeBtn : parseInt(vm.model.activeBtn);
                for (var h = 0; h < vm.listItems.length; h++) {
                    if (vm.listItems?.[h]?.hidden && vm.listItems[h].hidden === true && h <= d) {
                        hiddenItems++;
                    }
                    if (h >= d) {
                        break;
                    }
                }

                return hiddenItems;
            };

            // BJS 20190127 - Moved this code out to separate function because same code was used twice.
            //                Also modified so $scope.model.activeBnt.index is an int not a string.
            var activeIndexChanged = function () {
                // BJS 20180524 - Endret litt for � kunne st�tte integer som activeBtn
                if (!vm?.activeBtn || vm.activeBtn === '') return;

                vm.model.activeBtn.index = angular.isNumber(vm.activeBtn) ? vm.activeBtn : parseInt(vm.activeBtn);

                var divider = vm.listItems.length < 7 ? 3 : 4;
                var groupNum = Math.ceil(vm.listItems.length / divider);
                var inUse = 0;

                for (var g = 0; g < groupNum; g++) {
                    for (var ii = 0; ii < divider; ii++) {
                        if (inUse === vm.model.activeBtn.index) {
                            inUse++;
                            vm.model.activeBtn.row = g;
                            break;
                        } else {
                            inUse++;
                        }
                    }
                }

                let item = vm.listItems?.[vm.model.activeBtn.index];

                if (item) {
                    if(item?.hidden && item.hidden === true) return;
                    vm.optionfunc({ item: vm.listItems[vm.model.activeBtn.index].item_func });
                }
            };

            let refreshButtonGroup = function () {
                if (vm?.listItems && vm.listItems.length > 0) {
                    var dividerSmall = angular.isNumber(vm?.divider) ? vm.divider : 3;
                    var dividerLarge = angular.isNumber(vm?.divider) ? vm.divider : 4;

                    var divider = vm.listItems.length < 7 ? dividerSmall : dividerLarge;

                    var hiddenItems = 0;
                    angular.forEach(vm.listItems, function (data) {
                        if (data?.hidden && data.hidden === true) {
                            hiddenItems++;
                        }
                    });

                    var groupNum = Math.ceil((vm.listItems.length - hiddenItems) / divider);
                    var inUse = 0;

                    for (var g = 0; g < groupNum; g++) {
                        var tempList = [];

                        for (var i = 0; i < divider; i++) {
                            if (inUse >= vm.listItems.length) break;

                            if (angular.isUndefined(vm.listItems[inUse].hidden) || vm.listItems[inUse].hidden !== true) {
                                tempList.push(vm.listItems[inUse]);
                            }

                            inUse++;
                        }

                        var gw = (100 / tempList.length).toString() + "%";
                        vm.model.lines[g] = {
                            list: tempList,
                            gwidth: gw,
                            row: g
                        };
                    }
                }

                vm.model.runByList = true;

                activeIndexChanged();

                vm.model.runByList = false;
            };

            vm.$onInit = function () {
                //refreshButtonGroup();


            }

            vm.activeBtnChanged = function (value) {
                vm.activeBtn = value;
                vm.optionfunc({ item: value });
            }

            vm.$onChanges = function (changes) {
                if (!!changes?.plabel?.currentValue && typeof changes.plabel.currentValue === 'string' && changes.plabel.currentValue.trim()) {
                    vm.label = changes.plabel.currentValue;
                    vm.translateLabel = 'false';
                }

                if (!!changes?.label?.currentValue && typeof changes.label.currentValue === 'string' && !!changes.label.currentValue.trim()) {
                    vm.label = changes.label.currentValue;
                    vm.translateLabel = 'true';
                }

                if (changes?.activeBtn?.currentValue) {
                    let active = vm.listItems.find((item) => item.item_id === changes.activeBtn.currentValue);

                    if (active && active?.item_func) {
                        vm.activeBtnChanged(active.item_func);
                    }
                }

                if (changes?.listItems?.currentValue) {

                    vm.buttons = changes.listItems.currentValue.map((button) => {
                        return { id: button.item_func, label: button.item_name };
                    })

                    if (vm.buttons && !vm.activeBtn) {
                        vm.activeBtnChanged(vm.buttons[0].id);
                    }
                }

                
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
