(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("checkListItemService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 543,
                    parameters: parms || {}
                });
            },
            listElementTypes: function (parms) {
                return $ihttp.post({
                    method: 544,
                    parameters: parms || {}
                });
            },
            listDependencies: function (parms) {
                return $ihttp.post({
                    method: 545,
                    parameters: parms || {}
                });
            },
            listDependencyValues: function (parms) {
                return $ihttp.post({
                    method: 546,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 547,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
