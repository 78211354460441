(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productLangs', {
        templateUrl: 'views/components/views/productLangs/productLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'productLangsService', function ($stateParams, productLangsService) {
            let vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                itemsListProductLanguages: []
            };

            let loadProductLanguages = function () {
                productLangsService.listProductLanguages({ prod_id: prodId }).then(function (result) {
                    angular.copy(result, vm.model.itemsListProductLanguages);
                });
            };

            vm.$onInit = function () {
                loadProductLanguages();
            };
        }]
    });
})();
