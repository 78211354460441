(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("orderCustomerServiceService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
            loaded: false,
            getOrder: function(internal_no) {
                return p2DataTaskService.call(1872, { order_internal_no: internal_no });
            },
            updateColumnResize: function(parms) {
                return p2DataTaskService.call(1457, parms);
            },
            savePageSetting: function(parms) {
                return p2DataTaskService.call(616, parms);
            },
            listOrdercustcoms: function(internal_no) {
                return p2DataTaskService.call(1871, { order_internal_no: internal_no });
            },
            listOrderlines: function(internal_no) {
                return p2DataTaskService.call(1864, { order_internal_no: internal_no });
            },
            listConsignments: function (internal_no) {
                return p2DataTaskService.call(1865, { order_internal_no: internal_no });
            },
            listRequisitions: function (internal_no) {
                return p2DataTaskService.call(1866, { order_internal_no: internal_no });
            },
            listPurchlines: function (internal_no) {
                return p2DataTaskService.call(1869, { order_internal_no: internal_no });
            },
            calculateDeliveryDate: function (order_internal_no) {
                return p2DataTaskService.call(1899, {
                    order_internal_no: order_internal_no
                });
            }
        };
        return service;
    }]);
})();