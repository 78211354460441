(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipmentGrpMenu', {
        templateUrl: 'views/components/views/equipmentGrpMenu/equipmentGrpMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'equipmentGrpMenuService', function ($stateParams, stateService, utilityService, equipmentGrpMenuService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            vm.model = {
                equipmentGrpId: $stateParams.equipmentgrp_id,
                menuEquipmentGroup: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            equipmentGrpMenuService.getEquipmentGrpMenu(vm.model.equipmentGrpId).then(function (data) {
                angular.copy(data[0], vm.model.menuEquipmentGroup);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'equipmentgrplang':
                        go({
                            equipmentgrp_id: vm.model.menuEquipmentGroup.equipmentgrp_id
                        });
                        break;
                    case 'equipmentgrp':
                        go({
                            equipmentgrp_id: vm.model.menuEquipmentGroup.equipmentgrp_id
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
            	angular.forEach(onDestroy, function (fn) {
            		if (angular.isFunction(fn) === true) {
            			fn();
            		}
            	});
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
