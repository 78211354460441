(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productSettings', {
        templateUrl: 'views/components/views/productSettings/productSettings.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'productSettingsService', function (stateService, productSettingsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                itemsListProductSettings: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadProductSettings = function () {
                productSettingsService.listProductSettings().then(function (list) {
                    angular.copy(list, vm.model.itemsListProductSettings);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'productsetting':
                        go({ productsetting_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadProductSettings();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
