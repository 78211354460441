(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStateRemark', {
        templateUrl: 'views/components/views/oeeStateRemark/oeeStateRemark.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'oeeStateRemarkService', 'stateService', function($stateParams, oeeStateRemarkService, stateService) {
            var vm = this;
            
            oeeStateRemarkService.loadP2OeeStateRemarkGet($stateParams.oeestate_keyno, $stateParams.oeestateremark_keyno).then(function () {
                vm.p2_oeestateremark_get = oeeStateRemarkService.p2_oeestateremark_get;
            });       
            
            vm.saveP2OeeStateRemark = function () {
                oeeStateRemarkService.saveP2OeeStateRemark().then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();