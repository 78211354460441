(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockBatchOnLocation', {
        templateUrl: 'views/components/views/stockBatchOnLocation/stockBatchOnLocation.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$window', 'stateService', 'stockBatchOnLocationService', function ($stateParams, $q, $window, stateService, stockBatchOnLocationService) {

            // #region VARIABLES & DEFINITIONS
            
            let vm = this;
            let stockKeyno = $stateParams.stock_keyno;
            let stockLocationKeyno = $stateParams.stocklocation_keyno;
            let functionName = $stateParams.functionname;
            
            vm.model = {
                stockKeyno: stockKeyno,
                functionName: functionName,
                bgPickType: {
                    selected: 'stockbatchonlocation_pick_part',
                    buttons: [
                        { id: 'stockbatchonlocation_pick_part', label: 'stockbatchonlocation_pick_part', color: 'primary', onClick: function () { vm.model.bgPickType.selected = 'stockbatchonlocation_pick_part'; } },
                        { id: 'stockbatchonlocation_show', label: 'stockbatchonlocation_show', color: 'primary', onClick: function () { vm.model.bgPickType.selected = 'stockbatchonlocation_show'; } },
                    ]
                },
                setting: {},
                itemsListStockBatches: [],
                gridReady: false,
                gridView: false,
                gridDataLoaded: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_stockbatchonlocation_grid',
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        //method: 2577,
                        //parameters: {
                        //    stock_keyno: $stateParams.stock_keyno
                        //}
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    editColumns: [],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: false,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: false,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: false,                                 // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: []                                     // List of custom buttons
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: []
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                          // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OTHER

            var optionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);

                    // Save the default printer to db.
                    // vm.grid.gridfunc.rebind();
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            // ## LOAD PROCEDURE CALLS ##

            let loadGrid = function () {
                if (vm.model.gridView === false) return;

                vm.grid.dataTask.loadData = {
                    method: 2579,
                    parameters: {
                        stock_keyno: stockKeyno,
                        stocklocation_keyno: stockLocationKeyno,
                        functionname: functionName,
                        list_type: vm.model.bgPickType.selected
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) {
                    vm.grid.gridfunc.rebind();
                }
            };

            let loadSetting = function () {
                let deferred = $q.defer();
                
                stockBatchOnLocationService.getStockInfo({
                    stock_keyno: stockKeyno,
                    stocklocation_keyno: stockLocationKeyno,
                    functionname: functionName
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockbatchInfoShow(),
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadStockbatchInfoShow = function () {
                return stockBatchOnLocationService.listStockBatchInfoShow({
                    stock_keyno: stockKeyno,
                    stocklocation_keyno: stockLocationKeyno,
                    functionname: functionName,
                    list_type: vm.model.bgPickType.selected
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockBatches);
                });
            };

            vm.selectBgPickType = function (item) {
                vm.model.bgPickType.selected = item;

                if (vm.model.bgPickType.selected === 'stockbatchonlocation_pick_part') {
                    loadStockbatchInfoShow();
                } else if (vm.model.bgPickType.selected === 'stockbatchonlocation_show') {
                    if (vm.model.gridView === false) {
                        loadStockbatchInfoShow();
                    } else {
                        loadGrid();
                    }
                }
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockget':
                        go({ stock_keyno: stockKeyno });
                        break;
                    case 'stockbatchonlocation':
                        go({
                            stock_keyno: '0',
                            stocklocation_keyno: stockLocationKeyno,
                            functionname: functionName
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            var onWindowResize = function () {
                if (window.innerWidth > 926) {
                    if (vm.model.gridView === false) {
                        vm.model.gridView = true;
                        
                        if (vm.model.gridReady === false && vm.model.gridDataLoaded === false) {
                            loadGrid();
                            vm.model.gridDataLoaded = true;
                        }
                    }
                } else {
                    if (vm.model.gridView === true) {
                        vm.model.gridView = false;
                        loadStockbatchInfoShow();
                    }
                }
            };

            let onDestroy = [];

            vm.$onInit = function () {
                loadSetting();
                $window.addEventListener('resize', onWindowResize);
                onWindowResize();
                loadGrid();
            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
