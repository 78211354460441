(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("businesscoSettingSveaService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loadP2BusinessCoSettingSveaGet: function (businessco_no) {
                return $ihttp.post({
                    method: 2985,
                    parameters: {
                        businessco_no: businessco_no
                    }
                });
            },
            testCredentials: function (svea_sender_id, svea_api_key) {
                return $ihttp.post({
                    method: 2987,
                    parameters: {
                        svea_sender_id: svea_sender_id,
                        svea_api_key: svea_api_key
                    }
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2986,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
