(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicViewService", ['$ihttp', function($ihttp) {
        var service = {
            init: function (route) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'init',
                        route_id: route
                    }
                });
            },
            getDatataskList: function () {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'datatasks'
                    }
                });
            }
        }

        return service;
    }]);
})();
