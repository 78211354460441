export interface IUserDetailsDb {
    breadcrumb_view: string;
    develop_mode: boolean;
    email: string;
    fontsize: number;
    fontsize_mobile: string;
    hexcolor: string;
    input_style: string;
    label_always_on_top: boolean;
    labour_name: string;
    labour_no: number;
    language_id: string;
    date_format: DateFormat;
    last_login_date: string;
    mobilephone: string;
    my_cellphoneno: string;
    my_email: string;
    portal_user_keyno_edit: number;
    show_settings: boolean;
    theme_keyno: number;
    theme_keyno_mobile: number;
    usergroup_keyno: number;
    usergroups: string;
    user_id_ie: string;
    user_id_portal: string;
    user_name: string;
    windowheadingcolor: string;
    roles: string[];
    /**
     * Whether ag-grid should be used over kendo-grid.
     */
    use_ag_grid: boolean;
}

export interface IUserDetails {
    breadcrumbView: string;
    developMode: boolean;
    email: string;
    fontSize: number;
    fontSizeMobile: string;
    hexColor: string;
    inputStyle: string;
    labelAlwaysOnTop: boolean;
    labourName: string;
    labourNo: number;
    languageId: string;
    dateFormat: DateFormat;
    lastLoginDate: string;
    mobilePhone: string;
    myCellPhoneNo: string;
    myEmail: string;
    portalUserKeynoEdit: number;
    showSettings: boolean;
    themeKeyno: number;
    themeKeynoMobile: number;
    userGroupKeyno: number;
    userGroups: string;
    userIdIe: string;
    userIdPortal: string;
    userName: string;
    windowHeadingColor: string;
    roles: string[];
    useAgGrid: boolean;
}

/**
 * Represents a date-format.
 */
export type DateFormat = 'dd-mm-yyyy' | 'dd/mm/yyyy' | 'mm/dd/yyyy' | 'yyyy/mm/dd' | 'yyyy-mm-dd';

export class UserDetails implements IUserDetails {
    private _breadcrumbView: string = '';
    private _developMode: boolean = false;
    private _email: string = '';
    private _fontSize: number = 14;
    private _fontSizeMobile: string = '';
    private _hexColor: string = '';
    private _inputStyle: string = '';
    private _labelAlwaysOnTop: boolean = false;
    private _labourName: string = '';
    private _labourNo: number = 0;
    private _languageId: string = '';
    private _lastLoginDate: string = '';
    private _mobilePhone: string = '';
    private _myCellPhoneNo: string = '';
    private _myEmail: string = '';
    private _portalUserKeynoEdit: number = 0;
    private _showSettings: boolean = false;
    private _themeKeyno: number = 0;
    private _themeKeynoMobile: number = 0;
    private _userGroupKeyno: number = 0;
    private _userGroups: string = '';
    private _userIdIe: string = '';
    private _userIdPortal: string = '';
    private _userName: string = '';
    private _windowHeadingColor: string = '';
    private _roles: string[] = [];
    private _userAgGrid: boolean = false;
    private _dateFormat: DateFormat = 'dd/mm/yyyy';

    constructor(details: IUserDetailsDb) {
        this.breadcrumbView = details.breadcrumb_view;
        this.developMode = details.develop_mode;
        this.email = details.email;
        this.fontSize = details.fontsize;
        this.fontSizeMobile = details.fontsize_mobile;
        this.hexColor = details.hexcolor;
        this.inputStyle = details.input_style;
        this.labelAlwaysOnTop = details.label_always_on_top;
        this.labourName = details.labour_name;
        this.labourNo = details.labour_no;
        this.languageId = details.language_id;
        this.dateFormat = details.date_format;
        this.lastLoginDate = details.last_login_date;
        this.mobilePhone = details.mobilephone;
        this.myCellPhoneNo = details.my_cellphoneno;
        this.myEmail = details.my_email;
        this.portalUserKeynoEdit = details.portal_user_keyno_edit;
        this.showSettings = details.show_settings;
        this.themeKeyno = details.theme_keyno;
        this.themeKeynoMobile = details.theme_keyno_mobile;
        this.userIdIe = details.user_id_ie;
        this.userIdPortal = details.user_id_portal;
        this.userName = details.user_name;
        this.userGroupKeyno = details.usergroup_keyno;
        this.userGroups = details.usergroups;
        this.windowHeadingColor = details.windowheadingcolor;
        this.roles = details.roles;
        this.useAgGrid = details.use_ag_grid;
    }

    get breadcrumbView(): string {
        return this._breadcrumbView;
    }

    set breadcrumbView(value: string) {
        this._breadcrumbView = value;
    }

    get developMode(): boolean {
        return this._developMode;
    }

    set developMode(value: boolean) {
        this._developMode = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get fontSize(): number {
        return this._fontSize;
    }

    set fontSize(value: number) {
        this._fontSize = value;
    }

    get fontSizeMobile(): string {
        return this._fontSizeMobile;
    }

    set fontSizeMobile(value: string) {
        this._fontSizeMobile = value;
    }

    get hexColor(): string {
        return this._hexColor;
    }

    set hexColor(value: string) {
        this._hexColor = value;
    }

    get inputStyle(): string {
        return this._inputStyle;
    }

    set inputStyle(value: string) {
        this._inputStyle = value;
    }

    get labelAlwaysOnTop(): boolean {
        return this._labelAlwaysOnTop;
    }

    set labelAlwaysOnTop(value: boolean) {
        this._labelAlwaysOnTop = value;
    }

    get labourName(): string {
        return this._labourName;
    }

    set labourName(value: string) {
        this._labourName = value;
    }

    get labourNo(): number {
        return this._labourNo;
    }

    set labourNo(value: number) {
        this._labourNo = value;
    }

    get languageId(): string {
        return this._languageId;
    }

    set languageId(value: string) {
        this._languageId = value;
    }

    get dateFormat(): DateFormat {
        return this._dateFormat;
    }

    set dateFormat(value: DateFormat) {
        this._dateFormat = value;
    }

    get lastLoginDate(): string {
        return this._lastLoginDate;
    }

    set lastLoginDate(value: string) {
        this._lastLoginDate = value;
    }

    get mobilePhone(): string {
        return this._mobilePhone;
    }

    set mobilePhone(value: string) {
        this._mobilePhone = value;
    }

    get myCellPhoneNo(): string {
        return this._myCellPhoneNo;
    }

    set myCellPhoneNo(value: string) {
        this._myCellPhoneNo = value;
    }

    get myEmail(): string {
        return this._myEmail;
    }

    set myEmail(value: string) {
        this._myEmail = value;
    }

    get portalUserKeynoEdit(): number {
        return this._portalUserKeynoEdit;
    }

    set portalUserKeynoEdit(value: number) {
        this._portalUserKeynoEdit = value;
    }

    get showSettings(): boolean {
        return this._showSettings;
    }

    set showSettings(value: boolean) {
        this._showSettings = value;
    }

    get themeKeyno(): number {
        return this._themeKeyno;
    }

    set themeKeyno(value: number) {
        this._themeKeyno = value;
    }

    get themeKeynoMobile(): number {
        return this._themeKeynoMobile;
    }

    set themeKeynoMobile(value: number) {
        this._themeKeynoMobile = value;
    }

    get userGroupKeyno(): number {
        return this._userGroupKeyno;
    }

    set userGroupKeyno(value: number) {
        this._userGroupKeyno = value;
    }

    get userGroups(): string {
        return this._userGroups;
    }

    set userGroups(value: string) {
        this._userGroups = value;
    }

    get userIdIe(): string {
        return this._userIdIe;
    }

    set userIdIe(value: string) {
        this._userIdIe = value;
    }

    get userIdPortal(): string {
        return this._userIdPortal;
    }

    set userIdPortal(value: string) {
        this._userIdPortal = value;
    }

    get userName(): string {
        return this._userName;
    }

    set userName(value: string) {
        this._userName = value;
    }

    get windowHeadingColor(): string {
        return this._windowHeadingColor;
    }

    set windowHeadingColor(value: string) {
        this._windowHeadingColor = value;
    }

    get roles(): string[] {
        return this._roles;
    }

    set roles(value: string[]) {
        this._roles = value;
    }

    get useAgGrid(): boolean {
        return this._userAgGrid;
    }

    set useAgGrid(value: boolean) {
        this._userAgGrid = value;
    }
}
