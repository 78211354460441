(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poPlansProdService", ['$q', '$ihttp',  function ($q, $ihttp) {
        var service = {
            loaded: false,
            poPlansProdGet: {},
            loadPoPlansProdGet: function (stock_keyno, date_to) {
                var deferred = $q.defer();

                var parmsPoPlansProdGet = {
                    method: 436,
                    parameters: {
                        stock_keyno: stock_keyno,
                        date_to: date_to
                    }
                };

                service.poPlansProdGet = {};

                $ihttp.post(parmsPoPlansProdGet).then(function (data) {
                    service.poPlansProdGet = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            poPlansProdSave: function (stock_keyno, date_to, quantity_tostart) {
                var deferred = $q.defer();

                var parmsPoPlansProdSave = {
                    method: 438,
                    parameters: {
                        stock_keyno: stock_keyno,
                        date_to: date_to,
                        quantity_tostart: quantity_tostart
                    }
                };

                $ihttp.post(parmsPoPlansProdSave).then(function (data) {
                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();