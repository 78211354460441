(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("powTimeApprovalService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1245,
                    parameters: parms || {}
                });
            },
            listActivities: function (parms) {
                return $ihttp.post({
                    method: 1859,
                    parameters: parms || {}
                });
            },
            listApprovals: function (parms) {
                return $ihttp.post({
                    method: 1247,
                    parameters: parms || {}
                });
            },
            saveObj: function (list) {
                return $ihttp.post({
                    method: 1248,
                    parameters: list
                });
            },
            changeItems: function (mergeObj) {
                return $ihttp.post({
                    method: 1858,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
