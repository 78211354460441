(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('inventoryArticleSearch', {
        templateUrl: 'views/components/views/inventoryArticleSearch/inventoryArticleSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'inventoryArticleSearchService', function ($q, utilityService, inventoryArticleSearchService) {
            let vm = this;
    		
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadInventoryArticles() }
                ],
                setting: {},
                itemsListArticles: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();
                
                inventoryArticleSearchService.getInventoryArticleSearch().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadInventoryArticles()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadInventoryArticles = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.only_on_stock, true) !== true) return;

                vm.model.itemsListArticles = [];

                return inventoryArticleSearchService.listInventoryArticles(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListArticles);
                });
            };
            
            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';
                        vm.model.itemsListArticles = [];
                        break;
                    default:
                        break;
                }
            };
            
            vm.searchArticles = function () {
                loadInventoryArticles();
            };
            
            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'only_on_stock':
                        if (utilityService.validateWatchValue(value, vm.model.setting.only_on_stock) !== true) return;

                        vm.model.setting.only_on_stock = value;

                        if (utilityService.validateParmsValue(vm.model.setting.only_on_stock, true) !== true) return;

                        loadInventoryArticles();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
