(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('choicePossRules', {
        templateUrl: 'views/components/views/choicePossRules/choicePossRules.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'choicePossRulesService', function ($stateParams, stateService, choicePossRulesService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;

            vm.model = {
                productIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoProduct }
                ],
                modelButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearModel },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoModels }
                ],
                modelButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearModel },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoModels },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoModelMenu }
                ],
                productNameSelectButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.clearProduct() },
                ],
                fabricsButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearFabric },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoFabrics }
                ],
                fabricsButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearFabric },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoFabrics },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoFabricMenu }
                ],
                choosedProductButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearChoosedProduct },
                ],
                choosedProductButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearChoosedProduct },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoChoosedProduct }
                ],
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                getChoicePossRules: {},
                listProductChoice: [],
                listModelSelect: [],
                listFurnitureFabricsSelect: [],
                listChoicePossRulesChoosed: [],
                listChoicePossRules: [],
                listChoicePossRulesProduct: [],
                loading: false
            };

            // ## LOAD PROCEDURE CALLS ##


            // ## REMEMBER VALUE FUNCTIONS ##


            let rememberChoiceProdId = function () {
                let variableNameChoiceProdId = 'w_choicepossrules.choice_prod_id';

                if (vm.model.getChoicePossRules.choice_prod_id && vm.model.getChoicePossRules.choice_prod_id !== '0') {
                    return choicePossRulesService.remember(variableNameChoiceProdId, vm.model.getChoicePossRules.choice_prod_id);
                }
            };

            let rememberModelId = function () {
                let variableNameModelId = 'w_choicepossrules.model_id';

                if (vm.model.getChoicePossRules.model_id && vm.model.getChoicePossRules.model_id !== '0') {
                    return choicePossRulesService.remember(variableNameModelId, vm.model.getChoicePossRules.model_id);
                }
            };

            let rememberProdId = function () {
                let variableNameProdId = 'w_choicepossrules.prod_id';

                if (vm.model.getChoicePossRules.prod_id || vm.model.getChoicePossRules.prod_id === '') {
                    return choicePossRulesService.remember(variableNameProdId, vm.model.getChoicePossRules.prod_id);
                }
            };

            let rememberFurnitureFabricsId = function () {
                let variableNameFurnitureFabricsId = 'w_choicepossrules.furniturefabrics_id';

                if (vm.model.getChoicePossRules.furniturefabrics_id && vm.model.getChoicePossRules.furniturefabrics_id !== '0') {
                    choicePossRulesService.remember(variableNameFurnitureFabricsId, vm.model.getChoicePossRules.furniturefabrics_id);
                }
            };

            let rememberProdIdChoosed = function () {
                let variableNameProdIdChoosed = 'w_choicepossrules.prod_id_choosed';

                if ((vm.model.getChoicePossRules.prod_id_choosed || vm.model.getChoicePossRules.prod_id_choosed === '') && vm.model.getChoicePossRules.prod_id_choosed !== '0') {
                    return choicePossRulesService.remember(variableNameProdIdChoosed, vm.model.getChoicePossRules.prod_id_choosed);
                }
            };

            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##

            vm.typeaheadSearch = function () {
                return choicePossRulesService.search(vm.model.getChoicePossRules.prod_name, stateService.getCurrentName(), '0', '0', '0', '0', '0', '0', vm.model.getChoicePossRules.model_id);
            };

            // ## REFRESH BUTTON FUNCTION ##

            vm.refresh = function () {
                if (vm.model.getChoicePossRules.choice_prod_id && vm.model.getChoicePossRules.choice_prod_id !== '0' && vm.model.getChoicePossRules.model_id && vm.model.getChoicePossRules.model_id !== '0') {
                    vm.model.loading = true;
                    vm.model.listChoicePossRules = [];

                    return choicePossRulesService.loadChoicePossRulesList(vm.model.getChoicePossRules)
                        .then((data) => angular.copy(data, vm.model.listChoicePossRules))
                        .finally(() => vm.model.loading = false);
                }
            };

            // ## CREATE NEW BUTTON FUNCTIONS ##

            vm.createNew = function () {
                stateService.go('choicepossrule', { choicepossrule_keyno: '0' });
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.gotoChoiceRulesRun = function () {
                stateService.go('choicepossrulesrun', {});
            };


            function clearModel() {
                vm.model.getChoicePossRules.model_id = 'ALL';
                rememberModelId();
            }

            function gotoModels() {
                stateService.go('models', {});
            }

            function gotoModelMenu() {
                stateService.go('modelmenu', { model_id: vm.model.getChoicePossRules.model_id });
            }

            function gotoProduct() {
                if (vm.model.getChoicePossRules.prod_id) {
                    choicePossRulesService.loadProdKeynoGet(vm.model.getChoicePossRules.prod_id).then((data) => {
                        if (data?.[0].prod_keyno) stateService.go('product', { prod_id: data[0].prod_keyno });
                    });
                }
            }

            function clearFabric() {
                vm.model.getChoicePossRules.furniturefabrics_id = 'ALL';
                rememberFurnitureFabricsId();

                vm.model.getChoicePossRules.prod_id_choosed = '';
                rememberProdIdChoosed()
            }

            function gotoFabrics() {
                stateService.go('furniturefabricss', {});
            }

            function gotoFabricMenu() {
                stateService.go('furniturefabrics', {
                    furniturefabrics_id: vm.model.getChoicePossRules.furniturefabrics_id
                });
            }

            function clearChoosedProduct() {
                vm.model.getChoicePossRules.prod_id_choosed = '';
                rememberProdIdChoosed();
            }

            function gotoChoosedProduct() {
                if (vm.model.getChoicePossRules.prod_id_choosed?.trim()) {
                    choicePossRulesService.loadProdKeynoGet(vm.model.getChoicePossRules.prod_id_choosed).then((data) => {
                        if (angular.isDefined(data[0].prod_keyno)) {
                            stateService.go('product', { prod_id: data[0].prod_keyno });
                        }
                    });
                }
            }

            vm.onChoiceProductChanged = async function (value) {
                vm.model.getChoicePossRules.choice_prod_id = value;

                if (value) {
                    vm.model.getChoicePossRules.furniturefabrics_id = 'ALL';
                    vm.model.getChoicePossRules.prod_id_choosed = '';

                    loadProdIdChoosed();
                    await loadChoiceProdId();
                    loadSelectList();
                    vm.refresh();
                    rememberChoiceProdId();
                };
            }

            vm.onModelChanged = async function (value) {
                vm.model.getChoicePossRules.model_id = value;

                if (value) {
                    loadModelId();
                    vm.refresh();
                    rememberModelId();
                    vm.clearProduct();
                }
            }

            vm.onProductNameSelected = function (value) {
                vm.model.getChoicePossRules.prod_id = value;

                if (value) {
                    let productItem = vm.model.listChoicePossRulesProduct.find((item) => item?.prod_id === vm.model.getChoicePossRules.prod_id);

                    if (productItem?.prod_name) {
                        vm.model.getChoicePossRules.prod_name = productItem.prod_name;
                        rememberProdId();
                    }
                } else {
                    vm.clearProduct();
                }
            }

            vm.onSearchProductNameSelected = function (item) {
                if (item) {
                    vm.model.getChoicePossRules.prod_id = item?.item_id ?? '';
                    vm.model.getChoicePossRules.prod_name = item?.item_name ?? '';
                    rememberProdId();
                } else {
                    vm.clearProduct();
                }
            }

            vm.onProductNameChanged = function (value) {
                vm.model.getChoicePossRules.prod_name = value;

                if (value) {
                    let productItem = vm.model.listChoicePossRulesProduct.find((item) => item?.prod_name === vm.model.getChoicePossRules.prod_name);

                    if (productItem?.prod_id) {
                        vm.model.getChoicePossRules.prod_id = productItem.prod_id;
                        rememberProdId();
                    }
                } else {
                    vm.clearProduct();
                }
            }

            vm.clearProduct = function () {
                vm.model.getChoicePossRules.prod_name = '';
                vm.model.getChoicePossRules.prod_id = '';
                vm.refresh();
                rememberProdId();
            }

            vm.onChosenProductChanged = function (value) {
                vm.model.getChoicePossRules.prod_id_choosed = value;

                if (value !== undefined) {
                    vm.refresh();
                    rememberProdIdChoosed();
                }
            }

            vm.onFurnitureFabricsChanged = function (value) {
                vm.model.getChoicePossRules.furniturefabrics_id = value;

                if (value) {
                    loadProdIdChoosed();
                    vm.refresh();
                    rememberFurnitureFabricsId();

                    if (vm.model.getChoicePossRules.furniturefabrics_id === 'ALL') {
                        vm.model.getChoicePossRules.prod_id_choosed = '';
                        rememberProdIdChoosed()
                    }
                }
            }

            async function loadChoiceProdId() {
                if (vm.model.getChoicePossRules.choice_prod_id?.trim()) {
                    return vm.model.getChoicePossRules.nbr_of_furniturefabrics = (await choicePossRulesService.loadChoiceProdIdChanged(vm.model.getChoicePossRules.choice_prod_id))[0].nbr_of_furniturefabrics;
                }
            };

            async function loadSelectList() {
                if (vm.model.getChoicePossRules.choice_prod_id && vm.model.getChoicePossRules.nbr_of_furniturefabrics > 0) {
                    return vm.model.listFurnitureFabricsSelect = await choicePossRulesService.loadFurnitureFabricsSelectList('1', vm.model.getChoicePossRules.choice_prod_id);
                }
            };

            async function loadModelId() {
                if (vm.model.getChoicePossRules.model_id?.trim()) {
                    vm.model.getChoicePossRules.nbr_of_products = (await choicePossRulesService.loadModelIdChanged(vm.model.getChoicePossRules))[0].nbr_of_products;
                    return loadProductList();
                }
            };

            async function loadProdIdChoosed() {
                if (vm.model.getChoicePossRules.choice_prod_id && vm.model.getChoicePossRules.furniturefabrics_id) {
                    return vm.model.listChoicePossRulesChoosed = await choicePossRulesService.loadChoicePossRulesChoosedList(vm.model.getChoicePossRules);
                }
            };

            async function loadProductList() {
                if (vm.model.getChoicePossRules.model_id?.trim() && vm.model.getChoicePossRules.nbr_of_products < 200) {
                    return vm.model.listChoicePossRulesProduct = await choicePossRulesService.loadChoicePossRulesProductList(vm.model.getChoicePossRules);
                }
            };

            async function loadProductChoiceList() {
                return vm.model.listProductChoice = await choicePossRulesService.loadProductChoiceList();
            }

            async function loadModelSelectList() {
                return vm.model.listModelSelect = await choicePossRulesService.loadModelSelectList('1', '0');
            }

            vm.$onInit = async function () {
                loadProductChoiceList();
                loadModelSelectList();
                vm.model.getChoicePossRules = (await choicePossRulesService.loadChoicePossRulesGet(vm.model.choicePossRuleKeyno))[0];

                await Promise.all([
                    loadChoiceProdId(),
                    loadProdIdChoosed(),
                    loadModelId(),
                ]);

                loadSelectList();
                vm.refresh();
            }
        }]
    });
})();
