(function () {
    'use strict';
 
    angular.module("imApp")
        .factory("headerHelpService", [
            '$rootScope', '$ihttp', '$q', function ($rootScope, $ihttp, $q) {
                var service = {
                    dataList: {},

                    getHelpText: function (helpId) {
                        // TODO BJS20160218 - Dersom denne skal brukes m� den skrives om til � bruke $ihttp
                        //if(angular.isDefined(service.dataList[helpId])) {
                        //   return $q.when({items: service.dataList[helpId] });
                        //}

                        var deferred = $q.defer();

                        //// parms sp_ws_p2_help
                        //var parms = angular.toJson({  
                        //    labour_no: 
                        //    pwd: 
                        //    ip_address: '',//$scope.cl_edit_json.ip_address,
                        //    word_id: helpId
                        //});

                                  
                        //// webservice sp_ws_p2_help
                        //$http.post('http://'+l_s_companydbip+'/sp_ws_p2_help/', parms).success(function(data) {
                        //    //$scope.cl_edit = angular.copy(data[0]);
                        //    angular.copy(data[0], service.dataList);

                        //    service.dataList[helpId] = data[0];

                        //    deferred.resolve({items: service.dataList[helpId]});
                        //}).catch(function (error) {
                        //    deferred.reject();
                        //});

                        return deferred.promise;
                    }
                }
 
                return service;        
            }
        ]);
})();