(function() {
    'use strict';

    angular.module("imApp").factory("genericTerminalScanAService", ['$ihttp', '$q', 'base64', function($ihttp, $q, base64) {
        var service = {
            init: function (route) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 2360,
                    parameters: {
                        type: 'init',
                        route: route
                    }
                }).then(function (response) {
                    var jsondata = base64.urldecode(response);

                    var terminal = angular.fromJson(jsondata);

                    deferred.resolve(terminal);
                });

                return deferred.promise;
            },

            list: function (route, value, sessiondata) {
                var deferred = $q.defer();

                sessiondata = sessiondata || '';
                sessiondata = sessiondata.length > 0 ? base64.urlencode(sessiondata) : '';

                $ihttp.post({
                    method: 2360,
                    parameters: {
                        route: route,
                        sessiondata: sessiondata,
                        type: 'list',
                        value: value.item_id
                    }
                }).then(function (response) {
                    var jsondata = base64.urldecode(response);

                    var terminal = angular.fromJson(jsondata);

                    deferred.resolve(terminal);
                });

                return deferred.promise;
            },

            select: function (route, type, value, sessiondata) {
                var deferred = $q.defer();

                sessiondata = sessiondata || '';
                sessiondata = sessiondata.length > 0 ? base64.urlencode(sessiondata) : '';

                var parameters = {
                    type: type,
                    route: route,
                    sessiondata: sessiondata
                };

                parameters[type] = value;

                $ihttp.post({
                    method: 2360,
                    parameters: parameters
                }).then(function (response) {
                    var jsondata = base64.urldecode(response);

                    var terminal = angular.fromJson(jsondata);

                    deferred.resolve(terminal);
                });

                return deferred.promise;
            },

            scan: function (route, scandata, number, ttNumber, sessiondata) {
                var deferred = $q.defer();

                scandata = scandata || '';
                // La til nummer til scan - 20230727 EO
                number = number || 0;
                ttNumber = ttNumber || 0;
                sessiondata = sessiondata || '';

                scandata = scandata.length > 0 ? base64.urlencode(scandata) : '';
                sessiondata = sessiondata.length > 0 ? base64.urlencode(sessiondata) : '';

                $ihttp.post({
                    method: 2360,
                    parameters: {
                        type: 'scan',
                        route: route,
                        scandata: scandata,
                        number: number,
                        ttNumber: ttNumber,
                        sessiondata: sessiondata
                    }
                }).then(function (response) {
                    var jsondata = base64.urldecode(response);

                    var terminal = angular.fromJson(jsondata);

                    deferred.resolve(terminal);
                });

                return deferred.promise;
            },

            number: function (route, type, number, sessiondata) {
                var deferred = $q.defer();

                number = number || 0;
                console.log(number);
                sessiondata = sessiondata || '';

                sessiondata = sessiondata.length > 0 ? base64.urlencode(sessiondata) : '';

                $ihttp.post({
                    method: 2360,
                    parameters: {
                        route: route,
                        type: type,
                        number: number,
                        sessiondata: sessiondata
                    }
                }).then(function (response) {
                    var jsondata = base64.urldecode(response);

                    var terminal = angular.fromJson(jsondata);

                    deferred.resolve(terminal);
                });

                return deferred.promise;
            },


            button: function (route, type, scandata, number, selectA, selectB, sessiondata) {
                var deferred = $q.defer();

                scandata = scandata || '';
                number = number || 0;
                selectA = selectA || '';
                selectB = selectB || '';
                sessiondata = sessiondata || '';

                scandata = scandata.length > 0 ? base64.urlencode(scandata) : '';
                sessiondata = sessiondata.length > 0 ? base64.urlencode(sessiondata) : '';

                // for legacy support button type a is called only "button".
                var id = type.toUpperCase() === 'A'
                    ? 'button'
                    : 'button' + type.toUpperCase();

                $ihttp.post({
                    method: 2360,
                    parameters: {
                        type: id,
                        route: route,
                        scandata: scandata,
                        number: number,
                        selectA: selectA,
                        selectB: selectB,
                        sessiondata: sessiondata
                    }
                }).then(function (response) {
                    var jsondata = base64.urldecode(response);

                    var terminal = angular.fromJson(jsondata);

                    deferred.resolve(terminal);
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
