(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('timeCustomersWo', {
        templateUrl: 'views/components/views/timeCustomersWo/timeCustomersWo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'timeCustomersWoService', function ($stateParams, timeCustomersWoService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custNo = $stateParams.cust_no;

            vm.model = {
                itemsListTimeCustomersWo: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadTimeCustomersWos = function () {
                timeCustomersWoService.listTimeCustomersWos({
                    cust_name: 'ALL',
                    cust_no: custNo,
                    project_keyno: '0',
                    ok: '0'
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListTimeCustomersWo);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTimeCustomersWos();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
