(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("stockHistoryService", ['$ihttp', function ($ihttp) {
		let service = {
            getStockHistory: function (parms) {
                return $ihttp.post({
                    method: 1420,
                    parameters: parms || {}
                });
            },
            listStockHistories: function (parms) {
                return $ihttp.post({
                    method: 1419,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
