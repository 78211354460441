(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('interBranchMultiGet', {
        templateUrl: 'views/components/views/interBranchMultiGet/interBranchMultiGet.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'interBranchMultiGetService', function($stateParams, interBranchMultiGetService) {
            var vm = this;
            
            vm.model = {
                p2interBranchMultiGet: {},
                p2InterBranchLevelList: {
                    records: []
                }
            };
            
            var loadP2interBranchMultiGet = function () {
                interBranchMultiGetService.genericFunction(790, {
                    interbranchmulti_keyno: $stateParams.interbranchmulti_keyno
                }).then(function (data) {
                    vm.model.p2interBranchMultiGet = angular.copy(data[0]);
                });
            };
            
        	loadP2interBranchMultiGet();
            
            var loadP2interBranchLevelList = function () {
                interBranchMultiGetService.genericFunction(791, {
                    interbranchmulti_keyno: $stateParams.interbranchmulti_keyno
                }).then(function (data) {
                    angular.copy(data, vm.model.p2InterBranchLevelList.records);
                });
            };
            
            loadP2interBranchLevelList();
        }]
    });
})();