(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poFinishProdListService", ['$q', '$ihttp', '$stateParams', 'stateService', function ($q, $ihttp, $stateParams, stateService) {
        var service = {
            loaded: false,
            p2_pofinishprod_list: {
                item_glyphicon: '',
                item_id: '',
                item_name: '',
                item_name_sub1: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            p2_pofinish_status: {
                readytofinish: ''
            },
            p2_pofinish_finish: {
                webpagename: '',
                webpage_parms: ''
            },
            loadP2PoFinishProdList: function (p2_pofinish_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinishprod_list = {
                    method: 181,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinishprod_list).then(function (data) {
                    service.p2_pofinishprod_list.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_pofinishprod_list.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2PoFinishStatus: function (p2_pofinish_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinish_status = {
                    method: 211,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinish_status).then(function (data) {
                    service.p2_pofinish_status = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            scanP2PoFinishProdAdd: function (p2_pofinish_keyno, barcode) {
                var deferred = $q.defer();

                var parms_p2_pofinishprod_add = {
                    method: 188,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno,
                        barcode: barcode
                    }
                };

                $ihttp.post(parms_p2_pofinishprod_add).then(function (data) {
                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            finishP2PoFinish: function () {
                var deferred = $q.defer();

                var parms_p2_pofinish_finish = {
                    method: 197,
                    parameters: {
                        finishfromkey: 'p2_pofinish_keyno',
                        keyvalue: $stateParams.p2_pofinish_keyno,
                        from_webpagename: stateService.getCurrentName(),
                        from_parms: $stateParams.p2_pofinish_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinish_finish).then(function (data) {
                    service.p2_pofinish_finish = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            abortPoFinish: function (parms) {
                return $ihttp.post({
                    method: 212,
                    parameters: parms
                });
            }
        };

        return service;
    }]);
})();