(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentOlService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2ConsignmentOlGet: {},
            loadP2ConsignmentOlGet: function (consignmentol_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentOlGet = {
                    method: 328,
                    parameters: {
                        consignmentol_keyno: consignmentol_keyno
                    }
                };

                $ihttp.post(parmsP2ConsignmentOlGet).then(function (data) {
                    service.p2ConsignmentOlGet = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();