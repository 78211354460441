(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentBatches', {
        templateUrl: 'views/components/views/consignmentBatches/consignmentBatches.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'consignmentBatchesService', 'searchService', 'utilityService', 'cargonizerApiService', function ($timeout, consignmentBatchesService, searchService, utilityService, cargonizerApiService) {
            
            // ##############################
            // GLOBAL VARIABLES & DEFINITIONS
            // ##############################

            var vm = this;
            vm.gbShowStatusDefault = '0';

            if (angular.isDefined(searchService.consignmentbatches.gbShowStatusDefault) && (searchService.consignmentbatches.gbShowStatusDefault > 0)){
                vm.gbShowStatusDefault = searchService.consignmentbatches.gbShowStatusDefault;
            }
            
            vm.model = {
                gbShowStatus: {
                    item_id: '',
                    item_name: '',
                    item_default: vm.gbShowStatusDefault,
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'consignmentbatches_show_open', item_func: 'SHOWOPEN' },
                        { item_id: '1', item_name: 'consignmentbatches_close_open ', item_func: 'CLOSEOPEN' },
                        { item_id: '2', item_name: 'consignmentbatches_closed', item_func: 'CLOSED' }
                    ]
                },
                p2ConsignmentBatchList: {
                    records: []
                },
                locked: '0',
                unlock_timer: null
            };
            
            vm.gbShowStatusSelect = function (item) {
                if (utilityService.validateWatchValue(item, vm.model.gbShowStatus.item_selected) !== true) return;

                vm.model.gbShowStatus.item_selected = item;
                searchService.consignmentbatches.gbShowStatusDefault = utilityService.groupButtonCurrentId(vm.model.gbShowStatus);

                if (angular.isDefined(vm.model.gbShowStatus)) {
                    consignmentBatchesService.loadP2ConsignmentBatchList(vm.model.gbShowStatus.item_selected).then(function () {
                        vm.model.p2ConsignmentBatchList = consignmentBatchesService.p2ConsignmentBatchList;
                    });
                }
            };
    
            // #########################
            // CLOSE BUTTON FUNCTION
            // #########################
    
            vm.closeConsignmentBatches = function (index) {
                vm.model.locked = '1';

                angular.forEach(vm.model.p2ConsignmentBatchList.records, function (item) {
                    if (item.item_is_selected != '0') {
                        cargonizerApiService.transferCargonizerConsignment(item.item_id).then(function () {
                            if (vm.model.unlock_timer != null) {
                                $timeout.cancel(vm.model.unlock_timer);
                            }

                            vm.model.unlock_timer = $timeout(function () {
                                vm.model.locked = '0';

                                consignmentBatchesService.loadP2ConsignmentBatchList(vm.model.gbShowStatus.item_selected).then(function () {
                                    vm.model.p2ConsignmentBatchList = consignmentBatchesService.p2ConsignmentBatchList;
                                });
                            }, 500);
                        });
                    }
                });
            };

            // ###################
            // LOAD PROCEDURE CALL
            // ###################
        }]
    });
})();
