(function () {
    'use strict';

    angular.module("imApp").factory("eventService", ['$rootScope', 'utilityService', function ($rootScope, us) {
        var events = {};

        var service = {
            on: function (id, handler) {
                if (angular.isString(id) !== true) {
                    console.log('Error: invalid event name in eventService.on');
                    console.dir(id);
                    return;
                }

                if (angular.isFunction(handler) !== true) {
                    console.log('Error: handler is not a function in eventService.on');
                    console.dir(handler);
                    return;
                }

                if (angular.isUndefined(events[id])) {
                    events[id] = {
                        id: id,
                        handlers: {}
                    };
                }

                var handlerId = uuid();

                events[id].handlers[handlerId] = handler;

                return function () {
                    delete events[id].handlers[handlerId];
                }
            },

            trigger: function (id, parms, doBroadcast) {
                if (angular.isUndefined(events[id])) {
                    return;
                }

                angular.forEach(events[id].handlers, function (handler) {
                    if (angular.isFunction(handler)) {
                        handler(parms);
                    }
                });

                if (us.toBoolean(doBroadcast, false)) {
                    service.broadcast(id, parms);
                }
            },

            broadcast: function (id, parms) {
                $rootScope.$broadcast(id, parms);
            },

            destroy: function (onDestroy) {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            }
        };

        return service;
    }]);
})();
