import { Injectable } from '@angular/core';
import { DataTaskService } from '../core/services/data-task.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

    constructor(private datatask: DataTaskService) { }

    /**
     * Returns a promise containg a list of available TouchTime themes.
     * 
     * @returns a promise containing a list of available TouchTime themes.
     */
    public getThemes(): Promise<TouchTimeTheme[]> {
        return this.datatask.Post(3047);
    }
}

export interface TouchTimeTheme {
    theme_name: string;
    theme_keyno: string;
    system_name: string;
    color_scheme: string;
}
