(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory4Lang', {
        templateUrl: 'views/components/views/textileModelCategory4Lang/textileModelCategory4Lang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'textileModelCategory4LangService', function ($stateParams, stateService, textileModelCategory4LangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var textileModelCategory4Keyno = $stateParams.textilemodelcategory4_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory4LangService.listTextileModelCategory4Languages({ textilemodelcategory4_keyno: textileModelCategory4Keyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                textileModelCategory4LangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();