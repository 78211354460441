(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttInputButtons', {
        //templateUrl: 'views/components/directives/ttInputButtons/ttInputButtons.template.html?v=' + module.version,
        template: `
    <div class="tt-input__container col-xs-12 sp-0" tt-item="{{vm.ttItemId}}" when-ready="vm.whenReady()">
      <div class="tt-input__base col-xs-12 sp-0" ng-class="vm.class.base" ng-style="vm.style.base">
        <div class="col-xs-12 sp-0" ng-if="vm.showSearch === true" style="margin-bottom: 7px;">
          <div class="col-xs-12 sp-0" ng-style="vm.style.group">
            <label class="tt-input__label" ng-class="vm.class.label" ng-style="vm.style.label">{{vm.translations.batch.stat_search}}</label>
            
            <div class="tt-input__group" ng-class="vm.class.input">
              <input class="tt-input__form-control" name="{{vm.id.searchInput}}" id="{{vm.id.searchInput}}" type="text" 
                ng-model="vm.ttSearch" ng-change="vm.onSearchChanged(vm.ttSearch)" ng-blur="vm.ttOnBlur({ $model: vm.ttModel })" 
                ng-style="vm.style.input" uib-typeahead="item.item_name for item in vm.onSearch($viewValue)" 
                typeahead-on-select="vm.onSearchSelected($item, $model, $label, $event)" typeahead-min-length="3" typeahead-wait-ms="50" />
              
              <button tabindex="-1" class="tt-input__button tt-input__button--danger" ng-click="vm.clearInput($event)" 
                ng-style="vm.input.style.groupButton">
                <span class="fa-fw fas fa-eraser" ng-style="vm.style.icon"></span>
              </button>
              <button tabindex="-1" class="tt-input__button tt-input__button--success" ng-click="vm.manualSearch($event)" 
                ng-style="vm.input.style.groupButton">
                <span class="fa-fw fas fa-search" ng-style="vm.style.icon"></span>
              </button>
            </div>
          </div>

          <button class="col-xs-12 sp-0 tt-input__button tt-input__button--primary" style="text-align: center; padding: 0 5px;" 
            ng-style="vm.style.button" ng-click="vm.cancel($event)">
            <span ng-style="vm.style.buttonText">{{vm.translations.batch.cancel_search}}</span>
            <span class="fa fa-undo" ng-style="vm.style.buttonText" style="padding-left: 10px;"></span>
          </button>
        </div>

        <label for="{{vm.id.input}}" class="tt-input__label" ng-class="vm.class.label" ng-style="vm.style.label">
          {{vm.translations.ttLabel}}
          <span ng-if="vm.required === true" class="tt-input__label--required" ng-style="vm.style.label">*</span>
        </label>

        <label ng-if="vm.ttSublabel && vm.ttSublabel.length > 0" class="tt-input__sublabel" ng-class="vm.class.sublabel" 
          ng-style="vm.style.sublabel" when-ready="whenReady()" wait-for-interpolation="true">{{vm.ttSublabel}}</label>
        
        <div class="tt-input__group" ng-class="{ 'tt-input__group--readonly': vm.ttReadonly == true, 'tt-input__group--disabled': vm.ttDisabled == true }">
          <input class="tt-input__form-control" name="{{vm.id.input}}" id="{{vm.id.input}}" placeholder="{{vm.translations.ttPlaceholder}}" 
            type="text" ng-model="vm.ttModel" ng-change="vm.onModelChanged(vm.ttModel)" ng-style="vm.style.input" 
            ng-blur="vm.ttOnBlur({ $model: vm.ttModel })" ng-readonly="vm.ttReadonly" />
          
          <button id="{{vm.ttItemId + btn.id}}" tabindex="-1" type="button" class="tt-input__button" ng-class="btn.classes" 
            ng-repeat="btn in vm.ttButtons" ng-click="vm.onClick(btn, $event)" ng-style="vm.style.groupButton" ng-if="btn.show">
            <span ng-if="btn.showIcon" class="fa-fw" ng-class="btn.icon" ng-style="vm.style.icon"></span>
            <span ng-if="btn.showText" ng-style="vm.style.iconButtonText">{{btn.text}}</span>
          </button>
        </div>
      </div>
    </div>
  `,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttSublabel: '@',
            ttHideLabel: '@',
            ttReadonly: '<',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttTextAlign: '@',
            ttOnBlur: '&',
            ttFocus: '<',
            ttPlaceholder: '@',
            //ttBindFocus: '@',
            ttPhone: '@',           // RHE 20231027 if true, adds 'phone'-button to the buttons
            ttEmail: '@',           // RHE 20231027 if true, adds 'email'-button to the buttons
            ttButtons: '<',
            ttButtonsParm: '<',
            ttSearch: '<',
            ttSearchChange: '&',
            ttSearchChangeArgs: '<',
            ttOnSearchSelected: '&',
            ttItemId: '@',
            ttRequired: '@',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttStyle: '<',           // BJS 20220810
            ttOptions: '<',         // JLR 20231009 configurationobject, takes all other component properteties as properties, supports both camelCase and snake_case notation with or without tt. Object needs to be reinstantiated to reflect changes in its properties.
        },
        controller: ['$q', '$element', '$timeout', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($q, $element, $timeout, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.ttReadonly = vm.ttReadonly || true;

            vm.onSearch = noop();

            vm.hideLabel = false;
            vm.showEmail = false;
            vm.showPhone = false;
            vm.showSearch = false;
            vm.required = false;

            vm.style = {
                base: {},
                group: {},
                groupButton: {
                    //paddingTop: '0',      //changed from 4px
                    //paddingBottom: '0',
                    //paddingLeft: '12px',
                    //paddingRight: '12px'
                },
                label: {},
                input: {},
                button: {},
                buttonText: {
                    //color: 'white'
                },
                iconButtonText: {
                    //color: 'white',
                    fontWeight: 'bold',
                    marginLeft: '3px'
                },
                icon: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                input: ''
            };

            vm.id = {
                searchInput: uuid(),
                input: uuid()
            };

            vm.translations = {
                batch: {
                    stat_search: '',
                    cancel_search: ''
                },
                ttLabel: '',
                ttPlaceholder: '',
            };

            // JLR 20230622
            let setClasses = (labelAlwaysOnTop) => {
                vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
            };

            // JLR 20230622
            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            vm.onSearchChanged = function (value) {
                if (angular.isFunction(vm.ttSearchChange)) {
                    vm.ttSearchChange({ $value: value, $searchId: us.getSearchId($element), $args: vm.ttSearchChangeArgs });
                }
            };

            vm.onSearchSelected = function (item, model, label, event) {
                vm.ttSearch = label;

                vm.onModelChanged(label);

                if (angular.isFunction(vm.ttOnSearchSelected)) {
                    vm.ttOnSearchSelected({ $item: item, $model: model, $label: label, $event: event });
                }
            };
            
            vm.onClick = (btn, event) => ttDirectivesService.onButtonClick({ button: btn, parameter: vm.ttButtonsParm, controller: vm, event: event });

            //var inputElement;
            //var stopFocusBinding;

            //var setFocus = function () {
            //    if (angular.isUndefined(inputElement)) {
            //        inputElement = $element.find('#' + vm.id.input)[0];
            //    }

            //    if (angular.isDefined(inputElement)) {
            //        $timeout(function () {
            //            inputElement.focus();
            //        }, 0);
            //    }
            //};

            //var bindFocus = function () {
            //    // return if focus already bound
            //    if (angular.isDefined(stopFocusBinding)) return;

            //    stopFocusBinding = $interval(setFocus, 1000);

            //    $element.focus();
            //};

            //var unbindFocus = function () {
            //    if (angular.isUndefined(stopFocusBinding))
            //        return;

            //    $interval.cancel(stopFocusBinding);

            //    stopFocusBinding = undefined;
            //};

            vm.$onInit = function () {
                setStyle(vm.ttStyle);

                if (vm?.ttFocus === true) {
                    $timeout(function () {
                        let elem = $element.find('#' + vm.id.input);

                        if (elem) elem.focus();
                    }, 0);
                }
            }

            vm.$onChanges = function (changes) {
                if (changes.ttOptions?.currentValue) {
                    ttDirectivesService.setOptions(vm, changes);
                }

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
                    vm.style.input.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttPlaceholder) && us.isStringValue(changes.ttPlaceholder.currentValue) && changes.ttPlaceholder.currentValue !== changes.ttPlaceholder.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttPlaceholder = changes.ttPlaceholder.currentValue;
                    } else {
                        translateService.translate(changes.ttPlaceholder.currentValue).then((translation) => vm.translations.ttPlaceholder = translation);
                    }
                }

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttEmail)) {
                    vm.showEmail = us.toBoolean(changes.ttEmail.currentValue);

                    if (vm.showEmail === true) {
                        let containsEmail = false;
                        vm.ttButtons ??= [];

                        for (let i = vm.ttButtons.length - 1; i >= 0; i--) {
                            let btn = vm.ttButtons[i];
                            if (btn.id === '$email') {
                                containsEmail = true;
                                break;
                            }
                        }

                        if (!containsEmail) {
                            vm.ttButtons.push({ id: '$email', icon: 'far fa-envelope', color: 'primary', type: 'primary', onClick: () => { window.open('mailto:' + vm.ttModel, '_self'); return; } });

                            let btn = vm.ttButtons[vm.ttButtons.length - 1];

                            if (angular.isDefined(btn.color) && btn.color.length > 0) {
                                btn.classes = btn.color.startsWith('btn-') ? btn.color : 'btn-' + btn.color;
                            }

                            btn.show = us.toBoolean(btn.show, true);
                            btn.showIcon = btn.show === true && angular.isDefined(btn.icon) && angular.isString(btn.icon) && btn.icon !== null && btn.icon.trim().length > 0;
                            btn.showText = btn.show === true && angular.isDefined(btn.text) && angular.isString(btn.text) && btn.text !== null && btn.text.trim().length > 0;
                        }
                    }
                }

                if (angular.isDefined(changes.ttPhone)) {
                    vm.showPhone = us.toBoolean(changes.ttPhone.currentValue);

                    if (vm.showPhone === true) {
                        let containsPhone = false;
                        vm.ttButtons ??= [];

                        for (let i = vm.ttButtons.length - 1; i >= 0; i--) {
                            let btn = vm.ttButtons[i];
                            if (btn.id === '$phone') {
                                containsPhone = true;
                                break;
                            }
                        }

                        if (!containsPhone) {
                            vm.ttButtons.push({ id: '$phone', icon: 'fas fa-mobile-alt', color: 'primary', type: 'primary', onClick: () => { window.open('tel:' + vm.ttModel, '_self'); return; } });

                            let btn = vm.ttButtons[vm.ttButtons.length - 1];

                            if (angular.isDefined(btn.color) && btn.color.length > 0) {
                                btn.classes = btn.color.startsWith('btn-') ? btn.color : 'btn-' + btn.color;
                            }

                            btn.show = us.toBoolean(btn.show, true);
                            btn.showIcon = btn.show === true && angular.isDefined(btn.icon) && angular.isString(btn.icon) && btn.icon !== null && btn.icon.trim().length > 0;
                            btn.showText = btn.show === true && angular.isDefined(btn.text) && angular.isString(btn.text) && btn.text !== null && btn.text.trim().length > 0;
                        }
                    }
                }

                if (angular.isDefined(changes.ttButtons) && angular.isArray(changes.ttButtons.currentValue)) {
                    for (var i = 0; i < changes.ttButtons.currentValue.length; i++) {
                        var btn = changes.ttButtons.currentValue[i];

                        if (btn.id === '$search') {
                            btn.onClick = vm.onShowSearch;

                            var onSearch = btn.onSearch;

                            if (angular.isFunction(onSearch)) {
                                vm.onSearch = function (value) {
                                    var deferred = $q.defer();

                                    onSearch(value).then(function (searchResponse) {
                                        deferred.resolve(searchResponse);
                                    });

                                    return deferred.promise;
                                };
                            }
                        } else if (btn.id === '$email' && !vm.showEmail) {
                            btn.icon ??= 'far fa-envelope';
                            btn.color ??= 'primary';
                            btn.type ??= 'primary';
                            btn.onClick ??= () => { window.open('mailto:' + vm.ttModel, '_self'); return; };
                        } else if (btn.id === '$phone' && !vm.showPhone) {
                            btn.icon ??= 'fas fa-mobile-alt';
                            btn.color ??= 'primary';
                            btn.type ??= 'primary';
                            btn.onClick ??= () => { window.open('tel:' + vm.ttModel, '_self'); return; };
                        }

                        if (angular.isDefined(btn.color) && btn.color.length > 0) {
                            if (btn.color.startsWith('btn-')) {
                                btn.classes = 'tt-input__button--' + btn.color.split('-')[1];
                            } else {
                                btn.classes = 'tt-input__button--' + btn.color;

                            }
                            //btn.classes = btn.color.startsWith('btn-') ? btn.color : 'tt-input__button--' + btn.color;
                        } else {
                            btn.classes = 'tt-input__button--primary';
                        }

                        btn.show = us.toBoolean(btn.show, true);
                        btn.showIcon = btn.show === true && angular.isDefined(btn.icon) && angular.isString(btn.icon) && btn.icon !== null && btn.icon.trim().length > 0;
                        btn.showText = btn.show === true && angular.isDefined(btn.text) && angular.isString(btn.text) && btn.text !== null && btn.text.trim().length > 0;
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (changes?.ttFocus === true) {
                    $timeout(function () {
                        let elem = $element.find('#' + vm.id.input);

                        if (elem) elem.focus();
                    }, 0);
                }
            };

            var deregBatchTranslations = translateService.on(vm.translations.batch, 'changed', function (translations) {
                angular.copy(translations, vm.translations.batch);

                deregBatchTranslations();
            });

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);

                //vm.style.label.fontSize = info.fontSizes.textSizeS;
                //vm.style.input.fontSize = info.fontSizes.textSize;
                //vm.style.group.height = info.height + 'px';
                //vm.style.input.height = info.height + 'px';
                //vm.style.input.paddingTop = info.padding.top + 'px';
                //vm.style.input.paddingBottom = info.padding.bottom + 'px';
                //vm.style.input.paddingLeft = info.padding.left + 'px';
                //vm.style.input.paddingRight = info.padding.right + 'px';
                //vm.style.icon.fontSize = info.fontSizes.textSize;
                //vm.style.buttonText.fontSize = info.fontSizes.textSize;
                //vm.style.iconButtonText.fontSize = info.fontSizes.textSizeL;
                //vm.style.button.fontSize = info.fontSizes.textSize;
                //vm.style.button.height = info.height + 'px';

                // JLR 20230622
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            var searchValue;

            vm.manualSearch = function (timeout) {
                if (angular.isUndefined(timeout) || timeout === true) {
                    $timeout(vm.manualSearch, 0, true, false);
                }

                var ctrl = $element.find('#' + vm.id.searchInput).controller('ngModel');

                searchValue = vm.ttSearch;

                ctrl.$setViewValue(' ');

                vm.ttSearch = searchValue;

                ctrl.$setViewValue(vm.ttSearch);
            };

            vm.setFocus = function () {
                var element = $element.find('#' + vm.id.searchInput)[0];

                if (angular.isUndefined(element))
                    return;

                element.focus();
            };

            vm.clearInput = function () {
                vm.ttSearch = '';

                vm.setFocus();
            };

            vm.onShowSearch = function () {
                vm.showSearch = true;

                vm.setFocus();

                eventService.trigger('element:ready');
            };

            vm.cancel = function () {
                vm.showSearch = false;
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
