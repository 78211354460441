import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tt-context-menu-report',
  templateUrl: './context-menu-report.component.html',
  styleUrls: ['./context-menu-report.component.css']
})
export class ContextMenuReportComponent {
    @Input() item: any; // Pass the item to edit
    @Input() position: { x: number; y: number; } | undefined; // Position for the menu
    @Output() close = new EventEmitter();
    @Output() update = new EventEmitter<any>();

    updateStyle() {
        this.update.emit(this.item);
        this.close.emit(); // Close the menu after updating
    }

    getFontSize(): string {
        return this.item.fontsize ? this.item.fontsize.replace('px', '') : '';
    }

    // Handle input change
    onFontSizeInput(event: Event) {
        const target = event.target as HTMLInputElement; // Type assertion
        const value = target.value;
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue)) {
            this.item.fontsize = `${numericValue}px`; // Append 'px' for returning
        } else {
            this.item.fontsize = ''; // Clear the value if input is not a number
        }
    }


    // Increase font size
    increaseFontSize() {
        const currentSize = parseInt(this.getFontSize(), 10);
        if (!isNaN(currentSize)) {
            this.item.fontsize = `${currentSize + 1}px`;
            this.updateStyle();
        }
    }

    // Decrease font size
    decreaseFontSize() {
        const currentSize = parseInt(this.getFontSize(), 10);
        if (!isNaN(currentSize) && currentSize > 0) {
            this.item.fontsize = `${currentSize - 1}px`;
            this.updateStyle();
        }
    }

    toggleBold() {
        this.item.bold = this.item.bold === '1' ? '0' : '1'; // Toggle between 0 and 1
        this.updateStyle();
    }

    toggleItalic() {
        this.item.italic = this.item.italic === '1' ? '0' : '1'; // Toggle between 0 and 1
        this.updateStyle();
    }


}
