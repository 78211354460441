(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('interbranchInvoicelineMultiApproval', {
        templateUrl: 'views/components/views/interbranchInvoicelineMultiApproval/interbranchInvoicelineMultiApproval.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['interbranchInvoicelineMultiApprovalService', '$q', '$stateParams', '$uibModal', 'ieScreenBlockService', 'logisticService', 'rememberService', 'translateService', 'modalService', function (interbranchInvoicelineMultiApprovalService, $q, $stateParams, $uibModal, ieScreenBlockService, logisticService, rememberService, translateService, modalService) {
            var vm = this;

            vm.gridReady = false;

            vm.model = {};
            vm.model.edit = {};
            vm.model.edit.shipmentContainerKeyno = 0;
            vm.model.edit.approved = false;
            vm.model.edit.accepted = false;

            vm.model.selectListShipmentContainer = [];
            

            var translations = {
                from_business: '',
                to_business: ''
            };


            var rememberIdGrid = 'w_interbranchInvoicelineMultiApprovalGrid';
            var rememberIdModelEdit = 'w_interbranchInvoicelineMultiApprovalModelEdit';

            vm.arg = {
                type: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                value: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
            };

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 3256,
                        parameters: function () {
                            return {
                                argtype: vm.arg.type,
                                argvalue: vm.arg.value,
                                approved: vm.model.edit.approved,
                                accepted: vm.model.edit.accepted,
                                shipmentcontainer_keyno: vm.model.edit.shipmentContainerKeyno
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    //serverSideHandling: true
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            var remember = function () {
                rememberService.remember(rememberIdModelEdit, vm.model.edit);

                vm.gridOptions.gridfunc.read();
            };


            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });

                
            });


            var loadSelectListShipmentContainer = function () {
                interbranchInvoicelineMultiApprovalService.listShipmentContainer(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.selectListShipmentContainer);
                });
            }


            var load = function () {

                var deferred = $q.defer();

                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdModelEdit, true));
                
                $q.all(promises).then(function (response) {
                    vm.model.edit.approved = response[0]?.approved || '0';
                    vm.model.edit.accepted = response[0]?.accepted || '0';
                    vm.model.edit.shipmentContainerKeyno = response[0]?.shipmentContainerKeyno || '0';
                    loadSelectListShipmentContainer();
                    vm.gridReady = true;
                });

                return deferred.promise;
            };


            vm.$onInit = function () {
                load();
            };


            vm.onChangeShipmentContainer = function (shipmentcontainer_keyno) {
                vm.model.edit.shipmentContainerKeyno = shipmentcontainer_keyno;
                remember();
                vm.gridOptions.gridfunc.rebind();
            };

            vm.onChangeApproved = function (approved) {
                vm.model.edit.approved = approved;
                remember();
                loadSelectListShipmentContainer();
                vm.gridOptions.gridfunc.rebind();
            };

            vm.onChangeAccepted = function (accepted) {
                vm.model.edit.accepted = accepted;
                remember();
                loadSelectListShipmentContainer();
                vm.gridOptions.gridfunc.rebind();
            };
            
            

        }]
    });
})();
