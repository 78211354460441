(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('holiday', {
        templateUrl: 'views/components/views/holiday/holiday.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'holidayService', 'logisticService', 'utilityService', function ($stateParams, stateService, holidayService, logisticService, utilityService) {
            let vm = this;
            
            vm.model = {
                country_id: $stateParams.country_id,
                edit: {},
                selectListCountries: [],
                lockedSave: false,
                lockedDelete: false
            };

            holidayService.getHoliday(utilityService.returnObjectValueOr($stateParams.p2_holyday_keyno, 0), $stateParams.country_id).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
            });

            logisticService.listCountries().then(function (result) {
                angular.copy(result, vm.model.selectListCountries);
            });

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var d = {};
                var keys = Object.keys(vm.model.edit);

                angular.forEach(keys, function (key) {
                    d[key] = vm.model.edit[key];
                });

                d['systemdate'] = d['systemdate'].substring(0, 10) + ' 00:00:00.000';

                holidayService.saveHoliday(d).then(function (data) {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };
            
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                holidayService.deleteHoliday(vm.model.edit.p2_holyday_keyno).then(function (data) {
                    vm.model.lockedDelete = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };
        }]
    });
})();
