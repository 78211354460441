<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="datataskTest()" style="margin: 10px;">Datatask Test</button>

<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="modalTranslateTest()" style="margin: 10px;">Modal Translate Test</button>

<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="modalTest()" style="margin: 10px;">Modal Test</button>

<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="go()" style="margin: 10px;">stateService.go test</button>

<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="htmlToPdfTest()" style="margin: 10px;">Html to Pdf Test</button>

<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="htmlToPdfTestBase64()" style="margin: 10px;">Html to Pdf Test - Base64</button>

<button class="col-xs-12 col-sm-4 sp-0 btn btn-primary" (click)="rememberTest()" style="margin: 10px;">RememberService - Test</button>

<div class="col-xs-12 sp-0"></div>

<textarea style="margin-left: 10px" class="col-xs-12 col-sm-4 sp-0" [(ngModel)]="htmlToPdfValue"></textarea>
<textarea style="margin-left: 20px" class="col-xs-12 col-sm-4 sp-0" [(ngModel)]="htmlToPdfData"></textarea>
