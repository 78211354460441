(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderpickStatusStl', {
        templateUrl: 'views/components/views/orderpickStatusStl/orderpickStatusStl.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<'
        },
        controller: ['rememberService', 'orderpickStatusStlService', function (rememberService, orderpickStatusStlService) {
            var vm = this;

            let rememberId = {
                grid: 'orderpickstatusstl.grid',
                fields: 'orderpickstatusstl.fields'
            };

            vm.fields = {
                stocktransfertype_keyno: 12
            };

            vm.stocktransfertypes = [];

            vm.grid = {
                dataTask: {
                    rememberId: rememberId.grid,
                    loadSetupId: null,
                    loadData: {
                        method: 2527,
                        parameters: function () {
                            return {
                                stocktransfertype_keyno: vm.fields.stocktransfertype_keyno
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            vm.onDataChanged = function (value, modelId) {
                var parts = modelId.split('.');

                vm.fields[parts[parts.length - 1]] = value;

                rememberService.remember(rememberId.fields, vm.fields);

                vm.grid.gridfunc.read();
            };

            vm.$onInit = function () {
                rememberService.getLastStatus(rememberId.fields, true).then(function (response) {
                    vm.fields.stocktransfertype_keyno = response.stocktransfertype_keyno;
                });

                orderpickStatusStlService.init().then(function (response) {
                    angular.copy(response.stocktransfertypes, vm.stocktransfertypes);
                });
            };
        }]
    });
})();
