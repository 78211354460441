(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("salaryTypeService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            dataSource: {},
            unitList: {
                records: []
            },
            getSalaryType: function (salarytype_id) {
                var deferred = $q.defer();

                var parms_getSalaryType = {
                    method: 482,
                    parameters: {
                        salarytype_id: salarytype_id
                    }
                };

                $ihttp.post(parms_getSalaryType).then(function (data) {
                    service.dataSource = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            listExtraQuantityTypes: function (parms) {
                return $ihttp.post({
                    method: 3422,
                    parameters: parms || {}
                });
            },
            saveSalaryType: function (dataSource) {
                var deferred = $q.defer();

                var parms_saveSalaryType = {
                    method: 483,
                    parameters: dataSource
                };

                $ihttp.post(parms_saveSalaryType).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deleteSalaryType: function (salarytype_id) {
                var deferred = $q.defer();

                var parms_deleteSalaryType = {
                    method: 484,
                    parameters: {
                        salarytype_id: salarytype_id
                    }
                };

                $ihttp.post(parms_deleteSalaryType).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadUnitList: function () {
                var deferred = $q.defer();

                var parms_p2_unit_list = {
                    method: 218
                };

                $ihttp.post(parms_p2_unit_list).then(function (data) {
                    angular.copy(data, service.unitList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            listImpactTimeAccount: function (parms) {
                return $ihttp.post({
                    method: 2124,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
