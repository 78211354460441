(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2WebpageLinkEdit', {
        templateUrl: 'views/components/views/p2WebpageLinkEdit/p2WebpageLinkEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2WebpageLinkEditService', 'stateService', function($stateParams, p2WebpageLinkEditService, stateService) {
    
            var vm = this;
    
    
            vm.webpage_link_edit = p2WebpageLinkEditService.webpage_link_edit;
            vm.p2_webpage_link_list_belongto = p2WebpageLinkEditService.p2_webpage_link_list_belongto;
    
            var isSaving = false;
            
            vm.lockingP2WebpageLinkEditSaveButton = {
                locked: '0'
            };
    
            p2WebpageLinkEditService.loadP2WebpageLinkGet($stateParams.p2_webpage_keyno_belongto, $stateParams.p2_webpagelink_keyno || '0').then(function () {
                vm.webpage_link_edit = p2WebpageLinkEditService.webpage_link_edit;
            });
    
            p2WebpageLinkEditService.loadP2WebpageLinkList();
    
            vm.saveP2WebpageLinkEdit = function () {
                if (isSaving == true || vm.lockingP2WebpageLinkEditSaveButton.locked == '1') return;
    
                isSaving = true;
                vm.lockingP2WebpageLinkEditSaveButton.locked = '1';
    
                p2WebpageLinkEditService.saveP2WebpageLinkEdit().then(function () {
                    stateService.back();
                });
    
                isSaving = false;
            }
        }]
    });
})();