(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('selectEquipmentPu', {
        templateUrl: 'views/components/views/selectEquipmentPu/selectEquipmentPu.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectEquipmentPuService', function ($stateParams, selectEquipmentPuService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let webPageName = $stateParams.webpagename;
            let operationId = $stateParams.operation_id;

            vm.model = {
                itemsListEquipmentPus: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSelectEquipmentPus = function () {
                selectEquipmentPuService.listSelectEquipmentPus({
                    webpagename: webPageName,
                    operation_id: operationId
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListEquipmentPus);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE & GO TO PREVIOUS FUNCTION

            vm.goToPrevious = function (item) {
                selectEquipmentPuService.saveItem({
                    equipment_id: item.item_id,
                    webpagename: webPageName
                });
            };

		    // #endregion SAVE & GO TO PREVIOUS FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSelectEquipmentPus();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
