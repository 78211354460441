(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2ReleaseNoteEdit', {
        templateUrl: 'views/components/views/p2ReleaseNoteEdit/p2ReleaseNoteEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2ReleaseNoteEditService', 'stateService', function ($stateParams, p2ReleaseNoteEditService, stateService) {

            // ####################
            // LOAD PROCEDURE CALLS
            // ####################

            var vm = this;
            
            p2ReleaseNoteEditService.loadP2ReleaseNoteGet($stateParams.p2_releasenote_keyno || '0').then(function () {
                vm.p2_releasenote_get = p2ReleaseNoteEditService.p2_releasenote_get;
            });
    
            p2ReleaseNoteEditService.loadP2ReleaseTypeNameList().then(function () {
                vm.p2_releasetype_name_list = p2ReleaseNoteEditService.p2_releasetype_name_list;
            });
    
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
    
            vm.saveP2ReleaseNote = function () {
                p2ReleaseNoteEditService.saveP2ReleaseNote().then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();