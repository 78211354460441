(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("timeSheetsStatusService", ['$ihttp', '$timeout', function ($ihttp, $timeout) {
        let service = {
            reloaded: false,
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1396,
                    parameters: parms || {}
                });
            },
            listStatuses: function (parms) {
                return $ihttp.post({
                    method: 276,
                    parameters: parms || {}
                });
            },
            reloadP2TimeSheetStatusList: function () {
                service.reloaded = false;

                // Reload/update prosedyre sp_ws_p2_timesheet_status_list (lagt til 17.03.2017)
                $timeout(function () {
                    service.reloaded = true;
                }, 30000);

                return service.reloaded;
            }
        };

        return service;
    }]);
})();