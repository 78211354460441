(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('printTestPrinters', {
        templateUrl: 'views/components/views/printTestPrinters/printTestPrinters.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'printService', 'modalParametersService', function($stateParams, printService, modalParametersService) {
    
            var vm = this;
    
    
            vm.model = {
                reportKeyno: $stateParams.reportKeyno,
                printers: [],
                page: 1,
                pageSize: 10,
                total: 0,
                pageSizes: [5, 10, 15, 25, 50]
            }
    
            var load = function () {
                printService.getPrinters({ page: vm.model.page, pageSize: vm.model.pageSize }).then(function (data) {
                    vm.model.total = data.total;
    
                    angular.copy(data.items, vm.model.printers);
                });
            };
    
            load();
    
            vm.printReport = function(printer) {
                printService.getParameters({ reportKeyno: vm.model.reportKeyno }).then(function (parameters) {
                    if (parameters.length > 0) {
                        var schema = {
                            type: 'object',
                            properties: {}
                        };
    
                        angular.forEach(parameters,
                            function (value, key) {
                                var name = value.parmname.substr(0, 1) === '@' ? value.parmname.substr(1) : value.parmname;
    
                                var parts = name.split('_');
    
                                var title = '';
    
                                for (var i = 0; i < parts.length; i++) {
                                    if (title.length > 0) {
                                        title = title + ' ';
                                    }
    
                                    title = title + parts[i].substr(0, 1).toUpperCase() + parts[i].substr(1);
                                }
    
                                schema.properties[name] = {
                                    title: title,
                                    type: value.javaScriptType.toLowerCase()
                                };
                            });
    
                        modalParametersService.getParameterValues({ form: ["*"], schema: schema }).then(
                            function (parameters) {
                                var parms = {
                                    reportKeyno: vm.model.reportKeyno,
                                    printerKeyno: printer.printClientPrinterKeyno,
                                    parameters: parameters,
                                    pageAutoSize: true,
                                    isAutoRotate: true
                                };
    
                                printService.print(parms).then(function () {
                                    console.log('print completed 1');
                                });
                            });
                    } else {
                        var parms = {
                            reportKeyno: vm.model.reportKeyno,
                            printerKeyno: printer.printClientPrinterKeyno
                        };
    
                        printService.print(parms).then(function () {
                            console.log('print completed 2');
                        });
                    }
                });
            };
    
            vm.pageChanged = function () {
                load();
            };
    
            vm.setPageSize = function (size) {
                vm.model.pageSize = size;
    
                load();
            };
        }]
    });
})();
