(function() {
    'use strict';

    angular.module("imApp").factory("purchaseCreateService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        var service = {
            //createProduction: function (method, rememberId, settings, isBase) {
            //    return call(2272, {
            //        is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
            //        variablename: rememberId,
            //        variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(settings) : utoa(angular.toJson(settings)) //defaults to true
            //    });
            //},
            createProduction: function (parms) {
                //return call(2272, angular.toJson(parms) || {});
                return call(2272, parms || {});
            },
        }

        return service;
    }]);
})();
