(function() {
    'use strict';

    angular.module("imApp").factory("aboutComponentService", ['$ihttp', function ($ihttp) {
        var service = {
            getAbout: function (parms) {
                return $ihttp.post({
                    method: 500,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();