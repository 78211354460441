(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epBlockOrdersFinishedShipments', {
        templateUrl: 'views/components/views/epBlockOrdersFinishedShipments/epBlockOrdersFinishedShipments.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$stateParams', 'stateService', 'ttGridFactory', function ($stateParams, stateService, ttGridFactory) {
            var vm = this;
            vm.version = module.version;

            vm.isModal = false;
            vm.ready = false;

            var load = function (pohKeyno) {
                vm.gridOptions = new ttGridFactory({ rememberId: 'epBlockOrdersFinishedShipments', loadData: { method: 2011, parameters: { poh_keyno: pohKeyno } } }).setToolbar({ hidden: true });
                //vm.gridOptions = {
                //    dataTaskKeyno: 2011,
                //    parameters: {
                //        poh_keyno: pohKeyno
                //    },
                //    rememberId: 'epBlockOrdersFinishedShipments'
                //};
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve) && angular.isDefined(vm.resolve.pohKeyno)) {
                    // when opened as modal
                    vm.isModal = true;
                    load(vm.resolve.pohKeyno);
                    vm.ready = true;
                }
            };

            if (angular.isDefined($stateParams.pohKeyno)) {
                // when opened with state
                load($stateParams.pohKeyno);
            }

            vm.cancel = function () {
                if (vm.isModal === true) {
                    vm.modalInstance.dismiss()
                } else {
                    stateService.back();
                }
            };
        }]
    });
})();
