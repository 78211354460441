<div *ngIf="!!params && !!params.column" class="ag-cell-label-container" role="presentation">
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <span *ngIf="editable" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper" [ngClass]="headerChecked ? 'ag-checked' : ''" role="presentation">
            <input
                class="ag-input-field-input ag-checkbox-input"
                type="checkbox"
                [value]="headerChecked"
                (change)="checkAll()"
                tabindex="-1"
                aria-label="Press Space to toggle all rows selection (unchecked)"
            />
        </span>
        <span ref="eText" class="ag-header-cell-text" (click)="toggleSort($event)">
            {{ params.displayName }}
        </span>
        <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon ag-hidden" aria-hidden="true">
            <span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>
        </span>
        <span class="ag-sort-indicator-container" ref="eSortIndicator" (click)="toggleSort($event)">
            <span *ngIf="sortIndex !== undefined && sortIndex !== null && numberOfActiveSorts > 1" ref="eSortOrder" class="ag-sort-indicator-icon ag-sort-order">
                {{ sortIndex + 1 }}
            </span>
            <span *ngIf="sort === 'asc'" class="ag-sort-indicator-icon ag-sort-ascending-icon">
                <span class="ag-icon ag-icon-asc" unselectable="on" role="presentation"></span>
            </span>
            <span *ngIf="sort === 'desc'" class="ag-sort-indicator-icon ag-sort-descending-icon">
                <span class="ag-icon ag-icon-desc" unselectable="on" role="presentation"></span>
            </span>
        </span>
    </div>
</div>
