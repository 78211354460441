//(function () {
//    'use strict';

//    let module = angular.module("imApp");

//    module.factory("mainMenuService", ['$ihttp', '$q', function ($ihttp, $q) {
//        var callDatatask = function (method, parms) {
//            console.log('callDatatask: ' + method);
//            console.dir(parms);

//            var deferred = $q.defer();


//            $ihttp.post({
//                method: method,
//                parameters: parms || {}
//            }).then(function (response) {
//                console.log('callDatatask - response: ' + method);
//                console.dir(response);

//                deferred.resolve(response);
//            });

//            return deferred.promise;
//        }

//        let service = {
//            menus: [],
//            getMainMenu: function (parms) {
//                return callDatatask(2146, parms);

//                //return $ihttp.post({
//                //    method: 2146,
//                //    parameters: parms || {}
//                //});
//            },
//            listWebPageMenuAll: function (parms) {
//                return callDatatask(2854, parms);

//                //return $ihttp.post({
//                //    method: 2854,
//                //    parameters: parms || {}
//                //});
//            },
//            getCriticalProcesses: function () {
//                return callDatatask(2977, {});

//                //return $ihttp.post({
//                //    method: 2977,
//                //    parameters: {}
//                //});
//            },
//            listMyMenus: function (parms) {
//                return callDatatask(2857, parms);

//                //return $ihttp.post({
//                //    method: 2857,
//                //    parameters: parms || {}
//                //});
//            },
//            searchGlobalAll: function (setting) {
//                return callDatatask(2855, setting);

//                //return $ihttp.post({
//                //    method: 2855,
//                //    parameters: setting
//                //});
//            },
//            approveGdpr: function (setting) {
//                return callDatatask(2147, setting);

//                //return $ihttp.post({
//                //    method: 2147,
//                //    parameters: setting
//                //});
//            },
//            checkVersion: function (version) {
//                return callDatatask(2979, {
//                    tt_client_version: version
//                });

//                //return $ihttp.post({
//                //    method: 2979,
//                //    parameters: {
//                //        tt_client_version: version
//                //    }
//                //});
//            }
//        };

//        return service;
//    }]);
//})();
