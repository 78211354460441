(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactCustomer', {
        templateUrl: 'views/components/views/custactCustomer/custactCustomer.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custactCustomerService', 'typeaheadService', function ($stateParams, stateService, custactCustomerService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;
            let custactCustomerKeyno = $stateParams.custact_customer_keyno;

            vm.model = {
                custNoButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.cust_no }) }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'customer_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'customer_name':
                        if (item) {
                            vm.model.edit.cust_no = item?.item_id ?? '0';
                            vm.model.edit.customer_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('customer_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'customer_name':
                        vm.model.edit.customer_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('customer_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'customer_name':
                        vm.model.edit.cust_no = '0';
                        vm.model.edit.customer_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                custactCustomerService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ANGULAR LIFECYCLE

            vm.$onInit = function () {
                custactCustomerService.getEdit({
                    custact_no: custactNo,
                    custact_customer_keyno: custactCustomerKeyno
                }).then((info) => vm.model.edit = info[0]);
            }

            // #endregion ANGULAR LIFECYCLE
        }]
    });
})();
