(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("purchHeadService", ['$ihttp', '$timeout', function ($ihttp, $timeout) {
        let service = {
            reloaded: false,
            timer: null,
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 686,
                    parameters: parms || {}
                });
            },
            getPersonInfo: function (parms) {
                return $ihttp.post({
                    method: 700,
                    parameters: parms || {}
                });
            },
            getFreightRate: function (edit) {
                return $ihttp.post({
                    method: 1548,
                    parameters: edit
                });
            },
            listFreightRateSets: function (parms) {
                return $ihttp.post({
                    method: 1551,
                    parameters: parms || {}
                });
            },
            listFreightRates: function (parms) {
                return $ihttp.post({
                    method: 1552,
                    parameters: parms || {}
                });
            },
            listInitiatedBy: function (parms) {
                return $ihttp.post({
                    method: 1045,
                    parameters: parms || {}
                });
            },
            convertToSalesOrders: function (edit) {
                return $ihttp.post({
                    method: 1599,
                    parameters: edit
                });
            },
            convertToPurchaseOrders: function (edit) {
                return $ihttp.post({
                    method: 702,
                    parameters: edit
                });
            },
            progressToSalesOrder: function (edit) {
                return $ihttp.post({
                    method: 1980,
                    parameters: edit
                });
            },
            reloadProgressPurchHeadToSalesOrder: function (s) {
                if (angular.isDefined(service.timer)) {
                    $timeout.cancel(service.timer);
                }

                service.timer = $timeout(function () {
                    service.reloaded = true;
                }, 3000);

                return service.reloaded;
            },
            updatePurchaseFreight: function (edit) {
                return $ihttp.post({
                    method: 1555,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 690,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 735,
                    parameters: edit
                });
            },
            cancelRestPurchHead: function (edit) {
                return $ihttp.post({
                    method: 1989,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
