(function() {
    'use strict';
    var module = angular.module("imApp");

    module.factory("phoneNumberService", [function () {
        var phoneUtil = PhoneNumberUtil.getInstance();

        var service = {
            validateNumber: function (number) {
                if (angular.isObject(phoneUtil) !== true) return false;

                var phoneNumber = phoneUtil.parse(number, 'NO');

                if (angular.isObject(phoneNumber) !== true) return false;

                var isValid = phoneUtil.isValidNumber(phoneNumber);

                if (isValid !== true) return false;

                var hasCountryCode = phoneNumber.hasCountryCode();

                return hasCountryCode;
            },
            toInternationalNumber: function (number) {
                var phoneNumber = phoneUtil.parse(number, 'NO');

                return phoneUtil.format(phoneNumber, 1).removeWhiteSpace();
            }
        };

        return service;
    }]);
})();
