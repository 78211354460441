(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryOrderStatusInvoiceDate', {
        templateUrl: 'views/components/views/deliveryOrderStatus/deliveryOrderStatus.template.invoiceDate.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: [function () {
            let vm = this;
            vm.model = {
                invoiceDate: null
            };
            let onDestroy = [];
            
            
            

            vm.dateOptions = {
                date: { show: true, disabled: false, align: 'right' },
                hour: { show: false, disabled: false, align: 'right' },
                minute: { show: false, disabled: false, align: 'right', interval: 15 },
            };

            vm.saveInvoiceDate = function () {
                vm.modalInstance.close(vm.model)
            }
 
            vm.cancel = function () {
                vm.modalInstance.dismiss();
            };


            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                console.log('Print OPEN');
                console.dir(vm.resolve.parameters.lastDeliveryDate);
                vm.model.invoiceDate = vm.resolve.parameters.lastDeliveryDate;
                
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS



        }]
    });
})();
