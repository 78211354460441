(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderDialog', {
        templateUrl: 'views/components/views/orderDialog/orderDialog.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'orderDialogService', function ($stateParams, stateService, modalService, orderDialogService) {
            var vm = this;
            
            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                orderDialog: {},
                listOrderDialogs: [],
                locked: false
            };
            
            orderDialogService.loadOrderDialogSettings(vm.model.orderInternalNo).then(function (data) {
                vm.model.orderDialog = angular.copy(data[0]);
            });
            
            orderDialogService.loadOrderDialogList(vm.model.orderInternalNo).then(function (data) {
                angular.copy(data, vm.model.listOrderDialogs);
            });
            
            vm.saveOrderDialog = function () {
                vm.model.locked = true;
                
                orderDialogService.save(vm.model.orderDialog).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.locked = false;
                    }
                });
            };
        }]
    });
})();