(function () {
    'use strict';

    /**
     * @author JLR
     * @version 20231108
     */
    angular.module("imApp").factory("emailAccountSharedEditService", ['$ihttp', function ($ihttp) {
        let service = {

            /**
             * @typedef {Object} SharedMailbox represents a shared mailbox.
             * @property {string} active_email_account the active email account of the user.
             * @property {string} emailaccount_shared_keyno stringified number representing the id of the shared mailbox, "0" if there is none.
             * @property {string} shared_email_account the address of the shared mailbox, an empty string if there is none.
             */

            /**
             * Gets the shared mailbox with the given shared email account keyno and the current account email. If a new shared account is to be added keyno should be "0".
             * 
             * @param {string} emailaccountSharedKeyno the key for the shared email account, if a new is to be added, key should be "0".
             * @param {string} currentAccountEmail the current account email for the shared mailbox.
             * @returns {Promise<{data: SharedMailbox[]; iserror: boolean; message: string;}>} a promise containing a list of data with the shared mailbox.
             */
            getSharedMailbox: function (emailaccountSharedKeyno, currentAccountEmail) {
                return $ihttp.post({ method: 2959, parameters: { emailaccount_shared_keyno: emailaccountSharedKeyno, current_account_email: currentAccountEmail } });
            },

            /**
             * Saves the given shared mailbox, if emailaccount_shared_keyno is "0", a new shared mailbox is created.
             * 
             * @param {SharedMailbox} sharedMailbox the shared mailbox to save.
             * @returns {Promise<{ data: {errorcode: string; errormessage: string;}[], iserror: boolean; message: string;}>} a promise containing error response, if save was successful then errorcode is "0". 
             */
            saveSharedMailbox: function (sharedMailbox) {
                return $ihttp.post({ method: 2960, parameters: sharedMailbox });
            },

            /**
             * Deletes the given shared mailbox.
             * 
             * @param {SharedMailbox} sharedMailbox the shared mailbox to delete.
             * @returns {Promise<{ data: {errorcode: string; errormessage: string;}[], iserror: boolean; message: string;}>} a promise containing error response, if delete was successful then errorcode is "0". 
             */
            deleteSharedMailbox: function (sharedMailbox) {
                return $ihttp.post({ method: 2961, parameters: sharedMailbox });
            }
        }

        return service;
    }]);
})();
