(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2Terminal', {
        templateUrl: 'views/components/views/p2Terminal/p2Terminal.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2TerminalService', 'stateService', function ($stateParams, p2TerminalService, stateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;

            vm.model = {
                p2TerminalGet: {},
                p2TerminalTypesList: {
                    records: []
                },
                p2BusinessCoList: {
                    records: []
                },
                p2TerminalNotificationTypesList: {
                    records: []
                },
                p2TerminalPartDeliveryTypeList: {
                    records: []
                },
                p2EquipmentList: {
                    records: []
                },
                hideRegByPortalUserName: $stateParams.p2_terminal_keyno,
                hideRegDateTime: $stateParams.p2_terminal_keyno
            };
            
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
            
            p2TerminalService.loadP2TerminalGet($stateParams.p2_terminal_keyno).then(function () {
                vm.model.p2TerminalGet = p2TerminalService.p2TerminalGet;
            });
            
            p2TerminalService.loadP2TerminalTypesList().then(function () {
                vm.model.p2TerminalTypesList = p2TerminalService.p2TerminalTypesList;
            });
            
            p2TerminalService.loadP2BusinessCoList().then(function () {
                vm.model.p2BusinessCoList = p2TerminalService.p2BusinessCoList;
            });
            
            p2TerminalService.loadP2TerminalNotificationTypesList().then(function() {
                vm.model.p2TerminalNotificationTypesList = p2TerminalService.p2TerminalNotificationTypesList;
            });
            
            p2TerminalService.loadP2TerminalPartDeliveryTypeList().then(function () {
                vm.model.p2TerminalPartDeliveryTypeList = p2TerminalService.p2TerminalPartDeliveryTypeList;
            });
            
            p2TerminalService.loadP2EquipmentList().then(function (data) {
                angular.copy(data, vm.model.p2EquipmentList.records);
            });
            
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
            
            vm.saveP2Terminal = function () {
                p2TerminalService.saveP2Terminal(vm.model.p2TerminalGet).then(function () {
                    stateService.back();
                });
            };
            
            // ###################
            // DELETE BUTTON
            // ###################
            
            vm.deleteP2Terminal = function () {
                p2TerminalService.deleteP2Terminal().then(function () {
                    stateService.back();
                });
            };
            
            vm.gotoPortalUserStockplace = function () {
                stateService.go('portaluserstockplace', {
                    portal_user_keyno: vm.model.p2TerminalGet.portal_user_keyno
                });
            };
        }]
    });
})();