(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('gisPosition', {
        templateUrl: 'views/components/views/gisPosition/gisPosition.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'gisPositionService', function ($stateParams, stateService, modalService, gisPositionService) {
            var vm = this;
            
            vm.model = {
                gisPositionKeyno: $stateParams.gisposition_keyno,
                location: {},
                readyGisPositionNote: true,
                lockedSave: false,
                lockedDelete: false
            };
            
            gisPositionService.loadGisPositionGet(vm.model.gisPositionKeyno).then(function (data) {
                vm.model.location = angular.copy(data[0]);
            });
            
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'pictures':
                        go({
                            argtype: 'gisposition_keyno',
                            argvalue: vm.model.location.gisposition_keyno
                        });
                        break;
                }
            };
            
            vm.openGoogleMaps = function () {
                window.open(vm.model.location.google_url);
            };
            
            vm.openFinn = function () {
                window.open(vm.model.location.finn_url);
            };
            
            vm.saveGisPosition = function () {
                vm.model.lockedSave = true;
                
                gisPositionService.save(vm.model.location).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
            
            vm.deleteGisPosition = function () {
                vm.model.lockedDelete = false;
                
                gisPositionService.delete(vm.model.location).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();