(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2ExportProcMenu', {
        templateUrl: 'views/components/views/p2ExportProcMenu/p2ExportProcMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2ExportProcMenuService', function ($stateParams, stateService, p2ExportProcMenuService) {
            var vm = this;
            
            vm.model = {
                p2ExportProcKeyNo: $stateParams.p2_exportproc_keyno,
                menuExportProcedure: {}
            };
            
            p2ExportProcMenuService.loadP2ExportProcMenuGet(vm.model.p2ExportProcKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.menuExportProcedure);
            });
            
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'p2exportproc':
                        go({
                            p2_exportproc_keyno: vm.model.menuExportProcedure.p2_exportproc_keyno
                        });
                        break;
                    case 'p2exportprocusergroups':
                        go({
                            p2_exportproc_keyno: vm.model.menuExportProcedure.p2_exportproc_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();