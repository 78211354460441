(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('calView', {
        templateUrl: 'views/components/views/calView/calView.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'utilityService', 'calViewService', function($stateParams, us, calViewService) {
			var vm = this;

            vm.date_list = [];
            vm.months = {};
            vm.day_letters = {};
            vm.month_names = {};
            vm.view_mode = 1;
            vm.dd_codes = {};
            vm.codeList = {};
            vm.active_code = {};
            vm.dd_code_keyno = '';
            vm.filterMonth = '';
            vm.filterWeek = '';
            vm.filterDayNum = '';
            vm.filterDay = '';

            calViewService.load_p2_m_calview_dates().then(function () {           
                vm.date_list = calViewService.p2_m_calview_dates.records;
                vm.months = calViewService.p2_m_calview_dates.months;
                vm.day_letters = calViewService.p2_m_calview_dates.days;
                vm.month_names = calViewService.p2_m_calview_dates.month_names;
            
                calViewService.load_p2_m_calview_dd_codes().then(function (){
                    vm.dd_codes = calViewService.p2_m_calview_dd_codes.records;
                });
            });

            vm.resetFilter = function(){
                vm.filterMonth = '';
                vm.filterWeek = '';
                vm.filterDayNum = '';
                vm.filterDay='';
            };

            vm.view_modes = {
                item_id: 0,
                item_name: '',
                item_default: '0',
                item_selected: '1',
                records: [
                    { item_id: '0', item_name: 'calview_view', item_func: '1' },
                    { item_id: '1', item_name: 'calview_reg', item_func: '2' }
                ]
            };

            vm.selectViewMode = function (view_mode) {
                switch (view_mode) {
                    case '2':
                        $('#register').show(); $('#viewer').hide();
                        break;
                    default:
                        vm.resetFilter();
                        $('#register').hide(); $('#viewer').show();
                        break;
                }

                vm.view_modes.item_selected = view_mode;
            };

            vm.setActiveCode = function (code) {
                vm.dd_code_keyno = code;

                if (code) vm.active_code = vm.dd_codes[vm.dd_code_keyno];
            };

            vm.cellRender = function(testObj){
                var codecount = us.objectLength(testObj.codes);

                switch (codecount){
                    case 0:
                        testObj.style_bg_from = testObj.background_color;
                        testObj.style_bg_to = testObj.background_color;
                        testObj.text_color = testObj.text_color_hex;
                        break;
                    case 1:
                        testObj.style_bg_from = testObj.codes[Object.keys(testObj.codes)[0]].background_color_hex;
                        testObj.style_bg_to = testObj.codes[Object.keys(testObj.codes)[0]].background_color_hex;
                        testObj.text_color = testObj.codes[Object.keys(testObj.codes)[0]].text_color_hex;
                        break;
                    case 2:
                        testObj.style_bg_from = testObj.codes[Object.keys(testObj.codes)[0]].background_color_hex;
                        testObj.style_bg_to = testObj.codes[Object.keys(testObj.codes)[1]].background_color_hex;
                        testObj.text_color = testObj.codes[Object.keys(testObj.codes)[0]].text_color_hex;
                        break;
                    default:
                        testObj.style_bg_from = '#d27c14';
                        testObj.style_bg_to = '#d27c14';
                        testObj.text_color = testObj.text_color_hex;
                        break;
                }
            };

            vm.cleanCodeList = function () {
                vm.codeList = {};
            };

            vm.addCode = function (codeRecord) {
                var key = codeRecord.systemdate + '_' + codeRecord.offdutycode_keyno;
                vm.codeList[key] = codeRecord;
            };

            vm.testCode = function () {
                angular.forEach(vm.codeList, function (item) {
                    item.background_color_hex = item.actual_background_color_hex;
                    item.text_color_hex = item.actual_text_color_hex;
                    vm.cellRender(item.render_reference);
                });
            };

            vm.saveCodes = function () {
                if (us.objectLength(vm.codeList) > 0) {
                    calViewService.saveOffdutyData(vm.codeList).then(function () {
                        vm.testCode();
                    });
                }
            };

            vm.elementClicked = function (elem) {
                if ($(elem).hasClass('p_0')) {
                    var month = $(elem).attr('o-month');
                    var week = $(elem).attr('o-week');
                    var day = $(elem).attr('o-day');

                    if (!vm.months[month][week][day].codes[vm.active_code.offdutycode_keyno]) {
                        //add
                        var codeRecord = {
                            labour_no: $stateParams.argvalue,
                            offdutydata_keyno: '',
                            offdutycode_keyno: vm.active_code.offdutycode_keyno,
                            time_from: $(elem).attr('o-timefrom'),
                            time_to: $(elem).attr('o-timeto'),
                            systemdate: $(elem).attr('o-systemdate'),
                            remark: '',
                            deleted: 0,
                            background_color_hex: '#cccccc',
                            text_color_hex: '#cccccc',
                            actual_background_color_hex: vm.active_code.background_color_hex,
                            actual_text_color_hex: vm.active_code.text_color_hex,
                            render_reference: vm.months[month][week][day]
                        };

                        vm.months[month][week][day].codes[vm.active_code.offdutycode_keyno] = codeRecord;
                        vm.cellRender(vm.months[month][week][day]);
                        vm.addCode(codeRecord);

                        /*calViewService.saveOffdutyData(codeRecord).then(function (){
                            //codeRecord.offdutydata_keyno = calViewService;
                            vm.months[month][week][day].codes[vm.active_code.offdutycode_keyno] = codeRecord;
                            vm.cellRender(vm.months[month][week][day]);
                        });*/
                    } else {
                        // remove
                        var newCodes = vm.months[month][week][day].codes;
                        vm.months[month][week][day].codes = {};

                        angular.forEach(newCodes, function (item) {
                            if (item.offdutycode_keyno == vm.active_code.offdutycode_keyno) {
                                if (item.offdutydata_keyno > 0) {
                                    var codeRecord = {
                                        labour_no: $stateParams.argvalue,
                                        offdutydata_keyno: item.offdutydata_keyno,
                                        offdutycode_keyno: item.offdutycode_keyno,
                                        time_from: item.time_from,
                                        time_to: item.time_to,
                                        systemdate: item.systemdate,
                                        remark: item.remark,
                                        deleted: 1,
                                        background_color_hex: '#cccccc',
                                        text_color_hex: '#cccccc',
                                        actual_background_color_hex: vm.active_code.background_color_hex,
                                        actual_text_color_hex: vm.active_code.text_color_hex,
                                        render_reference: vm.months[month][week][day]
                                    };

                                    vm.months[month][week][day].codes[vm.active_code.offdutycode_keyno] = codeRecord;
                                    vm.cellRender(vm.months[month][week][day]);
                                    vm.addCode(codeRecord);
                                } else {
                                    //vm.months[month][week][day].codes[item.offdutycode_keyno] = item;
                                    //vm.months[month][week][day].codes.splice(item);
                                    vm.cellRender(vm.months[month][week][day]);
                                }

                                //vm.months[month][week][day].codes[item.offdutycode_keyno] = item;
                            } else {
                                vm.months[month][week][day].codes[item.offdutycode_keyno] = item;
                                vm.cellRender(vm.months[month][week][day]);
                            }
                        });

                        //vm.cellRender(vm.months[month][week][day]);
                    }
                }
            };

            vm.tableCellClick = function (click_date) {
                if (click_date != '') {
                    if (vm.view_modes.item_selected == '2') {
                        if (vm.active_code) {
                            vm.cleanCodeList();
                            vm.elementClicked($('#cell_' + click_date));
                            vm.saveCodes();
                        }
                    } else {
                        vm.resetFilter();
                        vm.filterDay = click_date;
                    }
                }
            };

            vm.weekClick = function (weekno) {
                if (weekno != '') {
                    if (vm.view_modes.item_selected == '2') {
                        if (vm.active_code) {
                            vm.cleanCodeList();

                            $('.w_' + weekno).each(function () {
                                vm.elementClicked($(this));
                            });

                            vm.saveCodes();
                        }
                    } else {
                        vm.resetFilter();
                        vm.filterWeek = weekno;
                    }
                }
            };

            vm.dayClick = function (dl, m) {
                if ((dl != '') && (m != '')) {
                    if (vm.view_modes.item_selected == '2') {
                        if (vm.active_code) {
                            vm.cleanCodeList();

                            $('.dl_' + dl + '_' + m).each(function () {
                                vm.elementClicked($(this));
                            });

                            vm.saveCodes();
                        }
                    }
                }
            };

            vm.sleep = function (ms) {
                var now = new Date().getTime();
                var i = 0;

                while (new Date().getTime() < now + ms) {
                    i += 1;
                }
            };

            vm.monthClick = function (month) {
                if (month != '') {
                    if (vm.view_modes.item_selected == '2') {
                        if (vm.active_code) {
                            vm.cleanCodeList();

                            $('.m_' + month).each(function () {
                                vm.elementClicked($(this));
                            });

                            vm.saveCodes();
                        }
                    } else {
                        if (month == '1') {
                            vm.testCode();
                        } else {
                            vm.resetFilter();
                            vm.filterMonth = month;
                        }
                    }
                }
            };
		}]
	});
})();