(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productUnitData', {
        templateUrl: 'views/components/views/productUnitData/productUnitData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'productUnitDataService', function ($stateParams, $q, stateService, utilityService, productUnitDataService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let productUnitDataKeyno = $stateParams.productunitdata_keyno;
            
            vm.model = {
                edit: {},
                selectListFactorInputMethods: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                const deferred = $q.defer();

                productUnitDataService.getEdit({ productunitdata_keyno: productUnitDataKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadFactorInputMethods();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadFactorInputMethods = function () {
                if (utilityService.validateParmsValue(vm.model.edit.productunitdata_keyno) !== true) return;

                return productUnitDataService.listFactorInputMethods({ productunitdata_keyno: vm.model.edit.productunitdata_keyno }).then(function (list) {
                    angular.copy(list, vm.model.selectListFactorInputMethods);
                });
            };

            let retrieveInputMethod = function () {
                if (utilityService.validateParmsValue(vm.model.edit.productunitdata_factor_input_method_no) !== true) return;

                return productUnitDataService.changeInputMethod(vm.model.edit).then(function (response) {
                    vm.model.edit.qtyfactor = response[0].qtyfactor;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'productunitdata_factor_input_method_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.productunitdata_factor_input_method_no) !== true) return;

                        vm.model.edit.productunitdata_factor_input_method_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.productunitdata_factor_input_method_no) !== true) return;

                        retrieveInputMethod();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE FUNCTION

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productUnitDataService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
