(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sopCls', {
        templateUrl: 'views/components/views/sopCls/sopCls.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'sopClsService', function($stateParams, stateService, utilityService, sopClsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                sopKeyNo: $stateParams.sop_keyno,
                menuChoices: {},
                itemsListChoices: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            sopClsService.loadSopClsGet(vm.model.sopKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.menuChoices);
    
                loadSopCls();
            });
    
            var loadSopCls = function () {
                if (utilityService.validateParmsValue(vm.model.menuChoices.sop_keyno) !== true) return;
    
                vm.model.itemsListChoices = [];
    
                sopClsService.loadSopClsList(vm.model.menuChoices.sop_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListChoices);
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'sopcl':
                        go({
                            sop_keyno: vm.model.menuChoices.sop_keyno,
                            sopcl_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();