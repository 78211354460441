(function () {
    'use strict';

    var module = angular.module('imApp');

    // BJS 20241126
    module.factory('idInterceptor', ['$q', 'appConfigService', function ($q, appConfigService) {
        return {
            request: function (config) {
                if (appConfigService.clientSessionId) {
                    config.headers['X-INSOFT-ID'] = appConfigService.clientSessionId;
                }

                return config;
            },
            response: function (response) {
                return response;
            },
            responseError: function (rejection) {
                return $q.reject(rejection);
            }
        };
    }]);
})();
