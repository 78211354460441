import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { ComponentBaseComponent } from '@app/core/components/component-base/component-base.component';
import { TTDateAdapter } from '@app/core/models/date-adapter';
import { TranslateService } from '@app/core/services/translate.service';
import { UserStore } from '@app/core/services/user.store';

/**
 * Represents a datetime cell editor component for AG-Grid.
 */
@Component({
    selector: 'tt-date-time-cell-editor',
    templateUrl: './date-time-cell-editor.component.html',
    styleUrls: ['./date-time-cell-editor.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateTimeCellEditorComponent extends ComponentBaseComponent implements ICellEditorAngularComp, AfterViewInit {
    /**
     * Refernce to the datetime html input element.
     */
    @ViewChild('inputRef')
    public inputRef?: ElementRef;

    @ViewChild('timeRef')
    public timeRef?: ElementRef;

    /**
     * Cell editor parameters passed from the cell.
     */
    public params?: ICellEditorParams;

    public dateFormat?: string;

    /**
     * The date value.
     */
    public value: Date | null = null;

    public time: string = '';

    /**
     * Whether the value in the cell has been edited since editing started.
     */
    public hasBeenEdited: boolean = false;

    public datePlaceholder: string = '';

    public timePlaceholder: string = '--:--';

    @HostListener('keydown', ['$event'])
    public keydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.params?.api.stopEditing(true);
            this.params?.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field!);
        }
    }

    constructor(private userStore: UserStore, translate: TranslateService) {
        super();
        this.userStore.dateFormatChanged.subscribe(async (format) => {
            this.dateFormat = format.replaceAll('m', 'M');
            this.datePlaceholder = await translate.translate(format);
        });
    }

    /**
     * Sets the date value when changed.
     *
     * @param event string representation of the new date.
     */
    onDateChanged(event: Date | null) {
        this.hasBeenEdited = true;
        this.value = event;
    }

    onTimeChanged(_: string) {
        this.hasBeenEdited === true;
    }

    async onDateKeydown(event: KeyboardEvent) {
        const dateField = this.inputRef?.nativeElement as HTMLInputElement;
        const timeField = this.timeRef?.nativeElement as HTMLInputElement;

        if (event.key === ' ') {
            const oldValue = this.time;

            timeField?.focus(); // this makes the time get replaced with the space

            setTimeout(() => {
                this.time = oldValue; // so i reset the value here
                setTimeout(() => {
                    timeField?.select(); // but i need to wait for the change to finish before i can select the value (thus many timeouts).
                });
            });
        } else if (event.key === 'ArrowRight' && (await this.selectionTwiceInLastPosition(dateField))) {
            timeField?.focus();
            timeField?.setSelectionRange(0, 0, 'forward'); // to prevent jumping of selection to end of field.
        }
    }

    async onTimeKeydown(event: KeyboardEvent) {
        if (event.key === ' ') {
        } else if (event.key === 'ArrowLeft' || event.key === 'Backspace' || event.key === 'Delete') {
            const timeField = this.timeRef?.nativeElement as HTMLInputElement;

            if (await this.selectionTwiceInFirstPosition(timeField)) {
                const dateField = this.inputRef?.nativeElement as HTMLInputElement;
                dateField?.focus();
                dateField?.setSelectionRange(dateField.value.length, dateField.value.length, 'backward');
                this.time = this.getTimeValue(this.time);
            }
        }
    }

    /**
     * Checks whether the given input field has had a selection (*arrow navigation*) to the end of the input field twice in a row.
     * Returns a promise containing `true` if the field had selection twice in a row, `false` if not.
     *
     * @param inputElement the input field to check if had selection to the end of the input field twice in a row.
     * @returns a promise containing `true` if selection to the end of the input field happened twice in a row, `false` if not.
     */
    private selectionTwiceInLastPosition(inputElement: HTMLInputElement): Promise<boolean> {
        return new Promise((resolve) => {
            if (!!inputElement && inputElement.tagName === 'INPUT') {
                const previousSelectionEnd = inputElement.selectionEnd;

                setTimeout(() => {
                    if (inputElement?.selectionEnd === inputElement.value.length && previousSelectionEnd === inputElement.value.length) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Checks whether the given input field has had a selection (*arrow navigation*) to the start of the input field twice in a row.
     * Returns a promise containing `true` if the field had selection twice in a row, `false` if not.
     *
     * @param inputElement the input field to check if had selection to the start of the input field twice in a row.
     * @returns a promise containing `true` if selection to the start of the input field happened twice in a row, `false` if not.
     */
    private selectionTwiceInFirstPosition(inputElement: HTMLInputElement): Promise<boolean> {
        return new Promise((resolve) => {
            if (!!inputElement && inputElement.tagName === 'INPUT') {
                const previousSelectionStart = inputElement.selectionStart;

                setTimeout(() => {
                    if (inputElement?.selectionStart === 0 && previousSelectionStart === 0) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;

        this.value = !!this.params.data[this.params.column.getColId()] ? new Date(this.params.data[this.params.column.getColId()]) : null;
        this.time = this.getTimeValue(this.params.data[this.params.column.getColId()]);
    }

    private getTimeValue(value?: string | Date | null): string {
        if (!value) {
            return '';
        } else if (value instanceof Date && value.toString() === 'Invalid Date') {
            return '00:00';
        } else if (value instanceof Date) {
            return `${this.getTwoDigitNumberString(value.getHours())}:${this.getTwoDigitNumberString(value.getMinutes())}`;
        } else if (typeof value === 'string') {
            if (this.isISODateTimeString(value)) {
                return value.split('T')[1].substring(0, 5);
            } else if (this.isDateTimeString(value)) {
                return value.split(' ')[1].substring(0, 5);
            } else if (this.containsOnlyDigits(value)) {
                return this.getTimeFromDigitString(value);
            } else {
                return this.parsedTime(value);
            }
        }

        return '00:00';
    }

    private getTimeFromDigitString(string: string) {
        if (string.length === 3) {
            return '0' + string[0] + ':' + string[1] + string[2];
        } else if (string.length === 4) {
            return string[0] + string[1] + ':' + string[2] + string[3];
        } else {
            return '00:00';
        }
    }

    private getTwoDigitNumberString(number: number) {
        return number < 10 ? '0' + number : number;
    }

    private isISODateTimeString(string: string) {
        return string.includes('T') && !!string.split('T')[1]?.substring(0, 5) && this.validTimeFormat(string.split('T')[1].substring(0, 5));
    }

    private isDateTimeString(string: string) {
        return string.includes(' ') && !!string.split(' ')[1]?.substring(0, 5) && this.validTimeFormat(string.split(' ')[1].substring(0, 5));
    }

    private containsOnlyDigits(string: string) {
        return /^\d+$/.test(string);
    }

    private validTimeFormat(time: string) {
        return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(time);
    }

    private parsedTime(time: string) {
        const seperator = time.match(/\D/)?.[0] ?? '';

        let hour = Number(time.split(seperator)[0].replace(/\D+/g, ''));
        let minute = Number(time.split(seperator)[1].replace(/\D+/g, ''));

        if (isNaN(hour) || hour < 0 || hour > 24 || isNaN(minute) || minute < 0 || minute > 60) {
            return '00:00';
        } else {
            return this.getTwoDigitNumberString(hour) + ':' + this.getTwoDigitNumberString(minute);
        }
    }

    getValue() {
        this.time = this.getTimeValue(this.time);
        this.value?.setHours(+(this.time.split(':')[0] ?? '00'), +(this.time.split(':')[1] ?? '00'));

        if (this.value?.toString() === 'Invalid Date') {
            return this.params?.data[this.params.column.getColId()] ?? null;
        } else {
            return this.value;
        }
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            this.inputRef?.nativeElement.focus();
            this.inputRef?.nativeElement.select();
        });
    }
}
