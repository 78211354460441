(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockBatchList', {
        templateUrl: 'views/components/views/stockBatchList/stockBatchList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockBatchListService', function ($stateParams, stockBatchListService) {
            
            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var stockKeyno = $stateParams.stock_keyno;

            vm.model = {
                itemsListStockBatches: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            stockBatchListService.listStockBatches({ stock_keyno: stockKeyno }).then(function (result) {
                angular.copy(result, vm.model.itemsListStockBatches);
            });

		    // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();