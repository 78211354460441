import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'im-input-directive'
})
export class ImInputDirective extends UpgradeComponent {
    @Input() label!: string;
    @Input() type!: string;
    @Input() typevar!: string;
    @Input() glyphs!: string;
    @Input() btnvar!: string;
    @Input() dataid!: string;
    @Input() dataname!: string;
    @Input() ttItemId!: string;
    @Input() ttGroupId!: string;
    @Input() ttFieldId!: string;
    @Input() ttStyle!: any;
    @Input() ttInvalid!: any;

    // Two-way Bindings (`=` in AngularJS)
    @Input() plabel: any;
    @Output() plabelChange = new EventEmitter<any>();

    @Input() sublabel: any;
    @Output() sublabelChange = new EventEmitter<any>();

    @Input() ptype: any;
    @Output() ptypeChange = new EventEmitter<any>();

    @Input() ptypevar: any;
    @Output() ptypevarChange = new EventEmitter<any>();

    @Input() dtvar: any;
    @Output() dtvarChange = new EventEmitter<any>();

    @Input() field: any;
    @Output() fieldChange = new EventEmitter<any>();

    @Input() myuser: any;
    @Output() myuserChange = new EventEmitter<any>();

    @Input() spin: any;
    @Output() spinChange = new EventEmitter<any>();

    @Input() placeholder: any;
    @Output() placeholderChange = new EventEmitter<any>();

    @Input() icons: any;
    @Output() iconsChange = new EventEmitter<any>();

    @Input() btnList: any;
    @Output() btnListChange = new EventEmitter<any>();

    @Input() pbtnvar: any;
    @Output() pbtnvarChange = new EventEmitter<any>();

    @Input() data: any;
    @Output() dataChange = new EventEmitter<any>();

    @Input() ieTrueValue: any;
    @Output() ieTrueValueChange = new EventEmitter<any>();

    @Input() ieFalseValue: any;
    @Output() ieFalseValueChange = new EventEmitter<any>();

    // Function Inputs (`&?` in AngularJS)
    @Input() optionfunc?: (args?: any) => any;
    @Input() changefunc?: (args?: any) => any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('imInputDirective', elementRef, injector);
    }
}
