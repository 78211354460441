//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('pohCompAddQuant', {
//        templateUrl: 'views/components/views/pohCompAddQuant/pohCompAddQuant.template.html?v=' + module.version, 
//        controllerAs: 'vm',
//        controller: ['pohCompAddQuantService', '$stateParams', 'stateService', function(pohCompAddQuantService, $stateParams, stateService) {
    
//            var vm = this;
    
    
//            vm.p2_pohcompaddquant_get = pohCompAddQuantService.p2_pohcompaddquant_get;
    
//            pohCompAddQuantService.loadP2PohCompAddQuantGet($stateParams.pohcomp_keyno).then(function () {
//                vm.p2_pohcompaddquant_get = pohCompAddQuantService.p2_pohcompaddquant_get;
//            });
    
//            vm.changePohCompAddScan = function () {
//                pohCompAddQuantService.deletePohCompAddQuant().then(function () {
//                    stateService.go('pohcompaddscan', { poh_keyno: $stateParams.poh_keyno });
//                });
//            }
    
//            vm.deletePohCompAddQuant = function () {
//                pohCompAddQuantService.deletePohCompAddQuant().then(function () {
//                    stateService.back();
//                });
//            }
    
//            vm.savePohCompAddQuant = function () {
//                pohCompAddQuantService.savePohCompAddQuant().then(function () {
//                    stateService.back();
//                });
//            }        
//        }]
//    });
//})();
