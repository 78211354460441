(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('rfidSearch', {
        templateUrl: 'views/components/views/rfidSearch/rfidSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'rememberService', 'rfidSearchService', function ($q, stateService, utilityService, rememberService, rfidSearchService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchRfids() }
                ],
                setting: {},
                itemsListRfids: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                rfidSearchService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadRfids();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadRfids = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;
                
                vm.model.itemsListRfids = [];
                
                return rfidSearchService.listRfids({ searchtext: vm.model.setting.searchtext }).then(function (list) {
                    angular.copy(list, vm.model.itemsListRfids);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE FUNCTIONS
            
            let rememberFunc = function () {
                var variableName = 'w_' + stateService.getCurrentName() + '.' + 'searchtext';
                
                if (utilityService.validateParmsValue(variableName) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                return rememberService.remember(variableName, vm.model.setting.searchtext);
            };

            // #endregion REMEMBER VALUE FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc();
                            vm.model.itemsListRfids = [];
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SEARCH FUNCTION
            
            vm.searchRfids = function () {
                rememberFunc();
                loadRfids();
            };

            // #endregion SEARCH FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc();
                            vm.model.itemsListRfids = [];
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
