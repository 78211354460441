(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('stockHistory', {
        templateUrl: 'views/components/views/stockHistory/stockHistory.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'stateService', 'stockHistoryService', 'rememberService', function ($stateParams, $q, utilityService, stateService, stockHistoryService, rememberService) {
            
    		// ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let stockKeyNo = $stateParams.stock_keyno;

            let variableNames = {
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

    		vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
    			setting: {},
    			itemsListHistories: []
    		};
    
    		// ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                stockHistoryService.getStockHistory({ stock_keyno: stockKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockHistories()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
    		
    		let loadStockHistories = function () {
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;
                
    			vm.model.itemsListHistories = [];
                
                return stockHistoryService.listStockHistories(vm.model.setting).then(function (result) {
    				angular.copy(result, vm.model.itemsListHistories);
    			});
    		};
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region DATE RANGE FIELD CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval === '-1' ? vm.model.setting.dateselector_index : dateInterval;
                
                if (vm.model.setting.date_fom) rememberFunc('date_fom');

                if (vm.model.setting.date_tom) rememberFunc('date_tom');

                if (vm.model.setting.dateselector_index && vm.model.setting.dateselector_index !== -1) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadStockHistories();
                }
            };

		    // #endregion DATE RANGE FIELD CHANGE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
    	}]
    });
})();
