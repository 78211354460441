(function () {
    'use strict';
    
    let module = angular.module('imApp');

    module.component('about', {
        templateUrl: 'views/components/views/about/about.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$location', 'aboutComponentService', function ($location, aboutComponentService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;

            vm.model = {
                edit: {}
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            aboutComponentService.getAbout({ url: $location.absUrl() }).then(function (result) {
                angular.copy(result[0], vm.model.edit);
                vm.model.edit.client_version = module.version;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            vm.refreshChanges = function () {
                window.location.reload(true);
            };
        }]
    });
})();
