(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('pohTops', {
        templateUrl: 'views/components/views/pohTops/pohTops.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'utilityService', 'stateService', 'rememberService', 'translateService', 'modalService', 'ieScreenBlockService', 'pohTopsService', function ($stateParams, $timeout, $q, utilityService, stateService, rememberService, translateService, modalService, ieScreenBlockService, pohTopsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            let variableNames = {
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

    		vm.model = {
    			argType: $stateParams.argtype,
    			argValue: $stateParams.argvalue,
                bgPohTops: {
                    selected: 'SHOW',
                    buttons: [
                        { id: 'SHOW', label: 'bg_pohtops_show', color: 'primary', onClick: () => vm.model.bgPohTops.selected = 'SHOW' },
                        { id: 'SELECT', label: 'bg_pohtops_select', color: 'primary', onClick: () => vm.model.bgPohTops.selected = 'SELECT' }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filterval = '' }
                ],
    			setting: {},
    			selectListPoStatus: [],
    			selectListPoStatusNew: [],
    			itemsListPohTops: [],
    			progress: '',
    			progressInfo: {
    				label: '',
    				plabel: '',
    				field: '',
    				remField: '',
    				min: 0,
    				max: 100,
    				step: 0,
    				finishclose: ''
    			},
    			showChecked: false
    		};

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

    		// ## LOAD PROCEDURE CALLS ##
            
    		pohTopsService.loadPohTopsGet(vm.model.argType, vm.model.argValue).then(function (data) {
    			vm.model.setting = angular.copy(data[0]);
    			loadPostStatusSelect();
    			loadPohTops();
    		});
    
    		pohTopsService.loadPostStatusSelectList('1').then(function (data) {
    			angular.copy(data, vm.model.selectListPoStatus);
    		});
    
    		var loadPostStatusSelect = function () {
    			if (utilityService.validateParmsValue(vm.model.setting.postatus_no_new) !== true) return;
    
    			pohTopsService.loadPostStatusSelectList('nochange').then(function (data) {
    				angular.copy(data, vm.model.selectListPoStatusNew);
    			});
    		};
    
    		var loadPohTops = function () {
    			if (angular.isUndefined(vm.model.setting.date_fom) === true) return;
    			if (vm.model.setting.date_fom === null) return;
    			if (vm.model.setting.date_fom.trim().length < 1) return;
    
    			if (angular.isUndefined(vm.model.setting.date_tom) === true) return;
    			if (vm.model.setting.date_tom === null) return;
    			if (vm.model.setting.date_tom.trim().length < 1) return;
    
    			if (angular.isUndefined(vm.model.setting.dateselector_index) === true) return;
    			if (vm.model.setting.dateselector_index === null) return;
    			if (vm.model.setting.dateselector_index.trim().length < 1) return;
    
    			if (angular.isUndefined(vm.model.setting.postatus_no) === true) return;
    			if (vm.model.setting.postatus_no === null) return;
    
    			if (angular.isUndefined(vm.model.setting.filterval) === true) return;
    			if (vm.model.setting.filterval === null) return;
    
    			ieScreenBlockService.start();
    			
    			vm.model.itemsListPohTops = [];
    
    			pohTopsService.loadPohTopsList(vm.model.setting).then(function (data) {
    				angular.copy(data, vm.model.itemsListPohTops);
    
    				for (var i = 0; i < vm.model.itemsListPohTops.length; i++) {
    					if (angular.isDefined(vm.model.itemsListPohTops[i].item_is_selected) && vm.model.itemsListPohTops[i].item_is_selected === '0') {
    						vm.model.showChecked = false;
    					}
    				}
    
    				ieScreenBlockService.stop();
    			});
    		};

    		var loadPohTopsNewStatus = function () {
    			if (utilityService.validateParmsValue(vm.model.setting.postatus_no_new) !== true) return;
    
                pohTopsService.setPohTopsNewStatus(vm.model.setting.postatus_no_new).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
    					loadPohTops();
                    }
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            var rememberSelectedItems = function () {
                var variableNameSelectedItems = 'w_pohtops.selected_items';
                var selectedItems = '';

                if (angular.isDefined(vm.model.itemsListPohTops)) {
                    for (var i = 0; i < vm.model.itemsListPohTops.length; i++) {
                        if (angular.isDefined(vm.model.itemsListPohTops[i].item_is_selected) && vm.model.itemsListPohTops[i].item_is_selected === '1') {
                            if (selectedItems.length > 1) {
                                selectedItems += ',';
                            }
                            selectedItems += vm.model.itemsListPohTops[i].item_id;
                        }
                    }
                }

                if (utilityService.validateParmsValue(variableNameSelectedItems) !== true) return;
                if (utilityService.validateParmsValue(selectedItems, true) !== true) return;

                rememberService.remember(variableNameSelectedItems, selectedItems).then(function (data) {
                    loadPohTopsNewStatus();
                });
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
    		function addPohTops(item) {
    			var deferred = $q.defer();
    
    			pohTopsService.loadPohTopsCheckOrder(item.item_id).then(function (data) {
    				angular.forEach(vm.model.itemsListPohTops, function (poh) {
    					if (poh.item_id === item.item_id) { // && item.item_id !== '9999'
    						poh.item_glyphicon = data[0].item_glyphicon;
    						poh.item_glyphicon_color = data[0].item_glyphicon_color;
    					}
    				});
    
    				deferred.resolve(data);
    			});
    
    			return deferred.promise;
    		}
    
    		vm.checkOrdersProgress = function (items) {
    			var increment = 1, skip = 0, failed = 0, step = 0;
    			vm.model.progressInfo.step = 0;
    
    			var doStep = function () {
    				$timeout(function () {
    					modalService.progressInfo.finishclose = '0';
    					if (vm.model.progress === 'ABORT') return;
    					if (angular.isUndefined(items[step])) return;
    
    					if (items[step].item_id === '-1' || items[step].item_id === '0') { // || items[step].item_id === '9999'
    						skip++;
    						step = vm.model.progressInfo.step + skip + failed;
    						doStep();
    					} else if (angular.isDefined(vm.model.setting.filterval) && vm.model.setting.filterval !== null && vm.model.setting.filterval !== ''
    							&& angular.isDefined(items[step].item_filtervalue) && items[step].item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === -1) {
    						skip++;
    						step = vm.model.progressInfo.step + skip + failed;
    						doStep();
    					} else {
    						step = vm.model.progressInfo.step + skip + failed;
    						if (angular.isUndefined(items[step])) return;
    						vm.model.progressInfo.field = items[step].item_id + ': ' + items[step].item_name;
    						modalService.progressInfo.field = vm.model.progressInfo.field;
                            
    						addPohTops(items[step]).then(function () {
    							vm.model.progressInfo.step += increment;
    							modalService.progressInfo.step = vm.model.progressInfo.step;
    							modalService.progressInfo.finishclose = '1';
                                
    							doStep();
    						});
    					}
    				});
    			};
    			doStep();
    		};
    
    		vm.checkOrders = function () {
    			console.log('Knappen er trykt ned og vi starter loop');
    			vm.model.progress = 'START';
    			vm.model.progressInfo.label = 'progressbar';
    			vm.model.progressInfo.field = '';
    			vm.model.progressInfo.max = 0;
    			vm.model.progressInfo.step = 0;
    
    			var filteredList = [];
    
    			if (angular.isDefined(vm.model.setting.filterval) && vm.model.setting.filterval !== null && vm.model.setting.filterval !== '') {
    				angular.forEach(vm.model.itemsListPohTops, function (item) {
    					if (item.item_id !== '-1' && item.item_id !== '0' // && item.item_id !== '9999'
    					&& angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) !== -1) {
    						vm.model.progressInfo.max++;
    						filteredList.push(item);
    					}
    				});
    
    				vm.checkOrdersProgress(filteredList);
    			} else {
    				angular.forEach(vm.model.itemsListPohTops, function (item) {
    					if (item.item_id !== '-1' && item.item_id !== '0') { // && item.item_id !== '9999'
    						vm.model.progressInfo.max++;
    					}
    				});
    
    				vm.checkOrdersProgress(vm.model.itemsListPohTops);
    			}
    
    			modalService.showProgress(vm.model.progressInfo).then(function (value) {
    				vm.model.progress = value;
    			});
    		};
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPohTops = function (value) {
                vm.model.bgPohTops.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// ## TOGGLE FUNCTIONS ##
            
    		vm.toggleChosenPohTops = function (item) {
                if (utilityService.validateParmsValue(item.item_id) !== true) return;
    			
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
    
    		vm.selectAllPohTops = function () {
    			angular.forEach(vm.model.itemsListPohTops, function (item) {
    				if (utilityService.validateParmsValue(item.item_filtervalue, true) !== true) return;
    				if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === '-1') return;
    				if (utilityService.validateParmsValue(item.item_id) !== true) return;
    
    				item.item_is_selected = '1';
    			});
    		};
    
    		vm.unselectAllPohTops = function () {
    			angular.forEach(vm.model.itemsListPohTops, function (item) {
    				if (utilityService.validateParmsValue(item.item_filtervalue, true) !== true) return;
    				if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filterval.toLowerCase()) === '-1') return;
    				if (utilityService.validateParmsValue(item.item_id) !== true) return;
    
    				item.item_is_selected = '0';
    			});
    		};
    
    		// ## CHECK & UNCHECK ALL FUNCTION ##
    
    		vm.checkButtonClick = function () {
    			if (!vm.model.showChecked) {
    				vm.model.showChecked = true;
    				vm.selectAllPohTops();
    			} else {
    				vm.model.showChecked = false;
    				vm.unselectAllPohTops();
    			}
    		};
    
            // ## SET NEW STATUS BUTTON FUNCTION ##
    
    		vm.setNewStatus = function () {
    			rememberSelectedItems();
            };

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    //vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadPohTops();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'postatus_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.postatus_no) !== true) return;

                        vm.model.setting.postatus_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.postatus_no) !== true) return;

                        loadPohTops();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
    	}]
    });
})();
