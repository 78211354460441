(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('carrier', {
        templateUrl: 'views/components/views/carrier/carrier.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'carrierService', 'typeaheadService', 'translateService', function ($stateParams, stateService, modalService, carrierService, typeaheadService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var carrierKeyno = $stateParams.carrier_keyno;

            var translations = {
                carrier_save_show_title: 'Varsel',
                carrier_save_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'product':
                        carrierService.saveObj(vm.model.edit).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.carrier_save_show_title,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.carrier_save_show_ok_label,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                stateService.go(state, { prod_id: vm.model.edit.prod_id });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                prodIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'product' } }
                ],
                edit: {},
                lockedSave: false
            };

            vm.options = {
                weight: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                },
                measure: {
                    autoselect: true,
                    decimals: 1,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            carrierService.getCarrier({ carrier_keyno: carrierKeyno }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchProduct({ prod_name: value || '' });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SET VALUE FUNCTION

            vm.setProdId = function (item) {
                vm.model.edit.prod_id = angular.isUndefined(item) ? '' : item.item_id;
                vm.model.edit.prod_name = angular.isUndefined(item) ? '' : item.item_name;
            };

		    // #endregion SET VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                carrierService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.carrier_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.carrier_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    translateService.translateBatch(translations).then(function (data) {
			    angular.forEach(translations, function (_, key) {
				    if (angular.isDefined(data[key])) {
					    translations[key] = data[key];
				    }
			    });
		    });

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();