(function() {
    'use strict';

    angular.module("imApp").factory("ttWebpagemenuModalsService", ['$ihttp', function ($ihttp) {
        var service = {
            newWebpage: function (webpage) {
                webpage.state = 'new';

                return $ihttp.post({
                    method: 2400,
                    parameters: webpage
                });
            },

            deleteWebpage: function (p2_webpage_keyno) {
                return $ihttp.post({
                    method: 2400,
                    parameters: {
                        state: 'delete',
                        p2_webpage_keyno: p2_webpage_keyno
                    }
                });
            },

            newLinkInit: function (webpage_name_belongto) {
                return $ihttp.post({
                    method: 2401,
                    parameters: {
                        state: 'init',
                        webpage_name_belongto: webpage_name_belongto || ''
                    }
                });
            },

            newLink: function (webpagelink) {
                webpagelink.state = 'new'

                return $ihttp.post({
                    method: 2401,
                    parameters: webpagelink
                });
            },
        }

        return service;
    }]);
})();
