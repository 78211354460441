(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productLinks', {
        templateUrl: 'views/components/views/productLinks/productLinks.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'rememberService', 'productLinksService', function ($stateParams, $q, stateService, utilityService, rememberService, productLinksService) {
            
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let productLinkTypeKeyNo = $stateParams.productlinktype_keyno;

            let variableNames = {
                bg_productlinks: ''
            };

            vm.model = {
                bgProductLinks: {
                    selected: 'GOTO',
                    buttons: [
                        { id: 'GOTO', label: 'bg_productlinks_goto', color: 'primary', onClick: () => setProductLinksView('GOTO') },
                        { id: 'EDIT', label: 'bg_productlinks_edit', color: 'primary', onClick: () => setProductLinksView('EDIT') }
                    ]
                },
                setting: {},
                itemsListLinks: []
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            let setProductLinksView = function (id) {
                vm.model.bgProductLinks.selected = vm.model.setting.bg_productlinks = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();

                productLinksService.getProductLinks({
                    argtype: argType,
                    argvalue: argValue,
                    productlinktype_keyno: productLinkTypeKeyNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setProductLinksView(vm.model.setting.bg_productlinks);

                    $q.all([
                        loadProductLinks()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadProductLinks = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bg_productlinks) !== true) return;
                
                vm.model.itemsListLinks = [];
                
                return productLinksService.listProductLinks(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListLinks);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bg_productlinks':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'productlinkschange':
                        go({
                            argtype: vm.model.setting.argtype,
                            argvalue: vm.model.setting.argvalue,
                            productlinktype_keyno: vm.model.setting.productlinktype_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgProductLinks = function (value) {
                vm.model.bgProductLinks.selected = value;
                vm.model.setting.bg_productlinks = vm.model.bgProductLinks.selected;

                rememberFunc('bg_productlinks');
                loadProductLinks();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
