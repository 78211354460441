(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishProdList', {
        templateUrl: 'views/components/views/poFinishProdList/poFinishProdList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishProdListService', 'stateService', function ($stateParams, poFinishProdListService, stateService) {

            //##########################
            //## VARIABLES & DEFINITIONS
            //##########################

            var vm = this;
            var isSaving = false;
            
            vm.filter_p2_pofinishprod_add = {
                barcode: '',
                locked: '0',
                records: []
            };
            
            vm.state = {
                loading: false
            };
            
            //##################
            //## PROCEDURE CALLS
            //##################
            
            poFinishProdListService.loadP2PoFinishProdList($stateParams.p2_pofinish_keyno).then(function () {
                vm.p2_pofinishprod_list = poFinishProdListService.p2_pofinishprod_list;
            });
            
            poFinishProdListService.loadP2PoFinishStatus($stateParams.p2_pofinish_keyno).then(function () {
                vm.p2_pofinish_status = poFinishProdListService.p2_pofinish_status;
            });
            
            //#################################
            //## SCAN FIELD AND BUTTON FUNCTION
            //#################################
            
            vm.scanP2PoFinishProdAdd = function () {
                if (angular.isUndefined(vm.filter_p2_pofinishprod_add.barcode) || vm.filter_p2_pofinishprod_add.barcode.length == 0) return;

                vm.state.loading = true;

                vm.filter_p2_pofinishprod_add.records.unshift({
                    barcode: vm.filter_p2_pofinishprod_add.barcode
                });

                poFinishProdListService.scanP2PoFinishProdAdd($stateParams.p2_pofinish_keyno, vm.filter_p2_pofinishprod_add.records[0].barcode).then(function () {
                    poFinishProdListService.loadP2PoFinishProdList($stateParams.p2_pofinish_keyno).then(function () {
                        vm.p2_pofinishprod_list = poFinishProdListService.p2_pofinishprod_list;
                    });

                    vm.state.loading = false;

                    vm.clearField();
                });
            };
            
            vm.clearField = function () {
                if (vm.filter_p2_pofinishprod_add.barcode !== '' && vm.filter_p2_pofinishprod_add.records.length > 0 && vm.state.loading == false) {
                    vm.filter_p2_pofinishprod_add.barcode = '';
                }
            };
            
            //###############################################
            //## DIRECTION BUTTON TO POFINISHPRODCOMPLISTACCS
            //###############################################
            
            vm.goToPoFinishProdCompListAccs = function (index) {
                stateService.go('pofinishprodcomplistaccs', {
                    p2_pofinish_keyno: $stateParams.p2_pofinish_keyno
                });
            };
            
            //###############################
            //## PRODUCTION ORDER SAVE BUTTON
            //###############################
            
            vm.finishP2PoFinish = function () {
                if (isSaving == true || vm.filter_p2_pofinishprod_add.locked == '1') return;

                isSaving = true;
                vm.filter_p2_pofinishprod_add.locked = '1';

                poFinishProdListService.finishP2PoFinish().then(function () {
                    vm.p2_pofinish_finish = poFinishProdListService.p2_pofinish_finish;

                    if (angular.isDefined(vm.p2_pofinish_finish)) {
                        if (vm.p2_pofinish_finish.webpagename === 'pofinishprodpack') {
                            stateService.go(vm.p2_pofinish_finish.webpagename,
                                {
                                    poh_keyno_top: vm.p2_pofinish_finish.webpage_parms.replace('/', ''),
                                    counter: 0
                                });
                        } else if (vm.p2_pofinish_finish.webpagename === 'createcase') {
                            stateService.go(vm.p2_pofinish_finish.webpagename, vm.p2_pofinish_finish.webpage_parms);;
                        } else {
                            stateService.back('/' + vm.p2_pofinish_finish.webpagename + '/' + vm.p2_pofinish_finish.webpage_parms);
                        }
                    }
                });

                isSaving = false;
                //vm.filter_p2_pofinishprod_add.locked = '0';
            };
            
            // #region ABORT BUTTON FUNCTION
            
            vm.abortTask = function () {
                poFinishProdListService.abortPoFinish({ p2_pofinish_keyno: $stateParams.p2_pofinish_keyno }).then(function (data) {
                    stateService.back('/' + data[0].webpagename + '/' + data[0].webpage_parms);
                });
            };
            
    		// #endregion ABORT BUTTON FUNCTION
        }]
    });
})();