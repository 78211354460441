(function() {
    'use strict';

    angular.module("imApp").factory("ttButtonactionEditService", ['$ihttp', function ($ihttp) {
        var service = {
            init: function (keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initeditbuttonaction',
                        tt_dynamicproperty_keyno: keyno
                    }
                });
            }
        }

        return service;
    }]);
})();
