(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("serviceObjectTypeIntService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadServiceObjectTypeIntGet: function (serviceobjecttype_keyno, serviceobjecttypeint_keyno) {
                return p2DataTaskService.call(1745, {
                    serviceobjecttype_keyno: serviceobjecttype_keyno,
                    serviceobjecttypeint_keyno: serviceobjecttypeint_keyno
                });
            },
            save: function (interval) {
                return p2DataTaskService.call(1746, interval);
            },
            deleteObj: function (serviceobjecttypeint_keyno) {
                return p2DataTaskService.call(1747, {
                    serviceobjecttypeint_keyno: serviceobjecttypeint_keyno
                });
            }
        };

        return service;
    }]);
})();