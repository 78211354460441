(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('docPackDocAdd', {
        templateUrl: 'views/components/views/docPackDocAdd/docPackDocAdd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'docPackDocAddService', 'ttGridFactory', 'rememberService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, docPackDocAddService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let docPackChapterKeyno = $stateParams.docpackchapter_keyno;

            let variableNames = {
                gb_docpackdocadd: ''
            };

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                bgDocPackAdd: {
                    selected: 'PROJECT',
                    buttons: [
                        { id: 'PROJECT', label: 'gb_docpackdocadd_project', color: 'primary', onClick: () => setDocPackAddView('PROJECT') },
                        { id: 'CERTIFICATE', label: 'gb_docpackdocadd_certificate', color: 'primary', onClick: () => setDocPackAddView('CERTIFICATE') },
                        { id: 'SKILL', label: 'gb_docpackdocadd_skill', color: 'primary', onClick: () => setDocPackAddView('SKILL') },
                        { id: 'SEARCH', label: 'gb_docpackdocadd_search', color: 'primary', onClick: () => setDocPackAddView('SEARCH') }
                    ]
                },
                docArcSearchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('docarc_searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadGridDocArcSearch() }
                ],
                setting: {},
                gridProjectsReady: false,
                gridStockBatchesReady: false,
                gridDocumentsReady: false,
                gridLaboursReady: false,
                gridLabourDocumentsReady: false,
                gridDocArcSearchReady: false
            };

            vm.gridProjects = new ttGridFactory({
                rememberId: 'w_docpackdocadd_projectdocs_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([{
                    name: 'docpackdocadd_add',
                    text: 'docpackdocadd_add',
                    func: function () {
                        let currentData = vm.gridProjects.gridfunc.getDirtyRows();
                        let selectedItems = [];

                        angular.forEach(currentData, function (item) {
                            if (item.is_selected === '0' || item.is_selected === false) return;

                            selectedItems.push({ docarc_keyno: item.docarc_keyno });
                        });

                        let mergeObj = angular.copy(vm.model.setting);
                        mergeObj.records = selectedItems;

                        docPackDocAddService.addItems(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                stateService.back();
                            }
                        });
                    },
                    icon: 'fa-plus',
                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                    translate: true
                }]);

            vm.gridStockBatches = new ttGridFactory({
                rememberId: 'w_docpackdocadd_docpackstockbatch_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([
                    {
                        name: 'docpackdocadd_docpacksbadd',
                        text: 'docpackdocadd_docpacksbadd',
                        func: function () {
                            stateService.go('docpacksbadd', { docpack_keyno: vm.model.setting.docpack_keyno });
                        },
                        icon: 'fa-plus',
                        cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                        translate: true
                    }, {
                        name: 'docpackdocadd_docpackstockbatch_update',
                        text: 'docpackdocadd_docpackstockbatch_update',
                        func: function () {
                            docPackDocAddService.updateItems(vm.model.setting).then(function (response) {
                                if (response[0].errorcode !== '0') {
                                    modalService.show({
                                        type: 'warning',
                                        title: translations.error,
                                        message: response[0].errormessage,
                                        buttons: [{
                                            label: translations.ok,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                } else {
                                    loadGridStockBatches();
                                }
                            });
                        },
                        icon: 'fa-sync-alt',
                        cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                        translate: true
                    }, {
                        name: 'docpackdocadd_docpackstockbatch_delete',
                        text: 'docpackdocadd_docpackstockbatch_delete',
                        func: function () {
                            let currentData = vm.gridStockBatches.gridfunc.getDirtyRows();
                            let selectedItems = [];

                            angular.forEach(currentData, function (item) {
                                if (item.is_selected === '0' || item.is_selected === false) return;

                                selectedItems.push({ docpackstockbatch_keyno: item.docpackstockbatch_keyno });
                            });

                            let mergeObj = angular.copy(vm.model.setting);
                            mergeObj.records = selectedItems;

                            modalService.confirm({
                                type: 'danger',
                                title: translations.confirm_title,
                                message: translations.confirm_message,
                                okLabel: translations.confirm_ok_label,
                                cancelLabel: translations.confirm_cancel_label
                            }).then(function () {
                                docPackDocAddService.deleteItems(mergeObj).then(function (response) {
                                    if (response[0].errorcode !== '0') {
                                        modalService.show({
                                            type: 'warning',
                                            title: translations.error,
                                            message: response[0].errormessage,
                                            buttons: [{
                                                label: translations.ok,
                                                cssClass: 'btn-warning',
                                                action: function (dialogItself) {
                                                    dialogItself.close();
                                                }
                                            }]
                                        });
                                    } else {
                                        loadGridStockBatches();
                                        loadGridDocuments();
                                    }
                                });
                            });
                        },
                        icon: 'fa-trash-alt',
                        cssClass: 'btn btn-danger im-grid-btn-xs-r',
                        translate: true
                    }
                ]);

            vm.gridDocuments = new ttGridFactory({
                rememberId: 'w_docpackdocadd_stockbatchdocs_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([{
                    name: 'docpackdocadd_stockbatchdoc_add',
                    text: 'docpackdocadd_stockbatchdoc_add',
                    func: function () {
                        let currentData = vm.gridDocuments.gridfunc.getDirtyRows();
                        let selectedItems = [];

                        angular.forEach(currentData, function (item) {
                            if (item.is_selected === '0' || item.is_selected === false) return;

                            selectedItems.push({ docarc_keyno: item.docarc_keyno });
                        });

                        let mergeObj = angular.copy(vm.model.setting);
                        mergeObj.records = selectedItems;

                        docPackDocAddService.addDocuments(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                stateService.back();
                            }
                        });
                    },
                    icon: 'fa-plus',
                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                    translate: true
                }]);

            vm.gridLabours = new ttGridFactory({
                rememberId: 'w_docpackdocadd_docpacklabour_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([
                    {
                        name: 'docpackadd_docpacklabour_update',
                        text: 'docpackadd_docpacklabour_update',
                        func: function () {
                            docPackDocAddService.updateLabours(vm.model.setting).then(function (response) {
                                if (response[0].errorcode !== '0') {
                                    modalService.show({
                                        type: 'warning',
                                        title: translations.error,
                                        message: response[0].errormessage,
                                        buttons: [{
                                            label: translations.ok,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                } else {
                                    loadGridLabours();
                                    loadGridLabourDocuments();
                                }
                            });
                        },
                        icon: 'fa-sync-alt',
                        cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                        translate: true
                    }, {
                        name: 'docpackdocadd_docpacklabour_delete',
                        text: 'docpackdocadd_docpacklabour_delete',
                        func: function () {
                            let currentData = vm.gridLabours.gridfunc.getDirtyRows();
                            let selectedItems = [];

                            angular.forEach(currentData, function (item) {
                                if (item.is_selected === '0' || item.is_selected === false) return;

                                selectedItems.push({ docpacklabour_keyno: item.docpacklabour_keyno });
                            });

                            let mergeObj = angular.copy(vm.model.setting);
                            mergeObj.records = selectedItems;

                            modalService.confirm({
                                type: 'danger',
                                title: translations.confirm_title,
                                message: translations.confirm_message,
                                okLabel: translations.confirm_ok_label,
                                cancelLabel: translations.confirm_cancel_label
                            }).then(function () {
                                docPackDocAddService.deleteLabours(mergeObj).then(function (response) {
                                    if (response[0].errorcode !== '0') {
                                        modalService.show({
                                            type: 'warning',
                                            title: translations.error,
                                            message: response[0].errormessage,
                                            buttons: [{
                                                label: translations.ok,
                                                cssClass: 'btn-warning',
                                                action: function (dialogItself) {
                                                    dialogItself.close();
                                                }
                                            }]
                                        });
                                    } else {
                                        loadGridLabours();
                                        loadGridLabourDocuments();
                                    }
                                });
                            });
                        },
                        icon: 'fa-trash-alt',
                        cssClass: 'btn btn-danger im-grid-btn-xs-r',
                        translate: true
                    }
                ]);

            vm.gridLabourDocuments = new ttGridFactory({
                rememberId: 'w_docpackdocadd_labourdocs_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([{
                    name: 'docpackdocadd_labourdocs_add',
                    text: 'docpackdocadd_labourdocs_add',
                    func: function () {
                        let currentData = vm.gridLabourDocuments.gridfunc.getDirtyRows();
                        let selectedItems = [];

                        angular.forEach(currentData, function (item) {
                            if (item.is_selected === '0' || item.is_selected === false) return;

                            selectedItems.push({ docarc_keyno: item.docarc_keyno });
                        });

                        let mergeObj = angular.copy(vm.model.setting);
                        mergeObj.records = selectedItems;

                        docPackDocAddService.addLabourDocuments(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                stateService.back();
                            }
                        });
                    },
                    icon: 'fa-plus',
                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                    translate: true
                }]);

            vm.gridDocArcSearch = new ttGridFactory({
                rememberId: 'w_docpackdocadd_docarcsearch_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([{
                    name: 'docpackdocadd_docarcdoc_add',
                    text: 'docpackdocadd_docarcdoc_add',
                    func: function () {
                        let currentData = vm.gridDocArcSearch.gridfunc.getDirtyRows();
                        let selectedItems = [];

                        angular.forEach(currentData, function (item) {
                            if (item.is_selected === '0' || item.is_selected === false) return;

                            selectedItems.push({ docarc_keyno: item.docarc_keyno });
                        });

                        let mergeObj = angular.copy(vm.model.setting);
                        mergeObj.records = selectedItems;

                        docPackDocAddService.addDocArcs(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                stateService.back();
                            }
                        });
                    },
                    icon: 'fa-plus',
                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                    translate: true
                }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setDocPackAddView = function (id) {
                vm.model.bgDocPackAdd.selected = vm.model.setting.gb_docpackdocadd = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

            	docPackDocAddService.getSetting({ docpackchapter_keyno: docPackChapterKeyno }).then(function (info) {
            		angular.copy(info[0], vm.model.setting);
                    setDocPackAddView(vm.model.setting.gb_docpackdocadd);

                    loadGridProjects();
                    loadGridStockBatches();
                    loadGridDocuments();
                    loadGridLabours();
                    loadGridLabourDocuments();
                    loadGridDocArcSearch();

            		deferred.resolve();
            	});

            	return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridProjects = function () {
                vm.gridProjects.dataTask.loadData = {
                    method: 3294,
                    parameters: vm.model.setting
                };

                vm.model.gridProjectsReady = true;

                if (vm.gridProjects.gridfunc !== null) vm.gridProjects.gridfunc.read();
            };

            let loadGridStockBatches = function () {
                vm.gridStockBatches.dataTask.loadData = {
                    method: 3296,
                    parameters: vm.model.setting
                };


                vm.model.gridStockBatchesReady = true;

                if (vm.gridStockBatches.gridfunc !== null) vm.gridStockBatches.gridfunc.read();
            };

            let loadGridDocuments = function () {
                vm.gridDocuments.dataTask.loadData = {
                    method: 3301,
                    parameters: vm.model.setting
                };

                vm.model.gridDocumentsReady = true;

                if (vm.gridDocuments.gridfunc !== null) vm.gridDocuments.gridfunc.read();
            };

            let loadGridLabours = function () {
                vm.gridLabours.dataTask.loadData = {
                    method: 3304,
                    parameters: vm.model.setting
                };

                vm.model.gridLaboursReady = true;

                if (vm.gridLabours.gridfunc !== null) vm.gridLabours.gridfunc.read();
            };

            let loadGridLabourDocuments = function () {
                vm.gridLabourDocuments.dataTask.loadData = {
                    method: 3307,
                    parameters: vm.model.setting
                };

                vm.model.gridLabourDocumentsReady = true;

                if (vm.gridLabourDocuments.gridfunc !== null) vm.gridLabourDocuments.gridfunc.read();
            };

            let loadGridDocArcSearch = function () {
                vm.gridDocArcSearch.dataTask.loadData = {
                    method: 3309,
                    parameters: vm.model.setting
                };

                vm.model.gridDocArcSearchReady = true;

                if (vm.gridDocArcSearch.gridfunc !== null) vm.gridDocArcSearch.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'gb_docpackdocadd':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '.' + vm.model.setting.docpack_keyno;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_docpackdocadd':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'docarc_searchtext':
                        vm.model.setting.docarc_searchtext = '';

                        if (vm.model.setting.docarc_searchtext === '') loadGridDocArcSearch();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.searchGrid = function () {
                loadGridDocArcSearch();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'docarc_searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.docarc_searchtext) !== true) return;

                        vm.model.setting.docarc_searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.docarc_searchtext) !== true) return;

                        if (vm.model.setting.docarc_searchtext === '') loadGridDocArcSearch();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'docarc_searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.docarc_searchtext) !== true) return;

                        if (vm.model.setting.docarc_searchtext > '') loadGridDocArcSearch();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgDocPackAdd = function (value) {
                vm.model.bgDocPackAdd.selected = value;
                vm.model.setting.gb_docpackdocadd = vm.model.bgDocPackAdd.selected;

                rememberFunc('gb_docpackdocadd');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
