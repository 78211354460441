(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactSearchService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            p2ActivityTypeList: {
                records: []
            },
            loadP2ActivityTypeList: function (extra_record_for_all, custacttype) {
                var deferred = $q.defer();

                var parmsP2ActivityTypeList = {
                    method: 49,
                    parameters: {
                        extra_record_for_all: extra_record_for_all,
                        only_deviation: custacttype === 'D'? 1 : 0
                    }
                };

                $ihttp.post(parmsP2ActivityTypeList).then(function (data) {

                    angular.copy(data, service.p2ActivityTypeList.records);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadCustactSearchOkList: function () {
                return p2DataTaskService.call(1418, {});
            },
            loadPortalUserSubCrmList: function (add_all) {
                return p2DataTaskService.call(842, {
                    add_all: add_all
                });
            },
            loadActStatusList: function (activitytype_no, add_all) {
                return p2DataTaskService.call(50, {
                    activitytype_no: activitytype_no,
                    add_all: add_all
                });
            },
            listSubCrms: function (parms) {
                return $ihttp.post({
                    method: 842,
                    parameters: parms || {}
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadCustactFindList: function (searchtext, ok, filterfield, filtervalue, custacttype, acttype, activitytype_no, actstatus_keyno, bg_main, bg_search, member_portal_user_keyno) {
                return p2DataTaskService.call(54, {
                    searchtext: searchtext,
                    ok: ok,
                    filterfield: filterfield,
                    filtervalue: filtervalue,
                    custacttype: custacttype,
                    acttype: acttype,
                    activitytype_no: activitytype_no,
                    actstatus_keyno: actstatus_keyno,
                    bg_main: bg_main,
                    bg_search: bg_search,
                    member_portal_user_keyno: member_portal_user_keyno
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            save: function (custact_no, actstatus_keyno, listToIndex) {
                console.log(custact_no);
                console.log(actstatus_keyno);
                console.log(listToIndex);
                return p2DataTaskService.call(1417, {
                    custact_no: custact_no,
                    actstatus_keyno: actstatus_keyno,
                    listToIndex: listToIndex
                });
            }
        };

        return service;
    }]);
})();
