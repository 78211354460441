(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('activityTypeDevType', {
        templateUrl: 'views/components/views/activityTypeDevType/activityTypeDevType.template.html?v=' + module.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'activityTypeDevTypeService', function($stateParams, stateService, activityTypeDevTypeService) {
			var vm = this;

            vm.model = {
                activityTypeDevTypeKeyno: $stateParams.activitytypedevtype_keyno,
                deviation: {},
                lockedSave: false
            };

            activityTypeDevTypeService.loadActivityTypeDevTypeGet(vm.model.activityTypeDevTypeKeyno).then(function (data) {
                vm.model.deviation = angular.copy(data[0]);
            });

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                activityTypeDevTypeService.save(vm.model.deviation).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                });
            };
		}]
	});
})();