(function () {
    'use strict';

    angular.module('imApp').directive('ttPackeryControls', ['$rootScope', 'stateService', 'p2WebpageService', 'utilityService', 'layoutService', 'eventService', function ($rootScope, stateService, p2WebpageService, us, layoutService, eventService) {
        var directive = {
            restrict: 'A',
            template:
                '<div class="col-xs-12 sp-0" ng-if="piModel.showLayoutControls">' +
                    '<button class="sp-5 pull-right tt-item-control" style="border: 0;" ng-click="onClick(\'right\')"><span class="fa fa-chevron-right" ng-style="piModel.iconStyle"></span></button>' +
                    '<button class="sp-5 pull-right tt-item-control" style="border: 0;" ng-click="onClick(\'left\')"><span class="fa fa-chevron-left" ng-style="piModel.iconStyle"></span></button>' +
                '</div>',
            link: function ($scope, _, $attr) {
                $scope.piModel = {
                    iconStyle: {},
                    showLayoutControls: false
                };

                var userCanEditLayout = false;
                var showLayoutControls = false;

                layoutService.onLayoutChanged($scope, function (info) {
                    $scope.piModel.iconStyle.fontSize = info.fontSizes.textSize;

                    userCanEditLayout = info.userCanEditLayout;

                    $scope.piModel.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                var dereg1 = $rootScope.$on('p2WebpageService:showLayoutControlsChanged', function (data) {
                    showLayoutControls = us.toBoolean(data.show_layout_controls);

                    $scope.piModel.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                var init = function () {
                    var stateName = stateService.getCurrentName();

                    p2WebpageService.details(stateName).then(function (response) {
                        showLayoutControls = us.toBoolean(response.show_layout_controls);

                        $scope.piModel.showLayoutControls = userCanEditLayout && showLayoutControls;
                    });

                    var parts = $attr.ttPackeryControls.split('.');

                    if (parts.length !== 2) {
                        console.error('You must provide the tt-packery-controls with a two part id separated by ".".')
                    }

                    var groupId = parts[0];
                    var itemId = parts[1];

                    $scope.onClick = function (direction) {
                        eventService.trigger('packery:moveItem', { groupId: groupId, itemId: itemId, direction: direction === 'left' ? 'up' : 'down' });
                    };
                };

                if (angular.isString($attr.ttPackeryControls) && $attr.ttPackeryControls.length > 0) {
                    init();
                } else {
                    var dereg2 = $scope.$watch(function () { return $attr.ttPackeryControls; }, function () {
                        if (angular.isFunction(dereg2)) {
                            dereg2();
                        }

                        init();
                    });
                }

                $scope.$on('$destroy', function () {
                    if (angular.isFunction(dereg1)) {
                        dereg1();
                    }
                });
            }
        };

        return directive;
    }]);
})();