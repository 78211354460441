(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('checkInMenu', {
		templateUrl: 'views/components/views/checkInMenu/checkInMenu.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'checkInMenuService', 'stateService', function($stateParams, checkInMenuService, stateService) {
			var vm = this;

            vm.model = {
                checkInOut: {
                    in: 'I',
                    out: 'O'
                },
                p2WCheckInOutPortalUser: {},
                p2WCheckInOutSetting: {}
            };

            var loadP2WCheckInOutSetting = function () {
                checkInMenuService.loadP2WCheckInOutSetting($stateParams.argtype, $stateParams.argvalue).then(function () {
                    vm.model.p2WCheckInOutSetting = checkInMenuService.p2WCheckInOutSetting;
                });
            };

            loadP2WCheckInOutSetting();

            vm.checkIn = function () {
                navigator.geolocation.getCurrentPosition(function (data) {
                    checkInMenuService.loadP2WCheckInOutPortalUser(vm.model.checkInOut.in, $stateParams.argtype, $stateParams.argvalue, data.coords.latitude, data.coords.longitude, data.coords.accuracy).then(function () {
                        vm.model.p2WCheckInOutPortalUser = checkInMenuService.p2WCheckInOutPortalUser;
                        loadP2WCheckInOutSetting();
                    });
                });
            };

            vm.checkOut = function () {
                navigator.geolocation.getCurrentPosition(function (data) {
                    checkInMenuService.loadP2WCheckInOutPortalUser(vm.model.checkInOut.out, $stateParams.argtype, $stateParams.argvalue, data.coords.latitude, data.coords.longitude, data.coords.accuracy).then(function () {
                        vm.model.p2WCheckInOutPortalUser = checkInMenuService.p2WCheckInOutPortalUser;
                        loadP2WCheckInOutSetting();
                    });
                });
            };

            vm.checkedInGuard = function () {
                navigator.geolocation.getCurrentPosition(function (data) {
                    checkInMenuService.loadP2WCheckInOutPortalUser('G', $stateParams.argtype, $stateParams.argvalue, data.coords.latitude, data.coords.longitude, data.coords.accuracy).then(function () {
                        vm.model.p2WCheckInOutPortalUser = checkInMenuService.p2WCheckInOutPortalUser;
                        loadP2WCheckInOutSetting();
                    });
                });
            };

            vm.checkedOutGuard = function () {
                navigator.geolocation.getCurrentPosition(function (data) {
                    checkInMenuService.loadP2WCheckInOutPortalUser('F', $stateParams.argtype, $stateParams.argvalue, data.coords.latitude, data.coords.longitude, data.coords.accuracy).then(function () {
                        vm.model.p2WCheckInOutPortalUser = checkInMenuService.p2WCheckInOutPortalUser;
                        loadP2WCheckInOutSetting();
                    });
                });
            };

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'checkinstatus':
                        go({
                            argtype: '-',
                            argvalue: '-',
                            viewtype: 'checkin'
                        });
                        break;
                }
            };

            vm.goToCheckInStatus = function (index) {
                stateService.go('checkinstatus', {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    viewtype: 'checkin'
                });
            };

            vm.goToCheckInStatusGuard = function (index) {
                stateService.go('checkinstatus', {
                    argtype: 'custact_no',
                    argvalue: $stateParams.argvalue,
                    viewtype: 'guard'
                });
            };

            vm.goToCheckIn = function (index) {
                stateService.go('checkin', {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    checkin_keyno: '0'
                });
            };
		}]
	});
})();