(function () {
    'use strict';

    let module = angular.module('imApp');

    /**
     * @author JLR
     * @version 20231108
     */
    module.component('emailAccountShared', {
        templateUrl: 'views/components/views/emailAccountShared/emailAccountShared.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'emailAccountSharedService', function ($stateParams, stateService, emailAccountSharedService) {
            let vm = this;

            vm.model = {
                sharedMailboxes: []
            }

            vm.gotoAddNewSharedMailbox = function () {
                stateService.go('emailaccountsharededit', { emailaccount_shared_keyno: '0', current_account_email: $stateParams.current_account_email })
            }

            async function _getSharedMailboxes() {
                return vm.model.sharedMailboxes = await emailAccountSharedService.getSharedMailboxes($stateParams.current_account_email);
            }

            vm.$onInit = async function () {
                _getSharedMailboxes();
            }
        }]
    });
})();
