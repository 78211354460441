(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("portalUserStockplaceService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            dataSource: {
                records: []
            },
            portalUserStockplaceToggle: function (portalUser, id, active_value) {
                var deferred = $q.defer();

                var parms_portalUserStockplaceToggle = {
                    method: 452,
                    parameters: {
                        portal_user_keyno: portalUser,
                        stockplace_id: id,
                        access_planning: active_value
                    }
                };

                $ihttp.post(parms_portalUserStockplaceToggle).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            portalUserStockplaceList: function (portalUser) {
                var deferred = $q.defer();

                var parms_portalUserStockplaceList = {
                    method: 451,
                    parameters: {
                        portal_user_keyno: portalUser
                    }
                };

                $ihttp.post(parms_portalUserStockplaceList).then(function (data) {
                    service.dataSource.records = [];

                    angular.forEach(data, function (item) {
                        var icon = 'glyphicon-unchecked';

                        if (item.access_planning == "1") {
                            icon = 'glyphicon-check';
                        }

                        var i = {
                            item_id: item.stockplace_id,
                            //item_order: item.company_name,
                            item_name: item.stockplace_name,
                            item_glyphicon: icon,
                            access_planning: item.access_planning,
                            orderby: item.orderby
                        };

                        service.dataSource.records.push(i);
                    });

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();