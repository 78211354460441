(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('csvImport', {
        templateUrl: 'views/components/views/csvImport/csvImport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$rootScope', 'utilityService', 'translateService', 'modalService', 'base64', 'csvImportService', function ($rootScope, utilityService, translateService, modalService, base64, csvImportService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let fileContent = '';

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                loadingFile: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region IMPORT CSV FILE PROCEDURE FUNCTION CALL

            let retrieveCsvFile = function () {
                if (utilityService.validateParmsValue(fileContent) !== true) return;
                
                vm.model.loadingFile = true;
                fileContent = base64.urlencode(fileContent);
                
                csvImportService.importFileCsv({ filecontent: fileContent }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingFile = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.loadingFile = false;
                    }
                });
            };

            // #endregion IMPORT CSV FILE PROCEDURE FUNCTION CALL

            // #region SELECT FILE FUNCTION
            
            vm.selectFile = function () {
                document.getElementById('fileInput').click();
            };

            // #endregion SELECT FILE FUNCTION

            // #region CHANGED FILE FUNCTION
            
            vm.fileChanged = function () {
                let reader = new FileReader();

                reader.onload = function (e) {
                    $rootScope.$apply(function () {
                        fileContent = reader.result;
                        document.getElementById('fileInput').value = '';
                        retrieveCsvFile();
                    });
                };

                let csvFile = document.getElementById('fileInput').files[0];
                reader.readAsText(csvFile);
            };

            // #endregion CHANGED FILE FUNCTION
        }]
    });
})();
