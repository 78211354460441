(function () {
    'use strict';

    angular.module('imApp').component('ttDatataskSelector', {
        templateUrl: 'views/components/dynamics/components/ttDatataskSelector/ttDatataskSelector.template.html',
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttHideLabel: '@',
            ttReadonly: '@',
            ttModel: '<',
            ttChange: '&',
            ttTextAlign: '@',
            ttPlaceholder: '@',
            ttRequired: '@',
            ttStyle: '<',
            ttLimit: '<'
        },
        controller: ['$element', 'ttDatataskSelectorService', 'utilityService', function ($element, ttDatataskSelectorService, us) {
            var vm = this;

            vm.label = '';
            vm.id = uuid();
            vm.datatasks = [];
            vm.selectedDatatask = '';

            vm.style = {
                label: {},
                typeahead: {}
            };

            vm.class = {
                label: '',
                input: '',
                typeahead: '',
            }

            vm.setDatatask = function (value, modelId) {
                vm.selectedDatatask = value;

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element) });
                }
            };

            vm.$onInit = function () {
                initLimit(vm.ttLimit);

                ttDatataskSelectorService.getDatataskList().then(function (response) {
                    angular.copy(response, vm.datatasks);

                    initDatatask(vm.ttModel);
                });

                if (angular.isString(vm.ttLabel) && vm.ttLabel.length > 0) {
                    vm.label = vm.ttLabel;
                }
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttLimit)) {
                    initLimit(changes.ttLimit.currentValue);
                }

                if (angular.isDefined(changes.ttModel)) {
                    initDatatask(changes.ttModel.currentValue);
                }
            };

            function initLimit(value) {
                vm.ttLimit = value || 25;

                if (vm.ttLimit < 1) {
                    vm.ttLimit = 25;
                }
            }

            function initDatatask(value) {
                if (angular.isUndefined(value) || value === null) return;

                if (angular.isObject(value)) {
                    vm.selectedDatatask = value.proc_name;
                } else {
                    vm.selectedDatatask = value;
                }
            }
        }]
    });
})();