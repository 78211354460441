(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deviationTypes', {
        templateUrl: 'views/components/views/deviationTypes/deviationTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'deviationTypesService', function ($stateParams, $q, stateService, utilityService, deviationTypesService) {
            let vm = this;
            let activityTypeNo = $stateParams.activitytype_no;
			
            vm.model = {
                setting: {},
                itemsListDeviationTypes: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();

                deviationTypesService.getDeviationTypes({ activitytype_no: activityTypeNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadDeviationTypes()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadDeviationTypes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.activitytype_no, true) !== true) return; 

                vm.model.itemsListDeviationTypes = [];
                
                return deviationTypesService.listDeviationTypes({
                    isactive: 'ALL',
                    activitytype_no: vm.model.setting.activitytype_no
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListDeviationTypes);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'deviationtype':
                        go({ deviationtype_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'show_all':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_all) !== true) return;

                        vm.model.setting.show_all = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_all, true) !== true) return;

                        if (vm.model.setting.show_all === '1') {
                            vm.model.setting.activitytype_no = '0';

                            loadDeviationTypes();
                        } else {
                            vm.model.setting.activitytype_no = activityTypeNo;

                            loadDeviationTypes();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
