(function () {
    const module = angular.module('imApp');

    /**
     * @author JLR
     * @version 20231218
     */

    /**
     * @typedef {Object} OverflowMenuItemProps represents the properties of the overflow-menu-item component.
     * @property {string | undefined} ttIcon (optional) the icon classes for the icon to display.
     * @property {string} ttText the text to display for the menu option.
     * @property {string} ttClick callback when the menu item is clicked.
     * @property {'seperator' | undefined} ttType (optional) the type of the menu item, none is default button, or 'seperator' can be specified.
     * @property {boolean | undefined} ttDanger (optional) whether the menu item should be marked with danger styling.
     */

    module.component('ttOverflowMenuItem', {
        templateUrl: 'views/components/directives/ttOverflowMenu/ttOverflowMenuItem.template.html?v=' + module.version,
        controllerAs: 'vm',
        /** @type OverflowMenuItemProps */
        bindings: {
            ttIcon: '@?',
            ttText: '@',
            ttClick: '&',
            ttType: '@?',
            ttDanger: '@?'
        },
        controller: ['layoutService', 'translateService', function (layoutService, translateService) {
            const vm = this;
     
            vm.style = {
                button: {}
            };

            vm.translations = {
                ttText: '',
            };

            vm.click = function () {
                if (vm.ttClick && typeof vm.ttClick === 'function') {
                    vm.ttClick();
                }
            };

            vm.$onInit = function () {
                layoutService.onLayoutChanged([], (info) => {
                    vm.style.button.fontSize = info.fontSizes.textSize;
                });
            };

            vm.$onChanges = async function (changes) {
                if (changes?.ttText?.currentValue && typeof changes.ttText.currentValue === 'string' && changes.ttText.currentValue !== changes.ttText.previousValue) {
                    vm.translations.ttText = await translateService.translate(changes.ttText.currentValue)
                }
            };

        }]
    });
})()
