(function () {
    'use strict';

    /**
     * @author JLR
     * @version 20231108
     */
    angular.module("imApp").factory("emailAccountSharedService", ['$ihttp', function ($ihttp) {
        let service = {

            /**
             * @typedef {Object} SharedMailboxItem represents a shared mailbox item.
             * @property {string} item_id stringified number id of shared mailbox.
             * @property {string} item_name the address of the shared mailbox.
             * @property {string} item_parms the parameters used to identify the shared mailbox.
             * @property {string} item_path the goto path for when the mailbox is clicked in an list.
             * @property {string} item_state the page/state to go to when the mailbox is clicked in an list.
             * @property {string} orderby stringified number representing the order of the mailbox in the list.
             */

            /**
             * Retrieves a list of shared mailboxes connected to the given current account email.
             * 
             * @param {string} currentAccountEmail the email to find shared mailboxes for.
             * @returns {Promise<SharedMailboxItem[]} a promise containing a list of the shared mailboxes.
             */
            getSharedMailboxes: function (currentAccountEmail) {
                return $ihttp.post({ method: 2958, parameters: { current_account_email: currentAccountEmail } });
            },
        }

        return service;
    }]);
})();
