(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('selectCustomer', {
        templateUrl: 'views/components/views/selectCustomer/selectCustomer.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'typeaheadService', 'rememberService', function (stateService, utilityService, typeaheadService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                bgLastCustomersSelection: {
                    selected: 'LAST',
                    buttons: [
                        { id: 'LAST', label: 'findcustomers_last', color: 'primary', onClick: () => vm.model.bgLastCustomersSelection.selected = 'LAST' },
                        { id: 'SEARCH', label: 'findcustomers_search', color: 'primary', onClick: () => vm.model.bgLastCustomersSelection.selected = 'SEARCH' }
                    ]
                },
                bgCustomerSelection: {
                    selected: 'C',
                    buttons: [
                        { id: 'C', label: 'cust_find_customers', color: 'primary', onClick: () => vm.model.bgCustomerSelection.selected = 'C' },
                        { id: 'S', label: 'cust_find_suppliers', color: 'primary', onClick: () => vm.model.bgCustomerSelection.selected = 'S' },
                        { id: 'O', label: 'cust_find_other', color: 'primary', onClick: () => vm.model.bgCustomerSelection.selected = 'O' }
                    ]
                },
                customerNameButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('cust_name') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => vm.searchItems() }
                ],
                setting: {
                    cust_name: ''
                },
                itemsListCustomers: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURES

            let loadCustomers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.cust_name, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgCustomerSelection.selected) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgLastCustomersSelection.selected) !== true) return;

                vm.model.itemsListCustomers = [];

                typeaheadService.searchCustomer({
                    cust_name: vm.model.setting.cust_name,
                    filtertype: '',
                    filtervalue: '',
                    custtype: vm.model.bgCustomerSelection.selected,
                    lastselect: vm.model.bgLastCustomersSelection.selected
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListCustomers);
                });
            };

            // #endregion LOAD PROCEDURES

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.setting.cust_name = '';

                        if (vm.model.setting.cust_name === '') loadCustomers();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SEARCH FUNCTION

            vm.searchItems = function () {
                loadCustomers();
            };

            // #endregion SEARCH FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'customeredit':
                        go({ cust_no: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region SAVE & DIRECTION FUNCTION

            vm.goToPre = function (item) {
                rememberService.remember('selected_cust_no', item.item_id);
            };

            // #endregion SAVE & DIRECTION FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgLastCustomersSelection = function (value) {
                vm.model.bgLastCustomersSelection.selected = value;

                loadCustomers();
            };

            vm.selectBgCustomerSelection = function (value) {
                vm.model.bgCustomerSelection.selected = value;

                loadCustomers();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadCustomers();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
