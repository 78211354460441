<div class="context-menu" [style.top.px]="position?.y" [style.left.px]="position?.x">
    <label>
        <div class="font-size-control">
            <button (click)="decreaseFontSize()" class="icon-button">-</button>
            <input type="text" [value]="getFontSize()" (input)="onFontSizeInput($event)" />
            <button (click)="increaseFontSize()" class="icon-button">+</button>
        </div>
    </label>
    <label class="colorpicker">
        A
        <input type="color" [(ngModel)]="item.color" [ngStyle]=" {'background-color' : item.color || 'transparent'} " />
    </label>

    <label class="colorpicker">
        B
        <input type="color" [(ngModel)]="item.bg_color" [ngStyle]=" {'background-color' : item.bg_color || 'transparent'} " />
    </label>


    <label>
        <select [(ngModel)]="item.font">
            <option value="" disabled>Select a font</option>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
            <option value="Georgia">Georgia</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Verdana">Verdana</option>
            <option value="Tahoma">Tahoma</option>
            <option value="Trebuchet MS">Trebuchet MS</option>
            <option value="Impact">Impact</option>
            <option value="Comic Sans MS">Comic Sans MS</option>
            <option value="Libre Barcode 128">Barcode</option>
        </select>
    </label>

    <button [class.active]="item.bold === '1'"
            class="icon-button"
            (click)="toggleBold()">
        B
    </button>


    <button [class.active]="item.italic === '1'"
            class="icon-button"
            (click)="toggleItalic()">
        I
    </button>


    <label>
        Text Align:
        <select [(ngModel)]="item.textalign">
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
            <option value="justify">Justify</option>
        </select>

    </label>
    <button [class.active]="item.textalign === 'left'">
        <i class="far fa-align-left"></i>
    </button>
    <button [class.active]="item.textalign === 'center'" onclick="">
        <i class="far fa-align-justify"></i>
    </button>
    <button [class.active]="item.textalign === 'right'">
        <i class="far fa-align-right"></i>
    </button>
</div>
