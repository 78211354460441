(function() {
    'use strict';

    angular.module("imApp").factory("customerBonusService", [function() {
        //var call = function (method, parameters) {
        //    return $ihttp.post({
        //        method: method,
        //        parameters: parameters || {}
        //    });
        //};

        //var utoa = function (data) { // Utility function for base64encode with unicode support
        //    return btoa(unescape(encodeURIComponent(data)));
        //}

        //var atou = function (data) { // Utility function for base64decode with unicode support
        //    return decodeURIComponent(escape(atob(data)));
        //}

        var service = {
            //getCustomer: function (parameters) {
            //    return call(2517, parameters || {});
            //},
            //remember: function (rememberId, value, method, isBase) {
            //    return call( method ?? 616, { // for use if need to have a different method
            //        is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
            //        variablename: rememberId,
            //        variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
            //    });
            //},
            //getRemember: function (rememberId, method) {
            //    return call( method ?? 973, { variablename: rememberId }); // for use if need to have a different method
            //}
        }

        return service;
    }]);
})();
