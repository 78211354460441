(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('acledgerhistoryimport', {
        templateUrl: 'views/components/views/acledgerhistoryimport/acledgerhistoryimport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$uibModal', 'utilityService', 'acLedgerHistoryImportService', 'logisticService', function ($q, $uibModal, utilityService, acLedgerHistoryImportService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            vm.model = {
                setting: {},
                selectListCompanies: [],
                selectListAccountingYears: [],
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_acledgerhistoryimport_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'import_data', text: 'w_acledgerhistoryimport_import_data', func: openImportModal, icon: 'far fa-file-import', cssClass: 'tt-button tt-button--secondary im-grid-btn-xs-r', translate: true },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            function openImportModal (item) {
                const modalRef = $uibModal.open({
                    component: 'acledgerhistoryimportImportModal',
                    resolve: {
                        businessCoNo: function () {
                            return vm.model.setting.businessco_no;
                        },
                        accountingYear: function () {
                            return vm.model.setting.accounting_year;
                        },
                    },
                    size: 'pst-ninety',
                });

                modalRef.closed.then(function () {
                    vm.grid.gridfunc.rebind();
                });
            };



            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	let deferred = $q.defer();

            	acLedgerHistoryImportService.getAcLedgerHistoryImport().then(function (result) {
            		angular.copy(result[0], vm.model.setting);

            		$q.all([
            			loadYears()
                    ]).then(() => {
                        loadGrid();
                        deferred.resolve();
                    });
            	});

            	return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadYears = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                return acLedgerHistoryImportService.listYears({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListAccountingYears);
                });
            };

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3008,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        loadYears();
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'accounting_year':
                        if (utilityService.validateWatchValue(value, vm.model.setting.accounting_year) !== true) return;

                        vm.model.setting.accounting_year = value;

                        if (utilityService.validateParmsValue(vm.model.setting.accounting_year, true) !== true) return;
                        
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
