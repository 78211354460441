(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("p2ReportDefsService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1026,
                    parameters: parms || {}
                });
            },
            listReports: function (parms) {
                return $ihttp.post({
                    method: 333,
                    parameters: parms || {}
                });
            },
            listPrinters: function (parms) {
                return $ihttp.post({
                    method: 337,
                    parameters: parms || {}
                });
            },
            listClients: function (parms) {
                return $ihttp.post({
                    method: 532,
                    parameters: parms || {}
                });
            },
            updateReports: function (data) {
                return $ihttp.post({
                    method: 529,
                    parameters: data
                });
            },
            updatePrinters: function (data) {
                return $ihttp.post({
                    method: 530,
                    parameters: data
                });
            },
            updateClients: function (data) {
                return $ihttp.post({
                    method: 531,
                    parameters: data
                });
            }
        };

        return service;
    }]);
})();
