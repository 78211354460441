(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderHeadSetDisc', {
        templateUrl: 'views/components/views/orderHeadSetDisc/orderHeadSetDisc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'orderHeadSetDiscService', function ($stateParams, stateService, modalService, orderHeadSetDiscService) {
            var vm = this;
            
            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                setDisc: {},
                lockedSave: false
            };
            
            orderHeadSetDiscService.loadOrderHeadSetDiscGet(vm.model.orderInternalNo).then(function (data) {
                vm.model.setDisc = angular.copy(data[0]);
            });
            
            vm.saveOrderHeadSetDisc = function () {
                vm.model.lockedSave = true;
                
                orderHeadSetDiscService.save(vm.model.setDisc).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();