<div class="ag-cell-edit-wrapper" [id]="id.wrapper">
    <div class="ag-picker-field ag-labeled ag-label-align-left ag-rich-select ag-cell-editor" role="presentation">
        <div ref="eLabel" class="ag-label ag-hidden" aria-hidden="true" role="presentation" id="ag-537-label"></div>
        <div
            ref="eWrapper"
            class="ag-wrapper ag-picker-field-wrapper ag-rich-select-value ag-picker-expanded ag-has-popup-positioned-under"
            aria-controls="ag-rich-select-list-539"
            tabindex="0"
            style="min-width: 100%"
        >
            <div ref="eDisplayField" class="ag-picker-field-display ag-hidden" id="ag-537-display"></div>
            <!--AG-INPUT-TEXT-FIELD-->
            <div
                role="presentation"
                ref="eInput"
                class="ag-rich-select-field-input ag-labeled ag-label-align-left ag-text-field ag-input-field"
                style="width: calc(100% - var(--ag-cell-horizontal-padding))"
            >
                <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-text-field-label" aria-hidden="true" role="presentation" id="ag-538-label"></div>
                <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-text-field-input-wrapper" role="presentation">
                    <input
                        #inputRef
                        [formControl]="searchControl"
                        class="ag-input-field-input ag-text-field-input ag-custom-component-popup"
                        aria-labelledby="ag-537-label"
                        type="text"
                        [id]="id.input"
                        tabindex="0"
                        aria-expanded="true"
                        role="combobox"
                        (focus)="$event.stopPropagation()"
                        (click)="$event.stopPropagation()"
                        autocomplete="off"
                        ttListboxPopup
                        [ttRelativeElement]="id.wrapper"
                        ttFilterOperation="filter"
                        (ttSelect)="onOptionSelected($event)"
                        [ttData]="(filteredOptions | async) ?? []"
                        [ttDataId]="params?.key || 'item_name'"
                        [ttDataName]="params?.keyname || 'item_id'"
                        [ttStyle]="listboxStyle"
                        ttMinWidth="30rem"
                        [ngStyle]="style['input']"
                        style="outline: none"
                    />
                    <!-- <input
                        #inputRef
                        [formControl]="searchControl"
                        [matAutocomplete]="auto"
                        class="ag-input-field-input ag-text-field-input ag-custom-component-popup"
                        aria-labelledby="ag-537-label"
                        type="text"
                        id="ag-538-input"
                        tabindex="0"
                        aria-expanded="true"
                        role="combobox"
                        (focus)="$event.stopPropagation()"
                        (click)="$event.stopPropagation()"
                        autocomplete="off"
                        [ngStyle]="style['input']"
                        style="outline: none"
                    /> -->
                </div>
            </div>
            <div ref="eIcon" class="ag-picker-field-icon" aria-hidden="true"><span class="far fa-search" unselectable="on" role="presentation"></span></div>
        </div>
    </div>
</div>
<!-- <mat-autocomplete
    [classList]="['ag-custom-component-popup']"
    [overlayPanelClass]="['ag-custom-component-popup']"
    #auto="matAutocomplete"
    (optionSelected)="onOptionSelected($event)"
    (click)="$event.preventDefault(); $event.stopImmediatePropagation(); $event.stopPropagation()"
    autoActiveFirstOption
>
    <mat-option
        *ngFor="let option of filteredOptions | async"
        class="ag-custom-component-popup"
        [value]="option"
        (click)="$event.preventDefault(); $event.stopImmediatePropagation(); $event.stopPropagation()"
        (onSelectionChange)="onOptionSelected($event)"
        [ngStyle]="style['option']"
    >
        {{ option?.[params?.keyname || 'item_name'] || (option | json) }}
    </mat-option>
</mat-autocomplete> -->
