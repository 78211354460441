(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipmentList', {
        templateUrl: 'views/components/views/equipmentList/equipmentList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'equipmentListService', 'rememberService', function ($stateParams, $q, stateService, utilityService, equipmentListService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            let variableNames = {
                show_all: ''
            };

            vm.model = {
                setting: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                equipmentListService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_all, true) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: vm.model.setting.p2_datatask_keyno,
                    parameters: {
                        argtype: vm.model.setting.argtype,
                        argvalue: vm.model.setting.argvalue,
                        show_all: vm.model.setting.show_all
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'show_all':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'show_all':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_all) !== true) return;

                        vm.model.setting.show_all = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_all, true) !== true) return;

                        rememberFunc('show_all');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
