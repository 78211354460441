(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("riskElementsService", ['$ihttp', function ($ihttp) {
        let service = {
            listProbabilityLabels: function (parms) {
                return $ihttp.post({
                    method: 270,
                    parameters: parms || {}
                });
            },
            listConsequenceLabels: function (parms) {
                return $ihttp.post({
                    method: 271,
                    parameters: parms || {}
                });
            },
            listRiskLevels: function (parms) {
                return $ihttp.post({
                    method: 277,
                    parameters: parms || {}
                });
            },
            listRiskElements: function (parms) {
                return $ihttp.post({
                    method: 268,
                    parameters: parms || {}
                });
            },
            listRiskElementCounts: function (parms) {
                return $ihttp.post({
                    method: 275,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
