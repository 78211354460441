(function () {
	'use strict';
	
	let module = angular.module('imApp');
	
	module.component('cuttingPlans', {
		templateUrl: 'views/components/views/cuttingPlans/cuttingPlans.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'cuttingPlansService', 'modalService', 'translateService', 'paymentPlansService', function (stateService, utilityService, cuttingPlansService, modalService, translateService, paymentPlansService) {
			
			// #region VARIABLES & DEFINITIONS
			
            let vm = this;

            let translations = {
                error: '',
                ok: '',
                tt_delete_grid_row_title: '',
                tt_delete_grid_row_warning: '',
                tt_delete_grid_row_confirm: '',
                tt_delete_grid_row_abort: '',

            };

			vm.model = {
				setting: {},
				selectListStatuses: [],
				gridReady: false
			};

            vm.grid = {
                dataTask: {
                    rememberId: 'w_cuttingplans_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: vm.model.setting,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: vm.model.setting,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: vm.model.setting,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: false,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                            {
                                name: 'customDelete',
                                text: '',
                                icon: 'far-trash-alt',
                                type: 'danger',
                                func: function (data) {
                                    modalService.show({
                                        type: 'warning',
                                        title: translations.tt_delete_grid_row_title,
                                        message: translations.tt_delete_grid_row_warning,
                                        buttons: [{
                                            label: translations.tt_delete_grid_row_abort,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }, {
                                            label: translations.tt_delete_grid_row_confirm,
                                            cssClass: 'btn-danger',
                                            action: async function (dialogItself) {
                                                await vm.grid.gridfunc.removeRow(data);
                                                vm.grid.gridfunc.saveChanges(false);
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                }
                            }

                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

			// #endregion VARIABLES & DEFINITIONS

			// #region LOAD PROCEDURE FUNCTION CALLS
            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });


			cuttingPlansService.getCuttingPlans().then(function (result) {
                angular.copy(result[0], vm.model.setting);

			    vm.grid.dataTask.loadData = {
                    method: 2884,
				    parameters: vm.model.setting
			    };
		
                vm.model.gridReady = true;
			});
			
			cuttingPlansService.listStatuses().then(function (result) {
				angular.copy(result, vm.model.selectListStatuses);
			});
			
			// #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'cuttingplanstatus_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.cuttingplanstatus_no) !== true) return;

                        vm.model.setting.cuttingplanstatus_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.cuttingplanstatus_no, true) !== true) return;

                        vm.grid.gridfunc.rebind() 
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

			// #region MULTI DIRECTION BUTTON FUNCTIONS
			
			vm.goToFunc = function (state) {
				let go = function (parms) {
					stateService.go(state, parms);
				};
				
				switch (state) {
					case 'cuttingplan':
						paymentPlansService.getNextNumber({ table_name: 'cuttingplan' }).then(function (response) {
							go({ cuttingplan_keyno: response[0].nextnumber });
						});
						break;
					default:
						break;
				}
			};
			
			// #endregion MULTI DIRECTION BUTTON FUNCTIONS
		}]
	});
})();
