(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('relatedPurchOrders', {
        templateUrl: 'views/components/views/relatedPurchOrders/relatedPurchOrders.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'relatedPurchOrdersService', function ($stateParams, relatedPurchOrdersService) {
            var vm = this;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;
            
            vm.model = {
                itemsListRelated: []
            };
            
            relatedPurchOrdersService.listRelatedPurchaseOrders({
                argtype: argType,
                argvalue: argValue
            }).then(function (data) {
                angular.copy(data, vm.model.itemsListRelated);
            });
        }]
    });
})();