(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectShift', {
        templateUrl: 'views/components/views/selectShift/selectShift.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectShiftService', 'stateService', function ($stateParams, selectShiftService, stateService) {
            var vm = this;
            
            selectShiftService.loadP2ShiftSelectList($stateParams.labour_no).then(function () {
                vm.p2_shift_select_list = selectShiftService.p2_shift_select_list;
            });
            
            vm.goToPreTrNewJob = function (item) {
                selectShiftService.saveP2LabourSwitchShift(item);
            };
            
            vm.cancelToPreTrNewJob = function () {
                stateService.back();
            };
        }]
    });
})();