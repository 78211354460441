(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('counter', {
        templateUrl: 'views/components/views/counter/counter.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'counterService', 'translateService', function ($stateParams, stateService, modalService, counterService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var counterKeyno = $stateParams.counter_keyno;

            var translations = {
                counter_show_title: 'Varsel',
                counter_show_ok_label: 'OK'
            };

            vm.model = {
                btnListLastNumber: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'calculator', item_func: 'calculator_item', glyph: 'fa fa-calculator', color: 'primary' },
                    { item_id: '2', item_name: 'previous', item_func: 'previous_item', glyph: 'glyphicon-minus', color: 'primary' },
                    { item_id: '3', item_name: 'next', item_func: 'next_item', glyph: 'glyphicon-plus', color: 'primary' }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadGet = function () {
                counterService.getCounter({ counter_keyno: counterKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            loadGet();

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncLastNumber = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.edit.lastnumber = 0;
                        break;
                    case 'calculator_item':
                        modalService.showCalculator(vm.model.edit.lastnumber).then(function (value) {
                            vm.model.edit.lastnumber = value;
                        });
                        break;
                    case 'previous_item':
                        vm.model.edit.lastnumber--;
                        break;
                    case 'next_item':
                        vm.model.edit.lastnumber++;
                        break;
                    default:
                        break;
                }
            };

		    // #endregion BUTTON LIST FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                counterService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.counter_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.counter_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSave = false;
                                    loadGet();
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    translateService.translateBatch(translations).then(function (data) {
			    angular.forEach(translations, function (_, key) {
				    if (angular.isDefined(data[key])) {
					    translations[key] = data[key];
				    }
			    });
		    });

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();