(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2WebpageEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_webpage_edit: {
                p2_webpage_keyno: '',
                webpage_name: '',
                word_id: '',
                subpages: ''
            },
            loadP2WebpageEdit: function (p2_webpage_keyno) {
                var deferred = $q.defer();

                var parms_p2webpage_edit = {
                    method: 13,
                    parameters: {
                        p2_webpage_keyno: p2_webpage_keyno
                    }
                };

                $ihttp.post(parms_p2webpage_edit).then(function (data) {
                    service.p2_webpage_edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2WebpageEdit: function () {
                var deferred = $q.defer();

                var parms_p2webpage_save = {
                    method: 15,
                    parameters: {
                        p2_webpage_keyno: service.p2_webpage_edit.p2_webpage_keyno,
                        webpage_name: service.p2_webpage_edit.webpage_name,
                        word_id: service.p2_webpage_edit.word_id
                    }
                };

                $ihttp.post(parms_p2webpage_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();