(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderCustomerService', {
        templateUrl: 'views/components/views/orderCustomerService/orderCustomerService.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$window', '$timeout', 'stateService', 'utilityService', 'orderCustomerServiceService', 'ieScreenBlockService', function($stateParams, $window, $timeout, stateService, utilityService, orderCustomerServiceService, ieScreenBlockService) {
    
            var vm = this;
    
    
    
            var resizing = false;
            var editing = false;
    
            vm.model = {
                ordercustcomConfig: {
                    name: 'ordercustcom',
                    enabled: true,
                    show: true,
                    activate: gridActivate,
                    deactivate: gridDeactivate,
                    function: function(item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                gridColumnResize(vm.model.ordercustcomConfig, vm.model.ordercustcoms);
                            }
                        }
                    },
                    records: [
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '4', item_name: 'toggle', glyph: 'glyphicon-check', color: 'primary', show: false },
                        { item_id: '5', item_name: 'erase', glyph: 'glyphicon-erase', color: 'danger', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                orderlineConfig: {
                    name: 'orderline',
                    enabled: true,
                    show: true,
                    activate: gridActivate,
                    deactivate: gridDeactivate,
                    function: function(item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                gridColumnResize(vm.model.orderlineConfig, vm.model.orderlines);
                            }
                        }
                    },
                    records: [
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '4', item_name: 'toggle', glyph: 'glyphicon-check', color: 'primary', show: false },
                        { item_id: '5', item_name: 'erase', glyph: 'glyphicon-erase', color: 'danger', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                consignmentConfig: {
                    name: 'consignment',
                    enabled: true,
                    show: true,
                    activate: gridActivate,
                    deactivate: gridDeactivate,
                    function: function(item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                gridColumnResize(vm.model.consignmentConfig, vm.model.consignments);
                            }
                        }
                    },
                    records: [
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '4', item_name: 'toggle', glyph: 'glyphicon-check', color: 'primary', show: false },
                        { item_id: '5', item_name: 'erase', glyph: 'glyphicon-erase', color: 'danger', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                requisitionConfig: {
                    name: 'requisition',
                    enabled: true,
                    show: true,
                    activate: gridActivate,
                    deactivate: gridDeactivate,
                    function: function(item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                gridColumnResize(vm.model.requisitionConfig, vm.model.requisitions);
                            }
                        }
                    },
                    records: [
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '4', item_name: 'toggle', glyph: 'glyphicon-check', color: 'primary', show: false },
                        { item_id: '5', item_name: 'erase', glyph: 'glyphicon-erase', color: 'danger', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                purchlineConfig: {
                    name: 'purchline',
                    enabled: true,
                    show: true,
                    activate: gridActivate,
                    deactivate: gridDeactivate,
                    function: function(item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                gridColumnResize(vm.model.purchlineConfig, vm.model.purchlines);
                            }
                        }
                    },
                    records: [
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '4', item_name: 'toggle', glyph: 'glyphicon-check', color: 'primary', show: false },
                        { item_id: '5', item_name: 'erase', glyph: 'glyphicon-erase', color: 'danger', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                orderInfo: {
                    // order_internal_no decimal(16)
                    // order_order_no decimal(16)
                    // order_date date
                    // show_ordercustcom char(1)
                    // show_orderline_section char(1)
                    // show_consignment_section char(1)
                    // show_requisiton_section char(1)
                    // show_purchase_section char(1)
                },
                ordercustcoms: [],
                ordercustcomsRaw: [],
                orderlines: [],
                orderlinesRaw: [],
                consignments: [],
                consignmentsRaw: [],
                requisitions: [],
                requisitionsRaw: [],
                purchlines: [],
                purchlinesRaw: [],
                loading: false
            }
        
            vm.consignmentClick = function(item) {
                var iconclicked = angular.isDefined(item.item) ? false : true;
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;
                
                if (baseItem.item_id != 0) {
                    if (baseItem.item_glyphicon_color !== 'grey'){
                        openUrl(baseItem.item_tracking_url);
                    }                
                }
            }
    
            function getOrder() {
                orderCustomerServiceService.getOrder($stateParams.order_internal_no).then(function(data) {
                    angular.copy(data[0], vm.model.orderInfo);
                    
                    if (vm.model.orderInfo.show_ordercustcom_section === '1') {
                        listOrdercustcoms();
                    }
    
                    if (vm.model.orderInfo.show_orderline_section === '1') {
                        listOrderlines();
                    }
    
                    if (vm.model.orderInfo.show_consignment_section === '1') {
                        listConsignments();
                    }
    
                    if (vm.model.orderInfo.show_requisition_section === '1') {
                        listRequisitions();
                    }
    
                    if (vm.model.orderInfo.show_purchline_section === '1') {
                        listPurchlines();
                    }
                });
            };
    
            function listOrdercustcoms() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');
                orderCustomerServiceService.listOrdercustcoms($stateParams.order_internal_no)
                    .then(function (data) {
                        angular.copy(data, vm.model.ordercustcoms);
                        angular.copy(data, vm.model.ordercustcomsRaw);
                        vm.loaded = true;
                        ieScreenBlockService.stop();
                    });
            }
    
            function listOrderlines() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');
                orderCustomerServiceService.listOrderlines($stateParams.order_internal_no)
                    .then(function (data) {
                        angular.copy(data, vm.model.orderlines);
                        angular.copy(data, vm.model.orderlinesRaw);
                        vm.loaded = true;
                        ieScreenBlockService.stop();
                    });
            }
    
            function listConsignments() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');
                orderCustomerServiceService.listConsignments($stateParams.order_internal_no)
                    .then(function (data) {
                        angular.copy(data, vm.model.consignments);
                        angular.copy(data, vm.model.consignmentsRaw);
                        vm.loaded = true;
                        ieScreenBlockService.stop();
                    });
            }
    
            function listRequisitions() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');
                orderCustomerServiceService.listRequisitions($stateParams.order_internal_no)
                    .then(function (data) {
                        angular.copy(data, vm.model.requisitions);
                        angular.copy(data, vm.model.requisitionsRaw);
                        vm.loaded = true;
                        ieScreenBlockService.stop();
                    });
            }
    
            function listPurchlines() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');
                orderCustomerServiceService.listPurchlines($stateParams.order_internal_no)
                    .then(function (data) {
                        angular.copy(data, vm.model.purchlines);
                        angular.copy(data, vm.model.purchlinesRaw);
                        vm.loaded = true;
                        ieScreenBlockService.stop();
                    });
            }
       
            function gridActivate(item) {}
            
            function gridDeactivate(item) {}
            
            function gridColumnResize(modelConfig, modelArray) {
                for (var m = 0; m < modelConfig.records.length; m++) {
                    if (angular.isDefined(modelConfig.records[m]) && modelConfig.records[m].item_id === '99') {
                        modelConfig.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(modelArray[0]) && angular.isDefined(modelArray[0].item_secondary)) {
                    for (var r = 0; r < modelArray[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: modelArray[0].item_col_width })
                        }
                        if (angular.isDefined(modelArray[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: modelArray[0].item_secondary[r].item_col_width })
                        }
                    }
                }
    
                var updateParms = {
                    view_id: 'ordercustomerservice_' + modelConfig.name + '_list',
                    records: updateList
                };
    
                orderCustomerServiceService.updateColumnResize(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < modelConfig.records.length; m++) {
                            if (angular.isDefined(modelConfig.records[m]) && modelConfig.records[m].item_id === '99') {
                                modelConfig.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            modelConfig.records[m].animate = null;
                        }, 2000);
                    }
    
                    resizing = false;
                });
            }
    
            function openUrl(url) {
                var tab = $window.open('_blank');
                tab.location = url;
            }
    
            getOrder();
    
            // #region CALCULATE BUTTON FUNCTION
    
            vm.calculateNewDeliveryDate = function () {
                vm.model.loading = true;
                
                orderCustomerServiceService.calculateDeliveryDate(vm.model.orderInfo.order_internal_no).then(function (data) {
                    //if (data[0].errorcode !== '0') {
                    //    utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                    //        vm.model.loading = false;
                    //    });
                    //} else {
                        vm.model.loading = false;
                        getOrder();
                    //}
                });
            };
    
            // #endregion CALCULATE BUTTON FUNCTION
    
            // #region DIRECTION BUTTON FUNCTIONS
    
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'orderhead':
                        go({
                            order_internal_no: vm.model.orderInfo.order_internal_no
                        });
                        break;
                    case 'orderdeliveryinfo':
                        go({
                            argtype: 'order_internal_no',
                            argvalue: vm.model.orderInfo.order_internal_no
                        });
                        break;
                    case 'stocktransallocations':
                        go({
                            argtype: 'order_internal_no',
                            argvalue: vm.model.orderInfo.order_internal_no
                        });
                        break;
                    default:
                        break;
                }
            };
    
            // #endregion DIRECTION BUTTON FUNCTIONS
        }]
    });
})();