(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srNotCompletedNew', {
        templateUrl: 'views/components/views/srNotCompletedNew/srNotCompletedNew.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'modalService', 'rememberService', 'srNotCompletedNewService', 'watcherFactory', function($stateParams, $timeout, stateService, modalService, rememberService, srNotCompletedNewService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceId: $stateParams.resource_id,
                newNotcompleted: {},
                selectListResources: [],
                selectListSpecialEvents: [],
                lockedSave: false
            };
    
            // ## IF CONDITIONS FUNCTIONS ##
    
            var validateParmsEmptyValue = function (parmEmptyValue) {
                if (angular.isUndefined(vm.model.newNotcompleted[parmEmptyValue]) === true) return false;
                if (vm.model.newNotcompleted[parmEmptyValue] === null) return false;
    
                return true;
            };
    
    		var validateVariables = function (variableName) {
    			if (angular.isUndefined(variableName) === true) return false;
    			if (variableName === null) return false;
    			if (variableName.trim().length < 1) return false;
    
    			return true;
    		};
    
    		var validateNewOldValue = function (newVal, oldVal) {
    			if (angular.isUndefined(newVal) === true) return false;
    			if (angular.isUndefined(oldVal) === true) return false;
    			if (newVal === oldVal) return false;
    
    			return true;
    		};
    
            // ## INIT LOAD FUNCTION ##
    
            var initLoadDone = false;
            var timerInitLoadDone = null;
    
            var initLoadFunc = function () {
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }
    
                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srNotCompletedNewService.loadSrNotCompletedNewGet(vm.model.resourceId).then(function (data) {
                vm.model.newNotcompleted = angular.copy(data[0]);
    
                loadSrNotCompletedNewResources();
                loadSrNotCompletedNewEvents();
    
                initLoadFunc();
            });
    
            var loadSrNotCompletedNewResources = function () {
                vm.model.selectListResources = [];
    
                srNotCompletedNewService.loadSrNotCompletedNewResourceList().then(function (data) {
                    angular.copy(data, vm.model.selectListResources);
                });
            };
    
            var loadSrNotCompletedNewEvents = function () {
                vm.model.selectListSpecialEvents = [];
    
                srNotCompletedNewService.loadSrNotCompletedNewEventList().then(function (data) {
                    angular.copy(data, vm.model.selectListSpecialEvents);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberSpecialEventId = function () {
                var variableNameSpecialEventId = 'w_srnotcompletednew.special_event_id';
    
                if (initLoadDone !== true) return;
                if (validateVariables(variableNameSpecialEventId) !== true) return;
                if (validateParmsEmptyValue('special_event_id') !== true) return;
    
                rememberService.remember(variableNameSpecialEventId, vm.model.newNotcompleted.special_event_id);
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrNotCompletedNew = function () {
                vm.model.lockedSave = true;
                
                srNotCompletedNewService.save(vm.model.newNotcompleted).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () {
                return vm.model.newNotcompleted.special_event_id;
            }, function (newValue, oldValue) {
                if (validateNewOldValue(newValue, oldValue) !== true) return;
    
                rememberSpecialEventId();
            });
        }]
    });
})();