//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("dndFilterService", ['$q', '$ihttp', function ($q, $ihttp) {
//        var service = {
//            loaded: false,
//            p2_get: {
//                // property namn og verdiar (blank)
//                //test
//            },
//            filter_records: [],
//            filtered_records: [],
//            filter_type: 0,
//            filter_status: 0
//        }
//        return service;
//    }]);

//})();
