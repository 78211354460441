(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttTextarea', {
        templateUrl: 'views/components/directives/ttTextarea/ttTextarea.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttSublabel: '@',
            ttReadonly: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttOnBlur: '&',
            ttItemId: '@',
            ttRequired: '@',
            ttStyle: '<',
            ttTranslate: '@',       // whether to translate the primary label or not.
        },
        controller: ['$element', '$timeout', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, $timeout, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;
            var onDestroy = [];

            vm.readonly = false;
            vm.required = false;

            vm.id = {
                textarea: uuid()
            };

            vm.style = {
                base: {},
                label: {},
                sublabel: {},
                textarea: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                sublabel: '',
                input: '',
            };

            vm.translations = {
                ttLabel: ''
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'textarea' }), vm.style);

            vm.whenReady = function () {
                eventService.trigger('element:ready');

                eventService.broadcast('elastic:adjust');
                
            };

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };
            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            vm.setFocus = function () {
                var element = $element.find('#' + vm.id.textarea)[0];

                if (angular.isUndefined(element))
                    return;

                element.focus();
            };

            vm.selectText = function () {
                vm.setFocus();

                $timeout(function () {
                    var element = $element.find('#' + vm.id.textarea)[0];

                    element.setSelectionRange(0, element.value.length);
                }, 10);
            };

            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttStyle)) setStyle(changes.ttStyle.currentValue);


                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttReadonly) && us.isStringValue(changes.ttReadonly.currentValue, true)) {
                    vm.readonly = vm.ttReadonly.toLowerCase() === 'true';
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {

                    if (vm.ttTranslate === false || vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = vm.ttLabel;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
