(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttSelectPrinter', {
        templateUrl: 'views/components/directives/ttSelectPrinter/ttSelectPrinter.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttModel: '<',
            ttChange: '&'
        },
        controller: ['$uibModal', 'dbPrinterService', 'eventService', function ($uibModal, dbPrinterService, eventService) {
            var vm = this;

            vm.printers = [];
            vm.printer = {
                item_id: '',
                item_name: ''
            };

            dbPrinterService.list().then(function (data) {
                angular.forEach(data, function (value) {
                    vm.printers.push({ item_id: value.item_id, item_name: value.item_name });
                });
            });

            var okClicked = false;

            var onModalClose = function () {
                eventService.trigger('event:focuslock:start');

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $printer: vm.printer, $changed: okClicked });
                }
            };

            var modalInstance = null;

            vm.openModal = function () {
                eventService.trigger('event:focuslock:stop');

                modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/components/directives/ttSelectPrinter/ttSelectPrinter.modal.html?v=' + module.version,
                    controllerAs: 'uib',
                    controller: [function () {
                        var uib = this;

                        uib.style = {
                            color: '#777'
                        };

                        uib.printers = angular.copy(vm.printers);

                        uib.printer = vm.printer.item_id;

                        uib.onPrinterChanged = function (printer) {
                            for (var i = 0; i < vm.printers.length; i++) {
                                if (vm.printers[i].item_id !== printer)
                                    continue;

                                vm.printer = vm.printers[i];
                                break;
                            }
                        };

                        uib.ok = function () {
                            okClicked = true;

                            modalInstance.close();
                        };

                        uib.cancel = function () {
                            modalInstance.close();
                        };
                    }]
                });

                modalInstance.result.then(onModalClose, onModalClose);
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttModel) && angular.equals(changes.ttModel.currentValue, vm.printer) !== true) {
                    angular.copy(changes.ttModel.currentValue, vm.printer);
                }
            };
        }]
    });
})();
