(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srUserService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            selectResourcesList: [],
            loadSrUserGet: function (portal_user_keyno) {
                return p2DataTaskService.call(1465, {
                    portal_user_keyno: portal_user_keyno
                });
            },
            loadFontSizeList: function () {
                return p2DataTaskService.call(1466, {});
            },
            loadSpecialHrsDeviationRightsList: function () {
                return p2DataTaskService.call(1469, {});
            },
            loadSrUserRefundList: function () {
                return p2DataTaskService.call(1633, {});
            },
            searchResources: function (resource_searchtext) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 1321,
                    parameters: {
                        resource_searchtext: resource_searchtext
                    }
                }).then(function (data) {
                    angular.copy(data, service.selectResourcesList);

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            save: function (user) {
                return p2DataTaskService.call(1470, user);
            }
        };

        return service;
    }]);
})();