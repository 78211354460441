(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('p2DataTaskLog', {
        templateUrl: 'views/components/views/p2DataTaskLog/p2DataTaskLog.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'translateService', 'p2DataTaskLogService', function ($stateParams, translateService, p2DataTaskLogService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2DataTaskLogKeyno = $stateParams.p2_datatasklog_keyno;

            let translations = {
                //confirm_success_copy_title: '',
                //confirm_success_copy_json_data_message: '',
                //confirm_ok_label: '',
                //confirm_cancel_label: '',
                copy_to_clipboard: ''
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            vm.model = {
                jsonDataInButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.copy.jsondata_in = '' },
                    { id: 'copy', icon: 'fa fa-solid fa-copy', color: 'primary', type: 'primary', onClick: () => window.prompt(translations.copy_to_clipboard, vm.model.copy.jsondata_in) }
                ],
                jsonDataOutButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.copy.jsondata_out = '' },
                    { id: 'copy', icon: 'fa fa-solid fa-copy', color: 'primary', type: 'primary', onClick: () => window.prompt(translations.copy_to_clipboard, vm.model.copy.jsondata_out) }
                ],
                info: {},
                copy: {},
                copyingJsonDataIn: false,
                copyingJsonDataOut: false,
                formattingJsonDataIn: false,
                formattingJsonDataOut: false,
                showCopyJsonDataIn: false,
                showCopyJsonDataOut: false,
                showFormatJsonDataIn: false,
                showFormatJsonDataOut: false,
                readyJsonDataIn: true,
                readyJsonDataOut: true
            };

            // #endregion VARIABLES & DEFINITIONS

            let loadInfo = function () {
                p2DataTaskLogService.getInfo({ p2_datatasklog_keyno: p2DataTaskLogKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.info);
                });
            };

            // #region BUTTON COPY JSON DATA PROCEDURE FUNCTION CALLS

            vm.copyJsonDataIn = function () {
                vm.model.copy.jsondata_in = angular.copy(vm.model.info.jsondata_in);

                vm.model.showCopyJsonDataIn = true;
                vm.model.showCopyJsonDataOut = false;
            };

            vm.copyJsonDataOut = function () {
                vm.model.copy.jsondata_out = angular.copy(vm.model.info.jsondata_out);

                vm.model.showCopyJsonDataIn = false;
                vm.model.showCopyJsonDataOut = true;
            };

            // #endregion BUTTON COPY JSON DATA PROCEDURE FUNCTION CALLS

            // #region BUTTON FORMAT JSON DATA PROCEDURE FUNCTION CALLS

            vm.formatJsonDataIn = function () {
                vm.model.copy.jsondata_in = angular.copy(vm.model.info.jsondata_in);

                JSON.stringify(vm.model.copy.jsondata_in, null, 4);
                JSON.stringify(vm.model.copy.jsondata_in, null, "\t");

                vm.model.showFormatJsonDataIn = true;
                vm.model.showFormatJsonDataOut = false;
            };

            vm.formatJsonDataOut = function () {
                vm.model.copy.jsondata_out = angular.copy(vm.model.info.jsondata_out);
                
                JSON.stringify(vm.model.copy.jsondata_out, null, 4);
                JSON.stringify(vm.model.copy.jsondata_out, null, "\t");

                vm.model.showFormatJsonDataIn = false;
                vm.model.showFormatJsonDataOut = true;
            };

            // #endregion BUTTON FORMAT JSON DATA PROCEDURE FUNCTION CALLS

            vm.$onInit = function () {
                loadInfo();
            };
        }]
    });
})();
