(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('seasonalities', {
        templateUrl: 'views/components/views/seasonalities/seasonalities.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'seasonalitiesService', function (stateService, seasonalitiesService) {
            const vm = this;
            
            vm.model = {
                itemsListSeasonalities: []
            };
            
            let loadSeasonalities = function () {
                seasonalitiesService.listSeasonalities().then(function (result) {
                    angular.copy(result, vm.model.itemsListSeasonalities);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'seasonality':
                        go({ seasonality_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSeasonalities();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
