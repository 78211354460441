(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('collectionCalculation', {
        templateUrl: 'views/components/views/collectionCalculation/collectionCalculation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['ttGridFactory', function (ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.grid = new ttGridFactory({
                rememberId: 'w_collectioncalculation.grid',
                loadData: {
                    method: 2781,
                    parameters: null
                }
            })
                .setToolbar({
                    pdfExport: false,
                    excelExport: false,
                    layouts: false,
                    wrapping: true
                });

            // #endregion VARIABLES & DEFINITIONS

        }]
    });
})();
