(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epSchenkerOverviewGetEdi', {
        templateUrl: 'views/components/views/epSchenkerOverviewGetEdi/epSchenkerOverviewGetEdi.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$stateParams', 'stateService', 'epSchenkerOverviewGetEdiService', function ($stateParams, stateService, epSchenkerOverviewGetEdiService) {
            var vm = this;
            vm.version = module.version;

            var shipmentContainerKeyno = 0;

            vm.gridControls = null;
            vm.isModal = false;

            var setGridOptions = function () {
                vm.gridOptions = {
                    dataTaskKeyno: 2060,
                    parameters: {
                        shipmentcontainer_keyno: shipmentContainerKeyno
                    },
                    rememberId: 'epSchenkerOverviewGetEdi',
                    gridControlCallback: function (gridControls) { vm.gridControls = gridControls; }
                };
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve) && angular.isDefined(vm.resolve.shipmentContainerKeyno)) {
                    // when opened as modal
                    vm.isModal = true;

                    shipmentContainerKeyno = vm.resolve.shipmentContainerKeyno;

                    setGridOptions();
                }
            };

            if (angular.isDefined($stateParams.shipmentContainerKeyno)) {
                // when opened with state
                shipmentContainerKeyno = $stateParams.shipmentContainerKeyno;

                setGridOptions();
            }

            vm.cancel = function () {
                if (vm.isModal === true) {
                    vm.modalInstance.dismiss()
                } else {
                    stateService.back();
                }
            };

            vm.getEdi = function () {
                epSchenkerOverviewGetEdiService.getEdi(shipmentContainerKeyno).then(function () {
                    gridControls.refresh();
                });
            };
        }]
    });
})();
