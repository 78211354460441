(function () {
	'use strict';
	
	let module = angular.module('imApp');
	
	module.component('cuttingPlan', {
		templateUrl: 'views/components/views/cuttingPlan/cuttingPlan.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'cuttingPlanService', 'logisticService', 'labourPositionService', 'equipmentEditService', 'operationFactoryService', 'cuttingPlansService', 'rememberService', 'translateService', 'printService', function ($stateParams, stateService, utilityService, modalService, cuttingPlanService, logisticService, labourPositionService, equipmentEditService, operationFactoryService, cuttingPlansService, rememberService, translateService, printService) {
			
			// #region VARIABLES & DEFINITIONS
			
			let vm = this;
			let cuttingPlanKeyno = $stateParams.cuttingplan_keyno;

            let variableNames = {
                businessco_no: '',
				factory_id: '',
				workgrp_keyno: '',
				equipment_id: '',
				operation_id: '',
				prod_id: '',
				componentalternative_keyno: '',
				stockplace_id: ''
            };
			
            let translations = {
                error: '',
                ok: '',
                tt_delete_grid_row_title: '',
                tt_delete_grid_row_warning: '',
                tt_delete_grid_row_confirm: '',
                tt_delete_grid_row_abort: '',

            };

            vm.version = module.version;

			vm.model = {
				bgCuttingPlanMain: {
					item_id: '',
					item_name: '',
					item_default: '0',
					item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'gb_cuttingplan_main_scan', item_func: 'SCAN' },
                        { item_id: '1', item_name: 'gb_cuttingplan_main_basis', item_func: 'BASIS' },
						{ item_id: '2', item_name: 'gb_cuttingplan_main_pos', item_func: 'POS' }
					]
				},
				edit: {},
				selectListCompanies: [],
				selectListFactories: [],
				selectListWorkGroups: [],
				selectListEquipments: [],
				selectListOperations: [],
				selectListUdefs: [],
				selectListFormats: [],
				selectListStatuses: [],
                selectListStockPlaces: [],
                selectListPrinters: [],
                printer_keyno: 0,
				gridPoReady: false,
                gridCompReady: false,
                distributing: false,
                lockedSave: false
			};

            vm.scan = {
                last_errorcode: '',
                last_errormessage: ''
            };

            vm.style = {
                basic: {
                    labelAlwaysOnTop: true
                }
            };

            vm.options = {
                stockQuantity: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

			vm.gridPo = {
                dataTask: {
					rememberId: 'w_cuttingplan_cuttingplanpo_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: vm.model.edit, 
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: vm.model.edit,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: false,
                        buttons: [
                            {
                                name: 'customDelete',
                                text: '',
                                icon: 'far-trash-alt',
                                type: 'danger',
                                func: function (data) {
                                    modalService.show({
                                        type: 'warning',
                                        title: translations.tt_delete_grid_row_title,
                                        message: translations.tt_delete_grid_row_warning,
                                        buttons: [{
                                            label: translations.tt_delete_grid_row_abort,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }, {
                                            label: translations.tt_delete_grid_row_confirm,
                                            cssClass: 'btn-danger',
                                            action: async function (dialogItself) {
                                                await vm.gridPo.gridfunc.removeRow(data);
                                                vm.gridPo.gridfunc.saveChanges(false);
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                }
                            }

                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridComp = {
                dataTask: {
                    rememberId: 'w_cuttingplan_cuttingplanpocomp_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

			// #endregion VARIABLES & DEFINITIONS
			
			// #region LOAD PROCEDURE FUNCTION CALLS
			
            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            printService.getPrinters().then(function (data) { angular.copy(data.items, vm.model.selectListPrinters) });
            console.dir('printers: ',vm.model.printers)

            let loadGet = function () {
                cuttingPlanService.getCuttingPlan({ cuttingplan_keyno: cuttingPlanKeyno }).then(function (result) {
                    console.dir(result[0]);
                    angular.copy(result[0], vm.model.edit);
                    vm.model.bgCuttingPlanMain.item_default = utilityService.groupButtonIdOf(vm.model.bgCuttingPlanMain, vm.model.edit.gb_cuttingplan_main);

                    
                    loadSelectFactories();
                    loadSelectWorkGroups();
                    loadSelectEquipments();
                    loadOperations();
                    loadFormats();
                    loadStocks();

                    vm.gridPo.dataTask.loadData = {
                        method: 2886,
                        parameters: vm.model.edit
                    };

                    vm.gridComp.dataTask.loadData = {
                        method: 2887,
                        parameters: vm.model.edit
                    };

                    vm.model.gridPoReady = true;
                    vm.model.gridCompReady = true;
                });
            };

            loadGet();
			
			logisticService.listCompanies().then(function (result) {
				angular.copy(result, vm.model.selectListCompanies);
			});
			
			cuttingPlanService.listUdefs().then(function (result) {
                angular.copy(result, vm.model.selectListUdefs);
                console.dir(vm.model.selectListUdefs);
                for (let i = 0; i < vm.model.selectListUdefs.length; i++) {
                    if (vm.model.selectListUdefs[i].prod_id === vm.model.edit.prod_id) {
                        vm.model.edit.prod_keyname = vm.model.selectListUdefs[i].prod_name;
                        break;
                    }
                }                


			});
			
			cuttingPlansService.listStatuses().then(function (result) {
				angular.copy(result, vm.model.selectListStatuses);
			});
			
			let loadOperations = function () {
				if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;
				if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;

				logisticService.listOperations({ 
					direct: '1',
					equipment_id: vm.model.edit.equipment_id,
					workgrp_keyno: vm.model.edit.workgrp_keyno
				}).then(function (result) {
					angular.copy(result, vm.model.selectListOperations);
				});
			}
			
			let loadSelectFactories = function () {
				if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

				labourPositionService.listSelectFactories({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
					angular.copy(result, vm.model.selectListFactories);
				});
			};
            
            let loadSelectWorkGroups = function () {
                if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;

                equipmentEditService.listSelectWorkGroups({ factory_id: vm.model.edit.factory_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListWorkGroups);
                });
            };
			
            let loadSelectEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;

                operationFactoryService.listSelectEquipments({
					workgrp_keyno: vm.model.edit.workgrp_keyno,
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListEquipments);
                });
            };
			
			let loadFormats = function () {
				if (utilityService.validateParmsValue(vm.model.edit.prod_id, true) !== true) return;

				cuttingPlanService.listFormats({ prod_id: vm.model.edit.prod_id }).then(function (result) {
					angular.copy(result, vm.model.selectListFormats);
				});
			};

			let loadStocks = function () {
				if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
					angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

			// #endregion LOAD PROCEDURE FUNCTION CALLS
			
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
					variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
					case 'factory_id':
					case 'workgrp_keyno':
					case 'equipment_id':
					case 'operation_id':
					case 'prod_id':
					case 'componentalternative_keyno':
					case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);

                console.log(variableNames[id], ' - ', variableValue); 

            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region CALC PROCEDURE FUNCTION CALL

            let calcChanges = function () {
                if (utilityService.validateParmsValue(vm.model.edit.quantity_to_pick, true) !== true) return;

                let dotIndex = vm.model.edit.quantity_to_pick.indexOf('.');

                if (dotIndex > 0 && dotIndex + 3 === vm.model.edit.quantity_to_pick.length) {
                    cuttingPlanService.calcObj(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            vm.model.edit.stock_quantity_after_optimalization = response[0].stock_quantity_after_optimalization;
                        }
                    });
                }
            };

		    // #endregion CALC PROCEDURE FUNCTION CALL

			// #region BUTTON GROUP SINGLE FUNCTION
			
			vm.selectBgCuttingPlanMain = function (item) {
				vm.model.bgCuttingPlanMain.item_selected = item;
				vm.model.edit.gb_cuttingplan_main = vm.model.bgCuttingPlanMain.item_selected;
			};
			
			// #endregion BUTTON GROUP SINGLE FUNCTION

			// #region FIELD CHANGE MULTI FUNCTIONS
			
			vm.handleFieldChanged = function (value, id) {
				if (id.startsWith('vm.') !== true) return;
				
				let parts = id.split('.');
				
				switch (parts[parts.length - 1]) {
					case 'businessco_no':
						if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;
						
						vm.model.edit.businessco_no = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
						
						rememberFunc('businessco_no');
						loadSelectFactories();
						loadStocks();
						break;
					case 'factory_id':
						if (utilityService.validateWatchValue(value, vm.model.edit.factory_id) !== true) return;
						
						vm.model.edit.factory_id = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;
						
						rememberFunc('factory_id');
						loadSelectWorkGroups();
						loadSelectEquipments();
						break;
					case 'workgrp_keyno':
						if (utilityService.validateWatchValue(value, vm.model.edit.workgrp_keyno) !== true) return;
						
						vm.model.edit.workgrp_keyno = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;
						
						rememberFunc('workgrp_keyno');
						loadSelectEquipments();
						loadOperations();
						break;
					case 'equipment_id':
						if (utilityService.validateWatchValue(value, vm.model.edit.equipment_id) !== true) return;
						
						vm.model.edit.equipment_id = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;
						
						rememberFunc('equipment_id');
						loadOperations();
						break;
					case 'operation_id':
						if (utilityService.validateWatchValue(value, vm.model.edit.operation_id) !== true) return;
						
						vm.model.edit.operation_id = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;
						
						rememberFunc('operation_id');
						break;
					case 'prod_id':
						if (utilityService.validateWatchValue(value, vm.model.edit.prod_id) !== true) return;
						
						vm.model.edit.prod_id = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.prod_id, true) !== true) return;
						
						rememberFunc('prod_id');
						loadFormats();
						break;
					case 'componentalternative_keyno':
						if (utilityService.validateWatchValue(value, vm.model.edit.componentalternative_keyno) !== true) return;
						
						vm.model.edit.componentalternative_keyno = value;
						
						if (utilityService.validateParmsValue(vm.model.edit.componentalternative_keyno, true) !== true) return;
						
						rememberFunc('componentalternative_keyno');
						break;
					case 'stockplace_id':
						if (utilityService.validateWatchValue(value, vm.model.edit.stockplace_id) !== true) return;

						vm.model.edit.stockplace_id = value;

						if (utilityService.validateParmsValue(vm.model.edit.stockplace_id, true) !== true) return;

						rememberFunc('stockplace_id');
                        break;
                    case 'quantity_to_pick':
                        if (utilityService.validateWatchValue(value, vm.model.edit.quantity_to_pick) !== true) return;

                        vm.model.edit.quantity_to_pick = value;

                        if (utilityService.validateParmsValue(vm.model.edit.quantity_to_pick, true) !== true) return;

                        calcChanges();
                        break;
					default:
						utilityService.setValueToVariable(vm, parts, value);
						//eval(id + " = '" + value + "'");
						break;
				}
			};
			
			// #endregion FIELD CHANGE MULTI FUNCTIONS

			// #region FIELD SCAN PROCEDURE FUNCTION CALL

			vm.onScan = function (value) {
				vm.model.edit.barcode = value;
				
				if (vm.model.edit.barcode.length === 0) return;

				cuttingPlanService.scanItem(vm.model.edit).then(function (response) {
					vm.model.edit.barcode = '';
					
					if (response[0].errorcode !== '0') {
						//modalService.show({
						//	type: 'warning',
						//	title: translations.error,
						//	message: response[0].errormessage,
						//	buttons: [{
						//		label: translations.ok,
						//		cssClass: 'btn-warning',
						//		action: function (dialogItself) {
						//			dialogItself.close();
						//		}
						//	}]
						//});

                        vm.scan.last_errorcode = response[0].errorcode;
                        vm.scan.last_errormessage = response[0].errormessage;
                    } else {
                        vm.scan.last_errorcode = response[0].errorcode;
                        vm.scan.last_errormessage = response[0].errormessage;

                        loadGet();
					}
				});
			};

		    // #endregion FIELD SCAN PROCEDURE FUNCTION CALL

            // #region BUTTON DISTRIBUTE PROCEDURE FUNCTION CALL

            vm.distributeChanges = function () {
                vm.model.distributing = true;

                cuttingPlanService.distributeObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.distributing = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.distributing = false;
                        vm.gridPo.gridfunc.rebind();
                        vm.gridComp.gridfunc.rebind();
                    }
                });
            };

            // #endregion BUTTON DISTRIBUTE PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                cuttingPlanService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region buttons

            vm.printCuttingPlan = function () {
                if (vm.model?.edit?.cuttingplan_keyno && vm.model.edit.cuttingplan_keyno.length > 0) {
                    cuttingPlanService.printCuttingPlan(vm.model.edit.cuttingplan_keyno, vm.model.printer_keyno).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                    });
                }
            };

		    // #endregion buttons

            // #region LOOKUPSEARCH FUNCTIONS

            vm.typeaheadSearch = function (value) {
                return cuttingPlanService.searchKeyname(value);
                
            };

            vm.selectItem = function (item, model) {
                if (item?.prod_id && item.prod_id !== '') {
                    console.dir(item);
                    vm.model.edit.prod_keyname = item.prod_keyname;
                    vm.model.edit.prod_id = item.prod_id;

                    rememberFunc('prod_id');
                    loadFormats();

                }

                
                

            };

            // #endregion LOOKUPSEARCH FUNCTIONS



		}]
	});
})();
