(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttMessage', {
        templateUrl: 'views/components/directives/ttMessage/ttMessage.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',           // the primary label of the message.
            ttLabelView: '@',       // the position of the primary label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttSublabel: '@',        // the secondary label of the message.
            ttHideLabel: '@',       // whether to hide the label or not, this option will preserve the space for the label and only render it invisible.
            ttModel: '<',           // the content of the message.
            ttHideEmptyContent: '@',//
            ttStyle: '<',           // custom styling to apply to the message.
            ttIsHtml: '<',          // whether the message is html (and thus should be parsed), or not (only text).
            ttIsCurrentUser: '<',   // whether the message is authored by the current user.
            ttTranslate: '<',       // whether to translate the primary label or not.
        },
        controller: ['$element', '$sce', '$timeout', 'translateService', 'layoutService', 'utilityService', 'ttDirectivesService', function ($element, $sce, $timeout, translateService, layoutService, utilityService, ttDirectivesService) {
            let vm = this;
            let onDestroy = [];

            vm.hideLabel = false;

            vm.id = {
                message: uuid(),
            };

            vm.style = {
                base: {},
                label: {
                    size: null,
                },
                sublabel: {
                    size: null,
                },
                message: {
                    size: null,
                },
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                sublabel: '',
                message: '',
                article: '',
            };

            vm.translations = {
                ttLabel: '',
            };

            let setClasses = (labelAlwaysOnTop) => {
                vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
                vm.class.message += angular.isDefined(vm.ttIsCurrentUser) && vm.ttIsCurrentUser == true ? ' tt-input__message--current-user' : '';
            }

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'message' }), vm.style);

            /**
             * Takes the given html string and sets it as innerhtml of the article.
             * 
             * @param htmlString HTML string to place inside the article.
             */
            let setInnerHtml = function (htmlString) {

                $timeout(function () {
                    let element = $element.find('#' + vm.id.message)[0];

                    if (angular.isDefined(element) && element !== null) {
                        element.innerHTML = htmlString;
                    }
                }, 0);
            }

            /**
             * Checks if the label should be translated and sets the label accordingly.
             */
            function setLabel() {
                if (vm.ttTranslate === true && angular.isString(vm.ttLabel) && vm.ttLabel !== null && vm.ttLabel.length > 0) {
                    translateService.translate(vm.ttLabel).then((translation) => vm.translations.ttLabel = translation);
                } else {
                    vm.translations.ttLabel = vm.ttLabel;
                }
            }

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = vm.ttStyle?.label?.size?.length > 0 ? info.fontSizes[vm.ttStyle.label.size] : info.fontSizes.textSizeS;
                vm.style.sublabel.fontSize = vm.ttStyle?.sublabel?.size?.length > 0 ? info.fontSizes[vm.ttStyle.sublabel.size] : info.fontSizes.textSizeS;
                vm.style.message.fontSize = vm.ttStyle?.message?.size?.length > 0 ? info.fontSizes[vm.ttStyle.message.size] : info.fontSizes.textSize;

                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            vm.$onInit = function () {
                if (vm.ttIsHtml && vm.ttIsHtml === true) setInnerHtml(vm.ttModel);

                setLabel();
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                if (changes.ttLabel?.currentValue && utilityService.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel?.currentValue !== changes.ttLabel?.previousValue) setLabel();

                if (angular.isDefined(changes.ttModel) && utilityService.isStringValue(changes.ttModel.currentValue)) {
                    vm.ttModel = changes.ttModel.currentValue;

                    if (vm.ttIsHtml === true) setInnerHtml(vm.ttModel);
                }

                if (angular.isDefined(changes.ttIsHtml) && angular.isDefined(changes.ttIsHtml.currentValue) === true) {
                    vm.ttIsHtml = changes.ttIsHtml.currentValue;

                    if (vm.ttIsHtml === true) setInnerHtml(vm.ttModel);
                }

                if (changes.ttStyle?.currentValue && angular.isObject(changes.ttStyle.currentValue)) setStyle(changes.ttStyle.currentValue);
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
