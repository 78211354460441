(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("productionhTopsComponentsService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadComponents: function (inargtype, inargvalue) {
				return p2DataTaskService.call(1635, {
					argtype: inargtype,
					argvalue: inargvalue
				});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
			}/*,
			getLastStatus: function(){
                return p2DataTaskService.call(973, {variablename: 'productionhtopsstatus'});
            },
			setLastStatus: function(printer){
                return p2DataTaskService.call(616, {
                    variablename: 'productionhtopsstatus',
                    variablevalue: printer
                });
			}*/
		};

		return service;
	}]);
})();