(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srEquipmentResourceView', {
        templateUrl: 'views/components/views/srEquipmentResourceView/srEquipmentResourceView.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'rememberService', 'srCrewResourceViewService', 'srEquipmentResourceViewService', 'watcherFactory', function(stateService, utilityService, rememberService, srCrewResourceViewService, srEquipmentResourceViewService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            var variableNames = {
                main_resource_id: '',
                sub_resource_id: '',
                srrefundtype_id: ''
            };
    
            vm.model = {
                settingEquipmentView: {},
                selectListResources: [],
                selectListSubResources: [],
                selectListRefundTypes: [],
                itemsListEquipments: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srEquipmentResourceViewService.loadSrEquipmentResourceViewGet().then(function (data) {
                angular.copy(data[0], vm.model.settingEquipmentView);
    
                loadSrSubResources();
                loadSrEquipmentResourceViews();
            });
    
            srCrewResourceViewService.loadSrMainResourceList().then(function (data) {
                angular.copy(data, vm.model.selectListResources);
            });
    
            srEquipmentResourceViewService.loadSrRefundTypeIsEquipmentList({
                add_all: '1'
            }).then(function (data) {
                angular.copy(data, vm.model.selectListRefundTypes);
            });
    
            var loadSrSubResources = function () {
                if (utilityService.validateParmsValue(vm.model.settingEquipmentView.main_resource_id) !== true) return;
    
                vm.model.selectListSubResources = [];
    
                srCrewResourceViewService.loadSrSubResourceList(vm.model.settingEquipmentView.main_resource_id).then(function (data) {
                    angular.copy(data, vm.model.selectListSubResources);
                });
            };
    
            var loadSrEquipmentResourceViews = function () {
                if (utilityService.validateParmsValue(vm.model.settingEquipmentView.sub_resource_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingEquipmentView.srrefundtype_id) !== true) return;
    
                vm.model.itemsListEquipments = [];
    
                srEquipmentResourceViewService.loadSrEquipmentResourceViewList(vm.model.settingEquipmentView.sub_resource_id, vm.model.settingEquipmentView.srrefundtype_id).then(function (data) {
                    angular.copy(data, vm.model.itemsListEquipments);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
    
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
    
                var variableValue = null;
    
                switch (id) {
                    case 'main_resource_id':
                        if (utilityService.validateParmsValue(vm.model.settingEquipmentView.main_resource_id) !== true) return;
    
                        variableValue = vm.model.settingEquipmentView.main_resource_id;
                        break;
                    case 'sub_resource_id':
                        if (utilityService.validateParmsValue(vm.model.settingEquipmentView.sub_resource_id) !== true) return;
    
                        variableValue = vm.model.settingEquipmentView.sub_resource_id;
                        break;
                    case 'srrefundtype_id':
                        if (utilityService.validateParmsValue(vm.model.settingEquipmentView.srrefundtype_id) !== true) return;
    
                        variableValue = vm.model.settingEquipmentView.srrefundtype_id;
                        break;
                    default:
                        break;
                }
    
                rememberService.remember(variableNames[id], variableValue);
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.settingEquipmentView.main_resource_id; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrSubResources();
                rememberFunc('main_resource_id');
            });
    
            watcher.$watch(function () { return vm.model.settingEquipmentView.sub_resource_id; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrEquipmentResourceViews();
                rememberFunc('sub_resource_id');
            });
    
            watcher.$watch(function () { return vm.model.settingEquipmentView.srrefundtype_id; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrEquipmentResourceViews();
                rememberFunc('srrefundtype_id');
            });
        }]
    });
})();