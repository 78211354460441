(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCrews', {
        templateUrl: 'views/components/views/srCrews/srCrews.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'rememberService', 'srCrewsService', function($stateParams, stateService, utilityService, rememberService, srCrewsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                settingCrew: {},
                itemsListCrews: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCrewsService.loadSrCrewsGet(vm.model.argType, vm.model.argValue).then(function (data) {
                vm.model.settingCrew = angular.copy(data[0]);
    
                loadSrCrews();
            });
    
            var loadSrCrews = function () {
                if (utilityService.validateParmsValue(vm.model.settingCrew.searchtext, true) !== true) return;
    
                vm.model.itemsListCrews = [];
    
                srCrewsService.loadSrCrewsList(vm.model.settingCrew).then(function (data) {
                    angular.copy(data, vm.model.itemsListCrews);
                });
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.settingCrew.searchtext = '';
                        break;
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srcrew':
                        go({
                            resource_id: vm.model.settingCrew.search_resource_id,
                            srcrew_keyno: '0'
                        });
                        break;
                }
            };
        }]
    });
})();