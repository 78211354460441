(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('priceListFactors', {
        templateUrl: 'views/components/views/priceListFactors/priceListFactors.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'priceListFactorsService', function ($stateParams, stateService, priceListFactorsService) {

            //##########################
            //## VARIABLES & DEFINITIONS
            //##########################

            var vm = this;
            
            vm.model = {
                p2WPriceListFactorsSettings: {},
                p2PriceListFactorList: {
                    records: []
                }
            };
            
            //##################
            //## LOAD PROCEDURES
            //##################
            
            vm.loadP2WPriceListFactorsSettings = function () {
                priceListFactorsService.genericFunction(670, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue
                }).then(function (data) {
                    vm.model.p2WPriceListFactorsSettings = angular.copy(data[0]);
                });
            };
            
            vm.loadP2WPriceListFactorsSettings();
            
            vm.loadP2PriceListFactorList = function () {
                priceListFactorsService.genericFunction(669, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue
                }).then(function (data) {
                    angular.copy(data, vm.model.p2PriceListFactorList.records);
                });
            };
            
        	vm.loadP2PriceListFactorList();
            
            //#######################
            //## SAVE BUTTON FUNCTION
            //#######################
            
            vm.saveP2WPriceListFactorS = function () {
                priceListFactorsService.genericFunction(671, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    items: vm.model.p2PriceListFactorList.records
                }).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();