(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("customerEditService", ['$q', '$ihttp', 'selectCityService', 'p2DataTaskService', function ($q, $ihttp, selectCityService, p2DataTaskService) {
        var service = {
            loaded: false,
            selectPostPlaceList: [],

            init: function () {
                return $ihttp.post({
                    method: 2449,
                    parameters: {
                        state: 'init'
                    }
                });
            },

            getAutoInvoiceMethods: function () {
                return $ihttp.post({
                    method: 2449,
                    parameters: {
                        state: 'autoinvoicemethod'
                    }
                });
            },

            load: function (cust_no) {
                var deferred = $q.defer();
                
                $ihttp.post({ method: 67, parameters: { cust_no: cust_no } }).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            loadP2CountryList: function () {
                return $ihttp.post({ method: 220 });
            },
            loadDeliveryMethodList: function () {
                return p2DataTaskService.call(385, {});
            },
            loadP2TaxList: function (country_id) {
                return p2DataTaskService.call(640, {
                    country_id: country_id
                });
            },
            loadBusinessList: function () {
                return p2DataTaskService.call(638, {});
            },
            loadDeliveryTermList: function () {
                return p2DataTaskService.call(623, {});
            },
            loadCustGroupList: function () {
                return $ihttp.post({ method: 69 });
            },
            loadValutaList: function () {
                return $ihttp.post({ method: 373 });
            },
            loadPriceList: function () {
                return $ihttp.post({ method: 627 });
            },
            loadPaymentList: function () {
                return $ihttp.post({ method: 624 });
            },
            loadP2LanguageList: function () {
                return p2DataTaskService.call(57, {});
            },
            loadCoopList: function () {
                return p2DataTaskService.call(639, {});
            },
            loadBusinessCoList: function () {
                return p2DataTaskService.call(104, {});
            },
            //loadInvoiceMethodList: function () {
            //    return p2DataTaskService.call(641, {});
            //},
            listCustomerBonusDeals: function (parms) {
                return $ihttp.post({
                    method: 2903,
                    parameters: parms || {}
                });
            },
            listDiscGroupCustomers: function (parms) {
                return $ihttp.post({
                    method: 3124,
                    parameters: parms || {}
                });
            },
            listEhfGroupingMethods: function (parms) {
                return $ihttp.post({
                    method: 3359,
                    parameters: parms || {}
                });
            },
            getNextCounterNumber: function (counter_id, cust_no) {
                return p2DataTaskService.call(678, {
                    counter_id: counter_id,
                    cust_no: cust_no
                });
            },
            assignNewAccCustNo: function (edit) {
                return $ihttp.post({
                    method: 3267,
                    parameters: edit
                });
            },
            changeCustGrpId: function (customer) {
                return p2DataTaskService.call(1431, customer);
            },
            search: function (searchtext) {
                var deferred = $q.defer();
                
                $ihttp.post({ method: 103, parameters: { searchtext: searchtext } }).then(function (data) {
                    angular.copy(data, service.selectPostPlaceList);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            save: function (customer) {
                var validate = function (val1, val2) {
                    if (angular.isUndefined(val1) === true) return val2;
                    if (val1 === null) return val2;
                    if (angular.isString(val1) === false) return val2;
                    if (val1 === '') return val2;

                    return val1;
                };

                customer.postplace_id_street = validate(customer.postplace_id_street, selectCityService.selectedPostplace.id);
                customer.postplace_name_street = validate(customer.postplace_name_street, selectCityService.selectedPostplace.name);
                customer.postplace_id = validate(customer.postplace_id, selectCityService.selectedPostplace.id);
                customer.postplace_name = validate(customer.postplace_name, selectCityService.selectedPostplace.name);

                return $ihttp.post({ method: 70, parameters: customer });
            }
        };

        return service;
    }]);
})();
