(function () {
    'use strict';

    angular.module("imApp").factory("factoriesService", ['$ihttp', function ($ihttp) {
        let service = {
            listFactories: function (businessCoNo) {
                return $ihttp.post({
                    method: 2025,
                    parameters: {
                        businessco_no: businessCoNo || ''
                    }
                });
            }
        };

        return service;
    }]);
})();
