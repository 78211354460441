(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansDeliveryLocation', {
        templateUrl: 'views/components/views/consignmentPlansDeliveryLocation/consignmentPlansDeliveryLocation.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'consignmentPlansDeliveryLocationService', function(stateService, consignmentPlansDeliveryLocationService) {
            var vm = this;

            vm.model = {
                //btnListFilterVal: [
                //    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                //],
                stocklocation_list: [],
                filterval: ''
            };
            
            consignmentPlansDeliveryLocationService.loadLocationList().then(function (data) {
                angular.forEach(data, function (item) {
                    vm.model.stocklocation_list.push(item);
                });
            });
            
            //vm.btnListFuncFilterVal = function (item_func) {
            //    switch (item_func) {
            //        case 'erase_item':
            //            vm.model.filterval = '';
            //            break;
            //        default:
            //            break;
            //    }
            //};
            
            vm.saveLocation = function (item) {
                consignmentPlansDeliveryLocationService.remember('consignmentplansdelivery.stocklocation_keyno', item.item_id).then(function (data) {
                    consignmentPlansDeliveryLocationService.remember('consignmentplansdelivery.stocklocation_name', item.item_name).then(function (data) {
                        stateService.back();
                    });
                });
            };
        }]
    });
})();