(function () {
    'use strict';

    angular.module("imApp").factory("jsResponsiveBridgeService", ['$rootScope', 'eventService', 'responsiveBridgeService', function ($rootScope, eventService, responsiveBridgeService) {
        let isInitialized = false;
        let previous = '';

        let service = {
            init: function () {
                if (isInitialized) return;

                isInitialized = true;

                responsiveBridgeService.responsiveChanged.subscribe(data => {
                    $rootScope.$broadcast('event:responsive-breakpoint', { oldSize: previous, newSize: data });
                    eventService.trigger('event:responsive-breakpoint', { oldSize: previous, newSize: data });

                    previous = data;
                });
            }
        };

        return service;
    }]);
})();
