(function () {
    'use strict';

    angular.module("imApp").factory("accountingProcessingService", ['$q', '$ihttp', function ($q, $ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        var service = {
            selectListProjects: [],
            isSearchingProjects: false,
            loadAccountingProcessingGet: function (custact_no) {
                return call(1672, { custact_no: custact_no });
            },

            /**
             *
             * 
             * @param {any} parms
             * @returns {{colname: string; new_value: any}
             */
            sendCustactAcColchanged: function (parms) {
                return call(3186, parms || {});
            },

            getAccountingProcessingCustomerActivity: function (custactNo) {
                return call(1971, { custact_no: custactNo });
            },

            getGridData: function (custactacKeyno) {
                return call(2089, { custactac_keyno: custactacKeyno });
            },

            getExchangeRate: function (baseCurrencyId, valutaId) {
                return call(1973, {
                    base_currency_id: baseCurrencyId,
                    valuta_id: valutaId
                });
            },

            listAcVoucherTypes: function () {
                return call(1972, {});
            },

            changeAcVoucherType: function (acVoucherTypeId) {
                return call(1975, { acvouchertype_id: acVoucherTypeId });
            },

            listAccountingProcessingFunctions: function () {
                return call(1673, {});
            },

            loadAccountingProcessingCustactList: function (settingAccountingProcessing) {
                return call(1674, settingAccountingProcessing);
            },

            custactaclineDelete: function (dataItem, format) {
                var postData = angular.copy(dataItem);

                angular.forEach(postData, function (data, key) {
                    postData[key] = encoder(data, key, format);
                });

                return call(1981, postData);
            },

            custactaclineSave: function (dataItem, format) {
                var postData = angular.copy(dataItem);

                angular.forEach(postData, function (data, key) {
                    postData[key] = encoder(data, key, format);
                    //for (var s = 0; s < format.length; s++) {
                    //    if (format[s].field === key) {
                    //        if (format[s].type === 'char' || format[s].type === 'varchar' || format[d].type === 'long varchar') {
                    //            postData[key] = btoa(data);
                    //            break;
                    //        } else {
                    //            postData[key] = data;
                    //            break;
                    //        }
                    //    }
                    //}
                    ////postData[key] = btoa(data);
                });

                return call(1988, postData);
            },

            loadPortalUserVariableGet: function (variablename) {
                return call(973, { variablename: variablename });
            },
            loadDocument: function (docarcKeyno) {
                var deferred = $q.defer();

                call(311, { docarc_keyno: docarcKeyno }).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },

            loadDocumentsList: function (argtype, argvalue, searchtext, doctype, onlyFilenames) {
                return call(309, {
                    argtype: argtype,
                    argvalue: argvalue,
                    searchtext: searchtext,
                    doctype: doctype,
                    onlyFilenames: onlyFilenames
                });
            },

            loadLookupSearch: function (searchtext) {
                return call(1900, { searchtext: searchtext });
            },

            lookUpSearchValutas: function (searchField, searchText, row) {
                return call(2123, {
                    searchfield: searchField,
                    searchtext: searchText,
                    row: row
                });
            },

            lookUpSearchProjects: function (parms) {
                var deferred = $q.defer();

                service.isSearchingProjects = true;

                $ihttp.post({
                    method: 1974,
                    parameters: parms
                }).then(function (data) {
                    angular.copy(data, service.selectListProjects);

                    service.isSearchingProjects = false;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            searchAccounts: function (searchtext, searchfield, row) {
                return call(1900, {
                    row: row, searchfield: searchfield, searchtext: searchtext
                })
            },
            searchVatCode: function (searchtext, searchfield, row) {
                return call(2460, {
                    row: row, searchfield: searchfield, searchtext: searchtext
                })
            },
            searchValutaId: function ( searchtext, searchfield, row ) {
                return call(2509, {
                    row: row, searchfield: searchfield, searchtext: searchtext
                })
            },

            loadGridSetup: function () {
                return call(1908, { p2_datatask_keyno: 2089 });
            },

            saveObj: function (settingCustactAc) {
                return call(2091, settingCustactAc);
            },

            duplicateVoucher: function (custactacKeyno) {
                return call(3011, { custactac_keyno: custactacKeyno });
            },

            reverseVoucher: function (custactacKeyno) {
                return call(3012, { custactac_keyno: custactacKeyno });
            },

            /**
             * Retrieves the sum value for debit credit.
             * 
             * @param custactNo the customer account to retrieve the sum debit credit for.
             */
            getSumDebitCredit: (custactNo) => {
                return call(2671, custactNo);
            },

            /**
             * Approves the customer account.
             * 
             * @param custactNo the customer account number to approve
             * @param {'approve' | 'send' | 'reject'} type the type of approval to send.
             * @param accountingfunction the current accounting function tab.
             */
            sendApproval: (custactNo, type, accountingfunction) => {
                return $ihttp.post({
                    method: 2672, parameters: {
                        custact_no: custactNo,
                        attestants: [],
                        comment: '',
                        type: type,
                        accountingfunction: accountingfunction
                    }
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 2970,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
