<h2 mat-dialog-title [ngStyle]="style['title']" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>Rediger rad</h2>
<mat-dialog-content>
    <ng-container *ngFor="let field of data.fields">
        <tt-search
            *ngIf="field.type === 'search'"
            class="col-xs-12 col-md-6 sp-4"
            [ttLabel]="field.label"
            ttLabelView="top"
            [(ttModel)]="field.field"
            (ttModelChange)="change()"
            [ttSearch]="search"
            [ttSearchParm]="field"
            [ttDataName]="field.keyname"
            [ttDataId]="field.field"
            (ttOnSelected)="searchSelected(field, $event)"
            (ttOnClear)="searchSelected(field, null)"
            ttMinLength="1"
        ></tt-search>
        <tt-select
            *ngIf="field.type === 'select'"
            class="col-xs-12 col-md-6 sp-4"
            [ttLabel]="field.label"
            ttLabelView="top"
            [(ttModel)]="field.field"
            (ttModelChange)="change()"
            [ttData]="field.list"
            [ttDataId]="field.id"
            [ttDataName]="field.name"
        ></tt-select>
        <tt-checkbox
            *ngIf="field.type === 'checkbox'"
            class="col-xs-12 col-md-6 sp-4"
            [ttLabel]="field.label"
            ttLabelView="top"
            [(ttModel)]="field.field"
            (ttModelChange)="change()"
            [ttTrueValue]="true"
            [ttFalseValue]="false"
        ></tt-checkbox>
        <tt-input *ngIf="field.type === 'input'" class="col-xs-12 col-md-6 sp-4" [ttLabel]="field.label" ttLabelView="top" [(ttModel)]="field.field" (ttModelChange)="change()"></tt-input>
        <tt-input-number
            *ngIf="field.type === 'number'"
            class="col-xs-12 col-md-6 sp-4"
            [ttLabel]="field.label"
            ttLabelView="top"
            [(ttModel)]="field.field"
            (ttModelChange)="change()"
            [ttDecimals]="field.decimals"
            [ttFormatNumber]="field.formatNumber"
        ></tt-input-number>
        <tt-textarea *ngIf="field.type === 'textarea'" class="col-xs-12 sp-4" [ttLabel]="field.label" ttLabelView="top" [(ttModel)]="field.field" (ttModelChange)="change()"></tt-textarea>
        <!-- TODO: text-editor -->
        <tt-datetime
            *ngIf="field.type === 'datetime'"
            class="col-xs-12 col-md-6 sp-4"
            [ttLabel]="field.label"
            ttLabelView="top"
            [(ttModel)]="field.field"
            (ttModelChange)="change()"
            [ttHideTime]="true"
        ></tt-datetime>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <tt-button class="col-xs-3 sp-2" ttText="cancel" ttType="secondary" (ttClick)="dialogRef.close(false)" [ttStyle]="style"></tt-button>
    <tt-button class="col-xs-3 sp-2" ttText="save" [ttType]="'success'" (ttClick)="close()" [ttStyle]="style"></tt-button>
</mat-dialog-actions>
