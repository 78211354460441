(function () {
    'use strict';

    angular.module("imApp").factory("receivablesService", ['$ihttp', function ($ihttp) {
        let service = {
            getReceivables: function (parms) {
                return $ihttp.post({
                    method: 3004,
                    parameters: parms || {}
                });
            },
            getTransactions: function (edit) {
                return $ihttp.post({
                    method: 3005,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
