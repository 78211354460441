(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButtonSettings', {
        templateUrl: 'views/components/directives/ttButtonSettings/ttButtonSettings.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttText: '@',
            ttTextAlign: '@',
            ttIcon: '@',
            ttIconAlign: '@',
            ttIconPullRight: '@',
            ttLocked: '<',
            ttLockedText: '@',
            ttBadge: '@',
            ttType: '@',
            ttDisabled: '<',
            ttItemId: '@',
            ttStyle: '<',
        },
        controller: ['stateService', 'layoutService', 'eventService', 'utilityService', 'ttDirectivesService', function (stateService, layoutService, eventService, us, ttDirectivesService) {
            var onDestroy = [];
            var vm = this;

            vm.showSettings = false;
            vm.text = 'settings'
            vm.textAlign = 'left';
            vm.type = 'warning';
            vm.icon = 'glyphicon glyphicon-chevron-right';
            vm.iconPullRight = true;

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onClick = function () {
                stateService.go('webpagesettingmenu', {
                    webpage_name: stateService.getCurrentName()
                });
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.show = info.showSettings;
            });

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttText) && us.isStringValue(changes.ttText.currentValue)) {
                    vm.text = changes.ttText.currentValue;
                }

                if (angular.isDefined(changes.ttTextAlign) && us.isStringValue(changes.ttTextAlign.currentValue)) {
                    vm.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttType) && us.isStringValue(changes.ttType.currentValue)) {
                    vm.type = changes.ttType.currentValue;
                }

                if (angular.isDefined(changes.ttIcon) && us.isStringValue(changes.ttIcon.currentValue)) {
                    vm.icon = changes.ttIcon.currentValue;
                }

                if (angular.isDefined(changes.ttIconPullRight)) {
                    vm.iconPullRight = us.toBoolean(changes.ttIconPullRight.currentValue, true);
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
