(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('subscription', {
        templateUrl: 'views/components/views/subscription/subscription.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'subscriptionService', 'economyService', 'logisticService', 'typeaheadService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, subscriptionService, economyService, logisticService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let subscriptionKeyno = $stateParams.subscription_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgSubscriptionCustomer: {
                    selected: 'STAT',
                    buttons: [
                        { id: 'STAT', label: 'gb_subscription_customer_stat', color: 'primary', onClick: () => vm.model.bgSubscriptionCustomer.selected = 'STAT' },
                        { id: 'DELIVERY', label: 'gb_subscription_customer_delivery', color: 'primary', onClick: () => vm.model.bgSubscriptionCustomer.selected = 'DELIVERY' },
                        { id: 'INVOICE', label: 'gb_subscription_customer_invoice', color: 'primary', onClick: () => vm.model.bgSubscriptionCustomer.selected = 'INVOICE' }
                    ]
                },
                bgSubscriptionOther: {
                    selected: 'REPEATINFO',
                    buttons: [
                        { id: 'REPEATINFO', label: 'gb_subscription_other_repeatinfo', color: 'primary', onClick: () => vm.model.bgSubscriptionOther.selected = 'REPEATINFO' },
                        { id: 'TERMS', label: 'gb_subscription_other_terms', color: 'primary', onClick: () => vm.model.bgSubscriptionOther.selected = 'TERMS' },
                        { id: 'NOTE', label: 'gb_subscription_other_note', color: 'primary', onClick: () => vm.model.bgSubscriptionOther.selected = 'NOTE' },
                        { id: 'PRODUCED', label: 'gb_subscription_other_produced', color: 'primary', onClick: () => vm.model.bgSubscriptionOther.selected = 'PRODUCED' },
                        { id: 'DOCUMENTS', label: 'gb_subscription_other_documents', color: 'primary', onClick: () => vm.model.bgSubscriptionOther.selected = 'DOCUMENTS' },
                        { id: 'FUNCTIONS', label: 'gb_subscription_other_functions', color: 'primary', onClick: () => vm.model.bgSubscriptionOther.selected = 'FUNCTIONS' }
                    ]
                },
                statCustomerButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.stat_cust_no }) }
                ],
                deliveryCustomerButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.delivery_cust_no }) }
                ],
                invoiceCustomerButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.invoice_cust_no }) }
                ],
                edit: {},
                selectListRepeatingIntervals: [],
                selectListProduces: [],
                selectListCurrencies: [],
                selectListTaxes: [],
                selectListPayments: [],
                selectListPrices: [],
                selectListCompanies: [],
                selectListDepartments: [],
                itemsListDocuments: [],
                gridReady: false,
                gridProducedReady: false,
                updating: false,
                producingInvoices: false,
                producingOrders: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_subscription_subscriptionline_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'subscriptionline_new',
                                text: 'subscriptionline_new',
                                func: function () {
                                    stateService.go('subscriptionline', {
                                        subscription_keyno: vm.model.edit.subscription_keyno,
                                        subscriptionline_keyno: '0'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.edit.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridProduced = {
                dataTask: {
                    rememberId: 'w_subscription_produced_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

            	subscriptionService.getSetting({ subscription_keyno: subscriptionKeyno }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);

            		$q.all([
            			loadDepartments()
                    ]).then(() => {
                        loadGrid();
                        loadProducedGrid();
                        loadDocuments();

                        deferred.resolve();
                    });
            	});

            	return deferred.promise;
            };

            let loadRepeatingIntervals = function () {
                subscriptionService.listRepeatingIntervals().then(function (result) {
                    angular.copy(result, vm.model.selectListRepeatingIntervals);
                });
            };

            let loadProduces = function () {
                subscriptionService.listProduces().then(function (result) {
                    angular.copy(result, vm.model.selectListProduces);
                });
            };

            let loadCurrencies = function () {
                economyService.listCurrencies().then(function (result) {
                    angular.copy(result, vm.model.selectListCurrencies);
                });
            };

            let loadTaxes = function () {
                economyService.listTaxes().then(function (result) {
                    angular.copy(result, vm.model.selectListTaxes);
                });
            };

            let loadPayments = function () {
                economyService.listPayments().then(function (result) {
                    angular.copy(result, vm.model.selectListPayments);
                });
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (result) {
                    angular.copy(result, vm.model.selectListPrices);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadDepartments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                return logisticService.listDepartment({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListDepartments);
                });
            };

            let loadDocuments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.subscription_keyno) !== true) return;
                
                vm.model.itemsListDocuments = [];
                
                return subscriptionService.listDocuments({
                    argtype: 'subscription_keyno',
                    argvalue: vm.model.edit.subscription_keyno,
                    searchtext: '',
                    doctype: 'd',
                    onlyFilenames: '0'
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListDocuments);
                });
            };

            let retrieveCustomerEdit = function (id) {
                switch (id) {
                    case 'stat_cust_no':
                        if (utilityService.validateParmsValue(vm.model.edit.stat_cust_no) !== true) return;

                        return economyService.getCustomerEdit({ cust_no: vm.model.edit.stat_cust_no }).then(function (response) {
                            vm.model.edit.stat_cust_address = response[0].cust_address;
                            vm.model.edit.tax_keyno = response[0].tax_keyno;
                            vm.model.edit.payment_id = response[0].cust_paym_id;
                            vm.model.edit.pricelist_id = response[0].pricelist_id;
                            vm.model.edit.pricelist_id2 = response[0].pricelist_id2;

                            vm.model.edit.delivery_cust_no = response[0].delivery_cust_no;
                            vm.model.edit.invoice_cust_no = response[0].invoice_cust_no;
                            vm.model.edit.delivery_cust_name = response[0].delivery_cust_name;
                            vm.model.edit.invoice_cust_name = response[0].invoice_cust_name;
                            vm.model.edit.delivery_cust_address = response[0].cust_address;
                            vm.model.edit.invoice_cust_address = response[0].cust_address;
                        });
                        break;
                    case 'delivery_cust_no':
                        if (utilityService.validateParmsValue(vm.model.edit.delivery_cust_no) !== true) return;

                        return economyService.getCustomerEdit({ cust_no: vm.model.edit.delivery_cust_no }).then(function (response) {
                            vm.model.edit.delivery_cust_address = response[0].cust_address;
                            vm.model.edit.tax_keyno = response[0].tax_keyno;
                            vm.model.edit.payment_id = response[0].cust_paym_id;
                            vm.model.edit.pricelist_id = response[0].pricelist_id;
                            vm.model.edit.pricelist_id2 = response[0].pricelist_id2;

                            vm.model.edit.stat_cust_no = response[0].cust_no;
                            vm.model.edit.invoice_cust_no = response[0].invoice_cust_no;
                            vm.model.edit.stat_cust_name = response[0].cust_name;
                            vm.model.edit.invoice_cust_name = response[0].invoice_cust_name;
                            vm.model.edit.stat_cust_address = response[0].cust_address;
                            vm.model.edit.invoice_cust_address = response[0].cust_address;
                        });
                        break;
                    case 'invoice_cust_no':
                        if (utilityService.validateParmsValue(vm.model.edit.invoice_cust_no) !== true) return;

                        return economyService.getCustomerEdit({ cust_no: vm.model.edit.invoice_cust_no }).then(function (response) {
                            vm.model.edit.invoice_cust_address = response[0].cust_address;
                            vm.model.edit.tax_keyno = response[0].tax_keyno;
                            vm.model.edit.payment_id = response[0].cust_paym_id;
                            vm.model.edit.pricelist_id = response[0].pricelist_id;
                            vm.model.edit.pricelist_id2 = response[0].pricelist_id2;

                            vm.model.edit.stat_cust_no = response[0].cust_no;
                            vm.model.edit.delivery_cust_no = response[0].delivery_cust_no;
                            vm.model.edit.stat_cust_name = response[0].cust_name;
                            vm.model.edit.delivery_cust_name = response[0].delivery_cust_name;
                            vm.model.edit.stat_cust_address = response[0].cust_address;
                            vm.model.edit.delivery_cust_address = response[0].cust_address;
                        });
                        break;
                    default:
                        break;
                }
            };

            async function recalcChanges(colname) {
                const response = (await subscriptionService.recalcColumn({ ...vm.model.edit, colname_changed: colname }))[0];

                vm.model.edit = { ...vm.model.edit, ...response };
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3218,
                    parameters: { subscription_keyno: vm.model.edit.subscription_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadProducedGrid = function () {
                vm.gridProduced.dataTask.loadData = {
                    method: 3248,
                    parameters: { subscription_keyno: vm.model.edit.subscription_keyno }
                };

                vm.model.gridProducedReady = true;

                if (vm.gridProduced.gridfunc !== null) vm.gridProduced.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'stat_cust_name':
                    case 'delivery_cust_name':
                    case 'invoice_cust_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'C',
                            lastselect: 'SEARCH'
                        });
                        break;
                    case 'project_name':
                        vm.model.edit.project_name = value;

                        return typeaheadService.lookUpSearchProjects({
                            project_name: vm.model.edit.project_name || '',
                            businessco_no: vm.model.edit.businessco_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'stat_cust_name':
                        if (item) {
                            vm.model.edit.stat_cust_no = item?.item_id ?? '0';
                            vm.model.edit.stat_cust_name = item?.item_name ?? '';

                            retrieveCustomerEdit('stat_cust_no');
                        } else {
                            vm.onTypeaheadClear('stat_cust_name');
                        }
                        break;
                    case 'delivery_cust_name':
                        if (item) {
                            vm.model.edit.delivery_cust_no = item?.item_id ?? '0';
                            vm.model.edit.delivery_cust_name = item?.item_name ?? '';
                            
                            retrieveCustomerEdit('delivery_cust_no');
                        } else {
                            vm.onTypeaheadClear('delivery_cust_name');
                        }
                        break;
                    case 'invoice_cust_name':
                        if (item) {
                            vm.model.edit.invoice_cust_no = item?.item_id ?? '0';
                            vm.model.edit.invoice_cust_name = item?.item_name ?? '';
                            
                            retrieveCustomerEdit('invoice_cust_no');
                        } else {
                            vm.onTypeaheadClear('invoice_cust_name');
                        }
                        break;
                    case 'project_name':
                        if (item) {
                            vm.model.edit.project_keyno = item?.item_id ?? '';
                            vm.model.edit.project_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('project_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'stat_cust_name':
                        vm.model.edit.stat_cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.stat_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('stat_cust_name');
                        }
                        break;
                    case 'delivery_cust_name':
                        vm.model.edit.delivery_cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.delivery_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('delivery_cust_name');
                        }
                        break;
                    case 'invoice_cust_name':
                        vm.model.edit.invoice_cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.invoice_cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('invoice_cust_name');
                        }
                        break;
                    case 'project_name':
                        vm.model.edit.project_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.project_keyno = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('project_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'stat_cust_name':
                        vm.model.edit.stat_cust_no = '0';
                        vm.model.edit.stat_cust_name = '';

                        retrieveCustomerEdit('stat_cust_no');
                        break;
                    case 'delivery_cust_name':
                        vm.model.edit.delivery_cust_no = '0';
                        vm.model.edit.delivery_cust_name = '';
                        
                        retrieveCustomerEdit('delivery_cust_no');
                        break;
                    case 'invoice_cust_name':
                        vm.model.edit.invoice_cust_no = '0';
                        vm.model.edit.invoice_cust_name = '';
                        
                        retrieveCustomerEdit('invoice_cust_no');
                        break;
                    case 'project_name':
                        vm.model.edit.project_keyno = '';
                        vm.model.edit.project_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON UPDATE PROCEDURE FUNCTION CALL

            let updateChanges = function () {
                subscriptionService.updateObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                    loadSetting();
                                }
                            }]
                        });
                    } else {
                        vm.model.updating = false;
                        loadSetting();
                    }
                });
            };

            vm.updateChanges = function () {
                vm.model.updating = true;

                subscriptionService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                    loadSetting();
                                }
                            }]
                        });
                    } else {
                        updateChanges();
                    }
                });
            };

            // #endregion BUTTON UPDATE PROCEDURE FUNCTION CALL

            let produceChanges = function () {
                subscriptionService.produceObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.producingInvoices = false;
                                    vm.model.producingOrders = false;
                                    loadSetting();
                                }
                            }]
                        });
                    } else {
                        vm.model.producingInvoices = false;
                        vm.model.producingOrders = false;
                        loadSetting();
                    }
                });
            };

            vm.produceChanges = function () {
                vm.model.producingInvoices = true;

                subscriptionService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.producingInvoices = false;
                                    loadSetting();
                                }
                            }]
                        });
                    } else {
                        produceChanges();
                    }
                });
            };

            vm.produceOrderChanges = function () {
                vm.model.producingOrders = true;

                subscriptionService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.producingOrders = false;
                                    loadSetting();
                                }
                            }]
                        });
                    } else {
                        produceChanges();
                    }
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                subscriptionService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgSubscriptionCustomer = function (value) {
                vm.model.bgSubscriptionCustomer.selected = value;
            };

            vm.selectBgSubscriptionOther = function (value) {
                vm.model.bgSubscriptionOther.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        loadDepartments();
                        break;
                    case 'repeat_every':
                    case 'repeat_interval_id':
                    case 'next_period_fom':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        recalcChanges(id);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
                loadRepeatingIntervals();
                loadProduces();
                loadCurrencies();
                loadTaxes();
                loadPayments();
                loadPrices();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
