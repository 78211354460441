(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('priceGrid', {
        templateUrl: 'views/components/views/priceGrid/priceGrid.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', '$q', 'priceGridService', 'modalDynamicViewFactory', 'utilityService', 'translateService', 'modalService', function ($timeout, $q, priceGridService, modalDynamicViewFactory, utilityService, translateService, modalService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.resetColumnName = function () {
                vm.model.setting.colname = '';
                vm.model.setting.elementtype = '';
            };

            vm.model = {
                columnNameButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: vm.resetColumnName } }
                ],
                gridReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                setting: {},
                selectListColumns: [],
                runProgress: '',
                lockedSave: false,
                lockedChange: false,
                copyingPrices: false
            };

            vm.model.setting.new_value_DA = "";

            vm.grid = {
                dataTask: {
                    rememberId: 'w_priceGrid',                          // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {
                        method: null,
                        parameters: null
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autosave: false,
                        confirm: false
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    editColumns: [],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            { name: 'bulkEdit', text: 'tt_bulk_col_edit', func: function () { callBulkEditPopup(true); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } }
                            //{ name: 'duplicate', text: 'pricegrid_duplicate', func: function () { vm.duplicatePrice(); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: true /*function () { return hasDirty(); }*/ }
                            //{ name: 'ediTranspReturn', text: 'tt_edi_transporter_return', func: function (data) { ediTranspReturn(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'planningReady', text: 'tt_po_sp_planning_ready', func: function (data) { planningReady(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'setProdplace', text: 'tt_po_sp_set_prodplace', func: function (data) { setProdplace(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'goToShipment', text: '', func: function (data) { goToShipment(data); }, icon: 'far-truck-container', disabled: function (data) { return goToShipmentDisability(data); } },
                            //{ name: 'goToPoh', text: '', func: function (data) { goToPoh(data); }, icon: 'far-industry', disabled: function (data) { return goToPohDisability(data); } }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: '100%',                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') { // when a cell is clicked
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') { // on cell close (on edit finished)
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') { // searchable dropdown (autocompletion)
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'CheckboxBoxClick') {  // When a checkbox is clicked

                }
            };

            // #endregion OPTIONFUNC

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region TOOLBAR BUTTON VALIDATION

            let getIsSelectedItemsParm = function (key) {
                let dirtyItems = vm.grid.gridfunc?.getDirtyRows();
                let isSelectedItems = [];

                if (angular.isUndefined(key)) key = 'price_keyno';

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true && angular.isDefined(item[key])) isSelectedItems.push(item[key]);
                });

                return isSelectedItems;
            };

            let getIsSelectedItems = function () {
                let dirtyItems = vm.grid.gridfunc?.getDirtyRows();
                let isSelectedItems = [];
                console.log(dirtyItems);
                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                });

                return isSelectedItems;
            }

            let hasDirty = function () {
                let parmItems = getIsSelectedItems();

                if (parmItems.length > 0) return false;

                return true;
            };

            let disable = function () {
                return true;
                //if (isSaveRunning) return true;
            };

            // #endregion TOOLBAR BUTTON VALIDATION

            // #region TOOLBAR BUTTONFUNC

            var callBulkEditPopup = function (rebind, remValue) {
                var editableCols = [];

                angular.forEach(vm.grid.gridfunc?.getColumnSchema(), function (col) {
                    if (col.from === 'is_selected') return;
                    if (angular.isDefined(col.editable) && col.editable !== false) {
                        var editType = null;
                        switch (col.type) {
                            case 'text':
                            case 'string':
                                editType = 'input';
                                break;
                            case 'date':
                            case 'datetime':
                                editType = 'datetime';
                                break;
                            case 'number':
                                editType = 'number2';
                                break;
                        }

                        if (editType !== null) {
                            //console.log(ed)
                            editableCols.push({
                                name: col.from,
                                info: {
                                    label: col.from,
                                    placeholder: '',
                                    type: editType,
                                    translate: false,
                                },
                                data: {
                                    field: '',
                                },
                                disabled: function () { return false; }
                            });
                        }
                    }
                });


                remValue = angular.isDefined(remValue) ? remValue : null;
                //initBulkEditPopup(remValue).then(function (config) {
                initBulkEditPopup(editableCols).then(function (config) {

                    console.log('callBulkEditPopup finished');
                    console.dir(config);

                    var hasChanges = false;

                    angular.forEach(config, function (item) {
                        if (item.data.field !== '') {
                            hasChanges = true;
                        }
                    });

                    if (hasChanges) {
                        var dirtyRows = vm.grid.gridfunc?.getDirtyRows();

                        angular.forEach(dirtyRows, function (row) {
                            if (angular.isDefined(row.is_selected) && row.is_selected === true) {
                                angular.forEach(config, function (item) {
                                    if (item.data.field === '') return;

                                    row[item.info.label] = item.data.field === ' ' ? '' : item.data.field;
                                    if (!!row?.dirtyFields) {
                                        row.dirtyFields[item.info.label] = true;
                                    }
                                });
                            }
                        });

                        vm.grid.gridfunc?.refresh();
                    }

                    //let allData = vm.grid.gridfunc.getDirtyRows();
                    //angular.forEach(allData, function (item) {
                    //    if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                    //        item[vm.model.setting.colname] = newValue;
                    //    }
                    //});



                    //    vm.icbConfig.data = {
                    //        stockplace_id: config[0].data.field,
                    //        from_date: config[1].data.date_fom,
                    //        to_date: config[1].data.date_tom,
                    //        cust_grp_id: config[2].data.field,
                    //        cust_coop_id: config[3].data.field,
                    //        cust_no: config[4].data.field,
                    //        prod_grp_id: getSelectedItemsAsString(config[5].data.list)
                    //    };

                    //    //vm.grid.dataTask.loadData.parameters = vm.icbConfig.data;

                    //    //rememberFunc();

                    //    //vm.gridReady = true;

                    //if (rebind === true) vm.grid.gridfunc.rebind();
                });
            };

            var initBulkEditPopup = function (remValue) {
                var deferred = $q.defer();

                var icbConfigPopup = modalDynamicViewFactory.$create({
                    data: remValue
                });

                icbConfigPopup.show().then(function (config) {
                    return deferred.resolve(config);
                });

                return deferred.promise;
            };

            var initBulkEditPopup2 = function (remValue) {
                var deferred = $q.defer();
                var promises = [];

                promises.push(invoiceCustomerBonusService.getStockplaces({}));
                promises.push(invoiceCustomerBonusService.getCustomerGroups());
                promises.push(invoiceCustomerBonusService.getCoops());
                promises.push(invoiceCustomerBonusService.getProductGroups());

                remValue = angular.isArray(remValue) && angular.isObject(remValue[0]) && angular.isDefined(remValue[0].variablevalue) && remValue[0].variablevalue !== '' ? remValue : null;
                var fromJsonValue = remValue === null ? vm.icbConfig.data : angular.fromJson(remValue[0].variablevalue);

                console.log('remValue');
                console.dir(fromJsonValue);

                $q.all(promises).then(function (response) {
                    var icbConfigPopup = modalDynamicViewFactory.$create({
                        //url: '',
                        data: [
                            {
                                name: 'stockplace',
                                info: {
                                    label: 'stockplace',
                                    placeholder: '',
                                    type: 'select',
                                    translate: false,
                                },
                                data: {
                                    field: fromJsonValue !== null && angular.isDefined(fromJsonValue['stockplace_id']) ? fromJsonValue['stockplace_id'] : '',
                                    list: response[0],
                                    id: 'stockplace_id',
                                    name: 'stockplace_name'
                                },
                                disabled: function () { return false; }
                            },
                            {
                                name: 'datefomtom',
                                info: {
                                    label: 'date_fom_tom',
                                    placeholder: '',
                                    type: 'date',
                                    translate: false,
                                },
                                data: {
                                    date_fom: fromJsonValue !== null && angular.isDefined(fromJsonValue['from_date']) ? fromJsonValue['from_date'] : '2022-01-01',
                                    date_tom: fromJsonValue !== null && angular.isDefined(fromJsonValue['to_date']) ? fromJsonValue['to_date'] : '2022-01-31',
                                    bindex: '3'
                                },
                                disabled: function () { return false; }
                            },
                            {
                                name: 'customergroup',
                                info: {
                                    label: 'custgrp',
                                    placeholder: '',
                                    type: 'select',
                                    translate: false,
                                },
                                data: {
                                    field: fromJsonValue !== null && angular.isDefined(fromJsonValue['cust_grp_id']) ? fromJsonValue['cust_grp_id'] : '',
                                    list: response[1],
                                    id: 'custgrp_id',
                                    name: 'custgrp_name'
                                },
                                disabled: function () { return false; }
                            },
                            {
                                name: 'coop',
                                info: {
                                    label: 'coop',
                                    placeholder: '',
                                    type: 'select',
                                    translate: false,
                                },
                                data: {
                                    field: fromJsonValue !== null && angular.isDefined(fromJsonValue['cust_coop_id']) ? fromJsonValue['cust_coop_id'] : '',
                                    list: response[2],
                                    id: 'coop_id',
                                    name: 'coop_name'
                                },
                                disabled: function () { return false; }
                            },
                            {
                                name: 'custno',
                                info: {
                                    label: 'write_custno_label',
                                    placeholder: '',
                                    type: 'input',
                                    translate: false,
                                },
                                data: {
                                    field: fromJsonValue !== null && angular.isDefined(fromJsonValue['cust_no']) ? fromJsonValue['cust_no'] : '',
                                },
                                disabled: function () { return false; }
                            },
                            {
                                name: 'prodgrp',
                                info: {
                                    label: 'prodgrp_label',
                                    placeholder: '',
                                    type: 'list',
                                    translate: false,
                                },
                                data: {
                                    field: fromJsonValue !== null && angular.isDefined(fromJsonValue['prod_grp_id']) ? fromJsonValue['prod_grp_id'] : '',
                                    list: response[3],
                                    id: 'item_id',
                                    name: 'item_name',
                                    filter: '',
                                    allSelected: false
                                },
                                disabled: function () { return false; }
                            }
                        ]
                    });

                    icbConfigPopup.show().then(function (config) {
                        return deferred.resolve(config);
                    });
                });

                return deferred.promise;
            };

            // #endregion TOOLBAR BUTTONFUNC

            // #region LOAD PROCEDURE CALLS

            var loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 2355,
                    parameters: {}
                };

                vm.model.gridReady = true;
            };

            var loadMetaColumns = function () {
                vm.model.selectListColumns = [];

                priceGridService.listEditColumns().then(function (data) {
                    angular.copy(data, vm.model.selectListColumns);
                });
            };

            loadGrid();
            loadMetaColumns();

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            //var rememberFunc = function (parm) {
            //    poShipmentPlanningService.remember(null, vm.seasonyears.rememberId, vm.seasonyears.field);
            //};

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region DUPLICATE

            vm.duplicatePrice = function () {
                let allData = vm.grid.gridfunc?.getAllRows();
                angular.forEach(allData, async function (item) {
                    if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                        await priceGridService.duplicateRow(item);
                    }
                });
                vm.retrieveChanges();
            };

            // #endregion DUPLICATE

            // #region CHANGES

            var loadMetaColumn = function () {
                if (utilityService.validateParmsValue(vm.model.setting.colname) !== true) return;
                angular.forEach(vm.model.selectListColumns, function (item) {
                    if (item.item_id === vm.model.setting.colname) {
                        let colname_parts = item.item_name.split(' ');
                        vm.model.setting.elementtype = colname_parts[colname_parts.length - 1].replace(')', '');
                    }
                });
                //TODO: REPLACE ITEM_ID WITH ITEM_NAME
            };

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'colname':
                        vm.model.setting.colname = value;
                        if (utilityService.validateParmsValue(vm.model.setting.colname) !== true) return;
                        loadMetaColumn();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            vm.retrieveChanges = function () {
                priceGridService.getPriceGrid();
                vm.grid.gridfunc?.rebind();
            };

            vm.changeSelected = function () {
                var newValue = null;

                switch (vm.model.setting.elementtype) {
                    case 'CB':
                        newValue = vm.model.setting.new_value_CB === 1;
                        break;
                    case 'DA':
                        if (angular.isDefined(vm.model.setting.new_value_DA)) {
                            if (vm.model.setting.new_value_DA.length > 0) {
                                newValue = new Date(vm.model.setting.new_value_DA);
                            }
                        } else {
                            newValue = new Date();
                        }
                        break;
                    case 'DT':
                        newValue = new Date(vm.model.setting.new_value_DT);
                        break;
                    case 'HE':
                        newValue = vm.model.setting.new_value_HE;
                        break;
                    case 'N0':
                        newValue = angular.isDefined(vm.model.setting.new_value_N0) ? parseFloat(vm.model.setting.new_value_N0) : 0;
                        break;
                    case 'N1':
                        newValue = angular.isDefined(vm.model.setting.new_value_N1) ? parseFloat(vm.model.setting.new_value_N1) : 0;
                        break;
                    case 'N2':
                        newValue = angular.isDefined(vm.model.setting.new_value_N2) ? parseFloat(vm.model.setting.new_value_N2) : 0;
                        break;
                    case 'T':
                        newValue = vm.model.setting.new_value_T;
                        break;
                    case 'TL':
                        newValue = vm.model.setting.new_value_TL;
                        break;
                    case 'TI':
                        newValue = vm.model.setting.new_value_TI;
                        break;
                    default:
                        break;
                }
                let allData = vm.grid.gridfunc?.getAllRows();
                angular.forEach(allData, function (item) {
                    if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                        item[vm.model.setting.colname] = newValue;
                    }
                });

                vm.grid.gridfunc.refresh();
            };

            // #endregion CHANGES

            // #region SAVE

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'price_keyno: ' + items[step].price_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        var parms = JSON.parse(JSON.stringify(items[step]));

                        priceGridService.saveSelected(parms).then(function (response) {
                            if (response.errorcode !== 0) {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            vm.saveChanges = function () {
                vm.grid.gridfunc.saveChanges(true);
                vm.grid.gridfunc.rebind();
                //vm.model.lockedSave = true;

                //vm.model.runProgress = 'START';
                //vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.field = '';
                //vm.model.progressInfo.max = 0;
                //vm.model.progressInfo.step = 0;
                //vm.model.progressInfo.finishclose = '1';
                //vm.model.progressInfo.abortclose = false;

                //var dirtyRows = vm.grid.gridfunc.getDirtyRows();
                //vm.model.progressInfo.max = dirtyRows.length;
                //vm.activateProgress(dirtyRows);

                //modalService.showProgress(vm.model.progressInfo).then(function (value) {
                //    vm.model.runProgress = value;

                //    if (vm.model.runProgress === 'ABORT') {
                //        vm.model.lockedSave = false;
                //    } else if (vm.model.runProgress === 'FINISH') {
                //        vm.model.lockedSave = false;
                //        loadGrid();
                //        vm.grid.gridfunc.rebind();
                //    }
                //});
            };

            // #endregion SAVE
        }]
    });
})();
