//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('lastUsed', {
//        templateUrl: 'views/components/views/lastUsed/lastUsed.template.html?v=' + module.version,
//        controllerAs: 'vm',
//        controller: [function() {
    
//            var vm = this;
    
    
    
//        }]
//    });
//})();
