(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('tailorAccessData', {
        templateUrl: 'views/components/views/tailorAccessData/tailorAccessData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'tailorAccessDataService', 'typeaheadService', function ($stateParams, $q, stateService, modalService, translateService, tailorAccessDataService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tailorAccessDataKeyNo = $stateParams.tailoraccessdata_keyno;
            let tailorAccessKeyNo = $stateParams.tailoraccess_keyno;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                tailorAccessDataKeyNo: tailorAccessDataKeyNo,
                editTailoredAccessData: {},
                selectListPKeyTypes: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region SHOW DIALOGUE BOX FUNCTION

            let showMessage = function (type, title, message, label, cssClass) {
                let deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            };

            // #endregion SHOW DIALOGUE BOX FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();

                tailorAccessDataService.getTailorAccessData({
                    tailoraccessdata_keyno: tailorAccessDataKeyNo,
                    tailoraccess_keyno: tailorAccessKeyNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.editTailoredAccessData);

                    $q.all([
                        loadPKeyTypes()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadPKeyTypes = function () {
                return tailorAccessDataService.listPKeyTypes(vm.model.editTailoredAccessData).then(function (result) {
                    angular.copy(result, vm.model.selectListPKeyTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH MULTI PROCEDURE FUNCTION CALLS

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'resource_searchtext':
                        vm.model.editTailoredAccessData.resource_searchtext = value;

                        return typeaheadService.lookUpResources({ resource_searchtext: vm.model.editTailoredAccessData.resource_searchtext || '' });
                        break;
                    case 'portal_user_name':
                        vm.model.editTailoredAccessData.portal_user_name = value;

                        return typeaheadService.lookUpPortalUser({ portal_user_name: vm.model.editTailoredAccessData.portal_user_name || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH MULTI PROCEDURE FUNCTION CALLS

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item, id) {
                switch (id) {
                    case 'resource_searchtext':
                        if (item) {
                            vm.model.editTailoredAccessData.pkeyvalue = item?.item_id ?? '0';
                            vm.model.editTailoredAccessData.resource_id = item?.item_id ?? '0';
                            vm.model.editTailoredAccessData.resource_searchtext = item?.item_name ?? '';
                        } else {
                            vm.onClear('resource_searchtext');
                        }
                        break;
                    case 'portal_user_name':
                        if (item) {
                            vm.model.editTailoredAccessData.pkeyvalue = item?.item_id ?? '0';
                            vm.model.editTailoredAccessData.portal_user_keyno = item?.item_id ?? '0';
                            vm.model.editTailoredAccessData.portal_user_name = item?.item_name ?? '';
                        } else {
                            vm.onClear('portal_user_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedTypeahead = function (value, item, id) {
                switch (id) {
                    case 'resource_searchtext':
                        vm.model.editTailoredAccessData.resource_searchtext = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.editTailoredAccessData.pkeyvalue = item.item_id;
                                vm.model.editTailoredAccessData.resource_id = item.item_id;
                            }
                        } else {
                            vm.onClear('resource_searchtext');
                        }
                        break;
                    case 'portal_user_name':
                        vm.model.editTailoredAccessData.portal_user_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.editTailoredAccessData.pkeyvalue = item.item_id;
                                vm.model.editTailoredAccessData.portal_user_keyno = item.item_id;
                            }
                        } else {
                            vm.onClear('portal_user_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function (id) {
                switch (id) {
                    case 'resource_searchtext':
                        vm.model.editTailoredAccessData.pkeyvalue = '0';
                        vm.model.editTailoredAccessData.resource_id = '0';
                        vm.model.editTailoredAccessData.resource_searchtext = '';
                        break;
                    case 'portal_user_name':
                        vm.model.editTailoredAccessData.pkeyvalue = '0';
                        vm.model.editTailoredAccessData.portal_user_keyno = '0';
                        vm.model.editTailoredAccessData.portal_user_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                tailorAccessDataService.saveObj(vm.model.editTailoredAccessData).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        showMessage('warning', translations.error, response[0].errormessage, translations.ok, 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    tailorAccessDataService.deleteObj(vm.model.editTailoredAccessData).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            showMessage('warning', translations.error, response[0].errormessage, translations.ok, 'btn-warning').then(function () {
                                vm.model.lockedDelete = false;
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
