(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("dbPrinterService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            list: function () {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 337,
                    parameters: {}
                }).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();