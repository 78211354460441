(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2UpdateScriptEdit', {
        templateUrl: 'views/components/views/p2UpdateScriptEdit/p2UpdateScriptEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2UpdateScriptEditService', 'p2UpdateScriptsService', function ($stateParams, stateService, p2UpdateScriptEditService, p2UpdateScriptsService) {
            let vm = this;
            let p2UpdateScriptKeyno = $stateParams.p2_updatescript_keyno;

            vm.model = {
                edit: {},
                selectListRunBatches: []
            };

            let loadSetting = function () {
                p2UpdateScriptEditService.getUpdateScript({ p2_updatescript_keyno: p2UpdateScriptKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            let loadRunBatches = function () {
                p2UpdateScriptsService.listRunBatches({ add_all: '0' }).then(function (result) {
                    angular.copy(result, vm.model.selectListRunBatches);
                });
            };

            vm.saveChanges = function () {
                p2UpdateScriptEditService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadSetting();
                loadRunBatches();
            };
        }]
    });
})();
