(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButtonactions', {
        templateUrl: 'views/components/dynamics/components/ttButtonactions/ttButtonactions.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttOptions: '<',
            ttLabel: '@',
            ttChange: '&'
        },
        controller: ['$element', '$uibModal', '$q', 'base64', 'layoutService', 'translateService', 'eventService', 'utilityService', 'ttButtonactionsService', 'ieScreenBlockService', function ($element, $uibModal, $q, base64, layoutService, translateService, eventService, us, ttButtonactionsService, ieScreenBlockService) {
            var vm = this;

            let rememberIdGrid = '';
            let onDestroy = [];
            let labelAlwaysOnTop = false;

            let setClasses = function () {
                vm.class.label = labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2 col-xl-2';
                vm.class.input = labelAlwaysOnTop === true || vm.hideLabel ? 'col-xs-12' : 'col-xs-12 col-sm-10 col-xl-10';
            };

            vm.style = {
                label: {}
            };

            vm.class = {
                label: '',
                input: ''
            };

            vm.translations = {
                ttConfirmDeleteRecordMessage: ''
            };

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2564,
                        parameters: function () {
                            var parms = {
                                tt_dynamicproperty_keyno: vm.ttOptions.tt_dynamicproperty_keyno
                            }

                            return parms;
                        }
                    },
                    addRow: {
                        autoSave: true,
                        pre: onAddRow
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: true,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        buttons: [
                            { name: 'lineDelete', text: '', func: onDeleteRow, icon: 'far-times', type: 'danger' },
                            { name: 'lineEdit', text: '', func: onEditRow, icon: 'far-pencil' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                labelAlwaysOnTop = info.labelAlwaysOnTop;

                setClasses();
            });

            vm.$onInit = function () {
                vm.translations[vm.ttOptions.label] = '';

                translateService.translateBatch(vm.translations).then(function (translations) {
                    angular.forEach(translations, function (value, key) {
                        if (key === vm.ttOptions.label) {
                            vm.translations.ttLabel = value;
                        } else {
                            vm.translations[key] = value;
                        }
                    });

                    delete vm.translations[vm.ttOptions.label];
                });
            }

            vm.$onDestroy = function () {
                eventService.destroy(onDestroy);
            };

            function onAddRow(dataItem) {
                var deferred = $q.defer();

                if (angular.isUndefined(dataItem.keyno)) {
                    dataItem.keyno = 0;
                    dataItem.tt_dynamicproperty_keyno = vm.ttOptions.tt_dynamicproperty_keyno;
                    dataItem.order = 0;
                    dataItem.tt_buttonactiontype_keyno = 0;
                    dataItem.p2_datatask_keyno = 0;
                    dataItem.gridfunc_name = '';
                    dataItem.parms_base64 = '';
                    dataItem.remarks = '';
                };

                $uibModal.open({
                    component: 'ttButtonactionEdit',
                    resolve: {
                        parameters: {
                            response: function (response) {
                                ieScreenBlockService.start();

                                angular.copy(response, dataItem);

                                ttButtonactionsService.update(response).then(function () {
                                    vm.gridOptions.gridfunc.read();

                                    if (angular.isFunction(vm.ttChange)) {
                                        let changeData = { tt_dynamicproperty_keyno: response.tt_dynamicproperty_keyno };

                                        let encodedData = base64.urlencode(angular.toJson(changeData));

                                        vm.ttChange({ $value: encodedData, $modelId: us.getAttrId($element, 'tt-options') });
                                    }

                                    deferred.resolve();

                                    ieScreenBlockService.stop();
                                });
                            },
                            instance_id: vm.ttOptions.instance_id,
                            tt_dynamicproperty_keyno: vm.ttOptions.tt_dynamicproperty_keyno,
                            dataItem: dataItem
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                return deferred.promise;
            };

            function onEditRow(dataItem) {
                $uibModal.open({
                    component: 'ttButtonactionEdit',
                    resolve: {
                        parameters: {
                            response: function (response) {
                                ieScreenBlockService.start();

                                angular.copy(response, dataItem);

                                ttButtonactionsService.update(response).then(function () {
                                    vm.gridOptions.gridfunc.read();

                                    if (angular.isFunction(vm.ttChange)) {
                                        let changeData = { tt_dynamicproperty_keyno: response.tt_dynamicproperty_keyno };

                                        let encodedData = base64.urlencode(angular.toJson(changeData));

                                        vm.ttChange({ $value: encodedData, $modelId: us.getAttrId($element, 'tt-options') });
                                    }

                                    ieScreenBlockService.stop();
                                });
                            },
                            instance_id: vm.ttOptions.instance_id,
                            tt_dynamicproperty_keyno: vm.ttOptions.tt_dynamicproperty_keyno,
                            dataItem: dataItem
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });
            };

            function onDeleteRow(dataItem) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return vm.translations.ttConfirmDeleteRecordMessage;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        return;
                    }

                    ieScreenBlockService.start();

                    ttButtonactionsService.delete(dataItem.keyno).then(function () {
                        vm.gridOptions.gridfunc.read();

                        ieScreenBlockService.stop();
                    });
                });
            }; 
        }]
    });
})();
