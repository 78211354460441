(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("biReportEmbedService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            get: function (param) {
                return p2DataTaskService.call(2457, {
                    bi_report_keyno: param
                });
            }
        }

        return service;
    }]);
})();
