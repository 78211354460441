<h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>{{ translations["ttgrid_modal_layouts_heading"] }}</h2>
<mat-dialog-content>
    <p _ngcontent-ng-cli-universal-c34="" class="tt-grid-modal__new-message">{{ errormessage }}</p>
    <div class="tt-grid-modal__new tt-input__group" [ngClass]="!!errormessage ? 'tt-grid-modal__new-input--danger' : ''">
        <input
            class="tt-grid-modal__new-input tt-input__form-control"
            [(ngModel)]="layoutname"
            (input)="errormessage = ''"
            [placeholder]="translations['ttgrid_modal_layouts_placeholder']"
            [ngStyle]="style['input']"
        />
        <span *ngIf="!!errormessage" class="fa-fw fas fa-exclamation-circle tt-input__icon tt-grid-modal__new-input-error-icon"></span>
        <button
            tabindex="-1"
            class="tt-grid-modal__button--new tt-input__button tt-input__button--success"
            (click)="createColumnLayout()"
            [ngStyle]="style['addButton']"
            [matTooltip]="translations['new_layout']"
            matTooltipShowDelay="1000"
        >
            <span class="far fa-fw fa-plus"></span>
        </button>
    </div>
    <div *ngIf="ready; else spinner">
        <ul *ngIf="columnLayouts.length > 0" class="tt-grid-modal__ul">
            <li class="tt-grid-modal__li" *ngFor="let layout of columnLayouts">
                <button
                    class="tt-grid-modal__button tt-grid-modal__button--name"
                    [ngClass]="layout.willGetDeleted === true ? 'tt-grid-modal__button--name--delete' : ''"
                    (click)="applyColumnLayout(layout)"
                    [ngStyle]="style['item']"
                >
                    <span>
                        <span *ngIf="layout.willGetDeleted === true" class="fas fa-exclamation"></span>
                        <span *ngIf="layout.willGetDeleted === true" class="fas fa-exclamation"></span>
                        <span *ngIf="layout.willGetDeleted === true" class="fas fa-exclamation"></span>
                        <span>
                            {{ layout.gridlayout_name }}
                        </span>
                    </span>
                    <span
                        *ngIf="layout.isActive === true && layout.willGetDeleted === false"
                        [matTooltip]="translations['current_layout_tooltip']"
                        matTooltipShowDelay="1000"
                        class="fas fa-fw fa-check-circle"
                    ></span>
                </button>

                <button
                    *ngIf="layout.isActive === true && (hasCurrentLayout === false || layoutname !== '')"
                    [matTooltip]="translations['save']"
                    matTooltipShowDelay="1000"
                    class="tt-grid-modal__button tt-grid-modal__button--restore"
                    (click)="updateActiveLayout(layout)"
                >
                    <span class="fas fa-fw fa-save"></span>
                </button>

                <ng-container *ngIf="layout.isActive !== true || (hasCurrentLayout === true && layoutname === '')">
                    <button
                        *ngIf="layout.willGetDeleted === true"
                        [matTooltip]="translations['restore']"
                        matTooltipShowDelay="1000"
                        class="tt-grid-modal__button tt-grid-modal__button--restore"
                        (click)="layout.willGetDeleted = false"
                    >
                        <span class="fas fa-fw fa-trash-undo"></span>
                    </button>
                    <button
                        *ngIf="layout.willGetDeleted !== true"
                        [matTooltip]="translations['delete']"
                        matTooltipShowDelay="1000"
                        class="tt-grid-modal__button tt-grid-modal__button--delete"
                        (click)="layout.willGetDeleted = true"
                    >
                        <span class="fas fa-fw fa-trash"></span>
                    </button>
                </ng-container>
            </li>
        </ul>
        <p *ngIf="columnLayouts.length === 0" class="tt-grid-modal__no-layout">{{ translations["ttgrid_modal_layouts_placeholder_no_layout"] }}</p>
    </div>
</mat-dialog-content>

<ng-template #spinner>
    <tt-grid-loading-overlay class="tt-grid-modal__no-layout" style="width: 100%; display: flex; justify-content: center"></tt-grid-loading-overlay>
</ng-template>
