(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStates', {
        templateUrl: 'views/components/views/oeeStates/oeeStates.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['oeeStatesService', 'stateService', function(oeeStatesService, stateService) {
            var vm = this;
            
            oeeStatesService.loadP2OeeStatesList().then(function () {
                vm.p2_oeestates_list = oeeStatesService.p2_oeestates_list;
            });
            
            vm.createNewOeeStateEdit = function () {
                stateService.go('oeestateedit', {
                    oeestate_keyno: '0'
                });
            };
        }]
    });
})();