(function () {
    'use strict';

    angular.module("imApp").factory("accountingBuntComponentService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1929,
                    parameters: parms || {}
                });
            },
            listAccountingBuntInvoices: function (accountingbunt_keyno) {
                return p2DataTaskService.call(1948, {
                    accountingbunt_keyno: accountingbunt_keyno
                });
            },
            makeInvoice: function (item_id) {
                return p2DataTaskService.call(1950, {
                    item_id: item_id
                });
            },
            exportAccountingBunt: function (edit) {
                return $ihttp.post({
                    method: 1953,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1930,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
