(function() {
    'use strict';

    angular.module("imApp").factory("textileModelsService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var service = {
            loadCollections: function () {
                return $ihttp.post({
                    method: 2261
                });
            },
            searchTextilemodelsKeyname: function (value, collection) {
                return call(2931, { searchtext: value, collection_keyno: collection });
            }
        }

        return service;
    }]);
})();
