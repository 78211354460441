import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {
    private _current: BehaviorSubject<string> = new BehaviorSubject('');

    constructor() { }

    public get Current(): Observable<string> {
        return this._current.asObservable();
    }

    public get current(): string {
        return this._current.getValue();
    }

    public SetCurrent(current: string) {
        this._current.next(current);
    }

    public sizes = {
        //0:   0,
        //025: 2.08333333,
        //050: 4.16666665,
        //075: 6.25,
        1: 8.33333333,
        //125: 10.41666667,
        //150: 12.5,
        //175: 14.58333333,
        2: 16.66666667,
        //225: 18.75,
        //250: 20.83333333,
        //275: 22.91666667,
        3: 25,
        //325: 27.08333333,
        //350: 29.16666667,
        //375: 31.25,
        4: 33.33333333,
        //425: 35.41666667,
        //450: 37.5,
        //475: 39.58333333,
        5: 41.66666667,
        //525: 43.75,
        //550: 45.83333333,
        //575: 47.91666667,
        6: 50,
        //625: 52.08333333,
        //650: 54.16666667,
        //675: 56.25,
        7: 58.33333333,
        //725: 60.41666667,
        //750: 62.5,
        //775: 64.58333333,
        8: 66.66666667,
        //825: 68.75,
        //850: 70.83333333,
        //875: 72.91666667,
        9: 75,
        //925: 77.08333333,
        //950: 79.16666667,
        //975: 81.25,
        10: 83.33333333,
        //1025: 85.41666667,
        //1050: 87.5,
        //1075: 89.58333333,
        11: 91.66666667,
        //1125: 93.75,
        //1150: 95.83333333,
        //1175: 97.91666667,
        12: 100
    };
}
