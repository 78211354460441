<tt-page>
    <tt-page-heading class="col-xs-12 sp-0" [ttModel]="get.page_heading"></tt-page-heading>
    <div class="col-xs-12 sp-0 flex-1" style="display: flex">
        <div class="col-md-3 col-xs-12 sp-0 picturebanks__edit">
            <tt-card class="col-xs-12 p-5">
                <tt-file-uploader [(ttFiles)]="files" ttMultiple ttDropZone></tt-file-uploader>
                <tt-button
                    class="col-xs-12 sp-0"
                    ttText="upload_pictures"
                    [ttLoading]="locks.upload"
                    ttLoadingText=""
                    (ttClick)="uploadAndSaveFiles()"
                    ttIcon="far fa-cloud-upload"
                    [ttDisabled]="files.length === 0"
                ></tt-button>
            </tt-card>

            <!-- temporary til tt-list is done-->
            <ul class="picturebanks-list__ul col-xs-12 sp-5">
                <li *ngFor="let img of pictures" class="picturebanks-list__li">
                    <button (click)="gotoPictureBank(img)">
                        <img class="picturebanks-list__img" [src]="img.item_thumb + '?thumbnail=' + 32" />
                        <span [ngClass]="getIconClassesFromItem(img)" [style.color]="img.item_glyphicon_color"></span>
                        <span class="picturebanks-list__main-text">{{ img.item_name }}</span>
                    </button>
                </li>
            </ul>
        </div>
        <tt-picture-bank
            *ngIf="selectedPictureBank !== '0'"
            class="col-xs-12 p-5 picturebanks__picturebank"
            [pictureBankKeyno]="selectedPictureBank"
            (pictureBankDeleted)="loadPictures()"
            (pictureBankSave)="loadPictures()"
        ></tt-picture-bank>
    </div>
</tt-page>
