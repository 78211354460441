(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderHeadDeleteOl', {
        templateUrl: 'views/components/views/orderHeadDeleteOl/orderHeadDeleteOl.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'orderHeadDeleteOlService', function ($stateParams, stateService, utilityService, orderHeadDeleteOlService) {
            
            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                settingDeletedOrderLines: {},
                itemsListDeletedOrderLines: []
            };
            
            // ## LOAD PROCEDURE CALLS ##
            
            orderHeadDeleteOlService.loadOrderHeadDeleteOlGet(vm.model.orderInternalNo).then(function (data) {
                angular.copy(data[0], vm.model.settingDeletedOrderLines);
                
                loadOrderHeadDeleteOls();
            });
            
            var loadOrderHeadDeleteOls = function () {
                if (utilityService.validateParmsValue(vm.model.settingDeletedOrderLines.searchtext, true) !== true) return;
                
                vm.model.itemsListDeletedOrderLines = [];
                
                orderHeadDeleteOlService.loadOrderHeadDeleteOlList(vm.model.settingDeletedOrderLines).then(function (data) {
                    angular.copy(data, vm.model.itemsListDeletedOrderLines);
                });
            };
            
            // ## BUTTON LIST FUNCTIONS ##
            
            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.settingDeletedOrderLines.searchtext = '';
                        break;
                }
            };
            
            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosenDeletedOrderLines = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
            
            vm.selectAllDeletedOrderLines = function () {
    			angular.forEach(vm.model.itemsListDeletedOrderLines, function (item) {
    				if (angular.isUndefined(item.item_filtervalue) === true) return;
    				if (item.item_filtervalue.toLowerCase().indexOf(vm.model.settingDeletedOrderLines.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
                    
    				item.item_is_selected = '1';
    			});
    		};
            
            vm.unselectAllDeletedOrderLines = function () {
    			angular.forEach(vm.model.itemsListDeletedOrderLines, function (item) {
    				if (angular.isUndefined(item.item_filtervalue) === true) return;
                    if (item.item_filtervalue.toLowerCase().indexOf(vm.model.settingDeletedOrderLines.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
                    
    				item.item_is_selected = '0';
    			});
    		};
            
            // ## DELETE BUTTON FUNCTION ##
            
            vm.deleteOrderHeadDeleteOl = function () {
                vm.model.lockedDelete = true;
                
                var mergeObj = {
                    records: vm.model.itemsListDeletedOrderLines.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; })
                };
                
                orderHeadDeleteOlService.deleteItems(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelete = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();