import { Injectable } from '@angular/core';
import { GraphServiceBase } from '../core/graph-service.base';
import { ODataQueryParms } from '../model/odata-query-parms.model';
import { GraphQuery } from '../core/graph-query.object';
import { QueryOptions } from '../model/query-options.model';
import {
    Calendar,
    DateTimeTimeZone,
    Event,
} from '@microsoft/microsoft-graph-types';
import { Subset } from '../model/subset.type';
import { FindMeetingTimesParms } from '../model/calendar-parms-findmeetingtimes.model';


/**
 * https://learn.microsoft.com/en-us/graph/api/resources/calendar?view=graph-rest-1.0
 */
@Injectable({
    providedIn: 'root',
})
export class CalendarService extends GraphServiceBase {

    constructor() {
        super();
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/user-list-calendars?view=graph-rest-1.0&tabs=http
     * @param fields
     */
    public async list(fields?: { userId?: string, groupId?: string, odata?: ODataQueryParms }): Promise<GraphQuery> {
        fields = fields || {};

        // All the user's calendars.
        const url1 = "/me/calendars"; // GET
        const url2 = "/users/{userId}/calendars"; // GET

        // The user's calendars in a specific calendarGroup.
        const url3 = "/me/calendarGroups/{groupId}/calendars"; // GET
        const url4 = "/users/{userId}/calendarGroups/{groupId}/calendar"; // GET

        let url = '';
        let has = this.checkValues(fields.userId, null, fields.groupId);

        if (has.userId && has.groupId) {
            url = url4;
        } else {
            if (has.userId) {
                url = url2;
            } else {
                if (has.groupId) {
                    url = url3;
                } else {
                    url = url1;
                }
            }
        }

        url = this.updateUrl(url, fields.userId, undefined, fields.groupId);

        let query = new GraphQuery(url, fields.odata, 'GET');

        await query.next();

        return query;
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/user-post-calendars?view=graph-rest-1.0&tabs=http
     * @param fields
     */
    public async create(fields: { calendar: Calendar | Subset<Calendar>, userId?: string }) {
        const url1 = "/me/calendars"; // POST
        const url2 = "/users/{userId}/calendars"; // POST

        let has = this.checkValues(fields.userId, null, null);

        let url = has.userId ? url2 : url1;

        url = this.updateUrl(url, fields.userId, undefined, undefined);

        let query = new GraphQuery(url, undefined, 'POST');

        await query.next();

        return query;
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-get?view=graph-rest-1.0&tabs=http
     * @param fields
     */
    public async get(fields: { userId?: string, calendarId?: string, groupId?: string }) {
        // A user's or group's default calendar.
        const url1 = "/me/calendar"; // GET
        const url2 = "/users/{userId}/calendar"; // GET
        const url3 = "/groups/{groupId}/calendar"; // GET
        // A user's calendar in the default calendarGroup.
        const url4 = "/me/calendars/{itemId}"; // GET
        const url5 = "GET /users/{userId}/calendars/{itemId}"; // GET
        //A user's calendar in a specific calendarGroup.
        const url6 = "/me/calendarGroups/{groupId}/calendars/{itemId}"; // GET
        const url7 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}"; // GET
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-update?view=graph-rest-1.0&tabs=http
     * @param calendar
     * @param fields
     */
    public async update(calendar: Calendar | Subset<Calendar>, fields: { userId?: string, groupId?: string, calendarId?: string }) {
        // A user's or group's default calendar.
        const url1 = "/me/calendar"; // PATCH
        const url2 = "/users/{userId}/calendar"; // PATCH
        const url3 = "/groups/{groupId}/calendar"; // PATCH

        // A user's calendar in the default calendarGroup.
        const url4 = "/me/calendars/{itemId}"; // PATCH
        const url5 = "/users/{userId}/calendars/{itemId}"; // PATCH

        // A user's calendar in a specific calendarGroup.
        const url6 = "/me/calendarGroups/{groupId}/calendars/{itemId}"; // PATCH
        const url7 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}"; // PATCH

        let url = '';
        let has = this.checkValues(fields.userId, fields.calendarId, fields.groupId);

        if (has.userId && has.itemId && has.groupId) {
            url = url7;
        } else {
            if (has.itemId && has.groupId) {
                url = url6;
            } else {
                if (has.userId && has.itemId) {
                    url = url5;
                } else {
                    if (has.itemId) {
                        url = url4;
                    } else {
                        if (has.groupId) {
                            url = url3;
                        } else {
                            if (has.userId) {
                                url = url2;
                            } else {
                                url = url1;
                            }
                        }
                    }
                }
            }
        }

        url = this.updateUrl(url, fields.userId, fields.calendarId, fields.groupId);


    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-delete?view=graph-rest-1.0&tabs=http
     * @param fields
     */
    public async delete(fields: { userId?: string, calendarId?: string, groupId?: string }) {
        const url1 = "/me/calendars/{itemId}"; // DELETE
        const url2 = "/users/{userId}/calendars/{itemId}"; // DELETE
        const url3 = "/me/calendarGroups/{groupId}/calendars/{itemId}"; // DELETE
        const url4 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}"; // DELETE
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-list-calendarview?view=graph-rest-1.0&tabs=http
     * @param startDateTime
     * @param endDateTime
     * @param odata
     */
    public async listCalendarView(startDateTime: string, endDateTime: string, odata?: ODataQueryParms) {
        // A user's default calendar.
        const url1 = "/me/calendar/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}"; // GET
        const url2 = "/users/{userId}/calendar/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}"; // GET

        // A user's calendar in the default calendarGroup.
        const url3 = "/me/calendars/{itemId}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}"; // GET
        const url4 = "/users/{userId}/calendars/{itemId}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}"; // GET

        // A user's calendar in a specific calendarGroup.
        const url5 = "/me/calendarGroups/{groupId}/calendars/{itemId}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}"; // GET
        const url6 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}"; // GET

        //let url: string = '';

        //// Logged in users default calendar
        //if (!options?.hasUserId && !options?.hasGroupId && !options?.hasResourceId) {
        //    // GET /me/calendar/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
        //    url = 'me/calendar/calendarView';
        //}

        //// A specific users default calendar (permissions required)
        //if (options?.hasUserId && !options?.hasGroupId && !options?.hasResourceId) {
        //    // GET /users/{id | userPrincipalName}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
        //    url = 'users/' + options.userId + '/calendarView';
        //}

        //// Logged in users calendar in the default calendarGroup
        //if (!options?.hasUserId && !options?.hasGroupId && options?.hasResourceId) {
        //    // GET /me/calendars/{id}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
        //    url = 'me/calendars/' + options.resourceId + '/calendarView';
        //}

        //// A specific users calandar in the default calendarGroup (permissions required)
        //if (options?.hasUserId && !options?.hasGroupId && options?.hasResourceId) {
        //    // GET /users/{id | userPrincipalName}/calendars/{id}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
        //    url = 'users/' + options.userId + '/calendars/' + options.resourceId + '/calendarView';
        //}

        //// Logged in users calendar in a specific calendarGroup
        //if (!options?.hasUserId && options?.hasGroupId && options.hasResourceId) {
        //    // GET /me/calendarGroups/{id}/calendars/{id}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
        //    url = 'me/calendarGroups/' + options.groupId + '/calendars/' + options.resourceId + '/calendarView';
        //}

        //// A specific users calendar in a specific calendarGroup (permissions required)
        //if (options?.hasUserId && options?.hasGroupId && options?.hasResourceId) {
        //    // GET /users/{id | userPrincipalName}/calendarGroups/{id}/calendars/{id}/calendarView?startDateTime={start_datetime}&endDateTime={end_datetime}
        //    url = 'users/' + options.userId + '/calendarGroups/' + options.groupId + '/calendars/' + options.resourceId + '/calendarView';
        //}

        //let queryObject = this.CreateQuery(this._rootUrl + url, options?.odata);

        //queryObject.addParameter('startDateTime', startDateTime);
        //queryObject.addParameter('endDateTime', endDateTime);

        //await queryObject.next(accessToken);

        //return queryObject;
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-list-calendarview?view=graph-rest-1.0&tabs=http
     * @param startDateTime
     * @param endDateTime
     */
    public async listEvents(odata?: ODataQueryParms) {
        // A user's or group's default calendar.
        const url1 = "/me/calendar/events"; // GET
        const url2 = "/users/{userId}/calendar/events"; // GET
        const url3 = "/groups/{groupId}/calendar/events"; // GET

        //A user's calendar in the default calendarGroup.
        const url4 = "/me/calendars/{itemId}/events"; // GET
        const url5 = "/users/{userId}/calendars/{itemId}/events"; // GET

        // A user's calendar in a specific calendarGroup.
        const url6 = "/me/calendarGroups/{groupId}/calendars/{itemId}/events"; // GET
        const url7 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}/events"; // GET
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-post-events?view=graph-rest-1.0&tabs=http
     * @param event
     * @param fields
     */
    public async createEvent(event: any, fields?: { userId?: string, calendarId?: string, groupId?: string, token?: string }): Promise<any> {
        fields = fields || {};

        // A user's or group's default calendar.
        const url1 = "/me/calendar/events"; // POST
        const url2 = "/users/{userId}/calendar/events"; // POST
        const url3 = "/groups/{groupId}/calendar/events"; // POST

        // A user's calendar in the default calendarGroup.
        const url4 = "/me/calendars/{itemId}/events"; // POST
        const url5 = "/users/{userId}/calendars/{itemId}/events"; // POST

        // A user's calendar in a specific calendarGroup.
        const url6 = "/me/calendarGroups/{groupId}/calendars/{itemId}/events"; // POST
        const url7 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}/events"; // POST

        let url = '';
        let has = this.checkValues(fields.userId, fields.calendarId, fields.groupId);

        if (has.userId && has.itemId && has.groupId) {
            url = url7;
        } else {
            if (has.groupId && has.itemId) {
                url = url6;
            } else {
                if (has.userId && has.itemId) {
                    url = url5;
                } else {
                    if (has.itemId) {
                        url = url4;
                    } else {
                        if (has.groupId) {
                            url = url3;
                        } else {
                            if (has.userId) {
                                url = url2;
                            } else {
                                url = url1;
                            }
                        }
                    }
                }
            }
        }

        url = this.updateUrl(url, fields.userId, fields.calendarId, fields.groupId);

        let retval = await super.postData(url, event, fields.token);

        return retval;
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/event-update?view=graph-rest-1.0&tabs=http
     * @param event
     * @param fields
     */
    public async updateEvent(event: any, fields?: { userId?: string, eventId?: string, calendarId?: string, groupId?: string, token?: string }): Promise<any> {
        fields = fields || {};

        console.log('updateEvent');
        console.dir(event);
        console.dir(fields);

        const url1 = "/me/calendar/events/{itemId2}"; // PATCH
        const url2 = "/users/{userId}/calendar/events/{itemId2}"; // PATCH
        const url3 = "/groups/{groupId}/calendar/events/{itemId2}"; // PATCH

        const url4 = "/me/calendars/{itemId}/events/{itemId2}"; // PATCH
        const url5 = "/users/{userId}/calendars/{itemId}/events/{itemId2}"; // PATCH

        const url6 = "/me/calendarGroups/{groupId}/calendars/{itemId}/events/{itemId2}"; // PATCH
        const url7 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}/events/{itemId2}"; // PATCH

        let url = '';
        let has = this.checkValues(fields.userId, fields.calendarId, fields.groupId, fields.eventId);

        console.dir(has);

        if (has.userId && has.itemId && has.itemId2 && has.groupId) {
            url = url7;
        } else if (has.groupId && has.itemId && has.itemId2) {
            url = url6;
        } else if (has.userId && has.itemId && has.itemId2) {
            url = url5;
        } else if (has.itemId && has.itemId2) {
            url = url4;
        } else if (has.groupId && has.itemId2) {
            url = url3;
        } else if (has.userId && has.itemId2) {
            url = url2;
        } else if (has.itemId2) {
            url = url1;
        }

        console.log(url);

        url = this.updateUrl(url, fields.userId, fields.calendarId, fields.groupId, fields.eventId);

        console.log(url);

        let retval = await super.patchData(url, event, fields.token);

        return retval;
    }

    public async deleteEvent(eventId: string, fields?: { userId?: string, calendarId?: string, groupId?: string, token?: string }): Promise<void> {
        fields = fields || {};

        const url1 = "/me/calendar/events/{itemId2}" // DELETE
        const url2 = "/users/{userId}/calendar/events/{itemId2}" // DELETE
        const url3 = "/groups/{groupId}/calendar/events/{itemId2}/" // DELETE
        const url4 = "/me/calendars/{itemId}/events/{itemId2}" // DELETE
        const url5 = "/users/{userId}/calendars/{itemId}/events/{itemId2}" // DELETE
        const url6 = "/me/calendarGroups/{groupId}/calendars/{itemId}/events/{itemId2}" // DELETE
        const url7 = "/users/{userId}/calendarGroups/{groupId}/calendars/{itemId}/events/{itemId2}" // DELETE

        // itemId = calendarId
        // itemId2 = eventId

        let url = '';
        let has = this.checkValues(fields.userId, fields.calendarId, fields.groupId, eventId);

        if (has.userId && has.itemId && has.itemId2 && has.groupId) {
            url = url7;
        } else if (has.groupId && has.itemId && has.itemId2) {
            url = url6;
        } else if (has.userId && has.itemId && has.itemId2) {
            url = url5;
        } else if (has.itemId && has.itemId2) {
            url = url4;
        } else if (has.groupId && has.itemId2) {
            url = url3;
        } else if (has.userId && has.itemId2) {
            url = url2;
        } else if (has.itemId2) {
            url = url1;
        }

        url = this.updateUrl(url, fields.userId, fields.calendarId, fields.groupId, eventId);

        let retval = await super.deleteData(url, fields.token);

        return retval;
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/calendar-getschedule?view=graph-rest-1.0&tabs=http
     * @param startTime
     * @param endTime
     * @param schedules
     * @param availabilityViewInterval
     * @param userId
     */
    public async getSchedule(startTime: DateTimeTimeZone, endTime: DateTimeTimeZone, schedules: string[], availabilityViewInterval?: number, userId?: string) {
        // POST
        const url1 = "/me/calendar/getSchedule";
        const url2 = "/users/{userId}/calendar/getSchedule";
    }

    /**
     * https://learn.microsoft.com/en-us/graph/api/user-findmeetingtimes?view=graph-rest-1.0&tabs=http
     * @param fields
     */
    public async findMeetingTimes(fields: Subset<FindMeetingTimesParms>) {
        // POST
        const url1 = "/me/findMeetingTimes";
        const url2 = "/users/{userId}/findMeetingTimes";
    }
}
