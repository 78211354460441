(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('vatReportMenu', {
        templateUrl: 'views/components/views/vatReportMenu/vatReportMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'layoutService', 'stateService', 'modalService', 'translateService', 'base64', 'vatReportMenuService', 'idportenService', 'fileService', 'docarcService', 'ieScreenBlockService', function ($stateParams, $q, layoutService, stateService, modalService, translateService, base64, vatReportMenuService, idportenService, fileService, docarcService, ieScreenBlockService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let vatReportKeyno = $stateParams.vatreport_keyno;

            vm.translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: '',
                vatreportmenu_sign_in_idporten_first: '',
                done: '',
            };

            vm.model = {
                acVoucherBuntButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('acledger', { argtype: 'acvoucherbunt_keyno', argvalue: vm.model.menu.acvoucherbunt_keyno }) }
                ],
                acVoucherBuntRollbackButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('acledger', { argtype: 'acvoucherbunt_keyno', argvalue: vm.model.menu.acvoucherbunt_keyno_rollback }) }
                ],
                vatReportReplacedByButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('vatreportmenu', { vatreport_keyno: vm.model.menu.vatreport_keyno_replaced_by }) }
                ],
                vatReportReplacementForButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('vatreportmenu', { vatreport_keyno: vm.model.menu.vatreport_keyno_replacment_for }) }
                ],
                menu: {},
                itemFiles: [],
                files: [],
                receiptUrl: '',
                accountButton: {
                    text: 'vatreportmenu_account',
                    icon: 'fas fa-sync',
                    menu: {
                        items: [
                            { text: 'vatreportmenu_delete', danger: true, hasDivider: true, icon: 'far fa-backspace', hidden: () => vm.model.menu.is_sent !== '0' || vm.model.menu.is_accounted !== '0', click: () => vm.deleteValues() },
                        ]
                    }
                },
                submitbutton: {
                    text: 'vatreportmenu_send',
                    icon: 'fas fa-paper-plane',
                    menu: {
                        items: [
                            { text: 'vatreportmenu_validate', icon: 'far fa-check', disabled: () => vm.model.idportenIsSignedIn !== true, tooltipText: () => vm.model.idportenIsSignedIn !== true ? vm.translations.vatreportmenu_sign_in_idporten_first : '', click: () => vm.validate() },
                            { text: 'vatreportmenu_transfer', icon: 'far fa-paper-plane', disabled: () => vm.model.idportenIsSignedIn !== true, tooltipText: () => vm.model.idportenIsSignedIn !== true ? vm.translations.vatreportmenu_sign_in_idporten_first : '', click: () => vm.sendToAltinn(1) }
                        ]
                    }
                },
                activeSubmissionStep: null,
                submissionSteps: [],
                showStatusList: false,
                gridReady: false,
                updating: false,
                retrieving: false,
                validating: false,
                lockedDelete: false,
                idportenIsSignedIn: false,
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_vatreportmenu_vatreportdata_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.style = {
                submissionStepActive: { fontSize: '14px' },
                submissionStepItem: { fontSize: '13px' },
                downloadLink: {fontSize: '12px'},
            }
            // #endregion VARIABLES & DEFINITIONS



            // #region TRANSLATE SERVICE



            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                let deferred = $q.defer();

                vatReportMenuService.getMenu({ vatreport_keyno: vatReportKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                    try {

                        createReceiptURL();
                    } catch (error) {
                        console.log(error);
                    }
                    $q.all([
                        loadGrid(),

                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3136,
                    parameters: { vatreport_keyno: vm.model.menu.vatreport_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON UPDATE PROCEDURE FUNCTION CALL

            vm.validate = function () {
                ieScreenBlockService.start()
                vatReportMenuService.validate({ vatreport_keyno: vm.model.menu.vatreport_keyno, id_porten_token: idportenService.getUser()['access_token'] }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'danger',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    } else {
                        modalService.show({
                            type: 'success',
                            title: vm.translations.done,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    }
                    ieScreenBlockService.stop();
                }).finally(() => ieScreenBlockService.stop());
            };


            vm.sendToAltinn = function (transfer_only) {
                ieScreenBlockService.start()
                vatReportMenuService.sendToAltinn({
                    vatreport_keyno: vm.model.menu.vatreport_keyno,
                    id_porten_token: idportenService.getUser()['access_token'],
                    transfer_only: transfer_only
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'danger',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    } else {
                        modalService.show({
                            type: 'success',
                            title: vm.translations.done,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    }
                    vm.model.activeSubmissionStep = response[0].submission_step_no;
                    vm.model.menu.submission_step_no = response[0].submission_step_no;
                    vm.model.showStatusList = true;
                    ieScreenBlockService.stop();

                }).finally(() => ieScreenBlockService.stop());
            };

            vm.retrieveFeedback = function () {
                ieScreenBlockService.start()
                vatReportMenuService.retrieveFeedback({
                    vatreport_keyno: vm.model.menu.vatreport_keyno,
                    id_porten_token: idportenService.getUser()['access_token']
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'danger',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    } else {
                        modalService.show({
                            type: 'success',
                            title: vm.translations.done,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    }
                    console.dir(response)
                    vm.model.menu.receipt_base64 = response[0].receipt_base64;
                    vm.model.activeSubmissionStep = response[0].submission_step_no;
                    vm.model.menu.submission_step_no = response[0].submission_step_no;
                    vm.model.showStatusList = true;
                    
                    createReceiptURL();
                    ieScreenBlockService.stop();

                }).finally(() => ieScreenBlockService.stop());
            }

            
            vm.updateChanges = function () {
                vm.model.updating = true;

                vatReportMenuService.updateObj({ vatreport_keyno: vm.model.menu.vatreport_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updating = false;
                        loadMenu();
                    }
                });
            };

            // #endregion BUTTON UPDATE PROCEDURE FUNCTION CALL

            // #region BUTTON ACCOUNT PROCEDURE FUNCTION CALL

            vm.retrieveChanges = function () {
                vm.model.retrieving = true;

                vatReportMenuService.retrieveAccount({ vatreport_keyno: vm.model.menu.vatreport_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.retrieving = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.retrieving = false;
                        loadMenu();
                    }
                });
            };

            // #endregion BUTTON ACCOUNT PROCEDURE FUNCTION CALL

            // ## UPDATE BUTTON FUNCTION ##

            /**
             * Updates the list of attachments according to the files in the event.
             * 
             * @param {any} files the event, a list of current files.
             */
            vm.onFilesChanged = async function (files) {
                const filesToUpload = files.filter((file) => !vm.model.files.some((existingFiles) => existingFiles.name === file.name));
                const filesToDelete = vm.model.files.filter((existingFiles) => !files.some((file) => existingFiles.name === file.name));

                // upload files
                vm.uploadFiles(filesToUpload);

                // remove files
                filesToDelete.forEach((file) => {
                    if (!files.includes(file)) {
                        vm.deleteFile(file);
                    }
                });

                vm.model.files = files;
            };

            vm.uploadFiles = function (files) {
                if (files < 1 || vm.model.menu.vatreport_keyno < 1) return;
                if (vm.model.uploadLocked === true) return;

                vm.model.uploadLocked = true;

                var addToDocarc = function (data) {
                    if (data.fileItemKeyno < 1) return $q.resolve();

                    var docarc = {
                        contentType: data.contentType || '',
                        filename: data.fileName,
                        localFileName: data.localFileName || '', // BJS 20211019
                        originalFileName: data.originalFileName || '',
                        originalDateTime: data.originalDateTime || '',
                        argType: 'vatreport_keyno',
                        argValue: vm.model.menu.vatreport_keyno,
                        size: data.size || 0,
                        checksum: data.checksum || '',
                        latitude: data.latitude || 0,
                        longitude: data.longitude || 0,
                        altitude: data.altitude || 0,
                        heading: data.heading || 0,
                        checksum_md5: data.checksum_md5 || 0
                    };

                    var daDeferred = $q.defer();

                    docarcService.add(docarc, { checksum_md5: data.checksum_md5 }).then(function (data) {
                        getAttachments();
                        daDeferred.resolve();
                    });

                    return daDeferred.promise;
                };

                fileService.uploadFiles({
                    files: files,
                    preprocess: function (fileName, file) {
                        var ppDeferred1 = $q.defer();
                        fileService.hash(file).then(function (checksum_md5) {
                            docarcService.exists(fileName, checksum_md5.hash, $stateParams.argtype, $stateParams.argvalue).then(function (data) {
                                ppDeferred1.resolve({ cancelUpload: data.exists, checksum_md5: checksum_md5.hash });
                            });
                        });

                        return ppDeferred1.promise;
                    },
                    postprocess: function (data) {
                        var ppDeferred2 = $q.defer();

                        addToDocarc(data).then(function () {
                            ppDeferred2.resolve();
                        });

                        return ppDeferred2.promise;
                    }
                }).then(function () {
                    vm.model.uploadLocked = false;
                });
            };

            vm.deleteFile = async function (file) {
                const itemFile = vm.model.itemFiles.find((fileToDelete) => file.name === fileToDelete.docfilename);
                if (itemFile) {
                    await fileService.delete({ fileName: itemFile.docfilename, confirmMessage: undefined });
                    await docarcService.delete(itemFile.item_id);
                    vm.model.itemFiles.splice(vm.model.itemFiles.indexOf(itemFile), 1);
                }
            }

            function createReceiptURL() {
                function base64ToArrayBuffer(base64) {
                    var binaryString = window.atob(base64);
                    var len = binaryString.length;
                    var bytes = new Uint8Array(len);
                    for (var i = 0; i < len; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    return bytes.buffer;
                }


                if (vm.model.menu.receipt_base64) {
                    var arrayBuffer = base64ToArrayBuffer(vm.model.menu.receipt_base64);

                    var blob = new Blob([arrayBuffer], { type: 'application/pdf' });

                    vm.model.receiptUrl = URL.createObjectURL(blob);
                }

            };

            vm.exportToXml = function () {
                vm.model.exportingToXml = true;

                vatReportMenuService.exportTo({ vatreport_keyno: vm.model.menu.vatreport_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.exportingToXml = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.exportingToXml = false;
                        var elem = document.getElementById('formVatReportMenuLink');
                        var text = base64.urldecode(response[0].filecontent);

                        var txtData = new Blob([text], { type: 'text/txt' });
                        var txtUrl = URL.createObjectURL(txtData);

                        elem.setAttribute('href', txtUrl);
                        elem.setAttribute('download', response[0].filename);
                        elem.click();
                    }
                });
            };

            // ## UPDATE BUTTON FUNCTION ##

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: vm.translations.confirm_title,
                    message: vm.translations.confirm_message,
                    okLabel: vm.translations.confirm_ok_label,
                    cancelLabel: vm.translations.confirm_cancel_label
                }).then(function () {
                    vatReportMenuService.deleteItem(vm.model.menu).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: vm.translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: vm.translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            vm.idportenLogin = function () {
                idportenService.login();
                console.log(idportenService.getUser());

            }
            vm.idportenLogout = function () {
                idportenService.completeLogout();
                idportenService.logout();
            }


            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
            async function getAttachments() {
                if (vm.model.menu.vatreport_keyno > 0) {
                    vm.model.itemFiles = await vatReportMenuService.getAttachments(vm.model.menu.vatreport_keyno);
                    createFileFromItemList();
                }
            };

            async function getSubmissionSteps() {
                vm.model.submissionSteps = (await vatReportMenuService.getSubmissionSteps());
                vm.model.submissionSteps.splice(0, 1);
            }

            async function translate() {
                const data = await translateService.translateBatch(vm.translations);

                Object.keys(vm.translations).forEach((key) => {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            }

            function createFileFromItemList() {
                vm.model.files = vm.model.itemFiles.map((file) => new File([], file.docfilename, { checksum: file.checksum }))
            };

            vm.getSubmissionStepIcon = function (step) {
                if (step.step_no < vm.model.menu.submission_step_no || (vm.model.submissionSteps.indexOf(step) === vm.model.submissionSteps.length - 1 && step.step_no === vm.model.menu.submission_step_no)) {
                    return 'fa-check-circle vat-report-menu__submission-step--finished';
                } else if (step.step_no === vm.model.menu.submission_step_no) {
                    return;//'fa-circle-notch fa-spin vat-report-menu__submission-step--wip';
                } else {
                    return 'fa-circle vat-report-menu__submission-step--na';
                }
            }

            function getActiveSubmissionStep() {
                vm.model.activeSubmissionStep = vm.model.submissionSteps.find((step) => step.step_no === vm.model.menu.submission_step_no);
            }

            // #region ON INIT FUNCTION

            vm.$onInit = async function () {
                await Promise.all([getSubmissionSteps(), loadMenu()]);
                await idportenService.createUserManagerSettings(vm.model.menu.businessco_no);
                vm.model.idportenIsSignedIn = idportenService.getUser() !== null;
                translate();


                getActiveSubmissionStep();
                getAttachments();

                idportenService.tokenExpired.subscribe(() => vm.model.idportenIsSignedIn = false);

                layoutService.onLayoutChanged([], (info) => {
                    if (info) {
                        vm.style.submissionStepActive.fontSize = info.fontSizes.textSizeL;
                        vm.style.submissionStepItem.fontSize = info.fontSizes.textSize;
                        vm.style.downloadLink.fontSize = info.fontSizes.textSize;
                    }
                });
                console.log(idportenService.getUser());
                console.log(vm.model.idportenIsSignedIn);
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
