(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('portalUserStockplace', {
        templateUrl: 'views/components/views/portalUserStockplace/portalUserStockplace.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'portalUserStockplaceService', function ($stateParams, portalUserStockplaceService) {
            var vm = this;
            let portalUserKeyno = $stateParams.portal_user_keyno;
            
            vm.model = {
                itemsListStockPlaces: {
                    records: []
                }
            };
            
            var loadData = function () {
                portalUserStockplaceService.portalUserStockplaceList(portalUserKeyno).then(function () {
                    vm.model.itemsListStockPlaces = portalUserStockplaceService.dataSource;
                });
            };

            loadData();
            
            vm.toggle_item = function (item) {
                var active = "1";

                if (item.access_planning != "0") {
                    active = "0";
                }

                portalUserStockplaceService.portalUserStockplaceToggle(portalUserKeyno, item.item_id, active).then(function () {
                    loadData();
                });
            };
        }]
    });
})();
