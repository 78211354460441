(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("srOperationsService", ['$q', '$ihttp', '$timeout', 'p2DataTaskService', function ($q, $ihttp, $timeout, p2DataTaskService) {
		var service = {
			reloaded: false,
			typeaheadResourcesList: [],
			isSearching: false,
			loadSrOperationsGet: function () {
				return p2DataTaskService.call(1319, {});
			},
			loadOperationsList: function (setting) {
				return p2DataTaskService.call(1320, setting);
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			search: function (resource_searchtext) {
				var deferred = $q.defer();

				service.isSearching = true;

				$ihttp.post({
					method: 1321,
					parameters: {
						resource_searchtext: resource_searchtext
					}
				}).then(function (data) {
					angular.copy(data, service.typeaheadResourcesList);

					service.isSearching = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			reloadOperationsList: function () {
				service.reloaded = false;

				$timeout(function () {
					service.reloaded = true;
				}, 60000);

				return service.reloaded;
			}
		};

		return service;
	}]);
})();