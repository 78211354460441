(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('campaigns', {
        templateUrl: 'views/components/views/campaigns/campaigns.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'campaignsComponentService', 'utilityService', 'rememberService', function (stateService, campaignsComponentService, utilityService, rememberService) {
            var vm = this;

            function setSelected(id) {
                return vm.model.mainButtonGroup.selected = vm.model.settings.campaign_selected = id;
            }

            function handleButtonClick(id) {
                setSelected(id);
                handleRemember(vm.model.mainButtonGroup.remember, id).then(function () {});
                loadCampaigns(id);
            }

            function handleRemember(name, value) {
                if (utilityService.validateParmsValue(name) !== true) return;
                if (utilityService.validateParmsValue(value) !== true) return;

                return rememberService.remember(name, value).then(function () {});
            }

            function loadCampaigns(campaign) {
                if (utilityService.validateParmsValue(campaign) !== true) return;

                vm.model.campaigns = [];

                return campaignsComponentService.list(campaign).then(function (data) {
                    angular.copy(data, vm.model.campaigns);
                });
            }

            vm.handleCreateCampaign = function () {
                return stateService.go('campaignedit', { campaign_keyno: 0 });
            };

            vm.$onInit = function () {
                return campaignsComponentService.get().then(function (data) {
                    angular.copy(data[0], vm.model.settings);
                    handleButtonClick(vm.model.settings.campaign_selected);
                    loadCampaigns();
                });
            };

            vm.model = {
                mainButtonGroup: {
                    selected: 'campaigns_active',
                    remember: stateService.getCurrentName() + ':selected',
                    buttons: [
                        { id: 'campaigns_active', label: 'campaigns_active', color: 'primary', onClick: { click: handleButtonClick, id: 'campaigns_active' } },
                        { id: 'campaigns_scheduled', label: 'campaigns_scheduled', color: 'primary', onClick: { click: handleButtonClick, id: 'campaigns_scheduled' } },
                        { id: 'campaigns_finished', label: 'campaigns_finished', color: 'primary', onClick: { click: handleButtonClick, id: 'campaigns_finished' } }
                    ]
                },
                settings: {},
                campaigns: []
            };
        }]
    });
})();