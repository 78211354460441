//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("pohFinishService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
//        var service = {
//            loaded: false,
//            p2_pohfinish_get: {
//                prod_id: '',
//                prod_name: '',
//                poh_keyno: '',
//                project_keyno: '',
//                show_button_tracing: '',
//                quantity_finished_now: '',
//                quantity_total: ''
//            },
//            loadP2PohFinishGet: function (poh_keyno) {
//                var deferred = $q.defer();

//                var parms_p2_pohfinish_get = {
//                    method: 155,
//                    parameters: {
//                        poh_keyno: poh_keyno
//                    }
//                };

//                $ihttp.post(parms_p2_pohfinish_get).then(function (data) {
//                    service.p2_pohfinish_get = angular.copy(data[0]);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            completeFinishPohScan: function () {
//                var deferred = $q.defer();

//                var parms_p2_pohfinish_complete = {
//                    method: 163,
//                    parameters: {
//                        poh_keyno: $stateParams.poh_keyno,
//                        project_keyno: service.p2_pohfinish_get.project_keyno,
//                        prod_id: service.p2_pohfinish_get.prod_id,
//                        prod_name: service.p2_pohfinish_get.prod_name,
//                        quantity_finished_now: service.p2_pohfinish_get.quantity_finished_now,
//                        quantity_total: service.p2_pohfinish_get.quantity_total
//                    }
//                };

//                $ihttp.post(parms_p2_pohfinish_complete).then(function () {
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            }
//        };

//        return service;
//    }]);
//})();
