// https://beta.touchtime.app/#/externalproductioncompletion
(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epProductionCompletion', {
        templateUrl: 'views/components/views/epProductionCompletion/epProductionCompletion.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$uibModal', '$timeout', '$sce', '$localStorage', 'utilityService', 'translateService', 'eventService', 'printService', 'responsive', 'ieScreenBlockService', 'epProductionCompletionService', 'modalReportPreviewService', function ($q, $uibModal, $timeout, $sce, $localStorage, utilityService, translateService, eventService, printService, responsive, ieScreenBlockService, epProductionCompletionService, modalReportPreviewService) {
            var vm = this;

            var id = uuid();

            vm.gridControls = null;

            vm.report = {};

            var initialized = false;

            var getStatusClass = function (totQuantity, scanQuantity) {
                if (angular.isNumber(totQuantity) !== true) {
                    totQuantity = parseInt(totQuantity);
                }

                if (angular.isNumber(scanQuantity) !== true) {
                    scanQuantity = parseInt(scanQuantity);
                }

                if (scanQuantity > 0) {
                    switch (true) {
                        case totQuantity > scanQuantity:
                            return 'bg-yellow';
                        case totQuantity < scanQuantity:
                            return 'bg-red'
                        default:
                            return 'bg-green';
                    }
                } else {
                    return 'bg-default';
                }
            };

            var previousCqsKeyno = 0;

            var setGridOptions = function (cqsKeyno) {
                if (previousCqsKeyno === cqsKeyno) {
                    vm.gridControls.refresh();
                } else {
                    vm.gridOptions = {
                        dataTaskKeyno: 1941,
                        parameters: {
                            caseqtystyleKeyno: cqsKeyno
                        },
                        rememberId: 'epProductionCompletion',
                        gridControlCallback: function (gridControls) { vm.gridControls = gridControls; },
                        onSetup: function (schema, fields, columns) {
                            var cols = [
                                columns[1],
                                columns[4],
                                columns[2],
                                columns[5],
                                columns[3],
                                columns[6],
                                columns[7]
                            ];

                            angular.copy(cols, columns);
                        },
                        onDataBound: function (e) {
                            var rows = e.sender.content.find('tr');
                            var quantityIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "quantity" + "]").index();
                            var scanQuantityIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "scanQuantity" + "]").index();

                            rows.each(function (_, row) {
                                var dataItem = e.sender.dataItem(row);

                                var statusClass = getStatusClass(dataItem.quantity, dataItem.scan_quantity);

                                $(row).children('td:eq(' + quantityIndex + ')').addClass(statusClass);
                                $(row).children('td:eq(' + quantityIndex + ')').children('span').addClass(statusClass);
                                $(row).children('td:eq(' + scanQuantityIndex + ')').addClass(statusClass);
                                $(row).children('td:eq(' + scanQuantityIndex + ')').children('span').addClass(statusClass);
                            })
                        }
                    };

                    if (vm.gridControls !== null) {
                        vm.gridControls.rebind().then(function () {
                            vm.gridControls.refresh();
                        });
                    }
                }

                previousCqsKeyno = cqsKeyno;
            };

            epProductionCompletionService.init().then(function (data) {
                angular.copy(data.report, vm.report);

                initialized = true;
            });

            utilityService.setDefaultValues($localStorage, {
                externalProductionCompletion: {
                    printer: {}
                }
            });

            vm.$storage = $localStorage.externalProductionCompletion;

            vm.model = {
                scanId: id,
                scanValue: '',
                header: {
                    text: ''
                },
                label: {
                    text: ''
                },
                status: {
                    show: false,
                    type: '',
                    class: '',
                    text: ''
                },
                item: {
                    textilemodel_id: '',
                    textilemodel_name: '',
                    textilecolor_id: '',
                    textilecolor_name: '',
                    textilesize_id: '',
                    quantity: 0,
                    scan_quantity: 0,
                    jqGridCC: []
                },
                data: {
                    caseqtystyle_keyno: 0,
                    case_keyno: 0,
                    status_keyno: 0
                },
                size: responsive.current
            };

            var translations = {
                ep_confirm_cancel_order: '',
                ep_confirm_cancel_carton: '',
                ep_message_printing_label: '',
                ep_message_print_completed: ''
            };

            var deregTranslation = translateService.on(translations, 'changed', function (result) {
                angular.copy(result, translations);

                deregTranslation();
            });

            var setHeader = function (header) {
                if (header.set !== true) return;

                if (angular.isDefined(header.info) && header.info.length > 0) {
                    vm.model.header.text = header.info[0].text;
                }
            };

            var setLabel = function (label) {
                if (label.clear === true) {
                    vm.model.label.text = '';
                }

                if (angular.isDefined(label.text) && label.text.length > 0) {
                    vm.model.label.text = label.text;
                }
            };

            var setStatus = function (status) {
                vm.model.status.show = status.show;
                vm.model.status.text = status.text;

                if (angular.isDefined(status.color)) {
                    if (status.color === 'success' || status.color === 'info' || status.color === 'warning' || status.color === 'danger') {
                        vm.model.status.type = status.color;
                    } else {
                        vm.model.status.type = status.color === '#33CC33' ? 'success' : 'danger';
                    }
                }

                if (angular.isDefined(status.type)) {
                    vm.model.status.type = status.type;
                }

                vm.model.status.class = 'alert-' + vm.model.status.type;
            };

            var setGrid = function (grid) {
                if (grid.refresh !== true) return;

                setGridOptions(grid.args.caseqtystyle_keyno);
            };

            var setDialog = function (dialog) {
                setStatus({
                    type: dialog.type,
                    show: true,
                    text: dialog.text
                });
            };

            var setAll = function (response) {
                if (angular.isDefined(response.grid)) {
                    setGrid(response.grid);
                }

                if (response.clearinput === true) {
                    vm.model.scanValue = '';
                }

                if (angular.isDefined(response.header)) {
                    setHeader(response.header);
                }

                if (angular.isDefined(response.label)) {
                    setLabel(response.label);
                }

                if (angular.isDefined(response.status)) {
                    setStatus(response.status);
                }

                if (angular.isDefined(response.dialog)) {
                    setDialog(response.dialog);
                }

                if (angular.isDefined(response.data) && response.data.set === true) {
                    if (angular.isDefined(vm.model.data.caseqtystyle_keyno) && angular.isDefined(response.data.values.caseqtystyle_keyno))
                        vm.model.data.caseqtystyle_keyno = response.data.values.caseqtystyle_keyno;

                    if (angular.isDefined(vm.model.data.case_keyno) && angular.isDefined(response.data.values.case_keyno))
                        vm.model.data.case_keyno = response.data.values.case_keyno;

                    if (angular.isDefined(vm.model.data.status_keyno) && angular.isDefined(response.data.values.status_keyno))
                        vm.model.data.status_keyno = response.data.values.status_keyno;
                }
            };

            var confirm = function (message) {
                var deferred = $q.defer();

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return message;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    deferred.resolve({ isConfirmed: confirmed });
                });

                return deferred.promise;
            };

            var isValidKeyno = function (keyno) {
                if (angular.isUndefined(keyno))
                    return false;
                if (keyno === null)
                    return false;

                if (angular.isString(keyno)) {
                    keyno = parseInt(keyno);
                }

                if (angular.isNumber(keyno) !== true)
                    return false;
                if (keyno < 1)
                    return false;

                return true;
            };

            vm.reprintLabel = function () {
                if (initialized !== true) {
                    $timeout(vm.reprintLabel, 100);
                    return;
                }

                printService.preview({
                    reportKeyno: vm.report.keyno,
                    parameters: {
                        cqs_keyno: vm.model.data.caseqtystyle_keyno,
                        case_keyno: vm.model.data.case_keyno
                    }
                }).then(function (url) {
                    var previewLink = $sce.trustAsResourceUrl(url);

                    var printFrame = $('#insoftPrintFrame');

                    if (printFrame.length === 0) {
                        $('body').append('<iframe id="insoftPrintFrame" src="' + previewLink + '" style="visibility:hidden; display: none;" width="1px" height="1px" ></iframe>');

                        $('#insoftPrintFrame').on('load', function () {
                            $('#insoftPrintFrame')[0].contentWindow.print({ bUI: false, bSilent: true, bShrinkToFit: false });
                        });
                    }
                    else {
                        $(printFrame).attr('src', previewLink);
                    }
                });

                //modalReportPreviewService.show({
                //    reportKeyno: vm.report.keyno,
                //    parameters: {
                //        cqs_keyno: vm.model.data.caseqtystyle_keyno,
                //        case_keyno: vm.model.data.case_keyno
                //    }
                //});
            };

            // BJS 20211119 - Added processing alert
            vm.processing = false;

            vm.onScan = function (value) {
                vm.model.scanValue = value;

                if (vm.model.scanValue.length === 0) return;

                vm.processing = true;

                epProductionCompletionService.scan({
                    scandata: vm.model.scanValue,
                    caseqtystyle_keyno: angular.copy(vm.model.data.caseqtystyle_keyno),
                    case_keyno: angular.copy(vm.model.data.case_keyno),
                    status_keyno: angular.copy(vm.model.data.status_keyno)
                }).then(function (response) {
                    setAll(response);

                    vm.processing = false;
                });
            };

            vm.caseCompleted = function () {
                if (isValidKeyno(vm.model.data.caseqtystyle_keyno) !== true)
                    return;
                if (isValidKeyno(vm.model.data.case_keyno) !== true)
                    return;

                epProductionCompletionService.caseCompleted(vm.model.data.caseqtystyle_keyno, vm.model.data.case_keyno).then(function (result) {
                    if (angular.isUndefined(result.dialog)) {
                        vm.reprintLabel();
                    }

                    setAll(result);
                });
            };

            vm.cancelOrder = function () {
                if (isValidKeyno(vm.model.data.caseqtystyle_keyno) !== true)
                    return;

                confirm(translations.ep_confirm_cancel_order).then(function (response) {
                    if (response.isConfirmed !== true)
                        return;

                    epProductionCompletionService.cancelCaseQtyStyle(vm.model.data.caseqtystyle_keyno).then(function (result) {
                        setAll(result);
                    }).catch(function (e) {
                        console.log('exception');
                        console.dir(e);
                    });
                });
            };

            vm.cancelCase = function () {
                if (isValidKeyno(vm.model.data.caseqtystyle_keyno) !== true)
                    return;

                confirm(translations.ep_confirm_cancel_carton).then(function (response) {
                    if (response.isConfirmed !== true)
                        return;

                    epProductionCompletionService.cancelCase(vm.model.data.caseqtystyle_keyno).then(function (result) {
                        setAll(result);
                    }).catch(function (e) {
                        console.log('exception');
                        console.dir(e);
                    });
                });
            };

            vm.onPrinterChanged = function (printer, changed) {
                if (changed !== true)
                    return;

                vm.$storage.printer = printer;
            };

            vm.style = {
                reprintLabel: {},
                cancelOrder: {}
            };

            var setStyle = function () {
                switch (vm.model.size) {
                    case 'xs':
                        if (angular.isDefined(vm.style.reprintLabel.paddingRight)) {
                            delete vm.style.reprintLabel.paddingRight;
                        }
                        if (angular.isDefined(vm.style.cancelOrder.paddingLeft)) {
                            delete vm.style.cancelOrder.paddingLeft;
                        }
                        break;
                    default:
                        vm.style.reprintLabel.paddingRight = '3px';
                        vm.style.cancelOrder.paddingLeft = '3px';
                        break;
                }
            };

            setStyle();

            var onDestroy = [];

            onDestroy.push(eventService.on('event:responsive-breakpoint', function (data) {
                vm.model.size = data.newSize;

                setStyle();
            }));

            vm.$onDestroy = function () {
                eventService.destroy(onDestroy);
            };
        }]
    });
})();
