(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('choiceProdPriceGrp', {
        templateUrl: 'views/components/views/choiceProdPriceGrp/choiceProdPriceGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'choiceProdPriceGrpService', 'productService', function ($stateParams, stateService, translateService, modalService, choiceProdPriceGrpService, productService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let choiceProdPriceGrpKeyno = $stateParams.choiceprodpricegrp_keyono;
            let prodIdChoice = $stateParams.prod_id_choice;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                choiceProdPriceGrpKeyno: choiceProdPriceGrpKeyno,
                colourGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('colorgrps') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('colorgrpmenu', { colorgrp_id: vm.model.edit.colorgrp_id }) }
                ],
                edit: {},
                selectListColourGroups: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                choiceProdPriceGrpService.getEdit({
                    choiceprodpricegrp_keyono: choiceProdPriceGrpKeyno,
                    prod_id_choice: prodIdChoice
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadColourGroups = function () {
                productService.listColourGroups().then(function (list) {
                    angular.copy(list, vm.model.selectListColourGroups);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                choiceProdPriceGrpService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    choiceProdPriceGrpService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadColourGroups();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
