(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('checkListData', {
        templateUrl: 'views/components/views/checkListData/checkListData.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'base64', 'checkListDataService', 'stateService', 'modalService', function ($stateParams, $timeout, base64, checkListDataService, stateService, modalService) {
            var vm = this;
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            //vm.model = {
            //    test1: 'https://www.oakland.no/wp-content/uploads/2018/02/2000071-400x267.jpg',
            //    test2: 'https://www.oakland.no/wp-content/uploads/2018/02/2000071-400x2672.jpg',
            //    test3: '',
            //    hmax1: '100px'
            //}
    
            vm.model = {
                updatingDataFromResponse: false,
                checkListDataKeyno: $stateParams.checklistdata_keyno,
                p2WCheckListDataSettings: {},
                p2_checklistdata_get: [],
                lockedSave: false,
                lockedSaveAndApprove: false
            };
    
    		//######################
    		//## PROCEDURE FUNCTIONS
    		//######################
            
            var loadP2WCheckListDataSettings = function () {
                checkListDataService.loadP2WCheckListDataSettings($stateParams.checklistdata_keyno).then(function (data) {
                    vm.model.p2WCheckListDataSettings = angular.copy(data[0]);
    
                    if (vm.model.p2WCheckListDataSettings.report_html_base64 !== '') {
                        vm.model.p2WCheckListDataSettings.report_html = base64.urldecode(vm.model.p2WCheckListDataSettings.report_html_base64);
                    }
                });
            };
    
            loadP2WCheckListDataSettings();
            
            checkListDataService.loadP2CheckListData($stateParams.checklistdata_keyno).then(function () {
                vm.model.p2_checklistdata_get = checkListDataService.p2_checklistdata_get;         
            });
    
            // ###############
            // CHANGE FUNCTION
            // ###############
    
            var changeTimer = null;
    
            vm.checkListDataOnChange = function (index) {
                if (vm.model.updatingDataFromResponse) return;
                $timeout(function () {
                    //console.dir('checkListDataService.checkListDataValidate(index): ' + index);
                    checkListDataService.checkListDataValidate(index);
                }, 50);
    
                var cldList = vm.model.p2_checklistdata_get.checklistitems;
                if (angular.isUndefined(cldList) || angular.isUndefined(cldList[index])) return;
    
                var cldItem = cldList[index];
                if (angular.isUndefined(cldItem.cv) || cldItem.cv !== '1') return;
    
                var done = false;
                var timerMS = 0;
    
                switch (cldItem.elementtype) {
                    case 'DT':
                    case 'CL':
                    case 'CB':
                    case 'CS':
                        timerMS = 20;
                        break;
                    default:
                        timerMS = 500;
                }
    
                if (angular.isDefined(changeTimer)) {
                    done = false;
                    $timeout.cancel(changeTimer);
                }
    
                var finishedTyping = function () {
                    if (done) {
                        var cldConnectedData = [];
    
                        for (var c = 0; c < cldItem.cvs.length; c++) {
                            for (var d = 0; d < cldList.length; d++) {
                                if (cldItem.cvs[c].clik === cldList[d].checklistitem_keyno) {
                                    cldConnectedData.push({ cii: cldList[d].checklistitem_keyno, civ: cldList[d].itemvalue });
                                    break;
                                }
                            }
                        }
                        
                        var cldCompactItem = { item_id: cldItem.checklistitem_keyno, item_value: cldItem.itemvalue, cvd: cldConnectedData }
                        
                        //console.log(index);
                        //console.dir(cldList);
                        //console.dir(cldItem);
                        //console.dir(cldCompactItem);
                        
                        var updateData = [];
                        
                        checkListDataService.computeValidation(cldCompactItem).then(function (data) {
                            //console.dir('Returndata: ');
                            //console.dir(data);
                            
                            if (data.errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data.errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            }
    
                            if (angular.isDefined(data.outputresult)) {
                                vm.model.updatingDataFromResponse = true;
                                updateData = data.outputresult;
                                for (var u = 0; u < updateData.length; u++) {
                                    for (var v = 0; v < cldList.length; v++) {
                                        if (updateData[u].clik === cldList[v].checklistitem_keyno && angular.isDefined(updateData[u].cliv)) {
                                            cldList[v].itemvalue = updateData[u].cliv;
                                            checkListDataService.checkListDataValidate(v);
                                            break;
                                        }
                                    }
                                }
                                vm.model.updatingDataFromResponse = false;
                            }
                        });
                        return;
                    }
    
                    done = true;
    
                    changeTimer = $timeout(finishedTyping, timerMS);
                };
    
                finishedTyping();
            };
    
            vm.showPad = function () {
                modalService.showSignaturePad().then(function (value) {
                    //console.log('Pad finished');
                    //console.log(value);
                });
            };
    
            // #########################
            // DIRECTION BUTTON FUNCTION
            // #########################
    
            vm.goToPictures = function () {
                checkListDataService.saveP2CheckListData();
    
                stateService.go('pictures', {
                    argtype: 'checklistdata_keyno',
                    argvalue: $stateParams.checklistdata_keyno
                });
            };
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'checklistdata_keyno',
                            argvalue: vm.model.checkListDataKeyno
                        });
                        break;
                }
            };
    
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
    
            vm.saveP2CheckListData = function () {
                vm.model.lockedSave = true;
    
                checkListDataService.saveP2CheckListData().then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // #######################
            // APPROVE BUTTON FUNCTION
            // #######################
    
            vm.saveAndApproveP2CheckListData = function () {
                vm.model.lockedSaveAndApprove = true;
    
                checkListDataService.saveAndApproveP2CheckListData().then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveAndApprove = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSaveAndApprove = false;
                    }
                });
            };
    
            // ######################
            // DELETE BUTTON FUNCTION
            // ######################
    
            vm.deleteP2SelectCheckList = function () {
                checkListDataService.deleteP2SelectCheckList().then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();