(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockForecast', {
        templateUrl: 'views/components/views/stockForecast/stockForecast.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'stockForecastService', 'seasonalitiesService', 'forecastsService', function ($stateParams, utilityService, stockForecastService, seasonalitiesService, forecastsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodId = $stateParams.prod_id;
            let prodSizeKeyno = $stateParams.prodsize_keyno;
            let prodChoiceKeyno = $stateParams.prodchoice_keyno;
            let stockPlaceId = $stateParams.stockplace_id;
    		
            vm.model = {
                chartType: 'line',
                chartLabels: '0',
                bgStockForecastMain: {
                    selected: 'SETTINGS',
                    buttons: [
                        { id: 'SETTINGS', label: 'bg_stockforecast_main_settings', color: 'primary', onClick: () => vm.model.bgStockForecastMain.selected = 'SETTINGS' },
                        { id: 'DISPLAY', label: 'bg_stockforecast_main_display', color: 'primary', onClick: () => vm.model.bgStockForecastMain.selected = 'DISPLAY' }
                    ]
                },
                bgStockForecastDisplayType: {
                    selected: 'GRAPH',
                    buttons: [
                        { id: 'GRAPH', label: 'bg_stockforecast_displaytype_graph', color: 'primary', onClick: () => vm.model.bgStockForecastDisplayType.selected = 'GRAPH' },
                        { id: 'LIST', label: 'bg_stockforecast_displaytype_list', color: 'primary', onClick: () => vm.model.bgStockForecastDisplayType.selected = 'LIST' }
                    ]
                },
                setting: {},
                selectListSeasonalities: [],
                selectListForecasts: [],
                selectListResolutions: [],
                forecastGraphList: {
                    records: []
                },
                itemsListForecastDatas: []
            };

            // #endregion VARIABLES & DEFINITIONS
            
            // #region PROCEDURE CALLS
            
            let loadSetting = function () {
                stockForecastService.getSetting({
                    prod_id: prodId,
                    prodsize_keyno: prodSizeKeyno,
                    prodchoice_keyno: prodChoiceKeyno,
                    stockplace_id: stockPlaceId
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };
            
            let loadSeasonalities = function () {
                seasonalitiesService.listSeasonalities().then(function (result) {
                    angular.copy(result, vm.model.selectListSeasonalities);
                });
            };

            let loadForecasts = function () {
                forecastsService.listForecasts().then(function (result) {
                    angular.copy(result, vm.model.selectListForecasts);
                });
            };

            let loadResolutions = function () {
                stockForecastService.listResolutions().then(function (result) {
                    angular.copy(result, vm.model.selectListResolutions);
                });
            };
            
            let retrieveForecast = function () {
                if (utilityService.validateParmsValue(vm.model.setting.forecast_keyno) !== true) return;

                return stockForecastService.getForecast({ forecast_keyno: vm.model.setting.forecast_keyno }).then(function (response) {
                    vm.model.setting.forecast_type_id = response[0].forecast_type_id;
                    vm.model.setting.forecast_resolution_id = response[0].forecast_resolution_id;
                    vm.model.setting.forecast_forward_nbr = response[0].forecast_forward_nbr;
                    vm.model.setting.forecast_history_running_nbr = response[0].forecast_history_running_nbr;

                    if (response[0].forecast_type_id === 'F') {
                        vm.model.setting.history_fom_date = response[0].forecast_history_fixed_fom_date;
                        vm.model.setting.history_tom_date = response[0].forecast_history_fixed_tom_date;
                        vm.model.setting.forecast_forward_fixed_startdate = response[0].forecast_forward_fixed_startdate;
                    }
                });
            };
            
            let retrieveHistoryDates = function () {
                if (utilityService.validateParmsValue(vm.model.setting.forecast_resolution_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.forecast_history_running_nbr) !== true) return;
                
                return stockForecastService.getHistoryDates({
                    forecast_resolution_id: vm.model.setting.forecast_resolution_id,
                    forecast_history_running_nbr: vm.model.setting.forecast_history_running_nbr
                }).then(function (response) {
                    vm.model.setting.history_fom_date = response[0].history_fom_date;
                    vm.model.setting.history_tom_date = response[0].history_tom_date;
                });
            };
            
            let retrieveHistory = function () {
                if (utilityService.validateParmsValue(vm.model.setting.seasonality_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.history_fom_date) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.history_tom_date) !== true) return;

                return stockForecastService.getHistory(vm.model.setting).then(function (response) {
                    vm.model.setting.history_qty = response[0].history_qty;
                    vm.model.setting.history_pst = response[0].history_pst;
                });
            };
            
            let retrieveStockForecast = function () {
                vm.model.itemsListForecastDatas = [];

                return stockForecastService.listForecastDatas(vm.model.setting).then(function (response) {
                    angular.copy(response, vm.model.itemsListForecastDatas);

                    vm.model.forecastGraphList.records = [];

                    angular.forEach(vm.model.itemsListForecastDatas, function (data) {
                        if (angular.isDefined(data)) {
                            //if (data.item_id > 0) {
                            //    var gItem = {
                            //        item_id: data.item_id,
                            //        item_name: data.item_name,
                            //        //item_col: data.item_secondary[1],
                            //        item_secondary: data.item_secondary,
                            //        orderby: data.orderby,
                            //    }

                            //    gItem.item_secondary[1].visible_chart = true;
                            //    gItem.item_secondary[1].name = 'item_col1';
                            //    gItem.item_secondary[3].visible_chart = true;
                            //    gItem.item_secondary[3].name = 'item_col3';

                            //    vm.model.forecastGraphList.records.push(gItem);
                            //}

                            //var gItem = {
                            //    item_id: data.item_id,
                            //    item_name: data.item_name,
                            //    //item_col: data.item_secondary[1],
                            //    item_secondary: data.item_secondary,
                            //    orderby: data.orderby,
                            //}

                            //gItem.item_secondary[1].visible_chart = true;
                            //gItem.item_secondary[1].name = 'item_col1';
                            //gItem.item_secondary[3].visible_chart = true;
                            //gItem.item_secondary[3].name = 'item_col3';

                            data.item_secondary[1].visible_chart = true;
                            data.item_secondary[1].name = 'item_col1';
                            data.item_secondary[3].visible_chart = true;
                            data.item_secondary[3].name = 'item_col3';

                            vm.model.forecastGraphList.records.push(data);
                        }
                    });
                });
            };

            // #endregion PROCEDURE CALLS

            // #region CREATE FUNCTION

            let createStockForecast = function () {
                stockForecastService.createUpdates(vm.model.setting).then(function () {
                    retrieveStockForecast();
                });
            };

            // #endregion CREATE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockForecastMain = function (value) {
                vm.model.bgStockForecastMain.selected = value;

                if (vm.model.bgStockForecastMain.selected === 'DISPLAY') createStockForecast();
            };

            vm.selectBgStockForecastDisplayType = function (value) {
                vm.model.bgStockForecastDisplayType.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'seasonality_keyno':
                    case 'history_fom_date':
                    case 'history_tom_date':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        retrieveHistory();
                        break;
                    case 'forecast_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.forecast_keyno) !== true) return;

                        vm.model.setting.forecast_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.forecast_keyno) !== true) return;

                        retrieveForecast();
                        break;
                    case 'forecast_resolution_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.forecast_resolution_id) !== true) return;

                        vm.model.setting.forecast_resolution_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.forecast_resolution_id) !== true) return;

                        if (vm.model.setting.forecast_type_id === 'R') retrieveHistoryDates();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'forecast_history_running_nbr':
                        if (utilityService.validateParmsValue(vm.model.setting.forecast_history_running_nbr) !== true) return;

                        if (vm.model.setting.forecast_type_id === 'R') retrieveHistoryDates();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadSeasonalities();
                loadForecasts();
                loadResolutions();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
