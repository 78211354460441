(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("payCheckService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadPayCheckGet: function (paycheck_keyno) {
				return p2DataTaskService.call(1372, {
					paycheck_keyno: paycheck_keyno
				});
			},
			loadPayCheckLList: function (paycheck_keyno) {
				return p2DataTaskService.call(1373, {
					paycheck_keyno: paycheck_keyno
				});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			}
		};

		return service;
	}]);
})();