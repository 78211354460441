(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('emailInbox', {
        templateUrl: 'views/components/views/emailInbox/emailInbox.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'emailInboxService', 'emailService', function ($timeout, stateService, emailInboxService, emailService) {
            var vm = this;
            var timer = null;

    		vm.lm = [];
            vm.cm = [];
            vm.selectedCount = 0;
            vm.showChecked = false;
            
            vm.lm = emailInboxService.lm;
            vm.cm = emailInboxService.cm;
            
            vm.messageOptions = {
                item_id: 0,
                item_name: '',
                item_default: '-1',
                item_selected: '-1',
                records: [
                    { item_id: '1', item_name: 'email_list_action_accounts', item_func: '-1' },
                    { item_id: '2', item_name: 'email_list_action_mark', item_func: '2' }
                ]
            };
            
            vm.checkButtonClick = function () {
                if (!vm.showChecked) {
                    vm.showChecked = true;
                    timer = $timeout(vm.checkSelectedCount, 500);
                } else {
                    vm.showChecked = false;
                    if (timer) $timeout.cancel(timer);
                    vm.unselectAll();
                }
            };
            
            vm.checkSelectedCount = function () {
                vm.selectedCount = 0;

                angular.forEach(vm.cm, function (item) {
                    if (item.item_is_selected > 0) {
                        vm.selectedCount += 1;
                    }
                });

                timer = $timeout(vm.checkSelectedCount, 500);
            };
            
            vm.deleteButtonClick = function () {
                var deleteCount = 0;
                var ids = [];

                angular.forEach(vm.cm, function (item) {
                    if (item.item_is_selected > 0) {
                        //var i = { id: item.item_id };
                        ids.push(item.item_id);
                        deleteCount += 1;
                    }
                });

                emailInboxService.deleteVvMessages(ids).then(function () {
                    vm.showChecked = false;

                    if (timer) $timeout.cancel(timer);

                    vm.unselectAll();
                    vm.reloadEmails();
                });

                //timer = $timeout(vm.checkSelectedCount,500);
            };
            
            vm.unselectAll = function () {
                angular.forEach(vm.cm, function (item) {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                });

                vm.checkSelectedCount();
            };
            
            vm.accountButtonClick = function () {
                emailService.defaultAccount = '';
                emailService.messageActive = false;

                stateService.go('email', {});
            };
        }]
    });
})();    