(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2ReportDef', {
        templateUrl: 'views/components/views/p2ReportDef/p2ReportDef.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'p2ReportDefService', 'p2ExportProcService', 'dbReportService', 'logisticService', 'ttGridFactory', function ($stateParams, stateService, translateService, p2ReportDefService, p2ExportProcService, dbReportService, logisticService, ttGridFactory) {
            const vm = this;
            let p2ReportDefKeyno = $stateParams.p2_reportdef_keyno;

            let translations = {
                papersize_standard: '',
                papersize_custom: ''
            };

            vm.model = {
                edit: {},
                translations: {
                    change_default_printer: ''
                },
                tabs: {
                    selected: 'DETAILS',
                    buttons: [
                        { id: 'DETAILS', label: 'gb_p2reportdef_details', color: 'primary', onClick: () => vm.model.selected = 'DETAILS' },
                        { id: 'PRINTERS', label: 'gb_p2reportdef_printer', color: 'primary', onClick: () => vm.model.selected = 'PRINTERS' },
                        { id: 'WEBPAGES', label: 'gb_p2reportdef_webpages', color: 'primary', onClick: () => vm.onPreview() },
                    ]
                },
                selectListWebPages: [],
                selectListEdiTypes: [],
                selectListPrinters: [],
                selectListReportTypes: [],
                selectListHtmlReports: [],
                selectListPaperSizes: [
                    {
                        id: 'standard',
                        name: 'Standard'
                    },
                    {
                        id: 'custom',
                        name: 'Custom'
                    }
                ],
                gridReady: false,
                webpageGridReady: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2536,
                        parameters: {
                            p2_reportdef_keyno: p2ReportDefKeyno
                        }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                        ////method: 2217,
                        //parameters: {
                        //    datatask_keyno: 2217,
                        //    order_internal_no: $stateParams.order_internal_no
                        //},
                        //autoSave: true,
                        ////confirm: true
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    rememberId: 'w_p2reportdef_grid',
                    editColumns: [/*{ key: 'default_printer_name', lookup: 337, relations: [{ key: 'default_printer_keyno', value: 'item_id' }], optionfunc: true }*/],
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: false,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: false,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: false,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: false,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'customInvoice', text: 'LAG FAKTURA', func: function (data) { testItems(data); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' },
                            //{ name: 'customInvoice', text: 'create_invoice', func: function (data) { testItems(data); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true },
                            //{ name: 'customTest', text: 'Dette er en tekst', func: function () { testItems(); }, icon: 'star' }
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } },
                            //{ name: 'test2', text: '', func: function (data) { testItems(data); }, icon: 'fas-save', disabled: function (data) { return test2Hidden(data); } }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            vm.webpageGrid = new ttGridFactory({
                rememberId: 'w_p2reportdef.webpagegrid',
            }).setLoadDataFunction({
                method: 3410,
                parameters: { p2_reportdef_keyno: $stateParams.p2_reportdef_keyno }
            }).setAddRowFunction({
                method: null,
                parameters: { p2_reportdef_keyno: $stateParams.p2_reportdef_keyno },
                pre: null,
                post: null,
                autoSave: true,
                confirm: null
            }).setSaveDataFunction({
                method: null,
                parameters: { p2_reportdef_keyno: $stateParams.p2_reportdef_keyno },
                autoSave: true,
                confirm: null
            }).setRemoveRowFunction({
                method: null,
                parameters: {},
                autoSave: true,
                confirm: null,
                post: null
            }).setSpecialFunc({
                buttons: [
                    {
                        name: 'customDelete',
                        text: '',
                        icon: 'far-trash-alt',
                        type: 'danger',
                        func: async function (data) {
                            await vm.webpageGrid.gridfunc.removeRow(data);
                            vm.webpageGrid.gridfunc.saveChanges(false);
                        }
                    }
                ]
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region OTHER

            var optionfunc = function (data) {
                //console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);

                    // Save the default printer to db.
                    dbReportService.setUserReportPrinter(vm.model.edit['p2_reportdef_keyno'], data['rdata']['default_printer_keyno'], data['rdata']['portal_user_keyno']);
                    vm.grid.gridfunc.rebind();
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    //console.log('optionfunc - LookupCellEditor');
                    //console.dir(data);
                }
            };

            // #region TRANSLATION FUNCTION

            translateService.translateBatch(vm.model.translations).then(function (data) {
                angular.forEach(vm.model.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.model.translations[key] = data[key];
                    }
                });
            });

            var deregTranslations = translateService.on(translations, 'changed', function (result) {
                deregTranslations();
                
                angular.copy(result, translations);
                
                angular.forEach(vm.model.selectListPaperSizes, function (value) {
                    //value.name = result['papersize_' + value.id];
                    value.name = 'papersize_' + value.id;
                });
            });

            // #endregion TRANSLATION FUNCTION

            let load = function () {
                p2ReportDefService.getEdit({ p2_reportdef_keyno: p2ReportDefKeyno }).then(function (result) {
                    if (angular.isUndefined(result.paper_size_id)) {
                        result.paper_size_id = 'standard';
                    }

                    if (angular.isUndefined(result.width)) {
                        result.width = 0;
                    }

                    if (angular.isUndefined(result.height)) {
                        result.height = 0;
                    }

                    angular.copy(result[0], vm.model.edit);
                });

                p2ReportDefService.listReportTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListReportTypes);
                });

                p2ReportDefService.listHtmlReports().then(function (result) {
                    angular.copy(result, vm.model.selectListHtmlReports);
                });
                
                p2ReportDefService.listWebPages().then(function (result) {
                    angular.copy(result, vm.model.selectListWebPages);
                });

                p2ExportProcService.listEdiTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListEdiTypes);
                });

                logisticService.listPrinters().then(function (result) {
                    vm.model.selectListPrinters = [{
                        item_id: '0',
                        item_name: 'Standard'
                    }];

                    result.forEach(printer => vm.model.selectListPrinters.push(printer));                    
                });

                vm.model.gridReady = true;
                vm.model.webpageGridReady = true;
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2ReportDefService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                load();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
