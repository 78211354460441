(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("deliveryTermService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadDeliveryTermGet: function (deliveryterm_no) {
                return p2DataTaskService.call(1532, {
                    deliveryterm_no: deliveryterm_no
                });
            },
            save: function (term) {
                return p2DataTaskService.call(1533, term);
            }
        };

        return service;
    }]);
})();