//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('pohFinishComp', {
//        templateUrl: 'views/components/views/pohFinishComp/pohFinishComp.template.html?v=' + module.version, 
//        controllerAs: 'vm',
//        controller: ['pohFinishCompService', '$stateParams', 'stateService', function(pohFinishCompService, $stateParams, stateService) {
    
//            var vm = this;
    
    
//            vm.pohfinish_comp = pohFinishCompService.pohfinish_comp;
    
//            pohFinishCompService.loadP2PohFinishCompList($stateParams.poh_keyno, $stateParams.quantity_finished_now);
    
//            vm.goToPohCompAddScan = function (index) {
//                stateService.go('pohcompaddscan', { poh_keyno: $stateParams.poh_keyno });
//            }
//        }]
//    });
//})();
