(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDelPurchLineService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            p2_batchdelpurchline_get: {
                batchdelpurchline_keyno: '',
                batchdelivery_keyno: '',
                purchline_keyno: '',
                quantity_received: '',
                price_locval: '',
                price_ordval: '',
                prod_id: '',
                prod_name: '',
                note_before: '',
                purchline_note: '',
                nbr_of_stockbatches: '',
                stock_keyno: '',
                project_id_and_name: '',
                order_valuta_id: '',
                freight_eu: '',
                freight_ex_eu: '',
                customs_eu: '',
                customs_ex_eu: '',
                divcost: '',
                amount_locval: '',
                purchprice_locval: '',
                purchprice_invval: '',
                show_delete_button: ''
            },
            loadP2BatchDelPurchLineGet: function (batchdelpurchline_keyno) {
                var deferred = $q.defer();

                var parms_p2_batchdelpurchline_get = {
                    method: 287,
                    parameters: {
                        batchdelpurchline_keyno: batchdelpurchline_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelpurchline_get).then(function (data) {
                    service.p2_batchdelpurchline_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deleteP2BatchDelPurchLine: function () {
                var deferred = $q.defer();

                var parms_p2_batchdelpurchline_delete = {
                    method: 290,
                    parameters: {
                        batchdelpurchline_keyno: service.p2_batchdelpurchline_get.batchdelpurchline_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelpurchline_delete).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            rollback: function (batchdelpurchline_keyno) {
                return p2DataTaskService.call(1720, {
                    batchdelpurchline_keyno: batchdelpurchline_keyno
                });
            }
        };

        return service;
    }]);
})();