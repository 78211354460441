(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bankReconciliations', {
        templateUrl: 'views/components/views/bankReconciliations/bankReconciliations.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'stateService', 'bankReconciliationsService', 'logisticService', 'rememberService', 'ttGridFactory', function ($q, utilityService, stateService, bankReconciliationsService, logisticService, rememberService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                businessco_no: '',
                balance_date: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_bankreconciliations_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

                bankReconciliationsService.getSetting().then(function (info) {
            		angular.copy(info[0], vm.model.setting);

            		$q.all([
            			loadGrid()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3384,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                    	case 'balance_date':
                            variableNames[key] = stateService.getCurrentName() + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'balance_date':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                    case 'balance_date':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
