(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("searchService", [function () {
        var service = {
            //product: {
            //    selectedProdId: '',
            //    selectedProdName: '',
            //    selected: false
            //},
            orderLineGet: {
                stockPlaceId: ''
            },
            custactCalcEditCtrl: {
                p2CustactCalcItemsSelectionDefault: '0',
                p2CustactCompListSelectionDefault: '0',
                bgP2CustactCalcSumSelectionDefault: '0',
                bgP2CustactCalcSumNumberTypeDefault: '0'
            },
            custactCompProductSearch: '',
            productSearch: {
                gbPickTypeDefault: ''
            },
            holidaysView: {
                activeCountryId: ''
            },
            consignmentbatches: {
                gbShowStatusDefault: ''
            },
            stockBatchOnLocation: {
                listStat: ''
            },
            searchMaterialRegister: [{
                custactNo: '',
                searchedProdName: ''
            }],
            productMaterial: {
                selectedProdId: '',
                selectedProdName: '',
                selectedUnitName: '',
                selected: false
            },
            productMaterialReg: [{
                custactNo: '',
                rememberProdId: '',
                rememberProdName: '',
                rememberUnitName: ''
            }],
            p2CustactCompSelectProduct: {
                selectedItemId: '',
                selectedItemName: '',
                selectedUnitName: '',
                selected: false
            },
            p2OeeStateRemarksList: {
                oeeStateRemarkKeyno: '',
                oeeStateRemarkName: '',
                showRemarkText: ''
            },
            shiftDayDefaults: {
                gbTimeBreakItemDefault: '0'
            },
            oeeData: {
                p2OeeDataGet: ''
            },
            searchProductTimeReg: [{
                custactNo: '',
                searchedProdName: ''
            }],
            p2OffDutyDatas: {
                activeLabourNo: ''
            },
            productTimeReg: {
                selectedProdId: '',
                selectedProdName: '',
                selectedUnitName: '',
                selected: false
            },
            productOrderLine: {
                selectedProdId: '',
                selectedProdName: '',
                selectedUnitName: '',
                selected: false
            },
            custactComp: {
                selectedProdId: '',
                selectedProdName: '',
                selectedUnitNo: '',
                selectedUnitName: '',
                selected: false
            },
            operation: {
                selectedProdId: '',
                selectedProdName: '',
                selectedUnitName: '',
                selected: false
            },
            equipmentEdit: {
                selectedProdId: '',
                selectedProdName: '',
                selectedUnitName: '',
                selected: false
            },
            searchOrderLineEdit: [{
                custactNo: '',
                searchedProdName: ''
            }],
            prodsize: {
                selectedProdsizeKeyno: '',
                selectedProdSizeName: '',
                selected: false
            },
            customer: {
                selectedCustNo: 0,
                selectedCustName: '',
                selectedCustGrpId: ''
            },
            selLabour: {
                selectedLabourNo: '',
                selectedLabourName: ''
            },
            selCustomer: {
                //searchedCustName: '',
                selectedCustNo: 0,
                selectedCustName: '',
                directedToSelectCustomer: false
            },
            //p2WConsignmentPlanning: {
            //    dateFom: '',
            //    dateTom: '',
            //    deliveryWeekInfo: '',
            //    selectedDeliveryWeek: '',
            //    selectedDeliveryWeekInfo: ''
            //},
            p2ConsignmentPlanning: [{
                dateFomOld: '',
                dateTomOld: '',
                deliveryWeek: '',
                deliveryWeekInfo: '',
                selector: '',
                consignmentPlanningItemDefault: '0'
            }],
            consignmentPlanningDefaults: {
                custact_no_consignmentplan: '',
                stockplace_id: '',

                gbPlanningType_item_default: '0',
                gbPlanStatus_item_default: '0',
                //gbPlanStatus_item_default: '0', // BJS20180330 - Fjernet fordi der er 2 properties med samme navn.
                gbPlansStatus_item_default: '0',
                filterVal: '',


                p2ConsignmentPlanningGb3Selection_item_selected: '',
                p2ConsignmentPlanningGb3Selection_default: ''
            },
            selProdChoice: {
                selectedProdChoiceSearch: ''
            },
            userSettings: {
                userGetEdit: '',
                absUrl: ''
            },
            timeRegister: {
                powTimeGetEdit: '',
                custNo: '',
                projectKeyno: ''
            },
            processTimeRegister: [{
                projectProcessKeyno: '',
                custactNo: ''
            }],
            customerEdit: {
                customerGetEdit: ''
            },
            processMaterialRegister: [{
                projectProcessKeyno: '',
                custactNo: ''
            }],
            materialRegister: {
                powTimeGetMaterial: ''
            },
            orderHead: {
                gbRow1Default: '0'
            },
            custactSearch: [{
                filterField: '',
                filterVal: '',
                responsibleType: '',
                ok: '',
                actType: '',
                searchText: '',
                activitytype_no: '0',
                selection_item_default: '0',
                ok_item_default: '0',
                activity_item_default: '0',
            }],
            oeeStateLog: [{
                equipmentId: '',
                selector: '',
                mainSelector: '',
                oeestatelogshow_item_default: '0',
                oeestatelog_item_default: '0'
            }],
            oeeStateLogDefaults: {
                oeestatelogshow_item_default: '0',
                oeestatelog_item_default: '0',
                equipment_id: ''
            },
            findCustomers: [{
                //custName: '',
                custGrpId: '',
                selector: '',
                findcustomers_item_default: '0'
            }],
            documents: [{
                searchText: '',
                argType: '',
                argVal: '',
                selector: '',
                documents_item_default: '0'
            }],
            workDetailsGroup: [{
                custactNo: '',
                selector: '',
                workdetails_item_default: '0'
            }],
            oeeDataCurrent: [{
                oeeDataKeyno: '',
                oeeStateKeyno: '',
                equipmentId: ''
            }],
            custact: {
                lastOk: '0',
                custactGetEdit: ''
            },
            visitor: {
                lastInOut: 'IN'
            }
        };

        return service;
    }]);
})();