(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('metaColumns', {
        templateUrl: 'views/components/views/metaColumns/metaColumns.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'metaColumnsService', function ($stateParams, stateService, utilityService, metaColumnsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                metaTableKeyno: $stateParams.metatable_keyno,
                itemsListMetaColumns: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            metaColumnsService.listMetaColumns({ metatable_keyno: vm.model.metaTableKeyno }).then(function (data) {
                angular.copy(data, vm.model.itemsListMetaColumns);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'metacolumn':
                        go({
                            metatable_keyno: vm.model.metaTableKeyno,
                            metacolumn_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();