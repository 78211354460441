import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormButton } from '../form-field-button/form-field-button.component';
import { Style, TextAlign } from '@app/core/services/core-component.service';
import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'tt-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.css'],
})
export class InputComponent<TData> extends FormFieldBaseComponent implements OnInit, OnChanges, FormFieldButtons {
    @Input()
    public ttModel?: TData;

    @Output()
    public ttModelChange = new EventEmitter<TData>();

    @Input()
    public ttTextAlign: TextAlign = 'left';

    @Input()
    public ttType: 'date' | 'datetime-local' | 'email' | 'hidden' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week' = 'text';

    @Input()
    public ttInputMode: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url' = 'text';

    @Input()
    public get ttMaxLength(): number {
        return this._maxLength;
    }
    public set ttMaxLength(value: NumberInput) {
        this._maxLength = coerceNumberProperty(value);
    }
    private _maxLength = 524288;

    
    @Output()
    public ttOnBlur = new EventEmitter();

    @Input()
    public ttButtons?: FormButton[] | undefined;

    @Input()
    public ttButtonParms?: { [key: string]: any } | undefined;

  
    @ViewChild('inputRef')
    public inputRef?: ElementRef;

    public id = {
        input: crypto.randomUUID(),
    };

    public override style: Style = {
        input: {},
    };

    public override setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, textAlign: this.ttTextAlign, mainElement: 'input' });
    }

    public onModelChanged(event: TData) {
        this.ttModel = event;
        this.ttModelChange.emit(event);
    }

    public onFocus(_: FocusEvent) {
        this.inputRef?.nativeElement.select();
    }

    public onBlur(_: FocusEvent) {
        this.ttOnBlur.emit(this.ttModel);
    }

    override ngOnInit(): void {}

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {}
}

//           ttBindFocus: '@',
//           ttType: '@',
//           ttFunc: '&',
//       },
//       controller: ['$element', '$timeout', '$interval', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, $timeout, $interval, layoutService, eventService, translateService, us, ttDirectivesService) {
//           var vm = this;

//           var onDestroy = [];

//           vm.hideLabel = false;
//           vm.readonly = false;
//           vm.tabable = '';
//           vm.required = false;
//           vm.inputType = 'text';
//           vm.inputMode = 'text';
//           vm.maxLength = 524288;  //Webstandard default

//           vm.id = {
//               input: uuid()
//           };

//           vm.style = {
//               base: {},
//               label: {},
//               sublabel: {},
//               input: {},
//               labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
//           };

//           vm.class = {
//               base: '',
//               label: '',
//               sublabel: '',
//               input: ''
//           };

//           vm.translations = {
//               ttLabel: '',
//               ttPlaceholder: '',
//           };

//           vm.whenReady = function () {
//               eventService.trigger('element:ready');
//           };

//           let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

//           let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

//           var setInputMode = function (value) {
//               var element = $element.find('#' + vm.id.input);

//               element.attr('inputmode', value);
//           };

//           var isValidInputType = function (type) {
//               if (us.isStringValue(type, true) !== true) return false;

//               switch (type) {
//                   case 'button':
//                   case 'checkbox':
//                   case 'color':
//                   case 'date':
//                   case 'datetime-local':
//                   case 'email':
//                   case 'file':
//                   case 'hidden':
//                   case 'image':
//                   case 'month':
//                   case 'number':
//                   case 'password':
//                   case 'radio':
//                   case 'range':
//                   case 'reset':
//                   case 'search':
//                   case 'submit':
//                   case 'tel':
//                   case 'text':
//                   case 'time':
//                   case 'url':
//                   case 'week':
//                       return true;
//               }

//               return false;
//           };

//           var isValidInputMode = function (mode) {
//               if (us.isStringValue(mode, true) !== true) return false;

//               switch (mode) {
//                   case 'none':
//                   case 'text':
//                   case 'decimal':
//                   case 'numeric':
//                   case 'tel':
//                   case 'search':
//                   case 'email':
//                   case 'url':
//                       return true;
//               }

//               return false;
//           };

//           var inputElement;
//           var stopFocusBinding;

//           var setFocus = function () {
//               if (angular.isUndefined(inputElement)) {
//                   inputElement = $element.find('#' + vm.id.input)[0];
//               }

//               if (angular.isDefined(inputElement)) {
//                   $timeout(function () {
//                       inputElement.focus();
//                   }, 0);
//               }
//           };

//           var bindFocus = function () {
//               // return if focus already bound
//               if (angular.isDefined(stopFocusBinding)) return;

//               stopFocusBinding = $interval(setFocus, 1000);

//               $element.focus();
//           };

//           var unbindFocus = function () {
//               if (angular.isUndefined(stopFocusBinding))
//                   return;

//               $interval.cancel(stopFocusBinding);

//               stopFocusBinding = undefined;
//           };

//           vm.$onInit = function () {
//               if (angular.isDefined(vm.ttInputMode) && isValidInputMode(vm.ttInputMode) === true) {
//                   vm.inputMode = vm.ttInputMode;
//                   setInputMode(vm.inputMode);
//               }

//               if (angular.isDefined(vm.ttMaxLength)) {
//                   vm.maxLength = vm.ttMaxLength;
//               }

//               if (angular.isFunction(vm.ttFunc)) {
//                   vm.ttFunc({
//                       $value: {
//                           setFocus: setFocus,
//                           getInputElement: function () { return $element.find('#' + vm.id.input)[0]; },
//                           bindFocus: bindFocus,
//                           unbindFocus: unbindFocus
//                       }
//                   });
//               }

//               if (angular.isDefined(vm.ttInvalid) && us.toBoolean(vm.ttInvalid) === true) {
//                   vm.style.input.borderColor = 'red';
//               }

//               setStyle(vm.ttStyle);
//           }

//           var focusIsBound = false;

//           vm.$onChanges = function (changes) {
//               if (changes.ttOptions?.currentValue) {
//                   ttDirectivesService.setOptions(vm, changes);
//               }

//               if (angular.isDefined(changes.ttInvalid)) {
//                   vm.ttInvalid = us.toBoolean(changes.ttInvalid.currentValue);

//                   if (vm.ttInvalid === true) {
//                       vm.style.input.borderColor = 'red';
//                   } else {
//                       delete vm.style.input.borderColor;
//                   }
//               }

//               if (angular.isDefined(changes.ttBindFocus) && us.isStringValue(changes.ttBindFocus.currentValue)) {
//                   focusIsBound = us.toBoolean(changes.ttBindFocus.currentValue, true);

//                   if (focusIsBound === true) {
//                       bindFocus();
//                   } else {
//                       unbindFocus();
//                   }
//               }

//               if (angular.isDefined(changes.ttHideLabel)) {
//                   vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

//                   setClasses();
//               }

//               if (angular.isDefined(changes.ttType) && isValidInputType(changes.ttType.currentValue) === true) {
//                   vm.inputType = changes.ttType.inputType;
//               }

//               if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
//                   vm.style.input.textAlign = changes.ttTextAlign.currentValue;
//               }

//               if (angular.isDefined(changes.ttReadonly)) {
//                   vm.readonly = us.toBoolean(changes.ttReadonly.currentValue);
//               }

//               if (angular.isDefined(changes.ttTabable)) {
//                   if (us.toBoolean(changes.ttTabable.currentValue) === false || changes.ttTabable.currentValue === '-1') {
//                       vm.tabable = '-1';
//                   } else {
//                       vm.tabable = '';
//                   }
//               }

//               if (angular.isDefined(changes.ttPlaceholder) && us.isStringValue(changes.ttPlaceholder.currentValue, true)) {
//                   if (vm.ttTranslate === 'false') {
//                       vm.translations.ttPlaceholder = changes.ttPlaceholder.currentValue;
//                   } else {
//                       translateService.translate(changes.ttPlaceholder.currentValue).then((translation) => vm.translations.ttPlaceholder = translation);
//                   }
//               }

//               if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
//                   if (vm.ttTranslate === 'false') {
//                       vm.translations.ttLabel = changes.ttLabel.currentValue;
//                   } else {
//                       translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
//                           vm.translations.ttLabel = translation;

//                           vm.whenReady();
//                       });
//                   }
//               }

//               if (angular.isDefined(changes.ttRequired) && angular.isDefined(changes.ttRequired.currentValue)) {
//                   vm.required = us.toBoolean(changes.ttRequired.currentValue);
//               }

//               if (angular.isDefined(changes.ttStyle)) {
//                   setStyle(changes.ttStyle.currentValue);
//               }

//               if (angular.isDefined(changes.ttInputMode) && isValidInputMode(changes.ttInputMode.currentValue) === true) {
//                   vm.inputMode = changes.ttInputMode.currentValue;
//                   setInputMode(vm.inputMode);
//               }

//               if (angular.isDefined(changes.ttMaxLength)) {
//                   vm.maxLength = changes.ttMaxLength.currentValue;
//               }
//           };

//           layoutService.onLayoutChanged(onDestroy, function (info) {
//               if (angular.isUndefined(info)) return;

//               ttDirectivesService.setLayoutStyle(vm.style, info);
//               setStyle(vm.ttStyle);
//               setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
//           });

//           onDestroy.push(eventService.on('event:focuslock:start', function (data) {
//               if (focusIsBound !== true)
//                   return;

//               bindFocus();
//           }));

//           onDestroy.push(eventService.on('event:focuslock:stop', function (data) {
//               if (focusIsBound !== true)
//                   return;

//               unbindFocus();
//           }));

//           vm.onModelChanged = function (value) {
//               if (angular.isFunction(vm.ttChange)) {
//                   vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
//               }
//           };

//           vm.onBlur = function ($event) {
//               if (angular.isFunction(vm.ttOnBlur)) {
//                   vm.ttOnBlur($event);
//               }
//           }

//           vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
//       }]
//   });
// })();
