(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('metaTableMenu', {
        templateUrl: 'views/components/views/metaTableMenu/metaTableMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'metaTableMenuService', 'metaTableService', function ($stateParams, stateService, metaTableMenuService, metaTableService) {
            const vm = this;
            let metaTableKeyno = $stateParams.metatable_keyno;

            vm.model = {
                menu: {},
                selectListRelatedTo: []
            };

            let loadMenu = function () {
                metaTableMenuService.getMetaTableMenu({ metatable_keyno: metaTableKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };

            let loadRelatedToMetaTables = function () {
                metaTableService.listRelatedToMetaTables().then(function (result) {
                    angular.copy(result, vm.model.selectListRelatedTo);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'metatable':
                    case 'metacolumns':
                    case 'metatableviews':
                        go({ metatable_keyno: vm.model.menu.metatable_keyno });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadMenu();
                loadRelatedToMetaTables();
            };
        }]
    });
})();
