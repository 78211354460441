(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("docArticleSearchService", ['$q', '$timeout', 'p2DataTaskService', function ($q, $timeout, p2DataTaskService) {
        let onSelectedSearchesUpdate;
        let groupTimeout;
        var service = {
            selectedSearches: [],

            /**
             * Subscribe to 'selectedSearches' updates.
             * Handler function passed in will be called whenever 'selectedSearches' is updated.
             *
             * KLN 20231004
             * 
             * @param {Function} handler
             */
            subscribeToUpdate: function (handler) {
                onSelectedSearchesUpdate = handler;
            },
            loadDocArticleSearchGet: function () {
                return p2DataTaskService.call(1380, {});
            },
            loadDocArticleSearchList: function (searchtext, instant) {
                let deferred = $q.defer();

                if (groupTimeout) $timeout.cancel(groupTimeout);
                if (instant) {
                    p2DataTaskService.call(1381, {
                        searchtext: searchtext
                    }).then(function (data) {
                        angular.copy(data, service.selectedSearches);
                        deferred.resolve(data);

                        if (onSelectedSearchesUpdate) {
                            onSelectedSearchesUpdate();
                        }
                    });
                } else {
                    groupTimeout = $timeout(function () {
                        p2DataTaskService.call(1381, {
                            searchtext: searchtext
                        }).then(function (data) {
                            angular.copy(data, service.selectedSearches);
                            deferred.resolve(data);

                            if (onSelectedSearchesUpdate) {
                                onSelectedSearchesUpdate();
                            }
                        });
                    }, 300);
                }


                return deferred.promise;
            }
        };

        return service;
    }]);
})();
