(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipmentEdit', {
        templateUrl: 'views/components/views/equipmentEdit/equipmentEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'equipmentEditService', 'logisticService', 'labourPositionService', 'equipmentGrpsService', 'typeaheadService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, equipmentEditService, logisticService, labourPositionService, equipmentGrpsService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let equipmentId = $stateParams.equipment_id;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                factoryButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('factories', {}) }
                ],
                workGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('workgrps', {}) }
                ],
                workGroupButtonsExpanded: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.workgrp_keyno = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('workgrps', {}) }
                ],
                equipmentGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('equipmentgrps', {}) }
                ],
                productIdButtons: [
                    {
                        id: 'goto',
                        icon: 'glyphicon glyphicon-search',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('selectproduct', {
                            webpage_name: stateService.getCurrentName(),
                            parm1: vm.model.edit.equipment_id,
                            parm2: '0'
                        })
                    }
                ],
                productIdButtons2: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno })
                    }
                ],
                edit: {},
                selectListCompanies: [],
                selectListFactories: [],
                selectListWorkGroups: [],
                selectListEquipmentGroups: [],
                selectListStockLocations: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();

                equipmentEditService.getEdit({ equipment_id: equipmentId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadSelectWorkGroups(),
                        loadFactories(),
                        loadStockLocations()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadEquipmentGroupViews = function () {
                equipmentGrpsService.listEquipmentGrpViews().then(function (result) {
                    angular.copy(result, vm.model.selectListEquipmentGroups);
                });
            };

            let loadFactories = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return labourPositionService.listSelectFactories({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListFactories);
                });
            };

            let loadSelectWorkGroups = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;
                
                return equipmentEditService.listSelectWorkGroups({
                    businessco_no: vm.model.edit.businessco_no,
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListWorkGroups);
                });
            };

            let loadStockLocations = function () {
                if (utilityService.validateParmsValue(vm.model.edit.factory_id) !== true) return;
                
                return logisticService.listStockLocation({
                    stockzoneclass_keyno: '50',
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockLocations);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH FUNCTION

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchProduct({ prod_name: vm.model.edit.prod_name });
            };

            vm.typeaheadSearchSupplierName = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: vm.model.edit.supplier_name,
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'S',
                    lastselect: 'SEARCH'
                });
            };

            // #endregion TYPEAHEAD SEARCH FUNCTION

            // #region BUTTON LIST SINGLE FUNCTION
            
            vm.onProdNameCleared = function () {
                vm.model.edit.prod_id = '';
                vm.model.edit.prod_name = '';
            };

            vm.onProdNameSelected = function (item) {
                vm.model.edit.prod_id = item.item_id;
                vm.model.edit.prod_name = item.item_name;
            };

            vm.onProdNameChanged = function (value) {
                vm.model.edit.prod_name = value;

                if (!value) {
                    vm.onProdNameCleared();
                } else {
                    let product = typeaheadService.selectListProducts.find((item) => item.item_name === vm.model.edit.prod_name);

                    if (product) {
                        vm.onProdNameSelected(product);
                    }
                }
            };
            
            vm.onSuplierNameCleared = function () {
                vm.model.edit.supplier_no = '0';
                vm.model.edit.supplier_name = '';
            };

            vm.onSuplierNameSelected = function (item) {
                vm.model.edit.supplier_no = item.item_id;
                vm.model.edit.supplier_name = item.item_name;
            };

            vm.onSuplierNameChanged = function (value) {
                vm.model.edit.supplier_name = value;

                if (!value) {
                    vm.onSuplierNameCleared();
                } else {
                    let suplier = typeaheadService.selectListCustomers.find((item) => item.item_name === vm.model.edit.supplier_name);

                    if (suplier) {
                        vm.onSuplierNameSelected(suplier);
                    }
                }
            };
            
            // #endregion BUTTON LIST SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                equipmentEditService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'shiftview':
                        go({ labour_no: 'E' + vm.model.edit.equipment_id });
                        break;
                    case 'oeestate_equipments':
                    case 'operationequipments':
                    case 'documents':
                    case 'labourpositions':
                        go({
                            argtype: 'equipment_id',
                            argvalue: vm.model.edit.equipment_id
                        });
                        break;
                    case 'custactsearch':
                        go({
                            filterfield: 'equipment_id',
                            filtervalue: vm.model.edit.equipment_id,
                            custacttype: 'A'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.factory_id = '';
                        loadFactories();
                        loadSelectWorkGroups();
                        break;
                    case 'factory_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.factory_id) !== true) return;

                        vm.model.edit.factory_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.factory_id) !== true) return;

                        vm.model.edit.workgrp_keyno = '0';
                        loadSelectWorkGroups();
                        loadStockLocations();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadEquipmentGroupViews();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
