(function () {
    'use strict';

    angular.module('imApp')
    .directive('imSpreadsheetDirective', ['$timeout', 'modalService', 'consoleService', 'FileSaver', function ($timeout, modalService, consoleService, FileSaver) {
        var directive = {
            restrict: 'E',
            scope: {
                //field: '=',         // Links in the values                                                          //REQUIRED
                listItems: '=',     // Used for linking a list to the directive                                     //REQUIRED
                exportjson: '=?',   // Used to link json value from outside, most often database                    //Situationally REQUIRED
                headers: '=?',      // Used to list the headers back to controller
                setup: '=?',        // Used for linking variables from database
                showUpload: '=?',   // Used to show the "select file"-btn
                optionfunc: '&?'    // Used as reference to funtion in Controller
            },
            template:
                '<div class="form-group im-no-mar" ng-show="model.showUpload === true">' +
                    '<div class="col-xs-12 sp-0">' +
                        '<input name="files" type="file" id="{{model.iId}}" kendo-upload k-options="uploadOptions"></input>' +
                        '<div ng-show="model.isTxt === true">' +
                            '<br>' +
                            '<im-input-directive label="encoding" field="model.encodings.selected" type="select" data="model.encodings.data" dataid="item_id" dataname="item_name"></im-input-directive>' +
                            '<im-input-directive label="filecontent" field="model.dataJSON" type="textarea" typevar="r"></im-input-directive>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<im-button-directive name="readingdata" spin="model.dataReading" glyphs="fa fa-refresh" btnstyle="btn-warning" btnsize="col-xs-12 im-no-pad" ng-show="model.dataReading === true"></im-button-directive>' +
                '<div class="col-xs-12 im-no-pad" ng-show="model.hasData">' +
                    '<div id="{{model.mId}}" style="width: 100%;" kendo-spreadsheet ' +
                        'k-scope-field="spreadsheet" ' +
                        'k-options="spreadsheetOptions">' +
                    '</div>' +
                    '<br>' +
                    '<im-button-directive name="saveasexcel" optionfunc="saveAsExcel()" glyphs="fa fa-file-excel-o" btnstyle="btn-success" btnsize="col-xs-12 im-no-pad" ng-show="model.showUpload === false"></im-button-directive>' +
                '</div>',
            link: function ($scope) {
                $scope.model = {
                    mId: uuid(),
                    iId: uuid(),
                    spreadsheet: null,
                    hasData: false,
                    isTxt: false,
                    showUpload: false,
                    actionbarVisible: true,
                    dataRead: false,
                    dataReading: false,
                    datalist: [],
                    dataJSON: '',
                    rawFile: null,
                    fileName: '',
                    encodings: {
                        selected: '1',
                        data: [{ item_id: '1', item_name: 'UTF-8' }, { item_id: '2', item_name: 'Binary' }, { item_id: '3', item_name: 'ISO-8859-1' }]
                    },
                    allowedExtensions: ['.xlsx'], // ['.xlsx', '.json']
                    //otherExtensions: ['.txt', '.xml', '.pdf'],
                    invalidExtensions: []
                };

                $scope.onChange = function (e) {
                    //console.log("Changed: " + e);
                    //console.dir(e);
                    //console.dir(e.range._ref.topLeft);
                    //console.log(e.range._ref.topLeft);

                    var spreadsheet = $scope.model.spreadsheet;
                    //var sheet = spreadsheet.sheetByIndex(0);
                    $timeout(function () {
                        var spreadsheetZero = spreadsheet.toJSON().sheets[0];
                        var headerIndex = 0;
                        for (var i = 0; i < spreadsheetZero.rows.length; i++) {
                            if (spreadsheetZero.rows[i].index === 0) {
                                headerIndex = i;
                                $scope.headers = spreadsheet.toJSON().sheets[0].rows[i].cells;
                                break;
                            }
                        }

                        var rowNum = e.range._ref.topLeft.row;
                        //console.log('rowNum: ' + rowNum);
                        var colNum = e.range._ref.topLeft.col;
                        //console.log('colNum: ' + colNum);
                        var row = spreadsheetZero.rows[rowNum];
                        //console.log('row: ' + angular.toJson(row));
                        var cells = spreadsheetZero.rows[headerIndex].cells;
                        //console.log('cells: ' + angular.toJson(cells));
                        var cell = row.cells[colNum];
                        //console.log('cell: ' + angular.toJson(cell));

                        if (rowNum === 0) {
                            // FOR NOW, CHANGES TO HEADER WILL BE UNAFFECTED

                            //for (var i = 0; i < $scope.model.datalist.length; i++) {
                            //    $scope.model.datalist[i][cells[colNum]] = cell.value
                            //}
                            //$scope.model.datalist[rowNum - 1][cells[colNum].value] = cell.value;
                            //console.log($scope.model.datalist[0]);
                            //for (var c = 0; i < cells.length; i++) {
                            //    if (angular.isUndefined($scope.model.datalist[i][cell.value]))
                            //    //console.log('value: ' + $scope.model.datalist[i][cells[colNum].value]);
                            //    //$scope.model.datalist[i][cell.value] = $scope.model.datalist[i][cells[colNum].value];
                            //    //$scope.model.datalist[i]['hello'] = 'test';
                            //    //delete $scope.model.datalist[i][cells[colNum].value];
                            //}

                            //for (var i = 0; i < $scope.model.datalist.length; i++) {
                            //    console.log('value: ' + $scope.model.datalist[i][cells[colNum].value]);
                            //    $scope.model.datalist[i][cell.value] = $scope.model.datalist[i][cells[colNum].value];
                            //    //$scope.model.datalist[i]['hello'] = 'test';
                            //    //delete $scope.model.datalist[i][cells[colNum].value];
                            //}
                            //console.log('datalist');
                            //console.dir($scope.model.datalist);
                            //console.dir($scope.model.datalist[rowNum][cells[colNum]]);
                        } else {
                            //console.log('cell');
                            if (angular.isUndefined(cell) || angular.isUndefined(cell.value) || cell.value === null || cell.index !== colNum) {
                                $scope.model.datalist[rowNum - 1][cells[colNum].value] = '';
                            } else {
                                $scope.model.datalist[rowNum - 1][cells[colNum].value] = cell.value;
                            }
                        }
                        $scope.listItems.records = $scope.model.datalist;
                    }, 100);
                };

                $scope.onRender = function (e) {
                    //console.log("Spreadsheet rendered");
                    //console.dir(e);
                    if ($scope.model.actionbarVisible === true) {
                        if (angular.isDefined(e.sender) && angular.isDefined(e.sender.element) && angular.isDefined(e.sender.element.children()) && angular.isDefined(e.sender.element.children()[0])) {
                            e.sender.element.children()[0].remove();
                            $scope.model.actionbarVisible = false;

                            //var height = window.innerHeight;
                            //e.sender.element.innerHeight(height-300);
                        }
                    }
                };

                $scope.onSelect = function (e) {
                    //console.log("File selected :: " + e);
                    //console.dir(e);

                    var extension = e.files[0].extension.toLowerCase();
                    var name = e.files[0].name;
                    if ($scope.model.allowedExtensions.indexOf(extension) === -1) {
                        $scope.model.hasData = false;
                        console.log('Not a valid spreadsheet file format!');

                        //if ($scope.model.otherExtensions.indexOf(extension) !== -1) {
                        if ($scope.model.invalidExtensions.indexOf(extension) !== -1) {
                            $scope.model.hasData = false;
                            $scope.model.isTxt = false;
                            console.log('Not a valid file format!');
                            $scope.listItems = { filename: name, records: [] };
                        } else {
                            $scope.model.isTxt = true;
                            //console.log("event :: select (" + message + ")");
                            //console.dir(e.files[0]);
                            //console.dir(e);

                            $scope.model.spreadsheet = $('#' + $scope.model.mId).data("kendoSpreadsheet");

                            $scope.readTxtFile(e.files[0].rawFile);
                        }

                        $scope.listItems = { filename: name, records: [] };
                        //e.preventDefault();
                    } else {
                        //console.log("event :: select (" + message + ")");
                        //console.dir(e.files[0]);
                        //console.dir(e);
                        $scope.model.hasData = true;
                        $scope.model.isTxt = false;

                        $scope.model.spreadsheet = $('#' + $scope.model.mId).data("kendoSpreadsheet");

                        $scope.readFromFile(e.files[0].rawFile, name);
                    }
                };

                $scope.onRemove = function (e) {
                    //console.log("File removed :: " + e);
                    //console.dir(e);
                };

                $scope.onExcelExport = function (e) {
                    //e.preventDefault();
                    //console.log('Spreadsheet is exported to Excel :: ');
                    //console.dir(e);
                    //console.dir(e.sender);
                    //console.dir(e.workbook);
                    //console.dir(e.data);
                };

                //$scope.onAdd = function (e) {
                //    console.log("Task added: " + e);
                //}

                //$scope.onEdit = function (e) {
                //    console.log("Task about to be edited :: " + e);
                //}

                //$scope.onCancel = function (e) {
                //    console.log("Cancel task edit :: " + e);
                //}

                //$scope.onSave = function (e) {
                //    console.log("Task saved :: " + e); //Saved
                //    console.dir(e);
                        
                //}

                //$scope.onDataBinding = function (e) {
                //    console.log("Gantt data binding");
                //    //console.dir(e);
                //}

                //$scope.onNavigate = function (e) {
                //    console.log(kendo.format("navigate:: view:{0};", e.view));
                //    console.dir(e);
                //}

                //$scope.onMoveStart = function (e) {
                //    console.log("moveStart");
                //}

                //$scope.onMove = function (e) {
                //    console.log("move");
                //}

                //$scope.onMoveEnd = function (e) {
                //    console.log("moveEnd");
                //    console.dir(e);
                //}

                //$scope.onResizeStart = function (e) {
                //    console.log("resizeStart");
                //}

                //$scope.onResize = function (e) {
                //    console.log("resize");
                //}

                //$scope.onResizeEnd = function (e) {
                //    console.log("resizeEnd");
                //}

                //function isInArray(value, array) {
                //    return array.indexOf(value) > -1;
                //}

                //function isNumber(n) {
                //    return !isNaN(parseFloat(n)) && isFinite(n);
                //}

                //$scope.dataReadStep = function () {
                //    var increment = 1, skip = 0, step = 0;
                //    //$scope.model.progressInfo.step = 0;

                //    console.log('start');

                //    var doStep = function () {
                //        $timeout(function () {
                //            console.log('timeout step: ' + $scope.model.progressInfo.step);
                //            console.log('timeout max: ' + $scope.model.progressInfo.max);
                //            if (($scope.model.progressInfo.step >= $scope.model.progressInfo.max && $scope.model.progressInfo.max !== -1) || $scope.model.progress === 'ABORT') return;
                //            //step = $scope.model.progressInfo.step + skip;
                //            //$scope.model.progressInfo.field = items[step].item_name;
                //            //$scope.model.progressInfo.step += increment;

                //            //console.log('timeout: ' + $scope.model.progressInfo.step);

                //            modalService.progressInfo.step = $scope.model.progressInfo.step;
                //            //modalService.progressInfo.field = $scope.model.progressInfo.field;

                //            doStep();
                //        }, 10);
                //    };

                //    doStep();
                //}

                //$scope.dataReadProgress = function () {
                //    $scope.model.progress = 'START';
                //    $scope.model.progressInfo.label = 'progressbar';
                //    //$scope.model.progressInfo.plabel = 'progressbar';
                //    $scope.model.progressInfo.field = '';
                //    //$scope.model.progressInfo.remField = '';
                //    //$scope.model.progressInfo.min = 0;
                //    $scope.model.progressInfo.max = -1;
                //    $scope.model.progressInfo.step = 0;

                //    $scope.dataReadStep();

                //    modalService.showProgress($scope.model.progressInfo).then(function (value) {
                //        $scope.model.progress = value;
                //    });
                //}

                //function s2ab(s) {
                //    var buf = new ArrayBuffer(s.length);
                //    var view = new Uint8Array(buf);
                //    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                //    return buf;
                //}
                
                function isIllegalHeader(n) {
                    var regex = new RegExp('\W');
                    return regex.test(n);
                }

                function urltoFile(url, filename, mimeType) {
                    return (fetch(url)
                        .then(function (res) { return res.arrayBuffer(); })
                        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
                    );
                }

                $scope.readFromJSON = function (jsn) {
                    $scope.model.fileName = 'db';
                    //console.log('JSON: ');
                    //console.log(jsn);

                    $(".k-upload-files > li").remove();

                    var j = angular.fromJson(jsn);
                    var k = Object.keys(j[0]);

                    //console.log('JJ: ');
                    //console.dir(j);
                    //console.log(k);

                    var json = { rows: [{ index: 0, cells: [] }] };
                    //json.rows[0].index = 0;

                    for (var i = 0; i < k.length; i++) {
                        json.rows[0].cells.push({ index: i, value: k[i] });
                    }

                    $scope.spreadsheetOptions.rows = j.length + 1;

                    for (var n = 0; n < j.length; n++) {
                        json.rows.push({ index: n + 1, cells: [] });
                        for (var m = 0; m < k.length; m++) {
                            json.rows[n + 1].cells.push({ index: m, value: j[n][k[m]] });
                        }
                    }

                    $scope.model.hasData = true;

                    //var spreadsheet = $scope.model.spreadsheet;
                    //var sheet = spreadsheet.sheetByIndex(0);
                    $timeout(function () {
                        //sheet.fromJSON(json);

                        //console.log('json');
                        //console.dir(json);

                        $scope.model.spreadsheet.fromJSON({ sheets: [json] });
                        $scope.model.spreadsheet.refresh();

                        $scope.organizeData();
                    }, 200);
                };

                $scope.readFromFile = function (file, name) {
                    $scope.model.rawFile = file;
                    $scope.model.fileName = name;
                    //console.log('$scope.model.rawFile');
                    //console.dir($scope.model.rawFile);

                    $scope.model.dataRead = false;
                    $scope.model.dataReading = true;

                    $timeout(function () {
                        $scope.model.spreadsheet.fromFile($scope.model.rawFile).then(function (value) {
                            $scope.model.dataRead = true;
                        });
                    }, 50);
                };

                $scope.readTxtFile = function (file) {
                    $scope.model.rawFile = file;
                    var reader = new FileReader();
                    reader.onload = function (progressEvent) {
                        var lines = this.result.split('\n');
                        $scope.model.datalist.length = 0;
                        for (var n = 0; n < lines.length; n++) {
                            $scope.model.datalist.push({ value: lines[n] });
                        }
                        for (var c = 0; c < $scope.model.encodings.data.length; c++) {
                            if ($scope.model.encodings.data[c].item_id === $scope.model.encodings.selected) {
                                $scope.listItems.encoding = $scope.model.encodings.data[c].item_name;
                                break;
                            }
                        }
                        $scope.headers.length = 0;
                        $scope.listItems.records = $scope.model.datalist;
                        $timeout(function () {
                            $scope.model.dataJSON = angular.toJson($scope.model.datalist);
                        }, 175);
                    };

                    if ($scope.model.encodings.selected === '1') {
                        reader.readAsText(file);
                    } else if ($scope.model.encodings.selected === '2') {
                        reader.readAsBinaryString(file);
                    } else {
                        var encoding = '';
                        for (var i = 0; i < $scope.model.encodings.data.length; i++) {
                            if ($scope.model.encodings.data[i].item_id === $scope.model.encodings.selected) {
                                encoding = $scope.model.encodings.data[i].item_name;
                                break;
                            }
                        }
                        reader.readAsText(file, encoding);
                    }
                };

                $scope.organizeData = function () {
                    var spreadJson = $scope.model.spreadsheet.toJSON();
                    //console.log('spreadsheet.toJSON()');
                    //console.dir(spreadJson);
                    var headerIndex = 0;
                    var validAttrs = true;
                    $scope.model.datalist.length = 0;
                    for (var i = 0; i < spreadJson.sheets[0].rows.length; i++) {
                        if (spreadJson.sheets[0].rows[i].index === 0) {
                            headerIndex = i;
                            $scope.headers = spreadJson.sheets[0].rows[i].cells;
                            break;
                        }
                    }

                    var rows = spreadJson.sheets[0].rows;
                    var cells = rows[headerIndex].cells;
                    var cLength = 0;

                    if (cells.length < spreadJson.sheets[0].columns.length) {
                        validAttrs = false;
                        modalService.show({
                            type: 'danger',
                            title: 'Error in document',
                            message: 'HEADER IS NOT VALID - Number of columns does not match - Missing value in row 1',
                            buttons: [
                                {
                                    label: 'OK',
                                    close: true
                                }
                            ]
                        });
                        consoleService.errorLog('HEADER IS NOT VALID - Number of columns does not match - Missing value in row 1');
                    } else {
                        for (var n = 0; n < cells.length; n++) {
                            cLength = n + 1;
                            if (angular.isUndefined(cells[n].value) || cells[n].value === '' || isIllegalHeader(cells[n].value) === true) {
                                cLength = n;
                                var errorMessage = '';
                                if (angular.isUndefined(cells[n].value)) {
                                    errorMessage = ' - Column ' + (n + 1) + ' is undefined';
                                } else if (cells[n].value === '') {
                                    errorMessage = ' - Column ' + (n + 1) + ' is empty';
                                } else if (isIllegalHeader(cells[n].value) === true) {
                                    errorMessage = ' - Column ' + (n + 1) + ' contains invalid char';

                                    modalService.show({
                                        type: 'danger',
                                        title: 'Error in document',
                                        message: 'HEADER IS NOT VALID - Row 1 has incorrect value' + errorMessage,
                                        buttons: [
                                            {
                                                label: 'OK',
                                                close: true
                                            }
                                        ]
                                    });
                                }

                                consoleService.errorLog('HEADER IS NOT VALID - Row 1 has incorrect value' + errorMessage);
                                break;
                            }
                        }
                    }

                    if (validAttrs) {
                        angular.forEach(rows, function (row) {
                            if (angular.isDefined(row) && row.index !== 0 && angular.isDefined(row.cells[0].value)) {
                                angular.forEach(row.cells, function (cell) {
                                    if (angular.isDefined(cell) && (angular.isUndefined(cell.index) || cell.index < cLength)) {
                                        var shift = row.index >= headerIndex ? 1 : 0;
                                        var shiftedIndex = row.index - shift;
                                        while (angular.isUndefined($scope.model.datalist[shiftedIndex])) {
                                            $scope.model.datalist.push({});
                                        }
                                        $scope.model.datalist[shiftedIndex][cells[cell.index].value] = cell.value;
                                    }
                                });

                                for (var i = 0; i < cLength; i++) {
                                    var shift = row.index >= headerIndex ? 1 : 0;
                                    var shiftedIndex = row.index - shift;
                                    if (angular.isUndefined($scope.model.datalist[shiftedIndex][cells[i].value])) {
                                        $scope.model.datalist[shiftedIndex][cells[i].value] = '';
                                    }
                                }
                            }
                        });
                        $scope.model.hasData = true;
                    } else {
                        $scope.model.fileName = '';
                        $scope.model.datalist.length = 0;
                        $scope.model.hasData = false;
                    }

                    $scope.listItems = { filename: $scope.model.fileName, records: $scope.model.datalist };
                    $scope.model.dataReading = false;
                };
                    
                $scope.saveAsExcel = function () {
                    //console.log('$scope.saveAsExcel');
                    //console.dir($scope.model.spreadsheet);

                    var workbook = new kendo.ooxml.Workbook({
                        sheets: $scope.model.spreadsheet._workbook.options.sheets
                    });

                    urltoFile(workbook.toDataURL(), 'Workbook.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet').then(function (file) {
                        FileSaver.saveAs(file, "Workbook.xlsx");
                    });
                };

                $scope.spreadsheetOptions = {
                    //toolbar: {
                    //    insert: false,
                    //    data: false
                    //},
                    //rows: 250,
                    toolbar: false,
                    //toolbar: {
                    //    home: ["open", 'exportAs']
                    //},
                    sheetsbar: false,
                    change: $scope.onChange,
                    excelExport: $scope.onExcelExport,
                    render: $scope.onRender
                };

                $scope.uploadOptions = {
                    multiple: false,
                    select: $scope.onSelect,
                    remove: $scope.onRemove
                };

                $scope.$watch(function () {
                    return $scope.setup;
                }, function (newValue, oldValue) {
                    if (!$scope?.setup?.encodingtype || angular.isUndefined($scope.setup.encodingtype) || $scope.setup.encodingtype === '' || angular.isUndefined(newValue)) return;

                    for (var c = 0; c < $scope.model.encodings.data.length; c++) {
                        if ($scope.model.encodings.data[c].item_name === newValue.encodingtype) {
                            $scope.model.encodings.selected = $scope.model.encodings.data[c].item_id;
                            break;
                        }
                    }
                });

                $scope.$watch(function () {
                    return $scope.model.encodings.selected;
                }, function (newValue, oldValue) {
                    if (angular.isUndefined(newValue) || newValue === '' || newValue === '0' || newValue === oldValue) return;
                    if (!$scope?.model?.rawFile || angular.isUndefined($scope.model.rawFile) || $scope.model.rawFile === '') return;

                    $scope.readTxtFile($scope.model.rawFile);
                });

                $scope.$watch(function () {
                    return $scope.model.dataRead;
                }, function (newValue, oldValue) {
                    if (newValue === false || newValue === oldValue) return;
                    //console.log('data read');
                    $scope.organizeData();
                });
                    
                $scope.$watch(function () {
                    return $scope.showUpload;
                }, function (newValue, oldValue) {

                    // THIS IS CORRECT JUST IMPLEMENT AS NEEDED
                    // $(".k-upload-files > li").remove();
                    // 
                    // OR
                    // 
                    //if (angular.isDefined($element.find('.k-file')[0])) {
                    //    $(".k-upload-files > li").remove();
                    //}

                    if (newValue === '0' || newValue === 'false' || newValue === false) {
                        $scope.model.showUpload = false;
                    } else {
                        $scope.model.showUpload = true;
                    }
                });
                    
                $scope.$watch(function () {
                    return $scope.exportjson;
                }, function (newValue, oldValue) {
                    if (angular.isUndefined(newValue) || newValue === '' || newValue === oldValue) return;
                    //if (angular.isUndefined(newValue) || newValue === '') {
                    //    $scope.headers.length = 0;
                    //    $scope.listItems.records = $scope.model.datalist;
                    //    return;
                    //}
                    //if (angular.isUndefined(newValue)) return;
                    //if (newValue === '' && angular.isDefined($('#' + $scope.model.mId).data("kendoSpreadsheet"))) {
                    //    var spreadsheet = $('#' + $scope.model.mId).data("kendoSpreadsheet");
                    //    spreadsheet.removeSheet(spreadsheet.toJSON().sheets[0]);
                    //} else {
                    //    $scope.readFromJSON($scope.exportjson);
                    //}

                    $scope.model.spreadsheet = $('#' + $scope.model.mId).data("kendoSpreadsheet");

                    $scope.readFromJSON($scope.exportjson);
                });
            }
        };

    return directive;
    }]);
})();
