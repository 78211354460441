(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("productPricesService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 643,
                    parameters: parms || {}
                });
            },
            listPriceGroups: function (parms) {
                return $ihttp.post({
                    method: 645,
                    parameters: parms || {}
                });
            },
            listRelatedToPriceGroups: function (parms) {
                return $ihttp.post({
                    method: 646,
                    parameters: parms || {}
                });
            },
            listRelatedToProductSizes: function (parms) {
                return $ihttp.post({
                    method: 647,
                    parameters: parms || {}
                });
            },
            getProductPriceSettings: function (parms) {
                return $ihttp.post({
                    method: 644,
                    parameters: parms || {}
                });
            },
            setActivePrice: function (setting) {
                return $ihttp.post({
                    method: 659,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
