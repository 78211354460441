(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishProdCompListAcc', {
        templateUrl: 'views/components/views/poFinishProdCompListAcc/poFinishProdCompListAcc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishProdCompListAccService', 'stateService', 'niService', function ($stateParams, poFinishProdCompListAccService, stateService, niService) {
            
            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                itemsListAccumulated: []
            };

            vm.p2pofinishprodcompacclist_selection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: '1',
                records: [
                    { item_id: '0', item_name: 'last_picked_info', item_func: 'last_picked_info' },
                    { item_id: '1', item_name: 'component_distribution', item_func: 'component_distribution' }
                ]
            };

		    // #endregion VARIABLES & DEFINITIONS

            // ###############
            // PROCEDURE CALLS
            // ###############
            
            poFinishProdCompListAccService.getP2PoFinishProdCompListAcc($stateParams.p2_pofinish_keyno, $stateParams.prodsize_keyno, $stateParams.prodchoice_keyno).then(function () {
                vm.p2_pofinishprodcomplistacc_get = poFinishProdCompListAccService.p2_pofinishprodcomplistacc_get;
                
                if (niService.number_input.number !== '') {
                    vm.p2_pofinishprodcomplistacc_get.quantity_topick = niService.number_input.number;
                    poFinishProdCompListAccService.p2_pofinishprodcomplistacc_get.quantity_topick = vm.p2_pofinishprodcomplistacc_get.quantity_topick;
                    
                    vm.recalculateP2PoFinishProdCompListAcc();
                    
                    niService.number_input.number = '';
                }
            });
            
            poFinishProdCompListAccService.loadP2PoFinishStatus($stateParams.p2_pofinish_keyno).then(function () {
                vm.p2_pofinish_status = poFinishProdCompListAccService.p2_pofinish_status;
            });
            
            poFinishProdCompListAccService.loadP2PoFinishProdCompAccLastList($stateParams.prodsize_keyno, $stateParams.prodchoice_keyno).then(function () {
                vm.p2_pofinishprodcompacc_last_list = poFinishProdCompListAccService.p2_pofinishprodcompacc_last_list;
            });
            
            poFinishProdCompListAccService.listAccumulatedComponents({
                p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                prodsize_keyno: $stateParams.prodsize_keyno,
                prodchoice_keyno: $stateParams.prodchoice_keyno
            }).then(function (result) {
                angular.copy(result, vm.model.itemsListAccumulated)
            });

            // ################################
            // BUTTON GROUP SELECT LISTS
            // ################################
            
            vm.selectP2PofinishProdCompAccList = function (l_s_p2pofinishprodcompacclist_status) {
                vm.p2pofinishprodcompacclist_selection.item_selected = l_s_p2pofinishprodcompacclist_status;
            };
            
            // ################################
            // DIRECTION BUTTON TO NUMBER INPUT
            // ################################
            
            vm.goToNi = function () {
                stateService.go('ni');
            };
            
            vm.goToPoFinishProdList = function (index) {
                stateService.go('pofinishprodlist', {
                    p2_pofinish_keyno: $stateParams.p2_pofinish_keyno
                });
            };
            
            // #region DIRECTION BUTTON FUNCTIONS
            
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'stockget':
                        go({
                            stock_keyno: vm.p2_pofinishprodcomplistacc_get.stock_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion DIRECTION BUTTON FUNCTIONS
            
            // ##############
            // REFRESH BUTTON
            // ##############
            
            vm.recalculateP2PoFinishProdCompListAcc = function () {
                poFinishProdCompListAccService.updateP2PoFinishProdCompListAcc().then(function () {
                    poFinishProdCompListAccService.listAccumulatedComponents({
                        p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                        prodsize_keyno: $stateParams.prodsize_keyno,
                        prodchoice_keyno: $stateParams.prodchoice_keyno
                    }).then(function (result) {
                        angular.copy(result, vm.model.itemsListAccumulated)
                    });

                    poFinishProdCompListAccService.getP2PoFinishProdCompListAcc($stateParams.p2_pofinish_keyno, $stateParams.prodsize_keyno, $stateParams.prodchoice_keyno).then(function () {
                        vm.p2_pofinishprodcomplistacc_get = poFinishProdCompListAccService.p2_pofinishprodcomplistacc_get;
                    });

                    poFinishProdCompListAccService.loadP2PoFinishStatus($stateParams.p2_pofinish_keyno).then(function () {
                        vm.p2_pofinish_status = poFinishProdCompListAccService.p2_pofinish_status;
                    });
                });
            };
            
            vm.finishP2PoFinish = function () {
                poFinishProdCompListAccService.finishP2PoFinish().then(function () {
                    // Variabel for ferdigmelding ifrå prosedyre sp_ws_p2_pofinish_finish
                    vm.p2_pofinish_finish = poFinishProdCompListAccService.p2_pofinish_finish;

                    if (angular.isDefined(vm.p2_pofinish_finish)) {
                        stateService.back('/' + vm.p2_pofinish_finish.webpagename + '/' + vm.p2_pofinish_finish.webpage_parms);
                    }
                });
            };
            
            //################
            //## CANCEL BUTTON
            //################
            
            vm.abortP2PoFinish = function (index) {
                poFinishProdCompListAccService.abortP2PoFinish($stateParams.p2_pofinish_keyno).then(function () {
                    vm.p2_pofinish_abort = poFinishProdCompListAccService.p2_pofinish_abort;

                    stateService.back('/' + vm.p2_pofinish_abort.webpagename + '/' + vm.p2_pofinish_abort.webpage_parms);
                });
            };
            
            // ###########
            // SAVE BUTTON
            // ###########
            
            vm.saveP2PoFinishProdCompListAcc = function () {
                stateService.back();
                //poFinishProdCompListAccService.updateP2PoFinishProdCompListAcc().then(function () {
                //    stateService.back();
                //});
            };
        }]
    });
})();