(function () {
    'use strict';

    angular.module('imApp').directive('ttIf', ['$animate', '$compile', 'eventService', function ($animate, $compile, eventService) {
        var directive = {
            multiElement: true,
            transclude: 'element',
            priority: 600,
            terminal: true,
            restrict: 'A',
            $$tlb: true,
            link: function ($scope, $element, $attr, _, $transclude) {
                var ttGroupId = $attr.ttGroupId;

                var currentValue;
                var block;
                var childScope;
                var previousElements;

                var getBlockNodes = function (nodes) {
                    var node = nodes[0];
                    var endNode = nodes[nodes.length - 1];
                    var blockNodes = [node];

                    do {
                        node = node.nextSibling;
                        if (!node) break;
                        blockNodes.push(node);
                    } while (node !== endNode);

                    return $(blockNodes);
                };


                var ttIfAction = function (value) {
                    if (value) {
                        if (!childScope) {
                            $transclude(function (clone, newScope) {
                                childScope = newScope;

                                clone[clone.length++] = $compile.$$createComment('end ttIf', $attr.ttIf);

                                // Note: We only need the first/last node of the cloned nodes.
                                // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                                // by a directive with templateUrl when its template arrives.
                                block = {
                                    clone: clone
                                };

                                $animate.enter(clone, $element.parent(), $element);
                            });
                        }
                    } else {
                        if (previousElements) {
                            previousElements.remove();
                            previousElements = null;
                        }

                        if (childScope) {
                            childScope.$destroy();
                            childScope = null;
                        }

                        if (block) {
                            previousElements = getBlockNodes(block.clone);

                            $animate.leave(previousElements).done(function (response) {
                                if (response !== false) previousElements = null;
                            });

                            block = null;
                        }
                    }
                };

                $scope.$watch($attr.ttIf, function (value) {
                    currentValue = value;

                    ttIfAction(value);
                });

                if (angular.isString(ttGroupId) && ttGroupId.length > 0) {
                    var dereg = eventService.on('ttIf:all', function (parms) {
                        if (ttGroupId !== parms.groupId)
                            return;

                        if (parms.visible) {
                            ttIfAction(true);
                        } else {
                            ttIfAction(currentValue);
                        }
                    });

                    $scope.$on('$destroy', function () {
                        if (angular.isFunction(dereg) === true) {
                            dereg();
                        }
                    });
                }
            }
        };

        return directive;
    }]);
})();