(function() {
    'use strict';

    angular.module("imApp").factory("consignmentDeliveryConfirmService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,

            data: {
                checklistdata_keyno: '',
                checklist_keyno: '',
                checklist_name: '',
                checkliststatus: '',
                checklistitems: []
            },

            get: function (custact_no, order_order_no) {
                // 1979 - sp_ws_p2_consignmentdeliveryactivity_get
                return p2DataTaskService.call(1979, {
                    custact_no: custact_no,
                    order_order_no: order_order_no
                });
            },

            getSettings: function (checklistdata_keyno) {
                return p2DataTaskService.call(922, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },

            getChecklists: function (checklistdata_keyno) {
                var deferred = $q.defer();

                var requestOptions = {
                    method: 231,
                    parameters: {
                        checklistdata_keyno: checklistdata_keyno
                    }
                };

                $ihttp.post(requestOptions).then(function (data) {
                    service.data = angular.copy(data[0]);

                    for (var i = 0; i < service.data.checklistitems.length; i++) {
                        service.checkListDataValidate(i);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },

            computeValidation: function (item) {
                return p2DataTaskService.call(1583, item);
            },

            checkListDataValidate: function (index) {
                if (service.data.checklistitems[index].elementtype === 'CS'
                    || service.data.checklistitems[index].elementtype === 'CB'
                    || service.data.checklistitems[index].elementtype === 'CL') {
                    // ('Verdien ble satt til:' + ' rad=' + index + 'verdien er' + service.data.checklistitems[index].itemvalue);
                    // Kaller funksjon som sjekker om noen skal bli synlig
                    service.checkDown(index);
                }
            },

            checkDown: function (index) {
                // ('Skal sjekke fra rad ' + index + ' og nedover til og med' + service.p2_checklistdata_get.checklistitems.length - 1);
                // Henter verdi på den raden som ble endret
                var ls_itemvalue_new = service.data.checklistitems[index].itemvalue;
                var ln_checklistitem_keyno = service.data.checklistitems[index].checklistitem_keyno;
                //('Den nye verdien det skal sjekkes på er:' + ls_itemvalue_new);
                for (var i = index + 1; i < service.data.checklistitems.length; i++) {
                    // ('Sjekker array med no ' + i);
                    if (service.data.checklistitems[i].dep1_checklistitem_keyno === ln_checklistitem_keyno) {
                        // ('Fann match på rad ' + i);
                        if (service.data.checklistitems[i].dep1_value === ls_itemvalue_new) {
                            // Her er det avhengighet og setter da visible = '1'
                            service.data.checklistitems[i].dep1_visible = '1';
                        } else {
                            service.data.checklistitems[i].dep1_visible = '0';
                        }
                    }
                }

                // Sjekker avhengighet 2
                for (var j = index + 1; j < service.data.checklistitems.length; j++) {
                    //('Sjekker array med no ' + i);
                    if (service.data.checklistitems[j].dep2_checklistitem_keyno === ln_checklistitem_keyno) {
                        //('Fann match på rad ' + i);
                        if (service.data.checklistitems[j].dep2_value === ls_itemvalue_new) {
                            //Her er det avhengighet og setter da visible = '1'
                            service.data.checklistitems[j].dep2_visible = '1';
                        } else {
                            service.data.checklistitems[j].dep2_visible = '0';
                        }
                    }
                }
            },

            save: function (index) {
                var deferred = $q.defer();

                var requestOptions = {
                    method: 1982,
                    parameters: {
                        checklistdata_keyno: service.data.checklistdata_keyno,
                        checklist_keyno: service.data.checklist_keyno,
                        checklist_name: service.data.checklist_name,
                        approve: '0',
                        checklistitems: service.data.checklistitems.map(function (a) { return { "i": a.checklistitemdata_keyno, "v": a.itemvalue }; })
                    }
                };

                $ihttp.post(requestOptions).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },

            saveAndConfirm: function (index) {
                var deferred = $q.defer();

                var requestOptions = {
                    method: 1982,
                    parameters: {
                        checklistdata_keyno: service.data.checklistdata_keyno,
                        checklist_keyno: service.data.checklist_keyno,
                        checklist_name: service.data.checklist_name,
                        approve: '1',
                        checklistitems: service.data.checklistitems.map(function (a) { return { "i": a.checklistitemdata_keyno, "v": a.itemvalue }; })
                    }
                };

                $ihttp.post(requestOptions).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },

            saveAndConfirmWithDeviation: function (index) {
                var deferred = $q.defer();

                var requestOptions = {
                    method: 1983,
                    parameters: {
                        checklistdata_keyno: service.data.checklistdata_keyno,
                        checklist_keyno: service.data.checklist_keyno,
                        checklist_name: service.data.checklist_name,
                        approve: '1',
                        checklistitems: service.data.checklistitems.map(function (a) { return { "i": a.checklistitemdata_keyno, "v": a.itemvalue }; })
                    }
                };

                $ihttp.post(requestOptions).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };


        return service;
    }]);
})();
