(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListEquipmentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListEquipmentGet: function (checklistdata_keyno, srrefundtype_id, srrefundinput_keyno) {
                return p2DataTaskService.call(1799, {
                    checklistdata_keyno: checklistdata_keyno,
                    srrefundtype_id: srrefundtype_id,
                    srrefundinput_keyno: srrefundinput_keyno
                });
            },
            loadSrCheckListEquipmentList: function (checklistdata_keyno, srrefundtype_id, srrefundinput_keyno, resource_id) {
                return p2DataTaskService.call(1800, {
                    checklistdata_keyno: checklistdata_keyno,
                    srrefundtype_id: srrefundtype_id,
                    srrefundinput_keyno: srrefundinput_keyno,
                    resource_id: resource_id
                });
            },
            save: function (checkList) {
                return p2DataTaskService.call(1802, checkList);
            },
            deleteObj: function (checkList) {
                return p2DataTaskService.call(1804, checkList);
            }
        };

        return service;
    }]);
})();