(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectOeeState', {
        templateUrl: 'views/components/views/selectOeeState/selectOeeState.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectOeeStateService', function ($stateParams, selectOeeStateService) {
            var vm = this;
            
            vm.direction_arrow = {
                arrowside: ''
            };
            
            vm.direction_arrow.arrowside = $stateParams.arrowside;
            
            selectOeeStateService.loadP2SelectOeeStateList().then(function () {
                vm.p2_select_oeestate_list = selectOeeStateService.p2_select_oeestate_list;
            });
            
            vm.saveToPreP2SelectOeeState = function (item) {
                selectOeeStateService.savingToPreP2SelectOeeState = true;

                selectOeeStateService.saveToPreP2SelectOeeState(item);
            };
        }]
    });
})();