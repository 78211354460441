//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('custactCompAdd', {
//templateUrl: 'views/components/views/custactCompAdd/custactCompAdd.template.html?v=' + module.version,
        //controllerAs: 'vm',
        //controller: ['$stateParams', '$timeout', 'custactCompAddService', 'stateService', 'searchService', 'watcherFactory', function($stateParams, $timeout, custactCompAddService, stateService, searchService, watcherFactory) {
//var vm = this;

//var watcher = watcherFactory.$create(vm);
    //        // #######################
    //        // VARIABLES & DEFINITIONS
    //        // #######################
    
    //        vm.model = {
    //            p2CustactCompGetSelection: {
    //                item_id: '',
    //                item_name: '',
    //                item_default: '0',
    //                item_selected: 'B',
    //                records: [
    //                    { item_id: '1', item_name: 'custactcomp_basic_info', item_func: 'B' },
    //                    { item_id: '2', item_name: 'custactcomp_details', item_func: 'D' }
    //                ]
    //            },
    //            p2CustactCompAddSelection: {
    //                item_id: '',
    //                item_name: '',
    //                item_default: '0',
    //                item_selected: '1',
    //                records: [
    //                    { item_id: '1', item_name: 'custactcomp_sales', item_func: '1' },
    //                    { item_id: '2', item_name: 'custactcomp_purchase', item_func: '2' },
    //                    { item_id: '3', item_name: 'custactcomp_db', item_func: '3' }
    //                ]
    //            },
    //            showField: {
    //                custactCompKeyNo: $stateParams.custactcomp_keyno
    //            },
    //            p2CustactCompAdd: {},
    //            p2CustactCompAddProduct: {},
    //            p2CustactCompGetPrice: {},
    //            p2ProductProdsizeList: {
    //                records: []
    //            },
    //            p2ProductProdchoiceList: {
    //                records: []
    //            },
    //            p2ValutaList: {
    //                records: []
    //            }
    //        };
    
    //        //console.log('custactCompKeyNo = ' + vm.model.showField.custactCompKeyNo);
    
    //        // #####################
    //        // BUTTON GROUP FUNCTION
    //        // #####################
    
    //        vm.selectP2CustactCompGet = function (lsP2CustactCompGetStatus) {
    //            //console.log('lsP2CustactCompGetStatus = ' + lsP2CustactCompGetStatus);
    //            vm.model.p2CustactCompGetSelection.item_selected = lsP2CustactCompGetStatus;
    //            //console.log('vm.model.p2CustactCompGetSelection.item_selected = ' + vm.model.p2CustactCompGetSelection.item_selected);
    //        }
    
    //        vm.selectP2CustactCompAdd = function (lsP2CustactCompAddStatus) {
    //            //console.log('lsP2CustactCompAddStatus = ' + lsP2CustactCompAddStatus);
    //            vm.model.p2CustactCompAddSelection.item_selected = lsP2CustactCompAddStatus;
    //            //console.log('vm.model.p2CustactCompAddSelection.item_selected = ' + vm.model.p2CustactCompAddSelection.item_selected);
    //        }
    
    //        // ###################
    //        // LOAD PROCEDURE CALL
    //        // ###################
            
    //        custactCompAddService.loadP2CustactCompAdd($stateParams.custact_no, $stateParams.custactcomp_keyno).then(function () {
    //            //console.log('custactCompAddService.selectingProduct = ' + custactCompAddService.selectingProduct);
                
    //            vm.model.p2CustactCompAdd = custactCompAddService.p2CustactCompAdd;
    
    //            if (custactCompAddService.selectingProduct !== true) return;
    
    //            if (custactCompAddService.selectingProduct === true && searchService.custactCompAdd.selectedProdId.length > 0) {
    //                custactCompAddService.selectingProduct = false;
    
    //                vm.model.p2CustactCompAdd.prod_id = searchService.custactCompAdd.selectedProdId;
    //                vm.model.p2CustactCompAdd.prodname = searchService.custactCompAdd.selectedProdName;
                    
    //                //console.log('searchService.custactCompAdd.selected = ' + searchService.custactCompAdd.selected);
    //                //console.log('vm.model.p2CustactCompAdd.prod_id = ' + vm.model.p2CustactCompAdd.prod_id);
    //                //console.log('searchService.custactCompAdd.selectedProdId = ' + searchService.custactCompAdd.selectedProdId);
    //                //console.log('vm.model.p2CustactCompAdd.prodname = ' + vm.model.p2CustactCompAdd.prodname);
    //                //console.log('searchService.custactCompAdd.selectedProdName = ' + searchService.custactCompAdd.selectedProdName);
    //                //console.log('searchService.custactCompAdd.selectedUnitName = ' + searchService.custactCompAdd.selectedUnitName);
    
    //                if (angular.isDefined(vm.model.p2CustactCompAdd.prod_id)) {
    //                    custactCompAddService.loadP2ProductProdsizeList(vm.model.p2CustactCompAdd.prod_id).then(function () {
    //                        vm.model.p2ProductProdsizeList = custactCompAddService.p2ProductProdsizeList;
    //                    });
    
    //                    custactCompAddService.loadP2ProductProdchoiceList(vm.model.p2CustactCompAdd.prod_id).then(function () {
    //                        vm.model.p2ProductProdchoiceList = custactCompAddService.p2ProductProdchoiceList;
    //                    });
    
    //                    custactCompAddService.loadP2CustactCompAddProduct(vm.model.p2CustactCompAdd.prod_id).then(function () {
    //                        vm.model.p2CustactCompAddProduct = custactCompAddService.p2CustactCompAddProduct;
    
    //                        vm.model.p2CustactCompAdd.prod_id = vm.model.p2CustactCompAddProduct.prod_id;
    //                        vm.model.p2CustactCompAdd.prodname = vm.model.p2CustactCompAddProduct.prodname;
    //                        vm.model.p2CustactCompAdd.prodsize_keyno = vm.model.p2CustactCompAddProduct.prodsize_keyno;
    //                        vm.model.p2CustactCompAdd.prodchoice_keyno = vm.model.p2CustactCompAddProduct.prodchoice_keyno;
    //                        vm.model.p2CustactCompAdd.unit_name = vm.model.p2CustactCompAddProduct.unit_name;
    //                        vm.model.p2CustactCompAdd.nbrof_prodsize = vm.model.p2CustactCompAddProduct.nbrof_prodsize;
    //                        vm.model.p2CustactCompAdd.nbrof_prodchoice = vm.model.p2CustactCompAddProduct.nbrof_prodchoice;
    //                    });
    //                }
    //            }
    //        });
    
    //        custactCompAddService.loadP2ValutaList().then(function () {
    //            vm.model.p2ValutaList = custactCompAddService.p2ValutaList;
    //        });
    
    //        // ######################
    //        // CHANGE BUTTON FUNCTION
    //        // ######################
    
    //        vm.changeCustactCompAdd = function (index) {
    //            $timeout(function (index) {
    //                for (var i = 0; i < vm.model.p2ValutaList.records.length; i++) {
    //                    //console.log('$TIMEOUT FOR --- vm.model.p2CustactCompAdd.cost_valuta_id=' + vm.model.p2CustactCompAdd.cost_valuta_id);
    //                    //console.log('$TIMEOUT FOR --- vm.model.p2ValutaList.records[i].valuta_id=' + vm.model.p2ValutaList.records[i].valuta_id);
    //                    if (vm.model.p2ValutaList.records[i].valuta_id == vm.model.p2CustactCompAdd.cost_valuta_id) {
    //                        //console.log('$TIMEOUT IF --- vm.model.p2ValutaList.records[i].valuta_exchangerate = ' + vm.model.p2ValutaList.records[i].valuta_exchangerate);
    //                        //console.log('$TIMEOUT IF --- vm.model.p2ValutaList.records[i].valuta_exchangefactor = ' + vm.model.p2ValutaList.records[i].valuta_exchangefactor);
    //                        vm.model.p2CustactCompAdd.cost_exchangerate = vm.model.p2ValutaList.records[i].valuta_exchangerate;
    //                        vm.model.p2CustactCompAdd.cost_exchangefactor = vm.model.p2ValutaList.records[i].valuta_exchangefactor;
    //                        //console.log('$TIMEOUT IF --- vm.model.p2CustactCompAdd.cost_exchangerate = ' + vm.model.p2CustactCompAdd.cost_exchangerate);
    //                        //console.log('$TIMEOUT IF --- vm.model.p2CustactCompAdd.cost_exchangefactor = ' + vm.model.p2CustactCompAdd.cost_exchangefactor);
    //                    }
    //                }
    //                vm.calculateCost();
    //                vm.calculateSalesAmounts();
    //            }, 50);
    //        }
    
    //        // ##############
    //        // WATCH FUNCTION
    //        // ##############
    
    //        watcher.$watch(function () {
    //            return vm.model.p2CustactCompAdd.prodsize_keyno;
    //        }, function (newVal) {
    //            if (vm.model.p2CustactCompAdd.prod_id !== '' && vm.model.p2CustactCompAdd.prodsize_keyno > 0 && vm.model.p2CustactCompAdd.prodchoice_keyno > 0) {
    //                custactCompAddService.loadP2CustactCompGetPrice(vm.model.p2CustactCompAdd.prod_id, vm.model.p2CustactCompAdd.prodsize_keyno, vm.model.p2CustactCompAdd.prodchoice_keyno, $stateParams.custact_no).then(function () {
    //                    vm.model.p2CustactCompGetPrice = custactCompAddService.p2CustactCompGetPrice;
    
    //                    vm.model.p2CustactCompAdd.cost_valuta_id = vm.model.p2CustactCompGetPrice.cost_valuta_id;
    //                    vm.model.p2CustactCompAdd.cost_price_invval = vm.model.p2CustactCompGetPrice.cost_price_invval;
    //                    vm.model.p2CustactCompAdd.ordval_price_brt = vm.model.p2CustactCompGetPrice.ordval_price_brt;
    
    //                    vm.changeCustactCompAdd();
    //                });
    //            }
    //        });
    
    //        watcher.$watch(function () {
    //            return vm.model.p2CustactCompAdd.prodchoice_keyno;
    //        }, function (newVal) {
    //            if (vm.model.p2CustactCompAdd.prod_id !== '' && vm.model.p2CustactCompAdd.prodsize_keyno > 0 && vm.model.p2CustactCompAdd.prodchoice_keyno > 0) {
    //                custactCompAddService.loadP2CustactCompGetPrice(vm.model.p2CustactCompAdd.prod_id, vm.model.p2CustactCompAdd.prodsize_keyno, vm.model.p2CustactCompAdd.prodchoice_keyno, $stateParams.custact_no).then(function () {
    //                    vm.model.p2CustactCompGetPrice = custactCompAddService.p2CustactCompGetPrice;
    
    //                    vm.model.p2CustactCompAdd.cost_valuta_id = vm.model.p2CustactCompGetPrice.cost_valuta_id;
    //                    vm.model.p2CustactCompAdd.cost_price_invval = vm.model.p2CustactCompGetPrice.cost_price_invval;
    //                    vm.model.p2CustactCompAdd.ordval_price_brt = vm.model.p2CustactCompGetPrice.ordval_price_brt;
    
    //                    vm.changeCustactCompAdd();
    //                });
    //            }
    //        });
    
    //        watcher.$watch(function () {
    //            return vm.model.p2CustactCompAdd.cost_price_invval;
    //        }, function (newVal) {
    //            vm.calculateCost();
    //            vm.calculateSalesAmounts();
    //        });
    
    //        watcher.$watch(function () {
    //            return vm.model.p2CustactCompAdd.quantity;
    //        }, function (newVal) {
    //            vm.calculateCost();
    //            vm.calculateSalesAmounts();
    //        });
    
    //        watcher.$watch(function () {
    //            return vm.model.p2CustactCompAdd.ordval_price_brt;
    //        }, function (newVal) {
    //            vm.calculateSalesAmounts();
    //        });
    
    //        watcher.$watch(function () {
    //            return vm.model.p2CustactCompAdd.orderdiscount_pst;
    //        }, function (newVal) {
    //            vm.calculateSalesAmounts();
    //        });
    
    //        // ##############
    //        // CALC FUNCTIONS
    //        // ##############
    
    //        vm.calculateCost = function () {
    //            vm.model.p2CustactCompAdd.cost_price_locval = vm.model.p2CustactCompAdd.cost_price_invval * vm.model.p2CustactCompAdd.cost_exchangefactor;
    //            vm.model.p2CustactCompAdd.cost_locval = vm.model.p2CustactCompAdd.cost_price_locval * vm.model.p2CustactCompAdd.quantity;
    //            vm.model.p2CustactCompAdd.cost_invval = vm.model.p2CustactCompAdd.cost_price_invval * vm.model.p2CustactCompAdd.quantity;
    //        }
    
    //        vm.calculateSalesAmounts = function () {
    //            vm.model.p2CustactCompAdd.ordval_price_net =
    //                vm.model.p2CustactCompAdd.ordval_price_brt - 
    //                (vm.model.p2CustactCompAdd.ordval_price_brt * vm.model.p2CustactCompAdd.orderdiscount_pst / 100);
    //            vm.model.p2CustactCompAdd.ordval_amount_net = vm.model.p2CustactCompAdd.ordval_price_net * vm.model.p2CustactCompAdd.quantity;
    //            //Valutaomregning
    //            vm.model.p2CustactCompAdd.locval_price_brt  = vm.model.p2CustactCompAdd.ordval_price_brt * vm.model.p2CustactCompAdd.ordval_exchangefactor;
    //            vm.model.p2CustactCompAdd.locval_price_net  = vm.model.p2CustactCompAdd.ordval_price_net * vm.model.p2CustactCompAdd.ordval_exchangefactor;
    //            vm.model.p2CustactCompAdd.locval_amount_net = vm.model.p2CustactCompAdd.ordval_amount_net * vm.model.p2CustactCompAdd.ordval_exchangefactor;
    //            vm.model.p2CustactCompAdd.db_locval = vm.model.p2CustactCompAdd.locval_amount_net - vm.model.p2CustactCompAdd.cost_locval;
    
    //            if (vm.model.p2CustactCompAdd.db_locval > '0.00' || vm.model.p2CustactCompAdd.locval_amount_net > '0.00') {
    //                vm.model.p2CustactCompAdd.db_pst = (vm.model.p2CustactCompAdd.db_locval * 100) / vm.model.p2CustactCompAdd.locval_amount_net;
    //            }
    //        }
            
    //        // #################################
    //        // DIRECTION BUTTON TO SELECTPRODUCT
    //        // #################################
    
    //        vm.goToSelectSearch = function (index) {
    //            custactCompAddService.selectingProduct = true;
    
    //            stateService.go('selectproduct', {
    //                webpage_name: stateService.getCurrentName(),
    //                parm1: $stateParams.custact_no,
    //                parm2: '0'
    //            });
    //        };
    
    //        // ###########
    //        // SAVE BUTTON
    //        // ###########
    
    //        vm.saveP2CustactCompAdd = function () {
    //            custactCompAddService.p2CustactCompAddProduct = vm.model.p2CustactCompAddProduct;
    
    //            custactCompAddService.saveP2CustactCompAdd().then(function () {
    //                stateService.back();
    //            });
    //        }
    
    //        // ######################
    //        // DELETE BUTTON FUNCTION
    //        // ######################
    
    //        vm.deleteP2CustactComp = function () {
    //            custactCompAddService.deleteP2CustactComp().then(function () {
    //                stateService.back();
    //            });
    //        }
    //    }]
    //});
//})();
