(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectPortalUserService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            select_portal_user: {
                item_id: '',
                item_name: '',
                item_parms: '',
                itam_state: '',
                records: []
            },
            loadPortalUserSelectList: function (custact_no, user_name) {
                var deferred = $q.defer();

                var parms_portal_user_selectlist = {
                    method: 95,
                    parameters: {
                        custact_no: custact_no,
                        user_name: user_name
                    }
                };

                $ihttp.post(parms_portal_user_selectlist).then(function (data) {
                    service.select_portal_user.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.select_portal_user.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            goToCustactMembers: function (item) {
                var deferred = $q.defer();

                var parms_custact_member_add = {
                    method: 96,
                    parameters: {
                        custact_no: $stateParams.arg1,
                        portal_user_keyno: item.item_id
                    }
                };

                $ihttp.post(parms_custact_member_add).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();