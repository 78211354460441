(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderLine', {
        templateUrl: 'views/components/views/orderLine/orderLine.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'utilityService', 'stateService', 'modalService', 'orderLineService', 'logisticService', 'economyService', 'stockAssignService', 'purchLineService', 'typeaheadService', 'rememberService', 'translateService', function ($stateParams, $timeout, utilityService, stateService, modalService, orderLineService, logisticService, economyService, stockAssignService, purchLineService, typeaheadService, rememberService, translateService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            var initLoadDone = false;
    		
            let variableNames = {
                position_keyno: '',
                stockplace_id: ''
            };

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            let digits = 2;

            vm.model = {
                orderLineKeyNo: $stateParams.orderline_keyno,
                barcodeBtnList: [
                    { item_id: '0', item_name: 'calculator', item_func: 'calculator_item', glyph: 'fa-calculator', color: 'primary' },
                    { item_id: '1', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '2', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'primary' }
                ],
                productSearchButtons: [
                    {
                        id: 'add',
                        icon: 'fa fa-solid fa-plus',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('productcreate', { argtype: 'orderhead', argvalue: vm.model.edit.order_internal_no })
                    }
                ],
                productSearchButtons2: [
                    {
                        id: 'configure',
                        icon: 'fa fa-solid fa-cubes',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => {
                            rememberFunc('stockplace_id');

                            stateService.go('orderlinems', {
                                argtype: 'orderline',
                                headno: vm.model.edit.order_internal_no,
                                lineno: vm.model.edit.orderline_keyno
                            })
                        }
                    },
                    {
                        id: 'add',
                        icon: 'fa fa-solid fa-plus',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('productcreate', { argtype: 'orderhead', argvalue: vm.model.edit.order_internal_no })
                    }
                ],
                prodIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                serviceObjectNameButtons: [
                    {
                        id: 'list',
                        icon: 'fa fa-solid fa-bars',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('serviceobjects', {
                            argtype: '-',
                            argvalue: '-'
                        })
                    }
                ],
                bgView: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'orderline_gb_view_basic', color: 'primary', onClick: () => vm.model.bgView.selected = 'BASIC' },
                        { id: 'OTHER', label: 'orderline_gb_view_other', color: 'primary', onClick: () => vm.model.bgView.selected = 'OTHER' }
                    ]
                },
                bgOrderLinePriceType: {
                    selected: 'EX',
                    buttons: [
                        { id: 'EX', label: 'bg_orderline_pricetype_ex', color: 'primary', onClick: () => vm.model.bgOrderLinePriceType.selected = 'EX' },
                        { id: 'INCL', label: 'bg_orderline_pricetype_incl', color: 'primary', onClick: () => vm.model.bgOrderLinePriceType.selected = 'INCL' },
                        { id: 'MARGIN', label: 'bg_orderline_pricetype_margin', color: 'primary', onClick: () => vm.model.bgOrderLinePriceType.selected = 'MARGIN' }
                    ]
                },
                edit: {},
                selectListStockPlaces: [],
                selectListPrices: [],
                selectListProductSizes: [],
                selectListProductChoices: [],
                selectListProductUnits: [],
                selectListPositions: [],
                selectListTaxes: [],
                selectListKits: [],
                lockedSaveAndNew: false,
                lockedSaveAndBack: false,
                lockedCancelRest: false,
                lockedDelete: false,
                searching: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgView = function (value) {
                vm.model.bgView.selected = value;
            };

            vm.selectBgOrderLinePriceType = function (value) {
                vm.model.bgOrderLinePriceType.selected = value;
                vm.model.edit.bg_orderline_pricetype = vm.model.bgOrderLinePriceType.selected;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            let setOrderLinePriceTypeView = function (id) {
                vm.model.bgOrderLinePriceType.selected = vm.model.edit.bg_orderline_pricetype = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ####################
            // LOAD PROCEDURE CALLS
            // ####################
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };
            
            let loadPrices = function () {
                economyService.listPrices().then(function (list) {
                    angular.copy(list, vm.model.selectListPrices);
                });
            };
            
            let loadEdit = function () {
                orderLineService.getEdit({
                    order_internal_no: $stateParams.order_internal_no,
                    orderline_keyno: $stateParams.orderline_keyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    if (vm.model.edit.show_4_digits_in_price === '1') {
                        digits = 4;
                    } else {
                        digits = 2;
                    }

                    setOrderLinePriceTypeView(vm.model.edit.bg_orderline_pricetype);

                    loadStockPlaces();
                    loadPositions();
                    loadKits();
                });
            };
            
            let loadProductSizes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return logisticService.listProductSizes({ prod_id: vm.model.edit.prod_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListProductSizes);
                });
            };
            
            let loadProductChoices = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return logisticService.listProductChoices(vm.model.edit).then(function (list) {
                    angular.copy(list, vm.model.selectListProductChoices);
                });
            };
            
            let loadProductUnits = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                
                return stockAssignService.listProductUnits({ prod_id: vm.model.edit.prod_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListProductUnits);
                });
            };
            
            let loadTaxes = function () {
                economyService.listTaxes().then(function (list) {
                    angular.copy(list, vm.model.selectListTaxes);
                });
            };
            
            let retrieveTax = function () {
                if (utilityService.validateParmsValue(vm.model.edit.tax_keyno) !== true) return;
                
                return economyService.getTax({ tax_keyno: vm.model.edit.tax_keyno }).then(function (response) {
                    vm.model.edit.tax_pst = response[0].tax_pst;
                    recalcPriceInclTax();
                });
            };

            let loadPositions = function () {
                if (utilityService.validateParmsValue(vm.model.edit.order_internal_no) !== true) return;

                return orderLineService.listPositions({
                    argtype: 'order_internal_no',
                    argvalue: vm.model.edit.order_internal_no
                }).then(function (list) {
                    angular.copy(list, vm.model.selectListPositions);
                });
            };
            
            let loadKits = function () {
                if (utilityService.validateParmsValue(vm.model.edit.order_internal_no) !== true) return;
                
                return orderLineService.listKits({ order_internal_no: vm.model.edit.order_internal_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListKits);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'position_keyno':
                            variableNames[key] = 'orderhead' + '.' + key + '.' + 'oi' + '=' + vm.model.edit.order_internal_no;
                            break;
                        case 'stockplace_id':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + 'stockplace_id_config';
                            break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'position_keyno':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // ## SHOW CALCULATOR FUNCTIONS	##
            
            var goCalcBarcode = function () {
                modalService.showCalculator(vm.model.edit.barcode).then(function (value) {
                    vm.model.edit.barcode = value;
                });
            };
            
            // ## BUTTON LIST FUNCTIONS ##
            
            vm.barcodeBtnListFunc = function (item_func) {
                if (item_func === 'barcode_item') {
                    vm.scanBarcode();
                }else if (item_func === 'calculator_item') {
                    goCalcBarcode();
                } else if (item_func === 'erase_item') {
                    vm.model.edit.barcode = '';
                } else if (item_func === 'search_item') {
                    vm.scanBarcode();
                }
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'product_search':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchProduct({
                            prod_name: vm.model.edit[id] || '',
                            webpage_name: stateService.getCurrentName(),
                            parm1: $stateParams.order_internal_no,
                            parm2: $stateParams.orderline_keyno,
                            supplier_no: '',
                            search_only_this_supplier: '0',
                            businessco_no: vm.model.edit.businessco_no,
                            onlyonstock: vm.model.edit.onlyonstock,
                            stockplace_id: vm.model.edit.stockplace_id
                        });
                        break;
                    case 'serviceobject_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.lookUpServiceObjects({ searchtext: vm.model.edit[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'product_search':
                        if (item) {
                            vm.model.edit.prod_id = item?.item_id ?? '0';
                            vm.model.edit.product_search = item?.item_name ?? '';

                            retrieveProductChanged();
                        } else {
                            vm.onTypeaheadClear('product_search');
                        }
                        break;
                    case 'serviceobject_name':
                        if (item) {
                            vm.model.edit.serviceobject_keyno = item?.item_id ?? '0';
                            vm.model.edit.serviceobject_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('serviceobject_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'product_search':
                        vm.model.edit.product_search = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('product_search');
                        }
                        break;
                    case 'serviceobject_name':
                        vm.model.edit.serviceobject_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.serviceobject_keyno = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('serviceobject_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'product_search':
                        vm.model.edit.prod_id = '0';
                        vm.model.edit.product_search = '';

                        if ($stateParams.orderline_keyno === '0') loadEdit();
                        break;
                    case 'serviceobject_name':
                        vm.model.edit.serviceobject_keyno = '0';
                        vm.model.edit.serviceobject_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            vm.setQuantityFocus = function (index) {
                if (angular.isUndefined(index)) {
                    index = 0;
                }

                document.getElementsByName('formOrderLineFocus')[0][index].focus();
            };
            
            let retrieveProductChanged = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                
                return orderLineService.changeProduct({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    let changedProd = false;

                    if (vm.model.edit.prod_id !== response[0].prod_id) {
                        changedProd = true;
                    }

                    vm.model.edit.prod_id = response[0].prod_id;
                    vm.model.edit.prod_name = response[0].prod_name;
                    
                    if (vm.model.edit.orderline_keyno === '0') {
                        vm.model.edit.prodsize_keyno = response[0].prodsize_keyno;

                        if (response[0].nbrof_prodchoice === '1') {
                            vm.model.edit.prodchoice_keyno = response[0].prodchoice_keyno;
                        }
                    }
                    
                    vm.model.edit.unit_name_stock = response[0].unit_name_stock;
                    vm.model.edit.nbrof_prodsize = response[0].nbrof_prodsize;
                    vm.model.edit.nbrof_prodchoice = response[0].nbrof_prodchoice;
                    vm.model.edit.stock_unit_no = response[0].stock_unit_no;

                    if (vm.model.edit.orderline_keyno === '0') {
                        vm.model.edit.input_unit_no = response[0].stock_unit_no;
                    }
                    
                    vm.model.edit.prod_text1 = response[0].prod_text1;
                    vm.model.edit.prod_text2 = response[0].prod_text2;
                    vm.model.edit.prod_text3 = response[0].prod_text3;
                    vm.model.edit.drawing_no = response[0].drawing_no;
                    vm.model.edit.prod_keyno = response[0].prod_keyno;

                    if (changedProd) {
                        if (response[0].setting_orderline_copy_prod_text2_to_note_before === '1') {
                            vm.model.edit.note_before = vm.model.edit.prod_text2;
                        }

                        if (response[0].setting_orderline_copy_prod_text2_to_orderline_note === '1') {
                            vm.model.edit.orderline_note = vm.model.edit.prod_text2;
                        }
                    }

                    loadProductSizes();
                    loadProductChoices();
                    loadProductUnits(vm.model.edit.prod_id);

                    updatePrice();

                    vm.setQuantityFocus();
                });
            };
            
            let retrieveExampleQty = function () {
                if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.input_quantity, true) !== true) return;

                if (vm.model.edit.input_unit_no > 0) {
                    return purchLineService.getExampleData(vm.model.edit).then(function (response) {
                        vm.model.edit.exampledata = response[0].exampledata;
                    });
                }
            };
            
            let updatePrice = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prodchoice_keyno, true) !== true) return;
                
                return orderLineService.getPrice(vm.model.edit).then(function (response) {
                    vm.model.edit.input_price_ordval = response[0].input_price_ordval;
                    vm.model.edit.input_price_ordval_incl_tax = response[0].input_price_ordval_incl_tax;
                    vm.model.edit.orderdiscount_pst = response[0].orderdiscount_pst;
                    vm.model.edit.estimated_cost_locval = response[0].estimated_cost_locval;

                    vm.model.edit.linediscount_pst = utilityService.parseNumber(response[0].linediscount_pst);
                    vm.model.edit.linediscount_ordval = (utilityService.parseNumber(vm.model.edit.input_price_ordval) * utilityService.parseNumber(vm.model.edit.linediscount_pst) / 100).toFixed(digits);
                });
            };
    
            //#######################
            //## SCAN BUTTON FUNCTION
            //#######################
    
            vm.scanBarcode = function () {
                orderLineService.loadOrderLineBarcodeScan(vm.model.edit.barcode).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        vm.model.edit.prod_id = response[0].prod_id;
                        vm.model.edit.prodsize_keyno = response[0].prodsize_keyno;
                        vm.model.edit.prodchoice_keyno = response[0].prodchoice_keyno;
                        vm.model.edit.prod_keyno = response[0].prod_keyno;
                        
                        retrieveProductChanged();
                    }
                });
            };

            // #region RECALCULATION FUNCTIONS

            vm.calcInputAmountOrdval = function () {
                var ipo = utilityService.parseNumber(vm.model.edit.input_price_ordval).toFixed(digits);
                var ldp = utilityService.parseNumber(vm.model.edit.linediscount_pst) / 100;
                var odp = utilityService.parseNumber(vm.model.edit.orderdiscount_pst) / 100;
                ldp = ldp.toFixed(9);

                var var_linediscount_ordval = (ipo * ldp).toFixed(digits);

                ipo = (ipo - var_linediscount_ordval );
                ipo -= (ipo * odp);
    
                vm.model.edit.input_price_net_ordval = ipo;
                vm.model.edit.input_amount_ordval = ipo * utilityService.parseNumber(vm.model.edit.input_quantity);
                vm.model.edit.input_amount_locval = utilityService.parseNumber(vm.model.edit.input_amount_ordval) * utilityService.parseNumber(vm.model.edit.ordval_to_locval_factor);
                vm.model.edit.estimated_cost_amount_locval = utilityService.parseNumber(vm.model.edit.estimated_cost_locval) * utilityService.parseNumber(vm.model.edit.input_quantity);
                vm.model.edit.estimated_margin_locval = utilityService.parseNumber(vm.model.edit.input_amount_locval) - utilityService.parseNumber(vm.model.edit.estimated_cost_amount_locval);
    
                if (vm.model.edit.input_amount_locval !== 0) {
                    vm.model.edit.estimated_margin_pst = utilityService.parseNumber(vm.model.edit.estimated_margin_locval * 100) / utilityService.parseNumber(vm.model.edit.input_amount_locval);
                } else {
                    vm.model.edit.estimated_margin_pst = '0.00';
                }
            };
            
            vm.calcInputAmountOrdvalInclTax = function () {
                var ipo = utilityService.parseNumber(vm.model.edit.input_price_ordval_incl_tax);
                var ldp = utilityService.parseNumber(vm.model.edit.linediscount_pst) / 100;
                var odp = utilityService.parseNumber(vm.model.edit.orderdiscount_pst) / 100;
    
                ipo -= ipo * ldp;
                ipo -= ipo * odp;
    
                vm.model.edit.input_price_net_ordval_incl_tax = ipo.toFixed(digits);
                vm.model.edit.input_amount_ordval_incl_tax = (ipo * utilityService.parseNumber(vm.model.edit.input_quantity)).toFixed(digits);
            };
            
            vm.updatePriceExTax = function () {
                let ipoi = utilityService.parseNumber(vm.model.edit.input_price_ordval_incl_tax);
                let tpst = utilityService.parseNumber(vm.model.edit.tax_pst);
                let pstCalc = (tpst / 100);
                let calc = ipoi / (1 + pstCalc);

                //vm.model.edit.input_price_ordval = (Math.round(calc * 100) / 100).toFixed(digits);
                //vm.model.edit.input_price_ordval = calc.toFixed(digits);
                vm.model.edit.input_price_ordval = utilityService.toFixed(calc, digits);
            };
    
            let recalcPriceInclTax = function () {
                let ipo = utilityService.parseNumber(vm.model.edit.input_price_ordval);
                let pst = utilityService.parseNumber(vm.model.edit.tax_pst);
                let pstCalc = (pst / 100);
                let calc = ipo * (1 + pstCalc);

                vm.model.edit.input_price_ordval_incl_tax = utilityService.toFixed(calc, 4);
            };

            var recalcLineDiscountPercentageExclusiveTax = function () {
                var var_linediscount_ordval = utilityService.parseNumber(vm.model.edit.linediscount_ordval).toFixed(digits);
                var var_input_price_ordval = utilityService.parseNumber(vm.model.edit.input_price_ordval).toFixed(digits);
                var var_linediscount_pst = utilityService.parseNumber(var_linediscount_ordval / var_input_price_ordval) * 100;
                var var_linediscount_pst = utilityService.parseNumber(var_linediscount_pst).toFixed(9);

                if (vm.model.edit.show_linediscount_ordval == '1') {
                    vm.model.edit.linediscount_pst = utilityService.parseNumber(var_linediscount_pst);
                };
            };

            var recalcLineDiscountPercentageInclusiveTax = function () {
                if (vm.model.edit.show_linediscount_ordval == '1') {
                    let calculatedNumber = (utilityService.parseNumber(vm.model.edit.linediscount_ordval_incl_tax) / utilityService.parseNumber(vm.model.edit.input_price_ordval_incl_tax)) * 100;

                    vm.model.edit.linediscount_pst = utilityService.parseNumber(calculatedNumber);
                };
            };

            var recalcLineDiscountOrdValExclusiveTax = function () {
                if (vm.model.bgOrderLinePriceType.selected === 'EX') {
                    var var_input_price_ordval = utilityService.parseNumber(vm.model.edit.input_price_ordval).toFixed(digits);
                    var var_linediscount_pst = utilityService.parseNumber(vm.model.edit.linediscount_pst).toFixed(9);
                    var var_linediscount_ordval = (utilityService.parseNumber(var_input_price_ordval) * utilityService.parseNumber(var_linediscount_pst) / 100).toFixed(digits);
                    
                    vm.model.edit.linediscount_ordval = utilityService.parseNumber(var_linediscount_ordval);
                }
            };

            var recalcLineDiscountOrdValInclusiveTax = function () {
                if (vm.model.bgOrderLinePriceType.selected === 'INCL') {
                    let calculatedNumber = (utilityService.parseNumber(vm.model.edit.input_price_ordval_incl_tax) * (utilityService.parseNumber(vm.model.edit.linediscount_pst) / 100)).toFixed(digits);

                    vm.model.edit.linediscount_ordval_incl_tax = utilityService.parseNumber(calculatedNumber);
                }
            };

		    // #endregion RECALCULATION FUNCTIONS

            //###########################################
            //## BUTTON FUNCTION
            //###########################################

            vm.cancelValues = function () {
                vm.model.lockedCancelRest = true;

                orderLineService.cancelItem({ orderline_keyno: vm.model.edit.orderline_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCancelRest = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCancelRest = false;
                        loadEdit();
                    }
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveAndNewChanges = function () {
                vm.model.lockedSaveAndNew = true;

                orderLineService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveAndNew = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSaveAndNew = false;
                        loadEdit();
                        window.scrollTo(0, 0);
                    }
                });
            };

            vm.saveAndBackChanges = function () {
                vm.model.lockedSaveAndBack = true;

                orderLineService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSaveAndBack = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSaveAndBack = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    orderLineService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'linediscount_pst':
                        if (utilityService.validateParmsValue(vm.model.edit.linediscount_pst, true) !== true) return;

                        if (vm.model.bgOrderLinePriceType.selected === 'EX') {
                            recalcLineDiscountOrdValExclusiveTax();
                        } else {
                            recalcLineDiscountOrdValInclusiveTax();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            let inputQuantityTimer = null;

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'input_unit_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.input_unit_no) !== true) return;

                        vm.model.edit.input_unit_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;

                        retrieveExampleQty();
                        updatePrice();
                        break;
                    case 'input_quantity':
                        if (utilityService.validateWatchValue(value, vm.model.edit.input_quantity) !== true) return;

                        vm.model.edit.input_quantity = value;

                        if (utilityService.validateParmsValue(vm.model.edit.input_quantity, true) !== true) return;
                        if (inputQuantityTimer) clearTimeout(inputQuantityTimer);

                        inputQuantityTimer = setTimeout(() => {
                            retrieveExampleQty();
                            vm.calcInputAmountOrdval();
                            vm.calcInputAmountOrdvalInclTax();
                            updatePrice();
                        }, 1000);
                        break;
                    case 'tax_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.tax_keyno) !== true) return;

                        vm.model.edit.tax_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.tax_keyno) !== true) return;

                        retrieveTax();
                        break;
                    case 'position_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.position_keyno) !== true) return;

                        vm.model.edit.position_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.position_keyno) !== true) return;

                        rememberFunc('position_keyno');
                        break;
                    case 'orderdiscount_pst':
                        if (utilityService.validateWatchValue(value, vm.model.edit.orderdiscount_pst) !== true) return;

                        vm.model.edit.orderdiscount_pst = value;

                        if (utilityService.validateParmsValue(vm.model.edit.orderdiscount_pst, true) !== true) return;

                        vm.calcInputAmountOrdval();
                        vm.calcInputAmountOrdvalInclTax();
                        break;
                    case 'linediscount_pst':
                        if (utilityService.validateWatchValue(value, vm.model.edit.linediscount_pst) !== true) return;

                        vm.model.edit.linediscount_pst = value;

                        if (utilityService.validateParmsValue(vm.model.edit.linediscount_pst, true) !== true) return;

                        vm.calcInputAmountOrdval();
                        vm.calcInputAmountOrdvalInclTax();
                        break;
                    case 'linediscount_ordval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.linediscount_ordval) !== true) return;

                        vm.model.edit.linediscount_ordval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.linediscount_ordval, true) !== true) return;

                        recalcLineDiscountPercentageExclusiveTax();
                        vm.model.edit.linediscount_ordval_incl_tax = (vm.model.edit.linediscount_ordval * (1 + (utilityService.parseNumber(vm.model.edit.tax_pst) / 100))).toFixed(digits);
                        break;
                    case 'input_price_ordval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.input_price_ordval) !== true) return;

                        vm.model.edit.input_price_ordval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.input_price_ordval, true) !== true) return;

                        vm.calcInputAmountOrdval();
                        recalcPriceInclTax();
                        recalcLineDiscountPercentageExclusiveTax();
                        recalcLineDiscountOrdValExclusiveTax();
                        break;
                    case 'linediscount_ordval_incl_tax':
                        if (utilityService.validateWatchValue(value, vm.model.edit.linediscount_ordval_incl_tax) !== true) return;

                        vm.model.edit.linediscount_ordval_incl_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.linediscount_ordval_incl_tax, true) !== true) return;

                        recalcLineDiscountPercentageInclusiveTax();
                        vm.model.edit.linediscount_ordval = (utilityService.parseNumber(vm.model.edit.linediscount_ordval_incl_tax) / (1 + (utilityService.parseNumber(vm.model.edit.tax_pst) / 100))).toFixed(digits);
                        break;
                    case 'input_price_ordval_incl_tax':
                        if (utilityService.validateWatchValue(value, vm.model.edit.input_price_ordval_incl_tax) !== true) return;

                        vm.model.edit.input_price_ordval_incl_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.input_price_ordval_incl_tax, true) !== true) return;

                        vm.calcInputAmountOrdvalInclTax();
                        vm.updatePriceExTax();
                        recalcLineDiscountPercentageInclusiveTax();
                        recalcLineDiscountOrdValInclusiveTax();
                        break;
                    case 'prodsize_keyno':
                    case 'prodchoice_keyno':
                    case 'pricelist_id':
                    case 'pricelist_id2':
                    case 'cost_pricelist_id':
                    case 'cost_pricelist_id2':
                        if (inputQuantityTimer) clearTimeout(inputQuantityTimer);
                        
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;
                        
                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;
                        
                        updatePrice();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPrices();
                loadTaxes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
