(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("purchHeadsService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 681,
                    parameters: parms || {}
                });
            },
            listPurchaseStatuses: function (parms) {
                return $ihttp.post({
                    method: 682,
                    parameters: parms || {}
                });
            },
            listPurchaseHeads: function (parms) {
                return $ihttp.post({
                    method: 683,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
