(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("brRegService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadBrRegGet: function () {
				return p2DataTaskService.call(1304, {});
			},
			loadNaceSelectList: function () {
				return p2DataTaskService.call(1312, {});
			},
			loadCountyNoList: function () {
				return p2DataTaskService.call(1307, {});
			},
			loadCommunitySelectList: function (county_id) {
				return p2DataTaskService.call(1308, {
					county_id: county_id
				});
			},
			loadBrRegUrlGet: function (bronnoysundRegister) {
				return p2DataTaskService.call(1309, bronnoysundRegister);
			},
			loadBrRegCompaniesList: function (response) {
				return p2DataTaskService.call(1305, {
					response: response
				});
			}
		};

		return service;
	}]);
})();