(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('postPlace', {
        templateUrl: 'views/components/views/postPlace/postPlace.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'postPlaceService', 'logisticService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, postPlaceService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let postPlaceId = $stateParams.postplace_id;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                postPlaceId: postPlaceId,
                edit: {},
                selectListCommunities: [],
                selectListCountries: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();
                
                postPlaceService.getPostPlace({ postplace_id: postPlaceId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadCommunities()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCountries = function () {
                logisticService.listCountries().then(function (result) {
                    angular.copy(result, vm.model.selectListCountries);
                });
            };

            let loadCommunities = function () {
                if (utilityService.validateParmsValue(vm.model.edit.country_id) !== true) return;

                return postPlaceService.listCommunities({ country_id: vm.model.edit.country_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListCommunities);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'country_id':
                        vm.model.edit.country_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.country_id) !== true) return;

                        loadCommunities();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                postPlaceService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCountries();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
