(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListLds', {
        templateUrl: 'views/components/views/srCheckListLds/srCheckListLds.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'srCheckListLdsService', function($stateParams, stateService, srCheckListLdsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                menuLossDamages: {},
                itemsListLossDamages: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListLdsService.loadSrCheckListLdsGet(vm.model.checkListDataKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.menuLossDamages);
            });
    
            srCheckListLdsService.loadSrCheckListLdsList(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListLossDamages);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srchecklistld':
                        go({
                            checklistdata_keyno: vm.model.menuLossDamages.checklistdata_keyno,
                            srrefundinput_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();