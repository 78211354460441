(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srRefundCreate', {
        templateUrl: 'views/components/views/srRefundCreate/srRefundCreate.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'rememberService', 'consignmentPlansService', 'srRefundBasisService', 'srRefundCreateService', 'watcherFactory', function($stateParams, stateService, utilityService, modalService, rememberService, consignmentPlansService, srRefundBasisService, srRefundCreateService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            var resizing = false;
    
            vm.model = {
                custactNo: $stateParams.custact_no,
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                mconfig: {
                    enabled: true,		// Is the menu button enabled
                    show: false,		// Is the menu visible or not
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfig.records[0].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.resizeRepayments();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else {
                            vm.model.mconfig.records[0].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.resizeRepayments();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '1', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                createRepayment: {},
                selectListResources: [],
                itemsListRepayments: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srRefundCreateService.loadSrRefundCreateGet(vm.model.custactNo, vm.model.checkListDataKeyNo).then(function (data) {
                vm.model.createRepayment = angular.copy(data[0]);
    
                loadSrRefundCreateCustomer();
                loadSrRefundCreateItems();
            });
    
            srRefundCreateService.loadSrRefundCreateResourceList(vm.model.custactNo, vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.selectListResources);
            });
    
            var loadSrRefundCreateCustomer = function () {
                if (utilityService.validateParmsValue(vm.model.createRepayment.special_resource_id_from) !== true) return;
                if (utilityService.validateParmsValue(vm.model.createRepayment.custact_no) !== true) return;
                
                srRefundCreateService.loadSrRefundCreateCustomerGet(vm.model.createRepayment.special_resource_id_from, vm.model.createRepayment.custact_no).then(function (data) {
                    vm.model.createRepayment.org_no = data[0].org_no;
                    vm.model.createRepayment.cust_no = data[0].cust_no;
                    vm.model.createRepayment.cust_name = data[0].cust_name;
                    vm.model.createRepayment.cust_adr_street = data[0].cust_adr_street;
                    vm.model.createRepayment.bankaccount = data[0].bankaccount;
                    vm.model.createRepayment.send_to_resource_id = data[0].send_to_resource_id;
                    vm.model.createRepayment.send_to_resource_name = data[0].send_to_resource_name;
                });
            };
    
            var loadSrRefundCreateItems = function () {
                if (utilityService.validateParmsValue(vm.model.createRepayment.special_resource_id_from, true) !== true) return;
                
                vm.model.itemsListRepayments = [];
    
                srRefundCreateService.loadSrRefundCreateItemList(vm.model.createRepayment).then(function (data) {
                    angular.copy(data, vm.model.itemsListRepayments);
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'brregview':
                        go({
                            orgno: vm.model.createRepayment.org_no,
                            resource_id: vm.model.createRepayment.special_resource_id_from
                        });
                        break;
                    case 'srcustomeredit':
                        go({
                            cust_no: vm.model.createRepayment.cust_no
                        });
                        break;
                }
            };
    
            // ## RESIZE COLUMN WIDTH FUNCTION PROCEDURE CALLS ##
    
            vm.resizeRepayments = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(vm.model.itemsListRepayments[0]) && angular.isDefined(vm.model.itemsListRepayments[0].item_secondary)) {
                    for (var r = 0; r < vm.model.itemsListRepayments[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.itemsListRepayments[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.itemsListRepayments[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.itemsListRepayments[0].item_secondary[r].item_col_width })
                        }
                    }
                }
    
                var updateParms = {
                    view_id: 'w_srrefundbasis',
                    records: updateList
                };
    
                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
    
                    resizing = false;
                });
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveRepaymentsCreated = function () {
                vm.model.lockedSave = true;
    
                srRefundCreateService.createSrRefund(vm.model.createRepayment).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back('2');
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.createRepayment.special_resource_id_from; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrRefundCreateCustomer();
                loadSrRefundCreateItems();
            });
        }]
    });
})();