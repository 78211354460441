(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('biReports', {
        templateUrl: 'views/components/views/biReports/biReports.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['biReportsService', function (biReportsService) {
            var vm = this;

            vm.model = {
                biReportList: []
            }

            vm.$onInit = () => {
                biReportsService.list().then(function (data) {
                    angular.copy(data, vm.model.biReportList);
                });
            }
        }]
    });
})();