(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('multiDoc', {
        templateUrl: 'views/components/views/multiDoc/multiDoc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'multiDocService', function($stateParams, multiDocService) {
            var vm = this;
            
            vm.model = {
                p2WMultiDocList: {
                    records: []
                }
            };
            
            multiDocService.loadP2WMultiDocList($stateParams.doctype, $stateParams.argtype, $stateParams.argvalue).then(function () {
                vm.model.p2WMultiDocList = multiDocService.p2WMultiDocList;
            });
        }]
    });
})();