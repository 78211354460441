(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryTerms', {
        templateUrl: 'views/components/views/deliveryTerms/deliveryTerms.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'deliveryTermsService', function (stateService, deliveryTermsService) {
            let vm = this;
            
            vm.model = {
                itemsListTerms: []
            };

            let loadDeliveryTerms = function () {
                deliveryTermsService.listDeliveryTerms().then(function (result) {
                    angular.copy(result, vm.model.itemsListTerms);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'deliveryterm':
                        go({ deliveryterm_no: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadDeliveryTerms();
            };
        }]
    });
})();
