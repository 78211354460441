(function () {
    'use strict';

    let module = angular.module('imApp');

    module.factory("modalPopupTableFactory", ['$q', '$uibModal', function ($q, $uibModal) {
        function modalPopupTable(options) {
            options = options || {};

            var testlist = [
                {
                    aaa: '11a',
                    bbb: '11b',
                    ccc: '11c',
                }, {
                    aaa: '22a',
                    bbb: '22b',
                    ccc: '22c',
                }, {
                    aaa: '33a',
                    bbb: '33b',
                    ccc: '33c',
                },
            ];

            this.model = {
                //datatask_keyno: options.datatask_keyno || '',
                //placeholder: options.placeholder || '',
                //placeholderError: options.placeholderError || '',
                //placeholderNoLayout: options.placeholderNoLayout || '',
                //currentLayout: options.currentLayout || {},
                //activeLayout: options.activeLayout || null,
                list: options.list || []
                //list: testlist || [{}]
            };

            this.instance = null;
        };

        modalPopupTable.prototype.show = function () {
            var deferred = $q.defer();

            var vm = this;

            this.instance = $uibModal.open({
                backdrop: true,
                animation: true,
                keyboard: false,
                templateUrl: 'views/modal/popupTableModal.html?v=' + module.version,
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.model = vm.model;
                    $scope.model.activeIndex = null;
                    $scope.model.selectedItem = null;
                    //$scope.model.field = '';
                    //$scope.model.canAdd = false;

                    //var unactivate = function () {
                    //    for (var i = 0; i < $scope.model.list.length; i++) {
                    //        if ($scope.model.list[i].isActive) {
                    //            $scope.model.list[i].isActive = false;
                    //            break;
                    //        }
                    //    }
                    //};

                    $scope.close = function () {
                        $uibModalInstance.close();

                        deferred.resolve(vm.model);
                    };

                    $scope.activate = function (e, index) {
                        if ($scope.model.activeIndex === index) {
                            $scope.model.selectedItem = $scope.model.list[index];
                            $scope.close();
                        } else {
                            $scope.model.activeIndex = index;
                        }


                        //$scope.close();
                    };

                    //$scope.setActive = function (e, item) {
                    //    unactivate();
                    //    item.isActive = true;
                    //    $scope.model.activeLayout = item;

                    //    $scope.close();
                    //};

                    //$scope.updateActive = function (e, item, index) {
                    //    e.stopPropagation();

                    //    item.gridlayout_name = $scope.model.field.length > 0 ? $scope.model.field : item.gridlayout_name;
                    //    item.layout_schema = $scope.model.currentLayout;
                    //    item.hasUpdates = true;

                    //    $scope.model.hasCurrentLayout = true;
                    //    $scope.model.hasCurrentLayoutIndex = index;
                    //    $scope.model.field = '';

                    //    $scope.close();
                    //};

                    $scope.$on('modal.closing', function (event, reason) {
                        switch (reason) {
                            case "backdrop click":
                            case "escape key press":
                            case "cancel":
                                event.preventDefault();
                                $scope.close();
                                break;
                        }
                    });
                }]
            });

            return deferred.promise;
        };

        modalPopupTable.prototype.hide = function () {
            if (angular.isUndefined(this.instance)) return;
            if (this.instance === null) return;

            this.instance.close();

            this.instance = null;
        };

        modalPopupTable.$create = function (options) {
            return new modalPopupTable(options);
        };

        return modalPopupTable;
    }]);
})();
