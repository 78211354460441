(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businesscoSettings', {
        templateUrl: 'views/components/views/businesscoSettings/businesscoSettings.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'businesscoSettingsService', function ($stateParams, businesscoSettingsService) {
            const vm = this;
            let businessCoNo = $stateParams.businessco_no;

            vm.model = {
                itemsListGroups: []
            };

            let loadSettingGroups = function () {
                businesscoSettingsService.listSettingGroups({ businessco_no: businessCoNo }).then(function (list) {
                    angular.copy(list, vm.model.itemsListGroups);
                });
            };

            vm.$onInit = function () {
                loadSettingGroups();
            };
		}]
	});
})();
