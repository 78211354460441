(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productCategory', {
        templateUrl: 'views/components/views/productCategory/productCategory.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'productCategoryService', 'translateService', function ($stateParams, stateService, modalService, productCategoryService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var productCategoryKeyno = $stateParams.productcategory_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'productcategorylang':
                        stateService.go(state, {
                            productcategory_keyno: vm.model.edit.productcategory_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                productCategoryLangNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'productcategorylang' } }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            productCategoryService.getProductCategory({ productcategory_keyno: productCategoryKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productCategoryService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

        }]
    });
})();
