(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('poh', {
        templateUrl: 'views/components/views/poh/poh.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$uibModal', '$stateParams', 'stateService', 'pohService', 'utilityService', 'translateService', 'ieScreenBlockService', 'modalService', function($uibModal, $stateParams, stateService, pohService, utilityService, translateService, ieScreenBlockService, modalService) {

            var vm = this;



            // #region VARIABLES & DEFINITIONS

            var words = {
                ttConfirmDeletePohMessage: '',
                tt_warning_header: ''
            };

            vm.gridReady = false;

            vm.model = {
                pohKeyno: $stateParams.poh_keyno,
                bgPohMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_poh_main_components', item_func: 'COMPONENTS' },
                        { item_id: '1', item_name: 'gb_poh_main_pos', item_func: 'PO' },
                        { item_id: '2', item_name: 'gb_poh_main_functions', item_func: 'FUNCTIONS' }
                    ]
                },
                productionOrder: {},
                itemsListComponents: [],
                itemsListPos: [],
                deleteLocked: false,
                changeStatusText: '',
                changeStatusDisabled: true
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_poh.poh_keyno',                      // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2320,
                        parameters: { poh_keyno: vm.model.pohKeyno }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } },
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            vm.goTo = function (state) {
                var name = stateService.getCurrentName();

                var parms = {
                    argtype: 'poh_keyno',
                    argvalue: vm.model.pohKeyno
                };

                switch (state) {
                    case 'emailcreate':
                        parms.webpage = name;
                        break;
                    case 'selectreport':
                        parms.webpage_name = name;
                        break;
                };

                stateService.go(state, parms);
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) { };

            // #endregion OPTIONFUNC

            // ## LOAD PROCEDURE CALLS ##

            pohService.getStatusBtnState(vm.model.pohKeyno).then(function (response) {
                console.log('current.response');
                console.dir(response);

                vm.model.changeStatusText = response.text;
                vm.model.changeStatusDisabled = response.disabled;
            });

            translateService.translateBatch(words).then(function (response) {
                angular.forEach(response, function (value, key) {
                    words[key] = value;
                });
            });

            pohService.getPoh(vm.model.pohKeyno).then(function (data) {
                angular.copy(data[0], vm.model.productionOrder);

                if (angular.isDefined(vm.model.productionOrder.poh_keyno)) {
                    vm.grid.dataTask.loadData.parameters = { poh_keyno: vm.model.productionOrder.poh_keyno };
                    vm.model.gridReady = true;
                }

                loadComponents();
                loadPos();
                console.dir(vm.model.productionOrder);
            });

            var loadComponents = function () {
                if (utilityService.validateParmsValue(vm.model.productionOrder.poh_keyno) !== true) return;

                vm.model.itemsListComponents = [];

                pohService.listPohComps(vm.model.productionOrder.poh_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListComponents);
                });
            };

            var loadPos = function () {
                if (utilityService.validateParmsValue(vm.model.productionOrder.poh_keyno) !== true) return;

                vm.model.itemsListPos = [];

                pohService.listPohPos(vm.model.productionOrder.poh_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListPos);
                });
            };

            // ## BUTTON GROUP FUNCTIONS ##

            vm.changeStatus = function () {
                pohService.updatePoStatus(vm.model.pohKeyno).then(function (response) {
                    vm.model.changeStatusText = response.text;
                    vm.model.changeStatusDisabled = response.disabled;

                    if (vm.model.errorcode !== null) {
                        modalService.show({
                            type: 'warning',
                            title: words.tt_warning_header,
                            message: response.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogInstance) {
                                    dialogInstance.close();
                                }
                            }]
                        });
                    }
                });
            };

            vm.selectBgPohMain = function (item) {
                vm.model.bgPohMain.item_selected = item;
            };

            // #region BUTTON FUNCTIONS

            vm.delete = function () {
                vm.model.deleteLocked = true;

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return words.ttConfirmDeletePohMessage;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        deferred.resolve();
                        return;
                    }

                    ieScreenBlockService.start();

                    modalInstance.dismiss();

                    pohService.delete(vm.model.productionOrder.poh_keyno).then(function (response) {
                        ieScreenBlockService.stop();

                        if (response.errorcode === 0) {
                            stateService.go('poshipmentplanning', {});
                        } else {
                            modalService.show({
                                type: 'warning',
                                title: words.tt_warning_header,
                                message: response.errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogInstance) {
                                        dialogInstance.close();
                                    }
                                }]
                            });
                        }

                        vm.model.deleteLocked = false;
                    }, function (error) {
                        console.log('error');
                        console.dir(error);

                        ieScreenBlockService.stop();

                        vm.model.deleteLocked = false;
                    });
                });
            };

            vm.cancelCaseQtyOl = function () {
                pohService.cancelCaseQtyOl(vm.model.productionOrder.poh_keyno).then(function (response) {
                    if (angular.isDefined(response) && angular.isDefined(response.errorcode) && response.errorcode !== 0) {
                        modalService.show({
                            type: 'warning',
                            title: words.tt_warning_header,
                            message: response.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogInstance) {
                                    dialogInstance.close();
                                }
                            }]
                        });
                    }
                });
            };

            vm.cancelCaseQty = function () {
                pohService.cancelCaseQty(vm.model.productionOrder.poh_keyno).then(function (response) {
                    if (angular.isDefined(response) && angular.isDefined(response.errorcode) && response.errorcode !== 0) {
                        modalService.show({
                            type: 'warning',
                            title: words.tt_warning_header,
                            message: response.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogInstance) {
                                    dialogInstance.close();
                                }
                            }]
                        });
                    }
                });
            };

            vm.updatePoReqQty = function () {
                pohService.updatePoReqQty(vm.model.productionOrder.poh_keyno).then(function (response) {
                    if (angular.isDefined(response) && angular.isDefined(response.errorcode) && response.errorcode !== 0) {
                        modalService.show({
                            type: 'warning',
                            title: words.tt_warning_header,
                            message: response.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogInstance) {
                                    dialogInstance.close();
                                }
                            }]
                        });
                    }
                });
            };

            vm.goToPoFinish = function () {
                pohService.createPoFinish(vm.model.productionOrder.poh_keyno).then(function (response) {
                    if (angular.isDefined(response)) {
                        if (angular.isDefined(response.errorcode) && response.errorcode !== 0) {
                            modalService.show({
                                type: 'warning',
                                title: words.tt_warning_header,
                                message: response.errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogInstance) {
                                        dialogInstance.close();
                                    }
                                }]
                            });
                        } else if (angular.isDefined(response[0].p2_pofinishprod_keyno) && response[0].p2_pofinishprod_keyno > 0) {
                            console.log('p2_pofinish_keyno: ' + response[0].p2_pofinish_keyno);
                            console.log('p2_pofinishprod_keyno: ' + response[0].p2_pofinishprod_keyno);
                            console.log('po_keyno: ' + response[0].po_keyno);

                            stateService.go('pofinishprod', {
                                p2_pofinishprod_keyno: Number.parseInt(response[0].p2_pofinishprod_keyno),
                                po_keyno: Number.parseInt(response[0].po_keyno)
                            });
                        }
                    } 
                });
            }

            // #endregion BUTTON FUNCTIONS
        }]
    });
})();
