(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businessLang', {
        templateUrl: 'views/components/views/businessLang/businessLang.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'businessLangService', function ($stateParams, stateService, businessLangService) {
            let vm = this;
            let businessId = $stateParams.business_id;

            vm.model = {
                inputListLanguages: []
            };

            let loadLanguages = function () {
                businessLangService.listBusinessLanguages({ business_id: businessId }).then(function (result) {
                    angular.copy(result, vm.model.inputListLanguages);
                });
            };

            vm.saveChanges = function () {
                businessLangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadLanguages();
            };
		}]
	});
})();
