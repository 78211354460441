(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('serviceObjectNewAct', {
        templateUrl: 'views/components/views/serviceObjectNewAct/serviceObjectNewAct.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'serviceObjectNewActService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, serviceObjectNewActService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let serviceObjectKeyno = $stateParams.serviceobject_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                itemsListNewActs: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                serviceObjectNewActService.getSettings({ serviceobject_keyno: serviceObjectKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadNewActs()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadNewActs = function () {
                if (utilityService.validateParmsValue(vm.model.setting.start_date) !== true) return;
                
                vm.model.itemsListNewActs = [];
                
                return serviceObjectNewActService.listNewActs(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListNewActs);
                });
            };
            
            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            vm.setMarkedAs = function () {
                vm.model.lockedSave = true;
    			let selectedItems = [];
                
    			angular.forEach(vm.model.itemsListNewActs, function (item) {
    				if (item.item_id === '0' || item.item_id === '-1') return;
    				if (item.item_is_selected === '0') return;
    
                    selectedItems.push({
                        serviceobjecttypeint_keyno: item.item_id,
                        nextdate: item.nextdate
                    });
                });
                
                let mergeObj = {
                    serviceobject_keyno: vm.model.setting.serviceobject_keyno,
                    records: selectedItems
                };
                
                serviceObjectNewActService.createObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
    		};

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'start_date':
                        if (utilityService.validateWatchValue(value, vm.model.setting.start_date) !== true) return;

                        vm.model.setting.start_date = value;

                        if (utilityService.validateParmsValue(vm.model.setting.start_date) !== true) return;

                        loadNewActs();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
