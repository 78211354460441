// BJS 20241014 - Flyttet til typescript NotificationsService

//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("p2NotificationsService", ['$q', '$ihttp', function ($q, $ihttp) {
//        var handlers = {};

//        var service = {
//            subscribe: function (id, handler) {
//                handlers[id] = handler;
//            },

//            unsubscribe: function (id) {
//                if (angular.isUndefined(handlers[id])) return;

//                delete handlers[id];
//            },

//            getSettings: function () {
//                var deferred = $q.defer();

//                $ihttp.post({
//                    method: 1471
//                }).then(function (data) {
//                    deferred.resolve(data[0]);
//                });

//                return deferred.promise;
//            },

//            loadUnreadNotifications: function () {
//                var deferred = $q.defer();

//                $ihttp.post({
//                    method: 345
//                }).then(function (data) {
//                    let p2NotificationsListUnread = {
//                        records: []
//                    };

//                    angular.copy(data, p2NotificationsListUnread.records);

//                    angular.forEach(handlers, function (handler) {
//                        handler({
//                            type: 'unread',
//                            value: data.length
//                        })
//                    });

//                    deferred.resolve(p2NotificationsListUnread);
//                });

//                return deferred.promise;
//            },

//            loadReadNotifications: function () {
//                var deferred = $q.defer();

//                $ihttp.post({
//                    method: 339,
//                    parameters: {
//                        statusid: '1'
//                    }
//                }).then(function (data) {
//                    let p2NotificationsListRead = {
//                        records: []
//                    };

//                    angular.copy(data, p2NotificationsListRead.records);

//                    angular.forEach(handlers, function (handler) {
//                        handler({
//                            type: 'read',
//                            value: data.length
//                        })
//                    });

//                    deferred.resolve(p2NotificationsListRead);
//                });

//                return deferred.promise;
//            },

//            setAsRead: function (item) {
//                var deferred = $q.defer();

//                var parmsP2NotificationRead = {
//                    method: 340,
//                    parameters: {
//                        p2_notification_keyno: item.item_id
//                    }
//                };

//                $ihttp.post(parmsP2NotificationRead).then(function () {
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            }
//        };

//        return service;
//    }]);
//})();
