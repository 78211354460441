(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('selectStockLocation', {
        templateUrl: 'views/components/views/selectStockLocation/selectStockLocation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'selectStockLocationService', 'logisticService', 'rememberService', function ($stateParams, $q, stateService, utilityService, selectStockLocationService, logisticService, rememberService) {

    		// #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                stockzone_keyno: ''
            };

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
    			selectListStockZones: [],
                itemsListStockLocations: []
            };
    
    		// #endregion VARIABLES & DEFINITIONS

    		// #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                selectStockLocationService.getSetting({ stockplace_id: $stateParams.stockplace_id }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadStockZones()
                    ]).then(() => {
                        loadStockLocations();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadStockZones = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                return selectStockLocationService.listStockZones({ stockplace_id: vm.model.setting.stockplace_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockZones);
                });
            };
            
            let loadStockLocations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockzone_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                vm.model.itemsListStockLocations = [];
                
                return logisticService.listStockLocation({
                    stockzone_keyno: vm.model.setting.stockzone_keyno,
                    stockplace_id: vm.model.setting.stockplace_id
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockLocations);
                });
            };
            
    		// #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'stockzone_keyno':
                            variableNames[key] = stateService.getCurrentName() + '.' + 'stockzone_keyno' + '/' + vm.model.setting.stockplace_id;
                            break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'stockzone_keyno':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

    		// #region DIRECTION BUTTON FUNCTIONS
            
            vm.selectedStocklocation = function (item) {
                let variableName = 'selected.stocklocation_keyno_' + vm.model.setting.stockplace_id;

                rememberService.remember(variableName, item.item_id).then(function () {
                    stateService.back();
                });
            };
            
    		// #endregion DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockzone_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockzone_keyno) !== true) return;

                        vm.model.setting.stockzone_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockzone_keyno) !== true) return;

                        rememberFunc('stockzone_keyno');
                        loadStockLocations();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
