(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('subscriptionGrid', {
        templateUrl: 'views/components/views/subscriptionGrid/subscriptionGrid.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'subscriptionGridService', 'logisticService', 'rememberService', function ($stateParams, $q, stateService, utilityService, subscriptionGridService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let variableNames = {
                is_active: ''
            };

            vm.model = {
                companyButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('businessco_no') }
                ],
                setting: {},
                selectListCompanies: [],
                selectListIsActive: [],
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_subscriptiongrid.grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'subscriptiongrid_new',
                                text: 'subscriptiongrid_new',
                                func: function () {
                                    logisticService.getNextCounterNumber({ counter_id: 'subscription_keyno' }).then(function (response) {
                                        stateService.go('subscription', { subscription_keyno: response[0].nextnumber });
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

                subscriptionGridService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
            		angular.copy(result[0], vm.model.setting);

            		$q.all([
            			loadGrid()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            let loadIsActive = function () {
                subscriptionGridService.listIsActive().then(function (result) {
                    angular.copy(result, vm.model.selectListIsActive);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3208,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'is_active':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = '99999';

                        if (vm.model.setting.businessco_no === '99999') loadGrid();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        if (vm.model.setting.businessco_no !== '99999') loadGrid();
                        break;
                    case 'is_active':
                        if (utilityService.validateWatchValue(value, vm.model.setting.is_active) !== true) return;

                        vm.model.setting.is_active = value;

                        if (utilityService.validateParmsValue(vm.model.setting.is_active) !== true) return;

                        rememberFunc('is_active');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
                loadCompanies();
                loadIsActive();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
