//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    // BJS 20210525
//    module.factory("layoutService", ['$rootScope', '$ihttp', '$q', 'utilityService', 'userService', 'eventService', function ($rootScope, $ihttp, $q, utilityService, userService, eventService) {
//        var layoutChangedEventHandlers = {};

//        var isOdd = function (x) { return x & 1; };

//        var getEventArgs = function () {
//            var fontSize = userService.fontSize;
//            const fontSizeMobile = userService.fontSizeMobile;

//            var top = userService.labelAlwaysOnTop;

//            if (angular.isUndefined(fontSize) || angular.isUndefined(top)) return null;
            
//            var size = parseInt(fontSize.substr(0, fontSize.length - 2));
            
//            if (window.matchMedia('(min-width: 768px)').matches === false) {
//                size += Number(fontSizeMobile);
//                userService.updateMobileTheme(userService.theme_keyno_mobile);
//            } else {
//                userService.updateTheme(userService.theme_keyno);
//            }
            
//            var padding1 = size - 10;
//            var padding2 = padding1 + 6;

//            var eventArgs = {
//                size: size,
//                labelAlwaysOnTop: top,
//                breadcrumbView: userService.breadcrumbView,
//                showBreadcrumbs: userService.showBreadcrumbs,
//                sidemenuOpened: userService.sidemenuOpened,
//                showSettings: userService.showSettings,
//                windowheadingcolor: userService.windowheadingcolor,
//                fontSizes: {
//                    textSize3XL: (size + 18).toString() + "px",
//                    textSizeXXL: (size + 12).toString() + "px",
//                    textSizeXL: (size + 6).toString() + "px",
//                    textSizeL: (size + 3).toString() + "px",
//                    textSize: (size).toString() + "px",
//                    textSizeS: (size - 2).toString() + "px",
//                    textSizeSs: (size - 3).toString() + "px",
//                    textSizeXs: (size - 8).toString() + "px",
//                    thumbnail: `${size + 24}`,
//                    //thumbnail: '60',
//                },
//                height: size + 16,
//                padding: {
//                    top: padding1 > 5 ? 5 : padding1,
//                    bottom: padding1 > 5 ? 5 : padding1,
//                    left: padding2 > 16 ? 16 : padding2,
//                    right: padding2 > 16 ? 16 : padding2
//                },
//                margin: {
//                    bottom: (isOdd(size) ? size - 1 : size) / 2
//                },
//                mediaQueries: {
//                    tablet: window.matchMedia('(min-width: 768px)'),
//                    laptop: window.matchMedia('(min-width: 992px)'),
//                    desktop: window.matchMedia('(min-width: 1440px)'),
//                },
//                userCanEditLayout: utilityService.toBoolean(userService.userSettings['_240'].value)
//            };
            
//            return eventArgs;
//        };

//        var layoutChanged = function () {
//            userService.ensureIsReady().then(function () {
//                var eventArgs = getEventArgs();

//                if (eventArgs === null)
//                    return;

//                angular.forEach(layoutChangedEventHandlers, function (handler) {
//                    try {
//                        handler.callback(eventArgs);
//                    } catch (error) {
//                        console.dir(handler);
//                        console.dir(error);
//                    }
//                });
//            });
//        };
        
//        var dereg1 = $rootScope.$on('userService:fontSize:changed', layoutChanged);
//        var dereg6 = $rootScope.$on('userService:breadcrumbView:changed', layoutChanged);
//        var dereg7 = $rootScope.$on('userService:showBreadcrumbs:changed', layoutChanged);
//        var dereg2 = $rootScope.$on('userService:labelAlwaysOnTop:changed', layoutChanged);
//        var dereg3 = $rootScope.$on('userService:showSettings:changed', layoutChanged);
//        var dereg4 = $rootScope.$on('userService:windowheadingcolor:changed', layoutChanged);
//        var dereg5 = $rootScope.$on('usergroupSettingEditService:changed', layoutChanged);
//        var dereg8 = $rootScope.$on('userService:sidemenuOpened:changed', layoutChanged);
//        var dereg9 = $rootScope.$on('userService:fontSizeMobile:changed', layoutChanged);
//        //var dereg10 = $rootScope.$on('userService:inputLayout:changed', layoutChanged);

//        $rootScope.$on('$destroy', function () {
//            if (angular.isFunction(dereg1) === true) { dereg1(); }
//            if (angular.isFunction(dereg2) === true) { dereg2(); }
//            if (angular.isFunction(dereg3) === true) { dereg3(); }
//            if (angular.isFunction(dereg4) === true) { dereg4(); }
//            if (angular.isFunction(dereg5) === true) { dereg5(); }
//            if (angular.isFunction(dereg6) === true) { dereg6(); }
//            if (angular.isFunction(dereg7) === true) { dereg7(); }
//            if (angular.isFunction(dereg8) === true) { dereg8(); }
//            if (angular.isFunction(dereg9) === true) { dereg9(); }
//            //if (angular.isFunction(dereg10) === true) { dereg10(); }
//        });

//        var triggerCallback = function (id) {
//            userService.ensureIsReady().then(function () {
//                var eventArgs = getEventArgs();
                
//                if (eventArgs === null)
//                    return;

//                if (angular.isUndefined(layoutChangedEventHandlers[id]))
//                    return;
//                if (angular.isFunction(layoutChangedEventHandlers[id].callback) !== true)
//                    return;
                    
//                layoutChangedEventHandlers[id].callback(eventArgs);
//            });
//        };

//        var preloadedFieldInfo = {};

//        var service = {
//            isReady: function () {
//                // use this method to check if fontsizes and other layout info is loaded.
//                return userService.ensureIsReady();
//            },

//            getMediaQueries: function () {
//                return {
//                    tablet: window.matchMedia('(min-width: 768px)'),
//                    laptop: window.matchMedia('(min-width: 992px)'),
//                    desktop: window.matchMedia('(min-width: 1440px)'),
//                };
//            },

//            toggleItemSizeFieldInfo: function (groupId, fieldId, sizes) {
//                if (angular.isUndefined(groupId))
//                    return;
//                if (angular.isString(groupId) !== true)
//                    return;
//                if (groupId.length < 1)
//                    return;

//                var parts = groupId.split('.');

//                if (parts.length > 1) {
//                    if (angular.isUndefined(sizes)) {
//                        sizes = fieldId;
//                    }

//                    groupId = parts[0];
//                    fieldId = parts[1];
//                }

//                if (angular.isUndefined(fieldId))
//                    return;
//                if (angular.isString(fieldId) !== true)
//                    return;
//                if (fieldId.length < 1)
//                    return;

//                return $ihttp.post({ method: 1912, parameters: { group_id: groupId, field_id: fieldId, sizes: sizes } });
//            },

//            getFieldInfo: function (groupId) {
//                if (angular.isDefined(preloadedFieldInfo[groupId])) {
//                    if (preloadedFieldInfo[groupId].state === 'ready') {
//                        var fields = preloadedFieldInfo[groupId].fields.splice(0);

//                        delete preloadedFieldInfo[groupId];

//                        return $q.resolve(fields);
//                    } else {
//                        var deferred1 = $q.defer();

//                        preloadedFieldInfo[groupId].defers.push(deferred1);

//                        return deferred1.promise;
//                    }
//                } else {
//                    return $ihttp.post({ method: 1877, parameters: { group_id: groupId } });
//                }
//            },

//            preloadFieldInfo: function (groups) {
//                if (angular.isArray(groups) !== true) {
//                    console.log('Invalid groups parameter value in layoutService.getFieldInfoBatch. Must be an array.');
//                }

//                var groupString = '';

//                angular.forEach(groups, function (group) {
//                    groupString += group + ';';

//                    if (angular.isDefined(preloadedFieldInfo[group]))
//                        return;

//                    preloadedFieldInfo[group] = {
//                        state: 'loading',
//                        fields: [],
//                        defers: []
//                    }
//                });

//                $ihttp.post({ method: 1889, parameters: { groups: groupString } }, { context: { groups: groups } }).then(function (response) {
//                    angular.forEach(response.data, function (fields, groupId) {
//                        if (fields.length > 0) {
//                            angular.copy(fields, preloadedFieldInfo[groupId].fields);
//                        }

//                        preloadedFieldInfo[groupId].state = 'ready';

//                        if (preloadedFieldInfo[groupId].defers.length > 0) {
//                            angular.forEach(preloadedFieldInfo[groupId].defers, function (def) {
//                                def.resolve(preloadedFieldInfo[groupId].fields);
//                            });

//                            delete preloadedFieldInfo[groupId];
//                        }
//                    });
//                });
//            },

//            updateFieldInfo: function (fieldInfo) {
//                var deferred = $q.defer();

//                $ihttp.post({ method: 1878, parameters: fieldInfo }).then(function (data) {
//                    eventService.trigger('layoutService:fieldInfo:changed', data);

//                    deferred.resolve(data);
//                });

//                return deferred.promise;
//            },

//            onLayoutChanged: function (scope, callback) {
//                if (angular.isFunction(callback) !== true)
//                    return;

//                var id;

//                // when calling from a component you can send in an array to contain dereg
//                // instead of scope.
//                if (angular.isArray(scope) === true) {
//                    var fn = function () {
//                        var id = fn.$id;

//                        if (angular.isUndefined(layoutChangedEventHandlers[id]))
//                            return;

//                        delete layoutChangedEventHandlers[id];
//                    }

//                    fn.$id = id = uuid();

//                    scope.push(fn);
//                } else {
//                    if (angular.isObject(scope) !== true)
//                        return;
//                    if (angular.isFunction(scope.$on) !== true)
//                        return;

//                    scope.$on('$destroy', function (event) {
//                        if (angular.isUndefined(layoutChangedEventHandlers[event.currentScope.$id]))
//                            return;

//                        delete layoutChangedEventHandlers[event.currentScope.$id];
//                    });

//                    id = scope.$id;
//                }

//                layoutChangedEventHandlers[id] = {
//                    callback: callback
//                };

//                triggerCallback(id);
//            },

//            //getInputStyles: function () {
//            //    return [
//            //        { id: '0', url: 'assets/styles/input-styles/_underline.css' },
//            //        { id: '1', url: 'assets/styles/input-styles/_framed.css' },
//            //    ];
//            //}
//        };

//        return service;
//    }]);
//})();
