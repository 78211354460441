(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentSplitLine', {
        templateUrl: 'views/components/views/consignmentSplitLine/consignmentSplitLine.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'modalService', 'utilityService', 'consignmentSplitLineService', function($stateParams, $timeout, stateService, modalService, utilityService, consignmentSplitLineService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            var timerSumQuantityEntered = null;

            vm.model = {
                consignmentLineKeyNo: $stateParams.consignmentline_keyno,
                splitLine: {},
                inputListSplitLines: [],
                lockedSave: false
            };
            
            // ## LOAD PROCEDURE CALLS ##
            
            consignmentSplitLineService.loadConsignmentSplitLineGet(vm.model.consignmentLineKeyNo).then(function (data) {
                vm.model.splitLine = angular.copy(data[0]);
            });
            
            consignmentSplitLineService.loadConsignmentSplitLineList(vm.model.consignmentLineKeyNo).then(function (data) {
                angular.copy(data, vm.model.inputListSplitLines);
                
                vm.recalcSumQuantityEntered();
            });
            
            // ## CALCULATION FUNCTIONS ##
            
            vm.recalcSumQuantityEntered = function (item) {
                if (angular.isDefined(timerSumQuantityEntered)) {
                    $timeout.cancel(timerSumQuantityEntered);
                }
                
                timerSumQuantityEntered = $timeout(function () {
                    vm.model.splitLine.sum_quantity_entered = 0;
                    
                    angular.forEach(vm.model.inputListSplitLines, function (line) {
                        var num_quantity_topick = utilityService.parseNumber(line.quantity_topick);
                        var num_fixed_n2 = Math.floor(num_quantity_topick * 100) / 100 ;
                        //num_quantity_topick = line.quantity_topick;
                        //num_quantity_topick.toFixed(2);
                        
                        vm.model.splitLine.sum_quantity_entered += num_fixed_n2; //utilityService.parseNumber(line.quantity_topick);
                    });
                    
                    if (utilityService.parseNumber(vm.model.splitLine.quantity_total) !== utilityService.parseNumber(vm.model.splitLine.sum_quantity_entered)) {
                        vm.model.splitLine.show_validation_text = '1';
                    } else {
                        vm.model.splitLine.show_validation_text = '0';
                    }
                }, 500);
            };
            
            // ## SAVE BUTTON FUNCTION ##
            
            vm.saveConsignmentSplitLine = function () {
                vm.model.lockedSave = true;
                
                var mergeObj = {
                    quantity_total: vm.model.splitLine.quantity_total,
                    consignmentline_keyno: vm.model.splitLine.consignmentline_keyno,
                    records: vm.model.inputListSplitLines.map(function (a) { return { "consignmentline_keyno": a.consignmentline_keyno, "quantity_topick": a.quantity_topick }; })
                };
                
                consignmentSplitLineService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();