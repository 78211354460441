(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListEquipments', {
        templateUrl: 'views/components/views/srCheckListEquipments/srCheckListEquipments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'srCheckListEquipmentsService', function($stateParams, stateService, srCheckListEquipmentsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                srRefundTypeId: $stateParams.srrefundtype_id,
                settingCheckList: {},
                itemsListEquipments: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListEquipmentsService.loadSrCheckListEquipmentsGet(vm.model.checkListDataKeyNo, vm.model.srRefundTypeId).then(function (data) {
                angular.copy(data[0], vm.model.settingCheckList);
    
                loadSrCheckListEquipments();
            });
    
            var loadSrCheckListEquipments = function () {
                vm.model.itemsListEquipments = [];
    
                srCheckListEquipmentsService.loadSrCheckListEquipmentsList(vm.model.settingCheckList).then(function (data) {
                    angular.copy(data, vm.model.itemsListEquipments);
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srchecklistequipment':
                        go({
                            checklistdata_keyno: vm.model.settingCheckList.checklistdata_keyno,
                            srrefundtype_id: vm.model.settingCheckList.srrefundtype_id,
                            srrefundinput_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();