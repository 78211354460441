(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("trNewJobConfService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 125,
                    parameters: parms || {}
                });
            },
            listSelectOffDutyCodes: function (parms) {
                return $ihttp.post({
                    method: 2125,
                    parameters: parms || {}
                });
            },
            getOvertime: function (edit) {
                return $ihttp.post({
                    method: 148,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 142,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
