(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("finishPohScanService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_w_finishpohscan_settings: {
                labour_no: '',
                labour_name: '',
                webpagename: '',
                webpage_parameters: '',
                operation_id: '',
                operation_name: '',
                show_setting: '',
                equipment_id: '',
                equipment_name: '',
                show_equipment: '',
                stockplace_id: '',
                stockplace_name: '',
                show_paperless: '',
                show_stockplace: '',
                show_barcode: ''
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2WFinishPohScanSettings: function () {
                var deferred = $q.defer();

                var parms_p2_w_finishpohscan_settings = {
                    method: 194
                };

                $ihttp.post(parms_p2_w_finishpohscan_settings).then(function (data) {
                    service.p2_w_finishpohscan_settings = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            listStockBatchLasts: function (parms) {
                return $ihttp.post({
                    method: 2469,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();