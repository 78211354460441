(function() {
    'use strict';

    angular.module("imApp").factory("ttDatataskSelectorService", ['$q', '$ihttp', function ($q, $ihttp) {
        let datatasks = [];

        let service = {
            getDatataskList: function (reload) {
                reload = reload || false;

                if (datatasks.length > 0 && reload !== true) {
                    return $q.resolve(datatasks);
                }

                let deferred = $q.defer();

                $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'datatasks'
                    }
                }).then(function (response) {
                    angular.copy(response, datatasks);

                    deferred.resolve(datatasks);
                }, function (error) {
                    console.log('error');
                    console.dir(error);

                    deferred.reject(error);
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
