(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('vatReport', {
        templateUrl: 'views/components/views/vatReport/vatReport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'vatReportService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, vatReportService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let businessCoNo = $stateParams.businessco_no;
            let accountingYear = $stateParams.accounting_year;
            let vatReportKeyno = $stateParams.vatreport_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListTaxPeriods: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS
            
            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
            	let deferred = $q.defer();

                vatReportService.getEdit({
                    businessco_no: businessCoNo,
                    accounting_year: accountingYear,
                    vatreport_keyno: vatReportKeyno
                }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);

            		$q.all([
                    	loadTaxPeriods()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            let loadTaxPeriods = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.accounting_year) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.vatreport_keyno) !== true) return;

                return vatReportService.listTaxPeriods({
                    businessco_no: vm.model.edit.businessco_no,
                    accounting_year: vm.model.edit.accounting_year,
                    vatreport_keyno: vm.model.edit.vatreport_keyno
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListTaxPeriods);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                vatReportService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
