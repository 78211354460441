import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { WidgetChartConfig, WidgetDOMRect, WidgetResize } from '../widget/widget.component';
import { ChartData, ChartDataset, ChartType } from 'chart.js';
import { WidgetTextConfig } from '../widget-text/widget-text.component';

@Component({
    selector: 'tt-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, OnChanges, OnDestroy {
    /** List of widgets in the dashboard. */
    @Input() widgets: WidgetConfig[] = [];

    /** Number of columns to display in the dashboard. */
    @Input() numberOfColumns: number = 12;

    /** Number of rows to display in the dashboard. */
    @Input() numberOfRows: number = 12;

    /** The height of the row,  */
    @Input() rowHeight: number = 12;

    /** Whether the dashboard can be edited or not. */
    @Input() editMode: boolean = false;

    /** Event emitted when the dashboard changes any widgets or the widget list itself. */
    @Output() widgetsChange: EventEmitter<WidgetConfig[]> = new EventEmitter();

    constructor(private cdr: ChangeDetectorRef) {}

    trackByFn(index: number, item: WidgetConfig) {
        return item.id; // Use a unique identifier
    }

    /**
     * Gets the row css index for the cell of the given index.
     *
     * @param index the cell index to get the css row index for.
     * @returns the css row index (css grid indexes start at 1).
     */
    public getRowForIndex(index: number) {
        return Math.floor(index / this.numberOfColumns);
    }

    /**
     * Gets the column css index for the cell of the given index.
     *
     * @param index the cell index to the css column index for.
     * @returns the css column index (css grid indexes start at 1).
     */
    public getColumnForIndex(index: number) {
        return index % this.numberOfColumns;
    }

    /**
     * Gets the column template value for the css grid based on dashboard configuration.
     *
     * @returns the column template value for the css grid based on dashboard configuration.
     */
    public getColumnsCSSValue() {
        return 'repeat(' + this.numberOfColumns + ', 1fr)';
    }

    /**
     * Gets the row template value for the vss grid based on dashboard configuration.
     *
     * @returns  the row template value for the css grid based on dashboard configuration.
     */
    public getRowsCSSValue() {
        return 'repeat(' + this.numberOfRows + ', ' + this.rowHeight + 'rem)';
    }

    onWidgetClick(widget: WidgetConfig) {
        if (this.editMode === true) {
            // console.dir(widget);
            widget.canMove = true;
            widget.canResize = true;
        }
    }

    onWidgetBlur(event: PointerEvent, widget: WidgetConfig) {
        // console.log('outsideclick');
        // console.log(widget);
        widget.canMove = false;
        widget.canResize = false;
    }

    /**
     * Resizes the widgets and moves other widgets around to make place.
     *
     * @param event resize widget event given from component.
     * @param widgetId the id of the widget being resized.
     */
    public onWidgetResize(event: WidgetResize, widgetId: string) {
        let widget: WidgetConfig | undefined = this.widgets.find((wid) => wid.id === widgetId);
        let newGridArea = this.newGridArea(event, widget);
        // console.dir(newGridArea);

        if (newGridArea && widget) {
            widget.columnStart = newGridArea.gridColumnStart;
            widget.rowStart = newGridArea.gridRowStart;
            widget.columnSpan = newGridArea.gridColumnEnd;
            widget.rowSpan = newGridArea.gridRowEnd;
            this.rearrangeOtherWidgets(widget);
        }
    }

    /**
     * Moves the widgets and moves every other widgets to make place.
     *
     * @param event widget dom-rect given from component.
     * @param widgetId the id of the widget being moved.
     */
    public onWidgetMove(event: WidgetDOMRect, widgetId: string) {
        document.querySelectorAll('.dashboard__cell').forEach((cell) => ((cell as HTMLDivElement).style.backgroundColor = ''));
        let widget: WidgetConfig | undefined = this.widgets.find((wid) => wid.id === widgetId);
        const newGridArea = this.newGridArea(event, widget);

        if (newGridArea && widget) {
            widget.columnStart = newGridArea.gridColumnStart;
            widget.rowStart = newGridArea.gridRowStart;
            this.rearrangeOtherWidgets(widget);
        }
    }

    private rearrangeOtherWidgets(movedWidget: WidgetConfig) {
        const moveWidgetDown = (widgetToMove: WidgetConfig, spacesToMove: number) => {
            widgetToMove.rowStart! += spacesToMove;

            // After moving this widget, check if it now overlaps with others and move them too
            this.widgets.forEach((otherWidget) => {
                if (otherWidget.id !== widgetToMove.id && this.areWidgetsInSameColumn(widgetToMove, otherWidget) && this.areWidgetsOnSameRow(widgetToMove, otherWidget)) {
                    const overlapAmount = widgetToMove.rowStart! + widgetToMove.rowSpan - otherWidget.rowStart!;
                    if (overlapAmount > 0) {
                        moveWidgetDown(otherWidget, overlapAmount);
                    }
                }
            });
        };

        this.widgets.forEach((widget) => {
            if (widget.id !== movedWidget.id) {
                // Check if the current widget overlaps with the moved widget and move it down if necessary
                if (this.areWidgetsInSameColumn(widget, movedWidget) && this.areWidgetsOnSameRow(widget, movedWidget)) {
                    const overlapAmount = movedWidget.rowStart! + movedWidget.rowSpan - widget.rowStart!;
                    if (overlapAmount > 0) {
                        moveWidgetDown(widget, overlapAmount);
                    }
                }
            }
        });

        this.compactWidgetsVertically();
        this.widgetsChange.emit(this.widgets);
    }

    private compactWidgetsVertically() {
        this.widgets.sort((a, b) => a.rowStart! - b.rowStart!);

        this.widgets.forEach((widget, index) => {
            if (index === 0 || !widget.rowStart) return;

            let newRowStart = 1;

            for (let i = 0; i < index; i++) {
                const aboveWidget = this.widgets[i];
                if (!aboveWidget.rowStart) return;

                if (this.areWidgetsInSameColumn(widget, aboveWidget)) {
                    const aboveWidgetBottomRow = aboveWidget.rowStart + aboveWidget.rowSpan - 1;

                    if (aboveWidgetBottomRow + 1 > newRowStart) {
                        newRowStart = aboveWidgetBottomRow + 1;
                    }
                }
            }

            if (newRowStart < widget.rowStart) {
                widget.rowStart = newRowStart;
            }
        });
    }

    private areWidgetsOnSameRow(widgetA: WidgetConfig, widgetB: WidgetConfig) {
        if (!widgetA.rowStart || !widgetA.columnStart || !widgetB.rowStart || !widgetB.columnStart) return false; // indicates automatic position with no preferrence.

        let widgetEndA = widgetA.rowStart + widgetA.rowSpan;
        let widgetEndB = widgetB.rowStart + widgetB.rowSpan;

        if (this.rangeOverlap(widgetA.rowStart, widgetEndA, widgetB.rowStart, widgetEndB)) {
            return true;
        }

        return false;
    }

    private areWidgetsInSameColumn(widgetA: WidgetConfig, widgetB: WidgetConfig) {
        if (!widgetA.rowStart || !widgetA.columnStart || !widgetB.rowStart || !widgetB.columnStart) return false;

        let widgetEndA = widgetA.columnStart + widgetA.columnSpan;
        let widgetEndB = widgetB.columnStart + widgetB.columnSpan;

        if (this.rangeOverlap(widgetA.columnStart, widgetEndA, widgetB.columnStart, widgetEndB)) {
            return true;
        }

        return false;
    }

    private rangeOverlap(aStart: number, aEnd: number, bStart: number, bEnd: number, softOverlap: boolean = true) {
        if (softOverlap) {
            return aStart < bEnd && bStart < aEnd;
        }
        return aStart <= bEnd && bStart <= aEnd;
    }

    /**
     * Gets the new grid area values based on the given widget dom-rect values.
     *
     * @param event dom-rect values of the widget to find new grid area for.
     * @returns an object containing grid-area values or null if a fitting grid-area could not be found.
     */
    private newGridArea(event: WidgetDOMRect, widget?: WidgetConfig): { gridColumnStart: number; gridRowStart: number; gridColumnEnd: number; gridRowEnd: number } | null {
        let northWestCell = this.getClosestCell(event.x, event.y, 16);
        let southEastCell = this.getClosestCell(event.right, event.bottom, -16);
        let northWestCellIndexes;
        let southEastCellIndexes;

        if (northWestCell) {
            // console.dir('font northwest cells');
            northWestCellIndexes = this.getCellIndexes(northWestCell as HTMLDivElement);

            const dashboardCellOffsetX = event.left - northWestCell.getBoundingClientRect().left;
            const dashboardCellOffsetY = event.top - northWestCell.getBoundingClientRect().top;

            if (dashboardCellOffsetX > 0 && dashboardCellOffsetX > northWestCell.getBoundingClientRect().width / 2) {
                northWestCellIndexes.col += 1;
            }

            if (dashboardCellOffsetY > 0 && dashboardCellOffsetY > northWestCell.getBoundingClientRect().height / 2) {
                northWestCellIndexes.row += 1;
            }
        } else if (widget?.rowStart && widget.columnStart) {
            // console.dir('did not find northwest cells');
            northWestCellIndexes = {
                row: widget?.rowStart,
                col: widget?.columnStart,
            };
        }

        if (southEastCell && northWestCellIndexes) {
            southEastCellIndexes = this.getCellIndexes(southEastCell as HTMLDivElement);
            // console.log('southEastCellIndexes.row :>> ', southEastCellIndexes.row);
            // console.log('southEastCellIndexes.row - (northWestCellIndexes.row - 1) :>> ', southEastCellIndexes.row - (northWestCellIndexes.row - 1));
            // southEastCellIndexes.col = southEastCellIndexes.col - (northWestCellIndexes.col - 1);
            // southEastCellIndexes.row = southEastCellIndexes.row - (northWestCellIndexes.row - 1);
            southEastCellIndexes.col = southEastCellIndexes.col;
            southEastCellIndexes.row = southEastCellIndexes.row;
            if (southEastCellIndexes.col > this.numberOfColumns || southEastCellIndexes.row > this.numberOfRows) return null;
        } else if (widget?.rowStart && widget.columnStart && northWestCellIndexes) {
            // console.log('widget.rowSpan :>> ', widget.rowSpan);
            // console.log('widget.columnSpan :>> ', widget.columnSpan);
            // console.log('widget.rowStart :>> ', widget.rowStart);
            // console.log('widget.columnStart :>> ', widget.columnStart);
            // console.log('northWestCellIndexes.row :>> ', northWestCellIndexes.row);

            southEastCellIndexes = {
                row: widget.rowSpan + northWestCellIndexes.row - 2,
                col: widget.columnSpan,
            };
        }

        if (northWestCellIndexes && southEastCellIndexes) {
            return {
                gridColumnStart: northWestCellIndexes.col,
                gridRowStart: northWestCellIndexes.row,
                gridColumnEnd: southEastCellIndexes.col - (northWestCellIndexes.col - 1),
                gridRowEnd: southEastCellIndexes.row - (northWestCellIndexes.row - 1),
            };
        }

        // console.log('missing new grid area info');
        return null;
    }

    /**
     * Gets the nearest dashboard cell of the given x and y coordinates using the given margin.
     * Returns the HTMLDivElement of the dashboard cell if found, null if no dashboard cell was found.
     *
     * @param x the x coordinate to find the nearest dashboard cell with.
     * @param y the y coordinate to find the nearest dashboard cell with.
     * @param margin the margin to use if x and y coordinates does not provide a cell.
     * @returns HTMLDivElement of the dashboard cell if found, null if no dashboard cell was found.
     */
    private getClosestCell(x: number, y: number, margin: number): HTMLDivElement | null {
        let cells = document.elementsFromPoint(x, y).filter((element) => element.classList.contains('dashboard__cell'));

        if (cells.length === 0) {
            cells = document.elementsFromPoint(margin + x, y).filter((element) => element.classList.contains('dashboard__cell'));
        }

        if (cells.length === 0) {
            cells = document.elementsFromPoint(x, margin + y).filter((element) => element.classList.contains('dashboard__cell'));
        }

        if (cells.length === 0) {
            cells = document.elementsFromPoint(margin + x, margin + y).filter((element) => element.classList.contains('dashboard__cell'));
        }

        if (cells.length > 0) return cells[0] as HTMLDivElement;

        return null;
    }

    /**
     * Gets the cell indexes of the given HTMLDivElement dashboard cell.
     *
     * @param dashboardCell the dashboard cell to find the css grid indexes of.
     * @returns cell indexes of the given HTMLDivElement dashboard cell.
     */
    private getCellIndexes(dashboardCell: HTMLDivElement) {
        const col = Number(dashboardCell.dataset?.['col'] ?? 0) + 1;
        const row = Number(dashboardCell.dataset?.['row'] ?? 0) + 1;

        return {
            col: col,
            row: row,
        };
    }

    ngOnInit(): void {}
    ready = true;
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['widgets']?.currentValue) {
            // console.log('changes in widgets');
            this.widgets = changes?.['widgets']?.currentValue.map((widget: WidgetConfig) => {
                return {
                    canMove: false,
                    canResize: false,
                    ...widget,
                };
            });

            this.widgets.sort((a, b) => {
                if (a.rowStart === b.rowStart) {
                    return a.columnStart! - b.columnStart!;
                }
                return a.rowStart! - b.rowStart!;
            });
            // this.ready = false;
            // setTimeout(() => (this.ready = true));
            // // console.dir(this.widgets.pop());
            // console.dir(changes);
        }

        if (changes?.['editMode']?.currentValue !== null && changes?.['editMode']?.currentValue !== undefined && changes['editMode'].currentValue !== changes['editMode'].previousValue) {
            this.editMode = changes['editMode'].currentValue === 'true' || changes['editMode'].currentValue === true;
        }
    }

    ngOnDestroy(): void {}
}

export interface WidgetConfig {
    /** Unique id for the widget. */
    id: string;

    /** The main heading for the widget. */
    mainHeading?: string;

    /** Secondary heading for the widget.  */
    subHeading?: string;

    value: string | WidgetChartConfig | WidgetTextConfig;

    type: string;

    /** Icon classes to use with the heading. */
    icon?: string;

    /** The color of the icon next to the heading. */
    iconColor?: string;

    /** The css grid index of the column to position the left side of the widget at. */
    columnStart?: number;

    /** The css grid index of the row to positiol the top of the widget at.  */
    rowStart?: number;

    /** The length of the widget, given in how many columns it spans over. */
    columnSpan: number;

    /** The height of the widget, given in how many rows it spans over. */
    rowSpan: number;

    canMove?: boolean;
    canResize?: boolean;

    /** Custom styling for the widget. */
    style: Partial<CSSStyleDeclaration>;
}
