(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("transportAreasService", ['$ihttp', function ($ihttp) {
        let service = {
            listAreas: function (parms) {
                return $ihttp.post({
                    method: 1397,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
