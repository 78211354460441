(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('interBranchMulti', {
        templateUrl: 'views/components/views/interBranchMulti/interBranchMulti.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['interBranchMultiService', function (interBranchMultiService) {
            let vm = this;
            
            vm.model = {
                itemsListInterBranchMulti: []
            };

            let loadInterBranchMulti = function () {
                interBranchMultiService.listInterBranchMulti().then(function (result) {
                    angular.copy(result, vm.model.itemsListInterBranchMulti);
                });
            };
            
            vm.$onInit = function () {
                loadInterBranchMulti();
            };
        }]
    });
})();
