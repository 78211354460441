(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travelExpence', {
        templateUrl: 'views/components/views/travelExpence/travelExpence.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'travelExpenceService', 'economyService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, travelExpenceService, economyService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let travelBillNo = $stateParams.travelbill_no;
            let travelExpenceNo = $stateParams.travelexpence_keyno;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };
            
            vm.model = {
                trexTypeButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('travexptypes', {}) }
                ],
                edit: {},
                selectListExpenceTypes: [],
                selectListCurrencies: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                let deferred = $q.defer();
                
                travelExpenceService.getTravelExpence({
                    travelbill_no: travelBillNo,
                    travelexpence_keyno: travelExpenceNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadExpenceTypes()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCurrencies = function () {
                economyService.listCurrencies().then(function (result) {
                    angular.copy(result, vm.model.selectListCurrencies);
                });
            };
            
            let loadExpenceTypes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.travelbill_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.travelexpence_keyno) !== true) return;
                
                return travelExpenceService.listExpenceTypes({
                    travelbill_no: vm.model.edit.travelbill_no,
                    travelexpence_keyno: vm.model.edit.travelexpence_keyno
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListExpenceTypes);
                });
            };
            
            let loadCurrencyExRate = function () {
                if (utilityService.validateParmsValue(vm.model.edit.base_valuta_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.valuta_id) !== true) return;
                
                return travelExpenceService.getCurrencyExRate({
                    valuta_id: vm.model.edit.base_valuta_id,
                    currency_id: vm.model.edit.valuta_id,
                    currencytype_keyno: '1'
                }).then(function (response) {
                    vm.model.edit.exchangerate = response[0].exchangerate;
                });
            };
            
            let loadExchangeFactor = function () {
                if (utilityService.validateParmsValue(vm.model.edit.valuta_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.exchangerate) !== true) return;
                
                return travelExpenceService.getExchangeFactor({
                    currency_id: vm.model.edit.valuta_id,
                    exchangerate: vm.model.edit.exchangerate
                }).then(function (response) {
                    vm.model.edit.exchangefactor = response[0].exchangerate_factor;
                });
            };
            
            // ## CALCULATION FUNCTIONS ##
            
            var recalcAmount = function () {
                //console.log('recalcAmount');
                vm.model.edit.amount = utilityService.parseNumber(vm.model.edit.amountinvaluta) * utilityService.parseNumber(vm.model.edit.exchangerate);
                //console.dir(vm.model.edit.amount);
            };
            
            var recalcExchangeFactor = function () {
                //console.log('recalcExchangeFactor');
                vm.model.edit.amount = utilityService.parseNumber(vm.model.edit.amountinvaluta) * utilityService.parseNumber(vm.model.edit.exchangefactor);
                //console.dir(vm.model.edit.amount);
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };
                
                travelExpenceService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        switch (state) {
                            case 'pictures':
                            case 'documents':
                                go({
                                    argtype: 'travelexpence_keyno',
                                    argvalue: vm.model.edit.travelexpence_keyno
                                });
                                break;
                            default:
                                break;
                        }
                    }
                });
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                travelExpenceService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    travelExpenceService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };
            
            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'valuta_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.valuta_id) !== true) return;

                        vm.model.edit.valuta_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.valuta_id, true) !== true) return;

                        loadCurrencyExRate();
                        break;
                    case 'amountinvaluta':
                        if (utilityService.validateWatchValue(value, vm.model.edit.amountinvaluta) !== true) return;

                        vm.model.edit.amountinvaluta = value;

                        if (utilityService.validateParmsValue(vm.model.edit.amountinvaluta, true) !== true) return;

                        recalcAmount();
                        recalcExchangeFactor();
                        break;
                    case 'exchangerate':
                        if (utilityService.validateWatchValue(value, vm.model.edit.exchangerate) !== true) return;

                        vm.model.edit.exchangerate = value;

                        if (utilityService.validateParmsValue(vm.model.edit.exchangerate, true) !== true) return;
                        
                        loadExchangeFactor();
                        break;
                    case 'exchangefactor':
                        if (utilityService.validateWatchValue(value, vm.model.edit.exchangefactor) !== true) return;

                        vm.model.edit.exchangefactor = value;

                        if (utilityService.validateParmsValue(vm.model.edit.exchangefactor, true) !== true) return;

                        recalcExchangeFactor();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCurrencies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
