(function () {
    'use strict';

    angular.module('imApp')
        .directive('imNumpadDirective', ['layoutService', function (layoutService) {
            var directive = {
                restrict: 'E',
                scope: {
                    label: '=',           //
                    plabel: '=',          //
                    field: '=',           //
                    //showcal: '=',       //Adds a state where the button is shown or hidden //Accepts "0" or "1"
                    //btnsize: '@',       //Added as class and will determine the size of the btn with ability to add one or more sizes xs, sm, md, lg. Ex col-xs-8 col-lg-4
                    //btnstyle: '@',      //Used to add a different style to the button   //NOT IMPLEMENTED
                    //optionfunc: '&',    //Used as reference to function in Controller
                    onEnter: '='
                },
                template:
                '<div ng-class="addGroup()" id="{{model.mId}}" ng-style="{height: \'230px\'}">' +
                    //'<div ng-show="showCal()" ng-class="addClass()">' +
                    //'<div >' +
                    //'<input ng-class="addClass()" type="' + type + '" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="{\'fontSize\': fontSizes.textSize}" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" ng-blur="keepFocus(false)" ng-readonly="' + (rIndex > -1) + '" ></input>' +
                    '<label ng-if="label !== undefined && label !== \'\'" class="name-label col-xx-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}" im-translate="{{label}}">' +
                    '</label>' +
                    '<label ng-if="plabel !== undefined && plabel !== \'\'" class="name-label col-xx-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">'+
                        '{{plabel}}' +
                    '</label>' +
                    '<div ng-class="addClass(\'inputDiv\')">' +
                        '<input ng-class="addClass(\'input\')" type="text" ng-model="model.field" ng-style="{\'fontSize\': fontSizes.textSize}" ng-readonly="true"></input>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'7\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">7</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'8\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">8</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'9\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">9</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'del\')" type="{{model.type}}" ng-class="addBtnClass(\'danger\')" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">DEL</span>' + //remove
                            '<span class="fa fa-backspace" ng-style="{\'fontSize\': fontSizes.textSize}" style="color: white; top: 0"></span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'4\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">4</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'5\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">5</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'6\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">6</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'minus\')" type="{{model.type}}" ng-class="addBtnClass(\'warning\')" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">-</span>' + 
                            '<span class="fa fa-minus" ng-style="{\'fontSize\': fontSizes.textSize}" style="color: white; top: 0"></span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'1\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">1</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'2\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">2</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'3\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">3</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'pluss\')" type="{{model.type}}" ng-class="addBtnClass(\'warning\')" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">+</span>' + 
                            '<span class="fa fa-plus" ng-style="{\'fontSize\': fontSizes.textSize}" style="color: white; top: 0"></span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'0\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">0</span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'dot\')" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">.</span>' + //circle
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'clear\')" type="{{model.type}}" ng-class="addBtnClass(\'danger\')" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">CLEAR</span>' + 
                            '<span class="fa fa-eraser" ng-style="{\'fontSize\': fontSizes.textSize}" style="color: white; top: 0"></span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-class="addClass()">' +
                        '<button ng-click="goNum(\'enter\')" type="{{model.type}}" ng-class="addBtnClass(\'success\')" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<span class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}">ENTER</span>' + 
                            '<span class="fa fa-check" ng-style="{\'fontSize\': fontSizes.textSize}" style="color: white; top: 0"></span>' +
                        '</button>' +
                    '</div>' +
                '</div>',
                link: function ($scope) {
                    $scope.model = {
                        mId: uuid(),
                        name: '',
                        field:'',
                        type: 'button',
                        textalign: 'center',
                        isDirty: false
                    };

                    $scope.addGroup = function () {

                        var allClass = 'form-group im-no-mar'; //default

                        //if (angular.isDefined($scope.group)) {
                        //    allClass = $scope.group;
                        //    //allClass = '';
                        //}

                        return allClass;
                    };

                    $scope.addClass = function (btnsize) {
                        //var allClass = 'col-lg-3 col-md-4 col-sm-6 col-xs-12 im-no-pad'; //default
                        var allClass = 'col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xx-3 im-no-pad'; //default

                        if (angular.isDefined($scope.btnsize) && $scope.btnsize !== null) {
                            allClass = $scope.btnsize;
                        } else if (angular.isDefined(btnsize) && btnsize !== null) {
                            //allClass = btnsize;
                            if (btnsize === 'inputDiv') {
                                allClass = 'col-xx-12 im-no-pad';
                            } else if (btnsize === 'input') {
                                allClass = 'form-control input-lg input-field input-text-right ';
                                //allClass = 'form-control input-lg input-field input-text-right col-xx-12 im-no-pad';
                            }
                        }

                        return allClass;
                    };

                    $scope.addBtnClass = function (type) {
                        //var allClass = angular.isDefined(type) ? 'btn btn-' + type + ' btn-block btn-lg im-btn' : 'btn btn-primary btn-block btn-lg im-btn'; //default
                        var allClass = 'btn btn-primary btn-block btn-lg im-btn'; //default

                        //if (angular.isDefined($scope.btnstyle) && $scope.btnstyle !== null) {
                        //    allClass = 'btn ' + $scope.btnstyle + ' btn-block btn-lg im-btn';
                        //    if ($scope.showSetting() == true) {
                        //        allClass += ' im-setting-btn';
                        //    }
                        //} else if ($scope.showSetting() == true) {
                        //    allClass = 'btn btn-block btn-lg im-btn';
                        //    allClass += ' btn-warning';
                        //    //allClass += ' im-setting-btn';
                        //}

                        return allClass;
                    };

                    $scope.dirtyCheck = function (value) {
                        if ($scope.model.isDirty === false) {
                            $scope.model.field = '';
                            $scope.model.isDirty = true;
                        }

                        if (value === '.') {
                            if ($scope.model.field.indexOf('.') <= -1 && $scope.model.field.indexOf(',') <= -1) {
                                $scope.model.field += value;
                            }
                        } else {
                            $scope.model.field += value;
                        }
                    };

                    $scope.goNum = function (input) {
                        switch (input) {
                            case '1':
                                $scope.dirtyCheck('1');
                                //$scope.model.field += '1';
                                break;
                            case '2':
                                $scope.dirtyCheck('2');
                                //$scope.model.field += '2';
                                break;
                            case '3':
                                $scope.dirtyCheck('3');
                                //$scope.model.field += '3';
                                break;
                            case '4':
                                $scope.dirtyCheck('4');
                                //$scope.model.field += '4';
                                break;
                            case '5':
                                $scope.dirtyCheck('5');
                                //$scope.model.field += '5';
                                break;
                            case '6':
                                $scope.dirtyCheck('6');
                                //$scope.model.field += '6';
                                break;
                            case '7':
                                $scope.dirtyCheck('7');
                                //$scope.model.field += '7';
                                break;
                            case '8':
                                $scope.dirtyCheck('8');
                                //$scope.model.field += '8';
                                break;
                            case '9':
                                $scope.dirtyCheck('9');
                                //$scope.model.field += '9';
                                break;
                            case '0':
                                $scope.dirtyCheck('0');
                                //$scope.model.field += '0';
                                break;
                            case 'dot':
                                $scope.dirtyCheck('.');
                                //if ($scope.model.field.indexOf('.') <= -1 && $scope.model.field.indexOf(',') <= -1) {
                                //}
                                //$scope.model.field += '.';
                                break;
                            case 'clear':
                                $scope.model.isDirty = true;
                                $scope.model.field = '';
                                break;
                            case 'del':
                                $scope.model.isDirty = true;
                                $scope.model.field = $scope.model.field.slice(0, -1);
                                break;
                            case 'minus':
                                $scope.model.isDirty = true;
                                $scope.model.field = (Number($scope.model.field) - 1).toString();
                                break;
                            case 'pluss':
                                $scope.model.isDirty = true;
                                $scope.model.field = (Number($scope.model.field) + 1).toString();
                                break;
                            case 'enter':
                                $scope.model.isDirty = false;
                                if ($scope.model.field === '') {
                                    $scope.model.field = '0';
                                }
                                $scope.field = $scope.model.field;

                                if (angular.isFunction($scope.onEnter)) {
                                    $scope.onEnter($scope.field);
                                }

                                //if (angular.isFunction($scope.optionfunc) !== true) {
                                //    $scope.optionfunc({ value: value });
                                //}

                                break;
                        }
                        //$scope.optionfunc();
                    };

                    //$scope.showCal = function () {
                    //    if ($scope.model.showcal == '1' || $scope.model.showcal == true) {
                    //        return true;
                    //    } else {
                    //        return false;
                    //    }
                    //}

                    $scope.addGlyph = function () {
                        var allClass = 'glyphicon glyphicon-chevron-right pull-right'; //default

                        if (angular.isDefined($scope.glyphs) && $scope.glyphs !== null) {
                            allClass = 'glyphicon ' + $scope.glyphs;
                        }

                        return allClass;
                    };

                    $scope.fontSizes = {};

                    layoutService.onLayoutChanged($scope, function (info) {
                        if (angular.isUndefined(info)) return;

                        angular.copy(info.fontSizes, $scope.fontSizes);
                    });

                    $scope.$watch(function () {
                        return $scope.field;
                    }, function () {
                        if (angular.isDefined($scope.field) && $scope.field !== null) {
                            $scope.model.field = $scope.field;
                        }
                    });
                }
            };

            return directive;
        }]);
})();