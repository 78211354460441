(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('dispatchPlanningConsignment', {
        templateUrl: 'views/components/views/dispatchPlanningConsignment/dispatchPlanningConsignment.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'dispatchPlanningConsignmentService', 'modalService', 'stateService', 'ieScreenBlockService', 'printService', 'fileService', 'cargonizerApiService', function ($stateParams, $timeout, dispatchPlanningConsignmentService, modalService, stateService, ieScreenBlockService, printService, fileService, cargonizerApiService) {
            var vm = this;

            vm.model = {
                dispatch_no: $stateParams.dispatch_no,
                consignment: {},
                collies: [],
                orderlines: [],
                case_count: 1,
                checkedCount: 0,
                case_mode: 0,
                createConsignmentEnabled: 0,
                printer: '',
                p2_reportdef_keyno: '',
                printer: '',
                printers: [],
                settings: {
                    printer_id: '',
                    report_name: ''
                }
            };

            var init = function(){
                dispatchPlanningConsignmentService.validateDispatch(vm.model.dispatch_no).then(function(data){
                    angular.copy(data[0], vm.model.consignment);

                    loadList();
                    loadPrinters();
                });
            };

            var loadList = function () {
                if (vm.model.consignment.consignment_keyno > 0) {
                    dispatchPlanningConsignmentService.loadCollies(vm.model.dispatch_no).then(function (data) {
                        angular.copy(data, vm.model.collies);
                    });
                } else {
                    dispatchPlanningConsignmentService.loadOrderlines(vm.model.dispatch_no).then(function (data) {
                        angular.copy(data, vm.model.orderlines);
                        console.log(vm.model.orderlines);

                        vm.model.createConsignmentEnabled = 1;

                        vm.model.orderlines.forEach(function (item) {
                            if (item.item_stock_status == '0') {
                                vm.model.createConsignmentEnabled = 0;
                            }
                        });
                    });
                }
            };

            var loadSettings = function () {
                dispatchPlanningConsignmentService.loadSettings().then(function (data) {
                    vm.model.settings = data[0];
                    vm.model.printer = vm.model.settings.printer_id;
                    console.log('Report Name', vm.model.settings.report_name);
                });
            };

            var loadPrinters = function () {
                dispatchPlanningConsignmentService.loadPrinters().then(function (data) {
                    vm.model.printers = [];

                    angular.forEach(data, function (item) {
                        item.item_id = item.item_cargonizer_printer_id;
                        item.item_name = item.item_name + ' ( ' + item.item_name_sub1 + ') ';
                        vm.model.printers.push(item);
                    });

                    loadSettings();
                });
            };

            var handleListToggle = function (item) {
                if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                } else {
                    item.item_is_selected = '1';
                }
            };

            init();

            vm.createConsignment = function () {
                dispatchPlanningConsignmentService.createConsignment(vm.model.dispatch_no).then(function (data) {
                    console.log('createConsignment()');
                    stateService.go('dispatchplanningconsignment', { dispatch_no: vm.model.dispatch_no || 0 },{ reload: true, inherit: false });
                });
            };

            vm.handlePrinterChange = function (value) {
                vm.model.printer = value;
                dispatchPlanningConsignmentService.remember('dispatchplanningconsignment.default_printer', vm.model.printer);
            };

            vm.handleListClick = function (item) {
                var iconClicked = angular.isDefined(item.item) ? false : true;
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;

                // Toggle list status, but only inside else
                handleListToggle(item);

                /*
                if ((currentItem.item_readytofinish == 0) && (currentItem.item_has_stockbatch > 0) && (currentItem.item_has_case == 0)) {
                    console.log('if');

                    if (currentItem.item_is_selected != '0') {
                        currentItem.item_is_selected = '0';
                    } else {
                        currentItem.item_is_selected = '1';
                        vm.model.case_mode = 0;
                    }

                    vm.model.checkedCount = 0;
                    vm.model.case_count = 1;
                    
                    angular.forEach(vm.model.collies, function (item) {
                        if (item.item_has_case != vm.model.case_mode) {
                            item.item_is_selected = '0';
                        }
                        if (item.item_is_selected != '0') {
                            vm.model.checkedCount += 1;

                            if (item.item_quantity > vm.model.case_count) {
                                vm.model.case_count = item.item_quantity;
                            }
                        }
                    });
                } else if ((currentItem.item_readytofinish == 0) && (currentItem.item_has_stockbatch > 0) && (currentItem.item_has_case != 0)) {
                    console.log('else if');

                    if (currentItem.item_is_selected != '0') {
                        currentItem.item_is_selected = '0';

                    } else {
                        currentItem.item_is_selected = '1';
                        vm.model.case_mode = 1;
                    }

                    vm.model.checkedCount = 0;
                    vm.model.case_count = 1;

                    angular.forEach(vm.model.collies, function (item) {
                        if (item.item_has_case != vm.model.case_mode) {
                            item.item_is_selected = '0';
                        }
                        if (item.item_is_selected != '0') {
                            vm.model.checkedCount += 1;

                            if (item.item_quantity > vm.model.case_count) {
                                vm.model.case_count = item.item_quantity;
                            }
                        }
                    });
                } else {
                    console.log('else');

                    if (currentItem.item_case != '') {
                        modalService.confirm({
                            title: 'Bekreft',
                            message: 'Vennligst bekreft at du vil slette denne?',
                            okLabel: 'Ok',
                            cancelLabel: 'Avbryt'
                        }).then(function () {
                            console.error('CASE DELETED (NOT REALLY :-) )', currentItem.item_case);


                            // TODO: Remove this when using service to actually delete case
                            stateService.go(stateService.getCurrentName(), {
                                dispatch_no: $stateParams.dispatch_no
                            },{ 
                                reload: true, inherit: false 
                            });

                            
                            //dispatchPlanningConsignmentService.deleteCase(currentItem.item_case).then(function () {
                            //    stateService.go(stateService.getCurrentName(), {
                            //        dispatch_no: $stateParams.dispatch_no
                            //    },{ 
                            //    reload: true, inherit: false 
                            //    });
                            //});
                            
                        });
                    }
                }
                */
            };

            vm.gotoConsignment = function () {
                stateService.go('consignment', {
                    consignment_keyno: vm.model.consignment.consignment_keyno
                });
            };

            vm.mergeConsignmentCollies = function () {
                var params = {
                    //consignment_keyno: '', //$stateParams.consignment_keyno,
                    data: vm.model.collies
                };

                console.dir(vm.model.collies);

                dispatchPlanningConsignmentService.mergeCollies(params).then(function () {
                    loadList();
                });
            };

            vm.splitConsignmentCollies = function () {
                var params = {
                    // consignment_keyno: '', //$stateParams.consignment_keyno,
                    data: vm.model.collies
                };

                console.dir(vm.model.collies);

                dispatchPlanningConsignmentService.splitCollies(params).then(function () {
                    loadList();
                });
            };

            vm.transferConsignment = function () {                
                dispatchPlanningConsignmentService.transferConsignment(vm.model.consignment.consignment_keyno).then(function () {
                    stateService.go('dispatchplanningconsignment', { dispatch_no: vm.model.dispatch_no || 0 },{ reload: true, inherit: false });
                });
            };

            vm.printCargonizerReport = function (docarcFileName) {
                cargonizerApiService.printCargonizerConsignmentReport(0, vm.model.printer, docarcFileName).then(function () {
                    ieScreenBlockService.stop();
                    stateService.go('dispatchplanningconsignment', { dispatch_no: vm.model.dispatch_no || 0 },{ reload: true, inherit: false });
                });
            };

            vm.consolidateAndPrint = function () {
                console.table(vm.model.collies);
                var case_list = '';

                vm.model.collies.forEach(function (item) {
                    console.log('Kolli:');
                    console.dir(item);

                    if (item.item_is_selected == '1') {
                        if (case_list.length > 0) {
                            case_list = case_list + ',' + item.item_case;
                        } else {
                            case_list = item.item_case;
                        }
                    }
                });

                if (case_list.length > 0) {
                    ieScreenBlockService.start('message');
                    ieScreenBlockService.setMessage('Lager kolli');

                    printService.archive({
                        reportId: vm.model.settings.report_name,
                        parameters: {}
                    }).then(function (fileItem) {
                        console.log('then..');
                        console.dir(fileItem);
                        ieScreenBlockService.setMessage('Getting file details...');

                        fileService.getFileDetails({ fileName: fileItem.fileNameWithExtension, wait: true, instanceId: fileItem.instanceId }).then(function (dr) {
                            ieScreenBlockService.setMessage('Printing labels....');
                            console.dir(dr);
                            vm.printCargonizerReport(dr.fileName);
                        });
                    }).catch(function (err) {
                        console.log('report error:', err);
                    });

                    $timeout(function () {
                        //ieScreenBlockService.setMessage('Printing completed');
                        console.log('Ferdig og lukk');
                        ieScreenBlockService.stop();
                        stateService.go('dispatchplanningconsignment', { dispatch_no: vm.model.dispatch_no || 0 }, { reload: true, inherit: false });
                    }, 1500);
                };

                /*
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Lager kolli');
                var pos = '';

                angular.forEach($scope.model.collies, function (item) {
                    if (item.item_is_selected != '0') {
                        if (pos.length > 0) {
                            pos = pos + ',';
                        }
                        pos = pos + item.item_po;
                    }
                });

                var cc = 1;
                if ($scope.model.case_count > 1) {
                    cc = $scope.model.case_count;
                }

                if (pos.length > 0) {
                    console.dir('$scope.model.printer:', $scope.model.printer);
                    if (parseInt($scope.model.printer) > 0) {
                        poFinishProdPackService.caseOrders(
                            {
                                pos: pos,
                                case_count: cc
                            }).then(function (data) {
                                console.log('CASES:');
                                console.dir(data);
                                // store user variable:
                                poFinishProdPackService.remember('prodpack.cases', data[0].case_keyno_list).then(function () {


                                    ieScreenBlockService.setMessage('Printing labels');

                                    console.log('$scope.model.settings.report_name: ', $scope.model.settings.report_name);
                                    // cutout

                                    printService.archive({
                                        reportId: $scope.model.settings.report_name,
                                        parameters: {}
                                    }).then(function (fileItem) {

                                        console.log('then..');
                                        console.dir(fileItem);
                                        ieScreenBlockService.setMessage('Getting file details...');
                                        fileService.getFileDetails({ fileName: fileItem.fileNameWithExtension, wait: true, instanceId: fileItem.instanceId }).then(function (dr) {
                                            ieScreenBlockService.setMessage('Printing labels....');
                                            console.dir(dr);
                                            $scope.printCargonizerReport(dr.fileName);
                                        });

                                    }).catch(function (err) {
                                        console.log('report error:', err);
                                    });

                                    $timeout(function () {
                                        //ieScreenBlockService.setMessage('Printing completed');
                                        console.log('Ferdig og lukk');
                                        ieScreenBlockService.stop();
                                        stateService.go('pofinishprodpack', {
                                            poh_keyno_top: $stateParams.poh_keyno_top,
                                            counter: $scope.model.parmCounter
                                        });
                                    }, 1500);
                                    // cutout 3 

                                });
                                //stordalconsignmentcolli.trdp          
                            });
                    } else {
                        alert('Ugyldig printer');
                        ieScreenBlockService.stop();
                    }
                } else {
                    alert('Ingen valgt');
                    ieScreenBlockService.stop();
                }
                */
            };
        }]
    });
})();