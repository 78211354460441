(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("businessLangService", ['$ihttp', function ($ihttp) {
        let service = {
            listBusinessLanguages: function (parms) {
                return $ihttp.post({
                    method: 1148,
                    parameters: parms || {}
                });
            },
            saveObj: function (list) {
                return $ihttp.post({
                    method: 1149,
                    parameters: list
                });
            }
        };

        return service;
    }]);
})();
