(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('printTestClientDetails', {
        templateUrl: 'views/components/views/printTestClientDetails/printTestClientDetails.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'printService', function($stateParams, printService) {
    
            var vm = this;
    
    
            vm.model = {
                printClientKeyno: $stateParams.printClientKeyno,
                printClientName: '',
                printClient: {}
            };
    
            var load = function () {
                printService.getClientDetails({ printClientKeyno: vm.model.printClientKeyno}).then(function (data) {
                    data.regDateTime = moment(data.regDateTime).format('YYYY-MM-DD HH:mm:ss');
                    data.modDateTime = moment(data.modDateTime).format('YYYY-MM-DD HH:mm:ss');
    
                    angular.copy(data, vm.model.printClient);
                });
            };
    
            load();
        }]
    });
})();
