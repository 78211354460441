(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDelControl', {
        templateUrl: 'views/components/views/batchDelControl/batchDelControl.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', '$timeout', 'modalService', 'stateService', 'utilityService', 'rememberService', 'batchDelControlService', function($stateParams, $timeout, modalService, stateService, utilityService, rememberService, batchDelControlService) {
			
            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var timerSearchText = null;
            var onDestroy = [];

            vm.model = {
                batchDeliveryKeyNo: $stateParams.batchdelivery_keyno,
                purchInternalNo: $stateParams.purch_internal_no,
                bgBatchDelControl: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_batchdelcontrol_colli', item_func: 'COLLI' },
                        { item_id: '1', item_name: 'bg_batchdelcontrol_sku', item_func: 'SKU' }
                    ]
                },
                searchButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', onClick: () => vm.onSearchTextChange('') }
                ],
                settingControl: {},
                itemsListControls: [],
                itemsListSkus: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // ## LOAD PROCEDURE CALLS ##

            batchDelControlService.loadBatchDelControlGet(vm.model.batchDeliveryKeyNo, vm.model.purchInternalNo).then(function (data) {
                vm.model.settingControl = angular.copy(data[0]);

                loadBatchDelReceiveLinesControls();
                loadBatchDelReceiveLinesControlSkus();
            });

            var loadBatchDelReceiveLinesControls = function () {
                if (utilityService.validateParmsValue(vm.model.settingControl.searchtext, true) !== true) return;

                vm.model.itemsListControls = [];

                batchDelControlService.loadBatchDelReceiveLinesControlList(vm.model.settingControl).then(function (data) {
                    angular.copy(data, vm.model.itemsListControls);
                });
            };

            var loadBatchDelReceiveLinesControlSkus = function () {
                if (utilityService.validateParmsValue(vm.model.settingControl.searchtext, true) !== true) return;

                vm.model.itemsListSkus = [];

                batchDelControlService.loadBatchDelReceiveLinesControlSkuList(vm.model.settingControl).then(function (data) {
                    angular.copy(data, vm.model.itemsListSkus);
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var rememberSearchText = function () {
                var variableNameSearchText = 'w_batchdelcontrol.searchtext';

                if (utilityService.validateParmsValue(variableNameSearchText) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingControl.searchtext, true) !== true) return;

                if (angular.isDefined(timerSearchText)) {
                    $timeout.cancel(timerSearchText);
                }

                if (vm.model.settingControl.searchtext !== '') {
                    timerSearchText = $timeout(function () {
                        rememberService.remember(variableNameSearchText, vm.model.settingControl.searchtext);
                    }, 1000);
                } else {
                    rememberService.remember(variableNameSearchText, vm.model.settingControl.searchtext);
                }
            };

            // ## BUTTON GROUP FUNCTIONS ##

            vm.selectBgBatchDelControl = function (item) {
                vm.model.bgBatchDelControl.item_selected = item;
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'deviations':
                        go({
                            argtype: 'batchdelivery_keyno',
                            argvalue: vm.model.settingControl.batchdelivery_keyno
                        });
                        break;
                }
            };

            // ## SAVE BUTTON FUNCTION ##

            vm.approveBatchDelControl = function () {
                vm.model.lockedApprove = true;
            
                batchDelControlService.approve(vm.model.settingControl).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back('/batchdelivery/' + vm.model.settingControl.batchdelivery_keyno);
                        vm.model.lockedApprove = false;
                    }
                });
            };

            vm.onSearchTextChange = function (value) {
                vm.model.settingControl.searchtext = value;

                rememberSearchText();
            }

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

		    // #endregion DESTROY FUNCTION
		}]
	});
})();