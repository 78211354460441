(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectNewDoctype', {
        templateUrl: 'views/components/views/selectNewDoctype/selectNewDoctype.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectNewDoctypeService', function ($stateParams, selectNewDoctypeService) {
            var vm = this;
            
            vm.model = {
                items: []
            };
            
            selectNewDoctypeService.loadP2DocarcCreateSelectTypeList($stateParams.argtype, $stateParams.argvalue).then(function (data) {
                angular.copy(data, vm.model.items);
            });
        }]
    });
})();