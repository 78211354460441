(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("bdScanDetailService", ['$ihttp', function ($ihttp) {
        let service = {
            getDetails: function (parms) {
                return $ihttp.post({
                    method: 1643,
                    parameters: parms || {}
                });
            },
            listScanDetailsPlaced: function (details) {
                return $ihttp.post({
                    method: 1644,
                    parameters: details || {}
                });
            }
        };

        return service;
    }]);
})();
