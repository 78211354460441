import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnInit, HostListener, Inject } from '@angular/core';
import { List } from '@microsoft/microsoft-graph-types';
import { last } from 'pdf-lib';
import { Base64UrlService } from '../../../services/base64url.service';
import { HtmlReportService } from '../../../services/html-report.service';
import { PdfService } from '../../../services/pdf.service';
import { DataTaskService } from '../../services/data-task.service';
import { LanguageService } from '../../services/language.service';
import { ModalService } from '../../services/modal.service';
import { TranslateService } from '../../services/translate.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { element } from 'angular';
import { StateService } from '../../services/state.service';
import { CdkMenuModule } from '@angular/cdk/menu';


interface HtmlProcItem {
    htmlproccol_keyno: string,
    htmlproc_keyno: string,
    colname: string,
    colname_lang: string,
    pos_x: number,
    pos_y: number,
    width: string,
    height: string,
    visible: string,
    bold: string,
    italic: string,
    textalign: string,
    fontsize: string,
    style: string,
    decimals: string,
    is_image: string,
    color: string,
    bg_color: string,
    font: string,
    language_id: string,
    stringvalue: string
}

interface HtmlProc {
    htmlproc_keyno: string;
    htmlreport_keyno: string;
    proc_name: string;
    presentation_type: string;
    row_count: string;
    col_count: string;
    has_header: string;
    has_border: string;
    has_padding_bottom: string;
    has_alternating_color: string;
    sortorder: string;
    htmlproc_keyno_belongto: string;
    is_header: string;
}

type PlacehHolderDataMap = Map<number, HtmlProcItem[]>

@Component({
    selector: 'tt-html-report',
    templateUrl: './html-report.component.html',
    styleUrls: ['./html-report.component.css']
})
    /**
     * HTML DRAG AND DROP PAGE COMPONENT
     * @Author: HSK, EO
     * Last Edited: 24.10.2024
     *
     * Allows a user to create a report template
     * Supports drag & drop of items on the page
     * Edit all text & tables. Right click on text to edit
     *
     * @Input Htmlreport_keyno
     *
     */
export class HtmlReportComponent {

    focusedKeyno: string | null = null;

    model = {
        paperWidth: 805,
        scale: 1,
        htmlReport: {
            htmlreport_keyno: '',
            htmlreport_name: '',
            proc_name: '',
            header_proc_name: '',
            footer_proc_name: ''
        } as any,
        htmlStructure: [] as any[],
        htmlProcColList: [] as any[],
        htmlReportHtml: '',
        htmlReportHtmlHeader: '',
        htmlReportHtmlBody: '',
        htmlReportHtmlFooter: '',
        colData: [] as any[],
        totalCells: 0 as number,
        totalCellsArray: [] as any[],
        colDataMap: null as Map<number, HtmlProcItem[]> | null,
        PlaceHolderDataMap: new Map as Map<number, PlacehHolderDataMap> | null,
        connectedList: [] as any[],
        language_id: '',
        languageList: [] as any[],
        lockedHtmlRefresh: false,
        lockedPrint: false,
        get: null,
        isDragging: false,
        contextMenuVisible: false,
        contextMenuPosition: { x: 0, y: 0 },
        selectedItem: null as unknown as HtmlProcItem,
        documentOverviewMap: null as Map<HtmlProc, HtmlProcItem[]> | null,
        colChanges: [] as string[],
        currentHtmlProc: null as unknown as HtmlProc,
        documentSortOrderArray: [] as any[],
        isFocus: false,
        hierarchy: [] as { htmlproc_keyno: string, name: string, indentations: number }[],
        tablePreviewMap: null as Map<HtmlProc, string> | null,
        htmlStaticPreview: '',
        isReady: false,
        sanitizedStringValue: this.sanitizeHTML('Select an item to edit'),

    };

    public translations: { [key: string]: string } = {
        procedures: 'sub_procedures',
        preview: 'preview',
        error: 'error',
        ok: 'ok'
    }
    constructor(
        private htmlReportService: HtmlReportService,
        private languageService: LanguageService,
        private translateService: TranslateService,
        private base64: Base64UrlService,
        private pdfService: PdfService,
        private modalService: ModalService,
        @Inject('$stateParams') private stateparams: any,
        private elementRef: ElementRef,
        private datatask: DataTaskService,
        private sanitizer: DomSanitizer,
        private stateService: StateService
    ) { }


    ngOnInit() {
        this.model.PlaceHolderDataMap = new Map<number, PlacehHolderDataMap>();
        this.model.documentOverviewMap = new Map<HtmlProc, HtmlProcItem[]>();
        this.model.tablePreviewMap = new Map<HtmlProc, string>();

    
        this.load();
    }

    // Translate words on the page outside of the htmlPreviewBuilder
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.values(this.translations));

        for (let key of Object.keys(translations)) {
            Object.entries(this.translations).forEach(([prop, value]) => {
                if (value === key) {
                    this.translations[prop] = translations[key];
                }
            });
        }
    }
    /**
     * Loads the entire preview page
     *
     * 1: Gets the sctruture of the htmlreport
     *   - This includes the header, body & footer
     *   - Headers are marked with isHeader
     *   - Footers are marked with isFooter
     *
     *   - They are all sorted by SortOrder (header 1, footer 100 000)
     *
     * 2: Get all of the proccols for all the sections
     *  - All the sub items (draggable elements)
     *
     * 3: Map the placeholder lists
     *  - For each section, make corresponding placeholder lists for the items to go into
     *  - One section contains row * count + 1 amount of placeholders
     *  - The last key in that map is the empty non visible placeholder list where all invisible elements are stored outside of the paper
     *
     * 4: Place all of the items in their correct placeholders and apply their style
     *  - Indexing is used (row * col count) + col count to place them in the correct indexed placeholder
     *  - Apply the items style to itself and it's parent cell / placeholder
     *
     * 5: It is now ready to be displayed
     * 
     */
    private async load() {
        this.model.htmlReport = await this.htmlReportService.getReport(this.stateparams.htmlreport_keyno);
        this.model.htmlReport = this.model.htmlReport[0];
        this.translate();

        // STEP 1: HTML STRUCTURE
        this.model.htmlStructure = await this.htmlReportService.getHtmlDisplayList(this.stateparams.htmlreport_keyno);
        this.model.htmlProcColList = await this.htmlReportService.getHtmlProccolList(this.stateparams.htmlreport_keyno);
        this.model.documentSortOrderArray = this.model.htmlStructure;
  
        // STEP 2: GET ALL PROCCOLS FOR EACH ITEM IN HTMLSTRUCTURE
        this.mapProccols();

        // STEP 3: MAP THE PLACEHOLDER LISTS
        this.createPlaceholderData(this.model.htmlStructure);
        // STEP 4: PLACE ALL ITEMS IN THEIR CORRECT PLACEHOLDERS
        if (this.model.documentOverviewMap) {
            this.placeProcccolsInPlaceholders(this.model.documentOverviewMap);
        }

        // GET HIERARCHY (FOR LEFT SIDE HIERARCHY VIEW)
        this.model.hierarchy = this.getHierarchy();
        // GET STATIC HTML PREVIEW (FOR WHEN NOT FOCUSED)
        this.getHtmlPreview();
        // GET STATIC TABLE PREVIEWS (FOR FOCUS ON TABLES)
        this.getTablePreview();

        // Set ready
        this.model.isReady = true;
    }

    /**
     * Maps the proccols to the correct proc
     * Sets a map with Key => proc object, Value => Array of HtmlProcItem
     */
    async mapProccols() {
        if (this.model.htmlStructure && this.model.htmlProcColList && this.model.documentOverviewMap) {
            this.model.htmlStructure.forEach(proc => {
                this.model.documentOverviewMap?.set(proc, this.model.htmlProcColList.filter(col => col.htmlproc_keyno == proc.htmlproc_keyno));
            });
        }
    }

    /**
     * Puts the proccols in the correct placeholders
     * Takes an in parameter so it can be called on only 1 proc and not all when updating 1 proc's table size
     * 
     * @param map Onload = placeHolderDataMap
     */
    async placeProcccolsInPlaceholders(map: Map<HtmlProc, HtmlProcItem[]>) {
        if (this.model.PlaceHolderDataMap && this.model.documentOverviewMap) {

            map.forEach((value, key) => {
                value.forEach(item => {
                    if (item.pos_y > 0 && item.pos_x > 0 && key.presentation_type === 'form') {
                            let index = (+ item.pos_x + ((item.pos_y - 1) * (+key.col_count)));
                            this.model.PlaceHolderDataMap?.get(+key.htmlproc_keyno)?.get(index)?.push(item);
                    }
                    else {
                        if (item.visible === '1' && key.presentation_type === 'table') {
                            console.log(item);
                            this.model.PlaceHolderDataMap?.get(+key.htmlproc_keyno)?.get(1)?.push(item);
                        } else {
                            this.model.PlaceHolderDataMap?.get(+key.htmlproc_keyno)?.get(0)?.push(item);
                        } 
                        }
                    });
            });

        };
    }
    /**
     * Makes the placeholders in which data will actually be stored
     * This function is only used for form layouts, tables only require two placeholders no matter the item count or size
     * Row_count * Col_count == amount of placeholder
     * Takes an input so on change of grid size, it only updates one proc, not all
     * 
     * @param array array of procs
     */
    createPlaceholderData(array: any[]) {
        array.forEach(proc => {
            let tempPlaceholderDataMap = new Map<number, HtmlProcItem[]>;
            if (proc.presentation_type === 'form') {
                let totalCellsTemp = + proc.row_count * +proc.col_count;

                for (let i = 0; i <= totalCellsTemp; i++) {
                    tempPlaceholderDataMap.set(i, []);
                }
            }
            if (proc.presentation_type === 'table') {
                tempPlaceholderDataMap.set(0, []);
                tempPlaceholderDataMap.set(1, []);
            }
            this.model.PlaceHolderDataMap?.set(+proc.htmlproc_keyno, tempPlaceholderDataMap);
        });

    }
    /**
     * Gets the data array for each placeholder cell
     * the CDK library requires that all lists have a knowledge of their content.
     * 
     * @param htmlproc interface
     * @param index the placeholder's index, to ensure correct assignment of data.
     * @returns
     */
    getPlaceholderData(htmlproc: HtmlProc, index: number) {
        if (htmlproc.presentation_type === 'table') {
            return this.model.PlaceHolderDataMap?.get(+htmlproc.htmlproc_keyno)?.get(index)?.sort((a, b) => a.pos_x - b.pos_x);
        }
        return this.model.PlaceHolderDataMap?.get(+htmlproc.htmlproc_keyno)?.get(index);
    }

    /**
     * Gets the hierarchy of the document structure.
     * As the drag & drop model is flat, this is a seperate function for the hierarchy on the left side of the screen
     * 
     * @param htmlproc_keyno
     * @param indentations
     * @returns
     */
    getHierarchy(htmlproc_keyno: string = '0', indentations:number = 0): {htmlproc_keyno:string, name: string, indentations: number}[] {
        let hierarchy: { htmlproc_keyno: string, name: string, indentations: number }[] = [];

        let procs = this.model.htmlStructure.filter(e => e.htmlproc_keyno_belongto === htmlproc_keyno);

        for (let proc of procs) {

            hierarchy.push({ htmlproc_keyno: proc.htmlproc_keyno, name: proc.proc_name, indentations: indentations});
            let children = this.getHierarchy(proc.htmlproc_keyno, indentations + 1);

            for (let child of children) {
                hierarchy.push(child);
            }
        }
        return hierarchy;
    }
    /**
     * Frontend workaround to tables not using col_count.
     * This needs to be reworked as it is not two way binding
     * In effect, this means the user does not receive visual feedback when updating col count on table
     *
     * Easy fix: Remove the col_count label on maps (use *ngIf) as it is only visual
     * Hard fix: Use a two way binding using [ ] on html
     * 
     * @param htmlproc Interface
     * @returns amount of items in the table (amount of td)
     */
    getVisibleItemCount(htmlproc: any): number {
        if (this.model.documentOverviewMap) {
            const items = this.model.documentOverviewMap.get(htmlproc) || [];
            return items.filter(item => item.visible === '1').length;
        }
        return 0;
    }


    /**
     * Loads the htmlPreview form the SQL protocol
     * Provides the static view when not in focus
     */
    async getHtmlPreview() {
        this.model.lockedHtmlRefresh = true;

        // TODO: Implement Language
        const params = {
            htmlreport_keyno: this.stateparams.htmlreport_keyno,
            language_id: 'no'//this.model.language_id
        };

        this.htmlReportService.getHtmlReportHtml(params).then((result: {
            headerstring: string;
            htmlstring: string;
            footerstring: string;
        }[]) => {
            if (result[0]?.htmlstring) {
                this.model.htmlReportHtmlBody = this.base64.Decode(result[0].htmlstring);
                this.model.htmlReportHtmlHeader = this.base64.Decode(result[0].headerstring);
                this.model.htmlReportHtmlFooter = this.base64.Decode(result[0].footerstring);
                this.model.htmlReportHtml = this.model.htmlReportHtmlHeader + this.model.htmlReportHtmlBody + this.model.htmlReportHtmlFooter;

                const element = this.elementRef.nativeElement.querySelector('#htmlStaticPreview');
                if (element) {
                    element.innerHTML = this.model.htmlReportHtml;
                }
            }
            this.model.lockedHtmlRefresh = false;
        });
    }

    

    // TODO: Implement this.
    onChange(name: string, value: any) {
        if (name === 'language_id' && value !== this.model.language_id) {
            this.model.language_id = value;

        }
    }

    /**
     * Print the html report
     * Currently not in use, change this comment when it is
     */
    async print() {
        this.model.lockedPrint = true;

        try {
            const pdfOptions = {
                marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                landscape: false,
                outline: false,
                printBackground: true,
                displayHeaderFooter: true,
                headerTemplate: this.model.htmlReportHtmlHeader,
                footerTemplate: this.model.htmlReportHtmlFooter
            };

            const params = {
                sourceHtml: this.model.htmlReportHtmlBody,
                filename: this.model.htmlReport.htmlreport_name,
                download: true,
                pdfOptions
            };

            //@ts-ignore
            await this.pdfService.htmlToPdf(undefined, params);
        } catch (e) {
        } finally {
            this.model.lockedPrint = false;
        }
    }

    /**
     * Save function
     * Saves only the changes made to the report to save time and space
     */
    async save():Promise<void> {
        if (this.model.documentOverviewMap) {
            let temp = Array.from(this.model.documentOverviewMap.values()).flat();
            await this.htmlReportService.saveList(temp.filter(e => this.model.colChanges.includes(e.htmlproccol_keyno)));
        }

    }

    public getNumbers(count: number): number[] {
        return Array.from({ length: count }, (_, i) => i);
    }

    updateEditItemContent(value: string): void {
        this.model.sanitizedStringValue = this.sanitizeHTML(value);
    }

    /**
     * On drop of items in CDKDRag
     * Send the item being dropped to the function, moves it and updates its value
     * Different handling of drop event based on if it has presentation_type = form or table
     * 
     * @param event The item being dropped as an event
     * @param index 
     * @param proc The section / proc in which the action is happening
     */
    drop(event: CdkDragDrop<HtmlProcItem[]>, index: number, proc: HtmlProc) {
        if (proc.presentation_type === 'form') {
            if (event.previousContainer === event.container) {
                // Moving within the same container
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                // Check if the target is the not visible target
                const targetElement = event.container.element.nativeElement;
                const isHtmlReportInvisible = targetElement.classList.contains('htmlSection');
                const elementIndex = targetElement.dataset["index"] || 0;
                const sourceItem = event.previousContainer.data[event.previousIndex];

                // Check if the target container already has an item
                if (event.container.data.length > 0 && !isHtmlReportInvisible) {
                    // Swap items between the previous container and the target container
                    const targetItem = event.container.data[0]; // Assuming there's only one item


                    // Remove the existing item from the target container
                    event.container.data.splice(0, 1); // Remove the item at index 0

                    // Add the source item to the target container
                    event.container.data.push(sourceItem);

                    // Move the target item back to the source container
                    event.previousContainer.data[event.previousIndex] = targetItem;
                } else {
                    // If the target container is empty, simply transfer the item
                    transferArrayItem(
                        event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        0 // Always place it at index 0 in the target container
                    );
                }

                // Handle position resetting based on visibility
                if (isHtmlReportInvisible) {
                    event.item.data.pos_x = "0";
                    event.item.data.pos_y = "0";
                    event.item.data.visible = "0";
                } else {
                    let new_pos_x = +elementIndex % +proc.col_count;
                    if (new_pos_x == 0) { new_pos_x = 4; }
                    const new_pos_y = +Math.ceil(+elementIndex / +proc.col_count);
                    event.item.data.pos_x = new_pos_x;
                    event.item.data.pos_y = new_pos_y;

                }

                this.model.colChanges.push(event.item.data);
                this.model.colChanges.push(sourceItem.htmlproccol_keyno);
            }
        } else if (proc.presentation_type === 'table') {
            if (event.previousContainer === event.container) {
                // Moving within the same container
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            } else {
                transferArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    0 // Always place it at index 0 in the target container
                );
            }

            const targetElement = event.container.element.nativeElement;
            const isHtmlReportInvisible = targetElement.classList.contains('htmlSection');
            const elementIndex = targetElement.dataset["index"] || 0;
            const sourceItem = event.previousContainer.data[event.previousIndex];

            if (isHtmlReportInvisible) {
                console.log("Invisible");
                event.item.data.pos_x = "0"; // Set position to 0 if invisible
                event.item.data.pos_y = "0";
                event.item.data.visible = "0";
            } else {
                // Update pos_x for the moved item
                event.item.data.pos_x = event.currentIndex; // Set the pos_x of the moved item to its current index

                const containerData = event.container.data;

                if (event.currentIndex > event.previousIndex) {
                    for (let i = 0; i < event.currentIndex; i++) {
                        const leftItem = containerData[i];
                        if (leftItem) {
                            leftItem.pos_x = leftItem.pos_x - 1; // Subtract 1 from pos_x of each item to the left
                            if (leftItem.pos_x < 0) {
                                leftItem.pos_x = 0;
                            }
                        }
                    }
                } else if (event.currentIndex < event.previousIndex) {
                    for (let i = event.currentIndex + 1; i < containerData.length; i++) {
                        const rightItem = containerData[i];
                        if (rightItem) {
                            rightItem.pos_x = i; // Set pos_x to the index of the item
                        }
                    }
                }
            }
            if (this.model.PlaceHolderDataMap) {
                for (let item of this.model.PlaceHolderDataMap?.get(+proc.htmlproc_keyno)?.get(1)!) {
                    this.model.colChanges.push(item.htmlproccol_keyno);
                }
            }
        }
    }


    /**
     * Handles the sections / procs being moved up and down using the handle
     * Currently not implemented
     * 
     * @param event
     */
    dropSection(event: CdkDragDrop<HtmlProcItem[]>) {
        console.log("dropped a section");
    }

    /**
     * Redirects to the page creating a new sub procedure
     * In the future this will handled directly inside the page
     * 
     * @param htmlproc_keyno
     */
    createNewSubProcedure(htmlproc_keyno: string) {
        this.stateService.go('htmlproc', { htmlreport_keyno: this.stateparams.htmlreport_keyno, htmlproc_keyno_belongto: htmlproc_keyno, htmlproc_keyno: '0' });
    }
    /**
     * Removes focus and shows the htmlPreview that is static
     */
    showAll() {
        this.getHtmlPreview();
        this.model.isFocus = false;
    }

    /**
     * Sets the keyno that is supposed to be focus
     * Goes into focusmode allowing the user to edit
     * 
     * @param keyno
     */
    toggleFocus(keyno: string) {
        this.focusedKeyno = keyno;
        this.model.isFocus = true;
    }

    /**
     * When the user changes the size of a form grid new placeholders have to be made
     * TODO: Make it so that a user cant write 0 as it is not productive and potentially confusing
     * 
     * @param htmlproc proc to be edited
     */
    updateFormGrid(htmlproc: HtmlProc) {
        // Ensure col_count and row_count are positive
        if (+htmlproc.col_count > 0 && +htmlproc.row_count > 0) {
            // Call the method to create placeholder data
            this.createPlaceholderData([htmlproc]);

            // Create a temporary map for storing HtmlProc and corresponding HtmlProcItem[]
            const tempMap: Map<HtmlProc, HtmlProcItem[]> = new Map();

            // Ensure the documentOverviewMap exists and contains the required data
            const overviewItems = this.model.documentOverviewMap?.get(htmlproc);
            if (overviewItems) {
                // Add the htmlproc and its items to the temporary map
                tempMap.set(htmlproc, overviewItems);
                this.placeProcccolsInPlaceholders(tempMap);
            }
          
        }
    }

    /**
     * Gets the table content preview for a table
     * If one already exists, replace it as there have been an update
     */
    async getTablePreview() {
        if (this.model.tablePreviewMap) {

            this.model.documentOverviewMap?.forEach(async (value, key) => {
                if (key.presentation_type === 'table') {
                    let result = await this.htmlReportService.getHtmlProcHtmlExcludeHeader(key.htmlproc_keyno);
                    this.model.tablePreviewMap?.set(key, this.base64.Decode(result[0].htmlstring));
                }
            });

        }
    }  

    /**
     * Saves a proc, used for the right side "save button" when focused.
     * Updates a table if the proc is of presentation_type table
     * @param htmlproc
     */
    async saveReport(htmlproc: HtmlProc) {
        await this.htmlReportService.saveHtmlProc(htmlproc);
        await this.save();

        console.log(htmlproc.presentation_type);
        if (htmlproc.presentation_type === 'table') {
            let result = await this.htmlReportService.getHtmlProcHtmlExcludeHeader(htmlproc.htmlproc_keyno);
            this.model.tablePreviewMap?.set(htmlproc, this.base64.Decode(result[0].htmlstring));
        }
    }

    /**
     * See if your keyno is focues, if it is make it visible
     * @param keyno
     * @returns
     */
    isFocused(keyno: string): boolean {
        if (this.model.isFocus) {
            return this.focusedKeyno === keyno;
        }
        else { return true; }
    }
    /**
     * Lets the user go to a procedure
     * Currently redirects to a JS page, should be refactored and changed
     * @param htmlproc_keyno
     * @param htmlproc_keyno_belongto
     */
    goToProcedure(htmlproc_keyno: string, htmlproc_keyno_belongto: string) {
        this.stateService.go('htmlproc', { htmlreport_keyno: this.stateparams.htmlreport_keyno, htmlproc_keyno_belongto: htmlproc_keyno_belongto, htmlproc_keyno: htmlproc_keyno });
    }

    /**
     * Allows the user to make a new procedure with the belong_to == 0
     * Currently directs to a JS page, should be refactored and changed
     */
    newProcedure() {
        this.stateService.go('htmlproc', { htmlreport_keyno: this.stateparams.htmlreport_keyno, htmlproc_keyno_belongto: 0, htmlproc_keyno: 0 });

    }
    /**
     * Html workaround for handling things like <br> so it does not display
     * @param html
     * @returns
     */
    sanitizeHTML(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    // Triggered when dragging starts
    dragStarted(htmlProc: HtmlProc): void {
        
        this.model.currentHtmlProc = htmlProc
        this.model.isDragging = true;
    }

    // Triggered when dragging ends
    dragEnded(): void {
        this.model.isDragging = false;

    }
    /**
     * Shows the context menu
     * To make changes to it, see the context-menu component
     * TODO: Make it appear next to the mouse
     * @param event
     * @param item
     */
    showContextMenu(event: MouseEvent, item: any) {
        event.preventDefault(); // Prevent the default context menu
        this.model.selectedItem = item;
        this.model.contextMenuVisible = true;

        this.model.contextMenuPosition = { x: event.clientX, y: event.clientY};
    }


    /**
     * Function called once an update is clicked on the context menu
     * @param updatedItem the proccol that has been updated
     */
    updateItem(updatedItem: any) {
        this.model.htmlProcColList.forEach((item, index) => {
            if (item.htmlproccol_keyno === updatedItem.htmlproccol_keyno) {
                this.model.htmlProcColList[index] = updatedItem;
                this.model.colChanges.push(item.htmlproccol_keyno);
                this.save();
            }
        });

    }

    /**
     * Supposed to change the order of the procs, not yet implemented
     * @param data
     */
    updateOrder(data: List[]) {
        throw new Error('Method not implemented.');
    }

    getFontStyle(item: any) {
        return '"' + item.font + '"' || 'sans-serif'
    }

    @HostListener('window:resize')
    onResize() {
        const wrapper = document.getElementById('htmlReportPreviewWrapper');
        if (wrapper && wrapper.clientWidth > 0) {
            this.model.scale = this.model.paperWidth < wrapper.clientWidth ? 1 : wrapper.clientWidth / this.model.paperWidth;
        }
    }
}

