(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("activityTypeDevTypeService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadActivityTypeDevTypeGet: function (activitytypedevtype_keyno) {
                return p2DataTaskService.call(1236, {
                    activitytypedevtype_keyno: activitytypedevtype_keyno
                });
            },
            save: function (deviation) {
                return p2DataTaskService.call(1237, deviation);
            }
        };

        return service;
    }]);
})();