(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('checkIn', {
        templateUrl: 'views/components/views/checkIn/checkIn.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'checkInService', function ($stateParams, $q, stateService, utilityService, checkInService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let checkInKeyno = $stateParams.checkin_keyno;

            vm.model = {
                bgCheckInPersonTypeSelection: {
                    selected: 'CHECKIN_PERSONTYPE_EXTERNAL',
                    buttons: [
                        { id: 'CHECKIN_PERSONTYPE_EXTERNAL', label: 'checkin_persontype_external', color: 'primary', onClick: () => vm.model.bgCheckInPersonTypeSelection.selected = 'CHECKIN_PERSONTYPE_EXTERNAL' },
                        { id: 'CHECKIN_PERSONTYPE_PERSON', label: 'checkin_persontype_person', color: 'primary', onClick: () => vm.model.bgCheckInPersonTypeSelection.selected = 'CHECKIN_PERSONTYPE_PERSON' }
                    ]
                },
                externalCellPhoneButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.external_cellphone = '' },
                    { id: '$phone' }
                ],
                edit: {},
                itemsListSkillsCertificates: [],
                lockedSave: false,
                lockedCheckInOut: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                const deferred = $q.defer();

                checkInService.getEdit({
                    argtype: argType,
                    argvalue: argValue,
                    checkin_keyno: checkInKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadCheckInSkillsCertificates();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCheckInSkillsCertificates = function () {
                if (utilityService.validateParmsValue(vm.model.edit.labour_no) !== true) return;

                return checkInService.listCheckInSkillsCertificates({ labour_no: vm.model.edit.labour_no }).then(function (list) {
                    angular.copy(list, vm.model.itemsListSkillsCertificates);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCheckInPersonTypeSelection = function (value) {
                vm.model.bgCheckInPersonTypeSelection.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                checkInService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON CHECK IN AND OUT PROCEDURE FUNCTION CALL

            vm.checkInOut = function () {
                vm.model.lockedCheckInOut = true;

                checkInService.checkInOutOther(vm.model.edit).then(function () {
                    vm.model.lockedCheckInOut = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedCheckInOut = false;
                });
            };

            // #endregion BUTTON CHECK IN AND OUT PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
