(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('activityTypeDevTypes', {
        templateUrl: 'views/components/views/activityTypeDevTypes/activityTypeDevTypes.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'activityTypeDevTypesService', function ($stateParams, $q, utilityService, activityTypeDevTypesService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListDeviationTypes: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                activityTypeDevTypesService.getEdit({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadDeviationTypes();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadDeviationTypes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;

                return activityTypeDevTypesService.listDeviationTypes({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListDeviationTypes);
                });
            };

            vm.$onInit = function () {
                loadSetting();
            };
		}]
	});
})();
