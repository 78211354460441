(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockTransferCreate', {
        templateUrl: 'views/components/views/stockTransferCreate/stockTransferCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'stockTransferCreateService', function ($stateParams, stateService, modalService, stockTransferCreateService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
    
            vm.model = {
                stockPlaceId: $stateParams.stockplace_id,
                listStockTransferCreateTrolleySelect: []
            };
    
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
    		stockTransferCreateService.loadStockTransferCreateTrolleySelectList(vm.model.stockPlaceId).then(function (data) {
    			angular.copy(data, vm.model.listStockTransferCreateTrolleySelect);
    		});
    		
            // ##################################
            // ## SAVE ITEM AND NAVIGATE PREVIOUS
            // ##################################
    
            vm.goToPreStockTransfers = function (item) {
                stockTransferCreateService.saveToPreStockTransferCreate(item.item_id).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        var ok = 'OK';
    
                        modalService.show({
                            type: 'warning',
                            title: 'Error',
                            message: data[0].errormessage,
                            buttons: [{
                                label: ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                    }
                });
            };
        }]
    });
})();