(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('docArticleSearch', {
        templateUrl: 'views/components/views/docArticleSearch/docArticleSearch.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'docArticleSearchService', 'rememberService', function ($timeout, stateService, utilityService, docArticleSearchService, rememberService) {
            var vm = this;
            
            var variableNames = {
                searchtext: ''
            };

            var timer = {
                searchtext: null
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadDocArticleSearches() }
                ],
    			setting: {},
    			itemsListDocArticles: []
            };

            vm.$onInit = function () {
                docArticleSearchService.subscribeToUpdate(getUpdatedArticles);
            };

            const getUpdatedArticles = function () {
                angular.copy(docArticleSearchService.selectedSearches, vm.model.itemsListDocArticles);
            }
            
    		docArticleSearchService.loadDocArticleSearchGet().then(function (data) {
    			vm.model.setting = angular.copy(data[0]);
                
    			loadDocArticleSearches();
    		});
            
            var loadDocArticleSearches = function (instant) {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                vm.model.itemsListDocArticles = [];
                
                docArticleSearchService.loadDocArticleSearchList(vm.model.setting.searchtext, instant).then(function (data) {
                    angular.copy(data, vm.model.itemsListDocArticles);
                });
            };

            var rememberFunc = function (id) {
                if (angular.isDefined(timer[id])) {
                    $timeout.cancel(timer[id]);
                }

                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                if (vm.model.setting.searchtext > '') {
                    timer[id] = $timeout(function () {
                        rememberService.remember(variableNames[id], variableValue);
                    }, 500);
                } else {
                    rememberService.remember(variableNames[id], variableValue);
                }
            };

            let groupTimeoutRemember = null;
            /**
             * Calls rememberFunc for 'searchtext', function is called after 2s, any subsequent call resets the timeout.
             * RememberFunc will be called instantly if 'instant' is 'true' 
             * @param {boolean} instant call rememberFunc instantly, clears the timeout
             */
            const doRememberSearchText = function (instant) {
                if (groupTimeoutRemember) {
                    $timeout.cancel(groupTimeoutRemember);
                }

                if (instant) {
                    rememberFunc('searchtext')
                } else {
                    groupTimeoutRemember = $timeout(function () {
                        rememberFunc('searchtext');
                    }, 2000);
                }
            }

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            doRememberSearchText(true);

                            vm.model.itemsListDocArticles = [];
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.searchDocArticleSearch = function () {
                doRememberSearchText(true);
                loadDocArticleSearches();
            };

            //vm.typeAheadSearch = function () {
            //    return docArticleSearchService.loadDocArticleSearchList(vm.model.setting.searchtext);
            //}

            //vm.onSearchtextCleared = function () {
            //    vm.model.setting.searchtext = '';
            //    vm.model.itemsListDocArticles = [];
            //};

            //vm.onSearchtextSelected = function (item) {
            //    vm.model.setting.searchtext = item.item_name;
            //};

            //vm.onSearchtextChanged = function (value) {
            //    vm.model.setting.searchtext = value;

            //    if (vm.model.setting.searchtext === '') {
            //        vm.onSearchtextCleared();
            //        doRememberSearchText();
            //    } else {
            //        let searchText = docArticleSearchService.selectedSearches.find((item) => item.item_name === vm.model.setting.searchtext);
            //        if (searchText) {
            //            vm.onSearchtextSelected(searchText);
            //            loadDocArticleSearches(true);
            //            doRememberSearchText(true); // remember instantly if a selection
            //        } else {
            //            doRememberSearchText();
            //        }
            //    }
            //};
        }]
    });
})();
