(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2DataTaskEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_datatask_edit: {
                manual_parm: '',
                mod_datetime: '',
                p2_datatask_keyno: '',
                proc_name: '',
                reg_datetime: '',
                is_report: '',
                set_parms: '',
                encoded_parms: '',
                encoded_data: ''
            },
            loadP2DataTaskEdit: function (p2_datatask_keyno) {
                var deferred = $q.defer();

                var parms_p2datatask_edit = {
                    method: 11,
                    parameters: {
                        p2_datatask_keyno: p2_datatask_keyno
                    }
                };

                $ihttp.post(parms_p2datatask_edit).then(function (data) {
                    service.p2_datatask_edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2DataTaskEdit: function () {
                var deferred = $q.defer();

                var parms_p2datatask_save = {
                    method: 12,
                    parameters: {
                        manual_parm: service.p2_datatask_edit.manual_parm,
                        is_report: service.p2_datatask_edit.is_report,
                        set_parms: service.p2_datatask_edit.set_parms,
                        encoded_parms: service.p2_datatask_edit.encoded_parms,
                        encoded_data: service.p2_datatask_edit.encoded_data,
                        mod_datetime: service.p2_datatask_edit.mod_datetime,
                        p2_datatask_keyno: service.p2_datatask_edit.p2_datatask_keyno,
                        proc_name: service.p2_datatask_edit.proc_name,
                        reg_datetime: service.p2_datatask_edit.reg_datetime
                    }
                };

                $ihttp.post(parms_p2datatask_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();