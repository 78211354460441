(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poPlansService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            operationInfo: {},
            dataSource: {
                records: []
            },
            loadDataSource: function (choice, period) {
                var deferred = $q.defer();

                var parms_get_plans_by_period = {
                    method: 424,
                    parameters: {
                        choice: choice,
                        period: period
                    }
                };

                $ihttp.post(parms_get_plans_by_period).then(function (data) {
                    service.dataSource.records = [];

                    angular.forEach(data, function (item) {
                        service.dataSource.records.push(item);
                    });

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            getOperation: function () {
                var deferred = $q.defer();

                var parms_getOperation = {
                    method: 457,
                    parameters: {}
                };

                $ihttp.post(parms_getOperation).then(function (data) {
                    service.operationInfo = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();