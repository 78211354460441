(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('bulkReturn', {
        templateUrl: 'views/components/views/bulkReturn/bulkReturn.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['stateService', 'bulkReturnService', 'dbPrinterService', 'printPreviewService', 'printService', 'ieScreenBlockService', 'modalService', function(stateService, bulkReturnService, dbPrinterService, printPreviewService, printService, ieScreenBlockService, modalService) {
            var vm = this;
            var reportDefinitions = {};

            vm.model = {
                prod_id: "",
                prod_id_scan: "",
                prod_name: "",
                batchid: "",
                po_keyno: "",
                purchline_keyno: "",
                quantity: 0,
                setting: {},
                selectListBusinessCos: [],
                itemsListStocks: [],
                printer: 0,
                printers: [],
                p2_reportdef_keyno: 0,
                p2_reportdef_test_keyno: 0,
                searching: false,
                destination: "1",            
                destinations: [
                    { code_id: "1", code_name: "Autostore" },
                    { code_id: "2", code_name: "Buffer" }
                ]
            };

            vm.loadPrinters = function () {
                dbPrinterService.list().then(function (data) {
                    angular.copy(data, vm.model.printers);

                    bulkReturnService.getDefaultPrinter()
                        .then(function (data) {
                            vm.model.printer = data[0].variablevalue;
                        });
                });
            };

            vm.loadPrinters();

            vm.getBulkReturnReport = function () {
                bulkReturnService.getBulkReturnReport()
                    .then(function (data) {
                        console.dir(data);
                        vm.model.p2_reportdef_keyno = data[0].p2_reportdef_keyno;
                        vm.model.p2_reportdef_test_keyno = data[0].p2_reportdef_test_keyno;

                        printPreviewService.loadP2ReportDef(vm.model.p2_reportdef_keyno).then(function (data) {
                            reportDefinitions = angular.copy(data[0]);
                        });

                        // Autofocus
                        var prodIdElement = document.getElementById("formBulkReturn").children[0].children[0].children[0].children[1].children[0].children[0];
                        prodIdElement.focus();
                    })
                    .catch(function (error) {
                        console.dir(error);
                    });
            };

            vm.getBulkReturnReport();

            vm.productLoopUp = function () {
                var prodIdElement = document.getElementById("formBulkReturn").children[0].children[0].children[0].children[1].children[0].children[0];
                var batchElement = document.getElementById("formBulkReturnStockBatch").children[0].children[0].children[0].children[1].children[0].children[0];

                bulkReturnService.productLoopUp(vm.model.prod_id_scan)
                    .then(function (data) {
                        vm.model.prod_id = data[0].prod_id;
                        vm.model.prod_name = data[0].prod_name;

                        batchElement.focus();
                    })
                    .catch(function () {
                        vm.model.prod_id_scan = "";
                        vm.model.prod_id = "";
                        vm.model.prod_name = "";

                        prodIdElement.value="";
                        prodIdElement.focus();
                    });            
            };

            vm.stockbatchLoopUp = function () {
                var batchElement = document.getElementById("formBulkReturnStockBatch").children[0].children[0].children[0].children[1].children[0].children[0];
                var quantityElement = document.getElementById("formBulkReturnQuantity").children[0].children[0].children[0].children[1].children[0].children[0];

                bulkReturnService.stockbatchLoopUp(vm.model.prod_id, vm.model.batchid)
                    .then(function (data) {
                        vm.model.batchid = data[0].batchid;
                        vm.model.po_keyno = data[0].po_keyno;
                        vm.model.purchline_keyno = data[0].purchline_keyno;

                        quantityElement.focus();
                    })
                    .catch(function (error) {
                        console.dir(error);

                        vm.model.batchid = "";
                        batchElement.value = "";

                        batchElement.focus();
                    });
            };

            vm.quantityVerification = function(){
                if ((vm.model.quantity > 0) && (vm.model.quantity < 10000)){
                    vm.confirm();        
                } else alert("Ugyldig antall");
            };

            vm.confirm = function(){
                if (vm.model.prod_id != '' && (vm.model.po_keyno > 0 || vm.model.purchline_keyno > 0) ) {             
                    if (vm.model.p2_reportdef_keyno > 0 && vm.model.printer > 0) {
                        bulkReturnService.createCrateForPrint({
                            prod_id: vm.model.prod_id,
                            po_keyno: vm.model.po_keyno,
                            purchline_keyno: vm.model.purchline_keyno,
                            quantity: vm.model.quantity,
                            destination: vm.model.destination
                        })
                            .then(function(data){
                                if (data[0].case_keyno > 0){
                                    bulkReturnService.setDefaultPrinter(vm.model.printer);
                                    
                                    ieScreenBlockService.start('message');
                                    ieScreenBlockService.setMessage('Printing');
                                
                                    var parms = {
                                        reportKeyno: vm.model.p2_reportdef_keyno,
                                        printerKeyno: vm.model.printer,
                                        parameters: {},
                                        printerProperties: {
                                            isLandscape: reportDefinitions.isLandscape,
                                            pageAutoSize: true,
                                            copies: 1
                                        }
                                    };
                                
                                    if (reportDefinitions.paper_size_id === 'custom') {
                                        parms.printerProperties.pageAutoSize = false;
                                        parms.printerProperties.scale = 'none';

                                        parms.printerProperties.paperSize = {
                                            width: reportDefinitions.width,
                                            height: reportDefinitions.height
                                        };
                                    }
                
                                    parms.parameters['case_keyno'] = data[0].case_keyno;
                
                                    printService.print(parms).then(function () {
                                        /*ieScreenBlockService.setMessage('Cleaning screen');
                                        
                                        vm.model.prod_id = "";
                                        vm.model.prod_id_scan = "";
                                        vm.model.prod_name = "";
                                        vm.model.batchid = "";
                                        vm.model.po_keyno = "";
                                        vm.model.purchline_keyno = "";
                                        vm.model.quantity = 0;

                                        var prodIdElement = document.getElementById("formBulkReturn").children[0].children[0].children[0].children[1].children[0].children[0];
                                        var batchElement = document.getElementById("formBulkReturnStockBatch").children[0].children[0].children[0].children[1].children[0].children[0];
                                        var quantityElement = document.getElementById("formBulkReturnQuantity").children[0].children[0].children[0].children[1].children[0].children[0];
                                        
                                        prodIdElement.value = '';
                                        batchElement.value = '';
                                        quantityElement.value = 0;
                                        prodIdElement.focus();
                                        */
                                        ieScreenBlockService.setMessage('Printing completed');
                                        ieScreenBlockService.stop();

                                        var d = Date.now();

                                        stateService.go('bulkreturn', {
                                            counter: d
                                        });
                                    });
                                } else {
                                    modalService.confirm({
                                        title: 'Mangler case_keyno',
                                        message: 'Mangler case_keyno',
                                        okLabel: 'OK',
                                        //cancelLabel: 'Avbryt'
                                    });
                                } 
                            })
                            .catch(function(error){
                                console.dir(error);
                            });
                    } else {
                        modalService.confirm({
                            title: 'Mangler rapport eller printer',
                            message: 'Mangler rapport eller printer',
                            okLabel: 'OK'
                        });
                    }                
                } else {
                    modalService.confirm({
                        title: 'Mangler prod_id, po_keyno eller purchline_keyno',
                        message: 'Mangler prod_id, po_keyno eller purchline_keyno',
                        okLabel: 'OK'
                    });
                }
            };

            vm.confirm_test = function(){          
                if (vm.model.p2_reportdef_test_keyno > 0 && vm.model.printer > 0){               
                    bulkReturnService.setDefaultPrinter(vm.model.printer);

                    var parms = {
                        reportKeyno: vm.model.p2_reportdef_test_keyno,
                        printerKeyno: vm.model.printer,
                        parameters: {},
                        printerProperties: {
                            isLandscape: reportDefinitions.isLandscape,
                            pageAutoSize: true,
                            copies: 1
                        }
                    };
                    
                    ieScreenBlockService.start('message');
                    ieScreenBlockService.setMessage('Executing test');

                    if (reportDefinitions.paper_size_id === 'custom') {
                        parms.printerProperties.pageAutoSize = false;
                        parms.printerProperties.scale = 'none';
                        parms.printerProperties.paperSize = {
                            width: reportDefinitions.width,
                            height: reportDefinitions.height
                        };
                    }

                    parms.parameters['case_keyno'] = '1';
                    ieScreenBlockService.setMessage('Printing');

                    printService.print(parms).then(function () {
                        ieScreenBlockService.setMessage('Test completed');
                        ieScreenBlockService.stop();
                    });

                    /*stateService.go('printdialogue',{
                        webpage_name: 'bulkreturn',
                        argtype: 'case_keyno',
                        argvalue: data[0].case_keyno,
                        p2_reportdef_keyno: data[0].p2_reportdef_keyno
                    });*/
                }
            };
		}]
	});
})();