import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { Style } from '@app/core/services/core-component.service';

@Component({
    selector: 'tt-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.css'],
})
export class CheckboxComponent<TData> extends FormFieldBaseComponent {
    /**
     * The data model of the component, default is `'1'` for checked, and `'0'` for unchecked.
     * If `ttTrueValue` or `ttFalseValue` is given, the model will be either of those.
     */
    @Input()
    get ttModel(): TData {
        return this._model === true ? this.ttTrueValue : this.ttFalseValue;
    }
    set ttModel(value: TData) {
        this._model = value === this.ttTrueValue;
    }
    _model = false;

    /**
     * Event emitted when the checkbox has a new value.
     */
    @Output()
    ttModelChange = new EventEmitter<TData>();

    /**
     * The true value used when the checkbox is checked, default is `'1'`.
     */
    @Input()
    ttTrueValue: any = '1';

    /**
     * The false value used when the checkbox is unchecked, default is `'0'`.
     */
    @Input()
    ttFalseValue: any = '0';

    /**
     * Ids of the elements in the component.
     */
    public id = {
        checkbox: crypto.randomUUID(),
    };

    public onModelChange(event: boolean) {
        this._model = event;
        this.ttModel = event === true ? this.ttTrueValue : this.ttFalseValue;

        this.ttModelChange.emit(this.ttModel);
    }

    public override style: Style = {
        checkbox: {},
    };

    public override setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, mainElement: 'checkbox' });
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        console.log('changes :>> ', changes);
        if (changes?.['ttModel']) {
            this.ttModel = changes['ttModel'].currentValue;
        }
    }
}
