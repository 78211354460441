(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('srFollowUps', {
        templateUrl: 'views/components/views/srFollowUps/srFollowUps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'ieScreenBlockService', 'watcherFactory', 'srFollowUpsService', 'rememberService', function ($timeout, stateService, utilityService, ieScreenBlockService, watcherFactory, srFollowUpsService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var watcher = watcherFactory.$create(vm);
            var onDestroy = [];

            var variableNames = {
                gb_srfollowups_main: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                filterval: '',
                show_sub_resources: ''
            };

		    var timer = {
		    	filterval: null
		    };

            var setMainView = function (id) {
                vm.model.bgSrFollowUpsMain.selected = vm.model.setting.gb_srfollowups_main = id;
            };

            vm.model = {
                bgSrFollowUpsMain: {
                    selected: 'OPEN',
                    buttons: [
                        { id: 'OPEN', label: 'gb_srfollowups_main_open', color: 'primary', onClick: { click: setMainView, id: 'OPEN' } },
                        { id: 'CLOSED', label: 'gb_srfollowups_main_closed', color: 'primary', onClick: { click: setMainView, id: 'CLOSED' } },
                        { id: 'ALL', label: 'gb_srfollowups_main_all', color: 'primary', onClick: { click: setMainView, id: 'ALL' } }
                    ]
                },
                btnListFilterVal: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                setting: {},
                itemsListFollowUps: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            srFollowUpsService.getSrFollowUps().then(function (data) {
                angular.copy(data[0], vm.model.setting);

                setMainView(vm.model.setting.gb_srfollowups_main);
                loadSrFollowUps();
            });

            var loadSrFollowUps = function () {
                ieScreenBlockService.start();

                if (utilityService.validateParmsValue(vm.model.setting.gb_srfollowups_main) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                vm.model.itemsListFollowUps = [];

                srFollowUpsService.listSrFollowUps(vm.model.setting).then(function (data) {
                    ieScreenBlockService.stop();
                    angular.copy(data, vm.model.itemsListFollowUps);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
		        if (angular.isDefined(timer[id])) {
		        	$timeout.cancel(timer[id]);
		        }

                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'gb_srfollowups_main':
                        if (utilityService.validateParmsValue(vm.model.setting.gb_srfollowups_main) !== true) return;

                        variableValue = vm.model.setting.gb_srfollowups_main;
                        break;
                    case 'date_fom':
                        if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;

                        variableValue = vm.model.setting.date_fom;
                        break;
                    case 'date_tom':
                        if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;

                        variableValue = vm.model.setting.date_tom;
                        break;
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;

                        variableValue = vm.model.setting.dateselector_index;
                        break;
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        variableValue = vm.model.setting.filterval;
                        break;
                    case 'show_sub_resources':
                        if (utilityService.validateParmsValue(vm.model.setting.show_sub_resources, true) !== true) return;

                        variableValue = vm.model.setting.show_sub_resources;
                        break;
                    default:
                        break;
                }

                if (angular.isDefined(vm.model.setting.filterval) && vm.model.setting.filterval > '') {
                    timer[id] = $timeout(function () {
                        rememberService.remember(variableNames[id], variableValue);
                    }, 2000);
                } else {
                    rememberService.remember(variableNames[id], variableValue);
                }
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP MULTI FUNCTIONS

            vm.onRadioButtonsChanged = function (value, id) {
                if (id.startsWith('vm.') !== true)
                    return;

                eval(id + " = '" + value + "'");

                if (id === 'vm.model.bgSrFollowUpsMain.selected') {
                    vm.model.setting.gb_srfollowups_main = value;

                    rememberFunc('gb_srfollowups_main');
                    loadSrFollowUps();
                }
            };

		    // #endregion BUTTON GROUP MULTI FUNCTIONS

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncFilterVal = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.filterval = '';
                        break;
                    default:
                        break;
                }
            };

		    // #endregion BUTTON LIST FUNCTIONS

            // #region WATCH FUNCTIONS

            watcher.$watch(function () { return vm.model.setting.date_fom; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                rememberFunc('date_fom');
            }, onDestroy);

            watcher.$watch(function () { return vm.model.setting.date_tom; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                rememberFunc('date_tom');
            }, onDestroy);

            watcher.$watch(function () { return vm.model.setting.dateselector_index; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                rememberFunc('dateselector_index');
            }, onDestroy);

            watcher.$watch(function () { return vm.model.setting.date_fomtom; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                loadSrFollowUps();
            }, onDestroy);

            watcher.$watch(function () { return vm.model.setting.filterval; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                rememberFunc('filterval');
            }, onDestroy);

            watcher.$watch(function () { return vm.model.setting.show_sub_resources; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                loadSrFollowUps();
                rememberFunc('show_sub_resources');
            }, onDestroy);

		    // #endregion WATCH FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

		    // #endregion DESTROY FUNCTION
        }]
    });
})();
