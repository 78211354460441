(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('stockLocationCtrl', {
        templateUrl: 'views/components/views/stockLocationCtrl/stockLocationCtrl.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'utilityService', 'modalService', 'translateService', 'stockLocationCtrlService', function ($stateParams, $timeout, stateService, utilityService, modalService, translateService, stockLocationCtrlService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var initLoadDone = false;
            var timerInitLoadDone = null;
            var timerStockLocationCtrlList = null;
            var timerSumQuantityControlled = null;
            var timerStockCountKeyno = null;
    		
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                stockKeyno: $stateParams.stock_keyno,
                edit: {},
                selectListStockCounts: [],
                inputListQuantities: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
            stockLocationCtrlService.loadStockLocationCtrlGet(vm.model.stockKeyno).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
                
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }
                
                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 100);
                
                loadStockCountsList();
                loadStockLocationCtrlList();
            });
            
            var loadStockCountsList = function () {
                if (angular.isDefined(vm.model.edit.stockplace_id)) {
                    stockLocationCtrlService.loadStockCountsList(vm.model.edit.stockplace_id, 'ALL').then(function (data) {
                        angular.copy(data, vm.model.selectListStockCounts);
                    });
                }
            };
            
            var loadStockLocationCtrlList = function () {
                if (angular.isDefined(timerStockLocationCtrlList)) {
                    $timeout.cancel(timerStockLocationCtrlList);
                }
    
                timerStockLocationCtrlList = $timeout(function () {
                    if (angular.isDefined(vm.model.edit.stockcount_keyno) && vm.model.edit.stockcount_keyno !== '') {
                        stockLocationCtrlService.loadStockLocationCtrlList(vm.model.edit).then(function (data) {
                            angular.copy(data, vm.model.inputListQuantities);
                            vm.recalcAll();
                        });
                    }
                }, 50);
            };
    
            // ###########################
            // ## REMEMBER VALUE FUNCTIONS
            // ###########################
            
            var rememberCompareWithStockCount = function () {
                var variableNameCompareWithStockCount = 'w_stocklocationctrl.compare.' + vm.model.edit.stockplace_id;
                
                if (angular.isDefined(variableNameCompareWithStockCount) && angular.isDefined(vm.model.edit.compare_with_stockcount) && initLoadDone === true) {
                    stockLocationCtrlService.remember(variableNameCompareWithStockCount, vm.model.edit.compare_with_stockcount);
                }
            };
            
            var rememberStockCountKeyno = function () {
                if (angular.isDefined(timerStockCountKeyno)) {
                	$timeout.cancel(timerStockCountKeyno);
                }
    
                var variableNameStockCountKeyno = 'w_stocklocationctrl.stockcount_keyno.' + vm.model.edit.stockplace_id;
    
                timerStockCountKeyno = $timeout(function () {
                    if (angular.isDefined(variableNameStockCountKeyno) && angular.isDefined(vm.model.edit.stockcount_keyno) && initLoadDone === true) {
                        stockLocationCtrlService.remember(variableNameStockCountKeyno, vm.model.edit.stockcount_keyno);
                    }
                }, 50);	
            };
    
            // ###################
            // ## RECALC FUNCTIONS
            // ###################
            
            vm.recalcAll = function () {
                vm.recalcSumQuantityStock();
                vm.recalcSumQuantityStockCount();
                vm.recalcSumQuantityControlled();
            };
            
            vm.recalcSumQuantityStock = function () {
                var recalcSum = 0;
                
                angular.forEach(vm.model.inputListQuantities, function (data) {
                    if (angular.isUndefined(data.quantity_stock) || data.quantity_stock === null || data.quantity_stock === '') {
                        recalcSum += 0;
                    } else {
                        recalcSum += parseFloat(data.quantity_stock);
                    }
                });
                
                vm.model.edit.sum_quantity_stock = recalcSum.toString();
            };
            
            vm.recalcSumQuantityStockCount = function () {
                var recalcSum = 0;
                
                angular.forEach(vm.model.inputListQuantities, function (data) {
                    if (angular.isUndefined(data.quantity_stockcount) || data.quantity_stockcount === null || data.quantity_stockcount === '') {
                        recalcSum += 0;
                    } else {
                        recalcSum += parseFloat(data.quantity_stockcount);
                    }
                });
                
                vm.model.edit.sum_quantity_stockcount = recalcSum.toString();
            };
            
            vm.recalcSumQuantityControlled = function () {
                if (angular.isDefined(timerSumQuantityControlled)) {
                    $timeout.cancel(timerSumQuantityControlled);
                }
    
                var recalcSum = 0;
    
                timerSumQuantityControlled = $timeout(function () {
                    angular.forEach(vm.model.inputListQuantities, function (data) {
                        if (angular.isUndefined(data.quantity_controlled) || data.quantity_controlled === null || data.quantity_controlled === '') {
                            recalcSum += 0;
                        } else {
                            recalcSum += parseFloat(data.quantity_controlled);
                        }
                    });
                    
                    vm.model.edit.sum_quantity_controlled = recalcSum.toString();
                }, 50);
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var mergeObj = vm.model.edit;
                mergeObj.records = vm.model.inputListQuantities;

                stockLocationCtrlService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'compare_with_stockcount':
                        if (utilityService.validateWatchValue(value, vm.model.edit.compare_with_stockcount) !== true) return;

                        vm.model.edit.compare_with_stockcount = value;

                        if (utilityService.validateParmsValue(vm.model.edit.compare_with_stockcount) !== true) return;

                        rememberCompareWithStockCount();
                        break;
                    case 'stockcount_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stockcount_keyno) !== true) return;

                        vm.model.edit.stockcount_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stockcount_keyno, true) !== true) return;

                        rememberStockCountKeyno();
                        loadStockLocationCtrlList();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'quantity_controlled':
                        vm.recalcSumQuantityControlled();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS
            
        }]
    });
})();