(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipmentGrpLang', {
        templateUrl: 'views/components/views/equipmentGrpLang/equipmentGrpLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'equipmentGrpLangService', function ($stateParams, stateService, modalService, equipmentGrpLangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            vm.model = {
                equipmentGrpId: $stateParams.equipmentgrp_id,
                inputListEquipmentGroups: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            equipmentGrpLangService.listEquipmentGrpLangs(vm.model.equipmentGrpId).then(function (data) {
            	angular.copy(data, vm.model.inputListEquipmentGroups);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE PROCEDURE FUNCTION CALL

            vm.saveEquipmentGrpLang = function () {
                //vm.model.lockedSave = true;

                var mergeObj = {
                    records: vm.model.inputListEquipmentGroups
                };

                equipmentGrpLangService.saveObj(mergeObj).then(function (data) {
                    stateService.back();
                    //if (data[0].errorcode !== '0') {
                    //    modalService.show({
                    //        type: 'warning',
                    //        title: 'Varsel',
                    //        message: data[0].errormessage,
                    //        buttons: [{
                    //            label: 'OK',
                    //            cssClass: 'btn-warning',
                    //            action: function (dialogItself) {
                    //                dialogItself.close();
                    //                vm.model.lockedSave = false;
                    //            }
                    //        }]
                    //    });
                    //} else {
                    //    stateService.back();
                    //    vm.model.lockedSave = false;
                    //}
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
            	angular.forEach(onDestroy, function (fn) {
            		if (angular.isFunction(fn) === true) {
            			fn();
            		}
            	});
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
