(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectSop', {
        templateUrl: 'views/components/views/serviceObjectSop/serviceObjectSop.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'serviceObjectSopService', 'typeaheadService', function ($stateParams, stateService, utilityService, modalService, translateService, serviceObjectSopService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                serviceObjectSopKeyNo: $stateParams.serviceobjectsop_keyno,
                sopClButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('sopcls', { sop_keyno: vm.model.edit.sop_keyno }) }
                ],
                productIdButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => {
                            serviceObjectSopService.saveObj(vm.model.edit).then(function (response) {
                                if (response[0].errorcode !== '0') {
                                    modalService.show({
                                        type: 'warning',
                                        title: translations.error,
                                        message: response[0].errormessage,
                                        buttons: [{
                                            label: translations.ok,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                } else {
                                    stateService.go('product', { prod_id: vm.model.edit.prod_keyno });
                                }
                            });
                        }
                    }
                ],
                edit: {},
                selectListChoices: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##
    
            serviceObjectSopService.loadServiceObjectSopGet(vm.model.serviceObjectSopKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.edit);
    
                loadSopClSelects();
            });
    
            var loadSopClSelects = function () {
                if (utilityService.validateParmsValue(vm.model.edit.sop_keyno) !== true) return;
    
                vm.model.selectListChoices = [];
    
                serviceObjectSopService.loadSopClSelectList(vm.model.edit.sop_keyno).then(function (data) {
                    angular.copy(data, vm.model.selectListChoices);
                });
            };
    
            var changedServiceObjectSops = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
    
                serviceObjectSopService.changedServiceObjectSop(vm.model.edit.prod_id).then(function (data) {
                    vm.model.edit.prod_keyno = data[0].prod_keyno;
                    vm.model.edit.url_picture = data[0].url_picture;
                    vm.model.edit.nbr_of_spareparts = data[0].nbr_of_spareparts;
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'picturebanks':
                    case 'pictures':
                    case 'documents':
                        go({
                            argtype: 'serviceobjectsop_keyno',
                            argvalue: vm.model.edit.serviceobjectsop_keyno
                        });
                        break;
                    case 'productlinks':
                        serviceObjectSopService.saveObj(vm.model.edit).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                go({
                                    argtype: 'prod_id',
                                    argvalue: vm.model.edit.prod_keyno,
                                    productlinktype_keyno: '1'
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchProduct({ prod_name: value || '' });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.edit.prod_id = item?.item_id ?? '';
                    vm.model.edit.prod_name = item?.item_name ?? '';

                    changedServiceObjectSops();
                } else {
                    vm.onClear();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChanged = function (value, item) {
                vm.model.edit.prod_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.prod_id = item.item_id;
                    }
                } else {
                    vm.onClear();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                vm.model.edit.prod_id = '';
                vm.model.edit.prod_name = '';
                vm.model.edit.prod_keyno = '';
                vm.model.edit.url_picture = '';
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                serviceObjectSopService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
        }]
    });
})();
