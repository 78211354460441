(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('businesscoaccounting', {
        templateUrl: 'views/components/views/businesscoaccounting/businesscoaccounting.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'businessCoAccountingService', 'ttGridFactory', 'externalApisService', 'economyService', 'typeaheadService', function ($stateParams, $q, stateService, modalService, translateService, businessCoAccountingService, ttGridFactory, externalApisService, economyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoNo = $stateParams.businessco_no;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                externalApisButtons1: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('externalapis') }
                ],
                externalApisButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('externalapis') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('externalapi', { external_api_keyno: vm.model.edit.bankintegration_external_api_keyno }) }
                ],
                edit: {},
                selectListExternalApis: [],
                selectListSalaryTypes: [],
                gridReady: false,
                gridBankAccountReady: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_businesscoaccounting_acremltr_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'acremltr_new',
                                text: 'acremltr_new',
                                func: function () {
                                    stateService.go('acremltr', {
                                        businessco_no: vm.model.edit.businessco_no,
                                        acremltr_keyno: '0'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridBankAccount = new ttGridFactory({
                rememberId: 'businessco_bankaccount_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({
                wrapping: true
            }).setCustomToolbarButtons([{
                name: 'businessco_bankaccount_new',
                text: 'businessco_bankaccount_new',
                func: function () {
                    stateService.go('businessco_bankaccount', {
                        businessco_no: vm.model.edit.businessco_no,
                        businessco_bankaccount_keyno: '0'
                    });
                },
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();
                
            	businessCoAccountingService.getBusinessCoAccounting({ businessco_no: businessCoNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadGrid(),
                        loadGridBankAccount()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadExternalApis = function () {
                externalApisService.listExternalApis().then(function (result) {
                    angular.copy(result, vm.model.selectListExternalApis);
                });
            };

            let loadSalaryTypes = function () {
                economyService.listSalaryTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListSalaryTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3091,
                    parameters: {
                        businessco_no: vm.model.edit.businessco_no
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridBankAccount = function () {
                vm.gridBankAccount.dataTask.loadData = {
                    method: 3354,
                    parameters: {
                        businessco_no: vm.model.edit.businessco_no
                    }
                };

                vm.model.gridBankAccountReady = true;

                if (vm.gridBankAccount.gridfunc !== null) vm.gridBankAccount.gridfunc.read();
            };

            //loadGridBankAccount();

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.lookUpSearchAccounts({
                    searchtext: value || '',
                    businessco_no: vm.model.edit.businessco_no,
                    onlyaccounttype: 'a'
                });
            };

            vm.typeaheadSearchAgio = function (value) {
                return typeaheadService.lookUpSearchAccounts({
                    searchtext: value || '',
                    businessco_no: vm.model.edit.businessco_no,
                    onlyaccounttype: 'a'
                });
            };

            vm.typeaheadSearchDisAgio = function (value) {
                return typeaheadService.lookUpSearchAccounts({
                    searchtext: value || '',
                    businessco_no: vm.model.edit.businessco_no,
                    onlyaccounttype: 'a'
                });
            };

            vm.typeaheadSearchBankFees = function (value) {
                return typeaheadService.lookUpSearchAccounts({
                    searchtext: value || '',
                    businessco_no: vm.model.edit.businessco_no,
                    onlyaccounttype: 'a'
                });
            };

            vm.typeaheadSearchOcr = function (value) {
                return typeaheadService.lookUpSearchAccounts({
                    searchtext: value || '',
                    businessco_no: vm.model.edit.businessco_no,
                    onlyaccounttype: 'a'
                });
            };

            vm.typeaheadSearchAccountNameRoundOff = function (value) {
                return typeaheadService.lookUpSearchAccounts({
                    searchtext: value || '',
                    businessco_no: vm.model.edit.businessco_no,
                    onlyaccounttype: 'a'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.edit.freight_account_no = item?.item_id ?? '0';
                    vm.model.edit.freight_account_name = item?.item_name ?? '';
                } else {
                    vm.onClear();
                }
            };

            vm.onSelectedAgio = function (item) {
                if (item) {
                    vm.model.edit.account_no_agio = item?.item_id ?? '0';
                    vm.model.edit.account_name_agio = item?.item_name ?? '';
                } else {
                    vm.onClearAgio();
                }
            };

            vm.onSelectedDisAgio = function (item) {
                if (item) {
                    vm.model.edit.account_no_disagio = item?.item_id ?? '0';
                    vm.model.edit.account_name_disagio = item?.item_name ?? '';
                } else {
                    vm.onClearDisAgio();
                }
            };

            vm.onSelectedBankFees = function (item) {
                if (item) {
                    vm.model.edit.account_no_bank_fees = item?.item_id ?? '0';
                    vm.model.edit.account_name_bank_fees = item?.item_name ?? '';
                } else {
                    vm.onClearBankFees();
                }
            };

            vm.onSelectedOcr = function (item) {
                if (item) {
                    vm.model.edit.account_no_ocr = item?.item_id ?? '0';
                    vm.model.edit.account_name_ocr = item?.item_name ?? '';
                } else {
                    vm.onClearOcr();
                }
            };

            vm.onSelectedAccountNameRoundOff = function (item) {
                if (item) {
                    vm.model.edit.account_no_roundoff = item?.item_id ?? '0';
                    vm.model.edit.account_name_roundoff = item?.item_name ?? '';
                } else {
                    vm.onClearAccountNameRoundOff();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChanged = function (value, item) {
                vm.model.edit.freight_account_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.freight_account_no = item.item_id;
                    }
                } else {
                    vm.onClear();
                }
            };

            vm.onChangedAgio = function (value, item) {
                vm.model.edit.account_name_agio = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.account_no_agio = item.item_id;
                    }
                } else {
                    vm.onClearAgio();
                }
            };

            vm.onChangedDisAgio = function (value, item) {
                vm.model.edit.account_name_disagio = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.account_no_disagio = item.item_id;
                    }
                } else {
                    vm.onClearDisAgio();
                }
            };

            vm.onChangedBankFees = function (value, item) {
                vm.model.edit.account_name_bank_fees = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.account_no_bank_fees = item.item_id;
                    }
                } else {
                    vm.onClearBankFees();
                }
            };

            vm.onChangedOcr = function (value, item) {
                vm.model.edit.account_name_ocr = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.account_no_ocr = item.item_id;
                    }
                } else {
                    vm.onClearOcr();
                }
            };

            vm.onChangedAccountNameRoundOff = function (value, item) {
                vm.model.edit.account_name_roundoff = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.account_no_roundoff = item.item_id;
                    }
                } else {
                    vm.onClearAccountNameRoundOff();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                vm.model.edit.freight_account_no = '0';
                vm.model.edit.freight_account_name = '';
            };

            vm.onClearAgio = function () {
                vm.model.edit.account_no_agio = '0';
                vm.model.edit.account_name_agio = '';
            };

            vm.onClearDisAgio = function () {
                vm.model.edit.account_no_disagio = '0';
                vm.model.edit.account_name_disagio = '';
            };

            vm.onClearBankFees = function () {
                vm.model.edit.account_no_bank_fees = '0';
                vm.model.edit.account_name_bank_fees = '';
            };

            vm.onClearOcr = function () {
                vm.model.edit.account_no_ocr = '0';
                vm.model.edit.account_name_ocr = '';
            };

            vm.onClearAccountNameRoundOff = function () {
                vm.model.edit.account_no_roundoff = '0';
                vm.model.edit.account_name_roundoff = '';
            };

		    // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                businessCoAccountingService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadExternalApis();
                loadSalaryTypes();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
