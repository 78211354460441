import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DATE_FORMATS } from '@app/core/models/date-adapter';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
    selector: 'tt-bank-reconciliation-item',
    templateUrl: './bank-reconciliation-item.component.html',
    styleUrls: ['./bank-reconciliation-item.component.css'],
})
export class BankReconciliationItemComponent implements OnChanges {
    @Input()
    public ttDate?: string | Date;
    public date: string = '';

    @Input()
    public ttNote: string = '';

    @Input()
    public ttSubNote: string = '';

    @Input()
    public ttAmount?: number;
    public amount: string = '';

    @Input()
    public ttHighlight: boolean = false;

    @Input()
    public ttSelected: boolean = false;

    @Output()
    public ttSelectedChange = new EventEmitter<boolean>();

    public style: Style = {
        text: {
            fontSize: '12px',
        },
    };

    constructor(private layout: LayoutService) {
        this.layout.layoutChanged.subscribe((info) => {
            if (info) {
                console.log('setting layoutstuff');
                this.style['text'].fontSize = info?.fontSizes.textSize;
            }
        });
    }

    public toggleSelection(event: MouseEvent) {
        this.ttSelected = !this.ttSelected;
        this.ttSelectedChange.emit(this.ttSelected);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes['ttDate']) {
            if (!!changes['ttDate']?.currentValue && new Date(changes['ttDate'].currentValue).toString() !== 'Invalid Date') {
                this.date = formatDate(changes['ttDate'].currentValue, DATE_FORMATS.display.dateInput.format, DATE_FORMATS.display.dateInput.language || navigator.language || navigator.languages[0] || 'nb-NO');
            } else {
                this.date = '';
            }
        }

        if (!!changes['ttAmount']) {
            if (changes['ttAmount'].currentValue !== undefined && changes['ttAmount'].currentValue !== null && changes['ttAmount'].currentValue !== '' && !isNaN(Number(changes['ttAmount'].currentValue))) {
                const formatter = new Intl.NumberFormat('nb-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
                this.amount = formatter.format(changes['ttAmount'].currentValue).replaceAll(' ', ' ').replaceAll('−', '-');
            } else {
                this.amount = '';
            }
        }
    }
}
