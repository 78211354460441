import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, lastValueFrom, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../../services/appconfig.service';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
    constructor(private configService: AppConfigService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const modifiedReq = req.clone({
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                'X-INSOFT-PATH': 'database-' + this.configService.settings.clientId,
                'X-INSOFT-ID': this.configService.clientSessionId
            })
        });

        return next.handle(modifiedReq);
    }
}
