(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('documents', {
        templateUrl: 'views/components/views/documents/documents.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'documentsService', 'fileService', 'docarcService', 'watcherFactory', function ($stateParams, $q, utilityService, documentsService, fileService, docarcService, watcherFactory) {

            // #########
            // VARIABLES
            // #########

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

			var watcher = watcherFactory.$create(vm);
            
            vm.model = {
                bgDocuments: {
                    selected: 'd',
                    buttons: [
                        { id: 'd', label: 'documents_documents', color: 'primary', onClick: () => vm.model.bgDocuments.selected = 'd' },
                        { id: 'p', label: 'documents_pictures', color: 'primary', onClick: () => vm.model.bgDocuments.selected = 'p' },
                        { id: 'v', label: 'documents_videos', color: 'primary', onClick: () => vm.model.bgDocuments.selected = 'v' }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {
                    searchtext: ''
                },
                uploadFiles: [],
                fileNameSyntax: {},
                selectedFileNames: '',
                itemsListDocuments: [],
                lockedUpload: true
            };
            
            watcher.$watch(function () {
                return vm.model.uploadFiles;
            }, function () {
                var files = '';
                
                for (var i = 0; i < vm.model.uploadFiles.length; i++) {
                    if (i > 0) {
                        files += ', ';
                    }
                    
                    files += vm.model.uploadFiles[i].name;
                }
                
                vm.model.selectedFileNames = files;
            });
            
            let loadSetting = function () {
                const deferred = $q.defer();

                documentsService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    loadDocuments();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadDocuments = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgDocuments.selected) !== true) return;

                vm.model.itemsListDocuments = [];
                
                return documentsService.listDocuments({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue,
                    searchtext: '',
                    doctype: vm.model.bgDocuments.selected,
                    onlyFilenames: '0',
                    glyphicon_select: '1'
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListDocuments);
                });
            };

            fileService.getFileNameSyntax(argType, argValue).then(function (data) {
                vm.model.fileNameSyntax = data;
                vm.model.lockedUpload = false;
            });
            
            vm.uploadFiles = function () {
                if (vm.model.uploadFiles.length < 1) return;
                if (vm.model.lockedUpload === true) return;
                
                vm.model.lockedUpload = true;
                
                var addToDocarc = function(data) {
                    if (data.fileItemKeyno < 1) return $q.resolve();
                    
                    var docarc = {
                        contentType: data.contentType || '',
                        filename: data.fileName,
                        localFileName: data.localFileName || '', // BJS 20211019
                        originalFileName: data.originalFileName || '',
                        originalDateTime: data.originalDateTime || '',
                        argType: argType || '',
                        argValue: argValue || '',
                        size: data.size || 0,
                        checksum: data.checksum || '',
                        latitude: data.latitude || 0,
                        longitude: data.longitude || 0,
                        altitude: data.altitude || 0,
                        heading: data.heading || 0,
                        checksum_md5: data.checksum_md5 || 0
                    };
                    
                    var daDeferred = $q.defer();
                    
                    docarcService.add(docarc, { checksum_md5: data.checksum_md5 }).then(function () {
                        daDeferred.resolve();
                    });
                    
                    return daDeferred.promise;
                };
                
                fileService.uploadFiles({
                    files: vm.model.uploadFiles,
                    preprocess: function (fileName, file) {
                        var ppDeferred1 = $q.defer();
                        
                        fileService.hash(file).then(function (checksum_md5) {
                            docarcService.exists(fileName, checksum_md5.hash, argType, argValue).then(function (data) {
                                ppDeferred1.resolve({ cancelUpload: data.exists, checksum_md5: checksum_md5.hash });
                            });
                        });
                        
                        return ppDeferred1.promise;
                    },
                    postprocess: function (data) {
                        var ppDeferred2 = $q.defer();
                        
                        addToDocarc(data).then(function () {
                            ppDeferred2.resolve();
                        });
                        
                        return ppDeferred2.promise;
                    }
                }).then(function () {
                    vm.model.uploadFiles.length = 0;
                    vm.model.selectedFileNames = '';
                    vm.model.lockedUpload = false;
                    
                    loadDocuments();
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgDocuments = function (value) {
                vm.model.bgDocuments.selected = value;

                loadDocuments();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
