(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicView', {
        templateUrl: 'views/components/dynamics/views/ttDynamicView/ttDynamicView.template.html',
        controllerAs: 'vm',
        bindings: {
            route: '<'
        },
        controller: ['$q', '$scope', '$compile', '$uibModal', 'ttDynamicViewService', 'ttDatasourceManager', 'eventService', function ($q, $scope, $compile, $uibModal, ttDynamicViewService, ttDatasourceManager, eventService) {
            var vm = this;

            let container = null;
            let onDestroy = [];

            vm.dynamicOptions = {
                instance_id: uuid(),
                route_id: '',
                is_root: true
            };

            let load = function () {
                ttDynamicViewService.init(vm.route).then(function (response) {
                    var promises = [];

                    angular.forEach(response.datasources, function (datasource) {
                        promises.push(ttDatasourceManager.add(vm.dynamicOptions.instance_id, datasource));
                    });

                    $q.all(promises).then(function (response) {
                        angular.forEach(response, function (r) {
                            onDestroy.push(r);
                        });

                        ttDatasourceManager.postInit(vm.dynamicOptions.instance_id);
                    });

                    let dynamicElement = $compile('<tt-dynamic tt-options="vm.dynamicOptions"></tt-dynamic>')($scope);

                    if (container === null) {
                        container = angular.element(document.getElementById(vm.dynamicOptions.instance_id));
                    } else {
                        container.empty();
                    }

                    container.append(dynamicElement);
                });
            };

            vm.$onInit = function () {
                vm.dynamicOptions.route_id = vm.route;

                load();
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (d) {
                    if (angular.isFunction(d)) {
                        d();
                    }
                });
            };
        }]
    });
})();