(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('paymentLang', {
        templateUrl: 'views/components/views/paymentLang/paymentLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'paymentLangService', function ($stateParams, stateService, utilityService, paymentLangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var paymentId = $stateParams.payment_id;

            vm.model = {
                //setting: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            //paymentLangService.getPaymentLang({ payment_id: paymentId }).then(function (result) {
            //	angular.copy(result[0], vm.model.setting);
            //});

            // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();