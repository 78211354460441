(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory8Lang', {
        templateUrl: 'views/components/views/textileModelCategory8Lang/textileModelCategory8Lang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'textileModelCategory8LangService', function ($stateParams, stateService, textileModelCategory8LangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var textileModelCategory8Keyno = $stateParams.textilemodelcategory8_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory8LangService.listTextileModelCategory8Languages({ textilemodelcategory8_keyno: textileModelCategory8Keyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                textileModelCategory8LangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();