(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sops', {
        templateUrl: 'views/components/views/sops/sops.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'sopsService', function(stateService, sopsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                itemsListFields: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            sopsService.loadSopsList().then(function (data) {
                angular.copy(data, vm.model.itemsListFields);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'sop':
                        go({
                            sop_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();