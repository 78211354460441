(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("prePickCreateService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadPrePickCreateGet: function (argtype, argvalue) {
                return p2DataTaskService.call(1052, {
                    argtype: argtype,
                    argvalue: argvalue
				});
			},
            saveToPrePickCreate: function (argtype, argvalue, stocklocation_keyno) {
                return p2DataTaskService.call(1054, {
                    argtype: argtype,
                    argvalue: argvalue,
                    stocklocation_keyno: stocklocation_keyno
                });
            },
            loadSelectStockLocationPrePickList: function (getPrePickCreate) {
                return p2DataTaskService.call(1053, getPrePickCreate);
            }
        };

        return service;
    }]);
})();