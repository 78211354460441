(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicEditorService", ['$ihttp', function($ihttp) {
        var service = {
            delete: function (tt_dynamic_keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'deleteDynamic',
                        tt_dynamic_keyno: tt_dynamic_keyno
                    }
                });
            },

            generateDynamicsFromDatasource: function (tt_datasource_keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'generateDynamicsFromDatasource',
                        tt_datasource_keyno: tt_datasource_keyno
                    }
                });
            }
        }

        return service;
    }]);
})();
