(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('payouts', {
        templateUrl: 'views/components/views/payouts/payouts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'modalService', 'payoutsService', 'logisticService', 'rememberService', 'translateService', function (stateService, utilityService, modalService, payoutsService, logisticService, rememberService, translateService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let variableNames = {
                businessco_no: '',
                bg_payouts_main: ''
            };

            let translations = {
            	error: '',
                ok: '',
                message: ''
            };

            let setPayoutsMainView = function (id) {
                vm.model.bgPayoutsMain.selected = vm.model.setting.bg_payouts_main = id;

                if (vm.model.bgPayoutsMain.selected === 'PAYOUT') {
                    vm.model.gridPayoutReady = true;
                } else {
                    vm.model.gridStatusReady = true;
                }
            };

            vm.model = {
                bgPayoutsMain: {
                    selected: 'PAYOUT',
                    buttons: [
                        { id: 'PAYOUT', label: 'bg_payouts_main_payout', color: 'primary', onClick: { click: setPayoutsMainView, id: 'PAYOUT' } },
                        { id: 'STATUS', label: 'bg_payouts_main_status', color: 'primary', onClick: { click: setPayoutsMainView, id: 'STATUS' } }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListBankAccounts: [],
                gridPayoutReady: false,
                gridStatusReady: false,
                paying: false,
                settingBack: false,
                gettingStatusAndBook: false
            };

            vm.gridPayout = {
                dataTask: {
                    rememberId: 'w_payouts_payout_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        minHeight: '500px'
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionPayoutFunc(data.data); },
                gridfunc: null
            };

            const optionPayoutFunc = function (data) {
                //console.log('optionPayoutFunc');
                //console.dir(data);

                if (angular.isDefined(data.func) && data.func === 'CheckboxBoxClick') {
                    if (data.dataItem.is_selected === true) {
                        data.dataItem.is_selected_rem_amount_invval = data.dataItem.rem_amount_invval;
                    } else {
                        data.dataItem.is_selected_rem_amount_invval = 0;
                    }

                    vm.gridPayout.gridfunc.refresh();
                    vm.gridPayout.gridfunc.refreshAggregates();
                } else if (angular.isDefined(data.func) && data.func === 'CheckboxHeaderClick') {
                    angular.forEach(data.data, function (dataItem) {
                        if (dataItem.is_selected === true) {
                            dataItem.is_selected_rem_amount_invval = dataItem.rem_amount_invval;
                        } else {
                            dataItem.is_selected_rem_amount_invval = 0;
                        }
                    });

                    vm.gridPayout.gridfunc.refresh();
                    vm.gridPayout.gridfunc.refreshAggregates();
                }
            };

            vm.gridStatus = {
                dataTask: {
                    rememberId: 'w_payouts_status_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        minHeight: '500px'
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadSetting = function () {
                payoutsService.getPayouts().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    loadBankAccounts();

                    vm.gridPayout.dataTask.loadData = {
                        method: 2835,
                        parameters: vm.model.setting
                    };

                    vm.gridStatus.dataTask.loadData = {
                        method: 2836,
                        parameters: vm.model.setting
                    };

                    setPayoutsMainView(vm.model.setting.bg_payouts_main);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadBankAccounts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return payoutsService.listBankAccounts({ businessco_no: vm.model.setting.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListBankAccounts);
                });
            };

            let loadGrid = function () {
                if (vm.model.bgPayoutsMain.selected === 'PAYOUT') {
                    vm.gridPayout.gridfunc.rebind();
                } else {
                    vm.gridStatus.gridfunc.rebind();
                }
            };

            let retrieveBusinessco = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return payoutsService.changeBusinessCo({ businessco_no: vm.model.setting.businessco_no }).then(function (response) {
                    vm.model.setting.businessco_bankaccount_keyno = response[0].businessco_bankaccount_keyno;

                    if (vm.model.setting.businessco_bankaccount_keyno > 0) loadGrid();
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                        case 'bg_payouts_main':
                            variableNames[key] = 'w_accountingprocessing' + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'bg_payouts_main':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        vm.model.setting.businessco_bankaccount_keyno = '0';

                        rememberFunc('businessco_no');
                        retrieveBusinessco();
                        loadBankAccounts();
                        //loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_bankaccount_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_bankaccount_keyno) !== true) return;

                        vm.model.setting.businessco_bankaccount_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_bankaccount_keyno) !== true) return;

                        if (vm.model.setting.businessco_bankaccount_keyno > 0) loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPayoutsMain = function (value) {
                vm.model.bgPayoutsMain.selected = value;
                vm.model.setting.bg_payouts_main = vm.model.bgPayoutsMain.selected;

                rememberFunc('bg_payouts_main');
                loadGrid();
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON PAY SELECTED PROCEDURE FUNCTION CALL

            vm.payChanges = function () {
                vm.model.paying = true;

                let dirtyRows = vm.gridPayout.gridfunc.getDirtyRows();
                let selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({
                        payment_type: item.payment_type,
                        payment_keyno: item.payment_keyno
                    });
                });

                let mergeObj = angular.copy(vm.model.setting);
                mergeObj.records = selectedItems;

                payoutsService.paySelected(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.paying = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.paying = false;
                        vm.gridPayout.gridfunc.rebind();
                    }
                });
            };

		    // #endregion BUTTON PAY SELECTED PROCEDURE FUNCTION CALL

            // #region BUTTON REVERSE SELECTED PROCEDURE FUNCTION CALL

            vm.setBackChanges = function () {
                vm.model.settingBack = true;

                let dirtyRows = vm.gridStatus.gridfunc.getDirtyRows();
                let selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({
                        payment_type: item.payment_type,
                        payment_keyno: item.payment_keyno
                    });
                });

                let mergeObj = angular.copy(vm.model.setting);
                mergeObj.records = selectedItems;

                payoutsService.setBackSelected(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.settingBack = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.settingBack = false;
                        vm.gridStatus.gridfunc.rebind();
                    }
                });
            };

		    // #endregion BUTTON REVERSE SELECTED PROCEDURE FUNCTION CALL

            // #region BUTTON GET STATUS AND BOOK PROCEDURE FUNCTION CALL

            vm.getStatusAndBookChanges = function () {
                vm.model.gettingStatusAndBook = true;

                payoutsService.getStatusAndBook(vm.model.setting).then(function (response) {
                    switch (response[0].errorcode) {
                        case '-1':
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.gettingStatusAndBook = false;
                                        vm.gridStatus.gridfunc.rebind();
                                    }
                                }]
                            });
                            break;
                        case '1':
                            modalService.show({
                                type: 'success',
                                title: translations.message,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-success',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.gettingStatusAndBook = false;
                                        vm.gridStatus.gridfunc.rebind();
                                    }
                                }]
                            });
                            break;
                        default:
                            vm.model.gettingStatusAndBook = false;
                            vm.gridStatus.gridfunc.rebind();
                            break;
                    }
                });
            };

            // #endregion BUTTON GET STATUS AND BOOK PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
