(function () {
    'use strict';

    angular.module("imApp").factory("textileModelCategory3Service", ['$ihttp', function ($ihttp) {
        var service = {
            getTextileModelCategory3: function (parms) {
                return $ihttp.post({
                    method: 2676,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2677,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();