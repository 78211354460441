(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ediExport', {
        templateUrl: 'views/components/views/ediExport/ediExport.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'base64', 'ediExportService', function($stateParams, base64, ediExportService) {
            var vm = this;
            
            vm.model = {
                bgEdiExportMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_ediexport_main_details', item_func: 'DETAILS' },
                        { item_id: '1', item_name: 'gb_ediexport_main_content', item_func: 'CONTENT' },
                        { item_id: '2', item_name: 'gb_ediexport_main_functions', item_func: 'FUNCTIONS' }
                    ]
                },
                ediExportKeyno: $stateParams.edi_export_keyno,
                getEdiExport: {},
                listEdiDestination: [],
                listExportProc: []
            };
            
            ediExportService.loadEdiExportGet(vm.model.ediExportKeyno).then(function (data) {
                vm.model.getEdiExport = angular.copy(data[0]);
                vm.model.getEdiExport.filecontent = base64.urldecode(vm.model.getEdiExport.filecontent);
            });
            
            ediExportService.loadEdiDestinationList().then(function (data) {
                angular.copy(data, vm.model.listEdiDestination);
            });
            
            ediExportService.loadExportProcList().then(function (data) {
                angular.copy(data, vm.model.listExportProc);
            });

            vm.selectBgEdiExportMain = function (item) {
                vm.model.bgEdiExportMain.item_selected = item;
            };
        }]
    });
})();