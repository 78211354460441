(function () {
    'use strict';

    let module = angular.module('imApp');

    module.directive('ttSidePanel', [function () {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: 'views/components/directives/ttSidePanel/ttSidePanel.template.html?v=' + module.version,
            scope: {
                ttOpen: '=',
                // Define scope variables here if needed
            },
            link: function (scope, element) {
                scope.model = {
                    open: false,
                }

                scope.id = {
                    container: uuid(),
                    closeButton: uuid(),
                }

                angular.element(document.body).append(element);

                scope.close = function (event) {
                   //console.dir(event);
                    const container = document.getElementById(scope.id.container);
                    const closeButton = document.getElementById(scope.id.closeButton);

                    if (!isTargetInElement(event.target, container) || isTargetInElement(event.target, closeButton)) {
                       //console.log('closing modal now');
                        scope.model.open = false;
                        scope.ttOpen = false;
                    }
                }

                scope.$watch('ttOpen', function (newValue, oldValue) {
                   //console.log(newValue);
                    if (newValue !== null && newValue !== undefined && newValue !== oldValue) {
                        scope.model.open = newValue;
                    }
                });

                function isTargetInElement(target, element) {
                    if (element === target) return true;

                    for (let i = 0; i < element?.children?.length; i++) {
                        let child = element.children[i];
                        let found = isTargetInElement(target, child);

                        if (found) {
                            return true;
                        }
                    };

                    return false;
                }


                scope.$on('$destroy', function () {
                   //console.log('element destroy');
                    scope.$destroy();
                    element.remove();
                });
            }
        }
    }]);
})();
