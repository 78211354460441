(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srOperationArService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrOperationArGet: function (custact_no) {
                return p2DataTaskService.call(1472, {
                    custact_no: custact_no
                });
            },
            loadSrOperationArResourceList: function () {
                return p2DataTaskService.call(1473, {});
            },
            loadSrOperationArResourcesList: function (setting) {
                return p2DataTaskService.call(1474, setting);
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1475, mergeObj);
            }
        };

        return service;
    }]);
})();