(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('colliDispatch', {
        templateUrl: 'views/components/views/colliDispatch/colliDispatch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'modalService', 'colliDispatchService', 'logisticService', 'rememberService', 'translateService', function ($timeout, stateService, utilityService, modalService, colliDispatchService, logisticService, rememberService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var initLoadDone = false;
            var initLoadDoneTimer = null;
            var onDestroy = [];

            var variableNames = {
                businessco_no: '',
                stockplace_id: '',
                deliverymethod_no: '',
                gb_collidispatch: '',
                gb_collidispatch_compilation: '',
                gb_collidispatch_send: ''
            };

            var translations = {
                collidispatch_ready_to_pick_up_show_title: 'Varsel',
                collidispatch_ready_to_pick_up_show_ok_label: 'OK',
                collidispatch_ready_to_pick_up_compilation_show_title: 'Varsel',
                collidispatch_ready_to_pick_up_compilation_show_ok_label: 'OK',
                collidispatch_not_ready_to_pick_up_show_title: 'Varsel',
                collidispatch_not_ready_to_pick_up_show_ok_label: 'OK',
                collidispatch_send_colli_not_ready_show_title: 'Varsel',
                collidispatch_send_colli_not_ready_show_ok_label: 'OK',
                collidispatch_send_sent_show_title: 'Varsel',
                collidispatch_send_sent_show_ok_label: 'OK',
                collidispatch_consignments_show_title: 'Varsel',
                collidispatch_consignments_show_ok_label: 'OK'
            };

            vm.model = {
                bgColliDispatch: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_collidispatch_orders', item_func: 'ORDERS' },
                        { item_id: '1', item_name: 'gb_collidispatch_compilation', item_func: 'COMPILATION' },
                        { item_id: '2', item_name: 'gb_collidispatch_send', item_func: 'SEND' }
                    ]
                },
                bgColliDispatchCompilation: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_collidispatch_compilation_orders', item_func: 'ORDERS' },
                        { item_id: '1', item_name: 'gb_collidispatch_compilation_colli', item_func: 'COLLI' }
                    ]
                },
                bgColliDispatchSend: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_collidispatch_send_orders', item_func: 'ORDERS' },
                        { item_id: '1', item_name: 'gb_collidispatch_send_colli', item_func: 'COLLI' },
                        { item_id: '2', item_name: 'gb_collidispatch_send_consignments', item_func: 'CONSIGNMENTS' }
                    ]
                },
                setting: {},
                selectListBusinessCompanies: [],
                selectListStockPlaces: [],
                selectListDeliveryMethods: [],
                gridReady: false,
                gridCompilationOrderHeadReady: false,
                gridCompilationCaseReady: false,
                gridSendOrderHeadReady: false,
                gridSendCaseReady: false,
                gridConsignmentsReady: false,
                lockedPickUp: false,
                lockedPickUpCompilation: false,
                lockedNotPickUp: false,
                lockedSendColliNotReady: false,
                lockedSent: false,
                lockedComplete: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridCompilationOrderHead = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridCompilationCase = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridSendOrderHead = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridSendCase = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridConsignments = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region INIT LOAD DONE TIMEOUT FUNCTION

            var initLoadDoneFunc = function () {
                if (angular.isDefined(initLoadDoneTimer)) {
                    $timeout.cancel(initLoadDoneTimer);
                }

                initLoadDoneTimer = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };

            // #endregion INIT LOAD DONE TIMEOUT FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            colliDispatchService.getColliDispatch().then(function (result) {
                angular.copy(result[0], vm.model.setting);

                initLoadDoneFunc();

                vm.model.bgColliDispatch.item_default = utilityService.groupButtonIdOf(vm.model.bgColliDispatch, vm.model.setting.gb_collidispatch);
                vm.model.bgColliDispatchCompilation.item_default = utilityService.groupButtonIdOf(vm.model.bgColliDispatchCompilation, vm.model.setting.gb_collidispatch_compilation);
                vm.model.bgColliDispatchSend.item_default = utilityService.groupButtonIdOf(vm.model.bgColliDispatchSend, vm.model.setting.gb_collidispatch_send);

                vm.grid.dataTask.rememberId = 'w_collidispatch_orders_grid';
                vm.gridCompilationOrderHead.dataTask.rememberId = 'w_collidispatch_compilation_orderhead_grid';
                vm.gridCompilationCase.dataTask.rememberId = 'w_collidispatch_compilation_case_grid';
                vm.gridSendOrderHead.dataTask.rememberId = 'w_collidispatch_send_orderhead_grid';
                vm.gridSendCase.dataTask.rememberId = 'w_collidispatch_send_case_grid';
                vm.gridConsignments.dataTask.rememberId = 'w_collidispatch_consignments_grid';

                loadStocks();

                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_fom) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_tom) === true
                    && utilityService.validateParmsValue(vm.model.setting.dateselector_index) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) === true) {
                    vm.grid.dataTask.loadData = {
                        method: 2602,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_fom) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_tom) === true
                    && utilityService.validateParmsValue(vm.model.setting.dateselector_index) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) === true) {
                    vm.gridCompilationOrderHead.dataTask.loadData = {
                        method: 2606,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_fom) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_tom) === true
                    && utilityService.validateParmsValue(vm.model.setting.dateselector_index) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) === true) {
                    vm.gridCompilationCase.dataTask.loadData = {
                        method: 2605,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_fom) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_tom) === true
                    && utilityService.validateParmsValue(vm.model.setting.dateselector_index) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) === true) {
                    vm.gridSendOrderHead.dataTask.loadData = {
                        method: 2610,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_fom) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_tom) === true
                    && utilityService.validateParmsValue(vm.model.setting.dateselector_index) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) === true) {
                    vm.gridSendCase.dataTask.loadData = {
                        method: 2611,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_fom) === true
                    && utilityService.validateParmsValue(vm.model.setting.date_tom) === true
                    && utilityService.validateParmsValue(vm.model.setting.dateselector_index) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) === true) {
                    vm.gridConsignments.dataTask.loadData = {
                        method: 2634,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;
                vm.model.gridCompilationOrderHeadReady = true;
                vm.model.gridCompilationCaseReady = true;
                vm.model.gridSendOrderHeadReady = true;
                vm.model.gridSendCaseReady = true;
                vm.model.gridConsignmentsReady = true;
            });

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListBusinessCompanies);
            });

            colliDispatchService.listDeliveryMethods({ add_all: '1' }).then(function (result) {
                angular.copy(result, vm.model.selectListDeliveryMethods);
            });

            var loadStocks = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        variableValue = vm.model.setting.stockplace_id;
                        break;
                    case 'deliverymethod_no':
                        if (utilityService.validateParmsValue(vm.model.setting.deliverymethod_no) !== true) return;

                        variableValue = vm.model.setting.deliverymethod_no;
                        break;
                    case 'gb_collidispatch':
                        if (initLoadDone !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.gb_collidispatch) !== true) return;

                        variableValue = vm.model.setting.gb_collidispatch;
                        break;
                    case 'gb_collidispatch_compilation':
                        if (initLoadDone !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.gb_collidispatch_compilation) !== true) return;

                        variableValue = vm.model.setting.gb_collidispatch_compilation;
                        break;
                    case 'gb_collidispatch_send':
                        if (initLoadDone !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.gb_collidispatch_send) !== true) return;

                        variableValue = vm.model.setting.gb_collidispatch_send;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStocks();

                        if (vm.model.bgColliDispatch.item_selected === 'ORDERS') {
                            vm.grid.gridfunc.rebind();
                        }
                        break;
                    case 'stockplace_id':
                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');

                        if (vm.model.bgColliDispatch.item_selected === 'ORDERS') {
                            vm.grid.gridfunc.rebind();
                        } else if (vm.model.bgColliDispatch.item_selected === 'COMPILATION') {
                            if (vm.model.bgColliDispatchCompilation.item_selected === 'ORDERS') {
                                vm.gridCompilationOrderHead.gridfunc.rebind();
                            } else {
                                vm.gridCompilationCase.gridfunc.rebind();
                            }
                        }
                        break;
                    case 'deliverymethod_no':
                        vm.model.setting.deliverymethod_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.deliverymethod_no, true) !== true) return;

                        rememberFunc('deliverymethod_no');
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgColliDispatch = function (item) {
                vm.model.bgColliDispatch.item_selected = item;
                vm.model.setting.gb_collidispatch = vm.model.bgColliDispatch.item_selected;

                if (item) rememberFunc('gb_collidispatch');

                //if (item === 'ORDERS') {
                //    //vm.grid.gridfunc.refresh();
                //}
            };

            vm.selectBgColliDispatchCompilation = function (item) {
                vm.model.bgColliDispatchCompilation.item_selected = item;
                vm.model.setting.gb_collidispatch_compilation = vm.model.bgColliDispatchCompilation.item_selected;

                if (item) rememberFunc('gb_collidispatch_compilation');

                //if (vm.model.bgColliDispatch.item_selected === 'COMPILATION' && item === 'COLLI') {
                //    //vm.gridCompilationCase.gridfunc.refresh();
                //}
            };

            vm.selectBgColliDispatchSend = function (item) {
                vm.model.bgColliDispatchSend.item_selected = item;
                vm.model.setting.gb_collidispatch_send = vm.model.bgColliDispatchSend.item_selected;

                if (item) rememberFunc('gb_collidispatch_send');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.pickUpChanges = function () {
                vm.model.lockedPickUp = true;
                var dirtyRows = vm.gridCompilationOrderHead.gridfunc.getDirtyRows();
                var selectedRows = [];
                var mergeObj = vm.model.setting;

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push({
                            order_internal_no: item.order_internal_no
                        });
                    }
                });

                mergeObj.records = selectedRows;

                colliDispatchService.pickUpReadyItems(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.collidispatch_ready_to_pick_up_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.collidispatch_ready_to_pick_up_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPickUp = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedPickUp = false;
                        vm.gridCompilationOrderHead.gridfunc.rebind();
                    }
                });
            };

            vm.pickUpCompilationChanges = function () {
                vm.model.lockedPickUpCompilation = true;
                var dirtyRows = vm.gridCompilationCase.gridfunc.getDirtyRows();
                var selectedRows = [];
                var mergeObj = vm.model.setting;

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push({
                            case_keyno: item.case_keyno
                        });
                    }
                });

                mergeObj.records = selectedRows;

                colliDispatchService.pickUpCompilationReadyItems(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.collidispatch_ready_to_pick_up_compilation_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.collidispatch_ready_to_pick_up_compilation_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPickUpCompilation = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedPickUpCompilation = false;
                        vm.gridCompilationCase.gridfunc.rebind();
                    }
                });
            };

            vm.notPickUpChanges = function () {
                vm.model.lockedNotPickUp = true;
                var dirtyRows = vm.gridSendOrderHead.gridfunc.getDirtyRows();
                var selectedRows = [];
                var mergeObj = vm.model.setting;

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push({
                            order_internal_no: item.order_internal_no
                        });
                    }
                });

                mergeObj.records = selectedRows;

                colliDispatchService.pickUpNotReadyItems(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.collidispatch_not_ready_to_pick_up_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.collidispatch_not_ready_to_pick_up_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedNotPickUp = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedNotPickUp = false;
                        vm.gridSendOrderHead.gridfunc.rebind();
                    }
                });
            };

            vm.sendColliNotReadyChanges = function () {
                vm.model.lockedSendColliNotReady = true;
                var dirtyRows = vm.gridSendCase.gridfunc.getDirtyRows();
                var selectedRows = [];
                var mergeObj = vm.model.setting;

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push({
                            case_keyno: item.case_keyno
                        });
                    }
                });

                mergeObj.records = selectedRows;

                colliDispatchService.sendColliNotReadyItems(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.collidispatch_send_colli_not_ready_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.collidispatch_send_colli_not_ready_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSendColliNotReady = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSendColliNotReady = false;
                        vm.gridSendCase.gridfunc.rebind();
                    }
                });
            };

            vm.sendChanges = function () {
                vm.model.lockedSent = true;
                var dirtyRows = vm.gridSendOrderHead.gridfunc.getDirtyRows();
                var selectedRows = [];
                var mergeObj = vm.model.setting;

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push({
                            order_internal_no: item.order_internal_no,
                            delivery_address_compact: item.delivery_address_compact
                        });
                    }
                });

                mergeObj.records = selectedRows;

                colliDispatchService.sendSentItems(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.collidispatch_send_sent_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.collidispatch_send_sent_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSent = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSent = false;
                        vm.gridSendOrderHead.gridfunc.rebind();
                    }
                });
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].consignment_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        var parms = {
                            consignment_keyno: items[step].consignment_keyno
                        };

                        colliDispatchService.completeConsignmentItems(parms).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.collidispatch_consignments_show_title,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.collidispatch_consignments_show_ok_label,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            vm.completeConsignmentChanges = function () {
                vm.model.lockedComplete = true;
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var dirtyRows = vm.gridConsignments.gridfunc.getDirtyRows();
                var selectedRows = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push({
                            consignment_keyno: item.consignment_keyno
                        });
                    }
                });

                vm.model.progressInfo.max = selectedRows.length;
                vm.activateProgress(selectedRows);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.model.lockedComplete = false;
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.model.lockedComplete = false;
                        vm.gridConsignments.gridfunc.rebind();
                    }
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval;

                if (vm.model.setting.date_fom && vm.model.setting.date_tom) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    vm.grid.gridfunc.rebind();
                }
            }

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
