(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttInputNumber', {
        templateUrl: 'views/components/directives/ttInputNumber/ttInputNumber.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttSublabel: '@',
            ttReadonly: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttOnBlur: '&',
            ttOptions: '<',
            ttTextAlign: '@',       // 'left', 'center', 'right' - EO 20240521
            ttPlaceholder: '@',
            ttInputmode: '@',       // TODO: add to the change event and have it swap depending on number of decimals 'numeric' || 'decimal'
            ttItemId: '@',
            ttBindFocus: '@',
            ttRequired: '@',
            ttStyle: '<',
            ttInvalid: '<',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttUnaryButtons: '@',    // JLR 20240222 `true` or `false`. Default is false. Shows decrement and increment buttons if true.
            ttButtons: '<',
            ttCallback: '&?'
        },
        controller: ['$element', '$timeout', '$interval', 'layoutService', 'eventService', 'translateService', 'utilityService', 'modalService', 'ttDirectivesService', function ($element, $timeout, $interval, layoutService, eventService, translateService, us, modalService, ttDirectivesService) {
            let vm = this;
            let onDestroy = [];
            let focusIsBound = false;
            let inputElement;
            let stopFocusBinding;

            vm.hideLabel = false;
            vm.readonly = false;
            vm.required = false;

            vm.inputmode = 'decimal';

            vm.id = {
                input: uuid()
            };

            vm.style = {
                base: {},
                group: {},
                groupButton: {
                    //paddingTop: '0',
                    ////paddingTop: '4px',
                    //paddingBottom: '0',
                    //paddingLeft: '12px',
                    //paddingRight: '12px'
                },
                label: {},
                sublabel: {},
                input: {},
                icon: {
                    //color: 'white',
                    //top: '0'
                },
                iconButtonText: {
                    //color: 'white',
                    fontWeight: 'bold',
                    marginLeft: '3px'
                },
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. JLR 20230622
            };

            vm.class = {
                base: '',
                //group: 'input-group',
                label: '',
                sublabel: '',
                input: 'input-group'
            };

            vm.translations = {
                ttLabel: '',
                ttPlaceholder: '',
            };

            let openCalculator = function () {
                modalService.showCalculator('', vm.translations.ttLabel, vm.ttModel).then(function (value) {
                    //vm.onModelChanged(value);
                    vm.validateNumber(value);
                });
            };

            vm.calculator = {
                icon: 'fa fa-calculator',
                classes: 'btn-primary',
                click: openCalculator
            };

            vm.decrement = function () {
                console.log(vm.ttModel);
                if (!isNaN(Number(vm.ttModel))) {
                    vm.validateNumber((Number(vm.ttModel) - 1));
                }
            }

            vm.increment = function () {
                console.log(vm.ttModel);
                if (!isNaN(Number(vm.ttModel))) {
                    vm.validateNumber(Number(vm.ttModel) + 1);
                }
            }

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            // JLR 20230622
            let setClasses = (labelAlwaysOnTop) => {
                vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
                setReadonly(vm.readonly);
            }

            function setReadonly(readonly) {
                if (readonly === true && vm.class.input?.includes('input-group')) {
                    vm.class.input = '';
                } else if (readonly !== true && !vm.class.input?.includes('input-group')) {
                    vm.class.input = 'input-group';
                }
            }

            // JLR 20230622
            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

            vm.onBtnClick = function (btn) {
                if (angular.isDefined(vm.ttCallback) && angular.isFunction(vm.ttCallback)) {
                    vm.ttCallback();
                } else {
                    ttDirectivesService.onButtonClick({ button: btn, parameter: vm.ttButtonsParm, controller: vm });
                }
            };

            let setFocus = function () {
                if (angular.isUndefined(inputElement)) {
                    inputElement = $element.find('#' + vm.id.input);
                }

                if (angular.isDefined(inputElement[0])) {
                    $timeout(function () {
                        inputElement[0].focus();
                    });
                }
            };

            let bindFocus = function () {
                // return if focus already bound
                if (angular.isDefined(stopFocusBinding)) return;

                stopFocusBinding = $interval(setFocus, 1000);

                $element.focus();
            };

            let unbindFocus = function () {
                if (angular.isUndefined(stopFocusBinding))
                    return;

                $interval.cancel(stopFocusBinding);

                stopFocusBinding = undefined;
            };

            function isInputFocused() {
                return document?.activeElement === $element.find('#' + vm.id.input)[0];
            }

            vm.selectText = function () {
                if (vm.ttOptions?.autoselect === false) return; // changed autoselect true to be default - JLR 20230711

                if (angular.isUndefined(inputElement)) {
                    inputElement = $element.find('#' + vm.id.input);
                }

                inputElement[0].select();
            };

            vm.validateNumber = function (value) {
                if (angular.isUndefined(value) || value === null) {
                    vm.onModelChanged(value);
                    return;
                }

                if (vm.ttOptions?.alwaysPositive === true) {
                    value = `${value}`.replace(/[^0-9.,]/g, '');
                } else {
                    value = `${value}`.replace(/[^0-9.,-]/g, '');
                    value = `${value}`.replace(/(?!^)-/g, '');
                }

                value = value.replace(/,/g, '.');

                value = value.replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
                    return b + c.replace(/\./g, '');
                });

                if (vm.ttOptions?.decimals) {
                    let multiplier = Math.pow(10, vm.ttOptions.decimals);

                    value = (Math.round((Number(value) + Number.EPSILON) * multiplier) / multiplier).toFixed(vm.ttOptions.decimals);
                }

                vm.onModelChanged(value);
            };

            vm.onBlur = function (value) {
                vm.validateNumber(value);
                vm.ttOnBlur({ $model: value });
            }

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(vm.style.labelAlwaysOnTop || info.labelAlwaysOnTop);
            });

            onDestroy.push(eventService.on('event:focuslock:start', function (data) {
                if (focusIsBound !== true)
                    return;

                bindFocus();
            }));

            onDestroy.push(eventService.on('event:focuslock:stop', function (data) {
                if (focusIsBound !== true)
                    return;

                unbindFocus();
            }));

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttInvalid)) {
                    vm.ttInvalid = us.toBoolean(changes.ttInvalid.currentValue);

                    if (vm.ttInvalid === true) {
                        vm.style.input.borderColor = 'red';
                    } else {
                        delete vm.style.input.borderColor;
                    }
                }

                if (angular.isDefined(changes.ttBindFocus) && us.isStringValue(changes.ttBindFocus.currentValue)) {
                    focusIsBound = us.toBoolean(changes.ttBindFocus.currentValue, true);

                    if (focusIsBound === true) {
                        bindFocus();
                    } else {
                        unbindFocus();
                    }
                }

                if (changes.ttModel && !isInputFocused()) vm.validateNumber(changes.ttModel.currentValue);

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
                    vm.style.input.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttReadonly)) {
                    vm.readonly = us.toBoolean(changes.ttReadonly.currentValue);
                }

                if (angular.isDefined(changes.ttPlaceholder) && us.isStringValue(changes.ttPlaceholder.currentValue, true)) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttPlaceholder = changes.ttPlaceholder.currentValue;
                    } else {
                        translateService.translate(changes.ttPlaceholder.currentValue).then((translation) => vm.translations.ttPlaceholder = translation);
                    }
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttRequired) && angular.isDefined(changes.ttRequired.currentValue)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttButtons) && angular.isArray(changes.ttButtons.currentValue)) {
                    for (var i = 0; i < changes.ttButtons.currentValue.length; i++) {
                        var btn = changes.ttButtons.currentValue[i];

                        if (angular.isDefined(btn.color) && btn.color.length > 0) {
                            btn.classes = btn.color.startsWith('btn-') ? btn.color : 'btn-' + btn.color;
                        }

                        btn.show = us.toBoolean(btn.show, true);
                        btn.showIcon = btn.show === true && angular.isDefined(btn.icon) && angular.isString(btn.icon) && btn.icon !== null && btn.icon.trim().length > 0;
                        btn.showText = btn.show === true && angular.isDefined(btn.text) && angular.isString(btn.text) && btn.text !== null && btn.text.trim().length > 0;
                    }
                }

            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.ttInvalid) && us.toBoolean(vm.ttInvalid) === true) {
                    vm.style.input.borderColor = 'red';
                }

                if (vm.ttModel) vm.validateNumber(vm.ttModel);

                setStyle(vm.ttStyle);
            }

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
