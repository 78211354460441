(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productSearch', {
        templateUrl: 'views/components/views/productSearch/productSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'productSearchService', 'logisticService', 'rememberService', function ($stateParams, $q, stateService, utilityService, modalService, productSearchService, logisticService, rememberService) {
            let vm = this;
            let functionName = $stateParams.functionname;

            let variableNames = {
                businessco_no: '',
                stockplace_id: ''
            };

            vm.model = {
                functionName: functionName,
                bgPickType: {
                    selected: 'product',
                    buttons: [
                        { id: 'product', label: 'product', color: 'primary', onClick: () => vm.model.bgPickType.selected = 'product' },
                        { id: 'stock', label: 'stock', color: 'primary', onClick: () => vm.model.bgPickType.selected = 'stock' }
                    ]
                },
                productSearchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('productsearch_searchtext') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('productsearch_searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadSearchProducts() }
                ],
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                itemsListProducts: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();

                productSearchService.getSetting({
                    argtype: '-',
                    argvalue: '-'
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => {
                        loadSearchProducts();
                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            let loadSearchProducts = function () {
                let fn = functionName;
                
                if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;
                if (fn === 'choice') fn = vm.model.bgPickType.selected;
                
                vm.model.itemsListProducts = [];

                return productSearchService.listSearchProducts({
                    productsearch_searchtext: vm.model.setting.productsearch_searchtext,
                    functionname: fn,
                    stockplace_id: vm.model.setting.stockplace_id
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListProducts);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'businessco_no':
                        case 'stockplace_id':
                            variableNames[key] = 'stock' + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'productsearch_searchtext':
                        vm.model.setting.productsearch_searchtext = '';
                        vm.model.itemsListProducts = [];
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (id) {
                modalService.showCalculator(vm.model.setting[id]).then(function (value) {
                    vm.model.setting[id] = value;

                    if (vm.model.setting.productsearch_searchtext > '') loadSearchProducts();
                });
            };

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            vm.searchItems = function () {
                if (vm.model.setting.productsearch_searchtext > '') loadSearchProducts();
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'productcreate':
                        go({
                            argtype: '-',
                            argvalue: '-'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPickType = function (value) {
                vm.model.bgPickType.selected = value;
                
                if (vm.model.setting.productsearch_searchtext > '') loadSearchProducts();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');

                        if (vm.model.setting.productsearch_searchtext > '') loadSearchProducts();
                        break;
                    case 'productsearch_searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.productsearch_searchtext) !== true) return;

                        vm.model.setting.productsearch_searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;

                        if (vm.model.setting.productsearch_searchtext === '') {
                            vm.model.setting.productsearch_searchtext = '';
                            vm.model.itemsListProducts = [];
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
