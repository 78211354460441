import { Component } from '@angular/core';

/**
 * Base component for all TouchTime core components for sharing base logic.
 * All core-components should extend this component.
 */
@Component({
    selector: 'tt-component-base',
    templateUrl: './component-base.component.html',
    styleUrls: ['./component-base.component.css'],
})
export abstract class ComponentBaseComponent {
    protected validateParmsWithValue(parmWithValue: any): boolean {
        if (parmWithValue == null) {
            return false;
        }

        if (typeof parmWithValue.trim !== 'function') {
            return false;
        }

        return parmWithValue.trim().length > 0;
    }

    protected validateParmsValue(parmWithValue: any, allowEmptyString?: boolean): boolean {
        if (parmWithValue === undefined || parmWithValue === null) {
            return false;
        }

        if (allowEmptyString !== true) {
            if (typeof parmWithValue.trim !== 'function' || parmWithValue.trim().length < 1) {
                return false;
            }
        }

        return true;
    }

    protected base64UrlEncodeUnicode(input: string): string {
        const utf8Bytes = encodeURIComponent(input).replace(
            /%([0-9A-F]{2})/g,
            (_, p1) => String.fromCharCode(parseInt(p1, 16))
        );

        const base64String = btoa(utf8Bytes);

        return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    }

    protected base64UrlDecode(input: string): string {
        // Replace URL-safe characters
        input = input.replace(/-/g, '+').replace(/_/g, '/');

        // Add padding
        const padLength = (4 - (input.length % 4)) % 4;

        input += '='.repeat(padLength);

        // Decode Base64
        try {
            const binaryString = atob(input);

            // Convert binary string to Unicode string
            const decoded = decodeURIComponent(
                binaryString
                    .split('')
                    .map((char) => {
                        const charCode = char.charCodeAt(0).toString(16).padStart(2, '0');
                        return `%${charCode}`;
                    })
                    .join('')
            );

            return decoded;
        } catch (error) {
            console.error('Failed to decode base64 URL string:', error);
            return '';
        }
    }
}
