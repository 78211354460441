(function () {
	'use strict';
	
	angular.module("imApp").factory("cuttingPlanService", ['$ihttp', function ($ihttp) {
		var service = {
			getCuttingPlan: function (parms) {
				return $ihttp.post({
					method: 2880,
					parameters: parms || {}
				});
			},
			listUdefs: function (parms) {
				return $ihttp.post({
					method: 2881,
					parameters: parms || {}
				});
			},
			listFormats: function (parms) {
				return $ihttp.post({
					method: 2882,
					parameters: parms || {}
				});
			},
            scanItem: function (edit) {
                return $ihttp.post({
					method: 2885,
                    parameters: edit
                });
			},
            calcObj: function (edit) {
                return $ihttp.post({
					method: 2888,
                    parameters: edit
                });
			},
			distributeObj: function (edit) {
				return $ihttp.post({
					method: 2889,
					parameters: edit
				});
			},
			saveObj: function (edit) {
				return $ihttp.post({
					method: 2883,
					parameters: edit
				});
            },
            printCuttingPlan: function (cuttingplan_keyno, printer_keyno) {
                return $ihttp.post({
                    method: 2934,
                    parameters: {
                        cuttingplan_keyno: cuttingplan_keyno,
                        printer_keyno: printer_keyno
                    }
                });
            },
            searchKeyname: function (value) {
                return $ihttp.post({
                    method: 2969,
                    parameters: {
                        searchtext: value
                    }
                });
            },

		};

		return service;
	}]);
})();
