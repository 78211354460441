(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("dbReportService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            details: function (reportKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 338,
                    parameters: {
                        p2_reportdef_keyno: reportKeyno
                    }
                }).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },

            setUserReportPrinter: function (reportKeyno, printerKeyno, portalUserKeyno) {
                var parms = angular.isObject(reportKeyno)
                    ? reportKeyno
                    : {
                        method: 550,
                        parameters: {
                            reportKeyno: reportKeyno,
                            defaultPrinterKeyno: printerKeyno,
                            portal_user_keyno: portalUserKeyno
                        }
                    };

                // Procedure sp_ws_p2_printerdialogue_save
                return $ihttp.post(parms);
            },

            loadReports: function (webpage, argtype, argvalue) {
                // Procedure sp_ws_p2_selectreport_list
                return $ihttp.post({
                    method: 335,
                    parameters: {
                        webpage: webpage,
                        argtype: argtype,
                        argvalue: argvalue
                    }
                });
            },

            getReport: function (reportDefKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 334,
                    parameters: {
                        p2_reportdef_keyno: reportDefKeyno
                    }
                }).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;

                //// Procedure sp_ws_p2_p2reportdef_get
                //return $ihttp.post({
                //    method: 334,
                //    parameters: {
                //        p2_reportdef_keyno: reportDefKeyno
                //    }
                //});
            }
        };
        
        return service;
    }]);
})();