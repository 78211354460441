(function () {
    'use strict';

    angular.module("imApp").factory("createCaseService", ['$ihttp', function ($ihttp) {
        var service = {
            getCreateCase: function (parms) {
                return $ihttp.post({
                    method: 2546,
                    parameters: parms || {}
                });
            },
            mergeObj: function (mergeObject) {
                return $ihttp.post({
                    method: 2548,
                    parameters: mergeObject
                });
            },
            createSeparateCases: function (mergeObject) {
                return $ihttp.post({
                    method: 3379,
                    parameters: mergeObject
                });
            },
            splitObj: function (splitObject) {
                return $ihttp.post({
                    method: 2550,
                    parameters: splitObject
                });
            }
        };

        return service;
    }]);
})();
