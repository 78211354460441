(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("itemService", [function () {
        var service = {
            item_id: '',
            item_name: '',
            item_path: '',
            records: []
        };

        return service;
    }]);
})();