(function () {
    'use strict';

    angular.module("imApp").factory("srFollowUpForwardService", ['$ihttp', function ($ihttp) {
        var service = {
            getSrFollowUpForward: function (parms) {
                return $ihttp.post({
                    method: 2135,
                    parameters: parms || {}
                });
            },
            listResources: function (parms) {
                return $ihttp.post({
                    method: 2136,
                    parameters: parms || {}
                });
            },
            sendObj: function (edit) {
                return $ihttp.post({
                    method: 2137,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();