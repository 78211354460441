(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("docarcService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_docarc_get: {
                docarc_keyno: '',
                cust_no: '',
                project_keyno: '',
                projectprosess_keyno: '',
                docfilename: '',
                doctitle: '',
                oleapplication_id: '',
                reg_by_portal_user_keyno: '',
                latitude: '',
                longitude: '',
                altitude: '',
                filesize: ''
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2DocarcGet: function (docarcKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 311,
                    parameters: {
                        docarc_keyno: docarcKeyno
                    }
                }).then(function (data) {
                    service.p2_docarc_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            add: function (parms, context) {
                parms = parms || {};
                parms.filename = parms.filename || '';

                if (parms.filename.length < 1) return $q.reject('missing filename');

                parms.contentType = parms.contentType || '';
                parms.latitude = parms.latitude || '0';
                parms.longitude = parms.longitude || '0';
                parms.altitude = parms.altitude || '0';
                parms.heading = parms.heading || '0';
                parms.createdTimestamp = parms.createdTimestamp || '';
                parms.originalTimestamp = parms.originalTimestamp || '';
                parms.size = parms.size || '';
                parms.argType = parms.argType || '';
                parms.argValue = parms.argValue || '';
                parms.checksum_md5 = parms.checksum_md5 || '';

                var internalParms = {
                    method: 310,
                    parameters: parms
                };

                var deferred = $q.defer();

                $ihttp.post(internalParms, { context: context }).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            // BJS 20201204
            exists: function (filename, checksum_md5, argtype, argvalue, context) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 1636,
                    parameters: {
                        originalFilename: filename || '',
                        checksum_md5: checksum_md5 || '',
                        argtype: argtype || '',
                        argvalue: argvalue || ''
                    }
                }, {
                    context: context
                }).then(function (data) {
                    deferred.resolve({
                        exists: data[0].docarcExists === '1'
                    });
                });

                return deferred.promise;
            },
            update: function (parms, context) {
                parms = parms || {};
                parms.docarcKeyno = parms.docarcKeyno || 0;
                parms.docFileName = parms.docFileName || '';

                if (parms.docarcKeyno < 1) return $q.reject({ isError: true, message: 'Missing docarcKeyno' });

                var deferred = $q.defer();

                $ihttp.post({
                    method: 435,
                    parameters: parms
                }, { context: context }).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            delete: function (docarcKeyno, context) {
                var deferred = $q.defer();

                var parms = {
                    method: 434,
                    parameters: {
                        docarcKeyno: docarcKeyno
                    }
                };

                $ihttp.post(parms, { context: context }).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            saveP2DocArc: function (fileItem) {
                var deferred = $q.defer();

                var parmsP2DocArcSave = {
                    method: 618,
                    parameters: fileItem
                };

                $ihttp.post(parmsP2DocArcSave).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();