(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderLineMs', {
        templateUrl: 'views/components/views/orderLineMs/orderLineMs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'orderLineMsService', function ($stateParams, $q, orderLineMsService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let headNo = $stateParams.headno;
            let lineNo = $stateParams.lineno;
            
            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
                itemsListSelectModels: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                orderLineMsService.getSetting({
                    argtype: argType,
                    headno: headNo,
                    lineno: lineNo
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadSelectModels();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadSelectModels = function () {
                return orderLineMsService.listSelectModels(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListSelectModels);
                });
            };
            
            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
