(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("checkListDataService", ['$q', '$ihttp', '$stateParams', 'p2DataTaskService', function ($q, $ihttp, $stateParams, p2DataTaskService) {
        var service = {
            loaded: false,
            loadP2WCheckListDataSettings: function (checklistdata_keyno) {
                return p2DataTaskService.call(922, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            p2_checklistdata_get: {
                checklistdata_keyno: '',
                checklist_keyno: '',
                checklist_name: '',
                checkliststatus: '',
                checklistitems: []
            },
            loadP2CheckListData: function (checklistdata_keyno) {
                var deferred = $q.defer();

                var parms_p2_checklistdata_get = {
                    method: 231,
                    parameters: {
                        checklistdata_keyno: checklistdata_keyno
                    }
                };

                $ihttp.post(parms_p2_checklistdata_get).then(function (data) {
                    service.p2_checklistdata_get = angular.copy(data[0]);

                    for (var i = 0; i < service.p2_checklistdata_get.checklistitems.length; i++) {
                        service.checkListDataValidate(i);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            computeValidation: function (item) {
                return p2DataTaskService.call(1583, item);
            },
            checkListDataValidate: function (index) {
                if (service.p2_checklistdata_get.checklistitems[index].elementtype === 'CS' || service.p2_checklistdata_get.checklistitems[index].elementtype === 'CB' || service.p2_checklistdata_get.checklistitems[index].elementtype === 'CL') {
                    //('Verdien ble satt til:' + ' rad=' + index + 'verdien er' + service.p2_checklistdata_get.checklistitems[index].itemvalue);
                    //Kaller funksjon som sjekker om noen skal bli synlig
                    service.checkDown(index);
                }
            },
            checkDown: function (index) {
                //('Skal sjekke fra rad ' + index + ' og nedover til og med' + service.p2_checklistdata_get.checklistitems.length - 1);
                //Henter verdi på den raden som ble endret
                var ls_itemvalue_new = service.p2_checklistdata_get.checklistitems[index].itemvalue;
                var ln_checklistitem_keyno = service.p2_checklistdata_get.checklistitems[index].checklistitem_keyno;
                //('Den nye verdien det skal sjekkes på er:' + ls_itemvalue_new);
                for (var i = index + 1; i < service.p2_checklistdata_get.checklistitems.length; i++) {
                    //('Sjekker array med no ' + i);
                    if (service.p2_checklistdata_get.checklistitems[i].dep1_checklistitem_keyno === ln_checklistitem_keyno) {
                        //('Fann match på rad ' + i);
                        if (service.p2_checklistdata_get.checklistitems[i].dep1_value === ls_itemvalue_new) {
                            //Her er det avhengighet og setter da visible = '1'
                            service.p2_checklistdata_get.checklistitems[i].dep1_visible = '1';
                        } else {
                            service.p2_checklistdata_get.checklistitems[i].dep1_visible = '0';
                        }
                    }
                }

                //Sjekker avhengighet 2
                for (var j = index + 1; j < service.p2_checklistdata_get.checklistitems.length; j++) {
                    //('Sjekker array med no ' + i);
                    if (service.p2_checklistdata_get.checklistitems[j].dep2_checklistitem_keyno === ln_checklistitem_keyno) {
                        //('Fann match på rad ' + i);
                        if (service.p2_checklistdata_get.checklistitems[j].dep2_value === ls_itemvalue_new) {
                            //Her er det avhengighet og setter da visible = '1'
                            service.p2_checklistdata_get.checklistitems[j].dep2_visible = '1';
                        } else {
                            service.p2_checklistdata_get.checklistitems[j].dep2_visible = '0';
                        }
                    }
                }
            },
            saveP2CheckListData: function (index) {
                var deferred = $q.defer();

                var parms_p2_checklistdata_save = {
                    method: 267,
                    parameters: {
                        checklistdata_keyno: service.p2_checklistdata_get.checklistdata_keyno,
                        checklist_keyno: service.p2_checklistdata_get.checklist_keyno,
                        checklist_name: service.p2_checklistdata_get.checklist_name,
                        approve: '0',
                        checklistitems: service.p2_checklistdata_get.checklistitems.map(function (a) { return { "i": a.checklistitemdata_keyno, "v": a.itemvalue }; })
                    }
                };

                $ihttp.post(parms_p2_checklistdata_save).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            saveAndApproveP2CheckListData: function (index) {
                var deferred = $q.defer();

                var parms_p2_checklistdata_save = {
                    method: 267,
                    parameters: {
                        checklistdata_keyno: service.p2_checklistdata_get.checklistdata_keyno,
                        checklist_keyno: service.p2_checklistdata_get.checklist_keyno,
                        checklist_name: service.p2_checklistdata_get.checklist_name,
                        approve: '1',
                        checklistitems: service.p2_checklistdata_get.checklistitems.map(function (a) { return { "i": a.checklistitemdata_keyno, "v": a.itemvalue }; })
                    }
                };

                $ihttp.post(parms_p2_checklistdata_save).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            deleteP2SelectCheckList: function () {
                var deferred = $q.defer();

                var parms_p2_select_checklist_delete = {
                    method: 262,
                    parameters: {
                        checklistdata_keyno: $stateParams.checklistdata_keyno
                    }
                };

                $ihttp.post(parms_p2_select_checklist_delete).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();