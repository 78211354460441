(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("productMultiUnitService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
			loadProductMultiUnitGet: function (prod_id) {
				return p2DataTaskService.call(1080, {
					prod_id: prod_id
				});
			},
            loadUnitList: function (isactive) {
                return p2DataTaskService.call(218, {
                    isactive: isactive
                });
            },
            loadProductUnitList: function (prod_id) {
                return p2DataTaskService.call(580, {
                    prod_id: prod_id
                });
            },
            save: function (getProductMultiUnit) {
                return p2DataTaskService.call(1081, getProductMultiUnit);
            }
        };

        return service;
    }]);
})();