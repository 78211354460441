(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('subOperation', {
        templateUrl: 'views/components/views/subOperation/subOperation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'subOperationService', 'logisticService', function ($stateParams, stateService, utilityService, modalService, translateService, subOperationService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let operationId = $stateParams.operation_id;
            let subOperationKeyno = $stateParams.suboperation_keyno;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                subOperationNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('suboperationlang', { suboperation_keyno: vm.model.edit.suboperation_keyno }) }
                ],
                edit: {},
                selectListOnlyTimeUnits: [],
                selectListAllUnits: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                subOperationService.getSubOperation({
                    operation_id: operationId,
                    suboperation_keyno: subOperationKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadTimeUnits = function () {
                logisticService.listUnits({
                    onlytimeunits: '1',
                    isactive: '1',
                    fullname: '1'
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListOnlyTimeUnits);
                });
            };

            let loadAllUnits = function () {
                logisticService.listUnits({
                    onlytimeunits: '0',
                    isactive: '1',
                    fullname: '1'
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListAllUnits);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region CALCULATE PROCEDURE FUNCTION CALL

            let calculateChanges = function () {
                if (utilityService.validateParmsValue(vm.model.edit.help_time_unit_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.help_quantity_unit_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.help_speed, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.help_quantity, true) !== true) return;

                if (vm.model.edit.help_speed > '0.00' || vm.model.edit.help_quantity > '0.00') {
                    subOperationService.calculateObj(vm.model.edit).then(function (response) {
                        vm.model.edit.std_time = response[0].std_time;
                        vm.model.edit.help_speed_helptext = response[0].help_speed_helptext;
                        vm.model.edit.help_quantity_helptext = response[0].help_quantity_helptext;
                    });
                } else {
                    subOperationService.calculateObj(vm.model.edit).then(function (response) {
                        vm.model.edit.std_time = response[0].std_time;
                        vm.model.edit.help_speed_helptext = response[0].help_speed_helptext;
                        vm.model.edit.help_quantity_helptext = response[0].help_quantity_helptext;
                    });
                }
            };

		    // #endregion CALCULATE PROCEDURE FUNCTION CALL

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'help_speed':
                    case 'help_quantity':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        calculateChanges();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'use_helpunit':
                    case 'help_time_unit_no':
                    case 'help_quantity_unit_no':
                    case 'std_time_unit_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        calculateChanges();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                subOperationService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    subOperationService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadTimeUnits();
                loadAllUnits();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();