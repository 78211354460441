(function () {
    'use strict';

    angular.module('imApp')
        .directive('imChartDirective', ['userService', 'stateService', function (userService, stateService) {
            var directive = {
                restrict: 'E',
                //transclude: true,
                scope: {
                    field: '@',         //Used to determine the field to use as primary value                   //Required, if pfield not used
                    chartType: '=',     //Used to change the chart type                                         //Accepts '', 'pie', 'line' or 'column'
                    orderBy: '=',       //Used to change the value to order by
                    categoryAxis: '@',  //
                    multi: '@',         //Used to indicate the data will come from a 'multi'-list               //Accepts '0' or '1'
                    legend: '@',        //Used in combination with multi to show legend from item_id=0          //Accepts '0' or '1'
                    labels: '=',        //Used to hide or show the labels                                       //Accepts '0' or '1'
                    listItems: '=',     //Used for linking a list to the directive
                    optionfunc: '&',    //Used as reference to funtion in Controller
                },
                template:
                    //'<div ng-class="addGroup()">' +
                    '<div id="{{model.mId}}" class="im-kendo-chart col-xs-12 im-no-pad" kendo-chart ' +
                    'k-legend="{ position: \'bottom\' }" ' +
                    'k-theme="model.theme"' +
                    //'k-series-defaults="{ type: model.chartType, labels: { visible: true, background: \'transparent\', template: chartTool, align: \'column\' } }" ' +
                    'k-series-defaults="{ type: model.chartType, labels: { visible: model.labelsVis, background: \'transparent\', template: chartTool, align: model.chartTypeAlign } }" ' +
                    'k-data-source="model.graphDataSource" ' +
                    'k-series-hover="onSeriesHover" ' +
                    'k-series-click="onSeriesClick" ' +
                    //'k-tooltip="{visible: true, template: graphTool}" ' +
                    'k-category-axis="{ field: model.categoryAxis, labels:{rotation: \'300\'}}" ' +
                    //'k-series="graphSeries">' +
                    //'k-series="[{name:field, field:field}]" ' +
                    'k-series="model.dataSeries" ' + //, aggregate: \'sum\'
                    'k-rebind="model">' +
                    //'</div>' +
                    '</div>',
                link: function ($scope, $element, $attr) {
                    $scope.model = {
                        mId: uuid(),
                        categoryAxis: '',
                        theme: 'default',
                        labelsVis: true,
                        chartType: 'column',
                        chartTypeDefault: 'column',
                        chartTypeAlign: 'column',
                        orderBy: 'item_name',
                        graphDataSource: null,
                        acceptedChartTypes: ['column', 'pie', 'line'],
                        dataSeries: [{ field: $scope.field, categoryField: 'item_name', explodeField: 'explode' }]
                    };

                    //console.log('$scope imChartDirective');
                    //console.dir($scope);

                    //console.log('$scope.model imChartDirective');
                    //console.dir($scope.model);

                    $scope.model.categoryAxis = $scope.categoryAxis;

                    $scope.addGroup = function () {

                        var allClass = 'form-group'; //default

                        if (angular.isDefined($scope.grpsize)) {
                            allClass += ' ' + $scope.grpsize;
                        }

                        return allClass;
                    };

                    $scope.graphTool = function (e) {
                        //console.dir(e.dataItem.item_name);
                        return e.dataItem.item_name;
                    };

                    $scope.chartTool = function (e) {
                        //console.log(kendo.format("Chart Tool :: Name-{0} Cat-({1}): Val-{2}", e.series.name, e.category, e.value));
                        //console.dir(e.dataItem);

                        if (angular.isDefined($scope.multi) && $scope.multi == '1') {
                            if ($scope.model.chartType == 'column' || $scope.model.chartType == 'line') {
                                return e.value;
                            } else if ($scope.model.chartType == 'pie') {
                                return e.dataItem.item_name + ' ' + e.value;
                            }
                        } else {
                            if ($scope.model.chartType == 'column' || $scope.model.chartType == 'line') {
                                return e.dataItem[$scope.field];
                            } else if ($scope.model.chartType == 'pie') {
                                return e.dataItem.item_name + ' ' + e.dataItem[$scope.field];
                            }
                        }
                    };

                    $scope.onSeriesClick = function (e) {
                        console.log(kendo.format("Series click :: Name-{0} Cat-({1}): Val-{2}", e.series.name, e.category, e.value));
                        console.dir(e.dataItem);

                        if (angular.isDefined($scope.multi) && $scope.multi == '1' && angular.isFunction($scope.optionfunc)) {
                            if ($scope.model.chartType = 'pie' || $scope.model.chartType == 'line') {
                                $scope.optionfunc({ item: e.dataItem });
                            } else {
                                $scope.optionfunc({ item: e.dataItem.__proto__ });
                            }
                        } else {
                            if (e.dataItem.__proto__.item_path == '' || e.dataItem.__proto__.item_name == 'Other') return;

                            if ($scope.model.chartType = 'pie' || $scope.model.chartType == 'line') {
                                stateService.go(e.dataItem.item_state, e.dataItem.item_parms);
                            } else {
                                stateService.go(e.dataItem.__proto__.item_state, e.dataItem.__proto__.item_parms);
                            }
                        }

                        //console.dir(e.dataItem);
                        //console.dir(e.dataItem.__proto__.item_parms);
                        //console.dir(e.dataItem.__proto__.item_state);
                        //stateService.go(l_item.item_state, l_item.item_parms);
                    }

                    $scope.onSeriesHover = function (e) {
                        //console.log(kendo.format("event :: seriesHover ({0} : {1})", e.series.field, e.value));
                        //console.dir(e);
                        ////var chart = $("#chart").data("kendoChart");
                        ////chart.refresh();
                        //$.each(e.sender.dataSource.view(), function () {
                        //    // Clean up exploded state
                        //    this.explode = false;
                        //});

                        //// Disable animations
                        //e.sender.options.transitions = false;

                        //// Explode the current slice
                        //e.dataItem.explode = true;
                        //e.sender.refresh();
                        //e.sender.options.tooltip.visible = true;
                        //applyExplode(e);
                    };

                    //function applyExplode(e) {
                    //    //$.each(e.sender.dataSource.view(), function () {
                    //    //    // Clean up exploded state
                    //    //    this.explode = false;
                    //    //});

                    //    console.dir(e);
                    //    console.dir(e.sender.dataSource.view());

                    //    // Disable animations
                    //    e.sender.options.transitions = false;

                    //    // Explode the current slice
                    //    //e.dataItem.explode = true;

                    //    //e.sender.refresh();
                    //}

                    function isInArray(value, array) {
                        return array.indexOf(value) > -1;
                    }

                    function isNumber(n) {
                        return !isNaN(parseFloat(n)) && isFinite(n);
                    }

                    //$(window).on("resize", function () {
                    //    kendo.resize($(".chart-wrapper"));
                    //});

                    //$scope.$on('$viewContentLoaded', function () {
                    //    kendo.resize($("div.k-chart[data-role='chart']"));
                    //});

                    //$(window).resize(function () {
                    //    kendo.resize($("div.k-chart[data-role='chart']"));
                    //});

                    $scope.$watch(function () {
                        return $scope.listItems;
                    }, function (newValue) {

                        //console.log('$scope listItems');
                        //console.dir($scope.listItems);

                        var sum = 0;
                        var tempDataSource = [];

                        for (var i = 0; i < $scope.listItems.length; i++) {
                            if (angular.isDefined($scope.field) && $scope.field == 'sum_salaryhours' && isNumber(parseInt($scope.listItems[i][$scope.field]))) {
                                sum += parseInt($scope.listItems[i][$scope.field]);
                            }
                        }

                        var containsOther = false;
                        var indexOther = 0;

                        for (var i = 0; i < $scope.listItems.length; i++) {
                            if (angular.isDefined($scope.field) && $scope.field == 'sum_salaryhours' && (!isNumber(parseInt($scope.listItems[i][$scope.field])) || $scope.listItems[i][$scope.field] <= (sum / 100)) && $scope.model.chartType != 'column') {
                                if (isNumber(parseInt($scope.listItems[i][$scope.field])) && containsOther === true) {
                                    tempDataSource[indexOther][$scope.field] = (parseInt(tempDataSource[indexOther][$scope.field]) + parseInt($scope.listItems[i][$scope.field])).toString();
                                    //tempDataSource[indexOther].sum_salaryhours = (parseInt(tempDataSource[indexOther].sum_salaryhours) + parseInt($scope.listItems[i].sum_salaryhours)).toString();
                                } else if (containsOther === false) {
                                    tempDataSource.push(angular.copy($scope.listItems[i]));
                                    tempDataSource[i].item_name = 'Other';
                                    tempDataSource[i].color = "#00FFFF";
                                    indexOther = i;
                                    containsOther = true;
                                }
                            } else {
                                if (angular.isDefined($scope.multi) && $scope.multi == '1') {
                                    if (i !== 0 && $scope.listItems[i].item_id !== '-1') {
                                        if (angular.isDefined($scope.listItems[i].item_secondary)) {
                                            var orderbyVal = i;
                                            if (angular.isDefined($scope.listItems[i].orderby)) {
                                                if (isNumber($scope.listItems[i].orderby)) {
                                                    orderbyVal = parseInt($scope.listItems[i].orderby);
                                                } else {
                                                    orderbyVal = $scope.listItems[i].orderby;
                                                }
                                            }

                                            var tempSeries = {
                                                item_id: $scope.listItems[i].item_id,
                                                item_name: $scope.listItems[i].item_name,
                                                orderby: orderbyVal,
                                                item_path: $scope.listItems[i].item_path,
                                                item_parms: $scope.listItems[i].item_parms,
                                                item_state: $scope.listItems[i].item_state,
                                                gotolink: $scope.listItems[i].gotolink
                                            };
                                            for (var j = 0; j < $scope.listItems[i].item_secondary.length; j++) {
                                                var seriesName = 'item_col' + parseInt(j);

                                                tempSeries[seriesName] = parseFloat($scope.listItems[i].item_secondary[j].item_col);
                                            }
                                            tempDataSource.push(tempSeries);
                                        }
                                    } else {
                                        if (angular.isDefined($scope.listItems[i].item_secondary) && $scope.listItems[i].item_id !== '-1') {
                                            var visCount = 0;
                                            $scope.model.dataSeries = [];
                                            for (var j = 0; j < $scope.listItems[i].item_secondary.length; j++) {
                                                if (angular.isDefined($scope.listItems[i].item_secondary[j].visible_chart) && $scope.listItems[i].item_secondary[j].visible_chart == true) {
                                                    var seriesField = $scope.field + parseInt(j);
                                                    var seriesName = $scope.listItems[i].item_secondary[j];
                                                    if ($scope.listItems[i].item_id == '0' && $scope.legend == '1') {
                                                        $scope.model.dataSeries.push({ field: seriesField, categoryField: 'item_name', explodeField: 'explode', name: $scope.listItems[i].item_secondary[j].item_col });
                                                    } else {
                                                        $scope.model.dataSeries.push({ field: seriesField, categoryField: 'item_name', explodeField: 'explode' });
                                                    }
                                                    visCount++;
                                                }
                                            }
                                            if (visCount > 1) {
                                                if ($scope.chartType == 'line') {
                                                    $scope.model.chartType = $scope.chartType;
                                                    $scope.model.chartTypeAlign = 'center';
                                                } else {
                                                    $scope.model.chartType = $scope.model.chartTypeDefault;
                                                }
                                            } else {
                                                if (angular.isDefined($scope.chartType) && $scope.chartType != null && $scope.chartType != '' && isInArray($scope.chartType, $scope.model.acceptedChartTypes)) {
                                                    $scope.model.chartType = $scope.chartType;
                                                    if ($scope.chartType == 'line') {
                                                        $scope.model.chartTypeAlign = 'center';
                                                    }
                                                } else {
                                                    $scope.model.chartType = $scope.model.chartTypeDefault;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    tempDataSource.push($scope.listItems[i]);
                                }
                            }
                        }

                        //var series = $scope.model.dataSeries

                        //$scope.model.dataSeries = [{ field: 'item_id', categoryField: 'item_col', explodeField: 'explode' }];

                        //var temp = [{ item_id: '0', item_name: '2018', item_col1: '123', item_col2: '456', item_col3: '789' },
                        //            { item_id: '1', item_name: '2019', item_col1: '100', item_col2: '101', item_col3: '103' },
                        //            { item_id: '2', item_name: '2020', item_col1: '999', item_col2: '987', item_col3: '951' }]

                        if ($scope.listItems.length > 0) {
                            $scope.model.graphDataSource = new kendo.data.DataSource({
                                data: tempDataSource, //$scope.listItems,
                                //group: {
                                //    field: $scope.model.orderBy,
                                //    dir: "asc"
                                //},
                                //sort: [{
                                //    field: "item_name",
                                //    dir: "asc"
                                //}]
                                sort: {
                                    field: $scope.model.orderBy,
                                    dir: "asc"
                                }
                            });
                            //$scope.model.categoryAxis = "item_name"
                            //$scope.model.categoryAxis = { baseUnit: "fit", labels: { step: 2} }
                        }


                        //console.log('$scope.model.graphDataSource');
                        //console.dir($scope.model.graphDataSource);
                    }, true);

                    if (document.getElementsByTagName('html')?.[0]?.classList.contains('dark')) {
                        $scope.model.theme = 'materialBlack';
                    }
                    $scope.$watch(function () {
                        return $scope.chartType;
                    }, function (newValue) {
                        //console.log('Looking for the chartType ' + $scope.chartType);
                        if (angular.isDefined($scope.chartType) && $scope.chartType != null && $scope.chartType != '' && isInArray($scope.chartType, $scope.model.acceptedChartTypes)) {
                            $scope.model.chartType = $scope.chartType;
                            if (angular.isDefined($scope.multi) && $scope.multi == '1' && angular.isDefined($scope.listItems) && angular.isDefined($scope.listItems[0]) && angular.isDefined($scope.listItems[0].item_secondary)) {
                                var visCount = 0;
                                for (var j = 0; j < $scope.listItems[0].item_secondary.length; j++) {
                                    if (angular.isDefined($scope.listItems[0].item_secondary[j].visible_chart) && $scope.listItems[0].item_secondary[j].visible_chart == true) {
                                        visCount++;
                                    }
                                }
                                if (visCount > 1) {
                                    if ($scope.chartType == 'line') {
                                        $scope.model.chartType = $scope.chartType;
                                        $scope.model.chartTypeAlign = 'center';
                                    } else {
                                        $scope.model.chartType = $scope.model.chartTypeDefault;
                                    }
                                } else {
                                    $scope.model.chartType = $scope.chartType;
                                    if ($scope.chartType == 'line') {
                                        $scope.model.chartTypeAlign = 'center';
                                    }
                                }
                            }
                        } else if ($scope.chartType == 'L' || $scope.chartType == 'E' || $scope.chartType == 'C') {
                            $scope.model.chartType = 'pie';
                        } else {
                            $scope.model.chartType = 'column';
                        }
                    });

                    $scope.$watch(function () {
                        return $scope.orderBy;
                    }, function (newValue) {
                        if (angular.isDefined($scope.orderBy) && $scope.orderBy !== '') {
                            $scope.model.orderBy = $scope.orderBy;
                        }
                    });

                    $scope.$watch(function () {
                        return $scope.labels;
                    }, function (newValue) {
                        if (angular.isDefined($scope.labels) && $scope.labels !== '') {
                            $scope.model.labelsVis = true;
                            if ($scope.labels == '0') {
                                $scope.model.labelsVis = false;
                            }
                        }
                    });
                }
            };

            return directive;
        }]);
})();
