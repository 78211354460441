(function () {
    'use strict';

    const module = angular.module('imApp');

    /**
     * @typedef DataTask represents values for making a datatask request.
     * @property {number} method the procedure to make a request to.
     * @property {Object} parameters object containing parameters to send with the request.
     */

    /**
     * @typedef DialogFetch represents datatasks required to use a dialog.
     * @property {DataTask} get the datatask to use for getting the list of dialogs.
     * @property {DataTask} save the datatask for saving a new comment.
     * @property {DataTask} delete the datatask for deleting a comment.
     */

    /**
     * @typedef {Object} Heading represents a heading.
     * @property {string} text the text of the heading.
     * @property {string} translate whether to translate the heading or not.
     */

    /**
     * @typedef {Object} DialogOptions represents configuration for dialog.
     * @property {Heading} heading the configuration for the heading of the dialog.
     * @property {DialogFetch} fetch configuration of the datatasks in the dialog.
     */

    module.component('ttDialog', {
        templateUrl: 'views/components/directives/ttDialog/ttDialog.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<',
            /** @type DialogOptions */
            ttOptions: '<',
        },
        controller: [
            '$ihttp',
            'base64',
            function ($ihttp, base64) {
                const vm = this;


                /** @type DialogOptions */
                vm.options = {
                    keynames: {
                        commentKeyno: 'item_id',
                    },
                    fetch: {
                        get: {},
                        save: {},
                        delete: {},
                    },
                };

                vm.model = {
                    comment: '',
                    comments: [],
                };

                vm.loading = false;
                vm.ready = true;

                vm.dismiss = function () {
                    vm.modalInstance?.close();
                }

                vm.saveComment = async function () {
                    vm.ready = false;

                    if (vm.options.fetch.save.method) {

                        let parameters = {};

                        if (vm.options.fetch.save.parameters && vm.options.fetch.save.parameters instanceof Function) {
                            parameters = vm.options.fetch.save.parameters();
                            if (parameters instanceof Promise) parameters = await parameters;
                        } else if (vm.options.fetch.save.parameters) {
                            parameters = vm.options.fetch.save.parameters;
                        }

                        parameters = { ...parameters, messagetext: base64.encode(vm.model.comment) };

                        await $ihttp.post({ method: vm.options.fetch.save.method, parameters: parameters || {} });
                        await vm.loadComments();

                        vm.ready = true;
                        vm.model.comment = '';
                    }
                };

                vm.deleteComment = async function (commentKeyno) {
                    if (vm.options.fetch.delete.method) {
                        let parameters = {};

                        if (vm.options.fetch.delete.parameters && vm.options.fetch.delete.parameters instanceof Function) {
                            parameters = vm.options.fetch.delete.parameters();

                            if (parameters instanceof Promise) parameters = await parameters;
                        } else if (vm.options.fetch.delete.parameters) {
                            parameters = vm.options.fetch.delete.parameters;
                        }

                        parameters[vm.options.keynames.commentKeyno] = commentKeyno;

                        await $ihttp.post({ method: vm.options.fetch.delete.method, parameters: parameters || {} });

                        vm.loadComments();
                    }
                };




                vm.loadComments = async function () {
                    vm.loading = true;

                    if (vm.options.fetch.get.method) {
                        let parameters = {};

                        if (vm.options.fetch.get.parameters && vm.options.fetch.get.parameters instanceof Function) {
                            parameters = vm.options.fetch.get.parameters();

                            if (parameters instanceof Promise) parameters = await parameters;
                        } else if (vm.options.fetch.get.parameters) {
                            parameters = vm.options.fetch.get.parameters;
                        }

                        vm.model.comments = await $ihttp.post({ method: vm.options.fetch.get.method, parameters: parameters || {} });
                    }

                    vm.loading = false;
                };

                // #region ANGULAR LIFECYCLE

                vm.$onInit = function () { };

                vm.$onChanges = function (changes) {
                    if (changes?.resolve?.currentValue && changes.resolve.currentValue !== changes.resolve.previousValue) {
                        vm.options = mergeObjects(vm.options, changes.resolve.currentValue || {});

                        vm.loadComments();
                    }

                    if (changes?.ttOptions?.currentValue && changes?.ttOptions?.currentValue !== changes?.ttOptions?.previousValue) {
                        vm.options = mergeObjects(vm.options, changes.ttOptions.currentValue);

                        vm.loadComments();
                    }
                };

                vm.$onDestroy = function () { };


                // #endregion ANGULAR LIFECYCLE

                function mergeObjects(mergeA, mergeB) {
                    let combined = { ...mergeA };

                    Object.keys(mergeB).forEach((key) => {
                        if (mergeB?.[key]) {
                            if (typeof mergeB[key] === 'object' && !mergeB[key] instanceof Array) {
                                combined[key] = mergeObjects(mergeA[key], mergeB[key]);
                            } else {
                                combined[key] = mergeB[key];
                            }
                        }
                    });

                    return combined;
                }
            },
        ],
    });
})();
