import { Component } from '@angular/core';
import { User } from "@microsoft/microsoft-graph-types";

import { GraphAuthService } from '../auth/services/graph-auth.service';
import { CalendarService } from '../services/graph-calendar.service';
import { GraphGroupService } from '../services/graph-group.service';
import { GraphMailService } from '../services/graph-mail.service';
import { GraphUserService } from '../services/graph-user.service';

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.css']
})

export class GraphComponent {
    constructor(
        private authService: GraphAuthService,
        private calendarService: CalendarService,
        private groupService: GraphGroupService,
        private mailService: GraphMailService,
        private userService: GraphUserService
    ) {
        console.log('GraphComponent');
    }

    public async test() {
        console.log('test');

        let query = await this.calendarService.list();

        console.dir(query);

        let calendars = query.values?.filter((c) => c.isDefaultCalendar);

        if (calendars === undefined) return;

        let calendar = calendars[0];
        console.dir(calendar);

        const event = {
            subject: 'Hello Calendar!',
            body: {
                contentType: 'HTML',
                content: 'Ser dette bra ut?'
            },
            start: {
                dateTime: '2023-08-24T15:15:00',
                timeZone: 'Europe/Berlin'
            },
            end: {
                dateTime: '2023-08-24T15:20:00',
                timeZone: 'Europe/Berlin'
            },
            location: {
                displayName: 'Moa'
            },
            attendees: [{
                emailAddress: {
                    address: 'bjorn.solvang@insoft.net',
                    name: 'Bjørn'
                },
                type: 'required'
            }/*, {
                emailAddress: {
                    address: 'srs@insoft.net',
                    name: 'Svein Roger'
                },
                type: 'required'
                }*/
            ]
        };

        let newEvent = await this.calendarService.createEvent(event, {});

        console.log('newEvent');
        console.dir(newEvent);

        //let account = await this.authService.getAccount();

        //console.log('account');
        //console.dir(account);

        //let token = await this.authService.getAccessToken(account);

        //console.log(token);

        //let user = await this.userService.getUser(account.localAccountId, token);

        //console.dir(user);

        //await this.mailService.getMailList(token);

        //let query = await this.calendarService.calendarViewList(token, '2020-01-01T00:00:00-00:00', '2024-01-01T00:00:00-00:00');

        //console.log('AAA');
        //console.dir(query.values);

        //await query.next(token);

        //console.log('BBB');
        //console.dir(query.values);
    }
}
