(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('payCheck', {
        templateUrl: 'views/components/views/payCheck/payCheck.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'payCheckService', function ($stateParams, stateService, payCheckService) {
            
            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
    		vm.model = {
    			payCheckKeyno: $stateParams.paycheck_keyno,
                bgPayCheckMain: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'gb_paycheck_main_basic', color: 'primary', onClick: () => setPayCheckMainView('BASIC') },
                        { id: 'TOTRANSFER', label: 'gb_paycheck_main_totransfer', color: 'primary', onClick: () => setPayCheckMainView('TOTRANSFER') },
                        { id: 'FUNCTIONS', label: 'gb_paycheck_main_functions', color: 'primary', onClick: () => setPayCheckMainView('FUNCTIONS') }
                    ]
                },
                labourNameButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('laboursalarytypes', {
                            argtype: 'labour_no',
                            argvalue: vm.model.edit.labour_no
                        })
                    }
                ],
                edit: {},
    			itemsListPayCheckLs: []
    		};

            // #region BUTTON GROUP SINGLE FUNCTION

            let setPayCheckMainView = function (id) {
                vm.model.bgPayCheckMain.selected = vm.model.edit.gb_paycheck_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// ## LOAD PROCEDURE CALLS ##
            
    		payCheckService.loadPayCheckGet(vm.model.payCheckKeyno).then(function (data) {
    			vm.model.edit = angular.copy(data[0]);
                setPayCheckMainView(vm.model.edit.gb_paycheck_main);
                
    			loadPayCheckLs();
    		});
            
    		var loadPayCheckLs = function () {
    			if (angular.isUndefined(vm.model.edit.paycheck_keyno) === true) return;
    			if (vm.model.edit.paycheck_keyno === null) return;
    			if (vm.model.edit.paycheck_keyno.trim().length < 1) return;
    
    			vm.model.itemsListPayCheckLs = [];
    
    			payCheckService.loadPayCheckLList(vm.model.edit.paycheck_keyno).then(function (data) {
    				angular.copy(data, vm.model.itemsListPayCheckLs);
    			});
    		};
            
    		// ## REMEMBER VALUE FUNCTIONS ##
            
    		var canRun616 = function (variableName, parm) {
    			if (angular.isUndefined(variableName) === true) return false;
    			if (variableName === null) return false;
    			if (variableName === '') return false;
    			if (angular.isUndefined(vm.model.edit[parm]) === true) return false;
    			if (vm.model.edit[parm] === null) return false;
                
    			return true;
    		};
            
    		var rememberBgPayCheckMain = function () {
    			var variableNameBgPayCheckMain = 'w_paycheck.gb_paycheck_main';
    
    			if (canRun616(variableNameBgPayCheckMain, 'gb_paycheck_main') !== true) return;
    
    			payCheckService.remember(variableNameBgPayCheckMain, vm.model.edit.gb_paycheck_main);
    		};
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPayCheckMain = function (value) {
                vm.model.bgPayCheckMain.selected = value;
                vm.model.edit.gb_paycheck_main = vm.model.bgPayCheckMain.selected;

                rememberBgPayCheckMain();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
    	}]
    });
})();
