(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("productsConfigService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            selectProductsConfigSearchResultList: [],
            loadProductsConfigGet: function (argtype, headno, lineno) {
                return p2DataTaskService.call(1115, {
                    argtype: argtype,
                    headno: headno,
                    lineno: lineno
                });
            },
            loadProductConfigQuestionList: function (getProductConfig) {
                return p2DataTaskService.call(1116, getProductConfig);
            },
            search: function (searchstring, id, restriction_id) {
                var deferred = $q.defer();

                $ihttp.post({ method: 1117, parameters: {
                        chosen_searchstring: searchstring,
                        choice_prod_id: id,
                        restriction_prod_id: restriction_id
                }}).then(function (data) {
                    angular.copy(data, service.selectProductsConfigSearchResultList);
                    
                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1123, mergeObj);
            },
            loadProductsConfigKitKeyNoGet: function () {
                return p2DataTaskService.call(1574, {});
            },
            addConfig: function (prod_id, prodsize_keyno, prodchoice_keyno, quantity, input_price_ordval, input_disc, input_amount_ordval, argtype, headno, lineno, kit_keyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 1126, parameters: {
                        prod_id: prod_id,
                        prodsize_keyno: prodsize_keyno,
                        prodchoice_keyno: prodchoice_keyno,
                        quantity: quantity,
                        input_price_ordval: input_price_ordval,
                        input_disc: input_disc,
                        input_amount_ordval: input_amount_ordval,
                        argtype: argtype,
                        headno: headno,
                        lineno: lineno,
                        kit_keyno: kit_keyno
                    }
                }).then(function (data) {
                    //angular.copy(data, service.selectProductsConfigSearchResultList);
                    
                    //service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            }
            //loadProductsConfigSearchResult: function (chosen_searchstring, choice_prod_id, restriction_prod_id) {
            //    return p2DataTaskService.call(1117, {
            //        chosen_searchstring: chosen_searchstring,
            //        choice_prod_id: choice_prod_id,
            //        restriction_prod_id: restriction_prod_id
            //    });
            //}
        };

        return service;
    }]);
})();