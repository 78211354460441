(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('acVatCodes', {
        templateUrl: 'views/components/views/acVatCodes/acVatCodes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ttGridFactory', function (stateService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                gridAcVatCodeReady: false,
                gridDataReady: false
            };

            vm.gridAcVatCode = new ttGridFactory({
                rememberId: 'w_acvatacodes_acvatcode_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setOptionFunc((data) => optionFuncAcVatCode(data.data));

            let optionFuncAcVatCode = function (data) {
                //console.log('data: ');
                //console.dir(data.clickedCell.dataItem);
                //console.log('acvatcode_keyno: ' + data.clickedCell.dataItem.acvatcode_keyno);

                vm.gridData.dataTask.loadData = {
                    method: 3426,
                    parameters: { acvatcode_keyno: data.clickedCell.dataItem.acvatcode_keyno }
                };

                vm.model.gridDataReady = true;

                if (vm.gridData.gridfunc !== null) vm.gridData.gridfunc.read();
            };

            vm.gridData = new ttGridFactory({
                rememberId: 'w_' + stateService.getCurrentName() + '.' + 'grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridAcVatCode = function () {
                vm.gridAcVatCode.dataTask.loadData = {
                    method: 3425,
                    parameters: {}
                };

                vm.model.gridAcVatCodeReady = true;

                if (vm.gridAcVatCode.gridfunc !== null) vm.gridAcVatCode.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadGridAcVatCode();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
