import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@app/core/services/translate.service';
import { UserStore } from '@app/core/services/user.store';

@Component({
    selector: 'tt-date-cell-editor',
    templateUrl: './date-cell-editor.component.html',
    styleUrls: ['./date-cell-editor.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    /**
     * Refernce to the datetime html input element.
     */
    @ViewChild('inputRef')
    public inputRef?: ElementRef;

    /**
     * Cell editor parameters passed from the cell.
     */
    public params?: ICellEditorParams;

    public dateFormat?: string;

    /**
     * The date value.
     */
    public value: Date | null = null;

    /**
     * Whether the value in the cell has been edited since editing started.
     */
    public hasBeenEdited: boolean = false;

    public placeholder: string = '';

    @HostListener('keydown', ['$event'])
    public keydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            event.preventDefault();
            event.stopPropagation();
            this.params?.api.stopEditing(true);
            this.params?.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field!);
        }
    }

    constructor(private userStore: UserStore, translate: TranslateService) {
        this.userStore.dateFormatChanged.subscribe(async (format) => {
            this.dateFormat = format.replaceAll('m', 'M');
            this.placeholder = await translate.translate(format);
        });
    }

    /**
     * Sets the date value when changed.
     *
     * @param event string representation of the new date.
     */
    onDateChanged(event: Date | null) {
        this.hasBeenEdited = true;
        this.value = event;
    }

    agInit(params: ICellEditorParams): void {
        this.params = params;

        console.dir(this.params.data[this.params.column.getColId()]);
        this.value = !!this.params.data[this.params.column.getColId()] ? new Date(this.params.data[this.params.column.getColId()]) : null;
    }

    getValue() {
        return this.value;
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            this.inputRef?.nativeElement.focus();
            this.inputRef?.nativeElement.select();
        });
    }
}
