(function () {
    'use strict';

    angular.module("imApp").factory("validationService", ['$q', '$ihttp', '$uibModal', 'base64', 'modalListSelectFactory', function ($q, $ihttp, $uibModal, base64, modalListSelectFactory) {
        function showMessages(messages, convert) {
            if (angular.isUndefined(convert)) {
                convert = {
                    id: 'keyno',
                    name: 'message',
                    order: 'keyno'
                };
            }

            var modalListSelect = modalListSelectFactory.$create({
                list: messages,
                convert: convert,
                title: 'Error',
                sort: true,
                searchable: false,
                selectable: false,
                hideIcon: true
            });

            return modalListSelect.show();
        }

        function reAssembleObject(items) {
            let saveObj = {
                parameters: {}
            }

            angular.forEach(items, function (item) {
                if (angular.isUndefined(saveObj.parameters[item.id])) saveObj.parameters[item.id] = { data: [] };

                angular.forEach(item.data, function (data) {
                    saveObj.parameters[item.id].data.push({ id: data.id, value: data.field });
                });
            });

            return saveObj;
        }

        function validate(p2_datatask_keyno, dataItem) {
            var deferred = $q.defer();

            $ihttp.post({
                method: 2603,
                parameters: {
                    p2_datatask_keyno: p2_datatask_keyno,
                    data: dataItem
                }
            }).then(function (response) {
                if (response.haserror !== true) {
                    deferred.resolve({ haserror: false, validate: false });

                    return;
                }

                if (response.messages.length > 0) {
                    angular.forEach(response.messages, function (message) {
                        message.message = base64.urldecode(message.message);
                    });
                    showMessages(response.messages);

                    deferred.resolve({ haserror: true });

                    return;
                }

                if (angular.isUndefined(response.items)) {
                    deferred.resolve({ haserror: true });

                    return;
                }

                angular.forEach(response.items, function (item) {
                    item.label = base64.urldecode(item.label);

                    if (angular.isDefined(item.data)) {
                        angular.forEach(item.data, function (data) {
                            data.label = base64.urldecode(data.label);
                            data.type_data = base64.urldecode(data.type_data);
                            if (data.type_data.length > 0) data.type_data = angular.fromJson(data.type_data);
                        });
                    }
                });

                var modalInstance = $uibModal.open({
                    component: 'ttValidatorModal',
                    resolve: {
                        parameters: function () {
                            return response.items;
                        }
                    },
                    size: 'pst-ninetyfive',
                    backdrop: 'static'
                });

                modalInstance.result.then(function (response) {
                    $ihttp.post({
                        method: 2608,
                        parameters: {
                            p2_datatask_keyno: p2_datatask_keyno,
                            data: base64.urlencode(angular.toJson(reAssembleObject(response))) 
                        }
                    }).then(function (response) {
                        if (response.haserror === true) {
                            if (response.messages.length > 0) {
                                showMessages(response.messages);
                            }

                            deferred.resolve({ haserror: true });

                            return;
                        } else {
                            deferred.resolve({ haserror: false, validate: true });
                        }
                    });
                }, function () {
                    deferred.reject({ haserror: true });
                });
            });

            return deferred.promise;
        }

        var service = {
            dynamicValidationModal: function (p2_datatask_keyno, dataItem, deferred) {
                let doReturn = false;

                if (angular.isUndefined(deferred)) {
                    deferred = $q.defer();

                    doReturn = true
                }

                validate(p2_datatask_keyno, dataItem).then(function (response) {
                    if (response.haserror === true) {
                        deferred.resolve({ haserror: true });
                    } else {
                        if (angular.isDefined(response.validate) && response.validate === true) {
                            service.dynamicValidationModal(p2_datatask_keyno, dataItem, deferred);
                        } else {
                            deferred.resolve({ haserror: false });
                        }
                    }
                }, function () {
                    deferred.resolve({ haserror: true });
                });

                if (doReturn === true) {
                    return deferred.promise;
                }
            }
        };

        return service;
    }]);
})();
