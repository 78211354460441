(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epShipmentPreviousView', {
        templateUrl: 'views/components/views/epShipmentPreviousView/epShipmentPreviousView.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$stateParams', 'stateService', function ($stateParams, stateService) {
            var vm = this;
            vm.version = module.version;

            vm.isModal = false;

            var load = function (shipmentContainerKeyno) {
                vm.gridOptions = {
                    dataTaskKeyno: 2009,
                    parameters: {
                        shipmentcontainer_keyno: shipmentContainerKeyno
                    },
                    rememberId: 'epShipmentPreviousView'
                };
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve) && angular.isDefined(vm.resolve.shipmentContainerKeyno)) {
                    // when opened as modal
                    vm.isModal = true;

                    load(vm.resolve.shipmentContainerKeyno);
                }
            };

            if (angular.isDefined($stateParams.shipmentContainerKeyno)) {
                // when opened with state
                load($stateParams.shipmentContainerKeyno);
            }

            vm.cancel = function () {
                if (vm.isModal === true) {
                    vm.modalInstance.dismiss()
                } else {
                    stateService.back();
                }
            };
        }]
    });
})();
