(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectType', {
        templateUrl: 'views/components/views/serviceObjectType/serviceObjectType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'serviceObjectTypeService', function($stateParams, stateService, utilityService, serviceObjectTypeService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                serviceObjectTypeKeyNo: $stateParams.serviceobjecttype_keyno,
                settingType: {},
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            serviceObjectTypeService.loadServiceObjectTypeGet(vm.model.serviceObjectTypeKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.settingType);
            });
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveServiceObjectType = function () {
                vm.model.lockedSave = true;
                
                serviceObjectTypeService.save(vm.model.settingType).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();