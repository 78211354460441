(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderConfirmation', {
        templateUrl: 'views/components/views/orderConfirmation/orderConfirmation.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['orderConfirmationService', '$q', '$stateParams', '$uibModal', 'ieScreenBlockService', 'logisticService', 'rememberService', 'translateService', 'modalService', function (orderConfirmationService, $q, $stateParams, $uibModal, ieScreenBlockService, logisticService, rememberService, translateService, modalService) {
            var vm = this;

            var rememberIdGrid = 'w_orderConfirmationComponentGrid';
            var rememberIdSelect = 'w_orderConfirmationComponentSelect';

            var translations = [
            ];

            vm.arg = {
                type: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                value: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
            };

            vm.select = {
                businesscoNo: 0,
                dates: {
                    from: null,
                    to: null,
                    bindex: 1
                },
                statusId: 10
            };

            vm.companies = [];

            vm.selectListStatuses = [];

            vm.gridReady = false;


            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 3051,
                        parameters: function () {
                            return {
                                argtype: vm.arg.type,
                                argvalue: vm.arg.value,
                                businesscoNo: vm.select.businesscoNo,
                                dateFrom: vm.select.dates.from,
                                dateTo: vm.select.dates.to,
                                statusId: vm.select.statusId
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [{ name: 'sendOrderConfirmation', text: 'tt_send_order_confirmation', func: function (data) { sendOrderConfirmation(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return false; } }]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [{
                            name: 'resend',
                            text: '',
                            icon: 'fa-paper-plane',
                            func: function (data) {
                                console.log('data');
                                console.dir(data);

                                var modalInstance = $uibModal.open({
                                    animation: true,
                                    backdrop: 'static',
                                    component: 'ttConfirm',
                                    resolve: {
                                        message: function () {
                                            return '';
                                        }
                                    }
                                });

                                modalInstance.result.then(function (confirmed) {
                                    if (confirmed !== true) return;

                                    ieScreenBlockService.start();

                                    orderConfirmationService.resend(data.order_internal_no).then(function (result) {
                                        ieScreenBlockService.stop();

                                        if (result[0].errorcode !== '0') {
                                            modalService.show({
                                                type: 'danger',
                                                title: 'Varsel',
                                                message: result[0].errormessage,
                                                buttons: [{
                                                    label: 'OK',
                                                    cssClass: 'btn-danger',
                                                    action: function (dialogItself) {
                                                        dialogItself.close();
                                                    }
                                                }]
                                            });
                                        }
                                        else {
                                            modalService.show({
                                                type: 'success',
                                                title: 'Varsel',
                                                message: 'Done',
                                                buttons: [{
                                                    label: 'OK',
                                                    cssClass: 'btn-success',
                                                    action: function (dialogItself) {
                                                        dialogItself.close();
                                                    }
                                                }]
                                            });
                                        }

                                        vm.gridOptions.gridfunc.read();
                                    });
                                });
                            },
                            disabled: function (data) {
                                //return data.errorcode !== '0';
                                return false;
                            }
                        }]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    //serverSideHandling: true
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };


            // #region LOAD PROCEDURE FUNCTION CALLS

            var remember = function () {
                rememberService.remember(rememberIdSelect, vm.select);

                vm.gridOptions.gridfunc.read();
            };

            var load = function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdSelect, true));
                promises.push(logisticService.listBusinessCompanies(true));
                promises.push(translateService.translateBatch(translations))

                $q.all(promises).then(function (response) {
                    vm.select.businesscoNo = response[0].businesscoNo;
                    vm.select.dates.from = response[0].dates.from;
                    vm.select.dates.to = response[0].dates.to;
                    vm.select.dates.bindex = response[0].dates.bindex;
                    vm.select.statusId = response[0].statusId;

                    angular.copy(response[1], vm.companies);

                    if (vm.gridOptions.gridfunc?.rebind) {
                        vm.gridOptions.gridfunc.rebind();
                    } else {
                        vm.gridReady = true;
                    }
                });

                return deferred.promise;
            };

            vm.onSelectChanged = function (value, id) {
                switch (id) {
                    case 'vm.select.businesscoNo':
                        vm.select.businesscoNo = value;
                }
                switch (id) {
                    case 'vm.select.statusId':
                        vm.select.statusId = value;
                }

                remember();
            };

            vm.onSelectedDateChanged = function (fom, tom, bindex) {
                vm.select.dates.from = fom;
                vm.select.dates.to = tom;

                if (bindex >= 0) {
                    vm.select.dates.bindex = bindex;
                }

                remember();
            };

            vm.$onInit = function () {
                load();
            };

            orderConfirmationService.getStatusSelectList().then(function (result) {
                angular.copy(result, vm.selectListStatuses);
            });


            // #endregion LOAD PROCEDURE FUNCTION CALLS



            // #region BUTTON FUNCTIONS

            var getIsSelectedItemsParms = function (key) {
                if (!key) return [];
                let dirtyItems = vm.gridOptions.gridfunc.getDirtyRows();
                let isSelectedItems = [];
                let keyList = [];

                if (angular.isArray(key)) {
                    keyList = key;
                } else {
                    keyList.push(key);
                }

                angular.forEach(dirtyItems, function (item) {
                    if (item && item.is_selected === true) {
                        let data = {};
                        angular.forEach(keyList, function (k) {
                            if (item[k]) data[k] = item[k];
                        });

                        isSelectedItems.push(data);
                    }
                });

                return isSelectedItems;
            }

            var sendOrderConfirmation = async function () {
                let errorcode = 0;
                let errormessage = 'orders processed correctly: ';
                var parmItems = getIsSelectedItemsParms(['order_internal_no']);
                console.dir(parmItems);
                vm.gridOptions.gridfunc.gridProgress(true);
                if (parmItems.length > 0) {
                    for (let i = 0; i < parmItems.length; i++) {
                        let item = parmItems[i];
                        try {
                            let result = await orderConfirmationService.resend(item.order_internal_no);

                            if (result[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'danger',
                                    title: 'Varsel',
                                    message: result[0].errormessage + "\n" + errormessage + "\n" + "order_internal_no with error: " + item.order_internal_no,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-danger',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                                errorcode = -1;
                                break;
                            }
                            else {
                                errormessage = errormessage + String(item.order_internal_no) + ", "
                            }
                        } catch (error) {
                            console.error(error);
                            errorcode = -1;
                            break;
                        }
                    }
                }
                vm.gridOptions.gridfunc.gridProgress(false);

                if (errorcode === 0) {
                    modalService.show({
                        type: 'success',
                        title: 'Varsel',
                        message: 'Done',
                        buttons: [{
                            label: 'OK',
                            cssClass: 'btn-success',
                            action: function (dialogItself) {
                                dialogItself.close();
                            }
                        }]
                    });
                }

                vm.gridOptions.gridfunc.rebind();

            }


            // #endregion BUTTON FUNCTIONS


        }]
    });
})();
