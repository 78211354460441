(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("cargonizerApiService", ['$q', '$http','$ihttp','$window', 'modalService', 'xml2jsonService', 'consoleService', 'p2DataTaskService',  function ($q,$http, $ihttp,$window, modalService, xml2jsonService, consoleService, p2DataTaskService) {
        var service = {
        	transportProductRecord: {
        		productIdentifier: '',
        		productName: '',
        		minItems: '',
        		maxItems: '',
        		minWeight: '',
        		maxWeight: '',
        		services: []

        	},
        	transportServiceRecord: {
        		serviceIdentifier: '',
        		serviceName: '',

        	},
        	transportAgreementRecord: {
        		description: '',
        		id: '',
        		number: '',
        		carrierName: '',
        		carrierIdentifier: '',
        		products: []

        	},
            validateStatus: {
                status: 0,
                message: ''
            },
            getTransportAgreements: function(businessCoNo){
                var deferred = $q.defer();
                var parms_getTransportAgreements = {
                    method: 347,
                    parameters: {   
                    	businessco_no: businessCoNo                     
                    }
                };

                
                console.log('getTransportAgreements');
                console.log(angular.toJson(parms_getTransportAgreements));
                
                $ihttp.post(parms_getTransportAgreements).then(function (data) {
                
                    service.loaded = true;
                    deferred.resolve();
                });
                return deferred.promise;
            },
        	getTransportAgreements_org: function(businessCoNo){
        		var deferred = $q.defer();

                var parms_getTransportAgreements = {
                    method: 347,
                    parameters: {   
                    	businessco_no: businessCoNo                     
                    }
                };

                
                console.log('getTransportAgreements');
                console.log(angular.toJson(parms_getTransportAgreements));
                
                $ihttp.post(parms_getTransportAgreements).then(function (data) {
                    
                    //console.log('parms_getTransportAgreements data');
                    //console.log   (angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;

                    /*$http({
	                    url: post_url,
	                    method: "GET",
	                    headers: {
	                       'X-Cargonizer-Key': post_key,
	                       'X-Cargonizer-Sender': post_sender,
	                       'Content-Type': 'application/json'
	                    }, 
	                    data: ''
	                })
	                .then(function(result) {
	                    console.log('getTransportAgreements result: ',angular.toJson(result));*/

                    var parms_createCargonizerPost = {
                        method: 405,
                        parameters: {   
                            url: post_url,
                            key: post_key,
                            sender: post_sender,
                            method: 'GET',
                            ctype: '',
                            data: ''                   
                        }
                    }
                    
                    console.log('parms_createCargonizerPost data');
                    console.log(angular.toJson(parms_createCargonizerPost));

                    $ihttp.post(parms_createCargonizerPost).then(function (data) {



                        /*console.log('parms_createCargonizerPost data XML:>');
                        console.log('');
                        console.log('');
                        console.log('');
                        console.log(data[0].data);
                        //console.dir(data[0].data);
                        console.log('');
                        console.log('');
                        console.log('');
                        console.log('**********************************');
                        console.log('');
                        console.log('');
                        console.log('');
                        console.log('');

                        */
                        var json_result = xml2jsonService.xmlJson(data[0].data);
                        /*
                        console.log('json Result: ',angular.toJson(json_result['transport-agreements']['transport-agreement']));
                        console.log('');
                        console.log('');
                        console.log('');
                        console.log('');
                        console.log('**********************************');*/
                        //service.loaded = true;
                        //deferred.resolve();
                        //console.log('json Result: ',angular.toJson(json_result));


                        
                        json_result = json_result['transport-agreements']['transport-agreement'];
                        consoleService.log('json Result: ',angular.toJson(json_result));

                        var carriers = {};
                        var products = [];
                        var services = [];
                        var product_identifier = '';

                        angular.forEach(json_result, function(item){
                            /*console.log('*********************');
                            console.log('Angular foreach: ',angular.toJson(item));
                            console.log('*********************');*/


                            if (item.carrier){
                                //console.log('getTransportAgreements Item.carrier: ',angular.toJson(item.carrier));

                                carriers[item.carrier.identifier] = {
                                    agreement_id: item.id.__text,
                                    identifier: item.carrier.identifier,
                                    name: item.carrier.name
                                }

                                var carrier_identifier = item.carrier.identifier;
                                if (item.products){
                                    if (item.products.product){

                                        if (angular.isArray(item.products.product)){
                                            angular.forEach(item.products.product, function(pitem){
                                                if (!angular.isDefined(pitem.name)){
                                                    console.log('***** getTransportAgreements [',carrier_identifier,'] Item.products ** : ',angular.toJson(pitem), ' **');
                                                } else {
                                                    console.log('getTransportAgreements [',carrier_identifier,'] Item.products ** : ',pitem.name, ' **');
                                                }

                                                product_identifier = pitem.identifier;
                                                var rwov = 0;
                                                if (pitem.requires_weight_or_volume == 'true'){
                                                    rwov = 1;
                                                }
                                                products.push({
                                                    carrier_identifier: carrier_identifier,
                                                    name: pitem.name,
                                                    identifier: pitem.identifier,
                                                    min_items: pitem.min_items,
                                                    max_items: pitem.max_items,
                                                    min_weight: pitem.min_weight,
                                                    max_weight: pitem.max_weight,
                                                    require_weight_or_volume: rwov 
                                                });

                                                if (pitem.services){
                                                    if (pitem.services.service){
                                                        angular.forEach(pitem.services.service, function(sitem){
                                                            //console.log('getTransportAgreements Item.service ** : ',sitem.name, ' **');
                                                            services.push({
                                                                carrier_identifier: carrier_identifier,
                                                                product_identifier: product_identifier,
                                                                name: sitem.name,
                                                                identifier: sitem.identifier
                                                            });
                                                        });
                                                    }
                                                }                                                    
                                            });
                                        } else {
                                            var pitem = item.products.product;
                                            product_identifier = pitem.identifier;
                                            var rwov = 0;
                                            if (pitem.requires_weight_or_volume == 'true'){
                                                rwov = 1;
                                            }
                                            products.push({
                                                carrier_identifier: carrier_identifier,
                                                name: pitem.name,
                                                identifier: pitem.identifier,
                                                min_items: pitem.min_items,
                                                max_items: pitem.max_items,
                                                min_weight: pitem.min_weight,
                                                max_weight: pitem.max_weight,
                                                require_weight_or_volume: rwov 
                                            });

                                            if (pitem.services){
                                                if (pitem.services.service){
                                                    angular.forEach(pitem.services.service, function(sitem){
                                                        //console.log('getTransportAgreements Item.service ** : ',sitem.name, ' **');
                                                        services.push({
                                                            carrier_identifier: carrier_identifier,
                                                            product_identifier: product_identifier,
                                                            name: sitem.name,
                                                            identifier: sitem.identifier
                                                        });
                                                    });
                                                }
                                            }
                                        }

                                        
                                    }
                                }
                            }
                              
                            //var cr = item.carrier;
                            
                            
                        });

                        var output = [];
                        angular.forEach(carriers, function(item){
                            output.push({
                                agreement_id: item.agreement_id,
                                identifier: item.identifier,
                                name: item.name
                            });
                        });

                        

                        /*angular.forEach(json_result, function(item){
                            var carrier_identifier = item.carrier.identifier;
                            if (item.products){
                                if (item.products.product){
                                    angular.forEach(item.products.product, function(pitem){
                                        console.log('getTransportAgreements [',carrier_identifier,'] Item.products ** : ',pitem.name, ' **');
                                        product_identifier = pitem.identifier;
                                        
                                        products.push({
                                            carrier_identifier: carrier_identifier,
                                            name: pitem.name,
                                            identifier: pitem.identifier,
                                            min_items: pitem.min_items,
                                            max_items: pitem.max_items,
                                            min_weight: pitem.min_weight,
                                            max_weight: pitem.max_weight
                                        });

                                        //services::
                                        if (pitem.services){
                                            if (pitem.services.service){
                                                angular.forEach(pitem.services.service, function(sitem){
                                                    //console.log('getTransportAgreements Item.service ** : ',sitem.name, ' **');
                                                    services.push({
                                                        carrier_identifier: carrier_identifier,
                                                        product_identifier: product_identifier,
                                                        name: sitem.name,
                                                        identifier: sitem.identifier
                                                    });
                                                });
                                            }
                                        }
                                        //var srv = pitem.services;
                                        //console.log('SErvices: ',angular.toJson(pitem.services));
                                    });
                                }
                            }
                        });
                        */

                        //console.log('getTransportAgreements Products: ',angular.toJson(products));

                        var parms_setTransportAgreements = {
                            method: 357,
                            parameters: {   
                                businessco_no: businessCoNo,
                                records: output,
                                product_records: products,
                                service_records: services                  
                            }
                        };

                        console.log('parms_setTransportAgreements products only: ', angular.toJson(products));

                        $ihttp.post(parms_setTransportAgreements).then(function (data) {

                            //console.log('parms_setTransportAgreements data');
                            //console.log(angular.toJson(data[0]));


                            service.loaded = true;
                            deferred.resolve();
                        });
                        //service.loaded = true;
                        //    deferred.resolve();
                        
                        
	                });
                });
                
                return deferred.promise;
        	},
        	freigtCostRecord: {
        		grossPrice: '',
        		netPrice: ''
        	},	
        	getCargonizerFreightCost: function(consignmentId){
        		var deferred = $q.defer();

                var parms_getCargonizerFreightCost = {
                    method: 350,
                    parameters: {   
                    	consignment_keyno: consignmentId                     
                    }
                };

                
                console.log('getCargonizerFreightCost');
                console.log(angular.toJson(parms_getCargonizerFreightCost));
                
                $ihttp.post(parms_getCargonizerFreightCost).then(function (data) {
                    
                    console.log('parms_getCargonizerFreightCost data');
                    console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;
                    var post_data = data[0].post_data;

                    $http({
	                    url: post_url,
	                    method: "POST",
	                    headers: {
	                       'X-Cargonizer-Key': post_key,
	                       'X-Cargonizer-Sender': post_sender,
	                       'Content-Type': ':application/xml'
	                    }, 
	                    data: post_data
	                })
	                .then(function(result) {
	                    console.log('getCargonizerFreightCost result: ',angular.toJson(result))
	                    console.dir(result);
	                    console.log('Data to return: ',result.data);

	                    var xml_parsed = xml2jsonService.xmlJson(result.data);
	                    var xml_reparsed = xml2jsonService.jsonXml(xml_parsed);

	                    console.log('Data parsed: ', angular.toJson(xml_parsed));
	                    console.log('Data re parsed: ', xml_reparsed);
	                    /*var parms_setCargonizerFreightCost = {
		                    method: 351,
		                    parameters: {   
		                    	consignment_result: result.data                     
		                    }
		                };

		                console.log('setCargonizerFreightCost');
                		console.log(angular.toJson(parms_setCargonizerFreightCost));

		                $ihttp.post(parms_setCargonizerFreightCost).then(function (data) {

		                	console.log('parms_setCargonizerFreightCost data');
                    		console.log(angular.toJson(data));


		                	service.loaded = true;
	                    	deferred.resolve();

		                });*/	
                        service.loaded = true;
                        deferred.resolve();                    
	                });
                });

                return deferred.promise;
        	},
        	cargonizerPackage: {
        		cargonizerBundleId: '',
        		cargonizerPackageId: '',
        		cargonizerPackageNumber: ''
        	},
        	cargonizerConsignment: {
        		cargonizerConsignmentId: '',
        		cargonizerConsignmentNumber: '',
        		cargonizerConsignmentPdf: '',
        		cargonizerWaybillPdf: '',
        		cargonizerTrackingUrl: '',
        		cargonizerPackages: []
        	},
            cargonizerPrinters: {
                records: []
            },

            validateCargonizerConsignment: function(consignmentId,deliveryMethod,colliCount,weightValue,volumeValue){
                

                return p2DataTaskService.call(404, {   
                    consignment_keyno: consignmentId,
                    deliverymethod_no: deliveryMethod,
                    colli: colliCount,
                    weight: weightValue,
                    volum : volumeValue         
                });

                            
            },
            splitString:  function(input, splitChar, splitIndex) {
                try{
                    return input.split(splitChar)[splitIndex];
                }catch(e){
                    return 0;
                }                
            },

            createCargonizerConsignment: function (consignmentId, deliveryMethod, colliCount, weightValue, volumeValue) {
                var deferred = $q.defer();

                var parms_createCargonizerConsignment = {
                    method: 348,
                    parameters: {
                        consignment_keyno: consignmentId,
                        deliverymethod_no: deliveryMethod,
                        colli: colliCount,
                        weight: weightValue,
                        volum: volumeValue
                    }
                };


                console.log('createCargonizerConsignment');
                console.log(angular.toJson(parms_createCargonizerConsignment));

                $ihttp.post(parms_createCargonizerConsignment).then(function (data) {

                    console.log('parms_createCargonizerConsignment data');
                    console.log(angular.toJson(data[0]));

                    //service.loaded = true;
                    //deferred.resolve();

                    if (data[0].consignment_keyno > 0) {
                        var parms_updateConsignmentDelivered = {
                            method: 418,
                            parameters: {
                                consignment_keyno: data[0].consignment_keyno
                            }
                        };

                        console.log('parms_updateConsignmentDelivered');
                        console.log(angular.toJson(parms_updateConsignmentDelivered));

                        $ihttp.post(parms_updateConsignmentDelivered).then(function (data) {

                            console.log('parms_updateConsignmentDelivered data');
                            console.log(angular.toJson(data[0]));

                            service.loaded = true;
                            deferred.resolve();
                        });
                    } else {
                        service.loaded = true;
                        deferred.resolve();    
                    }
                    

                    //service.loaded = true;
                    //deferred.resolve();

                });

                return deferred.promise;
            },

        	createCargonizerConsignment_org: function(consignmentId,deliveryMethod,colliCount,weightValue,volumeValue){
        		var deferred = $q.defer();

                var parms_createCargonizerConsignment = {
                    method: 348,
                    parameters: {   
                    	consignment_keyno: consignmentId,
                        deliverymethod_no: deliveryMethod,
                        colli: colliCount,
                        weight: weightValue,
                        volum : volumeValue                   
                    }
                };

                
                console.log('createCargonizerConsignment');
                console.log(angular.toJson(parms_createCargonizerConsignment));
                
                $ihttp.post(parms_createCargonizerConsignment).then(function (data) {
                    
                    console.log('parms_createCargonizerConsignment data');
                    console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;
                    var post_data = data[0].post_data;
                    var post_method = 'POST';

                    /*if (post_url.indexOf('consignments/') > 0){
                        post_method = 'PUT';
                    }*/
                    
                    //post_data = post_data.replace('id=\"\"','');

                    var parms_createCargonizerPost = {
                        method: 405,
                        parameters: {   
                            url: post_url,
                            key: post_key,
                            sender: post_sender,
                            method: post_method,
                            ctype: ':application/xml',
                            data: post_data                   
                        }
                    }
                    
                    console.log('parms_createCargonizerPost',angular.toJson(parms_createCargonizerPost));
                    
                    $ihttp.post(parms_createCargonizerPost).then(function (data) {


                        console.log('parms_createCargonizerPost data');
                        console.log(angular.toJson(data));
                        
                        var json_result = xml2jsonService.xmlJson(data[0].data);
                        console.log('json Result: ',angular.toJson(json_result));
                        
                        var ssccs = [];
                        if (typeof json_result.consignments.consignment.id.__text != "undefined"){
                            
                            var remote_consignment_id = json_result.consignments.consignment.id.__text;
                            var remote_consignment_number = json_result.consignments.consignment.number;
                            var remote_tracking_url = json_result.consignments.consignment['tracking-url'];
                            var remote_waybill_pdf = json_result.consignments.consignment['waybill-pdf'];
                            remote_waybill_pdf = remote_waybill_pdf.replace('%5B%5D','[]');
                            console.log('Consignment_id: ',remote_consignment_id);

                            if (angular.isDefined(json_result.consignments.consignment.bundles)){
                                if (angular.isArray(json_result.consignments.consignment.bundles.bundle)){
                                    //consoleService.log('---> Array');
                                    angular.forEach(json_result.consignments.consignment.bundles.bundle,function(item){
                                        var keyno = service.splitString(item.description,' ',0);
                                        var bundle_id = item.id.__text;
                                        if (angular.isDefined(item.pieces.piece)){
                                            var piece=item.pieces.piece;
                                            ssccs.push({
                                                        sscc_keyno: keyno,
                                                        sscc_id: piece.id.__text,
                                                        sscc_bundle_id: bundle_id,
                                                        sscc_number: piece['number-with-checksum']});
                                        }
                                    });
                                } else {
                                    var bundle = json_result.consignments.consignment.bundles.bundle;
                                    var keyno = service.splitString(bundle.description,' ',0);
                                    var bundle_id = bundle.id.__text;
                                    if (angular.isDefined(bundle.pieces.piece)){
                                        var piece=bundle.pieces.piece;
                                        ssccs.push({
                                                    sscc_keyno: keyno,
                                                    sscc_id: piece.id.__text,
                                                    sscc_bundle_id: bundle_id,
                                                    sscc_number: piece['number-with-checksum']});
                                    }
                                }
                            }

                            //if (angular.isArray(item.products.product)){
                            

                            var parms_updateCargonizerConsignment = {
                                method: 363,
                                parameters: {   
                                    consignment_keyno: consignmentId,
                                    cargonizer_consignment_id: remote_consignment_id,
                                    consignment_number: remote_consignment_number,
                                    tracking_url: remote_tracking_url,
                                    waybill_pdf: remote_waybill_pdf,
                                    sscc_records: ssccs,

                                }
                            };


                            console.log('parms_updateCargonizerConsignment');
                            console.log(angular.toJson(parms_updateCargonizerConsignment));

                            $ihttp.post(parms_updateCargonizerConsignment).then(function (data) {

                                console.log('parms_updateCargonizerConsignment data');
                                console.log(angular.toJson(data[0]));

                                var parms_updateConsignmentDelivered = {
                                    method: 418,
                                    parameters: {   
                                        consignment_keyno: consignmentId
                                    }
                                };

                                console.log('parms_updateConsignmentDelivered');
                                console.log(angular.toJson(parms_updateConsignmentDelivered));

                                $ihttp.post(parms_updateConsignmentDelivered).then(function (data) {

                                    console.log('parms_updateConsignmentDelivered data');
                                    console.log(angular.toJson(data[0]));

                                    service.loaded = true;
                                    deferred.resolve();
                                });
                            });
                        } else {
                            service.loaded = true;
                            deferred.resolve();
                        }
                        

                    });
                    
                    //service.loaded = true;
                    //deferred.resolve();
                   
                });

                return deferred.promise;
        	},


            
            createGetObject: function (url, apiKey, senderId, success) {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                //xhr.setRequestHeader("Authorization", "Basic " + btoa("username:password"));
                xhr.setRequestHeader("X-Cargonizer-Key", apiKey);
                xhr.setRequestHeader("X-Cargonizer-Sender", senderId);
                //'X-Cargonizer-Key:!postkey\x0AX-Cargonizer-Sender:!sender\x0AAccept!ctype'
                xhr.responseType = "blob";
                xhr.onreadystatechange = function() {
                    if (xhr.readyState == 4) {
                        if (success) success(xhr.response);
                    }
                };
                xhr.send(null);
            },
            getCargonizerWaybill: function(consignmentId,waybillUrl){
                var deferred = $q.defer();

                var parms_infoCargonizerConsignment = {
                    method: 419,
                    parameters: {   
                        consignment_keyno: consignmentId                     
                    }
                };

                
                console.log('parms_infoCargonizerConsignment');
                console.log(angular.toJson(parms_infoCargonizerConsignment));
                
                $ihttp.post(parms_infoCargonizerConsignment).then(function (data) {
                    
                    console.log('parms_infoCargonizerConsignment data');
                    console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_consignment_id = data[0].post_external_id;
                    var post_data = data[0].post_data;
                    var post_url = 'http://cargonizer.no/printers.xml';
                    
                    var popup = $window.open('_blank');

                    $http({
                        url: waybillUrl,
                        method: "GET",
                        withCredentials: true,
                        responseType:'arraybuffer',
                        headers: {
                           // 'Access-Control-Allow-Headers': 'ontent-Type',
                            //'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
                            'Access-Control-Allow-Origin': '*',
                            //'X-Cargonizer-Key': post_key,
                            //'X-Cargonizer-Sender': post_sender,
                            //'Content-Type': 'application/xml'
                        }
                    }).success(function(response){
                        var file = new Blob([response]);
                        var fileURL = URL.createObjectURL(file);
                        popup.location = url;
                        service.loaded = true;
                        deferred.resolve();
                    });

                    /*var popup = $window.open('_blank');
                    service.createGetObject(waybillUrl, post_key, post_sender, function(blob) {
                        var url = URL.createObjectURL(blob);
                        popup.location = url;

                        //&& model.p2ConsignmentGet.waybill_pdf !== ''
                        service.loaded = true;
                        deferred.resolve();
                    });*/

                });

                return deferred.promise;
            },

        	deleteCargonizerConsignment: function(consignmentId){
        		var deferred = $q.defer();

                var parms_deleteCargonizerConsignment = {
                    method: 349,
                    parameters: {   
                    	consignment_keyno: consignmentId                     
                    }
                };

                
                console.log('deleteCargonizerConsignment');
                console.log(angular.toJson(parms_deleteCargonizerConsignment));
                
                $ihttp.post(parms_deleteCargonizerConsignment).then(function (data) {
                    
                    console.log('parms_deleteCargonizerConsignment data');
                    console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;
                    var post_data = data[0].post_data;


                    var parms_createCargonizerPost = {
                        method: 405,
                        parameters: {   
                            url: post_url,
                            key: post_key,
                            sender: post_sender,
                            method: 'DELETE',
                            ctype: '',//:text/xml,application/json,text/plain,application/xml,text/html,*/*\nAccept-Charset: *\nContent-type:charset=utf-8',
                            data: post_data                   
                        }
                    }
                    

                    $ihttp.post(parms_createCargonizerPost).then(function (data) {


                        console.log('parms_createCargonizerPost data');
                        console.log(angular.toJson(data));
                        
                        //var json_result = xml2jsonService.xmlJson(data[0].data);
                        //console.log('json Result: ',angular.toJson(json_result));


                    /*$http({
	                    url: post_url,
	                    method: "DELETE",
	                    headers: {
	                       'X-Cargonizer-Key': post_key,
	                       'X-Cargonizer-Sender': post_sender,
	                       'Content-Type': 'application/xml'
	                    }, 
	                    data: post_data
	                })
	                .then(function(result) {
	                    console.log('deleteCargonizerConsignment result: ',angular.toJson(result))*/


                        var parms_updateCargonizerConsignment = {
                            method: 363,
                            parameters: {   
                                consignment_keyno: consignmentId,
                                cargonizer_consignment_id: '',
                                consignment_number: '',
                                tracking_url: ''

                            }
                        };


                        console.log('parms_updateCargonizerConsignment');
                        console.log(angular.toJson(parms_updateCargonizerConsignment));

                        $ihttp.post(parms_updateCargonizerConsignment).then(function (data) {

                            console.log('parms_updateCargonizerConsignment data');
                            console.log(angular.toJson(data[0]));

                            service.loaded = true;
                            deferred.resolve();

                        });

	                    
	                    //service.loaded = true;
	                    //deferred.resolve();
	                });
                });

                return deferred.promise;
            },

            printCargonizerConsignmentReport: function (consignmentId, printerId, docarcFilename) {
                var deferred = $q.defer();

                var parms_printCargonizerConsignment = {
                    method: 1224,
                    parameters: {
                        consignment_keyno: consignmentId,
                        cargonizer_printer_id: printerId,
                        docarc_filename: docarcFilename
                    }
                };


                console.log('parms_printCargonizerConsignment');
                console.log(angular.toJson(parms_printCargonizerConsignment));

                $ihttp.post(parms_printCargonizerConsignment).then(function (data) {
                    console.log('parms_printCargonizerConsignment:',angular.toJson(data));
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },

            printCargonizerConsignment: function(consignmentId,printerId){
                var deferred = $q.defer();

                var parms_printCargonizerConsignment = {
                    method: 420,
                    parameters: {   
                        consignment_keyno: consignmentId,
                        cargonizer_printer_id: printerId                     
                    }
                };

                
                console.log('parms_printCargonizerConsignment');
                console.log(angular.toJson(parms_printCargonizerConsignment));
                
                $ihttp.post(parms_printCargonizerConsignment).then(function (data) {
                    
                    console.log('parms_printCargonizerConsignment data');
                    console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;
                    var post_data = data[0].post_data;

                    service.loaded = true;
                    deferred.resolve();
                    
                    if (post_url != ''){
                        
                        var parms_printCConsignment = {
                            method: 405,
                            parameters: {   
                                url: post_url,
                                key: post_key,
                                sender: post_sender,
                                method: 'POST',
                                ctype: '',
                                data: ''                   
                            }
                        }
                        
                        console.log('print data: ',angular.toJson(parms_printCConsignment));

                        $ihttp.post(parms_printCConsignment).then(function (data) {


                            console.log('parms_printCConsignment data');
                            console.log(angular.toJson(data));
                            service.loaded = true;
                            deferred.resolve();

                        });
                    }                    
                });

                return deferred.promise;
            },
            getCargonizerPrintersLocal: function(consignmentId){
                var deferred = $q.defer();

                var parms_infoCargonizerConsignment = {
                    method: 419,
                    parameters: {   
                        consignment_keyno: consignmentId                     
                    }
                };

                
                //console.log('parms_infoCargonizerConsignment');
                //console.log(angular.toJson(parms_infoCargonizerConsignment));
                
                $ihttp.post(parms_infoCargonizerConsignment).then(function (data) {
                    
                    //console.log('parms_infoCargonizerConsignment data');
                    //console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_consignment_id = data[0].post_external_id;
                    var post_data = data[0].post_data;
                    var post_url = 'http://cargonizer.no/printers.xml';

                    var parms_infoCargonizerPrintersSelectList = {
                        method: 428,
                        parameters: {   
                            cargonizer_api_key: post_key,
                            cargonizer_sender_id: post_sender                     
                        }
                    };
                    

                    $ihttp.post(parms_infoCargonizerPrintersSelectList).then(function (data) {


                        //console.log('parms_infoCargonizerPrintersSelectList data');
                        //console.log(angular.toJson(data));
                        service.cargonizerPrinters.records = [];
                        angular.forEach(data,function(item){
                            //if (item.printer_name == '') {
                                item.printer_name = item.cargonizer_printer_name + ' ('+item.printer_name+')';
                            //}
                            service.cargonizerPrinters.records.push(item);
                        });
                        
                        
                        service.loaded = true;
                        deferred.resolve();

                        //service.loaded = true;
                        //deferred.resolve();
                    });
                });

                return deferred.promise;
            },
            getCargonizerPrinters_org: function(consignmentId){
                var deferred = $q.defer();

                var parms_infoCargonizerConsignment = {
                    method: 419,
                    parameters: {   
                        consignment_keyno: consignmentId                     
                    }
                };

                
                console.log('parms_infoCargonizerConsignment');
                console.log(angular.toJson(parms_infoCargonizerConsignment));
                
                $ihttp.post(parms_infoCargonizerConsignment).then(function (data) {
                    
                    console.log('parms_infoCargonizerConsignment data');
                    console.log(angular.toJson(data[0]));
                    
                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_consignment_id = data[0].post_external_id;
                    var post_data = data[0].post_data;
                    var post_url = 'http://cargonizer.no/printers.xml';

                    var parms_getCargonizerPrinters = {
                        method: 405,
                        parameters: {   
                            url: post_url,
                            key: post_key,
                            sender: post_sender,
                            method: 'GET',
                            ctype: '',//:text/xml,application/json,text/plain,application/xml,text/html,*/*\nAccept-Charset: *\nContent-type:charset=utf-8',
                            data: ''                   
                        }
                    }
                    

                    $ihttp.post(parms_getCargonizerPrinters).then(function (data) {


                        console.log('parms_getCargonizerPrinters data');
                        console.log(angular.toJson(data));
                        
                        var json_result = xml2jsonService.xmlJson(data[0].data);
                        console.log('Printer Result: ',angular.toJson(json_result));
                        service.cargonizerPrinters.records = [];
                        if (json_result.printers.printer){
                            if (angular.isArray(json_result.printers.printer)){
                                
                                angular.forEach(json_result.printers.printer, function(item){
                                    service.cargonizerPrinters.records.push({
                                        printer_id: item.id.__text,
                                        printer_name: item.name
                                    });
                                });

                            } else {
                                var item = json_result.printers.printer;
                                service.cargonizerPrinters.records.push({
                                    printer_id: item.id.__text,
                                    printer_name: item.name
                                });
                            }
                        }

                    

                        service.loaded = true;
                        deferred.resolve();

                        //service.loaded = true;
                        //deferred.resolve();
                    });
                });

                return deferred.promise;
            },

            getCargonizerPrintersByKeyAndId: function(apiKey,senderId){
                var deferred = $q.defer();
                    
                
                var post_key = apiKey;
                var post_sender = senderId;
                var post_data = '';
                var post_url = 'http://cargonizer.no/printers.xml';

                var parms_getCargonizerPrinters = {
                    method: 405,
                    parameters: {   
                        url: post_url,
                        key: post_key,
                        sender: post_sender,
                        method: 'GET',
                        ctype: '',//:text/xml,application/json,text/plain,application/xml,text/html,*/*\nAccept-Charset: *\nContent-type:charset=utf-8',
                        data: ''                   
                    }
                }

                console.log('parms_getCargonizerPrinters');
                console.log(angular.toJson(parms_getCargonizerPrinters));
                

                $ihttp.post(parms_getCargonizerPrinters).then(function (data) {


                    console.log('parms_getCargonizerPrinters data');
                    console.log(angular.toJson(data));
                    
                    var json_result = xml2jsonService.xmlJson(data[0].data);
                    console.log('Printer Result: ',angular.toJson(json_result));
                    
                    service.cargonizerPrinters.records = [];

                    if (json_result.printers.printer){
                        if (angular.isArray(json_result.printers.printer)){
                            
                            angular.forEach(json_result.printers.printer, function(item){
                                service.cargonizerPrinters.records.push({
                                    printer_id: item.id.__text,
                                    printer_name: item.name
                                });
                            });

                        } else {
                            var item = json_result.printers.printer;
                            service.cargonizerPrinters.records.push({
                                printer_id: item.id.__text,
                                printer_name: item.name
                            });
                        }
                    }

                

                    service.loaded = true;
                    deferred.resolve();

                    //service.loaded = true;
                    //deferred.resolve();
                });


                return deferred.promise;
            },

            saveCargonizerPrinters: function(businessCoNo){
                var deferred = $q.defer();
                var parms_getPrinters = {
                    method: 422,
                    parameters: {   
                    	businessco_no: businessCoNo                     
                    }
                };

                
                console.log('getPrinters');
                
                $ihttp.post(parms_getPrinters).then(function (data) {
                
                    service.loaded = true;
                    deferred.resolve();
                });
                return deferred.promise;
            },

        	saveCargonizerPrinters_org: function(apiKey,senderId){
                //cargonizerPrinters
                var deferred = $q.defer();

                var printers = [];

                var post_key = apiKey;
                var post_sender = senderId;
                var post_data = '';
                var post_url = 'http://cargonizer.no/printers.xml';

                var parms_getCargonizerPrinters = {
                    method: 405,
                    parameters: {   
                        url: post_url,
                        key: post_key,
                        sender: post_sender,
                        method: 'GET',
                        ctype: '',//:text/xml,application/json,text/plain,application/xml,text/html,*/*\nAccept-Charset: *\nContent-type:charset=utf-8',
                        data: ''                   
                    }
                }

                console.log('parms_getCargonizerPrinters');
                console.log(angular.toJson(parms_getCargonizerPrinters));
                

                $ihttp.post(parms_getCargonizerPrinters).then(function (data) {

                    var json_result = xml2jsonService.xmlJson(data[0].data);
                    console.log('Printer Result: ',angular.toJson(json_result));


                    if (json_result.printers.printer){
                        if (angular.isArray(json_result.printers.printer)){
                            
                            angular.forEach(json_result.printers.printer, function(item){
                                printers.push({
                                    cargonizer_printer_id: item.id.__text,
                                    cargonizer_name: item.name
                                });
                            });

                        } else {
                            var item = json_result.printers.printer;
                            printers.push({
                                cargonizer_printer_id: item.id.__text,
                                cargonizer_name: item.name
                            });
                        }
                    }


                    var parms_cargonizer_printers_save = {
                        method: 422,
                        parameters: {   
                            cargonizer_api_key: apiKey,
                            cargonizer_sender_id: senderId,
                            records: printers                     
                        }
                    };

                    console.log('parms_cargonizer_printers_save');
                    console.log(angular.toJson(parms_cargonizer_printers_save));

                    $ihttp.post(parms_cargonizer_printers_save).then(function (data) {


                        console.log('parms_cargonizer_printers_save data');
                        console.log(angular.toJson(data));


                        service.loaded = true;
                        deferred.resolve();

                    });
                    /*service.loaded = true;
                    deferred.resolve();
                    */


                });

 
                return deferred.promise;
            },


        	transferCargonizerConsignment: function (consignmentbatch_keyno) {
                var deferred = $q.defer();
            
                var parms_transferCargonizerConsignment = {
                    method: 479,
                    parameters: {
                        consignmentbatch_keyno: consignmentbatch_keyno
                    }
                };
            
                consoleService.log('parms_transferCargonizerConsignment: ',angular.toJson(parms_transferCargonizerConsignment));
            
                $ihttp.post(parms_transferCargonizerConsignment).then(function (data) {
                    consoleService.log('parms_transferCargonizerConsignment data: ', angular.toJson(data));
                    
                    /*var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;
                    var post_data = data[0].post_data;
                    
                    if (post_url != ''){
                        
                        var parms_transferConsignment = {
                            method: 405,
                            parameters: {   
                                url: post_url,
                                key: post_key,
                                sender: post_sender,
                                method: 'GET',
                                ctype: '',
                                data: ''                   
                            }
                        }
                        
                        console.log('parms_transferConsignment: ',angular.toJson(parms_transferConsignment));

                        $ihttp.post(parms_transferConsignment).then(function (data) {


                            console.log('parms_transferConsignment data');
                            console.log(angular.toJson(data));

                            var parms_transferredCargonizerConsignment = {
                                method: 480,
                                parameters: {
                                    consignmentbatch_keyno: consignmentbatch_keyno
                                }
                            };

                            consoleService.log('parms_transferredCargonizerConsignment: ',angular.toJson(parms_transferredCargonizerConsignment));
            
                            $ihttp.post(parms_transferredCargonizerConsignment).then(function (data) { 

                                service.loaded = true;
                                deferred.resolve();
                                
                            });
                        
                        });
                        
                    } else {
                        service.loaded = true;
                        deferred.resolve();
                    }*/
                    service.loaded = true;
                    deferred.resolve();
                    
                });
            
                return deferred.promise;
            },



            transferCargonizerConsignment_org: function (consignmentbatch_keyno) {
                var deferred = $q.defer();

                var parms_transferCargonizerConsignment = {
                    method: 479,
                    parameters: {
                        consignmentbatch_keyno: consignmentbatch_keyno
                    }
                };

                consoleService.log('parms_transferCargonizerConsignment: ', angular.toJson(parms_transferCargonizerConsignment));

                $ihttp.post(parms_transferCargonizerConsignment).then(function (data) {
                    consoleService.log('parms_transferCargonizerConsignment data: ', angular.toJson(data));

                    var post_sender = data[0].post_sender;
                    var post_key = data[0].post_key;
                    var post_url = data[0].post_url;
                    var post_data = data[0].post_data;

                    if (post_url != '') {

                        var parms_transferConsignment = {
                            method: 405,
                            parameters: {
                                url: post_url,
                                key: post_key,
                                sender: post_sender,
                                method: 'GET',
                                ctype: '',
                                data: ''
                            }
                        }

                        console.log('parms_transferConsignment: ', angular.toJson(parms_transferConsignment));

                        $ihttp.post(parms_transferConsignment).then(function (data) {


                            console.log('parms_transferConsignment data');
                            console.log(angular.toJson(data));

                            var parms_transferredCargonizerConsignment = {
                                method: 480,
                                parameters: {
                                    consignmentbatch_keyno: consignmentbatch_keyno
                                }
                            };

                            consoleService.log('parms_transferredCargonizerConsignment: ', angular.toJson(parms_transferredCargonizerConsignment));

                            $ihttp.post(parms_transferredCargonizerConsignment).then(function (data) {

                                service.loaded = true;
                                deferred.resolve();

                            });

                        });
                    } else {
                        service.loaded = true;
                        deferred.resolve();
                    }

                });

                return deferred.promise;
            }


        }
        return service;
    }]);
})();