(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('userSettingsDialogueUserInfo', {
        templateUrl: 'views/components/views/userSettingsDialogueUserInfo/userSettingsDialogueUserInfo.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$location', 'messageService', 'userService', 'ieScreenBlockService', function($stateParams, $location, messageService, userService, ieScreenBlockService) {
    
            var vm = this;
    
    
            vm.model = {
                portalUserKeyno: $stateParams.portalUserKeyno,
                user: {},
                sendSmsToUser: false,
                sendEmailToUser: false,
                sendSmsToMe: false,
                sendEmailToMe: false,
                userLink: ''
            };
    
            console.log('userService.userEditCache.user');
            console.dir(userService.userEditCache.user);
    
            var load = function () {
                ieScreenBlockService.start();
    
                userService.details(vm.model.portalUserKeyno).then(function (details) {
                    angular.copy(details, vm.model.user);
    
                    userService.userEditCache.set(vm.model.user);
    
                    ieScreenBlockService.stop();
                });
            };
    
            if (userService.userEditCache.user === null) {
                load();
            } else {
                angular.copy(userService.userEditCache.user, vm.model.user);
            }
    
            vm.copyToClipboard = function() {
                window.prompt("Kopier til clipboard: Ctrl+C, Enter", vm.model.userLink);
            };
    
            vm.generateUserLink = function () {
                console.log('generateUserLink');

                ieScreenBlockService.start();
                
                userService.generateUserLink(vm.model.user.user_id_portal).then(function (data) {
                    var absUrl = $location.absUrl();
    
                    vm.model.userLink = absUrl.substring(0, absUrl.search("#") + 2) + 'mainmenu/userlink/' + data.payload + '/' + data.hash;
    
                    ieScreenBlockService.stop();
                }, ieScreenBlockService.stop);
            };
    
            vm.sendP2UserLoginInfo = function () {
                ieScreenBlockService.start();
    
                messageService.sendUserLink(vm.model.userLink, vm.model.portalUserKeyno, vm.model.sendSmsToUser, vm.model.sendSmsToMe, vm.model.sendEmailToUser, vm.model.sendEmailToMe).then(function() {
                    ieScreenBlockService.stop();
                }, ieScreenBlockService.stop);
            };
        }]
    });
})();
