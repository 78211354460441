(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('createPoPu', {
        templateUrl: 'views/components/views/createPoPu/createPoPu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'createPoPuService', 'stateService', function($stateParams, createPoPuService, stateService) {
            var vm = this;

            createPoPuService.loadP2WCreatePoPuGet($stateParams.stock_keyno).then(function () {
                vm.p2_w_createpopu_get = createPoPuService.p2_w_createpopu_get;
            });
            
            vm.goToProduct = function (index) {
                stateService.go('product', {
                    prod_id: vm.p2_w_createpopu_get.prod_id
                });
            };
            
            vm.saveP2WCreatePoPuCreate = function () {
                createPoPuService.saveP2WCreatePoPuCreate().then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();