<tt-page *ngIf="ready" class="col-xs-12 sp-0 height-full" style="container: picturebank-container / inline-size">
    <tt-page-heading *ngIf="!inlineComponent" [ttModel]="get.page_heading"></tt-page-heading>
    <div class="col-xs-12 sp-0 picturebank_cards">
        <tt-card class="col-xs-12 col-md-5 sp-5" *ngIf="!!get.page_heading">
            <tt-input class="col-xs-12 sp-5" ttLabel="picturebank_filename" [(ttModel)]="filename" ttReadonly></tt-input>
            <tt-input-number class="col-xs-12 sp-5" ttLabel="picturebank_filesize" [(ttModel)]="get.filesize_mb" ttReadonly ttDecimals="3" ttFormatNumber></tt-input-number>
            <tt-input class="col-xs-12 sp-5" ttLabel="picturebank_url" [(ttModel)]="get.full_url" ttReadonly [ttButtons]="urlInputButtons"></tt-input>
            <tt-input class="col-xs-6 sp-5" ttLabel="picturebank_author" [(ttModel)]="get.reg_by_portal_user_name" ttReadonly></tt-input>
            <tt-datetime class="col-xs-6 sp-5" ttLabel="picturebank_created" [(ttModel)]="get.reg_datetime" ttReadonly></tt-datetime>
            <tt-checkbox ttLabel="picturebank_main" [(ttModel)]="get.picture_main"></tt-checkbox>
            <tt-button-save class="col-xs-12 col-md-6 sp-5 pull-right" ttText="picturebank_save" [ttLoading]="locks.save" (ttClick)="save()" tt-text-align="center"></tt-button-save>
        </tt-card>
        <tt-card class="col-xs-12 col-md-7 sp-5" *ngIf="!!get.page_heading">
            <img class="col-xs-12 p-5 picturebank_img" [src]="url" [style.maxWidth]="imageWidth + 'px'" />
            <tt-button
                class="col-xs-12 col-md-6 p-5 pull-right"
                ttType="danger"
                [ttLoading]="locks.delete"
                ttLoadingText=""
                ttText="picturebank_delete"
                ttIcon="far fa-trash"
                (ttClick)="deleteImage()"
            ></tt-button>
        </tt-card>
    </div>
</tt-page>
