(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('biReportEmbed', {
        templateUrl: 'views/components/views/biReportEmbed/biReportEmbed.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['biReportEmbedService', '$stateParams', function (biReportEmbedService, $stateParams) {
            var vm = this;

            var param = $stateParams.bi_report_keyno || undefined;

            vm.model = {
                biReportEmbed: {}
            }

            vm.$onInit = () => {
                biReportEmbedService.get(param).then(function (data) {
                    angular.copy(data[0], vm.model.biReportEmbed);
                });
            }
        }]
    });
})();