(function () {
    'use strict';

    angular.module('imApp').component('ttDatasourceModal', {
        templateUrl: 'views/components/dynamics/views/ttDynamicEditor/ttDatasourceModal.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: [function () {
            var vm = this;

            vm.datasources = [];

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                if (angular.isDefined(vm.resolve.parameters.datasources) && angular.isObject(vm.resolve.parameters.datasources)) {
                    angular.forEach(vm.resolve.parameters.datasources, function (ds) {
                        vm.datasources.push({
                            item_id: ds.keyno,
                            item_name: ds.datasource_id,
                            item_glyphicon: 'glyphicon-unchecked',
                            item_glyphicon_color: 'red',
                            orderby: 1000,
                            item_state: 'customaction',
                            item_parms: '',
                            item_action: function (item) {
                                angular.forEach(vm.datasources, function (vmds) {
                                    if (vmds.item_id === item.item_id) {
                                        vmds.item_glyphicon = 'glyphicon-check';
                                        vmds.item_glyphicon_color = 'green';
                                    } else {
                                        vmds.item_glyphicon = 'glyphicon-unchecked';
                                        vmds.item_glyphicon_color = 'red';
                                    }
                                });
                            }
                        })
                    });
                }
            };

            vm.$onDestroy = function () {
                var selectedDatasource = null;

                angular.forEach(vm.datasources, function (vmds) {
                    if (vmds.item_glyphicon === 'glyphicon-check') {
                        selectedDatasource = vmds;
                    }
                });

                vm.resolve.parameters.callback({
                    tt_datasource_keyno: selectedDatasource.item_id,
                    datasource_id: selectedDatasource.item_name
                });
            };
        }]
    });
})();