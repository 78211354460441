(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceHeadEhf', {
        templateUrl: 'views/components/views/invoiceHeadEhf/invoiceHeadEhf.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$document', 'invoiceHeadEhfService', function ($stateParams, $document, invoiceHeadEhfService) {
            var vm = this;

            vm.model = {
                linkId: 'formInvoiceHeadEhfLink',
                p2WInvoiceHeadEhfGet: {},
                p2WInvoiceHeadEhfCreate: {}
            };
            
            var loadP2WInvoiceHeadEhfGet = function () {
                invoiceHeadEhfService.genericFunction(909, {
                    invoice_no: $stateParams.invoice_no
                }).then(function (data) {
                    vm.model.p2WInvoiceHeadEhfGet = angular.copy(data[0]);
                });
            };
            
            loadP2WInvoiceHeadEhfGet();
            
            vm.createInvoiceEhf = function () {
                invoiceHeadEhfService.genericFunction(910, vm.model.p2WInvoiceHeadEhfGet).then(function (data) {
                    vm.model.p2WInvoiceHeadEhfCreate = angular.copy(data[0]);
                    vm.model.p2WInvoiceHeadEhfGet.ehf_content = vm.model.p2WInvoiceHeadEhfCreate.ehf_content;
                });
            };
            
            vm.saveToFile = function () {
                var elem = $document[0].getElementById(vm.model.linkId);
                var text = vm.model.p2WInvoiceHeadEhfGet.ehf_content;

                var xmlData = new Blob([text], { type: 'text/xml' });
                var xmlUrl = URL.createObjectURL(xmlData);

                elem.setAttribute('href', xmlUrl);
                elem.setAttribute('download', vm.model.p2WInvoiceHeadEhfGet.filename);
                elem.click();
            };
        }]
    });
})();