(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('visitorConfirm', {
        templateUrl: 'views/components/views/visitorConfirm/visitorConfirm.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'visitorConfirmService', 'stateService', function($stateParams, visitorConfirmService, stateService) {
    
            var vm = this;
    
    
            vm.visitor_confirm = visitorConfirmService.visitor_confirm;
    
            vm.hide_in = {
                inout: $stateParams.inout
            };
    
            var isSaving = false;
            vm.visitor_confirm.locked = '0'; // locked må vere definert 0 viss den ikkje er definert i ei prosedyre på sida
    
            visitorConfirmService.loadPersonGetVisitor($stateParams.inout, $stateParams.pers_no).then(function () {
                vm.visitor_confirm = visitorConfirmService.visitor_confirm;
            });
    
            vm.saveVisitorConfirm = function () {
                if (isSaving == true || vm.visitor_confirm.locked == '1') return;
    
                isSaving = true;
                vm.visitor_confirm.locked = '1';
    
                visitorConfirmService.saveVisitorConfirm().then(function () {
                    stateService.back();
                });
    
                isSaving = false;
            }
        }]
    });
})();