(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('specialCustProdCalc', {
        templateUrl: 'views/components/views/specialCustProdCalc/specialCustProdCalc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'specialCustProdCalcService', 'watcherFactory', function($stateParams, $timeout, specialCustProdCalcService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
            
            // ########
            // VARIABLE
            // ########
    
            vm.scpcService = specialCustProdCalcService;
            vm.showvar = 0;
            var timer = null;
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            specialCustProdCalcService.loadP2SpecialVpCustCalcList().then(function () {
                vm.p2_special_vp_cust_calc_list = specialCustProdCalcService.p2_special_vp_cust_calc_list;
            });
    
            // ####################
            // WATCH PROCEDURE CALL
            // ####################
    
            watcher.$watch(function () {
                return vm.scpcService.p2_special_vp_cust_calc_list.prod_id;
            }, function (newVal) {            
                if (vm.scpcService.p2_special_vp_cust_calc_list.prod_id !== '') {
                    specialCustProdCalcService.getP2SpecialVpCustCalc($stateParams.cust_no, vm.scpcService.p2_special_vp_cust_calc_list.prod_id).then(function () {
                        vm.p2_special_vp_cust_calc_get = specialCustProdCalcService.p2_special_vp_cust_calc_get;
    
                        if (vm.p2_special_vp_cust_calc_get.discount !== '' && vm.p2_special_vp_cust_calc_get.sold_freight !== '') {
                            vm.calcCustomers();
                        }
                    });
                }            
            });
    
            // ##############################
            // UPDATE FUNCTION PROCEDURE CALL
            // ##############################
    
            vm.calcCustomers = function (index) {
                if (timer) $timeout.cancel(timer);
                
                timer = $timeout(function () {
                    var ln_price = Number(vm.p2_special_vp_cust_calc_get.price);
                    var ln_discount = Number(vm.p2_special_vp_cust_calc_get.discount);
                    var ln_sold_freight = Number(vm.p2_special_vp_cust_calc_get.sold_freight);
                    var ln_bonus = Number(vm.p2_special_vp_cust_calc_get.bonus);
                    var ln_weight = Number(vm.p2_special_vp_cust_calc_get.weight);
    
                    specialCustProdCalcService.getP2SpecialVpCalcCustomers($stateParams.cust_no, vm.p2_special_vp_cust_calc_list.prod_id, ln_price, ln_discount, ln_bonus, ln_sold_freight, ln_weight, vm.p2_special_vp_cust_calc_get.postplace_id).then(function () {
                        vm.p2_special_vp_calc_customers_get = specialCustProdCalcService.p2_special_vp_calc_customers_get;
                        vm.showvar = 1;
                    });
                }, 1000);
            }
        }]
    });
})();