(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("oeeStateEquipmentsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_oeestate_equipment_list: {
                item_id: '',
                item_name: '',
                item_state: '',
                item_parms: '',
                orderby: '',
                item_glyphicon: '',
                records: []
            },
            loadP2OeeStateEquipmentList: function (argtype, argvalue) {
                var deferred = $q.defer();

                var parms_p2_oeestate_equipment_list = {
                    method: 243,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parms_p2_oeestate_equipment_list).then(function (data) {
                    service.p2_oeestate_equipment_list.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_oeestate_equipment_list.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();