(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("activityTypeLangsService", ['$ihttp', function ($ihttp) {
        let service = {
            listLanguages: function (parms) {
                return $ihttp.post({
                    method: 1238,
                    parameters: parms || {}
                });
            },
            saveObj: function (inputList) {
                return $ihttp.post({
                    method: 1239,
                    parameters: inputList
                });
            }
        };

        return service;
    }]);
})();
