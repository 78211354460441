import { AfterContentInit, Component, ContentChild, ContentChildren, Input, OnChanges, QueryList, SimpleChanges } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { OverflowMenuComponent } from '../overflow-menu/overflow-menu.component';

/**
 * Represents a button element allowing for multiple elements to be connected
 * to the button. Supports other tt-button elements and one single overflow menu.
 * The tt-button-multi element attributes will be used for the main button element, additional buttons are
 * added as children inside the tt-button-multi element.
 *
 * @example ```html
 *    <tt-button-multi class="col-xs-4 sp-0" ttText="save">
 *      <tt-button ttIcon="far fa-cog"></tt-button>
 *      <tt-button ttIcon="far fa-user"></tt-button>
 *      <tt-overflow-menu>
 *          <tt-overflow-menu-item ttText="user" ttIcon="far fa-user" (ttClick)="log('clicked')"></tt-overflow-menu-item>
 *      </tt-overflow-menu>
 *   </tt-button-multi>
 * ```
 */
@Component({
    selector: 'tt-button-multi',
    templateUrl: './button-multi.component.html',
    styleUrls: ['./button-multi.component.css'],
})
export class ButtonMultiComponent extends ButtonComponent implements AfterContentInit, OnChanges {
    @ContentChildren(ButtonComponent)
    public buttons: QueryList<ButtonComponent> = new QueryList();

    @ContentChild(OverflowMenuComponent)
    public menu?: OverflowMenuComponent;

    @Input()
    public ttOptions?: ttButtonMultiOptions;

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttOptions']?.currentValue) {
            this.ttText = this.ttOptions?.text || '';
            this.ttIcon = this.ttOptions?.icon || '';
        }
    }
    public hasVisibleItem() {
        return (this.ttOptions?.menu.items?.filter((item) => (!!item.hidden ? item.hidden() : false) === false) || []).length > 0;
    }
    ngAfterContentInit(): void {
        if (this.menu) {
            this.menu.ttType = this.ttType;
        }

        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.buttons.forEach((button) => {
                    button.style = {
                        button: { height: info.height + 'px', minWidth: info.height + 'px' },
                        locketButton: { height: info.height + 'px', minWidth: info.height + 'px' },
                    };
                });

                this.setStyle();
            }
        });
    }
}

export interface ttButtonMultiOptions {
    text?: string;
    icon?: string;
    iconAlign?: 'right' | 'left';
    iconPullRight?: boolean;
    click: (event: MouseEvent) => unknown;
    buttons: any[];
    menu: ttOverflowMenuOptions;
}

export interface ttOverflowMenuOptions {
    icon?: string;
    items?: ttOverflowMenuItemOptions[];
}

export interface ttOverflowMenuItemOptions {
    text?: string;
    icon?: string;
    danger?: boolean;
    click: (event: any) => unknown;
    tooltipText?: () => string;
    hidden?: () => boolean;
    disabled?: () => boolean;
    hasDivider?: boolean;
}
