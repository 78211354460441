(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("activityTypeActStatusesService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1338,
                    parameters: parms || {}
                });
            },
            listStatuses: function (parms) {
                return $ihttp.post({
                    method: 1337,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
