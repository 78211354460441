(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('travelWageSetMenu', {
        templateUrl: 'views/components/views/travelWageSetMenu/travelWageSetMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'travelWageSetMenuService', function ($stateParams, travelWageSetMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let travelWageSetKeyno = $stateParams.travelwageset_keyno;
            
            vm.model = {
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadMenu = function () {
                travelWageSetMenuService.getMenu({ travelwageset_keyno: travelWageSetKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
