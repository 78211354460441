import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import imAppModule, { default as imApp } from '../../ng-app/app.module.ajs';

import { ITranslation, TranslateService } from '../core/services/translate.service';
import { DataTaskService } from '../core/services/data-task.service';
import { UtilityService } from '../core/services/utility.service';
import { DataCacheService } from '../modules/tt-data-cache/services/data-cache.service';
import { BislabService } from '../modules/bislab/services/bislab.service';
import { ThemeService } from '@app/services/theme.service';
import { Settings, AppSettingsService } from '../core/services/app-settings.service';
import { DataResponse } from '../core/models/data-response';
import { PasswordInputComponent } from '../core/components/password-input/password-input.component';
import { MultiSelectComponent } from '../core/components/multi-select/multi-select.component';
import { FieldGroupComponent } from '../core/components/field-group/field-group.component';
import { PageHeadingComponent } from '@app/core/components/typography/page-heading/page-heading.component';
import { FileUploaderComponent } from '@app/core/components/file-uploader/file-uploader.component';
import { IdportenService } from './idporten.service';
import { ButtonMultiComponent } from '../core/components/button-multi/button-multi.component';
import { PdfService } from './pdf.service';
import { GridComponent } from '../core/components/grid/grid.component';
import { HtmlProcEditorComponent } from '../core/components/html-proc-editor/html-proc-editor.component';
import { PopupService } from '../core/services/popup.service';
import { SirvService } from './sirv.service';

declare var fallback: any;

@Injectable()
export class AppConfigService {
    private readonly CLIENT_SESSION_ID_KEY: string = 'clientSessionId';
    private _clientSessionId: string = '';

    constructor(private http: HttpClient, private appSettings: AppSettingsService, private dcs: DataCacheService) {
        // BJS 20241126 - Set unique session id for this browser instance
        this.setClientSessionIdIfNotExists();
    }

    get settings(): Settings {
        this.appSettings.clientSessionId = this._clientSessionId;

        return this.appSettings.settings;
    }

    get clientSessionId(): string {
        return this._clientSessionId;
    }

    public load(): Promise<Settings> {
        return new Promise<Settings>((resolve) => {
            let self = this;

            function login() {
                const parts = window.location.href.split('/');

                const index = parts.indexOf('mainmenu');

                var url = self.appSettings.settings.loginUrl;

                if (parts.length === index + 4) {
                    if (parts[index + 1] === 'userlink') {
                        // userlink login
                        url = self.appSettings.settings.loginUrlUserlink + '/' + parts[index + 2] + '/' + parts[index + 3];
                    } else {
                        // assume this is login with credentials from app
                        url = self.appSettings.settings.loginUrlCredentials + '/' + parts[index + 1] + '/' + parts[index + 2] + '/' + parts[index + 3];
                    }

                    return;
                }

                // "normal" login
                window.location.href = url + `&csi=${self.appSettings.clientSessionId}`;
            }

            this.http.get<Settings>('/api/settings').subscribe({
                next: (result) => {
                    this.appSettings.settings = result;

                    if (!result.isAuthorized) {
                        login();

                        return;
                    }

                    this.dcs.InitCache<ITranslation>('translations_' + result.companyId + '_' + result.user?.languageId);

                    fallback.load(
                        {
                            jQueryui: ['//cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js', './assets/js/jquery-ui/jquery-ui-1.12.1.min.js?v=' + result.version],
                            SparkMD5: ['//cdnjs.cloudflare.com/ajax/libs/spark-md5/3.0.0/spark-md5.min.js', './assets/js/spark-md5/spark-md5.min.js?v=' + result.version],
                            moment: ['//cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment-with-locales.min.js', './assets/js/moment/moment-with-locales-2.18.1.min.js?v=' + result.version],
                            notify: ['//cdnjs.cloudflare.com/ajax/libs/mouse0270-bootstrap-notify/3.1.7/bootstrap-notify.min.js', './assets/js/bootstrap-notify/bootstrap-notify-3.1.5.min.js?v=' + result.version],
                            PhoneFormat: './assets/js/phone-format-global/phone-format-global.min.js?v=' + result.version,
                            uuid: './assets/js/uuid/uuid.js?v=' + result.version,
                            'kendo.cultures.no': ['./assets/js/kendo.culture.no/kendo.culture.no.min.js?v=' + result.version],
                            'signature-pad': ['./assets/js/signature-pad/signature-pad.min.js?v=' + result.version],
                            tv4: ['//cdnjs.cloudflare.com/ajax/libs/tv4/1.3.0/tv4.min.js', './assets/js/tv4/tv4-1.3.0.min.js?v=' + result.version],
                            xml2json: ['//cdn.jsdelivr.net/gh/abdmob/x2js@master/xml2json.min.js', './assets/js/xml2json/xml2json.min.js?v=' + result.version],
                            JSZip: ['//cdnjs.cloudflare.com/ajax/libs/jszip/2.6.1/jszip.min.js', './assets/js/jszip/jszip.min.js?v=' + result.version],
                            Flickity: ['//cdnjs.cloudflare.com/ajax/libs/flickity/2.2.2/flickity.pkgd.min.js', './assets/js/flickity/flickity.pkgd-2.2.2.min.js?v=' + result.version],
                            Packery: ['./assets/js/packery/packery.pkgd-2.1.2.min.js?v=' + result.version],
                            'ui.router.state.events': ['//cdnjs.cloudflare.com/ajax/libs/angular-ui-router/1.0.29/stateEvents.js', './assets/js/angular-ui-router-stateevents/angular-ui-router-stateevents-1.0.29.min.js?v=' + result.version],
                            'ui.select': ['./assets/js/ui-select/ui-select.js?v=' + result.version], // BJS 20220730 - Do not use cdn for this, source has been modified
                            ngAnimate: ['//cdnjs.cloudflare.com/ajax/libs/angular-animate/1.8.3/angular-animate.min.js', './assets/js/angular-animate/angular-animate-1.8.3.min.js?v=' + result.version],
                            ngSanitize: ['//cdnjs.cloudflare.com/ajax/libs/angular-sanitize/1.8.3/angular-sanitize.min.js', './assets/js/angular-sanitize/angular-sanitize-1.8.3.min.js?v=' + result.version],
                            ngTouch: ['//cdnjs.cloudflare.com/ajax/libs/angular-touch/1.8.3/angular-touch.min.js', './assets/js/angular-touch/angular-touch-1.6.3.min.js?v=' + result.version],
                            ngFileSaver: ['./assets/js/angular-file-saver/angular-file-saver-bundle.min.js?v=' + result.version],
                            'monospaced.elastic': ['./assets/js/angular-elastic/angular-elastic-2.5.1.min.js?v=' + result.version], // BJS 20220428 - Do not use cdn for this, source has been modified
                            signature: './assets/js/signature/signature.min.js?v=' + result.version,
                            'ui.bootstrap': ['//cdnjs.cloudflare.com/ajax/libs/angular-ui-bootstrap/2.5.0/ui-bootstrap-tpls.min.js', './assets/js/ui-bootstrap/ui-bootstrap-tpls-2.5.0.min.js?v=' + result.version],
                            'ui.sortable': ['//cdnjs.cloudflare.com/ajax/libs/angular-ui-sortable/0.19.0/sortable.min.js', './assets/js/angular-ui-sortable/ui-sortable-0.19.0.min.js?v=' + result.version],
                            noUiSlider: ['//cdnjs.cloudflare.com/ajax/libs/noUiSlider/14.5.0/nouislider.min.js', './assets/js/nouislider/nouislider-14.5.0.min.js?v=' + result.version],
                            'ya.nouislider': './assets/js/ya-nouislider/ya.nouislider-2.8.0.min.js?v=' + result.version,
                            ngStorage: ['//cdnjs.cloudflare.com/ajax/libs/ngStorage/0.3.10/ngStorage.min.js', './assets/js/ng-storage/ngStorage-0.3.10.min.js?v=' + result.version],
                            ngFileUpload: ['//cdnjs.cloudflare.com/ajax/libs/danialfarid-angular-file-upload/12.2.13/ng-file-upload-all.min.js', './assets/js/ng-file-upload/ng-file-upload-all-12.2.13.min.js?v=' + result.version],
                            'angular-md5': ['./assets/js/angular-md5/angular-md5-0.1.7.min.js', '//cdnjs.cloudflare.com/ajax/libs/angular-md5/0.1.7/angular-md5.min.js?v=' + result.version],
                            'ab-base64': ['//cdnjs.cloudflare.com/ajax/libs/angular-utf8-base64/0.0.5/angular-utf8-base64.min.js', './assets/js/angular-utf8-base64/angular-uft8-base64.min.js?v=' + result.version],
                            schemaForm: './assets/js/angular-schema-form/angular-schema-form-1.0.0-alpha.5.min.js?v=' + result.version,
                            schemaFormBootstrap: './assets/js/angular-schema-form-bootstrap/angular-schema-form-bootstrap-1.0.0-alpha.5.min.js?v=' + result.version,
                            'kendo.directives': ['//kendo.cdn.telerik.com/2019.1.115/js/kendo.all.min.js', './assets/js/kendo-all/kendo.all-2019.1.115.min.js?v=' + result.version],
                            appConfig: './assets/js/app-config/app-config.min.js?v=' + result.version,
                            'ie.http': './assets/js/ie-http/ie.http.min.js?v=' + result.version,
                            'ie.screenblock': './assets/js/ie-screenblock/ie.screenblock.min.js?v=' + result.version,
                            'ie.modal': './assets/js/ie-modal/ie.modal.min.js?v=' + result.version,
                            'ie.fileserver': './assets/js/ie-fileserver/ie.fileserver.js?v=' + result.version,
                            'ie.printserver': './assets/js/ie-printserver/ie.printserver.js?v=' + result.version,
                            'ie.officeonline': './assets/js/ie-officeonline/ie.officeonline.js?v=' + result.version,
                            'tt.interceptor': './assets/js/tt-interceptor/tt.interceptor.js?v=' + result.version,
                        },
                        {
                            shim: {
                                'ya.nouislider': ['noUiSlider'],
                                'ui.select': ['ngSanitize', 'ui.bootstrap'],
                                'tt.interceptor': ['ui.bootstrap', 'ab-base64'],
                                schemaForm: ['tv4'],
                                schemaFormBootstrap: ['schemaForm'],
                                'ie.officeonline': ['ie.modal'],
                                'kendo.cultures.no': ['kendo.directives'],
                            },
                            callback: function () {
                                self.appSettings.setIsReady();

                                resolve(self.appSettings.settings);
                            },
                        }
                    );
                },
                error: (e) => console.error(e),
            });
        });
    }

    private setClientSessionIdIfNotExists(): string {
        let clientSessionId = this.getClientSessionId();

        if (!clientSessionId || clientSessionId === null) {
            clientSessionId = this.generateUniqueId();

            this.setClientSessionId(clientSessionId);
        }

        this._clientSessionId = clientSessionId;

        return clientSessionId;
    }

    private setClientSessionId(clientSessionId: string) {
        sessionStorage.setItem(this.CLIENT_SESSION_ID_KEY, clientSessionId);
    }

    private getClientSessionId(): string | null {
        return sessionStorage.getItem(this.CLIENT_SESSION_ID_KEY);
    }

    private generateUniqueId(): string {
        const array = new Uint8Array(32); // 32 bytes = 256 bits

        window.crypto.getRandomValues(array);

        return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
    }
}

imApp.factory('appConfigService', downgradeInjectable(AppConfigService));
imApp.factory('translateService', downgradeInjectable(TranslateService));
imApp.factory('uService', downgradeInjectable(UtilityService));
imApp.factory('dtService', downgradeInjectable(DataTaskService));
imApp.factory('bislabService', downgradeInjectable(BislabService));
imApp.factory('themeService', downgradeInjectable(ThemeService));
imApp.factory('idportenService', downgradeInjectable(IdportenService));
imApp.factory('pdfService', downgradeInjectable(PdfService));
imApp.factory('popupService', downgradeInjectable(PopupService));
imApp.factory('sirvService', downgradeInjectable(SirvService));
imApp.directive('ttPasswordInput', downgradeComponent({ component: PasswordInputComponent }));
imApp.directive('ttMultiSelect', downgradeComponent({ component: MultiSelectComponent }));
imApp.directive('ttFieldGroup', downgradeComponent({ component: FieldGroupComponent }));
imApp.directive('ttPageHeading', downgradeComponent({ component: PageHeadingComponent }));
imApp.directive('ttFileUploader', downgradeComponent({ component: FileUploaderComponent }));
imApp.directive('ttButtonMultiTs', downgradeComponent({ component: ButtonMultiComponent }));
imApp.directive('ttGridTs', downgradeComponent({ component: GridComponent }));
imApp.directive('ttHtmlProcEditor', downgradeComponent({ component: HtmlProcEditorComponent }));
