<tt-form-field-base
    [ttFieldId]="id.progress"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="progress"
>
    <div role="progressbar" class="tt-progress-bar">
        <span
            class="tt-progress-bar__status fas"
            [ngClass]="{ 'fa-exclamation-circle tt-progress-bar__status--invalid': ttStatus === 'invalid', 'fa-check-circle tt-progress-bar__status--finish': ttStatus === 'finish' }"
        ></span>
        <div
            class="tt-progress-bar__bar"
            [ngClass]="{
                'tt-progress-bar__bar--indeterminate': ttIndeterminate && ttStatus === 'active',
                'tt-progress-bar__bar--finish': ttStatus === 'finish',
                'tt-progress-bar__bar--invalid': ttStatus === 'invalid'
            }"
            [ngStyle]="style['bar']"
        ></div>
    </div>
</tt-form-field-base>
