(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2Notifications', {
        templateUrl: 'views/components/views/p2Notifications/p2Notifications.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'p2NotificationsService', 'rememberService', function (stateService, utilityService, p2NotificationsService, rememberService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            const vm = this;

            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                bgNotificationSelection: {
                    selected: '0',
                    buttons: [
                        { id: '0', label: 'notifications_new', color: 'primary', onClick: () => vm.model.bgNotificationSelection.selected = '0' },
                        { id: '1', label: 'notifications_read', color: 'primary', onClick: () => vm.model.bgNotificationSelection.selected = '1' },
                        { id: '2', label: 'notifications_mark', color: 'primary', onClick: () => vm.model.bgNotificationSelection.selected = '2' }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                setting: {},
                p2NotificationsListUnread: {},
                p2NotificationsListRead: {},
                locked: false,
                //testlist: {
                //    records: [
                //        { item_id: '1', item_name: 'notifications_read1', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '2', item_name: 'notifications_read2', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '3', item_name: 'notifications_read3', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '4', item_name: 'notifications_read4', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '5', item_name: 'notifications_read5', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '6', item_name: 'notifications_read6', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '7', item_name: 'notifications_read7', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' },
                //        { item_id: '8', item_name: 'notifications_read8', item_glyphicon: 'glyphicon-none', item_glyphicon_2: 'glyphicon-save', item_glyphicon_color: 'green', item_is_selected: '0' }
                //    ]
                //}
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                p2NotificationsService.getSettings().then(function (response) {
                    angular.copy(response, vm.model.setting);
                });
            };

            let loadUnread = function () {
                p2NotificationsService.loadUnreadNotifications().then(function (data) {
                    angular.copy(data, vm.model.p2NotificationsListUnread);
                });
            };

            let loadRead = function () {
                p2NotificationsService.loadReadNotifications().then(function (data) {
                    angular.copy(data, vm.model.p2NotificationsListRead);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #################################
            // DIRECTION AND READ CONFIRM BUTTON
            // #################################
    
            vm.readP2Notification = function (item) {
                p2NotificationsService.setAsRead(item).then(function () {
                    stateService.go(item.item_state, {
                        custact_no: item.item_parms
                    });
                });
            };
    
            vm.markNotification = function (item) {
                console.dir(item);
                if (angular.isUndefined(item)) {
                    angular.forEach(vm.model.p2NotificationsListUnread.records, function (data) {
                        if (angular.isDefined(data)) {
                            data.item_is_selected = '1';
                        }
                    });
                }
            };
    
            vm.confirmMarkNotification = function () {
                vm.model.locked = true;

                angular.forEach(vm.model.p2NotificationsListUnread.records, function (data) {
                    if (angular.isDefined(data) && angular.isDefined(data.item_is_selected) && data.item_is_selected == '1') {
                        p2NotificationsService.setAsRead(data);
                    }
                });

                p2NotificationsService.loadUnreadNotifications().then(function (data) {
                    angular.copy(data, vm.model.p2NotificationsListUnread);
                });

                p2NotificationsService.loadReadNotifications().then(function (data) {
                    angular.copy(data, vm.model.p2NotificationsListRead);
                    vm.model.locked = false;
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgNotificationSelection = function (value) {
                vm.model.bgNotificationSelection.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadUnread();
                loadRead();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
