(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("pohTopService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadPohTopGet: function (poh_keyno_top) {
				return p2DataTaskService.call(1350, {
					poh_keyno_top: poh_keyno_top
				});
			},
			loadPostStatusSelectList: function (add_all) {
				return p2DataTaskService.call(1348, {
					add_all: add_all
				});
			},
			loadSubPosList: function (poh_keyno_top) {
				return p2DataTaskService.call(1351, {
					poh_keyno_top: poh_keyno_top
				});
			}
		};

		return service;
	}]);
})();