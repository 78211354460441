(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttDtGrid', {
        templateUrl: 'views/components/directives/ttDtGrid/ttDtGrid.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttOptions: '<'
        },
        controller: ['$q', '$timeout', 'ttDtGridService', 'ttDtGridSchemaService', 'rememberService', 'translateService', 'layoutService', 'userService', 'eventService', 'modalService', function ($q, $timeout, ttDtGridService, ttDtGridSchemaService, rememberService, translateService, layoutService, userService, eventService, modalService) {
            var vm = this;

            vm.id = uuid();

            // vm.options
            //{
            //    dataTaskKeyno: 123, { load: 123, save: 456 }            ** Can be a number or an object. Use an object if the grid is editable and
            //                                                                 you need to save the changes.
            //    onSetup: function(schema, fields, columns, translations)  ** Optional callback function for customizing columns. Parameters are references
            //                                                                 so any changes will be reflected in the grid except if you replace the object.
            //                                                                 e.g. if you in the callback do columns = "new array" it will not work 
            //                                                                 but angular.copy("new array", columns) will work.
            //    gridControlCallback: function(gridControls) {}            ** Optional callback function to get control methods for the grid ("refresh").
            //    onDataBound: function(e) {}                               ** Callback method after data is bound, corresponds to databound event on kendo grid.
            //    rememberId: "abcd1234"                                    ** Unique id used for storing settings in the database.
            //    translations: []                                          ** Array containing wordId's for to ble translated. An object with translations is passed into the onSetup function.
            //}

            var settings = {
                page: 1,
                pageSize: 25,
                columns: {},
                sort: {
                    field: '',
                    dir: ''
                }
            }

            var changes = {};

            var saveSettings = function () {
                if (canRemember() !== true)
                    return;

                rememberService.remember(vm.ttOptions.rememberId, angular.toJson(settings))
            };

            vm.rebind = false;

            vm.fontSize = null;

            var getSortSettings = function () {
                if (angular.isUndefined(settings.sort))
                    return undefined;
                if (angular.isObject(settings.sort) !== true)
                    return undefined;
                if (angular.isUndefined(settings.sort.field))
                    return undefined;
                if (angular.isString(settings.sort.field) !== true)
                    return undefined;
                if (settings.sort.field.trim().length < 1)
                    return undefined;

                if (angular.isUndefined(settings.sort.dir) || angular.isString(settings.sort.dir) !== true || (settings.sort.dir !== 'asc' && settings.sort.dir !== 'desc')) {
                    settings.sort.dir = 'asc';
                }

                return settings.sort;
            };

            var updateSortSettings = function (newSort) {
                if (angular.isUndefined(newSort))
                    return;
                if (angular.isArray(newSort) !== true)
                    return;
                if (newSort.length < 1)
                    return;

                if (angular.isUndefined(settings.sort)) {
                    settings.sort = {
                        field: '',
                        dir: ''
                    }

                    saveSettings();
                } else {
                    if (angular.isUndefined(settings.sort.field)) {
                        settings.sort.field = '';
                    }

                    if (angular.isUndefined(settings.sort.dir)) {
                        settings.sort.dir = '';
                    }
                }

                if (newSort[0].field === settings.sort.field && newSort[0].dir === settings.sort.dir)
                    return;

                settings.sort.field = newSort[0].field;
                settings.sort.dir = newSort[0].dir;

                saveSettings();
            };

            var getLoadKeyno = function () {
                return angular.isObject(vm.ttOptions.dataTaskKeyno)
                    ? vm.ttOptions.dataTaskKeyno.load
                    : vm.ttOptions.dataTaskKeyno;
            };

            var buildOptions = function (schema, translations) {
                var fields = {};
                var columns = [];

                var userTranslations = splitTranslations(translations);

                angular.forEach(schema, function (colSchema) {
                    fields[colSchema.colname] = { type: ttDtGridSchemaService.getType(colSchema.coltype) }

                    var col = {
                        field: colSchema.colname,
                        title: colSchema.title,
                        attributes: {
                            style: 'text-align: ' + colSchema.align + ';'
                        },
                        headerAttributes: {
                            style: 'text-align: ' + colSchema.align + ';'
                        }
                    }

                    if (vm.fontSize !== null) {
                        col.attributes.style += 'font-size: ' + vm.fontSize + ';'
                        col.headerAttributes.style += 'font-size: ' + vm.fontSize + ';'
                    }

                    if (angular.isDefined(settings.columns[colSchema.colname]) && angular.isDefined(settings.columns[colSchema.colname].width)) {
                        col.width = settings.columns[colSchema.colname].width + 'px';
                    }

                    var format = ttDtGridSchemaService.getFormat(colSchema);

                    if (format !== null) {
                        col.format = format;
                    }

                    columns.push(col);
                });

                if (angular.isFunction(vm.ttOptions.onSetup)) {
                    vm.ttOptions.onSetup(schema, fields, columns, userTranslations);
                }

                return {
                    dataSource: new kendo.data.DataSource({
                        transport: {
                            read: function (opt) {
                                ttDtGridService.loadData(getLoadKeyno(), vm.ttOptions.parameters).then(function (data) {
                                    opt.success(data);
                                })
                            }
                        },
                        change: function (e) {
                            updateSortSettings(e.sender._sort);
                        },
                        schema: {
                            model: {
                                fields: fields
                            }
                        },
                        pageSize: settings.pageSize,
                        page: settings.page,
                        sort: getSortSettings()
                    }),
                    columns: columns,
                    dataBound: onDataBound,
                    pageable: {
                        pageSizes: [5, 10, 15, 20, 25, 50, 100, 250, 500],
                        messages: {
                            display: translations.grid_pg_display,              // default: "{0} - {1} of {2} items"
                            empty: translations.grid_pg_empty,                  // default: "No items to display",
                            page: translations.grid_pg_page,                    // default: "Page"
                            of: translations.grid_pg_of,                        // default: "of {0}")
                            itemsPerPage: translations.grid_pg_itemsperpage,    // default: "items per page"
                            first: translations.grid_pg_first,                  // default: "Go to the first page"
                            last: translations.grid_pg_last,                    // default: "Go to the last page"
                            next: translations.grid_pg_next,                    // default: "Go to the next page"
                            previous: translations.grid_pg_previous,            // default: "Go to the previous page"
                            refresh: translations.grid_pg_refresh,              // default: "Refresh"
                            morePages: translations.grid_pg_morepages           // default: "More pages"
                        }
                    },
                    page: function (e) {
                        settings.page = e.page;

                        saveSettings();
                    },
                    sortable: true,
                    resizable: true,
                    columnResize: function (e) {
                        if (angular.isDefined(settings.columns[e.column.field])) {
                            settings.columns[e.column.field].width = e.newWidth;
                        } else {
                            settings.columns[e.column.field] = {
                                width: e.newWidth
                            };
                        }

                        saveSettings();
                    },
                    editable: angular.isDefined(vm.ttOptions.editable) ? vm.ttOptions.editable : false,
                    save: onSave
                };
            };

            var onSave = function (e) {
                var changed = angular.copy(e.model);

                var del = [];

                for (var key in changed) {
                    if (key === 'dirtyFields')
                        continue;

                    if (key === 'uid' || key === 'dirty')
                        del.push(key);

                    if (angular.isObject(changed[key]) || angular.isArray(changed[key]) || angular.isFunction(changed[key])) {
                        del.push(key);
                    }
                }

                angular.forEach(changed, function (value, key) {
                    if (angular.isObject(value) || angular.isArray(value) || angular.isFunction(value)) {
                        del.push(key);
                    }
                });

                angular.forEach(del, function (value) {
                    delete changed[value];
                });

                angular.forEach(e.values, function (value, key) {
                    changed[key] = value;
                });

                changes[e.model.uid] = changed;
            };

            var saveChanges = function () {
                if (angular.isObject(vm.ttOptions.dataTaskKeyno) !== true)
                    return;
                if (angular.isUndefined(vm.ttOptions.dataTaskKeyno.save))
                    return;

                kendo.ui.progress($('#' + vm.id), true);

                var promises = [];

                angular.forEach(changes, function (value, key) {
                    promises.push(ttDtGridService.saveData(vm.ttOptions.dataTaskKeyno.save, value));

                    delete changes[key];
                });

                $q.all(promises).then(function (response) {
                    angular.forEach(response, function (res) {
                        if (angular.isDefined(res[0].errorcode) && res[0].errorcode !== 0 && res[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: 'Error: ' + res[0].errorcode,
                                message: res[0].errormessage
                            });
                        }
                    });
                    
                    refreshData();

                    kendo.ui.progress($('#' + vm.id), false);
                });
            };

            var canRemember = function () {
                if (angular.isUndefined(vm.ttOptions.rememberId))
                    return false;
                if (angular.isString(vm.ttOptions.rememberId) !== true)
                    return false;

                return vm.ttOptions.rememberId.length > 0;
            };

            var getRemember = function (remembering, response) {
                if (remembering !== true)
                    return undefined;
                if (angular.isArray(response) !== true)
                    return undefined;
                if (response.length < 3)
                    return undefined;
                if (angular.isArray(response[2]) !== true)
                    return undefined;
                if (response[2].length < 1)
                    return undefined;
                if (angular.isUndefined(response[2][0].variablevalue))
                    return undefined;
                if (angular.isString(response[2][0].variablevalue) !== true)
                    return undefined;
                if (response[2][0].variablevalue.trim().length < 1)
                    return undefined;

                return angular.fromJson(response[2][0].variablevalue);
            };

            var words = ['grid_pg_display', 'grid_pg_empty', 'grid_pg_page', 'grid_pg_of', 'grid_pg_itemsperpage', 'grid_pg_first', 'grid_pg_last', 'grid_pg_next', 'grid_pg_previous', 'grid_pg_refresh', 'grid_pg_morepages'];

            var addTranslations = function (translations) {
                angular.forEach(words, function (word) {
                    translations.push(word);
                });
            };

            var splitTranslations = function (translations) {
                var userTranslations = angular.copy(translations);

                angular.forEach(words, function (word) {
                    delete userTranslations[word];
                });

                angular.forEach(userTranslations, function (_, key) {
                    delete translations[key];
                });

                return userTranslations;
            };

            var updateSettings = function (remembering, response) {
                var remember = getRemember(remembering, response);

                if (angular.isUndefined(remember))
                    return;

                if (angular.isDefined(remember.pageSize)) {
                    settings.pageSize = remember.pageSize;
                }

                if (angular.isDefined(remember.page)) {
                    settings.page = parseInt(remember.page);
                }

                if (angular.isDefined(remember.columns) && angular.isObject(remember.columns)) {
                    settings.columns = remember.columns;
                }

                if (angular.isDefined(remember.sort) && angular.isObject(remember.sort)) {
                    settings.sort = remember.sort;
                }
            };

            var loadGrid = function () {
                var deferred = $q.defer();

                // ensure user settings are loaded before building grid
                userService.ensureIsReady().then(function () {
                    var promises = [];

                    promises.push(ttDtGridService.loadSchema(getLoadKeyno()));

                    var translations = angular.isArray(vm.ttOptions.translations) ? vm.ttOptions.translations : [];

                    addTranslations(translations);

                    promises.push(translateService.translateBatch(translations));

                    var remembering = canRemember();

                    if (remembering === true) {
                        promises.push(rememberService.getLastStatus(vm.ttOptions.rememberId));
                    }

                    $q.all(promises).then(function (response) {
                        updateSettings(remembering, response);

                        vm.options = buildOptions(response[0], response[1]);

                        // run on next digest cycle
                        $timeout(function () {
                            // stopping focuslock when clicking to select number of items per page.
                            // restarts focuslock after. These events are handled in ttInput component.
                            var element = $('#' + vm.id + ' .k-grid-pager .k-pager-sizes .k-dropdown')

                            element.click(function () {
                                eventService.trigger('event:focuslock:stop');
                            });

                            element.blur(function () {
                                eventService.trigger('event:focuslock:start');
                            });

                            $('#' + vm.id + ' .k-grid-pager .k-pager-sizes .k-dropdown select').change(function (e) {
                                eventService.trigger('event:focuslock:start');
                            });

                            deferred.resolve();
                        });
                    });
                });

                return deferred.promise;
            };

            var rebindGrid = function () {
                var deferred = $q.defer();

                loadGrid().then(function () {
                    // Any change of vm.rebind triggers rebind. Toggles between true and false to start rebind.
                    vm.rebind = !vm.rebind;

                    deferred.resolve();
                });

                return deferred.promise;
            };

            var refreshData = function () {
                // kendo-grid="id" or k-scope-name="id" does not seem to work with a component without $scope.
                // Using jquery to get dataSource instead.
                $('#' + vm.id).data("kendoGrid").dataSource.read();
            };

            var onDataBound = function (e) {
                var r = document.querySelector(':root');

                r.style.setProperty('--im-k-alt-bg-color', '#E0E0E0');

                $(".k-grid-content").css("min-height", "200px");

                if (angular.isFunction(vm.ttOptions.onDataBound)) {
                    vm.ttOptions.onDataBound(e);
                }

                var pageSize = e.sender.dataSource.pageSize();

                if (settings.pageSize !== pageSize) {
                    settings.pageSize = pageSize;

                    saveSettings();
                }
            };

            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSizeS;
            });

            vm.$onChanges = function (change) {
                if (angular.isDefined(change.ttOptions) && angular.isDefined(change.ttOptions.currentValue)) {
                    if (angular.isDefined(change.ttOptions.currentValue.gridControlCallback) && angular.isFunction(change.ttOptions.currentValue.gridControlCallback)) {
                        change.ttOptions.currentValue.gridControlCallback({
                            rebind: rebindGrid,
                            refresh: refreshData,
                            save: saveChanges
                        });
                    }

                    loadGrid();
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
