(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("productUnitService", ['$ihttp', function ($ihttp) {
        let service = {
            getProductUnit: function (parms) {
                return $ihttp.post({
                    method: 581,
                    parameters: parms || {}
                });
            },
            listNewProductUnits: function (parms) {
                return $ihttp.post({
                    method: 582,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 583,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 590,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
