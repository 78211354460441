(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('furnitureFabricsS', {
        templateUrl: 'views/components/views/furnitureFabricsS/furnitureFabricsS.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'furnitureFabricsSService', 'rememberService', function (stateService, utilityService, furnitureFabricsSService, rememberService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            let variableNames = {
                gb_furniturefabricss_status: ''
            };

            vm.model = {
                bgFurnitureFabricssStatus: {
                    selected: 'ACTIVE',
                    buttons: [
                        { id: 'ACTIVE', label: 'gb_furniturefabricss_status_active', color: 'primary', onClick: () => setFurnitureFabricssStatusView('ACTIVE') },
                        { id: 'NOTACTIVE', label: 'gb_furniturefabricss_status_notactive', color: 'primary', onClick: () => setFurnitureFabricssStatusView('NOTACTIVE') },
                        { id: 'ALL', label: 'gb_furniturefabricss_status_all', color: 'primary', onClick: () => setFurnitureFabricssStatusView('ALL') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListFurnitureFabrics: []
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setFurnitureFabricssStatusView = function (id) {
                vm.model.bgFurnitureFabricssStatus.selected = vm.model.setting.gb_furniturefabricss_status = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## LOAD PROCEDURE CALLS ##
            
            furnitureFabricsSService.loadFurnitureFabricsGet('1', 'ALL').then(function (data) {
                vm.model.setting = angular.copy(data[0]);
                setFurnitureFabricssStatusView(vm.model.setting.gb_furniturefabricss_status);
                
                load();
            });
            
            var load = function () {
                if (angular.isUndefined(vm.model.setting.gb_furniturefabricss_status) === true) return;
                if (vm.model.setting.gb_furniturefabricss_status === null) return;
                if (vm.model.setting.gb_furniturefabricss_status.trim().length < 1) return;
                
                vm.model.itemsListFurnitureFabrics = [];
                
                if (vm.model.setting.gb_furniturefabricss_status === 'ACTIVE') {
                    furnitureFabricsSService.loadFurnitureFabricsList('1', '-').then(function (data) {
                        angular.copy(data, vm.model.itemsListFurnitureFabrics);
                    });
                } else if (vm.model.setting.gb_furniturefabricss_status === 'NOTACTIVE') {
                    furnitureFabricsSService.loadFurnitureFabricsList('0', '-').then(function (data) {
                        angular.copy(data, vm.model.itemsListFurnitureFabrics);
                    });
                } else if (vm.model.setting.gb_furniturefabricss_status === 'ALL') {
                    furnitureFabricsSService.loadFurnitureFabricsList('1', 'ALL').then(function (data) {
                        angular.copy(data, vm.model.itemsListFurnitureFabrics);
                    });
                }
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'gb_furniturefabricss_status':
                    		variableNames[key] = key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_furniturefabricss_status':
                        if (utilityService.validateParmsValue(vm.model.setting.gb_furniturefabricss_status) !== true) return;

                        variableValue = vm.model.setting.gb_furniturefabricss_status;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgFurnitureFabricssStatus = function (value) {
                vm.model.bgFurnitureFabricssStatus.selected = value;
                vm.model.setting.gb_furniturefabricss_status = vm.model.bgFurnitureFabricssStatus.selected;

                rememberFunc('gb_furniturefabricss_status');
                load();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'furniturefabrics':
                        go({ furniturefabrics_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
        }]
    });
})();
