(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectTypes', {
        templateUrl: 'views/components/views/serviceObjectTypes/serviceObjectTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'serviceObjectTypesService', function (stateService, serviceObjectTypesService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
            vm.model = {
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                settingTypes: {},
                itemsListTypes: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            serviceObjectTypesService.loadServiceObjectTypesGet().then(function (data) {
                angular.copy(data[0], vm.model.settingTypes);
            });
    
            serviceObjectTypesService.loadServiceObjectTypesList().then(function (data) {
                angular.copy(data, vm.model.itemsListTypes);
            });
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.settingTypes.searchtext = '';
                        break;
                    default:
                        break;
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'serviceobjecttype':
                        go({
                            serviceobjecttype_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();