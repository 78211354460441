(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('pohTop', {
        templateUrl: 'views/components/views/pohTop/pohTop.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'pohTopService', function($stateParams, stateService, pohTopService) {
    
            var vm = this;
    
    
    
    		// ## VARIABLES & DEFINITIONS ##
    
    		vm.model = {
    			pohKeynoTop: $stateParams.poh_keyno_top,
    			productionOrder: {},
    			selectListPoStatus: [],
    			itemsListSubPos: []
    		};
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		pohTopService.loadPohTopGet(vm.model.pohKeynoTop).then(function (data) {
    			vm.model.productionOrder = angular.copy(data[0]);
    
    			loadSubPos();
    		});
    
    		pohTopService.loadPostStatusSelectList('0').then(function (data) {
    			angular.copy(data, vm.model.selectListPoStatus);
    		});
    
    		var loadSubPos = function () {
    			if (angular.isUndefined(vm.model.productionOrder.poh_keyno_top) === true) return;
    			if (vm.model.productionOrder.poh_keyno_top === null) return;
    			if (vm.model.productionOrder.poh_keyno_top.trim().length < 1) return;
    
    			vm.model.itemsListSubPos = [];
    
    			pohTopService.loadSubPosList(vm.model.productionOrder.poh_keyno_top).then(function (data) {
    				angular.copy(data, vm.model.itemsListSubPos);
    			});
    		};
    
    		// ## DIRECTION BUTTON FUNCTIONS ##
    
    		vm.goTo = function (state) {
    			var go = function (parms) {
    				stateService.go(state, parms);
    			};
    
    			switch (state) {
    				case 'selectreport':
    					go({
    						webpage_name: stateService.getCurrentName(),
    						argtype: 'poh_keyno_top',
    						argvalue: vm.model.productionOrder.poh_keyno_top
    					});
    					break;
    			}
    		};
    	}]
    });
})();