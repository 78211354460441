import { ColDef, ColumnState, FirstDataRenderedEvent, GridApi, ICellEditorParams, IRowNode, ModelUpdatedEvent, RowSelectedEvent, ToolPanelDef } from '@ag-grid-community/core';
import { Observable } from 'rxjs';

export type ChangeState = 'add' | 'all' | 'update' | 'remove';

/**
 * Represents the changes of a grid row.
 */
export interface GridRowChanges {
    /**
     * The state of the change.
     */
    state: ChangeState;

    /**
     * The row to change with updated values if any.
     */
    data: GridRow;
}

/**
 * Represents general info of a column.
 */
export interface GridColumnInfo {
    /**
     * The id of the column.
     */
    colname: string;

    /**
     * The name of the column the info is based on.
     *
     * @deprecated use `colname` instead.
     */
    from?: string;

    /**
     * The data type displayed in the cells of the column.
     */
    type: CellDataTypes | null;

    /**
     * The edit type used for specialfunc edit.
     */
    editType?: string | null;

    /**
     * Editable configuration for the column.
     */
    editable?: boolean | null | LookupConfig | string;

    /**
     * Whether the grid should skip this column when navigating in editmode.
     */
    clickonly?: null | boolean;

    /**
     * The set of options used for the select edittype.
     */
    dd_data?: { [key: string]: string }[] | null;

    /**
     * The key of the id property for the set of options used for the select edittype.
     */
    dd_data_id?: string | null;

    /**
     * The key of the name property for the set of options used for the select edittype.
     */
    dd_data_name?: string | null;

    /**
     * The translated title of the column.
     */
    title: string | null;

    /**
     * In a db decimal type defined by (x, y), x is this width.
     */
    decimalWidth: number | null;

    /**
     * Whether the column should be saved in usersettings.
     */
    remember: boolean;
}

/**
 * Represents data used in the grid component.
 */
export interface GridOptionsData {
    /**
     * The column definitions of the grid.
     */
    columnDefinitions: ColDef[];

    /**
     * Extra column information for the columns.
     */
    columnInfo?: { [key: string]: GridColumnInfo };

    /**
     * The row data in the grid.
     */
    rowData: GridRow[];

    /**
     * The active layout keyno chosen during this session.
     */
    layoutKeyno?: string | null;

    /**
     * The unsaved changes of data in the grid rows.
     */
    changes?: { [key: string]: GridRowChanges };

    /**
     * Whether the grid data has any grid glyphs.
     */
    hasGridGlyphs?: boolean;

    /**
     * Whether the grid has any special function buttons.
     */
    hasSpecialFunc?: boolean;

    /**
     * Whether the grid uses special func edit (edit modal generated based on colschema).
     */
    hasSpecialFuncEdit?: boolean;

    /**
     * Whether the grid has an `is_selected` column.
     */
    hasSelection?: boolean;

    /**
     * Whether the grid data has any goto parameters.
     */
    hasGotoParms?: boolean;

    /**
     * Whether the grid data has any thumbnails.
     */
    hasThumbnails?: boolean;
}

/**
 * Represents an options configuration object to initialize a grid with.
 */
export interface GridOptions {
    /**
     * The data-task configuration for a grid.
     */
    dataTask?: GridDataTask;

    /**
     * Represents data displayed in the grid.
     * *For internal use in the component, or special cases*.
     */
    data?: GridOptionsData;

    /**
     * Comma seperated list of words to translate.
     */
    translations?: string[];

    /**
     * Configuration for the grid.
     */
    config?: GridConfig;

    /**
     * @deprecated use `config` instead.
     */
    kendo?: KendoConfig;

    events?: GridEvents;
    reports?: any[];
    optionfunc?: any;

    /**
     * Callback to call when setup of initial grid data has completed.
     *
     * @param schema the columns of the grid.
     * @param fields the additional data about the columns.
     * @param columns the column definitions used in the grid.
     * @param userTranslations translations from the grid.
     * @returns either nothing or an empty promise.
     */
    onSetup?: (schema: GridColumn[], fields: { [key: string]: GridColumnInfo }, columns: ColDef[], userTranslations: { [key: string]: string }) => Promise<void> | void;

    onSelect?: (event: { $event: RowSelectedEvent; $item?: GridRow }) => unknown;

    /**
     * Function to call when data is loaded to the grid.
     * **IMPORTANT** with ag-grid the event is only called when the data is loaded to the grid for the first time, no sequental times.
     */
    onDataBound?: null | ((event?: FirstDataRenderedEvent) => void);

    /**
     * Function to call when data model of the grid is updated.
     *
     * @deprecated use `ttModelUpdated` event of grid component instead. Like `<tt-grid ... (ttModelUpdated)="update()"></tt-grid>`
     */
    onDataBinding?: null | ((event?: ModelUpdatedEvent) => void);

    /**
     * Object containing additional functions for interacting with the grid.
     */
    gridfunc?: null | GridFunctions;
}

export interface KendoConfig {
    /**
     * The fixed height of the grid, if null or undefined, the grid will autosize to fit the all the rows in the pagesize of the pagination.
     */
    height?: null | number | string;
    sortable?: boolean;

    /**
     * Whether to aggregate or not. If `true` it will calculate the sum of all decimals with length of 17.
     */
    aggregate?: boolean | null | TTGridAggregate[];
    pager?: false;
    pageable?: boolean;
    selectable?: boolean | 'multiple' | 'single';
    filterable?: GridFilterable;
}

export interface GridEvents {
    /**
     * Callback to call when setup of initial grid data has completed.
     *
     * @param schema the columns of the grid.
     * @param fields the additional data about the columns.
     * @param columns the column definitions used in the grid.
     * @param userTranslations translations from the grid.
     * @returns either nothing or an empty promise.
     */
    onSetup?: (schema: GridColumn[], fields: { [key: string]: GridColumnInfo }, columns: ColDef[], userTranslations: { [key: string]: string }) => Promise<void> | void;
}

/**
 * Represents filerable settings of the grid.
 */
export type GridFilterable = boolean | { mode: 'row' };

/**
 * Represents an aggregrate function.
 */
export type TTAggregateFunction = 'count' | 'sum' | 'average' | 'min' | 'max' | 'custom';

/**
 * Represents a aggregate configuration.
 */
export interface TTGridAggregate {
    /**
     * The column field to make an aggregate for.
     */
    field: string;

    /**
     * The aggregate function to calculate for the field.
     */
    aggregate: TTAggregateFunction;

    /**
     * A string which holds formula to calculate aggregate function based on value of other aggregates. Values of other aggregates can be retrieved by using their colname.
     */
    aggregate_function?: string;
}

/**
 * Represents data task configuration for a grid.
 */
export interface GridDataTask {
    /**
     * **Recommended**, human-readible unqiue id used for remembering layout settings for this grid per user.
     */
    rememberId?: string | null;

    /**
     * Method to use instead of the default `1999` grid columns method, for setting up the colschema.
     */
    loadSetupId?: string | null;

    /**
     * The data-task for loading data into the grid.
     */
    loadData?: LoadDataTaskRequest | null;

    /**
     * The data-task for adding a new row into the grid.
     */
    addRow?: AddRowDataTaskRequest | null;

    /**
     * The data-task for deleting a row from the grid.
     */
    removeRow?: RemoveRowDataTaskRequest | null;

    /**
     * The data-task for saving changes done in the grid.
     */
    saveData?: SaveDataTaskRequest | null;
}

/**
 * Checks whether the given value matches the interface of a `DataTaskRequest`.
 *
 * @param value the value to check if is a data task request.
 * @returns `true` if the value matches the interface of a `DataTaskRequest`, `false` if not.
 */
export function isDataTaskRequest(value: unknown): value is DataTaskRequest {
    if (!!value && typeof value === 'object' && Object.hasOwn(value, 'method') && Object.hasOwn(value, 'parameters')) {
        return true;
    }
    return false;
}

/**
 * Represents a data task request.
 */
export interface DataTaskRequest {
    /**
     * The registered procedure number of the datatask request.
     */
    method: number | ((state?: ChangeState, changes?: { [key: string]: GridRowChanges }) => unknown) | null;

    /**
     * The parameters to send with the datatask request.
     */
    parameters: object | null;
}

/**
 * Represents data task request configuration for load data procedure of the grid.
 */
export interface LoadDataTaskRequest extends DataTaskRequest {
    /**
     * The keyname of the property to use as the id for rows, the value of this property must be unique for the row.
     * Setting the primary key of a row helps the grid not flicker when reloading data as it can keep a reference and can simply redraw the row, instead of deleting and inserting a new.
     */
    primaryKey?: string | null;
}

export interface AddRowDataTaskRequest extends DataTaskRequest {
    /**
     * Reference to function to call after a new row has been inserted.
     *
     * @param row the newly added row.
     */
    post?: ((row?: GridRow) => unknown) | null;

    /**
     * Whether new rows should be auto-saved.
     */
    autoSave?: boolean | null;

    /**
     * Whether a user should confirm before adding a new row.
     */
    confirm?: boolean | null;

    /**
     * Reference to the function to call before a row is to be inserted.
     *
     * @param row the row to be inserted.
     */
    pre?: ((row?: GridRow) => unknown) | null;

    /**
     * Wheather a edit modal should be opened after inserting a new row, default is `false`.
     */
    openEdit?: boolean;
}

export interface RemoveRowDataTaskRequest extends DataTaskRequest {
    /**
     * Reference to function to call after a rows have been removed.
     *
     * @param rows the removed rows.
     */
    post?: ((rows?: GridRow[]) => unknown) | null;

    /**
     * Whether removal of rows should be auto-saved.
     */
    autoSave?: boolean | null;

    /**
     * Whether the user should have to confirm before removing a row.
     */
    confirm?: boolean | null;
}

export type SaveDataTaskRequest = DataTaskRequest & SaveDataConfiguration;

/**
 *
 */
export interface GridConfig {
    /**
     * Configuration of editable columns.
     *
     * @deprecated use edit configuration from colschema datatask procedures.
     */
    editColumns?: EditColumn[];
    fixedHeader?: boolean;
    toolbar?: GridToolbar;
    keepSortOnAdd?: boolean;
    keepSortOnCheckbox?: boolean;
    keepSortOnIsSelected?: boolean;
    rowClick?: boolean;

    /**
     * Configuration of the side-bar.
     */
    sidebar?: GridSidebarConfig;

    /**
     * Whether shortcuts should be enabled in te grid, currently supports `Ctrl + I` / `Ctrl + Shift + I` for inserting new row and `Ctrl + D` for deleting a row.
     */
    shortcuts?: boolean;
    specialFunc?: GridSpecialFunctions;

    /**
     * Navigation settings for the grid.
     */
    navigation?: GridNavigation;
    css?: GridPredefinedCSS;
    onDataSourceChanges?: null;

    onDataChanged?: null | ((event?: any) => void);

    /**
     * Whether paging, filtering and sorting should be handled server side
     *
     * **NB: editing is not supported with server side handling yet**.
     */
    serverSideHandling?: boolean | null;
}

/**
 * Represents configuration option of the side-bar.
 */
export interface GridSidebarConfig {
    /**
     * Whether the sidebar should be hidden.
     */
    hidden?: boolean | null;

    /**
     * Whether the columns panel should be hidden from the side-bar.
     */
    hideColumnsPanel?: boolean | null;

    /**
     * Whether the filter panel should be hidden from the side-bar.
     */
    hideFilterPanel?: boolean | null;

    /**
     * Whether the row group pane should be hidden in the columns panel. **NB: does not disable rowgrouping**.
     */
    hideRowGroupPane?: boolean | null;

    /**
     * Whether the values pane should be hidden in the columns panel.
     */
    hideValuesPane?: boolean | null;

    /**
     * Whether pivot mode toggle should be hidden in the columns panel.
     */
    hidePivotMode?: boolean | null;

    /**
     * Custom toolbar panels, see [ag-grid documentation](https://www.ag-grid.com/angular-data-grid/component-tool-panel/) for implemenation.
     */
    customPanels?: ToolPanelDef[];
}

/**
 * Represents configuration of a grid's toolbar.
 */
export interface GridToolbar {
    /**
     * Whether the toolbar should be hidden or not.
     */
    hidden?: boolean;

    /**
     * Whether pdf-export button should be displayed or not.
     */
    pdfExport?: boolean;

    /**
     * Whether excel-export button should be displayed or not.
     */
    excelExport?: boolean;

    /**
     * Whether filter button, to toggle floating-filters, should be displayed or not.
     */
    filter?: boolean;

    /**
     * Whether button for controlling column visibility should be displayed or not.
     *
     * @deprecated column visibility is not the side panel.
     */
    columnVisibility?: boolean;

    /**
     * Whether button for toggling colnames should be displayed or not.
     */
    headers?: boolean;

    // TODO: whats this?
    edit?: boolean;

    // TODO: whats this?
    lock?: boolean;

    /**
     * Whether buttons for adding row either above or below the selected row, or on top/bottom in the grid should be displayed.
     */
    add?: boolean;

    /**
     * Whether button for adding a row above the selected, or in the top of the grid should be displayed.
     */
    addSimple?: boolean;

    /**
     * Whether button for delete should be displayed or not.
     */
    delete?: boolean;

    /**
     * Whether button for manual save should be displayed or not.
     */
    save?: boolean;

    /**
     * Whether button for toggling text-wrapping should be displayed or not.
     */
    wrapping?: boolean;

    /**
     * Whether button for custom column-layouts should be displayed or not.
     */
    layouts?: boolean;

    /**
     * Whether button for re-rendering rows should be displayed or not.
     */
    refresh?: boolean;

    /**
     * Whether button for reloading grid-data should be displayed or not.
     */
    read?: boolean;

    /**
     * Whether button for completely reinitializing grid should be displayed or not.
     */
    rebind?: boolean;

    /**
     * Whether button for printing reports should be displayed or not.
     */
    print?: boolean;

    /**
     * List of custom toolbar buttons.
     */
    buttons?: (GridButton | DBGridButton)[];

    /**
     * List of custom toolbar toggles.
     */
    toggles?: ToggleButton[];
}

/**
 * Represents a button displayed in the grid.
 */
export interface GridButton {
    /**
     * Name of the button, used in grid component.
     */
    name: string;

    /**
     * Text to display in the button.
     */
    text?: string;

    ariaLabel?: string;

    /**
     * Event handler for the button.
     *
     * @param event the mouse event from the click.
     */
    func(event: MouseEvent): void;

    /**
     * Event handler for the button.
     *
     * @param row the row for which this button was clicked..
     * @param button the button configuration for this button.
     * @param event the mouse event for the button click.
     */
    func(row: GridRow, button: DBGridButton, event: MouseEvent): unknown | Promise<unknown>;

    /**
     * The class for the icon to display.
     */
    icon?: string;

    secondIcon?: string;

    /**
     * Classes to apply to the button.
     */
    cssClass?: string;

    /**
     * Whether to translate the text of the button or not, default is true.
     */
    translate?: boolean;

    /**
     * Whether the button should be disabled or hidden.
     */
    disabled?: () => (boolean | 'hidden') | Promise<boolean | 'hidden'>;

    disabled$?: Observable<boolean | 'hidden'>;
}

/**
 * Represents a button set up from colschema.
 */
export interface DBGridButton extends GridButton {
    /**
     * The modal component to open, if type is modal.
     */
    component?: string;

    /**
     * The icon to display for the button.
     */
    icon?: string;

    /**
     * The id of the button.
     */
    keyno?: number;

    /**
     * If type is modal, this is the size of the modal.
     */
    modal_size?: 'pst-ninetyfive' | 'sm' | 'md' | 'lg';

    /**
     * The datatask procedure to call if type is datatask.
     */
    p2_datatask_keyno?: number;

    /**
     * If type is modal and component = ttDynamicView then this is the route to send to the component.
     */
    route_id?: string;

    /**
     * If type is goto, this is the state to go to.
     */
    state?: string;

    /**
     * If type is goto, this the parms to use for the state to go to.
     */
    state_params?: string;

    /**
     * The type of button.
     */
    type?: 'statigoto' | 'goto' | 'datatask' | 'modal' | 'cell' | 'noclick' | 'print' | 'popup';

    /**
     * The button type for the button if its not cell type.
     */
    btn_type?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning';
}

/**
 *
 */
export interface GridToolbarToggle {}

export interface ToggleState {
    text: string;
    translate?: boolean;
    func: (state: number) => void;
    _func?: () => void;
    icon: string;
}

export interface ToggleButton {
    /**
     * The name of the button, used to identify the button.
     */
    name: string;

    /**
     * Index of the initial state.
     */
    state: number;

    /**
     * The states of the toggle.
     */
    states: ToggleState[];

    /**
     * Css classes to apply to the button.
     */
    cssClass: string;

    /**
     * Whether the button should be disabled or hidden.
     */
    disabled?: () => (boolean | 'hidden') | Promise<boolean | 'hidden'>;

    disabled$?: Observable<boolean | 'hidden'>;
}

export interface GridSpecialFunctions {
    /**
     * Whether to make the goto button added to each row if it has goto parms to a new tab button.
     */
    newTab?: boolean;

    /**
     * Whether to edit button to row buttons opening a default edit modal.
     */
    edit?: boolean | null;

    /**
     * Custom buttons to add to each row.
     */
    buttons?: any[];
}

/**
 * Represents configuration of navigation in grid.
 */
export interface GridNavigation {
    /**
     * Alternative navigation, whether `Enter` key should behave like `Tab`.
     */
    altNav?: boolean;

    /**
     * Whether a new row should be added when pressing `Enter` on the last editable cell or the first editable cell.
     */
    newLine?: boolean;
}

/**
 * Represents configuration for predefined css settings.
 */
export interface GridPredefinedCSS {
    /**
     * Whether to alternate colors or not.
     */
    altColor?: boolean;

    /**
     * Whether text should wrap or not, default is false.
     */
    textWrapping?: boolean;

    height?: string | 'fill';

    minHeight?: string;
}

/**
 * Represents an object used in a grid row.
 */
export type GridRow = { [key: string]: any } & { _dirty?: boolean; _uuid?: string };

/**
 * Represents cell editor configuration for a look-up cell.
 */
export interface LookupCellEditorParams extends ICellEditorParams {
    /**
     * The datatask procedure to use for searching.
     */
    method: string | number;

    /**
     * The colname for the cell where the search is happening.
     */
    field: string;

    /**
     * The key name for the display value of the objects retrieved from the lookup procedure.
     */
    keyname: string;

    /**
     * The key of the id property of the lookup result objects.
     */
    key: string;
}

/**
 * Checks whether the given column-editable property of a gridcolumn is of type LookupConfig.
 *
 * @param editable the editable property of a column to check if is a LookupConfig.
 * @returns true if the editable is a LookupConfig, false if not.
 */
export function isEditableLookup(editable: unknown): editable is LookupConfig {
    if (editable && typeof editable === 'object' && Object.hasOwn(editable, 'lookup') && (editable as LookupConfig).lookup !== null && !isNaN(Number((editable as LookupConfig).lookup))) {
        return true;
    }
    return false;
}

/**
 * Represents user settings for the grid.
 */
export interface GridUserSettings {
    /**
     * The currently chosen page the user last selected.
     */
    page?: number;

    /**
     * The page size the user last used.
     */
    pageSize?: number;
    /**
     * Map of columns, where column field is key and valye is the respective column settings.
     */
    columns: { [key: string]: GridColumnSetting };

    /**
     * Contains a filters objects which holds a list over the last used filters.
     */
    filter: { filters: GridFilterSetting[]; base64Encoded?: boolean };

    /**
     * List over sort settings.
     */
    sort?: GridSortSetting[];

    /**
     * Setting for last used filter method.
     */
    filterable?: null | GridFilterable;
}

/**
 * Represents a sort setting for a column.
 */
export interface GridSortSetting {
    /**
     * The field name for the column with the sort setting.
     */
    field: string;

    /**
     * The direction of the sort setting.
     */
    dir: 'asc' | 'desc';

    /**
     * The index of the sort, if multiple sorts are applied.
     */
    index?: number | null;
}

/**
 * Represents a filter setting for a column.
 */
export interface GridFilterSetting {
    /**
     * The field name of the column to apply this filter to.
     */
    field: string;

    /**
     * The operator for the filter type on the filter.
     */
    operator: GridFilterType;

    /**
     * The value of filter.
     */
    value: string;

    base64Encoded?: boolean;

    set?: string[];
}

export type GridFilterType = 'contains' | 'doesnotcontain' | 'eq' | 'neq' | 'startswith' | 'endswith' | 'gt' | 'gte' | 'lt' | 'lte' | 'isempty' | 'isnull' | 'isnullorempty' | 'isnotempty' | 'isnotnull' | 'isnotnullorempty';

/**
 * Represents settings for a single column.
 */
export interface GridColumnSetting {
    /**
     * Whether the column should be hidden or not.
     * If not defined the column will be visible.
     */
    hidden?: boolean;

    /**
     * The order of the column where 0 is left and increasing number goes toward the right.
     */
    order?: number;

    /**
     * The width of the column.
     */
    width?: number;

    /**
     * Whether the column is pinned or not.
     */
    pinned?: boolean | 'left' | 'right' | null;

    /**
     * The filter string to apply to the columns filter.
     */
    filter?: GridFilterSetting | null;
}

/**
 * Represents a column layout schema.
 */
export type ColumnLayoutSchema = { [key: string]: GridColumnSetting } & { tt_grid_filterable?: boolean };

/**
 * Represents a grid column configuration.
 */
export interface GridColumn {
    /**
     * The field name of the column.
     */
    colname: string;

    /**
     * The db data type of the column.
     */
    coltype: string | null;

    /**
     * In a db decimal type defined by (x, y), x is this width.
     */
    width: number | null;

    /**
     * How many decimals to append to a column of the type `'decimal'`.
     */
    decimals: number | null;

    /**
     * How to align the content in the columns cell.
     */
    align: 'right' | 'left' | 'center' | null;

    /**
     * The translate id of the title.
     */
    title_id: string | null;

    /**
     * The translated title to display.
     */
    title: string | null;

    /**
     * Whether the cells in this columns are editable, or configuration for editing.
     */
    editable: boolean | string | null | LookupConfig;

    /**
     * How the cells in this column should be edited.
     */
    edittype: string | null;

    /**
     * Whether this cilumn should be visible initially before user settings set in.
     */
    visibility: string | null;

    /**
     * The initial width of the column, before usersettings set in.
     */
    colwidth: any;

    /**
     * The initial order of the column, before usersettings set in.
     */
    colorder: null;

    /**
     * Whether the column is sortable or not.
     */
    sortable: null | '1' | '0';

    /**
     * Whether the column is filterable or not.
     */
    filterable: null | '1' | '0';

    /**
     * Stringified function statement to call to style the cells in the column.
     */
    style_script: null;

    /**
     * The set of options to display in the select, only applicable if edittype of a column is set to `"DD"` (meaning select-component).
     */
    dd_data: null | { [key: string]: string }[];

    /**
     * The key of the id property of the select, only applicable if edittype of a column is set to `"DD"` (meaning select-component).
     */
    dd_data_id: null | string;

    /**
     * The key of the name property of the select, only applicable if edittype of a column is set to `"DD"` (meaning select-component).
     */
    dd_data_name: null | string;

    /**
     * The sort to apply on the column.
     */
    sort?: 'asc' | 'desc' | null;

    /**
     * The sort index to apply on the column if theres multiple sorts.
     */
    sort_index?: number | null;

    /**
     * Grid-options set up from database, are only applied if the colname is `'xxxoptionsxxx'`.
     */
    gridoptions: null | DBGridOptions;

    /**
     *
     */
    buttonoptions: null | object;
}

/**
 * Represents configuration for a cell which has a search-field.
 */
export interface LookupConfig {
    /**
     * The id key name for the id property of the the values retrieved from lookup.
     */
    key: string;

    /**
     * The datatask procedure number to use for the seatch.
     */
    lookup: string | number;

    //hhuh
    optionfunc: string | boolean;

    /**
     * The key name for the display proprty of the values retrieved from lookup.
     */
    datatextfield: string | null;

    /**
     * List of objects containing relations keyname for the key (the data key of the cell) to be updated,
     * and the value (the data key of the list from lookup) to update with.
     */
    relations: { key: string; value: string }[];

    /**
     *
     */
    clickonly: boolean;
}

/**
 * Represents configuration of save data procedure.
 */
export interface SaveDataConfiguration {
    /**
     * Whether changes should be confirmed.
     */
    confirm?: null | boolean;

    /**
     * Whether changes to data should be auto-saved, (this one is used internally).
     */
    autoSave?: boolean | null;

    /**
     * Whether changes to data should be auto-saved.
     */
    autoSaveChanges?: null | boolean;

    /**
     * Whether to wait for each save to finish before saving next, and stopping saving when an error occurs.
     */
    single?: null | boolean;

    /**
     * Whether to refresh row after data is saved.
     */
    refreshRowOnSave?: true;

    /**
     * Whether the refresh spinner should be hidden while saving data.
     */
    hideRefreshSpinner?: null | boolean;

    /**
     * Whether to reload the all grid data rows after changes are saved.
     */
    readAfterSave?: null | boolean;

    /**
     * Whether only rows where `is_selected` row is checked should be saved.
     */
    onlySaveIsSelected?: null | boolean;

    /**
     * Whether to include the load data parameters in the save procedure.
     */
    saveInclLoadparms?: null | boolean;
}

/**
 * Represents grid options retrieved from db in colschema.
 */
export interface DBGridOptions {
    load?: {
        /**
         * The keyname of the property to use as the id for rows, the value of this property must be unique for the row.
         * Setting the primary key of a row helps the grid not flicker when reloading data as it can keep a reference and can simply redraw the row, instead of deleting and inserting a new.
         */
        primaryKey?: string | null;
    };

    add?: {
        confirm?: null | boolean;
        autoSave?: null | boolean;
    };

    remove?: {
        confirm?: null | boolean;
        autoSave?: null | boolean;
    };

    /**
     * Configuration for the save data procedure.
     */
    save?: SaveDataConfiguration;

    /**
     * Toolbar buttons from colschema setup.
     */
    toolbar?: GridToolbar;

    sidebar?: GridSidebarConfig;

    aggregates?: boolean | null | TTGridAggregate[];

    kendo?: {
        selectable?: boolean | 'multiple' | 'single' | 'na';
        sortable?: 'na' | boolean;
        filterable?: null | boolean | 'row' | 'na';
    };

    specialFunc?: {
        newTab?: false;
        buttons?: [
            {
                keyno?: 1;
                name?: 'prod_id';
                text?: '';
                translate?: false;
                icon?: '';
                btn_type?: '';
                type?: 'noclick' | 'staticgoto' | 'modal' | 'datatask' | 'cell' | 'goto';
                state?: '';
                state_params?: '';
                p2_datatask_keyno?: 0;
                component?: '';
                route_id?: '';
            }
        ];
    };

    reports?: [];
}

/**
 * Represents a column layout used in the grid.
 */
export interface GridColumnLayout {
    /**
     * The id of the column layout.
     */
    gridlayout_keyno: string;

    /**
     * The name of the column layout.
     */
    gridlayout_name: string;

    /**
     * Base64 encoded object of the user settings for this column layout.
     */
    layout_schema: string;

    /**
     * The order placement of this layout in a layouts list.
     */
    orderby: string;

    /**
     * Whether this layout is the current layout applied to the grid.
     */
    isActive?: boolean;

    /**
     * Whether this column layout is getting deleted.
     */
    willGetDeleted?: boolean;
}

/**
 * Represents parameters required for retrieving grid columns.
 */
export interface GridColumnsParams {
    /**
     * The method to use for retrieving columns, defaults to `1999` if not specified.
     */
    method?: number;

    /**
     * The method to use for loading grid row data.
     */
    loadDataMethod: number;

    /**
     * Parameters to use for retrieving row data and columns.
     */
    params: { [key: string]: any } | null;

    force?: boolean;
}

/**
 * Represents parameters used for saving usersettings.
 */
export interface SaveUserSettingsParams {
    /**
     * The method to use for storing user variables, defaults to `616` if not specified.
     */
    method?: number;

    /**
     * The name of the variable to index the value with.
     */
    variablename: string;

    /**
     * The value to store, is stringified to JSON when stored.
     */
    variablevalue: any;

    /**
     * Whether the value should be converted to base 64.
     */
    isBase64?: boolean;
}

/**
 * Represents parameters required for saving a new column layout.
 */
export interface CreateColumnLayoutParams {
    /**
     * The grids load data method which the column layout should be connected to.
     */
    loadDataMethod: number;

    /**
     * The name of the new column layout.
     */
    layoutname: string;

    /**
     * The state of the user settings to be stored as a column layout.
     */
    layout: ColumnLayoutSchema;
}

/**
 * Represent configuration for edit behaviour of a column.
 */
export interface EditColumn {
    /**
     * The colname of the column to configure edit-behaviour of, if only this property is then the column with the given key will be editable.
     */
    key: string;

    /**
     * Whether the column can only be edited by clicking the cell.
     */
    clickonly?: boolean | null;

    /**
     * Lookup configuration for the column if applicable.
     */
    lookup?: null | LookupConfig;
}

/**
 * Represents parameters in datatask requests for server side handling of data.
 */
export interface ServerSideRequestParams {
    /**
     * The index of the last row to retrieve.
     */
    take?: number;

    /**
     * Number of rows to skip past in the request.
     */
    skip?: number;

    /**
     * The filter model to retrieve rows based on.
     */
    filter?: { filters: GridFilterSetting[] };

    /**
     * The sort model to retrieve rows based on.
     */
    sort?: GridSortSetting[];

    /**
     * The current page to retrieve rows for.
     */
    page?: number;

    /**
     * The current page size to retrieve rows for.
     */
    pageSize?: number;
}

/**
 * Represents additional functions to use for interacting with the grid.
 */
export interface GridFunctions {
    /**
     * Redraws in DOM the currently loaded data in the grid.
     */
    refresh: () => void;

    /**
     * Fetches grid data again and reload the rows in the grid.
     */
    read: () => Promise<void>;

    /**
     * Forces a check of the disabling and visibility of toolbar buttons.
     */
    refreshToolbarBtnDisability: () => void;

    /**
     * Forces a recalculation of aggregates.
     */
    refreshAggregates: () => void;

    /**
     * Opens a modal displaying a table of containing the given list of records. The headers of the table are equal to the keynames of the first object in the list.
     */
    callPopupTable: (data: Record<string, unknown>[]) => Promise<null | Record<string | symbol, unknown>>;

    /**
     * Sets the cell at the given row index and column index to the focused cell.
     *
     * @param rowIndex the index of the row where to set the focused cell.
     * @param colIndex the index of the column (zero-based, only visible) for where to set the focused cell.
r     */
    setFocusToCell: (rowIndex: number, colIndex: number) => void;

    /**
     * Starts cell editing on the cell with the given row-index and given column-index if the column is editable and visible.
     *
     * @param rowIndex the row index to start editing on.
     * @param colIndex the column index to start editing on.
     */
    editCell: (rowIndex: number, colIndex: number) => void;

    /**
     * Clears the current filters from the grid.
     */
    clearFilter: () => void;

    /**
     * Clears the current sortings from the grid.
     */
    clearSorting: () => void;

    /**
     * Inserts a row before the row at the given index, containing the given data-item values.
     *
     * @param atIndex the index of the row to insert the new row before.
     * @param dataItem the row data to insert into the row.
     * @returns a promise containing the newly added row once the add operation is complete.
     */
    addRowBefore: (atIndex: number, dataItem?: GridRow) => Promise<GridRow | undefined>;

    /**
     * Inserts a row after the row at the given index, containing the given data-item values.
     *
     * @param atIndex the index of the row to insert the new row after.
     * @param dataItem the row data to insert into the row.
     * @returns a promise containing the newly added row once the add operation is complete.
     */
    addRowAfter: (atIndex: number, dataItem?: GridRow) => Promise<GridRow | undefined>;

    /**
     * @deprecated not supported anymore after switch to ag-grid, also was not used anywhere.
     */
    getColumnFormatType: (key: string) => string;

    /**
     * @deprecated used to return list of responses from an `Promise.all`, but the list is not the same in ag-grid and it was also never used anywhere.
     */
    getResponse: () => void;

    /**
     * @deprecated not used anywhere, and key information about columns is found in `getGridColumn` and `getColumnSchema`.
     */
    getResponseColumns: () => GridColumn[];

    /**
     * Redraws the given row, or the given row at the given row index.
     *
     * @param row the row to redraw, set as `null` if using row index to update row instead.
     * @param rowIdx the index of the row to update.
     */
    updateRow: (row: GridRow | null, rowIdx?: number) => void;

    /**
     * Removes the given data-item (grid-row). The item must contain its assigned _uuid.
     *
     * @param dataItem the grid row to remove.
     * @returns promise which resolves when removal has finished. Promise contains the removed row or `null` if the removal was cancelled
     */
    removeRow: (dataItem: GridRow) => Promise<GridRow | null>;

    /**
     * Remove currently selected (selected with native select) rows.
     *
     * @returns promise which resolves when row removal has finished. Promise contains removed rows or `null` if the removal was cancelled.
     */
    removeRows: () => Promise<GridRow[] | null>;

    /**
     * Returns a list over edited (dirty) rows.
     *
     * @returns list over edited (dirty) rows.
     */
    getDirtyRows: () => GridRow[];

    /**
     * Returns currently selected rows based on ag-grid native row selection.
     *
     * @returns list of currently selected rows based on ag-grid native row selection.
     * @deprecated use `getSelectedRows` instead.
     */
    getSelectedRow: () => GridRow[];

    /**
     * Returns currently selected rows based on ag-grid native row selection.
     *
     * @returns list of currently selected rows based on ag-grid native row selection.
     */
    getSelectedRows: () => GridRow[];

    getIsSelectedRows: () => GridRow[];

    /**
     * Retrieves all rows from the grid, also non-displayed rows.
     *
     * @returns all rows from the grid, also non-displayed rows.
     */
    getAllRows: () => GridRow[];

    /**
     * Retrieves rows from the grid, also non-displayed rows. If dirty is true then only dirty rows are retrieved.
     * If sorted is true the rows are returned in the order of the current sort settings.
     *
     * @param dirty whether to filter out to retrieve only dirty rows.
     * @param sorted whether to retrieve the rows in the order of the current sort settings.
     * @returns rows from the grid.
     */
    getRows: (dirty?: boolean, filtered?: boolean, sorted?: boolean) => GridRow[];

    /**
     * Retrieves the row at the given index.
     *
     * @param index the index of the row to retrieve.
     * @returns the row at the given index, or `null` if there are no rows at the index.
     */
    getRowAt: (index: number) => IRowNode | null;

    /**
     * @deprecated kendo grid object doesnt exist in ag-grid `:)` use other gridfunction or try getGridApi for special cases.
     */
    getGrid: () => {};

    /**
     * Returns the ag-grid grid-api.
     *
     * @returns the ag-grid grid-api.
     */
    getGridApi: () => GridApi;

    /**
     * Retrieves the row at the given index.
     *
     * @param atIndex the index of the row to retrieve.
     * @returns the row at the given index, or `null` if there are no rows at the index.
     * @deprecated use `getRowAt` instead.
     */
    getDataItem: (atIndex?: number) => GridRow | null;

    /**
     * Returns list over all displayed rows in the grid.
     *
     * @returns list of all displayed rows in the grid.
     */
    getDataItems: () => GridRow[];

    /**
     * Returns object containing information about datasource.
     *
     * @returns object containing information about datasource.
     * @deprecated due to upgrade of grid the object is incomplete and no longer in use.
     */
    getDataSource: () => { sort: () => { field: string; dir: 'asc' | 'desc' }[] };

    /**
     * Returns a list of the current column states.
     *
     * @returns a list over current column states.
     */
    getColumnState: () => ColumnState[];

    /**
     * Sets the given new data source as data in the grid.
     *
     * @param newDataSource list of rows to set as data in the grid.
     */
    setDataSource: (newDataSource: GridRow[]) => void;

    /**
     * Returns list of column defintions of the columns in the grid.
     *
     * @returns list of column definitions of the columns in the grid.
     */
    getGridColumns: () => ColDef[];

    /**
     * Returns object containing information about each column in the grid, where the key is the colname of each column.
     *
     * @returns object containing information about each column in the grid.
     */
    getColumnSchema: () => { [key: string]: GridColumnInfo };

    /**
     * TODO: find out what it does and document.
     */
    viewMatching: (criteria: string | undefined, value: unknown) => void;

    /**
     * Saves all current changes in the grid. Returns a promise which resolves when all save operations are completed.
     *
     * @param showProgress whether to show loading overlay while saving changes or not.
     * @returns a void promise which resolves when the load operation is completed.
     */
    saveChanges: (showProgress?: boolean) => Promise<void>;

    /**
     * Completely removes the grid from DOM and refetches all data for the grid. Resolves a void promise when rebind is completed.
     *
     * @returns void promise that resolves when the rebind is complete.
     */
    rebind: () => Promise<void>;

    /**
     * Redraws the row.
     *
     * @param rowElement not in use. do not use. dont expect anything from using this.
     * @param row the grid row object to update.
     * @deprecated use updaterow.
     */
    redrawRow: (rowElement: HTMLElement, row: GridRow) => void;

    /**
     * Start or stop the grid loading overlay.
     *
     * @param spin `true` if loading overlay should show, `false` if the loading overlay should be hidden.
     */
    gridProgress: (spin?: boolean) => void;

    /**
     * Selects the row at the given index of currently visible rows.
     *
     * @param index the row index of the row to select.
     */
    selectRow: (index: number) => void;

    /**
     * Returns whether the grid is finished loading and ready for use.
     *
     * @returns `true` if the grid is ginished loading and ready for use, `false` if not.
     */
    isReady: () => boolean;

    /**
     * Returns whether the grid has any row data or not.
     *
     * @returns `true` if the grid has row data, `false` if not.
     */
    hasRows: () => boolean;

    /**
     * Resizes the grid elements.
     */
    resize: () => void;

    /**
     * Test function.
     *
     * @param p1 parameter 1.
     * @param p2 parameter 2.
     */
    test: (p1: unknown, p2: unknown) => string;
}

export type CellDataTypes = 'text' | 'number' | 'boolean' | 'date' | 'currency' | 'datetime' | 'time' | 'alt' | 'icon';
