(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2ExportProcService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            loadP2EdiExportProcTableNameExtList: function () {
                return p2DataTaskService.call(991, {});
            },
            loadExportProcWebPageList: function () {
                return p2DataTaskService.call(1890, {});
            },
            listEdiTypes: function (parms) {
                return $ihttp.post({
                    method: 2264,
                    parameters: parms || {}
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();