
// ##################################################################
// ## Author: 
// ## Description: 
// ## Dependencies: 
// ## Date modified: 01.01.2019       
// ## Date registered: 15.10.2018
// ##################################################################

(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockCountMenuService", ['$q', 'p2DataTaskService', 'consoleService', function ($q, p2DataTaskService, consoleService) {
        var service = {
			loadP2WStockCountMenuGet: function (stockcount_keyno) {
				return p2DataTaskService.call(952, {
					stockcount_keyno: stockcount_keyno
				});
            },
            approveP2WStockCountMenu: function (p2WStockCountMenuGet) {
                return p2DataTaskService.call(962, p2WStockCountMenuGet);
            },
            loadP2WStockCountMenuDataMiningItem: function (p2WStockCountMenuGet) {
                return p2DataTaskService.call(958, p2WStockCountMenuGet);
            },
            //refreshP2WStockCountMenuStockCount: function (stockcount_keyno) {
            //    return p2DataTaskService.call(981, {
            //        stockcount_keyno: stockcount_keyno
            //    });
            //},
            deleteP2WStockCount: function (p2WStockCountMenuGet) {
                return p2DataTaskService.call(955, p2WStockCountMenuGet);
			}
        };

        return service;
    }]);
})();
