(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('labourPosition', {
        templateUrl: 'views/components/views/labourPosition/labourPosition.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'labourPositionService', 'logisticService', 'equipmentEditService', 'operationFactoryService', 'rememberService', 'translateService', function ($stateParams, $q, stateService, utilityService, modalService, labourPositionService, logisticService, equipmentEditService, operationFactoryService, rememberService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var labourPositionKeyno = $stateParams.labourposition_keyno;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;
            
            let translations = {
                error: '',
                ok: ''
            };

            var variableNames = {
                scanning_modus: '',
                businessco_no: '',
                factory_id: '',
                workgrp_keyno: '',
                equipment_id: '',
                operation_id: ''
            };

            var resetEquipment = function () {
                vm.model.edit.equipment_id = '';

                rememberFunc('equipment_id');
                loadOperations();
                loadPositions();
                
                if (vm.model.bgLabourPosition.item_selected === 'LABOURPOSITIONS') {
                    vm.grid.gridfunc.rebind();
                } else {
                    vm.gridActiveJob.gridfunc.rebind();
                }
            };

            var resetOperation = function () {
                vm.model.edit.operation_id = '';
                rememberFunc('operation_id');
            };

            vm.version = module.version;

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'factories':
                    case 'workgrps':
                    case 'equipments':
                    case 'operations':
                        stateService.go(state, {});
                        break;
                    case 'factorymenu':
                        stateService.go(state, { factory_id: vm.model.edit.factory_id });
                        break;
                    case 'workgrpmenu':
                        stateService.go(state, { workgrp_keyno: vm.model.edit.workgrp_keyno });
                        break;
                    case 'equipmentedit':
                        stateService.go(state, { equipment_id: vm.model.edit.equipment_id });
                        break;
                    case 'timestampstartcorr':
                        stateService.go(state, { labour_no: vm.model.edit.labour_no });
                        break;
                    case 'operationmenu':
                        stateService.go(state, { operation_id: vm.model.edit.operation_id });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                labourPositionKeyno: labourPositionKeyno,
                mobileSize: window.matchMedia('(min-width: 992px)'),
                btnListBarcode: [
                    { item_id: '0', item_name: 'scan', item_func: 'scan_item', glyph: 'glyphicon-barcode', color: 'primary' }
                ],
                factoryButtons: [
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'factories' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'factorymenu' } }
                ],
                workGroupButtons: [
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'workgrps' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'workgrpmenu' } }
                ],
                equipmentButtons: [
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'equipments' } }
                ],
                equipmentButtons2: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: resetEquipment } },
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'equipments' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'equipmentedit' } }
                ],
                operationButtons: [
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'operations' } }
                ],
                operationButtons2: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: resetOperation } },
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'operations' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'operationmenu' } }
                ],
                timeStampStartButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'timestampstartcorr' } }
                ],
                bgLabourPosition: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_labourposition_labourpositions', item_func: 'LABOURPOSITIONS' },
                        { item_id: '1', item_name: 'bg_labourposition_po', item_func: 'PO' }
                    ]
                },
                edit: {},
                selectListBusinessCompanies: [],
                selectListOperations: [],
                selectListLabourPositions: [],
                selectListFactories: [],
                selectListWorkGroups: [],
                selectListEquipments: [],
                itemsListPositions: [],
                lockedSave: false,
                lockedDelete: false,
                gridReady: false,
                gridActiveJobReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridActiveJob = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadlabourPosition = function () {
                labourPositionService.getLabourPosition({
                    labourposition_keyno: labourPositionKeyno,
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                    vm.grid.dataTask.rememberId = 'w_labourpositions_grid';
                    vm.gridActiveJob.dataTask.rememberId = 'w_labourposition_active_pojob_grid';

                    loadLabourPositions();
                    loadSelectFactories();
                    loadSelectWorkGroups();
                    loadSelectEquipments();
                    loadOperations();
                    loadPositions();

		            vm.grid.dataTask.loadData = {
                        method: 2562,
                        parameters: vm.model.edit
		            };

                    vm.gridActiveJob.dataTask.loadData = {
                        method: 2585,
                        parameters: vm.model.edit
                    };
                    
                    vm.model.gridReady = true;
		            vm.model.gridActiveJobReady = true;
                });
            };

            loadlabourPosition();

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListBusinessCompanies);
            });

            var loadLabourPositions = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;
                
                labourPositionService.listLabourPositions({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListLabourPositions);
                });
            };

            var loadSelectFactories = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;
                
                labourPositionService.listSelectFactories({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListFactories);
                });
            };

            var loadSelectWorkGroups = function () {
                if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;
                
                equipmentEditService.listSelectWorkGroups({ factory_id: vm.model.edit.factory_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListWorkGroups);
                });
            };

            var loadSelectEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;

                operationFactoryService.listSelectEquipments({
                    workgrp_keyno: vm.model.edit.workgrp_keyno,
                    businessco_no: vm.model.edit.businessco_no,
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListEquipments);
                });
            };

            var loadOperations = function () {
                if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;

                logisticService.listOperations({
                    direct: '1',
                    equipment_id: vm.model.edit.equipment_id,
                    workgrp_keyno: vm.model.edit.workgrp_keyno
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListOperations);
                });
            };

            var loadPositions = function () {
                if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;

                vm.model.itemsListPositions = [];

                labourPositionService.listPositions(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.itemsListPositions);
                });
            };

            var loadLabour = function () {
                var deferred = $q.defer();
		        
                if (utilityService.validateParmsValue(vm.model.edit.labour_no) !== true) return;

                labourPositionService.getLabour({ labour_no: vm.model.edit.labour_no }).then(function (response) {
                    vm.model.edit.factory_id = response[0].factory_id;
                    deferred.resolve();
                });

                return deferred.promise;
            };

            var loadScanBarcode = function () {
                if (utilityService.validateParmsValue(vm.model.edit.barcode) !== true) return;

                labourPositionService.scanItem(vm.model.edit).then(function (response) {
                    vm.model.edit.barcode = '';

                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        vm.model.edit.labour_no = response[0].labour_no;

                        labourPositionService.saveObj(vm.model.edit).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                loadlabourPosition();
                                loadPositions();
                                vm.grid.gridfunc.rebind();
                                vm.gridActiveJob.gridfunc.rebind();
                            }
                        });
                    }
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'scanning_modus':
                        if (utilityService.validateParmsValue(vm.model.edit.scanning_modus, true) !== true) return;

                        variableValue = vm.model.edit.scanning_modus;
                        break;
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;

                        variableValue = vm.model.edit.businessco_no;
                        break;
                    case 'factory_id':
                        if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;

                        variableValue = vm.model.edit.factory_id;
                        break;
                    case 'workgrp_keyno':
                        if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;

                        variableValue = vm.model.edit.workgrp_keyno;
                        break;
                    case 'equipment_id':
                        if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;

                        variableValue = vm.model.edit.equipment_id;
                        break;
                    case 'operation_id':
                        if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;

                        variableValue = vm.model.edit.operation_id;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'scanning_modus':
                        vm.model.edit.scanning_modus = value;

                        if (utilityService.validateParmsValue(vm.model.edit.scanning_modus, true) !== true) return;

                        rememberFunc('scanning_modus');
                        break;
                    case 'businessco_no':
                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadLabourPositions();
                        loadSelectFactories();
                        loadSelectEquipments();
                        break;
                    case 'factory_id':
                        vm.model.edit.factory_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;

                        vm.model.edit.workgrp_keyno = '0';
                        vm.model.edit.equipment_id = '';
                        vm.model.edit.operation_id = '';

                        rememberFunc('factory_id');
                        loadSelectWorkGroups();
                        loadSelectEquipments();
                        break;
                    case 'workgrp_keyno':
                        vm.model.edit.workgrp_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;

                        vm.model.edit.equipment_id = '';
                        vm.model.edit.operation_id = '';

                        rememberFunc('workgrp_keyno');
                        loadSelectEquipments();
                        loadOperations();
                        loadPositions();

                        if (vm.model.bgLabourPosition.item_selected === 'LABOURPOSITIONS') {
                            vm.grid.gridfunc.rebind();
                        } else {
                            vm.gridActiveJob.gridfunc.rebind();
                        }
                        break;
                    case 'equipment_id':
                        vm.model.edit.equipment_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;

                        rememberFunc('equipment_id');
                        loadOperations();
                        loadPositions();

                        if (vm.model.bgLabourPosition.item_selected === 'LABOURPOSITIONS') {
                            vm.grid.gridfunc.rebind();
                        } else {
                            vm.gridActiveJob.gridfunc.rebind();
                        }
                        break;
                    case 'labour_no':
                        vm.model.edit.labour_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.labour_no, true) !== true) return;

                        loadLabour().then(function () {
                            loadSelectWorkGroups();
                            loadSelectEquipments();
                        });
                        break;
                    case 'operation_id':
                        vm.model.edit.operation_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;

                        rememberFunc('operation_id');
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncBarcode = function (item_func) {
                switch (item_func) {
                    case 'scan_item':
                        loadScanBarcode();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion BUTTON LIST FUNCTIONS

            vm.scanBarcode = function () {
                loadScanBarcode();
            };

            vm.doScan = function (value) {
                vm.model.edit.barcode = value;
                vm.scanBarcode();
            }

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgLabourPosition = function (item) {
                vm.model.bgLabourPosition.item_selected = item;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                labourPositionService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                labourPositionService.deleteItem(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion DELETE PROCEDURE FUNCTION CALL

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            vm.turnOnScanMode = function () {
                vm.model.edit.scanning_modus = '1';
                rememberFunc('scanning_modus');
            };

            vm.turnOffScanMode = function () {
                vm.model.edit.scanning_modus = '0';
                rememberFunc('scanning_modus');
            };
        }]
    });
})();
