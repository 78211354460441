(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('transportAreaMenu', {
        templateUrl: 'views/components/views/transportAreaMenu/transportAreaMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'transportAreaMenuService', 'rememberService', function ($stateParams, $q, stateService, utilityService, transportAreaMenuService, rememberService) {
            
    		// ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let transportAreaKeyNo = $stateParams.transportarea_keyno;

            let variableNames = {
                filtervalue: ''
            };

    		vm.model = {
    			filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
    			menu: {},
    			itemsListPostPlaces: []
    		};
            
    		// ## LOAD PROCEDURE CALLS ##

            let loadMenu = function () {
                let deferred = $q.defer();
                
                transportAreaMenuService.getTransportAreaMenu({ transportarea_keyno: transportAreaKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);

                    $q.all([
                        loadPostPlaces()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
    		let loadPostPlaces = function () {
                transportAreaMenuService.listPostPlaces({ transportarea_keyno: transportAreaKeyNo }).then(function (result) {
    				angular.copy(result, vm.model.itemsListPostPlaces);
    			});
    		};
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'filtervalue':
                    		variableNames[key] = stateService.getCurrentName() + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.menu[id], true) !== true) return;

                        variableValue = vm.model.menu[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'transportarea':
                        go({
                            transportarea_keyno: vm.model.menu.transportarea_keyno,
                            stockplace_id: vm.model.menu.stockplace_id
                        });
                        break;
                    case 'transportareappadd':
                        go({ transportarea_keyno: vm.model.menu.transportarea_keyno });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.menu.filtervalue = '';

                        if (vm.model.menu.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.menu.filtervalue, true) !== true) return;

                        if (vm.model.menu.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.menu.filtervalue) !== true) return;

                        vm.model.menu.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.menu.filtervalue, true) !== true) return;

                        if (vm.model.menu.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
