(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('batchDeliveries', {
        templateUrl: 'views/components/views/batchDeliveries/batchDeliveries.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'batchDeliveriesService', function ($stateParams, batchDeliveriesService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                selectListRelatedTo: []
            };

            let loadSetting = function () {
                batchDeliveriesService.getBatchDelivery({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadBatchDeliveryRelatedTo = function () {
                batchDeliveriesService.listBatchDeliveryRelatedTo({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.selectListRelatedTo);
                });
            };

            vm.$onInit = function () {
                loadSetting();
                loadBatchDeliveryRelatedTo();
            };
		}]
	});
})();
