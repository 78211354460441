(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("selectCustomerService", [function () {
        let service = {};

        return service;
    }]);
})();
