(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("colorService", [function () {
        var service = {
            // Returns the complementary rgb color of the given rgb color values.
            complementaryRGBColor: function(r, g, b) {
                if (Math.max(r, g, b) == Math.min(r, g, b)) {
                    return [255 - r, 255 - g, 255 - b];
                } else {
                    r /= 255, g /= 255, b /= 255;
                    var max = Math.max(r, g, b), min = Math.min(r, g, b);
                    var h, s, l = (max + min) / 2;
                    var d = max - min;
                    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

                    switch (max) {
                        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                        case g: h = (b - r) / d + 2; break;
                        case b: h = (r - g) / d + 4; break;
                    }

                    h = Math.round((h * 60) + 180) % 360;
                    h /= 360;

                    function hue2rgb(p, q, t) {
                        if (t < 0) t += 1;
                        if (t > 1) t -= 1;
                        if (t < 1 / 6) return p + (q - p) * 6 * t;
                        if (t < 1 / 2) return q;
                        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                        return p;
                    }

                    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
                    var p = 2 * l - q;

                    r = hue2rgb(p, q, h + 1 / 3);
                    g = hue2rgb(p, q, h);
                    b = hue2rgb(p, q, h - 1 / 3);

                    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
                }
            },

            // Returns the complementary hex color string of given hex color string.
            complementaryHexColor: function (hex) {
                let rgb = service.hexToRgb(hex);
                let r = rgb[0];
                let g = rgb[1];
                let b = rgb[2];
                [r, g, b] = service.complementaryRGBColor(r, g, b);
                return '#' + (r < 16 ? '0' + r.toString(16) : r.toString(16)) + (g < 16 ? '0' + g.toString(16) : g.toString(16)) + (b < 16 ? '0' + b.toString(16) : b.toString(16));
            },

            // Converts a rgb array to a hexcolor string.
            rgbToHex: function (r, g, b, a) {
                let alpha = '';
                if (angular.isDefined(a) && a !== null) {
                    alpha = a.toString(16).length === 1 ? "0" + a.toString(16) : a.toString(16);
                }
                return "#" + ((1 << 24) + (r << 16) + (g << 8) + b) + alpha .toString(16).slice(1);
            },

            // Converts a hexcolor string to a rgb array.
            hexToRgb: function (hex) {
                let r = hex.length == 4 ? parseInt(hex[1] + hex[1], 16) : parseInt(hex.slice(1, 3), 16);
                let g = hex.length == 4 ? parseInt(hex[2] + hex[2], 16) : parseInt(hex.slice(3, 5), 16);
                let b = hex.length == 4 ? parseInt(hex[3] + hex[3], 16) : parseInt(hex.slice(5), 16);
                return [r, g, b];
            },

            // Parses a color string of any valid format to hexadecimal.
            parseColor: function (string) {
                if (null == string || string.length < 3) return;

                if (string.match('^#([A-Fa-f0-9]{8})|([A-Fa-f0-9]{6})|([A-Fa-f0-9]{3})$')) {
                    // Already hexadecimal.
                    return string;
                } else if (string.match('^[rgba]{0,4}\({0,1}[0-9]{1,3},[0-9]{1,3},[0-9]{1,3}[,]{0,1}[0-9]{0,3}\){0,1}$')) {
                    //format of rgb(r, g, b), rgba(r,g,b,a), (r,g,b,a) or r,g,b,a
                    let parts = string.split('rgba(,)');
                    console.dir(parts);
                    if (parts.length === 4) {
                        return colorService.rgbToHex(Number.parseInt(parts[0]), Number.parseInt(parts[1]), Number.parseInt(parts[2], Number.parseInt(3)));
                    } else if (parts.length === 3) {
                        return colorService.rgbToHex(Number.parseInt(parts[0]), Number.parseInt(parts[1]), Number.parseInt(parts[2]));
                    } else return;
                } else return;
            },

            // Gets the computed background color of a dom-element in the format "rgb(r, g, b)" or "rgba(r, g, b, a)".
            getBackgroundColor: function(element) {
                var backgroundColor = window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue("background-color") : element.style.backgroundColor;
                return backgroundColor !== "" && backgroundColor !== 'rgba(0, 0, 0, 0)' && backgroundColor !== '#ffffff' ? backgroundColor : service.getBackgroundColor(element.parentElement);
            },
            
            // Returns the luminance (or perceived brightness) of a color.
            getLuminance: function (color) {
                if (null === color || !angular.isDefined(color)) return;
                if (typeof color === 'string') {
                    if (color.substr(0, 1) === '#') {
                        color = service.hexToRgb(color);
                    } else if (color.substr(0, 3) === 'rgb') {
                        color = service.rgbStringToArray(color);
                    } else return;
                } else if (typeof color !== 'array') return;
                return (
                    0.2126 * color[0] +
                    0.7152 * color[1] +
                    0.0722 * color[2]
                );
            },

            // Gets the contrast between two colors.
            getContrast: function(f, b) {
                const L1 = service.getLuminance(f)
                const L2 = service.getLuminance(b)
                return (Math.max(L1, L2) + 0.05) - (Math.min(L1, L2) + 0.05);
            },

            // Picks the color with the highest contrast to the given background color from given array.
            getContrastColorFromArray: function(bgColor, colors) {
                const contrasts = [];
                for (let i = 0; i < colors.length; i++) {
                    contrasts.push(service.getContrast(bgColor, colors[i]));
                }
                return colors[contrasts.indexOf(
                contrasts.reduce((a, b) => Math.max(a, b), -Infinity))];;
            },
            getCssVariable: function (variable) {
                return getComputedStyle(document.documentElement).getPropertyValue(variable);
            },
            setCssVariable: function (variable, value) {
                document.documentElement.style.setProperty(variable, value);
            },
            getTextColors: function () {
                return ['#ffffff', '#000000'];
            }
        };

        return service;
    }]);
})();