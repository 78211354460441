(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderToConsignmentsService", [function () {
        var service = {

        };

        return service;
    }]);
})();