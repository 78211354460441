(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srUserAdminService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            
        };

        return service;
    }]);
})();