/**
 * https://learn.microsoft.com/en-us/graph/query-parameters?tabs=http
 * 
 * A Microsoft Graph API operation might support one or more of the following
 * OData system query options. These query options are compatible with the
 * OData V4 query language and are supported in only GET operations.
 */
export class ODataQueryParms {
    private _count: boolean | null | undefined;
    public _expand: string | undefined;
    public _filter: string | undefined;
    public _format: string | undefined;
    public _orderby: string | undefined;
    public _search: string | undefined;
    public _select: string | undefined;
    public _skip: number | undefined;
    public _top: number | undefined;
    public _skipToken: string | undefined;

    private _isChanged: boolean = false;

    public constructor(fields?: {
        count?: boolean,
        expand?: string,
        filter?: string,
        format?: string,
        orderby?: string,
        search?: string,
        select?: string,
        skip?: number,
        top?: number,
        skipToken?: string
    }) {
        if (fields) {
            if (fields.count) { this.count = fields.count; }
            if (fields.expand) { this.expand = fields.expand; }
            if (fields.filter) { this.filter = fields.filter; }
            if (fields.format) { this.format = fields.format; }
            if (fields.orderby) { this.orderby = fields.orderby; }
            if (fields.search) { this.search = fields.search; }
            if (fields.select) { this.select = fields.select; }
            if (fields.skip) { this.skip = fields.skip; }
            if (fields.top) { this.top = fields.top; }
            if (fields.skipToken) { this.skipToken = fields.skipToken; }
        }
    }

    public get isChanged() { return this._isChanged; }

    public get count() { return this._count; }
    public set count(value: boolean | null | undefined) {
        this._count = value;
        this._isChanged = true;
    }

    public get expand() { return this._expand; }
    public set expand(value: string | undefined) {
        this._expand = value;
        this._isChanged = true;
    }

    public get filter() { return this._filter; }
    public set filter(value: string | undefined) {
        this._filter = value;
        this._isChanged = true;
    }

    public get format() { return this._format; }
    public set format(value: string | undefined) {
        this._format = value;
        this._isChanged = true;
    }

    public get orderby() { return this._orderby; }
    public set orderby(value: string | undefined) {
        this._orderby = value;
        this._isChanged = true;
    }

    public get search() { return this._search; }
    public set search(value: string | undefined) {
        this._search = value;
        this._isChanged = true;
    }

    public get select() { return this._select; }
    public set select(value: string | undefined) {
        this._select = value;
        this._isChanged = true;
    }

    public get skip() { return this._skip; }
    public set skip(value: number | undefined) {
        this._skip = value;
        this._isChanged = true;
    }

    public get top() { return this._top; }
    public set top(value: number | undefined) {
        this._top = value;
        this._isChanged = true;
    }

    public get skipToken() { return this._skipToken; }
    public set skipToken(value: string | undefined) {
        this._skipToken = value;
        this._isChanged = true;
    }

    public toQueryParms(addAnd?: boolean): string {
        addAnd = typeof addAnd === 'undefined' ? false : addAnd;

        let qs = '';

        if (this.count !== undefined) {
            if (this.count === null) {
                qs = '$count'
            } else {
                qs = '$count=' + this.count.toString();
            }
        }

        if (this.expand !== undefined) {
            qs = this.addToQueryString(qs, '$expand=' + this.expand);
        }

        if (this.filter !== undefined) {
            qs = this.addToQueryString(qs, '$filter=' + this.filter);
        }

        if (this.format !== undefined) {
            qs = this.addToQueryString(qs, '$format=' + this.format);
        }

        if (this.orderby !== undefined) {
            qs = this.addToQueryString(qs, '$orderby=' + this.orderby);
        }

        if (this.search !== undefined) {
            qs = this.addToQueryString(qs, '$search=' + this.search);
        }

        if (this.select !== undefined) {
            qs = this.addToQueryString(qs, '$select=' + this.select);
        }

        if (this.skip !== undefined) {
            qs = this.addToQueryString(qs, '$skip=' + this.skip);
        }

        if (this.top !== undefined) {
            qs = this.addToQueryString(qs, '$top=' + this.top);
        }

        if (this.skipToken !== undefined) {
            qs = this.addToQueryString(qs, '$skipToken=' + this.skipToken);
        }

        return (addAnd ? '&' : '') + qs;
    }

    public previous() {
        if (this.skip === undefined || this.top === undefined) return;

        this.skip = this.skip - this.top;

        if (this.skip < 0) {
            this.skip = 0;
        }
    }

    public next() {
        if (this.skip === undefined || this.top === undefined) return;

        this.skip = this.skip + this.top;
    }

    private addToQueryString(queryString: string, add: string): string {
        if (queryString === undefined) return add;
        if (queryString === null) return add;
        if (queryString.trim().length === 0) return add;

        return queryString + '&' + add;
    }
}
