(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srOperationAddrService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadSrOperationAddrGet: function (custact_no) {
                return p2DataTaskService.call(1483, {
                    custact_no: custact_no 
                });
            },
            loadSrOperationAddrResourceList: function (custact_no) {
                return p2DataTaskService.call(1484, {
                    custact_no: custact_no
                });
            },
            listChecks: function (address) {
                return $ihttp.post({
                    method: 2138,
                    parameters: address || {}
                });
            },
            saveObj: function (address) {
                return $ihttp.post({
                    method: 2139,
                    parameters: address
                });
            }
        };

        return service;
    }]);
})();