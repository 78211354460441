(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("stockForecastService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 860,
                    parameters: parms || {}
                });
            },
            listResolutions: function (parms) {
                return $ihttp.post({
                    method: 857,
                    parameters: parms || {}
                });
            },
            listForecastDatas: function (parms) {
                return $ihttp.post({
                    method: 864,
                    parameters: parms || {}
                });
            },
            getHistory: function (setting) {
                return $ihttp.post({
                    method: 863,
                    parameters: setting
                });
            },
            getForecast: function (setting) {
                return $ihttp.post({
                    method: 856,
                    parameters: setting
                });
            },
            getHistoryDates: function (setting) {
                return $ihttp.post({
                    method: 862,
                    parameters: setting
                });
            },
            createUpdates: function (setting) {
                return $ihttp.post({
                    method: 861,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
