(function () {
    'use strict';

    let module = angular.module('imApp');

    module.filter('ttSearchSelectFilter', function () {
        return function (items, parms) {
            if (items.length < 1) return items;
            if (!items[0][parms.key]) return items;
            if (parms.filter.length < 1) return items;

            return items.filter(function (item) {
                return item[parms.key].indexOf(parms.filter) >= 0;
            });
        };
    });

    module.component('ttSearchSelect', {
        templateUrl: 'views/components/directives/ttSearchSelect/ttSearchSelect.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttDisabled: '<',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttData: '<',
            ttDataId: '@',
            ttDataName: '@',
            ttItemId: '@',
            ttRequired: '@',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttButtons: '<'
        },
        controller: ['$timeout', '$element', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($timeout, $element, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;
            var onDestroy = [];

            vm.keyName = '';
            vm.isDisabled = false;
            vm.required = false;
            vm.hideLabel = false;

            vm.filter = '';

            vm.buttons = [];

            vm.id = {
                select: uuid()
            };

            vm.style = {
                base: {},
                group: {},
                label: {},
                select: {
                    root: {},
                    match: {
                        span: {}
                    }
                },
                icon: {
                    color: 'white',
                    top: '0'
                }
            };

            vm.class = {
                base: '',
                label: '',
                select: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'select' }), vm.style);

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            var changedPromise = null;

            vm.onChanged = function (newSearch) {
                if (vm.filter === newSearch)
                    return;

                if (changedPromise !== null) {
                    $timeout.cancel(changedPromise);
                }

                changedPromise = $timeout(function (filter) {
                    vm.filter = filter;

                    changedPromise = null;
                }, 350, true, newSearch);
            };

            vm.onSelect = function (item, model) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: item, $modelId: null, $args: null });
                }
            };

            vm.onBtnClick = (btn) => ttDirectivesService.onButtonClick({ button: btn });

            var initData = function () {
                vm.keyName = '_' + vm.ttDataName.toLowerCase();

                if (vm.ttData.length < 1) return;
                if (vm.ttData[vm.keyName] !== undefined) return;

                vm.ttData = vm.ttData.map(e => {
                    e['_' + vm.ttDataName] = e[vm.ttDataName].toLowerCase();

                    return e;
                });
            };

            vm.$onInit = function () {
                initData();
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttData) && angular.isArray(changes.ttData.currentValue)) {
                    initData();
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttDisabled) && changes.ttDisabled.currentValue !== changes.ttDisabled.previousValue) {
                    vm.isDisabled = us.toBoolean(changes.ttDisabled.currentValue);
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttHideLabel) && angular.isDefined(changes.ttHideLabel.currentValue)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);
                }

                if (angular.isDefined(changes.ttButtons) && angular.isDefined(changes.ttButtons.currentValue) && angular.isArray(changes.ttButtons.currentValue)) {
                    angular.copy(changes.ttButtons.currentValue, vm.buttons);

                    angular.forEach(vm.buttons, function (btn) {
                        if (angular.isDefined(btn.color) && angular.isString(btn.color)) {
                            btn.class = 'tt-input__button--' + btn.color;
                        } else {
                            if (angular.isUndefined(btn.class)) {
                                btn.class = 'tt-input__button--primary';
                            }
                        }
                    });
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;
                vm.style.group.height = info.height + 'px';

                vm.style.select.root.fontSize = info.fontSizes.textSize;
                vm.style.select.root.height = info.height + 'px';
                vm.style.select.match.span.fontSize = info.fontSizes.textSize;
                vm.style.select.match.span.height = info.height + 'px';
                vm.style.select.match.span.padding = "5px 16px";

                vm.style.icon.fontSize = info.fontSizes.textSize;

                if (vm.buttons.length > 0) {
                    vm.class.select += ' input-group';
                }

                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop)
            });

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
