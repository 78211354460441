(function() {
    'use strict';

    angular.module("imApp").factory("forecastDataGridService", ['$ihttp', function ($ihttp) {
        var service = {
            getForecastDataGrid: function () {
                return $ihttp.post({
                    method: 3448,
                    parameters: {}
                });
            },
            listResolutions: function () {
                return $ihttp.post({
                    method: 857,
                    parameters: {}
                });
            },
            getHistoryPst: function (setting) {
                return $ihttp.post({
                    method: 3447,
                    parameters: setting
                });
            },
        }

        return service;
    }]);
})();
