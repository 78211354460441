(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('stockControlLocList', {
        templateUrl: 'views/components/views/stockControlLocList/stockControlLocList.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$element', 'stateService', 'utilityService', 'stockControlLocListService', function($stateParams, $timeout, $element, stateService, utilityService, stockControlLocListService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                mId: 'stockControlLocListCtrl',
                stockLocationKeyNo: $stateParams.stocklocation_keyno,
                barcodeBtnList: [
                    { item_id: '0', item_name: 'scan', item_func: 'scan_item', glyph: 'glyphicon-barcode', color: 'primary' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' },
                ],
                settingLocalControl: {},
                itemsListLocalControls: [],
                searchingBarcode: false,
                lockedApprove: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            stockControlLocListService.loadStockControlLocListGet(vm.model.stockLocationKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.settingLocalControl);
    
                loadStockControlLocLists();
            });
    
            var loadStockControlLocLists = function () {
                if (utilityService.validateParmsValue(vm.model.settingLocalControl.stocklocation_keyno) !== true) return;
    
                vm.model.itemsListLocalControls = [];
    
                stockControlLocListService.loadStockControlLocListList(vm.model.settingLocalControl.stocklocation_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListLocalControls);
                });
            };
    
            var loadScan = function () {
                if (utilityService.validateParmsValue(vm.model.settingLocalControl.stocklocation_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingLocalControl.barcode) !== true) return;
    
                stockControlLocListService.scan(vm.model.settingLocalControl.stocklocation_keyno, vm.model.settingLocalControl.barcode).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.searchingBarcode = false;
    
                            clearField();
                        });
                    } else {
                        vm.model.searchingBarcode = false;
                        clearField();
                        vm.model.settingLocalControl.stock_keyno = data[0].stock_keyno;
                        
                        if (angular.isDefined(vm.model.settingLocalControl.stock_keyno)) {
                            vm.goTo('stockcontrollocstock');
                        }
                    }
                });
            };
    
            // ## CLEAR FIELD FUNCTION ##
    
            var clearField = function () {
                if (vm.model.settingLocalControl.barcode !== '' && vm.model.searchingBarcode === false) {
                    vm.model.settingLocalControl.barcode = '';
                }
            };
    
            // ## MOCHASOFT SCAN FUNCTION ##
    
            var launchBarcode = function () {
                var wait = function () {
                    var val = $element.find('#' + vm.model.mId)[0].value;
    
                    if (angular.isString(val) && val !== null && val.length > 0) {
                        vm.model.settingLocalControl.barcode = val;
    
                        loadScan();
    
                        $element.find('#' + vm.model.mId)[0].value = '';
                        return;
                    }
    
                    $timeout(wait, 250);
                };
    
                wait();
    
                window.location = "readbarcode://" + vm.model.mId;
            };
    
            // ## ENTER & SEARCH FUNCTION ##
    
            vm.scanBarcodes = function () {
                vm.model.searchingBarcode = true;
    
                loadScan();
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.barcodeBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'scan_item':
                        launchBarcode();
                        break;
                    case 'search_item':
                        vm.scanBarcodes();
                    	break;
                    default:
                        break;
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'stockcontrollocstock':
                        go({
                            stocklocation_keyno: vm.model.settingLocalControl.stocklocation_keyno,
                            stock_keyno: vm.model.settingLocalControl.stock_keyno
                        });
                        break;
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.approveStockControlLocList = function () {
                vm.model.lockedApprove = true;
                
                stockControlLocListService.approve(vm.model.settingLocalControl.stocklocation_keyno).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedApprove = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedApprove = false;
                    }
                });
            };
        }]
    });
})();