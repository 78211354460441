<div class="col-xs-12 sp-0" style="margin-bottom: 7px">
    <div class="tt-tabs sp-0">
        <ng-container *ngFor="let tab of ttTabs">
            <button *ngIf="tab.disabled !== 'hidden'" class="tt-tabs__button" (click)="onClick(tab)" [ngClass]="{ 'tt-tabs__button--active': ttModel === tab.id }" [ngStyle]="style['tab']">
                <span *ngIf="tab.icon" class="fa-fw" [ngClass]="tab.icon"></span>
                <span class="tt-tabs__text">{{ tab.translation }}</span>
            </button>
        </ng-container>
    </div>
</div>
<!-- <div class="col-xs-12 sp-0 " style="margin-bottom: 7px;" ng-style="vm.ttStyle" tt-item="{{vm.ttItemId}}">
    <div class="col-xs-12 sp-0">
        <label class="name-label sp-0" ng-class="vm.class.label" ng-if="vm.showLabel" ng-style="vm.style.label">{{vm.translations.ttLabel}}</label>

        <div class="tt-tabs sp-0" ng-class="vm.class.input">
            <button class="tt-tabs__button" ng-repeat="btn in vm.ttButtons" ng-if="btn.disabled !== 'hidden'" ng-model="vm.selected" ng-change="vm.onChange(vm.selected)" ng-click="vm.onButtonClick(btn)" uib-btn-radio="'{{btn.id}}'" ng-class="{ 'tt-tabs__button--active': vm.selected === btn.id }" ng-style="{ 'font-size': vm.style.button.fontSize }">
                <span ng-style="{ 'font-size': vm.style.button.fontSize }" ng-if="btn.icon !== undefined && btn.icon !== null" class="fa-fw" ng-class="btn.icon"></span> <span class="tt-tabs__text" ng-style="{ 'font-size': vm.style.button.fontSize }">{{btn.translation}}</span>
            </button>
        </div>
    </div>
</div> -->
