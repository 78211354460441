import { ICellEditorAngularComp } from '@ag-grid-community/angular';
import { ICellEditorParams } from '@ag-grid-community/core';
import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { ComponentBaseComponent } from '@app/core/components/component-base/component-base.component';

export interface NumberCellEditorParams {
    /**
     * How many decimals the number should contain.
     */
    decimals: number;

    /**
     * How many significant digits the number can contain.
     */
    length: number;

    /**
     * Whether to format the number or not.
     */
    formatNumber: boolean;

    /**
     * Whether the number should be always positive or not.
     */
    alwaysPositive?: boolean;

    /**
     * Whether to allow a number to be empty (null, but shown as empty string).
     */
    allowEmpty?: boolean;
}

@Component({
    selector: 'tt-number-cell-editor',
    templateUrl: './number-cell-editor.component.html',
    styleUrls: ['./number-cell-editor.component.css'],
})
export class NumberCellEditorComponent extends ComponentBaseComponent implements ICellEditorAngularComp, AfterViewInit {
    /**
     * Refernce to the input html input element.
     */
    @ViewChild('inputRef')
    public inputRef?: ElementRef;

    /**
     * Cell editor parameters passed from the cell.
     */
    public params!: ICellEditorParams & NumberCellEditorParams;

    /**
     * Whether the value in the cell has been edited since editing started.
     */
    public hasBeenEdited: boolean = false;

    /**
     * The number value.
     */
    public value: number | null = null;

    /**
     * The formatted number value.
     */
    public _viewValue: string = '';

    @HostListener('keydown', ['$event'])
    public keydown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            this.params?.api.stopEditing();
            this.params?.api.setFocusedCell(this.params.rowIndex, this.params.colDef.field!);
        }
    }

    public onModelChanged(event?: string) {
        this.hasBeenEdited = true;
        const caretPosition = this.inputRef?.nativeElement?.selectionStart ?? 0;
        let value = this.parseInputToValidNumberString(event ?? '');

        if (value === '' && this.params.allowEmpty !== false) {
            setTimeout(() => {
                this._viewValue = value;
                this.value = null;
            });
            return;
        }

        if (value === '-') {
            setTimeout(() => (this._viewValue = value));
            return;
        }

        if (this.params.formatNumber) {
            const existingDecimalsLength = value.split('.')?.[1]?.length ?? 0;
            value = this.formatInput(value, value.includes('.') ? existingDecimalsLength : 0);
        } else {
            value = value.replace('.', ',');
        }

        const difference = value.length - (event?.length ?? 0);
        let caretPositionAfter = difference === 0 ? caretPosition : caretPosition ? caretPosition + difference : 0;

        setTimeout(() => {
            this._viewValue = value;
            this.isInputFocused() && setTimeout(() => this.setCaretPosition(caretPositionAfter));
            this.value = Number(Number(this.parseInputToValidNumberString(value ?? '')));
        });
    }

    private parseInputToValidNumberString(input: string): string {
        let validCharacters = new RegExp(/[^0-9.,-]/g);

        let number = `${input}`
            .replaceAll(/\,/g, '.')
            .replaceAll(/−/g, '-')
            .replaceAll(/—/g, '-')
            .replaceAll(' ', '')
            .replace(validCharacters, '')
            .replace(/(?!^)-/g, '')
            .replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, ''));

        if (number?.trim() === '' && this.params.allowEmpty !== false) {
            return '';
        } else if (number?.trim() === '') {
            return '0';
        }

        return number;
    }

    private formatInput(input: string, minimumFractionDigits: number = 0): string {
        const formatter = Intl.NumberFormat('nb', {
            maximumSignificantDigits: this.params.length,
            minimumFractionDigits: Math.min(Math.abs(minimumFractionDigits), Math.abs(this.params.decimals)),
            maximumFractionDigits: Math.abs(this.params.decimals),
        });

        const indexOfFirstComma = input.indexOf('.');

        if (indexOfFirstComma !== -1 && indexOfFirstComma !== input.length - 1) {
            const beforeComma = input.substring(0, indexOfFirstComma);
            const afterComma = input.substring(indexOfFirstComma).replaceAll('.', '');

            input = formatter.format(Number(beforeComma)) + ',' + afterComma;
        } else if (indexOfFirstComma !== -1) {
            input = formatter.format(Number(input.replaceAll('.', ''))) + ',';
        } else {
            input = formatter.format(Number(input));
        }

        return input;
    }

    private formatViewValue(value?: string) {
        const number = this.parseInputToValidNumberString(value ?? this._viewValue ?? '');

        if (number === '' && this.params.allowEmpty !== false) {
            setTimeout(() => (this._viewValue = number));
        }

        if (number === '-') {
            setTimeout(() => (this._viewValue = number));
            return;
        }

        if (this.params.formatNumber) {
            this._viewValue = this.formatInput(number, this.params.decimals);
        } else {
            this._viewValue = this.roundDecimals(number).replace('.', ',');
        }
    }

    private roundDecimals(input: string): string {
        let multiplier = Math.pow(10, Math.abs(this.params.decimals));
        return (Math.round((Number(input) + Number.EPSILON) * multiplier) / multiplier).toFixed(Math.abs(this.params.decimals));
    }

    private setCaretPosition(caretPosition: number | undefined): void {
        if (this.inputRef?.nativeElement && caretPosition) {
            this.inputRef.nativeElement.focus();
            this.inputRef.nativeElement.setSelectionRange(caretPosition, caretPosition);
        }
    }

    private isInputFocused() {
        return document?.activeElement === this.inputRef?.nativeElement;
    }

    agInit(params: ICellEditorParams & NumberCellEditorParams): void {
        this.params = params;
        this.value = !!this.params.data[this.params.column.getColId()] ? Number(this.params.data[this.params.column.getColId()]) : null;
        this.formatViewValue(this.value?.toString());
    }

    getValue() {
        if (this.value === null) {
            return null;
        }
        this.value = Number(this.roundDecimals('' + this.value || '0'));
        return this.value;
    }

    ngAfterViewInit() {
        // this.inputRef?.nativeElement.select();
        window.setTimeout(() => {
            if (!!this.value) {
                this.inputRef?.nativeElement.select();
            } else {
                this.inputRef?.nativeElement.focus();
            }
        });
    }
}
