(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockCountService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2WStockCountGet: function (stockplace_id, stockcount_keyno) {
				return p2DataTaskService.call(953, {
                    stockplace_id: stockplace_id,
                    stockcount_keyno: stockcount_keyno
				});
			},
            saveP2WStockCount: function (p2WStockCountGet) {
                return p2DataTaskService.call(954, p2WStockCountGet);
			}
        };

        return service;
    }]);
})();