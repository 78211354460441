(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('choicePossRule', {
        templateUrl: 'views/components/views/choicePossRule/choicePossRule.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'choicePossRuleService', function ($stateParams, stateService, modalService, choicePossRuleService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;

            vm.model = {
                productIdButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoProduct }
                ],
                modelButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearModel },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoModels }
                ],
                modelButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearModel },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoModels },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoModelMenu }
                ],
                productNameSelectButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.clearProduct() },
                ],
                fabricsButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearFabric },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoFabrics }
                ],
                fabricsButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearFabric },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoFabrics },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoFabricMenu }
                ],
                choosedProductButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearChoosedProduct },
                ],
                choosedProductButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearChoosedProduct },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoChoosedProduct }
                ],
                choicePossRuleKeyno: $stateParams.choicepossrule_keyno,
                getChoicePossRule: {},
                listProductChoice: [],
                listModelSelect: [],
                listFurnitureFabricsSelect: [],
                listChoicePossRulesChoosed: [],
                listChoicePossRulesProduct: [],
                locked: false
            };

            // ## LOAD PROCEDURE CALLS ##

            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##

            vm.typeaheadSearch = function () {
                return choicePossRuleService.search(vm.model.getChoicePossRule.prod_name, stateService.getCurrentName(), '0', '0', '0', '0', '0', '0', vm.model.getChoicePossRule.model_id);
            };

            // ## SAVE BUTTON FUNCTION ##

            vm.saveChoicePossRule = function () {
                vm.model.locked = true;

                choicePossRuleService.save(vm.model.getChoicePossRule).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.locked = false;
                    }
                });
            };

            // ## DELETE BUTTON FUNCTION ##

            vm.deleteChoicePossRule = function () {
                choicePossRuleService.delete(vm.model.getChoicePossRule).then(function (data) {
                    stateService.back();
                });
            };

            // ## formfield button functions

            function clearModel() {
                vm.model.getChoicePossRule.model_id = 'ALL';
            }

            function gotoModels() {
                stateService.go('models', {});
            }

            function gotoModelMenu() {
                stateService.go('modelmenu', {
                    model_id: vm.model.getChoicePossRule.model_id
                });
            }

            function gotoProduct() {
                if (vm.model.getChoicePossRule.prod_id) {
                    choicePossRuleService.loadProdKeynoGet(vm.model.getChoicePossRule.prod_id).then((data) => {
                        if (data?.[0].prod_keyno) stateService.go('product', { prod_id: data[0].prod_keyno });
                    });
                }
            }

            function clearFabric() {
                vm.model.getChoicePossRule.furniturefabrics_id = 'ALL';
                vm.model.getChoicePossRule.choosed_prod_id = '';
            }

            function gotoFabrics() {
                stateService.go('furniturefabricss', {});
            }

            function gotoFabricMenu() {
                stateService.go('furniturefabrics', {
                    furniturefabrics_id: vm.model.getChoicePossRule.furniturefabrics_id
                });
            }

            function clearChoosedProduct() {
                vm.model.getChoicePossRule.choosed_prod_id = '';
            }

            function gotoChoosedProduct() {
                if (vm.model.getChoicePossRule.choosed_prod_id?.trim()) {
                    choicePossRuleService.loadProdKeynoGet(vm.model.getChoicePossRule.choosed_prod_id).then((data) => {
                        if (angular.isDefined(data[0].prod_keyno)) {
                            stateService.go('product', {
                                prod_id: data[0].prod_keyno
                            });
                        }
                    });
                }
            }

            // ## Directives change functions

            vm.onProductNameSelected = function (value) {
                vm.model.getChoicePossRule.prod_id = value;

                if (value) {
                    let productItem = vm.model.listChoicePossRulesProduct.find((item) => item?.item_id === vm.model.getChoicePossRule.prod_id);

                    if (productItem?.item_name) vm.model.getChoicePossRule.prod_name = productItem.item_name;
                }
            }

            vm.onSearchProductNameSelected = function (item) {
                vm.model.getChoicePossRule.prod_id = item?.item_id;
                vm.model.getChoicePossRule.prod_name = item?.item_name;
            }

            vm.onProductNameChanged = function (value) {
                vm.model.getChoicePossRule.prod_name = value;

                if (value) {
                    let productItem = vm.model.listChoicePossRulesProduct.find((item) => item?.item_name === vm.model.getChoicePossRule.prod_name);

                    if (productItem?.item_id) vm.model.getChoicePossRule.prod_id = productItem.item_id;
                } else {
                    vm.clearProduct();
                }
            }

            vm.clearProduct = function () {
                vm.model.getChoicePossRule.prod_name = '';
                vm.model.getChoicePossRule.prod_id = '';
            }

            vm.onModelChanged = function (value) {
                vm.model.getChoicePossRule.model_id = value;

                if (value) {
                    loadModelId();
                    vm.clearProduct();
                }
            }

            vm.onChoiceProductChanged = async function (value) {
                vm.model.getChoicePossRule.choice_prod_id = value;

                if (value) {
                    vm.model.getChoicePossRule.furniturefabrics_id = 'ALL';
                    vm.model.getChoicePossRule.choosed_prod_id = '';

                    await loadProdIdChoosed();
                    await loadChoiceProdId();
                    loadSelectList();
                }
            }

            vm.onFurnitureFabricsChanged = function (value) {
                vm.model.getChoicePossRule.furniturefabrics_id = value;

                if (value) {
                    loadProdIdChoosed();

                    if (vm.model.getChoicePossRule.furniturefabrics_id === 'ALL') {
                        vm.model.getChoicePossRule.choosed_prod_id = '';
                    }
                }
            }

            async function loadProductList() {
                if (vm.model.getChoicePossRule.model_id?.trim() && vm.model.getChoicePossRule.nbr_of_products < 200) {
                    return vm.model.listChoicePossRulesProduct = await choicePossRuleService.loadChoicePossRulesProductList(vm.model.getChoicePossRule);
                }
            };

            async function loadChoiceProdId() {
                if (vm.model.getChoicePossRule.choice_prod_id?.trim()) {
                    return vm.model.getChoicePossRule.nbr_of_furniturefabrics = (await choicePossRuleService.loadChoiceProdIdChanged(vm.model.getChoicePossRule.choice_prod_id))[0].nbr_of_furniturefabrics;
                }
            };

            async function loadSelectList() {
                if (vm.model.getChoicePossRule.choice_prod_id?.trim() && vm.model.getChoicePossRule.nbr_of_furniturefabrics > 0) {
                    return vm.model.listFurnitureFabricsSelect = await choicePossRuleService.loadFurnitureFabricsSelectList('1', vm.model.getChoicePossRule.choice_prod_id);
                }
            };

            async function loadModelId() {
                if (vm.model.getChoicePossRule.model_id?.trim()) {
                    vm.model.getChoicePossRule.nbr_of_products = (await choicePossRuleService.loadModelIdChanged(vm.model.getChoicePossRule))[0].nbr_of_products;
                    return loadProductList();
                }
            };

            async function loadProdIdChoosed() {
                if (vm.model.getChoicePossRule.choice_prod_id && vm.model.getChoicePossRule.furniturefabrics_id) {
                    return vm.model.listChoicePossRulesChoosed = await choicePossRuleService.loadChoicePossRulesChoosedList(vm.model.getChoicePossRule);
                }
            };

            async function loadChoicePossRuleGet() {
                return vm.model.getChoicePossRule = (await choicePossRuleService.loadChoicePossRuleGet(vm.model.choicePossRuleKeyno))[0];
            }

            async function loadProductChoiceList() {
                vm.model.listProductChoice = await choicePossRuleService.loadProductChoiceList();
            }

            async function loadModelSelectList() {
                vm.model.listModelSelect = await choicePossRuleService.loadModelSelectList('1', '0');
            }

            // ## Angular lifecycle functions.

            vm.$onInit = async function () {
                loadProductChoiceList();
                loadModelSelectList();
                await loadChoicePossRuleGet();

                loadModelId();
                loadProdIdChoosed()
                await loadChoiceProdId()
                loadSelectList();
            }
        }]
    });
})();
