import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormFieldBaseComponent } from '@app/core/components/form-field-base/form-field-base.component';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormButton } from '../form-field-button/form-field-button.component';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SearchItem } from '../search/search.component';
import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ListboxPopupDirective, ListboxSelectEvent } from '@app/core/directives/listbox-popup/listbox-popup.directive';
import { DATE_FORMATS } from '@app/core/models/date-adapter';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-datetime',
    templateUrl: './datetime.component.html',
    styleUrls: ['./datetime.component.css'],
})
export class DatetimeComponent extends FormFieldBaseComponent implements OnInit, AfterViewInit, FormFieldButtons {
    /**
     * A date string of the format `YYYY-MM-DD HH:MM`.
     */
    @Input()
    ttModel: string = '2024-02-27 08:10';

    /**
     * Object representing the date given in ttModel, internal use within the component.
     */
    _model: { date: null | Date; time: string } = {
        date: new Date(),
        time: '12:00',
    };

    /**
     * Emits event with a string representation of the new date given in the event if the format `YYYY-MM-DD HH:MM`.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * The minute precision of the time input, default is `5`.
     */
    @Input()
    set ttMinutePrecision(value: NumberInput) {
        this._minutePrecision = coerceNumberProperty(value);
    }
    get ttMinutePrecision(): number {
        return this._minutePrecision;
    }
    _minutePrecision = 5;

    /**
     * Whether to round the time the user inputed. If true the time is rounded to closest minute precision, if `'up'` the time
     * is rounded to the next minute precision. If `'down'` the time is always rounded down the previous minute-precision.
     */
    @Input()
    set ttRoundTime(value: BooleanInput | 'up' | 'down') {
        if (value === 'up') {
            this._roundTime = 'up';
        } else if (value === 'down') {
            this._roundTime = 'down';
        } else {
            this._roundTime = coerceBooleanProperty(value);
        }
    }
    get ttRoundTime(): boolean | 'up' | 'down' {
        return this._roundTime;
    }
    _roundTime: boolean | 'up' | 'down' = true;

    /**
     * Whether to allow a date to be empty (empty string).
     */
    @Input()
    set ttAllowEmptyDate(value: BooleanInput) {
        this._allowEmptyDate = coerceBooleanProperty(value);
    }
    get ttAllowEmptyDate(): boolean {
        return this._allowEmptyDate;
    }
    _allowEmptyDate = true;

    /**
     * Whether to hide the date-input or not.
     */
    @Input()
    set ttHideDate(value: BooleanInput) {
        this._hideDate = coerceBooleanProperty(value);
    }
    get ttHideDate(): boolean {
        return this._hideDate;
    }
    _hideDate = false;

    /**
     * Whether to hide the time-input or not.
     */
    @Input()
    set ttHideTime(value: BooleanInput) {
        this._hideTime = coerceBooleanProperty(value);
    }
    get ttHideTime(): boolean {
        return this._hideTime;
    }
    _hideTime = false;

    @Input()
    public override style: Style = {
        date: {},
        hour: {},
        minute: {},
    };

    override translations: { [key: string]: string } = {
        placholder: DATE_FORMATS.display.dateInput.format,
    };

    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;

    @Input()
    ttButtons?: FormButton[] | undefined;

    /**
     * Reference to the date picker component.
     */
    @ViewChild(MatDatepicker) picker?: MatDatepicker<Date>;

    /**
     * Reference to input field responsible for the date input.
     */
    @ViewChild('dateRef') dateRef?: ElementRef;

    /**
     * Reference to the input field responsible for the time input.
     */
    @ViewChild('timeRef') timeRef?: ElementRef;

    /**
     * Reference to the listbox directive.
     */
    @ViewChild('timeListbox') timeListbox?: ListboxPopupDirective;

    /**
     * Ids of elements in the component.
     */
    id = {
        dateInput: crypto.randomUUID(),
        timeGroup: crypto.randomUUID(),
        timeInput: crypto.randomUUID(),
    };

    today = new Date();

    restoreFocus = true;

    dateOptions = {
        minute: {
            interval: 120,
        },
    };

    timeOptions: SearchItem[] = [];

    showTimes = false;

    constructor(layoutService: LayoutService, coreComponentService: CoreComponentService, translateService: TranslateService) {
        super(layoutService, coreComponentService, translateService);
        this.layoutService.layoutChanged.subscribe((info) => {
            if (!!info) {
                this.coreComponentService.setLayoutStyle(this.style, info);
            }
        });
    }

    /**
     * Opens the date picker and focuses the date input.
     */
    openDatepicker() {
        this.picker?.open();
        setTimeout(() => {
            this.dateRef?.nativeElement.focus();
            this.dateRef?.nativeElement.select();
        }, 50);
    }

    /**
     * Opens the listbox with time intervals and focuses the time input.
     */
    openTimeListbox() {
        if (!this.showTimes) {
            this.timeRef?.nativeElement.focus();
            this.timeRef?.nativeElement.select();
            this.showTimes = true;
        }
    }

    /**
     * Handles date changed from the date picker.
     *
     * @param event material datepicker inpute event.
     */
    onDatePickerChanged(event: MatDatepickerInputEvent<any>) {
        if (event.value instanceof Date && event.value.toString() !== 'Invalid Date') {
            var tzo = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

            // @ts-ignore
            this._model.date = new Date(event.value - tzo);
            this.onModelChange();
        } else if (!event.value && this.ttAllowEmptyDate) {
            this._model.date = null;
            this.onModelChange();
        }
    }

    /**
     * Closes the date picker when the input field is blurred.
     */
    onDateInputBlur() {
        this.restoreFocus = false;
        setTimeout(() => this.picker?.close(), 100);
        if (!this._model.date && !this.ttAllowEmptyDate) this._model.date = new Date(this.ttModel);
        if (!this._model.date && this._allowEmptyDate) this._model.date = null;
    }

    /**
     * Parses input changes from time input.
     *
     * @param event value from the change event.
     */
    onTimeChanged(event: string) {
        setTimeout(() => {
            this._model.time = event.replace(/[^0-9:]/g, '').substring(0, 5);
        });
    }

    onTimeInputKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.onTimeInputBlur();
        }
    }

    /**
     * Handles time changes.
     *
     * @param option the time option selected.
     */
    onTimeOptionSelect(event: ListboxSelectEvent<SearchItem>) {
        if (this.isValidTimeFormat(event.item['item_id'])) {
            this._model.time = event.item['item_id'];
        } else {
            this._model.time = this.ttModel.split(' ')[1] ?? '08:00';
        }

        this.onModelChange();
        this.showTimes = false;
    }

    /**
     * Parses the inputed value to a valid time format when the input field is blurred.
     */
    onTimeInputBlur() {
        if (this._model.time === this.ttModel.split(' ')[1]) return;

        if (this.isValidTimeFormat(this._model.time)) {
            let hour = 8;
            let minute = 0;

            if (this._model.time.includes(':')) {
                hour = Number(this._model.time.split(':')[0]);
                minute = Number(this._model.time.split(':')[1]);
            } else if (this._model.time.length > 0 && this._model.time.length < 3) {
                hour = Number(this._model.time);
            } else if (this._model.time.length === 3) {
                hour = Number(this._model.time.substring(0, 1));
                minute = Number(this._model.time.substring(1, 3));
            } else if (this._model.time.length === 4) {
                hour = Number(this._model.time.substring(0, 2));
                minute = Number(this._model.time.substring(2));
            }

            this.setHourAndMinuteToTime(hour, minute);
            this.onModelChange();
        } else {
            this._model.time = this.ttModel.split(' ')[1] ?? '08:00';
        }
    }

    /**
     * Takes the raw values of hour and minute and changes it according to minute precision and rounding.
     *
     * @param hour the raw hour value the user inputted.
     * @param minute the raw minute value the user inputted.
     */
    private setHourAndMinuteToTime(hour: number, minute: number): void {
        const remainder = minute % this.ttMinutePrecision;

        if (this.ttRoundTime === true) {
            if (remainder !== 0) {
                const roundingUp = this.ttMinutePrecision - remainder;
                const roundingDown = -remainder;

                if (remainder >= this.ttMinutePrecision / 2) {
                    minute += roundingUp;
                } else {
                    minute += roundingDown;
                }
            }
        } else if (this.ttRoundTime === 'up') {
            if (remainder !== 0) {
                minute += this.ttMinutePrecision - remainder;
            }
        } else if (this.ttRoundTime === 'down') {
            minute -= remainder;
        }

        if (minute >= 60) {
            hour += 1;
            minute -= 60;
        } else if (minute < 0) {
            hour -= 1;
            minute += 60;
        }

        if (hour >= 24) {
            hour -= 24;
        } else if (hour < 0) {
            hour += 24;
        }

        this._model.time = `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`;
    }

    /**
     * Handles changes in the date values, puts together a date string based on the `_model` object, and emits the `ttModelChange` event with the new date-string.
     *
     * @emits `(ttModelChange)` event emitted with the new date string.
     */
    onModelChange() {
        if (this.ttDisabled || this.ttReadonly) return;

        if (!this.ttHideDate && !this.ttHideTime) {
            if (this._model.date && this.isValidTimeFormat(this._model.time)) {
                const dateString = this._model.date.toISOString().substring(0, 10) + ' ' + this._model.time;
                if (dateString !== this.ttModel) {
                    this.ttModelChange.emit(dateString);
                }
            } else if (!this._model.date) {
                this.ttModelChange.emit('');
            }
        } else if (this.ttHideDate && !this.ttHideTime) {
            if (this._model.time) {
                this.ttModelChange.emit(this._model.time);
            } else {
                this.ttModelChange.emit('');
            }
        } else if (!this.ttHideDate && this.ttHideTime) {
            if (this._model.date) {
                this.ttModelChange.emit(this._model.date.toISOString().substring(0, 10));
            } else {
                this.ttModelChange.emit('');
            }
        }
    }

    /**
     * Checks whether the given value is of a valid time format.
     *
     * @param value the value to check if is a valid timeformat.
     * @returns `true` if the given value is a valid time format, `false` if not.
     */
    private isValidTimeFormat(value: string): boolean {
        if (value.includes(':') && value.length > 2 && this.isValidHourValue(value.split(':')[0]) && this.isValidMinuteValue(value.split(':')[1])) {
            return true;
        } else if (value.length > 0 && value.length < 3 && this.isValidHourValue(value)) {
            return true;
        } else if (value.length === 3 && this.isValidHourValue(value.substring(0, 1)) && this.isValidMinuteValue(value.substring(1))) {
            return true;
        } else if (value.length === 4 && this.isValidHourValue(value.substring(0, 2)) && this.isValidMinuteValue(value.substring(3))) {
            return true;
        }

        return false;
    }

    /**
     * Checks wheter the given hour is of a valid hour value.
     *
     * @param hour the hour value to check if is a valid hour value.
     * @returns `true` if the given value is a valid hour value, `false` if not.
     */
    private isValidHourValue(hour: string | number): boolean {
        let hourValue = Number(hour);

        if (!isNaN(hourValue) && hourValue >= 0 && hourValue < 24) {
            return true;
        }
        return false;
    }

    /**
     * Checks whether the given minute if of a valid minute value.
     *
     * @param minute the minute value to check if is a valid minute value.
     * @returns `true` if the given value is a valid minute value, `false` if not.
     */
    private isValidMinuteValue(minute: string | number): boolean {
        let minuteValue = Number(minute);

        if (!isNaN(minuteValue) && minuteValue >= 0 && minuteValue < 60) {
            return true;
        }
        return false;
    }

    /**
     * Focuses the date picker on arrow key down event.
     *
     * @param event the key event.
     */
    onDateInputKeydown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();

            if (this.picker?.id) {
                this.picker.open();
                (document.getElementById(this.picker?.id)?.querySelector('.mat-calendar-body-active') as HTMLButtonElement)?.focus();
            }
        } else if (event.key === 'Tab') {
            this.onDateInputBlur();
        } else {
            this.picker?.close();
        }
    }

    /**
     * Creates and sets the `timeOptions` list with times spaced out by the given minute interval.
     */
    private createTimeOptions(minutePrecision: number = 15) {
        this.timeOptions = [];

        for (let hour = 0; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += minutePrecision) {
                this.timeOptions.push({
                    item_name: `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`,
                    item_id: `${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`,
                });
            }
        }
    }

    override ngOnInit(): void {
        this.createTimeOptions(this.ttMinutePrecision);
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.['ttHideDate']) {
            this.ttHideDate = changes['ttHideDate'].currentValue;
        }

        if (changes?.['ttHideTime']) {
            this.ttHideTime = changes['ttHideTime'].currentValue;
        }

        if (changes?.['ttMinutePrecision']) {
            this.ttMinutePrecision = changes['ttMinutePrecision'].currentValue;
        }

        if (changes?.['ttRoundTime']) {
            this.ttRoundTime = changes['ttRoundTime'].currentValue;
        }

        if (changes?.['ttAllowEmptyDate']) {
            this.ttAllowEmptyDate = changes['ttAllowEmptyDate'].currentValue;
        }

        if (changes?.['ttModel']) {
            const dateValue = this.ttHideDate ? null : this.ttModel.split(' ')[0];
            const timeValue = this.ttHideTime ? null : this.ttHideDate ? this.ttModel : this.ttModel.split(' ')[1];

            if (dateValue && new Date(dateValue).toString() !== 'Invalid Date') {
                this._model.date = new Date(dateValue);
            }

            if (timeValue && this.isValidTimeFormat(timeValue)) {
                this._model.time = timeValue;
            }
        }
    }

    ngAfterViewInit(): void {
        this.picker?.closedStream.subscribe(() => (this.restoreFocus = true));
    }
}
