(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('subscriptionLine', {
        templateUrl: 'views/components/views/subscriptionLine/subscriptionLine.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'subscriptionLineService', 'economyService', 'typeaheadService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, subscriptionLineService, economyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let subscriptionKeyno = $stateParams.subscription_keyno;
            let subscriptionLineKeyno = $stateParams.subscriptionline_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                productButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_id }) }
                ],
                priceIndexButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('priceindexes') }
                ],
                priceIndexButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('priceindexes') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('priceindexmenu', { priceindex_keyno: vm.model.edit.priceindex_keyno }) }
                ],
                edit: {},
                selectListTaxes: [],
                selectListPriceIndexes: [],
                selectListAdjustedQuantities: [],
                selectListAdjustedPrices: [],
                selectListDataIndexes: [],
                selectListPrices: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                subscriptionLineService.getSetting({
                    subscription_keyno: subscriptionKeyno,
                    subscriptionline_keyno: subscriptionLineKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadDataIndexes()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadTaxes = function () {
                economyService.listTaxes().then(function (result) {
                    angular.copy(result, vm.model.selectListTaxes);
                });
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (result) {
                    angular.copy(result, vm.model.selectListPrices);
                });
            };

            let loadPriceIndexes = function () {
                subscriptionLineService.listPriceIndexes().then(function (result) {
                    angular.copy(result, vm.model.selectListPriceIndexes);
                });
            };

            let loadAdjustedQuantities = function () {
                subscriptionLineService.listAdjustedQuantities().then(function (result) {
                    angular.copy(result, vm.model.selectListAdjustedQuantities);
                });
            };

            let loadAdjustedPrices = function () {
                subscriptionLineService.listAdjustedPrices().then(function (result) {
                    angular.copy(result, vm.model.selectListAdjustedPrices);
                });
            };

            let loadDataIndexes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.priceindex_keyno) !== true) return;

                subscriptionLineService.listDataIndexes({ priceindex_keyno: vm.model.edit.priceindex_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListDataIndexes);
                });
            };

            async function recalcChanges(colname) {
                const response = (await subscriptionLineService.recalcColumn({ ...vm.model.edit, colname_changed: colname }))[0];

                vm.model.edit = { ...vm.model.edit, ...response };
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchProduct({
                            prod_name: vm.model.edit[id] || ''
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'prod_name':
                        if (item) {
                            vm.model.edit.prod_id = item?.item_id ?? '';
                            vm.model.edit.prod_name = item?.item_name ?? '';

                            recalcChanges('prod_id');
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit.prod_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.edit.prod_id = '';
                        vm.model.edit.prod_name = '';

                        recalcChanges('prod_id');
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'priceadjust_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.priceadjust_id) !== true) return;

                        vm.model.edit.priceadjust_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.priceadjust_id) !== true) return;

                        vm.model.edit.priceindex_keyno = '0';
                        break;
                    case 'priceindex_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.priceindex_keyno) !== true) return;

                        vm.model.edit.priceindex_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.priceindex_keyno) !== true) return;

                        vm.model.edit.priceindexdata_keyno_basis = '0';
                        loadDataIndexes();
                        recalcChanges('priceindex_keyno');
                        break;
                    case 'priceindexdata_keyno_basis':
                        if (utilityService.validateWatchValue(value, vm.model.edit.priceindexdata_keyno_basis) !== true) return;

                        vm.model.edit.priceindexdata_keyno_basis = value;

                        if (utilityService.validateParmsValue(vm.model.edit.priceindexdata_keyno_basis) !== true) return;

                        recalcChanges('priceindexdata_keyno_basis');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'quantity':
                    case 'price_invval_basis':
                    case 'price_invval':
                    case 'discount_pst':
                    case 'amount_invval':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        recalcChanges(id);
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                subscriptionLineService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
                loadTaxes();
                loadPriceIndexes();
                loadAdjustedQuantities();
                loadAdjustedPrices();
                loadPrices();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
