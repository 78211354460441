(function () {
    'use strict';

    angular.module('imApp').factory('ttDirectivesService', ['utilityService', function (utilityService) {
        // Service for common code between tt directives. - JLR 20230629

        let service = {};

        service.ttifyObject = function (object) {
            let newObject = {};

            for (const property in object) {
                let ttifiedProperty = property.replace(/(_\w)/g, (match) => match[1].toUpperCase())

                if (!ttifiedProperty.startsWith('tt')) {
                    ttifiedProperty = 'tt' + ttifiedProperty.charAt(0).toUpperCase() + ttifiedProperty.slice(1);
                }

                newObject[ttifiedProperty] = object[property];
            }

            return newObject;
        }

        /**
         * Sets the tt-options object to the component controller. It needs to be the first thing set on onChanges so the changes will be reføected.
         * 
         * @param {Object} controller the component controller to set the tt-options object to.
         * @param {Object} changes the object containing SimpleChanges object given when changes from the parent component happen.
         */
        service.setOptions = function (controller, changes) {
            const options = this.ttifyObject(changes.ttOptions?.currentValue);

            for (const property in options) {
                if (!options[property]) continue;

                if (property === 'ttModel') {
                    controller.ttModel = options.ttModel;
                    continue;
                }

                if (property === 'ttChange') {
                    controller.ttChange = options.ttChange;
                    continue;
                }

                controller[property] ??= options[property];

                if (!changes[property]) {
                    changes[property] = {
                        previousValue: null,
                        currentValue: options[property],
                    };
                }

                changes[property].currentValue ??= options[property];
            }
        }

        /**
         * Calls the functions in the list, used on a components `onDestroy` lifecycle event.
         * 
         * @param {(() => void)[]} onDestroy list of functions to call when a directive is being destroyed.
         */
        service.onDestroy = function (onDestroy) {
            onDestroy.forEach((func) => {
                if (angular.isFunction(func) === true) func();
            });
        }

        /**
         * Checks if the given text align is a valid align parameter.
         * 
         * @param {string} textAlign the text align parameter to check if is a valid align.
         * @returns {boolean} true if the text align is a valid align parameter, false if not.
         */
        service.isValidAlign = function (textAlign) {
            if (utilityService.isStringValue(textAlign, true) !== true)
                return false;
            if (textAlign !== 'center' && textAlign !== 'left' && textAlign !== 'right' && textAlign !== 'justify')
                return false;

            return true;
        };

        /**
         * @typedef {Object} GroupButtonClick represents the clickevent configuration for a group button, only click property is strict, addition properties are applied as parameters on the function.
         * @property {() => void} click the function to be called when the group button is clicked.
         */

        /**
         * @typedef {Object} GroupButton represents a button which is a part of a group of buttons on an input field.
         * @property {string} id the id of the group button.
         * @property {string} icon the icon classes needed to apply an icon for the group button.
         * @property {'primary' | 'warning' | 'success' | 'danger'} color
         * @property {GroupButtonClick | () => void} onClick the function or an object with additional configuration and the function to be called when the button is clicked.
         */

        /**
         * @typedef {Object} GroupButtonClickConfig represents the configuration for generick click event handler.
         * @property {GroupButton} button the group button to handle click event for.
         * @property {any} parameter the parameter to apply on the function.
         * @property {Object} controller the vm for the controller where this button is created from.
         */

        /**
         * Generic click event handler for the given button applying the given parameter for the given controller.
         * 
         * @param {GroupButtonClickConfig} config the configuration for the handling click event on a group button.
         */
        service.onButtonClick = function ({ button, parameter, controller, event}) {
            if (!button?.onClick) return;

            if (angular.isFunction(button.onClick)) {
                if (parameter) {
                    button.onClick(parameter, event);
                } else {
                    button.onClick(button, event);
                }
            } else {
                if (angular.isObject(button.onClick) && angular.isFunction(button.onClick.click)) {
                    let args = [];

                    if (angular.isDefined(parameter)) args.push(parameter);

                    angular.forEach(button.onClick, (value, key) => {
                        if (key !== 'click') args.push(value);
                    });

                    if (args.length > 0) {
                        button.onClick.click.apply(controller, args);
                    } else {
                        button.onClick.click(button, event);
                    }
                } else {
                    //console.error(`Invalid onClick event`);
                    //console.dir(controller);
                }
            }
        };
        /**
         * Combines the given default styling and given custom styling (ttStyle), where ttStyle propertied takes precedence.
         * 
         * @param {Object} style the default styling for the component.
         * @param {Object} ttStyle the custom styling for the component.
         * @returns {Object} an object of the combined style properties.
         */
        function mergeObjects(style, ttStyle) {
            let combined = { ...style };

            Object.keys(ttStyle).forEach((key) => {
                if (ttStyle?.[key]) {

                    // If style has this attribute and the attribute is an object, merge these.
                    if (style?.[key] && typeof ttStyle[key] === 'object') {
                        combined[key] = mergeObjects(style[key], ttStyle[key]);
                    } else {
                        combined[key] = ttStyle[key];
                    }
                }
            });

            return combined;
        };

        function setLabelConfig({ labelAlwaysOnTop = false, labelView = 'auto', hideLabel = false }) {
            let baseClasses = '';

            if (hideLabel === true) {
                baseClasses += ' tt-input__base--label-none';
            }

            switch (labelView) {
                case 'top':
                    baseClasses += ' tt-input__base--label-top';
                    break;
                case 'side':
                    baseClasses += ' tt-input__base--label-side';
                    break;
                case 'none':
                    baseClasses += 'tt-input__base--label-none';
                    break;
                case 'hidden':
                    baseClasses += 'tt-input__base--label-hidden';
                    break;
                case 'auto':
                    baseClasses += '';
                    break;
                default:
                    baseClasses += '';
                    break;
            }

            baseClasses += labelAlwaysOnTop === true ? ' tt-input__base--label-top' : '';
            return baseClasses;
        }

        /**
         * @typedef {Object} LabelConfig represents configuration properties for labels on tt directives.
         * @property {boolean} labelAlwaysOnTop whether the label should always be on top.
         * @property {'auto' | 'top' | 'side' | 'none' | 'hidden'} labelView the positioning or view of the label. 'none' will remove the space for label completely.
         * @property {boolean} hideLabel whether the label should be hidden, this will preserve the spave for the label and only hide it.
         */

        /**
         * Returns a string of classes to add to the vm.class.base object for positioning the label according to
         * label configurations.
         * 
         * @param {LabelConfig} labelConfig the configuration for position the label.
         * @returns {string} a string of classes to add to vm.class.base to position the label.
         */
        service.getBaseClasses = function ({ labelAlwaysOnTop = false, labelView = 'auto', hideLabel = false }) {
            /*
            let baseClasses = '';

            if (hideLabel === true) {
                baseClasses += ' tt-input__base--label-none';
            }

            switch (labelView) {
                case 'top':
                    baseClasses += ' tt-input__base--label-top';
                    break;
                case 'side':
                    baseClasses += ' tt-input__base--label-side';
                    break;
                case 'none':
                    baseClasses += 'tt-input__base--label-hidden';
                    break;
                case 'hidden':
                    baseClasses += 'tt-input__base--label-hidden';
                    break;
                case 'auto':
                    baseClasses += '';
                    break;
                default:
                    baseClasses += '';
                    break;
            }

            baseClasses += labelAlwaysOnTop === true ? ' tt-input__base--label-top' : '';*/
            return setLabelConfig({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: labelView, hideLabel: hideLabel });
        };

        service.setClasses = function ({ classes, ttClasses = null }) {

            // Make sure styles are objects.
            if (ttClasses && angular.isObject(ttClasses) !== true) {
                if (angular.isString(ttClasses) !== true) return { ...classes };

                ttClasses = stringToObject(ttClasses);
            }

            classes.container = ttClasses?.container?.length > 0 ? ttClasses.container : classes.container;
            classes.base = ttClasses?.base?.length > 0 ? ttClasses.base : classes.base;
            classes.label = ttClasses?.label?.length > 0 ? ttClasses.label : classes.label;
            classes.input = ttClasses?.input?.length > 0 ? ttClasses.input : classes.input;

            return classes;
        };


        /**
         * Parses the given string to a javascript object.
         * 
         * @param {string} string the string to be parsed to a javascript object.
         * @returns {Object} the object created from the string.
         */
        function stringToObject(string) {
            if (utilityService.isStringValue(string)) {
                string = string.trim();

                if (string.substring(0, 1) !== '{') string = '{' + string + '}';

                return angular.fromJson(string);
            }
        }

        /**
         * @typedef {Object} StyleParam represents parameters needed to create styling for a component.
         * @property {Object} style the default style object for the component.
         * @property {Object} [ttStyle] the custom styling for the component.
         * @property { 'left' | 'right' | 'center' | 'justify' } [textAlign] the text alignment for the element.
         * @property {string | string[]} mainElement the main html element(s) for the component, i.e. 'input', 'button' etc or ['button', 'lockedButton'].
         */

        /**
         * Given style parameters, correct value for textAlign is set based on the content of the parameters.
         * 
         * @param {StyleParam} config the parameters for styling to set correct align value.
         * @param {string} config.mainElement the main element of the component, in this case **it should be only a string.**
         */
        function setTextAlignOnStyles({ style, ttStyle, mainElement, textAlign }) {
            if (!textAlign || (textAlign && service.isValidAlign(textAlign) === false)) return;

            if (ttStyle && ttStyle.hasOwnProperty(mainElement)) {
                ttStyle[mainElement].textAlign = textAlign;
            } else if (style && style.hasOwnProperty(mainElement)) {
                style[mainElement].textAlign = textAlign;
            }
        }

        /**
         * Sets the style object for a component based on recieved properties.
         * 
         * @param {StyleParam} config the parameters needed to create styling for a component.
         * @returns style object based on properties recieved.
         */
        service.setStyle = function ({ style, ttStyle, textAlign, mainElement }) {

            if (!ttStyle) {
                ttStyle = { ...style };
            }

            // Make sure styles are objects.
            if (angular.isObject(ttStyle) !== true) {
                if (angular.isString(ttStyle) !== true) return { ...style };

                ttStyle = stringToObject(ttStyle);
            }

            // textAlign.
            if (angular.isArray(mainElement) && mainElement.length > 0) {
                mainElement.forEach((element) => setTextAlignOnStyles({ style: style, ttStyle: ttStyle, textAlign: textAlign, mainElement: element }));
            } else if (mainElement && utilityService.isStringValue(mainElement)) {
                setTextAlignOnStyles({ style: style, ttStyle: ttStyle, textAlign: textAlign, mainElement: mainElement });
            } else {
                console.error(`Main element missing from ttDirectiveService.setStyle`);
            }

            return mergeObjects(style, ttStyle);
        };

        function isFormElement(property) {
            return ['input', 'date'].includes(property);
        }

        /**
         * Sets styling on the style object from layout information recieved from layout changes.
         * 
         * @param {Object} style the style object to set layout specific styling for.
         * @param {Object} info the layout information object recieved when layout changes.
         */
        service.setLayoutStyle = function (style, info) {
            Object.keys(style).forEach((key) => {
                if (isFormElement(key)) {
                    style[key].fontSize = info.fontSizes.textSize;
                    style[key].height = info.height + 'px';
                    style[key].paddingTop = info.padding.top + 'px';
                    style[key].paddingBottom = info.padding.bottom + 'px';
                    style[key].paddingLeft = info.padding.left + 'px';
                    style[key].paddingRight = info.padding.right + 'px';
                } else if (key === 'textarea') {
                    style[key].fontSize = info.fontSizes.textSize;
                    style[key].minHeight = '0';
                    style[key].maxHeight = 'none';
                    style[key].height = (info.height * 3) + 'px';
                    style[key].paddingTop = info.padding.top + 'px';
                    style[key].paddingBottom = info.padding.bottom + 'px';
                    style[key].paddingLeft = info.padding.left + 'px';
                    style[key].paddingRight = info.padding.right + 'px';
                } else if (key === 'select') {
                    style[key].fontSize = info.fontSizes.textSize;
                    style[key].height = info.height + 'px';
                    style[key].paddingTop = info.padding.top + 'px';
                    style[key].paddingBottom = info.padding.bottom + 'px';
                    style[key].paddingLeft = info.padding.left + 'px';
                    style[key].paddingRight =  '2rem';
                } else if (key === 'checkbox') {
                    style[key].height = info.fontSizes.textSize;
                    style[key].width = info.fontSizes.textSize;
                    style[key].fontSize = info.fontSizes.textSize;
                } else if (key === 'iconButtonText') {
                    style[key] = info.fontSizes.textSizeL;
                } else if (key === 'icon' || key === 'buttonText') {
                    style[key].fontSize = info.fontSizes.textSize;
                } else if (key === 'label') {
                    style[key].fontSize = info.fontSizes.textSizeS;
                } else if (key === 'sublabel') {
                    style[key].fontSize = info.fontSizes.textSizeSs;
                } else if (key === 'button' || key === 'lockedButton') {
                    style[key].fontSize = info.fontSizes.textSize;
                    style[key].height = info.height + 'px';
                } else if (key === 'group') {
                    style[key].height = info.height + 'px';
                } else if (key === 'badge') {
                    style[key].fontSize = info.fontSizes.textSizeSs;
                }
            });
        }

        return service;
    }]);
})();
