(function () {
    'use strict';
 
    angular.module('imApp')
        .directive('imFuncDirective', ['layoutService', function (layoutService) {
        var directive = {
            restrict: 'E',
            scope: {
                func: '&',
                locked: '=',
            },
            template:   '<div class="form-group" style="padding-left: 15px; padding-right: 15px;">' +
                            '<button ng-show="showLocked(locked)" type="submit" class="btn btn-success btn-lg col-xs-12" style="color:white;" ng-style="model.buttonStyle">' +
                                    '<i class="glyphicon glyphicon-save" ng-style="{\'fontSize\': model.fontSize}"></i> Lagre' +
                            '</button>' +
                            '<button ng-hide="showLocked(locked)" type="submit" class="btn btn-success btn-lg disabled col-xs-12" style="color:white; font-size:18px" ng-style="model.buttonStyle">' +
                                '<span id="spin_text" ng-style="{\'fontSize\': model.fontSize}">' +
                                    '<i class="glyphicon glyphicon-lock" id="spin_text" ng-style="{\'fontSize\': model.fontSize}"></i> Låst' +
                                '</span>' +
                            '</button>' +
                        '</div>',
            link: function ($scope) {
                $scope.model = {
                    fontSize: '',
                    buttonStyle: {}
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    $scope.model.fontSize = info.fontSizes.textSize;

                    $scope.model.buttonStyle.fontSize = info.fontSizes.textSize;;
                    $scope.model.buttonStyle.height = info.height + 'px';
                    $scope.model.buttonStyle.padding = '0px 5px'
                });

                $scope.showLocked = function (lockedvar) {
                    if (lockedvar == '0') {
                        return true;
                    }
                    else if (lockedvar == '1') {
                        return false;
                    }
                };
            }
        };
 
        return directive;
    }]);
})();