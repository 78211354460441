(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productionhTopsComponents', {
        templateUrl: 'views/components/views/productionhTopsComponents/productionhTopsComponents.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', '$stateParams', 'productionhTopsComponentsService', function($timeout, stateService, $stateParams, productionhTopsComponentsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            console.log('entering: productionhTopsComponentsCtrl');
    
            var resizing = false;
            var editing = false;
    
            vm.model = {
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item && vm.model.mconfig.records[r].canActive === true) {
                                    vm.model.mconfig.records[r].isActive = true;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {                    
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.productionhTopsComponentsColumnResize();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
    
                        /** UNCHECK ALL */
    
                        if (item.item_name === 'uncheck') {
                            vm.productionhTopsComponentsColumnUncheckAll();
                        }
    
                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.productionhTopsComponentsColumnResize();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                        { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '5', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: false },
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '7', item_name: 'edit', glyph: 'fa-edit', color: 'primary', colorActive: 'warning', canActive: true, isActive: true, isIcon: false, enabled: true, show: false },
                        { item_id: '10', item_name: 'uncheck', glyph: 'glyphicon-unchecked', color: 'primary', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: false }
                    ]
                },
                argType: $stateParams.argtype,
    			argValue: $stateParams.argvalue,
                components: [],
                componentsRaw: [],
                loaded: false,
                clickDec: false
            };
    
    
    
            vm.loadComponents = function(){
                productionhTopsComponentsService.loadComponents(vm.model.argType,vm.model.argValue).then(function (data) {
                    var smallDataLoad = [];
                    for (var i = 0; i < data.length; i++) {
                        data[i].item_id = vm.illegalCharRegExp(data[i].item_id);
                        smallDataLoad.push(data[i]);
                    }
                    angular.copy(smallDataLoad, vm.model.components);
                    angular.copy(smallDataLoad, vm.model.componentsRaw);
                    vm.loaded = true; 
                });
            };
            
            vm.loadComponents();         
    
            var canRun616 = function (variableName, VariableValue) {
                
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.pageSettings[parm]) === true) return false;
                if (vm.model.pageSettings[parm] === null) return false;
                return true;
            };        
    
            vm.illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            }
    
            vm.productionhTopsComponentsColumnResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(vm.model.components[0]) && angular.isDefined(vm.model.components[0].item_secondary)) {
                    for (var r = 0; r < vm.model.components[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.components[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.components[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.components[0].item_secondary[r].item_col_width })
                        }
                    }
                }
    
                var updateParms = {
                    view_id: 'productionhtops.components.list',
                    records: updateList
                };
    
                productionhTopsComponentsService.updateColumnResize(updateParms).then(function (data) {
                    console.log('productionhTopsComponentsService.updateColumnResize...');
                    console.dir(data);
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        console.dir(data);
    
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
    
                    resizing = false;
                });
            };
    
            
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'productionhTopsComponentsstock':
                        go({});
                        break;
                    default:
                        go({
                            argtype: '',
                            argvalue: ''
                        });
                        break;
                }
            };
    
            vm.gridClick = function(item){
                console.dir(item);
                //item = angular.isDefined(item.item) ? item.item : item;
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;
                if (baseItem.item_id != 0){
    
                    console.log('Other clicked...');
                    console.dir(baseItem);
    
                    switch (currentItem.item_func) {
                        case 'stock':
                            console.log('Item function is defined as: ' + currentItem.item_func);  
                            vm.goTo('productionhTopsComponentsstock');                      
                            break;
                        case 'blockorder':
                                console.log('Item function is defined as: ' + currentItem.item_func);
                                //vm.goTo('productionhTopsComponentsblockorder');
                                stateService.go('productionhTopsComponentsblockorder', { order_internal_no: baseItem.item_order_internal_no });
                                break;
                        case 'orderlines':
                                    console.log('Item function is defined as: ' + currentItem.item_func);
                                    //vm.goTo('productionhTopsComponentsblockorder');
                                    stateService.go('productionhTopsComponentsorderlines', {});
                                    break;
                        case 'dispatch':
                                console.log('Item function is defined as: ' + currentItem.item_func);
                                stateService.go('productionhTopsComponentsconsignment', { dispatch_no: baseItem.item_dispatch_no });
                            break;
                        default:
    
                            console.log('Grid clicked');
                            console.dir(item);
                            if (baseItem.item_is_selected != '0'){
                                baseItem.item_is_selected = '0';
                                //baseItem.item_glyphicon = vm.replaceFirstList(baseItem.item_glyphicon,'glyphicon-unchecked');
                                vm.model.clickDec = true;
                            } else {
                                baseItem.item_is_selected = '1';
                                //baseItem.item_glyphicon = vm.replaceFirstList(baseItem.item_glyphicon,'glyphicon-check');
                                vm.model.clickDec = false;
                            }
                            vm.validateFilter();
    
                            break;
                        }
                }  else {
                    console.log('First line clicked...');
                }          
            };
    
            
        }]
    });
})();