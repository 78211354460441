(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('gridBookingColumnsModal', {
        templateUrl: 'views/components/views/gridBookingModal/gridBookingColumnsModal/gridBookingColumnsModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['layoutService', 'translateService', 'rememberService', function (layoutService, translateService, rememberService) {
            const vm = this;

            vm.id = {
                fileinput: crypto.randomUUID(),
            };

            vm.model = {
                rememberId: '',
                columns: [
                    { item_name: 'voucherdate', item_id: 'voucherdate', show: true },
                    { item_name: 'transdate', item_id: 'transdate', show: true },
                    { item_name: 'accounttype_db', item_id: 'accounttype_db', show: true },
                    { item_name: 'account_name_db', item_id: 'account_name_db', show: true },
                    { item_name: 'acvatcode_db', item_id: 'acvatcode_db', show: true },
                    { item_name: 'accounttype_cr', item_id: 'accounttype_cr', show: true },
                    { item_name: 'account_name_cr', item_id: 'account_name_cr', show: true },
                    { item_name: 'acvatcode_cr', item_id: 'acvatcode_cr', show: true },
                    { item_name: 'valuta_id', item_id: 'valuta_id', show: true },
                    { item_name: 'amount_invval', item_id: 'amount_invval', show: true },
                    { item_name: 'exchange_rate', item_id: 'exchange_rate', show: true },
                    { item_name: 'amount_locval', item_id: 'amount_locval', show: true },
                    { item_name: 'exchange_factor', item_id: 'exchange_factor', show: false },
                ]
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.rememberLayout = function () {
                if (vm.model.rememberId) {
                    rememberService.remember(vm.model.rememberId + '.grid_booking_column_layout', vm.model.columns);
                }
            };

            async function getLayout() {
                if (vm.model.rememberId) {
                    const lastStatuses = await rememberService.getLastStatus(vm.model.rememberId + '.grid_booking_column_layout', true) || [];

                    vm.model.columns = vm.model.columns.map((column) => {
                        const lastStatus = lastStatuses.find((status) => status.item_id === column.item_id);

                        if (!!lastStatus) {
                            return { ...column, show: lastStatus.show };
                        }
                        return column;
                    });
                }
            };

            async function translateDataNames() {
                const translations = await translateService.translateBatch(vm.model.columns.map((column) => column.item_name));

                Object.keys(translations).forEach((key) => {
                    vm.model.columns.forEach((column) => {
                        if (translations[key] && column.item_name === key) {
                            column.item_name = translations[key];
                        }
                    })
                })

                vm.model.columns = [...vm.model.columns];
            };

       

            vm.$onInit = function () {
                if (vm.resolve?.rememberId) {
                    vm.model.rememberId = vm.resolve.rememberId
                }

                if (vm.resolve?.label_transdate) {
                    const trandateColumn = vm.model.columns.find((column) => column.item_id === 'transdate');

                    if (!!trandateColumn) {
                        vm.model.columns[vm.model.columns.indexOf(trandateColumn)].item_name = vm.resolve.label_transdate;
                    }
                }

                if (vm.resolve?.label_voucherdate) {
                    const trandateColumn = vm.model.columns.find((column) => column.item_id === 'voucherdate');

                    if (!!trandateColumn) {
                        trandateColumn.item_name = vm.resolve.label_voucherdate;
                    }
                }

                if (vm.resolve?.show_transdate === '0') {
                    const transdateColumn = vm.model.columns.find((column) => column.item_id === 'transdate');

                    if (!!transdateColumn) {
                        vm.model.columns.splice(vm.model.columns.indexOf(transdateColumn), 1);
                        //vm.model.columns[vm.model.columns.indexOf(transdateColumn)].item_name = vm.resolve.label_transdate;
                    }
                }

                if (vm.resolve?.show_voucherdate === '0') {
                    const voucherdateColumn = vm.model.columns.find((column) => column.item_id === 'voucherdate');

                    if (!!voucherdateColumn) {
                        vm.model.columns.splice(vm.model.columns.indexOf(voucherdateColumn), 1);
                        //voucherdateColumn.item_name = vm.resolve.label_voucherdate;
                    }
                }

                getLayout();
                translateDataNames();

                console.log(vm.model.columns);
            };
        }]
    });
})();
