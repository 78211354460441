(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('metaTableDatas', {
        templateUrl: 'views/components/views/metaTableDatas/metaTableDatas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['metaTableDatasService', function (metaTableDatasService) {
            const vm = this;

            vm.model = {
                itemsListMetaTableDatas: []
            };

            let loadMetaTableDatas = function () {
                metaTableDatasService.listMetaTableDatas().then(function (result) {
                    angular.copy(result, vm.model.itemsListMetaTableDatas);
                });
            };

            vm.$onInit = function () {
                loadMetaTableDatas();
            };
        }]
    });
})();
