(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("oeeDataService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_oeedata_get: {
                oeedata_keyno: '',
                equipment_id: '',
                equipment_name: '',
                oeestate_keyno: '',
                oeestate_name: '',
                reg_by_labour_no: '',
                reg_by_equipment_id: '',
                oeestateremark_keyno: '',
                oeestateremark_name: '',
                approved_status: '',
                cycle_time_seconds: '',
                products_optimal: '',
                products_real: '',
                startup_wrecks: '',
                wrecks: '',
                prod_id: '',
                oeestateremark_text: '',
                start_dt: '',
                end_dt: '',
                approved_by_portal_user_name: '',
                approved_datetime: '',
                show_savebutton_change: '',
                show_details: '',
                show_remark: '',
                show_prodid: '',
                show_remarktext: ''
            },
            loadP2OeeDataGet: function (oeedata_keyno) {
                var deferred = $q.defer();

                var parms_p2_oeedata_get = {
                    method: 251,
                    parameters: {
                        oeedata_keyno: oeedata_keyno
                    }
                };

                $ihttp.post(parms_p2_oeedata_get).then(function (data) {
                    service.p2_oeedata_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            listOeeStates: function (parms) {
                return $ihttp.post({
                    method: 252,
                    parameters: parms || {}
                });
            },
            listRegisteredByLabours: function (parms) {
                return $ihttp.post({
                    method: 195,
                    parameters: parms || {}
                });
            },
            getShowSettings: function (edit) {
                return $ihttp.post({
                    method: 323,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 254,
                    parameters: edit
                });
            },
            approveObj: function (edit) {
                return $ihttp.post({
                    method: 258,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 324,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
