(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentSerialNumber', {
        templateUrl: 'views/components/views/consignmentSerialNumber/consignmentSerialNumber.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'consignmentSerialNumberService', 'utilityService', function ($stateParams, stateService, consignmentSerialNumberService, us) {

            var vm = this;

            vm.model = {
                locked: false,
                lastKey: -1,
                consignmentlines: []
            };

            vm.loadConsignmentSerialNumber = function () {
                consignmentSerialNumberService.loadConsignmentSerialNumber($stateParams.consignment_keyno).then(function (data) {
                    vm.model.consignmentlines = angular.copy(data);
                    console.dir(vm.model.consignmentlines);
                });
            }

            vm.serialNoChange = function (consignmentline, value) {
                consignmentline.serialno = value;
            }

            vm.saveConsignmentSerialNumber = function () {
                vm.model.locked = true;

                consignmentSerialNumberService.saveConsignmentSerialNumber(vm.model.consignmentlines).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.locked = false;
                    }
                });
            }
            vm.$onInit = function () {
                vm.loadConsignmentSerialNumber();
            }
        }]
    });
})();
