(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCrew', {
        templateUrl: 'views/components/views/srCrew/srCrew.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'srCrewService', function($stateParams, stateService, utilityService, modalService, srCrewService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceId: $stateParams.resource_id,
                srCrewKeyNo: $stateParams.srcrew_keyno,
                crew: {},
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCrewService.loadSrCrewGet(vm.model.resourceId, vm.model.srCrewKeyNo).then(function (data) {
                vm.model.crew = angular.copy(data[0]);
            });
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrCrew = function () {
                vm.model.lockedSave = true;
                
                srCrewService.save(vm.model.crew).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();