import { NgModule, CUSTOM_ELEMENTS_SCHEMA, OnInit, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphComponent } from './graph/graph.component';

@NgModule({
    declarations: [
        GraphComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        GraphComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MicrosoftGraphModule {
    constructor() { }

    //static forRoot(): ModuleWithProviders<MicrosoftGraphModule> {
    //    return {
    //        ngModule: MicrosoftGraphModule,
    //        providers: [
    //        ]
    //    };
    //}
}
