import { Injectable } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';

export interface SirvRequestConfig {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    contentType?: string;
    data?: BodyInit | null | undefined;
}

/**
 * Service for the Sirv CDN REST API.
 */
export interface SirvClientInfo {
    clientId: string;
    clientSecret: string;
}

@Injectable({
    providedIn: 'root',
})
export class SirvService {
    /**
     * The base url for the api.
     */
    private readonly API_URL = 'https://api.sirv.com/v2';

    /**
     * The api path for retrieving the token.
     */
    private readonly API_PATH_TOKEN = '/token';

    /**
     * The api path for uploading files.
     */
    private readonly API_PATH_UPLOAD_FILES = '/files/upload?filename=';

    /**
     * The api path for updating a filepath.
     */
    private readonly API_PATH_UPDATE_FILEPATH = '/files/rename?from=';

    /**
     * The api path for searching through files.
     */
    private readonly API_PATH_SEARCH_FILES = '/files/search';

    /**
     * The path for deleting files.
     */
    private readonly API_PATH_DELETE_FILES = '/files/delete?filename=';

    /**
     * The client info which was stored in the db if any.
     */
    private clientInfo?: SirvClientInfo;

    /**
     * The company url which was stored in the db if any.
     */
    private companyUrl?: string;

    /**
     * The number representing the time when the token expires.
     */
    private tokenExpiration?: number;

    /**
     * The sirv-api token to use for api requests.
     */
    private token?: string;

    /**
     * The root folder in the
     */
    private rootFolder = 'TouchTime';

    constructor(private datatask: DataTaskService, private modal: ModalService) {}

    /**
     * Checks the connection with the given client info. If no client info is given then check the connection with the client info stored in db.
     *
     * @param clientInfo (optional) the client info to check connection with.
     * @returns a promise containing `true` if the connection is ok, `false` if not.
     */
    public async checkConnection(clientInfo?: SirvClientInfo): Promise<boolean> {
        let connection = false;

        try {
            if (!!clientInfo) {
                if (this.isClientInfoValid(clientInfo)) {
                    this.tokenExpiration = Date.now();
                    await this.retrieveToken(clientInfo);
                    connection = true;
                } else {
                    connection = false;
                    // throw Error('Invalid sirv api setup');
                }
            } else {
                await this.retrieveClientInfo();

                if (!this.clientInfo) {
                    connection = false;
                    // throw Error('Missing sirv api setup');
                } else if (this.isClientInfoValid(this.clientInfo)) {
                    this.tokenExpiration = Date.now();
                    await this.retrieveToken(this.clientInfo);
                    connection = true;
                } else {
                    connection = false;
                    // throw Error('Invalid sirv api setup');
                }
            }
        } catch (error) {
            connection = false;
        }

        return connection;
    }

    /**
     * Uploads the list of images to the sirv cdn.
     *
     * @param images the list of images to upload.
     * @param folder the folder til add the images to.
     * @returns a promise containing the list of image-data of the uploaded images.
     */
    public async uploadMultipleImages(images: File[], folder?: string) {
        const progressModal = this.modal.openProgressDialog(0, images.length);

        try {
            const imageData: { image: Blob; imageUrl: string }[] = [];
            let count = 0;

            await Promise.all(
                images.map(async (image) => {
                    count++;
                    const response = await this.uploadImage(image, folder);
                    progressModal.updateValue(count);
                    imageData.push(response);
                })
            );

            return imageData;
        } catch (error) {
            console.log(error);
            progressModal.updateStatus('invalid');
            progressModal.updateInvalidText(`${error}`);
            return [];
        } finally {
            progressModal.updateStatus('finish');
        }
    }

    /**
     * Uploads the given image to the sirv cdn.
     *
     * @param image the image to upload.
     * @param folder the folder to upload the image to.
     * @returns a promise containing the image data of the uploaded image.
     */
    public async uploadImage(image: File, folder?: string): Promise<{ image: Blob; imageUrl: string }> {
        const data = new Blob([image], { type: image.type });
        let filename = image.name;

        if (await this.fileOfNameAndFolderExists(image.name, folder)) {
            filename = this.generateUniqueFilename(filename);
        }

        await this.sendApiRequest(this.API_PATH_UPLOAD_FILES + this.buildEncodedFilepath(filename, folder), { method: 'POST', contentType: image.type, data: data });

        return { image: image, imageUrl: this.buildImageUrl(filename, folder) };
    }

    /**
     * Updates the filename of the given current filename to the value of the new filename.
     *
     * @param currentFilename the current filename to update.
     * @param newFilename the new filename to update to.
     * @param folder the folder the file resides in, assumes root-folder if not provided.
     * @returns a promise for the update filepath request.
     */
    public async updateFilepath(currentFilename: string, newFilename: string, folder?: string) {
        const currentFilepath = this.buildEncodedFilepath(currentFilename, folder);
        const newFilepath = this.buildEncodedFilepath(newFilename, folder);

        return this.sendApiRequest(`${this.API_PATH_UPDATE_FILEPATH}${currentFilepath}to=${newFilepath}`, { method: 'POST' });
    }

    public async deleteImage(filepath: string) {
        return this.sendApiRequest(`${this.API_PATH_DELETE_FILES}${filepath}`, { method: 'POST' });
    }

    /**
     * Checks if a file of the given name exists in the given folder or root folder if not provided.
     *
     * @param name the filename to check if already exists.
     * @param folder the folder to check if the filename exists in.
     * @returns a promise returning `true` if the filename already exists, `false` if not.
     */
    private async fileOfNameAndFolderExists(name: string, folder?: string) {
        const response = await this.sendApiRequest(this.API_PATH_SEARCH_FILES, { method: 'POST', data: JSON.stringify({ query: `filename:${name} AND dirname:${folder ?? this.rootFolder}` }) });

        if (response?.total !== 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Generates a unique filename for the given filename.
     *
     * @param filename the filename to make unique.
     * @returns a unique version of the given filename.
     */
    private generateUniqueFilename(filename: string) {
        let modifyFilename = filename.split('.');
        modifyFilename[0] += '_' + new Date().toISOString().replace(/T/, '').replace(/\..+/, '').replaceAll(' ', '').replaceAll(':', '');
        return modifyFilename[0] + Math.floor(Math.random() * 90 + 10) + '.' + modifyFilename[1];
    }

    /**
     * Builds the image url for the given filename in the given folder if provided (root-folder will be used if not).
     *
     * @param name the name of the file to build url for.
     * @param folder the folder to create url for, uses root folder if not provided.
     * @returns the image url for the given filename of the given folder (root-folder if not provided).
     */
    private buildImageUrl(name: string, folder?: string) {
        let filePath = this.buildFolderPath(folder) + encodeURIComponent(name);
        return this.companyUrl + filePath.replaceAll('%2F', '/');
    }

    /**
     * Builds the uri-encoded filepath for the image in the given folder, uses root-folder if folder is not provided.
     *
     * @param name the name of the image to create a file-path for.
     * @param folder the folder of the image, uses root-folder if not provided.
     * @returns the encoded filepath for the given image name in the given folder.
     */
    private buildEncodedFilepath(name: string, folder?: string) {
        return this.buildFolderPath(folder) + name;
    }

    /**
     * Builds an encoded folder path for the given folder, uses root-folder if not provided.
     *
     * @param folder the folder to create a folder path to.
     * @returns encoded folder path to the given folder, root-folder if not provided.
     */
    private buildFolderPath(folder?: string): string {
        const today = new Date();
        let folderPath = '';

        if (!!folder) {
            folderPath = `/${this.rootFolder}/${folder}/${today.getFullYear()}/${1 + today.getMonth()}/`;
            // return `%2F${this.rootFolder}%2F${folder}%2F${today.getFullYear()}%2F${1 + today.getMonth()}%2F`;
        } else {
            folderPath = `/${this.rootFolder}/undefined/${today.getFullYear()}/${1 + today.getMonth()}/`;
            // return `%2F${this.rootFolder}%2Fundefined%2F${today.getFullYear()}%2F${1 + today.getMonth()}%2F`;
        }

        return encodeURIComponent(folderPath);
    }

    /**
     * Retrieves the stored client info.
     */
    private async retrieveClientInfo() {
        const response = (await this.datatask.Post(2470))[0];

        if (!!response && typeof response === 'object' && !!response.sirv_client_id && !!response.sirv_client_secret && !!response.sirv_company_url) {
            this.clientInfo = {
                clientId: response.sirv_client_id,
                clientSecret: response.sirv_client_secret,
            };

            this.companyUrl = response.sirv_company_url;
        } else {
            throw Error('Missing or invalid sirv api setup');
        }
    }

    /**
     * Retrieves the sirv-api token based on the given client info. Sets the global token property and returns the whole token response.
     *
     * @param clientInfo the client info to retrieve token for.
     * @returns the token response.
     */
    private async retrieveToken(clientInfo: SirvClientInfo) {
        try {
            if (!!clientInfo) {
                const request = await fetch(`${this.API_URL}${this.API_PATH_TOKEN}`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify(clientInfo),
                    credentials: 'omit',
                    redirect: 'follow',
                });

                if (`${request.status}`.startsWith('4') || `${request.status}`.startsWith('5')) {
                    throw Error(request.statusText);
                }

                const response = await request.json();

                this.token = response.token;
                this.tokenExpiration += response.expiresIn;

                return response;
            } else {
                throw Error('Missing Sirv api setup');
            }
        } catch (error) {
            throw error;
        }
    }

    /**
     * Sends an api-request to the sirv api.
     *
     * @param api the api-path to use in the sirv request.
     * @param config the configuration of the api-request.
     * @returns a promise containing the response data, if no data it will return the response itself.
     */
    private async sendApiRequest(api: string, config: SirvRequestConfig): Promise<any | string | Blob | Response> {
        if (!this.isTokenValid()) {
            await this.checkConnection();
        }

        try {
            const request: RequestInit = {
                method: config?.method ?? 'GET',
                headers: {
                    'content-type': config.contentType ?? 'application/json',
                    authorization: `Bearer ${this.token}`,
                },
                body: config.data,
            };

            const response = await fetch(`${this.API_URL}${api}`, request);

            if (!response.ok) throw Error('Sirv request failed \n ' + JSON.stringify(response, null, 2));

            const contentType = response.headers.get('content-type');

            if (contentType?.includes('application/json')) {
                return await response.json();
            } else if (contentType?.includes('text/')) {
                return await response.text();
            } else if (contentType?.includes('application/octet-stream') || contentType?.includes('image/')) {
                return await response.blob();
            } else {
                return response;
            }
        } catch (error) {
            throw error;
        }
    }

    /**
     * Checks if there is a valid token available or not.
     *
     * @returns `true` if token is valid, `false` if not.
     */
    private isTokenValid(): boolean {
        return !!this.token && !!this.tokenExpiration && this.tokenExpiration <= Date.now();
    }

    /**
     * Checks if the given client info config is a valid client info for retrieving a token.
     *
     * @param clientInfo the client info to check if is valid.
     * @returns `true` if the client info is valid, `false` if not.
     */
    private isClientInfoValid(clientInfo?: SirvClientInfo): boolean {
        return !!clientInfo && typeof clientInfo === 'object' && Object.hasOwn(clientInfo, 'clientId') && Object.hasOwn(clientInfo, 'clientSecret');
    }
}
