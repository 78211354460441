(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('selectGlyphicon', {
        templateUrl: 'views/components/views/selectGlyphicon/selectGlyphicon.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'selectGlyphiconService', 'skillsCertificateTypeService', 'rememberService', function (utilityService, selectGlyphiconService, skillsCertificateTypeService, rememberService) {

    		// ## VARIABLES & DEFINITIONS ##

            const vm = this;
            
    		vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
    			setting: {},
    			itemsListGlyphicons: []
    		};
            
    		// ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                selectGlyphiconService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };
            
            let loadGlyphicons = function () {
                skillsCertificateTypeService.listGlyphicons().then(function (result) {
                    angular.copy(result, vm.model.itemsListGlyphicons);
                });
            };
            
    		// ## REMEMBER VALUE FUNCTIONS ##
            
    		vm.goToPrevious = function (item) {
    			var variableName = 'w_selectglyphicon.selected_glyphicon';
                
                if (utilityService.validateParmsValue(item.item_id) !== true) return;
                
                rememberService.remember(variableName, item.item_id);
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadGlyphicons();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
