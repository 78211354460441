// MOVED TO Typescript - RememberService

//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("rememberService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
//        var service = {
//            remember: function (variablename, variablevalue) {
//                var value = angular.isObject(variablevalue)
//                    ? angular.toJson(variablevalue)
//                    : variablevalue;

//                return p2DataTaskService.call(616, {
//                    variablename: variablename,
//                    variablevalue: value
//                });
//            },
//            getLastStatus: function (variableName, isObject) {
//                isObject = isObject || false;

//                var deferred = $q.defer();

//                var getResponseObject = function (response) {
//                    if (angular.isArray(response) !== true) return null;
//                    if (response.length < 1) return null;
//                    if (angular.isUndefined(response[0].variablevalue) === true) return null;
//                    if (angular.isString(response[0].variablevalue) !== true) return null;
//                    if (response[0].variablevalue.length < 1) return null;

//                    return angular.fromJson(response[0].variablevalue);
//                };

//                $ihttp.post({
//                    method: 973,
//                    parameters: {
//                        variablename: variableName
//                    }
//                }).then(function (response) {
//                    if (isObject !== true) {
//                        deferred.resolve(response);
//                    } else {
//                        deferred.resolve(getResponseObject(response));
//                    }
//                });

//                return deferred.promise;
//            }
//        };

//        return service;
//    }]);
//})();
