(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srOperationAr', {
        templateUrl: 'views/components/views/srOperationAr/srOperationAr.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'srOperationArService', 'watcherFactory', function($stateParams, stateService, modalService, srOperationArService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                custactNo: $stateParams.custact_no,
                filterValueBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                setting: {},
                selectListResources: [],
                itemsListResources: [],
                lockedSave: false
            };
    
            // ## IF CONDITIONS FUNCTIONS ##
    
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.setting[parmWithValue]) === true) return false;
                if (vm.model.setting[parmWithValue] === null) return false;
                if (vm.model.setting[parmWithValue].trim().length < 1) return false;
    
                return true;
            };
    
    		var validateNewOldValue = function (newVal, oldVal) {
    			if (angular.isUndefined(newVal) === true) return false;
    			if (angular.isUndefined(oldVal) === true) return false;
    			if (newVal === oldVal) return false;
    
    			return true;
    		};
    
    		var validateNewOldValueLength = function (newVal, oldVal) {
    			if (newVal.length > 16) return false;
    			if (oldVal.length > 16) return false;
    
    			return true;
    		};
    
            // ## LOAD PROCEDURE CALLS ##
    
            srOperationArService.loadSrOperationArGet(vm.model.custactNo).then(function (data) {
                vm.model.setting = angular.copy(data[0]);
    
                loadSrOperationArSelectResources();
                loadSrOperationArItemsResources();
            });
    
            var loadSrOperationArSelectResources = function () {
                vm.model.selectListResources = [];
    
                srOperationArService.loadSrOperationArResourceList().then(function (data) {
                    angular.copy(data, vm.model.selectListResources);
                });
            };
    
            var loadSrOperationArItemsResources = function () {
                if (validateParmsWithValue('resource_id_superior') !== true) return;
                if (validateParmsWithValue('first_alert_dt') !== true) return;
    
                vm.model.itemsListResources = [];
    
                srOperationArService.loadSrOperationArResourcesList(vm.model.setting).then(function (data) {
                    angular.copy(data, vm.model.itemsListResources);
                });
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.filterValueBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.filtervalue = '';
                        break;
                }
            };
    
            // ## TOGGLE FUNCTIONS ##
    
            vm.toggleChosen = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;
    
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrOperationAr = function () {
                vm.model.lockedSave = true;
                var selected = [];
    
                angular.forEach(vm.model.itemsListResources, function (item) {
    		    	if (angular.isUndefined(item.item_filtervalue) === true) return;
    		    	if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filtervalue.toLowerCase()) === '-1') return;
    		    	if (item.item_id === '0') return;
    		    	if (item.item_id === '-1') return;
    		    	if (item.item_is_selected === '0') return;
    
    		    	selected.push(item);
    		    });
    
                var mergeObj = vm.model.setting;
                mergeObj.records = selected.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; });
    
                srOperationArService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () {
                return vm.model.setting.resource_id_superior;
            }, function (newValue, oldValue) {
                if (validateNewOldValue(newValue, oldValue) !== true) return;
    
                loadSrOperationArItemsResources();
            });
    
            watcher.$watch(function () {
                return vm.model.setting.first_alert_dt;
            }, function (newValue, oldValue) {
                if (validateNewOldValue(newValue, oldValue) !== true) return;
                if (validateNewOldValueLength(newValue, oldValue) !== true) return;
    
                loadSrOperationArItemsResources();
            });
        }]
    });
})();