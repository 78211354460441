/******************************************************************
auth: bhw
description: 
mods:       
*******************************************************************/
(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderHeadService", ['$q', '$ihttp', 'p2DataTaskService', 'utilityService', function ($q, $ihttp, p2DataTaskService, us) {
        var service = {
            loadStockplaceList: function (businesscoNo) {
                return $ihttp.post({ method: 622, parameters: { businessco_no: businesscoNo } });
            },

            loadSellerList: function (businesscoNo) {
                return $ihttp.post({ method: 628, parameters: { businessco_no: businesscoNo } });
            },

            loadTaxList: function (countryId) {
                return $ihttp.post({ method: 640, parameters: { country_id: countryId } });
            },

            loadCustomerPersonsList: function (statCustNo) {
                return $ihttp.post({ method: 71, parameters: { cust_no: statCustNo } });
            },

            loadPaymentGet: function (paymentId) {
                return $ihttp.post({ method: 1131, parameters: { payment_id: paymentId } });
            },



            loadOrderMethodList: function () {
                return $ihttp.post({ method: 621, parameters: {} });
            },

            loadDeliveryMethodList: function (params) {
                return $ihttp.post({ method: 385, parameters: params || {} });
            },

            loadDeliveryTermList: function () {
                return $ihttp.post({ method: 623, parameters: {} });
            },

            loadPaymentList: function () {
                return $ihttp.post({ method: 624, parameters: {} });
            },

            loadFactoringList: function () {
                return $ihttp.post({ method: 625, parameters: {} });
            },

            loadPricelistList: function () {
                return $ihttp.post({ method: 627, parameters: {} });
            },

            loadValutaList: function () {
                return $ihttp.post({ method: 373, parameters: {} });
            },

            loadCollectionList: function () {
                return $ihttp.post({ method: 1218, parameters: { isactive: '1' } });
            },

            loadBusinesscoList: function () {
                return $ihttp.post({ method: 104, parameters: {} });
            },

            loadDepartmentList: function () {
                return $ihttp.post({ method: 105, parameters: {} });
            },

            loadPortalUserOrderList: function () {
                return $ihttp.post({ method: 1046, parameters: {} });
            },

            loadBusinessList: function () {
                return $ihttp.post({ method: 638, parameters: {} });
            },

            loadCoopList: function () {
                return $ihttp.post({ method: 639, parameters: {} });
            },

            loadInvoiceMethodSelectList: function () {
                return $ihttp.post({ method: 1766, parameters: {} });
            },

            loadCountryList: function () {
                return $ihttp.post({ method: 220, parameters: {} });
            },

            loadSpecialDiscount2List: function () {
                return $ihttp.post({ method: 3151, parameters: {} });
            },


            loadOrderDialogs: function (internal_no,) {
                return $ihttp.post({ method: 1281, parameters: { order_internal_no: internal_no } });
            },

            loadP2OrderLineList: function (internal_no, view_id) {
                // Prosedyre sp_ws_p2_orderline_list
                return $ihttp.post({ method: 523, parameters: { order_internal_no: internal_no, view_id: view_id } });
            },

            loadP2OrderLineListGrid: function (internal_no) {
                // Prosedyre sp_ws_p2_orderline_list_grid
                return $ihttp.post({ method: 2069, parameters: { order_internal_no: internal_no } });
            },

            loadP2OrderHeadGet: function (order_internal_no) {
                var deferred = $q.defer();
                console.log('Skal hente orderhead_get');
                
                // Prosedyre sp_ws_p2_orderhead_get
                $ihttp.post({ method: 617, parameters: { order_internal_no: order_internal_no } }).then(function (data) {
                    //console.dir(data[0]);
                    console.log('Har hentet orderhead_get');
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            
            loadOrderLineChangeMethodProvide: function (orderline_keyno, provide_method_id) {
                return p2DataTaskService.call(1138, {
                    orderline_keyno: orderline_keyno,
                    provide_method_id: provide_method_id
                });
            },
            loadOrderHeadLockStatusWs: function (order_internal_no) {
                return p2DataTaskService.call(1299, {
                    order_internal_no: order_internal_no
                });
            },
            scanConsignment: function (edit) {
                return $ihttp.post({
                    method: 321,
                    parameters: edit
                });
            },

            updateOrderLine: function (parms) {
                return p2DataTaskService.call(2070, parms);
            },

            loadCustomerGetEdit: function (cust_no) {
                return p2DataTaskService.call(67, {
                    cust_no: cust_no
                });
            },
            loadOrderHeadOrderPush: function (order_internal_no) {
                return p2DataTaskService.call(1366, {
                    order_internal_no: order_internal_no
                });
            },
            confirmPickJob: function (order_internal_no) {
                return p2DataTaskService.call(1585, {
                    order_internal_no: order_internal_no
                });
            },
            regretConfirmPickJob: function (order_internal_no) {
                return p2DataTaskService.call(1584, {
                    order_internal_no: order_internal_no
                });
            },
            generatePickJob: function (order_internal_no) {
                return p2DataTaskService.call(1586, {
                    order_internal_no: order_internal_no
                });
            },
            deliverOrder: function (parms) {
                return $ihttp.post({
                    method: 2965,
                    parameters: parms
                });
            },
            genericFunction: function (method_nr, parms) {
                return $ihttp.post({
                    method: method_nr,
                    parameters: parms
                });
            },
            loadOrderlineCalculation: function (orderline_keyno) {
                return $ihttp.post({ method: 3184, parameters: { orderline_keyno: orderline_keyno } });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 642,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
