(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('serviceObjects', {
        templateUrl: 'views/components/views/serviceObjects/serviceObjects.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'rememberService', 'serviceObjectsService', function ($stateParams, $q, stateService, utilityService, rememberService, serviceObjectsService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            
            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                itemsListServiceObjects: []
            };

            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                serviceObjectsService.loadServiceObjectsGet(vm.model.argType, vm.model.argValue).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadServiceObjects()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadServiceObjects = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_all) !== true) return;
    
                vm.model.itemsListServiceObjects = [];
                
                return serviceObjectsService.loadServiceObjectsList(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListServiceObjects);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // ## SEARCH FUNCTION ##
            
            vm.searchItems = function () {
                rememberFunc('searchtext');
                loadServiceObjects();
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'serviceobject':
                        go({ serviceobject_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadServiceObjects();
                        }
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadServiceObjects();
                        }
                        break;
                    case 'show_all':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_all) !== true) return;

                        vm.model.setting.show_all = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_all, true) !== true) return;

                        loadServiceObjects();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
