(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custGrps', {
        templateUrl: 'views/components/views/custGrps/custGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'custGrpsService', function (stateService, custGrpsService) {
            const vm = this;
            
            vm.model = {
                itemsListCustomerGroups: []
            };

            let loadCustomerGroups = function () {
                custGrpsService.listCustomerGroups().then(function (list) {
                    angular.copy(list, vm.model.itemsListCustomerGroups);
                });
            };
    		
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'custgrp':
                        go({ custgrp_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadCustomerGroups();
            };
        }]
    });
})();
