(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travExpTypeMenu', {
        templateUrl: 'views/components/views/travExpTypeMenu/travExpTypeMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'travExpTypeMenuService', function ($stateParams, stateService, travExpTypeMenuService) {
            
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let trexTypeId = $stateParams.trextype_id;
            
            vm.model = {
                menu: {}
            };
    
            // ## LOAD PROCEDURE CALLS ##

            let loadMenu = function () {
                travExpTypeMenuService.getTravelExpenceType({ trextype_id: trexTypeId }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'travexptype':
                    case 'travexptypebuscos':
                        go({ trextype_id: vm.model.menu.trextype_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteValues = function () {
                travExpTypeMenuService.deleteItem({ trextype_id: vm.model.menu.trextype_id }).then(function () {
                    stateService.back();
                });
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
