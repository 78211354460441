(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("printPreviewService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2ReportDef: function (p2_reportdef_keyno) {
                return p2DataTaskService.call(334, {
					p2_reportdef_keyno: p2_reportdef_keyno
				});
			}
        };

        return service;
    }]);
})();