<tt-page>
    <tt-page-heading ttModel="p2_m_bankreconciliation" [ttTranslate]="true"></tt-page-heading>
    <tt-card class="col-xs-12 p-5">
        <tt-input *ngIf="!!model.get" class="col-xs-2" ttLabel="company_name" ttLabelView="top" [ttModel]="model.get.company_name" ttReadonly></tt-input>
        <tt-datetime *ngIf="!!model.get" class="col-xs-2" ttLabel="balance_date" ttLabelView="top" [(ttModel)]="model.get.balance_date" (ttModelChange)="getBalance()" ttHideTime></tt-datetime>
        <tt-checkbox
            *ngIf="!!model.get"
            class="col-xs-2"
            ttLabel="show_transaction_details"
            ttLabelView="top"
            [(ttModel)]="model.get.show_transaction_details"
            (ttModelChange)="rememberShowTransactionDetails()"
        ></tt-checkbox>
        <tt-button class="col-xs-2" ttText="refresh" (ttClick)="getStatements()" ttIcon="fas fa-sync" [ttSpin]="model.fetchingStatements"></tt-button>
        <tt-button
            *ngIf="!!model.get && model.get.show_reset_match === '1'"
            class="col-xs-2"
            ttText="reset_match"
            (ttClick)="resetMatch()"
            ttIcon="fas fa-sync"
            ttType="danger"
            [ttSpin]="model.resettingMatch"
        ></tt-button>
        <div *ngIf="!!model.get && model.get.warning_message > ''" class="col-xs-12 p-2 tt-danger-background" [ngStyle]="style['warning']">{{ model.get.warning_message }}</div>
    </tt-card>
    <tt-card class="col-xs-12 p-5 flex-1 height-full" ttClass="bank-reconciliation__content height-full flex-1">
        <div class="bank-reconciliation__list-container">
                <h3 *ngIf="!!model.get" [ngStyle]="style['listHeading']">{{ model.get.bankaccount_name }}</h3>
            <tt-input *ngIf="model.get !== null" ttLabel="searchtext_bank" ttLabelView="top" [(ttModel)]="model.get.searchtext_bank" (ttModelChange)="filterBankTransactions($event)"></tt-input>
            <ul #bankTransactionsList class="bank-reconciliation__list tt-scrollbar">
                <ng-container *ngFor="let item of bankTransactions | ttBankReconciliationFilter : model.get?.searchtext_bank">
                    <tt-bank-reconciliation-item
                        [ttDate]="item.ValueDate"
                        [ttAmount]="+item.Amount"
                        [ttNote]="item.Description"
                        [ttSubNote]="model.get?.show_transaction_details === '1' ? item.transaction_details : ''"
                        [(ttSelected)]="item.is_selected"
                        [ttHighlight]="item.highlight"
                        (ttSelectedChange)="clearHighlights(); sumBankRows(); sumAccountingRows(); setHighlights()"
                    ></tt-bank-reconciliation-item>
                </ng-container>
            </ul>
        </div>
        <div class="bank-reconciliation__center">
            <div class="bank-reconciliation__difference">
                <span class="bank-reconciliation__difference-label" [ngStyle]="style['differenceLabel']">
                    <span>{{ translations["selected_rows_bank"] }}: </span>
                    <span> {{ model.sum_selected_rows_bank_label }}</span>
                </span>
                <span class="bank-reconciliation__difference-label" [ngStyle]="style['differenceLabel']">
                    <span>{{ translations["selected_rows_accounting"] }}: </span>
                    <span> {{ model.sum_selected_rows_accounting_label }}</span>
                </span>
                <span class="bank-reconciliation__difference-label" [ngStyle]="style['differenceLabel']">
                    <span> Differanse: </span>
                    <span> {{ model.difference_label }}</span>
                </span>
                <tt-button *ngIf="checkShowMatch() === true" ttText="match" ttType="success" (ttClick)="processMatch()" [ttLoading]="model.processingMatch"></tt-button>
                <tt-button *ngIf="checkShowMatch() === false" ttText="add_to_posting" (ttClick)="openGridBooking()"></tt-button>
            </div>
            <div>
                <span
                    *ngIf="checkBankTransactionsHighlight()"
                    [matTooltip]="translations['potential_match_indicator_left_side'] || ''"
                    class="far fa-arrow-square-left bank-reconciliation__highlight-indicator"
                ></span>
                <span
                    *ngIf="checkAccountingRecordsHighlight()"
                    [matTooltip]="translations['potential_match_indicator_right_side'] || ''"
                    class="far fa-arrow-square-right bank-reconciliation__highlight-indicator"
                ></span>
            </div>
        </div>
        <div class="bank-reconciliation__list-container">
                <h3 *ngIf="!!model.get" [ngStyle]="style['listHeading']">{{ model.get.account_no_and_name }}</h3>
            <tt-input
                *ngIf="model.get !== null"
                ttLabel="searchtext_acledger"
                ttLabelView="top"
                [(ttModel)]="model.get.searchtext_acledger"
                (ttModelChange)="filterAccountingRecords($event)"
            ></tt-input>
            <ul #accountingRecordsList class="bank-reconciliation__list tt-scrollbar">
                <ng-container *ngFor="let item of accountingRecords | ttBankReconciliationFilter : model.get?.searchtext_acledger">
                    <tt-bank-reconciliation-item
                        [ttDate]="item.transdate"
                        [ttAmount]="+item.amount_locval"
                        [ttNote]="item.note"
                        [ttSubNote]="model.get?.show_transaction_details === '1' ? item.transaction_details : ''"
                        [(ttSelected)]="item.is_selected"
                        [ttHighlight]="item.highlight"
                        (ttSelectedChange)="clearHighlights(); sumAccountingRows(); sumBankRows(); setHighlights()"
                    ></tt-bank-reconciliation-item>
                </ng-container>
            </ul>
        </div>
    </tt-card>
</tt-page>
