(function () {
	'use strict';

	let module = angular.module('imApp');

	module.component('baseCurrencies', {
		templateUrl: 'views/components/views/baseCurrencies/baseCurrencies.template.html?v=' + module.version,
		controllerAs: 'vm',
		controller: ['baseCurrenciesService', function (baseCurrenciesService) {
			const vm = this;

			vm.model = {
				itemsListBaseCurrencies: []
			};

            let loadBaseCurrencies = function () {
                baseCurrenciesService.listBaseCurrencies().then(function (list) {
                    angular.copy(list, vm.model.itemsListBaseCurrencies);
                });
            };

            vm.$onInit = function () {
                loadBaseCurrencies();
            };
		}]
	});
})();
