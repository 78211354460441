(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('shiftDayTime', {
        templateUrl: 'views/components/views/shiftDayTime/shiftDayTime.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'shiftDayTimeService', 'shiftService', 'economyService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, shiftDayTimeService, shiftService, economyService) {
            let vm = this;
            let shiftTimeKeyno = $stateParams.shifttime_keyno;
            let shiftId = $stateParams.shift_id;
            let dayNo = $stateParams.dayno;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {
                    shift_id: shiftId
                },
            	selectListSalaryTypes: [],
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                let deferred = $q.defer();

                shiftDayTimeService.getEdit({
                    shifttime_keyno: shiftTimeKeyno,
                    shift_id: shiftId,
                    dayno: dayNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadWeekDays()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadSalaryTypes = function () {
                economyService.listSalaryTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListSalaryTypes);
                });
            };

            let loadWeekDays = function () {
                let itemsListWeekDays = [];

                shiftService.listWeekDays({ shift_id: shiftId }).then(function (result) {
                    angular.forEach(result, function (item) {
                        itemsListWeekDays[item.item_id] = item;
                    });

                    vm.model.edit.day_name = itemsListWeekDays[dayNo].item_name;
                });
            };

            vm.saveChanges = function () {
                var d = {};
                var keys = Object.keys(vm.model.edit);

                angular.forEach(keys, function (key) {
                    d[key] = vm.model.edit[key];
                });

                d['shift_id'] = $stateParams.shift_id;
                d['dayno'] = $stateParams.dayno;

                shiftDayTimeService.saveObj(d).then(function () {
                    stateService.back();
                });
            };

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    shiftDayTimeService.deleteItem({ shifttime_keyno: vm.model.edit.shifttime_keyno }).then(function (response) {
                        if (response[0].status_no !== '0') {
                    		modalService.show({
                    			type: 'warning',
                    			title: translations.error,
                                message: response[0].status_message,
                    			buttons: [{
                    				label: translations.ok,
                    				cssClass: 'btn-warning',
                    				action: function (dialogItself) {
                    					dialogItself.close();
                    					vm.model.lockedDelete = false;
                    				}
                    			}]
                    		});
                    	} else {
                    		vm.model.lockedDelete = false;
                    		stateService.back();
                    	}
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'in_time':
                        if (utilityService.validateWatchValue(value, vm.model.edit.in_time) !== true) return;

                        vm.model.edit.in_time = value;

                        if (utilityService.validateParmsValue(vm.model.edit.in_time) !== true) return;

                        vm.model.edit.in_from_time = vm.model.edit.in_time;
                        vm.model.edit.in_to_time = vm.model.edit.in_time;
                        break;
                    case 'out_time':
                        if (utilityService.validateWatchValue(value, vm.model.edit.out_time) !== true) return;

                        vm.model.edit.out_time = value;

                        if (utilityService.validateParmsValue(vm.model.edit.out_time) !== true) return;

                        vm.model.edit.out_from_time = vm.model.edit.out_time;
                        vm.model.edit.out_to_time = vm.model.edit.out_time;
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadSalaryTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
