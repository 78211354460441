(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageSubscription', {
        templateUrl: 'views/components/views/webpageSubscription/webpageSubscription.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'webpageSubscriptionService', function ($stateParams, webpageSubscriptionService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListWebPageSubscriptions: []
            };

            let loadSetting = function () {
                webpageSubscriptionService.getWebPageSubscription().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadWebPageSubscriptions = function () {
                webpageSubscriptionService.listWebPageSubscriptions({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebPageSubscriptions);
                });
            };

            vm.$onInit = function () {
                loadSetting();
                loadWebPageSubscriptions();
            };
        }]
    });
})();
