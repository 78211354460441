(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactCalcEditService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            loadCustactCompPricesRefresh: function (custact_no) {
                return p2DataTaskService.call(1128, {
                    custact_no: custact_no
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2CustactWorkSumList: function (custact_no) {
                var deferred = $q.defer();

                var parmsP2CustactWorkSumList = {
                    method: 596,
                    parameters: {
                        custact_no: custact_no
                    }
                };

                $ihttp.post(parmsP2CustactWorkSumList).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            getWorkTime: function (parms) {
                return $ihttp.post({
                    method: 2163,
                    parameters: parms || {}
                });
            },
            saveP2CustactSaveWCustactCalcEdit: function (p2CustactGet) {
                var deferred = $q.defer();

                var parmsP2CustactSaveWCustactCalcEditSave = {
                    method: 594,
                    parameters: p2CustactGet
                };

                $ihttp.post(parmsP2CustactSaveWCustactCalcEditSave).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            updateFromBasicsObj: function (edit) {
                return $ihttp.post({
                    method: 2844,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();