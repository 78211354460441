(function () {
    'use strict';

    var module = angular.module("imApp");

    function pad(num, size) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }


    module.factory("custactPlanningService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            store: {},
            loadCustactPlanningGet: function () {
                return p2DataTaskService.call(1358, {});
            },

            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },

            loadLabours: function () {
                var deferred = $q.defer();

                var parms_labour_list = {
                    method: 305,
                    parameters: {
                        activestatus: 'ACT'
                    }
                };

                $ihttp.post(parms_labour_list).then(function (data) {
                    var records = {};
                    var labels = {};

                    angular.forEach(data, function (item, i) {
                        records[item.labour_no] = {
                            labour_no: item.labour_no,
                            labour_name: item.labour_name,
                            labour_short: item.labour_initial,
                            inscope: 1
                        };

                        labels[i] = {
                            labelitem_index: i,
                            labelitem_keyno: item.labour_no,
                            labelitem_label: item.labour_name,
                            labelitem_short: item.labour_initial,
                            labelitem_image: '',
                            labelitem_noimage: 'user',
                            labelitem_inscope: 1
                        };
                    });

                    deferred.resolve({records: records, labels: labels});
                });

                return deferred.promise;

            },

            loadJobs: function (iargtype, iargvalue) {
                var deferred = $q.defer();

                if (iargtype != '' && iargvalue != ''){
                    $ihttp.post({
                        method: 300,
                        parameters: {
                            argtype: iargtype,
                            argvalue: iargvalue
                        }
                    }).then(function (data) {
                        var records = {};
                        var labels = {};

                        angular.forEach(data, function (item, i) {
                            records[item.custact_no] = {
                                job_no: item.custact_no,
                                job_name: item.custact_note,
                                job_short: item.custact_note,
                                inscope: item.inscope
                            };

                            labels[i] = {
                                labelitem_index: i,
                                labelitem_keyno: item.custact_no,
                                labelitem_label: item.custact_note,
                                labelitem_short: item.custact_note,
                                labelitem_image: '',
                                labelitem_noimage: 'cogs',
                                labelitem_inscope: item.inscope
                            };
                        });

                        deferred.resolve({records: records, labels: labels});
                    });
                } else {
                    var data = [
                        { job_type: 'eksempel', job_no: 1, job_name: 'eksempel', job_zip_code: '' },
                    ];

                    var records = {};
                    var labels = {};

                    angular.forEach(data, function (item, i) {
                        records[item.job_no] = item;

                        labels[i] = {
                            labelitem_index: i,
                            labelitem_keyno: item.job_no,
                            labelitem_label: item.job_name,
                            labelitem_image: '',
                            labelitem_noimage: 'cogs',
                            labelitem_inscope: 1
                        };
                    });

                    deferred.resolve({ records: records, labels: labels });
                }

                return deferred.promise;
            },

            loadMachines: function(){
                var deferred = $q.defer();

                $ihttp.post({
                    method: 30,
                    parameters: {}
                }).then(function (data) {
                    var records = {};
                    var labels = {};

                    angular.forEach(data, function (item, i) {
                        records[item.item_id] = {
                            equipment_id: item.item_id,
                            equipment_name: item.item_name,
                            equipment_short: item.item_name,
                            inscope: 1
                        };

                        labels[i] = {
                            labelitem_index: i,
                            labelitem_keyno: item.item_id,
                            labelitem_label: item.item_name,
                            labelitem_short: item.item_name,
                            labelitem_image: '',
                            labelitem_noimage: 'truck',
                            labelitem_inscope: 1
                        };
                    });

                    deferred.resolve({ records: records, labels: labels });
                });

                return deferred.promise;

            },

            loadTimescales: function (count,ifom_date,idirection) {
                var deferred = $q.defer();

                ifom_date = ifom_date || "today";
                idirection = idirection || "";

                $ihttp.post({
                    method: 303,
                    parameters: {
                        nbr_of_columns: count,
                        fom_date: ifom_date,
                        direction: idirection
                    }
                }).then(function (data) {
                    var records = {};

                    angular.forEach(data, function (item, i) {
                        records[item.timescale_value] = {
                            timescale_value: item.timescale_value,
                            timescale_label: item.timescale_label,
                            timescale_heading_color: item.timescale_heading_color,
                            timescale_heading_backcolor: item.timescale_heading_backcolor,
                            is_visible: 1
                        };
                    });

                    deferred.resolve(records);
                });

                return deferred.promise;
            },

            loadJobTasks: function (iargtype, iargvalue, ifom_date) {
                var deferred = $q.defer();

                if (iargtype != '' && iargvalue != '') {
                    ifom_date = ifom_date || '';

                    // Prosedyre kall sp_ws_p2_customer_get
                    $ihttp.post({
                        method: 302,
                        parameters: {
                            argtype: iargtype,
                            argvalue: iargvalue,
                            fom_date: ifom_date
                        }
                    }).then(function (data) {
                        var records = [];

                        angular.forEach(data, function (item, i) {
                            if (item.custactplan_keyno == '') item.custactplan_keyno = null;
                            if (item.custact_no == '') item.custact_no = null;
                            if (item.equipment_id == '') item.equipment_id = null;
                            if (item.labour_no == '') item.labour_no = null;
                            if (!item.note) item.note = '';

                            records.push({
                                jobtask_keyno: item.custactplan_keyno,
                                job_no: item.custact_no,
                                labour_no: item.labour_no,
                                equipment_id: item.equipment_id,
                                jobtime_scalevalue: item.jobtime_scalevalue,
                                jobtime_start_dt: item.jobtime_start_dt,
                                jobtime_end_dt: item.jobtime_end_dt,
                                jobtime_timelabel: item.jobtime_timelabel,
                                deleted: 0,
                                changed: 0,
                                note: item.note
                            });
                        });

                        deferred.resolve(records);
                    });
                } else {
                    var itemCount = 0;
                    var records = [];
                    var i = 0;

                    // BJS 20220216 - Forstår ikke helt poenget med denne siden itemCount alltid er 0 og denne derfor aldri vil slå til.
                    for (i = 0; i < itemCount; i++) {
                        var r_job = Math.floor((Math.random() * 10) + 1);
                        var r_labour = Math.floor((Math.random() * 10) + 1);
                        var r_equipment = Math.floor((Math.random() * 10) + 1);
                        var r_day = Math.floor((Math.random() * 2) + 30);

                        records.push({
                            jobtask_keyno: i,
                            job_no: r_job,
                            labour_no: r_labour,
                            equipment_id: r_equipment,
                            jobtime_scalevalue: '2017-03-' + r_day,
                            jobtime_start_dt: '2017-03-'+r_day+' 08:00',
                            jobtime_end_dt: '2017-03-' + r_day + ' 09:00',
                            jobtime_timelabel: '08:00 - 09:00',
                            deleted: 0
                        });
                    }

                    deferred.resolve(data);
                }

                return deferred.promise;
            },

            saveJobTasks: function (records) {
                var deferred = $q.defer();
                var recordsToSave = [];

                angular.forEach(records, function(item){
                    item.note = item.note || '';
                    item.changed = item.changed || 1;

                    recordsToSave.push({
                        custactplan_keyno: item.jobtask_keyno, 
                        job_no: item.job_no, 
                        labour_no: item.labour_no, 
                        equipment_id: item.equipment_id, 
                        jobtime_scalevalue: item.jobtime_scalevalue, 
                        jobtime_start_dt: item.jobtime_start_dt, 
                        jobtime_end_dt: item.jobtime_end_dt, 
                        deleted: item.deleted,
                        changed: item.changed,
                        note: item.note 
                    });
                });

                $ihttp.post({
                    method: 301,
                    parameters: {
                        custactplannings: recordsToSave
                    }
                }).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
