(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListItemTws', {
        templateUrl: 'views/components/views/checkListItemTws/checkListItemTws.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'checkListItemTwsService', function ($stateParams, $q, stateService, utilityService, checkListItemTwsService) {
            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListLinksToForms: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();
                
                checkListItemTwsService.getLinkToForm({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadLinksToForms()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadLinksToForms = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                
                vm.model.itemsListLinksToForms = [];
                
                checkListItemTwsService.listLinksToForms({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListLinksToForms);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'checklistitemtw':
                        go({
                            argtype: vm.model.setting.argtype,
                            argvalue: vm.model.setting.argvalue,
                            checklistitemtw_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
