(function () {
    'use strict';

    let module = angular.module('imApp');

    module.factory("modalFavoritesFactory", ['$q', '$uibModal', function ($q, $uibModal) {
        function modalFavorites(options) {
            options = options || {};

            this.model = {
                placeholder: options.placeholder || '',
                placeholderError: options.placeholderError || '',
                placeholderRename: options.placeholderRename || '',
                header: options.header || '',
                list: options.list || []
            };

            this.instance = null;
        };

        modalFavorites.prototype.show = function () {
            var deferred = $q.defer();

            var vm = this;

            this.instance = $uibModal.open({
                backdrop: true,
                animation: true,
                keyboard: false,
                templateUrl: 'views/modal/favoritesModal.html?v=' + module.version,
                controller: ['$scope', '$uibModalInstance', 'translateService', 'stateService', function ($scope, $uibModalInstance, translateService, stateService) {
                    $scope.model = vm.model;
                    $scope.model.field = '';
                    $scope.model.filter = '';
                    $scope.model.addedFavorite = false;
                    $scope.model.addedIndex = -1;
                    $scope.model.path = {};
                    $scope.model.placeholder = '';
                    $scope.model.filterplaceholder = 'filterplaceholder';

                    translateService.translate('filterplaceholder').then(function (response) {
                        $scope.model.filterplaceholder = response;
                    });

                    if ($scope.model.header.translate === true) {
                        translateService.translate($scope.model.header.text).then(function (response) {
                            $scope.initializeValues(response);
                        });
                    } else {
                        $scope.initializeValues($scope.model.header.text);
                    }

                    $scope.initializeValues = function (displayText) {
                        $scope.model.header.displayText = displayText;
                        $scope.model.placeholder = $scope.model.header.displayText;
                        $scope.model.path.state = stateService.getCurrentName();
                        $scope.model.path.parms = stateService.getCurrentPath().slice($scope.model.path.state.length + 2);

                        for (var i = 0; i < $scope.model.list.length; i++) {
                            $scope.model.list[i].hasUpdate = false;
                            if ($scope.model.list[i].item_name === $scope.model.header.displayText
                                && $scope.model.list[i].item_state === $scope.model.path.state
                                && $scope.model.list[i].item_parms === $scope.model.path.parms) {
                                $scope.model.addedFavorite = true;
                                $scope.model.addedIndex = i;
                            }
                        }
                    }

                    $scope.close = function () {
                        $uibModalInstance.close();

                        deferred.resolve(vm.model);
                    };

                    $scope.addToFavorites = function () {
                        $scope.model.addedFavorite = true;
                        $scope.model.addedIndex = 0;

                        $scope.model.list.unshift({
                            item_name: $scope.model.field === '' ? $scope.model.header.displayText : $scope.model.field,
                            item_state: $scope.model.path.state,
                            item_parms: $scope.model.path.parms,
                            orderby: 0,
                            willGetDeleted: false,
                            hasUpdate: false
                        });
                    };

                    $scope.toggleDeleteFromFavorites = function (e, item) {
                        item.willGetDeleted = !item.willGetDeleted;
                        e.stopPropagation();
                    };

                    $scope.renameFavorite = function (e, item, index) {
                        e.stopPropagation();

                        $scope.model.list[index].item_name = $scope.model.field;
                        $scope.model.list[index].hasUpdate = true;
                        $scope.close();
                    };

                    $scope.goTo = function (e, item) {
                        stateService.go(item.item_state, item.item_parms);
                        $scope.close();
                    };

                    $scope.goTab = function (e, item) {
                        e.stopPropagation();

                        stateService.newTab(item.item_state, item.item_parms);
                        $scope.close();
                    };

                    $scope.$on('modal.closing', function (event, reason) {
                        switch (reason) {
                            case "backdrop click":
                            case "escape key press":
                            case "cancel":
                                event.preventDefault();
                                $scope.close();
                                break;
                        }
                    });
                }]
            });

            return deferred.promise;
        };

        modalFavorites.prototype.hide = function () {
            if (angular.isUndefined(this.instance)) return;
            if (this.instance === null) return;

            this.instance.close();

            this.instance = null;
        };

        modalFavorites.$create = function (options) {
            return new modalFavorites(options);
        };

        return modalFavorites;
    }]);
})();
