(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactCompsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2CustactGet: {},
            p2CustactCompList: {
                records: []
            },
            loadP2CustactGet: function (custact_no) {
                var deferred = $q.defer();

                var parmsP2CustactGet = {
                    method: 28,
                    parameters: {
                        custact_no: custact_no
                    }
                };

                $ihttp.post(parmsP2CustactGet).then(function (data) {
                    service.p2CustactGet = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            loadP2CustactCompList: function (custact_no, select_status) {
                var deferred = $q.defer();

                var parmsP2CustactCompList = {
                    method: 368,
                    parameters: {
                        custact_no: custact_no,
                        select_status: select_status
                    }
                };

                $ihttp.post(parmsP2CustactCompList).then(function (data) {
                    angular.copy(data, service.p2CustactCompList.records);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            saveP2CustactCompIsSelected: function (item) {
                var deferred = $q.defer();

                var parmsP2CustactCompIsSelectedSave = {
                    method: 371,
                    parameters: {
                        custactcomp_keyno: item.item_id,
                        item_is_selected: item.item_is_selected
                    }
                };

                $ihttp.post(parmsP2CustactCompIsSelectedSave).then(function (data) {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
