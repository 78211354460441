(function () {
    'use strict';

    angular.module('imApp').factory('ttItemService', ['$rootScope', '$compile', '$q', 'layoutService', 'utilityService', 'userService', 'eventService', 'responsive', function ($rootScope, $compile, $q, layoutService, us, userService, eventService, responsive) {
        var groups = {};

        var getId = function (commonId) {
            var parts = commonId.split('.');

            return {
                group: parts[0],
                field: parts[1]
            }
        };

        var initGroup = function (groupId) {
            var deferred = $q.defer();

            if (angular.isUndefined(groups[groupId])) {
                groups[groupId] = {
                    state: 'initializing',
                    defers: [],
                    active: false,
                    fields: {}
                };

                groups[groupId].defers.push(deferred);

                layoutService.getFieldInfo(groupId).then(function (list) {
                    angular.forEach(list, function (field) {
                        groups[groupId].fields[field.field_id] = {
                            info: field,
                            onChange: {}
                        };
                    });

                    groups[groupId].state = 'ready';

                    var defers = groups[groupId].defers.splice(0);

                    angular.forEach(defers, function (def) {
                        def.resolve();
                    });
                });
            } else {
                if (groups[groupId].state === 'initializing') {
                    groups[groupId].defers.push(deferred);
                } else {
                    deferred.resolve();
                }
            }

            return deferred.promise;
        };

        var addCleanup = function (scope) {
            if (angular.isObject(scope) !== true)
                return;
            if (angular.isFunction(scope.$on) !== true)
                return;

            scope.$on('$destroy', function (event) {
                if (angular.isUndefined(event.currentScope.ttItemServiceVars))
                    return;
                if (angular.isArray(event.currentScope.ttItemServiceVars.onChangeInfo) !== true)
                    return;

                angular.forEach(event.currentScope.ttItemServiceVars.onChangeInfo, function (info) {
                    if (angular.isUndefined(groups[info.groupId]))
                        return;
                    if (angular.isUndefined(groups[info.groupId].fields[info.fieldId]))
                        return;
                    if (angular.isUndefined(groups[info.groupId].fields[info.fieldId].onChange[info.handlerId]))
                        return;

                    delete groups[info.groupId].fields[info.fieldId].onChange[info.handlerId];
                });
            });
        };

        var service = {
            // scope, element, attr => registerDefault
            // scope, id, onChange  => registerCustom
            // scope, onChange      => registerCustom
            register: function (arg1, arg2, arg3) {
                return angular.isUndefined(arg3) || angular.isString(arg2)
                    ? registerCustom(arg1, arg2)
                    : registerDefault(arg1, arg2, arg3);
            },

            toggleActive: function (groupId) {
                if (angular.isUndefined(groups[groupId]))
                    return;

                groups[groupId].active = !groups[groupId].active;

                angular.forEach(groups[groupId].fields, function (field) {
                    angular.forEach(field.onChange, function (handler) {
                        if (angular.isFunction(handler)) {
                            handler({
                                type: 'active',
                                active: groups[groupId].active,
                                info: field.info
                            });
                        }
                    });
                });
            },

            toggleEnabled: function (id) {
                var tmpId = getId(id);

                var groupId = tmpId.group;
                var fieldId = tmpId.field;

                if (angular.isUndefined(groups[groupId]))
                    return $q.resolve();

                var deferred = $q.defer();
                groups[groupId].fields[fieldId].info.enabled = us.toBoolean(groups[groupId].fields[fieldId].info.enabled, false) === true ? '0' : '1';
                layoutService.updateFieldInfo(groups[groupId].fields[fieldId].info).then(function (info) {
                    angular.copy(info, groups[groupId].fields[fieldId].info);
                    $rootScope.$broadcast('ttItemService:fieldInfoToggle', info);

                    deferred.resolve(info);
                });

                return deferred.promise;
            },

            getGroupInfo: function (groupId) {
                var deferred = $q.defer();

                initGroup(groupId).then(function () {
                    deferred.resolve(groups[groupId].fields);
                });

                return deferred.promise;
            }
        };

        var generateId = function (scope) {
            if (angular.isDefined(scope.ttItemId) && angular.isString(scope.ttItemId)) {
                return scope.ttItemId;
            }

            if (angular.isUndefined(scope.ttGroupId) && angular.isString(scope.ttGroupId) !== true)
                return null;

            var ttItemId = scope.ttGroupId + '.';

            if (angular.isDefined(scope.ttFieldId) && angular.isString(scope.ttFieldId))
                return ttItemId += scope.ttFieldId;

            if (angular.isDefined(scope.label) && angular.isString(scope.label))
                return ttItemId += scope.label;

            if (angular.isDefined(scope.dataid) && angular.isString(scope.dataid))
                return ttItemId += scope.dataid;

            if (angular.isDefined(scope.dataname) && angular.isString(scope.dataname))
                return ttItemId += scope.dataname;

            return null;
        };

        var registerDefault = function (scope, element, attr) {
            var isVisible = function (info) {
                if (angular.isUndefined(info)) {
                    info = scope.ttInfo;
                }

                var visible = false;

                if (angular.isDefined(info) && us.toBoolean(info.enabled, true) === true) {
                    if (info.usergroups.length > 0) {
                        for (var i = 0; i < info.usergroups.length; i++) {
                            if (angular.isUndefined(userService.userGroups['_' + info.usergroups[i].usergroup_keyno]))
                                continue;

                            visible = true;

                            break;
                        }
                    } else {
                        visible = true;
                    }

                    if (visible === true && angular.isDefined(info.sizes) && info.sizes.length > 0) {
                        var parts = info.sizes.split(' ');

                        visible = false;

                        for (var j = 0; j < parts.length; j++) {
                            if (parts[j] !== responsive.current)
                                continue;

                            visible = true;
                            break;
                        }
                    }
                }

                return visible;
            };

            var showHide = function (visible) {
                if (visible === true) {
                    attr.$removeClass('ng-hide');
                } else {
                    attr.$addClass('ng-hide');
                }

                if (scope?.ttInfo?.ttItemId) {
                    eventService.trigger('ttItemService:visiblityChanged', { ttItemId: scope.ttInfo.ttItemId, visible: visible }, true);
                }
            }

            var btnElements;

            var toggleButtonElements = function () {
                if (angular.isDefined(btnElements) === true) {
                    btnElements.remove();
                    btnElements = undefined;
                } else {
                    btnElements = $compile('' +
                        '<div class="col-xs-12 sp-0">' +
                        '   <div class="pull-right" tt-item-usergroup-manager="ttInfo"></div>' +
                        '   <tt-item-size-manager class="pull-right" tt-info="ttInfo" style="padding-right: 2px;"></tt-item-size-manager>' +
                        '</div>')(scope);

                    element.prepend(btnElements);
                }
            };

            var toggleEnabled = function (info) {
                attr.$removeClass('item-active-off');
                attr.$removeClass('item-active-on');
                attr.$removeClass('item-active-on-group');

                if (us.toBoolean(info.enabled) === true) {
                    if (angular.isUndefined(info.sizes)) {
                        info.sizes = '';
                    }

                    if (info.usergroups.length > 0 || info.sizes.length > 0) {
                        attr.$addClass('item-active-on-group');
                    } else {
                        attr.$addClass('item-active-on');
                    }
                } else {
                    attr.$addClass('item-active-off');
                }

                eventService.trigger('ttItem:toggledEnabled', info);
            };

            var clickHandler = function (e) {
                e.preventDefault();
                e.stopPropagation();
                service.toggleEnabled(scope.ttItemId).then(toggleEnabled);
            };

            var changeHandler = function (args) {
                switch (args.type) {
                    case 'active':
                        toggleButtonElements();
                        toggleEnabled(args.info);

                        var visible = false;

                        if (us.toBoolean(args.active) === true) {
                            visible = true;

                            element.on('click', clickHandler);
                        } else {
                            attr.$removeClass('item-active-off');
                            attr.$removeClass('item-active-on');
                            attr.$removeClass('item-active-on-group');

                            visible = isVisible(args.info);

                            element.off('click', clickHandler);
                        }

                        showHide(visible);

                        eventService.trigger('ttItem:toggledActive', args);
                        eventService.trigger('ttIf:all', { groupId: args.info.group_id, visible: us.toBoolean(args.active) });

                        break;
                }
            }

            var id = generateId(scope);

            if (id === null)
                return $q.resolve(null);

            var cid = getId(id);

            var dereg1 = eventService.on('layoutService:fieldInfo:changed', function (info) {
                if (info.group_id === cid.group && info.field_id === cid.field) {
                    toggleEnabled(info);
                }
            });

            var dereg2 = eventService.on('ttItemUsergroupManager:toggle', function (ugToggleData) {
                if (ugToggleData.field.group_id === cid.group && ugToggleData.field.field_id === cid.field) {
                    toggleEnabled(scope.ttInfo);
                }
            });

            var dereg3 = eventService.on('ttItemSizeManager:toggle', function (ttItemSizeManager) {
                if (ttItemSizeManager.field.group_id === cid.group && ttItemSizeManager.field.field_id === cid.field) {
                    toggleEnabled(scope.ttInfo);
                }
            });

            var dereg4 = eventService.on('event:responsive-breakpoint', function (data) {
                showHide(isVisible());
            });

            scope.$on('$destroy', function () {
                if (angular.isFunction(dereg1)) { dereg1(); }

                if (angular.isFunction(dereg2)) { dereg2(); }

                if (angular.isFunction(dereg3)) { dereg3(); }

                if (angular.isFunction(dereg4)) { dereg4(); }
            });

            scope.ttItemId = id;

            var deferred = $q.defer();

            service.register(scope, changeHandler).then(function (info) {
                scope.ttInfo = info;
                scope.ttInfo.ttItemId = scope.ttItemId;

                var visible = isVisible(info);

                showHide(visible);

                if (groups[info.group_id].active === true) {
                    angular.forEach(groups[info.group_id].fields[info.field_id].onChange, function (handler) {
                        if (angular.isFunction(handler)) {
                            handler({
                                type: 'active',
                                active: true,
                                info: groups[info.group_id].fields[info.field_id].info
                            });
                        }
                    });
                }

                $q.resolve(info);
            });

            return deferred.promise;
        };

        var registerCustom = function (scope, id, onChange) {
            if (angular.isObject(scope) !== true)
                return $q.resolve();
            if (angular.isFunction(scope.$on) !== true)
                return $q.resolve();

            if (angular.isFunction(id)) {
                onChange = id;
                id = scope.ttItemId;
            }

            if (angular.isFunction(onChange) !== true)
                return $q.resolve();

            var deferred = $q.defer();

            var tmpId = getId(id);

            var groupId = tmpId.group;
            var fieldId = tmpId.field;

            initGroup(groupId).then(function () {
                if (angular.isUndefined(groups[groupId].fields[fieldId])) {
                    groups[groupId].fields[fieldId] = {
                        info: {
                            keyno: 0,
                            group_id: groupId,
                            field_id: fieldId,
                            enabled: '1',
                            usergroups: []
                        },
                        onChange: {}
                    }
                }

                if (angular.isUndefined(scope.ttItemServiceVars)) {
                    scope.ttItemServiceVars = {};
                }

                if (angular.isUndefined(scope.ttItemServiceVars.onChangeInfo)) {
                    scope.ttItemServiceVars.onChangeInfo = [];
                }

                var handlerId = uuid();

                groups[groupId].fields[fieldId].onChange[handlerId] = onChange;

                scope.ttItemServiceVars.onChangeInfo.push({
                    groupId: groupId,
                    fieldId: fieldId,
                    handlerId: handlerId
                });

                addCleanup(scope);

                deferred.resolve(groups[groupId].fields[fieldId].info);
            });

            return deferred.promise;
        };

        return service;
    }]);
})();
