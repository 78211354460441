(function () {
    'use strict';

    var module = angular.module('imApp');

    module.factory('headerModel', ['homeButtonModel', 'bigButtonModel', 'backButtonModel', function (homeButtonModel, bigButtonModel, backButtonModel) {
        // constructor, with class name
        function header(data, stateParams) {
            data = data || {};

            if (angular.isFunction(data.translate)) {
                this.translate = data.translate(stateParams);
            } else {
                this.translate = angular.isDefined(data.translate) ? data.translate : true;
            }

            this.text = data.text || '';
            this.visible = angular.isDefined(data.visible) ? data.visible : true;
            this.backButton = backButtonModel.build(data.backButton || {}, stateParams);
            this.bigButton = bigButtonModel.build(data.bigButton || {});
            this.homeButton = homeButtonModel.build(data.homeButton || {});
        };

        // static method
        header.build = function (data, stateParams) {
            return new header(data, stateParams);
        };

        return header;
    }]);
})();