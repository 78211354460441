(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipmentGrps', {
        templateUrl: 'views/components/views/equipmentGrps/equipmentGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'equipmentGrpsService', function (stateService, equipmentGrpsService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            vm.model = {
                itemsListEquipmentGroups: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEquipmentGroupViews = function () {
                equipmentGrpsService.listEquipmentGrpViews().then(function (result) {
                    angular.copy(result, vm.model.itemsListEquipmentGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'equipmentgrp':
                        go({ equipmentgrp_id: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEquipmentGroupViews();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
