(function () {
    'use strict';

    angular.module("imApp").factory("factoryService", ['$ihttp', function ($ihttp) {
        var service = {
            getFactory: function (factoryId) {
                return $ihttp.post({
                    method: 2027,
                    parameters: {
                        factory_id: factoryId
                    }
                });
            },
            saveObj: function (editFactory) {
                return $ihttp.post({
                    method: 2028,
                    parameters: editFactory
                });
            }
        };

        return service;
    }]);
})();