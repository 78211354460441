(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poFinishPoFinishCompsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2PoFinishPoFinishProdCompList: {
                records: []
            },
            loadP2PoFinishPoFinishProdCompList: function (p2_pofinish_keyno, who) {
                var deferred = $q.defer();

                var parmsP2PoFinishPoFinishProdCompList = {
                    method: 487,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno,
                        who: who
                    }
                };

                $ihttp.post(parmsP2PoFinishPoFinishProdCompList).then(function (data) {
                    angular.copy(data, service.p2PoFinishPoFinishProdCompList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();