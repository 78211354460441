(function () {
    'use strict';

    angular.module('imApp')
        .directive('imLegendDirective', ['layoutService', function (layoutService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            scope: {
                label: '@',         // Used for translation of label
                open: '@',          // Allows you to have it initialize as open or closed //Values: true/false
            },
            
            template:
                    '<div class="form-group">' +
                        '<div class="col-xs-12">' +
                            '<fieldset class="im-fs">' +
                                '<legend class="im-legend" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">' +
                                    '<a class="legend-label" ng-click="openClose()" im-translate="{{label}}">' +
                                        '<i class="pull-right glyphicon" ng-class="{\'glyphicon-chevron-down\': model.open, \'glyphicon-chevron-right\': !model.open}" ng-style="{\'fontSize\': model.fontSizes.textSizeS}" style="padding-left: 4px; text-align:right; color:#B8B8B8"></i>' +
                                    '</a>' +
                                '</legend>' +
                                '<div uib-collapse="!model.open" id="{{model.inputId}}">' +
                                    '<div class="col-xs-12">' +
                                        '<ng-transclude>' +
                                        '</ng-transclude>' +
                                    '</div>' +
	                            '</div>' +
                            '</fieldset>' +
                        '</div>' +
                    '</div>',

            link: function ($scope) {
                $scope.model = {
                    inputId: uuid(),
                    open: true,
                    fontSizes: {}
                };

                if (angular.isDefined($scope.open) && $scope.open == 'false') {
                    $scope.model.open = false;
                }

                $scope.openClose = function () {
                    $scope.model.open = !$scope.model.open;
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    angular.copy(info.fontSizes, $scope.model.fontSizes);
                });
            }
        };

        return directive;
    }]);
})();