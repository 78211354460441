(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory7', {
        templateUrl: 'views/components/views/textileModelCategory7/textileModelCategory7.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'textileModelCategory7Service', 'translateService', function ($stateParams, stateService, modalService, textileModelCategory7Service, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var textileModelCategory7Keyno = $stateParams.textilemodelcategory7_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'textilemodelcategory7lang':
                        stateService.go(state, {
                            textilemodelcategory7_keyno: vm.model.edit.textilemodelcategory7_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                textileModelCategory7LangNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'textilemodelcategory7lang' } }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory7Service.getTextileModelCategory7({ textilemodelcategory7_keyno: textileModelCategory7Keyno }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                textileModelCategory7Service.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
