(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListEquipment', {
        templateUrl: 'views/components/views/srCheckListEquipment/srCheckListEquipment.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srRefundInputCrewService', 'srCheckListEquipmentService', function($stateParams, stateService, utilityService, srRefundInputCrewService, srCheckListEquipmentService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                srRefundTypeId: $stateParams.srrefundtype_id,
                srRefundTypeKeyNo: $stateParams.srrefundinput_keyno,
                srEquipmentKeyNoBtnList: [
                    { item_id: '0', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' },
                    { item_id: '1', item_name: 'new', item_func: 'new_item', glyph: 'glyphicon-plus', color: 'primary' }
                ],
                checkList: {},
                selectListEquipments: [],
                itemsListInputs: [],
                lockedSave: false,
                lockedDelete: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListEquipmentService.loadSrCheckListEquipmentGet(vm.model.checkListDataKeyNo, vm.model.srRefundTypeId, vm.model.srRefundTypeKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.checkList);
    
                loadSrCheckListEquipments();
                loadSrRefundInputs();
            });
    
            var loadSrCheckListEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.checkList.checklistdata_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.checkList.srrefundtype_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.checkList.srrefundinput_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.checkList.resource_id) !== true) return;
    
                srCheckListEquipmentService.loadSrCheckListEquipmentList(vm.model.checkList.checklistdata_keyno, vm.model.checkList.srrefundtype_id, vm.model.checkList.srrefundinput_keyno, vm.model.checkList.resource_id).then(function (data) {
                    angular.copy(data, vm.model.selectListEquipments);
                });
            };
    
            var loadSrRefundInputs = function () {
                if (utilityService.validateParmsValue(vm.model.checkList.srrefundinput_keyno) !== true) return;
    
                vm.model.itemsListInputs = [];
    
                srRefundInputCrewService.loadSrRefundInputsList(vm.model.checkList.srrefundinput_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListInputs);
                });
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.srEquipmentKeyNoBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'list_item':
                        vm.goTo('srequipments')
                        break;
                    case 'new_item':
                        vm.goTo('srequipment')
                        break;
                    default:
                        break;
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srequipments':
                        go({
                            resource_id: vm.model.checkList.resource_id
                        });
                        break;
                    case 'srequipment':
                        go({
                            resource_id: vm.model.checkList.resource_id,
                            srequipment_keyno: '0'
                        });
                        break;
                    case 'pictures':
                        go({
                            argtype: 'srrefundinput_keyno',
                            argvalue: vm.model.checkList.srrefundinput_keyno
                        });
                        break;
                    case 'documents':
                        go({
                            argtype: 'srrefundinput_keyno',
                            argvalue: vm.model.checkList.srrefundinput_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrCheckListEquipment = function () {
                vm.model.lockedSave = true;
                
                srCheckListEquipmentService.save(vm.model.checkList).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteSrCheckListEquipment = function () {
                vm.model.lockedDelete = true;
                
                srCheckListEquipmentService.deleteObj(vm.model.checkList).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelete = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();