(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('docPackType', {
        templateUrl: 'views/components/views/docPackType/docPackType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'modalService', 'translateService', 'docPackTypeService', function ($stateParams, stateService, base64, modalService, translateService, docPackTypeService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let docPackTypeKeyno = $stateParams.docpacktype_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                docPackTypeKeyno: docPackTypeKeyno,
                edit: {},
                readyHtmlTextFrontPage: true,
                readyHtmlTextIndexPage: true,
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
            	docPackTypeService.getEdit({ docpacktype_keyno: docPackTypeKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                    vm.model.edit.html_text_frontpage = base64.urldecode(vm.model.edit.html_text_frontpage);
                    vm.model.edit.html_text_indexpage = base64.urldecode(vm.model.edit.html_text_indexpage);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let editAndSave = {
                    docpacktype_keyno: vm.model.edit.docpacktype_keyno,
                    docpacktype_name: vm.model.edit.docpacktype_name,
                    html_text_frontpage: base64.urlencode(vm.model.edit.html_text_frontpage),
                    html_text_indexpage: base64.urlencode(vm.model.edit.html_text_indexpage)
                };

                docPackTypeService.saveObj(editAndSave).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
