(function () {
    'use strict';

    var module = angular.module("ttCompany", ['ngStorage', 'appConfig']);

    module.factory("companyService", ['$rootScope', '$q', '$http', '$sessionStorage', 'appConfig', function ($rootScope, $q, $http, $sessionStorage, appConfig) {
        var events = {
            datachanged: {}//,
            //fileServerDataChanged: {},
            //printServerDataChanged: {}
        };

        var service = {
            isReady: false,
            companyName: '',
            custNo: 0,
            custId: '', // BJS 20161026
            dbDescription: '',
            dbId: 0,
            //url: '',
            urlLogoWelcome: '',
            //useHttps: false,
            companyid: '',
            //dataUrl: '',
            dataTaskUrl: '',
            //fsaUrl: '',
            //fsaApplicationId: '',
            //printServerApplicationId: '',
            fileServerUrl: '',
            printServerUrl: '',

            on: function (event, handler) {
                if(angular.isUndefined(events[event])) return function () {};

                var id = uuid();

                events[event][id] = {
                    handler : handler
                };

                return function () {
                    if (angular.isDefined(events[event][id])) {
                        delete events[event][id];
                    }
                };
            },

            init: function () {
                service.companyName = appConfig.companyName;
                service.custNo = appConfig.custNo;
                service.custId = appConfig.companyId;
                service.dbDescription = appConfig.dbDescription;
                service.dbId = appConfig.dbDescription;
                service.urlLogoWelcome = appConfig.urlLogoWelcome;
                service.companyid = appConfig.hashId;
                service.dataTaskUrl = appConfig.siteRoot + '/db-api/' + appConfig.companyId + '/' + appConfig.service.dataTask + '/';
                service.fileServerUrl = appConfig.siteRoot + '/fs-api/' + appConfig.companyId + '/';
                service.printServerUrl = appConfig.siteRoot + '/ps-api/' + appConfig.companyId + '/';

                $sessionStorage.companyid = service.companyid;

                service.isReady = true;

                angular.forEach(events.datachanged, function (value) {
                    if (angular.isFunction(value.handler)) {
                        value.handler(data);
                    }
                });
            },

        //    getInfo: function (companyId) {
        //        var deferred = $q.defer();

        //        var url = appConfig.url.companyInfo + appConfig.service.companyInfo + '/' + companyId;

        //        $http.get(url).then(function (result) {
        //            var data = result.data[0];

        //            service.companyName = data.company_name;
        //            service.custNo = data.cust_no;
        //            service.custId = data.cust_id;   // BJS 20161026
        //            service.dbDescription = data.db_description;
        //            service.dbId = data.db_id;
        //            service.url = data.ip;
        //            service.dataUrl = 'https://' + service.url + '/';
        //            service.dataTaskUrl = 'https://' + service.url + '/' + appConfig.service.dataTask + '/';
        //            service.urlLogoWelcome = data.url_logo_welcome;
        //            service.useHttps = data.use_https === '1';
        //            service.companyid = data.hashid;
        //            service.fileServerUrl = data.server_a_url;
        //            service.fileServerProxyId = data.fileserver_proxy_id;
        //            service.printServerUrl = data.printserver_url;
        //            service.printServerProxyId = data.printserver_proxy_id;

        //            $sessionStorage.companyid = service.companyid;

        //            appConfig.url.printServer = data.printserver_url;
        //            appConfig.printServerProxyId = data.printserver_proxy_id;
        //            appConfig.url.fileServer = data.server_a_url;
        //            appConfig.fileServerProxyId = data.fileserver_proxy_id;

        //            service.isReady = true;

        //            angular.forEach(events.datachanged, function (value) {
        //                if (angular.isFunction(value.handler)) {
        //                    value.handler(data);
        //                }
        //            });

        //            angular.forEach(events.fileServerDataChanged, function (value) {
        //                if (angular.isFunction(value.handler)) {
        //                    value.handler(service);
        //                }
        //            });

        //            angular.forEach(events.printServerDataChanged, function (value) {
        //                if (angular.isFunction(value.handler)) {
        //                    value.handler(service);
        //                }
        //            });

        //            deferred.resolve(data);
        //        });

        //        return deferred.promise;
        //    }
        };

        //// uses event to communicate between fileserver and companyService so fileserver will get correct companydata
        //$rootScope.$on('fileServer:wantsConnectionInformation', function (event, handler) {
        //    if (angular.isFunction(handler) !== true) return;

        //    if (service.isReady === true) {
        //        handler(service);
        //    }

        //    service.on('fileServerDataChanged', handler);
        //});

        //// uses event to communicate between printserver and companyService so printserver will get correct companydata
        //$rootScope.$on('printServer:wantsConnectionInformation', function(event, handler) {
        //    if (angular.isFunction(handler) !== true) return;

        //    if (service.isReady === true) {
        //        handler(service);
        //    }

        //    service.on('printServerDataChanged', handler);
        //});
        
        return service;
    }]);
})();
