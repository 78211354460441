(function () {
	'use strict';
	
	var module = angular.module('imApp');
	
	module.component('purchRequestCreate', {
		templateUrl: 'views/components/views/purchRequestCreate/purchRequestCreate.template.html?v=' + module.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'purchRequestCreateService', function ($stateParams, stateService, modalService, translateService, purchRequestCreateService) {
			
			// #region VARIABLES & DEFINITIONS
			
			var vm = this;
			var purchInternalNo = $stateParams.purch_internal_no;
			
			var translations = {
				purchrequestcreate_create_show_title: 'Varsel',
				purchrequestcreate_create_show_ok_label: 'OK'
			};
			
			vm.model = {
				setting: {},
				selectListSuppliers: [],
				gridReady: false,
				lockedSave: false
			};

			vm.grid = {
				dataTask: {
					rememberId: 'w_purchrequestcreate_grid',
					loadSetupId: null,
					loadData: {},
					addRow: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					},
					removeRow: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					},
					saveData: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					}
				},
				translations: [],
				config: {
					editColumns: [
						//{ key: 'test_column' }
						//'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
					],
					toolbar: {
						hidden: false,
						pdfExport: true,
						excelExport: true,
						filter: true,
						columnVisibility: true,
						headers: true,
						edit: false,
						lock: false,
						add: false,
						delete: false,
						save: false,
						wrapping: true,
						layouts: true,
						buttons: [
							//{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
							//{ name: 'test', text: '', func: function () { testItems(); } },
							//{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
							//{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
							//{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
							//{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
						]
					},
					fixedHeader: true,
					keepSortOnAdd: false,
					keepSortOnCheckbox: false,
					keepSortOnIsSelected: false,
					rowClick: false,
					//navigation: {
					//	altNav: true,
					//	newLine: true
					//},
					specialFunc: {
                        			newTab: true,
                        			buttons: [
                            				//{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        			]
                    			},
					css: {
						altColor: true,
						textWrapping: false
					},
					onDataSourceChanges: function (e) {
						//console.log('onDataSourceChanges');
						//console.dir(e);
					},
					onDataChanged: function (changed, e) {
						//console.log('onDataChanged');
						//console.dir(e);
					}
				},
				kendo: {
					height: '100%',
					sortable: true,
					aggregate: true,
					pager: false,
					selectable: false, //'multiple, row'
					//persistSelection: false,
					filterable: false
				},
				optionfunc: null,
				gridfunc: null
			};
			
			// #endregion VARIABLES & DEFINITIONS
			
			// #region LOAD PROCEDURE FUNCTION CALLS
			
			translateService.translateBatch(translations).then(function (data) {
				angular.forEach(translations, function (_, key) {
					if (angular.isDefined(data[key])) {
						translations[key] = data[key];
					}
				});
			});
		
			purchRequestCreateService.getPurchRequestCreate({ purch_internal_no: purchInternalNo }).then(function (result) {
				angular.copy(result[0], vm.model.setting);

				loadSuppliers();

				vm.grid.dataTask.loadData = {
					method: 2861,
					parameters: vm.model.setting
				};
				
				vm.model.gridReady = true;
			});
			
			var loadSuppliers = function () {
				purchRequestCreateService.listSuppliers(vm.model.setting).then(function (result) {
					angular.copy(result, vm.model.selectListSuppliers);
				});
			};
			
			// #endregion LOAD PROCEDURE FUNCTION CALLS

			// #region MULTI DIRECTION BUTTON FUNCTIONS
			
			vm.goToFunc = function (state) {
				switch (state) {
					case 'purchhead':
						vm.model.lockedSave = true;
						purchRequestCreateService.createObj(vm.model.setting).then(function (response) {
							if (response[0].errorcode !== '0') {
								modalService.show({
									type: 'warning',
									title: translations.purchrequestcreate_create_show_title,
									message: response[0].errormessage,
									buttons: [{
										label: translations.purchrequestcreate_create_show_ok_label,
										cssClass: 'btn-warning',
										action: function (dialogItself) {
											dialogItself.close();
											vm.model.lockedSave = false;
										}
									}]
								});
							} else {
								vm.model.lockedSave = false;
								stateService.go(state, { purch_internal_no: response[0].purch_internal_no });
							}
						});
						break;
					default:
						break;
				}
			};
			
			// #endregion MULTI DIRECTION BUTTON FUNCTIONS
		}]
	});
})();
