(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deviations', {
        templateUrl: 'views/components/views/deviations/deviations.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'deviationsService', function ($stateParams, stateService, deviationsService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                itemsListDeviations: []
            };
            
            let loadDeviations = function () {
                deviationsService.listDeviations({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListDeviations);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'deviation':
                        deviationsService.getCustactNo().then(function (response) {
                            if (angular.isDefined(response[0])) {
                                go({
                                    argtype: argType,
                                    argvalue: argValue,
                                    custact_no_deviation: response[0].custact_no
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadDeviations();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
