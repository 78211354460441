(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkPoints', {
        templateUrl: 'views/components/views/checkPoints/checkPoints.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'checkPointsService', 'ttGridFactory', function ($stateParams, $q, utilityService, checkPointsService, ttGridFactory) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_checkpointsComponentGrid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            let loadSetting = function () {
                const deferred = $q.defer();

                checkPointsService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: 3318,
                    parameters: {
                        argtype: vm.model.setting.argtype,
                        argvalue: vm.model.setting.argvalue
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
