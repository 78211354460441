(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('createCase', {
        templateUrl: 'views/components/views/createCase/createCase.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'utilityService', 'createCaseService', 'logisticService', 'translateService', 'rememberService', function ($stateParams, stateService, modalService, utilityService, createCaseService, logisticService, translateService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;

            var variableNames = {
                printer_id: ''
            };

            var translations = {
                createcase_show_title: 'Varsel',
                createcase_show_ok_label: 'OK',
                createcase_split_show_title: 'Varsel',
                createcase_split_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'orderhead':
                        stateService.go(state, {
                            order_internal_no: vm.model.setting.order_internal_no
                        });
                        break;
                    case 'finishpohscan':
                        stateService.back('/' + 'finishpohscan');
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                bgCreateCase: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_createcase_case', item_func: 'CASE' },
                        { item_id: '1', item_name: 'gb_createcase_order', item_func: 'ORDER' }
                    ]
                },
                orderOrderNoButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderhead' } }
                ],
                setting: {
                    case_content: '',
                    volume_m3: '',
                    weight_kg: ''
                },
                selectListPrinters: [],
                gridMergeReady: false,
                gridSplitReady: false,
                gridAllReady: false,
                lockedMerge: false,
                lockedSplit: false
            };

            vm.gridMerge = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: 'multiple', //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfuncMerge(data.data); },
                gridfunc: null
            };

            var aggregateSelectedRows = function (data) {
                vm.model.setting.case_content = '';
                vm.model.setting.volume_m3 = 0;
                vm.model.setting.weight_kg = 0;

                angular.forEach(data, function (item) {
                    if (angular.isString(item.colli_content_special) && item.colli_content_special !== null && item.colli_content_special.length > 0) {
                        if (vm.model.setting.case_content.length > 0) {
                            vm.model.setting.case_content += ', ';
                        }

                        vm.model.setting.case_content += item.colli_content_special;
                    }

                    if (angular.isNumber(item.volum_m3) && item.volum_m3 !== null) {
                        vm.model.setting.volume_m3 += item.volum_m3;
                    } else if (!isNaN(parseFloat(item.volum_m3))) {
                        vm.model.setting.volume_m3 += parseFloat(item.volum_m3);
                    }

                    if (angular.isNumber(item.weight_kg) && item.weight_kg !== null) {
                        vm.model.setting.weight_kg += item.weight_kg;
                    } else if (!isNaN(parseFloat(item.weight_kg))) {
                        vm.model.setting.weight_kg += parseFloat(item.weight_kg);
                    }
                    
                });
            };

            var optionfuncMerge = function (data) {
                if (angular.isObject(data) !== true) return;
                if (angular.isString(data.func) !== true) return;
                
                switch (data.func.toLowerCase()) {
                    case 'onchange':
                        let selRows = vm.gridMerge.gridfunc.getSelectedRows();
                        aggregateSelectedRows(selRows);
                        break;
                }
                
            };

            vm.gridSplit = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: 'multiple', //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfuncSplit(data.data); },
                gridfunc: null
            };

            var optionfuncSplit = function (data) { };

            vm.gridAll = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: 'multiple', //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) { };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            createCaseService.getCreateCase({
                argtype: argType,
                argvalue: argValue
            }).then(function (result) {
                angular.copy(result[0], vm.model.setting);
                vm.gridMerge.dataTask.rememberId = 'w_createcase_grid_merge';
                vm.gridSplit.dataTask.rememberId = 'w_createcase_grid_split';
                vm.gridAll.dataTask.rememberId = 'w_createcase_order_all_grid';

		        vm.gridMerge.dataTask.loadData = {
                    method: 2547,
                    parameters: vm.model.setting
		        };

		        vm.gridSplit.dataTask.loadData = {
                    method: 2549,
                    parameters: vm.model.setting
		        };

			    vm.gridAll.dataTask.loadData = {
                    method: 2576,
                    parameters: vm.model.setting
			    };
		        
                vm.model.gridMergeReady = true;
                vm.model.gridSplitReady = true;
                vm.model.gridAllReady = true;
            });

            logisticService.listCargonizerPrinters().then(function (result) {
                angular.copy(result, vm.model.selectListPrinters);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'printer_id':
                            variableNames[key] = 'pofinishprodpackprinter';
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'printer_id':
                        if (utilityService.validateParmsValue(vm.model.setting.printer_id) !== true) return;

                        variableValue = vm.model.setting.printer_id;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.selectBgCreateCase = function (item) {
                vm.model.bgCreateCase.item_selected = item;
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'printer_id':
                        vm.model.setting.printer_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.printer_id) !== true) return;

                        rememberFunc('printer_id');
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON MERGE PROCEDURE FUNCTION CALL

            vm.mergeChanges = function () {
                vm.model.lockedMerge = true;

                var checkedItems = vm.gridMerge.gridfunc.getSelectedRows();

                var mergeObject = vm.model.setting;
                
                mergeObject.records = checkedItems.map(function (a) {
                    return {
                        "stockbatch_keynos": a.stockbatch_keynos,
                        "stock_keyno": a.stock_keyno,
                        "prodsizecolli_keyno": a.prodsizecolli_keyno,
                        "prodsizecolli_collino": a.prodsizecolli_collino,
                        "orderline_keyno": a.orderline_keyno,
                        "olm_keyno": a.olm_keyno,
                        "quantity": a.quantity
                    };
                });

                createCaseService.mergeObj(mergeObject).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                            type: 'warning',
                    		title: translations.createcase_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.createcase_show_ok_label,
                                cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedMerge = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedMerge = false;
                        vm.gridMerge.gridfunc.rebind();
                        vm.gridSplit.gridfunc.rebind();
                    }
                });
            };

		    // #endregion BUTTON MERGE PROCEDURE FUNCTION CALL

            // #region BUTTON CREATE CASE PROCEDURE FUNCTION CALL

            vm.createCase = function () {
                vm.model.lockedMerge = true;
                
                var checkedItems = vm.gridMerge.gridfunc.getSelectedRows();
                //console.dir(checkedItems)

                var mergeObject = vm.model.setting;
                //console.dir(checkedItems);
                mergeObject.records = checkedItems.map(function (a) {
                    return {
                        "stockbatch_keynos": a.stockbatch_keynos,
                        "stock_keyno": a.stock_keyno,
                        "prodsizecolli_keyno": a.prodsizecolli_keyno,
                        "prodsizecolli_collino": a.prodsizecolli_collino,
                        "orderline_keyno": a.orderline_keyno,
                        "olm_keyno": a.olm_keyno,
                        "quantity": a.quantity,
                        "weight": a.weight_kg,
                        "volume": a.volum_m3,
                        "colli_content": a.colli_content,
                        "colli_content_special": a.colli_content_special
                    };
                });

                //console.dir(mergeObject);
                
                createCaseService.createSeparateCases(mergeObject).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.createcase_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.createcase_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedMerge = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedMerge = false;
                        vm.gridMerge.gridfunc.rebind();
                        vm.gridSplit.gridfunc.rebind();
                    }
                });
                
            };

		    // #endregion BUTTON CREATE CASE PROCEDURE FUNCTION CALL

		    // #region BUTTON SPLIT PROCEDURE FUNCTION CALL

            vm.splitChanges = function () {
                vm.model.lockedSplit = true;

                var checkedItems = vm.gridSplit.gridfunc.getSelectedRows();

                var splitObject = vm.model.setting;
                splitObject.records = checkedItems.map(function (a) { return { "case_keyno": a.case_keyno }; });

                createCaseService.splitObj(splitObject).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.createcase_split_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.createcase_split_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSplit = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSplit = false;
                        vm.gridMerge.gridfunc.rebind();
                        vm.gridSplit.gridfunc.rebind();
                    }
                });
            };

		    // #endregion BUTTON SPLIT PROCEDURE FUNCTION CALL

		    translateService.translateBatch(translations).then(function (data) {
		    	angular.forEach(translations, function (_, key) {
		    		if (angular.isDefined(data[key])) {
		    			translations[key] = data[key];
		    		}
		    	});
		    });
        }]
    });
})();
