(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('webpageSettingMenu', {
        templateUrl: 'views/components/views/webpageSettingMenu/webpageSettingMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'userService', 'webpageSettingMenuService', 'translateService', 'p2WebpageService', function($stateParams, $q, utilityService, userService, webpageSettingMenuService, translateService, p2WebpageService) {
    
            var vm = this;
    
    
            vm.model = {
                list: []
            };
    
            var translations = {
                showHideLayoutControls: ''
            };
    
            var canHideShowLayoutSetting = false;
            var slc;
            var slcIndex;
    
            var load = function () {
                var promises = [];
    
                promises.push(webpageSettingMenuService.loadWebpageSettingMenuList($stateParams.webpage_name));
    
                if (canHideShowLayoutSetting) {
                    promises.push(p2WebpageService.details($stateParams.webpage_name));
                }
    
                $q.all(promises).then(function (response) {
                    if (canHideShowLayoutSetting) {
                        slc = utilityService.toBoolean(response[1].show_layout_controls);
    
                        response[0].push({
                            item_id: '1000',
                            item_name: translations.showHideLayoutControls,
                            item_glyphicon: slc ? 'glyphicon-check' : 'glyphicon-unchecked',
                            item_glyphicon_color: slc ? 'green' : 'red',
                            orderby: 1000,
                            item_state: 'customaction',
                            item_parms: '',
                            item_action: function () {
                                slc = !slc;
    
                                p2WebpageService.toogleShowLayoutControls($stateParams.webpage_name).then(function () {
                                    vm.model.list[slcIndex].item_glyphicon = slc ? 'glyphicon-check' : 'glyphicon-unchecked';
                                    vm.model.list[slcIndex].item_glyphicon_color = slc ? 'green' : 'red';
                                });
                            }
                        });
    
                        slcIndex = response[0].length - 1;
                    }
    
                    angular.copy(response[0], vm.model.list);
                });
            };
    
            var deregTranslations = translateService.on(translations, 'changed', function (result) {
                deregTranslations();
    
                angular.copy(result, translations);

                userService.ensureIsReady().then(function () {
                    canHideShowLayoutSetting = utilityService.toBoolean(userService.userSettings['_240'].value); // setting_show_gui_layout_controls
    
                    load();
                });
            });
        }]
    });
})();
