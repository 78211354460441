(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderlineStatus', {
        templateUrl: 'views/components/views/orderlineStatus/orderlineStatus.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'orderlineStatusService', function ($stateParams, stateService, orderlineStatusService) {
            var vm = this;
            
            vm.model = {
                p2OrderlineListStatus: {
                    records: []
                }
            };
            
            orderlineStatusService.loadP2OrderlineListStatus($stateParams.order_internal_no).then(function () {
                vm.model.p2OrderlineListStatus = orderlineStatusService.p2OrderlineListStatus;
            });

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'rfidconnect':
                        go({
                            argtype: 'order_internal_no',
                            argvalue: $stateParams.order_internal_no
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();