(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('notifications', {
        templateUrl: 'views/components/views/notifications/notifications.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['notificationsService', 'stateService', function (notificationsService, stateService) {
            var vm = this;
            vm.notifications = notificationsService.notifications;
            
            notificationsService.loadNotifications(stateService.getCurrentName());
            
            //on click read function, remove from notificationlist
        }]
    });
})();