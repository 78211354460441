(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2Subscription', {
        templateUrl: 'views/components/views/p2Subscription/p2Subscription.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['p2SubscriptionService', 'stateService', function (p2SubscriptionService, stateService) {
            var vm = this;
            
            vm.model = {
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                setting: {},
                itemsListSubscriptions: []
            };
            
            p2SubscriptionService.getSubscription().then(function (data) {
                angular.copy(data[0], vm.model.setting);
            });
            
            p2SubscriptionService.listSubscriptions().then(function (result) {
                angular.copy(result, vm.model.itemsListSubscriptions);
            });

            vm.searchTextBtnListFunc = function (item_func) {
                if (item_func === 'erase_item') {
                    vm.model.setting.searchtext = '';
                }
            };
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2subscriptionedit':
                        stateService.go(state, {
                            p2_subscription_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();