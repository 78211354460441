(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('case', {
        templateUrl: 'views/components/views/case/case.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'caseService', 'translateService', function ($stateParams, stateService, modalService, caseService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var caseKeyno = $stateParams.case_keyno;

            var translations = {
                case_show_title: 'Varsel',
                case_show_ok_label: 'OK'
            };

            vm.model = {
                setting: {},
                gridReady: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) { };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            caseService.getCase({ case_keyno: caseKeyno }).then(function (result) {
            	angular.copy(result[0], vm.model.setting);
                vm.grid.dataTask.rememberId = 'w_case_stockbatches_grid';
		        
		        vm.grid.dataTask.loadData = {
                    method: 2559,
                    parameters: vm.model.setting
		        };
		        
		        vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                caseService.saveObj(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.case_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.case_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    translateService.translateBatch(translations).then(function (data) {
			    angular.forEach(translations, function (_, key) {
				    if (angular.isDefined(data[key])) {
					    translations[key] = data[key];
				    }
			    });
		    });

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();