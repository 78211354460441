(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customerBusCos', {
        templateUrl: 'views/components/views/customerBusCos/customerBusCos.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'customerBusCosService', function ($stateParams, $q, utilityService, customerBusCosService) {
            const vm = this;
            let custNo = $stateParams.cust_no;
    		
            let setCustomerBusCosView = function (id) {
                vm.model.bgCustomerBusCos.selected = vm.model.setting.gb_customerbuscos = id;
            };

            vm.model = {
                bgCustomerBusCos: {
                    selected: 'USED',
                    buttons: [
                        { id: 'USED', label: 'gb_customerbuscos_used', color: 'primary', onClick: { click: setCustomerBusCosView, id: 'USED' } },
                        { id: 'ALL', label: 'gb_customerbuscos_all', color: 'primary', onClick: { click: setCustomerBusCosView, id: 'ALL' } },
                    ]
                },
                setting: {},
                itemsListCustomerCompanies: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();
                
                customerBusCosService.getCustomerBusCos({ cust_no: custNo }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                    setCustomerBusCosView(vm.model.setting.gb_customerbuscos);

                    $q.all([
                        loadCustomerCompanies()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadCustomerCompanies = function () {
                if (utilityService.validateParmsValue(vm.model.setting.cust_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_customerbuscos) !== true) return;

                vm.model.itemsListCustomerCompanies = [];
                
                return customerBusCosService.listCustomerCompanies({
                    cust_no: vm.model.setting.cust_no,
                    gb_customerbuscos: vm.model.setting.gb_customerbuscos
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCustomerCompanies);
                });
            };
            
            vm.selectBgCustomerBusCos = function (item) {
                vm.model.bgCustomerBusCos.selected = item;
                vm.model.setting.gb_customerbuscos = vm.model.bgCustomerBusCos.selected;

                loadCustomerCompanies();
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
