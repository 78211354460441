(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srRefundInputCrew', {
        templateUrl: 'views/components/views/srRefundInputCrew/srRefundInputCrew.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srRefundInputCrewService', 'watcherFactory', function($stateParams, stateService, utilityService, srRefundInputCrewService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                srRefundInputKeyNo: $stateParams.srrefundinput_keyno,
                refundCrew: {},
                itemsListInputs: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srRefundInputCrewService.loadSrRefundInputCrewGet(vm.model.checkListDataKeyNo, vm.model.srRefundInputKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.refundCrew);
    
                loadSrRefundInputs();
            });
    
            var loadSrRefundInputs = function () {
                if (utilityService.validateParmsValue(vm.model.refundCrew.srrefundinput_keyno) !== true) return;
    
                vm.model.itemsListInputs = [];
    
                srRefundInputCrewService.loadSrRefundInputsList(vm.model.refundCrew.srrefundinput_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListInputs);
                });
            };
    
            var calculateHours = function () {
                if (utilityService.validateParmsValue(vm.model.refundCrew.signin_dt) !== true) return;
                if (utilityService.validateParmsValue(vm.model.refundCrew.has_signed_out) !== true) return;
                if (utilityService.validateParmsValue(vm.model.refundCrew.signout_dt) !== true) return;
    
                srRefundInputCrewService.calc(vm.model.refundCrew).then(function (data) {
                    vm.model.refundCrew.hours_total = data[0].hours_total;
                });
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrRefundInputCrew = function () {
                vm.model.lockedSave = true;
                
                srRefundInputCrewService.save(vm.model.refundCrew).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteSrRefundInputCrew = function () {
                vm.model.lockedDelete = true;
                
                srRefundInputCrewService.deleteObj(vm.model.refundCrew).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.refundCrew.signin_dt; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
                calculateHours();
            });
    
            watcher.$watch(function () { return vm.model.refundCrew.has_signed_out; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
                calculateHours();
            });
    
            watcher.$watch(function () { return vm.model.refundCrew.signout_dt; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
                calculateHours();
            });
        }]
    });
})();