(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            p2ConsignmentGet: {},
            p2ConsignmentSave: {
                saveok: ''
            },
            p2ConsignmentConsignmentOlList: {
                records: []
            },
            p2ConsignmentConsignmentLineList: {
                records: []
            },
            p2ConsignmentColliList: {
                records: []
            },
            p2ConsignmentConsignmentLinePickList: {
                records: []
            },
            p2PortalUserVariableSetWs: {},
            deliveryMethods: {
                records: [],
                indexed_records: {}
            },
            p2ConsignmentAssignSb: {},
            p2ConsignmentRefreshWeightVolum: {},
            p2DeliverConsignment: {},
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            deliveryMethodsList: function (consignment_keyno) {
                var deferred = $q.defer();

                var parms_deliveryMethodsGet = {
                    method: 399,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                $ihttp.post(parms_deliveryMethodsGet).then(function (data) {
                    service.deliveryMethods.records = [];
                    service.deliveryMethods.indexed_records = {};
                    angular.copy(data, service.deliveryMethods.records);

                    angular.forEach(service.deliveryMethods.records, function (item) {
                        service.deliveryMethods.indexed_records[item.deliverymethod_no] = {
                            deliverymethod_no: item.deliverymethod_no,
                            deliverymethod_name: item.deliverymethod_name,
                            deliverymethod_cargonizer: item.deliverymethod_cargonizer
                        };
                    });

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2ConsignmentGet: function (consignment_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentGet = {
                    method: 322,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                service.p2ConsignmentGet = {};

                $ihttp.post(parmsP2ConsignmentGet).then(function (data) {
                    service.p2ConsignmentGet = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadStockTransferLoadConsignmentPrePickList: function (stocktransfer_keyno) {
                return p2DataTaskService.call(1055, {
                    stocktransfer_keyno: stocktransfer_keyno
                });
            },
            loadSelectStockLocationConsignmentList: function (stockplace_id) {
                return p2DataTaskService.call(1051, {
                    stockplace_id: stockplace_id
                });
            },
            loadConsignmentShipmentSelectList: function (stockplace_id) {
                return p2DataTaskService.call(1107, {
                    stockplace_id: stockplace_id
                });
            },
            loadP2ConsignmentConsignmentOlList: function (consignment_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentConsignmentOlList = {
                    method: 326,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                $ihttp.post(parmsP2ConsignmentConsignmentOlList).then(function (data) {
                    angular.copy(data, service.p2ConsignmentConsignmentOlList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2ConsignmentConsignmentLineList: function (consignment_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentConsignmentLineList = {
                    method: 327,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                $ihttp.post(parmsP2ConsignmentConsignmentLineList).then(function (data) {
                    angular.copy(data, service.p2ConsignmentConsignmentLineList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            createCargonizerConsignment: function (consignmentId, deliveryMethod, colliCount, weightValue, volumeValue) {
                return $ihttp.post({
                    method: 348,
                    parameters: {
                        consignment_keyno: consignmentId,
                        deliverymethod_no: deliveryMethod,
                        colli: colliCount,
                        weight: weightValue,
                        volum: volumeValue
                    }
                });
            },
            updateCargonizerConsignment: function (consignmentId) {
                return $ihttp.post({
                    method: 418,
                    parameters: {
                        consignment_keyno: consignmentId
                    }
                });
            },
            completeConsignmentInWebshop: function (params) {
                return p2DataTaskService.call(1437, params);
            },
            consignmentWebshopPreValidate: function (params) {
                return p2DataTaskService.call(1575, params);
            },
            mergeConsignmentCollies: function (params) {
                return p2DataTaskService.call(1428, params);
            },
            splitConsignmentCollies: function (params) {
                return p2DataTaskService.call(1430, params);
            },
            loadP2ConsignmentColliList: function (consignment_keyno, retreiveType) {
                var deferred = $q.defer();

                var parmsP2ConsignmentColliList = {
                    method: 439,
                    parameters: {
                        consignment_keyno: consignment_keyno,
                        retreive_type: retreiveType
                    }
                };

                $ihttp.post(parmsP2ConsignmentColliList).then(function (data) {
                    angular.copy(data, service.p2ConsignmentColliList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2ConsignmentConsignmentLinePickList: function (consignment_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentConsignmentLinePickList = {
                    method: 615,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                $ihttp.post(parmsP2ConsignmentConsignmentLinePickList).then(function (data) {
                    angular.copy(data, service.p2ConsignmentConsignmentLinePickList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            getP2PortalUserVariableSetWs: function (variablename, variablevalue) {
                var deferred = $q.defer();

                var parmsP2PortalUserVariableSetWs = {
                    method: 616,
                    parameters: {
                        variablename: variablename,
                        variablevalue: variablevalue
                    }
                };

                $ihttp.post(parmsP2PortalUserVariableSetWs).then(function (data) {
                    service.p2PortalUserVariableSetWs = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            getP2ConsignmentAssignSb: function (consignment_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentAssignSb = {
                    method: 415,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                $ihttp.post(parmsP2ConsignmentAssignSb).then(function (data) {
                    service.p2ConsignmentAssignSb = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveConsignmentHead: function (params) {
                var deferred = $q.defer();

                var parmsSaveConsignmentHead = {
                    method: 413,
                    parameters: params
                };

                $ihttp.post(parmsSaveConsignmentHead).then(function (data) {
                    angular.copy(data[0], service.p2ConsignmentSave);
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deliverConsignment: function (consignment_keyno) {
                var deferred = $q.defer();

                var parms_deliverConsignment = {
                    method: 418,
                    parameters: {
                        consignment_keyno: consignment_keyno
                    }
                };

                $ihttp.post(parms_deliverConsignment).then(function (data) {
                    service.p2DeliverConsignment = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            unloadStockTransferAll: function (stocktransfer_keyno) {
                return p2DataTaskService.call(1064, {
                    stocktransfer_keyno: stocktransfer_keyno
                });
            },
            printLabelCargonizerApi: function (edit) {
                return $ihttp.post({
                    method: 2653,
                    parameters: edit
                });
            },
            rollbackItem: function (parms) {
                return $ihttp.post({
                    method: 2253,
                    parameters: parms
                });
            },

            getProdKeyname: function (searchtext) {
                return $ihttp.post({
                    method: 3110,
                    parameters: {
                        searchtext: searchtext
                    }
                });
            },
            getEstimatedFreight: function (consignment_keyno, deliverymethod_no, colli, weight, volum) {
                return $ihttp.post({
                    method: 350,
                    parameters: {
                        consignment_keyno: consignment_keyno,
                        deliverymethod_no: deliverymethod_no,
                        colli: colli,
                        weight: weight,
                        volum: volum
                    }
                });
            },
            handleEstimatedFreight: function (consignment_keyno, prod_id_freight, est_freight) {
                return $ihttp.post({
                    method: 3195,
                    parameters: {
                        consignment_keyno: consignment_keyno,
                        prod_id_freight: prod_id_freight,
                        est_freight: est_freight
                    }
                });
            },
            getOrderlineProdSum: function (consignment_keyno, prod_id) {
                return $ihttp.post({
                    method: 3199,
                    parameters: {
                        consignment_keyno: consignment_keyno,
                        prod_id: prod_id
                    }
                });
            },
        };

        return service;
    }]);
})();
