(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('sizematrix', {
        templateUrl: 'views/components/views/sizematrix/sizematrix.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['sizematrixService', 'ttGridFactory', function (sizematrixService, ttGridFactory) {
            var vm = this;

            vm.grid = new ttGridFactory({
                rememberId: 'w_sizematrix.grid',
                loadData: {
                    method: 3050,
                    parameters: {}
                }
            }).setOptionFunc((data) => optionfunc(data.data));

            vm.gridlang = new ttGridFactory({
                rememberId: 'w_sizematrixlang.grid',
                loadData: {
                    method: 3054,
                    parameters: {
                        sizematrix_keyno: 0
                    }
                }
            });

            let optionfunc = function (data) {
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    if (data?.clickedCell?.dataItem?.sizematrix_keyno && vm.gridlang?.gridfunc) {
                        if (vm.gridlang.dataTask.loadData.parameters.sizematrix_keyno === data.clickedCell.dataItem.sizematrix_keyno) return;

                        vm.gridlang.dataTask.loadData.parameters.sizematrix_keyno = data.clickedCell.dataItem.sizematrix_keyno;
                        vm.gridlang.gridfunc.rebind();
                    }
                }
            };
        }]
    });
})();
