(function () {
    'use strict';

    angular.module("imApp").factory("stockTransferReceptionService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1030,
                    parameters: parms || {}
                });
            },
            listPicking: function (parms) {
                return $ihttp.post({
                    method: 1031,
                    parameters: parms || {}
                });
            },
            listStockTransfers: function (parms) {
                return $ihttp.post({
                    method: 1033,
                    parameters: parms || {}
                });
            },
            listOnLocations: function (parms) {
                return $ihttp.post({
                    method: 1034,
                    parameters: parms || {}
                });
            },
            createNewItem: function (setting) {
                return $ihttp.post({
                    method: 1032,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
