(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockTransfers', {
        templateUrl: 'views/components/views/stockTransfers/stockTransfers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'stockTransfersService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, stockTransfersService, logisticService, rememberService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                filtervalue: ''
            };
            
            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                itemsListStockTransfers: []
            };
            
            // #endregion VARIABLES & DEFINITIONS
            
            let loadSetting = function () {
                let deferred = $q.defer();
                
                stockTransfersService.getSettings().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces(),
                        loadStockTransfers()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
    
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
    
            let loadStockTransfers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                vm.model.itemsListStockTransfers = [];

                return stockTransfersService.listStockTransfers(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockTransfers);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'stockplace_id':
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '.' +  vm.model.setting.businessco_no;
                            break;
                        case 'filtervalue':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '.' + vm.model.setting.stockplace_id;
                            break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
            
    		// #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stocktransfercreate':
                        go({ stockplace_id: vm.model.setting.stockplace_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');
                        loadStockTransfers();
                        break;
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue) !== true) return;

                        vm.model.setting.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
