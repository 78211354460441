(function () {
    'use strict';
    angular.module('imApp')
        .directive('imTreeDirective', ['$rootScope', 'layoutService', function ($rootScope, layoutService) {
            var directive = {
                restrict: 'E',
                transclude: true,
                scope: {
                    //field: '=',         // Links in the values                              //REQUIRED
                    mode: '@?',         // Used to make transcusion visible                 //REQUIRED if using transculsion, accepts "full", "part", "dual"
                    listItems: '=',     // Used for linking a list to the directive         //REQUIRED
                    setup: '=?',        // Used for linking variables from database
                    size: '@?',         // Used for custom sizing                           //In use with mode, accepts "xs", "xx", and so on followed by a number ex.:"col-xx-3"
                    optionfunc: '&?',   // Used as reference to funtion in Controller
                    autoSize: '@'      // true or false if it should take up rest of screen size.
                },
                template:
                    '<div id="{{id.rootContainer}}" class="col-xs-12 im-no-pad" ng-style="style.rootContainer">' +
                    //'<div id="{{model.mId}}" style="width: 100%;" kendo-map ' +
                    //    //'k-scope-field="spreadsheet" ' +
                    //    //'k-options="model.mapOptions">' +
                    //    'k-options="mapOptions">' +
                    //'</div>' +
                    // class="name-label col-sm-2 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}"
                    '<div ng-class="addClass(\'tree\')" ng-style="style.treeContainer">' +
                    //'<div class="label name-label col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">HELLO</div>' +
                    //'<label ng-if="label !== undefined && label !== \'\'" class="name-label col-sm-2 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}" im-translate="{{label}}">' +
                    '<div id="{{model.mId}}" class="name-label im-no-pad" ng-style="style.treeView" kendo-tree-view style="text-transform: none;"' +
                    //'<div id="{{model.mId}}" kendo-tree-view ' +
                    'k-data-source="model.treeData" ' +
                    //'k-options="treeOptions">' +
                    //'k-options="treeOptions" ng-style="{\'fontSize\': fontSizes.textSizeS}">' +
                    'k-options="treeOptions">' +
                    //'k-data-source="treeData">' +
                    //'k-on-change="selectedItem = dataItem">' +
                    //'<span k-template>' +
                    //    '{{ dataItem.text }} <button class='k-button' ng-click='click(dataItem)'>Select</button>' +
                    //'</span>' +
                    '</div>' +
                    '</div>' +
                    //'<div id="{{model.mId}}" style="height: 300px;"></div>' +
                    '<div ng-if="mode !== undefined && (mode === \'full\' || mode === \'part\' || mode === \'dual\')" ng-class="addClass(\'trans\')">' +
                    '<ng-transclude>' +
                    '</ng-transclude>' +
                    '</div>' +
                    '</div>',
                link: function ($scope, $element) {
                    $scope.model = {
                        mId: uuid(),
                        tree: null,
                        treeData: new kendo.data.HierarchicalDataSource({}),
                        isInitialized: false
                        //mapOptions: {}
                    };

                    $scope.id = {
                        rootContainer: uuid()
                    }

                    $scope.style = {
                        rootContainer: {},
                        treeContainer: {},
                        treeView: { fontSize: '10px' }
                    };

                    $scope.onChange = function (e) {
                        //console.log("Changed: " + e);
                        //console.dir(e);
                    };

                    $scope.onReset = function (e) {
                        //console.log("onReset");
                        //console.dir(e);

                        //if ($scope.model.isInitialized === true) return;

                        //$scope.model.isInitialized = true;

                        //$scope.model.map = $('#' + $scope.model.mId).data('kendoMap');
                    };

                    //$scope.onSelect = function (e) {
                    //    //console.log("File selected :: " + e);
                    //    //console.dir(e);
                    //};

                    //$scope.onRemove = function (e) {
                    //    //console.log("File removed :: " + e);
                    //    //console.dir(e);
                    //};

                    //$scope.onAdd = function (e) {
                    //    console.log("Task added: " + e);
                    //}

                    //$scope.onEdit = function (e) {
                    //    console.log("Task about to be edited :: " + e);
                    //}

                    //$scope.onCancel = function (e) {
                    //    console.log("Cancel task edit :: " + e);
                    //}

                    //$scope.onSave = function (e) {
                    //    console.log("Task saved :: " + e); //Saved
                    //    console.dir(e);

                    //}

                    //$scope.onDataBinding = function (e) {
                    //    console.log("Gantt data binding");
                    //    //console.dir(e);
                    //}

                    $scope.onDataBound = function (e) {
                        //console.log("Data bound");
                        //console.dir(e);
                    };

                    //$scope.onNavigate = function (e) {
                    //    console.log(kendo.format("navigate:: view:{0};", e.view));
                    //    console.dir(e);
                    //}

                    //$scope.onMoveStart = function (e) {
                    //    console.log("moveStart");
                    //}

                    //$scope.onMove = function (e) {
                    //    console.log("move");
                    //}

                    //$scope.onMoveEnd = function (e) {
                    //    console.log("moveEnd");
                    //    console.dir(e);
                    //}

                    //$scope.onResizeStart = function (e) {
                    //    console.log("resizeStart");
                    //}

                    //$scope.onResize = function (e) {
                    //    console.log("resize");
                    //}

                    //$scope.onResizeEnd = function (e) {
                    //    console.log("resizeEnd");
                    //}

                    $scope.onSelect = function (e) {
                        //console.log("onSelect");
                        //console.log(e);
                        //console.log(e.node);

                        //var data = $('treeview').data('kendoTreeView').dataItem(e.node);
                        //var data = $('treeview').data('kendoTreeView').dataItem(e.node);
                        //var data = $('#' + $scope.model.mId).data('kendoTreeView').dataItem(e.node);
                        //var data = $scope.model.tree.dataItem(e.node);

                        //console.dir(data);
                        //console.dir(data.item_id);
                        //$scope.goCustom.dir(data);
                        $scope.goCustom($scope.model.tree.dataItem(e.node));
                    };

                    $scope.goCustom = function (l_item) {
                        if (angular.isUndefined(l_item) || angular.isUndefined(l_item.item_id) || l_item.item_id === '') return;

                        if (angular.isFunction($scope.optionfunc)) {
                            $scope.optionfunc({ item: l_item });
                        }

                        //if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('g') > -1) {
                        //    if (l_item.item_path === '') return;
                        //    stateService.go(l_item.item_state, l_item.item_parms);
                        //}
                    };

                    $scope.addClass = function (condition) {
                        var allClass = '';
                        var size = '';

                        if (condition === 'tree') {
                            if (angular.isDefined($scope.mode) && $scope.mode === 'full') {
                                size = 'col-xs-3';
                                allClass = ' im-no-pad well well-sm';
                            } else if (angular.isDefined($scope.mode) && $scope.mode === 'part') {
                                size = 'col-xx-3';
                                allClass = ' im-no-pad sidepanel';
                            } else if (angular.isDefined($scope.mode) && $scope.mode === 'dual') { //INCOMPLETE
                                size = 'col-xx-3';
                                allClass = ' im-no-pad sidepanel';
                            } else {
                                size = 'col-xs-12';
                                allClass = ' im-no-pad sidepanel';
                            }

                            if (angular.isDefined($scope.size) && $scope.size !== '' && parseInt($scope.size.slice(7)) < 13) {
                                size = $scope.size;
                            }

                            allClass = size + allClass;

                            if (angular.isUndefined($scope.listItems) || $scope.listItems.length === 0) {
                                allClass += " sidepanel-empty";
                            }
                        } else if (condition === 'trans') {
                            if (angular.isDefined($scope.mode) && $scope.mode === 'full') {
                                size = 'col-xs-9';
                                allClass = ' im-no-pad sidepanel-full';
                                //allClass = 'col-xs-9 im-no-pad';
                            } else if (angular.isDefined($scope.mode) && $scope.mode === 'part') {
                                size = 'col-xx-12';
                                allClass = ' im-no-pad sidepanel-trans';
                            } else if (angular.isDefined($scope.mode) && $scope.mode === 'dual') { //INCOMPLETE
                                size = 'col-xx-9';
                                allClass = ' im-no-pad sidepanel-trans';
                            } else {
                                size = 'col-xx-0';
                                allClass = ' im-no-pad sidepanel-trans';
                            }

                            if (angular.isDefined($scope.size) && $scope.size !== '' && parseInt($scope.size.slice(7)) < 13) {
                                var transWidth = 12 - parseInt($scope.size.slice(7));
                                if (parseInt($scope.size.slice(7) === '12')) transWidth = 12;
                                size = $scope.size.slice(0, 7) + transWidth.toString();
                            }

                            allClass = size + allClass;
                        }

                        return allClass;
                    };

                    $scope.fontSizes = {};

                    layoutService.onLayoutChanged($scope, function (info) {
                        if (angular.isUndefined(info)) return;

                        angular.copy(info.fontSizes, $scope.fontSizes);
                        $scope.style.treeView.fontSize = info.fontSizes.textSizeS;
                    });

                    function deepSort(list, parameter) {
                        if (angular.isUndefined(parameter)) parameter = 'orderby';

                        list.sort(function (a, b) {
                            return parseFloat(a[parameter]) - parseFloat(b[parameter]);
                        });

                        angular.forEach(list, function (child) {
                            if (angular.isDefined(child.items) && child.items.length > 0) {
                                deepSort(child.items, parameter);
                            }
                        });

                        return list;
                    }

                    function formatIcons(icons) {
                        var iconStr = icons;

                        if (icons.startsWith('fa-')) {
                            iconStr = 'fa ' + icons;
                        } else if (icons.startsWith('fab-')) {
                            iconStr = 'fab ' + icons.slice(0, 2) + icons.slice(3);
                        } else if (icons.startsWith('fad-')) {
                            iconStr = 'fad ' + icons.slice(0, 2) + icons.slice(3);
                        } else if (icons.startsWith('fal-')) {
                            iconStr = 'fal ' + icons.slice(0, 2) + icons.slice(3);
                        } else if (icons.startsWith('far-')) {
                            iconStr = 'far ' + icons.slice(0, 2) + icons.slice(3);
                        } else if (icons.startsWith('fas-')) {
                            iconStr = 'fas ' + icons.slice(0, 2) + icons.slice(3);
                        } else if (icons.startsWith('glyphicon ')) {
                            iconStr = icons;
                        } else {
                            iconStr = 'glyphicon ' + glyphStr;
                        }

                        return iconStr;
                    }

                    function listToTree(list) {
                        var tree = [], map = {}, node, i, hasOrderBy = true, hasCompKeyno = false;

                        // First map the nodes of the array to an object -> create a hash table.
                        if (list[0]?.comp_keyno) {
                            hasCompKeyno = true;
                            for (i = 0; i < list.length; i++) {
                                map[list[i].comp_keyno] = i;
                                list[i]['items'] = [];
                            }
                        } else {
                            for (i = 0; i < list.length; i++) {
                                map[list[i].item_id] = i;
                                list[i]['items'] = [];
                            }
                        }

                        for (i = 0; i < list.length; i++) {
                            node = list[i];

                            if (hasOrderBy === false || angular.isUndefined(node.orderby) || node.orderby === null || node.orderby === '') hasOrderBy = false;
                            if (node.isexpanded === '0') {
                                node.expanded = false;
                            } else if (node.isexpanded === '1') {
                                node.expanded = true;
                            }
                            if (angular.isDefined(node.item_glyphicon) && node.item_glyphicon !== null && node.item_glyphicon !== '') {
                                node.spriteCssClass = formatIcons(node.item_glyphicon);
                            }

                            if (node?.item_glyphicon_color && typeof node?.item_glyphicon_color === 'string' && node?.item_glyphicon_color?.trim().length > 0) {
                                const className = `tree-view__item--${node.item_glyphicon_color}`;
                                const stylesheet = document.styleSheets[0];

                                if (!doesCSSRuleExist(stylesheet, className)) {
                                    let style = `color: ${node.item_glyphicon_color};`;
                                    addCSSRuleToSheet(stylesheet, className, style);
                                }
                                node.spriteCssClass += ' ' + className;
                            }

                            if (angular.isDefined($scope.setup) && $scope.setup !== null && $scope.setup !== '' && node.item_id === $scope.setup) {
                                node.selected = true;
                            }

                            if (hasCompKeyno) {
                                // If the element is not at the root level, add it to its parent array of children.
                                if (node.comp_keyno_belongto && list[map[node.comp_keyno_belongto]]) {
                                    //list[map[node.item_id_parent]]['items'].push(node);
                                    list[map[node.comp_keyno_belongto]]['items'].push(node);
                                }
                                // If the element is at the root level, add it to first level elements array.
                                else {
                                    tree.push(node);
                                }
                            } else {
                                // If the element is not at the root level, add it to its parent array of children.
                                if (node.item_id_parent && list[map[node.item_id_parent]]) {
                                    //list[map[node.item_id_parent]]['items'].push(node);
                                    list[map[node.item_id_parent]]['items'].push(node);
                                }
                                // If the element is at the root level, add it to first level elements array.
                                else {
                                    tree.push(node);
                                }
                            }
                        }

                        if ($scope.autoSize === 'true') {
                            setTimeout(() => {
                                const rootContainerRect = document.getElementById($scope.id.rootContainer)?.getBoundingClientRect();
                                const section = document.querySelector('section');
                                //const scroll =  0;
                                const scroll = 60
                                const top = !!rootContainerRect ? `(${rootContainerRect.top}px + ${scroll}px)` : `379px + ${scroll}px`;

                                $scope.style.rootContainer = { ...$scope.style.rootContainer, height: '100%', maxHeight: 'fit-content', };
                                $scope.style.treeContainer = { ...$scope.style.treeContainer, height: `calc(100vh - ${top})`, maxHeight: 'max-content' };
                                $scope.style.treeView = { ...$scope.style.treeView, height: '100%', maxHeight: 'max-content', overflow: 'scroll!important' };
                            }, 500);
                        }
                        //if (hasOrderBy) deepSort(tree);
                        //console.dir(tree.length);

                        return tree;
                    }

                    function doesCSSRuleExist(stylesheet, className) {
                        let existingRule = [...stylesheet.cssRules].find((rule) => rule.selectorText === `.${className}`);

                        if (existingRule) {
                            return true;
                        }

                        return false;
                    }

                    function addCSSRuleToSheet(stylesheet, className, styles) {
                        if (stylesheet.insertRule) {
                            stylesheet.insertRule(`.${className} { ${styles} }`, 0);
                        }
                    }

                    $scope.treeOptions = {
                        dataTextField: "item_name",
                        select: $scope.onSelect,
                        //dataTextField: ["item_name"],
                        //click: onClick,
                        dataBound: $scope.onDataBound
                        //reset: $scope.onReset,
                        //pan: onPan,
                        //panEnd: onPanEnd,
                        //shapeClick: onShapeClick,
                        //shapeCreated: onShapeCreated,
                        //shapeFeatureCreated: onShapeFeatureCreated,
                        //shapeMouseEnter: onShapeMouseEnter,
                        //shapeMouseLeave: onShapeMouseLeave,
                        //zoomStart: onZoomStart,
                        //zoomEnd: onZoomEnd
                        //hide: function () {
                        //    console.log('map hide');
                        //    for (var l = 0; l < $scope.mapOptions.layers.length; l++) {
                        //        //$('#' + $scope.model.mId).data('kendoMap').layers[l].hide();
                        //        $scope.model.map.layers[l].hide();
                        //    }
                        //},
                        //show: function () {
                        //    console.log('map show');
                        //    $scope.model.map = $('#' + $scope.model.mId).data('kendoMap');
                        //}
                    };

                    $scope.model.tree = $('#' + $scope.model.mId).data('kendoTreeView');

                    $scope.$watch(function () {
                        return $scope.listItems;
                    }, function (newValue, oldValue) {
                        //if (angular.isUndefined($scope.listItems) || angular.isUndefined(newValue) || newValue === oldValue) return;  // --JLR 20232221 this prevented the treeview to reload if its hidden and then reappears as the value hasn't changed, the datasource was never reset, meaning no treeview.
                        if (angular.isUndefined($scope.listItems) || angular.isUndefined(newValue)) return;

                        //console.log('Scope-list:');
                        //console.dir($scope.listItems);

                        if (angular.isUndefined($scope.model.tree) || $scope.model.tree === null) {
                            //console.log('scope treeview');
                            //console.dir($('#' + $scope.model.mId));
                            //if (angular.isUndefined($('#' + $scope.model.mId).data('kendoTreeView')) || $('#' + $scope.model.mId).data('kendoTreeView') === null) return;
                            $scope.model.tree = $('#' + $scope.model.mId).data('kendoTreeView');
                        }

                        var treeDataList = [];

                        angular.copy(listToTree($scope.listItems), treeDataList);

                        $scope.model.tree.setDataSource(new kendo.data.HierarchicalDataSource({ data: treeDataList }));
                    }, true);

                    //$scope.$watch(function () {
                    //    return $scope.setup;
                    //}, function (newValue, oldValue) {
                    //    if (angular.isUndefined($scope.setup.encodingtype) || $scope.setup.encodingtype === '' || angular.isUndefined(newValue)) return;
                    //});
                }
            };

            return directive;
        }]);
})();
