(function() {
    'use strict';

    angular.module("imApp").factory("calculationTextilemodelService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var atou = function (data) { // Utility function for base64decode with unicode support
            return decodeURIComponent(escape(atob(data)));
        }

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        var service = {
            //updatePrice: function (method, parms) {
            updatePrice: function (parameters) {
                //return call(angular.isDefined(method) && method !== null ? method : 1999, { p2_datatask_keyno: keyno } || {});
                //return call(angular.isDefined(method) && method !== null ? method : 2220, parms);
                //return call(2220, parms);
                return call(2220, parameters || {});
            },
            updateTargetPrice: function (parameters) {
                return call(2631, parameters || {});
            },
            recalculate: function (parameters) {
                return call(2640, parameters || {});
            },
            remember: function (method, rememberId, value, isBase) {
                return call(angular.isDefined(method) && method !== null ? method : 616, { // for use if need to have a different method
                    is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (method, rememberId) {
                return call(angular.isDefined(method) && method !== null ? method : 973, { variablename: rememberId }); // for use if need to have a different method
            }
        }

        return service;
    }]);
})();
