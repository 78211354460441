(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2Table', {
        templateUrl: 'views/components/views/p2Table/p2Table.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['p2TableService', 'stateService', function (p2TableService, stateService) {
            var vm = this;

            vm.model = {
                itemsListTables: []
            };

            p2TableService.listTables().then(function (result) {
                angular.copy(result, vm.model.itemsListTables);
            });
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2tableedit':
                        stateService.go(state, {
                            p2_table_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();