(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productMoveChoices', {
        templateUrl: 'views/components/views/productMoveChoices/productMoveChoices.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'productMoveChoicesService', function ($stateParams, productMoveChoicesService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                setting: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_productmovechoices_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            productMoveChoicesService.getProductMoveChoices({ prod_id: prodId }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

                vm.grid.dataTask.loadData = {
                    method: 2892,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();