import { Chart, ChartConfiguration, ChartData, ChartDataset, ChartOptions, ChartType, LineController } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WidgetComponent } from '../widget/widget.component';

@Component({
    selector: 'tt-widget-chart',
    templateUrl: './widget-chart.component.html',
    styleUrls: ['./widget-chart.component.css'],
})
export class WidgetChartComponent implements OnInit, OnChanges {
    @Input() ttChartType?: ChartType;

    @Input() ttData?: ChartData;

    @Input() ttOptions?: ChartOptions;

    @Input() ttPreventPointerEvents = false;

    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    protected elementId = {
        chartContainer: crypto.randomUUID(),
    };

    public barChartOptions: ChartConfiguration['options'] = {
        maintainAspectRatio: false,
        responsive: true,
        animation: {
            duration: 400,
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'start',

                labels: {
                    color: '#fff',
                    pointStyle: 'dash',
                    boxHeight: 12,
                    boxWidth: 12,
                },
                title: {
                    color: '#fff',
                },
            },
        },
    };

    public barChartType?: ChartType = this.ttChartType;

    public barChartData?: ChartData = this.ttData;

    public updateChart(): void {
        this.chart?.update();
    }

    ngOnInit(): void {}

    private setupChart() {
        const customColors = ['#8a3ffc', '#33b1ff', '#08bdba', '#ff7eb6', '#fa4d56', '#fff1f1', '#6fdc8c', '#4589ff', '#d12771', '#d2a106', '#007d79', '#bae6ff'];
        if (this.barChartType) {
            Chart.defaults.datasets[this.barChartType].backgroundColor = customColors.map((color) => color + 'AA');
            Chart.defaults.datasets[this.barChartType].hoverBackgroundColor = customColors;
            Chart.defaults.datasets[this.barChartType].borderColor = customColors;
            Chart.defaults.borderColor = 'rgba(255, 255, 255, 0.1)';
            Chart.defaults.color = '#fff';
        }

        if (this.barChartType === 'line') {
            // Chart.defaults.datasets[this.barChartType].pointBackgroundColor = customColors;
            // Chart.defaults.datasets[this.barChartType].pointBorderColor = '#fff';
            // Chart.defaults.datasets[this.barChartType].pointBorderWidth = 2;
            // Chart.defaults.datasets[this.barChartType].pointHoverBackgroundColor
            Chart.defaults.datasets[this.barChartType].backgroundColor = customColors.map((color) => color + '33');

            this.barChartData?.datasets.forEach((dataset, index) => {
                if (this.isDataLineChartDataset(dataset)) {
                    dataset.pointHoverBackgroundColor ??= customColors[index];
                    dataset.backgroundColor ??= customColors[index] + '33';
                    dataset.borderColor ??= customColors[index];
                    dataset.pointBackgroundColor ??= customColors[index];
                    dataset.pointBorderColor ??= customColors[index];
                    dataset.pointBorderWidth ??= 3;
                }
            });
        }

        if (this.barChartType === 'bar' && this.barChartData?.datasets) {
            const datasetsLength = this.barChartData.datasets.length;

            this.barChartData.datasets.forEach((dataset, index) => {
                // dataset.backgroundColor ??= customColors[index % datasetsLength] + 'AA';
                dataset.backgroundColor ??= customColors[index % datasetsLength];

                dataset.hoverBackgroundColor ??= customColors[index % datasetsLength];
                dataset.borderColor ??= customColors[index % datasetsLength];
            });
        }
    }

    private isDataLineChartDataset(data: ChartDataset): data is ChartDataset<'line'> {
        return true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttChartType']?.currentValue && changes['ttChartType'].currentValue !== changes?.['ttChartType'].previousValue) {
            this.barChartType = changes['ttChartType'].currentValue;
        }

        if (changes?.['ttData']?.currentValue && changes['ttData'].currentValue !== changes?.['ttData'].previousValue) {
            this.barChartData = changes['ttData'].currentValue;
        }

        if (changes?.['ttOptions']?.currentValue && changes['ttOptions'].currentValue !== changes?.['ttOptions'].previousValue) {
            this.barChartOptions = { ...this.barChartOptions, ...changes['ttOptions'].currentValue };
        }

        console.log(this.ttChartType);
        console.dir(this.ttData);
        if ((changes?.['ttChartType']?.currentValue && changes['ttChartType'].currentValue !== changes?.['ttChartType'].previousValue) || (changes?.['ttData']?.currentValue && changes['ttData'].currentValue !== changes?.['ttData'].previousValue)) {
            this.setupChart();
        }
        this.chart?.update();
    }
}
