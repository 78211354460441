(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('usergroupSetting', {
        templateUrl: 'views/components/views/usergroupSetting/usergroupSetting.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'modalService', 'translateService', 'usergroupSettingService', function ($stateParams, modalService, translateService, usergroupSettingService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let itemId = $stateParams.item_id;

            let translations = {
                confirm_title: '',
                confirm_switch_off_all_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                itemsListUserGroupSettings: [],
                showEnableButton: false,
                showDisableButton: false,
                lockedEnable: false,
                lockedDisable: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTIONS

            let loadUserGroupSettings = function () {
                usergroupSettingService.listUserGroupSettings({ p2_setting_keyno: itemId }).then(function (list) {
                    angular.copy(list, vm.model.itemsListUserGroupSettings);

                    angular.forEach(vm.model.itemsListUserGroupSettings, function (item) {
                        if (item.item_glyphicon === 'glyphicon-check') {
                            vm.model.showEnableButton = false;
                            vm.model.showDisableButton = true;
                        } else {
                            vm.model.showEnableButton = true;
                            vm.model.showDisableButton = false;
                        }
                    }); 
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region ENABLE ALL BUTTON FUNCTION
            
            vm.enableAll = function () {
                vm.model.lockedEnable = true;
    
                usergroupSettingService.changeAllItems({
                    p2_setting_keyno: itemId,
                    onoff: '1'
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedEnable = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedEnable = false;
                        vm.model.showEnableButton = false;
                        vm.model.showDisableButton = true;

                        loadUserGroupSettings();
                    }
                });
            };

            // #endregion ENABLE ALL BUTTON FUNCTION

            // #region DISABLE ALL BUTTON FUNCTION
            
            vm.disableAll = function () {
                vm.model.lockedDisable = true;
                
                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_switch_off_all_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    usergroupSettingService.changeAllItems({
                        p2_setting_keyno: itemId,
                        onoff: '0'
                    }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDisable = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDisable = false;
                            vm.model.showEnableButton = true;
                            vm.model.showDisableButton = false;

                            loadUserGroupSettings();
                        }
                    }, function () {
                        vm.model.lockedDisable = false;
                    });
                }, function () {
                    vm.model.lockedDisable = false;
                });
            };

            // #endregion DISABLE ALL BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadUserGroupSettings();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
