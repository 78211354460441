(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('salaryTypeLang', {
        templateUrl: 'views/components/views/salaryTypeLang/salaryTypeLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'salaryTypeLangService', function ($stateParams, stateService, salaryTypeLangService) {
            var vm = this;
            
            vm.model = {
            	dataSource: {
            		records: []
            	}
            };
            
            salaryTypeLangService.loadTranslations($stateParams.salarytype_id).then(function () {
                vm.model.dataSource = salaryTypeLangService.dataSource;
            });
            
            vm.saveTranslations = function () {
                salaryTypeLangService.saveTranslations(vm.model.dataSource).then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();