(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('businessCo', {
        templateUrl: 'views/components/views/businessCo/businessCo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'businessCoService', 'logisticService', 'typeaheadService', function ($stateParams, stateService, translateService, modalService, businessCoService, logisticService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoNo = $stateParams.businessco_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListStockPlaces: [],
                lockedSave: false
            };

            vm.style = {
                logo: {
                    img: {
                        maxWidth: '100%',
                        height: 'auto',
                        maxHeight: '4.5rem',
                        aspectRatio: 'auto',
                    }
                }
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                businessCoService.getEdit({ businessco_no: businessCoNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadStockPlaces = function () {
                logisticService.listStocks({ businessco_no: '0' }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'cust_name':
                        if (item) {
                            vm.model.edit.cust_no = item?.item_id ?? '0';
                            vm.model.edit.cust_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_no = '0';
                        vm.model.edit.cust_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                businessCoService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadStockPlaces();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
