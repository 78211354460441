(function () {
    'use strict';

    angular.module("imApp").factory("bislabSetupService", ['$ihttp', function ($ihttp) {
        const service = {

            get: function (businesscoNo) {
                return $ihttp.post({ method: 3027, parameters: { businessco_no: businesscoNo } });
            },
            save: function (get) {
                return $ihttp.post({ method: 3026, parameters: get });
            },
            delete: function () {
                //return $ihttp.post({ method: 1000, parameters: {} });
            },
        };

        return service;
    }]);
})();
