import { Injectable } from '@angular/core';
import { Deferred } from '../../../core/models/deferred.model';
import { DataCache } from '../data-cache';

enum CacheStatus {
    Initializing = 10,
    Ready = 20
}

interface CacheItem {
    status: CacheStatus;
    data: any;
    handlers: Deferred<void>[];
}

@Injectable({
  providedIn: 'root'
})
export class DataCacheService {
    private _caches: Record<string, CacheItem> = {};

    constructor() { }

    public async GetCache<T>(id: string): Promise<DataCache<T>> {
        await this.InitCache<T>(id);
        
        return this._caches[id].data as DataCache<T>;
    }

    public async InitCache<T>(id: string): Promise<void> {
        if (id in this._caches) {
            switch(this._caches[id].status) {
                case CacheStatus.Initializing:
                    const handler = new Deferred<void>();

                    this._caches[id].handlers.push(handler);

                    return handler.promise;
                default:
                    return;
            }
        }

        this._caches[id] = {
            status: CacheStatus.Initializing,
            data: undefined,
            handlers: []
        } as CacheItem;
        
        let result = await DataCache.Create<T>(id);

        if (result.isFailure) {
            throw new Error(result.error as string);
        }

        this._caches[id].data = result.getValue();

        this._caches[id].status = CacheStatus.Ready;

        let callbacks = this._caches[id].handlers.splice(0);

        this._caches[id].handlers = [];

        callbacks.forEach(function (cb) {
            cb.resolve();
        });
    }

    public async ReloadCache<T>(id: string): Promise<void> {
        delete this._caches[id];

        await this.InitCache<T>(id);
    }
}
