<ng-container *ngIf="!ttLoading">
    <button
        [attr.title]="translations['tooltiptext']"
        [attr.type]="ttButtonType"
        (click)="ttClick.emit($event)"
        class="col-xs-12 sp-0 tt-button"
        [ngClass]="'tt-button--' + ttType + ' ' + (!!ttPrinter ? 'tt-button--printer' : '')"
        [ngStyle]="style['button']"
        [disabled]="ttDisabled"
    >
        <ng-container *ngIf="ttIconAlign === 'left'" [ngTemplateOutlet]="icon"></ng-container>
        <span *ngIf="!!ttPrinter">{{ ttPrinter }}</span>
        <span *ngIf="!!ttText && !ttPrinter">{{ translations["text"] }}</span>
        <span *ngIf="!!ttBadge" class="tt-button__badge" [ngStyle]="style['badge']">{{ ttBadge }}</span>
        <ng-container *ngIf="ttIconAlign === 'right'" [ngTemplateOutlet]="icon"></ng-container>
    </button>
</ng-container>

<ng-container *ngIf="ttLoading">
    <button [attr.title]="translations['tooltip']" class="col-xs-12 sp-0 tt-button" [ngClass]="'tt-button--' + ttType" [ngStyle]="style['lockedButton']" disabled>
        <ng-conatiner *ngIf="ttIconAlign === 'left'" [ngTemplateOutlet]="loadingIcon"></ng-conatiner>
        <span>{{ translations["loadingtext"] }}</span>
        <ng-container *ngIf="ttIconAlign === 'right'" [ngTemplateOutlet]="loadingIcon"></ng-container>
    </button>
</ng-container>

<ng-template #icon>
    <span class="tt-button__icon" [ngClass]="ttIcon + (ttSpin === true ? ' fa-spin' : '') + (ttIconPullRight === true ? ' tt-button__icon--pull-right' : '')"></span>
</ng-template>

<ng-template #loadingIcon>
    <span
        *ngIf="ttIconAlign === 'right'"
        class="tt-button__icon"
        [ngClass]="'fa-fw fas fa-spin fa-spinner-third' + (ttIconPullRight === true ? ' tt-button__icon--pull-right' : '')"
        [ngStyle]="style['icon']"
    ></span>
</ng-template>
