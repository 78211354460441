/******************************************************************
auth: bhw
description: 
mods:       
*******************************************************************/
(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlans', {
        templateUrl: 'views/components/views/consignmentPlans/consignmentPlans.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'rememberService', 'stateService', 'consignmentPlansService', function ($timeout, rememberService, stateService, consignmentPlansService) {
            var vm = this;

            let reload;
            var editing = false;

            vm.model = {
                bgJobStatus: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'consignmentplans_now', item_func: 'consignmentplans_now' },
                        { item_id: '1', item_name: 'consignmentplans_not_finished', item_func: 'consignmentplans_not_finished' },
                        { item_id: '2', item_name: 'consignmentplans_finished', item_func: 'consignmentplans_finished' }
                    ]
                },
                mconfig: {
                    enabled: true,		// Is the menu button enabled
                    show: false,		// Is the menu visible or not
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfig.records[0].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.consignmentPlansResize();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else {
                            vm.model.mconfig.records[0].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                editing = true;
                            } else {
                                vm.consignmentPlansResize();
                            }
                        }
                        if (item.item_name === 'columns') {
                            if (item.isActive) {
                                editing = true;
                            } else {
                                editing = false;
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '1', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '2', item_name: 'columns', glyph: 'fa-columns', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                setting: {},
                itemsListPlans: []
            };

            // ## IF CONDITIONS FUNCTION ##

            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.setting[parmWithValue]) === true) return false;
                if (vm.model.setting[parmWithValue] === null) return false;
                if (vm.model.setting[parmWithValue].trim().length < 1) return false;

                return true;
            };

            var validateParmsEmptyValue = function (parmEmptyValue) {
                if (angular.isUndefined(vm.model.setting[parmEmptyValue]) === true) return false;
                if (vm.model.setting[parmEmptyValue] === null) return false;

                return true;
            };

            var validateVariables = function (variableName) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName.trim().length < 1) return false;

                return true;
            };

            var validateNewOldValue = function (newVal, oldVal) {
                if (angular.isUndefined(newVal) === true) return false;
                if (angular.isUndefined(oldVal) === true) return false;
                if (newVal === oldVal) return false;

                return true;
            };

            var validateNewOldValueLength = function (newVal, oldVal) {
                if (newVal.length > 16) return;
                if (oldVal.length > 16) return;

                return true;
            };

            // ## INIT LOAD FUNCTION ##

            var initLoadDone = false;
            var timerInitLoadDone = null;

            var initLoadFunc = function () {
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }

                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };

            // ## LOAD PROCEDURE CALLS ##

            consignmentPlansService.loadConsignmentPlansGet().then(function (data) {
                vm.model.setting = angular.copy(data[0]);

                loadConsignmentPlans();
                initLoadFunc();
            });

            var loadConsignmentPlans = function () {
                if (angular.isUndefined(vm.model.bgJobStatus.item_selected) === true) return;
                if (vm.model.bgJobStatus.item_selected === null) return;
                if (vm.model.bgJobStatus.item_selected.trim().length < 1) return;

                if (validateParmsWithValue('date_fom') !== true) return;
                if (validateParmsWithValue('date_tom') !== true) return;

                consignmentPlansService.loadConsignmentPlansList(vm.model.bgJobStatus.item_selected, 'w_' + stateService.getCurrentName() + '.' + vm.model.bgJobStatus.item_selected, vm.model.setting.date_fom, vm.model.setting.date_tom).then(function (data) {
                    angular.copy(data, vm.model.itemsListPlans);
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var rememberDateFom = function () {
                var variableNameDateFom = 'w_consignmentplans.date_fom';

                if (initLoadDone !== true) return;
                if (validateVariables(variableNameDateFom) !== true) return;
                if (validateParmsEmptyValue('date_fom') !== true) return;

                rememberService.remember(variableNameDateFom, vm.model.setting.date_fom);
            };

            var rememberDateTom = function () {
                var variableNameDateTom = 'w_consignmentplans.date_tom';

                if (initLoadDone !== true) return;
                if (validateVariables(variableNameDateTom) !== true) return;
                if (validateParmsEmptyValue('date_tom') !== true) return;

                rememberService.remember(variableNameDateTom, vm.model.setting.date_tom);
            };

            var rememberDateSelectorIndex = function () {
                var variableNameDateSelectorIndex = 'w_consignmentplans.dateselector_index';

                if (initLoadDone !== true) return;
                if (validateVariables(variableNameDateSelectorIndex) !== true) return;
                if (validateParmsEmptyValue('dateselector_index') !== true) return;

                rememberService.remember(variableNameDateSelectorIndex, vm.model.setting.dateselector_index);
            };

            vm.selectBgJobStatus = function (item) {
                vm.model.bgJobStatus.item_selected = item;
                loadConsignmentPlans();
            };

            // ## DRAG AND DROP SORT FUNCTION PROCEDURE CALLS ##

            vm.consignmentPlansReorder = function (item) {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
                var updateParms = {
                    custact_no: vm.model.custactNo,
                    records: vm.model.itemsListConsignmentOns
                };

                for (var a = 0; a < vm.model.mconfig.records.length; a++) {
                    if (angular.isDefined(vm.model.mconfig.records[a]) && vm.model.mconfig.records[a].item_id === '99') {
                        vm.model.mconfig.records[a].animate = false;
                        break;
                    }
                }

                $timeout(function () {
                    vm.model.mconfig.records[a].animate = null;
                }, 2000);

                //consignmentPlanService.updateOrder(updateParms).then(function (data) {
                //    if (data[0].errorcode !== '0') {
                //        modalService.show({
                //            type: 'warning',
                //            title: 'Varsel',
                //            message: data[0].errormessage,
                //            buttons: [{
                //                label: 'OK',
                //                cssClass: 'btn-warning',
                //                action: function (dialogItself) {
                //                    dialogItself.close();
                //                }
                //            }]
                //        });
                //    } else {
                //        console.dir(data);

                //        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                //            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                //                vm.model.mconfig.records[m].animate = false;
                //                break;
                //            }
                //        }

                //        $timeout(function () {
                //            vm.model.mconfig.records[m].animate = null;
                //        }, 2000);
                //    }
                //});
            };

            // ## RESIZE COLUMN WIDTH FUNCTION PROCEDURE CALLS ##

            vm.consignmentPlansResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }

                var updateList = [];

                if (angular.isDefined(vm.model.itemsListPlans[0]) && angular.isDefined(vm.model.itemsListPlans[0].item_secondary)) {
                    for (var r = 0; r < vm.model.itemsListPlans[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.itemsListPlans[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.itemsListPlans[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.itemsListPlans[0].item_secondary[r].item_col_width })
                        }
                    }
                }

                var updateParms = {
                    view_id: 'w_' + stateService.getCurrentName() + '.' + vm.model.bgJobStatus.item_selected,
                    records: updateList
                };

                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        console.dir(data);

                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }

                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }

                    editing = false;
                });
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'consignmentplan':
                        go({ custact_no: '0' });
                        break;
                    case 'consignmentplanbuild':
                        go({ custact_no: '0' });
                        break;
                }
            };

            // reload = consignmentPlansService.reloadConsignmentPlansList();

            // ## WATCH FUNCTIONS ##

            //watcher.$watch(function () { return vm.model.bgJobStatus.item_selected; }, function (newValue, oldValue) {
            //    if (angular.isUndefined(newValue) === true) return;
            //    if (angular.isUndefined(oldValue) === true) return;
            //    if (newValue === oldValue) return;

            //    loadConsignmentPlans();
            //});

            //watcher.$watch(function () {
            //    return vm.model.setting.date_fom;
            //}, function (newValue, oldValue) {
            //    if (validateNewOldValue(newValue, oldValue) !== true) return;

            //    loadConsignmentPlans();
            //    rememberDateFom();
            //});

            //watcher.$watch(function () {
            //    return vm.model.setting.date_tom;
            //}, function (newValue, oldValue) {
            //    if (validateNewOldValue(newValue, oldValue) !== true) return;

            //    loadConsignmentPlans();
            //    rememberDateTom();
            //});

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval;

                if (vm.model.setting.date_fom) rememberDateFom();

                if (vm.model.setting.date_tom) rememberDateTom();

                if (vm.model.setting.dateselector_index) rememberDateSelectorIndex();

                if (vm.model.setting.date_fom && vm.model.setting.date_tom) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadConsignmentPlans();
                }
            }


            //watcher.$watch(function () {
            //    return vm.model.setting.keyno;
            //}, function (newValue, oldValue) {
            //    if (validateNewOldValue(newValue, oldValue) !== true) return;
            //    if (validateNewOldValueLength(newValue, oldValue) !== true) return;

            //    rememberDateSelectorIndex();
            //});

            //watcher.$watch(function () { return consignmentPlansService.reloaded; }, function (newValue) {
                

            //    if (newValue === false) return;
            //    console.log('consignmentPlansService.reloaded');

            //    reload = consignmentPlansService.reloadConsignmentPlansList();

            //    if (!editing) {
            //        loadConsignmentPlans();
            //    }
            //});

            function reloadInterval() {
                consignmentPlansService.reloadConsignmentPlansList();

                if (!editing) {
                    loadConsignmentPlans();
                }
            }

            vm.$onInit = function () {
                reload = setInterval(reloadInterval, 15000);
            }

            vm.$onDestroy = function () {
                clearInterval(reload);
            }
        }]
    });
})();
