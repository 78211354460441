(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('unitLang', {
        templateUrl: 'views/components/views/unitLang/unitLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'unitLangService', function ($stateParams, stateService, modalService, translateService, unitLangService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let unitNo = $stateParams.unit_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgUnitName: {
                    selected: 'shortname',
                    buttons: [
                        { id: 'shortname', label: 'shortname', color: 'primary', onClick: () => vm.model.bgUnitName.selected = 'shortname' },
                        { id: 'longname', label: 'longname', color: 'primary', onClick: () => vm.model.bgUnitName.selected = 'longname' }
                    ]
                },
                inputListLanguageNames: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadUnitLanguages = function () {
                unitLangService.listUnitLanguages({ unit_no: unitNo }).then(function (result) {
                    angular.copy(result, vm.model.inputListLanguageNames);
                });
            };
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                unitLangService.saveObj({ records: vm.model.inputListLanguageNames }).then(function (response) {
                    if (response[0].status_no !== '') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].status_message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgUnitName = function (value) {
                vm.model.bgUnitName.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadUnitLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
