import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
// prettier-ignore
import { ColDef, SizeColumnsToFitGridStrategy, ModuleRegistry, SideBarDef, GridReadyEvent, GridApi, ValueGetterParams, ValueFormatterParams, ValueSetterParams, ICellEditorParams, FilterModel, ColumnResizedEvent, FilterChangedEvent, ColumnMovedEvent, ColumnVisibleEvent, RowClassParams, ICellRendererParams, SortChangedEvent, CellValueChangedEvent, GetRowIdParams, CellClassParams, PaginationChangedEvent, ColumnPinnedEvent, FirstDataRenderedEvent, DomLayoutType, DataTypeDefinition, IHeaderParams, CellEditingStoppedEvent, CellClickedEvent, RowModelType, IServerSideDatasource, IServerSideGetRowsRequest, CellKeyDownEvent, FullWidthCellKeyDownEvent, CellEditingStartedEvent, IRowNode,  EditableCallbackParams,  RowSelectedEvent, IMultiFilterParams, ToolPanelDef, SetFilterParams, ModelUpdatedEvent, SetFilterModel, IFilter } from '@ag-grid-community/core';
import { GridButton, LookupCellEditorParams, GridOptions, isEditableLookup, GridUserSettings, GridColumn, LookupConfig, GridColumnSetting, ColumnLayoutSchema, GridFilterable, GridSortSetting, GridFilterSetting, GridToolbar, DBGridButton, DBGridOptions, GridRow, GridDataTask, ChangeState, TTGridAggregate, ServerSideRequestParams, GridSidebarConfig, CellDataTypes, ToggleButton, GridFilterType } from './grid.types';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { AgGridAngular } from '@ag-grid-community/angular';
import { GridLoadingOverlayComponent } from './grid-loading-overlay/grid-loading-overlay.component';
import { LayoutService } from '@app/core/services/layout.service';
import { Style } from '@app/core/services/core-component.service';
import { exportToPDF } from './grid-pdf-export';
import { DateTimeCellEditorComponent } from './cell-editors/date-time-cell-editor/date-time-cell-editor.component';
import { UserStore } from '@app/core/services/user.store';
import { RichSelectModule } from '@ag-grid-enterprise/rich-select';
import { LookupCellEditorComponent } from './cell-editors/lookup-cell-editor/lookup-cell-editor.component';
import { NumberCellEditorComponent, NumberCellEditorParams } from './cell-editors/number-cell-editor/number-cell-editor.component';
import { GridService } from './grid.service';
import { CheckboxFloatingFilterComponent } from './floating-filters/checkbox-floating-filter/checkbox-floating-filter.component';
import { SymbolCellRendererComponent, SymbolCellRendererParams } from './cell-renderers/symbol-cell-renderer/symbol-cell-renderer.component';
import { from, of, BehaviorSubject, Subject, Subscription, debounceTime } from 'rxjs';
import { TranslateService } from '@app/core/services/translate.service';
import { DataTaskService } from '@app/core/services/data-task.service';
import { DatataskEvent, GridFunctionsCellRendererComponent, GridFunctionsCellRendererComponentParams, ModalEvent, NavigateEvent } from './cell-renderers/grid-functions-cell-renderer/grid-functions-cell-renderer.component';
import { formatDate } from '@angular/common';
import { LinkCellRendererComponent, LinkCellRendererParams } from './cell-renderers/link-cell-renderer/link-cell-renderer.component';
import { ProgressModalComponent } from './modals/progress-modal/progress-modal.component';
import { UserService } from '@app/core/services/user.service';
import { CheckboxColumnHeaderComponent } from './column-headers/checkbox-column-header/checkbox-column-header.component';
import { ThumbnailCellRendererComponent } from './cell-renderers/thumbnail-cell-renderer/thumbnail-cell-renderer.component';
import { GridModalService } from './modals/grid-modal.service';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AG_GRID_LOCALE_EN } from '@ag-grid-community/locale';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { DEFAULT_GRID_OPTIONS } from './grid.model';
import { AppSettingsService } from '@app/core/services/app-settings.service';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { DateCellEditorComponent } from './cell-editors/date-cell-editor/date-cell-editor.component';
import { DATE_FORMATS } from '@app/core/models/date-adapter';
import { DateFloatingFilterComponent } from './floating-filters/date-floating-filter/date-floating-filter.component';
import { DateFilterSelectionComponent } from './filters/date-filter-selection/date-filter-selection.component';
import { StateService } from '@app/core/services/state.service';
import { UtilityService } from '@app/core/services/utility.service';
import { ModalService } from '../../services/modal.service';

ModuleRegistry.registerModules([ClientSideRowModelModule, ColumnsToolPanelModule, FiltersToolPanelModule, SetFilterModule, ExcelExportModule, RichSelectModule, ServerSideRowModelModule, ClipboardModule, MenuModule, RangeSelectionModule, MultiFilterModule]);

@Component({
    selector: 'tt-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class GridComponent extends ComponentBaseComponent implements OnInit, OnChanges, OnDestroy {
    /**
     * The Grid-configuration object to initialize the grid options with.
     */
    @Input()
    public ttOptions!: GridOptions;

    /**
     * Event emmited when changes are made to the tt-options object, (like grid-functions being created).
     */
    @Output()
    public ttOptionsChange = new EventEmitter<GridOptions>();

    /**
     * Event emitted before a modal is opened.
     */
    @Output()
    public ttOpenModal = new EventEmitter<ModalEvent>();

    /**
     * Event emitted when opening print modal.
     */
    @Output()
    public ttOpenPrintModal = new EventEmitter();

    /**
     * Event emitted before navigating.
     */
    @Output()
    public ttNavigate = new EventEmitter<NavigateEvent>();

    /**
     * Event emitted when a datatask from a button is to be called.
     */
    @Output()
    public ttDatatask = new EventEmitter<DatataskEvent>();

    @Output()
    public ttRowDataChange = new EventEmitter();

    @Output()
    public ttReady = new EventEmitter();

    /**
     * Event emitted when displayed row data has changed through sort, filter, tree expand and collapse events.
     */
    @Output()
    public ttModelUpdated = new EventEmitter<ModelUpdatedEvent>();

    /**
     * Handles shortcuts and custom keydown events for grid on document.
     *
     * @param event the keydown event on document.
     */
    @HostListener('document:keydown', ['$event'])
    public async onDoucmentKeydown(event: KeyboardEvent) {
        try {
            if (event && this.gridService.isActiveGrid(this.id['grid'])) {
                if (this.ttOptions.kendo?.selectable !== false && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
                    const rowIndex = this.gridApi?.getFocusedCell()?.rowIndex ?? -1;

                    if (event.shiftKey) {
                        this.selectRow(rowIndex, false);
                    } else {
                        this.selectRow(rowIndex);
                    }
                }

                if (this.ttOptions.config?.shortcuts === true && event.ctrlKey) {
                    switch (event.code) {
                        case 'KeyI':
                            await this.shortcutInsertRow(event);
                            break;
                        case 'KeyD':
                            await this.shortcutDeleteRows(event);
                            break;
                        default:
                            break;
                    }
                }
            }
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    /**
     * Grid configuration recieved from database, if any.
     */
    private dbGridOptions?: DBGridOptions;

    /**
     * The merged grid configurations of db and `ttOptions`, linked to the ag-grid component.
     */
    public options: GridOptions = { ...DEFAULT_GRID_OPTIONS() };

    /**
     * Reference to this instance's toolbar.
     */
    @ViewChild('toolbar')
    public toolbar!: ElementRef;

    /**
     * Reference to this instance's AG-grid.
     */
    @ViewChild(AgGridAngular)
    public agGrid?: AgGridAngular;

    /**
     * The currently applies user settings on the grid.
     */
    private userSettings?: GridUserSettings = {
        page: 1,
        pageSize: 25,
        columns: {},
        filter: { filters: [] },
        sort: [],
    };

    /**
     * AG-grid api for this instance of AG-grid.
     */
    private gridApi?: GridApi;

    /**
     * The language texxt to use for in grid.
     */
    public localeText = AG_GRID_LOCALE_EN;

    /**
     * The row model type used for this grid.
     */
    public rowModelType?: RowModelType;

    /**
     * The number of rows to retrieve per request when using server-side row model.
     */
    public cacheBlockSize?: number = undefined;

    /**
     * The data-source for server-side row model. Should be kept undefined if row model is client-side.
     */
    public serverSideDataSource?: IServerSideDatasource = undefined;

    /**
     * Observable for save function.
     */
    private saveUserSettingsSubject = new Subject<void>();

    /**
     * Subscription for the save observable.
     */
    private saveSubscription?: Subscription;

    /**
     * Observable fro the redrawing of rows in grid.
     */
    private refreshGridSubject = new Subject<void>();

    /**
     * Subscription of the refresh grid subject.
     */
    private refreshSubscription?: Subscription;

    /**
     * Observable over the grid's saving state.
     */
    private savingGridData = new BehaviorSubject(false);

    /**
     * The theme of the grid.
     */
    public gridTheme = 'ag-theme-quartz';

    /**
     * Overlay for when the grid is loading data..
     */
    public loadingOverlayComponent = GridLoadingOverlayComponent;

    /**
     * The dom layout for this grid, defining how the height of the grid should be sized.
     */
    public domLayout: DomLayoutType = 'autoHeight';

    /**
     * Auto size configuration.
     */
    public autoSizeStrategy: SizeColumnsToFitGridStrategy = { type: 'fitGridWidth', defaultMinWidth: 40 };

    /**
     * Sidebar configuration for the grid.
     */
    public sidebarDefinition?: SideBarDef | null | boolean | string | string[] = null;

    /**
     * Custom column types.
     */
    public columnTypes = {
        centerAligned: {
            cellClass: 'tt-grid__cell--center',
            headerClass: 'tt-grid__cell--center',
        },
    };

    /**
     * Default settings for columns.
     */
    public defaultColumnDefinitions: ColDef = {
        minWidth: 40,
        // width: 100,
        enableValue: true,
        enableRowGroup: true,
        enablePivot: true,
        suppressHeaderMenuButton: true,
        sortable: this.options.kendo!.sortable,
        autoHeight: true,
        wrapText: false,
        filter: true,
        floatingFilter: true,
        contextMenuItems: ['copy', 'copyWithHeaders', 'copyWithGroupHeaders'],
    };

    /**
     * Default configuration of row groups created with columns side panel.
     */
    public autoGroupColumnDef: ColDef = {
        filter: 'agGroupColumnFilter',
    };

    /**
     * Customs data-types definitions.
     */
    public dataTypeDefinitions: { [cellDataType: string]: DataTypeDefinition } = {
        datetime: {
            baseDataType: 'date',
            extendsDataType: 'date',
        },
    };

    /**
     * Returns the current row selection mode.
     */
    public get rowSelection(): 'single' | 'multiple' | undefined {
        return this.options.kendo!.selectable! === true || ((this.options.kendo?.selectable as any) instanceof Object && (this.options.kendo?.selectable as any)?.mode === 'row') ? 'single' : this.options.kendo!.selectable === false ? undefined : this.options.kendo!.selectable;
    }

    public components: { [p: string]: any } = { agDateInput: DateFilterSelectionComponent };

    /**
     * Ids of elements in the component.
     */
    public id: { [key: string]: string } = {
        grid: crypto.randomUUID(),
    };

    /**
     * Styling for the grid.
     */
    public style: Style = {
        grid: {},
    };

    /**
     * Translation of text used in this component.
     */
    public translations: { [key: string]: string } = {
        export_to_excel: '',
        export_to_pdf: '',
        toggle_filtering: '',
        clear_all_filters: '',
        toggle_field_name: '',
        redraw_data: '',
        reload_data: '',
        rebind_grid: '',
        add_row_over: '',
        add_row_below: '',
        delete_row: '',
        save: '',
        toggle_text_wrapping: '',
        ttgrid_modal_layouts_heading: '',
        print: '',
        grid_functions: '',
        grid_glyphs: '',
        is_selected: '',
    };

    constructor(private renderer: Renderer2, layoutService: LayoutService, private userStore: UserStore, public gridService: GridService, private translateService: TranslateService, private datatask: DataTaskService, private userService: UserService, private gridModalService: GridModalService, private appSettings: AppSettingsService, private state: StateService, private utility: UtilityService, private modalService: ModalService) {
        super();

        userStore.themeKeynoChanged.subscribe((keyno) => {
            if (`${keyno}` === '1') {
                this.gridTheme = 'ag-theme-quartz';
            } else {
                this.gridTheme = 'ag-theme-alpine-dark';
            }
        });

        layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                // @ts-ignore variable not defined by `CSSStyleDeclaration` type.
                this.style['grid']['--ag-font-size'] = info.fontSizes.textSize;
            }
        });

        this.saveSubscription = this.saveUserSettingsSubject.pipe(debounceTime(500)).subscribe(() => this.saveUserSettings());
        this.refreshSubscription = this.refreshGridSubject.pipe(debounceTime(500)).subscribe(() => this.gridApi?.redrawRows());
    }

    /**
     * Gets the classes for the row represented in the given parameters.
     *
     * @param params the parameters to use for retrieving the classes.
     * @returns the classes to apply to the row or `undefined`.
     */
    public getRowClass = (params: RowClassParams) => {
        // TODO: and aggregate
        if (params.node.rowPinned === 'bottom') {
            return 'tt-grid__row--aggregate';
        }

        if (this.ttOptions.config?.css?.altColor == true && params.rowIndex % 2 === 1) {
            return 'tt-grid__row--alternate';
        }

        return undefined;
    };

    /**
     * Gets the row id of the row represented in the given parameters.
     *
     * @param params the parameters to use for retrieving the row id.
     * @returns the row is.
     */
    public getRowId = (params: GetRowIdParams) => {
        if (!!this.options?.dataTask?.loadData?.primaryKey) {
            return params.data[this.options.dataTask.loadData.primaryKey];
        }

        return params.data._uuid;
    };

    // #region FOOTER AGGREGATE

    /**
     * Formats the number with seperators.
     *
     * @param number the number to format.
     * @param decimals how many decimals to format the number with.
     * @returns a formatted string representation of the number.
     */
    private formatNumber(number: number | string, decimals?: number | null): string {
        const formatter = new Intl.NumberFormat('nb-NO', { maximumFractionDigits: decimals || undefined, minimumFractionDigits: decimals || undefined });

        if (typeof number === 'string') number = parseFloat(number);

        if (!isNaN(number)) {
            return formatter.format(number);
        }

        return number.toFixed(decimals || 0).replace('.', ',');
    }

    /**
     * Calculates the aggregate for the given column using the given aggregate function.
     *
     * @param aggregateFunction the aggregate function to calculate with.
     * @param colDef the field to calculate the aggregate for.
     * @returns a string or number containing the aggregation value.
     */
    private getAggregateValue(aggregate: TTGridAggregate, colDef: ColDef, rows: GridRow[], footerRowData: GridRow): number | string {
        let value: number | string = '';

        switch (aggregate.aggregate) {
            case 'count':
                value = 'Total Count: ' + rows.filter((row) => !!row[colDef.field!]).length;
                break;
            case 'sum':
                value = rows.reduce((sum, row) => sum + (Number(row[colDef.field!]) || 0), 0);
                break;
            case 'average':
                value = 'Average: ' + this.formatNumber(rows.reduce((sum, row) => sum + (Number(row[colDef.field!]) || 0), 0) / rows.filter((row) => !!row[colDef.field!]).length, 2);
                break;
            case 'min':
            case 'max':
                value = 'Min: ' + Math.min(...rows.map((row) => Number(row[colDef.field!]))) + ' Max: ' + Math.max(...rows.map((row) => Number(row[colDef.field!])));
                break;
            case 'custom':
                if (aggregate.aggregate_function) {
                    let aggregateFormula = aggregate.aggregate_function;

                    for (let [key, value] of Object.entries(footerRowData)) {
                        aggregateFormula = aggregateFormula.replaceAll(key, value);
                    }

                    try {
                        value = Function('return ' + aggregateFormula)();
                    } catch (error) {
                        value = '';
                    }
                }
                break;
            default:
                console.error('Invalid aggregate function >>: ', colDef);
                value = '';
        }

        return value;
    }

    /**
     * Creates a row with sum aggregate of every number column and sets it as fixed to the bottom..
     */
    private updateFooterRow(rows?: GridRow[]) {
        const footerRowData: { [key: string]: number | string } = {};
        const rowsToAggregate: GridRow[] = [];

        this.gridApi?.forEachNodeAfterFilter((node) => {
            if (node.isRowPinned() === false && node.group !== true) {
                rowsToAggregate.push(node.data);
            }
        });

        this.options.data!.columnDefinitions.forEach((colDef) => {
            // if (this.options.kendo?.aggregate === true) {
            if (!!colDef.field && this.options.data?.columnInfo?.[colDef.field].decimalWidth === 17) {
                footerRowData[colDef.field] = rowsToAggregate.reduce((sum, row) => sum + (Number(row[colDef.field!]) || 0), 0);
            }

            if (this.options.kendo?.aggregate && this.options.kendo.aggregate instanceof Array) {
                this.options.kendo.aggregate.forEach((aggregate) => {
                    if (colDef.field === aggregate.field) {
                        footerRowData[aggregate.field!] = this.getAggregateValue(aggregate, colDef, rowsToAggregate, footerRowData);
                    }
                });
            }
        });

        if (this.options.kendo?.aggregate) {
            this.gridApi?.setGridOption('pinnedBottomRowData', [footerRowData]);
        } else {
            this.gridApi?.setGridOption('pinnedBottomRowData', undefined);
        }
    }

    // #endregion FOOTER AGGREGATE

    // #region COLUMN CONFIGURATION

    /**
     * Maps the given list of grid columns to AG-grid ColDef.
     *
     * @param columns the list of columns to map to AG-grid ColDef.
     * @returns a list of objects that can be used for AG-Grid ColDef.
     */
    private mapColumnsToColDef(columns: GridColumn[]): ColDef[] {
        const allColumns: ColDef[] = [];

        this.options.data!.columnInfo = {};
        columns.forEach(
            (column: GridColumn) =>
                (this.options.data!.columnInfo![column.colname] = {
                    colname: column.colname,
                    from: column.colname,
                    title: column.title,
                    remember: !!column.title,
                    decimalWidth: column.width,
                    type: this.getCellDataType(column),
                    editType: column.edittype,
                    dd_data: column.dd_data,
                    dd_data_id: column.dd_data_id,
                    dd_data_name: column.dd_data_name,
                    editable: column.editable,
                })
        );

        this.options.data!.hasGridGlyphs = false;
        this.options.data!.hasGotoParms = false;
        this.options.data!.hasThumbnails = false;
        this.options.data!.hasSelection = false; //TODO: or not todo, that is the question. ('cause what if we want a selection column without the default behaviour of ag-grid?, anyway not implemented)

        let colDefs = columns
            .map((column) => {
                if (column.colname === 'item_glyphicon' || column.colname === 'item_glyphicon_color') {
                    this.options.data!.hasGridGlyphs = true;
                    return null;
                }

                if (column.colname === 'item_state' || column.colname === 'item_parms' || column.colname === 'item_path') {
                    this.options.data!.hasGotoParms = true;
                    return null;
                }

                if (column.colname === 'item_thumb') {
                    this.options.data!.hasThumbnails = true;
                }

                return this.createColDefFromGridColumn(column);
            })
            .filter((column) => column !== null) as ColDef[];

        if (this.options.kendo?.selectable === 'multiple' || this.options.data?.hasSelection) {
            allColumns.push(this.createCheckboxSelectionColumn());
        }

        if (this.options.data!.hasGridGlyphs) {
            allColumns.push(this.createSymbolColumn());
        }

        if (this.options.data!.hasGotoParms || this.options.data!.hasSpecialFuncEdit || this.options.config?.specialFunc?.buttons?.some((button) => !button.type || (button.type !== 'noclick' && button.type !== 'cell'))) {
            allColumns.push(this.createGridFunctionsColumn());
        }

        allColumns.push(...colDefs);

        return allColumns;
    }

    /**
     * Creates a column definition from the given grid column.
     *
     * @param column the grid column object to create a column definition for.
     * @returns the column defintiion based on the given grid column.
     */
    private createColDefFromGridColumn(column: GridColumn): ColDef {
        return <ColDef>{
            colId: column.colname,
            field: column.colname,
            headerName: column.title || '',
            headerClass: (_) => this.getHeaderClasses(column),
            cellStyle: (params: CellClassParams) => this.getColumnCellStyle(column, params),
            cellClass: (params: CellClassParams) => this.getCellClass(column, params),
            width: !!column.colwidth ? column.colwidth : null,
            type: this.getColumnTypes(column),
            filter: this.getFilterComponent(column),
            sort: column?.sort || undefined,
            sortIndex: column.sort_index === null ? undefined : column.sort_index,
            // floatingFilter: true,
            floatingFilterComponent: this.getColumnFloatingFilterType(column),
            filterParams: this.getFilterParams(column),
            headerComponent: this.getHeaderComponent(column),
            headerComponentParams: (params: IHeaderParams) => this.getHeaderComponentParams(params, column),
            suppressFloatingFilterButton: this.isCheckboxColumn(column),
            cellRenderer: this.getCellRenderer(column),
            cellRendererParams: (params: ICellRendererParams) => this.getCellRendererParams(params, column),
            cellEditor: this.getCellEditor(column),
            cellEditorParams: (params: ICellEditorParams) => this.getCellEditorParams(column, params),
            hide: column.visibility !== 'visible',
            cellDataType: this.getCellDataType(column),
            suppressNavigable: (event: any) => this.gridApi?.getPinnedBottomRow(0)?.data._uuid === event.node.data._uuid,
            editable: (event: EditableCallbackParams) => this.isEditable(column, event),
            sortable: !!column.sortable ? column.sortable !== '0' : undefined,
            valueFormatter: (params: ValueFormatterParams) => this.valueFormatter(params, column),
            valueGetter: (params: ValueGetterParams) => this.valueGetter(params, column),
            valueSetter: (params: ValueSetterParams) => this.valueSetter(params, column),
        };
    }

    /**
     * Creates the column defintion for the grid function column, a column used for displaying row buttons.
     *
     * @returns column definition for grid function column.
     */
    private createGridFunctionsColumn(): ColDef {
        this.options.data!.columnInfo!['grid_functions'] = { colname: 'grid_functions', title: this.translations['grid_functions'], remember: true, decimalWidth: null, type: 'alt' };

        return <ColDef>{
            colId: 'grid_functions',
            field: 'grid_functions',
            headerName: this.translations['grid_functions'],
            pinned: 'left',
            filter: false,
            floatingFilter: false,
            autoHeight: true,
            suppressAutoSize: true,
            suppressSizeToFit: true,
            cellRenderer: GridFunctionsCellRendererComponent,
            cellRendererParams: (params: ICellRendererParams) => <GridFunctionsCellRendererComponentParams>{ ...params, specialFunc: this.options.config?.specialFunc, options: this.options, openModalEventEmitter: this.ttOpenModal, navigateEventEmitter: this.ttNavigate, datataskEventEmitter: this.ttDatatask },
        };
    }

    /**
     * Creates the column definition for a symbol column.
     *
     * @returns column definition for a symbol column.
     */
    private createSymbolColumn(): ColDef {
        this.options.data!.columnInfo!['grid_glyphs'] = { colname: 'grid_glyphs', title: 'Symbol', remember: true, decimalWidth: null, type: 'icon' };

        return <ColDef>{
            colId: 'grid_glyphs',
            field: 'grid_glyphs',
            headerName: this.translations['grid_glyphs'],
            headerClass: 'symbol-header',
            width: 90,
            filter: false,
            wrapText: true,
            cellRenderer: SymbolCellRendererComponent,
            cellRendererParams: (params: ICellRendererParams) => <SymbolCellRendererParams>{ ...params, iconKeyname: 'item_glyphicon', iconColorKeyname: 'item_glyphicon_color' },
            suppressFloatingFilterButton: true,
            suppressHeaderMenuButton: true,
            editable: false,
            sortable: false,
        };
    }

    /**
     * Creates the column definition for a checkbox selection column.
     *
     * @returns column definition for a checkbox delection column.
     */
    private createCheckboxSelectionColumn(): ColDef {
        this.options.data!.columnInfo!['isselected'] = { colname: 'isselected', title: '', remember: true, decimalWidth: null, type: 'boolean' };

        return <ColDef>{
            colId: 'isselected',
            field: 'isselected',
            pinned: 'left',
            lockPinned: true,
            lockPosition: true,
            // lockVisible: true,
            hide: false,
            headerName: this.translations['is_selected'],
            headerClass: 'tt-checkbox-header',
            headerCheckboxSelection: this.rowSelection === 'multiple',
            checkboxSelection: true,
            showDisabledCheckboxes: true,
            floatingFilterComponent: false,
            suppressFloatingFilterButton: true,
            suppressMovable: true,
            type: 'centerAligned',
            width: 40,
        };
    }

    /**
     * Returns a list of css classes to apply the column header of the given column.
     *
     * @param column the column to configure headerclasses for.
     * @returns a list of css classes to apply the to given header column.
     */
    getHeaderClasses(column: GridColumn) {
        const classes: string[] = [];

        if (this.options.data?.columnInfo?.[column.colname]?.clickonly === true) {
            classes.push('manual-edit-header');
        } else if (isEditableLookup(column.editable)) {
            classes.push('lookup-header');
        } else if (this.isEditable(column)) {
            classes.push('edit-header');
        }
        if (column.align === 'center') classes.push('tt-grid__cell--center');
        if (column.align === 'right' || column.coltype?.startsWith('N')) classes.push('tt-grid__cell--right');

        return classes;
    }

    /**
     * Returns a list of types to apply to the given column based on it'r properties.
     *
     * @param column the column to retrieve list of types for.
     * @returns a list of types to apply to the column.
     */
    private getColumnTypes(column: GridColumn): string[] {
        const types: string[] = [];

        if (column.align === 'right') {
            types.push('rightAligned');
        } else if (column.align === 'center') {
            types.push('centerAligned');
        }

        if (column.coltype?.startsWith('N')) {
            types.push('numericColumn');
        }

        return types;
    }

    /**
     * Returns the appropriate styles based on the given column and parameters.
     * @param column
     * @param params
     * @returns
     */
    private getColumnCellStyle(column: GridColumn, params: CellClassParams): Partial<CSSStyleDeclaration> | undefined {
        try {
            if (!!column.style_script && !!params.data && !params.node.isRowPinned()) {
                return new Function('row', column.style_script).call(this, params.data);
            }

            return undefined;
        } catch (error) {
            return undefined;
        }
    }

    private getCellClass(column: GridColumn, params: CellClassParams) {
        const classes: string[] = [];
        const buttonConfig = this.options.config!.specialFunc!.buttons?.find((button: any) => button?.name === column.colname);

        if (!!buttonConfig && (!buttonConfig.type || buttonConfig.type === 'cell')) {
            classes.push('tt-cell-goto');
        }

        if (column.align === 'right' || column.coltype?.startsWith('N')) {
            classes.push('tt-grid__cell--right');
        }

        if (column.align === 'center' || this.getCellDataType(column) === 'boolean') {
            classes.push('tt-grid__cell--center');
        }

        return classes;
    }

    /**
     * Returns the ag-grid cell data type equivelant of the coltype property on the given column.
     *
     * @param column the column to find the ag-grid cell data type equivelant for.
     * @returns the ag-grid cell data type for the given column.
     */
    private getCellDataType(column: GridColumn): CellDataTypes {
        // if (isEditableLookup(column)) {
        //     return 'object';
        // }

        switch (column.coltype) {
            case 'currency':
                return 'currency';
            case 'decimal':
            case 'integer':
            case 'number':
            case 'numeric':
            case 'tinyint':
            case 'smallint':
            case 'unsigned int':
                return 'number';
            case 'date':
                // case 'time':
                // case 'datetime':
                // case 'timestamp':
                return 'date';
            case 'datetime':
            case 'timestamp':
                return 'datetime';
            case 'time':
                return 'time';
            case 'bit':
            case 'bool':
            case 'boolean':
                return 'boolean';
            default:
                return 'text';
        }
    }

    /**
     * Formats the cells to how they should be displayed in the grid, does not change the underlying value of the cell.
     *
     * @param params the value formatter parameters to format.
     * @returns a string used for displaying the value in the grid.
     */
    private valueFormatter(params: ValueFormatterParams, column: GridColumn): string {
        const value = params.data?.[params.column.getColId()] ?? params.value;

        try {
            if (value === null || value === 'null') {
                return '';
            } else if (params.colDef.cellDataType === 'datetime' && value) {
                return formatDate(new Date(value), DATE_FORMATS.display.dateInput.format + ', HH:mm', DATE_FORMATS.display.dateInput.language || navigator.language || navigator.languages[0] || 'nb-NO');
                // return Intl.DateTimeFormat(navigator?.language || navigator.languages[0] || 'nb-NO', { dateStyle: 'short', timeStyle: 'medium' }).format(new Date(value));
            } else if (params.colDef.cellDataType === 'date' && value) {
                if (params.value instanceof Date) {
                    return formatDate(new Date(value), DATE_FORMATS.display.dateInput.format, DATE_FORMATS.display.dateInput.language || navigator.language || navigator.languages[0] || 'nb-NO');
                } else {
                    return value;
                }
                // return Intl.DateTimeFormat(navigator?.language || navigator.languages[0] || 'nb-NO', { dateStyle: 'short', timeStyle: undefined }).format(new Date(value));
            } else if (params.colDef.cellDataType === 'boolean') {
                return ['1', 1, true].includes(value).toString();
            } else if (column.coltype === 'decimal') {
                const formatter = new Intl.NumberFormat('nb-NO', { maximumFractionDigits: column.decimals || undefined, minimumFractionDigits: column.decimals || undefined });

                if (params.node?.group && params.node?.aggData && params.node.aggData[params.colDef.field!]) {
                    return formatter.format(Number(params.node.aggData[params.colDef.field!]));
                } else if (value !== undefined && value !== null && value !== '' && !isNaN(Number(value))) {
                    return formatter.format(value).replaceAll(' ', ' ').replaceAll('−', '-');
                }
            }
        } catch (error) {
            return value;
        }

        return value;
    }

    /**
     * Parses the values to native types.
     *
     * @param params value getter parameters to parse.
     * @returns
     */
    private valueGetter(params: ValueGetterParams, column: GridColumn): any {
        if (column.coltype === 'bit') {
            return ['1', 1, true].includes(params.data?.[params.column.getColId()]);
        }

        switch (params.colDef.cellDataType) {
            case 'boolean':
                return ['1', 1, true].includes(params.data?.[params.column.getColId()]);
            case 'number':
            case 'formatNumber':
                if (params.data?.[params.column.getColId()]) {
                    if (!isNaN(Number(params.data?.[params.column.getColId()]))) {
                        return parseFloat(params.data?.[params.column.getColId()]);
                    }
                    return params.data?.[params.column.getColId()];
                } else {
                    return null;
                }
            case 'date':
                const dateString = params.data?.[params.column.getColId()];

                if (dateString && typeof dateString === 'string') {
                    const dateParts = dateString.split('-');

                    return dateParts.length === 3 ? new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])) : null;
                } else if (dateString && dateString instanceof Date && dateString.toString() !== 'Invalid Date') {
                    return dateString;
                } else {
                    return null;
                }
            default:
                return params.data?.[params.column.getColId()];
        }
    }

    /**
     * Sets the new value when a cell has been edited.
     *
     * @param params value setter params.
     * @param column column configuration for the cell that was edited.
     * @returns true if a cell has been edited, false if not.
     */
    private valueSetter(params: ValueSetterParams, column: GridColumn): boolean {
        if (isEditableLookup(column.editable)) {
            const lookupConfig = <LookupConfig>column.editable;
            const newValue: { [key: string]: string } | string | null = !!params.newValue && params.newValue.startsWith('{') && params.newValue.endsWith('}') ? JSON.parse(params.newValue) : !!params.newValue ? params.newValue : null;

            if (typeof newValue === 'object') {
                params.data[params.column.getColId()] = newValue?.['item_name'] ?? '';

                lookupConfig.relations.forEach((relation) => {
                    if (newValue?.[relation.value] !== undefined && newValue?.[relation.value] !== null) {
                        params.data[relation.key] = newValue?.[relation.value] ?? null;
                    }
                });
            } else {
                params.data[params.column.getColId()] = newValue;
            }

            if (this.ttOptions.optionfunc) {
                this.ttOptions.optionfunc({ data: { field: params.column.getColId(), func: 'LookupCellEditor', item: newValue, rowIdx: params.node?.rowIndex } });
            }
        } else if (params.column.getColId() === 'is_selected') {
            params.data[params.column.getColId()] = params.newValue;
            params.data._dirty = true;
            return true;
        } else {
            params.data[params.column.getColId()] = params.newValue;
        }

        if (this.getCellDataType(column) === 'date') {
            function getDateString(date: unknown) {
                if (date instanceof Date && date.toString() !== 'Invalid Date') {
                    date.setHours(8);
                    return date.toISOString().substring(0, 10);
                }

                return '';
            }

            const newDateString = getDateString(params.newValue);
            const oldDateString = getDateString(params.oldValue);

            if (newDateString !== oldDateString) {
                params.data._dirty = true;
                this.options.data!.changes![params.node!.id!] = { state: 'update', data: params.node?.data };
            }
        } else {
            if (params.newValue?.toString() !== params.oldValue?.toString()) {
                params.data._dirty = true;
                // const rowData = this.options.data?.rowData.find((row) => params.node?.id === row._uuid);
                // if (rowData) {
                //     rowData._dirty = true;
                // }
                this.options.data!.changes![params.node!.id!] = { state: 'update', data: params.node?.data };
            }
        }

        if (this.options.dataTask?.saveData?.autoSave === true) {
            (async () => {
                await this.saveChanges('update');
                if (!!this.ttOptions.optionfunc && this.ttOptions.optionfunc instanceof Function) {
                    this.ttOptions?.optionfunc({ data: { func: 'OnCellClose', postSave: true, ridx: params.node?.rowIndex, cidx: this.options.data?.columnDefinitions.indexOf(params.colDef), cval: column.colname, change: params.data[column.colname], rdata: params.data } });
                }

                if (this.options.dataTask?.saveData?.readAfterSave === true) {
                    this.readData();
                }

                this.gridApi?.onFilterChanged();
            })();
        }

        this.updateGridRow(params.data);
        this.gridApi?.onFilterChanged();

        if (!!this.ttOptions.optionfunc && this.ttOptions.optionfunc instanceof Function) {
            this.ttOptions?.optionfunc({ data: { func: 'OnCellClose', postSave: false, ridx: params.node?.rowIndex, cidx: this.options.data?.columnDefinitions.indexOf(params.colDef), cval: column.colname, change: params.data[column.colname], rdata: params.data } });
        }

        this.ttRowDataChange.emit({ colId: params.column.getColId(), columnInfo: this.options.data!.columnInfo![params.column.getColId()], newValue: params.newValue, oldValue: params.oldValue, row: params.data });

        try {
            if (!!this.ttOptions.config?.onDataChanged && this.ttOptions.config?.onDataChanged instanceof Function) {
                this.ttOptions.config.onDataChanged(params);
            }
        } catch (error) {
            return true;
        }

        return true;
    }

    /**
     * Updates the data source and the grid with the value of the given grid row. Uses _uuid to match the rows so the row can contain changes.
     *
     * @param row the row to update with updated values.
     */
    private updateGridRow(row: GridRow) {
        const rowIndex = this.options.data?.rowData.findIndex((rowItem) => rowItem?._uuid === row?._uuid);

        if (rowIndex !== undefined && rowIndex !== -1) {
            this.options.data!.rowData[rowIndex] = row;
            this.gridApi?.getRowNode(row._uuid!)?.updateData({ ...row })!;
        }
    }

    private getHeaderComponent(column: GridColumn) {
        if (this.isEditableCheckboxColumn(column)) {
            return CheckboxColumnHeaderComponent;
        }
        return undefined;
    }

    private getHeaderComponentParams(params: IHeaderParams, column: GridColumn) {
        if (this.isEditableCheckboxColumn(column)) {
            return {
                ...params,
                ttGridOptions: this.options,
                checkboxChanged: (key: string) => this.ttOptions.optionfunc?.({ data: { func: 'CheckboxHeaderClick', data: this.options.data?.rowData, key: key } }),
            };
        }

        return params;
    }

    /**
     * Gets the cell renderer for the given grid column. `null` uses default renderer.
     *
     * @param column the column to get cell renderer for.
     * @returns cell renderer for the given column.
     */
    private getCellRenderer(column: GridColumn): any {
        const buttonConfig = this.options.config!.specialFunc!.buttons?.find((button: any) => button?.name === column.colname);

        if (!!buttonConfig && (!buttonConfig.type || buttonConfig.type === 'cell')) {
            return LinkCellRendererComponent;
        } else if (column.coltype === 'bit') {
            return 'agCheckboxCellRenderer';
        } else if (column.colname === 'item_thumb') {
            return ThumbnailCellRendererComponent;
        }

        return undefined;
    }

    /**
     * Gets the cell renderer parameters for the cell renderer based on the given column.
     *
     * @param params the default parameters provided by ag agrid.
     * @param column the column to retrieve cell renderer parameters for.
     * @returns cell renderer parameters for the given column.
     */
    private getCellRendererParams(params: ICellRendererParams, column: GridColumn): ICellRendererParams | LinkCellRendererParams {
        const buttonConfig = this.options.config!.specialFunc?.buttons?.find((button: any) => button?.name === column.colname);

        if (!!buttonConfig && (!buttonConfig.type || buttonConfig.type === 'cell')) {
            return <LinkCellRendererParams>{ ...params, button: buttonConfig, loadData: this.options.dataTask?.loadData, rememberId: this.options.dataTask?.rememberId, navigateEventEmitter: this.ttNavigate };
        }

        return params;
    }

    /**
     * Gets the appropriate cell editor component to use for the given column.
     *
     * @param column the column to get the cell editor for.
     * @returns a string or class of the component to use for cell editing.
     */
    private getCellEditor(column: GridColumn): string | typeof DateTimeCellEditorComponent | typeof DateCellEditorComponent | typeof LookupCellEditorComponent | typeof NumberCellEditorComponent | null {
        // if (column.edittype === 'CB') {
        if (this.isCheckboxColumn(column)) {
            return 'agCheckboxCellEditor';
        } else if (column.coltype === 'date') {
            // return 'agDateCellEditor';
            return DateCellEditorComponent;
        } else if (column.coltype === 'datetime' || column.coltype === 'timestamp') {
            return DateTimeCellEditorComponent;
        } else if (isEditableLookup(column.editable)) {
            return LookupCellEditorComponent;
        } else if (column.edittype?.startsWith('N')) {
            return NumberCellEditorComponent;
        }

        return null;
    }

    /**
     * Gets cell editor parameters for the given grid column and basic cell editor parameter.
     *
     * @param column the grid column to get cell editor parameters for.
     * @param params the basic cell editor parameters from ag-grid.
     * @returns cell editor paramters.
     */
    private getCellEditorParams(column: GridColumn, params: ICellEditorParams): ICellEditorParams | LookupCellEditorParams | NumberCellEditorParams {
        if (isEditableLookup(column.editable)) {
            const lookupConfig = <LookupConfig>column.editable;

            return <LookupCellEditorParams>{
                ...params,
                method: lookupConfig.lookup,
                field: column.colname,
                keyname: lookupConfig?.datatextfield || 'item_name',
                key: lookupConfig.key,
                onKeyDown: (event: KeyboardEvent) => this.onCellKeyDown(null, event, column.colname, params.node.rowIndex),
            };
        } else if (column.edittype?.startsWith('N')) {
            return <NumberCellEditorParams>{
                ...params,
                formatNumber: column.coltype === 'decimal',
                decimals: column.decimals,
                length: column.width,
            };
        }

        return params;
    }

    /**
     * Retrieves the floating filter type to use for the given grid column.
     *
     * @param column the grid column to find floating filter type for.
     * @returns the floating filter type to use for the given grid column.
     */
    private getColumnFloatingFilterType(column: GridColumn): string | boolean | typeof CheckboxFloatingFilterComponent | typeof DateFloatingFilterComponent | undefined {
        if (column.filterable === '0') return false;

        let filterType: string | boolean | typeof CheckboxFloatingFilterComponent | typeof DateFloatingFilterComponent | undefined = true;

        if (this.isCheckboxColumn(column)) {
            filterType = CheckboxFloatingFilterComponent;
        } else if (this.isDateColumn(column) || this.isDateTimeColumn(column)) {
            filterType = undefined;
        } else if (column.coltype === 'null' || column.coltype === null) {
            filterType = false;
        }

        return filterType;
    }

    /**
     * Retrieves the filter type for the given grid column to be used in multi-filter.
     *
     * @param column the grid column to find the main filter type for in a multi-filter.
     * @returns the filter type to use for the given grid column in a multi-filter.
     */
    private getMultiFilterColumnFilter(column: GridColumn): string | boolean {
        if (column.filterable === '0') return false;

        let filterType: string | boolean = true;

        if (column.coltype?.includes('char') || this.isCheckboxColumn(column)) {
            filterType = 'agTextColumnFilter';
            // } else if (column.coltype === 'decimal' || column.coltype === 'integer' || column.coltype === 'numeric' || column.coltype === 'unsigned int') {
        } else if (this.isNumberColumn(column)) {
            filterType = 'agNumberColumnFilter';
        } else if (this.isDateColumn(column) || this.isDateTimeColumn(column)) {
            filterType = 'agDateColumnFilter';
        } else if (column.coltype === 'null' || column.coltype === null) {
            filterType = false;
        }

        return filterType;
    }

    private getMultiFilterColumnFilterParams(column: GridColumn) {
        let filterParams: undefined | any;

        if (this.isDateTimeColumn(column)) {
            filterParams = {
                comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                    let date = !!cellValue ? new Date(cellValue.substring(0, 10)) : null;

                    date?.setHours(filterLocalDateAtMidnight.getHours());
                    date?.setMinutes(filterLocalDateAtMidnight.getMinutes());
                    date?.setSeconds(filterLocalDateAtMidnight.getSeconds());
                    date?.setMilliseconds(filterLocalDateAtMidnight.getMilliseconds());

                    if (!date || date < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (date > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            };
        } else {
            filterParams = {
                buttons: ['clear'],
            };
        }

        return filterParams;
    }

    /**
     * Retrieves the floating filter component to be used in a multi filter type for the given grid-column.
     *
     * @param column the column to retrieve the floating filter component for used in a multi-filter.
     * @returns the floating filter component to use for the given column.
     */
    private getMultiFilterFloatingFilter(column: GridColumn) {
        if (column.filterable === '0') return;

        let filterType: string | undefined;

        if (this.isCheckboxColumn(column)) {
            filterType = undefined;
            // filterType = CheckboxFloatingFilterComponent;
            // filterType = false;
        } else if (this.isDateColumn(column) || this.isDateTimeColumn(column)) {
            filterType = 'agDateColumnFloatingFilter';
        } else if (column.coltype === 'null' || column.coltype === null) {
            filterType = undefined;
        }

        return filterType;
    }

    private getFilterComponent(column: GridColumn) {
        if (this.isCheckboxColumn(column)) {
            return 'agTextColumnFilter';
        } else if (this.options.config?.serverSideHandling !== true) {
            return 'agMultiColumnFilter';
        } else {
            return this.getMultiFilterColumnFilter(column);
        }
    }

    private getFilterParams(column: GridColumn): undefined | IMultiFilterParams {
        if (this.isCheckboxColumn(column)) {
            return undefined;
            // } else if (this.isDateTimeColumn(column)) {
            //     return this.getMultiFilterFloatingFilter(column);
        } else if (this.options.config?.serverSideHandling !== true) {
            return {
                filters: [
                    {
                        filter: this.getMultiFilterColumnFilter(column),
                        floatingFilterComponent: this.getMultiFilterFloatingFilter(column),
                        floatingFilterComponentParams: { buttons: ['clear'] },
                        filterParams: this.getMultiFilterColumnFilterParams(column),
                    },
                    {
                        filter: 'agSetColumnFilter',
                        floatingFilterComponent: 'agSetColumnFloatingFilter',
                        filterParams: <SetFilterParams>{
                            convertValuesToStrings: true,
                            defaultToNothingSelected: true,
                            buttons: ['clear'],
                            valueFormatter: (params: ValueFormatterParams) => this.valueFormatter(params, column),
                        },
                    },
                ],
            };
        } else {
            return;
        }
    }

    /**
     * Checks whether the given column is editable or not.
     *
     * @param column the column to check if is editable.
     * @returns `true` if the column is editable, `false` if not.
     */
    private isEditable(column: GridColumn, event?: EditableCallbackParams): boolean {
        if (event && this.gridApi?.getPinnedBottomRow(0)?.data._uuid === event.node.data._uuid) return false;

        const editColumn = this.options.config?.editColumns?.find((editCol) => editCol.key === column.colname);

        if (editColumn) {
            if (!!editColumn.clickonly || (typeof column.editable === 'object' && (column.editable as LookupConfig).clickonly)) {
                this.options.data!.columnInfo![column.colname].clickonly = editColumn.clickonly || (column.editable as LookupConfig).clickonly;
            }

            if (!!editColumn.lookup) {
                this.options.data!.columnInfo![column.colname].editable = editColumn.lookup;
                return true;
            }

            return true;
        }

        if (typeof column.editable === 'object' && column.editable?.clickonly === true) {
            this.options.data!.columnInfo![column.colname].clickonly = true;
            return true;
        }

        return isEditableLookup(column.editable) || column.editable === true;
    }

    private isDateTimeColumn(column: GridColumn) {
        return this.getCellDataType(column) === 'datetime';
    }

    private isDateColumn(column: GridColumn) {
        return this.getCellDataType(column) === 'date';
    }

    private isNumberColumn(column: GridColumn) {
        return this.getCellDataType(column) === 'number';
    }

    /**
     * Checks whether the given column is a checkbox column.
     *
     * @param column the column to check if is a checkbox column.
     * @returns `true` if the given column is a checkbox column, `false` if not.
     */
    private isCheckboxColumn(column: GridColumn): boolean {
        return this.getCellDataType(column) === 'boolean';
    }

    /**
     * Checks if the given column is an editable checkbox column.
     * The predicate is that the column must be of boolean type and editable must be `true`, unless it is the `ìs_selected` column, where the default if editable `true` and thus will not be editable only if editable is `false`.
     *
     * @param column the column definition to check if is an editable checkbox column.
     * @returns `true` if the column is an editable checkbox column, `false` if not.
     */
    private isEditableCheckboxColumn(column: GridColumn): boolean {
        return this.isCheckboxColumn(column) && (column.editable === true || (column.colname === 'is_selected' && column.editable !== false));
    }

    // #endregion COLUMN CONFIGURATION

    // #region TOOLBAR

    /**
     * List of toolbar buttons to display in this instance's toolbar.
     */
    toolbarButtons: GridButton[] = [
        { name: 'excelExport', ariaLabel: 'export_to_excel', translate: true, icon: 'fal fa-file-excel', func: (event) => this.exportToExcel(event) },
        { name: 'pdfExport', ariaLabel: 'export_to_pdf', translate: true, icon: 'fal fa-file-pdf', func: () => this.exportToPdf() },
        { name: 'filter', ariaLabel: 'toggle_filtering', translate: true, icon: 'fal fa-filter', func: () => this.toggleFloatingFilters() },
        { name: 'filter', ariaLabel: 'clear_all_filters', translate: true, icon: 'fal fa-filter', secondIcon: 'fal fa-times tt-grid__button-icon--secondary', func: () => this.clearAllFilters() },
        // { name: 'edit', ariaLabel: 'Rediger rad', icon: 'fal fa-pencil', func: () => console.log('edit') },
        { name: 'headers', ariaLabel: 'toggle_field_name', translate: true, icon: 'k-icon k-i-group-box', func: () => this.toggleFieldNameAndTitle() },
        { name: 'refresh', ariaLabel: 'redraw_data', translate: true, icon: 'fal fa-redo', func: () => this.refreshData() },
        { name: 'read', ariaLabel: 'reload_data', translate: true, icon: 'fal fa-redo', func: () => this.readData() },
        { name: 'rebind', ariaLabel: 'rebind_grid', translate: true, icon: 'fal fa-redo', func: () => this.reloadData(true) },
        { name: 'addBefore_addSimple', ariaLabel: 'add_row_over', translate: true, icon: 'k-icon k-i-insert-up', func: () => this.addRowBefore() },
        { name: 'addAfter', ariaLabel: 'add_row_below', translate: true, icon: 'k-icon k-i-insert-down', func: () => this.addRowAfter() },
        { name: 'delete', ariaLabel: 'delete_row', translate: true, icon: 'fal fa-trash', func: () => this.removeSelectedRows() },
        { name: 'save', ariaLabel: 'save', translate: true, icon: 'fal fa-save', func: () => this.saveAllChanges() },
        { name: 'wrapping', ariaLabel: 'toggle_text_wrapping', translate: true, icon: 'k-icon k-i-text-wrap', func: () => this.toggleTextWrapping() },
        { name: 'layouts', ariaLabel: 'ttgrid_modal_layouts_heading', translate: true, icon: 'k-icon k-i-table-properties', func: () => this.openColumnLayoutDialog() },
        { name: 'print', ariaLabel: 'print', translate: true, icon: 'fal fa-print', func: () => this.openPrintDialog() },
    ];

    /**
     * Exports the grid data to a excel file.
     */
    private exportToExcel(event: any) {
        // hacky lazy check, but stops excel from being downloaded sometimes
        if (event.pointerType === 'mouse' || document.activeElement?.classList.contains('tt-grid__toolbar-button')) this.gridApi?.exportDataAsExcel();
    }

    /**
     * Exports the grid data to a pdf.
     */
    private exportToPdf() {
        if (this.gridApi && this.agGrid) {
            exportToPDF(this.gridApi);
        }
    }

    /**
     * Toggles the view of floating filters.
     */
    private toggleFloatingFilters() {
        this.defaultColumnDefinitions.floatingFilter = !this.defaultColumnDefinitions.floatingFilter;
        if (this.defaultColumnDefinitions.floatingFilter === false) {
            this.gridApi?.setFilterModel(null);

            if (this.userSettings) {
                this.userSettings.filter.filters = [];
                delete this.userSettings.filterable;
            }
        } else {
            if (this.userSettings) {
                this.userSettings.filterable = { mode: 'row' };
            }
        }
        this.defaultColumnDefinitions = { ...this.defaultColumnDefinitions };
        this.saveUserSettingsSubject.next();
    }

    /**
     * Clears all the filter values.
     */
    private clearAllFilters() {
        this.gridApi?.setFilterModel(null);

        if (this.userSettings?.columns) {
            for (let [key, column] of Object.entries(this.userSettings.columns)) {
                try {
                    if (!this.userSettings.columns?.[key]) {
                        this.userSettings.columns[key] = { ...column, filter: undefined };
                    }
                } catch (error) {
                    console.log(error);
                }
            }

            this.userSettings.filter.filters = [];
            delete this.userSettings.filterable;
        }

        this.saveUserSettingsSubject.next();
    }

    /**
     * Whether field names are showing or not.
     */
    private showFieldNames: boolean = false;

    /**
     * Toggles between displaying the title or the field name of a column.
     */
    private toggleFieldNameAndTitle() {
        this.showFieldNames = !this.showFieldNames;
        this.options.data!.columnDefinitions = this.options.data!.columnDefinitions.map((column) => {
            if (!!column.field && this.options.data?.columnInfo?.[column.field]) {
                const title = this.showFieldNames ? this.options.data?.columnInfo?.[column.field!].colname : this.options.data?.columnInfo?.[column.field!].title || '';

                return { ...column, headerName: title };
            } else {
                return column;
            }
        });
    }

    /**
     * Redraws the current data again.
     */
    private refreshData() {
        // this.gridApi?.redrawRows();
        this.gridApi?.stopEditing(true);

        setTimeout(() => {
            this.refreshGridSubject?.next();
            this.updateFooterRow();
        });
    }

    /**
     * Fetches row data again and draws it to grid.
     */
    private async readData() {
        if (this.rowModelType === 'serverSide') {
            this.gridApi?.refreshServerSide();
        } else {
            this.setRowData(await this.getGridRows());
        }
    }

    /**
     * Refetches all data and initializes the grid again.
     */
    private reloadData(showLoading?: boolean) {
        this.initializeGrid({ showLoading: showLoading });
    }

    /**
     * Adds a row on first line or above the first selected row.
     */
    private addRowBefore() {
        this.addRow({ addBefore: true });
    }

    /**
     * Adds a row on below other rows or below the first selected row.
     */
    private addRowAfter() {
        this.addRow({ addBefore: false });
    }

    /**
     * Toggles between text wrapping and text overflowing.
     */
    private toggleTextWrapping() {
        this.defaultColumnDefinitions.wrapText = !this.defaultColumnDefinitions.wrapText;
        this.defaultColumnDefinitions.wrapHeaderText = this.defaultColumnDefinitions.wrapText;
        this.defaultColumnDefinitions = { ...this.defaultColumnDefinitions };
    }

    /**
     * Opens the predfined column layouts modal.
     */
    private async openColumnLayoutDialog() {
        if (this.options.dataTask?.loadData?.method) {
            const result = await this.gridModalService.openColumnLayoutDialog(this.options, this.getUserSettingsAsColumnLayoutSchema());

            // dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const layout = JSON.parse(this.gridService.atou(result.layout_schema));

                this.options.data!.layoutKeyno = result.gridlayout_keyno;
                this.applyColumnLayoutSchema(layout);
            }
            // });
        }
    }

    /**
     * Opens the print report modal.
     */
    private openPrintDialog(button?: DBGridButton) {
        this.ttOpenPrintModal.emit(button);
        // const dialogRef = this.dialog.open(PrintModalComponent);
        // TODO: open print-modal event.
    }

    /**
     * Parses the users current layout settings to a column layout schema.
     *
     * @returns the column layout schema based on the users current layout.
     */
    private getUserSettingsAsColumnLayoutSchema(): ColumnLayoutSchema {
        const filters = this.getCurrentFilterState();
        let columnLayout: { [key: string]: GridColumnSetting } & { tt_grid_filterable?: boolean } = { ...this.userSettings?.columns };
        columnLayout.tt_grid_filterable = !!this.userSettings?.filterable;

        if (filters.length > 0) {
            filters.forEach((filter) => {
                if (columnLayout?.[filter.field]) {
                    (columnLayout[filter.field] as GridColumnSetting).filter = filter;
                }
            });
        }

        return columnLayout;
    }

    /**
     * Applies the given column layout schema to the usersettings and persists the changes.
     *
     * @param schema the schema to apply.
     */
    private applyColumnLayoutSchema(schema: ColumnLayoutSchema) {
        const filterable = schema?.tt_grid_filterable ?? false;
        delete schema.tt_grid_filterable;
        const columnSettings = schema;

        if (filterable === true || filterable === false) {
            const filterSettings: GridFilterSetting[] = Object.keys(schema)
                .map((key) => (!!schema?.[key]?.filter ? { ...schema[key].filter, field: key } : null))
                .filter((filter) => !!filter) as GridFilterSetting[];
            this.userSettings = { ...this.userSettings, columns: columnSettings, filter: { filters: filterSettings }, filterable: filterable === true ? { mode: 'row' } : false };
        } else {
            this.userSettings = { ...this.userSettings, columns: columnSettings, filter: { filters: [] } };
        }

        this.applyUserSettings();
        this.saveUserSettingsSubject.next();
    }

    /**
     * Modifies the header and appends the toolbar element.
     */
    private customizeHeaderForToolbar() {
        // @ts-ignore _nativeElement is a property of AgGridAngular
        const root: HTMLDivElement | undefined | null = this.agGrid?._nativeElement.querySelector('.ag-root');
        // @ts-ignore _nativeElement is a property of AgGridAngular
        const header: HTMLDivElement | undefined | null = this.agGrid?._nativeElement.querySelector('.ag-header');

        if (root && header && this.toolbar.nativeElement) {
            this.renderer.insertBefore(root, this.toolbar.nativeElement, header);
        }
    }

    /**
     * Configures visibility with disabled observables for the predefined toolbar buttons.
     */
    private configureToolbarButtonsVisibility() {
        if (!this.options.config?.toolbar) return;

        Object.keys(this.options.config.toolbar).forEach((key) => {
            if (key === 'addSimple' && this.options.config?.toolbar?.addSimple === false && this.options.config.toolbar.add === true) return;

            const toolbarbuttons: GridButton[] = this.toolbarButtons.filter((button) => button.name.includes(key));
            toolbarbuttons.forEach(async (toolbarbutton) => {
                const showToolbar = this.options.config?.toolbar?.[key as keyof GridToolbar];

                toolbarbutton.disabled = () => (showToolbar === false ? 'hidden' : false);
                toolbarbutton.disabled$ = await this.getDisabledObservable(toolbarbutton);
                this.toolbarButtons[this.toolbarButtons.indexOf(toolbarbutton)] = toolbarbutton;
            });
        });
    }

    /**
     * Rechecks all disabled properties of the toolbar buttons.
     */
    private refreshToolbarButtonsVisibility() {
        if (this.options.config?.toolbar?.buttons) {
            this.options.config.toolbar.buttons.forEach(async (button) => (button.disabled$ = await this.getDisabledObservable(button)));
        }
    }

    /**
     * Creates an observable from the values returned from the disabled function of the button.
     *
     * @param button the button to retireve observable value for.
     * @returns the observable value created from the disabled function of the button.
     */
    private async getDisabledObservable(button: GridButton | ToggleButton) {
        // @ts-ignore
        if (button.ariaLabel === 'clear_all_filters') {
            let result = !!button?.disabled ? button.disabled() : false;

            if (result instanceof Promise) {
                result = await result;
                // return from(result);
            }
            result = result === 'hidden' || (result === false && Object.keys(this.gridApi?.getFilterModel() || {}).length === 0) ? 'hidden' : false;
            return of(result);
        } else {
            const result = !!button?.disabled ? button.disabled() : false;

            if (result instanceof Promise) {
                return from(result);
            }
            return of(result);
        }
    }

    // #endregion TOOLBAR

    /**
     * Adds load data information, (procedure name and number) to the pagination bar.
     */
    private async addLoadDataInfoToPagination() {
        if ((await this.userService.currentUserDetails()).developMode !== true) return;
        // @ts-ignore _nativeElement is a property of AgGridAngular
        const paging: HTMLDivElement | undefined | null = this.agGrid?._nativeElement.querySelector('.ag-paging-panel');

        if (paging && !isNaN(Number(this.options.dataTask?.loadData?.method)) && !document.getElementById(this.id['grid'] + this.options.dataTask?.loadData?.method)) {
            const seperator: HTMLSpanElement = this.renderer.createElement('span');
            seperator.textContent = ':::';

            const dataTaskProcedure: HTMLSpanElement = this.renderer.createElement('span');
            dataTaskProcedure.textContent = this.options.dataTask?.loadData?.method + ' - ' || ' - ';
            dataTaskProcedure.style.fontWeight = '600';

            const dataTaskName: HTMLSpanElement = this.renderer.createElement('span');
            dataTaskName.id = this.id['grid'] + this.options.dataTask?.loadData?.method;
            dataTaskName.textContent = (await this.gridService.getLoadMethodName(this.options.dataTask!.loadData!.method! as number)) || '';

            this.renderer.insertBefore(dataTaskName, dataTaskProcedure, dataTaskName.childNodes[0]);
            this.renderer.insertBefore(paging, seperator, paging.children[0]);
            this.renderer.insertBefore(paging, dataTaskName, seperator);
        }
    }

    /**
     * Sets the height setting for the grid.
     */
    private setGridHeight() {
        if (this.style['grid']) {
            if (this.options.config?.css?.height === 'fill') {
                this.domLayout = 'normal';
                this.style['grid'].height = '100%';
            } else if (this.options.kendo?.height && typeof (this.options.kendo.height === 'string' || (typeof this.options.kendo.height === 'number' && !isNaN(Number(this.options.kendo?.height))))) {
                this.domLayout = 'normal';
                this.style['grid'].height = typeof this.options.kendo.height === 'string' ? this.options.kendo.height : this.options.kendo.height + 'px';
            } else {
                this.domLayout = 'autoHeight';
                this.style['grid'].height = '100%';
                // delete this.style['grid'].height;
            }
            this.style['grid'].minHeight = this.options.config?.css?.minHeight;
        }
    }

    // #region USERSETTINGS

    /**
     * Applies the values from the given column settings to the column definitions.
     *
     * @param columnSettings the column settings to apply.
     */
    private setColumnsFromColumnSettings(columnSettings: { [key: string]: GridColumnSetting }) {
        this.options.data!.columnDefinitions = this.options
            .data!.columnDefinitions.map((column) => {
                if (columnSettings?.[column.field!]?.hidden !== true) {
                    // console.log(Number(columnSettings?.[column.field!]?.width));
                }

                return {
                    ...column,
                    width: !!columnSettings?.[column.field!]?.width ? Number(columnSettings?.[column.field!]?.width) : column.width,
                    hide: columnSettings?.[column.field!] ? columnSettings?.[column.field!]?.hidden === true : column.hide,
                    pinned: columnSettings?.[column.field!]?.pinned !== undefined ? columnSettings?.[column.field!]?.pinned : column.pinned,
                } as ColDef & { title: string };
            })
            // send the rows far back
            .sort((a, b) => {
                if (columnSettings?.[a.field!]?.order === undefined || columnSettings?.[a.field!]?.order === null) return 1;
                if (columnSettings?.[b.field!]?.order === undefined || columnSettings?.[b.field!]?.order === null) return 1;
                return (columnSettings?.[a.field!]?.order ?? -1) - (columnSettings?.[b.field!]?.order ?? -1);
            });
    }

    private sortColumns() {
        let columnSettings = this.userSettings?.columns;
        if (!columnSettings) return;
        this.options.data!.columnDefinitions = this.options
            .data!.columnDefinitions.map((column) => {
                if (columnSettings?.[column.field!]?.hidden !== true) {
                    // console.log(Number(columnSettings?.[column.field!]?.width));
                }

                return {
                    ...column,
                    width: !!columnSettings?.[column.field!]?.width ? Number(columnSettings?.[column.field!]?.width) : column.width,
                    hide: columnSettings?.[column.field!] ? columnSettings?.[column.field!]?.hidden === true : column.hide,
                    pinned: columnSettings?.[column.field!]?.pinned !== undefined ? columnSettings?.[column.field!]?.pinned : column.pinned,
                } as ColDef & { title: string };
            })
            // send the rows far back
            .sort((a, b) => {
                if (columnSettings?.[a.field!]?.order === undefined || columnSettings?.[a.field!]?.order === null) return 1;
                if (columnSettings?.[b.field!]?.order === undefined || columnSettings?.[b.field!]?.order === null) return 1;
                return (columnSettings?.[a.field!]?.order ?? -1) - (columnSettings?.[b.field!]?.order ?? -1);
            });
    }

    /**
     * Applies the values from the given filter settings.
     *
     * @param filterSettings the filter settings to apply.
     */
    private async setFiltersFromFilterSettings(filterSettings?: GridFilterSetting[] | null) {
        if (!filterSettings || filterSettings.length === 0) {
            this.gridApi?.setFilterModel(null);

            this.gridApi?.onFilterChanged('api');
        } else {
            this.gridApi?.setFilterModel(null);

            filterSettings?.forEach(async (filter) => {
                const colDef = this.gridApi?.getColumnDef(filter.field);
                const filterInstance = await this.gridApi?.getColumnFilterInstance(filter.field);

                if ((colDef?.cellDataType === 'date' || colDef?.cellDataType === 'datetime') && this.options.config?.serverSideHandling !== true) {
                    filterInstance?.setModel({ filterType: 'multi', filterModels: [this.getDateFilterModel(filter), this.getSetFilterModel(filter)] });
                } else if (colDef?.cellDataType === 'boolean') {
                    filterInstance?.setModel(this.getTextFilterModel(filter));
                } else if (this.options.config?.serverSideHandling !== true) {
                    filterInstance?.setModel({
                        filterType: 'multi',
                        filterModels: [this.getTextFilterModel(filter), this.getSetFilterModel(filter)],
                    });
                } else if (this.options.config.serverSideHandling === true) {
                    await this.setFilterFromFilterSettingsServerSideGrid(filter, filterInstance);
                }

                this.gridApi?.onFilterChanged('api');
            });
        }
    }

    /**
     * Sets the filtermodel of the the given filter instance using the given filter setting.
     *
     * @param filter the filter setting to set.
     */
    private async setFilterFromFilterSettingsServerSideGrid(filter: GridFilterSetting, filterInstance?: IFilter | null) {
        if (this.gridApi?.getColumnDefs()?.length === 0) return;

        const columnInfo = this.options.data?.columnInfo?.[filter.field];

        if (columnInfo?.type === 'date' || columnInfo?.type === 'datetime') {
            filterInstance?.setModel(this.getDateFilterModel(filter));
        } else {
            filterInstance?.setModel(this.getTextFilterModel(filter));
        }
    }

    /**
     * Creates and returns the filter model for a date filter based on the provided filter setting.
     *
     * @param filter the filter setting to create a date filter model of.
     * @returns a date filter model based on the given filter setting.
     */
    private getDateFilterModel(filter: GridFilterSetting): FilterModel {
        const timezoneOffset = new Date().getTimezoneOffset() * 60000;

        return {
            dateFrom: filter.value ? new Date((new Date(this.getFilterValueFromGridFilterSetting(filter)) as any) - timezoneOffset).toISOString().substring(0, 10).replace('T', ' ') : null,
            dateTo: null,
            filterType: 'date',
            type: this.gridService.getFilterType(filter.operator) ?? '',
        };
    }

    /**
     * Creates and return the text filter model based on the provided filter setting.
     *
     * @param filter the filter setting to create a text filter model of.
     * @returns a text filter model based on the provided filter setting.
     */
    private getTextFilterModel(filter: GridFilterSetting): FilterModel {
        return {
            type: this.gridService.getFilterType(filter.operator) ?? '',
            filterType: 'text',
            filter: this.getFilterValueFromGridFilterSetting(filter),
        };
    }

    private getFilterValueFromGridFilterSetting(filter: GridFilterSetting) {
        return filter.base64Encoded !== true ? filter.value : !!filter.value ? this.gridService.atou(filter.value) : '';
    }

    /**
     * Creates and return a set filter model based of the provided filter setting.
     *
     * @param filter the filter setting to create a set filter model of.
     * @returns a set filter model based on hte provided filter setting.
     */
    private getSetFilterModel(filter: GridFilterSetting): SetFilterModel {
        return {
            filterType: 'set',
            values: filter.set ?? [],
        };
    }

    /**
     * Applies the given sort setting to the grid.
     *
     * @param sortSettings the sort settings to apply.
     */
    private setSortFromSortSettings(sortSettings?: GridSortSetting[] | null) {
        if (sortSettings && sortSettings instanceof Array) {
            sortSettings.forEach((sortSetting) => {
                this.gridApi?.applyColumnState({ state: [{ colId: sortSetting.field, sort: sortSetting.dir, sortIndex: sortSetting.index }], defaultState: { sort: null } });
            });
        }

        this.gridApi?.onSortChanged();
    }

    /**
     * Applies the given filterable settings to the grid.
     *
     * @param filterableSettings the filterable settings to apply.
     */
    private setFilterableFromFilterableSettings(filterableSettings: undefined | null | GridFilterable) {
        if (typeof filterableSettings === 'object' && filterableSettings?.mode === 'row') {
            this.defaultColumnDefinitions = {
                ...this.defaultColumnDefinitions,
                floatingFilter: true,
                menuTabs: undefined,
                suppressHeaderMenuButton: true,
            };
        } else if (filterableSettings === true) {
            this.defaultColumnDefinitions = {
                ...this.defaultColumnDefinitions,
                floatingFilter: false,
                menuTabs: ['filterMenuTab'],
                suppressHeaderMenuButton: false,
            };
        } else {
            this.defaultColumnDefinitions = {
                ...this.defaultColumnDefinitions,
                floatingFilter: false,
                menuTabs: undefined,
                suppressHeaderMenuButton: true,
            };
        }
    }

    /**
     * Applies the given user settings to the pagination setting fo the grid.
     *
     * @param userSettings the user settings to apply to pagination settings.
     */
    private setPageFromUserSettings(userSettings?: GridUserSettings, api: GridApi | undefined = this.gridApi) {
        if (userSettings?.pageSize && !isNaN(Number(userSettings.pageSize))) {
            api?.setGridOption('paginationPageSize', userSettings.pageSize);
        }

        if (userSettings?.page !== undefined && userSettings.page !== null && !isNaN(Number(userSettings.page))) {
            api?.paginationGoToPage(userSettings.page - 1);
        }
    }

    /**
     * Returns the filter value from the given filter model.
     *
     * @param filterModel the filter model to find the value from.
     * @returns the valye found from the filter model.
     */
    private getFilterValue(filterModel: FilterModel): any {
        if (filterModel['filterType'] === 'date') {
            return new Date(new Date(filterModel['dateFrom']).getTime() - new Date().getTimezoneOffset() * 60000);
        } else if (filterModel['filterType'] === 'multi') {
            return filterModel['filterModels'][0]['filter'];
        }

        return filterModel['filter'];
    }

    /**
     * Whether usersettings are ready to be updated or not.
     */
    private userSettingsReady = false;

    /**
     * Applies the current user settings.
     */
    private applyUserSettings(event?: FirstDataRenderedEvent) {
        if (this.userSettings) {
            this.setColumnsFromColumnSettings(this.userSettings.columns);

            if (!!this.userSettings?.filter?.filters) {
                this.setFiltersFromFilterSettings(this.userSettings.filter.filters);
            }
            this.setSortFromSortSettings(this.userSettings.sort);
            this.setFilterableFromFilterableSettings(this.userSettings.filterable);

            if (event) {
                this.setPageFromUserSettings(this.userSettings, event?.api);
            }
        }

        /**
         * Wait till UI settles before usersettings are ready to be updated again.
         */
        setTimeout(() => {
            this.userSettingsReady = true;
            // this.gridApi?.sizeColumnsToFit();
        }, 100);
    }

    /**
     * Loads the user settings and applies the settings returned.
     */
    private async getUserSettings() {
        if (this.ttOptions.dataTask?.rememberId) {
            this.userSettings = await this.gridService.getRemember(this.ttOptions.dataTask.rememberId);
        }
    }

    private async saveUserSettings(): Promise<any> {
        if (this.ttOptions.dataTask?.rememberId && this.userSettings) {
            const gridUserSettings = { ...this.userSettings };

            this.gridService.saveUserSettings({ variablename: this.ttOptions.dataTask.rememberId, variablevalue: gridUserSettings });
        }
    }

    // #endregion USERSETTINGS

    private async saveAllChanges(showProgress: boolean = true) {
        await new Promise((resolve) => setTimeout(resolve, 0));
        this.savingGridData.next(true);
        this.mapDirtyRowsToChanges();

        if (Object.keys(this.options.data!.changes!).length < 1) return;

        try {
            await this.gridService.customSave(this.options, 'all');
        } catch (customSaveError) {
            if (this.options.dataTask?.saveData?.confirm === true) {
                const result = await this.modalService.openConfirmDialog({ type: 'warning', title: 'ttgrid_modal_save_title', message: 'ttgrid_modal_save_message', ok: 'ttgrid_modal_save_ok', cancel: 'ttgrid_modal_save_cancel' });

                if (result !== true) return;
            }

            let progressModal: ProgressModalComponent | null = null;

            if (showProgress) {
                progressModal = this.modalService.openProgressDialog(0, Object.keys(this.options.data!.changes!).length, this.options.dataTask?.rememberId);
            }

            try {
                await this.gridService.saveAllChanges(this.options, progressModal, this.gridApi);
            } catch (error) {
                console.error(error);
                this.modalService.openErrorDialog(`${error}`);
            } finally {
                if (this.options.dataTask?.saveData?.readAfterSave !== false) {
                    this.readData();
                }
            }
        } finally {
            this.savingGridData.next(false);
        }
    }

    private mapDirtyRowsToChanges() {
        const dirtyRows = this.options.data?.rowData.filter((row) => row._dirty === true);

        dirtyRows?.forEach((row) => {
            if (!this.options.data?.changes?.[row._uuid!]) {
                this.options.data!.changes![row._uuid!] = { state: 'update', data: row };
            } else if (this.options.data?.changes?.[row._uuid!].data) {
                this.options.data!.changes![row._uuid!].data = row;
            }
        });
    }

    /**
     * Adds a new row based on the given data.
     *
     * @param param0 configuration for what and where to add the new row.
     * @returns a promise containing the newly added row, or undefined adding a new row was cancelled.
     */
    private async addRow({ index, rowItem, addBefore = false, confirm = true }: { index?: number; rowItem?: GridRow; addBefore?: boolean; confirm?: boolean }): Promise<GridRow | undefined> {
        if (this.options.dataTask?.addRow?.confirm && confirm === true) {
            const result = await this.modalService.openConfirmDialog({ type: 'warning', title: 'ttgrid_modal_add_title', message: 'ttgrid_modal_add_message', ok: 'ttgrid_modal_add_ok', cancel: 'ttgrid_modal_add_cancel' });

            if (result !== true) {
                return;
            }
        }

        if (!!rowItem) {
            rowItem._uuid = crypto.randomUUID();
        }

        const insertIndex = this.getInsertIndex({ index, addBefore });
        const row: GridRow = !!rowItem ? rowItem : { _uuid: crypto.randomUUID(), ...this.createNewRow() };
        await this.beforeAddRow(row);

        if (this.options.config?.keepSortOnAdd !== true && this.gridApi?.getColumnState().some((columnState) => !!columnState.sort)) {
            this.gridApi?.applyColumnState({ defaultState: { sort: null } });
        }

        this.options.data?.rowData.splice(insertIndex, 0, row);
        this.gridApi?.updateGridOptions({ rowData: this.options.data?.rowData });

        if (this.options.kendo?.selectable === 'single' || this.options.kendo?.selectable === 'multiple') {
            this.selectRow(insertIndex, true);
        }

        const firstEditableColumn = this.getFirstEditableColumnKey(this.gridApi?.getRowNode(row._uuid!)!);

        if (firstEditableColumn) {
            this.startCellEditing({ rowId: row._uuid!, insertIndex: insertIndex, columnKey: this.getFirstEditableColumnKey(this.gridApi?.getRowNode(row._uuid!)!) });
        }

        if (this.options.data!.hasGotoParms) {
            row['item_state'] = '';
            row['item_parms'] = '';
            row['item_path'] = '';
        }

        if (this.options.data!.hasThumbnails) {
            row['item_thumb'] = '';
        }

        this.options.data!.changes![row._uuid!] = { state: 'add', data: row };

        if (this.options.dataTask?.addRow?.autoSave === true) {
            await this.saveChanges('add');
        }
        await this.afterAddRow(row, insertIndex);

        if (this.options.dataTask?.addRow?.openEdit && this.gridApi) {
            await this.gridModalService.openRowEditModal(this.options, row, this.gridApi);
        }

        return row;
    }

    /**
     * Removes the given row from the grid row data.
     *
     * @param row the row to remove.
     * @param checkShouldConfirm whether to check if remove should be confirm and if so opens the confirmation modal.
     * @param checkShouldAutoSave whether to check if remove should be auto-save and if so persists changes.
     * @returns empty promise which fullfils once the save is completes.
     */
    private async removeRow(row: GridRow, checkShouldConfirm: boolean = true, checkShouldAutoSave: boolean = true, runPostRemove: boolean = true) {
        if (checkShouldConfirm === true) {
            const confirmedRemove = await this.confirmRemoveRow();

            if (confirmedRemove !== true) {
                return null;
            }
        }

        const index = this.options.data?.rowData.findIndex((rowItem) => row._uuid === rowItem._uuid);

        if (index !== undefined && index !== -1) {
            this.options.data?.rowData.splice(index, 1);

            if (this.options.data?.changes?.[row._uuid!]?.state === 'add') {
                delete this.options.data.changes[row._uuid!];
            } else {
                this.options.data!.changes![row._uuid!] = { state: 'remove', data: row };
            }
        }

        if (checkShouldAutoSave === true && this.options.dataTask?.removeRow?.autoSave === true) {
            await this.saveChanges('remove');
        }

        if (runPostRemove) {
            this.afterRemoveRow([row]);
        }

        return row;
    }

    /**
     * Removes all the currently selected rows. Persists changes only if auto-save for remove is true.
     */
    private async removeSelectedRows() {
        const confirmedRemove = await this.confirmRemoveRow();

        if (confirmedRemove !== true) {
            return null;
        }

        const selectedRows: GridRow[] | undefined = this.gridApi?.getSelectedRows();
        const firstSelectedNode = this.gridApi?.getSelectedNodes()?.[0]?.rowIndex;

        if (!selectedRows || selectedRows.length === 0) {
            const cell = this.gridApi?.getFocusedCell();
            if (cell) {
                const row = this.gridApi?.getDisplayedRowAtIndex(cell.rowIndex);
                console.log(row);
                await this.removeRow(row?.data, false, false, false);
            }
        } else {
            await Promise.all(selectedRows?.map(async (row) => await this.removeRow(row, false, false, false)));
        }

        this.gridApi?.setGridOption('rowData', this.options.data?.rowData);

        if (this.options.dataTask?.removeRow?.autoSave === true) {
            await this.saveChanges('remove');
        }

        await this.afterRemoveRow(selectedRows);

        if (firstSelectedNode !== undefined && firstSelectedNode !== null) {
            const selectIndex = this.gridApi!.getDisplayedRowCount() > firstSelectedNode ? firstSelectedNode : this.gridApi!.getDisplayedRowCount() - 1;
            this.selectRow(selectIndex);
            this.setFocusedCell({ rowIndex: selectIndex, columnIndex: 0 });
        }

        return selectedRows || null;
    }

    /**
     * Checks if the confirm remove row modal should be opened, and returns true if the user confirmed or confirm not necessary.
     *
     * @returns `true` if user confirmed, or confirmation modal shouldn't open.
     */
    private async confirmRemoveRow() {
        if (this.options.dataTask?.removeRow?.confirm === true) {
            const result = await this.modalService.openConfirmDialog({ type: 'warning', title: 'ttgrid_modal_remove_title', message: 'ttgrid_modal_remove_message', ok: 'ttgrid_modal_remove_ok', cancel: 'ttgrid_modal_remove_cancel' });

            if (result !== true) {
                return false;
            }
        }

        return true;
    }

    /**
     * Calls post function set up in remove row, gives the removed rows as parameters. Updates the grid data to display the changes in rows.
     *
     * @param rows the removed rows.
     */
    private async afterRemoveRow(rows?: GridRow[]) {
        if (this.options.dataTask?.removeRow?.post instanceof Function) {
            this.options.dataTask.removeRow.post(rows);
        }

        this.gridApi?.setGridOption('rowData', this.options.data?.rowData);
        this.updateFooterRow();
    }

    /**
     * Gets the index for which to insert a new row at based on the given row index and whether to place the row before or after.
     *
     * @param index the index to place a new row at.
     * @param addBefore whether to add the new row before the given index or after.
     * @return the index to place the new row at.
     */
    private getInsertIndex({ index, addBefore = false }: { index?: number; addBefore?: boolean }) {
        // const selectedRows = this.gridApi?.getSelectedRows();
        const focusedCell = this.gridApi?.getFocusedCell();

        let insertIndex = 0;

        if (index !== undefined && index !== null && !isNaN(Number(index))) {
            if (addBefore !== true) {
                insertIndex = index + 1;
            } else {
                insertIndex = index;
            }
        } else if (!!focusedCell) {
            if (addBefore === true) {
                insertIndex = focusedCell.rowIndex;
            } else {
                insertIndex = focusedCell.rowIndex + 1;
            }
            // }
        } else {
            if (addBefore === true) {
                insertIndex = 0;
            } else {
                insertIndex = this.options.data!.rowData.length;
            }
        }

        return insertIndex;
    }

    /**
     * Starts cell editing for the cell whose row has the given uuid, the cell att the given
     *
     * @param uuid the unique id of the grid row to start editing on.
     * @param insertIndex the index to start editing on if row index of the row with given `uuid` is `null`,
     * @param columnKey the key of the column to start editing on.
     */
    private startCellEditing({ rowId, insertIndex, columnKey }: { rowId: string; insertIndex?: number; columnKey?: string }) {
        const rowNode = this.gridApi?.getRowNode(rowId);

        if (rowNode) {
            const colKey = !!columnKey ? columnKey : this.getFirstEditableColumnKey(rowNode);

            if (colKey && (rowNode.rowIndex !== null || insertIndex !== undefined)) {
                this.gridApi?.setFocusedCell(rowNode.rowIndex ?? insertIndex!, colKey);
                this.gridApi?.startEditingCell({ colKey: colKey, rowIndex: rowNode.rowIndex ?? insertIndex! });
                this.gridApi?.clearRangeSelection();
            }
        }
    }

    private getFirstEditableColumnKey(rowNode: IRowNode): string | undefined {
        return this.gridApi
            ?.getColumns()
            ?.find((column) => column.isCellEditable(rowNode) && column.isVisible())
            ?.getColId();
    }

    /**
     * Creates and returns a new row with empty for every colname.
     *
     * @returns a row with empty values for every colname.
     */
    private createNewRow(): GridRow {
        const row: any = {};

        for (let [colname, { type }] of Object.entries(this.options!.data!.columnInfo!)) {
            if (type === 'date' || type === 'datetime' || type === 'time') {
                row[colname] = new Date();
            } else if (type === 'number') {
                row[colname] = 0;
            } else if (type === 'boolean') {
                row[colname] = false;
            } else if (!!type) {
                row[colname] = '';
            }
        }

        return row;
    }

    /**
     * Calls the pre function, to be used before inserting a new row.
     *
     * @param row the row item which is to be inserted.
     */
    private async beforeAddRow(row: GridRow) {
        if (!!this.options.dataTask?.addRow?.pre && this.options.dataTask.addRow.pre instanceof Function) {
            const response = this.options.dataTask.addRow.pre(row);
            if (response instanceof Promise) await response;
        }
    }

    /**
     * Refreshes grid data after inserting new row and calls post function.
     *
     * @param row the newly inserted row, needs to have `_uuid` attribute to find correct data.
     * @param index the rowindex to start cell editing on.
     */
    private async afterAddRow(row: GridRow, index?: number) {
        if (!!this.options.dataTask?.addRow?.post && this.options.dataTask.addRow.post instanceof Function) {
            const newRow = this.options.data?.rowData.find((item) => row._uuid === item._uuid);
            const response = this.options.dataTask.addRow.post(newRow);

            if (response instanceof Promise) await response;
        }

        this.gridApi?.setGridOption('rowData', this.options.data?.rowData);
        this.refreshToolbarButtonsVisibility();
        this.updateFooterRow();
    }

    /**
     * Saves the changes of the given change state.
     *
     * @param state which state to save.
     * @returns empty promise which fullfills once the changes are persisted.
     */
    private async saveChanges(state: ChangeState) {
        try {
            await this.gridService.customSave(this.options, state);
        } catch (customSaveError) {
            try {
                this.savingGridData.next(true);

                const responses = await this.gridService.saveChanges(state, this.options, this.gridApi);

                if (responses instanceof Array) {
                    responses?.forEach((response) => {
                        if (!!response?.row && !!response?.savedata) {
                            const row = { ...response.row, ...response.savedata };
                            this.options.data!.rowData[this.options.data!.rowData.indexOf(response.row)] = row;
                            this.gridApi?.getRowNode(response.row._uuid!)?.setData(row)!;
                            this.updateCellEditors(row);
                        }
                    });
                }
            } catch (error) {
                this.modalService.openErrorDialog(`${error}`);
            }
        } finally {
            this.savingGridData.next(false);
        }
    }

    /**
     * Updates the active cell editors if any are rendered.
     *
     * @param row the row to update the cell editor value with.
     */
    private updateCellEditors(row: GridRow) {
        const rowNode = this.getRowNode({ rowIndex: this.gridApi?.getFocusedCell()?.rowIndex });
        if (!!rowNode && rowNode.id === row._uuid) {
            const activeEditor = this.gridApi?.getCellEditorInstances()?.[0];

            if (activeEditor && Object.hasOwn(activeEditor, 'cellEditorInput') && Object.hasOwn((activeEditor as any).cellEditorInput, 'eInput')) {
                // @ts-ignore
                const params = activeEditor.cellEditorInput.params;
                // @ts-ignore
                activeEditor.cellEditorInput.eInput.setValue(row[params.column.getColId()]);
                setTimeout(() => {
                    // @ts-ignore
                    activeEditor.cellEditorInput.eInput?.eInput.select();
                }, 1);
            } else if (activeEditor instanceof NumberCellEditorComponent && activeEditor.params && activeEditor.hasBeenEdited === false) {
                activeEditor.onModelChanged(row[activeEditor.params.column.getColId()]);
                setTimeout(() => {
                    activeEditor.inputRef?.nativeElement.select();
                }, 1);
            } else if ((activeEditor instanceof DateTimeCellEditorComponent || activeEditor instanceof DateCellEditorComponent) && activeEditor.params && activeEditor.hasBeenEdited === false) {
                activeEditor.onDateChanged(row[activeEditor.params.column.getColId()]);
                setTimeout(() => {
                    activeEditor.inputRef?.nativeElement.select();
                }, 1);
            } else if (activeEditor instanceof LookupCellEditorComponent && activeEditor.params && activeEditor.hasBeenEdited === false) {
                activeEditor.searchControl.setValue(row[activeEditor.params.column.getColId()], { emitEvent: false });
                setTimeout(() => {
                    activeEditor.inputRef?.nativeElement.select();
                }, 1);
            }
        }
    }

    /**
     * Retrieves the grid columns and maps them to column definitions and applies to the grid.
     *
     * @param param0 the values to use for retrieving the grid columns.
     */
    private async getGridColumns({ method, loadDataMethod, params, force }: { method?: null | number | string; loadDataMethod: number; params: any; force?: boolean }) {
        return this.gridService.getGridColumns({ method: !!method === true ? Number(method) : 1999, loadDataMethod: loadDataMethod, params: params, force: force });
    }

    /**
     * Finds the options column and applies the options. Returns the columns without the options column.
     *
     * @param columns the columns which may contain a option column.
     * @returns the list of columns without the option column.
     */
    private applyOptionsFromColSchema(columns?: GridColumn[] | null) {
        if (columns && columns instanceof Array) {
            const gridOptions = columns.find((column) => column.colname === 'xxxoptionsxxx');

            if (gridOptions?.gridoptions) {
                columns.splice(columns.indexOf(gridOptions), 1);
                this.dbGridOptions = gridOptions.gridoptions;
                this.validateStateMethod('add');
                this.validateStateMethod('update');
                this.validateStateMethod('remove');

                if (!!this.dbGridOptions.load?.primaryKey) {
                    this.options.dataTask!.loadData!.primaryKey = this.dbGridOptions.load.primaryKey;
                }

                if (this.dbGridOptions.add?.autoSave !== null) {
                    this.options.dataTask!.addRow!.autoSave = this.dbGridOptions.add!.autoSave;
                }

                if (this.dbGridOptions.add?.confirm !== null) {
                    this.options.dataTask!.addRow!.confirm = this.dbGridOptions.add!.confirm;
                }

                if (this.dbGridOptions?.remove?.autoSave !== null) {
                    this.options.dataTask!.removeRow!.autoSave = this.dbGridOptions.remove!.autoSave;
                }

                if (this.dbGridOptions?.remove?.confirm !== null) {
                    this.options.dataTask!.removeRow!.confirm = this.dbGridOptions.remove!.confirm;
                }

                if (this.dbGridOptions?.save?.autoSaveChanges !== null) {
                    this.options.dataTask!.saveData!.autoSave = this.dbGridOptions.save!.autoSaveChanges;
                }

                if (this.dbGridOptions?.save?.confirm !== null) {
                    this.options.dataTask!.saveData!.confirm = this.dbGridOptions.save!.confirm;
                }

                if (this.dbGridOptions?.save?.single !== null) {
                    this.options.dataTask!.saveData!.single = this.dbGridOptions.save!.single;
                }

                if (this.dbGridOptions?.save?.refreshRowOnSave !== null) {
                    this.options.dataTask!.saveData!.refreshRowOnSave = this.dbGridOptions.save!.refreshRowOnSave;
                }

                if (this.dbGridOptions?.save?.hideRefreshSpinner !== null) {
                    this.options.dataTask!.saveData!.hideRefreshSpinner = this.dbGridOptions.save!.hideRefreshSpinner;
                }

                if (this.dbGridOptions?.save?.readAfterSave !== null) {
                    this.options.dataTask!.saveData!.readAfterSave = this.dbGridOptions.save!.readAfterSave;
                }

                if (this.dbGridOptions?.save?.onlySaveIsSelected !== null) {
                    this.options.dataTask!.saveData!.onlySaveIsSelected = this.dbGridOptions.save!.onlySaveIsSelected;
                }

                if (this.dbGridOptions?.save?.saveInclLoadparms !== null) {
                    this.options.dataTask!.saveData!.saveInclLoadparms = this.dbGridOptions.save!.saveInclLoadparms;
                }

                if (this.dbGridOptions.reports !== null) {
                    this.options.reports = this.dbGridOptions.reports;
                }

                if (typeof this.dbGridOptions?.aggregates === 'boolean' || (!!this.dbGridOptions?.aggregates && this.dbGridOptions.aggregates instanceof Array && this.dbGridOptions.aggregates.length > 0)) {
                    this.options.kendo!.aggregate = this.dbGridOptions.aggregates;
                }

                if (typeof this.dbGridOptions.aggregates === 'boolean' || !!this.dbGridOptions.aggregates) {
                    if (this.dbGridOptions.aggregates instanceof Array) {
                        if (!this.options.kendo?.aggregate) {
                            this.options.kendo!.aggregate = [];
                        }

                        this.dbGridOptions.aggregates.forEach((aggregate) => {
                            (this.options.kendo!.aggregate as TTGridAggregate[]).push(aggregate);
                        });
                    } else {
                        this.options.kendo!.aggregate = this.dbGridOptions.aggregates;
                    }
                }

                if (this.dbGridOptions?.kendo?.selectable !== undefined && this.dbGridOptions?.kendo?.selectable !== null && (typeof this.dbGridOptions?.kendo?.selectable === 'boolean' || ['single', 'multiple', 'na'].includes(this.dbGridOptions.kendo.selectable)) && this.dbGridOptions.kendo.selectable !== 'na') {
                    this.options.kendo!.selectable = this.dbGridOptions.kendo.selectable;
                }

                if (this.dbGridOptions?.kendo?.sortable !== undefined && this.dbGridOptions?.kendo?.sortable !== null && (typeof this.dbGridOptions?.kendo?.sortable === 'boolean' || this.dbGridOptions.kendo.sortable !== 'na')) {
                    this.options.kendo!.sortable = this.dbGridOptions.kendo.sortable;
                    this.defaultColumnDefinitions.sortable = this.options.kendo!.sortable;
                }

                if (this.dbGridOptions.kendo?.filterable !== undefined && this.dbGridOptions.kendo.filterable !== null && this.dbGridOptions.kendo.filterable !== 'na') {
                    this.options.kendo!.filterable = this.dbGridOptions.kendo.filterable !== 'row' ? this.dbGridOptions.kendo.filterable : { mode: 'row' };
                }

                if (this.dbGridOptions.sidebar) {
                    Object.entries(this.dbGridOptions.sidebar).forEach(([key, value]) => {
                        if (value !== null) {
                            this.options.config!.sidebar![<keyof GridSidebarConfig>key] = value;
                        }
                    });
                }

                if (!!this.dbGridOptions?.toolbar) {
                    Object.keys(this.dbGridOptions.toolbar).forEach((key) => {
                        const toolbarButtonVisibility = this.dbGridOptions?.toolbar?.[key as keyof GridToolbar];

                        if (key !== 'buttons' && key !== 'toggles' && typeof toolbarButtonVisibility === 'boolean' && this.options?.config?.toolbar?.[key as keyof GridToolbar] !== undefined && !(this.options.config?.toolbar?.[key as keyof GridToolbar] instanceof Array)) {
                            // @ts-ignore - too deeply nested for the compiler to know we have checked that this is not buttons or toggles (the two only attributes which are not booleans).
                            this.options.config.toolbar[key as keyof GridToolbar] = toolbarButtonVisibility;
                        }
                    });

                    this.dbGridOptions.toolbar?.buttons?.forEach((dbButton: DBGridButton) => {
                        if (dbButton.name === 'print' && dbButton.type === 'print') {
                            this.options.config!.toolbar!.print = true;
                            const defaultBPrintButton = this.toolbarButtons.find((button) => button.name === 'print');
                            if (defaultBPrintButton) {
                                defaultBPrintButton.func = () => this.openPrintDialog(dbButton);
                            }
                        } else if (!this.options.config?.toolbar?.buttons?.some((button) => button.name === dbButton.name) && !this.toolbarButtons.some((button) => button.name === dbButton.name)) {
                            this.options.config!.toolbar!.buttons?.push({ ...dbButton, func: (event) => this.dbToolbarButtonClick(dbButton as DBGridButton, event as MouseEvent) });
                        }
                    });
                }

                if (!this.ttOptions.config?.specialFunc) {
                    this.options.config!.specialFunc = {
                        newTab: this.dbGridOptions.specialFunc?.newTab,
                        buttons: [],
                    };
                } else {
                    this.options.config!.specialFunc!.newTab = this.dbGridOptions.specialFunc?.newTab;
                }

                if (!!this.dbGridOptions.specialFunc?.buttons) {
                    this.dbGridOptions.specialFunc.buttons.forEach((button) => {
                        if (!!this.options.config?.specialFunc?.buttons && (this.options.config?.specialFunc?.buttons?.filter((specialButton) => specialButton.name === button.name).length ?? 0) === 0) {
                            this.options.config!.specialFunc!.buttons!.push(button);
                        }
                    });
                    // this.options.config!.specialFunc!.buttons = [...(this.options.config?.specialFunc?.buttons ?? []), ...this.dbGridOptions.specialFunc.buttons];
                    // console.log('this.ttOptions.config?.specialFunc?.buttons?.length :>> ', this.ttOptions.config?.specialFunc?.buttons?.length);
                }
            }
        }

        return columns;
    }

    /**
     * Configures the sidebar according to configuration set in gridoptions sidebar config.
     */
    private configureSidebar() {
        if (this.options.config?.sidebar?.hidden === true) {
            this.sidebarDefinition = null;
        } else {
            let columnsPanelDefinition: ToolPanelDef | null = null;
            let filterPanelDefinition: ToolPanelDef | null = null;
            let toolPanels: ToolPanelDef[] = [];

            if (this.options.config?.sidebar?.hideColumnsPanel !== true) {
                columnsPanelDefinition = { id: 'columns', labelDefault: 'Columns', labelKey: 'columns', iconKey: 'columns', toolPanel: 'agColumnsToolPanel' };

                if (this.options.config?.sidebar?.hidePivotMode === true) {
                    columnsPanelDefinition.toolPanelParams = { ...columnsPanelDefinition.toolPanelParams, suppressPivots: true, suppressPivotMode: true };
                }
                if (this.options.config?.sidebar?.hideRowGroupPane === true) {
                    columnsPanelDefinition.toolPanelParams = { ...columnsPanelDefinition.toolPanelParams, suppressRowGroups: true };
                }
                if (this.options.config?.sidebar?.hideValuesPane === true) {
                    columnsPanelDefinition.toolPanelParams = { ...columnsPanelDefinition.toolPanelParams, suppressValues: true };
                }

                toolPanels.push(columnsPanelDefinition);
            }

            if (this.options.config?.sidebar?.hideFilterPanel !== true) {
                filterPanelDefinition = { id: 'filters', labelDefault: 'Filters', labelKey: 'filters', iconKey: 'filter', toolPanel: 'agFiltersToolPanel' };
                toolPanels.push(filterPanelDefinition);
            }

            if (this.options.config?.sidebar?.customPanels && this.options.config.sidebar.customPanels.length > 0) {
                toolPanels.push(...this.options.config.sidebar.customPanels);
            }

            if (toolPanels.length > 0) {
                this.sidebarDefinition = { toolPanels: toolPanels, position: 'left' };
            }
        }
    }

    /**
     * Applies the values of `ttOptions` to the internally used `options`.
     */
    private setupOptionsFromTTOptions(): void {
        if (!this.ttOptions) return;
        this.createGridFunctions();

        this.options.dataTask = { ...this.ttOptions.dataTask };

        this.options.config!.editColumns = [...(this.ttOptions.config?.editColumns || [])];
        this.options.config!.shortcuts = this.ttOptions.config?.shortcuts ?? false;
        this.options.config!.toolbar = { ...this.options.config!.toolbar, ...this.ttOptions.config?.toolbar };
        this.options.config!.serverSideHandling = this.ttOptions.config?.serverSideHandling || false;
        this.options.config!.navigation = { ...this.options.config?.navigation, ...this.ttOptions.config?.navigation };
        this.options.config!.specialFunc = { ...this.options.config!.specialFunc, ...this.ttOptions.config?.specialFunc };

        this.options.config!.css = { ...this.options.config!.css, ...this.ttOptions.config?.css };

        if (this.options.config?.serverSideHandling === true) {
            this.rowModelType = 'serverSide';
        } else {
            this.rowModelType = 'clientSide';
        }

        this.options.config!.sidebar = { ...this.options.config!.sidebar, ...this.ttOptions.config!.sidebar };

        this.options.kendo = { ...this.options.kendo, ...this.ttOptions.kendo };
        this.options.kendo!.height = this.ttOptions.kendo?.height || this.options.kendo!.height;
        this.options.kendo!.aggregate = this.ttOptions.kendo?.aggregate !== undefined && this.ttOptions.kendo?.aggregate !== null ? this.ttOptions.kendo?.aggregate : this.options.kendo!.aggregate!;
        this.defaultColumnDefinitions.wrapText = this.ttOptions.config?.css?.textWrapping ?? false;

        // if (this.options.config?.toolbar?.buttons && this.options.config.toolbar.buttons.length > 0) {
        //     this.options.config.toolbar.buttons.forEach((button) => {
        //         if (button.translate === true && button.text) {
        //             this.translations[button.text] = '';
        //         }

        //         // if (button.name.startsWith('§print')) {
        //         //     button.func = () => this.openPrintDialog(button);
        //         //     this.ttOpenPrintModal.emit(button);
        //         // }
        //     });
        // }

        // this.setGridHeight();
        // this.translate();
    }

    private async dbToolbarButtonClick(button: DBGridButton, event: MouseEvent) {
        try {
            if (button.type === 'datatask' && button.p2_datatask_keyno) {
                this.gridService.dbButtonDatatask({
                    button: button,
                    parameters: {
                        row: this.gridApi?.getSelectedRows()[0],
                        rows: this.gridApi?.getSelectedRows(),
                        isSelected: this.options.data?.rowData.filter((data) => data['is_selected'] === true) || [],
                    },
                    event: event,
                    navigateEventEmitter: this.ttNavigate,
                    datataskEventEmitter: this.ttDatatask,
                    options: this.options,
                });
            } else if (button.type === 'modal') {
                const modalEvent: ModalEvent = {
                    preventDefault: () => (modalEvent.defaultPrevented = true),
                    defaultPrevented: false,
                    modalComponent: button.component || '',
                    modalSize: button.modal_size || 'pst-ninetyfive',
                    rowData: null,
                };
                this.ttOpenModal.emit(modalEvent);

                if (modalEvent.defaultPrevented) return;

                await this.gridModalService.openDialogFromDbButton(button, {
                    selectedRows: this.options.gridfunc?.getIsSelectedRows() || [],
                    loadData: this.options.dataTask?.loadData,
                    rememberId: this.options.dataTask?.rememberId,
                });

                this.readData();
            } else if (button.type === 'print') {
                this.ttOpenPrintModal.emit();
            } else if (button.type === 'goto') {
                this.gridService.dbButtonGoto({ button: button, event: event, navigateEventEmitter: this.ttNavigate });
            }
        } catch (error) {
            this.modalService.openErrorDialog(`${error}`);
        }
    }

    /**
     * Retireves the grid data.
     */
    private async getGridRows(parameters?: any) {
        let rows: GridRow[] = [];

        if (this.ttOptions.dataTask?.loadData?.method && !isNaN(Number(this.ttOptions.dataTask.loadData.method))) {
            let parameters = await this.getLoadDataParameters();

            rows = await this.gridService.getGridData(Number(this.ttOptions.dataTask.loadData.method), parameters);

            // rows = this.mapDataToGridRows(rows);
        }

        return rows;
    }

    /**
     * Retrieves the load data parameters.
     *
     * @returns a promise containing the load data parameters.
     */
    private async getLoadDataParameters() {
        let parameters = {};

        if (!this.ttOptions.dataTask?.loadData?.parameters) return parameters;

        if (this.ttOptions.dataTask.loadData?.parameters instanceof Function) {
            const result = this.ttOptions.dataTask.loadData.parameters();

            if (result instanceof Promise) {
                parameters = { ...(await result), webpage_name: this.state.getCurrentName() };
            } else {
                parameters = { ...result, webpage_name: this.state.getCurrentName() };
            }
        } else {
            parameters = { ...this.ttOptions.dataTask.loadData.parameters, ...parameters, webpage_name: this.state.getCurrentName() };
        }

        return parameters;
    }

    /**
     * Retrieves the grid data server side using the given request parameters.
     *
     * @param requestParams the request parameters to select rows based of.
     * @returns an object containing grid data and total row count.
     */
    private async getGridRowsServerSide(requestParams: IServerSideGetRowsRequest): Promise<{ items: GridRow[]; total: number }> {
        try {
            let rows: { items: GridRow[]; total: number } = { items: [], total: 0 };

            if (this.ttOptions.dataTask?.loadData?.method && !isNaN(Number(this.ttOptions.dataTask.loadData.method))) {
                const params: ServerSideRequestParams = {
                    take: requestParams.endRow,
                    skip: requestParams.startRow,
                    filter: this.userSettings?.filter || { filters: [] },
                    sort: this.userSettings?.sort || [],
                    page: this.userSettings?.page || 1,
                    pageSize: this.userSettings?.pageSize,
                };
                let parameters = await this.getLoadDataParameters();

                rows = await this.gridService.getGridDataServerSide(Number(this.ttOptions.dataTask.loadData.method), parameters, params);
                rows.items = this.mapDataToGridRows(rows.items);
            }
            return rows;
        } catch (error) {
            throw Error(`${error}`);
        }
    }

    /**
     * Maps the given list of objects to grid rows.
     *
     * @param data the list of data to map as grid rows.
     * @returns a list of grid-rows.
     */
    private mapDataToGridRows(data: Object[]): GridRow[] {
        return data.map((row: GridRow) => {
            for (let colDef of this.options.data?.columnDefinitions || []) {
                row[colDef.colId ?? ''] = this.getCellValue(row, colDef);
            }

            return {
                ...row,
                _dirty: false,
                _uuid: crypto.randomUUID(),
            };
        });
    }

    /**
     * Retrieves the cell value of the row at the given column.
     *
     * @param row the row to find the cell of.
     * @param colDef the column to find the cell value of.
     * @returns the cell value of the cell at the given row and column.
     */
    private getCellValue(row: GridRow, colDef: ColDef) {
        if (!colDef.colId) return null;

        switch (colDef.cellDataType) {
            case 'boolean':
                return ['1', 1, true].includes(row[colDef.colId]);
            case 'number':
            case 'formatNumber':
                if (row[colDef.colId] || row[colDef.colId] === 0) {
                    if (!isNaN(Number(row[colDef.colId]))) {
                        return parseFloat(row[colDef.colId]);
                    }
                    return row[colDef.colId];
                } else {
                    return null;
                }
            case 'date':
                const dateString = row[colDef.colId];

                if (dateString && typeof dateString === 'string') {
                    const dateParts = dateString.split('-');

                    return dateParts.length === 3 ? new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2])) : null;
                } else if (dateString && dateString instanceof Date && dateString.toString() !== 'Invalid Date') {
                    return dateString;
                } else {
                    return null;
                }
            case 'datetime':
                const dateTimeString = row[colDef.colId];

                if (dateTimeString && typeof dateTimeString === 'string') {
                    const dateParts = dateTimeString.split(' ')[0].split('T')[0].split('-');
                    let timeString = [0, 0, 0];

                    if (dateTimeString.includes(' ')) {
                        timeString = dateTimeString
                            .split(' ')[1]
                            .substring(0, 8)
                            .split(':')
                            .map((part) => +part);
                    } else if (dateTimeString.includes('T')) {
                        timeString = dateTimeString
                            .split('T')[1]
                            .substring(0, 8)
                            .split(':')
                            .map((part) => +part);
                    }

                    return dateParts.length === 3 ? new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]), timeString[0], timeString[1], timeString[2]) : null;
                } else if (dateTimeString && dateTimeString instanceof Date && dateTimeString.toString() !== 'Invalid Date') {
                    return dateTimeString;
                } else {
                    return null;
                }
            default:
                return row[colDef.colId];
        }
    }

    private configureServerSideHandling() {
        const _self = this;

        this.cacheBlockSize = 25;

        this.serverSideDataSource = {
            getRows(params) {
                _self
                    .getGridRowsServerSide(params.request)
                    .then((data) => params.success({ rowData: data.items, rowCount: data.total }))
                    .catch((_) => params.fail());
            },
        };
    }

    /**
     * Fetches all data and applies it to the grid.
     *
     * @param options the grid option to use for initializing the grid.
     */
    private async initializeGrid({ showLoading, setupTTOptions = true, newDataTaskColumns }: { options?: GridOptions; showLoading?: boolean; setupTTOptions?: boolean; newDataTaskColumns?: boolean }) {
        delete this.dbGridOptions;

        if (setupTTOptions === true) {
            this.setupOptionsFromTTOptions();
        }

        if (showLoading !== false) {
            this.gridApi?.showLoadingOverlay();
        }

        try {
            await this.userStore.ensureIsReady();
            const promises: Promise<any>[] = [];

            this.customizeHeaderForToolbar();

            if (!!this.options?.dataTask?.loadData?.method && !isNaN(Number(this.options.dataTask.loadData.method))) {
                this.addLoadDataInfoToPagination();

                let parameters = await this.getLoadDataParameters();

                promises.push(this.getGridColumns({ method: this.options.dataTask.loadSetupId, loadDataMethod: Number(this.options.dataTask.loadData.method), params: parameters, force: newDataTaskColumns }), this.getUserSettings());

                if (this.options.config?.serverSideHandling === true) {
                    this.configureServerSideHandling();
                } else {
                    promises.push(this.getGridRows());
                }
            }

            const responses = await Promise.all(promises);
            let columns = responses[0];

            if (this.options?.config?.specialFunc?.edit) {
                this.options.data!.hasSpecialFuncEdit = true;
            }

            if ((!!this.options.config?.specialFunc?.buttons && this.options.config.specialFunc.buttons.length > 0) || this.options.data?.hasSpecialFuncEdit) {
                this.options.data!.hasSpecialFunc = true;
            }

            if (columns && columns instanceof Array) {
                columns = this.applyOptionsFromColSchema(columns);
                this.options.data!.columnDefinitions = this.mapColumnsToColDef(columns);

                if (this.options.config?.serverSideHandling === true) this.gridApi?.setGridOption('columnDefs', this.options.data!.columnDefinitions);
            }

            if (this.options.config?.toolbar?.buttons && this.options.config.toolbar.buttons.length > 0) {
                this.options.config.toolbar.buttons.forEach((button) => {
                    if (button.translate === true && button.text) {
                        this.translations[button.text] = '';
                    }
                });
            }

            if (this.options.config?.toolbar?.toggles && this.options.config.toolbar.toggles.length > 0) {
                this.options.config.toolbar.toggles.forEach((toggle) => {
                    toggle.states.forEach((toggleState) => {
                        if (toggleState.translate !== false && toggleState.text) {
                            this.translations[toggleState.text] = '';
                        }

                        toggleState._func = () => {
                            toggle.state = (toggle.state + 1) % toggle.states.length;
                            toggleState.func(toggle.state);
                        };
                    });
                });
            }

            this.setGridHeight();
            this.translate();

            this.configureSidebar();

            if (!!this.ttOptions.onSetup && this.ttOptions.onSetup instanceof Function) {
                const result = this.ttOptions.onSetup(columns, this.options.data!.columnInfo!, this.options.data!.columnDefinitions, this.translations);

                if (result instanceof Promise) await result;
            }

            if (this.options.config?.serverSideHandling !== true) {
                this.setRowData(responses[2]);
            }

            setTimeout(() => this.gridApi?.autoSizeColumns(['grid_functions']));

            this.applyUserSettings();

            if (!!this.options.kendo?.aggregate) {
                this.gridApi?.onFilterChanged();
            }
        } catch (error) {
            console.error('******************** GRID COULD NOT INITIALIZE ********************');
            console.error('error! error! error! error! error!');
            console.error(error);

            let message: string = '';

            if (!!(error as any)?.data?.message) {
                message = (error as any).data.message;

                if (!!(error as any)?.data?.data?.message && typeof (error as any)?.data?.data?.message === 'string' && (error as any).data.message !== (error as any).data.data.message) {
                    message = (error as any).data.data.message;
                }
            } else if (!!(error as any).data?.data?.message) {
                message = (error as any).data.data.message;
            } else {
                message = (error as any).message;
            }

            this.modalService.openErrorDialog(message);
        } finally {
            this.gridApi?.hideOverlay();
            this.ttOptions.reports = this.options.reports;
            this.ttOptions.gridfunc = this.options.gridfunc;
            this.ttOptionsChange.emit(this.ttOptions);
            this.configureToolbarButtonsVisibility();
            this.refreshToolbarButtonsVisibility();
        }
    }

    // #region AG-GRID EVENTS

    public onSortChanged(event: SortChangedEvent): void {
        if (event.source === 'uiColumnSorted') {
            const sort: GridSortSetting[] = [];

            this.gridApi?.getColumnState().forEach((column) => {
                if (column?.sort) {
                    sort.push({ field: column.colId, dir: column.sort, index: column.sortIndex });
                }
            });

            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                    sort: [],
                };
            }

            this.userSettings.sort = sort;
            this.saveUserSettingsSubject.next();
        }

        // this.gridApi?.redrawRows();
    }

    private getCurrentFilterState(): GridFilterSetting[] {
        const filterModel = this.gridApi?.getFilterModel();
        const filters: any[] = [];

        if (!this.userSettings) {
            this.userSettings = {
                columns: {},
                filter: { filters: [] },
                sort: [],
            };
        }

        for (let key in filterModel) {
            try {
                let operator;
                let value;
                let set: string[] = [];

                if (filterModel[key].filterType === 'multi') {
                    const filterModels = filterModel[key].filterModels;
                    if (filterModels[0]?.type) {
                        operator = this.gridService.getFilterOperatorType(filterModels[0].type);
                        value = this.getFilterValue(filterModels[0]);
                    }

                    if (filterModels[1]?.filterType === 'set') {
                        set = filterModels[1].values;
                    }
                } else {
                    operator = this.gridService.getFilterOperatorType(filterModel[key].type);
                    value = this.getFilterValue(filterModel[key]);
                }

                let filter: GridFilterSetting;

                if (this.options.config?.serverSideHandling !== true) {
                    filter = { field: key, operator: operator as GridFilterType, base64Encoded: true, value: !!value ? this.gridService.utoa(value) : value, set: set };
                } else {
                    filter = { field: key, operator: operator as GridFilterType, value: value, set: set };
                }

                filters.push(filter);

                if (!this.userSettings.columns?.[key]) {
                    this.userSettings.columns[key] = {};
                }

                if (this.userSettings?.columns?.[key]) {
                    this.userSettings.columns[key].filter = filter;
                }
            } catch (error) {
                console.log(error);
            }
        }

        return filters;
    }

    /**
     * Stores the state of the current filters when the filter is changed by the user.
     *
     * @param event the filter change event.
     */
    public onFilterChanged(event: FilterChangedEvent): void {
        if (event.source === 'columnFilter') {
            const filters: any[] = this.getCurrentFilterState();

            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                };
            }

            this.userSettings.filter.filters = filters;
            this.saveUserSettingsSubject.next();
        }

        this.configureToolbarButtonsVisibility();

        // @ts-ignore - Attribute exists, but interface is not updated by Ag-grid dev teams to have it.
        if (event.api?.rowModel?.rowsToDisplay) {
            // @ts-ignore - Attribute exists, but interface is not updated by Ag-grid dev teams to have it.
            this.updateFooterRow(event.api.rowModel.rowsToDisplay.map((row) => row.data));
        }
    }

    /**
     * Stores the state of the columns width when a column has been resized.
     *
     * @param event the column resize event.
     */
    public onColumnResized(event: ColumnResizedEvent): void {
        if (event.source === 'autosizeColumns' && event.finished) {
            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                };
            }

            event.columns?.forEach((column) => {
                this.userSettings!.columns[column.getColId()] = {
                    ...this.userSettings?.columns[column.getColId()],
                    width: column.getActualWidth(),
                };
            });

            this.saveUserSettingsSubject.next();
        }

        if (event.source === 'uiColumnResized' && event.finished && event.column) {
            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                };
            }

            if (!isNaN(event.column.getActualWidth())) {
                this.userSettings.columns[event.column.getColId()] = {
                    ...this.userSettings.columns[event.column.getColId()],
                    width: event.column.getActualWidth(),
                };

                this.saveUserSettingsSubject.next();
            }
        }
    }

    /**
     * Stores the state of the column order when a column has been moved.
     *
     * @param event the column moved event.
     */
    public onColumnMoved(event: ColumnMovedEvent | ColumnPinnedEvent): void {
        if ((event.source === 'uiColumnMoved' || event.source === 'toolPanelUi' || event.source === 'uiColumnDragged' || event.source === 'contextMenu') && ((event.type === 'columnMoved' && (event as ColumnMovedEvent)?.finished) || event.type === 'columnPinned')) {
            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                };
            }

            this.gridApi
                ?.getColumnDefs()
                ?.filter((column: ColDef) => !!this.options.data?.columnInfo?.[column.field!].remember !== false)
                ?.forEach((column: ColDef, index: number) => (this.userSettings!.columns[column.field!] = { ...this.userSettings?.columns[column.field!], order: index, pinned: column.pinned }));
            this.sortColumns();
            this.saveUserSettingsSubject.next();
        }
    }

    /**
     * Stores the state of the visible columns when a column has been hidden/shown.
     *
     * @param event the column visible event.
     */
    public onColumnVisible(event: ColumnVisibleEvent) {
        if ((event.source === 'toolPanelUi' || event.source === 'columnMenu') && event.visible !== undefined && event.visible !== null && this.ttOptions.dataTask?.rememberId) {
            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                };
            }

            if (event.column) {
                this.userSettings.columns[event.column.getColId()] = { ...this.userSettings.columns[event.column.getColId()], hidden: !event.visible };
            } else {
                this.gridApi?.getColumnDefs()?.forEach((column: ColDef) => {
                    this.userSettings!.columns[column.field!] = { ...this.userSettings!.columns[column.field!], hidden: !event.visible };
                });
            }

            this.saveUserSettingsSubject.next();
        }
    }

    /**
     * Handles insertion of row on keyboard event.
     *
     * @param event the keyboard event that riggered the insertion of a new row.
     */
    private async shortcutInsertRow(event: KeyboardEvent) {
        event.preventDefault();

        if (event.shiftKey) {
            await this.addRow({ addBefore: true });
        } else {
            await this.addRow({ addBefore: false });
        }
    }

    /**
     * Handles deletion of row(s) on keyboard event.
     *
     * @param event the kayboard event which triggered the deletion of row(s).
     */
    private async shortcutDeleteRows(event: KeyboardEvent) {
        event.preventDefault();

        if (!!this.gridApi && this.gridApi.getSelectedRows().length > 0) {
            await this.removeSelectedRows();
        } else {
            if (this.gridApi?.getFocusedCell()?.rowIndex !== undefined) {
                const gridRow = this.getRowNode({ rowIndex: this.gridApi.getFocusedCell()!.rowIndex });

                if (gridRow) {
                    await this.removeRow(<GridRow>gridRow.data, true, true, true);
                }
            }
        }
    }

    /**
     * Select the row at the given row-index.
     *
     * @param rowIndex the index of the row to set as selected.
     * @param clearCurrentSelected whether to clear the currently selected rows or not.
     */
    private selectRow(rowIndex: number, clearCurrentSelected: boolean = true) {
        const rowNode = this.gridApi?.getDisplayedRowAtIndex(rowIndex);

        if (!!rowNode) {
            rowNode?.setSelected(true, clearCurrentSelected);
        }
    }

    /**
     * Tabs to the next or previous cell using the given keyboard event.
     *
     * @param event the keyboard event to use for tabbing to the next cell.
     */
    private tabToNextOrPreviousCell(event: KeyboardEvent) {
        if (event.shiftKey) {
            this.gridApi!.tabToPreviousCell(event);
        } else {
            this.gridApi!.tabToNextCell(event);
        }
    }

    /**
     * Creates an editable callback parameters object based on the given column definition and row node.
     *
     * @param colDef the column definition to use for the editable callback.
     * @param rowNode the row node to use for the editable callback.
     * @returns an edtiable callback parameters object based on the given column definition and row node.
     * @throws errors if given invalid column definition, row node, or if the grid api is undefined.
     */
    private getEditableCallbackParams(colDef: ColDef, rowNode: IRowNode): EditableCallbackParams {
        if (!this.gridApi) {
            throw Error('Grid api is undefined');
        }
        if (!colDef.colId || !this.gridApi.getColumn(colDef.colId)) {
            throw Error("Column couldn't be found");
        }

        if (!rowNode) {
            throw Error('Row node missing');
        }

        // @ts-ignore column api is deprecated and no longer in use.
        return {
            context: null,
            api: this.gridApi,
            column: this.gridApi.getColumn(colDef)!,
            colDef: colDef,
            data: rowNode.data,
            node: rowNode,
        };
    }

    /**
     * Returns the first visible and editable column of the grid, `null` if there are no visible editable columns.
     *
     * @returns the first visible editable column of the grid, `null` if there are no visible editable columns.
     */
    private getFirstVisibleEditableColumn(): ColDef | null {
        return (
            this.options.data?.columnDefinitions.find((colDef) => {
                const columnInfo = this.options.data?.columnInfo?.[colDef.colId || ''];
                return (colDef.editable instanceof Function ? colDef.editable(this.getEditableCallbackParams(colDef, this.gridApi!.getDisplayedRowAtIndex(0)!)) : colDef.editable === true) && colDef.hide === false && columnInfo?.clickonly !== true;
            }) ?? null
        );
    }

    /**
     * Returns the last visible and editable column of the grid, `null` if there are no visible editable columns.
     *
     * @returns the last visible editable column of the grid, `null` if there are no visible editable columns.
     */
    private getLastVisibleEditableColumn(): ColDef | null {
        return (
            this.options
                .data!.columnDefinitions.slice()
                .reverse()
                .find((colDef) => {
                    const columnInfo = this.options.data?.columnInfo?.[colDef.colId || ''];
                    return (colDef.editable instanceof Function ? colDef.editable(this.getEditableCallbackParams(colDef, this.gridApi!.getDisplayedRowAtIndex(0)!)) : colDef.editable === true) && colDef.hide === false && columnInfo?.clickonly !== true;
                }) ?? null
        );
    }

    /**
     * Checks whether the cell at the given column and row index is the first editable cell of the grid.
     *
     * @param colId the column name of the column to check if has the position of the first editable cell.
     * @param rowIndex the row index of the cell to check if has the position of the first editable cell.
     * @returns `true` if the cell at the position of the given column and row index is the first editable cell, `false` if not.
     */
    private isFirstEditableCell(colId: string, rowIndex: number | null): boolean {
        const firstEditableColumn = this.getFirstVisibleEditableColumn();

        return !!firstEditableColumn && rowIndex !== null && rowIndex === 0 && colId === firstEditableColumn.colId;
    }

    /**
     * Checks whether the cell at the given column and row index is the last editable cell of the grid.
     *
     * @param colId the column name of the column to check if has the position of the last editable cell.
     * @param rowIndex the row index of the cell to check if has the position of the last editable cell.
     * @returns `true` if the cell at the position of the given column and row index is the last editable cell, `false` if not.
     */
    private isLastEditableCell(colId: string, rowIndex: number | null): boolean {
        const lastEditableColumn = this.getLastVisibleEditableColumn();
        const visibleRowLength = this.gridApi!.getDisplayedRowCount();

        return !!lastEditableColumn && rowIndex !== null && rowIndex === visibleRowLength - 1 && colId === lastEditableColumn.colId;
    }

    /**
     * Adds a new line using the keyboard event and cell key down event.
     *
     * @param shiftKey whether the shiftkey was entered with the event or not.
     * @param colId the name of the column the keyboard event happened on.
     * @param rowIndex the row index of the column the keyboard event happened on.
     * @returns the new row which was added, or null if no row could be added.
     */
    private async addNewLineOnCellKeyDown(shiftKey: boolean, colId: string, rowIndex: number | null): Promise<GridRow | undefined> {
        let newRow: GridRow | undefined;

        if (shiftKey === true && this.isFirstEditableCell(colId, rowIndex)) {
            newRow = await this.addRow({ index: 0, addBefore: true });
            this.selectRow(0, true);
        } else if (this.isLastEditableCell(colId, rowIndex)) {
            newRow = await this.addRow({ index: this.gridApi!.getDisplayedRowCount() - 1, addBefore: false });
            this.selectRow(this.gridApi!.getDisplayedRowCount() - 1, true);
        }

        return newRow;
    }

    /**
     * Performs a simulated tab event using the given keyboard event and cell key down event.
     *
     * @param event the keyboard event used to trigger the simulated tab..
     * @param cellKeyDownEvent the cell key down event used to trigger the simulated tab.
     */
    private simulateTabOnCellKeyDown(event: KeyboardEvent, colId: string) {
        this.tabToNextOrPreviousCell(event);

        let focusedCell = this.gridApi!.getFocusedCell();
        let columnInfo = this.options.data!.columnInfo![focusedCell?.column.getColId() || ''];
        let focusedRow = this.getRowNode({ rowIndex: focusedCell?.rowIndex });

        if (focusedRow && focusedCell) {
            while (!!focusedRow && (focusedCell?.column.isCellEditable(focusedRow) !== true || columnInfo.clickonly === true) && focusedCell?.column.getColDef().hide !== true) {
                this.tabToNextOrPreviousCell(event);

                if (focusedCell === this.gridApi?.getFocusedCell()) {
                    break;
                }

                focusedCell = this.gridApi!.getFocusedCell();
                columnInfo = this.options.data!.columnInfo![focusedCell?.column.getColId() || ''];
                focusedRow = this.getRowNode({ rowIndex: focusedCell?.rowIndex });
            }

            setTimeout(() => {
                this.gridApi?.startEditingCell({ colKey: focusedCell!.column, rowIndex: focusedCell!.rowIndex });
            });
        }
    }

    /**
     * Customize navigation in cell keydown event.
     *
     * @param event the cell keydown event.
     */
    public async onCellKeyDown(event: CellKeyDownEvent | FullWidthCellKeyDownEvent | null, keyboardEvent?: KeyboardEvent, colname?: string, rowIndex?: null | number) {
        if ((!!event && !event.event && event.type !== 'cellKeyDown') || (event === null && !keyboardEvent && !colname) || this.editingCell === false) return;

        const keyEvent = <KeyboardEvent>event?.event || keyboardEvent;
        const colId = (event as CellKeyDownEvent)?.column?.getColId() ?? colname;
        const index = (event as CellKeyDownEvent)?.rowIndex ?? rowIndex ?? null;

        if (keyEvent.key === 'Enter') {
            this.handleCellKeydownNavigation(keyEvent, colId, index);
        }
    }

    /**
     * Handles special navigation which should occur on keydown events on a cell.
     *
     * @param event the keyboard event of the cell key-down event.
     * @param colId the colunm name of the column were the cell key-down event occured.
     * @param rowIndex the index of the row where the cell key-down event occured.
     */
    private async handleCellKeydownNavigation(event: KeyboardEvent, colId: string, rowIndex: number | null) {
        let row: GridRow | undefined;

        if (this.options.config?.navigation?.newLine === true && ((this.isLastEditableCell(colId, rowIndex) && event.shiftKey === false) || (this.isFirstEditableCell(colId, rowIndex) && event.shiftKey === true))) {
            row = await this.addNewLineOnCellKeyDown(event.shiftKey, colId, rowIndex);
        }

        if (this.options.config?.navigation?.altNav === true && !row) {
            this.simulateTabOnCellKeyDown(event, colId);
        } else if (rowIndex !== null && !row) {
            setTimeout(() => {
                let rowNode;

                if (event.shiftKey) {
                    rowNode = this.getRowNode({ rowIndex: rowIndex - 1 });
                } else {
                    rowNode = this.getRowNode({ rowIndex: rowIndex + 1 });
                }

                if (!rowNode?.id) return;
                this.startCellEditing({ rowId: rowNode.id, columnKey: colId });
            });
        }
    }

    /**
     * Handles cell click event.
     *
     * @param event the cell click event.
     */
    public onCellClicked(event: CellClickedEvent) {
        const row = event.eventPath?.find((target) => target instanceof HTMLElement && target.getAttribute('row-id') === event.node.id);

        let clickedCell = { dataItem: event.data, column: event.colDef, row: row };

        if (this.ttOptions.optionfunc instanceof Function) {
            this.ttOptions.optionfunc({ data: { func: 'CellClickHandler', clickedCell: clickedCell, options: this.options } });
        }
    }

    /**
     * Marks the cell of the given event as dirty.
     *
     * @param event the event of the cell that was changed to mark as dirty.
     */
    public onCellValueChanged(event: CellValueChangedEvent) {
        let editedRow = this.options.data?.rowData.findIndex((row) => row[this.options.dataTask?.loadData?.primaryKey || '_uuid'] === event.data[this.options.dataTask?.loadData?.primaryKey || '_uuid']);

        if (editedRow !== -1) {
            this.options.data!.rowData[editedRow!] = event.data;
        }
    }

    private editingCell = false;
    /**
     * Checks if the cell is clickonly or not and then navigates accordingly.
     *
     * @param event the cell editing start event.
     */
    public onCellEditingStarted(event: CellEditingStartedEvent) {
        setTimeout(() => (this.editingCell = true));

        if (event.event && (event.event as KeyboardEvent)?.key === 'Tab' && this.options.data?.columnInfo?.[event.column.getColId()].clickonly === true) {
            if ((event.event as KeyboardEvent).shiftKey === true) {
                this.gridApi?.tabToPreviousCell(event.event as KeyboardEvent);
            } else {
                this.gridApi?.tabToNextCell(event.event as KeyboardEvent);
            }
        }
    }

    /**
     *
     *
     * @param event
     */
    public onCellEditingStopped(event: CellEditingStoppedEvent) {
        let column = this.options.data?.columnDefinitions.find((column) => column.field === event.column.getColId());

        if (!!this.ttOptions.optionfunc && this.ttOptions.optionfunc instanceof Function) {
            if (event.colDef.cellDataType === 'boolean') {
                this.ttOptions.optionfunc({ data: { func: 'CheckboxBoxClick', dataItem: event.data, key: event.colDef.colId, row: event.node.uiLevel } });
            }

            if (!!column && !!column.field && !!this.ttOptions.optionfunc && this.ttOptions.optionfunc instanceof Function) {
                this.ttOptions?.optionfunc({ data: { func: 'OnCellClose', ridx: event.rowIndex, cidx: this.options.data?.columnDefinitions.indexOf(column), cval: column.field, change: event.data[column.field], rdata: event.data } });
            }
        }
        setTimeout(() => (this.editingCell = false));

        this.refreshToolbarButtonsVisibility();
    }

    /**
     * Stores the page the user last changed to in user settings.
     *
     * @param event pagination changed event to store.
     */
    public onPaginationChanged(event: PaginationChangedEvent) {
        if ((event.newPage || event.newPageSize) && this.gridApi && this.userSettingsReady === true) {
            if (!this.userSettings) {
                this.userSettings = {
                    columns: {},
                    filter: { filters: [] },
                };
            }
            if (event.newPage) {
                this.userSettings.page = this.gridApi?.paginationGetCurrentPage() + 1;
                this.saveUserSettingsSubject.next();
            }

            if (event.newPageSize) {
                this.userSettings.pageSize = this.gridApi?.paginationGetPageSize();
                this.saveUserSettingsSubject.next();
            }
        }
    }

    /**
     * Uses onSelect callback to notify about selection change.
     *
     * @param event the row selections event.
     */
    public onRowSelected(event: RowSelectedEvent) {
        if (!!this.ttOptions?.onSelect && this.ttOptions.onSelect instanceof Function) {
            if (event.node.isSelected()) {
                this.ttOptions.onSelect({ $event: event, $item: event.node.data });
            } else {
                this.ttOptions.onSelect({ $event: event });
            }
        }

        if (!!this.ttOptions.optionfunc && this.ttOptions.optionfunc instanceof Function) {
            const data = { data: { func: 'OnChange', change: event } };

            this.ttOptions.optionfunc(data);
        }
    }

    public async onFirstDataRendered(event: FirstDataRenderedEvent) {
        if (!!this.ttOptions?.onDataBound && this.ttOptions.onDataBound instanceof Function) {
            this.ttOptions.onDataBound(event);
        }

        if (this.options?.dataTask?.rememberId) {
            this.applyUserSettings(event);
        }
    }

    public onModelUpdated(event: ModelUpdatedEvent) {
        this.ttModelUpdated.emit(event);

        try {
            if (!!this.ttOptions.onDataBinding && this.ttOptions.onDataBinding instanceof Function) {
                this.ttOptions.onDataBinding(event);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public async onGridReady($event: GridReadyEvent) {
        this.gridApi = $event.api;

        if (this.ttOptions) {
            await this.initializeGrid({ setupTTOptions: false });
            this.ttReady.emit();
        }
    }

    // #endregion AG-GRID EVENTS

    private async translate() {
        const translations = await this.translateService.translateBatch(Object.keys(this.translations));

        for (let key of Object.keys(this.translations)) {
            if (!!translations[key]) {
                this.translations[key] = translations[key];
            }
        }
    }

    /**
     * Returns the key name for the datatask configuration required by the given state.
     *
     * @param state the state to get the keyname of datatask configuration for.
     * @returns the key name for the datatask configuration or `null` if state not supported.
     */
    private getDataTaskId(state: 'add' | 'all' | 'update' | 'remove'): 'addRow' | 'saveData' | 'removeRow' | null {
        let dataTaskId: keyof GridDataTask | null = null;

        switch (state) {
            case 'add':
                dataTaskId = 'addRow';
                break;
            case 'update':
            case 'all':
                dataTaskId = 'saveData';
                break;
            case 'remove':
                dataTaskId = 'removeRow';
                break;
            default:
                break;
        }

        return dataTaskId;
    }

    private validateStateMethod(state: ChangeState) {
        const dataTaskId = this.getDataTaskId(state);

        if (dataTaskId === null) return;

        if (!this.options.dataTask || typeof this.options.dataTask !== 'object') {
            this.options.dataTask = {};
        }

        this.options.dataTask[dataTaskId] ??= { method: 2219, parameters: {} };
        this.options.dataTask[dataTaskId]!.parameters ??= {};

        if (!(this.options.dataTask[dataTaskId]?.method instanceof Function)) {
            if (!!this.options.dataTask[dataTaskId]?.method && !isNaN(Number(this.options.dataTask[dataTaskId]?.method))) {
                this.options.dataTask[dataTaskId]!.method = parseInt(`${this.options.dataTask[dataTaskId]?.method}`, 10);
            } else {
                this.options.dataTask[dataTaskId]!.method = 2219;
            }
        }
    }

    // #region ANGULAR COMPONENT LIFECYCLE

    ngOnInit(): void {
        if (!!this.appSettings?.settings?.agGridLicense) {
            LicenseManager.setLicenseKey(this.appSettings.settings.agGridLicense);
        }

        this.userStore.languageIdChanged.subscribe((languageId) => (this.localeText = this.gridService.getLocaleText(languageId)));

        if (this.ttOptions) {
            this.setupOptionsFromTTOptions();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttOptions']?.isFirstChange() === false) {
            this.setupOptionsFromTTOptions();
        }
    }

    ngOnDestroy(): void {
        this.gridService.activeGridId = null;
        this.saveSubscription?.unsubscribe();
        this.refreshSubscription?.unsubscribe();
    }

    // #endregion ANGULAR COMPONENT LIFECYCLE

    // #region GRID FUNCTIONS

    /**
     * Creates grid functions object and appends to `this.options.gridfunc` property.
     */
    private createGridFunctions() {
        this.options.gridfunc = {
            /* ok */ addRowAfter: (atIndex: number, dataItem?: GridRow) => this.addRow({ index: atIndex, addBefore: false, rowItem: dataItem }),
            /* ok */ addRowBefore: (atIndex: number, dataItem?: GridRow) => this.addRow({ index: atIndex, addBefore: true, rowItem: dataItem }),
            /* ok */ callPopupTable: (data: Record<string, unknown>[]) => this.gridModalService.openPopupTableModal(data),
            /* ok */ clearFilter: () => this.gridApi?.setFilterModel(null),
            /* ok */ clearSorting: () => this.gridApi?.applyColumnState({ defaultState: { sort: null } }),
            /* ok */ editCell: (rowIndex: number, colIndex: number) => this.editCell({ rowIndex: rowIndex, columnIndex: colIndex }),
            /* ok */ getAllRows: () => this.getRows(),
            /* ok (deprecated) */ getColumnFormatType: (key) => '',
            /* ok */ getColumnSchema: () => {
                console.log(this.options);
                return this.options.data!.columnInfo!;
            },
            /* ok */ getColumnState: () => this.gridApi?.getColumnState() || [],
            /* ok */ getDataItem: (atIndex?: number) => (atIndex !== undefined ? this.gridApi!.getDisplayedRowAtIndex(atIndex) : this.gridApi!.getDisplayedRowAtIndex(0)) || null,
            /* ok */ getDataItems: () => this.gridApi!.getRenderedNodes().map((node) => node.data),
            /* ok (deprecated) */ getDataSource: () => ({ sort: () => [] }),
            /* ok */ getDirtyRows: () => this.getRows(true),
            /* ok (deprecated) */ getGrid: () => ({}),
            /* ok */ getGridApi: () => this.gridApi!,
            /* ok */ getGridColumns: () => this.options.data!.columnDefinitions,
            /* ok (deprecated) */ getResponse: () => [],
            /* ok (deprecated) */ getResponseColumns: () => [],
            /* ok */ getRowAt: (index: number) => this.gridApi!.getDisplayedRowAtIndex(index) || null,
            /* ok */ getRows: (dirty: boolean = false, filtered: boolean = false, sorted: boolean = false) => this.getRows(dirty, filtered, sorted),
            /* ok */ getSelectedRow: () => (this.gridApi!.getSelectedRows().length === 1 ? this.gridApi!.getSelectedRows()[0] : this.gridApi!.getSelectedRows()),
            /* ok */ getSelectedRows: () => this.gridApi!.getSelectedRows(),
            getIsSelectedRows: () => this.getRows().filter((value) => value?.['is_selected'] === true),
            /* ok */ gridProgress: (spin: boolean = true) => (spin === true ? this.gridApi?.showLoadingOverlay() : this.gridApi?.hideOverlay()),
            /* ok */ hasRows: () => this.getRows().length > 0,
            /* ok */ isReady: () => this.gridApi !== undefined,
            /* ok */ read: () => this.readData(),
            redrawRow: (_, row) => this.redrawRow({ row: row }),
            /* ok */ rebind: (newDataTaskColumns: boolean = false) => this.initializeGrid({ newDataTaskColumns: newDataTaskColumns }),
            /* ok */ refresh: () => this.refreshData(),
            /* ok */ refreshAggregates: () => this.updateFooterRow(),
            /* ok */ refreshToolbarBtnDisability: () => this.refreshToolbarButtonsVisibility(),
            /* ok */ removeRow: (dataItem: GridRow) => this.removeRow(dataItem, true, true, true),
            /* ok */ removeRows: () => this.removeSelectedRows(),
            /* ok */ resize: () => console.log('gridfunc.resize() => nothing to resize for now...'),
            /* ok */ saveChanges: (showProgress: boolean = true) => this.saveAllChanges(showProgress),
            /* ok */ selectRow: (index: number) => this.gridApi?.getDisplayedRowAtIndex(index)?.setSelected(true, true),
            /* ok */ setDataSource: (newDataSource: GridRow[]) => this.setRowData(newDataSource),
            /* ok */ setFocusToCell: (rowIndex: number, colIndex) => this.setFocusedCell({ rowIndex: rowIndex, columnIndex: colIndex }),
            /* ok */ test: (p1: unknown, p2: unknown) => this.test(p1, p2),
            /* ok */ updateRow: (row: GridRow | null, rowIdx?: number) => this.redrawRow({ row: row, rowIndex: rowIdx }),
            viewMatching: (criteria: string | undefined, value: unknown) => this.viewMatching(criteria ?? '', value),
        };
    }

    /**
     * Retrieves the list of rows. If dirty is true, only dirty rows are returned, if filtered is true, only filtered rows are returned, if sortes is
     * true the list is sorted according to the current sort state.
     *
     * @param dirty whether the list should only contain dirty rows.
     * @param filtered whether the list should only contain the filtered rows.
     * @param sorted whether the list should be sorted according to the current sort state of the grid.
     * @returns list of row-data.
     */
    private getRows(dirty: boolean = false, filtered: boolean = false, sorted: boolean = false) {
        let data = [...this.options.data!.rowData];

        if (filtered && !sorted) {
            data = [];
            this.gridApi?.forEachNodeAfterFilter((rowNode) => data.push(rowNode.data));
        } else if (filtered && sorted) {
            data = [];
            this.gridApi?.forEachNodeAfterFilterAndSort((rowNode) => data.push(rowNode.data));
        }

        if (dirty) {
            data = data.filter((row) => row._dirty === true);
        }

        if (filtered && !sorted) {
            data = this.sortData(data);
        }

        return data;
    }

    /**
     * Sorts the given data according to the current column state of the grid
     *
     * @param data the data to sort.
     * @returns the data list after sorting.
     */
    private sortData(data: GridRow[]) {
        this.gridApi!.getColumnState()
            .filter((state) => !!state.sort)
            .forEach((state) => {
                data.sort((a, b) => {
                    const valueA = a[state.colId];
                    const valueB = b[state.colId];
                    if (valueA === valueB) return 0;

                    const sortDirection = state.sort === 'asc' ? 1 : -1;
                    return valueA > valueB ? sortDirection : sortDirection * -1;
                });
            });

        return data;
    }

    /**
     * Starts cell editing on the cell matching the given row and column parameters.
     *
     * @param param0 row and column parameters of the cell to start editing on, only one for row and one for column is required, not all.
     */
    private editCell({ row, rowIndex, rowId, columnIndex, columnKey }: { row?: GridRow | null; rowIndex?: number | null; rowId?: string | null; columnIndex?: number | null; columnKey?: string | null }) {
        // TODO: theres a startCellEditing function, try and merge these.
        const rowNode = this.getRowNode({ row: row, rowIndex: rowIndex, rowId: rowId });
        let colKey = columnKey ?? null;

        if (columnIndex !== undefined && columnIndex !== null && !colKey) {
            colKey = this.options.data?.columnDefinitions?.filter((column) => column.hide !== true)?.[columnIndex]?.colId ?? null;
        }

        if (!!rowNode && rowNode.rowIndex !== null && !!colKey) {
            this.gridApi?.startEditingCell({ rowIndex: rowNode.rowIndex, colKey: colKey });
        }
    }

    /**
     * Sets the focused cell to the cell which matches the row parameter provided and the column parameter provided,
     *
     * @param param0 the row and column of the cell to focus.
     */
    private setFocusedCell({ row, rowIndex, rowId, columnIndex, columnKey }: { row?: GridRow | null; rowIndex?: number | null; rowId?: string | null; columnIndex?: number | null; columnKey?: string | null }) {
        let indexOfRow = rowIndex ?? this.getRowNode({ row: row, rowId: rowId, rowIndex: rowIndex })?.rowIndex ?? null;
        let colKey = columnKey ?? null;

        if (columnIndex !== undefined && columnIndex !== null && !colKey) {
            colKey = this.options.data?.columnDefinitions?.filter((column) => column.hide !== true)?.[columnIndex]?.colId ?? null;
        }

        if (indexOfRow !== null && colKey !== null) {
            this.gridApi?.setFocusedCell(indexOfRow, colKey, null);
        }
    }

    /**
     * Redraws the row which matches the given row parameters.
     *
     * @param param0 the row of which to redraw, only one is necessary, `row` takes precedence, then `rowIndex`, then `rowId`.
     */
    private redrawRow({ row, rowIndex, rowId }: { row?: GridRow | null; rowIndex?: number; rowId?: string }) {
        let rowNode: IRowNode | null = null;

        if (!!row) {
            rowNode = this.gridApi?.getRowNode(row._uuid || '') || null;
        } else if (rowIndex !== undefined && rowIndex !== null) {
            rowNode = this.gridApi?.getDisplayedRowAtIndex(rowIndex) || null;
        } else if (!!rowId && typeof rowId === 'string') {
            rowNode = this.gridApi?.getRowNode(rowId) || null;
        }

        if (!!rowNode) {
            rowNode.data = row;
            rowNode.setData({ _uuid: rowNode.id, ...row });
            if (!!rowNode.data) this.updateCellEditors(rowNode.data);
            // this.gridApi?.redrawRows({ rowNodes: [rowNode] });
        }
    }

    /**
     * Sets the given rowData as the rowdata of the grid, then redraws the grid.
     *
     * @param rowData the rowdata to set in the grid.
     */
    private setRowData(rowData: GridRow[]): void {
        if (!rowData) rowData = [];

        if (rowData.some((row) => Object.hasOwn(row, '_uuid') === false)) {
            this.options.data!.rowData = this.mapDataToGridRows(rowData);
        } else {
            this.options.data!.rowData = rowData;
        }

        this.gridApi?.setGridOption('rowData', this.options.data!.rowData);
        this.updateFooterRow();

        if (this.ttOptions.gridfunc) {
            this.refreshToolbarButtonsVisibility();
            this.configureToolbarButtonsVisibility();
        }
    }

    /**
     * Retrieves the row node matching the given parameters. `rowId` takes precedence over `roIndex`, and `rowIndex` takes precedence over `row`.
     *
     * @param param0 the row information of which to retireve row node for, only one parameters is required.
     * @returns the row node matching the given parameters.
     */
    private getRowNode({ row, rowIndex, rowId }: { row?: GridRow | null; rowIndex?: number | null; rowId?: string | null }): IRowNode | null {
        let rowNode: IRowNode | null = null;

        if (!!rowId) {
            rowNode = this.gridApi?.getRowNode(rowId) ?? null;
        } else if (rowIndex !== undefined && rowIndex !== null) {
            rowNode = this.gridApi?.getDisplayedRowAtIndex(rowIndex) ?? null;
        } else if (!!row && !!row._uuid) {
            rowNode = this.gridApi?.getRowNode(row._uuid) ?? null;
        }

        return rowNode;
    }

    private test(p1: unknown, p2: unknown) {
        console.log('bjs func test ok: ' + p1 + ' - ' + p2);
        return 'efgh';
    }

    /**
     * Rerenders the grid to only display rows which has a property of the given keyname whose value equals the given value.
     *
     * @param keyname the keyname of the property in the rows to match with.
     * @param value the value to match the value of the property with.
     */
    private viewMatching(keyname: string, value: unknown) {
        if (this.options.data?.rowData.length === 0) {
            this.gridApi?.setGridOption('rowData', this.options.data.rowData);
        }

        if (keyname === '') {
            this.gridApi?.setGridOption('rowData', this.options.data!.rowData);
        } else if (!!keyname) {
            const matchingData = [];

            for (let row of this.options.data!.rowData) {
                if (row[keyname] === value) {
                    matchingData.push(row);
                }
            }

            if (matchingData.length > 0) {
                this.gridApi?.setGridOption('rowData', matchingData);
            }
        }
    }

    // #endregion GRID FUNCTIONS
}
