(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sopCl', {
        templateUrl: 'views/components/views/sopCl/sopCl.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'sopClService', function ($stateParams, stateService, utilityService, sopClService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
            vm.model = {
                sopKeyNo: $stateParams.sop_keyno,
                sopClKeyNo: $stateParams.sopcl_keyno,
                choice: {},
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            sopClService.loadSopClGet(vm.model.sopKeyNo, vm.model.sopClKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.choice);
            });
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSopCl = function () {
                vm.model.lockedSave = true;
                
                sopClService.save(vm.model.choice).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();