(function () {
    'use strict';

    // ReSharper disable once UndeclaredGlobalVariableUsing
    let module = angular.module('imApp');

    module.component('p2UpdateProcedures', {
        templateUrl: 'views/components/views/p2UpdateProcedures/p2UpdateProcedures.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'rememberService', 'stateService', 'p2UpdateProceduresService', function (utilityService, rememberService, stateService, p2UpdateProceduresService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
    		
            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ], 
                settingUpdateProcedure: {},
                itemsListUpdateProcedures: []
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                p2UpdateProceduresService.getUpdateProcedure().then(function (result) {
                    angular.copy(result[0], vm.model.settingUpdateProcedure);
                });
            };

            let loadUpdateProcedures = function () {
                p2UpdateProceduresService.listUpdateProcedures().then(function (result) {
                    angular.copy(result, vm.model.itemsListUpdateProcedures);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.settingUpdateProcedure[id], true) !== true) return;

                        variableValue = vm.model.settingUpdateProcedure[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.settingUpdateProcedure.searchtext = '';

                        if (vm.model.settingUpdateProcedure.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                };
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2updateprocedureedit':
                        go({ p2_updateprocedure_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (vm.model.settingUpdateProcedure.searchtext > '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                };
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadUpdateProcedures();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
