import { Injectable } from '@angular/core';
import { DataTaskService } from './data-task.service';
import { Deferred } from '../models/deferred.model';
import { UtilityService } from './utility.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { openWordlangDialog, WordlangDialogComponent } from '../../modal/wordlang-dialog/wordlang-dialog.component';
import { WordlangDialogInput } from '../../model/wordlang-dialog-input.model';
import { DataCacheService } from '../../modules/tt-data-cache/services/data-cache.service';
import { Result } from '../../modules/utilities/result';
import { LanguageService } from './language.service';
import { DataCache } from '../../modules/tt-data-cache/data-cache';
import { AppConfigService } from '../../services/appconfig.service';

export interface ITranslation {
    id: string;
    translation: string;
    isTranslated: boolean;
}

interface ITranslateCallback {
    remaining: Record<string, string>;
    translations: Record<string, string>;
    handler: Deferred<Record<string, string>>;
}

interface ITranslationResponse {
    id: string;
    wl: string;
    lid: string;
}

@Injectable({
    providedIn: 'root'
})
export class TranslateService {
    private _waitCallbacks: Array<ITranslateCallback> = []
    private _waiting: Array<string> = [];
    private _waitCount: number = 0;
    private _waitTimeout: number | undefined;
    private _dataCache: DataCache<ITranslation> | undefined;
    private _isRefreshed: string[] = [];
    private _cacheId: string;

    constructor(private us: UtilityService, private dt: DataTaskService, private dialog: MatDialog, private dcs: DataCacheService, private ls: LanguageService, private config: AppConfigService) {
        this._cacheId = 'translations_' + this.config.settings.clientId + '_' + this.ls.GetCurrentLanguage();
    }

    private async ensureHasDataCache() {
        if (this._dataCache === undefined) {
            this._dataCache = await this.dcs.GetCache<ITranslation>(this._cacheId);
        }
    }

    public showModal(wordId: string, autoSave: boolean) {
        openWordlangDialog(this.dialog, { wordId, autoSave } as WordlangDialogInput);
        
        //const dialogConfig = new MatDialogConfig();

        //dialogConfig.disableClose = true;
        //dialogConfig.autoFocus = true;

        //console.log('opening modal');

        //this.dialog.open(WordlangDialogComponent, dialogConfig);

        //var deferred = $q.defer();

        //var instance = $uibModal.open({
        //    component: 'modalWordlang',
        //    resolve: {
        //        word_id: function () {
        //            return word_id;
        //        },
        //        autoSave: function () {
        //            return autoSave;
        //        }
        //    },
        //    size: 'pst-ninety',
        //    backdrop: true
        //});

        //instance.result.then(function (response) {
        //    deferred.resolve(response);
        //}, function (response) {
        //    deferred.resolve(response);
        //});

        //return deferred.promise;
    }

    public on(translate: any, _: undefined, handler: Function): Function {
        let self = this;

        new Promise<any>(async (resolve, reject) => {
            let response = await self.commonTranslate(translate);

            let result: any;

            if (self.us.isString(response)) {
                result = response;
            } else {
                result = response[Object.keys(response)[0]];
            }

            handler(result);
        });

        return function () { };
    }

    public translate(translationId: string): Promise<any> {
        let self = this;
        
        return new Promise<any>(async (resolve, reject) => {
            let response = await self.commonTranslate(translationId);

            resolve(response[Object.keys(response)[0]]);
        });
    }

    public translateBatch(translationIds: string[]): Promise<any> {
        let self = this;

        return self.commonTranslate(translationIds);
    }

    private ensureIsStringArray(parms: any): string[] {
        // Must be able to handle the following parameter formats
        //  'wordId'
        //  {wordId: ''}
        //  {wordId1: '', wordId2: '')
        //  [wordId1, wordId2]
        //  [{wordId: ''}, {wordId: ''}]

        let retval: string[] = [];

        let self = this;

        function addValue(val: any) {
            if (self.us.isString(val)) {
                retval.push(val);
            } else if (self.us.isArray(val)) {
                for (let i = 0; i < val.length; i++) {
                    addValue(val[i]);
                }
            } else if (self.us.isObject(val)) {
                let keys = Object.keys(val);

                for (let i = 0; i < keys.length; i++) {
                    retval.push(keys[i]);
                }
            }
        }

        addValue(parms);

        return retval;
    }

    private async commonTranslate(parms: any): Promise<Record<string, string>> {
        await this.ensureHasDataCache();

        let self = this;

        let ids = self.ensureIsStringArray(parms);

        if (ids.length < 1) return Promise.resolve({} as Record<string, string>);

        let callback = {
            remaining: {},
            translations: {},
            handler: new Deferred<Record<string, string>>()
        } as ITranslateCallback;

        let mustTranslate: Array<string> = [];

        ids.forEach(function (id) {
            let cacheResult = self._dataCache?.Get(id) ?? Result.fail('');

            callback.remaining[id] = id;
            callback.translations[id] = '';
            
            if (cacheResult?.isSuccess ?? false) {
                const val = cacheResult.getValue();

                if (val.isTranslated) {
                    callback.translations[id] = val.translation;

                    if (id in callback.remaining) {
                        delete callback.remaining[id];
                    }
                }

                if (!self._isRefreshed.includes(id)) {
                    // All words should be translated once to make sure the cache is updated with new values.
                    // Any change will show up next time the translation is requested.
                    mustTranslate.push(id);
                }
            } else {
                self._dataCache?.Set(id, {
                    id: id,
                    translation: '',
                    isTranslated: false
                });

                mustTranslate.push(id);
            }
        });

        if (Object.keys(callback.remaining).length === 0) {
            callback.handler.resolve(callback.translations);
        } else {
            self._waitCallbacks.push(callback);
        }
        
        if (mustTranslate.length > 0) {
            //await self._dataCache?.Update();

            this._isRefreshed = this._isRefreshed.concat(mustTranslate);
            
            self._waiting = self._waiting.concat(mustTranslate);

            self.translateWaiting();
        }

        return callback.handler.promise;
    }

    private async translateWaiting() {
        let self = this;

        if (self._waitCount !== self._waiting.length) {
            self._waitCount = self._waiting.length;

            if (typeof self._waitTimeout === 'number') {
                clearTimeout(self._waitTimeout);

                self._waitTimeout = undefined;
            }

            self._waitTimeout = window.setTimeout(self.translateWaiting.bind(this), 450);
            return;
        }

        let ids = self._waiting.splice(0);

        self._waitCount = self._waiting.length;
        self._waitTimeout = undefined;

        // BJS 20240827 - Modified to use new translate procedure
        let response = await self.dt.Post(3320, { timestamp: 0, words: ids }) as Array<ITranslationResponse>;

        // BJS 20240827 - Base64 decodes encoded unicode strings
        function b64DecodeUnicode(str: string) {
            // Going backwards: from bytestream, to percent-encoding, to original string.
            return decodeURIComponent(atob(str).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
        }

        response.forEach(function (tr: ITranslationResponse) {
            // BJS 20240827 - The new translate service returns base64 encoded translation strings
            var translation = b64DecodeUnicode(tr.wl);

            let cacheResult = self._dataCache?.Get(translation) ?? Result.fail('');

            if (cacheResult?.isSuccess ?? false) {
                let val = cacheResult.getValue();

                val.translation = translation;
                val.isTranslated = true;

                self._dataCache?.Set(tr.id, val);
            } else {
                self._dataCache?.Set(tr.id, {
                    id: tr.id,
                    translation: translation,
                    isTranslated: true
                });
            }

            self._waitCallbacks.forEach(function (tc: ITranslateCallback) {
                if (tr.id in tc.remaining) {
                    tc.translations[tr.id] = translation;

                    delete tc.remaining[tr.id];
                }
            });
        });

        await self._dataCache?.Update();

        let callbacks = self._waitCallbacks.splice(0);

        self._waitCallbacks = [];

        callbacks.forEach(function (cb) {
            if (Object.keys(cb.remaining).length > 0) {
                self._waitCallbacks.push(cb);
            } else {
                cb.handler.resolve(cb.translations);
            }
        });
    }
}
