(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('textileStockSearch', {
        templateUrl: 'views/components/views/textileStockSearch/textileStockSearch.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'rememberService', 'textileStockSearchService', function (stateService, utilityService, rememberService, textileStockSearchService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                itemsListTextileStocks: []
            };
            
            // ## LOAD PROCEDURE CALLS ##
            
            textileStockSearchService.loadTextileStockSearchGet().then(function (data) {
                vm.model.setting = angular.copy(data[0]);
                
                loadTextileStockSearches();
            });
            
            var loadTextileStockSearches = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListTextileStocks = [];
                
                textileStockSearchService.loadTextileStockSearchList(vm.model.setting.searchtext).then(function (data) {
                    angular.copy(data, vm.model.itemsListTextileStocks);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'searchtext':
                            variableNames[key] = stateService.getCurrentName() + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadTextileStockSearches();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ## SEARCH FUNCTION ##
            
            vm.searchItems = function () {
                if (vm.model.setting.searchtext > '') {
                    rememberFunc('searchtext');
                    loadTextileStockSearches();
                }
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadTextileStockSearches();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') {
                            rememberFunc('searchtext');
                            loadTextileStockSearches();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

        }]
    });
})();
