<div class="container">
    <h2 mat-dialog-title
        class="tt-signature-background col-xs-12"
        [ngStyle]="style['title']"
        cdkDrag cdkDragRootElement=".cdk-overlay-pane"
        cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>
        {{ translations["signature"] }}
    </h2>
    <mat-dialog-content style="padding: 1.8rem; padding-bottom: 1rem">
            <canvas #canvas class="canvas"></canvas>
    </mat-dialog-content>
     <mat-dialog-actions>
         <tt-button (ttClick)="clear()" ttIcon="fas fa-eraser" ttType="primary" ></tt-button>
         <div class="right">
             <tt-button (ttClick)="dialogRef.close(null)" ttIcon="far fa-times" ttType="danger"></tt-button>
             <tt-button (ttClick)="confirm()" ttIcon="fas fa-check" ttType="success"></tt-button>
         </div>
    </mat-dialog-actions>

</div>
