(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('currencyTypes', {
        templateUrl: 'views/components/views/currencyTypes/currencyTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'currencyTypesService', function ($stateParams, $q, utilityService, currencyTypesService) {
            const vm = this;
            let valutaId = $stateParams.valuta_id;
            
            vm.model = {
                setting: {},
    			itemsListCurrencyTypes: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                currencyTypesService.getCurrencyType({ valuta_id: valutaId }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadCurrencyTypes();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCurrencyTypes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.valuta_id) !== true) return;

                return currencyTypesService.listCurrencyTypes({ valuta_id: vm.model.setting.valuta_id }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCurrencyTypes);
                });
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
