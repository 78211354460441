(function () {
    'use strict';

    angular.module("imApp").factory("prodGrp3MenuService", ['$ihttp', function ($ihttp) {
        let service = {
            getMenu: function (parms) {
                return $ihttp.post({
                    method: 3078,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();