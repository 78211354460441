(function () {
    'use strict';

    // The unicorn: https://stackoverflow.com/a/18234317/1028230
    // prettier-ignore
    String.prototype.formatUnicorn = String.prototype.formatUnicorn || function () {
        var str = this.toString();

        if (arguments.length) {
            var t = typeof arguments[0];
            var key;
            var args = 'string' === t || 'number' === t
                ? Array.prototype.slice.call(arguments)
                : arguments[0];

            for (key in args) {
                str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
            }
        }

        return str;
    };

    var module = angular.module("imApp");

    module.factory("utilityService", ['$q', 'modalService', function ($q, modalService) {
        var service = {
            isObject: function (obj) {
                return angular.isDefined(obj) && angular.isObject(obj);
            },

            destroy: function (onDestroy) {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            },

            randomId: function (length, characters) {
                characters = characters || 'abcdefghijklmnopqrstuvwxyz';

                let result = '';
                let charactersLength = characters.length;

                for (let i = 0; i < length; i++) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }

                return result;
            },

            generateId: function(length, postfix, vm) {
                let id = service.randomId(length) + (postfix || '');

                if (angular.isObject(vm)) {
                    while (angular.isDefined(vm[id])) {
                        id = service.randomId(length) + (postfix || '');
                    }
                }

                return id;
            },

            isBoolean: function (value) {
                return value === true || value === false || toString.call(value) == '[object Boolean]';
            },

            getModelId: function (element) {
                for (var i = 0; i < element[0].attributes.length; i++) {
                    if (element[0].attributes[i].nodeName !== 'tt-model')
                        continue;

                    return element[0].attributes[i].nodeValue;
                }

                return undefined;
            },

            getAttrId: function (element, id) {
                for (var i = 0; i < element[0].attributes.length; i++) {
                    if (element[0].attributes[i].nodeName !== id)
                        continue;

                    return element[0].attributes[i].nodeValue;
                }

                return undefined;
            },

            getSearchId: function (element) {
                for (var i = 0; i < element[0].attributes.length; i++) {
                    if (element[0].attributes[i].nodeName !== 'tt-search')
                        continue;

                    return element[0].attributes[i].nodeValue;
                }

                return undefined;
            },

            // BJS 20210809
            getDiff: function (newObject, oldObject, path) {
                var diff = [];

                if (angular.isUndefined(newObject) || angular.isUndefined(oldObject))
                    return null;

                if (angular.isObject(newObject) !== true || angular.isObject(oldObject) !== true)
                    return null;

                if (angular.equals(newObject, oldObject))
                    return diff;

                path = path || '';

                angular.forEach(newObject, function (value, key) {
                    var subPath = path + path.length > 0 ? '.' : '' + key;

                    if (angular.isUndefined(oldObject[key])) {
                        diff.push({
                            state: 'new',
                            path: subPath,
                            value: value
                        });
                    } else {
                        if (angular.isObject(value)) {
                            var subDiff = service.getDiff(value, oldObject[key], subPath);

                            if (subDiff !== null) {
                                for (var i = 0; i < subDiff.length; i++) {
                                    diff.push(subDiff[i]);
                                }
                            }
                        } else {
                            if (value !== oldObject[key]) {
                                diff.push({
                                    state: 'diff',
                                    path: subPath,
                                    newValue: value,
                                    oldValue: oldObject[key]
                                });
                            }
                        }
                    }
                });

                angular.forEach(oldObject, function (value, key) {
                    if (angular.isUndefined(newObject[key])) {
                        var subPath = path + path.length > 0 ? '.' : '' + key;

                        diff.push({
                            state: 'removed',
                            path: subPath,
                            value: value
                        });
                    }
                });

                return diff;
            },

            // BJS 20210708
            compareObjects: function (s, t, propsToIgnoreWhenCompare) {
                function compareFirstToSecond(first, second, propsToIgnore, path) {
                    var msgs = [];

                    propsToIgnore = propsToIgnore || [];

                    // Check type
                    if (typeof first !== typeof second) {
                        msgs.push(
                            path +
                            ' -- two objects not the same type $${0}$${1}$$'.formatUnicorn(
                                typeof first,
                                typeof second
                            )
                        );
                        return msgs;
                    }

                    // Check value
                    // Keep in mind that typeof null is 'object'
                    // https://stackoverflow.com/a/18808270/1028230
                    if (typeof first !== 'object' || first === null) {
                        if (first !== second) {
                            msgs.push(
                                '{2} -- Unequal (null and not null) or (two unequal non-objects): {0}-{1} '.formatUnicorn(
                                    first,
                                    second,
                                    path
                                )
                            );
                        }
                        return msgs;
                    }

                    // Check properties
                    for (var prop in first) {
                        if (propsToIgnore.indexOf(prop) === -1) {
                            if (first.hasOwnProperty(prop) && first[prop] !== undefined) {
                                if (second.hasOwnProperty(prop) && second[prop] !== undefined) {
                                    msgs = msgs.concat(
                                        compareFirstToSecond(
                                            first[prop],
                                            second[prop],
                                            propsToIgnore,
                                            path + prop + '/'
                                        )
                                    );
                                } else {
                                    msgs.push(path + prop + ' -- second object does not have property ' + prop);
                                }
                            }
                        }
                    }

                    return msgs;
                };

                // now verify that t doesn't have any properties
                // that are missing from s.
                // To recurse this properly, let's set up another function.
                function compareSecondToFirst(second, first, propsToIgnore, path) {
                    var msgs = [];
                    propsToIgnore = propsToIgnore || [];

                    for (var prop in second) {
                        if (propsToIgnore.indexOf(prop) === -1) {
                            if (second.hasOwnProperty(prop) && second[prop] !== undefined) {
                                if (!first.hasOwnProperty(prop) || first[prop] === undefined) {
                                    msgs.push(path + prop + ' -- first object does not have property ' + prop);
                                } else if (
                                    typeof first[prop] === 'object' &&
                                    typeof second[prop] === 'object'
                                ) {
                                    // NOTE: To make this proceed down the object tree, even though we've already
                                    // checked equality for each match, we need to keep recursively calling
                                    // a check to see if the second object's object model has a prop the first's
                                    // model does not.
                                    //
                                    // That is, we don't know what "props of props" are missing all the way
                                    // down the object model without this call. But we're recursively calling this
                                    // inner function so we don't do any extra comparision work.
                                    msgs = msgs.concat(
                                        compareSecondToFirst(
                                            second[prop],
                                            first[prop],
                                            propsToIgnore,
                                            path + prop + '/'
                                        )
                                    );
                                } // else they aren't objects and we already know the props values match, as they've already been checked.
                            }
                        }
                    }

                    return msgs;
                };

                return compareFirstToSecond(s, t, propsToIgnoreWhenCompare, '/').concat(
                    compareSecondToFirst(t, s, propsToIgnoreWhenCompare, '/')
                );
            },

            // BJS 20210607
            toBoolean: function (value, defaultValue) {
                // BJS 20230315 - Modified to include more variants and make comparisons more precise.
                if (angular.isUndefined(value))
                    return defaultValue;
                if (value === null)
                    return defaultValue;
                if (value === true)
                    return true;
                if (value === false)
                    return false;

                if (angular.isNumber(value)) {
                    return value === 1 ? true : false;
                }

                if (angular.isString(value)) {
                    switch (value.toLowerCase()) {
                        case 'true':
                        case '1':
                        case 'yes':
                            return true;
                        case 'false':
                        case '0':
                        case 'no':
                            return false;
                    }
                }

                return defaultValue;
            },

            // BJS 20190203
            setDefaultValues: function (obj, def) {
                if (angular.isObject(def) !== true) return def;

                angular.forEach(def, function (value, key) {
                    if (angular.isObject(obj[key])) {
                        obj[key] = service.setDefaultValues(obj[key], value);
                    } else {
                        if (angular.isUndefined(obj[key])) {
                            obj[key] = value;
                        }
                    }
                });

                return obj;
            },

            escapeRegExp: function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
            },

            replaceAll: function (str, find, replace) {
                return str.replace(new RegExp(service.escapeRegExp(find), 'g'), replace);
            },

            isNumber: function (n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },

            returnObjectValueOr: function (obj, orValue) {
                var out = orValue;
                if (angular.isDefined(obj)) {
                    out = obj;
                }
                return out;
            },

            groupButtonCurrentId: function (groupButton) {
                var s = groupButton.item_selected;
                var out = 0;
                if (angular.isDefined(groupButton.records)) {
                    if (groupButton.records.length > 0) {
                        angular.forEach(groupButton.records, function (item) {
                            if (item.item_func == s) {
                                out = item.item_id;
                                return true;
                            }
                        });
                    }
                }
                return out;
            },

            groupButtonIdOf: function (groupButton, functionName) {
                var out = 0;

                if (angular.isArray(groupButton)) {
                    angular.forEach(groupButton, function (item) {
                        if (item.id === functionName) {
                            out = item.id;

                            return true;
                        }
                    });
                } else {
                    if (angular.isDefined(groupButton.records)) {
                        if (groupButton.records.length > 0) {
                            angular.forEach(groupButton.records, function (item) {
                                if (item.item_func == functionName) {
                                    out = item.item_id;
                                    return true;
                                }
                            });
                        }
                    }
                }

                return out;
            },

            objectLength: function (obj) {
                var out = 0;

                try {
                    if (angular.isArray(obj)) {
                        if (obj) {
                            out = obj.length;
                        }

                    } else {
                        if (obj) {
                            out = Object.keys(obj).length;
                        }

                    }
                } catch (e) { }

                return out;
            },

            emailString:  function (str) {
                var out = str.replace('@', '_');

                out = out.replace('.', '_');

                if (out.indexOf('<') > 0) {
                    out = out.substring(out.indexOf('<'));

                    if (out.indexOf('>') > 0) {
                        out = out.substring(1, out.indexOf('>'));
                    }
                }

                return out;
            },

            toCamelCase: function (str) {
                var out = str;
                if (str.length > 0) {
                    out = out.charAt(0).toUpperCase() + out.slice(1).toLowerCase();
                }
                return out;
            },

            sleep: function (ms) {
                var unixtime_ms = new Date().getTime();
                while (new Date().getTime() < unixtime_ms + ms) { /**/ }
            },

            parseNumber: function (str) {
                if (typeof str === 'string') {
                    str = str.replace(',', '.');
                    str = str.replace(' ', '');

                    if (str === '') {
                        str = '0';
                    }
                }

                var out = parseFloat(str);

                if (isNaN(out)) {
                    out = 0;
                }

                if (!isFinite(out)) {
                    out = 0;
                }

                return out;
            },

            /**
             * Looks for the given variable in the given scope and updates its value if the variable is defined.
             * NB: This function only updates predefined variables, it does not define new ones.
             * @param {object}            scope the scope in which to search for the variable (eg. vm). 
             * @param {string | string[]} parms full name of the variable (eg. vm.model.variable_name), or array including the full name of the variable (eg. [vm, model, variable_name])
             * @param {any}               value the new value of the variable.
             */
            setValueToVariable: function (scope, parms, value) {
                parms = angular.isArray(parms) ? parms[0] !== 'vm' ? parms : parms.splice(1, parms.length - 1) : parms.indexOf('vm.') > -1 ? parms.substring(3).split('.') : parms.split('.');
                if (angular.isDefined(scope[parms[0]])) {
                    if (parms.length < 2) {
                        scope[parms[0]] = value;
                    } else {
                        service.setValueToVariable(scope[parms.splice(0, 1)], parms, value);
                    }
                }
            },

            validateParmsValue: function (parmWithValue, allowEmptyString) {
                if (angular.isUndefined(parmWithValue) === true) return false;
                if (parmWithValue === null) return false;
                if (angular.isUndefined(allowEmptyString) || allowEmptyString !== true) {
                    if (!angular.isFunction(parmWithValue.trim) || parmWithValue.trim().length < 1) return false;
                }

                return true;
            },

            validateParmsWithValue: function (parmWithValue) {
                if (angular.isUndefined(parmWithValue) === true) return false;
                if (parmWithValue === null) return false;
                if (!angular.isFunction(parmWithValue.trim) || parmWithValue.trim().length < 1) return false;

                return true;
            },

            validateParmsEmptyValue: function (parmEmptyValue) {
                if (angular.isUndefined(parmEmptyValue) === true) return false;
                if (parmEmptyValue === null) return false;

                return true;
            },

            validateWatchValue: function (newVal, oldVal, limitDateTimeLength, limitDateLength) {
                if (angular.isUndefined(newVal) === true || angular.isUndefined(oldVal) === true) return false;
                if (newVal === oldVal) return false;
                if (angular.isDefined(limitDateTimeLength) && limitDateTimeLength === true) {
                    if (newVal.length > 16 || oldVal.length > 16) return false;
                }
                if (angular.isDefined(limitDateLength) && limitDateLength === true) {
                    if (newVal.length < 14 || oldVal.length < 14) return false;
                }

                return true;
            },

            validateNewOldValue: function (newVal, oldVal) {
                if (angular.isUndefined(newVal) === true || angular.isUndefined(oldVal) === true) return false;
                if (newVal === oldVal) return false;

                return true;
            },

            validateString: function (value, defaultValue, checkLength) {
                if (angular.isUndefined(value))
                    return defaultValue;
                if (value === null)
                    return defaultValue;
                if (angular.isString(value) !== true)
                    return value.toString();

                if (checkLength !== true)
                    return value;

                if (value.length < 1)
                    return defaultValue;

                return value;
            },

            isStringValue: function (value, checkLength) {
                if (angular.isUndefined(value))
                    return false;
                if (angular.isString(value) !== true)
                    return false;
                if (value === null)
                    return false;

                if (service.toBoolean(checkLength, false) && value.length < 1)
                    return false;

                return true;
            },

            showMessage: function (type, title, message, label, cssClass) {
                var deferred = $q.defer();

                modalService.show({
                    type: type || 'default',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label,
                        cssClass: cssClass,
                        action: function (dialogItself) {
                            dialogItself.close();

                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            },
            findObjectInList: function (list, key, value) {
                let index = list.findIndex(obj => obj?.[key] === value);

                return { index: index, data: list[index] };
            },
            round: function (num, decimalPlaces) {
                var p = Math.pow(10, decimalPlaces || 0);
                var n = (num * p) * (1 + Number.EPSILON);
                return Math.round(n) / p;
            },
            // Decimal ceil
            ceil: function (num, decimalPlaces) {
                var p = Math.pow(10, decimalPlaces || 0);
                var n = (num * p) * (1 - Math.sign(num) * Number.EPSILON);
                return Math.ceil(n) / p;
            },
            // Decimal floor
            floor: function (num, decimalPlaces) {
                var p = Math.pow(10, decimalPlaces || 0);
                var n = (num * p) * (1 + Math.sign(num) * Number.EPSILON);
                return Math.floor(n) / p;
            },
            // Decimal trunc
            trunc: function (num, decimalPlaces) {
                return (num < 0 ? this.ceil : this.floor)(num, decimalPlaces);
            },
            // Format using fixed-point notation
            toFixed: function (num, decimalPlaces) {
                return this.round(num, decimalPlaces).toFixed(decimalPlaces);
            },

            isObjectEmpty: function (obj) {
                return angular.isObject(obj) && Object.keys(obj).length === 0 && obj.constructor === Object;
            }
        };

        return service;
    }]);
})();
