import { Component, Input } from '@angular/core';
import { Style } from '@app/core/services/core-component.service';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
    selector: 'tt-overflow-menu',
    templateUrl: './overflow-menu.component.html',
    styleUrls: ['./overflow-menu.component.css'],
})
export class OverflowMenuComponent extends ComponentBaseComponent {
    /**
     * Icon to display for the overflow menu, default is `'fa-ellipsis-v'`.
     */
    @Input()
    ttIcon?: string;

    @Input()
    ttType?: 'success' | 'primary' | 'secondary' | 'danger' | 'warning' | 'link' | null = null;

    /**
     * Whether the overflow menu is open or not.
     */
    open = false;

    /**
     * Id of elements in the overflow menu.
     */
    id = {
        button: crypto.randomUUID(),
    };

    /**
     * Style of elements in the overflow menu.
     */
    style: Style = {
        button: {},
        icon: {},
    };

    constructor(private layout: LayoutService) {
        super();
        layout.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['button'].height = info.height + 'px';
            }
        });
    }

    /**
     * Toggled the open state of the overflow menu.
     */
    toggleMenu() {
        this.open = !this.open;
    }
}
