(function () {
    'use strict';

    angular.module("imApp").factory("operationEquipmentsService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var atou = function (data) { // Utility function for base64decode with unicode support
            return decodeURIComponent(escape(atob(data)));
        }

        var encoder = function (data, format, encodeAll, escape) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = angular.isDefined(escape) && escape === true ? utoa(data) : btoa(data);
            } else {
                if (format === 'char' || format === 'varchar' || format === 'long varchar' || format === 'string') {
                    returnData = angular.isDefined(escape) && escape === true ? utoa(data) : btoa(data);
                } else {
                    returnData = data;
                }
            }
            return returnData;
        };

        var service = {
            getOperationEquipments: function (argType, argValue) {
                return $ihttp.post({
                    method: 2041,
                    parameters: {
                        argtype: argType,
                        argvalue: argValue
                    }
                });
            },
            saveOperationEquipment: function (dataItem, format) {
                var postData = angular.copy(dataItem);
                
                angular.forEach(postData, function (data, key) {
                    postData[key] = encoder(data, format.getColumnFormatType(key), false, true);
                });
                
                return call(2043, postData);
            }
        };

        return service;
    }]);
})();