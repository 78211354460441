(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('choicePossibilityList', {
        templateUrl: 'views/components/views/choicePossibilityList/choicePossibilityList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'stateService', 'translateService', 'modalService', 'choicePossibilityListService', function ($stateParams, $q, utilityService, stateService, translateService, modalService, choicePossibilityListService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let choiceKeyno = $stateParams.choice_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                productNameChoiceButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.setting.prod_keyno_choice }) }
                ],
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                bgChoicePossibilityListType: {
                    selected: 'POSSIBLE',
                    buttons: [
                        { id: 'POSSIBLE', label: 'gb_choicepossibilitylist_type_possible', color: 'primary', onClick: () => setChoicePossibilityListTypeView('POSSIBLE') },
                        { id: 'NOTPOSSIBLE', label: 'gb_choicepossibilitylist_type_notpossible', color: 'primary', onClick: () => setChoicePossibilityListTypeView('NOTPOSSIBLE') },
                        { id: 'ALL', label: 'gb_choicepossibilitylist_type_all', color: 'primary', onClick: () => setChoicePossibilityListTypeView('ALL') }
                    ]
                },
                setting: {},
                itemsListChoicePossibilities: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setChoicePossibilityListTypeView = function (id) {
                vm.model.bgChoicePossibilityListType.selected = vm.model.setting.gb_choicepossibilitylist_type = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                const deferred = $q.defer();

                choicePossibilityListService.getSetting({ choice_keyno: choiceKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    setChoicePossibilityListTypeView(vm.model.setting.gb_choicepossibilitylist_type);

                    loadChoicePossibilities();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadChoicePossibilities = function () {
                if (utilityService.validateParmsValue(vm.model.setting.choice_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_choicepossibilitylist_type) !== true) return;
                
                vm.model.itemsListChoicePossibilities = [];
                
                return choicePossibilityListService.listChoicePossibilities(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListChoicePossibilities);
                });
            };
            
            // ## BUTTON GROUP FUNCTIONS ##
            
            vm.selectBgChoicePossibilityListType = function (value) {
                vm.model.bgChoicePossibilityListType.selected = value;
                vm.model.setting.gb_choicepossibilitylist_type = vm.model.bgChoicePossibilityListType.selected;

                if (vm.model.setting.gb_choicepossibilitylist_type) loadChoicePossibilities();
            };

            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                let selectedItems = [];

                angular.forEach(vm.model.itemsListChoicePossibilities, function (item) {
                    selectedItems.push({
                        item_id: item.item_id,
                        item_is_selected: item.item_is_selected
                    });
                });

                choicePossibilityListService.saveObj({
                    choice_keyno: vm.model.setting.choice_keyno,
                    records: selectedItems
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
