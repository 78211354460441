(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListItemItem', {
        templateUrl: 'views/components/views/checkListItemItem/checkListItemItem.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'checkListItemItemService', function ($stateParams, stateService, checkListItemItemService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let checkListItemItemKeyno = $stateParams.checklistitemitem_keyno;
            let checkListItemKeyno = $stateParams.checklistitem_keyno;
            
            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                checkListItemItemService.getEdit({
                    checklistitemitem_keyno: checkListItemItemKeyno,
                    checklistitem_keyno: checkListItemKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                checkListItemItemService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
