(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('subscriptionUsergroupEdit', {
        templateUrl: 'views/components/views/subscriptionUsergroupEdit/subscriptionUsergroupEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'subscriptionUsergroupEditService', function ($stateParams, stateService, subscriptionUsergroupEditService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                subscriptionUsergroupEditService.getEdit({ p2_subscription_usergroup_keyno: $stateParams.p2_subscription_usergroup_keyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                subscriptionUsergroupEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
