(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttCheckbox', {
        templateUrl: 'views/components/directives/ttCheckbox/ttCheckbox.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttReadonly: '<',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttTrue: '@',
            ttFalse: '@',
            ttItemId: '@',
            ttRequired: '@',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttStyle: '<'            // ODL 20230322
        },
        controller: ['$element', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.trueValue = '1';
            vm.falseValue = '0';
            vm.readonly = false;
            vm.required = false;

            vm.id = {
                checkbox: uuid()
            };

            vm.style = {
                base: {},
                label: {},
                checkbox: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                input: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'checkbox' }), vm.style);

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            // ODL 20230322
            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            };

            vm.$onChanges = function (changes) {
                // ODL 20230322
                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttTrue) && angular.isDefined(changes.ttTrue.currentValue)) {
                    vm.trueValue = us.validateString(changes.ttTrue.currentValue, '1', true);
                }

                if (angular.isDefined(changes.ttTrue) && angular.isDefined(changes.ttTrue.currentValue)) {
                    vm.falseValue = us.validateString(changes.ttFalse.currentValue, '0', true);
                }

                if (angular.isDefined(changes.ttModel) && angular.isDefined(changes.ttModel.currentValue)) {
                    var val = changes.ttModel.currentValue;

                    if (val === 'true' && vm.trueValue === true) {
                        val = true;
                    }

                    if (val === 'false' && vm.trueValue === false) {
                        val = false;
                    }

                    if (val === true && vm.trueValue === 'true') {
                        val = 'true';
                    }

                    if (val === false && vm.trueValue === 'false') {
                        val = 'false';
                    }

                    vm.cbValue = val === vm.trueValue;
                }

                if (angular.isDefined(changes.ttReadonly) && angular.isDefined(changes.ttReadonly.currentValue)) {
                    vm.readonly = us.toBoolean(changes.ttReadonly.currentValue);
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue, true)) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }
            };

            vm.onModelChanged = function (value) {
                vm.ttModel = value === true
                    ? vm.trueValue
                    : vm.falseValue;

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: vm.ttModel, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
