(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListExpensesService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListExpensesGet: function (checklistdata_keyno) {
                return p2DataTaskService.call(1805, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrCheckListExpensesList: function (checklistdata_keyno) {
                return p2DataTaskService.call(1808, {
                    checklistdata_keyno: checklistdata_keyno
                });
            }
        };

        return service;
    }]);
})();