(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('gridExports', {
        templateUrl: 'views/components/views/gridExports/gridExports.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'gridExportsService', function ($stateParams, gridExportsService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                itemsListExports: []
            };
            
            gridExportsService.loadGridExportsList(vm.model.argType, vm.model.argValue).then(function (data) {
            	angular.copy(data, vm.model.itemsListExports);
            });
        }]
    });
})();