(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("fabricsGrpsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadFabricsGrpsSettings: function () {
                return p2DataTaskService.call(1243, {});
            },
            loadFabricsGrpList: function () {
                return p2DataTaskService.call(1240, {});
            }
        };

        return service;
    }]);
})();