(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sopValid', {
        templateUrl: 'views/components/views/sopValid/sopValid.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'sopMenuService', 'sopValidService', function($stateParams, stateService, utilityService, sopMenuService, sopValidService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                sopKeyNo: $stateParams.sop_keyno,
                settingValid: {},
                itemsListValids: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            sopMenuService.loadSopMenuGet(vm.model.sopKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.settingValid);
    
                loadSopValids();
            });
    
            var loadSopValids = function () {
                if (utilityService.validateParmsValue(vm.model.settingValid.sop_keyno) !== true) return;
    
                vm.model.itemsListValids = [];
    
                sopValidService.loadSopValidList(vm.model.settingValid.sop_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListValids);
                });
            };
    
            // ## TOGGLE FUNCTIONS ##
    
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
    
                if (item.item_id === '0' || item.item_id === '-1') return;
    
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSopValid = function () {
                vm.model.lockedSave = true;
                
                var mergeObj = {
                    records: vm.model.itemsListValids.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; })
                };
    
                sopValidService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();