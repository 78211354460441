(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('subtasks', {
        templateUrl: 'views/components/views/subtasks/subtasks.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'subtasksService', 'rememberService', function ($stateParams, stateService, subtasksService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;
            let retrieveType = $stateParams.retrievetype;
            let includeTop = $stateParams.include_top;
            
            vm.model = {
                custactNo: custactNo,
                setting: {},
                itemsListSubTasks: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadSetting = function () {
                subtasksService.getSetting({ custact_no: custactNo }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadSubTasks = function () {
                subtasksService.listSubTasks({
                    custact_no: custactNo,
                    retrievetype: retrieveType,
                    include_top: includeTop
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListSubTasks);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'custactedit':
                        go({
                            custact_no: '0',
                            filterfield: 'custact_no',
                            filtervalue: custactNo
                        });
                        break;
                    case 'custactSearchSubTask':
                        rememberService.remember('custact_no_linkto', custactNo).then(function () {
                            state = 'custactsearch';

                            go({
                                filterfield: '-',
                                filtervalue: '-',
                                custacttype: 'A'
                            });
                        });
                        break;
                    case 'custactSearchDeviation':
                        rememberService.remember('custact_no_linkto', custactNo).then(function () {
                            state = 'custactsearch';

                            go({
                                filterfield: '-',
                                filtervalue: '-',
                                custacttype: 'D'
                            });
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadSubTasks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
