(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srRefundInputCrewService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrRefundInputCrewGet: function (checklistdata_keyno, srrefundinput_keyno) {
                return p2DataTaskService.call(1785, {
                    checklistdata_keyno: checklistdata_keyno,
                    srrefundinput_keyno: srrefundinput_keyno
                });
            },
            loadSrRefundInputsList: function (srrefundinput_keyno) {
                return p2DataTaskService.call(1789, {
                    srrefundinput_keyno: srrefundinput_keyno
                });
            },
            calc: function (refundCrew) {
                return p2DataTaskService.call(1786, refundCrew);
            },
            save: function (refundCrew) {
                return p2DataTaskService.call(1787, refundCrew);
            },
            deleteObj: function (refundCrew) {
                return p2DataTaskService.call(1788, refundCrew);
            }
        };

        return service;
    }]);
})();