(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("productBomService", ['$ihttp', 'p2DataTaskService', '$q', function ($ihttp, p2DataTaskService, $q) {
        var service = {
            loadProductBomGet: function (prod_id) {
                return p2DataTaskService.call(1129, {
                    prod_id: prod_id
                });
            },
            loadProductBomList: function (prod_id) {
                return p2DataTaskService.call(1130, {
                    prod_id: prod_id
                });
            },
            loadIncludedInList: function (prod_id) {
                return p2DataTaskService.call(1137, {
                    prod_id: prod_id
                });
            },
            listOwns: function (parms) {
                return $ihttp.post({
                    method: 2930,
                    parameters: parms || {}
                });
            },
            loadProdIdSelectedGet: function (prod_id_selected) {
                return p2DataTaskService.call(1135, {
                    prod_id_selected: prod_id_selected
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            checkProduct: function (mergeObj) {
                return $ihttp.post({
                    method: 2314,
                    parameters: mergeObj
                });
            },
            bomVerificationCheck: function (parms) {
                return $ihttp.post({
                    method: 3180,
                    parameters: parms
                });
            },
            loadP2DocumentsList: function (argtype, argvalue, searchtext, doctype, onlyFilenames, date_fom, date_tom, dateselector_index, show_dateselector) {

                var parameters = angular.isObject(argtype) === true ? argtype : {
                    argtype: argtype,
                    argvalue: argvalue,
                    searchtext: searchtext,
                    doctype: doctype,
                    onlyFilenames: onlyFilenames || '0',
                    date_fom: date_fom,
                    date_tom: date_tom,
                    dateselector_index: dateselector_index,
                    show_dateselector: show_dateselector
                };


                return $ihttp.post({
                    method: 309,
                    parameters: parameters
                });

            },
            listAssemblyGroups: function (parms) {
                return $ihttp.post({
                    method: 3238,
                    parameters: parms || {}
                });
            },
            saveAssemblyGroup: function (prod_id, assemblygroup_id) {
                console.log(prod_id, assemblygroup_id)
                return p2DataTaskService.call(3239, {
                    prod_id: prod_id,
                    assemblygroup_no: assemblygroup_id
                });
            }

        };

        return service;
    }]);
})();
