(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlan', {
        templateUrl: 'views/components/views/consignmentPlan/consignmentPlan.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'consignmentPlanService', function ($stateParams, stateService, modalService, consignmentPlanService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            vm.model = {
                custactNo: $stateParams.custact_no,
                equipmentIdBtnList: [
                    { item_id: '0', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' }
                ],
                equipmentIdBtnList2: [
                    { item_id: '0', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' },
                    { item_id: '1', item_name: 'goto', item_func: 'goto_item', glyph: 'glyphicon-chevron-right', color: 'primary' }
                ],
                equipmentNameButtonsUnselected: [
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: () => gotoEquipments() },
                ],
                equipmentNameButtonsSelected: [
                    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: () => gotoEquipments() },
                    //{ id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onSearch: vm.typeaheadSearch },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => gotoEquipment() }
                ],
                mconfig: {
                    enabled: true,		// Is the menu enabled
                    show: true,			// Is the menu visible or not
                    activate: function (item) {
                        if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else {
                            vm.model.mconfig.records[0].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else {
                            vm.model.mconfig.records[0].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                plan: {},
                selectListEquipments: [],
                selectListActStatuses: [],
                selectListStockPlaces: [],
                selectListTransportAreas: [],
                itemsListConsignmentOns: [],
                commonDTVar: { minuteInterval: '15' },
                searchingEquipments: false,
                searchingEquipments2: false,
                lockedSave: false
            };

            // ## DRAG AND DROP SORT FUNCTION PROCEDURE CALLS ##

            vm.consignmentPlansReorder = function (item) {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }

                var updateParms = {
                    custact_no: vm.model.custactNo,
                    records: vm.model.itemsListConsignmentOns.map(function (a) { return { "consignment_keyno": a.consignment_keyno }; })
                };

                consignmentPlanService.updateOrder(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    loadConsignmentPlanOns();
                                }
                            }]
                        });
                    } else {
                        console.dir(data);
                        loadConsignmentPlanOns();

                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }

                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
                });
            };

            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##

            vm.typeaheadSearchEquipments = function () {
                return consignmentPlanService.searchEquipments(vm.model.plan.equipment_name);
            };

            var changedEquipmentId = function (equipment_id) {
                vm.model.plan.equipment_id = equipment_id;
            };

            // ## BUTTON LIST FUNCTIONS ##

            vm.equipmentIdBtnListFunc = function (item_func) {
                if (item_func === 'list_item') {
                    stateService.go('equipments', {});
                }
            };

            vm.equipmentIdBtnListFunc2 = function (item_func) {
                if (item_func === 'list_item') {
                    stateService.go('equipments', {});
                } else if (item_func === 'goto_item') {
                    if (angular.isUndefined(vm.model.plan.equipment_id) === true) return;
                    if (vm.model.plan.equipment_id === null) return;
                    if (vm.model.plan.equipment_id.trim().length < 1) return;

                    stateService.go('equipmentedit', {
                        equipment_id: vm.model.plan.equipment_id
                    });
                }
            };

            vm.clearEquipment = function () {
                vm.model.plan.equipment_id = '';
                vm.model.plan.equipment_name = '';
            }

            function gotoEquipments() {
                stateService.go('equipments', {});
            }

            function gotoEquipment() {
                stateService.go('equipmentedit', {
                    equipment_id: vm.model.plan.equipment_id
                });
            }

            // ## SAVE BUTTON FUNCTION ##

            vm.saveConsignmentPlan = function () {
                vm.model.lockedSave = true;

                consignmentPlanService.save(vm.model.plan).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'activitytype':
                        go({ activitytype_no: vm.model.plan.activitytype_no });
                        break;
                    case 'consignmentplanbuild':
                        go({ custact_no: vm.model.plan.custact_no });
                        break;
                }
            };

            // ## WATCH FUNCTIONS ##

            vm.onEquipmentNameChanged = function (value) {
                vm.model.plan.equipment_name = value;

                if (value?.trim() === '') {
                    vm.clearEquipment();
                } else {
                    let equipment = consignmentPlanService.selectEquipmentList.find((equip) => equip.item_name === value);

                    if (equipment?.item_id) changedEquipmentId(equipment.item_id);
                }
            }

            vm.onEquipmentSelected = function (item) {
                if (item) {
                    vm.model.plan.equipment_name = item.item_name;
                    vm.model.plan.equipment_id = item.item_id;
                }
            }

            vm.onStockPlaceChanged = function (value) {
                vm.model.plan.stockplace_id = value;
                loadTransportAreas();
            }


            async function loadConsignmentPlanGet() {
                return consignmentPlanService.loadConsignmentPlanGet(vm.model.custactNo).then(function (data) {
                    vm.model.plan = angular.copy(data[0]);

                    loadActStatuses();
                    loadTransportAreas();
                    loadConsignmentPlanOns();
                });
            }

            async function loadEquipmentList() {
                return consignmentPlanService.loadEquipmentList().then(function (data) {
                    angular.copy(data, vm.model.selectListEquipments);
                });
            }

            async function loadStockplaces() {
                return consignmentPlanService.loadStockPlaceList().then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });

            }
            var loadActStatuses = function () {
                if (angular.isUndefined(vm.model.plan.activitytype_no) === true) return;
                if (vm.model.plan.activitytype_no === null) return;
                if (vm.model.plan.activitytype_no.trim().length < 1) return;

                vm.model.selectListActStatuses = [];

                consignmentPlanService.loadActStatusList(vm.model.plan.activitytype_no).then(function (data) {
                    angular.copy(data, vm.model.selectListActStatuses);
                });
            };

            var loadTransportAreas = function () {
                if (angular.isUndefined(vm.model.plan.stockplace_id) === true) return;
                if (vm.model.plan.stockplace_id === null) return;
                if (vm.model.plan.stockplace_id.trim().length < 1) return;

                vm.model.selectListTransportAreas = [];

                consignmentPlanService.loadTransportAreaList('0', vm.model.plan.stockplace_id).then(function (data) {
                    angular.copy(data, vm.model.selectListTransportAreas);
                });
            };

            var loadConsignmentPlanOns = function () {
                consignmentPlanService.loadConsignmentPlanOnList(vm.model.custactNo).then(function (data) {
                    angular.copy(data, vm.model.itemsListConsignmentOns);
                });
            };


            vm.$onInit = async function () {
                await Promise.all([loadEquipmentList(), loadStockplaces()]);
                loadConsignmentPlanGet();
            }
        }]
    });
})();
