(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productCreate', {
        templateUrl: 'views/components/views/productCreate/productCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'translateService', 'productCreateService', 'productService', 'economyService', 'logisticService', 'discGrpProdService', 'productSettingsService', 'typeaheadService', 'rememberService', function ($stateParams, $q, $timeout, stateService, utilityService, modalService, translateService, productCreateService, productService, economyService, logisticService, discGrpProdService, productSettingsService, typeaheadService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let translations = {
                error: '',
                ok: ''
            };

            let variableNames = {
                copy_components: '',
                pricelist_id: ''
            };

            vm.model = {
                bgDetails: {
                    selected: 'SIMPLE',
                    buttons: [
                        { id: 'SIMPLE', label: 'productcreate_gb_details_simple', color: 'primary', onClick: () => vm.model.bgDetails.selected = 'SIMPLE' },
                        { id: 'ALL', label: 'productcreate_gb_details_all', color: 'primary', onClick: () => vm.model.bgDetails.selected = 'ALL' }
                    ]
                },
                productSettingButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('productsettings') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('productsetting', { productsetting_keyno: vm.model.edit.productsetting_keyno }) }
                ],
                productButtons: [
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => retrieveProdId() }
                ],
                quantityFactorButtons: [
                    { id: 'exchange', icon: 'fas fa-exchange', color: 'primary', type: 'primary' } //onClick: (index) => console.dir(index) } // onClick: () => { return; } }
                ],
                productGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrps') }
                ],
                productGroupButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrps') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrpmenu', { prodgrp_id: vm.model.edit.prodgrp_id }) }
                ],
                supplierButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.supplier_no }) }
                ],
                discGroupProductButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('discgrpprod', { discgrpprod_keyno: vm.model.edit.discgrpprod_keyno }) }
                ],
                edit: {},
                selectListUnits: [],
                selectListProductSettings: [],
                selectListProductGroups: [],
                selectListCompanies: [],
                selectListCompletionMethods: [],
                selectListCompletionPrinciples: [],
                selectListPlanPolicies: [],
                selectListProductTypes: [],
                selectListProductGroup2s: [],
                selectListProductGroup3s: [],
                selectListProductGroup4s: [],
                selectListPriceMethods: [],
                selectListDiscGroupProducts: [],
                selectListProductionPlaces: [],
                selectListProductionOrderMethods: [],
                selectListPrices: [],
                inputListUnits: [],
                inputListMcs: [],
                readyFieldValue: true,
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region EXCHANGE BUTTON FUNCTION

            vm.exchangeValue = function (item) {
                if (item.inversevalue === '1') {
                    item.inversevalue = '0';
                } else {
                    item.inversevalue = '1';
                }
                
                item.qtyfactor = (1 / utilityService.parseNumber(item.qtyfactor));

                loadSalesPrice();
            };

            // #endregion EXCHANGE BUTTON FUNCTION

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                const deferred = $q.defer();

                productCreateService.getEdit({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    retrieveProductSetting();
                    loadProductSettingMcs();

                    $timeout(function () {
                        loadInputUnits();
                    }, 500);

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadPriceMethods = function () {
                economyService.listPriceMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListPriceMethods);
                });
            };
            
            let loadUnits = function () {
                logisticService.listUnits({ isactive: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListUnits);
                });
            };

            let loadProductSettings = function () {
                productSettingsService.listProductSettings().then(function (list) {
                    angular.copy(list, vm.model.selectListProductSettings);
                });
            };

            let loadDiscGroupProducts = function () {
                productService.listDiscGroupProducts().then(function (list) {
                    angular.copy(list, vm.model.selectListDiscGroupProducts);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadCompletionMethods = function () {
                logisticService.listCompletionMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListCompletionMethods);
                });
            };

            let loadCompletionPrinciples = function () {
                logisticService.listCompletionPrinciples().then(function (list) {
                    angular.copy(list, vm.model.selectListCompletionPrinciples);
                });
            };

            let loadPlanPolicies = function () {
                logisticService.listPlanPolicies().then(function (list) {
                    angular.copy(list, vm.model.selectListPlanPolicies);
                });
            };

            let loadProductTypes = function () {
                productCreateService.listProductTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListProductTypes);
                });
            };

            let loadProductionPlaces = function () {
                logisticService.listProductionPlaces().then(function (list) {
                    angular.copy(list, vm.model.selectListProductionPlaces);
                });
            };

            let loadProductionOrderMethods = function () {
                logisticService.listProductionOrderMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListProductionOrderMethods);
                });
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (list) {
                    angular.copy(list, vm.model.selectListPrices);
                });
            };

            let loadProductGroups = function () {
                logisticService.listProductGroups().then(function (list) {
                    angular.copy(list, vm.model.selectListProductGroups);
                });
            };

            let loadProductGroup2s = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodgrp_id, true) !== true) return;
                
                return productService.listProductGroup2s({ prodgrp_id: vm.model.edit.prodgrp_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListProductGroup2s);
                });
            };

            let loadProductGroup3s = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodgrp2_id, true) !== true) return;

                return productCreateService.listProductGroup3s({ prodgrp2_id: vm.model.edit.prodgrp2_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListProductGroup3s);
                });
            };

            let loadProductGroup4s = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodgrp3_id, true) !== true) return;

                return productCreateService.listProductGroup4s({ prodgrp3_id: vm.model.edit.prodgrp3_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListProductGroup4s);
                });
            };

            let loadProductSettingMcs = function () {
                if (utilityService.validateParmsValue(vm.model.edit.productsetting_keyno) !== true) return;

                return productCreateService.listProductSettingMcs({ productsetting_keyno: vm.model.edit.productsetting_keyno }).then(function (list) {
                    angular.copy(list, vm.model.inputListMcs);

                    if (vm.model.edit.prod_name_get === '') buildProductName();
                });
            };

            let loadInputUnits = function () {
                if (vm.model.edit.show_unitconversions === '1') {
                    return productCreateService.listUnits(vm.model.edit).then(function (list) {
                        angular.copy(list, vm.model.inputListUnits);
                    });
                }
            };

            let loadEdit2 = function () {
                if (utilityService.validateParmsValue(vm.model.edit.discgrpprod_keyno, true) !== true) return;

                return discGrpProdService.getEdit({ discgrpprod_keyno: vm.model.edit.discgrpprod_keyno }).then(function (response) {
                    vm.model.edit.pricemethod_id = response[0].pricemethod_id;
                    vm.model.edit.calc_factor = response[0].calc_factor;
                    vm.model.edit.calc_margin_pst = response[0].calc_margin_pst;
                });
            };

            let timerSalesPrice = null;

            let loadSalesPrice = function () {
                if (angular.isDefined(timerSalesPrice)) {
                    $timeout.cancel(timerSalesPrice);
                }

                var mergeObj = vm.model.edit;
                mergeObj.units = vm.model.inputListUnits;

                timerSalesPrice = $timeout(function () {
                    return productCreateService.calcSalesPrice(mergeObj).then(function (response) {
                        vm.model.edit.pricelist_price = response[0].pricelist_price;
                        vm.model.edit.db_pst = response[0].db_pst;
                        vm.model.edit.grouptext_purchase = response[0].grouptext_purchase;
                        vm.model.edit.grouptext_sales_price = response[0].grouptext_sales_price;
                        vm.model.edit.pricelist_price_locval = response[0].pricelist_price_locval;
                        vm.model.edit.cost_locval = response[0].cost_locval;
                        vm.model.edit.db_amount_locval = response[0].db_amount_locval;
                        vm.model.edit.basecurrency_id = response[0].basecurrency_id;
                        vm.model.edit.pricelist_valuta_id = response[0].pricelist_valuta_id;
                    });
                }, 500);
            };

            let loadExistingSetting = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                return productCreateService.getExistingSetting({ prod_id: vm.model.edit.prod_id }).then(function (response) {
                    vm.model.edit.existing_prod_ids = response[0].existing_prod_ids;
                });
            };

            let retrieveProductSetting = function () {
                if (utilityService.validateParmsValue(vm.model.edit.productsetting_keyno) !== true) return;

                return productCreateService.getProductSetting({ productsetting_keyno: vm.model.edit.productsetting_keyno }).then(function (response) {
                    vm.model.edit.prodtype_no = response[0].prodtype_no;
                    vm.model.edit.stock_unit_no = response[0].stock_unit_no;
                    vm.model.edit.prodbeomethod_no = response[0].prodbeomethod_no;

                    if (vm.model.edit.prodgrp_id === '') {
                        vm.model.edit.prodgrp_id = response[0].prodgrp_id;
                        vm.model.edit.prodgrp2_id = response[0].prodgrp2_id;
                    }

                    vm.model.edit.pohcompletionmethod_keyno = response[0].pohcompletionmethod_keyno;
                    vm.model.edit.pohcompletionprinciple_keyno = response[0].pohcompletionprinciple_keyno;
                    vm.model.edit.planpolicy_no = response[0].planpolicy_no;
                    vm.model.edit.warning_on_pick_as_component_outside_structure = response[0].warning_on_pick_as_component_outside_structure;
                    vm.model.edit.manage_stockquantity_pr_batch = response[0].manage_stockquantity_pr_batch;
                    vm.model.edit.prodplace_id = response[0].prodplace_id;
                    vm.model.edit.stockcount_unit_no = response[0].stockcount_unit_no;
                    vm.model.edit.purch_price_unit_no = response[0].purch_price_unit_no;
                    vm.model.edit.sales_price_unit_no = response[0].sales_price_unit_no;
                    vm.model.edit.purch_number_unit_no = response[0].purch_number_unit_no;
                    vm.model.edit.protected_prod_name = response[0].protected_prod_name;
                    vm.model.edit.show_drawing_no = response[0].show_drawing_no;
                    vm.model.edit.discgrpprod_keyno = response[0].discgrpprod_keyno;

                    loadInputUnits();
                    loadSalesPrice();
                    loadEdit2();
                });
            };

            let retrieveProdId = function () {
                return productCreateService.getProductId(vm.model.edit).then(function (response) {
                    var nextNumber = 0;
                    nextNumber = angular.copy(response[0].nextnumber);

                    vm.model.edit.prod_id = nextNumber;
                    vm.model.edit.new_number_assigned = '1';
                });
            };

            let retrieveCopyFrom = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id_copy_from, true) !== true) return;

                return productCreateService.getCopyFrom({ prod_id_copy_from: vm.model.edit.prod_id_copy_from }).then(function (response) {
                    if (response[0] > '') {
                        vm.model.edit.productsetting_keyno = response[0].productsetting_keyno;
                        vm.model.edit.prodgrp_id = response[0].prodgrp_id;
                        vm.model.edit.prodgrp2_id = response[0].prodgrp2_id;
                        vm.model.edit.prod_name = response[0].prod_name;
                        vm.model.edit.supplier_no = response[0].supplier_no;
                        vm.model.edit.searchtext_supplier = response[0].supplier_name;
                        vm.model.edit.suppliers_valuta = response[0].suppliers_valuta;
                    } else {
                        vm.model.edit.productsetting_keyno = '';
                        vm.model.edit.prodgrp_id = '';
                        vm.model.edit.prodgrp2_id = '';
                        vm.model.edit.prod_name = '';
                        vm.model.edit.supplier_no = '';
                        vm.model.edit.searchtext_supplier = '';
                        vm.model.edit.suppliers_valuta = '';
                    }
                });
            };

            let retrieveCustomerEdit = function () {
                if (utilityService.validateParmsValue(vm.model.edit.supplier_no, true) !== true) return;

                return economyService.getCustomerEdit({ cust_no: vm.model.edit.supplier_no }).then(function (response) {
                    vm.model.edit.suppliers_valuta = response[0].cust_invoice_valuta_id;
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'copy_components':
                    case 'pricelist_id':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'product_search':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchProduct({
                            prod_name: vm.model.edit[id] || '',
                            webpage_name: stateService.getCurrentName(),
                            parm1: argType,
                            parm2: argValue,
                            supplier_no: '',
                            search_only_this_supplier: '0',
                            businessco_no: vm.model.edit.businessco_no,
                            onlyonstock: vm.model.edit.onlyonstock
                        });
                        break;
                    case 'searchtext_supplier':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'product_search':
                        if (item) {
                            vm.model.edit.prod_id_copy_from = item?.item_id ?? '';
                            vm.model.edit.product_search = item?.item_name ?? '';

                            if (vm.model.edit.prod_id_copy_from > '') retrieveCopyFrom();
                        } else {
                            vm.onTypeaheadClear('product_search');
                        }
                        break;
                    case 'searchtext_supplier':
                        if (item) {
                            vm.model.edit.supplier_no = item?.item_id ?? '0';
                            vm.model.edit.searchtext_supplier = item?.item_name ?? '';

                            if (vm.model.edit.supplier_no > 0) retrieveCustomerEdit();
                        } else {
                            vm.onTypeaheadClear('searchtext_supplier');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'product_search':
                        vm.model.edit.product_search = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.prod_id_copy_from = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('product_search');
                        }
                        break;
                    case 'searchtext_supplier':
                        vm.model.edit.searchtext_supplier = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.supplier_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('searchtext_supplier');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'product_search':
                        vm.model.edit.prod_id_copy_from = '';
                        vm.model.edit.product_search = '';

                        if (vm.model.edit.prod_id_copy_from === '') retrieveCopyFrom();
                        break;
                    case 'searchtext_supplier':
                        vm.model.edit.supplier_no = '0';
                        vm.model.edit.searchtext_supplier = '';

                        if (vm.model.edit.supplier_no === '0') retrieveCustomerEdit();
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;
                vm.model.edit.argtype = argType;
                vm.model.edit.argvalue = argValue;

                var mergeObj = vm.model.edit;
                mergeObj.units = vm.model.inputListUnits;
                mergeObj.metacolumns = vm.model.inputListMcs.map(function (a) {
                    return {
                        "productsettingmc_keyno": a.productsettingmc_keyno,
                        "elementtype": a.elementtype,
                        "isrequired": a.isrequired,
                        "field_value": a.field_value
                    };
                })

                productCreateService.createObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCreate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCreate = false;

                        if (response[0].item_state > '') {
                            stateService.go(response[0].item_state, response[0].item_parms);
                        } else {
                            stateService.back();
                        }
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            let buildProductName = function () {
                let newProductId = '';
                let newProductName = '';
                vm.model.edit.prod_id = '';
                vm.model.edit.prod_name = '';

                angular.forEach(vm.model.inputListMcs, function (item, index) {
                    vm.model.inputListUnits.forEach((unitItem) => {
                        if (index === 0) {
                            unitItem.conversion_formula_replaced = unitItem.conversion_formula;
                        }

                        unitItem.conversion_formula_replaced = unitItem.conversion_formula_replaced.replaceAll(new RegExp(`\\b${item.field_name}\\b`, 'g'), item.field_value);
                        
                        if (index === vm.model.inputListMcs.length - 1 && /^[0-9+\-*/()\s]*$/.test(unitItem.conversion_formula_replaced)) {
                            let newValue = (new Function('return ' + unitItem.conversion_formula_replaced))();

                            if (!isNaN(newValue)) {
                                unitItem.qtyfactor = newValue;
                            }
                        }
                    });
                    
                    if (utilityService.validateParmsValue(item.field_value) !== true) return;

                    let newFieldValue = item.field_value;

                    if (item.build_prod_id === '1') {
                        if (newFieldValue.length > 0) {
                            if (newProductId.length > 0) newProductId += '';

                            if (item.prod_id_length > 0) {
                                newProductId += item.prod_id_prefix + newFieldValue.slice(0, item.prod_id_length) + item.prod_id_postfix;
                            } else {
                                if (item.elementtype === 'N1') {
                                    if (newFieldValue.indexOf(".0")) {
                                        newFieldValue = newFieldValue.replace(".0", "");
                                    }
                                }

                                if (item.elementtype === 'N2') {
                                    if (newFieldValue.indexOf(".00")) {
                                        newFieldValue = newFieldValue.replace(".00", "");
                                    }
                                }
                                
                                if (item.elementtype.includes("N")) {
                                    if (newFieldValue !== '0') {
                                        newProductId += item.prod_id_prefix + newFieldValue + item.prod_id_postfix;
                                    }
                                } else {
                                    newProductId += item.prod_id_prefix + newFieldValue + item.prod_id_postfix;
                                }
                            }
                        }
                    }

                    if (item.build_product_name === '1') {
                        if (newFieldValue.length > 0) {
                            if (newProductName.length > 0) newProductName += ' ';
                            newProductName += item.prefix_value + newFieldValue;
                        }
                    }
                });

                vm.model.edit.prod_id = newProductId.toUpperCase();
                vm.model.edit.prod_name = newProductName;

                loadExistingSetting();
            };

            // #region RECALCULATION FUNCTIONS

            let recalcSupplierPrice = function () {
                //suppliers_price = fullprice_val - fullprice_val * purch_discount / 100
                vm.model.edit.suppliers_price = utilityService.parseNumber(vm.model.edit.fullprice_val) - (utilityService.parseNumber(vm.model.edit.fullprice_val) * (utilityService.parseNumber(vm.model.edit.purch_discount) / 100));
            };
            
            // #endregion RECALCULATION FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                if (vm.model.edit.prod_name_get === '') buildProductName();
            };

            vm.onBlurUnits = function (id) {
                loadSalesPrice();
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS
            
            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockcount_unit_no':
                    case 'purch_price_unit_no':
                    case 'sales_price_unit_no':
                    case 'purch_number_unit_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        loadInputUnits();
                        loadSalesPrice();
                        break;
                    case 'suppliers_price':
                    case 'supplier_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        loadSalesPrice();
                        break;
                    case 'copy_components':
                        if (utilityService.validateWatchValue(value, vm.model.edit.copy_components) !== true) return;

                        vm.model.edit.copy_components = value;

                        if (utilityService.validateParmsValue(vm.model.edit.copy_components) !== true) return;

                        rememberFunc('copy_components');
                        break;
                    case 'productsetting_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.productsetting_keyno) !== true) return;

                        vm.model.edit.productsetting_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.productsetting_keyno) !== true) return;

                        loadProductSettingMcs();

                        if (vm.model.edit.productsetting_keyno > 0) retrieveProductSetting();
                        break;
                    case 'prod_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prod_id) !== true) return;

                        vm.model.edit.prod_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prod_id, true) !== true) return;

                        loadExistingSetting();
                        break;
                    case 'prodgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodgrp_id) !== true) return;

                        vm.model.edit.prodgrp_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodgrp_id, true) !== true) return;

                        loadProductGroup2s();

                        vm.model.edit.prodgrp2_id = '';
                        vm.model.edit.prodgrp3_id = '';
                        vm.model.edit.prodgrp4_id = '';
                        vm.model.selectListProductGroup3s = [];
                        vm.model.selectListProductGroup4s = [];
                        break;
                    case 'prodgrp2_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodgrp2_id) !== true) return;

                        vm.model.edit.prodgrp2_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodgrp2_id, true) !== true) return;

                        loadProductGroup3s();
                        
                        vm.model.edit.prodgrp3_id = '';
                        vm.model.edit.prodgrp4_id = '';
                        vm.model.selectListProductGroup4s = [];
                        break;
                    case 'prodgrp3_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodgrp3_id) !== true) return;

                        vm.model.edit.prodgrp3_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodgrp3_id, true) !== true) return;

                        loadProductGroup4s();
                        
                        vm.model.edit.prodgrp4_id = '';
                        break;
                    case 'fullprice_val':
                        if (utilityService.validateWatchValue(value, vm.model.edit.fullprice_val) !== true) return;

                        vm.model.edit.fullprice_val = value;
                        
                        if (utilityService.validateParmsValue(vm.model.edit.fullprice_val) !== true) return;

                        recalcSupplierPrice();
                        loadSalesPrice();
                        break;
                    case 'purch_discount':
                        if (utilityService.validateWatchValue(value, vm.model.edit.purch_discount) !== true) return;
                        
                        vm.model.edit.purch_discount = value;
                        
                        if (utilityService.validateParmsValue(vm.model.edit.purch_discount) !== true) return;

                        recalcSupplierPrice();
                        loadSalesPrice();
                        break;
                    case 'pricemethod_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.pricemethod_id) !== true) return;

                        vm.model.edit.pricemethod_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.pricemethod_id) !== true) return;

                        loadSalesPrice();
                        break;
                    case 'calc_factor':
                        if (utilityService.validateWatchValue(value, vm.model.edit.calc_factor) !== true) return;

                        vm.model.edit.calc_factor = value;

                        if (utilityService.validateParmsValue(vm.model.edit.calc_factor) !== true) return;

                        loadSalesPrice();
                        break;
                    case 'calc_margin_pst':
                        if (utilityService.validateWatchValue(value, vm.model.edit.calc_margin_pst) !== true) return;

                        vm.model.edit.calc_margin_pst = value;

                        if (utilityService.validateParmsValue(vm.model.edit.calc_margin_pst) !== true) return;

                        loadSalesPrice();
                        break;
                    case 'pricelist_price':
                        if (utilityService.validateWatchValue(value, vm.model.edit.pricelist_price) !== true) return;

                        vm.model.edit.pricelist_price = value;

                        if (utilityService.validateParmsValue(vm.model.edit.pricelist_price) !== true) return;

                        loadSalesPrice();
                        break;
                    case 'discgrpprod_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.discgrpprod_keyno) !== true) return;

                        vm.model.edit.discgrpprod_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.discgrpprod_keyno) !== true) return;

                        loadEdit2();
                        break;
                    case 'pricelist_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.pricelist_id) !== true) return;

                        vm.model.edit.pricelist_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.pricelist_id) !== true) return;

                        rememberFunc('pricelist_id');
                        loadSalesPrice();
                        break;
                    case 'stock_unit_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stock_unit_no) !== true) return;

                        vm.model.edit.stock_unit_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stock_unit_no) !== true) return;

                        loadInputUnits();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgDetails = function (value) {
                vm.model.bgDetails.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPriceMethods();
                loadDiscGroupProducts();
                loadProductSettings();
                loadPrices();
                loadProductTypes();
                loadUnits();
                loadCompanies();
                loadCompletionMethods();
                loadCompletionPrinciples();
                loadProductionOrderMethods();
                loadPlanPolicies();
                loadProductionPlaces();
                loadProductGroups();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
