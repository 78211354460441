import { Injectable } from '@angular/core';
import { DataTaskService } from '../core/services/data-task.service';

@Injectable({
    providedIn: 'root'
})
export class HtmlReportService {

    constructor(private datatask: DataTaskService) { }  

    public async getReport(htmlreport_keyno: number): Promise<any> {
        if (!htmlreport_keyno) {
            throw Error('Missing parameter: htmlreport_keyno');
        }
        return this.datatask.Post(3362, { htmlreport_keyno });
    }
    // DENNE HER SKAL DU BRUKE IMORAA FORE Å HENTE HELE PREVIEWEN
    public async getHtmlReportHtml(parms: any): Promise<any> {
        if (!parms) {
            throw Error('Missing parameter: parms');
        }
        return this.datatask.Post(3370, parms);
    }

    // DENNE HER SKAL DU BRUKE IMORAA FORE Å HENTE PREVIEW AV EN HTMLPROC
    public async getHtmlProcHtml(htmlproc_keyno: any) {
        if (!htmlproc_keyno) {
            throw Error('Missing parameter: parms');
        }
        return this.datatask.Post(3374, { htmlproc_keyno });
    }

    public async getHtmlProcHtmlExcludeHeader(htmlproc_keyno: any) {
        if (!htmlproc_keyno) {
            throw Error('Missing parameter: parms');
        }
        return this.datatask.Post(3392, { htmlproc_keyno });
    }

    public async listWebPages(parms: any = {}): Promise<any> {
        return this.datatask.Post(4, parms);
    }

    public async loadHtmlProcList(htmlreport_keyno: number): Promise<any> {
        if (!htmlreport_keyno) {
            throw Error('Missing parameter: htmlreport_keyno');
        }
        return this.datatask.Post(3365, { htmlreport_keyno });
    }

    public async saveReport(htmlReport: any): Promise<any> {
        if (!htmlReport) {
            throw Error('Missing parameter: htmlReport');
        }
        return this.datatask.Post(3363, htmlReport);
    }
    public async saveHtmlProc(htmlProc: any): Promise<any> {
        if (!htmlProc) {
            throw Error('Missing parameter: htmlReport');
        }
        return this.datatask.Post(3367, htmlProc);
    }

    public async getList(htmlproc_keyno: number): Promise<any> {
        if (!htmlproc_keyno) {
            throw Error('Missing parameters');
        }
        return this.datatask.Post(3385, { htmlproc_keyno: htmlproc_keyno, visible: 2 } );
    }

    public async saveList(parms: any = {}): Promise<any> {
        if (!parms) {
            throw Error('Missing parameters');
        }
        return this.datatask.Post(3386, parms);
    }

    public async getHtmlProc(htmlproc_keyno: number): Promise<any> {
        if (!htmlproc_keyno) {
            throw Error('Missing parameters');
        }
        return this.datatask.Post(3366, { htmlproc_keyno: htmlproc_keyno });
    }

    public async getHtmlDisplayList(htmlreport_keyno: number): Promise<any> {
        if (!htmlreport_keyno) {
            throw Error('Missing parameters');
        }
        return this.datatask.Post(3389, { htmlreport_keyno: htmlreport_keyno });
    }

    public async getHtmlProccolList(htmlreport_keyno: number): Promise<any> {
        if (!htmlreport_keyno) {
            throw Error('Missing parameters');
        }
        return this.datatask.Post(3390, { htmlreport_keyno: htmlreport_keyno });
    }


}
