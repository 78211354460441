(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sop', {
        templateUrl: 'views/components/views/sop/sop.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'sopService', function ($stateParams, stateService, utilityService, sopService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
            vm.model = {
                sopKeyNo: $stateParams.sop_keyno,
                settingServiceObjectProperty: {},
                selectListFieldTypes: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            sopService.loadSopGet(vm.model.sopKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.settingServiceObjectProperty);
            });
    
            sopService.loadSopFieldTypesList().then(function (data) {
                angular.copy(data, vm.model.selectListFieldTypes);
            });
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSop = function () {
                vm.model.lockedSave = true;
                
                sopService.save(vm.model.settingServiceObjectProperty).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();