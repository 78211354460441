(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('salesProspects', {
        templateUrl: 'views/components/views/salesProspects/salesProspects.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'salesProspectsService', 'userService', function ($stateParams, salesProspectsService, userService) {
            var vm = this;
            
            salesProspectsService.loadP2RiskElementList($stateParams.argtype, $stateParams.argvalue, '0', '0').then(function () {
                vm.p2_riskelement_list = salesProspectsService.p2_riskelement_list;
            });
            
            vm.riskCountMatrix = [];

            vm.riskCountMatrixRec = {
                sales_possibility_no: 0,
                sales_size_no: 0,
                count: 0,
                background: '#B91114',
                color: '#ffffff'
            };
    
            vm.cell_clicked = 0;

            vm.riskCountMatrix_clicked = function (sales_possibility_no, sales_size_no) {
                vm.cell_clicked += 1;
                
                salesProspectsService.loadP2RiskElementList($stateParams.argtype, $stateParams.argvalue, sales_possibility_no, sales_size_no).then(function () {
                    vm.p2_riskelement_list = salesProspectsService.p2_riskelement_list;
                });
            };

            vm.riskCountMatrix_showall_clicked = function () {
                vm.cell_clicked = 0;

                salesProspectsService.loadP2RiskElementList($stateParams.argtype, $stateParams.argvalue, '0', '0').then(function () {
                    vm.p2_riskelement_list = salesProspectsService.p2_riskelement_list;
                });
            };
    
            salesProspectsService.loadP2RiskElementProbabilityLabels().then(function () {
                vm.p2_riskelement_probability_labels = salesProspectsService.p2_riskelement_probability_labels;
            });
    
            salesProspectsService.loadP2RiskElementConsequenceLabels().then(function () {
                vm.p2_riskelement_consequence_labels = salesProspectsService.p2_riskelement_consequence_labels;
            });
    
            salesProspectsService.loadP2RiskElementDescription().then(function () {
                vm.p2_riskelement_description = salesProspectsService.p2_riskelement_description;

                angular.forEach(vm.p2_riskelement_description.records, function (item) {
                    var ic = vm.get_list_item_color('', '', item.salesopportunity_no);
                    item.background = ic.background;
                    item.color = ic.color;
                });
            });
            
            salesProspectsService.loadP2RiskElementListCount($stateParams.argtype, $stateParams.argvalue).then(function () {
                vm.p2_riskelement_list_count = salesProspectsService.p2_riskelement_list_count;
                vm.prepare_riskCountMatrix(vm.p2_riskelement_list_count.records);
            });
    
            vm.get_list_item_count = function (list, probability, consequence) {
                var out = 0;
                
                angular.forEach(list, function (item, key) {
                    if (item.sales_possibility_no == probability && item.sales_size_no == consequence) {
                        //out = out + 1;
                        out = item.nbr;
                    }
                });
    
                return out;
            };
    
            vm.get_list_item_color = function (probability, consequence, salesopportunity_no) {
                var out = {
                    background: '#B91114',
                    color: '#ffffff'
                };

                var num = probability + '_' + consequence + salesopportunity_no; //+'_' + ;
    
                switch (num) {
                    /*RED*/
                    case '1_1':
                    case '1_2':
                    case '1_3':
                    case '2_1':
                    case '2_2':
                    case '3_1':
                    case '_4':
                        out.background = '#B91114';
                        out.color = '#ffffff';
                        //out.background = '#0F9F0F';
                        //out.color = '#FFFFFF';
                        break;
                    /*ORANGE*/
                    case '1_4':
                    case '1_5':
                    case '2_2':
                    case '2_3':
                    case '2_4':
                    case '3_2':
                    case '3_3':
                    case '4_1':
                    case '4_2':
                    case '5_1':
                    case '_3':
                        out.background = '#FFAC22';
                        out.color = '#000000';
                        //out.background = '#F2E228';
                        //out.color = '#000000';
                        break;
                    /*YELLOW*/
                    case '_2':
                    case '2_5':
                    case '3_4':
                    case '4_3':
                    case '5_2':
                        out.background = '#F2E228';
                        out.color = '#000000';
                        //out.background = '#FFAC22';
                        //out.color = '#000000';
                        break;
                    /*GREEN*/
                    default:
                        out.background = '#0F9F0F';
                        out.color = '#FFFFFF';
                        //out.background = '#B91114';
                        //out.color = '#ffffff';
                        break;
                }
    
                return out;
            };
    
            vm.eye_state = true;

            vm.toggle_eye_state = function () {
                vm.eye_state != true;
            };

            vm.get_eye_state = function () {
                return vm.eye_state;
            };
    
            var f_size = userService.fontSize;
    
            vm.get_font = {
                text_size: f_size,
                text_size_s: (parseInt(f_size.substr(0, f_size.length - 2)) - 3).toString() + "px",
                text_size_ss: (parseInt(f_size.substr(0, f_size.length - 2)) - 5).toString() + "px",
            };
    
            vm.shadeColor2 = function (color, percent) {
                var f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
                return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
            };
    
            vm.blendColors = function (c0, c1, p) {
                var f = parseInt(c0.slice(1), 16), t = parseInt(c1.slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF, R2 = t >> 16, G2 = t >> 8 & 0x00FF, B2 = t & 0x0000FF;
                return "#" + (0x1000000 + (Math.round((R2 - R1) * p) + R1) * 0x10000 + (Math.round((G2 - G1) * p) + G1) * 0x100 + (Math.round((B2 - B1) * p) + B1)).toString(16).slice(1);
            };
    
            vm.riskCountMatrixCounter = 0;
    
            vm.prepare_riskCountMatrix = function (list) {
                vm.riskCountMatrix = [];
                var probability = 5;
                var consequence = 1;

                for (probability = 5; probability > 0; probability--) {
                    consequence = 1;

                    for (consequence = 1; consequence < 6; consequence++) {
                        var nr = {};
                        nr.sales_possibility_no = probability;
                        nr.sales_size_no = consequence;
                        nr.count = vm.get_list_item_count(list, probability, consequence);
                        var nrc = vm.get_list_item_color(probability, consequence, '');
                        nr.background = nrc.background;
                        nr.color = nrc.color;
                        vm.riskCountMatrix[consequence + '_' + probability] = nr;
                        //vm.riskCountMatrix.push(nr);
                    }
                }

                vm.riskCountMatrixCounter = 0;
            };
        }]
    });
})();