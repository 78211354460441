(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srUser', {
        templateUrl: 'views/components/views/srUser/srUser.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'modalService', 'rememberService', 'userService', 'languageService', 'srUserService', 'idServerService', 'appConfig', 'utilityService', 'watcherFactory', function($stateParams, $timeout, stateService, modalService, rememberService, userService, languageService, srUserService, idServerService, appConfig, us, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                portalUserKeyNo: $stateParams.portal_user_keyno,
                resourceSearchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                user: {},
                idInfo: {
                    twoFaEnabled: false,
                    twoFaType: ''
                },
                selectListLanguages: [],
                selectListFontSizes: [],
                selectListDeviationRights: [],
                selectListRefunds: [],
                lockedSave: false,
                twoFaTypes: [
                    { item_id: 'Email', item_name: 'Email' },
                    { item_id: 'SmsCode', item_name: 'Phone' },
                    { item_id: 'SmsAndEmail', item_name: 'Email and Phone' }
                ],
                twoFaLoginDisabled: true
            };
    
            // ## IF CONDITIONS FUNCTION ##
    
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.user[parmWithValue]) === true) return false;
                if (vm.model.user[parmWithValue] === null) return false;
                if (vm.model.user[parmWithValue].trim().length < 1) return false;
    
                return true;
            };
    
            var validateParmsEmptyValue = function (parmEmptyValue) {
                if (angular.isUndefined(vm.model.user[parmEmptyValue]) === true) return false;
                if (vm.model.user[parmEmptyValue] === null) return false;
    
                return true;
            };
    
    		var validateNewOldValue = function (newVal, oldVal) {
    			if (angular.isUndefined(newVal) === true) return false;
    			if (angular.isUndefined(oldVal) === true) return false;
    			if (newVal === oldVal) return false;
    
    			return true;
            };
    
            // Info about the currently logged in user
            var currentUser = {};
            var currentUserLoaded = false;
            // Info about the user that's being edited
            var editUser = {};
            var editUserLoaded = false;
    
            var setIdInfo = function () {
                if (appConfig.client.canModifyTwoFaLogin !== true)
                    return;
                if (currentUserLoaded !== true)
                    return;
                if (editUserLoaded !== true)
                    return;
    
                if (currentUser.userName !== editUser.userName) {
                    // Editing other user, must be admin
                    if (currentUser.isCompanyAdmin !== true)
                        return;
                }
    
                vm.model.twoFaLoginDisabled = false;
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            // BJS 20200907
            // Need to reset any cached values
            userService.userEditCache.reset();
    
            // flag indicating if twoFa has been changed and should be updated
            var twoFaDirty = false;
    
            vm.twoFaChanged = function (id, value) {
                switch (id) {
                    case 'twoFaEnabled':
                        vm.model.idInfo[id] = us.toBoolean(value);
                        break;
                    default:
                        vm.model.idInfo[id] = value;
                        break;
                }
    
                twoFaDirty = true
            };
    
            srUserService.loadSrUserGet(vm.model.portalUserKeyNo).then(function (data) {
                vm.model.user = angular.copy(data[0]);
    
                idServerService.userDetails(vm.model.user.user_id_portal).then(function (cu) {
                    angular.copy(cu, editUser);
    
                    vm.model.idInfo.twoFaEnabled = editUser.twoFactorEnabled;
                    vm.model.idInfo.twoFaType = editUser.twoFactorType;
    
                    editUserLoaded = true;
    
                    setIdInfo();
                });
            });
    
            idServerService.currentUserDetails().then(function (loggedInUser) {
                angular.copy(loggedInUser, currentUser);
    
                currentUserLoaded = true;
    
                setIdInfo();
            });
    
            var loadLanguages = function () {
                languageService.list().then(function (data) {
                    angular.copy(data, vm.model.selectListLanguages);
                });
            };
    
            var loadFontSizes = function () {
                srUserService.loadFontSizeList().then(function (data) {
                    angular.copy(data, vm.model.selectListFontSizes);
                });
            };
    
            var loadSpecialHrsDeviationRights = function () {
                srUserService.loadSpecialHrsDeviationRightsList().then(function (data) {
                    angular.copy(data, vm.model.selectListDeviationRights);
                });
            };
    
            var loadSrUserRefunds = function () {
                srUserService.loadSrUserRefundList().then(function (data) {
                    angular.copy(data, vm.model.selectListRefunds);
                });
            };
    
            loadLanguages();
            loadFontSizes();
            loadSpecialHrsDeviationRights();
            loadSrUserRefunds();
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.resourceSearchTextBtnListFunc = function (value, item_func) {
                if (item_func === 'erase_item') {
                    vm.model.user.resource_id = '';
                    vm.model.user.resource_searchtext = '';
                } else if (angular.isDefined(value) && value !== '' && angular.isUndefined(item_func)) {
                    //vm.model.searchingResources = true;
                    return vm.typeaheadSearch(value);
                } else if (item_func === 'search_item') {
                    //vm.model.searchingResources = true;
                    return vm.typeaheadSearch(value);
                }
            };
    
            var changedResourceId = function (resource_id) {
                vm.model.user.resource_id = resource_id;
    
                //rememberResourceId();
            };
    
            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##
    
            vm.typeaheadSearch = function (value) {
                return srUserService.searchResources(vm.model.user.resource_searchtext);
            };
    
            // ## SAVE BUTTON FUNCTION ##
            var lockCounter = 0;
    
            vm.saveSrUser = function () {
                lockCounter = 1;
                vm.model.lockedSave = true;
    
                var openLock = function () {
                    lockCounter--;
    
                    vm.model.lockedSave = lockCounter !== 0;
                };
    
                if (twoFaDirty === true) {
                    lockCounter++;
    
                    if (us.toBoolean(vm.model.idInfo.twoFaEnabled) === true) {
                        console.log(editUser.userName + ' - ' + vm.model.idInfo.twoFaType);
    
                        idServerService.addTwoFactor(editUser.userName, vm.model.idInfo.twoFaType).then(openLock);
                    } else {
                        idServerService.removeTwoFactor(editUser.userName).then(openLock);
                    }
                }
                
                srUserService.save(vm.model.user).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    openLock();
    
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        openLock();
    
                        stateService.back();
                    }
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'usersettings_dialogue_userinfo':
                        go({
                            portalUserKeyno: vm.model.user.portal_user_keyno
                        });
                        break;
                }
            };
    
            // ## SIZE FONTSIZE FUNCTION ##
    
            var sizeSelection = function () {
                if (userService.userId !== vm.model.user.portal_user_keyno) return;
                if (validateParmsEmptyValue('fontsize') !== true) return;
    
                userService.fontSize = vm.model.user.fontsize + 'px';
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () {
                return vm.model.user.resource_searchtext;
            }, function (newValue, oldValue) {
                //vm.model.searchingResources = false;
                if (angular.isUndefined(newValue) === true) return;
                if (angular.isUndefined(oldValue) === true) return;
                if (newValue === oldValue) return;
    
                if (newValue === '') {
                    vm.model.user.resource_id = '';
                    vm.model.user.resource_searchtext = '';
                } else {
                    angular.forEach(srUserService.selectResourcesList, function (data) {
                        if (angular.isDefined(data)) {
                            if (data.item_name === vm.model.user.resource_searchtext) {
                                changedResourceId(data.item_id);
                            }
                        }
                    });
                }
            });
    
            watcher.$watch(function () {
                return vm.model.user.fontsize;
            }, function (newValue, oldValue) {
                if (validateNewOldValue(newValue, oldValue) !== true) return;
    
                sizeSelection();
            });
        }]
    });
})();