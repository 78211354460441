(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('colorGrp', {
        templateUrl: 'views/components/views/colorGrp/colorGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'colorGrpService', function ($stateParams, stateService, colorGrpService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let colorGrpId = $stateParams.colorgrp_id;

            vm.model = {
                colorGrpId: colorGrpId,
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadEdit = function () {
                colorGrpService.getEdit({ colorgrp_id: colorGrpId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                colorGrpService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
