(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('domObjectTypeProp', {
        templateUrl: 'views/components/views/domObjectTypeProp/domObjectTypeProp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'domObjectTypePropService', function ($stateParams, stateService, modalService, translateService, domObjectTypePropService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let domObjectTypeKeyno = $stateParams.domobjecttype_keyno;
            let domObjectTypePropKeyno = $stateParams.domobjecttypeprop_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
            	error: '',
            	ok: ''
            };

            vm.model = {
                domObjectTypePropKeyno: domObjectTypePropKeyno,
                edit: {},
                selectListPropertyTypes: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                domObjectTypePropService.getDomObjectTypeProp({
                    domobjecttype_keyno: domObjectTypeKeyno,
                    domobjecttypeprop_keyno: domObjectTypePropKeyno
                }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);
            	});
            };
            
            let loadPropertyTypes = function () {
                domObjectTypePropService.listPropertyTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListPropertyTypes);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                domObjectTypePropService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    domObjectTypePropService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };
            
            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPropertyTypes();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
