(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2TableEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_table_edit: {
                p2_table_keyno: '',
                table_name: '',
                word_id: ''
            },
            loadP2TableEdit: function (p2_table_keyno) {
                var deferred = $q.defer();

                var parms_p2table_get = {
                    method: 25,
                    parameters: {
                        p2_table_keyno: p2_table_keyno
                    }
                };

                $ihttp.post(parms_p2table_get).then(function (data) {
                    service.p2_table_edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2TableEdit: function () {
                var deferred = $q.defer();

                var parms_p2table_save = {
                    method: 26,
                    parameters: {
                        p2_table_keyno: service.p2_table_edit.p2_table_keyno,
                        table_name: service.p2_table_edit.table_name,
                        word_id: service.p2_table_edit.word_id
                    }
                };

                $ihttp.post(parms_p2table_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();