(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchHeads', {
        templateUrl: 'views/components/views/purchHeads/purchHeads.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'purchHeadsService', 'logisticService', 'typeaheadService', 'rememberService', function ($stateParams, $q, stateService, utilityService, purchHeadsService, logisticService, typeaheadService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let webPage = stateService.getCurrentName() + '/' + argType + '/' + argValue;

            let variableNames = {
                searchtext: ''
            };

            vm.version = module.version;
            
            vm.onGridBoxResized = function () {
                vm.grid?.gridfunc?.resize();
                vm.gridPurchaseLines?.gridfunc?.resize();
            }

            vm.model = {
                argType: argType,
                argValue: argValue,
                mobileSize: window.matchMedia('(min-width: 992px)'),
                purchaseViewBoxes: {
                    //height: '25vh',
                    rememberId: 'purchheads.boxes',
                    boxes: [
                        { item_id: uuid(), item_name: 'purchhead', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'purchlines', type: 'h', size: '50', depth: 0 },
                        { item_id: uuid(), item_name: 'filters', type: 'v', size: '25', depth: 1 },
                        { item_id: uuid(), item_name: 'purchheadgrid', type: 'v', size: '75', depth: 1 }
                    ],
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'success', type: 'success', onClick: () => vm.searchPurchases() }
                ],
                portalUsersButtons: [
                    { id: 'erase', icon: 'fas fa-user', color: 'success', type: 'success', onClick: () => setCurrentPortalUser() },
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('portal_user_keyno') }
                ],
                purchaseStatusButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('purchasestatus_id') }
                ],
                setting: {},
                selectListPurchaseStatuses: [],
                itemsListPurchases: [],
                selectListPortalUsers: [],
                selectListCompanies: [],
                gridReady: false,
                gridPurchaseLinesReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_purchheads_purchhead_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        height: 'fill'
                    },
                    //onDataSourceChanges: function (e) {
                    //    setTimeout(() => {
                    //        try {
                    //            vm.grid.gridfunc?.getGrid()?.select('tr:eq(0)');
                    //        } catch (error) {

                    //        }
                    //    }, 100);
                    //},
                    onDataChanged: function (changed, e) {
                        console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                //onReady: function () {
                //    setTimeout(() => {
                //        vm.grid.gridfunc?.getGrid()?.select('tr:eq(0)');
                //    });
                //},
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    },
                    //selectable: false, //'multiple, row'
                    navigatable: true,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                onDataBound: () => {
                    setTimeout(() => {
                        try {
                            vm.grid.gridfunc.selectRow(0)
                            //vm.grid.gridfunc?.getGrid()?.select('tr:eq(0)');
                        } catch (error) {

                        }
                    }, 100);
                },
                onSelect: (event) => {
                    if (event.$item) {
                        vm.model.setting.purch_internal_no = event.$item.purch_internal_no;
                        loadGridPurchaseLines();
                    }
                },
                gridfunc: null
            };
            
            vm.gridPurchaseLines = {
                dataTask: {
                    rememberId: 'purchheads_purchline_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        height: 'fill'
                    },
                    onDataSourceChanges: function (e) {
                        setTimeout(() => {
                           // vm.gridPurchaseLines?.gridfunc?.getGrid()?.select('tr:eq(0)');
                        });
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    }, //'multiple, row'
                    //navigatable: true,
                    //persistSelection: false,
                    filterable: false
                },
                onReady: () => setTimeout(() => vm.gridPurchaseLines?.gridfunc?.resize(), 250),
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS
            
            // #region PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                purchHeadsService.getSetting({
                    argtype: argType,
                    argvalue: argValue,
                    webpage: webPage
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all().then(() => {
                        if (vm.model.mobileSize.matches === true) {
                            loadGrid();
                            vm.model.gridPurchaseLinesReady = false;
                        } else {
                            loadPurchaseHeads();
                        }

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadPortalUserPurchases = function () {
                logisticService.listPortalUserPurchases({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListPortalUsers);
                });
            };

            let loadPurchaseStatuses = function () {
                purchHeadsService.listPurchaseStatuses({ addall: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListPurchaseStatuses);
                });
            };

            let loadPurchaseHeads = function () {
                if (utilityService.validateParmsValue(vm.model.setting.supplier_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext_supplier, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.purchasestatus_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.supplier_name, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.supplier_no_and_name, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_whos, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_purchasetype, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_purchasetype_purch, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_purchdate, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListPurchases = [];

                return purchHeadsService.listPurchaseHeads({
                    argtype: argType,
                    argvalue: argValue,
                    supplier_no: vm.model.setting.supplier_no,
                    searchtext_supplier: vm.model.setting.searchtext_supplier,
                    purchasestatus_id: vm.model.setting.purchasestatus_id,
                    filterval: vm.model.setting.filterval,
                    supplier_name: vm.model.setting.supplier_name,
                    supplier_no_and_name: vm.model.setting.supplier_no_and_name,
                    portal_user_keyno: vm.model.setting.portal_user_keyno,
                    gb_whos: vm.model.setting.gb_whos,
                    gb_purchasetype: vm.model.setting.gb_purchasetype,
                    gb_purchasetype_purch: vm.model.setting.gb_purchasetype_purch,
                    gb_purchdate: vm.model.setting.gb_purchdate,
                    webpage: webPage,
                    businessco_no: vm.model.setting.businessco_no,
                    searchtext: vm.model.setting.searchtext
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListPurchases);
                });
            };
            
            // #endregion PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3059,
                    parameters: {
                        argtype: argType,
                        argvalue: argValue,
                        supplier_no: vm.model.setting.supplier_no,
                        searchtext_supplier: vm.model.setting.searchtext_supplier,
                        purchasestatus_id: vm.model.setting.purchasestatus_id,
                        filterval: vm.model.setting.filterval,
                        supplier_name: vm.model.setting.supplier_name,
                        supplier_no_and_name: vm.model.setting.supplier_no_and_name,
                        portal_user_keyno: vm.model.setting.portal_user_keyno,
                        gb_whos: vm.model.setting.gb_whos,
                        gb_purchasetype: vm.model.setting.gb_purchasetype,
                        gb_purchasetype_purch: vm.model.setting.gb_purchasetype_purch,
                        gb_purchdate: vm.model.setting.gb_purchdate,
                        webpage: webPage,
                        businessco_no: vm.model.setting.businessco_no,
                        searchtext: vm.model.setting.searchtext
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadGridPurchaseLines = function () {
                vm.gridPurchaseLines.dataTask.loadData = {
                    method: 3000,
                    parameters: { purch_internal_no: vm.model.setting.purch_internal_no }
                };

                vm.model.gridPurchaseLinesReady = true;

                if (vm.gridPurchaseLines.gridfunc !== null) vm.gridPurchaseLines.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'searchtext':
                            variableNames[key] = stateService.getCurrentName() + '/' + argType + '/' + argValue + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let setCurrentPortalUser = function () {
                vm.model.setting.portal_user_keyno = vm.model.setting.current_portal_user_keyno;

                if (vm.model.setting.portal_user_keyno > 0) {
                    if (vm.model.mobileSize.matches === true) {
                        loadGrid();
                        vm.model.gridPurchaseLinesReady = false;
                    } else {
                        loadPurchaseHeads();
                    }
                }
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') vm.searchPurchases();
                        break;
                    case 'portal_user_keyno':
                        vm.model.setting.portal_user_keyno = '0';

                        if (vm.model.setting.portal_user_keyno === '0') {
                            if (vm.model.mobileSize.matches === true) {
                                loadGrid();
                                vm.model.gridPurchaseLinesReady = false;
                            } else {
                                loadPurchaseHeads();
                            }
                        }
                        break;
                    case 'purchasestatus_id':
                        vm.model.setting.purchasestatus_id = '999';

                        if (vm.model.setting.purchasestatus_id === '999') {
                            if (vm.model.mobileSize.matches === true) {
                                loadGrid();
                                vm.model.gridPurchaseLinesReady = false;
                            } else {
                                loadPurchaseHeads();
                            }
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.searchPurchases = function () {
                rememberFunc('searchtext');

                if (vm.model.mobileSize.matches === true) {
                    loadGrid();
                    vm.model.gridPurchaseLinesReady = false;
                } else {
                    loadPurchaseHeads();
                }
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'S',
                    lastselect: 'SEARCH'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.setting.supplier_no = item?.item_id ?? '0';
                    vm.model.setting.searchtext_supplier = item?.item_name ?? '';

                    if (vm.model.mobileSize.matches === true) {
                        loadGrid();
                        vm.model.gridPurchaseLinesReady = false;
                    } else {
                        loadPurchaseHeads();
                    }
                } else {
                    vm.onClear();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedSearchTextSupplier = function (value, item) {
                vm.model.setting.searchtext_supplier = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.setting.supplier_no = item.item_id;
                    }
                } else {
                    vm.onClear();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                vm.model.setting.supplier_no = '0';
                vm.model.setting.searchtext_supplier = '';

                if (vm.model.mobileSize.matches === true) {
                    loadGrid();
                    vm.model.gridPurchaseLinesReady = false;
                } else {
                    loadPurchaseHeads();
                }
            };

            // #endregion CLEAR VALUE FUNCTION
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'purchheadcreate':
                        go({
                            argtype: argType,
                            argvalue: argValue
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        //if (vm.model.setting.searchtext === '') vm.searchPurchases();
                        vm.searchPurchases();
                        break;
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        if (vm.model.mobileSize.matches === true) {
                            loadGrid();
                            vm.model.gridPurchaseLinesReady = false;
                        } else {
                            loadPurchaseHeads();
                        }
                        break;
                    case 'portal_user_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.portal_user_keyno) !== true) return;

                        vm.model.setting.portal_user_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno) !== true) return;

                        if (vm.model.mobileSize.matches === true) {
                            loadGrid();
                            vm.model.gridPurchaseLinesReady = false;
                        } else {
                            loadPurchaseHeads();
                        }
                        break;
                    case 'purchasestatus_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.purchasestatus_id) !== true) return;

                        vm.model.setting.purchasestatus_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.purchasestatus_id) !== true) return;

                        if (vm.model.mobileSize.matches === true) {
                            loadGrid();
                            vm.model.gridPurchaseLinesReady = false;
                        } else {
                            loadPurchaseHeads();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON WINDOW RESIZE FUNCTION

            let onWindowResize = function () {
                if (vm.model.mobileSize.matches === true) {
                    loadGrid();
                    vm.model.gridPurchaseLinesReady = false;
                } else {
                    loadPurchaseHeads();
                }
            };

            // #endregion ON WINDOW RESIZE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
                loadPortalUserPurchases();
                loadPurchaseStatuses();

                vm.model.mobileSize.addEventListener('change', onWindowResize);

                // JLR 20240927 - no need to call this here.
                //onWindowResize();
            };

            // #endregion ON INIT FUNCTION

            // #region ON DESTROY FUNCTION

            vm.$onDestroy = function () {
                vm.model.mobileSize.removeEventListener('change', onWindowResize);
            };

            // #endregion ON DESTROY FUNCTION

        }]
    });
})();
