(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('srReportMenu', {
        templateUrl: 'views/components/views/srReportMenu/srReportMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'utilityService', 'stateService', 'modalService', 'rememberService', 'srReportMenuService', 'watcherFactory', function($stateParams, $timeout, utilityService, stateService, modalService, rememberService, srReportMenuService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
    		// ## VARIABLES & DEFINITIONS ##
    
    		var onDestroy = [];
    
    		vm.model = {
    			checkListDataKeyno: $stateParams.checklistdata_keyno,
                bgSrReportMenuMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '',
                    records: [
    					{ item_id: '0', item_name: 'srreportmenu_basis', item_func: 'BASIS' },
    					{ item_id: '1', item_name: 'srreportmenu_refund', item_func: 'REFUND' }
                    ]
                },
    			specialHrsNoBtnList: [
    				{ item_id: '0', item_name: 'goto', item_func: 'goto_item', glyph: 'glyphicon-chevron-right', color: 'primary' }
    			],
    			menu: {},
    			selectListResponsibleLabours: [],
    			itemsListMenu: [],
    			updatingStatus: false
    		};
    
            // ## INIT LOAD FUNCTION ##
    
            var initLoadDone = false;
            var timerInitLoadDone = null;
    
            var initLoadFunc = function () {
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }
    
                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		var loadSrReportMenuGet = function () {
    			srReportMenuService.loadSrReportMenuGet(vm.model.checkListDataKeyno).then(function (data) {
    				vm.model.menu = angular.copy(data[0]);
    				vm.model.bgSrReportMenuMain.item_default = utilityService.groupButtonIdOf(vm.model.bgSrReportMenuMain, vm.model.menu.bg_srreportmenu_main);
    				
    				initLoadFunc();
    				loadSrReportMenu();
    				loadResponsibleLabours();
    			});
    		};
    
    		loadSrReportMenuGet();
    
    		var loadSrReportMenu = function () {
    			if (utilityService.validateParmsValue(vm.model.menu.checklistdata_keyno) !== true) return;
    
    			vm.model.itemsListMenu = [];
    
    			srReportMenuService.loadSrReportMenuList(vm.model.menu.checklistdata_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListMenu);
                });
            };
    
    		var loadResponsibleLabours = function () {
    			if (utilityService.validateParmsValue(vm.model.menu.resource_id) !== true) return;
    
    			vm.model.selectListResponsibleLabours = [];
    
    			srReportMenuService.listResponsibleLabours({ resource_id: vm.model.menu.resource_id }).then(function (data) {
                    angular.copy(data, vm.model.selectListResponsibleLabours);
                });
            };
    
    		var createNewResponsible = function () {
    			if (utilityService.validateParmsValue(vm.model.menu.checklistdata_keyno) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.menu.responsible_labour_no) !== true) return;
    
    			srReportMenuService.createNewResponsibleLabour({
    				checklistdata_keyno: vm.model.menu.checklistdata_keyno,
    				responsible_labour_no: vm.model.menu.responsible_labour_no
    			}).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        
                    }
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberFunc = function () {
    			var variableName = 'w_' + stateService.getCurrentName() + '.' + 'bg_srreportmenu_main';
    
                if (initLoadDone !== true) return;
                if (utilityService.validateParmsValue(variableName) !== true) return;
    			if (utilityService.validateParmsValue(vm.model.menu.bg_srreportmenu_main) !== true) return;
    
                rememberService.remember(variableName, vm.model.menu.bg_srreportmenu_main);
            };
    
            // ## BUTTON GROUP FUNCTIONS ##
    
    		vm.selectBgSrReportMenuMain = function (item) {
    			vm.model.bgSrReportMenuMain.item_selected = item;
    			vm.model.menu.bg_srreportmenu_main = vm.model.bgSrReportMenuMain.item_selected;
            };
    
    		// ## BUTTON LIST FUNCTIONS ##
    
    		vm.specialHrsNoBtnListFunc = function (item_func) {
    			if (item_func === 'goto_item') {
    				vm.goTo('sroperation');
    			}
    		};
    
    		// ## DIRECTION BUTTON FUNCTIONS ##
    
    		vm.goTo = function (state) {
    			var go = function (parms) {
    				stateService.go(state, parms);
    			};
    
    			switch (state) {
    				case 'sroperation':
    					go({ custact_no: vm.model.menu.custact_no });
    					break;
    				case 'checklistdata':
    					go({ checklistdata_keyno: vm.model.menu.checklistdata_keyno });
    					break;
    				case 'selectreport':
    					go({
    						webpage_name: 'checklistdata',
    						argtype: 'checklistdata_keyno',
    						argvalue: vm.model.menu.checklistdata_keyno
    					});
    					break;
    				case 'mapview':
    					go({
    						argtype: 'custact_no',
    						argvalue: vm.model.menu.custact_no
    					});
    					break;
    				case 'srequipments':
    					go({
    						resource_id: vm.model.menu.resource_id
    					});
    					break;
    				case 'srrefundbasis':
    					go({
    						custact_no: vm.model.menu.custact_no,
    						checklistdata_keyno: vm.model.menu.checklistdata_keyno
    					});
    					break;
    				case 'srtravelbill':
    					go({
    						travelbill_no: vm.model.menu.travelbill_no
    					});
    					break;
    			}
    		};
    
            // ## CHANGE BUTTON FUNCTION ##
    
    		vm.updateStatus = function () {
    			vm.model.updatingStatus = true;
    
    			srReportMenuService.changeStatus({ checklistdata_keyno: vm.model.menu.checklistdata_keyno }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingStatus = false;
                                }
                            }]
                        });
                    } else {
    					vm.model.updatingStatus = false;
    					loadSrReportMenuGet();
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
    		watcher.$watch(function () { return vm.model.menu.bg_srreportmenu_main; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			rememberFunc();
    		}, onDestroy);
    
    		watcher.$watch(function () { return vm.model.menu.responsible_labour_no; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			createNewResponsible();
    		}, onDestroy);
    
            // #region DESTROY FUNCTION
    
            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
    
    		// #endregion DESTROY FUNCTION
    	}]
    });
})();