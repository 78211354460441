(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCustomerEditService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCustomerEditGet: function (cust_no) {
                return p2DataTaskService.call(1613, {
                    cust_no: cust_no
                });
            },
            save: function (editCustomer) {
                return p2DataTaskService.call(1614, editCustomer);
            }
        };

        return service;
    }]);
})();