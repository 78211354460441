(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("freightRateTypeService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadFreightRateTypeGet: function (freightratetype_keyno) {
                return p2DataTaskService.call(1537, {
                    freightratetype_keyno: freightratetype_keyno
                });
            },
            save: function (settingRateType) {
                return p2DataTaskService.call(1538, settingRateType);
            }
        };

        return service;
    }]);
})();