(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('metaTableViews', {
        templateUrl: 'views/components/views/metaTableViews/metaTableViews.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'metaTableViewsService', function ($stateParams, stateService, utilityService, metaTableViewsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                metaTableKeyno: $stateParams.metatable_keyno,
                itemsListMetaTableViews: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            metaTableViewsService.listMetaTableViews({ metatable_keyno: vm.model.metaTableKeyno }).then(function (data) {
            	angular.copy(data, vm.model.itemsListMetaTableViews);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'metatableview':
                        go({
                            metatable_keyno: vm.model.metaTableKeyno,
                            metatableview_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();