import { Injectable } from "@angular/core";
import { Buffer } from "buffer";

@Injectable({
    providedIn: 'root'
})
export class Base64UrlService {
    public Encode(value: string): string {
        return btoa(value)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }

    public Decode(value: string): string {
        const m = value.length % 4;

        return atob(
            value.replace(/-/g, '+')
                .replace(/_/g, '/')
                .padEnd(value.length + (m === 0 ? 0 : 4 - m), '=')
        );
    }
    public urlDecode(str: string):string {
        return Buffer.from(str, 'base64').toString('utf-8');
    }
    public urlEncode(str: string):string {
        return Buffer.from(str, 'utf-8').toString('base64');
    }
}
