(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('tailorAccessLang', {
        templateUrl: 'views/components/views/tailorAccessLang/tailorAccessLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'tailorAccessLangService', function ($stateParams, stateService, modalService, translateService, tailorAccessLangService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tailorAccessKeyNo = $stateParams.tailoraccess_keyno;

            let translations = {
                confirmation: '',
                error: '',
                ok: ''
            };

            vm.model = {
                inputListTailoredAccess: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadTailorAccessLanguages = function () {
                tailorAccessLangService.listTailorAccessLang({ tailoraccess_keyno: tailorAccessKeyNo }).then(function (result) {
                    angular.copy(result, vm.model.inputListTailoredAccess);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                tailorAccessLangService.saveObj({ records: vm.model.inputListTailoredAccess }).then(function (response) {
                    if (response[0].ws_result === '1') {
                    	modalService.show({
                    		type: 'success',
                    		title: translations.confirmation,
                            message: response[0].ws_result_message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-success',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTailorAccessLanguages();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
