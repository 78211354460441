(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("gisPositionService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadGisPositionGet: function (gisposition_keyno) {
                return p2DataTaskService.call(1286, {
                    gisposition_keyno: gisposition_keyno
                });
            },
            save: function (location) {
                return p2DataTaskService.call(1287, location);
            },
            delete: function (location) {
                return p2DataTaskService.call(1288, location);
            }
        };

        return service;
    }]);
})();