(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactCalcPickService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadStockSearchScan: function (functionname, barcode, stockplace_id) {
                return p2DataTaskService.call(455, {
                    functionname: functionname,
                    barcode: barcode,
                    stockplace_id: stockplace_id
                });
            }
        };

        return service;
    }]);
})();