(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("incomingCallService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadIncomingCallGet: function (phoneno) {
				return p2DataTaskService.call(1359, {
					phoneno: phoneno
				});
			},
			loadFindPersonList: function (searchtext) {
				return p2DataTaskService.call(1125, {
					searchtext: searchtext
				});
			}
		};

		return service;
	}]);
})();