import { forEach } from "angular";

(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("fileService", ['$q', '$injector', 'ieFileService', 'companyService', 'appConfig', function ($q, $injector, ieFileService, companyService, appConfig) {
        var methodProxy = function(fileService, methodService, method, params) {
            var deferred = $q.defer();
            
            params.url = params.url || companyService.fileServerUrl;
                
            methodService[method].apply(methodService, [params]).then(function(result) {
                deferred.resolve(result);
            });

            return deferred.promise;
        };

        var service = {
            hash: function (params) {
                return methodProxy(service, ieFileService, 'hash', params);
            },

            isConvertCompleted: function (params) {
                return methodProxy(service, ieFileService, 'isConvertCompleted', params);
            },

            upload: function (params) {
                return methodProxy(service, ieFileService, 'upload', params);
            },

            uploadFiles: function(params) {
                return methodProxy(service, ieFileService, 'uploadFiles', params);
            },

            getFileInfo: function (params) {
                var deferred = $q.defer();

                methodProxy(service, ieFileService, 'getFileInfo', params).then(function (response) {
                    angular.forEach(response.files, function (file, _) {
                        if (file.originalUrl) {
                            file.originalUrl = service.toFileServerUrl(file.originalUrl);
                        }

                        if (file.thumbUrl) {
                            file.thumbUrl = service.toFileServerUrl(file.thumbUrl);
                        }

                        if (file.url) {
                            file.url = service.toFileServerUrl(file.url);
                        }
                    });
                    
                    deferred.resolve(response);
                });

                return deferred.promise;
            },

            getFileList: function(params) {
                return methodProxy(service, ieFileService, 'getFileList', params);
            },

            getFileDetails: function (params) {
                var deferred = $q.defer();

                methodProxy(service, ieFileService, 'getFileDetails', params).then(function (response) {
                    // BJS 20240814 - Temporary rewrite of links to avoid having to update fileserver to get new links.
                    response.imageLink = service.toFileServerUrl(response.imageLink);
                    response.thumbnailLink = service.toFileServerUrl(response.thumbnailLink);
                    
                    deferred.resolve(response);
                });

                return deferred.promise;
            },

            toFileServerUrl: function (original) {
                let relativeUrl = service.toRelativeUrl(original, appConfig.url.fileServerProxyId);

                let delimiter = relativeUrl.indexOf('?') >= 0
                    ? '&'
                    : '?';

                return appConfig.url.fileServerUrl + relativeUrl + delimiter + 'csi=' + appConfig.clientSessionId;
            },

            toRelativeUrl: function (absoluteUrl, proxyId) {
                var index = absoluteUrl.indexOf(proxyId);

                let relativeUrl = absoluteUrl.substr(index + proxyId.length);

                return relativeUrl;
            },

            update: function(params) {
                return methodProxy(service, ieFileService, 'update', params);
            },

            delete: function(params) {
                return methodProxy(service, ieFileService, 'delete', params);
            },
            

            getTokenLink: function (imageLink) {
                return imageLink;
                //return ieFileService.getTokenLink(imageLink);
            },

            getTokenLinkAsync: function (imageLink) {
                return imageLink;
                //return ieFileService.getTokenLinkAsync(imageLink);
            },

            isImgContent: function (contentType) {
                return ieFileService.isImgContent(contentType);
            },

            isOfficeContent: function (contentType) {
                return ieFileService.isOfficeContent(contentType);
            },

            getFileNameSyntax: function (argtype, argvalue) {
                var deferred = $q.defer();

                var parms = {
                    method: 313,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                // Prosedyre sp_ws_p2_docarc_filename_syntax_get
                $injector.get('$ihttp').post(parms).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
