(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDeliverySelectPurchHead', {
		templateUrl: 'views/components/views/batchDeliverySelectPurchHead/batchDeliverySelectPurchHead.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'batchDeliverySelectPurchHeadService', function($stateParams, stateService, batchDeliverySelectPurchHeadService) {
			var vm = this;

            vm.model = {
                btnListSearchText: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                p2WBatchDeliverySelectPurchHeadGet: {},
                p2_batchdel_select_purchhead_list: {
                    records: []
                },
                showChecked: false
            };

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            vm.getP2WBatchDeliverySelectPurchHeadGet = function () {
                batchDeliverySelectPurchHeadService.genericFunction(820, {
                    supplier_no: $stateParams.supplier_no,
                    batchdelivery_keyno: $stateParams.batchdelivery_keyno
                }).then(function (data) {
                    console.log('data from db:');
                    console.dir(data);
                    vm.model.p2WBatchDeliverySelectPurchHeadGet = angular.copy(data[0]);
                });
            };

    	    vm.getP2WBatchDeliverySelectPurchHeadGet();

            batchDeliverySelectPurchHeadService.loadP2BatchDelSelectPurchHeadList($stateParams.supplier_no, $stateParams.batchdelivery_keyno).then(function () {
                vm.model.p2_batchdel_select_purchhead_list = batchDeliverySelectPurchHeadService.p2_batchdel_select_purchhead_list;
            });

            vm.btnListFuncSearchText = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.p2WBatchDeliverySelectPurchHeadGet.searchtext = '';
                        break;
                    default:
                        break;
                }
            };

            vm.toggleChosenPurchHead = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                    //item.item_glyphicon_color = 'green';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                    //item.item_glyphicon_color = 'black';
                }
            };

            vm.checkButtonClick = function () {
                if (!vm.model.showChecked) {
                    vm.model.showChecked = true;
                    angular.forEach(vm.model.p2_batchdel_select_purchhead_list.records, function (item) {
                        item.item_is_selected = '1';
                    });
                } else {
                    vm.model.showChecked = false;
                    angular.forEach(vm.model.p2_batchdel_select_purchhead_list.records, function (item) {
                        item.item_is_selected = '0';
                    });
                }
            };

            vm.chooseSelected = function () {
                //console.log('chooseSelected');
                var purch_internal_nos = '';
                var item_state = '';
                var item_parms = '';

                angular.forEach(vm.model.p2_batchdel_select_purchhead_list.records, function (item) {
                    //console.log('item:');
                    //console.dir(item);
                    if (item.item_is_selected == '1') {
                        if (purch_internal_nos != '') {
                            purch_internal_nos = purch_internal_nos + ',';
                        }
                        purch_internal_nos = purch_internal_nos + item.item_id;
                        item_state = item.item_state;
                        item_parms = item.item_parms;
                    }
                });

                if (purch_internal_nos != '') {
                    batchDeliverySelectPurchHeadService.remember('batchdeliverypurchhead.purch_internal_nos', purch_internal_nos).then(function (data) {
                        //console.log('ready to go:',item_state+'/'+item_parms);
                        stateService.go(item_state, item_parms);
                    });
                }

                //stateService.go('stockplanning',{},{ reload: true, inherit: false });
            };
		}]
	});
})();