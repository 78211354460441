import { Injectable } from '@angular/core';
import { ResponsiveService } from './responsive.service';

export interface ResponsiveChangedArgs {
    size: string
}

@Injectable({
  providedIn: 'root'
})
export class ResponsiveBridgeService {
    responsiveChanged = this.responsive.Current;

    constructor(private responsive: ResponsiveService) { }
}
