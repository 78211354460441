(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('labelPrint', {
        templateUrl: 'views/components/views/labelPrint/labelPrint.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'stateService', 'utilityService', 'consignmentPlansService', 'economyService', 'logisticService', 'rememberService', 'labelPrintService', function ($q, $stateParams, stateService, utilityService, consignmentPlansService, economyService, logisticService, rememberService, labelPrintService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
    		
            var resizing = false;
            var initialEdit = true;

            var variableNames = {
                selection: ''
            };

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchLabelPrints() }
                ],
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
    
                                    /* RESIZING COLUMNS */
                                    if (data.item_name === 'resize' && data.isActive) {
                                    	vm.resizeColumns();
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        //} else if (angular.isDefined(item)) {
                        //	for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                        //		if (vm.model.mconfig.records[r].item_name === item) {
                        //			vm.model.mconfig.records[r].isActive = false;
                        //			break;
                        //		}
                        //	}
                        } else {
                            vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            if (initialEdit === true) {
                                item.isActive = !item.isActive;
                                initialEdit = null;
                            }
    
                            item.isActive = !item.isActive;
                        }
    
                        /* SORTING ROWS */
                        //if (item === 'deactivate') {
                        //	vm.model.mconfig.records[4].isActive = false;
                        //}
    
                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                        	if (item.isActive) {
                        		resizing = true;
                        	} else {
                        		vm.resizeColumns();
                        	}
                        }
    
                        /* EDITING COLUMNS */
                        //if (item.item_name === 'edit') {
                        //	if (item.isActive) {
                        //		editing = true; //var editing = false;
                        //	} else {
                        //		vm.saveChanges();
                        //	}
                        //}
    
                        /* OPEN & CLOSE TOOLBAR */
                        //if (item.item_id === '0') {
                        //	if (item.isActive === true) {
                        //		vm.model.mconfig.records[1].show = true;
                        //		vm.model.mconfig.records[2].show = true;
                        //	} else {
                        //		vm.model.mconfig.records[1].show = false;
                        //		vm.model.mconfig.records[2].show = false;
                        //	}
                        //}
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', enabled: true, show: false },
                        { item_id: '1', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '2', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '4', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '5', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '6', item_name: 'edit', glyph: 'fa-edit', color: 'primary', canActive: true, isActive: true, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                setting: {},
                selectBusinessCompanies: [],
                selectPriceLists: [],
                selectListStockPlaces: [],
                itemsListLabelPrints: []
            };
            
            // ## LOAD PROCEDURE CALLS ##
    
            labelPrintService.loadLabelPrintGet(vm.model.argType, vm.model.argValue).then(function (data) {
                angular.copy(data[0], vm.model.setting);
    
                loadStockPlaces();
                loadLabelPrints();
            });
    
            logisticService.listCompanies().then(function (data) {
                angular.copy(data, vm.model.selectBusinessCompanies);
            });
    
            economyService.listPrices().then(function (data) {
                angular.copy(data, vm.model.selectPriceLists);
            });
    
            var loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                logisticService.listStockPlaces(vm.model.setting.businessco_no).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };
    
            var loadLabelPrints = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.pricelist_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.pricelist_id2, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.only_price_changed) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.only_on_stock) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListLabelPrints = [];
                
                labelPrintService.loadLabelPrintList(vm.model.setting).then(function (data) {
                    angular.copy(data, vm.model.itemsListLabelPrints);
                });
            };
    
            var itemIdIsValid = function (id) {
                if (id === 0 || id === "0")
                    return false;
                else
                    return true;
            };
    
            var labelsToPrint = function () {
                var tempList = [];
                angular.forEach(vm.model.itemsListLabelPrints, function (item) {
                    if (angular.isDefined(item) && itemIdIsValid(item.item_id)) {
                        if (angular.isDefined(item.item_secondary) && item.item_secondary.length > 0 && angular.isDefined(item.item_secondary[1])) {
                            if (isNumber(item.item_secondary[1].item_col) && parseInt(item.item_secondary[1].item_col) > 0) {
                                tempList.push({ item_id: item.item_id, item_is: item.item_is, qty: item.item_secondary[1].item_col, price: item.item_secondary[2].item_col });
                                //console.log("item_id: " + item.item_id + " what: " + item.item_is + " qty: " + item.item_secondary[1].item_col);
                            }
                        }
                    }
                });
                return tempList;
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
            
            var rememberFunc = function (id) {
                var deferred = $q.defer();
    
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
    
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
    
                var variableValue = null;
    
                switch (id) {
                    case 'selection':
                        var tempList = labelsToPrint();
                        //tempList.push(item.item_id + ";" + item.item_is + ";" + item.item_secondary[1].item_col + ";");
                        variableValue = "";
                        for (var i = 0; i < tempList.length; i++) {
                            if (i > 0) {
                                variableValue += "|";
                                //variableValue += "---";
                                //variableValue += "%0D%0A";
                                //variableValue += "\r\n";
                                //variableValue += "\\n";
                            }
                            variableValue += tempList[i].item_id + ";" + tempList[i].item_is + ";" + tempList[i].qty + ";" + tempList[i].price;
                        }
                        break;
                    default:
                        break;
                }
    
                rememberService.remember(variableNames[id], variableValue).then(function () {
                    deferred.resolve();
                });
    
                return deferred.promise;
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') loadLabelPrints();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ## BUTTON LIST FUNCTIONS ##
            
            vm.searchLabelPrints = function () {
                loadLabelPrints();
            };
    
            // ## RESIZE COLUMN WIDTH FUNCTION PROCEDURE CALLS ##
    
            vm.resizeColumns = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(vm.model.itemsListLabelPrints[0]) && angular.isDefined(vm.model.itemsListLabelPrints[0].item_secondary)) {
                    for (var r = 0; r < vm.model.itemsListLabelPrints[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.itemsListLabelPrints[0].item_col_width });
                        }
    
                        if (angular.isDefined(vm.model.itemsListLabelPrints[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.itemsListLabelPrints[0].item_secondary[r].item_col_width });
                        }
                    }
                }
    
                var updateParms = {
                    view_id: vm.model.setting.view_id, //'w_' + stateService.getCurrentName(),
                    records: updateList
                };
    
                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning');
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
    
                    resizing = false;
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectreport':
                        rememberFunc('selection').then(function () {
                            go({
                                webpage_name: 'labelprint',
                                argtype: 'portal_user_variable',
                                argvalue: 'w_labelprint.selection'
                            });
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        loadStockPlaces();
                        loadLabelPrints();
                        break;
                    case 'stockplace_id':
                    case 'pricelist_id':
                    case 'pricelist_id2':
                    case 'only_price_changed':
                    case 'only_on_stock':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        loadLabelPrints();
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') loadLabelPrints();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') loadLabelPrints();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // ## OTHER FUNCTIONS ##
    
            function isNumber(n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            }
            
        }]
    });
})();
