(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("salaryTypeLangService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            dataSource: {
                records: []
            },
            loadTranslations: function (salarytype_id) {
                var deferred = $q.defer();

                var parms_loadTranslations = {
                    method: 485,
                    parameters: {
                        salarytype_id: salarytype_id
                    }
                };

                $ihttp.post(parms_loadTranslations).then(function (data) {
                    service.dataSource.records = [];
                    angular.copy(data, service.dataSource.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveTranslations: function (dataSource) {
                var deferred = $q.defer();

                var parms_saveTranslations = {
                    method: 486,
                    parameters: dataSource
                };

                $ihttp.post(parms_saveTranslations).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();