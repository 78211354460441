import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { WidgetService } from './widget.service';
import { Item } from '@app/core/components/listbox/listbox.component';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { SearchItem } from '@app/core/components/search/search.component';
import { GridOptions } from '@app/core/components/grid/grid.types';
import { IdportenService } from '../../../services/idporten.service';
import { PdfService } from '@app/services/pdf.service';
import { Base64UrlService } from '@app/services/base64url.service';
import { IRowNode } from '@ag-grid-community/core';
import { StateService } from '@app/core/services/state.service';
import { Grid } from '@app/core/components/grid/grid.model';
import { WidgetTextConfig, WidgetTextLine } from './widget-text/widget-text.component';
import { WidgetConfig } from './dashboard/dashboard.component';

@Component({
    selector: 'tt-jlr-test',
    templateUrl: './jlr-test.component.html',
    styleUrls: ['./jlr-test.component.css'],
})
export class JlrTestComponent {
    loginIdPorten() {
        this.idporten.login();
    }

    logoutIdPorten() {
        this.idporten.logout();
    }

    getUser() {
        console.dir(this.idporten.getUser()?.access_token);
    }

    date = '2024-01-02 08:30';
    columns = 16;
    rows = 12;
    rowHeight = 8;
    customerSearchtext = '';
    textValue = 'hel';
    text1 = 1000;
    text2 = 1000;
    text3 = 1000;
    text4 = 1000;
    editMode = false;
    check = '1';
    check2 = 'true';
    textarea = 'hei';
    selectedTab = '';

    files: File[] = [new File([], 'test.jpg'), new File([], 'test2.jpg'), new File([], 'test4.jpg')];

    onTabChange(event: string) {
        console.log('changed to: ', event);
    }

    log(value: any) {
        // const params = {
        //     argtype: 'custact_no',
        //     argvalue: '623098',
        //     // records: ['su'],
        // };

        // this.state.go('documents', params);

        console.dir(value);
    }

    tabs = [
        { id: 'EDIT', icon: 'far fa-edit', label: 'edit_customer', color: 'primary', onClick: () => console.log('EDIT') },
        { id: 'CONTACTS', icon: 'far fa-users', label: 'customer_persons', color: 'primary', onClick: () => console.log('CONTACTS') },
        { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', color: 'primary', onClick: () => console.log('EMAIL') },
        { id: 'PROJECTS', icon: 'far fa-tasks-alt', label: 'projects', color: 'primary', onClick: () => console.log('PROJECTS') },
        // { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'activities', color: 'primary', onClick: () => console.log('CUSTACTS') },
        // { id: 'ORDERS', icon: 'far fa-box', label: 'quotation_and_orders', color: 'primary', onClick: () => console.log('ORDERS') },
        // { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary', onClick: () => console.log('DOCUMENTS') },
        // { id: 'POWTIMES', icon: 'far fa-clock', label: 'customer_powtimes', color: 'primary', onClick: () => console.log('POWTIMES') },
        // { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary', onClick: () => console.log('FUNCTIONS') },
    ];

    data: SearchItem[] = [
        { item_id: '1', text: 'hello', item_glyphicon: '', item_glyphicon_color: '' },
        { item_id: '1', text: 'hello2', item_glyphicon: '', item_glyphicon_color: '' },
        { item_id: '1', text: 'hello3', item_glyphicon: '', item_glyphicon_color: '' },
        { item_id: '1', text: 'hello23', item_glyphicon: '', item_glyphicon_color: '' },
    ];

    selectData = [
        { id: '1', name: 'tes t', selected: '1' },
        { id: '2', name: 'test 2 ', selected: '0' },
        { id: '3', name: 'test 2', selected: '0' },
        { id: '4', name: 'hello test', selected: '0' },
    ];

    onListboxSelect(event: Item) {
        console.dir(event);
    }

    onClear() {
        console.log('********* input cleared **********');
        console.log(this.customerSearchtext);
    }

    search: () => Promise<Item[]> = () => {
        return this.datataskService.Post(56, {
            cust_name: this.customerSearchtext,
            custtype: 'C',
            filtertype: '',
            filtervalue: '',
            lastselect: 'SEARCH',
        });
    };

    formButtons: FormButton[] = [
        {
            id: 'sendemail',
            icon: 'fas fa-envelope',
            type: 'primary',
            onClick: ({ event, buttonId, options }) => {
                console.log('event :>> ', event);
                console.log('button :>> ', buttonId);
                console.log('options :>> ', options);
                console.log('sendemail');
                alert('Event: \n' + JSON.stringify(event, null, 2) + '\n\n Button: \n' + JSON.stringify(buttonId, null, 2) + '\n\n Parameters: \n' + JSON.stringify(options, null, 2));
            },
        },
        {
            id: 'refresh',
            icon: 'fas fa-sync',
            type: 'primary',
            onClick: () => alert('2'),
        },
    ];

    hideButton: boolean | 'hidden' = false;

    // acsupptranses = {
    //     dataTask: {
    //         rememberId: 'w_acsupptranses_grid',
    //         loadSetupId: null,
    //         loadData: {},
    //         addRow: {
    //             method: null,
    //             parameters: null,
    //             autoSave: false,
    //             confirm: false,
    //         },
    //         removeRow: {
    //             method: null,
    //             parameters: null,
    //             autoSave: false,
    //             confirm: false,
    //         },
    //         saveData: {
    //             method: null,
    //             parameters: null,
    //             autoSave: false,
    //             confirm: false,
    //         },
    //     },
    //     translations: [],
    //     config: {
    //         editColumns: [
    //             //{ key: 'test_column' }
    //             //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
    //         ],
    //         toolbar: {
    //             hidden: false,
    //             pdfExport: true,
    //             excelExport: true,
    //             filter: true,
    //             columnVisibility: true,
    //             headers: true,
    //             edit: false,
    //             lock: false,
    //             add: false,
    //             delete: false,
    //             save: false,
    //             wrapping: true,
    //             layouts: true,
    //             buttons: [
    //                 //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
    //                 //{ name: 'test', text: '', func: function () { testItems(); } },
    //                 //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
    //                 //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
    //                 //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
    //                 //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
    //             ],
    //         },
    //         fixedHeader: true,
    //         keepSortOnAdd: false,
    //         keepSortOnCheckbox: false,
    //         keepSortOnIsSelected: false,
    //         rowClick: false,
    //         //navigation: {
    //         //	altNav: true,
    //         //	newLine: true
    //         //},
    //         specialFunc: {
    //             newTab: true,
    //             buttons: [
    //                 //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
    //             ],
    //         },
    //         css: {
    //             altColor: true,
    //             textWrapping: false,
    //         },
    //         onDataSourceChanges: function () {
    //             //console.log('onDataSourceChanges');
    //             //console.dir(e);
    //         },
    //         onDataChanged: function () {
    //             //console.log('onDataChanged');
    //             //console.dir(e);
    //         },
    //     },
    //     kendo: {
    //         height: null,
    //         sortable: true,
    //         aggregate: true,
    //         pager: false,
    //         selectable: false, //'multiple, row'
    //         //persistSelection: false,
    //         filterable: false,
    //     },
    //     // optionfunc: functions (data) { optionfunc(data.data); },
    //     gridfunc: null,
    // };

    invoices: GridOptions = {
        dataTask: {
            rememberId: 'invoicesComponentGrid',
            loadData: {
                method: 2369,
                parameters: {
                    argtype: null,
                    argvalue: null,
                    businesscoNo: '99999',
                    dateFrom: '2023-01-01',
                    dateTo: '2023-12-31',
                    statusId: 10,
                },
            },
        },
        config: {
            toolbar: {
                hidden: true,
                rebind: true,
            },
            serverSideHandling: true,
        },
    };

    stockMinMaxAdjustGrid: GridOptions = {
        dataTask: {
            // rememberId: 'w_labours.grid',
            loadData: {
                method: 2212,
                parameters: {
                    stock_keyno: '6076300',
                    p2_datatask_keyno: '2212',
                },
            },
        },
        config: {
            serverSideHandling: false,
            editColumns: [
                { key: 'stock_minimum', clickonly: true },
                { key: 'stock_maximum' },
                { key: 'stock_std_orderquantity' },
                { key: 'is_selected' },
                //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
            ],
            specialFunc: {
                edit: true,
            },
        },
    };

    laboursGrid: GridOptions = {
        dataTask: {
            rememberId: 'w_labours.grid',
            loadData: {
                method: 2492,
                parameters: {
                    searchtext: '',
                    gb_labourtype: 'INTERNAL',
                    activestatus: 'ACT',
                    show_setting: '1',
                    p2_datatask_keyno: '2492',
                },
            },
        },
        config: {
            editColumns: [],
            specialFunc: {
                edit: true,
            },
        },
    };

    gridOrderlineBackorders: GridOptions = {
        dataTask: {
            rememberId: 'w_orderlineBackorders.grid',
            loadData: {
                method: 3058,
                parameters: {
                    date_fom: '',
                    date_tom: '',
                    p2_datatask_keyno: 3058,
                },
            },
        },
        config: {
            toolbar: {
                buttons: [
                    {
                        name: 'select_entire_order',
                        text: 'select_entire_order',
                        func: function () {
                            alert('here');
                            // selectEntireOrder();
                        },
                        icon: 'check',
                        cssClass: 'btn btn-primary im-grid-btn-xs-r',
                        translate: true,
                        disabled: function () {
                            return false;
                        },
                    },
                ],
            },

            css: {
                textWrapping: false,
                altColor: true,
            },
        },
    };

    acsupptranses: GridOptions = {
        dataTask: {
            rememberId: 'w_acsupptranses_grid',
            loadData: {
                method: 2792,
                parameters: {
                    businessco_no: '200',
                    date_fom: '2023-01-01',
                    date_tom: '2023-12-30',
                    dateselector_index: '6',
                    date_fomtom: '2023-01-012023-12-30',
                    openpost: '0',
                    acc_supp_no: '0',
                    show_account_saldo: '0',
                    page_heading: 'Hele leverandørreskontroen',
                    p2_datatask_keyno: 2792,
                },
            },
            saveData: {
                method: null,
                parameters: null,
                autoSave: false,
                single: false,
            },
        },
        config: {
            css: {
                textWrapping: false,
                altColor: true,
            },
        },
        kendo: {
            height: '500px',
            aggregate: true,
        },
    };

    payouts: GridOptions = {
        dataTask: {
            rememberId: 'w_payouts_payout_grid',
            loadData: {
                method: 2835,
                parameters: {
                    businessco_no: '200',
                    bg_payouts_main: 'PAYOUT',
                    p2_datatask_keyno: 2835,
                },
            },
            saveData: {
                method: null,
                parameters: null,
                autoSave: false,
                single: false,
            },
        },
        config: {
            specialFunc: {
                edit: true,
            },
            toolbar: {
                wrapping: true,
            },
            css: {
                textWrapping: false,
                altColor: true,
            },
        },
        kendo: {
            aggregate: true,
        },
    };

    gridxexport: GridOptions = {
        dataTask: {
            rememberId: 'w_gridexportart.grid_5',
            loadData: {
                method: 1863,
                parameters: {
                    p2_exportproc_keyno: '5',
                    p2_datatask_keyno: '1863',
                },
            },
            saveData: {
                method: null,
                parameters: null,
                single: true,
            },
        },
        config: {
            toolbar: {
                wrapping: true,
                rebind: true,
            },
            specialFunc: {
                newTab: true,
                buttons: [],
            },
            css: {
                textWrapping: true,
                altColor: true,
            },
        },
        kendo: {
            // height: '150px',
            aggregate: true,
        },
        optionfunc: (data: any) => console.log(data),
    };

    activityManagementGrid: GridOptions = {
        dataTask: {
            rememberId: 'w_gridexportart.grid_5',
            loadData: {
                method: 2867,
                parameters: {
                    p2_datatask_keyno: 2867,
                },
            },
        },
        config: {
            css: {
                textWrapping: false,
                altColor: true,
            },
        },
    };

    grid: GridOptions = {
        dataTask: {
            rememberId: 'w_powtimes.grid',
            loadData: {
                method: 2127,
                parameters: {
                    businessco_no: '200',
                    show_setting: '1',
                    argtype: '-',
                    argvalue: '-',
                    date_fom: '2024-01-01',
                    date_tom: '2024-12-31',
                    dateselector_index: '6',
                    view_id: 'w_powtimes.grid',
                    p2_datatask_keyno: '2127',
                    date_fomtom: '2024-01-012024-12-31',
                    labour_no: '0',
                    cust_no: '0',
                    cust_name: '',
                    project_keyno: '0',
                    project_name: '',
                },
            },
        },
        kendo: {
            height: '500px',
        },
    };

    powtimesGrid: GridOptions = {
        dataTask: {
            rememberId: 'w_powtimes.grid',
            loadSetupId: null,
            loadData: {
                method: 2127,
                parameters: {
                    businessco_no: '200',
                    show_setting: '1',
                    argtype: '-',
                    argvalue: '-',
                    date_fom: '2024-01-01',
                    date_tom: '2024-12-31',
                    dateselector_index: '6',
                    view_id: 'w_powtimes.grid',
                    p2_datatask_keyno: '2127',
                    date_fomtom: '2024-01-012024-12-31',
                    labour_no: '0',
                    cust_no: '0',
                    cust_name: '',
                    project_keyno: '0',
                    project_name: '',
                },
            },
        },
        translations: [],
        config: {
            toolbar: {
                hidden: false,
                pdfExport: true,
                excelExport: true,
                filter: true,
                columnVisibility: true,
                headers: true,
                edit: false,
                lock: false,
                add: false,
                delete: false,
                save: false,
                wrapping: true,
                layouts: true,
                refresh: false,
                read: false,
                rebind: false,
                buttons: [],
            },
            fixedHeader: true,
            keepSortOnAdd: false,
            keepSortOnCheckbox: false,
            keepSortOnIsSelected: false,
            rowClick: false,
            specialFunc: {
                newTab: true,
                buttons: [],
            },
            css: {
                altColor: true,
                textWrapping: false,
            },
        },
        kendo: {
            height: '500px',
            // aggregate: true,
            aggregate: [
                { field: 'amount_locval', aggregate: 'average' },
                { field: 'break_minutes', aggregate: 'max' },
                { field: 'offdutycode_name', aggregate: 'count' },
                { field: 'po_keyno', aggregate: 'sum' },
                { field: 'salaryhours', aggregate: 'sum' },
            ],
            pager: false,
            selectable: false,
            filterable: true,
        },
        optionfunc: null,
        gridfunc: null,
    };

    accountingprocessingGrid = new Grid()
        .setRememberId('w_accountingprocessing.accountgrid')
        .setLoadDataFunction({
            method: 2089,
            parameters: {
                custact_no: '623409',
                custactac_keyno: '127',
            },
        })
        .setSaveDataFunction({ method: null, parameters: { custactac_keyno: '127' } })
        .setAddRowFunction({ method: null, parameters: { custactac_keyno: '127' } })
        .setToolbar({ pdfExport: false, excelExport: false, add: true, rebind: true, delete: true, save: true })
        .setCustomToolbarButtons([
            { name: 'import_voucherlines', text: 'w_accountingprocessing_import_voucherlines', func: () => alert('yolo') },
            { name: 'custactac_match', text: 'custactac_match', func: () => alert('yolo again') },
        ])
        .setSpecialFunc({ edit: true, newTab: true })
        .setNavigation({ newLine: true, altNav: true })
        .setKendoConfig({ height: '800px' })
        .build();

    // accountingprocessingGrid: GridOptions = {
    //     dataTask: {
    //         rememberId: 'w_accountingprocessing.accountgrid',
    //         loadSetupId: null,
    //         loadData: {
    //             method: 2089,
    //             parameters: {
    //                 custact_no: '623409',
    //                 custactac_keyno: '127',
    //             },
    //         },
    //         saveData: {
    //             method: null,
    //             parameters: {
    //                 custactac_keyno: '127',
    //             },
    //         },
    //         addRow: {
    //             method: null,
    //             parameters: {
    //                 custactac_keyno: '127',
    //             },
    //             confirm: false,
    //             // openEdit: true,
    //             // pre: (row) => alert('before add row\n ' + JSON.stringify(row, null, 2)),
    //             // post: (row) => alert('after add row\n ' + JSON.stringify(row, null, 2)),
    //         },
    //     },
    //     translations: [],
    //     config: {
    //         toolbar: {
    //             hidden: false,
    //             pdfExport: false,
    //             excelExport: false,
    //             add: true,
    //             rebind: true,
    //             delete: true,
    //             save: true,
    //             buttons: [
    //                 {
    //                     name: 'import_voucherlines',
    //                     text: 'w_accountingprocessing_import_voucherlines',
    //                     func: () => alert('yoyo'),
    //                     icon: 'fal fa-file-import',
    //                     cssClass: 'tt-button tt-button--secondary im-grid-btn-xs-r',
    //                     translate: true,
    //                     disabled: () => this.hideButton,
    //                     // disabled: () => (vm.model.custact.acvoucherbunt_keyno > '0' ? 'hidden' : false),
    //                 },
    //                 {
    //                     name: 'custactac_match',
    //                     text: 'custactac_match',
    //                     func: () => alert('yo'),
    //                     icon: 'fal fa-plus',
    //                     cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
    //                     translate: true,
    //                     disabled: () => this.hideButton,
    //                     // disabled: () => (vm.model.custact.acvouchertype_id !== 'IF' || vm.model.get.show_purchase_match !== '1' ? 'hidden' : false),
    //                 },
    //                 {
    //                     name: 'import_voucherlines',
    //                     text: 'w_accountingprocessing_import_voucherlines',
    //                     func: () => alert('yoyo'),
    //                     icon: 'fal fa-file-import',
    //                     cssClass: 'tt-button tt-button--secondary im-grid-btn-xs-r',
    //                     translate: true,
    //                     disabled: () => this.hideButton,
    //                     // disabled: () => (vm.model.custact.acvoucherbunt_keyno > '0' ? 'hidden' : false),
    //                 },
    //                 {
    //                     name: 'custactac_match',
    //                     text: 'custactac_match',
    //                     func: () => alert('yo'),
    //                     icon: 'fal fa-plus',
    //                     cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
    //                     translate: true,
    //                     disabled: () => this.hideButton,
    //                     // disabled: () => (vm.model.custact.acvouchertype_id !== 'IF' || vm.model.get.show_purchase_match !== '1' ? 'hidden' : false),
    //                 },
    //             ],
    //         },

    //         fixedHeader: true,
    //         keepSortOnAdd: false,
    //         keepSortOnCheckbox: false,
    //         keepSortOnIsSelected: false,
    //         rowClick: false,
    //         shortcuts: true,
    //         specialFunc: {
    //             edit: true,
    //             newTab: true,
    //             buttons: [],
    //         },
    //         navigation: {
    //             altNav: true,
    //             newLine: true,
    //         },
    //         css: {
    //             altColor: true,
    //             textWrapping: false,
    //         },
    //     },
    //     kendo: {
    //         // height: 150,
    //         // aggregate: true,
    //         aggregate: false,
    //         pager: false,
    //         selectable: 'multiple',
    //         filterable: false,
    //     },
    //     optionfunc: (data: any) => console.log(data),
    //     gridfunc: null,
    // };

    accountingPlgrid: GridOptions = {
        dataTask: {
            rememberId: 'w_accountingpl_std.grid',
            loadSetupId: null,
            loadData: {
                method: 2810,
                parameters: {
                    businessco_no: '200',
                    date_fom: '1901-01-01',
                    date_tom: '2999-12-31',
                    dateselector_index: '8',
                    date_fomtom: '1901-01-012999-12-31',
                    acreportlayout_keyno: '1',
                    show_accounts: '1',
                    show_12months: '0',
                    grid_std_p2_datatask_keyno: '2810',
                    grid_std_view_id: 'w_accountingpl_std.grid',
                    grid_12m_p2_datatask_keyno: '2811',
                    grid_12m_view_id: 'w_accountingpl_12m.grid',
                    accounting_year: '2024',
                    project_keynos: '',
                    department_ids: '',
                    equipment_ids: '',
                    labour_nos: '',
                    p2_datatask_keyno: '2811',
                },
            },
            addRow: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
            },
            removeRow: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
            },
            saveData: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
            },
        },
        translations: [],
        config: {
            toolbar: {
                hidden: false,
                pdfExport: true,
                excelExport: true,
                filter: true,
                columnVisibility: true,
                headers: true,
                edit: false,
                lock: false,
                add: false,
                delete: false,
                save: false,
                wrapping: true,
                layouts: true,
                buttons: [
                    //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                    //{ name: 'test', text: '', func: function () { testItems(); } },
                    //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                    //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                    //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                    //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                ],
            },
            fixedHeader: true,
            keepSortOnAdd: false,
            keepSortOnCheckbox: false,
            keepSortOnIsSelected: false,
            rowClick: false,
            //navigation: {
            //	altNav: true,
            //	newLine: true
            //},
            specialFunc: {
                newTab: true,
                buttons: [
                    //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                ],
            },
            css: {
                altColor: true,
                textWrapping: false,
            },
        },
        kendo: {
            height: null,
            sortable: true,
            aggregate: true,
            pager: false,
            selectable: false, //'multiple, row'
            //persistSelection: false,
            filterable: false,
        },
        optionfunc: (data: any) => console.log(data.data),
        gridfunc: null,
    };

    activityGrid: GridOptions = {
        dataTask: {
            rememberId: 'w_activitygrid.grid',
            loadSetupId: null,
            loadData: {
                method: 2452,
                parameters: {
                    cust_no: '0',
                    cust_name: '',
                    ok: '0',
                    argtype: '-',
                    argvalue: '-',
                    p2_datatask_keyno: '2452',
                    view_id: 'w_activitygrid.grid',
                    businessco_no: '200',
                },
            },
            addRow: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
            },
            removeRow: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
            },
            saveData: {
                method: null,
                parameters: null,
                autoSave: false,
                confirm: false,
            },
        },
        translations: [],
        config: {
            toolbar: {
                hidden: false,
                pdfExport: true,
                excelExport: true,
                filter: true,
                columnVisibility: true,
                headers: true,
                edit: false,
                lock: false,
                add: false,
                delete: false,
                save: false,
                wrapping: true,
                layouts: true,
                buttons: [
                    //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                    //{ name: 'test', text: '', func: function () { testItems(); } },
                    //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                    //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                    //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                    //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                ],
            },
            fixedHeader: true,
            keepSortOnAdd: false,
            keepSortOnCheckbox: false,
            keepSortOnIsSelected: false,
            rowClick: false,
            //navigation: {
            //	altNav: true,
            //	newLine: true
            //},
            specialFunc: {
                newTab: true,
                buttons: [
                    //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                ],
            },
            css: {
                altColor: true,
                textWrapping: false,
            },
        },
        kendo: {
            height: null,
            sortable: true,
            aggregate: true,
            pager: false,
            selectable: false, //'multiple, row'
            //persistSelection: false,
            filterable: false,
        },
        optionfunc: null,
        gridfunc: null,
    };
    // columns = 16;
    // rows = 12;
    // rowHeight = 8;
    // customerSearchtext = '';
    // textValue = 'hel';
    // text1 = 1000;
    // text2 = 1000;
    // text3 = 1000;
    // text4 = 1000;
    // editMode = false;
    // check = '1';
    // check2 = 'true';

    // selectedTab = '';

    // onTabChange(event: string) {
    //     console.log('changed to: ', event);
    // }

    // log(value: any) {
    //     console.dir(value);
    // }

    // tabs = [
    //     { id: 'EDIT', icon: 'far fa-edit', label: 'edit_customer', color: 'primary', onClick: () => console.log('EDIT') },
    //     { id: 'CONTACTS', icon: 'far fa-users', label: 'customer_persons', color: 'primary', onClick: () => console.log('CONTACTS') },
    //     { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', color: 'primary', onClick: () => console.log('EMAIL') },
    //     { id: 'PROJECTS', icon: 'far fa-tasks-alt', label: 'projects', color: 'primary', onClick: () => console.log('PROJECTS') },
    //     // { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'activities', color: 'primary', onClick: () => console.log('CUSTACTS') },
    //     // { id: 'ORDERS', icon: 'far fa-box', label: 'quotation_and_orders', color: 'primary', onClick: () => console.log('ORDERS') },
    //     // { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary', onClick: () => console.log('DOCUMENTS') },
    //     // { id: 'POWTIMES', icon: 'far fa-clock', label: 'customer_powtimes', color: 'primary', onClick: () => console.log('POWTIMES') },
    //     // { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary', onClick: () => console.log('FUNCTIONS') },
    // ];

    // data: SearchItem[] = [
    //     { item_id: '1', text: 'hello', item_glyphicon: '', item_glyphicon_color: '' },
    //     { item_id: '1', text: 'hello2', item_glyphicon: '', item_glyphicon_color: '' },
    //     { item_id: '1', text: 'hello3', item_glyphicon: '', item_glyphicon_color: '' },
    //     { item_id: '1', text: 'hello23', item_glyphicon: '', item_glyphicon_color: '' },
    // ];

    // selectData = [
    //     { id: '1', name: 'tes t', selected: '1' },
    //     { id: '2', name: 'test 2 ', selected: '0' },
    //     { id: '3', name: 'test 2', selected: '0' },
    //     { id: '4', name: 'hello test', selected: '0' },
    // ];

    // onListboxSelect(event: SearchItem) {
    //     console.dir(event);
    // }

    // onClear() {
    //     console.log('********* input cleared **********');
    //     console.log(this.customerSearchtext);
    // }

    // search: () => Promise<Item[]> = () => {
    //     return this.datataskService.Post(56, {
    //         cust_name: this.customerSearchtext,
    //         custtype: 'C',
    //         filtertype: '',
    //         filtervalue: '',
    //         lastselect: 'SEARCH',
    //     });
    // };

    // formButtons: FormButton[] = [
    //     {
    //         id: 'sendemail',
    //         icon: 'fas fa-envelope',
    //         type: 'primary',
    //         onClick: ({ event, buttonId, options }) => {
    //             console.log('event :>> ', event);
    //             console.log('button :>> ', buttonId);
    //             console.log('options :>> ', options);
    //             console.log('sendemail');
    //             alert('Event: \n' + JSON.stringify(event, null, 2) + '\n\n Button: \n' + JSON.stringify(buttonId, null, 2) + '\n\n Parameters: \n' + JSON.stringify(options, null, 2));
    //         },
    //     },
    //     {
    //         id: 'refresh',
    //         icon: 'fas fa-sync',
    //         type: 'primary',
    //         onClick: () => alert('2'),
    //     },
    //     {
    //         id: 'gotodoc',
    //         icon: 'fas fa-search',
    //         type: 'primary',
    //         onClick: () => alert('3'),
    //     },
    //     {
    //         id: 'gotodocarc',
    //         icon: 'fas fa-chevron-right',
    //         type: 'primary',
    //         onClick: () => alert('4'),
    //     },
    // ];
    // variables = {
    //     testVariable: 'HEIHEI',
    //     testVariable_2: 'HeiPåDeg!',
    // };

    newVariables: { [key: string]: WidgetVariable | WidgetNumberVariable } = {
        testVariable: {
            id: crypto.randomUUID(),
            value: 'HEIHEI',
            type: 'text',
        },
        testVariable_2: {
            id: crypto.randomUUID(),
            value: 5320000,
            type: 'number',
            format: 'thousand-separator',
            displayUnits: 'none',
            decimals: 2,
        },
    };

    spin = true;

    logStuff() {
        // console.log('allrows :>> ', this.payouts.gridfunc?.getRows());
        // console.log('dirty rows :>> ', this.payouts.gridfunc?.getRows(true, false));
        // console.log('dirty filtered rows :>> ', this.payouts.gridfunc?.getRows(true, true));
        // console.log('dirty filtered sorted rows :>> ', this.payouts.gridfunc?.getRows(true, true, true));
        // console.log('filtered sorted rows :>> ', this.payouts.gridfunc?.getRows(false, true, true));
        // console.log('dirty sorted rows :>> ', this.payouts.gridfunc?.getRows(true, false, true));
        // console.log('sorted rows :>> ', this.payouts.gridfunc?.getRows(false, false, true));
        // console.log(this.gridxexport.gridfunc?.getSelectedRow());
        // console.log(this.gridxexport.gridfunc?.getSelectedRows());
        // this.payouts.gridfunc?.saveChanges(false);
        this.accountingprocessingGrid.gridfunc!.getGridApi().getDisplayedRowAtIndex(0)!.data['note'] = 'Testing updaterow';
        this.accountingprocessingGrid.gridfunc?.updateRow(null, 0);
    }

    constructor(public widgetService: WidgetService, private cdr: ChangeDetectorRef, public datataskService: DataTaskService, private idporten: IdportenService, private pdfService: PdfService, base64Service: Base64UrlService, public state: StateService) {
        // try {
        //     pdfService.convertHTMLToPDF('<h1>Party-triks</h1> <a href="#next">go to next</a> <div style="break-after:page"></div> <p id="next">next</p>').then((result) => {
        //         try {
        //             console.log('result :>> ', result);
        //             // console.log(this._arrayBufferToBase64(result));
        //         } catch (error) {
        //             console.log('error :>> ', error);
        //         }
        //     });
        // } catch (error) {
        //     console.log('error :>> ', error);
        // }

        setTimeout(async () => {
            console.log('set focus');

            try {
                console.log(this.accountingprocessingGrid.gridfunc?.getGridColumns());
                console.log(this.accountingprocessingGrid.gridfunc?.getDataItem());
                console.log(this.accountingprocessingGrid.gridfunc?.getDirtyRows());
                // this.accountingprocessingGrid.gridfunc?.getGridApi().setGridOption('rowData', []);
                // console.log(this.accountingprocessingGrid.gridfunc?.getRows(true));
                // this.accountingprocessingGrid.gridfunc?.callPopupTable([this.accountingprocessingGrid!.gridfunc!.getRowAt(0)?.data, this.accountingprocessingGrid!.gridfunc!.getRowAt(1)?.data]);
                // this.stockMinMaxAdjustGrid.gridfunc?.editCell(2, 7);
                // this.stockMinMaxAdjustGrid.gridfunc?.clearFilter();
                // this.stockMinMaxAdjustGrid.gridfunc?.clearSorting();
                // console.log(this.stockMinMaxAdjustGrid.gridfunc?.getDataItems());
                // await this.stockMinMaxAdjustGrid.gridfunc?.addRowBefore(5, { stockplace_name: 'new test' });
                // setTimeout(() => {
                //     this.stockMinMaxAdjustGrid.gridfunc?.addRowAfter(6, { stockplace_name: 'new test' });
                // }, 5000);
            } catch (error) {
                console.error(error);
            }
        }, 20000);
    }

    _arrayBufferToBase64(buffer: ArrayBufferLike) {
        try {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        } catch (error) {
            console.log('error :>> ', error);
            throw Error('lol');
        }
    }

    toggleEditMode() {
        this.editMode = !this.editMode;
    }

    onWidgetsChange(event: any) {
        // console.dir(event);
    }

    onTextChanged(event: any) {
        console.log('this.grid.gridfunc?.getSelectedRow() :>> ', this.grid.gridfunc?.getSelectedRow());
        console.log('event :>> ', event);
        console.log('this.text1 :>> ', this.text1);
        // console.log('this.text2 :>> ', this.text2);
        // console.log('this.text3 :>> ', this.text3);
        // console.log('this.text4 :>> ', this.text4);
    }

    // updateWidgetInList(widgetId: string, newData: any) {
    //     const index = this.widgets.findIndex((widget) => widget.id === widgetId);
    //     if (index !== -1) {
    //         const updatedWidget = { ...this.widgets[index], ...newData };
    //         this.widgets = [...this.widgets.slice(0, index), updatedWidget, ...this.widgets.slice(index + 1)];
    //     }
    // }

    // onVariableChange(event: any) {
    //     console.dir(event);
    //     this.newVariables['testVariable'].value = event;
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    // onVariable2Change(event: any) {
    //     console.dir(event);
    //     this.newVariables['testVariable_2'].value = Number(event);
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    // onVariable3Change(event: any) {
    //     console.dir(event);
    //     if (this.widgetService.isWidgetNumberVariable(this.newVariables['testVariable_2'])) this.newVariables['testVariable_2'].decimals = event;
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    // onVariable4Change(event: any) {
    //     console.dir(event);
    //     if (this.widgetService.isWidgetNumberVariable(this.newVariables['testVariable_2'])) this.newVariables['testVariable_2'].displayUnits = event;
    //     this.widgets
    //         .filter((widget) => widget.type === 'text')
    //         .forEach((widget) => {
    //             if (this.widgetService.isWidgetTextConfig(widget.value)) {
    //                 widget.value.variables = () => this.newVariables;
    //                 this.updateWidgetInList(widget.id, widget.value);
    //             }
    //         });
    //     // this.variables = { ...this.variables };

    //     // this.widgets = this.widgets.slice();
    //     // this.cdr.detectChanges();
    // }

    widgets: WidgetConfig[] = [
        {
            id: crypto.randomUUID() as string,
            mainHeading: '1. Lorem ipsum dolor de amet',
            subHeading: 'Donec dictum tristique porta etiam convallis',
            value: {
                chartType: 'line',
                data: {
                    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
                    datasets: [
                        {
                            data: [65, 59, 80, 81, 56, 55, 40],
                            label: 'Series A',
                            //  backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)']
                        },
                        {
                            data: [28, 48, 40, 19, 86, 27, 90],
                            label: 'Series B',
                            // backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)']
                        },
                    ],
                },
            },
            icon: 'fal fa-usd-circle',
            iconColor: '#B5FFC1',
            type: 'chart',
            rowStart: 2,
            rowSpan: 3,
            columnStart: 8,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '2. Lorem ipsum dolor de amet',
            subHeading: 'Donec dictum tristique porta etiam convallis',
            value: {
                chartType: 'doughnut',
                data: {
                    labels: ['Red', 'Blue', 'Yellow'],
                    datasets: [
                        {
                            label: 'My First Dataset',
                            data: [300, 50, 100],
                            // backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(255, 205, 86)'],
                            // borderColor: ['rgba(255, 99, 132, 0)', 'rgba(54, 162, 235, 0)', 'rgba(255, 205, 86, 0)'],
                            hoverOffset: 4,
                        },
                    ],
                },
            },
            icon: 'fal fa-file-invoice-dollar',
            iconColor: '#E475F6',
            type: 'chart',

            rowStart: 2,
            rowSpan: 3,
            columnStart: 14,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '3. Lorem ipsum dolor de amet',
            value: {
                options: {
                    indexAxis: 'y',
                },
                chartType: 'bar',
                data: {
                    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019'],
                    datasets: [
                        { data: [65, 59, 80, 81, 56, 55, 40, 28, 48, 40, 19, 86, 27, 90], label: 'Series A' },
                        { data: [28, 48, 40, 19, 86, 27, 90, 65, 59, 80, 81, 56, 55, 40], label: 'Series B' },
                    ],
                },
            },
            icon: 'fal fa-usd-circle',
            iconColor: '#B5FFC1',
            type: 'chart',

            rowStart: 2,
            rowSpan: 11,
            columnStart: 1,
            columnSpan: 4,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '4. Lorem ipsum dolor de amet',
            value: {
                chartType: 'bar',
                data: {
                    labels: ['2006', '2007', '2008', '2009', '2010', '2011', '2012'],
                    datasets: [
                        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
                        { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
                    ],
                },
            },
            icon: 'fal fa-file-invoice-dollar',
            iconColor: '#E475F6',
            type: 'chart',

            rowStart: 2,
            rowSpan: 3,
            columnStart: 5,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            mainHeading: '5. Lorem ipsum dolor de amet',
            value: {
                model: 'Test av [[testVariable]] i tekststreng, og enda en [[testVariable]] og en annerledes [[testVariable_2]]',
                variables: () => this.newVariables,
            } as WidgetTextConfig,
            icon: 'fal fa-unicorn',
            iconColor: '#E475F6',
            type: 'text',
            rowStart: 2,
            rowSpan: 3,
            columnStart: 11,
            columnSpan: 3,
            style: {} as Partial<CSSStyleDeclaration>,
        },
        {
            id: crypto.randomUUID() as string,
            value: {
                model: [
                    {
                        value: '[[testVariable]][[testVariable]] og en [[testVariable_2]]',
                        fontSize: '3.8rem',
                        fontWeight: '900',
                        textAlign: 'center',
                        alignSelf: 'center',
                    } as WidgetTextLine,
                ],
                variables: () => this.newVariables,
            } as WidgetTextConfig,
            type: 'text',
            rowStart: 1,
            rowSpan: 1,
            columnStart: 1,
            columnSpan: this.columns,
            style: {} as Partial<CSSStyleDeclaration>,
        },

        {
            id: crypto.randomUUID() as string,
            mainHeading: '5. Lorem ipsum dolor de amet',
            value: {
                chartType: 'line',
                data: {
                    datasets: [
                        {
                            data: [65, 59, 80, 81, 56, 55, 40],
                            label: 'Series A',
                            fill: 'origin',
                        },
                        {
                            data: [28, 48, 40, 19, 86, 27, 90],
                            label: 'Series B',
                            fill: 'origin',
                        },
                        {
                            data: [180, 480, 770, 90, 1000, 270, 400],
                            label: 'Series C',
                            yAxisID: 'y1',
                            fill: 'origin',
                        },
                    ],
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                },
            },
            icon: 'fal fa-unicorn',
            iconColor: '#E475F6',
            type: 'chart',

            rowStart: 5,
            rowSpan: 8,
            columnStart: 5,
            columnSpan: 12,
            style: {} as Partial<CSSStyleDeclaration>,
        },
    ];
}

export interface WidgetVariable {
    id: string;
    value: number | string;
    type: 'text' | 'number';
}

export interface WidgetNumberVariable extends WidgetVariable {
    type: 'number';
    format?: 'standard' | 'thousand-separator';
    decimals?: number;
    displayUnits?: 'none' | 'thousands' | 'millions' | 'billions' | 'trillions';
}
