(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('mainMenu', {
        templateUrl: 'views/components/views/mainMenu/mainMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['base64', 'stateService', 'utilityService', 'modalService', 'translateService', 'mainMenuService', 'p2NotificationsService', 'rememberService', 'layoutService', function (base64, stateService, utilityService, modalService, translateService, mainMenuService, p2NotificationsService, rememberService, layoutService) {
            // #region VARIABLES & DEFINITIONS
            const vm = this;

            vm.version = module.version;

            var onDestroy = [];

            let variableNames = {
                show_suggestions: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            let resetGlobalSearchValue = function () {
                vm.model.setting.global_searchtext = '';
                vm.model.searchResult = [];
            }

            let initModel = function () {
                vm.model = {
                    name: stateService.getCurrentName(),
                    miniMobileSize: window.matchMedia('(min-width: 768px)'),
                    mobileSize: window.matchMedia('(min-width: 992px)'),
                    filterValueButtons: [
                        { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.menuitem_filtervalue = '' },
                        { id: 'filter', icon: 'fa fa-filter', color: 'success', type: 'success', onClick: function () { return; } }
                    ],
                    globalSearchTextButtons: [
                        { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: resetGlobalSearchValue } },
                        { id: 'filter', icon: 'fa fa-binoculars', color: 'success', type: 'success', onClick: () => loadGlobalAll() }
                    ],
                    setting: {
                        show_gdpr_message: '0',
                        gdpr_message: '',
                        gdpr_title: '',
                        menuitem_filtervalue: '',
                        show_global_search: '',
                        global_searchtext: ''
                    },
                    menus: [],
                    itemsListMyMenus: [],
                    criticalProcesses: [],
                    isHtml: true,
                    lockedApprove: false,
                    searchResult: [],
                    searchModalOpen: false
                };

                vm.style = {
                    search: {
                        top: '',
                    },
                    list: {
                        width: '100%'
                    },
                    modal: {
                        position: 'absolute',
                        top: '93px',
                        right: '10px',
                        width: '30vh',
                        backgroundColor: 'white',
                        borderRadius: '6px',
                        pointerEvents: 'all',
                        maxHeight: '40vh',
                        width: '520px',
                        maxWidth: '90vw',
                        overflowY: 'scroll',
                        zIndex: '1000000000000000',
                    },
                    history: {
                        width: '25px',
                        height: '16px',
                        fontSize: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                };

                vm.id = {
                    modal: uuid(),
                    searchingGlobal: uuid(),
                    searchingGlobalMobile: uuid()
                };
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            let onTranslate = function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            }

            // #endregion TRANSLATE SERVICE

            // #region PROCEDURE ON LAYOUT CHANGE FUNCTION CALL

            let onLayoutChanged = function(info) {
                vm.style.history.fontSize = info.fontSizes.textSize;
                vm.style.history.height = info.height + 'px';
                vm.style.history.width = Number(info.height + 8) + 'px';

                if ((info?.showBreadcrumbs && vm.model.breadcrumbsView === 'toggle') || vm.model.breadcrumbsView === 'true') vm.style.search.top = '58px';
                if ((!info?.showBreadcrumbs && vm.model.breadcrumbsView === 'toggle') || vm.model.breadcrumbsView === 'false') vm.style.search.top = '34px';
            }

            // #endregion PROCEDURE ON LAYOUT CHANGE FUNCTION CALL

            let onGetMainMenu = function(data) {
                vm.model.setting.show_gdpr_message = data[0].show_gdpr_message;
                vm.model.setting.show_global_search = data[0].show_global_search;
                vm.model.setting.show_suggestions = data[0].show_suggestions;

                if (angular.isDefined(data[0].gdpr_title) && data[0].gdpr_title !== '' && angular.isDefined(data[0].gdpr_message) && data[0].gdpr_message !== '') {
                    vm.model.setting.gdpr_title = base64.urldecode(data[0].gdpr_title);
                    vm.model.setting.gdpr_message = base64.urldecode(data[0].gdpr_message);
                }
            }

            let onListMyMenus = function(data) {
                angular.copy(data, vm.model.itemsListMyMenus);
            }

            let onLoadUnreadNotifications = function (data) {
                angular.copy(data, vm.p2NotificationsListUnread);
            }

            /**
             * Sets right position of the modal based on available space in the window.
             */
            function setModalPosition() {
                let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
                const globalSearchInputRect = document.getElementById(vm.id.searchingGlobalMobile)?.getBoundingClientRect()
                
                if (globalSearchInputRect) {
                    vm.style.modal.top = (globalSearchInputRect.bottom - 7) + 'px';
                    vm.style.modal.width = document.documentElement.clientWidth - globalSearchInputRect.left;
                }

                vm.style.modal.right = +scrollbarWidth + 'px';
            }

            let menuWidthCalc = function () {
                let ul = vm.model.menus.length > 0 ? 100 / vm.model.menus.length : 100;

                vm.style.list.width = ul + '%';
            };

            let loadWebPageMenuAll = function () {
                mainMenuService.listWebPageMenuItems().then(function (response) {
                    angular.copy(response, vm.model.menus);

                    menuWidthCalc();
                });
            };

            var loadGlobalAll = function () {
                mainMenuService.searchGlobalAll({ global_searchtext: vm.model.setting.global_searchtext }).then((result) => {
                    vm.model.searchResult = result;

                    if (result.length > 0) vm.model.searchModalOpen = true;

                    setModalPosition();
                });
            }

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'show_suggestions':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            /**
             * Checks whether the given element or its children is the given event target.
             * 
             * @param {HTMLElement} element the element and its children to check.
             * @param {EventTarget} target the target from an event to search for.
             * @returns true if the given element or any of its children is the event target, false if not.
             */
            function isElementInTarget(element, target) {
                if (element === target) return true;

                for (let i = 0; i < element?.children?.length; i++) {
                    let child = element.children[i];
                    let found = isElementInTarget(child, target);

                    if (found) {
                        return true;
                    }
                };

                return false;
            }

            /**
             * Toggles the modal based on requirements met with a click event.
             * 
             * @param {MouseEvent} event click event on the document.
             */

            const toggleModalOnEvent = function (event) {
                let globalSearch = document?.getElementById(`${vm.id.searchingGlobal}`);
                let globalSearchMobile = document?.getElementById(`${vm.id.searchingGlobalMobile}`);

                if ((isElementInTarget(globalSearch, event.target) || isElementInTarget(globalSearchMobile, event.target)) && vm.model.setting.global_searchtext.length > 0 && vm.model.searchModalOpen === false && vm.model.searchResult.length > 0) {
                    vm.model.searchModalOpen = true;
                    setModalPosition();
                    return;
                }

                let modal = document?.getElementById(`${vm.id.modal}`);
                let shouldModalClose = false;

                if (modal && globalSearch) shouldModalClose = isElementInTarget(modal, event.target) === false && !isElementInTarget(globalSearch, event.target) === false;

                vm.model.searchModalOpen = shouldModalClose;
            }

            vm.setFavourites = function () {
                if (vm.model.setting.show_suggestions === '0') {
                    vm.model.setting.show_suggestions = '1';
                } else {
                    vm.model.setting.show_suggestions = '0';
                }

                rememberFunc('show_suggestions');
            };

            vm.searchItems = function () {
                loadGlobalAll();
            };

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncGlobalSearchText = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.global_searchtext = '';
                        vm.model.searchResult = [];
                        vm.model.searchModalOpen = false;
                        break;
                    case 'search_item':
                        vm.searchItems();
                        break;
                    default:
                        break;
                }
            };

            // #endregion BUTTON LIST FUNCTIONS

            // #region SAVE BUTTON FUNCTION

            vm.approve = function () {
                vm.model.lockedApprove = true;

                mainMenuService.approveGdpr(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedApprove = false;
                        vm.model.setting.show_gdpr_message = '0';
                    }
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            async function getCriticalProcesses() {
                vm.model.criticalProcesses = await mainMenuService.getCriticalProcesses();
            }

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                initModel();

                mainMenuService.getMainMenu().then(onGetMainMenu);

                mainMenuService.listMyMenus({ webpage_name: stateService.getCurrentName() }).then(onListMyMenus);

                p2NotificationsService.loadUnreadNotifications().then(onLoadUnreadNotifications);

                loadWebPageMenuAll();

                getCriticalProcesses();

                document.addEventListener('click', toggleModalOnEvent);

                setModalPosition();

                mainMenuService.checkVersion(module.version).then(function (data) {
                    if (data?.[0]?.force_reload && data[0].force_reload === '1') window.location.reload(true);
                });

                translateService.translateBatch(translations).then(onTranslate);

                layoutService.onLayoutChanged(onDestroy, onLayoutChanged);
            };


            // #endregion ANGULAR FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                document.removeEventListener('click', toggleModalOnEvent);
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION

        }]
    });
})();
