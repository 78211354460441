(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderHeadCalcs', {
        templateUrl: 'views/components/views/orderHeadCalcs/orderHeadCalcs.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'orderHeadCalcsService', function($stateParams, stateService, orderHeadCalcsService) {
            var vm = this;
            
            vm.model = {
                p2WOrderHeadCalcsGet: {},
                p2WOrderHeadCalcsList: {
                    records: []
                }
            };
            
            var loadP2WOrderHeadCalcsGet = function () {
                orderHeadCalcsService.genericFunction(885, {
                    order_internal_no: $stateParams.order_internal_no
                }).then(function (data) {
                    vm.model.p2WOrderHeadCalcsGet = angular.copy(data[0]);
                });
            };
            
            loadP2WOrderHeadCalcsGet();
            
            var loadP2WOrderHeadCalcsList = function () {
                //vm.model.p2WOrderHeadCalcsList.records = [];

                orderHeadCalcsService.genericFunction(887, {
                    order_internal_no: $stateParams.order_internal_no
                }).then(function (data) {
                    angular.copy(data, vm.model.p2WOrderHeadCalcsList.records);
                });
            };
            
            loadP2WOrderHeadCalcsList();
            
            vm.goToCustactEdit = function () {
                stateService.go('custactedit', {
                    custact_no: '0',
                    filterfield: 'order_internal_no',
                    filtervalue: $stateParams.order_internal_no
                });
            };
        }]
    });
})();