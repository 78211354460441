(function () {
    'use strict';

    let module = angular.module('imApp');


    module.component('ttSelectButtons', {
        templateUrl: 'views/components/directives/ttSelectButtons/ttSelectButtons.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttSublabel: '@',
            ttDisabled: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttData: '<',
            ttDataId: '@',
            ttOnBlur: '&',
            ttDataName: '@',
            ttButtons: '<',
            ttButtonsParm: '<',
            ttItemId: '@',
            ttRequired: '@',
            ttReadonly: '@',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttStyle: '<'
        },
        controller: ['$element', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            vm.required = false;
            vm.viewValue = '';

            var onDestroy = [];

            vm.id = {
                select: uuid()
            };

            vm.style = {
                base: {},
                label: {},
                group: {},
                select: {},
                icon: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                input: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'select' }), vm.style);

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onModelChanged = function (value) {
                if(vm.ttData) vm.viewValue = vm.ttData.find((item) => item[vm.ttDataId] === value)?.[vm.ttDataName] ?? '';
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });
            
            vm.onBtnClick = (btn, event) => ttDirectivesService.onButtonClick({ button: btn, parameter: vm.ttButtonsParm, controller: vm, event: event });

            vm.$onInit = function () {
                if (vm.ttData) vm.viewValue = vm.ttData.find((item) => item[vm.ttDataId] === vm.ttModel)?.[vm.ttDataName] ?? '';
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                    translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                        vm.translations.ttLabel = translation;

                        vm.whenReady();
                    });
                    }
                }

                if (changes?.ttModel?.currentValue || changes?.ttData?.currentValue) {
                    if (vm.ttData) vm.viewValue = vm.ttData.find((item) => item[vm.ttDataId] === vm.ttModel)?.[vm.ttDataName] ?? '';
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttButtons) && angular.isArray(changes.ttButtons.currentValue) && changes.ttButtons.currentValue.length > 0) {
                    angular.forEach(vm.ttButtons, function (btn) {
                        if (angular.isDefined(btn.type) && angular.isString(btn.type)) {
                            btn.class = 'tt-input__button--' + btn.type;
                        } else {
                            if (angular.isUndefined(btn.class)) {
                                btn.class = 'tt-input__button--primary';
                            }
                        }
                    });
                }

                //if (angular.isDefined(changes.ttStyle) && angular.isObject(changes.ttStyle.currentValue)) {
                //    vm.required = us.toBoolean(changes.ttRequired.currentValue);

                //    angular.forEach(changes.ttStyle.currentValue, function (value, key) {
                //        vm.style.select[key] = value;
                //    });
                //}
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
