(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("serviceObjectsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadServiceObjectsGet: function (argtype, argvalue) {
                return p2DataTaskService.call(1732, {
                    argtype: argtype,
                    argvalue: argvalue
                });
            },
            loadServiceObjectsList: function (settingServiceObject) {
                return p2DataTaskService.call(1733, settingServiceObject);
            }
        };

        return service;
    }]);
})();