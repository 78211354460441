(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("prodGrpService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
			loadP2WProdGrpGet: function (prodgrp_id) {
				return p2DataTaskService.call(917, {
					prodgrp_id: prodgrp_id
				});
			},
            saveP2ProdGrp: function (parm_name) {
                return p2DataTaskService.call(918, parm_name);
            },
            deleteP2ProdGrp: function (parm_name) {
                return p2DataTaskService.call(919, parm_name);
            }
        };

        return service;
    }]);
})();