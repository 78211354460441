import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentBaseComponent } from '../component-base/component-base.component';

/**
 * Represents a card/well. Content is passed through the element.
 */
@Component({
    selector: 'tt-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class CardComponent extends ComponentBaseComponent {
    /** (Optional) a heading for the card. No translation supported yet. */
    @Input() ttHeading?: string;
    @Input() ttIcon?: string;

    @Input()
    public ttClass?: string;
}
