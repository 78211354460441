(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('models', {
        templateUrl: 'views/components/views/models/models.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'modelsService', 'rememberService', function (stateService, utilityService, modelsService, rememberService) {
            let vm = this;

            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                setting: {},
                itemsListModels: []
            };

            let loadSetting = function () {
                modelsService.getModelsSettings().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };

            let loadModels = function () {
                modelsService.listModels({ show_inactive: '1' }).then(function (result) {
                    angular.copy(result, vm.model.itemsListModels);
                });
            };
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'model':
                        go({ model_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;
                        
                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
                loadModels();
            };
        }]
    });
})();
