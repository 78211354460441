(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("holidayService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            getHoliday: function (p2_holyday_keyno, country_id) {
                var deferred = $q.defer();

                var parms_getHoliday = {
                    method: 569,
                    parameters: {
                        p2_holyday_keyno: p2_holyday_keyno,
                        country_id: country_id
                    }
                };

                $ihttp.post(parms_getHoliday).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            saveHoliday: function (dataSource) {
                var deferred = $q.defer();

                var parms_saveHoliday = {
                    method: 570,
                    parameters: dataSource
                };

                $ihttp.post(parms_saveHoliday).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            deleteHoliday: function (p2_holyday_keyno) {
                var deferred = $q.defer();

                var parms_deleteHoliday = {
                    method: 571,
                    parameters: {
                        p2_holyday_keyno: p2_holyday_keyno
                    }
                };

                $ihttp.post(parms_deleteHoliday).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();