(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicSearch', {
        templateUrl: 'views/components/dynamics/components/ttDynamicSearch/ttDynamicSearch.template.html',
        controllerAs: 'vm',
        bindings: {
            ttRootId: '@',
            ttLabel: '@',
            ttModel: '<',
            ttDataId: '@',
            ttDataName: '@',
            ttDatasourceKeyno: '<'
        },
        controller: ['$q', '$element', 'utilityService', 'ttDatasourceManager', function ($q, $element, us, ttDatasourceManager) {
            var vm = this;

            vm.label = '';
            vm.model = null;
            vm.items = [];

            vm.typeaheadSearch = function (value) {
                console.log(value);
                var deferred = $q.defer();

                let ds = ttDatasourceManager.getDatasourceByKeyno(vm.ttRootId, vm.ttDatasourceKeyno);

                ds.read({ searchvalue: value }).then(function (response) {
                    console.log('search response');
                    console.dir(response);

                    deferred.resolve(response);
                });

                return deferred.promise;
            };


            vm.setSelected = function (item) {
                angular.copy(item, vm.ttModel);
            };
        }]
    });
})();