(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('payCheckBatches', {
        templateUrl: 'views/components/views/payCheckBatches/payCheckBatches.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'payCheckBatchesService', function (stateService, payCheckBatchesService) {
            
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            
    		vm.model = {
    			itemsListPayCheckBatches: []
    		};
            
    		// ## LOAD PROCEDURE CALLS ##

            let loadPayCheckBatches = function () {
                payCheckBatchesService.listPayCheckBatches().then(function (result) {
                    angular.copy(result, vm.model.itemsListPayCheckBatches);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'paycheckbatch':
                        go({ paycheckbatch_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadPayCheckBatches();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
