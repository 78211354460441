(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ediSetup', {
        templateUrl: 'views/components/views/ediSetup/ediSetup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$uibModal', 'stateService', 'ediSetupService', 'utilityService', function ($stateParams, $q, $uibModal, stateService, ediSetupService, us) {
            var vm = this;

            var data = {};

            vm.argtype = null;
            vm.argvalue = null;
            vm.newCustomer = null;

            let custNo = 0;

            var openModal = function (dataItem) {
                return new Promise((resolve) => {

                    var deferred = $q.defer();

                    var modalInstance = $uibModal.open({
                        component: 'ediSetupModal',
                        resolve: {
                            parameters: function () {
                                return {
                                    dataItem: dataItem,
                                    argtype: vm.argtype,
                                    argvalue: vm.argvalue,
                                    data: data,
                                    rows: vm.ttGrid.gridfunc.getAllRows()
                                };
                            }
                        },
                        size: 'pst-ninetyfive',
                        backdrop: 'static'
                    });

                    modalInstance.result.then(function (response) {
                        resolve(response);
                    }, function () {
                        deferred.reject();
                    })
                });

                //return deferred.promise;
            };

            var onSetup = function (_schema, _fields, columns) {
                function buildSpan(value) {
                    return '<span>' + value + '</span>';
                };

                function getEdiTypeKeyno(dataItem) {
                    if (angular.isUndefined(dataItem))
                        return 0;
                    if (angular.isUndefined(dataItem.ediTypeKeyno))
                        return 0;

                    return dataItem.ediTypeKeyno;
                };

                function getEdiType(dataItem, ediTypeKeyno) {
                    if (angular.isUndefined(data))
                        return null;
                    if (angular.isUndefined(data.ediTypes))
                        return null;

                    if (angular.isUndefined(ediTypeKeyno)) {
                        ediTypeKeyno = getEdiTypeKeyno(dataItem);

                        if (ediTypeKeyno < 1) return null;
                    }

                    if (angular.isUndefined(data.ediTypes['ediType' + ediTypeKeyno]))
                        return null;

                    return data.ediTypes['ediType' + ediTypeKeyno];
                };

                angular.forEach(columns, function (column) {
                    switch (column.field) {
                        case 'ediTypeKeyno':
                            column.template = function (dataItem) {
                                var ediType = getEdiType(dataItem);

                                if (angular.isUndefined(ediType) || ediType === null)
                                    return '';

                                return buildSpan(ediType.name);
                            };
                            break;
                        case 'p2ReportdefKeyno':
                            column.template = function (dataItem) {
                                var ediType = getEdiType(dataItem);

                                if (angular.isUndefined(ediType) || ediType === null)
                                    return '';
                                if (angular.isUndefined(ediType.reportdefs))
                                    return '';

                                var retval = '';

                                angular.forEach(ediType.reportdefs, function (reportdef) {
                                    if (reportdef.keyno === dataItem.p2ReportdefKeyno) {
                                        retval = buildSpan(reportdef.name);
                                    }
                                });

                                return retval;
                            };
                            break;
                        case 'p2ExportprocKeyno':
                            column.template = function (dataItem) {
                                var ediType = getEdiType(dataItem);

                                if (angular.isUndefined(ediType) || ediType === null)
                                    return '';
                                if (angular.isUndefined(ediType.exportprocs))
                                    return '';

                                var retval = '';

                                angular.forEach(ediType.exportprocs, function (exportproc) {
                                    if (exportproc.keyno === dataItem.p2ExportprocKeyno) {
                                        retval = buildSpan(exportproc.name);
                                    }
                                });

                                return retval;
                            };
                            break;
                        case 'ediDestinationKeyno':
                            column.template = function (dataItem) {
                                if (angular.isUndefined(dataItem))
                                    return '';
                                if (angular.isUndefined(data))
                                    return '';
                                if (angular.isUndefined(data.ediDestinations))
                                    return '';

                                var retval = '';

                                angular.forEach(data.ediDestinations, function (dest) {
                                    if (dest.keyno === dataItem.ediDestinationKeyno) {
                                        retval = buildSpan(dest.name);
                                    }
                                });

                                return retval;
                            };
                            break;
                    }
                });
            };

            vm.ttGrid = {
                dataTask: {
                    rememberId: 'ediSetup.ttGrid',
                    loadData: {
                        method: 2265,
                        parameters: function () {
                            return {
                                argtype: vm.argtype,
                                argvalue: vm.argvalue
                            };
                        }
                    },
                    addRow: {
                        method: null,
                        parameters: null,
                        pre: async function (dataItem) {
                            return openModal(dataItem);
                            await vm.ttGrid.gridfunc.saveChanges();
                            vm.ttGrid.gridfunc.read();
                        },
                        post: () => vm.ttGrid.gridfunc.read(),
                        autoSave: true,
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                    },
                    saveData: {
                        method: null,
                        parameters: {},
                        readAfterSave: true,
                    }
                },
                config: {
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        add: true,
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    specialFunc: {                                      // IN DEVELOPMENT
                        newTab: false,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            {
                                name: 'edit',
                                text: '',
                                icon: 'far fa-chevron-right',
                                func: async function (data) {
                                    const response = openModal(data).then(async (response) => {

                                        if (response.delete === true) {
                                            await vm.ttGrid.gridfunc.removeRow(data);
                                        }

                                        vm.ttGrid.gridfunc.saveChanges();
                                    });
                                }
                            }
                        ]
                    },
                    onDataChanged: function () { },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: [],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: true
                },
                optionfunc: function (parms) { },
                gridfunc: null,
                onSetup: onSetup
            };

            // BJS 20220930
            vm.goToCustomers = function () {
                stateService.go('findcustomers');
            };

            // BJS 20220930
            vm.goToNewCustomer = function () {
                console.log('goToNewCustomer: ' + custNo);

                stateService.go('customeredit', { cust_no: custNo });
            };

            vm.$onInit = function () {
                vm.argtype = $stateParams.argtype;
                vm.argvalue = $stateParams.argvalue;
                vm.newCustomer = us.toBoolean($stateParams.new_customer, false);

                if (vm.argtype === 'cust_no') {
                    custNo = vm.argvalue;
                }

                ediSetupService.load(vm.argtype, vm.argvalue).then(function (response) {
                    angular.copy(response, data);

                    vm.ttGrid.gridfunc.rebind();
                });
            };
        }]
    });
})();
