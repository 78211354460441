(function () {
    'use strict';

    angular.module('imApp').component('ttDatasource', {
        templateUrl: 'views/components/dynamics/components/ttDatasource/ttDatasource.template.html',
        controllerAs: 'vm',
        bindings: {
            ttRootid: '@',
            ttModel: '<',
            ttLabel: '@',
            ttChange: '&',
            ttExclude: '<'
        },
        controller: ['$element', 'translateService', 'layoutService', 'ttDatasourceManager', 'utilityService', function ($element, translateService, layoutService, ttDatasourceManager, us) {
            var vm = this;

            var onDestroy = [];

            vm.datasources = [];

            vm.selectedDatasource = {};

            vm.id = {
                select: uuid()
            };

            vm.translations = {
                tt_datasource: ''
            };

            vm.style = {
                label: {},
                select: {}
            };

            vm.class = {
                label: '',
                input: '',
                select: ''
            }

            vm.onDatasourceChanged = function () {
                let dss = vm.datasources.filter(function (ds) {
                    return ds.keyno === vm.selectedDatasource.keyno;
                });

                if (dss.length < 1 || angular.isObject(dss[0]) !== true) return;

                angular.copy(dss[0], vm.selectedDatasource);

                onModelChanged();
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                vm.style.select.fontSize = info.fontSizes.textSize;
                vm.style.select.height = info.height + 'px';
                vm.style.select.paddingTop = info.padding.top + 'px';
                vm.style.select.paddingBottom = info.padding.bottom + 'px';
                vm.style.select.paddingLeft = info.padding.left + 'px';
                vm.style.select.paddingRight = info.padding.right + 'px';

                vm.class.label = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2';
                vm.class.input = vm.hideLabel
                    ? 'col-xs-12'
                    : info.labelAlwaysOnTop === true
                        ? 'col-xs-12'
                        : 'col-xs-12 col-sm-10';
            });

            vm.$onInit = function () {
                translateService.translateBatch(vm.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.translations[key] = val;
                    });
                });

                var datasources = ttDatasourceManager.getDatasources(vm.ttRootid);

                let exclude = (angular.isUndefined(vm.ttExclude) ? 0 : vm.ttExclude).toString();

                angular.forEach(datasources, function (ds) {
                    if ((exclude === '0') || (ds.keyno.toString() !== exclude)) {
                        vm.datasources.push({
                            keyno: ds.keyno,
                            name: ds.datasource_id
                        });
                    }
                });

                let value = angular.isString(vm.ttModel)
                    ? parseInt(vm.ttModel, 10)
                    : angular.isNumber(vm.ttModel)
                        ? vm.ttModel
                        : 0;

                if (value > 0) {
                    let dss = vm.datasources.filter(function (ds) {
                        return ds.keyno === value;
                    });

                    if (dss.length > 0 && angular.isObject(dss[0])) {
                        angular.copy(dss[0], vm.selectedDatasource);
                    }
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            function onModelChanged() {
                if (angular.isFunction(vm.ttChange) !== true) return;

                vm.ttChange({ $value: vm.selectedDatasource.keyno, $modelId: us.getModelId($element) });
            }
        }]
    });
})();