(function () {
    'use strict';

    class CategoryDataSelectController {
        model = {
            categoryDataSelectTabs: {
                active: 'S',
                buttons: [
                    { id: 'S', label: 'categorydataselect_select', onClick: () => this.#selectTab('S') },
                    { id: 'G', label: 'categorydataselect_goto', onClick: () => this.#selectTab('G') },
                ]
            },
            filterButtons: [
                { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => this.model.filterText = '' },
            ],
            filterText: '',
            categoryData: [],
            selectedCategoryData: [],
            activities: [],
            isLoadingActivities: false,
        }

        static $inject = ['$stateParams', '$timeout', 'categoryDataSelectService'];
        constructor($stateParams, $timeout, categoryDataSelectService) {
            Object.assign(this, { $stateParams, $timeout, categoryDataSelectService });
        }

        /**
         * Updates the value of the category keyno and updates visibility of subcategories for the given category.
         * 
         * @param {Object} category the object to update visibility of subcategories for.
         * @param {number} index the index position for the category in the list.
         * @param {string} value the new value to set for category_keyno.
         */
        onCategorySelected(category, index, value) {
            category.category_keyno = value;

            if (value === '-1' || !value) {
                console.log('cleared?');
                this.#clearSubcategories(index);
            } else if (value) {
                this.#setSubcategoriesVisible(index);
            }
        }

        /**
         * Fetches new list of activites.
         */
        loadActivities() {
            this.model.isLoadingActivities = true;

            this.categoryDataSelectService.getCustactsWithCategories(this.model.categoryData, this.$stateParams.argvalue, this.model.categoryDataSelectTabs.active)
                .then((data) => this.model.activities = data)
                .finally(() => this.model.isLoadingActivities = false);
        }

        /**
         * Sets the new tab id to be the active tab and fetches activities again.
         * 
         * @param {'S' | 'G'} id the id of the tab chosen.
         */
        #selectTab(id) {
            this.model.categoryDataSelectTabs.active = id;

            this.loadActivities();
        }

        /**
         * Sets all subcategories from the given category of the index position of the list to be visible.
         * 
         * @param {number} index the index position of the category to set visible from.
         */
        #setSubcategoriesVisible(index) {
            let currentCategoryKeyno = this.model.categoryData[index]?.category_keyno;

            this.model.categoryData.forEach((category, i) => {
                if (i > index) {
                    if (currentCategoryKeyno === category.category_keyno_belongto) {
                        category.category_keyno ??= undefined;
                        category.visible = '1';
                    } else {
                        category.visible = '0';
                    }
                }
            });
        }

        /**
         * Clears the subcategories from the category at the given index in the category list.
         * 
         * @param {number} index the index position of the category to clear categories from.
         */
        #clearSubcategories(index) {
            this.model.categoryData.forEach((category, i) => {
                if (i > index) {
                    delete category.category_keyno;
                    category.visible = '0';
                }
            });
        }

        /**
         * @typedef {Object} existingCategorySelection represents an existing category selection.
         * @property {string} category_keyno_selected the selected keyno for the category.
         * @property {string} category_keyno_belongto the keyno the category belongs to.
         */

        /**
         * Takes a list of existing category selection and makes each category visible and selected in the main category list.
         * 
         * @param {existingCategorySelection[]} selectedCategories the list of existing category selection choices.
         */
        #setCategoryDataToSelected(selectedCategories) {
            selectedCategories.forEach((category) => {
                let selectedCategory = this.model.categoryData.find((cat) => cat.category_keyno_belongto === category.category_keyno_belongto);
                let selectedCategories = this.model.categoryData.filter((cat) => cat.category_keyno_belongto === category.category_keyno_belongto);

                if (selectedCategory) {
                    selectedCategory.category_keyno = category.category_keyno_selected;
                    selectedCategories.forEach((cat) => cat.visible = '1');
                }
            });
        }

        /**
         * Retrieves and returns categories with the argtype and argvalue in stateparameters.
         * 
         * @returns {Promise<Array>} promise for the list of categories
         */
        async #getCategoryData() {
            return this.model.categoryData = (await this.categoryDataSelectService.getCategoryData(this.$stateParams.argtype, this.$stateParams.argvalue)).sort((a, b) => Number(a.category_keyno_belongto) - Number(b.category_keyno_belongto));
        }

        /**
         * Retrieves and returns list of selected categories.
         * 
         * @returns {Promise<existingCategorySelection[]>} list of selected categories.
         */
        async #getSelectedCategories() {
            return this.categoryDataSelectService.getSelectedCategoryData(this.$stateParams.argtype, this.$stateParams.argvalue);
        }

        async $onInit() {
            await this.#getCategoryData();
            let selectedCategories = await this.#getSelectedCategories();
            if (selectedCategories?.length > 0) this.#setCategoryDataToSelected(selectedCategories);
        }
    }

    let module = angular.module('imApp');

    module.component('categoryDataSelect', {
        templateUrl: 'views/components/views/categoryDataSelect/categoryDataSelect.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: CategoryDataSelectController
    });
})();
