(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("transportAreaMenuService", ['$ihttp', function ($ihttp) {
		let service = {
            getTransportAreaMenu: function (parms) {
                return $ihttp.post({
                    method: 1398,
                    parameters: parms || {}
                });
            },
            listPostPlaces: function (parms) {
                return $ihttp.post({
                    method: 1404,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
