(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('oeeData', {
        templateUrl: 'views/components/views/oeeData/oeeData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'translateService', 'modalService', 'oeeDataService', 'stateService', 'searchService', 'selectOeeStateService', 'selectLabourPuService', 'selectOeeStateRemarkService', function ($stateParams, utilityService, translateService, modalService, oeeDataService, stateService, searchService, selectOeeStateService, selectLabourPuService, selectOeeStateRemarkService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.hide_field = {
                oeedata_keyno: $stateParams.oeedata_keyno,
                show_remarktext: searchService.p2OeeStateRemarksList.showRemarkText
            };

            let translations = {
                confirmation: '',
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                oeeStateNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => goToFunc('selectoeestate') }
                ],
                regByLabourNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => goToFunc('selectlabourpu') }
                ],
                oeeStateRemarkNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => goToFunc('selectoeestateremark') }
                ],
                edit: {},
                selectListOeeStates: [],
                selectListRegisteredByLabours: [],
                lockedSave: false,
                lockedApprove: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                if (searchService.oeeData.p2OeeDataGet === '') {
                    oeeDataService.loadP2OeeDataGet($stateParams.oeedata_keyno).then(function () {
                        vm.model.edit = oeeDataService.p2_oeedata_get;

                        loadOeeStates();
                    });
                } else if (searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno === '' && searchService.p2OeeStateRemarksList.oeeStateRemarkName === '') {
                    oeeDataService.loadP2OeeDataGet($stateParams.oeedata_keyno).then(function () {
                        vm.model.edit = oeeDataService.p2_oeedata_get;

                        loadOeeStates();
                    });
                } else {
                    if (searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno !== '' && searchService.p2OeeStateRemarksList.oeeStateRemarkName !== '') {
                        if (selectOeeStateService.savingToPreP2SelectOeeState === true || selectLabourPuService.savingToPreP2SelectLabourPuSave === true) {
                            selectOeeStateService.savingToPreP2SelectOeeState = false;
                            selectLabourPuService.savingToPreP2SelectLabourPuSave = false;

                            oeeDataService.loadP2OeeDataGet($stateParams.oeedata_keyno).then(function () {
                                vm.model.edit = oeeDataService.p2_oeedata_get;

                                loadOeeStates();

                                if (selectOeeStateRemarkService.rememberingToPreOeeData === true) {
                                    vm.model.edit.oeestateremark_keyno = searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno;
                                    vm.model.edit.oeestateremark_name = searchService.p2OeeStateRemarksList.oeeStateRemarkName;
                                    oeeDataService.p2_oeedata_get.oeestateremark_keyno = searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno;
                                    oeeDataService.p2_oeedata_get.oeestateremark_name = searchService.p2OeeStateRemarksList.oeeStateRemarkName;
                                }
                            });
                        } else {
                            vm.model.edit = angular.copy(angular.fromJson(searchService.oeeData.p2OeeDataGet));
                            oeeDataService.p2_oeedata_get = angular.copy(angular.fromJson(searchService.oeeData.p2OeeDataGet));
                            searchService.oeeData.p2OeeDataGet = '';

                            vm.model.edit.oeestateremark_keyno = searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno;
                            vm.model.edit.oeestateremark_name = searchService.p2OeeStateRemarksList.oeeStateRemarkName;
                            oeeDataService.p2_oeedata_get.oeestateremark_keyno = searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno;
                            oeeDataService.p2_oeedata_get.oeestateremark_name = searchService.p2OeeStateRemarksList.oeeStateRemarkName;

                            loadOeeStates();
                        }
                    }
                }
            };

            let loadRegisteredByLabours = function () {
                oeeDataService.listRegisteredByLabours({ webpagename: 'oeedata' }).then(function (result) {
                    angular.copy(result, vm.model.selectListRegisteredByLabours);
                });
            };

            let loadOeeStates = function () {
                if (utilityService.validateParmsValue(vm.model.edit.equipment_id) !== true) return;

                return oeeDataService.listOeeStates({ equipment_id: vm.model.edit.equipment_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListOeeStates);
                });
            };

            let retrieveShowSettings = function () {
                if (utilityService.validateParmsValue(vm.model.edit.oeestate_keyno) !== true) return;

                return oeeDataService.getShowSettings({ oeestate_keyno: vm.model.edit.oeestate_keyno }).then(function (response) {
                    vm.model.edit.show_details = response[0].show_details;
                    vm.model.edit.show_remark = response[0].show_remark;
                    vm.model.edit.show_prodid = response[0].show_prodid;
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            let goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                searchService.oeeData.p2OeeDataGet = angular.toJson(vm.model.edit);

                switch (state) {
                    case 'selectoeestate':
                        searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno = '';
                        searchService.p2OeeStateRemarksList.oeeStateRemarkName = '';

                        go({ arrowside: 'L' });
                        break;
                    case 'selectlabourpu':
                        go({ webpagename: stateService.getCurrentName() });
                        break;
                    case 'selectoeestateremark':
                        go({ oeestate_keyno: vm.model.edit.oeestate_keyno });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                oeeDataService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].saveok === '1') {
                    	modalService.show({
                    		type: 'success',
                    		title: translations.confirmation,
                            message: response[0].promptmessage,
                    		buttons: [{
                    			label: translations.ok,
                                cssClass: 'btn-success',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                    			}
                    		}]
                    	});
                    }
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON APPROVE PROCEDURE FUNCTION CALL

            vm.approveChanges = function () {
                vm.model.lockedApprove = true;
                oeeDataService.p2_oeedata_get = vm.model.edit;

                oeeDataService.approveObj({
                    oeedata_keyno: vm.model.edit.oeedata_keyno,
                    equipment_id: vm.model.edit.equipment_id
                }).then(function () {
                    vm.model.lockedApprove = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedApprove = false;
                });
            };

            // #endregion BUTTON APPROVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    oeeDataService.deleteItem({ oeedata_keyno: vm.model.edit.oeedata_keyno }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'oeestate_keyno_new':
                        if (utilityService.validateWatchValue(value, vm.model.edit.oeestate_keyno_new) !== true) return;

                        vm.model.edit.oeestate_keyno_new = value;

                        if (utilityService.validateParmsValue(vm.model.edit.oeestate_keyno_new) !== true) return;

                        if (vm.model.edit.oeestate_keyno_new !== value) {
                            vm.model.edit.oeestateremark_keyno = '';
                            vm.model.edit.oeestateremark_name = '';
                        }

                        if (vm.model.edit.oeestate_keyno_new !== undefined) {
                            vm.model.edit.oeestate_keyno = value;
                            vm.model.edit.oeestate_name = value;

                            retrieveShowSettings();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadRegisteredByLabours();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
