(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockGet', {
        templateUrl: 'views/components/views/stockGet/stockGet.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'stockGetService', function ($stateParams, $q, stateService, stockGetService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;
            
            vm.model = {
                productButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno  }) }
                ],
                stockStockValueButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockbatchlist', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                stockBatchQtyButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockstockbatchassigned', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                numberOfLocationsButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockonlocation', { stock_keyno: vm.model.edit.stock_keyno, functionname: 'stock' }) }
                ],
                stockStockValueOtherButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockall', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                stockIncomingButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockincoming', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                stockOutgoingButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockoutgoing', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                stockLocationNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectstocklocation', { stockplace_id: vm.model.edit.stockplace_id }) }
                ],
                edit: {},
                selectListProdBeoMethods: [],
                itemsListOwnKpis: []
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                const deferred = $q.defer();

                stockGetService.getStock({ stock_keyno: stockKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadOwnKpis();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadProdBeoMethods = function () {
                stockGetService.listProdBeoMethods().then(function (result) {
                    angular.copy(result, vm.model.selectListProdBeoMethods);
                });
            };

            let loadOwnKpis = function () {
                return stockGetService.listOwnKpis({ stock_keyno: vm.model.edit.stock_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListOwnKpis);
                });
            };

            // #endregion LOAD PROCEDURE CALL
            
            // #region SAVE BUTTON FUNCTION
            
            vm.saveChanges = function () {
                stockGetService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
            
            // #endregion SAVE BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'createpopu':
                    case 'requisitioncreatefrom':
                    case 'stocklocationctrl':
                    case 'stockhistory':
                    case 'stockminmaxadjust':
                        go({ stock_keyno: vm.model.edit.stock_keyno });
                        break;
                    case 'stocktransallocations':
                    case 'pos':
                        go({
                            argtype: 'stock_keyno',
                            argvalue: vm.model.edit.stock_keyno
                        });
                        break;
                    case 'stockcorrection':
                        go({
                            stock_keyno: vm.model.edit.stock_keyno,
                            stocklocation_keyno: '0'
                        });
                        break;
                    case 'selectreport':
                        go({
                            webpage_name: 'stockget',
                            argtype: 'stock_keyno',
                            argvalue: vm.model.edit.stock_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadProdBeoMethods();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
