(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListItemItems', {
        templateUrl: 'views/components/views/checkListItemItems/checkListItemItems.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'checkListItemItemsService', function ($stateParams, stateService, checkListItemItemsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
                itemsListCheckLists: [],
                orderingAlphabetically: false,
                orderingByType: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadSetting = function () {
                checkListItemItemsService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };
            
            let loadCheckListItems = function () {
                vm.model.itemsListCheckLists = [];

                checkListItemItemsService.listCheckListItems({ checklistitem_keyno: $stateParams.checklistitem_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCheckLists);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'checklistitemitem':
                        go({
                            checklistitem_keyno: $stateParams.checklistitem_keyno,
                            checklistitemitem_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ORDERING BUTTON FUNCTIONS

            vm.orderAlphabetically = function () {
                vm.model.orderingAlphabetically = true;

                checkListItemItemsService.reorganizeCheckListItems({
                    checklistitem_keyno: $stateParams.checklistitem_keyno,
                    orderbytype: '',
                    alpha: 'alpha'
                }).then(function () {
                    vm.model.orderingAlphabetically = false;
                    loadCheckListItems();
                }, function () {
                    vm.model.orderingAlphabetically = false;
                });
            };

            vm.orderByType = function () {
                vm.model.orderingByType = true;

                checkListItemItemsService.reorganizeCheckListItems({
                    checklistitem_keyno: $stateParams.checklistitem_keyno,
                    orderbytype: 'orderby',
                    alpha: ''
                }).then(function () {
                    vm.model.orderingByType = false;
                    loadCheckListItems();
                }, function () {
                    vm.model.orderingByType = false;
                });
            };

            // #endregion ORDERING BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCheckListItems();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
