<h2
    mat-dialog-title
    [ngClass]="!!data.type && data.type !== 'none' ? 'tt-' + data.type + '-background' : ''"
    [ngStyle]="style['title']"
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDragHandle
>
    {{ translations["title"] }}
</h2>
<mat-dialog-content>
    <p [ngStyle]="style['message']">{{ translations["message"] }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <tt-button class="col-xs-3 sp-2" ttText="cancel" ttType="secondary" (ttClick)="dialogRef.close(false)" [ttStyle]="style"></tt-button>
    <tt-button class="col-xs-3 sp-2" ttText="ok" [ttType]="!data.type || data.type === 'none' ? 'primary' : data.type" (ttClick)="dialogRef.close(true)" [ttStyle]="style"></tt-button>
</mat-dialog-actions>
