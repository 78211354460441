(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListCrewChangeService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListCrewAddGet: function (checklistdata_keyno) {
                return p2DataTaskService.call(1779, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrCheckListCrewAddList: function (settingChangeCrew) {
                return p2DataTaskService.call(1780, settingChangeCrew);
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1781, mergeObj);
            }
        };

        return service;
    }]);
})();