(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectCity', {
        templateUrl: 'views/components/views/selectCity/selectCity.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectCityService', function ($stateParams, selectCityService) {
            var vm = this;
            
            vm.model = {
                webpage: $stateParams.webpage_name,
                parm: $stateParams.parm1,
                items: [],
                ppSearch: {
                    name: ''
                },
                loading: false
            };
    
            selectCityService.selectedPostplace.id = '';
            selectCityService.selectedPostplace.name = '';
    
            if (angular.isUndefined(selectCityService.current[vm.model.webpage])) {
                selectCityService.current[vm.model.webpage] = {
                    items: {}
                };
            }
    
            if (angular.isDefined(selectCityService.current[vm.model.webpage].items[vm.model.parm]) === true) {
                vm.model.ppSearch.name = selectCityService.current[vm.model.webpage].items[vm.model.parm].name;
            } else {
                selectCityService.current[vm.model.webpage].items[vm.model.parm] = {
                    name: vm.model.ppSearch.name
                };
            }
    
            vm.goTo = function (item) {
                selectCityService.selectedPostplace.id = item.item_id;
                selectCityService.selectedPostplace.name = item.item_name;
            };
    
            vm.searchCity = function () {
                vm.model.loading = true;
    
                selectCityService.current[vm.model.webpage].items[vm.model.parm].name = vm.model.ppSearch.name;
    
                if (angular.isString(vm.model.ppSearch.name) && vm.model.ppSearch.name.length > 0) {
                    selectCityService.searchPostPlaceList(vm.model.webpage, vm.model.parm, vm.model.ppSearch.name).then(function (data) {
                        angular.copy(data, vm.model.items);
    
                        vm.model.loading = false;
                    });
                } else {
                    vm.model.loading = false;
                }
            };
    
            if (vm.model.ppSearch.name !== '') {
                vm.searchCity();
            }
        }]
    });
})();