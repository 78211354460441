(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactCalc', {
        templateUrl: 'views/components/views/custactCalc/custactCalc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'custactCalcService', 'stateService', 'custactCompsService', 'ttGridFactory', function ($stateParams, $timeout, custactCalcService, stateService, custactCompsService, ttGridFactory) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;

            vm.model = {
                spin: false,
                p2CustactCalcOrderSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalc_subtasks', item_func: 'SUBTASKS' },
                        //{ item_id: '1', item_name: 'custactcalc_calc', item_func: 'C' },
                        { item_id: '2', item_name: 'custactcalc_order', item_func: 'O' }
                    ]
                },
                bgOptionView: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalc_included', item_func: 'INCLUDED' },
                        { item_id: '1', item_name: 'custactcalc_options', item_func: 'OPTIONS' }
                    ]
                },
                bgPostView: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalc_summary', item_func: 'POST' },
                        { item_id: '1', item_name: 'custactcalc_text', item_func: 'TEXT' }
                    ]
                },
                p2CustactCalcItemsSelection: {
                    item_default: '0',
                    item_selected: 'C',
                    records: [
                        { item_id: '1', item_name: 'custactcalc_materials', item_func: 'C' },
                        { item_id: '2', item_name: 'custactcalc_work', item_func: 'W' },
                        { item_id: '3', item_name: 'custactcalc_sum', item_func: 'S' }
                    ]
                },
                p2CustactCalcStatusSelection: {
                    item_default: '0',
                    item_selected: 'F',
                    records: [
                        { item_id: '1', item_name: 'custactcalc_release_to_order', item_func: 'F' },
                        { item_id: '2', item_name: 'custactcalc_status', item_func: 'S' }
                    ]
                },
                p2CustactCalcReleaseSelection: {
                    item_default: '0',
                    item_selected: 'R',
                    records: [
                        { item_id: '1', item_name: 'custactcalc_ready_to_release', item_func: 'R' },
                        { item_id: '2', item_name: 'custactcalc_not_ready_to_release', item_func: 'N' },
                        { item_id: '3', item_name: 'custactcalc_released', item_func: '1' }
                    ]
                },
                p2CustactGet: {},
                p2WCustactCalcSubTasksList: {
                    records: []
                },
                p2CustactSubtasksList: {
                    records: []
                },
                p2CustactCalcSum: {},
                p2WCustactCalcSubTasksTextList: {
                    records: []
                },
                p2OverheadFromCustomerCreate: {},
                p2CustactSubtasksListValue: {
                    subtask_custact_no: $stateParams.argvalue
                },
                p2CustactCompReleaseList: {
                    records: []
                },
                loading: true,
                gridReady: false,
            };

            // ######################
            // BUTTON GROUP FUNCTIONS
            // ######################

            vm.selectP2CustactOrderView = function (lsP2CustactOrderSelectItem) {
                vm.model.p2CustactCalcOrderSelection.item_selected = lsP2CustactOrderSelectItem;

                if (vm.model.p2CustactCalcOrderSelection.item_selected === 'SUBTASKS' && vm.model.bgOptionView.item_selected !== '') {
                    vm.loadGrid();
                    vm.getP2WCustactCalcSubTasksList();
                }
            };

            vm.selectBgOptionView = function (item) {
                vm.model.bgOptionView.item_selected = item;
                
                if (vm.model.p2CustactCalcOrderSelection.item_selected === 'SUBTASKS' && vm.model.bgOptionView.item_selected !== '') {
                    vm.loadGrid();
                    vm.getP2WCustactCalcSubTasksList();
                }

                if (vm.model.bgPostView.item_selected === 'TEXT' && vm.model.bgOptionView.item_selected !== '') {
                    vm.loadP2WCustactCalcSubTasksTextList();
                }
            };

            vm.selectBgPostView = function (item) {
                vm.model.bgPostView.item_selected = item;

                if (vm.model.bgPostView.item_selected === 'TEXT' && vm.model.bgOptionView.item_selected !== '') {
                    vm.loadP2WCustactCalcSubTasksTextList();
                }
            };

            vm.selectP2CustactItemsView = function (lsP2CustactItemsSelectItem) {
                vm.model.p2CustactCalcItemsSelection.item_selected = lsP2CustactItemsSelectItem;
                vm.retrieve();
            };

            vm.selectP2CustactStatusView = function (lsP2CustactStatusSelectItem) {
                vm.model.p2CustactCalcStatusSelection.item_selected = lsP2CustactStatusSelectItem;
            };

            vm.selectP2CustactReleaseView = function (lsP2CustactReleaseSelectItem) {
                vm.model.p2CustactCalcReleaseSelection.item_selected = lsP2CustactReleaseSelectItem;
            };

            vm.grid = new ttGridFactory({
                rememberId: 'custactcalc.grid'
            });

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            //custactCompsService.loadP2CustactGet($stateParams.argvalue).then(function () {
            //    vm.model.p2CustactGet = custactCompsService.p2CustactGet;

            //    vm.loadGrid();

            //});


            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                custactCalcService.getSetting({ custact_no: $stateParams.argvalue }).then(function (info) {
                    angular.copy(info[0], vm.model.p2CustactGet);

                    vm.loadGrid();
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS


            var timerCustactCalcSubTasksList = null;

            vm.getP2WCustactCalcSubTasksList = function () {
                if (angular.isDefined(timerCustactCalcSubTasksList)) {
                    $timeout.cancel(timerCustactCalcSubTasksList);
                }

                timerCustactCalcSubTasksList = $timeout(function () {
                    custactCalcService.getP2WCustactCalcSubTasksList($stateParams.argvalue, vm.model.bgOptionView.item_selected).then(function (data) {
                        angular.copy(data, vm.model.p2WCustactCalcSubTasksList.records);
                    });
                }, 50);
            };

            vm.loadGrid = function() {
                vm.grid.dataTask.loadData = {
                    method: 3413,
                    parameters: vm.model.p2CustactGet
                };
                if (vm.grid.dataTask.loadData.method > 0) {
                    vm.model.gridReady = true;
                    vm.grid.gridfunc.rebind();
                }
            }

            vm.loadP2WCustactCalcSubTasksTextList = function () {
                custactCalcService.loadP2WCustactCalcSubTasksTextList($stateParams.argvalue, vm.model.bgOptionView.item_selected).then(function (data) {
                    angular.copy(data, vm.model.p2WCustactCalcSubTasksTextList.records);
                });
            };

            var timerCustactSubTasksList = null;

            vm.retrieve = function () {
                if (angular.isDefined(timerCustactSubTasksList)) {
                    $timeout.cancel(timerCustactSubTasksList);
                }

                timerCustactSubTasksList = $timeout(function () {
                    if (vm.model.p2CustactCalcItemsSelection.item_selected == 'C' || vm.model.p2CustactCalcItemsSelection.item_selected == 'W') {
                        custactCalcService.loadP2CustactSubtasksList($stateParams.argvalue, 'child', '1', vm.model.p2CustactCalcItemsSelection.item_selected).then(function () {
                            vm.model.p2CustactSubtasksList = custactCalcService.p2CustactSubtasksList;
                        });
                    }
                }, 50);
            };

            vm.createP2OverheadFromCustomer = function () {
                custactCalcService.createP2OverheadFromCustomer(vm.model.p2CustactGet.cust_no, $stateParams.argvalue).then(function () {
                    vm.model.p2OverheadFromCustomerCreate = custactCalcService.p2OverheadFromCustomerCreate;

                    vm.model.p2CustactGet.orderline_keyno = vm.model.p2OverheadFromCustomerCreate.orderline_keyno;
                    vm.model.p2CustactGet.order_internal_no = vm.model.p2OverheadFromCustomerCreate.order_internal_no;
                    vm.model.p2CustactGet.po_keyno = vm.model.p2OverheadFromCustomerCreate.po_keyno;
                });
            };

            vm.updateCustactCalcPo = function () {
                vm.model.spin = true;

                custactCalcService.genericFunction(869, {
                    custact_no: vm.model.p2CustactGet.custact_no
                }).then(function (data) {
                    vm.model.spin = false;
                });
            };

            // ##############
            // WATCH FUNCTION
            // ##############

            var timer = null;

            vm.onPriceLocvalChanged = function (value) {
                vm.model.p2CustactCalcSum.price_locval = value;

                if (parseFloat(value).toString() !== 'NaN') {
                    if (vm.model.loading === false) {
                        if (timer) $timeout.cancel(timer);

                        timer = $timeout(function () {
                            vm.model.loading = true;
                            vm.loadP2CustactCalcSum(newVal);
                        }, 500);
                    }
                }
            }

            // ####################
            // VALIDATION FUNCTIONS
            // ####################

            vm.validateCheckItem = function (item) {
                if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                    item.item_glyphicon = 'glyphicon-unchecked';
                } else {
                    item.item_is_selected = '1';
                    item.item_glyphicon_color = 'green';
                    item.item_glyphicon = 'glyphicon-check';
                }
            };

            vm.is_checked = 0;

            vm.checkUncheckP2CustactCompReleaseList = function () {
                if (vm.is_checked === 0) {
                    angular.forEach(vm.model.p2CustactCompReleaseList.records, function (item) {
                        item.item_is_selected = '1';
                        item.item_glyphicon_color = 'green';
                        item.item_glyphicon = 'glyphicon-check';
                    });

                    vm.is_checked = 1;
                } else {
                    angular.forEach(vm.model.p2CustactCompReleaseList.records, function (item) {
                        item.item_is_selected = '0';
                        item.item_glyphicon_color = 'black';
                        item.item_glyphicon = 'glyphicon-unchecked';
                    });

                    vm.is_checked = 0;
                }
            };

            vm.goTo = function (item) {
                stateService.go(item.item_state, item.item_parms);
            };

            // ######################################
            // DIRECTION BUTTON TO CATEGORYDATASELECT
            // ######################################

            vm.goToCategoryDataSelect = function (index) {
                stateService.go('categorydataselect', {
                    argtype: 'custact',
                    argvalue: $stateParams.argvalue
                });
            };

            vm.goToCustactEdit = function () {
                stateService.go('custactedit', {
                    custact_no: '0',
                    filterfield: 'custact_no',
                    filtervalue: $stateParams.argvalue
                });
            };

            vm.goToOrderHead = function (index) {
                stateService.go('orderhead', {
                    order_internal_no: vm.model.p2CustactGet.order_internal_no
                });
            };

            vm.goToPage = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'postcalc':
                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.p2CustactGet.custact_no
                        });
                        break;
                }
            };

            // #####################
            // SAVE BUTTON FUNCTIONS
            // #####################

            vm.saveP2CustactWCustactCalcEditText = function () {
                custactCalcService.saveP2CustactWCustactCalcEditText(vm.model.p2WCustactCalcSubTasksTextList.records).then(function () {
                    vm.loadP2WCustactCalcSubTasksTextList();
                });
            };

            vm.releaseToOrder = function (item) {
                for (var i = 0; i < vm.model.p2CustactCompReleaseList.records.length; i++) {
                    if (vm.model.p2CustactCompReleaseList.records[i].item_is_selected === '1') {
                        custactCalcService.transferP2CustactCompTransferToOrderLine(vm.model.p2CustactCompReleaseList.records[i]).then(function () {
                            vm.model.p2CustactCompTransferToOrderLine = custactCalcService.p2CustactCompTransferToOrderLine;
                        });
                    }
                }
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
