(function () {
    'use strict';

    angular.module("imApp").factory("vatReportMenuService", ['$ihttp', function ($ihttp) {
        let service = {
            getMenu: function (parms) {
                return $ihttp.post({
                    method: 3134,
                    parameters: parms || {}
                });
            },
            updateObj: function (menu) {
                return $ihttp.post({
                    method: 3135,
                    parameters: menu
                });
            },
            retrieveAccount: function (menu) {
                return $ihttp.post({
                    method: 3137,
                    parameters: menu
                });
            },
            exportTo: function (menu) {
                return $ihttp.post({
                    method: 3141,
                    parameters: menu
                });
            },
            deleteItem: function (menu) {
                return $ihttp.post({
                    method: 3194,
                    parameters: menu
                });
            },
            getAttachments: function (vatreportKeyno) {
                var parameters = {
                    argtype: 'vatreport_keyno',
                    argvalue: vatreportKeyno,
                    doctype: 'a',
                    searchtext: '',
                };

                //var parms = {
                //    method: 309,
                //    parameters: parameters
                //};

                return $ihttp.post({ method: 309, parameters: parameters });
            },
            getSubmissionSteps: function () {
                return $ihttp.post({ method: 3259, parameters: {} });
            },
            validate: function (parms) {
                return $ihttp.post({
                    method: 3260,
                    parameters: parms
                });
            },
            sendToAltinn: function (parms) {
                return $ihttp.post({
                    method: 3271,
                    parameters: parms
                });
            },
            retrieveFeedback: function (parms) {
                return $ihttp.post({
                    method: 3273,
                    parameters: parms
                });
            },

        };

        return service;



    }]);


})();
