(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('freightRateSetCreate', {
        templateUrl: 'views/components/views/freightRateSetCreate/freightRateSetCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'freightRateSetCreateService', 'logisticService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, freightRateSetCreateService, logisticService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let custNo = $stateParams.cust_no;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                lockedSave: false
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE
            
            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                let deferred = $q.defer();

                freightRateSetCreateService.getFreightRateSetCreate({ cust_no: custNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => deferred.resolve());
                });
                
                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                freightRateSetCreateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;

                        loadStockPlaces();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
