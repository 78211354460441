(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('workDetails', {
        templateUrl: 'views/components/views/workDetails/workDetails.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'stateService', 'imHeaderService', 'translateService', 'utilityService', 'rememberService', 'modalService', 'workDetailsService', 'ttGridFactory', 'graphCalendarService', function ($q, $stateParams, stateService, imHeaderService, translateService, utilityService, rememberService, modalService, workDetailsService, ttGridFactory, graphCalendarService) {

            // ## VARIABLES & DEFINITIONS ##

            const vm = this;

            vm.version = module.version;

            vm.model = {
                page: '',
                work_details: {},
                mainTabs: {
                    selected: '',
                    tabs: [
                        { id: 'EDIT', icon: 'far fa-edit', label: 'edit_custact', color: 'primary' },
                        { id: 'COMMENTS', icon: 'far fa-comments-alt', label: 'custact_dialog', color: 'primary' },
                        { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary' },
                        { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', color: 'primary' },
                        { id: 'SUBTASKS', icon: 'far fa-tasks', label: 'subtasks', color: 'primary' },
                        { id: 'CUSTACTMEMBERS', icon: 'far fa-users', label: 'participants', color: 'primary' },
                        { id: 'POWTIMES', icon: 'far fa-clock', label: 'workdetails_powtimes', color: 'primary' },
                        { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary' }
                    ]
                },
                mobileMainTabs: {
                    selected: '',
                    tabs: [
                        { id: 'REGISTER', label: 'work_details_group_register', color: 'primary' },
                        { id: 'PLAN', label: 'work_details_group_plan', color: 'primary' }
                    ],
                    showNextTabs: [
                        { id: 'REGISTER', label: 'work_details_group_register', color: 'primary', },
                        { id: 'PLAN', label: 'work_details_group_plan', color: 'primary' },
                        { id: 'PLANS', label: 'work_details_group_plans', color: 'primary' }
                    ]
                },
                customerButtons: [
                    { id: 'goto', icon: 'far fa-chevron-right', type: 'primary', color: 'primary', onClick: () => vm.goToCustomersMenu() }
                ],
                projectButtons: [
                    { id: 'goto', icon: 'far fa-chevron-right', type: 'primary', color: 'primary', onClick: () => vm.goToProjectMenu() }
                ],
                serviceObjectButtons: [
                    { id: 'goto', icon: 'far fa-chevron-right', type: 'primary', color: 'primary', onClick: () => vm.goTo('serviceobjectmenu') }
                ],
                orderButtons: [
                    { id: 'goto', icon: 'far fa-chevron-right', type: 'primary', color: 'primary', onClick: () => vm.goToOrderHead() }
                ],
                purchOrderButtons: [
                    { id: 'goto', icon: 'far fa-chevron-right', type: 'primary', color: 'primary', onClick: () => vm.goTo('purchhead') }
                ],
                videoUrlButtons: [
                    { id: 'goto', icon: 'far fa-video', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.work_details.video_url) }
                ],
                activityStatuses: [],
                subtasks: [],
                mobileSize: window.matchMedia('(min-width: 992px)'),
            };

            vm.ready = {
                documents: false,
                email: false,
                subTasks: false,
                participants: false,
                powTimes: false,
            }

            vm.gridDocuments = new ttGridFactory({
                rememberId: 'documents_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setSpecialFunc({ newTab: true })
                .setCustomToolbarButtons([{ name: 'upload_new_document', text: 'upload_new_document', func: gotoDocuments, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }]);

            function gotoDocuments() {
                stateService.go('documents', {
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            };

            vm.gridEmail = new ttGridFactory({
                rememberId: 'workdetails_email_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setSpecialFunc({ newTab: true })
                .setCustomToolbarButtons([{ name: 'create_new_email', text: 'create_new_email', func: gotoCreateEmail, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }]);

            function gotoCreateEmail() {
                stateService.go('emailcreate', {
                    webpage: 'workdetails',
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            };

            vm.gridSubTasks = new ttGridFactory({
                rememberId: 'workdetails_custact_subtasks_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setSpecialFunc({ newTab: true })
                .setCustomToolbarButtons([{ name: 'workdetails_new_subtask', text: 'workdetails_new_subtask', func: gotoNewSubTask, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }]);

            function gotoNewSubTask() {
                stateService.go('custactedit', {
                    custact_no: '0',
                    filterfield: 'custact_no',
                    filtervalue: vm.model.work_details.custact_no
                });
            };

            vm.gridParticipants = new ttGridFactory({
                rememberId: 'custact_members_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setSpecialFunc({ newTab: true })
                .setCustomToolbarButtons([
                    { name: 'workdetails_add_custactmember', text: 'workdetails_add_custactmember', func: gotoAddParticipant, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true },
                    { name: 'add_external_participant', text: 'add_external_participant', func: gotoAddExternalParticipant, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }
                ]);

            function gotoAddParticipant() {
                stateService.go('selectportaluser', {
                    webpage: 'custactmembers',
                    arg1: vm.model.work_details.custact_no
                });
            };

            function gotoAddExternalParticipant() {
                stateService.go('custactmemberedit', {
                    custact_no: vm.model.work_details.custact_no,
                    custact_member_keyno: '0'
                });
            };

            vm.gridPowTimes = new ttGridFactory({
                rememberId: 'workdetails_powtimes_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setSpecialFunc({ newTab: true })
                .setCustomToolbarButtons([{ name: 'workdetails_new_timeregister', text: 'workdetails_new_timeregister', func: gotoTimeRegister, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }]);

            function gotoTimeRegister() {
                stateService.go('timeregister', {
                    custact_no: vm.model.work_details.custact_no,
                    powtime_keyno: '0'
                });
            };

            function setLoadGridDocuments() {
                vm.gridDocuments.dataTask.loadData = {
                    method: 2972,
                    parameters: {
                        argtype: 'custact_no',
                        argvalue: vm.model.work_details.custact_no,
                        searchtext: '',
                        doctype: 'd',
                        onlyFilenames: '0'
                    }
                };

                vm.ready.documents = true;
            };

            function setLoadGridEmail() {
                vm.gridEmail.dataTask.loadData = {
                    method: 2971,
                    parameters: { custact_no: vm.model.work_details.custact_no }
                };

                vm.ready.email = true;
            };

            function setLoadGridSubTasks() {
                vm.gridSubTasks.dataTask.loadData = {
                    method: 2974,
                    parameters: {
                        custact_no: vm.model.work_details.custact_no,
                        retrievetype: 'child',
                        include_top: '0'
                    }
                };

                vm.ready.subTasks = true;
            };

            function setLoadGridParticipants() {
                vm.gridParticipants.dataTask.loadData = {
                    method: 2975,
                    parameters: { custact_no: vm.model.work_details.custact_no }
                };

                vm.ready.participants = true;
            };

            function setLoadGridPowTimes() {
                vm.gridPowTimes.dataTask.loadData = {
                    method: 2127,
                    parameters: {
                        businessco_no: '99999',
                        date_fom: '1900-01-01',
                        date_tom: '2200-12-31',
                        custact_no: vm.model.work_details.custact_no
                    }
                };

                vm.ready.powTimes = true;
            };

            vm.goToPowtimeGroup = function () {
                if (vm.model.work_details.sum_salaryhours !== '0') {
                    stateService.go('powtimegroup', {
                        cust_no: 0,
                        project_keyno: 0,
                        projectprosess_keyno: 0,
                        custact_no: vm.model.work_details.custact_no,
                        date_start: vm.model.work_details.min_date_start,
                        date_end: vm.model.work_details.max_date_end,
                        grouptype: 'M',
                        equipment_id: '-',
                        labour_no: 0,
                        prod_id: '-'
                    });
                }
            }

            vm.goToCustomersMenu = function () {
                stateService.go('customersmenu', { cust_no: vm.model.work_details.cust_no }, { pageChanged: { header: vm.model.work_details.cust_name } });
            }

            vm.goToCheckInMenu = function () {
                stateService.go('checkinmenu', {
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            vm.goToOrderHeads = function () {
                stateService.go('orderheads', {
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            vm.goToTimeRegister = function () {
                stateService.go('timeregister', { custact_no: vm.model.work_details.custact_no, powtime_keyno: 0 });
            }

            vm.goToMaterialRegister = function () {
                stateService.go('materialregister', {
                    custact_no: vm.model.work_details.custact_no,
                    powtime_keyno: 0, pkey: '-'
                });
            }

            vm.goToDeviations = function () {
                stateService.go('deviations', {
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            vm.gotoEmailCreate = function () {
                stateService.go('emailcreate', {
                    webpage: 'workdetails',
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            vm.goToCustactPlanning = function () {
                stateService.go('custactplanning', {
                    argtype: 'custact_no',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            vm.goToCheckListDatas = function () {
                stateService.go('checklistdatas', { argtype: 'custact_no', argvalue: vm.model.work_details.custact_no });
            }

            vm.goToRiskElements = function () {
                stateService.go('riskelements', { argtype: 'custact_no', argvalue: vm.model.work_details.custact_no });
            }

            vm.goToPictures = function () {
                stateService.go('pictures', { argtype: 'custact_no', argvalue: vm.model.work_details.custact_no });
            }

            vm.goToDocuments = function () {
                stateService.go('documents', { argtype: 'custact_no', argvalue: vm.model.work_details.custact_no });
            }

            vm.goToPrePayments = function () {
                stateService.go('prepayments', {
                    argtype: 'order_internal_no',
                    argvalue: vm.model.work_details.order_internal_no
                });
            }


            vm.goToEditActivity = function () {
                stateService.go('custactedit', { custact_no: vm.model.work_details.custact_no, filterfield: '-', filtervalue: '-' });
            }

            vm.goToCustactMembers = function () {
                stateService.go('custactmembers', {
                    custact_no: vm.model.work_details.custact_no
                });
            }

            vm.goToCustactDialogs = function () {
                stateService.go('custactdialogs', {
                    custact_no: vm.model.work_details.custact_no
                });
            }

            vm.goToSubTasks = function () {
                stateService.go('subtasks', {
                    custact_no: vm.model.work_details.custact_no,
                    retrievetype: 'child',
                    include_top: '0'
                });
            }

            vm.goToCustactGantt = function () {
                stateService.go('custactgantt', {
                    connectto: 'custact',
                    connectto_value: vm.model.work_details.custact_no
                });
            }

            vm.goToCategoryData = function () {
                stateService.go('categorydatas', {
                    argtype: 'custact',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            vm.goToCustactCalc = function () {
                stateService.go('custactcalc', {
                    argtype: 'custact',
                    argvalue: vm.model.work_details.custact_no
                });
            }

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state, e) {
                var go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'serviceobjectmenu':
                        go({
                            serviceobject_keyno: vm.model.work_details.serviceobject_keyno
                        });
                        break;
                    case 'custactcustomers':
                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.work_details.custact_no
                        });
                        break;
                    case 'travelbills':
                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.work_details.custact_no
                        });
                        break;
                    case 'gpximport':
                    case 'mapview3d':
                    case 'mapview':
                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.work_details.custact_no
                        });
                        break;
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'custact_no',
                            argvalue: vm.model.work_details.custact_no
                        });
                        break;
                    case 'custactedit':
                        go({
                            custact_no: '0',
                            filterfield: 'custact_no',
                            filtervalue: $stateParams.custact_no
                        });
                        break;
                    case 'custactsearch':
                        var variableNameExistingActivitySubtask = 'custact_no_linkto';

                        if (angular.isUndefined(vm.model.work_details.custact_no) === true) return;
                        if (vm.model.work_details.custact_no === null) return;
                        if (vm.model.work_details.custact_no.trim().length < 1) return;

                        workDetailsService.remember(variableNameExistingActivitySubtask, vm.model.work_details.custact_no).then(function (data) {
                            go({
                                filterfield: '-',
                                filtervalue: '-',
                                custacttype: 'A'
                            });
                        });

                        break;
                    case 'purchhead':
                        go({
                            purch_internal_no: vm.model.work_details.purch_internal_no
                        });
                        break;
                    case 'custactcomplaint':
                    case 'custactprocess':
                        go({
                            custact_no: vm.model.work_details.custact_no
                        });
                        break;
                    case 'materialregisterh':
                        state = 'materialregister';
                        go({
                            custact_no: vm.model.work_details.custact_no,
                            powtime_keyno: 0,
                            pkey: '-',
                            materialtype: 'H'
                        });
                        break;
                    case 'materialregisterm':
                        state = 'materialregister';
                        go({
                            custact_no: vm.model.work_details.custact_no,
                            powtime_keyno: 0,
                            pkey: '-',
                            materialtype: 'M'
                        });
                        break;
                    default:
                        break;
                }
            };


            // #############################
            // ## DIRECTION BUTTON FUNCTIONS
            // #############################

            vm.goToProjectMenu = function () {
                if (vm.model.work_details.project_keyno > 0) {
                    stateService.go('projectmenu', {
                        project_keyno: vm.model.work_details.project_keyno
                    });
                }
            };

            vm.goToOrderHead = function () {
                stateService.go('orderhead', {
                    order_internal_no: vm.model.work_details.order_internal_no
                });
            };

            vm.goToConsignmentLines = function () {
                stateService.go('consignmentlines', {
                    argtype: 'consignment_keyno',
                    argvalue: vm.model.work_details.last_consignment_keyno
                });
            };

            // #region CHANGE FUNCTION

            vm.onMainTabsChanged = function (value) {
                vm.model.mainTabs.selected = vm.model.work_details.bg_tab1 = value;
                rememberMainTab();
            };

            vm.onMobileMainTabsChanged = function (value) {
                vm.model.mobileMainTabs.selected = vm.model.work_details.bg_workdetails_group = value;
                rememberMobileMainTab();
            };

            vm.onActivityStatusChanged = function (value) {
                if (vm.model.work_details.actstatus_keyno !== value) {
                    vm.model.work_details.actstatus_keyno = value;
                    save();
                }
            };

            // #endregion CHANGE FUNCTIONS

            // #region ACTIONS

            async function save() {
                if (vm.model?.work_details?.custact_no && vm.model?.work_details?.actstatus_keyno) {
                    const data = await workDetailsService.save(vm.model.work_details.custact_no, vm.model.work_details.actstatus_keyno);

                    if (data[0].errorcode !== '0') {
                        modalService.show({ type: 'warning', title: 'Varsel', message: data[0].errormessage, buttons: [{ label: 'OK', cssClass: 'btn-warning', action: (dialogItself) => dialogItself.close() }] });
                    }
                }
            };

            vm.deleteCustact = async function () {
                const promises = [];

                promises.push(workDetailsService.deleteP2Custact());

                if (vm.model.work_details.microsoft_graph_event_id.length > 0) {
                    promises.push(graphCalendarService.deleteEvent(vm.model.work_details.microsoft_graph_event_id));
                }

                await Promise.all(promises);
                stateService.back();
            };

            vm.addExistingDeviationSubtask = function () {
                if (vm.model.work_details?.custact_no) {
                    workDetailsService.remember('custact_no_linkto', vm.model.work_details.custact_no).then(function (data) {
                        stateService.go('custactsearch', {
                            filterfield: '-',
                            filtervalue: '-',
                            custacttype: 'D'
                        });
                    });
                }
            };

            vm.linkToCustact = async function () {
                await workDetailsService.addP2CustactAsSubtask(vm.model.work_details.custact_no, vm.model.work_details.custact_no_linkto);
                stateService.back('/subtasks/' + vm.model.work_details.custact_no_linkto + '/child/0');
            };

            vm.deleteCustactLink = async function () {
                await workDetailsService.deleteP2CustactLink(vm.model.work_details.custactlink_keyno);
                stateService.back();
            };


            // #endregion ACTIONS

            // #region REMEMBER FUNC

            async function rememberMainTab() {
                if (utilityService.validateParmsValue(vm.model.work_details.bg_tab1)) {
                    rememberService.remember('w_workdetails.bg_tab1', vm.model.work_details.bg_tab1);
                }
            };

            async function rememberMobileMainTab() {
                if (utilityService.validateParmsValue(vm.model.work_details.bg_workdetails_group)) {
                    rememberService.remember('w_workdetails.bg_workdetails_group', vm.model.work_details.bg_workdetails_group);
                }
            };

            // #endregion REMEMBER FUNC

            // #region LOAD FUNCTIONS

            async function loadWorkDetails() {
                vm.model.work_details = await workDetailsService.loadWorkDetails($stateParams.custact_no);
            };

            async function loadActivityStatuses() {
                if (vm.model?.work_details?.activitytype_no) {
                    vm.model.activityStatuses = await workDetailsService.loadActStatusList(vm.model.work_details.activitytype_no);
                }
            };

            async function loadSubtasks() {
                vm.model.subtasks = await workDetailsService.loadCustactSubTaskList($stateParams.custact_no, 'child', '0');
            }

            async function loadPageTitle(header) {
                vm.model.page = await translateService.translate(header.text);
            }
            // #endregion LOAD FUNCTIONS

            // #region ANGULAR LIFECYCLE

            vm.$onInit = async function () {
                imHeaderService.on('stateChanged', loadPageTitle);
                await loadWorkDetails();

                stateService.setTitleOverride(vm.model.work_details.note, false);
                vm.model.mainTabs.selected = vm.model.work_details.bg_tab1;
                vm.model.mobileMainTabs.selected = vm.model.work_details.bg_workdetails_group;

                loadActivityStatuses();
                loadSubtasks();
                setLoadGridDocuments();
                setLoadGridEmail();
                setLoadGridSubTasks();
                setLoadGridParticipants();
                setLoadGridPowTimes();
            };

            // #endregion ANGULAR LIFECYCLE

        }]
    });
})();
