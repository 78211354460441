(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acSuppTranses', {
        templateUrl: 'views/components/views/acSuppTranses/acSuppTranses.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'acSuppTransesService', 'logisticService', 'rememberService', 'translateService', function ($stateParams, stateService, utilityService, modalService, acSuppTransesService, logisticService, rememberService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var accSuppNo = $stateParams.acc_supp_no;
            var onDestroy = [];

            var variableNames = {
                businessco_no: '',
                openpost: '',
                show_account_saldo: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            var translations = {
                acsupptranses_remove_matching_show_title: 'Varsel',
                acsupptranses_remove_matching_show_ok_label: 'OK',
                acsupptranses_match_selected_show_title: 'Varsel',
                acsupptranses_match_selected_show_ok_label: 'OK',
                acsupptranses_match_auto_show_title: 'Varsel',
                acsupptranses_match_auto_show_ok_label: 'OK'
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                gridReady: false,
                removing: false,
                matching: false,
                matchingAuto: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_acsupptranses_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //console.dir(data);

                if (angular.isDefined(data.func) && data.func === 'CheckboxBoxClick') {
                    if (data.dataItem.is_selected === true) {
                        data.dataItem.is_selected_amount_locval_balance = data.dataItem.amount_locval_balance;
                    } else {
                        data.dataItem.is_selected_amount_locval_balance = 0;
                    }

                    vm.grid.gridfunc.refresh();
                    vm.grid.gridfunc.refreshAggregates();
                } else if (angular.isDefined(data.func) && data.func === 'CheckboxHeaderClick') {
                    angular.forEach(data.data, function (dataItem) {
                        if (dataItem.is_selected === true) {
                            dataItem.is_selected_amount_locval_balance = dataItem.amount_locval_balance;
                        } else {
                            dataItem.is_selected_amount_locval_balance = 0;
                        }
                    });

                    vm.grid.gridfunc.refresh();
                    vm.grid.gridfunc.refreshAggregates();
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            acSuppTransesService.getAcSuppTranses({ acc_supp_no: accSuppNo }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

                vm.grid.dataTask.loadData = {
                    method: 2792,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
            });

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListCompanies);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                            variableNames[key] = 'w_accountingprocessing.businessco_no';
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                //if (initLoadDone !== true) return;
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    case 'openpost':
                        if (utilityService.validateParmsValue(vm.model.setting.openpost, true) !== true) return;

                        variableValue = vm.model.setting.openpost;
                        break;
                    case 'show_account_saldo':
                        if (utilityService.validateParmsValue(vm.model.setting.show_account_saldo, true) !== true) return;

                        variableValue = vm.model.setting.show_account_saldo;
                        break;
                    case 'date_fom':
                        if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;

                        variableValue = vm.model.setting.date_fom;
                        break;
                    case 'date_tom':
                        if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;

                        variableValue = vm.model.setting.date_tom;
                        break;
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;

                        variableValue = vm.model.setting.dateselector_index;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            vm.removeChanges = function () {
                vm.model.removing = true;

                acSuppTransesService.removeMatching(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.acsupptranses_remove_matching_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.acsupptranses_remove_matching_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.removing = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.removing = false;
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            // #endregion BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            // #region BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            vm.matchChanges = function () {
                vm.model.matching = true;

                var dirtyRows = vm.grid.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({ acsupptrans_keyno: item.acsupptrans_keyno });
                });

                var mergeObj = angular.copy(vm.model.setting);
                mergeObj.records = selectedItems;

                acSuppTransesService.matchSelected(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.acsupptranses_match_selected_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.acsupptranses_match_selected_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.matching = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.matching = false;
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            // #endregion BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            // #region BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            vm.matchAutoChanges = function () {
                vm.model.matchingAuto = true;

                acSuppTransesService.matchAuto(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.acsupptranses_match_auto_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.acsupptranses_match_auto_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.matchingAuto = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.matchingAuto = false;
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            // #endregion BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');
                console.log(parts[parts.length - 1]);
                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'openpost':
                        if (utilityService.validateWatchValue(value, vm.model.setting.openpost) !== true) return;

                        vm.model.setting.openpost = value;

                        if (utilityService.validateParmsValue(vm.model.setting.openpost, true) !== true) return;

                        rememberFunc('openpost');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'show_account_saldo':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_account_saldo) !== true) return;

                        vm.model.setting.show_account_saldo = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_account_saldo, true) !== true) return;

                        rememberFunc('show_account_saldo');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'date_tom_open_post':
                        if (vm.model.setting.date_tom_open_post === value || !vm.grid.gridfunc?.rebind) return;

                        vm.model.setting.date_tom_open_post = value;

                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');

                if (vm.model.setting.date_tom) rememberFunc('date_tom');

                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    vm.grid.gridfunc.rebind();
                }
            }

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
