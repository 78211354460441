(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2SubscriptionsEdit', {
        templateUrl: 'views/components/views/p2SubscriptionsEdit/p2SubscriptionsEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2SubscriptionsEditService', 'subscriptionUsergroupService', 'stateService', function ($stateParams, p2SubscriptionsEditService, subscriptionUsergroupService, stateService) {
            var vm = this;
            var p2SubscriptionKeyno = $stateParams.p2_subscription_keyno;

            vm.model = {
                bgP2SubscriptionsEdit: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_p2subscriptions_edit_usergroups', item_func: 'USERGROUPS' },
                        { item_id: '1', item_name: 'bg_p2subscriptions_edit_users', item_func: 'USERS' },
                        { item_id: '2', item_name: 'bg_p2subscriptions_edit_webpages', item_func: 'WEBPAGES' }
                    ]
                },
                edit: {},
                itemsListSubscriptionUserGroups: [],
                gridUsersReady: false,
				gridWebPagesReady: false
            };

            vm.gridUsers = {
				dataTask: {
					rememberId: 'w_p2subscriptions_edit_users_grid',
					loadSetupId: null,
					loadData: {},
					addRow: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					},
					removeRow: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					},
					saveData: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					}
				},
				translations: [],
				config: {
					editColumns: [
						//{ key: 'test_column' }
						//'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
					],
					toolbar: {
						hidden: false,
						pdfExport: true,
						excelExport: true,
						filter: true,
						columnVisibility: true,
						headers: true,
						edit: false,
						lock: false,
						add: false,
						delete: false,
						save: false,
						wrapping: true,
						layouts: true,
						buttons: [
							//{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
							//{ name: 'test', text: '', func: function () { testItems(); } },
							//{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
							//{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
							//{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
							//{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
						]
					},
					fixedHeader: true,
					keepSortOnAdd: false,
					keepSortOnCheckbox: false,
					keepSortOnIsSelected: false,
					rowClick: false,
					//navigation: {
					//	altNav: true,
					//	newLine: true
					//},
					specialFunc: {
                        newTab: true,
                        buttons: [
                            	//{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
					css: {
						altColor: true,
						textWrapping: false
					},
					onDataSourceChanges: function (e) {
						//console.log('onDataSourceChanges');
						//console.dir(e);
					},
					onDataChanged: function (changed, e) {
						//console.log('onDataChanged');
						//console.dir(e);
					}
				},
				kendo: {
					height: null,
					sortable: true,
					aggregate: true,
					pager: false,
					selectable: false, //'multiple, row'
					//persistSelection: false,
					filterable: false
				},
				optionfunc: null,
				gridfunc: null
			};
			
            vm.gridWebPages = {
				dataTask: {
					rememberId: 'w_p2subscriptions_edit_webpages_grid',
					loadSetupId: null,
					loadData: {},
					addRow: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					},
					removeRow: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					},
					saveData: {
						method: null,
						parameters: null,
						autoSave: false,
						confirm: false
					}
				},
				translations: [],
				config: {
					editColumns: [
						//{ key: 'test_column' }
						//'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
					],
					toolbar: {
						hidden: false,
						pdfExport: true,
						excelExport: true,
						filter: true,
						columnVisibility: true,
						headers: true,
						edit: false,
						lock: false,
						add: false,
						delete: false,
						save: false,
						wrapping: true,
						layouts: true,
						buttons: [
							//{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
							//{ name: 'test', text: '', func: function () { testItems(); } },
							//{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
							//{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
							//{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
							//{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
						]
					},
					fixedHeader: true,
					keepSortOnAdd: false,
					keepSortOnCheckbox: false,
					keepSortOnIsSelected: false,
					rowClick: false,
					//navigation: {
					//	altNav: true,
					//	newLine: true
					//},
					specialFunc: {
                        newTab: true,
                        buttons: [
                            	//{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
					css: {
						altColor: true,
						textWrapping: false
					},
					onDataSourceChanges: function (e) {
						//console.log('onDataSourceChanges');
						//console.dir(e);
					},
					onDataChanged: function (changed, e) {
						//console.log('onDataChanged');
						//console.dir(e);
					}
				},
				kendo: {
					height: null,
					sortable: true,
					aggregate: true,
					pager: false,
					selectable: false, //'multiple, row'
					//persistSelection: false,
					filterable: false
				},
				optionfunc: null,
				gridfunc: null
			};

            p2SubscriptionsEditService.getSubscriptionsEdit({ p2_subscription_keyno: p2SubscriptionKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.edit);

                vm.gridUsers.dataTask.loadData = {
					method: 2873,
					parameters: { p2_subscription_keyno: p2SubscriptionKeyno }
				};
				
                vm.gridWebPages.dataTask.loadData = {
					method: 2874,
					parameters: { p2_subscription_keyno: p2SubscriptionKeyno }
				};

				vm.model.gridUsersReady = true;
				vm.model.gridWebPagesReady = true;
            });

            subscriptionUsergroupService.listSubscriptionUserGroups({
                argtype: 'p2_subscription_keyno',
                argvalue: p2SubscriptionKeyno
            }).then(function (result) {
                angular.copy(result, vm.model.itemsListSubscriptionUserGroups);
            });

            vm.selectBgP2SubscriptionsEdit = function (item) {
                vm.model.bgP2SubscriptionsEdit.item_selected = item;
            };
        }]
    });
})();