(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentSerialNumberService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loadConsignmentSerialNumber: function (consignment_keyno) {
                return p2DataTaskService.call(3196, {
                    consignment_keyno: consignment_keyno
                });
            },
            saveConsignmentSerialNumber: function (consignmentLines) {
                return p2DataTaskService.call(3197, {
                    records: consignmentLines
                });
            }
        };

        return service;
    }]);
})();
