(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("multiColumnListService", ['$ihttp', function ($ihttp) {
        var service = {
            genericFunc: function (methodNo, parms) {
                return $ihttp.post({
                    method: methodNo,
                    parameters: parms || {}
                });
            },
            //updateColumnResize: function (parms) {
            //    return $ihttp.post({
            //        method: 1457,
            //        parameters: parms
            //    });
            //},
            defineGridSetup: function (keyNo) {
                return $ihttp.post({
                    method: 1908,
                    parameters: {
                        p2_datatask_keyno: keyNo
                    }
                });
            }
        };

        return service;
    }]);
})();