(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('helpdoc', {
        templateUrl: 'views/components/views/helpdoc/helpdoc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'helpdocService', function ($stateParams, stateService, helpdocService) {

            let vm = this;

            vm.model = {
                argtype: $stateParams.argtype,
                get: {},
                settings: {},
                helpdocView: {
                    active: 'GENERALLY',
                    buttons: [
                        { id: 'GENERALLY', label: 'helpdoc_user', onClick: () => selectHelpdocView('GENERALLY') },
                        { id: 'INSTALLATION', label: 'helpdoc_installation', onClick: () => selectHelpdocView('INSTALLATION') },
                    ],
                },
                generallyView: {
                    active: 'VIEW',
                    buttons: [
                        { id: 'VIEW', label: 'bg_mode_generally_view', onClick: () => selectGenerallyView('VIEW') },
                        { id: 'EDIT', label: 'bg_mode_generally_edit', onClick: () => selectGenerallyView('EDIT') },
                    ]
                },
                installationView: {
                    active: 'VIEW',
                    buttons: [
                        { id: 'VIEW', label: 'bg_mode_installation_view', onClick: () => selectInstallationView('VIEW') },
                        { id: 'EDIT', label: 'bg_mode_installation_edit', onClick: () => selectInstallationView('EDIT') },
                    ]
                },
                languageList: [],
                relatedDocsList: [],
                editorReady: true
            };

            vm.style = {
                editor: {
                    div: {
                        height: '100vh',
                        maxHeight: 'calc(100vh - 400px)'
                    }
                }
            }

            function selectHelpdocView(id) {
                vm.model.helpdocView.active = id;
            };

            function selectGenerallyView(id) {
                vm.model.generallyView.active = id;
            }

            function selectInstallationView(id) {
                vm.model.installationView.active = id;
            }

            vm.onLanguageSelected = function ($value) {
                if (vm.model.settings.language_id !== $value) {
                    vm.model.settings.language_id = $value;
                    loadrelatedDocsList();
                    loadHelpDoc();
                }
            }

            vm.addNewRelatedHelpDoc = function () {
                return stateService.go('helpdoc', { argtype: 'helpdoctt_keyno_sub', argvalue: vm.model.get.helpdoctt_keyno });
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'helpdocsearch':
                        stateService.go(state, {});
                        break;
                    case 'accessinfo':
                        stateService.go(state, {
                            argtype: 'webpage',
                            argvalue: vm.model.get.webpage
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.saveHelpDoc = function () {
                vm.model.get.helpdoc_view = vm.model.helpdocView.active;
                vm.model.get.bg_mode_generally = vm.model.generallyView.active;

                helpdocService.saveHelpDoc(vm.model.get).then((_) => stateService.back());
            };

            // #region LOAD FUNCTIONS

            async function loadrelatedDocsList() {
                return vm.model.relatedDocsList = await helpdocService.getRelatedDocs(vm.model.get.helpdoctt_keyno, vm.model.get.language_id);
            };

            async function loadSettings() {
                return vm.model.settings = (await helpdocService.getHelpDocSettings($stateParams.argtype, $stateParams.argvalue))[0];
            };

            async function loadHelpDoc() {
                return vm.model.get = (await helpdocService.getHelpDoc(vm.model.settings))[0];
            };

            async function loadLanguageList() {
                return vm.model.languageList = await helpdocService.getLanguageList();
            };

            // #endregion LOAD FUNCTIONS

            // #region ANGULAR LIFECYCLE HOOKS

            vm.$onInit = async function () {
                loadLanguageList();
                await loadSettings();
                await loadHelpDoc();
                loadrelatedDocsList();
            }

            // #endregion ANGULAR LIFECYCLE HOOKS

        }]
    });
})();
