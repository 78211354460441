(function () {
    'use strict';
 
    angular.module('imApp')
        .directive('headerHelpDirective', ['headerHelpService', 'modalService', 'layoutService', function (headerHelpService, modalService, layoutService) {
        var directive = {
            restrict: 'E',
            scope: {
                id: '@hId',
            },
            template: '<label for="checklistitemitem_order" class="name-label" ng-style="{\'fontSize\': model.fontSize}">{{model.labelname}}</label>' + ' '+
                      '<span class="glyphicon glyphicon-question-sign help_question" ng-style="{\'fontSize\': model.fontSize}" ng-click="helpInfo()"></span>',
            link: function ($scope) {
                $scope.model = {
                    labelname: '',
                    helptext: '',
                    fontSize: ''
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    $scope.model.fontSize = info.fontSizes.textSize;
                });

                //special_ws_help_json
                headerHelpService.getHelpText($scope.id).then(function(result){
                    $scope.model.labelname = result.items.labelname;
                    $scope.model.helptext = result.items.helptext;
                });

                $scope.helpInfo = function () {
                    modalService.show({
                        type: 'primary',
                        title: $scope.model.labelname,
                        message: $scope.model.helptext,
                        buttons: [{
                            label: 'OK',
                            close: true
                        }]
                    });
                };
            }
        };
 
        return directive;
    }]);
})();