(function () {
    'use strict';

    angular.module("imApp").factory("watcherFactory", ['$parse', '$exceptionHandler', function ($parse, $exceptionHandler) {
        var isNumberNaN = Number.isNaN || function isNumberNaN(num) {
            // eslint-disable-next-line no-self-compare
            return num !== num;
        };

        function watcher(vm) {
            this.watchers = [];
            this.digestWatchIndex = -1;

            this.initWatchVal = function () { };

            if (angular.isObject(vm)) {
                vm.$doCheck = this.$doCheck.bind(this);
            }
        };

        watcher.prototype.$watch = function (watchExp, listener, objectEquality) {
            var get = $parse(watchExp);
            var fn = angular.isFunction(listener) ? listener : noop;

            var watch = {
                id: uuid(),
                fn: fn,
                last: this.initWatchVal,
                get: get,
                exp: watchExp,
                eq: !!objectEquality
            };

            // we use unshift since we use a while loop in custom digest for speed.
            // the while loop reads in reverse order.
            this.watchers.unshift(watch);

            this.digestWatchIndex++;
        };

        watcher.prototype.$doCheck = function () {
            var dirty, value, watch, get, last, fn;

            do {
                dirty = false;

                this.digestWatchIndex = this.watchers.length;

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    this.digestWatchIndex--;

                    if (this.digestWatchIndex < 0)
                        break;

                    try {
                        watch = this.watchers[this.digestWatchIndex];

                        // Most common watches are on primitives, in which case we can short
                        // circuit it with === operator, only when === fails do we use .equals
                        if (watch) {
                            get = watch.get;

                            if ((value = get()) !== (last = watch.last) &&
                                !(watch.eq
                                    ? angular.equals(value, last)
                                    : (isNumberNaN(value) && isNumberNaN(last)))) {

                                dirty = true;
                                watch.last = watch.eq ? angular.copy(value, null) : value;
                                fn = watch.fn;

                                fn(value, ((last === this.initWatchVal) ? value : last));
                            }
                        } else {
                            dirty = false;
                        }
                    } catch (e) {
                        $exceptionHandler(e);
                    }
                }
            } while (dirty)
        };

        watcher.$create = function (vm) {
            return new watcher(vm);
        };

        return watcher;
    }]);
})();