(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ftpAccount', {
        templateUrl: 'views/components/views/ftpAccount/ftpAccount.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'ftpAccountService', function($stateParams, stateService, ftpAccountService) {
            var vm = this;
            
            vm.model = {
                p2FtpAccountGet: {}
            };
            
            ftpAccountService.loadP2FtpAccountGet($stateParams.ftpaccount_keyno).then(function (data) {
                vm.model.p2FtpAccountGet = angular.copy(data[0]);
                
                vm.model.p2FtpAccountGet.remotepass = atob(vm.model.p2FtpAccountGet.remotepass);
                vm.model.p2FtpAccountGet.remotefolder = atob(vm.model.p2FtpAccountGet.remotefolder);
                vm.model.p2FtpAccountGet.localfolder = atob(vm.model.p2FtpAccountGet.localfolder);
            });
            
            vm.saveFtpAccount = function () {
                ftpAccountService.saveP2FtpAccount(vm.model.p2FtpAccountGet).then(function (data) {
                    stateService.back();
                });
            };
            
            vm.deleteFtpAccount = function () {
                ftpAccountService.deleteP2FtpAccount(vm.model.p2FtpAccountGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();