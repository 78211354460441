(function() {
    'use strict';

    angular.module("imApp").factory("campaignProductsService", [function() {
        var service = {

        }

        return service;
    }]);
})();
