(function () {
    'use strict';

    angular.module("imApp").factory("payoutsService", ['$ihttp', function ($ihttp) {
        let service = {
            getPayouts: function (parms) {
                return $ihttp.post({
                    method: 2834,
                    parameters: parms || {}
                });
            },
            listBankAccounts: function (parms) {
                return $ihttp.post({
                    method: 3357,
                    parameters: parms || {}
                });
            },
            changeBusinessCo: function (setting) {
                return $ihttp.post({
                    method: 3358,
                    parameters: setting
                });
            },
            paySelected: function (mergeObj) {
                return $ihttp.post({
                    method: 2839,
                    parameters: mergeObj
                });
            },
            setBackSelected: function (mergeObj) {
                return $ihttp.post({
                    method: 2840,
                    parameters: mergeObj
                });
            },
            getStatusAndBook: function (setting) {
                return $ihttp.post({
                    method: 2949,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
