(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockPlacesTo', {
        templateUrl: 'views/components/views/stockPlacesTo/stockPlacesTo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'stockPlacesToService', 'rememberService', function ($stateParams, $q, stateService, utilityService, stockPlacesToService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let stockPlaceIdFrom = $stateParams.stockplace_id_from;
            
            let variableNames = {
                searchtext: ''
            };
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                setting: {},
                itemsListStockPlacesTo: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                stockPlacesToService.getStockPlacesTo({ stockplace_id_from: stockPlaceIdFrom }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlacesTos()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadStockPlacesTos = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_from, true) !== true) return;

                vm.model.itemsListStockPlacesTo = [];

                return stockPlacesToService.listStockPlacesTos({ stockplace_id_from: vm.model.setting.stockplace_id_from }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockPlacesTo);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
