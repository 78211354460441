(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("brRegViewService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadBrRegViewGet: function (response, resource_id) {
				return p2DataTaskService.call(1310, {
					response: response,
					resource_id: resource_id
				});
			},
			loadCustGrpList: function (add_all) {
				return p2DataTaskService.call(69, {
					add_all: add_all
				});
			},
			loadBusinessList: function () {
				return p2DataTaskService.call(638, {});
			},
			save: function (companyView) {
				return p2DataTaskService.call(1311, companyView);
			}
		};

		return service;
	}]);
})();