(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('undoDeliveryOnOrder', {
        templateUrl: 'views/components/views/undoDeliveryOnOrder/undoDeliveryOnOrder.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'modalService', 'utilityService', 'translateService', 'undoDeliveryOnOrderService', function ($stateParams, modalService, utilityService, translateService, undoDeliveryOnOrderService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let orderInternalNo = $stateParams.order_internal_no;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.gridReady = false;

            vm.model = {
                edit: {},
                fetchingOrderOrderNo: false,
                undoingDelivery: false,
                orderOrderNoButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('order_order_no') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.fetchChanges() }
                ]
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_undoDeliveryOnOrder',                          // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {
                        method: 2368,
                        parameters: function () {
                            if (angular.isUndefined(vm.model.edit.order_internal_no)) return { order_internal_no: 0 };
                            return vm.model.edit;
                        }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autosave: true
                    }
                },

                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    editColumns: [],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: false,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: false,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'ediTranspReturn', text: 'tt_edi_transporter_return', func: function (data) { ediTranspReturn(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'planningReady', text: 'tt_po_sp_planning_ready', func: function (data) { planningReady(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'setProdplace', text: 'tt_po_sp_set_prodplace', func: function (data) { setProdplace(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'goToShipment', text: '', func: function (data) { goToShipment(data); }, icon: 'far-truck-container', disabled: function (data) { return goToShipmentDisability(data); } },
                            //{ name: 'goToPoh', text: '', func: function (data) { goToPoh(data); }, icon: 'far-industry', disabled: function (data) { return goToPohDisability(data); } }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            var reloadGrid = function () {
                if (vm.grid.gridfunc == null) return;
                vm.grid.gridfunc.rebind();
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadSetting = function () {
                undoDeliveryOnOrderService.getUndoDeliveryOnOrder({ order_internal_no: orderInternalNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            vm.fetchChanges = function () {
                if (angular.isUndefined(vm.model) === true) return;
                vm.model.fetchingOrderOrderNo = true;

                undoDeliveryOnOrderService.fetchOrderOrderNo({ order_order_no: vm.model.edit.order_order_no }).then(function (data) {
                    vm.model.fetchingOrderOrderNo = false;
                    vm.model.edit.old_order_internal_no = vm.model.edit.order_internal_no;
                    vm.model.edit.order_internal_no = data[0].order_internal_no;
                    vm.model.edit.delivery_keyno = data[0].delivery_keyno;
                    vm.model.edit.delivery_undo_information = data[0].delivery_undo_information;


                });

                //if search result is a valid order
                if (vm.model.edit.order_internal_no !== 0 && vm.model.edit.old_order_internal_no !== vm.model.edit.order_internal_no) {
                    //load gridData then show.
                    if (utilityService.validateParmsValue(vm.model.edit.order_internal_no) === true) {
                        reloadGrid();
                    }
                }
            };
            
            vm.undoChanges = function () {
                vm.model.undoingDelivery = true;

                undoDeliveryOnOrderService.undoDelivery({ delivery_keyno: vm.model.edit.delivery_keyno }).then(function (data) {
                    //en feil har oppstått
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: data[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.undoingDelivery = false;
                                }
                            }]
                        });
                    } else {
                        //leveringen er angret.
                        vm.fetchChanges();
                        reloadGrid();

                        if (utilityService.validateParmsValue(vm.model.edit.order_internal_no) === true) {
		                	vm.grid.dataTask.loadData = {
                                method: 2368,
                                parameters: function () {
                                    if (angular.isUndefined(vm.model.edit.order_internal_no)) return { order_internal_no: 0 };
                                    return vm.model.edit;
                                }
		                	};
		                }

                        //vm.model.edit.order_internal_no > 0
                        vm.model.undoingDelivery = false;
                    }
                });
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'order_order_no':
                        vm.model.edit.order_order_no = '';

                        if (vm.model.edit.order_order_no === '') vm.fetchChanges();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'order_order_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.order_order_no) !== true) return;

                        vm.model.edit.order_order_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.order_order_no, true) !== true) return;

                        if (vm.model.edit.order_order_no === '') vm.fetchChanges();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
