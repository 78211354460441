(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customsTarifs', {
        templateUrl: 'views/components/views/customsTarifs/customsTarifs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'customsTarifsService', function (stateService, customsTarifsService) {
            const vm = this;
            
            vm.model = {
                itemsListTarifs: []
            };

            let loadTarifs = function () {
                customsTarifsService.listTarifs().then(function (list) {
                    angular.copy(list, vm.model.itemsListTarifs);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'customstarif':
                        go({ customstarif_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadTarifs();
            };
        }]
    });
})();
