(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("pohCompAddScanService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            filter_pohcomp_addscan: {
                barcode: ''
            },
            p2_pohcomp_addscan: {
                webpagename: '',
                stock_keyno: '',
                parm_barcode: '',
                poh_keyno: ''
            },
            searchP2PohCompAddScan: function (poh_keyno, barcode) {
                var deferred = $q.defer();

                var parms_p2_pohcompadd_scan = {
                    method: 157,
                    parameters: {
                        poh_keyno: poh_keyno,
                        barcode: barcode
                    }
                };

                $ihttp.post(parms_p2_pohcompadd_scan).then(function (data) {
                    service.p2_pohcomp_addscan = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();