import { Component, OnInit } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';
import { StateService } from '@app/core/services/state.service';
import { TranslateService } from '@app/core/services/translate.service';
import { SirvService } from '@app/services/sirv.service';

@Component({
    selector: 'tt-sirv-setup',
    templateUrl: './sirv-setup.component.html',
    styleUrls: ['./sirv-setup.component.css'],
})
export class SirvSetupComponent implements OnInit {
    public edit = {
        sirv_company_url: '',
        sirv_client_id: '',
        sirv_client_secret: '',
    };

    public locks = {
        save: false,
        test: false,
        upload: false,
    };

    public files: File[] = [];

    private translations: { [key: string]: string } = {
        sirv_save_warning: '',
        sirv_connection_established: '',
        sirv_connection_established_description: '',
        sirv_connection_failed: '',
        sirv_connection_failed_description: '',
    };

    constructor(private sirv: SirvService, private modal: ModalService, private datatask: DataTaskService, private state: StateService, private translate: TranslateService) {}

    public async testConnection() {
        this.locks.test = true;

        try {
            const connected = await this.sirv.checkConnection({ clientId: this.edit.sirv_client_id, clientSecret: this.edit.sirv_client_secret });

            if (connected) {
                this.modal.show({ type: 'success', message: this.translations['sirv_connection_established_description'], title: this.translations['sirv_connection_established'] });
            } else {
                this.modal.show({ type: 'danger', message: this.translations['sirv_connection_failed_description'], title: this.translations['sirv_connection_failed'] });
            }
        } finally {
            this.locks.test = false;
        }
    }

    public async saveSetup() {
        this.locks.save = true;

        try {
            const response = (await this.datatask.Post(2471, this.edit))[0];

            if (response.errorcode !== '0') {
                await this.modal.show({ type: 'danger', message: response.errormessage, title: this.translations['sirv_save_warning'] });
            } else {
                this.state.back();
            }
        } finally {
            this.locks.save = false;
        }
    }

    public async uploadImage() {
        this.locks.upload = true;

        try {
            await this.sirv.uploadMultipleImages(this.files, 'janita-test');
            this.files = [];
        } finally {
            this.locks.upload = false;
        }
    }

    private async getSirvSetup() {
        this.edit = { ...(await this.datatask.Post(2470))[0] };
    }

    private async translateWords() {
        const response: typeof this.translations = await this.translate.translateBatch(Object.keys(this.translations));

        for (let [key, word] of Object.entries(response)) {
            if (word !== undefined && word !== null) {
                this.translations[key] = word;
            }
        }
    }

    ngOnInit(): void {
        this.translateWords();
        this.getSirvSetup();
    }
}
