(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("oeeDataCurrentService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_oeedata_current_redirect_get: {
                webpagename: '',
                parms: '',
                oeedata_keyno: ''
            },
            p2_oeedata_get: {
                oeedata_keyno: '',
                equipment_id: '',
                equipment_name: '',
                oeestate_keyno: '',
                oeestate_name: '',
                reg_by_labour_no: '',
                reg_by_equipment_id: '',
                oeestateremark_keyno: '',
                oeestateremark_name: '',
                approved_status: '',
                cycle_time_seconds: '',
                products_optimal: '',
                products_real: '',
                startup_wrecks: '',
                wrecks: '',
                prod_id: '',
                oeestateremark_text: '',
                start_dt: '',
                end_dt: '',
                color: '',
                show_details: '',
                show_remark: '',
                show_prodid: '',
                show_remarktext: ''
            },
            redirectP2OeeDataCurrentGet: function () {
                var deferred = $q.defer();

                var parms_p2_oeedata_current_redirect_get = {
                    method: 250
                };

                $ihttp.post(parms_p2_oeedata_current_redirect_get).then(function (data) {
                    service.p2_oeedata_current_redirect_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2OeeDataGet: function (oeedata_keyno) {
                var deferred = $q.defer();

                var parms_p2_oeedata_get = {
                    method: 251,
                    parameters: {
                        oeedata_keyno: oeedata_keyno
                    }
                };

                $ihttp.post(parms_p2_oeedata_get).then(function (data) {
                    service.p2_oeedata_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2OeeData: function () {
                var deferred = $q.defer();

                var parms_p2_oeedata_save = {
                    method: 254,
                    parameters: {
                        oeedata_keyno: service.p2_oeedata_get.oeedata_keyno,
                        equipment_id: service.p2_oeedata_get.equipment_id,
                        equipment_name: service.p2_oeedata_get.equipment_name,
                        oeestate_keyno: service.p2_oeedata_get.oeestate_keyno,
                        oeestate_name: service.p2_oeedata_get.oeestate_name,
                        reg_by_labour_no: service.p2_oeedata_get.reg_by_labour_no,
                        reg_by_labour_name: service.p2_oeedata_get.reg_by_labour_name,
                        oeestateremark_keyno: service.p2_oeedata_get.oeestateremark_keyno,
                        oeestateremark_name: service.p2_oeedata_get.oeestateremark_name,
                        approved_status: service.p2_oeedata_get.approved_status,
                        cycle_time_seconds: service.p2_oeedata_get.cycle_time_seconds,
                        products_optimal: service.p2_oeedata_get.products_optimal,
                        products_real: service.p2_oeedata_get.products_real,
                        startup_wrecks: service.p2_oeedata_get.startup_wrecks,
                        wrecks: service.p2_oeedata_get.wrecks,
                        prod_id: service.p2_oeedata_get.prod_id,
                        oeestateremark_text: service.p2_oeedata_get.oeestateremark_text,
                        start_dt: service.p2_oeedata_get.start_dt,
                        end_dt: service.p2_oeedata_get.end_dt,
                        color: service.p2_oeedata_get.color,
                        approved_datetime: service.p2_oeedata_get.approved_datetime,
                        approved_by_portal_user_keyno: service.p2_oeedata_get.approved_by_portal_user_keyno,
                        approved_by_portal_user_name: service.p2_oeedata_get.approved_by_portal_user_name,
                        insert_new_oeedata: '0'
                    }
                };

                $ihttp.post(parms_p2_oeedata_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();