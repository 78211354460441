(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("pocfService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1496,
                    parameters: parms || {}
                });
            },
            listComponents: function (parms) {
                return $ihttp.post({
                    method: 1506,
                    parameters: parms || {}
                });
            },
            scanBarcode: function (edit) {
                return $ihttp.post({
                    method: 1497,
                    parameters: edit
                });
            },
            createObj: function (edit) {
                return $ihttp.post({
                    method: 1500,
                    parameters: edit
                });
            },
            createPoFinish: function (edit) {
                return $ihttp.post({
                    method: 165,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1498,
                    parameters: edit
                });
            },
            abortObj: function (edit) {
                return $ihttp.post({
                    method: 1507,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
