(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('csvExports', {
        templateUrl: 'views/components/views/csvExports/csvExports.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'csvExportsService', function ($stateParams, csvExportsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListCsvExports: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadCsvExports = function () {
                csvExportsService.listCsvExports({
                    argtype: argType,
                    argvalue: argValue,
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCsvExports);
            	});

            	//return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadCsvExports();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
