// JLR 14.12.2022
(function () {
    'use strict';

    let module = angular.module('imApp');

    module.factory('sirvImageService', ['$ihttp', '$q', '$timeout', '$http', 'modalService', 'translateService', function ($ihttp, $q, $timeout, $http, modalService, translateService) {

        /**
         * Object containing client id and client secret, necessary for getting a token from sirv.
         */
        let clientApi = null;

        /**
         * URL connected to Sirv used for linking images,
         * example: 'https://insoft.sirv.com'.
         */
        let companyUrl = '';

        /**
         * Default folder to upload images to.
         */
        let rootFolder = 'TouchTime';

        /**
         * Token recieved from Sirv, necesary for making API requests.
         */
        let token = '';

        /**
         * When the token expires.
         */
        let tokenExpiration = null;


        // #region SIRV API

        const sirvApiUrl = 'https://api.sirv.com/v2';
        const tokenApiPath = 'token';
        const uploadFilesApiPath = '/files/upload?filename=';
        const updateFilepathApiPath = '/files/rename?from=';
        const searchFilesApiPath = '/files/search';
        const deleteFilesApiPath = '/files/delete?filename=';

        // #endregion SIRV API 

        /***********************************************************
         * TRANSLATION BEGIN
         ***********************************************************/

        let translations = {
            sirv_overriding_image: '',
            sirv_overriding_image_description: '',
            sirv_overriding_image_ok: '',
            sirv_overriding_image_cancel: '',
            sirv_upload_success: '',
            sirv_upload_success_description: '',
            sirv_upload_success_ok: '',
            sirv_upload_fail: '',
            sirv_upload_fail_description: '',
            sirv_upload_fail_ok: '',
        };

        translateService.translateBatch(translations).then(function (response) {
            angular.copy(response, translations);
        });

        /***********************************************************
         * TRANSLATION END
         ***********************************************************/

        let progress = '';

        let progressInfo = {
            label: '',
            plabel: '',
            field: '',
            remField: '',
            min: 0,
            max: 100,
            step: 0
        }

        let imageUploadSuccessModal = {
            type: 'success',
            title: translations.sirv_upload_success,
            message: translations.sirv_upload_success_description,
            buttons: [{
                label: translations.sirv_upload_success_ok,
                cssClass: 'btn-success',
                action: function (dialogItself) {
                    dialogItself.close();
                }
            }]
        };

        function showUploadFailedModal() {
            modalService.show({
                type: 'warning',
                title: translations.sirv_upload_fail,
                message: translations.sirv_upload_fail_description,
                buttons: [{
                    label: translations.sirv_upload_fail_ok,
                    cssClass: 'btn-warning',
                    action: function (dialogItself) {
                        dialogItself.close();
                    }
                }]
            });
        }

        function showUploadSuccessModal() {
            modalService.show({
                type: 'success',
                title: translations.sirv_upload_success,
                message: translations.sirv_upload_success_description,
                buttons: [{
                    label: translations.sirv_upload_success_ok,
                    cssClass: 'btn-success',
                    action: function (dialogItself) {
                        dialogItself.close();
                    }
                }]
            });
        }

        /**
         * Adds the current date to the end of the given filename and returns the filename with the
         * date appended.
         * 
         * @param {String} filename the filename to append a date to.
         */
        function addDateToFilename(filename) {
            let modifyFilename = filename.split('.');
            modifyFilename[0] += '_' + new Date().toISOString().replace(/T/, '').replace(/\..+/, '').replaceAll(' ', '').replaceAll(':', '');
            return modifyFilename[0] + Math.floor(Math.random() * 90 + 10) + '.' + modifyFilename[1];
        }

        /**
         * Extracts the name from the given image and appends the type
         * 
         * @param {File} image the image to extract name and type from.
         * @return returns the name and type of the given image file
         */
        function getFilenameFromFile(image) {
            const filetype = image.type.split('/')[1];
            const filename = image.name.split('.')[0];
            return `${filename}.${filetype}`;
        }

        /**
         * Builds the encoded path through the folders where the file should be placed.
         * 
         * @param {String} folder the folder after root, should be argtype.
         */
        function buildFolderPath(folder) {
            const today = new Date();

            if (angular.isDefined(folder) && folder !== null) {
                return `%2F${rootFolder}%2F${folder}%2F${today.getFullYear()}%2F${1 + today.getMonth()}%2F`;
            } else {
                return `%2F${rootFolder}%2Fundefined%2F${today.getFullYear()}%2F${1 + today.getMonth()}%2F`;
            }
        }


        /**
         * Builds the filepath to send request to Sirv with.
         * !!! works only for api requests, not for image urls !!!
         * 
         * @param {String} filename the filename to create a filepath to.
         * @param {folder} folder  the folder to use for the filepath.
         * @return {String} Returns a string value of the filepath to send for requests to Sirv.
         */
        function buildEncodedFilepath(filename, folder) {
            return buildFolderPath(folder) + filename;
        }

        /**
         * Builds the decoded filepath from the filename and folder and returns it.
         * 
         * @return decoded filepath for the filename and folder given.
         */
        function buildDecodedFilepath(filename, folder) {
            return (buildFolderPath(folder) + filename).replaceAll('%2F', '/');
        }


        /**
         * Builds the image url from the given pathname using the company url.
         * 
         * @return {String} image url for the image in the given pathname.
         */
        function buildImageUrl(filename, folder) {
            let filePath = buildFolderPath(folder) + encodeURIComponent(filename);
            return companyUrl + filePath.replaceAll('%2F', '/');
        }


        /**
         * Makes a call to the database procedure registered with the given method using the given parameters.
         * 
         * @param {any} method the number the database procedure is registered with.
         * @param {any} parameters parameters to call the database procedure with.
         */
        let call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };


        /**
         * Retrieves the object with the token in and sets the tokenExpiration.
         * 
         * @return {Promise<any>} promise containing token data.
         */
        let getToken = function () {
            tokenExpiration = Date.now();

            let deferred = $q.defer();

            $http({
                method: 'POST',
                url: 'https://api.sirv.com/v2/token',
                data: clientApi,
                headers: {
                    'content-type': 'application/json'
                },
                withCredenitals: true,
                disableRedirect: true
            }).then((response) => {

                if (angular.isUndefined(response.data)) {
                    deferred.resolve(null);
                } else {
                    tokenExpiration += response.data.expiresIn;
                    token = response.data.token;

                    deferred.resolve(response);
                }

            }, function errorResponse(response) {
                deferred.reject(response);
            });

            return deferred.promise;
        };

        let hasTokenExpired = function () {
            return tokenExpiration === null || tokenExpiration <= Date.now();
        };


        /**
         * Retrieves the client api data from the database and sets it to the clientApi letiable.
         */
        let getClientApi = function () {
            let deferred = $q.defer();

            call(2470).then((response) => {
                if (response[0].sirv_client_id.length === 0 || response[0].sirv_client_secret.length === 0) {
                    deferred.reject(clientApi);
                } else {
                    clientApi = {
                        clientId: response[0].sirv_client_id,
                        clientSecret: response[0].sirv_client_secret
                    }

                    companyUrl = response[0].sirv_company_url;

                    deferred.resolve(clientApi);
                }
            });
            return deferred.promise;
        };

        /**
         * Sets the client api if necessary and requests a new token if token has expired.
         * 
         * @param {any} clientInfo a map with `clientId` and `clientSecret` for requesting token.
         * 
         * @return {Promise<boolean>} Returns a promise containing false if connection could not be established, or 
         * a promise containing true if connection was established.
         */
        let establishConnection = function (clientInfo) {
            let deferred = $q.defer();

            let connectionOk = false;

            if (angular.isObject(clientInfo) && angular.isDefined(clientInfo.clientId) && clientInfo.clientId !== null && clientInfo.clientId !== '') {

                clientApi = clientInfo;

                getToken().then((result) => {
                    token = result.data.token;
                    connectionOk = true;
                    deferred.resolve(connectionOk);
                }, () => deferred.resolve(connectionOk));

            } else {

                if (hasTokenExpired()) {
                    getClientApi().then(() => getToken().then((result) => {

                        if (result === null) {
                            deferred.reject(connectionOk);
                        } else {
                            token = result.data.token;
                            connectionOk = true;
                            deferred.resolve(connectionOk);
                        }

                    }, (_) => {
                        deferred.reject(false);
                    }));

                } else {
                    connectionOk = true;
                    deferred.resolve(connectionOk);
                }
            }

            return deferred.promise;
        };


        /**
         * General API call to the sirv api.
         *
         * @param {String} method http method to call.
         * @param {String} pathname the pathname after 'https://api.sirv.com/v2/files' for the api call.
         * @param {any} data Data to send with the api method.
         */
        let sirvApi = function (method, pathname, data, contentType) {
            method = angular.isDefined(method) ? method : null;
            pathname = angular.isDefined(pathname) ? pathname : null;
            contentType = angular.isDefined(contentType) ? contentType : 'application/json';
            data = angular.isDefined(data) ? data : null;

            if (method === null || pathname === null) return;

            let deferred = $q.defer();

            establishConnection().then((response) => {
                if (response === true) {
                    //console.dir('established connection: ' + response);
                } else {
                    //console.dir('established connection: ' + response);
                }
            }, (response) => {
                //console.dir('established connection: ' + response);
            }).then(() => {
                $http({
                    method: method,
                    data: data,
                    url: sirvApiUrl + pathname,
                    headers: {
                        'content-type': contentType,
                        'authorization': 'Bearer ' + token
                    },
                    withCredenitals: true,
                    disableRedirect: true,
                }).then((result) => deferred.resolve(result));
            });

            return deferred.promise;
        }

        /**
         * Checks if the name of the given image already exists in the given folder.
         * 
         * @param {File} image the image that has the name to check for.
         * @param {String} folder the folder name to check if the image name exists in.
         * @return {Promise<void>} void promise.
         */
        let doesImageOfSameNameExist = function (image, folder) {
            let deferred = $q.defer();
            folder = angular.isDefined(folder) && folder !== null ? folder : rootFolder;

            let filename = image.name;
            let dirnameQuery = ' AND ' + 'dirname:' + folder;

            sirvApi('POST', searchFilesApiPath, { query: 'filename:' + filename + dirnameQuery }).then((response) => {

                if (response.data.total !== 0) {
                    deferred.resolve(true);
                } else {
                    deferred.resolve(false);
                }

            });

            return deferred.promise;

        }

        /**
         * Uploads the given image to sirv.
         * 
         * @param {File} image the image to upload to Sirv.
         * @param {String} folder (optional) string of the foldername to upload image to.
         * @return {Promise<void>} void promise.
         */
        let uploadSirvImage = function (image, folder) {
            let deferred = $q.defer();

            let data = new Blob([image], { type: image.type });
            let filename = image.name;

            doesImageOfSameNameExist(image, folder).then((nameExists) => {
                if (nameExists) {
                    filename = addDateToFilename(filename);
                }

                progressInfo.field = buildDecodedFilepath(filename, folder);

                let pathname = uploadFilesApiPath + buildEncodedFilepath(filename, folder);
                sirvApi('POST', pathname, data, image.type).then((_) => {
                    deferred.resolve({
                        imageUrl: buildImageUrl(filename, folder),
                        image: image,
                    });
                });
            });

            return deferred.promise;
        }

        /**
         * Uploads the given list of images and increments the steps in the progress modal.
         * 
         * @param {List<File>} images list of images to upload.
         * @param {String} folder the name of the folder to upload to.
         * @return {Promise<void>} void promise.
         */
        let uploadMultipleImages = function (images, folder) {
            let deferred = $q.defer();
            let increment = 1, skip = 0, step = 0;
            progressInfo.step = 0;
            let imageUrls = [];

            let doStep = function () {
                $timeout(function () {
                    if (progressInfo.step >= progressInfo.max || progress === 'ABORT') return deferred.promise;

                    step = progressInfo.step + skip;
                    progressInfo.step += increment;

                    modalService.progressInfo.step = progressInfo.step;
                    modalService.progressInfo.field = progressInfo.field;


                    uploadSirvImage(images[step], folder).then(function (data) {
                        imageUrls.push(data);
                        if (progressInfo.step === progressInfo.max) {
                            deferred.resolve(imageUrls);
                        }
                        doStep();
                    });

                });
            };

            doStep();

            return deferred.promise;
        };

        /**
         * 
         */
        //let searchImages = function ({includeDirnames = [], excludeDirnames = ['/.Trash'], extenstions = ['.jpg', '.jpeg', '.png', '.svg', '.webp'], sortBy = 'createdAt', descending = true }) {

        //    let excludeDirnamesQuery = excludeDirnames.length === 0 ? '' : excludeDirnames.forEach((dirname) => `-dirname: \\${dirname} AND `);
        //    excludeDirnamesQuery.slice(0, -5);

        //    let includeDirnamesQuery = includeDirnames.length === 0 ? '' : includeDirnames.forEach((dirname) => `dirname: \\${dirname} OR `);
        //    includeDirnamesQuery.slice(0, -4);

        //    let extensionsQuery = extenstions.length === 0 ? '' : extenstions.forEach((extension) => `extension: ${extension} OR `);
        //    extensionsQuery.slice(0, -4);

        //    let data = { query: `${excludeDirnamesQuery} AND ${includeDirnames} AND ${extensionsQuery}`, sort:  };

        //    sirvApi('POST', searchFilesApiPath, data);
        //}

        let service = {

            /**
             * Returns whether the layout info is loaded.
             */
            isReady: function () {
                return userService.ensureIsReady();
            },

            getClientId: function () {

            },

            getClientSecret: function () {

            },

            getSirvDomain: function () {

            },


            /**
             * Checks if there is a connection to the Sirv API. Returns a promise containing
             * true if connection is established, false if connection failed.
             * 
             * @param {any} clientInfo a map with `clientId` and `clientSecret` for requesting token.
             * @return {Promise<boolean>} true if connection is established, false if connection failed.
             */
            checkConnection: function (clientInfo) {
                let deferred = $q.defer();
                establishConnection(clientInfo).then(response => deferred.resolve(response));
                return deferred.promise;
            },

            /**
             * Takes either a list of images or one image and uploads to sirv.
             * 
             * @param {List<File> | File} images a list of images or one single image to upload to Sirv.
             * @param {String} folder the folder to add the the list of images to.
             * @return {Promise<void>} void promise.
             */
            uploadImages: function (images, folder) {
                let deferred = $q.defer();

                images = !(images instanceof Array) && images instanceof File ? [images] : images;

                progress = 'START';
                progressInfo.label = 'progressbar';
                progressInfo.field = '';
                progressInfo.max = images.length;
                progressInfo.step = 0;

                uploadMultipleImages(images, folder).then((imageUrls) => deferred.resolve(imageUrls));

                modalService.showProgress(progressInfo).then(function (value) {
                    progress = value;
                });

                return deferred.promise;
            },

            /**
             * Uploads the given image to sirv with the given folder (should be argtype). Returns a promise
             * continaing the image and the image url for sirv.
             * 
             * @param {File} image the image to upload to sirv.
             * @return {Promise<Object>} object containing the file uploaded and the image url.
             */
            uploadImage: function (image, folder) {
                let deferred = $q.defer();

                uploadSirvImage(image, folder).then((imageResponse) => deferred.resolve(imageResponse));

                return deferred.promise;
            },

            /**
             * Updates the file at the given filepath to update to the given updated filepath.
             * 
             * @param {String} filepathToUpdate the previous filepath to be updated.
             * @param {String} updatedFilepath the new updated filepath.
             * @param {String} folder should be the argtype.
             * @return {Promise<void>} void promise.
             */
            updateFilepath: function (filenameToUpdate, updatedFilename, folder) {
                let deferred = $q.defer();

                const filepathToUpdate = buildEncodedFilepath(filenameToUpdate, folder);
                const updatedFilepath = buildEncodedFilepath(updatedFilename, folder);

                sirvApi('POST', `${updateFilepathApiPath}${buildEncodedFilepath(filepathToUpdate)}to=${buildEncodedFilepath(updatedFilepath)}`, null).then((response) => {
                    console.log(response.filename);
                });

                return deferred.promise;
            },

            searchImages: function (query) {
                query = angular.isDefined(query) || query !== null ? query : '';

                let data = { query: 'filename:' + query }

                sirvApi('POST', searchFilesApiPath, data);
            },

            /**
             * Deletes the image at the given filepath.
             * 
             * @param {String} filepath the filepath for the file to delete.
             * @return {Promise<void>} void promise.
             */
            deleteImage: function (filepath) {
                let deferred = $q.defer();

                sirvApi('POST', `${deleteFilesApiPath}${filepath}`, null).then(() => deferred.resolve());

                return deferred.promise;
            },

            getImage: function () {

            },

            errorHandler: function () {

            },

        };

        return service;
    }]);
})();
