(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentBatchConsignmentsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2ConsignmentList: {
                records: []
            },
            loadP2ConsignmentList: function (consignmentBatchKeyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentList = {
                    method: 329,
                    parameters: {
                        consignmentbatch_keyno: consignmentBatchKeyno
                    }
                };

                // Prosedyre sp_ws_p2_consignment_list
                $ihttp.post(parmsP2ConsignmentList).then(function (data) {
                    angular.copy(data, service.p2ConsignmentList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();