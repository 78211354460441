(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentShipmentsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadConsignmentShipmentsSettings: function () {
            	return p2DataTaskService.call(1101, {});
            },
            loadBusinessCoList: function () {
                return p2DataTaskService.call(104, {});
            },
            loadStockPlaceList: function (businessco_no) {
                return p2DataTaskService.call(622, {
                    businessco_no: businessco_no
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            loadBusinessCoNoChanged: function (businessco_no_from) {
                return p2DataTaskService.call(1103, {
                    businessco_no_from: businessco_no_from
                });
            },
            loadConsignmentShipmentsList: function (settingsConsignmentShipments) {
                return p2DataTaskService.call(1102, settingsConsignmentShipments);
            }
        };

        return service;
    }]);
})();