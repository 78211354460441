(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2OffDutyData', {
        templateUrl: 'views/components/views/p2OffDutyData/p2OffDutyData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'p2OffDutyDataService', 'economyService', function ($stateParams, stateService, utilityService, modalService, translateService, p2OffDutyDataService, economyService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2OffDutyDataKeyno = $stateParams.p2_offdutydata_keyno;
            let labourNo = $stateParams.labour_no;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
            	selectListLabours: [],
            	selectListOffDutyCodes:[],
            	dtUpdate: true,
                dateChanged: false,
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                p2OffDutyDataService.getEdit({
                    p2_offdutydata_keyno: p2OffDutyDataKeyno,
                    labour_no: labourNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadLabours = function () {
                economyService.listLabours({ activestatus: 'ACT' }).then(function (result) {
                    angular.copy(result, vm.model.selectListLabours);
                });
            };

            let loadOffDutyCodes = function () {
                p2OffDutyDataService.listOffDutyCodes().then(function (result) {
                    angular.copy(result, vm.model.selectListOffDutyCodes);
                });
            };

            let autoPickFromToTime = function (what) {
                p2OffDutyDataService.getAutoTime({
                    labour_no: vm.model.edit.labour_no,
                    datetime_from: vm.model.edit.datetime_from,
                    datetime_to: vm.model.edit.datetime_to
                }).then(function (response) {
                    try {
                        if (what !== 'to') {
                            if (parseInt(response[0].datetime_from.substr(11, 2)) > 0) vm.model.edit.datetime_from = response[0].datetime_from;
                        }

                        if (what !== 'from') {
                            if (parseInt(response[0].datetime_to.substr(11, 2)) > 0) vm.model.edit.datetime_to = response[0].datetime_to;
                        }
                    } catch (e) { }
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2OffDutyDataService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].status_no !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].status_message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    p2OffDutyDataService.deleteItem({ p2_offdutydata_keyno: vm.model.edit.p2_offdutydata_keyno }).then(function (response) {
                        if (response[0].status_no !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                                message: response[0].status_message,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'datetime_from':
                        if (utilityService.validateParmsValue(vm.model.edit.datetime_from) !== true) return;
                        
                        if (vm.model.edit.datetime_from !== '1970.10.10 ') {
                            autoPickFromToTime('from');
                        } else {
                            autoPickFromToTime('both');
                        }
                        break;
                    case 'datetime_to':
                        if (utilityService.validateParmsValue(vm.model.edit.datetime_to) !== true) return;

                        if (vm.model.edit.datetime_to !== '1970.10.10 ') {
                            autoPickFromToTime('to');
                        } else {
                            autoPickFromToTime('both');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadLabours();
                loadOffDutyCodes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
