(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttInputMulti', {
        transclude: true,
        templateUrl: 'views/components/directives/ttInputMulti/ttInputMulti.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',
            ttReadonly: '<',        // NOT IMPLEMENTED FULLY
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttTextAlign: '@',       // NOT IMPLEMENTED FULLY
            ttPlaceholder: '@',     // NOT IMPLEMENTED FULLY
            ttButtons: '<',
            ttItemId: '@',          
            ttRequired: '@',        
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttStyle: '<',           // BJS 20220810
            ttOptions: '<',         // JLR 20231009 configurationobject, takes all other component properteties as properties, supports both camelCase and snake_case notation with or without tt. Object needs to be reinstantiated to reflect changes in its properties.
        },
        controller: ['$q', '$element', '$timeout', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($q, $element, $timeout, layoutService, eventService, translateService, us, ttDirectivesService) {
            let vm = this;
            let onDestroy = [];

            vm.ttReadonly = vm.ttReadonly || true;

            vm.hideLabel = false;
            vm.required = false;


            vm.style = {
                base: {},
                group: {},
                groupButton: {
                    //paddingTop: '0',      //changed from 4px
                    //paddingBottom: '0',
                    //paddingLeft: '12px',
                    //paddingRight: '12px'
                },
                label: {},
                input: {},
                button: {},
                buttonText: {
                    //color: 'white'
                },
                iconButtonText: {
                    //color: 'white',
                    fontWeight: 'bold',
                    marginLeft: '3px'
                },
                icon: {},
                labelAlwaysOnTop: false,     // Deprecated, use ttLabelView instead. - JLR 20230622
                //transclude: {
                //    //display: 'contents', 
                //    width: '100%',
                //    overflow: 'hidden',
                //    height: 'auto'
                //}
            };

            vm.class = {
                base: '',
                label: '',
                input: ''
            };

            vm.id = {
                input: uuid()
            };

            vm.translations = {
                ttLabel: '',
                ttPlaceholder: '',
            };

            let setClasses = (labelAlwaysOnTop) => {
                vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
            };

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            vm.onClick = (btn, event) => ttDirectivesService.onButtonClick({ button: btn, controller: vm, event: event });

            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                if (changes.ttOptions?.currentValue) {
                    ttDirectivesService.setOptions(vm, changes);
                }

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
                    vm.style.input.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttPlaceholder) && us.isStringValue(changes.ttPlaceholder.currentValue) && changes.ttPlaceholder.currentValue !== changes.ttPlaceholder.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttPlaceholder = changes.ttPlaceholder.currentValue;
                    } else {
                        translateService.translate(changes.ttPlaceholder.currentValue).then((translation) => vm.translations.ttPlaceholder = translation);
                    }
                }

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                //if (angular.isDefined(changes.ttInputs) && angular.isArray(changes.ttInputs.currentValue)) {
                //    for (let i = 0; i < changes.ttInputs.currentValue.length; i++) {
                //        let input = changes.ttInputs.currentValue[i];

                //        if (input.sizes && input.sizes.length > 1) {
                            
                //            if (input.color.startsWith('input-')) {
                //                input.classes = 'tt-input__button--' + btn.color.split('-')[1];
                //            } else {
                //                input.classes = 'tt-input__button--' + btn.color;
                //            }
                //        } else {
                //            input.classes = 'tt-input__button--primary';
                //        }
                //    }
                //}

                if (angular.isDefined(changes.ttButtons) && angular.isArray(changes.ttButtons.currentValue)) {
                    for (var i = 0; i < changes.ttButtons.currentValue.length; i++) {
                        var btn = changes.ttButtons.currentValue[i];

                        if (btn.id === '$email') {
                            btn.icon ??= 'far fa-envelope';
                            btn.color ??= 'primary';
                            btn.type ??= 'primary';
                            btn.onClick ??= () => { window.open('mailto:' + vm.ttModel, '_self'); return; };
                        } else if (btn.id === '$phone') {
                            btn.icon ??= 'fas fa-mobile-alt';
                            btn.color ??= 'primary';
                            btn.type ??= 'primary';
                            btn.onClick ??= () => { window.open('tel:' + vm.ttModel, '_self'); return; };
                        }

                        if (angular.isDefined(btn.color) && btn.color.length > 0) {
                            if (btn.color.startsWith('btn-')) {
                                btn.classes = 'tt-input__button--' + btn.color.split('-')[1];
                            } else {
                                btn.classes = 'tt-input__button--' + btn.color;

                            }
                        } else {
                            btn.classes = 'tt-input__button--primary';
                        }

                        btn.show = us.toBoolean(btn.show, true);
                        btn.showIcon = btn.show === true && angular.isDefined(btn.icon) && angular.isString(btn.icon) && btn.icon !== null && btn.icon.trim().length > 0;
                        btn.showText = btn.show === true && angular.isDefined(btn.text) && angular.isString(btn.text) && btn.text !== null && btn.text.trim().length > 0;
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }
            };

            var deregBatchTranslations = translateService.on(vm.translations.batch, 'changed', function (translations) {
                angular.copy(translations, vm.translations.batch);

                deregBatchTranslations();
            });

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);

                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
