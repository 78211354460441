(function () {
    'use strict';

    angular.module("imApp").factory("acReportLayoutMenuService", ['$ihttp', function ($ihttp) {
        var service = {
            getAcReportLayoutMenu: function (parms) {
                return $ihttp.post({
                    method: 2802,
                    parameters: parms || {}
                });
            },
            renumberGrid: function (menu) {
                return $ihttp.post({
                    method: 2809,
                    parameters: menu
                });
            },
            copyObj: function (menu) {
                return $ihttp.post({
                    method: 2814,
                    parameters: menu
                });
            },
            deleteItem: function (menu) {
                return $ihttp.post({
                    method: 2816,
                    parameters: menu
                });
            }
        };

        return service;
    }]);
})();