(function() {
    'use strict';

    angular.module("imApp").factory("orderSpecialService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var atou = function (data) { // Utility function for base64decode with unicode support
            return decodeURIComponent(escape(atob(data)));
        }

        let definedAndNotNull = function (parm) {
            return defined(parm) && parm !== null;
        }

        let defined = function (parm) {
            return angular.isDefined(parm);
        }

        var service = {
            getCustomerList: function (parameters) {
                return call(2517, parameters || {});
            },
            getProdKeynameList: function (parameters) {
                return call(2528, parameters || {});
            },
            getEquipmentList: function (parameters) {
                //return call(1365, parameters || {});
                return call(2578, parameters || {});
            },
            getDeliveryMethods: function (parameters) {
                return call(1693, parameters || {});
            },
            getLabourers: function () {
                return call(1790, {});
            },
            getCurretLabourer: function () {
                return call(572, {});
            },
            getStockplaces: function () {
                return call(2815, {});
            },
            updateDriver: function (parameters) {
                return call(1717, parameters || {});
            },
            save: function (parameters) {
                return call(2538, parameters || {});
            },
            remember: function (rememberId, value, method, isBase) {
                return call(definedAndNotNull(method) ? method : 616, { // for use if need to have a different method
                    is_base64: defined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: defined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (rememberId, method) {
                return call(definedAndNotNull(method) ? method : 973, { variablename: rememberId }); // for use if need to have a different method
            }
        }

        return service;
    }]);
})();
