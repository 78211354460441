(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productMoveChoice', {
        templateUrl: 'views/components/views/productMoveChoice/productMoveChoice.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'productMoveChoiceService', function ($stateParams, stateService, modalService, translateService, productMoveChoiceService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let prodId = $stateParams.prod_id;
            let compKeyno = $stateParams.comp_keyno;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                edit: {},
                selectListMoveTo: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            productMoveChoiceService.getProductMoveChoice({
                prod_id: prodId,
                comp_keyno: compKeyno
            }).then(function (result) {
                angular.copy(result[0], vm.model.edit);

                loadMoveTo();
            });

            let loadMoveTo = function () {
                productMoveChoiceService.listMoveTo(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.selectListMoveTo);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productMoveChoiceService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
