(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("idServerService", ['$ihttp', function ($ihttp) {
        var getUrl = function(method) {
            return 'id-api/' + method;
        };

        var post = function (parms, method) {
            return $ihttp.post(parms, { noAccept: true, headers: { "Content-Type": 'application/json' } }, getUrl(method));
        };

        var service = {
            currentUserDetails: function () {
                return post({}, 'currentUserDetails');
            },

            userList: function (page, pageSize, searchTerm) {
                return post({ page: page, pageSize: pageSize, searchTerm: searchTerm }, 'userList');
            },

            userDetails: function (userName) {
                return post({ userName: userName }, 'userDetails');
            },

            clientList: function (page, pageSize, searchTerm) {
                return post({ page: page, pageSize: pageSize, searchTerm: searchTerm }, 'clientList');
            },

            addUser: function (userName, email, phone, password) {
                return post({ userName: userName, email: email, phone: phone, password: password }, 'addUser');
            },

            updateUser: function (userName, email, phone) {
                return post({ userName: userName, email: email, phone: phone }, 'updateUser');
            },

            removeUser: function (userName) {
                return post({ userName: userName }, 'removeUser');
            },

            addToClient: function (userName) {
                return post({ userName: userName }, 'addToClient');
            },

            removeFromClient: function (userName) {
                return post({ userName: userName }, 'removeFromClient');
            },

            addToRole: function (userName, roleName) {
                return post({ userName: userName, roleName: roleName }, 'addToRole');
            },

            removeFromRole: function (userName, roleName) {
                return post({ userName: userName, roleName: roleName }, 'removeFromRole');
            },

            addCompanyAdmin: function (userName) {
                return service.addToRole(userName, 'CompanyAdmin');
            },

            removeCompanyAdmin: function (userName) {
                return service.removeFromRole(userName, 'CompanyAdmin');
            },

            sendResetPassword: function (userName, sendEmail, sendSms) {
                return post({ userName: userName, sendEmail: sendEmail, sendSms: sendSms }, 'sendResetPassword');
            },

            resetPassword: function (userName, password) {
                return post({userName: userName, password: password}, 'resetPassword');
            },

            addTwoFactor: function (userName, type) {
                return post({ userName: userName, type: type }, 'add2fa');
            },

            removeTwoFactor: function (userName) {
                return post({ userName: userName }, 'remove2fa');
            }
        };

        return service;
    }]);
})();
