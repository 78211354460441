(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("notificationsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            notifications: {
                item_glyphicon: '',
                item_id: '1',
                item_name: 'test',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            addNotifications: function (destination) {
                if (angular.isUndefined(destination)) return;

                if (destination.charAt(0) == '/') {
                    destination = destination.toString().substring(1); //Removes the first instance of '/'
                }

                var allDest = destination.split('/');
                var state = allDest[0];
                var parms = [];

                for (var i = 1; i < allDest.length; i++) {
                    parms.push(allDest[i]);
                }

                //var state0 = destination.split('/', 0);
                //var state1 = destination.split('/', 1);
                //var state2 = destination.split('/', 2);
                ////var parms = destination.Substring(state.length, destination.length - state.length);
                //var parms0 = destination.toString().substring(state2[1].length + 2); //Removes the first instance of '/'
                //var parms2 = destination.toString().substring(state[0].length).split('/'); //Removes the first instance of '/'
                //state = state.toString().substring(1); //Removes the first instance of '/'
                //state = state.toString(); //Removes the first instance of '/'

                service.notifications.records.push({
                    item_glyphicon: '',
                    item_id: service.notifications.records.length.toString(),
                    item_name: destination,
                    //item_parms: { "custact_no": "611400" },
                    //item_parms: "611400",
                    //item_state: 'state',
                    item_parms: parms,
                    item_state: state,
                    orderby: ''
                });
            },
            //removeNotifications: function (index) {
            //}
            countNotifications: function () {
                return service.notifications.records.length;
            },
            loadNotifications: function (webpage_name) {
                var deferred = $q.defer();

                service.loaded = true;
                deferred.resolve();

                //var parms_develop_menu = {
                //    method: 53,
                //    parameters: {
                //        webpage_name: webpage_name
                //    }
                //};

                //$ihttp.post(parms_develop_menu).then(function (data) {
                //    service.develop_menu.records.length = 0;

                //    for (var i = 0; i < data.length; i++) {
                //        service.develop_menu.records.push(data[i]);
                //    }

                //    service.loaded = true;

                //    deferred.resolve();
                //});

                return deferred.promise;
            }
        };

        return service;
    }]);
})();