(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("srExpensesService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadSrExpensesGet: function (argtype, argvalue) {
				return p2DataTaskService.call(1323, {
					argtype: argtype,
					argvalue: argvalue
				});
			},
			loadSrExpensesList: function (argtype, argvalue, bg_srexpenses_main, date_fom, date_tom, dateselector_index, date_fomtom) {
				return p2DataTaskService.call(1322, {
					argtype: argtype,
					argvalue: argvalue,
					bg_srexpenses_main: bg_srexpenses_main,
					date_fom: date_fom,
					date_tom: date_tom,
					dateselector_index: dateselector_index,
					date_fomtom: date_fomtom
				});
			}
		};

		return service;
	}]);
})();