(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockCountData', {
        templateUrl: 'views/components/views/stockCountData/stockCountData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'stockCountDataService', function ($stateParams, stateService, stockCountDataService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
            
            vm.model = {
                p2StockCountDataGet: {},
                p2WStockCountDataItemList: {
                    records: []
                }
            };
    
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
            
            stockCountDataService.loadP2StockCountDataGet($stateParams.stockcountdata_keyno).then(function (data) {
                vm.model.p2StockCountDataGet = angular.copy(data[0]);
            });
            
            stockCountDataService.loadP2WStockCountDataItemList($stateParams.stockcountdata_keyno).then(function (data) {
                angular.copy(data, vm.model.p2WStockCountDataItemList.records);
            });
            
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'stockget':
                        go({ stock_keyno: vm.model.p2StockCountDataGet.stock_keyno });
                        break;
                }
            };
            
    		//############################
    		//## DIRECTION BUTTON FUNCTION
    		//############################
    
            vm.goToStockCountDataItem = function () {
                stateService.go('stockcountdataitem', {
                    stockcountdataitem_keyno: '0',
                    argtype: 'stock_keyno',
                    argvalue: vm.model.p2StockCountDataGet.stock_keyno
                });
            };
        }]
    });
})();