(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('custactCalcPick', {
        templateUrl: 'views/components/views/custactCalcPick/custactCalcPick.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'utilityService', 'stateService', 'modalService', 'logisticService', 'custactCalcPickService', function ($stateParams, $timeout, utilityService, stateService, modalService, logisticService, custactCalcPickService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            let vm = this;
            let timerFilterValueNew = null;
            let timerCustactCalcPickComponentList = null;
            let timerBgCustactCalcView = null;
            let timerFilterValue = null;
            let timerUrlParms = null;

            vm.model = {
                filterValueButtons: [
                    { id: 'calc', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => vm.goCalc() },
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.onFilterValueChanged('') }
                ],
                custactCalcView: {
                    active: 'INFO',
                    buttons: [
                        { id: 'INFO', label: 'bg_custactcalc_view_info', onClick: () => selectCustactCalcView('INFO') },
                        { id: 'PICK', label: 'bg_custactcalc_view_pick', onClick: () => selectCustactCalcView('PICK') },
                        { id: 'PICKED', label: 'bg_custactcalc_view_picked', onClick: () => selectCustactCalcView('PICKED') }
                    ]
                },
                p2CustactGet: {},
                p2WCustactCalcPickSettings: {},
                p2WCustactCalcPickComponentList: {
                    records: []
                },
                selectListStockPlaces: []
            };

            function selectCustactCalcView(selected) {
                vm.model.custactCalcView.active = selected;
                loadP2PortalUserVariableSetWsBgCustactCalcView();
                loadP2WCustactCalcPickComponentList();
            }

            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################

            let loadP2CustactGet = function () {
                custactCalcPickService.genericFunction(28, {
                    custact_no: $stateParams.custact_no
                }).then(function (data) {
                    vm.model.p2CustactGet = angular.copy(data[0]);

                    //loadP2WCustactCalcPickComponentList();
                });
            };

            let loadP2WCustactCalcPickSettings = function () {
                custactCalcPickService.genericFunction(874, {
                    p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                    custact_no: $stateParams.custact_no
                }).then(function (data) {
                    vm.model.p2WCustactCalcPickSettings = angular.copy(data[0]);

                    if (vm.model.custactCalcView.buttons.map((button) => button.id).includes(vm.model.p2WCustactCalcPickSettings.bg_custactcalc_view)) {
                        vm.model.custactCalcView.active = vm.model.p2WCustactCalcPickSettings.bg_custactcalc_view;
                        loadP2WCustactCalcPickComponentList();
                    }

                    loadStockPlaces();
                });
            };

            let loadP2WCustactCalcPickComponentList = function () {
                if (angular.isDefined(timerCustactCalcPickComponentList)) {
                    $timeout.cancel(timerCustactCalcPickComponentList);
                }

                timerCustactCalcPickComponentList = $timeout(function () {
                    if (utilityService.validateParmsValue(vm.model.p2WCustactCalcPickSettings.stockplace_id) !== true) return;

                    vm.model.p2WCustactCalcPickComponentList.records = [];

                    if (vm.model.custactCalcView.active && vm.model.custactCalcView.active !== 'INFO') {
                        custactCalcPickService.genericFunction(872, {
                            p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                            custact_no: $stateParams.custact_no,
                            bg_custactcalc_view: vm.model.custactCalcView.active,
                            stockplace_id: vm.model.p2WCustactCalcPickSettings.stockplace_id
                        }).then(function (data) {
                            angular.copy(data, vm.model.p2WCustactCalcPickComponentList.records);
                        });
                    }
                }, 50);
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.p2WCustactCalcPickSettings.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                logisticService.loadStockPlaceList(vm.model.p2WCustactCalcPickSettings.businessco_no).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            let loadP2PortalUserVariableSetWsBgCustactCalcView = function () {
                if (angular.isDefined(timerBgCustactCalcView)) {
                    $timeout.cancel(timerBgCustactCalcView);
                }

                let variableNameBgCustactCalcView = 'w_custactcalcpick.bg_custactcalc_view' + '/' + $stateParams.p2_pofinish_keyno + '/' + $stateParams.custact_no;

                timerBgCustactCalcView = $timeout(function () {
                    if (angular.isDefined(variableNameBgCustactCalcView) && variableNameBgCustactCalcView !== '' && vm.model.custactCalcView.active) {
                        custactCalcPickService.genericFunction(616, {
                            variablename: variableNameBgCustactCalcView,
                            variablevalue: vm.model.custactCalcView.active
                        });
                    }
                }, 50);
            };

            let loadP2PortalUserVariableSetWsFilterValue = function () {
                if (angular.isDefined(timerFilterValue)) {
                    $timeout.cancel(timerFilterValue);
                }

                let variableNameFilterValue = 'w_custactcalcpick.filtervalue' + '/' + $stateParams.p2_pofinish_keyno + '/' + $stateParams.custact_no;

                timerFilterValue = $timeout(function () {
                    if (angular.isDefined(variableNameFilterValue) && variableNameFilterValue !== '' && angular.isDefined(vm.model.p2WCustactCalcPickSettings.filtervalue)) {
                        custactCalcPickService.genericFunction(616, {
                            variablename: variableNameFilterValue,
                            variablevalue: vm.model.p2WCustactCalcPickSettings.filtervalue
                        });
                    }
                }, 200);
            };

            let loadP2PortalUserVariableSetWsUrlParms = function () {
                if (angular.isDefined(timerUrlParms)) {
                    $timeout.cancel(timerUrlParms);
                }

                let variableNameUrlParms = 'w_custactcalcpick' + '/' + $stateParams.p2_pofinish_keyno;

                timerUrlParms = $timeout(function () {
                    if (angular.isDefined(variableNameUrlParms) && variableNameUrlParms !== '') {
                        custactCalcPickService.genericFunction(616, {
                            variablename: variableNameUrlParms,
                            variablevalue: $stateParams.custact_no
                        });
                    }
                }, 50);
            };

            let loadStockSearchScan = function () {
                if (utilityService.validateParmsValue(vm.model.p2WCustactCalcPickSettings.stockplace_id) !== true) return;

                custactCalcPickService.loadStockSearchScan('trmenu', vm.model.p2WCustactCalcPickSettings.filtervalue, vm.model.p2WCustactCalcPickSettings.stockplace_id).then(function (data) {
                    stateService.go(data[0].webpagename, data[0].parms);
                });
            };

            //#######################
            //## BUTTON LIST FUNCTION
            //#######################

            vm.filterValueBtnListFunc = function (item_func) {
                if (item_func == 'erase_item') {
                    vm.model.p2WCustactCalcPickSettings.filtervalue = '';
                } else if (item_func == 'calc_item') {
                    vm.goCalc();
                }
            };

            vm.goCalc = function () {
                modalService.showCalculator(vm.model.p2WCustactCalcPickSettings.filtervalue).then(function (value) {
                    vm.model.p2WCustactCalcPickSettings.filtervalue = value;
                });
            };

            //############################
            //## DIRECTION BUTTON FUNCTION
            //############################

            vm.goToDocuments = function (index) {
                stateService.go('documents', {
                    argtype: 'custact_no',
                    argvalue: $stateParams.custact_no
                });
            };

            vm.goToPictures = function (index) {
                stateService.go('pictures', {
                    argtype: 'custact_no',
                    argvalue: $stateParams.custact_no
                });
            };

            vm.goToProductSearch = function (index) {
                if (angular.isDefined(timerFilterValueNew)) {
                    $timeout.cancel(timerFilterValueNew);
                }

                let variableNameFilterValueNew = 'w_custactcalcpick.filtervalue_new';

                timerFilterValueNew = $timeout(function () {
                    if (angular.isDefined(variableNameFilterValueNew) && variableNameFilterValueNew !== '' && angular.isDefined(vm.model.p2WCustactCalcPickSettings.filtervalue)) {
                        custactCalcPickService.genericFunction(616, {
                            variablename: variableNameFilterValueNew,
                            variablevalue: vm.model.p2WCustactCalcPickSettings.filtervalue
                        }).then(function (data) {
                            loadStockSearchScan();

                            //stateService.go('productsearch', {
                            //    functionname: 'trmenu'
                            //});
                        });
                    }
                }, 200);
            };

            vm.onStockplaceIdChanged = function (value) {
                vm.model.p2WCustactCalcPickSettings.stockplace_id = value;

                if (value) loadP2WCustactCalcPickComponentList();
            }

            vm.onFilterValueChanged = function (value) {
                vm.model.p2WCustactCalcPickSettings.filtervalue = value;

                loadP2PortalUserVariableSetWsFilterValue();
            }

            vm.$onInit = function () {
                loadP2CustactGet();
                loadP2WCustactCalcPickSettings();
                loadP2PortalUserVariableSetWsUrlParms();
            }
        }]
    });
})();
