(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ediDestinationService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2EdiDestinationGet: function (edi_destination_keyno) {
                return p2DataTaskService.call(988, {
					edi_destination_keyno: edi_destination_keyno
				});
            },
            loadP2EdiDestinationTypeExtList: function () {
                return p2DataTaskService.call(990, {});
            },
            saveP2EdiDestination: function (p2EdiDestinationGet) {
				return p2DataTaskService.call(989, p2EdiDestinationGet);
			}
        };

        return service;
    }]);
})();