(function () {
    'use strict';

    angular.module("imApp").factory("factoringBuntsComponentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            getFactoringBunts: function () {
                return p2DataTaskService.call(1954, {});
            },
            listFactoringBuntGrid: function (settingFactoring) {
                return p2DataTaskService.call(1955, settingFactoring);
            },
            createNew: function (settingFactoring) {
                return p2DataTaskService.call(1957, settingFactoring);
            }
        }

        return service;
    }]);
})();