//(function() {
//    'use strict';

//    var module = angular.module('imApp');

//    module.directive('ieResponsiveBreakpoints', ['$timeout', '$window', '$rootScope', 'responsive', 'eventService', function ($timeout, $window, $rootScope, responsive, eventService) {
//            return {
//                restrict: 'A',
//                scope: {},
//                template: '' +
//                    '<div class="device-xx visible-xx"></div>' +
//                    '<div class="device-xs visible-xs"></div>' +
//                    '<div class="device-sm visible-sm"></div>' +
//                    '<div class="device-md visible-md"></div>' +
//                    '<div class="device-lg visible-lg"></div>' +
//                    '<div class="device-xl visible-xl"></div>' +
//                    '<div class="device-xxl visible-xxl"></div>',
//                link: function($scope) {
//                    var isBreakpoint = function (alias) {
//                        return ($('.device-' + alias).is(':visible'));
//                    };

//                    var getBreakpoint = function() {
//                        var newSize = '';

//                        if (isBreakpoint('xx')) {
//                            newSize = 'xx';
//                        }
//                        if (isBreakpoint('xs')) {
//                            newSize = 'xs';
//                        }
//                        if (isBreakpoint('sm')) {
//                            newSize = 'sm';
//                        }
//                        if (isBreakpoint('md')) {
//                            newSize = 'md';
//                        }
//                        if (isBreakpoint('lg')) {
//                            newSize = 'lg';
//                        }
//                        if (isBreakpoint('xl')) {
//                            newSize = 'xl';
//                        }
//                        if (isBreakpoint('xxl')) {
//                            newSize = 'xxl';
//                        }

//                        return newSize;
//                    };

//                    var checkBreakpoint = function(_, data) {
//                        data = data || false;

//                        var newSize = getBreakpoint();

//                        if ((newSize !== $scope.currentSize) || (data.force === true)) {
//                            var oldSize = $scope.currentSize;

//                            $scope.currentSize = newSize;
//                            responsive.current = newSize;

//                            $rootScope.$broadcast('event:responsive-breakpoint', { oldSize: oldSize, newSize: newSize });
//                            eventService.trigger('event:responsive-breakpoint', { oldSize: oldSize, newSize: newSize });
//                        }
//                    };

//                    var waitForFinalEvent = function() {
//                        var timers = {};

//                        return function(callback, ms, uid) {
//                            if (timers[uid]) {
//                                $timeout.cancel(timers[uid]);
//                            }

//                            timers[uid] = $timeout(callback, ms);
//                        };
//                    }();

//                    var timestring = new Date();

//                    $rootScope.$on('event:responsive-breakpoint-check', checkBreakpoint);

//                    angular.element($window).bind('resize', function() {
//                        waitForFinalEvent(function() {
//                            checkBreakpoint();

//                            $rootScope.$broadcast('event:responsive-changed', { current: responsive.current });
//                            eventService.trigger('event:responsive-changed', { current: responsive.current });
//                        }, 100, timestring.getTime()); //}, 250, timestring.getTime()); This is the original RHE 20171206
//                    });

//                    checkBreakpoint();
//                }
//            };
//        }
//    ])
//    .factory("responsive", function() {
//        var service = {
//            current: '',
//            sizes: {
//                //0:   0,
//                //025: 2.08333333,
//                //050: 4.16666665,
//                //075: 6.25,
//                1:   8.33333333,
//                //125: 10.41666667,
//                //150: 12.5,
//                //175: 14.58333333,
//                2:   16.66666667,
//                //225: 18.75,
//                //250: 20.83333333,
//                //275: 22.91666667,
//                3:   25,
//                //325: 27.08333333,
//                //350: 29.16666667,
//                //375: 31.25,
//                4:   33.33333333,
//                //425: 35.41666667,
//                //450: 37.5,
//                //475: 39.58333333,
//                5:   41.66666667,
//                //525: 43.75,
//                //550: 45.83333333,
//                //575: 47.91666667,
//                6:   50,
//                //625: 52.08333333,
//                //650: 54.16666667,
//                //675: 56.25,
//                7:   58.33333333,
//                //725: 60.41666667,
//                //750: 62.5,
//                //775: 64.58333333,
//                8:   66.66666667,
//                //825: 68.75,
//                //850: 70.83333333,
//                //875: 72.91666667,
//                9:   75,
//                //925: 77.08333333,
//                //950: 79.16666667,
//                //975: 81.25,
//                10:  83.33333333,
//                //1025: 85.41666667,
//                //1050: 87.5,
//                //1075: 89.58333333,
//                11:   91.66666667,
//                //1125: 93.75,
//                //1150: 95.83333333,
//                //1175: 97.91666667,
//                12:   100,
//            }
//        };

//        return service;
//    });
//})();
