(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srUserAdmin', {
        templateUrl: 'views/components/views/srUserAdmin/srUserAdmin.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'webPageMenuService', 'srUserAdminService', function(stateService, webPageMenuService, srUserAdminService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                webPageName: stateService.getCurrentName(),
                itemsListWebPageMenu: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            webPageMenuService.loadWebPageMenuList(vm.model.webPageName).then(function (data) {
                angular.copy(data, vm.model.itemsListWebPageMenu);
            });
        }]
    });
})();