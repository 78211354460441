(function () {
	'use strict';
	
	var module = angular.module("imApp");

	module.factory("deliveryMethodService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			deliveryMethodGet: function (id) {
				return p2DataTaskService.call(386, { deliverymethod_no: id });
			},
			returnDeliveryMethodsList: function () {
				return p2DataTaskService.call(1598, {});
			},
			transcarrierList: function () {
				return p2DataTaskService.call(388, {});
			},
			transcarrierProductList: function (id) {
				return p2DataTaskService.call(389, {
					transcarrier_keyno: id
				});
			},
			deliveryMethodSave: function (parms) {
				return p2DataTaskService.call(391, parms);
			},
			deliveryMethodDelete: function (id) {
				return p2DataTaskService.call(392, {
					deliverymethod_no: id
				});
			}
		};

        return service;
    }]);
})();
