import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';
import { GraphMailService } from '../../../../../app/modules/microsoft-graph/services/graph-mail.service';

(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttNavbar', {
        templateUrl: 'views/components/directives/ttNavbar/ttNavbar.template.html?v=' + module.version,
        controllerAs: 'vm',
        transclude: true,
        bindings: {},
        controller: ['$animate', '$element', '$ihttp', '$sessionStorage', '$rootScope', 'stateService', 'userService', 'imHeaderService', 'layoutService', 'translateService', 'p2NotificationsService', 'modalFavoritesFactory', 'rememberService', 'ttWebPageMenuService', 'mainMenuService', 'ttNavbarService', 'graphAuthService', function ($animate, $element, $ihttp, $sessionStorage, $rootScope, stateService, userService, imHeaderService, layoutService, translateService, p2NotificationsService, modalFavoritesFactory, rememberService, ttWebPageMenuService, mainMenuService, ttNavbarService, graphAuthService) {
            let vm = this;
            let onDestroy = [];
            vm.version = module.version;

            vm.model = {
                header: {},
                get: {},
                counter: 0,
                notify: false,
                helpdoc: true,
                favorites: true,
                fixed: true,
                backgroundColor: null,
                currentCrumb: '',
                breadcrumbs: [],
                showBreadcrumbs: true, // only applicable if breadcrumbView is 'toggle'
                breadcrumbView: 'false', // 'false', 'true' or 'toggle'
                sidebarOpen: true,
                menuItems: [],
                globalSearch: '',
                searchModalOpen: false,
                searchResult: [],
                globalSearchMobile: false,
                darkmode: false,
                pinned: []
            };

            vm.id = {
                globalSearch: crypto.randomUUID(),
                modal: uuid(),
            }

            vm.ready = false;

            vm.mediaQueries = {};

            vm.class = {
                nav: '',
                button: '',
            };

            vm.style = {
                fontSize: '',
                button: {},
                sidemenu: {},
                modal: {},
                breadcrumbs: {},
                breadcrumb: {
                    fontSize: '12px'
                },
                buttonIcon: {}
            };

            vm.translations = {
                navbar_favorites: '',
                navbar_home: '',
                navbar_sing_out: '',
                navbar_notifications: '',
                navbar_helpdoc: '',
                navbar_close_sidemenu: '',
                navbar_open_sidemenu: '',
                navbar_go_back: '',
                switch_to_light_mode: '',
                switch_to_dark_mode: '',
            }

            vm.gotoItem = function (item) {
                if (item?.item_state === 'favorites') {
                    vm.openFavorites();
                } else {
                    stateService.go(item.item_state, item.item_parms, { reload: true });
                }
                if (vm.mediaQueries.laptop.matches === false) {
                    vm.toggleSidebar(false);
                }

                vm.model.globalSearchMobile = false;
            }

            vm.toggleGlobalSearchMobile = function (value) {
                setTimeout(() => { // to let selection process before hiding.
                    vm.model.globalSearchMobile = (value ?? !vm.model.globalSearchMobile);

                    setTimeout(() => { // to wait so the input field can be reached.
                        if (vm.model.globalSearchMobile === true && vm.mediaQueries.tablet.matches === false) {
                            let globalSearchInput = document?.getElementById(vm.id.globalSearch);

                            if (globalSearchInput) {
                                globalSearchInput.select();
                            }
                        }
                    });
                }, 200);
            }

            vm.toggleDarkMode = function () {
                const mode = vm.model.darkmode === true ? '1' : '2';

                if (window.matchMedia('(min-width: 768px)').matches === true) {
                    userService.updateTheme(mode);
                    ttNavbarService.saveThemeSetting('big', mode);
                } else {
                    userService.updateMobileTheme(mode);
                    ttNavbarService.saveThemeSetting('small', mode);
                }

                if (document.getElementsByTagName('html')?.[0].classList.contains('dark')) {
                    vm.model.darkmode = true;
                } else {
                    vm.model.darkmode = false;
                }
            }

            vm.goHome = function () {
                stateService.go('mainmenu');
            }

            vm.goNotifications = function () {
                if (vm.mediaQueries.laptop.matches === false) {
                    vm.toggleSidebar(false);
                }
                stateService.go('p2notifications');
            }

            vm.goHelpDoc = function () {
                if (vm.model.helpdoc === true) {
                    if (vm.mediaQueries.laptop.matches === false) {
                        vm.toggleSidebar(false);
                    }
                    stateService.go('helpdoc', 'webpage/' + stateService.getCurrentName());
                }
            }

            vm.openFavorites = async function () {
                if (!vm.model.favorites) return;

                let favorites = await getFavorites();

                let favoritesModal = modalFavoritesFactory.$create({
                    header: vm.model.header,
                    list: favorites?.length > 0 ? favorites : []
                });

                let value = await favoritesModal.show();

                let favoriteList = [];

                value.list.forEach((item) => {
                    if (item?.myfavorites_keyno && item.willGetDeleted) {
                        favoriteList.push({
                            myfavorites_keyno: item.myfavorites_keyno,
                            item_name: item.item_name,
                            item_state: item.item_state,
                            item_parms: item.item_parms,
                            orderby: item.orderby || 0,
                            function: 'DELETE'
                        });
                    } else if (!item.myfavorites_keyno && !item.willGetDeleted) {
                        favoriteList.push({
                            myfavorites_keyno: 0,
                            item_name: item.item_name,
                            item_state: item.item_state,
                            item_parms: item.item_parms,
                            orderby: item.orderby || 0,
                            function: 'NEW'
                        });
                    } else if (item?.myfavorites_keyno && !item.willGetDeleted && item.hasUpdate) {
                        favoriteList.push({
                            myfavorites_keyno: item.myfavorites_keyno,
                            item_name: item.item_name,
                            item_state: item.item_state,
                            item_parms: item.item_parms,
                            orderby: item.orderby || 0,
                            function: 'UPDATE'
                        });
                    }
                });

                setFavorites(favoriteList);
            }

            vm.goBack = function () {
                if (vm.mediaQueries.laptop.matches === false) {
                    vm.toggleSidebar(false);
                }
                stateService.back();
            }

            vm.toggleSidebar = function (state) {
                vm.model.sidebarOpen = state ?? !vm.model.sidebarOpen;
                userService.updateSideMenu(vm.model.sidebarOpen);

                if (vm.mediaQueries.laptop.matches === false) {
                    rememberService.remember('w_mainmenu_navbar.sidebar_open.mobile', vm.model.sidebarOpen === true ? '1' : '0');
                } else {
                    rememberService.remember('w_mainmenu_navbar.sidebar_open.desktop', vm.model.sidebarOpen === true ? '1' : '0');
                }

                vm.style.breadcrumbs.width = vm.model.sidebarOpen ? `calc(100vw - 18rem)` : `100vw`;
            }

            vm.onSearchFocus = function () {
                let search = document.getElementById(vm.id.globalSearch);

                if (search) {
                    search.select();
                }
            }

            vm.signOut = function () {
                stateService.signOut();
            }

            function call(method, parameters) {
                return $ihttp.post({
                    method: method,
                    parameters: parameters || {}
                });
            }

            function getFavorites() {
                return call(2235, {});
            }

            function setFavorites(list) {
                list.forEach((item) => {
                    return call(2236, {
                        myfavorites_keyno: item.myfavorites_keyno,
                        item_name: item.item_name,
                        item_state: item.item_state,
                        item_parms: item.item_parms,
                        orderby: item.orderby || 0,
                        function: item.function
                    });
                });
            }

            function setClasses() {
                if (vm.model.backgroundColor) {

                    switch (vm.model.backgroundColor) {
                        case 'danger':
                            vm.class.nav += ' tt-navbar--danger';
                            break;
                        default:
                            break;
                    }
                }

                //if (vm.model.fixed === true) vm.class.nav += ' navbar-fixed-top';
            };

            async function setNotificationBadge() {
                const data = (await p2NotificationsService.loadUnreadNotifications())?.records ?? [];

                vm.model.counter = {
                    read: 0,
                    unread: data.length
                }

                if (vm.model.counter.unread > 0) {
                    vm.model.notify = true;

                    let notificationCounterElement = $element.find('.notification-counter')[0];

                    if (notificationCounterElement) {
                        $animate.addClass(notificationCounterElement, 'notify-bounce').then(function () {
                            notificationCounterElement = $element.find('.notification-counter')[0];

                            if (notificationCounterElement) $animate.removeClass(notificationCounterElement, 'notify-bounce');
                        });
                    }
                } else {
                    vm.model.notify = false;
                }
            }

            async function translate() {
                const data = await translateService.translateBatch(vm.translations);
                Object.keys(vm.translations).forEach((key) => {
                    if (data?.[key]) {
                        vm.translations[key] = data[key];
                    }
                })
            }

            vm.setBreadcrumbs = function (show) {
                vm.model.currentCrumb = imHeaderService.getDocumentTitle();
                vm.model.showBreadcrumbs = show ?? false;
                userService.updateShowBreadcrumbs(vm.model.showBreadcrumbs);

                if ((vm.model.showBreadcrumbs && vm.model.breadcrumbView === 'toggle') || vm.model.breadcrumbView === 'true') {
                    document.querySelector('.cf').children[0].style.marginTop = '7rem';
                } else {
                    document.querySelector('.cf').children[0].style.marginTop = '3.8rem';
                }
            }

            vm.pinPage = async function (crumb) {
                if (crumb) {
                    stateService.setPin(crumb, true);
                    await ttNavbarService.savePagePinned({ pinned_url: crumb.path, pinned_name: crumb.title });
                    vm.model.pinned = await ttNavbarService.getPinnedPages();
                    //stateService.setPinnedList(vm.model.pinned);
                }
            }

            //vm.unpinPageCrumb = function (crumb) {
            //    if (crumb) {
            //        stateService.setPin(crumb, false)
            //        crumb.pinned = false;
            //        ttNavbarService.savePageUnpinned({ pinned_url: crumb.path, pinned_name: crumb.title })
            //    }
            //};

            vm.unpinExistingPinned = async function (page) {
                if (page) {
                    await ttNavbarService.savePageUnpinned(page);

                    if (stateService.getCurrentPath() === page.pinned_url) {
                        stateService.back();
                    }
                    vm.model.pinned = await ttNavbarService.getPinnedPages();
                    //stateService.setPinnedList(vm.model.pinned);
                }
            };

            vm.gotoPin = function (pin) {
                stateService.pinnedGo(pin.pinned_url);
            };

            vm.goto = function (crumb) {
                stateService.goBackTo(crumb);
            }

            //53


            vm.onGlobalSearchTextChanged = function () {
                mainMenuService.searchGlobalAll({ global_searchtext: vm.model.globalSearch }).then((result) => {
                    vm.model.searchResult = result;

                    //result.map((item) => {
                    //    let icons = '';
                    //    if (item.item_glyphicon.startsWith('fa')) {
                    //        icons = 'fad fa' + item.item_glyphicon.replace(item.item_glyphicon.split('-')[0], '');
                    //    } else {
                    //        icons = 'glyphicon ' + item.item_glyphicon;
                    //    }
                    //    return { ...item, item_glyphicon: icons };
                    //});

                    if (result.length > 0 && vm.model.globalSearch) vm.model.searchModalOpen = true;
                    setModalPosition();
                }).finally(() => {
                    if (vm.model.globalSearch.trim() === '') vm.model.searchModalOpen = false;
                });
            }


            /**
             * Checks whether the given element or its children is the given event target.
             * 
             * @param {HTMLElement} element the element and its children to check.
             * @param {EventTarget} target the target from an event to search for.
             * @returns true if the given element or any of its children is the event target, false if not.
             */
            function isElementInTarget(element, target) {
                if (element === target) return true;

                for (let i = 0; i < element?.children?.length; i++) {
                    let child = element.children[i];
                    let found = isElementInTarget(child, target);

                    if (found) {
                        return true;
                    }
                };

                return false;
            }

            /**
             * Toggles the modal based on requirements met with a click event.
             * 
             * @param {MouseEvent} event click event on the document.
             */

            const toggleModalOnEvent = function (event) {
                let globalSearch = document?.getElementById(`${vm.id.globalSearch}`);

                if (isElementInTarget(globalSearch, event.target) && vm.model.globalSearch && vm.model.searchModalOpen === false && vm.model.searchResult.length > 0) {
                    vm.model.searchModalOpen = true;
                    setModalPosition();
                    return;
                }

                let modal = document?.getElementById(`${vm.id.modal}`);
                let shouldModalClose = false;

                if (modal && globalSearch) shouldModalClose = isElementInTarget(modal, event.target) === false && !isElementInTarget(globalSearch, event.target) === false;

                vm.model.searchModalOpen = shouldModalClose;
            }

            /**
             * Sets right position of the modal based on available space in the window.
             */
            function setModalPosition() {
                let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
                vm.style.modal.right = +scrollbarWidth + 'px';
                vm.style.modal.width = `calc(0.4rem + (3.4rem * 4) + (0.2rem * 4) + 20rem)`;
            }

            async function setHeader(header) {
                vm.ready = false;

                if (!vm.model?.header || header !== vm.model.header) {
                    vm.model.header = angular.copy(header);

                    await setNotificationBadge();

                    if (vm.model.header?.text?.trim() && vm.model.header.translate) {
                        vm.model.header.text = await translateService.translate(vm.model.header.text);
                    }

                    if (vm.model.header?.backButton?.text?.trim() && vm.model.header.backButton.translate) {
                        vm.model.header.backButton.text = await translateService.translate(vm.model.header.backButton.text);
                    }

                    vm.model.breadcrumbs = $sessionStorage.stateChain;
                    //vm.model.breadcrumbs.pop();
                }

                vm.setBreadcrumbs(vm.model.showBreadcrumbs);

                vm.ready = true;
            }

            async function getInitialData() {
                vm.model.get = (await ttNavbarService.get())[0];
                try {

                    if (vm.mediaQueries.laptop.matches === false) {
                        vm.model.sidebarOpen = getSideBarState(vm.model.get.sidebar_open_mobile) ?? false;
                    } else {
                        //if (vm.model.get.sidebar_open === '1' || vm.model.get.sidebar_open === '0') {
                        //vm.model.sidebarOpen = vm.model.get.sidebar_open === '1';
                        vm.model.sidebarOpen = getSideBarState(vm.model.get.sidebar_open_desktop) ?? true;
                    }

                    userService.updateSideMenu(vm.model.sidebarOpen);
                } catch (error) {
                    console.log(error);
                }
            }

            function getSideBarState(sidebarState) {
                if (sidebarState === '1' || sidebarState === '0') {
                    return sidebarState === '1';
                }

                return undefined;
            }

            // ## ANGULAR LIFECYCLE

            let initializing = false;


            vm.$onInit = async function () {
                vm.mediaQueries = layoutService.getMediaQueries();
                translate()
                getInitialData();
                vm.model.breadcrumbs = $sessionStorage.stateChain;
                vm.model.activeState = $sessionStorage.stateServiceIndex;
                vm.model.activePin = $sessionStorage.pinIndex;

                vm.model.pinned = await ttNavbarService.getPinnedPages();

                graphAuthService.initialize();
                $rootScope.$on('stateChainChanged', async () => {
                    vm.model.breadcrumbs = $sessionStorage.stateChain;
                    vm.model.activeState = $sessionStorage.stateServiceIndex;
                    vm.model.activePin = $sessionStorage.pinIndex;

                    if (vm.model.activeState?.path === '/mainmenu') {
                        vm.model.pinned = await ttNavbarService.getPinnedPages();
                    }
                });
                layoutService.onLayoutChanged(onDestroy, (info) => {
                    // BJS 20240212 - Added initializing flag to avoid infinite loop with new LayoutService.
                    if (initializing) return;

                    initializing = true;
                    vm.style.breadcrumbs.width = vm.model.sidebarOpen ? `calc(100vw - 18rem)` : `100vw`;


                    vm.model.backgroundColor = info?.windowheadingcolor ?? null;
                    vm.model.breadcrumbView = info?.breadcrumbView ?? 'true';
                    vm.style.fontSize = info.fontSizes.textSize;

                    vm.setBreadcrumbs((vm.model.showBreadcrumbs && vm.model.breadcrumbView === 'toggle') || vm.model.breadcrumbView === 'true');

                    if (((vm.model.showBreadcrumbs && vm.model.breadcrumbs?.length >= 0 && vm.model.breadcrumbView === 'toggle') || vm.model.breadcrumbView === 'true')) {
                        vm.style.sidemenu.marginTop = '-3.2rem';
                    } else {
                        vm.style.sidemenu.marginTop = '0';
                    }

                    setClasses();

                    if (document.getElementsByTagName('html')?.[0].classList.contains('dark')) {
                        vm.model.darkmode = true;
                        //applyTheme('dark', document.querySelector('mgt-login'));
                    }

                    initializing = false;
                });


                if (vm.mediaQueries.laptop.matches === false) {
                    vm.toggleSidebar(false);
                }


                vm.model.menuItems = (await ttWebPageMenuService.load('mainmenu', false)).map((item) => {
                    item.item_glyphicon = item.item_glyphicon.replace('fas', 'fa');

                    return item;
                });

                document.addEventListener('click', toggleModalOnEvent);

                onDestroy.push(imHeaderService.on('stateChanged', setHeader));
                //await setHeader(imHeaderService.getCurrentHeader());
            }

            vm.$onChanges = function (changes) { };

            vm.$onDestroy = function () {

                document.removeEventListener('click', toggleModalOnEvent);
                ttDirectivesService.onDestroy(onDestroy);
            }

            // ## END ANGULAR LIFECYCLE

        }]
    });
})();
