(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectPoFinish', {
        templateUrl: 'views/components/views/selectPoFinish/selectPoFinish.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectPoFinishService', function ($stateParams, selectPoFinishService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;
            
            vm.model = {
                bgOpenOptions: {
                    selected: 'selectpofinish_open',
                    buttons: [
                        { id: 'selectpofinish_open', label: 'selectpofinish_open', color: 'primary', onClick: () => vm.model.bgOpenOptions.selected = 'selectpofinish_open' },
                        { id: 'selectpofinish_notopen', label: 'selectpofinish_notopen', color: 'primary', onClick: () => vm.model.bgOpenOptions.selected = 'selectpofinish_notopen' }
                    ]
                },
                bgFinishOptions: {
                    selected: 'selectpofinish_open_mine',
                    buttons: [
                        { id: 'selectpofinish_open_mine', label: 'selectpofinish_open_mine', color: 'primary', onClick: () => vm.model.bgFinishOptions.selected = 'selectpofinish_open_mine' },
                        { id: 'selectpofinish_open_other', label: 'selectpofinish_open_other', color: 'primary', onClick: () => vm.model.bgFinishOptions.selected = 'selectpofinish_open_other' }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {
                    filtervalue: ''
                },
                dataSource: [],
                p2WSelectPoFinishList: {
                    records: []
                },
                p2PohScan: {}
            };
            
            // ####################
            // LOAD PROCEDURE CALLS
            // ####################
    
            vm.loadP2WPaperLessPoGet = function () {
                selectPoFinishService.loadP2WPaperLessPoGet($stateParams.webpagename).then(function () {
                    vm.model.setting = selectPoFinishService.setting;

                    if (vm.model.bgOpenOptions.selected === 'selectpofinish_notopen') {
                        if ((angular.isDefined(vm.model.setting.stockplace_id) && vm.model.setting.stockplace_id !== '') && (angular.isDefined(vm.model.setting.operation_id) && vm.model.setting.operation_id !== '')) {
                            vm.loadP2WSelectPoFinishList();
                        }
                    }
                });
            };
    
            vm.loadPoFinish = function (selectType) {
                selectPoFinishService.loadPoFinish(selectType).then(function () {
                    vm.model.dataSource = selectPoFinishService.dataSource;

                    if (selectType === 'selectpofinish_notopen') {
                        vm.loadP2WPaperLessPoGet();
                    }
                });
            };
    
            vm.loadP2WSelectPoFinishList = function () {
                if ((angular.isDefined(vm.model.setting.stockplace_id) && vm.model.setting.stockplace_id !== '') && (angular.isDefined(vm.model.setting.operation_id) && vm.model.setting.operation_id !== '')) {
                    selectPoFinishService.loadP2WSelectPoFinishList(vm.model.setting.stockplace_id, vm.model.setting.operation_id).then(function () {
                        vm.model.p2WSelectPoFinishList = selectPoFinishService.p2WSelectPoFinishList;
                    });
                }
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgOpenOptions = function (value) {
                vm.model.bgOpenOptions.selected = value;

                if (vm.model.bgOpenOptions.selected === 'selectpofinish_notopen') {
                    vm.loadPoFinish(vm.model.bgOpenOptions.selected);
                } else {
                    vm.loadPoFinish(vm.model.bgFinishOptions.selected);
                }
            };
            
            vm.selectBgFinishOptions = function (value) {
                vm.model.bgFinishOptions.selected = value;

                vm.loadPoFinish(vm.model.bgFinishOptions.selected);
            };
            
            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ##################
            // SAVE ITEM FUNCTION
            // ##################
    
            vm.savePoFinish = function (item) {
                selectPoFinishService.savePoFinish(item);
            };
    
            vm.saveP2WSelectPoFinish = function (item) {
                selectPoFinishService.searchP2PohScan(item.barcode).then(function () {
                    vm.model.p2PohScan = selectPoFinishService.p2PohScan;

                    if (vm.model.p2PohScan.p2_pofinish_keyno > 0) {
                        selectPoFinishService.savePoFinish(vm.model.p2PohScan.p2_pofinish_keyno, $stateParams.webpagename);
                    }
                });
            };

            vm.$onInit = function () {
                vm.selectBgOpenOptions(vm.model.bgOpenOptions.selected);
                vm.selectBgFinishOptions(vm.model.bgFinishOptions.selected);
            }
            
        }]
    });
})();
