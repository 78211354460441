(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('economyMenu', {
        templateUrl: 'views/components/views/economyMenu/economyMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', function (stateService) {
            var vm = this;

            vm.model = {
                name: stateService.getCurrentName()
            };
        }]
    });
})();