(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectWorkingOrderService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            custactList: {
                records: []
            },
            loadCustactList: function (cust_name, cust_no, project_keyno, ok) {
                var deferred = $q.defer();

                var parms_loadCustactList = {
                    method: 27,
                    parameters: {
                        cust_name: cust_name,
                        cust_no: cust_no,
                        project_keyno: project_keyno,
                        ok: ok
                    }
                };

                $ihttp.post(parms_loadCustactList).then(function (data) {
                    service.custactList.records = [];

                    angular.forEach(data, function (item) {
                        service.custactList.records.push({ item_id: item.item_id, item_name: item.item_name, item_name_sub1: item.item_name_sub1, item_filtervalue: item.item_filtervalue });
                    });

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveSelectWorkingOrder: function (item) {
                var deferred = $q.defer();

                var parms_saveSelectWorkingOrder = {
                    method: 446,
                    parameters: {
                        custact_no: item.item_id,
                        webpagename: $stateParams.webpagename
                    }
                };

                $ihttp.post(parms_saveSelectWorkingOrder).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();