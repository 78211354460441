(function () {
    'use strict';

    angular.module('imApp').component('ttDynamic', {
        templateUrl: 'views/components/dynamics/components/ttDynamic/ttDynamic.template.html',
        controllerAs: 'vm',
        bindings: {
            ttOptions: '<'
        },
        controller: ['$timeout', '$scope', '$compile', '$uibModal', 'ttDynamicService', 'ttDatasourceManager', 'eventService', 'utilityService', 'rememberService', 'userService', function ($timeout, $scope, $compile, $uibModal, ttDynamicService, ttDatasourceManager, eventService, us, rememberService, userService) {
            var vm = this;

            let compElement = null;
            let templates = [];
            let remember = {};
            let rememberId = '';
            let shouldRemember = {};
            let elementsReady = false;

            vm.elementId = uuid();

            vm.developMode = false;
            vm.isRoot = false;

            userService.ensureIsReady().then(function () {
                vm.developMode = userService.developMode;
            });

            vm.onEditComponents = function () {
                var instance = $uibModal.open({
                    component: 'ttDynamicEditor',
                    resolve: {
                        parameters: function () {
                            return vm.ttOptions
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                instance.result.then(load, load);
            };

            vm.onEditDataSources = function () {
                var instance = $uibModal.open({
                    component: 'ttDatasourceEditor',
                    resolve: {
                        parameters: function () {
                            return vm.ttOptions
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                instance.result.then(load, load);
            };

            let dataChangedPromises = {};

            // When data in this element is changed
            vm.onDataChanged = function (value, modelId, isCallback) {
                isCallback = isCallback || false;

                if (isCallback === true) {
                    delete dataChangedPromises[modelId];

                    let parts = modelId.split('.');

                    let ds = parts[1];
                    let prop = parts[2];

                    let rid = ds + '.' + prop;

                    if (shouldRemember[rid] === true) {
                        if (angular.isUndefined(remember[ds])) {
                            remember[ds] = {};
                        }

                        remember[ds][prop] = value;

                        rememberService.remember(rememberId, angular.toJson(remember));
                    }

                    ttDatasourceManager.updateDatasource(vm.ttOptions.instance_id, ds, prop, value);
                } else {
                    if (angular.isDefined(dataChangedPromises[modelId])) {
                        $timeout.cancel(dataChangedPromises[modelId]);
                    }

                    dataChangedPromises[modelId] = $timeout(function () {
                        vm.onDataChanged(value, modelId, true);
                    }, 500);
                }
            };

            // when data in datasource is changed
            function onDatasourceDataChanged(datasourceId, propertyId, value) {
                if (angular.isUndefined(vm[datasourceId])) return;
                if (angular.isUndefined(vm[datasourceId][propertyId])) return;

                vm[datasourceId][propertyId] = value;
            };

            function load() {
                templates.length = 0;

                ttDynamicService.init(vm.ttOptions.route_id).then(function (response) {
                    if (compElement !== null) {
                        compElement.empty();
                    }

                    let dynamics = response.dynamics.sort(function (a, b) { return a.order - b.order; });

                    angular.forEach(dynamics, addComponent);

                    // Using timeout to ensure all properties are ready before adding elements.
                    $timeout(addElements);
                });
            };

            function buildProperties(parent_keyno, allProps) {
                let obj = {};

                var parentProps = allProps.filter(function (p) {
                    return p.parent_keyno === parent_keyno;
                });

                angular.forEach(parentProps, function (p) {
                    switch (p.type) {
                        case 'container':
                            var props = buildProperties(p.cp_keyno, allProps);

                            if (angular.isObject(props) && Object.keys(props).length > 0) {
                                obj[p.tag_name] = props;
                            }
                            break;
                        default:
                            if (p.value !== null) {
                                obj[p.tag_name] = p.value;
                            }
                            break;
                    }
                });

                return obj;
            };

            function addComponent(compdata) {
                let name = angular.isString(compdata.name) && compdata.name.length > 0
                    ? ' tt-name="' + compdata.name + '"'
                    : '';

                let template = "<" + compdata.tag_name + ' tt-keyno="' + compdata.keyno + '" tt-root-id="' + vm.ttOptions.instance_id +'"' + name + ' class="col-xs-12 sp-0"';

                var attributes = compdata.properties.filter(function (p) { return p.parent_keyno === 0; });

                angular.forEach(attributes, function (a) {
                    switch (a.type) {
                        case 'buttonactions':
                            let id = us.generateId(4, 'BtnAct', vm);

                            vm[id] = a.value;

                            template += ' tt-model="vm.' + id + '"';
                            break;

                        case 'container':
                            let containerId = us.generateId(4, 'Obj', vm);

                            vm[containerId] = buildProperties(a.cp_keyno, compdata.properties);

                            vm[containerId]._rootId = vm.ttOptions.instance_id;

                            template += ' ' + a.tag_name + '="vm.' + containerId + '"';

                            break;
                        case 'function':
                            break;
                        case 'expression':
                            if (a.value !== null && a.value.length > 0) {
                                template += ' ' + a.tag_name + '="' + a.value + '"';
                            }
                            break;
                        case 'jsonarray':
                            break;
                        case 'jsonobject':
                            template += ' tt-change="vm.onDataChanged($value, $modelId)"';

                            let value = angular.isUndefined(a.value)
                                ? ''
                                : a.value === null
                                    ? '' 
                                    : !angular.isString(a.value)
                                        ? ''
                                        : a.value.trim().length < 1
                                            ? ''
                                            : a.value.startsWith('vm.')
                                                ? a.value
                                                : 'vm.' + a.value;

                            if (value.length > 0) {
                                template += ' ' + a.tag_name + '="' + value + '"';

                                addDatasourceProperty(value, compdata.remember_value);
                            }
                            break;
                        default:
                            template += ' tt-change="vm.onDataChanged($value, $modelId)"';

                            if (a.value !== null && a.value.length > 0) {
                                template += ' ' + a.tag_name + '="' + a.value + '"';
                            }
                            break;
                    }
                });

                template += '></' + compdata.tag_name + '>';

                templates.push(template);
            };

            vm.$onInit = function () {
                rememberId = vm.ttOptions.route_id + '.data'

                vm.isRoot = vm.ttOptions.is_root || false;

                rememberService.getLastStatus(rememberId, true).then(setRemembered);

                load();
            };

            function addDatasourceProperty(id, remember_value) {
                var parts = id.split('.');

                if (parts.length !== 3) return;
                if (parts[0] !== 'vm') return;

                let ds = parts[1];

                let rid = ds + '.' + parts[2];

                shouldRemember[rid] = (remember_value || false);

                if (angular.isDefined(vm[ds])) return;

                let datasources = ttDatasourceManager.getDatasources(vm.ttOptions.instance_id);

                if (angular.isObject(datasources) !== true) return;
                if (angular.isUndefined(datasources[ds])) return;

                if (angular.isDefined(datasources[ds]) && datasources[ds] !== null) {
                    vm[ds] = datasources[ds].item;
                }

                ttDatasourceManager.subscribe(vm.ttOptions.instance_id, vm.elementId, onDatasourceDataChanged);
            };

            function addElements() {
                if (compElement === null) {
                    compElement = angular.element(document.getElementById(vm.elementId));
                }

                angular.forEach(templates, function (template) {
                    let insertedEl = $compile(template)($scope);

                    compElement.append(insertedEl);
                });

                elementsReady = true;
            };

            function setRemembered(data) {
                if (elementsReady !== true) {
                    $timeout(setRemembered, 100, true, data);
                    return;
                }

                angular.forEach(data, function (ds, key) {
                    if (angular.isUndefined(remember[key])) {
                        remember[key] = {};
                    }

                    angular.forEach(ds, function (val, prop) {
                        let rid = key + '.' + prop;

                        if (shouldRemember[rid] === true) {
                            remember[key][prop] = val;
                            vm[key][prop] = val;
                        }
                    });
                });
            };
        }]
    });
})();