(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderHeadMi', {
        templateUrl: 'views/components/views/orderHeadMi/orderHeadMi.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'orderHeadMiService', function ($stateParams, stateService, modalService, translateService, orderHeadMiService) {
            var vm = this;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                p2WOrderHeadMiGet: {},
                p2WOrderHeadMiCreateInvoice: {},
                gridReady: false,
                lockedSave: false,
                lockedManualCreate: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_orderheadmi_invoice_now_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            vm.loadP2WOrderHeadMiGet = function () {
                orderHeadMiService.genericFunction(740, {
                    order_internal_no: $stateParams.order_internal_no
                }).then(function (data) {
                    vm.model.p2WOrderHeadMiGet = angular.copy(data[0]);

                    vm.grid.dataTask.loadData = {
                        method: 2916,
                        parameters: {
                            order_internal_no: vm.model.p2WOrderHeadMiGet.order_internal_no
                        }
                    };

                    vm.model.gridReady = true;
                });
            };
            
        	vm.loadP2WOrderHeadMiGet();
            
            vm.createInvoice = function () {
                vm.model.lockedSave = true;
                
                orderHeadMiService.genericFunction(741, vm.model.p2WOrderHeadMiGet).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        if (vm.model.p2WOrderHeadMiGet.setting_goto_invoicehead == '1' && data[0].invoice_no) {
                            stateService.go('invoicehead', {
                                invoice_no: data[0].invoice_no
                            });
                        } else {
                            stateService.back();
                        }
                        vm.model.lockedSave = false;
                    }
                });
            };

            vm.createManualChanges = function () {
                vm.model.lockedManualCreate = true;

                var currentData = vm.grid.gridfunc.getAllRows();
                var selectedItems = [];

                angular.forEach(currentData, function (item) {
                    selectedItems.push({
                        orderline_keyno: item.orderline_keyno,
                        invoice_quantity_now: item.invoice_quantity_now
                    });
                });

                var mergeObj = angular.copy(vm.model.p2WOrderHeadMiGet);
                mergeObj.records = selectedItems;

                orderHeadMiService.createManualObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedManualCreate = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedManualCreate = false;
                    	stateService.back();
                    }
                });
            };
        }]
    });
})();
