(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('receivables', {
        templateUrl: 'views/components/views/receivables/receivables.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'receivablesService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, modalService, translateService, receivablesService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let variableNames = {
                businessco_no: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                bg_receivables_main: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgReceivablesMain: {
                    selected: 'ALL',
                    buttons: [
                        { id: 'ALL', label: 'bg_receivables_main_all', color: 'primary', onClick: () => setReceivablesMainView('ALL') },
                        { id: 'NOT_BOOKED', label: 'bg_receivables_main_not_booked', color: 'primary', onClick: () => setReceivablesMainView('NOT_BOOKED') },
                        { id: 'BOOKED', label: 'bg_receivables_main_booked', color: 'primary', onClick: () => setReceivablesMainView('BOOKED') }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                gridReady: false,
                getting: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_receivables_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setReceivablesMainView = function (id) {
                vm.model.bgReceivablesMain.selected = vm.model.setting.bg_receivables_main = id;

                rememberFunc('bg_receivables_main');
                loadGrid();
                vm.grid.gridfunc.rebind(true);
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	let deferred = $q.defer();

            	receivablesService.getReceivables().then(function (result) {
            		angular.copy(result[0], vm.model.setting);

                    setReceivablesMainView(vm.model.setting.bg_receivables_main);

            		$q.all([
                        loadGrid()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3006,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'bg_receivables_main':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.getChanges = function () {
                vm.model.getting = true;

                receivablesService.getTransactions(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.getting = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.getting = false;
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    vm.grid.gridfunc.rebind();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
