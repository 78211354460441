<tt-page>
    <tt-page-heading ttModel="p2_m_settings" [ttTranslate]="true"></tt-page-heading>
    <tt-input ttLabel="search" [(ttModel)]="model.setting.filterval" [ttButtons]="model.filterValueButtons"></tt-input>
    <tt-list class="col-xs-12 sp-0" [listItems]="model.itemsListSettings" designVar="s12t0" [filterVal]="model.setting.filterval"></tt-list>
</tt-page>

<!--
    <tt-input-buttons class="col-xs-12 sp-0 flex-1 height-full"
                      ttLabel="search"
                      [ttFocus]="false"
                      [ttModel]="model.setting.filterval"
                      [ttOnBlur]="onBlur('filterval')"
                      [ttButtons]="model.filterValueButtons"></tt-input-buttons>
-->
