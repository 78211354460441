<tt-button
    [ttText]="ttText"
    [ttTranslateText]="ttTranslateText"
    [ttLoadingText]="ttLoadingText"
    [ttTranslateLoadingText]="ttTranslateLoadingText"
    [ttBadge]="ttBadge"
    [ttLoading]="ttLoading"
    (ttClick)="ttClick.emit($event)"
    [ttIcon]="ttIcon"
    [ttIconAlign]="ttIconAlign"
    [ttIconPullRight]="ttIconPullRight"
    [ttType]="ttType"
    [ttDisabled]="ttDisabled"
    [ttButtonType]="ttButtonType"
    [ttTextAlign]="ttTextAlign"
    [ttStyle]="ttStyle"
    [ttSpin]="ttSpin"
    [ttPrinter]="ttPrinter"
></tt-button>
