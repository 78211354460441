(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travelWage', {
        templateUrl: 'views/components/views/travelWage/travelWage.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'travelWageService', 'typeaheadService', function ($stateParams, stateService, modalService, translateService, travelWageService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let travelWageId = $stateParams.travelwage_id;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                travelWageId: travelWageId,
                edit: {},
                selectListTravelWageTypes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                travelWageService.getTravelWage({ travelwage_id: travelWageId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            let loadTravelWageTypes = function () {
                travelWageService.listTravelWageTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListTravelWageTypes);
                });
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'account_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.lookUpSearchAccounts({
                            searchtext: vm.model.edit[id] || '',
                            businessco_no: vm.model.edit.businessco_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'account_name':
                        if (item) {
                            vm.model.edit.account_no = item?.item_id ?? '0';
                            vm.model.edit.account_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('account_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'account_name':
                        vm.model.edit.account_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.account_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('account_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'account_name':
                        vm.model.edit.account_no = '0';
                        vm.model.edit.account_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                travelWageService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadTravelWageTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
