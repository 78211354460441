(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentColliDetail', {
        templateUrl: 'views/components/views/consignmentColliDetail/consignmentColliDetail.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'consignmentColliDetailService', 'translateService', function ($stateParams, stateService, utilityService, modalService, consignmentColliDetailService, translateService) {
            var vm = this;

            var translations = {
                consignmentcollidetail_save_show_title: 'Varsel',
                consignmentcollidetail_save_show_ok_label: 'OK'
            };

            vm.model = {
                bgConsignmentColliDetail: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_consignmentcollidetail_basis', item_func: 'BASIS' },
                        { item_id: '1', item_name: 'bg_consignmentcollidetail_content', item_func: 'CONTENT' }
                    ]
                },
                consignmentColli: {},
                itemsListDetails: [],
                gridReady: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            consignmentColliDetailService.loadConsignmentColli({ consignmentcolli_keyno: $stateParams.consignmentcolli_keyno }).then(function(data){
                vm.model.consignmentColli = angular.copy(data[0]);
                vm.grid.dataTask.rememberId = 'w_consignmentcollidetail_carriers_grid';
                
                loadConsignmentColliDetails();
                
			    vm.grid.dataTask.loadData = {
                    method: 2668,
				    parameters: vm.model.consignmentColli
			    };
		        
		        vm.model.gridReady = true;
            });

            var loadConsignmentColliDetails = function () {
                if (utilityService.validateParmsValue(vm.model.consignmentColli.consignmentcolli_keyno) !== true) return;

                vm.model.itemsListDetails = [];

                consignmentColliDetailService.listConsignmentColliDetails({ consignmentcolli_keyno: vm.model.consignmentColli.consignmentcolli_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListDetails);
                });
            };

            vm.selectBgConsignmentColliDetail = function (item) {
                vm.model.bgConsignmentColliDetail.item_selected = item;
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'case':
                        stateService.go(state, { case_keyno: vm.model.consignmentColli.case_keyno });
                        break;
                    default:
                        break;
                }
            };

            vm.onChanged = function (value, field) {
                switch (field) {
                    case 'volume': { vm.model.consignmentColli.volume = value; break; }
                    case 'weight': { vm.model.consignmentColli.weight = value; break; }
                    case 'length': { vm.model.consignmentColli.length = value; vm.recalcVolume(); break; }
                    case 'width': { vm.model.consignmentColli.width = value; vm.recalcVolume(); break; }
                    case 'height': { vm.model.consignmentColli.height = value; vm.recalcVolume(); break; }
                    default: break;
                }
            }

            vm.recalcVolume = function () {
                vm.model.consignmentColli.volume = vm.model.consignmentColli.length * vm.model.consignmentColli.width * vm.model.consignmentColli.height / 1000000; // Volum i m3
            }

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var allData = vm.grid.gridfunc.getAllRows();
                var mergeObj = vm.model.consignmentColli;

                mergeObj.records = allData.map(function (a) {
                    return {
                        "carrier_keyno": a.carrier_keyno,
                        "quantity": a.quantity,
                        "consignmentcarrier_keyno": a.consignmentcarrier_keyno
                    };
                });

                consignmentColliDetailService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.consignmentcollidetail_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.consignmentcollidetail_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedSave = false;

                        stateService.go('consignment', {
                            consignment_keyno: $stateParams.consignment_keyno
                        });
                    }
                });
            };

		    translateService.translateBatch(translations).then(function (data) {
			    angular.forEach(translations, function (_, key) {
				    if (angular.isDefined(data[key])) {
					    translations[key] = data[key];
				    }
			    });
		    });
        }]
    });
})();
