(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('textileOlAdd', {
        templateUrl: 'views/components/views/textileOlAdd/textileOlAdd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'translateService', 'utilityService', 'modalService', 'rememberService', 'textileOlAddService', function ($stateParams, $timeout, $q, stateService, translateService, utilityService, modalService, rememberService, textileOlAddService) {
            var vm = this;

            var isDirty = false;

            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                orderLineKeyNo: $stateParams.orderline_keyno,
                //modelSearch: {
                //    item_id: '',
                //    item_name: ''
                //},

                textile: {
                    textilemodel_id: '-'//$stateParams.textilemodel_id
                },

                formDirty: false,
                settings: {},
                selectListCollections: [],
                selectListCategoryModels: [],
                //selectListTextileModels: [],
                selectListQc: [],
                itemsListTextilesUnedited: [],
                itemsListTextiles: [],
                itemsListTextilesEdited: [],
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                summary: {
                    head: 0,
                    active: 0,
                    total: 0
                }
            };

            var translations = {
                toladd_confirm_modal_title: '',
                toladd_confirm_modal_message: '',
                toladd_confirm_modal_ok: '',
                toladd_confirm_modal_cancel: ''
            };

            var onCellClose = function (data) {
                var rows = vm.ttGrid.gridfunc.getAllRows();

                vm.model.summary.active = 0;
                vm.model.summary.total = 0;

                angular.forEach(rows, function (row) {
                    if (data.cval === 'item_date') {
                        // SRS/BJS 20220504 - When changing date all rows should be set to same date.
                        row.item_date = data.change;
                    }
                    if (data.cval === 'item_discount') {
                        // SRS/BJS 20220525 - When changing item_discount all rows should be set to same discount.
                        row.item_discount = data.change;
                    }

                    var qty = parseFloat(row.item_qty)

                    if (qty > 0) {
                        var price = parseFloat(row.item_price);

                        vm.model.summary.active = vm.model.summary.active + (qty * price);
                    }
                });

                if (data.cval === 'item_date' || data.cval === 'item_discount') {
                   setTimeout(() => vm.ttGrid.gridfunc.refresh());
                } else {
                    vm.ttGrid.gridfunc.refreshAggregates();
                }

                vm.model.summary.total = vm.model.summary.head + vm.model.summary.active;
            };

            vm.ttBtnOptions = {
                save: {
                    visible: true
                }
            };

            vm.ttGrid = {
                dataTask: {
                    rememberId: 'textileoladd.list.ttGrid',             // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2218,
                        parameters: function () {
                            return vm.model.textile;
                        }
                    },
                    addRow: {                                           // NOT IMPLEMENTED
                        method: null,
                        parameters: null,
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                    },
                    saveData: {
                        method: function () { return vm.saveTextileOlAdd; },
                        parameters: {},
                        readAfterSave: false,
                        //autoSave: false,
                        //single: false
                    }
                },
                config: {                                               // TouchTime parameters configuring the grid
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: [                                      // List of custom buttons
                            //{ name: 'cancel', text: '', func: function () {  } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    onDataChanged: function () {
                        isDirty = true;
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: [                                        // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                        //{ field: 'item_qty', aggregate: 'sum' }
                    ],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (parms) {
                    switch (parms.data.func) {
                        case 'OnCellClose':
                            onCellClose(parms.data);
                            break;
                    }

                },
                gridfunc: null
            };

            var reloadGrid = function () {
                //console.log('reloading');
                vm.ttGrid.gridfunc.rebind();
            };

            var confirmIfDirty = function (onConfirmed, onCatch) {
                var deferred = $q.defer();

                var doJob = function () {
                    isDirty = false;

                    var result = onConfirmed();

                    if (angular.isDefined(result) && angular.isFunction(result.then)) {
                        result.then(function (data) {
                            deferred.resolve(data);
                        });
                    } else {
                        deferred.resolve(result);
                    }
                }

                var dirtyRows = angular.isUndefined(vm.ttGrid.gridfunc) || vm.ttGrid.gridfunc === null
                    ? []
                    : angular.isUndefined(vm.ttGrid.gridfunc.getDirtyRows)
                        ? []
                        : angular.isFunction(vm.ttGrid.gridfunc.getDirtyRows)
                            ? vm.ttGrid.gridfunc.getDirtyRows()
                            : [];

                if (dirtyRows.length < 1 || isDirty !== true) {
                    doJob();
                } else {
                    if (angular.isUndefined(onCatch) || angular.isFunction(onCatch) !== true) {
                        onCatch = function () { };
                    }

                    modalService.confirm({
                        title: translations.toladd_confirm_modal_title,
                        message: translations.toladd_confirm_modal_message,
                        okLabel: translations.toladd_confirm_modal_ok,
                        cancelLabel: translations.toladd_confirm_modal_cancel
                    })
                        .then(doJob)
                        .catch(onCatch);;
                }

                return deferred.promise;
            };

            var loadTextileOlAdd = function () {
                //console.log('loadTextileOlAdd');
                textileOlAddService.settings(vm.model.orderInternalNo).then(function (data) {
                    angular.copy(data[0], vm.model.settings);

                    if (parseFloat(vm.model.settings.total_amount_ordval) >= 0) {
                        vm.model.summary.head = parseFloat(vm.model.settings.total_amount_ordval);
                        vm.model.summary.total = vm.model.summary.head;
                    }
                });

                return confirmIfDirty(function () {
                    //console.log('Before GET....:',vm.model.textile.textilemodel_id);

                    textileOlAddService.loadTextileOlAddGet(vm.model.orderInternalNo, vm.model.orderLineKeyNo, vm.model.textile.textilemodel_id).then(function (data) {

                        vm.model.textile = angular.copy(data[0]);
                        //console.log('After GET....:',vm.model.textile.textilemodel_id);

                        loadTextileOlAddCollections();
                        loadTextileOlAddTextileModelCategory7s();
                        loadTextileOlAddQc();
                        loadTextileOlAdds();

                        vm.textileSelected({ item_id: vm.model.textile.textilemodel_id });
                    });

                });
            };

            var loadTextileOlAddCollections = function () {
                //console.log('loadTextileOlAddCollections');
                return confirmIfDirty(function () {
                    vm.model.selectListCollections = [];

                    textileOlAddService.loadTextileOlAddCollectionList().then(function (data) {
                        angular.copy(data, vm.model.selectListCollections);
                    });
                });
            };

            var loadTextileOlAddTextileModelCategory7s = function () {
                //console.log('loadTextileOlAddTextileModelCategory7s');
                return confirmIfDirty(function () {
                    if (utilityService.validateParmsValue(vm.model.textile.collection_keyno) !== true) return;

                    vm.model.selectListCategoryModels = [];

                    textileOlAddService.loadTextileOlAddTextileModelCategory7List(vm.model.textile.collection_keyno).then(function (data) {
                        angular.copy(data, vm.model.selectListCategoryModels);
                    });
                });
            };

            var loadTextileOlAddQc = function () {
                //console.log('loadTextileOlAddQc');
                return confirmIfDirty(function () {
                    if (utilityService.validateParmsWithValue(vm.model.textile.textilemodel_id) !== true) return;
                    if (utilityService.validateParmsEmptyValue(vm.model.textile.textilemqcsearch) !== true) return;

                    vm.model.selectListQc = [];

                    textileOlAddService.loadTextileOlAddQcList(vm.model.textile.textilemodel_id, vm.model.textile.textilemqcsearch).then(function (data) {
                        angular.copy(data, vm.model.selectListQc);

                        vm.model.textile.textileqc_id = vm.model.selectListQc[0].textileqc_id;
                        console.log('vm.model.textile.textileqc_id >>: ', vm.model.textile.textileqc_id);
                    });
                });
            };

            var loadTextileOlAdds = function () {
                //console.log('loadTextileOlAdds');
                return confirmIfDirty(function () {
                    vm.model.itemsListTextiles = [];

                    reloadGrid();
                });
            };

            var convertRow = function (row) {
                var item = angular.copy(row);

                item.textile_article = row.item_name;
                item.textile_date = new Date(row.item_date);
                item.textile_discount = parseFloat(row.item_discount);
                item.textile_disp_now = parseFloat(row.item_disp_now);
                item.textile_disp_tot = parseFloat(row.item_disp_tot);
                item.textile_model = row.item_model;
                item.textile_next_ddate = row.item_next_ddate;
                item.textile_price = parseFloat(row.item_price);
                item.textile_prod_id = row.item_prod_id;
                item.textile_prod_id_short = row.item_prod_id_short;
                item.textile_qty = parseFloat(row.item_qty);

                delete item.item_name;
                delete item.item_date;
                delete item.item_discount;
                delete item.item_disp_now;
                delete item.item_disp_tot;
                delete item.item_model;
                delete item.item_next_ddate;
                delete item.item_price;
                delete item.item_prod_id;
                delete item.item_prod_id_short;
                delete item.item_qty;

                return item;
            }

            function saveTextileOrderline(item) {
                var deferred = $q.defer();

                textileOlAddService.save(
                    item.textile_prod_id,
                    item.textile_qty,
                    vm.model.textile.order_internal_no,
                    item.textile_price,
                    item.textile_discount,
                    moment(item.textile_date).format('YYYY-MM-DD HH:mm:ss')
                ).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }

            vm.typeaheadSearch = function (value) {
                var deferred = $q.defer();

                confirmIfDirty(function () {
                    if (utilityService.validateParmsValue(vm.model.textile.collection_keyno) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.textile.textilemodelcategory7_keyno) !== true) return;

                    if (value == undefined) {
                        value = '';
                    }

                    textileOlAddService.searchTextileModel(vm.model.orderInternalNo, vm.model.textile.collection_keyno, vm.model.textile.textilemodelcategory7_keyno, vm.model.settings.ordertype_id, value).then(function (data) {
                        deferred.resolve(data);
                    });
                }, function () {
                    document.forms[0].elements[5].focus();

                    textileOlAddService.nullSearch().then(function (data) {
                        deferred.resolve(data);
                    });
                });

                return deferred.promise;
            }

            vm.textileSelected = function (value) {
                //console.log('textileSelected');
                try {
                    vm.model.textile.textilemodel_id = value.item_id;

                    loadTextileOlAddQc();

                    loadTextileOlAdds();

                    reloadGrid();
                } catch (error) { }
            }

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;

                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        if (items[step].item_id === '0') {
                            skip++;
                            step = vm.model.progressInfo.step + skip + failed;

                            doStep();
                        } else {
                            step = vm.model.progressInfo.step + skip + failed;

                            vm.model.progressInfo.field = items[step].textile_article;

                            modalService.progressInfo.field = vm.model.progressInfo.field;

                            saveTextileOrderline(items[step]).then(function (data) {
                                if (data[0].errorcode != '0') {
                                    modalService.show({
                                        type: 'warning',
                                        title: 'Varsel',
                                        message: data[0].errormessage,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();

                                                vm.model.progressInfo.step += increment;
                                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                                doStep();
                                            }
                                        }]
                                    });
                                } else {
                                    vm.model.progressInfo.step += increment;

                                    modalService.progressInfo.step = vm.model.progressInfo.step;
                                    modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                    doStep();
                                }
                            }, function (error) {
                                console.log('error');
                                console.dir(error);
                                vm.model.progressInfo.step += increment;

                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            });
                        }
                    });
                };

                doStep();
            };

            vm.saveTextileOlAdd = function () {
                vm.model.formDirty = false;
                vm.model.itemsListTextilesEdited = [];
                vm.model.lockedSave = true;
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';

                vm.model.progressInfo.field = '';
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';

                var dirtyRows = vm.ttGrid.gridfunc.getDirtyRows();

                angular.forEach(dirtyRows, function (row) {
                    if (parseFloat(row.item_qty) > 0) {
                        // BJS 20220316 - convert row to match save procedure for old grid
                        var item = convertRow(row);

                        vm.model.itemsListTextilesEdited.push(item);
                    }
                });

                //for (var u = 0; u < vm.grid.saveData.length; u++) {
                //    if (parseFloat(vm.grid.saveData[u].textile_qty) > 0) {
                //        vm.model.itemsListTextilesEdited.push(vm.grid.saveData[u]);
                //    }
                //}

                if (vm.model.itemsListTextilesEdited.length === 0) {
                    vm.model.lockedSave = false;
                    return;
                }

                angular.forEach(vm.model.itemsListTextilesEdited, function (item) {
                    if (item.item_id !== '0') {
                        vm.model.progressInfo.max++;
                    }
                });

                vm.activateProgress(vm.model.itemsListTextilesEdited);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT' || vm.model.runProgress === 'FINISH') {
                        vm.model.lockedSave = false;
                        textileOlAddService.remember('textileoladd.textilemodel_id_last', vm.model.textile.textilemodel_id).then(function () {
                            //$timeout(function () {
                            //    stateService.go('textileoladd', {
                            //        order_internal_no: vm.model.orderInternalNo,
                            //        orderline_keyno: vm.model.orderLineKeyNo,
                            //        textilemodel_id: '-'//vm.model.textile.textilemodel_id
                            //    }, {
                            //        reload: true,
                            //        inherit: false,
                            //        notify: true
                            //    });
                            //}, 200);
                            reloadGrid();
                        });
                    }
                });
            };

            vm.onCollectionChange = function (value) {
                //if (angular.isDefined(vm.grid.grid) && vm.grid.grid != undefined){
                //    vm.grid.data = [];
                //    vm.grid.saveData = [];
                //    var options = vm.grid.grid.getOptions();
                //    options.dataSource.data = [];
                //    vm.grid.grid.refresh();
                //}

                //console.log('onCollectionChange');

                if ((parseInt(value) > 0) && (vm.model.textile.collection_keyno != value)) {
                    textileOlAddService.remember('textileoladd.collection_keyno', value);
                }

                vm.model.textile.collection_keyno = value;

                loadTextileOlAddTextileModelCategory7s();
            };

            vm.onTextileModelCategory7Change = function (value) {
                //if (angular.isDefined(vm.grid.grid) && vm.grid.grid != undefined){
                //    vm.model.textile.modelSearch = '';
                //    vm.grid.data = [];
                //    vm.grid.saveData = [];
                //    var options = vm.grid.grid.getOptions();
                //    options.dataSource.data = [];
                //    vm.grid.grid.refresh();
                //}

                //console.log('onTextileModelCategory7Change');

                if ((parseInt(value) > 0) && (vm.model.textile.textilemodelcategory7_keyno != value)) {
                    textileOlAddService.remember('textileoladd.textilemodelcategory7_keyno', value);
                }

                vm.model.textile.textilemodelcategory7_keyno = value;

                //loadTextileOlAddTextileModels();
            };

            vm.onTextileQCChange = function (value) {
                //console.log('onTextileQCChange');
                if (value !== null) {
                    vm.model.textile.textileqc_id = value;
                }

                console.log('vm.model.textile.textileqc_id >>: ', vm.model.textile.textileqc_id);

                loadTextileOlAdds();
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });


            // #region ANGULAR FUNCTIONS

            //function prepareData() {
            //    //let deferred = $q.defer();

            //    let promises = [];

            //    promises.push(orderSpecialService.getStockplaces());
            //    promises.push(orderSpecialService.getRemember(vm.stockplace.remId));

            //    $q.all(promises).then(function (response) {
            //        angular.copy(response[0], vm.stockplace.list);

            //        if (response[1]?.[0]?.variablevalue && response[1][0].variablevalue !== '') {
            //            vm.stockplace.data.stockplace_id = angular.fromJson(response[1][0].variablevalue);
            //        }

            //        //deferred.resolve(response);
            //    }, function (error) {
            //        //deferred.reject(error);
            //    });

            //    //return deferred.promise;
            //}

            vm.$onInit = function () {
                loadTextileOlAdd();
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
