(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectOeeStateRemark', {
        templateUrl: 'views/components/views/selectOeeStateRemark/selectOeeStateRemark.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectOeeStateRemarkService', 'searchService', function ($stateParams, selectOeeStateRemarkService, searchService) {
            var vm = this;
            
            selectOeeStateRemarkService.loadP2OeeStateRemarksList($stateParams.oeestate_keyno).then(function () {
                vm.p2_oeestateremarks_list = selectOeeStateRemarkService.p2_oeestateremarks_list;
            });
            
            vm.saveToPreOeeData = function (item) {
                selectOeeStateRemarkService.rememberingToPreOeeData = true;

                searchService.p2OeeStateRemarksList.oeeStateRemarkKeyno = item.item_id;
                searchService.p2OeeStateRemarksList.oeeStateRemarkName = item.item_name;
                searchService.p2OeeStateRemarksList.showRemarkText = item.show_remarktext;
            };
        }]
    });
})();