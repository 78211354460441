(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ohDispatches', {
        templateUrl: 'views/components/views/ohDispatches/ohDispatches.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'translateService', 'modalService', 'ohDispatchesService', function ($stateParams, $q, utilityService, translateService, modalService, ohDispatchesService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let translations = {
                confirm_title: '',
                reminder_url_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: ''
            };

            vm.model = {
                setting: {},
                itemsListDispatches: []
            };

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            let loadSetting = function () {
                const deferred = $q.defer();

                ohDispatchesService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadDispatches();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadDispatches = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;
                
                return ohDispatchesService.listDispatches({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListDispatches);
                });
            };

            vm.openWebLinkDispatch = function (item) {
                if (item.trackandtrace_url > '') {
                    window.open(item.trackandtrace_url);
                } else {
                    modalService.confirm({
                        type: 'warning',
                        title: translations.confirm_title,
                        message: translations.reminder_url_message,
                        okLabel: translations.confirm_ok_label,
                        cancelLabel: translations.confirm_cancel_label
                    });
                }
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
