(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('campaignProducts', {
        templateUrl: 'views/components/views/campaignProducts/campaignProducts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['campaignProductsService', '$stateParams', 'stateService', function (campaignProductsService, $stateParams, stateService) {
            var vm = this;
            // vm.grid.dataTask.rememberId = vm.model.exportGrid.view_id;
            vm.model = {
                request: {
                    campaign_keyno: $stateParams.campaign_keyno,
                },
                method: 2225, // TODO: This should be in grid settings???
                gridReady: false
            };

            // TODO: Are there a better way to bail if not valid input argument for this page?
            if (vm.model.request.campaign_keyno == '') {
                return stateService.go('campaigns', {});
            }

            vm.grid = {
                dataTask: {
                    rememberId: 'campaignproducts.grid.list', // Id used to retrieve user configs for the grid
                    loadSetupId: null, // Uses default if null or undefined
                    loadData: { // Method id of the get data procedure
                        //method: 1863,
                        //parameters: { p2_exportproc_keyno: '5' }
                    },
                    addRow: {
                        method: null,
                        parameters: null
                    },
                    removeRow: {
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                config: { // TouchTime parameters configuring the grid
                    editColumns: [
                        //{ key: 'is_selected' },
                        //{ key: 'prod_name' }
                    ], //['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Supports addRow      // NOT IMPLEMENTED
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Supports removeRow   // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData       // NOT IMPLEMENTED
                        buttons: [                                      // List of custom buttons
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' }
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'customInvoice', text: 'LAG FAKTURA', func: function (data) { testItems(data); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' },
                            //{ name: 'customTest', text: 'Dette er en tekst', func: function () { testItems(); }, icon: 'star' }
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // IN DEVELOPMENT
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            {
                                name: 'test',
                                text: '',
                                func: function (data) { console.log('hei', data.other_campaigns) },
                                icon: 'fas-check'
                            }
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    //selectable: 'multiple, row',                      // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                          // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            var optionfunc = function (data) {
                // console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    // console.log('optionfunc - CellClickHandler');
                    // console.dir(data);
                                 
                    var fieldName = data.clickedCell.column.field;
                    var fieldValue = data.clickedCell.dataItem[fieldName];

                    // IKKE BRUK
                    // 
                    // Hvis et produkt ligger på flere kampanjer, så blir dette en kommaseparert liste,
                    // men det kreves at KEYNO bare er ett tall inn til skjermbildet `campaignedit`, dette
                    // vil derfor ikke fungere og føre til en SQL error
                    //
                    //if (fieldName === 'item_other_campaigns' && fieldValue.length > 0) {
                    //    stateService.go('campaignedit', {
                    //        campaign_keyno: fieldValue
                    //    });
                    //}

                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    // console.log('optionfunc - OnCellClose');
                    // console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    // console.log('optionfunc - LookupCellEditor');
                    // console.dir(data);
                }
            };

            var loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: vm.model.method,
                    parameters: vm.model.request
                }

                vm.model.gridReady = true;
            }

            loadGrid();
        }]
    });
})();
