(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2ReleaseNoteEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_releasenote_get: {
                p2_releasenote_keyno: '',
                public_note: '',
                internal_note: '',
                reg_datetime: '',
                portal_user_name: '',
                releasetype: '',
                show: ''
            },
            p2_releasetype_name_list: {
                releasetype: '',
                releasetype_name: '',
                orderby: '',
                records: []
            },
            loadP2ReleaseNoteGet: function (p2_releasenote_keyno) {
                var deferred = $q.defer();

                var parms_p2_releasenote_get = {
                    method: 222,
                    parameters: {
                        p2_releasenote_keyno: p2_releasenote_keyno
                    }
                };

                $ihttp.post(parms_p2_releasenote_get).then(function (data) {
                    service.p2_releasenote_get = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2ReleaseTypeNameList: function () {
                var deferred = $q.defer();

                var parms_p2_releasetype_name_list = {
                    method: 223
                };

                $ihttp.post(parms_p2_releasetype_name_list).then(function (data) {
                    angular.copy(data, service.p2_releasetype_name_list.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2ReleaseNote: function () {
                var deferred = $q.defer();

                var parms_p2_releasenote_save = {
                    method: 224,
                    parameters: {
                        p2_releasenote_keyno: service.p2_releasenote_get.p2_releasenote_keyno,
                        public_note: service.p2_releasenote_get.public_note,
                        internal_note: service.p2_releasenote_get.internal_note,
                        releasetype: service.p2_releasenote_get.releasetype
                    }
                };

                $ihttp.post(parms_p2_releasenote_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();