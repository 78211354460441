(function () {
    'use strict';

    var module = angular.module('imApp');

    module.factory('ttPopoverModel', [function () {

        // JLR 202306

        /**
         * Creates an instance of ttPopover. An object which represents the configuration
         * needed to use the tt-popover directive.
         * 
         * @example
         * vm.popover = new ttPopoverModel( vm.id.button );
         * 
         * @param {string} relativeElementId the id of the element to place the popover relative to and which triggers the popover.
         * @param {[]} data the list of elements to display in the popover.
         * @returns the ttPopover object;
         */
        function ttPopover(relativeElementId, data) {
            this.relativeElementId = relativeElementId;
            this.data = data ?? [];
            this.events = {
                onClose: () => { }
            }

            return this;
        };

        ttPopover.prototype.onClose = function (handler) {
            this.events.onClose = handler ?? (() => { });
            return this;
        }

        /**
         * @typedef {Object} Checklist represents checklist in the tt-popover.
         * @property {string} title the title of the checklist.
         * @property {boolean} required whether checked items in the list is required.
         * @property {{item_name: string; item_id: string | number; is_selected: boolean}[]} list list over items in the checklist.
         * @property {string} filter the active search string for filtering the checklist.
         * @property {string} filterPlaceholder the placeholder for the search field in the checklist.
         * @property {boolean} allSelected whether or not all items in the checklist are selected.
         */

        /**
         * Adds a checklist element to the ttpopover data.
         * 
         * @example
         * vm.popover = new ttPopoverModel({ relativeElementId: vm.id.button }).addChecklist({title: 'new Title', required: false, list: [{item_name: 'checkbox', item_id_ 1, is_selected: false}]})
         * 
         * @param {Checklist} config the configuartion object for the checklist.
         * @returns the ttPopover object with the new checklist added.
         */
        ttPopover.prototype.addChecklist = function ({ title, required, list, filter, filterPlaceholder, allSelected, onChecklistChanged }) {
            this.data.push({
                type: 'checklist',
                title: title ?? 'Checklist title',
                required: required ?? false,
                data: {
                    list: list ?? [],
                    filter: filter ?? '',
                    filterPlaceholder: filterPlaceholder ?? '',
                    allSelected: allSelected ?? false,
                    onChecklistChanged: onChecklistChanged ?? (() => { }),
                },
            });

            return this;
        };

        /**
         * @typedef {Object} Message represents a message displayed in a tt-message in the tt-popover.
         * @property {string} heading the main heading for the message to display.
         * @property {string} datetime the date of the message, displayed in a sub-heading.
         * @property {string} text the content of the message. 
         */

        /**
         * Adds list of ttMessages to the ttPopover data.
         * 
         * @example
         * vm.popover = new ttPopoverModel({ relativeElementId: vm.id.button }).addMessages([{ heading: 'Message heading', datetime: '20230517', text: 'This is the message content'}, ]);
         * 
         * @param { Message[] } messages list of messages to display in the ttPopover.
         * @returns the ttPopover object with the list of ttMessages added.
         */
        ttPopover.prototype.addMessages = function (messages) {
            this.data.push({
                type: 'messages',
                data: messages ?? [],
            });

            return this;
        }

        /**
         * @typedef {Object} Textarea represent a text area displayed in the tt-popover.
         * @property {string} title the title to display for the text area.
         * @property {boolean} required whether the text area must be filled in or not.
         * @property {string} text the text content in the text area.
         */

        /**
         * Adds a ttTextarea to the ttPopover data.
         * 
         * @example
         * vm.popover = new ttPopoverModel({ relativeElementId: vm.id.button }).addTextarea({ title: 'Comment', required: false, text: 'Write your comment here' })
         * 
         * @param {Textarea} config the configuation object for the text area.
         * @returns the ttPopover object with the ttTextarea added.
         */
        ttPopover.prototype.addTextarea = function ({ title, required, text }) {
            this.data.push({
                type: 'textarea',
                title: title,
                required: required,
                text: text,
            });

            return this;
        }

        /**
         * @typedef {Object} Button represents a button in the tt-popover.
         * @property {string} label the label to display in the button.
         * @property {string} iconClass the icon class to for the icon to add in the button, if any.
         * @property {() => void} onClick the event handler for the button.
         * @property {'success' | 'danger' | 'primary' | 'warning' | 'default'} buttonType the type of button, nothing indicates primary.
         * @property {boolean} mustMeetRequirements whether or not the button is disabled if required fields are not met.
         */

        /**
         * Adds a ttButton to the ttPopover data.
         * 
         * @example
         * vm.popover = new ttPopoverModel({ relativeElementId: vm.id.button }).addButton({ label: 'save', 'iconClass: 'fas-star'});
         * 
         * @param {Button} config configuration object for the button.
         * @returns the ttPopover object with ttButton added.
         */
        ttPopover.prototype.addButton = function ({ label, iconClass, onClick, buttonType, mustMeetRequirements }) {
            this.data.push({
                type: 'button',
                label: label ?? '',
                iconClass: iconClass ?? '',
                onClick: onClick ?? (() => console.log('clicked')),
                buttonType: buttonType ?? '',
                mustMeetRequirements: mustMeetRequirements ?? true
            })

            return this;
        }

        return ttPopover;
    }]);
})();
