(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('ttWebpagemenuEditor', {
        templateUrl: 'views/components/directives/ttWebpagemenuEditor/ttWebpagemenuEditor.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$uibModal', 'translateModalService', 'modalListSelectFactory', 'ieScreenBlockService', 'ttWebPageMenuEditorService', 'userService', function ($uibModal, translateModalService, modalListSelectFactory, ieScreenBlockService, ttWebPageMenuEditorService, userService) {
            let vm = this;

            // BJS 20230123 - Translating some words that are used in components and modal windows so
            //                they will already be cached if they are needed.
            let translations = [
                'tt_usergroups',
                'tt_delete',
                'tt_confirm_delete_message',
                'tt_error',
                'tt_confirm_delete_approute',
                'tt_missing_route_id',
                'tt_approute_already_exists',
                'tt_no_webpage_exists_with_this_route_id_as_webpage_name'
            ];

            vm.title = {
                delete: '',
                header: '',
                modal: ''
            };

            vm.confirmDeleteMessage = '';

            vm.item = null;

            vm.locked = {
                new: false
            };

            vm.webpage = {};
            vm.webpages = [];

            var usergroups = [];

            var buildNamekey = function (name, key) {
                return name + ' (' + key + ')';
            };

            vm.wordlangButtons = [{
                id: 'translate', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: {
                    click: function () {
                        translateModalService.showModal(vm.webpage.word_id).then(function (response) {
                            if (angular.isDefined(response.word_id) && angular.isDefined(response.wordlang_name)) {
                                vm.webpage.word_id = response.word_id;
                                vm.webpage.wordlang_name = response.wordlang_name;
                                vm.webpage.namekey = buildNamekey(vm.webpage.wordlang_name, vm.webpage.key);

                                vm.onChange('word_id', vm.webpage.word_id);
                            }
                        });
                    }
                }
            }];

            vm.subGridOptions = {
                dataTask: {
                    rememberId: 'ttWebpagemenuEditor.subGrid',
                    loadData: {
                        method: 2392,
                        parameters: function () {
                            return {
                                p2_webpage_keyno: vm.item.item_id
                            };
                        }
                    }
                },
                translations: translations,
                onSetup: function (schema, fields, columns, translations) {
                    vm.title.delete = translations.tt_delete;
                    vm.title.modal = translations.tt_usergroups;
                    vm.confirmDeleteMessage = translations.tt_confirm_delete_message;
                },
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    disableSortOnCheckbox: true,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: false,
                        buttons: [{
                            name: 'usergroups',
                            text: '',
                            icon: 'glyphicon-chevron-right',
                            func: function (data) {
                                var allUsergroups = angular.copy(usergroups);

                                var subUsergroupIds = data.usergroups.split(',');

                                var subUsergroupIdsIndex = {};

                                angular.forEach(subUsergroupIds, function (ug) {
                                    subUsergroupIdsIndex['ix_' + ug.trim()] = ug.trim();
                                });

                                angular.forEach(allUsergroups, function (ug) {
                                    ug.isSelected = angular.isDefined(subUsergroupIdsIndex['ix_' + ug.key]);
                                });

                                var modalListSelect = modalListSelectFactory.$create({
                                    list: allUsergroups,
                                    convert: {
                                        id: 'key',
                                        name: 'name',
                                        order: 'key',
                                        selected: 'isSelected'
                                    },
                                    title: vm.title.modal,
                                    sort: true,
                                    onChange: function (item) {
                                        ttWebPageMenuEditorService.updateUsergroup(data.p2_webpage_subscription_keyno, item.item_id, item.item_selected);
                                    }
                                });

                                modalListSelect.show().then(function (response) {
                                    var ugString = '';

                                    angular.forEach(response, function (ug) {
                                        if (ugString.length > 0) {
                                            ugString += ', ';
                                        }

                                        ugString += ug.key;
                                    });

                                    data.usergroups = ugString;
                                });
                            }
                        }]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false,
                    scrollable: true
                },
                optionfunc: function (data) {
                    switch (data.data.func) {
                        case 'CellClickHandler':
                            onRowClicked(data.data.clickedCell);
                            break;
                    }
                },
                gridfunc: null
            };

            function onRowClicked(e) {
                var access = false;

                if (e.dataItem.item_glyphicon_color === 'red') {
                    e.dataItem.set('item_glyphicon', 'glyphicon-check');
                    e.dataItem.set('item_glyphicon_color', 'green');

                    access = true;
                } else {
                    e.dataItem.set('item_glyphicon', 'glyphicon-unchecked');
                    e.dataItem.set('item_glyphicon_color', 'red');
                }

                ttWebPageMenuEditorService.updateSubscription(e.dataItem.p2_webpage_subscription_keyno, access);
            };

            vm.deleteItem = function () {
                ttWebPageMenuEditorService.delete(vm.webpage.link_id, vm.confirmDeleteMessage);
            };

            vm.onChange = function (type, value) {
                vm.webpage[type] = value;

                switch (type) {
                    case 'icon':
                        ttWebPageMenuEditorService.updateIcon(vm.webpage.key, value);
                        break;
                    case 'orderby':
                        ttWebPageMenuEditorService.updateOrderBy(vm.webpage.link_id, value);
                        break;
                    case 'word_id':
                        ttWebPageMenuEditorService.updateWordId(vm.webpage.link_id, value);
                        break;
                    case 'approute':
                        ttWebPageMenuEditorService.updateApproute(vm.webpage.link_id, value);
                        break;
                }
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.item)) {
                    vm.modalInstance.dismiss();
                }

                vm.item = vm.resolve.item;

                vm.title.header = vm.item.item_name;

                vm.webpage = {
                    key: parseInt(vm.item.item_id),
                    namekey: buildNamekey(vm.item.item_name, vm.item.item_id),
                    icon: vm.item.item_glyphicon,
                    word_id: vm.item.word_id,
                    wordlang_name: vm.item.item_name,
                    orderby: vm.item.orderby,
                    link_id: vm.item.link_id,
                    p2_approute_keyno: vm.item.p2_approute_keyno,
                    dirty: false,
                    dirtyFields: false,
                    dirtySubscriptions: false,
                    dirtyUsergroups: false
                };

                ttWebPageMenuEditorService.load(vm.item.item_id).then(function (response) {
                    angular.copy(response.usergroups, usergroups);
                    angular.copy(response.webpages, vm.webpages);

                    usergroups = usergroups.sort(function (a, b) {
                        return a - b;
                    });
                });
            };
        }]
    });
})();
