(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('consignmentLines', {
        templateUrl: 'views/components/views/consignmentLines/consignmentLines.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'consignmentLinesService', function ($stateParams, consignmentLinesService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListConsigmentLines: []
            };
            
            let loadConsignmentLines = function () {
                consignmentLinesService.listConsignmentLines({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListConsigmentLines);
                });
            };

            vm.$onInit = function () {
                loadConsignmentLines();
            };
        }]
    });
})();
