(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("powTimePayCheckLsService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadPowTimePayCheckLsList: function (argtype, argvalue) {
				return p2DataTaskService.call(1374, {
					argtype: argtype,
					argvalue: argvalue
				});
			}
		};

		return service;
	}]);
})();