(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('poFinishProdComp', {
        templateUrl: 'views/components/views/poFinishProdComp/poFinishProdComp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'poFinishProdCompService', function ($stateParams, stateService, modalService, translateService, poFinishProdCompService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2PoFinishProdKeyno = $stateParams.p2_pofinishprod_keyno;
            let p2PoFinishProdCompKeyno = $stateParams.p2_pofinishprodcomp_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                p2PoFinishProdCompKeyno: p2PoFinishProdCompKeyno,
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_id }) }
                ],
                edit: {},
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region PROCEDURE CALLS

            let loadEdit = function () {
                poFinishProdCompService.getEdit({
                    p2_pofinishprod_keyno: p2PoFinishProdKeyno,
                    p2_pofinishprodcomp_keyno: p2PoFinishProdCompKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion PROCEDURE CALLS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    poFinishProdCompService.deleteItem({ p2_pofinishprodcomp_keyno: vm.model.edit.p2_pofinishprodcomp_keyno }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                poFinishProdCompService.saveObj({
                    p2_pofinishprodcomp_keyno: vm.model.edit.p2_pofinishprodcomp_keyno,
                    quantity: vm.model.edit.quantity
                }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
