(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockBatchGet', {
        templateUrl: 'views/components/views/stockBatchGet/stockBatchGet.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'translateService', 'modalService', 'stockBatchGetService', function ($stateParams, $q, stateService, translateService, modalService, stockBatchGetService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockBatchKeyno = $stateParams.stockbatch_keyno;
            var p2ReportDefKeyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                bgBatchDelivery: {
                    selected: '0',
                    buttons: [
                        { id: '0', label: 'batchdelivery_gb_simple', color: 'primary', onClick: () => vm.model.bgBatchDelivery.selected = '0' },
                        { id: '1', label: 'batchdelivery_gb_details', color: 'primary', onClick: () => vm.model.bgBatchDelivery.selected = '1' },
                        { id: '2', label: 'batchdelivery_gb_tracing', color: 'primary', onClick: () => vm.model.bgBatchDelivery.selected = '2' }
                    ]
                },
                stockLocationNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectstocklocation', { stockplace_id: vm.model.edit.stockplace_id }) }
                ],
                productButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                allocationInfoButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => goToFunc('allocation_state') }
                ],
                purchaseOrderButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('purchhead', { purch_internal_no: vm.model.edit.purch_internal_no }) }
                ],
                batchDeliveryButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('batchdelivery', { batchdelivery_keyno: vm.model.edit.batchdelivery_keyno }) }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                const deferred = $q.defer();

                stockBatchGetService.getEdit({ stockbatch_keyno: stockBatchKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    if (vm.model.edit.stockplace_id > '') retrieveReportDefinitions();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let retrieveReportDefinitions = function () {
                return stockBatchGetService.getReportDefinition({ report_filename: vm.model.edit.report_name }).then(function (response) {
                    p2ReportDefKeyno = response[0].p2_reportdef_keyno;
                });
            }

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgBatchDelivery = function (value) {
                vm.model.bgBatchDelivery.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockBatchGetService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            let goToFunc = function (state) {
                switch (state) {
                    case 'allocation_state':
                        if (vm.model.edit.allocation_state === '' || vm.model.edit.allocation_parms === '') {
                            modalService.confirm({
                                type: 'warning',
                                title: translations.confirm_title,
                                message: 'Ingen allokering',
                                okLabel: translations.confirm_ok_label,
                                cancelLabel: translations.confirm_cancel_label
                            }).then(function () {
                                return;
                            });
                        } else {
                            stateService.go(vm.model.edit.allocation_state, vm.model.edit.allocation_parms);
                        }
                        break;
                    default:
                        break;
                }
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'printdialogue':
                        if (p2ReportDefKeyno > '') {
                            go({
                                webpage_name: stateService.getCurrentName(),
                                argtype: 'stockbatch_keyno',
                                argvalue: vm.model.edit.stockbatch_keyno,
                                p2_reportdef_keyno: p2ReportDefKeyno
                            });
                        } else {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: 'Det er ikke definert en rapport for denne siden.',
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (self) {
                                        self.close();
                                    }
                                }]
                            });

                            return;
                        }
                        break;
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'stockbatch_keyno',
                            argvalue: vm.model.edit.stockbatch_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
