(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('operationLangs', {
        templateUrl: 'views/components/views/operationLangs/operationLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'operationLangsService', function ($stateParams, stateService, operationLangsService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let operationId = $stateParams.operation_id;

            vm.model = {
                inputListOperationLanguages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadLanguages = function () {
                operationLangsService.listOperationLanguages({ operation_id: operationId }).then(function (result) {
                    angular.copy(result, vm.model.inputListOperationLanguages);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                operationLangsService.saveObj({ records: vm.model.inputListOperationLanguages }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadLanguages();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
