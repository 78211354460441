(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('campaignEdit', {
        templateUrl: 'views/components/views/campaignedit/campaignedit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'campaignEditComponentService', 'economyService', function ($stateParams, stateService, campaignEditComponentService, economyService) {
            var vm = this;
            var onDestroy = [];

            vm.model = {
                campaignKeyNo: $stateParams.campaign_keyno,
                editCampaign: {},
                selectListPrices: [],
                lockedSave: false
            };

            vm.saveCampaign = saveCampaign;

            function saveCampaign() {
                vm.model.lockedSave = true;
                
                return campaignEditComponentService.save(vm.model.editCampaign)
                    .then(function (data) {
                        vm.model.lockedSave = false;
                        stateService.back();
                    })
                    .catch(function (err) {
                        vm.model.lockedSave = false;
                    });
            }

            function handleProductsClick() {
                return stateService.go('campaignproducts', { campaign_keyno: vm.model.campaignKeyNo || 0 });
            }

            function loadCampaigns() {
                return campaignEditComponentService.get(vm.model.campaignKeyNo)
                    .then(function (data) {
                        angular.copy(data[0], vm.model.editCampaign);
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
            }
            
            function listPrices() {
                return economyService.listPrices()
                    .then(function (data) {
                        angular.copy(data, vm.model.selectListPrices);
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
            }

            loadCampaigns();
            listPrices();

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'save':
                        saveCampaign();
                        break;
                    case 'products':
                        handleProductsClick();
                    default:
                        break;
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
