(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceMethod', {
        templateUrl: 'views/components/views/invoiceMethod/invoiceMethod.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'invoiceMethodService', function ($stateParams, stateService, utilityService, invoiceMethodService) {
            var vm = this;
            
            vm.model = {
                invoiceMethodNo: $stateParams.invoicemethod_no,
                invoice: {},
                lockedSave: false
            };
            
            invoiceMethodService.loadInvoiceMethodGet(vm.model.invoiceMethodNo).then(function (data) {
            	angular.copy(data[0], vm.model.invoice);
            });
            
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'invoicemethodlangs':
                        go({
                            invoicemethod_no: vm.model.invoice.invoicemethod_no
                        });
                        break;
                    default:
                        break;
                }
            };
            
            vm.saveInvoiceMethod = function () {
                vm.model.lockedSave = true;
                
                invoiceMethodService.save(vm.model.invoice).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();