(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('poPurchPlanning', {
        templateUrl: 'views/components/views/poPurchPlanning/poPurchPlanning.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'translateService', 'modalService', 'poPurchPlanningService', 'logisticService', 'rememberService', function (stateService, utilityService, translateService, modalService, poPurchPlanningService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            var variableNames = {
                businessco_no: '',
                stockplace_id: ''
            };

            vm.model = {
                setting: {},
                selectListBusinessCompanies: [],
                selectListStockPlaces: [],
                gridReady: false,
                switchingPoToPurchase: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            { name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'fal fa-save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false,
                        minHeight: '400px'
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            poPurchPlanningService.getPoPurchPlanning().then(function (data) {
                angular.copy(data[0], vm.model.setting);

                loadStockPlaces();

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
			        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

                loadGrid();
            });

            logisticService.listCompanies().then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCompanies);
            });

            var loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.setting.businessco_no) === true && utilityService.validateParmsValue(vm.model.setting.stockplace_id) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        variableValue = vm.model.setting.stockplace_id;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        loadGrid();
                        break;
                    case 'stockplace_id':
                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SWITCH PROCEDURE FUNCTION CALL

            vm.switchChanges = function () {
                saveItems();
            };

            var saveItems = function () {
                vm.model.switchingPoToPurchase = true;

                var allData = vm.grid.gridfunc.getAllRows();
                var currentData = [];
                
                angular.forEach(allData, function (item) {
                    if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                        currentData.push(item);
                    }
                });

                var mergeObj = {
                    stockplace_id: vm.model.setting.stockplace_id,
                    pos: currentData.map(function (a) { return { "po_keyno": a.po_keyno, "supplier_no": a.supplier_no, "purchase_quantity": a.purchase_quantity, "deliverydate_wish": a.deliverydate_wish }; })
                };

                poPurchPlanningService.switchToPurchase(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.switchingPoToPurchase = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.switchingPoToPurchase = false;
                        
		                stateService.go('purchhead', {
                            purch_internal_no: response[0].purch_internal_no
		                });
                    }
                });
            };

		    // #endregion BUTTON SWITCH PROCEDURE FUNCTION CALL
        }]
    });
})();
