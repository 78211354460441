(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("findPersonService", ['p2DataTaskService', '$q', '$timeout', function (p2DataTaskService, $q, $timeout) {
        let onSelectedUpdate;
        let groupTimeoutLoadSearch;
        let groupTimeoutRemember;

        var service = {

            selectedPersons: [],

            /**
             * Subscribe to 'selectedPersons' updates.
             * Handler function passed in will be called whenever 'selectedPersons' is updated.
             *
             * KLN 20231004
             * 
             * @param {Function} handler
             */
            subscribeToUpdate: function (handler) {
                onSelectedUpdate = handler;
            },
            loadFindPersonGet: function () {
                return p2DataTaskService.call(1124, {});
            },
            remember: function (variablename, variablevalue) {
                if (groupTimeoutRemember) $timeout.cancel(groupTimeoutRemember);
                groupTimeoutRemember = $timeout(function () {
                    p2DataTaskService.call(616, {
                        variablename: variablename,
                        variablevalue: variablevalue
                    });
                }, 400);
                //return p2DataTaskService.call(616, {
                //    variablename: variablename,
                //    variablevalue: variablevalue
                //});
            },
            loadFindPersonSearchList: function (getFindPerson, instant) {

                let deferred = $q.defer();

                if (groupTimeoutLoadSearch) $timeout.cancel(groupTimeoutLoadSearch);

                if (instant) {
                    p2DataTaskService.call(1125, getFindPerson).then(function (data) {
                        angular.copy(data, service.selectedPersons);
                        deferred.resolve(data);
                        if (onSelectedUpdate) {
                            onSelectedUpdate();
                        }
                    });
                } else {
                    groupTimeoutLoadSearch = $timeout(function () {
                        p2DataTaskService.call(1125, getFindPerson).then(function (data) {
                            angular.copy(data, service.selectedPersons);
                            deferred.resolve(data);
                            if (onSelectedUpdate) {
                                onSelectedUpdate();
                            }
                        });
                    }, 400);
                }


                //lastResponse.then(function (data) {
                //        angular.copy(data, service.selectedPersons);
                //        deferred.resolve(data);
                //        if (onSelectedUpdate) {
                //            onSelectedUpdate();
                //        }
                //    });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
