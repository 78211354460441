(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCustomerEdit', {
        templateUrl: 'views/components/views/srCustomerEdit/srCustomerEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'customerEditService', 'srCustomerEditService', 'watcherFactory', function($stateParams, stateService, utilityService, modalService, customerEditService, srCustomerEditService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                custNo: $stateParams.cust_no,
                editCustomer: {},
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCustomerEditService.loadSrCustomerEditGet(vm.model.custNo).then(function (data) {
                vm.model.editCustomer = angular.copy(data[0]);
            });
    
            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##
    
            vm.typeaheadSearchStreet = function (value) {
                return customerEditService.search(vm.model.editCustomer.postplace_name_street);
            };
    
            var changedPostplaceSearchStreet = function (postplace_id_street, postplace_name_street) {
                vm.model.editCustomer.postplace_id_street = postplace_id_street;
    
                //if (vm.model.editCustomer.postplace_id_street === '') {
                //    vm.model.editCustomer.postplace_id_street = postplace_id_street;
                //    vm.model.editCustomer.postplace_name_street = postplace_name_street;
                //}
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrCustomerEdit = function () {
                vm.model.lockedSave = true;
                
                srCustomerEditService.save(vm.model.editCustomer).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.editCustomer.postplace_name_street; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                if (newValue === '') {
                    vm.model.editCustomer.postplace_id_street = '';
                    vm.model.editCustomer.postplace_name_street = '';
                } else {
                    angular.forEach(customerEditService.selectPostPlaceList, function (data) {
                        if (angular.isDefined(data)) {
                            if (data.item_name === vm.model.editCustomer.postplace_name_street) {
                                changedPostplaceSearchStreet(data.item_id, data.item_name);
                            }
                        }
                    });
                }
            });
        }]
    });
})();