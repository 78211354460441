(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('deliveryTermLangs', {
        templateUrl: 'views/components/views/deliveryTermLangs/deliveryTermLangs.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'deliveryTermLangsService', function($stateParams, stateService, modalService, deliveryTermLangsService) {
            var vm = this;
            
            vm.model = {
                deliveryTermNo: $stateParams.deliveryterm_no,
                inputListDeliveryTermLanguages: [],
                lockedSave: false
            };
            
            deliveryTermLangsService.loadDeliveryTermLangsList(vm.model.deliveryTermNo).then(function (data) {
                angular.copy(data, vm.model.inputListDeliveryTermLanguages);
            });
            
            vm.saveDeliveryTermLangs = function () {
                vm.model.lockedSave = true;
                
                var mergeObj = {
                    records: vm.model.inputListDeliveryTermLanguages
                };
                
                deliveryTermLangsService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();