(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('salaryTypes', {
        templateUrl: 'views/components/views/salaryTypes/salaryTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'salaryTypesService', function ($stateParams, stateService, salaryTypesService) {
            var vm = this;
            
        	vm.model = {
        		dataSource: {
        			records: []
        		}
        	};
        	
        	salaryTypesService.loadSalaryTypes().then(function(){
        		vm.model.dataSource = salaryTypesService.dataSource;
        	});
        	
            vm.createNewSalaryType = function () {
                stateService.go('salarytype', {
                    salarytype_id: '0'
                })
            };
        }]
    });
})();