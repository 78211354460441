(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('payments', {
        templateUrl: 'views/components/views/payments/payments.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'paymentsService', function (stateService, paymentsService) {
            let vm = this;
            
            vm.model = {
                itemsListPayments: []
            };

            let loadPayments = function () {
                paymentsService.listPayments().then(function (result) {
                    angular.copy(result, vm.model.itemsListPayments);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'payment':
                        go({ payment_id: '' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadPayments();
            };
        }]
    });
})();
