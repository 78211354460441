(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('dispatchPlanningStock', {
        templateUrl: 'views/components/views/dispatchPlanningStock/dispatchPlanningStock.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: [function() {
            var vm = this;
            
            vm.model = {};
        }]
    });
})();