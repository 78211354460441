(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("serviceObjectMenuService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadServiceObjectMenuGet: function (serviceobject_keyno) {
                return p2DataTaskService.call(1734, {
                    serviceobject_keyno: serviceobject_keyno
                });
            },
            loadServiceObjectsCustactList: function (serviceobject_keyno) {
                return p2DataTaskService.call(1737, {
                    serviceobject_keyno: serviceobject_keyno
                });
            },
            loadServiceObjectMenuLinkList: function (serviceobject_keyno) {
                return p2DataTaskService.call(1751, {
                    serviceobject_keyno: serviceobject_keyno
                });
            },
            loadServiceObjectsSopsList: function (serviceobject_keyno) {
                return p2DataTaskService.call(1842, {
                    serviceobject_keyno: serviceobject_keyno
                });
            },
            listServiceObjectMenuFields: function (parms) {
                return $ihttp.post({
                    method: 2160,
                    parameters: parms || {}
                });
            },
            saveField: function (setting) {
                return $ihttp.post({
                    method: 2162,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();