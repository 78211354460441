(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactCalcEditModal', {
        templateUrl: 'views/components/views/custactCalcEditModal/custactCalcEditModal.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$timeout', '$stateParams', 'stateService', 'modalService', 'searchService', 'utilityService', 'custactCalcEditModalService', 'custactCalcService', 'custactCompsService', 'custactWorksService', 'workDetailsService', 'rememberService', 'translateService', function ($timeout, $stateParams, stateService, modalService, searchService, utilityService, custactCalcEditService, custactCalcService, custactCompsService, custactWorksService, workDetailsService, rememberService, translateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;
            var initLoadDone = false;
            var initLoadDoneTimer = null;
            var onDestroy = [];
            vm.lockedSave = false;
            vm.popup = undefined;

            var variableNames = {
                bg_custactcalcedit_main: ''
            };

            var translations = {
                custactcalcedit_updated_from_basics_confirm_title: '',
                custactcalcedit_updated_from_basics_confirm_message: '',
                custactcalcedit_updated_from_basics_confirm_ok_label: '',
                custactcalcedit_updated_from_basics_confirm_cancel_label: '',
                custactcalcedit_update_from_basics_show_title: 'Varsel',
                custactcalcedit_update_from_basics_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'workdetails':
                    case 'custactcalcedit':
                        stateService.go(state, {
                            custact_no: vm.model.p2CustactGet.custact_no_basis
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                mainBg: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalcedit_mainbg_calc', item_func: 'CALC' },
                        { item_id: '1', item_name: 'custactcalcedit_mainbg_text', item_func: 'TEXT' },
                        //{ item_id: '2', item_name: 'custactcalcedit_mainbg_functions', item_func: 'FUNCTIONS' }
                    ]
                },
                p2CustactCalcItemsSelection: {
                    item_default: searchService.custactCalcEditCtrl.p2CustactCalcItemsSelectionDefault, // '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalc_materials', item_func: 'C' },
                        { item_id: '1', item_name: 'custactcalc_work', item_func: 'W' },
                        { item_id: '2', item_name: 'custactcalc_sum', item_func: 'S' }
                    ]
                },
                p2CustactCompListSelection: {
                    item_default: searchService.custactCalcEditCtrl.p2CustactCompListSelectionDefault, //'0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcomps_selected', item_func: 'S' },
                        { item_id: '1', item_name: 'custactcomps_all', item_func: 'A' }
                    ]
                },
                bgP2CustactCalcSumSelection: {
                    item_default: searchService.custactCalcEditCtrl.bgP2CustactCalcSumSelectionDefault, //'0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalcedit__calc_pcs', item_func: 'PCS' },
                        { item_id: '1', item_name: 'custactcalcedit_calc_sum', item_func: 'SUM' },
                        { item_id: '2', item_name: 'custactcalcedit_calc_sumwork', item_func: 'SUMWORK' }
                    ]
                },
                bgP2CustactCalcSumSelection2: {
                    item_default: searchService.custactCalcEditCtrl.bgP2CustactCalcSumSelectionDefault, //'0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalcedit__calc_pcs', item_func: 'PCS' },
                        { item_id: '1', item_name: 'custactcalcedit_calc_sum', item_func: 'SUM' }
                    ]
                },
                bgP2CustactCalcSumNumberTypeSelection: {
                    item_default: searchService.custactCalcEditCtrl.bgP2CustactCalcSumNumberTypeDefault, //'0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'custactcalcedit_nt_total', item_func: 'NT_TOTAL' },
                        { item_id: '1', item_name: 'custactcalcedit_nt_material', item_func: 'NT_MATERIAL' },
                        { item_id: '2', item_name: 'custactcalcedit_nt_work', item_func: 'NT_WORK' }
                    ]
                },
                custactNoBasisButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'workdetails' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'custactcalcedit' } }
                ],
                custact_no: 0,
                p2CustactGet: {},
                p2CustactCalcSum: {},
                p2CustactCompList: {
                    records: []
                },
                p2CustactWorkList: {
                    records: []
                },
                p2CustactWorkSumList: {
                    records: []
                },
                p2CounterNextNumberGet: {},
                gridReady: false,
                gridCustactCompReady: false,
                gridCustactCompSelectReady: false,
                gridCustactWorkReady: false,
                spin: false,
                fetchingWorkTime: false,
                updatingFromBasics: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'custactcalc_relations_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridCustactComp = {
                dataTask: {
                    rememberId: 'w_custactcalcedit_custactcomp_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridCustactCompSelect = {
                dataTask: {
                    rememberId: 'w_custactcalcedit_custactcomp_select_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridCustactWork = {
                dataTask: {
                    rememberId: 'w_custactcalcedit_custactwork_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #region INIT LOAD DONE TIMEOUT FUNCTION

            var initLoadDoneFunc = function () {
                if (angular.isDefined(initLoadDoneTimer)) {
                    $timeout.cancel(initLoadDoneTimer);
                }

                initLoadDoneTimer = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };

            // #endregion INIT LOAD DONE TIMEOUT FUNCTION

            // ######################
            // BUTTON GROUP FUNCTIONS
            // ######################

            vm.selectMainBg = function (item) {
                vm.model.mainBg.item_selected = item;
                vm.model.p2CustactGet.bg_custactcalcedit_main = vm.model.mainBg.item_selected;
                rememberFunc('bg_custactcalcedit_main');
            };

            vm.selectP2CustactItemsView = function (item) {
                vm.model.p2CustactCalcItemsSelection.item_selected = item;
                searchService.custactCalcEditCtrl.p2CustactCalcItemsSelectionDefault = utilityService.groupButtonCurrentId(vm.model.p2CustactCalcItemsSelection);

                if ((vm.model.p2CustactCalcItemsSelection.item_selected == 'S' && vm.model.bgP2CustactCalcSumSelection.item_selected !== '') && (angular.isDefined(vm.model.p2CustactGet.price_locval) || vm.model.p2CustactGet.price_locval !== '')) {
                    vm.loadP2CustactCalcSum();
                }

                if (vm.model.p2CustactCalcItemsSelection.item_selected == 'W') {
                    vm.loadP2CustactWorkList();
                }
            };

            vm.selectP2CustactCompList = function (item) {
                vm.model.p2CustactCompListSelection.item_selected = item;
                searchService.custactCalcEditCtrl.p2CustactCompListSelectionDefault = utilityService.groupButtonCurrentId(vm.model.p2CustactCompListSelection);
                vm.loadP2CustactCompList();
            };

            vm.selectBgP2CustactCalcSum = function (item) {
                vm.model.bgP2CustactCalcSumSelection.item_selected = item;
                searchService.custactCalcEditCtrl.bgP2CustactCalcSumSelectionDefault = utilityService.groupButtonCurrentId(vm.model.bgP2CustactCalcSumSelection);

                if ((vm.model.p2CustactCalcItemsSelection.item_selected == 'S' && vm.model.bgP2CustactCalcSumSelection.item_selected !== '') && (angular.isDefined(vm.model.p2CustactGet.price_locval) || vm.model.p2CustactGet.price_locval !== '')) {
                    vm.loadP2CustactCalcSum();
                }

                if (vm.model.bgP2CustactCalcSumSelection.item_selected == 'SUMWORK') {
                    vm.loadP2CustactWorkSumList();
                }
            };

            vm.selectBgP2CustactCalcSum2 = function (item) {
                vm.model.bgP2CustactCalcSumSelection2.item_selected = item;
                vm.selectBgP2CustactCalcSum(item);
            };

            vm.selectBgP2CustactCalcNumberTypeSum = function (item) {
                vm.model.bgP2CustactCalcSumNumberTypeSelection.item_selected = item;
                searchService.custactCalcEditCtrl.bgP2CustactCalcSumNumberTypeDefault = utilityService.groupButtonCurrentId(vm.model.bgP2CustactCalcSumNumberTypeSelection);
            };

            // ####################
            // LOAD PROCEDURE CALLS
            // ####################


            vm.load = function() {

                translateService.translateBatch(translations).then(function (data) {
                    angular.forEach(translations, function (_, key) {
                        if (angular.isDefined(data[key])) {
                            translations[key] = data[key];
                        }
                    });
                });
                
                vm.loadP2CustactGet();
                vm.model.p2CustactCalcItemsSelection.item_selected = 'S';
                vm.model.bgP2CustactCalcSumSelection.item_selected = 'PCS';
                console.dir(vm.model.p2CustactCalcItemsSelection.item_selected);
                console.dir(vm.model.bgP2CustactCalcSumSelection.item_selected);
                console.dir(vm.model.custact_no);
                vm.selectBgP2CustactCalcSum('PCS');
            }
            

            vm.loadP2CustactGet = function () {
                custactCompsService.loadP2CustactGet(vm.model.custact_no).then(function (data) {
                    vm.model.p2CustactGet = custactCompsService.p2CustactGet;
                    vm.model.mainBg.item_default = utilityService.groupButtonIdOf(vm.model.mainBg, vm.model.p2CustactGet.bg_custactcalcedit_main);

                    initLoadDoneFunc();

                    vm.gridCustactComp.dataTask.loadData = {
                        method: 2849,
                        parameters: vm.model.p2CustactGet
                    };

                    vm.grid.dataTask.loadData = {
                        method: 2848,
                        parameters: vm.model.p2CustactGet
                    };

                    vm.gridCustactCompSelect.dataTask.loadData = {
                        method: 2850,
                        parameters: vm.model.p2CustactGet
                    };

                    vm.gridCustactWork.dataTask.loadData = {
                        method: 2851,
                        parameters: vm.model.p2CustactGet
                    };

                    vm.model.gridCustactCompReady = true;
                    vm.model.gridReady = true;
                    vm.model.gridCustactCompSelectReady = true;
                    vm.model.gridCustactWorkReady = true;
                });
            };

            vm.loadP2CustactCalcSum = function () {
                custactCalcService.loadP2CustactCalcSum(vm.model.custact_no, 'child', '1', vm.model.p2CustactGet.price_locval, vm.model.bgP2CustactCalcSumSelection.item_selected).then(function () {
                    vm.model.p2CustactCalcSum = custactCalcService.p2CustactCalcSum;
                });
            };

            var timerCustactCompList = null;

            vm.loadP2CustactCompList = function () {
                if (angular.isDefined(timerCustactCompList)) {
                    $timeout.cancel(timerCustactCompList);
                }

                timerCustactCompList = $timeout(function () {
                    custactCompsService.loadP2CustactCompList(vm.model.custact_no, vm.model.p2CustactCompListSelection.item_selected).then(function () {
                        vm.model.p2CustactCompList = custactCompsService.p2CustactCompList;
                    });
                }, 50);
            };

            vm.loadP2CustactWorkList = function () {
                custactWorksService.loadP2CustactWorkList(vm.model.custact_no).then(function () {
                    vm.model.p2CustactWorkList = custactWorksService.p2CustactWorkList;
                });
            };

            vm.loadP2CustactWorkSumList = function () {
                custactCalcEditService.loadP2CustactWorkSumList(vm.model.custact_no).then(function (data) {
                    angular.copy(data, vm.model.p2CustactWorkSumList.records);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'bg_custactcalcedit_main':
                            variableNames[key] = key;
                            break;
                        default:
                            variableNames[key] = 'w_custactcalceditmodal' + '.' + key;
                            break;
                    }
                });

                if (initLoadDone !== true) return;
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'bg_custactcalcedit_main':
                        if (utilityService.validateParmsValue(vm.model.p2CustactGet.bg_custactcalcedit_main) !== true) return;

                        variableValue = vm.model.p2CustactGet.bg_custactcalcedit_main;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            //p2CounterNextNumberGet
            //vm.getP2CounterNextNumberGet = function () {
            //    custactCalcEditService.genericFunction(678, {
            //        counter_id: 'autoproductean'
            //    }).then(function (data) {
            //        vm.model.p2CounterNextNumberGet = angular.copy(data[0]);
            //        vm.model.p2CustactGet.prod_id = vm.model.p2CounterNextNumberGet.nextnumber;
            //    });
            //}

            //vm.getP2CounterNextNumberGet();

            // ######################
            // RECALCULATION FUNCTION
            // ######################

            vm.recalcAmoutOrdVal = function () {
                vm.model.p2CustactGet.amount_ordval = (vm.model.p2CustactGet.quantity * vm.model.p2CustactGet.price_ordval) - (vm.model.p2CustactGet.quantity * vm.model.p2CustactGet.price_ordval * vm.model.p2CustactGet.linediscount_pst / 100);
            };

            // ####################
            // VALIDATION FUNCTIONS
            // ####################

            vm.validateCheckItem = function (item) {
                if (item.item_is_selected == '1') {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                    item.item_glyphicon = 'glyphicon-unchecked';
                } else {
                    item.item_is_selected = '1';
                    item.item_glyphicon_color = 'green';
                    item.item_glyphicon = 'glyphicon-check';
                }

                custactCompsService.saveP2CustactCompIsSelected(item);
            };

            // ####################
            // SAVE BUTTON FUNCTION
            // ####################

            vm.saveP2CustactSaveWCustactCalcEdit = function () {
                vm.lockedSave = true;
                custactCalcEditService.saveP2CustactSaveWCustactCalcEdit(vm.model.p2CustactGet).then(function () {
                    vm.loadP2CustactCalcSum();
                    vm.loadP2CustactWorkSumList();
                    vm.lockedSave = false;
                });
            };

            // ######################
            // DELETE BUTTON FUNCTION
            // ######################

            vm.deleteP2Custact = function () {
                workDetailsService.deleteP2Custact().then(function () {
                    stateService.back();
                });
            };

            // ############################
            // UPDATE PRICE BUTTON FUNCTION
            // ############################

            vm.refreshPrices = function () {
                vm.model.spin = true;

                custactCalcEditService.loadCustactCompPricesRefresh(vm.model.custact_no).then(function (data) {
                    if (vm.model.p2CustactCalcItemsSelection.item_selected === 'C') {
                        //vm.loadP2CustactCompList();
                        vm.gridCustactComp.gridfunc.rebind();
                    } else if (vm.model.p2CustactCalcItemsSelection.item_selected === 'S') {
                        vm.loadP2CustactCalcSum();
                    } else if (vm.model.p2CustactCalcItemsSelection.item_selected === 'W') {
                        vm.loadP2CustactWorkList();
                    }

                    vm.model.spin = false;
                });
            };

            // #region GET WORK TIME PROCEDURE FUNCTION CALL

            var updateWorkTime = function () {
                vm.model.fetchingWorkTime = true;

                custactCalcEditService.getWorkTime({ custact_no: vm.model.p2CustactGet.custact_no }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.fetchingWorkTime = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.fetchingWorkTime = false;
                        vm.loadP2CustactWorkList();
                        vm.loadP2CustactCalcSum();
                    }
                });
            };

            // #endregion GET WORK TIME PROCEDURE FUNCTION CALL

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'worktime':
                        updateWorkTime();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region BUTTON UPDATE FROM BASICS PROCEDURE FUNCTION CALL

            vm.updateFromBasicsChanges = function () {
                if (vm.model.p2CustactGet.nbr_of_childs > 0) {
                    modalService.confirm({
                        type: 'danger',
                        title: translations.custactcalcedit_updated_from_basics_confirm_title,
                        message: translations.custactcalcedit_updated_from_basics_confirm_message,
                        okLabel: translations.custactcalcedit_updated_from_basics_confirm_ok_label,
                        cancelLabel: translations.custactcalcedit_updated_from_basics_confirm_cancel_label
                    }).then(function () {
                        vm.model.updatingFromBasics = true;

                        custactCalcEditService.updateFromBasicsObj({ custact_no: vm.model.p2CustactGet.custact_no }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.custactcalcedit_update_from_basics_show_title,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.custactcalcedit_update_from_basics_show_ok_label,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.updatingFromBasics = false;
                                        }
                                    }]
                                });
                            } else {
                                vm.model.updatingFromBasics = false;

                                if (vm.model.p2CustactCalcItemsSelection.item_selected === 'C') {
                                    //vm.loadP2CustactCompList();
                                    vm.gridCustactComp.gridfunc.rebind();
                                } else if (vm.model.p2CustactCalcItemsSelection.item_selected === 'S') {
                                    vm.loadP2CustactCalcSum();
                                } else if (vm.model.p2CustactCalcItemsSelection.item_selected === 'W') {
                                    vm.loadP2CustactWorkList();
                                }
                            }
                        });
                    }, function () {
                        vm.model.updatingFromBasics = false;
                    });
                } else {
                    vm.model.updatingFromBasics = true;

                    custactCalcEditService.updateFromBasicsObj({ custact_no: vm.model.p2CustactGet.custact_no }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.custactcalcedit_update_from_basics_show_title,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.custactcalcedit_update_from_basics_show_ok_label,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.updatingFromBasics = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.updatingFromBasics = false;

                            if (vm.model.p2CustactCalcItemsSelection.item_selected === 'C') {
                                //vm.loadP2CustactCompList();
                                vm.gridCustactComp.gridfunc.rebind();
                            } else if (vm.model.p2CustactCalcItemsSelection.item_selected === 'S') {
                                vm.loadP2CustactCalcSum();
                            } else if (vm.model.p2CustactCalcItemsSelection.item_selected === 'W') {
                                vm.loadP2CustactWorkList();
                            }
                        }
                    });
                }
            };

            // #endregion BUTTON UPDATE FROM BASICS PROCEDURE FUNCTION CALL

            // ##########################
            // DIRECTION BUTTON FUNCTIONS
            // ##########################

            //vm.goTo = function (item) {
            //    stateService.go(item.item_state, item.item_parms);
            //};
            function getCustactCalcEditUrl() {
                return '/#/custactcalcedit/' + vm.model.custact.custactac_keyno + '?ispopup'
            }

            async function openCustactCalcEditPopup() {
                    if (!vm.popup) { // if not already open
                        const size = await getCustactCalcEditPopupSize()

                        vm.popup = window.open(getCustactCalcEditUrl(), 'mozillaWindow', 'popup,width=' + size.width + ',height=' + size.height + ',screenX=' + size.x + ',screenY=' + size.y);

                        if (vm.popup) {

                            vm.popup.onresize = function (event) {
                                const width = event.target.innerWidth;
                                const height = event.target.innerHeight;
                                rememberCustactMatchPopupSize(width, height, event.target.screenX, event.target.screenY);
                            }

                            vm.popup.onbeforeunload = function (event) {
                            }

                            let intervalId = setInterval(async () => {
                                if (!vm.popup) {
                                    clearInterval(intervalId);
                                }



                                if (vm.popup?.closed) {
                                    vm.grid.gridfunc.rebind();
                                    clearInterval(intervalId);
                                    vm.popup = null;
                                }
                            }, 2000);
                        } else {
                            const modalRef = $uibModal.open({
                                component: 'custactAcMatch',
                                resolve: {
                                    custactAcKeyno: function () {
                                        return vm.model.custact.custactac_keyno;
                                    },
                                },
                                size: 'pst-ninety',
                            });

                            modalRef.closed.then(function () {
                                vm.grid.gridfunc.rebind();
                                setSumDebitCredit();
                            });

                            //modalService.show({
                            //    type: 'danger',
                            //    title: translations.error,
                            //    message: translations.remove_popup_window_blocker,
                            //    buttons: [{
                            //        label: translations.ok,
                            //        cssClass: 'btn-danger',
                            //        action: function (dialogItself) {
                            //            dialogItself.close();
                            //            vm.model.lockedSave = false;
                            //        }
                            //    }]
                            //});
                        }
                    }
                }

            vm.goToCustactMembers = function () {
                stateService.go('custactmembers', {
                    custact_no: vm.model.custact_no
                });
            };

            vm.goToCustactDialogs = function () {
                stateService.go('custactdialogs', {
                    custact_no: vm.model.custact_no
                });
            };

            vm.goToPictures = function (index) {
                stateService.go('pictures', {
                    argtype: 'custact_no',
                    argvalue: vm.model.custact_no
                });
            };

            vm.goToDocuments = function (index) {
                stateService.go('documents', {
                    argtype: 'custact_no',
                    argvalue: vm.model.custact_no
                });
            };

            vm.goToCategoryData = function () {
                stateService.go('categorydatas', {
                    argtype: 'custact',
                    argvalue: vm.model.custact_no
                });
            };

            vm.goToCustactComp = function () {
                stateService.go('custactcomp', {
                    custact_no: vm.model.custact_no,
                    custactcomp_keyno: '0'
                });
            };

            vm.goToCustactWork = function (index) {
                stateService.go('custactwork', {
                    custact_no: vm.model.custact_no,
                    custactwork_keyno: '0'
                });
            };

            vm.goToCustactGantt = function () {
                stateService.go('custactgantt', {
                    connectto: 'custact',
                    connectto_value: vm.model.custact_no
                });
            };

            vm.goToCustactEdit = function (index) {
                stateService.go('custactedit', {
                    custact_no: vm.model.custact_no,
                    filterfield: '-',
                    filtervalue: '-'
                });
            };

            vm.goToProduct = function (index) {
                stateService.go('product', {
                    prod_id: vm.model.p2CustactGet.prod_id
                });
            };

            var timerProdId = null;

            vm.goToProductCreate = function (index) {
                if (angular.isDefined(timerProdId)) {
                    $timeout.cancel(timerProdId);
                }

                var variableNameProdId = 'w_custactcalcedit.newproduct';

                timerProdId = $timeout(function () {
                    custactCalcEditService.genericFunction(616, {
                        variablename: variableNameProdId,
                        variablevalue: '1'
                    }).then(function (data) {
                        stateService.go('productcreate', {
                            argtype: 'custact_no',
                            argvalue: vm.model.p2CustactGet.custact_no
                        });
                    });
                }, 50);
            };

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'postcalc':
                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.p2CustactGet.custact_no
                        });
                        break;
                    case 'custactcalcadjustdisc':
                        go({ custact_no: vm.model.p2CustactGet.custact_no });
                        break;
                }
            };

            //vm.productNewProdId = function () {
            //    vm.getP2CounterNextNumberGet();
            //};

            vm.onQuantityChanged = function (value) {
                vm.model.p2CustactGet.quantity = value;

                if (value) {
                    vm.recalcAmoutOrdVal(value);
                }
            }

            vm.onPriceOrdvalChanged = function (value) {
                vm.model.p2CustactGet.price_ordval = value;

                if (value) {
                    vm.recalcAmoutOrdVal(value);
                }
            }

            vm.onLinediscountPstChanged = function (value) {
                vm.model.p2CustactGet.linediscount_pst = value;

                if (value) {
                    vm.recalcAmoutOrdVal(value);
                }
            }

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            vm.$onInit = async function () {
                console.log(vm.resolve);

                if (vm.resolve?.dataItem !== undefined) {
                    vm.model.dataItem = vm.resolve.dataItem;
                    vm.model.custact_no = vm.model.dataItem?.custact_no ?? '0';
                } else if (angular.isDefined($stateParams.custact_no)) {
                    vm.model.custact_no = $stateParams.custact_no;
                }

                vm.load();

            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
