(function () {
    'use strict';

    angular.module("imApp").factory("ttPackeryService", ['$ihttp', function ($ihttp) {
        var service = {
            list: function (group_id) {
                return $ihttp.post({ method: 1905, parameters: { group_id: group_id } });
            },

            update: function (group_id, item_id, index) {
                return $ihttp.post({ method: 1906, parameters: { group_id: group_id, item_id: item_id, index: index } });
            }
        }

        return service;
    }]);
})();
