(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp
		.filter('taskFilter', function () {
            return function (tasklist, filterType, currentScaleValue, objectNo) {
                var filtered = [];

                angular.forEach(tasklist, function (item) {
                    switch (filterType) {
                        case 2:
                            if (item.jobtime_scalevalue == currentScaleValue && item.equipment_id == objectNo && item.deleted == 0) {
                                filtered.push(item);
                            }
                            break;
                        case 3:
                            if (item.jobtime_scalevalue == currentScaleValue && item.labour_no == objectNo && item.deleted == 0) {
                                filtered.push(item);
                            }
                            break;
                        default: //1
                            if (item.jobtime_scalevalue == currentScaleValue && item.job_no == objectNo && item.deleted == 0) {
                                filtered.push(item);
                            }
                            break;
                    }
                });

                filtered.sort(function (a, b) {
                    a = a.jobtime_start_dt;
                    b = b.jobtime_start_dt;

                    return a > b;
                });

                return filtered;
            };
		})
		.filter('labelFilter', function () {
            return function (labels, starts_with) {
                starts_with = starts_with || '';
                starts_with += '*';

                var filtered = [];

                // BJS 20220216 - starts_with er aldri lik '' siden den settes til '*' rett over her.
                // BJS 20220216 - siden counter aldri brukes til noe og gir en feilmelding siden den ikke er definert så har
                //                jeg kommentert den bort.
                if (starts_with != ''){
                    starts_with = starts_with.replace('?', '*');

                    filtered = [];

                    angular.forEach(labels, function (item) {
                        if (item.labelitem_label) {
                            if (item.labelitem_inscope > 0){
                                if (starts_with.indexOf('*') !== -1) {
                                    //alltid
                                    var s2 = starts_with.replace('*', '');
                                    var is_true = true;
                                    var s = '' + item.labelitem_label;
                                    var arr = s2.split(" ");

                                    angular.forEach(arr, function (search) {
                                        if (s.toUpperCase().indexOf(search.toUpperCase()) !== -1) {
                                            //is_true = true;
                                        } else {
                                            is_true = false;
                                        }
                                    });
                                    
                                    if (is_true){
                                        filtered.push(item);
                                        //counter += 1;
                                    }
                                } else {
                                    var s = '' + item.labelitem_label;
                                    if (item.labelitem_label.toUpperCase().startsWith(starts_with.toUpperCase())) {
                                        filtered.push(item);
                                        //counter += 1;
                                    }
                                }
                            }
                        }                        
                    });
                } else {
                    angular.forEach(labels, function (item) {
                        if (item.labelitem_inscope != 0){
                            filtered.push(item);

                            //counter += 1;
                        }
                    });

                }

                return filtered;
            };
		})
        .filter('ddFilter', function () {
            return function (dd, currentScaleValue, objectNo) {
                var filtered = [];

                angular.forEach(dd, function (item) {
                    if (item.jobtime_scalevalue == currentScaleValue && item.labelitem_keyno == objectNo) {
                        filtered.push(item);
                    }
                });

                filtered.sort(function (a, b) {
                    a = a.jobtime_start_dt;
                    b = b.jobtime_start_dt;

                    return a > b;
                });

                return filtered;
            };
		})
		.filter('timeScaleFilter', function () {
            return function (timescales, max_count) {
                // BJS 20220216 - Kommentert vekk total_count siden den ikke er i bruk.
                return timescales;

                var filtered = [];
                //var total_count = 0;

                angular.forEach(timescales, function (item) {
                    filtered.push(item);

                    //total_count += 1;
                });

                return filtered;
            };
		})
        .directive('sglclick', ['$parse', function ($parse) {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    var fn = $parse(attr['sglclick']);
                    var delay = 300, clicks = 0, timer = null;

                    element.on('click', function (event) {
                        clicks++;  //count clicks
                        if (clicks === 1) {
                            timer = setTimeout(function () {
                                scope.$apply(function () {
                                    fn(scope, { $event: event });
                                });
                                clicks = 0;             //after action performed, reset counter
                            }, delay);
                        } else {
                            clearTimeout(timer);    //prevent single-click action
                            clicks = 0;             //after action performed, reset counter
                        }
                    });
                }
            };
        }]);
})();