(function () {
    'use strict';

    class FurnitureFabricsSuppliersController {
        static $inject = ['ttGridFactory'];
        constructor(ttGridFactory) {
            this.grid = new ttGridFactory({
                rememberId: 'furniturefabricssuppliers_grid',
                loadData: {
                    method: 2902,
                    parameters: {}
                },
                saveData: {
                    method: null,
                    parameters: {}
                }
            }).setKendoConfig({ height: '100%' });
        }
        $onInit() {
            //console.log('I am alive!');
        };

        optionfunc = function (data) {
            //console.log('optionfunc');
            if (angular.isDefined(data.func) && data.func === 'CellClickHandler') { // when a cell is clicked
                //console.log('optionfunc - CellClickHandler');
                //console.dir(data);
            }

            if (angular.isDefined(data.func) && data.func === 'OnCellClose') { // on cell close (on edit finished)
                //console.log('optionfunc - OnCellClose');
                //console.dir(data);
            }

            if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') { // searchable dropdown (autocompletion)
                //console.log('optionfunc - LookupCellEditor');
                //console.dir(data);
            }

            if (angular.isDefined(data.func) && data.func === 'CheckboxBoxClick') {  // When a checkbox is clicked

            }
        };
    }
    const module = angular.module('imApp');
    module.component('furnitureFabricsSuppliers', {
        templateUrl: 'views/components/views/furnitureFabricsSuppliers/furnitureFabricsSuppliers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: FurnitureFabricsSuppliersController
    });
})();
