import { AfterViewInit, ApplicationRef, ComponentRef, Directive, ElementRef, EnvironmentInjector, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, createComponent } from '@angular/core';
import { ListboxComponent, ListboxStyle } from '../../components/listbox/listbox.component';
import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap, shareReplay, defer } from 'rxjs';
import { SearchItem } from '../../components/search/search.component';

/**
 * Appends a listbox modal to the element with this directive.
 *
 * @author JLR
 * @version 20240226
 */
@Directive({
    selector: '[ttListboxPopup]',
    exportAs: 'listbox-popup',
})
export class ListboxPopupDirective<TType extends Record<string | symbol, unknown> = SearchItem> implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input()
    set ttListboxPopup(value: BooleanInput) {
        this._listboxPopupEnabled = coerceBooleanProperty(value);
    }
    get ttListboxPopup(): boolean {
        return this._listboxPopupEnabled;
    }
    _listboxPopupEnabled = true;

    /**
     * The element to position the popup relative to. If not provided it will use the element which the popup is appended to.
     */
    @Input()
    ttRelativeElement?: ElementRef | string;

    @Input()
    ttLabel: string = '';

    /**
     * The list of items to display in the listbox.
     */
    @Input()
    ttData: TType[] | (() => Promise<TType[]>) = [];

    @Input()
    ttMinWidth: string = '30rem';

    /**
     * Observable array which is updated based on searchtext subject.
     */
    data$!: Observable<TType[]>;

    /**
     * The items of the observable array.
     */
    data: TType[] = [];

    /**
     * The key for the property to use as view value in the list.
     */
    @Input()
    ttDataName: keyof TType = 'item_name' as keyof TType;

    /**
     * The key for the property to use as id's for the items in the list.
     */
    @Input()
    ttDataId: keyof TType = 'item_id' as keyof TType;

    @Input()
    set ttTriggerManually(value: BooleanInput) {
        this._triggerManually = coerceBooleanProperty(value);
    }
    get ttTriggerManualy(): boolean {
        return this._triggerManually;
    }
    _triggerManually = false;

    @Input()
    ttShow: boolean = false;

    @Output()
    ttShowChange = new EventEmitter<boolean>();

    /**
     * The `NgModel` form-module attribute on the element the listbox modal is appended to.
     */
    @Input()
    ngModel?: string;

    /**
     * `ngModel` as observable subject, used to update the data if searchtext changes.
     */
    searchtext$ = new Subject<string>();

    /**
     * Emits event when the listbox modal changes the value of ngModel.
     */
    @Output()
    ngModelChange = new EventEmitter<string>();

    /**
     * **Only works for static lists.**
     * Whether to filter the list or sort the list.
     */
    @Input()
    ttFilterOperation: 'filter' | 'sort' | 'none' = 'filter';

    /**
     * The index of the current active selected item, defaults to `0`.
     */
    @Input()
    ttSelectedIndex: number = 0;

    /**
     * Event emitted when a item is selected.
     */
    @Output()
    ttSelect = new EventEmitter<ListboxSelectEvent<TType>>();

    @Input()
    ttStyle: Partial<ListboxStyle> = {
        modal: {},
        listbox: {},
        option: {},
        icon: {},
    };

    /**
     * The minimum required length needed of `ngModel` for the modal to show.
     */
    @Input()
    get ttMinLength(): number {
        return this._minLength;
    }
    set ttMinLength(value: NumberInput) {
        this._minLength = coerceNumberProperty(value);
    }
    _minLength = 0;

    /**
     * The time to wait after a user has finished typing before calling `ttData` if `ttData` is
     * a promise returning a resultset.
     */
    @Input()
    get ttDebounceTime(): number {
        return this._debounceTime;
    }
    set ttDebounceTime(value: NumberInput) {
        this._debounceTime = coerceNumberProperty(value);
    }
    _debounceTime = 500;
    selected: TType | null = null;

    /**
     * Reference to the listbox component.
     */
    private listboxRef: ComponentRef<ListboxComponent<TType>> | null = null;

    constructor(private elementRef: ElementRef, private appRef: ApplicationRef, private injector: EnvironmentInjector) {}

    /**
     * Opens the listbox when the parent has been focused.
     *
     * @param event focus event of the parent.
     */
    @HostListener('focus', ['$event'])
    open(_: FocusEvent) {
        if ((this.ngModel ?? '').length >= this._minLength) this.openListbox();
    }

    /**
     * Closes the listbox if the parent has lost focus.
     *
     * @param event focus event of the parent.
     */
    @HostListener('focusout', ['$event'])
    close(_: FocusEvent) {
        setTimeout(() => this.hideListbox(), 100);
    }

    /**
     * Toggles the selected item with arrow up and down, and selects the active item if enter is pressed.
     *
     * @param event keyboard event of the parent component.
     */
    @HostListener('keydown', ['$event'])
    onKeydown(event: KeyboardEvent): void {
        setTimeout(() => {
            if (this.listboxRef?.instance.ttShow === true && (this.ngModel ?? '')?.length < this.ttMinLength) {
                this.hideListbox();
            } else if ((this.ngModel ?? '')?.length >= this.ttMinLength && event.key !== 'Tab') {
                this.openListbox();
            }

            switch (event.key) {
                case 'Escape':
                    this.hideListbox();
                    break;
                case 'ArrowUp':
                    event.preventDefault();
                    this.decrementSelectedIndex();
                    break;
                case 'ArrowDown':
                    event.preventDefault();
                    this.incrementSelectedIndex();
                    break;
                case 'Enter':
                    event.preventDefault();
                    this.selectSearchItemAtActiveIndex(event);
                    break;
                default:
                    break;
            }

            this.updateListbox('ttSelectedIndex', this.ttSelectedIndex);
        });
    }

    /**
     * Forces a search.
     *
     * @returns an empty promise which fullfills after the process of updating the data has begun.
     */
    public async forceSearch(): Promise<void> {
        return new Promise((resolve) => {
            this.searchtext$.next('');

            setTimeout(() => {
                this.searchtext$.next(this.ngModel ?? '');
                this.openListbox();
                resolve();
            }, this.ttDebounceTime);
        });
    }

    /**
     * Increments the selected index.
     */
    private incrementSelectedIndex() {
        if (this.ttSelectedIndex === undefined || this.ttSelectedIndex === null) {
            this.ttSelectedIndex = 0;
        } else if (this.ttSelectedIndex < this.data.length - 1) {
            this.ttSelectedIndex++;
        } else if (this.ttSelectedIndex >= this.data.length - 1) {
            this.ttSelectedIndex = 0;
        }
    }

    /**
     * Decrements the selected index.
     */
    private decrementSelectedIndex() {
        if (this.ttSelectedIndex > 0) {
            this.ttSelectedIndex--;
        }
    }

    /**
     * Emits the selected event on the item with of the currently selected index.
     */
    private selectSearchItemAtActiveIndex(event: KeyboardEvent) {
        if (this.ttSelectedIndex >= 0) {
            const item = this.data.at(this.ttSelectedIndex);
            if (item) this.onSelect({ item: item, event: event });
        }
    }

    /**
     * Selects the currently active item and closes the modal.
     *
     * @param item the item to select.
     */
    private onSelect(item: ListboxSelectEvent<TType>) {
        this.ttSelect.emit(item);
        // this.ngModel = `${item.item[this.ttDataName]}`;
        // this.ngModelChange.emit(`${item.item[this.ttDataName]}`);
        this.hideListbox();
    }

    /**
     * Opens the listbox.
     */
    private openListbox(): void {
        if (!this._triggerManually && this.ttListboxPopup) {
            this.updateListbox('ttShow', true);
            this.ttShowChange.emit(true);

            if (this.ttData instanceof Array) {
                const selectedItem = this.ttData.find((item) => item[this.ttDataName] === this.ngModel);

                if (selectedItem) {
                    // this.ttSelectedIndex = this.ttData.indexOf(selectedItem);
                    this.updateListbox('ttSelectedIndex', this.ttData.indexOf(selectedItem));
                }
            }
        }
    }

    /**
     * Closes the listbox.
     */
    private hideListbox(): void {
        if (!this._triggerManually) {
            this.updateListbox('ttShow', false);
            this.ttShowChange.emit(false);
        }
    }

    /**
     * Creates an instance of listbox and appends it to the body and appview.
     */
    private createListbox(): void {
        if (!this.listboxRef) {
            this.listboxRef = createComponent(ListboxComponent<TType>, { environmentInjector: this.injector });

            this.listboxRef.setInput('ttData', this.data$);
            this.listboxRef.setInput('ttRelativeElement', this.ttRelativeElement ?? this.elementRef);
            this.listboxRef.setInput('ttFilterValue', this.ngModel);
            this.listboxRef.setInput('ttSelectedIndex', this.ttSelectedIndex);
            this.listboxRef.setInput('ttDataName', this.ttDataName);
            this.listboxRef.setInput('ttDataId', this.ttDataId);
            this.listboxRef.setInput('ttMinWidth', this.ttMinWidth);
            this.listboxRef.setInput('ttLabel', this.ttLabel);
            this.listboxRef.setInput('ttShow', false);
            this.listboxRef.setInput('ttStyle', this.ttStyle);

            this.listboxRef.instance.ttSelect.subscribe((event) => {
                this.onSelect(event);
            });

            this.listboxRef.changeDetectorRef.detectChanges();

            document.body.appendChild(this.listboxRef.location.nativeElement);
            this.appRef.attachView(this.listboxRef.hostView);
        }
    }

    /**
     * Updates the given input of the component which has the given key to the given value.
     *
     * @param key the name of the input of the listbox component to update.
     * @param value the value to update the input with.
     */
    private updateListbox(key: string, value: unknown): void {
        if (this.listboxRef) {
            this.listboxRef.setInput(key, value);
            this.listboxRef.changeDetectorRef.detectChanges();
        }
    }

    /**
     * Removes the listbox from the body and appview, and destroys the component.
     */
    private destroyListbox(): void {
        if (this.listboxRef) {
            document.body.removeChild(this.listboxRef.location.nativeElement);
            this.appRef.detachView(this.listboxRef?.hostView);

            this.listboxRef.instance.ttSelect.unsubscribe();
            this.listboxRef.destroy();
            this.listboxRef = null;
        }
    }

    /**
     * Filters the given with the given string according to the filter-operation prop.
     *
     * @param items the list of items to filter through.
     * @param query the string to search for matching values of.
     * @returns a new list containing items which had any matching values with the query.
     */
    private filterItemsByFilterOperation(items: TType[], query: string) {
        let filtered: TType[] = [];

        switch (this.ttFilterOperation) {
            case 'filter':
                filtered = items.filter((item: TType) => (Object.values(item).filter((value) => (`${value}`.toLowerCase().toString().includes(query.toLocaleLowerCase()) ? true : false)).length > 0 ? true : false));
                break;
            case 'sort':
                filtered = items.sort((a: TType, b: TType) => this.findMatchRatio(`${b[this.ttDataName] ?? ''}`, query) - this.findMatchRatio(`${a[this.ttDataName] ?? ''}`, query));
                break;
            case 'none':
            default:
                filtered = items;
                break;
        }

        return filtered;
    }

    /**
     * Finds the percantage of matching characters between the string value given and the query given.
     * If query is 'test2', but value is 'test', the match ratio will be 0.
     *
     * @param value the value to test the match ratio with.
     * @param query the query to test the matching with value with.
     * @returns a number representing the percentage of matching characters between the string value and the query.
     */
    private findMatchRatio(value: string, query: string): number {
        return Number(this.findMatchLength(`${value}`, query)) / Number(value.length > 0 ? value.length : 1);
    }

    /**
     * Finds the length of the matching string between the value string and given query.
     * If query is 'test2' and value is 'test', then the length return will be 0.
     *
     * @param value the value to finding matches with.
     * @param query the query to find matches with.
     * @returns the length of the matching parts between value and query.
     */
    private findMatchLength(value: string, query: string): number {
        function escapeRegexp(queryToEscape: string) {
            return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
        }

        function containsHtml(matchItem: string) {
            return /<.*>/g.test(matchItem);
        }

        function match(matchItem: string, query: string) {
            if (!containsHtml(matchItem)) {
                const regex = new RegExp(escapeRegexp(query), 'gi');
                const matches = matchItem.match(regex);

                if (matches && matches.length > 0) {
                    return matches[0];
                }
            }
            return '';
        }

        return match(value, query) ? match(value, query).length : 0;
    }

    /**
     * Filters the data set coming from a function.
     *
     * @param search the function returning the data.
     * @param result the string to filter the list with if the function returns a static list, not a promise.
     * @returns a promise containing the filtered list.
     */
    private async filterData(search: () => Promise<TType[]> | TType[], result: string): Promise<TType[]> {
        let results: TType[] = [];

        if (result.length >= this.ttMinLength) {
            let data = search();

            if (data instanceof Promise) {
                results = await data;
            } else {
                results = data;
            }
            results = this.filterItemsByFilterOperation(results, result);
        }

        return results;
    }

    async ngOnInit(): Promise<void> {
        if (this.ttData instanceof Function) {
            const search = this.ttData;

            this.data$ = this.searchtext$.pipe(
                debounceTime(this.ttDebounceTime),
                distinctUntilChanged(),
                switchMap((result) => this.filterData(search, result)),
                shareReplay(1)
            ) as Observable<TType[]>;

            this.data$.subscribe((data) => (this.data = data));
        } else if (this.ttData instanceof Array) {
            this.data = this.ttData;
        }

        this.createListbox();

        this.searchtext$.next(this.ngModel ?? '');

        if (this.ttData instanceof Array) {
            // const selectedItem = this.ttData.find((item) => item[this.ttDataName] === this.ngModel);

            // if (selectedItem) {
            //     this.ttSelectedIndex = this.ttData.indexOf(selectedItem);

            //     this.updateListbox('ttSelectedIndex', this.ttData.indexOf(selectedItem));
            // }

            this.updateListbox('ttData', this.filterItemsByFilterOperation(this.ttData, this.ngModel ?? ''));
        }
    }

    ngAfterViewInit(): void {
        if (this.ttRelativeElement) {
            this.updateListbox('ttRelativeElement', this.ttRelativeElement);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttData']) {
            this.data = changes['ttData'].currentValue;
            this.updateListbox('ttData', changes['ttData'].currentValue);
        }

        if (changes?.['ttStyle']) {
            this.updateListbox('ttStyle', changes['ttStyle'].currentValue);
        }

        if (changes?.['ttLabel']) {
            this.updateListbox('ttLabel', changes['ttLabel'].currentValue);
        }

        if (changes?.['ttShow']) {
            this.updateListbox('ttShow', changes['ttShow'].currentValue);

            if (this.ttData instanceof Array) {
                const selectedItem = this.ttData.find((item) => item[this.ttDataName] === this.ngModel);

                if (selectedItem) {
                    this.ttSelectedIndex = this.ttData.indexOf(selectedItem);

                    this.updateListbox('ttSelectedIndex', this.ttData.indexOf(selectedItem));
                }
            }
        }

        if (changes?.['ngModel']?.currentValue || changes?.['ngModel']?.currentValue == '') {
            if (this.ttRelativeElement) {
                this.updateListbox('ttRelativeElement', this.ttRelativeElement);
            }

            this.updateListbox('ttFilterValue', changes?.['ngModel']?.currentValue);
            // this.updateListbox('ttSelectedIndex', (this.ttSelectedIndex = 0));

            this.searchtext$.next(changes['ngModel'].currentValue);

            if (this.ttData instanceof Array) {
                const selectedItem = this.ttData.find((item) => item[this.ttDataName] === changes['ngModel'].currentValue);

                if (selectedItem) {
                    this.ttSelectedIndex = this.ttData.indexOf(selectedItem);
                    this.updateListbox('ttSelectedIndex', this.ttData.indexOf(selectedItem));
                }

                this.updateListbox('ttData', this.filterItemsByFilterOperation(this.ttData, changes['ngModel'].currentValue));
            }
        }
    }

    ngOnDestroy(): void {
        this.destroyListbox();
    }
}

export interface ListboxSelectEvent<TType extends Record<string | symbol, unknown>> {
    item: TType;
    event: MouseEvent | PointerEvent | KeyboardEvent;
}
