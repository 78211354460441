(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2SubscriptionEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_subscription_edit: {
                p2_setting_keyno: '',
                word_id: '',
                input_type: '',
                default_value: '',
                choicedata: '',
                setting_name: '',
                price_eur: '',
                price_locval: ''
            },
            loadP2SubscriptionEdit: function (p2_subscription_keyno) {
                var deferred = $q.defer();

                var parms_p2subscription_get = {
                    method: 80,
                    parameters: {
                        p2_subscription_keyno: p2_subscription_keyno
                    }
                };

                $ihttp.post(parms_p2subscription_get).then(function (data) {
                    service.p2_subscription_edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2SubscriptionEdit: function () {
                var deferred = $q.defer();

                var parms_p2subscription_save = {
                    method: 81,
                    parameters: {
                        p2_subscription_keyno: service.p2_subscription_edit.p2_subscription_keyno,
                        word_id_heading: service.p2_subscription_edit.word_id_heading,
                        word_id_heading_currentlanguage: service.p2_subscription_edit.word_id_heading_currentlanguage,
                        word_id_description: service.p2_subscription_edit.word_id_description,
                        word_id_description_currentlanguage: service.p2_subscription_edit.word_id_description_currentlanguage,
                        price_eur: service.p2_subscription_edit.price_eur
                    }
                };

                $ihttp.post(parms_p2subscription_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();