(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('requisitionCreate', {
        templateUrl: 'views/components/views/requisitionCreate/requisitionCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'requisitionCreateService', function ($stateParams, stateService, requisitionCreateService) {
            var vm = this;
            
            vm.model = {
                p2WRequisitionCreateGet: {}
            };
    
            vm.loadP2WRequisitionCreateGet = function () {
                requisitionCreateService.genericFunction(760, {
                    stock_keyno_from: $stateParams.stock_keyno_from,
                    stock_keyno_to: $stateParams.stock_keyno_to
                }).then(function (data) {
                    vm.model.p2WRequisitionCreateGet = angular.copy(data[0]);
                });
            };
    
            vm.loadP2WRequisitionCreateGet();
    
            vm.createRequisition = function () {
                requisitionCreateService.genericFunction(761, vm.model.p2WRequisitionCreateGet).then(function (data) {
                    stateService.back('/' + 'stockget' + '/' + $stateParams.stock_keyno_to);
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'stockget':
                        go({ stock_keyno: vm.model.p2WRequisitionCreateGet.stock_keyno_from });
                        break;
                }
            };
    
            vm.goToStockGet = function () {
                stateService.go('stockget', {
                    stock_keyno: vm.model.p2WRequisitionCreateGet.stock_keyno_to
                });
            };
        }]
    });
})();