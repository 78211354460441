(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("unitLangService", ['$ihttp', function ($ihttp) {
        let service = {
            listUnitLanguages: function (parms) {
                return $ihttp.post({
                    method: 606,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 607,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
