(function () {
    'use strict';

    // ReSharper disable once UndeclaredGlobalVariableUsing
    let module = angular.module('imApp');

    module.component('p2UpdateScripts', {
        templateUrl: 'views/components/views/p2UpdateScripts/p2UpdateScripts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'utilityService', 'stateService', 'p2UpdateScriptsService', 'rememberService', function ($q, utilityService, stateService, p2UpdateScriptsService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
    		
            let variableNames = {
                runbatch: '',
                filtervalue: ''
            };

            vm.model = {
                runBatchButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('runbatch') }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ], 
                setting: {},
                selectListRunBatches: [],
                itemsListUpdateScripts: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                let deferred = $q.defer();
                
                p2UpdateScriptsService.getUpdatScriptSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadUpdateScripts()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadRunBatches = function () {
                p2UpdateScriptsService.listRunBatches({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListRunBatches);
                });
            };
            
            let loadUpdateScripts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.runbatch, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                vm.model.itemsListUpdateScripts = [];

                return p2UpdateScriptsService.listUpdateScripts(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListUpdateScripts);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'runbatch':
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'runbatch':
                        vm.model.setting.runbatch = 'all';

                        if (vm.model.setting.runbatch === 'all') {
                            rememberFunc('runbatch');
                            loadUpdateScripts();
                        }
                        break;
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2updatescriptedit':
                        stateService.go(state, { p2_updatescript_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                switch (id) {
                    case 'runbatch':
                        if (utilityService.validateWatchValue(value, vm.model.setting.runbatch) !== true) return;

                        vm.model.setting.runbatch = value;

                        if (utilityService.validateParmsValue(vm.model.setting.runbatch, true) !== true) return;

                        rememberFunc('runbatch');
                        loadUpdateScripts();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        break;
                }
            };

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (vm.model.setting.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadRunBatches();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
