(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderLinePs', {
        templateUrl: 'views/components/views/orderLinePs/orderLinePs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'orderLinePsService', 'rememberService', function ($stateParams, $q, stateService, orderLinePsService, rememberService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let headNo = $stateParams.headno;
            let lineNo = $stateParams.lineno;
            let selectedItemId = $stateParams.selected_item_id;
            
            vm.model = {
                bgOrderLinePsFunction: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_orderlineps_function_select', color: 'primary', onClick: () => vm.model.bgOrderLinePsFunction.selected = 'SELECT' },
                        { id: 'SELECTED', label: 'bg_orderlineps_function_selected', color: 'primary', onClick: () => vm.model.bgOrderLinePsFunction.selected = 'SELECTED' }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
                itemsListSelectProducts: [],
                itemsListSelectedProducts: [],
                lockedReset: false
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                orderLinePsService.getSetting({
                    argtype: argType,
                    headno: headNo,
                    lineno: lineNo,
                    selected_item_id: selectedItemId
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadSelectProducts();
                    loadSelectedProducts();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadSelectProducts = function () {
                return orderLinePsService.listSelectProducts(vm.model.setting).then(function (list) {
                    vm.model.lockedReset = false;
                    angular.copy(list, vm.model.itemsListSelectProducts);
                });
            };
            
            let loadSelectedProducts = function () {
                return orderLinePsService.listSelectedProducts(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListSelectedProducts);
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgOrderLinePsFunction = function (value) {
                vm.model.bgOrderLinePsFunction.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            let updateReturnQty = function (item) {
                var docontinue = -1;

                for (var i = 0; i < vm.model.itemsListSelectedProducts.length; i++) {
                    if (vm.model.itemsListSelectedProducts[i].item_id === item.item_id) {
                        var data = vm.model.itemsListSelectedProducts[i];
                        //data.quantity = parseFloat(data.quantity) + 1;
                        vm.model.itemsListSelectedProducts[i] = data;
                        docontinue = data.quantity;
                    }
                }

                return docontinue;
            };
            
            vm.clicked = function (item) {
                //item.quantity = 0;
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (angular.isUndefined(item.quantity) || item.quantity === null || item.quantity === '') {
                    item.quantity = 0;
                }
                
                item.quantity++;
                var qty = updateReturnQty(item);

                if (qty < 0) {
                    //Legg til ny record
                    vm.model.itemsListSelectedProducts.push(item);
                }
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'productsconfig':
                        return rememberService.remember('productsconfig_selected', btoa(angular.toJson(vm.model.itemsListSelectedProducts))).then(function () {
                            go({
                                argtype: vm.model.setting.argtype,
                                headno: vm.model.setting.headno,
                                lineno: vm.model.setting.lineno
                            });
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            vm.resetSelectedProducts = function () {
                vm.model.lockedReset = true;
                vm.model.itemsListSelectedProducts = [];
                loadSelectProducts();
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
