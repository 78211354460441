(function () {
    'use strict';
    angular.module('imApp')
        .directive('imBoxesDirective', ['$q', '$ihttp', '$window', '$stateParams', '$timeout', '$rootScope', '$compile', 'userService', 'stateService', 'responsive', function ($q, $ihttp, $window, $stateParams, $timeout, $rootScope, $compile, userService, stateService, responsive) {
            var directive = {
                restrict: 'E',
                transclude: true,
                scope: {
                    data: '=',                  // Used for linking a list to the directive, specifies the order, placement and number of boxes
                    //heightDependencies: '=?',   // Used to make the boxes have
                    //setup: '=',                 // Used for linking the setup variables
                    //options: '=',               // Used for linking the setup variables
                    height: '@?',
                    onResize: '&?',               // function to call when one of the boxes has been resized.
                },
                template:
                    '<ng-transclude id="{{model.mId}}" class="im-boxes" ng-style="{height: \'{{height}}\' }">' +
                    '</ng-transclude>',
                link: function ($scope, $element) {
                    $scope.model = {
                        mId: uuid(),
                        boxes: [],
                        dividers: []
                    };

                    /**
                     * holds the size of the boxes, key is the item_name of the data.boxes list,
                     * the value is a number representing a percentage for the size.
                     */
                    $scope.sizes = {};

                    function insertDivider(id, divider) {
                        //console.log('divider');
                        //console.dir(divider);
                        var dividerClass = divider.type === 'h' ? 'im-boxes-divider-h' : 'im-boxes-divider-v';
                        var ellipsisClass = divider.type === 'h' ? '<i class="far fa-ellipsis-h im-ellipsis-h"></i>' : '<i class="far fa-ellipsis-v im-ellipsis-v"></i>';
                        var el = document.getElementById(id),
                            html = '<div class="' + dividerClass + '" id="' + divider.id + '">' + ellipsisClass + '</div>';

                        // Internet Explorer, Opera, Google Chrome and Safari
                        if (el.insertAdjacentHTML)
                            el.insertAdjacentHTML("beforeBegin", html);
                        else {
                            var range = document.createRange();
                            var frag = range.createContextualFragment(html);
                            el.parentNode.insertBefore(frag, el);
                        }
                    }

                    function removeDividers() {
                        $scope.model.dividers.forEach((divider) => $(`#${divider.id}`).remove());
                        $scope.model.dividers = [];
                    }

                    //function elemAtDepth(elem, currentDepth, targetDepth) {
                    //    if (currentDepth <= targetDepth) {
                    //        elem = elem();
                    //    }

                    //    return elem;
                    //}

                    function addDepth(arr, id) {
                        var found = arr.some(function (el) { return el.id === id; });
                        if (!found) { arr.push({ id: id, count: 1 }); }
                        else { arr[id].count = arr[id].count + 1; }
                        return arr;
                    }

                    //function thisHas(item, contains) {
                    //    return item.indexOf(contains) > -1; //true
                    //}

                    var minOffset = 200;
                    var maxOffset = 600;

                    //console.dir($scope.data);
                    //console.log('heights');

                    //$(document).ready(function () {
                    //    console.log('onload');
                    //    console.log($(window).height()); // returns height of browser viewport
                    //    console.dir(document);
                    //    console.dir($scope.data.dependencies[0].item_id);
                    //    angular.forEach($scope.data.dependencies, function (item) {
                    //        //var elem = angular.element('#' + item.item_id)[0];
                    //        var elem = document.getElementById(item.item_id);
                    //        console.dir(elem);
                    //    });
                    //});

                    //$(window).onload = function () {
                    //    console.log('onload');
                    //    console.log($(window).height()); // returns height of browser viewport
                    //    angular.forEach($scope.data.dependencies, function (item) {
                    //        //var elem = angular.element('#' + item.item_id)[0];
                    //        //var elem = document.getElementById(item.item_id);
                    //        console.dir(elem);
                    //    });
                    //}

                    //console.log($(window).height()); // returns height of browser viewport
                    //console.log($('body').height()); // returns height of HTML document
                    //console.log($(document).height()); // returns height of HTML document
                    //console.dir($element[0]); // returns height of HTML document
                    //console.dir($('form')); // returns height of HTML document

                    //var elems = $element[0].firstChild.firstChild.children;
                    var mainElem = $element[0].firstChild
                    var elems = mainElem.children;
                    var minWidth = '0%';
                    async function tick(ms) {
                        return new Promise((resolve) => setTimeout(resolve, ms ?? 0));
                    }

                    $scope.$watch(function () { return $scope.data; }, async function (newValue, oldValue) {
                        if (angular.isUndefined($scope.data) || $scope.data === null) return;

                        if (angular.isDefined($scope.data.height) && $scope.data.height !== null && $scope.data.height !== '') {
                            mainElem.style.height = $scope.data.height + 'px';
                        } else {
                            await tick(100);
                            //console.dir($('im-boxes-directive')[0].children[0]);
                            mainElem.style.height = `calc(100vh - ${$('im-boxes-directive')[0].children[0].getBoundingClientRect().y + 5}px)`;
                        }
                    }, true);

                    $scope.$watchGroup([function () { return elems[elems.length - 1].id }, () => $scope.data.boxes], async function (newValue, oldValue) {

                        if (angular.isUndefined($scope.data) || $scope.data === null || angular.isUndefined($scope.data.boxes) || $scope.data.boxes === null || newValue.indexOf('{{model.') > 0 || newValue.indexOf('.item_id}}') > 0) return;
                        await getUserVariable();
                        await tick(100);
                        //console.log('imboxeselems');
                        //console.dir(elems);
                        //console.dir($scope.data.boxes);
                        //console.dir(newValue);
                        var depthCounts = [{ id: 0, count: 0 }];

                        //var allStyle = { fontSize: $scope.fontSizes.textSize, display: 'inline-block' }; //default
                        //allStyle = Object.assign(allStyle, { width: $scope.model.fieldWidthMin + '%', minWidth: $scope.model.fieldWidthMin + '%' });

                        angular.forEach($scope.data.boxes, function (item) {
                            depthCounts = addDepth(depthCounts, item.depth);
                        });

                        //for (var a = 0; a < $scope.data.boxes.length; a++) {
                        //    var found = arr.some(el => el.id === $scope.data.boxes[a].depth);
                        //    if (!found) { arr.push({ id: a, count: 1 }); }
                        //    else { arr[$scope.data.boxes[a].id].count = arr[$scope.data.boxes[a].id].count + 1; }
                        //}

                        //console.log('depthCounts');
                        //console.dir(depthCounts);

                        var depthValue = -1;
                        $scope.model.boxes = [];
                        removeDividers();

                        angular.forEach($scope.data.boxes, async function (item) {
                            var didFindElem = false;
                            if (angular.isDefined(item)) {
                                //for (var d = 1; d <= item.depth; d++) {
                                //    elem = elemAtDepth(elems)
                                //}
                                var newDepth = false;

                                //divider data
                                var defaultDivider = {
                                    id: '', //NOT SET BY USER
                                    type: 'v', // v/h = vertical / horizontal
                                    size: '20' + 'px', // any % / px
                                    dragable: true, // true / false
                                    clickable: true, // true / false //NOT IMPLEMENTED
                                    height: '', //NOT SET BY USER
                                    width: '', //NOT SET BY USER
                                    minSize: '20' + 'px', //NOT SET BY USER
                                    minHeight: '20' + 'px', //NOT SET BY USER
                                    minWidth: '20' + 'px', //NOT SET BY USER
                                    originalPosition: '', //NOT SET BY USER
                                    currentPosition: '', //NOT SET BY USER
                                    previousPosition: '', //NOT SET BY USER
                                    isClicked: false, //NOT SET BY USER //NOT IMPLEMENTED
                                    initialized: false, //NOT SET BY USER
                                    boxId: '' //NOT SET BY USER, INHERITED
                                }

                                var dividersIndex = -1;
                                var dividerSize = 0;

                                if (depthValue !== item.depth) {
                                    depthValue = item.depth;
                                    newDepth = true;
                                }

                                for (var i = 0; i < elems.length; i++) {
                                    if (elems[i].className.indexOf('im-boxes-box') <= -1) continue;
                                    if (angular.isDefined(elems[i]) && angular.isDefined(elems[i].id) && elems[i].id === item.item_id) {
                                        didFindElem = true;
                                        //console.dir(elems[i]);

                                        dividersIndex = $scope.model.dividers.length;
                                        dividerSize = 5;
                                        if (newDepth) dividerSize = 0;

                                        $scope.model.boxes.push(item);
                                        $scope.model.dividers.push(defaultDivider);
                                        $scope.model.dividers[dividersIndex].id = uuid();
                                        $scope.model.dividers[dividersIndex].boxId = item.item_id;
                                        $scope.model.dividers[dividersIndex].type = angular.isDefined(item.type) === true ? item.type : $scope.model.dividers[dividersIndex].type;
                                        //elems[i].style.width = 100 / $scope.data.boxes.length + '%';
                                        if (item.type === 'h') {
                                            //elems[i].style.height = 'calc((100% - 12px * ' + $scope.data.boxes.length + ') / ' + $scope.data.boxes.length + ')';
                                            if (angular.isDefined(item.size) && item.size !== '') {
                                                if (item.size.indexOf('px') > -1 || item.size.indexOf('%') > -1) {
                                                    elems[i].style.height = 'calc(' + item.size + ' - ' + dividerSize.toString() + 'px)';
                                                } else {
                                                    elems[i].style.height = 'calc(' + item.size + '% - ' + dividerSize.toString() + 'px)';
                                                }
                                            } else {
                                                elems[i].style.height = 'calc((100% - ' + dividerSize.toString() + 'px * ' + depthCounts[item.depth].count + ') / ' + depthCounts[item.depth].count + ')';
                                            }
                                        } else {
                                            let scrollbarsize = window.innerWidth - document.body.clientWidth;
                                            console.dir('scrollbarsize: ' + scrollbarsize);
                                            //console.dir('elementsLength: ' + elementsLength);
                                            console.dir('dividerSize: ' + dividerSize);
                                            const isLast = elems.length - 1 === i;
                                            if (angular.isDefined(item.size) && item.size !== '') {
                                                if (item.size.indexOf('px') > -1 || item.size.indexOf('%') > -1) {
                                                    //elems[i].style.width = 'calc(' + item.size + ' - ' + Math.ceil(dividerSize + scrollbarsize / elementsLength) + 'px)';
                                                    elems[i].style.width = 'calc(' + item.size + ' - 0.' + Math.ceil(dividerSize) + 'rem)';
                                                    //elems[i].style.width = 'calc(' + item.size + ' - ' + Math.ceil(dividerSize + 1) + 'px)';
                                                } else {
                                                    //elems[i].style.width = 'calc(' + item.size + '% - ' + Math.ceil(dividerSize + scrollbarsize / elementsLength) + 'px)';
                                                    elems[i].style.width = 'calc(' + item.size + '% - 0.' + Math.ceil(dividerSize) + 'rem)';
                                                    //elems[i].style.width = 'calc(' + item.size + '% - ' + Math.ceil(dividerSize + 1) + 'px)';
                                                }

                                                if (isLast) {
                                                    elems[i].style.width = 'calc(' + item.size + '% - ' + (scrollbarsize === 0 ? 18 : dividerSize + scrollbarsize) + 'px)';
                                                }
                                            } else {
                                                elems[i].style.width = 'calc((100% - ' + dividerSize.toString() + 'px * ' + depthCounts[item.depth].count + ') / ' + depthCounts[item.depth].count + ')';
                                            }
                                        }

                                        insertDivider(elems[i].id, $scope.model.dividers[dividersIndex]);
                                        elems[i].style.touchAction = 'none';
                                    }

                                    if (didFindElem) break;
                                }

                                if (!didFindElem) {
                                    //console.dir(elems[0].children);
                                    for (var j = 0; j < elems.length; j++) {
                                        if (elems[j].className.indexOf('im-boxes-box') <= -1) continue;
                                        for (var c = 0; c < elems[j].children.length; c++) {
                                            if (elems[j].children[c].className.indexOf('im-boxes-box') <= -1) continue;
                                            if (angular.isDefined(elems[j].children[c]) && angular.isDefined(elems[j].children[c].id) && elems[j].children[c].id === item.item_id) {
                                                didFindElem = true;
                                                //console.dir(elems[j].children[c]);

                                                dividersIndex = $scope.model.dividers.length;
                                                dividerSize = 5;
                                                if (newDepth) dividerSize = 0;

                                                $scope.model.boxes.push(item);
                                                $scope.model.dividers.push(defaultDivider);
                                                $scope.model.dividers[dividersIndex].id = uuid();
                                                $scope.model.dividers[dividersIndex].boxId = item.item_id;
                                                $scope.model.dividers[dividersIndex].type = angular.isDefined(item.type) === true ? item.type : $scope.model.dividers[dividersIndex].type;
                                                //elems[i].style.width = 100 / $scope.data.boxes.length + '%';
                                                //elems[i].style.width = 'calc((100% - 12px * ' + $scope.data.boxes.length + ') / ' + $scope.data.boxes.length + ')';
                                                if (item.type === 'h') {
                                                    if (angular.isDefined(item.size) && item.size !== '') {
                                                        if (item.size.indexOf('px') > -1 || item.size.indexOf('%') > -1) {
                                                            elems[j].children[c].style.height = 'calc(' + item.size + ' - ' + dividerSize.toString() + 'px)';
                                                        } else {
                                                            elems[j].children[c].style.height = 'calc(' + item.size + '% - ' + dividerSize.toString() + 'px)';
                                                        }
                                                    } else {
                                                        elems[j].children[c].style.height = 'calc((100% - ' + dividerSize.toString() + 'px * ' + depthCounts[item.depth].count + ') / ' + depthCounts[item.depth].count + ')';
                                                    }
                                                } else {

                                                    if (angular.isDefined(item.size) && item.size !== '') {
                                                        if (item.size.indexOf('px') > -1 || item.size.indexOf('%') > -1) {
                                                            elems[j].children[c].style.width = 'calc(' + item.size + ' - ' + dividerSize.toString() + 'px)';
                                                        } else {
                                                            elems[j].children[c].style.width = 'calc(' + item.size + '% - ' + dividerSize.toString() + 'px)';
                                                        }
                                                    } else {
                                                        elems[j].children[c].style.width = 'calc((100% - ' + dividerSize.toString() + 'px * ' + depthCounts[item.depth].count + ') / ' + depthCounts[item.depth].count + ')';
                                                    }
                                                }

                                                insertDivider(elems[j].children[c].id, $scope.model.dividers[dividersIndex]);
                                                elems[j].children[c].style.touchAction = 'none';
                                            }

                                            if (didFindElem) break;
                                        }

                                        if (didFindElem) break;
                                    }
                                }
                            }
                            setSizeFromUserVariable();

                        });

                        // #region USERSETTING

                        function setSizeFromUserVariable() {
                            Object.keys($scope.sizes).forEach((boxName) => {
                                let box = $(`[data-name="${boxName}"`);

                                if (box && box.hasClass('im-boxes-box-h')) {
                                    box.css('height', $scope.sizes[boxName] + '%');
                                } else if (box && box.hasClass('im-boxes-box-v')) {
                                    box.css('width', $scope.sizes[boxName] + '%');
                                }
                            })
                        }

                        function setUserVariable() {
                            if ($scope.data.rememberId) {
                                return $ihttp.post({ method: 616, parameters: { variablename: $scope.data.rememberId, variablevalue: JSON.stringify($scope.sizes) } });
                            }
                        }

                        async function getUserVariable() {
                            if ($scope.data.rememberId) {
                                const response = await $ihttp.post({ method: 973, parameters: { variablename: $scope.data.rememberId } });
                                if (response[0].variablevalue.length > 0)
                                    $scope.sizes = JSON.parse(response[0].variablevalue);
                            }
                        }

                        // #endregion USERSETTING

                        // #region EVENTHANDLING

                        let resizingHeight = false;
                        let resizeHeightInfo = {};
                        let resizingWidth = false;
                        let resizeWidthInfo = {};

                        function startHeightResize(event) {
                            if (resizingWidth || !event?.target?.parentElement?.previousElementSibling) return;

                            if (resizingHeight === false) resizingHeight = true;

                            for (const element of elems) {
                                element.style.pointerEvents = 'none';
                                element.style.touchAction = 'none';
                            }

                            const originalPageY = event.pageY;
                            const parent = event.target.parentElement;
                            const prevElem = parent.previousElementSibling;
                            const nextElem = parent.nextElementSibling;
                            const offsetPrev = prevElem.offsetHeight;
                            const offsetNext = nextElem.offsetHeight;
                            document.body.style.cursor = 'row-resize';

                            resizeHeightInfo = {
                                originalPageY: originalPageY,
                                parent: parent,
                                prevElem: prevElem,
                                nextElem: nextElem,
                                offsetPrev: offsetPrev,
                                offsetNext: offsetNext,
                            }
                        }

                        function startWidthResize(event) {
                            if (resizingHeight || !event?.target?.parentElement?.previousElementSibling) return;

                            if (resizingWidth === false) resizingWidth = true;

                            for (const element of elems) {
                                element.style.pointerEvents = 'none';
                                element.style.touchAction = 'none';
                            }

                            const originalPageX = event.pageX;
                            const parent = event.target.parentElement;
                            const prevElem = parent.previousElementSibling;
                            const nextElem = parent.nextElementSibling;
                            const offsetPrev = prevElem.offsetWidth;
                            const offsetNext = nextElem.offsetWidth;
                            document.body.style.cursor = 'col-resize';

                            resizeWidthInfo = {
                                originalPageX: originalPageX,
                                parent: parent,
                                prevElem: prevElem,
                                nextElem: nextElem,
                                offsetPrev: offsetPrev,
                                offsetNext: offsetNext,
                            }
                        }

                        function resizeHeight(event) {
                            if (Object.keys(resizeHeightInfo).length === 0 || !resizingHeight) return;

                            const offsetDivider = event.pageY;
                            let pageDiff = offsetDivider - resizeHeightInfo.originalPageY;

                            if (pageDiff <= -(resizeHeightInfo.offsetPrev)) {
                                pageDiff = -(resizeHeightInfo.offsetPrev);
                            } else if (pageDiff >= (resizeHeightInfo.offsetNext)) {
                                pageDiff = resizeHeightInfo.offsetNext;
                            }

                            const newOffsetPrev = resizeHeightInfo.offsetPrev + pageDiff;
                            const newOffsetNext = resizeHeightInfo.offsetNext - pageDiff;

                            $(resizeHeightInfo.prevElem).css('height', newOffsetPrev);
                            $(resizeHeightInfo.nextElem).css('height', newOffsetNext);

                            const mainElemHeight = window.getComputedStyle(mainElem).getPropertyValue('height');

                            if (resizeHeightInfo?.nextElem?.dataset?.name && resizeHeightInfo?.prevElem?.dataset?.name && mainElemHeight) {
                                $scope.sizes[resizeHeightInfo.prevElem.dataset.name] = Math.round(newOffsetPrev / Number(mainElemHeight.replace('px', '')) * 100);
                                $scope.sizes[resizeHeightInfo.nextElem.dataset.name] = 100 - Math.round(newOffsetPrev / Number(mainElemHeight.replace('px', '')) * 100) - (5 / Number(mainElemHeight.replace('px', '')));
                            }
                        }

                        function resizeWidth(event) {
                            if (Object.keys(resizeWidthInfo).length === 0 || !resizingWidth) return;

                            const offsetDivider = event.pageX;
                            let pageDiff = offsetDivider - resizeWidthInfo.originalPageX;

                            if (pageDiff <= -(resizeWidthInfo.offsetPrev)) {
                                pageDiff = -(resizeWidthInfo.offsetPrev);
                            } else if (pageDiff >= (resizeWidthInfo.offsetNext)) {
                                pageDiff = (resizeWidthInfo.offsetNext);
                            }

                            const newOffsetPrev = (resizeWidthInfo.offsetPrev) + pageDiff;
                            const newOffsetNext = (resizeWidthInfo.offsetNext) - pageDiff;

                            resizeWidthInfo.parent.parentElement.style.display = "flex";
                            const parentWidth = resizeWidthInfo.parent.parentElement.getBoundingClientRect().width;

                            $(resizeWidthInfo.prevElem).css('width', `calc(${((newOffsetPrev / parentWidth * 100))}%)`);
                            $(resizeWidthInfo.nextElem).css('width', `calc(${((newOffsetNext / parentWidth) * 100)}%)`);

                            if (resizeWidthInfo?.nextElem?.dataset?.name && resizeWidthInfo?.prevElem?.dataset?.name) {
                                $scope.sizes[resizeWidthInfo.prevElem.dataset.name] = ((newOffsetPrev / parentWidth * 100));
                                $scope.sizes[resizeWidthInfo.nextElem.dataset.name] = ((newOffsetNext / parentWidth) * 100);
                            }
                        }

                        function preventDefaultEvents(event) {
                            event.preventDefault();
                        }

                        function resizeBoxes(event) {
                            event.preventDefault();
                            event.stopPropagation();

                            if (resizingHeight === true) resizeHeight(event)
                            else if (resizingWidth === true) resizeWidth(event);
                        }

                        function stopResizing(event) {
                            if ((resizingHeight === true || resizingWidth === true) && angular.isDefined(event)) {
                                resizingHeight = false;
                                resizingWidth = false;

                                for (const element of elems) {
                                    element.style.pointerEvents = 'initial';
                                    element.style.touchAction = 'initial';
                                }

                                document.body.style.cursor = 'initial';

                                if ($scope.data?.rememberId && Object.keys($scope.sizes).length > 0) {
                                    setUserVariable();
                                }

                                if ($scope.onResize && angular.isFunction($scope.onResize)) {
                                    $scope.onResize();
                                }
                            }
                        }

                        // #endregion EVENTHANDLING

                        $('.im-ellipsis-h').on('pointerdown', startHeightResize);
                        $('.im-ellipsis-v').on('pointerdown', startWidthResize);
                        document.addEventListener('pointermove', resizeBoxes);
                        document.addEventListener('pointerup', stopResizing);
                        document.addEventListener('pointercancel', preventDefaultEvents);
                        document.addEventListener('scroll', preventDefaultEvents);


                        $scope.$on('$destroy', function () {
                            $('.im-ellipsis-h').unbind('pointerdown');
                            $('.im-ellipsis-v').unbind('pointerdown');
                            document.removeEventListener('pointermove', resizeBoxes);
                            document.removeEventListener('pointerup', stopResizing);
                            document.removeEventListener('pointercancel', preventDefaultEvents);
                            document.removeEventListener('scroll', preventDefaultEvents);
                        });
                    });
                }
            };

            return directive;
        }]);
})();
