(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockSearch', {
        templateUrl: 'views/components/views/stockSearch/stockSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'stateService', 'modalService', 'stockSearchService', 'logisticService', function ($stateParams, $q, utilityService, stateService, modalService, stockSearchService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let functionName = $stateParams.functionname;
            
            vm.model = {
                functionName: functionName,
                barcodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.barcode = '' },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('barcode') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => findItem() }
                ],
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                bgStockPredefinedSelection: {
                    selected: 'PREDEFINED',
                    buttons: [
                        { id: 'PREDEFINED', label: 'stocksearch_predefined', color: 'primary', onClick: () => vm.model.bgStockPredefinedSelection.selected = 'PREDEFINED' },
                        { id: 'EXTRA', label: 'stocksearch_extra', color: 'primary', onClick: () => vm.model.bgStockPredefinedSelection.selected = 'EXTRA' }
                    ]
                },
                bgStockPickSelection: {
                    selected: 'NOTPICKED',
                    buttons: [
                        { id: 'NOTPICKED', label: 'stocksearch_not_picked', color: 'primary', onClick: () => vm.model.bgStockPickSelection.selected = 'NOTPICKED' },
                        { id: 'PICKED', label: 'stocksearch_picked', color: 'primary', onClick: () => vm.model.bgStockPickSelection.selected = 'PICKED' }
                    ]
                },
                setting: {
                    filtervalue: ''
                },
                selectListStockPlaces: [],
                itemsListComponents: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadSetting = function () {
                const deferred = $q.defer();

                stockSearchService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => {
                        loadComponents();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            let loadComponents = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_pofinish_keyno) !== true) return;
                
                vm.model.itemsListComponents = [];
                
                return stockSearchService.listComponents({
                    p2_pofinish_keyno: vm.model.setting.p2_pofinish_keyno,
                    who: 'COMP',
                    predef: vm.model.bgStockPredefinedSelection.selected,
                    pick: vm.model.bgStockPickSelection.selected
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListComponents);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region SCAN & SEARCH FUNCTION

            let findItem = function () {
                if (utilityService.validateParmsValue(vm.model.setting.barcode) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                return stockSearchService.searchItem({
                    functionname: functionName,
                    barcode: vm.model.setting.barcode,
                    stockplace_id: vm.model.setting.stockplace_id
                }).then(function (response) {
                    vm.model.setting.barcode = '';
                    stateService.go(response[0].webpagename, response[0].parms);
                });
            };

            vm.searchStock = function () {
                findItem();
            };

            // #endregion SCAN & SEARCH FUNCTION

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (id) {
                modalService.showCalculator(vm.model.setting[id]).then(function (value) {
                    vm.model.setting[id] = value;

                    findItem();
                });
            };

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockPredefinedSelection = function (value) {
                vm.model.bgStockPredefinedSelection.selected = value;

                loadComponents();
            };

            vm.selectBgStockPickSelection = function (value) {
                vm.model.bgStockPickSelection.selected = value;

                loadComponents();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        findItem();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
