(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2UpdateTableEdit', {
        templateUrl: 'views/components/views/p2UpdateTableEdit/p2UpdateTableEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2UpdateTableEditService', function ($stateParams, stateService, p2UpdateTableEditService) {
            let vm = this;
            let p2UpdateTableKeyno = $stateParams.p2_updatetable_keyno;

            vm.model = {
                edit: {
                    sync_data: '1'
                },
                lockedSave: false
            };

            let loadEdit = function () {
                p2UpdateTableEditService.getUpdateTable({ p2_updatetable_keyno: p2UpdateTableKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2UpdateTableEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
