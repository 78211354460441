(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("choicePossRuleService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            selectProductList: [],
            loadChoicePossRuleGet: function (choicepossrule_keyno) {
                return p2DataTaskService.call(1176, {
                    choicepossrule_keyno: choicepossrule_keyno
                });
            },
            loadProductChoiceList: function () {
                return p2DataTaskService.call(1159, {});
            },
            loadModelSelectList: function (add_all, show_inactive) {
                return p2DataTaskService.call(1174, {
                    add_all: add_all,
                    show_inactive: show_inactive
                });
            },
            loadModelIdChanged: function (getChoicePossRules) {
                return p2DataTaskService.call(1190, getChoicePossRules);
            },
            loadChoiceProdIdChanged: function (choice_prod_id) {
                return p2DataTaskService.call(1185, {
                    choice_prod_id: choice_prod_id
                });
            },
            loadFurnitureFabricsSelectList: function (add_all, choice_prod_id) {
                return p2DataTaskService.call(1173, {
                    add_all: add_all,
                    choice_prod_id: choice_prod_id
                });
            },
            loadChoicePossRulesProductList: function (getChoicePossRule) {
                return p2DataTaskService.call(1191, getChoicePossRule);
            },
            loadChoicePossRulesChoosedList: function (getChoicePossRule) {
                return p2DataTaskService.call(1175, getChoicePossRule);
            },
            loadProdKeynoGet: function (prod_id) {
                return p2DataTaskService.call(1192, {
                    prod_id: prod_id
                });
            },
            search: function (prod_name, webpage_name, parm1, parm2, supplier_no, search_only_this_supplier, businessco_no, onlyonstock, model_id) {
                var deferred = $q.defer();
                console.log('prod_name :>> ', prod_name);

                $ihttp.post({
                    method: 78, parameters: {
                        prod_name: prod_name,
                        webpage_name: webpage_name,
                        parm1: parm1,
                        parm2: parm2,
                        supplier_no: supplier_no,
                        search_only_this_supplier: search_only_this_supplier,
                        businessco_no: businessco_no,
                        onlyonstock: onlyonstock,
                        model_id: model_id
                    }
                }).then(function (data) {
                    console.dir(data);
                    angular.copy(data, service.selectProductList);

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            save: function (getChoicePossRule) {
                return p2DataTaskService.call(1177, getChoicePossRule);
            },
            delete: function (getChoicePossRule) {
                return p2DataTaskService.call(1186, getChoicePossRule);
            }
        };

        return service;
    }]);
})();