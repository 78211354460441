(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicDetailsService", [function() {
        var service = {
        }

        return service;
    }]);
})();
