(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactMembers', {
        templateUrl: 'views/components/views/custactMembers/custactMembers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custactMembersService', function ($stateParams, stateService, custactMembersService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;

            vm.model = {
                itemsListMembers: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadMembers = function () {
                custactMembersService.listMembers({ custact_no: custactNo }).then(function (list) {
                    angular.copy(list, vm.model.itemsListMembers);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectportaluser':
                        go({
                            webpage: stateService.getCurrentName(),
                            arg1: custactNo
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMembers();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
