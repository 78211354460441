(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('projects', {
        templateUrl: 'views/components/views/projects/projects.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$window', 'stateService', 'utilityService', 'projectsService', 'ttGridFactory', 'rememberService', function ($stateParams, $q, $window, stateService, utilityService, projectsService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                mobileSize: window.matchMedia('(min-width: 992px)'),
                bgProjectsActiveStatus: {
                    selected: 'ACTIVE',
                    buttons: [
                        { id: 'ACTIVE', label: 'bg_projects_activestatus_active', color: 'primary', onClick: () => setProjectsActiveStatusView('ACTIVE') },
                        { id: 'NOTACTIVE', label: 'bg_projects_activestatus_notactive', color: 'primary', onClick: () => setProjectsActiveStatusView('NOTACTIVE') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => onSearch('searchtext') }
                ],
                setting: {},
                itemsListProjects: [],
                gridReady: false,
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_projects_grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setProjectsActiveStatusView = function (id) {
                vm.model.bgProjectsActiveStatus.selected = vm.model.setting.bg_projects_activestatus = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                projectsService.getProjectSettings({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    setProjectsActiveStatusView(vm.model.setting.bg_projects_activestatus);

                    loadProjects();
                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 2748,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadProjects = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bg_projects_activestatus) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                vm.model.itemsListProjects = [];

                return projectsService.listProjects(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListProjects);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'searchtext':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '/' + argType + '/' + argValue + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP FUNCTION

            vm.selectBgProjectsActiveStatus = function (value) {
                vm.model.bgProjectsActiveStatus.selected = value;
                vm.model.setting.bg_projects_activestatus = vm.model.bgProjectsActiveStatus.selected;

                if (vm.model.mobileSize.matches === true) {
                    vm.grid.gridfunc.read();
                } else {
                    loadProjects();
                }
            };

            // #region BUTTON GROUP FUNCTION

            // #region BUTTON LIST SINGLE FUNCTION

            vm.searchItems = function () {
                rememberFunc('searchtext');

                if (vm.model.mobileSize.matches === true) {
                    vm.grid.gridfunc.read();
                } else {
                    loadProjects();
                }
            };

            // #endregion BUTTON LIST SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'project':
                        go({ project_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.mobileSize.matches === true) {
                                vm.grid.gridfunc.rebind();
                            } else {
                                loadProjects();
                            }
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            let onSearch = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.searchItems();
                        break;
                    default:
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');

                            if (vm.model.mobileSize.matches === true) {
                                vm.grid.gridfunc.rebind();
                            } else {
                                loadProjects();
                            }
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region ON INIT FUNCTION

            let onWindowResize = function () {
                if (vm.model.mobileSize.matches === true) {
                    loadGrid();
                } else {
                    loadProjects();
                }
            };

            let onDestroy = [];

            vm.$onInit = function () {
                loadSetting();
                $window.addEventListener('resize', onWindowResize);

                // JLR 20240927 - no need to call this here.
                //onWindowResize();
            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
