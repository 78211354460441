(function () {
    'use strict';

    angular.module("imApp").factory("capTaskResourceService", ['$ihttp', function ($ihttp) {
        var service = {
            getCapTaskResource: function (capTaskResourceKeyNo) {
                return $ihttp.post({
                    method: 2067,
                    parameters: {
                        captaskresource_keyno: capTaskResourceKeyNo
                    }
                });
            },
            newStatus: function (parms) {
                return $ihttp.post({
                    method: 2068,
                    parameters: parms
                });
            }
        };

        return service;
    }]);
})();