(function () {
    'use strict';

    angular.module("imApp").factory("stockPlanningPoService", ['$ihttp', function ($ihttp) {
        var encoder = function (data, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                if (format === 'char' || format === 'varchar' || format === 'long varchar' || format === 'string') {
                    returnData = btoa(data);
                } else {
                    returnData = data;
                }
            }
            return returnData;
        };

        var service = {
            getStockPlanningPo: function (parms) {
                return $ihttp.post({
                    method: 2111,
                    parameters: parms || {}
                });
            },
            listProdBeoMethods: function (parms) {
                return $ihttp.post({
                    method: 997,
                    parameters: parms || {}
                });
            },
            listOperations: function (parms) { // add_all
                return $ihttp.post({
                    method: 2112,
                    parameters: parms || {}
                });
            },
            createProductionOrder: function (dataItem, format) {
                var postData = angular.copy(dataItem);

                angular.forEach(postData, function (data, key) {
                    postData[key] = encoder(data, key, format);
                });

                return $ihttp.post({
                    method: 2114,
                    parameters: postData
                });
            }
        };

        return service;
    }]);
})();