(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('myPosition', {
        templateUrl: 'views/components/views/myPosition/myPosition.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'myPositionService', 'watcherFactory', function($timeout, myPositionService, watcherFactory) {
            
            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            let vm = this;
            var watcher = watcherFactory.$create(vm);

            vm.model = {
                bgMyPosition: {
                    selected: 'MAP',
                    buttons: [
                        { id: 'MAP', label: 'bg_my_pos_map', color: 'primary', onClick: () => vm.model.bgMyPosition.selected = 'MAP' },
                        { id: 'DETAILS', label: 'bg_my_pos_details', color: 'primary', onClick: () => vm.model.bgMyPosition.selected = 'DETAILS' }
                    ]
                },
                urlStringButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-new-window', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.setting.urlstring) }
                ],
                setting: {},
                center: [],
                autoTrack: '0',
                autoCenter: '1',
                fullscreen: false
            };
    
            // ################################
            // GET NAVIGATION POSITION FUNCTION
            // ################################
    
            vm.getMyPosition = function () {
                navigator.geolocation.getCurrentPosition(function (data) {
                    vm.model.setting.latitude = data.coords.latitude;
                    vm.model.setting.longitude = data.coords.longitude;
                    vm.model.setting.altitude = data.coords.altitude;
                    vm.model.setting.accuracy = data.coords.accuracy;
                    
                    vm.model.setting.urlstring = 'https://sar.hovedredningssentralen.no/hrs/kart.html?lat=' + vm.model.setting.latitude + '&lng=' + vm.model.setting.longitude + '&zl=20&pt=pos';
                    
                    if (vm.model.autoCenter === '1') {
                        vm.model.center.length = 0;
                        vm.model.center.push(vm.model.setting.latitude);
                        vm.model.center.push(vm.model.setting.longitude);
                    }
                    
                    //if (vm.model.autoTrack === '1') autoTrack();
                });
            };
            
            var trackerTimer = null;
            
            var autoTrack = function () {
                if (angular.isDefined(trackerTimer)) {
                    $timeout.cancel(trackerTimer);
                }
                
                vm.getMyPosition();
                
                trackerTimer = $timeout(function () {
                    autoTrack();
                }, 5000);
            };
            
            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            let loadSetting = function () {
                myPositionService.getMyPosition().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    vm.getMyPosition();
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgMyPosition = function (value) {
                vm.model.bgMyPosition.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ##########################
            // OPEN WEB LINK MAP FUNCTION
            // ##########################
            
            vm.openWebLinkMyPos = function () {
                window.open(vm.model.setting.urlstring);
            };
            
            // ##########################
            // WATCH
            // ##########################
            
            watcher.$watch(function () {
                return vm.model.autoTrack;
            }, function (newValue, oldValue) {
                if (angular.isUndefined(vm.model.autoTrack) || angular.isUndefined(newValue)) return;
                
                if (vm.model.autoTrack === '1') {
                    autoTrack();
                } else {
                    if (angular.isDefined(trackerTimer) || vm.model.autoTrack !== '1') {
                        $timeout.cancel(trackerTimer);
                    }
                }
            });


            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
