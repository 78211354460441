import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Style } from '@app/core/services/core-component.service';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';

@Component({
    selector: 'tt-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent extends FormFieldBaseComponent {
    /**
     * The current value for this progress bar, starts at 0, should not be greater than the max.
     */
    @Input()
    get ttModel(): number {
        return this._model;
    }
    set ttModel(value: NumberInput) {
        this._model = coerceNumberProperty(value);
    }
    private _model = 0;

    /**
     * The max value for this progress bar, default is 100.
     */
    @Input()
    get ttMax(): number {
        return this._max;
    }
    set ttMax(value: NumberInput) {
        this._max = coerceNumberProperty(value);
    }
    private _max = 100;

    /**
     * The status of the progressbar.
     */
    @Input()
    public ttStatus?: 'finish' | 'invalid' | 'active' = 'active';

    /**
     * Whether this progress bar should be indeterminate (always spinning until finished).
     */
    @Input()
    get ttIndeterminate(): boolean {
        return this._indeterminate;
    }
    set ttIndeterminate(value: BooleanInput) {
        this._indeterminate = coerceBooleanProperty(value);
    }
    private _indeterminate = false;

    /**
     * Ids of elements in the component.
     */
    public id = {
        progress: crypto.randomUUID(),
    };

    public override style: Style = {
        bar: {},
    };

    /**
     * Updates the completion bar of the progress bar.
     */
    private updateProgressBar() {
        let completed = this.ttModel / this.ttMax;

        if (completed > 1) {
            completed = 1;
        } else if (completed < 0) {
            completed = 0;
        }

        this.style['bar'].transform = `scaleX(${completed})`;
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.['ttModel']) {
            this.ttModel = changes?.['ttModel']?.currentValue;
            this.updateProgressBar();
        }

        if (changes?.['ttMax']) {
            this.ttMax = changes?.['ttMax']?.currentValue;
            this.updateProgressBar();
        }

        if (changes?.['ttIndeterminate']) {
            this.ttIndeterminate = changes?.['ttIndeterminate']?.currentValue;
        }

        if (changes?.['ttStatus']?.currentValue) {
            if (this.ttStatus === 'invalid') {
                this.ttInvalid = true;
            } else {
                this.ttInvalid = false;
            }
        }

        if (changes?.['ttInvalid']?.currentValue) {
            if (this.ttInvalid === true) {
                this.ttStatus = 'invalid';
            }
        }
    }
}
