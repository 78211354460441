(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('csvExportArg', {
        templateUrl: 'views/components/views/csvExportArg/csvExportArg.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'base64', 'utilityService', 'translateService', 'modalService', 'rememberService', 'csvExportArgService', 'logisticService', function ($stateParams, base64, utilityService, translateService, modalService, rememberService, csvExportArgService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let p2ExportProcKeyno = $stateParams.p2_exportproc_keyno;

            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                searchtext: '',
                supplier_no: '',
                project_keyno: '',
                cust_no: '',
                labour_no: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                companyButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('businessco_no') }
                ],
                searchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                setting: {},
                selectListBusinessCompanies: [],
                selectListStockPlaces: [],
                executing: false
            };

            vm.options = {
                keynoInput: {
                    alwaysPositive: true,
                    decimals: 0
                }
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS
    
            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (list) {
                    angular.copy(list, vm.model.selectListBusinessCompanies);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({
                    businessco_no: vm.model.setting.businessco_no,
                    add_all: '1'
                }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_csvexport' + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                    case 'supplier_no':
                    case 'project_keyno':
                    case 'cust_no':
                    case 'labour_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (keyno) {
                switch (keyno) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = '99999';

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region BUTTON EXECUTE CSV PROCEDURE FUNCTION CALL

            vm.executeChanges = function () {
                vm.model.executing = true;

                csvExportArgService.executeCsv({ p2_exportproc_keyno: vm.model.setting.p2_exportproc_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.executing = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.executing = false;

                        let elem = document.getElementById('formCsvExportArgLink');
                        let text = base64.urldecode(response[0].filecontent);

                        let txtData = new Blob([text], { type: 'text/txt' });
                        let txtUrl = URL.createObjectURL(txtData);

                        elem.setAttribute('href', txtUrl);
                        elem.setAttribute('download', response[0].filename);
                        elem.click();
                    }
                });
            };

            // #endregion BUTTON EXECUTE CSV PROCEDURE FUNCTION CALL

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc('searchtext');
                        break;
                    case 'supplier_no':
                    case 'project_keyno':
                    case 'cust_no':
                    case 'labour_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        if (vm.model.setting[id] > '' || vm.model.setting[id] > 0) rememberFunc(id);
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    case 'supplier_no':
                    case 'project_keyno':
                    case 'cust_no':
                    case 'labour_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        if (vm.model.setting[id] === '' || vm.model.setting[id] === 0) rememberFunc(id);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval === -1 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');
            }

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ANGULAR LIFECYCLE

            vm.$onInit = async function () {
                let info = await csvExportArgService.getSetting({
                    argtype: argType,
                    argvalue: argValue,
                    p2_exportproc_keyno: p2ExportProcKeyno
                });

                vm.model.setting = angular.copy(info[0]);

                loadCompanies();
    
                await Promise.all([loadStockPlaces()]);
            }

            // #endregion ANGULAR LIFECYCLE
        }]
    });
})();
