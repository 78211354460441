// JLR 20230124
(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttTextEditor', {
        templateUrl: 'views/components/directives/ttTextEditor/ttTextEditor.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttSublabel: '@',
            ttReadonly: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttItemId: '@',
            ttRequired: '@',
            ttReady: '=',
            ttStyle: '<',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttClass: '=',
        },
        controller: ['$window', '$element', '$timeout', 'layoutService', 'eventService', 'translateService', 'utilityService', 'sirvImageService', 'ttDirectivesService', 'sirvService', function ($window, $element, $timeout, layoutService, eventService, translateService, us, sirvImageService, ttDirectivesService, sirvService) {
            let vm = this;

            vm.readonly = false;
            vm.required = false;
            vm.ttReady = true;

            let onDestroy = [];
            let wait = 0;
            let sirvLinks = [];
            let baseLinks = [];

            vm.id = {
                textarea: uuid(),
            };

            vm.style = {
                base: {},
                label: {},
                sublabel: {},
                textarea: {},
                div: {},
            };

            vm.class = {
                container: 'tt-input__container',
                base: 'tt-input__base ',
                label: '',
                //label: 'tt-input__label',
                sublabel: '',
                input: 'tt-input__group'
            };

            vm.translations = {
                ttLabel: ''
            };


            let setClasses = (labelAlwaysOnTop) => {
                vm.class.base += ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

                //console.dir(vm.class.base);
                //    console.dir(labelAlwaysOnTop);
                //if (labelAlwaysOnTop === false) {

                //    vm.class = ttDirectivesService.setClasses({ classes: vm.class, ttClasses: vm.ttClass });
                //    console.dir(vm.class);
                //}
                /* vm.class.base = 'col-xs-12';
                 vm.class.label = vm.ttClass.label?.length > 0 ? vm.ttClass.label : '';
                 vm.class.input = vm.ttClass.input?.length > 0 ? vm.ttClass.input : '';*/
            }

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'textarea' }), vm.style);

            /**
             * Checks whether the provided url contains correct info to be a image encoded url.
             * 
             * @param {String} url the image url to check whether is base 64 encoded.
             * @return true if the image url is base64 encoded, false if not.
             */
            function isBase64EncodedImage(url) {
                return url.includes('data:image/') && url.includes('base64');
            }

            /**
             * Inserts the provided insertion string to the original text at the given start and
             * end index.
             * 
             * @param {String} origin the original text where text is being replaced.
             * @param {Number} startIndex the index of where to start the replacing.
             * @param {Number} endIndex the index of where to end the replacing.
             * @param {String} insertion the new text to replace between the indices.
             * @return a new string where text has been replaced.
             */
            function replaceBetween(origin, startIndex, endIndex, insertion) {
                return origin.substring(0, startIndex) + insertion + origin.substring(endIndex);
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');

                eventService.broadcast('elastic:adjust');
            };

            function replaceBase64Links(htmlString) {
                if (angular.isDefined(htmlString) && sirvLinks.length > 0) {

                    for (let i = 0; i < baseLinks.length; i++) {
                        htmlString = replaceBetween(htmlString, htmlString.indexOf(baseLinks[i]), htmlString.indexOf(baseLinks[i]) + baseLinks[i].length, sirvLinks[i]);
                    }

                    sirvLinks = [];
                    baseLinks = [];
                }

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: htmlString, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            }

            vm.onModelChange = function (event) {
                let value = event.sender.element[0].value;

                if (vm.ttReady === true) {
                    wait = 0;
                    replaceBase64Links(value);
                } else {
                    $timeout(() => {
                        replaceBase64Links(value);
                        wait = 0;
                    }, wait);
                }
            };

            vm.setFocus = function () {
                let element = $element.find('#' + vm.id.textarea)[0];

                if (angular.isUndefined(element))
                    return;

                element.focus();
            };

            vm.selectText = function () {
                vm.setFocus();

                $timeout(function () {
                    let element = $element.find('#' + vm.id.textarea)[0];

                    element.setSelectionRange(0, element.value.length);
                }, 10);
            };


            vm.kTools = function () {
                return ['bold', 'italic', 'underline', 'strikethrough', 'insertUnorderedList', 'insertOrderedList', 'indent', 'outdent', 'fontSize', { name: 'foreColor', palette: 'basic' }, { name: 'backColor', palette: 'basic' }, 'createLink', 'unlink', 'insertImage', 'viewHtml', "tableWizard",
                    "tableProperties",
                    "tableCellProperties",
                    "createTable",
                    "addRowAbove",
                    "addRowBelow",
                    "addColumnLeft",
                    "addColumnRight",
                    "deleteRow",
                    "deleteColumn",
                    "mergeCellsHorizontally",
                    "mergeCellsVertically",
                    "splitCellHorizontally",
                    "splitCellVertically",
                    "tableAlignLeft",
                    "tableAlignCenter",
                    "tableAlignRight"];
            };

            vm.onPaste = function (event) {
                sirvService.checkConnection().then((connection) => {
                    if (connection && event.html.includes('<img src=') && event.html.includes('base64')) {
                        let images = [];
                        vm.ttReady = false;

                        event.html.split('"').forEach((item) => {
                            if (isBase64EncodedImage(item)) {
                                images.push(item);
                            }
                        });

                        angular.forEach(images, (image, index) => {
                            fetch(image).then((response) => {
                                response.blob().then((result) => {
                                    const file = new File([result], `clipboard.${result.type.split('/')[1]}`, { type: result.type });
                                    wait = 1500;

                                    sirvService.uploadImage(file, 'html').then((data) => {
                                        baseLinks.push(`<img src="${image}" />`);
                                        sirvLinks.push(`<img src="${data.imageUrl}?w=800&scale.option=noup" style="max-width: calc(100vw - 50px)" />`);

                                        if ((images.length - 1) === images.indexOf(image)) {
                                            vm.ttReady = true;
                                        }
                                    });
                                }, () => {
                                    vm.ttReady = true;
                                });
                            });
                        });
                    }
                });
            }

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop);
            });

            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttReadonly) && us.isStringValue(changes.ttReadonly.currentValue, true)) {
                    vm.readonly = vm.ttReadonly.toLowerCase() === 'true';
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
