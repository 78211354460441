(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custfacts', {
        templateUrl: 'views/components/views/custfacts/custfacts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'custFactsService', function ($stateParams, $q, stateService, modalService, translateService, custFactsService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let custNo = $stateParams.cust_no;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                setting: {},
                inputListCustomerFacts: [],
                lockedSave: false
            };

            vm.options = {
                number: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSettings = function () {
                let deferred = $q.defer();

                custFactsService.getCustomerFacts({ cust_no: custNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadCustomerFacts()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCustomerFacts = function () {
                return custFactsService.listCustomerFacts(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.inputListCustomerFacts);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                var mergeObj = vm.model.setting;
                mergeObj.custfacts = vm.model.inputListCustomerFacts;

                custFactsService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSettings();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
