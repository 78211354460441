(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ediSources', {
        templateUrl: 'views/components/views/ediSources/ediSources.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ediSourcesService', function(stateService, ediSourcesService) {
            var vm = this;
            
            vm.model = {
    			p2EdiSourceList: {
    				records: []
    			}
            };
            
            ediSourcesService.loadP2EdiSourceList().then(function (data) {
                angular.copy(data, vm.model.p2EdiSourceList.records);
            });
            
            vm.goToEdiSource = function () {
                stateService.go('edisource', {
                    edi_source_keyno: '0'
                });
            };
        }]
    });
})();