(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2StatSource', {
        templateUrl: 'views/components/views/p2StatSource/p2StatSource.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'p2StatSourceService', function (stateService, p2StatSourceService) {
            var vm = this;
            
            vm.model = {
                itemsListStatSources: []
            };
            
            p2StatSourceService.listStatSources().then(function (result) {
                angular.copy(result, vm.model.itemsListStatSources);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2statsourceedit':
                        stateService.go(state, {
                            p2_statsource_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();