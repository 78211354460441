(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('genericTerminalScanA', {
        templateUrl: 'views/components/generics/genericTerminalScanA/genericTerminalScanA.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            route: '<'
        },
        controller: ['$timeout', 'genericTerminalScanAService', 'stateService', function ($timeout, genericTerminalScanAService, stateService) {
            var vm = this;

            // BJS 20240129 - Added check if function exists because this failes in chrome on iphone.
            if (angular.isDefined(navigator) && angular.isDefined(navigator.virtualKeyboard) && angular.isFunction(navigator.virtualKeyboard.hide)) {
                navigator.virtualKeyboard.hide();
            }

            vm.locked = true;
            vm.model = {
                changes: {
                    number: 0,
                    ttNumber: 0,
                    ttNumber2: 0,
                    ttNumber3: 0
                }
            };
            vm.terminal = {
                scan: {
                    show: true,
                    value: '',
                    focus: true,
                    setFocus: false
                },
                inputA: {
                    show: false,
                    value: ''
                },
                inputB: {
                    show: false,
                    value: ''
                },
                number: {
                    show: false,
                    value: 0,
                    show1: false,
                    show10: false
                },
                ttNumber: {
                    show: false,
                    value: 0,
                    options: {
                        decimals: 2
                    },
                    placeholder: '',
                    label: ''
                },
                ttNumber2: {
                    show: false,
                    value: 0,
                    options: {
                        decimals: 2
                    },
                    placeholder: '',
                    label: ''
                },
                ttNumber3: {
                    show: false,
                    value: 0,
                    options: {
                        decimals: 2
                    },
                    placeholder: '',
                    label: ''
                },
                header: {
                    show: false,
                    value: '',
                    fontSize: 0
                },
                detailsA: {
                    show: false,
                    value: ''
                },
                detailsB: {
                    show: false,
                    value: ''
                },
                detailsC: {
                    show: false,
                    value: ''
                },
                background: {
                    class: '',
                    timer: 0
                },
                list: {
                    show: false,
                    data: [],
                    item: {}
                },
                buttonA: {
                    show: false,
                    enabled: true,
                    text: {
                        value: '',
                        align: 'center'
                    },
                    class: '',
                    state: '',
                    parms: ''
                },
                buttonB: {
                    show: false,
                    enabled: true,
                    text: {
                        value: '',
                        align: 'center'
                    },
                    class: '',
                    state: '',
                    parms: ''
                },
                buttonC: {
                    show: false,
                    enabled: true,
                    text: {
                        value: '',
                        align: 'center'
                    },
                    class: '',
                    state: '',
                    parms: ''
                },
                buttonD: {
                    show: false,
                    enabled: true,
                    text: {
                        value: '',
                        align: 'center'
                    },
                    class: '',
                    state: '',
                    parms: ''
                },
                buttonE: {
                    show: false,
                    enabled: true,
                    text: {
                        value: '',
                        align: 'center'
                    },
                    class: '',
                    state: '',
                    parms: ''
                },
                buttonF: {
                    show: false,
                    enabled: true,
                    text: {
                        value: '',
                        align: 'center'
                    },
                    class: '',
                    state: '',
                    parms: ''
                },
                selectA: {
                    show: false,
                    enabled: true,
                    value: '',
                    data: [],
                    dataId: '',
                    dataName: '',
                    auto: true
                },
                selectB: {
                    show: false,
                    enabled: true,
                    value: '',
                    data: [],
                    dataId: '',
                    dataName: '',
                    auto: true
                },
                scanDelay: 500,
                sessionData: '',
                isError: false,
                errorMessage: ''
            };

            var updateNumber = function (value) {
                vm.terminal.number.value = parseFloat(vm.terminal.number.value) + value;
            }

            var updateTtNumber = function(value) {
                vm.terminal.ttNumber.value = parseFloat(vm.terminal.ttNumber.value) + value;
            }

            vm.numberButtons = [
                { id: 'm10', show: false, icon: 'glyphicon glyphicon-minus', text: "10", color: 'primary', onClick: { click: function () { updateNumber(-10); } } },
                { id: 'm1', show: false, icon: 'glyphicon glyphicon-minus', text: "1", color: 'primary', onClick: { click: function () { updateNumber(-1); } } },
                { id: 'p1', show: false, icon: 'glyphicon glyphicon-plus', text: "1", color: 'primary', onClick: { click: function () { updateNumber(1); } } },
                { id: 'p10', show: false, icon: 'glyphicon glyphicon-plus', text: "10", color: 'primary', onClick: { click: function () { updateNumber(10); } } }
            ];

            vm.ttNumberButtons = [
                { id: 'm10', show: false, icon: 'fa fa-minus-circle', text: "", color: 'primary', onClick: { click: function () { updateTtNumber(-10); } } },
                { id: 'm1', show: false, icon: 'fa fa-minus', text: "", color: 'primary', onClick: { click: function () { updateTtNumber(-1); } } },
                { id: 'p1', show: false, icon: 'fa fa-plus', text: "", color: 'primary', onClick: { click: function () { updateTtNumber(1); } } },
                { id: 'p10', show: false, icon: 'fa fa-plus-circle', text: "", color: 'primary', onClick: { click: function () { updateTtNumber(10); } } }
            ];

            var scanFunc = null;

            vm.setScanFunc = function (func) {
                scanFunc = func;
            };

            var setDefaultStyles = function (type, styleJson) {
                if (angular.isUndefined(styleJson) || styleJson === null || angular.isString(styleJson) !== true || styleJson.trim().length < 1) {
                    styleJson = '{}';
                }

                var style = angular.fromJson(styleJson);

                switch (type) {
                    case 'detailsA':
                    case 'detailsB':
                    case 'detailsC':
                        if (angular.isUndefined(style.textarea)) {
                            style.textarea = {};
                        }

                        if (angular.isUndefined(style.textarea.fontFamily)) {
                            style.textarea.fontFamily = '"Arial Monospaced MT Regular", monospace';
                        }

                        if (angular.isUndefined(style.textarea.padding)) {
                            style.textarea.padding = '5px';
                        }

                        if (angular.isUndefined(style.textarea.fontWeight)) {
                            style.textarea.fontWeight = '500';
                        }
                        break;
                }

                return angular.toJson(style);
            };

            var updateLayout = function (terminal) {
                terminal.number.value = parseFloat(terminal.number.value);
                terminal.ttNumber.value = parseFloat(terminal.ttNumber.value);
                terminal.ttNumber2.value = parseFloat(terminal.ttNumber2.value);
                terminal.ttNumber3.value = parseFloat(terminal.ttNumber3.value);

                terminal.detailsA.style = setDefaultStyles('detailsA', terminal.detailsA.style);
                terminal.detailsB.style = setDefaultStyles('detailsB', terminal.detailsB.style);
                terminal.detailsC.style = setDefaultStyles('detailsC', terminal.detailsC.style);

                angular.copy(terminal, vm.terminal);

                vm.locked = false;

                if (vm.terminal.background.timer > 0) {
                    $timeout(function () {
                        vm.terminal.background.class = '';
                    }, vm.terminal.background.timer);
                }

                if (vm.terminal.scan.show !== true || vm.terminal.number.show === true || vm.terminal.scan.focus !== true) {
                    scanFunc.unbindFocus();
                } else {
                    scanFunc.bindFocus();
                }

                if (vm.terminal.scan.focus === true || vm.terminal.scan.setFocus === true) {
                    if (scanFunc !== null && angular.isFunction(scanFunc.setFocus)) {
                        scanFunc.setFocus();
                    }
                }

                vm.numberButtons[0].show = vm.terminal.number.show10;
                vm.numberButtons[1].show = vm.terminal.number.show1;
                vm.numberButtons[2].show = vm.terminal.number.show1;
                vm.numberButtons[3].show = vm.terminal.number.show10;
                vm.ttNumberButtons[0].show = vm.terminal.ttNumber.show10;
                vm.ttNumberButtons[1].show = vm.terminal.ttNumber.show1;
                vm.ttNumberButtons[2].show = vm.terminal.ttNumber.show1;
                vm.ttNumberButtons[3].show = vm.terminal.ttNumber.show10;
                vm.ttNumber2Buttons[0].show = vm.terminal.ttNumber.show10;
                vm.ttNumber2Buttons[1].show = vm.terminal.ttNumber.show1;
                vm.ttNumber2Buttons[2].show = vm.terminal.ttNumber.show1;
                vm.ttNumber2Buttons[3].show = vm.terminal.ttNumber.show10;
                vm.ttNumber3Buttons[0].show = vm.terminal.ttNumber.show10;
                vm.ttNumber3Buttons[1].show = vm.terminal.ttNumber.show1;
                vm.ttNumber3Buttons[2].show = vm.terminal.ttNumber.show1;
                vm.ttNumber3Buttons[3].show = vm.terminal.ttNumber.show10;

                $rootScope.$broadcast('elastic:adjust');
            };

            vm.onList = function (item) {
                console.log('onList');
                console.dir(item);

                genericTerminalScanAService.list(vm.route, item, vm.terminal.sessionData).then(updateLayout);
            };

            vm.onSelect = function (type, value) {
                vm.terminal[type].value = value;

                if (vm.terminal[type].auto !== true) return;
                genericTerminalScanAService.select(vm.route, type, value, vm.terminal.sessionData).then(updateLayout);

            };

            vm.onScan = function (value) {
                // BJS 20220629 - For some reason model will not update if it
                //                is set to an empty string, setting to null instead.
                vm.terminal.scan.value = null;

                if (vm.locked) return; // RHE 20230914 - Added to prevent double-scanning

                vm.locked = true;

                genericTerminalScanAService.scan(vm.route, value, vm.terminal.number.value, vm.terminal.ttNumber.value, vm.terminal.sessionData).then(updateLayout);
            };

            vm.onNumber = function (value) {
                if (vm.terminal.number == value) return;
                vm.terminal.number.value = value;

                if (vm.terminal.number.auto !== true) return;

                if (Date.now() - vm.model.changes.number < vm.terminal.scanDelay) return;
                vm.model.changes.number = Date.now();

                vm.locked = true;

                genericTerminalScanAService.number(vm.route, 'number', vm.terminal.number.value, vm.terminal.sessionData).then(updateLayout);
            };

            vm.onttNumber = function (value, type) {
                if (vm.terminal[type].value == value) return;
                vm.terminal[type].value = value;

                if (vm.terminal[type].auto !== true) return;

                if (value === '0.00') return;

                if (Date.now() - vm.model.changes[type] < vm.terminal.scanDelay) return;
                vm.model.changes[type] = Date.now();

                vm.locked = true;

                genericTerminalScanAService.number(vm.route, type, value, vm.terminal.sessionData).then(updateLayout);
            }

            vm.onButton = async function (type, e) {
                vm.locked = true;

                try {
                    if (vm.terminal['button' + type].state) {
                        if (e.ctrlKey) {
                            stateService.newTab(vm.terminal['button' + type].state, vm.terminal['button' + type].parms);
                        } else {
                            stateService.go(vm.terminal['button' + type].state, vm.terminal['button' + type].parms);
                        }
                    } else {
                        const terminal = await genericTerminalScanAService.button(
                            vm.route,
                            type,
                            vm.terminal.scan.value,
                            vm.terminal.number.value,
                            vm.terminal.selectA.value,
                            vm.terminal.selectB.value,
                            vm.terminal.sessionData,
                            vm.terminal.inputA.value,
                            vm.terminal.inputB.value
                        )

                        updateLayout(terminal);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    vm.locked = false;
                }
            };

            vm.$onInit = function () {
                genericTerminalScanAService.init(vm.route).then(updateLayout);
            };

            vm.$onChanges = function () { };

            vm.$onDestroy = function () {
                navigator.virtualKeyboard.show();
            };
        }]
    });
})();
