(function () {
    'use strict';

    let module = angular.module("imApp");

	module.factory("custGrpService", ['$ihttp', function ($ihttp) {
		let service = {
            getCustomerGroup: function (parms) {
                return $ihttp.post({
                    method: 1009,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1010,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
