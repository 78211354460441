(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("externalApisService", ['$ihttp', function ($ihttp) {
        let service = {
            listExternalApis: function (parms) {
                return $ihttp.post({
                    method: 1438,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
