(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('timeCustomers', {
        templateUrl: 'views/components/views/timeCustomers/timeCustomers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['timeCustomersService', function (timeCustomersService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListTimeCustomers: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                timeCustomersService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadTimeCustomers = function () {
                timeCustomersService.listTimeCustomers({
                    cust_name: 'ALL',
                    project_keyno: '0',
                    ok: '0'
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListTimeCustomers);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadTimeCustomers();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
