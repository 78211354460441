(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customerPersons', {
        templateUrl: 'views/components/views/customerPersons/customerPersons.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'customerPersonsService', 'economyService', function ($stateParams, stateService, customerPersonsService, economyService) {
            let vm = this;
            let custNo = $stateParams.cust_no;
            
            vm.model = {
                setting: {},
                itemsListCustomerPersons: []
            };
            
            let loadSetting = function () {
                customerPersonsService.getCustomerPersons({ cust_no: custNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };

            let loadCustomerPersons = function () {
                economyService.listCustomerPersons({ cust_no: custNo }).then(function (result) {
                    angular.copy(result, vm.model.itemsListCustomerPersons);
                });
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'person':
                        stateService.go(state, {
                            cust_no: custNo,
                            pers_no: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
                loadCustomerPersons();
            };
        }]
    });
})();
