(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("picturesService", ['$q', '$ihttp', 'fileService', function ($q, $ihttp, fileService) {
        var service = {
            loaded: false,
            currentView: '0',
            filenameSyntax: {
                prefixtxt: '',
                postfixtxt: ''
            },
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 896,
                    parameters: parms || {}
                });
            },
            loadP2DocArcFileNameSyntaxGet: function (argtype, argvalue) {
                var deferred = $q.defer();

                fileService.getFileNameSyntax(argtype, argvalue).then(function (data) {
                    service.filenameSyntax = angular.copy(data);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
