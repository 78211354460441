import { Component } from '@angular/core';
import { IFloatingFilterAngularComp } from '@ag-grid-community/angular';
import { IFloatingFilterParams, ISimpleFilter } from '@ag-grid-community/core';

/**
 * Used for filtering checkboxes in floating filter.
 */
@Component({
    selector: 'tt-checkbox-floating-filter',
    templateUrl: './checkbox-floating-filter.component.html',
    styleUrls: ['./checkbox-floating-filter.component.css'],
})
export class CheckboxFloatingFilterComponent implements IFloatingFilterAngularComp {
    /**
     * The flating filter parameters provided.
     */
    params!: IFloatingFilterParams<ISimpleFilter>;

    /**
     * The value of the `true` filter value.
     */
    checkedFilter: boolean = false;

    /**
     * The value of the `false` filter value.
     */
    uncheckedFilter: boolean = false;

    /**
     * Sets the new filter values and applies the filter when the checked filter value is changed.
     *
     * @param event the new value of the checked filter value.
     */
    onCheckedFilerChanged(event: boolean): void {
        if (event === true) {
            this.checkedFilter = true;
            this.uncheckedFilter = false;
        }
        setTimeout(() => this.applyFilter());
    }

    /**
     * Sets the new values on the filter values and apply the filter when the unchecked filter value is changed.
     *
     * @param event the new value of the unchecked filter value.
     */
    onUncheckedFilerChanged(event: boolean): void {
        if (event === true) {
            this.checkedFilter = false;
            this.uncheckedFilter = true;
        }
        setTimeout(() => this.applyFilter());
    }

    /**
     * Applies the filter to the parent filter which renders the filter values in the grid.
     */
    applyFilter(): void {
        this.params.parentFilterInstance((instance) => {
            if (this.checkedFilter && !this.uncheckedFilter) {
                instance.onFloatingFilterChanged('equals', 'true');
            } else if (!this.checkedFilter && this.uncheckedFilter) {
                instance.onFloatingFilterChanged('equals', 'false');
            } else {
                instance.onFloatingFilterChanged(null, null);
                this.checkedFilter = false;
                this.uncheckedFilter = false;
            }
        });
    }

    // #region ag-implementation

    agInit(params: IFloatingFilterParams<ISimpleFilter>): void {
        this.params = params;
        const filtermodel = this.params.api.getFilterModel();
        this.checkedFilter = filtermodel['filter'] === 'true';
        this.uncheckedFilter = filtermodel['filter'] === 'false';
    }

    onParentModelChanged(parentModel: any) {
        if (!parentModel) {
            this.checkedFilter = false;
            this.uncheckedFilter = false;
        } else if (parentModel.filter === 'true') {
            this.checkedFilter = true;
            this.uncheckedFilter = false;
        } else if (parentModel.filter === 'false') {
            this.uncheckedFilter = true;
            this.checkedFilter = false;
        } else {
            this.checkedFilter = false;
            this.uncheckedFilter = false;
        }
    }

    // #endregion ag-implementation
}
