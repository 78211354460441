(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('tailorAccess', {
        templateUrl: 'views/components/views/tailorAccess/tailorAccess.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'tailorAccessService', function ($stateParams, $q, stateService, modalService, translateService, tailorAccessService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tailorAccessKeyNo = $stateParams.tailoraccess_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                tailorAccessKeyNo: tailorAccessKeyNo,
                editTailoredAccess: {},
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region SHOW DIALOGUE BOX FUNCTION

            let showMessage = function (type, title, message, label, cssClass) {
                let deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            };

            // #endregion SHOW DIALOGUE BOX FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                tailorAccessService.getTailorAccess({ tailoraccess_keyno: tailorAccessKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.editTailoredAccess);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                tailorAccessService.saveObj(vm.model.editTailoredAccess).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        showMessage('warning', translations.error, response[0].errormessage, translations.ok, 'btn-warning').then(function () {
                    		vm.model.lockedSave = false;
                    	});
                    } else {
                        vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    tailorAccessService.deleteObj(vm.model.editTailoredAccess).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            showMessage('warning', translations.error, response[0].errormessage, translations.ok, 'btn-warning').then(function () {
                                vm.model.lockedDelete = false;
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back('/tailoraccesses');
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
