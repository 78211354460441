(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('workGrpMenu', {
        templateUrl: 'views/components/views/workGrpMenu/workGrpMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'workGrpMenuService', function ($stateParams, stateService, workGrpMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let workGrpKeyNo = $stateParams.workgrp_keyno;

            vm.model = {
                menuCapacityGroup: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                workGrpMenuService.getMenu({ workgrp_keyno: workGrpKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.menuCapacityGroup);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'workgrp':
                        go({ workgrp_keyno: vm.model.menuCapacityGroup.workgrp_keyno });
                        break;
                    case 'shiftview':
                        go({ labour_no: 'W' + vm.model.menuCapacityGroup.workgrp_keyno });
                        break;
                    case 'equipmentlist':
                        go({
                            argtype: 'workgrp_keyno',
                            argvalue: vm.model.menuCapacityGroup.workgrp_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
