(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("orderHeadsService", ['$ihttp', function ($ihttp) {
        let service = {

            // Parameters:
            //  page
            //  pageSize
            //  argtype
            //  argvalue
            //  cust_no
            //  gb_orderstatus
            //  orderstatus_id
            //  gb_ordertype
            //  gb_orderdate
            //  filterval
            //  ordertype_id
            //  webpage
            //  businessco_no
            //  show_all_orders
            //  ordersearch
            //  gb_regby
            //  reg_by_portal_user_keyno
            //  date_fom
            //  date_tom
            //  show_dateselector

            getSetting: function (parms) {
                return $ihttp.post({
                    method: 526,
                    parameters: parms || {}
                });
            },
            listPortalUsers: function (parms) {
                return $ihttp.post({
                    method: 1046,
                    parameters: parms || {}
                });
            },
            listSellers: function (parms) {
                return $ihttp.post({
                    method: 628,
                    parameters: parms || {}
                });
            },
            listOrdersPaged: function (parms) {
                return $ihttp.post({
                    method: 1685,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
