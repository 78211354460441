(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactGantt', {
        templateUrl: 'views/components/views/custactGantt/custactGantt.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$ihttp', '$stateParams', function($ihttp, $stateParams) {
            var vm = this;

            vm.gantt_task = {
                custact_no: '',
                custact_no_belongto: '',
                datetime_start: '',
                datetime_end: '',
                note: '',
                ok: '',
                orderby: '',
                summary: '',
                complete_pst: '',
                records: []
            };
            
            //vm.gantt_resource = {
            //    item_id: '',
            //    item_name: '',
            //    orderby: '',
            //    color: '',
            //    records: []
            //};
            
            var parms_custact_gantt_list = {
                method: 116,
                parameters: {
                    custact_no: $stateParams.connectto_value,
                    retrievetype: 'child',
                    include_top: '1',
                    argtype: $stateParams.connectto,
                    argvalue: $stateParams.connectto_value
                }
            };
            
            $ihttp.post(parms_custact_gantt_list).then(function (data) {
                vm.gantt_task.records.length = 0;
    
                for (var i = 0; i < data.length; i++) {
                    vm.gantt_task.records.push(data[i]);
                }
            });
            
            //var parms_custact_gantt_res_list = {
            //    method: 138,
            //    parameters: {
            //    }
            //};
            
            //var testData = [{
            //    custact_no: "621917", item_parent: "0", datetime_start: "2016-04-12 00:00:00.000", 
            //    datetime_end: "2016-04-18 00:00:00.000", note: "Cashtrans", ok: "0", 
            //    orderby: "1", summary: "true", complete_pst: "0.00", custactlink_keyno: "0",
            //    extracolumns: [{
            //        coltype: "responsible",
            //        colwidth: "20",
            //        colvalue: "A",
            //        colheading: "SRS",
            //        colorder: "1",
            //        coltextcolor: "white",
            //        colbackcolor: "red",
            //        colalign: "center"
            //    }]
            //}];
            
            //for (var i = 0; i < testData.length; i++) {
            //    vm.gantt_task.records.push(testData[i]);
            //}
            
            //$ihttp.post(parms_custact_gantt_res_list).then(function (data) {
            //    vm.gantt_resource.records.length = 0;
    
            //    for (var i = 0; i < data.length; i++) {
            //        vm.gantt_resource.records.push(data[i]);
            //    }
            //});
            
            //vm.saveLabourEdit = function () {
            //    var parms_labour_save = {
            //        method: 106,
            //        parameters: {
            //            labour_no: vm.gantt_task.labour_no,
            //            labour_name: vm.gantt_task.labour_name,
            //            labour_streetaddress: vm.gantt_task.labour_streetaddress,
            //            labour_postaddress: vm.gantt_task.labour_postaddress,
            //            labour_postplace_id: vm.gantt_task.labour_postplace_id,
            //            postplace_name: vm.gantt_task.postplace_name,
            //            labour_mobilephone: vm.gantt_task.labour_mobilephone,
            //            labour_telephone: vm.labour_edit.labour_telephone,
            //            labour_startdate: vm.labour_edit.labour_startdate,
            //            labour_active: vm.labour_edit.labour_active,
            //            labour_enddate: vm.labour_edit.labour_enddate,
            //            labour_birthday: vm.labour_edit.labour_birthday,
            //            labour_jobtitle: vm.labour_edit.labour_jobtitle,
            //            department_id: vm.labour_edit.department_id,
            //            labour_email: vm.labour_edit.labour_email,
            //            labour_initial: vm.labour_edit.labour_initial,
            //            businessco_no: vm.labour_edit.businessco_no,
            //            password: vm.labour_edit.password
            //        }
            //    };
            //};
            
            vm.updateInfo = function () {
                var gantt_save = {
                    method: 133,
                    parameters: {
                        gantt_list: vm.gantt_task.records[0].custacts,
                    }
                };

                $ihttp.post(gantt_save);
            };
            
            //vm.selectInfo = function (item) {
            //    for (var i = 0; i < vm.gantt_task.records.length; i++) {
            //        if (vm.gantt_task.records[i].custact_no == item) {
            //            vm.gantt_task.note = vm.gantt_task.records[i].note;
            //        }
            //    }
            //}
            
            //$ihttp.post(parms_custact_gantt_list).then(function (data) {
            //    vm.gantt_task = angular.copy(data[0]);
            //});
        }]
    });
})();