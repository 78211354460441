(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2ExportProc', {
        templateUrl: 'views/components/views/p2ExportProc/p2ExportProc.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'ediDestinationsService', 'p2ExportProcService', 'tailorAccessesService', function ($stateParams, stateService, ediDestinationsService, p2ExportProcService, tailorAccessesService) {

            var vm = this;

            vm.model = {
                ediDestinationKeynoBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                btnListP2WebPageKeyNo: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                btnListTailorAccess: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' }
                ],
                p2ExportProcGet: {},
                p2ImportProcList: {
                    records: []
                },
                p2EdiDestinationList: {
                    records: []
                },
                p2EdiExportProcTableNameExtList: {
                    records: []
                },
                selectListWebPages: [],
                selectListTailorAccesses: [],
                selectListEdiTypes: [],
                selectListGridCount: [{item_id: '1', item_name: '1'}, {item_id: '2', item_name: '2'}, {item_id: '3', item_name: '3'}]
            };

            var loadP2ExportProcGet = function () {
                p2ExportProcService.genericFunction(892, {
                    p2_exportproc_keyno: $stateParams.p2_exportproc_keyno
                }).then(function (data) {
                    vm.model.p2ExportProcGet = angular.copy(data[0]);
                });
            };

            loadP2ExportProcGet();

            tailorAccessesService.listTailorAccesses().then(function (data) {
                angular.copy(data, vm.model.selectListTailorAccesses);
            });

            p2ExportProcService.listEdiTypes().then(function (data) {
                angular.copy(data, vm.model.selectListEdiTypes);
            });

            var loadP2ImportProcList = function () {
                p2ExportProcService.genericFunction(877, {}).then(function (data) {
                    angular.copy(data, vm.model.p2ImportProcList.records);
                });
            };

            loadP2ImportProcList();

            ediDestinationsService.loadP2EdiDestinationList().then(function (data) {
                angular.copy(data, vm.model.p2EdiDestinationList.records);
            });

            p2ExportProcService.loadP2EdiExportProcTableNameExtList().then(function (data) {
                angular.copy(data, vm.model.p2EdiExportProcTableNameExtList.records);
            });

            p2ExportProcService.loadExportProcWebPageList().then(function (data) {
                angular.copy(data, vm.model.selectListWebPages);
            });

            vm.ediDestinationKeynoBtnListFunc = function (item_func) {
                if (item_func == 'erase_item') {
                    vm.model.p2ExportProcGet.edi_destination_keyno = 0;
                }
            };

            vm.btnListFuncP2WebPageKeyNo = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.p2ExportProcGet.p2_webpage_keyno = '0';
                        break;
                    default:
                        break;
                }
            };

            vm.btnListFuncTailorAccess = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.p2ExportProcGet.tailoraccess_keyno = '0';
                        break;
                    case 'list_item':
                        vm.goToFunc('tailoraccesses');
                        break;
                    default:
                        break;
                }
            };

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'tailoraccesses':
                        go();
                        break;
                    default:
                        break;
                }
            };

            vm.saveP2ExportProc = function () {
                p2ExportProcService.genericFunction(893, vm.model.p2ExportProcGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    })
})();
