(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory4s', {
        templateUrl: 'views/components/views/textileModelCategory4s/textileModelCategory4s.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'textileModelCategory4sService', function (stateService, textileModelCategory4sService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                itemsListCategories4: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory4sService.listTextileModelCategory4s().then(function (result) {
                angular.copy(result, vm.model.itemsListCategories4);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'textilemodelcategory4':
                        stateService.go(state, {
                            textilemodelcategory4_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();