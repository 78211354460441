import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.css'],
})
export class TabsComponent extends ComponentBaseComponent implements OnChanges {
    /**
     * The id of the selected tab.
     */
    @Input()
    ttModel = '';

    /**
     * Event emitted when a new tab is selected, fires before onClick of a tab.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * List of the tabs to display.
     */
    @Input()
    ttTabs: Tab[] = [];

    constructor(private layoutService: LayoutService, private translateService: TranslateService) {
        super();

        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['tab'].fontSize = info.fontSizes.textSize;
            }
        });
    }

    /**
     * Inline styles for elements in the component.
     */
    style: Style = {
        tab: {},
    };

    /**
     * Handles on click event for a tab. `ttModelChange` will be emitted before onClick handler on tab runs.
     *
     * @param tab the tab to perform click event on.
     */
    onClick(tab: Tab): void {
        this.ttModel = tab.id;
        this.ttModelChange.emit(this.ttModel);

        if (tab && tab?.onClick && tab.onClick instanceof Function) {
            tab?.onClick();
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        const wordsToTranslate: string[] = [];
        const tabIndexes: { [key: string]: number } = {};

        if (changes?.['ttTabs']?.currentValue && changes['ttTabs'].currentValue instanceof Array) {
            if (this.ttModel === '' || !changes['ttTabs'].currentValue.map((tab: Tab) => tab.id).includes(this.ttModel)) {
                this.ttModel = changes['ttTabs'].currentValue[0].id;
                this.ttModelChange.emit(this.ttModel);
            }

            changes['ttTabs'].currentValue.forEach((tab: Tab, index: number) => {
                if (tab?.label) {
                    wordsToTranslate.push(tab.label);
                    tabIndexes[tab.label] = index;
                }
            });
        }

        if (wordsToTranslate.length > 0) {
            const translations = await this.translateService.translateBatch(wordsToTranslate);

            Object.keys(translations).forEach((key) => {
                if (tabIndexes?.[key] !== undefined) this.ttTabs[tabIndexes[key]].translation = translations[key];
            });
        }
    }
}

/**
 * Represents the configuration of a single tab.
 */
export interface Tab {
    /**  The id of the tab. */
    id: string;

    /**  the label of the tab (will be translated). */
    label?: string;

    /** Optional click handler of the tab, will fire after `ttModelChanged`. */
    onClick?: () => any;

    /** Whether the button is disabled, or hidden. */
    disabled?: boolean | 'hidden';

    /** Translation of the tab label. */
    translation?: string;

    /** Icon to display in the tab. */
    icon?: string;
}
