(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('lists', {
        templateUrl: 'views/components/views/lists/lists.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'listsService', function (stateService, listsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                itemsLists: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            listsService.lists().then(function (result) {
            	angular.copy(result, vm.model.itemsLists);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'list':
                        stateService.go(state, {
                            list_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();