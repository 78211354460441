(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('activityGrid', {
        templateUrl: 'views/components/views/activityGrid/activityGrid.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'activityGridService', 'logisticService', 'typeaheadService', 'rememberService', function ($stateParams, stateService, utilityService, activityGridService, logisticService, typeaheadService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            var variableNames = {
                businessco_no: '',
                ok: ''
            };

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                setting: {},
                selectListOks: [],
                selectListBusinessCompanies: [],
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //	console.log('optionfunc - CellClickHandler');
                //	console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //	console.log('optionfunc - OnCellClose');
                //	console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //	console.log('optionfunc - LookupCellEditor');
                //	console.dir(data);
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            activityGridService.getActivityGrid({
                argtype: vm.model.argType,
                argvalue: vm.model.argValue
            }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
			        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

		        if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
			        vm.grid.dataTask.loadData = {
				        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
			        };
		        }

		        vm.model.gridReady = true;
            });

            activityGridService.listOks().then(function (result) {
                angular.copy(result, vm.model.selectListOks);
            });

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListBusinessCompanies);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                var parms = {
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'C',
                    lastselect: 'SEARCH'
                };

                return typeaheadService.searchCustomer(parms);
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SET VALUE FUNCTION

            vm.setCustNo = function (item) {
                vm.model.setting.cust_no = angular.isUndefined(item) ? '0' : item.item_id;
                vm.model.setting.cust_name = angular.isUndefined(item) ? '' : item.item_name;

                if (utilityService.validateParmsValue(vm.model.setting.cust_no) === true) {
                    vm.grid.gridfunc.rebind();
                }
            };

		    // #endregion SET VALUE FUNCTION

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    case 'ok':
                        if (utilityService.validateParmsValue(vm.model.setting.ok) !== true) return;

                        variableValue = vm.model.setting.ok;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'ok':
                        vm.model.setting.ok = value;

                        if (utilityService.validateParmsValue(vm.model.setting.ok) !== true) return;

                        rememberFunc('ok');
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'custactedit1':
                        stateService.go('custactedit', {
                            custact_no: '0',
                            filterfield: '-',
                            filtervalue: '-'
                        });

                        break;
                    case 'custactedit2':
                        stateService.go('custactedit', {
                            custact_no: '0',
                            filterfield: 'cust_no',
                            filtervalue: vm.model.setting.cust_no
                        });

                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();
