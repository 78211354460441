import { ApplicationRef, ComponentRef, Directive, ElementRef, EnvironmentInjector, HostListener, Input, OnDestroy, OnInit, Renderer2, TemplateRef, createComponent } from '@angular/core';
import { PopoverComponent } from './popover.component';
import { LayoutService } from '@app/core/services/layout.service';
import { OutsideClickDirective } from '@app/core/directives/outside-click/outside-click.directive';

@Directive({
    selector: '[ttPopover]',
})
export class PopoverDirective implements OnInit, OnDestroy {
    @Input()
    public ttPopover?: TemplateRef<any>;

    private _popoverRef: ComponentRef<PopoverComponent> | null = null;

    private outsideClickDirective: null | OutsideClickDirective = null;

    ttTriggers: string = 'click';

    @HostListener('click')
    onClick() {
        if (this.ttTriggers.includes('click')) {
            if (this.open) {
                this.hidePopover();
            } else {
                this.showPopover();
            }
        }
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        if (this.ttTriggers.includes('mouseenter')) {
            this.showPopover();
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        if (this.ttTriggers.includes('mouseenter')) {
            this.hidePopover();
        }
    }

    @HostListener('document:scroll')
    onScroll() {
        this.calculatePosition();
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: PointerEvent) {
        this.outsideClickDirective?.onDocumentClick(event);
    }

    constructor(private elementRef: ElementRef, private appRef: ApplicationRef, private injector: EnvironmentInjector, private renderer: Renderer2, layout: LayoutService) {
        layout.layoutChanged.subscribe((_) => setTimeout(() => this.calculatePosition(), 400));
    }

    private createPopover() {
        if (!this._popoverRef) {
            this.calculateThisPopover = this.calculatePosition.bind(this);
            this._popoverRef = createComponent(PopoverComponent, { environmentInjector: this.injector });

            this.outsideClickDirective = new OutsideClickDirective(this._popoverRef.location);
            this.outsideClickDirective.ignore = this.elementRef;
            this.outsideClickDirective.onOutsideClick.subscribe(() => this.hidePopover());

            this._popoverRef.setInput('ttPopover', this.ttPopover);
        }
    }

    calculatePosition() {
        if (!this.elementRef) return;

        const relativeRect = (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect();
        const popoverWindow: HTMLDivElement | null = document.querySelector('.tt-popover__window');

        if (popoverWindow) {
            const popoverRect = popoverWindow.getBoundingClientRect();
            let left = relativeRect.left + relativeRect.width / 2 - popoverRect.width / 2;
            let top = -1250000;

            if (relativeRect.top - 20 - popoverRect.height < 50) {
                top = relativeRect.top + 19 + relativeRect.height;
                this._popoverRef?.setInput('ttPosition', 'down');
            } else {
                top = relativeRect.top - 19 - popoverRect.height;
                this._popoverRef?.setInput('ttPosition', 'up');
            }
            const centerXOfRelativeElement = relativeRect.right - relativeRect.width / 2;

            if (centerXOfRelativeElement - popoverRect.width / 2 < 0) {
                this.renderer.setStyle(popoverWindow.querySelector('.tt-popover__arrow'), 'left', `${centerXOfRelativeElement}px`);
                this.renderer.removeStyle(popoverWindow.querySelector('.tt-popover__arrow'), 'transform');
                left = 0;
            } else if (centerXOfRelativeElement + popoverRect.width / 2 > window.innerWidth) {
                this.renderer.setStyle(popoverWindow.querySelector('.tt-popover__arrow'), 'transform', `translateX(${(centerXOfRelativeElement / window.innerWidth) * 100 + 50}%)`);
                this.renderer.removeStyle(popoverWindow.querySelector('.tt-popover__arrow'), 'left');
                left = left - Math.abs(centerXOfRelativeElement + popoverRect.width / 2 - window.innerWidth);
            } else {
                this.renderer.removeStyle(popoverWindow.querySelector('.tt-popover__arrow'), 'transform');
                this.renderer.removeStyle(popoverWindow.querySelector('.tt-popover__arrow'), 'left');
            }

            this.renderer.setStyle(popoverWindow, 'top', `0px`);
            this.renderer.setStyle(popoverWindow, 'left', `0px`);
            this.renderer.setStyle(popoverWindow, 'transform', `translate(${left}px, ${top}px)`);
        }
    }
    open = false;
    private showPopover() {
        if (this._popoverRef && this.open === false) {
            this.open = true;
            const popoverWindow: HTMLDivElement = this._popoverRef?.location.nativeElement.querySelector('.tt-popover__window');

            if (popoverWindow) {
                // initialize somewhere out of view of the user to we can correctly position the popover based on height.
                this.renderer.setStyle(popoverWindow, 'top', -1200000 + 'px');
                this.renderer.setStyle(popoverWindow, 'left', -1200000 + 'px');
            }

            document.body.appendChild(this._popoverRef.location.nativeElement);
            this.appRef.attachView(this._popoverRef.hostView);

            setTimeout(() => this.calculatePosition());
            // this.calculatePosition();
        }
    }

    private hidePopover() {
        if (this._popoverRef && this.open === true) {
            document.body.removeChild(this._popoverRef.location.nativeElement);
            this.appRef.detachView(this._popoverRef.hostView);
            this.open = false;
        }
    }

    ngOnInit(): void {
        this.createPopover();
    }

    calculateThisPopover: null | (() => void) = null;

    ngOnDestroy(): void {
        this.hidePopover();
        this.outsideClickDirective = null;
    }
}
