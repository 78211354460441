(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("offDutyCodeService", ['$q', '$ihttp',  function ($q, $ihttp) {
        var service = {
            loaded: false,
            dataSource: {},
            colorList: {
                records: []
            },
            salaryTypes: {
                records: []
            },
            getOffDutyCode: function (offdutycode_keyno) {
                var deferred = $q.defer();

                var parms_getOffDutyCode = {
                    method: 489,
                    parameters: {
                        offdutycode_keyno: offdutycode_keyno
                    }
                };

                $ihttp.post(parms_getOffDutyCode).then(function (data) {
                    service.dataSource = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            getColorList: function () {
                var deferred = $q.defer();

                var parms_getColorList = {
                    method: 491
                };

                $ihttp.post(parms_getColorList).then(function (data) {
                    angular.copy(data, service.colorList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            getSalaryTypes: function () {
                var deferred = $q.defer();

                var parms_getSalaryTypes = {
                    method: 481
                };

                $ihttp.post(parms_getSalaryTypes).then(function (data) {
                    angular.copy(data, service.salaryTypes.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveObj: function (dataSource) {
                return $ihttp.post({
                    method: 490,
                    parameters: dataSource
                });
            },
            deleteOffDutyCode: function (offdutycode_keyno) {
                var deferred = $q.defer();

                var parms_deleteOffDutyCode = {
                    method: 579,
                    parameters: {
                        offdutycode_keyno: offdutycode_keyno
                    }
                };

                $ihttp.post(parms_deleteOffDutyCode).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();