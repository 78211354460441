(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicButton', {
        templateUrl: 'views/components/dynamics/components/ttDynamicButton/ttDynamicButton.template.html',
        controllerAs: 'vm',
        bindings: {
            ttRootId: '@',
            ttText: '@',
            ttTextAlign: '@',
            ttLocked: '<',
            ttLockedText: '@',
            ttIcon: '@',
            ttIconAlign: '@',
            ttIconPullRight: '@',
            ttBadge: '@',
            ttType: '@',
            ttDisabled: '<',
            ttItemId: '@',
            ttStyle: '<',
            ttModel: '<',
            ttButtonactions: '<'
        },
        controller: ['base64', 'ttDynamicButtonService', 'ttDatasourceManager', 'ttDynamicGridManager', 'utilityService', function (base64, ttDynamicButtonService, ttDatasourceManager, ttDynamicGridManager, us) {
            var vm = this;

            vm.style = {};

            let buttonactions = [];

            vm.onClicked = function () {
                for (var i = 0; i < buttonactions.length; i++) {
                    switch (buttonactions[i].type) {
                        case 1: // datatask
                            var parms = {};

                            angular.forEach(buttonactions[i].parms, function (p) {
                                let ds = ttDatasourceManager.getDatasourceById(vm.ttRootId, p.datasource_id);

                                if (ds !== null) {
                                    if (angular.isDefined(ds.item) && ds.item !== null) {
                                        parms[p.datasource_id] = angular.copy(ds.item);
                                    } else {
                                        if (angular.isArray(ds.items) && ds.items !== null) {
                                            parms[p.datasource_id] = angular.copy(ds.items);
                                        } 
                                    }
                                }
                            }); 

                            ttDynamicButtonService.datatask(buttonactions[i].datatask, parms);
                            break;
                        case 2: // gridfunc
                            switch (buttonactions[i].gridfunc) {
                                case 'refresh':
                                    ttDynamicGridManager.refresh(vm.ttRootId, buttonactions[i].grid);
                                    break;
                                case 'read':
                                    ttDynamicGridManager.read(vm.ttRootId, buttonactions[i].grid);
                                    break;
                                case 'save':
                                    ttDynamicGridManager.save(vm.ttRootId, buttonactions[i].grid);
                                    break;
                            }
                            break;
                    }
                }
            };

            vm.$onChanges = function (changes) {
                if (us.isObject(changes.ttStyle) && us.isObject(changes.ttStyle.currentValue) && us.isObject(changes.ttStyle.currentValue.container)) {
                    angular.copy(changes.ttStyle.currentValue.container, vm.style);
                }
            };

            vm.$onInit = function () {
                if (vm.ttModel.length < 1) return;

                if (us.isObject(vm.ttStyle) && us.isObject(vm.ttStyle.container)) {
                    angular.copy(vm.ttStyle.container, vm.style);
                }

                let jsonData = us.isStringValue(vm.ttModel, true)
                    ? base64.urldecode(vm.ttModel)
                    : '{}';

                let data = angular.fromJson(jsonData);

                ttDynamicButtonService.init(data.tt_dynamicproperty_keyno).then(function (response) {
                    angular.forEach(response.buttonactions, function (ba) {
                        buttonactions.push({
                            type: ba.tt_buttonactiontype_keyno,
                            datatask: ba.p2_datatask_keyno,
                            gridfunc: ba.gridfunc_name,
                            parms: ba.parms_base64.length < 1 ? [] : angular.fromJson(base64.urldecode(ba.parms_base64)),
                            grid: ba.grid_name,
                            keyno: ba.keyno,
                            order: ba.order
                        });
                    });

                    buttonactions.sort((a, b) => a.order - b.order);
                });
            };
        }]
    });
})();