(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderlineBackorders', {
        templateUrl: 'views/components/views/orderlineBackorders/orderlineBackorders.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'orderlineBackordersService', 'ttGridFactory', function ($stateParams, orderlineBackordersService, ttGridFactory) {
            let vm = this;
            let onDestroy = [];

            vm.model = {
                rememberId: 'w_orderlineBackorders',
                date: {}
            }

            vm.grid = new ttGridFactory({
                rememberId: 'w_orderlineBackorders.grid',
                loadData: {
                    method: 3058,
                    parameters: {
                        date_fom: '',
                        date_tom: '',
                        backorder_only: $stateParams.backorder_only
                    }
                }
            }).setToolbar({
                wrapping: true,
                buttons: [
                    //{ name: 'select_entire_order', text: 'select_entire_order', func: function () { selectEntireOrder(); }, icon: 'check', cssClass: 'k-button tt-grid-btn-xs', translate: true, disabled: function () { return false; } }
                    { name: 'select_entire_order', text: 'select_entire_order', func: function () { selectEntireOrder(); }, icon: 'check', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return false; } }
                ]
            }).setKendoConfig({ height: '100%' });

            let getIsSelectedItems = function () {
                var dirtyItems = vm.grid.gridfunc.getDirtyRows();
                var isSelectedItems = [];

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                });

                return isSelectedItems;
            }

            let selectEntireOrder = function () {
                let selectedItems = getIsSelectedItems();
                let uniqueOrders = [];
                let allRows = vm.grid.gridfunc.getRows();

                console.dir(selectedItems);

                angular.forEach(selectedItems, function (item) {
                    let hasOrder = false;

                    for (let i = 0; i < uniqueOrders.length; i++) {
                        if (uniqueOrders[i] === item.order_order_no) {
                            hasOrder = true;
                            break;
                        }
                    }

                    if (!hasOrder) {
                        uniqueOrders.push(item.order_order_no);
                    }
                });

                angular.forEach(uniqueOrders, function (order) {
                    angular.forEach(allRows, function (row) {
                        if (row.order_order_no === order) {
                            row.is_selected = true;
                        }
                    });
                });

                vm.grid.gridfunc.refresh();
            };

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.grid.dataTask.loadData.parameters.date_fom = vm.model.date.date_fom;
                vm.grid.dataTask.loadData.parameters.date_tom = vm.model.date.date_tom;

                rememberFunc();

                vm.grid.gridfunc.read();
            };

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            let rememberFunc = function () {
                orderlineBackordersService.remember(vm.model.rememberId, vm.model.date);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                orderlineBackordersService.getRemember(vm.model.rememberId).then(function (response) {
                    if (response?.[0]?.variablevalue && response[0].variablevalue !== '') {
                        vm.model.date = angular.copy(angular.fromJson(response[0].variablevalue));

                        vm.grid.dataTask.loadData.parameters.date_fom = vm.model.date.date_fom;
                        vm.grid.dataTask.loadData.parameters.date_tom = vm.model.date.date_tom;

                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
