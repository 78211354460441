(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('users', {
        templateUrl: 'views/components/views/users/users.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'userService', 'usersService', 'rememberService', 'ieScreenBlockService', function ($q, stateService, utilityService, userService, usersService, rememberService, ieScreenBlockService) {
            
            const vm = this;

            let variableNames = {
                filtervalue: ''
            };

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchUsers() }
                ],
                setting: {},
                users: []
            };
            
            vm.service = usersService;
            
            let loadSetting = function () {
                const deferred = $q.defer();

    			usersService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
    				
                    load();

                    deferred.resolve();
                });

                return deferred.promise;
    		};
            
            let load = function () {
                var secondParm;
    
                switch (usersService.userInternalExternal.currentIndex) {
                    case 0:
                        secondParm = usersService.getUserType().item_func;
                        break;
                    case 1:
                        secondParm = usersService.getUserRole().item_func;
                        break;
                    default:
                        return;
                }
    
                if ((secondParm || '') === '') return;

                if (angular.isUndefined(vm.model.setting.filtervalue) === true) return;
                if (vm.model.setting.filtervalue === null) return;

                ieScreenBlockService.start();
    
                vm.model.users = [];
                
                userService.list(usersService.getUserState().item_func, secondParm, vm.model.setting.filtervalue).then(function (users) {
                    angular.copy(users, vm.model.users);
                    
                    ieScreenBlockService.stop();
                });
            };
            
            vm.setUserState = function (userState) {
                if (usersService.setUserState(userState) !== true) return;
                
                load();
            };
    
            vm.setUserInternalExternal = function (userInternalExternal) {
                if (usersService.setUserInternalExternal(userInternalExternal) !== true) return;
                
                load();
            };
    
            vm.setUserType = function (userType) {
                if (usersService.setUserType(userType) !== true) return;
                
                load();
            };
    
            vm.setUserRole = function (userRole) {
                if (usersService.setUserRole(userRole) !== true) return;
                
                load();
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') {
                            rememberFunc('filtervalue');
                            load();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ## BUTTON LIST FUNCTIONS ##
            
            vm.searchUsers = function () {
                rememberFunc('filtervalue');
                load();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue) !== true) return;

                        vm.model.setting.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue === '') {
                            rememberFunc('filtervalue');
                            load();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
