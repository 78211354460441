(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('priceUpdate', {
        templateUrl: 'views/components/views/priceUpdate/priceUpdate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'translateService', 'modalService', 'priceUpdateService', 'economyService', 'rememberService', function ($timeout, stateService, utilityService, translateService, modalService, priceUpdateService, economyService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                settingPriceUpdate: {},
                selectListPrices: [],
                selectListRoundingTo: [],
                gridReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: '',
                lockedCalc: false,
                updatingNextPrice: false,
                updatingPriceOld: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        { key: 'pricenow' },
                        { key: 'pricenext' },
                        { key: 'is_selected' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            priceUpdateService.getPriceUpdate().then(function (data) {
                angular.copy(data[0], vm.model.settingPriceUpdate);

                if (utilityService.validateParmsValue(vm.model.settingPriceUpdate.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.settingPriceUpdate.view_id;
                }

                loadGrid();
            });

            economyService.listPrices().then(function (data) {
                angular.copy(data, vm.model.selectListPrices);
            });

            priceUpdateService.listRoundTo().then(function (data) {
                angular.copy(data, vm.model.selectListRoundingTo);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.settingPriceUpdate.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.settingPriceUpdate.p2_datatask_keyno,
                        parameters: vm.model.settingPriceUpdate
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE SINGLE PROCEDURE FUNCTION CALL

            var rememberFunc = function () {
                var variableName = 'w_' + stateService.getCurrentName() + '.' + 'pricelist_id';
                var variableValue = vm.model.settingPriceUpdate.pricelist_id;

                if (utilityService.validateParmsValue(variableName) !== true) return;
                if (utilityService.validateParmsValue(variableValue) !== true) return;

                rememberService.remember(variableName, variableValue);
            };

            // #endregion REMEMBER VALUE SINGLE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'pricelist_id':
                        vm.model.settingPriceUpdate.pricelist_id = value;

                        if (utilityService.validateParmsValue(vm.model.settingPriceUpdate.pricelist_id) !== true) return;

                        rememberFunc();

                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'calculate':
                        progressFunc();
                        break;
                    case 'updateNextPrice':
                        loadUpdateNextPrice();
                        break;
                    case 'updatePriceOld':
                        loadUpdatePriceOld();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        //modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].price_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;
                        
                        var parms = {
                            price_keyno: items[step].price_keyno,
                            pricenow: items[step].pricenow,
                            pst_change: vm.model.settingPriceUpdate.pst_change,
                            rounding_to: vm.model.settingPriceUpdate.rounding_to,
                            rounding_down: vm.model.settingPriceUpdate.rounding_down,
                            step: step
                        };

                        priceUpdateService.calcPriceUpdates(parms).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedCalc = false;
                                            //modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                //modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                items[step].pricenext = data[0].pricenext;
                                items[step].next_pricechange = data[0].next_pricechange;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            var progressFunc = function () {
                vm.model.lockedCalc = true;
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var allData = vm.grid.gridfunc.getAllRows();
                var currentData = [];
                
                angular.forEach(allData, function (item) {
                    if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                        currentData.push(item);
                    }
                });

                vm.model.progressInfo.max = currentData.length;

                vm.activateProgress(currentData);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.model.lockedCalc = false;
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.model.lockedCalc = false;
                        vm.grid.gridfunc.read();
                    }
                });
            };

		    // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region UPDATE PROCEDURE FUNCTION CALL

            var loadUpdateNextPrice = function () {
                vm.model.updatingNextPrice = true;

                priceUpdateService.updateNextPrice({ pricelist_id: vm.model.settingPriceUpdate.pricelist_id }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingNextPrice = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updatingNextPrice = false;

                        loadGrid();
                    }
                });
            };

            var loadUpdatePriceOld = function () {
                vm.model.updatingPriceOld = true;

                priceUpdateService.updatePriceOld(vm.model.settingPriceUpdate).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingPriceOld = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updatingPriceOld = false;

                        loadGrid();
                    }
                });
            };

		    // #endregion UPDATE PROCEDURE FUNCTION CALL
        }]
    });
})();
