(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travExpTypeBusCos', {
        templateUrl: 'views/components/views/travExpTypeBusCos/travExpTypeBusCos.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: [function () {
            
        }]
    });
})();
