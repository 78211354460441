(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('jobqueueDetails', {
        templateUrl: 'views/components/views/jobqueueDetails/jobqueueDetails.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'jobqueueDetailsService', function ($stateParams, jobqueueDetailsService) {
            var vm = this;

            vm.details = {};

            jobqueueDetailsService.load($stateParams.jobqueue_keyno).then(function (response) {
                console.log('details');
                console.dir(response);

                angular.copy(response.details, vm.details);
            });
        }]
    });
})();
