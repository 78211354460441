(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("consignmentPlansDeliveryDriverService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadSettings: function (argt, argv) {
				return p2DataTaskService.call(1716, {
					argtype: argt,
					argvalue: argv
				});
			},
			loadDeliveryMethods: function () {
				return p2DataTaskService.call(1693, {});
			},
			updateDriver: function (parms) {
				return p2DataTaskService.call(1717, parms);
			}
		};

		return service;
	}]);
})();