(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productionConfirmation', {
        templateUrl: 'views/components/views/productionConfirmation/productionConfirmation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'productionConfirmationService', 'stateService', function ($timeout, productionConfirmationService, stateService) {
            var vm = this;
            var onDestroy = [];
            var resizeTimeout;
            var restoreTimer;

            vm.model = {
                barcode: '',
                productionOrder: {},
                productionConfirmed: [],
                isScanning: true,
                isLoading: false,
                lockedSave: false,
                color: 'light',
                setting: {},
                size: getScannerSize()
            };

            var focus = createInputFocusInterval();

            vm.handleScanSubmit = handleScanSubmit;
            vm.confirmError = confirmError;

            vm.$onDestroy = function () {
                destroyInputFocusInterval(focus);

                window.removeEventListener('resize', reportWindowSize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            window.addEventListener('resize', resizeDebounce);

            getSettings();

            function reportWindowSize() {
                var size = getScannerSize();

                vm.model.size = size;
                                
                stateService.go('productionconfirmation', null, { reload: true, inherit: false });
            }

            function resizeDebounce() {
                clearTimeout(resizeTimeout);
                resizeTimeout = setTimeout(reportWindowSize, 100);
            }

            function getInput() {
                return document.getElementById('productionConfirmationFocusForm').elements[0];
            }

            function getScannerSize() {
                var scanner = document.getElementById('productionConfirmationScanner');
                var scannerTop = scanner.getBoundingClientRect().top;
                var scannerSize = document.documentElement.clientHeight - scannerTop;
                
                return scannerSize;
            }

            function handleInputFocus() {
                var input = getInput();
                input.setAttribute('inputmode','none');
                return input.focus();
            }

            function createInputFocusInterval(timer) {
                timer = timer || 300;

                return setInterval(handleInputFocus, timer);
            }

            function destroyInputFocusInterval(id) {
                return clearInterval(id);
            }

            function clearState() {
                Object.getOwnPropertyNames(vm.model.productionOrder).forEach(function (prop) {
                    delete vm.model.productionOrder[prop];
                });
            }

            function confirmError(){
                vm.model.color = 'default';
                clearState();
            }

            function getSettings() {
                productionConfirmationService.settings()
                    .then(function (data) {
                        vm.model.setting = {};

                        angular.copy(data[0], vm.model.setting);
                    });
            }

            function handleScanSubmit() {
                vm.model.isLoading = true;

                clearState();

                var input = getInput();
                                
                if (angular.isDefined(restoreTimer)) {
                    $timeout.cancel(restoreTimer);
                    restoreTimer = null;
                }

                productionConfirmationService.search({ carriers_keyno: input.value })
                    .then(function (data) {
                        vm.model.isScanning = false;
                        vm.model.isLoading = false;
                        vm.model.productionOrder = angular.copy(data[0]);
                        vm.model.productionConfirmed = angular.copy(data[0]);

                        if (parseInt(data[0].errorcode) == 9) throw new Error();

                        if (parseInt(data[0].errorcode) == 0) {
                            vm.model.color = 'success';
                        } else {
                            vm.model.color = 'danger';
                        }

                        $timeout(function () {
                            // TODO: New shared function
                            restoreTimer = $timeout(function () {
                                vm.model.color = 'default';

                                //clearState();
                                    
                            }, vm.model.setting.scan_timeout || 10000);

                        }, 100);
                    })
                    .catch(function (err) {
                        vm.model.isScanning = true;
                        vm.model.isLoading = false;
                        vm.model.color = 'danger';

                        // TODO: New shared function
                        $timeout(function () {
                            vm.model.color = 'default';

                            //clearState();

                        }, 600);
                    });

                input.value = '';
            }

            /*
            function handleConfirmButton() {
                var params = {
                    product: vm.model.productionOrder.prod_id
                };

                return productionConfirmationService.confirm(params)
                    .then(function () {
                        vm.model.productionConfirmed.push(vm.model.productionOrder);
                        console.log('confirmed', vm.model.productionConfirmed);
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
            }

            function handleCancelButton() {
                var input = getInput();
                return input.value = '';
            }

            function handleScanChange() {
                // TODO: Do we need to do anything here if we rely on submit?
            }

            function handleResultChange() {
                // TODO: Future changes?
            }
            */

        }]
    });
})();
