(function () {
    'use strict';

    angular.module('imApp').component('ttDatasourceprop', {
        templateUrl: 'views/components/dynamics/components/ttDatasourceprop/ttDatasourceprop.template.html',
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttRootid: '@',
            ttKeyno: '<',
            ttModel: '<',
            ttChange: '&'
        },
        controller: ['$element', 'translateService', 'layoutService', 'ttDatasourceManager', 'utilityService', function ($element, translateService, layoutService, ttDatasourceManager, us) {
            var vm = this;

            var onDestroy = [];

            vm.props = [];

            vm.id = {
                select: uuid()
            };

            vm.translations = {
                tt_datasourceprop: ''
            };

            vm.style = {
                label: {},
                select: {}
            };

            vm.class = {
                label: '',
                input: '',
                select: ''
            }

            vm.onPropChanged = function () {
                if (angular.isFunction(vm.ttChange) !== true) return;

                vm.ttChange({ $value: vm.ttModel, $modelId: us.getModelId($element) });
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                vm.style.select.fontSize = info.fontSizes.textSize;
                vm.style.select.height = info.height + 'px';
                vm.style.select.paddingTop = info.padding.top + 'px';
                vm.style.select.paddingBottom = info.padding.bottom + 'px';
                vm.style.select.paddingLeft = info.padding.left + 'px';
                vm.style.select.paddingRight = info.padding.right + 'px';

                vm.class.label = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2';
                vm.class.input = vm.hideLabel
                    ? 'col-xs-12'
                    : info.labelAlwaysOnTop === true
                        ? 'col-xs-12'
                        : 'col-xs-12 col-sm-10';
            });

            vm.$onInit = function () {
                translateService.translateBatch(vm.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.translations[key] = val;
                    });
                });

                setProps();
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttKeyno) && changes.ttKeyno.currentValue !== changes.ttKeyno.previousValue) {
                    setProps();
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            function setProps() {
                var schema = ttDatasourceManager.getSchema(vm.ttRootid, vm.ttKeyno);

                angular.copy(schema, vm.props);
            }
        }]
    });
})();