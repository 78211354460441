(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockMinMaxAdjust', {
        templateUrl: 'views/components/views/stockMinMaxAdjust/stockMinMaxAdjust.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'translateService', 'modalService', 'stockMinMaxAdjustService', function ($stateParams, $timeout, translateService, modalService, stockMinMaxAdjustService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let stockKeyNo = $stateParams.stock_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                hasDataTask: false,
                lockedSave: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: '',
                    abortclose: false
                }
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        { key: 'stock_minimum' },
                        { key: 'stock_maximum' },
                        { key: 'stock_std_orderquantity' },
                        { key: 'is_selected' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: true,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                stockMinMaxAdjustService.getStockMinMaxAdjust({ stock_keyno: stockKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    if (angular.isDefined(vm.model.setting.view_id) && vm.model.setting.view_id !== '') {
                        vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                    }

                    if (angular.isDefined(vm.model.setting.p2_datatask_keyno) && vm.model.setting.p2_datatask_keyno !== '') {
                        vm.grid.dataTask.loadData.method = vm.model.setting.p2_datatask_keyno;
                    }

                    if (angular.isDefined(vm.model.setting.stock_keyno) && vm.model.setting.stock_keyno !== '') {
                        vm.grid.dataTask.loadData.parameters = { stock_keyno: vm.model.setting.stock_keyno };
                    }

                    vm.model.hasDataTask = true;
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region PROGRESSBAR FUNCTION

            vm.activateProgressSave = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].stock_keyno + ' : ' + items[step].stockplace_name;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        var mergeObj = {
                            stock_minimum: vm.model.setting.stock_minimum,
                            stock_maximum: vm.model.setting.stock_maximum,
                            stock_std_orderquantity: vm.model.setting.stock_std_orderquantity,
                            records: items[step]
                        };

                        stockMinMaxAdjustService.saveGrid(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;
                                            doStep();
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            vm.saveChanges = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;
                
                var currentData = vm.grid.gridfunc.getDirtyRows().map(function (a) { return { "stock_keyno": a.stock_keyno, "is_selected": a.is_selected }; });
                var selectedItems = [];

                angular.forEach(currentData, function (item) {
                    if (item.item_is_selected === '0' || item.item_is_selected === false) return;

                    selectedItems.push(item);
                });

                vm.model.progressInfo.max = selectedItems.length;
                vm.activateProgressSave(selectedItems);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        console.log('Aborted');
                    } else if (vm.model.runProgress === 'FINISH') {
                        console.log('Finished');
                    }
                });
            };

            // #endregion PROGRESSBAR FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
