(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockCountDataItem', {
        templateUrl: 'views/components/views/stockCountDataItem/stockCountDataItem.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'inventoryScanService', 'stockCountDataItemService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, inventoryScanService, stockCountDataItemService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockCountDataItemKeyno = $stateParams.stockcountdataitem_keyno;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                itemsListLastScans: [],
                lockedOkAndScanAgain: false,
                lockedConfirm: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                let deferred = $q.defer();

                stockCountDataItemService.getEdit({
                    stockcountdataitem_keyno: stockCountDataItemKeyno,
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadLastScans()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadLastScans = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockcount_keyno) !== true) return;
                
                return inventoryScanService.listLastScans({ stockcount_keyno: vm.model.edit.stockcount_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListLastScans);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region SAVE BUTTON FUNCTION

            let saveChanges = function () {
                if (vm.model.edit.scan_again === '1') {
                    stockCountDataItemService.saveObj(vm.model.edit).then(function () {
                        vm.model.lockedOkAndScanAgain = false;
                        stateService.back('/' + 'inventoryscan');
                    }, function () {
                        vm.model.lockedOkAndScanAgain = false;
                    });
                } else {
                    stockCountDataItemService.saveObj(vm.model.edit).then(function () {
                        vm.model.lockedConfirm = false;
                        stateService.back('/' + 'inventoryscan');
                    }, function () {
                        vm.model.lockedConfirm = false;
                    });
                }
            };
            
            vm.okAndScanAgain = function () {
                vm.model.lockedOkAndScanAgain = true;
                vm.model.edit.scan_again = '1';
                
                saveChanges();
            };
            
            vm.confirmChanges = function () {
                vm.model.lockedConfirm = true;
                
                saveChanges();
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    stockCountDataItemService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back('/' + 'inventoryscan');
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
