(function() {
    'use strict';

    angular.module("imApp").factory("orderpickStatusStlService", ['$ihttp', function ($ihttp) {
        var service = {
            init: function () {
                return $ihttp.post({
                    method: 2526,
                    parameters: {
                        state: 'init'
                    }
                });
            }
        }

        return service;
    }]);
})();
