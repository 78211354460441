(function() {
    'use strict';

	angular.module("imApp").factory("collectionMiningService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			load: function (parms) {
				return p2DataTaskService.call(2065, parms);
			},
			loadCollections: function () {
                return p2DataTaskService.call(1884, {});
            },
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			updateColumnResize: function (parms) {
				return p2DataTaskService.call(1457, parms);
			},
			// NEW NAME?
			create: function (parms) {
				return p2DataTaskService.call(2066, parms);
			},
			loadPageSettings: function () {
				return p2DataTaskService.call(2064, {});
			},
			savePageSetting: function (parms) {
				return p2DataTaskService.call(616, parms);
			},
		}

        return service;
    }]);
})();
