(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('srOperation', {
        templateUrl: 'views/components/views/srOperation/srOperation.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'base64', 'stateService', 'srOperationService', function($stateParams, base64, stateService, srOperationService) {
    
            var vm = this;
    
    
    
    		// ## VARIABLES & DEFINITIONS ##
    
    		vm.model = {
    			custactNo: $stateParams.custact_no,
    			//center: [],
    			//fullscreen: false,
    			bgSrOperationMain: {
    				item_id: '',
    				item_name: '',
    				item_default: '0',
    				item_selected: '0',
    				records: [
    					{ item_id: '0', item_name: 'gb_sroperation_main_basic', item_func: 'BASIC' },
    					{ item_id: '1', item_name: 'gb_sroperation_main_resources', item_func: 'RESOURCES' },
    					{ item_id: '2', item_name: 'gb_sroperation_main_reports', item_func: 'REPORTS' }
    				]
    			},
    			operation: {},
    			itemsListResources: [],
    			itemsListReports: [],
    			//gis: []
    		};
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		srOperationService.loadSrOperationGet(vm.model.custactNo).then(function (data) {
    			vm.model.operation = angular.copy(data[0]);
    
    			loadResources();
    			loadReports();
    			//loadGisObjects();
    		});
    
    		var loadResources = function () {
    			if (angular.isUndefined(vm.model.operation.custact_no) === true) return;
    			if (vm.model.operation.custact_no === null) return;
    			if (vm.model.operation.custact_no.trim().length < 1) return;
    
    			vm.model.itemsListResources = [];
    
    			srOperationService.loadSrOperationResourcesList(vm.model.operation.custact_no).then(function (data) {
    				angular.copy(data, vm.model.itemsListResources);
    			});
    		};
    
    		var loadReports = function () {
    			if (angular.isUndefined(vm.model.operation.custact_no) === true) return;
    			if (vm.model.operation.custact_no === null) return;
    			if (vm.model.operation.custact_no.trim().length < 1) return;
    
    			vm.model.itemsListReports = [];
    
    			srOperationService.loadSrOperationReportsList(vm.model.operation.custact_no).then(function (data) {
    				angular.copy(data, vm.model.itemsListReports);
    			});
    		};
    
    		//var loadGisObjects = function () {
    		//	if (angular.isUndefined(vm.model.operation.custact_no) === true) return;
    		//	if (vm.model.operation.custact_no === null) return;
    		//	if (vm.model.operation.custact_no.trim().length < 1) return;
    
    		//	srOperationService.loadGisObjectsGet('custact_no', vm.model.operation.custact_no).then(function (data) {
    		//		angular.copy(data, vm.model.gis);
    		//	});
    		//};
    
    		// ## BUTTON GROUP FUNCTIONS ##
    
    		vm.selectBgSrOperationMain = function (item) {
    			vm.model.bgSrOperationMain.item_selected = item;
    		};
    
    		// ## DIRECTION BUTTON FUNCTIONS ##
    
    		vm.goTo = function (state) {
    			var go = function (parms) {
    				stateService.go(state, parms);
    			};
    
    			switch (state) {
    				case 'mapview':
    					go({
    						argtype: 'custact_no',
    						argvalue: vm.model.operation.custact_no
    					});
    					break;
    				case 'srexpenses':
    					go({
    						argtype: 'custact_no',
    						argvalue: vm.model.operation.custact_no
    					});
    					break;
    				case 'srrefundbasis':
    					go({
    						custact_no: vm.model.operation.custact_no,
    						checklistdata_keyno: '0'
    					});
    					break;
    				case 'sroperationar':
    					go({
    						custact_no: vm.model.operation.custact_no
    					});
    					break;
    				case 'sroperationaddr':
    					go({
    						custact_no: vm.model.operation.custact_no
    					});
    					break;
    			}
    		};
    
    		// ## WINDOW OPEN FUNCTIONS ##
    
    		vm.openWebLink = function () {
    			window.open(base64.urldecode(vm.model.operation.maplink_external));
    		};
    
    		vm.openWebLinkMtbMap = function () {
    			window.open(base64.urldecode(vm.model.operation.maplink_external_mtbmap));
    		};
    
    		//
    
    		//vm.addPos = function (location) {
    		//	console.log('locs: ');
    		//	console.dir(location);
    		//	console.dir(location.lat);
    		//	console.dir(location.lng);
    
    		//	stateService.go('gispositionadd', {
    		//		argtype: vm.model.pageInfo.argtype,
    		//		argvalue: vm.model.pageInfo.argvalue,
    		//		lat: location.lat,
    		//		lng: location.lng
    		//	});
    		//};
    
    		//vm.selectPos = function (marker) {
    		//	console.log('marker: ');
    		//	console.dir(vm.model);
    		//	console.dir(marker);
    		//	console.dir(marker.id);
    		//	console.dir(marker.type);
    
    		//	stateService.go('gisposition', {
    		//		//argtype: vm.model.pageInfo.argtype,
    		//		//argvalue: vm.model.pageInfo.argvalue,
    		//		//gisposition_keyno: marker.id
    		//		gisposition_keyno: marker.parms
    		//	});
    		//};
    	}]
    });
})();