import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';

(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactEdit', {
        templateUrl: 'views/components/views/custactEdit/custactEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'utilityService', 'stateService', 'modalService', 'custactEditService', 'logisticService', 'economyService', 'typeaheadService', 'translateService', 'graphAuthService', 'graphCalendarService', 'graphUserService', function ($q, $stateParams, utilityService, stateService, modalService, custactEditService, logisticService, economyService, typeaheadService, translateService, graphAuthService, graphCalendarService, graphUserService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;
            let filterField = $stateParams.filterfield;
            let filterValue = $stateParams.filtervalue;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                customerButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.cust_no }) }
                ],
                salaryTypeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.salarytype_id = '' }
                ],
                departmentButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.department_id = '' }
                ],
                orderInternalNoButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('orderhead', { order_internal_no: vm.model.edit.order_internal_no }) }
                ],
                edit: {},
                settingActivityType: {},
                selectListActivities: {
                    records: []
                },
                selectListProjects: [],
                selectListProcesses: [],
                selectListEquipments: [],
                selectListStatuses: [],
                selectListDeviationTypes: [],
                selectListDeviationSources: [],
                selectListDeviationSources2: [],
                selectListSizes: [],
                selectListPossibilities: [],
                selectListSalaryTypes: [],
                selectListCompanies: [],
                selectListDepartments: [],
                selectListRecurrings: [],
                gridReady: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'orderlineproducts_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                custactEditService.getEdit({
                    custact_no: custactNo,
                    connectto: filterField,
                    connectto_value: filterValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    loadProjects();
                    loadProjectProcesses();
                    loadEquipments();
                    loadActivityStatuses();
                    loadDeviationTypes();
                    loadDeviationSources();
                    loadDeviationSources2();
                    
                    loadDepartments();

                    custactEditService.getActivityType({ activitytype_no: vm.model.edit.activitytype_no }).then(function (response) {
                        angular.copy(response[0], vm.model.settingActivityType);
                        vm.model.edit.is_deviation = vm.model.settingActivityType.is_activitytype_no_deviation;

                        loadGrid();
                    });
                });
            };

            let loadActivitityTypes = function () {
                economyService.listActivitityTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListActivities.records);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadRecurrings = function () {
                custactEditService.listRecurrings().then(function (result) {
                    angular.copy(result, vm.model.selectListRecurrings);
                });
            };

            let loadSalesSizes = function () {
                custactEditService.listSalesSizes().then(function (result) {
                    angular.copy(result, vm.model.selectListSizes);
                });
            };

            let loadSalesPossibilities = function () {
                custactEditService.listSalesPossibilities().then(function (result) {
                    angular.copy(result, vm.model.selectListPossibilities);
                });
            };

            let loadSalaryTypes = function () {
                economyService.listSalaryTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListSalaryTypes);
                });
            };

            let loadDepartments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                return logisticService.listDepartment({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListDepartments);
                });
            };

            let loadProjects = function () {
                if (utilityService.validateParmsValue(vm.model.edit.cust_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.custact_no) !== true) return;

                return custactEditService.listProjects({
                    cust_no: vm.model.edit.cust_no,
                    custact_no: vm.model.edit.custact_no
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListProjects);
                });
            };

            let loadProjectProcesses = function () {
                if (utilityService.validateParmsValue(vm.model.edit.project_keyno) !== true) return;

                return economyService.listProjectProcesses({ project_keyno: vm.model.edit.project_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListProcesses);
                });
            };

            let loadEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.custact_no) !== true) return;

                return logisticService.listEquipments({ custact_no: vm.model.edit.custact_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListEquipments);
                });
            };

            let loadActivityStatuses = function () {
                if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;

                return economyService.listActivityStatuses({ activitytype_no: vm.model.edit.activitytype_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStatuses);
                });
            };

            let loadDeviationTypes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;

                return custactEditService.listDeviationTypes({
                    isactive: '1',
                    activitytype_no: vm.model.edit.activitytype_no
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListDeviationTypes);
                });
            };

            let loadDeviationSources = function () {
                if (utilityService.validateParmsValue(vm.model.edit.deviationtype_keyno) !== true) return;

                return custactEditService.listDeviationSources({ deviationtype_keyno: vm.model.edit.deviationtype_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListDeviationSources);
                });
            };

            let loadDeviationSources2 = function () {
                if (utilityService.validateParmsValue(vm.model.edit.deviationsource_keyno) !== true) return;

                return custactEditService.listDeviationSources2({ deviationsource_keyno: vm.model.edit.deviationsource_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListDeviationSources2);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 2773,
                    parameters: {
                        order_internal_no: vm.model.edit.order_internal_no,
                        custact_no: vm.model.edit.custact_no
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearchCustName = function () {
                return typeaheadService.searchCustomer({
                    cust_name: vm.model.edit.cust_name,
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'C',
                    lastselect: 'SEARCH'
                });
            };

            vm.typeaheadSearchPersonNames = function (value) {
                return typeaheadService.lookUpPersonNames({ pers_name: value ?? vm.model.edit.initiated_by_person_name });
            };

            vm.typeaheadSearchServiceObjectName = function () {
                return typeaheadService.lookUpServiceObjects({ searchtext: vm.model.edit.serviceobject_name });
            };

            vm.typeaheadSearchOrders = function () {
                return typeaheadService.lookUpOrder({
                    searchtext: vm.model.edit.order_order_no,
                    cust_no: vm.model.edit.cust_no
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.onServiceobjectClear = function () {
                vm.model.edit.serviceobject_keyno = '0';
                vm.model.edit.serviceobject_name = '';
            };

            vm.onServiceobjectSelected = function (item) {
                vm.model.edit.serviceobject_keyno = item.item_id;
                vm.model.edit.serviceobject_name = item.item_name;
            };

            vm.onServiceobjectChanged = function (value) {
                vm.model.edit.serviceobject_name = value;

                if (!value) {
                    vm.onServiceobjectClear();
                } else {
                    let person = typeaheadService.selectListServiceObjects.find((item) => item.item_name === value);

                    if (person) {
                        vm.onServiceobjectSelected(person);
                    }
                }

            };

            vm.onEditCustNameClear = function () {
                vm.model.edit.cust_no = '0';
                vm.model.edit.cust_name = '';
            };

            vm.onEditCustNameSelected = function (item) {
                vm.model.edit.cust_no = item.item_id;
                vm.model.edit.cust_name = item.item_name;
            };

            vm.onEditCustNameChanged = function (value) {
                vm.model.edit.cust_name = value;

                if (!value) {
                    vm.onEditCustNameClear();

                    if (vm.model.edit.cust_no === '0') loadProjects();
                } else {
                    let customer = typeaheadService.selectListCustomers.find((item) => item.item_name === value);

                    if (customer) {
                        vm.onEditCustNameSelected(customer);

                        if (vm.model.edit.cust_no > 0) loadProjects();
                    }
                }
            };

            vm.onInitiatedByClear = function () {
                vm.model.edit.initiated_by_person = '0';
                vm.model.edit.initiated_by_person_name = '';
            };

            vm.onInitiatedBySelected = function (item) {
                vm.model.edit.initiated_by_person = item.item_id;
                vm.model.edit.initiated_by_person_name = item.item_name;
            };

            vm.onInitiatedByChanged = function (value) {
                vm.model.edit.initiated_by_person_name = value;

                if (!value) {
                    vm.onInitiatedByClear();
                } else {
                    let person = typeaheadService.selectListPersonNames.find((item) => item.item_name === value);

                    if (person) {
                        vm.onInitiatedBySelected(person);
                    }
                }
            };

            vm.onOrderClear = function () {
                vm.model.edit.order_order_no = '';
                vm.model.edit.order_internal_no = '0';
                vm.model.edit.cust_no = '0';
                vm.model.edit.cust_name = '';
            };

            vm.onOrderSelected = function (item) {
                vm.model.edit.order_order_no = item.item_name;
                vm.model.edit.order_internal_no = item.order_internal_no;
                vm.model.edit.cust_no = item.cust_no;
                vm.model.edit.cust_name = item.cust_name;
            };

            vm.onOrderChanged = function (value) {
                vm.model.edit.order_order_no = value;

                if (!value) {
                    vm.onOrderClear();

                    loadProjects();
                    loadGrid();
                } else {
                    let order = typeaheadService.selectListOrders.find((item) => item.item_name === value);

                    if (order) {
                        vm.onOrderSelected(order);

                        loadProjects();
                        loadGrid();
                    }
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            function addToMicrosoft365(custact, update) {
                if (angular.isUndefined(custact)) return $q.resolve();
                if (angular.isUndefined(custact.activitytype_no)) return $q.resolve();
                if (custact.activitytype_no.length < 1) return $q.resolve();

                let activityTypes = vm.model.selectListActivities.records.filter((r) => r.activitytype_no === custact.activitytype_no);
                let activityType = activityTypes.length < 1 ? undefined : activityTypes[0];

                if (angular.isUndefined(activityType)) return $q.resolve();
                if (activityType.sync_with_microsoft_365 !== '1') return $q.resolve();

                const deferred = $q.defer();

                graphAuthService.getAccount().then(function (account) {
                    graphAuthService.getAccessToken(account).then(function (token) {
                        graphUserService.getUser(account.localAccountId, token).then(function (user) {
                            let dtStart = moment(custact.datetime_start).format('YYYY-MM-DDTHH:mm:ss');
                            let dtEnd = moment(custact.datetime_end).format('YYYY-MM-DDTHH:mm:ss');

                            if (update === true) {
                                const event = {
                                    subject: custact.note,
                                    body: {
                                        contentType: 'HTML',
                                        content: custact.cust_name
                                    },
                                    bodyPreview: custact.cust_name,
                                    start: {
                                        dateTime: dtStart, //'2023-08-24T15:15:00',
                                        timeZone: 'Europe/Berlin'
                                    },
                                    end: {
                                        dateTime: dtEnd, //'2023-08-24T15:20:00',
                                        timeZone: 'Europe/Berlin'
                                    }
                                };

                                graphCalendarService.updateEvent(event, { eventId: custact.microsoft_graph_event_id }, token).then(function () {
                                    deferred.resolve();
                                });
                            } else {
                                const event = {
                                    subject: custact.note,
                                    body: {
                                        contentType: 'HTML',
                                        content: custact.cust_name
                                    },
                                    bodyPreview: custact.cust_name,
                                    start: {
                                        dateTime: dtStart, //'2023-08-24T15:15:00',
                                        timeZone: 'Europe/Berlin'
                                    },
                                    end: {
                                        dateTime: dtEnd, //'2023-08-24T15:20:00',
                                        timeZone: 'Europe/Berlin'
                                    },
                                    location: {
                                        displayName: ''
                                    },
                                    attendees: [{
                                        emailAddress: {
                                            address: user.mail,
                                            name: user.displayName
                                        },
                                        type: 'required'
                                    }]
                                };

                                graphCalendarService.createEvent(event, {}, token).then(function (response) {
                                    custactEditService.setGraphEventId(custact.custact_no, response.id).then(function () {
                                        deferred.resolve();
                                    });
                                });
                            }
                        });
                    });
                }).catch((reason) => {
                    console.log('ERROR');
                    console.dir(reason);
                    deferred.resolve();
                });

                return deferred.promise;
            }

            function isNumeric(str) {
                if (angular.isNumber(str)) return true;
                if (typeof str != "string") return false // we only process strings!

                return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
                    !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
            }

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let mergeObj = vm.model.edit;

                if (vm.grid.gridfunc !== null) {
                    mergeObj.custactproducts = vm.grid.gridfunc.getAllRows();
                }

                let update = isNumeric(mergeObj.custact_no) && mergeObj.custact_no > 0;

                if (update) {
                    mergeObj.custact_no = parseInt(mergeObj.custact_no);

                    if (mergeObj.custact_no < 1) {
                        update = false;
                    }
                }

                custactEditService.saveObj(mergeObj).then(function (response) {
                    mergeObj.custact_no = response[0].custact_no;

                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        // BJS 20230823
                        addToMicrosoft365(mergeObj, update).then(function () {
                            vm.model.lockedSave = false;
                            if (stateService.getCurrentName() === 'custactedit') {
                                stateService.back();
                            }
                        }).finally(() => vm.model.lockedSave = false);
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'activitytype':
                        go({ activitytype_no: vm.model.edit.activitytype_no });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'activitytype_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.activitytype_no) !== true) return;

                        vm.model.edit.activitytype_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;

                        loadDeviationTypes();
                        loadActivityStatuses();

                        if (vm.model.edit.activitytype_no !== '') {
                            custactEditService.getActivityType({ activitytype_no: vm.model.edit.activitytype_no }).then(function (response) {
                                angular.copy(response[0], vm.model.settingActivityType);
                                vm.model.edit.is_deviation = vm.model.settingActivityType.is_activitytype_no_deviation;
                                vm.model.edit.is_sales_opportunity = vm.model.settingActivityType.is_sales_opportunity;
                            });
                        }
                        break;
                    case 'project_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.project_keyno) !== true) return;

                        vm.model.edit.project_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.project_keyno) !== true) return;

                        loadProjectProcesses();
                        break;
                    case 'deviationtype_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.deviationtype_keyno) !== true) return;

                        vm.model.edit.deviationtype_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.deviationtype_keyno) !== true) return;

                        loadDeviationSources();
                        break;
                    case 'deviationsource_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.deviationsource_keyno) !== true) return;

                        vm.model.edit.deviationsource_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.deviationsource_keyno) !== true) return;

                        loadDeviationSources2();
                        break;
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        loadDepartments();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadActivitityTypes();
                loadRecurrings();
                loadSalesSizes();
                loadSalesPossibilities();
                loadSalaryTypes();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
