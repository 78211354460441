
(function () {
    'use strict';

    let module = angular.module('imApp');


    module.directive('ttListbox', ['$compile', 'layoutService', function ($compile, layoutService) {
        return {
            restrict: 'A',
            scope: {
                ttData: '<',
                ttOnSelect: '&',
                ttListboxWidth: '@',
                ttListboxHeight: '@',
            },
            link: function (scope, element) {
                scope.id = {
                    template: uuid(),
                    modal: uuid(),
                };

                const templateScope = scope.$new();
                const compiledTemplate = $compile(`<div id=${scope.id.template} ng-include="'views/components/directives/ttListbox/ttListbox.template.html?v=${module.version}'"></div>`)(templateScope);

                angular.element(document.body).append(compiledTemplate);

                scope.selectedIndex = 0;
                scope.showSearchList = false;

                scope.data = [];

                scope.style = {
                    modal: {},
                    listbox: {},
                    option: {},
                    icon: {},
                };

                element.on('keydown', function (event) {
                    setTimeout(() => {
                        if (scope.ttData.length === 0 || !element.val() || event.key === 'Tab') {
                            scope.showSearchList = false;
                            scope.$apply();
                            return;
                        } else if (scope.ttData.length > 0) {
                            scope.showSearchList = true;
                        }

                        if (event.key === 'ArrowDown') {
                            event.preventDefault();
                            if (scope.selectedIndex < scope.ttData.length - 1) {
                                scope.selectedIndex++;
                            } else if (scope.selectedIndex >= scope.ttData.length - 1) {
                                scope.selectedIndex = 0;
                            }

                            scrollListbox();
                        } else if (event.key === 'ArrowUp' && scope.selectedIndex > 0) {
                            event.preventDefault();
                            scope.selectedIndex--;
                            scrollListbox()
                        } else if (event.key === 'Enter' && scope.selectedIndex >= 0) {
                            scope.selectItem();
                        }
                    });
                });


                element.on('focus', function () {
                    if (element.val() && scope.ttData.length > 0) {
                        scope.showSearchList = true;
                        scope.$apply();
                    }
                });

                element.on('blur', function (event) {
                    setTimeout(() => {
                        if (!event?.relatedTarget?.closest('.tt-listbox__item')) {
                            scope.showSearchList = false;
                            scope.$apply();
                        }
                    }, 100);
                });

                scope.selectItem = function (item) {
                    scope?.ttOnSelect({ $item: item ?? scope.ttData[scope.selectedIndex] });
                    scope.showSearchList = false;
                }

                scope.$watch('ttData', function (newVal) {
                    if (angular.isArray(newVal)) {
                        scope.selectedIndex = 0;

                        scope.data = newVal.map((item) => {
                            let icons = '';
                            if (item.item_glyphicon.startsWith('fa')) {
                                icons = 'fad fa' + item.item_glyphicon.replace(item.item_glyphicon.split('-')[0], '');
                            } else {
                                icons = 'glyphicon ' + item.item_glyphicon;
                            }
                            return { ...item, item_glyphicon: icons };
                        });
                    }
                });

                scope.$watch('showSearchList', function (newVal) {
                    if (newVal === true) {
                        scope.style.modal.top = element[0].getBoundingClientRect().bottom;
                        if (scope.ttListboxWidth === 'parent') {
                            scope.style.modal.width = element[0].getBoundingClientRect().width;
                            scope.style.modal.left = element[0].getBoundingClientRect().left;
                        } else if (scope.ttListboxWidth === 'fill') {
                            scope.style.modal.width = '100vw';
                            scope.style.modal.maxWidth = '100vw';
                            scope.style.modal.left = 0;
                        } else if (scope.ttListboxWidth) {
                            scope.style.modal.width = scope.ttListboxWidth;
                        }
                    }
                });

                layoutService.onLayoutChanged([], (info) => {
                    scope.style.option.fontSize = info.fontSizes.textSize;
                })

                function getIconClassList(item) {
                    let icons = '';
                    if (item.item_glyphicon.startsWith('fa')) {
                        icons = 'fad fa' + item.item_glyphicon.replace(item.item_glyphicon.split('-')[0], '');
                    } else {
                        icons = 'glyphicon ' + item.item_glyphicon;
                    }

                    return icons;
                }

                function scrollListbox() {
                    const modal = document.getElementById(`${scope.id.modal}`);

                    if (modal) {
                        const listbox = modal.querySelector('.tt-listbox__modal');
                        const listItem = modal.querySelectorAll('.tt-listbox__item')[scope.selectedIndex];

                        if (listbox && listItem) {
                            var listItemHeight = listItem.offsetHeight;
                            var scrollTop = listbox.scrollTop;
                            var itemTop = listItem.offsetTop;

                            if (itemTop < scrollTop) {
                                listbox.scrollTop = itemTop;
                            } else if (itemTop + listItemHeight > scrollTop + listbox.clientHeight) {
                                listbox.scrollTop = itemTop + listItemHeight - listbox.clientHeight;
                            }

                            scope.$apply();
                        }
                    }
                }

                scope.$on('$destroy', function () {
                    scope.$destroy();
                    element.off('blur');
                    element.off('focus');
                    element.off('keydown');
                    angular.element(document.body).find(`#${scope.id.template}`).remove();
                });
            }
        };
    }]);
})();
