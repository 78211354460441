(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("purchLineService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 691,
                    parameters: parms || {}
                });
            },
            getPrice: function (edit) {
                return $ihttp.post({
                    method: 694,
                    parameters: edit
                });
            },
            getStockInfo: function (edit) {
                return $ihttp.post({
                    method: 698,
                    parameters: edit
                });
            },
            getExampleData: function (edit) {
                return $ihttp.post({
                    method: 699,
                    parameters: edit
                });
            },
            changeProduct: function (edit) {
                return $ihttp.post({
                    method: 692,
                    parameters: edit
                });
            },
            changeProductSize: function (edit) {
                return $ihttp.post({
                    method: 693,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 695,
                    parameters: edit
                });
            },
            cancelRestPurchaseLine: function (edit) {
                return $ihttp.post({
                    method: 2478,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 697,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
