(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("userUsergroupService", ['$ihttp', function ($ihttp) {
        var service = {
            getUserUserGroup: function (parms) {
                return $ihttp.post({
                    method: 2872,
                    parameters: parms || {}
                });
            },
            loadUserUsergroupList: function (userName) {
                return $ihttp.post({ method: 64, parameters: { get_username: userName }});
            },
            loadUserUsergroupDisableUser: function (userName) {
                return $ihttp.post({ method: 85, parameters: { user_id_portal: userName } });
            }
        };

        return service;
    }]);
})();