(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custprodaccbcs', {
        templateUrl: 'views/components/views/custprodaccbcs/custprodaccbcs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'translateService', 'custProdAccBcsService', 'logisticService', 'typeaheadService', 'rememberService', function ($q, $timeout, stateService, utilityService, modalService, translateService, custProdAccBcsService, logisticService, typeaheadService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let variableNames = {
                businessco_no: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                gridAccountingRulesReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.gridAccountingRules = {
                dataTask: {
                    rememberId: 'w_custprodaccbcs_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'custprodaccbcs_save_accounts',
                                text: 'custprodaccbcs_save_accounts',
                                func: function () {
                                    addItem();
                                },
                                icon: 'fa-plus',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();
                
            	custProdAccBcsService.getCustProdAccBcs().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadGridAccountingRules()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadGridAccountingRules = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                vm.gridAccountingRules.dataTask.loadData = {
                    method: 2995,
                    parameters: vm.model.setting
                };

                vm.model.gridAccountingRulesReady = true;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'account_name':
                    case 'account_name_free':
                    case 'account_name_export':
                    case 'account_name_free_export':
                        vm.model.setting[id] = value;

                        return typeaheadService.lookUpSearchAccounts({
                            searchtext: vm.model.setting[id] || '',
                            businessco_no: vm.model.setting.businessco_no,
                            onlyaccounttype: 'a'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item, id) {
                switch (id) {
                    case 'account_name':
                        if (item) {
                            vm.model.setting.account_no = item?.item_id ?? '0';
                            vm.model.setting.account_name = item?.item_name ?? '';
                        } else {
                            vm.onClear('account_name');
                        }
                        break;
                    case 'account_name_free':
                        if (item) {
                            vm.model.setting.account_no_free = item?.item_id ?? '0';
                            vm.model.setting.account_name_free = item?.item_name ?? '';
                        } else {
                            vm.onClear('account_name_free');
                        }
                        break;
                    case 'account_name_export':
                        if (item) {
                            vm.model.setting.account_no_export = item?.item_id ?? '0';
                            vm.model.setting.account_name_export = item?.item_name ?? '';
                        } else {
                            vm.onClear('account_name_export');
                        }
                        break;
                    case 'account_name_free_export':
                        if (item) {
                            vm.model.setting.account_no_free_export = item?.item_id ?? '0';
                            vm.model.setting.account_name_free_export = item?.item_name ?? '';
                        } else {
                            vm.onClear('account_name_free_export');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedTypeahead = function (value, item, id) {
                switch (id) {
                    case 'account_name':
                        vm.model.setting.account_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.setting.account_no = item.item_id;
                            }
                        } else {
                            vm.onClear('account_name');
                        }
                        break;
                    case 'account_name_free':
                        vm.model.setting.account_name_free = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.setting.account_no_free = item.item_id;
                            }
                        } else {
                            vm.onClear('account_name_free');
                        }
                        break;
                    case 'account_name_export':
                        vm.model.setting.account_name_export = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.setting.account_no_export = item.item_id;
                            }
                        } else {
                            vm.onClear('account_name_export');
                        }
                        break;
                    case 'account_name_free_export':
                        vm.model.setting.account_name_free_export = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.setting.account_no_free_export = item.item_id;
                            }
                        } else {
                            vm.onClear('account_name_free_export');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function (id) {
                switch (id) {
                    case 'account_name':
                        vm.model.setting.account_no = '0';
                        vm.model.setting.account_name = '';
                        break;
                    case 'account_name_free':
                        vm.model.setting.account_no_free = '0';
                        vm.model.setting.account_name_free = '';
                        break;
                    case 'account_name_export':
                        vm.model.setting.account_no_export = '0';
                        vm.model.setting.account_name_export = '';
                        break;
                    case 'account_name_free_export':
                        vm.model.setting.account_no_free_export = '0';
                        vm.model.setting.account_name_free_export = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        vm.gridAccountingRules.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].custprodaccbc_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;
                        
                        let mergeObj = vm.model.setting;
                        mergeObj.custprodaccbc_keyno = items[step].custprodaccbc_keyno;
                        
                        custProdAccBcsService.saveGrid(mergeObj).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            let addItem = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var dirtyRows = vm.gridAccountingRules.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({ custprodaccbc_keyno: item.custprodaccbc_keyno });
                });

                console.log('selectedItems: ' + angular.toJson(selectedItems));

                vm.model.progressInfo.max = selectedItems.length;
                vm.activateProgress(selectedItems);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.gridAccountingRules.gridfunc.rebind();
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.gridAccountingRules.gridfunc.rebind();
                    }
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
