(function () {
    'use strict';

    var module = angular.module('imApp');

    module.factory('bigButtonModel', [function () {
        // constructor, with class name
        function bigButton(data) {
            if (angular.isFunction(data.translate)) {
                this.translate = data.translate(stateParams);
            } else {
                this.translate = angular.isDefined(data.translate) ? data.translate : true;
            }

            this.text = data.text || '';
            this.state = data.state || '';
            this.visible = angular.isDefined(data.visible) ? data.visible : true;

            if (this.visible !== true) {
                this.translate = false;
            }
        };

        bigButton.build = function (data) {
            return new bigButton(data);
        };

        return bigButton;
    }]);
})();