(function () {
	'use strict';

    var module = angular.module('imApp');

    module.component('batchDeliveryAdd', {
        templateUrl: 'views/components/views/batchDeliveryAdd/batchDeliveryAdd.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'batchDeliveryAddService', function ($stateParams, utilityService, batchDeliveryAddService) {
            var vm = this;

            vm.model = {
                batchDeliveryKeyno: $stateParams.batchdelivery_keyno,
                bgBatchDeliveryAddSelection: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'batchdelivery_receive_all', item_func: 'all' },
                        { item_id: '1', item_name: 'batchdelivery_receive_to_date', item_func: 'to_date' }
                    ]
                },
                itemsListSelectSuppliers: []
            };

            var retrieve = function () {
                if (utilityService.validateParmsValue(vm.model.bgBatchDeliveryAddSelection.item_selected) !== true) return;

                vm.model.itemsListSelectSuppliers = [];

                batchDeliveryAddService.listBatchDeliverySelectSuppliers({
                    batchdelivery_keyno: vm.model.batchDeliveryKeyno,
                    period_selector: vm.model.bgBatchDeliveryAddSelection.item_selected
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListSelectSuppliers);
                });
            };

            vm.selectBgBatchDeliveryAddSelection = function (item) {
                vm.model.bgBatchDeliveryAddSelection.item_selected = item;
                retrieve();
            };
		}]
	});
})();