(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockTransferLoadItemService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1038,
                    parameters: parms || {}
                });
            },
            listCorrectionCodes: function (parms) {
                return $ihttp.post({
                    method: 1071,
                    parameters: parms || {}
                });
            },
            scanStockTransferLoadItem: function (edit) {
                return p2DataTaskService.call(1060, edit);
			},
            saveStockTransferLoadItem: function (edit) {
                return p2DataTaskService.call(1039, edit);
			}
        };

        return service;
    }]);
})();
