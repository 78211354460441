(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("jobScheduleService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadJobScheduleGet: function (jobschedule_keyno) {
                return p2DataTaskService.call(1663, {
                    jobschedule_keyno: jobschedule_keyno
                });
            },
            loadRepeatEveryTypeList: function () {
                return p2DataTaskService.call(1664, {});
            },
            loadRepeatEveryNumberList: function (repeat_every_type) {
                return p2DataTaskService.call(1665, {
                    repeat_every_type: repeat_every_type
                });
            },
            addToQueue: function (schedule) {
                return $ihttp.post({
                    method: 3400,
                    parameters: schedule
                });
            },
            saveObj: function (schedule) {
                return $ihttp.post({
                    method: 1666,
                    parameters: schedule
                });
            }
        };

        return service;
    }]);
})();
