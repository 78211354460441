(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srEquipmentsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrEquipmentsGet: function (resource_id) {
                return p2DataTaskService.call(1792, {
                    resource_id: resource_id
                });
            },
            loadSrEquipmentsList: function (resource_id) {
                return p2DataTaskService.call(1793, {
                    resource_id: resource_id
                });
            }
        };

        return service;
    }]);
})();