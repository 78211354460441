(function () {
    'use strict';

    angular.module('imApp').directive('ttItem', ['ttItemService', function (ttItemService) {
        var directive = {
            restrict: 'A',
            scope: {
                ttItemId: '@ttItem'
            },
            link: ttItemService.register
        };
 
        return directive;
    }]);
})();