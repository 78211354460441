(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("materialRegisterService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getMaterialRegister: function (parms) {
                return $ihttp.post({
                    method: 2214,
                    parameters: parms || {}
                });
            },
            loadP2WMaterialRegisterLastList: function (custact_no) {
                return p2DataTaskService.call(959, {
                    custact_no: custact_no
                });
            },
            listProcesses: function (parms) {
                return $ihttp.post({
                    method: 29,
                    parameters: parms || {}
                });
            },
            scanObj: function (edit) {
                return $ihttp.post({
                    method: 2216,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 150,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
