(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockCountDataService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2StockCountDataGet: function (stockcountdata_keyno) {
                return p2DataTaskService.call(983, {
					stockcountdata_keyno: stockcountdata_keyno
				});
            },
            loadP2WStockCountDataItemList: function (stockcountdata_keyno) {
                return p2DataTaskService.call(984, {
                    stockcountdata_keyno: stockcountdata_keyno
                });
            }
        };

        return service;
    }]);
})();