(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epBlockOrdersFinished', {
        templateUrl: 'views/components/views/epBlockOrdersFinished/epBlockOrdersFinished.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$state', '$uibModal', 'responsive', 'layoutService', function ($state, $uibModal, responsive, layoutService) {
            var vm = this;

            vm.fontSize = null;

            vm.shipments = function (e, b, c) {
                let dataItem;

                if (e.hasOwnProperty('originalEvent')) { // if it is a jquery event
                    e.preventDefault();
                    dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                } else {
                    dataItem = e;
                }


                if (['xx', 'xs', 'sm', 'md'].indexOf(responsive.current) > 0) {
                    $state.go('epblockordersfinishedshipments', { pohKeyno: dataItem.poh_keyno });
                } else {
                    $uibModal.open({
                        component: 'epBlockOrdersFinishedShipments',
                        resolve: {
                            pohKeyno: function () {
                                return dataItem.poh_keyno
                            }
                        },
                        size: 'pst-ninety',
                        backdrop: 'static'
                    }).result.then(function () {
                        vm.grid.gridfunc.refresh();
                    });
                }
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'epBlockOrdersFinished',
                    loadSetupId: null,
                    loadData: {
                        method: 2010,
                        parameters: {}
                    }
                },
                translations: ['ep_shipments'],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            {
                                name: 'show_shipments',
                                text: 'ep_shipments',
                                translate: true,
                                icon: '',
                                type: 'secondary',
                                func: vm.shipments
                            },
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                onSetup: function (schema, fields, columns, translations) {
                    // when this page is one day converted to ts, this is function is not needed :)
                    var cols = [
                        columns[0],
                        { command: { text: translations.ep_shipments, click: vm.shipments, className: 'col-xs-12' }, title: " ", width: "180px", attributes: { style: 'font-size: ' + vm.fontSize + ';' } },
                        columns[1],
                        columns[2],
                        columns[3],
                        columns[4],
                        columns[5],
                        columns[6],
                        columns[7]
                    ];

                    angular.copy(cols, columns);
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSizeS;
            });

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
