(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("emailEditService", ['$q', '$http',  function ($q, $http) {
		var service = {
			vvMessage: {
				msgSubject: '',
				msgFrom: '',
				msgFromName: '',
				msgTo: '',
				msgDate: '',
				msgMessageId: '',
				msgSize: '',
				msgUid: 0,
				msgMsgNo: 0,
				msgRecent: 0,
				msgFlagged: 0,
				msgAnswered: 0,
				msgDeleted: 0,
				msgSeen: 0,
				msgDraft: 0,
				msgUdate: 0,
				msgPlainText: '',
				msgHtml: '',
				msgAttachments: 0
			},
			loadVvMessage: function (message_id) {
				var deferred = $q.defer();

				$http({
					url: 'http://api.vintervev.no/api/tt_mail_mailmessage',
					method: "POST",
					headers: {
						'auth_key': 'tt_198750',
						'Content-Type': 'multipart/form-data'
					},
					data: JSON.stringify({
						'accountOwner': '1',
						'messageId': message_id
					})
				})
				.then(function (result) {
					if (result.data.response.data) {
						angular.forEach(result.data.response.data, function (msg) {
							service.vvMessage = {
								msgSubject: msg.msg_subject,
								msgFrom: msg.msg_from,
								msgFromName: msg.msg_from_name,
								msgTo: msg.msg_to,
								msgDate: msg.msg_date,
								msgMessageId: msg.msg_message_id,
								msgSize: msg.msg_size,
								msgUid: msg.msg_uid,
								msgMsgNo: msg.msg_msgno,
								msgRecent: msg.msg_recent,
								msgFlagged: msg.msg_flagged,
								msgAnswered: msg.msg_answered,
								msgDeleted: msg.msg_deleted,
								msgSeen: msg.msg_seen,
								msgDraft: msg.msg_draft,
								msgUdate: msg.msg_udate,
								msgPlainText: msg.msg_plaintext,
								msgHtml: msg.msg_html,
								msgAttachments: msg.msg_attachments
							};
						});
					}

					service.loaded = true;
					deferred.resolve();
				});

				return deferred.promise;
			}
		};

        return service;
    }]);
})();