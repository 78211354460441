(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deviation', {
        templateUrl: 'views/components/views/deviation/deviation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'utilityService', 'translateService', 'deviationService', 'deviationTypesService', 'economyService', 'rememberService', function ($stateParams, $q, stateService, modalService, utilityService, translateService, deviationService, deviationTypesService, economyService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let custactNoDeviation = $stateParams.custact_no_deviation;

            let variableNames = {
                activitytype_no: ''
            };

            let translations = {
                confirm: '',
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                custactNoDeviation: custactNoDeviation,
                edit: {},
                selectListOnlyDeviations: [],
                selectListDeviationTypes: [],
                selectListOrderLines: [],
                selectListActivityStatuses: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE
            
            let loadEdit = function () {
                let deferred = $q.defer();
                
                deviationService.getEdit({
                    argtype: argType,
                    argvalue: argValue,
                    custact_no_deviation: custactNoDeviation
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadDeviationTypes(),
                        loadOrderLines(),
                        loadActivityStatuses()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadOnlyDeviations = function () {
                deviationService.listOnlyDeviations().then(function (result) {
                    angular.copy(result, vm.model.selectListOnlyDeviations);
                });
            };

            let loadDeviationTypes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;
                
                if (vm.model.edit.isnew === '1') {
                    return deviationTypesService.listDeviationTypes({
                        isactive: '1',
                        activitytype_no: vm.model.edit.activitytype_no
                    }).then(function (result) {
                        angular.copy(result, vm.model.selectListDeviationTypes);
                    });
                } else if (vm.model.edit.isnew === '0') {
                    return deviationTypesService.listDeviationTypes({
                        isactive: 'ALL',
                        activitytype_no: vm.model.edit.activitytype_no
                    }).then(function (result) {
                        angular.copy(result, vm.model.selectListDeviationTypes);
                    });
                }
            };

            let loadOrderLines = function () {
                deviationService.listOrderLines({
                    argtype: vm.model.edit.argtype,
                    argvalue: vm.model.edit.argvalue,
                    custact_no_deviation: vm.model.edit.custact_no_deviation
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListOrderLines);
                });
            };

            let loadActivityStatuses = function () {
                if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;
    
                return economyService.listActivityStatuses({ activitytype_no: vm.model.edit.activitytype_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListActivityStatuses);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'activitytype_no':
                        if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;

                        variableValue = vm.model.edit.activitytype_no;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let saveDeviation = function () {
                deviationService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].ws_result !== '1') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].ws_result_message,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    }
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                deviationService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].ws_result === '1') {
                        modalService.show({
                            type: 'success',
                            title: translations.confirm,
                            message: response[0].ws_result_message,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-success',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                                }
                            }]
                        });
                    } else {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].ws_result_message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'workdetails':
                        saveDeviation();

                        go({ custact_no: vm.model.edit.custact_no_deviation });
                        break;
                    case 'pictures':
                    case 'checklistdatas':
                        saveDeviation();

                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.edit.custact_no_deviation
                        });
                        break;
                    case 'documents':
                        saveDeviation();

                        go({
                            argtype: 'custact_no',
                            argvalue: vm.model.edit.custact_no
                        });
                        break;
                    case 'selectreport':
                        deviationService.saveObj(vm.model.edit).then(function (response) {
                            if (response[0].ws_result !== '1') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].ws_result_message,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                go({
                                    webpage_name: stateService.getCurrentName(),
                                    argtype: 'custact_no',
                                    argvalue: vm.model.edit.custact_no
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL
            
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    deviationService.deleteItem({ custact_no: vm.model.edit.custact_no_deviation }).then(function () {
                    	vm.model.lockedDelete = false;
                    	stateService.back();
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'activitytype_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.activitytype_no) !== true) return;

                        vm.model.edit.activitytype_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.activitytype_no) !== true) return;

                        rememberFunc('activitytype_no');
                        loadDeviationTypes();
                        loadActivityStatuses();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadOnlyDeviations();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
