(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansDeliveryLabour', {
        templateUrl: 'views/components/views/consignmentPlansDeliveryLabour/consignmentPlansDeliveryLabour.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'consignmentPlansDeliveryLabourService', function(stateService, consignmentPlansDeliveryLabourService) {
            var vm = this;

            vm.model = {
                btnListFilterVal: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                labour_list: [],
                filterval: ''
            };
            
            consignmentPlansDeliveryLabourService.loadLabourList().then(function (data) {
                angular.forEach(data, function (item) {
                    vm.model.labour_list.push(item);
                });        
            });
            
            vm.btnListFuncFilterVal = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.filterval = '';
                        break;
                    default:
                        break;
                }
            };
            
            vm.saveLabour = function (item) {
                consignmentPlansDeliveryLabourService.remember('consignmentplansdelivery.labour_no', item.item_id).then(function (data) {
                    consignmentPlansDeliveryLabourService.remember('consignmentplansdelivery.labour_name', item.item_name).then(function (data) {
                        stateService.back();
                    });
                });
            };
        }]
    });
})();