(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButtonGoto', {
        templateUrl: 'views/components/directives/ttButtonGoto/ttButtonGoto.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttGoto: '@',        // string               name of the website to navigate to.
            ttGotoParms: '<',   // string|array|object  of parameters for the goTo function/url (e.g. {argtype: "cust_no", argvalue: 123}, ["cust_no", 123] or "cust_no/123").
            ttGotoFunc: '&',    // function             to be called on click, if ttGoto is not defined. If ttGotoParms is defined, it will be passed to the function as the first parameter. The click event will be passed as the second parameter.
            ttText: '@',        // string               text to be displayed on the button.
            ttTextAlign: '@',   // string               text alignment.
            ttLocked: '<',      // boolean              whether or not the button is locked (unclickable). 
            ttLockedText: '@',  // string               text to be displayed when the button is locked.
            ttBadge: '@',       // icon                 to be displayed **** when and where ? ****.
            ttType: '@',        // string               type of the button **** valid options? ****.
            ttDisabled: '<',    // boolean              whether or not the button is disabled.
            ttItemId: '@',      // string               custom ID of the button element.
            ttStyle: '<',       // string               custom css style of the button.
            ttIcon: '@?',       // string               optional icon, if not set 'fas fa-chevron-right' is used
            ttIconAlign: '@?',  // string               optional icon align, if not set 'right' is set
            ttIconPullRight: '@?', // boolean           optional pull icon right, if not set 'true' is set
        },
        controller: ['eventService', 'stateService', 'utilityService', function (eventService, stateService, us) {
            var vm = this;

            vm.ttIcon = 'fas fa-chevron-right';
            vm.ttIconAlign = 'right';
            vm.ttIconPullRight = 'true';

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onClick = function (e) {
                if (us.isStringValue(vm.ttGoto, true) === true) {
                    if (angular.isDefined(vm.ttGotoParms)) {
                        if (e.ctrlKey) {
                            stateService.newTab(vm.ttGoto, vm.ttGotoParms);
                        } else {
                            stateService.go(vm.ttGoto, vm.ttGotoParms);
                        }
                    } else {
                        if (e.ctrlKey) {
                            stateService.newTab(vm.ttGoto);
                        } else {
                            stateService.go(vm.ttGoto);
                        }
                    }
                } else {
                    if (angular.isFunction(vm.ttGotoFunc)) {
                        const f = vm.ttGotoFunc();
                        if (angular.isFunction(f)) {
                            vm.ttGotoFunc()(vm.ttGotoParms, e);
                        }
                    }
                }
            };
        }]
    });
})();
