(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("oeeStateRemarkService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_oeestateremark_get: {
                oeestateremark_keyno: '',
                oeestateremark_name: '',
                oeestate_keyno: '',
                oeestate_name: '',
                show_remarktext: ''
            },
            loadP2OeeStateRemarkGet: function (oeestate_keyno, oeestateremark_keyno) {
                var deferred = $q.defer();

                var parms_p2_oeestateremark_get = {
                    method: 247,
                    parameters: {
                        oeestate_keyno: oeestate_keyno,
                        oeestateremark_keyno: oeestateremark_keyno
                    }
                };

                $ihttp.post(parms_p2_oeestateremark_get).then(function (data) {
                    service.p2_oeestateremark_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2OeeStateRemark: function () {
                var deferred = $q.defer();

                var parms_p2_oeestateremark_save = {
                    method: 248,
                    parameters: {
                        oeestateremark_keyno: service.p2_oeestateremark_get.oeestateremark_keyno,
                        oeestateremark_name: service.p2_oeestateremark_get.oeestateremark_name,
                        oeestate_keyno: service.p2_oeestateremark_get.oeestate_keyno,
                        show_remarktext: service.p2_oeestateremark_get.show_remarktext
                    }
                };

                $ihttp.post(parms_p2_oeestateremark_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();