(function () {
    'use strict';

    angular.module("imApp").factory("pohTopOpComponentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            getPohTop: function (poh_keyno_top) {
                return p2DataTaskService.call(1924, {
                    poh_keyno_top: poh_keyno_top
                });
            }
        };

        return service;
    }]);
})();