(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDelPurchLine', {
        templateUrl: 'views/components/views/batchDelPurchLine/batchDelPurchLine.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'utilityService', 'batchDelPurchLineService', 'stateService', function($stateParams, utilityService, batchDelPurchLineService, stateService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            vm.model = {
                lockedRollback: false
            };

            vm.batchdelpurchline_selection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: '0',
                records: [
                    { item_id: '1', item_name: 'batchdelivery_gb_simple', item_func: '0' },
                    { item_id: '2', item_name: 'batchdelivery_gb_details', item_func: '1' }
                ]
            };

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            batchDelPurchLineService.loadP2BatchDelPurchLineGet($stateParams.batchdelpurchline_keyno).then(function () {
                vm.p2_batchdelpurchline_get = batchDelPurchLineService.p2_batchdelpurchline_get;
            });

            // #####################
            // BUTTON GROUP FUNCTION
            // #####################

            vm.selectBatchDelPurchLine = function (l_s_batchdelpurchline_status) {
                vm.batchdelpurchline_selection.item_selected = l_s_batchdelpurchline_status;
            };

            // ####################################
            // DIRECTION BUTTON TO DEVIATIONS
            // ####################################

            vm.goToDeviations = function (index) {
                stateService.go('deviations', {
                    argtype: 'batchdelpurchline_keyno',
                    argvalue: $stateParams.batchdelpurchline_keyno
                });
            };

            // ########################################
            // DIRECTION BUTTON TO BATCHDELPURCHLINESBS
            // ########################################

            vm.goToBatchDelPurchLinesBs = function (index) {
                stateService.go('batchdelpurchlinesbs', {
                    batchdelpurchline_keyno: vm.p2_batchdelpurchline_get.batchdelpurchline_keyno,
                    batchdelivery_keyno: vm.p2_batchdelpurchline_get.batchdelivery_keyno
                });
            };

            // ############################
            // DIRECTION BUTTON TO STOCKGET
            // ############################

            vm.goToStockGet = function (index) {
                stateService.go('stockget', {
                    stock_keyno: vm.p2_batchdelpurchline_get.stock_keyno
                });
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'purchhead':
                        go({
                            purch_internal_no: vm.p2_batchdelpurchline_get.purch_internal_no
                        });
                        break;
                }
            };

            // ################################
            // DELETE FUNCTION & PROCEDURE CALL
            // ################################

            vm.deleteP2BatchDelPurchLine = function () {
                batchDelPurchLineService.deleteP2BatchDelPurchLine().then(function () {
                    stateService.back();
                });
            };

            // ## ROLLBACK BUTTON FUNCTION ##

            vm.rollbackBatchDelPurchLine = function () {
                vm.model.lockedRollback = true;
            
                batchDelPurchLineService.rollback(vm.p2_batchdelpurchline_get.batchdelpurchline_keyno).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedRollback = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedRollback = false;
                    }
                });
            };
		}]
	});
})();