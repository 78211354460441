(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('travelBillMenu', {
        templateUrl: 'views/components/views/travelBillMenu/travelBillMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'travelBillMenuService', function ($stateParams, stateService, translateService, modalService, travelBillMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgTravelBillMenuMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_travelbillmenu_main_overview', item_func: 'OVERVIEW' },
                        { item_id: '1', item_name: 'gb_travelbillmenu_main_details', item_func: 'DETAILS' },
                        { item_id: '2', item_name: 'gb_travelbillmenu_main_processing', item_func: 'PROCESSING' }
                    ]
                },
                travelBillNo: $stateParams.travelbill_no,
                travelBillMenu: {},
                itemsListTravelBills: [],
                lockedSendIn: false,
                lockedApprove: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##
    
            travelBillMenuService.loadTravelBillMenuGet(vm.model.travelBillNo).then(function (data) {
                vm.model.travelBillMenu = angular.copy(data[0]);
            });
    
            travelBillMenuService.loadTravelBillMenuItemsList(vm.model.travelBillNo).then(function (data) {
                angular.copy(data, vm.model.itemsListTravelBills);
            });
    
            // ## BUTTON GROUP FUNCTIONS ##
    
            vm.selectBgTravelBillMenuMain = function (item) {
                vm.model.bgTravelBillMenuMain.item_selected = item;
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'sroperation':
                        go({
                            custact_no: vm.model.travelBillMenu.custact_no
                        });
                        break;
                    case 'customersmenu':
                        go({
                            cust_no: vm.model.travelBillMenu.cust_no
                        });
                        break;
                    case 'projectmenu':
                        go({
                            project_keyno: vm.model.travelBillMenu.project_keyno
                        });
                        break;
                    case 'workdetails':
                        go({
                            custact_no: vm.model.travelBillMenu.custact_no
                        });
                        break;
                    case 'travelwagedata':
                        go({
                            travelbill_no: vm.model.travelBillMenu.travelbill_no,
                            travelwagedata_keyno: '0'
                        });
                        break;
                    case 'travelbillreject':
                        go({
                            travelbill_no: vm.model.travelBillMenu.travelbill_no
                        });
                        break;
                }
            };
    
            vm.createNew = function () {
                travelBillMenuService.loadCounterNextNumberGet('travelexpence_keyno').then(function (data) {
                    stateService.go('travelexpence', {
                        travelbill_no: vm.model.travelBillMenu.travelbill_no,
                        travelexpence_keyno: data[0].nextnumber
                    });
                });
            };

            // #region SEND BUTTON FUNCTION

            vm.send = function () {
                vm.model.lockedSendIn = true;
                
                travelBillMenuService.sendIn({ travelbill_no: vm.model.travelBillMenu.travelbill_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSendIn = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSendIn = false;
                        stateService.back();
                    }
                });
            };

            // #endregion SEND BUTTON FUNCTION

            // #region APPROVE BUTTON FUNCTION
            
            vm.approveExpenses = function () {
                vm.model.lockedApprove = true;
                
                travelBillMenuService.approve({ travelbill_no: vm.model.travelBillMenu.travelbill_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedApprove = false;
                        stateService.back();
                    }
                });
            };

            // #endregion APPROVE BUTTON FUNCTION
        }]
    });
})();
