<ng-container *ngFor="let button of ttButtons">
    <tt-form-field-button
        [ttId]="button.id"
        [ttIcon]="button.icon"
        [ttTooltip]="button.tooltip"
        [ttTranslateTooltip]="button.translateTooltip"
        (ttClick)="button?.onClick($event)"
        [ttGoto]="button.goto"
        [ttGotoParms]="button.gotoParms"
        [ttButtonParms]="ttButtonParms"
    ></tt-form-field-button>
</ng-container>
