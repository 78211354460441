(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentPlanningService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2WConsignmentPlanning: {},
            p2ConsignmentPlanningDeliveryWeeksList: {
                records: [],
                records_indexed: {}
            },
            p2CustactConsignmentPlansList: {
                records: []
            },
            p2ConsignmentPlans: {
                records_base: [],
                total_weight: 0,
                total_volum: 0,
                records_copy: []
            },
            p2ConsignmentScan: {
                webpagename: '',
                parms: '',
                parm_barcode: '',
                barcodetype: ''
            },
            p2ConsignmentPlanning: {
                records_base: [],
                records_1: [],
                records_2: [],
                records_3: [],
                records_not_planned: [],
                records_planned: [],
                records_indexed: {}
            },
            p2Stockplaces: {
                records: []
            },
            deliveryMethods: {
                records: [],
                indexed_records: {}
            },
            deliveryMethodsList: function () {
                var deferred = $q.defer();

                var parms_deliveryMethodsGet = {
                    method: 399,
                    parameters: {
                        consignment_keyno: '0'
                    }
                };

                $ihttp.post(parms_deliveryMethodsGet).then(function (data) {
                    service.deliveryMethods.records = [];
                    service.deliveryMethods.indexed_records = {};
                    angular.copy(data, service.deliveryMethods.records);
                    angular.forEach(service.deliveryMethods.records, function (item) {
                        service.deliveryMethods.indexed_records[item.deliverymethod_no] = {
                            deliverymethod_no: item.deliverymethod_no,
                            deliverymethod_name: item.deliverymethod_name,
                            deliverymethod_cargonizer: item.deliverymethod_cargonizer
                        };
                    });
                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            p2StockplacesList: function () {
                var deferred = $q.defer();

                var parms_p2StockplacesList = {
                    method: 453
                };

                $ihttp.post(parms_p2StockplacesList).then(function (data) {
                    service.p2Stockplaces.records = [];
                    angular.copy(data, service.p2Stockplaces.records);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2WConsignmentPlanning: function () {
                var deferred = $q.defer();

                var parmsP2WConsignmentPlanning = {
                    method: 407
                };

                $ihttp.post(parmsP2WConsignmentPlanning).then(function (data) {
                    service.p2WConsignmentPlanning = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2ConsignmentPlanningDeliveryWeeksList: function (stockplace_id, deliverymethod_no) {
                var deferred = $q.defer();

                var parmsP2ConsignmentPlanningDeliveryWeeksList = {
                    method: 408,
                    parameters: {
                        stockplace_id: stockplace_id,
                        deliverymethod_no: deliverymethod_no
                    }
                };

                $ihttp.post(parmsP2ConsignmentPlanningDeliveryWeeksList).then(function (data) {
                    service.p2ConsignmentPlanningDeliveryWeeksList.records = [];
                    service.p2ConsignmentPlanningDeliveryWeeksList.records_indexed = {};
                    angular.forEach(data, function (item) {
                        service.p2ConsignmentPlanningDeliveryWeeksList.records.push(item);
                        service.p2ConsignmentPlanningDeliveryWeeksList.records_indexed[item.deliveryweek] = item;
                    });
                    //angular.copy(data, service.p2ConsignmentPlanningDeliveryWeeksList.records);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2CustactConsignmentPlansList: function (ok) {
                var deferred = $q.defer();

                var parmsP2CustactConsignmentPlansList = {
                    method: 410,
                    parameters: {
                        ok: ok
                    }
                };

                $ihttp.post(parmsP2CustactConsignmentPlansList).then(function (data) {
                    angular.copy(data, service.p2CustactConsignmentPlansList.records);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2ConsignmentPlans: function (stockplace_id, custact_no_consignmentplan, deliveryweek, deliverymethod_no) {
                var deferred = $q.defer();

                var parmsP2ConsignmentPlans = {
                    method: 400,
                    parameters: {
                        stockplace_id: stockplace_id,
                        date_fom: '',
                        date_tom: '',
                        custact_no_consignmentplan: custact_no_consignmentplan,
                        deliveryweek: deliveryweek,
                        deliverymethod_no: deliverymethod_no
                    }
                };

                $ihttp.post(parmsP2ConsignmentPlans).then(function (data) {
                    service.p2ConsignmentPlans.total_weight = 0;
                    service.p2ConsignmentPlans.total_volum = 0;
                    service.p2ConsignmentPlans.records_base = [];
                    //service.p2ConsignmentPlans.records_indexed = {};

                    angular.copy(data, service.p2ConsignmentPlans.records_base);
                    angular.copy(data, service.p2ConsignmentPlans.records_copy);

                    angular.forEach(service.p2ConsignmentPlans.records_copy, function (item) {
                        item.item_state = '';
                        item.item_parms = '';
                        item.item_path = '';
                    });

                    angular.forEach(service.p2ConsignmentPlans.records_base, function (item) {
                        if (parseFloat(item.weight) >= 0) {
                            service.p2ConsignmentPlans.total_weight += parseFloat(item.weight);
                        }

                        if (parseFloat(item.volum) >= 0) {
                            service.p2ConsignmentPlans.total_volum += parseFloat(item.volum);
                        }

                        //service.p2ConsignmentPlans.records_indexed[item.item_id] = item;
                    });

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            searchP2ConsignmentScan: function (barcode) {
                var deferred = $q.defer();

                var parmsP2ConsignmentScan = {
                    method: 321,
                    parameters: {
                        barcode: barcode
                    }
                };

                $ihttp.post(parmsP2ConsignmentScan).then(function (data) {
                    service.p2ConsignmentScan = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2ConsignmentPlanning: function (stockplace_id, date_fom, date_tom, deliveryweek, deliverymethod_no) {
                var deferred = $q.defer();

                var parmsP2ConsignmentPlanning = {
                    method: 400,
                    parameters: {
                        stockplace_id: stockplace_id,
                        date_fom: date_fom,
                        date_tom: date_tom,
                        deliveryweek: deliveryweek,
                        deliverymethod_no: deliverymethod_no
                    }
                };

                $ihttp.post(parmsP2ConsignmentPlanning).then(function (data) {
                    service.p2ConsignmentPlanning.records_base = [];
                    angular.copy(data, service.p2ConsignmentPlanning.records_base);
                    service.p2ConsignmentPlanning.records_1 = [];
                    service.p2ConsignmentPlanning.records_2 = [];
                    service.p2ConsignmentPlanning.records_3 = [];
                    service.p2ConsignmentPlanning.records_not_planned = [];
                    service.p2ConsignmentPlanning.records_planned = [];
                    service.p2ConsignmentPlanning.records_indexed = {};

                    service.refreshRecords();
                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            refreshRecords: function () {
                //service.p2ConsignmentPlans.records_base = [];
                service.p2ConsignmentPlanning.records_1 = [];
                service.p2ConsignmentPlanning.records_2 = [];
                service.p2ConsignmentPlanning.records_3 = [];
                service.p2ConsignmentPlanning.records_not_planned = [];
                service.p2ConsignmentPlanning.records_planned = [];
                service.p2ConsignmentPlanning.records_indexed = {};

                angular.forEach(service.p2ConsignmentPlanning.records_base, function (item) {
                    if (item.statusid == '1') {
                        service.p2ConsignmentPlanning.records_1.push(item);
                    }

                    if (item.statusid == '2') {
                        service.p2ConsignmentPlanning.records_2.push(item);
                    }

                    if (item.statusid == '3') {
                        service.p2ConsignmentPlanning.records_3.push(item);
                    }

                    if (item.custact_no_consignmentplan == '0') {
                        service.p2ConsignmentPlanning.records_not_planned.push(item);
                        //if (service.p2ConsignmentPlanning.records_not_planned.length > 0) {
                        //    service.p2ConsignmentPlanning.records_not_planned[service.p2ConsignmentPlanning.records_not_planned.length - 1].item_glyphicon += ' glyphicon-save';
                        //    service.p2ConsignmentPlanning.records_not_planned[service.p2ConsignmentPlanning.records_not_planned.length - 1].item_glyphicon_2 += ' glyphicon-send glyphicon-trash';
                        //}
                    }

                    if (item.custact_no_consignmentplan !== '0') {
                        service.p2ConsignmentPlanning.records_planned.push(item);
                    }

                    //service.p2ConsignmentPlans.records_indexed[item.item_id] = item;
                    service.p2ConsignmentPlanning.records_indexed[item.item_id] = item;
                });
            },
            addP2ConsignmentPlanningOrders: function (index) {
                var deferred = $q.defer();
                var sendData = [];

                angular.forEach(service.p2ConsignmentPlanning.records_not_planned, function (item) {
                    if (item.item_is_selected == '1') {
                        sendData.push(item);
                        item.item_is_selected = '0';
                    }
                });

                var parmsP2ConsignmentPlanningAddOrders = {
                    method: 411,
                    parameters: {
                        custact_no_consignmentplan: service.p2WConsignmentPlanning.custact_no_consignmentplan,
                        records: sendData
                    }
                };

                $ihttp.post(parmsP2ConsignmentPlanningAddOrders).then(function (data) {
                    angular.forEach(data, function (item) {
                        service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].custact_no_consignmentplan = item.custact_no_consignmentplan;
                        service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].item_is_selected = '0';
                        service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].item_name_sub2 = item.item_name_sub2;
                    });

                    //service.p2ConsignmentPlanning.records_planned = [];
                    //service.p2ConsignmentPlanning.records_not_planned = [];

                    service.refreshRecords();

                    //angular.forEach(service.p2ConsignmentPlanning.records_base, function (item) {
                    //    if (item.custact_no_consignmentplan == '0') {
                    //        service.p2ConsignmentPlanning.records_not_planned.push(item);
                    //    }

                    //    if (item.custact_no_consignmentplan !== '0') {
                    //        service.p2ConsignmentPlanning.records_planned.push(item);
                    //    }
                    //});

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            removeP2ConsignmentPlansOrders: function (index) {
                var deferred = $q.defer();
                var sendData = [];

                angular.forEach(service.p2ConsignmentPlans.records_base, function (item) {
                    if (item.item_is_selected == '1') {
                        sendData.push(item);
                        item.item_is_selected = '1';
                    }
                });

                var parmsP2ConsignmentPlansRemoveOrders = {
                    method: 412,
                    parameters: {
                        volum: service.p2ConsignmentPlans.total_volum,
                        weight: service.p2ConsignmentPlans.total_weight,
                        records: sendData
                    }
                };

                $ihttp.post(parmsP2ConsignmentPlansRemoveOrders).then(function (data) {
                    angular.forEach(data, function (item) {
                        try {
                            if (service.p2ConsignmentPlanning.records_indexed[item.order_internal_no]) {
                                service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].custact_no_consignmentplan = '0';
                                service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].item_is_selected = '0';
                                service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].item_name_sub2 = '';
                            }
                        } catch (e) { }

                    });

                    service.refreshRecords();
                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            removeP2ConsignmentPlanningOrders: function (index) {
                var deferred = $q.defer();
                var sendData = [];

                angular.forEach(service.p2ConsignmentPlanning.records_planned, function (item) {
                    if (item.item_is_selected == '1') {
                        sendData.push(item);
                        item.item_is_selected = '1';
                    }
                });

                var parmsP2ConsignmentPlanningRemoveOrders = {
                    method: 412,
                    parameters: {
                        records: sendData
                    }
                };

                $ihttp.post(parmsP2ConsignmentPlanningRemoveOrders).then(function (data) {
                    angular.forEach(data, function (item) {
                        service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].custact_no_consignmentplan = '0';
                        service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].item_is_selected = '0';
                        service.p2ConsignmentPlanning.records_indexed[item.order_internal_no].item_name_sub2 = '';
                    });

                    //service.p2ConsignmentPlanning.records_planned = [];
                    //service.p2ConsignmentPlanning.records_not_planned = [];

                    //angular.forEach(service.p2ConsignmentPlanning.records_base, function (item) {
                    //    if (item.custact_no_consignmentplan == '0') {
                    //        service.p2ConsignmentPlanning.records_not_planned.push(item);
                    //    }

                    //    if (item.custact_no_consignmentplan !== '0') {
                    //        service.p2ConsignmentPlanning.records_planned.push(item);
                    //    }
                    //});

                    service.refreshRecords();
                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
