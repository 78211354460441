(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('containerShipment', {
        templateUrl: 'views/components/views/containerShipment/containerShipment.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['containerShipmentService', 'translateService', 'modalService', function (containerShipmentService, translateService, modalService) {
            var vm = this;
            vm.model = {}

            // #region VARIABLES & DEFINITIONS

            let translations = {
                tt_send_container_title: '',
                tt_send_container_message: '',
                tt_send_container_ok: '',
                tt_send_container_cancel: '',
                tt_receive_container_title: '',
                tt_receive_container_message: '',
                tt_receive_container_ok: '',
                tt_receive_container_cancel: '',
                tt_edi_transporter_return_title: '',
                tt_edi_transporter_return_message: '',
                tt_generic_ok: '',
                tt_generic_cancel: '',
            };

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            vm.gridReady = false;

            vm.containers = {
                isReady: false,
                searchingContainers: false,
                rememberId: 'w_containershipment.containers',
                selected: {
                    field: '',
                    key: '',
                    status: '',
                    edistatus: ''
                },
                list: []
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_containershipment',                  // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2259,
                        parameters: { shipmentcontainer_keyno: vm.containers.selected.key }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            { name: 'receiveContainer', text: 'tt_receive_container', func: function (data) { receiveContainer(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasValidReceiveStatus(); } },
                            { name: 'ediTranspReturn', text: 'tt_edi_transporter_return', func: function (data) { ediTranspReturn(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            { name: 'forcedEdiStatus', text: 'tt_forced_edi_status', func: function (data) { forcedEdiStatus(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            { name: 'directDelivery', text: 'tt_direct_delivery', func: function (data) { directDelivery(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            { name: 'resendDelayed', text: 'tt_resend_delayed_orders', func: function (data) { resendDelayed(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            { name: 'reactivateEdi', text: 'tt_reactivate_edi', func: function (data) { reactivateEdi(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            { name: 'ediStop', text: 'tt_edi_stop', func: function (data) { ediStop(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'sendEdi', text: 'tt_send_edi', func: function (data) { sendEdi(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return false; } },
                            { name: 'sendEdi', text: 'tt_send_edi', func: function (data) { sendEdi(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasValidEdi(); } },
                            { name: 'sendContainer', text: 'tt_send_container', func: function (data) { sendContainer(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasValidSendStatus(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    //fixedHeader: 'limit',                                  // Enables header and toolbar to stay visible when scrolling, 'limit' will trigger only headers, and not toolbar
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: '100%',                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region BUTTON FUNCTIONS

            var getIsSelectedItems = function () {
                var dirtyItems = vm.grid.gridfunc.getDirtyRows();
                var isSelectedItems = [];

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                });

                return isSelectedItems;
            }

            var getIsSelectedItemsParm = function (key) {
                var dirtyItems = vm.grid.gridfunc.getDirtyRows();
                var isSelectedItems = [];

                //if (angular.isUndefined(key)) key = 'dispatchpre_keyno';

                angular.forEach(dirtyItems, function (item) {
                    //if (angular.isDefined(item) && item.is_selected === true && angular.isDefined(item[key])) isSelectedItems.push(item[key]);
                    if (angular.isDefined(key)) {
                        if (angular.isDefined(item) && item.is_selected === true && angular.isDefined(item[key])) isSelectedItems.push(item[key]);
                    } else {
                        if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                    }
                });

                return isSelectedItems;
            }

            let getIsSelectedItemsParms = function (key) {
                if (!key) return [];
                let dirtyItems = vm.grid.gridfunc.getDirtyRows();
                let isSelectedItems = [];
                let keyList = [];

                if (angular.isArray(key)) {
                    keyList = key;
                } else {
                    keyList.push(key);
                }

                angular.forEach(dirtyItems, function (item) {
                    if (item && item.is_selected === true) {
                        let data = {};
                        angular.forEach(keyList, function (k) {
                            if (item[k]) data[k] = item[k];
                        });

                        isSelectedItems.push(data);
                    }
                });

                return isSelectedItems;
            }

            var hasDirty = function (data) {
                if (vm.containers.selected.status !== '40') return 'hidden';

                var parmItems = getIsSelectedItemsParm();

                if (parmItems.length > 0) return false;

                return true;
            };

            var hasValidEdi = function (data) {
                if (vm.containers.selected.status !== '40' || (isValid(vm.containers.selected.edistatus) && vm.containers.selected.edistatus === '1')) return 'hidden';

                var rowCount = vm.grid.gridfunc.getAllRows().length;

                if (rowCount > 0) return false;

                return true;
            };

            var hasValidSendStatus = function (validStatus) {
                //validStatNum = angular.isDefined(validStatusNum) && validStatusNum !== null ? validStatusNum : '20';
                if (vm.containers.selected.status !== '20') return 'hidden';

                if (vm.grid.gridfunc.getAllRows().length === 0) return true;

                return false;
            };

            //var hasValidDelayed = function () {
            //    if (vm.containers.selected.status !== '40') return 'hidden';

            //    if (vm.grid.gridfunc.getAllRows().length === 0) return true;

            //    return false;
            //};

            var hasValidReceiveStatus = function (validStatus) {
                if (vm.containers.selected.status !== '40') return 'hidden';

                return false;
            };

            var isValid = function (parm) {
                if (angular.isUndefined(parm)) return false;
                if (parm === null) return false;
                if (parm === '') return false;

                return true;
            };

            var sendContainer = function () {
                modalService.show({
                    type: 'warning',
                    title: translations.tt_send_container_title,
                    //message: result[0].errormessage,
                    message: translations.tt_send_container_message,
                    buttons: [{
                        label: translations.tt_send_container_cancel,
                        cssClass: 'btn-danger',
                        action: function (modalInstance) {
                            modalInstance.close();
                        }
                    }, {
                        label: translations.tt_send_container_ok,
                        cssClass: 'btn-success',
                        action: function (modalInstance) {
                            containerShipmentService.sendContainer({ shipmentcontainer_keyno: vm.containers.selected.key }).then(function (result) {
                                if (result[0].errorcode === '0') {
                                    containerShipmentService.getShipmentContainerKeynameList().then(function (data) {
                                        angular.copy(data, vm.containers.list);
                                        if (vm.containers.list.length > 0) {
                                            for (let i = 0; i < vm.containers.list.length; i++) {
                                                if (vm.containers.list[i].shipmentcontainer_keyno === vm.containers.selected.key) {
                                                    vm.containers.selected.field = vm.containers.list[i].shipmentcontainer_keyname;
                                                    vm.containers.selected.key = vm.containers.list[i].shipmentcontainer_keyno;
                                                    vm.containers.selected.status = vm.containers.list[i].shipmentcontainerstatus_keyno;
                                                    vm.containers.selected.edistatus = vm.containers.list[i].edistatus;
                                                    //console.dir(vm.containers.list[i]);
                                                    break;
                                                }
                                            }
                                        } else {
                                            vm.containers.selected.field = '';
                                            vm.containers.selected.key = '';
                                            vm.containers.selected.status = '';
                                            vm.containers.selected.edistatus = '';
                                        }

                                        vm.grid.dataTask.loadData.parameters = { shipmentcontainer_keyno: vm.containers.selected.key };

                                        rememberFunc();

                                        if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
                                    });
                                } else {
                                    modalService.show({
                                        type: 'danger',
                                        title: 'ERROR',
                                        message: result[0].errormessage,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-danger',
                                            action: function (modalInstanceError) {
                                                modalInstanceError.close();
                                            }
                                        }]
                                    });
                                }
                            });
                            modalInstance.close();
                        }
                    }]
                });
            }

            var receiveContainer = function () {
                modalService.show({
                    type: 'warning',
                    title: translations.tt_receive_container_title,
                    //message: result[0].errormessage,
                    message: translations.tt_receive_container_message,
                    buttons: [{
                        label: translations.tt_receive_container_cancel,
                        cssClass: 'btn-danger',
                        action: function (modalInstance) {
                            modalInstance.close();
                        }
                    }, {
                        label: translations.tt_receive_container_ok,
                        cssClass: 'btn-success',
                        action: function (modalInstance) {
                            containerShipmentService.receiveContainer({ shipmentcontainer_keyno: vm.containers.selected.key }).then(function (result) {
                                if (result[0].errorcode === '0') {
                                    containerShipmentService.getShipmentContainerKeynameList().then(function (data) {
                                        angular.copy(data, vm.containers.list);
                                        if (vm.containers.list.length > 0) {
                                            for (let i = 0; i < vm.containers.list.length; i++) {
                                                if (vm.containers.list[i].shipmentcontainer_keyno === vm.containers.selected.key) {
                                                    vm.containers.selected.field = vm.containers.list[i].shipmentcontainer_keyname;
                                                    vm.containers.selected.key = vm.containers.list[i].shipmentcontainer_keyno;
                                                    vm.containers.selected.status = vm.containers.list[i].shipmentcontainerstatus_keyno;
                                                    vm.containers.selected.edistatus = vm.containers.list[i].edistatus;
                                                    //console.dir(vm.containers.list[i]);
                                                    break;
                                                }
                                            }
                                        } else {
                                            vm.containers.selected.field = '';
                                            vm.containers.selected.key = '';
                                            vm.containers.selected.status = '';
                                            vm.containers.selected.edistatus = '';
                                        }

                                        vm.grid.dataTask.loadData.parameters = { shipmentcontainer_keyno: vm.containers.selected.key };

                                        rememberFunc();

                                        if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
                                    });
                                } else {
                                    modalService.show({
                                        type: 'danger',
                                        title: 'ERROR',
                                        message: result[0].errormessage,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-danger',
                                            action: function (modalInstanceError) {
                                                modalInstanceError.close();
                                            }
                                        }]
                                    });
                                }
                            });
                            modalInstance.close();
                        }
                    }]
                });
            }

            var sendEdi = function () {
                containerShipmentService.sendEdi({ shipmentcontainer_keyno: vm.containers.selected.key }).then(function (result) {
                    //console.log('sendEdi - RESULT:');
                    //console.dir(result);
                    if (result[0].errorcode === '0') {
                        vm.grid.gridfunc.rebind();
                    }
                });
            }

            var ediStop = function () {
                var parmItems = getIsSelectedItemsParm('dispatchpre_keyno');

                if (parmItems.length > 0) {
                    containerShipmentService.ediStopCodes().then(function (data) {
                        vm.grid.gridfunc.callPopupTable(data).then(function (value) {
                            if (value !== null) {
                                containerShipmentService.ediStop({ stopcode_keyno: value.stopcode_keyno, items: parmItems }).then(function (result) {
                                    if (result[0].errorcode === '0') {
                                        vm.grid.gridfunc.rebind();
                                    }
                                });
                            }
                        });
                    });
                }
            }

            var reactivateEdi = function () {
                var parmItems = getIsSelectedItemsParm('dispatchpre_keyno');

                if (parmItems.length > 0) {
                    containerShipmentService.ediReactivate({ items: parmItems }).then(function (result) {
                        if (result[0].errorcode === '0') {
                            vm.grid.gridfunc.rebind();
                        }
                    });
                }
            }

            var resendDelayed = async function () {
                let errorcode = 0;
                //let errormessage = 'shipmentcontainer processed correctly: ';
                //var parmItems = getIsSelectedItemsParms(['shipmentcontainer_keyno', 'dispatchpre_keyno']);
                var parmItems = getIsSelectedItemsParm('dispatchpre_keyno');
                let selectedDispatchpres = [];

                angular.forEach(parmItems, function (item) {
                    if (item) {
                        selectedDispatchpres.push({
                            dispatchpre_keyno: item
                        });
                    }
                });

                vm.grid.gridfunc.gridProgress(true);
                //console.dir(selectedDispatchpres);
                //if (parmItems.length > 0) {
                //    for (let i = 0; i < parmItems.length; i++) {
                //        let item = parmItems[i];
                //        try {
                //            let result = await containerShipmentService.ediResendDelayedOrders({ shipmentcontainer_keyno: item.shipmentcontainer_keyno, dispatchpre_keyno: item.dispatchpre_keyno });

                //            if (result[0].errorcode !== '0') {
                //                modalService.show({
                //                    type: 'danger',
                //                    title: 'Varsel',
                //                    message: result[0].errormessage + "\n" + errormessage + "\n" + "dispatchpre_keyno with error: " + item.dispatchpre_keyno,
                //                    buttons: [{
                //                        label: 'OK',
                //                        cssClass: 'btn-danger',
                //                        action: function (dialogItself) {
                //                            dialogItself.close();
                //                        }
                //                    }]
                //                });
                //                errorcode = -1;
                //                break;
                //            }
                //            else {
                //                errormessage = errormessage + String(item.dispatchpre_keyno) + ", "
                //            }
                //        } catch (error) {
                //            console.error(error);
                //            errorcode = -1;
                //            break;
                //        }
                //    }
                //}

                try {
                    let result = await containerShipmentService.ediResendDelayedOrders({ shipmentcontainer_keyno: vm.containers.selected.key, items: selectedDispatchpres });

                    if (result[0].errorcode !== '0') {
                        //modalService.show({
                        //    type: 'danger',
                        //    title: 'Varsel',
                        //    message: result[0].errormessage + "\n" + errormessage + "\n" + "dispatchpre_keyno with error: " + item.dispatchpre_keyno,
                        //    buttons: [{
                        //        label: 'OK',
                        //        cssClass: 'btn-danger',
                        //        action: function (dialogItself) {
                        //            dialogItself.close();
                        //        }
                        //    }]
                        //});
                        errorcode = -1;
                        //break;
                    }
                    else {
                        //errormessage = errormessage + String(item.dispatchpre_keyno) + ", "
                    }
                } catch (error) {
                    console.error(error);
                    errorcode = -1;
                    //break;
                }

                vm.grid.gridfunc.gridProgress(false);

                if (errorcode === 0) {
                    modalService.show({
                        type: 'success',
                        title: 'Varsel',
                        message: 'Done',
                        buttons: [{
                            label: 'OK',
                            cssClass: 'btn-success',
                            action: function (dialogItself) {
                                dialogItself.close();
                            }
                        }]
                    });
                }

                vm.grid.gridfunc.read();
            };

            var directDelivery = function () {
                //var parmItems = getIsSelectedItemsParm();
                //var parmItems2 = getIsSelectedItemsParm('override_deliverytime');
                let items = getIsSelectedItems();
                let miniItems = [];

                angular.forEach(items, function (item) {
                    miniItems.push({
                        dispatchpre_keyno: item.dispatchpre_keyno,
                        override_deliverytime: item.override_deliverytime
                    });
                });

                if (miniItems.length > 0) {
                    //containerShipmentService.ediDirectDelivery({ items: parmItems, items2: parmItems2 }).then(function (result) {
                    containerShipmentService.ediDirectDelivery({ items: miniItems }).then(function (result) {
                        if (result[0].errorcode === '0') {
                            vm.grid.gridfunc.rebind();
                        }
                    });
                }
            }

            var forcedEdiStatus = function () {
                var parmItems = getIsSelectedItemsParm('dispatchpre_keyno');

                if (parmItems.length > 0) {
                    containerShipmentService.ediReturnStatuses().then(function (data) {
                        vm.grid.gridfunc.callPopupTable(data).then(function (value) {
                            if (value !== null) {
                                containerShipmentService.ediForcedEdiStatus({ edifact_code: value.edifact_code, items: parmItems }).then(function (result) {
                                    switch (result[0].errorcode) {
                                        case '0':
                                            vm.grid.gridfunc.rebind();
                                            break;
                                        case '-1':
                                            modalService.show({
                                                type: 'danger',
                                                title: 'ERROR',
                                                message: result[0].errormessage,
                                                buttons: [{
                                                    label: 'OK',
                                                    cssClass: 'btn-danger',
                                                    action: function (modalInstance) {
                                                        modalInstance.close();
                                                    }
                                                }]
                                            });
                                    }
                                });
                            }
                        });
                    });
                }
            }

            var ediTranspReturn = function () {
                // BJS 20221011 - Added shipmentcontainer_keyno as parameter
                //                to containerShipmentService.ediTransporterReturn.
                if (angular.isUndefined(vm.containers.selected.key)) return;
                if (isNaN(vm.containers.selected.key)) return;

                // converts to number
                let scKeyno = +vm.containers.selected.key;

                if (angular.isNumber(scKeyno) !== true) return;
                if (scKeyno <= 0) return;

                let items = getIsSelectedItemsParm('dispatchpre_keyno');

                modalService.show({
                    type: 'warning',
                    title: translations.tt_edi_transporter_return_title,
                    message: translations.tt_edi_transporter_return_message,
                    buttons: [{
                        label: translations.tt_generic_cancel,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                        }
                    }, {
                        label: translations.tt_generic_ok,
                        cssClass: 'btn-danger',
                        action: function (dialogItself) {
                            //containerShipmentService.ediTransporterReturn(scKeyno).then(function (result) {
                            containerShipmentService.ediTransporterReturn({ shipmentcontainer_keyno: scKeyno, items: items }).then(function (result) {
                                //console.dir(result);
                                vm.grid.gridfunc.rebind();
                                //switch (result[0].errorcode) {
                                //    case '0':
                                //        vm.grid.gridfunc.rebind();
                                //        break;
                                //    case '-1':
                                //        modalService.show({
                                //            type: 'danger',
                                //            title: 'ERROR',
                                //            message: result[0].errormessage,
                                //            buttons: [{
                                //                label: 'OK',
                                //                cssClass: 'btn-danger',
                                //                action: function (modalInstance) {
                                //                    modalInstance.close();
                                //                }
                                //            }]
                                //        });
                                //}
                            });

                            dialogItself.close();
                        }
                    }]
                });
            }

            // #endregion BUTTON FUNCTIONS

            // #region LOAD PROCEDURE CALLS

            angular.element(document).ready(function () {
                //var t = translateService.translateBatch(wordlang);
                //console.dir(t);
                containerShipmentService.getRemember(null, vm.containers.rememberId).then(function (value) {
                    if (angular.isDefined(value) && angular.isDefined(value[0]) && angular.isDefined(value[0].variablevalue)) {
                        if (value[0].variablevalue !== '') {
                            var remVal = angular.fromJson(value[0].variablevalue);
                            //console.dir(remVal);
                            if (angular.isObject(remVal)) vm.containers.selected = remVal;
                        }
                        //vm.grid.dataTask.loadData.parameters = { shipmentcontainer_keyno: vm.containers.field };
                    }

                    containerShipmentService.getShipmentContainerKeynameList().then(function (data) {
                        angular.copy(data, vm.containers.list);
                        if (vm.containers.list.length > 0 && vm.containers.selected.key === '') {
                            vm.containers.selected.field = vm.containers.list[0].shipmentcontainer_keyname;
                            vm.containers.selected.key = vm.containers.list[0].shipmentcontainer_keyno;
                            vm.containers.selected.status = vm.containers.list[0].shipmentcontainerstatus_keyno;
                            vm.containers.selected.edistatus = vm.containers.list[0].edistatus;
                            //console.dir(vm.containers.list[0]);
                        }

                        vm.grid.dataTask.loadData.parameters = { shipmentcontainer_keyno: vm.containers.selected.key };

                        vm.gridReady = true;
                    });
                });
            });

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            var rememberFunc = function (parm) {
                //containerShipmentService.remember(null, vm.containers.rememberId, { shipmentcontainer_keyno: vm.containers.key, shipmentcontainer_keyname: , shipmentcontainerstatus_keyno: , });
                containerShipmentService.remember(null, vm.containers.rememberId, vm.containers.selected);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region LOOKUPSEARCH FUNCTIONS

            vm.typeaheadSearch = function (value) {
                return containerShipmentService.searchKeyname(value);
                //return shipmentService.search(value);
            };

            vm.selectItem = function (item, model) {
                if (item?.shipmentcontainer_keyno && item.shipmentcontainer_keyno !== '') {
                    //console.dir(item);
                    vm.containers.selected.field = item.shipmentcontainer_keyname;
                    vm.containers.selected.key = item.shipmentcontainer_keyno;
                    vm.containers.selected.status = item.shipmentcontainerstatus_keyno;
                    vm.containers.selected.edistatus = item.edistatus;
                    vm.grid.dataTask.loadData.parameters = { shipmentcontainer_keyno: vm.containers.selected.key };
                }

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();

                rememberFunc();
            };

            // #endregion LOOKUPSEARCH FUNCTIONS
        }]
    });
})();
