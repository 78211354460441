(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epShipmentCompletionScan', {
        templateUrl: 'views/components/views/epShipmentCompletionScan/epShipmentCompletionScan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', '$stateParams', 'stateService', 'epShipmentCompletionScanService', function ($timeout, $stateParams, stateService, epShipmentCompletionScanService) {
            var vm = this;

            vm.model = {
                scKeyno: $stateParams.shipmentContainerKeyno || '',
                scanValue: '',
                header: {
                    text: ''
                },
                status: {
                    show: false,
                    type: '',
                    text: ''
                }
            };

            vm.gridControls = null;

            var getStatusClass = function (quantityScanned, quantityToScan) {
                if (angular.isNumber(quantityScanned) !== true) {
                    quantityScanned = parseInt(quantityScanned);
                }

                if (angular.isNumber(quantityToScan) !== true) {
                    quantityToScan = parseInt(quantityToScan);
                }

                switch (true) {
                    case quantityToScan > 0 && quantityScanned > 1:
                        return 'bg-yellow';
                    case quantityToScan === 0 && quantityScanned > 0:
                        return 'bg-green';
                    default:
                        return 'bg-default';
                }
            };

            vm.gridOptions = {
                dataTaskKeyno: 1995,
                parameters: {
                    shipmentcontainer_keyno: $stateParams.shipmentContainerKeyno
                },
                rememberId: 'epShipmentCompletionScan',
                gridControlCallback: function (gridControls) { vm.gridControls = gridControls; },
                onSetup: function (schema, fields, columns) {
                    var cols = [
                        columns[0],
                        columns[1],
                        columns[3],
                        columns[2],
                        columns[4],
                        columns[5]
                    ];

                    angular.copy(cols, columns);
                },
                onDataBound: function (e) {
                    var rows = e.sender.content.find('tr');
                    var qtyCartonsIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "QuantityCartons" + "]").index();
                    var qtyScannedIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "QuantityScanned" + "]").index();
                    var qtyToScanIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "QuantityToScan" + "]").index();

                    rows.each(function (_, row) {
                        var dataItem = e.sender.dataItem(row);

                        var statusClass = getStatusClass(dataItem.QuantityScanned, dataItem.QuantityToScan);

                        $(row).children('td:eq(' + qtyCartonsIndex + ')').addClass(statusClass);
                        $(row).children('td:eq(' + qtyCartonsIndex + ')').children('span').addClass(statusClass);
                        $(row).children('td:eq(' + qtyScannedIndex + ')').addClass(statusClass);
                        $(row).children('td:eq(' + qtyScannedIndex + ')').children('span').addClass(statusClass);
                        $(row).children('td:eq(' + qtyToScanIndex + ')').addClass(statusClass);
                        $(row).children('td:eq(' + qtyToScanIndex + ')').children('span').addClass(statusClass);
                    });
                }
            };

            var setHeader = function (header) {
                if (header.set !== true) return;

                if (angular.isDefined(header.info) && header.info.length > 0) {
                    vm.model.header.text = header.info[0].text;
                }
            };

            var setStatus = function (status) {
                vm.model.status.show = status.show;
                vm.model.status.text = status.text;

                if (angular.isDefined(status.color)) {
                    if (status.color === 'success' || status.color === 'info' || status.color === 'warning' || status.color === 'danger') {
                        vm.model.status.type = status.color;
                    } else {
                        vm.model.status.type = status.color === '#33CC33' ? 'success' : 'danger';
                    }
                }

                if (angular.isDefined(status.type)) {
                    vm.model.status.type = status.type;
                }
            };

            var setGrid = function (grid) {
                if (grid.refresh !== true) return;

                if (vm.gridControls !== null) {
                    vm.gridControls.refresh();
                }
            };

            var setDialog = function (dialog) {
                setStatus({
                    type: dialog.type,
                    show: true,
                    text: dialog.text
                });
            };

            var setAll = function (result) {
                if (angular.isDefined(result.grid)) {
                    setGrid(result.grid);
                }

                if (result.clearinput === true) {
                    vm.model.scanValue = '';
                }

                if (angular.isDefined(result.header)) {
                    setHeader(result.header);
                }

                if (angular.isDefined(result.status)) {
                    setStatus(result.status);
                }

                if (angular.isDefined(result.dialog)) {
                    setDialog(result.dialog);
                }
            };

            vm.processing = false;

            vm.onScan = function (value) {
                vm.model.scanValue = value;

                if (value.length === 0) return;

                vm.processing = true;

                epShipmentCompletionScanService.scan(value, vm.model.scKeyno).then(function (result) {
                    setAll(result);

                    vm.processing = false;
                });
            };

            vm.cancel = function () {
                stateService.back();
            };
        }]
    });
})();
