(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("serviceObjectSopService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadServiceObjectSopGet: function (serviceobjectsop_keyno) {
                return p2DataTaskService.call(1843, {
                    serviceobjectsop_keyno: serviceobjectsop_keyno
                });
            },
            loadSopClSelectList: function (sop_keyno) {
                return p2DataTaskService.call(1852, {
                    sop_keyno: sop_keyno
                });
            },
            changedServiceObjectSop: function (prod_id) {
                return p2DataTaskService.call(1845, {
                    prod_id: prod_id
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1844,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
