import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * Directive for using `onOutsideClick` event.
 */
@Directive({
    selector: '[ttOutsideClick]',
})
export class OutsideClickDirective {
    /**
     * Eventemitter for producing `onOutsideClick` for when a click happens outside of the element containing this directive.
     */
    @Output() onOutsideClick = new EventEmitter<PointerEvent>();

    /**
     * Ane ElementRef to ignore or the id of an element to ignore.
     */
    @Input() ignore?: ElementRef | string;

    constructor(private el: ElementRef) {}

    /**
     * Hooks onto all click events on the document which has not stopped propagation.
     * Emits onOutsideClick event if the click happened on any other element than
     * the element with the directive as been clicked.
     *
     * @param event the click event.
     */
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: PointerEvent) {
        let ignoreElement;

        if (this.ignore instanceof ElementRef && this.ignore) {
            ignoreElement = this.ignore.nativeElement;
        } else if (typeof this.ignore === 'string' && this.ignore) {
            ignoreElement = document.getElementById(this.ignore);
        }

        if (event.target && this.isTargetInElement(event.target as HTMLElement, this.el.nativeElement) === false && (!this.ignore || (ignoreElement && this.isTargetInElement(event.target as HTMLElement, ignoreElement) === false))) {
            this.onOutsideClick.emit(event);
        }
    }

    /**
     * Returns true if the event target is inside the "parent" element in question. Returns false if not.
     *
     * @param target the event target to check if is inside the "parent".
     * @param element the "parent" element to check if contains the target element.
     * @returns true if the element contains the target, false if not.
     */
    private isTargetInElement(target: HTMLElement, element: HTMLElement) {
        if (element === target) return true;

        for (let i = 0; i < element?.children?.length; i++) {
            let child = element.children[i] as HTMLElement;
            let found = this.isTargetInElement(target, child);

            if (found) {
                return true;
            }
        }

        return false;
    }
}
