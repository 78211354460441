(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('components', {
        templateUrl: 'views/components/views/components/components.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'componentsService', function ($stateParams, stateService, utilityService, componentsService) {
            let vm = this;
            let prodId = $stateParams.prod_id;

            vm.model = {
                bgCompFunction: {
                    selected: 'EDIT',
                    buttons: [
                        { id: 'EDIT', label: 'gb_compfunction_edit', color: 'primary', onClick: () => setCompFunctionView('EDIT') },
                        { id: 'COMPONENTS', label: 'gb_compfunction_components', color: 'primary', onClick: () => setCompFunctionView('COMPONENTS') }
                    ]
                },
                setting: {},
                itemsListComponent: []
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setCompFunctionView = function (id) {
                vm.model.bgCompFunction.selected = vm.model.setting.gb_compfunction = id;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            let loadSetting = function () {
                componentsService.getComponents({ prod_id: prodId }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    
                    setCompFunctionView(vm.model.setting.gb_compfunction);

                    loadComponents();
                });
            };
            
            let loadComponents = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prod_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.gb_compfunction) !== true) return;

                vm.model.itemsListComponent = [];

                componentsService.listComponents({
                    prod_id: vm.model.setting.prod_id,
                    gb_compfunction: vm.model.setting.gb_compfunction
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListComponent);
                });
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCompFunction = function (value) {
                vm.model.bgCompFunction.selected = value;
                vm.model.setting.gb_compfunction = vm.model.bgCompFunction.selected;

                loadComponents();
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'component':
                        go({
                            prod_id: prodId,
                            comp_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
