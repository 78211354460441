(function () {
    'use strict';

    angular.module('imApp').directive('ttPackery', ['$q', '$timeout', 'ttItemService', 'ttPackeryService', 'utilityService', 'userService', 'eventService', 'responsive', function ($q, $timeout, ttItemService, ttPackeryService, us, userService, eventService, responsive) {
        var directive = {
            restrict: 'A',
            template:
                '<div class="grid col-xs-12 sp-0">' +
                '   <div ng-if="view.visible" tt-item="{{view.ttItemId}}" ng-style="pModel.style" class="clearfix grid-item col-xs-12 col-lg-6 col-xl-4 col-xxl-3" ng-repeat="view in pModel.views" ng-include="view.url"></div>' +
                '</div>',
            link: function ($scope, $element, $attr) {
                $scope.pModel = {
                    views: [],
                    style: {}
                };

                var isInitialized = false;
                var allViews = $scope.$eval($attr.ttPackery);
                var hiddenViews = [];

                var isSingleColumn = function () {
                    if (responsive.current === 'xx')
                        return true;
                    if (responsive.current === 'xs')
                        return true;
                    if (responsive.current === 'sm')
                        return true;
                    if (responsive.current === 'md')
                        return true;

                    return false;
                };

                var setStyle = function () {
                    if (isSingleColumn()) {
                        $scope.pModel.style = {
                            paddingTop: '0',
                            paddingLeft: '0',
                            paddingRight: '0',
                            paddingBottom: '10px'
                        };
                    } else {
                        $scope.pModel.style = {
                            paddingTop: '0',
                            paddingLeft: '3px',
                            paddingRight: '3px',
                            paddingBottom: '6px'
                        };
                    }
                };

                var getIndex = function (collection, id) {
                    for (var i = 0; i < collection.length; i++) {
                        if (collection[i].id !== id)
                            continue;

                        return i;
                    }

                    return -1;
                };

                var groupId = $attr.ttGroupId;
                var groupInfo = null;

                var $grid;

                var updatePackery = function () {
                    if (angular.isUndefined($grid))
                        return;
                    if (isInitialized !== true)
                        return;

                    // ensure packery is initialized
                    if ($.data($grid[0], 'packery')) {
                        //$grid.packery('reloadItems');
                        $timeout(() => $grid.packery('reloadItems').packery('layout'), 100); // JLR 20230629 - timeout added because reloadItems sometimes is called before the DOM is ready, and sizing becomes wrong and causes overlapping elements.

                        $grid.packery();
                    }
                };



                var current = 0;
                var previous = 0;
                var currentPromise = null;

                var updatePackeryOnce = function () {
                    if (current === previous) {
                        currentPromise = null;
                        current = 0;
                        previous = 0;

                        updatePackery();
                    } else {
                        previous = current;

                        if (currentPromise !== null) {
                            $timeout.cancel(currentPromise);
                        }

                        currentPromise = $timeout(updatePackeryOnce, 50);
                    }
                };

                var tryUpdatePackery = function () {
                    current++;

                    updatePackeryOnce();
                };

                var onEnabledChanged = function (data) {
                    var hiddenIndex = getIndex(hiddenViews, data.field_id);

                    if (us.toBoolean(data.enabled) === true) {
                        if (hiddenIndex >= 0) {
                            hiddenViews.splice(hiddenIndex, 1);
                        }
                    } else {
                        if (hiddenIndex < 0) {
                            var viewIndex = getIndex($scope.pModel.views, data.field_id);

                            if (viewIndex >= 0) {
                                var view = angular.copy($scope.pModel.views[viewIndex]);

                                hiddenViews.push(view);
                            }
                        }
                    }

                    tryUpdatePackery();
                };

                var createGrid = function () {
                    $grid = $element.find('.grid').packery({
                        itemSelector: '.grid-item',
                        percentPosition: true,
                        horizontalOrder: true,
                        initLayout: false,
                        transitionDuration: 0
                    });

                    isInitialized = true;

                    tryUpdatePackery();
                };

                var currentShowAll = false;

                var loadGrid = function (showAll) {
                    showAll = showAll || currentShowAll;

                    currentShowAll = showAll;

                    var promises = [];

                    promises.push(ttPackeryService.list(groupId));
                    promises.push(ttItemService.getGroupInfo(groupId));

                    $q.all(promises).then(function (response) {
                        var pv = [];

                        angular.copy(allViews, pv);

                        var views = response[0];

                        groupInfo = response[1];

                        angular.forEach(pv, function (view) {
                            view.ready = false;
                        });

                        for (var i = 0; i < views.length; i++) {
                            var view = views[i];

                            if (view.index < 0)
                                continue;
                            if (view.index >= pv.length)
                                continue;
                            if (pv[view.index].id === view.item_id)
                                continue;

                            var idx = getIndex(pv, view.item_id);

                            var bi = pv.splice(idx, 1);

                            if (angular.isArray(bi) !== true)
                                continue;
                            if (bi.length < 1)
                                continue;

                            pv.splice(view.index, 0, bi[0]);

                            view.visible = us.toBoolean(groupInfo[view.item_id].info.enabled);
                            view.ready = true;
                        }

                        var hidden = [];

                        angular.forEach(pv, function (view) {
                            if (angular.isUndefined(groupInfo[view.id])) {
                                groupInfo[view.id] = {
                                    info: {
                                        enabled: '1',
                                        field_id: view.id,
                                        group_id: groupId,
                                        keyno: 0,
                                        sizes: '',
                                        ttItemId: groupId + '.' + view.id,
                                        usergroups: []
                                    },
                                    onChange: {}
                                };
                            }

                            var info = groupInfo[view.id].info;
            
                            if (showAll === true) {
                                view.visible = true;
                            } else {
                                if (view.ready !== true) {
                                    view.visible = us.toBoolean(info.enabled);
                                }

                                if (info.usergroups.length > 0) {
                                    var visible = false;

                                    for (var i = 0; i < info.usergroups.length; i++) {
                                        if (angular.isUndefined(userService.userGroups['_' + info.usergroups[i].usergroup_keyno]))
                                            continue;

                                        visible = true;
                                        break;
                                    }

                                    view.visible = visible;
                                }

                                if (info.sizes.length > 0) {
                                    view.visible = false;

                                    var parts = info.sizes.split(' ');

                                    for (var j = 0; j < parts.length; j++) {
                                        if (parts[j] !== responsive.current)
                                            continue;

                                        view.visible = true;

                                        break;
                                    }
                                }

                                if (view.visible !== true) {
                                    hidden.push(view);
                                }
                            }

                            view.ttItemId = groupId + '.' + view.id;

                            delete view.ready;
                        });

                        angular.forEach(hidden, function (view) {
                            var idx = getIndex(pv, view.id);

                            var hv = pv.splice(idx, 1)[0];

                            var hvIndex = getIndex(hiddenViews, hv.id);

                            if (hvIndex < 0) {
                                hiddenViews.push(hv);
                            }
                        });

                        angular.copy(pv, $scope.pModel.views);

                        createGrid();
                    });
                };

                var currentLoad = 0;
                var previousLoad = 0;
                var showAllOnLoad = false;

                var loadGridOnce = function () {
                    if (currentLoad === 0 && previousLoad === 0)
                        return;

                    if (currentLoad === previousLoad) {
                        loadGrid(showAllOnLoad);

                        currentLoad = 0;
                        previousLoad = 0;
                    } else {
                        previousLoad = currentLoad;

                        $timeout(loadGridOnce, 50);
                    }
                };

                var tryLoadGrid = function (showAll) {
                    showAllOnLoad = showAll || currentShowAll;

                    currentLoad++;

                    loadGridOnce();

                    setStyle();
                };

                tryLoadGrid();

                var onActiveChanged = function (data) {
                    if (data.info.group_id === groupId) {
                        if (us.toBoolean(data.active)) {
                            if ($scope.pModel.views.length !== allViews.length) {
                                // add hidden views
                                angular.forEach(hiddenViews, function (view) {
                                    view.visible = true;

                                    var viewIndex = getIndex($scope.pModel.views, view.id);

                                    if (viewIndex < 0) {
                                        $scope.pModel.views.push(view);
                                    }
                                });
                            }
                        } else {
                            // remove hidden views
                            angular.forEach(hiddenViews, function (view) {
                                var index = getIndex($scope.pModel.views, view.id);

                                if (index >= 0) {
                                    $scope.pModel.views.splice(index, 1);
                                }
                            });
                        }
                    }

                    tryUpdatePackery();
                };

                var onMoveItem = function (data) {
                    if (data.groupId !== groupId)
                        return;

                    var index = getIndex($scope.pModel.views, data.itemId);

                    var getNextIndex = function (idx) {
                        idx++;

                        return idx === $scope.pModel.views.length ? 0 : idx;
                    };
                    var getPreviousIndex = function (idx) {
                        idx--;

                        return idx < 0 ? $scope.pModel.views.length - 1 : idx;
                    };

                    var newIndex = data.direction === 'up'
                        ? getPreviousIndex(index)
                        : getNextIndex(index);

                    var item = $scope.pModel.views.splice(index, 1);

                    if (item.length < 1)
                        return;

                    item = item[0];

                    $scope.pModel.views.splice(newIndex, 0, item);

                    tryUpdatePackery();

                    angular.forEach($scope.pModel.views, function (view) {
                        var index = getIndex($scope.pModel.views, view.id);

                        ttPackeryService.update(data.groupId, view.id, index);
                    });
                };

                var onDestroy = [];

                onDestroy.push(eventService.on('event:responsive-breakpoint', function (data) {
                    tryLoadGrid();
                }));

                onDestroy.push(eventService.on('element:ready', tryUpdatePackery));
                onDestroy.push(eventService.on('translate:completed', tryUpdatePackery));
                onDestroy.push(eventService.on('ttItem:toggledEnabled', onEnabledChanged));
                onDestroy.push(eventService.on('ttItem:toggledActive', onActiveChanged));
                onDestroy.push(eventService.on('packery:moveItem', onMoveItem));

                $scope.$on('$destroy', function () {
                    angular.forEach(onDestroy, function (func) {
                        if (angular.isFunction(func) === true) {
                            func();
                        }
                    })
                });
            }
        };

        return directive;
    }]);
})();
