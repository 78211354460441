(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("stocksOnLocationService", ['p2DataTaskService', function (p2DataTaskService) {
        let service = {
            loadStocksOnLocationGet: function (stocklocation_keyno) {
                return p2DataTaskService.call(1070, {
                    stocklocation_keyno: stocklocation_keyno
                });
            },
			loadStocksOnLocationList: function (stocklocation_keyno) {
				return p2DataTaskService.call(1069, {
					stocklocation_keyno: stocklocation_keyno
				});
			}
        };

        return service;
    }]);
})();