(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('docArticle', {
        templateUrl: 'views/components/views/docArticle/docArticle.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'docArticleService', function ($stateParams, stateService, base64, docArticleService) {

    		// ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
    		vm.model = {
    			docArticleKeyNo: $stateParams.docarticle_keyno,
    			bgDocArticleMain: {
    				item_id: '',
    				item_name: '',
    				item_default: '0',
    				item_selected: '0',
    				records: [
    					{ item_id: '0', item_name: 'gb_docarticle_main', item_func: 'MAIN' },
    					{ item_id: '1', item_name: 'gb_docarticle_admin', item_func: 'ADMIN' }
    				]
    			},
                edit: {
                    html_content_base64: '',
                },
                isHtml: true,
    			itemsListDocArticles: []
    		};
            
    		// ## LOAD PROCEDURE CALLS ##
            
    		docArticleService.loadDocArticleGet(vm.model.docArticleKeyNo).then(function (data) {
    			vm.model.edit = angular.copy(data[0]);
                vm.model.edit.html_content_base64 = base64.urldecode(data[0].html_content_base64);
                //$sce.trustAsHtml(vm.model.edit.html_content_base64);
                //console.log(base64.urldecode(data[0].html_content_base64));
                //console.log(vm.model.edit.html_content_base64);
                //document.querySelector('#test').innerHTML = vm.model.edit.html_content_base64;
    			angular.forEach(vm.model.bgDocArticleMain.records, function (data) {
    				if (angular.isDefined(vm.model.edit.gb_docarticle_main) && angular.isDefined(data)) {
    					if (vm.model.edit.gb_docarticle_main === data.item_func) {
    						vm.model.bgDocArticleMain.item_default = data.item_id;
    					}
    				}
    			});
    		});
            
    		docArticleService.loadDocArticleList(vm.model.docArticleKeyNo).then(function (data) {
    			angular.copy(data, vm.model.itemsListDocArticles);
    		});
            
    		// ## BUTTON GROUP FUNCTIONS ##
            
    		vm.selectBgDocArticleMain = function (item) {
    			vm.model.bgDocArticleMain.item_selected = item;
    			vm.model.edit.gb_docarticle_main = vm.model.bgDocArticleMain.item_selected;
    		};
            
    		// ## DIRECTION BUTTON FUNCTIONS ##
            
    		vm.goTo = function (state) {
    			var go = function (parms) {
    				stateService.go(state, parms);
    			};
                
    			switch (state) {
    				case 'docarticleedit':
    					go({ docarticle_keyno: vm.model.edit.docarticle_keyno });
    					break;
    				case 'docarticleporus':
    					go({ docarticle_keyno: vm.model.edit.docarticle_keyno });
    					break;
    				case 'docarticletopusergroups':
    					go({ docarticle_keyno_top: vm.model.edit.docarticle_keyno_top });
    					break;
    			}
    		};
            
    		vm.createNew = function () {
    			var variableNameDocArticleKeyNo = 'docarticle_keyno_belongto';
                
    			if (angular.isUndefined(vm.model.edit.docarticle_keyno) === true) return;
    			if (vm.model.edit.docarticle_keyno === null) return;
    			if (vm.model.edit.docarticle_keyno.trim().length < 1) return;
                
    			docArticleService.remember(variableNameDocArticleKeyNo, vm.model.edit.docarticle_keyno).then(function (data) {
    				stateService.go('docarticleedit', {
    					docarticle_keyno: '0'
    				});
    			});
    		};
    	}]
    });
})();