(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('activityTypeActStatus', {
        templateUrl: 'views/components/views/activityTypeActStatus/activityTypeActStatus.template.html?v=' + module.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'activityTypeActStatusService', function ($stateParams, stateService, activityTypeActStatusService) {
            const vm = this;
            let activityTypeActStatusKeyno = $stateParams.activitytypeactstatus_keyno;

			vm.model = {
                activityTypeNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('activitytype', { activitytype_no: vm.model.edit.activitytype_no }) }
                ],
                activityStatusNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('actstatusmenu', { actstatus_keyno: vm.model.edit.actstatus_keyno }) }
                ],
                edit: {},
				lockedSave: false
			};

            let loadEdit = function () {
                activityTypeActStatusService.getSetting({ activitytypeactstatus_keyno: activityTypeActStatusKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

			vm.saveChanges = function () {
				vm.model.lockedSave = true;

                activityTypeActStatusService.saveObj(vm.model.edit).then(function () {
					vm.model.lockedSave = false;
					stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
		}]
	});
})();
