(function () {
    'use strict';

    angular.module("imApp").factory("pickJobGrpBatchesService", ['$ihttp', function ($ihttp) {
        var service = {
            getPickJobGroupBatches: function (parms) {
                return $ihttp.post({
                    method: 2180,
                    parameters: parms || {}
                });
            },
            listStockPlaces: function (parms) {
                return $ihttp.post({
                    method: 2181,
                    parameters: parms || {}
                });
            },
            listPickJobGroups: function (parms) {
                return $ihttp.post({
                    method: 2234,
                    parameters: parms || {}
                });
            },
            updateObj: function (setting) {
                return $ihttp.post({
                    method: 2182,
                    parameters: setting
                });
            },
            createObj: function (setting) {
                return $ihttp.post({
                    method: 2237,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();