(function () {
    'use strict';

    angular.module("imApp").factory("orderHeadCapJobService", ['$ihttp', function ($ihttp) {
        var service = {
            getOrderHeadCapJob: function (orderInternalNo) {
                return $ihttp.post({
                    method: 2071,
                    parameters: {
                        order_internal_no: orderInternalNo
                    }
                });
            },
            deletePrevious: function (parms) {
                return $ihttp.post({
                    method: 2076,
                    parameters: parms || {}
                });
            },
            addProduction: function (orderLineKeyNo, capJobKeyNo, lastDtProductionFinish, direction) {
                return $ihttp.post({
                    method: 2073,
                    parameters: {
                        orderline_keyno: orderLineKeyNo,
                        capjob_keyno: capJobKeyNo,
                        lastdt_production_finish: lastDtProductionFinish,
                        direction: direction
                    }
                });
            }
        };

        return service;
    }]);
})();