(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('deliveryMethodBusinessco', {
        templateUrl: 'views/components/views/deliveryMethodBusinessco/deliveryMethodBusinessco.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'deliveryMethodBusinesscoService', function($stateParams, deliveryMethodBusinesscoService) {
            var vm = this;
        	vm.deliverymethod_no = $stateParams.deliverymethod_no;
        	vm.businesscos = [];
            
            var updateList = function () {
                deliveryMethodBusinesscoService.businesscoList(vm.deliverymethod_no).then(function () {
                    vm.businesscos = deliveryMethodBusinesscoService.businesscos;
                });
            };

        	updateList();
        	
            vm.toggle_item = function (item) {
                if (item.item_agreement_id != "") {
                    var active = "1";

                    if (item.item_active != "0") {
                        active = "0";
                    }

                    deliveryMethodBusinesscoService.businesscoToggle(item.item_id, active).then(function () {
                        vm.businesscos = [];
                        updateList();
                    });
                }
            };
        }]
    });
})();