(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderToConsignments', {
        templateUrl: 'views/components/views/orderToConsignments/orderToConsignments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', function ($stateParams) {
            var vm = this;
            
            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                custactNoConsignmentPlan: $stateParams.custact_no_consignmentplan
            };
        }]
    });
})();