(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('timeRegisterNewJobConf', {
        templateUrl: 'views/components/views/trNewJobConf/trNewJobConf.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'trNewJobConfService', 'timeRegisterService', function ($stateParams, stateService, utilityService, translateService, modalService, trNewJobConfService, timeRegisterService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let labourNo = $stateParams.labour_no;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                argtype: argType,
                offDutyCodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('offdutycode_keyno') }
                ],
                edit: {},
                selectListOffDutyCodes: [],
                itemsListExtraPowTimes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE
            
            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                trNewJobConfService.getEdit({
                    labour_no: labourNo,
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    loadExtraPowTimes();

                    if (vm.model.edit.missingexit === '1') vm.model.edit.overtime_hours = '0.00';
                    if (vm.model.edit.stamped === '1') retrieveOvertime();
                });
            };

            let loadSelectOffDutyCodes = function () {
                trNewJobConfService.listSelectOffDutyCodes().then(function (result) {
                    angular.copy(result, vm.model.selectListOffDutyCodes);
                });
            };

            let retrieveOvertime = function () {
                if (utilityService.validateParmsValue(vm.model.edit.labour_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.old_job_start) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.timestamp_out_corrected) !== true) return;

                return trNewJobConfService.getOvertime({
                    labour_no: vm.model.edit.labour_no,
                    dt_start: vm.model.edit.old_job_start,
                    dt_end: vm.model.edit.timestamp_out_corrected
                }).then(function (response) {
                    if (response[0].overtime_hours !== '0.00') vm.model.edit.overtime_hours = response[0].overtime_hours;
                });
            };

            let loadExtraPowTimes = function () {
                return timeRegisterService.listExtraPowTimes(vm.model.edit).then(function (list) {
                    angular.copy(list, vm.model.itemsListExtraPowTimes);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            var offDutyCodeChanged = function () {
                var i;

                for (i = 0; i < vm.model.selectListOffDutyCodes.length; i++) {
                    if (angular.isDefined(vm.model.selectListOffDutyCodes[i].item_id) && vm.model.selectListOffDutyCodes[i].item_id !== '0') {
                        if (vm.model.edit.offdutycode_keyno === vm.model.selectListOffDutyCodes[i].item_id) {
                            vm.model.edit.offdutycode_specification_required = vm.model.selectListOffDutyCodes[i].note_required;
                            return;
                        }
                    } else {
                        vm.model.edit.offdutycode_specification_required = '0';
                    }
                }
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'offdutycode_keyno':
                        vm.model.edit.offdutycode_keyno = '0';

                        if (vm.model.edit.offdutycode_keyno === '0') offDutyCodeChanged();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'labourjobnote':
                        go({ labour_no: vm.model.edit.labour_no });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let mergeObj = {};
                mergeObj = angular.copy(vm.model.edit);
                mergeObj.powtimeextras = vm.model.itemsListExtraPowTimes;

                trNewJobConfService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back('/' + 'trstart');
                        vm.model.lockedSave = false;
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region CANCEL BUTTON FUNCTION

            vm.cancelToTrStart = function () {
                stateService.back();
            };

            // #endregion CANCEL BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'timestamp_out_corrected':
                        if (utilityService.validateWatchValue(value, vm.model.edit.timestamp_out_corrected) !== true) return;

                        vm.model.edit.timestamp_out_corrected = value;

                        if (utilityService.validateParmsValue(vm.model.edit.timestamp_out_corrected) !== true) return;

                        if (vm.model.edit.stamped === '1') retrieveOvertime();
                        break;
                    case 'offdutycode_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.offdutycode_keyno) !== true) return;

                        vm.model.edit.offdutycode_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.offdutycode_keyno) !== true) return;

                        if (vm.model.edit.offdutycode_keyno !== '0') offDutyCodeChanged();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadSelectOffDutyCodes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
