/**
 * The HTML5 autofocus property can be finicky when it comes to dynamically loaded templates and such with AngularJS. 
 * This replaces the old one with one for AngularJS.
 *
 * Usage:
 * <input type="text" autofocus>
 * 
 */
(function () {
    'use strict';
 
    angular.module('imApp')
    .directive('autofocus', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            link: function ($scope, $element) {
                $timeout(function () {
                    $element[0].focus();
                });
            }
        }
    }]);
})();