(function () {
    'use strict';

    angular.module("imApp").factory("posComponentService", ['$ihttp', function ($ihttp) {
        let service = {
            getPos: function (parms) {
                return $ihttp.post({
                    method: 1922,
                    parameters: parms || {}
                });
            },
            deleteObj: function (setting) {
                return $ihttp.post({
                    method: 1926,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
