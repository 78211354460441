(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("specialCustProdCalcService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_special_vp_cust_calc_list: {
                prod_id: '',
                prod_name: '',
                records: []
            },
            p2_special_vp_cust_calc_get: {
                price: '',
                discount: '',
                sold_freight: '',
                bonus: '',
                weight: '',
                price_pr_kg_label: '',
                postplace_id: ''
            },
            p2_special_vp_calc_customers_get: {
                company_name: '',
                frakt_pr_m3: '',
                price_with_discount: '',
                price_after_bonus: '',
                price_real_freight: '',
                price_pr_kilo: '',
                records: []
            },
            loadP2SpecialVpCustCalcList: function () {
                var deferred = $q.defer();

                var parms_p2_special_vp_cust_calc_list = {
                    method: 296
                };

                //console.log('parms_p2_special_vp_cust_calc_list');
                //console.log(angular.toJson(parms_p2_special_vp_cust_calc_list));

                // Prosedyre kall sp_ws_p2_special_vp_cust_calc_list
                $ihttp.post(parms_p2_special_vp_cust_calc_list).then(function (data) {
                    //console.log('sp_ws_p2_special_vp_cust_calc_list');
                    //console.log(angular.toJson(data));

                    angular.copy(data, service.p2_special_vp_cust_calc_list.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            getP2SpecialVpCustCalc: function (cust_no, prod_id) {
                var deferred = $q.defer();

                var parms_p2_special_vp_cust_calc_get = {
                    method: 297,
                    parameters: {
                        cust_no: cust_no,
                        prod_id: prod_id
                    }
                };

                //console.log('parms_p2_special_vp_cust_calc_get');
                //console.log(angular.toJson(parms_p2_special_vp_cust_calc_get));

                // Prosedyre kall sp_ws_p2_special_vp_cust_calc_get
                $ihttp.post(parms_p2_special_vp_cust_calc_get).then(function (data) {
                    //console.log('sp_ws_p2_special_vp_cust_calc_get');
                    //console.log(angular.toJson(data));

                    service.p2_special_vp_cust_calc_get = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            getP2SpecialVpCalcCustomers: function (cust_no, prod_id, price, discount, bonus, sold_freight, weight, postplace_id) {
                var deferred = $q.defer();

                var parms_p2_special_vp_calc_customers_get = {
                    method: 298,
                    parameters: {
                        cust_no: cust_no,
                        prod_id: prod_id,
                        price: price,
                        discount: discount,
                        bonus: bonus,
                        sold_freight: sold_freight,
                        weight: weight,
                        postplace_id: postplace_id
                    }
                };

                //console.log('parms_p2_special_vp_calc_customers_get');
                //console.log(angular.toJson(parms_p2_special_vp_calc_customers_get));

                // Prosedyre kall sp_ws_p2_special_vp_calc_customers_get
                $ihttp.post(parms_p2_special_vp_calc_customers_get).then(function (data) {
                    //console.log('sp_ws_p2_special_vp_calc_customers_get');
                    //console.log(angular.toJson(data));

                    angular.copy(data, service.p2_special_vp_calc_customers_get.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();