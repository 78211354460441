(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDeliveryList', {
        templateUrl: 'views/components/views/batchDeliveryList/batchDeliveryList.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['rememberService', 'utilityService', 'batchDeliveryListService', 'stateService', function (rememberService, utilityService, batchDeliveryListService, stateService) {

            var vm = this;

            vm.model = {
                businessCompanies: [],
                stockplaces: [],
                stockLocations: [],
                batchDeliveryRadioButtons: {
                    selected: 'batchdelivery_in_progress',
                    buttons: [
                        { id: 'batchdelivery_in_progress', label: 'batchdelivery_in_progress', color: 'primary', onClick: retrieve },
                        { id: 'batchdelivery_verified', label: 'batchdelivery_verified', color: 'primary', onClick: retrieve },
                        { id: 'batchdelivery_finished', label: 'batchdelivery_finished', color: 'primary', onClick: retrieve },
                    ]
                },
                batchDeliveryList: [],
                p2WBatchDeliveryListGet: {},
                getPortalUserVariable: {},
            };

            // #region LOAD FUNCTIONS

            async function loadStockLocations() {
                if (vm.model.p2WBatchDeliveryListGet?.stockplace_id) {
                    vm.model.stockLocations = await batchDeliveryListService.loadStockLocationList('1', vm.model.p2WBatchDeliveryListGet.stockplace_id, '1');
                };
            };

            async function loadBusinessCoList() {
                return vm.model.businessCompanies = await batchDeliveryListService.genericFunction(104, {});
            }

            async function loadStockPlaceList() {
                if (vm.model.p2WBatchDeliveryListGet.businessco_no !== '' && vm.model.p2WBatchDeliveryListGet.businessco_no) {
                    vm.model.stockplaces = await batchDeliveryListService.genericFunction(622, { businessco_no: vm.model.p2WBatchDeliveryListGet.businessco_no });
                }
            }

            async function retrieve() {
                if (vm.model.batchDeliveryRadioButtons.selected && vm.model.p2WBatchDeliveryListGet.businessco_no && vm.model.p2WBatchDeliveryListGet.stockplace_id && vm.model.p2WBatchDeliveryListGet.stocklocation_keyno) {
                    vm.model.batchDeliveryList = await batchDeliveryListService.loadP2BatchDeliveryList(vm.model.batchDeliveryRadioButtons.selected, vm.model.p2WBatchDeliveryListGet.businessco_no, vm.model.p2WBatchDeliveryListGet.stockplace_id, vm.model.p2WBatchDeliveryListGet.stocklocation_keyno);
                }
            }

            function loadPortalUserVariableGet() {
                let variablename = `batchdelivery_list.stocklocation_keyno.stockplace_id=${vm.model.p2WBatchDeliveryListGet.stockplace_id}`;

                if (vm.model.p2WBatchDeliveryListGet.stockplace_id) {
                    batchDeliveryListService.loadPortalUserVariableGet(variablename).then(function (data) {
                        vm.model.getPortalUserVariable = angular.copy(data[0]);

                        if (vm.model.getPortalUserVariable.variablevalue === '') {
                            vm.model.p2WBatchDeliveryListGet.stocklocation_keyno = '0';
                        } else {
                            vm.model.p2WBatchDeliveryListGet.stocklocation_keyno = vm.model.getPortalUserVariable.variablevalue;
                        }
                    });
                }
            };

            // #endregion LOAD FUNCTIONS

            var rememberStockPlaceId = function () {
                var variableNameStockPlaceId = 'batchdelivery_list.stockplace_id.' + vm.model.p2WBatchDeliveryListGet.businessco_no;

                if (utilityService.validateParmsValue(variableNameStockPlaceId) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WBatchDeliveryListGet.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.p2WBatchDeliveryListGet.stockplace_id) !== true) return;

                rememberService.remember(variableNameStockPlaceId, vm.model.p2WBatchDeliveryListGet.stockplace_id);
            };

            var setPortalUserVariableStockPlaceId = function () {
                var variableNameStockPlaceId = 'batchdelivery_list.stocklocation_keyno.stockplace_id=' + vm.model.p2WBatchDeliveryListGet.stockplace_id;

                if (angular.isDefined(variableNameStockPlaceId) && angular.isDefined(vm.model.p2WBatchDeliveryListGet.stocklocation_keyno) && (vm.model.p2WBatchDeliveryListGet.stocklocation_keyno !== null || vm.model.p2WBatchDeliveryListGet.stocklocation_keyno !== '') && initLoadDone === true) {
                    batchDeliveryListService.setPortalUserVariable(variableNameStockPlaceId, vm.model.p2WBatchDeliveryListGet.stocklocation_keyno);
                }
            };

            vm.goToStockTransferReception = function () {
                stateService.go('stocktransferreception', {
                    stocklocation_keyno: vm.model.p2WBatchDeliveryListGet.stocklocation_keyno
                });
            };

            vm.createP2BatchDelivery = function () {
                batchDeliveryListService.createP2BatchDelivery(vm.model.p2WBatchDeliveryListGet.businessco_no, vm.model.p2WBatchDeliveryListGet.stockplace_id, vm.model.p2WBatchDeliveryListGet.stocklocation_keyno).then(function () {
                    vm.p2_batchdelivery_create = batchDeliveryListService.p2_batchdelivery_create;

                    stateService.go(vm.p2_batchdelivery_create.webpagename, vm.p2_batchdelivery_create.parms);
                });
            };

            vm.onBusinessCompanyChanged = function (value) {
                vm.model.p2WBatchDeliveryListGet.businessco_no = value;
                loadStockPlaceList();
            }

            vm.onStockPlaceChanged = function (value) {
                vm.model.p2WBatchDeliveryListGet.stockplace_id = value;

                retrieve();
                loadStockLocations();
                loadPortalUserVariableGet();
                rememberStockPlaceId();
            }

            vm.onStockLocationChanged = function (value) {
                vm.model.p2WBatchDeliveryListGet.stocklocation_keyno = value;
                retrieve();
                setPortalUserVariableStockPlaceId();
            }

            // #region ANGULAR LIFECYCLE

            vm.$onInit = async function () {
                loadBusinessCoList();
                vm.model.p2WBatchDeliveryListGet = (await batchDeliveryListService.genericFunction(744, {}))[0];
                vm.onBusinessCompanyChanged(vm.model.p2WBatchDeliveryListGet.businessco_no);
                loadStockLocations();
                loadStockPlaceList();
                retrieve();
            }

            // #endregion ANGULAR LIFECYCLE
        }]
    });
})();
