import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';
import { GraphMailService } from '../../../../../app/modules/microsoft-graph/services/graph-mail.service';
import { applyTheme } from '@microsoft/mgt-components';

(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('email', {
        templateUrl: 'views/components/views/email/email.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$scope', 'stateService', 'base64', 'modalService', 'layoutService', 'translateService', 'emailService', 'graphAuthService', 'graphMailService', 'ttGridFactory', 'typeaheadService', function ($scope, stateService, base64, modalService, layoutService, translateService, emailService, graphAuthService, graphMailService, ttGridFactory, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            vm.version = module.version;
            vm.translations = {
                error: '',
                ok: '',
                email_search_all_folders: '',
                email_search_current_folder: '',
                w_email_mark_as_spam: '',
                w_email_delete: '',
                w_email_move_to_archive: '',
            };

            vm.mediaQueries = {};

            $scope.$on('ttTouchListModeChanged', function (event, newValue) {
                vm.model.listmode = newValue;
            })

            vm.sidePanel = {
                open: false,
            }

            vm.openSidePanel = function () {
                vm.sidePanel.open = true;
            }

            vm.toggleSearhMode = function () {
                vm.model.searchMode = !vm.model.searchMode;

                if (vm.model.searchMode === false) {
                    vm.onEmailSearchGlobalChanged('');
                }
            }

            vm.touchList = {
                buttons: [
                    { id: 'createVouchers', text: 'w_email_create_voucher', type: 'primary', icon: 'fas fa-arrow-alt-to-bottom', onClick: createVouchersTouch },
                    { id: 'delete', text: 'email_delete_selected', type: 'danger', icon: 'fad fa-trash', onClick: deleteEmailsTouch },
                ]
            }

            async function createVouchersTouch(items) {
                for (let email of items) {
                    let attachments = (await graphMailService.getAttachmentsFromMessageId(email.id, vm.model.edit.current_mailbox))?.value ?? [];
                    email = { ...email, email_body: base64.urlencode(email.email_body), heading: base64.urlencode(email.heading), custact_note_new: base64.urlencode(email.custact_note_new), attachments: attachments.filter((attachment) => attachment.isInline === false), create_voucher: '1' };

                    let data = (await emailService.saveEmailItem(email))[0];

                    if (data.errorcode !== '0') {
                        _showWarningModal(data.errormessage);
                    } else if (vm.model.edit.auto_archieve_folder_id !== '0' && vm.model.edit.auto_archieve_folder_id && vm.model.edit.selected_folder_id !== vm.model.edit.auto_archieve_folder_id) {
                        await graphMailService.moveMessageToFolder(email.id, vm.model.edit.auto_archieve_folder_id, vm.model.edit.current_mailbox);
                        vm.model.emails = vm.model.emails.filter((item) => item.id !== email.id);
                    }
                }
            }

            function deleteEmailsTouch(items) {
                items.forEach((email) => deleteMessage(email))
            }

            vm.openEmail = function (item) {
                setSelectedEmail(item);
            }

            vm.goBackToEmails = function () {
                clearSelectedEmail();
            }

            vm.model = {
                edit: {},
                darkmode: false,
                false: false,
                ready: false,
                saveLock: false,
                retrieving: false,
                iframeLoaded: false,
                listmode: 'default',
                inferenceTabs: {
                    selected: 'FOCUSED',
                    buttons: [
                        { id: 'FOCUSED', label: 'w_email_inference_focused', onClick: () => _selectInference('FOCUSED') },
                        { id: 'OTHER', label: 'w_email_inference_other', onClick: () => _selectInference('OTHER') },
                        { id: 'ALL', label: 'w_email_inference_all', onClick: () => _selectInference('ALL') },
                    ]
                },
                emailViewButtons: [
                    { id: 'open', title: 'open_in_new_tab', icon: 'fas fa-external-link-alt', onClick: () => { vm.openWeblink() } }
                ],
                autoArchiveButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.onAutoArchieveFolderChanged() }
                ],
                globalSearchButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.onEmailSearchGlobalChanged('') }
                ],
                folderSearchButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.onEmailSearchFolderChanged('') }
                ],
                fromCustomerButtons: [
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoCustomer(vm.model.selectedEmail.from_cust_no) }
                ],
                customerButtons: [
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoCustomer(vm.model.selectedEmail.cust_no) }
                ],
                personButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => clearPerson() }
                ],
                projectButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.onProjectSelected() },
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoProjectMenu() }
                ],
                emptyProjectButtons: [
                    { id: 'all', icon: 'fas fa-bars', type: 'primary', color: 'primary', onClick: () => gotoProjects() },
                ],
                projectSearchButtons: [
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoProjectMenu() }
                ],
                emptyProjectSearchButtons: [
                    { id: 'all', icon: 'fas fa-bars', type: 'primary', color: 'primary', onClick: () => gotoProjects() },
                ],
                prosessButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.onProsessSelected() }
                ],
                custactButtons: [
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoWorkDetails() }
                ],
                orderButtons: [
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoOrheadHead() }
                ],
                purchaseButtons: [
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => gotoPurchHead() }
                ],
                mailViewBoxes: {
                    boxes: [
                        { item_id: uuid(), type: 'v', size: '10', depth: 0 },
                        { item_id: uuid(), type: 'v', size: '50', depth: 0 },
                        { item_id: uuid(), type: 'v', size: '25', depth: 0 },
                        { item_id: uuid(), type: 'v', size: '15', depth: 0 },
                    ],
                },
                emails: [],
                selectedEmail: null,
                treeListEmailFolders: [],
                mailboxes: [],
                customerPersons: [],
                custactMembers: [],
                projects: [],
                projectProsesses: [],
                activityTypes: [],
                businessCompanies: [],
                custacts: [],
                searchFolders: [
                    { item_name: vm.translations.email_search_all_folders, item_id: '0' },
                    { item_name: vm.translations.email_search_current_folder, item_id: '1' },
                ],
                emailAmountSizes: [
                    { item_id: '25', item_name: '25' },
                    { item_id: '50', item_name: '50' },
                    { item_id: '75', item_name: '75' },
                    { item_id: '100', item_name: '100' },
                    { item_id: '200', item_name: '200' },
                    { item_id: '500', item_name: '500' },
                    { item_id: '1000', item_name: '1000' },
                ],
                searchMode: false,
                loading: false,
            };

            vm.style = {
                subject: {},
                iframeBody: {},
            }

            function _selectInference(id) {
                vm.model.inferenceTabs.selected = id;
                _setGridDataFromEmails();
            }

            vm.onLocalSearchSettingChanged = async function (value) {
                vm.model.edit.local_search = value;
                _rememberLocalSearchSetting();
                return _setGridDataFromEmails();
            }

            let globalSearchTimer = null;

            vm.onEmailSearchGlobalChanged = async function (value) {
                vm.model.edit.email_search_global = value;

                _rememberEmailSearch();

                if (globalSearchTimer) {
                    clearTimeout(globalSearchTimer);
                    globalSearchTimer = null;
                }

                if (!value) {
                    vm.model.edit.local_search = '0';
                }

                globalSearchTimer = setTimeout(() => {
                    _setGridDataFromEmails(vm.model.edit.searchtext)
                    _rememberEmailSearch();
                }, 500);
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region GRID-STUFF

            vm.grid = new ttGridFactory({ rememberId: 'w_email_grid' })
                .setLoadDataFunction({ method: 2945, parameters: {} })
                //.onReady(_setGridDataFromEmails)
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([
                    { name: 'refreshEmails', func: () => refreshEmails(), icon: 'fas fa-sync', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true },
                    { name: 'createVouchers', text: 'w_email_create_voucher', func: () => createVouchers(getSelectedRows()), icon: 'fas fa-arrow-alt-to-bottom', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: createVouchersDisabled },
                    { name: 'deleteSelected', text: 'email_delete_selected', func: deleteSelectedRows, icon: 'fas fa-trash', cssClass: 'tt-button tt-button--danger im-grid-btn-xs-r', translate: true, disabled: () => getSelectedRows().length === 0 }
                ])
                .setConfig({ fixedHeader: false })
                .setKendoConfig({
                    height: 'calc(100% - 28px)',
                    fixedHeader: false,
                    selectable: {
                        mode: "row",
                        cellAggregates: false,
                        checkboxSelection: false
                    }
                })
                .setSpecialFunc({ newTab: true })
                .setOptionFunc(optionfunc);

            function optionfunc(data) {
                if (data?.data?.func === 'CellClickHandler' || data?.data?.func === 'CheckboxBoxClick') {
                    let row = data?.data?.clickedCell?.row ? data.data.clickedCell.row : data.data.row;
                    let dataItem = data?.data?.clickedCell?.dataItem ? data.data.clickedCell.dataItem : data.data.dataItem;

                    setSelectedEmail(dataItem, row[0]);
                }
            }

            function createVouchersDisabled() {
                if (vm.model.edit.show_buttons_accounting !== '1') return 'hidden';
                if (getSelectedRows().length === 0) return true;

                return false;
            }

            function getSelectedRows() {
                let dirtyRows = vm.grid.gridfunc.getDirtyRows();

                return dirtyRows.filter((dirtyRow) => dirtyRow.is_selected === true);
            }

            function deleteSelectedRows() {
                getSelectedRows().forEach((email) => deleteMessage(email));
                clearSelectedEmail();
            }

            async function setEmailAsRead(message) {
                if (!message) return;
                graphMailService.updateMessage(message.id, { isRead: true }, vm.model.edit.current_mailbox);

                message.read_ok = true;

                if (!message.item_glyphicon?.includes('fas-envelope-open')) {
                    message.item_glyphicon = message.item_glyphicon?.replace('fas-envelope', 'fas-envelope-open');
                    message.item_glyphicon_color = message.item_glyphicon_color?.replaceAll('red', 'black');
                }

                if (vm.mediaQueries.tablet.matches === true) {
                    vm.grid.gridfunc.redrawRow(null, message);
                } else if (vm.mediaQueries.tablet.matches === false) {
                    message.icons[1][0] = 'fad fa-envelope-open';
                    message.icons[1][1] = 'black';
                }
            }

            async function setSelectedEmail(email) {
                vm.model.selectedEmail = null;
                vm.model.customerPersons = [];
                vm.model.projects = [];
                vm.model.iframeLoaded = false;

                //if (email && row) setEmailAsRead(email, row);
                setEmailAsRead(email);
                if (vm.model.retrieving) return;

                // timeout to wait on next digest so the ui gets time to reflect changes
                setTimeout(async () => {
                    try {

                        vm.model.selectedEmail = Object.assign({}, angular.copy(email));

                        _getCustactMembers();

                        if (vm.model.selectedEmail.from_cust_no > '0') {
                            _getCustomerPersons();
                        }

                        if (vm.model.selectedEmail.cust_no > '0') {
                            _getProjects();
                            _getCustacts();
                        } else {
                            vm.model.custacts = [];
                        }

                        if (vm.model.selectedEmail.project_keyno > '0') {
                            _getProjectProsesses();
                        }

                        let attachments = (await graphMailService.getAttachmentsFromMessageId(vm.model.selectedEmail.id, vm.model.edit.current_mailbox))?.value ?? [];
                        let htmlContent = base64.urldecode(vm.model.selectedEmail.email_body_html);

                        attachments.forEach((attachment) => {
                            if (attachment.isInline === true) {
                                if (attachment.size < 10000000) {

                                    htmlContent = htmlContent.replaceAll(`cid:${attachment.contentId}`, `data: ${attachment.contentType}; base64, ${attachment.contentBytes} `);
                                } else {
                                    htmlContent = htmlContent.replaceAll(`cid:${attachment.contentId}`, `cid:${attachment.contentId}" alt="Image size too big`);
                                }
                            }
                        });

                        vm.email = {
                            body: htmlContent
                        }

                        vm.model.selectedEmail.email_body_html = base64.urlencode(htmlContent);
                        vm.model.selectedEmail.attachments = attachments.filter((attachment) => attachment.isInline === false);
                        vm.model.selectedEmail = { ...vm.model.selectedEmail };

                        vm.model.iframeLoaded = true;
                    } catch (error) {
                        console.log(error);
                    }
                });
            }

            // #endregion GRID-STUFF

            // #region GOTO FUNCTIONS


            vm.gotoEmailAccountShared = function () {
                stateService.go('emailaccountshared', { current_account_email: vm.model.edit.current_account_email });
            }

            vm.gotoCustomerEdit = function () {
                stateService.go('customeredit', { cust_no: '0' });
            }

            function gotoCustomer(custNo) {
                stateService.go('customersmenu', { cust_no: custNo });
            }

            function gotoProjectMenu() {
                stateService.go('projectmenu', { project_keyno: vm.model.selectedEmail.project_keyno });
            }

            function gotoWorkDetails() {
                stateService.go('workdetails', { custact_no: vm.model.selectedEmail.custact_no });
            }

            function gotoProjects() {
                stateService.go('projects', { argtype: '-', argvalue: '-' });
            }

            function gotoOrheadHead() {
                stateService.go('orderhead', { order_internal_no: vm.model.selectedEmail.order_internal_no })
            }
            function gotoPurchHead() {
                stateService.go('purchhead', { purch_internal_no: vm.model.selectedEmail.purch_internal_no })
            }

            function gotoEmailCreate(emailKeyno) {
                stateService.go('emailcreate', {
                    webpage: 'emailview',
                    argtype: 'email_keyno',
                    argvalue: emailKeyno
                });
            }

            // #endregion GOTO FUNCTIONS

            function _parseEmailsToGridRows(emails) {
                return emails.map((email) => {
                    let recipientEmail = '';
                    let recipientName = '';
                    email?.toRecipients?.forEach((recipient) => {
                        recipientEmail += recipient?.emailAddress?.address + ', ';
                        recipientName += recipient?.emailAddress?.name + ', ';
                    });

                    let ccRecipients = '';
                    email?.ccRecipients?.forEach((email) => ccRecipients += email?.emailAddress?.address + ', ');

                    return {
                        id: email.id,
                        recipient_email: recipientEmail.substring(0, recipientEmail.length - 2),
                        recipient_name: recipientName.substring(0, recipientName.length - 2),
                        email_cc: ccRecipients?.substring(0, ccRecipients.length - 2),
                        heading: email.subject,
                        email_body: email.bodyPreview,
                        email_body_html: base64.urlencode(email.body.content),
                        webLink: email.webLink,
                        hasAttachments: email.hasAttachments,
                        datetime_sent: new Date(email.sentDateTime),
                        datetime_received: new Date(email.receivedDateTime),
                        read_ok: email.isRead,
                        is_selected: 0,
                        sender_email: email?.from?.emailAddress?.address ?? '',
                        sender_name: email?.from?.emailAddress?.name ?? '',
                        importance: email.importance,
                        conversationId: email.conversationId,
                    }
                })
            }

            function _mapEmailsAndEnrichedEmailsToGridRows(emails, enrichedEmails) {
                enrichedEmails.forEach((enrichedEmail) => {
                    let emailIndex = emails.findIndex((email) => enrichedEmail.id === email.id);

                    if (emailIndex !== -1) emails[emailIndex] = { ...emails[emailIndex], ...enrichedEmail };
                });

                return emails;
            }
            vm.onAmountOfEmailsChanged = function (value) {
                vm.model.edit.retrieve_max_nbr_of_emails = value;

                _rememberMaxRetrievalNumberOfEmails();
                _setGridDataFromEmails();
            }

            function _getFilterValue(filterId) {
                if (filterId === 'FOCUSED') {
                    return `inferenceClassification eq 'focused'`;
                }

                if (filterId === 'OTHER') {
                    return `inferenceClassification eq 'other'`;
                }

                return '';
            }

            async function _getEmails() {
                vm.model.retrieving = true;
                vm.model.selectedEmail = null;

                let messages = [];
                try {
                    let filter = _getFilterValue(vm.model.inferenceTabs.selected);

                    if (vm.model.edit.local_search === '0' && vm.model.edit.email_search_global) {
                        messages = (await graphMailService.getAllMessages({ limit: vm.model.edit.retrieve_max_nbr_of_emails, userId: vm.model.edit.current_mailbox, filter: '', search: `"${vm.model.edit.email_search_global}"` })).value;
                    } else if (vm.model.edit.local_search === '1' && vm.model.edit.email_search_global) {
                        messages = (await graphMailService.getMessagesFromFolder({ folderId: vm.model.edit.selected_folder_id, limit: vm.model.edit.retrieve_max_nbr_of_emails, userId: vm.model.edit.current_mailbox, filter: '', search: `"${vm.model.edit.email_search_global}"` })).value;
                    } else {
                        filter = _getFilterValue(vm.model.inferenceTabs.selected);
                        messages = (await graphMailService.getMessagesFromFolder({ folderId: vm.model.edit.selected_folder_id, limit: vm.model.edit.retrieve_max_nbr_of_emails, userId: vm.model.edit.current_mailbox, filter: filter ?? '' })).value;
                    }
                } catch (error) {
                    messages = [];
                }

                const emailGridRows = _parseEmailsToGridRows(messages);
                vm.grid.gridfunc?.setDataSource(emailGridRows);

                let chunks = [];
                let count = 0;
                let deepCopyEmails = angular.copy(emailGridRows);

                while (deepCopyEmails.length > 0) {
                    chunks[count] = deepCopyEmails.splice(0, 500);
                    count++;
                }

                let enrichedEmailChunks = await Promise.all(chunks.map(async (chunk) => await emailService.enrichEmails(vm.model.edit.current_account_email, vm.model.edit.businessco_no, chunk.map((emails) => {
                    let emailCopy = JSON.parse(JSON.stringify(emails));
                    delete emailCopy.email_body;
                    delete emailCopy.email_body_html;
                    delete emailCopy.webLink;
                    return emailCopy;
                }))));

                let mappedEmails = [];

                for (let i = 0; i < enrichedEmailChunks.length; i++) {
                    mappedEmails = [...mappedEmails, ..._mapEmailsAndEnrichedEmailsToGridRows(chunks[i], enrichedEmailChunks[i])];
                }

                vm.model.emails = mappedEmails;
                //console.dir(vm.model.emails);
                //vm.model.emails = mappedEmails.map((email) => {
                //    const icons = email.item_glyphicon.split(' ').map((icon) => 'fad fa' + icon.replace(icon.split('-')[0], ''));
                //    const colors = email.item_glyphicon_color.split(' ');

                //    let iconsMap = new Map();
                //    for (let i = 0; i < icons.length; i++) {
                //        iconsMap.set(icons[i], colors[i]);
                //    }

                //    return { ...email, icons: Array.from(iconsMap) }
                //});

                vm.model.retrieving = false;
            }

            async function _setGridDataFromEmails() {
                vm.model.loading = true;
                if (vm.mediaQueries.tablet.matches === true) {
                    vm.grid.gridfunc?.gridProgress(true);
                    await _getEmails();
                    vm.grid.gridfunc.setDataSource(vm.model.emails);
                    vm.grid.gridfunc?.gridProgress(false);
                } else {
                    await _getEmails();
                    vm.model.emails = parseIcon();
                }
                vm.model.loading = false;
            }

            async function refreshEmails() {
                vm.model.retrieving = true;

                try {
                    await _setGridDataFromEmails();
                } catch (error) {
                    _showWarningModal('An error occured');
                } finally {
                    vm.model.retrieving = false;
                }
            };

            async function deleteMessage(message) {
                const folder = await graphMailService.getMailFolder('deleteditems');

                if (folder.id === vm.model.edit.selected_folder_id) {
                    await graphMailService.deleteMessage(message.id, vm.model.edit.current_mailbox);
                } else {
                    await graphMailService.moveMessageToDeletedItems(message.id, vm.model.edit.current_mailbox);
                }

                if (vm.mediaQueries.tablet.matches === true) {
                    vm.grid.gridfunc.removeRow(message);
                } else {
                    vm.model.emails = vm.model.emails.filter((email) => email.id !== message.id);
                }
            }

            function clearSelectedEmail() {
                vm.model.selectedEmail = null;
            }

            /**
             * Selects the email at the given row index.
             * 
             * @param {number} rowIndex the index of the row to select.
             */
            function selectEmailAtRow(rowIndex) {
                setTimeout(() => {
                    if (rowIndex >= vm.grid.gridfunc.getDataItems().length - 1) rowIndex = vm.grid.gridfunc.getDataItems().length - 1;
                    if (rowIndex >= 0) {
                        vm.grid.gridfunc.selectRow(rowIndex);
                        setSelectedEmail(vm.grid.gridfunc.getSelectedRow(), vm.grid.gridfunc.getRowAt(rowIndex));
                    } else {
                        vm.model.selectedEmail = null;
                    }
                });
            }

            // #region CRUD-FUNCTIONS

            vm.markAsSpam = async function () {
                if (vm.model.selectedEmail.id && vm.model.edit.current_mailbox) {
                    try {
                        if (vm.mediaQueries.tablet.matches === true) {
                            const row = vm.grid.gridfunc.getSelectedRow();
                            const rowIndex = vm.grid.gridfunc.getDataItems().indexOf(row);
                            let response = (await graphMailService.markAsSpam(vm.model.selectedEmail.id, vm.model.edit.current_mailbox));

                            vm.grid.gridfunc.removeRow(row);
                            selectEmailAtRow(rowIndex);
                        } else {
                            let response = (await graphMailService.markAsSpam(vm.model.selectedEmail.id, vm.model.edit.current_mailbox));
                            vm.model.emails = vm.model.emails.filter((email) => email.id !== vm.model.selectedEmail.id);
                            vm.model.selectedEmail = null;

                        }
                    } catch (error) {
                        //console.dir(error);
                        _showWarningModal('An error occurred');
                    }
                }
            }

            vm.moveToArchiveFolder = async function () {
                if (vm.model.edit.auto_archieve_folder_id !== '0' && vm.model.edit.auto_archieve_folder_id && vm.model.edit.selected_folder_id !== vm.model.edit.auto_archieve_folder_id) {
                    if (vm.mediaQueries.tablet.matches === true) {
                        const row = vm.grid.gridfunc.getSelectedRow();
                        const rowIndex = vm.grid.gridfunc.getDataItems().indexOf(row);

                        await graphMailService.moveMessageToFolder(vm.model.selectedEmail.id, vm.model.edit.auto_archieve_folder_id, vm.model.edit.current_mailbox);

                        vm.grid.gridfunc.removeRow(row);
                        selectEmailAtRow(rowIndex);
                    } else {
                        await graphMailService.moveMessageToFolder(vm.model.selectedEmail.id, vm.model.edit.auto_archieve_folder_id, vm.model.edit.current_mailbox);
                        vm.model.emails = vm.model.emails.filter((email) => email.id !== vm.model.selectedEmail.id);
                        vm.model.selectedEmail = null;
                    }
                }

            }

            vm.deleteEmail = async function () {
                if (vm.mediaQueries.tablet.matches === true) {
                    let rowIndex = vm.grid.gridfunc.getDataItems().indexOf(vm.grid.gridfunc.getSelectedRow());
                    await deleteMessage(vm.model.selectedEmail);
                    return selectEmailAtRow(rowIndex);
                } else {
                    await deleteMessage(vm.model.selectedEmail);
                    vm.model.selectedEmail = null;
                }
            };

            vm.saveEmailItem = async function ({ gotoReplyTo, createVoucher }) {
                vm.model.saveLock = true;

                if (createVoucher) {
                    vm.model.selectedEmail.create_voucher = '1';
                }

                let email = { ...vm.model.selectedEmail, email_body: base64.urlencode(vm.model.selectedEmail.email_body), heading: base64.urlencode(vm.model.selectedEmail.heading), custact_note_new: base64.urlencode(vm.model.selectedEmail.custact_note_new) };
                ['_events', '_handlers', 'parent', 'dirty', 'dirtyFields', 'uid'].forEach((key) => delete email[key]);

                let data = (await emailService.saveEmailItem(email))[0];
                if (data.errorcode !== '0') {
                    _showWarningModal(data.errormessage);
                } else if (vm.model.edit.auto_archieve_folder_id !== '0' && vm.model.edit.auto_archieve_folder_id && vm.model.edit.selected_folder_id !== vm.model.edit.auto_archieve_folder_id) {
                    try {
                        await graphMailService.moveMessageToFolder(email.id, vm.model.edit.auto_archieve_folder_id, vm.model.edit.current_mailbox);

                        if (vm.mediaQueries.tablet.matches === true) {
                            const row = vm.grid.gridfunc.getSelectedRow();
                            const rowIndex = vm.grid.gridfunc.getDataItems().indexOf(row);

                            vm.grid.gridfunc.removeRow(row);
                            selectEmailAtRow(rowIndex);
                        } else {
                            vm.model.emails = vm.model.emails.filter((email) => email.id !== vm.model.selectedEmail.id);
                            vm.model.selectedEmail = null;
                        }
                    } catch (error) {
                        _updateEmailItem(vm.model.selectedEmail);
                    }

                } else {
                    _updateEmailItem(vm.model.selectedEmail);

                }

                if (gotoReplyTo && data.errorcode === '0') {
                    gotoEmailCreate(data.email_keyno_new);
                }

                vm.model.saveLock = false;
            };

            async function _updateEmailItem(email) {
                let enrichedEmail = (await emailService.enrichEmails(vm.model.edit.current_account_email, vm.model.edit.businessco_no, [email]))[0];

                if (vm.mediaQueries.tablet.matches === true) {
                    let dataitem = vm.grid.gridfunc.getAllRows().find((item) => item.uid === email.uid);

                    dataitem = { ...email, ...enrichedEmail };
                    vm.grid.gridfunc.redrawRow('', dataitem);

                    if (email === vm.model.selectedEmail) {
                        vm.model.selectedEmail = null;
                        setSelectedEmail(dataitem);
                    }
                } else {
                    const emailIndex = vm.model.emails.findIndex((item) => item.id === email.id);

                    if (emailIndex !== -1) {
                        vm.model.emails[emailIndex] = { ...email, ...enrichedEmail };
                        parseIcon();
                    }

                    vm.model.selectedEmail = null;
                }
            }

            //async function _updateEmailItem(email) {
            //    let enrichedEmail = (await emailService.enrichEmails(vm.model.edit.current_account_email, [vm.model.selectedEmail]))[0];

            //    if (vm.mediaQueries.tablet.matches === true) {
            //        let dataitem = vm.grid.gridfunc.getAllRows().find((item) => item.uid === vm.model.selectedEmail.uid);

            //        dataitem = { ...vm.model.selectedEmail, ...enrichedEmail };
            //        console.dir(dataitem);
            //        vm.grid.gridfunc.redrawRow('', dataitem);

            //        vm.model.selectedEmail = null;
            //        setSelectedEmail(dataitem);
            //    } else {
            //        const emailIndex = vm.model.emails.findIndex((email) => email.id === vm.model.selectedEmail.id);

            //        if (emailIndex !== -1) {
            //            vm.model.emails[emailIndex] = { ...vm.model.selectedEmail, ...enrichedEmail };
            //            parseIcon();
            //        }

            //        vm.model.selectedEmail = null;
            //    }
            //}

            async function createVouchers(emails) {
                vm.model.selectedEmail = null;
                vm.grid.gridfunc.gridProgress(true);

                for (let email of emails) {
                    let attachments = (await graphMailService.getAttachmentsFromMessageId(email.id, vm.model.edit.current_mailbox))?.value ?? [];
                    let htmlContent = base64.urldecode(email.email_body_html);

                    attachments.forEach((attachment) => {
                        if (attachment.isInline === true) {
                            htmlContent = htmlContent.replaceAll(`cid:${attachment.contentId}`, `data: ${attachment.contentType}; base64, ${attachment.contentBytes} `);
                        }
                    });

                    email = { ...email, email_body: base64.urlencode(email.email_body), email_body_html: base64.urlencode(htmlContent), heading: base64.urlencode(email.heading), custact_note_new: base64.urlencode(email.custact_note_new), attachments: attachments.filter((attachment) => attachment.isInline === false), create_voucher: '1' };
                    ['_events', '_handlers', 'parent', 'dirty', 'dirtyFields', 'uid'].forEach((key) => delete email[key]);

                    let data = (await emailService.saveEmailItem(email))[0];

                    if (data.errorcode !== '0') {
                        _showWarningModal(data.errormessage);
                    } else if (vm.model.edit.auto_archieve_folder_id !== '0' && vm.model.edit.auto_archieve_folder_id && vm.model.edit.selected_folder_id !== vm.model.edit.auto_archieve_folder_id) {
                        await graphMailService.moveMessageToFolder(email.id, vm.model.edit.auto_archieve_folder_id, vm.model.edit.current_mailbox);

                        let row = getSelectedRows().find((item) => item.id === email.id);
                        if (row) vm.grid.gridfunc.removeRow(row);
                    } else {
                        await Promise.all(getSelectedRows().map(async (row) => {
                            await _updateEmailItem(row);
                        }));

                        vm.grid.gridfunc.gridProgress(false);
                    }
                }
            };

            vm.saveEmailItemAndReply = async function () {
                await vm.saveEmailItem();
                gotoEmailView()
            }

            // #endregion CRUD-FUNCTIONS

            vm.openWeblink = function () {
                window.open(vm.model.selectedEmail.webLink, '_blank');
            }

            vm.openFileInNewTab = function (file) {
                const blobData = atob(file.contentBytes);
                let byteNumbers = new Array(blobData.length);

                for (let i = 0; i < blobData.length; i++) {
                    byteNumbers[i] = blobData.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                const fileBlob = new Blob([byteArray], { type: file.contentType });
                const blobUrl = URL.createObjectURL(fileBlob);
                window.open(blobUrl, '_blank');
            }

            vm.onAutoArchieveFolderChanged = function (item) {
                vm.model.edit.auto_archieve_folder_name = item?.item_name ?? '';
                vm.model.edit.auto_archieve_folder_id = item?.item_id ?? '0';
                _rememberAutoArchiveFolder();
            }

            vm.onBusinessCoNoChanged = function (value) {
                try {
                    vm.model.edit.businessco_no = value;
                    if (!!vm.model.selectedEmail) {
                        vm.model.selectedEmail.businessco_no = value;
                    }
                    vm.grid.gridfunc.getAllRows().forEach((row) => row.businessco_no = vm.model.edit.businessco_no);
                    vm.grid.gridfunc.refresh();
                    _rememberBusinessCoNo();
                } catch (error) {
                    console.log(error);
                }
            }

            // #region PROJECT-SEARCH

            vm.searchProjects = function () {
                return _getProjects(vm.model.selectedEmail.project_name);
                //return emailService.getAllProjects(vm.model.selectedEmail.project_name, vm.model.selectedEmail.cust_no);
            }

            vm.onProjectSelected = async function (item) {
                vm.model.selectedEmail.project_keyno = item?.item_id ?? '0';
                vm.model.selectedEmail.project_name = item?.item_name ?? '';

                if (vm.model.selectedEmail.project_keyno > '0') {
                    const project = (await _getProject())?.[0]
                    _setProjectInfoToEmail(project);
                    _getProjects();
                    _getProjectProsesses();
                } else {
                    vm.model.projectProsesses = [];
                    vm.model.selectedEmail.projectprosess_keyno = '0';
                }

                _getCustacts();
            }

            function _setProjectInfoToEmail(project) {
                vm.model.selectedEmail.project_keyno = project?.project_keyno ?? '0';
                vm.model.selectedEmail.project_name = project?.project_name ?? '';
                vm.model.selectedEmail.cust_no = project?.cust_no ?? '0';
                vm.model.selectedEmail.cust_name = project?.cust_name ?? '';
            }

            // #endregion PROJECT-SEARCH

            // #region PROSESS-SEARCH 

            vm.onProsessSelected = function (item) {
                vm.model.selectedEmail.projectprosess_keyno = item?.projectprosess_keyno ?? '0';
                vm.model.selectedEmail.prosess_name = item?.projectprosess_name ?? '0';
                _getCustacts();
            }

            // #endregion PROSESS-SEARCH 

            // #region CUSTOMER-SEARCH

            vm.searchCustomer = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'A',
                    lastselect: 'SEARCH'
                });
            };

            vm.onFromCustomerSelected = function (item) {
                if (!item || vm.model.selectedEmail.from_cust_no !== item?.item_id) {
                    vm.model.selectedEmail.from_cust_no = '0';
                    vm.model.selectedEmail.from_cust_name = '';

                    vm.model.customerPersons = [];
                    vm.model.selectedEmail.pers_name = '0';
                    vm.model.selectedEmail.pers_no = '0';
                }

                vm.model.selectedEmail.from_cust_name = item?.item_name ?? '';
                vm.model.selectedEmail.from_cust_no = item?.item_id ?? '0';

                if (vm.model.selectedEmail.from_cust_no > '0') {
                    _getCustomerPersons();
                }

                if (vm.model.selectedEmail.from_cust_no > '0' && vm.model.selectedEmail?.cust_no === '0') {
                    vm.onCustomerSelected({ item_id: item?.item_id, item_name: item?.item_name });
                }
            };

            vm.onCustomerSelected = function (item) {
                if (!item || vm.model.selectedEmail.cust_no !== item?.item_id) {
                    vm.model.selectedEmail.cust_no = '0';
                    vm.model.selectedEmail.cust_name = '';

                    vm.model.projects = [];
                    vm.model.selectedEmail.project_name = '';
                    vm.model.selectedEmail.project_keyno = '0';
                    vm.model.custacts = [];

                    vm.onCustactSelected();
                }

                vm.model.selectedEmail.cust_name = item?.item_name ?? '';
                vm.model.selectedEmail.cust_no = item?.item_id ?? '0';

                if (vm.model.selectedEmail.from_cust_no === '0') {
                    vm.model.selectedEmail.from_cust_no = item?.item_id ?? '0';
                    vm.model.selectedEmail.from_cust_name = item?.item_name ?? '';
                    _getCustomerPersons();
                }

                if (vm.model.selectedEmail.cust_no > '0') {
                    _getProjects();
                    _getCustacts();

                } else {
                    vm.model.custacts = [];
                }
            };

            function clearPerson() {
                vm.model.selectedEmail.pers_name = '0';
                vm.model.selectedEmail.pers_no = '0';
            }

            // #endregion CUSTOMER-SEARCH

            // #region CUSTACT-SEARCH

            vm.searchCustact = async function () {
                return [{ item_id: '0', item_name: '', orderby: '0' }, ...(await typeaheadService.lookUpCustact({
                    searchtext: vm.model.selectedEmail.custact_note,
                    cust_no: vm.model.selectedEmail.cust_no,
                    project_keyno: vm.model.selectedEmail.project_keyno,
                    projectprosess_keyno: vm.model.selectedEmail.projectprosess_keyno,
                    restrict_to_workorder: '0',
                }))];
            };

            vm.onCustactSelected = async function (item) {
                vm.model.selectedEmail.custact_no = item?.item_id ?? '0';
                vm.model.selectedEmail.custact_note = item?.item_name ?? '';

                if (item && vm.model.selectedEmail.custact_no > '0') {
                    let customer = (await emailService.getCustomerFromCustact(vm.model.selectedEmail.custact_no))[0];
                    vm.model.selectedEmail = { ...vm.model.selectedEmail, ...customer };

                    _getProjects();
                    _getProjectProsesses();
                    _getCustacts();
                }
            };

            // #endregion CUSTACT-SEARCH

            // #region ORDER-INFO-SEARCH

            vm.searchOrderInfo = function () {
                return emailService.searchOrderInfo(vm.model.selectedEmail.order_search_info, vm.model.selectedEmail.cust_no);
            }


            vm.onOrderInfoSelected = async function (item) {
                vm.model.selectedEmail.order_internal_no = item?.order_internal_no ?? '0';
                vm.model.selectedEmail.order_search_info = item?.item_name ?? '';

                if (vm.model.selectedEmail?.order_internal_no > '0' && vm.model.selectedEmail.cust_no === '0') {
                    vm.onCustomerSelected({ item_name: item.cust_name, item_id: item.cust_no });
                }
            };

            // #endregion ORDER-INFO-SEARCH

            // #region PURCHASE-INFO-SEARCH

            vm.searchPurchaseInfo = function () {
                return emailService.searchPurchaseInfo(vm.model.selectedEmail.purch_search_info, vm.model.selectedEmail.cust_no);
            }

            vm.onPurchaseInfoSelected = async function (item) {
                vm.model.selectedEmail.purch_internal_no = item?.purch_internal_no ?? '0';
                vm.model.selectedEmail.purch_search_info = item?.item_name ?? '';

                if (vm.model.selectedEmail.cust_no === '0' && item?.cust_no_name && item?.cust_no && item?.cust_no !== '0') {
                    vm.onCustomerSelected({ item_name: item.cust_no_name, item_id: item.cust_no });
                }
            };

            // #endregion PURCHASE-INFO-SEARCH

            vm.selectMailbox = async function (item) {
                vm.model.edit.current_mailbox = item.item_id;

                await _rememberCurrentMailbox();
                await _getEdit();

                vm.model.edit.current_mailbox_name = item.item_name;
                _loadEmailMailbox(vm.model.edit.current_mailbox_name);
            }

            vm.onMailboxChanged = async function (value) {
                vm.model.edit.current_mailbox = value;

                await _rememberCurrentMailbox();
                await _getEdit();

                const mailbox = vm.model.mailboxes.find((mailbox) => mailbox.item_id === value);
                vm.model.edit.current_mailbox_name = mailbox?.item_name ?? '';

                if (mailbox) {
                    _loadEmailMailbox(vm.model.edit.current_mailbox_name);
                }
            }

            vm.selectTreeItem = async function (item) {
                vm.model.edit.selected_folder_id = item?.item_id ?? 'noid';
                vm.model.edit.selected_folder_name = item?.item_name ?? '';
                clearSelectedEmail();

                if (!item) {
                    return;
                }

                if (vm.model.ready) {
                    _rememberSelectedFolder();
                }

                if (vm.model.edit.email_search_global?.trim() && !vm.model.retrieving) {
                    await vm.onLocalSearchSettingChanged('1');
                } else {
                    await _setGridDataFromEmails();
                }

                if (vm.grid.gridfunc?.getDataItems().length === 1) {
                    vm.grid.gridfunc.selectRow(0);
                    setSelectedEmail(vm.grid.gridfunc.getDataItems()[0], vm.grid.gridfunc.getRowAt(0));
                }
            };

            function _showWarningModal(message) {
                return new Promise((resolve) => modalService.show({
                    type: 'warning',
                    title: vm.translations.error,
                    message: message,
                    buttons: [{
                        label: vm.translations.ok,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            resolve();
                        }
                    }]
                }));
            }


            // #region REMEMBER FUNCTIONS

            function _rememberSelectedFolder() {
                if (vm.model?.edit?.selected_folder_id && vm.model?.edit?.selected_folder_name && vm.model?.edit?.current_account_email) {
                    const selectedFolderName = `w_email.selected_folder_name.${vm.model.edit.current_account_email}.${vm.model.edit.current_mailbox} `;
                    const selectedFolderId = `w_email.selected_folder_id.${vm.model.edit.current_account_email}.${vm.model.edit.current_mailbox}`;

                    return Promise.all([
                        emailService.remember(selectedFolderId, vm.model.edit.selected_folder_id),
                        emailService.remember(selectedFolderName, vm.model.edit.selected_folder_name)
                    ]);
                }
            }

            function _rememberAutoArchiveFolder() {
                if (vm.model?.edit?.auto_archieve_folder_id && vm.model?.edit?.current_account_email) {
                    const autoArcheiveFolder = `w_email.auto_archieve_folder_id.${vm.model.edit.current_account_email}.${vm.model.edit.current_mailbox}`;
                    const autoArcheiveName = `w_email.auto_archieve_folder_name.${vm.model.edit.current_account_email}.${vm.model.edit.current_mailbox}`;

                    return Promise.all([
                        emailService.remember(autoArcheiveFolder, vm.model.edit.auto_archieve_folder_id),
                        emailService.remember(autoArcheiveName, vm.model.edit.auto_archieve_folder_name),
                    ]);
                }
            }

            function _rememberBusinessCoNo() {
                if (!!vm.model.edit.businessco_no) {
                    return emailService.remember(`w_email.businessco_no.` + vm.model.edit.current_account_email, vm.model.edit.businessco_no);
                }
            }

            function _rememberCurrentAccountEmail() {
                if (vm.model?.edit?.current_account_email) {
                    return emailService.remember(`w_email.current_account_email`, vm.model.edit.current_account_email);
                }
            }

            function _rememberCurrentMailbox() {
                if (vm.model?.edit?.current_account_email && vm.model?.edit?.current_mailbox) {
                    return emailService.remember(`w_email.current_mailbox.${vm.model.edit.current_account_email}`, vm.model.edit.current_mailbox);
                }
            }

            function _rememberMaxRetrievalNumberOfEmails() {
                if (vm.model?.edit?.retrieve_max_nbr_of_emails) {
                    return emailService.remember(`w_email.retrieve_max_nbr_of_emails`, vm.model.edit.retrieve_max_nbr_of_emails);
                }
            }

            function _rememberEmailSearch() {
                if (vm.model?.edit?.current_account_email) {
                    return emailService.remember(`w_email.email_search_global.${vm.model.edit.current_account_email}`, vm.model.edit.email_search_global);
                }
            }

            function _rememberLocalSearchSetting() {
                if (vm.model?.edit?.current_account_email) {
                    return emailService.remember(`w_email.local_search.${vm.model.edit.current_account_email}`, vm.model.edit.local_search);
                }
            }

            // #endregion REMEMBER FUNCTIONS

            // #region GETTERS

            async function _getEdit() {
                return vm.model.edit = { ...vm.model.edit, ...(await emailService.getEmail())[0] };
            };

            async function _getMailboxes() {
                return vm.model.mailboxes = await emailService.getSharedMailboxes(vm.model.edit.current_account_email);
            }

            async function _getCustomerPersons() {
                return vm.model.customerPersons = await emailService.getCustomerPersons(vm.model.selectedEmail.from_cust_no);
            }

            async function _getCustactMembers() {
                return vm.model.custactMembers = await emailService.getCustactMembers(vm.model.selectedEmail.custact_no);
            }

            async function _getProjects(searchtext) {
                return vm.model.projects = await emailService.getProjects(searchtext ?? '', vm.model.selectedEmail.cust_no);
            }

            async function _getProjectProsesses() {
                return vm.model.projectProsesses = await emailService.getProjectProsesses(vm.model.selectedEmail.project_keyno);
            }

            async function _getActivityTypes() {
                return vm.model.activityTypes = await emailService.getActivityTypes();
            }

            async function _getCustacts() {
                return vm.model.custacts = await emailService.getCustacts(vm.model.selectedEmail?.cust_no ?? '0', vm.model.selectedEmail?.project_keyno ?? '0', vm.model.selectedEmail?.projectprosess_keyno ?? '0');
            }

            async function _getBusinessCompanies() {
                return vm.model.businessCompanies = await emailService.getBusinessCoList();
            }

            function _getProject() {
                if (vm.model.selectedEmail?.project_keyno && vm.model.selectedEmail?.project_keyno > '0') {
                    return emailService.getProject(vm.model.selectedEmail.project_keyno);
                }
            }

            async function _getAccount(tries = 3) {
                let account = null;

                try {
                    account = await graphAuthService.getAccount();
                    return account;
                } catch (error) {
                    if (tries === 0) return account;

                    return new Promise(
                        function (resolve) {
                            setTimeout(async () => {
                                account = await _getAccount(--tries);
                                return resolve(account);
                            }, 1000)
                        },
                        function (reject) {
                            reject(null)
                        }
                    );
                }
            }

            // #endregion GETTERS

            // #region PAGE INIT FUNCTIONS

            async function _loadEmailMailbox(mailbox) {
                try {
                    vm.model.treeListEmailFolders = await graphMailService.getAllMailFoldersAsItemList(mailbox);
                } catch (error) {
                    _showWarningModal('Mailbox not found');
                }

                if (!vm.model?.edit?.selected_folder_id || !vm.model?.edit?.selected_folder_name) {
                    let inbox = await graphMailService.getMailFolder('inbox', mailbox);

                    vm.model.edit.selected_folder_id = inbox.id;
                    vm.model.edit.selected_folder_name = inbox.displayName;
                }

                vm.selectTreeItem({ item_id: vm.model.edit.selected_folder_id, item_name: vm.model.edit.selected_folder_name });
            }

            async function _loadMailboxData() {
                let account = await _getAccount();

                if (account) {
                    vm.model.edit.current_account_email = account.username;

                    await _getMailboxes();
                    _loadEmailMailbox(vm.model.edit.current_mailbox);
                }
            }

            async function _onUserLoggedIn() {
                if (!vm.model?.reloadOnUserLoggedIn) return;

                let account = await _getAccount();

                if (account) {
                    if (vm.model.ready) {
                        await _rememberCurrentAccountEmail();
                        await _getEdit();
                    }

                    vm.model.edit.current_account_email = account.username;

                    await _getMailboxes();
                    _loadEmailMailbox(vm.model.edit.current_mailbox);
                }
            };

            function _translate() {
                return translateService.translateBatch(vm.translations).then((data) => Object.keys(vm.translations).forEach((key) => {
                    if (data?.[key]) vm.translations[key] = data[key];
                }));
            }

            // #endregion PAGE INIT FUNCTIONS

            // #region ANGULAR LIFECYCLE HOOKS
            let onDestroy = [];

            function parseIcon() {
                return vm.model.emails.map((email) => {
                    const icons = email.item_glyphicon.split(' ').map((icon) => 'fad fa' + icon.replace(icon.split('-')[0], ''));
                    const colors = email.item_glyphicon_color.split(' ');

                    let iconsMap = new Map();
                    for (let i = 0; i < icons.length; i++) {
                        iconsMap.set(icons[i], colors[i]);
                    }

                    return { ...email, icons: Array.from(iconsMap) }
                });
            };

            function somethingToCallWhenMediaChanges() {
                //console.dir('tablet or not, we can not say, but its either or when this is logged.');
                if (vm.mediaQueries.tablet.matches === false) {
                    vm.model.selectedEmail = null;
                    vm.model.emails = parseIcon();
                } else {
                    vm.model.selectedEmail = null;
                    vm.model.emails = vm.model.emails.map((email) => {
                        // Assuming email.icons is an array of [icon, color] pairs
                        let item_glyphicon = email.icons.map(iconPair => {
                            // Split the icon class to get the original prefix (e.g., 'fa' from 'fad fa-something')
                            let iconSplit = iconPair[0].split(' ');
                            let originalPrefix = iconSplit[1].split('-')[0];

                            // Replace 'fa' with the original prefix and remove 'fa' from the class name
                            return iconPair[0].replace('fa', originalPrefix);
                        }).join(' ');

                        let item_glyphicon_color = email.icons.map(iconPair => iconPair[1]).join(' ');

                        // Return the reconstructed email object
                        return { ...email, item_glyphicon, item_glyphicon_color };
                    });

                    vm.grid.gridfunc.setDataSource(vm.model.emails);
                }
            }

            async function _onLoginIniated() {
                let account = null;

                try {
                    account = await graphAuthService.getAccount();
                } catch (error) {
                }
                if (!account) {
                    vm.model.reloadOnUserLoggedIn = true;
                }
            }

            vm.$onInit = async function () {
                vm.model.retrieving = true;

                _getBusinessCompanies();

                layoutService.onLayoutChanged(onDestroy, async (info) => {
                    if (!info) return;
                    if (vm.mediaQueries?.tablet) {
                        vm.mediaQueries.tablet.removeEventListener('change', somethingToCallWhenMediaChanges);
                    }
                    vm.mediaQueries = info.mediaQueries;
                    vm.mediaQueries.tablet.addEventListener('change', somethingToCallWhenMediaChanges);

                    vm.style.subject.fontSize = info.fontSizes.textSizeL;
                    vm.style.iframeBody.fontSize = info.fontSizes.textSizeL;
                })

                _translate().then(() => {
                    vm.model.searchFolders = [

                        { item_name: vm.translations.email_search_all_folders, item_id: '0' },
                        { item_name: vm.translations.email_search_current_folder, item_id: '1' },
                    ];
                })

                _getActivityTypes();
                await _getEdit();
                //await _onUserLoggedIn();
                vm.model.ready = true;

                _loadMailboxData();

                if (document.getElementsByTagName('html')?.[0].classList.contains('dark')) {
                    vm.model.darkmode = true;
                    //applyTheme('dark', document.querySelector('mgt-login'));
                }

                document.querySelector('mgt-login').addEventListener('loginCompleted', _onUserLoggedIn);

                // loginIniated event not working so doing this for now.
                document.querySelector('mgt-login')?.addEventListener('click', _onLoginIniated);
            };

            vm.$onDestroy = function () {
                onDestroy.forEach((destruction) => destruction());
                document.querySelector('mgt-login')?.removeEventListener('loginCompleted', _onUserLoggedIn);
                document.querySelector('mgt-login')?.removeEventListener('click', _onLoginIniated);
            }

            // #endregion ANGULAR LIFECYCLE HOOKS

        }]
    });
})();
