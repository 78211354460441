(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productPic', {
        templateUrl: 'views/components/views/productPic/productPic.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productPicService', function ($stateParams, stateService, productPicService) {
            let vm = this;
            let productPictureBankKeyno = $stateParams.productpicturebank_keyno;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                edit: {}
            };
            
            let loadEdit = function () {
                productPicService.getProductPicture({
                    productpicturebank_keyno: productPictureBankKeyno,
                    prod_id: prodId
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'picturebanks':
                        go({
                            argtype: 'prod_id',
                            argvalue: vm.model.edit.prod_id
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.saveChanges = function () {
                productPicService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
            
            vm.deleteValues = function () {
                productPicService.deleteItem(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
