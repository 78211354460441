import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ResponsiveService } from '../../services/responsive.service';
import { ComponentBaseComponent } from '../component-base/component-base.component';

@Component({
    selector: 'tt-responsive-breakpoints',
    templateUrl: './responsive-breakpoints.component.html',
    styleUrls: ['./responsive-breakpoints.component.css'],
})
export class ResponsiveBreakpointsComponent extends ComponentBaseComponent implements OnInit, OnDestroy, AfterViewInit {
    observer?: IntersectionObserver;

    @ViewChild('responsiveXX') responsiveXX?: ElementRef;
    @ViewChild('responsiveXS') responsiveXS?: ElementRef;
    @ViewChild('responsiveSM') responsiveSM?: ElementRef;
    @ViewChild('responsiveMD') responsiveMD?: ElementRef;
    @ViewChild('responsiveLG') responsiveLG?: ElementRef;
    @ViewChild('responsiveXL') responsiveXL?: ElementRef;
    @ViewChild('responsiveXXL') responsiveXXL?: ElementRef;

    constructor(private responsive: ResponsiveService) {
        super();
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.listenForResponsiveViewChange();
    }

    listenForResponsiveViewChange() {
        this.observer = new IntersectionObserver(this.onIntersection.bind(this), { threshold: [0] });

        if (this.responsiveXX) {
            this.observer.observe(this.responsiveXX.nativeElement);
        }
        if (this.responsiveXS) {
            this.observer.observe(this.responsiveXS.nativeElement);
        }
        if (this.responsiveSM) {
            this.observer.observe(this.responsiveSM.nativeElement);
        }
        if (this.responsiveMD) {
            this.observer.observe(this.responsiveMD.nativeElement);
        }
        if (this.responsiveLG) {
            this.observer.observe(this.responsiveLG.nativeElement);
        }
        if (this.responsiveXL) {
            this.observer.observe(this.responsiveXL.nativeElement);
        }
        if (this.responsiveXXL) {
            this.observer.observe(this.responsiveXXL.nativeElement);
        }
    }

    //private lastResponsiveUnit: Element | null = null;

    onIntersection(entries: IntersectionObserverEntry[]) {
        const self = this;

        entries.forEach((entry) => {
            if (!entry.isIntersecting) return;

            var current = entry.target.attributes.getNamedItem('visible-size')?.value || '';

            if (self.responsive.current === current) return;
            if (current.length < 1) return;

            self.responsive.SetCurrent(current);

            //if (entry.isIntersecting && (!self.lastResponsiveUnit || self.lastResponsiveUnit !== entry.target)) {
            //    self.responsive.SetCurrent(entry.target.className.substring(entry.target.className.indexOf('-') + 1));
            //}

            //self.lastResponsiveUnit = entry.target;
        });
    }

    ngOnDestroy(): void {
        this.observer?.unobserve(this.responsiveXX?.nativeElement);
        this.observer?.unobserve(this.responsiveXS?.nativeElement);
        this.observer?.unobserve(this.responsiveSM?.nativeElement);
        this.observer?.unobserve(this.responsiveMD?.nativeElement);
        this.observer?.unobserve(this.responsiveLG?.nativeElement);
        this.observer?.unobserve(this.responsiveXL?.nativeElement);
        this.observer?.unobserve(this.responsiveXXL?.nativeElement);
    }
}
