(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('project', {
        templateUrl: 'views/components/views/project/project.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'projectService', 'logisticService', 'economyService', 'translateService', 'typeaheadService', function ($stateParams, stateService, utilityService, modalService, projectService, logisticService, economyService, translateService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.version = module.version;

            vm.model = {
                state: stateService.getCurrentName(),
                project: {},
                customerButtons: [
                    { id: 'new', icon: 'fas fa-plus', type: 'primary', color: 'primary', onClick: () => _gotoCustomerEdit('0') },
                    { id: 'edit', icon: 'fas fa-edit', type: 'primary', color: 'primary', onClick: () => _gotoCustomerEdit(vm.model.project.cust_no) },
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => _gotoCustomerMenu(vm.model.project.cust_no) },
                ],
                deliveryCustomerButtons: [
                    { id: 'new', icon: 'fas fa-plus', type: 'primary', color: 'primary', onClick: () => _gotoCustomerEdit('0', { delivery: true }) },
                    { id: 'edit', icon: 'fas fa-edit', type: 'primary', color: 'primary', onClick: () => _gotoCustomerEdit(vm.model.project.delivery_cust_no) },
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', color: 'primary', onClick: () => _gotoCustomerMenu(vm.model.project.delivery_cust_no) },
                ],
                shiftButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.model.project.shift_id = '' }
                ],
                businessCompanies: [],
                shiftTypes: [],
                minutePrecisions: [],
                pricelist: [],
                paymentTypes: [],
                selectListTaxes: [],
                projectManagers: [],
                lockedSave: false
            };

            vm.options = {
                datetime: {
                    date: { show: true, disabled: false, align: 'right' },
                    hour: { show: false },
                    minute: { show: false }
                },
                tax: {
                    autoselect: true,
                    decimals: 2
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            async function _gotoCustomerEdit(custNo, options) {
                if (options?.delivery && custNo === '0') {
                    await _rememberNewDeliveryCustomer();
                }

                stateService.go('customeredit', { cust_no: custNo });
            };

            function _gotoCustomerMenu(custNo) {
                stateService.go('customersmenu', { cust_no: custNo });
            };

            // #region SEARCH

            vm.searchCustomer = function (value) {
                return typeaheadService.searchCustomer({ cust_name: value, filtertype: '', filtervalue: '', custtype: 'C', lastselect: 'SEARCH' });
            };

            vm.onCustomerSelected = async function (item) {
                vm.model.project.cust_name = item?.item_name ?? '';
                vm.model.project.cust_no = item?.item_id ?? '0';

                if (item?.item_id > '0') {
                    vm.model.project.cust_name = await _getCustomerName(item.item_id) ?? '';
                }
            };

            vm.onDeliveryCustomerSelected = async function (item) {
                vm.model.project.show_delivery_cust_name = item?.item_name ?? '';
                vm.model.project.delivery_cust_no = item?.item_id ?? '0';

                if (item?.item_id > '0') {
                    vm.model.project.show_delivery_cust_name = await _getCustomerName(item.item_id) ?? '';
                }
            };

            // #endregion SEARCH

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onBusinessCoChanged = function (value) {
                vm.model.project.businessco_no = value;

                if (value) {
                    _getProjectManagers();
                }
            };

            vm.onTaxKeynoChanged = async function (value) {
                vm.model.project.tax_keyno = value;

                if (value) {
                    await _getTax();
                    recalcContractAmountInclusiveTax();
                    recalcMaxOutstandingAmountInclusiveTax();
                }
            };

            vm.onMaxOutstandingPercentageChanged = async function (value) {
                vm.model.project.max_outstanding_pst = value;

                if (value) {
                    recalcMaxOutstandingAmountExclusiveTax();
                    recalcMaxOutstandingAmountInclusiveTax();
                }
            };

            vm.onContractAmountExTaxChanged = async function (value) {
                vm.model.project.contract_amount_ex_tax = value;

                if (value) {
                    recalcContractAmountInclusiveTax();
                    recalcMaxOutstandingAmountExclusiveTax();
                    recalcMaxOutstandingAmountInclusiveTax();
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                projectService.saveObj(vm.model.project).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region CALCULATIONS

            function recalcContractAmountInclusiveTax() {
                vm.model.project.contract_amount_incl_tax = utilityService.parseNumber(vm.model.project.contract_amount_ex_tax) + (utilityService.parseNumber(vm.model.project.contract_amount_ex_tax) * (utilityService.parseNumber(vm.model.project.tax_pst) / 100));
            };

            function recalcMaxOutstandingAmountExclusiveTax() {
                vm.model.project.max_outstanding_amount_ex_tax = utilityService.parseNumber(vm.model.project.contract_amount_ex_tax) * (utilityService.parseNumber(vm.model.project.max_outstanding_pst) / 100);
            };

            function recalcMaxOutstandingAmountInclusiveTax() {
                vm.model.project.max_outstanding_amount_incl_tax = utilityService.parseNumber(vm.model.project.contract_amount_incl_tax) * (utilityService.parseNumber(vm.model.project.max_outstanding_pst) / 100);
            };

            // #endregion CALCULATIONS

            // #region REMEMBER

            function _rememberNewDeliveryCustomer() {
                return Promise.all([
                    projectService.remember('customer.new_customer_in_progress', '1'),
                    projectService.remember('w_project.new_cust_to_field', 'delivery_cust_no')
                ]);
            };

            // #endregion REMEMBER

            // #region GETTERS

            async function _getProject() {
                return vm.model.project = (await projectService.getProject({ project_keyno: $stateParams.project_keyno }))[0];
            };

            async function _getTax() {
                if (vm.model.project.tax_keyno) {
                    return vm.model.project.tax_pst = (await economyService.getTax({ tax_keyno: vm.model.project.tax_keyno }))[0].tax_pst;
                }
            };

            async function _getProjectManagers() {
                if (vm.model.project?.businessco_no) {
                    return vm.model.projectManagers = await projectService.listProjectManagers();
                }
            };

            async function _getTaxFees() {
                return vm.model.selectListTaxes = await economyService.listTaxes();
            };

            async function _getPaymentTypes() {
                vm.model.paymentTypes = await economyService.listPayments();
            };

            async function _getPricelist() {
                return vm.model.pricelist = await economyService.listPrices();
            };

            async function _getTimeRegisterMinutesPrecision() {
                return vm.model.minutePrecisions = await projectService.loadTimeRegisterMinutesPrecision();
            };

            async function _getShiftTypes() {
                return vm.model.shiftTypes = await economyService.listShifts({ only_active: '1' });
            };

            async function _getBusinessCompanies() {
                return vm.model.businessCompanies = await logisticService.listCompanies();
            };

            async function _getCustomerName(custNo) {
                return (await projectService.getP2CustomerGetEdit(custNo))[0].cust_name;
            };

            async function _translate() {
                const data = await translateService.translateBatch(translations);

                Object.keys(translations).forEach((key) => {
                    if (data?.[key]) translations[key] = data[key];
                });
            };

            // #endregion GETTERS

            // #region ANGULAR LIFECYCLE HOOKS

            vm.$onInit = async function () {
                _getBusinessCompanies();
                _getShiftTypes();
                _getTimeRegisterMinutesPrecision();
                _getPaymentTypes();
                _getPricelist();
                _getPaymentTypes();
                _getTaxFees();
                await _getProject();
                _getProjectManagers();
                _translate();
            };

            // #endregion ANGULAR LIFECYCLE HOOKS

        }]
    });
})();
