(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('bdScanDetail', {
        templateUrl: 'views/components/views/bdScanDetail/bdScanDetail.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'bdScanDetailService', function ($stateParams, $q, stateService, bdScanDetailService) {
            const vm = this;
            let batchDeliveryKeyno = $stateParams.batchdelivery_keyno;
            let purchInternalNo = $stateParams.purch_internal_no;
            let purchLineKeyno = $stateParams.purchline_keyno;
            let prodSizeColliKeyno = $stateParams.prodsizecolli_keyno;

            vm.model = {
                quantityRestInColliesButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('purchhead', { purch_internal_no: vm.model.details.purch_internal_no }) }
                ],
                numberOfColliesButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('batchdelreceivelines', {
                            batchdelivery_keyno: vm.model.details.batchdelivery_keyno,
                            purch_internal_no: vm.model.details.purch_internal_no,
                            purchline_keyno: vm.model.details.purchline_keyno,
                            prodsizecolli_keyno: vm.model.details.prodsizecolli_keyno,
                            stocklocation_keyno: '0',
                            controlled: '1',
                            placed: '0'
                        })
                    }
                ],
                numberOfColliesPlacedButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('batchdelreceivelines', {
                            batchdelivery_keyno: vm.model.details.batchdelivery_keyno,
                            purch_internal_no: vm.model.details.purch_internal_no,
                            purchline_keyno: vm.model.details.purchline_keyno,
                            prodsizecolli_keyno: vm.model.details.prodsizecolli_keyno,
                            stocklocation_keyno: '0',
                            controlled: '0',
                            placed: '1'
                        })
                    }
                ],
                deliveredQuantityBeforeButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('batchdeliveries', {
                            argtype: 'purch_internal_no',
                            argvalue: vm.model.details.purch_internal_no
                        })
                    }
                ],
                details: {},
                itemsListPlaced: []
            };

            let loadDetails = function () {
                const deferred = $q.defer();

                bdScanDetailService.getDetails({
                    batchdelivery_keyno: batchDeliveryKeyno,
                    purch_internal_no: purchInternalNo,
                    purchline_keyno: purchLineKeyno,
                    prodsizecolli_keyno: prodSizeColliKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.details);

                    loadScanDetailsPlaced();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadScanDetailsPlaced = function () {
                return bdScanDetailService.listScanDetailsPlaced(vm.model.details).then(function (data) {
                    angular.copy(data, vm.model.itemsListPlaced);
                });
            };

            vm.$onInit = function () {
                loadDetails();
            };
		}]
	});
})();
