(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectReport', {
        templateUrl: 'views/components/views/selectReport/selectReport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'dbReportService', function ($stateParams, dbReportService) {
            var vm = this;
            
            vm.model = {
                webPageName: $stateParams.webpage_name,
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                reports: []
            };
            
            dbReportService.loadReports(vm.model.webPageName, vm.model.argType, vm.model.argValue).then(function (reports) {
                angular.copy(reports, vm.model.reports);
            });
        }]
    });
})();