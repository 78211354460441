(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businesscoSettingSmtp', {
        templateUrl: 'views/components/views/businesscoSettingSmtp/businesscoSettingSmtp.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'businesscoSettingSmtpService', function ($stateParams, stateService, modalService, translateService, businesscoSettingSmtpService) {
            const vm = this;
            let businessCoNo = $stateParams.businessco_no;

            let translations = {
                confirmation: '',
                ok: ''
            };

            vm.model = {
                smtpAuthPassword: '',
                edit: {},
                lockedSave: false,
                testingEmail: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                businesscoSettingSmtpService.getEdit({ businessco_no: businessCoNo }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                vm.model.edit.smtp_auth_password = vm.model.smtpAuthPassword;

                businesscoSettingSmtpService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.testEmailSend = function () {
                vm.model.testingEmail = true;
                vm.model.edit.smtp_auth_password = vm.model.smtpAuthPassword;

                businesscoSettingSmtpService.saveObj(vm.model.edit);

                businesscoSettingSmtpService.testSmtpEmail({ businessco_no: businessCoNo }).then(function (response) {
                    modalService.show({
                        type: 'success',
                    	title: translations.confirmation,
                        message: response[0].resultmessage,
                    	buttons: [{
                    		label: translations.ok,
                            cssClass: 'btn-success',
                    		action: function (dialogItself) {
                    			dialogItself.close();
                                vm.model.testingEmail = false;
                    		}
                    	}]
                    });
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
		}]
	});
})();
