(function() {
    'use strict';

    angular.module("imApp").factory("epBlockOrderPlanService", ['$q', '$ihttp', 'printService', function ($q, $ihttp, printService) {
        var service = {
            init: function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push($ihttp.post({
                    method: 2007,
                    parameters: {}
                }));

                promises.push(printService.getReports());

                $q.all(promises).then(function (result) {
                    var settings = {
                        report: {
                            keyno: 0,
                            name: result[0][0].report_name
                        }
                    };

                    for (var i = 0; i < result[1].items.length; i++) {
                        if (result[1].items[i].reportId !== settings.report.name)
                            continue;

                        settings.report.keyno = result[1].items[i].reportKeyno;

                        break;
                    }

                    deferred.resolve(settings);
                });

                return deferred.promise;
            },
        }

        return service;
    }]);
})();
