(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDelPurchLinesBsService", ['$ihttp', function ($ihttp) {
        var service = {
            getBatchDeliveryPurchaseLinesBs: function (parms) {
                return $ihttp.post({
                    method: 299,
                    parameters: parms || {}
                });
            },
            listBatchPurchaseLines: function (parms) {
                return $ihttp.post({
                    method: 288,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 291,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();