(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("docArticleTopUserGroupService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadDocArticleTopUserGroupGet: function (docarticletopusergroup_keyno) {
				return p2DataTaskService.call(1383, {
					docarticletopusergroup_keyno: docarticletopusergroup_keyno
				});
			},
			save: function (userGroup) {
				return p2DataTaskService.call(1384, userGroup);
			}
		};

		return service;
	}]);
})();