(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('interBranchInvoiceOverview', {
        templateUrl: 'views/components/views/interBranchInvoiceOverview/interBranchInvoiceOverview.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['interBranchInvoiceOverviewService', 'ttGridFactory', function (interBranchInvoiceOverviewService, ttGridFactory) {
            let vm = this;
            let onDestroy = [];

            vm.model = {
                rememberId: 'w_interBranchInvoiceOverview',
                date: {},
                gridReady: false,
            }

            vm.grid = new ttGridFactory({
                rememberId: 'w_interBranchInvoiceOverview.grid',
                loadData: {
                    method: 3066,
                    parameters: {
                        date_fom: '',
                        date_tom: ''
                    }
                }
            }).setKendoConfig({ height: '100%' });

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.grid.dataTask.loadData.parameters.date_fom = vm.model.date.date_fom;
                vm.grid.dataTask.loadData.parameters.date_tom = vm.model.date.date_tom;

                rememberFunc();

                vm.grid.gridfunc.rebind();
            };

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            let rememberFunc = function () {
                interBranchInvoiceOverviewService.remember(vm.model.rememberId, vm.model.date);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                interBranchInvoiceOverviewService.getRemember(vm.model.rememberId).then(function (response) {
                    if (response?.[0]?.variablevalue && response[0].variablevalue !== '') {
                        vm.model.date = angular.copy(angular.fromJson(response[0].variablevalue));

                        vm.grid.dataTask.loadData.parameters.date_fom = vm.model.date.date_fom;
                        vm.grid.dataTask.loadData.parameters.date_tom = vm.model.date.date_tom;

                        //vm.grid.gridfunc.rebind();
                    }

                    vm.model.gridReady = true;
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
