(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('newProcedureDocumentation', {
        templateUrl: 'views/components/views/newProcedureDocumentation/newProcedureDocumentation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['newProcedureDocumentationService', function (newProcedureDocumentationService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                lockedSave: false,
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],

                bgNewProcedureDocumentation: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_newproceduredocumentation_not_documented', item_func: 'NOTDOCUMENTED' },
                        { item_id: '1', item_name: 'bg_newproceduredocumentation_documented', item_func: 'DOCUMENTED' }
                    ]
                },

                procedureDocumentationList: {
                    records: []
                }
            };

            vm.bgNewProcedureDocumentation = function (item) {
                vm.model.bgNewProcedureDocumentation.item_selected = item;
            };

            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.searchText = '';
                        break;
                    default:
                        break;
                }
            };

            vm.saveChanges = function () {
                console.log("clicked save button");
            };

            newProcedureDocumentationService.getChangelog().then(data => {
                console.log(data);
                angular.copy(data, vm.model.procedureDocumentationList.records);
            });
            

        }]
    });
})();
