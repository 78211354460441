(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListExpense', {
        templateUrl: 'views/components/views/srCheckListExpense/srCheckListExpense.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srRefundInputCrewService', 'srCheckListExpenseService', function($stateParams, stateService, utilityService, srRefundInputCrewService, srCheckListExpenseService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                srRefundInputKeyNo: $stateParams.srrefundinput_keyno,
                expense: {},
                itemsListInputs: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListExpenseService.loadSrCheckListExpenseGet(vm.model.checkListDataKeyNo, vm.model.srRefundInputKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.expense);
            });
    
            srRefundInputCrewService.loadSrRefundInputsList(vm.model.srRefundInputKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListInputs);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'pictures':
                        go({
                            argtype: 'srrefundinput_keyno',
                            argvalue: vm.model.expense.srrefundinput_keyno
                        });
                        break;
                    case 'documents':
                        go({
                            argtype: 'srrefundinput_keyno',
                            argvalue: vm.model.expense.srrefundinput_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrCheckListExpense = function () {
                vm.model.lockedSave = true;
                
                srCheckListExpenseService.save(vm.model.expense).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();