(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('business', {
        templateUrl: 'views/components/views/business/business.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'businessService', function ($stateParams, stateService, businessService) {
            let vm = this;
            let businessId = $stateParams.business_id;

            vm.model = {
                businessId: businessId,
                edit: {},
                lockedSave: false
            };

            let loadEdit = function () {
                businessService.getBusiness({ business_id: businessId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                businessService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
		}]
	});
})();
