(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderHeadCreateIbPurchService", ['$ihttp', function ($ihttp) {
        var service = {
            getOrderHeadCreateIbPurch: function (parms) {
                return $ihttp.post({
                    method: 736,
                    parameters: parms || {}
                });
            },
            createObj: function (edit) {
                return $ihttp.post({
                    method: 737,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();