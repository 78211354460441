(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("serviceObjectNewActService", ['$ihttp', function ($ihttp) {
        let service = {
            getSettings: function (parms) {
                return $ihttp.post({
                    method: 1748,
                    parameters: parms || {}
                });
            },
            listNewActs: function (parms) {
                return $ihttp.post({
                    method: 1749,
                    parameters: parms || {}
                });
            },
            createObj: function (mergeObj) {
                return $ihttp.post({
                    method: 1750,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
