(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderDialogService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadOrderDialogSettings: function (order_internal_no) {
                return p2DataTaskService.call(1279, {
                    order_internal_no: order_internal_no
                });
            },
            loadOrderDialogList: function (order_internal_no) {
                return p2DataTaskService.call(1281, {
                    order_internal_no: order_internal_no
                });
            },
            save: function (orderDialog) {
                return p2DataTaskService.call(1280, orderDialog);
            }
        };

        return service;
    }]);
})();