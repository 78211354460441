(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishProdWork', {
        templateUrl: 'views/components/views/poFinishProdWork/poFinishProdWork.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishProdWorkService', function ($stateParams, poFinishProdWorkService) {
            var vm = this;
            
            poFinishProdWorkService.loadP2PoFinishProdWorkGet($stateParams.p2_pofinishprodwork_keyno).then(function () {
                vm.p2_pofinishprodwork_get = poFinishProdWorkService.p2_pofinishprodwork_get;
            });
        }]
    });
})();