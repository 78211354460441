(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('printPreview', {
        templateUrl: 'views/components/views/printPreview/printPreview.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$sce', '$stateParams', 'stateService', 'printService', 'ieScreenBlockService', 'printPreviewService', 'htmlReportService', 'base64', 'pdfService', function ($sce, $stateParams, stateService, printService, ieScreenBlockService, printPreviewService, htmlReportService, base64, pdfService) {
            var vm = this;

            vm.model = {
                reportKeyno: $stateParams.report_keyno,
                printerKeyno: $stateParams.printer_keyno,
                argtype: $stateParams.argtype,
                argvalue: $stateParams.argvalue,
                previewLink: '',
                p2ReportDef: {},
                report: {},
                reportHtml: {}
            };

            const load = async function () {
                ieScreenBlockService.start();

                printPreviewService.loadP2ReportDef($stateParams.report_keyno).then(async function (data) {

                    vm.model.p2ReportDef = angular.copy(data[0]);

                    const parms = {
                        reportKeyno: vm.model.p2ReportDef.printserver_keyno,
                        parameters: { [vm.model.argtype]: vm.model.argvalue }
                    };

                    let url = '';

                    switch (vm.model.p2ReportDef.report_type_keyno) {
                        case '1':
                            url = await printService.preview(parms);
                            break;
                        case '2':
                            url = await pdfService.preview({
                                reportKeyno: vm.model.reportKeyno,
                                parameters: { [vm.model.argtype]: vm.model.argvalue }
                            });
                            break;
                        default:
                            console.error('Invalid report type in printPreview.component for report keyno: ' + $stateParams.report_keyno);
                            break;
                    }
                    if (url) {
                        vm.model.previewLink = $sce.trustAsResourceUrl(url);
                    }
                    ieScreenBlockService.stop();
                });
            }

            load();

            vm.print = async function () {
                if (vm.model.printerKeyno < 1) {
                    console.error('No printer selected');
                    return;
                }

                const parms = {
                    printerKeyno: vm.model.printerKeyno,
                    reportKeyno: vm.model.p2ReportDef.printserver_keyno,
                    parameters: { [vm.model.argtype]:vm.model.argvalue }
                }
                
                ieScreenBlockService.start();

                switch (vm.model.p2ReportDef.report_type_keyno) {
                    case '1':
                        parms.printerProperties = {
                            isLandscape: vm.model.p2ReportDef.isLandscape,
                            pageAutoSize: true
                        };
                        await printService.print(parms);
                        break;
                    case '2':
                        parms.reportKeyno = vm.model.reportKeyno;
                        await pdfService.printPDF(parms);
                        break;
                    default:
                        console.error('Invalid report type in printPreview.component for report keyno: ' + $stateParams.report_keyno);
                        break;
                }
                ieScreenBlockService.stop();
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'emailcreate':
                        go({
                            webpage: stateService.getCurrentName(),
                            argtype: $stateParams.argtype,
                            argvalue: $stateParams.argvalue
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();
