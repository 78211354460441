(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bspSetup', {
        templateUrl: 'views/components/views/bspSetup/bspSetup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'modalService', 'translateService', 'bspSetupService', function (stateService, modalService, translateService, bspSetupService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListBsps: [],
                lockedExecute: false,
                lockedRollback: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                bspSetupService.getEdit().then(function (info) {
            		angular.copy(info[0], vm.model.edit);
            	});
            };

            let loadBsps = function () {
                bspSetupService.listBsps().then(function (list) {
                    angular.copy(list, vm.model.selectListBsps);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.executeChanges = function () {
                vm.model.lockedExecute = true;

                bspSetupService.executeObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedExecute = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedExecute = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.rollbackValues = function () {
                vm.model.lockedRollback = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    bspSetupService.rollbackItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedRollback = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedRollback = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedRollback = false;
                    });
                }, function () {
                    vm.model.lockedRollback = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
                loadBsps();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
