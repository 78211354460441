(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('timeRegisterStart', {
        templateUrl: 'views/components/views/trStart/trStart.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['trStartService', 'stateService', function (trStartService, stateService) {
            var vm = this;
            
            vm.filter_p2_labour_select_scan = {
                barcode: '',
                webpage_from: '',
                records: []
            };
    
            vm.state = {
                loading: false
            };
            
            trStartService.loadP2LabourListTr().then(function () {
                vm.p2_labour_list_tr = trStartService.p2_labour_list_tr;
                vm.setFocusToSearchField();
            });
    
            trStartService.loadP2WTrStart().then(function () {
                vm.p2_w_trstart = trStartService.p2_w_trstart;
            });
    
            vm.searchP2LabourSelectScan = function () {
                if (angular.isUndefined(vm.filter_p2_labour_select_scan.barcode) || vm.filter_p2_labour_select_scan.barcode.length == 0) return;

                vm.state.loading = true;

                vm.filter_p2_labour_select_scan.records.unshift({
                    barcode: vm.filter_p2_labour_select_scan.barcode
                });

                trStartService.searchP2LabourSelectScan(vm.filter_p2_labour_select_scan.records[0].barcode, stateService.getCurrentName()).then(function () {
                    vm.p2_labour_select_scan = trStartService.p2_labour_select_scan;
                    vm.state.loading = false;

                    if (angular.isDefined(vm.p2_labour_select_scan)) {
                        stateService.go(vm.p2_labour_select_scan.webpagename, {
                            labour_no: vm.p2_labour_select_scan.labour_no
                        });

                        if (angular.isUndefined(vm.p2_labour_select_scan.webpagename) && angular.isUndefined(vm.p2_labour_select_scan.labour_no) || vm.p2_labour_select_scan.webpagename == '' && vm.p2_labour_select_scan.labour_no == '') {
                            vm.p2_labour_select_scan.webpagename = 'trstart';
                        }

                        vm.clearField();
                    } else {
                        vm.clearField();
                    }
                });
            };
            
            vm.clearField = function () {
                if (vm.filter_p2_labour_select_scan.barcode !== '' && vm.filter_p2_labour_select_scan.records.length > 0 && vm.state.loading == false) {
                    vm.filter_p2_labour_select_scan.barcode = '';
                }
            };

            // Hotfix for setting initial focus to the search field
            vm.setFocusToSearchField = function () {
                let field1 = document.getElementById("trstartsearchfield_1");
                if (null !== field1) {
                    field1.children[0].children[0].children[1].children[0].children[0].focus();
                }
                let field2 = document.getElementById("trstartsearchfield_2");
                if (null !== field2) {
                    field2.children[0].children[0].children[1].children[0].children[0].focus();
                }


            }
        }]
    });
})();
