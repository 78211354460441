(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ediExportService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadEdiExportGet: function (edi_export_keyno) {
                return p2DataTaskService.call(1172, {
                    edi_export_keyno: edi_export_keyno
                });
            },
            loadEdiDestinationList: function () {
                return p2DataTaskService.call(987, {});
            },
            loadExportProcList: function () {
                return p2DataTaskService.call(891, {});
            }
        };

        return service;
    }]);
})();