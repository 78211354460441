(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("pohCompAddService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            p2_pohcompadd_get: {
                prod_id: '',
                prod_name: '',
                stock_keyno: '',
                status: '',
                quantity: '',
                batch_no: '',
                stockbatch_keyno: '',
                quantity_original: '',
                stock_stockvalue: ''
            },
            loadP2PohCompAddGet: function (poh_keyno, stock_keyno) {
                var deferred = $q.defer();

                var parms_p2_pohcompadd_get = {
                    method: 158,
                    parameters: {
                        poh_keyno: poh_keyno,
                        stock_keyno: stock_keyno
                    }
                };

                $ihttp.post(parms_p2_pohcompadd_get).then(function (data) {
                    service.p2_pohcompadd_get = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            savePohCompAdd: function () {
                var deferred = $q.defer();

                var parms_pohcompadd_save = {
                    method: 162,
                    parameters: {
                        poh_keyno: $stateParams.poh_keyno,
                        stock_keyno: $stateParams.stock_keyno,
                        prod_id: service.p2_pohcompadd_get.prod_id,
                        prod_name: service.p2_pohcompadd_get.prod_name,
                        status: service.p2_pohcompadd_get.status,
                        quantity: service.p2_pohcompadd_get.quantity,
                        batch_no: service.p2_pohcompadd_get.batch_no,
                        stockbatch_keyno: service.p2_pohcompadd_get.stockbatch_keyno,
                        quantity_original: service.p2_pohcompadd_get.quantity_original,
                        stock_stockvalue: service.p2_pohcompadd_get.stock_stockvalue
                    }
                };

                $ihttp.post(parms_pohcompadd_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();