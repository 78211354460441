(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srEquipmentResourceViewService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrEquipmentResourceViewGet: function () {
                return p2DataTaskService.call(1821, {});
            },
            loadSrRefundTypeIsEquipmentList: function (parms) {
                return p2DataTaskService.call(1795, parms);
            },
            loadSrEquipmentResourceViewList: function (sub_resource_id, srrefundtype_id) {
                return p2DataTaskService.call(1825, {
                    sub_resource_id: sub_resource_id,
                    srrefundtype_id: srrefundtype_id
                });
            }
        };

        return service;
    }]);
})();