(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('materialRegister', {
        templateUrl: 'views/components/views/materialRegister/materialRegister.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'materialRegisterService', 'logisticService', 'rememberService', function ($stateParams, stateService, utilityService, modalService, translateService, materialRegisterService, logisticService, rememberService) {
            
            // #region VARIABLES & DEFINITIONS
            
            var vm = this;
            var materialType = $stateParams.materialtype;
            
            let variableNames = {
                projectprosess_keyno: ''
            };
            
            let translations = {
                error: '',
                confirmation: '',
                ok: ''
            };
            
            vm.model = {
                custactNo: $stateParams.custact_no,
                powTimeKeyNo: $stateParams.powtime_keyno,
                pKey: $stateParams.pkey,
                productButtons: [
                    {
                        id: 'goto',
                        icon: 'glyphicon glyphicon-search',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('selectproduct', {
                            webpage_name: stateService.getCurrentName(),
                            parm1: vm.model.edit.custact_no,
                            parm2: vm.model.edit.powtime_keyno
                        })
                    }
                ],
                edit: {},
                selectListProcesses: [],
                selectListDepartments: [],
                p2WMaterialRegisterLastList: {
                    records: []
                },
                searchingBarcode: false,
                lockedSaveAndNew: false,
                lockedSave: false
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadMaterialRegister = function () {
                materialRegisterService.getMaterialRegister({
                    custact_no: vm.model.custactNo,
                    powtime_keyno: vm.model.powTimeKeyNo,
                    pkey: vm.model.pKey,
                    materialtype: materialType
                }).then(function (data) {
                    angular.copy(data[0], vm.model.edit);

                    loadProcesses();
                    loadDepartments();
                });
            };

            loadMaterialRegister();

            var loadP2WMaterialRegisterLastList = function () {
                vm.model.p2WMaterialRegisterLastList.records = [];
    
                materialRegisterService.loadP2WMaterialRegisterLastList($stateParams.custact_no).then(function (data) {
                    angular.copy(data, vm.model.p2WMaterialRegisterLastList.records);
                });
            };
    
            loadP2WMaterialRegisterLastList();
            
            let loadProcesses = function () {
                if (utilityService.validateParmsValue(vm.model.edit.custact_no) !== true) return;

                return materialRegisterService.listProcesses({ custact_no: vm.model.edit.custact_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListProcesses);
                });
            };
            
            let loadDepartments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return logisticService.listDepartment({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListDepartments);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'projectprosess_keyno':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON SCAN PROCEDURE FUNCTION CALL

            vm.scanBarcode = function () {
                vm.model.searchingBarcode = true;

                if (utilityService.validateParmsValue(vm.model.edit.barcode) !== true) return;

                materialRegisterService.scanObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.searchingBarcode = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.searchingBarcode = false;
                        vm.model.edit.prod_id = data[0].prod_id;
                        vm.model.edit.prod_name = data[0].prod_name;
                        vm.model.edit.unit_name = data[0].unit_name;
                    }
                });
            };

		    // #endregion BUTTON SCAN PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL
            
            vm.saveAndNewChanges = function () {
                vm.model.lockedSaveAndNew = true;

                materialRegisterService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode < 0) {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveAndNew = false;
                                }
                            }]
                        });
                    } else if (response[0].errorcode > 0) {
                        modalService.show({
                            type: 'success',
                            title: translations.confirmation,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-success',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveAndNew = false;
                                    loadMaterialRegister();
                                    loadP2WMaterialRegisterLastList();
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSaveAndNew = false;
                    }
                });
            };
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                materialRegisterService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode < 0) {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else if (response[0].errorcode > 0) {
                        modalService.show({
                            type: 'success',
                            title: translations.confirmation,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-success',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                    stateService.back();
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'projectprosess_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.projectprosess_keyno) !== true) return;

                        vm.model.edit.projectprosess_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.projectprosess_keyno) !== true) return;

                        rememberFunc('projectprosess_keyno');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
