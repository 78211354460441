(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactCalcPickSelect', {
        templateUrl: 'views/components/views/custactCalcPickSelect/custactCalcPickSelect.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'utilityService', 'custactCalcPickSelectService', 'rememberService', function ($stateParams, $timeout, stateService, utilityService, custactCalcPickSelectService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            var variableNames = {
                filtervalue: ''
            };

		    var timer = {
                filtervalue: null
		    };

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.onFilterValueChanged('') },
                ],
                p2WCustactCalcPickSelectSettings: {},
                p2WCustactCalcPickSelectList: {
                    records: []
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            var loadP2WCustactCalcPickSelectSettings = function () {
                custactCalcPickSelectService.genericFunction(875, {
                    p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                    custact_no: $stateParams.custact_no
                }).then(function (data) {
                    vm.model.p2WCustactCalcPickSelectSettings = angular.copy(data[0]);
                });
            };
            
            var loadP2WCustactCalcPickSelectList = function () {
                custactCalcPickSelectService.genericFunction(871, {
                    p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                    custact_no: $stateParams.custact_no
                }).then(function (data) {
                    angular.copy(data, vm.model.p2WCustactCalcPickSelectList.records);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                if (angular.isDefined(timer[id])) {
                    $timeout.cancel(timer[id]);
                }

                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'filtervalue':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + 'filtervalue' + '/' + $stateParams.p2_pofinish_keyno + '/' + $stateParams.custact_no;
                    		break;
                    	default:
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.p2WCustactCalcPickSelectSettings.filtervalue, true) !== true) return;

                        variableValue = vm.model.p2WCustactCalcPickSelectSettings.filtervalue;
                        break;
                    default:
                        break;
                }

                if (vm.model.p2WCustactCalcPickSelectSettings.filtervalue > '') {
                    timer[id] = $timeout(function () {
                        rememberService.remember(variableNames[id], variableValue);
                    }, 1000);
                } else {
                    rememberService.remember(variableNames[id], variableValue);
                }
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region CHANGE FUNCTIONS

            vm.onFilterValueChanged = function (value) {
                vm.model.p2WCustactCalcPickSelectSettings.filtervalue = value;

                if (value || value?.trim() === '') rememberFunc('filtervalue');
            }

            // #endregion CHANGE FUNCTIONS

            // #region ANGULAR LIFECYCLE

            vm.$onInit = function () {
                loadP2WCustactCalcPickSelectSettings();
                loadP2WCustactCalcPickSelectList();
            }

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

		    // #endregion ANGULAR LIFECYCLE
        }]
    });
})();