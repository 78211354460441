
// ##################################################################
// ## Author: 
// ## Description: 
// ## Dependencies: 
// ## Date modified: 01.01.2019       
// ## Date registered: 15.10.2018
// ##################################################################

(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('stockCountMenu', {
        templateUrl: 'views/components/views/stockCountMenu/stockCountMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$location', 'stateService', 'consoleService', 'modalService', 'stockCountMenuService', function($stateParams, $timeout, $location, stateService, consoleService, modalService, stockCountMenuService) {
    
            var vm = this;
    
    
    
            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################
    
            vm.model = {
                spin: false,
                //spinRefresh: false,
                locked: false,
                p2WStockCountMenuGet: {},
                p2WStockCountMenuDataMiningItem: {},
                //p2WStockCountMenuStockCountRefresh: {}
            };
    
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
    
            // Tip: $stateParams | stateService.getCurrentName()
            var loadP2WStockCountMenuGet = function () {
                stockCountMenuService.loadP2WStockCountMenuGet($stateParams.stockcount_keyno).then(function (data) {
                    vm.model.p2WStockCountMenuGet = angular.copy(data[0]);
                });
            };
    
            loadP2WStockCountMenuGet();
    
    		//##########################
    		//## APPROVE BUTTON FUNCTION
    		//##########################
    
            vm.approveStockCountMenu = function () {
                if (vm.model.p2WStockCountMenuGet.floating_location === '1') {
                    stateService.go('stockcountapprove', {
                        stockcount_keyno: vm.model.p2WStockCountMenuGet.stockcount_keyno
                    });
                } else {
                    vm.model.spin = true;
    
                    stockCountMenuService.approveP2WStockCountMenu(vm.model.p2WStockCountMenuGet).then(function (data) {
                        loadP2WStockCountMenuGet();
                    
                        vm.model.spin = false;
                    });
                }
            };
    
    		//#############################
    		//## DIRECTION BUTTON FUNCTIONS
    		//#############################
    
            vm.goToStockCountDatas = function () {
                stateService.go('stockcountdatas', {
                    stockcount_keyno: $stateParams.stockcount_keyno
                });
            }
    
            vm.goToDataMiningItems = function () {
                stockCountMenuService.loadP2WStockCountMenuDataMiningItem(vm.model.p2WStockCountMenuGet).then(function (data) {
                    vm.model.p2WStockCountMenuDataMiningItem = angular.copy(data[0]);
                    
                    stateService.go(vm.model.p2WStockCountMenuDataMiningItem.item_state, vm.model.p2WStockCountMenuDataMiningItem.item_parms);
                });
            }
    
            vm.goToStockCount = function () {
                stateService.go('stockcount', {
                    stockplace_id: vm.model.p2WStockCountMenuGet.stockplace_id,
                    stockcount_keyno: vm.model.p2WStockCountMenuGet.stockcount_keyno
                });
            }
    
            vm.goToStockCountRefresh = function () {
                stateService.go('stockcountrefresh', {
                    stockcount_keyno: vm.model.p2WStockCountMenuGet.stockcount_keyno
                });
            }
    
    		//##########################
    		//## REFRESH BUTTON FUNCTION
    		//##########################
    
            //vm.refreshStock = function () {
            //    //consoleService.log('refreshStock');
            //    vm.model.spinRefresh = true;
    
            //    stockCountMenuService.refreshP2WStockCountMenuStockCount(vm.model.p2WStockCountMenuGet.stockcount_keyno).then(function (data) {
            //        vm.model.p2WStockCountMenuStockCountRefresh = angular.copy(data[0]);
            //        vm.model.spinRefresh = false;
    
            //        loadP2WStockCountMenuGet();
            //    });
            //}
    
            //#########################
            //## DELETE BUTTON FUNCTION
            //#########################
    
            vm.deleteStockCountMenu = function () {
                //consoleService.log('deleteStockCountMenu --- p2WStockCountMenuGet: ', angular.toJson(vm.model.p2WStockCountMenuGet));
                vm.model.locked = true;
    
                stockCountMenuService.deleteP2WStockCount(vm.model.p2WStockCountMenuGet).then(function (data) {
                    stateService.back();
                    vm.model.locked = false;
                });
            }
    
        }]
    });
})();
