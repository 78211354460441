(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('consignmentLineSelect', {
        templateUrl: 'views/components/views/consignmentLineSelect/consignmentLineSelect.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'consignmentLineSelectService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, consignmentLineSelectService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let consignmentKeyno = $stateParams.consignment_keyno;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                setting: {},
                itemsListConsignmentLines: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                consignmentLineSelectService.getSetting({ consignment_keyno: consignmentKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadConsignmentLines();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadConsignmentLines = function () {
                if (utilityService.validateParmsValue(vm.model.setting.consignment_keyno) !== true) return;

                return consignmentLineSelectService.listConsignmentLines({ consignment_keyno: vm.model.setting.consignment_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListConsignmentLines);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region TOGGLE FUNCTIONS
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #endregion TOGGLE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                consignmentLineSelectService.saveObj({
                    consignment_keyno: vm.model.setting.consignment_keyno,
                    records: vm.model.itemsListConsignmentLines
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
