import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Style } from '@app/core/services/core-component.service';
import { FormButton } from '../form-field-button/form-field-button.component';
import { ComponentBaseComponent } from '../component-base/component-base.component';

/**
 * Represents parameters used to implement the `FormFieldButtonsComponent` on a component.
 */
export interface FormFieldButtons {
    /**
     * Object containing parameters to add to the event handler when a button is pressed.
     */
    ttButtonParms?: { [key: string]: any };

    /**
     * List of buttons to append to the form-field.
     */
    ttButtons?: FormButton[];
}

@Component({
    selector: 'tt-form-field-buttons',
    templateUrl: './form-field-buttons.component.html',
    styleUrls: ['./form-field-buttons.component.css'],
})
export class FormFieldButtonsComponent extends ComponentBaseComponent implements FormFieldButtons {
    @Input()
    ttButtonParms?: { [key: string]: any };

    @Input()
    ttButtons: FormButton[] = [];

    style: Style = {
        button: {},
        icon: {},
    };
}

export type ButtonType = 'success' | 'primary' | 'secondary' | 'warning' | 'danger';
