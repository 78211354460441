(function () {
	'use strict';
	
	angular.module("imApp").factory("mapView3dService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
		var service = {
			getMapView3d: function (parms) {
				return $ihttp.post({
					method: 2858,
					parameters: parms || {}
				});
			},
			sendGpxTrackData: function (gpxtrack_keyno, custact_no, lat, lon, alt, timestamp, track_name ) {
				return p2DataTaskService.call(2870, {
					gpxtrack_keyno: gpxtrack_keyno,
					custact_no: custact_no,
					lat: lat,
					lon: lon,
					alt: alt,
					timestamp: timestamp,
					track_name: track_name
					
					
				});
			},
			receiveGpxTracksData: function (custact_no, is_initialization) {
				return p2DataTaskService.call(2871, {
					custact_no: custact_no,
					is_initialization: is_initialization
				});
			}
		};

		return service;
	}]);
})();