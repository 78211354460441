(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ediDestination', {
        templateUrl: 'views/components/views/ediDestination/ediDestination.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'ftpAccountsService', 'ediDestinationService', function($stateParams, stateService, ftpAccountsService, ediDestinationService) {
            var vm = this;
            
            vm.model = {
                p2EdiDestinationGet: {},
                p2EdiDestinationTypeExtList: {
    				records: []
                },
                p2FtpAccountList: {
                    records: []
                }
            };
            
            ediDestinationService.loadP2EdiDestinationGet($stateParams.edi_destination_keyno).then(function (data) {
                vm.model.p2EdiDestinationGet = angular.copy(data[0]);
    
                vm.model.p2EdiDestinationGet.ftp_remotefolder = atob(vm.model.p2EdiDestinationGet.ftp_remotefolder);
                vm.model.p2EdiDestinationGet.ftp_localfolder = atob(vm.model.p2EdiDestinationGet.ftp_localfolder);
                vm.model.p2EdiDestinationGet.folder_localfolder = atob(vm.model.p2EdiDestinationGet.folder_localfolder);
            });
            
            ediDestinationService.loadP2EdiDestinationTypeExtList().then(function (data) {
                angular.copy(data, vm.model.p2EdiDestinationTypeExtList.records);
            });
            
            ftpAccountsService.loadP2FtpAccountList().then(function (data) {
                angular.copy(data, vm.model.p2FtpAccountList.records);
            });
            
            vm.saveEdiDestination = function () {
                ediDestinationService.saveP2EdiDestination(vm.model.p2EdiDestinationGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();