(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCrewResourceView', {
        templateUrl: 'views/components/views/srCrewResourceView/srCrewResourceView.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'rememberService', 'srCrewResourceViewService', 'watcherFactory', function(stateService, utilityService, rememberService, srCrewResourceViewService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            var variableNames = {
                main_resource_id: '',
                sub_resource_id: ''
            };
    
            vm.model = {
                settingView: {},
                selectListResources: [],
                selectListSubResources: [],
                itemsListResourceViews: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCrewResourceViewService.loadSrCrewResourceViewGet().then(function (data) {
                angular.copy(data[0], vm.model.settingView);
    
                loadSrSubResources();
                loadSrCrewResourceViews();
            });
    
            srCrewResourceViewService.loadSrMainResourceList().then(function (data) {
                angular.copy(data, vm.model.selectListResources);
            });
    
            var loadSrSubResources = function () {
                if (utilityService.validateParmsValue(vm.model.settingView.main_resource_id) !== true) return;
    
                vm.model.selectListSubResources = [];
    
                srCrewResourceViewService.loadSrSubResourceList(vm.model.settingView.main_resource_id).then(function (data) {
                    angular.copy(data, vm.model.selectListSubResources);
                });
            };
    
            var loadSrCrewResourceViews = function () {
                if (utilityService.validateParmsValue(vm.model.settingView.sub_resource_id) !== true) return;
    
                vm.model.itemsListResourceViews = [];
    
                srCrewResourceViewService.loadSrCrewResourceViewList(vm.model.settingView.sub_resource_id).then(function (data) {
                    angular.copy(data, vm.model.itemsListResourceViews);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
    
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
    
                var variableValue = null;
    
                switch (id) {
                    case 'main_resource_id':
                        if (utilityService.validateParmsValue(vm.model.settingView.main_resource_id) !== true) return;
    
                        variableValue = vm.model.settingView.main_resource_id;
                        break;
                    case 'sub_resource_id':
                        if (utilityService.validateParmsValue(vm.model.settingView.sub_resource_id) !== true) return;
    
                        variableValue = vm.model.settingView.sub_resource_id;
                        break;
                    default:
                        break;
                }
    
                rememberService.remember(variableNames[id], variableValue);
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.settingView.main_resource_id; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrSubResources();
                rememberFunc('main_resource_id');
            });
    
            watcher.$watch(function () { return vm.model.settingView.sub_resource_id; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrCrewResourceViews();
                rememberFunc('sub_resource_id');
            });
        }]
    });
})();