(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("shiftPlanService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            dataSource: {},
            getShiftPlan: function (shiftplan_id) {
                var deferred = $q.defer();

                var parms_getShiftPlan = {
                    method: 513,
                    parameters: {
                        shiftplan_id: shiftplan_id
                    }
                };

                $ihttp.post(parms_getShiftPlan).then(function (data) {
                    service.dataSource = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveShiftPlan: function (dataSource) {
                var deferred = $q.defer();

                var parms_saveShiftPlan = {
                    method: 514,
                    parameters: dataSource
                };

                $ihttp.post(parms_saveShiftPlan).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deleteShiftPlan: function (shiftplan_id) {
                var deferred = $q.defer();

                var parms_deleteShiftPlan = {
                    method: 515,
                    parameters: {
                        shiftplan_id: shiftplan_id
                    }
                };

                $ihttp.post(parms_deleteShiftPlan).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();