(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("srOperationService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadSrOperationGet: function (custact_no) {
				return p2DataTaskService.call(1316, {
					custact_no: custact_no
				});
			},
			loadSrOperationResourcesList: function (custact_no) {
				return p2DataTaskService.call(1317, {
					custact_no: custact_no
				});
			},
			loadSrOperationReportsList: function (custact_no) {
				return p2DataTaskService.call(1318, {
					custact_no: custact_no
				});
			},
			//loadGisObjectsGet: function (argtype, argvalue) {
			//	return p2DataTaskService.call(1283, {
			//		argtype: argtype,
			//		argvalue: argvalue
			//	});
			//}
		};

		return service;
	}]);
})();