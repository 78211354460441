(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('departmentMenu', {
        templateUrl: 'views/components/views/departmentMenu/departmentMenu.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'departmentMenuService', function ($stateParams, stateService, modalService, translateService, departmentMenuService) {
            let vm = this;
            let departmentId = $stateParams.department_id;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                departmentButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('departmentlang', { department_id: vm.model.menu.department_id }) }
                ],
                menu: {},
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            let loadMenu = function () {
                departmentMenuService.getDepartmentMenu({ department_id: departmentId }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };
            
            vm.goToFunc = function (state) {
                let go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'department':
                        go({
                            department_id: vm.model.menu.department_id
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;
                
                departmentMenuService.deleteObj(vm.model.menu).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedDelete = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedDelete = false;
                    	stateService.back();
                    }
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
        }]
    });
})();
