(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectProdsizeService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_product_prodsize_list: {
                item_id: '',
                item_name: '',
                orderby: '',
                records: []
            },
            searchP2ProductProdsizeList: function (prodsize_name, webpage_name, parm1, parm2, prod_id) {
                var deferred = $q.defer();

                var parms_p2_product_prodsize_list = {
                    method: 135,
                    parameters: {
                        prodsize_name: prodsize_name,
                        webpage_name: webpage_name,
                        parm1: parm1,
                        parm2: parm2,
                        prod_id: prod_id
                    }
                };

                $ihttp.post(parms_p2_product_prodsize_list).then(function (data) {
                    angular.copy(data, service.p2_product_prodsize_list.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();