(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('docArticleTopUserGroup', {
        templateUrl: 'views/components/views/docArticleTopUserGroup/docArticleTopUserGroup.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'docArticleTopUserGroupService', function($stateParams, stateService, modalService, docArticleTopUserGroupService) {
            var vm = this;
            
    		vm.model = {
    			docArticleTopUserGroupKeyNo: $stateParams.docarticletopusergroup_keyno,
    			userGroup: {},
    			lockedSave: false
    		};
            
    		docArticleTopUserGroupService.loadDocArticleTopUserGroupGet(vm.model.docArticleTopUserGroupKeyNo).then(function (data) {
    			vm.model.userGroup = angular.copy(data[0]);
    		});
            
    		vm.saveDocArticleTopUserGroup = function () {
    			vm.model.lockedSave = true;
    			
    			docArticleTopUserGroupService.save(vm.model.userGroup).then(function (data) {
    				if (data[0].errorcode !== '0') {
    					modalService.show({
    						type: 'warning',
    						title: 'Varsel',
    						message: data[0].errormessage,
    						buttons: [{
    							label: 'OK',
    							cssClass: 'btn-warning',
    							action: function (dialogItself) {
    								dialogItself.close();
    								vm.model.lockedSave = false;
    							}
    						}]
    					});
    				} else {
    					stateService.back();
    					vm.model.lockedSave = false;
    				}
    			});
    		};
    	}]
    });
})();