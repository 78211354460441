(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srRefundBasisService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrRefundBasisGet: function (custact_no, checklistdata_keyno) {
                return p2DataTaskService.call(1587, {
                    custact_no: custact_no,
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrRefundBasisSrCrewList: function (checklistdata_keyno) {
                return p2DataTaskService.call(1815, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrRefundBasisSrEquipmentList: function (checklistdata_keyno) {
                return p2DataTaskService.call(1816, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrRefundBasisResourceList: function (custact_no, checklistdata_keyno) {
                return p2DataTaskService.call(1589, {
                    custact_no: custact_no,
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrRefundBasisCheckListItemList: function (refund) {
                return p2DataTaskService.call(1588, refund);
            },
            loadSrRefundBasisItemList: function (refund) {
                return p2DataTaskService.call(1590, refund);
            },
            loadSrRefundBasisRefundItemList: function (refund) {
                return p2DataTaskService.call(1814, refund);
            }
        };

        return service;
    }]);
})();