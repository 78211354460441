(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ni', {
        templateUrl: 'views/components/views/ni/ni.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['niService', 'stateService', function (niService, stateService) {

            // #########
            // VARIABLES
            // #########

            var vm = this;
            var l_s_input = '';
            var l_b_commapressed = false;

            vm.number_input = {
                number: '0'
            };
            
            // ##########################
            // CALCULATOR NUMBER FUNCTION
            // ##########################
            
            vm.typeNumber = function (num) {
                if (num == '.') {
                    if (l_b_commapressed === false) {
                        l_s_input = l_s_input + num;
                        l_b_commapressed = true;
                    }
                } else {
                    l_s_input = l_s_input + num;
                }

                vm.number_input.number = l_s_input;
            };
            
            // ##########################
            // CALCULATOR DELETE FUNCTION
            // ##########################
            
            vm.deleteNumber = function () {
                vm.number_input.number = '0';
                l_s_input = '';
                l_b_commapressed = false;
            };
            
            // ##########################
            // SAVE INTO SERVICE FUNCTION
            // ##########################
            
            vm.saveToPrePoFinishProdCompListAcc = function () {
                niService.number_input.number = vm.number_input.number;
                //niService.number_input.webpage_name = stateService.getCurrentName();
                stateService.back();
            };
        }]
    });
})();