(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectPoFinishService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            setting: {},
            dataSource: {
                records: []
            },
            p2WSelectPoFinishList: {
                records: []
            },
            p2PohScan: {},
            loadP2WPaperLessPoGet: function (webpagename) {
                var deferred = $q.defer();

                var parmsP2WPaperLessPoGet = {
                    method: 492,
                    parameters: {
                        webpagename: webpagename
                    }
                };

                $ihttp.post(parmsP2WPaperLessPoGet).then(function (data) {
                    service.setting = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadPoFinish: function (selectType) {
                var deferred = $q.defer();

                var parms_loadPoFinish = {
                    method: 447,
                    parameters: {
                        select_type: selectType
                    }
                };

                $ihttp.post(parms_loadPoFinish).then(function (data) {
                    service.dataSource.records = [];

                    angular.forEach(data, function (item) {
                        service.dataSource.records.push({ item_id: item.item_id, item_name: item.item_name, item_name_sub1: item.item_name_sub1, item_filtervalue: item.item_filtervalue });
                    })

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2WSelectPoFinishList: function (stockplace_id, operation_id) {
                var deferred = $q.defer();

                var parmsP2WSelectPoFinishList = {
                    method: 493,
                    parameters: {
                        stockplace_id: stockplace_id,
                        operation_id: operation_id
                    }
                };

                $ihttp.post(parmsP2WSelectPoFinishList).then(function (data) {
                    angular.copy(data, service.p2WSelectPoFinishList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            searchP2PohScan: function (barcode) {
                var deferred = $q.defer();

                var parmsP2PohScan = {
                    method: 154,
                    parameters: {
                        barcode: barcode
                    }
                };

                $ihttp.post(parmsP2PohScan).then(function (data) {
                    service.p2PohScan = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            savePoFinish: function (item) {
                var deferred = $q.defer();

                var parms_savePoFinish = {
                    method: 448,
                    parameters: {
                        p2_pofinish_keyno: item.item_id || service.p2PohScan.p2_pofinish_keyno,
                        webpagename: $stateParams.webpagename
                    }
                };

                $ihttp.post(parms_savePoFinish).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
