(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('positions', {
        templateUrl: 'views/components/views/positions/positions.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'positionsService', function ($stateParams, stateService, utilityService, positionsService) {
            var vm = this;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListPositions: []
            };
            
            positionsService.getPositions({
                argtype: argType,
                argvalue: argValue
            }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

                loadPositions();
            });

            var loadPositions = function () {
                if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;

                vm.model.itemsListPositions = [];

                positionsService.listPositions({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListPositions);
                });
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'position':
                        stateService.go(state, {
                            argtype: vm.model.setting.argtype,
                            argvalue: vm.model.setting.argvalue,
                            position_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();