(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("documentsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_documents_list: {
                item_glyphicon: '',
                item_id: '',
                item_name: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 895,
                    parameters: parms || {}
                });
            },
            // BJS 20181024 - Modified to accept an object with parameters
            loadP2DocumentsList: function (argtype, argvalue, searchtext, doctype, onlyFilenames, date_fom, date_tom, dateselector_index, show_dateselector) {
                var deferred = $q.defer();

                var parameters = angular.isObject(argtype) === true ? argtype : {
                    argtype: argtype,
                    argvalue: argvalue,
                    searchtext: searchtext,
                    doctype: doctype,
                    onlyFilenames: onlyFilenames || '0',
                    date_fom: date_fom,
                    date_tom: date_tom,
                    dateselector_index: dateselector_index,
                    show_dateselector: show_dateselector
                };

                var parms = {
                    method: 309,
                    parameters: parameters
                };

                $ihttp.post(parms).then(function (data) {
                    angular.copy(data, service.p2_documents_list.records);
                    service.loaded = true;
                    deferred.resolve(data);
                }, function (error) {
                    console.log('loadP2DocumentsList - error');
                    console.dir(error);
                });

                return deferred.promise;
            },
            listDocuments: function (parms) {
                return $ihttp.post({
                    method: 309,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
