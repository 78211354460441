(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicGridService", ['$ihttp', function($ihttp) {
        var service = {
            init: function (tt_datasource_keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initdynamicgrid',
                        tt_datasource_keyno: tt_datasource_keyno
                    }
                });
            }
        }

        return service;
    }]);
})();
