(function () {
    'use strict';

    angular.module("imApp").factory("prodGrpLangsService", ['$ihttp', function ($ihttp) {
        let service = {
            listLanguages: function (parms) {
                return $ihttp.post({
                    method: 3182,
                    parameters: parms || {}
                });
            },
            saveObj: function (inputListLanguages) {
                return $ihttp.post({
                    method: 3183,
                    parameters: inputListLanguages
                });
            }
        };

        return service;
    }]);
})();
