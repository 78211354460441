(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('htmlReports', {
        templateUrl: 'views/components/views/htmlReports/htmlReports.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'htmlReportsService', 'printService', function (stateService, utilityService, htmlReportsService, printService) {

            const vm = this;

            vm.model = {
                filtervalue: '',
                htmlReports:[],
                filtervalueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.filtervalue = '' }
                ],
                updatingChanges: false
            };

            let loadReports = function () {
                vm.model.htmlReports = [];

                htmlReportsService.listReports().then(function (result) {
                    vm.model.updatingChanges = false;
                    angular.copy(result, vm.model.htmlReports);
                });
            };

            vm.goToFunc = function (state, e) {
                if (e?.ctrlKey) {
                    stateService.newTab(state, parms);
                } else {
                    stateService.go(state, parms);
                }
            };

            vm.$onInit = function () {
                loadReports();
            };

        }]
    });
})();
