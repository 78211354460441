import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridModalComponent } from '@app/core/components/grid/grid.decorators';
import { Grid } from '@app/core/components/grid/grid.model';
import { GridOptions, GridRow, LoadDataTaskRequest } from '@app/core/components/grid/grid.types';
import { GridModalData } from '@app/core/components/grid/modals/grid-modal.service';
import { SearchItem } from '@app/core/components/search/search.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';
import { debounceTime, Subject } from 'rxjs';

@GridModalComponent('gridBookingModal')
@Component({
    selector: 'tt-grid-booking',
    templateUrl: './grid-booking.component.html',
    styleUrls: ['./grid-booking.component.css'],
})
export class GridBookingComponent implements OnDestroy {
    public model: GridBookingModel = {
        ready: false,
        dataItem: null,
        voucher: null,
        loadData: null,
        get: null,
        files: [],
        rows: [],
        departments: [],
    };

    public grid?: GridOptions;

    private refreshAmountSubject = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) data: GridModalData, private dialogRef: MatDialogRef<GridBookingComponent>, private datatask: DataTaskService, private modal: ModalService) {
        this.refreshAmountSubject.pipe(debounceTime(150)).subscribe(() => this.refreshAmountAndVatAmount());

        if (data.row) {
            this.model.dataItem = data.row;

            this.model.voucher = {
                amount: data.row['amount'] ?? '0',
                amount_vat: data.row['amount_vat'] ?? '0',
                amount_locval_balance: data.row['amount_locval_balance'] ?? '0',
                businessco_no: data.row['businessco_no'] ?? '0',
                project_keyno: data.row['project_keyno'] ?? '0',
                project_name: data.row['project_name'] ?? '',
                transdate: data.row['transdate'] ?? new Date().toISOString().substring(0, 10),
                voucherdate: data.row['voucherdate'] ?? new Date().toISOString().substring(0, 10),
                department_id: data.row['department_id'] ?? '0',
                note: data.row['note'] ?? '',
            };
        }

        if (data.loadData) {
            this.model.loadData = data.loadData;

            this.grid = new Grid()
                .setLoadDataFunction({ method: 3339, parameters: null })
                .setAddRowFunction({ method: () => {}, parameters: null })
                .setRemoveRowFunction({ method: () => {}, parameters: null })
                .setSaveDataFunction({ method: () => {}, parameters: null })
                .setToolbar({ hidden: false, excelExport: false, pdfExport: false, filter: false, headers: false, layouts: false, read: false })
                .setCustomToolbarButtons([{ name: 'grid_booking_new_row', text: 'new_row', func: () => this.addRow(), icon: 'far fa-plus', cssClass: 'tt-button tt-button--success im-grid-btn-xs', translate: true }])
                .setSpecialFunc({
                    buttons: [{ name: 'grid_booking_delete_row', text: '', func: (event: any) => this.removeRow(event), icon: 'far fa-trash', btn_type: 'danger', cssClass: 'tt-button tt-button--danger im-grid-btn-xs', translate: true }],
                })
                .setKendoConfig({ filterable: false, height: null });

            if (data.rememberId) {
                (this.grid as Grid).setRememberId(data.rememberId + '.gridbooking');
            }
        }

        (async () => {
            await this.getPageData();
            // let stuff process before becoming ready.
            setTimeout(() => (this.model.ready = true));

            this.getDepartments();
        })();
    }

    public onVoucherDateChanged(event: string) {
        if (this.model.voucher) {
            this.model.voucher.voucherdate = event;
            this.model.voucher.transdate = event;
        }
    }

    public overrideDatesToRows() {
        if (!!this.model.voucher) {
            this.model.rows = this.model.rows.map((row) => ({ ...row, voucherdate: this.model.voucher!.voucherdate, transdate: this.model.voucher!.transdate }));
            this.setDataSource(this.model.rows);
        }
    }

    public onGridReady() {
        this.setDataSource(this.model.rows);
    }

    /**
     * Adds a new row to the booking grid.
     */
    private async addRow() {
        if (this.grid?.gridfunc) {
            // @ts-ignore
            const data = await this.datatask.Post(3272, { last_row: this.model.rows[this.model.rows.length - 1], dataitem: this.model.dataItem }, { returnRawResponse: true });

            this.model.rows.push({ ...data });
            this.setDataSource(this.model.rows);
        }
    }

    private removeRow(row: GridRow) {
        const deleteIndex = this.model.rows.indexOf(row);

        if (deleteIndex !== -1) {
            this.model.rows.splice(this.model.rows.indexOf(row), 1);
            this.setDataSource(this.model.rows);
        }
    }

    private setDataSource(rows: GridRow[]) {
        this.grid?.gridfunc?.setDataSource(rows);
        // set the rows list equal to the one in grid component after mapping of ids.
        setTimeout(() => {
            this.model.rows = this.grid!.gridfunc!.getAllRows();
            this.refreshAmountSubject.next();
        });
    }

    public searchProject = async () => {
        if (!!this.model.voucher?.project_name && !!this.model.voucher?.businessco_no) {
            const parms = {
                project_name: this.model.voucher.project_name,
                businessco_no: this.model.voucher.businessco_no,
            };

            return this.datatask.Post(1974, parms);
        } else {
            return [];
        }
    };

    /**
     * Selects the given item as project, clears of no item given.
     *
     * @param item the item to set as project.
     */
    public onProjectSelected(item?: SearchItem) {
        if (!!this.model.voucher) {
            this.model.voucher.project_name = item?.['item_name'] ?? '';
            this.model.voucher.project_keyno = item?.['item_id'] ?? '';
        }
    }

    public async getDepartments() {
        this.model.departments = [];

        if (!!this.model.voucher?.businessco_no) {
            this.model.departments = await this.datatask.Post(105, {
                businessco_no: this.model.voucher.businessco_no,
            });
        }
    }

    private toBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
    }

    private async getPageData() {
        if (this.model.loadData) {
            this.model.get = (await this.datatask.Post(3268, { grid_datatask_keyno: this.model.loadData.method, grid_datatask_params: this.model.loadData.parameters, dataitem: this.model.dataItem, dataitems: [this.model.dataItem] }))[0];
            if (this.model.voucher && this.model.get) {
                this.model.voucher.note = this.model.get.note;
            }
            if (this.model.get) {
                this.model.rows = JSON.parse(this.model.get.suggested_rows ?? '');
                this.refreshAmountSubject.next();
            }
        }
    }

    private async refreshAmountAndVatAmount() {
        try {
            const data = (await this.datatask.Post(3269, { rows: this.model.rows }))[0];

            if (!!this.model.voucher) {
                this.model.voucher.amount = new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.amount));
                this.model.voucher.amount_vat = new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.amount_vat));
            }
        } catch (error) {
            this.modal.openErrorDialog('' + error);
        }
    }

    public onRowChanged(event: any) {
        if (event.newValue !== event.oldValue) {
            this.gridbookingColChanged(event.colId, event.row);
        }
    }

    public async process(buttonName: string) {
        if (this.changePromise !== null && this.changePromise instanceof Promise) {
            await this.changePromise;
        }

        if (this.model.get && this.grid) {
            const filesBase64 = await Promise.all(this.model.files.map(async (file) => ({ filename: file.name, base64: await this.toBase64(file) })));
            const data = await this.datatask.Post(+this.model.get.book_datatask_keyno, { button: buttonName, voucher: this.model.voucher, rows: this.model.rows, files: filesBase64 });

            if (data[0].errorcode !== '0') {
                await this.modal.openErrorDialog(data[0].errormessage);
            } else {
                this.dialogRef.close(data[0]);
            }
        }
    }
    private changePromise: null | Promise<void> = null;

    public async gridbookingColChanged(colnameChanged: string, row: GridRow) {
        try {
            if (!this.model.ready) return;

            this.changePromise = new Promise(async (resolve) => {
                const data = (await this.datatask.Post(3270, { ...row, colname_changed: colnameChanged }))[0];
                let rowToUpdate = this.model.rows.find((r) => r._uuid === row._uuid);

                if (!!rowToUpdate) {
                    this.model.rows[this.model.rows.indexOf(rowToUpdate)] = { ...row, ...data };
                    this.grid?.gridfunc?.updateRow({ ...row, ...data });

                    this.setDataSource(this.model.rows);
                    this.refreshAmountSubject.next();
                }

                resolve();
            });
        } catch (error) {
            console.log(error);
        }
    }

    public dismiss() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.refreshAmountSubject.unsubscribe();
    }
}

/**
 * Represents the view model in the grid-booking modal.
 */
interface GridBookingModel {
    /**
     * The dataitem which triggered the opening of the modal, if any.
     */
    dataItem: GridRow | null;

    /**
     * Whether the grid-booking component is finished loading initial data.
     */
    ready: boolean;

    /**
     * The voucher to book.
     */
    voucher: GridBookingVoucher | null;

    /**
     * The load data request of the grid where the modal was opened.
     */
    loadData: LoadDataTaskRequest | null;

    /**
     * The view get of the grid booking modal.
     */
    get: GridBookingGet | null;

    /**
     * Attachments added to the voucher to book.
     */
    files: File[];

    /**
     * Rows created for the voucher.
     */
    rows: GridRow[];

    /**
     * List over departments to select from.
     */
    departments: [];
}

/**
 * Represents the voucher being created for booking.
 */
interface GridBookingVoucher {
    /**
     * The amount value of the voucher.
     */
    amount: string;

    /**
     * The vat amount value of the voucher.
     */
    amount_vat: string;

    /**
     * The balance amount in local valuta.
     */
    amount_locval_balance: string;

    /**
     * The business/company id the voucher is being created at.
     */
    businessco_no: string;

    /**
     * The project connected to the voucher.
     */
    project_keyno: string;

    /**
     * The project name connected to the voucher.
     */
    project_name: string;

    /**
     * The transdate of the voucher.
     */
    transdate: string;

    /**
     * The voucherdate of the voucher.
     */
    voucherdate: string;

    /**
     * The department id where the voucher is being registered for.
     */
    department_id: string;

    /**
     * Additional information of the voucher.
     */
    note: string;
}

interface GridBookingGet {
    /**
     * The heading to display for the grid-booking modal.
     */
    heading: string;

    // /**
    //  * Label for the value used as transdate.
    //  */
    // label_transdate: string;

    // /**
    //  * Label for the value used as voucherdate.
    //  */
    // label_voucherdate: string;

    /**
     * Label for the value used for bookkeep button.
     */
    label_bookkeep: string;

    /**
     * JSON object over list of suggested rows.
     */
    suggested_rows: string;

    /**
     * The datatasl keyno to use for book procedure.
     */
    book_datatask_keyno: string;

    note: string;

    // /**
    //  * Whether transdate should show.
    //  */
    // show_transdate: '1' | '0';

    // /**
    //  * Whether voucher date should show.
    //  */
    // show_voucherdate: '1' | '0';
}
