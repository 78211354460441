(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acCustTransesAge', {
        templateUrl: 'views/components/views/acCustTransesAge/acCustTransesAge.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'acCustTransesAgeService', 'logisticService', 'rememberService', function (stateService, utilityService, acCustTransesAgeService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            var variableNames = {
                businessco_no: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_accusttransesage_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            acCustTransesAgeService.getAcCustTransesAge().then(function (result) {
                angular.copy(result[0], vm.model.setting);

			    vm.grid.dataTask.loadData = {
                    method: 2797,
				    parameters: vm.model.setting
			    };
		        
		        vm.model.gridReady = true;
            });

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListCompanies);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                            variableNames[key] = 'w_accountingprocessing.businessco_no';
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            //vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
            //    vm.model.setting.date_fom = startDate;
            //    vm.model.setting.date_tom = endDate;
            //    vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

            //    //if (vm.model.setting.date_fom) rememberFunc('date_fom');
            //    //if (vm.model.setting.date_tom) rememberFunc('date_tom');
            //    //if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

            //    if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
            //        vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
            //        vm.grid.gridfunc.rebind();
            //    }
            //};

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'date_tom':
                        if (utilityService.validateWatchValue(value, vm.model.setting.date_tom) !== true) return;

                        vm.model.setting.date_tom = value;

                        if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;

                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS
        }]
    });
})();
