(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentShipment', {
        templateUrl: 'views/components/views/consignmentShipment/consignmentShipment.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'consignmentShipmentService', function($stateParams, stateService, modalService, consignmentShipmentService) {
            var vm = this;

            vm.model = {
                consignmentShipmentKeyNo: $stateParams.consignmentshipment_keyno,
                stockPlaceId: $stateParams.stockplace_id,
                getConsignmentShipment: {},
                locked: false,
                lockedDelete: false
            };
            
            consignmentShipmentService.loadConsignmentShipmentGet(vm.model.consignmentShipmentKeyNo, vm.model.stockPlaceId).then(function (data) {
                vm.model.getConsignmentShipment = angular.copy(data[0]);
            });
            
            vm.saveConsignmentShipment = function () {
                vm.model.locked = true;
                
                consignmentShipmentService.save(vm.model.getConsignmentShipment).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function(dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.locked = false;
                    }
                });
            };
            
            vm.deleteConsignmentShipment = function () {
                vm.model.lockedDelete = true;
                
                consignmentShipmentService.delete(vm.model.getConsignmentShipment).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();