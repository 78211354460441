(function () {
    'use strict';

    angular.module("imApp").factory("orderHeadStateService", ['stateService', 'utilityService', function (stateService, us) {
        var service = {
            goTo: function (state, orderhead, args) {
                switch (state) {
                    case 'positions':
                    case 'checklistdatas':
                    case 'deviations':
                    case 'pictures':
                    case 'timesheetdiary':
                    case 'checkpointheads':
                    case 'ohdispatches':
                    case 'relatedpurchorders':
                    case 'invoiceheads':
                    case 'consignments':
                    case 'documents':
                    case 'prepayments':
                    case 'stocktransallocations':
                    case 'createcase':
                    case 'paymentplans':
                    case 'orderlinecredits':
                    case 'postcalc':
                        stateService.go(state, {
                            argtype: 'order_internal_no',
                            argvalue: orderhead.order_internal_no
                        });
                        break;
                    case 'prepayment':
                        stateService.go(state, {
                            argtype: 'order_internal_no',
                            argvalue: orderhead.order_internal_no,
                            prepayment_keyno: '0'
                        });
                        break;
                    case 'orderdialog':
                    case 'orderheadsetdisc':
                    case 'orderheaddeleteol':
                    case 'ohdirectdelivery':
                    case 'orderheadcopy':
                    case 'ordercustomerservice':
                    case 'orderpurch':
                    case 'orderheadcalcs':
                    case 'orderheadcreateibpurch':
                    case 'orderheadmi':
                    case 'orderlinestatus':
                    case 'orderheadcapjob':
                    case 'ordershipmentstatus':
                        stateService.go(state, {
                            order_internal_no: orderhead.order_internal_no
                        });
                        break;
                    case 'textileoladd':
                        stateService.go(state, {
                            order_internal_no: orderhead.order_internal_no,
                            orderline_keyno: '0',
                            textilemodel_id: '-'
                        });
                        break;
                    case 'emailcreate':
                        stateService.go(state, {
                            webpage: 'orderhead',
                            argtype: 'order_internal_no',
                            argvalue: orderhead.order_internal_no
                        });
                        break;
                    case 'selectreport':
                        stateService.go(state, {
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'order_internal_no',
                            argvalue: orderhead.order_internal_no
                        });
                        break;
                    case 'consignmentassembly':
                        stateService.go(orderhead.consignmentassembly_webpage_name, orderhead.consignmentassembly_parms)
                        break;
                    case 'purchhead':
                        stateService.go(state, {
                            purch_internal_no: orderhead.purch_internal_no_from
                        });
                        break;
                    case 'customeredit1':
                        stateService.go('customeredit', {
                            cust_no: '0'
                        });
                        break;
                    case 'customeredit2':
                        stateService.go('customeredit', {
                            cust_no: orderhead.stat_cust_no
                        });
                        break;
                    case 'customersmenu1':
                        stateService.go('customersmenu', {
                            cust_no: orderhead.stat_cust_no
                        });
                        break;
                    case 'customersmenu2':
                        stateService.go('customersmenu', {
                            cust_no: orderhead.invoice_cust_no
                        });
                        break;
                    case 'postplace':
                        stateService.go('postplace', {
                            postplace_id: '0'
                        });
                        break;
                    case 'serviceobjects':
                        stateService.go('serviceobjects', {
                            argtype: '-',
                            argvalue: '-'
                        });
                        break;
                    case 'customerpersons':
                        stateService.go(state, {
                            cust_no: orderhead.stat_cust_no
                        });
                        break;
                    case '':
                        break;
                    case 'selectproject':
                        stateService.go(state, {
                            argtype: 'cust_no',
                            argvalue: orderhead.stat_cust_no
                        });
                        break;
                    case 'projectmenu':
                        stateService.go(state, {
                            project_keyno: orderhead.project_keyno
                        });
                        break;
                    case 'businesss':
                        stateService.go('businesss', {});
                        break;
                    case 'orderhead':
                        if (us.isStringValue(orderhead.consernorder_internal_no, true) !== true)
                            return;

                        stateService.go(state, {
                            order_internal_no: orderhead.consernorder_internal_no
                        });
                        break;
                    case 'valuta':
                        if (angular.isUndefined(args) || angular.isObject(args) !== true || us.isStringValue(args.type) !== true)
                            return;

                        var id = args.type + '_valuta_id'

                        if (us.isStringValue(orderhead[id], true) !== true)
                            return;

                        stateService.go(state, {
                            valuta_id: orderhead[id]
                        });
                        break;
                }
            }
        };

        return service;
    }]);
})();
