(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockPlaceDelTimes', {
        templateUrl: 'views/components/views/stockPlaceDelTimes/stockPlaceDelTimes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockPlaceDelTimesService', function ($stateParams, stockPlaceDelTimesService) {
            const vm = this;
            let stockPlaceIdTo = $stateParams.stockplace_id_to;
            
            vm.model = {
                setting: {},
                itemsListStockPlaceDelTimes: []
            };

            let loadSetting = function () {
                stockPlaceDelTimesService.getStockPlaceDelTimes({ stockplace_id_to: stockPlaceIdTo }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadStockPlaceDelTimes = function () {
                stockPlaceDelTimesService.listStockPlaceDelTimes({ stockplace_id_to: stockPlaceIdTo }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockPlaceDelTimes);
                });
            };

            vm.$onInit = function () {
                loadSetting();
                loadStockPlaceDelTimes();
            };
        }]
    });
})();
