(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderLineCancelCause', {
        templateUrl: 'views/components/views/orderLineCancelCause/orderLineCancelCause.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'orderLineCancelCauseService', 'translateService', function ($stateParams, stateService, modalService, orderLineCancelCauseService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var orderLineCauseKeyno = $stateParams.orderlinecancelcause_keyno;

            var translations = {
                orderlinecancelcause_save_show_title: 'Varsel',
                orderlinecancelcause_save_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'orderlinecancelcauselang':
                        stateService.go(state, {
                            orderlinecancelcause_keyno: vm.model.edit.orderlinecancelcause_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                orderLineCancelCauseLangNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderlinecancelcauselang' } }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            orderLineCancelCauseService.getOrderLineCancelCause({ orderlinecancelcause_keyno: orderLineCauseKeyno }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                orderLineCancelCauseService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.orderlinecancelcause_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.orderlinecancelcause_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
