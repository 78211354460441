(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('docArticleTopUserGroups', {
        templateUrl: 'views/components/views/docArticleTopUserGroups/docArticleTopUserGroups.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'docArticleTopUserGroupsService', function($stateParams, docArticleTopUserGroupsService) {
            var vm = this;
            
    		vm.model = {
    			docArticleKeyNoTop: $stateParams.docarticle_keyno_top,
    			itemsListUserGroups: []
    		};
            
    		docArticleTopUserGroupsService.loadDocArticleTopUserGroupsList(vm.model.docArticleKeyNoTop).then(function (data) {
    			angular.copy(data, vm.model.itemsListUserGroups);
    		});
    	}]
    });
})();