(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productEan', {
        templateUrl: 'views/components/views/productEan/productEan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$element', 'utilityService', 'stateService', 'modalService', 'translateService', 'productEanService', function ($stateParams, $timeout, $element, utilityService, stateService, modalService, translateService, productEanService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let prodId = $stateParams.prod_id;
            let productEanKeyno = $stateParams.productean_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                mId: 'productEanCtrl',
                productEanKeyno: productEanKeyno,
                eanButtons: [
                    { id: 'scan', icon: 'fa fa-solid fa-barcode', color: 'primary', type: 'primary', onClick: () => goBarcode() } // onClick: () => { return; }
                ],
                eanButtons2: [
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => loadNextEan() },
                    { id: 'scan', icon: 'fa fa-solid fa-barcode', color: 'primary', type: 'primary', onClick: () => goBarcode() } // onClick: () => { return; }
                ],
                edit: {},
                lockedPrint: false,
                lockedSave: false
            };

		    // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                productEanService.getProductEan({
                    prod_id: prodId,
                    productean_keyno: productEanKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadNextEan = function () {
                if (utilityService.validateParmsValue(vm.model.edit.ean, true) !== true) return;

                if (vm.model.edit.ean === '') {
                    productEanService.getNextEan({ prod_keyno: prodId }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            vm.model.edit.ean = response[0].ean;
                        }
                    });
                }
            };

            // ## SCAN BUTTON FUNCTION ##

            let goBarcode = function () {
                var wait = function () {
                    var val = $element.find('#' + vm.model.mId)[0].value;

                    if (angular.isString(val) && val !== null && val.length > 0) {
                        vm.model.edit.ean = val;
                        $element.find('#' + vm.model.mId)[0].value = '';
                        return;
                    }

                    $timeout(wait, 250);
                };

                wait();

                window.location = "readbarcode://" + vm.model.mId;
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectreport':
                        vm.model.lockedPrint = true;

                        productEanService.saveObj(vm.model.edit).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedPrint = false;
                                        }
                                    }]
                                });
                            } else {
                                vm.model.lockedPrint = false;

                                go({
                                    webpage_name: stateService.getCurrentName(),
                                    argtype: 'productean_keyno',
                                    argvalue: vm.model.edit.productean_keyno
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productEanService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            vm.deleteValues = function () {
                productEanService.deleteItem(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'ean':
                        if (utilityService.validateWatchValue(value, vm.model.edit.ean) !== true) return;

                        vm.model.edit.ean = value;

                        if (utilityService.validateParmsValue(vm.model.edit.ean, true) !== true) return;

                        if (vm.model.edit.ean === '') {
                            vm.model.edit.ean = '';
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
