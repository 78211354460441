(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('settings', {
        templateUrl: 'views/components/views/settings/settings.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'settingsService', 'rememberService', function (stateService, utilityService, settingsService, rememberService) {
            let vm = this;
            
            let variableNames = {
                filterval: ''
            };

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filterval') }
                ],
                setting: {},
                itemsListSettings: []
            };

            let loadSetting = function () {
                settingsService.getSettings().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };

            let loadSettings = function () {
                settingsService.listSettings({ webpage_name: stateService.getCurrentName() }).then(function (result) {
                    angular.copy(result, vm.model.itemsListSettings);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filterval':
                        vm.model.setting.filterval = '';

                        if (vm.model.setting.filterval === '') rememberFunc('filterval');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filterval) !== true) return;

                        vm.model.setting.filterval = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        if (vm.model.setting.filterval === '') rememberFunc('filterval');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        if (vm.model.setting.filterval > '') rememberFunc('filterval');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadSettings();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
