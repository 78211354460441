(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('currencyExRate', {
        templateUrl: 'views/components/views/currencyExRate/currencyExRate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'currencyExRateService', function ($stateParams, stateService, utilityService, currencyExRateService) {
            const vm = this;
            let currencyExRateKeyno = $stateParams.currencyexrate_keyno;
            
            vm.model = {
                edit: {},
                lockedSave: false
            };

            let loadEdit = function () {
                currencyExRateService.getEdit({ currencyexrate_keyno: currencyExRateKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let recalcExchangeRateFactor = function () {
                vm.model.edit.exchangerate_factor = utilityService.parseNumber(vm.model.edit.exchangerate / vm.model.edit.valuta_pr);
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                currencyExRateService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.onExchangerateChanged = function (value) {
                vm.model.edit.exchangerate = value;

                if (value) {
                    recalcExchangeRateFactor();
                }
            }

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
