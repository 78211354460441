(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('cargonizerPrinterEdit', {
        templateUrl: 'views/components/views/cargonizerPrinterEdit/cargonizerPrinterEdit.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'cargonizerPrinterEditService', function ($stateParams, stateService, cargonizerPrinterEditService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let cargonizerPrinterKeyno = $stateParams.cargonizer_printer_keyno;

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION

            let loadEdit = function () {
                cargonizerPrinterEditService.getEdit({ cargonizer_printer_keyno: cargonizerPrinterKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                cargonizerPrinterEditService.saveObj({
                    cargonizer_printer_keyno: vm.model.edit.cargonizer_printer_keyno,
                    name: vm.model.edit.name
                }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
