(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('riskElements', {
        templateUrl: 'views/components/views/riskElements/riskElements.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'riskElementsService', 'userService', function ($stateParams, stateService, riskElementsService, userService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let sizeFunc = userService.fontSize;

            vm.model = {
                getFont: {
                    textSize: sizeFunc,
                    textSizeSM: (parseInt(sizeFunc.substr(0, sizeFunc.length - 2)) - 3).toString() + "px",
                    textSizeXS: (parseInt(sizeFunc.substr(0, sizeFunc.length - 2)) - 5).toString() + "px"
                },
                descriptiveCellsConsequenceLabels: {
                    records: [],
                    indexed: []
                },
                descriptiveCellsProbabilityLabels: [],
                tableBadges: [],
                tableListRiskDescriptions: [],
                itemsListRiskElements: [],
                cellClicked: 0
            };

            let loadProbabilityLabels = function () {
                riskElementsService.listProbabilityLabels().then(function (result) {
                    angular.copy(result, vm.model.descriptiveCellsProbabilityLabels);
                });
            };

            let loadConsequenceLabels = function () {
                riskElementsService.listConsequenceLabels().then(function (result) {
                    angular.copy(result, vm.model.descriptiveCellsConsequenceLabels.records);
                    var counter = 1;

                    angular.forEach(vm.model.descriptiveCellsConsequenceLabels.records, function (item) {
                        vm.model.descriptiveCellsConsequenceLabels.indexed[counter] = item;
                        counter += 1;
                    });
                });
            };

            let loadRiskLevels = function () {
                riskElementsService.listRiskLevels().then(function (result) {
                    angular.copy(result, vm.model.tableListRiskDescriptions);

                    angular.forEach(vm.model.tableListRiskDescriptions, function (item) {
                        var ic = getListItemColour('', '', item.risklevel_no);
                        item.background = ic.background;
                        item.color = ic.color;
                    });
                });
            };

            let loadRiskElementCounts = function () {
                riskElementsService.listRiskElementCounts({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (counts) {
                    prepareRiskCountMatrix(counts);
                });
            };

            let loadRiskElements = function () {
                vm.model.itemsListRiskElements = [];

                riskElementsService.listRiskElements({
                    argtype: argType,
                    argvalue: argValue,
                    probability_no: '0',
                    consequence_no: '0',
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListRiskElements);
                });
            };

            let getListItemCount = function (list, probability, consequence) {
                var out = 0;
                
                angular.forEach(list, function (item) {
                    if (item.probability_no == probability && item.consequence_no == consequence) {
                        out = item.nbr;
                    }
                });
                
                return out;
            };
            
            let getListItemColour = function (probability, consequence, risklevel_no) {
                var out = {
                    background: '#B91114',
                    color: '#ffffff'
                };

                var num = probability + '_' + consequence + risklevel_no;
                
                switch (num) {
                    /*GREEN*/
                    case '1_1':
                    case '1_2':
                    case '1_3':
                    case '2_1':
                    case '2_2':
                    case '3_1':
                    case '_4':
                        out.background = '#0F9F0F';
                        out.color = '#FFFFFF';
                        break;
                    /*YELLOW*/
                    case '1_4':
                    case '1_5':
                    case '2_2':
                    case '2_3':
                    case '2_4':
                    case '3_2':
                    case '3_3':
                    case '4_1':
                    case '4_2':
                    case '5_1':
                    case '_3':
                        out.background = '#F2E228';
                        out.color = '#000000';
                        break;
                    /*ORANGE*/
                    case '_2':
                    case '2_5':
                    case '3_4':
                    case '4_3':
                    case '5_2':
                        out.background = '#FFAC22';
                        out.color = '#000000';
                        break;
                    /*RED*/
                    default:
                        out.background = '#B91114';
                        out.color = '#ffffff';
                        break;
                }
                
                return out;
            };
            
            let prepareRiskCountMatrix = function (list) {
                vm.model.tableBadges = [];
                var probability = 5;
                var consequence = 1;

                for (probability = 5; probability > 0; probability--) {
                    consequence = 1;

                    for (consequence = 1; consequence < 6; consequence++) {
                        var nr = {};
                        nr.probability_no = probability;
                        nr.consequence_no = consequence;
                        nr.count = getListItemCount(list, probability, consequence);
                        var nrc = getListItemColour(probability, consequence,'');
                        nr.background = nrc.background;
                        nr.color = nrc.color;
                        vm.model.tableBadges[consequence + '_' + probability] = nr;
                    }
                }
            };

            vm.riskCountMatrixClicked = function (probabilityNo, consequenceNo) {
                vm.model.cellClicked += 1;
                vm.model.itemsListRiskElements = [];

                riskElementsService.listRiskElements({
                    argtype: argType,
                    argvalue: argValue,
                    probability_no: probabilityNo,
                    consequence_no: consequenceNo,
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListRiskElements);
                });
            };

            vm.showAllRiskElements = function () {
                vm.model.cellClicked = 0;

                loadRiskElements();
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'riskelement':
                        go({
                            riskelement_keyno: '0',
                            argtype: 'custact_no',
                            argvalue: argValue
                        });
                        break;
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'custact_no',
                            argvalue: argValue
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadProbabilityLabels();
                loadConsequenceLabels();
                loadRiskLevels();
                loadRiskElements();
                loadRiskElementCounts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
