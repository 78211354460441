(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('diary', {
        templateUrl: 'views/components/views/diary/diary.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', '$timeout', 'utilityService', 'diaryService', 'responsive', 'watcherFactory', 'graphAuthService', 'graphCalendarService', 'graphUserService', function ($q, $timeout, utilityService, diaryService, responsive, watcherFactory, graphAuthService, graphCalendarService, graphUserService) {
            var vm = this;
            var watcher = watcherFactory.$create(vm);
            var curr = new Date(), y = curr.getFullYear(), m = curr.getMonth(), f = curr.getDate();
            var timer = null;
            var timerOwnerList = null;

            vm.model = {
                setting: {
                    //date_fom: (new Date(y, m, f, 2)).toISOString().substring(0, 10),
                    //selectedview: 'week',
                    //portal_user_keyno: '1',
                },
                selectListPortalUsers: [],
                p2WDiaryTaskList: {
                    records: []
                },
                p2WDiaryOwnerList: {
                    records: []
                },
                //setup: {
                //    dt_start: '2018/08/07',
                //    dt_end: '2018/08/07',
                //    view: 'week',
                //    user: '1'
                //}
            };

            vm.schedulerCallback = {
                onRemove: function (item) {
                    // BJS 20230905
                    diaryService.deleteCustact(item.id);

                    if (item.microsoft_graph_event_id.length > 0) {
                        graphCalendarService.deleteEvent(item.microsoft_graph_event_id);
                    }
                }
            };

            let loadPortalUsers = function () {
                diaryService.listPortalUsers().then(function (result) {
                    angular.copy(result, vm.model.selectListPortalUsers);
                });
            };

            var loadP2WDiaryTaskList = function () {
                if (angular.isDefined(timer)) {
                    $timeout.cancel(timer);
                }

                timer = $timeout(function () {
                    if (angular.isDefined(vm.model.setting.date_fom) && vm.model.setting.date_fom !== '' && angular.isDefined(vm.model.setting.portal_user_keyno) && vm.model.setting.portal_user_keyno !== '' && angular.isDefined(vm.model.setting.selectedview)) {
                        vm.model.p2WDiaryTaskList.records = [];

                        diaryService.genericFunction(843, vm.model.setting).then(function (data) {
                            angular.copy(data, vm.model.p2WDiaryTaskList.records);

                            //angular.copy([{
                            //        "item_id": "622210",
                            //        "item_name": "Utbedre grøft ved Jan-Gunnars hus",
                            //        "item_state": "workdetails",
                            //        "item_parms": "622210",
                            //        "item_path": "workdetails/622210",
                            //        "item_dim": "test",
                            //        "orderby": "1",
                            //        "start_dt": "2020-02-12 07:00:00.000",
                            //        "end_dt": "2020-02-12 13:00:00.000",
                            //        "portal_user_keyno": "85",
                            //        "description": "Jan-Gunnar Hole"
                            //    }, {
                            //        "item_id": "622211",
                            //        "item_name": "Utbedre grøft ved Jan-Gunnars hus 2",
                            //        "item_state": "workdetails",
                            //        "item_parms": "622210",
                            //        "item_path": "workdetails/622210",
                            //        "item_dim": "test",
                            //        "orderby": "1",
                            //        "start_dt": "2020-02-12 08:00:00.000",
                            //        "end_dt": "2020-02-12 14:00:00.000",
                            //        "portal_user_keyno": "85",
                            //        "description": "Jan-Gunnar Hole"
                            //    }, {
                            //        "item_id": "622212",
                            //        "item_name": "Utbedre grøft ved Jan-Gunnars hus 3",
                            //        "item_state": "workdetails",
                            //        "item_parms": "622210",
                            //        "item_path": "workdetails/622210",
                            //        "item_dim": "test",
                            //        "orderby": "1",
                            //        "start_dt": "2020-02-12 09:00:00.000",
                            //        "end_dt": "2020-02-12 16:00:00.000",
                            //        "portal_user_keyno": "85",
                            //        "description": "Jan-Gunnar Hole"
                            //    }, {
                            //        "item_id": "622213",
                            //        "item_name": "Utbedre grøft ved Jan-Gunnars hus 4",
                            //        "item_state": "workdetails",
                            //        "item_parms": "622210",
                            //        "item_path": "workdetails/622210",
                            //        "item_dim": "test",
                            //        "orderby": "1",
                            //        "start_dt": "2020-02-12 10:00:00.000",
                            //        "end_dt": "2020-02-13 01:00:00.000",
                            //        "portal_user_keyno": "85",
                            //        "description": "Jan-Gunnar Hole"
                            //    }, {
                            //        "item_id": "622214",
                            //        "item_name": "Utbedre grøft ved Jan-Gunnars hus 5",
                            //        "item_state": "workdetails",
                            //        "item_parms": "622210",
                            //        "item_path": "workdetails/622210",
                            //        "item_dim": "test",
                            //        "orderby": "1",
                            //        "start_dt": "2020-02-12 11:00:00.000",
                            //        "end_dt": "2020-02-12 21:00:00.000",
                            //        "portal_user_keyno": "85",
                            //        "description": "Jan-Gunnar Hole"
                            //    }, {
                            //        "item_id": "622215",
                            //        "item_name": "Utbedre grøft ved Jan-Gunnars hus 6",
                            //        "item_state": "workdetails",
                            //        "item_parms": "622210",
                            //        "item_path": "workdetails/622210",
                            //        "item_dim": "test",
                            //        "orderby": "1",
                            //        "start_dt": "2020-02-12 13:00:00.000",
                            //        "end_dt": "2020-02-13 10:00:00.000",
                            //        "portal_user_keyno": "85",
                            //        "description": "Jan-Gunnar Hole"
                            //    }
                            //], vm.model.p2WDiaryDataList.records);

                            //angular.copy([{
                            //        item_id: "125",
                            //        item_name: "Test",
                            //        item_state: "workdetails",
                            //        item_parms: "125",
                            //        item_path: "workdetails/125",
                            //        item_dim: "custact_keyno",
                            //        isgroup: "false",
                            //        dims: [{
                            //            custact_keyno: ""
                            //            }, {
                            //                equipment_id: "G01"
                            //            }, {
                            //            cust_no: "10000"
                            //        }],
                            //        color: "blue",
                            //        orderby: "1",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Test"
                            //    }, {
                            //        item_id: "126",
                            //        item_name: "Test 2",
                            //        item_state: "workdetails",
                            //        item_parms: "126",
                            //        item_path: "workdetails/126",
                            //        item_dim: "custact_keyno",
                            //        isgroup: "false",
                            //        dims: [{
                            //            custact_keyno: ""
                            //        }, {
                            //                equipment_id: "G02"
                            //            }, {
                            //            cust_no: "10100"
                            //        }],
                            //        color: "blue",
                            //        orderby: "2",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Test 2"
                            //    }, {
                            //        item_id: "127",
                            //        item_name: "Test 3",
                            //        item_state: "workdetails",
                            //        item_parms: "127",
                            //        item_path: "workdetails/127",
                            //        item_dim: "custact_keyno",
                            //        isgroup: "false",
                            //        dims: [{
                            //            custact_keyno: ""
                            //        }, {
                            //                equipment_id: "G01"
                            //            }, {
                            //            cust_no: "10000"
                            //        }],
                            //        color: "blue",
                            //        orderby: "3",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Test 3"
                            //    }, {
                            //        item_id: "128",
                            //        item_name: "Test 4",
                            //        item_state: "workdetails",
                            //        item_parms: "128",
                            //        item_path: "workdetails/128",
                            //        item_dim: "custact_keyno",
                            //        isgroup: "false",
                            //        dims: [{
                            //            custact_keyno: ""
                            //        }, {
                            //                equipment_id: "L01"
                            //            }, {
                            //            cust_no: "10100"
                            //        }],
                            //        color: "blue",
                            //        orderby: "4",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Test 4"
                            //    }, {
                            //        item_id: "G01",
                            //        item_name: "Gravemaskin 1",
                            //        item_state: "equipmentdetails",
                            //        item_parms: "G01",
                            //        item_path: "equipmentdetails/G01",
                            //        item_dim: "equipment_id",
                            //        isgroup: "true",
                            //        dims: [{
                            //            custact_keyno: ["125", "127"]
                            //        }, {
                            //                equipment_id: ""
                            //            }, {
                            //            cust_no: "10000"
                            //        }],
                            //        color: "red",
                            //        orderby: "5",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Gravemaskin 1"
                            //    }, {
                            //        item_id: "G02",
                            //        item_name: "Gravemaskin 2",
                            //        item_state: "equipmentdetails",
                            //        item_parms: "G02",
                            //        item_path: "equipmentdetails/G02",
                            //        item_dim: "equipment_id",
                            //        isgroup: "true",
                            //        dims: [{ custact_keyno: "126" },
                            //            { equipment_id: "" },
                            //            { cust_no: "10100"}],
                            //        color: "red",
                            //        orderby: "6",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Gravemaskin 2"
                            //    }, {
                            //        item_id: "L01",
                            //        item_name: "Lastebil 1",
                            //        item_state: "equipmentdetails",
                            //        item_parms: "L01",
                            //        item_path: "equipmentdetails/L01",
                            //        item_dim: "equipment_id",
                            //        isgroup: "true",
                            //        dims: [{
                            //            custact_keyno: "128"
                            //        }, {
                            //            equipment_id: ""
                            //        }, {
                            //            cust_no: "10100"
                            //        }],
                            //        color: "blue",
                            //        orderby: "7",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Lastebil 1"
                            //    }, {
                            //        item_id: "10000",
                            //        item_name: "Ekornes",
                            //        item_state: "custdetails",
                            //        item_parms: "10000",
                            //        item_path: "custdetails/10000",
                            //        item_dim: "cust_no",
                            //        isgroup: "true",
                            //        dims: [{
                            //            custact_keyno: ["125", "127"]
                            //        }, {
                            //            equipment_id: "G01"
                            //        }, {
                            //            cust_no: ""
                            //        }],
                            //        color: "green",
                            //        orderby: "8",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Ekornes"
                            //    }, {
                            //        item_id: "10100",
                            //        item_name: "Sykkylven Kommune",
                            //        item_state: "custdetails",
                            //        item_parms: "10100",
                            //        item_path: "custdetails/10100",
                            //        item_dim: "cust_no",
                            //        isgroup: "true",
                            //        dims: [{
                            //            custact_keyno: ["126", "128"]
                            //        }, {
                            //                equipment_id: ["G02", "L01"]
                            //            }, {
                            //            cust_no: ""
                            //        }],
                            //        color: "green",
                            //        orderby: "9",
                            //        start_dt: "2020-06-17 09:00:00.000",
                            //        end_dt: "2020-06-17 15:00:00.000",
                            //        description: "Sykkylven Kommune"
                            //    }
                            //], vm.model.p2WDiaryDataList.records);
                        });
                    }
                }, 50);
            };

            var loadP2WDiaryOwnerList = function () {
                if (angular.isDefined(timerOwnerList)) {
                    $timeout.cancel(timerOwnerList);
                }

                timerOwnerList = $timeout(function () {
                    if (angular.isDefined(vm.model.setting.date_fom) && vm.model.setting.date_fom !== '' && angular.isDefined(vm.model.setting.portal_user_keyno) && vm.model.setting.portal_user_keyno !== '' && angular.isDefined(vm.model.setting.selectedview)) {
                        vm.model.p2WDiaryOwnerList.records = [];

                        diaryService.genericFunction(844, vm.model.setting).then(function (data) {
                            angular.copy(data, vm.model.p2WDiaryOwnerList.records);
                        });
                    }
                }, 50);
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var canRun616 = function (variableName, parm) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
                if (vm.model.setting[parm] === null) return false;

                return true;
            };

            var rememberPortalUserKeyNo = function () {
                var variableNamePortalUserKeyNo = 'w_diary.portal_user_keyno';

                if (canRun616(variableNamePortalUserKeyNo, 'portal_user_keyno') !== true) return;

                diaryService.remember(variableNamePortalUserKeyNo, vm.model.setting.portal_user_keyno);
            };

            vm.$onInit = function () {
                diaryService.genericFunction(841, {}).then(function (data) {
                    vm.model.setting = angular.copy(data[0]);
                    vm.model.setting.breakpoint = responsive.current;
                    vm.model.setting.functions = setupSchedulerFunctions();

                    loadPortalUsers();
                });
            };

            let setupSchedulerFunctions = function () {
                return [
                    { name: 'add', func: function (data) { schedulerChange(this.name, data); } },
                    { name: 'update', func: function (data) { schedulerChange(this.name, data); } },
                    { name: 'delete', func: function (data) { schedulerChange(this.name, data); } },
                    { name: 'newDate', func: function (data) { schedulerChange(this.name, data); } }
                ];
            };

            let schedulerChange = function (state, data) {
                switch (state) {
                    case 'add':
                        console.log('Item added to diary...');
                        break;
                    case 'update':
                        diaryService.updateItem(data).then(function (_response) {
                            addToMicrosoft365(data, true).then(function () {
                                console.log('Micro365 Updated')
                            });
                        });
                        break;
                    case 'delete':
                        console.log('Item not added...? Deleted...?');
                        break;
                    case 'newDate':
                        console.log('Item new date...');
                        break;
                    default:
                        console.log('Item default action...');
                        break;
                }
            };

            function addToMicrosoft365(data, update) {
                if (!data?.id || data.id === 0) return $q.resolve();
                if (data.sync_with_microsoft_365 !== '1') return $q.resolve();

                const deferred = $q.defer();

                graphAuthService.getAccount().then(function (account) {
                    graphAuthService.getAccessToken(account).then(function (token) {
                        graphUserService.getUser(account.localAccountId, token).then(function (user) {
                            let dtStart = moment(data.start).format('YYYY-MM-DDTHH:mm:ss');
                            let dtEnd = moment(data.end).format('YYYY-MM-DDTHH:mm:ss');

                            if (update === true) {
                                const event = {
                                    start: {
                                        dateTime: dtStart, //'2023-08-24T15:15:00',
                                        timeZone: 'Europe/Berlin'
                                    },
                                    end: {
                                        dateTime: dtEnd, //'2023-08-24T15:20:00',
                                        timeZone: 'Europe/Berlin'
                                    }
                                };

                                graphCalendarService.updateEvent(event, { eventId: data.microsoft_graph_event_id }, token).then(function () {
                                    deferred.resolve();
                                });
                            }
                        });
                    });
                }).catch((reason) => {
                    console.log('ERROR');
                    console.dir(reason);
                    deferred.resolve();
                });

                return deferred.promise;
            }

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'portal_user_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.portal_user_keyno) !== true) return;

                        vm.model.setting.portal_user_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno) !== true) return;

                        rememberPortalUserKeyNo();
                        loadP2WDiaryTaskList();
                        loadP2WDiaryOwnerList();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // ## WATCH FUNCTIONS ##

            watcher.$watch(function () {
                return vm.model.setting;
            }, function (newVal, oldVal) {
                if (!angular.equals(newVal, oldVal) && angular.isDefined(vm.model.setting)) {
                    loadP2WDiaryTaskList();
                    loadP2WDiaryOwnerList();
                }
            }, true);

        }]
    });
})();
