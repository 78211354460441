(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectNewDoctypeService", ['$ihttp', function ($ihttp) {
        var service = {
            loadP2DocarcCreateSelectTypeList: function (argtype, argvalue) {
                return $ihttp.post({
                    method: 317,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                });
            }
        };

        return service;
    }]);
})();