(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("orderHeadCreateService", ['$ihttp', function ($ihttp) {
        let service = {
            loaded: false,
            getOrderHeadCreate: function (parms) {
                return $ihttp.post({
                    method: 611,
                    parameters: parms || {}
                });
            },
            listDeliveryCustomers: function (parms) {
                return $ihttp.post({
                    method: 1076,
                    parameters: parms || {}
                });
            },
            listOrderTypes: function (parms) {
                return $ihttp.post({
                    method: 612,
                    parameters: parms || {}
                });
            },
            getStockPlaceId: function (parms) {
                return $ihttp.post({
                    method: 1525,
                    parameters: parms || {}
                });
            },
            createObj: function (edit) {
                return $ihttp.post({
                    method: 613,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
