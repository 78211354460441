(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderLineCreditCauseLang', {
        templateUrl: 'views/components/views/orderLineCreditCauseLang/orderLineCreditCauseLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'orderLineCreditCauseLangService', function ($stateParams, stateService, orderLineCreditCauseLangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var orderLineCreditCauseKeyno = $stateParams.orderlinecreditcause_keyno;
            
            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            orderLineCreditCauseLangService.listOrderLineCreditCauseLanguages({ orderlinecreditcause_keyno: orderLineCreditCauseKeyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                orderLineCreditCauseLangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();