(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("productLinksChangeService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadProductLinksChangeGet: function (argtype, argvalue, productlinktype_keyno) {
                return p2DataTaskService.call(1828, {
                    argtype: argtype,
                    argvalue: argvalue,
                    productlinktype_keyno: productlinktype_keyno
                });
            },
            loadProductLinksChangeList: function (setting) {
                return p2DataTaskService.call(1829, setting);
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 1830,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
