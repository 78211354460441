(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('labourSalaryTypes', {
        templateUrl: 'views/components/views/labourSalaryTypes/labourSalaryTypes.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'labourSalaryTypesService', function ($stateParams, labourSalaryTypesService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListSalaryTypes: []
            };
            
            labourSalaryTypesService.loadLabourSalaryTypeGet(vm.model.argType, vm.model.argValue).then(function (data) {
                vm.model.setting = angular.copy(data[0]);
                
                loadLabourSalaryTypes();
            });
            
            var loadLabourSalaryTypes = function () {
                if (angular.isUndefined(vm.model.setting.argtype) === true) return;
                if (vm.model.setting.argtype === null) return;
                if (vm.model.setting.argtype.trim().length < 1) return;
                
                if (angular.isUndefined(vm.model.setting.argvalue) === true) return;
                if (vm.model.setting.argvalue === null) return;
                if (vm.model.setting.argvalue.trim().length < 1) return;
                
                if (angular.isUndefined(vm.model.setting.searchtext) === true) return;
                if (vm.model.setting.searchtext === null) return;
                
                vm.model.itemsListSalaryTypes = [];
                
                labourSalaryTypesService.loadLabourSalaryTypeList(vm.model.setting).then(function (data) {
                    angular.copy(data, vm.model.itemsListSalaryTypes);
                });
            };
        }]
    });
})();
