(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListExpenseService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListExpenseGet: function (checklistdata_keyno, srrefundinput_keyno) {
                return p2DataTaskService.call(1806, {
                    checklistdata_keyno: checklistdata_keyno,
                    srrefundinput_keyno: srrefundinput_keyno
                });
            },
            save: function (expense) {
                return p2DataTaskService.call(1807, expense);
            }
        };

        return service;
    }]);
})();