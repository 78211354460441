(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('consignmentShipments', {
        templateUrl: 'views/components/views/consignmentShipments/consignmentShipments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'consignmentShipmentsService', function (stateService, consignmentShipmentsService) {

    		// #region VARIABLES & DEFINITIONS

            let vm = this;
            let isLoading = true;
            let onDestroy = [];
            
            vm.model = {
                bgConsignmentShipmentStatus: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_consignmentshipment_status_active', item_func: 'ACTIVE' },
                        { item_id: '1', item_name: 'gb_consignmentshipment_status_sent', item_func: 'SENT' }
                    ]
                },
                filterButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.model.settingsConsignmentShipments.filterval = '' }
                ],
                settingsConsignmentShipments: {},
                listBusinessCo: [],
                listStockPlace: [],
                listConsignmentShipments: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS
            
    		// #region LOAD PROCEDURE FUNCTION CALLS
            
            let loadStockPlaces = function (callback) {
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.businessco_no_from) === true) return;
                if (vm.model.settingsConsignmentShipments.businessco_no_from === null) return;
                if (vm.model.settingsConsignmentShipments.businessco_no_from.trim().length < 1) return;
                
                return consignmentShipmentsService.loadStockPlaceList(vm.model.settingsConsignmentShipments.businessco_no_from).then(function (data) {
                    angular.copy(data, vm.model.listStockPlace);
                    isLoading = false;
                    
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                });
            };
            
            let loadConsignmentShipments = function () {
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.businessco_no_from) === true) return;
                if (vm.model.settingsConsignmentShipments.businessco_no_from === null) return;
                if (vm.model.settingsConsignmentShipments.businessco_no_from.trim().length < 1) return;
                
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.stockplace_id_from) === true) return;
                if (vm.model.settingsConsignmentShipments.stockplace_id_from === null) return;
                
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.dateselector) === true) return;
                
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.date_fom) === true) return;
                if (vm.model.settingsConsignmentShipments.date_fom === null) return;
                
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.date_tom) === true) return;
                if (vm.model.settingsConsignmentShipments.date_tom === null) return;
                
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.gb_consignmentshipment_status) === true) return;
                if (vm.model.settingsConsignmentShipments.gb_consignmentshipment_status === null) return;
                if (vm.model.settingsConsignmentShipments.gb_consignmentshipment_status.trim().length < 1) return;
                
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.filterval) === true) return;
                if (vm.model.settingsConsignmentShipments.filterval === null) return;
                
                vm.model.listConsignmentShipments = [];
                
                consignmentShipmentsService.loadConsignmentShipmentsList(vm.model.settingsConsignmentShipments).then(function (data) {
                    angular.copy(data, vm.model.listConsignmentShipments);
                    isLoading = false;
                });
            };
            
    		// #endregion LOAD PROCEDURE FUNCTION CALLS
            
    		// #region REMEMBER VALUE PROCEDURE FUNCTION CALLS
            
            let canRun616 = function (variableName, parm) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.settingsConsignmentShipments[parm]) === null) return false;
                if (vm.model.settingsConsignmentShipments[parm] === null) return false;
                
                return true;
            };
            
            let rememberBusinessCoNoFrom = function () {
                let variableNameBusinessCoNoFrom = 'consignmentshipments.businessco_no_from';
                
                if (canRun616(variableNameBusinessCoNoFrom, 'businessco_no_from') !== true) return;
                
                consignmentShipmentsService.remember(variableNameBusinessCoNoFrom, vm.model.settingsConsignmentShipments.businessco_no_from);
            };
            
            let rememberStockPlaceIdFrom = function () {
                let variableNameStockPlaceIdFrom = 'consignmentshipments.stockplace_id_from.businessco_no_from=' + vm.model.settingsConsignmentShipments.businessco_no_from;
                
                if (canRun616(variableNameStockPlaceIdFrom, 'stockplace_id_from') !== true) return;
                
                consignmentShipmentsService.remember(variableNameStockPlaceIdFrom, vm.model.settingsConsignmentShipments.stockplace_id_from);
            };
            
    		// #endregion REMEMBER VALUE PROCEDURE FUNCTION CALLS
            
    		// #region FETCH VALUE PROCEDURE FUNCTION CALLS
            
            let changedBusinessCoNo = function () {
                if (angular.isUndefined(vm.model.settingsConsignmentShipments.businessco_no_from) === true) return;
                if (vm.model.settingsConsignmentShipments.businessco_no_from === null) return;
                if (vm.model.settingsConsignmentShipments.businessco_no_from.trim().length < 1) return;
                
                consignmentShipmentsService.loadBusinessCoNoChanged(vm.model.settingsConsignmentShipments.businessco_no_from).then(function (data) {
                    vm.model.settingsConsignmentShipments.stockplace_id_from = data[0].stockplace_id_from;
                    vm.onStockplaceChanged(vm.model.settingsConsignmentShipments.stockplace_id_from);
                });
            };
            
    		// #endregion FETCH VALUE PROCEDURE FUNCTION CALLS
            
    		// #region BUTTON GROUP FUNCTION
            
            vm.selectBgConsignmentShipmentStatus = function (item) {
                vm.model.bgConsignmentShipmentStatus.item_selected = item;
                vm.model.settingsConsignmentShipments.gb_consignmentshipment_status = vm.model.bgConsignmentShipmentStatus.item_selected;
                loadConsignmentShipments();
            };
            
    		// #endregion BUTTON GROUP FUNCTION
            
    		// #region DIRECTION BUTTON FUNCTION
            
            vm.createNew = function (state) {
                let go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'consignmentshipment':
                        go({
                            consignmentshipment_keyno: '0',
                            stockplace_id: vm.model.settingsConsignmentShipments.stockplace_id_from
                        });
                        break;
                }
            };

            vm.onBusinessCompanyChanged = function (value) {
                vm.model.settingsConsignmentShipments.businessco_no_from = value;

                if (isLoading === true) return;
                
                loadStockPlaces().then(() => {
                    rememberBusinessCoNoFrom();
                    changedBusinessCoNo();
                });
            }
            
            vm.onStockplaceChanged = function (value) {
                vm.model.settingsConsignmentShipments.stockplace_id_from = value;

                if (isLoading === true) return;

                rememberStockPlaceIdFrom();
                loadConsignmentShipments();
            }
            
            vm.onDateselectorCheckboxChanged = function (value) {
                vm.model.settingsConsignmentShipments.dateselector = value;

                if (isLoading === true) return;

                loadConsignmentShipments();
            }

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.settingsConsignmentShipments.date_fom = startDate;
                vm.model.settingsConsignmentShipments.date_tom = endDate;
                vm.model.settingsConsignmentShipments.dateselector_index = dateInterval;

                if (isLoading === true) return;

                loadConsignmentShipments();
            }

            // #region ANGULAR LIFECYCLE

            vm.$onInit = function () {
                consignmentShipmentsService.loadConsignmentShipmentsSettings().then(function (data) {
                    vm.model.settingsConsignmentShipments = angular.copy(data[0]);
                
                    loadStockPlaces();
                    loadConsignmentShipments();
                });
            
                consignmentShipmentsService.loadBusinessCoList().then(function (data) {
                    angular.copy(data, vm.model.listBusinessCo);
                });
            }

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

		    // #endregion ANGULAR LIFECYCLE
        }]
    });
})();
