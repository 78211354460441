(function () {
    'use strict';

    angular.module('imApp').factory("helpdocService", ['$ihttp', function ($ihttp) {
        let service = {

            /**
             * Gets the settings for the helpdoc with the given argument type and argument value.
             * 
             * @param {string} argtype the type to get the helpdoc settings for.
             * @param {string} argvalue the key of the helpdoc to get the settings for.
             * @returns {Promise<{data: object[]; iserror: boolean; message: string;}>} a promise containing the settings data.
             */
            getHelpDocSettings: function (argtype, argvalue) {
                return $ihttp.post({ method: 833, parameters: { argtype: argtype, argvalue: argvalue } });
            },

            /**
             * Retrieves the helpdoc with the given argtype, argvalue and on the specified language_id.
             * 
             * @param {{ argtype: string; argvalue: string; language_id: string;}} settings
             * @returns {Promise<{data: object[]; iserror: boolean; message: string;}>} a promise containing the helpdoc.
             */
            getHelpDoc: function (settings) {
                return $ihttp.post({ method: 834, parameters: settings });
            },

            /**
             * Saves the given helpdoc. Requires the active selected helpdoc view state and active generally view state.
             * 
             * @param {object} helpdoc the edited helpdoc to save.
             * @returns {Promise<{iserror: boolean}>} returns a promise containing whether an error occured or not.
             */
            saveHelpDoc: function (helpdoc) {
                return $ihttp.post({ method: 835, parameters: helpdoc });
            },

            /**
             * Retrieves the related helpdoc articles from the touchtime-helpdoc with the given keyno.
             * 
             * @param {string} ttHelpdocKeyno the keyno for the touchtime-helpdoc to get related docs from.
             * @param {string} languageId the language to retrieve the docs on.
             * @returns {Promise<object[]>} a promise containing an item list of the related docs.
             */
            getRelatedDocs: function (ttHelpdocKeyno, languageId) {
                return $ihttp.post({ method: 836, parameters: { helpdoctt_keyno: ttHelpdocKeyno, language_id: languageId } });
            },

            /**
             * @typedef {Object} Language
             * @property {'1' | '0'} currentlanguage '1' if the language is the currently chosen language of the user, '0' if not.
             * @property {string} google_language_id the language id used to make api calls to google api.
             * @property {string} item_id the language id used in touchtime.
             * @property {string} item_name the displayed name of the language.
             * @property {string} orderby the order of the language.
             */

            /**
             * Retireves a list over available languages.
             * 
             * @returns {Promise<Language[]>}
             */
            getLanguageList: function () {
                return $ihttp.post({ method: 57 });
            },
        };

        return service;
    }]);
})();
