(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("shiftViewService", ['$q', '$ihttp', 'utilityService',  function ($q, $ihttp, utilityService) {
        var service = {
            loaded: false,
            dataSource: {
                dates: [],
                months: {},
                monthnames: {},
                dayletters: {}
            },
            dataSetLock: false,
            shifts: {
                records: [],
                recordsIndexed: {}
            },
            createEmptyDay: function (item, labour_no) {
                var out = {};
                out = {
                    year: item.year,
                    week_no: item.week_no,
                    month_no: '',
                    month_name: '',
                    day_no: '',
                    week_day: '',
                    day_letter: '',
                    day_name: '',
                    background_color_hex: '',
                    text_color_hex: '',
                    remark: '',
                    systemdate: '',//item.systemdate,
                    time_from: '',
                    time_to: '',
                    is_protected: 1,
                    labour_no: labour_no,
                    shift_id: '',
                }
                return out;
            },
            getShiftView: function (labourNo ) {
                return $ihttp.post({
                    method: 2019,
                    parameters: {
                        labour_no: labourNo
                    }
                });
            },
            loadDates: function (year, labour_no) {
                var i = 0;

                if (service.dataSetLock) {
                    while (service.dataSetLock) {
                        i += 1;
                        if (i >= 5) return i;
                        utilityService.sleep(1000);
                    }
                }

                var deferred = $q.defer();

                if (!year > 0) {
                    year = 2017;
                }

                var parms_loadDates = {
                    method: 524,
                    parameters: {
                        year: year,
                        labour_no: labour_no
                    }
                };

                $ihttp.post(parms_loadDates).then(function (data) {
                    service.dataSource.dates = [];

                    angular.forEach(data, function (item) {
                        item.codes = {};
                        item.background_color_hex = item.background_color_hex;
                        item.text_color_hex = item.text_color_hex;
                        item.original_background_color_hex = item.background_color_hex;
                        item.original_text_color_hex = item.text_color_hex;
                        item.shift_background_color_hex = item.background_color_hex;
                        item.shift_text_color_hex = item.text_color_hex;

                        if (item.shift_id != '') {
                            if (angular.isDefined(service.shifts.recordsIndexed[item.shift_id])) {
                                item.shift_background_color_hex = service.shifts.recordsIndexed[item.shift_id].background_color_hex;
                                item.shift_text_color_hex = service.shifts.recordsIndexed[item.shift_id].text_color_hex;
                                item.background_color_hex = service.shifts.recordsIndexed[item.shift_id].background_color_hex;
                                item.text_color_hex = service.shifts.recordsIndexed[item.shift_id].text_color_hex;
                            }
                        }

                        service.dataSource.dates.push(item);
                    });

                    var month_no = 1;
                    var weeks = {};
                    var days = {};
                    var last_day_no = '';
                    var last_week_no = '';
                    var last_month_name = '';

                    angular.forEach(service.dataSource.dates, function (item) {
                        service.dataSource.dayletters[item.week_day] = item.day_letter;

                        if (month_no != item.month_no) {
                            if (last_day_no < 7) {
                                var i = last_day_no + 1;

                                for (i = last_day_no + 1; i < 7; i++) {
                                    days[i] = service.createEmptyDay(item, labour_no);
                                }

                                weeks[/*month_no+'_'+*/last_week_no + ' '] = days;
                            }

                            service.dataSource.months[month_no] = weeks;
                            service.dataSource.monthnames[month_no] = last_month_name;
                            weeks = {};
                            days = {};
                        }

                        if (utilityService.objectLength(days) == 0) {
                            if (item.week_day != 1) {
                                var i = 1;

                                for (i = 1; i < item.week_day; i++) {
                                    days[i] = service.createEmptyDay(item, labour_no);
                                }
                            }

                            days[item.week_day] = item;

                            if (item.week_day == 7) {
                                weeks[/*item.month_no+'_'+*/item.week_no + ' '] = days;
                                days = {};
                            }
                        } else {
                            days[item.week_day] = item;

                            if (item.week_day == 7) {
                                weeks[/*item.month_no+'_'+*/item.week_no + ' '] = days;
                                days = {};
                            }
                        }

                        last_day_no = item.week_day;
                        last_week_no = item.week_no;
                        month_no = item.month_no;
                        last_month_name = utilityService.toCamelCase(item.month_name);
                    });

                    if (utilityService.objectLength(weeks) > 0) {
                        if (last_day_no < 7) {
                            var i = last_day_no + 1;

                            for (i = last_day_no + 1; i < 7; i++) {
                                days[i] = service.createEmptyDay(item, labour_no);
                            }

                            weeks[/*month_no+'_'+*/last_week_no + ' '] = days;
                        }

                        service.dataSource.months[month_no] = weeks;
                        service.dataSource.monthnames[month_no] = last_month_name;
                    }

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadShifts: function (labourNo) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 516,
                    parameters: {
                        labour_no: labourNo
                    }
                }).then(function (data) {
                    service.shifts.records = [];
                    service.shifts.recordsIndexed = {};

                    angular.forEach(data, function (item) {
                        item.selected_style = '';
                        item.shift_days = 0;
                        if (item.item_validok > 0) {
                            service.shifts.records.push(item);
                        }
                    });

                    angular.forEach(service.shifts.records, function (item) {
                        service.shifts.recordsIndexed[item.item_id] = item;
                    });

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            illustrateGraphData: function (year, labourNo, bgTimePeriod) {
                return $ihttp.post({
                    method: 2031,
                    parameters: {
                        year: year,
                        labour_no: labourNo,
                        bg_timeperiod: bgTimePeriod
                    }
                });
            },
            saveObj: function (dataSource) {
                return $ihttp.post({
                    method: 533,
                    parameters: {
                        records: dataSource
                    }
                });
            }
        };

        return service;
    }]);
})();