(function () {
    'use strict';

    angular.module("imApp").factory("colliDispatchService", ['$ihttp', function ($ihttp) {
        var service = {
            getColliDispatch: function (parms) {
                return $ihttp.post({
                    method: 2601,
                    parameters: parms || {}
                });
            },
            listDeliveryMethods: function (parms) {
                return $ihttp.post({
                    method: 1391,
                    parameters: parms || {}
                });
            },
            pickUpReadyItems: function (edit) {
                return $ihttp.post({
                    method: 2607,
                    parameters: edit
                });
            },
            pickUpCompilationReadyItems: function (edit) {
                return $ihttp.post({
                    method: 2614,
                    parameters: edit
                });
            },
            pickUpNotReadyItems: function (edit) {
                return $ihttp.post({
                    method: 2612,
                    parameters: edit
                });
            },
            sendColliNotReadyItems: function (edit) {
                return $ihttp.post({
                    method: 2613,
                    parameters: edit
                });
            },
            sendSentItems: function (edit) {
                return $ihttp.post({
                    method: 2615,
                    parameters: edit
                });
            },
            completeConsignmentItems: function (edit) {
                return $ihttp.post({
                    method: 2635,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();