<tt-html-proc-editor htmlKeyno ="1" procKeyno="1"></tt-html-proc-editor>


    <!-- <tt-kanban [ttKanbanList]="[
            {
                'item_id': '623833',
                'item_name': 'Testing av kanban komponent - Registrering pågår',
                'ok': '0',
                'is_deviation': '0',
                'item_path': 'workdetails/623833',
                'item_state': 'workdetails',
                'item_parms': '623833',
                'item_glyphicon': 'glyphicon-unchecked fa-bus fas-video fas-calendar-alt',
                'item_glyphicon_color': 'black #5bc0de red red',
                'item_sort_id': '300',
                'orderby': '1',
                'item_name_sub1': 'InSoft Norge as',
                'item_name_sub2': '2024 (Insoft Student) ()',
                'sortcolumn': 'zz 2024-10-09 13:48:15.280',
                '$$hashKey': 'object:301',
                'editable': 'false'
            },
            {
                'item_id': '623831',
                'item_name': 'B - Testoppgave 2 - I arbeid',
                'ok': '0',
                'is_deviation': '0',
                'item_path': 'workdetails/623831',
                'item_state': 'workdetails',
                'item_parms': '623831',
                'item_glyphicon': 'glyphicon-unchecked fas-blog fas-video fas-calendar-alt',
                'item_glyphicon_color': 'black #F89406 red red',
                'item_sort_id': '300',
                'orderby': '2',
                'item_name_sub1': 'InSoft Norge as',
                'item_name_sub2': '2024 (Insoft Student) ()',
                'sortcolumn': 'zz 2024-10-09 09:17:00.135',
                '$$hashKey': 'object:302',
                'editable': 'false'
            },
            {
                'item_id': '623829',
                'item_name': 'Decoupling av kanban modul fra imLIstDirective - I arbeid',
                'ok': '0',
                'is_deviation': '0',
                'item_path': 'workdetails/623829',
                'item_state': 'workdetails',
                'item_parms': '623829',
                'item_glyphicon': 'glyphicon-unchecked fas-blog fas-video fas-calendar-alt',
                'item_glyphicon_color': 'black #F89406 red red',
                'item_sort_id': '300',
                'orderby': '3',
                'item_name_sub1': 'InSoft Norge as',
                'item_name_sub2': '2024 (Insoft Student) ()',
                'sortcolumn': 'zz 2024-10-09 09:15:39.680',
                '$$hashKey': 'object:303',
                'editable': 'false'
            },
            {
                'item_id': '623834',
                'item_name': 'Enda en liten test av kanban systemet - I arbeid',
                'ok': '0',
                'is_deviation': '0',
                'item_path': 'workdetails/623834',
                'item_state': 'workdetails',
                'item_parms': '623834',
                'item_glyphicon': 'glyphicon-unchecked fas-blog fas-video fas-calendar-alt',
                'item_glyphicon_color': 'black #F89406 red red',
                'item_sort_id': '300',
                'orderby': '4',
                'item_name_sub1': 'InSoft Norge as',
                'item_name_sub2': '2024 (Insoft Student) ()',
                'sortcolumn': 'zz 2024-10-09 08:45:44.151',
                '$$hashKey': 'object:304',
                'editable': 'false'
            },
            {
                'item_id': '623828',
                'item_name': 'Innføring i TouchTime - Registrering pågår',
                'ok': '0',
                'is_deviation': '0',
                'item_path': 'workdetails/623828',
                'item_state': 'workdetails',
                'item_parms': '623828',
                'item_glyphicon': 'glyphicon-unchecked fa-bus fas-video fas-calendar-alt',
                'item_glyphicon_color': 'black #5bc0de red red',
                'item_sort_id': '1100',
                'orderby': '5',
                'item_name_sub1': 'InSoft Norge as',
                'item_name_sub2': '2024 (Insoft Student) ()',
                'sortcolumn': 'zz 2024-10-08 14:32:17.664',
                '$$hashKey': 'object:305',
                'editable': 'false'
            }
        ]"
               [ttKanbanCategories]="[
                {
                    'actstatus_keyno': '0',
                    'actstatus_name': 'Alle',
                    'orderby': '1',
                    'glyphicon': '',
                    'glyphicon_color': '',
                    '$$hashKey': 'object:297'
                },
                {
                    'actstatus_keyno': '1100',
                    'actstatus_name': 'Registrering pågår',
                    'orderby': '2',
                    'glyphicon': 'fa-bus',
                    'glyphicon_color': '#5bc0de',
                    '$$hashKey': 'object:298'
                },
                {
                    'actstatus_keyno': '300',
                    'actstatus_name': 'I arbeid',
                    'orderby': '3',
                    'glyphicon': 'fas-blog',
                    'glyphicon_color': '#F89406',
                    '$$hashKey': 'object:299'
                },
                {
                    'actstatus_keyno': '200',
                    'actstatus_name': 'Ferdig og sett OK',
                    'orderby': '4',
                    'glyphicon': 'fas-ball-pile',
                    'glyphicon_color': '#d9534f',
                    '$$hashKey': 'object:300'
                }

        ]"
               [dataId]="'actstatus_keyno'"
               [dataName]="'actstatus_name'">
    </tt-kanban>

    <!-- <canvas #displayCanvas (click)="openSignaturePad()" style="border: 1px solid black; cursor: pointer;" ttClear="true"></canvas> -->
    <!-- <tt-signature-pad [ttClear] ='true'></tt-signature-pad> -->




