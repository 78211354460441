(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("timeProjectsWoService", ['$q', '$ihttp', 'consoleService', function ($q, $ihttp, consoleService) {
        var service = {
            loaded: false,
            time_projects_wo: {
                cust_no: '',
                cust_name: '',
                project_keyno: '',
                projectprosess_keyno: '',
                ok: '',
                activitytype_name: '',
                timestamp_start: '',
                item_id: '',
                item_name: '',
                item_state: '',
                item_parms: '',
                item_path: '',
                records: []
            },
            //time_projects_wo_list: {
            //    cust_name: 'ALL',
            //    cust_no: '0',
            //    project_keyno: '',
            //    ok: '0'
            //},
            loadTimeProjectsWo: function (cust_name, cust_no, project_keyno, ok) {
                var deferred = $q.defer();

                var parms_custact_list = {
                    method: 27,
                    parameters: {
                        cust_name: cust_name,
                        cust_no: cust_no,
                        project_keyno: project_keyno,
                        ok: ok
                    }
                };

                //consoleService.log('parms_custact_list');
                //consoleService.log(angular.toJson(parms_custact_list));

                $ihttp.post(parms_custact_list).then(function (data) {
                    //consoleService.log(angular.toJson(data));

                    service.time_projects_wo.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.time_projects_wo.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();