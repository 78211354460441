(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectCheckListAdd', {
        templateUrl: 'views/components/views/selectCheckListAdd/selectCheckListAdd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectCheckListAddService', 'stateService', function ($stateParams, selectCheckListAddService, stateService) {
            var vm = this;
            
            selectCheckListAddService.loadP2SelectCheckListList($stateParams.argtype, $stateParams.argvalue).then(function () {
                vm.p2_select_checklist_list = selectCheckListAddService.p2_select_checklist_list;
            });
            
            vm.saveToP2SelectCheckList = function (item) {
                selectCheckListAddService.saveToP2SelectCheckList(item).then(function () {
                    vm.p2_select_checklist_save = selectCheckListAddService.p2_select_checklist_save;
                    stateService.go(vm.p2_select_checklist_save.webpagename, vm.p2_select_checklist_save.parms);
                });
            };
        }]
    });
})();