(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epSchenkerOverview', {
        templateUrl: 'views/components/views/epSchenkerOverview/epSchenkerOverview.template.html?v=' + module.version,
        controllerAs: 'vm',
        //controller: ['$state', '$uibModal', 'layoutService', 'responsive', function ($state, $uibModal, layoutService, responsive) {
        controller: ['$state', 'modalService', 'layoutService', 'ieScreenBlockService', 'epSchenkerOverviewService', function ($state, modalService, layoutService, ieScreenBlockService, epSchenkerOverviewService) {
            var vm = this;

            vm.gridControls = null;
            vm.fontSize = null;

            vm.gridOptions = {
                dataTaskKeyno: 2059,
                rememberId: 'epSchenkerOverview',
                translations: ['getedi'],
                gridControlCallback: function (gridControls) { vm.gridControls = gridControls; },
                onSetup: function (schema, fields, columns, translations) {
                    var cols = [
                        { command: { text: translations.getedi, click: vm.getEdi, className: 'col-xs-12 tt-btn-success-important' }, title: " ", width: "300px", attributes: { style: 'font-size: ' + vm.fontSize + ';' } },
                        columns[0],
                        columns[1],
                        columns[2],
                        columns[3],
                        columns[4],
                        columns[5]
                    ];

                    angular.copy(cols, columns);
                }
            };

            vm.getEdi = function (e) {
                e.preventDefault();

                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                console.log('dataItem');
                console.dir(dataItem);

                modalService.confirm({
                    title: 'Bekreft',
                    message: 'Vennligst bekreft at du vil hente edi data for booking "' + dataItem.Booking + '".',
                    okLabel: 'Ok',
                    cancelLabel: 'Avbryt'
                }).then(function () {
                    ieScreenBlockService.start();

                    epSchenkerOverviewService.getEdi(dataItem.shipmentcontainer_keyno).then(function () {
                        if (angular.isFunction(gridControls?.refresh)) {
                            gridControls.refresh();
                        }

                        ieScreenBlockService.stop();

                        modalService.show({
                            type: 'info',
                            title: 'Fullført',
                            message: 'Edi for booking "' + dataItem.Booking + '" er hentet.',
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (self) {
                                    self.close();
                                }
                            }]
                        });
                    }, function () {
                        ieScreenBlockService.stop();

                        modalService.show({
                            type: 'error',
                            title: 'Error',
                            message: 'Det har oppstått en feil ved henting av edi for booking "' + dataItem.Booking + '".',
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (self) {
                                    self.close();
                                }
                            }]
                        });
                    }).finally(() => {
                        ieScreenBlockService.stop();
                    });
                });
            };

            //vm.getEdi = function (e) {
            //    e.preventDefault();

            //    var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

            //    if (['xx', 'xs', 'sm', 'md'].indexOf(responsive.current) > 0) {
            //        $state.go('epschenkeroverviewgetedi', { shipmentContainerKeyno: dataItem.shipmentcontainer_keyno });
            //    } else {
            //        $uibModal.open({
            //            component: 'epSchenkerOverviewGetEdi',
            //            resolve: {
            //                shipmentContainerKeyno: function () {
            //                    return dataItem.shipmentcontainer_keyno
            //                }
            //            },
            //            size: 'pst-ninety',
            //            backdrop: 'static'
            //        }).result.then(function () {
            //            vm.gridControls.refresh();
            //        });
            //    }
            //};

            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSizeS;
            });

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
