(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('printDialogue', {
        templateUrl: 'views/components/views/printDialogue/printDialogue.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'printDialogueService', 'printService', 'ieScreenBlockService', 'dbReportService', 'printPreviewService', 'pdfService', 'htmlReportService', 'translateService', 'base64', function ($stateParams, stateService, utilityService, modalService, printDialogueService, printService, ieScreenBlockService, dbReportService, printPreviewService, pdfService, htmlReportService, translateService, base64) {

            var vm = this;
            
            var reportDefinitions = {};
    
            var translations = {
                printdialogue_confirm_title: '',
                printdialogue_confirm_message: '',
                printdialogue_confirm_ok_label: '',
                printdialogue_confirm_cancel_label: ''
            };
    
            vm.model = {
                reportDefKeyno: $stateParams.p2_reportdef_keyno,
                reportKeyno: 0,
                dbReport: {},
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                currentReport: {},
                selectListPrinters: [],
                //isLandscape: '0'
            };
    
            var load = function () {
                console.log('load');

                ieScreenBlockService.start();

                console.log('01');

                printDialogueService.load(vm.model.reportDefKeyno).then(function (data) {
                    console.log('data');
                    console.dir(data);

                    angular.copy(data.dbReport, vm.model.dbReport);

                    angular.copy(data.report, vm.model.currentReport);

                    console.log('02');

                    angular.copy(data.printers, vm.model.selectListPrinters);

                    console.log('03');

                    vm.model.reportKeyno = data.reportKeyno;

                    console.log('04');

                    //vm.model.isLandscape = data.isLandscape;
                    ieScreenBlockService.stop();

                    console.log('05');
                });
            };
    
            load();
    
            printPreviewService.loadP2ReportDef(vm.model.reportDefKeyno).then(function (data) {
                reportDefinitions = angular.copy(data[0]);
            });
    
            // ## PRINT BUTTON FUNCTION ##
    
            vm.print = async function () {
                if (angular.isUndefined(vm.model.currentReport.default_printer_keyno) || vm.model.currentReport.default_printer_keyno === null || vm.model.currentReport.default_printer_keyno === '0') {
                    modalService.confirm({
                        type: 'danger',
                        title: translations.printdialogue_confirm_title,
                        message: translations.printdialogue_confirm_message,
                        okLabel: translations.printdialogue_confirm_ok_label,
                        cancelLabel: translations.printdialogue_confirm_cancel_label
                    });
                } else {
                    var parms = {
                        reportKeyno: vm.model.currentReport.report_keyno,
                        printerKeyno: vm.model.currentReport.default_printer_keyno,
                        copies: vm.model.currentReport.default_count,
                        parameters: {}
                    };

                    parms.parameters[vm.model.argType] = vm.model.argValue;
    
                    ieScreenBlockService.start();

                    if (vm.model.dbReport.report_type_keyno == 2) {
                        parms.reportKeyno = vm.model.currentReport.report_keyno;
                        const result = await pdfService.printPDF(parms);
                        if (result.errorcode != 0) {
                            modalService.confirm({
                                type: 'danger',
                                title: result.errormessage,
                                message: result.errormessage,
                                okLabel: translations.printdialogue_confirm_ok_label,
                                cancelLabel: translations.printdialogue_confirm_cancel_label
                            });
                        }
                    } else {
                        
                        parms.printerProperties = {
                            isLandscape: reportDefinitions.isLandscape,
                            pageAutoSize: true,
                            copies: vm.model.currentReport.default_count
                        };

                        // BJS 20200221
                        if (reportDefinitions.paper_size_id === 'custom') {
                            parms.printerProperties.pageAutoSize = false;
                            parms.printerProperties.scale = 'none';
                            parms.printerProperties.paperSize = {
                                width: reportDefinitions.width,
                                height: reportDefinitions.height
                            };
                        }
                        await printService.print(parms);
                    }
                    ieScreenBlockService.stop();
                }
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'printpreview':
                        go({
                            report_keyno: vm.model.reportDefKeyno, // BJS 20241017 - MÅ sende reportdef_keyno her, IKKE htmlreport_keyno
                            printer_keyno: vm.model.currentReport.default_printer_keyno,
                            argtype: vm.model.argType,
                            argvalue: vm.model.argValue
                        });
                        break;
                    case 'emailcreate':
                        go({
                            webpage: $stateParams.webpage_name,
                            argtype: vm.model.argType,
                            argvalue: vm.model.argValue
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region TRANSLATION FUNCTION
    
            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });
    
    		// #endregion TRANSLATION FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'default_printer_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.currentReport.default_printer_keyno) !== true) return;

                        vm.model.currentReport.default_printer_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.currentReport.default_printer_keyno, true) !== true) return;

                        dbReportService.setUserReportPrinter(vm.model.reportKeyno, vm.model.currentReport.default_printer_keyno);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

        }]
    });
})();
