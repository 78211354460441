(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockCorrCodes', {
        templateUrl: 'views/components/views/stockCorrCodes/stockCorrCodes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'stockCorrCodesService', function (stateService, stockCorrCodesService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            vm.model = {
                itemsListStockCorrectionCodes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadStockCorrectionCodes = function () {
                stockCorrCodesService.listStockCorrectionCodes().then(function (result) {
                    angular.copy(result, vm.model.itemsListStockCorrectionCodes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockcorrcode':
                        go({ stockcorrcode_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStockCorrectionCodes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
