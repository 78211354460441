(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('customerClaims', {
        templateUrl: 'views/components/views/customerClaims/customerClaims.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'customerClaimsService', 'translateService', 'layoutService', function ($stateParams, customerClaimsService, translateService, layoutService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];

            vm.model = {
                creditstop: 'creditstop',
                claims: {},
                successStyle: {
                    fontSize: '18px',
                    backgroundColor: 'linear-gradient(to bottom,#5cb85c 0,#449d44 100%)',
                    paddingLeft: '7px'
                },
                dangerStyle: {
                    fontSize: '18px',
                    backgroundColor: '#DC3545',
                    paddingLeft: '7px'
                }
            }

            vm.translations = {
                tt_creditstop_true: '',
                tt_creditstop_false: ''
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.model.successStyle.fontSize = info.fontSizes.textSize;
                vm.model.dangerStyle.fontSize = info.fontSizes.textSize;
            });

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                customerClaimsService.getCustomerClaims($stateParams).then(function (data) {
                    angular.copy(data[0], vm.model.claims);
                    //if (vm.model.claims.creditstop === 1) {
                    //    vm.model.style.input.backgroundColor = '#DC3545';
                    //} else {
                    //    vm.model.style.input.backgroundColor = 'linear-gradient(to bottom,#5cb85c 0,#449d44 100%)';
                    //}
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();