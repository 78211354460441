(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('languages', {
        templateUrl: 'views/components/views/languages/languages.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ttGridFactory', function (stateService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_' + stateService.getCurrentName() + '.' + 'languages_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([{
                    name: 'languages_new',
                    text: 'languages_new',
                    func: function () {
                        stateService.go('language', { language_id: '0' });
                    },
                    icon: 'fa-plus',
                    cssClass: 'btn btn-primary im-grid-btn-xs-r',
                    translate: true
                }]);
            
            // #endregion VARIABLES & DEFINITIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3346,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadGrid();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
