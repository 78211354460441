(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2ExportProcUserGroups', {
        templateUrl: 'views/components/views/p2ExportProcUserGroups/p2ExportProcUserGroups.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'p2ExportProcUserGroupsService', function ($stateParams, stateService, utilityService, p2ExportProcUserGroupsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                p2ExportProcKeyNo: $stateParams.p2_exportproc_keyno,
                settingUserGroup: {},
                itemsListUserGroups: [],
                locked: {
                    save: false
                }
            };
            
            // #endregion VARIABLES & DEFINITIONS
            
            // #region LOAD PROCEDURE FUNCTION CALLS
            
            p2ExportProcUserGroupsService.loadP2ExportProcUserGroupsGet(vm.model.p2ExportProcKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.settingUserGroup);
                
                loadExportProcUserGroups();
            });
            
            var loadExportProcUserGroups = function () {
                if (utilityService.validateParmsValue(vm.model.settingUserGroup.p2_exportproc_keyno) !== true) return;
                
                vm.model.itemsListUserGroups = [];
                
                p2ExportProcUserGroupsService.loadExportProcUserGroupsList(vm.model.settingUserGroup.p2_exportproc_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListUserGroups);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region TOGGLE LIST FUNCTION
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
            
    		// #endregion TOGGLE LIST FUNCTION
            
            // #region SAVE BUTTON FUNCTION
            
            vm.saveUserGroups = function () {
                vm.model.locked.save = true;
                
                var mergeObj = {
                    records: vm.model.itemsListUserGroups.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; })
                };
                
                p2ExportProcUserGroupsService.saveObj(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.locked.save = false;
                        });
                    } else {
                        vm.model.locked.save = false;
                        stateService.back();
                    }
                });
            };
            
            // #endregion SAVE BUTTON FUNCTION
        }]
    });
})();