(function() {
    'use strict';

    angular.module("imApp").factory("modalReportPreviewService", ['$uibModal', function ($uibModal) {
        var service = {
            show: function (parameters) {
                $uibModal.open({
                    component: 'modalReportPreview',
                    resolve: {
                        parameters: function () {
                            return parameters;
                        }
                    },
                    size: 'pst-ninety',
                    backdrop: 'static'
                });
            }
        }

        return service;
    }]);
})();
