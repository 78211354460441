(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srUsers', {
        templateUrl: 'views/components/views/srUsers/srUsers.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'rememberService', 'srUsersService', 'watcherFactory', function($timeout, stateService, utilityService, rememberService, srUsersService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                setting: {},
                itemsListUsers: [],
                searching: false
            };
    
            // ## INIT LOAD FUNCTION ##
    
            var initLoadDone = false;
            var timerInitLoadDone = null;
    
            var initLoadFunc = function () {
                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }
    
                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            };
    
            // ## IF CONDITIONS FUNCTION ##
    
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.setting[parmWithValue]) === true) return false;
                if (vm.model.setting[parmWithValue] === null) return false;
                if (vm.model.setting[parmWithValue].trim().length < 1) return false;
    
                return true;
            };
    
            var validateParmsEmptyValue = function (parmEmptyValue) {
                if (angular.isUndefined(vm.model.setting[parmEmptyValue]) === true) return false;
                if (vm.model.setting[parmEmptyValue] === null) return false;
    
                return true;
            };
    
    		var validateVariables = function (variableName) {
    			if (angular.isUndefined(variableName) === true) return false;
    			if (variableName === null) return false;
    			if (variableName.trim().length < 1) return false;
    
    			return true;
    		};
    
    		var validateNewOldValue = function (newVal, oldVal) {
    			if (angular.isUndefined(newVal) === true) return false;
    			if (angular.isUndefined(oldVal) === true) return false;
    			if (newVal === oldVal) return false;
    
    			return true;
    		};
    
            // ## LOAD PROCEDURE CALLS ##
    
            srUsersService.loadSrUsersGet().then(function (data) {
                vm.model.setting = angular.copy(data[0]);
    
                loadUsers();
                initLoadFunc();
            });
    
            var loadUsers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_deleted_users, true) !== true) return;
    
                vm.model.searching = true;
                vm.model.itemsListUsers = [];
    
                srUsersService.loadSrUsersList(vm.model.setting.searchtext, vm.model.setting.show_deleted_users).then(function (data) {
                    vm.model.searching = false;
                    angular.copy(data, vm.model.itemsListUsers);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var timerSearchText = null;
    
            var rememberSearchText = function () {
                var variableNameSearchText = 'w_srusers.searchtext';
    
                if (initLoadDone !== true) return;
                if (validateVariables(variableNameSearchText) !== true) return;
                if (validateParmsEmptyValue('searchtext') !== true) return;
    
                if (angular.isDefined(timerSearchText)) {
                    $timeout.cancel(timerSearchText);
                }
    
                timerSearchText = $timeout(function () {
                    rememberService.remember(variableNameSearchText, vm.model.setting.searchtext);
                }, 500);
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.searchTextBtnListFunc = function (item_func) {
                if (item_func === 'erase_item') {
                    vm.model.setting.searchtext = '';
                } else if (item_func === 'search_item') {
                    loadUsers();
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'sruser':
                        go({
                            portal_user_keyno: '0'
                        });
                        break;
                }
            };
    
            // ## SEARCH FUNCTION ##
    
            vm.searchItems = function () {
                loadUsers();
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () {
                return vm.model.setting.searchtext;
            }, function (newValue, oldValue) {
                if (validateNewOldValue(newValue, oldValue) !== true) return;
    
                if (vm.model.setting.searchtext === '') {
                    loadUsers();
                }
    
                rememberSearchText();
            });
    
            watcher.$watch(function () { return vm.model.setting.show_deleted_users; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadUsers();
            });
        }]
    });
})();