/**
 * This file imports the third party library dependencies, then creates the angular module "imApp"
 * and exports it.
 */

// External dependencies
import * as angular from "angular";
import uiRouter from "@uirouter/angularjs";
import { environment } from '../environments/environment';
import { downgradeInjectable, downgradeComponent } from "@angular/upgrade/static";
import { GraphAuthService } from "../app/modules/microsoft-graph/auth/services/graph-auth.service";
import { CalendarService } from "../app/modules/microsoft-graph/services/graph-calendar.service";
import { GraphUserService } from "../app/modules/microsoft-graph/services/graph-user.service";
import { GraphMailService } from "../app/modules/microsoft-graph/services/graph-mail.service";
//import { AuthBridgeService } from "../app/core/authentication/services/auth-bridge.service";
import { GoogleTranslateService } from "../app/services/google-translate.service";
import { CardComponent } from "../app/core/components/card/card.component";
import { PageComponent } from "../app/core/components/page/page.component";
import { ResponsiveService } from "../app/core/services/responsive.service";
import { ResponsiveBridgeService } from "../app/core/services/responsive-bridge.service";
import { UserStore } from "../app/core/services/user.store";
import { UserService } from "../app/core/services/user.service";
import { LayoutService } from "../app/core/services/layout.service";
import { RememberService } from "../app/core/services/remember.service";
import { NotificationsService } from "../app/core/services/notifications.service";
import { MainMenuService } from "../app/views/components/mainmenu/mainmenu.service";
//import { InputComponent } from "../app/core/components/input/input.component";

interface ITouchTimeModule extends angular.IModule {
    version: string
}

// Create the angularjs module "imApp".
//
// Since it is exported, other parts of the application (in other files) can then import it and register things.
// In bootstrap.js, the module is imported, and the components, services, and states are registered.
var imAppModule = angular.module("imApp", [
    uiRouter,
    'ui.router.upgrade',
    'imApp',
    'ui.select',
    'ui.router.state.events',
    'angular-md5',
    'ttCompany',
    'tt.interceptor',
    'ngAnimate',
    'ngSanitize',
    'schemaForm',
    'monospaced.elastic',
    'ngFileSaver',
    'signature',
    'ui.bootstrap',
    'ui.sortable',
    'ya.nouislider',
    'ngStorage',
    'ab-base64',
    'appConfig',
    'ie.http',
    'ie.fileserver',
    'ie.printserver',
    'ie.screenblock',
    'ie.officeonline',
    'kendo.directives'
]) as ITouchTimeModule;

imAppModule.version = environment.version;

imAppModule.factory('graphAuthService', downgradeInjectable(GraphAuthService) as any);
imAppModule.factory('graphCalendarService', downgradeInjectable(CalendarService) as any);
imAppModule.factory('graphUserService', downgradeInjectable(GraphUserService) as any);
imAppModule.factory('graphMailService', downgradeInjectable(GraphMailService) as any);
imAppModule.directive('ttCard', downgradeComponent({ component: CardComponent }));
imAppModule.directive('ttPage', downgradeComponent({ component: PageComponent }));
imAppModule.factory('googleTranslateService', downgradeInjectable(GoogleTranslateService));

imAppModule.factory('responsiveBridgeService', downgradeInjectable(ResponsiveBridgeService));
imAppModule.factory('responsive', downgradeInjectable(ResponsiveService));
imAppModule.factory('ngUserService', downgradeInjectable(UserService));
imAppModule.factory('userStore', downgradeInjectable(UserStore));
imAppModule.factory('layoutService', downgradeInjectable(LayoutService));
imAppModule.factory('rememberService', downgradeInjectable(RememberService)); // BJS 20241010
imAppModule.factory('p2NotificationsService', downgradeInjectable(NotificationsService)); // BJS 20241014
imAppModule.factory('mainMenuService', downgradeInjectable(MainMenuService)); // BJS 20241014

export default imAppModule;
