(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('timeRegister', {
        templateUrl: 'views/components/views/timeRegister/timeRegister.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$ihttp', '$q', '$stateParams', '$timeout', '$filter', 'translateService', 'modalService', 'stateService', 'utilityService', 'timeRegisterService', 'trNewJobConfService', 'logisticService', 'economyService', 'typeaheadService', function ($ihttp, $q, $stateParams, $timeout, $filter, translateService, modalService, stateService, utilityService, timeRegisterService, trNewJobConfService, logisticService, economyService, typeaheadService) {

            var vm = this;

            // ## VARIABLES & DEFINITIONS ##

            var onDestroy = [];

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                custactNo: $stateParams.custact_no,
                powTimeKeyno: $stateParams.powtime_keyno,
                webPageName: stateService.getCurrentName(),
                projectButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', onClick: () => vm.model.time.project_keyno = '0' },
                ],
                projectProcessButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', onClick: () => vm.model.time.projectprosess_keyno = '0' },
                    { id: 'list', icon: 'fas fa-bars', type: 'primary', onClick: () => vm.goTo('projectprocesses') }
                ],
                custactButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', onClick: resetCustactNo }
                ],
                equipmentButtons: [
                    { id: 'list', icon: 'fas fa-bars', type: 'primary', onClick: () => stateService.go('equipments', {}) }
                ],
                editableEquipmentButtons: [
                    { id: 'list', icon: 'fas fa-bars', type: 'primary', onClick: () => stateService.go('equipments', {}) },
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', onClick: () => stateService.go('equipmentedit', { equipment_id: vm.model.time.equipment_id }) }
                ],
                operationButtons: [
                    { id: 'list', icon: 'fas fa-bars', type: 'primary', onClick: () => stateService.go('operations', {}) }
                ],
                editableOperationButtons: [
                    { id: 'list', icon: 'fas fa-bars', type: 'primary', onClick: () => stateService.go('operations', {}) },
                    { id: 'goto', icon: 'fas fa-chevron-right', type: 'primary', onClick: () => stateService.go('operation', { operation_id: vm.model.time.operation_id }) }
                ],
                offDutyCodesButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', onClick: () => vm.model.time.offdutycode_keyno = '0' },
                ],
                time: {},
                selectListLabours: [],
                selectListProjects: [],
                selectListProjectProcesses: [],
                selectListDepartments: [],
                selectListEquipments: [],
                selectListOperations: [],
                selectListPercentages: [],
                selectListSalaryTypes: [],
                selectListShifts: [],
                selectListOffDutyCodes: [],
                selectListCustacts: [],
                selectListActivitityTypes: [],
                selectListActivityStatuses: [],
                itemsListExtraPowTimes: [],
                commonDTVarStart: { minuteInterval: '' },
                commonDTVarEnd: { minuteInterval: '' },
                searchingCustName: false,
                searchingProdName: false,
                validApprove: false,
                lockedApprove: false,
                lockedDelete: false,
                readonlyBreakMinutes: false // BJS 20240307
            };

            vm.showingDateDateOptions = {
                date: {
                    show: true, disabled: false, align: 'right'
                },
                hour: {
                    show: true, disabled: false, align: 'right'
                },
                minute: {
                    show: true, disabled: false, align: 'right', interval: 1
                }
            }

            vm.dateOptions = {
                date: {
                    show: false, disabled: false, align: 'right'
                },
                hour: {
                    show: true, disabled: false, align: 'right'
                },
                minute: {
                    show: true, disabled: false, align: 'right', interval: 1
                }
            }

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // ## IF CONDITIONS FUNCTIONS ##

            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.time[parmWithValue]) === true) return false;
                if (vm.model.time[parmWithValue] === null) return false;
                if (vm.model.time[parmWithValue].trim().length < 1) return false;

                return true;
            };

            // ## LOAD PROCEDURE CALLS ##

            timeRegisterService.loadTimeRegisterGet(vm.model.custactNo, vm.model.powTimeKeyno).then(function (data) {
                vm.model.time = angular.copy(data[0]);

                //vm.model.commonDTVarStart.minuteInterval = vm.model.time.timeregister_minutes_precision;
                //vm.model.commonDTVarEnd.minuteInterval = vm.model.time.timeregister_minutes_precision;
                vm.showingDateDateOptions.minute.interval = +vm.model.time.timeregister_minutes_precision;
                vm.dateOptions.minute.interval = +vm.model.time.timeregister_minutes_precision;

                vm.showingDateDateOptions = { ...vm.showingDateDateOptions };
                vm.dateOptions = { ...vm.dateOptions };

                // BJS 20240307
                vm.readonlyBreakMinutes = data[0].allow_override_break_minutes !== '1';

                loadEquipments();
                loadTimeRegisterLabours();
                loadProjects();
                loadProjectProcesses();
                loadDepartment();
                loadCustacts();
                loadActivityStatuses();
                loadPrices();
                loadExtraPowTimes();
            });

            timeRegisterService.loadOperationList(vm.model.custactNo).then(function (data) {
                angular.copy(data, vm.model.selectListOperations);
            });

            timeRegisterService.loadPstSelectList(vm.model.custactNo).then(function (data) {
                angular.copy(data, vm.model.selectListPercentages);
            });

            timeRegisterService.loadSalaryTypeList().then(function (data) {
                angular.copy(data, vm.model.selectListSalaryTypes);
            });

            timeRegisterService.loadShiftList('1').then(function (data) {
                angular.copy(data, vm.model.selectListShifts);
            });

            trNewJobConfService.listSelectOffDutyCodes().then(function (data) {
                angular.copy(data, vm.model.selectListOffDutyCodes);
            });

            economyService.listActivitityTypes({ only_timeentry: '1' }).then(function (result) {
                angular.copy(result, vm.model.selectListActivitityTypes);
            });

            let loadActivityStatuses = function () {
                if (vm.model.time.activitytype_no) {
                    return economyService.listActivityStatuses({ activitytype_no: vm.model.time.activitytype_no }).then((result) => angular.copy(result, vm.model.selectListActivityStatuses));
                }
            };

            let loadEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.time.labour_no) !== true) return;

                return logisticService.listEquipments({
                    custact_no: vm.model.custactNo,
                    labour_no: vm.model.time.labour_no
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListEquipments);
                });
            };

            let loadTimeRegisterLabours = function () {
                if (validateParmsWithValue('businessco_no') !== true) return;

                vm.model.selectListLabours = [];

                return timeRegisterService.loadTimeRegisterLabourList(vm.model.time.businessco_no).then(function (data) {
                    angular.copy(data, vm.model.selectListLabours);
                });
            };

            let loadProjects = function () {
                if (angular.isUndefined(vm.model.time.cust_no) === true) return;
                if (vm.model.time.cust_no === null) return;
                if (vm.model.time.cust_no.trim().length < 1) return;

                vm.model.selectListProjects = [];

                return timeRegisterService.loadProjectList('ACTIVE', 'cust_no', vm.model.time.cust_no).then(function (data) {
                    angular.copy(data, vm.model.selectListProjects);
                });
            };

            let loadProjectProcesses = function () {
                if (utilityService.validateParmsValue(vm.model.time.project_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.custact_no, true) !== true) return;

                //vm.model.selectListProjectProcesses = [];

                return timeRegisterService.loadProjectProcessList(vm.model.time.project_keyno, vm.model.time.custact_no, '1').then(function (data) {
                    angular.copy(data, vm.model.selectListProjectProcesses);
                });
            };

            let loadDepartment = function () {
                if (utilityService.validateParmsValue(vm.model.time.businessco_no) !== true) return;

                vm.model.selectListDepartments = [];

                return logisticService.listDepartment({ businessco_no: vm.model.time.businessco_no }).then(function (data) {
                    angular.copy(data, vm.model.selectListDepartments);
                });
            };

            let loadExtraPowTimes = function () {
                if (utilityService.validateParmsValue(vm.model.time.powtime_keyno) !== true) return;

                return timeRegisterService.listExtraPowTimes({ powtime_keyno: vm.model.time.powtime_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListExtraPowTimes);
                });
            };

            var loadBreakMinutes = function () {
                if (vm.model.time.timestamp_start?.trim() && vm.model.time.timestamp_end?.trim()) {
                    return timeRegisterService.loadBreakMinuteGet(vm.model.time).then(function (data) {
                        vm.model.time.break_minutes = data[0].breakminutes;
                        vm.model.time.salaryhours = data[0].salaryhours;

                        recalcQuantityToInvoice();
                        recalcAmount();
                    });
                }
            };

            let retrieveSalaryHoursToTimeAccount = function (id) {
                if (utilityService.validateParmsValue(vm.model.time.timestamp_start) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.timestamp_end) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.shift_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.salarytype_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.overtime_to_timeaccount, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.offdutycode_keyno, true) !== true) return;

                let mergeObj = vm.model.time;
                mergeObj.colname_changed = id;
                
                timeRegisterService.getSalaryHoursToTimeAccount(mergeObj).then(function (response) {
                    vm.model.time.salaryhours_to_timeaccount = response[0].salaryhours_to_timeaccount;
                });
            };

            vm.onBreakMinutesChanged = function (value) {
                if (vm.model.time.break_minutes === value) return;

                vm.model.time.break_minutes = value

                vm.model.time.break_minutes_modified = 1;
                
                loadBreakMinutes(value);
            };

            var loadPrices = function () {
                if (angular.isUndefined(vm.model.time.cust_no) === true) return;
                if (vm.model.time.cust_no === null) return;
                if (vm.model.time.cust_no.trim().length < 1) return;

                if (angular.isUndefined(vm.model.time.custact_no) === true) return;
                if (vm.model.time.custact_no === null) return;
                if (vm.model.time.custact_no.trim().length < 1) return;

                if (angular.isUndefined(vm.model.time.project_keyno) === true) return;
                if (vm.model.time.project_keyno === null) return;
                if (vm.model.time.project_keyno.trim().length < 1) return;

                if (angular.isUndefined(vm.model.time.projectprosess_keyno) === true) return;
                if (vm.model.time.projectprosess_keyno === null) return;
                if (vm.model.time.projectprosess_keyno.trim().length < 1) return;

                if (angular.isUndefined(vm.model.time.equipment_id) === true) return;
                if (vm.model.time.equipment_id === null) return;
                if (vm.model.time.equipment_id.trim().length < 1) return;

                if (angular.isUndefined(vm.model.time.prod_id) === true) return;
                if (vm.model.time.prod_id === null) return;
                if (vm.model.time.prod_id.trim().length < 1) return;

                timeRegisterService.loadPricesGet(vm.model.time.cust_no, vm.model.time.custact_no, vm.model.time.project_keyno, vm.model.time.projectprosess_keyno, vm.model.time.equipment_id, vm.model.time.prod_id).then(function (data) {
                    vm.model.time.price = data[0].price;
                    recalcAmount();
                });
            };

            var loadEquipmentProducts = function () {
                if (angular.isUndefined(vm.model.time.equipment_id) === true) return;
                if (vm.model.time.equipment_id === null) return;
                if (vm.model.time.equipment_id.trim().length < 1) return;

                return timeRegisterService.loadEquipmentProductGet(vm.model.time.equipment_id).then(function (data) {
                    vm.model.time.prod_id = data[0].prod_id;
                    vm.model.time.prod_name = data[0].prod_name;
                    loadPrices();
                });
            };

            var loadCustacts = function () {
                if (utilityService.validateParmsValue(vm.model.time.cust_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.project_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.time.projectprosess_keyno, true) !== true) return;
                if (vm.model.time.custact_no > 0) return;

                //if (vm.model.time.project_keyno > 0 || vm.model.time.projectprosess_keyno > 0) {
                return timeRegisterService.listCustacts({
                    cust_no: vm.model.time.cust_no,
                    project_keyno: vm.model.time.project_keyno,
                    projectprosess_keyno: vm.model.time.projectprosess_keyno
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListCustacts);
                });
                //}
            };

            // #region VARIABLES & DEFINITIONS

            var timerProjectProcessKeyno = null;

            // #endregion VARIABLES & DEFINITIONS

            var loadCustact = function () {
                if (angular.isDefined(timerProjectProcessKeyno)) {
                    $timeout.cancel(timerProjectProcessKeyno);
                }

                if (utilityService.validateParmsValue(vm.model.time.custact_no) !== true) return;

                if (vm.model.time.custact_no > 0) {
                    timeRegisterService.changeCustact({ custact_no: vm.model.time.custact_no }).then(function (response) {
                        vm.model.time.cust_no = response[0].cust_no;
                        vm.model.time.cust_name = response[0].cust_name;
                        vm.model.time.project_keyno = response[0].project_keyno;
                        vm.model.time.po_keyno = response[0].po_keyno;
                        vm.model.time.actstatus_keyno = response[0].actstatus_keyno;
                        vm.model.time.activitytype_no = response[0].activitytype_no;

                        //console.log('Set activitytype_no: ' + vm.model.time.activitytype_no);

                        timerProjectProcessKeyno = $timeout(function () {
                            vm.model.time.projectprosess_keyno = response[0].projectprosess_keyno;
                        }, 500);

                        vm.model.time.custact_note = response[0].custact_note;
                        var temp_custact_no = vm.model.time.custact_no;

                        vm.model.time.custact_no = 0;
                        loadCustacts();
                        vm.model.time.custact_no = temp_custact_no;

                        if (vm.model.time.description === '') {
                            vm.model.time.description = response[0].powtime_description;
                        }
                    });
                }
            };

            vm.onCustactSearchSelected = async function () {
                if (vm.model.time.custact_no > 0) {
                    let response = await timeRegisterService.changeCustact({ custact_no: vm.model.time.custact_no });
                    vm.model.time.cust_no = response[0].cust_no;
                    vm.model.time.cust_name = response[0].cust_name;

                    vm.model.time.project_keyno = response[0].project_keyno;
                    await loadProjects();
                    await vm.onProjectChanged(vm.model.time.project_keyno);

                    vm.model.time.projectprosess_keyno = response[0].projectprosess_keyno;
                    await loadProjectProcesses();
                    await vm.onProjectProcessChanged(vm.model.time.projectprosess_keyno);

                    //await loadCustacts();
                    await loadCustacts();
                    vm.model.time.custact_no = response[0].custact_no;
                    vm.model.time.custact_note = response[0].custact_note;

                    vm.model.time.po_keyno = response[0].po_keyno;

                    //vm.model.time.custact_no = 0;

                    vm.model.time.activitytype_no = response[0].activitytype_no;
                    await loadActivityStatuses();
                    vm.model.time.actstatus_keyno = response[0].actstatus_keyno;

                    if (vm.model.time.description === '') {
                        vm.model.time.description = response[0].powtime_description;
                    }
                }
            }

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'salarytype_id':
                    case 'shift_id':
                    case 'overtime_to_timeaccount':
                        if (utilityService.validateWatchValue(value, vm.model.time[id]) !== true) return;

                        vm.model.time[id] = value;

                        if (utilityService.validateParmsValue(vm.model.time[id], true) !== true) return;

                        loadBreakMinutes();
                        retrieveSalaryHoursToTimeAccount(id);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // ## REMEMBER VALUE FUNCTIONS ##

            var canRun616 = function (variableName, parm) {
                //if (initLoadDone !== true) return false;
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.time[parm]) === true) return false;
                if (vm.model.time[parm] === null) return false;

                return true;
            };

            var rememberPortalUserKeyno = function () {
                var variableNamePortalUserKeyno = 'w_timesheetdiary.portal_user_keyno';

                if (canRun616(variableNamePortalUserKeyno, 'portal_user_keyno') !== true) return;

                timeRegisterService.remember(variableNamePortalUserKeyno, vm.model.time.portal_user_keyno);
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearchCustact = function (value) {
                return typeaheadService.lookUpCustact({
                    searchtext: value || '',
                    cust_no: vm.model.time.cust_no,
                    project_keyno: vm.model.time.project_keyno,
                    projectprosess_keyno: vm.model.time.projectprosess_keyno,
                    labour_no: vm.model.time.labour_no
                });
            };

            vm.setCustactNo = function (item) {
                vm.model.time.custact_no = angular.isUndefined(item) ? '0' : item.item_id;
                vm.model.time.custact_note = angular.isUndefined(item) ? '' : item.item_name;
                //loadCustact();
                vm.onCustactSearchSelected();
            };

            vm.searchCustomerName = function (value) {
                if (angular.isDefined(value) && value !== '') {
                    return timeRegisterService.searchCustName(value, '', '', 'C', 'SEARCH');
                }
            }

            vm.selectCustomerName = function (item) {
                vm.model.time.cust_no = item?.item_id ?? 0;
                vm.model.time.cust_name = item?.item_name ?? '';
                vm.model.time.project_keyno = '0';
                vm.model.time.projectprosess_keyno = '0';
                loadProjects();
                loadPrices();
            }

            vm.clearCustomerName = function () {
                vm.model.time.cust_no = '0';
                vm.model.time.cust_name = '';
                vm.model.time.project_keyno = '0';
                vm.model.selectListProjects = [];
                vm.model.time.projectprosess_keyno = '0';
                vm.model.selectListProjectProcesses = [];
                vm.model.time.custact_no = '0';
                vm.model.time.custact_note = '';
                loadProjects();
                loadPrices();
            }

            // #region TOGGLE LIST SINGLE FUNCTION

            //vm.toggleChosenExtra = function (item) {
            //    item = angular.isDefined(item.item) ? item.item : item;

            //    if (item.item_id === '0' || item.item_id === '-1') return;

            //    if (item.item_is_selected === '0') {
            //        item.item_is_selected = '1';
            //    } else if (item.item_is_selected === '1') {
            //        item.item_is_selected = '0';
            //    }
            //};

            // #endregion TOGGLE LIST SINGLE FUNCTION

            function resetCustactNo() {
                vm.model.time.custact_no = '0';
                loadCustacts();
            }

            vm.onTimeStampChanged = function (value, timestamp) {
                if (!value || value.length > 16 || vm.model.time[timestamp] === value) return;

                vm.model.time[timestamp] = value;

                loadBreakMinutes();
                retrieveSalaryHoursToTimeAccount(timestamp);
            }

            vm.searchProductName = function () {
                return timeRegisterService.searchProdName(vm.model.time.prod_name, vm.model.webPageName, '0', '0', '0', '0', '0', '0', 'ALL');
            }


            vm.selectProductName = function (item) {
                vm.model.time.prod_id = item.item_id;
                vm.model.time.prod_name = item.prod_name;
                loadPrices();
            }

            vm.clearProductName = function () {
                vm.model.time.prod_id = '';
                vm.model.time.prod_name = '';
                loadPrices();
            }

            // #endregion BUTTON LIST FUNCTIONS

            // ## PARSEFLOAT FUNCTION ##

            var parseNumber = function (str) {
                if (typeof str === 'string') {
                    str = str.replace(',', '.');
                    str = str.replace(' ', '');

                    if (str === '') {
                        str = '0';
                    }
                }

                var out = parseFloat(str);

                if (isNaN(out)) {
                    out = 0;
                }

                if (!isFinite(out)) {
                    out = 0;
                }

                return out;
            };

            // ## RECALCULATION FUNCTIONS ##

            var recalcQuantityToInvoice = function () {
                if (vm.model.time.timestamp_start !== vm.model.time.timestamp_end) {
                    //console.log('salaryhours: ' + vm.model.time.salaryhours + ' * pst: ' + vm.model.time.pst / 100);
                    //vm.model.time.quantity_toinvoice = parseNumber(vm.model.time.salaryhours) * parseNumber(vm.model.time.pst / 100);
                    vm.onQuantityToInvoiceChanged(parseNumber(vm.model.time.salaryhours) * parseNumber(vm.model.time.pst / 100));
                    recalcAmount();
                }
            };

            var recalcAmount = function () {
                //console.log('quantity_toinvoice: ' + vm.model.time.quantity_toinvoice + ' * price: ' + vm.model.time.price);
                //* parseNumber(vm.model.time.pst / 100)
                vm.model.time.amount = parseNumber(vm.model.time.quantity_toinvoice) * (parseNumber(vm.model.time.price) - (parseNumber(vm.model.time.price) * parseNumber(vm.model.time.discount / 100)));
                //console.log('amount = ' + vm.model.time.amount);
            };

            var recalcSumM3ForCargos = function () {
                //console.log('recalcSumM3ForCargos');
                //console.log('number_of_cargos: ' + vm.model.time.number_of_cargos + ' * m3_for_cargos: ' + vm.model.time.m3_for_cargos);
                vm.model.time.sum_m3_for_cargos = parseNumber(vm.model.time.number_of_cargos) * parseNumber(vm.model.time.m3_for_cargos);
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'timeregistersplit':
                        timeRegisterService.save(vm.model.time).then(function (data) {
                            if (data[0].ws_result === '1') {
                                go({
                                    powtime_keyno: vm.model.time.powtime_keyno
                                });
                            } else {
                                modalService.show({
                                    type: 'danger',
                                    title: 'Error',
                                    message: data[0].ws_result_message,
                                    buttons: [{
                                        label: 'OK',
                                        close: true
                                    }]
                                });
                            }
                        });
                        break;
                    case 'timeinouts':
                        go({
                            argtype: 'powtime_keyno',
                            argvalue: vm.model.powTimeKeyno
                        });
                        break;
                    case 'timesheetdiary':
                        rememberPortalUserKeyno();

                        go({
                            argtype: '-',
                            argvalue: '-'
                        });
                        break;
                    case 'powtimepaycheckls':
                        go({
                            argtype: 'powtime_keyno',
                            argvalue: vm.model.powTimeKeyno
                        });
                        break;
                    case 'projectprocesses':
                        go({
                            project_keyno: vm.model.time.project_keyno
                        });
                        break;
                    case 'workdetails':
                        go({
                            custact_no: vm.model.time.custact_no
                        });
                        break;
                    case 'invoicehead':
                        go({
                            invoice_no: vm.model.time.invoice_no
                        });
                        break;
                }
            };


            // ## SAVE BUTTON FUNCTION ##

            vm.saveTimeRegister = function () {
                let mergeObj = {};
                mergeObj = angular.copy(vm.model.time);
                mergeObj.powtimeextras = vm.model.itemsListExtraPowTimes;

                if (vm.model.validApprove === true) {
                    timeRegisterService.save(mergeObj).then(function (data) {
                        loadApprove();
                    });
                } else {
                    vm.model.time.locked = '1';

                    timeRegisterService.save(mergeObj).then(function (data) {
                        if (data[0].ws_result === '1') {
                            modalService.show({
                                type: 'success',
                                title: 'Confirmation',
                                message: data[0].ws_result_message,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.time.locked = '0';
                                        stateService.back();
                                    }
                                }]
                            });
                        } else {
                            modalService.show({
                                type: 'danger',
                                title: 'Error',
                                message: data[0].ws_result_message,
                                buttons: [{
                                    label: 'OK',
                                    close: true
                                }]
                            });

                            vm.model.time.locked = '0';
                        }
                    });
                }
            };

            // ## APPROVE BUTTON FUNCTION ##

            var loadApprove = function () {
                timeRegisterService.approve(vm.model.time.powtime_keyno).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedApprove = false;
                    }
                });
            };

            vm.approveTimeRegister = function () {
                vm.model.lockedApprove = true;
                vm.model.validApprove = true;
                //console.log('validApprove --- approveTimeRegister = ' + vm.model.validApprove);

                vm.saveTimeRegister(true);
            };

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    timeRegisterService.deleteItem({ powtime_keyno: vm.model.time.powtime_keyno }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.onProjectChanged = async function (value) {
                vm.model.time.project_keyno = value;

                vm.model.selectListProjects

                if (value) {
                    vm.model.time.projectprosess_keyno = '0';

                    return Promise.all([
                        loadCustacts(),
                        loadProjectProcesses(),
                    ]);
                }
                loadPrices();
            }

            vm.onProjectProcessChanged = async function (value) {
                vm.model.time.projectprosess_keyno = value;

                if (value) {
                    await loadPrices();
                    await loadCustacts();
                }
            }

            vm.onEquipmentIdChanged = function (value) {
                vm.model.time.equipment_id = value;

                if (value) {
                    loadPrices();
                    loadEquipmentProducts();
                }
            }

            vm.onInvoicePercentageChanged = function (value) {
                vm.model.time.pst = value;

                console.log('change percentage');

                if (value) {
                    console.log('change stuff with percentage');
                    recalcQuantityToInvoice();
                    recalcAmount();
                }
            }

            vm.onDiscountChanged = function (value) {
                vm.model.time.discount = value;

                if (value) {
                    recalcQuantityToInvoice();
                    recalcAmount();
                }
            }

            vm.onQuantityToInvoiceChanged = function (value) {
                vm.model.time.quantity_toinvoice = value;

                if (value) recalcAmount();

            }

            vm.onPriceChanged = function (value) {
                vm.model.time.price = value;

                if (value) recalcAmount();
            }

            vm.onNumberOfCargosChanged = function (value) {
                vm.model.time.number_of_cargos = value;

                if (value) {
                    recalcSumM3ForCargos();
                }
            }

            vm.onM3ForCargosChanged = function (value) {
                vm.model.time.m3_for_cargos = value;

                if (value) {
                    recalcSumM3ForCargos();
                }
            }

            vm.onCustomerActivityChanged = function (value) {
                vm.model.time.custact_no = value;
                loadCustact();
            }

            vm.onActivityTypeChanged = function (value) {
                vm.model.time.activitytype_no = value;
                loadActivityStatuses();
            }

            vm.onOffDutyCodeChanged = function (value) {
                vm.model.time.offdutycode_keyno = value;

                let offDutyCode = vm.model.selectListOffDutyCodes.find((code) => code.item_id === vm.model.time.offdutycode_keyno);

                if (offDutyCode) {
                    vm.model.time.salarytype_id = offDutyCode.salarytype_id_pay;
                    vm.model.time.offdutycode_specification_required = offDutyCode.note_required;
                }

                loadBreakMinutes();
                retrieveSalaryHoursToTimeAccount('offdutycode_keyno');
            }

            vm.$onInit = function () {

            }

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            vm.time_register_equipment = {
                item_id: '',
                item_name: '',
                records: []
            };

            var parms_custact_prosess_list = {
                method: 29,
                parameters: {
                    custact_no: $stateParams.custact_no || 0
                }
            };

            //step 2
            $ihttp.post(parms_custact_prosess_list).then(function (data) {
                angular.copy(data, vm.time_register_process.records);
            });
        }]
    });
})();
