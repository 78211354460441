(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('furnitureFabricsColors', {
        templateUrl: 'views/components/views/furnitureFabricsColors/furnitureFabricsColors.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'furnitureFabricsColorsService', function ($stateParams, stateService, utilityService, furnitureFabricsColorsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                furnitureFabricsId: $stateParams.furniturefabrics_id,
                setting: {},
                itemsListColours: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            furnitureFabricsColorsService.getFurnitureFabricsColors({ furniturefabrics_id: vm.model.furnitureFabricsId }).then(function (data) {
                angular.copy(data[0], vm.model.setting);

                loadFurnitureFabricsColors();
            });

            var loadFurnitureFabricsColors = function () {
                if (utilityService.validateParmsValue(vm.model.setting.furniturefabrics_id) !== true) return;

                vm.model.itemsListColours = [];

                furnitureFabricsColorsService.listFurnitureFabricsColors({ furniturefabrics_id: vm.model.setting.furniturefabrics_id }).then(function (data) {
                    angular.copy(data, vm.model.itemsListColours);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'furniturefabricscolor':
                        go({
                            furniturefabrics_id: vm.model.setting.furniturefabrics_id,
                            furniturefabricscolor_id: '+'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
        }]
    });
})();
