(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('printTestReports', {
        templateUrl: 'views/components/views/printTestReports/printTestReports.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['printService', function(printService) {
    
            var vm = this;
    
    
            vm.model = {
                reports: [],
                page: 1,
                pageSize: 10,
                total: 0,
                pageSizes: [5, 10, 15, 25, 50]
            }
    
            var load = function () {
                printService.getReports({ page: vm.model.page, pageSize: vm.model.pageSize }).then(function (data) {
                    vm.model.total = data.total;
    
                    for (var i = 0; i < data.items.length; i++) {
                        data.items[i].modDateTime = moment(data.items[i].modDateTime).format('YYYY-MM-DD HH:mm');
                        data.items[i].regDateTime = moment(data.items[i].regDateTime).format('YYYY-MM-DD HH:mm');
                    }
    
                    angular.copy(data.items, vm.model.reports);
                });
            };
    
            load();
    
            vm.pageChanged = function () {
                load();
            };
    
            vm.setPageSize = function (size) {
                vm.model.pageSize = size;
    
                load();
            };
        }]
    });
})();
