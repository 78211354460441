(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('requisitionCreateFrom', {
        templateUrl: 'views/components/views/requisitionCreateFrom/requisitionCreateFrom.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'requisitionCreateFromService', function ($stateParams, requisitionCreateFromService) {
            var vm = this;
            var stockKeyno = $stateParams.stock_keyno;
            
            vm.model = {
                itemsListStocks: []
            };
            
            requisitionCreateFromService.listStocks({ stock_keyno_to: stockKeyno }).then(function (result) {
                angular.copy(result, vm.model.itemsListStocks);
            });
        }]
    });
})();