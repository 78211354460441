(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryMethodServices', {
        templateUrl: 'views/components/views/deliveryMethodServices/deliveryMethodServices.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'deliveryMethodServicesService', function ($stateParams, $q, deliveryMethodServicesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let deliveryMethodNo = $stateParams.deliverymethod_no;
            
            vm.model = {
                setting: {},
                itemsListServices: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                deliveryMethodServicesService.getDeliveryMethodServices({ deliverymethod_no: deliveryMethodNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadDeliveryMethodServices()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadDeliveryMethodServices = function () {
                vm.model.itemsListServices = [];

                return deliveryMethodServicesService.listDeliveryMethodServices(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListServices);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
