(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customersMenu', {
        templateUrl: 'views/components/views/customersMenu/customersMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$window', 'stateService', 'utilityService', 'customersMenuService', 'rememberService', function ($stateParams, $q, $window, stateService, utilityService, customersMenuService, rememberService) {
            const vm = this;
            let custNo = $stateParams.cust_no;

            let variableNames = {
                bg_tab1: ''
            };

            vm.model = {
                mobileSize: window.matchMedia('(min-width: 992px)'),
                bgTab1: {
                    selected: 'EDIT',
                    buttons1: [
                        { id: 'EDIT', icon: 'far fa-edit', label: 'edit_customer', color: 'primary', onClick: () => setTab1View('EDIT') },
                        { id: 'CONTACTS', icon: 'far fa-users', label: 'customer_persons', color: 'primary', onClick: () => setTab1View('CONTACTS') },
                        { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', color: 'primary', onClick: () => setTab1View('EMAIL') },
                        { id: 'PROJECTS', icon: 'far fa-tasks-alt', label: 'projects', color: 'primary', onClick: () => setTab1View('PROJECTS') },
                        { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'activities', color: 'primary', onClick: () => setTab1View('CUSTACTS') },
                        { id: 'ORDERS', icon: 'far fa-box', label: 'quotation_and_orders', color: 'primary', onClick: () => setTab1View('ORDERS') },
                        { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary', onClick: () => setTab1View('DOCUMENTS') },
                        { id: 'POWTIMES', icon: 'far fa-clock', label: 'customer_powtimes', color: 'primary', onClick: () => setTab1View('POWTIMES') },
                        { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary', onClick: () => setTab1View('FUNCTIONS') }
                    ],
                    buttons2: [
                        { id: 'EDIT', icon: 'far fa-edit', label: 'edit_customer', color: 'primary', onClick: () => setTab1View('EDIT') },
                        { id: 'CONTACTS', icon: 'far fa-users', label: 'customer_persons', color: 'primary', onClick: () => setTab1View('CONTACTS') },
                        { id: 'EMAIL', icon: 'far fa-envelope', label: 'customersmenu_email', color: 'primary', onClick: () => setTab1View('EMAIL') },
                        { id: 'PROJECTS', icon: 'far fa-tasks-alt', label: 'projects', color: 'primary', onClick: () => setTab1View('PROJECTS') },
                        { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'activities', color: 'primary', onClick: () => setTab1View('CUSTACTS') },
                        { id: 'ORDERS', icon: 'far fa-box', label: 'quotation_and_orders', color: 'primary', onClick: () => setTab1View('ORDERS') },
                        { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'documents', color: 'primary', onClick: () => setTab1View('DOCUMENTS') },
                        { id: 'POWTIMES', icon: 'far fa-clock', label: 'customer_powtimes', color: 'primary', onClick: () => setTab1View('POWTIMES') },
                        { id: 'DISCGRPPROD', icon: 'far fa-ellipsis-h', label: 'customer_discgrpprod', color: 'primary', onClick: () => setTab1View('DISCGRPPROD') },
                        { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'bg_main_functions', color: 'primary', onClick: () => setTab1View('FUNCTIONS') }
                    ]
                },
                customerWorldWideWebButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-window-restore', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.menu.weblink_custweb) }
                ],
                menu: {},
                gridPersonReady: false,
                gridEmailReady: false,
                gridProjectsReady: false,
                gridActivityReady: false,
                gridOrdersReady: false,
                gridDocumentsReady: false,
                gridPowTimesReady: false,
                gridDiscGroupProductReady: false
            };

            vm.gridPerson = {
                dataTask: {
                    rememberId: 'w_customersmenu_person_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'create_new_person', text: 'create_new_person', func: function () { stateService.go('person', { cust_no: vm.model.menu.cust_no, pers_no: '0' }); }, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridEmail = {
                dataTask: {
                    rememberId: 'customersmenu_email_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'customersmenu_email_new',
                                text: 'customersmenu_email_new',
                                func: function () {
                                    stateService.go('emailcreate', {
                                        webpage: 'customersmenu',
                                        argtype: 'cust_no',
                                        argvalue: vm.model.menu.cust_no
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true
                            }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridProjects = {
                dataTask: {
                    rememberId: 'w_projects_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'projects_new', text: 'projects_new', func: function () { stateService.go('project', { project_keyno: '0' }); }, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridActivity = {
                dataTask: {
                    rememberId: 'w_activitygrid.grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'new_activity', text: 'new_activity', func: function () { stateService.go('custactedit', { custact_no: '0', filterfield: 'cust_no', filtervalue: vm.model.menu.cust_no }); }, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridOrders = {
                dataTask: {
                    rememberId: 'w_orderheads_orderline_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'create_new_orderhead', text: 'create_new_orderhead', func: function () { stateService.go('orderheadcreate', { argtype: 'cust_no', argvalue: vm.model.menu.cust_no, ordertype_id: '0' }); }, icon: 'fa-plus', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridDocuments = {
                dataTask: {
                    rememberId: 'documents_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'customersmenu_documents_new',
                                text: 'customersmenu_documents_new',
                                func: function () {
                                    stateService.go('documents', {
                                        argtype: 'cust_no',
                                        argvalue: vm.model.menu.cust_no
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.menu.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridPowTimes = {
                dataTask: {
                    rememberId: 'customer_powtimes_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        rebind: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridDiscGroupProducts = {
                dataTask: {
                    rememberId: 'customersmenu_discgrpprod_rates_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setTab1View = function (id) {
                vm.model.bgTab1.selected = vm.model.menu.bg_tab1 = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            let loadMenu = function () {
                let deferred = $q.defer();

                customersMenuService.getCustomersMenu({ cust_no: custNo }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);

                    //if (vm.model.menu.cust_no === '18330') {
                    //    vm.model.menu.cust_www = 'www.langlo.no';
                    //    vm.model.menu.weblink_custweb = 'https://www.langlo.no/';
                    //    console.log('cust_www: ' + vm.model.menu.cust_www);
                    //    console.log('weblink_custweb: ' + vm.model.menu.weblink_custweb);
                    //}

                    //vm.model.menu.show_button_edit_customer = '0';
                    if (vm.model.menu.show_button_edit_customer === '0') {
                        if (vm.model.menu.show_discgrpcust === '1') {
                            vm.model.bgTab1.buttons2[0].disabled = 'hidden';
                        } else {
                            vm.model.bgTab1.buttons1[0].disabled = 'hidden';
                        }

                        if (vm.model.bgTab1.selected === 'EDIT') vm.model.bgTab1.selected = 'CONTACTS';
                    }

                    stateService.setTitleOverride(vm.model.menu.cust_name);
                    setTab1View(vm.model.menu.bg_tab1);

                    $q.all([
                        loadGridPerson(),
                        loadGridEmail(),
                        loadGridProjects(),
                        loadGridActivity(),
                        loadGridOrders(),
                        loadGridDocuments(),
                        loadGridPowTimes(),
                        loadGridDiscGroupProducts()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridPerson = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'CONTACTS') {
                        vm.gridPerson.dataTask.loadData = {
                            method: 2968,
                            parameters: { cust_no: vm.model.menu.cust_no }
                        };

                        vm.model.gridPersonReady = true;
                    }
                }
            };

            let loadGridEmail = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'EMAIL') {
                        vm.gridEmail.dataTask.loadData = {
                            method: 2971,
                            parameters: { cust_no: vm.model.menu.cust_no }
                        };

                        vm.model.gridEmailReady = true;
                    }
                }
            };

            let loadGridProjects = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'PROJECTS') {
                        vm.gridProjects.dataTask.loadData = {
                            method: 2748,
                            parameters: {
                                argtype: 'cust_no',
                                argvalue: vm.model.menu.cust_no,
                                searchtext: '',
                                bg_projects_activestatus: 'ACTIVE'
                            }
                        };

                        vm.model.gridProjectsReady = true;
                    }
                }
            };

            let loadGridActivity = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.menu.businessco_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'CUSTACTS') {
                        vm.gridActivity.dataTask.loadData = {
                            method: 2452,
                            parameters: {
                                cust_no: vm.model.menu.cust_no,
                                cust_name: '',
                                ok: 'A',
                                argtype: '-',
                                argvalue: '-',
                                p2_datatask_keyno: '2452',
                                view_id: 'w_activitygrid.grid',
                                businessco_no: vm.model.menu.businessco_no
                            }
                        };

                        vm.model.gridActivityReady = true;
                    }
                }
            };

            let loadGridOrders = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.menu.show_button_orderheadcreate == 1) {
                    if (vm.gridOrders.config.toolbar.buttons.some((button) => button.name === 'create_new_orderhead') === false) {
                        vm.gridOrders.config.toolbar.buttons.push({ name: 'create_new_orderhead', text: 'create_new_orderhead', func: function () { stateService.go('orderheadcreate', { argtype: 'cust_no', argvalue: vm.model.menu.cust_no, ordertype_id: '0' }); }, icon: 'fa-plus', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true });
                    }
                }

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'ORDERS') {
                        vm.gridOrders.dataTask.loadData = {
                            method: 2852,
                            parameters: {
                                cust_no: vm.model.menu.cust_no,
                                argtype: 'cust_no',
                                argvalue: vm.model.menu.cust_no
                            }
                        };

                        vm.model.gridOrdersReady = true;
                    }
                }
            };

            let loadGridDocuments = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'DOCUMENTS') {
                        vm.gridDocuments.dataTask.loadData = {
                            method: 2972,
                            parameters: {
                                argtype: 'cust_no',
                                argvalue: vm.model.menu.cust_no,
                                searchtext: '',
                                doctype: 'd',
                                onlyFilenames: '0'
                            }
                        };

                        vm.model.gridDocumentsReady = true;
                    }
                }
            };

            let loadGridPowTimes = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    vm.gridPowTimes.dataTask.loadData = {
                        method: 2127,
                        parameters: {
                            businessco_no: '99999',
                            date_fom: '1900-01-01',
                            date_tom: '2200-12-31',
                            cust_no: vm.model.menu.cust_no
                        }
                    };

                    vm.model.gridPowTimesReady = true;
                }
            };

            let loadGridDiscGroupProducts = function () {
                if (utilityService.validateParmsValue(vm.model.menu.cust_no, true) !== true) return;

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'DISCGRPPROD') {
                        vm.gridDiscGroupProducts.dataTask.loadData = {
                            method: 3125,
                            parameters: { cust_no: vm.model.menu.cust_no }
                        };

                        vm.model.gridDiscGroupProductReady = true;
                    }
                }

                if (vm.gridDiscGroupProducts.gridfunc !== null) vm.gridDiscGroupProducts.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bg_tab1':
                        if (utilityService.validateParmsValue(vm.model.menu[id], true) !== true) return;

                        variableValue = vm.model.menu[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.selectBgTab1 = function (value) {
                vm.model.bgTab1.selected = value;
                vm.model.menu.bg_tab1 = vm.model.bgTab1.selected;

                rememberFunc('bg_tab1');

                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'CONTACTS') {
                        loadGridPerson();
                    } else if (vm.model.bgTab1.selected === 'EMAIL') {
                        loadGridEmail();
                    } else if (vm.model.bgTab1.selected === 'PROJECTS') {
                        loadGridProjects();
                    } else if (vm.model.bgTab1.selected === 'CUSTACTS') {
                        loadGridActivity();
                    } else if (vm.model.bgTab1.selected === 'ORDERS') {
                        loadGridOrders();
                    } else if (vm.model.bgTab1.selected === 'DOCUMENTS') {
                        loadGridDocuments();
                    } else if (vm.model.bgTab1.selected === 'POWTIMES') {
                        loadGridPowTimes();
                    } else if (vm.model.bgTab1.selected === 'DISCGRPPROD') {
                        loadGridDiscGroupProducts();
                    }
                }
            };

            vm.openWebLink = function (id) {
                switch (id) {
                    case 'maplink':
                    case 'weblink_economics':
                        window.open(vm.model.menu[id]);
                        break;
                    default:
                        break;
                };
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'customerpersons':
                    case 'customeredit':
                    case 'special_cust_prod_calc':
                    case 'customer_discgrpprod':
                    case 'customerclaims':
                    case 'customerbonus':
                    case 'custfacts':
                    case 'freightratesets':
                    case 'deliverycustomers':
                        go({ cust_no: vm.model.menu.cust_no });
                        break;
                    case 'acsupptranses':
                        go({ acc_supp_no: vm.model.menu.acc_supp_no });
                        break;
                    case 'orderheads':
                    case 'invoiceheads':
                    case 'projects':
                    case 'custactplanning':
                    case 'documents':
                    case 'pictures':
                    case 'mapview':
                    case 'serviceobjects':
                    case 'checklistdatas':
                    case 'edisetup':
                        go({
                            argtype: 'cust_no',
                            argvalue: vm.model.menu.cust_no
                        });
                        break;
                    case 'accusttranses':
                        go({
                            argtype: 'acc_cust_no',
                            argvalue: vm.model.menu.acc_cust_no
                        });
                        break;
                    case 'pricelistprice':
                    case 'pricelistfactors':
                    case 'purchheads':
                        go({
                            argtype: 'supplier_no',
                            argvalue: vm.model.menu.cust_no
                        });
                        break;
                    case 'custactSearchActivity':
                        if (e?.ctrlKey) {
                            stateService.newTab('custactsearch', {
                                filterfield: 'cust_no',
                                filtervalue: vm.model.menu.cust_no,
                                custacttype: 'A'
                            });
                        } else {
                            stateService.go('custactsearch', {
                                filterfield: 'cust_no',
                                filtervalue: vm.model.menu.cust_no,
                                custacttype: 'A'
                            });
                        }
                        break;
                    case 'custactSearchDeviation':
                        if (e?.ctrlKey) {
                            stateService.newTab('custactsearch', {
                                filterfield: 'cust_no',
                                filtervalue: vm.model.menu.cust_no,
                                custacttype: 'D'
                            });
                        } else {
                            stateService.go('custactsearch', {
                                filterfield: 'cust_no',
                                filtervalue: vm.model.menu.cust_no,
                                custacttype: 'D'
                            });
                        }
                        break;
                    case 'powtimegroup':
                        go({
                            cust_no: vm.model.menu.cust_no,
                            project_keyno: '0',
                            projectprosess_keyno: '0',
                            custact_no: '0',
                            date_start: '2000-01-01',
                            date_end: '2100-12-31',
                            grouptype: 'Y',
                            equipment_id: '-',
                            labour_no: '0',
                            prod_id: '-'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.deleteValues = function () {
                customersMenuService.deleteItem({
                    cust_no: vm.model.menu.cust_no
                }).then(function () {
                    stateService.back();
                });
            };

            let onWindowResize = function () {
                if (vm.model.mobileSize.matches === true) {
                    if (vm.model.bgTab1.selected === 'CONTACTS') {
                        loadGridPerson();
                    } else if (vm.model.bgTab1.selected === 'EMAIL') {
                        loadGridEmail();
                    } else if (vm.model.bgTab1.selected === 'PROJECTS') {
                        loadGridProjects();
                    } else if (vm.model.bgTab1.selected === 'CUSTACTS') {
                        loadGridActivity();
                    } else if (vm.model.bgTab1.selected === 'ORDERS') {
                        loadGridOrders();
                    } else if (vm.model.bgTab1.selected === 'DOCUMENTS') {
                        loadGridDocuments();
                    } else if (vm.model.bgTab1.selected === 'POWTIMES') {
                        loadGridPowTimes();
                    } else if (vm.model.bgTab1.selected === 'DISCGRPPROD') {
                        loadGridDiscGroupProducts();
                    }
                }
            };

            let onDestroy = [];

            vm.$onInit = function () {
                loadMenu();
                $window.addEventListener('resize', onWindowResize);
                //onWindowResize();
                //stateService.setTitleOverride('test');
            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
