(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('timeRegisterNewJobSelectOp', {
        templateUrl: 'views/components/views/trNewJobSelectOp/trNewJobSelectOp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'trNewJobSelectOpService', function ($stateParams, utilityService, trNewJobSelectOpService) {
            var vm = this;
            var labourNo = $stateParams.labour_no;
            
            vm.model = {
                bgOperationTrListSelection: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '1',
                    records: [
                        { item_id: '0', item_name: 'operation_direct', item_func: '1' },
                        { item_id: '1', item_name: 'operation_indirect', item_func: '0' }
                    ]
                },
                itemsListOperationTr: []
            };

            var loadOperationTrs = function () {
                if (utilityService.validateParmsValue(vm.model.bgOperationTrListSelection.item_selected) !== true) return;

                vm.model.itemsListOperationTr = [];

                trNewJobSelectOpService.listOperationTrs({
                    labour_no: labourNo,
                    direct: vm.model.bgOperationTrListSelection.item_selected
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListOperationTr);
                });
            };

            loadOperationTrs();

            vm.selectBgOperationTrListSelection = function (item) {
                if (utilityService.validateWatchValue(item, vm.model.bgOperationTrListSelection.item_selected) !== true) return;

                vm.model.bgOperationTrListSelection.item_selected = item;

                loadOperationTrs();
            };
        }]
    });
})();