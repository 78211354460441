(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('unit', {
        templateUrl: 'views/components/views/unit/unit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'unitService', function ($stateParams, stateService, utilityService, modalService, translateService, unitService) {
            const vm = this;
            let unitNo = $stateParams.unit_no;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {
                    base_unit_name: ''
                },
            	selectListPropertyClasses: [],
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                unitService.getEdit({ unit_no: unitNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                    findBaseUnitName();
                });
            };

            let loadPropertyClasses = function () {
                unitService.listPropertyClasses().then(function (result) {
                    angular.copy(result, vm.model.selectListPropertyClasses);
                    findBaseUnitName();
                });
            };

            let findBaseUnitName = function () {
                let baseUnitName = vm.model.selectListPropertyClasses.find((item) => item.propertyclass_id === vm.model.edit.propertyclass_id)?.base_unit_name ?? '';
                
                if (baseUnitName > '') {
                    vm.model.edit.base_unit_name = baseUnitName;
                } else {
                    vm.model.edit.base_unit_name = '';
                }
            };

            vm.saveChanges = function () {
                unitService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    unitService.deleteItem({ unit_no: vm.model.edit.unit_no }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'unitlang':
                        go({ unit_no: vm.model.edit.unit_no });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'propertyclass_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.propertyclass_id) !== true) return;

                        vm.model.edit.propertyclass_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.propertyclass_id) !== true) return;
                        
                        findBaseUnitName();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPropertyClasses();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
