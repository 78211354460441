import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentBaseComponent } from '../component-base/component-base.component';

/**
 * Represents the outer page component.
 */
@Component({
    selector: 'tt-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class PageComponent extends ComponentBaseComponent {
    @Input()
    public ttClass: string = '';
}
