<div cdkDropListGroup class="col-xs-12" style="padding: 0;">
    <div *ngFor="let group of model.sortlist; let i = index">
        <div cdkDropList
             [cdkDropListData]="group.items"
             [ngClass]="addClass()"
             (cdkDropListDropped)="drop($event, i)"
             [ngStyle]="addStyleWell(group)">

            <div class="pull-left name-label col-xs-2 im-sort-pad"
                 [ngStyle]="addStyleSortlist(group)"
                 [ngClass]="addGlyph(group)">
            </div>

            <label class="name-label col-xs-10 im-sort-pad"
                   [ngStyle]="{'font-size': this.fontSizes.textSizeS, 'color': '#ffffff'}">
                {{group.value}}
            </label>

            <div *ngFor="let item of group.items" cdkDrag
                 [cdkDragStartDelay]="this.model.mobileSize.matches ? 200 : 0"
                 [cdkDragData]="item"
                 class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12"
                 (click)="goToActivity(item)"
                 [ngStyle]="{'font-size': this.fontSizes.textSize}">
                {{item.item_name}}
                <div class="col-xs-12 im-no-pad"
                     [ngStyle]="{'font-size': this.fontSizes.textSizeS}">
                    {{item.item_name_sub1}}
                </div>
                <div class="col-xs-12 im-no-pad" style="color:#909090;"
                     [ngStyle]="{'font-size': this.fontSizes.textSizeSs}">
                    {{item.item_name_sub2}}
                </div>
            </div>
        </div>
    </div>
</div>











