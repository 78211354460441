(function() {
    'use strict';

	var module = angular.module('imApp');

	module.factory('myActivitiesService', [function () {
		var service = {};

		return service;
	}]);
})();
