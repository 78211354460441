<!-- <p>grid-functions-cell-renderer works!</p> -->
<span
    *ngIf="!params?.node?.group && ((!!params?.data && !hasItemPath && !!params?.data['item_path']) || hasItemPath || hasSpecialFuncEdit || buttons.length > 0)"
    style="
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        height: min(var(--ag-internal-calculated-line-height), var(--ag-internal-padded-row-height));
    "
>
    <button *ngIf="hasValidGotoParameters()" class="tt-button tt-button--primary im-grid-col-btn-xs pull-right" (click)="defaultGoto($event)" style="width: fit-content">
        <span class="fas fa-fw fa-chevron-right"></span>
    </button>

    <button *ngIf="params?.specialFunc?.newTab && hasValidGotoParameters()" class="tt-button tt-button--primary im-grid-col-btn-xs pull-right" (click)="openInNewTab()" style="width: fit-content">
        <span class="fas fa-fw fa-window-restore"></span>
    </button>

    <ng-container *ngFor="let button of buttons">
        <button
            [hidden]="(button.disabled$ | async) == 'hidden'"
            [disabled]="(button.disabled$ | async) == true"
            class="tt-button im-grid-col-btn-xs pull-right"
            (click)="specialFuncButtonClick($event, button)"
            [ngClass]="'tt-button--' + (button.btn_type || button.type || 'primary')"
            style="width: fit-content"
        >
            <span class="fa-fw" [ngClass]="button.icon"></span>
            <span>{{ button.text }}</span>
        </button>
    </ng-container>

    <button *ngIf="hasSpecialFuncEdit" class="tt-button tt-button--primary im-grid-col-btn-xs pull-right" (click)="editRow()" style="width: fit-content">
        <span class="fas fa-fw fa-pencil"></span>
    </button>
</span>
