(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('bcr', {
        templateUrl: 'views/components/views/bcr/bcr.template.html?v=' + module.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'bcrService', 'stateService', 'consoleService', function($stateParams, bcrService, stateService, consoleService) {
			var vm = this;

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            vm.model = {
                p2WBcrGet: {}
            };

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            vm.loadP2WBcrGet = function () {
                bcrService.loadP2WBcrGet($stateParams.barcode).then(function () {
                    vm.model.p2WBcrGet = bcrService.p2WBcrGet;
                    stateService.back();
                });
            }

            vm.loadP2WBcrGet();
		}]
	});
})();
