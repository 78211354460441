(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('salaryType', {
        templateUrl: 'views/components/views/salaryType/salaryType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'salaryTypeService', 'typeaheadService', function ($stateParams, stateService, salaryTypeService, typeaheadService) {
            const vm = this;
            
            vm.model = {
            	indata: {
            		salaryTypeId: $stateParams.salarytype_id
                },
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.dataSource.prod_keyno }) }
                ],
            	dataSource: {},
            	unitList: {
            		records: []
                },
                selectListImpactTimeAccount: [],
                selectListExtraQuantityTypes: []
            };
            
            salaryTypeService.getSalaryType($stateParams.salarytype_id).then(function () {
                vm.model.dataSource = salaryTypeService.dataSource;
            });

            salaryTypeService.loadUnitList().then(function () {
                vm.model.unitList = salaryTypeService.unitList;
            });

            salaryTypeService.listImpactTimeAccount().then(function (data) {
                angular.copy(data, vm.model.selectListImpactTimeAccount);
            });

            let loadExtraQuantityTypes = function () {
                salaryTypeService.listExtraQuantityTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListExtraQuantityTypes);
                });
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.dataSource[id] = value;

                        return typeaheadService.searchProduct({ prod_name: vm.model.dataSource[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'prod_name':
                        if (item) {
                            vm.model.dataSource.prod_id = item?.item_id ?? '';
                            vm.model.dataSource.prod_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.dataSource.prod_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.dataSource.prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.dataSource.prod_id = '';
                        vm.model.dataSource.prod_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            vm.saveSalaryType = function () {
                if (vm.model.dataSource.overtime == '0') {
                    vm.model.dataSource.extrapst = '0';
                }

                if (vm.model.dataSource.absence == '0') {
                    vm.model.dataSource.absence_paid = '0';
                    vm.model.dataSource.absence_legal = '0';
                }

                salaryTypeService.saveSalaryType(vm.model.dataSource).then(function () {
                    stateService.back();
                });
            };
            
            vm.deleteSalaryType = function () {
                salaryTypeService.deleteSalaryType(vm.model.dataSource.salarytype_id).then(function () {
                    stateService.back();
                });
            };
            
            vm.translateSalaryType = function () {
                stateService.go('salarytypelang', {
                    salarytype_id: vm.model.dataSource.salarytype_id
                });
            };
            
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'laboursalarytypes':
                        go({
                            argtype: 'salarytype_id',
                            argvalue: vm.model.dataSource.salarytype_id
                        });
                        break;
                }
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadExtraQuantityTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
