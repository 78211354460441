import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ApplicationRef, Component, ElementRef, EmbeddedViewRef, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
    selector: 'tt-thumbnail-cell-renderer',
    templateUrl: './thumbnail-cell-renderer.component.html',
    styleUrls: ['./thumbnail-cell-renderer.component.css'],
})
export class ThumbnailCellRendererComponent implements ICellRendererAngularComp {
    /**
     * Reference to the thumbnail element visible in the grid cell.
     */
    @ViewChild('thumbnail')
    public thumbnailRef?: ElementRef<HTMLImageElement>;

    /**
     * Reference to the popup templateref to display in a popup.
     */
    @ViewChild(`popup`)
    public popupRef?: TemplateRef<HTMLDivElement>;

    /**
     * The embedded view for the popup, `null` if not initialized yet.
     */
    public embeddedView: EmbeddedViewRef<HTMLDivElement> | null = null;

    /**
     * The cell parameters from the grid.
     */
    public params?: ICellRendererParams;

    /**
     * The size of the thumbnail being displayed in the cell.
     */
    public thumbnailSize: string = '30';

    /**
     * Style configuration for elements in the component.
     */
    public style: Style = {
        popup: {},
    };

    constructor(layoutService: LayoutService, private renderer: Renderer2, private appRef: ApplicationRef, private viewContainerRef: ViewContainerRef) {
        layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.thumbnailSize = info.fontSizes.thumbnail;
            }
        });
    }

    /**
     * Opens up a popup of the thumbnail which gets the mouse over event.
     */
    public onMouseOver() {
        if (!!this.thumbnailRef?.nativeElement) {
            const bottomPosition = this.thumbnailRef?.nativeElement.getBoundingClientRect().bottom.toFixed();
            const leftPosition = this.thumbnailRef?.nativeElement.getBoundingClientRect().left.toFixed();
            const translateX = (-this.thumbnailRef.nativeElement.getBoundingClientRect().x.toFixed() / window.innerWidth) * 100 + '%';

            this.style['popup'].display = 'block';
            this.style['popup'].top = bottomPosition + 'px';
            this.style['popup'].left = leftPosition + 'px';
            this.style['popup'].transform = `translate(${translateX}, ${window.scrollY}px)`;

            this.createPopup();
        }
    }

    /**
     * Closes the popup for the thumbnail which gets the mouse out event.
     */
    public onMouseOut() {
        this.style['popup'].display = 'none';
    }

    /**
     * Creates a popup for the thumbnail.
     */
    private createPopup() {
        if (!!this.popupRef && !this.embeddedView) {
            this.embeddedView = this.viewContainerRef.createEmbeddedView(this.popupRef);
            this.embeddedView.detectChanges();

            for (let node of this.embeddedView.rootNodes) {
                this.renderer.appendChild(document.body, node);
            }
        }
    }

    agInit(params: ICellRendererParams<any, any, any>): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams<any, any, any>): boolean {
        return false;
    }
}
