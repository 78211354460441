(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("sopValidService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSopValidList: function (sop_keyno) {
                return p2DataTaskService.call(1840, {
                    sop_keyno: sop_keyno
                });
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1841, mergeObj);
            }
        };

        return service;
    }]);
})();