(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('srFindReport', {
        templateUrl: 'views/components/views/srFindReport/srFindReport.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'srFindReportService', 'watcherFactory', function($timeout, srFindReportService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
    		// ## VARIABLES & DEFINITIONS ##
    
    		vm.model = {
    			searchTextBtnList: [
    				{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
    				{ item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
    			],
    			findReport: {},
    			itemsListFindReports: [],
    			searching: false
    		};
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		srFindReportService.loadSrFindReportGet().then(function (data) {
    			vm.model.findReport = angular.copy(data[0]);
    
    			loadSrFindReports();
    		});
    
    		var loadSrFindReports = function () {
    			if (vm.model.findReport.searchtext !== '') {
    				vm.model.searching = true;
    			}
    
    			if (angular.isUndefined(vm.model.findReport.searchtext) === true) return;
    			if (vm.model.findReport.searchtext === null) return;
    			if (vm.model.findReport.searchtext.trim().length < 1) return;
    
    			vm.model.itemsListFindReports = [];
    
    			srFindReportService.loadSrFindReportList(vm.model.findReport.searchtext).then(function (data) {
    				vm.model.searching = false;
    				angular.copy(data, vm.model.itemsListFindReports);
    			});
    		};
    
    		// ## REMEMBER VALUE FUNCTIONS ##
    
    		var canRun616 = function (variableName, parm) {
    			if (angular.isUndefined(variableName) === true) return false;
    			if (variableName === null) return false;
    			if (variableName === '') return false;
    			if (angular.isUndefined(vm.model.findReport[parm]) === true) return false;
    			if (vm.model.findReport[parm] === null) return false;
    
    			return true;
    		};
    
    		var timerSearchText = null;
    
    		var rememberSearchText = function () {
    			if (angular.isDefined(timerSearchText)) {
    				$timeout.cancel(timerSearchText);
    			}
    
    			var variableNameSearchText = 'w_srfindreport.searchtext';
    
    			if (canRun616(variableNameSearchText, 'searchtext') !== true) return;
    
    			timerSearchText = $timeout(function () {
    				srFindReportService.remember(variableNameSearchText, vm.model.findReport.searchtext);
    			}, 500);
    		};
    
    		// ## SEARCH PROCEDURE CALLS ##
    
    		vm.searchFindReports = function () {
    			loadSrFindReports();
    		};
    
    		// ## BUTTON LIST FUNCTIONS ##
    
    		vm.searchTextBtnListFunc = function (item_func) {
    			if (item_func === 'erase_item') {
    				vm.model.findReport.searchtext = '';
    				vm.model.itemsListFindReports = [];
    			} else if (item_func === 'search_item') {
    				vm.searchFindReports();
    			}
    		};
    
    		// ## WATCH FUNCTIONS ##
    
    		watcher.$watch(function () {
    			return vm.model.findReport.searchtext;
    		}, function (newValue, oldValue) {
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    
    			rememberSearchText();
    		});
    	}]
    });
})();