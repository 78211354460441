(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('dataMiningCreate', {
        templateUrl: 'views/components/views/dataMiningCreate/dataMiningCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'dataMiningCreateService', function ($stateParams, stateService, dataMiningCreateService) {
            const vm = this;
            let p2StatSourceKeyno = $stateParams.p2_statsource_keyno;
            let levelNo = $stateParams.level_no;
            
            vm.model = {
                edit: {},
                lockedSave: false
            };
            
            let loadEdit = function () {
                dataMiningCreateService.getEdit({
                    p2_statsource_keyno: p2StatSourceKeyno,
                    level_no: levelNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                dataMiningCreateService.createNewObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back('/datamininglist');
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
