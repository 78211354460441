(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srActivity', {
        templateUrl: 'views/components/views/srActivity/srActivity.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'modalService', 'rememberService', 'srOperationsService', 'srActivityService', 'watcherFactory', function(stateService, utilityService, modalService, rememberService, srOperationsService, srActivityService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceSearchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                settingActivity: {},
                itemsListActivities: [],
                searchingResources: srOperationsService.isSearching
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srActivityService.loadSrActivityGet().then(function (data) {
                vm.model.settingActivity = angular.copy(data[0]);
    
                loadSrActivities();
            });
    
            var loadSrActivities = function () {
                if (utilityService.validateParmsValue(vm.model.settingActivity.resource_searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingActivity.datetime_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingActivity.only_this_resource, true) !== true) return;
    
                vm.model.itemsListActivities = [];
    
                srActivityService.loadSrActivityList(vm.model.settingActivity).then(function (data) {
                    angular.copy(data, vm.model.itemsListActivities);
                });
            };
    
            // ## REMEMBER VALUE FUNCTIONS ##
    
            var rememberOnlyThisResource = function () {
                var variableNameOnlyThisResource = 'w_sractivity.only_this_resource';
    
                if (utilityService.validateParmsValue(variableNameOnlyThisResource) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingActivity.only_this_resource) !== true) return;
    
                rememberService.remember(variableNameOnlyThisResource, vm.model.settingActivity.only_this_resource);
            };
    
            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##
    
            vm.typeaheadSearch = function (value) {
                return srOperationsService.search(vm.model.settingActivity.resource_searchtext);
            };
    
            var changedResourceId = function (resource_id) {
                vm.model.settingActivity.resource_id = resource_id;
    
                loadSrActivities();
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.resourceSearchTextBtnListFunc = function (value, item_func) {
                if (item_func === 'erase_item') {
                    vm.model.settingActivity.resource_id = '';
                    vm.model.settingActivity.resource_searchtext = '';
    
                    loadSrActivities();
                } else if (angular.isDefined(value) && value !== '' && angular.isUndefined(item_func)) {
                    return vm.typeaheadSearch(value);
                } else if (item_func === 'search_item') {
                    return vm.typeaheadSearch(value);
                }
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.settingActivity.resource_searchtext; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                if (newValue === '') {
                    vm.model.settingActivity.resource_id = '';
                    vm.model.settingActivity.resource_searchtext = '';
    
                    loadSrActivities();
                } else {
                    angular.forEach(srOperationsService.typeaheadResourcesList, function (data) {
                        if (angular.isDefined(data)) {
                            if (data.item_name === vm.model.settingActivity.resource_searchtext) {
                                changedResourceId(data.item_id);
                            }
                        }
                    });
                }
            });
    
            watcher.$watch(function () { return srOperationsService.isSearching; }, function (newValue, oldValue) {
                vm.model.searchingResources = srOperationsService.isSearching;
            });
    
            watcher.$watch(function () { return vm.model.settingActivity.datetime_fom; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
                loadSrActivities();
            });
    
            watcher.$watch(function () { return vm.model.settingActivity.only_this_resource; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue, true) !== true) return;
    
                loadSrActivities();
                rememberOnlyThisResource();
            });
        }]
    });
})();