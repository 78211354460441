(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("checkListItemItemsService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1360,
                    parameters: parms || {}
                });
            },
            listCheckListItems: function (parms) {
                return $ihttp.post({
                    method: 560,
                    parameters: parms || {}
                });
            },
            reorganizeCheckListItems: function (setting) {
                return $ihttp.post({
                    method: 566,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
