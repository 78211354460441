(function () {
    'use strict';

    angular.module("imApp").factory("skillsCertificateTableService", ['$ihttp', function ($ihttp) {
        var service = {
            
        };

        return service;
    }]);
})();