(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('furnitureFabricsColor', {
        templateUrl: 'views/components/views/furnitureFabricsColor/furnitureFabricsColor.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'furnitureFabricsColorService', function ($stateParams, $q, stateService, modalService, translateService, furnitureFabricsColorService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                furnitureFabricsId: $stateParams.furniturefabrics_id,
                furnitureFabricsColorId: $stateParams.furniturefabricscolor_id,
                edit: {},
                selectListLights: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            //#region SHOW DIALOGUE BOX FUNCTION

            let showMessage = function (type, title, message, label, cssClass) {
                let deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });
                
                return deferred.promise;
            };

            //#endregion SHOW DIALOGUE BOX FUNCTION

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            furnitureFabricsColorService.getFurnitureFabricsColor({
                furniturefabrics_id: vm.model.furnitureFabricsId,
                furniturefabricscolor_id: vm.model.furnitureFabricsColorId
            }).then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            furnitureFabricsColorService.listFurnitureFabricsLights().then(function (data) {
                angular.copy(data, vm.model.selectListLights);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                furnitureFabricsColorService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        showMessage('warning', translations.error, response[0].errormessage, translations.ok, 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
        }]
    });
})();
