(function () {
    'use strict';

    angular.module("imApp").factory("textileOlAddService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadTextileOlAddGet: function (order_internal_no, orderline_keyno, textilemodel_id) {
                return p2DataTaskService.call(1519, {
                    order_internal_no: order_internal_no,
                    orderline_keyno: orderline_keyno,
                    textilemodel_id: textilemodel_id
                });
            },
            loadTextileOlAddCollectionList: function () {
                return p2DataTaskService.call(1884, {});
            },
            loadTextileOlAddTextileModelCategory7List: function (collection_keyno) {
                return p2DataTaskService.call(1885, {
                    collection_keyno: collection_keyno
                });
            },
            loadTextileOlAddTextileModelList: function (collection_keyno, textilemodelcategory7_keyno) {
                return p2DataTaskService.call(1886, {
                    collection_keyno: collection_keyno,
                    textilemodelcategory7_keyno: textilemodelcategory7_keyno
                });
            },
            nullSearch: function () {
                var deferred = $q.defer();
                var responseData = [];
                deferred.resolve(responseData);
                return deferred.promise;
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            searchTextileModel: function (order_internal_no, collection_keyno, textilemodelcategory7_keyno, ordertype_id, searchText) {
                var parms = {
                    order_internal_no: order_internal_no,
                    collection_keyno: collection_keyno,
                    textilemodelcategory7_keyno: textilemodelcategory7_keyno,
                    ordertype_id: ordertype_id,
                    search_text: searchText
                };

                return p2DataTaskService.call(1961, parms);
            },
            loadTextileOlAddList: function (textile) {
                return p2DataTaskService.call(1928, textile);
            },
            loadTextileOlAddQcList: function (textilemodel_id, textilemqcsearch) {
                return p2DataTaskService.call(1550, {
                    textilemodel_id: textilemodel_id,
                    textilemqcsearch: textilemqcsearch
                });
            },
            loadTextileOlAddGetNexts: function (mergeObj) {
                return p2DataTaskService.call(1699, mergeObj);
            },
            updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
            },
            settings: function (orderInternalNo) {
                return p2DataTaskService.call(1967, {
                    order_internal_no: orderInternalNo
                });
            },
            save: function (prod_id, quantity, order_internal_no, newprice, newdiscount, newdate) {
                return p2DataTaskService.call(1554, {
                    prod_id: prod_id,
                    quantity: quantity,
                    order_internal_no: order_internal_no,
                    price: newprice,
                    discount: newdiscount,
                    date: newdate
                });
            }
        }

        return service;
    }]);
})();
