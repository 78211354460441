//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('pohFinish', {
//        templateUrl: 'views/components/views/pohFinish/pohFinish.template.html?v=' + module.version, 
//        controllerAs: 'vm',
//        controller: ['pohFinishService', '$stateParams', 'stateService', function(pohFinishService, $stateParams, stateService) {
    
//            var vm = this;
    
    
//            vm.p2_pohfinish_get = pohFinishService.p2_pohfinish_get;
    
//            pohFinishService.loadP2PohFinishGet($stateParams.poh_keyno).then(function () {
//                vm.p2_pohfinish_get = pohFinishService.p2_pohfinish_get;
//            });
    
//            vm.goToPohFinishComp = function (index) {
//                stateService.go('pohfinishcomp', {
//                    poh_keyno: $stateParams.poh_keyno,
//                    quantity_finished_now: vm.p2_pohfinish_get.quantity_finished_now
//                });
//            }
    
//            vm.completeFinishPohScan = function (index) {
//                pohFinishService.completeFinishPohScan().then(function () {
//                    stateService.back();
//                });
//            }
//        }]
//    });
//})();
