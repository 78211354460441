(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customsTarifLang', {
        templateUrl: 'views/components/views/customsTarifLang/customsTarifLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'customsTarifLangService', function ($stateParams, stateService, modalService, translateService, customsTarifLangService) {
            const vm = this;
            let customsTarifId = $stateParams.customstarif_id;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                inputListTarifs: [],
                lockedSave: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadLanguages = function () {
                customsTarifLangService.listCustomsTarifLanguages({ customstarif_id: customsTarifId }).then(function (list) {
                    angular.copy(list, vm.model.inputListTarifs);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                customsTarifLangService.saveObj({ records: vm.model.inputListTarifs }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            vm.$onInit = function () {
                loadLanguages();
            };
        }]
    });
})();
