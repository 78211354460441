(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('favorites', {
        templateUrl: 'views/components/views/favorites/favorites.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['favoritesService', function (favoritesService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            vm.model = {
                itemsListFavourites: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadFavourites = function () {
            	favoritesService.listFavourites().then(function (result) {
            		angular.copy(result, vm.model.itemsListFavourites);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadFavourites();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
