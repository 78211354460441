(function () {
    'use strict';

    angular.module('imApp').component('ttDatasourceSelector', {
        templateUrl: 'views/components/dynamics/components/ttDatasourceSelector/ttDatasourceSelector.template.html',
        controllerAs: 'vm',
        bindings: {
            ttRootid: '@',
            ttModel: '<',
            ttLabel: '@',
            ttChange: '&'
        },
        controller: ['$element', 'translateService', 'layoutService', 'ttDatasourceManager', 'utilityService', function ($element, translateService, layoutService, ttDatasourceManager, us) {
            var vm = this;

            var onDestroy = [];

            vm.datasources = [];

            vm.selectedDatasource = {};

            vm.id = {
                select1: uuid(),
                select2: uuid()
            };

            vm.translations = {
                label: ''
            };

            vm.style = {
                label: {},
                select1: {},
                select2: {}
            };

            vm.class = {
                label: '',
                input: '',
                select1: '',
                select2: ''
            }

            vm.onDatasourceChanged = function () {
                let dss = vm.datasources.filter(function (ds) {
                    return ds.keyno === vm.selectedDatasource.keyno;
                });

                if (dss.length < 1 || angular.isObject(dss[0]) !== true) return;

                angular.copy(dss[0], vm.selectedDatasource);

                onModelChanged();
            };

            vm.onPropertyChanged = function () {
                onModelChanged();
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                vm.style.select1.fontSize = info.fontSizes.textSize;
                vm.style.select1.height = info.height + 'px';
                vm.style.select1.paddingTop = info.padding.top + 'px';
                vm.style.select1.paddingBottom = info.padding.bottom + 'px';
                vm.style.select1.paddingLeft = info.padding.left + 'px';
                vm.style.select1.paddingRight = info.padding.right + 'px';

                vm.style.select2.fontSize = info.fontSizes.textSize;
                vm.style.select2.height = info.height + 'px';
                vm.style.select2.paddingTop = info.padding.top + 'px';
                vm.style.select2.paddingBottom = info.padding.bottom + 'px';
                vm.style.select2.paddingLeft = info.padding.left + 'px';
                vm.style.select2.paddingRight = info.padding.right + 'px';

                vm.class.label = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2';
                vm.class.input = vm.hideLabel
                    ? 'col-xs-12'
                    : info.labelAlwaysOnTop === true
                        ? 'col-xs-12'
                        : 'col-xs-12 col-sm-10';
            });

            vm.$onInit = function () {
                var setLabel = false;

                if (angular.isString(vm.ttLabel) && angular.isUndefined(vm.translations[vm.ttLabel])) {
                    vm.translations[vm.ttLabel] = '';

                    setLabel = true;
                }

                translateService.translateBatch(vm.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.translations[key] = val;
                    });

                    if (setLabel === true) {
                        vm.translations.label = translations[vm.ttLabel];
                    }
                });

                var datasources = ttDatasourceManager.getDatasources(vm.ttRootid);

                angular.forEach(datasources, function (ds) {
                    let pp = [];
                    let keyno = 0;

                    angular.forEach(ds.schema, function (p) {
                        pp.push({ keyno: keyno, name: p.name });

                        keyno++;
                    });

                    vm.datasources.push({
                        keyno: ds.keyno,
                        name: ds.datasource_id,
                        properties: pp,
                        selectedProperty: pp.length > 0 ? pp[0] : undefined
                    });
                });

                if (angular.isString(vm.ttModel) && vm.ttModel.length > 0) {
                    var parts = vm.ttModel.split('.');

                    if (parts.length === 2) {
                        let dss = vm.datasources.filter(function (ds) {
                            return ds.name.toLowerCase() === parts[0].toLowerCase();
                        });

                        if (dss.length > 0 && angular.isObject(dss[0])) {
                            let datasource = dss[0];

                            let sps = datasource.properties.filter(function (prop) {
                                return prop.name.toLowerCase() === parts[1].toLowerCase();
                            });

                            if (sps.length > 0) {
                                datasource.selectedProperty = sps[0];
                            }

                            angular.copy(datasource, vm.selectedDatasource);
                        }
                    }
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            function onModelChanged() {
                if (angular.isFunction(vm.ttChange) !== true) return;

                let datasourceModel = angular.isObject(vm.selectedDatasource.selectedProperty)
                    ? vm.selectedDatasource.name + '.' + vm.selectedDatasource.selectedProperty.name
                    : vm.selectedDatasource.name;

                vm.ttChange({ $value: datasourceModel, $modelId: us.getModelId($element) });
            }
        }]
    });
})();