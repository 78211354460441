(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('timeProjectsWo', {
        templateUrl: 'views/components/views/timeProjectsWo/timeProjectsWo.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['timeProjectsWoService', '$stateParams', function(timeProjectsWoService, $stateParams) {
    
            var vm = this;
    
    
            //vm.time_projects_wo = timeProjectsWoService.time_projects_wo;
            //vm.time_projects_wo_list = timeProjectsWoService.time_projects_wo_list;
    
            vm.time_projects_wo_list = {
                cust_name: 'ALL',
                cust_no: '0',
                ok: '0'
            };
    
            timeProjectsWoService.loadTimeProjectsWo(vm.time_projects_wo_list.cust_name, vm.time_projects_wo_list.cust_no, $stateParams.project_keyno, vm.time_projects_wo_list.ok).then(function () {
                vm.time_projects_wo = timeProjectsWoService.time_projects_wo;
            });
        }]
    });
})();