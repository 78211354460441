(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipments', {
        templateUrl: 'views/components/views/equipments/equipments.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'equipmentsService', 'rememberService', function ($q, stateService, utilityService, equipmentsService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            let variableNames = {
                searchtext: ''
            };

            let clear = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                };
            };

            vm.model = {
                bgEquipmentsMain: {
                    selected: 'TOUCH',
                    buttons: [
                        { id: 'TOUCH', label: 'bg_equipments_main_touch', color: 'primary', onClick: function () { vm.model.bgEquipmentsMain.selected = 'TOUCH'; } },
                        { id: 'GRID', label: 'bg_equipments_main_grid', color: 'primary', onClick: function () { vm.model.bgEquipmentsMain.selected = 'GRID'; } }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => clear('searchtext') }
                ], 
                setting: {},
                itemsListEquipments: [],
                gridReady: false
            };
            
            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

    		// #endregion VARIABLES & DEFINITIONS

            // ## LOAD PROCEDURE CALLS ##
            
            let loadSetting = function () {
                let deferred = $q.defer();

                equipmentsService.getEquipmentsSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.setting.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: {}
                    };
                }

                vm.model.gridReady = true;
            };

            let loadEquipments = function () {
                equipmentsService.listEquipments().then(function (result) {
                    angular.copy(result, vm.model.itemsListEquipments);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
    
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;
    
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
    
    		// #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
    
            // #region BUTTON GROUP SINGLE FUNCTION
    
            vm.selectBgEquipmentsMain = function (item) {
                vm.model.bgEquipmentsMain.selected = item;
            };
    
    		// #endregion BUTTON GROUP SINGLE FUNCTION

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        rememberFunc('searchtext');
                        break;
                    default:
                        break;
                };
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'equipmentedit':
                        go({ equipment_id: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadEquipments();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
