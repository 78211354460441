(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderPurch', {
        templateUrl: 'views/components/views/orderPurch/orderPurch.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'stateService', 'orderPurchService', 'rememberService', function ($stateParams, utilityService, stateService, orderPurchService, rememberService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;

            let variableNames = {
                displaytype_id: ''
            };

            vm.model = {
                bgOrderPurchaseView: {
                    selected: 'STATUS',
                    buttons: [
                        { id: 'STATUS', label: 'bg_orderpurch_view_status', color: 'primary', onClick: () => vm.model.bgOrderPurchaseView.selected = 'STATUS' },
                        { id: 'PURCHORDERS', label: 'bg_orderpurch_view_purchorders', color: 'primary', onClick: () => vm.model.bgOrderPurchaseView.selected = 'PURCHORDERS' }
                    ]
                },
                bgFunction: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_function_select', color: 'primary', onClick: () => vm.model.bgFunction.selected = 'SELECT' },
                        { id: 'VIEW', label: 'bg_function_view', color: 'primary', onClick: () => vm.model.bgFunction.selected = 'VIEW' }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
                selectListDeliveryAddresses: [],
                selectListDisplayTypes: [],
                itemsListComponents: [],
                itemsListPurchaseHeads: [],
                creating: false
            };
            
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
            orderPurchService.loadP2WOrderPurchSettings($stateParams.order_internal_no).then(function (data) {
    			vm.model.setting = angular.copy(data[0]);
                
                loadP2WOrderPurchComponentsList();
    		});
    		
    		orderPurchService.loadP2WOrderPurchDeladronPurchList().then(function (data) {
    			angular.copy(data, vm.model.selectListDeliveryAddresses);
    		});

            orderPurchService.loadP2WOrderPurchDisplayTypeList().then(function (data) {
                angular.copy(data, vm.model.selectListDisplayTypes);
            });

            var loadP2WOrderPurchComponentsList = function () {
                if (angular.isDefined(vm.model.setting.deladronpurch) && angular.isDefined(vm.model.setting.displaytype_id)) {
                    vm.model.itemsListComponents = [];
                    
                    orderPurchService.loadP2WOrderPurchComponentsList(vm.model.setting).then(function (data) {
                        angular.copy(data, vm.model.itemsListComponents);
                    });
                }
    		};
            
            var loadP2WOrderPurchMenuPurchHeadList = function () {
                orderPurchService.loadP2WOrderPurchMenuPurchHeadList($stateParams.order_internal_no).then(function (data) {
                    angular.copy(data, vm.model.itemsListPurchaseHeads);
                });
            };
            
    		loadP2WOrderPurchMenuPurchHeadList();

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'displaytype_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgOrderPurchaseView = function (value) {
                vm.model.bgOrderPurchaseView.selected = value;
            };
            
            vm.selectBgFunction = function (value) {
                vm.model.bgFunction.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
    		//##################
    		//## TOGGLE FUNCTION
    		//##################
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id !== '0' && item.item_id !== '-1') {
                    if (item.item_is_selected == '0') {
                        item.item_is_selected = '1';
                        //item.item_glyphicon = 'glyphicon-check' + returnGlyph;
                    } else if (item.item_is_selected == '1') {
                        item.item_is_selected = '0';
                        //item.item_glyphicon = 'glyphicon-unchecked' + returnGlyph;
                    }
                }
            };
            
            vm.selectAll = function () {
                angular.forEach(vm.model.itemsListComponents, function (item) {
                    if (angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filtervalue.toLowerCase()) !== -1 && item.item_id !== '0' && item.item_id !== '-1') {
                        item.item_is_selected = '0';
                        vm.toggleChosen(item);
                    }
                });
            };
            
            vm.unselectAll = function () {
                angular.forEach(vm.model.itemsListComponents, function (item) {
                    if (angular.isDefined(item.item_filtervalue) && item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.filtervalue.toLowerCase()) !== -1 && item.item_id !== '0' && item.item_id !== '-1') {
                        item.item_is_selected = '1';
                        vm.toggleChosen(item);
                    }
                    //item.item_glyphicon_color = 'black';
                });
            };
            
            vm.setMarkedAsSelected = function () {
                vm.model.creating = true;
                var selected = [];
                
                angular.forEach(vm.model.itemsListComponents, function (item) {
                    if (item.item_id !== '0' && item.item_id !== '-1' && item.item_is_selected == '1') {
                        selected.push(item);
                    }
                });
                
                var item = vm.model.setting;
                item.records = selected;
                
                orderPurchService.createP2WOrderPurchCreate(item).then(function () {
                    vm.model.creating = false;
                    
                    loadP2WOrderPurchComponentsList();
                    loadP2WOrderPurchMenuPurchHeadList();
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'deladronpurch':
                        if (utilityService.validateWatchValue(value, vm.model.setting.deladronpurch) !== true) return;

                        vm.model.setting.deladronpurch = value;

                        if (utilityService.validateParmsValue(vm.model.setting.deladronpurch) !== true) return;

                        loadP2WOrderPurchComponentsList();
                        break;
                    case 'displaytype_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.displaytype_id) !== true) return;

                        vm.model.setting.displaytype_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.displaytype_id) !== true) return;

                        rememberFunc('displaytype_id');
                        loadP2WOrderPurchComponentsList();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
