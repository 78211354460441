(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockLocationCtrlService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
			loadStockLocationCtrlGet: function (stock_keyno) {
				return p2DataTaskService.call(1077, {
					stock_keyno: stock_keyno
				});
            },
            loadStockCountsList: function (stockplace_id, bg_stockcount_status) {
                return p2DataTaskService.call(951, {
                    stockplace_id: stockplace_id,
                    bg_stockcount_status: bg_stockcount_status
                });
            },
            loadStockLocationCtrlList: function (edit) {
                return p2DataTaskService.call(1078, edit);
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 1079,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();