(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('inventoryScan', {
        templateUrl: 'views/components/views/inventoryScan/inventoryScan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$element', '$timeout', 'stateService', 'utilityService', 'inventoryScanService', 'stockCountsService', 'rememberService', 'eventService', function ($q, $element, $timeout, stateService, utilityService, inventoryScanService, stockCountsService, rememberService, eventService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            let variableNames = {
                stockplace_id: ''
            };

            vm.model = {
                mId: 'inventoryScanCtrl',
                stockLocationDescriptionButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectstocklocation', { stockplace_id: vm.model.setting.stockplace_id }) }
                ],
                setting: {
                    stockcount_no_active_info: ''
                },
                selectListStockPlaces: [],
                itemsListLastScans: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadSetting = function () {
                let deferred = $q.defer();

                inventoryScanService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    
                    $q.all().then(() => {
                        loadLastScans();

                        if (vm.model.setting.scan_again === '1') {
                            var wait = function () {
                                if (vm.model.setting.show_mochasoft_scannersymbol === '0') return;
                                var val = $element.find('#' + vm.model.mId)[0].value;

                                if (angular.isString(val) && val !== null && val.length > 0) {
                                    vm.model.setting.barcode = val;
                                    vm.scanBarcode();
                                    return;
                                }

                                $timeout(wait, 250);
                            }

                            wait();

                            window.location = "readbarcode://" + vm.model.mId;
                        } else {
                            vm.scanBarcode();
                        }

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadStockPlaces = function () {
                stockCountsService.listStockPlaces().then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            let changedStockPlace = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                
                return inventoryScanService.checkStockPlace({ stockplace_id: vm.model.setting.stockplace_id }).then(function (response) {
                    vm.model.setting.stockcount_keyno = response[0].stockcount_keyno;
                    vm.model.setting.stockcount_name = response[0].stockcount_name;
                    vm.model.setting.stockcount_no_active_info = response[0].stockcount_no_active_info;
                    vm.model.setting.stocklocation_description = response[0].stocklocation_description;
                    vm.model.setting.stocklocation_keyno = response[0].stocklocation_keyno;
                        
                    loadLastScans();
                });
            };
            
            let loadLastScans = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockcount_keyno) !== true) return;
                
                vm.model.itemsListLastScans = [];
                
                return inventoryScanService.listLastScans({ stockcount_keyno: vm.model.setting.stockcount_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListLastScans);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'stockplace_id':
                    		variableNames[key] = stateService.getCurrentName() + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.setSearchFocus = function (index) {
                if (angular.isUndefined(index)) {
                    index = 0;
                }
                
                setTimeout(function () {
                    document.getElementsByName('formInventoryScanBarcode')[0][index].focus();
                }, 500);
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectstocklocation':
                        go({ stockplace_id: vm.model.setting.stockplace_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

    		// #region SCAN BUTTON FUNCTION
            
            vm.scanBarcode = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockcount_name) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stocklocation_description) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.barcode) !== true) return;
                
                return inventoryScanService.scanStock(vm.model.setting).then(function (response) {
                    stateService.go(response[0].item_state, response[0].item_parms);
                });
            };

            // #endregion SCAN BUTTON FUNCTION

    		//######################
    		//## MOCHA SCAN FUNCTION
    		//######################
            
            //vm.scanMocha = function () {
            //    //window.location = "mochabarcode://CALLBACK=" + bcr //"https://office.insoft.net/touchtimeoys#/bcr.html";
            //    //window.location = "mochabarcode://CALLBACKHASH=https://office.insoft.net/touchtimeoys#/bcr.html?CODE=";
            //    //stateService.go("mochabarcode://CALLBACKHASH=bcr.html?CODE=");
            //}

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        changedStockPlace();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadStockPlaces();
            };

            // #endregion ON INIT FUNCTION

            //onReady
            eventService.on('$viewContentLoaded', function () {
                $timeout(function () {
                    vm.setSearchFocus();
                }, 50);
            });
        }]
    });
})();
