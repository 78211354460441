(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockPlanningProduction', {
        templateUrl: 'views/components/views/stockPlanningProduction/stockPlanningProduction.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', '$stateParams', 'stockPlanningProductionService', 'ieScreenBlockService', 'modalService', function ($timeout, stateService, $stateParams, stockPlanningProductionService, ieScreenBlockService, modalService) {
            var vm = this;
            var timer = null;
            
            vm.model = {
                selectedCount: 0,
                stockplaces: [],
                stockplace_id: '',
                businesscos: [],
                businessco_no: 0,
                suppliers: [],
                supplier_no: 0,
                futuremanko_only: 0,
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                products: [],
                productsRaw: [],
                checkedCount: 0,
                parmCounter: 0,
                checkState: 0,
                prod_id: '',
                stock_date: moment(new Date()).format('YYYY-MM-DD'),
                clickDec: false,
                isLoaded: false
            };

            vm.grid = {
                setup: {
                    remember: 'stockplanningproduction.list.grid',
                    columns: [],
                    format: [],
                    editableCols: [],
                    filter: { enabled: false, mode: false },
                    toolbar: { // alternative -> toolbar: false,
                        hidden: false,
                        fullToolbar: false,
                        tBarSave: false,
                        emptyToolbar: true,
                        hideExports: true,
                        buttons: gridButtons()
                    },
                    pager: { //alternative -> pager: false,
                        pageSizes: false,
                        pageSize: 1000,
                    },
                    autosave: true,
                    localData: true,
                    translate: true,
                    selectable: false,
                    behaviour: {
                        //selectable: "multiple",
                        rowClick: false
                    }
                },
                data: [],
                dataRaw: [],
                saveData: [],
                grid: null,
                gridfunc: null
            };

            vm.loadProducts = loadProducts;
            vm.savePageSetting = savePageSetting;
            vm.gridOptionFunc = gridOptionFunc;
            vm.purchaseLines = purchaseLines;
            vm.customFilter = customFilter;
            vm.createPurchase = createPurchase;
            vm.selectedCount = selectedCount;
            vm.handleStockPlaceChange = handleStockPlaceChange;
            vm.handleBusinesscoChange = handleBusinesscoChange;
            vm.handleSupplierChange = handleSupplierChange;
            vm.goTo = goTo;

            loadPageSettings();

            function handleSupplierChange(value) {
                vm.model.supplier_no = value;
                vm.savePageSetting('stockplanningproduction.supplier_no', value);
                loadQueue();
            }

            function handleStockPlaceChange(value) {
                vm.model.stockplace_id = value;
                vm.savePageSetting('stockplanningproduction.stockplace_id', value);
                loadQueue();
            }

            function handleBusinesscoChange(value) {
                vm.model.businessco_no = value;
                vm.savePageSetting('stockplanningproduction.businessco_no', value);
                loadQueue();
            }

            function gridButtons() {
                return [
                    { name: 'customFilter', text: '', func: function () { vm.customFilter(); }, icon: 'filter' },
                    { name: 'customCreatePurchase', text: '', func: function () { vm.createPurchase() }, icon: 'save' }
                ];
            }

            function customFilter(){
                console.log('Custom Filter');
				var m = 0;
				if (vm.grid.setup.filter.mode == true){
					vm.grid.setup.filter.mode = false;
					m = 0;
				} else {
					vm.grid.setup.filter.mode = true;
					m = 1;
				}				
                vm.savePageSetting('stockplanningproduction.list.grid.filter', m, function(){
                    stateService.go('stockplanningproduction',{},{ reload: true, inherit: false });
				});
				
			}

            function showMessage(title, message, type) {
                return modalService.show({
                    type: type || 'danger',
                    title: title || 'default_error',
                    message: message || 'default_error_message',
                    buttons: [{
                        label: 'ok',
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                        }
                    }]
                });
            }

            function loadPageSettings() {
                stockPlanningProductionService.loadPageSettings().then(function (data) {
                    vm.model.stockplaces = data.stockplaces;
                    vm.model.businesscos = data.businesscos;
                    vm.model.suppliers = data.suppliers;
                    vm.model.stockplace_id = data.stockplace_id;
                    vm.model.businessco_no = data.businessco_no;
                    vm.model.supplier_no = data.supplier_no;

                    if (data.filter == 0) {
                        vm.grid.setup.filter = { enabled: false, mode: false };
                    } else {
                        vm.grid.setup.filter = { enabled: true, mode: true };
                    }

                    console.log(vm.model);
                    
                    $timeout(function(){
                        console.log('Loading products')
                        loadProducts();
                    },300);

                    vm.model.isLoaded = true;
                });
            }

            function loadProducts() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');

                var aDate = vm.model.stock_date.replace(':::', '').trim();

                if (aDate.length > 10) {
                    aDate = aDate.slice(0, 10);
                }

                vm.grid.saveData = [];
                vm.grid.data = [];
                vm.grid.dataRaw = [];

                if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
                    var options = vm.grid.grid.getOptions();
                    options.dataSource.data = [];
                    vm.grid.grid.setOptions(options);
                }

                stockPlanningProductionService
                    .loadProducts({  // NEW NAME ON FUNCTION?
                        prod_id: '', // vm.model.prod_id,
                        supplier_no: vm.model.supplier_no,
                        stock_date: aDate,
                        future_manko_only: vm.model.futuremanko_only,
                        stockplace_id: vm.model.stockplace_id,
                        businessco_no: vm.model.businessco_no
                    })
                    .then(function (data) {
                        if (data.length > 3) {
                            angular.copy(data[3].editable_columns, vm.grid.setup.editableCols);
                        }

                        if (data[2].rows.length > 0) {
                            if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
                                vm.grid.setup.columns = [];
                                vm.grid.setup.format = [];
                                angular.copy(data[0].columns, vm.grid.setup.columns);
                                angular.copy(data[1].format, vm.grid.setup.format);
                                vm.grid.data = [];
                                vm.grid.dataRaw = [];
                                vm.grid.data.push(data[2].rows[0]);

                                $timeout(function () {
                                    vm.grid.setup.columns = [];
                                    vm.grid.setup.format = [];
                                    angular.copy(data[0].columns, vm.grid.setup.columns);
                                    angular.copy(data[1].format, vm.grid.setup.format);
                                    vm.grid.data = [];
                                    vm.grid.dataRaw = [];
                                    angular.copy(data[2].rows, vm.grid.dataRaw);
                                    angular.copy(data[2].rows, vm.grid.data);

                                    var options = vm.grid.grid.getOptions();
                                    options.dataSource.data = [];
                                    angular.copy(data[2].rows, options.dataSource.data);
                                    vm.grid.grid.setOptions(options);

                                    vm.model.isLoaded = true;
                                }, 300);

                            } else {
                                vm.grid.data = [];
                                vm.grid.dataRaw = [];
                                vm.grid.setup.columns = [];
                                vm.grid.setup.format = [];
                                angular.copy(data[0].columns, vm.grid.setup.columns);
                                angular.copy(data[1].format, vm.grid.setup.format);
                                angular.copy(data[2].rows, vm.grid.data);
                                angular.copy(data[2].rows, vm.grid.dataRaw);
                                vm.model.isLoaded = true;
                            }
                        } else {
                            vm.model.isLoaded = true;
                        }

                        vm.model.isLoaded = true;
                        ieScreenBlockService.stop();
                    })
                    .catch(function (error) {
                        vm.model.isLoaded = true;
                        ieScreenBlockService.stop();
                        showMessage(null, error);
                    });
            }

            function savePageSetting(variableName, variableValue, optionalFunc) {
                optionalFunc = optionalFunc || null;
                if (angular.isDefined(variableValue)) {
                    stockPlanningProductionService.savePageSetting({
                        variablename: variableName,
                        variablevalue: variableValue
                    })
                    .then(function (data) {
                        if (angular.isFunction(optionalFunc)) {
                            optionalFunc();
                        }
                    })
                    .catch(function (err) {
                        console.error(err);
                    });
                }
            }

            function purchaseLines() {
                var lines = [];
                var selectedLines = [];
                //try {
                    selectedLines = vm.grid.gridfunc.getAllRows();//getSelectedRows();
                //} catch (error) {}
                if (selectedLines.length > 0){
                    angular.forEach(selectedLines,function(item){
                        //console.log('line_item');
                        //console.dir(item);
                        var item_stock_keyno = item.spp_stock_keyno;
                        var item_ordered_quantity = item.spp_purch_proposal;
                        var item_purchase_price = item.spp_purchprice_default;

                        var item_requested_date = item.spp_requested_date;

                        if (item_ordered_quantity > 0) {
                            lines.push({
                                stock_keyno: item_stock_keyno,
                                ordered_quantity: item_ordered_quantity,
                                purchase_price: item_purchase_price,
                                requested_date: moment(item_requested_date).format('YYYY-MM-DD')
                            });
                        }
                    });                    
                }
                
                return lines;
            }

            function createPurchase() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Lagrer innkjøp');

                var aDate = vm.model.stock_date.replace(':::', '').trim();

                if (aDate.length > 10) {
                    aDate = aDate.slice(0, 10);
                }

                var lines = vm.purchaseLines();

                if (lines.length > 0) {
                    var purchaseLines = {
                        stockplace_id: vm.model.stockplace_id,
                        businessco_no: vm.model.businessco_no,
                        supplier_no: vm.model.supplier_no,
                        purchase_date: aDate,
                        purchase_lines: lines
                    };

                    stockPlanningProductionService
                        .createPurchase(purchaseLines) // NEW NAME ON FUNCTION?
                        .then(function (data) {
                            ieScreenBlockService.stop();
                            stateService.go('purchhead', { purch_internal_no: data[0].purch_internal_no });
                        })
                        .catch(function (err) {
                            ieScreenBlockService.stop();
                            console.error(err);
                        });
                } else {
                    showMessage('Feil', 'Ingen antall angitt!')
                    ieScreenBlockService.stop();
                }
            }

            // TODO: Not implemented
            function goTo(state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case '':
                        go({});
                        break;
                    default:
                        go({
                            argtype: '',
                            argvalue: ''
                        });
                        break;
                }
            }

            // TODO: Not implemented
            function selectedCount() {
                vm.model.selectedCount = 0;

                angular.forEach(vm.model.products, function (item) {
                    if (item.item_is_selected != '0') {
                        vm.model.selectedCount += 1;
                    }
                });
                return vm.model.selectedCount;
            }

            function loadQueue() {
                if (angular.isDefined(timer)) {
                    $timeout.cancel(timer);
                }

                timer = $timeout(function () {
                    vm.loadProducts();
                }, 200);
            }

            function gridOptionFunc(data) {
                if (angular.isDefined(data.type)) {
                    $timeout(function () {
                        vm.grid.selectedCount = vm.grid.gridfunc.getSelectedRowsCount();

                        if (vm.grid.selectedCount == 1){
                            var row = vm.grid.gridfunc.getSelectedRows();
                            if (vm.model.supplier_no == 0){
                                vm.model.supplier_no = row.data[0].spp_supplier_no;
                            }                            
                        }
                    }, 300);
                    
                    switch (data.type) {
                        case 'init':
                            console.log('Init');
                            vm.grid.grid = data.grid;
                            break;
                        case 'dataBoundRow':
                            var cols = {};
                            data.grid.columns;
                            var cellIndex = 0;

                            angular.forEach(data.grid.columns,function(item){
                                item.cellIndex = cellIndex;
                                cols[item.field] = item;
                                cellIndex += 1;
                            });
                            
                            data.row.find('td:eq('+cols['spp_prod_name'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                stateService.go('productprices', { prod_id: data.dataItem.spp_prod_parameter, selected_button: 1 });
                            });
                            break;
                        default:
                            break;
                    }
                }
            }
        }]
    });
})();
