(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pictureBanks', {
        templateUrl: 'views/components/views/pictureBanks/pictureBanks.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'pictureBanksService', 'sirvImageService', 'modalService', function ($stateParams, $timeout, $q, pictureBanksService, sirvImageService, modalService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let progress = '';

            let progressInfo = {
                label: '',
                plabel: '',
                field: '',
                remField: '',
                min: 0,
                max: 100,
                step: 0
            };

            vm.model = {
                setting: {},
                uploadFiles: [],
                selectedFileNames: '',
                itemsListPictures: [],
                lockedUpload: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                pictureBanksService.getPictureBanks({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };

            function getPictures() {
                vm.model.itemsListPictures = [];

                pictureBanksService.listPictures({
                    argtype: argType,
                    argvalue: argValue
                }).then((response) => {
                    angular.copy(response, vm.model.itemsListPictures);
                });
            }

            getPictures();

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'uploadFiles':
                        var files = '';

                        for (var i = 0; i < vm.model.uploadFiles.length; i++) {
                            if (i > 0) {
                                files += ', ';
                            }

                            files += vm.model.uploadFiles[i].name;
                        }

                        vm.model.selectedFileNames = files;
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            let uploadMultipleImages = function (images, folder) {
                let deferred = $q.defer();
                let increment = 1, skip = 0, step = 0;
                progressInfo.step = 0;
                let imageUrls = [];

                let doStep = function () {
                    $timeout(function () {
                        if (progressInfo.step >= progressInfo.max || progress === 'ABORT') return deferred.promise;

                        step = progressInfo.step + skip;
                        progressInfo.step += increment;

                        modalService.progressInfo.step = progressInfo.step;
                        modalService.progressInfo.field = progressInfo.field;

                        sirvImageService.uploadImage(images[step], folder).then(function (data) {
                            imageUrls.push(data);

                            if (progressInfo.step === progressInfo.max) {
                                deferred.resolve(imageUrls);
                            }

                            doStep();
                        });

                    });
                };

                doStep();

                return deferred.promise;
            };

            let uploadImages = function (images, folder) {
                let deferred = $q.defer();

                images = !(images instanceof Array) && images instanceof File ? [images] : images;

                progress = 'START';
                progressInfo.label = 'progressbar';
                progressInfo.field = '';
                progressInfo.max = images.length;
                progressInfo.step = 0;

                uploadMultipleImages(images, folder).then((imageUrls) => deferred.resolve(imageUrls));

                modalService.showProgress(progressInfo).then(function (value) {
                    progress = value;
                });

                return deferred.promise;
            };

            vm.uploadPictures = function () {
                vm.model.lockedUpload = true;

                uploadImages(vm.model.uploadFiles, vm.model.argType).then((imageUrls) => {
                    let counter = 0;

                    imageUrls.forEach((imageUrl) => {
                        pictureBanksService.savePictures({
                            argtype: argType,
                            argvalue: argValue,
                            full_url: imageUrl.imageUrl,
                            filesize_mb: (imageUrl.image.size / (1024 * 1024)).toFixed(2),
                        }).then((_) => {
                            vm.model.lockedUpload = false;
                            counter++;

                            if (counter === imageUrls.length) {
                                getPictures();
                                
                                vm.model.selectedFileNames = '';
                                vm.model.uploadFiles = [];
                            }
                        });
                    });
                });
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
