(function () {
    'use strict';

    angular.module("imApp").factory("domObjectTypeService", ['$ihttp', function ($ihttp) {
        let service = {
            getDomObjectType: function (parms) {
                return $ihttp.post({
                    method: 3018,
                    parameters: parms || {}
                });
            },
            listDomObjectTypes: function (parms) {
                return $ihttp.post({
                    method: 3020,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 3019,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
