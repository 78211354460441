(function () {
    'use strict';

    angular.module("imApp").factory("labourPositionService", ['$ihttp', function ($ihttp) {
        var service = {
            getLabourPosition: function (parms) {
                return $ihttp.post({
                    method: 2563,
                    parameters: parms || {}
                });
            },
            listLabourPositions: function (parms) {
                return $ihttp.post({
                    method: 2565,
                    parameters: parms || {}
                });
            },
            listSelectFactories: function (parms) {
                return $ihttp.post({
                    method: 2024,
                    parameters: parms || {}
                });
            },
            listPositions: function (parms) {
                return $ihttp.post({
                    method: 2600,
                    parameters: parms || {}
                });
            },
            getLabour: function (parms) {
                return $ihttp.post({
                    method: 102,
                    parameters: parms || {}
                });
            },
            scanItem: function (edit) {
                return $ihttp.post({
                    method: 2569,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2566,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 2568,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();