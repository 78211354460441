(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectCheckListAddService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            p2_select_checklist_list: {
                item_id: '',
                item_name: '',
                item_path: '',
                item_state: '',
                item_parms: '',
                orderby: '',
                records: []
            },
            p2_select_checklist_save: {
                webpagename: '',
                parms: ''
            },
            loadP2SelectCheckListList: function (argtype, argvalue) {
                var deferred = $q.defer();

                var parms_p2_select_checklist_list = {
                    method: 260,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parms_p2_select_checklist_list).then(function (data) {
                    service.p2_select_checklist_list.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_select_checklist_list.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveToP2SelectCheckList: function (item) {
                var deferred = $q.defer();

                var parms_p2_select_checklist_save = {
                    method: 261,
                    parameters: {
                        argtype: $stateParams.argtype,
                        argvalue: $stateParams.argvalue,
                        checklist_keyno: item.item_id
                    }
                };

                $ihttp.post(parms_p2_select_checklist_save).then(function (data) {
                    service.p2_select_checklist_save = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();