(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('findCustomers', {
        templateUrl: 'views/components/views/findCustomers/findCustomers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$window', 'stateService', 'utilityService', 'findCustomersService', 'economyService', 'typeaheadService', 'rememberService', function ($q, $window, stateService, utilityService, findCustomersService, economyService, typeaheadService, rememberService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            
            let variableNames = {
                bgLastCustomersSelection: '',
                cust_name: '',
                custgrp_id: '',
                findcustomers_selection: ''
            };

            vm.version = module.version;

            vm.model = {
                mobileSize: window.matchMedia('(min-width: 992px)'),
                bgLastCustomers: {
                    selected: 'LAST',
                    buttons: [
                        { id: 'LAST', label: 'findcustomers_last', color: 'primary', onClick: () => setLastCustomersView('LAST') },
                        { id: 'SEARCH', label: 'findcustomers_search', color: 'primary', onClick: () => setLastCustomersView('SEARCH') }
                    ]
                },
                bgFindCustomers: {
                    selected: 'C',
                    buttons: [
                        { id: 'C', label: 'cust_find_customers', color: 'primary', onClick: () => setFindCustomersView('C') },
                        { id: 'S', label: 'cust_find_suppliers', color: 'primary', onClick: () => setFindCustomersView('S') },
                        { id: 'O', label: 'cust_find_other', color: 'primary', onClick: () => setFindCustomersView('O') }
                    ]
                },
                customerNameButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('cust_name') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => onSearch() }
                ],
                customerGroupButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('custgrp_id') }
                ],
                setting: {},
                selectListCustomerGroups: [],
                itemsListFindCustomers: [],
                gridReady: false
            };
            
            vm.grid = {
                dataTask: {
                    rememberId: 'w_findcustomers.grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {},
                    onDataChanged: function (changed, e) {}
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setLastCustomersView = function (id) {
                vm.model.bgLastCustomers.selected = vm.model.setting.bgLastCustomersSelection = id;
            };

            let setFindCustomersView = function (id) {
                vm.model.bgFindCustomers.selected = vm.model.setting.findcustomers_selection = id;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                findCustomersService.getFindCustomers().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    setLastCustomersView(vm.model.setting.bgLastCustomersSelection);
                    setFindCustomersView(vm.model.setting.findcustomers_selection);

                    $q.all([
                        loadCustomers(),
                    ]).then(() => {
                        loadGridWithSearchtext();

                        deferred.resolve()
                    });
                });

                return deferred.promise;
            };

            // Load only if valid searchtext, as some customers has a lot of data, and the page is frozen for minutes while fetching from database
            let loadGridWithSearchtext = function () {
                if (vm.model.setting.cust_name === '') return;

                loadGrid();
            }

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bgLastCustomersSelection) !== true) return;

                switch (vm.model.setting.bgLastCustomersSelection) {
                    case 'LAST':
                        vm.grid.dataTask.loadData = {
                            method: 2575,
                            parameters: {
                                cust_name: '',
                                filtertype: '',
                                filtervalue: 'ALL',
                                custgrp_id: vm.model.setting.custgrp_id,
                                custtype: vm.model.setting.findcustomers_selection,
                                lastselect: 'LAST'
                            }
                        };

                        vm.model.gridReady = true;

                        if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
                        break;
                    case 'SEARCH':
                        vm.grid.dataTask.loadData = {
                            method: 2575,
                            parameters: {
                                cust_name: vm.model.setting.cust_name,
                                filtertype: '',
                                filtervalue: '',
                                custgrp_id: vm.model.setting.custgrp_id,
                                custtype: vm.model.setting.findcustomers_selection,
                                lastselect: 'SEARCH'
                            }
                        };

                        vm.model.gridReady = true;

                        if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
                        break;
                    default:
                        break;
                }

                vm.model.gridReady = true;
            };

            if (vm.model.setting.custgrp_id === '') {
                vm.model.setting.custgrp_id = 'ALL';
            }

            let loadCustomerGroups = function () {
                economyService.listCustomerGroups({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCustomerGroups);
                });
            };

            let loadCustomers = function () {
                if (vm.model.setting.bgLastCustomersSelection === 'LAST') {
                    if (utilityService.validateParmsValue(vm.model.setting.findcustomers_selection) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.setting.bgLastCustomersSelection) !== true) return;

                    vm.model.itemsListFindCustomers = [];

                    return typeaheadService.searchCustomer({
                        cust_name: '',
                        filtertype: '',
                        filtervalue: '',
                        custgrp_id: 'ALL',
                        custtype: vm.model.setting.findcustomers_selection,
                        lastselect: vm.model.setting.bgLastCustomersSelection
                    }).then(function (result) {
                        angular.copy(result, vm.model.itemsListFindCustomers);
                    });
                } else {
                    if (utilityService.validateParmsValue(vm.model.setting.cust_name, true) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.setting.custgrp_id) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.setting.findcustomers_selection) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.setting.bgLastCustomersSelection) !== true) return;

                    vm.model.itemsListFindCustomers = [];

                    return typeaheadService.searchCustomer({
                        cust_name: vm.model.setting.cust_name,
                        filtertype: '',
                        filtervalue: '',
                        custgrp_id: vm.model.setting.custgrp_id,
                        custtype: vm.model.setting.findcustomers_selection,
                        lastselect: vm.model.setting.bgLastCustomersSelection
                    }).then(function (result) {
                        angular.copy(result, vm.model.itemsListFindCustomers);
                    });
                }
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bgLastCustomersSelection':
                    case 'cust_name':
                    case 'custgrp_id':
                    case 'findcustomers_selection':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.searchCustomers = function () {
                return onSearch();
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgLastCustomers = function (value) {
                vm.model.bgLastCustomers.selected = value;
                vm.model.setting.bgLastCustomersSelection = vm.model.bgLastCustomers.selected;

                rememberFunc('bgLastCustomersSelection');

                if (vm.model.mobileSize.matches === true) {
                    loadGridWithSearchtext();
                } else {
                    loadCustomers();
                }
            };

            vm.selectBgFindCustomers = function (value) {
                vm.model.bgFindCustomers.selected = value;
                vm.model.setting.findcustomers_selection = vm.model.bgFindCustomers.selected;

                rememberFunc('findcustomers_selection');

                if (vm.model.mobileSize.matches === true) {
                    loadGridWithSearchtext();
                } else {
                    loadCustomers();
                }
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'customeredit':
                        go({ cust_no: '0' });
                        break;
                    case 'brreg':
                        rememberFunc('cust_name');
                        go({});
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS


            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.setting.cust_name = '';

                        if (vm.model.setting.cust_name === '') {
                            rememberFunc('cust_name');

                            if (vm.model.mobileSize.matches === true) {
                                loadGridWithSearchtext();
                            } else {
                                loadCustomers();
                            }
                        }
                        break;
                    case 'custgrp_id':
                        vm.model.setting.custgrp_id = 'ALL';

                        if (vm.model.setting.custgrp_id === 'ALL') {
                            rememberFunc('custgrp_id');

                            if (vm.model.mobileSize.matches === true) {
                                loadGridWithSearchtext();
                            } else {
                                loadCustomers();
                            }
                        }
                        break;
                    default:
                        break;
                }
            };

            let onSearch = function () {
                rememberFunc('cust_name');

                if (vm.model.mobileSize.matches === true) {
                    loadGrid();
                } else {
                    loadCustomers();
                }
            }

		    // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'cust_name':
                        if (utilityService.validateWatchValue(value, vm.model.setting.cust_name) !== true) return;

                        vm.model.setting.cust_name = value;

                        if (utilityService.validateParmsValue(vm.model.setting.cust_name, true) !== true) return;

                        if (vm.model.setting.cust_name === '') {
                            rememberFunc('cust_name');

                            if (vm.model.mobileSize.matches === true) {
                                loadGridWithSearchtext();
                            } else {
                                loadCustomers();
                            }
                        }

                        break;
                    case 'custgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.custgrp_id) !== true) return;

                        vm.model.setting.custgrp_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.custgrp_id, true) !== true) return;

                        rememberFunc('custgrp_id');

                        if (vm.model.mobileSize.matches === true) {
                            loadGridWithSearchtext();
                        } else {
                            loadCustomers();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

		    let onWindowResize = function () {
                if (vm.model.mobileSize.matches === true) {
                    loadGridWithSearchtext();
                } else {
                    loadCustomers();
                }
		    };

            // #region ON INIT FUNCTION

            let onDestroy = [];

            vm.$onInit = function () {
                loadSetting();
                loadCustomerGroups();
                $window.addEventListener('resize', onWindowResize);
                //onWindowResize();
            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
