(function () {
    'use strict';

    angular.module('imApp').component('ttApprouteModal', {
        templateUrl: 'views/components/dynamics/components/ttApprouteSelector/ttApprouteModal.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$ihttp', function ($ihttp) {
            var vm = this;

            vm.components = [];

            vm.approute = {
                id: '',
                path: '',
                route_id: '',
                component: '',
                order: 0,
                remarks: ''
            };

            vm.onChange = function (value, id) {
                let parts = id.split('.');

                let key = parts[parts.length - 1];

                vm.approute[key] = value;

                switch (key) {
                    case 'route_id':
                        vm.approute.id = value;
                        vm.approute.path = '/' + value;
                        break;
                }
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initnewapproute'
                    }
                }).then(function (response) {
                    angular.copy(response.components, vm.components);
                });
            };

            vm.$onDestroy = function () {
                if (angular.isFunction(vm.resolve.parameters.callback)) {
                    vm.resolve.parameters.callback(vm.approute);
                }
            };
        }]
    });
})();