(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('actStatusMenu', {
        templateUrl: 'views/components/views/actStatusMenu/actStatusMenu.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'actStatusMenuService', function ($stateParams, stateService, actStatusMenuService) {
            const vm = this;
            let actStatusKeyno = $stateParams.actstatus_keyno;

			vm.model = {
                activityStatusNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('actstatuslangs', { actstatus_keyno: vm.model.menu.actstatus_keyno }) }
                ],
				menu: {}
			};

            let loadMenu = function () {
                actStatusMenuService.getMenu({ actstatus_keyno: actStatusKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'actstatus':
                        go({ actstatus_keyno: vm.model.menu.actstatus_keyno });
                        break;
                    case 'activitytypeactstatuses':
                        go({
                            argtype: 'actstatus_keyno',
                            argvalue: vm.model.menu.actstatus_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
