(function () {
    'use strict';
    
    angular.module('imApp')
    .directive('imSchedulerDirective.prev', ['$q', '$ihttp', '$window', '$stateParams', '$timeout', '$rootScope', '$compile', 'userService', 'stateService', 'responsive', function ($q, $ihttp, $window, $stateParams, $timeout, $rootScope, $compile, userService, stateService, responsive) {
        var directive = {
            restrict: 'E',
            //transclude: true,
            scope: {
                //field: '=',         // Links in the values                                                  //REQUIRED
                data: '=',          // Used for linking a list to the directive
                data2: '=',         // Used for linking a list to the directive
                //listItems: '=',     // Used for linking a list to the directive
                owners: '=',        // Used for linking a list to the directive
                resources: '=?',    // Used for linking a list to the directive
                setup: '=',         // Used for linking the setup variables
                view: '@?'          // Used to specify predefined look and functionality                    //Defaults to 'default', accepts 'timeline' and 'both' as well //'both' not fully implemented
                //optionfunc: '&',    // Used as reference to funtion in Controller
            },
            template:
            '<div ng-class="{\'lock-screen\': model.moving === true}" ' +
                'id="{{model.mId}}" kendo-scheduler ' + 
                'k-options="schedulerOptions">' + 
                //'k-options="schedulerOptions" ' +
                //'k-rebind="listItems.length">' +
            '</div>',
            link: function ($scope, elem) {
                $scope.model = {
                    mId: uuid(),
                    moving: false,
                    schedulerDataSource: [],
                    schedulerDataSourceData: [],
                    schedulerDataSourceGroups: [],
                    schedulerDataSourceOwners: [],
                    schedulerDataSourceResources: [],
                    setup: {},
                    columnWidth: null,
                    columnWidthDefault: 50,
                    columnWidthStepDefault: 10,
                    columnWidthStep: 10,
                    columnWidthMinBigDefault: 50,
                    columnWidthMinBig: 50,
                    columnWidthMinDefault: 10,
                    columnWidthMin: 10,
                    columnWidthMax: 250,
                    columnWidthMaxDefault: 250,
                    columnWidthView: 0,
                    columnResize: false,
                    columnResizeCompress: false,
                    columnResizeExpand: false,
                    minorTick: 2,
                    majorTick: 120,
                    minorTickDefaultDay: 2,
                    majorTickDefaultDay: 120,
                    minorTickDefaultWeek: 1,
                    majorTickDefaultWeek: 240,
                    minorTickDefaultMonth: 1,
                    majorTickDefaultMonth: 1440,
                    minorTickDefaultQuarterYear: 1,
                    majorTickDefaultQuarterYear: 720,
                    resizeDownCounter: 0,
                    resizeUpCounter: 0,
                    eventTemplate: '',
                    //dateHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>'),
                    //majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'HH:mm\')#</strong>'),
                    dateHeaderTemplate: '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>',
                    majorTimeHeaderTemplate: '<strong>#=kendo.toString(date, \'HH:mm\')#</strong>',
                    eventResize: false,
                    initiated: false,
                    reading: false,
                    //hasData: { hasTasks: false, hasOwners: false, hasSetup: false, hasData: false, isValid: false, changeCounter: 0 },
                    hasData: { hasData: false, changeCounter: 0 },
                    dataIsValid: false,
                    view: 'default',
                    previousView: 'week',
                    scheduler: {}
                    //saveNDResponse: [],
                };

                //$scope.saveNewDate = function (variablename, variablevalue) {
                //    var deferred = $q.defer();

                //    var parmsP2PortalUserVariableSetWs = {
                //        method: 616,
                //        parameters: {
                //            variablename: variablename,
                //            variablevalue: variablevalue
                //        }
                //    };

                //    // Prosedyre sp_ws_p2_portal_user_variable_set_ws
                //    $ihttp.post(parmsP2PortalUserVariableSetWs).then(function (data) {
                //        console.log('sp_ws_p2_portal_user_variable_set_ws data:',angular.toJson(data));

                //        $scope.model.saveNDResponse = angular.copy(data[0]);

                //        deferred.resolve();
                //    });

                //    return deferred.promise;
                //}

                $scope.onChange = function (e) {
                    console.log("Task changed: " + e);
                };

                $scope.onAdd = function (e) {
                    console.log("Task added: " + e);
                };

                $scope.onEdit = function (e) {
                    console.log("Task about to be edited :: " + e);
                };

                $scope.onCancel = function (e) {
                    console.log("Cancel task edit :: " + e);
                };

                $scope.onRemove = function (e) {
                    console.log("Task removed :: " + e);
                    console.dir(e);
                    if (e.event.editable === false) {
                        e.preventDefault();
                    }
                };

                $scope.onSave = function (e) {
                    console.log("Task saved :: " + e); //Saved
                    console.dir(e);
                    console.dir($scope.model.schedulerDataSourceData);
                    var sd = new Date(e.event.start);
                    var ed = new Date(e.event.end);

                    sd.setHours(sd.getHours() + 2);
                    ed.setHours(ed.getHours() + 2);

                    console.log('webpagename: ' + stateService.getCurrentName());
                    console.log('item_id: ' + e.event.id);
                    console.log('start_dt: ' + sd.toISOString().replace('T', ' ').replace('Z', ''));
                    console.log('end_dt: ' + ed.toISOString().replace('T', ' ').replace('Z', ''));

                    //var parmsP2DiaryUpdateItem = {
                    //    method: 849,
                    //    parameters: {
                    //        webpagename: stateService.getCurrentName(),
                    //        item_id: e.event.id,
                    //        start_dt: sd.toISOString().replace('T', ' ').replace('Z', ''),
                    //        end_dt: ed.toISOString().replace('T', ' ').replace('Z', '')
                    //    }
                    //};

                    //// Prosedyre sp_ws_p2_diary_updateitem 
                    //$ihttp.post(parmsP2DiaryUpdateItem).then(function (data) {
                    //    console.log('respons: ' + angular.toJson(data));
                    //    console.dir(data);
                    //    if (data.iserror === false) {
                    //        //stateService.go($scope.setup.item_state_new, $scope.setup.item_parms_new);
                    //    }
                    //});

                    //angular.forEach($scope.listItems, function (data) {
                    //    if (angular.isDefined(data) && angular.isDefined(e) && angular.isDefined(e.event) && e.event.id == data.item_id) {
                    //        //console.log('This is all the dates:');
                    //        //console.log(e.event.end.toISOString().replace('T', ' ').replace('Z',''));
                    //        //console.log(data.end_dt);
                    //        //console.log(data.end_dt.toISOString());
                    //        //console.log(data.end_dt.toISOString().substr(0, 10) + ' ' + data.end_dt.toISOString().substr(11, 2) + ' ' + data.end_dt.toISOString().substr(14, 2));
                    //        //console.log(e.event.end.toISOString());
                    //        //console.log(e.event.end.toISOString().substr(0, 10) + ' ' + e.event.end.toISOString().substr(11, 2) + ' ' + e.event.end.toISOString().substr(14, 2));

                    //        var sd = new Date(e.event.start);
                    //        var ed = new Date(e.event.end);

                    //        sd.setHours(sd.getHours() + 2);
                    //        ed.setHours(ed.getHours() + 2);

                    //        data.start_dt = sd.toISOString().replace('T', ' ').replace('Z', '');
                    //        data.end_dt = ed.toISOString().replace('T', ' ').replace('Z', '');
                    //    }
                    //});
                };

                //document.addEventListener('touchstart', function (e) { console.log('touchstart from document') }, false);
                //document.addEventListener('touchmove', function (e) { console.log('touchmove from document') }, false);
                //document.addEventListener('touchend', function (e) { console.log('touchend from document') }, false);
                //document.addEventListener('mouseup', function (e) { console.log('mouseup from document') }, false);
                //document.addEventListener('mouseup', function (e) { console.log('mouseup from document') }, false);

                $scope.onDataBound = function (e) {
                    console.log("Scheduler data bound");
                    //console.dir($scope.setup);
                    //console.dir($scope.listItems);
                    //console.log(stateService.getCurrentName());
                    //console.dir(e.sender.wrapper);
                    $scope.model.eventResize = false;

                    var scheduler = e.sender;

                    $scope.model.scheduler = scheduler;
                    //e.sender.wrapper.off("mouseup touchend").on("mouseup touchend", ".k-scheduler-table td, .k-event", function (e) {
                    e.sender.wrapper.off("mouseup").on("mouseup", ".k-scheduler-table td, .k-event", function (e) {

                        var target = jQuery(e.target);

                        //if (target.hasClass('k-si-close') || target.hasClass('k-resize-handle') || $scope.model.eventResize === true) {
                        if (target.hasClass('k-si-close') || target.hasClass('k-resize-handle')) {
                            return;
                        }

                        target = jQuery(e.currentTarget);
                        if (target.hasClass('k-event')) {
                            var event = scheduler.occurrenceByUid(target.data('uid'));
                            //scheduler.editEvent(event);

                            console.log('Clicked on event');
                            console.dir(event);

                            if (event.item_path === '' || $scope.model.moving === true) return;

                            stateService.go(event.item_state, event.item_parms);
                        } else {
                            var slot = scheduler.slotByElement(target[0]);
                            console.log("Clicked on empty slot");
                            console.dir(slot);
                            //scheduler.addEvent({
                            //    start: slot.startDate,
                            //    end: slot.endDate
                            //});

                            var sd = new Date(slot.startDate);
                            var ed = new Date(slot.endDate);

                            sd.setHours(sd.getHours() + 2);
                            ed.setHours(ed.getHours() + 2);

                            var parmsP2PortalUserVariableSetWs = {
                                method: 616,
                                parameters: {
                                    variablename: 'scheduler.datetimefrom',
                                    variablevalue: sd.toISOString().replace('T', ' ').replace('Z', '')
                                }
                            };

                            // Prosedyre sp_ws_p2_portal_user_variable_set_ws
                            $ihttp.post(parmsP2PortalUserVariableSetWs).then(function (data) {
                                if (data.iserror === false) {
                                    stateService.go($scope.setup.item_state_new, $scope.setup.item_parms_new);
                                }
                            });
                        }
                    });

                    //var customButtons = $('<div class="k-reset im-scheduler-buttons" id="im-scheduler-buttons">' +
                    var customButtons = $(  '<div class="im-scheduler-buttons" id="im-scheduler-buttons">' +
                                                '<div ng-class="addGlyph(\'upup\')" type="button" ng-click="goSchedulerButtons(\'upup\')" ng-disabled="buttonsDisability(\'upup\')"></div>' +
                                                '<div ng-class="addGlyph(\'up\')" type="button" ng-click="goSchedulerButtons(\'up\')" ng-disabled="buttonsDisability(\'up\')"></div>' +
                                                '<div ng-class="addGlyph(\'expand\')" type="button" ng-click="goSchedulerButtons(\'expand\')" ng-disabled="buttonsDisability(\'expand\')"></div>' +
                                                '<div ng-class="addGlyph(\'compress\')" type="button" ng-click="goSchedulerButtons(\'compress\')" ng-disabled="buttonsDisability(\'compress\')"></div>' +
                                                '<div ng-class="addGlyph(\'down\')" type="button" ng-click="goSchedulerButtons(\'down\')" ng-disabled="buttonsDisability(\'down\')"></div>' +
                                                '<div ng-class="addGlyph(\'downdown\')" type="button" ng-click="goSchedulerButtons(\'downdown\')" ng-disabled="buttonsDisability(\'downdown\')"></div>' +
                                                //'<div type="button" ng-click="goSchedulerButtons(\'minus\')"> BTN </div>' +
                                            '</div>');

                    try {
                        //$compile($(scheduler.toolbar[0].children[0]).after(customButtons))($scope);
                        $compile(customButtons)($scope);
                        //$compile(wrap.html(template))($scope);
                    } catch (err) {
                        console.log('compile error: ' + err);
                    }

                    //$(scheduler.toolbar).splice(2, 0, customButtons);
                    //k-scheduler-navigation
                    //console.dir(scheduler.toolbar);

                    var btnsDefined = false;

                    for (var t = 0; t < scheduler.toolbar[0].children.length; t++) {
                        if (scheduler.toolbar[0].children[t].id === 'im-scheduler-buttons') {
                            btnsDefined = true;
                            break;
                        }
                    }

                    if (!btnsDefined) {
                        $(scheduler.toolbar[0].children[0]).after(customButtons);
                    }

                    //if ($scope.model.columnWidth === 0 || expandCompressPressed === true) {
                    //if ($scope.model.columnWidth === 0) {
                    //console.dir($scope.model.scheduler.options);

                    //if ($scope.model.columnWidthView === null || $scope.model.columnWidthView === 0) {
                    //    $scope.model.columnWidthView = $scope.model.scheduler.element[0].children[1].children[0].children[1].children[1].clientWidth;
                    //}

                    if ($scope.model.columnResize === true || $scope.model.columnWidthView === 0) {
                        //console.log('We here');
                        //$scope.model.columnWidthStep = $scope.model.columnWidthStepDefault * $scope.model.minorTick;
                        $scope.model.columnWidthView = $scope.model.scheduler.element[0].children[1].children[0].children[1].children[1].clientWidth;
                        var shownHours = $scope.model.scheduler.options.showWorkHours === false || $scope.setup.selectedview === 'timelineMonth' || $scope.setup.selectedview === 'timelineQuarter' || $scope.setup.selectedview === 'timelineYear' || $scope.setup.selectedview === 'timelineWeekYear' ? 24 : 8;
                        var numbOfDays = $scope.setup.selectedview === 'timelineYear' || $scope.setup.selectedview === 'timelineWeekYear' ? 12 : $scope.setup.selectedview === 'timelineQuarter' ? 3 : $scope.setup.selectedview === 'timelineMonth' ? daysInMonth() : $scope.setup.selectedview === 'timelineWeek' ? 7 : $scope.setup.selectedview === 'timelineWorkWeek' ? 5 : 1;
                        var hourPerDay = shownHours * numbOfDays;
                        var hourFactor = $scope.model.majorTick / 60;
                        var dividerBig = hourPerDay / hourFactor;
                        var dividerNum = dividerBig * $scope.model.minorTick;

                        //var totalWidth = $scope.model.columnWidth * hourPerDay;

                        //var v = $scope.model.scheduler.element[0].children[1].children[0].children[1].children[1].clientWidth;
                        var w = $scope.model.scheduler.element[0].children[1].children[0].children[0].children[1].children[0].children[0].scrollWidth;
                        //var wb = w * $scope.model.minorTick / dividerBig;
                        var wb = w / dividerBig;
                        var wi = w / dividerNum;
                        //var wbf = Math.floor((wb + ($scope.model.columnWidthStep / 2)) / $scope.model.columnWidthStep) * $scope.model.columnWidthStep;
                        var wbf = Math.floor(wb / $scope.model.columnWidthStep) * $scope.model.columnWidthStep;
                        var wif = Math.floor(wi / $scope.model.columnWidthStep) * $scope.model.columnWidthStep;

                        //console.log('The calculations');
                        //console.dir('columnWidthView: ' + $scope.model.columnWidthView);
                        //console.dir('columnWidthStep: ' + $scope.model.columnWidthStep);
                        //console.log('shownHours: ' + shownHours);
                        //console.log('numbOfDays: ' + numbOfDays);
                        //console.log('hourPerDay: ' + hourPerDay);
                        //console.log('hourFactor: ' + hourFactor);
                        //console.log('dividerBig: ' + dividerBig);
                        //console.log('dividerNum: ' + dividerNum);
                        //console.log('scrolWidth: ' + w);
                        //console.log('dvBigWidth: ' + wb);
                        //console.log('dvIndWidth: ' + wi);
                        //console.log('dvBigFlWth: ' + wbf);
                        //console.log('dvIndFlWth: ' + wif);

                        if ($scope.setup.selectedview === 'timelineMonth' && wi - wif < $scope.model.columnWidthStep) {
                            wif += $scope.model.columnWidthStep;
                        }

                        //var fl = 110;
                        //console.log('fl: ' + (fl/10)*10);
                        //console.log('flfl: ' + Math.floor(fl/10)*10);
                        //console.dir($scope.model.scheduler.element[0].children[1].children[0].children[0].children[1].children[0].children[0]);
                        //console.dir($scope.model.scheduler.element[0].children[1].children[0].children[1].children[1]);
                        //console.dir(totalWidth);

                        if (wbf < $scope.model.columnWidthMinBigDefault) {
                            wif = $scope.model.columnWidthMinBigDefault / $scope.model.minorTick;
                        }

                        //console.log('1: ' + $scope.model.columnWidth);
                        $scope.model.columnWidth = wif;
                        //console.log('2: ' + $scope.model.columnWidth);

                        //totalWidth = $scope.model.columnWidth * hourPerDay;

                        //if (totalWidth <= $scope.model.columnWidthView) {
                        if ($scope.model.columnWidth * dividerNum <= $scope.model.columnWidthView) {
                            $scope.model.columnResizeCompress = true;
                        } else {
                            $scope.model.columnResizeCompress = false;
                        }

                        //if ($scope.model.columnWidth * dividerNum >= $scope.model.columnWidthView) {
                        //    $scope.model.columnResizeCompress = true;
                        //} else {
                        //    $scope.model.columnResizeCompress = false;
                        //}
                        //console.log('$scope.model.columnResizeCompress: ' + $scope.model.columnResizeCompress);

                        //console.dir($scope.model.scheduler.element[0].children[1].children[0].children[1].children[1].clientWidth);
                        //console.dir($scope.model.columnWidthView);
                    }
                };

                //var timer = null;

                $scope.onDataBinding = function (e) {
                    //console.log("Scheduler data binding");
                    //console.dir(e);
                    //console.dir($scope.setup);

                    //if (angular.isDefined(timer)) {
                    //    $timeout.cancel(timer);
                    //}

                    //timer = $timeout(function () {
                    //    $scope.model.rebindDone = true;
                    //}, 250);
                };

                $scope.onNavigate = function (e) {
                    console.log(kendo.format("navigate:: view:{0};", e.view));
                    console.dir(e);

                    $scope.model.columnResize = true;
                    $scope.model.columnResizeCompress = false;

                    //$scope.model.columnWidthDefault: 50,
                    //$scope.model.columnWidthStepDefault: 10,
                    if (e.view === 'timeline') {
                        $scope.model.minorTick = $scope.model.minorTickDefaultDay;
                        $scope.model.majorTick = $scope.model.majorTickDefaultDay;
                        $scope.model.columnWidth = $scope.model.columnWidthDefault; //50
                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                        $scope.model.majorTimeHeaderTemplate = '<strong>#=kendo.toString(date, \'HH:mm\')#</strong>';

                        $scope.setSchedulerOptions();
                    } else if (e.view === 'timelineWeek' || e.view === 'timelineWorkWeek') {
                        $scope.model.minorTick = $scope.model.minorTickDefaultWeek;
                        $scope.model.majorTick = $scope.model.majorTickDefaultWeek;
                        $scope.model.columnWidth = $scope.model.columnWidthDefault; //50
                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                        $scope.model.majorTimeHeaderTemplate = '<strong>#=kendo.toString(date, \'HH:mm\')#</strong>';

                        $scope.setSchedulerOptions();
                    } else if (e.view === 'timelineMonth') {
                        $scope.model.minorTick = $scope.model.minorTickDefaultMonth;
                        $scope.model.majorTick = $scope.model.majorTickDefaultMonth;
                        $scope.model.columnWidth = $scope.model.columnWidthDefault; //50
                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                        $scope.model.majorTimeHeaderTemplate = '';

                        $scope.setSchedulerOptions();
                    } else if (e.view === 'timelineQuarter') {
                        $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                        $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                        $scope.model.columnWidth = $scope.model.columnWidthDefault; //50
                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMMM -yy\')#</strong>';
                        $scope.model.majorTimeHeaderTemplate = '';

                        $scope.setSchedulerOptions();
                    } else if (e.view === 'timelineYear') {
                        $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                        $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                        $scope.model.columnWidth = $scope.model.columnWidthDefault; //50
                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMM -yy\')#</strong>';
                        $scope.model.majorTimeHeaderTemplate = '';

                        $scope.setSchedulerOptions();
                    } else if (e.view === 'timelineWeekYear') {
                        $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                        $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                        $scope.model.columnWidth = $scope.model.columnWidthDefault; //50
                        //$scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMM -yy\')#</strong>';
                        //$scope.model.majorTimeHeaderTemplate = '';

                        $scope.setSchedulerOptions();
                    }

                    //if (e.action !== "changeView" && angular.isDefined(e.date) && e.date !== new Date($scope.setup.date_fom)) {
                    //    var curr = e.date, y = curr.getFullYear(), m = curr.getMonth(), f = curr.getDate();
                    //    if (e.view === 'agenda' && $scope.model.previousView !== 'day') {
                    //        e.preventDefault();
                    //        if (e.action === "previous") {
                    //            if ($scope.model.previousView === 'month') {
                    //                m = curr.getMonth() - 1;
                    //            } else {
                    //                f = curr.getDate() - 6;
                    //            }
                    //        } else if (e.action === "next") {
                    //            if ($scope.model.previousView === 'month') {
                    //                m = curr.getMonth() + 1;
                    //            } else {
                    //                f = curr.getDate() + 6;
                    //            }
                    //        }
                    //    }
                    //    var d = new Date(y, m, f, 2);

                    //    $scope.setup.date_fom = d.toISOString().substring(0, 10);
                    //    $scope.schedulerOptions.date = $scope.setup.date_fom;
                    //}

                    if ($scope.setup.selectedview !== e.view) {
                        $scope.setup.selectedview = e.view;
                        if (e.view !== 'agenda') {
                            $scope.model.previousView = e.view;
                        }

                        angular.forEach($scope.schedulerOptions.views, function (data) {
                            if (angular.isDefined(data)) {
                                if (data.type === $scope.setup.selectedview) {
                                    data.selected = true;
                                } else {
                                    data.selected = false;
                                }
                            }
                        });
                    }
                };

                $scope.onMoveStart = function (e) {
                    console.log("moveStart");
                    $scope.model.moving = true;
                };

                $scope.onMove = function (e) {
                    console.log("move");
                    $scope.model.moving = true;
                };

                $scope.onMoveEnd = function (e) {
                    console.log("moveEnd");
                    console.dir(e);
                    $scope.model.moving = false;

                    //angular.forEach($scope.listItems, function (data) {
                    //    if (angular.isDefined(e.event) && angular.isDefined(e.event.id) && e.event.id == data.id) {
                    //        data.start_dt = e.event.start.toISOString().replace('T', ' ').replace('Z', '');
                    //        data.end_dt = e.event.end.toISOString().replace('T', ' ').replace('Z', '');
                    //    }
                    //});
                };

                $scope.onResizeStart = function (e) {
                    console.log("resizeStart");
                    $scope.model.eventResize = true;
                };

                $scope.onResize = function (e) {
                    console.log("resize");
                };

                $scope.onResizeEnd = function (e) {
                    console.log("resizeEnd");
                };

                $scope.setSchedulerOptions = function () {
                    $scope.model.scheduler.setOptions({
                        minorTickCount: $scope.model.minorTick,
                        majorTick: $scope.model.majorTick,
                        columnWidth: $scope.model.columnWidth,
                        dateHeaderTemplate: kendo.template($scope.model.dateHeaderTemplate),
                        majorTimeHeaderTemplate: kendo.template($scope.model.majorTimeHeaderTemplate)
                    });
                }

                $scope.addGlyph = function (item) {
                    var allClass = ''; //default

                    if (item === 'downdown') {
                        //allClass = 'glyphicon glyphicon-minus btn btn-primary btn-sm multi-list-menu-btn pull-right';
                        allClass = 'far fa-angle-double-down btn btn-default btn-sm im-scheduler-btn pull-right';
                    } else if (item === 'down') {
                        allClass = 'far fa-angle-down btn btn-default btn-sm im-scheduler-btn pull-right';
                    } else if (item === 'expand') {
                        allClass = 'far fa-expand-arrows-alt btn btn-default btn-sm im-scheduler-btn pull-right';
                    } else if (item === 'compress') {
                        allClass = 'far fa-compress-arrows-alt btn btn-default btn-sm im-scheduler-btn pull-right';
                    } else if (item === 'up') {
                        allClass = 'far fa-angle-up btn btn-default btn-sm im-scheduler-btn pull-right';
                    } else if (item === 'upup') {
                        allClass = 'far fa-angle-double-up btn btn-default btn-sm im-scheduler-btn pull-right';
                    }

                    return allClass;
                };

                //var resizeTimer = null;

                $scope.goSchedulerButtons = function (item) {
                    //console.log('BTN IS CLICKED: ' + item);
                    //console.dir($scope.model.scheduler);

                    //console.dir($scope.model.scheduler.element[0].children[1].children[0].children[1].children[1].clientWidth);

                    //var minor = $scope.model.minorTick; //divisions per major
                    //var major = $scope.model.majorTick; //minutes

                    //var refresh = true;

                    $scope.model.columnResize = false;

                    if (item === 'downdown') {
                        $scope.model.columnResizeCompress = false;
                        if ($scope.model.majorTick > 60) {
                            $scope.model.majorTick -= 60;
                        } else if ($scope.model.majorTick > 30) {
                            $scope.model.majorTick -= 30;
                        } else if ($scope.model.majorTick > 15) {
                            $scope.model.majorTick -= 15;
                        } else if ($scope.model.majorTick > 5) {
                            if ($scope.model.majorTick > 10) {
                                if ($scope.model.minorTick > 10) {
                                    $scope.model.minorTick = 10;
                                }
                            } else {
                                if ($scope.model.minorTick > 5) {
                                    $scope.model.minorTick = 5;
                                }
                            }
                            $scope.model.majorTick -= 5;
                        }
                    } else if (item === 'down') {
                        if ($scope.model.minorTick > 1) {
                            //$scope.model.minorTick /= 2;
                            $scope.model.minorTick -= 1;
                        }
                    } else if (item === 'compress') {
                        console.log('compress');
                        $scope.model.resizeDownCounter = 0;
                        //console.log('Widths: ');
                        //console.log($scope.model.columnWidth);
                        //console.log($scope.model.columnWidthMinBig);
                        //console.log($scope.model.columnWidthMinBig * $scope.model.minorTick);
                        //if ($scope.model.columnWidth >= 50 && $scope.model.columnWidth > $scope.model.columnWidthMinDefault) {
                        if (($scope.model.columnWidth * $scope.model.minorTick) - ($scope.model.columnWidthStep * $scope.model.minorTick) >= ($scope.model.columnWidthMinBig / $scope.model.minorTick) && $scope.model.columnResizeCompress === false && $scope.setup.selectedview !== 'timelineMonth') {
                            if ($scope.model.columnWidth - $scope.model.columnWidthStep >= $scope.model.columnWidthMin) {
                                $scope.model.columnWidth -= $scope.model.columnWidthStep;
                            } else {
                                //$scope.model.columnWidth = $scope.model.columnWidthMinBig / $scope.model.minorTick;
                                $scope.model.columnWidth = $scope.model.columnWidthMin;
                            }
                            //$scope.model.columnWidth -= $scope.model.columnWidthStep;
                        } else {
                            if ($scope.model.resizeUpCounter < 2 && $scope.setup.selectedview !== 'timelineYear' && $scope.setup.selectedview !== 'timelineWeekYear') {
                                $scope.model.resizeUpCounter++;
                            } else {
                                console.log('This one!');
                                $scope.model.resizeUpCounter = 0;
                                $scope.model.columnResizeCompress = false;
                                if ($scope.model.majorTick >= 120) {
                                    console.log('We in the if');
                                    //$scope.model.columnWidthDefault: 50,
                                    //$scope.model.columnWidthStepDefault: 10,
                                    if ($scope.setup.selectedview === 'timeline') {
                                        $scope.setup.selectedview = 'timelineWorkWeek';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultWeek;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultWeek;
                                        //$scope.model.columnWidth = 75;
                                        $scope.model.columnWidth = $scope.model.columnWidthDefault + ($scope.model.columnWidthStepDefault * $scope.model.minorTick);
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '<strong>#=kendo.toString(date, \'HH:mm\')#</strong>';

                                        $scope.setSchedulerOptions();
                                    } else if ($scope.setup.selectedview === 'timelineWorkWeek' || $scope.setup.selectedview === 'timelineWeek') {
                                        $scope.setup.selectedview = 'timelineMonth';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultMonth;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultMonth;
                                        //$scope.model.columnWidth = 100;
                                        $scope.model.columnWidth = $scope.model.columnWidthDefault + ($scope.model.columnWidthStepDefault * $scope.model.minorTick);
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '';

                                        $scope.setSchedulerOptions();
                                    //} else if ($scope.setup.selectedview === 'timelineMonth') {
                                    //    $scope.setup.selectedview = 'timelineQuarter';

                                    //    $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                                    //    $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                                    //    $scope.model.columnWidth = 100;
                                    //    //$scope.model.columnWidth = $scope.model.columnWidthDefault + ($scope.model.columnWidthStepDefault * $scope.model.minorTick);
                                    //    $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMMM -yy\')#</strong>';
                                    //    $scope.model.majorTimeHeaderTemplate = '';

                                    //    $scope.setSchedulerOptions();
                                    //} else if ($scope.setup.selectedview === 'timelineQuarter') {
                                    //    $scope.setup.selectedview = 'timelineYear';

                                    //    $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                                    //    $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                                    //    $scope.model.columnWidth = 100;
                                    //    //$scope.model.columnWidth = $scope.model.columnWidthDefault + ($scope.model.columnWidthStepDefault * $scope.model.minorTick);
                                    //    $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMM -yy\')#</strong>';
                                    //    $scope.model.majorTimeHeaderTemplate = '';

                                    //    $scope.setSchedulerOptions();
                                    } else if ($scope.setup.selectedview === 'timelineMonth') {
                                        console.log('here');
                                        $scope.setup.selectedview = 'timelineWeekYear';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                                        $scope.model.columnWidth = 100;
                                        //$scope.model.columnWidth = $scope.model.columnWidthDefault + ($scope.model.columnWidthStepDefault * $scope.model.minorTick);
                                        //$scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMM -yy\')#</strong>';
                                        //$scope.model.majorTimeHeaderTemplate = '';

                                        $scope.setSchedulerOptions();
                                    } else {
                                        return;
                                    }

                                    //if (angular.isDefined(resizeTimer)) {
                                    //    console.log('cancel');
                                    //    $timeout.cancel(resizeTimer);
                                    //}

                                    //resizeTimer = $timeout(function () {
                                    //    $scope.model.columnResize = true;
                                    //    $scope.model.scheduler.view($scope.setup.selectedview);
                                    //}, 25);

                                    //console.log('days In Month: ');
                                    //for (var m = 0; m < 12; m++) {
                                    //    console.log('days In Month ' + m + ': ' + daysInMonth(m));
                                    //}
                                    //console.log('days In This Month: ' + daysInThisMonth());
                                    $scope.model.columnResize = true;
                                    $scope.model.scheduler.view($scope.setup.selectedview);
                                } else {
                                    //console.log('We in the else');
                                    $scope.goSchedulerButtons('upup');
                                }
                            }
                            console.log('compress further - change timeline');
                            return;
                        }
                        $scope.model.columnResize = true;
                    } else if (item === 'expand') {
                        console.log('expand');
                        $scope.model.resizeUpCounter = 0;
                        //if ($scope.model.columnWidth + $scope.model.columnWidthStep <= $scope.model.columnWidthMax) {
                        //    $scope.model.columnWidth += $scope.model.columnWidthStep;
                        //}
                        if (($scope.model.columnWidth * $scope.model.minorTick) + ($scope.model.columnWidthStep * $scope.model.minorTick) <= ($scope.model.columnWidthMinMax / $scope.model.minorTick) && $scope.model.columnResizeCompress === false) {
                            if ($scope.model.columnWidth + $scope.model.columnWidthStep <= $scope.model.columnWidthMax) {
                                $scope.model.columnWidth += $scope.model.columnWidthStep;
                            } else {
                                $scope.model.columnWidth = $scope.model.columnWidthMinBig / $scope.model.minorTick;
                                //$scope.model.columnWidth = $scope.model.columnWidthMin;
                            }
                        } else {
                            if ($scope.model.resizeDownCounter < 2 && $scope.setup.selectedview !== 'timeline') {
                                $scope.model.resizeDownCounter++;
                            } else {
                                $scope.model.resizeDownCounter = 0;
                                $scope.model.columnResizeExpand = false;
                                if ($scope.model.majorTick >= 120) {
                                    if ($scope.setup.selectedview === 'timelineWeekYear') {
                                        $scope.setup.selectedview = 'timelineMonth';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultMonth;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultMonth;
                                        $scope.model.columnWidth = 200;
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '';

                                        $scope.setSchedulerOptions();
                                    } else if ($scope.setup.selectedview === 'timelineYear') {
                                        $scope.setup.selectedview = 'timelineQuarter';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultQuarterYear;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultQuarterYear;
                                        $scope.model.columnWidth = 200;
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'MMMM -yy\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '';

                                        $scope.setSchedulerOptions();
                                    } else if ($scope.setup.selectedview === 'timelineQuarter') {
                                        $scope.setup.selectedview = 'timelineMonth';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultMonth;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultMonth;
                                        $scope.model.columnWidth = 200;
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '';

                                        $scope.setSchedulerOptions();
                                    } else if ($scope.setup.selectedview === 'timelineMonth') {
                                        $scope.setup.selectedview = 'timelineWorkWeek';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultWeek;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultWeek;
                                        $scope.model.columnWidth = 200;
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '<strong>#=kendo.toString(date, \'HH:mm\')#</strong>';

                                        $scope.setSchedulerOptions();
                                    } else if ($scope.setup.selectedview === 'timelineWeek' || $scope.setup.selectedview === 'timelineWorkWeek') {
                                        $scope.setup.selectedview = 'timeline';

                                        $scope.model.minorTick = $scope.model.minorTickDefaultDay;
                                        $scope.model.majorTick = $scope.model.majorTickDefaultDay;
                                        $scope.model.columnWidth = 50;
                                        $scope.model.dateHeaderTemplate = '<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>';
                                        $scope.model.majorTimeHeaderTemplate = '<strong>#=kendo.toString(date, \'HH:mm\')#</strong>';

                                        $scope.setSchedulerOptions();
                                    } else {
                                        return;
                                    }

                                    $scope.model.columnResize = true;
                                    $scope.model.scheduler.view($scope.setup.selectedview);
                                } else {
                                    $scope.goSchedulerButtons('downdown');
                                }
                            }
                            //$scope.model.columnWidth = $scope.model.columnWidthMax;
                            console.log('expand further - change timeline');
                            return;
                        }
                        $scope.model.columnResize = true;
                    } else if (item === 'up') {
                        if ($scope.model.majorTick <= 5) {
                            if ($scope.model.minorTick < 5) {
                                $scope.model.minorTick += 1;
                            }
                        } else if ($scope.model.majorTick <= 10) {
                            if ($scope.model.minorTick < 10) {
                                $scope.model.minorTick += 1;
                            }
                        } else {
                            if ($scope.model.minorTick >= 60) {
                                //$scope.model.columnResize = false;
                                return;
                            }
                            $scope.model.minorTick += 1;
                        }
                    } else if (item === 'upup') {
                        $scope.model.columnResizeCompress = false;
                        if ($scope.model.majorTick >= 720) {
                            return;
                        } if ($scope.model.majorTick >= 60) {
                            $scope.model.majorTick += 60;
                        } else if ($scope.model.majorTick >= 30) {
                            $scope.model.majorTick += 30;
                        } else if ($scope.model.majorTick >= 15) {
                            $scope.model.majorTick += 15;
                        } else if ($scope.model.majorTick >= 5) {
                            $scope.model.majorTick += 5;
                        }
                    }

                    //if ($scope.model.columnResize) {

                    //}

                    $scope.model.scheduler.setOptions({
                        minorTickCount: $scope.model.minorTick,
                        majorTick: $scope.model.majorTick,
                        columnWidth: $scope.model.columnWidth
                    });

                    $scope.model.scheduler.view($scope.model.scheduler.view().name);
                };

                $scope.buttonsDisability = function (item) {
                    var disability = false; //default

                    if (item === 'downdown') {
                        if ($scope.model.majorTick <= 5 || $scope.setup.selectedview === 'timelineQuarter' || $scope.setup.selectedview === 'timelineYear') {
                            disability = true;
                        }
                    } else if (item === 'down') {
                        if ($scope.model.minorTick <= 1) {
                            disability = true;
                        }
                    } else if (item === 'expand') {
                        //console.log('expand');
                    } else if (item === 'compress') {
                        //console.log('compress');
                    } else if (item === 'up') {
                        if ($scope.model.majorTick <= 5) {
                            if ($scope.model.minorTick >= 5) {
                                disability = true;
                            }
                        } else if ($scope.model.majorTick <= 10) {
                            if ($scope.model.minorTick >= 10) {
                                disability = true;
                            }
                        } else {
                            if ($scope.model.minorTick >= 60) {
                                disability = true;
                            }
                        }
                    } else if (item === 'upup') {
                        if ($scope.model.majorTick >= 720 || $scope.setup.selectedview === 'timelineQuarter' || $scope.setup.selectedview === 'timelineYear') {
                            disability = true;
                        }
                    }

                    return disability;
                };

                //function isInArray(value, array) {
                //    return array.indexOf(value) > -1;
                //}

                //function isNumber(n) {
                //    return !isNaN(parseFloat(n)) && isFinite(n);
                //}

                //function daysInThisMonth() {
                //    var now = new Date();
                //    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
                //}

                //function daysInYear(month, year) {
                //    //return new Date(year, month, 0).getDate();
                //    var today = new Date();
                //    year = (year !== undefined && year !== null) ? year : today.getFullYear();
                //    month = month !== undefined && month !== null ? month : today.getMonth();
                //    var days = new Date().getFullYear() % 4 == 0 ? 366 : 365;
                //    return new Date(year, month + 1, 0).getDate();
                //}

                function capitalizeFirstLetter(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                }

                function daysInMonth(month, year) {
                    //return new Date(year, month, 0).getDate();
                    var today = new Date();
                    year = (year !== undefined && year !== null) ? year : today.getFullYear();
                    month = month !== undefined && month !== null ? month : today.getMonth();
                    return new Date(year, month+1, 0).getDate();
                }

                function firstDayOfNextMonth(date) {
                    var result = new Date(date.getTime());

                    result.setMonth(result.getMonth() + 1);
                    result.setDate(1);

                    return result;
                }

                function firstDayOfThisMonth(date) {
                    var result = new Date(date.getTime());

                    result.setDate(1);

                    return result;
                }

                function firstDayOfPreviousMonth(date) {
                    var result = new Date(date.getTime());

                    result.setMonth(result.getMonth() - 1);
                    result.setDate(1);

                    return result;
                }

                function lastDayOfThisMonth(date) {
                    return new Date(date.getFullYear(), date.getMonth() + 1, 0)    // month starts at 1, so zero should be the last day of the previous month etc
                }

                function firstDayOfThisOrPreviousMonth(date) {
                    if (date.getDate() === 1)
                        return firstDayOfPreviousMonth(date);
                    else
                        return firstDayOfThisMonth(date);
                }

                function getWeekInfo(d) {
                    // Copy date so don't modify original
                    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
                    // Set to nearest Thursday: current date + 4 - current day number
                    // Make Sunday's day number 7
                    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
                    // Get first day of year
                    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
                    // Calculate full weeks to nearest Thursday
                    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

                    // Return week number, month and year
                    return {
                        week: weekNo,
                        month: d.getMonth(),
                        year: d.getUTCFullYear()
                    };
                };

                function getWeekNumberAndYear(date) {
                    // Copy date so don't modify original
                    var d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                    // Set to nearest Thursday: current date + 4 - current day number
                    // Make Sunday's day number 7
                    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
                    // Get first day of year
                    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
                    // Calculate full weeks to nearest Thursday
                    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
                    // Return array of year and week number
                    return [d.getUTCFullYear(), weekNo];
                }

                function getWeekNumber(d) {
                    var date = angular.isDefined(d) === true ? d : new Date();
                    var result = getWeekNumberAndYear(date);
                    return result[1];
                }

                //console.log('Today is week: ' + getWeekNumber(new Date('2011-01-05')));

                elem.on('mousewheel', function (e) {
                    //console.log('scrolling is cool!');
                    //console.dir(e);
                    if (e.originalEvent.deltaY < 0) { //zoom in
                        if (e.shiftKey === true) {
                            $scope.goSchedulerButtons('upup');
                        } else if (e.ctrlKey === true) {
                            //console.log('ctrlKey');
                            //$scope.goSchedulerButtons('down');
                        } else if (e.altKey === true) {
                            //console.log('altKey');
                            $scope.goSchedulerButtons('up');
                        } else {
                            $scope.goSchedulerButtons('compress');
                        }
                    } else if (e.originalEvent.deltaY > 0) { //zoom out
                        if (e.shiftKey === true) {
                            $scope.goSchedulerButtons('downdown');
                        } else if (e.ctrlKey === true) {
                            //console.log('ctrlKey');
                            //$scope.goSchedulerButtons('up');
                        } else if (e.altKey === true) {
                            //console.log('altKey');
                            $scope.goSchedulerButtons('down');
                        } else {
                            $scope.goSchedulerButtons('expand');
                        }
                    }
                });

                var checkBreakpoint = function (event, data) {
                    //console.log('checking!!!!!!!!!!!!!!!!!!!!!!');
                    if (responsive.current === 'xx') {
                        $scope.model.eventTemplate = '<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(description)#</small></p></div>';
                    } else {
                        $scope.model.eventTemplate = '<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(start, "HH:mm")#</small>-<small>#=kendo.toString(end, "HH:mm")#</small><br><small>#=kendo.toString(description)#</small></p></div>';
                    }

                    //if (angular.isDefined($scope.model.scheduler) && angular.isFunction($scope.model.scheduler.refresh)) {
                    //    console.log('it clearly IS A FUNCTION!');
                    //    $scope.model.scheduler.dataSource.read();
                    //    $scope.model.scheduler.refresh();
                    //}
                };

                checkBreakpoint();

                $rootScope.$on('event:responsive-changed', checkBreakpoint);

                var buildScheduler = function () {
                    var tempDims = [];
                    var i = 0;

                    var nnnn = 0;

                    // Refer to kendo.scheduler.timelineview.js to see origial implementation.
                    $window.timelineQuarter = kendo.ui.TimelineView.extend({
                    //$window.timelineQuarter = kendo.ui.TimelineMonthView.extend({
                        nextDate: function () {
                            //console.log('firstDayOfNextMonth(this.startDate()): ' + firstDayOfNextMonth(this.startDate()));

                            return firstDayOfNextMonth(this.startDate());
                        },
                        previousDate: function () {
                            //console.log('firstDayOfPreviousMonth(this.startDate()): ' + firstDayOfPreviousMonth(this.startDate()));

                            return firstDayOfPreviousMonth(this.startDate());
                        },
                        options: {
                            selectedDateFormat: "{0:D} - {1:D}"
                        },
                        name: "timelineQuarter",
                        //calculateDateRange: function () {
                        //    //create a range of dates to be shown within the view
                        //    var start = firstDayOfThisMonth(this.options.date);
                        //    var dates = [];

                        //    //console.log('this.options.date: ' + this.options.date);
                        //    //console.log('firstDayOfThisMonth(this.options.date): ' + firstDayOfThisMonth(this.options.date));
                        //    //console.log('firstDayOfNextMonth(start): ' + firstDayOfNextMonth(start));

                        //    for (var idx = 0; idx < 3; idx++) {
                        //        if (start.getDate() === 1)
                        //            dates.push(start);

                        //        start = firstDayOfNextMonth(start);
                        //    }

                        //    console.dir(dates);

                        //    this._render(dates);
                        //},
                        calculateDateRange: function () {
                            var selectedDate = this.options.date,
                                start = kendo.date.dayOfWeek(selectedDate, 1, -1),
                                //start = firstDayOfThisMonth(this.options.date),
                                //end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear() + 2, selectedDate.getMonth()), 1, 1), dates = [];
                                end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear(), selectedDate.getMonth()+2), 1, 1), dates = [];
                            //          end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 4), 1, 1), dates = [];

                            while (start < end) {
                                dates.push(start);
                                start = kendo.date.nextDay(start);
                            }

                            this._render(dates);
                        },

                        //// make proper slots
                        //_calculateSlotRanges: function () {
                        //    var dates = this._dates;
                        //    var slotStartTime = this.startTime();
                        //    var slotEndTime = this.endTime();

                        //    console.log('dates');
                        //    console.dir(dates);

                        //    console.log('slotStartTime: ' + slotStartTime);
                        //    console.log('slotEndTime: ' + slotEndTime);

                        //    slotEndTime = kendo.date.getMilliseconds(slotEndTime);
                        //    slotStartTime = kendo.date.getMilliseconds(slotStartTime);

                        //    if (slotEndTime === slotStartTime) {
                        //        slotEndTime += kendo.date.MS_PER_DAY - 1;
                        //    } else if (slotEndTime < slotStartTime) {
                        //        slotEndTime += kendo.date.MS_PER_DAY;
                        //    }

                        //    var slotRanges = [];

                        //    // week long slots?
                        //    for (var i = 0; i < dates.length; i += 1) {
                        //        var rangeStart = kendo.date.getDate(dates[i]);
                        //        kendo.date.setTime(rangeStart, slotStartTime);
                        //        var rangeEnd = kendo.date.getDate(dates[i]);
                        //        kendo.date.setTime(rangeEnd, slotEndTime);

                        //        slotRanges.push({
                        //            start: kendo.date.toUtcTime(rangeStart),
                        //            end: kendo.date.toUtcTime(rangeEnd)
                        //        });
                        //    }

                        //    console.log('slotRanges');
                        //    console.dir(slotRanges);
                        //},
                        //// Use our own extension to TimeLineGroupedView where ticks longer than 1 day isn't a problem.
                        //// This means grouping by dates isn't an option.
                        _getGroupedView: function () {
                            return new timelineGroupedYearView(this);
                        },
                        //// This is the method that adds the internal time-slots. It has been copy-pasted and modified to make the slots end at the last day of the month instead of just spanning 1 tick
                        _addTimeSlotToCollection: function (group, cells, cellIndex, cellOffset, dateIndex, time, interval) {
                            //console.log('cellIndex: ' + cellIndex);
                            //console.log('cellOffset: ' + cellOffset);
                            //console.dir(cells);

                            //console.log('_addTimeSlotToCollection');

                            var cell = cells[cellIndex + cellOffset];
                            var collection = group.getTimeSlotCollection(0);
                            var currentDate = this._dates[dateIndex];
                            var currentTime = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                            var start = currentTime + time;

                            // This is the part of this function that I messed with
                            var newEnd = lastDayOfThisMonth(new Date(start));
                            var end = kendo.date.toUtcTime(newEnd);

                            cell.setAttribute('role', 'gridcell');
                            cell.setAttribute('aria-selected', false);
                            //console.log('timeslot start: ' + start);
                            //console.log('timeslot end: ' + end);
                            collection.addTimeSlot(cell, start, end, true);
                            //console.log('collection - Quarter');
                            //console.dir(collection);
                        },
                    });

                    // Refer to kendo.scheduler.timelineview.js to see origial implementation.
                    $window.timelineYear = kendo.ui.TimelineView.extend({
                    //$window.timelineYear = kendo.ui.TimelineMonthView.extend({
                        //// Use our own extension to TimeLineGroupedView where ticks longer than 1 day isn't a problem.
                        //// This means grouping by dates isn't an option.
                        _getGroupedView: function () {
                            return new timelineGroupedYearView(this);
                        },
                        name: "timelineYear",
                        options: {
                            selectedDateFormat: "{0:D} - {1:D}"
                        },
                        nextDate: function () {
                            return firstDayOfNextMonth(this.startDate());
                        },
                        previousDate: function () {
                            return firstDayOfPreviousMonth(this.startDate());
                        },
                        //calculateDateRange: function () {
                        //    //create a range of dates to be shown within the view
                        //    var start = firstDayOfThisMonth(this.options.date);
                        //    var dates = [];

                        //    for (var idx = 0; idx < 12; idx++) {
                        //        if (start.getDate() === 1)
                        //            dates.push(start);

                        //        start = firstDayOfNextMonth(start);
                        //    }

                        //    this._render(dates);
                        //},
                        calculateDateRange: function () {
                            var selectedDate = firstDayOfThisMonth(this.options.date),
                                start = kendo.date.dayOfWeek(selectedDate, 1, -1),
                                //start = firstDayOfThisMonth(this.options.date),
                                //end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear() + 2, selectedDate.getMonth()), 1, 1), dates = [];
                                end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear() + 1, selectedDate.getMonth()), 1, 1), dates = [];
                            //          end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 4), 1, 1), dates = [];

                            while (start < end) {
                                dates.push(start);
                                start = kendo.date.nextDay(start);
                            }

                            this._render(dates);
                        },
                        //// make proper slots
                        //_calculateSlotRanges: function () {
                        //    var dates = this._dates;
                        //    var slotStartTime = this.startTime();
                        //    var slotEndTime = this.endTime();

                        //    console.log('dates');
                        //    console.dir(dates);

                        //    console.log('slotStartTime: ' + slotStartTime);
                        //    console.log('slotEndTime: ' + slotEndTime);

                        //    slotEndTime = kendo.date.getMilliseconds(slotEndTime);
                        //    slotStartTime = kendo.date.getMilliseconds(slotStartTime);

                        //    if (slotEndTime === slotStartTime) {
                        //        slotEndTime += kendo.date.MS_PER_DAY - 1;
                        //    } else if (slotEndTime < slotStartTime) {
                        //        slotEndTime += kendo.date.MS_PER_DAY;
                        //    }

                        //    var slotRanges = [];

                        //    // week long slots?
                        //    for (var i = 0; i < dates.length; i += 7) {
                        //        var rangeStart = kendo.date.getDate(dates[i]);
                        //        kendo.date.setTime(rangeStart, slotStartTime);
                        //        var rangeEnd = kendo.date.getDate(dates[i]);
                        //        kendo.date.setTime(rangeEnd, slotEndTime);

                        //        slotRanges.push({
                        //            start: kendo.date.toUtcTime(rangeStart),
                        //            end: kendo.date.toUtcTime(rangeEnd)
                        //        });
                        //    }

                        //    console.log('slotRanges');
                        //    console.dir(slotRanges);

                        //    this._slotRanges = slotRanges;
                        //},
                        _layout: function (dates) {
                            var columns = [];
                            var weekColumn = {};
                            var that = this;
                            var rows = [{ text: that.options.messages.defaultRowText }];
                            var groupedView = that._groupedView;

                            // loop months over weeks
                            for (var idx = 0; idx < dates.length; idx++) {
                                var info = getWeekInfo(dates[idx]);

                                // has year column already?
                                for (var yIdx = 0; yIdx < columns.length; yIdx++) {
                                    if (columns[yIdx].yearNum === info.year) break;
                                }

                                if (yIdx >= columns.length) {
                                    var yr = {
                                        //                  text: yearNumTemplate({ date: dates[idx] }),
                                        text: info.year,
                                        className: 'k-slot-cell',
                                        columns: [],
                                        weeks: [],
                                        yearNum: info.year,
                                        colspan: 1
                                    };

                                    columns.push(yr);
                                    yIdx = columns.length - 1;
                                } else {
                                    columns[yIdx].colspan++;
                                }

                                // has month column already?
                                for (var mIdx = 0; mIdx < columns[yIdx].columns.length; mIdx++) {
                                    if (columns[yIdx].columns[mIdx].monthNum === info.month) break;
                                }

                                if (mIdx >= columns[yIdx].columns.length) {
                                    var mn = {
                                        text: capitalizeFirstLetter(kendo.toString(new Date(2018, info.month, 1), 'MMM')),
                                        className: 'k-slot-cell',
                                        columns: [],
                                        monthNum: info.month,
                                        colspan: 1
                                    };

                                    columns[yIdx].columns.push(mn);
                                    mIdx = columns[yIdx].columns.length - 1;
                                } else {
                                    columns[yIdx].columns[mIdx].colspan++;
                                }

                                // has timeslot already?
                                for (var wIdx = 0; wIdx < columns[yIdx].weeks.length; wIdx++) {
                                    if (columns[yIdx].weeks[wIdx] === info.week) break;
                                }

                                if (wIdx >= columns[yIdx].weeks.length) {
                                    var ts = {
                                        //text: weekNumTemplate({ date: dates[idx] }),
                                        text: info.week,
                                        className: 'k-slot-cell',
                                        colspan: 1,
                                        weekNum: info.week
                                    }

                                    columns[yIdx].weeks.push(info.week);
                                    columns[yIdx].columns[mIdx].columns.push(ts);
                                    weekColumn = ts;
                                } else {
                                    weekColumn.colspan++;
                                }
                            }

                            var resources = this.groupedResources;

                            if (resources.length) {
                                if (this._groupOrientation() === 'vertical') {
                                    rows = groupedView._createRowsLayout(resources, null, this.groupHeaderTemplate, columns);
                                    columns = groupedView._createVerticalColumnsLayout(resources, null, this.groupHeaderTemplate, columns);
                                } else {
                                    columns = groupedView._createColumnsLayout(resources, columns, this.groupHeaderTemplate, columns);
                                }
                            }
                            return {
                                columns: columns,
                                rows: rows
                            };
                        },
                        _groups: function () {
                            var groupCount = this._groupCount();
                            var dates = this._dates;
                            this.groups = [];

                            for (var idx = 0; idx < groupCount; idx++) {
                                var view = this._addResourceView(idx);
                                var start = dates[0];
                                var end = dates[dates.length - 1 || 0];
                                var startTime = kendo.date.getMilliseconds(this.startTime());
                                var endTime = kendo.date.getMilliseconds(this.endTime());

                                //console.log('start slotime: ' + this.startTime());
                                //console.log('end slotime: ' + this.endTime());

                                if (startTime !== 0 && endTime <= startTime) {
                                    start = kendo.date.getDate(start);
                                    kendo.date.setTime(start, startTime);
                                    end = kendo.date.getDate(end);
                                    kendo.date.setTime(end, endTime);
                                }

                                view.addTimeSlotCollection(start, kendo.date.addDays(end, 1));
                            }

                            this._timeSlotGroups(groupCount);
                        },
                        _timeSlotGroups: function (groupCount) {
                            var tableRows = this.content.find('tr');
                            tableRows.attr('role', 'row');
                            this._groupedView._addTimeSlotsCollections(groupCount, tableRows);
                        }
                        ////// This is the method that adds the internal time-slots. It has been copy-pasted and modified to make the slots end at the last day of the month instead of just spanning 1 tick
                        //_addTimeSlotToCollection: function (group, cells, cellIndex, cellOffset, dateIndex, time, interval) {
                        //    var cell = cells[cellIndex + cellOffset];
                        //    var collection = group.getTimeSlotCollection(0);
                        //    var currentDate = this._dates[dateIndex];
                        //    var currentTime = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
                        //    var start = currentTime + time;

                        //    // This is the part of this function that I messed with
                        //    var newEnd = lastDayOfThisMonth(new Date(start));
                        //    var end = kendo.date.toUtcTime(newEnd);


                        //    cell.setAttribute('role', 'gridcell');
                        //    cell.setAttribute('aria-selected', false);
                        //    collection.addTimeSlot(cell, start, end, true);
                        //    //console.log('collection - Year');
                        //    //console.dir(collection);
                        //},
                    });

                    $window.timelineWeekYear = kendo.ui.TimelineView.extend({
                        _getGroupedView: function () {
                            return new timelineGroupedWeeksView(this);
                        },
                        name: "timelineWeekYear",
                        options: {
                            columnWidth: 5,
                            currentTimeMarker: true,
                            height: 700
                        },
                        nextDate: function () {
                            //var s = firstDayOfNextMonth(this.startDate());
                            //var s = firstDayOfNextMonth(this.options.date);
                            return firstDayOfNextMonth(this.options.date);
                            //return s;
                            //return kendo.date.dayOfWeek(kendo.date.addDays(s, -30), 1, -1);
                        },
                        previousDate: function () {
                            //var s = firstDayOfPreviousMonth(this.startDate());
                            //var s = firstDayOfPreviousMonth(this.options.date);
                            return firstDayOfPreviousMonth(this.options.date);
                            //return s;
                            //return kendo.date.dayOfWeek(kendo.date.addDays(s, -30), 1, -1);
                        },
                        //previousDate: function () {
                        //    var s = this.startDate();
                        //    return kendo.date.dayOfWeek(kendo.date.addDays(s, -30), 1, -1);
                        //},
                        //// advance by.... one month?
                        //nextDate: function () {
                        //    var s = this.startDate();
                        //    return kendo.date.dayOfWeek(kendo.date.addDays(s, 30), 1, -1);
                        //},
                        // make it one year, from the monday starting with selected date
                        calculateDateRange: function () {
                            //var selectedDate = this.options.date,
                            var selectedDate = firstDayOfThisMonth(this.options.date),
                                start = kendo.date.dayOfWeek(selectedDate, 1, -1),
                                end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear() + 2, selectedDate.getMonth()), 1, 1), dates = [];
                            //          end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 4), 1, 1), dates = [];
                                //end = kendo.date.dayOfWeek(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 2), 1, 1), dates = [];

                            while (start < end) {
                                dates.push(start);
                                start = kendo.date.nextDay(start);
                            }

                            this._render(dates);
                        },
                        //// make proper slots
                        //_calculateSlotRanges: function () {
                        //    var dates = this._dates;
                        //    var slotStartTime = this.startTime();
                        //    var slotEndTime = this.endTime();

                        //    //console.log('dates');
                        //    //console.dir(dates);

                        //    //console.log('slotStartTime: ' + slotStartTime);
                        //    //console.log('slotEndTime: ' + slotEndTime);

                        //    slotEndTime = kendo.date.getMilliseconds(slotEndTime);
                        //    slotStartTime = kendo.date.getMilliseconds(slotStartTime);

                        //    if (slotEndTime === slotStartTime) {
                        //        slotEndTime += kendo.date.MS_PER_DAY - 1;
                        //    } else if (slotEndTime < slotStartTime) {
                        //        slotEndTime += kendo.date.MS_PER_DAY;
                        //    }

                        //    var slotRanges = [];

                        //    //// week long slots?
                        //    //for (var i = 0; i < dates.length; i += 7) {
                        //    for (var i = 0; i < dates.length; i++) {
                        //        var rangeStart = kendo.date.getDate(dates[i]);
                        //        kendo.date.setTime(rangeStart, slotStartTime);
                        //        var rangeEnd = kendo.date.getDate(dates[i]);
                        //        kendo.date.setTime(rangeEnd, slotEndTime);

                        //        slotRanges.push({
                        //            start: kendo.date.toUtcTime(rangeStart),
                        //            end: kendo.date.toUtcTime(rangeEnd)
                        //        });
                        //    }

                        //    //console.log('slotRanges');
                        //    //console.dir(slotRanges);

                        //    this._slotRanges = slotRanges;
                        //},
                        _layout: function (dates) {
                            var columns = [];
                            var weekColumn = {};
                            var that = this;
                            var rows = [{ text: that.options.messages.defaultRowText }];
                            var groupedView = that._groupedView;

                            /*      var weekNumTemplate = kendo.template('<span class=\'k-link k-nav-day\'>#=kendo.date.weekInYear(date)#</span>');
                            var monthNumTemplate = kendo.template('<span class=\'k-link k-nav-day\'>#=kendo.format(\'{0:MMM}\', date)#</span>');
                            var yearNumTemplate = kendo.template('<span class=\'k-link k-nav-day\'>#=kendo.format(\'{0:yyyy}\', date)#</span>');
                            var weekNumTemplate = kendo.template('<span class=\'k-link k-nav-day\'>#=getWeekInfo(date).week#</span>');
                            var monthNumTemplate = kendo.template('<span class=\'k-link k-nav-day\'>#=getWeekInfo(date).month#</span>');
                            var yearNumTemplate = kendo.template('<span class=\'k-link k-nav-day\'>#=getWeekInfo(date).year#</span>');*/

                            // loop months over weeks
                            for (var idx = 0; idx < dates.length; idx++) {
                                var info = getWeekInfo(dates[idx]);

                                // has year column already?
                                for (var yIdx = 0; yIdx < columns.length; yIdx++) {
                                    if (columns[yIdx].yearNum === info.year) break;
                                }

                                if (yIdx >= columns.length) {
                                    var yr = {
                                        //                  text: yearNumTemplate({ date: dates[idx] }),
                                        text: info.year,
                                        className: 'k-slot-cell',
                                        columns: [],
                                        weeks: [],
                                        yearNum: info.year,
                                        colspan: 1
                                    };

                                    columns.push(yr);
                                    yIdx = columns.length - 1;
                                } else {
                                    columns[yIdx].colspan++;
                                }

                                // has month column already?
                                for (var mIdx = 0; mIdx < columns[yIdx].columns.length; mIdx++) {
                                    if (columns[yIdx].columns[mIdx].monthNum === info.month) break;
                                }

                                if (mIdx >= columns[yIdx].columns.length) {
                                    var mn = {
                                        text: capitalizeFirstLetter(kendo.toString(new Date(2018, info.month, 1), 'MMM')),
                                        className: 'k-slot-cell',
                                        columns: [],
                                        monthNum: info.month,
                                        colspan: 1
                                    };

                                    columns[yIdx].columns.push(mn);
                                    mIdx = columns[yIdx].columns.length - 1;
                                } else {
                                    columns[yIdx].columns[mIdx].colspan++;
                                }

                                // has timeslot already?
                                for (var wIdx = 0; wIdx < columns[yIdx].weeks.length; wIdx++) {
                                    if (columns[yIdx].weeks[wIdx] === info.week) break;
                                }

                                if (wIdx >= columns[yIdx].weeks.length) {
                                    var ts = {
                                        //text: weekNumTemplate({ date: dates[idx] }),
                                        text: info.week,
                                        className: 'k-slot-cell',
                                        colspan: 1,
                                        weekNum: info.week
                                    }

                                    columns[yIdx].weeks.push(info.week);
                                    columns[yIdx].columns[mIdx].columns.push(ts);
                                    weekColumn = ts;
                                } else {
                                    weekColumn.colspan++;
                                }
                            }

                            var resources = this.groupedResources;

                            if (resources.length) {
                                if (this._groupOrientation() === 'vertical') {
                                    rows = groupedView._createRowsLayout(resources, null, this.groupHeaderTemplate, columns);
                                    columns = groupedView._createVerticalColumnsLayout(resources, null, this.groupHeaderTemplate, columns);
                                } else {
                                    columns = groupedView._createColumnsLayout(resources, columns, this.groupHeaderTemplate, columns);
                                }
                            }
                            return {
                                columns: columns,
                                rows: rows
                            };
                        },
                        _groups: function () {
                            var groupCount = this._groupCount();
                            var dates = this._dates;
                            this.groups = [];

                            for (var idx = 0; idx < groupCount; idx++) {
                                var view = this._addResourceView(idx);
                                var start = dates[0];
                                var end = dates[dates.length - 1 || 0];
                                var startTime = kendo.date.getMilliseconds(this.startTime());
                                var endTime = kendo.date.getMilliseconds(this.endTime());

                                //console.log('start slotime: ' + this.startTime());
                                //console.log('end slotime: ' + this.endTime());

                                if (startTime !== 0 && endTime <= startTime) {
                                    start = kendo.date.getDate(start);
                                    kendo.date.setTime(start, startTime);
                                    end = kendo.date.getDate(end);
                                    kendo.date.setTime(end, endTime);
                                }

                                view.addTimeSlotCollection(start, kendo.date.addDays(end, 1));
                            }

                            this._timeSlotGroups(groupCount);
                        },
                        _timeSlotGroups: function (groupCount) {
                            var tableRows = this.content.find('tr');
                            tableRows.attr('role', 'row');
                            this._groupedView._addTimeSlotsCollections(groupCount, tableRows);
                        }
                    });

                    // Refer to kendo.scheduler.timelineview.js to see origial implementation.
                    var timelineGroupedYearView = kendo.ui.scheduler.TimelineGroupedView.extend({
                        //init: function (view) {
                        //    var that = this;
                        //    kendo.ui.scheduler.TimelineGroupedView.fn.init.call(that, view);
                        //},
                        /*
                         * Method responsible for rendering the cells of the scheduler view.
                         * This is what would fail if the tick length exceeded one day.
                         * The original one was a whole bunch of for-loops, one of which wouldn't be entered if MS_PER_DAY/tick-length rounded to nearest integer was 0.
                         *
                         * So yeah, we are ignoring a lot of the options-API, and all the weird ways you can group the dates and events.
                         * Anything but a horizontal timeline of events, vertically grouped by resources will fail miserably unless this function is modified.
                         * This version also doesn't add the k-nonwork-hour and k-today classes, because they aren't relevant in a year view. */
                        //_addContent: function (dates, columnCount, groupsCount, rowCount, start, end, slotTemplate, isVerticalGrouped) {
                        //    var view = this._view;
                        //    var html = '';

                        //    for (var rowIdx = 0; rowIdx < rowCount; rowIdx++) {
                        //        html += '<tr>';
                        //        for (var idx = 0, length = columnCount; idx < length; idx++) {
                        //            var resources = function (groupIndex) {
                        //                return function () {
                        //                    return view._resourceBySlot({ groupIndex: groupIndex });
                        //                };
                        //            };

                        //            html += '<td>';
                        //            html += slotTemplate({
                        //                date: dates[idx],
                        //                resources: resources(rowIdx),
                        //            });

                        //            html += '</td>';
                        //        }
                        //        html += '</tr>';
                        //    }
                        //    //console.dir(view);
                        //    //console.dir(html);
                        //    return html;
                        //},
                        _addContent: function (dates, columnCount, groupsCount, rowCount, start, end, slotTemplate, isVerticalGrouped) {
                            var view = this._view;
                            var html = '';

                            for (var rowIdx = 0; rowIdx < rowCount; rowIdx++) {
                                html += '<tr>';
                                for (var groupIdx = 0; groupIdx < groupsCount; groupIdx++) {
                                    for (var idx = 0; idx < dates.length; idx += daysInMonth(12)) {
                                        html += '<td> </td>';
                                    }
                                }
                                html += '</tr>';
                            }
                            return html;
                        },
                        _addTimeSlotsCollections: function (groupCount, tableRows) {
                            var view = this._view;

                            for (var groupIndex = 0; groupIndex < groupCount; groupIndex++) {
                                var cells = tableRows[groupIndex].children;
                                var day = view._startDate;
                                var ci = 0;
                                var collection = view.groups[groupIndex].getTimeSlotCollection(0);

                                while (day < view._endDate) {
                                    cells[ci].setAttribute('role', 'gridcell');
                                    cells[ci].setAttribute('aria-selected', false);

                                    collection.addTimeSlot(cells[ci], day, kendo.date.addDays(day, daysInMonth(12)), true);
                                    day = kendo.date.addDays(day, daysInMonth(12));
                                    ci++;
                                }
                            }
                        },
                        _hideHeaders: function () {
                            var view = this._view;
                            view.timesHeader.find('table tr:last').hide();
                            view.datesHeader.find('table tr:last').hide();
                        }
                    });

                    var timelineGroupedWeeksView = kendo.ui.scheduler.TimelineGroupedView.extend({
                        _addContent: function (dates, columnCount, groupsCount, rowCount, start, end, slotTemplate, isVerticalGrouped) {
                            var view = this._view;
                            var html = '';

                            for (var rowIdx = 0; rowIdx < rowCount; rowIdx++) {
                                html += '<tr>';
                                for (var groupIdx = 0; groupIdx < groupsCount; groupIdx++) {
                                    for (var idx = 0; idx < dates.length; idx += 7) {
                                        html += '<td> </td>';
                                    }
                                }
                                html += '</tr>';
                            }
                            return html;
                        },
                        _addTimeSlotsCollections: function (groupCount, tableRows) {
                            var view = this._view;

                            for (var groupIndex = 0; groupIndex < groupCount; groupIndex++) {
                                var cells = tableRows[groupIndex].children;
                                var day = view._startDate;
                                var ci = 0;
                                var collection = view.groups[groupIndex].getTimeSlotCollection(0);

                                while (day < view._endDate) {
                                    cells[ci].setAttribute('role', 'gridcell');
                                    cells[ci].setAttribute('aria-selected', false);

                                    collection.addTimeSlot(cells[ci], day, kendo.date.addDays(day, 7), true);
                                    day = kendo.date.addDays(day, 7);
                                    ci++;
                                }
                            }
                        },
                        // don't hide headers
                        _hideHeaders: function () {
                            //      var view = this._view;
                            //      view.timesHeader.find('table tr:last').hide();
                            //      view.datesHeader.find('table tr:last').hide();
                        }
                    });

                    //console.log('Check out these Dims');
                    angular.forEach($scope.model.schedulerDataSourceData, function (data) {
                        if (angular.isDefined(data) && angular.isDefined(data.dims) && data.dims.length > 0) {
                            angular.forEach(data.dims, function (dim) {
                                if (angular.isDefined(dim)) {
                                    if (i < 1) {
                                        //console.log('Check out this Dim');
                                        //console.dir(dim);
                                        //i++;
                                    }
                                    var addDim = true;
                                    for (var d = 0; d < tempDims.length; d++) {
                                        if (Object.keys(dim)[0] === tempDims[d]) {
                                            addDim = false;
                                            break;
                                        }
                                    }

                                    if (addDim) {
                                        tempDims.push(Object.keys(dim)[0]);
                                    }

                                    if (Array.isArray(dim[Object.keys(dim)])) {
                                        //console.log('it is array');
                                        //console.dir(Object.keys(dim)[0]);
                                        //console.dir(dim[Object.keys(dim)[0]]);
                                        //console.dir(data[Object.keys(dim)]);
                                        data[Object.keys(dim)[0]] = [];
                                        angular.copy(dim[Object.keys(dim)[0]], data[Object.keys(dim)[0]]);
                                        //data[Object.keys(dim)[0]] = dim[Object.keys(dim)[0]];
                                        //data[Object.keys(dim)[0]] = [];
                                        //data[Object.keys(dim)[0]] = dim[Object.keys(dim)[0]];
                                        //angular.forEach(dim[Object.keys(dim)], function (index) {
                                        //    data[Object.keys(dim)[0]].push(dim[Object.keys(dim)][index]);
                                        //});
                                    } else {
                                        data[Object.keys(dim)[0]] = dim[Object.keys(dim)[0]];
                                    }
                                }
                            });
                        }
                    });

                    //console.log('All the schedulerDataSources');
                    //console.dir($scope.model.schedulerDataSourceData);
                    //console.dir($scope.model.schedulerDataSourceGroups);
                    //console.dir($scope.model.schedulerDataSourceResources);

                    $scope.schemaFields = {};

                    $scope.schemaFields['id'] = { from: 'item_id', type: 'number' };
                    $scope.schemaFields['title'] = { from: 'item_name', type: 'string', defaultValue: 'No title', validation: { required: true } };
                    $scope.schemaFields['start'] = { from: 'start_dt', type: 'date' };
                    $scope.schemaFields['end'] = { from: 'end_dt', type: 'date' };

                    angular.forEach(tempDims, function (data) {
                        if (angular.isDefined(data)) {
                            //$scope.schemaFields[data] = { from: data, type: 'string', defaultValue: '1', validation: { required: true } };
                            //$scope.schemaFields[Object.keys(data)[0]] = { from: Object.keys(data)[0], type: 'number', defaultValue: '1', validation: { required: true } };
                        }
                    });
                    //$scope.schemaFields['ownerId'] = { from: 'portal_user_keyno', type: 'number', defaultValue: '1', validation: { required: true } };
                    //$scope.schemaFields['equipmentId'] = { from: 'equipment_keyno', type: 'number', defaultValue: '1', validation: { required: true } };

                    $scope.model.schedulerDataSource = new kendo.data.SchedulerDataSource({
                        batch: false,
                        transport: {
                            read: function (options) {
                                //console.log('OPTIONS');
                                //console.dir(options);
                                //options.success(angular.copy($scope.listItems));
                                options.success($scope.model.schedulerDataSourceData);
                                //options.success($scope.listItems);
                                //options.success(options.data);
                            },
                            create: function (options) {
                                options.success(options.data);
                            },
                            update: function (options) {
                                console.log('datasrc update');
                                angular.forEach($scope.model.schedulerDataSourceData, function (data) {
                                    if (angular.isDefined(data) && angular.isDefined(options) && angular.isDefined(options.data) && angular.isDefined(options.data.start) && angular.isDefined(options.data.end) && data.item_id === options.data.id) {
                                        data.start_dt = options.data.start.toISOString().replace('T', ' ').replace('Z', '');
                                        data.end_dt = options.data.end.toISOString().replace('T', ' ').replace('Z', '');
                                    }
                                });
                                options.success(options.data);
                            },
                            destroy: function (options) {
                                options.success(options.data);
                            }
                        },
                        schema: {
                            model: {
                                id: 'id',
                                fields: $scope.schemaFields
                            }
                        }
                    });

                    var tempGroups = { resources: [], orientation: "vertical" };

                    angular.forEach($scope.model.schedulerDataSourceGroups, function (data) {
                        if (angular.isDefined(data)) {
                            tempGroups.resources.push(data);
                        }
                    });

                    var tempResources = [];

                    angular.forEach($scope.model.schedulerDataSourceGroups, function (group) {
                        if (angular.isDefined(group)) {
                            tempResources.push({
                                field: group,
                                //title: group,
                                dataColorField: "color",
                                dataTextField: "item_name",
                                dataValueField: "item_id",
                                multiple: false,
                                dataSource: []
                            });
                            var currentGroupIndex = tempResources.length - 1;
                            angular.forEach($scope.model.schedulerDataSourceResources, function (res) {
                                if (angular.isDefined(res) && res.item_dim === group) {
                                    tempResources[currentGroupIndex].dataSource.push(res);
                                }
                            });
                        }
                    });

                    if (angular.isUndefined($scope.setup)) {
                        $scope.setup = {};
                    }

                    //THIS SETS THE DEFAULT VIEW TO 'Week'
                    if (angular.isUndefined($scope.setup.selectedview)) {
                        if (angular.isDefined($scope.view) && $scope.view === 'timeline') {
                            $scope.setup.selectedview === 'timeline';
                        } else {
                            $scope.setup.selectedview = 'week';
                        }
                    } else {
                        switch ($scope.setup.selectedview) {
                            case $scope.setup.selectedview === 'timeline':
                            case $scope.setup.selectedview === 'timelineWorkWeek':
                            case $scope.setup.selectedview === 'timelineWeek':
                            case $scope.setup.selectedview === 'timelineMonth':
                            case $scope.setup.selectedview === 'timelineQuarter':
                            case $scope.setup.selectedview === 'timelineYear':
                            case $scope.setup.selectedview === 'timelineWeekYear':
                            case $scope.setup.selectedview === 'day':
                            case $scope.setup.selectedview === 'workWeek':
                            case $scope.setup.selectedview === 'week':
                            case $scope.setup.selectedview === 'month':
                            case $scope.setup.selectedview === 'year':
                                break;
                            default:
                                if (angular.isDefined($scope.view) && $scope.view === 'timeline') {
                                    $scope.setup.selectedview === 'timeline';
                                } else {
                                    $scope.setup.selectedview = 'week';
                                }
                        }
                    }

                    var tempViews = [];

                    if (angular.isDefined($scope.view) && $scope.view === 'timeline') {
                        tempViews = [
                            {
                                title: 'Day',
                                type: 'timeline',
                                selected: $scope.setup.selectedview === 'timeline' ? true : false,
                                eventHeight: 50,
                                columnWidth: $scope.setup.columnWidthDefault,
                                minorTickCount: $scope.setup.minorTickDefaultDay,
                                majorTick: $scope.setup.majorTickDefaultDay,
                                startTime: new Date(1901, 1, 1, 0, 0, 0),
                                endTime: new Date(1901, 1, 1, 23, 59, 59),
                                workDayStart: new Date("2013/6/6 08:00"),
                                workDayEnd: new Date("2013/6/6 16:00")
                                //group: { orientation: 'vertical', resources: ['OwnerName']}
                            },
                            {
                                title: 'Work Week',
                                type: 'timelineWorkWeek',
                                selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false,
                                eventHeight: 50,
                                minorTickCount: $scope.setup.minorTickDefaultWeek,
                                majorTick: $scope.setup.majorTickDefaultWeek,
                                startTime: new Date(1901, 1, 1, 0, 0, 0),
                                endTime: new Date(1901, 1, 1, 23, 59, 59),
                                workDayStart: new Date("2013/6/6 08:00"),
                                workDayEnd: new Date("2013/6/6 16:00")
                            },
                            {
                                title: 'Week',
                                type: 'timelineWeek',
                                selected: $scope.setup.selectedview === 'timelineWeek' ? true : false,
                                eventHeight: 50,
                                minorTickCount: $scope.setup.minorTickDefaultWeek,
                                majorTick: $scope.setup.majorTickDefaultWeek,
                                startTime: new Date(1901, 1, 1, 0, 0, 0),
                                endTime: new Date(1901, 1, 1, 23, 59, 59),
                                workDayStart: new Date("2013/6/6 08:00"),
                                workDayEnd: new Date("2013/6/6 16:00")
                            },
                            {
                                title: 'Month',
                                type: 'timelineMonth',
                                selected: $scope.setup.selectedview === 'timelineMonth' ? true : false,
                                eventHeight: 50,
                                minorTickCount: $scope.setup.minorTickDefaultMonth,
                                majorTick: $scope.setup.majorTickDefaultMonth
                                //startTime: new Date(1901, 1, 1, 0, 0, 0),
                                //endTime: new Date(2201, 1, 1, 23, 59, 59),
                                //workDayStart: new Date("2013/6/6 08:00"),
                                //workDayEnd: new Date("2013/6/6 16:00")
                            },
                            //{
                            //    title: 'Quarter',
                            //    type: 'timelineQuarter',
                            //    selected: $scope.setup.selectedview === 'timelineQuarter' ? true : false,
                            //    eventHeight: 50,
                            //    minorTickCount: $scope.setup.minorTickDefaultQuarterYear,
                            //    majorTick: $scope.setup.majorTickDefaultQuarterYear
                            //    //title: "yearview, yay!",
                            //    // Tick lenghts have been hard-coded into the year-view.
                            //},
                            //{
                            //    title: 'Year',
                            //    type: 'timelineYear',
                            //    selected: $scope.setup.selectedview === 'timelineYear' ? true : false,
                            //    eventHeight: 50,
                            //    minorTickCount: $scope.setup.minorTickDefaultQuarterYear,
                            //    majorTick: $scope.setup.majorTickDefaultQuarterYear
                            //    //title: "yearview, yay!",
                            //    // Tick lenghts have been hard-coded into the year-view.
                            //},
                            {
                                //title: 'WeekYear',
                                title: 'Year',
                                type: 'timelineWeekYear',
                                selected: $scope.setup.selectedview === 'timelineWeekYear' ? true : false,
                                eventHeight: 50,
                                minorTickCount: $scope.setup.minorTickDefaultQuarterYear,
                                majorTick: $scope.setup.majorTickDefaultQuarterYear
                                //title: "yearview, yay!",
                                // Tick lenghts have been hard-coded into the year-view.
                            },
                        ];
                    } else if (angular.isDefined($scope.view) && $scope.view === 'both') {
                        tempViews = [
                            { type: 'timeline', selected: $scope.setup.selectedview === 'timeline' ? true : false, eventHeight: 50, columnWidth: 50 },
                            { type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false, eventHeight: 50, columnWidth: 50 },
                            { type: 'timelineWeek', selected: $scope.setup.selectedview === 'timelineWeek' ? true : false, eventHeight: 50, columnWidth: 50 },
                            //{ type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false },
                            { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                            { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                            { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                            //{ type: "month", selected: true, eventsPerDay: 6, eventHeight: "auto" }
                            //{ type: "month", selected: true, eventsPerDay: 8, adaptiveSlotHeight: true, eventSpacing: 5 }
                            { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false, eventHeight: 50 },
                            { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                        ];
                    } else {
                        tempViews = [
                            { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                            { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                            { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                            { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false, eventHeight: 'auto' },
                            { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                        ];
                    }

                    $scope.schedulerOptions = {
                        allDaySlot: false,
                        batch: true,
                        columnWidth: $scope.model.columnWidthDefault,
                        date: $scope.setup.date_fom,
                        //dateHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>'),
                        dateHeaderTemplate: kendo.template($scope.model.dateHeaderTemplate),
                        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#</div>'),
                        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(start, "HH:mm")#</small>-<small>#=kendo.toString(end, "HH:mm")#</small> <small>#=kendo.toString(description)#</small></p></div>'),
                        eventTemplate: kendo.template($scope.model.eventTemplate),
                        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(description)#</small></p></div>'),
                        //majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'HH:mm\')#</strong>'),
                        majorTimeHeaderTemplate: kendo.template($scope.model.majorTimeHeaderTemplate),
                        minorTickCount: $scope.model.minorTick,
                        majorTick: $scope.model.majorTick,
                        //height: 598,
                        height: 800,
                        showWorkHours: true,
                        views: tempViews,
                        dataSource: $scope.model.schedulerDataSource,
                        dataBinding: $scope.onDataBinding,
                        dataBound: $scope.onDataBound,
                        add: $scope.onAdd,
                        edit: $scope.onEdit,
                        cancel: $scope.onCancel,
                        change: $scope.onChange,
                        remove: $scope.onRemove,
                        save: $scope.onSave,
                        navigate: $scope.onNavigate,
                        moveStart: $scope.onMoveStart,
                        move: $scope.onMove,
                        moveEnd: $scope.onMoveEnd,
                        resizeStart: $scope.onResizeStart,
                        resize: $scope.onResize,
                        resizeEnd: $scope.onResizeEnd,
                        group: tempGroups,
                        resources: tempResources
                        //group: {
                        //    resources: ["Owner", "Equipment"],
                        //    orientation: "vertical"
                        //},
                        //resources: [
                        //    {
                        //        field: 'ownerId',
                        //        title: 'Owner',
                        //        dataColorField: "color",
                        //        dataTextField: "text",
                        //        dataValueField: "value",
                        //        multiple: false,
                        //        dataSource: [
                        //            { text: "Alex", value: 1, color: "#f8a398" },
                        //            { text: "Bob", value: 2, color: "#51a0ed" },
                        //            { text: "Charlie", value: 3, color: "#56ca85" },
                        //        ]
                        //        //dataSource: $scope.model.schedulerDataSourceOwners
                        //    //},
                        //    //{
                        //    //    field: 'equipmentID',
                        //    //    title: 'Equipment',
                        //    //    dataColorField: "color",
                        //    //    dataTextField: "text",
                        //    //    dataValueField: "value",
                        //    //    multiple: false,
                        //    //    dataSource: [
                        //    //        { text: "Meeting Room 101", value: 1, color: "#6eb3fa" },
                        //    //        { text: "Meeting Room 201", value: 2, color: "#f58a8a" }
                        //    //    ]
                        //    //    //dataSource: $scope.model.schedulerDataSourceResources
                        //    }
                        //]
                    };
                    $scope.model.initiated = true;
                }

                $scope.$watch(function () { if (angular.isDefined($scope.data)) return $scope.data.groups; else return $scope.data }, function (newValue, oldValue) {
                    if (angular.isUndefined($scope.data) || $scope.data === null) return;

                    //console.log('The full data');
                    //console.dir($scope.data);

                    var groupsExists = false;

                    if (angular.isDefined($scope.data.setup)) {
                        //Do setup related stuff
                        //console.log('Do setup related stuff');
                    } else {
                        //console.log('Setup undefined');
                    }

                    if (angular.isDefined($scope.data.groups)) {
                        //Do group related stuff
                        //console.log('Do group related stuff');
                        //$scope.model.schedulerDataSourceGroups = [];
                        //$scope.model.schedulerDataSourceResources = [];

                        angular.forEach($scope.data.groups, function (data) {
                            if (angular.isDefined(data) && angular.isDefined(data.item_id) && angular.isDefined(data.item_dim) && angular.isDefined(data.isgroup)) {
                                if (data.isgroup === "true" || data.isgroup === true) {
                                    $scope.model.schedulerDataSourceGroups.push(data.item_dim);
                                    //} else if (data.isgroup === "false" || data.isgroup === false) {
                                    //    $scope.model.schedulerDataSourceResources.push(data.item_dim);
                                }
                            }
                        });

                        if ($scope.model.schedulerDataSourceGroups.length > 0) {
                            groupsExists = true;
                        }

                    } else {
                        //console.log('Groups undefined');
                    }

                    if (angular.isDefined($scope.data.resources) || groupsExists) {
                        //Do resource related stuff
                        //console.log('Do resource related stuff');
                        $scope.model.schedulerDataSourceResources = [];

                        angular.forEach($scope.model.schedulerDataSourceGroups, function (group) {
                            angular.forEach($scope.data.items, function (data) {
                                if (angular.isDefined(data) && data.item_dim === group) {
                                    $scope.model.schedulerDataSourceResources.push(data);
                                }
                            });
                        });
                    } else {
                        //console.log('Resources undefined');
                    }

                    if (angular.isDefined($scope.data.items)) {
                        //Do item related stuff
                        //console.log('Do item related stuff');
                        $scope.model.schedulerDataSourceData = [];

                        angular.forEach($scope.data.items, function (data) {
                            if (angular.isDefined(data)) {
                                var addItem = true;

                                for (var i = 0; i < $scope.model.schedulerDataSourceGroups.length; i++) {
                                    if (angular.isDefined(data.item_dim) && data.item_dim === $scope.model.schedulerDataSourceGroups[i]) {
                                        addItem = false;
                                        break;
                                    }
                                }

                                if (addItem) {
                                    $scope.model.schedulerDataSourceData.push(data);
                                }
                            }
                        });
                    } else {
                        //Items not defined
                        //console.log('Items undefined');
                    }

                    if ($scope.model.schedulerDataSourceData.length > 0) {
                        buildScheduler();
                    }

                    if (angular.isFunction($scope.model.scheduler.refresh)) {
                        //console.log('it clearly IS A FUNCTION!');
                        $scope.model.scheduler.dataSource.read();
                        $scope.model.scheduler.refresh();
                    }
                }, true);

                //var reading = false;

                //$scope.$watch(function () {
                //    return $scope.listItems.length;
                //}, function (newValue) {
                //    //console.log('in hasbeen');
                //    //console.dir($scope.listItems);
                //    //if (angular.isUndefined($scope.listItems) || $scope.listItems === null) return;
                //    if (angular.isUndefined($scope.listItems) || $scope.listItems === null) {
                //        $scope.model.hasData.hasTasks = false;
                //    } else {
                //        $scope.model.hasData.hasTasks = true;
                //    }

                //    //if ($scope.listItems.length > 0) {
                //    //    $scope.model.hasData.hasTasks = true;
                //    //} else {
                //    //    $scope.model.hasData.hasTasks = false;
                //    //}

                //    if (angular.isFunction($scope.model.scheduler.refresh)) {
                //        //console.log('it clearly IS A FUNCTION!');
                //        $scope.model.scheduler.dataSource.read();
                //        $scope.model.scheduler.refresh();
                //    }

                //    //$scope.model.hasData.changeCounter++;

                //    //console.log('in hasbeen 2: ' + $scope.model.hasData.hasTasks);

                //    //if ($scope.model.initiated == true && $scope.model.reading == false) {
                //    //    $scope.schedulerOptions.dataSource.read();
                //    //    $scope.model.reading = true;
                //    //    console.log('in hasbeen 2: electric bogaloo');
                //    //    console.dir($scope.schedulerOptions.dataSource);
                //    //    //$scope.schedulerOptions.dataSource.read().then(function () {
                //    //        $scope.model.reading = false;
                //    //    //    console.log('in hasbeen 3: Just THEN');
                //    //    //    console.dir($scope.schedulerOptions.dataSource);
                //    //    //});
                //    //}

                //    //if ($scope.model.initiated == true && reading == false) {
                //    //    reading = true;
                //    //    $scope.schedulerOptions.dataSource.read();
                //    //    //$scope.model.initiated = false;
                //    //    reading = false;
                //    //}
                //});

                //$scope.$watch(function () {
                //    return $scope.owners.length;
                //}, function (newValue) {
                //    if (angular.isUndefined($scope.owners) || $scope.owners === null) return;
                //    //if (angular.isUndefined($scope.owners) || $scope.owners == null) {
                //    //    $scope.model.hasData.hasOwners = false;
                //    //} else {
                //    //    $scope.model.hasData.hasOwners = true;
                //    //}

                //    if ($scope.owners.length > 0) {
                //        $scope.model.hasData.hasOwners = true;
                //    } else {
                //        $scope.model.hasData.hasOwners = false;
                //    }

                //    //$scope.model.hasData.changeCounter++;

                //    //if ($scope.model.initiated == true && reading == false) {
                //    //    reading = true;
                //    //    $scope.schedulerOptions.dataSource.read();
                //    //    //$scope.model.initiated = false;
                //    //    reading = false;
                //    //}

                //    //if ($scope.model.initiated == true && reading == false) {
                //    //    reading = true;
                //    //    $scope.schedulerOptions.dataSource.read().then(function () {
                //    //        reading = false;
                //    //    });
                //    //}

                //    //if ($scope.model.initiated == true) {
                //    //    $scope.schedulerOptions.dataSource.read();
                //    //    //$scope.model.initiated = false;
                //    //}
                //});

                //$scope.$watch(function () { return $scope.data2.length; }, function (newValue) {
                //    if (angular.isUndefined($scope.data2) || $scope.data2 === null) return;

                //    if ($scope.data2.length > 0) {
                //        $scope.model.hasData.hasData = true;
                //    } else {
                //        $scope.model.hasData.hasData = false;
                //    }
                //});

                //$scope.$watch(function () { return $scope.setup; }, function (newValue, oldValue) {
                //    if (angular.isDefined($scope.setup) && angular.isDefined($scope.setup.date_fom) && $scope.setup.date_fom !== null) {
                //        $scope.model.hasData.hasSetup = true;
                //    } else {
                //        $scope.model.hasData.hasSetup = false;
                //    }

                //    //$scope.model.hasData.changeCounter++;

                //    //if (angular.isDefined(oldValue) && oldValue !== null && angular.isDefined(newValue) && newValue !== null && newValue.portal_user_keyno != oldValue.portal_user_keyno) {
                //    //    if ($scope.model.initiated == true) {
                //    //        $scope.schedulerOptions.dataSource.update();
                //    //        console.log('re-read');
                //    //    }
                //    //}
                //}, true);

                //$scope.$watch(function () { return $scope.model.hasData; }, function (newValue, oldValue) {
                //    //if (($scope.model.hasData.hasTasks === true && $scope.model.hasData.hasOwners === true && $scope.model.hasData.hasSetup === true) ||
                //    //    (angular.isDefined($scope.data2) && $scope.data2 !== null && $scope.data2.length > 0)) {
                //    if (angular.isDefined($scope.data2) && $scope.data2 !== null && $scope.data2.length > 0) {
                //        $scope.model.dataIsValid = true;
                //    } else {
                //        $scope.model.dataIsValid = false;
                //    }

                //    if (angular.isFunction($scope.model.scheduler.refresh)) {
                //        console.log('it clearly IS A FUNCTION!');
                //        $scope.model.scheduler.dataSource.read();
                //        $scope.model.scheduler.refresh();
                //    }
                //}, true);

                //$scope.$watch(function () { return $scope.model.dataIsValid; }, function (newValue) {
                //    if ($scope.model.dataIsValid === false) return;
                //    //if ($scope.model.hasData.hasTasks === false || $scope.model.hasData.hasOwners === false || $scope.model.hasData.hasSetup === false) return;
                        
                //    //$scope.model.schedulerDataSourceD = $scope.data2;
                //    $scope.model.schedulerDataSourceData = [];
                //    $scope.model.schedulerDataSourceGroups = [];
                //    $scope.model.schedulerDataSourceResources = [];

                //    console.log('Reorganize');
                //    console.dir($scope.data2);

                //    angular.forEach($scope.data2, function (data) {
                //        if (angular.isDefined(data) && angular.isDefined(data.item_id) && angular.isDefined(data.item_dim)) {
                //            if (angular.isDefined(data.isgroup) && data.isgroup === "true") {
                //                var addGroup = true;
                //                for (var g = 0; g < $scope.model.schedulerDataSourceGroups.length; g++) {
                //                    if (data.item_dim === $scope.model.schedulerDataSourceGroups[g]) {
                //                        addGroup = false;
                //                        break;
                //                    }
                //                }

                //                if (addGroup) {
                //                    $scope.model.schedulerDataSourceGroups.push(data.item_dim);
                //                }

                //                $scope.model.schedulerDataSourceResources.push(data);
                //            } else {
                //                $scope.model.schedulerDataSourceData.push(data);
                //            }
                //        }
                //    });

                //    var tempDims = [];
                //    var i = 0;

                //    console.log('Check out these Dims');
                //    angular.forEach($scope.model.schedulerDataSourceData, function (data) {
                //        if (angular.isDefined(data) && angular.isDefined(data.dims) && data.dims.length > 0) {
                //            angular.forEach(data.dims, function (dim) {
                //                if (angular.isDefined(dim)) {
                //                    if (i < 1) {
                //                        //console.log('Check out this Dim');
                //                        console.dir(dim);
                //                        //i++;
                //                    }
                //                    var addDim = true;
                //                    for (var d = 0; d < tempDims.length; d++) {
                //                        if (Object.keys(dim)[0] === tempDims[d]) {
                //                            addDim = false;
                //                            break;
                //                        }
                //                    }

                //                    if (addDim) {
                //                        tempDims.push(Object.keys(dim)[0]);
                //                    }

                //                    if (Array.isArray(dim[Object.keys(dim)])) {
                //                        console.log('it is array');
                //                        console.dir(Object.keys(dim)[0]);
                //                        console.dir(dim[Object.keys(dim)[0]]);
                //                        //console.dir(data[Object.keys(dim)]);
                //                        data[Object.keys(dim)[0]] = [];
                //                        angular.copy(dim[Object.keys(dim)[0]], data[Object.keys(dim)[0]]);
                //                        //data[Object.keys(dim)[0]] = dim[Object.keys(dim)[0]];
                //                        //data[Object.keys(dim)[0]] = [];
                //                        //data[Object.keys(dim)[0]] = dim[Object.keys(dim)[0]];
                //                        //angular.forEach(dim[Object.keys(dim)], function (index) {
                //                        //    data[Object.keys(dim)[0]].push(dim[Object.keys(dim)][index]);
                //                        //});
                //                    } else {
                //                        data[Object.keys(dim)[0]] = dim[Object.keys(dim)[0]];
                //                    }
                //                }
                //            });
                //        }
                //    });

                //    console.log('All the schedulerDataSources');
                //    console.dir($scope.model.schedulerDataSourceData);
                //    console.dir($scope.model.schedulerDataSourceGroups);
                //    console.dir($scope.model.schedulerDataSourceResources);

                //    $scope.schemaFields = {};

                //    $scope.schemaFields['id'] = { from: 'item_id', type: 'number' };
                //    $scope.schemaFields['title'] = { from: 'item_name', type: 'string', defaultValue: 'No title', validation: { required: true } };
                //    $scope.schemaFields['start'] = { from: 'start_dt', type: 'date' };
                //    $scope.schemaFields['end'] = { from: 'end_dt', type: 'date' };

                //    angular.forEach(tempDims, function (data) {
                //        if (angular.isDefined(data)) {
                //            //$scope.schemaFields[data] = { from: data, type: 'string', defaultValue: '1', validation: { required: true } };
                //            //$scope.schemaFields[Object.keys(data)[0]] = { from: Object.keys(data)[0], type: 'number', defaultValue: '1', validation: { required: true } };
                //        }
                //    });
                //    //$scope.schemaFields['ownerId'] = { from: 'portal_user_keyno', type: 'number', defaultValue: '1', validation: { required: true } };
                //    //$scope.schemaFields['equipmentId'] = { from: 'equipment_keyno', type: 'number', defaultValue: '1', validation: { required: true } };

                //    $scope.model.schedulerDataSource = new kendo.data.SchedulerDataSource({
                //        batch: false,
                //        transport: {
                //            read: function (options) {
                //                //console.log('OPTIONS');
                //                //console.dir(options);
                //                //options.success(angular.copy($scope.listItems));
                //                options.success($scope.model.schedulerDataSourceData);
                //                //options.success($scope.listItems);
                //                //options.success(options.data);
                //            },
                //            create: function (options) {
                //                options.success(options.data);
                //            },
                //            update: function (options) {
                //                angular.forEach($scope.model.schedulerDataSourceData, function (data) {
                //                    if (angular.isDefined(data) && angular.isDefined(options) && angular.isDefined(options.data) && angular.isDefined(options.data.start) && angular.isDefined(options.data.end) && data.item_id === options.data.id) {
                //                        data.start_dt = options.data.start.toISOString().replace('T', ' ').replace('Z', '');
                //                        data.end_dt = options.data.end.toISOString().replace('T', ' ').replace('Z', '');
                //                    }
                //                });
                //                options.success(options.data);
                //            },
                //            destroy: function (options) {
                //                options.success(options.data);
                //            }
                //        },
                //        schema: {
                //            model: {
                //                id: 'id',
                //                fields: $scope.schemaFields
                //            }
                //        }
                //    });

                //    var tempGroups = { resources: [], orientation: "vertical"};

                //    angular.forEach($scope.model.schedulerDataSourceGroups, function (data) {
                //        if (angular.isDefined(data)) {
                //            tempGroups.resources.push(data);
                //        }
                //    });

                //    var tempResources = [];

                //    angular.forEach($scope.model.schedulerDataSourceGroups, function (group) {
                //        if (angular.isDefined(group)) {
                //            tempResources.push({
                //                field: group,
                //                //title: group,
                //                dataColorField: "color",
                //                dataTextField: "item_name",
                //                dataValueField: "item_id",
                //                multiple: false,
                //                dataSource: []
                //            });
                //            var currentGroupIndex = tempResources.length - 1;
                //            angular.forEach($scope.model.schedulerDataSourceResources, function (res) {
                //                if (angular.isDefined(res) && res.item_dim === group) {
                //                    tempResources[currentGroupIndex].dataSource.push(res);
                //                }
                //            });
                //        }
                //    });

                //    //var tempOwners = [];

                //    //angular.forEach($scope.owners, function (data) {
                //    //    if (angular.isDefined(data)) {
                //    //        if (angular.isDefined(data.portal_user_name) && data.portal_user_name !== null) {
                //    //            data.text = data.portal_user_name;
                //    //        } else {
                //    //            data.text = '';
                //    //        }

                //    //        if (angular.isDefined(data.portal_user_keyno) && data.portal_user_keyno !== null) {
                //    //            data.value = data.portal_user_keyno;
                //    //        } else {
                //    //            data.text = 0;
                //    //        }
                //    //    }

                //    //    tempOwners.push(data);
                //    //});

                //    //$scope.model.schedulerDataSourceOwners = new kendo.data.SchedulerDataSource({
                //    //    batch: false,
                //    //    transport: {
                //    //        read: function (options) {
                //    //            options.success(tempOwners);
                //    //        },
                //    //        create: function (options) {
                //    //            options.success(options.data);
                //    //        },
                //    //        update: function (options) {
                //    //            options.success(options.data);
                //    //        },
                //    //        destroy: function (options) {
                //    //            options.success(options.data);
                //    //        }
                //    //    }
                //    //});

                //    //var tempEquipment = [];

                //    //angular.forEach($scope.owners, function (data) {
                //    //    if (angular.isDefined(data)) {
                //    //        if (angular.isDefined(data.equipment_name) && data.equipment_name !== null) {
                //    //            data.text = data.equipment_name;
                //    //        } else {
                //    //            data.text = '';
                //    //        }

                //    //        if (angular.isDefined(data.equipment_keyno) && data.equipment_keyno !== null) {
                //    //            data.value = data.equipment_keyno;
                //    //        } else {
                //    //            data.text = 0;
                //    //        }
                //    //    }

                //    //    tempEquipment.push(data);
                //    //});

                //    //$scope.model.schedulerDataSourceResources = new kendo.data.SchedulerDataSource({
                //    //    batch: false,
                //    //    transport: {
                //    //        read: function (options) {
                //    //            options.success(tempEquipment);
                //    //        },
                //    //        create: function (options) {
                //    //            options.success(options.data);
                //    //        },
                //    //        update: function (options) {
                //    //            options.success(options.data);
                //    //        },
                //    //        destroy: function (options) {
                //    //            options.success(options.data);
                //    //        }
                //    //    }
                //    //});

                //    //THIS SETS THE DEFAULT VIEW TO 'Week'
                //    if (angular.isUndefined($scope.setup.selectedview)) {
                //        if (angular.isDefined($scope.view) && $scope.view === 'timeline') {
                //            $scope.setup.selectedview === 'timeline';
                //        } else {
                //            $scope.setup.selectedview = 'week';
                //        }
                //    } else {
                //        switch ($scope.setup.selectedview) {
                //            case $scope.setup.selectedview === 'timeline':
                //            case $scope.setup.selectedview === 'timelineWeek':
                //            case $scope.setup.selectedview === 'timelineWorkWeek':
                //            case $scope.setup.selectedview === 'day':
                //            case $scope.setup.selectedview === 'workWeek':
                //            case $scope.setup.selectedview === 'week':
                //            case $scope.setup.selectedview === 'month':
                //                break;
                //            default:
                //                if (angular.isDefined($scope.view) && $scope.view === 'timeline') {
                //                    $scope.setup.selectedview === 'timeline';
                //                } else {
                //                    $scope.setup.selectedview = 'week';
                //                }
                //        }
                //    }

                //    var tempViews = [];

                //    if (angular.isDefined($scope.view) && $scope.view === 'timeline') {
                //        tempViews = [
                //            { type: 'timeline', selected: $scope.setup.selectedview === 'timeline' ? true : false, eventHeight: 50 },
                //            { type: 'timelineWeek', selected: $scope.setup.selectedview === 'timelineWeek' ? true : false, eventHeight: 50 },
                //            { type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false, eventHeight: 50 },
                //            { type: 'timelineMonth', selected: $scope.setup.selectedview === 'timelineMonth' ? true : false, eventHeight: 50 }
                //        ];
                //    } else if (angular.isDefined($scope.view) && $scope.view === 'both') {
                //        tempViews = [
                //            { type: 'timeline', selected: $scope.setup.selectedview === 'timeline' ? true : false, eventHeight: 50, columnWidth: 50 },
                //            { type: 'timelineWeek', selected: $scope.setup.selectedview === 'timelineWeek' ? true : false, eventHeight: 50, columnWidth: 50 },
                //            { type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false, eventHeight: 50, columnWidth: 50 },
                //            //{ type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false },
                //            { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                //            { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                //            { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                //            //{ type: "month", selected: true, eventsPerDay: 6, eventHeight: "auto" }
                //            //{ type: "month", selected: true, eventsPerDay: 8, adaptiveSlotHeight: true, eventSpacing: 5 }
                //            { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false, eventHeight: 50 },
                //            { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                //        ];
                //    } else {
                //        tempViews = [
                //            { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                //            { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                //            { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                //            { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false, eventHeight: 'auto' },
                //            { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                //        ];
                //    }

                //    $scope.schedulerOptions = {
                //        allDaySlot: false,
                //        batch: true,
                //        date: $scope.setup.date_fom,
                //        dateHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>'),
                //        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#</div>'),
                //        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(start, "HH:mm")#</small>-<small>#=kendo.toString(end, "HH:mm")#</small> <small>#=kendo.toString(description)#</small></p></div>'),
                //        eventTemplate: kendo.template($scope.model.eventTemplate),
                //        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(description)#</small></p></div>'),
                //        majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'HH:mm\')#</strong>'),
                //        //height: 598,
                //        height: 800,
                //        showWorkHours: true,
                //        views: tempViews,
                //        //views: [
                //        //    { type: 'timeline', selected: $scope.setup.selectedview === 'timeline' ? true : false },
                //        //    { type: 'timelineWeek', selected: $scope.setup.selectedview === 'timelineWeek' ? true : false },
                //        //    { type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false },
                //        //    //{ type: 'timelineWorkWeek', selected: $scope.setup.selectedview === 'timelineWorkWeek' ? true : false },
                //        //    { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                //        //    { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                //        //    { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                //        //    //{ type: "month", selected: true, eventsPerDay: 6, eventHeight: "auto" }
                //        //    //{ type: "month", selected: true, eventsPerDay: 8, adaptiveSlotHeight: true, eventSpacing: 5 }
                //        //    { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false },
                //        //    { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                //        //],
                //        //views: ["timeline", "timelineWeek", "timelineWorkWeek",
                //        //    { type: "timelineMonth", startTime: new Date("2013/6/13 00:00 AM"), majorTick: 1440 },
                //        //    { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                //        //    { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                //        //    { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                //        //    { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false },
                //        //    { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                //        //],
                //        //views: ["timeline", "timelineWeek", "timelineWorkWeek", {
                //        //    type: "timelineMonth",
                //        //    startTime: new Date("2013/6/13 00:00 AM"),
                //        //    majorTick: 1440
                //        //}],
                //        dataSource: $scope.model.schedulerDataSource,
                //        dataBinding: $scope.onDataBinding,
                //        dataBound: $scope.onDataBound,
                //        add: $scope.onAdd,
                //        edit: $scope.onEdit,
                //        cancel: $scope.onCancel,
                //        change: $scope.onChange,
                //        remove: $scope.onRemove,
                //        save: $scope.onSave,
                //        navigate: $scope.onNavigate,
                //        moveStart: $scope.onMoveStart,
                //        move: $scope.onMove,
                //        moveEnd: $scope.onMoveEnd,
                //        resizeStart: $scope.onResizeStart,
                //        resize: $scope.onResize,
                //        resizeEnd: $scope.onResizeEnd,
                //        group: tempGroups,
                //        resources: tempResources
                //        //group: {
                //        //    resources: ["Owner", "Equipment"],
                //        //    orientation: "vertical"
                //        //},
                //        //resources: [
                //        //    {
                //        //        field: 'ownerId',
                //        //        title: 'Owner',
                //        //        dataColorField: "color",
                //        //        dataTextField: "text",
                //        //        dataValueField: "value",
                //        //        multiple: false,
                //        //        dataSource: [
                //        //            { text: "Alex", value: 1, color: "#f8a398" },
                //        //            { text: "Bob", value: 2, color: "#51a0ed" },
                //        //            { text: "Charlie", value: 3, color: "#56ca85" },
                //        //        ]
                //        //        //dataSource: $scope.model.schedulerDataSourceOwners
                //        //    //},
                //        //    //{
                //        //    //    field: 'equipmentID',
                //        //    //    title: 'Equipment',
                //        //    //    dataColorField: "color",
                //        //    //    dataTextField: "text",
                //        //    //    dataValueField: "value",
                //        //    //    multiple: false,
                //        //    //    dataSource: [
                //        //    //        { text: "Meeting Room 101", value: 1, color: "#6eb3fa" },
                //        //    //        { text: "Meeting Room 201", value: 2, color: "#f58a8a" }
                //        //    //    ]
                //        //    //    //dataSource: $scope.model.schedulerDataSourceResources
                //        //    }
                //        //]
                //    };
                //    $scope.model.initiated = true;

                //    //console.log('GET READY TO READ!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
                //    //$scope.schedulerOptions.dataSource.read();
                //});
            }
        };

        return directive;
    }]);
})();