(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListLdsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListLdsGet: function (checklistdata_keyno) {
                return p2DataTaskService.call(1809, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrCheckListLdsList: function (checklistdata_keyno) {
                return p2DataTaskService.call(1810, {
                    checklistdata_keyno: checklistdata_keyno
                });
            }
        };

        return service;
    }]);
})();