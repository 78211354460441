(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('department', {
        templateUrl: 'views/components/views/department/department.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'departmentService', 'logisticService', 'economyService', 'typeaheadService', function ($stateParams, stateService, utilityService, modalService, departmentService, logisticService, economyService, typeaheadService) {
            var vm = this;
            
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'customersmenu':
                        go({
                            cust_no: vm.model.edit.delivery_cust_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                departmentId: $stateParams.department_id,
                deliveryCustomerButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customersmenu' } }
                ],
                edit: {},
                selectListBusinessCompanies: [],
                lockedSave: false
            };

            departmentService.getDepartment({ department_id: vm.model.departmentId }).then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            logisticService.listCompanies().then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCompanies);
            });

            var loadCustomer = function () {
                if (utilityService.validateParmsValue(vm.model.edit.delivery_cust_no) !== true) return;

                economyService.getCustomer({ cust_no: vm.model.edit.delivery_cust_no }).then(function (data) {
                    vm.model.edit.delivery_address = data[0].cust_address;
                });
            };

            vm.typeaheadSearch = function (value) {
                var parms = {
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'S',
                    lastselect: 'SEARCH'
                };
                
                return typeaheadService.searchCustomer(parms);
            };

            vm.onSearchCustomerSelected = function (item) {
                if (item) {
                    vm.model.edit.delivery_cust_no = item?.item_id ?? '0';
                    vm.model.edit.delivery_cust_name = item?.item_name ?? '';

                    loadCustomer();
                } else {
                    vm.clearCustomer();
                }
            };

            vm.onCustomerChanged = function (value, item) {
                vm.model.edit.delivery_cust_name = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.delivery_cust_no = item.item_id;
                    }
                } else {
                    vm.clearCustomer();
                }
            };

            vm.clearCustomer = function () {
                vm.model.edit.delivery_cust_no = '0';
                vm.model.edit.delivery_cust_name = '';
                vm.model.edit.delivery_address = '';
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                departmentService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };
        }]
    });
})();
