(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectLabour', {
        templateUrl: 'views/components/views/selectLabour/selectLabour.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['selectLabourService', 'stateService', 'userService', function (selectLabourService, stateService, userService) {
            var vm = this;
            
            vm.labour_selection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: 'ACT',
                records: [
                    { item_id: '0', item_name: 'active', item_func: 'ACT' },
                    { item_id: '1', item_name: 'not_active', item_func: 'NOT_ACT' },
                    { item_id: '2', item_name: 'all', item_func: 'ALL' }
                ]
            };
            
            vm.select_labour = selectLabourService.select_labour;
    
            vm.retrieve = function () {
                selectLabourService.loadSelectLabourList(vm.labour_selection.item_selected);
            };
    
            vm.selectStatus = function (l_s_item_status) {
                vm.labour_selection.item_selected = l_s_item_status;
                vm.retrieve();
            };
    
            vm.goToPreUserSettings = function (item) {
                // BJS 20180525 - Endret fra searchService til userService
                userService.userEditCache.selectedLabourNo = item.item_id;
                userService.userEditCache.selectedLabourName = item.item_name;
            };
    
            vm.goToLabourEdit = function () {
                stateService.go('labouredit', {
                    labour_no: 0
                });
            };
        }]
    });
})();