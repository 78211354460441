(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("invoiceHeadsService", ['$ihttp', function ($ihttp) {
        var service = {
            genericFunction: function (method_nr, parms) {
                return $ihttp.post({
                    method: method_nr,
                    parameters: parms
                });
            },

            // Parameters:
            // "page": 1,
            // "pageSize": 500,
            // "businessco_no": "1300",
            // "cust_no": "",
            // "searchtext_customer": "",
            // "filterval": "",
            // "cust_name": "",
            // "cust_no_and_name": "",
            // "show_setting": "",
            // "argtype": "-",
            // "argvalue": "-",
            // "order_internal_no": "",
            // "order_order_no": "",
            // "date_fom": "2010-12-30",
            // "date_tom": "2020-01-05",
            // "dateselector_index": "2",
            // "gb_printstatus": "ALL",
            // "gb_touchfunction": "SELECT"
            getInvoiceHeadList: function (parameters) {
                return $ihttp.post({
                    method: 1682,
                    parameters: parameters
                });
            },
            listPagedInvoices: function (parms) {
                return $ihttp.post({
                    method: 1682,
                    parameters: parms || {}
                });
            },
            sendInvoiceToAccounting: function (grid) {
                return $ihttp.post({
                    method: 2993,
                    parameters: grid
                });
            },
            gridMarkInvoiceAsPrinted: function (parms) {
                return $ihttp.post({
                    method: 3042,
                    parameters: parms
                });
            },
            gridMarkInvoiceAsNotPrinted: function (parms) {
                return $ihttp.post({
                    method: 3041,
                    parameters: parms
                });
            },
            gridMarkInvoiceAsSentEmail: function (parms) {
                return $ihttp.post({
                    method: 3049,
                    parameters: parms
                });
            },
            onBusinessCoChanged: function (parms) {
                return $ihttp.post({
                    method: 3040,
                    parameters: parms
                });
            }
        };

        return service;
    }]);
})();
