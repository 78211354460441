(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('shifts', {
        templateUrl: 'views/components/views/shifts/shifts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'shiftsService', function (stateService, shiftsService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;

            vm.model = {
                itemsListShifts: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadShifts = function () {
                shiftsService.listShifts().then(function (result) {
                    angular.copy(result, vm.model.itemsListShifts);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'shift':
                        go({ shift_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadShifts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
