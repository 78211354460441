(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("stockAssignService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 475,
                    parameters: parms || {}
                });
            },
            listQuantities: function (parms) {
                return $ihttp.post({
                    method: 478,
                    parameters: parms || {}
                });
            },
            listProductUnits: function (parms) {
                return $ihttp.post({
                    method: 599,
                    parameters: parms || {}
                });
            },
            listCalcPicks: function (parms) {
                return $ihttp.post({
                    method: 871,
                    parameters: parms || {}
                });
            },
            recalcUnit: function (edit) {
                return $ihttp.post({
                    method: 600,
                    parameters: edit
                });
            },
            saveObject: function (edit) {
                return $ihttp.post({
                    method: 476,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 168,
                    parameters: edit
                });
            },
            abortItem: function (edit) {
                return $ihttp.post({
                    method: 477,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
