(function() {
    'use strict';

    angular.module("imApp").factory("invoiceCustomerBonusService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };
        var service = {
            //getBusinessCos: function (parameters) {
            //    return call(104, parameters || {});
            //},
            getStockplaces: function (parameters) {
                return call(622, parameters || { add_all: 1 });
            },
            getCustomerGroups: function (parameters) {
                return call(69, parameters || { add_all: 1 });
            },
            getCoops: function (parameters) {
                return call(639, parameters || { add_all: 1 });
            },
            getProductGroups: function (parameters) {
                return call(915, parameters || {});
            },
            getInvoiceCustomerBonusGrid: function (parameters) {
                return call(2329, parameters || {});
            },
            remember: function (method, rememberId, value, isBase) {
                return call(angular.isDefined(method) && method !== null ? method : 616, { // for use if need to have a different method
                    is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (method, rememberId) {
                return call(angular.isDefined(method) && method !== null ? method : 973, { variablename: rememberId }); // for use if need to have a different method
            }
        }

        return service;
    }]);
})();
