(function () {
    'use strict';

    angular.module("imApp").factory("equipmentGrpMenuService", ['$ihttp', function ($ihttp) {
        var service = {
            getEquipmentGrpMenu: function (equipmentGrpId) {
                return $ihttp.post({
                    method: 2048,
                    parameters: {
                        equipmentgrp_id: equipmentGrpId
                    }
                });
            }
        };

        return service;
    }]);
})();