(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("stockBatchOnLocationService", ['$ihttp', function ($ihttp) {
        let service = {
            getStockInfo: function (parms) {
                return $ihttp.post({
                    method: 456,
                    parameters: parms || {}
                });
            },
            listStockBatchInfoShow: function (parms) {
                return $ihttp.post({
                    method: 170,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
