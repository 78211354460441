(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("htmlReportsService", ['$ihttp', function ($ihttp) {
        let service = {
            listReports: function (parms) {
                return $ihttp.post({
                    method: 3361,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
