(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('calculationOverview', {
        templateUrl: 'views/components/views/calculationOverview/calculationOverview.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', '$timeout', 'stateService', 'calculationOverviewService', function ($q, $stateParams, $timeout, stateService, calculationOverviewService) {

            var vm = this;

            // #region VARIABLES & DEFINITIONS

            vm.grid = {
                dataTask: {
                    rememberId: 'w_calculationoverview',                        // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2256,
                        //parameters: {}
                        parameters: { calculationseasonyear_keyno: $stateParams.calculationseasonyear_keyno }
                    },
                    addRow: {                                           // NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    saveData: {                                         // NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    }
                },
                config: {                                               // TouchTime parameters configuring the grid
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        addSimple: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: true,
                        rebind: false,
                        print: false,
                        buttons: [
                            //{ name: 'recalculate', text: 'recalculate', func: function () { recalculate(); }, icon: 'fa-calculator', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            { name: 'recalculate', text: 'recalculate', func: function () { recalculate(); }, icon: 'calculator', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'customInvoice', text: 'LAG FAKTURA', func: function () { testItems(); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' },
                            //{ name: 'customTest', text: 'Dette er en tekst', func: function () { testItems(); }, icon: 'star' }
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // IN DEVELOPMENT
                        newTab: true,                                   // If data has path, adds predefined additional button to functions
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } }
                        ]
                    },
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: '100%',                                       // Sets the height of the grid component
                    //aggregate: [                                      // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    //    { field: 'stock_stockvalue', aggregate: 'sum' }
                    //],
                    pager: false,                                       // NOT IMPLEMENTED
                    //selectable: 'multiple, row',                      // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                          // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region TOOLBAR BUTTON VALIDATION

            let getIsSelectedItems = function () {
                let dirtyItems = vm.grid.gridfunc?.getDirtyRows() || [];
                let isSelectedItems = [];

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                });

                return isSelectedItems;
            }

            let hasDirty = function () {
                let parmItems = getIsSelectedItems();

                if (parmItems.length > 0) return false;

                return true;
            };

            let disable = function () {
                return true;
                //if (isSaveRunning) return true;
            };

            // #endregion TOOLBAR BUTTON VALIDATION

            // #region TOOLBAR BUTTON FUNCTIONS

            function prepareRecalc(items) {
                let deferred = $q.defer();

                let promises = [];

                vm.grid.gridfunc.gridProgress(true);

                for (let i = 0; i < items.length; i++) {
                    promises.push(calculationOverviewService.recalculate({ calculationhead_keyno: items[i].calculationhead_keyno }));
                }

                $q.all(promises).then(function (response) {

                    vm.grid.gridfunc.gridProgress(false);
                    vm.grid.gridfunc.rebind();

                    deferred.resolve(response);
                }, function (error) {
                    console.log('error! error! error! error! error!');

                    if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                        console.log(error.data.message);

                        if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                            console.log(error.data.data.message);
                        }
                    } else {
                        if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                            console.log(error.data.data.message);
                        }
                    }

                    console.dir(error);

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            let recalculate = function () {
                //console.log('Recalculate');
                let items = getIsSelectedItems();

                prepareRecalc(items).then(function (result) {
                    //console.log('Done');
                    //console.dir(result);
                });
            };

            let testItems = function () {
                console.log('Test run successfully');
                //var index = $scope.gridOptions.gridfunc.addRowBefore();
            };

            vm.testFunc = function () {
                console.log('testFunc runs');
            }

            // #endregion TOOLBAR BUTTON FUNCTIONS
        }]
    });
})();
