(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('shift', {
        templateUrl: 'views/components/views/shift/shift.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'shiftService', 'skillsCertificateTypeService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, shiftService, skillsCertificateTypeService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let shiftId = $stateParams.shift_id;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
            	edit: {},
            	selectListColours: [],
                itemsListWeekDays: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                let deferred = $q.defer();

                shiftService.getEdit({ shift_id: shiftId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadWeekDays()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadHexColors = function () {
                skillsCertificateTypeService.listHexColors().then(function (result) {
                    angular.copy(result, vm.model.selectListColours);
                });
            };
    
            let loadWeekDays = function () {
                if (utilityService.validateParmsValue(vm.model.edit.shift_id) !== true) return;

                vm.model.itemsListWeekDays = [];

                return shiftService.listWeekDays({ shift_id: vm.model.edit.shift_id }).then(function (result) {
                    angular.copy(result, vm.model.itemsListWeekDays);
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                shiftService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].status_no !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                            message: response[0].status_message,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    shiftService.deleteItem({ shift_id: vm.model.edit.shift_id }).then(function (response) {
                        if (response[0].status_no !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                                message: response[0].status_message,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadHexColors();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
