(function() {
    'use strict';

    angular.module("imApp").factory("productionConfirmationService", ["$ihttp", "p2DataTaskService", function ($ihttp, p2DataTaskService) {
        var service = {
            settings: function () {
                return p2DataTaskService.call(2117, {});
            },
            search: function (params) {
                return p2DataTaskService.call(2016, params);
            },
            confirm: function (params) {
                return p2DataTaskService.call(2017, params);
            }
        }

        return service;
    }]);
})();
