(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prodGrp4Menu', {
        templateUrl: 'views/components/views/prodGrp4Menu/prodGrp4Menu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'prodGrp4MenuService', function ($stateParams, stateService, prodGrp4MenuService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let prodGrp4Id = $stateParams.prodgrp4_id;

            vm.model = {
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
            	prodGrp4MenuService.getMenu({ prodgrp4_id: prodGrp4Id }).then(function (result) {
            		angular.copy(result[0], vm.model.menu);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'prodgrp4':
                        go({
                            prodgrp3_id: vm.model.menu.prodgrp3_id,
                            prodgrp4_id: vm.model.menu.prodgrp4_id
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadMenu();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();