(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDelReceiveLinesService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadBatchDelReceiveLinesGet: function (batchdelivery_keyno, purch_internal_no, purchline_keyno, prodsizecolli_keyno, stocklocation_keyno, controlled, placed) {
                return p2DataTaskService.call(1594, {
                    batchdelivery_keyno: batchdelivery_keyno,
                    purch_internal_no: purch_internal_no,
                    purchline_keyno: purchline_keyno,
                    prodsizecolli_keyno: prodsizecolli_keyno,
                    stocklocation_keyno: stocklocation_keyno,
                    controlled: controlled,
                    placed: placed
                });
            },
            loadBatchDelReceiveLinesList: function (settingReceiveLines) {
                return p2DataTaskService.call(1595, settingReceiveLines);
            }
        };

        return service;
    }]);
})();