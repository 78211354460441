(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("workDetailsService", ['$q', '$ihttp', '$stateParams', 'p2DataTaskService', function ($q, $ihttp, $stateParams, p2DataTaskService) {
        var service = {
            loaded: false,
            work_details: {
                cust_no: 0,
                cust_name: '',
                cust_name_label: '',
                custact_no: 0,
                note: '',
                project_keyno: '',
                projectprosess_keyno: '',
                projectprosess_name: '',
                ok: '',
                activitytype_name: '',
                nbr_of_powtime: 0,
                sum_salaryhours: 0,
                min_date_start: '',
                max_date_end: '',
                nbr_of_participants: '',
                nbr_of_custactdialogs: '',
                edit_custact_possible: '',
                solution_note: '',
                show_comments: '',
                nbr_of_checklistdata: '',
                nbr_of_riskelements: '',
                show_riskelements: '',
                nbr_of_documents: '',
                nbr_of_pictures: '',
                nbr_of_orders: '',
                back_url: 'workingorders'
            },
            addP2CustactAsSubtask: function (custact_no, custact_no_linkto) {
                return p2DataTaskService.call(925, {
                    custact_no: custact_no,
                    custact_no_linkto: custact_no_linkto
                });
            },
            deleteP2CustactLink: function (custactlink_keyno) {
                return p2DataTaskService.call(927, {
                    custactlink_keyno: custactlink_keyno
                });
            },
            loadWorkDetails: function (custact_no) {
                var deferred = $q.defer();

                var parms_custact_get = {
                    method: 28,
                    parameters: {
                        custact_no: custact_no
                    }
                };

                $ihttp.post(parms_custact_get).then(function (data) {
                    service.work_details = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve(service.work_details);
                });

                return deferred.promise;
            },
            loadActStatusList: function (activitytype_no) {
                return p2DataTaskService.call(50, {
                    activitytype_no: activitytype_no
                });
            },
            loadCustactSubTaskList: function (custact_no, retrievetype, include_top) {
                return p2DataTaskService.call(115, {
                    custact_no: custact_no,
                    retrievetype: retrievetype,
                    include_top: include_top
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            save: function (custact_no, actstatus_keyno) {
                return p2DataTaskService.call(1417, {
                    custact_no: custact_no,
                    actstatus_keyno: actstatus_keyno
                });
            },
            deleteP2Custact: function () {
                var deferred = $q.defer();

                var parmsP2CustactDelete = {
                    method: 136,
                    parameters: {
                        custact_no: $stateParams.custact_no
                    }
                };

                $ihttp.post(parmsP2CustactDelete).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
