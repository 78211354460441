(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockStockBatchAssigned', {
        templateUrl: 'views/components/views/stockStockBatchAssigned/stockStockBatchAssigned.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockStockBatchAssignedService', function ($stateParams, stockStockBatchAssignedService) {
            
            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var stockKeyno = $stateParams.stock_keyno;

            vm.model = {
                itemsListAssigned: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            stockStockBatchAssignedService.listAssigned({ stock_keyno: stockKeyno }).then(function (result) {
                angular.copy(result, vm.model.itemsListAssigned);
            });

		    // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();