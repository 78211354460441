(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travExpType', {
        templateUrl: 'views/components/views/travExpType/travExpType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'travExpTypeService', 'logisticService', 'typeaheadService', function ($stateParams, stateService, utilityService, translateService, modalService, travExpTypeService, logisticService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let trexTypeId = $stateParams.trextype_id;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListGlyphicons: [],
                selectListCompanies: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                travExpTypeService.getTravelExpenceType({ trextype_id: trexTypeId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadGlyphicons = function () {
                travExpTypeService.listGlyphicons().then(function (result) {
                    angular.copy(result, vm.model.selectListGlyphicons);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '0' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'account_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.lookUpSearchAccounts({
                            searchtext: vm.model.edit[id] || '',
                            businessco_no: vm.model.edit.businessco_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'account_name':
                        if (item) {
                            vm.model.edit.account_no = item?.item_id ?? '0';
                            vm.model.edit.account_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('account_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'account_name':
                        vm.model.edit.account_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.account_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('account_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'account_name':
                        vm.model.edit.account_no = '0';
                        vm.model.edit.account_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // ## SHOW GLYPHICON SYMBOL FUNCTION ##
    
            let showGlyphiconSymbol = function () {
                angular.forEach(vm.model.selectListGlyphicons, function (item) {
                    if (vm.model.edit.glyphicon === item.item_id) {
                        vm.model.edit.glyphicon = item.item_glyphicon;
                    }
                });
            };
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                travExpTypeService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'glyphicon':
                        if (utilityService.validateWatchValue(value, vm.model.edit.glyphicon) !== true) return;

                        vm.model.edit.glyphicon = value;

                        if (utilityService.validateParmsValue(vm.model.edit.glyphicon, true) !== true) return;

                        showGlyphiconSymbol();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadGlyphicons();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
