(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('pohKeynoTopStatus', {
        templateUrl: 'views/components/views/pohKeynoTopStatus/pohKeynoTopStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'pohKeynoTopStatusService', 'stateService', function ($stateParams, pohKeynoTopStatusService, stateService) {

            // #region GLOBAL VARIABLES & DEFINITIONS
            
            var vm = this;
            
            vm.model = {
                p2PohTopSelection: {
                    item_default: '0',
                    item_selected: 'D',
                    records: [
                        { item_id: '0', item_name: 'this', item_func: 'D' },
                        { item_id: '1', item_name: 'orderinfo', item_func: 'INFO' },
                        { item_id: '2', item_name: 'orderlines', item_func: 'OL' }
                    ]
                },
                p2PohTopGet: {},
                p2OrderlineListStatus: {
                    records: []
                },
                p2PohTopList: {
                    records: []
                }
            };

            // #endregion GLOBAL VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            pohKeynoTopStatusService.getP2PohTop($stateParams.poh_keyno_top).then(function () {
                vm.model.p2PohTopGet = pohKeynoTopStatusService.p2PohTopGet;
                
                if (vm.model.p2PohTopGet.order_internal_no !== '') {
                    pohKeynoTopStatusService.loadP2OrderlineListStatus(vm.model.p2PohTopGet.order_internal_no).then(function () {
                        vm.model.p2OrderlineListStatus = pohKeynoTopStatusService.p2OrderlineListStatus;
                    });
                }
            });
            
            pohKeynoTopStatusService.getP2PohTopList($stateParams.poh_keyno_top).then(function () {
                vm.model.p2PohTopList = pohKeynoTopStatusService.p2PohTopList;
            });

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON GROUP FUNCTIONS

            vm.selectP2PohTopView = function (lsP2PohTopSelectItem) {
                vm.model.p2PohTopSelection.item_selected = lsP2PohTopSelectItem;
            };

            // #endregion BUTTON GROUP FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'rfidconnect1':
                        stateService.go('rfidconnect', {
                            argtype: 'poh_keyno_top',
                            argvalue: $stateParams.poh_keyno_top
                        });
                        break;
                    case 'rfidconnect2':
                        stateService.go('rfidconnect', {
                            argtype: 'order_internal_no',
                            argvalue: vm.model.p2PohTopGet.order_internal_no
                        });
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();