(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("choicePossRulesRunService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadChoicePossRulesRunGet: function () {
                return p2DataTaskService.call(1193, {});
            },
            loadProductChoiceList: function (add_all) {
                return p2DataTaskService.call(1159, {
                    add_all: add_all
                });
            },
            loadModelSelectList: function (add_all, show_inactive) {
                return p2DataTaskService.call(1174, {
                    add_all: add_all,
                    show_inactive: show_inactive
                });
            },
            loadCountList: function (get) {
                return p2DataTaskService.call(1194, get);
            },
            loadProductList: function (get) {
                return p2DataTaskService.call(1195, get);
            },
            loadExecuteOne: function (mergeObj) {
                return p2DataTaskService.call(1196, mergeObj);
            }
        };

        return service;
    }]);
})();