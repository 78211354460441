(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('guardList', {
        templateUrl: 'views/components/views/guardList/guardList.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'guardListService', function ($stateParams, guardListService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                itemsListGuards: []
            };
            
            guardListService.loadGuardListList(vm.model.argType, vm.model.argValue).then(function (data) {
                angular.copy(data, vm.model.itemsListGuards);
            });
        }]
    });
})();