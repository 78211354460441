(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productCategorys', {
        templateUrl: 'views/components/views/productCategorys/productCategorys.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'productCategorysService', function (stateService, productCategorysService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                itemsListProductCategories: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            productCategorysService.listProductCategories().then(function (result) {
                angular.copy(result, vm.model.itemsListProductCategories);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'productcategory':
                        stateService.go(state, {
                            productcategory_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();