(function () {
    'use strict';

    angular.module('imApp').component('ttDatasourceParameterEdit', {
        templateUrl: 'views/components/dynamics/views/ttDatasourceParameterEdit/ttDatasourceParameterEdit.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$timeout', 'ttDatasourceParameterEditService', 'ieScreenBlockService', function ($timeout, ttDatasourceParameterEditService, ieScreenBlockService) {
            var vm = this;

            let parameters = {};

            vm.instanceId = '';
            vm.datasourceParameter = {};

            vm.keyno = 0;

            vm.parmtypes = [
                { id: 'static', name: 'Static' },
                { id: 'datasource', name: 'Datasource' },
                { id: 'stateparam', name: 'State Param' }
            ];

            vm.sourceparameters = [];

            let changedPromise = null;

            vm.onDataChanged = function (value, modelId) {
                let parts = modelId.split('.');

                vm.datasourceParameter[parts[parts.length - 1]] = value;

                var updating = false;

                let update = function () {
                    changedPromise = null;

                    if (updating === true) {
                        changedPromise = $timeout(update, 350);
                        return;
                    }

                    updating = true;

                    ttDatasourceParameterEditService.update(vm.datasourceParameter).then(function () {
                        updating = false;
                    });
                };

                if (changedPromise !== null) {
                    $timeout.cancel(changedPromise);
                }

                changedPromise = $timeout(update, 350);
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                angular.copy(vm.resolve.parameters, parameters);
                angular.copy(vm.resolve.parameters.datasourceParameter, vm.datasourceParameter);

                vm.keyno = parameters.datasource.tt_datasource_keyno;

                vm.instanceId = parameters.instanceId;
            };
        }]
    });
})();