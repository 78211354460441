(function () {
    'use strict';

    angular.module("imApp").factory("accountingPlService", ['$ihttp', function ($ihttp) {
        var service = {
            getAccountingProfitLoss: function (parms) {
                return $ihttp.post({
                    method: 2800,
                    parameters: parms || {}
                });
            },
            listAcReportLayouts: function (parms) {
                return $ihttp.post({
                    method: 2801,
                    parameters: parms || {}
                });
            },
            getEquipments: function (parms) {
                return $ihttp.post({
                    method: 30,
                    parameters: parms || {}
                });
            },
            getLabours: function (parms) {
                return $ihttp.post({
                    method: 101,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
