(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('myActivities', {
        templateUrl: 'views/components/views/myActivities/myActivities.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['myActivitiesService', 'ttGridFactory', 'stateService', function (myActivitiesService, ttGridFactory, stateService) {
            var vm = this;

            vm.grid = new ttGridFactory({
                rememberId: 'myactivities_grid',
                loadData: {
                    method: 2866,
                    parameters: {}
                }
            })
            .setCustomToolbarButtons([
                { 
                    name: 'newActivity', 
                    text: 'new_activity', 
                    func: () => navigateToNewActivity(), 
                    icon: 'fas fa-plus',
                    cssClass: 'btn btn-primary im-grid-btn-xs-r', 
                    translate: true, 
                    disabled: () => {}
                },
                {
                    name: 'refreshGrid',
                    text: 'refresh',
                    func: () => refreshGrid(),
                    icon: 'fas fa-sync',
                    cssClass: 'btn btn-primary im-grid-btn-xs-r', 
                    translate: true, 
                    disabled: () => {}
                }
            ]);

            let refreshGrid = function () {
                return vm.grid.gridfunc.rebind();
            };

            let navigateToNewActivity = function () {
                return stateService.go('custactedit', {
                    custact_no: '0',
                    filterfield: '-',
                    filtervalue: '-'
                });
            }
        }]
    });
})();