(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('sopMenu', {
        templateUrl: 'views/components/views/sopMenu/sopMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'sopService', 'sopMenuService', function ($stateParams, stateService, sopService, sopMenuService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
            vm.model = {
                sopKeyNo: $stateParams.sop_keyno,
                menu: {},
                selectListFieldTypes: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            sopMenuService.loadSopMenuGet(vm.model.sopKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.menu);
            });
    
            sopService.loadSopFieldTypesList().then(function (data) {
                angular.copy(data, vm.model.selectListFieldTypes);
            });

            // ## DIRECTION BUTTON FUNCTIONS ##
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'sop':
                    case 'sopvalid':
                    case 'sopcls':
                        stateService.go(state, { sop_keyno: vm.model.menu.sop_keyno });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();