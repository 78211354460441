(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srNotCompletedService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrNotCompletedGet: function (resource_id) {
                return p2DataTaskService.call(1478, {
                    resource_id: resource_id
                });
            },
            loadP2SrReportListNoTokList: function (resource_id) {
                return p2DataTaskService.call(992, {
					resource_id: resource_id
				});
			}
        };

        return service;
    }]);
})();