<tt-form-field-base
    [ttFieldId]="!ttHideDate ? id.dateInput : id.timeInput"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="datetime"
>
    <div *ngIf="!ttHideDate" class="tt-input__group--time">
        <span class="tt-input__icon--time fa-fw fal fa-calendar"></span>
        <input
            #dateRef
            matInput
            [tabIndex]="ttTabable ? '0' : '-1'"
            [matDatepicker]="picker"
            [attr.id]="id.dateInput"
            class="tt-input__form-control tt-input__form-control--time mat-form-field-underline"
            inputmode="text"
            type="text"
            [placeholder]="translations['placeholder']"
            [(ngModel)]="_model.date"
            (dateChange)="onDatePickerChanged($event)"
            ttOutsideClick
            [ignore]="picker.id"
            (onOutsideClick)="onDateInputBlur()"
            (keydown)="onDateInputKeydown($event)"
            (click)="openDatepicker()"
            [ngStyle]="style['date']"
            [readonly]="ttReadonly"
            [disabled]="ttDisabled"
        />

        <mat-datepicker #picker [restoreFocus]="restoreFocus" yPosition="below" [disabled]="ttReadonly || ttDisabled">
            <p>{{ translations["label"] }}</p>
            <!-- <mat-datepicker-actions>
                <div style="width: 100%; display: flex; justify-content: space-between">
                    <button [ngStyle]="{ fontSize: style['date'].fontSize }" mat-button matDatepickerCancel (click)="restoreFocus = true">Lukk</button>
                    <div style="display: flex; gap: 0.4rem">
                        <button [ngStyle]="{ fontSize: style['date'].fontSize }" mat-button (click)="picker.select(today); picker.close()">I dag</button>
                        <button [ngStyle]="{ fontSize: style['date'].fontSize }" mat-raised-button color="primary" matDatepickerApply>Velg</button>
                    </div>
                </div>
            </mat-datepicker-actions> -->
        </mat-datepicker>
    </div>

    <div *ngIf="!ttHideTime" [attr.id]="id.timeGroup" class="tt-input__group--time">
        <span class="tt-input__icon--time fa-fw fal fa-clock"></span>
        <input
            #timeRef
            #timeListbox="listbox-popup"
            [attr.id]="id.timeInput"
            [tabIndex]="ttTabable ? '0' : '-1'"
            [ttLabel]="ttLabel"
            [ttListboxPopup]="!ttReadonly && !ttDisabled"
            (ttSelect)="onTimeOptionSelect($event)"
            [ttData]="timeOptions"
            [ttRelativeElement]="id.timeGroup"
            ttFilterOperation="none"
            [(ttShow)]="showTimes"
            class="tt-input__form-control tt-input__form-control--time"
            (focus)="timeRef.select()"
            (blur)="onTimeInputBlur()"
            [(ngModel)]="_model.time"
            (ngModelChange)="onTimeChanged($event)"
            (keydown)="onTimeInputKeydown($event)"
            placeholder="TT:mm"
            [ngStyle]="style['date']"
            [readOnly]="ttReadonly"
            [disabled]="ttDisabled"
        />

        <tt-form-field-button *ngIf="!ttReadonly && !ttDisabled" ttIcon="fal fa-chevron-down" ttType="secondary" (ttClick)="openTimeListbox()"></tt-form-field-button>
    </div>

    <tt-form-field-buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
</tt-form-field-base>
