(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDeliveryAddPurchLine', {
		templateUrl: 'views/components/views/batchDeliveryAddPurchLine/batchDeliveryAddPurchLine.template.html?v=' + imApp.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$element', 'utilityService', 'base64', 'modalService', 'stateService', 'rememberService', 'logisticService', 'batchDeliveryAddPurchLineService', function ($stateParams, $timeout, $element, utilityService, base64, modalService, stateService, rememberService, logisticService, batchDeliveryAddPurchLineService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            vm.model = {
                mId: 'batchDeliveryAddPurchLineCtrl',
                supplierNo: $stateParams.supplier_no,
                batchDeliveryKeyNo: $stateParams.batchdelivery_keyno,
                purchInternalNo: $stateParams.purch_internal_no,
                bgBatchDeliveryAddPurchLineSelection: {
                    selected: 'batchdelivery_addpurchline_select_purchline',
                    buttons: [
                        { id: 'batchdelivery_addpurchline_select_purchline', label: 'batchdelivery_addpurchline_select_purchline', color: 'primary', onClick: () => vm.model.bgBatchDeliveryAddPurchLineSelection.selected = 'batchdelivery_addpurchline_select_purchline' },
                        { id: 'batchdelivery_addpurchline_corrections', label: 'batchdelivery_addpurchline_corrections', color: 'primary', onClick: () => vm.model.bgBatchDeliveryAddPurchLineSelection.selected = 'batchdelivery_addpurchline_corrections' }
                    ]
                },
                barcodeBtnList: [
                    { item_id: '0', item_name: 'scan', item_func: 'scan_item', glyph: 'glyphicon-barcode', color: 'primary' },
                    { item_id: '1', item_name: 'calculator', item_func: 'calculator_item', glyph: 'fa fa-calculator', color: 'primary' },
                    { item_id: '2', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '3', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                settingAddPurchLine: {},
                selectListManualPurchlines: [],
                selectListCollies: [],
                inputListPurchaseLines: [],
                itemsListLocs: [],
                loadingBarcode: false,
                showChecked: false,
                gridReady: false,
                lockedSaveScanning: false,
                lockedSaveScanningRed: false,
                lockedSave: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_batchdelivery_addpurchline.grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null
            };

            // ## LOAD PROCEDURE CALLS ##

            // #region OTHER

            let optionfunc = function (data) {
                if (!data?.func) return;

                if (data.func === 'CheckboxBoxClick') {
                    for (let i = 0; i < vm.model.inputListPurchaseLines.length; i++) {
                        if (data?.dataItem?.purchline_keyno && data.dataItem.purchline_keyno.toString() === vm.model.inputListPurchaseLines[i].item_id) {
                            vm.model.inputListPurchaseLines[i].item_is_selected = data.dataItem.is_selected ? '1' : '0';
                            if (data.dataItem.is_selected) vm.model.inputListPurchaseLines[i].best_before_date = '';
                            break;
                        }
                    }
                } else if (data.func === 'CheckboxHeaderClick') {
                    angular.forEach(data.data, function (item) {
                        for (let i = 0; i < vm.model.inputListPurchaseLines.length; i++) {
                            if (item.purchline_keyno && item.purchline_keyno.toString() === vm.model.inputListPurchaseLines[i].item_id) {
                                vm.model.inputListPurchaseLines[i].item_is_selected = item.is_selected ? '1' : '0';
                                if (item.is_selected) vm.model.inputListPurchaseLines[i].best_before_date = '';
                                break;
                            }
                        }
                    });
                }
            };

            // #endregion OTHER
            
            var loadBatchDeliveryAddPurchLine = function () {
                batchDeliveryAddPurchLineService.loadBatchDeliveryAddPurchLineGet(vm.model.supplierNo, vm.model.batchDeliveryKeyNo, vm.model.purchInternalNo).then(function (data) {
                    if (angular.isDefined(data[0].getencoded) && data[0].getencoded !== '') {
                        vm.model.settingAddPurchLine = angular.copy(JSON.parse(base64.urldecode(data[0].getencoded)));
                        var stockKeyNo = vm.model.settingAddPurchLine.stock_keyno;
                        
                        vm.scanBarcode();
                        loadBatchDeliveryEanLists();

                        if (stockKeyNo > 0) {
                            loadBatchDeliveryAddPurchLineLocs(stockKeyNo);
                        }
                    } else {
                        vm.model.settingAddPurchLine = angular.copy(data[0]);

                        loadGrid();
                    }
                });
            };

            loadBatchDeliveryAddPurchLine();

            batchDeliveryAddPurchLineService.loadBatchDeliveryAddPurchLineManPlList(vm.model.supplierNo, vm.model.batchDeliveryKeyNo, vm.model.purchInternalNo).then(function (data) {
                angular.copy(data, vm.model.selectListManualPurchlines);
            });

            batchDeliveryAddPurchLineService.loadBatchDelSelectPurchLineList(vm.model.supplierNo, vm.model.batchDeliveryKeyNo, vm.model.purchInternalNo).then(function (data) {
                angular.copy(data, vm.model.inputListPurchaseLines);
            });

            var loadProdSizeCollies = function (prodsize_keyno) {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.prodsize_keyno || prodsize_keyno) !== true) return;

                vm.model.selectListCollies = [];

                logisticService.loadProdSizeColliList(vm.model.settingAddPurchLine.prodsize_keyno || prodsize_keyno).then(function (data) {
                    angular.copy(data, vm.model.selectListCollies);
                });
            };

            var loadBatchDeliveryAddPurchLineLocs = function (stock_keyno) {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.stock_keyno || stock_keyno) !== true) return;

                vm.model.itemsListLocs = [];

                batchDeliveryAddPurchLineService.loadBatchDeliveryAddPurchLineLocList(vm.model.settingAddPurchLine.stock_keyno || stock_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListLocs);
                });
            };

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.supplier_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.batchdelivery_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.purch_internal_no) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: 3258,
                    parameters: {
                        supplier_no: vm.model.settingAddPurchLine.supplier_no,
                        batchdelivery_keyno: vm.model.settingAddPurchLine.batchdelivery_keyno,
                        purch_internal_no: vm.model.settingAddPurchLine.purch_internal_no
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            var loadScan = function () {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.barcode, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.purchline_keyno_manual) !== true) return;

                batchDeliveryAddPurchLineService.scan(vm.model.settingAddPurchLine).then(function (data) {
                    vm.model.loadingBarcode = false;
                    vm.model.settingAddPurchLine.barcode = '';
                    //vm.model.settingAddPurchLine.best_before_date = '';
                    vm.model.settingAddPurchLine.prod_id = data[0].prod_id;
                    vm.model.settingAddPurchLine.scan_info = data[0].scan_info;
                    vm.model.settingAddPurchLine.colli_pr_sku = data[0].colli_pr_sku;
                    vm.model.settingAddPurchLine.prod_name = data[0].prod_name;
                    vm.model.settingAddPurchLine.quantity = data[0].quantity;
                    vm.model.settingAddPurchLine.volume_m3 = data[0].volume_m3;
                    vm.model.settingAddPurchLine.purchline_keyno = data[0].purchline_keyno;
                    vm.model.settingAddPurchLine.barcode_scanned = data[0].barcode_scanned;
                    vm.model.settingAddPurchLine.prodsize_keyno = data[0].prodsize_keyno;
                    vm.model.settingAddPurchLine.ean_list = data[0].ean_list;
                    vm.model.settingAddPurchLine.prod_keyno = data[0].prod_keyno;
                    vm.model.settingAddPurchLine.url_picture = data[0].url_picture;
                    vm.model.settingAddPurchLine.stock_quantity_pr_colli = data[0].stock_quantity_pr_colli;
                    vm.model.settingAddPurchLine.prodchoice_keyno = data[0].prodchoice_keyno;
                    vm.model.settingAddPurchLine.stocklocation_keyno = data[0].stocklocation_keyno;
                    vm.model.settingAddPurchLine.stocklocation_description = data[0].stocklocation_description;
                    vm.model.settingAddPurchLine.stock_keyno = data[0].stock_keyno;
                    vm.model.settingAddPurchLine.show_best_before_date = data[0].show_best_before_date;
                    vm.model.settingAddPurchLine.show_suppliers_batchno = data[0].show_suppliers_batchno;

                    loadBatchDeliveryStockQuantityPrCollis();
                    loadBatchDeliveryAddPurchLineLocs();

                    if (vm.model.settingAddPurchLine.prodsize_keyno > 0) {
                        loadProdSizeCollies();

                        $timeout(function () {
                            vm.model.settingAddPurchLine.prodsizecolli_keyno = data[0].prodsizecolli_keyno;
                        }, 50);
                    }
                });
            };

            var loadBatchDeliveryStockQuantityPrCollis = function () {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.prodsize_keyno) !== true) return;
            
                batchDeliveryAddPurchLineService.loadBatchDeliveryStockQuantityPrColliGet(vm.model.settingAddPurchLine.prodsize_keyno).then(function (data) {
                    vm.model.settingAddPurchLine.colli_pr_sku = data[0].colli_pr_sku;
                    vm.model.settingAddPurchLine.stock_quantity_pr_colli = data[0].stock_quantity_pr_colli;

                    recalcQuantity();
                });
            };

            var loadBatchDeliveryEanLists = function () {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.prod_id) !== true) return;

                batchDeliveryAddPurchLineService.loadBatchDeliveryEanListGet(vm.model.settingAddPurchLine.prod_id).then(function (data) {
                    vm.model.settingAddPurchLine.ean_list = data[0].ean_list;
                });
            };

            var loadBatchDeliveryGetStockLocationInfos = function () {
                if (utilityService.validateParmsValue(vm.model.settingAddPurchLine.stocklocation_keyno_selected) !== true) return;

                batchDeliveryAddPurchLineService.loadBatchDeliveryGetStockLocationInfo(vm.model.settingAddPurchLine.stocklocation_keyno_selected).then(function (data) {
                    vm.model.settingAddPurchLine.stocklocation_keyno = data[0].stocklocation_keyno;
                    vm.model.settingAddPurchLine.stocklocation_description = data[0].stocklocation_description;
                    vm.model.settingAddPurchLine.stocklocation_keyno_selected = '0';
                });
            };

            var loadBatchDeliveryGetVolumeM3s = function (prodsize_keyno) {
                batchDeliveryAddPurchLineService.loadBatchDeliveryGetVolumeM3(prodsize_keyno).then(function (data) {
                    vm.model.settingAddPurchLine.volume_m3 = data[0].volume_m3;
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var rememberGet = function (callback) {
                var variableNameGet = 'w_batchdelivery_addpurchline.get';
                var base64EncodedGet = base64.urlencode(angular.toJson(vm.model.settingAddPurchLine));
            
                if (utilityService.validateParmsValue(variableNameGet) !== true) return;
                if (utilityService.validateParmsValue(base64EncodedGet) !== true) return;

                rememberService.remember(variableNameGet, base64EncodedGet).then(function () {
                    if (angular.isFunction(callback)) {
                        callback();
                    }
                });
            };

            // ## SCAN BUTTON FUNCTION ##

            var goBarcode = function () {
                var wait = function () {
                    var val = $element.find('#' + vm.model.mId)[0].value;

                    if (angular.isString(val) && val !== null && val.length > 0) {
                        vm.model.settingAddPurchLine.barcode = val;
                        loadScan();
                        $element.find('#' + vm.model.mId)[0].value = '';
                        return;
                    }

                    $timeout(wait, 250);
                };

                wait();

                window.location = "readbarcode://" + vm.model.mId;
            };

            vm.scanBarcode = function () {
                vm.model.loadingBarcode = true;
                loadScan();
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgBatchDeliveryAddPurchLineSelection = function (value) {
                vm.model.bgBatchDeliveryAddPurchLineSelection.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## SHOW CALCULATOR FUNCTIONS	##

            var goCalcBarcode = function () {
                modalService.showCalculator(vm.model.settingAddPurchLine.barcode).then(function (value) {
                    vm.model.settingAddPurchLine.barcode = value;
                    loadScan();
                });
            };

            // ## BUTTON LIST FUNCTIONS ##

            vm.barcodeBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'scan_item':
                        goBarcode();
                        break;
                    case 'calculator_item':
                        goCalcBarcode();
                        break;
                    case 'erase_item':
                        vm.model.settingAddPurchLine.barcode = '';

                        if (vm.model.settingAddPurchLine.barcode === '') {
                            loadBatchDeliveryAddPurchLine();
                        }
                        break;
                    case 'search_item':
                        vm.scanBarcode();
                        break;
                }
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goTo = function (state, item) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'prodsizeedit':
                        if (angular.isDefined(item.prodsize_keyno) && item.prodsize_keyno !== '') {
                            go({
                                prodsize_keyno: item.prodsize_keyno
                            });
                        } else {
                            rememberGet(function () {
                                go({
                                    prodsize_keyno: vm.model.settingAddPurchLine.prodsize_keyno
                                });
                            });
                        }
                        break;
                    case 'selectstocklocation':
                        rememberGet(function () {
                            go({
                                stockplace_id: vm.model.settingAddPurchLine.stockplace_id
                            });
                        });
                        break;
                    case 'producteans':
                        rememberGet(function () {
                            go({
                                prod_id: vm.model.settingAddPurchLine.prod_keyno
                            });
                        });
                        break;
                    case 'batchdelreceivelines':
                        go({
                            batchdelivery_keyno: vm.model.settingAddPurchLine.batchdelivery_keyno,
                            purch_internal_no: vm.model.settingAddPurchLine.purch_internal_no,
                            purchline_keyno: vm.model.settingAddPurchLine.purchline_keyno,
                            prodsizecolli_keyno: vm.model.settingAddPurchLine.prodsizecolli_keyno,
                            stocklocation_keyno: vm.model.settingAddPurchLine.stocklocation_keyno,
                            controlled: '0',
                            placed: '0'
                        });
                        break;
                    case 'batchdelreceiveline':
                        go({
                            batchdelreceiveline_keyno: vm.model.settingAddPurchLine.batchdelreceiveline_keyno_last
                        });
                        break;
                    case 'batchdelcontrol':
                        go({
                            batchdelivery_keyno: vm.model.settingAddPurchLine.batchdelivery_keyno,
                            purch_internal_no: vm.model.settingAddPurchLine.purch_internal_no
                        });
                        break;
                    case 'purchhead':
                        if (vm.model.settingAddPurchLine.purch_internal_no > 0){
                            go({
                                purch_internal_no: vm.model.settingAddPurchLine.purch_internal_no
                            });
                        }                        
                        break;
                }
            };

            // ## TOGGLE FUNCTIONS ##

            vm.toggleChosenLocs = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;
                vm.model.settingAddPurchLine.stocklocation_keyno = item.item_id;
                vm.model.settingAddPurchLine.stocklocation_description = item.item_name;
            };

            vm.toggleChosenPurchaseLines = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item['best_before_date'] = '';
                    item.item_is_selected = '1';
                    item.item_glyphicon_color = 'green';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                }
            };

		    vm.selectAll = function () {
                angular.forEach(vm.model.inputListPurchaseLines, function (item) {
				    if (item.item_id === '0') return;
				    if (item.item_id === '-1') return;
                    item['best_before_date'] = '';
                    item.item_is_selected = '1';
                    item.item_glyphicon_color = 'green';
			    });
		    };

		    vm.unselectAll = function () {
			    angular.forEach(vm.model.inputListPurchaseLines, function (item) {
				    if (item.item_id === '0') return;
				    if (item.item_id === '-1') return;

                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
			    });
		    };

            // ## CHECK & UNCHECK ALL FUNCTION ##

            vm.checkButtonClick = function () {
                if (!vm.model.showChecked) {
                    vm.model.showChecked = true;
                    vm.selectAll();
                } else {
                    vm.model.showChecked = false;
                    vm.unselectAll();
                }
            };

            // ###############
            // UPDATE FUNCTION
            // ###############
        
            vm.changeField = function (index, unitType) {
                $timeout(function () {
                    switch (unitType) {
                        case 'quantity_received_now_salesprice':
                            var ln_quantity_received_now = Number(vm.model.inputListPurchaseLines[index].quantity_received_now_salesprice.replace(',','.'));
                            var base_factor = Number(ln_quantity_received_now / Number(vm.model.inputListPurchaseLines[index].sales_price_factor.replace(',', '.')));

                            vm.model.inputListPurchaseLines[index].quantity_received_now = Number(base_factor * Number(vm.model.inputListPurchaseLines[index].purch_number_factor.replace(',', '.')));
                            vm.model.inputListPurchaseLines[index].quantity_received_now_stockunits = base_factor;
                            break;
                        case 'quantity_received_now_stockunits':
                            /*var ln_quantity_received_now = 0;
                            if (angular.isDefined(vm.model.inputListPurchaseLines[index].quantity_received_now)) {
                                var s = vm.model.inputListPurchaseLines[index].quantity_received_now;
                                s.replace(',', '.');
                                ln_quantity_received_now = Number(s);
                            }*/
                            var base_factor = vm.model.inputListPurchaseLines[index].quantity_received_now_stockunits.replace(',', '.');

                            vm.model.inputListPurchaseLines[index].quantity_received_now_salesprice = Number(base_factor * Number(vm.model.inputListPurchaseLines[index].sales_price_factor.replace(',', '.')));
                            vm.model.inputListPurchaseLines[index].quantity_received_now = Number(base_factor * Number(vm.model.inputListPurchaseLines[index].purch_number_factor.replace(',', '.')));

                            var ln_quantity_received_now = 0;
                            if (angular.isDefined(vm.model.inputListPurchaseLines[index].quantity_received_now)) {
                                var s = vm.model.inputListPurchaseLines[index].quantity_received_now;
                                try {
                                    s.replace(',', '.');
                                } catch (e) { }
                            
                                ln_quantity_received_now = Number(s);
                            }
                            var ln_ordered_quantity = Number(vm.model.inputListPurchaseLines[index].ordered_quantity.replace(',', '.'));

                            if (ln_quantity_received_now < ln_ordered_quantity) {
                                vm.model.inputListPurchaseLines[index].delivered_less = '1';
                            } else {
                                vm.model.inputListPurchaseLines[index].delivered_less = '0';
                            }

                            break;
                        case 'quantity_received_now':
                            if ((vm.model.inputListPurchaseLines[index].ordered_quantity - vm.model.inputListPurchaseLines[index].delivered_quantity - vm.model.inputListPurchaseLines[index].quantity_received_now) < 0) {
                                vm.model.inputListPurchaseLines[index].quantity_rest = 0;
                            } else {
                                vm.model.inputListPurchaseLines[index].quantity_rest = vm.model.inputListPurchaseLines[index].ordered_quantity - vm.model.inputListPurchaseLines[index].delivered_quantity - vm.model.inputListPurchaseLines[index].quantity_received_now;
                            }
                            break;
                        case 'delivered_quantity':
                            if ((vm.model.inputListPurchaseLines[index].ordered_quantity - vm.model.inputListPurchaseLines[index].delivered_quantity - vm.model.inputListPurchaseLines[index].quantity_received_now) < 0) {
                                vm.model.inputListPurchaseLines[index].quantity_rest = 0;
                            } else {
                                vm.model.inputListPurchaseLines[index].quantity_rest = vm.model.inputListPurchaseLines[index].ordered_quantity - vm.model.inputListPurchaseLines[index].delivered_quantity - vm.model.inputListPurchaseLines[index].quantity_received_now;
                            }
                            break;
                        case 'ordered_quantity':
                            if ((vm.model.inputListPurchaseLines[index].ordered_quantity - vm.model.inputListPurchaseLines[index].delivered_quantity - vm.model.inputListPurchaseLines[index].quantity_received_now) < 0) {
                                vm.model.inputListPurchaseLines[index].quantity_rest = 0;
                            } else {
                                vm.model.inputListPurchaseLines[index].quantity_rest = vm.model.inputListPurchaseLines[index].ordered_quantity - vm.model.inputListPurchaseLines[index].delivered_quantity - vm.model.inputListPurchaseLines[index].quantity_received_now;
                            }
                            break;
                        default:
                            let quantity_received_now = roundToFour(Number(vm.model.inputListPurchaseLines[index].quantity_received_now.replace(',', '.')));

                            let purch_number_factor = roundToFour(Number(vm.model.inputListPurchaseLines[index].purch_number_factor.replace(',', '.')));

                            // BJS 20231207 - Fix to avoid situations where the base_factor is too small due to decimal rounding.
                            // First round to four decimals.
                            var base_factor = roundToFour(quantity_received_now / purch_number_factor);
                            
                            // Then make sure the calculated amount is equal or larger than the received quantity
                            while ((base_factor * purch_number_factor) < quantity_received_now) {
                                base_factor += 0.0001;
                            }
                            
                            let sales_price_factor = roundToFour(Number(vm.model.inputListPurchaseLines[index].sales_price_factor.replace(',', '.')));
                            
                            let quantity_received_now_salesprice = roundToFour(Number(base_factor * sales_price_factor));
                            
                            vm.model.inputListPurchaseLines[index].quantity_received_now_stockunits = base_factor;
                            vm.model.inputListPurchaseLines[index].quantity_received_now_salesprice = quantity_received_now_salesprice;

                            var ln_ordered_quantity = roundToFour(Number(vm.model.inputListPurchaseLines[index].ordered_quantity.replace(',', '.')));

                            if (quantity_received_now < ln_ordered_quantity) {
                                vm.model.inputListPurchaseLines[index].delivered_less = '1';
                            } else {
                                vm.model.inputListPurchaseLines[index].delivered_less = '0';
                            }
                            break;
                    }
                                
                }, 50);
            }

            // BJS - custom rounding function because the toFixed function will not round correct in all cases.
            function roundToFour(num) {
                return + (Math.round(num + "e+4") + "e-4");
            }

            // ## SAVE BUTTON FUNCTION ##

            vm.saveScanning = function () {
                vm.model.lockedSaveScanning = true;
                vm.model.settingAddPurchLine.savefunction = 'controlled';

                batchDeliveryAddPurchLineService.saveScan(vm.model.settingAddPurchLine).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveScanning = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSaveScanning = false;

                        loadBatchDeliveryAddPurchLine();
                    }
                });
            };

            vm.saveScanningRed = function () {
                vm.model.lockedSaveScanningRed = true;
                vm.model.settingAddPurchLine.savefunction = 'controlled';

                batchDeliveryAddPurchLineService.saveScan(vm.model.settingAddPurchLine).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveScanningRed = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSaveScanningRed = false;

                        loadBatchDeliveryAddPurchLine();
                    }
                });
            };

            vm.savePlace = function () {
                vm.model.lockedSavePlace = true;
                vm.model.settingAddPurchLine.savefunction = 'placed';

                batchDeliveryAddPurchLineService.saveScan(vm.model.settingAddPurchLine).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSavePlace = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSavePlace = false;
                        loadBatchDeliveryAddPurchLine();
                    }
                });
            };

            vm.savePlaceRed = function () {
                vm.model.lockedSavePlaceRed = true;
                vm.model.settingAddPurchLine.savefunction = 'placed';

                batchDeliveryAddPurchLineService.saveScan(vm.model.settingAddPurchLine).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSavePlaceRed = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSavePlaceRed = false;
                        loadBatchDeliveryAddPurchLine();
                    }
                });
            };

            vm.saveBatchDeliveryAddPurchLine = function () {
                vm.model.lockedSave = true;
            
                var mergeObj = {
                    batchdelivery_keyno: vm.model.settingAddPurchLine.batchdelivery_keyno,
                    records: vm.model.inputListPurchaseLines
                };

                batchDeliveryAddPurchLineService.save(mergeObj).then(function (data) {
                    vm.model.lockedSave = false;
                    stateService.back('/' + data[0].webpagename + '/' + data[0].parms);
                });
            };

            // ## RECALCULATION FUNCTIONS ##

            var recalcQuantity = function () {
                vm.model.settingAddPurchLine.quantity = utilityService.parseNumber(vm.model.settingAddPurchLine.colli_quantity) * utilityService.parseNumber(vm.model.settingAddPurchLine.stock_quantity_pr_colli);
            };

            // ## WATCH FUNCTIONS ##

            vm.onColliQuantityChanged = function (value) {
                vm.model.settingAddPurchLine.colli_quantity = value;
                recalcQuantity();
            }

            vm.onPurchlineSelected = function (value) {
                vm.model.settingAddPurchLine.purchline_keyno_manual = value;

                if (vm.model.settingAddPurchLine.purchline_keyno_manual !== '0') {
                    loadScan();
                }
            }
		}]
	});
})();
