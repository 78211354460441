(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travelWageMenu', {
        templateUrl: 'views/components/views/travelWageMenu/travelWageMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'travelWageMenuService', function ($stateParams, stateService, modalService, translateService, travelWageMenuService) {
            
            // ## VARIABLES & DEFINITIONS ##
            
            let vm = this;
            let travelWageId = $stateParams.travelwage_id;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                menu: {},
                lockedDelete: false
            };
            
            // ## LOAD PROCEDURE CALLS ##

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadMenu = function () {
                travelWageMenuService.getTravelWageMenu({ travelwage_id: travelWageId }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'travelwage':
                        go({ travelwage_id: vm.model.menu.travelwage_id });
                        break;
                    case 'checklistitemtws':
                        go({
                            argtype: 'travelwage_id',
                            argvalue: vm.model.menu.travelwage_id
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                travelWageMenuService.deleteItem(vm.model.menu).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedDelete = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedDelete = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
