(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("niService", [function () {
        var service = {
            number_input: {
                number: '',
                webpage_name: ''
            }
        };

        return service;
    }]);
})();