import { Component, Inject, Injectable } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent, ConfirmModalData } from '../components/grid/modals/confirm-modal/confirm-modal.component';
import { firstValueFrom } from 'rxjs';
import { ErrorModalComponent, ErrorModalData } from '../components/grid/modals/error-modal/error-modal.component';
import { ProgressModalComponent } from '../components/grid/modals/progress-modal/progress-modal.component';
import { SignaturePadModalComponent } from '../components/grid/modals/signature-pad-modal/signature-pad-modal.component';

export interface ModalConfig {
    type?: ModalType;
    title?: string;
    message?: string;
    buttons?: Array<{
        label: string;
        close?: boolean;
        action?: (modalInstance: MatDialogRef<any>) => void;
        type?: 'link' | 'primary' | 'secondary' | 'success' | 'danger' | 'warning';
    }>;
}

export type ModalType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'none';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private dialog: MatDialog) {}

    /**
     * Opens a confirmation modal using the given strings to convey what to confirm.
     *
     * @param param configuration for the confirm modal.
     * @returns a promise returning `true` if the user confirmed, `false` or undefined if the user cancelled.
     */
    public async openConfirmDialog({ type = 'none', title, message, ok = 'ok', cancel = 'cancel' }: ConfirmModalData) {
        const dialogRef: MatDialogRef<ConfirmModalComponent, boolean> = this.dialog.open(ConfirmModalComponent, { data: { type: type, title: title, message: message, ok: ok, cancel: cancel } });

        const result = await firstValueFrom(dialogRef.afterClosed());
        if (result === true) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Opens a error dialog displaying the given message. The message must be translated beforehand.
     *
     * @param message the error message to display in the error dialog.
     * @returns a promise which resolves once the modal as been closed.
     */
    public async openErrorDialog(message: string) {
        const dialogRef: MatDialogRef<ErrorModalComponent, undefined> = this.dialog.open(ErrorModalComponent, { data: <ErrorModalData>{ message: message }, minWidth: '37rem' });

        return await firstValueFrom(dialogRef.afterClosed());
    }

    /**
     * Open a progress dialog displaying the current progress
     * @param value
     * @param max
     * @param rememberId
     * @returns
     */

    public openProgressDialog(value: number, max: number, rememberId?: string | null): ProgressModalComponent {
        const dialogRef: MatDialogRef<ProgressModalComponent> = this.dialog.open(ProgressModalComponent, { data: { value: value, max: max, rememberId: rememberId }, width: '60rem' });

        return dialogRef.componentInstance;
    }

    /**
     * Opens a signaturePad Dialog
     * @returns a signature as SVG
     */
    public async openSignaturePadDialog(): Promise<string | undefined> {
        const dialogRef: MatDialogRef<SignaturePadModalComponent> = this.dialog.open(SignaturePadModalComponent);

        const result = await dialogRef.afterClosed().toPromise(); // Wait until the dialog is closed

        if (result) {
            console.log('Returned signature data URL:', result);
            return result; // This will be the signature data URL if it was returned
        } else {
            console.log('Dialog was closed without a signature.');
            return undefined; // No signature was provided
        }
    }

    public show(config: ModalConfig): Promise<void> {
        return new Promise<void>((resolve) => {
            const dialogRef = this.dialog.open(MessageDialogComponent, {
                width: '500px',
                data: {
                    type: config.type || 'secondary',
                    title: config.title || 'Default Title',
                    message: config.message || 'Default Message',
                    buttons: config.buttons || [{ label: 'Ok', close: true, type: config.type }],
                },
            });

            dialogRef.afterClosed().subscribe(() => {
                resolve();
            });
        });
    }
}

@Component({
    selector: 'app-message-dialog',
    template: `
        <h2 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle [ngClass]="!!model.type && model.type !== 'none' ? 'tt-' + model.type + '-background' : ''" [style.fontSize]="'var(--tt-font-size-xl)'">
            {{ model.title }}
        </h2>
        <mat-dialog-content>
            <p [style.fontSize]="'calc(var(--tt-font-size) + 2px)'" style="padding: 40px 50px; padding-bottom: 20px; color: var(--tt-body-text-color)">
                {{ model.message }}
            </p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <tt-button *ngFor="let button of model.buttons; index as i" class="col-xs-3 sp-2" [ttText]="button?.label || ''" [ttType]="button?.type ?? 'secondary'" (ttClick)="buttonClicked(i)"></tt-button>
            <!-- <tt-button class="col-xs-3 sp-2" ttText="ok" [ttType]="!data.type || data.type === 'none' ? 'primary' : data.type" (ttClick)="dialogRef.close(true)" [ttStyle]="style"></tt-button> -->
        </mat-dialog-actions>
    `,
})
export class MessageDialogComponent {
    model: ModalConfig;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.model = data || ({} as ModalConfig);
    }

    buttonClicked(index: number): void {
        if (!this.model) return;
        if (!this.model.buttons) return;
        if (this.model.buttons.length < index + 1) return;

        const button = this.model.buttons[index];

        if (button.close) {
            this.dialogRef.close();
        } else if (button.action) {
            button.action(this.dialogRef);
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}
