(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('domObjectsView', {
        templateUrl: 'views/components/views/domObjectsView/domObjectsView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'domObjectsViewService', 'languageService', 'rememberService', function ($stateParams, $q, stateService, utilityService, domObjectsViewService, languageService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let domObjectKeyno = $stateParams.domobject_keyno;

            let variableNames = {
                language_id: '',
                gb_domobjectsview_main: ''
            };

            vm.model = {
                bgDomObjectsViewMain: {
                    selected: 'OBJECT',
                    buttons: [
                        { id: 'OBJECT', label: 'gb_domobjectsview_main_object', color: 'primary', onClick: () => setDomObjectsViewMainView('OBJECT') },
                        { id: 'CHILDS', label: 'gb_domobjectsview_main_childs', color: 'primary', onClick: () => setDomObjectsViewMainView('CHILDS') }
                    ]
                },
                setting: {},
                edit: {},
                selectListLanguages: [],
                treeListView: [],
                gridChildReady: false
            };

            vm.gridChild = {
                dataTask: {
                    rememberId: 'w_domobjectsview_child_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: true,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setDomObjectsViewMainView = function (id) {
                vm.model.bgDomObjectsViewMain.selected = vm.model.setting.gb_domobjectsview_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();
                
                domObjectsViewService.getDomObjectsView({ domobject_keyno: domObjectKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    stateService.setTitleOverride(vm.model.setting.domobject_name);
                    setDomObjectsViewMainView(vm.model.setting.gb_domobjectsview_main);

                    $q.all([
                        loadTreeView()
                    ]).then(() => {
                        loadGridChild();
                        deferred.resolve();
                    });
            	});

                return deferred.promise;
            };

            let loadGridChild = function () {
                vm.gridChild.dataTask.loadData = {
                    method: 3048,
                    parameters: vm.model.setting
                };

                vm.model.gridChildReady = true;
            };

            let loadLanguages = function () {
                languageService.list().then(function (result) {
                    angular.copy(result, vm.model.selectListLanguages);
                });
            };

            let loadTreeView = function () {
                vm.model.treeListView = [];

                domObjectsViewService.listTreeView(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.treeListView);
                });
            };

            vm.updateTreeView = function () {
                loadTreeView();
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'gb_domobjectsview_main':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '.' + vm.model.setting.domobject_keyno;
                    		break;
                    	default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'language_id':
                    case 'gb_domobjectsview_main':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TREE SELECTION FUNCTION

            vm.selectTreeItem = function (item) {
                vm.model.setting.domobject_keyno_selected = item.domobject_keyno;
                vm.model.setting.domobjectchild_keyno = item.domobjectchild_keyno;
                vm.model.setting.domobject_keyno_parent = item.domobject_keyno_parent;

                loadGridChild();
                vm.gridChild.gridfunc.rebind();
            };

            // #endregion TREE SELECTION FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgDomObjectsViewMain = function (value) {
                vm.model.bgDomObjectsViewMain.selected = value;
                vm.model.setting.gb_domobjectsview_main = vm.model.bgDomObjectsViewMain.selected;

                rememberFunc('gb_domobjectsview_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'domobjectchild':
                        go({
                            domobject_keyno_parent: vm.model.setting.domobject_keyno_selected,
                            domobjectchild_keyno: '0',
                            language_id: vm.model.setting.language_id,
                            domobject_keyno_selected: vm.model.setting.domobject_keyno_selected
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'language_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.language_id) !== true) return;

                        vm.model.setting.language_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.language_id, true) !== true) return;

                        rememberFunc('language_id');
                        loadTreeView();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadLanguages();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
