(function () {
    'use strict';

    angular.module("imApp").factory("userUsergroupEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        let service = {
            loadUser: function (userKeyno) {
                var deferred = $q.defer();

                $ihttp.post({ method: 65, parameters: { p2_usergroup_portal_user_keyno: userKeyno } }).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            saveUser: function (user) {
                return $ihttp.post({ method: 66, parameters: user });
            }
        };

        return service;
    }]);
})();
