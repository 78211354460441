(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttDatetime', {
        templateUrl: 'views/components/directives/ttDatetime/ttDatetime.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttOnBlur: '&',
            ttTextAlign: '@',
            ttDateOptions: '<',     // Object, optional, any property can be left out: { "date": {"show": true, "disabled": false, align: 'right'}, "hour": {"show": true, "disabled": false, align: 'right'}, "minute": {"show": true, "disabled": false, align: 'right', "interval": 10}}
            ttItemId: '@',
            ttButtons: '<',
            ttButtonsParm: '<',
            ttRequired: '@',
            ttReadonly: '@',
            ttTabable: '@',         // RHE 20240319 can turn of ability to tab to it
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttStyle: '<',           // ODL 20230327
            ttClass: '<',           // JLR 20230706
        },
        controller: ['$element', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            vm.required = false;

            var hasButtons = false;
            let labelAlwaysOnTop = false;

            var onDestroy = [];

            vm.datePickerOptions = {
                formatYear: 'yy',
                startingDay: 1,
                datepickerAppendToBody: true,
            };

            vm.dateOptions = {
                date: {
                    id: uuid(),
                    show: true,
                    disabled: false,
                    align: 'right'
                },
                hour: {
                    id: uuid(),
                    show: true,
                    disabled: false,
                    align: 'right'
                },
                minute: {
                    id: uuid(),
                    show: true,
                    disabled: false,
                    align: 'right',
                    interval: 1
                },
                labelAlwaysOnTop: false,    // Deprecated, use ttLabelView instead. - JLR 20230622
                nullable: false
            };

            vm.hours = [];
            vm.minutes = [];

            for (var i = 0; i < 24; i++) {
                vm.hours.push({ hour: i < 10 ? '0' + i : i.toString() });
            }

            vm.datetime = {
                date: '',
                hour: '',
                minute: ''
            };

            vm.style = {
                base: {},
                label: {},
                group: {},
                date: {
                    //padding: '5px 11px'
                },
                hour: {
                    //padding: '5px 11px'
                },
                minute: {
                    //padding: '5px 11px'
                },
                icon: {},
                labelAlwaysOnTop: false     // ODL 20230327 // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                date: '',
                hour: '',
                minute: '',
                inputGroup: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            function isDateValid(dateStr) {
                return !isNaN(new Date(dateStr));
            }

            var onChanged = function (value) {

                if (angular.isFunction(vm.ttChange) !== true)
                    return;

                // Default verdi ved invalid date. Dette er det som vises i input-feltet (ttModel).
                let datetimeString = '';

                if (isDateValid(value) || value === '') {
                    let format = vm.dateOptions.date.show === true ? 'YYYY-MM-DD' : '';

                    format += vm.dateOptions.hour.show === true ? (vm.dateOptions.date.show === true ? ' ' : '') + 'HH' : '';
                    format += vm.dateOptions.minute.show === true ? (vm.dateOptions.hour.show === true ? ':' : (vm.dateOptions.date.show === true ? ' ' : '')) + 'mm' : '';

                    if (value !== '') datetimeString = moment(value).format(format);

                    datetimeString = datetimeString === '1900-01-01' && vm.dateOptions.nullable === true ? '' : datetimeString;
                }

                vm.ttChange({ $value: datetimeString, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
            };

            // Ved endring av felt
            vm.onDateChanged = function (value) {
                if (angular.isDate(value) !== true) {
                    if (vm.dateOptions?.nullable !== true) {
                        vm.datetime.date = new Date('1900-01-01');
                        value = vm.datetime.date;
                    }
                }

                console.dir(vm.datetime.dateViewValue);
                onChanged(value);
            };

            // Ved endring av felt
            vm.onHourChanged = function (value) {
                if (angular.isDate(vm.datetime.date) !== true) {
                    return;
                }
                if (angular.isUndefined(value))
                    return;

                vm.datetime.date.setHours(value);

                onChanged(vm.datetime.date);
            };

            // Ved endring av felt
            vm.onMinuteChanged = function (value) {
                if (angular.isDate(vm.datetime.date) !== true)
                    return;
                if (angular.isUndefined(value))
                    return;

                vm.datetime.date.setMinutes(value);

                onChanged(vm.datetime.date);
            };

            function hasMultipleDateInputs() {
                return [vm.dateOptions.date.show, vm.dateOptions.hour.show, vm.dateOptions.minute.show, hasButtons].filter((item) => item === true).length > 1;
            }

            let setClasses = (labelAlwaysOnTop) => {
                vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
                vm.class.inputGroup = hasMultipleDateInputs() ? 'input-group' : '';
                Object.keys(vm.class).forEach((element) => {
                    if (vm.ttClass?.[element] && !vm.class[element].includes(vm.ttClass[element])) {
                        vm.class[element] += ` ${vm.ttClass[element]}`;
                    }
                });
            }

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, mainElement: ['date', 'hour', 'minute'], textAlign: vm.ttTextAlign }), vm.style);

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);

                vm.style.hour.fontSize = info.fontSizes.textSize;
                vm.style.hour.height = info.height + 'px';
                //vm.style.hour.paddingTop = info.padding.top + 'px';
                //vm.style.hour.paddingRight = info.padding.right + 'px';
                //vm.style.hour.paddingRight = '0px';
                //vm.style.hour.paddingBottom = info.padding.bottom + 'px';
                vm.style.hour.paddingLeft = info.padding.left + 'px';
                //vm.style.hour.paddingLeft = '0px';
                vm.style.hour.minWidth = '38px';

                vm.style.minute.fontSize = info.fontSizes.textSize;
                vm.style.minute.height = info.height + 'px';
                //vm.style.minute.paddingTop = info.padding.top + 'px';
                //vm.style.minute.paddingRight = info.padding.right + 'px';
                //vm.style.minute.paddingRight = '0px';
                //vm.style.minute.paddingBottom = info.padding.bottom + 'px';
                vm.style.minute.paddingLeft = info.padding.left + 'px';
                //vm.style.minute.paddingLeft = '0px';
                vm.style.minute.minWidth = '38px';

                labelAlwaysOnTop = info.labelAlwaysOnTop;

                setStyle(vm.ttStyle);
                setClasses(labelAlwaysOnTop || vm.style.labelAlwaysOnTop || vm.dateOptions.labelAlwaysOnTop);
            });

            vm.onBtnClick = (btn) => ttDirectivesService.onButtonClick({ button: btn, parameter: vm.ttButtonsParm, controller: vm });

            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            }
            // Ved endring fra parent
            vm.$onChanges = function (changes) {

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttModel)) {

                    if (vm.dateOptions.nullable === true && !changes.ttModel.currentValue) {
                        vm.datetime.date = '';
                        vm.datetime.hour = '';
                        vm.datetime.minute = '';
                    } else {
                        var date;

                        if (angular.isDefined(changes.ttModel.currentValue) && changes.ttModel.currentValue !== null) {
                            // 20231103 - Fix for bug in older iOS (non-iso date formats)
                            changes.ttModel.currentValue = changes.ttModel.currentValue.replace(/-/g, "/");

                            if (['Invalid date', ''].includes(changes.ttModel.currentValue)) {

                                // 20231103 - We need to use slashes in dates because of strict iso standards on older safari browsers
                                date = new Date('1900/01/01');

                                date.setHours(0);
                                date.setMinutes(0);
                                date.setSeconds(0);
                                date.setMilliseconds(0);
                            } else {
                                // If we need to remove milliseconds
                                changes.ttModel.currentValue = changes.ttModel.currentValue.substring(0, 16);

                                let parts1 = changes.ttModel.currentValue.split(' ');

                                if (parts1.length > 1) {
                                    // should contain date and time
                                    date = new Date(changes.ttModel.currentValue);
                                } else {
                                    // should contain date or time
                                    let parts = changes.ttModel.currentValue.split(':');

                                    if (parts.length === 2 || parts.length === 3) {
                                        // currentValue contains only time value.
                                        date = new Date();
                                        date.setHours(parts[0]);
                                        date.setMinutes(parts[1]);

                                        if (parts.length === 3) {
                                            let sparts = parts[2].split('.');

                                            if (sparts.length === 1) {
                                                date.setSeconds(parts[2]);
                                            } else {
                                                date.setSeconds(sparts[0]);
                                                date.setMilliseconds(sparts[1]);
                                            }
                                        }
                                    } else {
                                        date = new Date(changes.ttModel.currentValue);
                                    }
                                }
                            }
                        } else {
                            date = new Date();
                        }

                        var hour = date.getHours() < 10 ? '0' + date.getHours().toString() : date.getHours().toString();
                        var minute = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes().toString();

                        vm.datetime.date = date;
                        vm.datetime.hour = hour;
                        vm.datetime.minute = us.isNumber(minute) ? minute : '';

                        var hasOwnMinute = false;

                        for (var m = 0; m < vm.minutes.length; m++) {
                            if (vm.minutes[m].minute === vm.datetime.minute) {
                                hasOwnMinute = true;
                                break;
                            }
                        }

                        if (!hasOwnMinute) {
                            vm.minutes.unshift({ minute: vm.datetime.minute });
                        }

                        //if (angular.isUndefined(changes.ttModel.currentValue) || angular.isDefined(changes.ttModel.currentValue) && changes.ttModel.currentValue !== '') {
                        //    onChanged(vm.datetime.date);
                        //}
                    }
                    onChanged(vm.datetime.date);

                    if (vm.ttReadonly === 'true') {
                        vm.datetime.dateViewValue = vm.ttModel?.substring(0, 10) ?? '';
                    }
                }

                if (angular.isDefined(changes.ttDateOptions)) {
                    var setOptionValue = function (type, prop) {
                        if (angular.isUndefined(changes.ttDateOptions.currentValue[type][prop]))
                            return;

                        vm.dateOptions[type][prop] = changes.ttDateOptions.currentValue[type][prop];
                    };

                    var setOptions = function (type) {
                        if (angular.isUndefined(changes.ttDateOptions.currentValue[type]))
                            return;

                        if (type === 'labelAlwaysOnTop') {
                            vm.dateOptions[type] = changes.ttDateOptions.currentValue[type];
                        } else if (type === 'nullable') {
                            vm.dateOptions[type] = changes.ttDateOptions.currentValue[type];
                        } else {
                            setOptionValue(type, 'show');
                            setOptionValue(type, 'disabled');
                            setOptionValue(type, 'interval');
                            setOptionValue(type, 'align');
                        }
                    };

                    setOptions('date');
                    setOptions('hour');
                    setOptions('minute');
                    setOptions('labelAlwaysOnTop');
                    setOptions('nullable');

                    if (vm.dateOptions.date.align !== 'right' && ttDirectivesService.isValidAlign(vm.dateOptions.date.align)) {
                        vm.style.date.textAlign = vm.dateOptions.date.align;
                    }

                    if (vm.dateOptions.hour.align !== 'right' && ttDirectivesService.isValidAlign(vm.dateOptions.hour.align)) {
                        vm.style.hour.textAlign = vm.dateOptions.hour.align;
                    }

                    if (vm.dateOptions.minute.align !== 'right' && ttDirectivesService.isValidAlign(vm.dateOptions.minute.align)) {
                        vm.style.minute.textAlign = vm.dateOptions.minute.align;
                    }

                    if (vm.dateOptions.minute.show) {
                        vm.minutes = [];

                        for (var i = 0; i < 60; i += vm.dateOptions.minute.interval) {
                            var t = i < 10 ? '0' + i : i.toString();

                            vm.minutes.push({ minute: t });
                        }
                    }

                    //vm.style.date.backgroundColor = vm.dateOptions.date.disabled === true ? '#eee' : '#fff';
                    //vm.style.hour.backgroundColor = vm.dateOptions.hour.disabled === true ? '#eee' : '#fff';
                    //vm.style.minute.backgroundColor = vm.dateOptions.minute.disabled === true ? '#eee' : '#fff';

                    if (vm.dateOptions.date.show && (vm.dateOptions.hour.show || vm.dateOptions.minute.show)) {
                        vm.style.date.borderRight = "0";
                    }

                    if (vm.dateOptions.hour.show && vm.dateOptions.minute.show) {
                        vm.style.hour.borderRight = "0";
                    }

                    if (angular.isDefined(changes.ttButtons) && angular.isArray(changes.ttButtons.currentValue)) {
                        hasButtons = changes.ttButtons.currentValue.length > 0;
                    }
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttTabable)) {
                    if (us.toBoolean(changes.ttTabable.currentValue) === false || changes.ttTabable.currentValue === '-1') {
                        vm.tabable = '-1';
                    } else {
                        vm.tabable = '';
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);
                }

                setClasses(labelAlwaysOnTop || vm.style.labelAlwaysOnTop || vm.dateOptions.labelAlwaysOnTop);
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
