(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srUsersService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrUsersGet: function () {
                return p2DataTaskService.call(1463, {});
            },
            loadSrUsersList: function (searchtext, show_deleted_users) {
                return p2DataTaskService.call(1464, {
                    searchtext: searchtext,
                    show_deleted_users: show_deleted_users
                });
            }
        };

        return service;
    }]);
})();