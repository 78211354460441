(function() {
    'use strict';

    angular.module("imApp").factory("shipmentService", ['$ihttp', function ($ihttp) {
        var service = {
            load: function () {
                return $ihttp.post({
                    method: 2316,
                    parameters: { }
                });
            },

            details: function (shipmentcontainerKeyno) {
                return $ihttp.post({
                    method: 2307,
                    parameters: {
                        shipmentcontainer_keyno: shipmentcontainerKeyno
                    }
                });
            },

            search: function (value) {
                return $ihttp.post({
                    method: 2310,
                    parameters: {
                        searchterm: value
                    }
                });
            },

            searchKeyname: function (value) {
                return $ihttp.post({
                    method: 2297,
                    parameters: {
                        searchtext: value
                    }
                });
            },

            save: function (shipment) {
                return $ihttp.post({
                    method: 2315,
                    parameters: shipment
                });
            }
        }

        return service;
    }]);
})();
