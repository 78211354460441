(function () {
    'use strict';

    var module = angular.module('imApp');

    module.directive('imRadioDirective', ['layoutService', function (layoutService) {
        var directive = {
            restrict: 'E',
            scope: {
                label: '@',         // Used for translation of label PS: Translation not active yet!!
                typevar: '@',
                btnStyle: '@',      // Default 0
                listItems: '=',
            },
            template:   '<div class="form-group">' +
                            '<div class="col-xs-12">' +
                                '<div class="col-xs-12 im-no-pad">' +
                                    '<label class="name-label" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">{{label}}</label>' +
                                    '<label ng-if="typevar.indexOf(\'q\') > -1" class="name-label-required" ng-style="{\'fontSize\': model.fontSizes.textSize}">*</label>' +
                                '</div>' +
                                '<div ng-if="btnStyle == undefined || (btnStyle.indexOf(\'1\') == -1 && btnStyle.indexOf(\'2\') == -1)" ng-repeat="item in listItems" class="col-xs-12 im-radio-line">' +
                                    '<input class="im-radio-btn" type="radio" ng-model="model.radioselect" value="{{item.item_id}}" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">' +
                                    '<label class="radio-label" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">{{item.item_name}}</label>' +
                                '</div>' +
                                '<div ng-if="btnStyle.indexOf(\'1\') > -1" ng-repeat="item in listItems" class="col-xs-12 im-radio-line">' + //Error in radio button size if label is very small
                                    '<input class="" type="radio" ng-model="model.radioselect" value="{{item.item_id}}" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">' +
                                    '<label class="radio-label" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">{{item.item_name}}</label>' +
                                '</div>' +
                                '<div ng-if="btnStyle.indexOf(\'2\') > -1" ng-repeat="item in listItems" class="col-xs-12">' +
                                    '<input class="im-radio-btn" type="radio" ng-model="model.radioselect" value="{{item.item_id}}" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">' +
                                    '<label class="radio-label" ng-style="{\'fontSize\': model.fontSizes.textSizeS}">{{item.item_name}}</label>' +
                                '</div>' +
                            '</div>' +
                        '</div>',
            
            link: function ($scope) {
                $scope.model = {
                    radioselect: '',
                    fontSizes: {}
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    angular.copy(info.fontSizes, $scope.model.fontSizes);
                });
            }
        };

        return directive;
    }]);
})();