(function() {
    'use strict';

    angular.module("imApp").factory("interbranchMultiInvoicingService", ['$ihttp', function ($ihttp) {
        var service = {
            makeInvoice: function (shipmentContainerKeyno, invoiceDate) {
                return $ihttp.post({
                    method: 3237,
                    parameters: {
                        shipmentcontainer_keyno: shipmentContainerKeyno,
                        invoice_date: invoiceDate
                    }
                });
            },

        }

        return service;
    }]);
})();
