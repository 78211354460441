(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bspSub', {
        templateUrl: 'views/components/views/bspSub/bspSub.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'modalService', 'translateService', 'bspSubService', function ($stateParams, stateService, base64, modalService, translateService, bspSubService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let bspSubKeyno = $stateParams.bspsub_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                readyHtmlDescriptionBase64: true,
                lockedSave: false
            };

            vm.editor = {
                ready: true,
                style: {
                    div: {
                        minHeight: '150px',
                        height: '95vh',
                        maxHeight: 'calc(95vh - 220px)',
                    },
                },
                class: {
                    base: 'col-xs-12 col-sm-12 col-xl-12 col-xxl-12 sp-0',
                    label: 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1 sp-0',
                    input: 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11 sp-0',
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
            	bspSubService.getEdit({ bspsub_keyno: bspSubKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                    vm.model.edit.html_description_base64 = base64.urldecode(vm.model.edit.html_description_base64); 
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                vm.model.edit.html_description_base64 = base64.urlencode(vm.model.edit.html_description_base64);
                
                bspSubService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
