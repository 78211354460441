(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlanBuild', {
        templateUrl: 'views/components/views/consignmentPlanBuild/consignmentPlanBuild.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'modalService', 'ieScreenBlockService', 'consignmentPlanBuildService', function ($stateParams, $timeout, stateService, modalService, ieScreenBlockService, consignmentPlanBuildService) {
            var vm = this;

            // ## VARIABLES & DEFINITIONS ##

            vm.model = {
                custactNo: $stateParams.custact_no,
                deliveryMethodButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => clearDeliveryMethod() }
                ],
                transportAreaButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => clearTransportArea() }
                ],
                filterValueBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                bgConsignmentPlanBuildFunction: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_consignmentplanbuild_function_add', item_func: 'ADD' },
                        { item_id: '1', item_name: 'gb_consignmentplanbuild_function_remove', item_func: 'REMOVE' },
                        { item_id: '2', item_name: 'gb_consignmentplanbuild_function_split', item_func: 'SPLIT' }
                    ]
                },
                bgConsignmentPlanBuildAdd: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_consignmentplanbuild_add_select', item_func: 'SELECT' },
                        { item_id: '1', item_name: 'gb_consignmentplanbuild_add_vieworder', item_func: 'VIEWORDER' }
                    ]
                },
                build: {},
                selectListStockPlaces: [],
                selectListDeliveryMethods: [],
                selectListTransportAreas: [],
                itemsListBuilds: [],
                itemsListBuildOns: [],
                itemsListSplits: [],
                retrievingBuilds: false,
                lockedSave: false,
                lockedRemove: false
            };

            var initLoadDone = false;
            var timerInitLoadDone = null;
            var spinStopTimer = null;

            // ## LOAD PROCEDURE CALLS ##

            consignmentPlanBuildService.loadConsignmentPlanBuildGet(vm.model.custactNo).then(function (data) {
                vm.model.build = angular.copy(data[0]);

                loadStockPlaces();
                loadDeliveryMethods();
                //ieScreenBlockService.start();
                loadConsignmentPlanBuilds();
                loadTransportAreas();
                loadConsignmentPlanBuildOns();

                if (angular.isDefined(timerInitLoadDone)) {
                    $timeout.cancel(timerInitLoadDone);
                }

                timerInitLoadDone = $timeout(function () {
                    initLoadDone = true;
                }, 50);
            });

            var loadStockPlaces = function () {
                vm.model.selectListStockPlaces = [];

                consignmentPlanBuildService.loadConsignmentPlanningStockPlacesList().then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            var loadDeliveryMethods = function () {
                vm.model.selectListDeliveryMethods = [];

                consignmentPlanBuildService.loadDeliveryMethodSelectList('1').then(function (data) {
                    angular.copy(data, vm.model.selectListDeliveryMethods);
                });
            };

            var loadTransportAreas = function () {
                if (angular.isUndefined(vm.model.build.stockplace_id) === true) return;
                if (vm.model.build.stockplace_id === null) return;
                if (vm.model.build.stockplace_id.trim().length < 1) return;

                vm.model.selectListTransportAreas = [];

                consignmentPlanBuildService.loadTransportAreaList('1', vm.model.build.stockplace_id).then(function (data) {
                    angular.copy(data, vm.model.selectListTransportAreas);
                });
            };

            var loadConsignmentPlanBuildOns = function () {
                consignmentPlanBuildService.loadConsignmentPlanBuildOnList(vm.model.custactNo).then(function (data) {
                    angular.copy(data, vm.model.itemsListBuildOns);
                });
            };

            var delaySpinStop = function () {
                if (angular.isDefined(spinStopTimer)) {
                    $timeout.cancel(spinStopTimer);
                }

                spinStopTimer = $timeout(function () {
                    vm.model.retrievingBuilds = false;
                }, 100);
            };

            var loadConsignmentPlanBuilds = function () {
                if (angular.isUndefined(vm.model.build.custact_no) === true || vm.model.build.custact_no === null || vm.model.build.custact_no.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.note) === true || vm.model.build.note === null) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.date_fom) === true || vm.model.build.date_fom === null || vm.model.build.date_fom.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.date_tom) === true || vm.model.build.date_tom === null || vm.model.build.date_tom.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.dateselector_index) === true || vm.model.build.dateselector_index === null || vm.model.build.dateselector_index.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.stockplace_id) === true || vm.model.build.stockplace_id === null || vm.model.build.stockplace_id.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.deliverymethod_no) === true || vm.model.build.deliverymethod_no === null || vm.model.build.deliverymethod_no.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.transportarea_keyno) === true || vm.model.build.transportarea_keyno === null || vm.model.build.transportarea_keyno.trim().length < 1) {
                    delaySpinStop();
                    return;
                }

                if (angular.isUndefined(vm.model.build.filtervalue) === true || vm.model.build.filtervalue === null) {
                    delaySpinStop();
                    return;
                }

                vm.model.itemsListBuilds = [];

                consignmentPlanBuildService.loadConsignmentPlanBuildList(vm.model.build).then(function (data) {
                    angular.copy(data, vm.model.itemsListBuilds);
                    vm.model.retrievingBuilds = false;
                    //ieScreenBlockService.stop();
                });
            };

            //     var validateParms = function (parm) {
            //if (angular.isUndefined(vm.model.build[parm]) === true) return false;
            //         if (vm.model.build[parm] === null) return false;
            //         if (vm.model.build[parm].trim().length < 1) return false;

            //         return true;
            //     };

            var loadConsignmentPlanBuildSplits = function () {
                //if (validateParms('custact_no') !== true) return;
                //if (validateParms('date_fom') !== true) return;
                //if (validateParms('date_tom') !== true) return;
                //if (validateParms('dateselector_index') !== true) return;
                //if (validateParms('stockplace_id') !== true) return;
                //if (validateParms('deliverymethod_no') !== true) return;
                //if (validateParms('transportarea_keyno') !== true) return;

                //if (angular.isUndefined(vm.model.build.note) === true) return;
                //if (vm.model.build.note === null) return;

                //if (angular.isUndefined(vm.model.build.filtervalue) === true) return;
                //if (vm.model.build.filtervalue === null) return;

                vm.model.itemsListSplits = [];

                consignmentPlanBuildService.loadConsignmentPlanBuildSplitList(vm.model.build).then(function (data) {
                    angular.copy(data, vm.model.itemsListSplits);
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var canRun616 = function (variableName, parm) {
                if (initLoadDone !== true) return false;
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.build[parm]) === true) return false;
                if (vm.model.build[parm] === null) return false;

                return true;
            };

            var rememberDateFom = function () {
                var variableNameDateFom = 'w_consignmentplanbuild.date_fom';

                if (canRun616(variableNameDateFom, 'date_fom') !== true) return;

                consignmentPlanBuildService.remember(variableNameDateFom, vm.model.build.date_fom);
            };

            var rememberDateTom = function () {
                var variableNameDateTom = 'w_consignmentplanbuild.date_tom';

                if (canRun616(variableNameDateTom, 'date_tom') !== true) return;

                consignmentPlanBuildService.remember(variableNameDateTom, vm.model.build.date_tom);
            };

            var rememberDateSelectorIndex = function () {
                var variableNameDateSelectorIndex = 'w_consignmentplanbuild.dateselector_index';

                if (canRun616(variableNameDateSelectorIndex, 'dateselector_index') !== true) return;

                consignmentPlanBuildService.remember(variableNameDateSelectorIndex, vm.model.build.dateselector_index);
            };

            // ## REFRESH BUTTON FUNCTION ##

            vm.retrieve = function () {
                vm.model.retrievingBuilds = true;
                loadConsignmentPlanBuilds();
            };

            // ## BUTTON GROUP FUNCTIONS ##

            vm.selectBgConsignmentPlanBuildFunction = function (item) {
                vm.model.bgConsignmentPlanBuildFunction.item_selected = item;

                if (vm.model.bgConsignmentPlanBuildFunction.item_selected === 'SPLIT') {
                    loadConsignmentPlanBuildSplits();
                }
            };

            vm.selectBgConsignmentPlanBuildAdd = function (item) {
                vm.model.bgConsignmentPlanBuildAdd.item_selected = item;
            };

            // ## BUTTON LIST FUNCTIONS ##

            function clearDeliveryMethod() {
                vm.model.build.deliverymethod_no = '0';
                onFieldChanged();
            }

            function clearTransportArea() {
                vm.model.build.transportarea_keyno = '0';
                onFieldChanged();
            }

            vm.filterValueBtnListFunc = function (item_func) {
                if (item_func === 'erase_item') {
                    vm.model.build.filtervalue = '';
                }
            };

            // ## TOGGLE FUNCTIONS ##

            vm.toggleChosen = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            //vm.selectAll = function () {
            //	angular.forEach(vm.model.itemsListBuilds, function (item) {
            //		if (angular.isUndefined(item.item_filtervalue) === true) return;
            //		if (item.item_filtervalue.toLowerCase().indexOf(vm.model.build.filtervalue.toLowerCase()) === '-1') return;
            //		if (item.item_id === '0') return;
            //		if (item.item_id === '-1') return;

            //		item.item_is_selected = '1';
            //	});
            //};

            //vm.unselectAll = function () {
            //	angular.forEach(vm.model.itemsListBuilds, function (item) {
            //		if (if (angular.isUndefined(item.item_filtervalue) === true) return;
            //		if (item.item_filtervalue.toLowerCase().indexOf(vm.model.build.filtervalue.toLowerCase()) === -1) return;
            //		if (item.item_id === '0') return;
            //		if (item.item_id === '-1') return;

            //		item.item_is_selected = '0';
            //	});
            //};

            //vm.setMarkedAs = function () {
            //	var selected = [];

            //	angular.forEach(vm.model.itemsListBuilds, function (item) {
            //		if (angular.isUndefined(item.item_filtervalue) === true) return;
            //		if (item.item_filtervalue.toLowerCase().indexOf(vm.model.build.filtervalue.toLowerCase()) === '-1') return;
            //		if (item.item_id === '0') return;
            //		if (item.item_id === '-1') return;
            //		if (item.item_is_selected === '0') return;

            //		selected.push(item);
            //	});
            //};

            vm.toggleChosen2 = function (item) {
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // ## SAVE BUTTON FUNCTION ##

            vm.saveConsignmentPlanBuild = function () {
                vm.model.lockedSave = true;

                consignmentPlanBuildService.save(vm.model.build.custact_no, vm.model.itemsListBuilds).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };

            // ## REMOVE BUTTON FUNCTION ##

            vm.removeBuildOn = function () {
                vm.model.lockedRemove = true;

                consignmentPlanBuildService.remove(vm.model.build.custact_no, vm.model.itemsListBuildOns).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedRemove = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedRemove = false;
                    }
                });
            };

            function onFieldChanged() {
                vm.retrieve();

                if (vm.model.bgConsignmentPlanBuildFunction.item_selected === 'SPLIT') {
                    loadConsignmentPlanBuildSplits();
                }
            }

            vm.onStockPlaceChanged = function (value) {
                vm.model.build.stockplace_id = value;

                if (value) {
                    loadTransportAreas();
                    onFieldChanged();
                }
            }

            vm.onDeliveryMethodChanged = function (value) {
                vm.model.build.deliverymethod_no = value;

                if (value) {
                    onFieldChanged();
                }
            }

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.build.date_fom = startDate;
                vm.model.build.date_tom = endDate;
                if (dateInterval !== -1) vm.model.build.dateselector_index = dateInterval;

                if (vm.model.build.date_fom) rememberDateFom();

                if (vm.model.build.date_tom) rememberDateTom();

                if (vm.model.build.dateselector_index) rememberDateSelectorIndex();

                if (vm.model.build.date_fom && vm.model.build.date_tom && vm.model.build.dateselector_index) {
                    vm.retrieve();

                    if (vm.model.bgConsignmentPlanBuildFunction.item_selected === 'SPLIT') {
                        loadConsignmentPlanBuildSplits();
                    }
                }
            }

            vm.onTransportAreaChanged = function (value) {
                vm.model.build.transportarea_keyno = value;

                if (value) {
                    onFieldChanged();
                }
            }
        }]
    });
})();