(function() {
    'use strict';

    angular.module("imApp").factory("componentReplacementService", ['$ihttp', function ($ihttp) {
        var service = {
            getComponentReplacement: function (parms) {
                return $ihttp.post({
                    method: 3245,
                    parameters: parms || {}
                });
            },
            save: function (edit) {
                console.dir(edit)
                return $ihttp.post({
                    method: 3246,
                    parameters: edit
                });
            },
            delete: function (edit) {
                return $ihttp.post({
                    method: 3254,
                    parameters: edit
                });
            },
            prodSearchKeyname: function (value, searchparam) {
                return $ihttp.post({
                    method: 3247,
                    parameters: {
                        searchtext: value,
                        searchparam: searchparam
                    }
                });
            }
            
        }

        return service;
    }]);
})();
