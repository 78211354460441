(function () {
	'use strict';

    angular.module("imApp").factory("travelBillRejectService", ['$ihttp', function ($ihttp) {
		let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1327,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1326,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
