(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("labourJobNoteService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2WLabourJobNoteGet: {},
            loadP2WLabourJobNoteGet: function (labour_no) {
                var deferred = $q.defer();

                var parmsP2WLabourJobNoteGet = {
                    method: 536,
                    parameters: {
                        labour_no: labour_no
                    }
                };

                $ihttp.post(parmsP2WLabourJobNoteGet).then(function (data) {
                    service.p2WLabourJobNoteGet = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2WLabourJobNote: function (p2WLabourJobNoteGet) {
                var deferred = $q.defer();

                var parmsP2WLabourJobNoteSave = {
                    method: 537,
                    parameters: p2WLabourJobNoteGet
                };

                $ihttp.post(parmsP2WLabourJobNoteSave).then(function (data) {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();