(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishProdPack', {
        templateUrl: 'views/components/views/poFinishProdPack/poFinishProdPack.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'poFinishProdPackService', 'stateService', 'modalService', 'ieScreenBlockService', 'printPreviewService', 'printService', 'fileService', 'cargonizerApiService', function ($stateParams, $timeout, poFinishProdPackService, stateService, modalService, ieScreenBlockService, printPreviewService, printService, fileService, cargonizerApiService) {
            var vm = this;
                    
            vm.model = {
                poh_keyno_top: $stateParams.poh_keyno_top,
                parmCounter: $stateParams.counter,
                bgPoFinishProdPackMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_pofinishprodpack_main_colli', item_func: 'COLLI' },
                        { item_id: '1', item_name: 'gb_pofinishprodpack_main_prodsizecolli', item_func: 'PRODSIZECOLLI' }
                    ]
                },
                orders: [],
                itemsListEdits: [],
                case_count: 1,
                checkedCount: 0,
                case_mode: 0,
    			printer: '',
                p2_reportdef_keyno: '',
    			printers: [],
                settings: {
                    printer_id: '',
                    report_name: ''
                }
            };
            
            if (!vm.model.parmCounter > 0){
                vm.model.parmCounter = 1;
            } else {
                vm.model.parmCounter = parseInt(vm.model.parmCounter) + 1;
            }
            
            vm.loadOrders = function () {
                poFinishProdPackService.loadOrders(vm.model.poh_keyno_top).then(function (data) {
                    vm.model.orders = [];
                    angular.copy(data, vm.model.orders);
                });
            };

            vm.loadOrders();

            poFinishProdPackService.listPoFinishProdPackEdits({ poh_keyno_top: vm.model.poh_keyno_top }).then(function (result) {
                angular.copy(result, vm.model.itemsListEdits);
            });
            
            vm.loadPrinters = function () {
                poFinishProdPackService.loadPrinters().then(function (data) {
                    vm.model.printers = [];

                    angular.forEach(data, function (item) {
                        item.item_id = item.item_cargonizer_printer_id;
                        item.item_name = item.item_name + ' ( ' + item.item_name_sub1 + ') ';
                        vm.model.printers.push(item);
                    });

                    loadSettings();
                });
            };

            vm.loadPrinters();
            
            function loadSettings () {
                poFinishProdPackService.loadSettings().then(function(data){
                    vm.model.settings = data[0];
                    vm.model.printer = vm.model.settings.printer_id;
                });
            }
            
            var reportDefinitions = {};
            
            vm.getReportByName = function () {
                poFinishProdPackService.getReportDefn(vm.model.settings.report_name)
                    .then(function (data) {
                        vm.model.p2_reportdef_keyno = data[0].p2_reportdef_keyno;

                        printPreviewService.loadP2ReportDef(vm.model.p2_reportdef_keyno).then(function (data) {
                            reportDefinitions = angular.copy(data[0]);
                        });
                    })
                    .catch(function (error) {
                        console.dir(error);
                    });
            };

            vm.getReportByName();
            
            vm.gridClick = function(item){
    			var iconclicked = angular.isDefined(item.item) ? false : true;
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;
                
                if ((currentItem.item_readytofinish == 0) && (currentItem.item_has_stockbatch > 0) && (currentItem.item_has_case == 0)){
                    if (currentItem.item_is_selected != '0'){
                        currentItem.item_is_selected = '0';
                    } else {
                        currentItem.item_is_selected = '1';  
                        vm.model.case_mode = 0;                  
                    }

                    vm.model.checkedCount = 0;
                    vm.model.case_count = 1;

                    angular.forEach(vm.model.orders,function(item){
                        if (item.item_has_case != vm.model.case_mode){
                            item.item_is_selected = '0';
                        }

                        if (item.item_is_selected != '0'){                        
                            vm.model.checkedCount += 1;

                            if (item.item_quantity > vm.model.case_count){
                                vm.model.case_count = item.item_quantity;
                            }
                        }                    
                    });
                } else if ((currentItem.item_readytofinish == 0) && (currentItem.item_has_stockbatch > 0) && (currentItem.item_has_case != 0)){                
                    if (currentItem.item_is_selected != '0'){
                        currentItem.item_is_selected = '0';
                    } else {
                        currentItem.item_is_selected = '1';   
                        vm.model.case_mode = 1;                 
                    }

                    vm.model.checkedCount = 0;
                    vm.model.case_count = 1;

                    angular.forEach(vm.model.orders,function(item){
                        if (item.item_has_case != vm.model.case_mode){
                            item.item_is_selected = '0';
                        }

                        if (item.item_is_selected != '0'){
                            vm.model.checkedCount += 1;
                            
                            if (item.item_quantity > vm.model.case_count){
                                vm.model.case_count = item.item_quantity;
                            }
                        }                    
                    });
                } else {
                    if (currentItem.item_case != ''){
                        modalService.confirm({
                            title: 'Bekreft',
                            message: 'Vennligst bekreft at du vil slette denne.',
                            okLabel: 'Ok',
                            cancelLabel: 'Avbryt'
                        }).then(function() {                        
                            poFinishProdPackService.deleteCase(currentItem.item_case).then(function () {
                                stateService.go('pofinishprodpack',{
                                    poh_keyno_top: $stateParams.poh_keyno_top,
                                    counter: vm.model.parmCounter
                                });
                            });
                        });
                    }
                }
            };
            
            vm.printCargonizerReport = function (docarcFileName) {
                cargonizerApiService.printCargonizerConsignmentReport(0, vm.model.printer, docarcFileName).then(function () {
                    vm.model.locked = '0';
                    ieScreenBlockService.stop();

                    stateService.go('pofinishprodpack',{
                        poh_keyno_top: $stateParams.poh_keyno_top,
                        counter: vm.model.parmCounter
                    });
                });
            };
    
            vm.caseOrders = function () {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Lager kolli');
                var pos = '';

                angular.forEach(vm.model.orders, function (item) {
                    if (item.item_is_selected != '0') {
                        if (pos.length > 0) {
                            pos = pos + ',';
                        }

                        pos = pos + item.item_po;
                    }
                });

                var cc = 1;

                if (vm.model.case_count > 1) {
                    cc = vm.model.case_count;
                }

                if (pos.length > 0) {
                    if (parseInt(vm.model.printer) > 0) {
                        poFinishProdPackService.caseOrders(
                            {
                                pos: pos,
                                case_count: cc
                            }).then(function (data) {
                                // store user variable:
                                poFinishProdPackService.remember('prodpack.cases', data[0].case_keyno_list).then(function () {
                                    ieScreenBlockService.setMessage('Printing labels');

                                    printService.archive({
                                        reportId: vm.model.settings.report_name,
                                        parameters: {}
                                    }).then(function (fileItem) {
                                        ieScreenBlockService.setMessage('Getting file details...');

                                        fileService.getFileDetails({ fileName: fileItem.fileNameWithExtension, wait: true, instanceId: fileItem.instanceId }).then(function (dr) {
                                            ieScreenBlockService.setMessage('Printing labels....');
                                            vm.printCargonizerReport(dr.fileName);
                                        });
                                    }).catch(function (err) {
                                        console.log('report error:', err);
                                    });

                                    $timeout(function () {
                                        //ieScreenBlockService.setMessage('Printing completed');
                                        ieScreenBlockService.stop();

                                        stateService.go('pofinishprodpack', {
                                            poh_keyno_top: $stateParams.poh_keyno_top,
                                            counter: vm.model.parmCounter
                                        });
                                    }, 1500);
                                });
                                //stordalconsignmentcolli.trdp          
                            });
                    } else {
                        alert('Ugyldig printer');
                        ieScreenBlockService.stop();
                    }
                } else {
                    alert('Ingen valgt');
                    ieScreenBlockService.stop();
                }
            };
            
            vm.goToScan = function () {
                stateService.go('finishpohscan', {});
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPoFinishProdPackMain = function (item) {
                vm.model.bgPoFinishProdPackMain.item_selected = item;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.onPrinterChange = function (value) {
                vm.model.printer = value;
                poFinishProdPackService.setDefaultPrinter(vm.model.printer);
            };
        }]
    });
})();