(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockPlaces', {
        templateUrl: 'views/components/views/stockPlaces/stockPlaces.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'stockPlacesService', 'logisticService', 'rememberService', function (stateService, utilityService, stockPlacesService, logisticService, rememberService) {
            let vm = this;
            
            let variableNames = {
                filterval: ''
            };
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filterval') }
                ],
                setting: {},
                itemsListStockPlaces: []
            };

            let loadSetting = function () {
                stockPlacesService.getStockPlace().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };
            
            let loadStockPlaces = function () {
                logisticService.listStocks().then(function (result) {
                    angular.copy(result, vm.model.itemsListStockPlaces);
                });
            };
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                rememberService.remember(variableNames[id], variableValue);
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        vm.model.setting.filterval = '';

                        if (vm.model.setting.filterval === '') rememberFunc('filterval');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockplace':
                        go({ stockplace_id: '+' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        if (vm.model.setting.filterval > '')  rememberFunc('filterval');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filterval':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filterval) !== true) return;

                        vm.model.setting.filterval = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                        if (vm.model.setting.filterval === '') rememberFunc('filterval');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadStockPlaces();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
