(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2Settings', {
        templateUrl: 'views/components/views/p2Settings/p2Settings.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['p2SettingsService', 'stateService', function (p2SettingsService, stateService) {

    		// #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                btnListFilterValue: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                filterValue: '',
                itemsListSettings: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS
            
    		// #region LOAD PROCEDURE CALLS
            
            p2SettingsService.listP2Settings().then(function (result) {
                angular.copy(result, vm.model.itemsListSettings);
            });
            
    		// #endregion LOAD PROCEDURE CALLS
            
            // #region BUTTON LIST SINGLE FUNCTION
            
            vm.btnListFuncFilterValue = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.filterValue = '';
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion BUTTON LIST SINGLE FUNCTION
            
            // #region DIRECTION BUTTON FUNCTIONS
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2settingedit':
                        stateService.go(state, { p2_setting_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion DIRECTION BUTTON FUNCTIONS
        }]
    });
})();