(function () {
    'use strict';

    angular.module("imApp").factory("doDataImpService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 3174,
                    parameters: parms || {}
                });
            },
            retrieveFill: function (edit) {
                return $ihttp.post({
                    method: 3175,
                    parameters: edit
                });
            },
            excelImport: function (parms) {
                return $ihttp.post({
                    method: 3179,
                    parameters: parms || {}
                });
            },

        };

        return service;
    }]);
})();
