(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicEdit', {
        templateUrl: 'views/components/dynamics/views/ttDynamicEdit/ttDynamicEdit.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$scope', 'base64', '$element', '$rootScope', '$compile', '$timeout', '$uibModal', 'ttDynamicEditService', 'ttDatasourceParameterEditService', 'ttDynamicViewService', 'ttDatasourceManager', 'translateService', 'ieScreenBlockService', 'utilityService', function ($scope, base64, $element, $rootScope, $compile, $timeout, $uibModal, ttDynamicEditService, ttDatasourceParameterEditService, ttDynamicViewService, ttDatasourceManager, translateService, ieScreenBlockService, us) {
            var vm = this;

            vm.propId = uuid();

            let parameters = {};

            let propElement = null;

            vm.dynamic = {};
            vm.properties = {};
            vm.components = [];
            vm.datatasks = [];

            vm.style = {
                container: {
                    label: {
                        color: '#14587f',
                        background: 'skyblue',
                        padding: '3px 5px 3px 10px',
                        border: '1px solid darkgray',
                        marginTop: '5px',
                        marginBottom: '5px'
                    }
                }
            };

            vm.align = [
                { id: 'left', name: 'Left' },
                { id: 'center', name: 'Center' },
                { id: 'right', name: 'Right' }
            ];

            let translations = { };

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            vm.setDatatask = function (item, model, index, id) {
                angular.copy(item, vm[id]);

                vm.onDataChanged(item.p2_datatask_keyno, 'vm.dynamic.properties[' + index + '].value');
            };

            let changedPromise = null;

            vm.onDataChanged = function (value, modelId) {
                if (angular.isString(modelId) !== true || modelId.length < 1) {
                    return;
                }

                let parts = modelId.split('.');
                let field = parts[parts.length - 1];

                switch (field) {
                    case 'tt_component_keyno':
                        ttDynamicEditService.componentChanged(vm.dynamic.keyno, value).then(load, load);
                        break;
                    default:
                        if (parts[2].startsWith('properties') === true) {
                            let index = parseInt(parts[2].split('[')[1].split('[')[0]);

                            vm.dynamic.properties[index].value = value;
                        } else {
                            vm.dynamic[field] = value;
                        }

                        let updating = false;

                        let update = function () {
                            changedPromise = null;

                            if (updating === true) {
                                changedPromise = $timeout(update, 350);
                                return;
                            }

                            updating = true;

                            var dynamic = angular.copy(vm.dynamic);

                            delete dynamic.properties;

                            var properties = angular.copy(vm.dynamic.properties);

                            ttDynamicEditService.update(dynamic, properties).then(function () {
                                updating = false;
                            });
                        };

                        if (changedPromise !== null) {
                            $timeout.cancel(changedPromise);
                        }

                        changedPromise = $timeout(update, 350);
                }
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                angular.copy(vm.resolve.parameters, parameters);

                load();
            };

            function load() {
                ttDynamicEditService.init(parameters.tt_dynamic_keyno).then(function (response) {
                    angular.copy(response.dynamic, vm.dynamic);

                    for (var i = 0; i < vm.dynamic.properties.length; i++) {
                        vm.dynamic.properties[i].index = i;
                    }

                    vm.dynamic.remarks = base64.urldecode(vm.dynamic.remarks);

                    angular.copy(response.components, vm.components);
                    angular.copy(response.datatasks, vm.datatasks);

                    $timeout(generatePropertyElements);
                });
            };

            function addPropertyElement(prop, leftPadding) {
                let propName = prop.tag_name.replaceAll('-', '');

                vm.properties[propName] = prop.value;

                let source = '';

                let style = angular.isDefined(leftPadding)
                    ? ' style="padding-left: ' + leftPadding + 'px;"'
                    : '';

                switch (prop.tag_name) {
                    case 'tt-buttonactions':
                        vm.dynamic.properties[prop.index].value = {
                            label: propName,
                            instance_id: parameters.instance_id,
                            tt_dynamicproperty_keyno: prop.dp_keyno
                        };

                        source = '<tt-buttonactions tt-options="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)"></tt-buttonactions>';
                        break;
                    case 'tt-model':
                        source = '<tt-datasource-selector' + style + ' tt-rootid="' + vm.resolve.parameters.instance_id + '" tt-label="' + propName + '" tt-model="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)"></tt-datasource-selector>';
                        break;
                    case 'tt-text-align':
                        source = '<tt-select' + style + ' class="col-xs-12 sp-0" tt-label="' + propName + '" tt-model="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)" tt-data="vm.align" tt-data-id="id" tt-data-name="name"></tt-select>';
                        break;
                    default:
                        switch (prop.type) {
                            case 'datasource':
                                source = '<tt-datasource' + style + ' class="col-xs-12 sp-0" tt-rootid="' + parameters.instance_id + '" tt-model="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)"></tt-datasource>';
                                break;
                            case 'datatask':
                                let id = us.generateId(4, 'Obj', vm);

                                if (prop.value > 0) {
                                    let val = parseInt(prop.value);

                                    let tasks = vm.datatasks.filter(function (d) { return d.p2_datatask_keyno === val; });

                                    vm[id] = tasks.length > 0
                                        ? tasks[0]
                                        : { p2_datatask_keyno: 0, proc_name: '' };
                                } else {
                                    vm[id] = { p2_datatask_keyno: 0, proc_name: '' }
                                }

                                //source = '<tt-typeahead tt-label="tt_datatask" tt-model="vm.' + id + '" tt-data="vm.datatasks" tt-data-name="proc_name" tt-limit="25" tt-select="vm.setDatatask($item, $model, ' + prop.index + ', \'' + id + '\')"></tt-typeahead>';
                                source = '<tt-datatask-selector tt-label="tt_datatask" tt-model="vm.' + id + '" tt-limit="25" tt-change="vm.setDatatask($value, $modelId, ' + prop.index + ', \'' + id + '\')"></tt-datatask-selector>';
                                break;
                            case 'string':
                                source = '<tt-input' + style + ' class="col-xs-12 sp-0" tt-label="' + propName + '" tt-model="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)"></tt-input>';
                                break;
                            case 'boolean':
                                source = '<tt-checkbox' + style + ' class="col-xs-12 sp-0" tt-label="' + propName + '" tt-model="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)"></tt-checkbox>';
                                break;
                            case 'expression':
                            case 'function':
                                source = '<tt-textarea' + style + ' class="col-xs-12 sp-0" tt-label="' + propName + '" tt-model="vm.dynamic.properties[' + prop.index + '].value" tt-change="vm.onDataChanged($value, $modelId)"></tt-textarea>';
                                break;
                            case 'container':
                                source = '<div' + style + ' class="col-xs-12 sp-0"><tt-lbl class="name-label col-xs-12 sp-0" tt-style="vm.style.container.label" tt-model="' + prop.tag_name + '" tt-translate="false"></tt-lbl>';
                                break;
                            case 'hr':
                                source = '<hr' + style + ' class="col-xs-12 sp-0" style="margin-top: 5px; margin-bottom: 5px;"/></div>';
                                break;
                        }
                }

                let insertedEl = $compile(source)($scope);

                propElement.append(insertedEl);
            };

            function addProperty(prop, leftPadding) {
                addPropertyElement(prop, leftPadding);

                if (prop.type !== 'container') return;

                var childProps = vm.dynamic.properties.filter(function (p) {
                    return p.parent_keyno === prop.cp_keyno;
                });

                if (childProps.length < 1) return;

                angular.forEach(childProps, function (childProp) {
                    addProperty(childProp, leftPadding + 20);
                });
            }
            
            function generatePropertyElements() {
                vm.properties = {};

                var rootProperties = vm.dynamic.properties.filter(function (p) {
                    return (p.parent_keyno || 0) < 1;
                });

                if (propElement === null) {
                    propElement = angular.element(document.getElementById(vm.propId));
                } else {
                    propElement.empty();
                }

                angular.forEach(rootProperties, function (prop) {
                    addProperty(prop, 0);
                });
            };
        }]
    });
})();