(function () {
    'use strict';

    angular.module("imApp").factory("srResourceViewService", ['$ihttp', function ($ihttp) {
        var service = {
            getSrResourceView: function (parms) {
                return $ihttp.post({
                    method: 2148,
                    parameters: parms || {}
                });
            },
            listSrMainResources: function (parms) {
                return $ihttp.post({
                    method: 1822,
                    parameters: parms || {}
                });
            },
            listSrSubResoures: function (parms) {
                return $ihttp.post({
                    method: 1823,
                    parameters: parms || {}
                });
            },
            listSrResourceViews: function (parms) {
                return $ihttp.post({
                    method: 2149,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();