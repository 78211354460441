(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('custactComps', {
        templateUrl: 'views/components/views/custactComps/custactComps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'custactCompsService', 'stateService', function($stateParams, custactCompsService, stateService) {
            
            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;

            vm.model = {
                p2CustactCompListSelection: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: 'S',
                    records: [
                        { item_id: '1', item_name: 'custactcomps_selected', item_func: 'S' },
                        { item_id: '2', item_name: 'custactcomps_all', item_func: 'A' }
                    ]
                },
                p2CustactGet: {},
                p2CustactCompList: {
                    records: []
                }
            };
            
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
            
            custactCompsService.loadP2CustactGet($stateParams.custact_no).then(function () {
                vm.model.p2CustactGet = custactCompsService.p2CustactGet;
            });
    
            vm.retrieve = function () {
                custactCompsService.loadP2CustactCompList($stateParams.custact_no, vm.model.p2CustactCompListSelection.item_selected).then(function () {
                    vm.model.p2CustactCompList = custactCompsService.p2CustactCompList;
                });
            };
    
            // ######################
            // VALIDATION FUNCTIONS
            // ######################
    
            vm.validateCheckItem = function (item) {
                if (item.item_is_selected == '1') {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                    item.item_glyphicon = 'glyphicon-unchecked';
                } else {
                    item.item_is_selected = '1';
                    item.item_glyphicon_color = 'green';
                    item.item_glyphicon = 'glyphicon-check';
                }

                custactCompsService.saveP2CustactCompIsSelected(item).then(function (item) {

                });
            };

            // #####################
            // BUTTON GROUP FUNCTION
            // #####################

            vm.selectP2CustactCompList = function (lsP2CustactCompListStatus) {
                vm.model.p2CustactCompListSelection.item_selected = lsP2CustactCompListStatus;
                vm.retrieve();
            };

            // ##################################
            // DIRECTION BUTTON TO CUSTACTCOMPADD
            // ##################################
            
            vm.goToCustactComp = function () {
                stateService.go('custactcomp', {
                    custact_no: $stateParams.custact_no,
                    custactcomp_keyno: '0'
                });
            };
        }]
    });
})();