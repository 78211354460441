(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('requisitionsBetween', {
        templateUrl: 'views/components/views/requisitionsBetween/requisitionsBetween.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'requisitionsBetweenService', function (utilityService, requisitionsBetweenService) {
            var vm = this;
            
            vm.model = {
                bgRequisitionsBetweenListType: {
                    selected: 'NEW',
                    buttons: [
                        { id: 'NEW', label: 'bg_requisitionsbetween_listtype_create', color: 'primary', onClick: () => vm.model.bgRequisitionsBetweenListType.selected = 'NEW' },
                        { id: 'IN_PROGRESS', label: 'bg_requisitionsbetween_listtype_in_progress', color: 'primary', onClick: () => vm.model.bgRequisitionsBetweenListType.selected = 'IN_PROGRESS' }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                itemsListRequisitionsBetween: [],
                p2WRequisitionsBetweenConsignmentList: {
                    records: []
                }
            };
            
            vm.loadP2WRequisitionsBetweenSettings = function () {
                requisitionsBetweenService.genericFunction(766, {}).then(function (data) {
                    vm.model.setting = angular.copy(data[0]);

                    vm.loadP2StockPlaceListFrom();
                    vm.loadP2WRequisitionsBetweenList();
                    vm.loadP2WRequisitionsBetweenConsignmentList();
                });
            };
    
            vm.loadP2WRequisitionsBetweenSettings();
    
            vm.loadP2BusinessCoListFrom = function () {
                requisitionsBetweenService.genericFunction(104, {}).then(function (data) {
                    angular.copy(data, vm.model.selectListCompanies);
                });
            };
    
            vm.loadP2BusinessCoListFrom();
    
            vm.loadP2StockPlaceListFrom = function () {
                if (vm.model.setting.businessco_no_from !== '' && angular.isDefined(vm.model.setting.businessco_no_from)) {
                    requisitionsBetweenService.genericFunction(622, {
                        businessco_no: vm.model.setting.businessco_no_from,
                        add_all: 'ALL'
                    }).then(function (data) {
                        angular.copy(data, vm.model.selectListStockPlaces);
                    });
                }
            };
            
            vm.loadP2WRequisitionsBetweenList = function () {
                if (vm.model.setting.businessco_no_from !== '' && angular.isDefined(vm.model.setting.businessco_no_from) && vm.model.setting.stockplace_id_from !== '' && angular.isDefined(vm.model.setting.stockplace_id_from)) {
                    requisitionsBetweenService.genericFunction(767, vm.model.setting).then(function (data) {
                        angular.copy(data, vm.model.itemsListRequisitionsBetween);
                    });
                }
            };
            
            vm.loadP2WRequisitionsBetweenConsignmentList = function () {
                if (vm.model.setting.businessco_no_from !== '' && angular.isDefined(vm.model.setting.businessco_no_from) && vm.model.setting.stockplace_id_from !== '' && angular.isDefined(vm.model.setting.stockplace_id_from)) {
                    requisitionsBetweenService.genericFunction(776, vm.model.setting).then(function (data) {
                        angular.copy(data, vm.model.p2WRequisitionsBetweenConsignmentList.records);
                    });
                }
            };
            
            // ## REMEMBER VALUE FUNCTIONS ##
            
            var canRun616 = function (variableName, parm) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
                if (vm.model.setting[parm] === null) return false;
    
                return true;
            };
    
            var rememberBusinessCoNoFrom = function () {
                var variableNameBusinessCoNoFrom = 'w_requisitionsbetween.businessco_no_from';
    
                if (canRun616(variableNameBusinessCoNoFrom, 'businessco_no_from') !== true) return;
    
                requisitionsBetweenService.remember(variableNameBusinessCoNoFrom, vm.model.setting.businessco_no_from);
            };
    
            var rememberStockPlaceIdFrom = function () {
                var variableNameStockPlaceIdFrom = 'w_requisitionsbetween.stockplace_id_from';
    
                if (canRun616(variableNameStockPlaceIdFrom, 'stockplace_id_from') !== true) return;
    
                requisitionsBetweenService.remember(variableNameStockPlaceIdFrom, vm.model.setting.stockplace_id_from);
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgRequisitionsBetweenListType = function (value) {
                vm.model.bgRequisitionsBetweenListType.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no_from) !== true) return;

                        vm.model.setting.businessco_no_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no_from) !== true) return;

                        vm.loadP2StockPlaceListFrom();
                        vm.loadP2WRequisitionsBetweenList();
                        vm.loadP2WRequisitionsBetweenConsignmentList();
                        rememberBusinessCoNoFrom();
                        break;
                    case 'stockplace_id_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_from) !== true) return;

                        vm.model.setting.stockplace_id_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_from) !== true) return;

                        vm.loadP2WRequisitionsBetweenList();
                        vm.loadP2WRequisitionsBetweenConsignmentList();
                        rememberStockPlaceIdFrom();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
