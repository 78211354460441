(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockControlListService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1702,
                    parameters: parms || {}
                });
            },
            listControls: function (parms) {
                return $ihttp.post({
                    method: 1703,
                    parameters: parms || {}
                });
            },
            scan: function (stockplace_id, barcode) {
                return p2DataTaskService.call(1714, {
                    stockplace_id: stockplace_id,
                    barcode: barcode
                });
            }
        };

        return service;
    }]);
})();
