(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acLedgerView', {
        templateUrl: 'views/components/views/acLedgerView/acLedgerView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'acLedgerViewService', 'logisticService', 'rememberService', function ($timeout, stateService, utilityService, acLedgerViewService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            var variableNames = {
                businessco_no: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                show_account_saldo: '',
                account_nos: ''
            };

            var timer = {
                account_nos: null
            };

            vm.model = {
                //accountNosButtons: [
                //    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: reset, state: 'account_nos' } },
                //    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onSearch: vm.searchGrid },
                //    //{ id: 'goto', icon: 'fas fa-list', color: 'primary', type: 'primary', onClick: function () { return; } }
                //],
                accountNosButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('account_nos') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchGrid() },
                    //{ id: 'goto', icon: 'fas fa-list', color: 'primary', type: 'primary', onClick: function () { return; } }
                ],
                setting: {},
                selectListCompanies: [],
                gridReady: false,
                retrieving: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_acledgerview_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },

                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },

                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            acLedgerViewService.getAcLedgerView().then(function (result) {
                angular.copy(result[0], vm.model.setting);

                vm.grid.dataTask.loadData = {
                    method: 2786,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
            });

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListCompanies);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                if (angular.isDefined(timer[id])) {
                    $timeout.cancel(timer[id]);
                }

                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                            variableNames[key] = 'w_accountingprocessing.businessco_no';
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    case 'date_fom':
                        if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;

                        variableValue = vm.model.setting.date_fom;
                        break;
                    case 'date_tom':
                        if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;

                        variableValue = vm.model.setting.date_tom;
                        break;
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;

                        variableValue = vm.model.setting.dateselector_index;
                        break;
                    case 'show_account_saldo':
                        if (utilityService.validateParmsValue(vm.model.setting.show_account_saldo, true) !== true) return;

                        variableValue = vm.model.setting.show_account_saldo;
                        break;
                    case 'account_nos':
                        if (utilityService.validateParmsValue(vm.model.setting.account_nos, true) !== true) return;

                        variableValue = vm.model.setting.account_nos;
                        break;
                    default:
                        break;
                }

                if (vm.model.setting.account_nos > '') {
                    timer[id] = $timeout(function () {
                        rememberService.remember(variableNames[id], variableValue);
                    }, 2000);
                } else {
                    rememberService.remember(variableNames[id], variableValue);
                }
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var reset = function (keyno) {
                switch (keyno) {
                    case 'account_nos':
                        vm.model.setting.account_nos = '';
                        rememberFunc('account_nos');

                        vm.grid.dataTask.loadData = {
                            method: 2786,
                            parameters: vm.model.setting
                        };

                        vm.model.gridReady = true;

                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        vm.model.setting[keyno] = '0';
                        break;
                }
            };

            vm.searchGrid = function () {
                console.log('hit');
                rememberFunc('account_nos');

                vm.grid.dataTask.loadData = {
                    method: 2786,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                vm.grid.gridfunc.rebind();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'show_account_saldo':
                        vm.model.setting.show_account_saldo = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_account_saldo, true) !== true) return;

                        rememberFunc('show_account_saldo');
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'account_nos':
                        vm.model.setting.account_nos = value;

                        if (utilityService.validateParmsValue(vm.model.setting.account_nos, true) !== true) return;

                        rememberFunc('account_nos');
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            //vm.onBlur = function (id) {
            //    switch (id) {
            //        case 'account_nos':
            //            if (utilityService.validateParmsValue(vm.model.setting.account_nos, true) !== true) return;

            //            if (vm.model.setting.account_nos > '') {
            //                rememberFunc('account_nos');

            //                vm.grid.dataTask.loadData = {
            //                    method: 2786,
            //                    parameters: vm.model.setting
            //                };

            //                vm.model.gridReady = true;

            //                vm.grid.gridfunc.rebind();
            //            }
            //            break;
            //        default:
            //            break;
            //    }
            //};

            // #endregion ON BLUR MULTI FUNCTIONS

            vm.retrieveChanges = function () {
                vm.model.retrieving = true;

                vm.grid.gridfunc.rebind().then(function () {
                    vm.model.retrieving = false;
                });
            };

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');

                if (vm.model.setting.date_tom) rememberFunc('date_tom');

                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    vm.grid.gridfunc.rebind();
                }
            }

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
