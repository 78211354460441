(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('riskElement', {
        templateUrl: 'views/components/views/riskElement/riskElement.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'riskElementService', 'riskElementsService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, riskElementService, riskElementsService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let riskElementKeyno = $stateParams.riskelement_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                riskElementKeyno: riskElementKeyno,
                bgRiskElementSelection: {
                    selected: 'B',
                    buttons: [
                        { id: 'B', label: 'riskelement_details', color: 'primary', onClick: () => vm.model.bgRiskElementSelection.selected = 'B' },
                        { id: 'L', label: 'riskelement_log', color: 'primary', onClick: () => vm.model.bgRiskElementSelection.selected = 'L' }
                    ]
                },
                edit: {},
                selectListProbabilities: [],
                selectListConsequences: [],
                selectListRisks: [],
                itemsListLogs: [],
                lockedSave: false,
                lockedConfirm: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                let deferred = $q.defer();

                riskElementService.getEdit({ riskelement_keyno: riskElementKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadLogs()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadProbabilities = function () {
                riskElementsService.listProbabilityLabels().then(function (result) {
                    angular.copy(result, vm.model.selectListProbabilities);
                });
            };

            let loadConsequences = function () {
                riskElementsService.listConsequenceLabels().then(function (result) {
                    angular.copy(result, vm.model.selectListConsequences);
                });
            };

            let loadRisks = function () {
                riskElementService.listRisks().then(function (result) {
                    angular.copy(result, vm.model.selectListRisks);
                });
            };

            let loadLogs = function () {
                if (utilityService.validateParmsValue(vm.model.edit.riskelement_keyno) !== true) return;

                vm.model.itemsListLogs = [];

                return riskElementService.listLogs({ riskelement_keyno: vm.model.edit.riskelement_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListLogs);
                });
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgRiskElementSelection = function (value) {
                vm.model.bgRiskElementSelection.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let mergeObj = vm.model.edit;
                mergeObj.argtype = argType;
                mergeObj.argvalue = argValue;
                mergeObj.riskelement_keyno = riskElementKeyno;

                riskElementService.saveObj(mergeObj).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON CONFIRM PROCEDURE FUNCTION CALL

            vm.confirmValues = function () {
                vm.model.lockedConfirm = true;

                riskElementService.confirmItem({ riskelement_keyno: vm.model.edit.riskelement_keyno }).then(function () {
                    vm.model.lockedConfirm = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedConfirm = false;
                });
            };

            // #endregion BUTTON CONFIRM PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    riskElementService.deleteItem({ riskelement_keyno: vm.model.edit.riskelement_keyno }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'probability_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.probability_no) !== true) return;

                        vm.model.edit.probability_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.probability_no) !== true) return;

                        vm.model.edit.risk_no = (vm.model.edit.consequence_no * value).toString();
                        break;
                    case 'consequence_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.consequence_no) !== true) return;

                        vm.model.edit.consequence_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.consequence_no) !== true) return;

                        vm.model.edit.risk_no = (vm.model.edit.probability_no * value).toString();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadProbabilities();
                loadConsequences();
                loadRisks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
