// BJS 20211215
(function () {
	'use strict';

	const module = angular.module("imApp");

    module.factory("custactDialogsService", ['$ihttp', function ($ihttp) {
        const service = {
            deleteCustactDialog: function (custactdialogKeyno) {
                return $ihttp.post({
                    method: 3001,
                    parameters: { custactdialog_keyno: custactdialogKeyno }
                });
            },
            getCustactDialogs: function (parms) {
                return $ihttp.post({
                    method: 2627,
                    parameters: parms || {}
                });
            },
            listCustactDialogues: function (parms) {
                return $ihttp.post({
                    method: 107,
                    parameters: parms || {}
                });
            },
            addObj: function (editObj) {
                return $ihttp.post({
                    method: 108,
                    parameters: editObj
                });
            }
		};

		return service;
	}]);
})();
