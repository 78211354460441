<div style="display: flex; gap: 0.1rem; width: 100%">
    <tt-button
        style="flex: 1; width: 100%"
        [ttText]="ttText"
        [ttTranslateText]="ttTranslateText"
        [ttTooltipText]="ttTooltipText"
        [ttTranslateTooltipText]="ttTranslateTooltipText"
        [ttLoadingText]="ttLoadingText"
        [ttTranslateLoadingText]="ttTranslateLoadingText"
        [ttBadge]="ttBadge"
        [ttLoading]="ttLoading"
        (ttClick)="ttClick.emit($event)"
        [ttIcon]="ttIcon"
        [ttIconAlign]="ttIconAlign"
        [ttIconPullRight]="ttIconPullRight"
        [ttType]="ttType"
        [ttDisabled]="ttDisabled"
        [ttButtonType]="ttButtonType"
        [ttTextAlign]="ttTextAlign"
        [ttStyle]="ttStyle"
        [ttSpin]="ttSpin"
        [ttPrinter]="ttPrinter"
    ></tt-button>
    <tt-button *ngFor="let button of ttOptions?.buttons" [ttText]="button.text" [ttIcon]="button.icon" [ttType]="button.type"></tt-button>
    <tt-overflow-menu *ngIf="!!ttOptions?.menu && hasVisibleItem()" [ttIcon]="ttOptions?.menu?.icon" [ttType]="ttType">
        <ng-container *ngFor="let item of ttOptions?.menu?.items">
            <!-- *ngIf="(item?.hidden && item?.hidden()) || !item.hidden" -->
            <tt-overflow-menu-item
                [hidden]="item.hidden ? item.hidden() : false"
                [ttText]="item.text || ''"
                [ttIcon]="item.icon"
                [ttHasDivider]="item.hasDivider"
                [ttDanger]="item.danger"
                (ttClick)="item.click($event)"
                [ttTooltipText]="item.tooltipText ? item.tooltipText() : ''"
                [ttDisabled]="item.disabled ? item.disabled() : false"
            ></tt-overflow-menu-item>
        </ng-container>
    </tt-overflow-menu>
    <ng-content select="tt-button"></ng-content>
    <ng-content select="tt-overflow-menu"></ng-content>
</div>
