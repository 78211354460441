(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poPlans', {
        templateUrl: 'views/components/views/poPlans/poPlans.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'poPlansService', function ($timeout, stateService, poPlansService) {
            var vm = this;
            
            vm.model = {
                loaded: false,
                operationInfo: {},
                actionType: 'NOGO',
                bgChoices: {
                    item_default: '0',
                    item_selected: 'bgChoices_notstarted',
                    records: [
                        { item_id: '0', item_name: 'bgChoices_notstarted', item_func: 'notstarted' },
                        { item_id: '1', item_name: 'bgChoices_started', item_func: 'started' },
                        { item_id: '2', item_name: 'bgChoices_all', item_func: 'all' }
                    ]
                },
                bgPlansPlans: {
                    item_default: '0',
                    item_selected: 'plansday',
                    records: [
                        { item_id: '0', item_name: 'bgPlansPlansDay', item_func: 'plansday' },
                        { item_id: '1', item_name: 'bgPlansPlansWeek', item_func: 'plansweek' }
                    ]
                },
                bgPlansDay: {
                    item_default: '0',
                    item_selected: 'today',
                    records: [
                        { item_id: '0', item_name: 'bgPlansDayToday', item_func: 'today' },
                        { item_id: '1', item_name: 'bgPlansDayTomorrow', item_func: 'tomorrow' },
                        { item_id: '2', item_name: 'bgPlansDayAfterTomorrow', item_func: 'after_tomorrow' }
                    ]
                },
                bgPlansWeek: {
                    item_default: '0',
                    item_selected: 'thisweek',
                    records: [
                        { item_id: '0', item_name: 'bgPlansWeekThisWeek', item_func: 'thisweek' },
                        { item_id: '1', item_name: 'bgPlansweekNextWeek', item_func: 'nextweek' }
                    ]
                },
                bgPlansMonth: {
                    item_default: '0',
                    item_selected: 'thismonth',
                    records: [
                        { item_id: '0', item_name: 'bgPlansMonthThisMonth', item_func: 'thismonth' },
                        { item_id: '1', item_name: 'bgPlansMonthNextMonth', item_func: 'nextmonth' }
                    ]
                },
                dataSource: {
                    records: []
                }
            };
            
            vm.do_nada = function () {};

            vm.removeEmpty = function () {
                var i = 0;
                i = 0;
                
                angular.forEach(vm.model.bgPlansWeek.records, function (item) {
                    if (item.item_name == '') {
                        vm.model.bgPlansWeek.records.splice(i);
                    }

                    i += 1;
                });

                i = 0;

                if (vm.model.bgPlansPlans.records.length == 0) {
                    vm.model.bgPlansPlans.records.push({ item_id: '0', item_name: 'bgPlansPlansDay', item_func: 'plansday' });
                    vm.model.bgPlansPlans.records.push({ item_id: '1', item_name: 'bgPlansPlansWeek', item_func: 'plansweek' });
                }

                if (vm.model.bgChoices.records.length == 2) {
                    vm.model.bgChoices.records.push({ item_id: '2', item_name: 'bgChoices_all', item_func: 'all' });
                }

                if (vm.model.bgPlansDay.records.length == 0) {
                    vm.model.bgPlansDay.records.push({ item_id: '0', item_name: 'bgPlansDayToday', item_func: 'today' });
                    vm.model.bgPlansDay.records.push({ item_id: '1', item_name: 'bgPlansDayTomorrow', item_func: 'tomorrow' });
                    vm.model.bgPlansDay.records.push({ item_id: '2', item_name: 'bgPlansDayAfterTomorrow', item_func: 'after_tomorrow' });
                }
            };
            
            vm.alterCoices = function () {
                if (vm.model.loaded) {
                    vm.model.bgChoices.records.splice(vm.model.bgChoices.records.length - 1);

                    $timeout(function () {
                        vm.removeEmpty();
                    }, 10);
                }
            };
            
            vm.alterPlansPlans = function () {
                if (vm.model.loaded) {
                    vm.model.bgPlansPlans.records = [];
                    
                    $timeout(function () {
                        vm.removeEmpty();
                    }, 10);
                }
            };

            vm.alterPlansDay = function () {
                if (vm.model.loaded) {
                    vm.model.bgPlansDay.records = [];

                    $timeout(function () {
                        vm.removeEmpty();
                    }, 10);
                }
            };
        	
            vm.alterPlansWeek = function () {
                if (vm.model.loaded) {
                    vm.model.bgPlansWeek.records.push({ item_id: '2', item_name: '', item_func: '' });

                    $timeout(function () {
                        vm.removeEmpty();
                    }, 10);
                }
            };

            vm.alterPlansMonth = function () {
                if (vm.model.loaded) {
                    vm.model.bgPlansMonth.records.push({ item_id: '2', item_name: 'test', item_func: 'test' });

                    $timeout(function () {
                        vm.removeEmpty();
                    }, 10);
                }
            };
            
            var timer = null;
            
            vm.loadDataSource = function (choice, period) {
                if (angular.isDefined(timer)) {
                    $timeout.cancel(timer);
                }

                timer = $timeout(function () {
                    poPlansService.loadDataSource(choice, period).then(function () {
                        vm.model.dataSource.records = poPlansService.dataSource.records;
                    });
                }, 100);
            };
            
        	vm.selectBgChoices= function (bgChoiceItem) {
                vm.model.bgChoices.item_selected = bgChoiceItem;
                vm.model.bgPlansPlans.item_selected = 'plansday';
            	vm.model.bgPlansPlans.item_default = '0';
            	vm.model.bgPlansDay.item_selected = 'today';
            	vm.model.bgPlansDay.item_default = '0';
            	
            	$timeout(vm.alterPlansPlans(),10);
            };

            vm.selectBgPlansPlans= function (bgPlansPlansItem) {
                vm.model.bgPlansPlans.item_selected = bgPlansPlansItem;
                
                switch (vm.model.bgPlansPlans.item_selected){
                	case 'plansweek':
                		vm.model.bgPlansWeek.item_selected = 'thisweek';
                		vm.model.bgPlansWeek.item_default = '0';
                		
                		$timeout(vm.alterPlansWeek(),10);
                		vm.selectBgPlansWeek('thisweek');
                	    break;
                	default:
                		vm.model.bgPlansDay.item_selected = 'today';
                		vm.model.bgPlansDay.item_default = '0';
                		
                		$timeout(vm.alterPlansDay(),10);
                		vm.selectBgPlansDay('today');
                	    break;
                }
            };

            vm.selectBgPlansDay= function (bgPlansDayItem) {
                vm.model.bgPlansDay.item_selected = bgPlansDayItem;
                vm.loadDataSource(vm.model.bgChoices.item_selected,vm.model.bgPlansDay.item_selected);
            };

            vm.selectBgPlansWeek= function (bgPlansWeekItem) {
                vm.model.bgPlansWeek.item_selected = bgPlansWeekItem;
               	vm.loadDataSource(vm.model.bgChoices.item_selected,vm.model.bgPlansWeek.item_selected);
            };

            vm.selectBgPlansMonth= function (bgPlansMonthItem) {
                vm.model.bgPlansMonth.item_selected = bgPlansMonthItem;
               	vm.loadDataSource(vm.model.bgChoices.item_selected,vm.model.bgPlansMonth.item_selected);
            };
            
            vm.goToSelectOperationPu = function (index) {
                stateService.go('selectoperationpu', {
                    webpagename: stateService.getCurrentName()
                });
            };
            
            vm.model.bgPlansPlans.item_selected = 'plansday';
            vm.model.bgPlansDay.item_selected = 'today';
            vm.loadDataSource(vm.model.bgChoices.item_selected,vm.model.bgPlansDay.item_selected);
            vm.model.loaded = true;
            
            poPlansService.getOperation().then(function(){
                vm.model.operationInfo = poPlansService.operationInfo;
            });
        }]
    });
})();