(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDelivery', {
        templateUrl: 'views/components/views/batchDelivery/batchDelivery.template.html?v=' + imApp.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'batchDeliveryService', 'stateService', 'utilityService', 'modalService', function ($stateParams, $timeout, batchDeliveryService, stateService, utilityService, modalService) {
            var vm = this;
            var timer = null;

            vm.model = {
                p2_batchdelivery_get: {},
                listStockLocation: {
                    records: []
                },
                lockedRollback: false,
                lockedDelete: false
            };

            vm.batchdelivery_selection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: 'batchdelivery_goods_received',
                records: [
                    { item_id: '1', item_name: 'batchdelivery_goods_received', item_func: 'batchdelivery_goods_received' },
                    { item_id: '2', item_name: 'batchdelivery_deliveryinfo', item_func: 'batchdelivery_deliveryinfo' },
                    { item_id: '3', item_name: 'costs', item_func: 'costs' }
                ]
            };

            vm.bgBatchDeliveryPurchSelection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: '',
                records: [
                    { item_id: '0', item_name: 'batchdelivery_purchline', item_func: 'PURCHLINE' },
                    { item_id: '1', item_name: 'batchdelivery_stockbatch', item_func: 'STOCKBATCH' }
                ]
            };

            batchDeliveryService.loadP2BatchDeliveryGet($stateParams.batchdelivery_keyno || '0').then(function () {
                vm.model.p2_batchdelivery_get = batchDeliveryService.p2_batchdelivery_get;
                //vm.model.p2_batchdelivery_get.notificationtype_id = 'test';

                loadStockLocationList();
            });

            batchDeliveryService.loadP2BatchDelPurchLineList($stateParams.batchdelivery_keyno || '0').then(function () {
                vm.p2_batchdelpurchline_list = batchDeliveryService.p2_batchdelpurchline_list;
            });

            batchDeliveryService.loadP2BatchDelPurchLinesBList('0', $stateParams.batchdelivery_keyno || '0').then(function () {
                vm.p2BatchDelPurchLinesBList = batchDeliveryService.p2BatchDelPurchLinesBList;
            });
            
		    var loadStockLocationList = function () {
                if (angular.isDefined(vm.model.p2_batchdelivery_get.stockplace_id)) {
                    batchDeliveryService.loadStockLocationList('1', vm.model.p2_batchdelivery_get.stockplace_id).then(function (data) {
                        angular.copy(data, vm.model.listStockLocation.records);
                    });
                }
		    };
		    
            vm.changeSum = function (index) {
                if (timer) $timeout.cancel(timer);

                timer = $timeout(function () {
                    var ln_freight_eu = Number(vm.model.p2_batchdelivery_get.freight_eu);
                    var ln_freight_ex_eu = Number(vm.model.p2_batchdelivery_get.freight_ex_eu);
                    var ln_customs_eu = Number(vm.model.p2_batchdelivery_get.customs_eu);
                    var ln_customs_ex_eu = Number(vm.model.p2_batchdelivery_get.customs_ex_eu);
                    var ln_divcost = Number(vm.model.p2_batchdelivery_get.divcost);

                    vm.model.p2_batchdelivery_get.sumcosts = (ln_freight_eu + ln_freight_ex_eu + ln_customs_eu + ln_customs_ex_eu + ln_divcost);
                    vm.saveP2BatchDelivery();
                }, 500);
            };

            vm.selectBatchDelivery = function (l_s_batchdelivery_status) {
                vm.batchdelivery_selection.item_selected = l_s_batchdelivery_status;
            };

            vm.bgSelectBatchDeliveryPurch = function (item) {
                vm.bgBatchDeliveryPurchSelection.item_selected = item;
            };

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'batchdelivery_addpurchline':
                        go(vm.model.p2_batchdelivery_get.batchdelivery_addpurchline_parms);
                        break;
                    case 'batchdelreceivelines':
                        go({
                            batchdelivery_keyno: vm.model.p2_batchdelivery_get.batchdelivery_keyno,
                            purch_internal_no: '0',
                            purchline_keyno: '0',
                            prodsizecolli_keyno: '0',
                            stocklocation_keyno: '0',
                            controlled: '0',
                            placed: '0'
                        });
                        break;
                    case 'labelprint':
                        go({
                            argtype: 'batchdelivery_keyno',
                            argvalue: vm.model.p2_batchdelivery_get.batchdelivery_keyno
                        });
                        break;
                }
            };

            vm.goToBatchDeliveryAdd = function (index) {
                if (vm.model.p2_batchdelivery_get.subsupplier_delivery == '1') {
                    stateService.go('batchdelivery_select_purchhead', {
                        supplier_no: vm.model.p2_batchdelivery_get.supplier_no,
                        batchdelivery_keyno: $stateParams.batchdelivery_keyno
                    });
                } else {
                    stateService.go('batchdeliveryadd', {
                        batchdelivery_keyno: $stateParams.batchdelivery_keyno
                    });
                }
            };

            vm.goToDeviations = function (index) {
                stateService.go('deviations', {
                    argtype: 'batchdelivery_keyno',
                    argvalue: $stateParams.batchdelivery_keyno
                });
            };

            vm.goToDocuments = function (index) {
                stateService.go('documents', {
                    argtype: 'batchdelivery_keyno',
                    argvalue: $stateParams.batchdelivery_keyno
                });
            };

            vm.goToConsignment = function (index) {
                stateService.go('consignment', {
                    consignment_keyno: vm.model.p2_batchdelivery_get.consignment_keyno
                });
            };

            vm.printP2BatchDelPurchLinesBList = function () {
                stateService.go('selectreport', {
                    webpage_name: stateService.getCurrentName(),
                    argtype: 'batchdelivery_keyno',
                    argvalue: vm.model.p2_batchdelivery_get.batchdelivery_keyno
                });
            };

            vm.saveP2BatchDelivery = function () {
                batchDeliveryService.saveP2BatchDelivery().then(function () {
                    //stateService.back();
                });
            };

            // #region DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                batchDeliveryService.deleteItem({ batchdelivery_keyno: vm.model.p2_batchdelivery_get.batchdelivery_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: response[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion DELETE PROCEDURE FUNCTION CALL

            vm.rollbackBatchDelivery = function () {
                vm.model.lockedRollback = true;
            
                batchDeliveryService.rollback(vm.model.p2_batchdelivery_get.batchdelivery_keyno).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedRollback = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedRollback = false;
                    }
                });
            };

            vm.handleNotificationTypeAction = function () {
                switch (vm.model.p2_batchdelivery_get.notificationtype_id) {
                    case 'N1':
                        batchDeliveryService.approveP2BatchDeliverySubSupplier().then(function () {
                            stateService.back('batchdelivery_list');
                        });
                        break;
                    case 'N2':
                        batchDeliveryService.approveP2BatchDelivery().then(function () {
                            stateService.back('batchdelivery_list');
                        });
                        break;
                    case 'N3':
                        if (vm.model.p2_batchdelivery_get.order_internal_no !== '0') {
                            batchDeliveryService.approveP2BatchDelivery().then(function () {
                                batchDeliveryService.searchP2ConsignmentScan(vm.model.p2_batchdelivery_get.barcode, vm.model.p2_batchdelivery_get.batchdelivery_keyno).then(function () {
                                    vm.model.p2ConsignmentScan = batchDeliveryService.p2ConsignmentScan;

                                    batchDeliveryService.loadP2BatchDeliveryGet($stateParams.batchdelivery_keyno || '0').then(function () {
                                        vm.model.p2_batchdelivery_get = batchDeliveryService.p2_batchdelivery_get;

                                        stateService.go('consignment', {
                                            consignment_keyno: vm.model.p2_batchdelivery_get.consignment_keyno
                                        });
                                    });
                                });
                            });
                        } else {
                            batchDeliveryService.approveP2BatchDeliverySubSupplier().then(function () {
                                stateService.back('batchdelivery_list');
                            });
                        }
                        break;
                    default:
                        break;
                }
            };
            
            vm.approveP2BatchDeliverySubSupplier = function () {
                batchDeliveryService.approveP2BatchDeliverySubSupplier().then(function () {
                    stateService.go('batchdelivery_list');
                });
            };

            vm.approveP2BatchDelivery = function () {
                batchDeliveryService.approveP2BatchDelivery().then(function () {
                    stateService.go('batchdelivery_list');
                });
            };

            vm.searchP2ConsignmentScan = function () {
                batchDeliveryService.searchP2ConsignmentScan(vm.model.p2_batchdelivery_get.barcode, vm.model.p2_batchdelivery_get.batchdelivery_keyno).then(function () {
                    vm.model.p2ConsignmentScan = batchDeliveryService.p2ConsignmentScan;

                    batchDeliveryService.loadP2BatchDeliveryGet($stateParams.batchdelivery_keyno || '0').then(function () {
                        vm.model.p2_batchdelivery_get = batchDeliveryService.p2_batchdelivery_get;
                    });
                });
            };
		}]
	});
})();