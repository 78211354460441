(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('brRegView', {
		templateUrl: 'views/components/views/brRegView/brRegView.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'base64', 'stateService', 'modalService', 'brRegViewService', function($stateParams, base64, stateService, modalService, brRegViewService) {
			
            // ## VARIABLES & DEFINITIONS ##

			var vm = this;

			vm.model = {
				orgNo: $stateParams.orgno,
				resourceId: $stateParams.resource_id,
				custGrpBtnList: [
					{ item_id: '0', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' }
				],
				custBusinessIdBtnList: [
					{ item_id: '0', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' }
				],
				companyView: {},
				selectListCustomerGroups: [],
				selectListBusinesses: [],
				lockedSave: false
			};

			// ## LOAD PROCEDURE CALLS ##

			var loadOrganizationNumbers = function () {
				var organizationUrl = 'https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=' + vm.model.orgNo;
				var xhr = new XMLHttpRequest();

				xhr.open('GET', organizationUrl, true);
				xhr.send();
				xhr.onreadystatechange = processRequest;

				function processRequest(e) {
					if (xhr.readyState == 4 && xhr.status == 200) {
						var response = xhr.responseText;
						
						brRegViewService.loadBrRegViewGet(base64.urlencode(response), vm.model.resourceId).then(function (data) {
							vm.model.companyView = angular.copy(data[0]);
						});
					}
				}
			};

			loadOrganizationNumbers();

			brRegViewService.loadCustGrpList('0').then(function (data) {
				angular.copy(data, vm.model.selectListCustomerGroups);
			});

			brRegViewService.loadBusinessList().then(function (data) {
				angular.copy(data, vm.model.selectListBusinesses);
			});

			// ## BUTTON LIST FUNCTIONS ##

			vm.custGrpBtnListFunc = function (item_func) {
				if (item_func === 'list_item') {
					stateService.go('custgrps', {});
				}
			};

			vm.custBusinessIdBtnListFunc = function (item_func) {
				if (item_func === 'list_item') {
					stateService.go('businesss', {});
				}
			};

			// ## WINDOW OPEN FUNCTIONS ##

			vm.openWebLink = function () {
				var proffNo = 'https://www.proff.no/bransjesøk?q=' + vm.model.orgNo;
				window.open(proffNo);
			};

			// ## SAVE BUTTON FUNCTION ##

			vm.saveBrRegView = function () {
				vm.model.lockedSave = true;
			
				brRegViewService.save(vm.model.companyView).then(function (data) {
					if (data[0].errorcode !== '0') {
						modalService.show({
							type: 'warning',
							title: 'Varsel',
							message: data[0].errormessage,
							buttons: [{
								label: 'OK',
								cssClass: 'btn-warning',
								action: function (dialogItself) {
									dialogItself.close();
									vm.model.lockedSave = false;
								}
							}]
						});
					} else {
						stateService.back();
						vm.model.lockedSave = false;
					}
				});
			};
		}]
	});
})();