(function () {
    'use strict';
    angular.module('imApp')
    .directive('imDocCarouselDirective', ['$q', '$ihttp', '$window', '$stateParams', '$timeout', '$rootScope', '$compile', 'userService', 'stateService', 'responsive', function ($q, $ihttp, $window, $stateParams, $timeout, $rootScope, $compile, userService, stateService, responsive) {
        var directive = {
            restrict: 'E',
            scope: {
                data: '=',          // Used for linking a list to the directive
                data2: '=?',        // Used for linking a list to the directive
                setup: '=',         // Used for linking the setup variables
                options: '=',       // Used for linking the setup variables
                refresh: '='        // Used for refreshing documents
            },
            //template:
            //    '<div class="carousel main-carousel">' +
            //        '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="data"></div>' +
            //        '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="data"></div>' +
            //    '</div>',
            //    //'<div class="carousel main-carousel" data-flickity="{ \'contain\': false, "pageDots": false }">' +
            //    '<div class="carousel main-carousel">' +
            //        '<!--<div ie-document-viewer ie-show="true" ie-options="model.viewerOptions" ie-details="model.details"></div>-->' +
            //        '<div class="carousel-cell"></div>' +
            //        //'<div class="carousel-cell" ie-document-viewer ie-show="model.showDocument" ie-options="model.viewerOptions" ie-details="model.fileItem"></div>' +
            //        '<div ie-document-viewer ie-show="model.showDocument" ie-options="model.viewerOptions" ie-details="model.details"></div>' +
            //        '<div ie-document-viewer ie-show="data2" ie-options="options" ie-details="data"></div>' +
            //        '<div class="carousel-cell"></div>' +
            //    '</div>',
            //    //'<div class="carousel nav-carousel">' +
            //    //    '<div class="carousel-cell"></div>' +
            //    //    '<div class="carousel-cell"></div>' +
            //    //    '<div class="carousel-cell"></div>' +
            //    //    '<div class="carousel-cell"></div>' +
            //    //'</div>' +
            //    '<div class="carousel main-carousel">' +
            //        //'<div class="carousel-cell"></div>' +
            //        //'<div class="carousel-cell"></div>' +
            //        //'<div class="carousel-cell"></div>' +
            //        //'<object data="test.pdf" type="application/pdf" width="300" height="200"> alt: <a href="test.pdf">test.pdf</a></object >' +
            //        //'<object data="data" type="application/pdf" width="300" height="200"> alt: <a href="data">test.pdf</a></object >' +
            //        //'<div class="col-xs-12 sp-0" ie-document-viewer ie-options="options" ie-details="data"></div>' +
            //        //'<div class="carousel-cell"><div ie-document-viewer ie-options="options" ie-details="data"></div></div>' +
            //        //'<div class="carousel-cell" ie-document-viewer ie-options="model.viewerOptions" ie-details="model.fileItem"></div>' +
            //        '<div ie-document-viewer ie-options="model.viewerOptions" ie-details="model.details"></div>' +
            //        '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="data"></div>' +
            //    '</div>',
            link: function ($scope, elem) {
                $scope.model = {
                    mId: uuid(),
                    nId: uuid(),
                    viewerOptions: {
                        wopiServerUrl: '',
                        documentHeight: 800,
                        maxWidth: 800,
                        maxHeight: 800,
                        canConvert: true,
                        officeControl: {},
                        showIcons: false
                    },
                    details: $scope.data,
                    showDocument: angular.isDefined($scope.data2) ? $scope.data2 : true,
                    initialIndex: 0,
                    flickityOn: {
                        ready: function () {
                            console.log('Flickity is ready');
                        },
                        select: function (index) {
                            console.log('Selected: ' + index);
                        },
                        change: function (index) {
                            console.log('Slide changed to: ' + index);
                        },
                        settle: function (index) {
                            console.log('Settled on: ' + index);
                        }
                    }
                };

                //elem.html(
                //    //'<div class="col-xs-12 sp-0" ie-document-viewer ie-show="true" ie-options="model.viewerOptions" ie-details="$scope.data"></div>'
                //    '<div class="carousel main-carousel">' +
                //        //'<div ie-document-viewer ie-show="model.showDocument" ie-options="model.viewerOptions" ie-details="model.details"></div>' +
                //        '<div ie-document-viewer ie-show="true" ie-options="model.viewerOptions" ie-details="model.details"></div>' +
                //        '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="data"></div>' +
                //    '</div>'
                //);
                //$compile(elem.contents())($scope);

                //$('.nav-carousel').flickity({
                //    asNavFor: '.main-carousel',
                //    contain: true,
                //    pageDots: false
                //});

                //$('.main-carousel').flickity({
                //    contain: false,
                //    pageDots: false
                //});

                $scope.$watchGroup(['data', 'data2', 'options', 'model', 'refresh'], function (newValues, oldValues, scope) {
                    //$scope.$watch(function () { return $scope.data; }, function () {

                    //console.log('imdocdata before');
                    //console.dir(newValues);
                    if ($scope.refresh === false) return;
                    if (angular.isUndefined($scope.data) || $scope.data === null) return;
                    if (newValues[1] !== true) return;

                    $scope.refresh = false;

                    //console.log('imdocdata');
                    //console.dir(newValues);
                    //console.dir($scope.data);
                    //console.dir($scope.data[0]);
                    //console.dir($scope.data[0][0]);
                    $scope.d = $scope.data[0];

                    var carouselCellString = '';

                    for (var d = 0; d < $scope.data.length; d++) {
                        carouselCellString += '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="data['+d+']"></div>';
                    }

                    //angular.forEach($scope.data, function (d) {
                    //    carouselCellString += '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="' + d + '"></div>';
                    //});

                    elem.html(
                        //'<div class="col-xs-12 sp-0" ie-document-viewer ie-show="true" ie-options="model.viewerOptions" ie-details="$scope.data"></div>'
                        '<div class="carousel main-carousel">' +
                            carouselCellString +
                            //'<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="data"></div>' +
                            //'<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="d"></div>' +
                            //'<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="d"></div>' +
                            //'<div ng-repeat="d in data" class="carousel-cell" ie-document-viewer ie-options="options" ie-details="d"></div>' +
                            //'<div ng-repeat="d in data">' +
                            //    '<div class="carousel-cell" ie-document-viewer ie-options="options" ie-details="d"></div>' +
                            //'</div>' +
                        '</div>'
                    );
                    $compile(elem.contents())($scope);

                    var on = (angular.isDefined($scope.setup) && angular.isDefined($scope.setup.on)) ? $scope.setup.on : $scope.model.flickityOn;
                    var ii = (angular.isDefined($scope.setup) && angular.isDefined($scope.setup.index) && $scope.setup.index >= 0) ? $scope.setup.index : $scope.model.initialIndex;

                    //console.log('flickity');

                    $('.main-carousel').flickity({
                        initialIndex: ii,
                        contain: false,
                        pageDots: false,
                        on: on
                    });
                });
            }
        };

        return directive;
    }]);
})();
