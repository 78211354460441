(function() {
    'use strict';

    angular.module("imApp").factory("ttDtGridService", ['$ihttp', function ($ihttp) {
        var service = {
            loadSchema: function (p2DataTaskKeyno) {
                return $ihttp.post({
                    method: 1999,
                    parameters: {
                        p2_datatask_keyno: p2DataTaskKeyno
                    }
                });
            },

            loadData: function (p2DataTaskKeyno, parameters) {
                return $ihttp.post({
                    method: p2DataTaskKeyno,
                    parameters: parameters || {}
                });
            },

            saveData: function (p2DataTaskKeyno, model) {
                return $ihttp.post({
                    method: p2DataTaskKeyno,
                    parameters: model || {}
                });
            }
        }

        return service;
    }]);
})();
