import { Injectable } from '@angular/core';
import { DataServiceBase } from '../../../core/framework/data-service-base';
import { DataTaskService } from '../../../core/services/data-task.service';

export interface SettingsGetResult {
    filterval?: string;
}

export interface WebPageMenuListParams {
    edit_mode?: '1' | '0';
    webpage_name?: string;
    url?: string;
}

export interface WebPageMenuListResult {
    item_id?: number;
    parent_id?: number;
    link_id?: number;
    item_name?: string;
    item_state?: string;
    item_parms?: string;
    orderby?: number;
    item_glyphicon?: string;
    item_glyphicon_color?: string;
    item_filtervalue?: string;
    badgeinfo?: string;
    word_id?: string;
    webpage_name?: string;
    p2_approute_keyno?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService extends DataServiceBase {

    constructor(private datatask: DataTaskService) {
        super();
    }

    public async getSettings(): Promise<SettingsGetResult[]> {
        return await this.datatask.Post(1020);
    }

    public async listSettings(parms: WebPageMenuListParams): Promise<WebPageMenuListResult[]> {
        return await this.datatask.Post(53, parms);
    }
}
