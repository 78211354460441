(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("productionStatusService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2PohTopScan: {},
            //p2PohTopGet: {},
            //p2PohTopList: {
            //    records: []
            //},
            scanP2PohTop: function (barcode) {
                var deferred = $q.defer();

                var parmsP2PohTopScan = {
                    method: 394,
                    parameters: {
                        barcode: barcode
                    }
                };

                //console.log('parmsP2PohTopScan');
                //console.log(angular.toJson(parmsP2PohTopScan));

                // Prosedyre sp_ws_p2_pohtop_scan
                $ihttp.post(parmsP2PohTopScan).then(function (data) {
                    //console.log('sp_ws_p2_pohtop_scan');
                    //console.log(angular.toJson(data));

                    service.p2PohTopScan = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            //getP2PohTop: function (poh_keyno_top) {
            //    var deferred = $q.defer();

            //    var parmsP2PohTopGet = {
            //        method: 395,
            //        parameters: {
            //            poh_keyno_top: poh_keyno_top
            //        }
            //    };

            //    //console.log('parmsP2PohTopGet');
            //    //console.log(angular.toJson(parmsP2PohTopGet));

            //    // Prosedyre sp_ws_p2_pohtop_get
            //    $ihttp.post(parmsP2PohTopGet).then(function (data) {
            //        //console.log('sp_ws_p2_pohtop_get');
            //        //console.log(angular.toJson(data));

            //        service.p2PohTopGet = angular.copy(data[0]);

            //        service.loaded = true;

            //        deferred.resolve();
            //    });

            //    return deferred.promise;
            //},
            //getP2PohTopList: function (poh_keyno_top) {
            //    var deferred = $q.defer();

            //    var parmsP2PohTopList = {
            //        method: 396,
            //        parameters: {
            //            poh_keyno_top: poh_keyno_top
            //        }
            //    };

            //    //console.log('parmsP2PohTopList');
            //    //console.log(angular.toJson(parmsP2PohTopList));

            //    // Prosedyre sp_ws_p2_pohtop_list
            //    $ihttp.post(parmsP2PohTopList).then(function (data) {
            //        //console.log('sp_ws_p2_pohtop_list');
            //        //console.log(angular.toJson(data));

            //        angular.copy(data, service.p2PohTopList.records);

            //        service.loaded = true;

            //        deferred.resolve();
            //    });

            //    return deferred.promise;
            //}
        }

        return service;
    }]);
})();