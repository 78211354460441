(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pictures', {
        templateUrl: 'views/components/views/pictures/pictures.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'appConfig', 'utilityService', 'translateService', 'ieLightbox', 'picturesService', 'fileService', 'docarcService', 'userService', 'documentsService', 'stateService', 'ieScreenBlockService', 'modalService', 'rememberService', 'watcherFactory', function ($stateParams, $q, appConfig, utilityService, translateService, ieLightbox, picturesService, fileService, docarcService, userService, documentsService, stateService, ieScreenBlockService, modalService, rememberService, watcherFactory) {
            var vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

    		var watcher = watcherFactory.$create(vm);
            var loading = false;
            var loadBuffer = [];
            var nbrOfRetrievedFiles = 0;

            let variableNames = {
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            let translations = {
                confirm_title: '',
                please_confirm_delete_picture: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                applicationId: fileService.applicationId,
                files: [],
                uploadFiles: [],
                fileNameSyntax: '',
                fontSize: userService.fontSize,
                page: 1,
                pageSize: 50,
                total: 0,
                pageSizes: [25, 50, 100, 200, 500],
                uploadLocked: false,
                selectedFileNames: '',
                bgMain: {
                    selected: 'UPLOAD',
                    buttons: [
                        { id: 'UPLOAD', label: 'bg_main_upload', color: 'primary', onClick: () => vm.model.bgMain.selected = 'UPLOAD' },
                        { id: 'SHOW', label: 'bg_main_show', color: 'primary', onClick: () => vm.model.bgMain.selected = 'SHOW' }
                    ]
                },
                bgPictureSelection: {
                    selected: '0',
                    buttons: [
                        { id: '0', label: 'picture_view', color: 'primary', onClick: () => vm.model.bgPictureSelection.selected = '0' },
                        { id: '1', label: 'picture_edit', color: 'primary', onClick: () => vm.model.bgPictureSelection.selected = '1' }
                    ]
                },
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadSetting = function () {
                const deferred = $q.defer();

                picturesService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    
                    loadSyntaxGet();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            var loadFiles = function () {
                // ØDL la til eit if-vilkår for fillasting utifrå button group-verdi 28.08.2018
                if (vm.model.bgMain.selected === 'UPLOAD') {
                    return;
                }
                
                var deferred = $q.defer();
                
                loadBuffer.push(deferred);
                
                if (loading === true) return deferred.promise;
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_dateselector) !== true) return;
                
                ieScreenBlockService.start();
                
                loading = true;
                
                vm.model.files.length = 0;
                
                documentsService.loadP2DocumentsList(vm.model.setting.argtype, vm.model.setting.argvalue, '', 'p', '0', vm.model.setting.date_fom, vm.model.setting.date_tom, vm.model.setting.dateselector_index, vm.model.setting.show_dateselector).then(function (data) {
                    var files = [];
    
                    var fileIndex = {};
    
                    for (var i = 0; i < data.length; i++) {
                        var filename = data[i].docfilename;
    
                        if (angular.isUndefined(fileIndex[filename])) {
                            files.push(filename);
                            fileIndex[filename] = {
                                itemId: data[i].item_id,
                                checksum: data[i].checksum,
                                itemState: data[i].item_state,
                                itemParms: data[i].item_parms
                            };
                        }
                    }
    
                    fileService.getFileInfo({
                        selectOnlyFromFiles: true,
                        files: files,
                        maxWidth: 75,
                        maxHeight: 75,
                        page: vm.model.page,
                        pageSize: vm.model.pageSize
                    }).then(function (result) {
                        vm.model.total = result.total;
    
                        vm.model.files = [];
    
                        for (var i = 0; i < result.files.length; i++) {
                            var file = result.files[i];
                            var fileName = file.fileName;
    
                            // BJS 20180105 - I tilfelle navnet på bildet ikke stemmer med navnet i docarc men checksum finnes må vi continue her.
                            // if (angular.isUndefined(fileIndex[fileName]))
                            //    continue;
    
                            file.docarcKeyno = fileIndex[fileName]?.itemId;
                            file.checksum = fileIndex[fileName]?.checksum;
                            file.itemState = fileIndex[fileName]?.itemState;
                            file.itemParms = fileIndex[fileName]?.itemParms;
                            file.originalUrl = file.url;
    
                            // BJS 20171121 - Resizer bilde url også ford Jan-Gunnar vil ha raskere lasting av større bilder.
                            var decoded = decodeURIComponent(file.thumbUrl);
    
                            var qsIndex = decoded.indexOf('{');
    
                            var url = decoded.substr(0, qsIndex);
    
                            var qs = decoded.substr(qsIndex);

                            qs = qs.substr(0, qs.indexOf(',"width"')) + ',"width":1024,"height":1024}';
    
                            qs = encodeURIComponent(qs);
    
                            file.url = url + qs + '&csi=' + appConfig.clientSessionId;
    
                            vm.model.files.push(file);
                        }
                        
                        //Lagrer antall bilder som er lastet i variabel for å hindre at lasting skjer mange ganger om en bytter fane
                        nbrOfRetrievedFiles = vm.model.files.length;
    
                        var buffer = loadBuffer.splice(0, loadBuffer.length);
    
                        loading = false;
    
                        for (var j = 0; j < buffer.length; j++) {
                            buffer[j].resolve(result);
                        }

                        ieScreenBlockService.stop();
                    });
                    
                });
    
                return deferred.promise;
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // ØDL kommenterte ut loadFiles-funksjonen globalt 28.08.2018
            //loadFiles();
    
            vm.pageChanged = function () {
                loadFiles();
            };
    
            vm.setPageSize = function (size) {
                if (vm.model.pageSizes.indexOf(vm.model.pageSize) < 0) return;
    
                vm.model.pageSize = size;
    
                loadFiles();
            };
    
            var pager = function (direction) {
                var pages = Math.ceil(vm.model.total / vm.model.pageSize);
    
                if (direction === 'next') {
                    if (vm.model.page === pages) {
                        vm.model.page = 1;
                    } else {
                        vm.model.page = vm.model.page + 1;
                    }
                } else {
                    if (vm.model.page === 1) {
                        vm.model.page = pages;
                    } else {
                        vm.model.page = vm.model.page - 1;
                    }
                }
    
                if (vm.model.page < 1) {
                    vm.model.page = 1;
                }
    
                var deferred = $q.defer();
    
                loadFiles().then(function (result) {
                    deferred.resolve(result.files);
                });
    
                return deferred.promise;
            };
            
            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deletePicture = function (index) {
                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.please_confirm_delete_picture,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    ieScreenBlockService.start();
    
                    docarcService.delete(vm.model.files[index].docarcKeyno).then(function () {
                        loadFiles().then(function () {
                            ieScreenBlockService.stop();
                        });
                    }, function () {
                        ieScreenBlockService.stop();
                    });
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.openLightBoxModal = function (index) {
                if (vm.model.bgPictureSelection.selected === '0') {
                    ieLightbox.openModal({
                        images: vm.model.files,
                        index: index,
                        autoRotate: true,
                        pager: pager
                    });
                } else {
                    if (angular.isDefined(vm.model.files[index].itemState) && vm.model.files[index].itemState.length > 0) {
                        stateService.go(vm.model.files[index].itemState, vm.model.files[index].itemParms);
                    } else {
                        stateService.go('docarc', {
                            argtype: $stateParams.argtype,
                            argvalue: $stateParams.argValue,
                            docarc_keyno: vm.model.files[index].docarcKeyno,
                        });
                    }
                }
            };
    
            var loadSyntaxGet = function () {
                picturesService.loadP2DocArcFileNameSyntaxGet(vm.model.setting.argtype, vm.model.setting.argvalue).then(function (data) {
                    vm.model.fileNameSyntax = data;
                });
            };
    
            vm.uploadFiles = function () {
                if (vm.model.uploadFiles.length < 1) return;
                if (vm.model.uploadLocked === true) return;
    
                vm.model.uploadLocked = true;
    
                var addToDocarc = function (data) {
                    var docarcDeferred = $q.defer();
    
                    vm.model.files.unshift({
                        caption: data.remarks,
                        fileName: data.fileName,
                        index: vm.model.files.length,
                        id: data.fileItemKeyno,
                        thumbUrl: data.thumbnailLink,
                        url: data.imageLink
                    });
    
                    vm.model.active = data.index;
    
                    var docarcParms = {
                        contentType: data.contentType || '',
                        filename: data.fileName,
                        localFileName: data.localFileName || '', // BJS 20211019
                        originalFileName: data.originalFileName || '',
                        originalDateTime: data.originalDateTime || '',
                        argType: $stateParams.argtype || '',
                        argValue: $stateParams.argvalue || '',
                        size: data.size || 0,
                        checksum: data.checksum || '',
                        latitude: data.latitude || 0,
                        longitude: data.longitude || 0,
                        altitude: data.altitude || 0,
                        heading: data.heading || 0,
                        checksum_md5: data.checksum_md5 || 0
                    };
    
                    docarcService.add(docarcParms, { checksum_md5: data.checksum_md5 }).then(function (docarcData) {
                        for (var j = 0; j < vm.model.files.length; j++) {
                            if (vm.model.files[j].checksum_md5 !== docarcData.context.checksum_md5) continue;
    
                            vm.model.files[j].docarcKeyno = docarcData.data[0].docarc_keyno;
    
                            break;
                        }
    
                        docarcDeferred.resolve();
                    });
    
                    return docarcDeferred.promise;
                };
    
                fileService.uploadFiles({
                    files: vm.model.uploadFiles,
                    fileNameSyntax: vm.model.fileNameSyntax,
                    preprocess: function (fileName, file) {
                        var ppDeferred1 = $q.defer();
    
                        fileService.hash(file).then(function (response) {
                            docarcService.exists(fileName, response.hash, $stateParams.argtype, $stateParams.argvalue).then(function (data) {
                                ppDeferred1.resolve({ cancelUpload: data.exists });
                            });
                        });
    
                        return ppDeferred1.promise;
                    },
                    postprocess: function (data) {
                        var ppDeferred2 = $q.defer();
    
                        if (data.fileItemKeyno > 0) {
                            if (data.itemExists === true) {
                                if (data.updatedFileName === true) {
                                    var exists = false;
    
                                    for (var i = 0; i < vm.model.files.length; i++) {
                                        if (vm.model.files[i].id !== data.fileItemKeyno) continue;
    
                                        vm.model.files[i].fileName = data.fileName;
    
                                        docarcService.update({
                                            docarcKeyno: vm.model.files[i].docarcKeyno,
                                            checksum: vm.model.files[i].checksum,
                                            checksum_md5: vm.model.files[i].checksum_md5,
                                            docFileName: vm.model.files[i].fileName
                                        });
    
                                        exists = true;
    
                                        break;
                                    }
    
                                    if (exists === false) {
                                        // file already exists on the fileserver but it is not connected to docarc for this argtype and argvalue.
                                        // We must add a new record to docarc same as if the file didn't exist.
                                        addToDocarc(data).then(function() {
                                            ppDeferred2.resolve();
                                        });
                                    } else {
                                        ppDeferred2.resolve();
                                    }
                                }
                            } else {
                                addToDocarc(data).then(function () {
                                    ppDeferred2.resolve();
                                });
                            }
                        } else {
                            if (data.isError === true) {
                                
                            }
    
                            ppDeferred2.resolve();
                        }
    
                        return ppDeferred2.promise;
                    }
                }).then(function () {
                    vm.model.uploadFiles.length = 0;
                    vm.model.selectedFileNames = '';
                    vm.model.uploadLocked = false;
    
                    loadFiles();
                });
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.model.bgPictureSelection.selected = picturesService.currentView;

            vm.selectBgMain = function (value) {
                vm.model.bgMain.selected = value;

                if (vm.model.files.length != nbrOfRetrievedFiles || nbrOfRetrievedFiles === 0) loadFiles();
            };

            vm.selectBgPictureSelection = function (value) {
                vm.model.bgPictureSelection.selected = value;
                picturesService.currentView = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadFiles();
                }
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            watcher.$watch(function () {
                return vm.model.uploadFiles;
            }, function () {
                var files = '';

                for (var i = 0; i < vm.model.uploadFiles.length; i++) {
                    if (i > 0) {
                        files += ', ';
                    }

                    files += vm.model.uploadFiles[i].name;
                }

                vm.model.selectedFileNames = files;
            });

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
