(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consoleService", [function () {
        var service = {
            inProduction: false,

            log: function () {
                if (service.inProduction === true) return;

                console.log.apply(console,Array.prototype.slice.call(arguments));
            },

            logProduction: function() {
                console.log.apply(console,Array.prototype.slice.call(arguments));
            },

            warn: function () {
                if (service.inProduction === true) return;

                console.warn.apply(console,Array.prototype.slice.call(arguments));
            },

            error: function () {
                if (service.inProduction === true) return;

                console.error.apply(console,Array.prototype.slice.call(arguments));
            },

            errorLog: function(log, dir) {
                console.log(log);

                if (angular.isDefined(dir) !== true) return;

                console.dir(dir);
            },

            dir: function(object) {
                if (service.inProduction === true) return;

                console.dir(object);
            }
        };

        return service;
    }]);
})();