(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('consignmentSplitEdit', {
        templateUrl: 'views/components/views/consignmentSplitEdit/consignmentSplitEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'modalService', 'consignmentSplitEditService', function ($stateParams, modalService, consignmentSplitEditService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let isReady = false;

            vm.model = {
                consignmentKeyNoSplitFrom: $stateParams.consignment_keyno_splitfrom,
                bgConsignmentSplitEdit: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_consignmentsplitedit_line', item_func: 'LINE' },
                        { item_id: '1', item_name: 'gb_consignmentsplitedit_manual', item_func: 'MANUAL' }
                    ]
                },
                setting: {},
                selectListConsignmentPlans: [],
                itemsListSplitEdits: [],
                loading: false
            };

            // ## IF CONDITIONS FUNCTION ##

            let validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.setting[parmWithValue]) === true) return false;
                if (vm.model.setting[parmWithValue] === null) return false;
                if (vm.model.setting[parmWithValue].trim().length < 1) return false;

                return true;
            };

            // ## GET BUTTON GROUP VALUE FUNCTION ##

            let getButtonGroupValue = function (bgVariable, propertyName) {
                angular.forEach(vm.model[bgVariable].records, function (data) {
                    if (angular.isDefined(vm.model.setting[propertyName]) && angular.isDefined(data)) {
                        if (vm.model.setting[propertyName] === data.item_func) {
                            vm.model[bgVariable].item_default = data.item_id;
                        }
                    }
                });
            };

            // ## LOAD PROCEDURE CALLS ##

            let loadConsignmentPlans = function () {
                if (validateParmsWithValue('consignment_keyno_splitfrom') !== true) return;

                vm.model.selectListConsignmentPlans = [];

                return consignmentSplitEditService.loadConsignmentPlanList(vm.model.setting.consignment_keyno_splitfrom).then(function (data) {
                    angular.copy(data, vm.model.selectListConsignmentPlans);
                });
            };

            let loadConsignmentSplitEdits = function () {
                if (validateParmsWithValue('consignment_keyno_splitfrom') !== true) return;

                vm.model.itemsListSplitEdits = [];

                return consignmentSplitEditService.loadConsignmentSplitEditList(vm.model.setting.consignment_keyno_splitfrom).then(function (data) {
                    angular.copy(data, vm.model.itemsListSplitEdits);
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            let canRun616 = function (variableName, parm) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
                if (vm.model.setting[parm] === null) return false;
                if (isReady === false) return false;

                return true;
            };

            let rememberLastConsignmentKeyNoSplitFrom = function () {
                let variableNameLastConsignmentKeyNoSplitFrom = 'w_consignmentsplitedit_last_consignment_keyno_splitfrom';

                if (canRun616(variableNameLastConsignmentKeyNoSplitFrom, 'consignment_keyno_splitfrom') !== true) return;

                return consignmentSplitEditService.remember(variableNameLastConsignmentKeyNoSplitFrom, vm.model.setting.consignment_keyno_splitfrom);
            };

            let rememberGbConsignmentSplitEdit = function () {
                let variableNameGbConsignmentSplitEdit = 'gb_consignmentsplitedit';

                if (canRun616(variableNameGbConsignmentSplitEdit, 'gb_consignmentsplitedit') !== true || !isReady) return;

                return consignmentSplitEditService.remember(variableNameGbConsignmentSplitEdit, vm.model.setting.gb_consignmentsplitedit);
            };

            // ## BUTTON GROUP FUNCTIONS ##

            vm.selectBgConsignmentSplitEdit = function (item) {
                vm.model.bgConsignmentSplitEdit.item_selected = item;
                vm.model.setting.gb_consignmentsplitedit = vm.model.bgConsignmentSplitEdit.item_selected;
                rememberGbConsignmentSplitEdit();
            };

            // ## TOGGLE FUNCTIONS ##

            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // ## SAVE BUTTON FUNCTION ##

            vm.moveLine = function () {
                vm.model.loading = true;

                let mergeObj = {
                    custact_no_consignmentplan: vm.model.setting.custact_no_consignmentplan,
                    consignment_keyno_splitfrom: vm.model.setting.consignment_keyno_splitfrom,
                    records: vm.model.itemsListSplitEdits.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected, "quantity_total": a.quantity_total }; })
                };

                consignmentSplitEditService.move(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    loadConsignmentSplitEdits();
                                    vm.model.loading = false;
                                }
                            }]
                        });
                    } else {
                        loadConsignmentSplitEdits();
                        vm.model.loading = false;
                    }
                });
            };

            async function loadConsignmentSplitEditGet() {
                return consignmentSplitEditService.loadConsignmentSplitEditGet(vm.model.consignmentKeyNoSplitFrom).then((data) => vm.model.setting = angular.copy(data[0]));
            }

            vm.$onInit = async function () {
                await loadConsignmentSplitEditGet();

                getButtonGroupValue('bgConsignmentSplitEdit', 'gb_consignmentsplitedit');

                await Promise.all([
                    rememberLastConsignmentKeyNoSplitFrom(),
                    loadConsignmentPlans(),
                    loadConsignmentSplitEdits()
                ]);

                isReady = true;
            }
        }]
    });
})();
