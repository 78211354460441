(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('interbranchMultiInvoicing', {
        templateUrl: 'views/components/views/interbranchMultiInvoicing/interbranchMultiInvoicing.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['interbranchMultiInvoicingService', '$q', '$stateParams', 'stateService', 'rememberService', 'modalService', '$uibModal', function (interbranchMultiInvoicingService, $q, $stateParams, stateService, rememberService, modalService, $uibModal) {
            var vm = this;

            vm.arg = {
                type: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                value: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
            };

            vm.model = {
                invoiceDate: null
            };

            var rememberIdGrid = 'w_interbranchMultiInvoicingComponentGrid';


            vm.grid = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 3228,
                        parameters: function () {
                            return {
                                argtype: vm.arg.type,
                                argvalue: vm.arg.value
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [{ name: 'makeInvoice', text: 'make_invoice', translate: true, func: function () { selectInvoiceDate(); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' }]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    //serverSideHandling: true
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };


            // #region BUTTON FUNCTIONS

            function selectInvoiceDate() {
                vm.model.invoiceDate = null;

                openInvoiceDateModal().then(function (result) {
                    vm.model.invoiceDate = result.invoiceDate;
                }).catch((reason) => {
                    console.log('Error getting invoice date: ', reason);
                }).finally(() => {
                    if (vm.model.invoiceDate !== null) {
                        console.log(vm.model.invoiceDate)
                        makeInvoice();
                    }
                    
                });
            };

            let openInvoiceDateModal = function () {
                let deferred = $q.defer();

                let modalInstance = $uibModal.open({
                    component: 'interbranchMultiInvoicingInvoiceDate',
                    resolve: {
                        parameters: function () {
                            return {};
                        }
                    },
                });

                modalInstance.result.then(function (response) {
                    deferred.resolve(response);
                }, function () {
                    deferred.reject();
                })

                return deferred.promise;
            };


            var getIsSelectedItemsParms = function (key) {
                if (!key) return [];
                let dirtyItems = vm.grid.gridfunc.getDirtyRows();
                let isSelectedItems = [];
                let keyList = [];

                if (angular.isArray(key)) {
                    keyList = key;
                } else {
                    keyList.push(key);
                }

                angular.forEach(dirtyItems, function (item) {
                    if (item && item.is_selected === true) {
                        let data = {};
                        angular.forEach(keyList, function (k) {
                            if (item[k]) data[k] = item[k];
                        });

                        isSelectedItems.push(data);
                    }
                });

                return isSelectedItems;
            }

            var makeInvoice = async function () {
                let errorcode = 0;
                let errormessage = 'Done ';
                var parmItems = getIsSelectedItemsParms(['shipmentcontainer_keyno']);
                console.dir(parmItems);
                vm.grid.gridfunc.gridProgress(true);
                if (parmItems.length > 0) {
                    for (let i = 0; i < parmItems.length; i++) {
                        let item = parmItems[i];
                        try {
                            let result = await interbranchMultiInvoicingService.makeInvoice(item.shipmentcontainer_keyno, vm.model.invoiceDate);

                            if (result[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'danger',
                                    title: 'Varsel',
                                    message: result[0].errormessage + "\n" + errormessage + "\n" + "shipmentcontainer_keyno with error: " + item.shipmentcontainer_keyno,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-danger',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                                errorcode = -1;
                                break;
                            }
                            else {
                                errormessage = errormessage + String(item.shipmentcontainer_keyno) + ", "
                            }
                        } catch (error) {
                            console.error(error);
                            errorcode = -1;
                            break;
                        }
                    }
                }
                vm.grid.gridfunc.gridProgress(false);

                if (errorcode === 0) {
                    modalService.show({
                        type: 'success',
                        title: 'Varsel',
                        message: 'Done',
                        buttons: [{
                            label: 'OK',
                            cssClass: 'btn-success',
                            action: function (dialogItself) {
                                dialogItself.close();
                            }
                        }]
                    });
                }

                vm.grid.gridfunc.rebind();

            }




            // #endregion BUTTON FUNCTIONS

        }]
    });
})();
