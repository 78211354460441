(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttWebpagemenu', {
        templateUrl: 'views/components/directives/ttWebpagemenu/ttWebpagemenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttName: '<'
        },
        controller: ['$uibModal', 'ttWebPageMenuService', 'ttWebPageMenuEditorService', 'userService', 'modalService', 'translateService', function ($uibModal, ttWebPageMenuService, ttWebPageMenuEditorService, userService, modalService, translateService) {
            var vm = this;

            var translations = {
                tt_confirm_delete_message: ''
            };

            vm.menu = [];

            vm.developMode = false;
            vm.inEditMode = false;

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            var load = function () {
                ttWebPageMenuService.load(vm.ttName, vm.inEditMode).then(function (data) {
                    angular.copy(data, vm.menu);
                });
            };

            vm.onEditModeChanged = function (value) {
                vm.inEditMode = value;

                load();
            };

            vm.deleteItem = function (item) {
                ttWebPageMenuEditorService.delete(item.link_id, translations.tt_confirm_delete_message).then(load, load);
            };

            vm.onNew = function () {
                modalService.showNewWebpagelinkModal(0, vm.ttName).then(load, load);
            };

            vm.onEditItem = function (item) {
                var instance = $uibModal.open({
                    component: 'ttWebpagemenuEditor',
                    resolve: {
                        item: function () {
                            return item;
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                instance.result.then(load, load);
            };

            userService.ensureIsReady().then(function () {
                vm.developMode = userService.developMode;
            });

            vm.$onInit = load;
        }]
    });
})();
