(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('docPackTypeMenu', {
        templateUrl: 'views/components/views/docPackTypeMenu/docPackTypeMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'modalService', 'translateService', 'docPackTypeMenuService', function ($stateParams, $q, stateService, modalService, translateService, docPackTypeMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let docPackTypeKeyno = $stateParams.docpacktype_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                documentationPackTypeNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('docpacktype', { docpacktype_keyno: vm.model.menu.docpacktype_keyno }) }
                ],
                menu: {},
                gridReady: false,
                lockedDelete: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'docpacktypechapter_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'docpacktypemenu_new_chapter',
                                text: 'docpacktypemenu_new_chapter',
                                func: function () {
                                    stateService.go('docpacktypechapter', {
                                        docpacktype_keyno: vm.model.menu.docpacktype_keyno,
                                        docpacktypechapter_keyno: '0'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
            	const deferred = $q.defer();

            	docPackTypeMenuService.getMenu({ docpacktype_keyno: docPackTypeKeyno }).then(function (info) {
            		angular.copy(info[0], vm.model.menu);

                    loadGrid();

            		deferred.resolve();
            	});

            	return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3278,
                    parameters: { docpacktype_keyno: vm.model.menu.docpacktype_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    docPackTypeMenuService.deleteItem(vm.model.menu).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadMenu();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
