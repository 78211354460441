(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttInput', {
        templateUrl: 'views/components/directives/ttInput/ttInput.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'hidden', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',       // renders the label invisible but will keep space for the label.
            ttSublabel: '@',
            ttReadonly: '@',
            ttTabable: '@',         // RHE 20240307 can turn of ability to tab to it
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttTextAlign: '@',
            ttPlaceholder: '@',
            ttItemId: '@',
            ttBindFocus: '@',
            ttRequired: '@',
            ttType: '@',
            ttOnBlur: '&',
            ttFunc: '&',
            ttStyle: '<',           // BJS 20220809
            ttInvalid: '<',         // BJS 20220928
            ttInputMode: '@',       // RHE 20230103
            ttMaxLength: '@',       // RHE 20230316
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttOptions: '<',         // JLR 20231009 configurationobject, takes all other component properteties as properties, supports both camelCase and snake_case notation.
        },
        controller: ['$element', '$timeout', '$interval', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, $timeout, $interval, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.hideLabel = false;
            vm.readonly = false;
            vm.tabable = '';
            vm.required = false;
            vm.inputType = 'text';
            vm.inputMode = 'text';
            vm.maxLength = 524288;  //Webstandard default

            vm.id = {
                input: uuid()
            };

            vm.style = {
                base: {},
                label: {},
                sublabel: {},
                input: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                sublabel: '',
                input: ''
            };

            vm.translations = {
                ttLabel: '',
                ttPlaceholder: '',
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

            var setInputMode = function (value) {
                var element = $element.find('#' + vm.id.input);

                element.attr('inputmode', value);
            };

            var isValidInputType = function (type) {
                if (us.isStringValue(type, true) !== true) return false;

                switch (type) {
                    case 'button':
                    case 'checkbox':
                    case 'color':
                    case 'date':
                    case 'datetime-local':
                    case 'email':
                    case 'file':
                    case 'hidden':
                    case 'image':
                    case 'month':
                    case 'number':
                    case 'password':
                    case 'radio':
                    case 'range':
                    case 'reset':
                    case 'search':
                    case 'submit':
                    case 'tel':
                    case 'text':
                    case 'time':
                    case 'url':
                    case 'week':
                        return true;
                }

                return false;
            };

            var isValidInputMode = function (mode) {
                if (us.isStringValue(mode, true) !== true) return false;

                switch (mode) {
                    case 'none':
                    case 'text':
                    case 'decimal':
                    case 'numeric':
                    case 'tel':
                    case 'search':
                    case 'email':
                    case 'url':
                        return true;
                }

                return false;
            };

            var inputElement;
            var stopFocusBinding;

            var setFocus = function () {
                if (angular.isUndefined(inputElement)) {
                    inputElement = $element.find('#' + vm.id.input)[0];
                }

                if (angular.isDefined(inputElement)) {
                    $timeout(function () {
                        inputElement.focus();
                    }, 0);
                }
            };

            var bindFocus = function () {
                // return if focus already bound
                if (angular.isDefined(stopFocusBinding)) return;

                stopFocusBinding = $interval(setFocus, 1000);

                $element.focus();
            };

            var unbindFocus = function () {
                if (angular.isUndefined(stopFocusBinding))
                    return;

                $interval.cancel(stopFocusBinding);

                stopFocusBinding = undefined;
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.ttInputMode) && isValidInputMode(vm.ttInputMode) === true) {
                    vm.inputMode = vm.ttInputMode;
                    setInputMode(vm.inputMode);
                }

                if (angular.isDefined(vm.ttMaxLength)) {
                    vm.maxLength = vm.ttMaxLength;
                }

                if (angular.isFunction(vm.ttFunc)) {
                    vm.ttFunc({
                        $value: {
                            setFocus: setFocus,
                            getInputElement: function () { return $element.find('#' + vm.id.input)[0]; },
                            bindFocus: bindFocus,
                            unbindFocus: unbindFocus
                        }
                    });
                }

                if (angular.isDefined(vm.ttInvalid) && us.toBoolean(vm.ttInvalid) === true) {
                    vm.style.input.borderColor = 'red';
                }

                setStyle(vm.ttStyle);
            }

            var focusIsBound = false;

            vm.$onChanges = function (changes) {
                if (changes.ttOptions?.currentValue) {
                    ttDirectivesService.setOptions(vm, changes);
                }

                if (angular.isDefined(changes.ttInvalid)) {
                    vm.ttInvalid = us.toBoolean(changes.ttInvalid.currentValue);

                    if (vm.ttInvalid === true) {
                        vm.style.input.borderColor = 'red';
                    } else {
                        delete vm.style.input.borderColor;
                    }
                }

                if (angular.isDefined(changes.ttBindFocus) && us.isStringValue(changes.ttBindFocus.currentValue)) {
                    focusIsBound = us.toBoolean(changes.ttBindFocus.currentValue, true);

                    if (focusIsBound === true) {
                        bindFocus();
                    } else {
                        unbindFocus();
                    }
                }

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttType) && isValidInputType(changes.ttType.currentValue) === true) {
                    vm.inputType = changes.ttType.inputType;
                }

                if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
                    vm.style.input.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttReadonly)) {
                    vm.readonly = us.toBoolean(changes.ttReadonly.currentValue);
                }

                if (angular.isDefined(changes.ttTabable)) {
                    if (us.toBoolean(changes.ttTabable.currentValue) === false || changes.ttTabable.currentValue === '-1') {
                        vm.tabable = '-1';
                    } else {
                        vm.tabable = '';
                    }
                }

                if (angular.isDefined(changes.ttPlaceholder) && us.isStringValue(changes.ttPlaceholder.currentValue, true)) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttPlaceholder = changes.ttPlaceholder.currentValue;
                    } else {
                        translateService.translate(changes.ttPlaceholder.currentValue).then((translation) => vm.translations.ttPlaceholder = translation);
                    }
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttRequired) && angular.isDefined(changes.ttRequired.currentValue)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttInputMode) && isValidInputMode(changes.ttInputMode.currentValue) === true) {
                    vm.inputMode = changes.ttInputMode.currentValue;
                    setInputMode(vm.inputMode);
                }

                if (angular.isDefined(changes.ttMaxLength)) {
                    vm.maxLength = changes.ttMaxLength.currentValue;
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            onDestroy.push(eventService.on('event:focuslock:start', function (data) {
                if (focusIsBound !== true)
                    return;

                bindFocus();
            }));

            onDestroy.push(eventService.on('event:focuslock:stop', function (data) {
                if (focusIsBound !== true)
                    return;

                unbindFocus();
            }));

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            vm.onBlur = function ($event) {
                if (angular.isFunction(vm.ttOnBlur)) {
                    vm.ttOnBlur($event);
                }
            }

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
