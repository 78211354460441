(function () {
    'use strict';

    angular.module("imApp").factory("csvImportService", ['$ihttp', function ($ihttp) {
        let service = {
            importFileCsv: function (filecontent) {
                return $ihttp.post({
                    method: 1524,
                    parameters: filecontent
                });
            }
        };

        return service;
    }]);
})();
