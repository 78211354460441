import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import SignaturePad from "signature_pad"
import { ModalService } from '../../../core/services/modal.service';


/**
 * SIGNATURE PAD COMPONENT
 * CREATED BY: HSK
 *
 * signature pad component containing a signature field and a clear button
 * @Input: ttClear: true if you want the user to be able to clear the field without going into the modal
 *
 * use <tt-signature-pad> in your html
 *
 * IF YOU NEED THE SIGNATURE DATA FOR A REPORT, DELIVERY ETC....
 * USE getSignatureData (returns an svg)
 *
 * NEED ANOTHER FORMAT THAT IS NOT IMPLEMENTED?
 * Here is the documentation of the code, add another getter function for what you need (takes 1 - 2 min)
 * https://github.com/szimek/signature_pad
 */

@Component({
  selector: 'tt-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent {
    @ViewChild('canvas')
    canvasElement!: ElementRef<HTMLCanvasElement>;
    signaturePad!: SignaturePad;

    // bool, if true: clear button is visible on field
    @Input() ttClear: boolean = false;

    constructor(
        private modalService: ModalService,
    ) { }

    // Initialize the signaturePad
    ngAfterViewInit() {
        const canvas = this.canvasElement.nativeElement;
        this.signaturePad = new SignaturePad(canvas);
        /*this.resizeCanvas();*/
        /*window.addEventListener('resize', this.resizeCanvas.bind(this));*/
    }

    // Clear the content within the signaturePad
    clear() {
        this.signaturePad.clear();
    }

    // Opens the modal on click and sets the signature as it's data
    async openModal() {
        // This returns a dataURL of type SVG
        let answer = await this.modalService.openSignaturePadDialog();
        if (answer) {
            // Clear previous data
            this.signaturePad.clear();
            this.signaturePad.fromDataURL(answer);
        }
    }

    // Gets signature data as svg
    getSignatureData() {
        this.signaturePad.toSVG();
    }

}
