(function() {
    'use strict';

    angular.module("imApp").factory("ediSetupService", ['$q', '$ihttp', 'base64', function ($q, $ihttp, base64) {
        var service = {
            load: function (argtype, argvalue) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 2266,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                }).then(function (response) {
                    angular.forEach(response.ediDestinations, function (edid) {
                        if (angular.isObject(edid) && angular.isDefined(edid.name) && edid.name !== null && edid.name.length > 0) {
                            try {
                                edid.name = base64.urldecode(edid.name);
                            } catch (_) {}
                        }
                    });

                    angular.forEach(response.ediTypes, function (ediType) {
                        if (angular.isObject(ediType) && angular.isDefined(ediType.name) && ediType.name !== null && ediType.name.length > 0) {
                            try {
                                ediType.name = base64.urldecode(ediType.name);
                            } catch (_) { }
                        }

                        angular.forEach(ediType.exportprocs, function (exportproc) {
                            if (angular.isObject(exportproc) && angular.isDefined(exportproc.name) && exportproc.name !== null && exportproc.name.length > 0) {
                                try {
                                    exportproc.name = base64.urldecode(exportproc.name);
                                } catch (_) { }
                            }
                        });

                        angular.forEach(ediType.reportdefs, function (reportdef) {
                            if (angular.isObject(reportdef) && angular.isDefined(reportdef.name) && reportdef.name !== null && reportdef.name.length > 0) {
                                try {
                                    reportdef.name = base64.urldecode(reportdef.name);
                                } catch (_) { }
                            }

                            if (angular.isObject(reportdef) && angular.isDefined(reportdef.description) && reportdef.description !== null && reportdef.description.length > 0) {
                                try {
                                    reportdef.description = base64.urldecode(reportdef.description);
                                } catch (_) { }
                            }

                            if (angular.isObject(reportdef) && angular.isDefined(reportdef.filename) && reportdef.filename !== null && reportdef.filename.length > 0) {
                                try {
                                    reportdef.filename = base64.urldecode(reportdef.filename);
                                } catch (_) { }
                            }
                        });
                    });

                    deferred.resolve(response);
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
