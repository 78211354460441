(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2WebpageLinkEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            webpage_link_edit: {
                p2_webpagelink_keyno: '',
                p2_webpage_keyno_belongto: '',
                p2_webpage_keyno: '',
                params: '',
                orderby: '',
                word_id: ''
            },
            p2_webpage_link_list_belongto: {
                item_id: '',
                item_name: '',
                records: []
            },
            loadP2WebpageLinkGet: function (p2_webpage_keyno_belongto, p2_webpagelink_keyno) {
                var deferred = $q.defer();

                var parms_p2webpagelink_get = {
                    method: 17,
                    parameters: {
                        p2_webpage_keyno_belongto: p2_webpage_keyno_belongto,
                        p2_webpagelink_keyno: p2_webpagelink_keyno
                    }
                };

                $ihttp.post(parms_p2webpagelink_get).then(function (data) {
                    service.webpage_link_edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2WebpageLinkList: function () {
                var deferred = $q.defer();

                var parms_p2webpagelink_list = {
                    method: 4,
                    parameters: {
                        item_id: service.p2_webpage_link_list_belongto.item_id,
                        item_name: service.p2_webpage_link_list_belongto.item_name,
                        records: service.p2_webpage_link_list_belongto.records
                    }
                };

                $ihttp.post(parms_p2webpagelink_list).then(function (data) {
                    angular.copy(data, service.p2_webpage_link_list_belongto.records);
                });

                return deferred.promise;
            },
            saveP2WebpageLinkEdit: function () {
                var deferred = $q.defer();

                var parms_p2webpagelink_save = {
                    method: 18,
                    parameters: {
                        p2_webpagelink_keyno: service.webpage_link_edit.p2_webpagelink_keyno,
                        p2_webpage_keyno_belongto: service.webpage_link_edit.p2_webpage_keyno_belongto,
                        p2_webpage_keyno: service.webpage_link_edit.p2_webpage_keyno,
                        params: service.webpage_link_edit.params,
                        orderby: service.webpage_link_edit.orderby,
                        word_id: service.webpage_link_edit.word_id
                    }
                };

                $ihttp.post(parms_p2webpagelink_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();