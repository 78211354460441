(function () {
    'use strict';

    angular.module("imApp").factory("domObjectChildService", ['$ihttp', function ($ihttp) {
        let service = {
            getDomObjectChild: function (parms) {
                return $ihttp.post({
                    method: 3034,
                    parameters: parms || {}
                });
            },
            listDomObjectDatas: function (parms) {
                return $ihttp.post({
                    method: 3035,
                    parameters: parms || {}
                });
            },
            listDomObjectChild: function (parms) {
                return $ihttp.post({
                    method: 3043,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 3036,
                    parameters: mergeObj
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 3038,
                    parameters: edit
                });
            },
            deleteDomObjectItem: function (edit) {
                return $ihttp.post({
                    method: 3044,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
