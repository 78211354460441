(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('shipmentContainer', {
        templateUrl: 'views/components/views/shipmentContainer/shipmentContainer.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'shipmentContainerService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, shipmentContainerService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let shipmentContainerKeyno = $stateParams.shipmentcontainer_keyno;
    		
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListStockLocations: [],
                itemsListContents: [],
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                let deferred = $q.defer();
                
                shipmentContainerService.getEdit({ shipmentcontainer_keyno: shipmentContainerKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadContents(),
                        loadStockLocations()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadStockLocations = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id_to) !== true) return;
                
                return shipmentContainerService.listStockLocations({
                    stockzoneclass_keyno: '1',
                    stockplace_id: vm.model.edit.stockplace_id_to,
                    add_all: '0'
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockLocations);
                });
            };
            
            let loadContents = function () {
                if (utilityService.validateParmsValue(vm.model.edit.shipmentcontainer_keyno) !== true) return;

                return shipmentContainerService.listContents({ shipmentcontainer_keyno: vm.model.edit.shipmentcontainer_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListContents);
                });
            };
            
            // ###########################
            // ## REMEMBER VALUE FUNCTIONS
            // ###########################
            
            var rememberStockLocationKeyno = function () {
                var variableNameStockLocationKeyno = 'w_shipmentcontainer.stocklocation_keyno.stockplaceid=' + vm.model.edit.stockplace_id_to;
                
                if (angular.isDefined(variableNameStockLocationKeyno) && angular.isDefined(vm.model.edit.stocklocation_keyno)) {
                    shipmentContainerService.remember(variableNameStockLocationKeyno, vm.model.edit.stocklocation_keyno);
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                shipmentContainerService.createObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedCreate = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedCreate = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stocklocation_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stocklocation_keyno) !== true) return;

                        vm.model.edit.stocklocation_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stocklocation_keyno) !== true) return;

                        rememberStockLocationKeyno();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
