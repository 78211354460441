(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceMethodLangs', {
        templateUrl: 'views/components/views/invoiceMethodLangs/invoiceMethodLangs.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'invoiceMethodLangsService', function ($stateParams, stateService, invoiceMethodLangsService) {
            var vm = this;
            
            vm.model = {
                invoiceMethodNo: $stateParams.invoicemethod_no,
                inputListLanguages: [],
                lockedSave: false
            };
            
            invoiceMethodLangsService.loadInvoiceMethodLangsList(vm.model.invoiceMethodNo).then(function (data) {
                angular.copy(data, vm.model.inputListLanguages);
            });
            
            vm.saveInvoiceMethodLangs = function () {
                vm.model.lockedSave = true;
                
                var mergeObj = {
                    records: vm.model.inputListLanguages
                };
                
                invoiceMethodLangsService.save(mergeObj).then(function (data) {
                    vm.model.lockedSave = false;
                    stateService.back();
                });
            };
        }]
    });
})();