(function() {
    'use strict';

    angular.module("imApp").factory("ttDynamicButtonService", ['$ihttp', function($ihttp) {
        var service = {
            init: function (keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initDynamicButton',
                        keyno: keyno
                    }
                });
            },

            datatask: function (datatask_keyno, parameters) {
                return $ihttp.post({
                    method: datatask_keyno,
                    parameters: parameters
                });
            }
        }

        return service;
    }]);
})();
