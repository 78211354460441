(function () {
	'use strict';

	var module = angular.module("imApp");

    module.factory("payCheckBatchService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
		var service = {
			loadPayCheckBatchGet: function (paycheckbatch_keyno) {
				return p2DataTaskService.call(1368, {
					paycheckbatch_keyno: paycheckbatch_keyno
				});
			},
			loadPayCheckList: function (paycheckbatch_keyno) {
				return p2DataTaskService.call(1371, {
					paycheckbatch_keyno: paycheckbatch_keyno
				});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			exportTo: function (paycheckbatch_keyno) {
				return p2DataTaskService.call(1523, {
					paycheckbatch_keyno: paycheckbatch_keyno
                });
            },
			update: function (paycheckbatch_keyno) {
				return p2DataTaskService.call(1370, {
					paycheckbatch_keyno: paycheckbatch_keyno
				});
			},
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1369,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
