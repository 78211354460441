(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderSpecialGridAdd', {
        templateUrl: 'views/components/views/orderSpecial/orderSpecial.template.grid.add.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['layoutService', 'translateService', 'orderSpecialService', function (layoutService, translateService, orderSpecialService) {
            var vm = this;

            var onDestroy = [];

            vm.prod = {
                data: {
                    prod_id: '',
                    prod_name: '',
                    quantity: '',
                    note: ''
                },
                selected: {
                    field: '',
                    key: ''
                },
                list: []
            };

            vm.fontSize = '14px';

            vm.translations = {
                tt_new_order_special_prod: ''
            };

            vm.add = function () {
                vm.modalInstance.close(vm.prod.data);
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            // #region LOOKUPSEARCH FUNCTIONS

            vm.selectItem = function (item, model) {
                if (angular.isDefined(item) && angular.isDefined(item.prod_id) && item.prod_id !== null && item.prod_id !== '') {
                    vm.prod.selected.field = item.prod_keyname;
                    vm.prod.data.prod_id = item.prod_id;
                    vm.prod.data.prod_name = item.prod_name;
                }
            };

            // #endregion LOOKUPSEARCH FUNCTIONS

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            vm.$onInit = function () {
                orderSpecialService.getProdKeynameList().then(function (data) {
                    angular.copy(data, vm.prod.list);
                    //if (vm.prod.list.length > 0 && vm.prod.data.prod_id === '') {
                    //    vm.prod.selected.field = vm.prod.list[0].prod_keyname;
                    //    vm.prod.data.prod_id = vm.prod.list[0].prod_id;
                    //    vm.prod.data.prod_name = vm.prod.list[0].prod_name;
                    //}
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
