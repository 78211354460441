(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockCalc', {
        templateUrl: 'views/components/views/stockCalc/stockCalc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$window', '$timeout', 'stateService', 'utilityService', 'stockCalcService', 'ieScreenBlockService', function ($stateParams, $window, $timeout, stateService, utilityService, stockCalcService, ieScreenBlockService) {
            var vm = this;

            /*
            function getSettings() {
                consignmentDeliveryService.get().then(function (data) {
                    vm.model.deliverymethod_no = data[0].cd_deliverymethod_no;
                });
            }

            function loadDeliveryMethods() {
                consignmentDeliveryService.listDeliveryMethods()
                    .then(function (data) {
                        angular.copy(data, vm.model.deliveryMethods);
                        if (vm.model.deliveryMethods.length > 0) {
                            loadConsignmentDeliveries();
                        }
                    });
            }

            function loadConsignmentDeliveries() {
                if (vm.model.deliverymethod_no > 0) {
                    ieScreenBlockService.start('message');
                    ieScreenBlockService.setMessage('Laster informasjon');
                    consignmentDeliveryService.listConsignmentDeliveries(vm.model.deliverymethod_no).then(function (data) {
                        angular.copy(data, vm.model.consignmentDeliveryData);
                        ieScreenBlockService.stop();

                    });
                }
            }

            function onDeliveryMethodChange() {
                $timeout(function () {
                    consignmentDeliveryService.remember(vm.model.variableName, vm.model.deliverymethod_no).then(function () { });
                    loadConsignmentDeliveries();
                }, 50);
            }

            vm.model = {
                variableName: 'cd_deliverymethod_no',
                deliveryMethods: [],
                deliverymethod_no: 0,
                consignmentDeliveryData: [],
                loadDeliveryMethods: loadDeliveryMethods,
                loadConsignmentDeliveries: loadConsignmentDeliveries,
                onDeliveryMethodChange: onDeliveryMethodChange
            }

            getSettings();
            loadDeliveryMethods();

            */
        }]
    });
})();
