(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentBatchConsignments', {
        templateUrl: 'views/components/views/consignmentBatchConsignments/consignmentBatchConsignments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'consignmentBatchConsignmentsService', function($stateParams, consignmentBatchConsignmentsService) {
            var vm = this;

            // ##############################
            // GLOBAL VARIABLES & DEFINITIONS
            // ##############################
    
            vm.model = {
                p2ConsignmentList: {
                    records: []
                }
            };
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            consignmentBatchConsignmentsService.loadP2ConsignmentList($stateParams.consignmentbatch_keyno).then(function () {
                vm.model.p2ConsignmentList = consignmentBatchConsignmentsService.p2ConsignmentList;
            });
        }]
    });
})();