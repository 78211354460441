(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('dbStation', {
        templateUrl: 'views/components/views/dbStation/dbStation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'modalService', 'dbStationService', function (stateService, modalService, dbStationService) {
            var vm = this;

            vm.model = {
                edit: {},
                testingAccessToken: false,
                lockedSave: false
            };

            dbStationService.getDbStation().then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            vm.testChanges = function () {
                vm.model.testingAccessToken = true;

                dbStationService.testAccessToken(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.testingAccessToken = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.testingAccessToken = false;
                    }
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                dbStationService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };
        }]
    });
})();
