(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('msgCreate', {
        templateUrl: 'views/components/views/msgCreate/msgCreate.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'msgCreateService', function (stateService, utilityService, msgCreateService) {
            var vm = this;
            
            vm.model = {
                settingMessage: {},
                lockedSave: false
            };
            
            msgCreateService.loadMsgCreateGet().then(function (data) {
                angular.copy(data[0], vm.model.settingMessage);
            });
            
            vm.saveMsgCreate = function () {
                vm.model.lockedSave = true;
                
                msgCreateService.save(vm.model.settingMessage).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        vm.model.lockedSave = false;
                        
                        stateService.go('translateedit', {
                            item_id: vm.model.settingMessage.word_id
                        });
                    }
                });
            };
        }]
    });
})();