(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('olmRequisition', {
        templateUrl: 'views/components/views/olmRequisition/olmRequisition.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'olmRequisitionService', function ($stateParams, $q, stateService, utilityService, olmRequisitionService) {
            const vm = this;
            let olmKeyno = $stateParams.olm_keyno;

            vm.model = {
                orderButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('orderhead', { order_internal_no: vm.model.menu.order_internal_no }) }
                ],
                menu: {},
                itemsListStocks: []
            };
            
            let loadMenu = function () {
                let deferred = $q.defer();

                olmRequisitionService.getMenu({ olm_keyno: olmKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);

                    $q.all([
                        loadStocks()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadStocks = function () {
                if (utilityService.validateParmsValue(vm.model.menu.stock_keyno_to) !== true) return;
                
                return olmRequisitionService.listStocks({ stock_keyno_to: vm.model.menu.stock_keyno_to }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStocks);
                });
            };
            
            vm.goToRequisitionMake = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                olmRequisitionService.genericFunction(761, {
                    stock_keyno_from: item.stock_keyno,
                    stock_keyno_to: vm.model.menu.stock_keyno_to,
                    quantity: vm.model.menu.quantity,
                    olm_keyno: vm.model.menu.olm_keyno
                }).then(function (data) {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
        }]
    });
})();
