/// <reference path="stringtonumber.js" />
(function () {
    'use strict';

    let module = angular.module('imApp');

    module.directive('imInputDirective', ['$rootScope', '$timeout', '$compile', 'modalService', 'layoutService', 'ttItemService', 'eventService', 'ttDirectivesService', function ($rootScope, $timeout, $compile, modalService, layoutService, ttItemService, eventService, ttDirectivesService) {
        var directive = {
            restrict: 'E',
            scope: {
                label: '@',         // Used for translation of label
                plabel: '=',        // Used instead of label when property is required
                sublabel: '=',      // Used if a sublabel is wanted
                type: '@',          // 
                typevar: '@',
                ptype: '=',         // Used instead of type when property is required 
                ptypevar: '=',      // Used instead of typevar when property is required
                dtvar: '=',         // Can be used in conjunction with type of 'datetime'       //Requires {minuteInterval: 'integer' (default=1)} 
                field: '=',
                myuser: '=',        // Used in conjunction with 'message' type to have differing colors dependant on user
                spin: '=',          // Add the property in charge of the spin state
                placeholder: '=',
                glyphs: '@',        // Added as class and will determine the glyphicons to be used, also need pull-left or pull-right for corresponding alignment
                icons: '=',         // Used to add icons into the field
                btnList: '=',       // Used in addition when typevar 'o' is set to add custom buttons to an input field
                btnvar: '@',        // Used to add predefined btn to input 
                pbtnvar: '=',       // Used instead of btnvar when property is required
                data: '=',
                dataid: '@',
                dataname: '@',
                optionfunc: '&?',   // Links a function from controller
                changefunc: '&?',   // Links a function from controller that works on change
                ieTrueValue: '=',   // BJS 20180525
                ieFalseValue: '=',  // BJS 20180525
                ttItemId: '@',      // BJS 20210603
                ttGroupId: '@',     // BJS 20210603
                ttFieldId: '@',     // BJS 20210603
                ttStyle: '<',       // BJS 20220928
                ttInvalid: '<'      // BJS 20220928
            },

            //The different types                                                               //Requirements
            //select = Makes the field a dropdown                                               //data, dataid, dataname
            //textarea = Makes the field a multiline textinput                                  //
            //input = Makes the field a singleline textinput                                    //
            //input_o = Makes the field a singleline textinput with multiple buttons            //
            //checkbox = Makes the field a large checkbox                                       //
            //checkbox_s = Makes the field a small checkbox                                     //
            //search = Makes the field a singleline textinput for searching                     //
            //signature = Makes the field a singleline textinput for signatures                 //
            //message = Makes the field display img and text and look like comments             //
            //html = Makes the field a pure html                                                //still in development
            //datetime = Makes the field a date and/or time field                               //requires a typevar
            //
            //The different datetime typevars                                                   //'dhm' specifies groupings
            //d = Date field                                                                    //
            //h = Hour field                                                                    //
            //m = Minute field                                                                  //
            //r = Makes the field READONLY                                                      //r is added behind the relevant type to make that field READONLY, ex: 'dr', 'dhr', 'hmr'
            //
            //The different typevars                                                            //
            //a = Sets autofocus to this element initially                                      //'aa' will make it always have focus
            //b = Enables buttons in the field                                                  //(Not predefined buttons)
            //c = Makes a "Clear input" icon in the field                                       //Does not work with 'o' in search
            //d = Makes the textarea an editor as well                                          //After d, add 0, to make the editor have 0 tools added. 'dd' will make it dynamic as well.
            //e = Adds an "E-mail"-button to the field                                          //
            //f = Adds functionality to suggest from search                                     //
            //g = Adds a goTo function to a button                                              //
            //h = Makes the field format text to the right                                      //
            //k = Adds a button to scan barcodes                                                //Works only when used from "Mochasoft Barcode"-app
            //n = Makes the field format numbers, removing/adding decimals and overflow 0's     //After n, add 0-9. ex: 'n3', also supports '+' for always positive number, ex: 'n4+', with 'b' it adds a calc button
            //o = Adds one or several buttons to a line                                         //After o, add 0-9. ex: 'o3' //Predefined NOT implemented, but list from ctrl works
            //p = Makes the field like a password                                               //
            //q = Adds a "required-star" behind the label                                       //
            //r = Makes the field READONLY                                                      //
            //s = Adds an event to mark the field when it gets focus                            //
            //t = Adds a "Telephone"-button to the field                                        //
            //u = Input field that has buttons for adjusting number up or down                  //Maybe add increment multiplier?
            //z = Clears the input after the OptionFunc has completed                           //
            //
            //Note:msd-elastic needs to be in class, not ng-class in order to work.
            //templateUrl: './views/templates/directives/imInputDirective.html?v=' + module.version,
            template: `
            <div class="tt-input__container form-group imrl-0i col-xs-12 sp-0" ng-style="model.groupStyle">
                <div class="tt-input__base col-xs-12 sp-0" ng-class="model.baseClasses">
                    <!-- label, plabel, sublabel -->
                    <label ng-if="label !== undefined && label !== ''" class="tt-input__label" ng-class="model.labelClasses" ng-style="{'fontSize': fontSizes.textSizeS}" im-translate="{{label}}" when-ready="whenReady()" wait-for-interpolation="true">
                        <span ng-if="typevar.indexOf('q') > -1" class="tt-input__label--required" ng-style="{'fontSize': fontSizes.textSize}">*</span>
                    </label>

                    <label ng-if="plabel !== undefined && plabel !== ''" class="tt-input__label" ng-class="model.labelClasses" ng-style="{'fontSize': fontSizes.textSizeS}" when-ready="whenReady()" wait-for-interpolation="true">
                        {{plabel}}
                        <span ng-if="typevar.indexOf('q') > -1" class="tt-input__label--required" ng-style="{'fontSize': fontSizes.textSize}">*</span>
                    </label>

                    <label ng-if="sublabel !== undefined" class="tt-input__sublabel" ng-class="model.subLabelClasses" ng-style="{'fontSize': fontSizes.textSizeSs}" when-ready="whenReady()" wait-for-interpolation="true">{{sublabel}}</label>
                    <div class="tt-input__group" ng-class="{ 'tt-input__group--readonly': typevar.indexOf('r') > -1, 'tt-input__group--checkbox': type.includes('checkbox') }" style="display: flex; flex-direction: column;">
                        <!-- Select -->
                        <div ng-if="type === 'select'" class="col-xs-12 sp-0 im-no-pad" ng-class="model.inputClasses" when-ready="whenReady()">
                            <select ng-if="typevar === undefined || (typevar.indexOf('b') <= -1 && typevar.indexOf('i') <= -1)" ng-class="addClass('select')" type="text" ng-model="model.field" ng-change="goChange()" ng-options="item[dataid] as item[dataname] for item in data" ng-style="model.selectStyle" ng-disabled="typevar.indexOf('r') > -1" when-ready="whenReady()"></select>
                            <div ng-if="typevar.indexOf('b') > -1" style="display: flex;">
                                <select ng-class="addClass('select')" class="tt-input__form-control" type="text" ng-model="model.field" ng-change="goChange()" ng-options="item[dataid] as item[dataname] for item in data" ng-style="model.selectStyle" ng-disabled="typevar.indexOf('r') > -1" when-ready="whenReady()"></select>
                                <button type="button" ng-if="typevar.indexOf('o') > -1" ng-class="addBtnStyle($index)" ng-repeat="line in model.lines" ng-click="goTo($index)" when-ready="whenReady()" ng-style="{ 'height': model.selectStyle.height }">
                                    <span ng-if="typevar.indexOf('g') > -1" ng-class="addGlyph($index)" class="fa-fw" ng-style="{'fontSize': fontSizes.textSize}"></span>
                                </button>
                            </div>
                        </div>

                        <!-- Textarea -->
                        <div ng-if="type === 'textarea'" class="im-no-pad" ng-class="typeClass()" when-ready="whenReady()">
                            <textarea ng-if="typevar.indexOf('d') <= -1 || typevar === undefined" id="{{model.mId}}" class="tt-input__form-control tt-input__form-control--textarea msd-elastic" ng-model="model.field" ng-change="goChange()" rows="2" ng-focus="selectText()" ng-style="textAreaAdjust()" placeholder="{{model.placeholder}}" ng-readonly="typevar.indexOf('r') > -1" when-ready="whenReady()"></textarea>
                            <textarea ng-if="typevar.indexOf('d') > -1" id="{{model.mId}}" class="tt-input__form-control tt-input__form-control--textarea msd-elastic kendo-editor" k-tools="kTools()" k-encoded="false" ng-model="model.field" ng-change="goChange()" rows="2" ng-focus="selectText()" ng-style="textAreaAdjust()" ng-readonly="typevar.indexOf('r') > -1" when-ready="whenReady()"></textarea>
                        </div>

                        <!-- message -->
                        <div ng-if="type === 'message'" class="im-no-pad" ng-class="model.inputClasses" when-ready="whenReady()">
                            <div ng-class="addClass('bubble')" ng-bind-html="model.field" ng-style="{'fontSize': fontSizes.textSize}"></div>
                        </div>

                        <!-- checkbox -->
                        <div ng-if="type === 'checkbox_s'" ng-class="model.inputClasses" class="im-no-pad" when-ready="whenReady()" ng-style="model.checkboxStyle">
                            <input class="tt-input__form-control tt-input__form-control--checkbox" type="checkbox" ng-model="model.field" ng-change="goChange()" ng-true-value="{{model.trueValue}}" ng-false-value="{{model.falseValue}}" ng-disabled="typevar.indexOf('r') > -1" ng-style="{'height':fontSizes.textSize, 'width': fontSizes.textSize}" />
                        </div>

                        <div ng-if="type === 'checkbox'" ng-class="model.inputClasses" class="im-no-pad" when-ready="whenReady()" ng-style="model.checkboxStyle">
                            <input ng-class="addClass('checkbox')" type="checkbox" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-true-value="{{model.trueValue}}" ng-false-value="{{model.falseValue}}" ng-disabled="typevar.indexOf('r') > -1" ng-style="{fontSize: fontSizes.textSize}" />
                        </div>

                        <!-- search -->
                        <div ng-if="type === 'search'" style="display: flex;" ng-class="model.inputClasses" when-ready="whenReady()">
                            <input ng-if="(typevar.indexOf('f') <= -1 && typevar.indexOf('a') <= -1) || typevar === undefined" ng-class="addClass('search')" type="search" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" ng-blur="keepFocus(false)" when-ready="whenReady()" />
                            <input ng-if="typevar.indexOf('f') <= -1 && typevar.indexOf('a') > -1" ng-class="addClass('search')" type="search" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" autofocus ng-blur="keepFocus(false)" when-ready="whenReady()" />
                            <input ng-if="typevar.indexOf('f') > -1 && typevar.indexOf('a') > -1" ng-class="addClass('search')" type="search" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" autofocus ng-blur="keepFocus(false)" uib-typeahead="item.item_name for item in getItems($viewValue)" typeahead-min-length="3" typeahead-wait-ms="500" typeahead-append-to-body="true" when-ready="whenReady()"><!-- typeahead-loading="model.taLoading" typeahead-no-results="noResults">-->
                            <input ng-if="typevar.indexOf('f') > -1 && typevar.indexOf('a') <= -1" ng-class="addClass('search')" type="search" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" ng-blur="keepFocus(false)" uib-typeahead="item.item_name for item in getItems($viewValue)" typeahead-min-length="3" typeahead-wait-ms="500" typeahead-append-to-body="true" when-ready="whenReady()"><!-- typeahead-loading="model.taLoading" typeahead-no-results="noResults">-->
                            <button ng-if="typevar.indexOf('b') > -1 && typevar.indexOf('o') > -1" ng-class="addBtnStyle($index)" ng-repeat="line in model.lines" type="button" ng-click="goTo($index)" when-ready="whenReady()">
                                <span ng-if="typevar.indexOf('g') > -1" ng-class="addGlyph($index)" class="fa-fw" ng-style="{'fontSize': fontSizes.textSize}" when-ready="whenReady()"></span>
                            </button>
                            <button ng-if="typevar.indexOf('k') > -1 && typevar.indexOf('r') <= -1 && typevar.indexOf('o') <= -1" ng-style="{'fontSize': fontSizes.textSize}" class="tt-input__button tt-input__button--primary" type="button" ng-click="goBarcode()" when-ready="whenReady()">
                                <span class="fa-fw" ng-class="addGlyph('barcode')"></span>
                            </button>
                            <button ng-if="typevar.indexOf('b') > -1 && typevar.indexOf('r') <= -1 && typevar.indexOf('o') <= -1" ng-style="{'fontSize': fontSizes.textSize}" class="tt-input__button tt-input__button--primary" type="button" ng-click="goCalc()" when-ready="whenReady()">
                                <span class="fa-fw" ng-class="addGlyph('calc')"></span>
                            </button>

                            <button ng-if="typevar.indexOf('c') > -1 && typevar.indexOf('r') <= -1" ng-style="{'fontSize': fontSizes.textSize}" class="tt-input__button tt-input__button--danger" type="button" ng-click="clearInput()" when-ready="whenReady()">
                                <span class="fa-fw" ng-class="addGlyph('remove')"></span>
                            </button>
                            <button ng-if="typevar.indexOf('o') <= -1" type="button" class="tt-input__button tt-input__button--success" ng-click="goTo()" ng-style="{fontSize: fontSizes.textSize}">
                                <span ng-hide="spin">
                                    <span class="fa-fw fas fa-search" ng-style="{'fontSize': fontSizes.textSize}"></span>
                                </span>
                                <span ng-show="spin">
                                    <i class="fas fa-fw fa-spinner fa-spin" ng-style="{'fontSize': fontSizes.textSize}"></i>
                                </span>
                            </button>
                        </div>

                        <!-- signature -->
                        <div ng-if="type === 'signature'" class="im-no-pad" ng-class="model.inputClasses" when-ready="whenReady()">
                            <input ng-if="model.field === undefined || model.field === ''" ng-class="addClass()" tabindex="-1" type="text" id="{{model.mId}}" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-click="goSign()" ng-readonly="typevar.indexOf('r') > -1" when-ready="whenReady()" />
                            <div ng-if="model.field !== undefined && model.field !== ''" class="col-xs-12 im-no-pad" ng-click="goSign()" style="cursor: pointer">
                                <img ng-src="{{model.field}}" style="max-height: 92px" when-ready="whenReady()" />
                            </div>
                        </div>

                        <!-- html -->
                        <div ng-if="type === 'html'" ng-class="model.inputClasses" class="im-no-pad" when-ready="whenReady()" wait-for-interpolation="true">
                            <p ng-class="addClass('html')" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="model.inputStyle" ng-readonly="typevar.indexOf('r') > -1"></p>
                        </div>



                        <!-- datetime -->
                        <div ng-if="type === 'datetime'" style="display: flex;" ng-class="model.inputClasses" when-ready="whenReady()">
                            <input ng-if="typevar.indexOf('d') > -1" ng-class="addClass() + dateClass('date')" type="text" uib-datepicker-popup ng-model="model.dtd" ng-model-options="{timezone: 'utc'}" ng-style="model.inputStyle" is-open="model.opened" datepicker-options="dateOptions" datepicker-append-to-body="true" ng-required="true" ng-click="open($event)" ng-change="goChange()" ng-disabled="typevar.indexOf('dr') > -1 || typevar.indexOf('rr') > -1" />
                            <select ng-if="typevar.indexOf('h') > -1" ng-class="addClass('select') + dateClass('hour')" type="text" ng-options="item.hour as item.hour for item in model.hours" ng-model="model.dth" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-change="goChange()" ng-disabled="typevar.indexOf('hr') > -1 || typevar.indexOf('rr') > -1"></select>
                            <select ng-if="typevar.indexOf('m') > -1" ng-class="addClass('select') + dateClass('minute')" type="text" ng-options="item.mins as item.mins for item in model.mins" ng-model="model.dtm" ng-style="model.inputStyle" placeholder="{{model.placeholder}}" ng-change="goChange()" ng-disabled="typevar.indexOf('mr') > -1 || typevar.indexOf('rr') > -1"></select>
                            <button ng-if="typevar.indexOf('g') > -1 && typevar.indexOf('b') <= -1 && typevar.indexOf('o') <= -1" class="tt-input__button tt-input__button--primary" type="button" style=" top: 0;" ng-style="model.glyphStyle" ng-click="goTo()">
                                <span class="fa-fw" ng-class="addGlyph()"></span>
                            </button>
                            <button type="button" ng-if="typevar.indexOf('b') > -1 && typevar.indexOf('o') > -1" ng-class="addBtnStyle($index)" ng-repeat="line in model.lines" ng-click="goTo($index)" when-ready="whenReady()">
                                <span ng-if="typevar.indexOf('g') > -1" class="fa-fw" ng-class="addGlyph($index)" ng-style="{'fontSize': fontSizes.textSize}" when-ready="whenReady()"></span>
                            </button>
                        </div>

                        <!-- input -->
                        <div ng-if="type === 'input'" ng-class="model.inputClasses" class="im-no-pad" when-ready="whenReady()">
                            <div ng-class="addClass('inputStart')" class="col-xs-12 im-no-pad">
                                <div im-typevar></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`,
            link: function ($scope, $element, $attr) {
                // For this to work you must set either ttItemId consisting of 'groupid.fieldid', or ttGroupId on scope.
                // Group id is the id for the group of items that will be activated by the ttItemManager.
                // If you only set ttGroupId and not the full ttItemId there must also be a value for either ttFieldId or dataid, field.
                // If field is the one set then the value after the last "." is used.
                ttItemService.register($scope, $element, $attr);

                $scope.fontSizes = {
                    textSize: '',
                    textSizeS: '',
                    textSizeSs: ''
                };

                $scope.model = {
                    mId: uuid(),
                    txtAreaId: uuid(),
                    focusKeeper: '',
                    initialFocus: true,
                    field: '',
                    label: '',
                    height: '0',
                    type: 'text',
                    placeholder: '',
                    searchText: null,
                    selectedText: null,
                    lines: [],
                    icons: '',
                    dtd: new Date(),    //Datetime date
                    dth: '00',          //Datetime hour
                    dtm: '00',          //Datetime minute
                    hours: [{ hour: '00' }, { hour: '01' }, { hour: '02' }, { hour: '03' }, { hour: '04' }, { hour: '05' }, { hour: '06' }, { hour: '07' }, { hour: '08' }, { hour: '09' }, { hour: '10' }, { hour: '11' },
                    { hour: '12' }, { hour: '13' }, { hour: '14' }, { hour: '15' }, { hour: '16' }, { hour: '17' }, { hour: '18' }, { hour: '19' }, { hour: '20' }, { hour: '21' }, { hour: '22' }, { hour: '23' }],
                    mins: [],
                    dtvar: { minuteInterval: 1 },
                    opened: false,
                    imOptions: {},
                    trueValue: "'1'",   // BJS 20180525
                    falseValue: "'0'",  // BJS 20180525
                    labelAlwaysOnTop: false,
                    root: document.querySelector(':root'),
                    groupStyle: {},
                    inputStyle: {},
                    glyphStyle: {},
                    selectStyle: {},
                    checkboxStyle: {},
                    labelClasses: '',
                    subLabelClasses: '',
                    inputClasses: '',
                    baseClasses: ''
                };

                $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };

                var setValid = function () {
                    if (angular.isUndefined($scope.ttStyle)) return;

                    if (angular.isDefined($scope.ttStyle.borderColor)) delete $scope.ttStyle.borderColor;
                    if (angular.isDefined($scope.model.inputStyle.borderColor)) delete $scope.model.inputStyle.borderColor;
                    if (angular.isDefined($scope.model.selectStyle.borderColor)) delete $scope.model.selectStyle.borderColor;
                    if (angular.isDefined($scope.model.checkboxStyle.border)) delete $scope.model.checkboxStyle.border;
                    if (angular.isDefined($scope.model.checkboxStyle.borderRadius)) delete $scope.model.checkboxStyle.borderRadius;
                    if (angular.isDefined($scope.model.checkboxStyle.padding)) delete $scope.model.checkboxStyle.padding;
                };

                var setInvalid = function () {
                    if (angular.isObject($scope.ttStyle)) {
                        $scope.ttStyle.borderColor = 'red';
                    } else {
                        $scope.ttStyle = {
                            borderColor: 'red'
                        };
                    }

                    $scope.model.inputStyle.borderColor = 'red';
                    $scope.model.selectStyle.borderColor = 'red';
                    $scope.model.checkboxStyle.border = '1px solid red';
                    $scope.model.checkboxStyle.borderRadius = '6px';
                    $scope.model.checkboxStyle.padding = '5px';
                };

                // BJS 20220928
                if (angular.isDefined($scope.ttStyle)) {
                    angular.extend($scope.model.selectStyle, $scope.ttStyle);
                    angular.extend($scope.model.checkboxStyle, $scope.ttStyle);
                    angular.extend($scope.model.checkboxStyleS, $scope.ttStyle);
                }

                // BJS 20220928
                if (angular.isDefined($scope.ttInvalid) && ($scope.ttInvalid === true || $scope.ttInvalid === 'true')) {
                    setInvalid();
                }

                var useCustomBoolean = false;

                // BJS 20180525
                if (($scope.type === 'checkbox' || $scope.type === 'checkbox_s') && angular.isDefined($scope.ieTrueValue) && angular.isDefined($scope.ieFalseValue)) {
                    $scope.model.trueValue = $scope.ieTrueValue;
                    $scope.model.falseValue = $scope.ieFalseValue;

                    useCustomBoolean = true;
                }

                $scope.whenReady = function () {
                    eventService.trigger('element:ready');
                };

                $scope.open = function ($event) {
                    $scope.model.opened = true;
                };

                var scopeModelFieldWatch = false;
                var scopeFieldWatch = false;
                var hide = false;
                var timer = null;

                $scope.dataid = $scope.dataid || $scope.dataname;
                $scope.dataname = $scope.dataname || $scope.dataid;

                $scope.goTo = function ($index) {
                    if (angular.isDefined($index)) {
                        if (angular.isDefined($scope.model.lines) && $scope.model.lines.length > 0 && angular.isDefined($scope.model.lines[$index].item_func)) {
                            $scope.optionfunc({ item_func: $scope.model.lines[$index].item_func });
                        } else {
                            $scope.optionfunc({ item_func: $index });
                        }
                    } else {
                        $scope.optionfunc();
                    }

                    if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('z') > -1)
                        $scope.clearInput();
                };

                $scope.goUp = function () {
                    if (isNumber($scope.model.field)) {
                        $scope.model.field = (parseInt($scope.model.field) + 1).toString();
                    }
                };

                $scope.goDown = function () {
                    if (isNumber($scope.model.field)) {
                        $scope.model.field = (parseInt($scope.model.field) - 1).toString();
                    }
                };

                $scope.goBarcode = function () {
                    var wait = function () {
                        var val = $element.find('#' + $scope.model.mId)[0].value;

                        if (angular.isString(val) && val !== null && val.length > 0) {
                            $scope.model.field = val;
                            if (angular.isFunction($scope.optionfunc) === true) {
                                $timeout(function () {
                                    $scope.goTo();
                                }, 100);
                            } else {
                                $timeout(function () {
                                    $scope.goChange();
                                }, 100);
                            }
                            return;
                        }

                        $timeout(wait, 250);
                    };

                    wait();

                    window.location = "readbarcode://" + $scope.model.mId;
                };

                $scope.goCalc = function () {
                    modalService.showCalculator($scope.label, $scope.plabel, $scope.model.field).then(function (value) {
                        $scope.model.field = value;
                        if (angular.isFunction($scope.optionfunc) === true) {
                            $timeout(function () {
                                $scope.goTo();
                            }, 100);
                        } else {
                            $timeout(function () {
                                $scope.goChange();
                            }, 100);
                        }
                    });
                };

                $scope.goSign = function () {
                    if (angular.isDefined($element.find('#' + $scope.model.mId)[0])) {
                        $element.find('#' + $scope.model.mId)[0].blur();
                    }

                    modalService.showSignaturePad($scope.model.field).then(function (value) {
                        $scope.model.field = value;
                    });
                };

                $scope.goChange = function () {
                    if (angular.isUndefined($scope.changefunc)) return;

                    $scope.changefunc();
                };

                $scope.getItems = function (value) {
                    if (angular.isFunction($scope.optionfunc) !== true) return;

                    return $scope.optionfunc({ value: value });
                };

                var elasticAdjusted = false;

                $scope.textAreaAdjust = function () {
                    var allStyle = {};

                    if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('dd') > -1) {
                        var idElement = $element.find('#' + $scope.model.mId)[0];

                        if (angular.isDefined(idElement)) {
                            var height = idElement.previousElementSibling.contentDocument.body.scrollHeight;

                            if ($scope.model.height !== height) {
                                if (angular.isDefined(timer)) {
                                    $timeout.cancel(timer);
                                }

                                timer = $timeout(function () {
                                    var table = idElement.closest('table');
                                    var body = idElement.parentElement.firstChild.contentDocument.body;

                                    allStyle['height'] = height + 'px';

                                    var h = parseInt(height);

                                    $(table).css('height', h + 'px');
                                    $(body).css('overflow', 'hidden');
                                }, 1);

                                $scope.model.height = height;
                            }
                        }
                    } else if (angular.isDefined($scope.typevar) && elasticAdjusted === false) {
                        $timeout(function () {
                            $rootScope.$broadcast('elastic:adjust');
                        }, 1000);
                        elasticAdjusted = !elasticAdjusted;
                    }

                    var fontSize = $scope.fontSizes.textSize;

                    allStyle['font-size'] = fontSize;

                    var size = parseInt(fontSize.substr(0, fontSize.length - 2));

                    var padding1 = size - 10;
                    var padding2 = padding1 + 6;

                    padding1 = padding1 > 5 ? 5 : padding1;
                    padding2 = padding2 > 16 ? 16 : padding2;

                    allStyle['padding'] = padding1 + 'px ' + padding2 + 'px';

                    // BJS 20220928
                    if (angular.isDefined($scope.ttStyle)) {
                        angular.extend(allStyle, $scope.ttStyle);
                    }

                    return allStyle;
                };

                $scope.kTools = function () {
                    var allTools = []; //initialize

                    if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('d') > -1) {
                        if ($scope.typevar.indexOf('d0') > -1) {        // no tools
                            allTools = [];
                        } else if ($scope.typevar.indexOf('d2') > -1) { // limited tools
                            allTools = ['bold', 'italic'];
                        } else {                                        // default
                            allTools = ['bold', 'italic', 'underline', 'strikethrough', 'insertUnorderedList', 'insertOrderedList', 'indent', 'outdent', 'fontSize', { name: 'foreColor', palette: 'basic' }, { name: 'backColor', palette: 'basic' }, 'createLink', 'unlink', 'insertImage', 'viewHtml'];
                        }
                    }

                    return allTools;
                };

                $scope.addClass = function (condition) {
                    var allClass = '';

                    if (condition === 'select') {
                        allClass = 'tt-input__form-control tt-input__form-control--select ';
                    } else if (condition === 'inputStart') {
                        if ($scope.typevar.indexOf('c') > -1) allClass = 'right-inner-addon';
                    } else if (condition === 'checkbox') {
                        allClass = 'tt-input__form-control tt-input__form-control--checkbox ';
                    } else if (condition === 'html') {
                        allClass = '';
                    } else if (condition === 'bubble') {
                        allClass = 'tt-input__message';

                        if (angular.isDefined($scope.myuser) && $scope.myuser === '1') {
                            allClass += ' tt-input__message--current-user';
                        }
                    } else {
                        allClass = 'tt-input__form-control ';

                        if (($scope.typevar.indexOf('n') > -1 && $scope.typevar.indexOf('u') <= -1) || $scope.typevar.indexOf('h') > -1) {
                            allClass += ' input-text-right ';
                        } else if ($scope.typevar.indexOf('u') > -1) {
                            allClass += ' input-text-center ';
                        } else {
                            allClass += '  input-text ';
                        }
                    }

                    return allClass;
                };

                $scope.typeClass = function () {
                    var allClass = '';

                    if ($scope.type === 'textarea') {
                        //if (angular.isUndefined($scope.label) && angular.isUndefined($scope.plabel)) {
                        //    allClass = 'col-xs-12 col-sm-12 sp-0';
                        //} else {
                        //    allClass = $scope.model.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-10 col-xl-10' + ($element.closest('tt-packery-item').length > 0 ? 'sp-0' : ' col-xxl-11 sp-0');
                        //}
                    } else if ($scope.type === '') {
                        // no class
                    } else {
                        allClass = 'col-xs-12 col-sm-10 sp-0';
                    }

                    return allClass;
                };

                $scope.addBtnStyle = function (index) {
                    //var allClass = 'input-group-addon btn btn-lg'; //btn-success
                    let allClass = 'tt-input__button tt-input__button--success';

                    if (angular.isDefined(index) && angular.isDefined($scope.typevar) && $scope.typevar.indexOf('o')) {
                        if (angular.isDefined($scope.model.lines) && $scope.model.lines.length > 0 && angular.isDefined($scope.model.lines[index].color)) {
                            //console.dir($scope.model.lines[index].color);
                            if ($scope.model.lines[index].color === 'default') {
                                allClass = 'input-group-addon btn btn-default btn-lg';
                            }
                            if ($scope.model.lines[index].color === 'primary') {
                                allClass = 'tt-input__button tt-input__button--primary';
                                //input - group - addon btn btn - primary btn - lg';
                            }
                            if ($scope.model.lines[index].color === 'success') {
                                allClass = 'tt-input__button tt-input__button--success';
                                //allClass = 'input-group-addon btn btn-success btn-lg';
                            }
                            if ($scope.model.lines[index].color === 'info') {
                                allClass = 'tt-input__button tt-input__button--info';
                                //allClass = 'input-group-addon btn btn-info btn-lg';
                            }
                            if ($scope.model.lines[index].color === 'warning') {
                                allClass = 'tt-input__button tt-input__button--warning';
                                //allClass = 'input-group-addon btn btn-warning btn-lg';
                            }
                            if ($scope.model.lines[index].color === 'danger') {
                                allClass = 'tt-input__button tt-input__button--danger';
                                //allClass = 'input-group-addon btn btn-danger btn-lg';
                            }
                        }
                    }

                    return allClass;
                };

                $scope.addGlyph = function (specific) {
                    var allClass = 'fas fa-chevron-right'; //default

                    if (angular.isDefined($scope.glyphs) && $scope.glyphs !== null) {
                        allClass = 'glyphicon ' + $scope.glyphs;
                    }

                    if (angular.isDefined(specific) && specific === 'down') {
                        allClass = 'glyphicon ' + 'glyphicon-minus';
                    }

                    if (angular.isDefined(specific) && specific === 'up') {
                        allClass = 'glyphicon ' + 'glyphicon-plus';
                    }

                    if (angular.isDefined(specific) && specific === 'remove') {
                        allClass = 'glyphicon glyphicon-erase';
                    }

                    if (angular.isDefined(specific) && specific === 'calc') {
                        allClass = 'fa fa-calculator';
                    }

                    if (angular.isDefined(specific) && specific === 'barcode') {
                        allClass = 'glyphicon glyphicon-barcode';
                    }

                    if (angular.isDefined(specific) && angular.isDefined($scope.typevar) && $scope.typevar.indexOf('o')) {
                        if (angular.isDefined($scope.model.lines) && $scope.model.lines.length > 0) {
                            var prefix = 'glyphicon ';

                            if ($scope.model.lines[specific].glyph.startsWith('fa-')) {
                                prefix = 'fa ';
                            } else if ($scope.model.lines[specific].glyph.startsWith('fab-')) {
                                prefix = 'fab ';
                            } else if ($scope.model.lines[specific].glyph.startsWith('fad-')) {
                                prefix = 'fad ';
                            } else if ($scope.model.lines[specific].glyph.startsWith('fal-')) {
                                prefix = 'fal ';
                            } else if ($scope.model.lines[specific].glyph.startsWith('far-')) {
                                prefix = 'far ';
                            } else if ($scope.model.lines[specific].glyph.startsWith('fas-')) {
                                prefix = 'fas ';
                            }

                            if (angular.isDefined($scope.spin) && $scope.model.lines[specific].glyph.indexOf('glyphicon-search') > -1) {
                                if ($scope.spin === true) {
                                    allClass = 'fa fa-spinner fa-spin';
                                } else {
                                    allClass = prefix + $scope.model.lines[specific].glyph;
                                }
                            } else {
                                allClass = prefix + $scope.model.lines[specific].glyph;
                            }
                        }
                    }

                    if (angular.isDefined(specific) && specific === 'icons' && angular.isDefined($scope.icons)) {
                        if ($scope.icons.length > 0) {
                            var glyphStr = $scope.icons;

                            if ($scope.icons.startsWith('fa-')) {
                                allClass = 'fa ' + glyphStr;
                            } else if ($scope.icons.startsWith('fab-')) {
                                allClass = 'fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if ($scope.icons.startsWith('fad-')) {
                                allClass = 'fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if ($scope.icons.startsWith('fal-')) {
                                allClass = 'fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if ($scope.icons.startsWith('far-')) {
                                allClass = 'far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if ($scope.icons.startsWith('fas-')) {
                                allClass = 'fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else {
                                allClass = 'glyphicon ' + glyphStr;
                            }
                        }
                    }

                    return allClass;
                };

                $scope.dateClass = function (condition) {
                    var allClass = '';

                    if ($scope.typevar.indexOf('d') > -1 && $scope.typevar.indexOf('h') > -1 && $scope.typevar.indexOf('m') > -1) {
                        if (condition === 'date') {
                            allClass = ' col-xs-6';
                        } else if (condition === 'hour') {
                            allClass = ' col-xs-3';
                        } else if (condition === 'mins') {
                            allClass = ' col-xs-3';
                        }
                    } else if ($scope.typevar.indexOf('d') > -1 && (($scope.typevar.indexOf('h') > -1 || $scope.typevar.indexOf('m') > -1))) {
                        if (condition === 'date') {
                            allClass = ' col-xs-8';
                        } else if (condition === 'hour') {
                            allClass = ' col-xs-4';
                        } else if (condition === 'mins') {
                            allClass = 'col-xs-4';
                        }
                    } else if (!($scope.typevar.indexOf('d') > -1) && ($scope.typevar.indexOf('h') > -1 && $scope.typevar.indexOf('m') > -1)) {
                        if (condition === 'date') {
                            allClass = ' col-xs-12';
                        } else if (condition === 'hour') {
                            allClass = ' col-xs-6';
                        } else if (condition === 'mins') {
                            allClass = ' col-xs-6';
                        }
                    } else {
                        allClass = 'col-xs-12';
                    }

                    allClass += ' im-no-pad';

                    return allClass;
                };

                var modelEdit = false;

                $scope.keepFocus = function (toggle) {
                    if (angular.isUndefined($scope.typevar)) return;

                    if ($scope.type === 'search') {
                        if ($scope.typevar.indexOf('a') > -1 && $scope.typevar.indexOf('aa') > -1) {
                            $element.find('#' + $scope.model.mId)[0].focus();
                        } else if ($scope.typevar.indexOf('a') > -1 && $scope.model.initialFocus === true) {
                            $element.find('#' + $scope.model.mId)[0].focus();
                            $scope.model.initialFocus = false;
                        }

                        return;
                    }

                    modelEdit = true;

                    if ($scope.typevar.indexOf('n') > -1 && !toggle) {
                        modelEdit = false;

                        var typeVarValue = Number($scope.typevar[$scope.typevar.indexOf('n') + 1]);

                        if ($scope.typevar.indexOf('+') > -1) {
                            $scope.model.field = $scope.model.field.replace(/[^0-9.,]/g, '');
                        } else {
                            $scope.model.field = $scope.model.field.replace(/[^0-9.,-]/g, '');
                            $scope.model.field = $scope.model.field.replace(/(?!^)-/g, '');
                        }

                        if (angular.isDefined($scope.model.field) && ($scope.model.field.indexOf('.') > -1 || $scope.model.field.indexOf(',') > -1)) {

                            $scope.model.field = $scope.model.field.replace(/,/g, '.');

                            $scope.model.field = $scope.model.field.replace(/^([^.]*\.)(.*)$/, function (a, b, c) {
                                return b + c.replace(/\./g, '');
                            });

                            var separatorPoint = Math.max($scope.model.field.indexOf('.'), $scope.model.field.indexOf(',')) + 1;
                            var newLength = separatorPoint + typeVarValue;

                            if ($scope.model.field.length > newLength) {
                                $scope.model.field = $scope.model.field.substr(0, newLength);
                            } else if ($scope.model.field.length < newLength) {
                                //$scope.model.field = $scope.model.field;

                                for (var i = $scope.model.field.length; i < newLength; i++) {
                                    $scope.model.field += '0';
                                }
                            } else {
                                //$scope.model.field = $scope.model.field;
                            }

                            if ($scope.model.field.length === separatorPoint) {
                                $scope.model.field = $scope.model.field.substr(0, (separatorPoint - 1));
                            }
                        }
                        else {
                            if (typeVarValue === 0) {
                                if ($scope.model.field === '') {
                                    $scope.model.field += '0';
                                }

                                //$scope.model.field = $scope.model.field;
                            }
                            else {
                                if ($scope.model.field === '') {
                                    $scope.model.field += '0';
                                }

                                $scope.model.field += '.';

                                for (var j = 0; j < typeVarValue; j++) {
                                    $scope.model.field += '0';
                                }
                            }
                        }

                        var excessZero = true;

                        do {
                            if ($scope.model.field[0] === '0') {
                                if ($scope.model.field[1] === '.' || $scope.model.field[1] === ',') {
                                    excessZero = false;
                                } else {
                                    $scope.model.field = $scope.model.field.substr(1, ($scope.model.field.length - 1));
                                }
                            } else {
                                excessZero = false;
                            }
                        } while (excessZero === true);
                    }

                    //if ($scope.typevar.indexOf('b') > -1 && $scope.typevar.indexOf('o') > -1 && $scope.typevar.indexOf('g') > -1) {
                    if ($scope.typevar.indexOf('b') > -1 && $scope.typevar.indexOf('o') > -1 && $scope.typevar.indexOf('g') > -1 && $scope.typevar.indexOf('n') <= -1) {
                        modelEdit = false;
                    }

                    if (!($scope.typevar.indexOf('c') > -1)) return;

                    if (toggle === false) {
                        hide = true;

                        $timeout(function () {
                            if (hide === true)
                                $scope.model.focusKeeper = false;
                        }, 200);
                    } else {
                        if ($scope.model.focusKeeper !== true)
                            $scope.model.focusKeeper = true;
                    }
                };

                $scope.selectText = function () {
                    if (angular.isUndefined($scope.typevar)) return;
                    if (!($scope.typevar.indexOf('s') > -1)) return;

                    $element.find('#' + $scope.model.mId)[0].focus();

                    $timeout(function () {
                        $element.find('#' + $scope.model.mId)[0].setSelectionRange(0, $element.find('#' + $scope.model.mId)[0].value.length);
                    }, 10);
                };

                $scope.clearInput = function () {
                    hide = false;

                    $scope.model.field = '';

                    $element.find('#' + $scope.model.mId)[0].focus();
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (info) {

                        angular.copy(info.fontSizes, $scope.fontSizes);

                        $scope.model.groupStyle.marginBottom = info.margin.bottom + 'px';
                        $scope.model.inputStyle.fontSize = info.fontSizes.textSize;
                        $scope.model.inputStyle.height = info.height + 'px';
                        $scope.model.inputStyle.paddingTop = info.padding.top + 'px';
                        $scope.model.inputStyle.paddingBottom = info.padding.bottom + 'px';
                        $scope.model.inputStyle.paddingLeft = info.padding.left + 'px';
                        $scope.model.inputStyle.paddingRight = info.padding.right + 'px';

                        $scope.model.selectStyle.fontSize = info.fontSizes.textSize;
                        $scope.model.selectStyle.height = info.height + 'px';
                        $scope.model.selectStyle.paddingTop = info.padding.top + 'px';
                        $scope.model.selectStyle.paddingBottom = info.padding.bottom + 'px';
                        $scope.model.selectStyle.paddingRight = info.padding.right + 'px';
                        $scope.model.selectStyle.paddingLeft = (info.padding.left - 3) + 'px';

                        $scope.model.baseClasses = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: info.labelAlwaysOnTop });

                        $scope.model.glyphStyle.fontSize = info.fontSizes.textSize;

                        //var root = document.querySelector(':root');
                        if (angular.isFunction($scope.model.root.style.setProperty)) $scope.model.root.style.setProperty('--im-uib-typeahead-fontsize', info.fontSizes.textSize);

                    }
                    //$scope.model.labelClasses = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2 col-xl-2' + ($element.closest('tt-packery-item').length > 0 ? ' sp-0' : ' col-xxl-1 sp-0');
                    //$scope.model.subLabelClasses = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-10 col-xl-10' + ($element.closest('tt-packery-item').length > 0 ? ' sp-0' : ' col-xxl-11 sp-0');
                    //$scope.model.inputClasses = info.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-10 col-xl-10' + ($element.closest('tt-packery-item').length > 0 ? ' sp-0' : ' col-xxl-11 sp-0');
                });

                function escapeRegExp(str) {
                    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                }

                function replaceAll(str, find, replace) {
                    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
                }

                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                function insertSpaces(number) {
                    return number.replace(" ", "").replace(/(\d)(?=(?:\d{3})+$)/g, "$1 ");
                }

                $scope.$watch(function () {
                    return $scope.ttInvalid;
                }, function (newVal, oldVal) {
                    if ($scope.ttInvalid === true || $scope.ttInvalid === 'true') {
                        setInvalid();
                    } else {
                        setValid();
                    }
                });

                $scope.$watch(function () {
                    return $scope.ptype;
                }, function () {
                    if (angular.isUndefined($scope.type) && angular.isDefined($scope.ptype)) {
                        $scope.type = $scope.ptype.toString();
                    }
                });

                $scope.$watch(function () {
                    return $scope.ptypevar;
                }, function () {
                    if (angular.isUndefined($scope.typevar) && angular.isDefined($scope.ptypevar))
                        $scope.typevar = $scope.ptypevar.toString();
                });

                $scope.$watch(function () { return $scope.dtvar; }, function () {
                    if (angular.isUndefined($scope.dtvar) || $scope.dtvar === null || angular.isUndefined($scope.dtvar.minuteInterval) || isNumber($scope.dtvar.minuteInterval) === false || parseInt($scope.dtvar.minuteInterval) < 1) {
                        $scope.model.dtvar.minuteInterval = 1;    //Default value
                        //return;
                    } else {
                        $scope.model.dtvar.minuteInterval = parseInt($scope.dtvar.minuteInterval);
                    }

                    $scope.model.mins.length = 0;
                    var dtmIncluded = false;

                    for (var i = 0; i < 60; i += $scope.model.dtvar.minuteInterval) {
                        var t = i.toString();

                        if (t.length < 2) {
                            t = '0' + t;
                        }

                        if (t === $scope.model.dtm) {
                            dtmIncluded = true;
                        }

                        $scope.model.mins.push({ mins: t });
                    }

                    if (!dtmIncluded) {
                        $scope.model.mins.push({ mins: $scope.model.dtm });
                    }

                }, true);

                $scope.$watch(function () {
                    return $scope.pbtnvar;
                }, function () {
                    if (angular.isUndefined($scope.btnvar) && angular.isDefined($scope.pbtnevar) && $scope.typevar.indexOf('o'))
                        $scope.btnvar = $scope.pbtnvar.toString();
                });

                $scope.$watch(function () {
                    return $scope.field;
                }, function () {
                    if (scopeModelFieldWatch === true || modelEdit === true) return;

                    scopeFieldWatch = true;

                    if (angular.isDefined($scope.typevar) && angular.isDefined($scope.field) && $scope.field !== null && $scope.field.toString().length > 0) {
                        if ($scope.typevar.indexOf('n') > -1) {
                            var typeVarValue = Number($scope.typevar[$scope.typevar.indexOf('n') + 1]);

                            if ($scope.field.toString().indexOf('.') > -1 || $scope.field.toString().indexOf(',') > -1) {
                                var separatorPoint = Math.max($scope.field.toString().indexOf('.'), $scope.field.toString().indexOf(',')) + 1;
                                if (separatorPoint === 1) {
                                    $scope.field = "0" + $scope.field.toString();
                                    separatorPoint += 1;
                                }
                                var newLength = separatorPoint + typeVarValue;

                                if ($scope.field.toString().length > newLength) {
                                    $scope.model.field = $scope.field.toString().substr(0, newLength);
                                } else if ($scope.field.toString().length < newLength) {
                                    $scope.model.field = $scope.field.toString();
                                    for (var i = $scope.field.toString().length; i < newLength; i++) {
                                        $scope.model.field += '0';
                                    }
                                } else {
                                    $scope.model.field = $scope.field.toString();
                                }

                                if ($scope.model.field.length === separatorPoint) {
                                    $scope.model.field = $scope.field.toString().substr(0, (separatorPoint - 1));
                                }
                            } else {
                                if (typeVarValue === 0) {
                                    $scope.model.field = $scope.field.toString();
                                } else {
                                    $scope.model.field = $scope.field.toString();
                                    if (!($scope.model.field.indexOf('.') > -1 || $scope.model.field.indexOf(',') > -1)) {
                                        $scope.model.field += '.';
                                        for (var j = 0; j < typeVarValue; j++) {
                                            $scope.model.field += '0';
                                        }
                                    }
                                }
                            }
                        } else if (angular.isDefined($scope.type) && $scope.type === 'textarea' && $scope.typevar.indexOf('d') > -1 && $scope.field !== null) {
                            $scope.model.field = $scope.field.toString();
                        } else if (angular.isDefined($scope.type) && $scope.type === 'datetime' && $scope.field !== null) {
                            $scope.model.field = $scope.field.toString();

                            // BJS 20210708
                            var hasTime = $scope.model.field.length > 10;

                            var date = new Date($scope.model.field);
                            var day = new Date($scope.model.field.substr(0, 10));
                            //var hours = date.getHours().toString();
                            //var minutes = date.getMinutes().toString();

                            //hours = hasTime ? hours.length === 1 ? '0' + hours : hours : '00';
                            //minutes = hasTime ? minutes.length === 1 ? '0' + minutes : minutes : '00';

                            $scope.model.dtd = day;
                            //$scope.model.dth = hours;
                            //$scope.model.dtm = minutes;
                            $scope.model.dth = $scope.model.field.substr(11, 2);
                            $scope.model.dtm = $scope.model.field.substr(14, 2);

                            if ($scope.model.mins.length > 1) {
                                var dtmIncluded = false;

                                for (var m = 0; m < $scope.model.mins.length; m++) {
                                    if ($scope.model.mins[m].mins === $scope.model.dtm) {
                                        dtmIncluded = true;
                                        break;
                                    }
                                }

                                if (!dtmIncluded) {
                                    $scope.model.mins.push({ mins: $scope.model.dtm });
                                }
                            }
                        } else {
                            $scope.model.field = $scope.field.toString();
                        }

                    } else if (useCustomBoolean === true) {
                        // BJS 20180526
                        if ($scope.model.trueValue === true && ($scope.field === 'true' || $scope.field === true)) {
                            $scope.model.field = true;
                        }

                        if ($scope.model.falseValue === false && ($scope.field === 'false' || $scope.field === false)) {
                            $scope.model.field = false;
                        }
                    } else if (angular.isDefined($scope.field) && ($scope.field !== null) && ($scope.type !== 'select')) {
                        $scope.model.field = $scope.field.toString();
                    } else {
                        $scope.model.field = $scope.field;
                    }

                    scopeFieldWatch = false;
                });

                $scope.$watch(function () {
                    return $scope.label;
                }, function () {
                    $scope.model.label = $scope.label;

                    if (angular.isUndefined($scope.placeholder)) {
                        $scope.model.placeholder = $scope.label;
                    }
                });

                $scope.$watch(function () {
                    return $scope.placeholder;
                }, function () {
                    $scope.model.placeholder = $scope.placeholder;
                });

                var testrun = false;

                $scope.$watch(function () {
                    return $scope.model.field;
                }, function (newValue) {
                    if (scopeFieldWatch === true && modelEdit === true) return;

                    scopeModelFieldWatch = true;

                    // BJS 20180526
                    if (useCustomBoolean === true) {
                        if ($scope.model.trueValue === true &&
                            ($scope.model.field === 'true' || $scope.model.field === true)) {
                            $scope.field = true;
                        }

                        if ($scope.model.falseValue === false &&
                            ($scope.model.field === 'false' || $scope.model.field === false)) {
                            $scope.field = false;
                        }
                    } else {
                        $scope.field = newValue;
                    }

                    if (testrun === false) {
                        if ($scope.type === 'html') {// || $scope.type === 'textarea') {
                            try {
                                var elmnt = angular.element($scope.field);

                                var test = $compile(elmnt)($scope);

                                $element.append(test);
                            } catch (err) {
                                console.log('html error: ' + err);
                            }
                        }

                        testrun = true;
                    }

                    scopeModelFieldWatch = false;
                });

                $scope.$watch(function () {
                    return $scope.btnList;
                }, function () {
                    if (angular.isDefined($scope.typevar) && angular.isDefined($scope.btnList) && $scope.typevar.indexOf('o')) {
                        $scope.model.lines.length = 0;

                        angular.forEach($scope.btnList, function (data) {
                            if (angular.isDefined(data)) {
                                $scope.model.lines.push(data);
                            }
                        });
                    }
                });

                // BJS 20210708
                var canBuildDateTime = function () {
                    if (angular.isUndefined($scope.type))
                        return false;
                    if ($scope.type !== 'datetime')
                        return false;
                    if (angular.isUndefined($scope.field))
                        return false;
                    if ($scope.field === null)
                        return false;

                    return true;
                };

                var canAddTime = function () {
                    if (angular.isString($scope.model.dth) !== true)
                        return false;
                    if ($scope.model.dth.trim().length < 1)
                        return false;
                    if (angular.isString($scope.model.dtm) !== true)
                        return false;
                    if ($scope.model.dtm.trim().length < 1)
                        return false;

                    return true;
                };

                var buildDateTime = function () {
                    if (canBuildDateTime() !== true)
                        return;

                    var dt = $scope.model.dtd.toISOString().substring(0, 10);

                    $scope.model.field = canAddTime()
                        ? dt + ' ' + $scope.model.dth + ':' + $scope.model.dtm
                        : dt;
                };

                $scope.$watch(function () {
                    return $scope.model.dtd;
                }, buildDateTime);

                $scope.$watch(function () {
                    return $scope.model.dth;
                }, buildDateTime);

                $scope.$watch(function () {
                    return $scope.model.dtm;
                }, buildDateTime);

                $scope.model.imOptions.typevar = $scope.typevar;
                $scope.model.imOptions.mId = $scope.model.mId;
                $scope.model.imOptions.model = $scope.model.field;
                $scope.model.imOptions.placeholder = $scope.model.placeholder;
                $scope.model.imOptions.lines = $scope.model.lines;
                $scope.model.imOptions.icons = $scope.model.icons;
                $scope.model.imOptions.glyphs = $scope.glyphs;
                $scope.model.imOptions.focusKeeper = $scope.model.focusKeeper;
                $scope.model.imOptions.goUp = $scope.goUp;
                $scope.model.imOptions.goDown = $scope.goDown;
                $scope.model.imOptions.goTo = $scope.goTo;
                $scope.model.imOptions.keepFocus = $scope.keepFocus;
                $scope.model.imOptions.clearInput = $scope.clearInput;
                $scope.model.imOptions.onChange = $scope.goChange;
            }
        };

        return directive;
    }])

        .directive('imTypevar', ['$rootScope', '$compile', 'layoutService', function ($rootScope, $compile, layoutService) {
            var directive = {
                restrict: 'A',
                link: function ($scope, $element) {
                    // BJS 20210522 - Added resize of input height when changing fontSize

                    var bIndex = $scope.typevar.indexOf('b');
                    var cIndex = $scope.typevar.indexOf('c');
                    var eIndex = $scope.typevar.indexOf('e');
                    var gIndex = $scope.typevar.indexOf('g');
                    var hIndex = $scope.typevar.indexOf('h');
                    var kIndex = $scope.typevar.indexOf('k');
                    var nIndex = $scope.typevar.indexOf('n');
                    var oIndex = $scope.typevar.indexOf('o');
                    var pIndex = $scope.typevar.indexOf('p');
                    var rIndex = $scope.typevar.indexOf('r');
                    var tIndex = $scope.typevar.indexOf('t');
                    var uIndex = $scope.typevar.indexOf('u');

                    $scope.varModel = {
                        fontSize: '',
                        inputStyle: {},
                        iconStyle: {},
                        root: document.querySelector(':root')
                    };

                    // BJS 20220928
                    if (angular.isDefined($scope.ttStyle)) {
                        angular.extend($scope.varModel.inputStyle, $scope.ttStyle);
                    }

                    //var root = document.querySelector(':root');
                    ////var rs = getComputedStyle(r);
                    ////r.style.setProperty('--im-k-alt-bg-color', 'lightblue');
                    //if (angular.isUndefined($scope.setup.css) || angular.isUndefined($scope.setup.css.altColor) || $scope.setup.css.altColor === true) {
                    //    //e.sender.element.style.setProperty('--im-k-alt-bg-color', '#E0E0E0');
                    //    r.style.setProperty('--im-k-alt-bg-color', '#E0E0E0');
                    //} else if ($scope.setup.css.altColor === false) {
                    //    //e.sender.element.style.setProperty('--im-k-alt-bg-color', undefined);
                    //    r.style.setProperty('--im-k-alt-bg-color', undefined);
                    //} else {
                    //    //e.sender.element.style.setProperty('--im-k-alt-bg-color', $scope.setup.css.altColor);
                    //    r.style.setProperty('--im-k-alt-bg-color', $scope.setup.css.altColor);
                    //}

                    layoutService.onLayoutChanged($scope, function (info) {
                        if (angular.isUndefined(info)) return;

                        $scope.varModel.fontSize = info.fontSizes.textSize;

                        $scope.varModel.inputStyle.fontSize = info.fontSizes.textSize;
                        $scope.varModel.inputStyle.height = info.height + 'px';
                        $scope.varModel.inputStyle.paddingTop = info.padding.top + 'px';
                        $scope.varModel.inputStyle.paddingBottom = info.padding.bottom + 'px';
                        $scope.varModel.inputStyle.paddingLeft = info.padding.left + 'px';
                        $scope.varModel.inputStyle.paddingRight = info.padding.right + 'px';

                        $scope.varModel.iconStyle.fontSize = info.fontSizes.textSize;
                        $scope.varModel.iconStyle.paddingTop = "7px";
                        $scope.varModel.iconStyle.paddingRight = info.padding.right + "px";

                        // BJS 20220928 - I don't know the purpose of calling setProperty but I do know
                        //                that it fails and prevent any further code execution.
                        if (angular.isDefined($scope.varModel.root.setProperty) === true) {
                            $scope.varModel.root.setProperty('--im-uib-typeahead-fontsize', info.fontSizes.textSize);
                        }

                        if (angular.isDefined($scope.ttStyle)) {
                            angular.extend($scope.varModel.inputStyle, $scope.ttStyle);
                        }

                    });

                    $scope.$watch(function () {
                        return $scope.ttInvalid;
                    }, function () {
                        if (angular.isUndefined($scope.ttInvalid)) return;

                        if ($scope.ttInvalid === true) {
                            $scope.varModel.inputStyle.borderColor = 'red';
                        } else {
                            delete $scope.varModel.inputStyle.borderColor;
                        }
                    });

                    var template = '';

                    //if (cIndex > -1) {
                    //    //template += '<i class="glyphicon glyphicon-remove-sign" ng-style="varModel.iconStyle" ng-show="model.focusKeeper" ng-focus="keepFocus(true)" ng-click="clearInput()"></i>';
                    //    //template += '<i class="glyphicon glyphicon-remove-sign" ng-style="varModel.iconStyle" ng-focus="keepFocus(true)" ng-click="clearInput()"></i>';
                    //}

                    var type = pIndex > -1 ? 'password' : 'text';
                    var isReadOnly = rIndex > -1;
                    var unTabAble = isReadOnly ? ' tabindex="-1"' : '';

                    if (bIndex <= -1) {
                        // Input, plain, with clear and/or select //typevar = null / 'c' / 's' //'c' does not work with 'b'
                        template += '<input ng-class="addClass()" type= "' + type + '" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="varModel.inputStyle" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" ng-blur="keepFocus(false)" ng-readonly="' + isReadOnly + '"' + unTabAble + ' ></input > ';
                    } else if (bIndex > -1) {
                        ////With button //typevar = 'b', can also work with 's'
                        template += '<div style="display: flex;">';

                        if (uIndex > -1) {
                            template +=
                                `<button class="tt-input__button tt-input__button--primary" type="button" ng-style="{'height': varModel.inputStyle.height, 'fontSize': varModel.fontSize}" ng-click="goDown()">
                                    <span class="fa-fw" ng-class="addGlyph('down')"></span>
                                </button>`;
                        }

                        if (angular.isDefined($scope.icons)) {
                            template +=
                                `<span class="tt-input__icon">
                                <span ng-class="addGlyph('icons')" class="fa-fw"></span>
                            </span>`;
                        }

                        template += '<input ng-class="addClass()" type="' + type + '" id="{{model.mId}}" ng-model="model.field" ng-change="goChange()" ng-style="varModel.inputStyle" placeholder="{{model.placeholder}}" ng-focus="keepFocus(true); selectText()" ng-blur="keepFocus(false)" ng-readonly="' + isReadOnly + '"' + unTabAble + '' + (pIndex <= -1 && isReadOnly ? ' ng-click="goTo()"' : '') + '/>';

                        if (uIndex > -1) {
                            template +=
                                `<button class="tt-input__button tt-input__button--primary" type="button" ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }" ng-click="goUp()">
                                    <span class="fa-fw" ng-class="addGlyph('up')"></span>
                                </button>`;
                        }

                        if (cIndex > -1 && rIndex <= -1) {
                            template +=
                                `<button class="tt-input__button tt-input__button--danger" type="button" ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }" ng-click="clearInput()">
                                    <span class="fa-fw" ng-class="addGlyph('remove')"></span>
                                </button>`;
                        }

                        if (kIndex > -1 && rIndex <= -1) {
                            template +=
                                `<button class="tt-input__button tt-input__button--primary" type="button" ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }" ng-click="goBarcode()">
                                    <span class="fa-fw" ng-class="addGlyph('barcode')"></span>
                                </button>`;
                        }

                        if (nIndex > -1 && rIndex <= -1 && oIndex <= -1) {
                            template +=
                                `<button class="tt-input__button tt-input__button--primary" type="button" ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }" ng-click="goCalc()">
                                    <span class="fa-fw" ng-class="addGlyph('calc')"></span>
                                </button>`;
                        }

                        if (oIndex > -1) {
                            template += `<button ng-class="addBtnStyle($index)" type="button" ng-repeat="line in model.lines" ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }" ng-click="goTo($index)">`;

                            if (gIndex > -1) {
                                template += `<span ng-class="addGlyph($index)" class="fa-fw" ng-style="{'fontSize': varModel.inputStyle.fontSize}" ></span>`;
                            }

                            template += '</button>';
                        } else if (gIndex > -1) {
                            template +=
                                `<button class="tt-input__button tt-input__button--primary" type="button" ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }" ng-click="goTo()">
                                    <span ng-class="addGlyph()" class="fa-fw"></span>
                                </button>`;
                        }

                        if (tIndex > -1 || eIndex > -1) {
                            template +=
                                //'<span class="input-group-addon btn btn-primarky btn-lg" type="button">' +
                                `<a class="tt-input__button tt-input__button--primary" href="${((tIndex > -1) ? 'tel' : 'mailto')}: {{model.field}}"  ng-style="{ 'height': varModel.inputStyle.height, 'fontSize': varModel.inputStyle.fontSize }">
                                    <span class="fa-fw glyphicon glyphicon-${(tIndex > -1) ? 'phone' : 'envelope'}" ng-style="{'fontSize': varModel.fontSize}"></span>
                                </a>`
                            //'</span>';
                        }

                        template += '</div>';
                    }

                    $compile(template)($scope, function (cloned) {
                        $element.append(cloned);
                    });
                }
            };

            return directive;
        }]);
})();
