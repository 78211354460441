(function() {
    'use strict';

    angular.module("imApp").factory("ttDatasourceEditorService", ['$ihttp', function($ihttp) {
        var service = {
            delete: function (tt_datasource_keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'deleteDatasource',
                        tt_datasource_keyno: tt_datasource_keyno
                    }
                });
            }
        }

        return service;
    }]);
})();
