(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('findPerson', {
        templateUrl: 'views/components/views/findPerson/findPerson.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['findPersonService', '$timeout', function (findPersonService, $timeout) {
            let vm = this;

            vm.model = {
                searchButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => vm.onSearchtextChanged ('') },
                    { id: 'search', icon: 'fas fa-search', type: 'primary', color: 'primary', onClick: () => vm.onSearchtextChanged(vm.model.getFindPerson.searchtext) },
                ],
                getFindPerson: {},
                listFindPersonSearch: []
            };

            vm.$onInit = function () {
                findPersonService.subscribeToUpdate(updateListFindPersonSearch);
                doInitialSearch();
            };

            const updateListFindPersonSearch = function () {
                angular.copy(findPersonService.selectedPersons, vm.model.listFindPersonSearch)
            }

            var doInitialSearch = function () {
                findPersonService.loadFindPersonGet().then(function (data) {
                    vm.model.getFindPerson = angular.copy(data[0]);

                    if (vm.model.getFindPerson.searchtext !== '') {
                        vm.searchFindPerson(true);
                    }
                });
            }
            var rememberSearchText = function () {
                var variableNameSearchText = 'w_findperson.searchtext';

                if (angular.isUndefined(variableNameSearchText) === true) return false;
                if (variableNameSearchText === null) return false;
                if (variableNameSearchText === '') return false;
                if (angular.isUndefined(vm.model.getFindPerson.searchtext) === true) return false;
                if (vm.model.getFindPerson.searchtext === null) return false;
                if (vm.model.getFindPerson.searchtext === '') return false;
                if (vm.model.getFindPerson.searchtext === '0') return false;

                findPersonService.remember(variableNameSearchText, vm.model.getFindPerson.searchtext);
            };

            vm.searchFindPerson = function (instant) {
                findPersonService.loadFindPersonSearchList(vm.model.getFindPerson, instant).then(function (data) {
                    angular.copy(data, vm.model.listFindPersonSearch);
                    rememberSearchText();
                });
            };

            vm.typeAheadsearch = function () {
                return findPersonService.loadFindPersonSearchList(vm.model.getFindPerson);
            }

            vm.onSearchtextCleared = function () {
                vm.model.getFindPerson.searchtext = '';
                vm.model.listFindPersonSearch = [];
            };

            vm.onSearchtextSelected = function (item) {
                vm.model.getFindPerson.searchtext = item.item_name;
                vm.searchFindPerson();

            };

            vm.onSearchtextChanged = function (value) {
                vm.model.getFindPerson.searchtext = value;
                if (vm.model.getFindPerson.searchtext === '') {
                    vm.onSearchtextCleared();
                    rememberSearchText();
                } else {
                    let searchText = findPersonService.selectedPersons.find((item) => item.item_name === vm.model.getFindPerson.searchtext);
                    if (searchText) {
                        vm.onSearchtextSelected(searchText);
                        rememberSearchText();
                    } else {
                        rememberSearchText();
                    }
                }
            };
        }]
    });
})();
