//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("pohFinishCompService", ['$q', '$ihttp', function ($q, $ihttp) {
//        var service = {
//            loaded: false,
//            pohfinish_comp: {
//                item_id: '',
//                item_name: '',
//                item_path: '',
//                item_parms: '',
//                item_state: '',
//                orderby: '',
//                records: []
//            },
//            loadP2PohFinishCompList: function (poh_keyno, quantity_finished_now) {
//                var deferred = $q.defer();

//                var parms_p2_pohfinishcomp_list = {
//                    method: 156,
//                    parameters: {
//                        poh_keyno: poh_keyno,
//                        quantity_finished_now: quantity_finished_now
//                    }
//                };

//                $ihttp.post(parms_p2_pohfinishcomp_list).then(function (data) {
//                    service.pohfinish_comp.records.length = 0;

//                    for (var i = 0; i < data.length; i++) {
//                        service.pohfinish_comp.records.push(data[i]);
//                    }

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//        };

//        return service;
//    }]);
//})();
