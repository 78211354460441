(function () {
    'use strict';

    angular.module("imApp").factory("modalProgressFactory", ['$q', '$uibModal', function ($q, $uibModal) {
        function modalProgress(options) {
            options = options || {};

            this.model = {
                label: options.label || '',
                plabel: options.plabel || '',
                field: options.field || '',
                remField: options.remField || '',
                min: options.min || 0,
                max: options.max || 100,
                step: options.step || 0,
                finishclose: options.finishclose || '1',
                abortclose: options.abortclose || false
            };

            this.instance = null;
        };

        modalProgress.prototype.show = function () {
            var deferred = $q.defer();

            var vm = this;

            this.instance = $uibModal.open({
                backdrop: 'static',
                animation: true,
                keyboard: false,
                template:
                    '<div class="well well-xs">' +
                    '   <im-progress-directive label="{{model.label}}" plabel="model.plabel" ' +
                    '       field="model.field" rem-field="model.remField" ' +
                    '       min="model.min" max="model.max" step="model.step" ' +
                    '       finishclose="model.finishclose" abortclose="model.abortclose" on-close="model.onClose">' +
                    '   </im-progress-directive>' +
                    '</div>',
                resolve: {
                    model: function () {
                        return vm.model;
                    }
                },
                controller: ['$scope', '$uibModalInstance', 'model', function ($scope, $uibModalInstance, model) {
                    $scope.model = model;

                    $scope.model.onClose = function (value) {
                        $uibModalInstance.close();

                        deferred.resolve(value);
                    };
                }]
            });

            return deferred.promise;
        };

        modalProgress.prototype.hide = function () {
            if (angular.isUndefined(this.instance)) return;
            if (this.instance === null) return;

            this.instance.close();

            this.instance = null;
        };

        modalProgress.prototype.step = function () {
            this.model.step++;

            return this.model.max > this.model.step;
        };

        modalProgress.$create = function (options) {
            return new modalProgress(options);
        };

        return modalProgress;
    }]);
})();
