(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("compValidationSetService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadCompValidationSetGet: function (comp_keyno) {
				return p2DataTaskService.call(1394, {
					comp_keyno: comp_keyno
				});
			},
			loadCompValidationSetList: function (comp_keyno) {
				return p2DataTaskService.call(1392, {
					comp_keyno: comp_keyno 
				});
			},
			loadCompValidationSetCombinationList: function (itemsListValidations) {
				return p2DataTaskService.call(1393, itemsListValidations);
			},
			save: function (comp_keyno, records1, records2) {
				return p2DataTaskService.call(1395, {
					comp_keyno: comp_keyno,
					records1: records1,
					records2: records2
				});
			}
		};

		return service;
	}]);
})();