(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('valutas', {
        templateUrl: 'views/components/views/valutas/valutas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'valutasService', function (stateService, valutasService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            vm.model = {
                itemsListValutas: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadValutaEdits = function () {
                valutasService.listValutaEdits().then(function (result) {
                    angular.copy(result, vm.model.itemsListValutas);
                });
            };
            
		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'valuta':
                        stateService.go(state, { valuta_id: '+' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadValutaEdits();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
