(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockZonesService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getStockZonesSetting: function (parms) {
                return $ihttp.post({
                    method: 928,
                    parameters: parms || {}
                });
            },
            listStockZones: function (parms) {
                return $ihttp.post({
                    method: 929,
                    parameters: parms || {}
                });
            },

            saveWarehouse: function (warehouse) {
                return p2DataTaskService.call(2545, {
                    warehouse_keyno: warehouse.warehouse_keyno,
                    warehouse_name: warehouse.warehouse_name,
                    stockplace_id: warehouse.stockplace_id,
                    stockzone_keyno: warehouse.stockzone_keyno
                });
            },

            saveStockZone: function (stockZone) {
                return p2DataTaskService.call(2573, {
                    warehouse_keyno: stockZone.warehouse_keyno,
                    stockzone_keyno: stockZone.stockzone_keyno,
                    stockzone_name: stockZone.stockzone_name
                });
            }
        };

        return service;
    }]);
})();
