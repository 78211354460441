(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderLineCancelCauses', {
        templateUrl: 'views/components/views/orderLineCancelCauses/orderLineCancelCauses.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'orderLineCancelCausesService', function (stateService, orderLineCancelCausesService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                itemsListCauses: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            orderLineCancelCausesService.listOrderLineCancelCauses().then(function (result) {
            	angular.copy(result, vm.model.itemsListCauses);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, keyno) {
                switch (state) {
                    case 'orderlinecancelcause':
                        stateService.go(state, {
                            orderlinecancelcause_keyno: keyno
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();
