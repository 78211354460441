(function () {
    'use strict';

    angular.module("imApp").factory("confirmationTerminalScanService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var encoder = function (data, key, format, encodeAll) { //Added if needed
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        var service = {
            scan: function (p2DataTaskKeyno, parameters) {
                return call(p2DataTaskKeyno, parameters || {});
            },
            requisitionScan: function (parameters) {
                return call(2224, parameters || {});
            },
            stocktransferScan: function (parameters) {
                return call(2325, parameters || {});
            }
        }

        return service;
    }]);
})();
