<div tabindex="0" id="{{ id.widget }}" class="tt-widget" [ngStyle]="style.widget" (pointerdown)="onWidgetMouseDown($event)">
    <div *ngIf="ttCanResize === true" class="tt-widget--resizable">
        <div class="tt-widget-resizable__container">
            <span
                *ngFor="let anchor of anchors"
                class="tt-widget-resizable__anchor"
                ngClass="tt-widget-resizable__anchor--{{ anchor }}"
                (pointerdown)="onPointerDown($event, anchor)"
                (pointercancel)="onPointerCancel($event)"
            ></span>
        </div>
    </div>
    <div style="display: flex; flex-direction: column; width: 100%; overflow: hidden">
        <header *ngIf="ttIcon || ttMainHeading || ttSubHeading" style="display: flex; min-width: 100%; width: 100%; gap: 0.8rem">
            <span class="tt-widget__icon-containter">
                <span class="tt-widget__icon-background" [ngStyle]="{ backgroundColor: ttColor }"></span>
                <span class="fa-fw tt-widget__icon" ngClass="{{ ttIcon }}" [ngStyle]="{ color: ttColor }"></span>
            </span>
            <div class="tt-widget__headings">
                <p class="tt-widget__heading--main">{{ ttMainHeading }}</p>
                <p *ngIf="ttSubHeading" class="tt-widget__heading--sub">{{ ttSubHeading }}</p>
                <!-- <p class="tt-widget__value">{{ ttModel }}</p> -->
            </div>
        </header>
        <ng-container *ngIf="ttType === 'chart' && widgetService.isChartConfig(ttModel)">
            <tt-widget-chart [ttOptions]="ttModel.options" [ttChartType]="ttModel.chartType" [ttData]="ttModel.data" style="height: 100%; width: 100%"></tt-widget-chart>
        </ng-container>
        <ng-container *ngIf="ttType === 'text' && ttModel && widgetService.isValidWidgetTextConfig(ttModel)">
            <tt-widget-text *ngIf="widgetService.isWidgetTextConfig(ttModel)" [ttModel]="ttModel.model" [ttVariables]="ttModel.variables" style="height: 100%; width: 100%"></tt-widget-text>
        </ng-container>

        <!-- <p *ngSwitchDefault class="tt-widget__value">{{ ttModel }}</p> -->
    </div>
</div>
