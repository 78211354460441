(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('ediImport', {
        templateUrl: 'views/components/views/ediImport/ediImport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'translateService', 'modalService', 'ediImportService', function ($stateParams, stateService, base64, translateService, modalService, ediImportService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                bgEdiImportMain: {
                    selected: 'DETAILS',
                    buttons: [
                        { id: 'DETAILS', label: 'gb_ediimport_main_details', color: 'primary', onClick: () => vm.model.bgEdiImportMain.selected = 'DETAILS' },
                        { id: 'CONTENT', label: 'gb_ediimport_main_content', color: 'primary', onClick: () => vm.model.bgEdiImportMain.selected = 'CONTENT' },
                        { id: 'FUNCTIONS', label: 'gb_ediimport_main_functions', color: 'primary', onClick: () => vm.model.bgEdiImportMain.selected = 'FUNCTIONS' }
                    ]
                },
                ediImportKeyno: $stateParams.edi_import_keyno,
                getEdiImport: {},
                listEdiSource: [],
                listImportProc: [],
                loading: false,
                lockedSave: false,
                lockedDelete: false,
                show_in_excelimport: 'A'
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##
            
            var loadEdiImport = function () {
                ediImportService.loadEdiImportGet(vm.model.ediImportKeyno).then(function (data) {
                    vm.model.getEdiImport = angular.copy(data[0]);
                    vm.model.getEdiImport.filecontent = base64.urldecode(vm.model.getEdiImport.filecontent);
                });
            };
            
            loadEdiImport();
            
            ediImportService.loadEdiSourceList().then(function (data) {
                angular.copy(data, vm.model.listEdiSource);
            });
            
            ediImportService.loadImportProcList(vm.model.show_in_excelimport).then(function (data) {
                angular.copy(data, vm.model.listImportProc);
            });
            
            // ## PROCESS BUTTON FUNCTION ##
            
            vm.processEdiImport = function () {
                vm.model.loading = true;
                
                ediImportService.loadEdiImportProcessManual(vm.model.getEdiImport.edi_import_keyno).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loading = false;
                                    loadEdiImport();
                                }
                            }]
                        });
                    } else {
                        vm.model.loading = false;
                        loadEdiImport();
                    }
                });
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgEdiImportMain = function (value) {
                vm.model.bgEdiImportMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                var getEdiImport = {
                    edi_import_keyno: vm.model.getEdiImport.edi_import_keyno,
                    reg_datetime: vm.model.getEdiImport.reg_datetime,
                    edi_source_keyno: vm.model.getEdiImport.edi_source_keyno,
                    filename: vm.model.getEdiImport.filename,
                    filecontent: base64.urlencode(vm.model.getEdiImport.filecontent),
                    file_created_datetime: vm.model.getEdiImport.file_created_datetime,
                    edi_prosess_status_name: vm.model.getEdiImport.edi_prosess_status_name,
                    prosessing_start_datetime: vm.model.getEdiImport.prosessing_start_datetime,
                    prosessing_end_datetime: vm.model.getEdiImport.prosessing_end_datetime,
                    p2_importproc_keyno: vm.model.getEdiImport.p2_importproc_keyno,
                    prosessing_message: vm.model.getEdiImport.prosessing_message,
                    reg_by_portal_user_name: vm.model.getEdiImport.reg_by_portal_user_name,
                    prosessing_count: vm.model.getEdiImport.prosessing_count,
                    add_type: vm.model.getEdiImport.add_type
                };
                
                ediImportService.save(getEdiImport).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    ediImportService.delete(vm.model.getEdiImport.edi_import_keyno).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

        }]
    });
})();
