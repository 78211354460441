(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('projectInvoicing', {
        templateUrl: 'views/components/views/projectInvoicing/projectInvoicing.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'projectInvoicingService', function ($stateParams, stateService, utilityService, translateService, modalService, projectInvoicingService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'projectmenu':
                        go({
                            project_keyno: vm.model.menu.project_keyno
                        });
                        break;
                    case 'customersmenu':
                        go({
                            cust_no: vm.model.menu.invoice_cust_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                projectKeyNo: $stateParams.project_keyno,
                invoiceCustNo: $stateParams.invoice_cust_no,
                projectKeyNoAndNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goToFunc, state: 'projectmenu' } }
                ],
                custNoAndNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goToFunc, state: 'customersmenu' } }
                ],
                bgProjectInvoicingMain: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_projectinvoicing_main_select', color: 'primary', onClick: function () { vm.model.bgProjectInvoicingMain.selected = 'SELECT'; } },
                        { id: 'SHOW', label: 'bg_projectinvoicing_main_show', color: 'primary', onClick: function () { vm.model.bgProjectInvoicingMain.selected = 'SHOW'; vm.saveChanges(); } }
                    ]
                },
                menu: {},
                gridReady: false,
                gridReadyBase: false,
                lockedSave: false,
                creatingInvoice: false,
                settingNot: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',						// Id used to retrieve user configs for the grid
                    loadSetupId: null,					// Uses default if null or undefined
                    loadData: {						// Method id of the get data procedure
                        //method: '',
                        //parameters: {}
                    },
                    addRow: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    removeRow: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    saveData: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    }
                },
                config: {							// TouchTime parameters configuring the grid
                    editColumns: [
                        { key: 'is_selected' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {						// alternative -> toolbar: false,
                        hidden: false,					// If true, hides the toolbar, default is false
                        pdfExport: true,				// Adds a predefined btn for exporting grid to pdf
                        excelExport: true,				// Adds a predefined btn for exporting grid to excel
                        filter: true,					// Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,				// Adds a predefined btn for deciding which columns to show/hide
                        headers: true,					// Adds a predefined btn for switched between db column names and titles
                        edit: false,					// Adds a predefined btn for en-/disabling editing in columns
                        lock: false,					// NOT IMPLEMENTED
                        add: false,					// Adds predefined btns for running an add function     // Supports addRow      // NOT IMPLEMENTED
                        delete: false,					// Adds a predefined btn for running a delete function  // Supports removeRow   // NOT IMPLEMENTED
                        save: false,					// Adds a predefined btn for running a save function // Needs saveData
                        buttons: [					// List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,				// Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,				// Clears sorting on only is_selected checkbox if false
                    rowClick: false,					// Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // IN DEVELOPMENT
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true					// accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    }
                },
                kendo: {							// Kendo parameters configuring the grid
                    height: 800,						// Sets the height of the grid component
                    aggregate: true,					// accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,						// NOT IMPLEMENTED
                    selectable: 'multiple, row',				// accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                    filterable: false					// Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); },		// Used for referencing functions in the current Component
                gridfunc: null							// Used for referencing functions in the Grid Component
            };

            var optionfunc = function (data) {
                console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    console.log('optionfunc - CellClickHandler');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    console.log('optionfunc - OnCellClose');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    console.log('optionfunc - LookupCellEditor');
                    console.dir(data);
                }
            };

            vm.gridBase = {
                dataTask: {
                    rememberId: '',						// Id used to retrieve user configs for the grid
                    loadSetupId: null,					// Uses default if null or undefined
                    loadData: {						// Method id of the get data procedure
                        //method: '',
                        //parameters: {}
                    },
                    addRow: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    removeRow: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    saveData: {						// NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    }
                },
                config: {							// TouchTime parameters configuring the grid
                    editColumns: [
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {						// alternative -> toolbar: false,
                        hidden: false,					// If true, hides the toolbar, default is false
                        pdfExport: true,				// Adds a predefined btn for exporting grid to pdf
                        excelExport: true,				// Adds a predefined btn for exporting grid to excel
                        filter: true,					// Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,				// Adds a predefined btn for deciding which columns to show/hide
                        headers: true,					// Adds a predefined btn for switched between db column names and titles
                        edit: false,					// Adds a predefined btn for en-/disabling editing in columns
                        lock: false,					// NOT IMPLEMENTED
                        add: false,					// Adds predefined btns for running an add function     // Supports addRow      // NOT IMPLEMENTED
                        delete: false,					// Adds a predefined btn for running a delete function  // Supports removeRow   // NOT IMPLEMENTED
                        save: false,					// Adds a predefined btn for running a save function // Needs saveData
                        buttons: [					// List of custom buttons
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,				// Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,				// Clears sorting on only is_selected checkbox if false
                    rowClick: false,					// Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // IN DEVELOPMENT
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true					// accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    }
                },
                kendo: {							// Kendo parameters configuring the grid
                    height: 800,						// Sets the height of the grid component
                    aggregate: true,					// accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,						// NOT IMPLEMENTED
                    selectable: 'multiple, row',				// accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                    filterable: false					// Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfuncBase(data.data); },		// Used for referencing functions in the current Component
                gridfunc: null							// Used for referencing functions in the Grid Component
            };

            var optionfuncBase = function (data) {
                console.log('optionfuncBase');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    console.log('optionfuncBase - CellClickHandler');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    console.log('optionfuncBase - OnCellClose');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    console.log('optionfuncBase - LookupCellEditor');
                    console.dir(data);
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            projectInvoicingService.getProjectInvoicing({
                project_keyno: vm.model.projectKeyNo,
                invoice_cust_no: vm.model.invoiceCustNo
            }).then(function (data) {
                angular.copy(data[0], vm.model.menu);

                if (utilityService.validateParmsValue(vm.model.menu.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.menu.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.menu.view_id_base) === true) {
                    vm.gridBase.dataTask.rememberId = vm.model.menu.view_id_base;
                }

                if (utilityService.validateParmsValue(vm.model.menu.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.menu.p2_datatask_keyno,
                        parameters: vm.model.menu
                    };
                }

                if (utilityService.validateParmsValue(vm.model.menu.p2_datatask_keyno_base) === true) {
                    vm.gridBase.dataTask.loadData = {
                        method: vm.model.menu.p2_datatask_keyno_base,
                        parameters: vm.model.menu
                    };
                }

                vm.model.gridReady = true;
                vm.model.gridReadyBase = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                var allData = vm.grid.gridfunc.getAllRows().map(function (a) { return { "powtime_keyno": a.powtime_keyno, "is_selected": a.is_selected }; });
                var mergeObj = vm.model.menu;
                mergeObj.records = allData;

                projectInvoicingService.saveObj(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;

                        vm.gridBase.dataTask.loadData = {
                            method: vm.model.menu.p2_datatask_keyno_base,
                            parameters: vm.model.menu
                        };

                        vm.gridBase.gridfunc.rebind();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON CREATE PROCEDURE FUNCTION CALL

            vm.create = function () {
                vm.model.creatingInvoice = true;

                var allData = vm.grid.gridfunc.getAllRows().map(function (a) { return { "powtime_keyno": a.powtime_keyno, "is_selected": a.is_selected }; });
                var mergeObj = vm.model.menu;
                mergeObj.records = allData;

                projectInvoicingService.createInvoice(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingInvoice = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.creatingInvoice = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON CREATE PROCEDURE FUNCTION CALL

            // #region BUTTON SET NOT PROCEDURE FUNCTION CALL

            vm.setNot = function () {
                vm.model.settingNot = true;

                var allData = vm.grid.gridfunc.getAllRows().map(function (a) { return { "powtime_keyno": a.powtime_keyno, "is_selected": a.is_selected }; });
                var mergeObj = vm.model.menu;
                mergeObj.records = allData;

                projectInvoicingService.setNotInvoice(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.settingNot = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.settingNot = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SET NOT PROCEDURE FUNCTION CALL
        }]
    });
})();
