(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectWorkingOrder', {
        templateUrl: 'views/components/views/selectWorkingOrder/selectWorkingOrder.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['selectWorkingOrderService', function (selectWorkingOrderService) {
            var vm = this;
            
            vm.model = {
                btnListFilterVal: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                custactList: {},
                searchParms: {
                    custName: 'ALL',
                    custNo: '0',
                    projectKeyno: '0',
                    stateOk: '0'
                }
            };
    
            vm.menuSearch = {
                filterVal: ''
            };

            vm.btnListFuncFilterVal = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.menuSearch.filterVal = '';
                        break;
                    default:
                        break;
                }
            };

            vm.searchWorkingOrders = function () {
                selectWorkingOrderService.loadCustactList(vm.model.searchParms.custName, vm.model.searchParms.custNo, vm.model.searchParms.projectKeyno, vm.model.searchParms.stateOk).then(function () {
                    vm.model.custactList = selectWorkingOrderService.custactList;
                });
            };
    
            vm.searchWorkingOrders();
    
            vm.saveSelectWorkingOrder = function (item) {
                selectWorkingOrderService.saveSelectWorkingOrder(item);
            };
        }]
    });
})();