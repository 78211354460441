(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('epBlockOrders', {
        templateUrl: 'views/components/views/epBlockOrders/epBlockOrders.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$state', '$uibModal', 'responsive', 'layoutService', function ($state, $uibModal, responsive, layoutService) {
            var vm = this;

            vm.gridControls = null;
            vm.fontSize = null;

            vm.edit = function (e) {
                e.preventDefault();

                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                if (['xx', 'xs', 'sm', 'md'].indexOf(responsive.current) > 0) {
                    $state.go('epblockorderedit', { pohKeyno: dataItem.poh_keyno });
                } else {
                    $uibModal.open({
                        component: 'epBlockOrderEdit',
                        resolve: {
                            pohKeyno: function () {
                                return dataItem.poh_keyno
                            }
                        },
                        size: 'pst-ninety',
                        backdrop: 'static'
                    }).result.then(function () {
                        vm.gridControls.refresh();
                    });
                }
            };

            vm.plan = function (e) {
                e.preventDefault();

                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                if (['xx', 'xs', 'sm', 'md'].indexOf(responsive.current) > 0) {
                    $state.go('epblockorderplan', { pohKeyno: dataItem.poh_keyno });
                } else {
                    $uibModal.open({
                        component: 'epBlockOrderPlan',
                        resolve: {
                            pohKeyno: function () {
                                return dataItem.poh_keyno
                            }
                        },
                        size: 'pst-ninety',
                        backdrop: 'static'
                    }).result.then(function () {
                        vm.gridControls.refresh();
                    });
                }
            };

            vm.gridOptions = {
                dataTaskKeyno: 1996,
                rememberId: 'epBlockOrders',
                translations: ['edit', 'plan_orders'],
                gridControlCallback: function (gridControls) { vm.gridControls = gridControls; },
                onSetup: function (schema, fields, columns, translations) {
                    for (var i = 0; i < columns.length; i++) {
                        if (columns[i].field !== 'postatus_name')
                            continue;

                        columns[i].attributes.style += 'text-align: center;';
                        columns[i].headerAttributes.style += 'text-align: center;';
                    }

                    var cols = [
                        { command: { text: translations.edit, click: vm.edit, className: 'col-xs-12' }, title: " ", width: "180px", attributes: { style: 'font-size: ' + vm.fontSize + ';' } },
                        { command: { text: translations.plan_orders, click: vm.plan, className: 'col-xs-12' }, title: " ", width: "180px", attributes: { style: 'font-size: ' + vm.fontSize + ';' } },
                        columns[1],
                        columns[2],
                        columns[3],
                        columns[11],
                        columns[5],
                        columns[6],
                        columns[8],
                        columns[0],
                        columns[10]
                    ];

                    angular.copy(cols, columns);
                },
                onDataBound: function (e) {
                    var rows = e.sender.content.find('tr');
                    var statusNameIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "postatus_name" + "]").index();

                    rows.each(function (_, row) {
                        var dataItem = e.sender.dataItem(row);

                        var statusClass = getStatusClass(dataItem.postatus_no);

                        $(row).children('td:eq(' + statusNameIndex + ')').addClass(statusClass);
                        $(row).children('td:eq(' + statusNameIndex + ')').children('span').addClass(statusClass);
                    })
                }
            };

            var getStatusClass = function (statusNo) {
                if (angular.isNumber(statusNo) !== true) {
                    statusNo = parseInt(statusNo);
                }

                switch (true) {
                    case statusNo < 100:
                        return 'bg-red';
                    case statusNo === 100:
                        return 'bg-gray'
                    case statusNo === 200:
                        return 'bg-yellow';
                    case statusNo === 300:
                        return 'bg-green';
                    default:
                        return 'bg-default';
                }
            };

            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSizeS;
            });

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();