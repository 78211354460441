(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicGrid', {
        templateUrl: 'views/components/dynamics/components/ttDynamicGrid/ttDynamicGrid.template.html',
        controllerAs: 'vm',
        bindings: {
            ttKeyno: '<',
            ttOptions: '<',
            ttName: '@'
        },
        controller: ['$timeout', 'eventService', 'ttDatasourceManager', 'ttDynamicGridManager', 'ttDynamicGridService', 'utilityService', function ($timeout, eventService, ttDatasourceManager, ttDynamicGridManager, ttDynamicGridService, us) {
            var vm = this;

            vm.showGrid = false;

            vm.grid = {};

            let _datasource = {};
            let _gridRowDatasource = {};
            let _onDestroy = [];

            vm.$onInit = function () {
                $.extend(vm.grid, angular.copy(vm.ttOptions));

                vm.grid.dynamic = {
                    instanceId: vm.ttOptions._rootId,
                    name: vm.ttName,
                    tt_dynamic_keyno: vm.ttKeyno
                };

                if (angular.isUndefined(vm.grid.gridfunc)) {
                    vm.grid.gridfunc = null;
                }

                if (angular.isUndefined(vm.grid.kendo) || angular.isObject(vm.grid.kendo) !== true) {
                    vm.grid.kendo = {};
                }

                vm.grid.kendo.selectable = true;
                vm.grid.kendo.height = vm.grid.kendo.height || null;
                vm.grid.kendo.aggregate = vm.grid.kendo.aggregate || undefined;
                vm.grid.kendo.pager = us.toBoolean(vm.grid.kendo.pager, false);
                vm.grid.kendo.filterable = us.toBoolean(vm.grid.kendo.filterable, false);

                if (angular.isUndefined(vm.grid.dataTask) || angular.isObject(vm.grid.dataTask) !== true) {
                    vm.grid.dataTask = {};
                }

                ensureHasLoadSetupId();

                setDataTaskDefaults('addRow');
                setDataTaskDefaults('removeRow');
                setDataTaskDefaults('saveData');

                vm.grid.dataTask.loadData.parameters = function () {
                    // TODO - Implement linking to multi item datasource.
                    if (angular.isUndefined(_datasource)) return {};
                    if (angular.isObject(_datasource) !== true) return {};
                    if (angular.isUndefined(_datasource.item)) return {};

                    return _datasource.item;
                };

                vm.showGrid = true;

                vm.grid.optionfunc = optionfunc;

                if (angular.isDefined(vm.grid.dynamic) && angular.isDefined(vm.grid.dynamic.gridRow)) {
                    ttDynamicGridService.init(vm.grid.dynamic.gridRow).then(function (response) {
                        angular.copy(response.datasource, _gridRowDatasource);
                    });
                }

                subscribeToDatasource();

                if (angular.isString(vm.ttName)) {
                    _onDestroy.push(eventService.on(vm.ttOptions._rootId + ':dynamicgrid:gridfunc:' + vm.ttName, function (options) {
                        if (angular.isObject(options) !== true) return;
                        if (angular.isString(options.method) !== true) return;
                        if (angular.isObject(vm.grid.gridfunc) !== true) return;
                        if (angular.isFunction(vm.grid.gridfunc[options.method]) !== true) return;

                        // TODO!!! if calling read or refresh with selected we must reselect row after grid is updated.
                        vm.grid.gridfunc[options.method](options.parms);
                    }));
                }

                if (angular.isObject(vm.grid.config)) {
                    vm.grid.config.fixedHeader = us.toBoolean(vm.grid.config.fixedHeader, false);
                    vm.grid.config.keepSortOnAdd = us.toBoolean(vm.grid.config.keepSortOnAdd, false);
                    vm.grid.config.keepSortOnCheckbox = us.toBoolean(vm.grid.config.keepSortOnCheckbox, false);
                    vm.grid.config.keepSortOnIsSelected = us.toBoolean(vm.grid.config.keepSortOnIsSelected, false);
                    vm.grid.config.rowClick = us.toBoolean(vm.grid.config.rowClick, false);

                    if (angular.isUndefined(vm.grid.config.onDataSourceChanges) || angular.isFunction(vm.grid.config.onDataSourceChanges) !== true) {
                        vm.grid.config.onDataSourceChanges = function () { };
                    }

                    if (angular.isUndefined(vm.grid.config.css) || angular.isObject(vm.grid.config.css) !== true) {
                        vm.grid.config.css = {};
                    }

                    vm.grid.config.css.altColor = us.toBoolean(vm.grid.config.css.altColor, true);
                    vm.grid.config.css.textWrapping = us.toBoolean(vm.grid.config.css.textWrapping, false);

                    if (angular.isObject(vm.grid.config.toolbar)) {
                        angular.forEach(vm.grid.config.toolbar, function (val, key) {
                            if (key === 'buttons' && angular.isArray(val) !== true) {
                                vm.grid.config.toolbar[key] = [];
                            } else {
                                vm.grid.config.toolbar[key] = us.toBoolean(val, false);
                            }
                        });
                    }

                    if (angular.isObject(vm.grid.config.specialFunc)) {
                        vm.grid.config.specialFunc.newTab = us.toBoolean(vm.grid.config.specialFunc.newTab, false);

                        if (angular.isUndefined(vm.grid.config.specialFunc.buttons) || angular.isArray(vm.grid.config.specialFunc.buttons) !== true) {
                            vm.grid.config.specialFunc.buttons = [];
                        }
                    }
                }

                ttDynamicGridManager.register(vm.ttOptions._rootId, vm.ttName, vm.grid);
            };

            vm.$onDestroy = function () {
                eventService.destroy(_onDestroy);
            };

            function setDataTaskDefaults(id) {
                if (angular.isUndefined(vm.grid.dataTask[id]) || angular.isObject(vm.grid.dataTask[id]) !== true) {
                    vm.grid.dataTask[id] = {};
                }

                if (angular.isUndefined(vm.grid.dataTask[id].method)) {
                    vm.grid.dataTask[id].method = null;
                }

                if (angular.isUndefined(vm.grid.dataTask[id].parameters)) {
                    vm.grid.dataTask[id].parameters = null;
                }

                vm.grid.dataTask[id].autoSave = us.toBoolean(vm.grid.dataTask[id].autoSave, false);
                vm.grid.dataTask[id].confirm = us.toBoolean(vm.grid.dataTask[id].confirm, false);
            };

            function ensureHasLoadSetupId() {
                if (angular.isUndefined(vm.grid.dataTask.loadSetupId)) {
                    vm.grid.dataTask.loadSetupId = 1999;
                    return;
                }

                if (vm.grid.dataTask.loadSetupId === null) {
                    vm.grid.dataTask.loadSetupId = 1999;
                    return;
                }

                if (angular.isString(vm.grid.dataTask.loadSetupId) && vm.grid.dataTask.loadSetupId.trim().length < 1) {
                    vm.grid.dataTask.loadSetupId = 1999;
                    return;
                }

                if (angular.isNumber(vm.grid.dataTask.loadSetupId) && vm.grid.dataTask.loadSetupId < 1) {
                    vm.grid.dataTask.loadSetupId = 1999;
                    return;
                }
            };

            function selectRow() {
                if (angular.isObject(vm.grid.gridfunc) !== true || vm.grid.gridfunc === null || vm.grid.gridfunc.isReady() !== true || vm.grid.gridfunc.hasRows() !== true) {
                    $timeout(selectRow, 250);
                    return;
                }

                vm.grid.gridfunc.selectRow(0);

                var dataItem = vm.grid.gridfunc.getRowAt(0);

                eventService.trigger('dynamicGrid.ds' + _gridRowDatasource.keyno, dataItem, false);
            };

            function optionfunc(event) {
                if (angular.isUndefined(event)) return;
                if (angular.isUndefined(event.data)) return;
                if (angular.isUndefined(event.data.func)) return;
                if (event.data.func !== 'CellClickHandler') return;

                eventService.trigger('dynamicGrid.ds' + _gridRowDatasource.keyno, event.data.clickedCell.dataItem, false);
            };

            function onDatasourceDataChanged(datasourceId) {
                if (angular.isString(datasourceId) !== true) return;
                if (datasourceId !== _datasource.datasource_id) return;

                _datasource = ttDatasourceManager.getDatasourceByKeyno(vm.grid._rootId, _datasource.keyno);

                vm.grid.gridfunc.read();
            };

            function subscribeToDatasource() {
                if (angular.isUndefined(vm.grid)) return;
                if (angular.isUndefined(vm.grid.dataTask)) return;
                if (angular.isUndefined(vm.grid.dataTask.loadData)) return;
                if (angular.isUndefined(vm.grid.dataTask.loadData.parameters)) return; 
                
                _datasource = ttDatasourceManager.subscribeByKeyno(vm.grid._rootId, vm.ttOptions.dataTask.loadData.parameters, onDatasourceDataChanged);
            };
        }]
    });
})();
