(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('htmlReport', {
        templateUrl: 'views/components/views/htmlReport/htmlReport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'htmlReportService', 'languageService', '$element', 'translateService', 'base64', 'pdfService', 'modalService', function (stateParams, stateService, htmlReportService, languageService, $element, translateService, base64, pdfService, modalService) {

            const vm = this;

            vm.model = {
                paperWidthCM: 21,
                paperWidth: 805,
                scale: 1,
                htmlReport: {},
                htmlProcList: [],
                selectListWebPages: [],
                htmlReportHtml: '',
                htmlReportHtmlHeader: '',
                htmlReportHtmlBody: '',
                htmlReportHtmlFooter: '',
                language_id: '',
                languageList: [],
                lockedHtmlRefresh: false,
                lockedPrint: false,
                translations: {
                    procedures: 'sub_procedures',
                    preview: 'preview',
                    error: 'error',
                    ok: 'ok',
                    show_report_preview: 'show_report_preview'
                },
                viewBoxes: {
                    height: '100%',
                    rememberId: 'htmlreport.boxes',
                    boxes: [
                        { item_id: uuid(), item_name: 'over', type: 'h', size: '100', depth: 0 },
                        { item_id: uuid(), item_name: 'venstre', type: 'v', size: '25', depth: 1 },
                        { item_id: uuid(), item_name: 'høyre', type: 'v', size: '75', depth: 1 }
                    ],
                },
                htmlproc_keyno_selected: 0,
                htmlproc_selected: {},
                tabs: {
                    selected: 'REPORT',
                    buttons: [
                        { id: 'REPORT', label: 'htmlreport', color: 'primary', onClick: () => vm.model.selected = 'PREVIEW' },
                        { id: 'PREVIEW', label: 'preview', color: 'primary', onClick: () => vm.model.selected = 'PREVIEW' },
                        { id: 'PROCEDURES', label: 'procedures', color: 'primary', onClick: () => vm.model.selected = 'PROCEDURES' },
                    ]
                }
            };

            let load = function () {
                translateService.translateBatch(vm.model.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.model.translations[key] = val;
                    });
                });

                htmlReportService.getReport(stateParams.htmlreport_keyno).then(function (result) {
                    angular.copy(result[0], vm.model.htmlReport);
                    console.dir(vm.model.htmlReport);
                });
                htmlReportService.loadHtmlProcList(stateParams.htmlreport_keyno).then(function (result) {
                    angular.copy(result, vm.model.htmlProcList);

                    vm.model.htmlProcList = [
                        {
                            "item_id": "0",
                            "item_name": vm.model.translations.show_report_preview,
                            "item_id_parent": "",
                            "item_glyphicon": "",
                            "item_glyphicon_color": "",
                            "orderby": "0",
                            "isexpanded": "1"
                        },
                        ...vm.model.htmlProcList
                    ];
                });

                htmlReportService.listWebPages().then(function (result) {
                    angular.copy(result, vm.model.selectListWebPages);
                });

                window.addEventListener('resize', vm.onResize);
                vm.onResize();

                languageService.list().then(function (result) {
                    angular.copy(result, vm.model.languageList);

                    const language = result.find(l => l.currentlanguage == 1);
                    if (language) vm.model.language_id = language.item_id;

                    vm.getHtmlPreview();
                });
            }

            vm.showHtmlPreview = function () {
                let element = $element.find('#htmlReportPreview')[0];
                if (angular.isDefined(element) && element !== null) {
                    element.innerHTML = vm.model.htmlReportHtml;
                }
            }

            vm.rotatePreview = function () {
                vm.model.paperWidthCM = vm.model.paperWidthCM === 21 ? 29.7 : 21;
            }

            vm.getHtmlPreview = function () {
                if (vm.model.htmlReport.htmlreport_keyno == 0) return;
                vm.model.lockedHtmlRefresh = true;
                htmlReportService.getHtmlReportHtml({
                    htmlreport_keyno: stateParams.htmlreport_keyno,
                    language_id: vm.model.language_id
                }).then(function (result) {

                    if (angular.isDefined(result[0].htmlstring)) {

                        
                        vm.model.htmlReportHtmlBody = base64.urldecode(result[0].htmlstring);
                        vm.model.htmlReportHtmlHeader = base64.urldecode(result[0].headerstring);
                        vm.model.htmlReportHtmlFooter = base64.urldecode(result[0].footerstring);
                        vm.model.htmlReportHtml = vm.model.htmlReportHtmlHeader + vm.model.htmlReportHtmlBody + vm.model.htmlReportHtmlFooter;

                        vm.showHtmlPreview();
                    }
                    vm.model.lockedHtmlRefresh = false;
                });
            }

            

            vm.showReportPreview = function () {
                vm.model.htmlproc_keyno_selected = 0;
                vm.model.htmlproc_selected = {};
                vm.getHtmlPreview();
            }

            vm.selectTreeItem = function (item) {
                if (vm.model.htmlproc_keyno_selected !== item.item_id) {
                    vm.model.htmlproc_keyno_selected = item.item_id;
                    vm.model.htmlproc_selected = item;
                }
                if (vm.model.htmlproc_keyno_selected == 0) {
                    vm.getHtmlPreview();
                }
                
                //console.log(item);
            }

            vm.onChange = function (name, value) {
                switch (name) {
                    case 'language_id': {
                        if (value !== vm.model.language_id) {
                            vm.model.language_id = value;
                            vm.getHtmlPreview();
                        }
                    }
                }
            }

            vm.print = async function () {

                vm.model.lockedPrint = true;

                try {

                    const pdfOptions = {
                        marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' },
                        landscape: false,
                        outline: false,
                        printBackground: true,
                        displayHeaderFooter: true,
                        //headerTemplate: '<div id="header-template" style="font-size:16px !important; color:#808080; padding-left:10px"><span class="date"></span><span class="title"></span><span class="url"></span><span class="pageNumber"></span><span class="totalPages"></span></div>',
                        //footerTemplate: '<div id="footer-template" style="font-size:16px !important; color:#808080; padding-left:10px"><span class="date"></span><span class="title"></span><span class="url"></span><span class="pageNumber"></span><span class="totalPages"></span></div>',
                        headerTemplate:  vm.model.htmlReportHtmlHeader,
                        footerTemplate: vm.model.htmlReportHtmlFooter,
                    };

                    const params = {
                        sourceHtml: vm.model.htmlReportHtmlBody,
                        filename: vm.model.htmlReport.htmlreport_name,
                        download: true,
                        pdfOptions: pdfOptions
                    };

                    await pdfService.htmlToPdf(undefined, params);

                } catch (e) {
                    console.log(e.message);
                    
                } finally {
                    vm.model.lockedPrint = false;
                }
            }

            vm.saveReport = function () {
                vm.model.lockedSave = true;
                htmlReportService.saveReport(vm.model.htmlReport).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        console.log('error');
                        modalService.show({
                            type: 'warning',
                            title: vm.model.translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: vm.model.translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        console.log('all is well');
                        vm.model.lockedSave = false;
                        if (vm.model.htmlReport.htmlreport_keyno == 0) stateService.back();
                    }
                });
            }

            vm.delete = async function () {
                vm.model.lockedSave = true;
                const response = (await htmlReportService.deleteReport(vm.model.htmlReport.htmlreport_keyno))[0];
                if (response && response.errorcode !== '0') {
                    console.log('error');
                    modalService.show({
                        type: 'warning',
                        title: vm.model.translations.error,
                        message: response.errormessage,
                        buttons: [{
                            label: vm.model.translations.ok,
                            cssClass: 'btn-warning',
                            action: function (dialogItself) {
                                dialogItself.close();
                                vm.model.lockedSave = false;
                            }
                        }]
                    });
                } else {
                    console.log('all is well');
                    stateService.back();
                }
                vm.model.lockedSave = false;
            }

            vm.duplicate = async function () {
                vm.model.lockedSave = true;

                const response = (await htmlReportService.duplicateReport(vm.model.htmlReport.htmlreport_keyno))[0];
                if (response && response.errorcode !== '0') {
                    console.log('error');
                    modalService.show({
                        type: 'warning',
                        title: vm.model.translations.error,
                        message: response.errormessage,
                        buttons: [{
                            label: vm.model.translations.ok,
                            cssClass: 'btn-warning',
                            action: function (dialogItself) {
                                dialogItself.close();
                                vm.model.lockedSave = false;
                            }
                        }]
                    });
                } else {
                    console.log('all is well');
                    stateService.back();
                }
                vm.model.lockedSave = false;
            }

            vm.$onInit = function () {
                load();
            };

            vm.onResize = function () {
                const wrapper = document.getElementById('htmlReportPreviewWrapper');
                if (wrapper && wrapper.clientWidth > 0) {
                    vm.model.scale = vm.model.paperWidth < wrapper.clientWidth ? 1 : wrapper.clientWidth / vm.model.paperWidth;
                }
            }
        }]
    });
})();
