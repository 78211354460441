(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('currencyExRates', {
        templateUrl: 'views/components/views/currencyExRates/currencyExRates.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'currencyExRatesService', function ($stateParams, $q, currencyExRatesService) {
            const vm = this;
            let valutaId = $stateParams.valuta_id;
            let currencyTypeKeyno = $stateParams.currencytype_keyno;

            vm.model = {
                setting: {},
    			itemsListCurrencyExRates: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                currencyExRatesService.getSetting({
                    valuta_id: valutaId,
                    currencytype_keyno: currencyTypeKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadCurrencyExRates();

                    deferred.resolve();
                });

                return deferred.promise;
            };
    		
            let loadCurrencyExRates = function () {
                return currencyExRatesService.listCurrencyExRates(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListCurrencyExRates);
    			});
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
