(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("labourSalaryTypeService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadLabourSalaryTypeGet: function (laboursalarytype_keyno) {
                return p2DataTaskService.call(1423, {
                    laboursalarytype_keyno: laboursalarytype_keyno
                });
            },
            save: function (edit) {
                return p2DataTaskService.call(1424, edit);
            }
        };

        return service;
    }]);
})();
