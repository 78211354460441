(function() {
    'use strict';

    angular.module("imApp").factory("priceGridService", ['$ihttp', function($ihttp) {
        var service = {
            getPriceGrid: function (parms) {
                return $ihttp.post({
                    method: 2355,
                    parameters: parms || {}
                });
            },
            duplicateRow: function (parms) {
                return $ihttp.post({
                    method: 2395,
                    parameters: parms || {}
                });
            },
            listEditColumns: function (parms) {
                return $ihttp.post({
                    method: 2362,
                    parameters: parms || {}
                });
            },
            saveSelected: function (parms) {
                return $ihttp.post({
                    method: 2398,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
