(function () {
    'use strict';

    class CustactPlansController {

        model = {
            custactPlans: {},
            labourList: [],
            custactPlansList: [],
        }

        static $inject = ['utilityService', 'custactPlansService'];
        constructor(utilityService, custactPlansService) {
            this.utilityService = utilityService;
            this.custactPlansService = custactPlansService;
        }

        async _loadCustactPlans() {
            return this.model.custactPlans = (await this.custactPlansService.loadCustactPlans())[0];
        }

        async _loadLabourList() {
            return this.model.labourList = await this.custactPlansService.loadLabourList();
        }

        async _loadCustactPlansList() {
            if (this.model.custactPlans.labour_no?.trim()) {
                this.model.custactPlansList = await this.custactPlansService.loadCustPlansList(this.model.custactPlans);
            }
        }

        onLabourChanged(value) {
            this.model.custactPlans.labour_no = value;

            if (value) this._loadCustactPlansList();
        }

        async $onInit() {
            this._loadLabourList();
            await this._loadCustactPlans();
            this._loadCustactPlansList();
        }
    }

    let module = angular.module('imApp');

    module.component('custactPlans', {
        templateUrl: 'views/components/views/custactPlans/custactPlans.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: CustactPlansController
    });
})();
