import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '../../core/services/app-settings.service';
import { IDataTaskResponse } from './data-task.service';
import { lastValueFrom } from 'rxjs';
import { Observable } from 'rxjs';

export interface IBase64Response {
    data: string;
    fileName: string;
    fileNameWithExtension: string;
    binaryData: Blob;
}

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    constructor(private http: HttpClient, private appSettings: AppSettingsService) { }

    public async HtmlToPdf(
        htmlValue: string,
        params: {
            sourceHtml?: string,
            sourceUrl?: string,
            filename?: string,
            saveToFileServer?: boolean,
            download?: boolean,
            getBase64Data?: boolean,
            urlEncodeBase64Data?: boolean,
            pdfOptions: Object
        } = { sourceHtml: htmlValue, pdfOptions: { marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' } }, getBase64Data: true }
    ): Promise<string> {
        if (params.download) {
            const response = await this.HtmlToPdfBlob(htmlValue, params);

            this.saveFile(response.binaryData, response.fileName);

            return '';
        } else {
            const apiUrl = this.appSettings.settings.printServerUrl + '/api/pdf/htmltopdf';

            const requestBody = params ?? {
                sourceHtml: htmlValue,
                download: true,
            };

            const data$ = this.http.post(apiUrl, requestBody);

            const response = (await lastValueFrom(data$)) as IDataTaskResponse;

            return (response.data as IBase64Response).data;
        }
    }


    public async HtmlToPdfBlob(
        htmlValue: string,
        params: {
            sourceHtml?: string,
            sourceUrl?: string,
            filename?: string,
            saveToFileServer?: boolean,
            download?: boolean,
            getBase64Data?: boolean,
            urlEncodeBase64Data?: boolean,
            pdfOptions: Object
        } = { sourceHtml: htmlValue, pdfOptions: { marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' } }, getBase64Data: true }
    ): Promise<IBase64Response> {
        const requestBody = params ?? {
            filename: '',
            sourceHtml: htmlValue,
            download: true,
        };

        //requestBody.sourceHtml = '<h1>TEST TEST TEST</h1>';
        console.log('inne i printservice');
        // uuid
        if (!requestBody.filename?.trim()) requestBody.filename = 'docarc' + crypto.randomUUID();
        const data$ = this.downloadFile(this.appSettings.settings.printServerUrl + '/api/pdf/htmltopdf', requestBody);

        let response = await lastValueFrom(data$);
        console.dir(response);
        const contentDisposition = response.headers.get('Content-Disposition');

        const fileName = contentDisposition ? this.getFileNameFromContentDisposition(contentDisposition) : params.filename;

        return {
            fileName: fileName,
            fileNameWithExtension: fileName + '.pdf',
            binaryData: response.body
        } as IBase64Response;
    }

    /**
    * Reads HTML and returns it as a pdf file. If saveToFileServer is set to true, also saves the pdf on the connected fileserver.
    * @param
    * {
    *  sourceHtml: Html code that should be converted to a pdf,
    *  sourceUrl: A url to a page that should be converted to a pdf,
    *  filename: Filename for the pdf file that will be created. If not filename is set a guid will be generated as a filename,
    *  saveToFileServer: If set to true the generated pdf file will be saved/archived to the connected fileserver (not the internal fileserver),
    *  download: The "Download" and "GetBase64Data" options are mutually exclusive. The default is Download so if you set both to true then the pdf will be downloaded. If you want data returned as base64 encoded bytes then "Download" MUST be false
    *  getBase64Data: The "Download" and "GetBase64Data" options are mutually exclusive. The default is Download so if you set both to true then the pdf will be downloaded. If you want data returned as base64 encoded bytes then "Download" MUST be false.
    *                  By default data is regular base64 encoded, if it should be base64url encoded then the "UrlEncodeBase64Data" option must be set to true
    *  urlEncodeBase64Data: Uses base64url encoding for data if it is to be returned as base64 data,
    *  pdfOptions: PuppeteerSharp pdf options (https://www.puppeteersharp.com/api/PuppeteerSharp.PdfOptions.html)
    * } params
    * @returns 
    */
    public async HtmlToPdfBase64(
        htmlValue: string,
        pdfOptions: {
            displayHeaderFooter?: boolean,
            footerTemplate?: string,
            format?: {
                width: number,
                height: number
            },
            headerTemplate?: string,
            height?: string,
            landscape?: string,
            marginOptions: {
                top: string,
                right: string,
                bottom: string,
                left: string
            },
            omitBackground?: boolean,
            outline?: boolean,
            pageRanges?: string,
            preferCSSPageSize?: boolean,
            printBackground?: boolean,
            scale?: number,
            tagged?: boolean,
            width?: Object
        } = { marginOptions: { bottom: '12.7mm', top: '12.7mm', left: '12.7mm', right: '12.7mm' } },
        params: {
            sourceHtml?: string,
            sourceUrl?: string,
            filename?: string,
            saveToFileServer?: boolean,
            download?: boolean,
            getBase64Data?: boolean,
            urlEncodeBase64Data?: boolean,
            pdfOptions: Object
        } = { sourceHtml: htmlValue, pdfOptions: pdfOptions, getBase64Data: true }
    ): Promise<string> {
        const apiUrl = this.appSettings.settings.printServerUrl + '/api/pdf/htmltopdf';

        const requestBody = params ?? {
            sourceHtml: htmlValue,
            getBase64Data: true,
            pdfOptions: pdfOptions,
        };

        const data$ = this.http.post(apiUrl, requestBody);

        let response = (await lastValueFrom(data$)) as IDataTaskResponse;

        return (response.data as IBase64Response).data;
    }

    private downloadFile(apiUrl: string, requestBody: any): Observable<HttpResponse<Blob>> {
        // Perform a POST request to the API with responseType as 'blob' and observe as 'response' to get the headers
        return this.http.post(apiUrl, requestBody, { responseType: 'blob', observe: 'response' });
    }

    private saveFile(blob: Blob, fileName: string) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = fileName;

        // Append to body to make it clickable
        document.body.appendChild(a);
        a.click();

        // Clean up: remove the anchor and revoke the URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    private getFileNameFromContentDisposition(contentDisposition: string): string {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

        const matches = filenameRegex.exec(contentDisposition);

        return matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : 'downloaded-file';
    }
}
