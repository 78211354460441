(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("pohService", ['$ihttp', function ($ihttp) {
		var service = {
			getPoh: function (pohKeyNo) {
				return $ihttp.post({
					method: 1353,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			listPohComps: function (pohKeyNo) {
				// BJS 20220914 - Modified to use 1355 instead of 1335, because 1335 is obviously wrong
				//			      and the name of the 1355 procedure almost matches the name of this function
				//				  (sp_ws_p2_w_poh_pohcomp_list) and expects the correct parameter.
				return $ihttp.post({
					method: 1355,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			listPohPos: function (pohKeyNo) {
				return $ihttp.post({
					method: 1993,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			cancelCaseQtyOl: function (pohKeyNo) {
				return $ihttp.post({
					method: 2321,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			cancelCaseQty: function (pohKeyNo) {
				return $ihttp.post({
					method: 2322,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			updatePoReqQty: function (pohKeyNo) {
				return $ihttp.post({
					method: 2323,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			delete: function (pohKeyNo) {
				return $ihttp.post({
					method: 2427,
					parameters: {
						poh_keyno: pohKeyNo
					}
				});
			},
			getStatusBtnState: function (pohKeyNo) {
				return $ihttp.post({
					method: 2440,
					parameters: {
						state: 'current',
						poh_keyno: pohKeyNo
					}
				});
			},
			updatePoStatus: function (pohKeyNo) {
				return $ihttp.post({
					method: 2440,
					parameters: {
						state: 'update',
						poh_keyno: pohKeyNo
					}
				});
            },
            createPoFinish: function (pohKeyNo) {
                return $ihttp.post({
                    method: 3206,
                    parameters: {
                        poh_keyno: pohKeyNo
                    }
                });
            }
		};

		return service;
	}]);
})();
