(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCrewMenuService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCrewMenuGet: function (resource_id, srcrew_keyno) {
                return p2DataTaskService.call(1773, {
                    resource_id: resource_id,
                    srcrew_keyno: srcrew_keyno
                });
            },
            loadSrCrewMenuBelongToList: function (srcrew_keyno) {
                return p2DataTaskService.call(1774, {
                    srcrew_keyno: srcrew_keyno
                });
            }
        };

        return service;
    }]);
})();