(function() {
    'use strict';

    angular.module("imApp").factory("jobqueueService", ['$ihttp', function ($ihttp) {
        var service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3401,
                    parameters: parms || {}
                });
            },
            load: function () {
                return $ihttp.post({
                    method: 2287,
                    parameters: {}
                });
            }
        }

        return service;
    }]);
})();
