(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactAcMatch', {
        templateUrl: 'views/components/views/custactAcMatch/custactAcMatch.template.html?v=' + module.version,
        bindings: {
            modalInstance: '<?',
            resolve: '<?'
        },
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'modalService', 'translateService', 'custactAcMatchService', function ($stateParams, $q, modalService, translateService, custactAcMatchService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.isPopupOrModal = false;
            let custactAcKeyno = $stateParams.custactac_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                gridReady: false,
                lockedApprove: false,
                distributing: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'custactac_match_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false,
                        readAfterSave: true,
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                custactAcMatchService.getSetting({ custactac_keyno: custactAcKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadGrid()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3200,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.approveChanges = function () {
                vm.model.lockedApprove = true;

                custactAcMatchService.approveObj(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedApprove = false;

                        loadSetting();

                        if (window.location.hash.includes('ispopup')) {
                            window.opener.postMessage('popupClosed');
                            setTimeout(() => window.close(), 0);
                        }
                    }
                });
            };

            vm.unapprovedChanges = function () {
                vm.model.lockedUnApproved = true;

                custactAcMatchService.unapproveObj(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedUnApproved = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedUnApproved = false;

                        loadSetting();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.distributeChanges = function () {
                vm.model.distributing = true;

                custactAcMatchService.distributeToGrid(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.distributing = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.distributing = false;
                        loadGrid();
                    }
                });
            };

            vm.gotoSettings = function () {

		//stateService.go('webpagename', {
		//	argtype: vm.model.setting.argtype,
		//	argvalue: vm.model.setting.argvalue
		//});

            }

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                if (vm.resolve?.custactAcKeyno) {
                   
                    custactAcKeyno = vm.resolve?.custactAcKeyno;
                }

                if (vm.modelInstance !== null && vm.modalInstance !== undefined) {
                    vm.grid.kendo.height = null;
                } else {
                    vm.grid.kendo.height = '100%';
                }

                if (vm.resolve || window.location.hash.includes('ispopup')) {
                    vm.isPopupOrModal = true;
                }

                loadSetting();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
