(function () {
    'use strict';

    var imApp = angular.module('imApp');

    //imApp.component('ttOverflowMenuItem', {
    //    transclude: true,
    //    require: {
    //        overflowMenuController: '^ttOverflowMenu'
    //    },
    //    template: `
    //        <li id="{{vm.id.button}}" class="tt-overflow-item" ng-click="vm.toggleMenu()">
    //            <button ng-class="{ 'tt-overflow-item__button--danger': vm.ttDanger === 'true' }" class="tt-overflow-item__button" ng-click="vm.click()" ng-style="vm.style.button">
    //                <span>{{vm.translations.ttText}}</span>
    //                <span ng-if="vm.ttIcon !== undefined && vm.ttIcon !== null && vm.ttIcon.length > 0" ng-class="vm.ttIcon"></span>
    //            </button>
    //        </li>
    //    `,
    //    controllerAs: 'vm',
    //    bindings: {
    //        ttIcon: '@?',
    //        ttText: '@?',
    //        ttClick: '&',
    //        ttDanger: '@?'
    //    },
    //    controller: ['$element', 'layoutService', 'translateService', function ($element, layoutService, translateService) {
    //        const vm = this;
    //        vm.open = false;
    //        vm.style = {
    //            button: {}
    //        };

    //        vm.translations = {
    //            ttText: '',
    //        }

    //        vm.toggleMenu = function () {
    //            vm.open = !vm.open;
    //        }

    //        vm.click = function () {
    //            console.log('click');
    //            console.dir(vm.overflowMenuController);
    //            if (vm.ttClick && typeof vm.ttClick === 'function') {
    //                vm.ttClick();
    //            }
    //            //if (vm.overflowMenuController) {
    //            //    vm.overflowMenuController.closeMenu();
    //            //}
    //        }

    //        vm.id = {
    //            button: crypto.randomUUID(),
    //        }

    //        vm.$onInit = function () {
    //            layoutService.onLayoutChanged([], (info) => {
    //                vm.style.button.fontSize = info.fontSizes.textSize;
    //            });
    //        }

    //        vm.$onChanges = async function (changes) {
    //            if (changes?.ttText?.currentValue && typeof changes.ttText.currentValue === 'string' && changes.ttText.currentValue !== changes.ttText.previousValue) {
    //                vm.translations.ttText = await translateService.translate(changes.ttText.currentValue)
    //            }
    //        }

    //    }]
    //});

    //imApp.component('ttOverflowMenu', {
    //    // Any actions to display next to the comment is given through transclude.
    //    transclude: true,
    //    template: `
    //        <button id="{{vm.id.button}}" ng-class="{'tt-overflow-menu__button--open': vm.open === true }" class="tt-button tt-overflow-menu__button" ng-click="vm.toggleMenu()" ng-style="vm.style.button">
    //            <span ng-style="vm.style.icon" class="fa-fw far fa-ellipsis-v"></span>
    //            <ul ng-if="vm.open" class="tt-overflow__menu" ng-transclude></ul>
    //        </button>
    //    `,
    //    controllerAs: 'vm',
    //    bindings: {
    //        ttIcon: '@?',
    //    },
    //    controller: ['$element', 'layoutService', function ($element, layoutService) {
    //        const vm = this;
    //        vm.open = false;
    //        vm.toggleMenu = function () {
    //            vm.open = !vm.open;
    //        }

    //        vm.closeMenu = function () {
    //            vm.open = false;
    //        }

    //        vm.id = {
    //            button: crypto.randomUUID(),
    //        }

    //        vm.style = {
    //            button: {},
    //            icon: {},
    //        }


    //        /**
    //         * Checks whether the given element or its children is the given event target.
    //         * 
    //         * @param {HTMLElement} element the element and its children to check.
    //         * @param {EventTarget} target the target from an event to search for.
    //         * @returns true if the given element or any of its children is the event target, false if not.
    //         */
    //        function isTargetInElement(element, target) {
    //            if (element === target) return true;

    //            for (let i = 0; i < element?.children?.length; i++) {
    //                let child = element.children[i];
    //                let found = isTargetInElement(child, target);

    //                if (found) {
    //                    return true;
    //                }
    //            };

    //            return false;
    //        }

    //        function handeMenuClick(event) {
    //            const menu = document.getElementById(vm.id.button);

    //            if (!menu || isTargetInElement(menu, event.target) === false) {
    //                vm.open = false;
    //            }
    //        }

    //        vm.$onInit = function () {
    //            document.addEventListener('click', handeMenuClick);
    //            layoutService.onLayoutChanged([], (info) => {
    //                vm.style.icon.fontSize = info.fontSizes.textSize;
    //            });
    //        }

    //        vm.$onChanges = function (changes) {

    //        }

    //        vm.$onDestroy = function () {
    //            document.removeEventListener('click', handeMenuClick);
    //        }
    //    }]
    //});


    ///**
    // * @author JLR
    // * @version 20231215
    // */

    ///**
    // * @typedef {Object} CommentProps represents the properties of the ttComment component.
    // * @property {string} ttAuthor the displayed name of the author of the comment.
    // * @property {string?} ttDatetime the date and time of the comment was created.
    // * @property {string} ttModel the content of the comment.
    // * @property {'1' | '0'} ttIsCurrentUser whether the author of the comment is the current user.
    // */

    //imApp.component('ttComment', {
    //    transclude: true,
    //    template: `
    //        <div class="col-xs-12 sp-0 tt-comment" ng-class="{ 'tt-comment--current-user': vm.ttIsCurrentUser === '1'}" >
    //            <div class="tt-comment__actions" ng-if="vm.ttIsCurrentUser === '1'" ng-transclude></div>
    //            <article class="tt-comment__article">
    //                <h3 class="tt-input__label" style="font-size: 10px; width: fit-content;"><span ng-if="vm.ttAuthor !== null && vm.ttAuthor !== undefined">{{vm.ttAuthor}}</span> <span ng-if="vm.ttDatetime !== null && vm.ttDatetime !== undefined" class="tt-input__sublabel">- {{vm.ttDatetime}}</span></h3>
    //                <div id="{{vm.id.content}}" class="tt-comment__content"></div>
    //            </article>
    //        </div>
    //    `,

    //    controllerAs: 'vm',
    //    /** @type CommentProps */
    //    bindings: {
    //        ttAuthor: '@',
    //        ttDatetime: '@?',
    //        ttModel: '<',
    //        ttIsCurrentUser: '<',
    //    },
    //    controller: [function () {
    //        const vm = this;

    //        vm.itemClick = function () {
    //            console.dir('menu-item-clicked');
    //        }

    //        vm.id = {
    //            content: crypto.randomUUID(),
    //        }
    //        let setInnerHtml = function (htmlString) {

    //            //$timeout(function () {
    //            setTimeout(() => {
    //                let element = document?.getElementById(vm.id.content);

    //                if (element) {
    //                    element.innerHTML = htmlString;
    //                }
    //            })
    //            //}, 0);
    //        }

    //        vm.$onChanges = function (changes) {
    //            console.dir(changes);
    //            if (changes?.ttModel?.currentValue && typeof changes.ttModel.currentValue === 'string') {
    //                setInnerHtml(changes?.ttModel.currentValue);
    //            }
    //        }

    //    }]
    //});

    imApp.component('custactDialogs', {
        templateUrl: 'views/components/views/custactDialogs/custactDialogs.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'base64', 'modalService', 'custactDialogsService', 'economyService', 'translateService', '$uibModal', function ($stateParams, stateService, utilityService, base64, modalService, custactDialogsService, economyService, translateService, $uibModal) {

            // #region VARIABLES & DEFINITIONS
            var vm = this;

            vm.options = {
                keynames: {
                    commentKeyno: 'custactdialog_keyno',
                },
                fetch: {
                    save: {
                        method: 108,
                        parameters: () => ({
                            custact_no: vm.model.setting.custact_no,
                            note: vm.model.setting.note,
                            messagetext: base64.urlencode(vm.model.setting.messagetext),
                            actstatus_keyno: vm.model.setting.actstatus_keyno,
                            activitytype_no: vm.model.setting.activitytype_no,
                            ok: vm.model.setting.ok
                        })
                    },
                    get: {
                        method: 107,
                        parameters: { custact_no: $stateParams.custact_no }
                    },
                    delete: {
                        method: 3001
                    }
                },
            };

            var custactNo = $stateParams.custact_no;

            vm.isHtml = true;

            vm.deleteComment = async function (custactdialogKeyno) {
                if (custactdialogKeyno) {
                    await custactDialogsService.deleteCustactDialog(custactdialogKeyno);
                    vm.refreshCustactDialogs();
                }
            }


            vm.custact_dialogs = {
                registered_by_name: '',
                messagetext: '',
                mymessage: '',
                orderby: '',
                reg_datetime: '',
                records: []
            };

            var translations = {
                custactdialogs_add_show_title: 'Varsel',
                custactdialogs_add_show_ok_label: 'OK'
            };


            vm.goToFunc = function (state) {
                switch (state) {
                    case 'workdetails':
                        stateService.go(state, {
                            custact_no: vm.model.setting.custact_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                heading: stateService.getCurrentName() === 'custactdialogs' ? 'Kommentarer for Akse 8 sjøarbeid' : '',
                noteButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'workdetails' } }
                ],
                setting: {},
                selectListActivityStatuses: [],
                commentListCustactDialogues: [],
                readyMessageText: true,
                lockedAdd: false,
                refreshingDialogues: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            custactDialogsService.getCustactDialogs({ custact_no: custactNo }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

                loadActivityStatuses();
            });

            var loadActivityStatuses = function () {
                if (utilityService.validateParmsValue(vm.model.setting.activitytype_no) !== true) return;

                economyService.listActivityStatuses({ activitytype_no: vm.model.setting.activitytype_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListActivityStatuses);
                });
            };

            vm.openDialog = function () {
                let newoption = { view: {modal: true},...vm.options };
                $uibModal.open({
                    component: 'ttDialog',
                    windowClass: 'tt-test',
                    size: 'lg',
                    resolve:  newoption
                });
            }

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            vm.refreshCustactDialogs = function () {
                vm.model.refreshingDialogues = true;

                custactDialogsService.listCustactDialogues({ custact_no: custactNo }).then(function (response) {
                    vm.model.refreshingDialogues = false;
                    angular.copy(response, vm.model.commentListCustactDialogues);
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.addNewComment = function () {
                vm.model.lockedAdd = true;

                var editObj = {
                    custact_no: vm.model.setting.custact_no,
                    note: vm.model.setting.note,
                    messagetext: base64.urlencode(vm.model.setting.messagetext),
                    actstatus_keyno: vm.model.setting.actstatus_keyno,
                    activitytype_no: vm.model.setting.activitytype_no,
                    ok: vm.model.setting.ok
                };

                custactDialogsService.addObj(editObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.custactdialogs_add_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.custactdialogs_add_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedAdd = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedAdd = false;
                        vm.model.setting.messagetext = '';

                        vm.refreshCustactDialogs();
                    }
                });
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.$onInit = function () {
                vm.refreshCustactDialogs();
            }
        }]
    });
})();
