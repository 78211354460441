(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productPics', {
        templateUrl: 'views/components/views/productPics/productPics.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productPicsService', function ($stateParams, stateService, productPicsService) {
            let vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                itemsListProductPictures: []
            };

            let loadSetting = function () {
                productPicsService.listProductPictures({ prod_id: prodId }).then(function (result) {
                    angular.copy(result, vm.model.itemsListProductPictures);
                });
            };
    		
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'productpic':
                        go({
                            productpicturebank_keyno: '0',
                            prod_id: prodId
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
