(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchPrice', {
        templateUrl: 'views/components/views/purchPrice/purchPrice.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'utilityService', 'modalService', 'translateService', 'purchPriceService', 'logisticService', 'economyService', 'valutaService', function ($stateParams, $timeout, stateService, utilityService, modalService, translateService, purchPriceService, logisticService, economyService, valutaService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodSizeKeyno = $stateParams.prodsize_keyno;
            let purchPriceKeyno = $stateParams.purchprice_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                purchPriceKeyno: purchPriceKeyno,
                twoDecimalsOption: {
                    decimals: 2
                },
                fourDecimalsOption: {
                    decimals: 4
                },
                inCalc: false, //obsolete
                lastMethodId: 'notset',
                lastPricelistId: 'notset',
                prodIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                supplierButtons: [
                    { item_id: '0', item_name: 'goto', item_func: 'goto_item', glyph: 'glyphicon-chevron-right', icon: 'fas fa-chevron-right', color: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.supplier_no }) }
                ],
                edit: {},
                selectListSuppliers: [],
                selectListCurrencies: [],
                selectListPrices: [],
                selectListPriceMethods: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            //##################
            //## LOAD PROCEDURES
            //##################

            let loadEdit = function () {
                purchPriceService.getEdit({
                    prodsize_keyno: prodSizeKeyno,
                    purchprice_keyno: purchPriceKeyno
                }).then(function (info) {
                    vm.model.edit = angular.copy(info[0]);
                    vm.model.edit.pricenow_org = vm.model.edit.pricenow;
                    vm.model.edit.fullprice_locval_org = vm.model.edit.fullprice_locval;
                });
            };

            let loadSuppliers = function () {
                logisticService.listSuppliers().then(function (list) {
                    angular.copy(list, vm.model.selectListSuppliers);
                });
            };

            let loadCurrencies = function () {
                economyService.listCurrencies().then(function (list) {
                    angular.copy(list, vm.model.selectListCurrencies);
                });
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (list) {
                    angular.copy(list, vm.model.selectListPrices);
                });
            };

            let loadPriceMethods = function () {
                economyService.listPriceMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListPriceMethods);
                });
            };

            let updateSalesPrice = function () {
                return purchPriceService.getSalesPrice(vm.model.edit).then(function (response) {
                    vm.model.edit.pricelist_tax_pst = response[0].pricelist_tax_pst;
                    vm.model.edit.pricenow = response[0].pricenow;

                    if (vm.model.edit.fullprice_valuta_id !== response[0].pricelist_valuta_id) {
                        vm.model.edit.fullprice_valuta_id = response[0].pricelist_valuta_id;

                        if (vm.model.edit.fullprice_valuta_id > '') {
                            retrieveCurrencyEdit();
                        } else {
                            callCalcPriceNow();
                        }
                    } else {
                        callCalcPriceNow();
                    }
                });
            };

            let retrieveCurrencyEdit = function () {
                if (utilityService.validateParmsValue(vm.model.edit.fullprice_valuta_id) !== true) return;

                return valutaService.getValutaEdit({ valuta_id: vm.model.edit.fullprice_valuta_id }).then(function (response) {
                    vm.model.edit.fullprice_valuta_exchangerate = response[0].valuta_exchangerate;
                    vm.model.edit.fullprice_valuta_exchangefactor = response[0].valuta_exchangefactor;

                    callCalcPriceNow();
                });
            };

            // ################
            // RECALC FUNCTIONS
            // ################

            let callCalcPriceNow = function () {
                if (!vm.model.inCalc) {
                    vm.model.inCalc = true;
                    calcPriceNew();

                    $timeout(function () {
                        vm.model.inCalc = false;
                    }, 10);
                }
            };

            let calcPriceNew = function () {
                switch (vm.model.edit.pricemethod_id) {
                    case '1': { // cost + pst
                        vm.model.edit.pricenow_new = (utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)) * (1 + (utilityService.parseNumber(vm.model.edit.calc_margin_pst) / 100))) * (1 + utilityService.parseNumber(vm.model.edit.pricelist_tax_pst) / 100);
                        vm.model.edit.calc_factor = utilityService.parseNumber(utilityService.parseNumber(vm.model.edit.pricenow_new) / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)));
                        break;
                    }
                    case '2': { // cost * factor
                        vm.model.edit.pricenow_new = utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)) * utilityService.parseNumber(vm.model.edit.calc_factor) * (1 + utilityService.parseNumber(vm.model.edit.pricelist_tax_pst) / 100);
                        vm.setFullPriceLocVal();
                        vm.model.edit.calc_margin_pst = Math.round(utilityService.parseNumber((utilityService.parseNumber(vm.model.edit.pricenow_new) - utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval))) * 100 / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval))));
                        break;
                    }
                    case '3': { // indicative
                        vm.model.edit.pricenow_new = utilityService.parseNumber(utilityService.parseNumber(vm.locValExchanged(vm.model.edit.fullprice_val * vm.model.edit.valuta_exchangefactor)) * (1 + utilityService.parseNumber(vm.model.edit.pricelist_tax_pst) / 100));
                        vm.model.edit.calc_factor = utilityService.parseNumber(utilityService.parseNumber(vm.model.edit.fullprice_locval) / utilityService.parseNumber(vm.model.edit.price_locval)); //bhw
                        vm.setFullPriceLocVal();
                        vm.model.edit.calc_margin_pst = utilityService.parseNumber((utilityService.parseNumber(vm.model.edit.pricenow_new) - utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval))) * 100 / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)));
                        break;
                    }
                    case '4': { // manual
                        if ((vm.lastMethodId != vm.model.edit.pricemethod_id) || (vm.lastPricelistId != vm.model.edit.pricelist_id)) {
                            vm.model.edit.pricenow_new = vm.locValExchanged(vm.model.edit.pricenow);
                        }

                        vm.model.edit.calc_factor = utilityService.parseNumber(utilityService.parseNumber(vm.model.edit.fullprice_locval) / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval))); //bhw
                        vm.setFullPriceLocVal();
                        vm.model.edit.calc_margin_pst = utilityService.parseNumber((utilityService.parseNumber(vm.model.edit.fullprice_locval) - utilityService.parseNumber(vm.model.edit.price_locval)) * 100 / utilityService.parseNumber(vm.model.edit.price_locval));//bhw
                        break;
                    }
                    case '5': { // coverage margin
                        // NB! Dekningsgrad må være mellom <0,100>
                        let fullCost = vm.model.edit.sb_price_locval ?? vm.model.edit.price_locval;
                        vm.model.edit.pricenow_new = utilityService.parseNumber(fullCost) / (1 - (vm.model.edit.db_pst / 100));
                        vm.model.edit.calc_factor = utilityService.parseNumber(utilityService.parseNumber(vm.model.edit.pricenow_new) / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)));
                        break;
                    }
                    default: {
                        vm.model.edit.calc_factor = utilityService.parseNumber(utilityService.parseNumber(vm.model.edit.fullprice_locval) / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)));//bhw
                        vm.setFullPriceLocVal();
                        vm.model.edit.calc_margin_pst = utilityService.parseNumber((utilityService.parseNumber(vm.model.edit.fullprice_locval) - utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval))) * 100 / utilityService.parseNumber(vm.locValExchanged(vm.model.edit.price_locval)));//bhw
                    }
                }

                vm.model.edit.price_change_pst = utilityService.round(utilityService.parseNumber(((vm.model.edit.pricenow_new - vm.model.edit.pricenow) * 100) / vm.model.edit.pricenow),2);
                const newPrice = (utilityService.parseNumber(vm.model.edit.pricenow_new) * utilityService.parseNumber(vm.model.edit.fullprice_valuta_exchangefactor));
                vm.model.edit.db_locval = utilityService.round(utilityService.parseNumber(newPrice) - utilityService.parseNumber(vm.model.edit.price_locval),2); //JGH
                if (vm.model.edit.pricemethod_id !== '5') {
                    vm.model.edit.db_pst = utilityService.parseNumber(utilityService.parseNumber(vm.model.edit.db_locval) * 100 / newPrice); //JGH
                }
                vm.lastMethodId = vm.model.edit.pricemethod_id;
                vm.lastPricelistId = vm.model.edit.pricelist_id;
            };
            
            vm.setDbPst = function () {
                if (vm.model.edit.pricenow_new !== '0') {
                    var newPrice = (utilityService.parseNumber(vm.model.edit.pricenow_new) * utilityService.parseNumber(vm.model.edit.fullprice_valuta_exchangefactor));

                    vm.model.edit.db_locval = utilityService.parseNumber(newPrice) - utilityService.parseNumber(vm.model.edit.price_locval); //JGH

                    vm.model.edit.db_pst = (utilityService.parseNumber(vm.model.edit.db_locval) * 100 / newPrice); //JGH
                } else {
                    vm.model.edit.db_pst = '0';
                }
            };
            
            let updateCurrency = function () {
                if (utilityService.validateParmsValue(vm.model.edit.valuta_id) !== true) return;

                return purchPriceService.changeCurrency({ valuta_id: vm.model.edit.valuta_id }).then(function (response) {
                    vm.model.edit.valuta_exchangerate = response[0].valuta_exchangerate;
                    vm.model.edit.valuta_exchangefactor = response[0].valuta_exchangefactor;
                    changedValutaExchangerate();
                });
            };
            
            let changedValutaExchangerate = function () {
                vm.model.edit.price_locval = utilityService.parseNumber(vm.model.edit.price_val) * utilityService.parseNumber(vm.model.edit.valuta_exchangefactor);
                calcPriceNew();
            };
            
            vm.locValExchanged = function (val) {
                return utilityService.parseNumber(val) / utilityService.parseNumber(vm.model.edit.fullprice_valuta_exchangefactor);
            };
            
            vm.setFullPriceLocVal = function () {
                var newPrice = (utilityService.parseNumber(vm.model.edit.pricenow_new) * utilityService.parseNumber(vm.model.edit.fullprice_valuta_exchangefactor));
                vm.model.edit.fullprice_locval = utilityService.parseNumber(newPrice / (1 + (utilityService.parseNumber(vm.model.edit.pricelist_tax_pst) / 100)));
                vm.model.edit.pricenow = utilityService.parseNumber(vm.model.edit.pricenow_org) / utilityService.parseNumber(vm.model.edit.fullprice_valuta_exchangefactor);
            };

            // ####################
            // SAVE BUTTON FUNCTION
            // ####################

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                purchPriceService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    updatePurchasePrice();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // ######################
            // UPDATE BUTTON FUNCTION
            // ######################

            let updatePurchasePrice = function () {
                return purchPriceService.changePurchasePrice(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    purchPriceService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'price_val':
                        if (utilityService.validateParmsValue(vm.model.edit.price_val, true) !== true) return;

                        vm.model.edit.fullprice_val = utilityService.parseNumber(vm.model.edit.price_val) / (1 - (utilityService.parseNumber(vm.model.edit.purch_discount ?? 0) / 100));
                        vm.model.edit.price_locval = utilityService.parseNumber(vm.model.edit.price_val) * utilityService.parseNumber(vm.model.edit.valuta_exchangefactor);
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            vm.onValueChange = function (key, value) {
                switch (key) {
                    case 'pricelist_id': {
                        if (vm.model.edit.pricelist_id !== value) {
                            vm.model.edit.pricelist_id = value;
                            updateSalesPrice();
                        }
                        break;
                    }
                    case 'supplier_no': {
                        vm.model.edit.supplier_no = value;
                    }
                    case 'valuta_exchangerate': {
                        if (vm.model.edit.valuta_exchangerate !== value) {
                            vm.model.edit.valuta_exchangerate = value;
                            changedValutaExchangerate();
                        }
                        break;
                    }
                    case 'valuta_id': {
                        if (vm.model.edit.valuta_id !== value) {
                            vm.model.edit.valuta_id = value;
                            updateCurrency();
                        }
                        break;
                    }
                    case 'fullprice_val': {
                        if (vm.model.edit.fullprice_val !== value) {
                            vm.model.edit.fullprice_val = value;
                            vm.model.edit.price_val = utilityService.parseNumber(vm.model.edit.fullprice_val) * (1 - (utilityService.parseNumber(vm.model.edit.purch_discount ?? 0) / 100));
                        }
                        break;
                    }
                    case 'purch_discount': {
                        if (vm.model.edit.purch_discount !== value) {
                            vm.model.edit.purch_discount = value;
                            vm.model.edit.price_val = utilityService.parseNumber(vm.model.edit.fullprice_val) * (1 - (utilityService.parseNumber(vm.model.edit.purch_discount ?? 0) / 100));
                        }
                        break;
                    }
                    case 'pricenow_new': {
                        if (vm.model.edit.pricenow_new !== value) {
                            vm.model.edit.pricenow_new = value;
                            callCalcPriceNow();
                        }
                        break;
                    }
                    case 'pricemethod_id': {
                        if (vm.model.edit.pricemethod_id !== value) {
                            vm.model.edit.pricemethod_id = value;
                            callCalcPriceNow();
                        }
                        break;
                    }
                    case 'calc_margin_pst': {
                        if (vm.model.edit.calc_margin_pst !== value) {
                            vm.model.edit.calc_margin_pst = value;
                            callCalcPriceNow();
                        }
                        break;
                    }
                    case 'calc_factor': {
                        if (vm.model.edit.calc_factor !== value) {
                            vm.model.edit.calc_factor = value;
                            callCalcPriceNow();
                        }
                        break;
                    }
                    case 'db_pst': {
                        if (vm.model.edit.db_pst !== value) {
                            vm.model.edit.db_pst = value;
                            callCalcPriceNow();
                        }
                        break;
                    }
                    default: break;
                }
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadSuppliers();
                loadCurrencies();
                loadPrices();
                loadPriceMethods();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
