import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CoreComponentService, Style, TextAlign } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.css'],
})
export class ButtonComponent extends ComponentBaseComponent implements OnInit, OnChanges {
    /**
     * The text to display in the button.
     */
    @Input()
    public ttText: string = '';

    /**
     * Whether to translate the text to display in the button, default is `'true'`.
     */
    @Input()
    public get ttTranslateText(): boolean {
        return this._translateText;
    }
    public set ttTranslateText(value: BooleanInput) {
        this._translateText = coerceBooleanProperty(value);
    }
    private _translateText = true;

    /**
     * Text to display in tooltip when hovering the button, can be used as helper text or to make an icon button accessible.
     */
    @Input()
    public ttTooltipText: string = '';

    /**
     * Whether the tooltip text should be translated or not, default is `true`.
     */
    @Input()
    public get ttTranslateTooltipText(): boolean {
        return this._translateTooltipText;
    }
    public set ttTranslateTooltipText(value: BooleanInput) {
        this._translateTooltipText = coerceBooleanProperty(value);
    }
    private _translateTooltipText = true;

    /**
     * The text align for the text in the button.
     */
    @Input()
    public ttTextAlign?: TextAlign;

    /**
     * The variant of the button, default is `'primary'`.
     */
    @Input()
    public ttType: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'link' = 'primary';

    /**
     * The icon classes to apply to the button.
     */
    @Input()
    public ttIcon: string = '';

    /**
     * Which side of the button to display the text on.
     */
    @Input()
    public ttIconAlign: 'left' | 'right' = 'right';

    /**
     * Whether to apply the icon to the far right of the button.
     */
    @Input()
    public get ttIconPullRight(): boolean {
        return this._iconPullRight;
    }
    public set ttIconPullRight(value: BooleanInput) {
        this._iconPullRight = coerceBooleanProperty(value);
    }
    private _iconPullRight = false;

    /**
     * Text to display in a badge on the button.
     */
    @Input()
    public ttBadge: string = '';

    /**
     * Whether to spin the current icon.
     */
    @Input()
    public get ttSpin(): boolean {
        return this._spin;
    }
    public set ttSpin(value: BooleanInput) {
        this._spin = coerceBooleanProperty(value);
    }
    private _spin = false;

    /**
     * Whether to display a loading variant of the button.
     */
    @Input()
    public get ttLoading(): boolean {
        return this._loading;
    }
    public set ttLoading(value: BooleanInput) {
        this._loading = coerceBooleanProperty(value);
    }
    private _loading = false;

    /**
     * The text to display while loading, default is `'locked'`.
     */
    @Input()
    public ttLoadingText: string = 'locked';

    /**
     * Whether to translate the current loading text, default is `'true'`.
     */
    @Input()
    public get ttTranslateLoadingText(): boolean {
        return this._translateLoadingText;
    }
    public set ttTranslateLoadingText(value: BooleanInput) {
        this._translateLoadingText = coerceBooleanProperty(value);
    }
    private _translateLoadingText = true;

    /**
     * Event emitted when the button is clicked.
     */
    @Output()
    public ttClick = new EventEmitter<MouseEvent>();

    /**
     * The html attribute type of the button, defualt is `'button'`.
     */
    @Input()
    public ttButtonType: 'button' | 'submit' | 'revert' = 'button';

    /**
     * Whether the buttons is disabled.
     */
    @Input()
    public get ttDisabled(): boolean {
        return this._disabled;
    }
    public set ttDisabled(value: BooleanInput) {
        this._disabled = coerceBooleanProperty(value);
    }
    private _disabled = false;

    /**
     * Displays the given printer text instead of `ttText` and changes styling.
     */
    @Input()
    public ttPrinter: string = '';

    /**
     * Custom styling to apply to the component.
     */
    @Input()
    public ttStyle?: Style;

    /**
     * Styling of elements in the component.
     */
    public style: Style = {
        button: {},
        lockedButton: {},
        icon: {},
        badge: {},
    };

    /**
     * Translations of words used in the component.
     */
    public translations: { [key: string]: string } = {
        text: '',
        loadingtext: '',
        title: '',
    };

    constructor(public layoutService: LayoutService, private translateService: TranslateService, private coreComponentService: CoreComponentService) {
        super();
        layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                coreComponentService.setLayoutStyle(this.style, info);
                this.setStyle();
            }
        });
    }

    /**
     * Sets the styling of the component.
     *
     * @param ttStyle custom styling to apply.
     */
    public setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, textAlign: this.ttTextAlign, mainElement: ['button', 'lockedButton'] });
    }

    /**
     * Sets the given text to the displayed button text.
     *
     * @param text the text to apply.
     */
    private async setText(text: string) {
        if (!!text && typeof text === 'string') {
            if (this.ttTranslateText) {
                this.translations['text'] = await this.translateService.translate(text);
            } else {
                this.translations['text'] = text;
            }
        }
    }

    /**
     * Sets the given loading text to the loading variant of the button.
     *
     * @param loadingText the loading text to apply.
     */
    private async setLoadingText(loadingText: string) {
        if (!!loadingText && typeof loadingText === 'string') {
            if (this.ttTranslateLoadingText) {
                this.translations['loadingtext'] = await this.translateService.translate(loadingText);
            } else {
                this.translations['loadingtext'] = loadingText;
            }
        }
    }

    private async setTooltipText(tooltiptext: string) {
        if (!!tooltiptext && typeof tooltiptext === 'string') {
            if (this.ttTranslateTooltipText) {
                this.translations['tooltiptext'] = await this.translateService.translate(tooltiptext);
            } else {
                this.translations['tooltiptext'] = tooltiptext;
            }
        }
    }

    ngOnInit(): void {
        this.setStyle();
        this.setLoadingText(this.ttLoadingText);
        this.setTooltipText(this.ttTooltipText);
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (!!changes?.['ttText']?.currentValue && changes['ttText'].currentValue !== changes['ttText'].previousValue) {
            this.setText(changes['ttText'].currentValue);
        }

        if (!!changes?.['ttTooltipText']?.currentValue && changes['ttTooltipText'].currentValue !== changes['ttTooltipText'].previousValue) {
            this.setTooltipText(changes['ttTooltipText'].currentValue);
        }

        if (!!changes?.['ttLoadingText']?.currentValue && changes['ttLoadingText'].currentValue !== changes['ttLoadingText'].previousValue) {
            this.setLoadingText(changes['ttLoadingText'].currentValue);
        }

        if (!!changes?.['ttLoading']) {
            this.ttLoading = changes['ttLoading'].currentValue;
        }

        if (!!changes?.['ttSpin']) {
            this.ttSpin = changes['ttSpin'].currentValue;
        }

        if (!!changes?.['ttIconPullRight']) {
            this.ttIconPullRight = changes['ttIconPullRight'].currentValue;
        }

        if (!!changes?.['ttDisabled']) {
            this.ttDisabled = changes['ttDisabled'].currentValue;
        }

        if (!!changes?.['ttStyle']?.currentValue) {
            this.setStyle(changes['ttStyle'].currentValue);
        }
    }
}
