(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('interBranchInvoiceLine', {
        templateUrl: 'views/components/views/interBranchInvoiceLine/interBranchInvoiceLine.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'interBranchInvoiceLineService', function ($stateParams, stateService, utilityService, interBranchInvoiceLineService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let interBranchInvoiceLineKeyno = $stateParams.interbranchinvoiceline_keyno;
    		
            vm.model = {
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_id }) }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURES

            let loadEdit = function () {
                interBranchInvoiceLineService.getEdit({ interbranchinvoiceline_keyno: interBranchInvoiceLineKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURES

            // #region RECALCULATION FUNCTIONS

            let calcInvoiceAmount = function () {
                vm.model.edit.to_invoice_amount = utilityService.parseNumber(vm.model.edit.delivered_quantity) * utilityService.parseNumber(vm.model.edit.ib_price) * ((100 - utilityService.parseNumber(vm.model.edit.discount_pst)) / 100);
            };
            
            let calcMarginLocVal = function () {
                vm.model.edit.margin_locval = utilityService.parseNumber(vm.model.edit.to_invoice_amount) - utilityService.parseNumber(vm.model.edit.cost_amount_locval);
            };
            
            let calcMarginPst = function () {
                if (vm.model.edit.to_invoice_amount === '0') {
                    vm.model.edit.margin_pst = '0.00';
                } else {
                    vm.model.edit.margin_pst = (utilityService.parseNumber(vm.model.edit.margin_locval) * 100) / utilityService.parseNumber(vm.model.edit.to_invoice_amount);
                }
            };
            
            let calcReceiversMarginPst = function () {
                if (vm.model.edit.receivers_amount_on_orderline === '0') {
                    vm.model.edit.receivers_margin_pst = '0.00';
                } else {
                    vm.model.edit.receivers_margin_pst = ((utilityService.parseNumber(vm.model.edit.receivers_amount_on_orderline) - utilityService.parseNumber(vm.model.edit.to_invoice_amount)) * 100) / utilityService.parseNumber(vm.model.edit.receivers_amount_on_orderline);
                }
            };

            // #endregion RECALCULATION FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                interBranchInvoiceLineService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'ib_price':
                    case 'discount_pst':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        calcInvoiceAmount();
                        break;
                    case 'to_invoice_amount':
                        if (utilityService.validateWatchValue(value, vm.model.edit.to_invoice_amount) !== true) return;

                        vm.model.edit.to_invoice_amount = value;

                        if (utilityService.validateParmsValue(vm.model.edit.to_invoice_amount, true) !== true) return;

                        calcMarginLocVal();
                        calcReceiversMarginPst();
                        break;
                    case 'margin_locval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.margin_locval) !== true) return;

                        vm.model.edit.margin_locval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.margin_locval, true) !== true) return;

                        calcMarginPst();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
