(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStateEquipment', {
        templateUrl: 'views/components/views/oeeStateEquipment/oeeStateEquipment.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'oeeStateEquipmentService', 'stateService', function($stateParams, oeeStateEquipmentService, stateService) {
            var vm = this;
            
            oeeStateEquipmentService.loadP2OeeStateEquipmentGet($stateParams.oeestate_equipment_keyno).then(function () {
                vm.p2_oeestate_equipment_get = oeeStateEquipmentService.p2_oeestate_equipment_get;
            });
            
            vm.saveP2OeeStateEquipment = function () {
                oeeStateEquipmentService.saveP2OeeStateEquipment().then(function () {
                    stateService.back();
                });
            };
            
            vm.goToOeeStateRemarks = function () {
                stateService.go('oeestateremarks', {
                    oeestate_keyno: vm.p2_oeestate_equipment_get.oeestate_keyno
                });
            };
        }]
    });
})();