(function() {
    'use strict';

    angular.module("imApp").factory("edgeRelationService", ['$ihttp', function ($ihttp) {
        var service = {

            getEdgeRelation: function (parms) {
                return $ihttp.post({
                    method: 3210,
                    parameters: parms || {}
                });
            },
            save: function (edit) {
                return $ihttp.post({
                    method: 3211,
                    parameters: edit
                });
            },
            delete: function (edit) {
                return $ihttp.post({
                    method: 3253,
                    parameters: edit
                });
            },
            edgeSearchKeyname: function (value, searchparam) {
                return $ihttp.post({
                    method: 3212,
                    parameters: {
                        searchtext: value,
                        searchparam: searchparam
                    }
                });
            },
            plateSearchKeyname: function (value, searchparam) {
                return $ihttp.post({
                    method: 3213,
                    parameters: {
                        searchtext: value,
                        searchparam: searchparam
                    }
                });
            },


        }

        return service;
    }]);
})();
