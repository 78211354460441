(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('searchStock', {
        templateUrl: 'views/components/views/searchStock/searchStock.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$window', 'stateService', 'utilityService', 'modalService', 'searchStockService', 'logisticService', 'rememberService', function ($q, $window, stateService, utilityService, modalService, searchStockService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];
            
            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                productsearch_searchtext: ''
            };
            
            vm.model = {
                productSearchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('productsearch_searchtext') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('productsearch_searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => onSearch() }
                ],
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                itemsListStocks: [],
                gridView: false,
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_searchstock_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // ## LOAD PROCEDURE CALLS ##
            
            let loadSetting = function () {
                let deferred = $q.defer();

                searchStockService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => {
                        loadProducts();

                        loadGridWithSearchtext();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            let loadProducts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;
                
                vm.model.itemsListStocks = [];
                
                return searchStockService.search({
                    productsearch_searchtext: vm.model.setting.productsearch_searchtext,
                    functionname: 'stock',
                    stockplace_id: vm.model.setting.stockplace_id
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStocks);
                });

                if (vm.model.gridView && vm.model.gridReady) {
                    loadGridWithSearchtext();
                }
            };

            // #region GRID PROCEDURE FUNCTION CALLS

            // Load only if valid searchtext, as some customers has a lot of data, and the page is frozen for minutes while fetching from database
            let loadGridWithSearchtext = function () {
                if (typeof vm.model.setting.productsearch_searchtext === 'string' && vm.model.setting.productsearch_searchtext.length === 0) return;

                loadGrid();
            }

            let loadGrid = function () {
                if (vm.model.gridView === false) return;
                if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: 2574,
                    parameters: {
                        productsearch_searchtext: vm.model.setting.productsearch_searchtext,
                        functionname: 'stock',
                        stockplace_id: vm.model.setting.stockplace_id
                    }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                        case 'stockplace_id':
                        case 'productsearch_searchtext':
                            variableNames[key] = 'stock' + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'productsearch_searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;

                        variableValue = vm.model.setting.productsearch_searchtext;
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (id) {
                modalService.showCalculator(vm.model.setting[id]).then(function (value) {
                    vm.model.setting[id] = value;

                    if (vm.model.setting.productsearch_searchtext > '') {
                        rememberFunc('productsearch_searchtext');

                        loadProducts();
                        loadGrid();
                    }
                });
            };
            
            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION
            
            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'productsearch_searchtext':
                        vm.model.setting.productsearch_searchtext = '';

                        if (vm.model.setting.productsearch_searchtext === '') {
                            rememberFunc('productsearch_searchtext');
                            loadGridWithSearchtext();

                            vm.model.itemsListStocks = [];
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION
            
            vm.searchStock = function () {
                return onSearch();
            };

            let onSearch = function () {
                rememberFunc('productsearch_searchtext');

                loadProducts();
                loadGrid();
            }

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();

                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');
                        
                        break;
                    case 'productsearch_searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.productsearch_searchtext) !== true) return;

                        vm.model.setting.productsearch_searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;

                        if (vm.model.setting.productsearch_searchtext === '') {
                            rememberFunc('productsearch_searchtext');
                            loadGridWithSearchtext();
                            
                            vm.model.itemsListStocks = [];
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region LAYOUT FUNCTIONS

            var onWindowResize = function () {
                if (window.innerWidth > 926) {
                    if (vm.model.gridView === false) {
                        //console.log("swapping to desktop view");
                        vm.model.gridView = true;

                        if (vm.model.gridReady === false) {
                            loadGrid();
                        }
                    }
                } else {
                    if (vm.model.gridView === true) {
                        //console.log("swapping to mobile view");
                        vm.model.gridView = false;
                    }
                }
            };

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();

                $window.addEventListener('resize', onWindowResize);
                onWindowResize();
            };

            // #endregion LAYOUT FUNCTIONS

            // #region ANGULAR FUNCTIONS
            
            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS

        }]
    });
})();
