(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('powTimeApprovals', {
        templateUrl: 'views/components/views/powTimeApprovals/powTimeApprovals.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['powTimeApprovalsService', function (powTimeApprovalsService) {
            let vm = this;
            
            vm.model = {
                itemsListApprovals: []
            };

            let loadApprovals = function () {
                powTimeApprovalsService.listApprovals().then(function (result) {
                    angular.copy(result, vm.model.itemsListApprovals);
                });
            };
            
            vm.$onInit = function () {
                loadApprovals();
            };
        }]
    });
})();
