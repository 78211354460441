(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectTypeMenu', {
        templateUrl: 'views/components/views/serviceObjectTypeMenu/serviceObjectTypeMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'serviceObjectTypeMenuService', function($stateParams, stateService, serviceObjectTypeMenuService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                serviceObjectTypeKeyNo: $stateParams.serviceobjecttype_keyno,
                menu: {}
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            serviceObjectTypeMenuService.loadServiceObjectTypeMenuGet(vm.model.serviceObjectTypeKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.menu);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'serviceobjecttype':
                        go({
                            serviceobjecttype_keyno: vm.model.menu.serviceobjecttype_keyno
                        });
                        break;
                    case 'serviceobjecttypeints':
                        go({
                            serviceobjecttype_keyno: vm.model.menu.serviceobjecttype_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();