(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('list', {
        templateUrl: 'views/components/views/list/list.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'listService', 'translateService', function ($stateParams, stateService, modalService, listService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var listKeyno = $stateParams.list_keyno;

            var translations = {
                list_save_show_title: 'Varsel',
                list_save_show_ok_label: 'OK'
            };

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            listService.getList({ list_keyno: listKeyno }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'listdatas':
                        stateService.go(state, {
                            list_keyno: vm.model.edit.list_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                listService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.list_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.list_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    translateService.translateBatch(translations).then(function (data) {
			    angular.forEach(translations, function (_, key) {
				    if (angular.isDefined(data[key])) {
					    translations[key] = data[key];
				    }
			    });
		    });

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();