(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('emailAdd', {
        templateUrl: 'views/components/views/emailAdd/emailAdd.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'emailAddService', function($stateParams, stateService, emailAddService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                functionName: $stateParams.functionname,
                bgEmailAdd: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_emailadd_suggestion', item_func: 'SUGGESTION' },
                        { item_id: '1', item_name: 'bg_emailadd_search', item_func: 'SEARCH' }
                    ]
                },
                bgEmailAddFunc: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_emailadd_func_select', item_func: 'SELECT' },
                        { item_id: '1', item_name: 'bg_emailadd_func_goto', item_func: 'GOTO' }
                    ]
                },
                getEmailAdd: {},
                listEmailAddSuggestion: [],
                listEmailAddSearch: []
            };
            
            emailAddService.loadEmailAddGet(vm.model.argType, vm.model.argValue, vm.model.functionName).then(function (data) {
                vm.model.getEmailAdd = angular.copy(data[0]);
                
                loadSuggestions();
            });
            
            var loadSuggestions = function () {
                emailAddService.loadEmailAddSuggestionList(vm.model.getEmailAdd).then(function (data) {
                    angular.copy(data, vm.model.listEmailAddSuggestion);
                });
            };
            
            var loadSearch = function () {
                emailAddService.loadEmailAddSearchList(vm.model.getEmailAdd).then(function (data) {
                    angular.copy(data, vm.model.listEmailAddSearch);
                });
            };
            
            var rememberEmailsToAdd = function (emails_to_add) {
                var variableNameEmailsToAdd = 'w_emailadd_emails_to_add_' + vm.model.getEmailAdd.functionname;
                
                if (angular.isUndefined(vm.model.getEmailAdd.functionname) === true) return false;
                if (vm.model.getEmailAdd.functionname === null) return false;
                if (vm.model.getEmailAdd.functionname === '') return false;
                if (vm.model.getEmailAdd.functionname === '0') return false;

                if (angular.isUndefined(variableNameEmailsToAdd) === true) return false;
                if (variableNameEmailsToAdd === null) return false;
                if (variableNameEmailsToAdd === '') return false;

                if (angular.isUndefined(emails_to_add) === true) return false;
                if (emails_to_add === null) return false;
                if (emails_to_add === '') return false;
                if (emails_to_add === '0') return false;
                
                emailAddService.remember(variableNameEmailsToAdd, emails_to_add).then(function () {
                    stateService.back();
                });
            };

            vm.selectBgEmailAdd = function (item) {
                vm.model.bgEmailAdd.item_selected = item;
                vm.model.getEmailAdd.bg_emailadd = item;
            };

            vm.selectBgEmailAddFunc = function (item) {
                vm.model.bgEmailAddFunc.item_selected = item;

                //if (vm.model.bgEmailAdd.item_selected === 'SEARCH' && vm.model.bgEmailAddFunc.item_selected === 'GOTO') {
                //    loadSearch();
                //}
            };

            vm.searchEmailAddresses = function () {
                loadSearch();
            };

            vm.toggleChosenSuggestion = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                if (item.item_id === '0' || item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            vm.toggleChosenSearch = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;

                if (item.item_id === '0' || item.item_id === '-1') return;

                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            vm.add = function () {
                var emails_to_add = '';
                
                angular.forEach(vm.model.listEmailAddSuggestion, function (data) {
                    if (data.item_is_selected === '1' && data.email !== '') {
                        emails_to_add = emails_to_add + data.email + '; ';
                    }
                });
                
                angular.forEach(vm.model.listEmailAddSearch, function (data) {
                    if (data.item_is_selected === '1' && data.email !== '') {
                        emails_to_add = emails_to_add + data.email + '; ';
                    }
                });
                
                vm.model.getEmailAdd.emails_to_add = emails_to_add;
                
                rememberEmailsToAdd(emails_to_add);
            };
        }]
    });
})();