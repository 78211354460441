(function () {
	'use strict';

	angular.module("imApp").factory("gpximportService", ['$ihttp', 'p2DataTaskService', '$q', 'fileService', function ($ihttp, p2DataTaskService, $q, fileService) {
		var service = {
			getGpxImport: function (parms) {
				return $ihttp.post({
					method: 2868,
					parameters: parms || {}
				});
			},

			loadFileImportGpx: function (custact_no, filename, filecontent) {
				return p2DataTaskService.call(2869, {
					custact_no: custact_no,
					filecontent: filecontent,
					filename: filename
				});
			},
			loadP2DocArcFileNameSyntaxGet: function (argtype, argvalue) {
				var deferred = $q.defer();

				fileService.getFileNameSyntax(argtype, argvalue).then(function (data) {
					service.filenameSyntax = angular.copy(data);

					service.loaded = true;

					deferred.resolve(data);
				});

				return deferred.promise;
			}
		};

		return service;
	}]);
})();