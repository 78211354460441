(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockControlLocStockService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadStockControlLocStockGet: function (stocklocation_keyno, stock_keyno) {
                return p2DataTaskService.call(1707, {
                    stocklocation_keyno: stocklocation_keyno,
                    stock_keyno: stock_keyno
                });
            },

            loadStockControlLocStockOtherList: function (stocklocation_keyno, stock_keyno) {
                return p2DataTaskService.call(1709, {
                    stocklocation_keyno: stocklocation_keyno,
                    stock_keyno: stock_keyno
                });
            },

            save: function (location) {
                return p2DataTaskService.call(1708, location);
            }
        };

        return service;
    }]);
})();