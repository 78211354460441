(function() {
    'use strict';

    angular.module("imApp").factory("collectionCalculationService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var service = {
            create: function (parameters) {
                return call(2782, parameters || {});
            }
        }

        return service;
    }]);
})();
