(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('categoryListMain', {
        templateUrl: 'views/components/views/categoryListMain/categoryListMain.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['stateService', 'categoryListMainService', function (stateService, categoryListMainService) {
			let vm = this;

			vm.model = {
                itemsListMainCategories: []
			};

            let loadMainCategories = function () {
                categoryListMainService.listMainCategories().then(function (result) {
                    angular.copy(result, vm.model.itemsListMainCategories);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'categorylinkadd':
                        go({ category_keyno_belongto: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadMainCategories();
            };
		}]
	});
})();
