(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productView', {
        templateUrl: 'views/components/views/productView/productView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'productService', function ($stateParams, stateService, utilityService, productService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadMenu = function () {
                productService.getProduct({ prod_id: prodId }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'picturebanks':
                        go({
                            argtype: 'prod_id',
                            argvalue: vm.model.menu.prod_id
                        });
                        break;
                    case 'stock':
                        if (utilityService.validateParmsValue(vm.model.menu.prod_id) !== true) return;

                        return productService.searchStock({
                            functionname: 'stock',
                            barcode: vm.model.menu.prod_id
                        }).then(function (response) {
                            state = response[0].webpagename;

                            go(response[0].parms);
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
