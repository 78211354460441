import { BooleanInput, NumberInput, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { Style, TextAlign } from '@app/core/services/core-component.service';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormButton } from '../form-field-button/form-field-button.component';

@Component({
    selector: 'tt-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.css'],
})
export class InputNumberComponent extends FormFieldBaseComponent implements OnInit, OnChanges, FormFieldButtons {
    @ViewChild('inputRef') inputRef?: ElementRef;

    /**
     * The model of the input field.
     */
    @Input()
    ttModel: number = 0;

    /**
     * The value displayed in the input field, a string version of the `ttModel` value.
     */
    _viewValue?: string = '0';

    /**
     * Event emitted when the model value changes from the component, $event is the new ttModel value.
     */
    @Output()
    ttModelChange = new EventEmitter<number>();

    /**
     * The text align for the form-field.
     */
    @Input()
    ttTextAlign?: TextAlign;

    /**
     * Whether or not the text in the input field should be selected when the field is focused.
     */
    @Input()
    get ttAutoSelect(): boolean {
        return this._autoSelect;
    }
    set ttAutoSelect(value: BooleanInput) {
        this._autoSelect = coerceBooleanProperty(value);
    }
    private _autoSelect: boolean = true;

    /**
     * Whether or not the ttModel should be formatted to the user's locale numberformat.
     */
    @Input()
    get ttFormatNumber(): boolean {
        return this._formatNumber;
    }
    set ttFormatNumber(value: BooleanInput) {
        this._formatNumber = coerceBooleanProperty(value);
    }
    private _formatNumber: boolean = false;

    /**
     * Whether or not the ttModel should only be positive, default is `false`.
     */
    @Input()
    get ttAlwaysPositive(): boolean {
        return this._alwaysPositive;
    }
    set ttAlwaysPositive(value: BooleanInput) {
        this._alwaysPositive = coerceBooleanProperty(value);
    }
    private _alwaysPositive: boolean = false;

    /**
     * Whether ot not the component will allow ttModel to be an empty or should always be atleast 0.
     */
    @Input()
    get ttAllowEmpty(): boolean {
        return this._allowEmpty;
    }
    set ttAllowEmpty(value: BooleanInput) {
        this._allowEmpty = coerceBooleanProperty(value);
    }
    private _allowEmpty: boolean = true;

    /**
     * Forces focus to always be on the number input.
     */
    @Input()
    get ttBindFocus(): boolean {
        return this._bindFocus;
    }
    set ttBindFocus(value: BooleanInput) {
        this._bindFocus = coerceBooleanProperty(value);
    }
    private _bindFocus: boolean = false;

    /**
     * The max number of decimals to display, default is 0.
     */
    @Input()
    get ttDecimals(): number {
        return this._decimals;
    }
    set ttDecimals(value: NumberInput) {
        this._decimals = coerceNumberProperty(value);
        if (this._decimals > 0) {
            this.inputmode = 'decimal';
        } else {
            this.inputmode = 'numeric';
        }
    }
    private _decimals: number = 0;

    @Input()
    ttButtonParms?: { [key: string]: any };

    @Input()
    ttButtons?: FormButton[] = [];

    /**
     * Event emitted when the input field loses focus. Event is the current model value.
     */
    @Output()
    ttOnBlur = new EventEmitter<number>();

    public override style: Style = {
        input: {},
    };

    /**
     * Id's of the component.
     */
    public id = {
        input: crypto.randomUUID(),
    };

    /**
     * Input modes for a number field
     */
    public inputmode: 'decimal' | 'numeric' = 'numeric';

    public override setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, textAlign: this.ttTextAlign, mainElement: 'input' });
    }

    /**
     * Updates the model value and formats the view value. Emits the ttModelChanged event.
     *
     * @param event the new updated view value given by the user input.
     */
    public onModelChanged(event?: string) {
        const caretPosition = this.inputRef?.nativeElement?.selectionStart ?? 0;
        let value = this.parseInputToValidNumberString(event ?? '');

        if (value === '' && this.ttAllowEmpty) {
            setTimeout(() => (this._viewValue = value));
            return this.ttModelChange.emit();
        }

        if (value === '-') {
            return setTimeout(() => (this._viewValue = value));
        }

        if (this._formatNumber) {
            const existingDecimalsLength = value.split('.')?.[1]?.length ?? 0;
            value = this.formatInput(value, value.includes('.') ? existingDecimalsLength : 0);
        } else {
            value = value.replace('.', ',');
        }

        const difference = value.length - (event?.length ?? 0);
        let caretPositionAfter = difference === 0 ? caretPosition : caretPosition ? caretPosition + difference : 0;

        setTimeout(() => {
            this._viewValue = value;
            this.isInputFocused() && setTimeout(() => this.setCaretPosition(caretPositionAfter));
            this.ttModelChange.emit(Number(this.parseInputToValidNumberString(value ?? '')));
        });
    }

    /**
     * Formats the view value with all decimals, strictly. Does not emit any events.
     *
     * @param value the value to format to the view value.
     */
    private formatViewValue(value?: string) {
        const number = this.parseInputToValidNumberString(value ?? this._viewValue ?? '');

        if (number === '' && this.ttAllowEmpty) {
            setTimeout(() => (this._viewValue = number));
        }

        if (number === '-') {
            setTimeout(() => (this._viewValue = number));
            return;
        }

        if (this._formatNumber) {
            this._viewValue = this.formatInput(number, this._decimals);
        } else {
            this._viewValue = this.roundDecimals(number).replace('.', ',');
        }
    }

    public onFocus(_: FocusEvent) {
        if (this.ttAutoSelect) {
            this.selectText();
        }
    }

    public onBlur(_: FocusEvent) {
        this.formatViewValue();
        if (this._viewValue?.endsWith(',')) this._viewValue = this._viewValue.substring(0, this._viewValue.length - 1);
        console.log('this._viewValue :>> ', this._viewValue);
        this.ttOnBlur.emit(Number(this.ttModel));
    }

    /**
     * Removes all non-digit characters from the given input. If empty is allowed an empty string
     * is returned, if not 0 will be returned. Does not take into account `NaN`. Removed `-` if only positive
     * numbers are allowed.
     *
     * @param input the input string to parse to a valid JS number as string.
     * @returns the given input as a valid JS number in the type of string.
     */
    private parseInputToValidNumberString(input: string): string {
        let validCharacters = this.ttAlwaysPositive ? new RegExp(/[^0-9.,]/g) : new RegExp(/[^0-9.,-]/g);

        let number = `${input}`
            .replaceAll(/\,/g, '.')
            .replaceAll(/−/g, '-')
            .replaceAll(/—/g, '-')
            .replaceAll(' ', '')
            .replace(validCharacters, '')
            .replace(/(?!^)-/g, '')
            .replace(/^([^.]*\.)(.*)$/, (a, b, c) => b + c.replace(/\./g, ''));

        if (number?.trim() === '' && this.ttAllowEmpty) {
            return '';
        } else if (number?.trim() === '') {
            return '0';
        }

        return number;
    }

    /**
     * Formats the number input to the norwegian locale number format.
     *
     * @param input the stringified number to format.
     * @param minimumFractionDigits the minimum number of decimals, default is 0.
     * @returns the formatted number.
     */
    private formatInput(input: string, minimumFractionDigits: number = 0): string {
        const formatter = Intl.NumberFormat('nb', {
            minimumFractionDigits: Math.min(Math.abs(minimumFractionDigits), Math.abs(this._decimals)),
            maximumFractionDigits: Math.abs(this._decimals),
        });

        const indexOfFirstComma = input.indexOf('.');

        if (indexOfFirstComma !== -1 && indexOfFirstComma !== input.length - 1) {
            const beforeComma = input.substring(0, indexOfFirstComma);
            const afterComma = input.substring(indexOfFirstComma).replaceAll('.', '');

            input = formatter.format(Number(beforeComma + '.' + afterComma));
        } else if (indexOfFirstComma !== -1) {
            input = formatter.format(Number(input.replaceAll('.', ''))) + ',';
        } else {
            input = formatter.format(Number(input));
        }

        return input;
    }

    /**
     * Rounds the decimals of the input to the number of decimals set on component.
     * Returns the stringified number with rounded decimals.
     *
     * @param input the number as string to round the decimals of.
     * @returns a string with the rounded decimals.
     */
    private roundDecimals(input: string): string {
        let multiplier = Math.pow(10, Math.abs(this._decimals));
        return (Math.round((Number(input) + Number.EPSILON) * multiplier) / multiplier).toFixed(Math.abs(this._decimals));
    }

    /**
     * The id of the interval binding the focus to the input field. Null if focus is not bound.
     */
    private focusIntervalId: NodeJS.Timer | null = null;

    /**
     * Creates an interval and refocuses this number input every second.
     */
    private bindFocus() {
        if (this._bindFocus === true) {
            this.focusIntervalId = setInterval(() => setTimeout(() => this.inputRef?.nativeElement.focus()), 1000);
        }
    }

    /**
     * Clears the focus interval.
     */
    private unbindFocus() {
        if (this.focusIntervalId) {
            clearInterval(this.focusIntervalId);
            this.focusIntervalId = null;
        }
    }

    /**
     * Returns true if this number input is focused. False if not.
     *
     * @returns `true` if this number input is focused, `false` if not.
     */
    private isInputFocused() {
        return document?.activeElement === this.inputRef?.nativeElement;
    }

    /**
     * Sets the care position of the input element to the given caretposition.
     *
     * @param caretPosition
     */
    private setCaretPosition(caretPosition: number | undefined): void {
        if (this.inputRef?.nativeElement && caretPosition) {
            this.inputRef.nativeElement.focus();
            this.inputRef.nativeElement.setSelectionRange(caretPosition, caretPosition);
        }
    }

    /**
     * Selects the text in the input field.
     */
    private selectText() {
        if (this.ttAutoSelect) {
            this.inputRef?.nativeElement?.select();
        }
    }

    override async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.['ttBindFocus']?.currentValue !== undefined && changes?.['ttBindFocus']?.currentValue !== null) {
            if (this._bindFocus) {
                this.bindFocus();
            } else {
                this.unbindFocus();
            }
        }

        if (changes?.['ttModel']?.currentValue !== undefined && changes?.['ttModel']?.currentValue !== null && !this.isInputFocused()) {
            setTimeout(() => this.formatViewValue(changes['ttModel'].currentValue.toString()));
        }
    }

    override ngOnInit(): void {
        if (this.ttModel) {
            this.formatViewValue(this.ttModel.toString());
        }

        if (this._bindFocus) {
            this.bindFocus();
        } else {
            this.unbindFocus();
        }
    }
}
