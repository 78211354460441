(function () {
    'use strict';

    angular.module("imApp").factory("dataMiningTableComponentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            getDataMiningTable: function (p2_statsource_keyno, level_no) {
                return p2DataTaskService.call(1968, {
                    p2_statsource_keyno: p2_statsource_keyno,
                    level_no: level_no
                });
            },
            listDataMiningTableColumnNames: function (p2_statsource_keyno, level_no) {
                return p2DataTaskService.call(1970, {
                    p2_statsource_keyno: p2_statsource_keyno,
                    level_no: level_no
                });
            }
        };

        return service;
    }]);
})();