(function () {
    'use strict';

	var module = angular.module("imApp");

    module.factory("poFinishProdPackService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            loadOrders: function (keyno) {
                return p2DataTaskService.call(1641, {
                    poh_keyno_top: keyno
                });
            },
            loadPrinters: function () {
                return p2DataTaskService.call(423, {});
            },
            loadSettings: function () {
                return p2DataTaskService.call(2015, {});
            },
            listPoFinishProdPackEdits: function (parms) {
                return $ihttp.post({
                    method: 2495,
                    parameters: parms || {}
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            getReportDefn: function (fname) {
                return p2DataTaskService.call(1624, { report_filename: fname });
            },
            caseOrders: function (params) {
                return p2DataTaskService.call(1642, params);
            },
            deleteCase: function (casekeyno) {
                return p2DataTaskService.call(1895, { case_keyno: casekeyno });
            },
            getDefaultPrinter: function () {
                return p2DataTaskService.call(973, { variablename: 'pofinishprodpackprinter' });
            },
            setDefaultPrinter: function (printer) {
                return p2DataTaskService.call(616, {
                    variablename: 'pofinishprodpackprinter',
                    variablevalue: printer
                });
            }
        };

        return service;
    }]);
})();