<img
    *ngIf="!!this.params?.data['item_thumb']"
    #thumbnail
    class="img-grid-thumbnail tt-grid__thumbnail"
    [src]="this.params?.data['item_thumb'] + '?thumbnail=' + thumbnailSize"
    alt="Thumbnail"
    (mouseover)="onMouseOver()"
    (mouseout)="onMouseOut()"
/>
<!-- <div *ngIf="!!this.params?.data['item_thumb']" style="position: fixed; z-index: 1000000000000000000; top: 0; left: 0; height: 100vh; width: 100vw; pointer-events: none"> -->
<!-- <div style="position: relative; height: 100%; width: 100%"> -->
<ng-template #popup>
    <div class="item-thumb-popup tt-grid__thumbnail--popup-container" [ngStyle]="style['popup']" (mouseover)="style['popup'].display = 'block'" (mouseout)="style['popup'].display = 'none'">
        <img [src]="this.params?.data['item_thumb'] + '?thumbnail=400px'" class="tt-grid__thumbnail--popup-img" />
    </div>
</ng-template>

<!-- </div> -->
<!-- </div> -->
<!-- <img
    class="#= (data.item_thumb) ? item_thumb : '' !== '' ? 'img-grid-thumbnail' : 'img-grid-thumbnail-hidden' #"
    src="#= (data.item_thumb) ? item_thumb : '' #?thumbnail=${vm.thumbnailSize}"
    onmouseover="
                                                let translateX = -this.getBoundingClientRect().x.toFixed() / window.innerWidth * 100 + '%';
                                                let translateY = 0;
                                                let popup = this.nextElementSibling.children[0].children[0];
                                                let bottomPosition = this.getBoundingClientRect().bottom.toFixed();
                                                let leftPosition = this.getBoundingClientRect().left.toFixed();
                                                let tresholdY = this.getBoundingClientRect().y.toFixed() / (window.innerWidth - 400) * 100;

                                                if (tresholdY >= 50) {
                                                       translateY = -400 - ${parseInt(vm.thumbnailSize)} + 'px';
                                                }

                                                popup.style.transform = 'translate(' + translateX + ',' + translateY +')';
                                                popup.style.left = leftPosition + 'px';
                                                popup.style.top = bottomPosition + 'px';
                                                popup.style.display = 'block';
                                            "
    onmouseout="this.nextElementSibling.children[0].children[0].style.display = 'none'"
    style="aspect-ratio: 1 / 1; width: ${vm.thumbnailSize};"
/>
<div style="position: fixed; z-index: 1000000000000000000; top: 0; left: 0; height: 100vh; width: 100vw; pointer-events: none">
    <div style="position: relative; height: 100%; width: 100%">
        <div
            class="item-thumb-popup"
            onmouseover="this.style.display = 'block';"
            onmouseout="this.style.display = 'none'"
            style="z-index: 10000000000000000; display: none; position: absolute; width: 400px; height: 400px; pointer-events: all"
        >
            <img src="#= (data.item_thumb) ? item_thumb : '' #?thumbnail=400px" style="aspect-ratio: 1 / 1; width: 400px" />
        </div>
    </div>
</div> -->
