(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderLineCancelCauseLang', {
        templateUrl: 'views/components/views/orderLineCancelCauseLang/orderLineCancelCauseLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'orderLineCancelCauseLangService', function ($stateParams, stateService, orderLineCancelCauseLangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var orderLineCancelCauseKeyno = $stateParams.orderlinecancelcause_keyno;
            
            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            orderLineCancelCauseLangService.listOrderLineCancelCauseLanguages({ orderlinecancelcause_keyno: orderLineCancelCauseKeyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                orderLineCancelCauseLangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
