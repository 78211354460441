(function () {
    'use strict';

    angular.module("imApp").factory("orderHeadComponentService", ['$q', '$ihttp', 'orderHeadService', 'utilityService', 'orderLineCancelCausesService', function ($q, $ihttp, orderHeadService, us, orderLineCancelCausesService) {
        var service = {
            loadOrderhead: function (orderInternalNo) {
                return orderHeadService.loadP2OrderHeadGet(orderInternalNo);
            },

            loadOrderlines: function (orderInternalNo) {
                return orderHeadService.loadP2OrderLineList(orderInternalNo, 'w_orderhead.orderline');
            },

            loadOrderlinesGrid: function (orderInternalNo) {
                return orderHeadService.loadP2OrderLineListGrid(orderInternalNo, 'w_orderhead.orderline');
            },

            loadPayments: function (paymentId) {
                return orderHeadService.loadPaymentGet(paymentId);
            },

            loadOrderDependentData: function (orderhead) {
                var deferred = $q.defer();

                var promises = [];

                promises.push(orderHeadService.loadStockplaceList(orderhead.businessco_no));
                promises.push(orderHeadService.loadSellerList(orderhead.businessco_no));
                promises.push(orderHeadService.loadTaxList(orderhead.invoice_country_id));
                promises.push(orderHeadService.loadCustomerPersonsList(orderhead.stat_cust_no));
                promises.push(service.loadPayments(orderhead.payment_id));

                if (orderhead.setting_orderlines_use_grid === '0'){
                    promises.push(service.loadOrderlines(orderhead.order_internal_no));
                } else {
                    //promises.push(service.loadOrderlinesGrid(orderhead.order_internal_no));
                }    

                $q.all(promises).then(function (result) {
                    deferred.resolve({
                        stockplaces: result[0],
                        sellers: result[1],
                        tax: result[2],
                        customerPersons: result[3],
                        payments: result[4],
                        orderlines: orderhead.setting_orderlines_use_grid === '0' ? result[5]:[],
                        orderlinesGrid: orderhead.setting_orderlines_use_grid === '1' ? result[5] : []
                    });
                });

                return deferred.promise;
            },

            loadGeneralData: function (orderInternalNo) {
                var deferred = $q.defer();

                var promises = [];

                promises.push(orderHeadService.loadOrderMethodList());
                promises.push(orderHeadService.loadDeliveryMethodList({ order_internal_no: orderInternalNo })); // 20231123 EO - Listen vil nå bare vise aktive leveringmetoder for den gjeldende virksomheten
                promises.push(orderHeadService.loadDeliveryTermList());
                promises.push(orderHeadService.loadPaymentList());
                promises.push(orderHeadService.loadFactoringList());
                promises.push(orderHeadService.loadPricelistList());
                promises.push(orderHeadService.loadValutaList());
                promises.push(orderHeadService.loadBusinesscoList());
                promises.push(orderHeadService.loadDepartmentList());
                promises.push(orderHeadService.loadPortalUserOrderList());
                promises.push(orderHeadService.loadBusinessList());
                promises.push(orderHeadService.loadCoopList());
                promises.push(orderHeadService.loadInvoiceMethodSelectList());
                promises.push(orderHeadService.loadCountryList());
                promises.push(orderHeadService.loadSpecialDiscount2List());
                promises.push(orderHeadService.loadOrderDialogs(orderInternalNo));
                promises.push(orderLineCancelCausesService.listOrderLineCancelCauses());
                promises.push(orderHeadService.loadCollectionList());

                $q.all(promises).then(function (result) {
                    deferred.resolve({
                        orderMethods: result[0],
                        deliveryMethods: result[1],
                        deliveryTerms: result[2],
                        payments: result[3],
                        factoring: result[4],
                        pricelists: result[5],
                        valuta: result[6],
                        businesscos: result[7],
                        departments: result[8],
                        portalUserOrders: result[9],
                        businesses: result[10],
                        coops: result[11],
                        invoiceMethods: result[12],
                        countries: result[13],
                        specialDiscount2: result[14],
                        orderDialogs: result[15],
                        cancelCauses: result[16],
                        collection: result[17]
                    });
                });

                return deferred.promise;
            },

            loadOrderData: function (orderInternalNo) {
                var deferred = $q.defer();
                console.log('Start load order data');
                service.loadOrderhead(orderInternalNo).then(function (data) {
                    console.log('Ferdig load order data');
                    service.loadOrderDependentData(data).then(function (orderData) {
                        console.log('Ferdig depending');
                        deferred.resolve({
                            order: data,
                            data: orderData
                        });
                    });
                });

                return deferred.promise;
            },

            changeDeliveryMethod: function (parms) {
                return $ihttp.post({
                    method: 2177,
                    parameters: parms
                });
            },

            loadAllData: function (orderInternalNo) {
                var deferred = $q.defer();

                var promises = [];

                promises.push(service.loadOrderData(orderInternalNo));
                promises.push(service.loadGeneralData(orderInternalNo));

                $q.all(promises).then(function (result) {
                    deferred.resolve({
                        order: result[0],
                        general: result[1]
                    });
                });

                return deferred.promise;
            },

            loadAllOtherData: function (orderInternalNo, orderhead) {
                var deferred = $q.defer();

                var promises = [];

                promises.push(service.loadOrderDependentData(orderhead));
                promises.push(service.loadGeneralData(orderInternalNo));

                $q.all(promises).then(function (result) {
                    deferred.resolve({
                        order: result[0],
                        general: result[1]
                    });
                });

                return deferred.promise;
            },

            generateItems: function (mergeObj) {
                return $ihttp.post({
                    method: 2309,
                    parameters: mergeObj
                });
            },

            updateOrderlineNo: function (orderline_keyno, orderline_no) {
                return $ihttp.post({
                    method: 2917,
                    parameters: {
                        orderline_keyno: orderline_keyno,
                        orderline_no: orderline_no
                    }
                });
            },
            sveaCreatePaymentLink: function (order_internal_no) {
                return $ihttp.post({
                    method: 2962,
                    parameters: {
                        order_internal_no: order_internal_no
                    }
                });
            },

            getDynamicSpecial: function (order_internal_no) {
                return $ihttp.post({
                    method: 3140,
                    parameters: {
                        order_internal_no: order_internal_no
                    }
                });
            },

            //callDynamicSpecialFunctions: function (special_function, special_parms) {
            callDynamicSpecialFunctions: function (parameters) {
                return $ihttp.post({
                    method: 3152, parameters
                    //parameters: {
                    //    special_function: special_function,
                    //    special_parms: special_parms
                    //}
                });
            },

            getPostplaceKeyname: function (searchtext) {
                return $ihttp.post({
                    method: 3147,
                    parameters: {
                        searchtext: searchtext
                    }
                });
            }
        };

        return service;
    }]);
})();
