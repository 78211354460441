(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactCustomers', {
        templateUrl: 'views/components/views/custactCustomers/custactCustomers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custactCustomersService', function ($stateParams, stateService, custactCustomersService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListCustomers: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadCustomers = function () {
                custactCustomersService.listCustomers({
                    argtype: argType,
                    argvalue: argValue,
                }).then(function (list) {
            		angular.copy(list, vm.model.itemsListCustomers);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'custactcustomer':
                        go({
                            custact_no: argValue,
                            custact_customer_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadCustomers();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
