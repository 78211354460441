(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderlineStatusService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2OrderlineListStatus: {
                records: []
            },
            loadP2OrderlineListStatus: function (order_internal_no) {
                var deferred = $q.defer();

                var parmsP2OrderlineListStatus = {
                    method: 406,
                    parameters: {
                        order_internal_no: order_internal_no
                    }
                };

                $ihttp.post(parmsP2OrderlineListStatus).then(function (data) {
                    angular.copy(data, service.p2OrderlineListStatus.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();