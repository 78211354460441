(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockTransfer', {
        templateUrl: 'views/components/views/stockTransfer/stockTransfer.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'stockTransferService', 'utilityService', 'rememberService', 'dbPrinterService', '$uibModal', 'ieScreenBlockService', function ($stateParams, stateService, modalService, stockTransferService, utilityService, rememberService, dbPrinterService, $uibModal, ieScreenBlockService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;

            var variableNames = {
                printer: ''
            };
            
            vm.model = {
                stockTransferKeyno: $stateParams.stocktransfer_keyno,
                bgMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_main_function', item_func: 'FUNCTION' },
                        { item_id: '1', item_name: 'bg_main_details', item_func: 'DETAILS' }
                    ]
                },
    			bgStockTransferFunction: {
    				item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_stocktransfer_load', item_func: 'LOAD' },
                        { item_id: '1', item_name: 'bg_stocktransfer_unload', item_func: 'UNLOAD' },
    					{ item_id: '2', item_name: 'bg_stocktransfer_placed', item_func: 'PLACED' }
                    ]
    			},
                getStockTransfer: {},
                listStockTransferLoaded: [],
                listStockTransferUnloaded: [],
                listStockTransferPlaced: [],
                printers: [],
                lockedDelete: false,
                
            };
            
            // #########################
            // ## BUTTON GROUP FUNCTIONS
            // #########################
    
            vm.selectBgMain = function (item) {
                vm.model.bgMain.item_selected = item;
            };
    
    		vm.selectBgStockTransferFunction = function (item) {
                vm.model.bgStockTransferFunction.item_selected = item;
                
                if (item === 'PLACED') {
                    stockTransferService.genericFunction(1047, vm.model.getStockTransfer).then(function (data) {
                        angular.copy(data, vm.model.listStockTransferPlaced);
                    });
                }
    		};
    
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
            var loadStockTransferGet = function () {
                stockTransferService.loadStockTransferGet(vm.model.stockTransferKeyno).then(function (data) {
    				vm.model.getStockTransfer = angular.copy(data[0]);
                    
                    angular.forEach(vm.model.bgStockTransferFunction.records, function (data) {
                        if (angular.isDefined(vm.model.getStockTransfer.bg_stocktransfer_function) && angular.isDefined(data)) {
                            if (vm.model.getStockTransfer.bg_stocktransfer_function === data.item_func) {
                                vm.model.bgStockTransferFunction.item_default = data.item_id;
                            }
                        }
                    });

                    vm.model.printer = vm.model.getStockTransfer.p2_printer_keyno;
                    
                    loadStockTransferLoadedList();
                    loadStockTransferUnloadedList();
                    loadStockTransferPlacedList();
    			});
    		};
    
    		loadStockTransferGet();
            
    		var loadStockTransferLoadedList = function () {
    			stockTransferService.loadStockTransferLoadedList(vm.model.getStockTransfer).then(function (data) {
    				angular.copy(data, vm.model.listStockTransferLoaded);
    			});
    		};
            
            var loadStockTransferUnloadedList = function () {
                stockTransferService.loadStockTransferUnloadedList(vm.model.getStockTransfer).then(function (data) {
                    angular.copy(data, vm.model.listStockTransferUnloaded);
                });
            };
    
            var loadStockTransferPlacedList = function () {
                stockTransferService.loadStockTransferPlacedList(vm.model.getStockTransfer).then(function (data) {
                    angular.copy(data, vm.model.listStockTransferPlaced);
                });
            };

            vm.loadPrinters = function () {
                dbPrinterService.list().then(function (data) {
                    angular.copy(data, vm.model.printers);
                });
            };

            vm.loadPrinters();

            // #############################
            // ## DIRECTION BUTTON FUNCTIONS
            // #############################
            
            vm.goTo = function () {
                if (vm.model.getStockTransfer.stocktransfertype_keyno === '12') {
                    stateService.go('stocktransferloaditem', {
                        stocktransfer_keyno: vm.model.stockTransferKeyno,
                        stock_keyno: '0',
                        stockbatch_keyno: '0',
                        stocklocation_keyno_from: '0',
                        quantity: '0.00'
                    });
                } else {
                    stateService.go('stocktransferload', {
                        stocktransfer_keyno: vm.model.stockTransferKeyno
                    });
                }
    		};
    
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'stocktransferlog':
                        go({
                            stocktransfer_keyno: vm.model.getStockTransfer.stocktransfer_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
    
            // #######################
            // ## SAVE BUTTON FUNCTION
            // #######################
            
    		vm.finishedStockTransferUnload = function () {
    			stockTransferService.finishedStockTransferUnload(vm.model.getStockTransfer).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Feilmelding',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                    }
    			});
            };
    
            vm.allStockTransferUnload = function () {
                stockTransferService.allStockTransferUnload(vm.model.getStockTransfer).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Feilmelding',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                    }
                });
            };

            // #######################
            // ## PRINTING FUNCTIONS
            // #######################

            vm.onPrinterChanged = function (value) {
                vm.model.printer = value;

                if (value) {
                    rememberFunc('printer');
                }
            };

            vm.printConsignmentPackingLabelTrolley = function () {

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return '';
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) return;

                    ieScreenBlockService.start();

                    stockTransferService.printConsignmentPackingLabelTrolley(vm.model.stockTransferKeyno, vm.model.printer).then(function (result) {
                        ieScreenBlockService.stop();

                        if (result[0].errorcode !== '0') {
                            modalService.show({
                                type: 'danger',
                                title: 'Varsel',
                                message: result[0].errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-danger',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                        else {
                            modalService.show({
                                type: 'success',
                                title: 'Varsel',
                                message: 'Done',
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-success',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }

                    });
                });
            }


            // #######################
            // ## REMEMBER FUNCTION
            // #######################

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'printer':
                        if (utilityService.validateParmsValue(`${vm.model.printer}`) !== true) return;

                        variableValue = vm.model.printer;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };


            // #region DELETE BUTTON FUNCTION
    
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;
                
                stockTransferService.deleteItem({ stocktransfer_keyno: vm.model.getStockTransfer.stocktransfer_keyno }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
            
    		// #endregion DELETE BUTTON FUNCTION


        }]
    });
})();
