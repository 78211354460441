(function () {
    'use strict';


    var module = angular.module("imApp");

    module.factory("selectReportService", [function () {
        var service = {};

        return service;
    }]);
})();