(function () {
    'use strict';

    angular.module("imApp").factory("equipmentGrpLangService", ['$ihttp', function ($ihttp) {
        var service = {
            listEquipmentGrpLangs: function (equipmentGrpId) {
                return $ihttp.post({
                    method: 2051,
                    parameters: {
                        equipmentgrp_id: equipmentGrpId
                    }
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 2052,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();