// Warning: ttList is still in development, use imListDirective instead!
(function () {
    'use strict';

    let module = angular.module('imApp');

    module
        .filter('reverse', function () {
            return function (items, disable) {
                if (disable) return items;
                return items.slice().reverse();
            };
        })
        .filter('filterList', function () {
            return function (items, filterval, filterparm) {
                if (filterval === '') return items;

                let filtered = [];
                let individual = [];

                //Filterparm "strict" should make the filter check for the exact value that has been input, and not seperate on space
                if (angular.isDefined(filterparm) && filterparm.indexOf('strict') === true) {
                    individual.push(filterval);
                } else {
                    individual = filterval.split(' ');
                }

                angular.forEach(items, function (value) {
                    let addToFilter = true;
                    angular.forEach(individual, function (individualvalue) {
                        if (angular.isDefined(value.item_filtervalue) && value.item_filtervalue.toLowerCase().indexOf(individualvalue.toLowerCase()) === -1 && value.item_id !== '0' && value.item_id !== '-1') {
                            addToFilter = false;
                        }
                    });

                    angular.forEach(filtered, function (f_val) {
                        if (f_val === value) {
                            addToFilter = false;
                        }
                    });

                    if (addToFilter === true) {
                        filtered.push(value);
                    }
                });

                return filtered;
            };
        })
        .filter('filterSecondList', function () {
            return function (items, parentItem) {

                let filtered = [];

                angular.forEach(items, function (value) {
                    if (angular.isDefined(value.parent_id) && angular.isDefined(parentItem.item_id) && value.parent_id === parentItem.item_id) {
                        filtered = value;
                    }
                });

                return filtered.slice().reverse();
            };
        })
        .filter('filterGlyph', function () {
            return function (glyphs, item) {
                let filtered = [];

                for (let i = 0; i < glyphs.length; i++) {
                    if (angular.isDefined(glyphs[i]) && angular.isDefined(glyphs[i][0]) && angular.isDefined(glyphs[i][0].item_parent_id) && angular.isDefined(item.item_id) && glyphs[i][0].item_parent_id === item.item_id) {
                        angular.forEach(glyphs[i], function (g) {
                            filtered.push(g);
                        });
                        break;
                    }
                }

                return filtered;
            };
        })
        .component('ttList', {
            //templateUrl: 'views/components/directives/ttList/ttList.template.html?v=' + module.version,
            template: `
                <div ng-if="vm.mconfig.show === true" class="well well-sm col-xs-12">
                    <div ng-if="vm.model.ltype !== 'default'" class="col-xs-1 sp-0">
                        <div ng-class="vm.addMenuGlyph('switch')" type="button" ng-click="vm.goSwitch('switch')"></div>
                    </div>
                    <div ng-if="vm.model.ltype === 'default'" class="col-xs-1 sp-0">
                    </div>
                    <div ng-if="vm.mconfig.records === undefined" class="col-xs-11 sp-0">
                        <div ng-class="vm.addMenuGlyph('sort')" type="button" ng-click="vm.goSwitch('sort')"></div>
                        <div ng-class="vm.addMenuGlyph('resize')" type="button" ng-click="vm.goSwitch('resize')"></div>
                        <div ng-class="vm.addMenuGlyph('columns')" type="button" ng-click="vm.goSwitch('columns')"></div>
                        <div ng-class="vm.addMenuGlyph('edit')" type="button" ng-click="vm.goSwitch('edit')"></div>
                    </div>
                    <div ng-if="vm.mconfig.records.length > 0" class="col-xs-11 sp-0">
                        <div ng-repeat="mitem in vm.mconfig.records|reverse:vm.model.disableFilter">
                            <div ng-if="mitem.show === undefined || mitem.show === true" ng-class="vm.addMenuGlyph(mitem)" type="button" ng-click="vm.goSwitch(mitem)"></div>
                        </div>
                    </div>
                </div>  
    
                <div ng-if="vm.model.resizeMode === true" class="well well-sm col-xs-12" ng-style="{'paddingRight': '.2em'}">
                    <div ng-class="vm.addColArea('leftAreaSlider')"></div>                    
                    <div ng-class="vm.addColArea('centerAreaSlider')">
                        <div class="sp-0" ya-no-ui-slider="vm.slider.options" ya-no-ui-slider-events="vm.slider.events"></div>
                    </div>
                    <div ng-class="vm.addColArea('rightAreaSlider', item)"></div>
                </div>

                <div class="col-xs-12" style="padding: 0;">
                    <div ng-if="vm.model.ltype !== 'sortable' && vm.model.sortMode === false">
                        <div ng-if="vm.backitem === 'true' && vm.model.ltype === 'default'" class="col-xs-12 im-no-pad">
                            <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListBack.template.html?v=' + vm.version"></div>-->
                            <div ng-repeat="item in vm.listItems|reverse:vm.model.disableFilter|filterList:vm.model.filterVal:vm.model.filterParm">
                                <button data-testid="item_button_{{item.item_id}}" ng-click="vm.goBack(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                    <div class="glyphicon glyphicon-chevron-left grayish pull-left" style="padding: 0; text-align:left; color:#B8B8B8"></div>
                                    <div ng-repeat="gitem in vm.model.glyphlist|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div class="col-xs-11 listview_text" ng-style="vm.model.listViewTextStyle">
                                        {{item.item_name}}
                                        <span ng-if="vm.badge !== undefined && vm.badge !== ''" class="spin-text" ng-style="{'fontSize': vm.fontSizes.textSizeSs}"> </span>
                                        <span class="badge" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item[vm.badge]}}</span>
                                    </div>
                                </button>
                                <button ng-if="((vm.designVar === undefined || vm.designVar === '') || vm.designVar.indexOf('t') > -1) && (vm.ttHideGoto) !== true" data-testid="item_button_{{item.item_id}}_newtab" ng-click="vm.goTab(item)" ng-style="vm.model.listViewTextStyle" type="button" class="fas fa-window-restore pull-right im-newtab">
                                    <span ng-if="vm.designVar === undefined || vm.designVar.indexOf('t0') <= -1" class="im-tooltiptext" im-translate="new_tab"></span>
                                </button>
                            </div>
                        </div>
                        <div ng-if="vm.backitem !== 'true' && vm.model.ltype === 'default'" class="col-xs-12 im-no-pad">
                            <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListDefault.template.html?v=' + vm.version"></div>-->
                            <div ng-repeat="item in vm.listItems|reverse:vm.model.disableFilter|filterList:vm.model.filterVal:vm.model.filterParm">
                                <button style="padding: 0; width: 100%;" data-testid="item_button_{{item.item_id}}" ng-click="vm.goTo(item, $event)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12 im-list-btn--flex" ng-style="vm.style.button">
                                    <img ng-if="item.item_thumb !== undefined && item.item_thumb !== null && item.item_thumb.length > 0" style="margin-left: 9px" ng-src="{{item.item_thumb}}?thumbnail={{vm.fontSizes.thumbnail}}" ng-style="{'fontSize': vm.fontSizes.textSize}" />
                                    <div class="im-list-btn--flex" style="z-index: 1; padding: .5em .2em .5em .2em; width: 100%" ;>
                                        <div ng-if="vm.hideIcon !== true && vm.useDocIcon !== 'true'" ng-repeat="gitem in vm.model.glyphlist|filterGlyph:item" style="z-index: 1;">
                                            <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                        </div>
                                        <!-- BJS 20171017 - Added use of icon for docfile type.-->
                                        <img ng-if="vm.hideIcon !== true && vm.useDocIcon === 'true'" class="pull-left" ng-src="{{vm.model.appRoot}}/images/icons/32px/{{item.extension}}.png" style="margin:auto; padding-left: 5px; margin-top: -6px; height: 32px;" />
                                        <div class="listview_text" ng-style="vm.model.listViewTextStyle" style="width: 100%;">
                                            <div class="col-xs-11 im-no-pad" ng-style="{'overflow': 'inherit'}">
                                                {{item.item_name}}
                                                <span ng-if="vm.badge !== undefined && vm.badge !== ''" class="spin-text" ng-style="{'fontSize': vm.fontSizes.textSizeSs}"> </span>
                                                <span class="badge im-list-badge" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item[vm.badge]}}</span>
                                                <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                                <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                            </div>
                                            <div class="col-xs-1 im-no-pad">
                                                <!-- BJS 20220731 - Addded button used for deleting list item.-->
                                                <div class="col-xs-12 sp-0" ng-if="(vm.ttHideGoto || false) !== true">
                                                    <div ng-if="(vm.ttEditmode || false) === true" ng-click="vm.onDeleteItem(item)" ng-style="vm.model.deleteButtonStyle" type="button" class="btn btn-danger btn-sm glyphicon glyphicon-remove pull-right"></div>
                                                    <!-- <div ng-if="(vm.designVar === undefined || vm.designVar === '' || vm.designVar === 't0') && item.item_state !== 'customaction' && vm.ttClickable === true" class="glyphicon glyphicon-chevron-right grayish pull-right im-chevron"></div> -->
                                                    <!-- <div ng-if="vm.designVar.indexOf('s') > -1 && ((vm.designVar.indexOf('1') > -1 && vm.designVar.indexOf('2') <= -1) || (vm.designVar.indexOf('2') > -1 && vm.designVar.indexOf('1') <= -1))" class="glyphicon glyphicon-chevron-right grayish pull-right im-chevron-2"></div> -->
                                                    <!-- <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1 && vm.designVar.indexOf('2') > -1" class="glyphicon glyphicon-chevron-right grayish pull-right im-chevron"></div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                                <button ng-if="((vm.designVar === undefined || vm.designVar === '') || vm.designVar.indexOf('t') > -1) && item.item_state !== 'customaction' && vm.ttClickable === true && (vm.ttHideGoto || false) !== true" data-testid="item_button_{{item.item_id}}_newtab" ng-click="vm.goTab(item)" ng-style="vm.model.listViewTextStyle" type="button" class="fas fa-window-restore pull-right im-newtab">
                                    <span ng-if="vm.designVar === undefined || vm.designVar.indexOf('t0') <= -1" class="im-tooltiptext" im-translate="new_tab"></span>
                                </button>
                                <div id="{{vm.model.mId}}" ng-if="vm.model.sortMode === true" class="col-xs-12 im-no-pad">
                                    <div ui-sortable="sortableOptions" ng-class="vm.addClass()" ng-style="vm.addStyleWell()" ng-model="vm.listItems">
                                        <div ng-show="item.item_type !== 'info'" class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="vm.goSort(item)" ng-repeat="item in vm.listItems" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                            {{item.item_name}}
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ng-if="vm.model.ltype === 'check' && item.item_id !== '0' && item.item_id !== '-1'" class="col-xs-12 im-no-pad">
                            <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListCheck.template.html?v=' + vm.version"></div>-->
                            <div ng-repeat="item in vm.listItems|reverse:vm.model.disableFilter|filterList:vm.model.filterVal:model.filterParm">
                                <button ng-click="vm.goCheck(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                    <div ng-if="item.item_is_selected !== '1'" ng-repeat="gitem in vm.model.glyphlist|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div ng-if="item.item_is_selected === '1'" ng-repeat="gitem in vm.model.glyphlist2|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div class="listview_text" ng-style="vm.model.listViewTextStyle">
                                        <div class="col-xs-11 im-no-pad" ng-style="{'overflow': 'inherit'}">
                                            {{item.item_name}}
                                            <span ng-if="vm.badge !== undefined && vm.badge !== ''" class="spin-text" ng-style="{'fontSize': vm.fontSizes.textSizeSs}"> </span>
                                            <span class="badge im-list-badge" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item[vm.badge]}}</span>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div ng-if="vm.model.ltype === 'mark' && item.item_id !== '0' && item.item_id !== '-1'" class="col-xs-12 im-no-pad">
                            <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListMark.template.html?v=' + vm.version"></div>-->
                            <div ng-repeat="item in vm.listItems|reverse:vm.model.disableFilter|filterList:vm.model.filterVal:vm.model.filterParm">
                                <button ng-click="vm.goMark(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-class="vm.addMark(item)" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                    <div ng-if="item.item_is_selected !== '1'" ng-repeat="gitem in vm.model.glyphlist|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div ng-if="item.item_is_selected === '1'" ng-repeat="gitem in vm.model.glyphlist2|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div class="listview_text" ng-style="vm.model.listViewTextStyle">
                                        <div class="col-xs-11 im-no-pad" ng-style="{'overflow': 'inherit'}">
                                            {{item.item_name}}
                                            <span ng-if="vm.badge !== undefined && vm.badge !== ''" class="spin-text" ng-style="{'fontSize': vm.fontSizes.textSizeSs}"> </span>
                                            <span class="badge im-list-badge" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item[vm.badge]}}</span>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div ng-if="vm.model.ltype === 'multi' && vm.model.sortMode === false" class="col-xs-12 im-ct im-no-pad">
                            <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListMulti.template.html?v=' + vm.version"></div>-->
                            <div ng-repeat="item in vm.listItems|reverse:vm.model.disableFilter|filterList:vm.model.filterVal:vm.model.filterParm">
                                <button ng-click="vm.goCustom(item, $event)" type="button" ng-class="vm.removeHover(item)" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="vm.addStyleMultiListBtn(item)">
                                    <div ng-class="vm.addColArea('leftArea')">
                                        <div ng-if="item.item_id === '0' && vm.mconfig.enabled === true && vm.mconfig.show === false">
                                            <div ng-class="vm.addGlyph('switch')" type="button" ng-click="vm.goSwitch('switch')"></div>
                                        </div>
                                        <div ng-if="vm.mconfig === undefined || vm.mconfig.enabled === false || vm.mconfig.show === true || item.item_id !== '0'">
                                            <div ng-if="item.item_is_selected !== '1'" ng-repeat="gitem in vm.model.glyphlist|filterGlyph:item">
                                                <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                            </div>
                                            <div ng-if="item.item_is_selected === '1'" ng-repeat="gitem in vm.model.glyphlist2|filterGlyph:item">
                                                <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div ng-class="vm.addColArea('centerArea')">
                                        <div ng-class="vm.addColItem('main')" ng-style="vm.addStyleCenterAreaItem(item)">
                                            <div ng-if="item.item_id === '0' || item.item_id === '-1'" class="multi-list-col-text">
                                                <b>{{item.item_name}} </b>
                                            </div>
                                            <div ng-if="item.item_id !== '0' && item.item_id !== '-1'" class="multi-list-col-text">
                                                <div ng-hide="vm.thisFocus(item, 'imp', '{{item.item_id}}')">{{item.item_name}} </div>
                                                <input ng-hide="!vm.thisFocus(item, 'imp', '{{item.item_id}}')" id="{{item.item_id}}-imp-{{item.item_id}}-{{vm.model.mId}}" value="{{item.item_name}}" ng-focus="vm.inputFocus($event)" ng-blur="vm.inputBlur($event)" ng-style="vm.addInputStyle()" />
                                            </div>
                                            <span ng-if="vm.badge !== undefined && vm.badge !== ''" class="spin-text" ng-style="{'fontSize': vm.fontSizes.textSizeSs}"> </span>
                                            <span class="badge im-list-badge" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item[vm.badge]}}</span>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1 && vm.model.inlineEditMode === false" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                        </div>
                                        <div ng-if="vm.model.secondary !== undefined && vm.model.secondary.length > 0" ng-class="vm.addColItem(sitem)" ng-style="vm.addStyleCenterAreaItem(sitem, item)" ng-repeat="sitem in vm.model.secondary|reverse:vm.model.disableFilter|filterSecondList:item">
                                            <div ng-if="item.item_id === '0'" class="multi-list-col-text">
                                                <b>{{sitem.item_col}} </b>
                                            </div>
                                            <div ng-if="item.item_id !== '0'" class="multi-list-col-text">
                                                <div ng-hide="vm.thisFocus(item, 'ims', {{item.item_secondary.length-$index-1}})">{{sitem.item_col}} </div>
                                                <input ng-hide="!vm.thisFocus(item, 'ims', {{item.item_secondary.length-$index-1}})" id="{{item.item_secondary.length-$index-1}}-ims-{{item.item_id}}-{{vm.model.mId}}" value="{{sitem.item_col}}" ng-focus="vm.inputFocus($event)" ng-blur="vm.inputBlur($event)" ng-style="vm.addInputStyle()" />
                                            </div>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1 && vm.model.inlineEditMode === false" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                        </div>
                                    </div>
                                    <div ng-class="vm.addColArea('rightArea', item)"></div>
                                </button>
                                <div id="{{vm.model.mId}}" ng-if="vm.model.sortMode === true" class="col-xs-12 im-no-pad">
                                    <div ui-sortable="sortableOptions" ng-class="vm.addClass()" ng-style="vm.addStyleWell()" ng-model="vm.listItems">
                                        <div ng-show="item.item_type !== 'info'" class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="vm.goSort(item)" ng-repeat="item in vm.listItems" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                            {{item.item_name}}
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ng-if="vm.model.ltype === 'custom'" class="col-xs-12 im-no-pad">
                            <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListCustom.template.html?v=' + vm.version"></div>-->
                            <div ng-repeat="item in vm.listItems|reverse:vm.model.disableFilter|filterList:vm.model.filterVal:vm.model.filterParm">
                                <button ng-click="vm.goCustom(item, $event)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                    <div ng-if="vm.backitem === 'true'" class="glyphicon glyphicon-chevron-left grayish pull-left" style="padding: 0; text-align:left; color:#B8B8B8"></div>
                                    <div ng-if="item.item_is_selected !== '1'" ng-repeat="gitem in vm.model.glyphlist|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div ng-if="item.item_is_selected === '1'" ng-repeat="gitem in vm.model.glyphlist2|filterGlyph:item">
                                        <div class="pull-left" ng-style="vm.addStyle(gitem)" ng-class="vm.addGlyph(gitem)"></div>
                                    </div>
                                    <div class="listview_text" ng-style="vm.model.listViewTextStyle">
                                        <div class="col-xs-11 im-no-pad" ng-style="{'overflow': 'inherit'}">
                                            {{item.item_name}}
                                            <span ng-if="vm.badge !== undefined && vm.badge !== ''" class="spin-text" ng-style="{'fontSize': vm.fontSizes.textSizeSs}"> </span>
                                            <span class="badge im-list-badge" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item[vm.badge]}}</span>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                            <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="{{vm.model.mId}}" ng-if="vm.model.ltype === 'sortable'" class="col-xs-12 im-no-pad">
                        <!--<div class="col-xs-12 sp-0" ng-include="'views/components/directives/ttList/ttListSortable.template.html?v=' + vm.version"></div>-->
                        <div ng-repeat="group in vm.model.sortlist">
                            <div ui-sortable="sortableOptions" ng-class="vm.addClass()" ng-style="vm.addStyleWell(group)" ng-model="group.items">
                                <div class="pull-left name-label col-xs-1-5 im-sort-pad" ng-style="vm.addStyleSortlist(group)" ng-class="vm.addGlyph(group)"></div>
                                <label class="name-label col-xs-10-5 im-sort-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{group.value}}</label>
                                <div class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="vm.goSort(item)" ng-repeat="item in group.items" ng-style="{'fontSize': vm.fontSizes.textSize}">
                                    {{item.item_name}}
                                    <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('1') > -1" class="col-xs-12 im-no-pad" ng-style="{'fontSize': vm.fontSizes.textSizeS}">{{item.item_name_sub1}}</div>
                                    <div ng-if="vm.designVar.indexOf('s') > -1 && vm.designVar.indexOf('2') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{'fontSize': vm.fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>`,
            controllerAs: 'vm',
            bindings: {
                ttChange: '&',          // BJS 20210806 - Fires when listItems is modified
                ttClickable: '<',       // BJS 20220419 - Used to disable what happens on click
                ttClick: '&',           // BJS 20220419 - Used in combination with ttClickable to run custom action on all clicks.
                ttEditmode: '<',        // BJS 20220731 - Used to put list into "edit mode".
                ttHideGoto: '<',        // BJS 20230323 - Used to hide "buttons" to the right
                ttDeleteitem: '&',      // BJS 20220731 - Callback for deleting list item.
                listItems: '=',         //Required and references a list                                                // Supports several glyphs for single items, seperated by space
                sortItems: '=?',        //Used as an addition to the main list for sorting it into different groups     // Required only if sorting between multiple groups
                //menuItems: '=?',      //Used if custom menu items for multi-list will be used                         // Usable only with "multi", accepts a list of objects
                dataid: '@?',           //Used in conjunction with sortItems to specify the list key                    // Required with sortItems
                dataname: '@?',         //Used in conjunction with sortItems to specify the visible text                // Required with sortItems
                backitem: '@',          //Set to true if the items should lead back to the previous screen
                ltype: '@',             //Defines how the list core functions                                           // Accepted values "check", "check+", "back", "mark", "multi", "sortable", "custom", "default", if undefined or "default" it functions in a default way //Dual changed to multi and works
                typevar: '@',           //Used for different predefined functionality                                   // Accepts 'g' for goto
                ptypeVar: '=',          //Used instead of typeVar when property is required                             //
                mconfig: '=?',          //Can be used with ltype 'multi' to configure the list and what it shows        // Also allows custom menu items, accepts a list of objects
                badge: '@',             //If it has value, creates a badge behind "name" displaying a value
                optionfunc: '&',        //Links a function from controller
                sortfunc: '&',          //Links a function from controller, optional function after moving              // REQUIRES sortItems, optional, fires after item is dragged
                designVar: '@',         //Allows the user to activate 1 or several additional infolayers to the list    // 's' followed by a number '1' and/or '2' will add sublines, not amount but which one
                orderby: '@',           //Used to determine which variable to order the list by                         // NOT IMPLEMENTED
                revOrder: '@',          //Used for reversing the order of the list                                      // Accepts only true
                filterVal: '=',         //Used for adding a filter                                                      // Any value is searchable by default, filter type can limit this.
                filterParm: '@',        //Used for setting filter parameters                                            // NOT IMPLEMENTED,
                useDocIcon: '@',        //Specifies using icons instead of glyphicons for showing document types        // 
                hideIcon: '<'
            },
            controller: ['$timeout', 'stateService', 'layoutService', 'appConfig', 'responsive', 'utilityService', 'watcherFactory', function ($timeout, stateService, layoutService, appConfig, responsive, utilityService, watcherFactory) {
                let vm = this;
                vm.first = 0;
                
                const watcher = watcherFactory.$create(vm);

                let onDestroy = [];

                vm.version = module.version;

                vm.restrict = 'E';

                vm.model = {
                    mId: uuid(),
                    disableFilter: true,
                    listIsDragged: false,
                    didUpdate: false,
                    sortMode: false,
                    resizeMode: false,
                    inlineEditMode: false,
                    isKeypress: false,
                    prosessingKeypress: false,
                    gridClicked: false,
                    currentInline: null,
                    activeInlineId: null,
                    elemInFocus: null,
                    filterVal: '',
                    filterParm: '',
                    field: '',
                    fieldWidth: '',
                    fieldWidthMin: '8',
                    //fieldWidthPadding: '5',
                    sliderMinDefault: 8,
                    sliderMin: 8,
                    slidersChanging: false,
                    colWidthChanging: false,
                    validatingEditable: false,
                    editableValidated: false,
                    inlineEditUpdate: false,
                    label: '',
                    ltype: 'default',
                    //showMenu: false,
                    initialGlyphColor: '',
                    itemLoc: { itemId: null, listFromId: null, listToId: null, listFromIndex: null, listToIndex: null },
                    menuList: [],
                    sortlist: [],
                    glyphlist: [],
                    glyphlist2: [],
                    secondary: [],
                    colList: [],
                    numCol: 0,
                    currentSize: responsive.current,
                    appRoot: appConfig.appRoot,
                    listViewTextStyle: {
                        fontSize: '',
                        paddingLeft: ''
                    },
                    deleteButtonStyle: {
                        fontSize: ''
                    },
                    thumbnailStyle: {
                        aspectRatio: '1 / 1',
                        width: '',
                        objectFit: 'cover'
                    }
                };

                vm.style = {
                    button: {
                        fontSize: ''
                    }
                };

                // BJS 20220928
                if (angular.isUndefined(vm.hideIcon)) {
                    vm.hideIcon = false;
                }

                let preventCustomClick = false;

                vm.onDeleteItem = function (item) {
                    preventCustomClick = true;

                    if (angular.isFunction(vm.ttDeleteitem)) {
                        vm.ttDeleteitem({ $item: item });
                    }

                    $timeout(function () { preventCustomClick = false; });
                };

                vm.combineId = function (index, role) {
                    let combinedId = index + '-' + role + '-' + uuid();
                    return combinedId;
                };

                if (angular.isDefined(vm.revOrder) && vm.revOrder === 'true') {
                    vm.model.disableFilter = false;
                }

                vm.sortableOptions = {
                    placeholder: "sort-list-placeholder",
                    connectWith: ".sort-list-container",
                    tolerance: 'pointer',
                    start: function () {
                    },
                    update: function (e, ui) {
                        console.log("Hallo");
                        vm.model.listIsDragged = true;
                        vm.model.didUpdate = true;

                        if (angular.isUndefined(ui.item.sortable.model)) {
                            ui.item.sortable.cancel();
                        }

                        vm.model.itemLoc.itemId = ui.item.sortable.model.item_id;
                        vm.model.itemLoc.listFromIndex = ui.item.sortable.index;
                        vm.model.itemLoc.listToIndex = ui.item.sortable.dropindex;
                        vm.model.itemLoc.listFromId = ui.item.sortable.model.item_sort_id;
                    },
                    stop: function (e, ui) {
                        // this callback has the changed model
                        if (vm.model.didUpdate === true) {
                            findItemById(vm.model.itemLoc, false);

                            if (vm.model.itemLoc.listFromId !== vm.model.itemLoc.listToId) {
                                updateOrder(vm.model.itemLoc.listFromId);
                                updateItemlist(vm.model.itemLoc);
                            }
                            updateOrder(vm.model.itemLoc.listToId);

                            if (angular.isFunction(vm.sortfunc)) {
                                vm.sortfunc({ item: vm.model.itemLoc });
                            }
                        }

                        vm.model.listIsDragged = false;
                        vm.model.didUpdate = false;
                    },
                };

                vm.slider = {
                    min: vm.model.sliderMin,
                    options: {
                        start: [20, 70],
                        connect: true,
                        margin: vm.model.sliderMin,
                        padding: vm.model.sliderMin,
                        step: 0.5,
                        tooltips: [true, true], //wNumb({ decimals: 1 }) formatting is possible, just not like this
                        range: { min: 0, max: 100 }
                    },
                    events: {
                        update: function (values, handle, unencoded) {
                        },
                        slide: function (values, handle, unencoded) {
                            vm.model.slidersChanging = true;

                            let colCount = vm.model.colList.length;
                            let nextValue = 100;
                            let skip = 0;

                            if (angular.isDefined(unencoded[handle + 1])) {
                                nextValue = unencoded[handle + 1];
                            }

                            if (handle === 0) {
                                vm.listItems[0].item_col_width = unencoded[handle];

                                for (let col = 0; col < colCount; col++) {
                                    if (vm.model.colList[col].visible === false) {
                                        skip++;
                                    } else if (col - skip === handle) {
                                        vm.listItems[0].item_secondary[col].item_col_width = nextValue - unencoded[handle];
                                        break;
                                    }
                                }
                            } else {
                                for (let sec = 0; sec < colCount; sec++) {
                                    if (vm.model.colList[sec].visible === false) {
                                        skip++;
                                    } else if (sec - skip + 1 === handle) {
                                        vm.listItems[0].item_secondary[sec].item_col_width = unencoded[handle] - unencoded[handle - 1];

                                        skip = 0;

                                        for (let nxt = sec + 1; nxt < colCount; nxt++) {
                                            if (vm.model.colList[nxt].visible === false) {
                                                skip++;
                                            } else if (nxt - skip === handle) {
                                                vm.listItems[0].item_secondary[nxt].item_col_width = nextValue - unencoded[handle];
                                                break;
                                            }
                                        }
                                        break;
                                    }
                                }
                            }

                            vm.model.slidersChanging = false;
                        },
                        set: function (values, handle, unencoded) { },
                        change: function (values, handle, unencoded) { }
                    },
                    functions: {
                        setStarts: function () {
                            if (angular.isUndefined(vm.listItems[0]) && angular.isUndefined(vm.listItems[0].item_secondary)) return;
                            if (vm.model.colList.length <= 0) return;

                            let colCount = vm.model.colList.length;
                            let totalLength = 0;
                            let sliderStarts = [];

                            vm.model.slidersChanging = true;

                            for (let col = 0; col < colCount; col++) {
                                if (col === 0) {
                                    if (angular.isDefined(vm.listItems[0].item_col_width) && isNumber(vm.listItems[0].item_col_width)) {
                                        if (parseFloat(vm.listItems[0].item_col_width) > 100) {
                                            vm.listItems[0].item_col_width = 100;
                                        }
                                        sliderStarts.push(parseFloat(vm.listItems[0].item_col_width));
                                        totalLength += parseFloat(vm.listItems[0].item_col_width);
                                    } else {
                                        sliderStarts.push(vm.slider.min);
                                        totalLength += vm.slider.min;
                                    }

                                    if (totalLength >= 100) {
                                        break;
                                    }
                                }

                                if (vm.model.colList[col].visible === true) {
                                    if (vm.model.colList[col].width > 100) {
                                        vm.model.colList[col].width = 100;
                                    } else if (vm.model.colList[col].width < vm.slider.min) {
                                        vm.model.colList[col].width = vm.slider.min;
                                    }

                                    if (col !== colCount - 1) {
                                        sliderStarts.push((vm.model.colList[col].width + totalLength));
                                    }

                                    totalLength += vm.model.colList[col].width;
                                }
                            }
                            vm.slider.options.start = sliderStarts;
                            vm.model.slidersChanging = false;
                        },
                        setConnections: function () {
                            let sliderCount = vm.slider.options.start.length;
                            let sliderConnections = [];

                            for (let col = 0; col <= sliderCount; col++) {
                                sliderConnections.push(true);
                            }

                            vm.slider.options.connect = sliderConnections;
                        },
                        setTooltips: function () {
                            let sliderCount = vm.slider.options.start.length;
                            let sliderTooltips = [];

                            for (let col = 0; col < sliderCount; col++) {
                                sliderTooltips.push(true);
                            }

                            vm.slider.options.tooltips = sliderTooltips;
                        }
                    }
                };

                vm.slider.functions.setConnections();

                const findItemById = function (itemLoc, idFrom) {
                    let foundItem = false;
                    if (vm.model.sortlist?.length) {
                        for (let i = 0; i < vm.model.sortlist.length; i++) {
                            if (vm.model.sortlist[i]?.items?.length) {
                                for (let l = 0; l < vm.model.sortlist[i].items.length; l++) {
                                    if (vm.model.sortlist[i].items[l].item_id === itemLoc.itemId) {
                                        if (idFrom === true) {
                                            itemLoc.listFromId = vm.model.sortlist[i].key;
                                        } else {
                                            itemLoc.listToId = vm.model.sortlist[i].key;
                                        }
                                        foundItem = true;
                                        break;
                                    }
                                }
                            }

                            if (foundItem === true) {
                                break;
                            }
                        }
                    }
                };

                const updateOrder = function (listId) {
                    if (vm.model.sortlist?.length) {
                        for (let i = 0; i < vm.model.sortlist.length; i++) {
                            if (vm.model.sortlist[i].key === listId && angular.isDefined(vm.model.sortlist[i].items)) {
                                for (let l = 0; l < vm.model.sortlist[i].items.length; l++) {
                                    vm.model.sortlist[i].items[l].orderby = i * 100 + l;
                                }
                            }
                        }
                    }
                };

                const updateItemlist = function (itemLoc) {
                    if (vm.listItems) {
                        if (vm.listItems?.records?.length) {
                            for (let i = 0; i < vm.listItems.records.length; i++) {
                                if (vm.listItems.records[i].item_id === itemLoc.itemId) {
                                    vm.listItems.records[i].item_sort_id = itemLoc.listToId;
                                    break;
                                }
                            }
                        } else {
                            for (let i = 0; i < vm.listItems.length; i++) {
                                if (vm.listItems[i].item_id === itemLoc.itemId) {
                                    vm.listItems[i].item_sort_id = itemLoc.listToId;
                                    break;
                                }
                            }
                        }
                    }
                };

                // BJS 20220419
                const isCustomClick = function (item) {
                    if (preventCustomClick === true) return true; // BJS 20220731
                    if (angular.isUndefined(vm.ttClickable)) return false;
                    if (vm.ttClickable === null) return false;
                    if (vm.ttClickable === true) return false;

                    if (angular.isFunction(vm.ttClick)) {
                        vm.ttClick({ item: item });
                    }
                    return true;
                };

                vm.goTo = function (l_item, e) {
                    if (isCustomClick(l_item)) return;

                    // BJS 20210607 - Added customaction to enable using listitem as a button.
                    if (l_item.item_state === 'customaction') {
                        if (angular.isFunction(l_item.item_action) === true) {
                            l_item.item_action(l_item);
                        }
                    } else {
                        if (l_item.item_path === '') return;
                        // EO 20230712 - Added functionality for opening in new tab.
                        if (e.ctrlKey) {
                            stateService.newTab(l_item.item_state, l_item.item_parms);
                        } else {
                            stateService.go(l_item.item_state, l_item.item_parms);
                        }
                    }
                };

                vm.goTab = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_state === '') return;

                    stateService.newTab(l_item.item_state, l_item.item_parms);
                };

                vm.goBack = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_id === '') return;

                    if (angular.isFunction(vm.optionfunc)) {
                        vm.optionfunc({ item: l_item });
                    }

                    stateService.back();
                };

                vm.goCheck = function (item) {
                    if (isCustomClick(item)) return;

                    if (item.item_id === '0' || item.item_id === '-1') return;

                    if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color === vm.model.initialGlyphColor) {
                        if (angular.isDefined(item.item_glyphicon_2) && item.item_glyphicon_2 !== null) {
                            item.item_is_selected = '1';
                        }
                        item.item_glyphicon_color = 'green';
                    } else if (vm.model.initialGlyphColor !== '') {
                        item.item_glyphicon_color = vm.model.initialGlyphColor;
                        item.item_is_selected = '0';
                    } else {
                        item.item_glyphicon_color = 'red';
                        item.item_is_selected = '0';
                    }
                };

                vm.goMark = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_id === '' || l_item.item_id === '0' || l_item.item_id === '-1') return;

                    if (l_item.item_is_selected === '0') {
                        l_item.item_is_selected = '1';
                    } else {
                        l_item.item_is_selected = '0';
                    }

                    if (angular.isFunction(vm.optionfunc)) {
                        vm.optionfunc({ item: l_item });
                    }
                };

                vm.goSwitch = function (item) {
                    if (isCustomClick(item)) return;

                    if (angular.isDefined(item) && angular.isDefined(item.item_id) && angular.isFunction(vm.mconfig.function)) {
                        if (item.item_name === 'sort') {
                            vm.model.sortMode = !vm.model.sortMode;
                        }
                        if (item.item_name === 'resize') {
                            vm.model.resizeMode = !vm.model.resizeMode;

                            if (vm.model.inlineEditMode === true) {
                                vm.model.inlineEditMode = false;
                                vm.mconfig.deactivate('edit');
                            }

                            if (vm.model.resizeMode === true) {
                                vm.slider.functions.setStarts();
                                vm.slider.functions.setConnections();
                                vm.slider.functions.setTooltips();
                            }
                        }
                        if (item.item_name === 'columns') {
                        }
                        if (item.item_name === 'edit') {
                            vm.model.inlineEditMode = !vm.model.inlineEditMode;

                            if (vm.model.resizeMode === true) {
                                vm.model.resizeMode = false;
                                vm.mconfig.deactivate('resize');
                            }

                            if (!vm.model.inlineEditMode) {
                                vm.model.editableValidated = false;
                            }
                        }
                        vm.mconfig.function(item);
                    } else if (item === 'switch') {
                        vm.mconfig.show = !vm.mconfig.show;
                        if (vm.mconfig.show === false) {
                            vm.model.sortMode = false;
                            vm.model.resizeMode = false;
                            vm.model.inlineEditMode = false;
                            vm.model.editableValidated = false;
                            vm.mconfig.deactivate('all');
                        } else if (vm.mconfig.show === true) {
                            if (vm.mconfig.records.length > 1) {
                                let recCount = 0;
                                let lastEnabledRec = null;
                                let didActivateARec = false;
                                for (let r = 0; r < vm.mconfig.records.length; r++) {
                                    if ((angular.isUndefined(vm.mconfig.records[r].isIcon) || vm.mconfig.records[r].isIcon === false)
                                        && (angular.isDefined(vm.mconfig.records[r].show) && vm.mconfig.records[r].show === true)
                                        && (angular.isDefined(vm.mconfig.records[r].enabled) && vm.mconfig.records[r].enabled === true)) {
                                        recCount++;
                                        lastEnabledRec = vm.mconfig.records[r];
                                        if (lastEnabledRec.isActive === true) {
                                            didActivateARec = true;
                                            vm.mconfig.activate(lastEnabledRec);
                                            switch (lastEnabledRec.item_name) {
                                                case 'option':
                                                    break;
                                                case 'sort':
                                                    vm.model.sortMode = true;
                                                    break;
                                                case 'resize':
                                                    vm.model.resizeMode = true;
                                                    vm.slider.functions.setStarts();
                                                    vm.slider.functions.setConnections();
                                                    vm.slider.functions.setTooltips();
                                                    break;
                                                case 'edit':
                                                    vm.model.inlineEditMode = true;
                                                    break;
                                            }
                                        }
                                    }
                                }
                                if (recCount === 1 && !didActivateARec) {
                                    vm.mconfig.activate(lastEnabledRec);
                                    switch (lastEnabledRec.item_name) {
                                        case 'option':
                                            break;
                                        case 'sort':
                                            vm.model.sortMode = true;
                                            break;
                                        case 'resize':
                                            vm.model.resizeMode = true;
                                            vm.slider.functions.setStarts();
                                            vm.slider.functions.setConnections();
                                            vm.slider.functions.setTooltips();
                                            break;
                                        case 'edit':
                                            vm.model.inlineEditMode = true;
                                            break;
                                    }
                                    vm.mconfig.function(lastEnabledRec);
                                }
                            }
                        }
                    } else if (angular.isDefined(item)) {
                        console.log('Defined as: ' + item);
                    } else {
                        console.log('item undefined');
                    }
                };

                vm.goSort = function (item) {
                    if (isCustomClick(item)) return;

                    if (vm.model.listIsDragged === true || vm.model.sortMode === true || item.item_id === '') {
                        console.log('click canceled');
                        return;
                    }

                    if (angular.isFunction(vm.optionfunc)) {
                        vm.optionfunc({ item: item });
                    }

                    if (angular.isDefined(vm.typevar) && vm.typevar.indexOf('g') > -1) {
                        if (item.item_path === '') return;
                        stateService.go(item.item_state, item.item_parms);
                    }
                };

                vm.goCustom = function (l_item, e) {
                    if (isCustomClick(l_item)) return;
                    if (l_item.item_id === '') return;

                    let child = null;

                    if (vm.model.inlineEditMode === true) {
                        $(this).blur();
                        vm.model.currentInline = l_item;
                        if (angular.isUndefined(e)) return;
                        child = findClosestChild(e.currentTarget.children[1], e.clientX - e.currentTarget.children[1].offsetLeft);
                        gridClick(child, 'edit');
                        return;
                    }

                    if (vm.ltype.indexOf('custom') <= -1 && (angular.isUndefined(vm.typevar) || vm.typevar.indexOf('g') <= -1) && vm.model.inlineEditMode === false) {
                        vm.model.currentInline = l_item;
                        if (angular.isUndefined(e)) return;
                        child = findClosestChild(e.currentTarget.children[1], e.clientX - e.currentTarget.children[1].offsetLeft);
                        gridClick(child, 'item_func');
                        if (vm.model.gridClicked) {
                            return;
                        }
                    }

                    if (angular.isFunction(vm.optionfunc)) {
                        vm.optionfunc({ item: l_item });
                    }

                    if (angular.isDefined(vm.typevar) && vm.typevar.indexOf('g') > -1) {
                        if (l_item.item_path === '') return;
                        stateService.go(l_item.item_state, l_item.item_parms);
                    }
                };

                vm.addMark = function (item) {
                    let markClass = '';

                    if (item.item_is_selected === '1') {
                    }

                    return markClass;
                };

                const cuttingPoints = [
                    { first: 'col-md-11-25 col-sm-11 col-xs-10-75 col-xx-10-25', second: 'col-xx-0', view: 'col-md-0-25 col-sm-0-25 col-xs-0-25 col-xx-0-25', noGlyphs: 'col-md-11-5 col-sm-11-5 col-xs-11-25 col-xx-11', moreGlyphs: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5' },
                    { first: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5', second: 'col-xx-0', view: 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75', noGlyphs: 'col-md-11 col-sm-11 col-xs-10-5 col-xx-10-25', moreGlyphs: 'col-md-10-75 col-sm-10-25 col-xs-9-25 col-xx-8-75' },
                    { first: 'col-md-10-75 col-sm-10 col-xs-9-5 col-xx-8-75', second: 'col-xx-0', view: 'col-md-0-5 col-sm-1 col-xs-1 col-xx-1-5', noGlyphs: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5', moreGlyphs: 'col-md-10-5 col-sm-9-75 col-xs-8-75 col-xx-8' },
                    { first: 'col-md-10-5 col-sm-9-5 col-xs-9 col-xx-8', second: 'col-xx-0', view: 'col-md-0-75 col-sm-1-5 col-xs-1-5 col-xx-2-25', noGlyphs: 'col-md-10-75 col-sm-10 col-xs-9-5 col-xx-8-75', moreGlyphs: 'col-md-10-25 col-sm-9-25 col-xs-8-25 col-xx-7-25' },
                    { first: 'col-md-10-25 col-sm-9 col-xs-8-5 col-xx-7-25', second: 'col-xx-0', view: 'col-md-1 col-sm-2 col-xs-2 col-xx-3', noGlyphs: 'col-md-10-5 col-sm-9-5 col-xs-9 col-xx-8', moreGlyphs: 'col-md-10 col-sm-8-75 col-xs-7-75 col-xx-6-5' },
                    { first: 'col-md-10 col-sm-8-5 col-xs-8 col-xx-6-5', second: 'col-xx-0', view: 'col-md-1-25 col-sm-2-5 col-xs-2-5 col-xx-3-75', noGlyphs: 'col-md-10-25 col-sm-9 col-xs-8-5 col-xx-7-25', moreGlyphs: 'col-md-9-75 col-sm-8-25 col-xs-7-25 col-xx-5-75' },
                    { first: 'col-md-9-75 col-sm-8 col-xs-7-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-1-5 col-sm-3 col-xs-3 col-xx-4-5', noGlyphs: 'col-md-10 col-sm-8-5 col-xs-8 col-xx-6-5', moreGlyphs: 'col-md-9-5 col-sm-7-75 col-xs-6-75 col-xx-5' },
                    { first: 'col-md-9-5 col-sm-7-5 col-xs-7 col-xx-5-75', second: 'col-xx-0', view: 'col-md-1-75 col-sm-3-5 col-xs-3-5 col-xx-4-5', noGlyphs: 'col-md-9-75 col-sm-8 col-xs-7-5 col-xx-6-5', moreGlyphs: 'col-md-9-25 col-sm-7-25 col-xs-6-25 col-xx-5' },
                    { first: 'col-md-9-25 col-sm-7 col-xs-6-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2 col-sm-4 col-xs-4 col-xx-4-5', noGlyphs: 'col-md-9-5 col-sm-7-5 col-xs-7 col-xx-6-5', moreGlyphs: 'col-md-9 col-sm-6-75 col-xs-5-75 col-xx-5' },
                    { first: 'col-md-9 col-sm-6-5 col-xs-6 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-25 col-sm-4-5 col-xs-4-5 col-xx-4-5', noGlyphs: 'col-md-9-25 col-sm-7 col-xs-6-5 col-xx-6-5', moreGlyphs: 'col-md-8-75 col-sm-6-25 col-xs-5-25 col-xx-5' },
                    { first: 'col-md-8-75 col-sm-6 col-xs-5-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-5 col-sm-5 col-xs-5 col-xx-4-5', noGlyphs: 'col-md-9 col-sm-6-5 col-xs-6 col-xx-6-5', moreGlyphs: 'col-md-8-5 col-sm-5-75 col-xs-4-75 col-xx-5' },
                    { first: 'col-md-8-5 col-sm-5-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-75 col-sm-5-5 col-xs-5-5 col-xx-4-5', noGlyphs: 'col-md-8-75 col-sm-6 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-8-25 col-sm-5-25 col-xs-4-25 col-xx-5' },
                    { first: 'col-md-8-25 col-sm-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-3 col-sm-6 col-xs-6 col-xx-4-5', noGlyphs: 'col-md-8-5 col-sm-5-5 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-8 col-sm-4-75 col-xs-4-75 col-xx-5' },
                    { first: 'col-md-4-75 col-sm-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-xx-0', noGlyphs: 'col-md-5 col-sm-5-5 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-4-5 col-sm-4-5 col-xs-4-25 col-xx-5' }
                ];

                const findCuttingPoint = function (cuttingLength, condition, noGlyphs, moreGlyphs, colView) {
                    let index = Math.floor(cuttingLength / 2 + 0.5);

                    if (index < 1 || index > 12) {
                        index = 13;
                    }

                    let first = moreGlyphs === true ? 'moreGlyphs' : noGlyphs === true ? 'noGlyphs' : 'first';
                    let second = colView === true ? 'view' : 'second';

                    let cuttingPoint1st = cuttingPoints[index][first];
                    let cuttingPoint2nd = cuttingPoints[index][second];

                    if (condition === 'cuttingPoint1st') return cuttingPoint1st;

                    return condition === 'cuttingPoint2nd' ? cuttingPoint2nd : '';
                };

                vm.addCol = function (condition, item) {
                    let allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
                    let noGlyphs = true;
                    let moreGlyphs = false;
                    let cuttingPoint1st = '';
                    let cuttingPoint2nd = '';

                    if (angular.isDefined(vm.model.glyphlist) && vm.model.glyphlist.length > 0) {
                        for (let i = 0; i < vm.model.glyphlist.length; i++) {
                            if (vm.model.glyphlist[i].length > 0 && angular.isDefined(vm.model.glyphlist[i][0])) {
                                if (vm.model.glyphlist[i][0].item_glyphicon === '') continue;

                                noGlyphs = false;

                                if (vm.model.glyphlist[i].length > 1) {
                                    moreGlyphs = true;

                                    break;
                                }
                            }
                        }
                    }

                    if (angular.isDefined(vm.listItems) && vm.listItems.length > 0) {
                        let columnNo = 0;
                        let foundRowCol = false;

                        for (let k = 0; k < vm.model.item_secondary.length; k++) {
                            for (let j = 0; j < vm.model.secondary[k].length; j++) {
                                if (item === vm.model.secondary[k][j]) {
                                    columnNo = j;

                                    foundRowCol = true;

                                    break;
                                }
                            }
                            if (foundRowCol === true) {
                                break;
                            }
                        }

                        let fullLength = 0;
                        let tempLength = 0;

                        vm.model.colList.sort(function (a, b) {
                            return parseFloat(a.priority) - parseFloat(b.priority);
                        });

                        for (let l = 0; l < vm.model.colList.length; l++) {
                            if (vm.model.colList[l].visible !== true) continue;

                            if (vm.model.colList[l].colLength % 2 === 0) {
                                tempLength += vm.model.colList[l].colLength;
                            } else {
                                tempLength += vm.model.colList[l].colLength + 1;
                            }

                            vm.model.colList[l].viewXX = false;
                            vm.model.colList[l].viewXS = false;
                            vm.model.colList[l].viewSM = false;
                            vm.model.colList[l].viewMD = false;
                            vm.model.colList[l].viewLG = false;

                            switch (true) {
                                case tempLength < 12:
                                    vm.model.colList[l].viewXX = true;
                                    vm.model.colList[l].viewXS = true;
                                    vm.model.colList[l].viewSM = true;
                                    vm.model.colList[l].viewMD = true;
                                    vm.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 20:
                                    vm.model.colList[l].viewXS = true;
                                    vm.model.colList[l].viewSM = true;
                                    vm.model.colList[l].viewMD = true;
                                    vm.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 28:
                                    vm.model.colList[l].viewSM = true;
                                    vm.model.colList[l].viewMD = true;
                                    vm.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 45:
                                    vm.model.colList[l].viewMD = true;
                                    vm.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 60:
                                    vm.model.colList[l].viewLG = true;
                                    break;
                            }
                        }

                        vm.model.colList.sort(function (a, b) {
                            return parseFloat(a.orderby) - parseFloat(b.orderby);
                        });

                        for (let m = 0; m < vm.model.colList.length; m++) {
                            let checkSize = function (size) {
                                if (vm.model.currentSize !== size) return;

                                vm.model.colList[m].view = vm.model.colList[m]['view' + size.toUpperCase()] || false;
                            };

                            checkSize('xx');
                            checkSize('xs');
                            checkSize('sm');
                            checkSize('md');
                            checkSize('lg');
                        }

                        if (condition === 'main') {
                            for (let n = 0; n < vm.model.colList.length; n++) {
                                if (vm.model.colList[n].view === true && vm.model.colList[n].visible === true) {
                                    if (vm.model.colList[n].colLength % 2 === 0) {
                                        fullLength += vm.model.colList[n].colLength;
                                    } else {
                                        fullLength += vm.model.colList[n].colLength + 1;
                                    }
                                }
                            }
                        } else {
                            fullLength = vm.model.colList[columnNo].colLength;
                        }

                    }

                    cuttingPoint2nd = findCuttingPoint(fullLength, 'cuttingPoint2nd', noGlyphs, moreGlyphs, vm.model.colList[columnNo].view);
                    cuttingPoint1st = findCuttingPoint(fullLength, 'cuttingPoint1st', noGlyphs, moreGlyphs, false);

                    if (condition === 'leftGlyph') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
                        if (noGlyphs === true && (angular.isUndefined(vm.mconfig) || vm.mconfig.enabled === false)) {
                            allClass = 'col-xx-0 im-no-pad';
                        }
                        if (moreGlyphs === true || (angular.isDefined(vm.mconfig) && vm.mconfig.enabled === true)) {
                            allClass = 'col-md-0-5 col-sm-0-75 col-xs-1-25 col-xx-1-5 im-no-pad';
                        }
                    } else if (condition === 'rightGlyph') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';

                        if (angular.isDefined(vm.typevar) && vm.typevar.indexOf('g') > -1) {
                            allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-chevron-right grayish pull-right im-chevron';
                        }
                    } else if (condition === 'rightGlyph2') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-chevron-right grayish pull-right im-chevron-2';
                    } else if (condition === 'nonGlyph') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';
                    } else if (condition === 'main') {
                        allClass = cuttingPoint1st + ' im-no-pad listview_text';
                    } else if (condition === 'secondary') {
                        if (vm.model.colList[columnNo].alignment === 'left') {
                            allClass = cuttingPoint2nd + ' im-no-pad im-l-c-l pull-right';
                        } else if (vm.model.colList[columnNo].alignment === 'center') {
                            allClass = cuttingPoint2nd + ' im-no-pad im-l-c-c pull-right';
                        } else {
                            allClass = cuttingPoint2nd + ' im-no-pad im-l-c-r pull-right';
                        }
                    }

                    return allClass;
                };

                vm.addColArea = function (condition, item) {
                    let allClass = '';
                    let menuBtnInList = false;
                    let glyphCount = 0;
                    let leftArea = 'im-no-pad';
                    let centerArea = 'im-no-pad';
                    let rightArea = ''; //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75

                    if (angular.isDefined(vm.mconfig) && vm.mconfig.enabled === true && vm.mconfig.show === false) {
                        menuBtnInList = true;
                    }

                    if (angular.isDefined(vm.model.glyphlist) && vm.model.glyphlist.length > 0) {
                        for (let g = 0; g < vm.model.glyphlist.length; g++) {
                            if (vm.model.glyphlist[g].length > 0 && angular.isDefined(vm.model.glyphlist[g][0])) {
                                if (vm.model.glyphlist[g][0].item_glyphicon === '') continue;

                                if (vm.model.glyphlist[g].length > glyphCount) {
                                    glyphCount = vm.model.glyphlist[g].length;
                                }
                            }
                        }
                    }

                    if (menuBtnInList === true && glyphCount < 2) {
                        glyphCount = 2;
                    }

                    switch (vm.model.currentSize) {
                        case 'xx': // 0-75 per glyph //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                            if (glyphCount === 0) {
                                leftArea += ' col-xx-0';
                                centerArea += ' col-xx-11-25';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-xx-0-75';
                                centerArea += ' col-xx-10-5';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-xx-1-5';
                                centerArea += ' col-xx-9-75';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-xx-2-25';
                                centerArea += ' col-xx-9';
                            } else {
                                leftArea += ' col-xx-3';
                                centerArea += ' col-xx-8-25';
                            }
                            rightArea += 'col-xx-0-75';
                            break;
                        case 'xs': // 0-75 per glyph, 0-5 per glyph, alternating //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                            if (glyphCount === 0) {
                                leftArea += ' col-xs-0';
                                centerArea += ' col-xs-11-5';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-xs-0-75';
                                centerArea += ' col-xs-10-75';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-xs-1-25';
                                centerArea += ' col-xs-10-25';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-xs-2';
                                centerArea += ' col-xs-9-5';
                            } else {
                                leftArea += ' col-xs-2-5';
                                centerArea += ' col-xs-9';
                            }
                            rightArea += 'col-xs-0-5';
                            break;
                        case 'sm': // 0-5, then 0-25, then 0-25, then 0-5 //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                            if (glyphCount === 0) {
                                leftArea += ' col-sm-0';
                                centerArea += ' col-sm-11-5';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-sm-0-5';
                                centerArea += ' col-sm-11';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-sm-0-75';
                                centerArea += ' col-sm-10-75';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-sm-1';
                                centerArea += ' col-sm-10-5';
                            } else {
                                leftArea += ' col-sm-1-5';
                                centerArea += ' col-sm-10';
                            }
                            rightArea += 'col-sm-0-5';
                            break;
                        case 'md':
                        case 'lg': // 0-25 per glyph //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                        default:
                            if (glyphCount === 0) {
                                leftArea += ' col-md-0';
                                centerArea += ' col-md-11-75';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-md-0-25';
                                centerArea += ' col-md-11-5';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-md-0-5';
                                centerArea += ' col-md-11-25';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-md-0-75';
                                centerArea += ' col-md-11';
                            } else {
                                leftArea += ' col-md-1';
                                centerArea += ' col-md-10-75';
                            }
                            rightArea += 'col-md-0-25';
                    }

                    if (['leftArea','leftAreaSlider'].includes(condition)) {
                        allClass = leftArea;
                    } else if (['centerArea', 'centerAreaSlider'].includes(condition)) {
                        allClass = centerArea;
                    } else if (['rightArea', 'rightAreaSlider'].includes(condition)) {
                        allClass = rightArea;

                        if (condition !== 'rightAreaSlider' && item.item_id !== '0' && item.item_id !== '-1') {
                            if (vm.typevar && vm.typevar.indexOf('g') > -1) {
                                allClass += ' glyphicon glyphicon-chevron-right grayish pull-right im-chevron';
                            } else {
                                allClass += ' glyphicon glyphicon-none grayish pull-right';
                            }
                        }
                    } else if (condition === 'nonGlyph') {
                        allClass = leftArea + ' glyphicon glyphicon-none grayish pull-right';
                    }

                    if (vm.model.inlineEditMode) {
                        allClass += ' inline-edit-mode-parent';
                    }

                    return allClass;
                };

                vm.addColItem = function (item) {
                    let allClass = '';

                    if (item === 'main') {
                        allClass = 'listview_text im-no-pad';

                        if (vm.model.inlineEditMode) {
                            allClass += ' inline-edit-mode';
                        }
                    } else if (vm.listItems?.length) {
                        let columnNo = 0;
                        let foundRowCol = false;

                        for (let k = 0; k < vm.model.secondary.length; k++) {
                            for (let j = 0; j < vm.model.secondary[k].length; j++) {
                                if (item === vm.model.secondary[k][j]) {
                                    columnNo = j;

                                    foundRowCol = true;

                                    break;
                                }
                            }
                            if (foundRowCol === true) {
                                break;
                            }
                        }
                        allClass = `im-no-pad im-l-c-${vm.model.colList[columnNo].alignment.substring(0, 1)} pull-right`;

                        if (vm.model.inlineEditMode) {
                            allClass += ' inline-edit-mode';
                        }
                    }

                    return allClass;
                };

                vm.addMenuGlyph = function (item) {
                    let allClass = ''; //default

                    switch (item) {
                        case 'plus':
                        case 'minus':
                        case 'trash':
                        case 'sort': allClass = `glyphicon glyphicon-${item} btn btn-primary btn-sm multi-list-menu-btn pull-right`; break;
                        case 'switch': allClass = 'glyphicon glyphicon-list-alt btn btn-primary btn-sm multi-list-btn'; break;
                        case 'resize': allClass = 'glyphicon glyphicon-resize-horizontal btn btn-primary btn-sm multi-list-menu-btn pull-right'; break;
                        case 'columns': allClass = 'fa fa-columns imp-12 btn btn-primary btn-sm multi-list-menu-btn pull-right'; break;
                        case 'edit': allClass = 'fa fa-edit imp-12 btn btn-primary btn-sm multi-list-menu-btn pull-right'; break;
                        default: {
                            if (angular.isObject(item) && item.glyph) {
                                if (item.glyph.startsWith('fa') && [2, 3].includes(item.glyph.indexOf('-'))) {
                                    allClass = `im-no-mar ${item.glyph.slice(0, item.glyph.indexOf('-'))} fa${item.glyph.slice(item.glyph.indexOf('-'))}`;
                                } else {
                                    allClass = 'glyphicon ' + item.glyph;
                                }

                                if (item.isIcon === true) {
                                    if (item.animate === true) {
                                        allClass += ' fadeinout-animate btn btn-light btn-sm multi-list-menu-icon-warning pull-right no-h';
                                    } else if (item.animate === false) {
                                        allClass = 'glyphicon glyphicon-saved fadeout-animate btn btn-light btn-sm multi-list-menu-icon-success pull-right no-h';
                                    } else {
                                        allClass = 'glyphicon glyphicon-none btn btn-light btn-sm multi-list-menu-icon pull-right no-h';
                                    }
                                } else {
                                    if (item.canActive === true && item.isActive === true) {
                                        allClass += ' btn btn-' + item.colorActive;
                                    } else {
                                        if (angular.isDefined(item.color)) {
                                            allClass += ' btn btn-' + item.color;
                                        } else {
                                            allClass += ' btn btn-primary';
                                        }
                                    }

                                    allClass += ' btn-sm multi-list-menu-btn pull-right';
                                }
                            }
                        }
                    }
                    return allClass;
                };

                vm.addGlyph = function (item) {
                    let allClass = ''; //default

                    if (item === 'switch') {
                        allClass = 'glyphicon glyphicon-list-alt btn btn-primary btn-sm multi-list-btn';
                    } else if (item === 'sort') {
                        allClass = 'glyphicon glyphicon-sort';
                    } else if (angular.isDefined(vm.ltype) && vm.ltype === 'check' && !item.item_glyphicon) {
                        item.item_glyphicon = 'glyphicon-save';
                    }

                    if (item.item_glyphicon?.length) {
                        const glyphStr = item.item_glyphicon_2 ?? item.item_glyphicon;

                        if (glyphStr.startsWith('fa') && [2, 3].includes(glyphStr.indexOf('-'))) {
                            allClass = `im-no-mar ${glyphStr.slice(0, glyphStr.indexOf('-'))} fa${glyphStr.slice(glyphStr.indexOf('-'))}`;
                        } else {
                            allClass = 'glyphicon ' + glyphStr;
                        }
                    }

                    return allClass;
                };

                vm.addClass = function () {
                    let allClass = '';

                    allClass = 'well well-sq sort-list-container col-xs-12';

                    if (angular.isDefined(vm.model.sortlist) && vm.model.sortlist !== null && vm.model.sortlist.length > 0) {
                        if (vm.model.sortlist.length === 2) {
                            allClass = 'well well-sq sort-list-container col-xs-6';
                        } else if (vm.model.sortlist.length === 3) {
                            allClass = 'well well-sq sort-list-container col-xx-4';
                        } else if (vm.model.sortlist.length === 4) {
                            allClass = 'well well-sq sort-list-container col-xx-6 col-xs-6 col-sm-3 col-lg-3';
                        } else if (vm.model.sortlist.length === 6) {
                            allClass = 'well well-sq sort-list-container col-xx-4 col-xs-2';
                        } else if (vm.model.sortlist.length === 8) {
                            allClass = 'well well-sq sort-list-container col-xx-1-5';
                        } else if (vm.model.sortlist.length === 12) {
                            allClass = 'well well-sq sort-list-container col-xs-1';
                        } else {
                            allClass = 'well well-sq sort-list-container col-xs-12';
                        }
                    }

                    return allClass;
                };

                vm.addThumbClass = function (item) {
                    let allClass = '';

                    if (angular.isDefined(item.item_thumb) && item.item_thumb !== null && item.item_thumb.length > 0) {

                        allClass = 'im-list-btn--flex';
                    }

                    return allClass;
                };

                vm.addItemClass = function (item) {
                    let allClass = 'sort-list-item btn btn-default listview btn-block btn-lg col-xs-12';

                    if (angular.isDefined(item) && item.item_type === 'info') {
                        allClass += ' not-sortable';
                    }

                    return allClass;
                };

                vm.addStyle = function (item) {
                    let allStyle = { color: 'black', width: '19px' }; //default

                    if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color !== null && item.item_glyphicon_color !== "black") {
                        allStyle = { color: item.item_glyphicon_color, width: '19px' };
                        if (vm.model.initialGlyphColor === '') {
                            vm.model.initialGlyphColor = item.item_glyphicon_color;
                        }
                    }

                    if (item === 'resize') {
                        let width = isNumber(vm.model.fieldWidth) && parseInt(vm.model.fieldWidth) > parseInt(vm.model.fieldWidthMin) ? vm.model.fieldWidth : vm.model.fieldWidthMin;

                        allStyle = {
                            fontSize: vm.fontSizes.textSize,
                            width: width + '%',
                            minWidth: vm.model.fieldWidthMin + '%',
                            maxWidth: '100%',
                            textOverflow: 'ellipsis',
                            textAlign: 'center'
                        };
                    }

                    return allStyle;
                };

                vm.addStyleMultiListBtn = function (item) {
                    let allStyle = { fontSize: vm.fontSizes.textSize, paddingBottom: 0 }; //default

                    if (item?.item_id === '0' && vm.mconfig?.enabled === true && vm.mconfig.show === false) {
                        allStyle = { fontSize: vm.fontSizes.textSize };
                    }

                    return allStyle;
                };

                vm.addStyleCenterAreaItem = function (item, parentItem) {
                    let allStyle = { fontSize: vm.fontSizes.textSize, display: 'inline-block' }; //default

                    if (item) {
                        if (item.item_id === '0') {

                            const width = isNumber(item.item_col_width) && parseInt(item.item_col_width) >= parseInt(vm.model.fieldWidthMin) ? item.item_col_width : vm.model.fieldWidthMin;
                            allStyle = Object.assign(allStyle, { width: width + '%', minWidth: vm.model.fieldWidthMin + '%' });

                        } else {
                            let columnNo = 0;
                            let foundRowCol = false;

                            for (let k = 0; k < vm.model.secondary.length; k++) {
                                for (let j = 0; j < vm.model.secondary[k].length; j++) {
                                    if (item === vm.model.secondary[k][j]) {
                                        columnNo = j;

                                        foundRowCol = true;

                                        break;
                                    }
                                }
                                if (foundRowCol === true) {
                                    break;
                                }
                            }
                            
                            if (foundRowCol) {
                                const width = isNumber(vm.model.colList[columnNo].width)
                                    && parseInt(vm.model.colList[columnNo].width) >= parseInt(vm.model.fieldWidthMin)
                                    ? vm.model.colList[columnNo].width
                                    : vm.model.fieldWidthMin;
                                const display = !vm.model.colList[columnNo]?.visible ? 'none' : 'inline-block';
                                allStyle = Object.assign(allStyle, { width: width + '%', minWidth: vm.model.fieldWidthMin + '%', display: display });

                            } else {
                                const width = isNumber(vm.listItems[0].item_col_width) && parseInt(vm.listItems[0].item_col_width) > parseInt(vm.model.fieldWidthMin) ? vm.listItems[0].item_col_width : vm.model.fieldWidthMin;
                                allStyle = Object.assign(allStyle, { width: width + '%', minWidth: vm.model.fieldWidthMin + '%' });
                            }
                        }

                        const tempItem = parentItem || item;
                        if (vm.model.inlineEditMode && tempItem.item_id !== '0' && tempItem.item_id !== '-1' && item.item_editable === 'true') {
                            allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                        }

                        if (vm.model.inlineEditMode) {
                            allStyle = Object.assign(allStyle, { lineHeight: '1' });
                        }
                    }

                    return allStyle;
                };

                vm.addStyleWell = function (item) {
                    vm.model.initialGlyphColor = vm.model.initialGlyphColor || item?.item_glyphicon_color || '';
                    return {
                        background: item?.item_glyphicon_color || '#f9f9f9',
                        color: item?.item_glyphicon_color ? 'white' : 'black'
                    };
                };

                vm.addStyleSortlist = function (item) {
                    let allStyle = { minWidth: '19px' }; //default

                    return allStyle;
                };

                vm.removeHover = function (item) {
                    return (item?.item_id === '0' || item?.item_id === '-1') ? 'no-h' : vm.model.inlineEditMode ? 'inline-edit-mode-div' : '';
                };

                vm.addInputStyle = function () {
                    let allStyle = ''; //default

                    if ((vm.model.activeInlineId ?? null) !== null) {
                        const textAlign = vm.model.activeInlineId.indexOf('-imp-') > - 1 ? vm.model.currentInline?.alignment ?? ''
                            : vm.model.activeInlineId.indexOf('-ims-') > -1 ? vm.model.currentInline?.item_secondary[sIdx]?.alignment ?? '' : '';
                        if (textAlign) allStyle = { textAlign: textAlign };
                    }
                    return allStyle;
                };

                const sizeChanged = function (_, data) {
                    vm.model.currentSize = data.current;
                };

                //const dereg = $rootScope.$on('event:responsive-changed', sizeChanged);

                //vm.$on('$destroy', function () {
                //    if (angular.isFunction(dereg) === true) {
                //        dereg();
                //    }
                //});
                vm.$onDestroy = function () {
                    angular.forEach(onDestroy, function (fn) {
                        if (angular.isFunction(fn) === true) {
                            fn();
                        }
                    });
                };

                vm.thisFocus = function (item, role, index) {
                    return item?.item_id && item.item_id === vm.model.currentInline?.item_id && (vm.model.activeInlineId?.slice(0, (vm.model.activeInlineId.indexOf('-' + role + '-') + 5)) === (index + '-' + role + '-').toString());
                };

                vm.inputFocus = function (e) {
                    if (vm.model.elemInFocus?.setSelectionRange) vm.model.elemInFocus.setSelectionRange(0, vm.model.elemInFocus.value.length);
                };

                vm.inputBlur = function (e) {
                    if (vm.model.currentInline === null || vm.model.activeInlineId === null) {
                        return;
                    }
                    vm.model.inlineEditUpdate = true;
                    let foundItem = false;

                    for (let r = 0; r < vm.listItems.length; r++) {
                        if (vm.model.currentInline.item_id === vm.listItems[r].item_id) {
                            if (vm.model.activeInlineId.indexOf('-imp-') > -1) {
                                vm.listItems[r].item_name = $(e.target).val();
                                foundItem = true;
                            } else if (vm.model.activeInlineId.indexOf('-ims-') > -1) {
                                let id = vm.model.activeInlineId.slice(0, vm.model.activeInlineId.indexOf('-ims-'));
                                vm.listItems[r].item_secondary[id].item_col = $(e.target).val();
                                foundItem = true;
                            }

                            if (foundItem) break;
                        }
                    }

                    vm.model.elemInFocus = null;
                    vm.model.currentInline = null;
                    vm.model.activeInlineId = null;
                    vm.model.isKeypress = false;
                };

                vm.fontSizes = {
                    textSize: '',
                    textSizeS: '',
                    textSizeSs: '',
                    thumbnail: '50'
                };
                
                layoutService.onLayoutChanged(onDestroy, function (info) {
                    if (angular.isUndefined(info)) return;
                    
                    angular.copy(info.fontSizes, vm.fontSizes);

                    vm.model.deleteButtonStyle.fontSize = info.fontSizes.textSizeSs;
                    vm.model.listViewTextStyle.fontSize = info.fontSizes.textSize;
                    vm.model.listViewTextStyle.paddingLeft = info.paddingLeft + 'px';
                    vm.model.thumbnailStyle.width = info.fontSizes.thumbnail + 'px';

                    vm.style.button.fontSize = info.fontSizes.textSize;
                });

                const populateSortlistFromMenu = function () {

                    vm.model.sortlist.length = 0;

                    let didChange = false;

                    vm.model.sortlist.push({ key: '0', keyName: 'sortlist', value: null, valueName: null, item_glyphicon: null, item_glyphicon_color: null, items: [] });

                    if (angular.isUndefined(vm.listItems) || vm.listItems === null) return;

                    if (angular.isDefined(vm.listItems) && vm.listItems !== null && angular.isDefined(vm.listItems.records) && vm.listItems.records !== null) {
                        angular.forEach(vm.listItems.records, function (item) {
                            if (angular.isDefined(item)) {
                                for (let i = 0; i < vm.model.sortlist.length; i++) {
                                    if (vm.model.sortlist[i].key === '0') {
                                        vm.model.sortlist[i].items.push(item);
                                        didChange = true;
                                        break;
                                    }
                                }
                            }
                        });
                    } else if (angular.isDefined(vm.listItems) && vm.listItems !== null) {

                        angular.forEach(vm.listItems, function (item) {
                            if (angular.isDefined(item)) {
                                for (let i = 0; i < vm.model.sortlist.length; i++) {
                                    if (vm.model.sortlist[i].key === '0') {
                                        vm.model.sortlist[i].items.push(item);
                                        didChange = true;
                                        break;
                                    }
                                }
                            }
                        });
                    }

                    if (didChange) {
                    }
                };

                let groupTimer;
                const populateSortlist = function (newValue, oldValue) {
                    if (newValue === oldValue || !angular.isObject(vm.sortItems) || !angular.isObject(vm.listItems)) return;

                    const doPopulate = function () {
                        vm.model.sortlist.length = 0;

                        let didChange = false;

                        angular.forEach(vm.sortItems, function (item) {
                            if (vm.dataid && vm.dataname && item?.[vm.dataid] && item[vm.dataname] && item[vm.dataid] !== '0') {
                                vm.model.sortlist.push({
                                    key: item[vm.dataid],
                                    keyName: vm.dataid,
                                    value: item[vm.dataname],
                                    valueName: vm.dataname,
                                    item_glyphicon: item.glyphicon,
                                    item_glyphicon_color: item.glyphicon_color,
                                    items: []
                                });
                            }
                        });

                        const addItemsToSortlist = function (array) {
                            angular.forEach(array, function (item) {
                                if (angular.isObject(item)) {
                                    for (let i = 0; i < vm.model.sortlist.length; i++) {
                                        if (vm.model.sortlist[i].key === item.item_sort_id) {
                                            vm.model.sortlist[i].items.push(item);
                                            didChange = true;
                                            break;
                                        }
                                    }
                                }
                            });
                        }

                        if (vm.listItems?.records?.length) {
                            addItemsToSortlist(vm.listItems.records);
                        } else if (vm.listItems?.length) {
                            addItemsToSortlist(vm.listItems);
                        }

                        if (didChange) {
                            console.log('List sort changed');
                        }
                    }

                    if (groupTimer) $timeout.cancel(groupTimer);

                    groupTimer = $timeout(doPopulate, 5000);
                };

                // ###############
                // FUNCTIONS START
                // ###############

                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                function isNullOrWhitespace(input) {
                    if (typeof input === 'undefined' || input === null) return true;
                    return input.trim().length < 1;
                }

                function escapeRegExp(str) {
                    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                }

                function gridClick(child, gridMode) {
                    vm.model.gridClicked = false;

                    let id = child?.id ?? child?.parentElement?.id ?? '';

                    if (id === '' || id === vm.model.activeInlineId) return;

                    let sIdx = null;

                    if (gridMode === 'edit') {
                        if (id.indexOf('-imp-') > -1) {
                            if (vm.model.currentInline.editable === 'false') return;
                        } else if (id.indexOf('-ims-') > -1) {
                            sIdx = parseInt(id.slice(0, id.indexOf('-ims-')));
                            if (vm.model.currentInline.item_secondary[sIdx].item_editable === 'false') return;
                        }

                        vm.model.activeInlineId = id;
                        contentEditable(child, id);
                    } else if (gridMode === 'item_func') {
                        vm.model.gridClicked = true;
                        if (id.indexOf('-imp-') > -1) {
                            vm.optionfunc({ item: { item: vm.model.currentInline, col: null } });
                        } else if (id.indexOf('-ims-') > -1) {
                            sIdx = parseInt(id.slice(0, id.indexOf('-ims-')));
                            vm.optionfunc({ item: { item: vm.model.currentInline, col: sIdx } });
                        }
                    } else {
                        console.log('gridMode not set');
                    }
                }

                function innerChild(child) {
                    let lastChild = null;

                    if (child.children.length > 1 && child.children[1].nodeName === 'INPUT') {
                        lastChild = innerChild(child.children[1]);
                    } else if (child.children.length > 0 && child.children[0].nodeName !== 'INPUT') {
                        lastChild = innerChild(child.children[0]);
                    } else {
                        lastChild = child;
                    }

                    return lastChild;
                }

                function shiftRowChild(child, rIdx, cIdx) {
                    let lastChild = null;

                    if (angular.isUndefined(child) || angular.isUndefined(child.offsetParent)) {
                        return lastChild;
                    } else if (child.className === 'col-xs-12' && child.nodeName === 'DIV' && child.children.length > 1) {
                        if (cIdx !== 0) cIdx = vm.model.currentInline.item_secondary.length + 1 - cIdx;

                        lastChild = innerChild(child.children[rIdx].children[0].children[0].children[1].children[cIdx]);
                    } else {
                        if (child.offsetParent === null) {
                            lastChild = shiftRowChild(child.parentElement.offsetParent, rIdx, cIdx);
                        } else {
                            lastChild = shiftRowChild(child.offsetParent, rIdx, cIdx);
                        }
                    }

                    return lastChild;
                }

                function findClosestChild(target, point) {
                    let closestChild = -1;

                    for (let c = 0; c < target.children.length; c++) {
                        if (point >= (target.children[c].offsetLeft) && point <= (target.children[c].offsetLeft + target.children[c].offsetWidth)) {
                            closestChild = c;
                            break;
                        }
                    }

                    if (closestChild < 0) {
                        console.log('didnt find closest');
                        return null;
                    } else {
                        const lastChild = innerChild(target.children[closestChild]);
                        return lastChild;
                    }
                }

                function inlineEditClick(child, editmode) {
                    if (angular.isUndefined(child) || child === null) return;
                    let id = angular.isDefined(child.id) ? child.id : '';
                    if (id === '') {
                        if (angular.isUndefined(child.parentElement) || child.parentElement === null) return;
                        id = angular.isDefined(child.parentElement.id) ? child.parentElement.id : '';
                    }

                    if (id === '' || id === vm.model.activeInlineId) return;

                    if (id.indexOf('-imp-') > -1) {
                        if (vm.model.currentInline.editable === 'false') return;
                    } else if (id.indexOf('-ims-') > -1) {
                        let sIdx = parseInt(id.slice(0, id.indexOf('-imp-')));
                        if (vm.model.currentInline.item_secondary[sIdx].item_editable === 'false') return;
                    }

                    vm.model.activeInlineId = id;
                    contentEditable(child, id);
                }

                function contentEditable(child, id) {
                    let elem = angular.element('#' + id)[0];
                    vm.model.elemInFocus = elem;
                    $timeout(function () {
                        elem.focus();
                    }, 10);

                    $element.find('#' + id).on('keydown', function (e) {
                        if (!vm.model.prosessingKeypress && vm.model.currentInline !== null && vm.model.activeInlineId !== null
                            && (['Tab', 'Enter', 'Escape'].includes(e.key) || (e.altKey && ['ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowLeft'].includes(e.key)))) {
                            let nextEditable = null;
                            let rIdx = null;
                            let cIdx = null;
                            let nIdx = null;
                            let nChild = null;
                            let gridMode = 'edit';

                            vm.model.prosessingKeypress = true;
                            e.preventDefault();
                            vm.model.isKeypress = true;
                            $(this).blur();

                            switch (e.key) {
                                case 'Tab':
                                case 'Enter': vm.model.gridDirection = e.shiftKey ? 'left' : 'right'; break;
                                //case 'Escape': vm.model.gridDirection = 'none'; break;
                                case 'ArrowLeft': vm.model.gridDirection = 'left'; break;
                                case 'ArrowUp': vm.model.gridDirection = 'up'; break;
                                case 'ArrowRight': vm.model.gridDirection = 'right'; break;
                                case 'ArrowDown': vm.model.gridDirection = 'down'; break;
                                default: vm.model.gridDirection = 'none';
                            }

                            if (vm.model.gridDirection !== 'none') {
                                nextEditable = findNextEditable(vm.model.currentInline, vm.model.activeInlineId, vm.model.gridDirection);
                                rIdx = nextEditable.row;
                                cIdx = nextEditable.col;

                                if (nextEditable.row === null || nextEditable.col === null) {
                                    vm.model.prosessingKeypress = false;
                                    return;
                                }

                                nIdx = cIdx;
                                nChild = shiftRowChild(child, rIdx, nIdx);

                                vm.model.currentInline = vm.listItems[rIdx];
                                $timeout(function () {
                                    gridClick(nChild, gridMode);
                                    vm.model.prosessingKeypress = false;
                                }, 10);
                            } else {
                                vm.model.currentInline = null;
                                vm.model.activeInlineId = null;
                                vm.model.prosessingKeypress = false;
                            }
                        }
                    });
                }

                function findNextEditable(currentItem, activeId, direction) {

                    let isPrime = null;
                    let row = null;
                    let col = null;

                    for (let r = 0; r < vm.listItems.length; r++) {
                        if (currentItem.item_id === vm.listItems[r].item_id) {
                            row = r;
                            break;
                        }
                    }

                    if (activeId.indexOf('-imp-') > -1) {
                        col = 0;
                        isPrime = true;
                    } else if (activeId.indexOf('-ims-') > -1) {
                        col = parseInt(activeId.slice(0, activeId.indexOf('-ims-'))) + 1;
                        isPrime = false;
                    }

                    if (isPrime === null) return;

                    let numCols = vm.listItems[0].item_secondary.length;
                    let rowAdjustment = 0;
                    let nextRow = null;
                    let nextCol = null;

                    switch (direction) {
                        case 'left':
                            col--;
                            if (angular.isDefined(vm.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined(vm.listItems[row + rowAdjustment]) || vm.listItems[row + rowAdjustment].item_id === '0' || vm.listItems[row + rowAdjustment].item_id === '-1') {
                                        console.log('left out of bounds');
                                        col = -2;
                                    } else if (col < 0) {
                                        col = numCols;
                                        rowAdjustment--;
                                    } else {
                                        if ((col === 0 && vm.listItems[row + rowAdjustment].editable === 'true') || (col !== 0 && vm.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true')) {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            col = -2;
                                        } else {
                                            col--;
                                        }
                                    }
                                } while (col >= -1);
                            } else {
                                console.log('left out of bounds');
                            }
                            break;
                        case 'up':
                            rowAdjustment--;
                            if (angular.isDefined(vm.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined(vm.listItems[row + rowAdjustment])) {
                                        console.log('up out of bounds');
                                        row = -1;
                                    } else if (col === 0) {
                                        if (vm.listItems[row + rowAdjustment].item_id !== '0' && vm.listItems[row + rowAdjustment].item_id !== '-1' && vm.listItems[row + rowAdjustment].editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment--;
                                        }
                                    } else {
                                        if (vm.listItems[row + rowAdjustment].item_id !== '0' && vm.listItems[row + rowAdjustment].item_id !== '-1' && vm.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment--;
                                        }
                                    }
                                } while (row >= 0);
                            } else {
                                console.log('up out of bounds');
                            }
                            break;
                        case 'right':
                            col++;
                            if (angular.isDefined(vm.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined(vm.listItems[row + rowAdjustment])) {
                                        console.log('right out of bounds');
                                        col = -2;
                                    } else if (col > numCols) {
                                        col = 0;
                                        rowAdjustment++;
                                    } else {
                                        if ((col === 0 && vm.listItems[row + rowAdjustment].editable === 'true') || (col !== 0 && vm.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true')) {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            col = -2;
                                        } else {
                                            col++;
                                        }
                                    }
                                } while (col >= -1);
                            } else {
                                console.log('right out of bounds');
                            }
                            break;
                        case 'down':
                            rowAdjustment++;
                            if (angular.isDefined(vm.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined(vm.listItems[row + rowAdjustment])) {
                                        console.log('up out of bounds');
                                        row = -1;
                                    } else if (col === 0) {
                                        if (vm.listItems[row + rowAdjustment].item_id !== '0' && vm.listItems[row + rowAdjustment].item_id !== '-1' && vm.listItems[row + rowAdjustment].editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment++;
                                        }
                                    } else {
                                        if (vm.listItems[row + rowAdjustment].item_id !== '0' && vm.listItems[row + rowAdjustment].item_id !== '-1' && vm.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment++;
                                        }
                                    }
                                } while (row >= 0);
                            } else {
                                console.log('down out of bounds');
                            }
                    }
                    return { row: nextRow, col: nextCol };
                }

                function validatingEditable(list) {
                    if (!angular.isObject(list)) return;

                    let headerItemIndex = null;

                    for (let i = 0; i < list.length; i++) {
                        if (list[i].item_id === '0') {
                            headerItemIndex = i;
                            break;
                        }
                    }

                    if (headerItemIndex === null) {
                        angular.forEach(list, function (item) {
                            if (angular.isObject(item)) {
                                item.editable = 'false';

                                if (angular.isDefined(item.item_secondary)) {
                                    for (let s = 0; s < item.item_secondary.length; s++) {
                                        item.item_secondary[s].item_editable = 'false';
                                    }
                                }
                            }
                        });
                    } else {
                        angular.forEach(list, function (item) {
                            if (angular.isDefined(item) && item.item_id !== '0') {
                                if (item.editable === '') {
                                    item.editable = list[headerItemIndex].editable;
                                }

                                if (angular.isDefined(item.item_secondary)) {
                                    for (let s = 0; s < item.item_secondary.length; s++) {
                                        if (item.item_secondary[s].item_editable === '') {
                                            item.item_secondary[s].item_editable = list[headerItemIndex].item_secondary[s].item_editable;
                                        }
                                    }
                                }
                            }
                        });
                    }

                    vm.model.editableValidated = true;
                }

                function checkModes() {
                    if (angular.isUndefined(vm.mconfig) || vm.mconfig.show !== true || angular.isUndefined(vm.mconfig.records)) return;
                    for (let r = 0; r < vm.mconfig.records.length; r++) {
                        if (vm.mconfig?.records[r]?.isIcon !== true
                            && vm.mconfig?.records[r]?.show === true
                            && vm.mconfig.records[r].enabled === true
                            && vm.mconfig.records[r].canActive === true
                            && vm.mconfig.records[r].isActive === true
                        ) {
                            vm.mconfig.activate(vm.mconfig.records[r]);
                            switch (vm.mconfig.records[r].item_name) {
                                case 'option':
                                    break;
                                case 'sort':
                                    vm.model.sortMode = true;
                                    break;
                                case 'resize':
                                    vm.model.resizeMode = true;
                                    vm.slider.functions.setStarts();
                                    vm.slider.functions.setConnections();
                                    vm.slider.functions.setTooltips();
                                    break;
                                case 'edit':
                                    vm.model.inlineEditMode = true;
                                    break;
                            }
                            vm.mconfig.function(vm.mconfig.records[r]);
                        }
                    }
                };

                // ###############
                // FUNCTIONS END
                // ###############

                //watcher.$watch(function () { return vm.listItems; }, function (newValue, oldValue) {
                //    populateSortlist(newValue, oldValue);
                //});

                watcher.$watch(function () { return vm.sortItems; }, function (newValue, oldValue) {
                    populateSortlist(newValue, oldValue);
                });

                watcher.$watch(function () { return vm.mconfig; }, function () {
                    if (vm.mconfig?.records?.length)
                        checkModes();
                });

                watcher.$watch(function () { return vm.ltype; }, function (newValue) {
                    if (vm.ltype)
                        vm.model.ltype = newValue;
                });

                watcher.$watch(function () { return vm.ptypeVar; }, function () {
                    if (angular.isUndefined(vm.typevar) && angular.isDefined(vm.ptypeVar))
                        vm.typevar = vm.ptypeVar.toString();
                });

                watcher.$watch(function () { return vm.filterVal; }, function (newValue) {
                    vm.model.filterVal = vm.filterVal ? newValue : '';
                });

                watcher.$watch(function () { return vm.listItems; }, function (newValue, oldValue) {
                    populateSortlist(newValue, oldValue);
                    // BJS 20210806
                    if (angular.isFunction(vm.ttChange)) {
                        if (angular.equals(oldValue, newValue) !== true) {
                            // BJS 20220727 - Added oldValue
                            vm.ttChange({ $value: newValue, $oldValue: oldValue });
                        }
                    }

                    if (vm.model.inlineEditUpdate) {
                        vm.model.inlineEditUpdate = false;
                        return;
                    }
                    if (vm.model.validatingEditable) return;
                    if (vm.model.inlineEditMode) {
                        vm.model.editableValidated = false;
                    }
                    if (vm.model.colWidthChanging || vm.model.slidersChanging) return;

                    if (angular.isDefined(vm.listItems) && vm.listItems.length > 0) {

                        vm.model.glyphlist.length = 0;
                        vm.model.glyphlist2.length = 0;
                        vm.model.secondary.length = 0;

                        if (!vm.model.editableValidated && angular.isDefined(vm.listItems) && vm.listItems.length > 0) {
                            vm.model.validatingEditable = true;
                            validatingEditable(vm.listItems);
                            vm.model.validatingEditable = false;
                        }

                        let i = 0;
                        let j = 0;
                        function isBlack(color) {
                            return color === 'black' || color === '#000000';
                        }
                        vm.listItems.forEach(function (item, key) {
                            if (angular.isUndefined(item)) return;

                            let c = 0;

                            if (angular.isDefined(item.item_glyphicon) && item.item_glyphicon !== null && item.item_glyphicon.length > 0) {
                                vm.model.glyphlist.push([]);

                                let tmplist1 = item.item_glyphicon.split(' ');
                                let tmplistColor1 = angular.isDefined(item.item_glyphicon_color) ? item.item_glyphicon_color.split(' ') : vm.model.initialGlyphColor.split(' ');

                                tmplist1.forEach(function (gitem) {
                                    
                                    vm.model.glyphlist[i].push({ item_glyphicon: gitem, item_glyphicon_color: isBlack(tmplistColor1[c]) ? 'var(--tt-text-color)' : tmplistColor1[c], item_parent_id: item.item_id });

                                    if (c < tmplistColor1.length - 1) {
                                        c++;
                                    }
                                });

                                i++;
                            }

                            if (angular.isDefined(item.item_glyphicon_2)) {
                                vm.model.glyphlist2.push([]);

                                let tmplist2 = item.item_glyphicon_2.split(' ');
                                let tmplistColor2 = angular.isDefined(item.item_glyphicon_2_color) ? item.item_glyphicon_2_color.split(' ') : angular.isDefined(item.item_glyphicon_color) ? item.item_glyphicon_color.split(' ') : vm.model.initialGlyphColor.split(' ');

                                c = 0;

                                tmplist2.forEach(function (gitem) {
                                    vm.model.glyphlist2[j].push({ item_glyphicon: gitem, item_glyphicon_color: isBlack(tmplistColor2[c]) ? 'var(--tt-text-color)' :  tmplistColor2[c], item_parent_id: item.item_id });

                                    if (c < tmplistColor2.length - 1) {
                                        c++;
                                    }
                                });

                                j++;
                            }

                            if (angular.isDefined(item.item_secondary)) {
                                if (vm.listItems[key].item_secondary.length > vm.model.numCol) {
                                    vm.model.numCol = vm.listItems[key].item_secondary.length;
                                }

                                angular.forEach(item.item_secondary, function (data) {
                                    if (angular.isDefined(data)) {
                                        if (vm.model.secondary.length <= key) {
                                            vm.model.secondary.push([]);
                                        }
                                        vm.model.secondary[key].push(data);
                                        vm.model.secondary[key].parent_id = item.item_id;
                                    }
                                });
                            }
                        });
                    }

                    vm.model.colList.length = 0;

                    for (let o = 0; o < vm.model.numCol; o++) {
                        let longest = 0;
                        let colVis = true;
                        let colPrio = o;
                        let colOrder = o;
                        let colAlign = 'right';
                        let colWidth = 0;
                        let colEditable = 'false';

                        for (let p = 0; p < vm.model.secondary.length; p++) {
                            if (vm.model.secondary?.[p]?.[o]?.item_col?.length > longest) {
                                longest = vm.model.secondary[p][o].item_col.length;
                            }
                        }

                        if (vm.model.secondary?.[0]?.[o]) {
                            colAlign = vm.model.secondary[0][o].alignment ?? colAlign;
                            colVis = vm.model.secondary[0][o].visible ?? colVis;
                            colPrio = vm.model.secondary[0][o].priority ?? colPrio;
                            colOrder = vm.model.secondary[0][o].orderby ?? colOrder;
                            colEditable = vm.model.secondary[0][o].item_editable ?? colEditable;
                            colWidth = isNumber(vm.model.secondary[0][o].item_col_width) ? parseFloat(vm.model.secondary[0][o].item_col_width) : colWidth;
                        }

                        vm.model.colList.push({
                            colId: o,
                            colLength: longest,
                            width: colWidth,
                            alignment: colAlign,
                            orderby: colOrder,
                            editable: colEditable,
                            priority: colPrio,
                            view: false,
                            viewXX: false,
                            viewXS: false,
                            viewSM: false,
                            viewMD: false,
                            viewLG: false,
                            visible: colVis
                        });
                    }

                    if (vm.model.colList.length > 0 && angular.isDefined(vm.listItems[0])) {
                        let colCount = vm.model.colList.length;
                        let visibleColCount = 0;
                        let totalLength = 0;
                        let allWidthsUndefined = true;

                        for (let col = 0; col < colCount; col++) {
                            if (col === 0 && angular.isDefined(vm.listItems[0].item_col_width) && isNumber(vm.listItems[0].item_col_width)) {
                                allWidthsUndefined = false;
                                break;
                            } else if (angular.isDefined(vm.listItems[0].item_secondary[col].item_col_width) && isNumber(vm.listItems[0].item_secondary[col].item_col_width)) {
                                allWidthsUndefined = false;
                                break;
                            }
                        }

                        vm.model.slidersChanging = true;

                        if (allWidthsUndefined) {
                            let allWidths = [];

                            // Finding the longest name among the items
                            const longestItemNameLength = vm.listItems.map(e => e.item_name?.length || 0).reduce((p, c) => p > c ? p : c);

                            allWidths.push(longestItemNameLength);
                            for (let col = 0; col < colCount; col++) {
                                allWidths.push(vm.model.colList[col].colLength);
                            }

                            let tempFullLength = 0;
                            let flooredLength = 0;
                            let awla = 0;
                            let awlb = 0;
                            let awlf = 0;
                            let minLength = 0;

                            switch (vm.model.currentSize) {
                                case 'xx':
                                case 'xs':
                                    minLength = 0;
                                    break;
                                case 'sm':
                                    minLength = 3;
                                    break;
                                case 'md':
                                case 'lg':
                                    minLength = 6;
                            }

                            for (let col = 0; col < allWidths.length; col++) {
                                tempFullLength += allWidths[col];
                            }

                            for (let col = 0; col < allWidths.length; col++) {
                                if (col === 0) {
                                    awla = (allWidths[col] / tempFullLength) * 100;
                                    awlf = Math.floor(awla);
                                    vm.listItems[0].item_col_width = awlf;
                                    flooredLength += awla - awlf;
                                } else {
                                    awla = (allWidths[col] / tempFullLength) * 100;
                                    awlb = ((allWidths[col] - minLength) / tempFullLength) * 100;
                                    if (awlb < vm.model.sliderMinDefault) awlb = vm.model.sliderMinDefault;
                                    awlf = Math.floor(awlb);
                                    vm.listItems[0].item_secondary[col - 1].item_col_width = awlf;
                                    flooredLength += awla - awlf;
                                }
                            }

                            vm.listItems[0].item_col_width += Math.floor(flooredLength);
                        }

                        for (let col = 0; col < colCount; col++) {
                            if (col === 0) {
                                visibleColCount++;
                                if (isNumber(vm.listItems[0]?.item_col_width)) {
                                    if (parseFloat(vm.listItems[0].item_col_width) > 100) {
                                        vm.listItems[0].item_col_width = 100;
                                    }
                                    totalLength += parseFloat(vm.listItems[0].item_col_width);
                                } else {
                                    vm.listItems[0].item_col_width = vm.slider.min;
                                    totalLength += vm.slider.min;
                                }

                                if (totalLength >= 100) {
                                    break;
                                }
                            }

                            if (vm.model.colList[col].visible === true) {
                                visibleColCount++;
                                if (vm.model.colList[col].width > 100) {
                                    vm.model.colList[col].width = 100;
                                } else if (vm.model.colList[col].width < vm.slider.min) {
                                    vm.model.colList[col].width = vm.slider.min;
                                }
                                if (vm.model.colList[col].width + totalLength > 100) {
                                    let canShrink = false;
                                    let needShrink = false;
                                    let totalShrinkage = 0;
                                    do {
                                        canShrink = false;
                                        needShrink = false;
                                        if (vm.model.colList[col].width > vm.slider.min) {
                                            vm.model.colList[col].width -= vm.slider.options.step;
                                            totalShrinkage += vm.slider.options.step;
                                            if (vm.model.colList[col].width + totalLength - totalShrinkage > 100) {
                                                needShrink = true;
                                            }
                                            if (needShrink && vm.model.colList[col].width > vm.slider.min) {
                                                canShrink = true;
                                            }
                                        }
                                    } while (needShrink && canShrink);

                                    totalLength += vm.model.colList[col].width - totalShrinkage;
                                } else {
                                    totalLength += vm.model.colList[col].width;
                                }
                            }
                        }

                        if (totalLength + vm.slider.options.step < 100) {
                            let remainderLength = 100 - totalLength;
                            let colLengthAdjustment = Math.floor(remainderLength / visibleColCount);
                            let additionalAdjustments = (remainderLength - (colLengthAdjustment * visibleColCount)) / vm.slider.options.step;

                            for (let col = 0; col < colCount; col++) {
                                if (col === 0) {
                                    vm.listItems[0].item_col_width = parseFloat(vm.listItems[0].item_col_width) + colLengthAdjustment;
                                    totalLength += colLengthAdjustment;
                                }

                                if (vm.model.colList[col].visible === true) {
                                    vm.model.colList[col].width += colLengthAdjustment;
                                    totalLength += colLengthAdjustment;
                                }
                            }

                            while (additionalAdjustments > 0) {
                                for (let col = colCount; col >= 0; col--) {
                                    if (col === 0) {
                                        vm.listItems[0].item_col_width = parseFloat(vm.listItems[0].item_col_width) + vm.slider.options.step;
                                        totalLength += vm.slider.options.step;
                                        additionalAdjustments--;
                                    } else if (vm.model.colList[col - 1].visible === true) {
                                        vm.model.colList[col - 1].width += vm.slider.options.step;
                                        totalLength += vm.slider.options.step;
                                        additionalAdjustments--;
                                    }

                                    if (additionalAdjustments <= 0) break;
                                }
                            }

                            for (let col = 0; col < colCount; col++) {
                                vm.listItems[0].item_secondary[col].item_col_width = vm.model.colList[col].width;
                            }
                        }

                        vm.model.slidersChanging = false;
                    }

                    let columnsEqualized = false;

                    do {
                        columnsEqualized = true;

                        angular.forEach(vm.model.secondary, function (data) {
                            if (data.length < vm.model.numCol) {
                                data.push({ item_col: '' });

                                columnsEqualized = false;
                            }
                        });
                    } while (columnsEqualized === false);
                }, true);
            }]
        });
})();
