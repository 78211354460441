(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('pohCompAddScan', {
        templateUrl: 'views/components/views/pohCompAddScan/pohCompAddScan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'pohCompAddScanService', 'stateService', function ($stateParams, pohCompAddScanService, stateService) {
            var vm = this;
            vm.pcasService = pohCompAddScanService;
            pohCompAddScanService.filter_pohcomp_addscan.barcode = '';

            vm.state = {
                loading: false
            };
            
            vm.searchPohCompAddScan = function () {
                if (angular.isUndefined(vm.pcasService.filter_pohcomp_addscan.barcode) || vm.pcasService.filter_pohcomp_addscan.barcode.length == 0) return;

                vm.state.loading = true;

                pohCompAddScanService.searchP2PohCompAddScan($stateParams.poh_keyno, vm.pcasService.filter_pohcomp_addscan.barcode).then(function () {
                    vm.state.loading = false;

                    stateService.go(vm.pcasService.p2_pohcomp_addscan.webpagename, {
                        poh_keyno: $stateParams.poh_keyno,
                        stock_keyno: vm.pcasService.p2_pohcomp_addscan.stock_keyno
                    });
                });

                vm.clearField();
            };
            
            vm.clearField = function () {
                if (vm.pcasService.filter_pohcomp_addscan.barcode !== '' && vm.pcasService.p2_pohcomp_addscan.length > 0 && vm.state.loading == false) {
                    vm.pcasService.filter_pohcomp_addscan.barcode = '';
                    pohCompAddScanService.filter_pohcomp_addscan.barcode = '';
                }
            };
        }]
    });
})();