(function() {
    'use strict';

    angular.module("imApp").factory("ttDtGridSchemaService", [function () {
        var service = {
            getType: function (domain) {
                switch (domain) {
                    case 'decimal':
                    case 'number':
                        return 'number';
                    case 'date':
                    case 'time':
                    case 'datetime':
                    case 'timestamp':
                        return 'date';
                    case 'bit':
                    case 'bool':
                    case 'boolean':
                        return 'boolean';
                    default:
                        return 'string';
                }
            },

            getFormat: function (schema) {
                switch (schema.coltype) {
                    case 'decimal':
                        var deci = angular.isDefined(schema.decimals) && schema.decimals !== '' ? schema.decimals : '0';

                        var zeros = '';

                        for (var d = 0; d < parseInt(deci); d++) {
                            zeros += '0';
                        }

                        return '{0:#,##.' + zeros + '}';
                    case 'date':
                        return '{0:dd/MM/yyyy}';
                    case 'datetime':
                    case 'timestamp':
                        return '{0:dd/MM/yyyy hh:mm:ss}';
                    default:
                        return null;
                }
            }
        }

        return service;
    }]);
})();
