import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AfterViewInit, Component } from '@angular/core';
import { LinkCellRendererParams } from '../link-cell-renderer/link-cell-renderer.component';

@Component({
    selector: 'tt-symbol-cell-renderer',
    templateUrl: './symbol-cell-renderer.component.html',
    styleUrls: ['./symbol-cell-renderer.component.css'],
})
export class SymbolCellRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    /**
     * List of icons to display in the cell.
     */
    public icons: GridGlyph[] = [];

    /**
     * The cell parameters for this cell.
     */
    private params?: SymbolCellRendererParams;

    /**
     * Returns the appropriate icon css classes based on the given icon string.
     *
     * @param icon the string to get css classes for.
     * @returns a string containing icon classes for the given icon.
     */
    private getIconClasses(icon: string) {
        var iconClasses = '';

        if (icon) {
            iconClasses = 'glyphicon ' + icon;

            if (icon.startsWith('fa')) {
                iconClasses = icon.split('-')[0] + ' fa-' + icon.split('-')[1];
            }
        }

        return iconClasses;
    }

    private isBlack(color: string) {
        return color === 'black' || color === '#000000';
    }

    agInit(params: SymbolCellRendererParams): void {
        this.params = params;
    }

    refresh(_: ICellRendererParams<any, any, any>): boolean {
        return false;
    }

    ngAfterViewInit(): void {
        const icons = this.params?.node.data?.[this.params.iconKeyname]?.split(' ') || [];
        const colors = this.params?.node.data?.[this.params.iconColorKeyname]?.split(' ') || [];

        for (let i = 0; i < icons.length; i++) {
            this.icons.push({ icon: this.getIconClasses(icons[i]), style: { color: this.isBlack(colors[i]) ? 'var(--tt-text-color)' : colors[i] } });
        }
    }
}

/**
 * Represents a grid symbol icon.
 */
interface GridGlyph {
    icon: string;
    style: Partial<CSSStyleDeclaration>;
}

export interface SymbolCellRendererParams extends ICellRendererParams {
    iconKeyname: string;
    iconColorKeyname: string;
}
