(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('transportAreas', {
        templateUrl: 'views/components/views/transportAreas/transportAreas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'transportAreasService', function ($stateParams, stateService, transportAreasService) {
            
    		// ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let stockPlaceId = $stateParams.stockplace_id;
    
    		vm.model = {
    			itemsListAreas: []
    		};
    
    		// ## LOAD PROCEDURE CALLS ##

            let loadAreas = function () {
                transportAreasService.listAreas({ stockplace_id: stockPlaceId }).then(function (result) {
                    angular.copy(result, vm.model.itemsListAreas);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'transportarea':
                        go({
                            transportarea_keyno: '0',
                            stockplace_id: stockPlaceId
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadAreas();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();
