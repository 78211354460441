(function () {
    'use strict';

    /**
     * @typedef {Object} RadioButton represents a radio button.
     * @property {string | number} id the id of the button.
     * @property {string} label the label for the button, will be translated.
     * @property {string?} icon the icon classes if an icon is to be displayed.
     * @property {boolean | 'hidden'} disabled whether the button should be disabled or hidden, currently only supports hidden.  // TODO: make disabled styling.
     * @property {() => void} onClick function to call when button is clicked, NB! is called before ttChange.
     */

    let module = angular.module('imApp');

    module.component('ttButtonsRadio', {
        templateUrl: 'views/components/directives/ttButtonsRadio/ttButtonsRadio.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@?',          // {string?} - label to display for the buttons.
            ttTranslateLabel: '@?', // 'true' or 'false'
            ttButtons: '<',         // {RadioButton[]} - a list of radio buttons.
            ttModel: '<',           // {string | number} - the id of the selected button.
            ttChange: '&',          // {($value) => void} - function called when model is changed, NB is called after a buttons onClick.
            ttChangeArgs: '<?',     // idunno
            ttItemId: '@?',         // {string} - id for tt-item-packery group or optional id.
            ttStyle: '<?'           // {object} - object containing custom styling, see vm.style for reference.
        },
        controller: ['$element', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.showLabel = false;

            vm.selected = '';

            vm.mediaQueries = {};

            vm.style = {
                label: {},
                button: {},
                tabs: {},
            };

            vm.class = {
                label: '',
                input: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onChange = function (id) {
                vm.ttModel = id;

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: id, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            var setBtnClasses = function (btn) {
                if (angular.isUndefined(btn) || btn === null)
                    return;

                for (var i = 0; i < vm.ttButtons.length; i++) {
                    vm.ttButtons[i].ttClass = 'btn-default tt-inactive-btn';
                }

                btn.ttClass = 'btn-primary tt-active-btn';
            };

            vm.onButtonClick = function (btn) {
                vm.selected = btn.id;
                ttDirectivesService.onButtonClick({ button: btn, controller: vm });
                setBtnClasses(btn);
            };

            var btnIndex = {};

            vm.$onChanges = function (changes) {
                //console.log(changes);
                var words = [];

                if (angular.isDefined(changes.ttModel) && us.isStringValue(changes.ttModel.currentValue) && changes.ttModel.currentValue !== changes.ttModel.previousValue && vm.ttButtons.length > 0) {
                    vm.selected = changes.ttModel.currentValue;
                    var btn = null;

                    for (var i = 0; i < vm.ttButtons.length; i++) {
                        if (vm.ttButtons[i].id !== changes.ttModel.currentValue)
                            continue;

                        btn = vm.ttButtons[i];

                        break;
                    }

                    setBtnClasses(btn);
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslateLabel !== 'false') {
                        words.push(vm.ttLabel);
                    }

                    vm.showLabel = true;
                }

                if (angular.isDefined(changes.ttButtons) && angular.isArray(changes.ttButtons.currentValue)) {
                    for (var j = 0; j < changes.ttButtons.currentValue.length; j++) {
                        words.push(changes.ttButtons.currentValue[j].label);

                        btnIndex[changes.ttButtons.currentValue[j].label] = j;

                        changes.ttButtons.currentValue[j].ttClass = vm.ttModel === changes.ttButtons.currentValue[j].id ? 'btn-primary tt-active-btn' : 'btn-default tt-inactive-btn';;
                    }
                }

                if (words.length > 0) {
                    translateService.translateBatch(words).then(function (response) {
                        angular.forEach(response, function (value, key) {
                            if (key === 'ttLabel') {
                                vm.translations.ttLabel = value;
                            } else {
                                vm.translations.ttLabel = vm.ttLabel ?? '';
                            }

                            if (angular.isDefined(btnIndex[key])) {
                                vm.ttButtons[btnIndex[key]].translation = value;

                            }
                        });

                        vm.whenReady();
                    });
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.mediaQueries = info.mediaQueries;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                vm.style.tabs.fontSize = info.fontSizes.textSize;
                vm.style.tabs.height = info.height + 'px';
                vm.style.button.fontSize = info.fontSizes.textSize;
                vm.style.button.height = info.height + 'px';
                vm.style.button.padding = '0px 5px';

                vm.class.label = info.labelAlwaysOnTop === true || vm.showLabel !== true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2';
                vm.class.input = info.labelAlwaysOnTop === true || vm.showLabel !== true ? 'col-xs-12' : 'col-xs-12 col-sm-10';
            });

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
