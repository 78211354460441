(function() {
    'use strict';

    angular.module("imApp").factory("containerShipmentService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        var service = {
            getShipmentContainerKeynameList: function (parameters) {
                return call(2297, parameters || {});
            },
            ediStopCodes: function (parameters) {
                return call(2304, parameters || {});
            },
            ediReturnStatuses: function (parameters) {
                return call(2305, parameters || {});
            },
            ediStop: function (parameters) {
                return call(2288, parameters || {});
            },
            ediReactivate: function (parameters) {
                return call(2289, parameters || {});
            },
            ediResendDelayedOrders: function (parameters) {
                return call(2290, parameters || {});
            },
            ediDirectDelivery: function (parameters) {
                return call(2291, parameters || {});
            },
            ediForcedEdiStatus: function (parameters) {
                return call(2292, parameters || {});
            },
            // BJS 20221011 - Added shipmentcontainer_keyno as parameter.
            //ediTransporterReturn: function (shipmentcontainer_keyno) {
            //    return $ihttp.post({
            //        method: 2293,
            //        parameters: {
            //            shipmentcontainer_keyno: shipmentcontainer_keyno
            //        }
            //    });
            //},
            // RHE 20230425 - Swapped to now expect a list of selected items as well
            ediTransporterReturn: function (parameters) {
                return call(2293, parameters || {});
            },
            sendContainer: function (parameters) {
                return call(2344, parameters || {});
            },
            receiveContainer: function (parameters) {
                return call(2906, parameters || {});
            },
            sendEdi: function (parameters) {
                return call(2345, parameters || {});
            },
            searchKeyname: function (value) {
                return $ihttp.post({
                    method: 2297,
                    parameters: {
                        searchtext: value
                    }
                });
            },
            remember: function (method, rememberId, value, isBase) {
                return call(angular.isDefined(method) && method !== null ? method : 616, { // for use if need to have a different method
                    is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (method, rememberId) {
                return call(angular.isDefined(method) && method !== null ? method : 973, { variablename: rememberId }); // for use if need to have a different method
            }
        };

        return service;
    }]);
})();
