(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('businesscoOnBoarding', {
        templateUrl: 'views/components/views/businesscoOnBoarding/businesscoOnBoarding.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$uibModal', 'stateService', 'utilityService', 'translateService', 'modalService', 'businesscoOnBoardingService', 'economyService', 'logisticService', 'typeaheadService', 'customerEditService', function ($uibModal, stateService, utilityService, translateService, modalService, businesscoOnBoardingService, economyService, logisticService, typeaheadService, customerEditService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
            	confirm_title: '',
            	confirm_message: '',
                businesscoonboarding_confirm_save: '',
            	confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                customerButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.cust_no }) }
                ],
                customerSearchButtons: [
                    { id: 'new', icon: 'fas fa-plus', color: 'primary', type: 'primary', onClick: () => newCustomer() }
                ],
                edit: {},
                selectListCompanies: [],
                saving: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                businesscoOnBoardingService.getEdit().then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let retrieveCustomer = function () {
                console.log(vm.model.edit.cust_no)
                console.log(utilityService.validateParmsValue(vm.model.edit.cust_no) !== true);

                if (utilityService.validateParmsValue(vm.model.edit.cust_no) !== true) return;

                return economyService.getCustomerEdit({ cust_no: vm.model.edit.cust_no }).then(function (response) {
                    vm.model.edit.cust_name = response[0].cust_name;
                    vm.model.edit.cust_address = response[0].cust_address;
                    vm.model.edit.valuta_id = response[0].cust_order_valuta_id;
                    vm.model.edit.org_no = response[0].org_no;
                    vm.model.edit.stockplace_name = response[0].cust_name + ' ' + response[0].postplace_name;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'C',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'cust_name':
                        if (item) {
                            vm.model.edit.cust_no = item?.item_id ?? '0';
                            vm.model.edit.cust_name = item?.item_name ?? '';

                            retrieveCustomer();
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_no = '0';
                        vm.model.edit.cust_name = '';

                        retrieveCustomer();
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.saving = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.businesscoonboarding_confirm_save,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    businesscoOnBoardingService.saveObj(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.saving = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.saving = false;
                            stateService.back();
                        }
                    });
                }, function () {
                    vm.model.saving = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            function newCustomer() {
                const modalRef = $uibModal.open({
                    component: 'customerEdit',
                    resolve: {
                        custNo: () => vm.model.edit.cust_no,
                    },
                    size: 'pst-ninetyfive',
                    openedClass: 'modal-content',
                });

                modalRef.result.then((data) => {
                    if (!!data) {
                        vm.model.edit.cust_no = '' + data;
                        retrieveCustomer();
                    }
                });
            }

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
