(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeMenu', {
        templateUrl: 'views/components/views/oeeMenu/oeeMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['oeeMenuService', 'stateService', function(oeeMenuService, stateService) {
            var vm = this;
            
            oeeMenuService.loadP2WebpageMenuList(stateService.getCurrentName()).then(function () {
                vm.p2_webpagemenu_list = oeeMenuService.p2_webpagemenu_list;
            });
        }]
    });
})();