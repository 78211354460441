(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactWorksService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2CustactWorkList: {
                records: []
            },
            loadP2CustactWorkList: function (custact_no) {
                var deferred = $q.defer();

                var parmsP2CustactWorkList = {
                    method: 377,
                    parameters: {
                        custact_no: custact_no
                    }
                };

                $ihttp.post(parmsP2CustactWorkList).then(function (data) {
                    angular.copy(data, service.p2CustactWorkList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            listJobs: function (parms) {
                return $ihttp.post({
                    method: 377,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
