(function () {
    'use strict';

    function pad(num, size) {
        var s = num + "";
        //while (s.length < size) s = "0" + s;
        return s;
    }

    Date.prototype.weekNumber = function(){
	    var d = new Date(+this);
	    d.setHours(0,0,0,0);
	    d.setDate(d.getDate()+4-(d.getDay()||7));
	    return Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);
	};

    function daysInMonth(month,year) {
	    return new Date(year, month, 0).getDate();
	}

    var objectLength = function (obj) {
        var out = 0;

        try {
            if (angular.isArray(obj)) {
                if (obj) {
                    out = obj.length;
                }
            } else {
                if (obj) {
                    out = Object.keys(obj).length;
                }
            }
        } catch (e) { }

        return out;
    };

    var module = angular.module("imApp");

    module.factory("calViewService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            p2_m_calview_dd_codes: {
                offdutycode_keyno: '',
                offdutycode_name: '',
                background_color_hex: 'white',
                text_color_hex: 'black',
                orderby: '3',
                records: {},
                //list: {}
            },
            load_p2_m_calview_dd_codes: function () {
                var deferred = $q.defer();

                try {
                    var parms_p2_m_calview_dd_codes = {
                        method: 314,
                        parameters: {}
                    };

                    $ihttp.post(parms_p2_m_calview_dd_codes).then(function (data) {
                        angular.forEach(data, function (item) {
                            service.p2_m_calview_dd_codes.records[item.offdutycode_keyno] = {
                                offdutycode_keyno: item.offdutycode_keyno,
                                offdutycode_name: item.offdutycode_name,
                                background_color_hex: item.background_color_hex,
                                text_color_hex: item.text_color_hex,
                                orderby: item.orderby
                            };
                        });

                        service.loaded = true;
                        deferred.resolve();
                    });
                } catch (e) {}

                return deferred.promise;
            },
            p2_m_calview_dates: {
                year: '',
                weekno: '',
                month_no: '',
                dayno: '',
                dayletter: '',
                day: '',
                background_color: 'white',
                style_bg_from: 'white',
                style_bg_to: 'white',
                style: '',
                time_from: '',
                time_to: '',
                is_protected: 0,
                records: [],
                months: {},
                month_names: {
                    1: { label: 'Januar' },
                    2: { label: 'Februar' },
                    3: { label: 'Mars' },
                    4: { label: 'April' },
                    5: { label: 'Mai' },
                    6: { label: 'Juni' },
                    7: { label: 'Juli' },
                    8: { label: 'August' },
                    9: { label: 'September' },
                    10: { label: 'Oktober' },
                    11: { label: 'November' },
                    12: { label: 'Desember' }
                },
                days: {}
            },
            load_p2_m_calview_dates: function () {
                var deferred = $q.defer();

                var parms_p2_m_calview_dates = {
                    method: 312,
                    parameters: {}
                };

                $ihttp.post(parms_p2_m_calview_dates).then(function (data) {
                    var i = 0;
                    var month = '';
                    var weekno = '';
                    var weeks = {};
                    var items = {};
                    var last_year = '';
                    var last_weekno = '';
                    var last_month_no = '';
                    var last_dayno = '';
                    var last_dayletter = '';
                    var last_day = '';
                    var last_background_color = '';

                    angular.forEach(data, function (item) {
                        item.style = 'border:1px solid #dbdbdb;cursor:pointer;';
                        item.text_color = 'black';
                        item.codes = {};
                        item.style_bg_from = item.background_color;
                        item.style_bg_to = item.background_color;

                        if (item.weekno + ' ' != weekno) {
                            if (weekno != '') {
                                weeks[weekno] = items;
                                items = {};
                            }
                        }

                        if (item.month_no != month) {
                            if (month != '') {
                                if (last_dayno < 7) {
                                    var sd = last_dayno;

                                    for (sd == last_dayno; sd < 7; sd++) {
                                        var em = {
                                            year: last_year,
                                            weekno: last_weekno,
                                            month_no: last_month_no,
                                            dayno: '',
                                            dayletter: last_dayletter,
                                            systemdate: '',
                                            remark: '',
                                            day: '',
                                            background_color: 'transparent',
                                            text_color: 'black',
                                            style_bg_from: 'transparent',
                                            style_bg_to: 'transparent',
                                            style: '',
                                            time_from: '',
                                            time_to: '',
                                            is_protected: 1,
                                            codes: {}
                                        };

                                        items[sd + '_' + ''] = em;
                                        service.p2_m_calview_dates.records.push(em);
                                    }
                                }

                                if (objectLength(items) > 0) {
                                    weeks[weekno] = items;
                                    items = {};
                                }

                                service.p2_m_calview_dates.months[month] = weeks;
                                weeks = {};
                            }
                        }

                        weekno = item.weekno + ' ';
                        month = item.month_no;

                        if (item.day == '1') {
                            var sd = 1;

                            for (sd == 1; sd < item.dayno; sd++) {
                                var em = {
                                    year: item.year,
                                    weekno: item.weekno,
                                    month_no: item.month_no,
                                    dayno: '',
                                    dayletter: item.dayletter,
                                    systemdate: '',
                                    remark: '',
                                    day: '',
                                    background_color: 'transparent',
                                    text_color: 'black',
                                    style_bg_from: 'transparent',
                                    style_bg_to: 'transparent',
                                    style: '',
                                    time_from: '',
                                    time_to: '',
                                    is_protected: 1,
                                    codes: {}
                                };

                                items[sd + '_' + ''] = em;
                                service.p2_m_calview_dates.records.push(em);
                            }

                            items[item.dayno + '_' + item.day] = item;
                            service.p2_m_calview_dates.records.push(item);
                        } else {
                            items[item.dayno + '_' + item.day] = item;
                            service.p2_m_calview_dates.records.push(item);
                            service.p2_m_calview_dates.days[item.dayno] = item.dayletter;
                        }

                        last_year = item.year;
                        last_weekno = item.weekno;
                        last_month_no = item.month_no;
                        last_dayno = item.dayno;
                        last_dayletter = item.dayletter;
                        last_day = '';
                        last_background_color = 'white';
                        i += 1;
                    });

                    if (weekno != '') {
                        weeks[weekno] = items;
                    }

                    if (month != '') {
                        service.p2_m_calview_dates.months[month] = weeks;
                    }

                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveOffdutyData_keyno: '',
            saveOffdutyData: function (input) {
                var deferred = $q.defer();
                var sendRecord = [];

                angular.forEach(input, function (item) {
                    var r = {
                        offdutydata_keyno: item.offdutydata_keyno,
                        labour_no: item.labour_no,
                        systemdate: item.systemdate,
                        offdutycode_keyno: item.offdutycode_keyno,
                        time_from: item.time_from,
                        time_to: item.time_to,
                        remark: item.remark,
                        deleted: item.deleted
                    };

                    sendRecord.push(r);
                });

                var parmsSaveOffdutyData = {
                    method: 315,
                    parameters: {
                        records: sendRecord
                    }
                };

                /*$ihttp.post(parmsSaveOffdutyData).then(function (data) {
                    service.loaded = true;
                    deferred.resolve();
                });*/

                service.loaded = true;
                deferred.resolve();

                return deferred.promise;
            },
            date_records: {
                year: '',
                week_no: '',
                month_no: '',
                day_of_week: '',
                days_in_month: '',
                date: '',
                records: []
            },
            loadDates: function (year) {
                var deferred = $q.defer();

                if (!year) {
                    year = new Date().getFullYear();
                }

                var d = new Date();
                d.setFullYear(year);
                var m = 0;

                for (m == 0; m < 12; m++) {
                    //month
                    d.setMonth(m, 1);
                    var tmd = daysInMonth(d.getMonth(), d.getFullYear());
                    var md = 1;

                    for (md == 1; md <= tmd; md++) {
                        d.setMonth(m, md);

                        var drec = {
                            year: d.getFullYear(),
                            week_no: d.weekNumber(),
                            month_no: pad((d.getMonth() + 1), 2),
                            days_in_month: tmd,
                            day_of_week: d.getDay(),
                            date: d.getDate()
                        };

                        service.date_records.records.push(drec);
                    }
                }

                service.loaded = true;
                deferred.resolve();

                return deferred.promise;
            }
        };

        return service;
    }]);
})();