(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("workService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var atou = function (data) { // Utility function for base64decode with unicode support
            return decodeURIComponent(escape(atob(data)));
        }

        let definedAndNotNull = function (parm) {
            return defined(parm) && parm !== null;
        }

        let defined = function (parm) {
            return angular.isDefined(parm);
        }

        var service = {
            loadWorkGet: function (prod_id, work_keyno) {
            	return p2DataTaskService.call(1341, {
                    prod_id: prod_id,
                    work_keyno: work_keyno
            	});
            },
            loadUnitList: function (onlytimeunits, isactive) {
                return p2DataTaskService.call(218, {
                    onlytimeunits: onlytimeunits,
                    isactive: isactive
                });
            },
            listLinkTypes: function (parms) {
                return $ihttp.post({
                    method: 2063,
                    parameters: parms || {}
                });
            },
            loadOperationGet: function (operation_id) {
                return p2DataTaskService.call(266, {
                    operation_id: operation_id
                });
            },
            changeOperation: function (parms) {
                return $ihttp.post({
                    method: 2650,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1342,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 1354,
                    parameters: edit
                });
            },
            remember: function (rememberId, value, method, isBase) {
                return call(definedAndNotNull(method) ? method : 616, { // for use if need to have a different method
                    is_base64: defined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: defined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (rememberId, method) {
                return call(definedAndNotNull(method) ? method : 973, { variablename: rememberId }); // for use if need to have a different method
            }
        };

        return service;
    }]);
})();
