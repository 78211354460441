(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acCustTrans', {
        templateUrl: 'views/components/views/acCustTrans/acCustTrans.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'acCustTransService', 'translateService', function ($stateParams, stateService, modalService, acCustTransService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var acCustTransKeyno = $stateParams.accusttrans_keyno;

            var translations = {
                accusttrans_remove_matching_show_title: 'Varsel',
                accusttrans_remove_matching_show_ok_label: 'OK'
            };

            vm.model = {
                setting: {},
                gridReady: false,
                removing: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_accusttrans_match_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            let optionfunc = function (data) {
                //console.log('optionfunc');
                //console.dir(data);

                if (angular.isDefined(data.func) && data.func === 'CheckboxBoxClick') {
                    if (data.dataItem.is_selected === true) {
                        data.dataItem.is_selected_amount_locval_balance = data.dataItem.amount_locval;
                    } else {
                        data.dataItem.is_selected_amount_locval_balance = 0;
                    }

                    vm.grid.gridfunc.refresh();
                    vm.grid.gridfunc.refreshAggregates();
                } else if (angular.isDefined(data.func) && data.func === 'CheckboxHeaderClick') {
                    angular.forEach(data.data, function (dataItem) {
                        if (dataItem.is_selected === true) {
                            dataItem.is_selected_amount_locval_balance = dataItem.amount_locval;
                        } else {
                            dataItem.is_selected_amount_locval_balance = 0;
                        }
                    });

                    vm.grid.gridfunc.refresh();
                    vm.grid.gridfunc.refreshAggregates();
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            acCustTransService.getAcCustTrans({ accusttrans_keyno: acCustTransKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

			    vm.grid.dataTask.loadData = {
                    method: 2832,
				    parameters: vm.model.setting
			    };
		        
		        vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL

            vm.removeChanges = function () {
                vm.model.removing = true;

                var dirtyRows = vm.grid.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;
                    
                    selectedItems.push({ accusttransmatch_keyno: item.accusttransmatch_keyno });
                });

                var mergeObj = angular.copy(vm.model.setting);
                mergeObj.records = selectedItems;

                acCustTransService.removeMatchingSelected(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.accusttrans_remove_matching_show_title,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.accusttrans_remove_matching_show_ok_label,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.removing = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.removing = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON REMOVE MATCHING PROCEDURE FUNCTION CALL
        }]
    });
})();
