(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("myFontSizeService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {

            /**
             * @typedef {Object} MyFontSize represents the my font size object containing page load.
             * @property {string} portal_user_keyno stringified portal user key.
             * @property {string} user_name the name of the user.
             * @property {string} fontsize the user's preferred font-size given in pixels.
             * @property {string} fontsize_mobile the difference between the users preferred fontsize and preferred fontsize for mobile screen, defaults to 0.
             */

            /**
             * Gets the initial page load for the my font size page.
             * 
             * @returns {Promise<{data: MyFontSize[]; iserror: boolean; message: string;}>} promise containing initial page load data.
             */
            loadMyFontSizeGet: function () {
                return p2DataTaskService.call(1833, {});
            },

            /**
             * Saves the new font-size settings.
             * 
             * @param {MyFontSize} get the modified page-load get.
             * @returns {Promise<{errorcode: string; errormessage: string;}>} promise containing errorcode '0' if save was successful.
             */
            save: function (get) {
                return p2DataTaskService.call(1834, get);
            }
        };

        return service;
    }]);
})();
