(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('cargonizerPrinters', {
        templateUrl: 'views/components/views/cargonizerPrinters/cargonizerPrinters.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['cargonizerPrintersService', function (cargonizerPrintersService) {
			let vm = this;

            vm.model = {
                itemsListPrinters: []
            };

            let loadCargonizerPrinters = function () {
                cargonizerPrintersService.listCargonizerPrinters().then(function (result) {
                    angular.copy(result, vm.model.itemsListPrinters);
                });
            };
            
            vm.$onInit = function () {
                loadCargonizerPrinters();
            };
		}]
	});
})();
