(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('requisitions', {
        templateUrl: 'views/components/views/requisitions/requisitions.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'requisitionsService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, requisitionsService, logisticService, rememberService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            let variableNames = {
                businessco_no_from: '',
                stockplace_id_from: '',
                businessco_no_to: '',
                stockplace_id_to: ''
            };
            
            vm.model = {
                setting: {},
                selectListCompanies: [],
                selectListStockPlacesFrom: [],
                selectListStockPlacesTo: [],
                gridReady: false
            };
            
            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };
            
            // #endregion VARIABLES & DEFINITIONS
            
            let loadSetting = function () {
                let deferred = $q.defer();

                requisitionsService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces('businessco_no_from'),
                        loadStockPlaces('businessco_no_to')
                    ]).then(() => {
                        loadGrid();
                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadStockPlaces = function (id) {
                switch (id) {
                    case 'businessco_no_from':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no_from, true) !== true) return;

                        return logisticService.listStocks({
                            businessco_no: vm.model.setting.businessco_no_from,
                            add_all: 'ALL'
                        }).then(function (result) {
                            angular.copy(result, vm.model.selectListStockPlacesFrom);
                        });
                        break;
                    case 'businessco_no_to':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no_to, true) !== true) return;

                        return logisticService.listStocks({
                            businessco_no: vm.model.setting.businessco_no_to,
                            add_all: 'ALL'
                        }).then(function (result) {
                            angular.copy(result, vm.model.selectListStockPlacesTo);
                        });
                        break;
                    default:
                        break;
                };

            };

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) vm.grid.dataTask.rememberId = vm.model.setting.view_id;

                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true
                    && utilityService.validateParmsValue(vm.model.setting.businessco_no_from) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id_from) === true
                    && utilityService.validateParmsValue(vm.model.setting.businessco_no_to) === true
                    && utilityService.validateParmsValue(vm.model.setting.stockplace_id_to) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'businessco_no_from':
                    case 'stockplace_id_from':
                    case 'businessco_no_to':
                    case 'stockplace_id_to':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
            
    		// #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no_from) !== true) return;

                        vm.model.setting.businessco_no_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no_from, true) !== true) return;

                        rememberFunc('businessco_no_from');
                        loadStockPlaces('businessco_no_from');
                        break;
                    case 'stockplace_id_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_from) !== true) return;

                        vm.model.setting.stockplace_id_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_from, true) !== true) return;

                        rememberFunc('stockplace_id_from');
                        loadGrid();
                        break;
                    case 'businessco_no_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no_to) !== true) return;

                        vm.model.setting.businessco_no_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no_to, true) !== true) return;

                        rememberFunc('businessco_no_to');
                        loadStockPlaces('businessco_no_to');
                        break;
                    case 'stockplace_id_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_to) !== true) return;

                        vm.model.setting.stockplace_id_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_to, true) !== true) return;

                        rememberFunc('stockplace_id_to');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
