(function() {
    'use strict';

    angular.module("imApp").factory("epProductionCompletionService", ['$q', '$ihttp', 'printService', function ($q, $ihttp, printService) {
        var service = {
            init: function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push($ihttp.post({
                    method: 1994,
                    parameters: { }
                }));

                promises.push(printService.getReports());

                $q.all(promises).then(function (result) {
                    var info = {
                        report: {
                            keyno: 0,
                            name: result[0][0].report_name
                        }
                    };

                    for (var i = 0; i < result[1].items.length; i++) {
                        if (result[1].items[i].reportId !== info.report.name)
                            continue;

                        info.report.keyno = result[1].items[i].reportKeyno;

                        break;
                    }

                    deferred.resolve(info);
                });

                return deferred.promise;
            },

            scan: function (parameters) {
                return $ihttp.post({
                    method: 1940,
                    parameters: parameters
                }, {
                    skipDataCheck: true
                });
            },

            loadGrid: function (cqsKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 1941,
                    parameters: {
                        caseqtystyleKeyno: cqsKeyno
                    }
                }).then(function (result) {
                    for (var i = 0; i < result.length; i++) {
                        if (angular.isDefined(result[i].jqGridCC)) {
                            result[i].jqGridCC = angular.fromJson(result[i].jqGridCC);
                        }
                    }

                    deferred.resolve(result);
                });

                return deferred.promise;
            },

            caseCompleted: function (cqsKeyno, caseKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 1942,
                    parameters: {
                        caseqtystyle_keyno: cqsKeyno,
                        case_keyno: caseKeyno
                    }
                }, {
                    skipDataCheck: true,
                    ieRawData: true
                }).then(function (response) {
                    deferred.resolve(response.data);
                });

                return deferred.promise;
            },

            cancelCaseQtyStyle: function (cqsKeyno) {
                return $ihttp.post({
                    method: 1943,
                    parameters: {
                        caseqtystyleKeyno: cqsKeyno
                    }
                }, { skipDataCheck: true });
            },

            cancelCase: function (cqsKeyno) {
                return $ihttp.post({
                    method: 1944,
                    parameters: {
                        caseqtystyleKeyno: cqsKeyno
                    }
                }, { skipDataCheck: true });
            },

            getCaseLabelUri: function (cqsKeyno, caseKeyno) {
                return '';//appConfig.url.root + '/api/report?cn=SkogstadCaseCompleted&cqs=' + cqsKeyno + '&ca=' + caseKeyno + '&t=' + authenticationService.getAuthHeader().value;
            }
        }

        return service;
    }]);
})();
