(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockPlace', {
        templateUrl: 'views/components/views/stockPlace/stockPlace.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'modalService', 'stateService', 'translateService', 'economyService', 'stockPlaceService', 'logisticService', 'factoriesService', 'typeaheadService', function ($stateParams, $q, utilityService, modalService, stateService, translateService, economyService, stockPlaceService, logisticService, factoriesService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let stockPlaceId = $stateParams.stockplace_id;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                businessSiteButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('businesssites', {}) }
                ],
                factoryButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('factories', {}) }
                ],
                edit: {},
                selectListBusinessSites: [],
                selectListBusinessCompanies: [],
                selectListFactories: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS
            
            let loadEdit = function () {
                let deferred = $q.defer();
                
                stockPlaceService.getEdit({ stockplace_id: stockPlaceId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadFactories()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListBusinessCompanies);
                });
            };

            let loadBusinessSites = function () {
                economyService.listBusinessSites().then(function (result) {
                    angular.copy(result, vm.model.selectListBusinessSites);
                });
            };
            
            let loadFactories = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return factoriesService.listFactories(vm.model.edit.businessco_no).then(function (result) {
                    angular.copy(result, vm.model.selectListFactories);
                });
            };
            
    		// #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit[id] = value;

                        return typeaheadService.searchCustomer({
                            cust_name: vm.model.edit[id] || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'cust_name':
                        if (item) {
                            vm.model.edit.cust_no = item?.item_id ?? '0';
                            vm.model.edit.cust_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.cust_no = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('cust_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_no = '0';
                        vm.model.edit.cust_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockPlaceService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockplacedeltimes':
                        go({ stockplace_id_to: vm.model.edit.stockplace_id });
                        break;
                    case 'stockplacesto':
                        go({ stockplace_id_from: vm.model.edit.stockplace_id });
                        break;
                    case 'transportareas':
                    case 'pickjobgrps':
                        go({ stockplace_id: vm.model.edit.stockplace_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        loadFactories();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadBusinessSites();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
