(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttGridButtons', {
        templateUrl: 'views/components/directives/ttGridButtons/ttGridButtons.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttOptions: '<',
            ttBtnOptions: '<'
        },
        controller: ['$q', 'utilityService', function ($q, us) {
            var vm = this;

            vm.buttonsConfig = {
                class: 'col-xs-12',
                save: {
                    id: uuid(),
                    visible: false,
                    type: 'success',
                    position: 'above',  // toolbar, below, above
                    text: {
                        value: '',
                        align: 'center' // left, center, right
                    },
                    icon: {
                        value: '',
                        align: 'right'  // left, right
                    },
                    onClick: null,
                    style: {},
                    class: ''
                },
                add: {
                    id: uuid(),
                    visible: false,
                    type: 'primary',
                    position: 'above',  // toolbar, below, above
                    text: {
                        value: '',
                        align: 'center' // left, center, right
                    },
                    icon: {
                        value: '',
                        align: 'right'  // left, right
                    },
                    onClick: null,
                    style: {},
                    class: ''
                },
                remove: {
                    id: uuid(),
                    visible: false,
                    type: 'danger',
                    position: 'above',  // toolbar, below, above
                    text: {
                        value: '',
                        align: 'center' // left, center, right
                    },
                    icon: {
                        value: '',
                        align: 'right'  // left, right
                    },
                    onClick: null,
                    style: {},
                    class: ''
                }
            };


            // *****************************************************************************************
            // btn settings set with default values
            // *****************************************************************************************
            // {
            //     visible: false,
            //     position: 'above',                        // toolbar, above, below
            //     type: '',                                 // button type, types are same as for bootstrap
            //     text: {
            //         value: '',                            // word_id for button
            //         align: 'center'
            //     },
            //     icon: {
            //         value: 'glyphicon glyphicon-save',
            //         align: 'right'
            //     },
            // }

            var updateButtonConfig = function (type, defaultType, defaultText) {
                vm.ttBtnOptions = vm.ttBtnOptions || {};
                vm.ttBtnOptions[type] = vm.ttBtnOptions[type] || {};
                vm.ttBtnOptions[type].text = vm.ttBtnOptions[type].text || {};
                vm.ttBtnOptions[type].icon = vm.ttBtnOptions[type].icon || {};

                vm.buttonsConfig[type].visible = us.toBoolean(vm.ttBtnOptions[type].visible, false);
                vm.buttonsConfig[type].position = vm.ttBtnOptions[type].position || 'above';
                vm.buttonsConfig[type].type = vm.ttBtnOptions[type].type || defaultType;
                vm.buttonsConfig[type].text.value = vm.ttBtnOptions[type].text.value || defaultText || '';
                vm.buttonsConfig[type].text.align = vm.ttBtnOptions[type].text.align || 'center';
                vm.buttonsConfig[type].icon.value = vm.ttBtnOptions[type].icon.value || 'glyphicon glyphicon-save';
                vm.buttonsConfig[type].icon.align = vm.ttBtnOptions[type].icon.align || 'right';
            };

            var updateConfigFromSchema = function (type, options) {
                if (angular.isDefined(options.visible) && options.visible !== null) {
                    vm.buttonsConfig[type].visible = options.visible;
                }

                if (angular.isDefined(options.position) && options.position !== null) {
                    vm.buttonsConfig[type].position = options.position;
                }

                if (angular.isDefined(options.style) && options.style !== null) {
                    vm.buttonsConfig[type].type = options.style;
                }

                if (angular.isDefined(options.text) && angular.isDefined(options.text.value) && options.text.value !== null) {
                    vm.buttonsConfig[type].text.value = options.text.value;
                }

                if (angular.isDefined(options.text) && angular.isDefined(options.text.align) && options.text.align !== null) {
                    vm.buttonsConfig[type].text.align = options.text.align;
                }

                if (angular.isDefined(options.icon) && angular.isDefined(options.icon.value) && options.icon.value !== null) {
                    vm.buttonsConfig[type].icon.value = options.icon.value;
                }

                if (angular.isDefined(options.icon) && angular.isDefined(options.icon.align) && options.icon.align !== null) {
                    vm.buttonsConfig[type].icon.align = options.icon.align;
                }
            };

            vm.saveButtonClick = function () {
                vm.ttOptions.gridfunc.saveChanges();
            };

            vm.addButtonClick = function () {
                vm.ttOptions.gridfunc.addRowAfter();
            };

            vm.removeButtonClick = function () {
                vm.ttOptions.gridfunc.removeRows();
            };

            vm.$onInit = function () {
                vm.ttOptions.onSetup = function (schema) {
                    var buttonOptions = null;

                    angular.forEach(schema, function (col) {
                        if (col.colname === 'xxxoptionsxxx') {
                            buttonOptions = col.buttonoptions;
                        }
                    });

                    if (angular.isDefined(buttonOptions) && buttonOptions !== null) {
                        updateConfigFromSchema('add', buttonOptions.add);
                        updateConfigFromSchema('remove', buttonOptions.remove);
                        updateConfigFromSchema('save', buttonOptions.save);
                    }
                };

                updateButtonConfig('save', 'success', 'ttgrid_save');
                updateButtonConfig('add', 'primary', 'ttgrid_add');
                updateButtonConfig('remove', 'danger', 'ttgrid_remove');
            };
        }]
    });
})();
