(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poFinishService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_pofinish_get: {
                p2_pofinish_keyno: '',
                portal_user_name: '',
                reg_datetime: '',
                pofinishprodnbr: '',
                p2_pofinishprod_keyno: ''
            },
            loadP2PoFinishGet: function (p2_pofinish_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinish_get = {
                    method: 180,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinish_get).then(function (data) {
                    service.p2_pofinish_get = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();