(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory5Lang', {
        templateUrl: 'views/components/views/textileModelCategory5Lang/textileModelCategory5Lang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'textileModelCategory5LangService', function ($stateParams, stateService, textileModelCategory5LangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var textileModelCategory5Keyno = $stateParams.textilemodelcategory5_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory5LangService.listTextileModelCategory5Languages({ textilemodelcategory5_keyno: textileModelCategory5Keyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                textileModelCategory5LangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();