(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("activityTypeActStatusService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1339,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1340,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
