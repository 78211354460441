import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges } from '@angular/core';
import { ComponentBaseComponent } from '../../component-base/component-base.component';
import { Style } from '@app/core/services/core-component.service';
import { TranslateService } from '@app/core/services/translate.service';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
    selector: 'tt-overflow-menu-item',
    templateUrl: './overflow-menu-item.component.html',
    styleUrls: ['./overflow-menu-item.component.css'],
})
export class OverflowMenuItemComponent extends ComponentBaseComponent implements OnChanges {
    @Input()
    ttText: string = '';

    @Input()
    ttIcon?: string;

    @Input()
    ttTooltipText?: string;

    @Input()
    set ttHasDivider(value: BooleanInput) {
        this._hasDivider = coerceBooleanProperty(value);
    }
    get ttHasDivider(): boolean {
        return this._hasDivider;
    }
    _hasDivider: boolean = false;

    @Input()
    set ttDanger(value: BooleanInput) {
        this._danger = coerceBooleanProperty(value);
    }
    get ttDanger(): boolean {
        return this._danger;
    }
    _danger: boolean = false;

    // @Input()
    // set ttDisabled(value: BooleanInput) {
    //     this._disabled = coerceBooleanProperty(value);
    // }
    // get ttDisabled(): boolean {
    //     return this._disabled;
    // }
    // _disabled: boolean = false;

    @Input()
    ttDisabled?: boolean = false;

    @Output()
    ttClick = new EventEmitter<MouseEvent>();

    id = {
        button: crypto.randomUUID(),
    };

    style: Style = {
        button: {},
    };

    translations = {
        ttText: '',
    };

    constructor(private translateService: TranslateService, private layoutService: LayoutService, private elementRef: ElementRef, private renderer: Renderer2) {
        super();

        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['button'].fontSize = info.fontSizes.textSize;
            }
        });

        this.renderer.setAttribute(this.elementRef.nativeElement, 'role', 'presentation');
    }

    public click(event: MouseEvent): void {
        this.ttClick.emit(event);
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes?.['ttText']?.currentValue) {
            this.translations.ttText = await this.translateService.translate(changes?.['ttText']?.currentValue);
        }

        if (changes?.['ttDisabled']) {
            this.ttDisabled = changes['ttDisabled'].currentValue;
        }
    }
}

//    * @typedef {Object} OverflowMenuItemProps represents the properties of the overflow-menu-item component.
//    * @property {string | undefined} ttIcon (optional) the icon classes for the icon to display.
//    * @property {string} ttText the text to display for the menu option.
//    * @property {string} ttClick callback when the menu item is clicked.
//    * @property {'seperator' | undefined} ttType (optional) the type of the menu item, none is default button, or 'seperator' can be specified.
//    * @property {boolean | undefined} ttDanger (optional) whether the menu item should be marked with danger styling.
//    */

//   module.component('ttOverflowMenuItem', {
//       templateUrl: 'views/components/directives/ttOverflowMenu/ttOverflowMenuItem.template.html?v=' + module.version,
//       controllerAs: 'vm',
//       /** @type OverflowMenuItemProps */
//       bindings: {
//           ttIcon: '@?',
//           ttText: '@',
//           ttClick: '&',

//           vm.click = function () {
//               if (vm.ttClick && typeof vm.ttClick === 'function') {
//                   vm.ttClick();
//               }
//           };

//           vm.$onInit = function () {
//               layoutService.onLayoutChanged([], (info) => {
//                   vm.style.button.fontSize = info.fontSizes.textSize;
//               });
//           };

//           vm.$onChanges = async function (changes) {
//               if (changes?.ttText?.currentValue && typeof changes.ttText.currentValue === 'string' && changes.ttText.currentValue !== changes.ttText.previousValue) {
//                   vm.translations.ttText = await translateService.translate(changes.ttText.currentValue)
//               }
//           };
