(function () {
    'use strict';

    angular.module('imApp').component('ttDatasourceEdit', {
        templateUrl: 'views/components/dynamics/views/ttDatasourceEdit/ttDatasourceEdit.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$timeout', '$uibModal', 'ttDatasourceEditService', 'ttDatasourceParameterEditService', 'ttDynamicViewService', 'ttDatasourceManager', 'translateService', 'ieScreenBlockService', function ($timeout, $uibModal, ttDatasourceEditService, ttDatasourceParameterEditService, ttDynamicViewService, ttDatasourceManager, translateService, ieScreenBlockService) {
            var vm = this;

            let rememberIdGrid = 'ttDatasourceEditGrid';

            let parameters = {};

            vm.datasource = {};

            vm.isGridType = false;

            vm.routeId = '';

            let translations = {
                tt_confirm_delete_datasourceparameter: ''
            };

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            vm.datatypes = [
                { id: 'object', name: 'Object' },
                { id: 'array', name: 'Array' },
                { id: 'gridselectedrow', name: 'Grid Selected Row' },
                { id: 'gridselectedrows', name: 'Grid Selected Rows' },
                { id: 'griddirtyrows', name: 'Grid Dirty Rows' },
                { id: 'gridallrows', name: 'Grid All Rows' }
            ];

            vm.datatasks = [];

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2434,
                        parameters: function() {
                            return { tt_datasource_keyno: vm.datasource.tt_datasource_keyno };
                        }
                    },
                    addRow: {
                        autoSave: true,
                        pre: function (dataItem) {
                            dataItem.tt_datasource_keyno = vm.datasource.tt_datasource_keyno;
                            dataItem.parm_type = 'static';
                            dataItem.parm_name = '';

                            return dataItem;
                        },
                        post: onEditRow
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: true,
                        delete: false,
                        save: false,
                        wrapping: false,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: false,
                        edit: false,
                        buttons: [
                            { name: 'lineDelete', text: '', func: onDeleteRow, icon: 'far-times', type: 'danger' },
                            { name: 'lineEdit', text: '', func: onEditRow, icon: 'far-pencil' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            let changedPromise = null;

            vm.onDataChanged = function (value, modelId) {
                let parts = modelId.split('.');

                let id = parts[parts.length - 1];

                vm.datasource[id] = value;

                if (id === 'data_type') {
                    setIsGridType();
                }

                var updating = false;

                let update = function () {
                    changedPromise = null;

                    if (updating === true) {
                        changedPromise = $timeout(update, 350);
                        return;
                    }

                    updating = true;

                    ttDatasourceEditService.update(vm.datasource).then(function () {
                        updating = false;
                    });
                };

                if (changedPromise !== null) {
                    $timeout.cancel(changedPromise);
                }

                changedPromise = $timeout(update, 350);
            };

            vm.setDatatask = function (item, model) {
                vm.onDataChanged(item.p2_datatask_keyno, 'vm.datasource.p2_datatask_keyno');
            };

            vm.setDatataskSave = function (item, model) {
                vm.onDataChanged(item.p2_datatask_keyno, 'vm.datasource.p2_datatask_keyno_save');
            };

            function onEditRow(row) {
                parameters.row = row;

                var instance = $uibModal.open({
                    component: 'ttDatasourceParameterEdit',
                    resolve: {
                        parameters: function () {
                            return {
                                datasource: vm.datasource,
                                datasourceParameter: row,
                                instanceId: parameters.instance_id
                            };
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                instance.result.then(vm.gridOptions.gridfunc.read, vm.gridOptions.gridfunc.read);
            };

            function setIsGridType() {
                vm.isGridType =
                    angular.isString(vm.datasource.data_type) &&
                    vm.datasource.data_type !== null &&
                    vm.datasource.data_type.length > 4 &&
                    vm.datasource.data_type.substring(0, 4) === 'grid';
            };

            function onDeleteRow(row) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return translations.tt_confirm_delete_datasource;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        return;
                    }

                    ieScreenBlockService.start();

                    ttDatasourceParameterEditService.deleteParameter(row.tt_datasourceparameter_keyno).then(function () {
                        vm.gridOptions.gridfunc.read();

                        ieScreenBlockService.stop();
                    });
                });
            };

            ttDynamicViewService.getDatataskList().then(function (response) {
                angular.copy(response, vm.datatasks);
            });

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                angular.copy(vm.resolve.parameters, parameters);

                vm.routeId = parameters.route_id;

                angular.copy(parameters.row, vm.datasource);

                ttDatasourceEditService.init(vm.datasource.tt_datasource_keyno).then(function (response) {
                    vm.datasource.datatask = response.datataskRead;
                    vm.datasource.datataskSave = response.datataskSave;

                    setIsGridType();
                });
            };
        }]
    });
})();