(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('freightRateType', {
        templateUrl: 'views/components/views/freightRateType/freightRateType.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'freightRateTypeService', function ($stateParams, stateService, modalService, freightRateTypeService) {
            var vm = this;
            
            vm.model = {
                freightRateTypeKeyNo: $stateParams.freightratetype_keyno,
                settingRateType: {},
                lockedSave: false
            };
            
            freightRateTypeService.loadFreightRateTypeGet(vm.model.freightRateTypeKeyNo).then(function (data) {
                vm.model.settingRateType = angular.copy(data[0]);
            });
            
            vm.saveFreightRateType = function () {
                vm.model.lockedSave = true;
                
                freightRateTypeService.save(vm.model.settingRateType).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();