(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("invoiceMethodService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadInvoiceMethodGet: function (invoicemethod_no) {
                return p2DataTaskService.call(1763, {
                    invoicemethod_no: invoicemethod_no
                });
            },
            save: function (invoice) {
                return p2DataTaskService.call(1764, invoice);
            }
        };

        return service;
    }]);
})();