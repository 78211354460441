(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('gisPositionAdd', {
        templateUrl: 'views/components/views/gisPositionAdd/gisPositionAdd.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'gisPositionAddService', function ($stateParams, stateService, modalService, gisPositionAddService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                lat: $stateParams.lat,
                lng: $stateParams.lng,
                add: {},
                readyGisPositionNote: true,
                lockedSave: false
            };
            
            gisPositionAddService.loadGisPositionAddGet(vm.model.argType, vm.model.argValue, vm.model.lat, vm.model.lng).then(function (data) {
                vm.model.add = angular.copy(data[0]);
            });
            
            vm.saveGisPositionAdd = function () {
                vm.model.lockedSave = true;
                
                gisPositionAddService.save(vm.model.add).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();