(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCrewMenu', {
        templateUrl: 'views/components/views/srCrewMenu/srCrewMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srCrewMenuService', function($stateParams, stateService, utilityService, srCrewMenuService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceId: $stateParams.resource_id,
                srCrewKeyNo: $stateParams.srcrew_keyno,
                bgSrCrewMenuMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_srcrewmenu_main_basis', item_func: 'BASIS' },
                        { item_id: '1', item_name: 'bg_srcrewmenu_main_memberof', item_func: 'MEMBEROF' },
                        { item_id: '2', item_name: 'bg_srcrewmenu_main_other', item_func: 'OTHER' }
                    ]
                },
                menu: {},
                itemsListBelongings: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCrewMenuService.loadSrCrewMenuGet(vm.model.resourceId, vm.model.srCrewKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.menu);
            });
    
            srCrewMenuService.loadSrCrewMenuBelongToList(vm.model.srCrewKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListBelongings);
            });
    
            // ## BUTTON GROUP FUNCTIONS ##
    
            vm.selectBgSrCrewMenuMain = function (item) {
                vm.model.bgSrCrewMenuMain.item_selected = item;
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srcrew':
                        go({
                            resource_id: vm.model.menu.resource_id,
                            srcrew_keyno: vm.model.menu.srcrew_keyno
                        });
                        break;
                    case 'srcrewresource':
                        go({
                            srcrewresource_keyno: '0',
                            resource_id: '0',
                            srcrew_keyno: vm.model.menu.srcrew_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();