(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceMakeMultiOrders', {
        templateUrl: 'views/components/views/invoiceMakeMultiOrders/invoiceMakeMultiOrders.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'invoiceMakeMultiOrdersService', function ($stateParams, invoiceMakeMultiOrdersService) {
            var vm = this;
            
            vm.model = {
                custNo: $stateParams.cust_no,
                invoiceMethodNo: $stateParams.invoicemethod_no,
                businessCoNo: $stateParams.businessco_no,
                settingMultiOrder: {},
                itemsListMultiOrders: []
            };
            
            invoiceMakeMultiOrdersService.loadInvoiceMakeMultiOrdersGet(vm.model.custNo, vm.model.invoiceMethodNo, vm.model.businessCoNo).then(function (data) {
                angular.copy(data[0], vm.model.settingMultiOrder);
                
                loadInvoiceMakeMultiOrders();
            });
            
            var loadInvoiceMakeMultiOrders = function () {
                vm.model.itemsListMultiOrders = [];
                
                invoiceMakeMultiOrdersService.loadInvoiceMakeMultiOrdersList(vm.model.settingMultiOrder).then(function (data) {
                    angular.copy(data, vm.model.itemsListMultiOrders);
                });
            };
        }]
    });
})();