(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockReplenishmentOverview', {
        templateUrl: 'views/components/views/stockReplenishmentOverview/stockReplenishmentOverview.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: [function () {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            vm.grid = {
                dataTask: {
                    rememberId: 'w_stockReplenishmentOverview',         // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 1863,
                        parameters: { p2_exportproc_keyno: '5' }
                    },
                    //loadData: {                                         // Method id of the get data procedure
                    //    method: 1000008,
                    //    parameters: { p2_exportproc_keyno: '1000007' }
                    //},
                    addRow: {                                           // NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    },
                    saveData: {                                         // NOT IMPLEMENTED
                        method: null,
                        parameters: null
                    }
                },
                config: {                                               // TouchTime parameters configuring the grid
                    //editColumns: ['prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'],
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: false,                               // Adds a predefined btn for exporting grid to pdf
                        excelExport: false,                             // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: true,                                      // Adds predefined btns for running an add function     // Supports addRow      // NOT IMPLEMENTED
                        delete: true,                                   // Adds a predefined btn for running a delete function  // Supports removeRow   // NOT IMPLEMENTED
                        save: true,                                     // Adds a predefined btn for running a save function    // Needs saveData       // NOT IMPLEMENTED
                        buttons: [                                      // List of custom buttons
                            { name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'customTest', text: 'Dette er en tekst', func: function () { testItems(); }, icon: 'star' }
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    //aggregate: [                                      // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    //    { field: 'stock_stockvalue', aggregate: 'sum' }
                    //],
                    pager: false,                                       // NOT IMPLEMENTED
                    //selectable: 'multiple, row',                      // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) {
                console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    console.log('optionfunc - CellClickHandler');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    console.log('optionfunc - OnCellClose');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    console.log('optionfunc - LookupCellEditor');
                    console.dir(data);
                }
            };

            var testItems = function () {
                console.log('Test run successfully');
                //var index = $scope.gridOptions.gridfunc.addRowBefore();
            };

            vm.testFunc = function () {
                console.log('testFunc runs');
            }

            // #endregion OPTIONFUNC
        }]
    });
})();
