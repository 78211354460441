(function () {
    'use strict';

    angular.module("imApp").factory("usergroupSettingService", ['$ihttp', function ($ihttp) {
        let service = {
            listUserGroupSettings: function (parms) {
                return $ihttp.post({
                    method: 37,
                    parameters: parms || {}
                });
            },
            changeAllItems: function (items) {
                return $ihttp.post({
                    method: 1114,
                    parameters: items
                });
            }
        };

        return service;
    }]);
})();
