(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectOperationPu', {
        templateUrl: 'views/components/views/selectOperationPu/selectOperationPu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectOperationPuService', function ($stateParams, selectOperationPuService) {
            var vm = this;
            
            vm.model = {
                isDirect: 1,
                bgOptions: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '1',
                    records: [
                        { item_id: '1', item_name: 'operation_direct', item_func: '1' },
                        { item_id: '2', item_name: 'operation_indirect', item_func: '0' }
                    ]
                }
            };
            
            vm.retrieve = function () {
                selectOperationPuService.loadP2SelectOperationPuList($stateParams.webpagename, vm.model.isDirect).then(function () {
                    vm.p2_select_operation_pu_list = selectOperationPuService.p2_select_operation_pu_list;
                });
            };
            
            vm.retrieve();
            
            vm.saveToPreP2SelectOperationPu = function (item) {
                selectOperationPuService.saveToPreP2SelectOperationPu(item);
            };
            
            vm.selectP2OperationTrList = function (selectedBgOption) {
                vm.model.bgOptions.item_selected = selectedBgOption;
                vm.model.isDirect = selectedBgOption;
                vm.retrieve();
            };
        }]
    });
})();