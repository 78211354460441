(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('blkRequisition', {
        templateUrl: 'views/components/views/blkRequisition/blkRequisition.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'ieScreenBlockService', 'blkRequisitionService', function ($stateParams, $timeout, stateService, ieScreenBlockService, blkRequisitionService) {
            var vm = this;

            vm.model = {
                bgListType: {
                    selected: 'LISTTYPE',
                    buttons: [
                        { id: 'LISTTYPE', label: 'bg_list_type', color: 'primary', onClick: () => vm.model.bgListType.selected = 'LISTTYPE' },
                        { id: 'LISTTYPEOPEN', label: 'bg_list_type_open', color: 'primary', onClick: () => vm.model.bgListType.selected = 'LISTTYPEOPEN' }
                    ]
                },
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item && vm.model.mconfig.records[r].canActive === true) {
                                    vm.model.mconfig.records[r].isActive = true;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.blkRequisitionProductsColumnResize();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }

                        /** CHECK TOGGLE */

                        if (item.item_name === 'check') {
                            checkLines();
                        }

                        if (item.item_name === 'erase') {
                            var item = {};
                            if (vm.model.products.length > 0) {
                                item = vm.model.products[0];
                                vm.model.products = [];
                                vm.model.productsRaw = [];
                                vm.model.products.push(item);
                            }
                            vm.updateSelectedCount();
                        }

                        if (item.item_name === 'cart') {
                            if (item.isActive) {
                                vm.model.futuremanko_only = 1;
                                vm.loadProducts();
                            } else {
                                vm.model.futuremanko_only = 0;
                                vm.loadProducts();
                            }
                        }

                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.blkRequisitionProductsColumnResize();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                        { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '5', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: false },
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        //{ item_id: '7', item_name: 'cart', glyph: 'glyphicon-shopping-cart', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: true },
                        { item_id: '8', item_name: 'edit', glyph: 'glyphicon-edit', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: true },
                        { item_id: '9', item_name: 'check', glyph: 'glyphicon-check', color: 'primary', show: true },
                        { item_id: '10', item_name: 'erase', glyph: 'glyphicon-erase', color: 'warning', show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: true, show: true }
                    ]
                },
                productSearchBtnList: [
                    { item_id: '0', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                pageSettings: {
                    date_fom: "",
                    date_tom: "",
                    dateselector_index: "1"
                },
                products: [],
                productsRaw: [],
                selectedCount: 0,
                searching: false,
                loaded: false,
                checkMode: 0
            };

            if (!parseInt($stateParams.counter) > 0) {
                $stateParams.counter = 0;
            }

            var searchResult = {};

            vm.getEditEnabled = function () {
                var out = false;

                if (vm.model.bgListType.selected === 'LISTTYPE') {
                    out = true;
                }

                return out;
            };

            vm.getShowEnabled = function () {
                var out = false;

                if (vm.model.bgListType.selected === 'LISTTYPE') {
                    out = true;
                }

                return out;
            };

            vm.loadSettings = function () {
                blkRequisitionService.loadSettings().then(function (data) {
                    angular.copy(data[0], vm.model.pageSettings);
                    vm.loadProducts();
                });
            };

            vm.loadSettings();

            vm.loadProducts = function () {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Loading products');

                blkRequisitionService.loadProducts({
                    date_fom: vm.model.pageSettings.date_fom,
                    date_tom: vm.model.pageSettings.date_tom
                }).then(function (data) {
                    var smallDataLoad = [];

                    for (var i = 0; i < data.length; i++) {
                        data[i].item_id = vm.illegalCharRegExp(data[i].item_id);
                        smallDataLoad.push(data[i]);
                    }

                    angular.copy(smallDataLoad, vm.model.products);
                    angular.copy(smallDataLoad, vm.model.productsRaw);
                    vm.model.loaded = true;
                    ieScreenBlockService.stop();
                });
            };

            vm.loadOpenProducts = function () {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Loading products');

                blkRequisitionService.loadOpenProducts().then(function (data) {
                    var smallDataLoad = [];

                    for (var i = 0; i < data.length; i++) {
                        data[i].item_id = vm.illegalCharRegExp(data[i].item_id);
                        smallDataLoad.push(data[i]);
                    }

                    angular.copy(smallDataLoad, vm.model.products);
                    angular.copy(smallDataLoad, vm.model.productsRaw);
                    vm.model.loaded = true;
                    ieScreenBlockService.stop();
                });
            };

            vm.illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            };

            vm.productSearchBtnListFunc = function (value, item_func) {
                vm.model.product_search = value;
                vm.onProductSearch();
                //if (item_func === 'search_item') {
                vm.model.searching = true;
                return vm.typeAheadSearch(vm.model.product_search).then((result) => {
                    return result;
                });
                //}
            };

            vm.typeAheadSearch = function (value) {
                if (vm.model.product_search !== ' ') {
                    searchResult = {};
                    return blkRequisitionService.typeAheadSearch(vm.model.product_search).then(function (data) {
                        angular.forEach(data, function (item) {
                            searchResult[item.item_name] = item;
                        })

                        return searchResult;
                    });
                }
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgListType = function (value) {
                vm.model.bgListType.selected = value;

                if (vm.model.bgListType.selected === 'LISTTYPEOPEN') {
                    vm.loadOpenProducts();
                } else {
                    vm.model.mconfig.records[6].enabled = true;
                    vm.model.mconfig.records[6].show = true;
                    vm.loadProducts();
                }
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.createRequisition = function () {
                var records = [];

                if (vm.model.bgListType.selected === 'LISTTYPEOPEN') {
                    angular.forEach(vm.model.products, function (item) {
                        if (item.item_is_selected === '1') {
                            records.push({
                                qty: item.item_secondary[4].item_col,
                                requisition_no: item.requisition_no
                            });
                        }
                    });

                    blkRequisitionService.updateRequisition(records).then(function (data) {
                        stateService.go('blkrequisition', {
                            counter: 1
                        }, { reload: true, inherit: false });
                    });
                } else {
                    angular.forEach(vm.model.products, function (item) {
                        if (item.item_is_selected === '1') {
                            records.push({
                                qty: item.item_secondary[4].item_col,
                                stock_keyno: item.stock_keyno_to
                            });
                        }
                    });

                    blkRequisitionService.createRequisition(records).then(function (data) {
                        stateService.go('blkrequisition', {
                            counter: 1
                        }, { reload: true, inherit: false });
                    });
                }
            };

            vm.onProductSearch = function () {
                if (searchResult[vm.model.product_search]?.item_id) {
                    let shortProdId = searchResult[vm.model.product_search].item_secondary[0].item_col;
                    vm.model.product_search = '';

                    if (shortProdId) {
                        ieScreenBlockService.start('message');
                        ieScreenBlockService.setMessage('Loading products from shortId');

                        blkRequisitionService.loadProductsFromShort(prod_id_short).then(function (data) {
                            data.forEach((item) => {
                                item.item_id = vm.illegalCharRegExp(item.item_id);
                                item.item_secondary[4].item_col = parseFloat(item.item_secondary[4].item_col).toFixed(2);
                                vm.model.products.push(item);
                            })

                            vm.model.loaded = true;
                            ieScreenBlockService.stop();
                        });
                    }
                }
            }

            vm.gridClick = function (item) {
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;
                if (baseItem.item_id != 0) {
                    if (baseItem.item_is_selected != '0') {
                        baseItem.item_is_selected = '0';
                    } else {
                        baseItem.item_is_selected = '1';
                    }
                } else {
                    if (baseItem.item_is_selected != '0') {
                        vm.unCheckAll();
                    } else {
                        vm.checkAll();
                    }
                }

                vm.updateSelectedCount();
            };

            var checkLines = function () {
                if (vm.model.checkMode > 0) {
                    //uncheck all
                    angular.forEach(vm.model.products, function (item) {
                        item.item_is_selected = '0';
                    });

                    vm.model.checkMode = 0;
                } else {
                    //check all
                    angular.forEach(vm.model.products, function (item) {
                        if (item.item_id !== '0') {
                            item.item_is_selected = '1';
                        }
                    });

                    vm.model.checkMode = 1;
                }

                vm.updateSelectedCount();
            };

            /** mconfig functions */

            vm.unCheckAll = function () {
                angular.forEach(vm.model.products, function (item) {
                    item.item_is_selected = '0';
                });
            };

            vm.checkAll = function () {
                angular.forEach(vm.model.products, function (item) {
                    item.item_is_selected = '1';
                });
            };

            vm.updateSelectedCount = function () {
                vm.model.selectedCount = 0;

                angular.forEach(vm.model.products, function (item) {
                    if (item.item_is_selected === '1') {
                        vm.model.selectedCount += 1;
                    }
                });
            };

            vm.blkRequisitionProductsColumnResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }

                var updateList = [];

                if (angular.isDefined(vm.model.products[0]) && angular.isDefined(vm.model.products[0].item_secondary)) {
                    for (var r = 0; r < vm.model.products[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.products[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.products[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.products[0].item_secondary[r].item_col_width })
                        }
                    }
                }

                var updateParms = {
                    view_id: 'blkrequisition.list',
                    records: updateList
                };

                blkRequisitionService.updateColumnResize(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }

                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }

                    resizing = false;
                });
            };

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.pageSettings.date_fom = startDate;
                vm.model.pageSettings.date_tom = endDate;
                vm.model.pageSettings.dateselector_index = dateInterval;

                if (vm.model.pageSettings.date_fom) blkRequisitionService.remember('blkrequisition.date_fom', vm.model.pageSettings.date_fom);

                if (vm.model.pageSettings.date_tom) blkRequisitionService.remember('blkrequisition.date_tom', vm.model.pageSettings.date_tom);

                if (vm.model.pageSettings.dateselector_index) blkRequisitionService.remember('blkrequisition.dateselector_index', vm.model.pageSettings.dateselector_index);

                if (vm.model.pageSettings.date_fom && vm.model.pageSettings.date_tom) {
                    vm.loadProducts();
                }
            }
        }]
    });
})();
