(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDelReceiveLineService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadBatchDelReceiveLineGet: function (batchdelreceiveline_keyno) {
                return p2DataTaskService.call(1596, {
                    batchdelreceiveline_keyno: batchdelreceiveline_keyno
                });
            },
            deleteObj: function (receiveLine) {
                return p2DataTaskService.call(1597, receiveLine);
            }
        };

        return service;
    }]);
})();