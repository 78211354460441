(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("serviceObjectTypeMenuService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadServiceObjectTypeMenuGet: function (serviceobjecttype_keyno) {
                return p2DataTaskService.call(1740, {
                    serviceobjecttype_keyno: serviceobjecttype_keyno
                });
            }
        };

        return service;
    }]);
})();