(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('tempstock', {
        templateUrl: 'views/components/views/tempstock/tempstock.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'tempstockService', 'rememberService', function ($q, tempstockService, rememberService) {
            var vm = this;

            var rememberIdGrid = 'tempstockGrid';

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 3391,
                        parameters: function () {
                            return {};
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: true
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };
        }]
    });
})();
