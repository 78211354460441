(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListLd', {
        templateUrl: 'views/components/views/srCheckListLd/srCheckListLd.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'srCheckListLdService', function($stateParams, stateService, utilityService, modalService, srCheckListLdService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                srRefundInputKeyNo: $stateParams.srrefundinput_keyno,
                lossDamage: {},
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListLdService.loadSrCheckListLdGet(vm.model.checkListDataKeyNo, vm.model.srRefundInputKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.lossDamage);
            });
    
            // ## SHOW SIGNATURE FUNCTION ##
    
            vm.showPad = function () {
                modalService.showSignaturePad().then(function (value) {
                    consoleService.log('Pad finished');
                    consoleService.log(value);
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'pictures':
                        go({
                            argtype: 'srrefundinput_keyno',
                            argvalue: vm.model.lossDamage.srrefundinput_keyno
                        });
                        break;
                    case 'documents':
                        go({
                            argtype: 'srrefundinput_keyno',
                            argvalue: vm.model.lossDamage.srrefundinput_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrCheckListLd = function () {
                vm.model.lockedSave = true;
                
                srCheckListLdService.save(vm.model.lossDamage).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteSrCheckListLd = function () {
                vm.model.lockedDelete = true;
                
                srCheckListLdService.deleteObj(vm.model.lossDamage).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelete = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();