(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockCount', {
        templateUrl: 'views/components/views/stockCount/stockCount.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'stockCountService', function ($stateParams, stateService, stockCountService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
            
            vm.model = {
                locked: false,
                p2WStockCountGet: {}
            };
    
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
            
            stockCountService.loadP2WStockCountGet($stateParams.stockplace_id, $stateParams.stockcount_keyno).then(function (data) {
                vm.model.p2WStockCountGet = angular.copy(data[0]);
            });
            
            //#######################
            //## SAVE BUTTON FUNCTION
            //#######################
            
            vm.saveStockCount = function () {
                vm.model.locked = true;

                stockCountService.saveP2WStockCount(vm.model.p2WStockCountGet).then(function (data) {
                    stateService.back();
                    vm.model.locked = false;
                });
            };
        }]
    });
})();