(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishList', {
        templateUrl: 'views/components/views/poFinishList/poFinishList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['poFinishListService', function (poFinishListService) {
            var vm = this;
            
            vm.pofinish_status = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: '0',
                records: [
                    { item_id: '0', item_name: 'in_progress', item_func: '0' },
                    { item_id: '1', item_name: 'finished', item_func: '1' }
                ]
            };
            
            vm.retrieve = function () {
                poFinishListService.loadP2PoFinishList(vm.pofinish_status.item_selected).then(function () {
                    vm.p2_pofinish_list = poFinishListService.p2_pofinish_list;
                });
            };

            vm.selectPoFinishStatus = function (l_s_pofinishstatus) {
                vm.pofinish_status.item_selected = l_s_pofinishstatus;
                vm.retrieve();
            };
        }]
    });
})();