(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttPopover', {
        templateUrl: 'views/components/directives/ttPopover/ttPopover.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            /** Configuartion object for the popover and its content. */
            ttOptions: '='
        },
        controller: ['$window', 'layoutService', 'utilityService', 'translateService', 'ttDirectivesService', function ($window, layoutService, us, translateService, ttDirectivesService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            /** Functions to call when component is destroyed. */
            let onDestroy = [];

            /** The element which has the relative element id. This element triggers opening of the popover modal when clicked.*/
            let relativeElement;

            /** Style object for arrow to point upwards. */
            let upArrow = {
                'position': 'absolute',
                'top': '0',
                'width': '0',
                'height': '0',
                'border-left': '20px solid transparent',
                'border-right': '20px solid transparent',
                'border-bottom': '20px solid var(--tt-card--color)',
                'border-radius': '6px',
                'margin': '2rem',
            }

            /** Style object for arrow to point downwards. */
            let downArrow = {
                'position': 'absolute',
                'top': '0',
                'width': '0',
                'height': '0',
                'border-left': '20px solid transparent',
                'border-right': '20px solid transparent',
                'border-top': '20px solid var(--tt-card--color)',
                'border-radius': '6px',
                'margin': '2rem',
            }

            vm.id = {
                modal: uuid(),
            }

            vm.style = {
                modal: {
                    'padding': '6px',
                    'position': 'absolute',
                    'width': '300px',
                    'background-color': 'var(--tt-card--color)',
                    'border-radius': '6px',
                    'pointer-events': 'all',
                    'max-height': '500px',
                    'overflow-y': 'scroll',
                    'z-index': '5',
                },
                arrow: {},
                label: {
                    fontSize: '',
                },
                input: {
                    label: {
                    },
                    textarea: { padding: '4px' },
                },
                message: {
                    label: {
                        size: 'textSizeS'
                    },
                    sublabel: {
                        size: 'textSizeS'
                    },
                    message: {
                        size: 'textSize',
                        padding: '4px',
                    },
                },
                checklist: {
                    minHeight: '80px',
                    maxHeight: '250px',
                }
            };

            vm.class = {
                label: '',

            };

            vm.translations = {
                listTitle: ''
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region MODAL STATE


            /** Whether the modal is open or not. */
            vm.open = false;

            /**
             * Searches recursively through children of the given target to find
             * the given element. Returns true if the element is in the target's tree
             * false if not
             * 
             * @param element the element to find in the target's tree.
             * @param target the target to search in.
             * @returns {boolean} true if the element is in the target's tree false if not.
             */
            function isElementInTarget(element, target) {
                if (element === target) return true;

                for (let i = 0; i < element.children.length; i++) {
                    let child = element.children[i];
                    let found = isElementInTarget(child, target);

                    if (found) {
                        return true;
                    }
                };

                return false;
            }

            /**
             * Resets the values in the modal.
             */
            function resetModal() {
                vm.ttOptions.data.forEach((element) => {
                    if (element.type === 'checklist') {
                        element.data.list.map((item) => item.is_selected = false);
                    }
                    if (element.type === 'textarea') {
                        element.text = '';
                    }
                })
            }

            /**
             * Closes the modal if the even happened outside of the modal element.
             */
            vm.closeModal = function (event) {
                let modal = document?.getElementById(`${vm.id.modal}`);
                let targetInModal = false;

                if (!event) {
                    //resetModal();
                    vm.open = false;
                    return;
                }

                if (modal) targetInModal = isElementInTarget(modal, event.target);

                //if (modal !== null && modal !== undefined) {
                //    targetInModal = isElementInTarget(modal, event.target);
                //}

                //!targetInModal && resetModal();
                vm.open = targetInModal;

                if (vm.open === false) vm.ttOptions.events.onClose(); 
            }

            /**
             * Toggles the modal between open and closed state.
             */
            const toggleModal = function () {
                if (vm.open === true) {
                    vm.open = false;
                    observer.disconnect()
                    resetModal();
                } else if (vm.open === false) {
                    vm.open = true;
                    observer.observe(document.body, { childList: true, subtree: true, attributes: true });
                }
            }

            // #endregion MODAL STATE

            // #region CHECKLIST

            vm.isListChecked = function (data) {
                let hasUnchecked = false;

                angular.forEach(data.list, function (row) {
                    if (hasUnchecked) return;
                    angular.forEach(row, function (value, key) {
                        if (hasUnchecked || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                        let val = value.toLowerCase();
                        let fltr = data.filter.toLowerCase();

                        if (val.includes(fltr)) {
                            if (angular.isUndefined(row.is_selected) || row.is_selected === false) {
                                hasUnchecked = true;
                            }
                        }
                    });
                });

                data.hasUnchecked = hasUnchecked;

                return !hasUnchecked;
            };

            vm.goCheckToggle = function (e, item, index, data) {
                item.is_selected = angular.isUndefined(item.is_selected) ? true : !item.is_selected;

                data.onChecklistChanged(data.list, data.list.filter((item) => item.is_selected === true).map((item) => item.item_id));
            };

            vm.goCheckToggleAll = function (e, data) {
                angular.forEach(data.list, function (row) {
                    if (data.filter !== '') {
                        let continueLoop = true;
                        angular.forEach(row, function (value, key) {
                            if (!continueLoop || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                            let val = value.toLowerCase();
                            let fltr = data.filter.toLowerCase();

                            if (val.includes(fltr)) {
                                row.is_selected = data.hasUnchecked;
                                continueLoop = false;
                            }
                        });
                    } else {
                        row.is_selected = data.hasUnchecked;
                    }
                });
                //console.dir(vm.ttOptions)
                //console.dir(data);
                data.onChecklistChanged(data.list, data.list.filter((item) => item.is_selected === true).map((item) => item.item_id))
            };

            // #endregion CHECKLIST

            /**
             * Returns true if checklist requirements are met.
             * 
             * @returns true if checklist requirements are met.
             */
            function listRequirementsMet() {
                let isRequirementsMet = true;

                vm.ttOptions.data.forEach((element) => {
                    if (element.type === 'checklist' && element.required === true && element.data.list.length > 0) {
                        isRequirementsMet = vm.ttOptions.list.data.list.filter((item) => item.is_selected === true).length > 0;
                    }
                });

                return isRequirementsMet;
            }

            /**
             * Returns true if comment in text area is not empty.
             * 
             * @returns true if comment in text area is not empty.
             */
            function commentRequirementsMet() {
                let isRequirementsMet = true;

                vm.ttOptions.data.forEach((element) => {
                    if (element.type === 'textarea' && element.required === true && element.text.trim().length === 0) {
                        isRequirementsMet = false;
                    }
                })

                return isRequirementsMet;
            }

            /**
             * Checks if requirements are valid and disables/enables buttons accordingly.
             */
            function disableButtons() {
                vm.ttOptions.data?.forEach((element) => {
                    if (element.type !== 'button') return;

                    if (element.mustMeetRequirements !== false) {
                        element.disabled = listRequirementsMet() === false || commentRequirementsMet() === false;
                    } else {
                        element.disabled = false;
                    }
                });
            }


            // #region CONFIGURE MODAL

            /**
             * Positions the modal and arrow of the modal according to the position of the
             * given element.
             * 
             * @param {HTMLElement} element the element to position the modal relative to.
             */
            function positionModal(element) {
                if (!element) return;

                let position = element.getBoundingClientRect();

                let leftMargin = 0;
                let modalWidth = parseInt(vm.style.modal['width'].replace('px', ''));
                let defaultRightModalPosition = position.right - position.width / 2 + modalWidth / 2;
                let defaultLeftModalPosition = Number((position.left + position.width / 2) - (modalWidth / 2));

                if (defaultLeftModalPosition < 0) {
                    leftMargin = Math.abs(defaultLeftModalPosition) + 5;
                } else if (defaultRightModalPosition > window.innerWidth) {
                    leftMargin = window.innerWidth - defaultRightModalPosition - 15;
                }

                const goUnder = position.top < vm.style.modal['max-height'].replace('px', '');
                if (goUnder) {
                    vm.style.arrow = upArrow;
                    vm.style.modal['top'] = position.bottom + 20 + 'px';
                    vm.style.modal['transform'] = 'translate(-50%, 0)';
                    vm.style.arrow['top'] = position.bottom - 18 + 'px';
                } else {
                    vm.style.arrow = downArrow;
                    vm.style.modal['top'] = position.top - 20 + 'px';
                    vm.style.modal['transform'] = 'translate(-50%, -100%)';
                    vm.style.arrow['top'] = parseInt(vm.style.modal['top'] + vm.style.modal['height']) - 22 + 'px';
                }

                vm.style.modal['left'] = (position.x + position.width / 2) + leftMargin + 'px';
                vm.style.arrow['left'] = position.x + position.width / 2 - 40 + 'px';
            }

            /**
             * Configures the popover and the relative element.
             * 
             * @param {HTMLElement} element the relative element to configure the popover modal for.
             */
            function configurePopoverModal(element) {
                element.removeEventListener('click', toggleModal); // clear event listener incase it already exist.
                positionModal(element);
                element.addEventListener('click', toggleModal);
                onDestroy.push(() => element.removeEventListener('click', toggleModal));
            }


            /**
             * Finds the relative element and positions the modal relative to the
             * element found.
             */
            const findElementAndSetPosition = function () {
                let elementId = vm.ttOptions.relativeElementId;
                relativeElement = document.getElementById(`${elementId}`);

                if (!relativeElement) {
                    setTimeout(() => {
                        relativeElement = document.getElementById(`${elementId}`);
                        configurePopoverModal(relativeElement);
                    }, 100);
                } else {
                    configurePopoverModal(relativeElement);
                }
            }

            /**
             * MutationObserver used to position the modal if there are changes to the site.
             */
            const observer = new MutationObserver((mutationList, observer) => {
                findElementAndSetPosition();
                disableButtons();
            });

            let setClasses = function () {
                //vm.class.label = labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : vm.style.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1';
                //vm.class.input = labelAlwaysOnTop === true || vm.hideLabel || vm.style.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11';
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;
                //vm.style.btn.paddingTop = info.fontSizes.textSize;

                setClasses();
            });

            // #endregion CONFIGURE MODAL

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                $window.addEventListener('scroll', findElementAndSetPosition);
                findElementAndSetPosition();
            };

            vm.$onChanges = (changes) => {
                translateService.translate(vm.translations.listTitle).then((translation) => {
                    if (translation.trim().length === 0) {
                        vm.translations.listTitle = 'List';
                    } else {
                        vm.translations.listTitle = translation;
                    }
                });
            }

            vm.$onDestroy = () => {
                observer.disconnect();
                $window.removeEventListener('scroll', findElementAndSetPosition);
                ttDirectivesService.onDestroy(onDestroy);
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
