(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttTypeahead', {
        templateUrl: 'views/components/directives/ttTypeahead/ttTypeahead.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttReadonly: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttTextAlign: '@',
            ttPlaceholder: '@',
            ttItemId: '@',
            ttRequired: '@',
            ttStyle: '<',
            ttLimit: '<',
            ttData: '<',
            ttDataName: '@',
            ttSelect: '&',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttMinLength: '<',       // number, the min amount of characters required before results are displayed. - JLR 20231113
        },
        controller: ['$element', '$timeout', '$interval', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, $timeout, $interval, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.hideLabel = false;
            vm.readonly = false;
            vm.required = false;
            vm.minLength = 0;

            vm.modelOptions = {
                debounce: {
                    default: 250,
                    blur: 250
                }
            };

            vm.id = {
                input: uuid()
            };

            vm.style = {
                base: {},
                label: {},
                input: {},
            };

            vm.class = {
                base: '',
                label: '',
                input: ''
            };

            vm.translations = {
                ttLabel: '',
                ttPlaceholder: '',
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onSelect = function (item, model) {
                console.log('onSelect');
                console.dir(item);
                console.dir(model);

                if (angular.isFunction(vm.ttSelect) !== true) return;

                console.log('calling ttSelect');

                vm.ttSelect({ $item: item, $model: model });
            };

            var customStyles = {
                base: {},
                label: {
                    fontSize: false
                },
                input: {
                    textAlign: false,
                    fontSize: false,
                    height: false,
                    paddingTop: false,
                    paddingBottom: false,
                    paddingLeft: false,
                    paddingRight: false
                }
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
                    customStyles.input.textAlign = true;

                    vm.style.input.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttReadonly)) {
                    vm.readonly = us.toBoolean(changes.ttReadonly.currentValue);
                }

                if (angular.isDefined(changes.ttPlaceholder) && us.isStringValue(changes.ttPlaceholder.currentValue, true)) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttPlaceholder = changes.ttPlaceholder.currentValue;
                    } else {
                        translateService.translate(changes.ttPlaceholder.currentValue).then((translation) => vm.translations.ttPlaceholder = translation);
                    }
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttMinLength) && angular.isDefined(changes.ttMinLength.currentValue)) {
                    vm.minLength = changes.ttMinLength.currentValue;
                }

                if (angular.isDefined(changes.ttRequired) && angular.isDefined(changes.ttRequired.currentValue)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop);
            });

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
