(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pickJobGrps', {
        templateUrl: 'views/components/views/pickJobGrps/pickJobGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'pickJobGrpsService', function ($stateParams, $q, stateService, utilityService, pickJobGrpsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockPlaceId = $stateParams.stockplace_id;

            vm.model = {
                setting: {},
                itemsListPickJobGroups: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                pickJobGrpsService.getPickJobGroups({ stockplace_id: stockPlaceId }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadPickJobGroups()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadPickJobGroups = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                vm.model.itemsListPickJobGroups = [];

                return pickJobGrpsService.listPickJobGroups({ stockplace_id: vm.model.setting.stockplace_id }).then(function (result) {
                    angular.copy(result, vm.model.itemsListPickJobGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'pickjobgrp':
                        go({
                            stockplace_id: vm.model.setting.stockplace_id,
                            pickjobgrp_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
