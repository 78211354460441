<div class="ag-multi-floating-filter ag-floating-filter-input">
    <div class="ag-floating-filter-input" role="presentation" ref="eFloatingFilterInputContainer">
        <div role="presentation" class="ag-labeled ag-label-align-left ag-text-field ag-input-field">
            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-date-field-input-wrapper ag-picker-field-wrapper" role="presentation">
                <ng-container *ngIf="!!text">
                    <input class="ag-input-field-input ag-date-field-input" [disabled]="true" type="text" [ngModel]="text" tabindex="0" autocomplete="off" />
                </ng-container>
                <ng-container *ngIf="!text">
                    <input
                        #inputRef
                        matInput
                        [matDatepicker]="picker"
                        class="ag-input-field-input ag-date-field-input"
                        [placeholder]="placeholder"
                        type="text"
                        (focus)="onFocus()"
                        (blur)="onBlur()"
                        (keydown)="onKeydown($event)"
                        [(ngModel)]="value"
                        (ngModelChange)="onDateChanged($event)"
                        tabindex="0"
                        autocomplete="off"
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="picker" [style.fontSize]="'var(--ag-font-size)'" [style.height]="'100%'">
                        <span matDatepickerToggleIcon class="far fa-calendar" [style.fontSize]="'var(--ag-font-size)'"></span>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker [panelClass]="'ag-custom-component-popup'"> </mat-datepicker>
                </ng-container>
            </div>
        </div>
    </div>
</div>
