(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('coop', {
        templateUrl: 'views/components/views/coop/coop.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'coopService', 'translateService', function ($stateParams, stateService, modalService, coopService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var coopId = $stateParams.coop_id;

            var translations = {
                coop_save_show_title: 'Varsel',
                coop_save_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'cooplangs':
                        stateService.go(state, {
                            coop_id: vm.model.edit.coop_id
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                coopId: coopId,
                coopNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'cooplangs' } }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            coopService.getCoop({ coop_id: coopId }).then(function (result) {
            	angular.copy(result[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                coopService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.coop_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.coop_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    translateService.translateBatch(translations).then(function (data) {
			    angular.forEach(translations, function (_, key) {
				    if (angular.isDefined(data[key])) {
					    translations[key] = data[key];
				    }
			    });
		    });

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();