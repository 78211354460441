(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bankReconciliationsBt', {
        templateUrl: 'views/components/views/bankReconciliationsBt/bankReconciliationsBt.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$uibModal', 'stateService', 'utilityService', 'translateService', 'modalService', 'bankReconciliationsBtService', 'rememberService', 'ttGridFactory', function ($stateParams, $q, $uibModal, stateService, utilityService, translateService, modalService, bankReconciliationsBtService, rememberService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoBankAccountKeyno = $stateParams.businessco_bankaccount_keyno;
            let bankReconciliationMatchNo = '';

            let translations = {
                error: '',
                ok: ''
            };

            let variableNames = {
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            vm.model = {
                setting: {},
                gridReady: false,
                gridMatchReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_bankreconciliationsbt_statement_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            })
                .setToolbar({ wrapping: true })
                .setCustomToolbarButtons([{
                    name: 'bankreconciliationsbt_unmatch',
                    text: 'bankreconciliationsbt_unmatch',
                    func: function () {
                        let currentData = vm.grid.gridfunc.getDirtyRows();
                        let selectedItems = [];

                        angular.forEach(currentData, function (item) {
                            if (item.is_selected === '0' || item.is_selected === false) return;
                            if (item.bankreconciliation_match_no === '0' || item.bankreconciliation_match_no === null) return;

                            selectedItems.push({ bankreconciliation_match_no: item.bankreconciliation_match_no });
                        });

                        bankReconciliationsBtService.unmatchGrid({ records: selectedItems }).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                loadGrid();
                            }
                        });
                    },
                    icon: 'fa-trash',
                    cssClass: 'btn btn-danger im-grid-btn-xs-r',
                    translate: true
                }])
                .setOptionFunc(optionFuncMatch);

            function optionFuncMatch(data) {
                if (data?.data?.func === 'CellClickHandler') {
                    bankReconciliationMatchNo = data.data.clickedCell.dataItem.bankreconciliation_match_no;
                    
                    loadGridMatch();
                }
            }

            vm.gridMatch = new ttGridFactory({
                rememberId: 'bankreconciliation_match_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

            	bankReconciliationsBtService.getSetting({ businessco_bankaccount_keyno: businessCoBankAccountKeyno }).then(function (info) {
            		angular.copy(info[0], vm.model.setting);

            		$q.all([
            			loadGrid()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3395,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadGridMatch = function () {
                vm.gridMatch.dataTask.loadData = {
                    method: 3397,
                    parameters: { bankreconciliation_match_no: bankReconciliationMatchNo }
                };

                vm.model.gridMatchReady = true;

                if (vm.gridMatch.gridfunc !== null) vm.gridMatch.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'getHistory':
                        openImportModal();
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            let openImportModal = function () {
                const modalRef = $uibModal.open({
                    component: 'bankReconciliationsBtGetHistoryModal',
                    resolve: {},
                    size: 'pst-ninety',
                });

                modalRef.closed.then(function () {
                    loadGrid();
                });
            };

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;

                    loadGrid();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadSetting();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
