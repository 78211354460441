(function () {
    'use strict';

    var module = angular.module('imApp');

    module.directive('imTranslate', ['translateService', function (translateService) {
        return {
            restrict: 'A',
            transclude: true,
            scope: {
                imTranslate: '@',
                imWatch: '='
            },
            template: '{{model.translation}}<ng-transclude></ng-transclude>',
            link: function ($scope, $element, $attr) {
                var imWatch = $scope.imWatch || false;

                $scope.model = {
                    translation: ''
                };

                var register = function () {
                    var dereg = translateService.on($scope.imTranslate, 'changed', function (translation) {
                        // BJS 20180528 - Endret for å støtte "blank" oversettelse i input directiv.
                        $scope.model.translation = translation === '&nbsp;' ? '' : translation;

                        if (imWatch === true) {
                            $scope.$watch(function () {
                                return $scope.imTranslate;
                            }, function () {
                                dereg();

                                dereg = translateService.on($scope.imTranslate, 'changed', function (translation) {
                                    // BJS 20180528 - Endret for å støtte "blank" oversettelse i input directiv.
                                    $scope.model.translation = translation === '&nbsp;' ? '' : translation;
                                });
                            });
                        }
                    });

                    $scope.$on('$destroy', function () {
                        if (angular.isFunction(dereg)) {
                            dereg();
                        }
                    });
                };

                if ($scope.imTranslate.length < 1) {
                    var dereg2 = $scope.$watch(function () {
                        return $scope.imTranslate;
                    }, function (newValue) {
                        if (newValue.length < 1) return;

                        register();

                        dereg2();
                    });
                } else {
                    register();
                }
            }
        };
    }]);
})();