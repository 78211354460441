(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2SubscriptionEdit', {
        templateUrl: 'views/components/views/p2SubscriptionEdit/p2SubscriptionEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2SubscriptionEditService', 'stateService', function ($stateParams, p2SubscriptionEditService, stateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;
            var isSaving = false;
            
            vm.lockingP2SubscriptionEditSaveButton = {
                locked: '0'
            };
            
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
            
            p2SubscriptionEditService.loadP2SubscriptionEdit($stateParams.p2_subscription_keyno || '0').then(function () {
                vm.p2_subscription_edit = p2SubscriptionEditService.p2_subscription_edit;
            });
            
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
            
            vm.saveP2SubscriptionEdit = function () {
                if (isSaving == true || vm.lockingP2SubscriptionEditSaveButton.locked == '1') return;

                isSaving = true;
                vm.lockingP2SubscriptionEditSaveButton.locked = '1';

                p2SubscriptionEditService.saveP2SubscriptionEdit().then(function () {
                    stateService.back();
                });

                isSaving = false;
            };
            
            // #########################
            // DIRECTION BUTTON FUNCTION
            // #########################
            
            vm.goToP2WebpageSubscription = function (index) {
                stateService.go('webpagesubscription', {
                    argtype: 'p2_subscription_keyno',
                    argvalue: vm.p2_subscription_edit.p2_subscription_keyno
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'translateedit_heading':
                        stateService.go('translateedit', { item_id: vm.p2_subscription_edit.word_id_heading });
                        break;
                    case 'translateedit_description':
                        stateService.go('translateedit', { item_id: vm.p2_subscription_edit.word_id_description });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();