import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';

(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('emailView', {
        templateUrl: 'views/components/views/emailView/emailView.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'layoutService', 'stateService', 'base64', 'modalService', 'translateService', 'emailViewService', 'graphAuthService', 'utilityService', function ($stateParams, layoutService, stateService, base64, modalService, translateService, emailViewService, graphAuthService, utilityService) {
            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                emailKeyNo: $stateParams.email_keyno,
                viewEmail: {},
                itemsListAttachments: [],
                replies: [],
                lockedDelete: false
            };

            vm.style = {
                subject: {},
                emailBody: {
                    message: {
                        backgroundColor: 'white'
                    }
                },
                senderInfo: {},
            }

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            async function loadEmail() {
                vm.model.viewEmail = (await emailViewService.getEmailView(vm.model.emailKeyNo))[0];
                //vm.model.viewEmail.body = base64.urldecode(vm.model.viewEmail.body);
                //const iframe = document.getElementById('w-email__iframe');
                //const iframeDoc = iframe.contentDocument;
                //iframeDoc.open();
                //iframeDoc.write(vm.model.viewEmail.body);
                //iframeDoc.close();

                //setTimeout(() => iframe.style.height = iframeDoc.documentElement.querySelector('body').scrollHeight + 'px', 50);
                //return vm.model.viewEmail;
            }

            async function loadEmailAttachments() {
                if (!vm.model.viewEmail?.email_keyno) return vm.model.itemsListAttachments = [];
                return vm.model.itemsListAttachments = await emailViewService.listEmailViewAttachments(vm.model.viewEmail.email_keyno);
            }

            async function loadReplyToList() {
                if (!vm.model.viewEmail?.email_keyno) return vm.model.replies = [];
                return vm.model.replies = (await emailViewService.getReplyToList(vm.model.viewEmail.email_keyno));
            }

            vm.gotoEmailCreate = function () {
                stateService.go('emailcreate', {
                    webpage: 'emailview',
                    argtype: 'email_keyno',
                    argvalue: vm.model.viewEmail.email_keyno
                });
            }

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                emailViewService.deleteItem(vm.model.viewEmail).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedDelete = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedDelete = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            let onDestroy = [];

            vm.$onInit = async function () {
                layoutService.onLayoutChanged(onDestroy, (info) => {
                    vm.style.subject.fontSize = info.fontSizes.textSizeL;
                    vm.style.senderInfo.fontSize = info.fontSizes.textSize;
                });
                await loadEmail();
                loadEmailAttachments();
                loadReplyToList();
            }

            vm.onDestroy = function () {
                onDestroy.forEach((destruction) => destruction());
            }
        }]
    });
})();
