(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('invoicedStatus', {
        templateUrl: 'views/components/views/invoicedStatus/invoicedStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['invoicedStatusService', function (invoicedStatusService) {
            var vm = this;

        }]
    });
})();
