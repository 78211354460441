(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectProdChoiceService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_prodchoice_select_list: {
                item_id: '',
                item_name: '',
                orderby: '',
                itam_state: '',
                item_parms: '',
                records: []
            },
            searchP2ProdChoiceSelectList: function (prodchoice_search, choice_keyno, argtype, argvalue) {
                var deferred = $q.defer();

                var parms_p2_prodchoice_select_list = {
                    method: 132,
                    parameters: {
                        prodchoice_search: prodchoice_search,
                        choice_keyno: choice_keyno,
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parms_p2_prodchoice_select_list).then(function (data) {
                    angular.copy(data, service.p2_prodchoice_select_list.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();