(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('dndEdit', {
        templateUrl: 'views/components/views/dndEdit/dndEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: [function() {  
            var vm = this;
        }]
    });
})();