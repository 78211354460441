(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("travelBillMenuService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadTravelBillMenuGet: function (travelbill_no) {
                return p2DataTaskService.call(1254, {
                    travelbill_no: travelbill_no
                });
            },
            loadTravelBillMenuItemsList: function (travelbill_no) {
                return p2DataTaskService.call(1257, {
                    travelbill_no: travelbill_no
                });
            },
            loadCounterNextNumberGet: function (counter_id) {
                return p2DataTaskService.call(678, {
                    counter_id: counter_id
                });
            },
            sendIn: function (edit) {
                return $ihttp.post({
                    method: 1324,
                    parameters: edit
                });
            },
            approve: function (edit) {
                return $ihttp.post({
                    method: 1325,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
