(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("invoiceMakeMultiOrdersService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadInvoiceMakeMultiOrdersGet: function (cust_no, invoicemethod_no, businessco_no) {
                return p2DataTaskService.call(1759, {
                    cust_no: cust_no,
                    invoicemethod_no: invoicemethod_no,
                    businessco_no: businessco_no
                });
            },
            loadInvoiceMakeMultiOrdersList: function (settingMultiOrder) {
                return p2DataTaskService.call(1760, settingMultiOrder);
            }
        };

        return service;
    }]);
})();