(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockTransferCreateService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadStockTransferCreateTrolleySelectList: function (stockplace_id) {
                return p2DataTaskService.call(1058, {
					stockplace_id: stockplace_id
				});
            },
            saveToPreStockTransferCreate: function (stocklocation_keyno_trolley) {
                return p2DataTaskService.call(1059, {
                    stocklocation_keyno_trolley: stocklocation_keyno_trolley
                });
            }
        };

        return service;
    }]);
})();