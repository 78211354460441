(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('scanStockLocation', {
        templateUrl: 'views/components/views/scanStockLocation/scanStockLocation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$element', '$stateParams', '$timeout', 'stateService', 'modalService', 'scanStockLocationService', function ($element, $stateParams, $timeout, stateService, modalService, scanStockLocationService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;

            vm.model = {
                mId: 'scanStockLocationCtrl',
                scanStockLocation: {},
                scanStockLocationScan: {},
                searchingLocation: false
            };
            
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
            var scanStockLocationGet = function () {
                scanStockLocationService.genericFunction(1049, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue,
                    functionname: $stateParams.functionname,
                    stock_keyno: $stateParams.stock_keyno,
                    autoscan: $stateParams.autoscan
                }).then(function (data) {
                    angular.copy(data[0], vm.model.scanStockLocation);
                    
                    if (vm.model.scanStockLocation.autoscan === '1') {
                        var wait = function () {
                            var val = $element.find('#' + vm.model.mId)[0].value;
                            
                            if (angular.isString(val) && val !== null && val.length > 0) {
                                vm.model.scanStockLocation.barcode = val;
                                vm.scanBarcode();
                                return;
                            }
                            
                            $timeout(wait, 250);
                        };
                        
                        wait();
                        
                        window.location = "readbarcode://" + vm.model.mId;
                    }
                });
            };
            
            scanStockLocationGet();
            
            //#######################
            //## SCAN BUTTON FUNCTION
            //#######################
            
            vm.scanBarcode = function () {
                if (angular.isDefined(vm.model.scanStockLocation) && angular.isDefined(vm.model.scanStockLocation.barcode) && vm.model.scanStockLocation.barcode !== '') {
                    vm.model.searchingLocation = true;

                    scanStockLocationService.genericFunction(1050, vm.model.scanStockLocation).then(function (data) {
                        vm.model.scanStockLocationScan = angular.copy(data[0]);
                        vm.model.scanStockLocation.barcode = '';
                        
                        if (vm.model.scanStockLocationScan.errorcode === '-1') {
                            modalService.show({
                                type: 'warning',
                                title: 'Feilmelding',
                                message: vm.model.scanStockLocationScan.errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.searchingLocation = false;
                                    }
                                }]
                            });
                        } else if (angular.isDefined(vm.model.scanStockLocationScan) && vm.model.scanStockLocationScan.errorcode === '0') {
                            vm.model.searchingLocation = false;
                            stateService.back();
                        }
                    });
                }
            };
        }]
    });
})();