<div class="col-xs-12 sp-0 height-full flex-column">
    <!-- <div class="row-fluid"> -->
    <!-- <form class="form-horizontal im-no-pad" name="formP2ReportDefs" novalidate method="get" role="form"> -->
    <div class="loadingContainer" *ngIf="!this.model.isReady">
        <tt-grid-loading-overlay></tt-grid-loading-overlay>
    </div>

    <div class="htmlReportPreviewWrapper" *ngIf="this.model.isReady">
        <ng-container *ngIf="this.model.documentOverviewMap !== null">
            <div id="htmlReportPreview" style="transform: scale({{ this.model.scale }}); transform-origin: top center;">
                <!--THIS IS A SECTION THAT CONTAINS THE VISIBLE FORM AND THE INVISIBLE ELEMENTS ON THE RIGHT-->
                <div class="htmlproc_section" style="display:flex;"
                     cdkDropListGroup *ngFor="let htmlproc of this.model.documentOverviewMap.keys() ?? []; let i = index"
                     [ngStyle]="{ 'display': isFocused(htmlproc.htmlproc_keyno) ? 'flex' : 'none' }">

                    <ng-container *ngIf="htmlproc.presentation_type === 'form'">
                        <!--THIS IS A SINGLE FORM-->
                        <div style="padding: 1.27cm; width: 100%; display: grid;"
                             [ngStyle]="{
                                                'grid-template-columns': 'repeat(' + htmlproc.col_count + ', 1fr)',
                                                'grid-template-rows': 'repeat(' + htmlproc.row_count + ', 1fr)',
                                                'align-self': htmlproc.is_header === '1' ? 'center' : 'flex-start',
                                                'box-shadow': htmlproc.is_header === '1' ? '0 5px 5px -5px rgba(0, 0, 0, 0.1),5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)' : '5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)'
                                                

                                                    }"
                             class="htmlForm">
                            <!--THIS IS A SINGLE PLACE HOLDER CELL, THIS IS MADE THE SAME AMOUNT OF TIMES AS THERE ARE CELLS IN THE GRID-->

                            <div *ngFor="let placeholderIndex of [].constructor(+ htmlproc.col_count * + htmlproc.row_count); let j = index"
                                 [attr.data-index]="j + 1"
                                 [class.list-with-border]="model.isDragging && model.currentHtmlProc === htmlproc"
                                 cdkDropList
                                 [cdkDropListData]="getPlaceholderData(htmlproc, j +1) || []"
                                 (cdkDropListDropped)="drop($event, i, htmlproc)"
                                 [ngStyle]="
                                                        getCellStyle(htmlproc, j + 1)
                                                    ">



                                &nbsp;
                                <ng-container *ngFor="let item of getPlaceholderData(htmlproc,j +1);">
                                    <label class="htmlInvisibleLabel"
                                           cdkDrag
                                           (cdkDragStarted)="dragStarted(htmlproc)"
                                           (cdkDragEnded)="dragEnded()"
                                           [cdkDragData]="item"
                                           (click)="updateEditItemContent(item.colname_lang,item)"
                                           [ngStyle]="{
                                                                    fontWeight: item.bold === '1' ? 'bold' : 'normal',
                                                                    fontStyle: item.italic === '1' ? 'italic' : 'normal',
                                                                    textAlign: item.textalign,
                                                                    fontSize: item.fontsize,
                                                                    color: item.color || 'black',
                                                                    backgroundColor: item.bg_color || 'transparent',
                                                                    fontFamily: getFontStyle(item)
                                                                  }">



                                        <!-- Conditionally show the image if has_image is 1 -->
                                        <ng-container *ngIf="item.is_image === '1'; else textContentShowForm">
                                            <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                <img [src]="item.stringvalue" alt="Image" style="max-width: 100%; max-height: 100%;">
                                            </ng-container>
                                        </ng-container>

                                        <!-- Else show the text content -->
                                        <ng-template #textContentShowForm>
                                            <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                <span [innerHTML]="sanitizeHTML(item.stringvalue)"></span>
                                            </ng-container>
                                        </ng-template>
                                    </label>

                                </ng-container>
                                <tt-context-menu-report *ngIf="this.model.contextMenuVisible"
                                                        [item]="this.model.selectedItem"
                                                        [position]="this.model.contextMenuPosition"
                                                        (close)="this.model.contextMenuVisible = false"
                                                        (update)="updateItem($event)">
                                </tt-context-menu-report>
                            </div>
                        </div>

                    </ng-container>
                    <ng-container *ngIf="htmlproc.presentation_type === 'table'">
                        <div class="tablePreviewContainer" style="display:flex; flex-direction: column; width:100%; background-color: white; max-width: 21cm;">
                            <!-- THIS IS A SINGLE TABLE-->
                            <ng-container *ngIf="htmlproc.has_header === '1'">

                                <div class="tableContainer"
                                     [class.list-with-border]="model.isDragging && model.currentHtmlProc === htmlproc"
                                     cdkDropList
                                     cdkDropListOrientation="horizontal"
                                     [cdkDropListData]="getPlaceholderData(htmlproc, 1) || []"
                                     (cdkDropListDropped)="drop($event, i, htmlproc)"
                                     [ngStyle]="{'align-self': htmlproc.is_header === '1' ? 'flex-end' : 'flex-start',
                                        'box-shadow': htmlproc.is_header === '1' ? '0 5px 5px -5px rgba(0, 0, 0, 0.1),5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)' : '5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)',
                                            'padding-bottom': htmlproc.has_padding_bottom === '1' ? '1rem' : '0'
                                            }">

                                    <ng-container *ngIf="!getPlaceholderData(htmlproc, 0)">
                                        &nbsp;
                                    </ng-container>
                                    <ng-container *ngFor="let item of getPlaceholderData(htmlproc,1);">
                                        <ng-container *ngIf="item.visible === '1'">
                                            <ng-container *ngIf="item.colname_lang && item.colname_lang.trim() !== ''">
                                                <label cdkDrag
                                                       (cdkDragStarted)="dragStarted(htmlproc)"
                                                       (cdkDragEnded)="dragEnded()"
                                                       [cdkDragData]="item"
                                                       (contextmenu)="showContextMenu($event, item)"
                                                       [ngStyle]="{
                                                                    padding: '0.5rem',
                                                                    fontStyle: item.italic === '1' ? 'italic' : 'normal',
                                                                    textAlign: item.textalign,
                                                                    fontSize: item.fontsize,
                                                                    color: item.color || 'black',
                                                                    flexGrow: (+item.width || 1),
                                                                    backgroundColor: item.bg_color || 'transparent',
                                                                    fontFamily: getFontStyle(item),
                                                                    minWidth: item.is_image === '1' ? (+item.width * 114) + 'px' : '0',
                                                                    'border': htmlproc.has_border === '1' ? '1px solid black' : 'none',
                                                                  }">

                                                    <span [innerHTML]="sanitizeHTML(item.colname_lang)"></span>
                                                </label>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>

                                </div>
                            </ng-container>

                            <ng-container *ngIf="this.model.tablePreviewMap && this.model.tablePreviewMap.get(htmlproc)">
                                <div class="tablePreview" id="tablePreview" [innerHTML]="sanitizeHTML(this.model.tablePreviewMap!.get(htmlproc)!)">
                                </div>
                            </ng-container>
                        </div>

                    </ng-container>
                    <!--SIDE SECTION WHERE ALL THE UNUSED VALUES ARE STORED-->

                    <div class="rightSidePreview">
                        <!-- <label>Rediger Element</label>

                        <div class="sectionSettings">
                            <div class="currentEdit" [innerHTML]="this.model.sanitizedStringValue">
                            </div>
                            <ng-container *ngIf="this.model.currentItemEdit">

                                <div class="editSection">

                                <div class="font-size-control">
                                    <button (click)="decreaseFontSize()" class="icon-button">-</button>
                                    <input type="text" [value]="getFontSize()" (input)="onFontSizeInput($event)" />
                                    <button (click)="increaseFontSize()" class="icon-button">+</button>
                                </div>
                                <div class="editSubSection">

                                <button [class.active]="this.model.currentItemEdit.bold === '1'"
                                        class="icon-button"
                                        (click)="toggleBold()">
                                    B
                                </button>


                                <button [class.active]="this.model.currentItemEdit.italic === '1'"
                                        class="icon-button"
                                        (click)="toggleItalic()">
                                    I
                                </button>
                                </div>
                                        <div class="editSubSection">

                                        <button [class.active]="this.model.currentItemEdit.textalign === 'left'">
                                            <i class="far fa-align-left"></i>
                                        </button>
                                        <button [class.active]="this.model.currentItemEdit.textalign === 'center'" onclick="">
                                            <i class="far fa-align-justify"></i>
                                        </button>
                                        <button [class.active]="this.model.currentItemEdit.textalign === 'right'">
                                            <i class="far fa-align-right"></i>
                                        </button>

                                    </div>
                                </div>
                                <div class="editSection">

                                <label class="colorpicker">
                                    A
                                    <input type="color" [(ngModel)]="this.model.currentItemEdit.color" [ngStyle]=" {'background-color' : this.model.currentItemEdit.color || 'transparent'} " />
                                </label>

                                <label class="colorpicker">
                                    B
                                    <input type="color" [(ngModel)]="this.model.currentItemEdit.bg_color" [ngStyle]=" {'background-color' : this.model.currentItemEdit.bg_color || 'transparent'} " />
                                </label>


                                <label>
                                    <select [(ngModel)]="this.model.currentItemEdit.font">
                                        <option value="" disabled>Select a font</option>
                                        <option value="Arial">Arial</option>
                                        <option value="Courier New">Courier New</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Verdana">Verdana</option>
                                        <option value="Tahoma">Tahoma</option>
                                        <option value="Trebuchet MS">Trebuchet MS</option>
                                        <option value="Impact">Impact</option>
                                        <option value="Comic Sans MS">Comic Sans MS</option>
                                        <option value="Libre Barcode 128">Barcode</option>
                                    </select>
                                </label>
                                </div>
                            </ng-container>
                        </div> -->
                        <!--  <label>Proc Instillinger</label>

                         <div class="sectionSettings">
                             <tt-input ttLabel="htmlproc_keyno"
                                       [(ttModel)]="htmlproc.htmlproc_keyno"
                                       name="htmlproc_keyno"
                                       ttReadonly>
                             </tt-input>
                             <tt-input ttLabel="htmlproc_name"
                                       [(ttModel)]="htmlproc.proc_name"
                                       name="proc_name"
                                       ttReadonly>
                             </tt-input>
                             <tt-input ttLabel="presentation_type"
                                       [(ttModel)]="htmlproc.presentation_type"
                                       name="presentation_type"
                                       ttReadonly>
                             </tt-input>
                             <tt-input *ngIf="htmlproc.presentation_type === 'table'"
                                       ttLabel="Columns"
                                       [ttModel]="getVisibleItemCount(htmlproc)"
                                       name="columns"
                                       (change)="updateFormGrid(htmlproc)">
                             </tt-input>
                             <tt-input *ngIf="htmlproc.presentation_type === 'form'"
                                       ttLabel="Columns"
                                       [(ttModel)]="htmlproc.col_count"
                                       name="columns"
                                       (change)="updateFormGrid(htmlproc)">
                             </tt-input>
                             <tt-input *ngIf="htmlproc.presentation_type === 'form'"
                                       ttLabel="Rows"
                                       [(ttModel)]="htmlproc.row_count"
                                       name="rows"
                                       (change)="updateFormGrid(htmlproc)">
                             </tt-input>
                             <tt-checkbox *ngIf="htmlproc.presentation_type === 'table'" style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_header" ttLabel="has_header">
                             </tt-checkbox>
                             <tt-checkbox *ngIf="htmlproc.presentation_type === 'table'" style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_alternating_color" ttLabel="has_alternating_color">
                             </tt-checkbox>
                             <tt-checkbox style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_border" ttLabel="has_border">
                             </tt-checkbox>
                             <tt-checkbox style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_padding_bottom" ttLabel="has_padding_bottom">
                             </tt-checkbox>

                             <tt-button-save ttText="Save" (click)="saveReport(htmlproc)"></tt-button-save>
                         </div> -->
                        <label>Ubrukte Elementer</label>
                        <div class="htmlReportInvisible">
                            <div class="htmlSection" cdkDropList
                                 [class.list-with-border]="model.isDragging && model.currentHtmlProc === htmlproc"
                                 [cdkDropListData]="getPlaceholderData(htmlproc,0)  || []"
                                 (cdkDropListDropped)="drop($event, i, htmlproc)"
                                 style="min-height:20px">

                                <ng-container *ngFor="let item of getPlaceholderData(htmlproc,0);">
                                    <ng-container *ngIf="item.visible === '0'">
                                        <label class="htmlInvisibleLabel"
                                               (cdkDragStarted)="dragStarted(htmlproc)"
                                               (cdkDragEnded)="dragEnded()"
                                               cdkDrag
                                               [cdkDragData]="item"
                                               [ngStyle]="{
                                                                'font-weight': item.bold === '1' ? 'bold' : 'normal',
                                                                'font-style': item.italic === '1' ? 'italic' : 'normal',
                                                                'text-align': item.textalign,
                                                                'font-size': item.fontsize,
                                                                'color': item.color || 'black',
                                                                'background-color': item.bg_color || 'transparent',
                                                                'font-family': item.font
                                                              }">
                                            <!-- Conditionally show the image if has_image is 1 -->
                                            <ng-container *ngIf="item.is_image === '1'; else textContent">
                                                <ng-container *ngIf="htmlproc.presentation_type === 'form'">
                                                    <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                        <img [src]="item.stringvalue" alt="Image" style="max-width: 100%; max-height: 100%;">
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="htmlproc.presentation_type === 'table'">
                                                    <span [innerHTML]="sanitizeHTML(item.colname_lang)"></span>

                                                </ng-container>
                                            </ng-container>

                                            <!-- Else show the text content -->
                                            <ng-template #textContent>
                                                <ng-container *ngIf="htmlproc.presentation_type === 'table'">
                                                    <span [innerHTML]="sanitizeHTML(item.colname_lang)"></span>

                                                </ng-container>
                                                <ng-container *ngIf="htmlproc.presentation_type === 'form'">
                                                    <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                        <span [innerHTML]="sanitizeHTML(item.stringvalue)"></span>
                                                    </ng-container>

                                                </ng-container>

                                            </ng-template>
                                        </label>
                                    </ng-container>


                                </ng-container>

                            </div>

                        </div>

                    </div>




                </div>


            </div>
        </ng-container>
    </div>
    <!-- </form> -->
    <!-- </div> -->
</div>

