(function() {
    'use strict';

    angular.module("imApp").factory("deliveryOrderStatusService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadData: function (parms) {
				return p2DataTaskService.call(2030, parms);
			},
			createInvoices: function (parms) {
				return p2DataTaskService.call(2083, parms);
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			loadPageSettings: function () {
				return p2DataTaskService.call(2029, {});
			},
			savePageSetting: function (parms) {
				return p2DataTaskService.call(616, parms);
			}
		};

        return service;
    }]);
})();
