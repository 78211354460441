(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishProdCompListAccs', {
        templateUrl: 'views/components/views/poFinishProdCompListAccs/poFinishProdCompListAccs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'poFinishProdCompListAccsService', 'poFinishProdListService', function ($stateParams, stateService, poFinishProdCompListAccsService, poFinishProdListService) {

            //############
            //## VARIABLES
            //############

            var vm = this;
            vm.pfpclaService = poFinishProdCompListAccsService;
            
            vm.p2_pofinishprodcomp_acc_selection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: '0',
                records: [
                    { item_id: '0', item_name: 'componentpick_all', item_func: 'A' },
                    { item_id: '1', item_name: 'componentpick_picked', item_func: '1' },
                    { item_id: '2', item_name: 'componentpick_not_picked', item_func: '0' }
                ]
            };
            
            //##################
            //## PROCEDURE CALLS
            //##################
            
            vm.retrieve = function () {
                poFinishProdCompListAccsService.loadP2PoFinishProdCompAccsList($stateParams.p2_pofinish_keyno, vm.p2_pofinishprodcomp_acc_selection.item_selected).then(function () {
                    vm.p2_pofinishprodcomp_acc_list = poFinishProdCompListAccsService.p2_pofinishprodcomp_acc_list;
                });
            };
            
            poFinishProdCompListAccsService.loadP2PoFinishStatus($stateParams.p2_pofinish_keyno).then(function () {
                vm.p2_pofinish_status = poFinishProdCompListAccsService.p2_pofinish_status;
            });
            
            vm.selectP2PoFinishProdCompAcc = function (l_s_p2_pofinishprodcomp_acc_status) {
                vm.p2_pofinishprodcomp_acc_selection.item_selected = l_s_p2_pofinishprodcomp_acc_status;
                vm.retrieve();
            };
            
            //###############################
            //## PRODUCTION ORDER SAVE BUTTON
            //###############################
            
            vm.finishP2PoFinish = function () {
                poFinishProdCompListAccsService.finishP2PoFinish().then(function () {
                    //stateService.go(vm.pfpclaService.p2_pofinish_finish.webpagename);
                    stateService.go(vm.pfpclaService.p2_pofinish_finish.webpagename, vm.pfpclaService.p2_pofinish_finish.webpage_parms);
                });
            };
            
            // #region ABORT BUTTON FUNCTION
            
            vm.abortTask = function () {
                poFinishProdListService.abortPoFinish({ p2_pofinish_keyno: $stateParams.p2_pofinish_keyno }).then(function (data) {
                    stateService.back('/' + data[0].webpagename + '/' + data[0].webpage_parms);
                });
            };
            
    		// #endregion ABORT BUTTON FUNCTION
        }]
    });
})();