(function() {
    'use strict';

    angular.module("imApp").factory("ttDatasourceManager", ['$q', '$ihttp', '$timeout', 'ttDatasourceFactory', function ($q, $ihttp, $timeout, ttDatasourceFactory) {
        let cache = {};

        let service = {
            // rootId is the id of the dynamic view the datasource belongs to.
            add: function (rootId, datasource) {
                if (angular.isUndefined(rootId)) throw 'ttDatasourceManager.init: rootId is undefined';
                if (angular.isString(rootId) !== true) throw 'ttDatasourceManager.init: rootId must be a string';
                if (angular.isUndefined(datasource)) throw 'ttDatasourceManager.init: datasource is undefined';
                if (angular.isObject(datasource) !== true) throw 'ttDatasourceManager.init: datasource must be an object';

                if (angular.isDefined(cache[rootId]) && angular.isDefined(cache[rootId].datasources[datasource.datasource_id])) return $q.resolve();

                var deferred = $q.defer();

                if (angular.isUndefined(cache[rootId])) {
                    cache[rootId] = {
                        onDestroy: [],
                        datasources: {}
                    };
                }

                cache[rootId].datasources[datasource.datasource_id] = null;

                datasource._rootId = rootId;

                ttDatasourceFactory.$create(datasource).then(function (ds) {
                    if (angular.isUndefined(cache[ds._rootId])) {
                        deferred.resolve();
                        return;
                    }

                    if (angular.isUndefined(cache[ds._rootId].datasources[ds.datasource_id])) {
                        deferred.resolve();
                        return;
                    }

                    cache[ds._rootId].datasources[ds.datasource_id] = ds;

                    deferred.resolve(function () {
                        if (angular.isUndefined(cache[ds._rootId])) return;

                        angular.forEach(cache[ds._rootId].onDestroy, function (d) {
                            if (angular.isFunction(d)) {
                                d();
                            }
                        });

                        delete cache[ds._rootId];
                    });
                });

                return deferred.promise;
            },

            postInit: function (rootId) {
                angular.forEach(cache[rootId].datasources, function (ds) {
                    ds.postInit();
                });
            },

            subscribe: function (rootId, datasourceId, listener, event) {
                if (angular.isString(rootId) !== true) return;
                if (angular.isString(datasourceId) !== true) return;
                if (angular.isFunction(listener) !== true) return;
                if (angular.isUndefined(cache[rootId])) return;
                if (angular.isUndefined(cache[rootId].datasources[datasourceId])) return;

                event = event || 'onChange';

                switch (event) {
                    case 'onChange':
                        cache[rootId].onDestroy.push(cache[rootId].datasources[datasourceId].onChanged(listener));
                        break;
                    case 'onRead':
                        cache[rootId].onDestroy.push(cache[rootId].datasources[datasourceId].onRead(listener));
                        break;
                }
            },

            subscribeByKeyno: function (rootId, datasourceKeyno, listener, event) {
                if (angular.isString(rootId) !== true) return;

                if (angular.isUndefined(datasourceKeyno)) return;

                if (angular.isUndefined(cache[rootId])) return;

                var ds = service.getDatasourceByKeyno(rootId, datasourceKeyno);

                if (ds === null) return;

                service.subscribe(rootId, ds.datasource_id, listener, event);

                return ds;
            },

            updateDatasource: function (rootId, datasourceId, propertyId, value, index) {
                if (angular.isString(rootId) !== true) return;
                if (angular.isString(datasourceId) !== true) return;
                if (angular.isUndefined(cache[rootId])) return;
                if (angular.isUndefined(cache[rootId].datasources[datasourceId])) return;

                cache[rootId].datasources[datasourceId].changed(propertyId, value, index);
            },

            getDatasourceByKeyno: function (rootId, keyno) {
                keyno = angular.isString(keyno)
                    ? parseInt(keyno)
                    : keyno;

                let datasource = null;

                angular.forEach(cache[rootId].datasources, function (ds) {
                    if (ds !== null && ds.keyno === keyno) {
                        datasource = ds;
                    }
                });

                return datasource;
            },

            getDatasourceById: function (rootId, datasourceId) {
                if (angular.isUndefined(cache[rootId])) return null;
                if (angular.isUndefined(cache[rootId].datasources[datasourceId])) return null;

                return cache[rootId].datasources[datasourceId];
            },

            getItemsByKeyno: function (rootId, keyno) {
                var ds = service.getDatasourceByKeyno(rootId, keyno);

                return ds !== null
                    ? ds.items
                    : null;
            },

            getSchema: function (rootId, keyno) {
                var ds = service.getDatasourceByKeyno(rootId, keyno);

                return ds !== null
                    ? ds.schema
                    : null;
            },

            getDatasources: function (rootId) {
                if (angular.isUndefined(cache[rootId])) return {};

                return cache[rootId].datasources;
            }
        }

        return service;
    }]);
})();
