(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockVariants', {
        templateUrl: 'views/components/views/stockVariants/stockVariants.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'stockVariantsService', function ($stateParams, $q, utilityService, stockVariantsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let functionName = $stateParams.functionname;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                menu: {},
                itemsListStockVariantsOfProdIds: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadMenu = function () {
                const deferred = $q.defer();

                stockVariantsService.getMenu({ prod_id: prodId }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);

                    loadStockVariantsOfProdIds();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadStockVariantsOfProdIds = function () {
                if (utilityService.validateParmsValue(vm.model.menu.prod_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.menu.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.menu.include_not_in_stock) !== true) return;

                vm.model.itemsListStockVariantsOfProdIds = [];

                return stockVariantsService.listStockVariantsOfProdIds({
                    prod_id: vm.model.menu.prod_id,
                    stockplace_id: vm.model.menu.stockplace_id,
                    functionname: functionName,
                    include_not_in_stock: vm.model.menu.include_not_in_stock
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListStockVariantsOfProdIds);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'include_not_in_stock':
                        if (utilityService.validateWatchValue(value, vm.model.menu.include_not_in_stock) !== true) return;

                        vm.model.menu.include_not_in_stock = value;

                        if (utilityService.validateParmsValue(vm.model.menu.include_not_in_stock, true) !== true) return;

                        loadStockVariantsOfProdIds();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
