(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epShipmentPrevious', {
        templateUrl: 'views/components/views/epShipmentPrevious/epShipmentPrevious.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$state', '$uibModal', 'layoutService', 'responsive', function ($state, $uibModal, layoutService, responsive) {
            var vm = this;

            vm.fontSize = null;

            vm.view = function (e) {
                e.preventDefault();

                var dataItem = this.dataItem($(e.currentTarget).closest("tr"));

                if (['xx', 'xs', 'sm', 'md'].indexOf(responsive.current) > 0) {
                    $state.go('epshipmentpreviousview', { shipmentContainerKeyno: dataItem.shipmentcontainer_keyno });
                } else {
                    $uibModal.open({
                        component: 'epShipmentPreviousView',
                        resolve: {
                            shipmentContainerKeyno: function () {
                                return dataItem.shipmentcontainer_keyno
                            }
                        },
                        size: 'pst-ninety',
                        backdrop: 'static'
                    });
                }
            };

            vm.gridOptions = {
                dataTaskKeyno: 2008,
                rememberId: 'epShipmentPrevious',
                translations: ['skogstad_shipment_previous', 'view'],
                onSetup: function (schema, fields, columns, translations) {
                    var cols = [
                        { command: { text: translations.view, click: vm.view, className: 'col-xs-12' }, title: " ", width: "180px", attributes: { style: 'font-size: ' + vm.fontSize + ';' } },
                        columns[1],
                        columns[2],
                        columns[3]
                    ];

                    cols[2].attributes.style += 'text-align: left;';
                    cols[2].headerAttributes.style += 'text-align: left;';

                    angular.copy(cols, columns);
                }
            };

            var onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSizeS;
            });

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
