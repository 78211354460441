(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttGridwithlabel', {
        templateUrl: 'views/components/directives/ttGridwithlabel/ttGridwithlabel.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttHideLabel: '@',
            ttOptions: '<'
        },
        controller: ['layoutService', 'utilityService', 'translateService', 'eventService', function (layoutService, us, translateService, eventService) {
            let vm = this;

            let onDestroy = [];

            let labelAlwaysOnTop = false;

            vm.hideLabel = false;

            vm.id = {
                grid: uuid()
            };

            vm.style = {
                label: {},
                labelAlwaysOnTop: false
            };

            vm.class = {
                label: '',
                grid: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);

                    setClasses();
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                        vm.translations.ttLabel = translation;

                        vm.whenReady();
                    });
                }
            };

            vm.$onDestroy = function () {
                us.destroy(onDestroy);
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                labelAlwaysOnTop = info.labelAlwaysOnTop;

                setClasses();
            });

            function setClasses() {
                vm.class.label = labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : vm.style.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1';
                vm.class.grid = labelAlwaysOnTop === true || vm.hideLabel || vm.style.labelAlwaysOnTop === true ? 'col-xs-12' : 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11';
            };
        }]
    });
})();
