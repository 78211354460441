(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("webPageMenuService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadWebPageMenuList: function (webpage_name) {
                return p2DataTaskService.call(53, {
                    webpage_name: webpage_name
                });
            }
        };

        return service;
    }]);
})();