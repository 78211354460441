import { Injectable } from '@angular/core';
import { UserDetails } from '../models/user-details';

export interface Settings {
    appRoutes: Array<AppRoute>;
    baseUrl: string;
    dataUrl: string;
    printServerUrl: string;
    fileServerUrl: string;
    loginUrl: string;
    loginUrlUserlink: string;
    loginUrlCredentials: string;
    canModifyTwoFaLogin: boolean;
    companyId: string;
    companyName: string;
    custNo: number;
    clientId: string;
    urlLogoWelcome: string;
    isAuthorized: boolean;
    clientApiProxyId: string;
    version: string;
    fileServerProxyId: string;
    printServerProxyId: string;
    user?: UserDetails;
    agGridLicense: string;
    clientSessionId: string;
}

export interface AppRoute {
    key: number;
    id: string;
    path: string;
    component: string;
    routeId: string;
    order: number;
    remarks: string;
}

@Injectable({
    providedIn: 'root',  // This ensures that the service is a singleton
})
export class AppSettingsService {
    private _settings: Settings;
    private _isReady: boolean = false;
    private _clientSessionId: string = '';

    constructor() {
        this._settings = {} as Settings;
    }

    get settings(): Settings {
        return this._settings;
    }

    set settings(value: Settings) {
        this._settings = value;
    }

    get clientSessionId(): string {
        return this._clientSessionId;
    }

    set clientSessionId(value: string) {
        this._clientSessionId = value;
    }

    public setIsReady() {
        this._isReady = true;
    }

    public async ensureIsReady(): Promise<void> {
        await this.waitForTrue(() => this._isReady);
    }

    private waitForTrue(variable: () => boolean, interval: number = 100): Promise<void> {
        return new Promise((resolve) => {
            const intervalId = setInterval(() => {
                if (variable()) {
                    clearInterval(intervalId);
                    resolve();
                }
            }, interval);
        });
    }
}
