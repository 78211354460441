import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Style } from '@app/core/services/core-component.service';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.css'],
})
export class ErrorModalComponent {
    /**
     * Whether all daya is finish loading for the modal.
     */
    private ready = false;

    public message: string = '';

    /**
     * Map over words to translate.
     */
    public translations: { [key: string]: string } = {
        error: 'error_occured',
    };

    /**
     * Styling for elements in component.
     */
    public style: Style = {
        title: {
            color: 'white',
        },
        button: {},
        invalidtext: {
            color: 'var(--tt-body-text-color)',
        },
    };

    constructor(public dialogRef: MatDialogRef<ErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ErrorModalData, private layoutService: LayoutService, private translateService: TranslateService, private datatask: DataTaskService) {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['title'].fontSize = info.fontSizes.textSizeXL;
                this.style['invalidtext'].fontSize = info.fontSizes.textSize;
                this.style['button'].height = info.height + 4 + 'px';
            }
        });

        this.message = data.message ?? '';
        this.loadModalData();
    }

    /**
     * Translates the words in the component.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.values(this.translations));

        for (let key of Object.keys(translations)) {
            Object.entries(this.translations).forEach(([prop, value]) => {
                if (value === key) {
                    this.translations[prop] = translations[key];
                }
            });
        }
    }

    /**
     * Loads data to the component.
     */
    private async loadModalData() {
        await this.translate();

        this.ready = true;
    }
}

/**
 * Represents data used in the error dialog.
 */
export interface ErrorModalData {
    /**
     * The message to display in the error dialog.
     */
    message: string;
}
