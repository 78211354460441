(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("serviceObjectTypeService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadServiceObjectTypeGet: function (serviceobjecttype_keyno) {
                return p2DataTaskService.call(1741, {
                    serviceobjecttype_keyno: serviceobjecttype_keyno
                });
            },
            save: function (settingType) {
                return p2DataTaskService.call(1742, settingType);
            }
        };

        return service;
    }]);
})();