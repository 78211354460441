(function () {
    'use strict';

    angular.module("imApp").factory("watchService", ['$parse', '$timeout', '$exceptionHandler', function ($parse, $timeout, $exceptionHandler) {
        var watchers = [];
        var digestWatchIndex = -1;

        var initWatchVal = function () { };

        var removing = false;

        var removeWatcher = function (value) {
            removing = true;

            var index = -1;

            for (var i = 0; i < watchers.length; i++) {
                if (value.id !== watchers[i].id)
                    continue;

                index = i;

                break;
            }

            if (index >= 0) {
                watchers.splice(index, 1);
                digestWatchIndex--;
            }

            removing = false;
        };

        var isNumberNaN = Number.isNaN || function isNumberNaN(num) {
            // eslint-disable-next-line no-self-compare
            return num !== num;
        };

        var service = {
            watch: function (watchExp, listener, objectEquality, prettyPrintExpression, onDestroy) {
                if (angular.isArray(watchExp)) {
                    var responses = [];

                    angular.forEach(watchExp, function (wexp) {
                        var response = service.watch(wexp, listener, objectEquality, prettyPrintExpression, onDestroy);

                        if (angular.isDefined(response)) {
                            responses.push(response);
                        }
                    });

                    if (responses.length > 0) {
                        return responses;
                    } else {
                        return;
                    }
                }

                var get = $parse(watchExp);
                var fn = angular.isFunction(listener) ? listener : noop;

                if (angular.isArray(objectEquality) && angular.isUndefined(prettyPrintExpression) && angular.isUndefined(onDestroy)) {
                    onDestroy = objectEquality;
                    objectEquality = false;
                    prettyPrintExpression = false;
                }

                if (angular.isArray(prettyPrintExpression) && angular.isUndefined(onDestroy)) {
                    onDestroy = prettyPrintExpression;
                    prettyPrintExpression = false;
                }

                var watcher = {
                    id: uuid(),
                    fn: fn,
                    last: initWatchVal,
                    get: get,
                    exp: prettyPrintExpression || watchExp,
                    eq: !!objectEquality
                };

                // we use unshift since we use a while loop in custom digest for speed.
                // the while loop reads in reverse order.
                watchers.unshift(watcher);

                digestWatchIndex++;

                if (angular.isArray(onDestroy)) {
                    onDestroy.push(function deregisterWatch() { removeWatcher(watcher); });
                } else {
                    return function deregisterWatch() { removeWatcher(watcher); }
                }
            },

            remove: function (fn) {
                if (angular.isFunction(fn)) {
                    fn();
                }
            }
        };

        var digest = function () {
            var dirty, value, watch, get, last, fn;

            if (removing === true)
                return;

            do {
                dirty = false;

                digestWatchIndex = watchers.length;

                // eslint-disable-next-line no-constant-condition
                while (true) {
                    digestWatchIndex--;

                    if (digestWatchIndex < 0)
                        break;

                    try {
                        watch = watchers[digestWatchIndex];

                        // Most common watches are on primitives, in which case we can short
                        // circuit it with === operator, only when === fails do we use .equals
                        if (watch) {
                            get = watch.get;

                            if ((value = get()) !== (last = watch.last) &&
                                !(watch.eq
                                    ? equals(value, last)
                                    : (isNumberNaN(value) && isNumberNaN(last)))) {

                                dirty = true;
                                watch.last = watch.eq ? angular.copy(value, null) : value;
                                fn = watch.fn;

                                fn(value, ((last === initWatchVal) ? value : last));
                            }
                        } else {
                            dirty = false;
                        }
                    } catch (e) {
                        $exceptionHandler(e);
                    }
                }
            } while (dirty)

            // timeout fires on next angularjs digest cycle.
            $timeout(digest, 0, false);
        };

        $timeout(digest, 0, false);

        return service;
    }]);
})();
