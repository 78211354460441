(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productMenu', {
        templateUrl: 'views/components/views/productMenu/productMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'productMenuService', function (stateService, productMenuService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
    
            vm.model = {
                name: stateService.getCurrentName()
            };

            // #endregion VARIABLES & DEFINITIONS
        }]
    });
})();
