(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('requisitionToConsignment', {
        templateUrl: 'views/components/views/requisitionToConsignment/requisitionToConsignment.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'requisitionToConsignmentService', function ($stateParams, stateService, utilityService, requisitionToConsignmentService) {
            var vm = this;
    		
            vm.model = {
                bgRequisitionToConsignment: {
                    selected: 'FULL',
                    buttons: [
                        { id: 'FULL', label: 'requisitiontoconsignment_full', color: 'primary', onClick: () => setRequisitionToConsignmentView('FULL') },
                        { id: 'PARTLY', label: 'requisitiontoconsignment_partly', color: 'primary', onClick: () => setRequisitionToConsignmentView('PARTLY') },
                        { id: 'NOT', label: 'requisitiontoconsignment_not', color: 'primary', onClick: () => setRequisitionToConsignmentView('NOT') }
                    ]
                },
                setting: {},
                itemsListRequisitionsToConsignment: [],
                p2WRequisitionToConsignmentCreate: {},
                lockedCreate: false
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            let setRequisitionToConsignmentView = function (id) {
                vm.model.bgRequisitionToConsignment.selected = vm.model.setting.bg_requisitiontoconsignment = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.loadP2WRequisitionToConsignmentGet = function () {
                requisitionToConsignmentService.genericFunction(768, {
                    stockplace_id_from: $stateParams.stockplace_id_from,
                    stockplace_id_to: $stateParams.stockplace_id_to,
                    consignment_keyno: $stateParams.consignment_keyno
                }).then(function (data) {
                    vm.model.setting = angular.copy(data[0]);
                    setRequisitionToConsignmentView(vm.model.setting.bg_requisitiontoconsignment);
                    
                    vm.loadP2WRequisitionToConsignmentList();
                });
            };
    
        	vm.loadP2WRequisitionToConsignmentGet();
    
            vm.loadP2WRequisitionToConsignmentList = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bg_requisitiontoconsignment) !== true) return;
    
                if (vm.model.setting.stockplace_id_from !== '' && angular.isDefined(vm.model.setting.stockplace_id_from)) {
                    vm.model.itemsListRequisitionsToConsignment = [];
                    
                    requisitionToConsignmentService.genericFunction(769, vm.model.setting).then(function (data) {
                        angular.copy(data, vm.model.itemsListRequisitionsToConsignment);
                    });
                }
            };
        	
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgRequisitionToConsignment = function (value) {
                vm.model.bgRequisitionToConsignment.selected = value;
                vm.model.setting.bg_requisitiontoconsignment = vm.model.bgRequisitionToConsignment.selected;

                vm.loadP2WRequisitionToConsignmentList();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## TOGGLE FUNCTIONS ##
            
    		vm.selectAllFull = function () {
                angular.forEach(vm.model.itemsListRequisitionsToConsignment, function (item) {
    				if (item.item_id === '0' || item.item_id === '-1') return;
    
    				item.item_is_selected = '1';
    			});
    		};
    
    		vm.unselectAllFull = function () {
    			angular.forEach(vm.model.itemsListRequisitionsToConsignment, function (item) {
    				if (item.item_id === '0' || item.item_id === '-1') return;
    
    				item.item_is_selected = '0';
    			});
    		};
            
            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                var mergeObj = vm.model.setting;
                mergeObj.records = vm.model.itemsListRequisitionsToConsignment;

                requisitionToConsignmentService.genericFunction(770, mergeObj).then(function (data) {
                    vm.model.lockedCreate = false;
                    vm.model.p2WRequisitionToConsignmentCreate = angular.copy(data[0]);

                    stateService.go(vm.model.p2WRequisitionToConsignmentCreate.webpagename, {
                        consignment_keyno: vm.model.p2WRequisitionToConsignmentCreate.consignment_keyno
                    });
                });
            };
        }]
    });
})();