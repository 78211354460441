(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2ClientUpdate', {
        templateUrl: 'views/components/views/p2ClientUpdate/p2ClientUpdate.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['p2ClientUpdateService', function (p2ClientUpdateService) {
            var vm = this;
            vm.p2_client_update = p2ClientUpdateService.p2_client_update;

            p2ClientUpdateService.loadP2ClientUpdate();
        }]
    });
})();