(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2Webpage', {
        templateUrl: 'views/components/views/p2Webpage/p2Webpage.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'rememberService', 'p2WebpageService', function (stateService, rememberService, p2WebpageService) {
            var vm = this;

            var rememberId = 'p2Webpage.config';

            var remember = function () {
                rememberService.remember(rememberId, angular.toJson({
                    searchText: vm.model.searchText
                }));
            };

            vm.onClear = function () {
                if (angular.isUndefined(vm.model)) return;
                if (angular.isUndefined(vm.model.searchText)) return;

                vm.model.searchText = '';
            };

            vm.model = {
                getWebPage: {},
                itemsListWebPages: [],
                searchText: '',
                searchButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', onClick: { click: vm.onClear } }
                ]
            };

            vm.onSearchChanged = function (value) {
                vm.model.searchText = value;

                remember();
            };

            p2WebpageService.loadWebPageGet().then(function (data) {
                vm.model.getWebPage = angular.copy(data[0]);
            });
    
            p2WebpageService.loadP2Webpage().then(function (data) {
                angular.copy(data, vm.model.itemsListWebPages);
            });

            vm.goToP2WebpageEdit = function () {
                stateService.go('p2webpageedit', {
                    p2_webpage_keyno: 0
                });
            };

            rememberService.getLastStatus(rememberId).then(function (lastState) {
                if (angular.isArray(lastState) !== true) return;
                if (lastState.length < 1) return;
                if (angular.isUndefined(lastState[0].variablevalue)) return;
                if (angular.isString(lastState[0].variablevalue) !== true) return;
                if (lastState[0].variablevalue.length < 1) return;

                var state = angular.fromJson(lastState[0].variablevalue);

                if (angular.isDefined(state.searchText)) {
                    vm.model.searchText = state.searchText;
                }
            });
        }]
    });
})();