(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("findStockService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 502,
                    parameters: parms || {}
                });
            },
            scanStock: function (setting) {
                return $ihttp.post({
                    method: 227,
                    parameters: setting
                });
            },
            confirmStockValue: function (scanningResponse) {
                return $ihttp.post({
                    method: 845,
                    parameters: scanningResponse
                });
            }
        };

        return service;
    }]);
})();
