(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("inventoryScanService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 939,
                    parameters: parms || {}
                });
            },
            listLastScans: function (parms) {
                return $ihttp.post({
                    method: 956,
                    parameters: parms || {}
                });
            },
            checkStockPlace: function (setting) {
                return $ihttp.post({
                    method: 941,
                    parameters: setting
                });
            },
            scanStock: function (setting) {
                return $ihttp.post({
                    method: 944,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
