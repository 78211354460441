(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('custactPlan', {
        templateUrl: 'views/components/views/custactPlan/custactPlan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custactPlanService', function ($stateParams, stateService, custactPlanService) {
            var vm = this;
            
            vm.model = {
                videoUrlButtons: [
                    { id: 'goto', icon: 'fas fa-video', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.p2CustactPlanGet.video_url) }
                ],
                p2CustactPlanGet: {},
                readyPlanNote: true
            };
            
            var loadP2CustactPlanGet = function () {
                custactPlanService.genericFunction(799, {
                    custactplan_keyno: $stateParams.custactplan_keyno
                }).then(function (data) {
                    vm.model.p2CustactPlanGet = angular.copy(data[0]);
                });
            };
            
        	loadP2CustactPlanGet();
            
            vm.goToWorkDetails = function () {
                stateService.go('workdetails', {
                    custact_no: vm.model.p2CustactPlanGet.custact_no
                });
            };
            
            vm.saveCustactPlan = function () {
                custactPlanService.genericFunction(807, vm.model.p2CustactPlanGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();
