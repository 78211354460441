(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("xml2jsonService", ['$q',   function ($q) {
        var service = {
        	xmlJson: function (xmlString){
        		var x2js = new X2JS();
	            var out = x2js.xml_str2json(xmlString);
	            return out;
        	},
        	jsonXml: function (jsonString){
        		var x2js = new X2JS();
        		var out = x2js.json2xml_str(jsonString);
        		return out;
        	}
        }
        return service;
    }]);
})();