(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productionStatus', {
        templateUrl: 'views/components/views/productionStatus/productionStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['productionStatusService', 'stateService', function(productionStatusService, stateService) {
    
            var vm = this;
    
    
    
            // ##############################
            // GLOBAL VARIABLES & DEFINITIONS
            // ##############################
    
            vm.model = {
                filterP2ProductionStatusScan: {
                    barcode: '',
                    records: []
                },
                state: {
                    loading: false
                },
                p2PohTopScan: {},
                p2PohTopGet: {},
                p2PohTopList: {
                    records: []
                }
            };
            
            // ###################
            // SCAN & SEARCH ITEMS
            // ###################
            
            vm.scanP2PohTop = function () {
                if (angular.isUndefined(vm.model.filterP2ProductionStatusScan.barcode) || vm.model.filterP2ProductionStatusScan.barcode.length == 0) return;
    
                vm.model.state.loading = true;
    
                vm.model.filterP2ProductionStatusScan.records.unshift({
                    barcode: vm.model.filterP2ProductionStatusScan.barcode
                });
                
                if (vm.model.filterP2ProductionStatusScan.records[0].barcode !== '') {
                    productionStatusService.scanP2PohTop(vm.model.filterP2ProductionStatusScan.records[0].barcode).then(function () {
                        vm.model.p2PohTopScan = productionStatusService.p2PohTopScan;
                        vm.model.state.loading = false;
    
                        if (angular.isDefined(vm.model.p2PohTopScan)) {
                            stateService.go(vm.model.p2PohTopScan.webpagename, vm.model.p2PohTopScan.parms);
                            vm.clearField();
                        } else {
                            vm.clearField();
                        }
    
                        //vm.clearField();
    
                        //if (angular.isDefined(vm.model.p2PohTopScan)) {
                        //    if (vm.model.p2PohTopScan.parm_barcode !== '') {
                        //        productionStatusService.getP2PohTop(vm.model.p2PohTopScan.parm_barcode).then(function () {
                        //            vm.model.p2PohTopGet = productionStatusService.p2PohTopGet;
                        //        });
    
                        //        productionStatusService.getP2PohTopList(vm.model.p2PohTopScan.parm_barcode).then(function () {
                        //            vm.model.p2PohTopList = productionStatusService.p2PohTopList;
                        //        });
                        //    }
                        //} else {
                        //    vm.model.p2PohTopGet.order_order_no = '';
                        //    vm.model.p2PohTopGet.custname = '';
                        //    vm.model.p2PohTopGet.deliverydate_confirmed = '';
                        //    vm.model.p2PohTopList.records.length = 0;
                        //}
                    });
                }
            }
    
            vm.clearField = function () {
                if (vm.model.filterP2ProductionStatusScan.barcode !== '' && vm.model.filterP2ProductionStatusScan.records.length > 0 && vm.model.state.loading == false) {
                    vm.model.filterP2ProductionStatusScan.barcode = '';
                }
            }
        }]
    });
})();