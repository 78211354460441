(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('searchLog', {
        templateUrl: 'views/components/views/searchLog/searchLog.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['searchLogComponentService', function (searchLogComponentService) {
            var vm = this;
            var debounceTimer = 300;
            var minimumSearchLength = 3;

            function handleQueryChange(value) {
                if (value.length < minimumSearchLength) return;

                return searchLogComponentService.search().then(function () {
                    console.log('search called');
                });
            }

            // https://stackoverflow.com/questions/26446681/angular-ng-change-delay
            // https://stackoverflow.com/questions/56013445/cant-convert-debounce-es5-to-es6
            function debounce(fn, wait) {
                var timeout;

                return function executeFunction(args) {
                    function later() {
                        clearTimeout(timeout);
                        fn(args);
                    };

                    clearTimeout(timeout);
                    timeout = setTimeout(later, wait);
                }
            }

            vm.model = {
                searchlog: {},
                //handleQueryChange: debounce(handleQueryChange, debounceTimer)
                handleQueryChange: handleQueryChange
            };
        }]
    });
})();
