(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentScanService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2ConsignmentScan: {
                webpagename: '',
                parms: '',
                parm_barcode: '',
                barcodetype: ''
            },
            searchP2ConsignmentScan: function (barcode) {
                var deferred = $q.defer();

                var parmsP2ConsignmentScan = {
                    method: 321,
                    parameters: {
                        barcode: barcode
                    }
                };

                $ihttp.post(parmsP2ConsignmentScan).then(function (data) {
                    service.p2ConsignmentScan = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();