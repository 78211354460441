(function() {
    'use strict';

    angular.module("imApp").factory("calculationCategoryService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var atou = function (data) { // Utility function for base64decode with unicode support
            return decodeURIComponent(escape(atob(data)));
        }

        var service = {
            recalculate: function (parameters) {
                return call(2640, parameters || {});
            }
        }

        return service;
    }]);
})();
