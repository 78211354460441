(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("travelBillsService", ['$ihttp', function ($ihttp) {
        let service = {
            getTravelBills: function (parms) {
                return $ihttp.post({
                    method: 1251,
                    parameters: parms || {}
                });
            },
            listLabours: function (parms) {
                return $ihttp.post({
                    method: 1252,
                    parameters: parms || {}
                });
            },
            listTravelBills: function (parms) {
                return $ihttp.post({
                    method: 1253,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
