(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('forecastDataGrid', {
        templateUrl: 'views/components/views/forecastDataGrid/forecastDataGrid.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['forecastDataGridService', 'ttGridFactory', 'logisticService', 'seasonalitiesService', 'forecastsService', 'stockForecastService', 'utilityService', 'typeaheadService', function (forecastDataGridService, ttGridFactory, logisticService, seasonalitiesService, forecastsService, stockForecastService, utilityService, typeaheadService) {
            var vm = this;

            vm.model = {
                gridReady: false,
                executing: false,
                setting: {},
                selectListCompanies: [],
                selectListForecasts: [],
                selectListResolutions: [],
                selectListSeasonalities: []

            };

            vm.grid = new ttGridFactory({
                dataTask: {
                    //rememberId: 'w_forecastgrid.grid',
                    loadData: {
                        method: null,
                        parameters: null
                    }
                }
            });

            const load = function () {
                forecastDataGridService.getForecastDataGrid().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });

                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });

                forecastsService.listForecasts().then(function (result) {
                    angular.copy(result, vm.model.selectListForecasts);
                });

                forecastDataGridService.listResolutions().then(function (result) {
                    angular.copy(result, vm.model.selectListResolutions);
                });

                seasonalitiesService.listSeasonalities().then(function (result) {
                    angular.copy(result, vm.model.selectListSeasonalities);
                });

                //vm.loadGrid();
            }

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'history_fom_date':
                    case 'history_tom_date':
                    case 'seasonality_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        retrieveHistoryPst();
                        break;
                    case 'forecast_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.forecast_keyno) !== true) return;

                        vm.model.setting.forecast_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.forecast_keyno) !== true) return;
                        
                        retrieveForecast();
                        break;
                    case 'forecast_resolution_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.forecast_resolution_id) !== true) return;

                        vm.model.setting.forecast_resolution_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.forecast_resolution_id) !== true) return;

                        if (vm.model.setting.forecast_type_id === 'R') retrieveHistoryDates();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.onBlur = function (id) {
                switch (id) {
                    case 'forecast_history_running_nbr':
                        if (utilityService.validateParmsValue(vm.model.setting.forecast_history_running_nbr) !== true) return;

                        if (vm.model.setting.forecast_type_id === 'R') retrieveHistoryDates();
                        break;
                    default:
                        break;
                }
            };

            vm.onClearTypeahead = function (id) {
                switch (id) {
                    case 'searchtext_supplier':
                        vm.model.setting.supplier_no = 0;
                        vm.model.setting.searchtext_supplier = '';
                        break;
                    default:
                        break;
                }
            }

            vm.onChangedTypeahead = function (value, item, id) {
                switch (id) {
                    case 'searchtext_supplier':
                        vm.model.setting.searchtext_supplier = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.setting.supplier_no = item.item_id;
                            }
                        } else {
                            vm.onClearTypeahead('searchtext_supplier');
                        }
                        break;
                    default:
                        break;
                }
            };

            vm.onSelectedTypeahead = function (item, id) {
                switch (id) {
                    case 'searchtext_supplier':
                        if (item) {
                            vm.model.setting.supplier_no = item?.item_id ?? '0';
                            vm.model.setting.searchtext_supplier = item?.item_name ?? '';
                        } else {
                            vm.onClearTypeahead('searchtext_supplier');
                        }
                        break;
                    default:
                        break;
                }
            };

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'searchtext_supplier':
                        return typeaheadService.searchCustomer({
                            cust_name: value || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.onDateRangeChanged = function (date_fom, date_tom, date_index) {
                vm.model.setting.history_fom_date = date_fom;
                vm.model.setting.history_tom_date = date_tom;
                vm.model.setting.date_index = date_index;
            }

            const retrieveHistoryPst = function () {
                if (utilityService.validateParmsValue(vm.model.setting.seasonality_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.history_fom_date) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.history_tom_date) !== true) return;

                return forecastDataGridService.getHistoryPst(vm.model.setting).then(function (response) {
                    vm.model.setting.history_pst = response[0].history_pst;
                });
            };

            const retrieveHistoryDates = function () {
                if (utilityService.validateParmsValue(vm.model.setting.forecast_resolution_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.forecast_history_running_nbr) !== true) return;

                return stockForecastService.getHistoryDates({
                    forecast_resolution_id: vm.model.setting.forecast_resolution_id,
                    forecast_history_running_nbr: vm.model.setting.forecast_history_running_nbr
                }).then(function (response) {
                    vm.model.setting.history_fom_date = response[0].history_fom_date;
                    vm.model.setting.history_tom_date = response[0].history_tom_date;
                });
            };

            const retrieveForecast = function () {
                if (utilityService.validateParmsValue(vm.model.setting.forecast_keyno) !== true) return;

                return stockForecastService.getForecast({ forecast_keyno: vm.model.setting.forecast_keyno }).then(function (response) {
                    vm.model.setting.forecast_type_id = response[0].forecast_type_id;
                    vm.model.setting.forecast_resolution_id = response[0].forecast_resolution_id;
                    vm.model.setting.forecast_forward_nbr = response[0].forecast_forward_nbr;
                    vm.model.setting.forecast_history_running_nbr = response[0].forecast_history_running_nbr;

                    if (response[0].forecast_type_id === 'F') {
                        vm.model.setting.history_fom_date = response[0].forecast_history_fixed_fom_date;
                        vm.model.setting.history_tom_date = response[0].forecast_history_fixed_tom_date;
                        vm.model.setting.forecast_forward_fixed_startdate = response[0].forecast_forward_fixed_startdate;
                    }
                });
            };

            vm.loadGrid = function () {
                
                console.log('try loading grid');
                if (utilityService.validateParmsValue(vm.model.setting.supplier_no, true) !== true) console.log('supplier_no');// return;
                if (utilityService.validateParmsValue(vm.model.setting.forecast_type_id) !== true) console.log('forecast_type_id');// return;
                if (utilityService.validateParmsValue(vm.model.setting.forecast_resolution_id) !== true) console.log('forecast_resolution_id');// return;
                if (utilityService.validateParmsValue(vm.model.setting.history_fom_date, true) !== true) console.log('history_fom_date');// return;
                if (utilityService.validateParmsValue(vm.model.setting.history_tom_date) !== true) console.log('history_tom_date');// return;
                if (utilityService.validateParmsValue(vm.model.setting.forecast_forward_nbr) !== true) console.log('forecast_forward_nbr');// return;
                if (utilityService.validateParmsValue(vm.model.setting.forecast_keyno) !== true) console.log('forecast_keyno');// return;
                if (utilityService.validateParmsValue(vm.model.setting.history_pst, true) !== true) console.log('history_pst');// return;
                if (utilityService.validateParmsValue(vm.model.setting.seasonality_keyno) !== true) console.log('seasonality_keyno');// return;
                if (vm.model.setting.forecast_type_id === '' && utilityService.validateParmsValue(vm.model.setting.forecast_forward_fixed_startdate) !== true) console.log('forecast_forward_fixed_startdate');// return;

                vm.model.executing = true;
                console.log('loading grid');

                vm.grid.dataTask.loadData = {
                    method: 3446,
                    parameters: vm.model.setting
                }
                vm.model.gridReady = true;

                if (vm.grid.gridfunc && vm.grid.gridfunc.rebind) {
                    //console.log('rebinding grid');

                    vm.grid.gridfunc.rebind();
                }
                vm.model.executing = false;
            }

            vm.$onInit = function () {
                load();
            }
        }]
    });
})();
