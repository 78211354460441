import { Component, Input, OnChanges, SimpleChanges, } from '@angular/core';
import { CoreComponentService} from '@app/core/services/core-component.service';
import { ComponentBaseComponent } from '../component-base/component-base.component';


@Component({
  selector: 'tt-field-group',
  templateUrl: './field-group.component.html',
  styleUrls: ['./field-group.component.css']
})
export class FieldGroupComponent extends ComponentBaseComponent implements OnChanges {
    /**
     * Custom styling for the form-field element.
     */
    @Input()
    ttStyle: Partial<CSSStyleDeclaration> = {};

    /**
     * Inline style for various different elements of the form field.
     */
    style: Partial<CSSStyleDeclaration> = {
    };

    constructor(public coreComponentService: CoreComponentService) {
        super();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttStyle']?.currentValue && (typeof changes['ttStyle'].currentValue === 'string' || typeof changes['ttStyle'].currentValue === 'object')) {
            if (typeof changes['ttStyle'].currentValue === 'string') {
                changes['ttStyle'].currentValue = this.coreComponentService.stringToObject(changes['ttStyle'].currentValue);
            }
            this.setStyle(changes['ttStyle'].currentValue);
        }
    }

    /**
     * Sets the inline style of the component.
     *
     * @param ttStyle the customizable inline style.
     */
    public setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.mergeObjects(this.style, ttStyle);
    }
}
