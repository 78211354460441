(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentSplitEditService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadConsignmentSplitEditGet: function (consignment_keyno_splitfrom) {
                return p2DataTaskService.call(1450, {
                    consignment_keyno_splitfrom: consignment_keyno_splitfrom
                });
            },
            loadConsignmentPlanList: function (consignment_keyno_splitfrom) {
                return p2DataTaskService.call(1451, {
                    consignment_keyno_splitfrom: consignment_keyno_splitfrom
                });
            },
            loadConsignmentSplitEditList: function (consignment_keyno_splitfrom) {
                return p2DataTaskService.call(1449, {
                    consignment_keyno_splitfrom: consignment_keyno_splitfrom
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            move: function (mergeObj) {
                return p2DataTaskService.call(1452, mergeObj);
            }
        };

        return service;
    }]);
})();