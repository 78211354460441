(function () {
    'use strict';

    angular.module("imApp").factory("emailService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {

        let service = {

            /**
             * Retrieves page data for email webpage.
             * 
             * @returns {Promise<object>} object containing page data.
             */
            getEmail: function () {
                return $ihttp.post({ method: 2942, parameters: {} });
            },

            /**
             * @typedef {Object} EnrichedEmail the new fields to enrich the content of the emails.
             * @property {string} id the id of the email the enriched fields belong to.
             * @property {string} item_glyphicon the icon classes linked to the email.
             * @property {string} item_glyphicon_color the color of the icons linked to the email.
             * @property {string} cust_name customer name linked to the email.
             * @property {string} cust_no customer key linked to the email.
             * @property {string} pers_name the name of the person linked to the email.
             * @property {string} pers_no the person key of the person linked to the email.
             * @property {'1' | '0'} project_is_related_to_customer whether to show projects linked to the customer or not.
             * @property {string} project_name the name of the project the email is linked to.
             * @property {string} project_keyno the key of the project the email is linked to.
             * @property {string} projectprosess_keyno the key of the project prosess the email is linked to.
             * @property {string} prosess_name the name of the project prosess the email is linked to.
             * @property {string} custact_note customer activity description linked to the email.
             * @property {string} custact_no customer activity key linked to the email.
             * @property {string} activitytype_no the activity type of the custact the email is linked to.
             * @property {'1' | '0'} create_new_custact whether to create a new custact ('1') from the email or not ('0').
             * @property {string} order_internal_no the id of the order the email is linked to.
             * @property {string} order_search_info the name of the order the email is linked to.
             * @property {string} purch_internal_no the key of the purchase the email is linked to.
             * @property {string} purch_search_info the name of the purchase the email is linked to.
             */

            /**
             * Enriches the emails in the given list of emails.
             * 
             * @param {string} currentAccountEmail the email of the currently logged into account.
             * @param {Object[]} emails list of all emails to enrich content of.
             * @returns {Promise<EnrichedEmail[]>} returns a promise containing a list of enriched email fields.
             */
            enrichEmails: function (currentAccountEmail, businessCoNo, emails) {
                return $ihttp.post({
                    method: 2950,
                    parameters: { businessco_no: businessCoNo, current_account_email: currentAccountEmail, emails: emails }
                });
            },

            getBusinessCoList: function () {
                return $ihttp.post({ method: 104, parameters: {} });
            },

            /**
             * Saves the email as a new customer activity.
             * 
             * @param {Object} email the email to save for the new customer activity.
             * @returns {Promise<void>} a promise for saving the customer activity.
             */
            saveEmailItem: function (email) {
                return $ihttp.post({
                    method: 2951,
                    parameters: email || {}
                });
            },

            /**
             * Retrieves a list of persons connected to the customer with the given customer key (cust_no).
             * 
             * @param {string} custNo the customer key for the customer to retireve list of connected persons for.
             * @returns {Promise<[]>} a list of persons connected to the customer.
             */
            getCustomerPersons: function (custNo) {
                return $ihttp.post({ method: 71, parameters: { cust_no: custNo } });
            },

            ///**
            // * Retrieves a list of projects connected to the customer with the given customer key (cust_no).
            // * 
            // * @param {string} custNo the customer key for the custmer to retrieve list of connected projects for.
            // * @returns {Promise<object[]>} a list of projects connected to the customer with the given customer key.
            // */
            //getCustomerProjects: function (custNo) {
            //    return $ihttp.post({
            //        method: 902, parameters: { bg_projects_activestatus: 'ACTIVE', argtype: 'cust_no', argvalue: custNo }
            //    });
            //},

            /**
             * Finds the members of the custact with the given custact key.
             * 
             * @param {string} custactNo the key of the custact to find the members of.
             * @returns {Promise<object[]>} a list of object representing the members of a custact with the given custact key.
             */
            getCustactMembers: function (custactNo) {
                if (!custactNo) custactNo = '0';
                return $ihttp.post({
                    method: 95, parameters: { custact_no: custactNo, user_name: '' }
                });
            },

            /**
             * Gets all active projects.
             * 
             * @returns {Promise<object[]>} a list of all active projects.
             */
            getProjects: function (searchtext, custNo) {
                if (custNo === '0') {
                    return $ihttp.post({
                        method: 902, parameters: { bg_projects_activestatus: 'ACTIVE', searchtext: searchtext ?? '', argtype: '0', argvalue: '0' },
                    });
                }

                return $ihttp.post({
                    method: 902, parameters: { bg_projects_activestatus: 'ACTIVE', searchtext: searchtext ?? '', argtype: 'cust_no', argvalue: custNo ?? '0' }
                });
            },

            /**
             * Retrieves a list of all project prosesses connected to the project with the given project keyno.
             * 
             * @param {string} projectKeyno the project keyno to get project prosesses for.
             * @returns {Promise<object[]>} a list of project prosesses.
             */
            getProjectProsesses: function (projectKeyno) {
                return $ihttp.post({
                    method: 47, parameters: { custact_no: '0', show_only_timeentry_allowed: '0', 'project_keyno': projectKeyno }
                });
            },

            /**
             * Returns the customer for the customer activity which has the given key (custact_no).
             * 
             * @param {string} custactNo the customer activity key to get the customer for.
             * @returns {Promise<object>} object containg information about the customer connected to the customer activity from the given custact key.
             */
            getCustomerFromCustact: function (custactNo) {
                return $ihttp.post({ method: 2725, parameters: { custact_no: custactNo } });
            },

            /**
             * Retrieves list of custact based on the given customer key, project key and process key.
             * 
             * @param {string} custNo the customer key to find custacts for, set as '0' to ignore this field.
             * @param {string} projectKeyno the project key to find custacts for, set as '0' to ignore this field.
             * @param {string} projectProsessKeyno the process key to find custacts for, set as '0' to ignore this field.
             * @returns {Promise<object[]} list of custacts based on the given customer key, project key and process key.
             */
            getCustacts: function (custNo, projectKeyno, projectProsessKeyno) {
                return $ihttp.post({ method: 2726, parameters: { cust_no: custNo, project_keyno: projectKeyno, projectprosess_keyno: projectProsessKeyno } });
            },

            /**
             * Retrieves information about the project with the given project keyno.
             * 
             * @param {string} projectKeyno the key for the project to find information about.
             * @returns {Promise<{ data: object[]; iserror: boolean; message: string;}>} retireves an object representing the project.
             */
            getProject: function (projectKeyno) {
                return $ihttp.post({ method: 905, parameters: { project_keyno: projectKeyno } });
            },

            /**
             * Retrieves the list of activity types.
             * 
             * @returns {Promise<object[]>} list of activity types.
             */
            getActivityTypes: function () {
                return $ihttp.post({ method: 49, parameters: {} });
            },

            /**
             * Retrieves the orders from the customer with the given customer key, filtered by the given search text.
             * 
             * @param {string} searchtext the searchtext to filter with.
             * @param {string} custNo the customer key to search through orders from.
             * @returns {Promise<object[]>} a list containing the search results.
             */
            searchOrderInfo: function (searchtext, custNo) {
                return $ihttp.post({ method: 2772, parameters: { searchtext: searchtext, cust_no: custNo } });
            },

            /**
             * Retrieves the purchases from the customer with the given customer key, filered by the given search text.
             * 
             * @param {string} searchtext the search text to filter with.
             * @param {string} custNo the customer key to search through orders from.
             * @returns {Promise<object[]>} a list containing the search results.
             */
            searchPurchaseInfo: function (searchtext, custNo) {
                return $ihttp.post({ method: 2955, parameters: { searchtext: searchtext, cust_no: custNo } });
            },

            /**
             * Retrieves the list of shared mailboxes connected to the gicen current account email.
             * 
             * @param {string} currentAccountEmail the email to find connected shared mailboxes for.
             * @returns {Promise<object[]>} list of shared mailboxes connected to the given account email.
             */
            getSharedMailboxes: function (currentAccountEmail) {
                return $ihttp.post({ method: 2957, parameters: { current_account_email: currentAccountEmail } });
            },

            /**
             * Remembers the given set of variable name and variable value.
             * 
             * @param {string} variablename the key to remember the value with.
             * @param {string | number | boolean} variablevalue the value to remember.
             * @returns {Promise<{iserror: boolean;}>} returns a promise containing whether an error occurred or not.
             */
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },

            // unused functions

            //listEmailFolders: function (parms) {
            //    return $ihttp.post({
            //        method: 2944,
            //        parameters: parms || {}
            //    });
            //},

            //saveObj: function (edit) {
            //    return $ihttp.post({
            //        method: 2943,
            //        parameters: edit
            //    });
            //}
        };

        return service;
    }]);
})();
