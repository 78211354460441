(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('discGrpProdLangs', {
        templateUrl: 'views/components/views/discGrpProdLangs/discGrpProdLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'discGrpProdLangsService', function ($stateParams, stateService, discGrpProdLangsService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let discGrpProdKeyno = $stateParams.discgrpprod_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadLanguages = function () {
            	discGrpProdLangsService.listLanguages({ discgrpprod_keyno: discGrpProdKeyno }).then(function (result) {
            		angular.copy(result, vm.model.inputListLanguages);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                discGrpProdLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadLanguages();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
