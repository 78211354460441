(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('custactDeadLines', {
        templateUrl: 'views/components/views/custactDeadLines/custactDeadLines.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custactDeadLinesService', 'ttGridFactory', function ($stateParams, stateService, custactDeadLinesService, ttGridFactory) {
            var vm = this;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;
            
            vm.model = {
                mobileSize: window.matchMedia('(max-width: 992px)'),
                itemsListDeadLines: [],
                setting: {
                    show_setting: false
                }
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_custactdeadlines.grid',
                loadData: {
                    method: 3343,
                    parameters: {}
                }
            }).setToolbar({ wrapping: true });


            angular.element(document).ready(function () {
                custactDeadLinesService.getCustActDeadlines().then(function (response) {
                    angular.copy(response[0], vm.model.setting);
                });

                custactDeadLinesService.listCustactDeadLines().then(function (response) {
                    angular.copy(response, vm.model.itemsListDeadLines);
                });
            });
            

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'custactedit':
                        stateService.go(state, {
                            custact_no: '0',
                            filterfield: argType,
                            filtervalue: argValue
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();
