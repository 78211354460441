(function() {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockAssign', {
        templateUrl: 'views/components/views/stockAssign/stockAssign.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'stockAssignService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, stockAssignService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;
            let stockBatchKeyno = $stateParams.stockbatch_keyno;
            let stockLocationKeyno = $stateParams.stocklocation_keyno;
            let functionName = $stateParams.functionname;
            let p2PoFinishProdCompKeyno = $stateParams.p2_pofinishprodcomp_keyno;
            let webPage = stateService.getCurrentName();

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                bgIntegerConsumption: {
                    selected: 'NORMAL',
                    buttons: [
                        { id: 'NORMAL', label: 'gb_integer_consumption_integer', color: 'primary', onClick: () => vm.model.bgIntegerConsumption.selected = 'NORMAL' },
                        { id: 'WASTAGE', label: 'gb_integer_consumption_wastage', color: 'primary', onClick: () => vm.model.bgIntegerConsumption.selected = 'WASTAGE' }
                    ]
                },
                edit: {},
                inputListQuantities: [],
                selectListUnits: [],
                selectListCalcPicks: [],
                lockedSave: false,
                lockedSaveAndNew: false,
                lockedDeleteAndSave: false,
                lockedDelete: false,
                lockedAbort: false
            };

            // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #region LOAD PROCEDURE CALLS
    
            let loadEdit = function () {
                let deferred = $q.defer();

                stockAssignService.getEdit({
                    stock_keyno: stockKeyno,
                    stockbatch_keyno: stockBatchKeyno,
                    stocklocation_keyno: stockLocationKeyno,
                    functionname: functionName,
                    p2_pofinishprodcomp_keyno: p2PoFinishProdCompKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadQuantities(),
                        loadProductUnits(),
                        loadCalcPicks()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadQuantities = function () {
                if (utilityService.validateParmsValue(vm.model.edit.p2_pofinish_keyno) !== true) return;

                return stockAssignService.listQuantities({ p2_pofinish_keyno: vm.model.edit.p2_pofinish_keyno }).then(function (result) {
                    angular.copy(result, vm.model.inputListQuantities);
                });
            };

            let loadProductUnits = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                
                return stockAssignService.listProductUnits({ prod_id: vm.model.edit.prod_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListUnits);
                });
            };

            let loadCalcPicks = function () {
                if (utilityService.validateParmsValue(vm.model.edit.p2_pofinish_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.custactcalc_custact_no) !== true) return;

                return stockAssignService.listCalcPicks({
                    p2_pofinish_keyno: vm.model.edit.p2_pofinish_keyno,
                    custact_no: vm.model.edit.custactcalc_custact_no
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListCalcPicks);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region CHANGE FUNCTION

            let updateQuantityPickNow = function () {
                for (var i = 0; i < vm.model.inputListQuantities.length; i++) {
                    if (vm.model.edit.nbr_of_p2_pofinishprod === '1') {
                        vm.model.inputListQuantities[i].quantity_distributed = vm.model.edit.quantity_pick_now;
                    }
                }
            };

            // #endregion CHANGE FUNCTION

            // #region RECALC FUNCTIONS
            
            let recalcStockQuantity = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.input_unit_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prod_stock_unit_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.input_quantity) !== true) return;
                
                return stockAssignService.recalcUnit({
                    prodsize_keyno: vm.model.edit.prodsize_keyno,
                    unit_no_from: vm.model.edit.input_unit_no,
                    unit_no_to: vm.model.edit.prod_stock_unit_no,
                    numbertocalc: vm.model.edit.input_quantity
                }).then(function (response) {
                    if (response[0].numberresult > '') {
                        vm.model.edit.quantity_pick_now = response[0].numberresult;
                        updateQuantityPickNow();
                    }
                });
            };
            
            let recalcLengthWidth = function () {
                let inputQuantity = 0;
                
                if (vm.model.edit.input_unit_no === '122') {
                    inputQuantity = (utilityService.parseNumber(vm.model.edit.helpcalc_width_mm) / 1000 * utilityService.parseNumber(vm.model.edit.helpcalc_length_mm) / 1000 * (1 + utilityService.parseNumber(vm.model.edit.helpcalc_wastage_pst) / 100) * utilityService.parseNumber(vm.model.edit.helpcalc_qty));
                    vm.model.edit.input_quantity = inputQuantity;
                }
            };

            // #endregion RECALC FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgIntegerConsumption = function (value) {
                vm.model.bgIntegerConsumption.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'input_unit_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        recalcStockQuantity();
                        break;
                    case 'quantity_pick_now':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        if (vm.model.edit.nbr_of_p2_pofinishprod === '1') updateQuantityPickNow();
                        break;
                    case 'helpcalc_width_mm':
                    case 'helpcalc_length_mm':
                    case 'helpcalc_wastage_pst':
                    case 'helpcalc_qty':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        recalcLengthWidth();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'input_quantity':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        recalcStockQuantity();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockget':
                        go({ stock_keyno: vm.model.edit.stock_keyno });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region SAVE BUTTON FUNCTIONS
    
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
    
                var pobject = {};
                angular.copy(vm.model.edit, pobject);
                pobject.gb_integer_consumption = vm.model.bgIntegerConsumption.selected;
                
                stockAssignService.saveObject({
                    p2WStockAssignGet: pobject,
                    p2PoFinishProdCompQtyInputList: vm.model.inputListQuantities
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back('/' + response[0].webpagename + '/' + response[0].parms);
                    }
                });
            };
    
            vm.saveAndNewChanges = function () {
                vm.model.edit.saveandnew = '1';
                vm.model.lockedSaveAndNew = true;
                
                var pobject = {};
                angular.copy(vm.model.edit, pobject);
                pobject.gb_integer_consumption = vm.model.bgIntegerConsumption.selected;
                
                stockAssignService.saveObject({
                    p2WStockAssignGet: pobject,
                    p2PoFinishProdCompQtyInputList: vm.model.inputListQuantities
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSaveAndNew = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSaveAndNew = false;
                        loadEdit();
                    }
                });
            };
    
            vm.saveAndDeleteChanges = function () {
                vm.model.lockedDeleteAndSave = true;

                stockAssignService.deleteItem({
                    p2_pofinishprodcomp_keyno: vm.model.edit.p2_pofinishprodcomp_keyno,
                    from_webpage: webPage
                }).then(function () {
                    var pobject = {};
                    angular.copy(vm.model.edit, pobject);
                    pobject.gb_integer_consumption = vm.model.bgIntegerConsumption.selected;
                    pobject.p2_pofinishprodcomp_keyno = 0;

                    stockAssignService.saveObject({
                        p2WStockAssignGet: pobject,
                        p2PoFinishProdCompQtyInputList: vm.model.inputListQuantities
                    }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDeleteAndSave = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDeleteAndSave = false;
                            stateService.back();
                        }
                    });
                }, function () {
                    vm.model.lockedDeleteAndSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTIONS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    stockAssignService.deleteItem({
                        p2_pofinishprodcomp_keyno: vm.model.edit.p2_pofinishprodcomp_keyno,
                        from_webpage: webPage
                    }).then(function (response) {
                        vm.model.lockedDelete = false;
                        stateService.back('/' + response[0].webpagename + '/' + response[0].parms);
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region BUTTON ABORT PROCEDURE FUNCTION CALL

            vm.abortValues = function () {
                vm.model.lockedAbort = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    stockAssignService.abortItem(vm.model.edit).then(function (response) {
                        vm.model.lockedAbort = false;
                        stateService.back('/' + response[0].webpagename + '/' + response[0].parms);
                    }, function () {
                        vm.model.lockedAbort = false;
                    });
                }, function () {
                    vm.model.lockedAbort = false;
                });
            };

            // #endregion BUTTON ABORT PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
