(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansDelivery', {
        templateUrl: 'views/components/views/consignmentPlansDelivery/consignmentPlansDelivery.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'consignmentPlansDeliveryService', function($timeout, stateService, consignmentPlansDeliveryService) {
            var vm = this;
            var resizing = false;
            var editing = false;

            vm.model = {
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item && vm.model.mconfig.records[r].canActive === true) {
                                    vm.model.mconfig.records[r].isActive = true;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {                    
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.consignmentPlansDeliveryColumnResize();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        
                        /** UNCHECK ALL */
                        /*if (item.item_name === 'uncheck') {
                            vm.productionhTopsComponentsColumnUncheckAll();
                        }*/
                        
                        if (item.item_name === 'settings') {
                            stateService.go('consignmentplansdeliverysettings',{});
                        }
                        
                        if (item.item_name === 'refresh') {
                            stateService.go('consignmentplansdelivery',{
                                counter: 1
                            },{ reload: true, inherit: false });
                        }
                                            
                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.consignmentPlansDeliveryColumnResize();
                            }
                        }
                        
                        if (item.item_name === 'plus') {
                            stateService.go('consignmentplansorders',{
                                argtype: 'direct',
                                argvalue: 0
                            });
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                        { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: true },
                        { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '5', item_name: 'refresh', glyph: 'glyphicon-refresh', color: 'primary',  enabled: true, show: true },
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '7', item_name: 'edit', glyph: 'fa-edit', color: 'primary', colorActive: 'warning', canActive: true, isActive: true, isIcon: false, enabled: false, show: false },
                        //{ item_id: '8', item_name: 'settings', glyph: 'glyphicon-user', color: 'primary',  enabled: true, show: true },
                        { item_id: '10', item_name: 'uncheck', glyph: 'glyphicon-unchecked', color: 'primary', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                plans: [],
                plansRaw: [],
                settings_mode: 0,
                deliverymethod_no: 0,
                deliverymethods: [],
                portal_user_keyno: '',
                portal_user_name: '',
                stocklocation_keyno: '',
                portalusers: [],
                loadingzone: '',
                loaded: false            
            };
            
            vm.saveSettings = function () {
                consignmentPlansDeliveryService.remember('consignmentplansdelivery.deliverymethod_no', vm.model.deliverymethod_no);
                consignmentPlansDeliveryService.remember('consignmentplansdelivery.loadingzone', vm.model.loadingzone);

                if (vm.model.settings_mode === 1) {
                    vm.model.settings_mode = 0;
                } else {
                    vm.model.settings_mode = 1;
                }
            };
            
            vm.cancelSettings = function () {
                if (vm.model.settings_mode === 1) {
                    vm.model.settings_mode = 0;
                } else {
                    vm.model.settings_mode = 1;
                }
            };

            consignmentPlansDeliveryService.loadSettings().then(function (data) {
                vm.model.labour_no = data.labour_no;
                vm.model.labour_name = data.labour_name;
                vm.model.stocklocation_keyno = data.stocklocation_keyno;
                vm.model.stocklocation_name = data.stocklocation_name;
            });
            
            consignmentPlansDeliveryService.loadDeliveryMethods().then(function (data) {
                angular.copy(data, vm.model.deliverymethods);
            });

            consignmentPlansDeliveryService.loadPlans({}).then(function (data) {
                var smallDataLoad = [];

                for (var i = 0; i < data.length; i++) {
                    data[i].item_id = illegalCharRegExp(data[i].item_id);
                    smallDataLoad.push(data[i]);
                }

                angular.copy(smallDataLoad, vm.model.plans);
                angular.copy(smallDataLoad, vm.model.plansRaw);                
                vm.loaded = true;
            });
            
            vm.goToSelectLabour = function () {
                stateService.go('consignmentplansdeliverylabour', {});
            };

            vm.goToSelectLocation = function () {
                stateService.go('consignmentplansdeliverylocation', {});
            };
            
            var illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            };
            
            vm.gridClick = function(item){
                //item = angular.isDefined(item.item) ? item.item : item;
                var baseItem = angular.isDefined(item.item) ? item.item : item;
                var currentItem = angular.isDefined(item.col) && item.col !== null ? baseItem.item_secondary[item.col] : baseItem;

                if (baseItem.item_id != 0){
                    switch (currentItem.item_func) {
                        case 'car':
                            //vm.goTo('productionhTopsComponentsstock');                      
                            stateService.go('consignmentplansdeliverydriver',{
                                argtype: 'custact',
                                argvalue: baseItem.item_id
                            });
                            break;
                        default:
                            stateService.go(baseItem.item_state, baseItem.item_parms);                        
                        break;
                    }
                }          
            };
            
            vm.consignmentPlansDeliveryColumnResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
                
                var updateList = [];
                
                if (angular.isDefined(vm.model.plans[0]) && angular.isDefined(vm.model.plans[0].item_secondary)) {
                    for (var r = 0; r < vm.model.plans[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.plans[0].item_col_width })
                        }

                        if (angular.isDefined(vm.model.plans[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.plans[0].item_secondary[r].item_col_width })
                        }
                    }
                }
                
                var updateParms = {
                    view_id: 'consignmentplansdelivery.list',
                    records: updateList
                };
                
                consignmentPlansDeliveryService.updateColumnResize(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
                        
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
                    
                    resizing = false;
                });
            };
        }]
    });
})();