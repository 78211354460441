(function () {
	'use strict';

	var module = angular.module('imApp');

	module.component('categoryLinkAdd', {
		templateUrl: 'views/components/views/categoryLinkAdd/categoryLinkAdd.template.html?v=' + module.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'categoryLinkAddService', function($stateParams, stateService, categoryLinkAddService) {
			var vm = this;

			vm.model = {
				addButtons: [
					{ item_id: '1', item_name: 'add', item_func: 'create_new', glyph: 'glyphicon-plus', color: 'success' }
				],
				belongto: {},
				dataSource:{
					records: []
				},
				category_name: ''
			};

			var loadBelongTo = function () {
				categoryLinkAddService.genericFunction(630, { category_keyno: $stateParams.category_keyno_belongto }).then(function (data) {
					vm.model.belongto = angular.copy(data[0]);
					loadCategoriesNotLinked($stateParams.category_keyno_belongto);
				});
			};

			loadBelongTo();

			var loadCategoriesNotLinked = function (belongto) {
				categoryLinkAddService.genericFunction(631, { category_keyno_belongto: belongto }).then(function (data) {
					angular.copy(data, vm.model.dataSource.records);
				});
			};

			vm.addLink = function () {
				categoryLinkAddService.genericFunction(632, { category_keyno: 0, category_keyno_belongto: $stateParams.category_keyno_belongto, category_name: vm.model.category_name }).then(function (data) {
					stateService.back();
				});
			};

			vm.linkItem = function (item) {
				categoryLinkAddService.genericFunction(632, { category_keyno: item.item_id, category_keyno_belongto: $stateParams.category_keyno_belongto, category_name: '' }).then(function (data) {
					stateService.back();
				});
			};
		}]
	});
})();