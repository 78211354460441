(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("consignmentPlansOrdersService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadOrders: function (parms) {
				return p2DataTaskService.call(1753, parms);
			},
            linkOrder: function (parms) {
				return p2DataTaskService.call(1754, parms);
			},
            createConsignment: function (parms) {
				return p2DataTaskService.call(1755, parms);
			},
			updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
			}
		};

		return service;
	}]);
})();