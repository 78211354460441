(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('counters', {
        templateUrl: 'views/components/views/counters/counters.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', '$q', 'stateService', 'utilityService', 'countersService', 'rememberService', function ($timeout, $q, stateService, utilityService, countersService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            var variableNames = {
                searchtext: ''
            };

            var timer = {
                searchtext: null
            };

            vm.model = {
                searchButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => vm.onSearchTextChanged('') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.onSearchTextChanged(vm.model.setting.searchtext) },
                ],
                setting: {},
                gridReady: false,
                searchingCounters: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) { };

            // #endregion VARIABLES & DEFINITIONS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                if (angular.isDefined(timer[id])) {
                    $timeout.cancel(timer[id]);
                }

                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                if (vm.model.setting.searchtext > '') {
                    timer[id] = $timeout(function () {
                        rememberService.remember(variableNames[id], variableValue);
                    }, 1000);
                } else {
                    rememberService.remember(variableNames[id], variableValue);
                }
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.onSearchTextChanged = function (value) {
                vm.model.setting.searchtext = value;
                vm.model.searchButtons[1].icon = 'fas fa-spinner fa-spin';

                rememberFunc('searchtext');

                vm.grid.gridfunc.rebind().then(() => vm.model.searchButtons[1].icon = 'glyphicon glyphicon-search');
            }

            vm.$onInit = function () {
                countersService.getCounters().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    vm.grid.dataTask.rememberId = 'w_counters_grid';

                    vm.grid.dataTask.loadData = {
                        method: 2553,
                        parameters: vm.model.setting
                    };

                    vm.model.gridReady = true;
                });
            }

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
