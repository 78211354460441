(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('modalWordlang', {
        templateUrl: 'views/components/directives/modalWordlang/modalWordlang.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$q', '$scope', '$timeout', 'base64', 'modalWordlangService', 'languageService', 'translateService', function ($q, $scope, $timeout, base64, modalWordlangService, languageService, translateService) {
            var vm = this;

            vm.word_id;
            vm.autoSave = true;

            vm.wordlang = [];
            vm.languages = [];

            var wordlangIndex = {};
            var languageIndex = {};
            var currentLanguage = '';

            vm.keeptextChanged = function (wl, value) {
                wl.keeptext = value;

                modalWordlangService.updateKeeptext(vm.word_id, wl.language_id, value);
            };

            vm.wordlangChanged = function (wl, value) {
                wl.wordlang_name = value;

                modalWordlangService.updateWordlangName(vm.word_id, wl.language_id, value);
            };

            var load = function (wordId) {
                vm.word_id = wordId;

                var loaded = function (response) {
                    if (angular.isDefined(response)) {
                        angular.copy(response, vm.wordlang);
                    }

                    if (vm.languages.length < 1) {
                        $timeout(loaded, 100);
                        return;
                    }

                    if (vm.wordlang.length < 1) {
                        // new word_id
                        if (vm.autoSave === true && vm.word_id.trim().length > 0) {
                            modalWordlangService.new(vm.word_id).then(function () {
                                load(vm.word_id);
                            });
                        } else {
                            angular.forEach(vm.languages, function (lg) {
                                vm.wordlang.push({
                                    keeptext: '0',
                                    language_id: lg.item_id,
                                    language_name: lg.item_name,
                                    wordlang_name: vm.word_id
                                });
                            });
                        }
                    }

                    var syIndex = -1;

                    for (var i = 0; i < vm.wordlang.length; i++) {
                        vm.wordlang[i].language_name = vm.languages[languageIndex[vm.wordlang[i].language_id]].item_name;

                        if (vm.wordlang[i].language_id === 'sy') {
                            syIndex = i;
                        }
                    }

                    vm.wordlang.splice(syIndex, 1);

                    vm.wordlang = vm.wordlang.sort(function (a, b) {
                        return a.language_name.localeCompare(b.language_name, currentLanguage, { sensitivity: 'base' });
                    });

                    for (var i = 0; i < vm.wordlang.length; i++) {
                        wordlangIndex[vm.wordlang[i].language_id] = i;
                    }
                };

                modalWordlangService.load(vm.word_id).then(loaded);
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.word_id)) {
                    vm.modalInstance.dismiss();
                }

                vm.word_id = vm.resolve.word_id;
                vm.autoSave = vm.resolve.autoSave || true;

                languageService.list().then(function (response) {
                    angular.copy(response, vm.languages);

                    for (var i = 0; i < vm.languages.length; i++) {
                        languageIndex[vm.languages[i].item_id] = i;

                        if (vm.languages[i].currentlanguage === '1') {
                            currentLanguage = vm.languages[i].item_id;
                        }
                    }
                });

                load(vm.resolve.word_id);
            };

            var wordIdPromise = null;
            var previousWordId = '';

            vm.wordIdChanged = function (value) {
                if (wordIdPromise !== null) {
                    $timeout.cancel(wordIdPromise);
                }

                if (value === previousWordId) {
                    wordIdPromise = null;

                    load(value);
                } else {
                    previousWordId = value;

                    wordIdPromise = $timeout(vm.wordIdChanged, 500, true, value);
                }
            };

            vm.ok = function () {
                var translations = angular.copy(vm.wordlang);

                angular.forEach(translations, function (trans) {
                    trans.wordlang_name = base64.urlencode(trans.wordlang_name);

                    delete trans.language_name;
                });

                modalWordlangService.update(vm.word_id, translations).then(function () {
                    var currentWordlangName = vm.wordlang[wordlangIndex[currentLanguage]].wordlang_name;

                    translateService.update(vm.word_id, currentWordlangName);

                    vm.modalInstance.close({
                        word_id: vm.word_id,
                        wordlang_name: currentWordlangName
                    });
                }, function (error) {
                    console.log('error when updating translations');
                    console.dir(error);
                })
            };

            vm.dismiss = function () {
                if (vm.autoSave === true) {
                    vm.modalInstance.close({
                        word_id: vm.word_id,
                        wordlang_name: vm.wordlang[wordlangIndex[currentLanguage]].wordlang_name
                    });
                } else {
                    vm.modalInstance.dismiss();
                }
            };

            if (vm.autoSave === true) {
                $scope.$on('modal.closing', function (event, reason) {
                    switch (reason) {
                        case "backdrop click":
                        case "escape key press":
                        case "cancel":
                            event.preventDefault();

                            vm.modalInstance.close({
                                word_id: vm.word_id,
                                wordlang_name: vm.wordlang[wordlangIndex[currentLanguage]].wordlang_name
                            });
                            break;
                    }
                });
            }
        }]
    });
})();
