import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataTaskService } from '@app/core/services/data-task.service';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-progress-modal',
    templateUrl: './progress-modal.component.html',
    styleUrls: ['./progress-modal.component.css'],
})
export class ProgressModalComponent {
    /**
     * Whether all daya is finish loading for the modal.
     */
    private ready = false;

    /**
     * Whether the finishclose checkbox was changed, (useful if 616 resolves slower so the user changes the value first).
     */
    private finishCloseChanged = false;

    /**
     * Map over words to translate.
     */
    public translations: { [key: string]: string } = {
        title: 'ttgrid_save_progress_label',
        error: 'error_occured',
    };

    /**
     * Styling for elements in component.
     */
    public style: Style = {
        title: {},
        button: {},
        invalidtext: {
            whiteSpace: 'break-spaces',
            lineHeight: '3',
        },
    };

    constructor(public dialogRef: MatDialogRef<ProgressModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ProgressModalData, private layoutService: LayoutService, private translateService: TranslateService, private datatask: DataTaskService) {
        this.data.finishclose ??= true;
        this.data.status ??= 'active';

        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['title'].fontSize = info.fontSizes.textSizeXL;
                this.style['button'].height = info.height + 4 + 'px';
            }
        });

        this.loadModalData();
    }

    /**
     * Handles changes on the finish-close checkbox.
     *
     * @param event the new value.
     */
    onFinishCloseChanged(event: boolean | undefined) {
        if (event !== undefined) {
            this.finishCloseChanged = true;
            this.datatask.Post(616, { variablename: this.data.rememberId + '.progress.finishclose', variablevalue: event });
        }
    }

    /**
     * Updates the progressbar status with the given status. Invalid will change the whole modal to danger.
     *
     * @param value the value of the new status to apply.
     */
    public updateStatus(value: 'active' | 'finish' | 'invalid') {
        this.data.status = value;

        if (this.data.finishclose === true && this.ready === true) {
            setTimeout(() => {
                this.dialogRef.close();
            }, 200);
        }
    }

    /**
     * Updates the model value of the progress bar.
     *
     * @param value the value to update the model value with.
     */
    public updateValue(value: number) {
        this.data.value = value;
    }

    /**
     * Updates the invalid text for the progress bar.
     *
     * @param value the text set as invalid text.
     */
    public updateInvalidText(value: string) {
        this.data.invalidtext = value;
    }

    /**
     * Fetches whether the user last wanted to auto-close the progress modal or not.
     */
    private async getShouldClose() {
        if (this.data.rememberId) {
            this.data.finishclose = (await this.datatask.Post(973, { variablename: this.data.rememberId + '.progress.finishclose' }))?.[0]?.variablevalue !== '0';
        }
    }

    /**
     * Translates the words in the component.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.values(this.translations));

        for (let key of Object.keys(translations)) {
            Object.entries(this.translations).forEach(([prop, value]) => {
                if (value === key) {
                    this.translations[prop] = translations[key];
                }
            });
        }
    }

    /**
     * Loads data to the component.
     */
    private async loadModalData() {
        await Promise.all([this.getShouldClose(), this.translate()]);

        this.ready = true;

        if (this.data.status === 'finish' && this.data.finishclose === true && this.finishCloseChanged === false) {
            this.dialogRef.close();
        }
    }
}

/**
 * Represents data required to configure a progress modal.
 */
export interface ProgressModalData {
    /**
     * The model value for the progress bar, should probably start at 0.
     */
    value: number;

    /**
     * The max value for the progress bar.
     */
    max: number;

    /**
     * The status of the progress bar, however invalid will be reflected in the whole modal.
     */
    status?: 'active' | 'finish' | 'invalid';

    /**
     * Invalidtext to display when the modal is invalid.
     */
    invalidtext?: string;

    /**
     * The remember id for the grid the modal is opened from, used to rememember finish when closing setting.
     */
    rememberId: string;

    /**
     * Whether the modal should finish when closing, default is true.
     */
    finishclose?: boolean;
}
