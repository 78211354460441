(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ediMenu', {
        templateUrl: 'views/components/views/ediMenu/ediMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ediMenuService', function(stateService, ediMenuService) {
            var vm = this;
            
            vm.model = {
                p2WebPageMenuList: {
                    records: []
                }
            };
            
            ediMenuService.loadP2WebPageMenuList(stateService.getCurrentName()).then(function (data) {
                angular.copy(data, vm.model.p2WebPageMenuList.records);
            });
        }]
    });
})();