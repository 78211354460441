(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("custactWorkService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 378,
                    parameters: parms || {}
                });
            },
            getProduct: function (edit) {
                return $ihttp.post({
                    method: 379,
                    parameters: edit
                });
            },
            getPrice: function (edit) {
                return $ihttp.post({
                    method: 380,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 398,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 577,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
