(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactCalcJobExit', {
        templateUrl: 'views/components/views/custactCalcJobExit/custactCalcJobExit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custactCalcJobExitService', function($stateParams, stateService, custactCalcJobExitService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;

            vm.model = {
                p2WCustactCalcJobExitGet: {},
                p2WCustactCalcJobExitList: {
                    records: []
                },
                p2WCustactCalcJobExitSetCustactNo: {}
            };
    
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
            
            vm.loadP2WCustactCalcJobExitGet = function () {
                custactCalcJobExitService.genericFunction(884, {
                    labour_no: $stateParams.labour_no,
                    custact_no: $stateParams.custactcalc_custact_no,
                    function_name: $stateParams.function_name
                }).then(function (data) {
                    vm.model.p2WCustactCalcJobExitGet = angular.copy(data[0]);
                });
            };
    
            vm.loadP2WCustactCalcJobExitGet();
            
            vm.loadP2WCustactCalcJobExitList = function () {
                //vm.model.p2WCustactCalcJobExitList.records = [];

                custactCalcJobExitService.genericFunction(882, {
                    labour_no: $stateParams.labour_no,
                    custact_no: $stateParams.custactcalc_custact_no,
                    function_name: $stateParams.function_name
                }).then(function (data) {
                    angular.copy(data, vm.model.p2WCustactCalcJobExitList.records);
                });
            };
    
            vm.loadP2WCustactCalcJobExitList();
    
    		//########################
    		//## SEARCH PROCEDURE CALL
    		//########################
    
            vm.searchP2WCustactCalcJobExitSetCustactNo = function (item) {
                custactCalcJobExitService.genericFunction(883, {
                    labour_no: $stateParams.labour_no,
                    custact_no: item.item_id
                }).then(function (data) {
                    vm.model.p2WCustactCalcJobExitSetCustactNo = angular.copy(data[0]);

                    stateService.go(item.item_state, item.item_parms);
                });
            };
        }]
    });
})();