(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentBatchesService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2ConsignmentBatchList: {
                records: []
            },
            loadP2ConsignmentBatchList: function (status) {
                var deferred = $q.defer();

                var parmsP2ConsignmentBatchList = {
                    method: 433,
                    parameters: {
                        status: status
                    }
                };

                $ihttp.post(parmsP2ConsignmentBatchList).then(function (data) {
                    service.p2ConsignmentBatchList.records = [];

                    if (status != 'CLOSEOPEN') {
                        angular.forEach(data, function (item) {
                            service.p2ConsignmentBatchList.records.push({
                                item_id: item.item_id,
                                item_name: item.item_name,
                                item_state: item.item_state,
                                item_path: item.item_path,
                                item_parms: item.item_parms,
                                orderby: item.orderby,
                                badge: item.badge
                            })
                        });
                    } else {
                        angular.copy(data, service.p2ConsignmentBatchList.records);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();