(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('printArg', {
        templateUrl: 'views/components/views/printArg/printArg.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'rememberService', 'printArgService', 'logisticService', function ($stateParams, stateService, utilityService, rememberService, printArgService, logisticService) {
            
            // ## VARIABLES & DEFINITIONS ##

            const vm = this;
            let webPageName = $stateParams.webpage_name;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let p2ReportDefKeyNo = $stateParams.p2_reportdef_keyno;

            let variableNames = {
                businessco_no: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            vm.model = {
                settingPrint: {},
                selectListCompanies: []
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                printArgService.getEdit({
                    webpage_name: webPageName,
                    argtype: argType,
                    argvalue: argValue,
                    p2_reportdef_keyno: p2ReportDefKeyNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.settingPrint);
                });
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.settingPrint[id]) !== true) return;

                        variableValue = vm.model.settingPrint[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'goToItemState':
                        stateService.go(vm.model.settingPrint.goto_item_state, vm.model.settingPrint.goto_item_parms);
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // ## CANCEL BUTTON FUNCTION ##
    
            vm.abortSelection = function () {
                stateService.back();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.settingPrint.businessco_no) !== true) return;

                        vm.model.settingPrint.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.settingPrint.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.settingPrint.date_fom = startDate;
                vm.model.settingPrint.date_tom = endDate;
                vm.model.settingPrint.dateselector_index = +dateInterval <= 0 ? vm.model.settingPrint.dateselector_index : dateInterval;

                if (vm.model.settingPrint.date_fom) rememberFunc('date_fom');
                if (vm.model.settingPrint.date_tom) rememberFunc('date_tom');
                if (vm.model.settingPrint.dateselector_index) rememberFunc('dateselector_index');
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
