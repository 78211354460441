(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('projectActivityTypes', {
        templateUrl: 'views/components/views/projectActivityTypes/projectActivityTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'projectActivityTypesService', function ($stateParams, $q, utilityService, projectActivityTypesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let projectKeyno = $stateParams.project_keyno;

            vm.model = {
                setting: {},
                itemsListProjectActivityTypes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                projectActivityTypesService.getProjectActivityTypes({ project_keyno: projectKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadProjectActivityTypes();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadProjectActivityTypes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.project_keyno) !== true) return;
                
                return projectActivityTypesService.listProjectActivityTypes({ project_keyno: vm.model.setting.project_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListProjectActivityTypes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
