(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("visitorConfirmService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            visitor_confirm: {
                inout_heading: '',
                visitor_datetime_in: '',
                visitor_datetime_out: '',
                cust_no: '',
                pers_no: '',
                pers_name: '',
                pers_jobtitle: '',
                pers_email: '',
                cust_name: '',
                visit_note: ''
            },
            loadPersonGetVisitor: function (inout, pers_no) {
                var deferred = $q.defer();

                var parms_person_get_visitor = {
                    method: 118,
                    parameters: {
                        inout: inout,
                        pers_no: pers_no
                    }
                };

                $ihttp.post(parms_person_get_visitor).then(function (data) {
                    service.visitor_confirm = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveVisitorConfirm: function () {
                var deferred = $q.defer();

                var parms_visitorconfirm_save = {
                    method: 119,
                    parameters: {
                        inout: $stateParams.inout,
                        inout_heading: service.visitor_confirm.inout_heading,
                        visitor_datetime_in: service.visitor_confirm.visitor_datetime_in,
                        visitor_datetime_out: service.visitor_confirm.visitor_datetime_out,
                        cust_no: service.visitor_confirm.cust_no,
                        pers_no: service.visitor_confirm.pers_no,
                        pers_name: service.visitor_confirm.pers_name,
                        pers_jobtitle: service.visitor_confirm.pers_jobtitle,
                        pers_email: service.visitor_confirm.pers_email,
                        cust_name: service.visitor_confirm.cust_name,
                        visit_note: service.visitor_confirm.visit_note
                    }
                };

                $ihttp.post(parms_visitorconfirm_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();