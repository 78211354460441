//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("custactGanttService", ['$q', '$ihttp', function ($q, $ihttp) {
//        var service = {
//            loaded: false,
//            custact_gantt_list: {
//                custact_no: '',
//                retrievetype: 'child',
//                include_top: '1'
//            },
//            gantt_task: {
//                custact_no: '',
//                custact_no_belongto: '',
//                datetime_start: '',
//                datetime_end: '',
//                note: '',
//                ok: '',
//                orderby: '',
//                summary: '',
//                complete_pst: '',
//                records: []
//            },
//            loadCustactGanttList: function (custact_no, retrievetype, include_top) {
//                console.log('custact_no = ' + custact_no);
//                console.log('retrievetype = ' + retrievetype);
//                console.log('include_top = ' + include_top);

//                var deferred = $q.defer();

//                var parms_custact_gantt_list = {
//                    method: 116,
//                    parameters: {
//                        custact_no: custact_no,
//                        retrievetype: retrievetype,
//                        include_top: include_top
//                    }
//                };

//                console.log('parms_custact_gantt_list');
//                console.dir(parms_custact_gantt_list);
//                console.log(parms_custact_gantt_list);

//                $ihttp.post(parms_custact_gantt_list).then(function (data) {
//                    console.log('sp_ws_p2_custact_gantt_list');
//                    console.dir(angular.toJson(data));
//                    console.dir(data);

//                    service.gantt_task.records.length = 0;

//                    for (var i = 0; i < data.length; i++) {
//                        service.gantt_task.records.push(data[i]);
//                    }

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            updateInfo: function () {
//                var deferred = $q.defer();

//                console.log('service.gantt_task.records[0].custacts = ' + service.gantt_task.records[0].custacts);

//                var gantt_save = {
//                    method: 133,
//                    parameters: {
//                        gantt_list: service.gantt_task.records[0].custacts,
//                    }
//                };

//                console.log('gantt_save');
//                console.dir(gantt_save);
//                console.log(gantt_save);

//                $ihttp.post(gantt_save).then(function () {
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            }
//        }

//        return service;
//    }]);
//})();