(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('poFinishProdCompsBs', {
        templateUrl: 'views/components/views/poFinishProdCompsBs/poFinishProdCompsBs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishProdCompsBsService', function ($stateParams, poFinishProdCompsBsService) {
            const vm = this;
            let p2PoFinishProdCompKeyno = $stateParams.p2_pofinishprodcomp_keyno;

            vm.model = {
                itemsListComponents: []
            };

            let loadPoFinishProdCompsBs = function () {
                poFinishProdCompsBsService.listPoFinishProdCompsBs({ p2_pofinishprodcomp_keyno: p2PoFinishProdCompKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListComponents);
                });
            };

            vm.$onInit = function () {
                loadPoFinishProdCompsBs();
            };
        }]
    });
})();
