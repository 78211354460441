import { Injectable } from '@angular/core';
import { WidgetTextConfig, WidgetTextLine } from './widget-text/widget-text.component';
import { isObject } from 'angular';
import { WidgetChartConfig } from './widget/widget.component';
import { WidgetNumberVariable } from './jlr-test.component';

@Injectable({
    providedIn: 'root',
})
export class WidgetService {
    constructor() {}

    public isWidgetTextConfig(data: unknown): data is WidgetTextConfig {
        if (data?.hasOwnProperty('model')) return true;
        return false;
    }

    public isWidgetTextLine(data: unknown): data is WidgetTextLine {
        if (data?.hasOwnProperty('value')) return true;
        return false;
    }

    public isWidgetTextLineArray(data: unknown): data is WidgetTextLine[] {
        if (data instanceof Array) {
            const validElements = data.filter((obj) => obj?.hasOwnProperty('value') === true);
            if (validElements.length === data.length) return true;
        }

        return false;
    }

    public isValidWidgetTextConfig(data: unknown): data is WidgetTextConfig | string {
        return true;
    }

    public isChartConfig(value: unknown): value is WidgetChartConfig {
        if (isObject(value) && value.hasOwnProperty('chartType') && value.hasOwnProperty('data')) return true;
        return false;
    }

    public isWidgetNumberVariable(data: unknown): data is WidgetNumberVariable {
        if (data?.hasOwnProperty('type') && isObject(data)) {
            let descriptor = Object.getOwnPropertyDescriptor(data, 'type');
            if (descriptor?.value === 'number') return true;
        }
        return false;
    }
}
