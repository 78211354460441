(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('incomingCall', {
        templateUrl: 'views/components/views/incomingCall/incomingCall.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'incomingCallService', function ($stateParams, incomingCallService) {
            var vm = this;
            
    		vm.model = {
    			phoneNo: $stateParams.phoneno,
    			call: {},
    			itemsListFindPersons: []
    		};
            
    		incomingCallService.loadIncomingCallGet(vm.model.phoneNo).then(function (data) {
    			vm.model.call = angular.copy(data[0]);
                
    			loadFindPersons();
    		});
            
    		var loadFindPersons = function () {
    			if (angular.isUndefined(vm.model.call.phoneno) === true) return;
    			if (vm.model.call.phoneno === null) return;
    			if (vm.model.call.phoneno.trim().length < 1) return;
                
    			vm.model.itemsListFindPersons = [];
                
    			incomingCallService.loadFindPersonList(vm.model.call.phoneno).then(function (data) {
    				angular.copy(data, vm.model.itemsListFindPersons);
    			});
    		};
    	}]
    });
})();