import { ServiceBase } from "./service-base";

export interface IData<T> {
    records: T[]
}

export abstract class DataServiceBase extends ServiceBase {
    constructor() {
        super();
    }
}
