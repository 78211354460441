(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStateEquipments', {
        templateUrl: 'views/components/views/oeeStateEquipments/oeeStateEquipments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'oeeStateEquipmentsService', function ($stateParams, oeeStateEquipmentsService) {
            var vm = this;
            
            oeeStateEquipmentsService.loadP2OeeStateEquipmentList($stateParams.argtype, $stateParams.argvalue).then(function () {
                vm.p2_oeestate_equipment_list = oeeStateEquipmentsService.p2_oeestate_equipment_list;
            });
        }]
    });
})();