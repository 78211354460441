(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2StatSourceEdit', {
        templateUrl: 'views/components/views/p2StatSourceEdit/p2StatSourceEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2StatSourceEditService', 'tailorAccessesService', function ($stateParams, stateService, p2StatSourceEditService, tailorAccessesService) {
            var vm = this;
            
            vm.model = {
                btnListTailorAccess: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'list', item_func: 'list_item', glyph: 'glyphicon-menu-hamburger', color: 'primary' }
                ],
                p2StatSourceGet: {},
                selectListTailorAccesses: []
            };
            
            var loadP2StatSourceGet = function () {
                p2StatSourceEditService.genericFunction(808, {
                    p2_statsource_keyno: $stateParams.p2_statsource_keyno
                }).then(function (data) {
                    vm.model.p2StatSourceGet = angular.copy(data[0]);
                });
            };
            
        	loadP2StatSourceGet();
            
            tailorAccessesService.listTailorAccesses().then(function (data) {
                angular.copy(data, vm.model.selectListTailorAccesses);
            });
            
            // #region DIRECTION BUTTON FUNCTIONS
            
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'tailoraccesses':
                        go();
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion DIRECTION BUTTON FUNCTIONS
            
            vm.goToTranslateEditHeading = function () {
                stateService.go('translateedit', {
                    item_id: vm.model.p2StatSourceGet.word_id_heading
                });
            };
            
            vm.goToTranslateEditDescription = function () {
                stateService.go('translateedit', {
                    item_id: vm.model.p2StatSourceGet.word_id_description
                });
            };
            
            // #region BUTTON LIST FUNCTIONS
            
            vm.btnListFuncTailorAccess = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.p2StatSourceGet.tailoraccess_keyno = '0';
                        break;
                    case 'list_item':
                        vm.goToFunc('tailoraccesses');
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion BUTTON LIST FUNCTIONS
            
            vm.saveP2StatSourceEdit = function () {
                p2StatSourceEditService.genericFunction(809, vm.model.p2StatSourceGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();