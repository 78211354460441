(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productLang', {
        templateUrl: 'views/components/views/productLang/productLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productLangService', function ($stateParams, stateService, productLangService) {
            let vm = this;
            let prodLangKeyno = $stateParams.prodlang_keyno
            
            vm.model = {
                edit: {}
            };
            
            let loadEdit = function () {
                productLangService.getProductLanguage({ prodlang_keyno: prodLangKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            vm.saveChanges = function () {
                productLangService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
