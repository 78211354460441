<!-- <tt-grid [ttOptions]="invoices"></tt-grid>
<tt-grid [(ttOptions)]="stockMinMaxAdjustGrid"></tt-grid> -->
<!-- <tt-grid [(ttOptions)]="stockMinMaxAdjustGrid"></tt-grid> -->
<!-- <h2 class="tt-header" style="font-size: 2.4rem; padding: 0; border: none; padding-left: 10px">Testside</h2> -->
<!-- <tt-grid [ttOptions]="laboursGrid"></tt-grid> -->
<!-- <tt-card class="col-xs-12 p-10" ttHeading="Grid"> </tt-card> -->
<!-- <h3 class="col-xs-12">Grid -Aktivitetsstyring</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="activityManagementGrid"></tt-grid> -->
<!-- 
<h3 class="col-xs-12">Grid - Restordre</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="gridOrderlineBackorders"></tt-grid> -->
<!-- 
<!-- <h3 class="col-xs-12">Grid - Leverandørreskontrok</h3> -->
<tt-grid class="col-xs-12 sp-0" [ttOptions]="acsupptranses" (ttOpenModal)="log($event)"></tt-grid>
<!-- <button (click)="logStuff()">dirty payouts</button>
<h3 class="col-xs-12">Grid - Utbetalinger</h3>
<tt-grid class="col-xs-12 sp-0" [(ttOptions)]="payouts"></tt-grid>

<h3 class="col-xs-12">Grid - Lagereksport test</h3>
<div>
    <tt-grid class="col-xs-12 sp-0" [ttOptions]="gridxexport"></tt-grid>
</div>

<h3 class="col-xs-12">Grid - Kontroll av timer</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="powtimesGrid"></tt-grid> -->

<!-- <button (click)="hideButton = hideButton === true ? 'hidden' : !hideButton">{{ hideButton ? "Skjul" : "Vis" }}</button> -->

<h3 class="col-xs-12">Grid - Bilagsbehandling</h3>
<tt-grid class="col-xs-12 sp-0" [(ttOptions)]="accountingprocessingGrid"></tt-grid>
<!-- 
<h3 class="col-xs-12">Grid - Aktiviteter</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="activityGrid"></tt-grid> -->

<ng-template #helloWorld><h1>Hello world!</h1></ng-template>
<button [ttPopover]="helloWorld">click</button>
<tt-card class="col-xs-12 p-10" ttHeading="ID-porten">
    <tt-button class="col-xs-4 sp-2" ttText="Bank Id logg inn" (ttClick)="log(accountingprocessingGrid)" [ttTranslateText]="false"></tt-button>
    <tt-button class="col-xs-4 sp-2" larttText="Hent bruker" (ttClick)="getUser()" [ttTranslateText]="false"></tt-button>
    <tt-button class="col-xs-4 sp-2" ttText="Bank Id logg ut" (ttClick)="logoutIdPorten()" [ttTranslateText]="false"></tt-button>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="ButtonMulti">
    <tt-button-multi class="col-xs-4 sp-0" ttText="save">
        <tt-button ttIcon="far fa-cog"></tt-button>
        <tt-overflow-menu>
            <tt-overflow-menu-item ttText="user" ttIcon="far fa-user" (ttClick)="log('clicked')"></tt-overflow-menu-item>
            <tt-overflow-menu-item ttText="settings" ttIcon="far fa-cog" (ttClick)="log('clicked')"></tt-overflow-menu-item>
            <tt-overflow-menu-item [ttHasDivider]="true" ttText="delete" [ttDanger]="true" ttIcon="far fa-backspace" (ttClick)="log('clicked')"></tt-overflow-menu-item>
        </tt-overflow-menu>
    </tt-button-multi>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="ButtonSave">
    <tt-button-save ttPrinter="print"></tt-button-save>
    <tt-button-save ttText="save" ttBadge="52" ttTextAlign="left"></tt-button-save>
    <tt-button-save [ttStyle]="{ button: { backgroundColor: 'red' } }" ttDisabled></tt-button-save>
    <tt-button-save></tt-button-save>
    <tt-button-save ttBadge="2" ttIcon="fas fa-pencil" ttIconPullRight></tt-button-save>
    <tt-button-save ttBadge="2" ttIcon="fas fa-pencil" ttIconAlign="left"></tt-button-save>
    <tt-button-save ttIcon="fas fa-pencil" ttSpin></tt-button-save>
    <tt-button-save ttLoading></tt-button-save>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Button">
    <div class="col-xs-12 sp-0">
        <tt-button class="col-xs-2 sp-2" ttType="primary" ttText="Primary" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="secondary" ttText="Secondary" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="danger" ttText="Danger" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="success" ttText="Success" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="warning" ttText="Warning" ttTranslateText="false"></tt-button>
        <tt-button class="col-xs-2 sp-2" ttType="link" ttText="Link" ttTranslateText="false"></tt-button>
    </div>

    <tt-button ttPrinter="print"></tt-button>
    <tt-button ttText="save" ttBadge="52" ttTextAlign="left"></tt-button>
    <tt-button [ttStyle]="{ button: { backgroundColor: 'red' } }" ttDisabled></tt-button>
    <tt-button></tt-button>
    <tt-button ttBadge="2" ttIcon="fas fa-pencil" ttIconPullRight></tt-button>
    <tt-button ttBadge="2" ttIcon="fas fa-pencil" ttIconAlign="left"></tt-button>
    <tt-button ttIcon="fas fa-pencil" ttSpin></tt-button>
    <tt-button ttLoading></tt-button>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Textarea">
    <tt-textarea ttLabel="user" [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
    <tt-textarea ttLabel="user" ttReadonly [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
    <tt-textarea ttLabel="user" ttSublabel="Hjelpetekst" ttDisabled [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
    <tt-textarea ttLabel="user" ttInvalid ttInvalidText="Invalid" [(ttModel)]="textarea" (ttModelChange)="log(textarea)"></tt-textarea>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Select">
    <tt-select ttLabel="user" ttSublabel="help" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" [ttStyle]="{ label: { color: 'red' } }" [ttButtons]="formButtons"></tt-select>
    <tt-select ttLabel="user" ttSublabel="help" ttInvalid ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" [ttButtons]="formButtons"></tt-select>
    <tt-select ttLabel="user" ttSublabel="help" [ttInvalid]="true" ttInvalidText="Invalid" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" [ttButtons]="formButtons"></tt-select>
    <tt-select ttLabel="user" ttSublabel="help" ttInvalidText="Invalid" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" [ttButtons]="formButtons"></tt-select>
    <tt-select ttLabel="user" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" [ttButtons]="formButtons"></tt-select>
    <tt-select ttLabel="user" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id" ttHideArrow></tt-select>
    <tt-select ttLabel="user" ttDisabled="true" ttModel="2" [ttData]="selectData" ttDataName="name" ttDataId="id"></tt-select>
    <tt-select ttLabel="user" ttReadonly="true" ttModel="2" (ttModelChange)="log($event)" [ttData]="selectData" ttDataName="name" ttDataId="id" [ttButtons]="formButtons"></tt-select>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Fileuploader">
    <tt-file-uploader ttLabel="attachment" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttAccept=".pdf"></tt-file-uploader>
    <tt-file-uploader ttLabel="attachment" [(ttFiles)]="files" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttMultiple></tt-file-uploader>
    <tt-file-uploader ttLabel="attachment" [(ttFiles)]="files" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttMultiple ttDropZone></tt-file-uploader>
    <tt-file-uploader ttLabel="attachment" [(ttFiles)]="files" ttSublabel=".jpg, .png, .webp" ttTranslateSublabel="false" ttAccept=".pdf" ttMultiple ttDropZone></tt-file-uploader>
</tt-card>

<!-- <h3>Grid: Kontroll av timer</h3>
<tt-grid class="col-xs-12 sp-0" [ttOptions]="grid"></tt-grid> -->

<tt-card class="col-xs-12 p-10" ttHeading="OverflowMenu">
    <tt-overflow-menu class="pull-right">
        <tt-overflow-menu-item ttText="user" ttIcon="fal fa-user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="settings" ttIcon="fal fa-cog"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="user"></tt-overflow-menu-item>
        <tt-overflow-menu-item ttText="delete" ttIcon="fal fa-backspace" ttHasDivider ttDanger (ttClick)="log('clicked')"></tt-overflow-menu-item>
    </tt-overflow-menu>
</tt-card>

<tt-card>
    <tt-progress-bar ttLabel="user" ttLabelView="top" ttSublabel="16 av 100" [ttTranslateSublabel]="false" [ttModel]="text4"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" [ttTranslateSublabel]="false" [ttModel]="text4"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" [ttTranslateSublabel]="false" ttIndeterminate [ttModel]="columns"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" ttStatus="finish" [ttTranslateSublabel]="false" [ttModel]="text4"></tt-progress-bar>
    <tt-progress-bar ttLabel="user" ttSublabel="16 av 100" ttStatus="invalid" [ttTranslateSublabel]="false" ttIndeterminate [ttModel]="columns"></tt-progress-bar>
    <tt-progress-bar
        ttLabel="user"
        ttSublabel="16 av 100"
        ttStatus="invalid"
        ttInvalidText="Noe gikk galt"
        [ttTranslateInvalidText]="false"
        [ttTranslateSublabel]="false"
        ttIndeterminate
        [ttModel]="columns"
    ></tt-progress-bar>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="MultiSelect">
    <!-- <tt-multi-select
        ttLabel="user"
        [ttData]="this.selectData"
        [ttFilterValue]="textValue"
        (ttFilterValueChange)="textValue = $event"
        ttDataId="id"
        ttDataName="name"
        ttDataSelected="selected"
        ttAllSelected="false"
    ></tt-multi-select> -->
    <tt-multi-select ttLabel="user" ttInvalid [ttData]="this.selectData" [(ttFilterValue)]="textValue" ttDataId="id" ttDataName="name" ttDataSelected="selected" ttAllSelected></tt-multi-select>
    <tt-multi-select ttLabel="user" [ttData]="this.selectData" [(ttFilterValue)]="textValue" ttDataId="id" ttDataName="name" ttDataSelected="selected" ttAllSelected></tt-multi-select>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Datetime">
    {{ date | date }}
    <tt-datetime ttLabel="user" [ttModel]="date" (ttModelChange)="date = $event; log($event)" ttMinutePrecision="30" ttAllowEmptyDate="true"></tt-datetime>
    <!-- <tt-datetime ttLabel="user" [ttModel]="date" (ttModelChange)="log($event)" ttMinutePrecision="30"></tt-datetime> -->
    <tt-datetime ttInvalid ttTabable="false" ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="up"></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="down"></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttMinutePrecision="30" ttRoundTime="false"></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttReadonly></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttDisabled></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttHideTime></tt-datetime>
    <tt-datetime ttLabel="user" (ttModelChange)="log($event)" ttHideDate></tt-datetime>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Tabs">
    <tt-tabs [ttTabs]="tabs" [(ttModel)]="selectedTab" (ttModelChange)="onTabChange($event)"></tt-tabs>
    <ng-container [ngSwitch]="selectedTab">
        <div *ngSwitchCase="'EDIT'">
            <h2>Rediger</h2>
        </div>
        <div *ngSwitchCase="'CONTACTS'">
            <h2>Kontaktpersoner</h2>
        </div>
        <div *ngSwitchCase="'EMAIL'">
            <h2>E-poster</h2>
        </div>
        <div *ngSwitchCase="'PROJECTS'">
            <h2>Prosjekter</h2>
        </div>
    </ng-container>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Search">
    <p>Live søk for kunde:</p>
    <tt-search
        ttInvalid
        ttLabel="search"
        [(ttModel)]="customerSearchtext"
        [ttSearch]="search"
        (ttOnSelected)="onListboxSelect($event)"
        (ttOnClear)="onClear()"
        ttFilterOperation="none"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-search>

    <p>Filtrere statisk data (typeahead):</p>
    <tt-search ttLabel="search" [(ttModel)]="textValue" [ttData]="data" ttDataName="text" ttDebounceTime="0" ttMinLength="0" ttFilterOperation="filter"></tt-search>

    <p>Sortere statisk data (typeahead):</p>
    <tt-search ttLabel="search" [(ttModel)]="textValue" [ttData]="data" ttDataName="text" ttDebounceTime="0" ttMinLength="0" ttFilterOperation="sort"></tt-search>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Checkbox">
    <p>{{ check === "1" ? "Checkbox med enveisbinding er avkrysset, verdi er: " + check : "Checkbox med enveisbinding er ikke avkrysset, verdi er: " + check }}</p>
    <tt-checkbox ttLabel="user" [ttModel]="check"></tt-checkbox>
    <p>{{ check2 === "true" ? "Checkbox med toveisbinding er avkrysset, verdi er: " + check2 : "Checkbox med toveisbinding er ikke avkrysset, verdi er: " + check2 }}</p>
    <tt-checkbox ttLabel="user" [(ttModel)]="check2" ttTrueValue="true" ttFalseValue="false"></tt-checkbox>
    <tt-checkbox ttInvalid ttLabel="user" [(ttModel)]="check2" ttTrueValue="true" ttFalseValue="false"></tt-checkbox>
</tt-card>
<tt-card class="col-xs-12 p-10" ttHeading="Input">
    <tt-input
        ttInvalid
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttBindFocus="false"
        ttMaxLength="20"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input>
    <tt-input class="col-xs-12 sp-0" ttLabel="user" ttRequired [(ttModel)]="text1" (ttModelChange)="onTextChanged($event)" ttFormatNumber="true" ttAllowEmpty="false" ttDecimals="2"></tt-input>
    <tt-input
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttLabelView="top"
        ttTranslateLabel="false"
        ttDisabled
        [(ttModel)]="text2"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="false"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input>
    <tt-input
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttSublabel="user"
        ttTranslateSublabel
        ttRequired
        ttReadonly
        [(ttModel)]="text3"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="true"
        ttAllowEmpty="true"
        ttDecimals="-3"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input>
    <tt-input
        class="col-xs-12 sp-0"
        ttLabel="user"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="InputNumber">
    <tt-input-number
        ttInvalid
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttBindFocus="false"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttRequired
        [(ttModel)]="text1"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="true"
        ttAllowEmpty="false"
        ttDecimals="2"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttLabelView="top"
        ttTranslateLabel="false"
        ttDisabled
        [(ttModel)]="text2"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="false"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        ttSublabel="user"
        ttTranslateSublabel
        ttRequired
        ttReadonly
        [(ttModel)]="text3"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="true"
        ttAllowEmpty="true"
        ttDecimals="-3"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input-number>
    <tt-input-number
        class="col-xs-12 sp-0"
        ttLabel="user"
        [(ttModel)]="text4"
        (ttModelChange)="onTextChanged($event)"
        ttFormatNumber="false"
        ttAllowEmpty="true"
        ttDecimals="3"
        [ttButtonParms]="newVariables"
        [ttButtons]="formButtons"
    ></tt-input-number>
</tt-card>

<tt-card class="col-xs-12 p-10" ttHeading="Listbox">
    <p>Listbox popup med toveisbinding</p>
    <input aria-label="test1" ttListboxPopup ttLabel="test" [(ngModel)]="textValue" (ngModelChange)="textValue = $event" [ttData]="data" ttMinLength="3" />

    <p>Listbox popup med enveisbidning</p>
    <input aria-label="test2" ttListboxPopup ttLabel="test" [ttData]="data" [ngModel]="textValue" (ngModelChange)="textValue = $event" />

    <p>Listbox component</p>
    <tt-listbox ttLabel="test" [ttData]="data" ttDataName="text"></tt-listbox>
</tt-card>

<button class="col-xs-2" class="tt-button tt-button--primary" (click)="toggleEditMode()">{{ editMode ? "Avbryt" : "Rediger" }}</button>
<!-- 
<div class="tt-input__group">
    <input class="col-xs-2" [(ngModel)]="variables.testVariable" (ngModelChange)="onVariableChange($event)" />
    <input class="col-xs-2" [(ngModel)]="newVariables['testVariable_2'].value" (ngModelChange)="onVariable2Change($event)" type="number" />
    <input
        *ngIf="widgetService.isWidgetNumberVariable(newVariables['testVariable_2'])"
        class="col-xs-2"
        [(ngModel)]="newVariables['testVariable_2'].decimals"
        (ngModelChange)="onVariable3Change($event)"
        type="text"
    />
    <select *ngIf="widgetService.isWidgetNumberVariable(newVariables['testVariable_2'])" [(ngModel)]="newVariables['testVariable_2'].displayUnits" (ngModelChange)="onVariable4Change($event)">
        <option>none</option>
        <option>thousands</option>
        <option>millions</option>
        <option>billions</option>
        <option>trillions</option>
    </select>
</div> -->
<tt-dashboard
    class="col-xs-12 sp-0"
    [(widgets)]="widgets"
    (widgetsChange)="onWidgetsChange($event)"
    [numberOfColumns]="columns"
    [numberOfRows]="rows"
    [editMode]="editMode"
    [rowHeight]="rowHeight"
></tt-dashboard>
<!-- </tt-page> -->
