(function () {
	'use strict';
	
	angular.module("imApp").factory("purchRequestCreateService", ['$ihttp', function ($ihttp) {
		var service = {
			getPurchRequestCreate: function (parms) {
				return $ihttp.post({
					method: 2860,
					parameters: parms || {}
				});
			},
			listSuppliers: function (parms) {
				return $ihttp.post({
					method: 2862,
					parameters: parms || {}
				});
			},
			createObj: function (setting) {
				return $ihttp.post({
					method: 2863,
					parameters: setting
				});
			}
		};

		return service;
	}]);
})();