(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactPlanningEdit', {
        templateUrl: 'views/components/views/custactPlanningEdit/custactPlanningEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', function($stateParams) {
            var vm = this;
            vm.custactplanning_keyno = $stateParams.custactplanning_keyno;
        }]
    });
})();