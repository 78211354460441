(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pickJobGrpBatches', {
        templateUrl: 'views/components/views/pickJobGrpBatches/pickJobGrpBatches.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'modalService', 'pickJobGrpBatchesService', 'logisticService', function (stateService, utilityService, modalService, pickJobGrpBatchesService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            var resetPickJobGroup = function () {
                vm.model.setting.pickjobgrp_keyno = '0';
            };

            vm.model = {
                pickJobGroupButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: resetPickJobGroup } }
                ],
                setting: {},
                selectBusinessCompanies: [],
                selectListStockPlaces: [],
                selectListPickJobGroups: [],
                gridReady: false,
                updating: false,
                lockedCreate: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            pickJobGrpBatchesService.getPickJobGroupBatches().then(function (data) {
                angular.copy(data[0], vm.model.setting);

                loadStockPlaces();
                loadPickJobGroups();

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
		        	vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

                loadGrid();
            });

            var loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.deliverydate) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.pickjobgrp_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_finished_stocktransfers, true) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: vm.model.setting.p2_datatask_keyno,
                    parameters: vm.model.setting
                };

                vm.model.gridReady = true;
            };

            logisticService.listCompanies().then(function (data) {
                angular.copy(data, vm.model.selectBusinessCompanies);
            });

            var loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                pickJobGrpBatchesService.listStockPlaces({ businessco_no: vm.model.setting.businessco_no }).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            var loadPickJobGroups = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                var parms = {
                    stockplace_id: vm.model.setting.stockplace_id,
                    add_all: '1'
                };

                vm.model.selectListPickJobGroups = [];

                pickJobGrpBatchesService.listPickJobGroups(parms).then(function (data) {
                    vm.model.setting.pickjobgrp_keyno = '0';
                    angular.copy(data, vm.model.selectListPickJobGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        loadPickJobGroups();
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'show_finished_stocktransfers':
                        vm.model.setting.show_finished_stocktransfers = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_finished_stocktransfers, true) !== true) return;

                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON REFRESH PROCEDURE FUNCTION CALL

            vm.updateChanges = function () {
                vm.model.updating = true;

                pickJobGrpBatchesService.updateObj(vm.model.setting).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updating = false;
                    }
                });
            };

            // #endregion BUTTON REFRESH PROCEDURE FUNCTION CALL

            // #region BUTTON CREATE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                pickJobGrpBatchesService.createObj(vm.model.setting).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCreate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCreate = false;
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            // #endregion BUTTON CREATE PROCEDURE FUNCTION CALL
        }]
    });
})();
