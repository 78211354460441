import { EventEmitter, Injectable } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { User, UserManager, UserManagerSettings } from 'oidc-client';

@Injectable({
    providedIn: 'root',
})
export class IdportenService {
    /**
     * The currently logged in user.
     */
    private user: User | null = null;

    /**
     * The OIDC user manager.
     */
    private manager?: UserManager;

    private onBehalfOf?: string;

    constructor(private datatask: DataTaskService) {
        // this.createUserManagerSettings();
    }

    public tokenExpired = new EventEmitter();

    private removeUserIfTokenExipred() {
        const checkInterval = setInterval(() => {
            console.log('check if token is expired');
            const user = this.getUser();

            if (user) {
                if (new Date(user.expires_at * 1000) < new Date()) {
                    this.tokenExpired.emit();
                    this.manager?.removeUser();
                    clearInterval(checkInterval);
                }
            } else {
                this.tokenExpired.emit();
                this.manager?.removeUser();
                clearInterval(checkInterval);
            }
        }, 60000);
    }

    /**
     * Returns `true` if a user is logged in, `false` if not.
     *
     * @returns `true` if a user is logged in, `false` if not.
     */
    public isLoggedIn() {
        if (this.user !== null && this.user.access_token && !this.user.expired) {
            return true;
        }

        localStorage.removeItem(this.getStorageId());
        return false;
    }

    /**
     * Returns the openid user. If no user is logged in, `null` is returned.
     *
     * @returns the current openid user, `null` if no user is logged in.
     */
    public getUser(): null | User {
        const storageString = localStorage.getItem(this.getStorageId());

        if (storageString) {
            return User.fromStorageString(storageString);
        }

        return null;
    }

    /**
     * Loads the logged in user to the service.
     *
     * @returns a void promise which resolves once the user is loaded.
     */
    public async loadUser(): Promise<void> {
        if (this.manager) {
            this.user = await this.manager.getUser();
        }
    }

    /**
     * Redirects to the sign in page.
     *
     * @returns void promise which fullfills when the user has logged in.
     */
    public async login(): Promise<void> {
        if (this.manager) {
            this.setRedirectLink();
            return this.manager?.signinRedirect({ response_mode: 'query', extraQueryParams: { onbehalfof: this.onBehalfOf } });
        }
    }

    /**
     * Redirects to the sign in page and loads the signed in user to the service.
     *
     * @return void promise which fullfills when the user has signed in.
     */
    public async completeLogin(): Promise<void> {
        if (this.manager) {
            const response = await this.manager?.signinRedirectCallback();

            // @ts-ignore
            if (response.profile.aud_onbehalfof !== this.onBehalfOf) {
                this.user = null;
                this.manager.removeUser();
                this.tokenExpired.emit();
                throw Error('Something went wrong, recieved wrong behalf of');
            } else {
                this.user = response;
            }
        }
    }

    /**
     * Logs out the current user.
     *
     * @returns empty promise.
     */
    public logout() {
        this.setRedirectLink();
        return this.manager?.signoutRedirect({ response_mode: 'query' });
    }

    /**
     * Removes the logged in user from oidc client.
     */
    public async completeLogout() {
        await this.manager?.signoutRedirectCallback();
        await this.manager?.removeUser();
        this.user = null;
    }

    /**
     * Stores the current touchtime link for redirect link in local storage.
     */
    private setRedirectLink() {
        let credentials = {
            companyId: '',
            userId: '',
            password: '',
        };

        var absUrl = window.location.href;

        var pos = absUrl.indexOf('/userlink/');

        // remove userlink if it is there
        if (pos > 0) {
            absUrl = absUrl.substring(0, pos);
        } else {
            pos = absUrl.indexOf('/' + credentials.companyId + '/' + credentials.userId + '/' + credentials.password);

            if (pos > 0) {
                absUrl = absUrl.substring(0, pos);
            }
        }
        console.log('absUrl :>> ', absUrl);
        localStorage.setItem('signinRedirect', absUrl);
    }

    /**
     * Creates the user manager settings for the id porten service.
     */
    public async createUserManagerSettings(businessco_no: string) {
        const branchId = window.location.host.split('.')[0];

        const response: any = await this.datatask.Post(3322, { branch_id: branchId, businessco_no: businessco_no });

        this.onBehalfOf = response[0].onbehalfof;
        delete response[0].onbehalfof;
        const config: UserManagerSettings = {
            ...response[0],
            loadUserInfo: response[0].loadUserInfo === '1' ? true : false,
            revokeAccessTokenOnSignout: response[0].revokeAccessTokenOnSignout === '1' ? true : false,
        };

        this.manager = new UserManager(config);
        this.manager.storeUser = async (user: User) => {
            if (user) {
                const storageString = user.toStorageString();
                this.removeUserIfTokenExipred();

                // @ts-ignore
                return localStorage.setItem(this.getStorageId(), storageString);
            } else {
                // @ts-ignore
                return localStorage.removeItem(this.getStorageId());
            }
        };

        this.manager.getUser = async () => {
            // @ts-ignore
            const storageString = localStorage.getItem(this.getStorageId());

            if (storageString) {
                console.dir(storageString);
                return User.fromStorageString(storageString);
            }

            return null;
        };

        this.manager.removeUser = async () => {
            return localStorage.removeItem(this.getStorageId());
        };

        this.manager.events.addUserLoaded(this.loadUser);

        this.removeUserIfTokenExipred();
    }

    private getStorageId() {
        return 'oidc.user.idporten';
    }
}
