(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttValidatorModal', {
        templateUrl: 'views/components/framework/ttValidatorModal/ttValidatorModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$q', 'layoutService', 'translateService', 'modalService', function ($q, layoutService, translateService, modalService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            var onDestroy = [];

            vm.fontSize = '14px';

            vm.header = {
                //marginBottom: '10px',
                //padding: '21px'
                //fontSize: 0
            };

            vm.translations = {
                //tt_new_order_special_delivery_driver: '',
                //tt_new_order_special_delivery_driver_update_warning: '',
                tt_generic_modal_warning_title: '',
                tt_nothing_will_be_saved_warning_header: '',
                tt_nothing_will_be_saved_warning_message: ''
            };

            vm.items = [];

            // #endregion VARIABLES & DEFINITIONS

            // #region MODAL FUNCTIONS

            vm.save = function () {
                vm.modalInstance.close(vm.items);
            };

            vm.dismiss = function () {
                modalService.show({
                    type: 'warning',
                    title: vm.translations.tt_nothing_will_be_saved_warning_header,
                    message: vm.translations.tt_nothing_will_be_saved_warning_message,
                    buttons: [{
                        label: 'OK',
                        cssClass: 'btn-primary',
                        action: function (dialogItself) {
                            dialogItself.close();
                            vm.modalInstance.dismiss();
                        }
                    }, {
                        label: 'Cancel',
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                        }
                    }]
                });
            };

            // #endregion MODAL FUNCTIONS

            // #region SERVICES

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #endregion SERVICES

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                angular.forEach(vm.resolve.parameters, function (item) {
                    vm.items.push(item);
                });
                console.dir(vm.items);
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
