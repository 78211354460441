(function () {
    'use strict';

    angular.module("imApp").factory("ttDatasourceFactory", ['$timeout', '$q', '$ihttp', 'eventService', 'ttDynamicGridManager', function ($timeout, $q, $ihttp, eventService, ttDynamicGridManager) {
        var create = function (options) {
            var deferred = $q.defer();

            $ihttp.post({
                method: 2418,
                parameters: {
                    state: 'init',
                    keyno: options.keyno,
                }
            }).then(function (response) {
                deferred.resolve(new datasource(options, response));
            }, function (error) {
                throw error;
            });

            return deferred.promise;
        };

        function setItem(vm, newItem) {
            angular.forEach(newItem, function (val, key) {
                if (angular.isDefined(vm.item[key])) {
                    vm.item[key] = val;
                }
            });

            angular.forEach(vm.dataChanged, function (listener) {
                listener(vm.datasource_id, vm.item);
            });

            eventService.trigger(vm._rootId + ':' + vm.keyno + ':changed', {
                all: true,
                value: vm.item
            });
        };

        function waitForReady(vm) {
            let deferred = $q.defer();

            let wait = function () {
                ttDynamicGridManager.isReady(vm._rootId, vm.grid.name).then(function (response) {
                    if (response !== true) {
                        $timeout(wait);
                    } else {
                        deferred.resolve();
                    }
                });
            };

            wait();

            return deferred.promise;
        };

        function waitForRows(vm) {
            let deferred = $q.defer();

            let wait = function () {
                ttDynamicGridManager.hasRows(vm._rootId, vm.grid.name).then(function (response) {
                    if (response !== true) {
                        $timeout(wait);
                    } else {
                        deferred.resolve();
                    }
                });
            };

            waitForReady(vm).then(wait);

            return deferred.promise;
        };

        // constructor
        function datasource(options, data) {
            if (angular.isUndefined(options)) throw 'datasource.constructor: options is undefined';
            if (angular.isObject(options) !== true) throw 'datasource.constructor: options is not an object';
            if (angular.isUndefined(data)) throw 'datasource.constructor: data is undefined';
            if (angular.isObject(data) !== true) throw 'datasource.constructor: data is not an object';
            if (angular.isUndefined(data.schema)) throw 'datasource.constructor: data.schema is undefined';
            if (angular.isUndefined(data.parameters)) throw 'datasource.constructor: data.parameters is undefined';

            var vm = this;

            vm.grid = {
                active: false,
                name: ''
            };

            vm.parameters = [];

            let dsParms = [];
            let destroy = [];

            if (angular.isObject(data.grid)) {
                vm.grid.active = true;
                vm.grid.name = data.grid.name
            }

            vm.onDestroy = function () {
                eventService.destroy(destroy);
            };

            $.extend(vm, options);

            angular.copy(data.parameters, vm.parameters);

            if (vm.parameters.length > 0) {
                angular.forEach(vm.parameters, function (parm) {
                    if (parm.parm_type === 'datasource' && parm.parm_source_keyno > 0 && parm.parm_name.length > 0) {
                        dsParms.push(parm);

                        destroy.push(eventService.on(vm._rootId + ':' + parm.parm_source_keyno + ':changed', function (data) {
                            angular.forEach(vm.parameters, function (p) {
                                if (angular.isDefined(data.value[p.parm_name])) {
                                    p.parm_value = data.value[p.parm_name];
                                }
                            });

                            if (vm.read_on_change === true) {
                                vm.read();
                            }
                        }));
                    }
                });
            }

            vm.schema = [];
            vm.dataChanged = {};
            vm.dataRead = {};

            angular.copy(data.schema, vm.schema);

            let resetItem = function () {
                vm.item = {};

                angular.forEach(vm.schema, function (val) {
                    switch (val.type) {
                        case 'number':
                            vm.item[val.name] = 0;
                            break;
                        default:
                            vm.item[val.name] = '';
                    }
                });
            };

            switch (vm.data_type) {
                case 'gridselectedrow':
                    resetItem();

                    ttDynamicGridManager.onRowSelected(options._rootId, vm.grid.name, function (dataItem) {
                        setItem(vm, dataItem);
                    });

                    break;
                case 'object':
                    resetItem();
                    break;
                default:
                    vm.items = [];

                    break;
            }
        };

        datasource.prototype.postInit = function () {
            let vm = this;

            if (vm.read_on_init === true) {
                switch (vm.data_type) {
                    case 'gridselectedrow':
                    case 'gridselectedrows':
                    case 'griddirtyrows':
                    case 'gridallrows':
                        waitForRows(vm).then(function () {
                            ttDynamicGridManager.selectRow(vm._rootId, vm.grid.name, 0).then(function () {
                                vm.read();
                            });
                        });
                        break;
                    default:
                        vm.read();
                }
            }
        };

        // call to read datasource (load from db)
        datasource.prototype.read = function () {
            let deferred = $q.defer();

            let vm = this;

            switch (vm.data_type) {
                case 'gridselectedrow':
                    ttDynamicGridManager.getSelectedRow(vm._rootId, vm.grid.name).then(function (response) {
                        setItem(vm, response);
                    });
                    break;
                case 'gridselectedrows':
                    ttDynamicGridManager.getSelectedRows(vm._rootId, vm.grid.name).then(function (response) {
                        angular.copy(response, vm.items);
                    });
                    break;
                case 'griddirtyrows':
                    ttDynamicGridManager.getDirtyRows(vm._rootId, vm.grid.name).then(function (response) {
                        angular.copy(response, vm.items);
                    });
                    break;
                case 'gridallrows':
                    ttDynamicGridManager.getAllRows(vm._rootId, vm.grid.name).then(function (response) {
                        angular.copy(response, vm.items);
                    });
                    break;
                default:
                    let parameters = {};

                    if (vm.parameters.length > 0) {
                        angular.forEach(vm.parameters, function (parm) {
                            if (angular.isString(parm.parm_name) && parm.parm_name.length > 0 && angular.isDefined(parm.parm_value)) {
                                parameters[parm.parm_name] = parm.parm_value;
                            }
                        });
                    }

                    $ihttp.post({
                        method: vm.p2_datatask_keyno,
                        parameters: parameters
                    }).then(function (response) {
                        if (vm.data_type === 'array') {
                            angular.copy(response, vm.items);
                        } else {
                            $.extend(vm.item, response[0]);
                        }

                        deferred.resolve(response);

                        angular.forEach(vm.dataRead, function (listener) {
                            listener(vm.datasource_id, null, response);
                        });
                    });

                    break;
            }

            return deferred.promise;
        };

        // save changes
        datasource.prototype.update = function () {
            let vm = this;
            let def = $q.defer();

            switch (vm.data_type) {
                case 'griddirtyrows':
                    // do nothing;
                    def.resolve(null);
                    break;
                default:
                    $ihttp.post({
                        method: 2525,
                        parameters: {
                            item: vm.item,
                            items: vm.items,
                            tt_datasource_keyno: vm.keyno
                        }
                    }).then(
                        function (response) {
                            def.resolve(response);
                        },
                        function (error) {
                            console.log('error');
                            console.dir(error);

                            def.reject(error)
                        }
                    );

                    break;
            }

            return def.promise;
        };

        // call to update datasource property
        datasource.prototype.changed = function (property, value, index) {
            let vm = this;

            switch (vm.data_type) {
                case 'griddirtyrows':
                    break;
                default:
                    if (angular.isDefined(vm.items) && angular.isDefined(index)) {
                        vm.items[index][property] = value;

                        angular.forEach(vm.dataChanged, function (listener) {
                            listener(vm.datasource_id, vm.items[index], property, index);
                        });

                        eventService.trigger(vm._rootId + ':' + vm.keyno + ':changed', {
                            all: false,
                            property: property,
                            value: value,
                            index: index
                        });
                    } else {
                        if (angular.isDefined(vm.item[property])) {
                            vm.item[property] = value;
                        }

                        angular.forEach(vm.dataChanged, function (listener) {
                            listener(vm.datasource_id, vm.item, property);
                        });

                        eventService.trigger(vm._rootId + ':' + vm.keyno + ':changed', {
                            all: false,
                            property: property,
                            value: value
                        });
                    }

                    if (angular.isString(vm.read_grid_on_change) && vm.read_grid_on_change.length > 0) {
                        eventService.trigger(vm._rootId + ':dynamicgrid:gridfunc:' + vm.read_grid_on_change, {
                            method: 'read'
                        });
                    }

                    if (vm.autosave === true) {
                        vm.update();
                    }

                    break;
            }
        };

        // subscribe to read
        datasource.prototype.onRead = function (listener) {
            let vm = this;

            if (angular.isFunction(listener) !== true) return;

            let id = uuid().replace('-', '');

            vm.dataRead[id] = listener;

            return function () {
                delete vm.dataRead[id];
            };
        };

        // subscribe to changes
        datasource.prototype.onChanged = function (listener) {
            let vm = this;

            if (angular.isFunction(listener) !== true) return;

            let id = uuid().replace('-', '');

            vm.dataChanged[id] = listener;

            return function () {
                delete vm.dataChanged[id];
            };
        };

        datasource.$create = function (options) {
            return create(options);
        };

        return datasource;
    }]);
})();