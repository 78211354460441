(function () {
    'use strict';

    

    //Ønsker:
    //Unngå cacha sidetittel og kunne sette sidetittel fra websida.    
    //Rowlock: sp_ws_p2_datatask_columns() sier ikke noe om pr rad for editable.
    //Kunne slå av pager. 
    //Kunne sette pager lik antall rader som man kunne før.
    //Kunne sette valgt antall rader som default for sida.
    //Sette pager til mer enn 500 rader.
    //Datetime konverteres til utc når kommer til databasen, har midlertidig løsning med hardkodet norsk tidssone med SWITCHOFFSET +01:00 i databasen.
    //"CellClickHandler" virker ikke på datofelt, så får ikke fortalt javascript at brukeren har markert linja.
    //Nummersortering for alle nummerdatatyper i databasen
    //Slippe å måtte ha timer i loadGridData()  og i "gridReady = true"
    //Sortert som griden i gridfunc.getAllRows()
    //På onClose-eventen så returneres 'null' når null når er et tall.
    //Når taber til kryss-felt så mistes editeringen. 
    //Når taber til kryss felt så endres krysset som om man klikker på det. Gjorde en workaround i component.
    //Event i optionfunct når ferdig å laste data? Så slipper å måtte ha timer i loadGridData()  og i "gridReady = true"
    //Optionfunc-event når ferdig å laste data i grid

    //Spørsmål til Robin:
    //Hvordan oppdatere griden (ikke data) når endrer felt som "title" og "hidden" i gridfunc.getGrid())
    //Hva er vm.grid.gridfunc.getResponse()?
    //Hvordan få lagt inn id til lagring i lookupsearch uten å ha id først i navnet man velger?
    //Fikk ikkje verdi i @parm_row i loopupsearch-prosedyra
    //Event for når du har valgt i lookupsearch så kan endre tilhørende lookupfelt da?
    //Hva utløser LookupCellEditor-eventen?
    //Fjerner direktivet navnedelen fra lookupsearch-servicen når man er på et id-feil men ikke et navnefelt?
    //Hvordan få selectrow på øverste rad automatisk fra start? Måtte foreløpig bruke setfocus() og timer på 2 sekund!
    //Hvordan finne ut hvilken rad man er på i "OnCellClose", vet kun hvor man har vært?
    //Vise time og timestamp i grid riktig med timevelger uten sekund og tusendeler
    //Kunne fjerne sekund og tusendeler på datetime-felt i grid

    //Spørsmål til TT-møte:
    //Sender inn dagens dato i datofelt, kunne ta vekk i direktiv?
    //Vente halvt minutt for hver endring av javascript, mindre senere med ny versjon av angular?
        

    //Gjøre:

    //Tittelknapp og spaceknapper lager feilmelding når trykker på dem, fiks det.
    //Når det blir bare en rad på "scan søk" eller "db søk"så vis detail-side automatisk. Skifter fra grid til dette for enkelte eventid når blir bare en rad.
    //"Ny rad"-lagring: Lagre hele raden i en gang når ny rad, så slipper problemet med "not null-kolonner" og lagring av default-verdier. Defaultverdi basert på en verdi i params til menyen (trengs til sida for ny menyrad)
    //Varsel om at endring av primarykey ikke er mulig (også når er to av dem), f.eks "button" i "Alle knapper"
    //Ny rad med insoft-tellere også. (autoincrement er max + 1 fordi må vise i grid før lagrer)
    
    //Showid-felt: kryss viser dropdown id
    //Lag sider for dropdownene
    //Hent dropdown_id fra primarykey til lookuptabellen, dropdown_name fra nytt navne-felt "lookup" som er lagt inn for, ellers 2. feltet og sub1 og sub2(i dropdownfelt istedet for mange kryss - felt),
    //Nytt felt lookup istedet for dropdown_table
    //lookupcolumnfilter1 i lookupsearch, bruk gridpage_keyno som referanse.
    //Søke i joina navn.
    //lookupchoice og lookupchoicelang med felt choice (id og navn_no og navn_gb) og type or sertering, kan bruke lookupseach i stedet for kryss for å spare antall felt.
    //Ctrl + E for å editere lookupdata (dynamics triks)
    //Rekkfølge på lookup-felt fungerer ikke alltid.

    //Forbedre special_gridpage_generate_page sin dropdown-felt-generering. Lag varsel i gridpageinit når har glemt å regenerere etter å ha lagt til felt i databsetabellen så sida tryner.
    //Lag prosedyrekall fra special_gridpage_proc så forenkler datatask_columns, bruker eget felt for leserettighet fra special_gridpage så slipper det i dataprosedyra. Denne hindrer lagring av de som ike skal det. Får valg for kun leserettigheter da også i special_gridproc()
    //Greater og lesser som parameter i meny i stedet for som default på sidene, greater = greater or equial, lesser = leser or equial. Ta vekk felt som Beskrivelse på Sider, = > < feltstørrelse på Kolonner osv.
    //"in()" og "not in ()" i url som parameter, brukes i "resten"-meny-menyen
    //Dersom searchfilter krysset av så søker på dei, dersom ikke finnes så søkes på alle feltene!
    //Meny webpage-felt åpner opp save og proc sikkerhet, nytt meny lese-webpage-felt åpner opp proc sikkerhet men ikke save-sikkerhet eller bruker meny-tilgang som readonly
    //Sjekk url-sikkerhet så ikke kan gjøre ting i url som man ikke har lov til
    //Bare ha pålogga businessco i griden når ikke er businessco som primærnøkkel
        
    //Ikke kunne slå på knappeprosedyre på web, ta vekk kryss for dette. Slås på/av i selve knappe-prosedyra. For sikkerhet.
    //Ctrl + m = lage ny meny om ikke finnes, velger menymappe på menylaginga, tittel settes inn samme på meny, params genereres med lookupsercher.
    //Oppater-knapp henter alle felt in fra databsen på nytt, "Fjern ubrukte felt"-knapp, fjerner alle ubrukte felt.
   
    //Funksjon som sjekker om grid er klar hvert 10-dels sekund når åpner sida
    //Ny rad ikke bare øverst
    //Ta vekk landscape i special_gridpage_reportfile
    //Ta vekk blafring når dbscan har bare rapport
    //Scan må få reportnumber så kan vise bare rapport når skanner inn i db med eventid 3 for bare rapport.
    //Kryss felt må fungere å tabe over uten å stoppe opp. Test i arbeidssseddel: Kryss for 0 - fakturering som må fungere med default verdi = 0

    //I "Ny rad": Sette inn i felt (f.eks gridpage_keyno) det samme som blir filtrert på sida for "Ny rad"
    //Lag rowlink_parm1 og rowlink_parm2 så får full kontroll med knappene.
    //Vis søkekolonnes navn dersom bare en kolonne i stedet for 'Søk' foran søkefeltet
    //Test ut telefonkamera qrkode som url for denne løsninga
    //Update trigger lager fil med script for alle menyradene uten at overskrives når oppgraderer
    //Slå på summering på felt på sida
    //Lag eventprosedyre special_dev_rowevent som returnerer nye feltverdiene for oppgitt tabell og felt som slås på med felt "event" i special_gridcolumn (lagra før kallet så finner i basen alle data)
    //Knapper med feltvalg i messageboxer som dukker opp med både verdier og valg mellom to alternativ. Altså to nivå med to valg så i alt 4 valg.
    //Klikk på feltet «søk» så messagebox med alle kolonnene man kan velge.Skifter da fra «søk»  til nVnet på kolonnen og søker i smdeb kononnen.Kan velge tilbake til søk etterpå. Når «søk» så søker på kolonnene som er avkrysset i Kolonner i feltet searchcolumn. Om ingen avkrysset så søker i alle. Eget valg for å beholde tidligere søkekriterier.
    //Kunne oversette felt når er krysset i Kolonner
    
    //Webpage = pg i stedet for fossproduction, 
    //special_pg_ i stedet for special_gridpage_ både for prosedyrer/funksjoner/tabeller/mpds_dev-prosedyrer
    //Kalle spesialoppdatering til kunde automatisk ved oppgradering
    //Prosessmeny med undermenyer for prosesser. Prosess: Hele prosessen fra ordre til levering inkludert standardvinduene.
    //Ctrl f8 ikke søkelækolonne/scannekolonne når ikke er det
    //Bilder i grid der kolonna må heite "item_thumb", og innneholder url til bildet.

    //TIL SKARP

    //"List"-grid med linker som åpner sider med felt for en rad + eventuelt en grid under. Special_gridpagecolumn.title gir gruppering av feltene. Viser også eventuelt bilde.
    //Eksempel: Trykker på "Ordenr": Åpner side med ordehodefeltene for valgt ordre + grid med ordrelinjene til ordren.Trykker på "Kunde": Åpner side med kundefeltene for valgt kunde
    //Når det blir bare en rad på "scan søk", "db søk" eller "weblink" så vis detail-side automatisk.
    //Detail-side kan ha flere kolonner som ikke grid har fordi kolonnene ikke er krysset av for grid i "Kolonner".
    //Har overskrift-felt for grupper av kolonner. Del sida opp i passende antall aviskolonner.
    //Grid kan være readonly liste med weblinks for "key fields" som primary og foreign  keys med knapp "Editer felt" så blir vanlig grid igjen.
    //Grid kan ha "Vis liste"-knapp så blir readonly liste igjen.
    //«Editer felt» og «Vis liste» knappppene plasseres helt vil venstre før "ny rad" - knappen.
    //Felt "Vis liste" i "Sider" som setter i default readonly mode.
    //Kan fjerne knappene «Vis liste» og "Editer felt" når slår av i felt i "Sider"
    //Bare grid som har alle kolonnene blir ikke detail ved ny rad fordi ellers trengs detail for å få plass til alle kolonnene. Kan ha kryss på side som gir detail likevel fordi dette fyller ut små skjermer som ipad.
    //For å få weblinks på en side man er på så må man først trykke på "Vis liste" - knappen
    //Transaksjonsvindu har detail og edit grid i samme sida. Feks ordre.
    //Rapportlinkene er special_gridpage_reportlink. Ikke parameterboks på rapporter, alt er url - parameter Rapporten åpner messagebox med url og navn og direkteutskriftlink til rapporter, 
    //Rapportikon kan slås på eller av på sida. Om er på så viser det om der er rapporter. Klikk på søketittel: Kan velge rapportparameter. Special_gridpage_report har reportname og url.
    //Prosessknapp også slik som rapportknapp, liste med linker til sider før og etter sida i prosessen. Viser ikke link på egen side, og de sidene man ikke har tilgang til. Viktig at alle ser hele prosessen selv om de ikke har tilgang for forståelse av erp-systemet.
    //Setupknapp også slik som rapportknapp. Viser alle sider som setter opp data for sida. Viser bare link om brukeren har tilgang, men viktig at man ser alle sidene osm man registrerer data for forståelse for erp-suystemet

    //Serverside filtrering på grid

    //Mobilt grensesnitt (standard Touchtimevinduer) når horisontal oppløsning er som for telefoner: List-side og edit-side, der list-sida må bli samme som grid-sida bare for telefoner (knapp på grid-sida til list sida til å begynne med)

    //Lag alle vinduen for prosessene bestilling - ordremottak, ordre - leveranse og fakturering. Bruk dynamicsboka og youtube-videoene til å få ideer om prosesser og sideløsninger. Lag rapporter samtidig med sidene: Lag statistikker og vinduer som i worksenter i dynamics for hver meny

    //Gjør forbedringer i systemet ut fra erfaringene med å lage sider.

    //TIL SKARP


    //---------------------------------------------------------------------------------------------------------------------------------------------
    // Sette opp griden (nye felt hentes fra ttGrid.readme.txt)
    //---------------------------------------------------------------------------------------------------------------------------------------------

    let module = angular.module('imApp');

    module.component('fossProduction', {
        templateUrl: 'views/components/views/fossProduction/fossProduction.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$sce', 'fossProductionService', 'printService', 'printPreviewService', function ($stateParams, $q, $sce, fossProductionService, printService, printPreviewService) {
            var vm = this;
            var page = $stateParams.gridpage_keyno;
            var dtk = $stateParams.p2_datatask_keyno;
            if (dtk === '') {
                dtk = 2244 //Dersom ikke oppgitt prosedyre å hente data fra så henter data fra special_gridpage_proc().
            }
            var eventID = $stateParams.eventid;
            if (eventID === '') { //Dersom ikke oppgitt eventID er eventID = 0 (inge|n søkefelt i vinduet).
                eventID = '0';
            };
            //eventID:
            //0 Ingen event
            //1 Søkefelt
            //2 Skannefelt, vis søk uten interaksjon
            //3 Skannefelt, vis søk egen registrering uten interaksjon
            //4 Skannefelt, vis registrering egen registrering uten interaksjon
            //5 Autorefresh, vis søk andres registrering med interaksjon (brukere, scanninger, filimporter eller webservicer).
            //6 Autorefresh uten søkefelt, vis registrering andres registrering med interaksjon (brukere, scanninger, filimporter eller webservicer).
            //7 Autorefresh med søkefelt, vis registrering andres registrering med interaksjon (brukere, scanninger, filimporter eller webservicer).
            //8 Autorefresh, vis søk andres registrering uten interaksjon (brukere, scanninger, filimporter eller webservicer).
            //9 Autorefresh, vis registering andres registrering uten interaksjon (brukere, scanninger, filimporter eller webservicer).
            //Bare grid og grid og rapport: 0-9. Rapport uten grid kan bare vise søk (en rad): 0, 2, 3, 8. 0 for "Rapport uten grid" bruker parm1 og parm2 til parameter.
            //10-19: Samme som over men med rememberfunksjon som registrerer hvilken rad man er på med primarykeys. Grid uten rapport: Registrerer remember. 
            //20: Rapport uten grid: Leser remember og viser dennes parameter for rapporten. For grid og grid/rapport så brukes dette til å kunne vise flere kolonner enn det som er mulig på iPad, så viser på felre iPader istedet.

            var reportNumber = $stateParams.reportnumber

            var parm1 = $stateParams.proc_parm1;
            var parm2 = $stateParams.proc_parm2;
            var parm3 = $stateParams.proc_parm3;

            var showGrid = '1'; //Om griden viser eller ikke. Tar utgangspunkt i at bare griden og ingen rapport skal vises. 

            //Om rapporten viser eller ikke. Selve rapporten blir kallet i initializePage(). Dermed kan det bli blank rapport om reportNumber er ikke gyldig rapport for sida. 
            var showReport = '0' //Tar utgangspunkt i at bare griden og ingen rapport skal vises.
            if (reportNumber > 0) {
                showReport = '1';
            };
            var reportdef_keyno;
            var argtype1;
            var argtype2;
            var argtype3;
            var argtype4;
            var argtype5;
            var argtype6;
            var landscape
            var originalReportPage;

            //Brukes av EventID-sida
            var visualMode = 'grid'; //Tar utgangspunkt i at bare griden og ingen rapport skal vises.
            if (showGrid === '1' && showReport === '1') {
                visualMode = 'grid&report';
            }
            if (showGrid === '0' && showReport === '1') {
                visualMode = 'report';
            }

            //Side = 0 betyr at bare rapporten skal vises.
            if (page === '0') {
                showGrid = '0';
                visualMode = 'report';
            };

            //Dersom det er lovlige valg av eventID så vises sida
            if (
                ((visualMode === 'grid' || visualMode === 'grid&report') && Number(eventID) >= 0 && Number(eventID) <= 20)
                ||
                (visualMode === 'report' && (eventID === '0' || eventID === '1' || eventID === '2' || eventID === '3' || eventID === '7' || eventID === '8' || eventID === '20'))
            ) {
                
                var showSearchField = eventID === '1' || eventID === '7' || eventID === '11' || eventID === '17' ? '1' : '0'; //Slår på/av søkefeltet.
                var showScanField = eventID === '2' || eventID === '3' || eventID === '4' || eventID === '12' || eventID === '13' || eventID === '14' ? '1' : '0'; //Slår på/av scannefeltet.

                var height; //Høyde på grid/rapport
                var headingHeight = 54;
                var scanFieldHeight = 55;
                var searchFieldHeight = 65;

                //Griden blir høyere når scannefeltet/søkefeltet ikke er der.
                if (showSearchField === '1') {
                    height = $(window).height() - headingHeight - searchFieldHeight;
                }
                if (showScanField === '1') {
                    height = $(window).height() - headingHeight - scanFieldHeight;
                }
                if (showSearchField === '0' && showScanField == '0') {
                    height = $(window).height() - headingHeight;
                }
                var heightstyle = height + 'px'; //høyde på rapport i html/style

                var A4reportheightPortrait = 1141; //Antar at rapporten alltid er A4-ark, altså har A4-ark høyde som er 1134.64 når det er portrettrapport.
                var A4reportheightLandscape = 808 //Antar at rapporten alltid er A4-ark, altså har A4-ark høyde som er  804.51 når er landskapsrapport
                var A4reportheight;
                if (showGrid === '1') {
                    A4reportheight = A4reportheightPortrait; //Antar at alltid er portrettrapport når grid finnes, for da er området for rapporten smalt.
                } else {
                    A4reportheight = A4reportheightLandscape; //Antar at alltid er landskapsrapport når grid ikke finnes, for da er området for rapporten bredt.
                }
                var reportzoom = height / A4reportheight * 100; //Zoomer rapporten for A4-høyde til høyden som er tilgjengelig. 100 = ingen zoom.
                var A4reportwidth = A4reportheight * 0.71 * reportzoom / 100;// 0.71 er forholdet mellom høyde og bredde for A4-ark.
                var width = $(window).width();
                var gridratio = (width - A4reportwidth) / width * 100;
                var widthstyle = gridratio + '%'; //Grid% i forhold til bedden på sida

                //Gridoppsett 
                if (showGrid =  '1') {

                    var pname1;
                    var pname2;
                    var refreshgrid = [];

                    var remember = 'w_fossproduction/' + page + '/' + dtk + '.remember_id';
                    var isNewRow = false; //ctrl+i må vite om kan lage ny rad
                    var checkBoxColumnName; //Bare eventen data.func === 'CheckboxBoxClick' vet når man er i et kryss-felt når man klikker på den med musa så lagrer da og sletter når går til annet felt
                    var dropdownList = [];
                    var rowNumberOnCellClose;

                    var menuName;
                    var menuID; //MenyID, brukes til å vite hvilken "Side" man er på, menynavn kan ikke brukes til det pga det kan være ulike språk og kan forekomme endringer av menynavnet over tid.

                    var pvalue1_last;
                    var pvalue2_last;

                    var searchname1 = ''; //Kolonnen som det søkes i. NB! Denne må hardkodes om man bruker egen prosdyre til å hente data.
                    var searchvalue1 = ''; //Verdien settes fra søkefeltet i vm.onScan(). Søket må hardkodesmed denne som søkekriterie om man bruker egen prosdyre til å hente data.
                    var dbsearchtext = '';

                    vm.grid = {
                        dataTask: {
                            rememberId: remember,                               // Id used to retrieve user configs for the grid
                            loadSetupId: null,                                  // Uses default if null or undefined
                            loadData: {                                         // Datatask for loading the grid data and related procedures
                                method: dtk,                                    // Needs datatask_keyno
                                parameters: { gridpage_keyno: page, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3, eventid: eventID, search_column_name1: searchname1, search_column_value1: searchvalue1 } // Accepts any number of parameters as needed        
                            },
                            addRow: {                                           // Datatask for adding a row to the grid
                                method: null,                                   // Needs datatask_keyno                                 // Not required if saveData is set
                                parameters: null,                               // Accepts any number of parameters as needed           // Not required if saveData is set
                                autoSave: false,                                // Will automatically save after row added, default is false
                                confirm: false                                  // Adds a confirmation popup before saving, default is false
                            },
                            removeRow: {                                        // Datatask for removing a row from the grid
                                method: null,                                   // Needs datatask_keyno                                 // Not required if saveData is set
                                parameters: null,                               // Accepts any number of parameters as needed           // Not required if saveData is set
                                autoSave: false,                                // Will automatically save after row added, default is false
                                confirm: false                                  // Adds a confirmation popup before saving, default is false
                            },
                            saveData: {                                         // Datatask for saving changes in the grid
                                method: null,                                   // Needs datatask_keyno
                                parameters: null,                               // Accepts any number of parameters as needed
                                autoSave: false,                                // Will automatically save after row added, default is false
                                confirm: false                                  // Adds a confirmation popup before saving, default is false
                            }
                        },
                        translations: [],                                       // Comma separated list of words to be translated
                        config: {                                               // TouchTime parameters configuring the grid
                            editColumns: [], //[{ key: 'p2_webpage_keyno', lookup: 1000028, relations: [{ key: 'p2_webpage_keyno', value: 'item_id' }], optionfunc: true }],
                            toolbar: {                                          // alternative -> toolbar: false,
                                hidden: false,                                  // If true, hides the toolbar, default is false
                                pdfExport: false,                               // Adds a predefined btn for exporting grid to pdf
                                excelExport: false,                             // Adds a predefined btn for exporting grid to excel
                                filter: false,                                  // Adds a predefined btn for en-/disabling filtering
                                columnVisibility: false,                        // Adds a predefined btn for deciding which columns to show/hide
                                headers: false,                                 // Adds a predefined btn for switched between db column names and titles
                                edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                                lock: false,                                    // NOT IMPLEMENTED
                                add: false,                                     // Adds predefined btns for running an add function     // Needs addRow or saveData
                                delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow or saveData
                                save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                                wrapping: false,                                // Adds a predefined btn for en-/disabling text-wrapping in columns
                                layouts: false,                                 // Adds a predefined btn for adding, setting or removing predefined column layouts // IN DEVELOPMENT
                                buttons: [                                      // List of custom buttons
                                ]
                            },
                            fixedHeader: true,                                  // Enables header to stay visible when scrolling
                            keepSortOnAdd: false,                               // Clears sorting when adding a row
                            keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                            keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                            rowClick: false,                                    // Enables functionality for drilldown on clicked row
                            specialFunc: {                                      // Adds additional functionality to the grid_functions column
                                newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                                buttons: [                                      // List of custom buttons
                                ]
                            },
                            css: {
                                altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                                textWrapping: true                             // Enables text-wrapping in columns, default is false
                            },
                            onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                            }
                        },
                        kendo: {                                                // Kendo parameters configuring the grid
                            height: height,                    // Sets the height of the grid component
                            aggregate: false,                                    // accepts -> true, false or list of objects (i.e: [{ field: '***', aggregate: 'sum' } ])
                            pager: false,                                       // NOT IMPLEMENTED
                            selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                            //persistSelection: false,                            // NOT IN USE
                            filterable: false,
                        },
                        optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                        gridfunc: null                                            // Used for referencing functions in the Grid Component



                        //---------------------------------------------------------------------------------------------------------------------------------------------
                        // Gridfunc-funksjoner kalles med  vm.grid.gridfunc. (nye hentes fra ttGrid.component.js)
                        //---------------------------------------------------------------------------------------------------------------------------------------------
                        //refresh: function () { vm.grid.refresh(); },
                        //read: readGridData,
                        //callPopupTable: function (data) { return callPopupTable(data); },
                        //setFocusToCell: function (rowIndex, colIndex) { vm.grid.current(vm.grid.tbody.children().eq(rowIndex).children().eq(colIndex)); },
                        //editCell(0, 1);  (rowIndex, colIndex) { vm.grid.editCell(vm.grid.tbody.children().eq(rowIndex).children().eq(colIndex)); },
                        //clearSorting: function () { clearSorting(); },
                        //addRowBefore: function (atIndex, dataItem) { return gridAddRowBefore(atIndex, dataItem); },
                        //addRowAfter: function (atIndex, dataItem) { return gridAddRowAfter(atIndex, dataItem); },
                        //getColumnFormatType: function (key) { return angular.isDefined(key) ? gridConfig.columnFormatType[key] : gridConfig.columnFormatType },
                        //getResponse: function () { return vm.response },
                        //getResponseColumns: function () { return vm.response[0] },
                        //removeRow: gridRemoveRow,
                        //removeRows: gridRemoveRows, // removes all selected rows
                        //getDirtyRows: function () { return getDirtyRows(); },
                        //getSelectedRows: function () { return getSelectedRows(); },
                        //getAllRows: function () { return vm.grid.dataSource.data(); },  //NB! lister alltid rader etter database-sorteringen ikke etter gridsorteringen av brukeren etterpå!!!! Bruk vm.grid.gridfunc.getGrid()._data[rownumber] istedet!
                        //getGrid: function () { return vm.grid; },
                        //getDataSource: function () { return vm.grid.dataSource; },
                        //getGridColumns: function () { return vm.grid.columns; },
                        //getColumnSchema: function () { return vm.grid.dataSource.options.schema.model.fields; },
                        //saveChanges: saveAllChanges,
                        //rebind: rebindGrid

                    };
                };


                //Kommunisere med template.html
                vm.model = {
                    previewLink: '',
                    scanValue: '',
                    showSearchField: showSearchField,
                    showScanField: showScanField,
                    showGrid: showGrid,
                    showReport: showReport,
                    heightstyle: heightstyle,
                    widthstyle: widthstyle
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Hente oppsettet for sida
                //---------------------------------------------------------------------------------------------------------------------------------------------    
                var initializePage = function () {
                    fossProductionService.initializePage(page, dtk, eventID, reportNumber, parm1, parm2, parm3).then(function (data) {
                        if (visualMode !== 'report') {
                            //Grid ting

                            var i = 0; //Antall knapper
                            var blueButtons = 0; //Antall knapper til høyre for Tittel

                            //Primærnøkler, alle må ha det for å vise rapporter eller sende rad til annen browser
                            if (angular.isDefined(data[3][0])) {
                                pname1 = data[3][0].columnname; //Primærnøkkel1
                            }

                            if (angular.isDefined(data[3][1])) {
                                pname2 = data[3][1].columnname; //Primærnøkkel2
                            }

                            //Alle eventID som har interaksjon skal ha oppsett av sida som vanlig
                            if (eventID === '0' || eventID === '1' || eventID === '5' || eventID === '6' || eventID === '7' || eventID === '10' || eventID === '11' || eventID === '15' || eventID === '16' || eventID === '17' || eventID === '20') { //Skal ikke kunne editere, lagre eller ha knapper når har scanning, fordi scannefeltet alltid skal ha focus så det hele tiden kan scanne inn i det

                                //Lookup editerbare felt
                                var lookupColumns;

                                if (angular.isDefined(data[0])) {
                                    lookupColumns = data[0];
                                }
                                angular.copy(lookupColumns, vm.grid.config.editColumns);

                                //Editerbare felt
                                var editableColumns;
                                if (angular.isDefined(data[1])) {
                                    editableColumns = data[1];
                                }
                                angular.forEach(editableColumns, function (property) {
                                    vm.grid.config.editColumns.push(property); //Må ha push() og ikke copy() ellers skriver man over vm.grid.config.editColumns som ble lagt inn ovenfor.
                                });

                                //Liste med lookup-felt som hører sammen (id og navn), rekkefølge: dropdown1, dropdown2 osv.
                                if (angular.isDefined(data[2])) {
                                    angular.copy(data[2], dropdownList);
                                }


                                //Refresher grid når lagrer på denne kolonnen.
                                if (angular.isDefined(data[5][0])) {
                                    refreshgrid = data[5];
                                };

                                //Knappene
                                var buttons = data[6];
                                angular.forEach(buttons, function (property) {

                                    //Setter opp knappen
                                    vm.grid.config.toolbar.buttons[i] = [];
                                    vm.grid.config.toolbar.buttons[i].name = property.name;
                                    vm.grid.config.toolbar.buttons[i].icon = property.icon;//'none';
                                    vm.grid.config.toolbar.buttons[i].text = property.text;
                                    if (property.cssclass > '') {

                                        vm.grid.config.toolbar.buttons[i].cssClass = property.cssclass;// 'btn btn-primary im-grid-btn-xs-r'
                                    }
                                    blueButtons++; //Alle knappene

                                    //Dersom nyradknapp så koble til nyradfunksjon
                                    if (property.name === 'customAddBefore') {
                                        vm.grid.config.toolbar.buttons[i].func = new Function('newRow()');
                                        isNewRow = true; //ctrl+i må vite om kan lage ny rad
                                        blueButtons--; //Trekker fra "add"
                                    }

                                    //Dersom sletteknapp så koble til slettefunksjon
                                    if (property.name === 'customDelete') {
                                        vm.grid.config.toolbar.buttons[i].func = new Function('deleteItem()');
                                        vm.grid.kendo.selectable = "multiple"; //Må slå på radvalg for å kunne velge rad
                                        blueButtons--; //Trekker fra "delete"
                                    }

                                    //Sette opp link for knappen
                                    if (property.link > '') {
                                        //Om sidas egen url så sett til rett eventID og reportNumber så slipper å ta hensyn til det i knappene, så kan sette blankt for de der. Dermed fungerer knappene for forskjellige eventid og rapporter  for samme side.
                                        var linksplit = property.link.split('/');
                                        if (linksplit[0] === page) {
                                            property.link = page + '/' + $stateParams.p2_datatask_keyno + '/' + $stateParams.eventid + '/' + reportNumber + '/' + linksplit[4] + '/' + linksplit[5] + '/' + linksplit[6];
                                        }
                                        var link = '/#/fossproduction/' + property.link;
                                        window.buttoncode_link = 'return location.href = ("' + link + '")'; //Global scope for dynamisk new Function()
                                        vm.grid.config.toolbar.buttons[i].func = new Function(buttoncode_link);
                                    }

                                    //Sette opp rowlink for knappen
                                    if (property.rowlink > '') {
                                        window.newPageAndProc = property.rowlink; //Global scope for dynamisk new Function()
                                        window.rowlink_parm3 = property.rowlink_parm3; //Global scope for dynamisk new Function()
                                        vm.grid.kendo.selectable = "multiple"; //Må slå på radvalg for å kunne velge rad
                                        window.buttoncode_rowlink = "rowLink('" + window.newPageAndProc;
                                        //Legge til rowlink_parm3 

                                        if (window.rowlink_parm3 > '') {
                                            window.buttoncode_rowlink += '/' + window.rowlink_parm3
                                        }
                                        window.buttoncode_rowlink += "')";
                                        vm.grid.config.toolbar.buttons[i].func = new Function(buttoncode_rowlink);
                                    }

                                    //Sette opp prosedyrekall for knappen
                                    if (property.callproc === 1) { //kan ikke være '1' siden er bit.                             
                                        window.buttoncode_callproc = 'callProc("' + property.name + '")'; //Global scope for dynamisk new Function()
                                        vm.grid.config.toolbar.buttons[i].func = new Function(buttoncode_callproc);
                                    }
                                    i++;
                                });
                            }

                            //Tittel på sida hentet fra menyen til sida
                            if (angular.isDefined(data[4][0])) {
                                menuName = data[4][0].menuname;
                            };
                            var iconButtonWidth = 47;
                            var blueButtonWidth = iconButtonWidth * 1.8;
                            var blueButtonSpace = blueButtonWidth * blueButtons;
                            var removeTitleSpace = 0;
                            if (blueButtonSpace > width / 2 - 120) { //Sikkerhetsmargin 120
                                removeTitleSpace = blueButtonSpace; //
                            }

                            for (var j = i + 1; j - blueButtons < (width - removeTitleSpace) / 2 / iconButtonWidth; j++) { //Regner ut antall space som trengs mellom knappene og tittel-knappen i forhold til bredden av vinduet.
                                //Lager space foran sidetittel regulert ut fra antall knapper og bredden på skjermen
                                vm.grid.config.toolbar.buttons[j] = [];
                                vm.grid.config.toolbar.buttons[j].name = 'space' + j;
                                vm.grid.config.toolbar.buttons[j].icon = 'none';
                                vm.grid.config.toolbar.buttons[j].text = '';
                            }
                            // Tittel til sida
                            vm.grid.config.toolbar.buttons[j + 1] = [];
                            vm.grid.config.toolbar.buttons[j + 1].name = 'title';
                            vm.grid.config.toolbar.buttons[j + 1].icon = 'none';
                            vm.grid.config.toolbar.buttons[j + 1].text = menuName.slice(0, 50); //Kan ikke være for langt navn i button ellers stopper programmet



                            //Alt klart, viser sida
                            vm.model.gridReady = true;
                        }

                        //Menyid
                        if (angular.isDefined(data[4][0])) {
                            menuID = data[4][0].p2_webpagelink_keyno;
                        };

                        //Setter i gang DBsearch() om er valgt det.
                        if (eventID === '5' || eventID === '6' || eventID === '7' || eventID === '8' || eventID === '9' || eventID === '15' || eventID === '16' || eventID === '17' || eventID === '18' || eventID === '19') {
                            DBsearch();
                        }

                        //Setter i gang printPreviewFromReceivedRow() om er valgt det.
                        if (eventID === '20') {
                            printPreviewFromReceivedRow();
                        }
           
                        //Lagrer rad i basen for andre browsere om er valgt det.
                        if (Number(eventID) >= 10 && Number(eventID) <= 19) {
                            //Når er grid så kan man sende valgt rad:
                            if (visualMode === 'grid') {
                                vm.grid.kendo.selectable = "multiple"; //Må slå på radvalg for å kunne velge rad å sende.
                                setTimeout(function () {  //Må vel finnes en bedre metode enn dette. Må vente til "vm.model.gridReady = true" er ferdig å laste inn dataene til griden.
                                    broadCastFirstRow();
                                }, 2000); //Venter 2 sekund
                            }
                        }

                        //Kjører rapporten om er valgt det.   
                        if (angular.isDefined(data[7][0])) {
                            reportdef_keyno = data[7][0].reportdef_keyno;
                            if (reportdef_keyno > '') {
                                argtype1 = data[7][0].argtype1;
                                argtype2 = data[7][0].argtype2;
                                landscape = data[7][0].landscape;
                                originalReportPage = data[7][0].original_reportpage;
                                //Rapporten må finnes på en side for at den skal kunne vises
                                if (originalReportPage > 0) {
                                    if (visualMode === 'grid&report') {
                                        vm.grid.kendo.selectable = "multiple"; //Må slå på radvalg for å kunne velge rad til rapporten
                                        setTimeout(function () {  //Må vel finnes en bedre metode enn dette. Må vente til "vm.model.gridReady = true" er ferdig å laste inn dataene til griden.
                                            printPreviewFirstRow();
                                        }, 2000); //Venter 2 sekund
                                    }
                                    if (visualMode === 'report' && eventID !== '20') { //eventID '20' oppdateres i printPreviewFromReceivedRow()
                                        printPreview();
                                    }
                                } 
                            };
                        }
                    });
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Hente dataene til griden, NB! Bare refresh, første gang gjøres i gridoppsettet
                //---------------------------------------------------------------------------------------------------------------------------------------------
                var loadGridData = function () {
                    vm.grid.dataTask.loadData = {
                        method: dtk,
                        parameters: { gridpage_keyno: page, proc_parm1: parm1, proc_parm2: parm2, proc_parm3: parm3, eventid: eventID, search_column_name1: searchname1, search_column_value1: searchvalue1 }
                    };
                    vm.grid.gridfunc.rebind();
                    if (visualMode === 'grid&report') {  //Om er rapportvisning så må kjøre rapport
                        setTimeout(function () {  //Må vel finnes en bedre metode enn dette. Må vente til er ferdig å laste dataene til griden.
                            printPreviewFirstRow();
                        }, 2000); //Venter 2 sekund
                    };
                    //Lagrer rad i basen for andre browsere om er valgt det.
                    if (Number(eventID) >= 10 && Number(eventID) <= 19) { 
                        //Når er kun grid så kan man sende valgt rad:
                        if (visualMode === 'grid') {
                            setTimeout(function () {  //Må vel finnes en bedre metode enn dette. Må vente til "vm.model.gridReady = true" er ferdig å laste inn dataene til griden.
                                broadCastFirstRow();
                            }, 2000); //Venter 2 sekund
                        }
                    }
                };




                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Knappetrykk som endrer urlen, også ut fra raden man har valgt med kryss)
                //---------------------------------------------------------------------------------------------------------------------------------------------
                window.rowLink = function (newPageAndProc) { //Global scope for dynamisk new Function() i knapp

                    var selectedRows = vm.grid.gridfunc.getSelectedRows();
                    if (angular.isDefined(selectedRows[0])) {
                        var pvalue1 = selectedRows[0][pname1];
                        //Eventuell primærnøkkel nr 2:
                        var pvalue2;
                        if (angular.isDefined(pname2 && pname2 > '')) {
                            pvalue2 = selectedRows[0][pname2];
                        } else {
                            pvalue2 = ''; // pvalue2 finnes ikke
                        };

                        if (pvalue1 > '') { //Må ha primærnøkkel
                            //Dersom mangler / så må denne med i newPageAndProc siden både page og proc skal med.
                            if (newPageAndProc.split('/').length - 1 === 0) {
                                newPageAndProc = newPageAndProc + '///';
                            }

                            //Bare sida valgt
                            if (newPageAndProc.split('/').length - 1 === 3) {
                                location.href = '/#/fossproduction/' + newPageAndProc + '/' + pvalue1 + '/' + pvalue2 + '/' + rowlink_parm3;
                            }
                            //Side og første parm1 valgt
                            if (newPageAndProc.split('/').length - 1 === 4) {
                                location.href = '/#/fossproduction/' + newPageAndProc + '/' + pvalue1 + '/' + rowlink_parm3;
                            };
                            //Side og parm1 og parm2 valgt
                            if (newPageAndProc.split('/').length - 1 === 5) {
                                location.href = '/#/fossproduction/' + newPageAndProc + '/' + rowlink_parm3;

                            };
                            //Side og parm1, parm2 og parm3 valgt
                            if (newPageAndProc.split('/').length - 1 === 6) {
                                location.href = '/#/fossproduction/' + newPageAndProc;
                            }
                        };
                    }
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Knappetrykk som kaller prosedyre som får vite raden man har valgt
                //---------------------------------------------------------------------------------------------------------------------------------------------
                window.callProc = function (buttonname) { //Global scope for dynamisk new Function()
                    var selectedRows = vm.grid.gridfunc.getSelectedRows();
                    angular.forEach(selectedRows, function (row) {
                        var pvalue1 = row[pname1];
                        //Eventuell primærnøkkel nr 2:
                        var pvalue2;
                        if (angular.isDefined(pname2 && pname2 > '')) {
                            pvalue2 = row[pname2];
                        };
                        if (pvalue1 > '') { //Må ha primærnøkkel
                            fossProductionService.button(page, buttonname, menuID, pvalue1, pvalue2).then(function (data) {
                            });
                        };
                    })
                    vm.grid.gridfunc.refresh(); //Fjerner krysset som valgte rad
                };






                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Ny rad
                //---------------------------------------------------------------------------------------------------------------------------------------------
                window.newRow = function () { //Global scope for dynamisk new Function() i knapp
                    vm.grid.gridfunc.addRowBefore();
                    var rowNumber = 0; //Alltid første rad som settes inn
                    var row = vm.grid.gridfunc.getGrid()._data[rowNumber];
                    fossProductionService.newRow(page).then(function (data) { //Henter defaultverdier for den nye rada
                        angular.forEach(data, function (property) {
                            row[property.columnname] = property.defaultvalue; //Setter defaultverdiene i griden 
                        });
                        vm.grid.gridfunc.refresh();
                        vm.grid.gridfunc.editCell(0, 1);
                    });
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Slette rad
                //---------------------------------------------------------------------------------------------------------------------------------------------
                window.deleteItem = function () { //Global scope for dynamisk new Function() i knapp
                    var deleterows = vm.grid.gridfunc.getSelectedRows();
                    var numberOfRows = deleterows.length;
                    if (numberOfRows > 0) {
                        var text;
                        if (numberOfRows === 1) {
                            text = 'Slette valgte linje?';
                        } else {
                            text = 'Slette valgte ' + numberOfRows + ' linjer?';
                        };
                        confirm(text).then(function () {
                            angular.forEach(deleterows, function (row) {
                                var pvalue1 = row[pname1];
                                //Eventuell primærnøkkel nr 2:
                                var pvalue2;
                                if (angular.isDefined(pname2 && pname2 > '')) {
                                    pvalue2 = row[pname2];
                                };
                                if (pvalue1 > '') { //Må ha primærnøkkel
                                    fossProductionService.saveGridData(page, 0, null, null, pname1, pvalue1, pname2, pvalue2).then(function (data) {  //1 = update, 0 = delete
                                        vm.grid.gridfunc.removeRow(row);
                                    });
                                };
                            });
                        }, {});
                    } else {
                        info('Advarsel', 'Velg rader å slette');
                    }
                };



                var optionfunc = function (data) {



                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    // Event for klikk i felt
                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                        checkBoxColumnName = false; //Er ikke lenger i kryss-felt 
                        //Lagrer rad i basen for andre browsere om er valgt det.
                        if (Number(eventID) >= 10 && Number(eventID) <= 19) {
                            if (visualMode === 'grid') {
                                var selectedRows = vm.grid.gridfunc.getSelectedRows();
                                if (angular.isDefined(selectedRows[0])) {
                                    var pvalue1 = selectedRows[0][pname1];
                                    //Eventuell primærnøkkel nr 2:
                                    var pvalue2;
                                    if (angular.isDefined(pname2 && pname2 > '')) {
                                        pvalue2 = selectedRows[0][pname2];
                                    } else {
                                        pvalue2 = ''; // pvalue2 finnes ikke
                                    };
                                };
                                broadCastFromRow(pvalue1, pvalue2);
                            }
                        }

                        //Dersom er rapport i riktig retning så vises rapport
                        if (!landscape && visualMode === 'grid&report' || landscape && visualMode === 'report') {  //Rapportvisning med grid kan bare være portrett, rapportvisning uten grid kan bare være landskap, gjelder også iPad pga da snur hele sida.
                            var selectedRows = vm.grid.gridfunc.getSelectedRows();
                            if (angular.isDefined(selectedRows[0])) {
                                var pvalue1 = selectedRows[0][pname1];
                                //Eventuell primærnøkkel nr 2:
                                var pvalue2;
                                if (angular.isDefined(pname2 && pname2 > '')) {
                                    pvalue2 = selectedRows[0][pname2];
                                } else {
                                    pvalue2 = ''; // pvalue2 finnes ikke
                                };
                            };
                            //Viser rapporten
                            if (pvalue1 > '') { //Må ha primærnøkkel
                                printPreviewFromRow(pvalue1, pvalue2, searchvalue1);
                            };
                        }
                    }



                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    // Event for å gå til url når gitt celle blir klikka
                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    // stateService.go(state, parms){} //Gå til weblink om gitt celle blir klikket



                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    // Event for lookupsearch editor
                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    }



                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    // Event for å lagre inputfelt når har endret en celle og går til neste felt
                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                        checkBoxColumnName = false; //Er ikke lenger i kryss-felt
                        var rowNumber = data.ridx;
                        rowNumberOnCellClose = rowNumber; //For CheckboxBoxClick
                        var columnNumber = data.cidx;
                        var row = vm.grid.gridfunc.getGrid()._data[rowNumber];
                        var cname = data.cval;
                        var cvalue = data.change;
                        var pvalue1 = row[pname1];
                        //Eventuell primærnøkkel nr 2:
                        var pvalue2;
                        if (angular.isDefined(pname2) && pname2 > '') {
                            pvalue2 = row[pname2];
                        }
                        if (pvalue1 > '') { //Må ha primærnøkkel
                            var dirtyRows = vm.grid.gridfunc.getDirtyRows();
                            if (angular.isDefined(dirtyRows[0]) && dirtyRows[0].dirty > '') {
                                if (cname.slice(0, 8) !== 'dropdown') { //Kan ikke lagre når lookupfelt for det finnes ikke i tabellen
                                    fossProductionService.saveGridData(page, 1, cname, cvalue, pname1, pvalue1, pname2, pvalue2).then(function (data) {  //1 = update, 0 = delete
                                        dirtyRows[0].dirty = false; //Har lagret så da er ikke cellen dirty lenger
                                    })
                                }



                                //---------------------------------------------------------------------------------------------------------------------------------------------
                                // Lookupsearch sin lookup-funksjonalitet
                                //---------------------------------------------------------------------------------------------------------------------------------------------

                                //Dersom er lookupsearch-felt så må eventuelt tilhørende lookupsearch-felt (id eller navn) også endres
                                var idField; //Om man er nå på et lookupsearch idfelt eller et lookupsearch navnefelt
                                var lookupField; //Feltnavnet til det tilhørende lookupfeltet om man er på et loookupseach-felt
                                //Sjekker om man er på et lookupseach navnefelt
                                if (cname.slice(0, 8) === 'dropdown') {
                                    idField = false;
                                    var currentlookupfieldNumber = parseInt(cname.slice(8)); //Finner tallet som er etter 8 tegn 
                                    lookupField = dropdownList[currentlookupfieldNumber - 1].key;
                                }
                                //Eller på et lookupsearch idfelt
                                else {
                                    idField = true;
                                    for (var i = 0; i < dropdownList.length; i++) {
                                        if (cname === dropdownList[i].key) {
                                            lookupField = 'dropdown' + (i + 1).toString();
                                            break;
                                        }
                                    }
                                }
                                //Om er på et lookupseachfelt og dermed har et tilhørende lookupfelt så forandres dette lookupfeltet når man foralter feltet for er her man i en OnCellClose-event
                                //Må oppdatere tilhørende lookupfelt til ny verdi i henhold til hva man endret til i cellen man var på når man er i et lookupseach-felt.
                                if (lookupField > '') { //Om er på et lookupseach-felt
                                    //Dersom er lookupsearch-navenfelt man er på så må man fjerne id-delen av id + navn man fikk returnert fra lookupseach-servicen i feltet man er på.
                                    //NB! For id - feltet blir dette gjort allerede i direktivet ?. Kan det gjøre  for navnefeltet også der?. Da blir koden nedenfor unødvendig.
                                    if (!idField) {
                                        //Finner id-verdien som er før navneverdien returnert fra lookupsearch. 
                                        var cvalueID = parseInt(cvalue); //Ta tallet stringen starter med som ID
                                        if (isNaN(cvalueID)) { //Dersom ikke et tall så ta stringen til første space som ID
                                            var indexOfSpace = cvalue.indexOf(' ');
                                            if (indexOfSpace !== -1) { //Dersom space finnes
                                                cvalueID = cvalue.substring(indexOfSpace + 1); //beholder stringen fram til første space.
                                            }
                                        }
                                        cvalue = cvalue.replace(cvalueID, "");
                                        cvalue = cvalue.trim();
                                        row[cname] = cvalue;
                                    }

                                    //Finner lookupverdien til det tilsvarende lookupsearch-feltet
                                    fossProductionService.lookup(page, cname, cvalue).then(function (data) {
                                        row[lookupField] = data[0].key;
                                        var cellIndex = vm.grid.gridfunc.getGrid()._current[0].cellIndex;
                                        vm.grid.gridfunc.refresh();
                                        vm.grid.gridfunc.editCell(rowNumber, cellIndex); //Setter fokus med editering på cellen man var på.
                                        //Om det var navnefeltet man var på så må man lagre den nye verdien til id-feltet i databasen. Dette trenger man ikke gjøre om man var på id-feltet for navnefeltet skal aldri lagres, finnes ikke i tabellen.
                                        if (!idField) {
                                            fossProductionService.saveGridData(page, 1, lookupField, row[lookupField], pname1, pvalue1, pname2, pvalue2).then(function (data) {  //1 = update, 0 = delete
                                                dirtyRows[0].dirty = false; //Har lagret så da er ikke cellen dirty lenger
                                            })
                                        }
                                    })
                                }
                            }
                        }
                    }



                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    // Event for å lagre kryss-felt når klikker på det.
                    //---------------------------------------------------------------------------------------------------------------------------------------------
                    if (angular.isDefined(data.func) && data.func === 'CheckboxBoxClick') {
                        var cname = data.key;
                        var rownumber = rowNumberOnCellClose;
                        checkBoxColumnName = cname; //Bare denne eventen vet at man er i et kryss-felt, så lagrer den for andre eventer
                        var cvalue = data.dataItem[data.key];

                        //Hindrer at tab over kryss-felt endrer verdien på kryss-feltet
                        if (rownumber >= 0) { //Om har rownumber fra OnCellClose så har man ikke klikket men tabet og da skal endringen hindres
                            var row = vm.grid.gridfunc.getGrid()._data[rownumber];
                            row[cname] = !row[cname];
                            vm.grid.gridfunc.refresh();
                            rowNumberOnCellClose = null;
                        }
                        var pvalue1 = data.dataItem[pname1];
                        //Eventuell primærnøkkel nr 2:
                        var pvalue2;
                        if (angular.isDefined(pname2) && pname2 > '') {
                            pvalue2 = data.dataItem[pname2];
                        }
                        if (pvalue1 > '') { //Må ha primærnøkkel
                            fossProductionService.saveGridData(page, 1, cname, cvalue, pname1, pvalue1, pname2, pvalue2).then(function (data) {  //1 = update, 0 = delete
                                // Sjekker om refresh er satt på for kolonna
                                var refresh = false;
                                //Skrudd på for kolonna?
                                angular.forEach(refreshgrid, function (property) {
                                    if (property.columnname == cname) {
                                        refresh = true;
                                    }
                                });
                                //Utfører refresh av grid
                                if (refresh) {
                                    loadGridData(dtk, page, parm1, parm2, parm3, eventID, searchname1, searchvalue1);  // Oppdaterer dataene i griden etter lagring.
                                }
                            });
                        };

                    };
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Event for søkefeltet
                //---------------------------------------------------------------------------------------------------------------------------------------------
                vm.onDataChanged = function (value, modelId) {
                    searchvalue1 = value;
                    //Filterer griden om grid finnes
                    if (visualMode === 'grid' || visualMode === 'grid&report') {  //Om er gridvisning
                        loadGridData(dtk, page, parm1, parm2, parm3, eventID, searchname1, searchvalue1); // Oppdaterer dataene i griden etter lagring.
                    } else if (visualMode === 'report') {
                        printPreview();
                    }
                };




                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Event for scannefeltet
                //---------------------------------------------------------------------------------------------------------------------------------------------
                vm.onScan = function (value) {
                    vm.model.scanValue = value;
                    if (value.length === 0) return; //Stoppes i tilfelle scanning aktivert uten string. Hindrer "blanking" som man vil ha ved søk men ikke scanning.
                    //Dersom skal kun søke med scannefeltet
                    if (eventID === '2' || eventID === '12') {
                        searchvalue1 = value;
                        //Må kalle scan() her for å få blanka scannefeltet vm.model.scanValue, selv om scan() her ikke gjør noe.  Bare når det er eventID 3 og 4 gjør fossProductionService.scan() noe. Bare da registreres den scanna verdien i databasen
                        fossProductionService.scan(page, '', eventID, menuID, parm1, parm2, parm3, dtk).then(function (data) {
                            //Må få reportNumber så kan scanne og vise bare rapport også!
                            vm.model.scanValue = ''; //Setter søkefeltet til tom string etter scanninga.
                        });
                        if (visualMode === 'grid' || visualMode === 'grid&report') {  //Om er gridvisning
                            loadGridData(dtk, page, parm1, parm2, parm3, eventID, searchname1, searchvalue1); // Oppdaterer dataene i griden etter lagring.
                        } else if (visualMode === 'report') {
                            printPreview();
                        }
                    }
                    //Dersom skal registrere verdien i scannefeltet i databasen
                    else if (eventID === '3' || eventID === '4' || eventID === '13' || eventID === '14') {
                        fossProductionService.scan(page, vm.model.scanValue, eventID, menuID, parm1, parm2, parm3, dtk).then(function (data) { //Må få reportNumber så kan scanne og vise bare rapport også!
                            vm.model.scanValue = ''; //Setter søkefeltet til tom string etter scanninga.
                            if (eventID === '3' || eventID === '13') { //Må søke
                                searchvalue1 = value;
                            }
                            if (visualMode === 'grid' || visualMode === 'grid&report') {  //Om er gridvisning
                                loadGridData(dtk, page, parm1, parm2, parm3, eventID, searchname1, searchvalue1); // Oppdaterer dataene i griden etter lagring. 
                            } else if (visualMode === 'report') {
                                printPreview();
                            }
                        });
                    };
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Event for databasescan hvert 2. sekund for å se om data har endret seg.
                //---------------------------------------------------------------------------------------------------------------------------------------------
                var DBsearch = function () {
                    window.setInterval(function () {
                        fossProductionService.dbsearch(page, eventID, reportNumber, menuID, parm1, parm2, parm3, dtk).then(function (data) {
                            //Sjekker om er skjedd en endring dbsearchtext lytter på. NB! Kan bli problem når dbsearchtext fra før har en verdi som egentlig skulle vært blanket for å få endring likevel. For eksempel når angrer på ferdigmelding og velger ikke siste som sjekkes for endring.
                            if (data[0].dbsearchtext !== dbsearchtext) {
                                dbsearchtext = data[0].dbsearchtext;
                                //EventID 5, 8, 15 og 18 skal vise søk, ikke registrering, så må søke i tillegg.
                                if (eventID === '5' || eventID === '8' || eventID === '15' || eventID === '18') {
                                    searchvalue1 = dbsearchtext;
                                }
                                if (visualMode === 'grid' || visualMode === 'grid&report') {  //Om er gridvisning
                                    loadGridData(dtk, page, parm1, parm2, parm3, eventID, searchname1, searchvalue1); // Oppdaterer dataene i griden om er endring.
                                } else if (visualMode === 'report') {
                                    printPreview();
                                }
                            }
                        });
                    }, 2000); //2 sekund intervall
                }




                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Event for mottak av briadcast hvert 2. sekund for å se om raden har endret seg.
                //---------------------------------------------------------------------------------------------------------------------------------------------
                var printPreviewFromReceivedRow = function () {
                    window.setInterval(function () {
                        var receivedPage;
                        var receivedPValue1;
                        var receivedPValue2;
                        
                        fossProductionService.userVariableGet('w_fossproduction.page').then(function (data) {       
                            receivedPage = Number(data[0].variablevalue);
                            fossProductionService.userVariableGet('w_fossproduction.pvalue1').then(function (data) {
                                receivedPValue1 = data[0].variablevalue;
                                fossProductionService.userVariableGet('w_fossproduction.pvalue2').then(function (data) {
                                    receivedPValue2 = data[0].variablevalue;
                                    if (receivedPage === originalReportPage) {
                                        printPreviewFromRow(receivedPValue1, receivedPValue2);
                                    }
                                });
                            });
                        });
                    }, 2000); //2 sekund intervall
                }


                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Sender primærnøkkelen til første rad til andre web-browsere
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function broadCastFirstRow() {
                    if (angular.isDefined(vm.grid.gridfunc.getGrid())) {
                        var row = vm.grid.gridfunc.getGrid()._data[0]; //Finner øverste rad
                        if (angular.isDefined(row)) {
                            //Om ikke finnes primærnøkkel fordi finnes ingen linjer så settes primærnøøkel til '' for å kunne få refresh til blank rapport.
                            var pvalue1;
                            if (angular.isDefined(pname1 && pname1 > '')) {
                                pvalue1 = row[pname1];
                            } else {
                                pvalue1 = ''; // pvalue1 finnes ikke så settes til blank.
                            };
                            //Eventuell primærnøkkel nr 2:
                            var pvalue2;
                            if (angular.isDefined(pname2 && pname2 > '')) {
                                pvalue2 = row[pname2];
                            } else {
                                pvalue2 = ''; // pvalue2 finnes ikke
                            };
                            if (pvalue1 > '') { //Må ha primærnøkkel
                                broadCastFromRow(pvalue1, pvalue2);
                            }
                        } else { //Må blanke den så ikke viser feil rad.
                            broadCastFromRow('', '');
                        }
                    }
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Sender primærnøkkelen til valgt rad til andre web-browsere
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function broadCastFromRow(pvalue1, pvalue2) {
                    //Unngår å sende primærnøklene på nytt med samme verdier som forrige gang
                    if (pvalue1 !== pvalue1_last || pvalue2 !== pvalue2_last) {
                        pvalue1_last = pvalue1;
                        pvalue2_last = pvalue2;
                        fossProductionService.userVariableSet('w_fossproduction.page', page);
                        console.log('Sender: ' +  pvalue1  + ' ' + pvalue2);
                        fossProductionService.userVariableSet('w_fossproduction.pvalue1', pvalue1);
                        fossProductionService.userVariableSet('w_fossproduction.pvalue2', pvalue2);
                    }
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Kalle printPreviewFromRow() fra første rad i griden
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function printPreviewFirstRow() {
                    //Må finne primørøkkel for første rad
                    if (angular.isDefined(vm.grid.gridfunc.getGrid())) {
                        var row = vm.grid.gridfunc.getGrid()._data[0]; //Finner øverste rad
                        if (angular.isDefined(row)) {
                            //Om ikke finnes primærnøkkel fordi finnes ingen linjer så settes primærnøøkel til '' for å kunne få refresh til blank rapport.
                            var pvalue1;
                            if (angular.isDefined(pname1 && pname1 > '')) {
                                pvalue1 = row[pname1];
                            } else {
                                pvalue1 = ''; // pvalue1 finnes ikke så settes til blank.
                            };
                            //Eventuell primærnøkkel nr 2:
                            var pvalue2;
                            if (angular.isDefined(pname2 && pname2 > '')) {
                                pvalue2 = row[pname2];
                            } else {
                                pvalue2 = ''; // pvalue2 finnes ikke
                            };
                            if (pvalue1 > '') { //Må ha primærnøkkel
                                //Setter fokus på øverste rad. NB! Skulle kunne ha gjort selectrow her om visste hvordan.
                                if (angular.isDefined(vm.grid.gridfunc)) {
                                    vm.grid.gridfunc.setFocusToCell(0, 1);
                                }
                                printPreviewFromRow(pvalue1, pvalue2);
                            }
                        } else {
                            printPreviewFromRow('', ''); //Må blanke den så ikke viser feil rapport.
                        }
                    }
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Kaller printPreview() fra en valgt rad i griden om den ikke akkurat ble skrevet ut fra før fra samme rad
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function printPreviewFromRow(pvalue1, pvalue2) {
                    //Unngår å kjøre rapport på nytt med samme paramterne som forrige gang
                    if (pvalue1 !== pvalue1_last || pvalue2 !== pvalue2_last) {
                        pvalue1_last = pvalue1;
                        pvalue2_last = pvalue2;
                        printPreview(pvalue1, pvalue2);  //Rapporter har opptil 6 parameter og skal ha dem i rekkefølgen primærnøkler, søkefelt, urlparm1, urlparm2, urlparm3. 
                    }
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Kaller printPreviewRun() ut fra situasjonen sida er i. 
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function printPreview(pvalue1, pvalue2) { //her er både pvalue1 og pvalue1 valgfritt fordi det kan være en rapport som ikke får parameter fra en gridrad.
                    //NB! Rapporter har opptil 6 parameter og skal ha dem i rekkefølgen primærnøkler, søkefelt, urlparm1, urlparm2, urlparm3. Om f.eks primærnøkler og søkefelt ikke finnes kalles i rekkefølgen:  urlparm1, urlparm2, urlparm3. Se kallene til printPreview() som følger dette.
                    if (visualMode === 'grid&report') {
                        if (pvalue2 > '') {
                            //Når er både grid og rapport og TO primærnøkler :
                            printPreviewRun(pvalue1, pvalue2, parm1, parm2, parm3); //Søkefeltet gjelder griden, ikke rapporten, så ingen søkefelt for eventID = '1'
                        } else {
                            //Når er både grid og rapport og EN primærnøkkel
                            printPreviewRun(pvalue1, parm1, parm2, parm3); //Søkefeltet gjelder griden, ikke rapporten, så ingen søkefelt for eventID = '1'
                        }
                        //Når er bare rapport:
                    } else if (visualMode === 'report') {
                        switch (eventID) {
                            case '0':
                            case '10':
                                printPreviewRun(parm1, parm2, parm3);
                                break;
                            case  '1': 
                            case '11': 
                            case  '2': 
                            case '12': 
                            case  '3': 
                            case '13': 
                                printPreviewRun(searchvalue1, parm1, parm2, parm3); //searchvalue1 gjelder her rapporten
                                break;
                            case  '7': 
                            case '17': 
                                printPreviewRun(dbsearchtext, searchvalue1, parm1, parm2, parm3); //viser søkefelt: dbsearchtext og searchvalue1 gjelder her rapporten, searchvalue1 typisk for å få mindre rader i rapporten
                                break;
                            case  '8': 
                            case '18': 
                                printPreviewRun(dbsearchtext, parm1, parm2, parm3); //dbsearchtext gjelder her rapporten
                                break;
                            case '20':
                                console.log('Mottar: ' + pvalue1 + ' ' + pvalue2);
                                printPreviewRun(pvalue1, pvalue2, parm1, parm2, parm3); //dbsearchtext gjelder her rapporten
                                break;
                        }
                    }
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Kjører "Print preview" for en rapport
                //---------------------------------------------------------------------------------------------------------------------------------------------
                window.printPreviewRun = function (reportParm1, reportParm2, reportParm3, reportParm4, reportParm5, reportParm6) { //reportParm1 kan ikke være null for da blir ikke parms-objektet instansiert.
                    //Setter parameterne til rapporten
                    var parms = {
                        reportKeyno: reportdef_keyno,
                        parameters: {}
                    };
                    if (angular.isDefined(argtype1 && argtype1 > '')) {
                        parms.parameters[argtype1] = reportParm1;
                    };
                    if (angular.isDefined(argtype2 && argtype2 > '')) {
                        parms.parameters[argtype2] = reportParm2;
                    };
                    if (angular.isDefined(argtype3 && argtype3 > '')) {
                        parms.parameters[argtype3] = reportParm3;
                    };
                    if (angular.isDefined(argtype4 && argtype4 > '')) {
                        parms.parameters[argtype4] = reportParm4;
                    }
                    if (angular.isDefined(argtype5 && argtype5 > '')) {
                        parms.parameters[argtype5] = reportParm5;
                    }
                    if (angular.isDefined(argtype6 && argtype6 > '')) {
                        parms.parameters[argtype6] = reportParm6;
                    }
                    //Skriver ut rapporten
                    printService.preview(parms).then(function (url) {
                        url = url + '#toolbar=0&zoom=' + reportzoom; //Fjerner pdf toolbar og zoomer pdf-en til rapporten til passer browservinduet
                        vm.model.previewLink = $sce.trustAsResourceUrl(url);
                    });
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Vise informasjon
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function info(title, content) {
                    $("<div></div>").kendoAlert({
                        title: title,
                        content: content
                    }).data("kendoAlert").open();
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Spørre om bekreftelse
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function confirm(content) {
                    return $("<div></div>").kendoConfirm({
                        title: "Bekreft",
                        content: content
                    }).data("kendoConfirm").open().result;
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Gi informasjon
                //---------------------------------------------------------------------------------------------------------------------------------------------
                function prompt(content, defaultValue) {
                    return $("<div></div>").kendoPrompt({
                        title: "Skriv inn",
                        value: defaultValue,
                        content: content
                    }).data("kendoPrompt").open().result;
                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Shortcut-taster
                //---------------------------------------------------------------------------------------------------------------------------------------------     

                window.onkeydown = function (e) {

                    //F1: Hjelp
                    if (e.key === 'F1') {
                        e.preventDefault();
                        fossProductionService.info(page, dtk, parm1, parm2, parm3).then(function (data) {                            
                            var helpPage = data[4].key;

                            var helpColumns = data[2].key;

                            info(menuName, helpPage +  helpColumns);
                        });

                    }
                    if (e.ctrlKey) {

                        //Ctrl + i: Ny rad
                        if (e.key.toLowerCase() === 'i' && isNewRow) {
                            e.preventDefault();
                            newRow();
                        }

                        //Ctrl + g: Vise grid-objektet i konsollet
                        if (e.key.toLowerCase() === 'g') {
                            e.preventDefault();
                            //console.dir(vm.grid.gridfunc.getGrid());
                            //console.dir(vm.grid.config.toolbar.buttons);
                            //console.dir(vm.grid.kendo);
                            //console.log(fossProductionService.loadGridData(dtk, page, parm1, parm2, parm3, eventID, searchname1, searchvalue1));
                        }

                        //Ctrl + F8: Vise datagrunnlaget til sida
                        if (e.key === 'F8') {
                            e.preventDefault();
                            fossProductionService.info(page, dtk, parm1, parm2, parm3).then(function (data) {
                                var databaseTable = data[0].key;
                                var databaseProcedure = data[1].key;
                                var pageInfo = data[3].key;
                                var word_in = ' i ';
                                if (!databaseProcedure > '') { word_in = '' };
                                fossProductionService.gridSql(page, parm1, parm2, parm3, eventID, searchname1, searchvalue1, dtk).then(function (data) {
                                    var gridSql = data[0].gridsql;
                                    info(menuName, databaseTable + word_in + databaseProcedure + pageInfo + '<BR>' + gridSql);
                                });
                            });
                        }

                    }
                    if (e.shiftKey) {

                        //Shift + F8: Vise databasefeltet
                        if (e.key === 'F8') {
                            e.preventDefault();
                            fossProductionService.info(page, dtk, parm1, parm2, parm3).then(function (data) {
                                var cellIndex = vm.grid.gridfunc.getGrid()._current[0].cellIndex;
                                var field = vm.grid.gridfunc.getGrid().columns[cellIndex].field;
                                if (checkBoxColumnName) { //Om har klikket på kryss-felt så er field alltid "grid_select_col" altså feil så henter det fra optionfunc: data.func === 'CheckboxBoxClick'
                                    field = checkBoxColumnName;
                                }
                                var databaseTable = data[0].key;
                                var databaseProcedure = data[1].key;
                                var pageInfo = data[3].key;
                                var help = data[4].key;
                                //Sjekker om det er databasefelt eller prosedyrefelt
                                var isDatabaseColumn = false;
                                var databaseColumnAndType;
                                angular.forEach(data, function (property) {
                                    var databaseColumnTest = property.key.slice(0, field.length);
                                    if (field === databaseColumnTest && databaseColumnTest !== databaseTable && databaseColumnTest !== databaseProcedure && databaseColumnTest !== menuName && databaseColumnTest !== pageInfo && databaseColumnTest !== help) {
                                        isDatabaseColumn = true
                                        databaseColumnAndType = property.key;
                                    }
                                });
                                //Viser messagebox med feltinfo
                                var word_in = ' i ';
                                if (isDatabaseColumn) {
                                    if (!databaseProcedure > '') { word_in = '' }; //Dersom special_gridpage_proc så er databaseprocedure blank
                                    info(databaseColumnAndType.replace(/\<BR\>/g, " "), databaseTable + '.' + databaseColumnAndType + word_in + databaseProcedure);
                                } else {
                                    info(field, field + word_in + databaseProcedure);
                                }
                            });
                        }
                    }

                    if (e.ctrlKey && e.shiftKey) {
                        console.log(e.key);
                        //Ctrl + Shift + p: Programmere sida man er på
                        if (e.key.toLowerCase() === 'p') {
                            e.preventDefault();
                            location.href = '#/fossproduction/2////' + page + '//';
                        }

                        //Ctrl + Shift + k: Programmere kolonnene til sida man er på. Står på kolonnelinja til kolonna man var på.
                        if (e.key.toLowerCase() === 'k') {
                            e.preventDefault();
                            location.href = '#/fossproduction/3////' + page + '//';
                        }

                        //Ctrl + Shift + b: Programmere knappene til sida man er på.
                        if (e.key.toLowerCase() === 'b') {
                            e.preventDefault();
                            location.href = '#/fossproduction/4////' + page + '//';
                        }

                        //Ctrl + Shift + y: Programmere menyen til sida man er på.
                        if (e.key.toLowerCase() === 'y') {
                            e.preventDefault();
                            location.href = '#/fossproduction/5/////' + menuName + '/';
                        }

                        //Ctrl + Shift + f: Sette inn/vise rapportfilene til sida man er på.
                        if (e.key.toLowerCase() === 'f') {
                            e.preventDefault();
                            if (page === '0') {
                                location.href = '#/fossproduction/8////' + originalReportPage + '//';
                            } else {
                                location.href = '#/fossproduction/8////' + page + '//';
                            }
                        }

                        //Ctrl + Shift + i: Vise eventid til sida man er på.
                        if (e.key.toLowerCase() === 'i') {
                            e.preventDefault();
                            location.href = '#/fossproduction/12////' + eventID + '/' + visualMode + '/';
                        }
                    }

                };



                //---------------------------------------------------------------------------------------------------------------------------------------------
                // Laste inn sida
                //---------------------------------------------------------------------------------------------------------------------------------------------
                angular.element(document).ready(function () {
                    initializePage();
                });
            }
        }]
    });
})();
