import { Injectable } from '@angular/core';

@Injectable()
export class GoogleTranslateService {

    private _baseApiUrl = 'https://translation.googleapis.com/language/translate/v2';
    private _apiKey = 'AIzaSyCUGNBYnPu2eT99OG6HFrHA1pIQvuAVEIE';

    constructor() { }

    /**
     * Translates the given string or array of strings.
     * 
     * @param text the string or array fo string to translate.
     * @param targetlanguage the language to translate to.
     * @param sourceLanguage the language to translate from, if none specified it will detect language.
     * @returns list of translates words.
     */
    public async translateText(text: string | string[], targetlanguage: string, sourceLanguage: string | null = null, format: 'html' | 'text' = 'text'): Promise<{ translatedText: string; detectedSourceLanguage: string; }[]> {
        if (!text || !targetlanguage) {
            throw Error('missing parameters for translating text');
        }

        return (await this._sendApiRequest('POST', `${this._baseApiUrl}`, { q: text, target: targetlanguage, source: sourceLanguage, format: format }))?.data?.translations ?? [];
    }

    /**
     * Detects the language of the given string.
     * 
     * @param text the string to detect the language for.
     * @returns the language id of the language detected or null if no language was detected.
     */
    public async detectLanguage(text: string): Promise<string | null> {
        const response = await this._sendApiRequest('POST', `${this._baseApiUrl}/detect`, { q: text });

        if (response?.data?.detections?.[0]?.[0]?.language) {
            return response?.data?.detections?.[0]?.[0]?.language;
        }

        return null;
    }

    /**
     * Sends an api request with the google api key attatched.
     * 
     * @param method the method of the request.
     * @param url the url to send the api request to.
     * @param params the payload of the request (will be stringified).
     * @returns the response of the api request.
     */
    private async _sendApiRequest(method: 'GET' | 'POST', url: string, params?: Object): Promise<any> {
        let response = await fetch(`${url}?key=${this._apiKey}`, { method: method, body: params ? JSON.stringify(params) : null });
        return response.json();
    }
}
