(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epBlockOrderEdit', {
        templateUrl: 'views/components/views/epBlockOrderEdit/epBlockOrderEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$stateParams', 'stateService', 'ieScreenBlockService', 'epBlockOrderEditService', function ($stateParams, stateService, ieScreenBlockService, epBlockOrderEditService) {
            var vm = this;

            vm.isModal = false;
            vm.version = module.version;

            var load = function (pohKeyno) {
                epBlockOrderEditService.loadData(pohKeyno).then(function (response) {
                    angular.copy(response[0], vm.model);
                });
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve) && angular.isDefined(vm.resolve.pohKeyno)) {
                    // when opened as modal
                    vm.isModal = true;

                    load(vm.resolve.pohKeyno);
                }
            };

            if (angular.isDefined($stateParams.pohKeyno)) {
                // when opened with state
                load($stateParams.pohKeyno);
            }

            vm.model = {};

            vm.postatus = [];
            vm.shipmentcontainers = [];

            vm.cancel = function () {
                if (vm.isModal === true) {
                    vm.modalInstance.dismiss()
                } else {
                    stateService.back();
                }
            };

            vm.save = function () {
                ieScreenBlockService.start();

                epBlockOrderEditService.saveData(vm.model).then(function () {
                    ieScreenBlockService.stop();

                    if (vm.isModal === true) {
                        vm.modalInstance.close()
                    } else {
                        stateService.back();
                    }
                }, function () {
                    ieScreenBlockService.stop();
                });
            };

            epBlockOrderEditService.getPoStatusList().then(function (response) {
                angular.copy(response, vm.postatus);
            });

            epBlockOrderEditService.getShipmentContainerList().then(function (response) {
                angular.copy(response, vm.shipmentcontainers);
            });
        }]
    });
})();
