(function() {
    'use strict';

    angular.module("imApp").factory("newProcedureDocumentationService", ['$ihttp', function ($ihttp) {
        var service = {
            
            getChangelog: function (parms) {
                return $ihttp.post({
                    method: 2407,
                    parameters: parms || {}
                });
            },

            getProcedure: function (parms) {
                return $ihttp.post({
                    method: 2408,
                    parameters: parms || {}
                });
            }
        }

        return service;
    }]);
})();
