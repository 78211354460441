(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('productHazardLabels', {
        templateUrl: 'views/components/views/productHazardLabels/productHazardLabels.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productHazardLabelsService', function ($stateParams, stateService, productHazardLabelsService) {
            var vm = this;


            // ## VARIABLES & DEFINITIONS ##

            vm.model = {
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                prod_id: $stateParams.prod_id,
                settings: {},
                lockedSave: false,
                items: []
            };

            // ## LOAD PROCEDURE CALLS ##

            productHazardLabelsService.loadProductHazardLabelsList(vm.model.prod_id).then(function (data) {
                angular.copy(data, vm.model.items);
            });

            // ## BUTTON LIST FUNCTIONS ##

            vm.searchTextBtnListFunc = function (item_func) {
                if (item_func === 'erase_item') {
                    vm.model.settings.searchtext = '';
                }
            };

            // ## SAVE FUNCTIONS ##

            vm.saveChanges = function () {
                // Sender kun med item_id til de som er valgt
                productHazardLabelsService.saveProductHazardLabels(vm.model.prod_id, vm.model.items.filter(e => e.item_is_selected === '1').map(e => e.item_id)).then(function (data) {
                    stateService.back();
                });
            }
        }]
    });
})();
