import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { GridService } from '../../grid.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';
import { GridRow, LookupConfig } from '../../grid.types';
import { SearchItem } from '@app/core/components/search/search.component';

@Component({
    selector: 'tt-row-edit-modal',
    templateUrl: './row-edit-modal.component.html',
    styleUrls: ['./row-edit-modal.component.css'],
})
export class RowEditModalComponent implements OnInit {
    /**
     * Map of words to translate.
     */
    public translations: { [key: string]: string } = {};

    /**
     * Styling to apply to the modal.
     */
    public style: Style = {
        input: {},
        addButton: {},
        item: {},
    };

    constructor(public dialogRef: MatDialogRef<RowEditModalComponent>, @Inject(MAT_DIALOG_DATA) public data: RowEditModalData, private gridService: GridService, private layoutService: LayoutService, private coreComponentService: CoreComponentService, private translateService: TranslateService) {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.coreComponentService.setLayoutStyle(this.style, info);
                this.style['input'].height = info.height - 1 + 'px';
                this.style['addButton'].height = info.height - 1 + 'px';
                this.style['item'].fontSize = info.fontSizes.textSize;
            }
        });
    }

    /**
     * Closes the modal and passes the edited row in the result.
     */
    public close() {
        const editedRow: GridRow = { ...this.data.row };

        this.data.fields.forEach((field) => (editedRow[field.label] = field.field));

        this.dialogRef.close(editedRow);
    }

    /**
     * Cancels editing and closes the modal.
     */
    public cancel() {
        this.data.row._dirty = false;
        this.dialogRef.close(null);
    }

    /**
     * General change handler for all edit fields.
     */
    public change() {
        this.data.row._dirty = true;
    }

    /**
     * Performs a search request for the field given as `ttSearchParm`, which is the `parms` parameter.
     *
     * @param parms the field to perform a search for.
     * @returns list of items to display as the search result.
     */
    public search = async (parms: { [key: string]: any } | undefined): Promise<SearchItem[]> => {
        if (!!parms) {
            const row: SearchFieldConfigOptions = parms as any;

            if (row) {
                return (await this.gridService.lookupSearch(row.method, row.label, row.field, this.data.row)) as SearchItem[];
            }
        }

        return [];
    };

    /**
     * Selects the given item and sets its properties to the given row.
     *
     * @param field the search field which is selecting an item.
     * @param item the item selected, if deselecting it should be `null`.
     */
    searchSelected(field: SearchFieldConfigOptions, item: null | SearchItem) {
        field.field = item?.['item_name'] ?? '';
        this.data.row[field.label] = item?.['item_name'] ?? '';

        field.lookupConfig.relations.forEach((relation) => {
            this.data.row[relation.key] = item?.[relation.value] ?? '';
        });
    }

    /**
     * Translates all words defined in `this.translations`.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.keys(this.translations));

        Object.keys(this.translations).forEach((key) => {
            if (translations[key]) {
                this.translations[key] = translations[key];
            }
        });
    }

    ngOnInit(): void {
        this.translate();

        this.dialogRef.beforeClosed().subscribe(async () => {});
    }
}

/**
 * Represents data to pass to the row-edit modal.
 */
export interface RowEditModalData {
    /**
     * The row to edit.
     */
    row: GridRow;

    /**
     * List of field configurations.
     */
    fields: FieldConfigOptions[];
}

/**
 * Represents configuration of a field for row-edit modal.
 */
export interface FieldConfig<TType extends string> {
    /**
     * The field type to configure.
     */
    type: TType;

    /**
     * The label of the field, must match the colname.
     */
    label: string;
}

/**
 * Union of field configuration types.
 */
export type FieldConfigOptions = SelectFieldConfig | CheckboxFieldConfig | InputFieldConfig | NumberInputFieldConfig | SearchFieldConfigOptions;

/**
 * Field configuration for `tt-select`.
 */
export interface SelectFieldConfig<TData = any> extends FieldConfig<'select'> {
    /**
     * The `tt-model` of the `tt-select` field.
     */
    field: string;

    /**
     * The list of data (`tt-data`) to use as options for the `tt-select`.
     */
    list: TData[];

    /**
     * The key of the id property in the options of the select.
     */
    id: keyof TData;

    /**
     * The key of the name property in the options of the select.
     */
    name: string;
}

/**
 * Field configuration for `tt-search`.
 */
export interface SearchFieldConfigOptions extends FieldConfig<'search'> {
    /**
     * The `tt-model` of the `tt-search` field.
     */
    field: string;

    /**
     * The lookup datatask keyno to use for the search.
     */
    method: number;

    /**
     * The key of the property in the result to use for displaying the item.
     */
    keyname: string;

    /**
     * Lookup configruation for the field.
     */
    lookupConfig: LookupConfig;
}

/**
 * Field configuration for `tt-checkbox`.
 */
export interface CheckboxFieldConfig extends FieldConfig<'checkbox'> {
    /**
     * The `tt-model` of the `tt-checkbox` field.
     */
    field: boolean;
}

/**
 * Generic field configration for `tt-input`, `tt-textarea`, `tt-datetime`.
 */
export interface InputFieldConfig extends FieldConfig<'input' | 'textarea' | 'datetime'> {
    /**
     * The `tt-model` value for the field.
     */
    field: string;
}

/**
 * Field configuration for `tt-input-number`.
 */
export interface NumberInputFieldConfig extends FieldConfig<'number'> {
    /**
     * The `tt-model` of the `tt-input-number` field.
     */
    field: number;

    /**
     * The number of decimals to display in the field.
     */
    decimals: number;

    /**
     * Whether the number should be formatted or not.
     */
    formatNumber: boolean;
}
