(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2DataTask', {
        templateUrl: 'views/components/views/p2DataTask/p2DataTask.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'modalService', 'ieScreenBlockService', 'p2DataTaskService', 'rememberService', function ($q, stateService, utilityService, modalService, ieScreenBlockService, p2DataTaskService, rememberService) {
            let vm = this;
            
            let variableNames = {
                searchtext: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator().then(function () { vm.searchItems() }) },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                itemsListProcedureNames: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();

                p2DataTaskService.getP2DataTask().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    ieScreenBlockService.start();

                    $q.all([
                        load()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let load = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListProcedureNames = [];
                
                return p2DataTaskService.list({ searchtext: vm.model.setting.searchtext }).then(function (result) {
                    ieScreenBlockService.stop();
                    angular.copy(result, vm.model.itemsListProcedureNames);
                });
            };
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                        
                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
            
            let openCalculator = function (value) {
                let deferred = $q.defer();
    		    
                modalService.showCalculator(vm.model.setting.searchtext).then(function (value) {
                    vm.model.setting.searchtext = value;
                    
                    deferred.resolve();
                });
                
                return deferred.promise;
            };

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            ieScreenBlockService.start();
                            load();
                        }
                        break;
                    default:
                        break;
                }
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2datataskedit':
                        go({ p2_datatask_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            vm.searchItems = function () {
                rememberFunc('searchtext');
                ieScreenBlockService.start();
                load();
            };
            
            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') {
                            rememberFunc('searchtext');
                            ieScreenBlockService.start();
                            load();
                        }
                        break;
                    default:
                        break;
                }
            };
            
            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                        
                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            ieScreenBlockService.start();
                            load();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();