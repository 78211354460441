<div
    #listboxRef
    [attr.aria-label]="ttLabel"
    ttOutsideClick
    [ignore]="ttRelativeElement"
    (onOutsideClick)="ttRelativeElement && (show = false)"
    [attr.id]="id.modal"
    role="listbox"
    class="tt-listbox__modal tt-scrollbar"
    [ngClass]="show && ttRelativeElement ? 'tt-listbox__modal--show tt-listbox__modal--shadow' : !show && ttRelativeElement ? 'tt-listbox__modal--hidden' : ''"
    [ngStyle]="style['modal']"
    [style.minWidth]="ttMinWidth"
>
    <!-- <ul role="list" class="tt-listbox__list" [ngStyle]="style['listbox']"> -->
    <ng-container *ngIf="!isDataObservable(ttData)">
        <div
            *ngFor="let item of ttData; let i = index; trackBy: trackById"
            #optionRef
            role="option"
            class="tt-listbox__item"
            tabindex="0"
            [attr.data-index]="i"
            [attr.selected]="ttSelectedIndex === i"
            (click)="onSelect(item, $event)"
            (mousedown)="$event.preventDefault()"
            [ngClass]="{ 'tt-listbox__item--selected': ttSelectedIndex === i }"
            [ngStyle]="ttSelectedIndex === i ? style['selectedOption'] : style['option']"
        >
            <span *ngIf="item['item_glyphicon']" [ngClass]="item['item_glyphicon']" [ngStyle]="{ color: item['item_glyphicon_color'] }"></span>
            <span [innerHTML]="'' + item[ttDataName] | ttListboxHighlight : ttFilterValue"></span>
        </div>
    </ng-container>
    <ng-container *ngIf="isDataObservable(ttData)">
        <div
            *ngFor="let item of data$ | async; let i = index; trackBy: trackById"
            #optionRef
            role="option"
            class="tt-listbox__item"
            tabindex="0"
            [attr.data-index]="i"
            [attr.selected]="ttSelectedIndex === i"
            (click)="onSelect(item, $event)"
            (mousedown)="$event.preventDefault()"
            [ngClass]="{ 'tt-listbox__item--selected': ttSelectedIndex === i }"
            [ngStyle]="style['option']"
        >
            <span *ngIf="item['item_glyphicon']" [ngClass]="item['item_glyphicon']" [ngStyle]="{ color: item['item_glyphicon_color'] }"></span>
            <span [innerHTML]="'' + item[ttDataName] | ttListboxHighlight : ttFilterValue"></span>
        </div>
    </ng-container>
    <!-- </ul> -->
</div>
