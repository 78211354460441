(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('businesscoSettingCargonizer', {
        templateUrl: 'views/components/views/businesscoSettingCargonizer/businesscoSettingCargonizer.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'businesscoSettingCargonizerService', function ($stateParams, stateService, translateService, modalService, businesscoSettingCargonizerService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoNo = $stateParams.businessco_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListReports: [],
                lockedSave: false,
                lockedAgreements: false,
                lockedPrinters: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                businesscoSettingCargonizerService.getEdit({ businessco_no: businessCoNo }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadCargonizerReports = function () {
                businesscoSettingCargonizerService.listCargonizerReports().then(function (result) {
                    angular.copy(result, vm.model.selectListReports);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                businesscoSettingCargonizerService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON GET TRANSPORT AGREEMENTS PROCEDURE FUNCTION CALL

            vm.getTransportAgreements = function () {
                vm.model.lockedAgreements = true;

                businesscoSettingCargonizerService.getTransportAgreements({ businessco_no: vm.model.edit.businessco_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedAgreements = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedAgreements = false;
                    }
                });
            };

            // #endregion BUTTON GET TRANSPORT AGREEMENTS PROCEDURE FUNCTION CALL

            // #region BUTTON GET CARGONIZER PRINTERS PROCEDURE FUNCTION CALL

            vm.getCargonizerPrinters = function () {
                vm.model.lockedPrinters = true;

                businesscoSettingCargonizerService.saveCargonizerPrinters({ businessco_no: vm.model.edit.businessco_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPrinters = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedPrinters = false;
                    }
                });
            };

            // #endregion BUTTON GET CARGONIZER PRINTERS PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCargonizerReports();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
