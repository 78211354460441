(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockTransferUnLoad', {
        templateUrl: 'views/components/views/stockTransferUnLoad/stockTransferUnLoad.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'stockTransferUnloadService', function ($stateParams, stateService, modalService, translateService, stockTransferUnloadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockTransferKeyno = $stateParams.stocktransfer_keyno;
            let stockKeyno = $stateParams.stock_keyno;
            let stockBatchKeyno = $stateParams.stockbatch_keyno;
            let stockLocationKeynoSuggestion = $stateParams.stocklocation_keyno_suggestion;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                stockLocationToButtons: [
                    { id: 'barcodesearchauto', icon: 'glyphicon glyphicon-barcode', color: 'primary', type: 'primary', onClick: () => lookUpLocation('barcodesearchauto') },
                    { id: 'barcodesearchmanual', icon: 'fa fa-keyboard', color: 'primary', type: 'primary', onClick: () => lookUpLocation('barcodesearchmanual') },
                    { id: 'locationsearch', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectstocklocation', { stockplace_id: vm.model.edit.stockplace_id }) }
                ],
                edit: {},
                lockedConfirm: false
            };

            vm.options = {
                quantityPlaced: {
                    autoselect: true,
                    decimals: 2
                }
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                stockTransferUnloadService.getStockTransferUnload({
                    stocktransfer_keyno: stockTransferKeyno,
                    stock_keyno: stockKeyno,
                    stockbatch_keyno: stockBatchKeyno,
                    stocklocation_keyno_suggestion: stockLocationKeynoSuggestion
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            let lookUpLocation = function (id) {
                switch (id) {
                    case 'barcodesearchauto':
                        stockTransferUnloadService.selectLocation(vm.model.edit).then(function (response) {
                            response[0].item_parms += '/1';
                            response[0].item_path += '/1';
                            stateService.go(response[0].item_state, response[0].item_parms);
                        });
                        break;
                    case 'barcodesearchmanual':
                        stockTransferUnloadService.selectLocation(vm.model.edit).then(function (response) {
                            response[0].item_parms += '/0';
                            response[0].item_path += '/0';
                            stateService.go(response[0].item_state, response[0].item_parms);
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON CONFIRM PROCEDURE FUNCTION CALL

            vm.confirmChanges = function () {
                vm.model.lockedConfirm = true;

                stockTransferUnloadService.confirmObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedConfirm = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedConfirm = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON CONFIRM PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
