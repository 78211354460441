<tt-form-field-base
    #baseRef
    [ttFieldId]="id.input"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttTextAlign]="ttTextAlign"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
>
    <input
        class="tt-input__form-control"
        #inputRef
        [attr.id]="id.input"
        [attr.type]="inputMode"
        [(ngModel)]="ttModel"
        (ngModelChange)="onModelChanged($event)"
        [ngStyle]="style['input']"
        (blur)="onBlur($event)"
        [readOnly]="ttReadonly"
        [disabled]="ttDisabled"
    />
    <ng-container buttons>
        <tt-form-field-buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
        <tt-form-field-button
            [ttIcon]="inputMode === 'password' ? 'fas fa-eye' : 'fas fa-eye-slash'"
            ttType="primary"
            (ttClick)="toddleVisibility()"
            *ngIf="!ttHideToggleButton"
        ></tt-form-field-button>
    </ng-container>
</tt-form-field-base>
