(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('p2UpdateProcedureEdit', {
        templateUrl: 'views/components/views/p2UpdateProcedureEdit/p2UpdateProcedureEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'p2UpdateProcedureEditService', function ($stateParams, stateService, p2UpdateProcedureEditService) {
            let vm = this;
            let p2UpdateProcedureKeyno = $stateParams.p2_updateprocedure_keyno;
            
            vm.model = {
                edit: {},
                lockedSave: false
            };

            let loadEdit = function () {
                p2UpdateProcedureEditService.getUpdateProcedure({ p2_updateprocedure_keyno: p2UpdateProcedureKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                p2UpdateProcedureEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = true;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
