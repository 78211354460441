(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactCalcService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2CustactSubtasksList: {
                records: []
            },
            p2CustactCalcSum: {},
            p2CustactGet: {},
            p2OverheadFromCustomerCreate: {},
            p2CustactCompReleaseList: {
                records: []
            },
            p2CustactCompTransferToOrderLine: {
                records: []
            },
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3416,
                    parameters: parms || {}
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            getP2WCustactCalcSubTasksList: function (custact_no, optiontype) {
                var deferred = $q.defer();

                var parmsP2WCustactCalcSubTasksList = {
                    method: 593,
                    parameters: {
                        custact_no: custact_no,
                        optiontype: optiontype
                    }
                };

                $ihttp.post(parmsP2WCustactCalcSubTasksList).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2CustactSubtasksList: function (custact_no, retrievetype, include_top, functiontype) {
                var deferred = $q.defer();

                var parmsP2CustactSubtasksList = {
                    method: 361,
                    parameters: {
                        custact_no: custact_no,
                        retrievetype: retrievetype,
                        include_top: include_top,
                        functiontype: functiontype
                    }
                };

                $ihttp.post(parmsP2CustactSubtasksList).then(function (data) {
                    angular.copy(data, service.p2CustactSubtasksList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2CustactCalcSum: function (custact_no, retrievetype, include_top, price_locval, calctype) {
                var deferred = $q.defer();

                var parmsP2CustactCalcSum = {
                    method: 376,
                    parameters: {
                        custact_no: custact_no,
                        retrievetype: retrievetype,
                        include_top: include_top,
                        price_locval: price_locval,
                        calctype: calctype
                    }
                };

                $ihttp.post(parmsP2CustactCalcSum).then(function (data) {
                    service.p2CustactCalcSum = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2CustactGet: function (custact_no) {
                var deferred = $q.defer();

                var parmsP2CustactGet = {
                    method: 28,
                    parameters: {
                        custact_no: custact_no
                    }
                };

                $ihttp.post(parmsP2CustactGet).then(function (data) {
                    service.p2CustactGet = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2WCustactCalcSubTasksTextList: function (custact_no, optiontype) {
                var deferred = $q.defer();

                var parmsP2WCustactCalcSubTasksTextList = {
                    method: 597,
                    parameters: {
                        custact_no: custact_no,
                        optiontype: optiontype
                    }
                };

                $ihttp.post(parmsP2WCustactCalcSubTasksTextList).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            saveP2CustactWCustactCalcEditText: function (p2WCustactCalcSubTasksTextList) {
                var deferred = $q.defer();

                var parmsP2CustactWCustactCalcEditTextSave = {
                    method: 598,
                    parameters: {
                        records: p2WCustactCalcSubTasksTextList
                    }
                };

                $ihttp.post(parmsP2CustactWCustactCalcEditTextSave).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            createP2OverheadFromCustomer: function (cust_no, custact_no) {
                var deferred = $q.defer();

                var parmsP2OverheadFromCustomerCreate = {
                    method: 868,
                    parameters: {
                        cust_no: cust_no,
                        custact_no: custact_no
                    }
                };

                $ihttp.post(parmsP2OverheadFromCustomerCreate).then(function (data) {
                    service.p2OverheadFromCustomerCreate = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            transferP2CustactCompTransferToOrderLine: function (item) {
                var deferred = $q.defer();

                var parmsP2CustactCompTransferToOrderLine = {
                    method: 384,
                    parameters: {
                        custactcomp_keyno: item.item_id
                    }
                };

                $ihttp.post(parmsP2CustactCompTransferToOrderLine).then(function (data) {
                    angular.copy(data, service.p2CustactCompTransferToOrderLine.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
