(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockZone', {
        templateUrl: 'views/components/views/stockZone/stockZone.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'stockZoneService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, stockZoneService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockPlaceId = $stateParams.stockplace_id;
            let stockZoneKeyno = $stateParams.stockzone_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListStockZoneClasses: [],
                selectListWareHouses: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                const deferred = $q.defer();

                stockZoneService.getEdit({
                    stockplace_id: stockPlaceId,
                    stockzone_keyno: stockZoneKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadWareHouses();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadStockZoneClasses = function () {
                stockZoneService.listStockZoneClasses().then(function (list) {
                    angular.copy(list, vm.model.selectListStockZoneClasses);
                });
            };

            let loadWareHouses = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id, true) !== true) return;

                return stockZoneService.listWareHouses({ stockplace_id: vm.model.edit.stockplace_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListWareHouses);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockZoneService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    stockZoneService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back('/' + 'stockzones');
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadStockZoneClasses();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
