type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;
type PromiseReject = (reason?: any) => void;

export class Deferred<T> {
    public promise: Promise<T>;
    private _resolve: PromiseResolve<T> | undefined;
    private _reject: PromiseReject | undefined;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this._reject = reject
            this._resolve = resolve
        })
    }

    public get resolve() {
        if (this._resolve === undefined) throw new Error('resolve is not defined');

        return this._resolve;
    }

    public get reject() {
        if (this._reject === undefined) throw new Error('reject is not defined');

        return this._reject;
    }
}
