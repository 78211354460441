import { default as imApp } from '../../app.module.ajs';

(function () {
    'use strict';

    imApp.controller('imAppController', ['$scope', '$window', 'appConfig', function ($scope, $window, appConfig) {
        $scope.model = {
            isOptions: {
                companyId: appConfig.companyId,
                client: appConfig.client
            },
            ispopup: new URLSearchParams($window.location.search).get('ispopup') === 'true' || false,
        };

        $scope.$on('$locationChangeSuccess', function () {
            $scope.model.ispopup = $window.location.hash.includes('ispopup');
            // say hello to your new content here
            // BUT NEVER TOUCHES THE DOM FROM A CONTROLLER

        });
    }]);
})();
