<h2 mat-dialog-title class="tt-danger-background" [ngStyle]="style['title']" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle>
    {{ translations["error"] }}
</h2>
<mat-dialog-content style="padding: 1.8rem; padding-bottom: 1rem">
    <p class="tt-error-modal__p" [style]="style['invalidtext']">
        <span
            class="tt-error-modal__icon fad fa-exclamation-triangle"
            style="--fa-primary-color: #ffffff; --fa-secondary-color: var(--tt-danger-color); --fa-secondary-opacity: 1; --fa-primary-opacity: 1"
        ></span>
        <span>
            {{ message }}
        </span>
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <tt-button class="col-xs-2 sp-0" ttText="close" ttType="danger" (ttClick)="dialogRef.close()" [ttStyle]="style"></tt-button>
</mat-dialog-actions>
