(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('domObjectChild', {
        templateUrl: 'views/components/views/domObjectChild/domObjectChild.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            domObjectKeynoParent: '<?',
            domObjectChildKeyno: '<?',
            languageId: '<?',
            domObjectKeynoSelected: '<?',
            onDelete: '&'
        },
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'base64', 'modalService', 'translateService', 'domObjectChildService', 'domObjectService', function ($stateParams, $q, stateService, utilityService, base64, modalService, translateService, domObjectChildService, domObjectService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let domObjectKeynoParent = vm?.domObjectKeynoParent ?? $stateParams.domobject_keyno_parent;
            let domObjectChildKeyno = vm?.domObjectChildKeyno ?? $stateParams.domobjectchild_keyno;
            let languageId = vm?.languageId ?? $stateParams.language_id;
            let domObjectKeynoSelected = vm?.domObjectKeynoSelected ?? $stateParams.domobject_keyno_selected;

            let translations = {
            	error: '',
                ok: '',
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: ''
            };

            vm.model = {
                domObjectChildKeyno: domObjectChildKeyno,
                domObjectChildButtons: [
                    { id: 'goto', icon: 'fas fa-sitemap', color: 'primary', type: 'primary', onClick: () => stateService.go('domobjectsview', { domobject_keyno: vm.model.edit.domobject_keyno_child }) }
                ],
                domObjectTypeNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('domobjecttypemenu', { domobjecttype_keyno: vm.model.edit.domobjecttype_keyno }) }
                ],
                domObjectTypeButtons: [
                    //{ id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.domobjecttype_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('domobjecttypes', {}) }
                ],
                edit: {},
                selectListDomObjectTypes: [],
                selectListDomObjectChild: [],
                inputListDomObjectDatas: [],
                readyText: true,
                lockedSave: false,
                lockedDelete: false,
                lockedDeleteDomObject: false
            };

            vm.style = {
                container: {},
                img: {},
                placeholder: {}
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();
                
                domObjectChildService.getDomObjectChild({
                    domobject_keyno_parent: domObjectKeynoParent,
                    domobjectchild_keyno: domObjectChildKeyno,
                    language_id: languageId,
                    webpage: stateService.getCurrentName(),
                    domobject_keyno_selected: domObjectKeynoSelected
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadDomObjectChild(),
                        loadDomObjectDatas()
                    ]).then(() => {
                        deferred.resolve();
                    });
            	});

                return deferred.promise;
            };

            let loadDomObjectTypes = function () {
                domObjectService.listDomObjectTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListDomObjectTypes);
                });
            };

            let loadDomObjectChild = function () {
                if (utilityService.validateParmsValue(vm.model.edit.domobjecttype_keyno_new, true) !== true) return;

                domObjectChildService.listDomObjectChild({ domobjecttype_keyno_new: vm.model.edit.domobjecttype_keyno_new }).then(function (result) {
                    angular.copy(result, vm.model.selectListDomObjectChild);
                });
            };

            let loadDomObjectDatas = function () {
                if (utilityService.validateParmsValue(vm.model.edit.domobjectchild_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.domobjecttype_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.language_id, true) !== true) return;
                
                vm.model.inputListDomObjectDatas = [];

                return domObjectChildService.listDomObjectDatas({
                    domobjectchild_keyno: vm.model.edit.domobjectchild_keyno,
                    domobjecttype_keyno: vm.model.edit.domobjecttype_keyno,
                    language_id: vm.model.edit.language_id,
                    domobject_keyno_selected: domObjectKeynoSelected
                }).then(function (result) {
                    angular.forEach(result, function (r) {
                        r.textvalue = base64.urldecode(r.textvalue);
                    });

                    angular.copy(result, vm.model.inputListDomObjectDatas);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'domobjecttype_keyno_new':
                        if (utilityService.validateWatchValue(value, vm.model.edit.domobjecttype_keyno_new) !== true) return;

                        vm.model.edit.domobjecttype_keyno_new = value;

                        if (utilityService.validateParmsValue(vm.model.edit.domobjecttype_keyno_new, true) !== true) return;

                        loadDomObjectChild();
                        break;
                    case 'domobjecttype_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.domobjecttype_keyno) !== true) return;

                        vm.model.edit.domobjecttype_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.domobjecttype_keyno, true) !== true) return;

                        loadDomObjectDatas();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'picturebanks':
                        angular.forEach(vm.model.inputListDomObjectDatas, function (item) {
                            if (item.domobjecttypeprop_keyno > 0 && item.is_picture_url === '1') {
                                go({
                                    argtype: 'domobjecttypeprop_keyno',
                                    argvalue: item.domobjecttypeprop_keyno
                                });
                            }
                        }); 
                        
                        break;
                    default:
                        break;
                }
            };
            
            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                if (utilityService.validateParmsValue(vm.model.edit.domobjecttype_keyno, true) !== true) return;

                vm.model.lockedSave = true;
                let decodedInputList = vm.model.inputListDomObjectDatas;
                let encodedInputList = [];

                angular.forEach(decodedInputList, function (item) {
                    encodedInputList.push({
                        domobjecttypeprop_keyno: item.domobjecttypeprop_keyno,
                        domobjecttypeprop_name: item.domobjecttypeprop_name,
                        domobjecttypeproptype_id: item.domobjecttypeproptype_id,
                        domobjecttypeprop_help: item.domobjecttypeprop_help,
                        textvalue: base64.urlencode(item.textvalue) 
                    }); 
                }); 

                let mergeObj = vm.model.edit;
                mergeObj.records = encodedInputList;
                
                domObjectChildService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;

                        if (vm.model.edit.goback === '1') stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    domObjectChildService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;

                            if (vm.model.edit.goback === '1') stateService.back();

                            if (angular.isFunction(vm.onDelete)) {
                                vm.onDelete();
                                loadEdit();
                            }
                        }
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            vm.deleteDomObjectValues = function () {
                vm.model.lockedDeleteDomObject = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    domObjectChildService.deleteDomObjectItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDeleteDomObject = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDeleteDomObject = false;

                            if (vm.model.edit.goback === '1') stateService.back();

                            if (angular.isFunction(vm.onDelete)) {
                                vm.onDelete();
                                loadEdit();
                            }
                        }
                    }, function () {
                        vm.model.lockedDeleteDomObject = false;
                    });
                }, function () {
                    vm.model.lockedDeleteDomObject = false;
                });
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                //loadEdit();
                //loadDomObjectTypes();
            };

            // #endregion ON INIT FUNCTION

            // #region ON CHANGES FUNCTION
            
            vm.$onChanges = function (changes) {
                if (changes?.domObjectKeynoParent?.currentValue) domObjectKeynoParent = changes.domObjectKeynoParent.currentValue;
                if (changes?.domObjectChildKeyno?.currentValue) domObjectChildKeyno = changes.domObjectChildKeyno.currentValue;
                if (changes?.languageId?.currentValue) languageId = changes.languageId.currentValue;
                if (changes?.domObjectKeynoSelected?.currentValue) domObjectKeynoSelected = changes.domObjectKeynoSelected.currentValue;
                
                if (domObjectKeynoParent && domObjectChildKeyno && languageId && domObjectKeynoSelected) {
                    loadEdit();
                    loadDomObjectTypes();
                }
            };
            
            // #endregion ON CHANGES FUNCTION

        }]
    });
})();
