(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customerBusCo', {
        templateUrl: 'views/components/views/customerBusCo/customerBusCo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'customerBusCoService', 'logisticService', function ($stateParams, stateService, utilityService, modalService, translateService, customerBusCoService, logisticService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let customerBusCoKeyno = $stateParams.customerbusco_keyno;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                accCustNoButtons: [
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('acc_cust_no') },
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: () => count('acc_cust_no') }
                ],
                accSuppNoButtons: [
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('acc_supp_no') },
                    { id: 'new', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: () => count('acc_supp_no') }
                ],
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                customerBusCoService.getCustomerBuscos({ customerbusco_keyno: customerBusCoKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            // ## COUNT NEXT NUMBER PROCEDURE CALLS ##

            let count = function (id) {
                switch (id) {
                    case 'acc_cust_no':
                        if (utilityService.validateParmsValue(vm.model.edit.acc_cust_no_counter_id, true) !== true) return;

                        return logisticService.getNextCounterNumber({ counter_id: vm.model.edit.acc_cust_no_counter_id }).then(function (response) {
                            if (response[0].nextnumber !== '') vm.model.edit.acc_cust_no = response[0].nextnumber;
                        });
                        break;
                    case 'acc_supp_no':
                        if (utilityService.validateParmsValue(vm.model.edit.acc_supp_no_counter_id, true) !== true) return;

                        return logisticService.getNextCounterNumber({ counter_id: vm.model.edit.acc_supp_no_counter_id }).then(function (response) {
                            if (response[0].nextnumber !== '') vm.model.edit.acc_supp_no = response[0].nextnumber;
                        });
                        break;
                    default:
                        break;
                };
            };

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (number) {
                modalService.showCalculator(vm.model.edit[number]).then(function (value) {
                    vm.model.edit[number] = value;
                });
            };

		    // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                customerBusCoService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
