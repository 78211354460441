(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productMultiUnit', {
        templateUrl: 'views/components/views/productMultiUnit/productMultiUnit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'productMultiUnitService', function ($stateParams, stateService, modalService, productMultiUnitService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
            
            vm.model = {
                prodId: $stateParams.prod_id,
                getProductMultiUnit: {},
                listUnit: [],
                listProductUnit: [],
                locked: false
            };
    
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
    		productMultiUnitService.loadProductMultiUnitGet(vm.model.prodId).then(function (data) {
    			vm.model.getProductMultiUnit = angular.copy(data[0]);
    		});
    		
            productMultiUnitService.loadUnitList('1').then(function (data) {
                angular.copy(data, vm.model.listUnit);
            });
            
            productMultiUnitService.loadProductUnitList(vm.model.prodId).then(function (data) {
                angular.copy(data, vm.model.listProductUnit);
            });

            // #######################
            // ## SAVE BUTTON FUNCTION
            // #######################
    
            vm.saveProductMultiUnit = function () {
                vm.model.locked = true;
    
                productMultiUnitService.save(vm.model.getProductMultiUnit).then(function (data) {
                    if (data[0].errorcode === '-1') {
                        modalService.show({
                            type: 'warning',
                            title: 'Feilmelding',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                    }
                });
            };
    
            // #############################
            // ## DIRECTION BUTTON FUNCTIONS
            // #############################
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'productunitsedit':
                        go({ prod_id: vm.model.prodId });
                        break;
                    case 'productunits':
                        go({ prod_id: vm.model.prodId });
                        break;
                }
            };
        }]
    });
})();
