<li
    class="bank-reconciliation__item"
    (click)="toggleSelection($event)"
    [ngClass]="{ 'bank-reconciliation__item--selected': ttSelected === true, ' bank-reconciliation__item--highlight': ttHighlight === true }"
    [ngStyle]="style['text']"
>
    <span class="bank-reconciliation-item__date">{{ date }}</span>
    <span style="display: flex; flex-direction: column">
        <span class="bank-reconciliation-item__note">{{ ttNote }}</span>
        <span *ngIf="ttSubNote.length > 0" class="bank-reconciliation-item__note">{{ ttSubNote }}</span>
    </span>
    <span class="bank-reconciliation-item__amount" [ngClass]="!!ttAmount && ttAmount < 0 ? 'bank-reconciliation-item__amount--negative' : ''">{{ amount }}</span>
</li>
