(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('batchDelPurchLinesB', {
        templateUrl: 'views/components/views/batchDelPurchLinesB/batchDelPurchLinesB.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'batchDelPurchLinesBService', function ($stateParams, stateService, modalService, batchDelPurchLinesBService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                batchDelPurchLinesBKeyno: $stateParams.batchdelpurchlinesb_keyno,
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            batchDelPurchLinesBService.getBatchDelPurchLinesB({ batchdelpurchlinesb_keyno: vm.model.batchDelPurchLinesBKeyno }).then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'checklistdatas':
                        go({
                            argtype: 'stockbatch_keyno',
                            argvalue: vm.model.edit.stockbatch_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                batchDelPurchLinesBService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();