(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bspMenu', {
        templateUrl: 'views/components/views/bspMenu/bspMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'base64', 'bspMenuService', 'rememberService', function ($stateParams, $q, stateService, utilityService, base64, bspMenuService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let bspKeyno = $stateParams.bsp_keyno;
            let bspUgSubKeyno = '';

            let variableNames = {
                bg_bspmenu_main: ''
            };

            vm.model = {
                bgBspMenuMain: {
                    selected: 'BASIS',
                    buttons: [
                        { id: 'BASIS', label: 'bg_bspmenu_main_basis', color: 'primary', onClick: () => setBspMenuMainView('BASIS') },
                        { id: 'SUB', label: 'bg_bspmenu_main_sub', color: 'primary', onClick: () => setBspMenuMainView('SUB') },
                        { id: 'TAB', label: 'bg_bspmenu_main_tab', color: 'primary', onClick: () => setBspMenuMainView('TAB') },
                        { id: 'DOCS', label: 'bg_bspmenu_main_docs', color: 'primary', onClick: () => setBspMenuMainView('DOCS') },
                        { id: 'DOC', label: 'bg_bspmenu_main_doc', color: 'primary', onClick: () => setBspMenuMainView('DOC') }
                    ]
                },
                bspNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('translateedit', { item_id: vm.model.menu.word_id }) }
                ],
                menu: {},
                selectListRecordRights: [],
                readyHtmlDescriptionBase64: true,
                gridReady: false,
                gridSubReady: false,
                gridSetReady: false,
                gridTabReady: false,
                gridBspSubReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_bspmenu_bspug_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'bspmenu_bspug_new',
                                text: 'bspmenu_bspug_new',
                                func: function () {
                                    stateService.go('bspug', {
                                        bsp_keyno: vm.model.menu.bsp_keyno,
                                        bspug_keyno: '0'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridSub = {
                dataTask: {
                    rememberId: 'w_bspmenu_bspugsub_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfuncSub(data.data); }, //null,
                gridfunc: null
            };

            var optionfuncSub = function (data) {
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    //console.log('optionfunc - CellClickHandler');
                    //console.dir(data);
                    //console.log('bspugsub_keyno: ' + data.clickedCell.dataItem.bspugsub_keyno);

                    bspUgSubKeyno = data.clickedCell.dataItem.bspugsub_keyno;

                    loadGridSet();
                }
            };

            vm.gridSet = {
                dataTask: {
                    rememberId: 'w_bspmenu_bspugset_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridTab = {
                dataTask: {
                    rememberId: 'w_bspmenu_bspugt_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridBspSub = {
                dataTask: {
                    rememberId: 'w_bspmenu_bspsub_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.editor = {
                ready: true,
                style: {
                    div: {
                        minHeight: '150px',
                        height: '100vh',
                        //maxHeight: 'min(calc(100vh - 220px), 700px)',
                        maxHeight: 'calc(100vh - 220px)',
                    },
                },
                class: {
                    base: 'col-xs-12 col-sm-12 col-xl-12 col-xxl-12 sp-0',
                    label: 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1 sp-0',
                    input: 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11 sp-0',
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setBspMenuMainView = function (id) {
                vm.model.bgBspMenuMain.selected = vm.model.menu.bg_bspmenu_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                let deferred = $q.defer();

                bspMenuService.getMenu({ bsp_keyno: bspKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                    vm.model.menu.html_description_base64 = base64.urldecode(vm.model.menu.html_description_base64);

                    stateService.setTitleOverride(vm.model.menu.bsp_name);
                    setBspMenuMainView(vm.model.menu.bg_bspmenu_main);

                    $q.all([
                        loadGrid(),
                        loadGridSub(),
                        loadGridTab(),
                        loadGridBspSub()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadRecordRights = function () {
                bspMenuService.listRecordRights().then(function (result) {
                    angular.copy(result, vm.model.selectListRecordRights);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3162,
                    parameters: { bsp_keyno: vm.model.menu.bsp_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            let loadGridSub = function () {
                vm.gridSub.dataTask.loadData = {
                    method: 3163,
                    parameters: { bsp_keyno: vm.model.menu.bsp_keyno }
                };

                vm.model.gridSubReady = true;

                if (vm.gridSub.gridfunc !== null) vm.gridSub.gridfunc.read();
            };

            let loadGridSet = function () {
                vm.gridSet.dataTask.loadData = {
                    method: 3164,
                    parameters: { bspugsub_keyno: bspUgSubKeyno }
                };

                vm.model.gridSetReady = true;

                if (vm.gridSet.gridfunc !== null) vm.gridSet.gridfunc.read();
            };

            let loadGridTab = function () {
                vm.gridTab.dataTask.loadData = {
                    method: 3165,
                    parameters: { bsp_keyno: vm.model.menu.bsp_keyno }
                };

                vm.model.gridTabReady = true;

                if (vm.gridTab.gridfunc !== null) vm.gridTab.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridBspSub = function () {
                vm.gridBspSub.dataTask.loadData = {
                    method: 3166,
                    parameters: { bsp_keyno: vm.model.menu.bsp_keyno }
                };

                vm.model.gridBspSubReady = true;

                if (vm.gridBspSub.gridfunc !== null) vm.gridBspSub.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'bg_bspmenu_main':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '.' + vm.model.menu.bsp_keyno;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bg_bspmenu_main':
                        if (utilityService.validateParmsValue(vm.model.menu[id]) !== true) return;

                        variableValue = vm.model.menu[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgBspMenuMain = function (value) {
                vm.model.bgBspMenuMain.selected = value;
                vm.model.menu.bg_bspmenu_main = vm.model.bgBspMenuMain.selected;

                rememberFunc('bg_bspmenu_main');
                if (vm.model.bgBspMenuMain.selected === 'TAB') loadRecordRights();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.selectedChanges = function () {
                let dirtyRows = vm.gridTab.gridfunc.getDirtyRows();
                let recordRightName = vm.model.selectListRecordRights.find((item) => item.item_id === vm.model.menu.recordright_no)?.item_name ?? '';

                angular.forEach(dirtyRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        item.recordright_no = vm.model.menu.recordright_no;
                        item.recordright_name = recordRightName;

                        vm.gridTab.gridfunc.redrawRow(null, item);
                    }
                });
            };

            // #region ON INIT FUNCTION

            vm.$onInit = async function () {
                await loadMenu();

                if (vm.model.bgBspMenuMain.selected === 'TAB') loadRecordRights();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
