(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ohDirectDelivery', {
        templateUrl: 'views/components/views/ohDirectDelivery/ohDirectDelivery.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'ohDirectDeliveryService', function ($stateParams, stateService, utilityService, ohDirectDeliveryService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                settingDirectDelivery: {},
                itemsListDirectDeliveries: [],
                lockedDelivery: false
            };
            
            // ## LOAD PROCEDURE CALLS ##
            
            ohDirectDeliveryService.loadOhDirectDeliveryGet(vm.model.orderInternalNo).then(function (data) {
                angular.copy(data[0], vm.model.settingDirectDelivery);
                
                loadOhDirectDeliveries();
            });
            
            var loadOhDirectDeliveries = function () {
                vm.model.itemsListDirectDeliveries = [];
                
                ohDirectDeliveryService.loadOhDirectDeliveryList(vm.model.settingDirectDelivery).then(function (data) {
                    angular.copy(data, vm.model.itemsListDirectDeliveries);
                });
            };
            
            // ## DIRECTION BUTTON FUNCTIONS ##
            
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'prepayments':
                        go({
                            argtype: 'order_internal_no',
                            argvalue: vm.model.settingDirectDelivery.order_internal_no
                        });
                        break;
                }
            };
            
            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosenDirectDeliveries = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
            
    		vm.selectAllDirectDeliveries = function () {
    			angular.forEach(vm.model.itemsListDirectDeliveries, function (item) {
    				if (angular.isUndefined(item.item_filtervalue) === true) return;
    				//if (item.item_filtervalue.toLowerCase().indexOf(vm.model.settingDirectDelivery.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
                    
    				item.item_is_selected = '1';
    			});
    		};
            
    		vm.unselectAllDirectDeliveries = function () {
                angular.forEach(vm.model.itemsListDirectDeliveries, function (item) {
    				if (angular.isUndefined(item.item_filtervalue) === true) return;
    				//if (item.item_filtervalue.toLowerCase().indexOf(vm.model.settingDirectDelivery.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
                    
    				item.item_is_selected = '0';
    			});
    		};
            
            // ## DELIVER BUTTON FUNCTION ##
            
            vm.deliverOhDirectDelivery = function () {
                vm.model.lockedDelivery = true;
                
                var mergeObj = {
                    order_internal_no: vm.model.orderInternalNo,
                    records: vm.model.itemsListDirectDeliveries.map(function (a) { return { "item_id": a.item_id, "item_name": a.item_name, "item_is_selected": a.item_is_selected }; })
                };
                
                ohDirectDeliveryService.deliver(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelivery = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelivery = false;
                    }
                });
            };
        }]
    });
})();