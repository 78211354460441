(function () {
    'use strict';

    angular.module("imApp").factory("acledgerhistoryimportImportModalService", ['$q', '$ihttp', function ($q, $ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };


        var service = {
            getImportProcesses: function () {
                return call(877, { });
            },
        };

        return service;
    }]);
})();
