(function () {
    'use strict';

    angular.module("imApp").factory("gridBookingModalService", ['$ihttp', function ($ihttp) {

        const service = {
            pageData: function (parameters) {
                return $ihttp.post({ method: 3268, parameters: parameters || {} });
            },
            addRow: function (parameters) {
                return $ihttp.post({ method: 3272, parameters: parameters || {} });
            },
            getAmountAndVatAmount: function (parameters) {
                return $ihttp.post({ method: 3269, parameters: parameters || {} });
            },
            gridbookingColChanged: function (parameters) {
                return $ihttp.post({ method: 3270, parameters: parameters || {} });
            },
            bookSubledgerWriteoff: function (parameters) {
                return $ihttp.post({
                    method: 3205,
                    parameters: parameters || {}
                });
            }
        };

        return service;
    }]);
})();
