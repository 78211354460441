import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'tt-input-buttons' // Use the selector of the AngularJS component
})
export class TtInputButtonsDirective extends UpgradeComponent {
    // Inputs
    @Input() ttLabel!: string;
    @Input() ttLabelView!: string;
    @Input() ttSublabel!: string;
    @Input() ttHideLabel!: boolean;
    @Input() ttReadonly!: boolean;
    @Input() ttModel: any;
    @Input() ttChangeArgs: any;
    @Input() ttTextAlign!: string;
    @Input() ttFocus!: boolean;
    @Input() ttPlaceholder!: string;
    @Input() ttPhone!: string;
    @Input() ttEmail!: string;
    @Input() ttButtons: any;
    @Input() ttButtonsParm: any;
    @Input() ttSearch: any;
    @Input() ttSearchChangeArgs: any;
    @Input() ttItemId!: string;
    @Input() ttRequired!: string;
    @Input() ttTranslate!: string;
    @Input() ttStyle: any;
    @Input() ttOptions: any;

    // Outputs
    @Output() ttModelChange = new EventEmitter<any>();
    @Output() ttChange = new EventEmitter<any>();
    @Output() ttOnBlur = new EventEmitter<any>();
    @Output() ttSearchChange = new EventEmitter<any>();
    @Output() ttOnSearchSelected = new EventEmitter<any>();

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ttInputButtons', elementRef, injector);
    }
}
