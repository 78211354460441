(function () {
    'use strict';

    angular.module('imApp').directive('ttPackeryItem', ['$rootScope', 'translateService', 'layoutService', 'utilityService', 'p2WebpageService', 'stateService', 'responsive', 'eventService', function ($rootScope, translateService, layoutService, us, p2WebpageService, stateService, responsive, eventService) {
        var directive = {
            restrict: 'E',
            transclude: true,
            template:
                '<div class="tt-packery-item col-xs-12 sp-0" tt-item="{{getModel().ttPackeryId}}">' +
                '   <div class="tt-packery-item-header col-xs-12" ng-if="getModel().header.visible === true || getModel().showLayoutControls">' +
                '       <div class="col-xs-12 sp-0" ng-if="getModel().header.visible">' +
                '           <h3 class= "col-xs-10 sp-0" style="margin-top: 5px; margin-bottom: 5px;" ng-style="getModel().header.style">{{ getModel().header.label}}</h3 >' +
                '           <div class="col-xs-2 sp-0" style="padding-top: 5px;">' +
                '               <div class="pull-right" tt-item-manager="{{getModel().itemsId}}"></div>' +
                '               <div class="pull-right" tt-packery-controls="{{getModel().ttPackeryId}}"></div>' +
                '           </div>' +
                '       </div>' +
                '       <div class="col-xs-12 sp-0" ng-if="getModel().header.visible !== true && getModel().showLayoutControls" style="padding-top: 5px;">' +
                '           <div class="pull-right" tt-item-manager="{{getModel().itemsId}}"></div>' +
                '           <div class="pull-right" tt-packery-controls="{{getModel().ttPackeryId}}"></div>' +
                '       </div>' +
                '   </div>' +
                '   <div class="col-xs-12" ng-style="getBodyStyle()">' +
                '       <div class="col-xs-12 sp-0" ng-if="getModel().useInclude !== true">' +
                '           <ng-transclude></ng-transclude>' +
                '       </div>' +
                '       <div class="col-xs-12 sp-0" ng-if="getModel().useInclude === true" ng-include="getModel().ttInclude"></div>' +
                '   </div>' +
                '</div>',
            link: function ($scope, _, $attr) {
                var getAttribute = function (id) {
                    if (angular.isUndefined($attr[id]))
                        return undefined;

                    var value = $scope.$eval($attr[id]);

                    return angular.isUndefined(value) || isNaN(value) ? $attr[id] : value;
                };

                var ttPackeryId = getAttribute('ttPackeryId'); // group.items: første del erid for show/hide hele gruppen, andre del er gruppe id for show/hide enkelt items i gruppen
                var ttHideHeader = getAttribute('ttHideHeader');
                var ttHeaderId = getAttribute('ttHeaderId');
                var ttInclude = getAttribute('ttInclude');

                var parts = ttPackeryId.split('.');

                var tpiModel = {
                    ttPackeryId: ttPackeryId,
                    ttInclude: ttInclude,
                    itemsId: parts[1],
                    header: {
                        visible: true,
                        label: '',
                        style: {},
                        sizeParts: []
                    },
                    body: {
                        style: {}
                    },
                    showLayoutControls: false,
                    useInclude: false
                };

                if (angular.isDefined(ttInclude) && ttInclude.length > 0) {
                    tpiModel.useInclude = true;
                }

                $scope.getBodyStyle = function () {
                    return tpiModel.body.style;
                };

                $scope.getModel = function () {
                    return tpiModel;
                };

                var userCanEditLayout = false;
                var showLayoutControls = false;

                var isSingleColumn = function () {
                    if (responsive.current === 'xx')
                        return true;
                    if (responsive.current === 'xs')
                        return true;
                    if (responsive.current === 'sm')
                        return true;
                    if (responsive.current === 'md')
                        return true;

                    return false;
                };

                var setStyle = function () {
                    if (isSingleColumn()) {
                        tpiModel.body.style = {
                            padding: '0'
                        };
                    } else {
                        tpiModel.body.style = {
                            paddingTop: '0',
                            paddingLeft: '3px',
                            paddingRight: '3px',
                            paddingBottom: '6px'
                        };
                    }
                };

                setStyle();

                var setHeaderVisibility = function (newSize) {
                    var visible = true;

                    for (var i = 0; i < tpiModel.header.sizeParts.length; i++) {
                        if (newSize !== tpiModel.header.sizeParts[i])
                            continue;

                        visible = false;
                        break;
                    }

                    tpiModel.header.visible = visible;
                };

                if (angular.isDefined(ttHideHeader)) {
                    if (angular.isString(ttHideHeader)) {
                        tpiModel.header.sizeParts = ttHideHeader.split(' ');
                    }

                    if (tpiModel.header.sizeParts.length > 1) {
                        // header visibility depends on view size.
                        setHeaderVisibility(responsive.current);
                    } else {
                        tpiModel.header.visible = !us.toBoolean(ttHideHeader);
                    }
                }

                var deregResponsive = eventService.on('event:responsive-breakpoint', function (data) {
                    setStyle();

                    if (tpiModel.header.sizeParts.length < 2)
                        return;

                    setHeaderVisibility(data.newSize);
                });

                layoutService.onLayoutChanged($scope, function (info) {
                    tpiModel.header.style = info.fontSizes.textSize;

                    userCanEditLayout = info.userCanEditLayout;

                    tpiModel.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                var stateName = stateService.getCurrentName();

                p2WebpageService.details(stateName).then(function (response) {
                    showLayoutControls = us.toBoolean(response.show_layout_controls);

                    tpiModel.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                var dereg = $rootScope.$on('p2WebpageService:showLayoutControlsChanged', function (data) {
                    showLayoutControls = us.toBoolean(data.show_layout_controls);

                    tpiModel.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                var translations = {};

                var customLabel = false;

                if (angular.isString(ttHeaderId)) {
                    translations[ttHeaderId] = '';

                    customLabel = true;
                } else {
                    translations[ttPackeryId] = '';
                }

                var deregTranslations = translateService.on(translations, 'changed', function (result) {
                    deregTranslations();

                    tpiModel.header.label = customLabel
                        ? result[ttHeaderId]
                        : result[ttPackeryId];
                });

                $scope.$on('$destroy', function () {
                    if (angular.isFunction(dereg)) {
                        dereg();
                    }

                    if (angular.isFunction(deregResponsive)) {
                        deregResponsive();
                    }
                });
            }
        };

        return directive;
    }]);
})();