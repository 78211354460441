import { Result } from "../utilities/result";

export class DataCache<T> {
    private _fileId: string = '';
    private _data: Record<string, T> = {}
    private _supportsOriginPrivateFileSystem = false;

    private constructor() { }

    public Get(id: string): Result<T> {
        return id in this._data
            ? Result.ok(this._data[id])
            : Result.fail('object not found');
    }

    public Set(id: string, data: T) {
        this._data[id] = data;
    }

    public async Update(): Promise<Result<void>> {
        await this.update();

        return Result.ok();
    }

    private async init(fileId: string): Promise<void> {
        this._supportsOriginPrivateFileSystem = this.supportsOriginPrivateFileSystem();

        if (!this._supportsOriginPrivateFileSystem) return;

        this._fileId = fileId;

        let root = await navigator.storage.getDirectory();

        let directoryHandle = await root.getDirectoryHandle('ttDataCache', { create: true });

        let fileHandle = await directoryHandle.getFileHandle(this._fileId + '.json', { create: true });

        let file: File = await fileHandle.getFile();

        let jsonData = await file.text();
        
        if (jsonData.length < 1) return undefined;

        this._data = JSON.parse(jsonData) as Record<string, T>;
    }

    private async update() {
        if (!this._supportsOriginPrivateFileSystem) return;

        const root = await navigator.storage.getDirectory();
        
        const directoryHandle = await root.getDirectoryHandle('ttDataCache', { create: true });

        const fileHandle = await directoryHandle.getFileHandle(this._fileId + '.json', { create: true });

        try {
            const writable = await fileHandle.createWritable();

            writable.write(JSON.stringify(this._data));
            writable.close();
        } catch (e) {
            console.log('ERRRRRRRROR');
            console.dir(e);
        }
    }

    private supportsOriginPrivateFileSystem(): boolean {
        if (!navigator) return false;
        if (!('storage' in navigator)) return false;
        if (!('getDirectory' in navigator.storage)) return false;
        if (typeof FileSystemDirectoryHandle.prototype.getDirectoryHandle !== 'function') return false;
        if (typeof FileSystemDirectoryHandle.prototype.getFileHandle !== 'function') return false;
        if (typeof FileSystemFileHandle.prototype.getFile !== 'function') return false;
        if (typeof FileSystemFileHandle.prototype.createWritable !== 'function') return false;
        if (typeof File.prototype.text !== 'function') return false;

        return true;
    }

    public static async Create<T>(fileId: string): Promise<Result<DataCache<T>>> {
        let dc = new DataCache<T>();

        await dc.init(fileId);

        return Result.ok(dc);
    }
}
