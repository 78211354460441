(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("gridExportArgService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        let call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        let utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        var service = {
            //validate: function (parameters) {
            //    //return call(-1, parameters || {});
            //    return [];
            //},
            loadGridExportArgGet: function (argtype, argvalue, p2_exportproc_keyno) {
                return p2DataTaskService.call(1880, {
                    argtype: argtype,
                    argvalue: argvalue,
                    p2_exportproc_keyno: p2_exportproc_keyno
                });
            },
            loadPortalUserVariableGet: function (variablename) {
                return p2DataTaskService.call(973, {
                    variablename: variablename
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadGridSetup: function (keyno) {
                return p2DataTaskService.call(1908, {
                    //p2_datatask_keyno: (keyno === undefined || keyno === null) ? 1898 : keyno //1898 was used in the original search, replace with current
                    p2_datatask_keyno: keyno
                });
            },
            searchOrderKeyname: function (value) {
                return $ihttp.post({
                    method: 2983,
                    parameters: {
                        searchtext: value
                    }
                });
            },
            searchCollectionKeyname: function (value) {
                return $ihttp.post({
                    method: 2984,
                    parameters: {
                        searchtext: value
                    }
                });
            }
        };

        return service;
    }]);
})();
