import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridService } from '../../grid.service';
import { LayoutService } from '@app/core/services/layout.service';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-print-modal',
    templateUrl: './print-modal.component.html',
    styleUrls: ['./print-modal.component.css'],
})
export class PrintModalComponent {
    /**
     * Whether data loading is completed or not.
     */
    public ready: boolean = false;

    /**
     * The layoutname entered into the input field, either to create new layout or to change name.
     */
    public layoutname: string = '';

    /**
     * Error message when trying to create new layout.
     */
    public errormessage: string = '';

    /**
     * Whether one of the column layouts matches the current layout of the grid.
     */
    public hasCurrentLayout: boolean = false;

    /**
     * Map of words to translate.
     */
    public translations: { [key: string]: string } = {};

    /**
     * Styling to apply to the modal.
     */
    public style: Style = {
        input: {},
        addButton: {},
        item: {},
    };

    constructor(public dialogRef: MatDialogRef<PrintModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private gridService: GridService, private layoutService: LayoutService, private coreComponentService: CoreComponentService, private translateService: TranslateService) {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
            }
        });
    }

    /**
     * Translates all words defined in `this.translations`.
     */
    private async translate() {
        const translations = await this.translateService.translateBatch(Object.keys(this.translations));

        Object.keys(this.translations).forEach((key) => {
            if (translations[key]) {
                this.translations[key] = translations[key];
            }
        });
    }

    ngOnInit(): void {
        this.translate();
    }
}
