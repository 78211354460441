import { default as imApp } from '../app.module.ajs';
import { ResponsiveBreakpointsComponent } from '../../app/core/components/responsive-breakpoints/responsive-breakpoints.component';
import { downgradeComponent } from '@angular/upgrade/static';

//if (typeof crypto === 'undefined')
//    var crypto = require('crypto');

if (!window?.crypto?.randomUUID)
    // https://stackoverflow.com/a/2117523/2800218
    // LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
    crypto.randomUUID = function randomUUID() {
        return (
            [1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g,
                c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
    };

(function () {
    'use strict';

    imApp
        .config(['$provide', '$httpProvider', 'appConfigProvider', 'appConfigServiceProvider', function ($provide, $httpProvider, appConfigProvider, appConfigServiceProvider) {
            var appConfigService = appConfigServiceProvider.$get();

            $httpProvider.interceptors.push('idInterceptor');

            //imApp.version = appConfigService.settings.version;

            appConfigProvider.set({
                companyId: appConfigService.settings.companyId,
                clientSessionId: appConfigService.clientSessionId,
                hashId: appConfigService.settings.hashId,
                companyName: appConfigService.settings.companyName,
                custNo: appConfigService.settings.custNo,
                custId: appConfigService.settings.clientId,
                dbDescription: appConfigService.settings.dbDescription,
                urlLogoWelcome: appConfigService.settings.urlLogoWelcome,
                siteRoot: appConfigService.settings.baseUrl,
                appRoot: appConfigService.settings.baseUrl,
                version: appConfigService.settings.version,
                service: {
                    companyInfo: 'special_ws_custip_json',
                    dataTask: 'sp_ws_p2_datatask'
                },
                client: {
                    id: appConfigService.settings.clientId,
                    canModifyTwoFaLogin: appConfigService.settings.canModifyTwoFaLogin,
                    pageSizes: [5, 10, 15, 25, 50, 75, 100],
                },
                appRoutes: angular.copy(appConfigService.settings.appRoutes),
                url: {
                    printServerUrl: appConfigService.settings.printServerUrl,
                    fileServerUrl: appConfigService.settings.fileServerUrl,
                    printServerProxyId: appConfigService.settings.printServerProxyId,
                    fileServerProxyId: appConfigService.settings.fileServerProxyId
                }
            });
        }])
        .run(['$rootScope', '$document', '$urlRouter', 'userService', 'usergroupService', 'eventService', 'jsResponsiveBridgeService', 'companyService', function ($rootScope, $document, $urlRouter, userService, usergroupService, eventService, jsResponsiveBridgeService, companyService) {
            //userService.init();

            companyService.init();
            usergroupService.init();
            jsResponsiveBridgeService.init();

            $rootScope.$on('$stateChangeSuccess', function () {
                $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
            });

            // BJS 20211109
            $rootScope.$on('$viewContentLoaded', function () {
                eventService.trigger('$viewContentLoaded', null, false);
            });

            // BJS 20211109
            $rootScope.$on('$stateChangeStart', function (evt) {
                eventService.trigger('$stateChangeStart', null, false);
            });
        }])
        .directive('ttResponsiveBreakpoints', downgradeComponent({ component: ResponsiveBreakpointsComponent }));
})();
