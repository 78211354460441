(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('timeRegisterGraph', {
        templateUrl: 'views/components/views/timeRegisterGraph/timeRegisterGraph.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$ihttp', function ($ihttp) {
            var vm = this;
            
            vm.graphlist = {
                item_id: '',
                item_name: '',
                item_graph: '',
                records: []
            };
    
            vm.onSeriesHover = function (e) {
                //console.log(kendo.format("event :: seriesHover ({0} : {1})", e.series.name, e.value));
                //console.dir(e);
            };
    
            var blogComments = [{
                "blog": "My blog",
                "day": "1",
                "value": 3,
                "userColor": "#ffd600"
            }, {
                "blog": "My blog",
                "day": "2",
                "value": 7,
                "userColor": "#ffd600"
            }, {
                "blog": "My blog",
                "day": "11",
                "value": 14,
                "userColor": "#ffd600"
            }, {
                "blog": "My blog",
                "day": "12",
                "value": 15,
                "userColor": "#ffd600"
            }, {
                "blog": "My blog",
                "day": "30",
                "value": 6,
                "userColor": "#ffd600"
            }];
    
            //console.log('vm.imDataSource.transport.data');
            //console.dir(vm.imDataSource.transport.data);
    
            var parms_graph_timeregister = {
                method: 86
            };
    
            $ihttp.post(parms_graph_timeregister).then(function (data) {
                var test = angular.toJson(data);
                
                //vm.graphlist.item_graph = data;
                //vm.graphlist.records.length = 0;
                
                //for (var i = 0; i < data.length; i++) {
                //    vm.graphlist.records.push(data[i]);
                //}
            });
    
            vm.imDataSource = new kendo.data.DataSource({
                data: blogComments
                //data: vm.graphlist.item_graph
                //transport: {
                //    read: {
                //        url: "../content/dataviz/js/spain-electricity.json",
                //        dataType: "json"
                //    }
                //},
                //sort: {
                //    field: "year",
                //    dir: "asc"
                //}
            });
        }]
    });
})();