(function () {
    'use strict';

    angular.module("imApp").factory("crmSelectionService", ['$ihttp', function ($ihttp) {
        let service = {
            getCrmSelection: function (parms) {
                return $ihttp.post({
                    method: 2907,
                    parameters: parms || {}
                });
            },
            listFacts: function (parms) {
                return $ihttp.post({
                    method: 2909,
                    parameters: parms || {}
                });
            },
            listPersonFacts: function (parms) {
                return $ihttp.post({
                    method: 2908,
                    parameters: parms || {}
                });
            },
            listBulkMails: function (parms) {
                return $ihttp.post({
                    method: 3101,
                    parameters: parms || {}
                });
            },
            addBulkEmails: function (grid) {
                return $ihttp.post({
                    method: 3106,
                    parameters: grid
                });
            },
            removeBulkEmails: function (grid) {
                return $ihttp.post({
                    method: 3107,
                    parameters: grid
                });
            }
        };

        return service;
    }]);
})();
