(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttInputScan', {
        templateUrl: 'views/components/directives/ttInputScan/ttInputScan.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttReadonly: '@',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttTextAlign: '@',
            ttPlaceholder: '@',
            ttItemId: '@',
            ttDelay: '@',
            ttFunc: '&',
            ttStyle: '<',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttInputMode: '@'   //RHE 20230102
        },
        controller: ['$element', '$timeout', 'utilityService', 'eventService', function ($element, $timeout, us, eventService) {
            var vm = this;

            var delay = 500;

            let setScanDelay = function (scanDelay) {
                if (angular.isUndefined(scanDelay)) return;
                if (scanDelay === null) return;
                if (scanDelay === '') return;
                if (isNaN(scanDelay)) return;

                if (angular.isString(scanDelay)) {
                    scanDelay = parseInt(scanDelay);
                }

                if (isFinite(scanDelay) !== true) return;

                delay = scanDelay;
            }

            var timeoutPromise = undefined;

            // BJS 20211223 - Added delay to scan function because some scanners send the scanned text in pieces as it is read. Not in one block after reading all.
            vm.onScan = function (value) {
                if (angular.isFunction(vm.ttChange) !== true)
                    return;

                if (delay === 0) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                } else {
                    if (angular.isDefined(timeoutPromise)) {
                        $timeout.cancel(timeoutPromise);
                    }

                    timeoutPromise = $timeout(function () {
                        vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                    }, delay);
                }
            };

            vm.onFunc = function (value) {
                if (angular.isFunction(vm.ttFunc) !== true)
                    return;

                vm.ttFunc({
                    $value: value
                });
            }

            vm.$onInit = function () {
                setScanDelay(vm.ttDelay);
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttDelay)) {
                    setScanDelay(changes.ttDelay.currentValue);
                }
            };
        }]
    });
})();
