(function () {
    'use strict';

    let module = angular.module('imApp');
    
    module.component('newWebpagelinkModal', {
        templateUrl: 'views/components/directives/ttWebpagemenuModals/ttWebpagemenu.newWebpagelinkModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$q', '$uibModal', 'ttWebpagemenuModalsService', 'translateService', 'translateModalService', 'userService', 'modalService', 'ieScreenBlockService', function ($q, $uibModal, ttWebpagemenuModalsService, translateService, translateModalService, userService, modalService, ieScreenBlockService) {
            var vm = this;

            var webpage_name_belongto = '';
            var p2_webpage_keyno_belongto = 0;

            var translations = {
                tt_confirm_delete_webpage: ''
            };

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            vm.buttonLocked = false;
            vm.belongtoLocked = false;

            vm.model = {
                webpage_belongto: {},
                webpage: {},
                word_id: '',
                orderby: 0,
                style: {
                    parent: {
                        backgroundColor: vm.belongtoLocked ? '#fff' : '#eee'
                    }
                }
            };

            vm.webpages = [];

            vm.wordlangButtons = [{
                id: 'translate', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: {
                    click: function () {
                        translateModalService.showModal(vm.model.word_id).then(function (response) {
                            if (angular.isDefined(response.word_id)) {
                                vm.model.word_id = response.word_id;
                            }
                        });
                    }
                }
            }];

            vm.webpageButtons = [{
                id: 'newWebpage', icon: 'glyphicon glyphicon-plus', color: 'success', onClick: {
                    click: function () {
                        modalService.showNewWebpageModal().then(function (response) {
                            vm.model.word_id = response.word_id;

                            load(response.p2_webpage_keyno);
                        });
                    }
                }
            }, {
                id: 'deleteWebpage', icon: 'glyphicon glyphicon-minus', color: 'danger', onClick: {
                    click: function () {
                        var deferred = $q.defer();

                        var modalInstance = $uibModal.open({
                            animation: true,
                            backdrop: 'static',
                            component: 'ttConfirm',
                            resolve: {
                                message: function () {
                                    return translations.tt_confirm_delete_webpage;
                                }
                            }
                        });

                        modalInstance.result.then(function (confirmed) {
                            if (confirmed !== true) {
                                deferred.resolve();
                                return;
                            }

                            ieScreenBlockService.start();

                            ttWebpagemenuModalsService.deleteWebpage(vm.model.webpage.p2_webpage_keyno).then(function () {
                                vm.model.webpage = {};
                                vm.model.word_id = '';
                                vm.model.orderby = 0;

                                ieScreenBlockService.stop();

                                load(p2_webpage_keyno_belongto);

                                deferred.resolve();
                            });
                        });

                        return deferred.promise;
                    }
                }
            }];

            vm.onWebpageChanged = function (webpage) {
                vm.model.webpage = webpage;

                if (vm.model.word_id.length > 0) return;

                var webpageList = vm.webpages.filter(function (wp) {
                    return wp.p2_webpage_keyno === webpage.p2_webpage_keyno;
                });

                if (webpageList.length > 0) {
                    vm.model.word_id = webpageList[0].word_id || webpageList[0].webpage_name;
                }
            };

            vm.create = function () {
                vm.buttonLocked = true;

                var newLinkData = {
                    p2_webpage_keyno_belongto: vm.model.webpage_belongto.p2_webpage_keyno,
                    p2_webpage_keyno: vm.model.webpage.p2_webpage_keyno,
                    params: '',
                    orderby: vm.model.orderby,
                    word_id: vm.model.word_id
                };

                ttWebpagemenuModalsService.newLink(newLinkData).then(function (response) {
                    vm.modalInstance.close(response);
                });
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve.p2_webpage_keyno_belongto) && vm.resolve.p2_webpage_keyno_belongto > 0) {
                    p2_webpage_keyno_belongto = vm.resolve.p2_webpage_keyno_belongto;
                }

                if (angular.isDefined(vm.resolve.webpage_name_belongto) && angular.isString(vm.resolve.webpage_name_belongto) && vm.resolve.webpage_name_belongto.length > 0) {
                    webpage_name_belongto = vm.resolve.webpage_name_belongto;
                }

                load();
            };

            function load(p2_webpage_keyno) {
                webpage_name_belongto = webpage_name_belongto || '';
                p2_webpage_keyno_belongto = p2_webpage_keyno_belongto || 0;

                ttWebpagemenuModalsService.newLinkInit(webpage_name_belongto).then(function (response) {
                    vm.model.orderby = response.orderby;

                    vm.webpages = response.webpages.sort(function (a, b) {
                        return a.namekey.localeCompare(b.namekey, userService.languageId, { sensitivity: 'base' });
                    });

                    if ((p2_webpage_keyno_belongto || 0) < 1 && (response.p2_webpage_keyno_belongto || 0) > 0) {
                        p2_webpage_keyno_belongto = response.p2_webpage_keyno_belongto;
                    }

                    if (p2_webpage_keyno_belongto > 0) {
                        var webpageBelongtoList = vm.webpages.filter(function (wp) {
                            return wp.p2_webpage_keyno === p2_webpage_keyno_belongto;
                        });

                        if (webpageBelongtoList.length > 0) {
                            vm.model.webpage_belongto = webpageBelongtoList[0];
                        }

                        vm.belongtoLocked = true;
                    }

                    if (angular.isDefined(p2_webpage_keyno)) {
                        var webpageList = vm.webpages.filter(function (wp) {
                            return wp.p2_webpage_keyno === p2_webpage_keyno;
                        });

                        if (webpageList.length > 0) {
                            vm.model.webpage = webpageList[0];
                        }
                    }
                });
            };
        }]
    });
})();
