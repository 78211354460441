(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('newWebpageModal', {
        templateUrl: 'views/components/directives/ttWebpagemenuModals/ttWebpagemenu.newWebpageModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<'
        },
        controller: ['ttWebpagemenuModalsService', 'translateModalService', function (ttWebpagemenuModalsService, translateModalService) {
            var vm = this;

            vm.buttonLocked = false;

            vm.model = {
                webpage_name: '',
                word_id: '',
                item_glyphicon: ''
            };

            vm.wordlangButtons = [{
                id: 'translate', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: {
                    click: function () {
                        translateModalService.showModal(vm.model.word_id).then(function (response) {
                            if (angular.isDefined(response.word_id)) {
                                vm.model.word_id = response.word_id;
                            }
                        });
                    }
                }
            }];

            vm.create = function () {
                vm.buttonLocked = true;

                ttWebpagemenuModalsService.newWebpage(vm.model).then(function (response) {
                    vm.modalInstance.close(response);
                });
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };
        }]
    });
})();
