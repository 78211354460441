(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListCrewChange', {
        templateUrl: 'views/components/views/srCheckListCrewChange/srCheckListCrewChange.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srCheckListCrewChangeService', 'watcherFactory', function($stateParams, stateService, utilityService, srCheckListCrewChangeService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                bgSrCheckListCrewChangeMain: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_srchecklistcrewchange_main_select', item_func: 'SELECT' },
                        { item_id: '1', item_name: 'bg_srchecklistcrewchange_main_signout', item_func: 'SIGNOUT' },
                        { item_id: '2', item_name: 'bg_srchecklistcrewchange_main_search', item_func: 'SEARCH' }
                    ]
                },
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'primary' }
                ],
                settingChangeCrew: {},
                itemsListChangeCrews: [],
                searchingChangeCrew: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListCrewChangeService.loadSrCheckListCrewAddGet(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.settingChangeCrew);
                vm.model.bgSrCheckListCrewChangeMain.item_default = utilityService.groupButtonIdOf(vm.model.bgSrCheckListCrewChangeMain, vm.model.settingChangeCrew.bg_srchecklistcrewchange_main);
    
                loadSrCheckListCrewAdds();
            });
    
            var loadSrCheckListCrewAdds = function () {
                if (utilityService.validateParmsValue(vm.model.settingChangeCrew.bg_srchecklistcrewchange_main) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingChangeCrew.signin_dt) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingChangeCrew.signout_dt) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingChangeCrew.searchtext, true) !== true) return;
    
                vm.model.itemsListChangeCrews = [];
    
                srCheckListCrewChangeService.loadSrCheckListCrewAddList(vm.model.settingChangeCrew).then(function (data) {
                    vm.model.searchingChangeCrew = false;
                    angular.copy(data, vm.model.itemsListChangeCrews);
                });
            };
    
            // ## BUTTON GROUP FUNCTIONS ##
    
            vm.selectBgSrCheckListCrewChangeMain = function (item) {
                vm.model.bgSrCheckListCrewChangeMain.item_selected = item;
                vm.model.settingChangeCrew.bg_srchecklistcrewchange_main = vm.model.bgSrCheckListCrewChangeMain.item_selected;
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.settingChangeCrew.searchtext = '';
                        break;
                    case 'search_item':
                        vm.searchCrews();
                        break;
                    default:
                        break;
                }
            };
    
            vm.searchCrews = function () {
                vm.model.searchingChangeCrew = true;
                loadSrCheckListCrewAdds();
            };
    
            // ## TOGGLE FUNCTIONS ##
    
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
    
                if (item.item_id === '0' || item.item_id === '-1') return;
    
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
    
    		vm.selectAll = function () {
    			angular.forEach(vm.model.itemsListChangeCrews, function (item) {
    				//if (angular.isUndefined(item.item_filtervalue) === true) return;
    				//if (item.item_filtervalue.toLowerCase().indexOf(vm.model.settingChangeCrew.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
    
    				item.item_is_selected = '1';
    			});
    		};
    
            vm.saveSrCheckListCrewChange = function () {
                vm.model.lockedSave = true;
    			var selectedItems = [];
    
    			angular.forEach(vm.model.itemsListChangeCrews, function (item) {
    				//if (angular.isUndefined(item.item_filtervalue) === true) return;
    				//if (item.item_filtervalue.toLowerCase().indexOf(vm.model.settingChangeCrew.searchtext.toLowerCase()) <= -1) return;
    				if (item.item_id === '0' || item.item_id === '-1') return;
    				if (item.item_is_selected === '0') return;
    
    				selectedItems.push(item);
                });
    
                var mergeObj = vm.model.settingChangeCrew;
                mergeObj.records = selectedItems.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; });
    
                srCheckListCrewChangeService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
    		};
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.settingChangeCrew.bg_srchecklistcrewchange_main; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                loadSrCheckListCrewAdds();
            });
        }]
    });
})();