(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2TerminalService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            p2TerminalGet: {},
            p2TerminalTypesList: {
                records: []
            },
            p2BusinessCoList: {
                records: []
            },
            p2TerminalNotificationTypesList: {
                records: []
            },
            p2TerminalPartDeliveryTypeList: {
                records: []
            },
            loadP2TerminalGet: function (p2TerminalKeyno) {
                var deferred = $q.defer();

                var parmsP2TerminalGet = {
                    method: 342,
                    parameters: {
                        p2_terminal_keyno: p2TerminalKeyno
                    }
                };

                $ihttp.post(parmsP2TerminalGet).then(function (data) {
                    service.p2TerminalGet = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2TerminalTypesList: function () {
                var deferred = $q.defer();

                var parmsP2TerminalTypesList = {
                    method: 429
                };

                $ihttp.post(parmsP2TerminalTypesList).then(function (data) {
                    angular.copy(data, service.p2TerminalTypesList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2BusinessCoList: function () {
                var deferred = $q.defer();

                var parmsP2BusinessCoList = {
                    method: 104
                };

                $ihttp.post(parmsP2BusinessCoList).then(function (data) {
                    angular.copy(data, service.p2BusinessCoList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2TerminalNotificationTypesList: function () {
                var deferred = $q.defer();

                var parmsP2TerminalNotificationTypesList = {
                    method: 430
                };

                $ihttp.post(parmsP2TerminalNotificationTypesList).then(function (data) {
                    angular.copy(data, service.p2TerminalNotificationTypesList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2TerminalPartDeliveryTypeList: function () {
                var deferred = $q.defer();

                var parmsP2TerminalPartDeliveryTypeList = {
                    method: 431
                };

                $ihttp.post(parmsP2TerminalPartDeliveryTypeList).then(function (data) {
                    angular.copy(data, service.p2TerminalPartDeliveryTypeList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2EquipmentList: function () {
                var deferred = $q.defer();

                var parmsP2EquipmentList = {
                    method: 30
                };

                $ihttp.post(parmsP2EquipmentList).then(function (data) {
                    //angular.copy(data, service.p2EquipmentList.records);

                    service.loaded = true;

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            saveP2Terminal: function (p2TerminalGet) {
                var deferred = $q.defer();

                var parmsP2TerminalSave = {
                    method: 343,
                    parameters: p2TerminalGet
                };

                $ihttp.post(parmsP2TerminalSave).then(function (data) {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deleteP2Terminal: function () {
                var deferred = $q.defer();

                var parmsP2TerminalDelete = {
                    method: 344,
                    parameters: {
                        p2_terminal_keyno: $stateParams.p2_terminal_keyno
                    }
                };

                $ihttp.post(parmsP2TerminalDelete).then(function (data) {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();