(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderDeliveryInfo', {
        templateUrl: 'views/components/views/orderDeliveryInfo/orderDeliveryInfo.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'orderDeliveryInfoService', function ($stateParams, utilityService, orderDeliveryInfoService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                deliveryInfo: {},
                gridReady: false
            };
            
            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            orderDeliveryInfoService.getOrderDeliveryInfo(vm.model.argType, vm.model.argValue).then(function (data) {
                angular.copy(data[0], vm.model.deliveryInfo);

		        if (utilityService.validateParmsValue(vm.model.deliveryInfo.grid_view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.deliveryInfo.grid_view_id;

                    vm.grid.dataTask.loadData = {
                        method: '1910', //vm.model.deliveryInfo.p2_datatask_keyno,
                        parameters: vm.model.deliveryInfo
                    };
		        }

		        vm.model.gridReady = true;
            });
        }]
    });
})();
