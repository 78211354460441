(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("pohTopsService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadPohTopsGet: function (argtype, argvalue) {
				return p2DataTaskService.call(1347, {
					argtype: argtype,
					argvalue: argvalue
				});
			},
			loadPostStatusSelectList: function (add_all) {
				return p2DataTaskService.call(1348, {
					add_all: add_all
				});
			},
			loadPohTopsList: function (setting) {
				return p2DataTaskService.call(1349, setting);
			},
			loadPohTopsCheckOrder: function (poh_keyno_top) {
				return p2DataTaskService.call(1357, {
					poh_keyno_top: poh_keyno_top
				});
			},
			setPohTopsNewStatus: function (postatus_no_new) {
				return p2DataTaskService.call(1573, {
					postatus_no_new: postatus_no_new
				});
			}
		};

		return service;
	}]);
})();
