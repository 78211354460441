(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("purchaseMenuService", [function () {
        var service = {};

        return service;
    }]);
})();