(function() {
    'use strict';

    angular.module("imApp").factory("tempstockService", ['$ihttp', function ($ihttp) {
        var service = {
            //_: function () {
            //    return $ihttp.post({
            //        method: 0,
            //        parameters: {
            //        }
            //    });
            //}
        }

        return service;
    }]);
})();
