(function () {
    'use strict';

    var module = angular.module('imApp');

    module.directive('imEditor', ['layoutService', function (layoutService) {
        var directive = {
            restrict: 'E',
            //transclude: true,
            scope: {
                label: '@',         //Used for translation of label
                plabel: '=',        //Used instead of label when property is required
                sublabel: '=',      //Used if a sublabel is wanted
                field: '=',         //
                placeholder: '=',   //
                grpsize: '@',       //Added as class and will determine the size of the grp with ability to add one or more sizes xs, sm, md, lg. Ex col-xs-8 col-lg-4
                optionfunc: '&',    //Used as reference to funtion in Controller
            },

            template:
                    '<div ng-class="addGroup()">' +
                        '<div ng-class="addClass()">' +
                            '<label ng-if="label !== undefined && label !== \'\'" class="name-label col-sm-2 im-no-pad" ng-style="{\'fontSize\': model.fontSize}" im-translate="{{label}}">' +
                                '<label ng-if="typevar.indexOf(\'q\') > -1" class="name-label-required" ng-style="{\'fontSize\': get_font.text_size}">*</label>' +
                            '</label>' +
                            '<label ng-if="plabel !== undefined && plabel !== \'\'" class="name-label col-xs-12 col-sm-2 im-no-pad" ng-style="{\'fontSize\': model.fontSize}">' +
                                '{{plabel}}' +
                            '</label>' +
                            '<label ng-if="sublabel !== undefined" class="name-sublabel col-xs-12 col-sm-10" ng-style="{\'fontSize\': model.fontSizes}">{{sublabel}}</label>' +
                            '<textarea kendo-editor col-xs-12 col-sm-10 im-no-pad></textarea>' +
                        '</div>' +
                    '</div>',
            link: function ($scope, $element, $attr) {
                $scope.model = {
                    mId: uuid(),
                    fontSize: ''
                };

                $scope.addGroup = function () {
                    var allClass = 'form-group'; //default

                    if (angular.isDefined($scope.grpsize)) {
                        allClass += ' ' + $scope.grpsize;
                    }

                    return allClass;
                }

                $scope.addClass = function () {
                    var allClass = 'col-xs-12'; //default

                    return allClass;
                }

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    $scope.model.fontSize = info.fontSizes.textSize;
                });
            }
        };

        return directive;
    }]);
})();