(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('equipmentGrp', {
        templateUrl: 'views/components/views/equipmentGrp/equipmentGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'equipmentGrpService', function ($stateParams, stateService, utilityService, modalService, equipmentGrpService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            vm.model = {
                equipmentGrpId: $stateParams.equipmentgrp_id,
                editEquipmentGroup: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            equipmentGrpService.getEquipmentGrp(vm.model.equipmentGrpId).then(function (data) {
            	angular.copy(data[0], vm.model.editEquipmentGroup);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE PROCEDURE FUNCTION CALL

            var saveEquipmentGrp = function () {
                vm.model.lockedSave = true;
                
                equipmentGrpService.saveObj(vm.model.editEquipmentGroup).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'save':
                        saveEquipmentGrp();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
            	angular.forEach(onDestroy, function (fn) {
            		if (angular.isFunction(fn) === true) {
            			fn();
            		}
            	});
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
