(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("usersService", ['$ihttp', function ($ihttp) {
        var get = function (usersService, id) {
            return usersService[id].items[usersService[id].currentIndex];
        };

        var set = function(usersService, id, value) {

            for (var i = 0; i < usersService[id].items.length; i++) {
                if (usersService[id].items[i].item_func !== value) continue;

                if (usersService[id].currentIndex === i) return false;

                usersService[id].currentIndex = i;

                return true;
            }

            return false;
        };

        var service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1027,
                    parameters: parms || {}
                });
            },
            userState: {
                currentIndex: 0,
                items: [
                    { item_id: '1', item_name: 'active', item_func: 'ACT' },
                    { item_id: '2', item_name: 'not_active', item_func: 'NOT_ACT' },
                    { item_id: '3', item_name: 'all', item_func: 'ALL' }
                ]
            },
            userInternalExternal: {
                currentIndex: 0,
                items: [
                    { item_id: '1', item_name: 'internal_users', item_func: 'i' },
                    { item_id: '2', item_name: 'external_users', item_func: 'e' }
                ]
            },
            userType: {
                currentIndex: 0,
                items: [
                    { item_id: '1', item_name: 'labours', item_func: 'L' },
                    { item_id: '2', item_name: 'terminals', item_func: 'T' }
                ]
            },
            userRole: {
                currentIndex: 0,
                items: [
                    { item_id: '1', item_name: 'suppliers', item_func: 'S' },
                    { item_id: '2', item_name: 'customers', item_func: 'C' }
                ]
            },
            getUserState: function() {
                return get(service, 'userState');
            },
            setUserState: function (userState) {
                return set(service, 'userState', userState);
            },
            getUserInternalExternal: function () {
                return get(service, 'userInternalExternal');
            },
            setUserInternalExternal: function (userInternalExternal) {
                return set(service, 'userInternalExternal', userInternalExternal);
            },
            getUserType: function () {
                return get(service, 'userType');
            },
            setUserType: function (userType) {
                return set(service, 'userType', userType);
            },
            getUserRole: function () {
                return get(service, 'userRole');
            },
            setUserRole: function (userRole) {
                return set(service, 'userRole', userRole);
            }
        }

        return service;
    }]);
})();
