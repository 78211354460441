(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("oeeDashBoardService", ['$ihttp', '$timeout', function ($ihttp, $timeout) {
        let service = {
            reloaded: false,
            listOeeDashboards: function (parms) {
                return $ihttp.post({
                    method: 256,
                    parameters: parms || {}
                });
            },
            reloadP2OeeDashBoardList: function () {
                service.reloaded = false;

                $timeout(function () {
                    service.reloaded = true;
                }, 30000);

                return service.reloaded;
            }
        };

        return service;
    }]);
})();
