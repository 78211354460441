(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('metaTable', {
        templateUrl: 'views/components/views/metaTable/metaTable.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'metaTableService', 'translateService', function ($stateParams, stateService, utilityService, modalService, metaTableService, translateService) {
            var vm = this;

            var translations = {
                metatable_confirm_title: 'Warning',
                //metatable_confirm_message: 'Do you really want to delete this meta table?',
                metatable_confirm_ok_label: 'OK',
                metatable_confirm_cancel_label: 'Cancel'
            };

            vm.model = {
                metaTableKeyno: $stateParams.metatable_keyno,
                edit: {},
                selectListRelatedTo: [],
                lockedSave: false,
                lockedDelete: false
            };

            metaTableService.getMetaTable({ metatable_keyno: vm.model.metaTableKeyno }).then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            metaTableService.listRelatedToMetaTables().then(function (data) {
                angular.copy(data, vm.model.selectListRelatedTo);
            });

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'table_name_related_to':
                        vm.model.edit.table_name_related_to = value;

                        if (utilityService.validateParmsValue(vm.model.edit.table_name_related_to, true) !== true) return;
                        
                        vm.model.edit.metatable_table_name = vm.model.edit.table_name_related_to;
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                metaTableService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.metatable_confirm_title,
                    message: vm.model.edit.delete_warning, //translations.metatable_confirm_message,
                    okLabel: translations.metatable_confirm_ok_label,
                    cancelLabel: translations.metatable_confirm_cancel_label
                }).then(function () {
                    metaTableService.deleteObj(vm.model.edit).then(function (data) {
                        if (data[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: 'Varsel',
                                message: data[0].errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back(2);
                        }
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });
        }]
    });
})();