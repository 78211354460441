(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('currencyExRateHist', {
        templateUrl: 'views/components/views/currencyExRateHist/currencyExRateHist.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'currencyExRateHistService', function ($stateParams, currencyExRateHistService) {
            const vm = this;
            let currencyExRateKeyno = $stateParams.currencyexrate_keyno;

            vm.model = {
    			itemsListHistory: []
            };

            let loadHistory = function () {
                currencyExRateHistService.listHistory({ currencyexrate_keyno: currencyExRateKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListHistory);
                });
            };

            vm.$onInit = function () {
                loadHistory();
            };
        }]
    });
})();
