(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("textileStockSearchService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadTextileStockSearchGet: function () {
                return p2DataTaskService.call(1467, {});
            },
            loadTextileStockSearchList: function (searchtext) {
                return p2DataTaskService.call(1468, {
                    searchtext: searchtext
                });
            }
        };

        return service;
    }]);
})();