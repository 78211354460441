(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('docPacksBAdd', {
        templateUrl: 'views/components/views/docPacksBAdd/docPacksBAdd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'docPacksBAddService', 'logisticService', 'typeaheadService', 'ttGridFactory', 'rememberService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, docPacksBAddService, logisticService, typeaheadService, ttGridFactory, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let docPackKeyno = $stateParams.docpack_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                prod_id: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                gridStockBatchesReady: false
            };

            vm.gridStockBatches = new ttGridFactory({
                rememberId: 'w_' + stateService.getCurrentName() + '_' + 'stockbatch' + '_' + 'grid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setCustomToolbarButtons([{
                name: 'docpacksbadd_add',
                text: 'docpacksbadd_add',
                func: function () {
                    let currentData = vm.gridStockBatches.gridfunc.getDirtyRows();
                    let selectedItems = [];

                    angular.forEach(currentData, function (item) {
                        if (item.is_selected === '0' || item.is_selected === false) return;

                        selectedItems.push({ stockbatch_keyno: item.stockbatch_keyno });
                    });

                    let mergeObj = angular.copy(vm.model.setting);
                    mergeObj.records = selectedItems;
                    
                    docPacksBAddService.addItems(mergeObj).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            stateService.back();
                        }
                    });
                },
                icon: 'fa-plus',
                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                translate: true,
                //disabled: function () { return; }
                //disabled: function () {
                //	if (vm.model.setting.show === '1') {
                //		return false;
                //	} else {
                //		return 'hidden';
                //	}
                //}
            }]);
            //.setOptionFunc((data) => optionfunc(data.data));

            //let optionfunc = function (data) {};

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

            	docPacksBAddService.getSetting({ docpack_keyno: docPackKeyno }).then(function (info) {
            		angular.copy(info[0], vm.model.setting);

                    loadStockPlaces();
                    loadGridStockBatches();

                    deferred.resolve();
            	});

            	return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridStockBatches = function () {
                vm.gridStockBatches.dataTask.loadData = {
                    method: 3299,
                    parameters: vm.model.setting
                };

                vm.model.gridStockBatchesReady = true;

                if (vm.gridStockBatches.gridfunc !== null) vm.gridStockBatches.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'prod_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.setting[id] = value;

                        return typeaheadService.searchProduct({ prod_name: vm.model.setting[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'prod_name':
                        if (item) {
                            vm.model.setting.prod_id = item?.item_id ?? '';
                            vm.model.setting.prod_name = item?.item_name ?? '';

                            rememberFunc('prod_id');
                            loadGridStockBatches();
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.setting.prod_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.setting.prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'prod_name':
                        vm.model.setting.prod_id = '';
                        vm.model.setting.prod_name = '';

                        rememberFunc('prod_id');
                        loadGridStockBatches();
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        loadGridStockBatches();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
