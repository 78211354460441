(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('projectProcesses', {
        templateUrl: 'views/components/views/projectProcesses/projectProcesses.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'projectProcessesService', function ($stateParams, $q, stateService, utilityService, projectProcessesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                setting: {},
                itemsListPosts: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadSetting = function () {
                const deferred = $q.defer();

                projectProcessesService.getSetting({ project_keyno: $stateParams.project_keyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
    
                    loadPosts();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadPosts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.project_keyno) !== true) return;
                
                return projectProcessesService.listPosts({ project_keyno: vm.model.setting.project_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListPosts);
                });
            };

            // #endregion LOAD PROCEDURE CALL
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'projectprocess':
                        go({
                            project_keyno: vm.model.setting.project_keyno,
                            projectprosess_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
