(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('finishPohScan', {
        templateUrl: 'views/components/views/finishPohScan/finishPohScan.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'finishPohScanService', 'stateService', function($timeout, finishPohScanService, stateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;

            vm.model = {
                state: {
                    loading: false,
                },
                filter_pohscan: {
                    barcode: '',
                    records: []
                },
                p2_w_finishpohscan_settings: {},
                p2_poh_scan: {},
                itemsListStockBatchLasts: []
            };
            
            // ###############
            // PROCEDURE CALLS
            // ###############
            
            // Loading settings data
            finishPohScanService.loadP2WFinishPohScanSettings().then(function () {
                vm.model.p2_w_finishpohscan_settings = finishPohScanService.p2_w_finishpohscan_settings;
                
                // Redirecting to a new webpage if webpagename has value
                if (vm.model.p2_w_finishpohscan_settings.webpagename !== '') {
                    stateService.go(vm.model.p2_w_finishpohscan_settings.webpagename, {
                        webpagename: stateService.getCurrentName()
                    });
                }

                if (vm.model.p2_w_finishpohscan_settings.show_last_produced_stockbatches === '1') {
                    loadStockBatchLasts();
                }
            });

            var loadStockBatchLasts = function () {
                vm.model.itemsListStockBatchLasts = [];

                finishPohScanService.listStockBatchLasts(vm.model.p2_w_finishpohscan_settings).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockBatchLasts);
                });
            };

            // #############
            // SCAN FUNCTION
            // #############
            
            vm.searchPohScan = function () {
                if (angular.isUndefined(vm.model.filter_pohscan.barcode) || vm.model.filter_pohscan.barcode.length == 0) return;

                // Activating spin function and icon
                vm.model.state.loading = true;

                if (angular.isDefined(vm.model.filter_pohscan.barcode) && vm.model.filter_pohscan.barcode !== '') {
                    finishPohScanService.genericFunction(154, { barcode: vm.model.filter_pohscan.barcode }).then(function (data) {
                        vm.model.p2_poh_scan = angular.copy(data[0]);

                        if (angular.isDefined(vm.model.p2_poh_scan)) {
                            if (vm.model.p2_poh_scan.barcodetype == 'LA') {
                                finishPohScanService.loadP2WFinishPohScanSettings().then(function () {
                                    vm.model.p2_w_finishpohscan_settings = finishPohScanService.p2_w_finishpohscan_settings;
                                });
                            } else if (vm.model.p2_poh_scan.barcodetype == 'PO' || vm.model.p2_poh_scan.barcodetype == 'PT') {
                                stateService.go(vm.model.p2_poh_scan.webpagename, vm.model.p2_poh_scan.parms);
                            }
                        }

                        vm.clearField();
                    });
                }

                vm.model.state.loading = false;
                vm.clearField();
            };
            
            vm.clearField = function () {
                $timeout(function () {
                    vm.model.filter_pohscan.barcode = '';
                }, 100);
            };
            
            // #################
            // DIRECTION BUTTONS
            // #################
            
            vm.goToSelectLabourPu = function (index) {
                stateService.go('selectlabourpu', {
                    webpagename: stateService.getCurrentName()
                });
            };
            
            vm.goToSelectOperationPu = function (index) {
                stateService.go('selectoperationpu', {
                    webpagename: stateService.getCurrentName()
                });
            };
            
            vm.goToSelectEquipmentPu = function (index) {
                stateService.go('selectequipmentpu', {
                    webpagename: stateService.getCurrentName(),
                    operation_id: vm.model.p2_w_finishpohscan_settings.operation_id
                });
            };
            
            vm.goToPaperLessPo = function () {
                stateService.go('paperlesspo');
            };
            
            vm.goToPoFinishList = function (index) {
                stateService.go('pofinishlist');
            };
            
            vm.goToProductionStatus = function () {
                stateService.go('productionstatus');
            };
        }]
    });
})();