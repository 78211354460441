(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockIncoming', {
        templateUrl: 'views/components/views/stockIncoming/stockIncoming.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockIncomingService', function ($stateParams, stockIncomingService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;

            vm.model = {
                itemsListStockIncomings: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadStockIncomings = function () {
                stockIncomingService.listStockIncomings({ stock_keyno: stockKeyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockIncomings);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStockIncomings();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
