(function () {
    'use strict';

    angular.module("imApp").factory("priceUpdateService", ['$ihttp', function ($ihttp) {
        var service = {
            getPriceUpdate: function (parms) {
                return $ihttp.post({
                    method: 2079,
                    parameters: parms || {}
                });
            },
            listRoundTo: function (parms) {
                return $ihttp.post({
                    method: 2081,
                    parameters: parms || {}
                });
            },
            calcPriceUpdates: function (parms) {
                return $ihttp.post({
                    method: 2082,
                    parameters: parms || {}
                });
            },
            updateNextPrice: function (parms) {
                return $ihttp.post({
                    method: 2084,
                    parameters: parms || {}
                });
            },
            updatePriceOld: function (parms) {
                return $ihttp.post({
                    method: 2085,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();