(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deviationTypeLangs', {
        templateUrl: 'views/components/views/deviationTypeLangs/deviationTypeLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'deviationTypeLangsService', function ($stateParams, stateService, deviationTypeLangsService) {
            let vm = this;
            let deviationTypeKeyno = $stateParams.deviationtype_keyno;
            
            vm.model = {
                inputListLanguages: []
            };

            let loadLanguages = function () {
                deviationTypeLangsService.listDeviationTypeLanguages({ deviationtype_keyno: deviationTypeKeyno }).then(function (result) {
                    angular.copy(result, vm.model.inputListLanguages);
                });
            };
            
            vm.saveChanges = function () {
                deviationTypeLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadLanguages();
            };
        }]
    });
})();
