(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('userSettings', {
        templateUrl: 'views/components/views/userSettings/userSettings.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'stateService', 'appConfig', 'languageService', 'userService', 'idServerService', 'translateService', 'modalService', 'ieScreenBlockService', 'userStore', 'themeService', 'utilityService', function ($q, $stateParams, stateService, appConfig, languageService, userService, idServerService, translateService, modalService, ieScreenBlockService, userStore, themeService, us) {
            var vm = this;
            var onDestroy = [];

            var saveToCache = function () {
                userService.userEditCache.set(vm.model.user, vm.model.idUser, vm.model.translations);
            };

            var onLabourSearch = function () {
                saveToCache();

                stateService.go('selectlabour', { webpage_name: 'usersettings' });
            };

            vm.onDevelopModeChanged = function (value) {
                vm.model.user.develop_mode = value;

                userService.developMode = vm.model.user.develop_mode === '1';
            };

            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'portalusersignature':
                        go({ portal_user_keyno: vm.model.user.portal_user_keyno_edit });
                        break;
                    case 'labouredit':
                        go({ labour_no: vm.model.user.labour_no });
                        break;
                    case 'portaluserstockplace':
                        go({ portal_user_keyno: vm.model.portalUserKeyno });
                        break;
                    case 'usersettings_dialogue_userinfo':
                        saveToCache();

                        go({ portalUserKeyno: vm.model.portalUserKeyno });
                        break;
                    case 'userusergroup':
                        saveToCache();

                        go({ get_username: vm.model.portalUserKeyno });
                        break;
                    case 'sendResetPasswordLink':
                        ieScreenBlockService.start();

                        idServerService.sendResetPassword(vm.model.user.user_id_portal, true, true).then(function () {
                            ieScreenBlockService.stop();

                            showMessage(vm.model.translations.reset_password_ok_message, vm.model.translations.reset_password_ok_title, 'success');
                        }, function (error) {
                            showMessage(error.data.message, 'Error', 'danger');
                        });
                        break;
                    case 'resetPassword':
                        saveToCache();

                        go({ userName: vm.model.user.user_id_portal });
                        break;
                    case 'addLoginUser':
                        ieScreenBlockService.start();

                        idServerService.addUser(vm.model.user.user_id_portal, vm.model.user.email, vm.model.user.mobilephone, uuid()).then(function () {
                            ieScreenBlockService.stop();

                            load();
                        }, function (error) {
                            showMessage(error.data.message, 'Error', 'danger');
                        });
                        break;
                    case 'removeLoginUser':
                        ieScreenBlockService.start();

                        idServerService.removeUser(vm.model.user.user_id_portal).then(function () {
                            ieScreenBlockService.stop();

                            load();
                        }, function (error) {
                            showMessage(error.data.message, 'Error', 'danger');
                        });
                        break;
                    case 'addUserLoginAdmin':
                        ieScreenBlockService.start();

                        idServerService.addCompanyAdmin(vm.model.user.user_id_portal).then(function () {
                            ieScreenBlockService.stop();

                            load();
                        }, function (error) {
                            showMessage(error.data.message, 'Error', 'danger');
                        });
                        break;
                    case 'removeUserLoginAdmin':
                        ieScreenBlockService.start();

                        idServerService.removeCompanyAdmin(vm.model.user.user_id_portal).then(function () {
                            ieScreenBlockService.stop();

                            load();
                        }, function (error) {
                            showMessage(error.data.message, 'Error', 'danger');
                        });
                        break;
                }
            };

            vm.model = {
                portalUserKeyno: $stateParams.userid,
                breadcrumbViews: [],
                inputStyles: [],
                labourNoButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goTo, state: 'labouredit' } }
                ],
                isTouchTimeDeveloper: false,
                user: {},
                idUser: {
                    enableSendLoginLink: false,
                    enablePasswordReset: false,
                    enableAddUser: false,
                    enableRemoveUser: false,
                    enableAddAdmin: false,
                    enableRemoveAdmin: false,
                    twoFaEnabled: false,
                    twoFaType: '',
                    userName: '',
                    details: {}
                },
                twoFaTypes: [
                    { item_id: 'Email', item_name: 'Epost' },
                    { item_id: 'SmsCode', item_name: 'SMS' },
                    { item_id: 'SmsAndEmail', item_name: 'Email og SMS' }
                ],
                languages: [],
                canSave: false,
                translations: {
                    reset_password_ok_title: '',
                    reset_password_ok_message: '',
                    ok_label: '',
                    tt_breadcrumb_false: '',
                    tt_breadcrumb_true: '',
                    tt_breadcrumb_toggle: '',
                    tt_input_style_underline: '',
                    tt_input_style_frames: '',
                    theme_dark: '',
                    theme_light: '',
                    tt_email: '',
                    tt_sms: '',
                    tt_emailAndSms: '',
                    fontsize: ''
                },
                labourButtons: [
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', onClick: onLabourSearch }
                ],
                twoFaLoginDisabled: true
            };

            var original = {
                userName: '',
                email: '',
                phone: ''
            };

            // flag indicating if twoFa has been changed and should be updated
            var twoFaDirty = false;

            vm.twoFaChanged = function (id, value) {
                switch (id) {
                    case 'twoFaEnabled':
                        vm.model.idUser[id] = us.toBoolean(value);
                        break;
                    default:
                        vm.model.idUser[id] = value;
                        break;
                }

                twoFaDirty = true
            };

            var showMessage = function (message, title, type) {
                modalService.show({
                    type: type || 'default',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: vm.model.translations.ok_label,
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                        }
                    }]
                });
            };


            (async function _translate() {
                const data = await translateService.translateBatch(vm.model.translations);
                Object.keys(vm.model.translations).forEach((key) => {
                    if (data?.[key]) vm.model.translations[key] = data[key];

                    vm.model.breadcrumbViews = [
                        { item_id: 'false', item_name: vm.model.translations.tt_breadcrumb_false },
                        { item_id: 'true', item_name: vm.model.translations.tt_breadcrumb_true },
                        { item_id: 'toggle', item_name: vm.model.translations.tt_breadcrumb_toggle },
                    ]

                    vm.model.inputStyles = [
                        { item_id: '0', item_name: vm.model.translations.tt_input_style_underline },
                        { item_id: '1', item_name: vm.model.translations.tt_input_style_frames },
                    ]

                    vm.model.twoFaTypes[0].item_name = vm.model.translations.tt_email;
                    vm.model.twoFaTypes[1].item_name = vm.model.translations.tt_sms;
                    vm.model.twoFaTypes[2].item_name = vm.model.translations.tt_emailAndSms;
                });
            })();


            vm.onInputStyleChanged = function (value) {
                vm.model.user.input_style = value;
                userService.updateInputStyles(value);
            }
            
            vm.onThemeChanged = function (value) {
                vm.model.user.theme_keyno = value;
                userService.updateTheme(value);
            }

            var loadFromCache = function () {
                if (userService.userEditCache.user === null) return false;
                if (userService.userEditCache.user.portal_user_keyno_edit !== vm.model.portalUserKeyno) return false;

                angular.copy(userService.userEditCache.user, vm.model.user);
                angular.copy(userService.userEditCache.idUser, vm.model.idUser);
                angular.copy(userService.userEditCache.translations, vm.model.translations);

                if (userService.userEditCache.selectedLabourNo !== '' && userService.userEditCache.selectedLabourName !== '') {
                    vm.model.user.labour_no = userService.userEditCache.selectedLabourNo;
                    vm.model.user.labour_name = userService.userEditCache.selectedLabourName;
                }

                userService.userEditCache.reset();

                vm.model.canSave = true;

                return true;
            };

            var loadCompleted = function () {
                vm.model.canSave = true;

                ieScreenBlockService.stop();
            };

            var userLoaded = function (loggedInUser, selectedUser, isLoggedInUser) {
                // if a user has the CompanyAdmin role on a "higher" level than the company level, then
                // canRemoveCompanyAdmin === false and it is not possible to modify the companyadmin role.
                vm.model.idUser.enableSendLoginLink = loggedInUser.isCompanyAdmin === true || isLoggedInUser === true;
                vm.model.idUser.enablePasswordReset = loggedInUser.isCompanyAdmin === true || isLoggedInUser === true;
                vm.model.idUser.enableRemoveUser = loggedInUser.isCompanyAdmin === true;
                vm.model.idUser.enableAddAdmin = loggedInUser.isCompanyAdmin === true && selectedUser.canRemoveCompanyAdmin !== false && selectedUser.isCompanyAdmin !== true;
                vm.model.idUser.enableRemoveAdmin = loggedInUser.isCompanyAdmin === true && selectedUser.canRemoveCompanyAdmin === true && selectedUser.isCompanyAdmin === true;

                vm.model.twoFaLoginDisabled = !appConfig.client.canModifyTwoFaLogin || (loggedInUser.isCompanyAdmin !== true && isLoggedInUser === false);

                vm.model.idUser.twoFaEnabled = selectedUser.twoFactorEnabled;
                vm.model.idUser.twoFaType = selectedUser.twoFactorType;
                vm.model.idUser.userName = selectedUser.userName;

                loadCompleted();
            };

            var load = async function () {
                ieScreenBlockService.start();

                vm.model.twoFaLoginDisabled = true;

                vm.model.themes = await themeService.getThemes();
                
                vm.model.idUser.enableSendLoginLink = false;
                vm.model.idUser.enablePasswordReset = false;
                vm.model.idUser.enableAddUser = false;
                vm.model.idUser.enableRemoveUser = false;
                vm.model.idUser.enableAddAdmin = false;
                vm.model.idUser.enableRemoveAdmin = false;
                
                idServerService.currentUserDetails().then(function (loggedInUser) {
                    console.dir(loggedInUser);

                    vm.model.twoFaLoginDisabled = !loggedInUser.isCompanyAdmin;

                    userService.details(vm.model.portalUserKeyno).then(function (details) {
                        original.userName = details.user_id_portal;
                        original.email = details.email || '';
                        original.phone = details.mobilephone || '';
                        
                        angular.copy(details, vm.model.user);
                        
                        if (angular.isObject(loggedInUser) !== true || angular.isUndefined(loggedInUser.userName) === true) {
                            loadCompleted();
                            return;
                        }
                        
                        if (loggedInUser.userName === vm.model.user.user_id_portal) {
                            userLoaded(loggedInUser, loggedInUser, true);
                        } else {
                            if (loggedInUser.isCompanyAdmin === true) {
                                idServerService.userDetails(vm.model.user.user_id_portal).then(function (cu) {
                                    if (angular.isObject(cu) && angular.isDefined(cu.userName)) {
                                        if (cu.canAccessClient === true) {
                                            userLoaded(loggedInUser, cu, false);
                                        } else {
                                            vm.model.idUser.enableAddUser = loggedInUser.isCompanyAdmin === true;

                                            loadCompleted();
                                        }
                                    } else {
                                        vm.model.idUser.enableAddUser = loggedInUser.isCompanyAdmin === true;

                                        loadCompleted();
                                    }
                                });
                            } else {
                                loadCompleted();
                            }
                        }
                    });
                });
            };

            // BJS 20220419 - This setting is enabled by adding the "TouchTimeDeveloper" role to the root user in the id-manager.
            //                It cannot be activated from inside TouchTime.
            vm.model.isTouchTimeDeveloper = userStore.isTouchTimeDeveloper;

            if (loadFromCache() !== true) {
                load();
            }

            languageService.list().then(function (data) {
                angular.copy(data, vm.model.languages);
            });

            // #region FIELD CHANGE MULTI FUNCTIONS

            document.getElementById("fontsize").onchange = function () {
                onFontSizeChanged()
            };

            function onFontSizeChanged() {
                userService.ensureIsReady().then(function () {
                    if (userService.userId !== vm.model.portalUserKeyno) return;
                    if (vm.model.user.fontsize === '') return;
                    
                    userService.fontSize = vm.model.user.fontsize + 'px';
                });
            }

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'label_always_on_top':
                        userService.ensureIsReady().then(function () {
                            if (userService.userId !== vm.model.portalUserKeyno) return;
                            if (us.validateWatchValue(value, vm.model.user.label_always_on_top) !== true) return;

                            vm.model.user.label_always_on_top = value;

                            if (us.validateParmsValue(vm.model.user.label_always_on_top) !== true) return;
                            
                            userService.labelAlwaysOnTop = vm.model.user.label_always_on_top === '1';
                        });
                        break;
                    default:
                        us.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            vm.saveUserSettings = function () {
                if (vm.model.canSave !== true) return;

                vm.model.canSave = false;

                ieScreenBlockService.start();

                var promises = [];

                if (twoFaDirty === true) {
                    if (us.toBoolean(vm.model.idUser.twoFaEnabled) === true) {
                        promises.push(idServerService.addTwoFactor(vm.model.idUser.userName, vm.model.idUser.twoFaType));
                    } else {
                        promises.push(idServerService.removeTwoFactor(vm.model.idUser.userName));
                    }
                }

                promises.push(userService.save(vm.model.user));

                $q.all(promises).then(function () {
                    vm.model.canSave = true;

                    ieScreenBlockService.stop();
                    stateService.back();
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
