(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('shiftView', {
        templateUrl: 'views/components/views/shiftView/shiftView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'shiftViewService', 'utilityService', function ($stateParams, stateService, modalService, translateService, shiftViewService, utilityService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                labourNo: $stateParams.labour_no,
                bgYearSelector: {
                    selected: 'this',
                    buttons: [
                        { id: 'prev', label: 'prev_year', color: 'primary', onClick: () => vm.model.bgYearSelector.selected = 'prev' },
                        { id: 'this', label: 'this_year', color: 'primary', onClick: () => vm.model.bgYearSelector.selected = 'this' },
                        { id: 'next', label: 'next_year', color: 'primary', onClick: () => vm.model.bgYearSelector.selected = 'next' }
                    ]
                },
                bgShiftViewMain: {
                    selected: 'CALENDAR',
                    buttons: [
                        { id: 'CALENDAR', label: 'bg_shiftview_main_calendar', color: 'primary', onClick: () => vm.model.bgShiftViewMain.selected = 'CALENDAR' },
                        { id: 'GRAPH', label: 'bg_shiftview_main_graph', color: 'primary', onClick: () => vm.model.bgShiftViewMain.selected = 'GRAPH' },
                        { id: 'GRID', label: 'bg_shiftview_main_grid', color: 'primary', onClick: () => vm.model.bgShiftViewMain.selected = 'GRID' }
                    ]
                },
                bgTimePeriod: {
                    selected: 'WEEK',
                    buttons: [
                        { id: 'WEEK', label: 'bg_timeperiod_week', color: 'primary', onClick: () => vm.model.bgTimePeriod.selected = 'WEEK' },
                        { id: 'MONTH', label: 'bg_timeperiod_month', color: 'primary', onClick: () => vm.model.bgTimePeriod.selected = 'MONTH' }
                    ]
                },
                settingShiftView: {},
        		dataSource: {
                    dates: [],
                    months: {},
                    monthnames: {},
                    dayletters: {}
                },
                shifts: {
                    total_shift_days: 0,
                    records: [],
                    recordsIndexed: {}
                },
                selectedShift: {},
                graphDataShiftView: {
                    //orderby: 'orderby',
                    //orderby: 'item_name',
                    //orderby: 'item_id',
                    records: []
                },
                gridReady: false,
                lockedSave: false
        	};
    
            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };
    
            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}
    
                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}
    
                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            shiftViewService.getShiftView(vm.model.labourNo).then(function (data) {
                angular.copy(data[0], vm.model.settingShiftView);
    
    		    if (utilityService.validateParmsValue(vm.model.settingShiftView.view_id) === true) {
    		    	vm.grid.dataTask.rememberId = vm.model.settingShiftView.view_id;
    		    }
    
                loadGrid();
    		    
    		    vm.model.gridReady = true;
            });
    
            var loadGrid = function () {
                var year = (new Date()).getFullYear();
                
                if (vm.model.bgYearSelector.selected === 'prev') {
                    year -= 1;
                }
                
                if (vm.model.bgYearSelector.selected === 'next') {
                    year += 1;
                }
                
                var parms = {
                    year: year,
                    labour_no: vm.model.labourNo
                };
                
                if (utilityService.validateParmsValue(vm.model.settingShiftView.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.settingShiftView.p2_datatask_keyno,
                        parameters: parms
                    };
                }
            };
    
            vm.setShiftSelected = function (index) {
                var i = 0;
    
                angular.forEach(vm.model.shifts.records, function (item) {
                    if (i !== index) {
                        item.selected_style = 'border: 1px solid white;';
                    } else {
                        item.selected_style = 'border: 1px solid #414141;';
                        vm.model.selectedShift = item;
                    }
    
                    i += 1;
                });
            };
    
            var loadData = function () {
                if (!utilityService.objectLength(vm.model.shifts.records) > 0) {
                    shiftViewService.loadShifts(vm.model.labourNo).then(function () {
                        vm.model.shifts = shiftViewService.shifts;
                        loadActualDates();
                    });
                } else {
                    loadActualDates();
                }
            };
    
            loadData();
    
            var loadActualDates = function () {
                var year = (new Date()).getFullYear();
                
                if (vm.model.bgYearSelector.selected == 'prev') {
                    year -= 1;
                }
                
                if (vm.model.bgYearSelector.selected == 'next') {
                    year += 1;
                }
                
                shiftViewService.loadDates(year, vm.model.labourNo).then(function () {
                    vm.model.dataSource = shiftViewService.dataSource;
                    vm.summarize();
                    
                    //if (!utilityService.objectLength(vm.model.shifts.records) > 0){
                    //    vm.loadShifts();
                    //}
                });
            };
    
            vm.summarize = function () {
                //var ds = [];
                vm.model.shifts.total_shift_days = 0;
    
                angular.forEach(vm.model.shifts.records, function (item) {
                    item.shift_days = 0;
                });
    
                angular.forEach(vm.model.dataSource.months, function (weeks) {
                    angular.forEach(weeks, function (days) {
                        angular.forEach(days, function (day) {
                            if (day.shift_id !== '') {
                                vm.model.shifts.recordsIndexed[day.shift_id].shift_days += 1;
                                vm.model.shifts.total_shift_days += 1;
                            }
                        });
                    });
                });
            };
    
            vm.loadShifts = function () {
                shiftViewService.loadShifts(vm.model.labourNo).then(function () {
                    vm.model.shifts = shiftViewService.shifts;
    
                    angular.forEach(vm.model.shifts.records, function (item) {
                        item.selected_style = 'border: 1px solid white;';
                    });
                });
            };
    
            var loadGraphDataShiftView = function () {
                var year = (new Date()).getFullYear();
                
                if (vm.model.bgYearSelector.selected === 'prev') {
                    year -= 1;
                }
                
                if (vm.model.bgYearSelector.selected === 'next') {
                    year += 1;
                }
                
                if (utilityService.validateParmsValue(vm.model.bgTimePeriod.selected) !== true) return;
                
                shiftViewService.illustrateGraphData(year, vm.model.labourNo, vm.model.bgTimePeriod.selected).then(function (data) {
                    angular.copy(data, vm.model.graphDataShiftView.records);
                });
            };
            
            vm.monthClick = function (month) {
                if ((month != '') && (utilityService.objectLength(vm.model.selectedShift) > 0)) {
                    $('.m_' + month).each(function () {
                        vm.elementClicked($(this));
                    });
                }
            };
    
            vm.dayClick = function (dl, m) {
                if ((dl != '') && (m != '')) {
                    $('.dl_' + dl + '_' + m).each(function () {
                        vm.elementClicked($(this));
                    });
                }
            };
    
            vm.tableCellClick = function (click_date) {
                if (click_date != '') {
                    vm.elementClicked($('#cell_' + click_date));
                }
            };
    
            vm.weekClick = function (weekno) {
                if (weekno != '') {
                    $('.w_' + weekno).each(function () {
                        vm.elementClicked($(this));
                    });
                }
            };
    
            vm.elementClicked = function (elem) {
                if ($(elem).hasClass('p_0')) {
                    var month = $(elem).attr('o-month');
                    var week = $(elem).attr('o-week');
                    var day = $(elem).attr('o-day');
                    vm.model.dataSource.months[month][week][day].ischanged = '1';
                    
                    if ((angular.isDefined(vm.model.selectedShift.item_id)) && (vm.model.selectedShift.item_id !== '')) {
                        if (vm.model.dataSource.months[month][week][day].shift_id !== '') {
                            //change or remove
                            if (vm.model.selectedShift.item_id == vm.model.dataSource.months[month][week][day].shift_id) {
                                //remove
                                vm.model.dataSource.months[month][week][day].shift_id = '';
                                vm.model.dataSource.months[month][week][day].shift_background_color_hex = vm.model.dataSource.months[month][week][day].original_background_color_hex;
                                vm.model.dataSource.months[month][week][day].shift_text_color_hex = vm.model.dataSource.months[month][week][day].original_text_color_hex;
                                vm.model.dataSource.months[month][week][day].background_color_hex = vm.model.dataSource.months[month][week][day].original_background_color_hex;
                                vm.model.dataSource.months[month][week][day].text_color_hex = vm.model.dataSource.months[month][week][day].original_text_color_hex;
                            } else {
                                //change
                                vm.model.dataSource.months[month][week][day].shift_id = vm.model.selectedShift.item_id;
                                vm.model.dataSource.months[month][week][day].shift_background_color_hex = vm.model.selectedShift.background_color_hex;
                                vm.model.dataSource.months[month][week][day].shift_text_color_hex = vm.model.selectedShift.text_color_hex;
                                vm.model.dataSource.months[month][week][day].background_color_hex = vm.model.dataSource.months[month][week][day].shift_background_color_hex;
                                vm.model.dataSource.months[month][week][day].text_color_hex = vm.model.dataSource.months[month][week][day].shift_text_color_hex;
                            }
                        } else {
                            //add
                            vm.model.dataSource.months[month][week][day].shift_id = vm.model.selectedShift.item_id;
                            vm.model.dataSource.months[month][week][day].shift_background_color_hex = vm.model.selectedShift.background_color_hex;
                            vm.model.dataSource.months[month][week][day].shift_text_color_hex = vm.model.selectedShift.text_color_hex;
                            vm.model.dataSource.months[month][week][day].background_color_hex = vm.model.dataSource.months[month][week][day].shift_background_color_hex;
                            vm.model.dataSource.months[month][week][day].text_color_hex = vm.model.dataSource.months[month][week][day].shift_text_color_hex;
                        }
                        vm.cellRender(vm.model.dataSource.months[month][week][day]);
                    }
                } else {
    
                }
    
                vm.summarize();
            };
    
            vm.cellRender = function(day){
                //var codecount = utilityService.objectLength(day.codes);
                var shift_id = day.shift_id;
    
                if (shift_id !== ''){
                    day.background_color_hex = day.shift_background_color_hex;
                    day.text_color_hex = day.shift_text_color_hex;
                } else {
                    day.background_color_hex = day.original_background_color_hex;
                    day.text_color_hex = day.original_text_color_hex;
                }
            };

            // #region SAVE BUTTON FUNCTION
    
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var ds = [];
    
                angular.forEach(vm.model.dataSource.months, function (weeks) {
                    angular.forEach(weeks, function (days) {
                        angular.forEach(days, function (day) {
                            if (day.systemdate !== '' && day.ischanged === '1') {
                                ds.push({
                                    systemdate: day.systemdate,
                                    shift_id: day.shift_id,
                                    labour_no: day.labour_no
                                });
                            }
                        });
                    });
                });
                
                shiftViewService.saveObj(ds).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };
    
    		// #endregion SAVE BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgYearSelector = function (value) {
                vm.model.bgYearSelector.selected = value;

                if (vm.model.bgShiftViewMain.selected === 'CALENDAR') {
                    var ds = [];

                    angular.forEach(vm.model.dataSource.months, function (weeks) {
                        angular.forEach(weeks, function (days) {
                            angular.forEach(days, function (day) {
                                if (day.systemdate !== '' && day.ischanged === '1') {
                                    ds.push({
                                        systemdate: day.systemdate,
                                        shift_id: day.shift_id,
                                        labour_no: day.labour_no
                                    });
                                }
                            });
                        });
                    });

                    shiftViewService.saveObj(ds).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            loadData();
                        }
                    });
                }

                if (vm.model.bgShiftViewMain.selected === 'GRAPH') {
                    loadGraphDataShiftView();
                }

                if (vm.model.bgShiftViewMain.selected === 'GRID') {
                    loadGrid();
                    vm.grid.gridfunc.rebind();
                }
            };
            
            vm.selectBgShiftViewMain = function (value) {
                vm.model.bgShiftViewMain.selected = value;

                if (vm.model.bgShiftViewMain.selected === 'CALENDAR') {
                    vm.model.graphDataShiftView.records = [];
                    var ds = [];

                    angular.forEach(vm.model.dataSource.months, function (weeks) {
                        angular.forEach(weeks, function (days) {
                            angular.forEach(days, function (day) {
                                if (day.systemdate !== '' && day.ischanged === '1') {
                                    ds.push({
                                        systemdate: day.systemdate,
                                        shift_id: day.shift_id,
                                        labour_no: day.labour_no
                                    });
                                }
                            });
                        });
                    });

                    shiftViewService.saveObj(ds).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            if (value === 'GRAPH') {
                                loadGraphDataShiftView();
                            }

                            if (value === 'GRID') {
                                loadGrid();
                                vm.grid.gridfunc.rebind();
                            }
                        }
                    });
                } else {
                    if (value === 'CALENDAR') {
                        loadData();
                    }

                    if (value === 'GRAPH') {
                        loadGraphDataShiftView();
                    }

                    if (value === 'GRID') {
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                    }
                }
            };
            
            vm.selectBgTimePeriod = function (value) {
                vm.model.bgTimePeriod.selected = value;

                loadGraphDataShiftView();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
        }]
    });
})();
