(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('powtimeGroup', {
        templateUrl: 'views/components/views/powtimeGroup/powtimeGroup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$rootScope', '$ihttp', '$stateParams', 'stateService', 'imHeaderService', function ($rootScope, $ihttp, $stateParams, stateService, imHeaderService) {
            const vm = this;

            vm.model = {
                filter: {},
                heading: {
                    text: '',
                    translate: false,
                },
                powTimes: [],
                groupTypeTabs: {
                    selected: $stateParams.grouptype,
                    buttons: [
                        { id: 'Y', label: 'year' },
                        { id: 'M', label: 'month', },
                        { id: 'D', label: 'date', },
                        { id: 'L', label: 'labour', },
                        { id: 'E', label: 'equipment', },
                        { id: 'C', label: 'customer', },
                        { id: 'P', label: 'powtime_entries', },
                    ],
                },
                amountTabs: {
                    selected: 'S',
                    buttons: [
                        { id: 'S', label: 'salaryhours', },
                        { id: 'A', label: 'amount', }
                    ]
                },
                displayTabs: {
                    selected: 'B',
                    buttons: [
                        { id: 'L', label: 'list', },
                        { id: 'G', label: 'graph', },
                        { id: 'B', label: 'both', }
                    ]
                }
            }

            vm.onGroupTypeTabChanged = function (value) {
                vm.model.groupTypeTabs.selected = value;

                if (vm.model.groupTypeTabs.selected === 'P' && vm.model.displayTabs.selected === 'G') {
                    vm.model.displayTabs.selected = 'L';
                }

                getPowTimeGroupList();
                setHeaderTitle();
            };

            vm.onAmountTabChanged = function (value) {
                vm.model.amountTabs.selected = value;

                getPowTimeGroupList();
            };

            vm.onDisplayTabChanged = async function (value) {
                vm.model.displayTabs.selected = value;

                await getPowTimeGroupList();
                setTimeout(() => {
                    kendo.resize($("div.k-chart[data-role='chart']"));
                    $rootScope.$apply();
                }, 200);
            }

            function getStateParams() {
                return {
                    cust_no: $stateParams.cust_no,
                    project_keyno: $stateParams.project_keyno,
                    projectprosess_keyno: $stateParams.projectprosess_keyno,
                    custact_no: $stateParams.custact_no,
                    date_start: $stateParams.date_start,
                    date_end: $stateParams.date_end,
                    grouptype: vm.model.groupTypeTabs.selected,
                    equipment_id: $stateParams.equipment_id,
                    labour_no: $stateParams.labour_no,
                    prod_id: $stateParams.prod_id,
                    amounttype: vm.model.amountTabs.selected,
                    displaytype: vm.model.displayTabs.selected,
                    webpage: stateService.getCurrentPath()
                };
            };

            async function getPowTimeFilter() {
                vm.model.filter = (await $ihttp.post({ method: 91, parameters: getStateParams() }))[0];

                vm.model.groupTypeTabs.selected = vm.model.filter.grouptype;
                vm.model.amountTabs.selected = vm.model.filter.amounttype;
                vm.model.displayTabs.selected = vm.model.filter.displaytype;
            };

            async function getPowTimeGroupList() {
                vm.model.powTimes = [];
                vm.model.powTimes = await $ihttp.post({ method: 90, parameters: getStateParams() });
            };

            function setHeaderTitle() {
                imHeaderService.setTitleText('powtimegroup_title', true, true);
                vm.model.heading.text = 'powtimegroup_title';
                vm.model.heading.translate = true;
            };

            vm.$onInit = async function () {
                await getPowTimeFilter();
                setHeaderTitle();
                getPowTimeGroupList();
            };

        }]
    });
})();
