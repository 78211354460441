(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderpickStatus', {
        templateUrl: 'views/components/views/orderpickStatus/orderpickStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$uibModal', 'rememberService', 'orderpickStatusService', function ($uibModal, rememberService, orderpickStatusService) {
            var vm = this;

            let rememberId = {
                grid: 'orderpickstatus.grid',
                fields: 'orderpickstatus.fields'
            };

            vm.fields = {
                stockplace_id: '',
                status_id: 0,
                dates: {
                    from: null,
                    to: null,
                    bindex: 1
                }
            };

            vm.stockplaces = [];
            vm.status = [];

            vm.grid = {
                dataTask: {
                    rememberId: rememberId.grid,
                    loadSetupId: null,
                    loadData: {
                        method: 2496,
                        parameters: function () {
                            return {
                                stockplace_id: vm.fields.stockplace_id,
                                status_id: vm.fields.status_id,
                                from_date: vm.fields.dates.from,
                                to_date: vm.fields.dates.to
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'stocktransfers', text: 'tt_stocktransfers', func: function () { gotoStocktransfers(); }, icon: 'glyphicon-chevron-right', cssClass: 'btn btn-primary im-grid-btn-xs-r' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            { name: 'lines', text: '', func: onShowLines, icon: 'fa-line-columns', type: 'primary' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            function onShowLines(dataItem) {
                $uibModal.open({
                    component: 'orderpickLines',
                    resolve: {
                        parameters: function () {
                            return {
                                stocktransfer_keyno: dataItem.stocktransfer_keyno
                            };
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });
            };

            vm.onDataChanged = function (value, modelId) {
                var parts = modelId.split('.');

                vm.fields[parts[parts.length - 1]] = value;

                rememberService.remember(rememberId.fields, vm.fields);

                vm.grid.gridfunc.read();
            };

            vm.onSelectedDateChanged = function (fom, tom, bindex) {
                vm.fields.dates.from = fom;
                vm.fields.dates.to = tom;
                vm.fields.dates.bindex = bindex;

                rememberService.remember(rememberId.fields, vm.fields);

                vm.grid.gridfunc.read();
            };

            vm.$onInit = function () {
                rememberService.getLastStatus(rememberId.fields, true).then(function (response) {
                    vm.fields.stockplace_id = response.stockplace_id;
                    vm.fields.status_id = response.status_id;

                    angular.copy(response.dates, vm.fields.dates);
                });

                orderpickStatusService.init().then(function (response) {
                    angular.copy(response.stockplaces, vm.stockplaces);
                    angular.copy(response.status, vm.status);
                });
            };

            function gotoStocktransfers() {
                $uibModal.open({
                    component: 'orderpickStatusStl',
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });
            };
        }]
    });
})();
