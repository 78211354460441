(function () {
    'use strict';

    angular.module("imApp").factory("tailorAccessDataService", ['$ihttp', function ($ihttp) {
        let service = {
            getTailorAccessData: function (parms) {
                return $ihttp.post({
                    method: 2101,
                    parameters: parms || {}
                });
            },
            listPKeyTypes: function (parms) {
                return $ihttp.post({
                    method: 2103,
                    parameters: parms || {}
                });
            },
            saveObj: function (editTailoredAccessData) {
                return $ihttp.post({
                    method: 2104,
                    parameters: editTailoredAccessData
                });
            },
            deleteObj: function (editTailoredAccessData) {
                return $ihttp.post({
                    method: 2105,
                    parameters: editTailoredAccessData
                });
            }
        };

        return service;
    }]);
})();
