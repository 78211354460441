(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttConfirm', {
        templateUrl: 'views/components/directives/ttConfirm/ttConfirm.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&'
        },
        controller: [function () {
            var vm = this;

            vm.message = '';

            vm.$onInit = function () {
                vm.message = vm.resolve.message;
            };

            vm.ok = function () {
                vm.close({ $value: true })
            };

            vm.cancel = function () {
                vm.close({ $value: false })
            };
        }]
    });
})();
