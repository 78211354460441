<!-- <p>checkbox-floating-filter works!</p> -->
<div class="filter">
    <label class="filter__label" [ngClass]="checkedFilter === true ? 'filter__label--check' : ''">
        <span class="fal fa-check-square"></span>
        <input type="checkbox" hidden [(ngModel)]="checkedFilter" (ngModelChange)="onCheckedFilerChanged($event)" />
    </label>
    <label class="filter__label" [ngClass]="uncheckedFilter === true ? 'filter__label--check' : ''">
        <span class="fal fa-square"></span>
        <input type="checkbox" hidden [(ngModel)]="uncheckedFilter" (ngModelChange)="onUncheckedFilerChanged($event)" />
    </label>
</div>
