(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('modelMenu', {
        templateUrl: 'views/components/views/modelMenu/modelMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modelMenuService', function ($stateParams, stateService, modelMenuService) {
            let vm = this;
            let modelId = $stateParams.model_id;
            
            vm.model = {
                modelNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('modellangs', { model_id: vm.model.menu.model_id }) }
                ],
                menu: {}
            };

            let loadMenu = function () {
                modelMenuService.getModelMenu({ model_id: modelId }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'model':
                        go({ model_id: vm.model.menu.model_id });
                        break;
                    case 'custactsearch':
                        go({
                            filterfield: 'model_id',
                            filtervalue: vm.model.menu.model_id,
                            custacttype: 'A'
                        });
                        break;
                    default:
                        break;
                }
            };
            
            vm.deleteValues = function () {
                modelMenuService.deleteItem(vm.model.menu).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
        }]
    });
})();
