(function () {
    'use strict';

    angular.module("imApp").factory("workGrpMenuService", ['$ihttp', function ($ihttp) {
        let service = {
            getMenu: function (parms) {
                return $ihttp.post({
                    method: 2021,
                    parameters: parms || {}
                });
            }
		};

        return service;
	}]);
})();
