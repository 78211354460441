(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("trNewJobService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            getTrMenu: function (parms) {
                return $ihttp.post({
                    method: 122,
                    parameters: parms || {}
                });
            },
            getNewJobMenu: function (parms) {
                return $ihttp.post({
                    method: 445,
                    parameters: parms || {}
                });
            },
            scanProductionOrder: function (menu) {
                return $ihttp.post({
                    method: 154,
                    parameters: menu
                });
            },
            savePoFinish: function (p2_pofinish_keyno, webpagename) {
                var deferred = $q.defer();

                var parms_savePoFinish = {
                    method: 448,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno,
                        webpagename: webpagename
                    }
                };

                $ihttp.post(parms_savePoFinish).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveObj: function (menu) {
                return $ihttp.post({
                    method: 142,
                    parameters: menu
                });
            },
            resetNewJob: function (menu) {
                return $ihttp.post({
                    method: 449,
                    parameters: menu
                });
            },
            correctionNextJob: function (menu) {
                return $ihttp.post({
                    method: 450,
                    parameters: menu
                });
            }
        };

        return service;
    }]);
})();
