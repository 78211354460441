(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectOperationPuService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            p2_select_operation_pu_list: {
                item_id: '',
                item_name: '',
                orderby: '',
                records: []
            },
            loadP2SelectOperationPuList: function (webpagename, isDirect) {
                var deferred = $q.defer();

                var parms_p2_select_operation_pu_list = {
                    method: 199,
                    parameters: {
                        webpagename: webpagename,
                        direct: isDirect
                    }
                };

                $ihttp.post(parms_p2_select_operation_pu_list).then(function (data) {
                    service.p2_select_operation_pu_list.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_select_operation_pu_list.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveToPreP2SelectOperationPu: function (item) {
                var deferred = $q.defer();

                var parms_p2_select_operation_pu_save = {
                    method: 200,
                    parameters: {
                        operation_id: item.item_id,
                        webpagename: $stateParams.webpagename
                    }
                };

                $ihttp.post(parms_p2_select_operation_pu_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();