(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travelBills', {
        templateUrl: 'views/components/views/travelBills/travelBills.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'travelBillsService', 'logisticService', 'rememberService', function ($stateParams, $q, stateService, utilityService, travelBillsService, logisticService, rememberService) {
            
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let webPageName = stateService.getCurrentName();

            let variableNames = {
                labour_no: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };
            
            vm.model = {
                labourButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('labour_no') }
                ],
                setting: {},
                selectListLabours: [],
                itemsListTravelBills: []
            };
    
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                travelBillsService.getTravelBills({
                    argtype: argType,
                    argvalue: argValue,
                    webpage_name: webPageName
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadTravelBills()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadLabours = function () {
                travelBillsService.listLabours().then(function (result) {
                    angular.copy(result, vm.model.selectListLabours);
                });
            };
    
            let loadTravelBills = function () {
                if (utilityService.validateParmsValue(vm.model.setting.labour_no, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;

                vm.model.itemsListTravelBills = [];
                
                return travelBillsService.listTravelBills(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListTravelBills);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'labour_no':
                        case 'date_fom':
                        case 'date_tom':
                        case 'dateselector_index':
                            variableNames[key] = stateService.getCurrentName() + '/' + argType + '/' + argValue + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'labour_no':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'labour_no':
                        vm.model.setting.labour_no = '0';

                        if (vm.model.setting.labour_no === '0') {
                            rememberFunc('labour_no');
                            loadTravelBills();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'travelbill':
                        go({
                            argtype: vm.model.setting.argtype,
                            argvalue: vm.model.setting.argvalue,
                            travelbill_no: '0'
                        });
                        break;
                    case 'travelexpence':
                        logisticService.getNextCounterNumber({ counter_id: 'travelexpence_keyno' }).then(function (response) {
                            stateService.go('travelexpence', {
                                travelbill_no: '0',
                                travelexpence_keyno: response[0].nextnumber
                            });
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'labour_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.labour_no) !== true) return;

                        vm.model.setting.labour_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.labour_no, true) !== true) return;

                        rememberFunc('labour_no');
                        loadTravelBills();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadTravelBills();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadLabours();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
