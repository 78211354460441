(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('srFollowUpForward', {
        templateUrl: 'views/components/views/srFollowUpForward/srFollowUpForward.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'srFollowUpForwardService', function ($stateParams, stateService, utilityService, modalService, srFollowUpForwardService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                experienceKeyNo: $stateParams.experience_keyno,
                edit: {},
                selectListResources: [],
                lockedSend: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            srFollowUpForwardService.getSrFollowUpForward({ experience_keyno: vm.model.experienceKeyNo }).then(function (data) {
                angular.copy(data[0], vm.model.edit);

                loadResources();
            });

            var loadResources = function () {
                if (utilityService.validateParmsValue(vm.model.edit.experience_keyno) !== true) return;

                vm.model.selectListResources = [];

                srFollowUpForwardService.listResources({ experience_keyno: vm.model.edit.experience_keyno }).then(function (data) {
                    angular.copy(data, vm.model.selectListResources);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE PROCEDURE FUNCTION CALL

            vm.sendSrFollowUpForward = function () {
                vm.model.lockedSend = true;
                
                srFollowUpForwardService.sendObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSend = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSend = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();
