(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('compValidationSet', {
        templateUrl: 'views/components/views/compValidationSet/compValidationSet.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'compValidationSetService', function($stateParams, stateService, modalService, compValidationSetService) {
            var vm = this;

    		vm.model = {
    			compKeyNo: $stateParams.comp_keyno,
    			compValidationFilterBtnList: [
    				{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
    			],
    			setting: {},
    			itemsListValidations: {
    				records: []
    			},
    			itemsListCombinations: {
    				records: []
    			},
    			lockedSave: false
    		};
            
    		compValidationSetService.loadCompValidationSetGet(vm.model.compKeyNo).then(function (data) {
    			vm.model.setting = angular.copy(data[0]);
                
    			loadValidations();
    		});
            
    		var loadValidations = function () {
    			vm.model.itemsListValidations.records = [];
                
    			compValidationSetService.loadCompValidationSetList(vm.model.compKeyNo).then(function (data) {
    				angular.copy(data, vm.model.itemsListValidations.records);
                    
    				loadCombinations();
    			});
    		};
            
    		var loadCombinations = function () {
    			vm.model.itemsListCombinations.records = [];
                
    			compValidationSetService.loadCompValidationSetCombinationList(vm.model.itemsListValidations).then(function (data) {
    				angular.copy(data, vm.model.itemsListCombinations.records);
    			});
    		};
            
    		vm.toggleChosenValidations = function (item) {
    			if (item.item_id === '0') return;
    			if (item.item_id === '-1') return;
                
    			if (item.item_is_selected === '0') {
    				item.item_is_selected = '1';
    			} else if (item.item_is_selected === '1') {
    				item.item_is_selected = '0';
    			}
                
    			loadCombinations();
    		};
            
    		vm.toggleChosenCombinations = function (item) {
    			if (item.item_id === '0') return;
    			if (item.item_id === '-1') return;
                
    			if (item.item_is_selected === '0') {
    				item.item_is_selected = '1';
    			} else if (item.item_is_selected === '1') {
    				item.item_is_selected = '0';
    			}
    		};
            
    		vm.compValidationFilterBtnListFunc = function (item_func) {
    			if (item_func === 'erase_item') {
    				vm.model.setting.compvalidation_filter = '';
    			}
    		};
            
    		vm.saveCompValidationSet = function () {
    			vm.model.lockedSave = true;
                
    			compValidationSetService.save(vm.model.compKeyNo, vm.model.itemsListValidations.records, vm.model.itemsListCombinations.records).then(function (data) {
    				if (data[0].errorcode !== '0') {
    					modalService.show({
    						type: 'warning',
    						title: 'Varsel',
    						message: data[0].errormessage,
    						buttons: [{
    							label: 'OK',
    							cssClass: 'btn-warning',
    							action: function (dialogItself) {
    								dialogItself.close();
    								vm.model.lockedSave = false;
    							}
    						}]
    					});
    				} else {
    					stateService.back();
    					vm.model.lockedSave = false;
    				}
    			});
    		};
    	}]
    });
})();