(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('domObject', {
        templateUrl: 'views/components/views/domObject/domObject.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'domObjectService', function ($stateParams, stateService, domObjectService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let domObjectKeynoParent = $stateParams.domobject_keyno_parent;
            let domObjectKeynoChild = $stateParams.domobject_keyno_child;

            vm.model = {
                edit: {},
                selectListDomObjectTypes: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                domObjectService.getDomObject({
                    domobject_keyno_parent: domObjectKeynoParent,
                    domobject_keyno_child: domObjectKeynoChild
                }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);
            	});
            };
            
            let loadDomObjectTypes = function () {
                domObjectService.listDomObjectTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListDomObjectTypes);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadDomObjectTypes();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
