(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentSplitService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadConsignmentSplitGet: function (consignment_keyno_splitfrom) {
                return p2DataTaskService.call(1445, {
                    consignment_keyno_splitfrom: consignment_keyno_splitfrom
                });
            },
            loadConsignmentSplitList: function (consignment_keyno_splitfrom) {
                return p2DataTaskService.call(1446, {
                    consignment_keyno_splitfrom: consignment_keyno_splitfrom
                });
            },
            save: function (splitList) {
                return p2DataTaskService.call(1447, splitList);
            }
        };

        return service;
    }]);
})();