(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('verticalPurchasing', {
        templateUrl: 'views/components/views/verticalPurchasing/verticalPurchasing.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'translateService', 'modalService', 'verticalPurchasingService', 'ttGridFactory', function ($stateParams, $q, stateService, translateService, modalService, verticalPurchasingService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderInternalNo = $stateParams.order_internal_no;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                gridOlReady: false,
                gridOlmReady: false
            };

            vm.gridOl = new ttGridFactory({
                rememberId: 'w_' + stateService.getCurrentName() + '.' + 'grid',
                loadData: {},
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                gridfunc: null
            })
                .setToolbar({ wrapping: true })
                .setOptionFunc((data) => optionfuncOl(data.data));

            vm.gridOl.onDataBound = loadGridOlm;

            let optionfuncOl = function (data) {
                //console.dir(data);

                if (data.func === 'CheckboxBoxClick' && data.key === 'verticalpurchasing_whole') {
                    //console.dir(data.dataItem);
                    //console.log('Endra: ' + data.dataItem.orderline_keyno);
                    loadGridOlm();
                }
            };

            vm.gridOlm = new ttGridFactory({
                rememberId: 'w_' + stateService.getCurrentName() + '_olm_grid',
                loadData: {},
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'verticalpurchasing_create_purchases',
                                text: 'verticalpurchasing_create_purchases',
                                func: function () {
                                    let allData = vm.gridOlm.gridfunc.getAllRows();
                                    let currentData = [];

                                    angular.forEach(allData, function (item) {
                                        if (angular.isDefined(item.is_selected) && item.is_selected === true) {
                                            currentData.push(item);
                                        }
                                    });

                                    let mergeObj = angular.copy(vm.model.setting);
                                    mergeObj.olmrecords = currentData;

                                    verticalPurchasingService.purchaseItems(mergeObj).then(function (response) {
                                        if (response[0].errorcode !== '0') {
                                            modalService.show({
                                                type: 'warning',
                                                title: translations.error,
                                                message: response[0].errormessage,
                                                buttons: [{
                                                    label: translations.ok,
                                                    cssClass: 'btn-warning',
                                                    cssClass: 'btn-warning',
                                                    action: function (dialogItself) {
                                                        dialogItself.close();
                                                    }
                                                }]
                                            });
                                        } else {
                                            loadGridOlm();
                                        }
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-success im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            });

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                verticalPurchasingService.getSetting({ order_internal_no: orderInternalNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    loadGridOl();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridOl = function () {
                vm.gridOl.dataTask.loadData = {
                    method: 3265,
                    parameters: vm.model.setting
                };

                vm.model.gridOlReady = true;

                if (vm.gridOl.gridfunc !== null) vm.gridOl.gridfunc.read();
            };

            let gridOlTimer = null;

            function loadGridOlm() {
                if (gridOlTimer !== null) {
                    clearTimeout(gridOlTimer);
                    gridOlTimer = null;
                }

                gridOlTimer = setTimeout(function () {
                    var currentData = vm.gridOl.gridfunc.getAllRows();
                    var selectedItems = [];

                    angular.forEach(currentData, function (item) {
                        selectedItems.push({
                            orderline_keyno: item.orderline_keyno,
                            verticalpurchasing_whole: item.verticalpurchasing_whole
                        });
                    });

                    var mergeObj = angular.copy(vm.model.setting);
                    mergeObj.records = selectedItems;

                    vm.gridOlm.dataTask.loadData = {
                        method: 3266,
                        parameters: mergeObj
                    };

                    vm.model.gridOlmReady = true;

                    if (vm.gridOlm.gridfunc !== null) vm.gridOlm.gridfunc.read();
                }, 50);
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
