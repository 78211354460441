(function () {
    'use strict';

    let imApp = angular.module('imApp');

    imApp.component('categoryDatas', {
        templateUrl: 'views/components/views/categoryDatas/categoryDatas.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'categoryDatasService', 'stateService', function ($stateParams, $timeout, categoryDatasService, stateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            let vm = this;

            vm.model = {
                p2CatagoryDataSelection: {
                    item_default: '0',
                    item_selected: 'A',
                    records: [
                        { item_id: '1', item_name: 'categorydatas_all', item_func: 'A' },
                        { item_id: '2', item_name: 'categorydatas_selected', item_func: 'S' }
                    ]
                },
                p2WCategoryDatasSettings: {},
                p2CategoryListMain: {
                    records: []
                },
                p2CatagoryDataList: []
            };

            // #region LOAD PROCEDURE CALL

            async function loadP2WCategoryDatasSettings() {
                return vm.model.p2WCategoryDatasSettings = (await categoryDatasService.genericFunction(636, {
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue
                }))[0];
            };

            async function loadP2CatagoryDataList() {
                return vm.model.p2CatagoryDataList = await categoryDatasService.loadP2CatagoryDataList($stateParams.argtype, $stateParams.argvalue, vm.model.p2WCategoryDatasSettings.category_keyno_top);
            };


            async function getP2CategoryListMain() {
                return vm.model.p2CategoryListMain.records = await categoryDatasService.genericFunction(626, {
                    category_keyno_top: vm.model.p2WCategoryDatasSettings.category_keyno_top,
                    argtype: $stateParams.argtype,
                    argvalue: $stateParams.argvalue
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // ######################
            // BUTTON GROUP FUNCTIONS
            // ######################

            vm.selectP2CatagoryDataView = function (lsP2CatagoryDataItem) {
                vm.model.p2CatagoryDataSelection.item_selected = lsP2CatagoryDataItem;
            };

            // ######################
            // CHANGE BUTTON FUNCTION
            // ######################

            vm.changeCategoryData = function (index) {
                $timeout(function () {
                    categoryDatasService.categoryDataListValidate(vm.model.p2CatagoryDataList, index);
                }, 50);
            };

            // ####################
            // SAVE BUTTON FUNCTION
            // ####################

            vm.saveP2CategoryData = function () {
                categoryDatasService.saveP2CategoryData(vm.model.p2CatagoryDataList).then(function () {
                    stateService.back();
                });
            };

            vm.onCategoryTopChanged = function (value) {
                vm.model.p2WCategoryDatasSettings.category_keyno_top = value;

                if (value) loadP2CatagoryDataList();
            }

            // #region ANGULAR LIFECYCLE

            vm.$onInit = async function () {
                await loadP2WCategoryDatasSettings();
                loadP2CatagoryDataList();
                getP2CategoryListMain();
            }

            // #endregion ANGULAR LIFECYCLE

        }]
    });
})();
