(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('customerBonus', {
        templateUrl: 'views/components/views/customerBonus/customerBonus.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'customerBonusService', function ($stateParams, customerBonusService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];

            vm.grid = {
                dataTask: {
                    rememberId: 'w_customerBonus.grid',                  // Id used to retrieve user configs for the grid
                    loadData: {                                         // Method id of the get data procedure
                        method: 2843,
                        parameters: { cust_no: $stateParams.cust_no }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: false,                               // Adds a predefined btn for exporting grid to pdf
                        excelExport: false,                             // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                        // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                 // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        addSimple: false,                                // Adds predefined btns for running an add function     // Needs addRow     // Alternative to 'add'
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: false,                                // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: false,                                 // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: []                                      // List of custom buttons
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } }
                        ]
                    },
                    navigation: {
                        altNav: false,                                  // accepts -> true or false, (or undefined)
                        newLine: false                                  // If true, will add a new line if (Shift+)Enter/Tab pressed at (first/)last editable cell on (first/)last row
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS


            // #region OPTIONFUNC

            let optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                //prepareLists().then(function (data) {
                //    //console.dir(data);
                //    vm.gridReady = true;
                //});

                //vm.gridReady = true;
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();