(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('srFollowUp', {
        templateUrl: 'views/components/views/srFollowUp/srFollowUp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'srFollowUpService', function ($stateParams, $q, stateService, utilityService, modalService, srFollowUpService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'srfollowupforward':
                        go({
                            experience_keyno: vm.model.edit.experience_keyno
                        });
                        break;
                    case 'sroperation':
                        go({
                            custact_no: vm.model.edit.custact_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                experienceKeyNo: $stateParams.experience_keyno,
                specialResourceNameToButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goToFunc, state: 'srfollowupforward' } }
                ],
                specialHrsNoButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', onClick: { click: vm.goToFunc, state: 'sroperation' } }
                ],
                edit: {},
                selectListResponsibleLabours: [],
                commentListExperiences: [],
                lockedAdd: false,
                lockedClose: false,
                lockedReopen: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region SHOW DIALOGUE BOX FUNCTION

            var showMessage = function (type, title, message, label, cssClass) {
                var deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            };

            // #endregion SHOW DIALOGUE BOX FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadSrFollowUp = function () {
                srFollowUpService.getSrFollowUp({ experience_keyno: vm.model.experienceKeyNo }).then(function (data) {
                    angular.copy(data[0], vm.model.edit);

                    loadResponsibleLabours();
                    loadExperienceComments();
                });
            };

            loadSrFollowUp();

            var loadResponsibleLabours = function () {
                if (utilityService.validateParmsValue(vm.model.edit.special_resource_id_to) !== true) return;

                vm.model.selectListResponsibleLabours = [];

                srFollowUpService.listResponsibleLabours({ special_resource_id_to: vm.model.edit.special_resource_id_to }).then(function (data) {
                    angular.copy(data, vm.model.selectListResponsibleLabours);
                });
            };

            var loadExperienceComments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.experience_keyno) !== true) return;

                vm.model.commentListExperiences = [];

                srFollowUpService.listExperienceComments({ experience_keyno: vm.model.edit.experience_keyno }).then(function (data) {
                    angular.copy(data, vm.model.commentListExperiences);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE PROCEDURE FUNCTION CALL

            var addNewResponsible = function () {
                if (utilityService.validateParmsValue(vm.model.edit.experience_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.responsible_labour_no) !== true) return;

                var parms = {
                    experience_keyno: vm.model.edit.experience_keyno,
                    responsible_labour_no: vm.model.edit.responsible_labour_no
                };

                srFollowUpService.addNewResponsibleLabour(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning');
                    } else {
                        loadExperienceComments();
                    }
                });
            };

            var addComment = function () {
                vm.model.lockedAdd = true;

                var parms = {
                    experience_keyno: vm.model.edit.experience_keyno,
                    new_note: vm.model.edit.new_note,
                    func: 'ADD'
                };

                srFollowUpService.addExperienceComment(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedAdd = false;
                        });
                    } else {
                        vm.model.lockedAdd = false;
                        vm.model.edit.new_note = '';
                        loadExperienceComments();
                    }
                });
            };

            var addAndClose = function () {
                vm.model.lockedClose = true;

                var parms = {
                    experience_keyno: vm.model.edit.experience_keyno,
                    new_note: vm.model.edit.new_note,
                    func: 'CLOSE'
                };

                srFollowUpService.addExperienceComment(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedClose = false;
                        });
                    } else {
                        vm.model.lockedClose = false;
                        vm.model.edit.new_note = '';
                        loadSrFollowUp();
                        loadExperienceComments();
                    }
                });
            };

            var addAndReopen = function () {
                vm.model.lockedReopen = true;

                var parms = {
                    experience_keyno: vm.model.edit.experience_keyno,
                    new_note: vm.model.edit.new_note,
                    func: 'REOPEN'
                };

                srFollowUpService.addExperienceComment(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedReopen = false;
                        });
                    } else {
                        vm.model.lockedReopen = false;
                        vm.model.edit.new_note = '';
                        loadSrFollowUp();
                        loadExperienceComments();
                    }
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'responsible_labour_no':
                        vm.model.edit.responsible_labour_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.responsible_labour_no) !== true) return;

                        addNewResponsible();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }

            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'add':
                        addComment();
                        break;
                    case 'close':
                        addAndClose();
                        break;
                    case 'reopen':
                        addAndReopen();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS
        }]
    });
})();
