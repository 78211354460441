(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("messageService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            sendUserLink: function (userLink, portalUserKeyno, sendSmsToUser, sendSmsToAdmin, sendEmailToUser, sendEmailToAdmin) {
                return $ihttp.post({
                    method: 77,
                    parameters: {
                        userlink: userLink,
                        portal_user_keyno: portalUserKeyno,
                        send_sms_to_user: sendSmsToUser === true ? '1' : '0',
                        send_sms_to_admin: sendSmsToAdmin === true ? '1' : '0',
                        send_email_to_user: sendEmailToUser === true ? '1' : '0',
                        send_email_to_admin: sendEmailToAdmin === true ? '1' : '0'
                    }
                });
            }
        };

        return service;
    }]);
})();