(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('shipment', {
        templateUrl: 'views/components/views/shipment/shipment.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'shipmentService', 'rememberService', 'modalService', 'translateService', function ($q, $stateParams, shipmentService, rememberService, modalService, translateService) {
            var vm = this;

            vm.prodplaces = [];
            vm.stockplaces = [];
            vm.pods = [];

            vm.showSearch = false;
            vm.lockedSave = false;
            vm.isNew = false;

            vm.style = {
                labelAlwaysOnTop: false
            };

            vm.options = {
                freight: {
                    autoselect: true,
                    decimals: 2
                    //textAlign: 'right',
                }
            };

            var translations = {
                shipment_cancel_new_title: '',
                shipment_cancel_new_message: '',
                shipment_cancel_new_ok: '',
                shipment_cancel_new_cancel: '',
                shipment_unsaved_changes_title: '',
                shipment_unsaved_changes_message: '',
                shipment_unsaved_changes_ok: '',
                shipment_unsaved_changes_cancel: ''
            };
            
            var rememberId = 'shipment.settings';

            var prevKeyno = 0;
            var originalDetails = {};

            vm.details = {
                shipmentcontainer_keyno: 0,
                shipmentcontainer_name: '',
                shipmentcontainer_keyname: ''
            };

            var loadDetails = function () {
                var keyno = vm.details.shipmentcontainer_keyno;

                if (angular.isUndefined(keyno) || keyno === null) {
                    keyno = 0;
                }

                shipmentService.details(keyno).then(function (response) {
                    if (!response || !angular.isArray(response) || response.length < 0) return;
                    //if (angular.isUndefined(response))
                    //    return;
                    //if (angular.isArray(response) !== true)
                    //    return;
                    //if (response.length < 1)
                    //    return;

                    vm.isNew = false;

                    //console.dir(response);

                    response[0].shipmentcontainer_keyname = vm.details.shipmentcontainer_keyname;

                    angular.copy(response[0], vm.details);
                    angular.copy(response[0], originalDetails);
                });
            };

            var setDetails = function (keyno, name, keyname) {
                var details = {
                    shipmentcontainer_keyno: keyno,
                    shipmentcontainer_name: name,
                    shipmentcontainer_keyname: keyname ?? ''
                };

                angular.copy(details, vm.details);
            }

            var saveSettings = function () {
                rememberService.remember(rememberId, angular.toJson({
                    shipmentcontainer_keyno: vm.details.shipmentcontainer_keyno,
                    shipmentcontainer_name: vm.details.shipmentcontainer_name,
                    shipmentcontainer_keyname: vm.details.shipmentcontainer_keyname
                }));
            };

            var loadSettings = function () {
                var deferred = $q.defer();

                rememberService.getLastStatus(rememberId).then(function (lastState) {
                    if (angular.isArray(lastState) && lastState.length > 0 && angular.isString(lastState[0].variablevalue) && lastState[0].variablevalue.length > 0) {
                        var state = angular.fromJson(lastState[0].variablevalue);

                        setDetails(state.shipmentcontainer_keyno, state.shipmentcontainer_name, state.shipmentcontainer_keyname);
                    }

                    deferred.resolve();
                });

                return deferred.promise;
            };

            vm.typeaheadSearch = function (value) {
                return shipmentService.searchKeyname(value);
                //return shipmentService.search(value);
            };

            vm.onShipmentSelected = function (item) {
                setDetails(item.shipmentcontainer_keyno, item.shipmentcontainer_name, item.shipmentcontainer_keyname);

                saveSettings();

                loadDetails();
            };

            vm.onNewShipment = function () {
                if (vm.isNew === true)
                    return;

                var confirmed = function () {
                    prevKeyno = vm.details.shipmentcontainer_keyno;

                    setDetails(0, '');

                    angular.copy(vm.details, originalDetails);

                    vm.isNew = true;
                };

                if (angular.equals(vm.details, originalDetails) !== true) {
                    modalService.confirm({
                        type: 'warning',
                        title: translations.shipment_unsaved_changes_title,
                        message: translations.shipment_unsaved_changes_message,
                        okLabel: translations.shipment_unsaved_changes_ok,
                        cancelLabel: translations.shipment_unsaved_changes_cancel
                    }).then(confirmed);
                } else {
                    confirmed();
                }
            };

            vm.onCancelNewShipment = function () {
                modalService.confirm({
                    type: 'warning',
                    title: translations.shipment_cancel_new_title,
                    message: translations.shipment_cancel_new_message,
                    okLabel: translations.shipment_cancel_new_ok,
                    cancelLabel: translations.shipment_cancel_new_cancel
                }).then(function () {
                    vm.isNew = false;

                    setDetails(prevKeyno);

                    loadDetails();
                });
            };

            vm.saveChanges = function () {
                vm.lockedSave = true;

                shipmentService.save(vm.details).then(function (response) {
                    vm.prevDetails = 0;

                    if (vm.isNew === true) {
                        vm.isNew = false;

                        setDetails(response.shipmentcontainer_keyno);
                    }

                    loadDetails();

                    vm.lockedSave = false;
                }).catch(error => {
                    vm.lockedSave = false;
                });
            };

            shipmentService.load().then(function (response) {
                angular.copy(response.prodplaces, vm.prodplaces);
                angular.copy(response.stockplaces, vm.stockplaces);
                angular.copy(response.pods, vm.pods);
            });

            vm.$onInit = function () {
                if (angular.isDefined($stateParams.shipmentcontainer_keyno) && $stateParams.shipmentcontainer_keyno !== null
                    && angular.isString($stateParams.shipmentcontainer_keyno) && $stateParams.shipmentcontainer_keyno.length > 0) {

                    setDetails(parseInt($stateParams.shipmentcontainer_keyno, 10));

                    loadDetails();
                } else {
                    vm.showSearch = true;

                    loadSettings().then(loadDetails);
                }
            };

            translateService.translateBatch(translations).then(function (response) {
                angular.forEach(response, function (val, key) {
                    translations[key] = val;
                });
            });
        }]
    });
})();
