(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('docPackTypeChapter', {
        templateUrl: 'views/components/views/docPackTypeChapter/docPackTypeChapter.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'modalService', 'translateService', 'docPackTypeChapterService', function ($stateParams, stateService, base64, modalService, translateService, docPackTypeChapterService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let docPackTypeKeyno = $stateParams.docpacktype_keyno;
            let docPackTypeChapterKeyno = $stateParams.docpacktypechapter_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                docPackTypeChapterKeyno: docPackTypeChapterKeyno,
                edit: {},
                readyHtmlText: true,
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                docPackTypeChapterService.getEdit({
                    docpacktype_keyno: docPackTypeKeyno,
                    docpacktypechapter_keyno: docPackTypeChapterKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                    vm.model.edit.html_text = base64.urldecode(vm.model.edit.html_text);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let editAndSave = {
                    docpacktypechapter_keyno: vm.model.edit.docpacktypechapter_keyno,
                    docpacktypechapter_name: vm.model.edit.docpacktypechapter_name,
                    docpacktypechapter_order: vm.model.edit.docpacktypechapter_order,
                    docpacktype_keyno: vm.model.edit.docpacktype_keyno,
                    html_text: base64.urlencode(vm.model.edit.html_text) 
                };

                docPackTypeChapterService.saveObj(editAndSave).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    docPackTypeChapterService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
