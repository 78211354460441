(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("sopClService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSopClGet: function (sop_keyno, sopcl_keyno) {
                return p2DataTaskService.call(1849, {
                    sop_keyno: sop_keyno,
                    sopcl_keyno: sopcl_keyno
                });
            },
            save: function (choice) {
                return p2DataTaskService.call(1850, choice);
            }
        };

        return service;
    }]);
})();