(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicSelect', {
        templateUrl: 'views/components/dynamics/components/ttDynamicSelect/ttDynamicSelect.template.html',
        controllerAs: 'vm',
        bindings: {
            ttRootId: '@',
            ttLabel: '@',
            ttModel: '<',
            ttDataId: '@',
            ttDataName: '@',
            ttDatasourceKeyno: '<',
            ttChange: '&'
        },
        controller: ['$element','utilityService', 'ttDatasourceManager', function ($element, us, ttDatasourceManager) {
            var vm = this;

            vm.label = '';
            vm.model = null;
            vm.items = [];

            vm.onChange = function (value, _, args) {
                let modelId = us.getModelId($element);

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: modelId, $args: args });
                }
            };

            vm.$onInit = function () {
                if (angular.isNumber(vm.ttDatasourceKeyno)) {
                    load();

                    ttDatasourceManager.subscribeByKeyno(vm.ttRootId, vm.ttDatasourceKeyno, load, 'onRead');
                }
            };

            function load() {
                var items = ttDatasourceManager.getItemsByKeyno(vm.ttRootId, vm.ttDatasourceKeyno);

                angular.copy(items, vm.items);
            };
        }]
    });
})();