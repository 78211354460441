(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('interBranchLevel', {
        templateUrl: 'views/components/views/interBranchLevel/interBranchLevel.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'interBranchLevelService', function($stateParams, stateService, interBranchLevelService) {
            var vm = this;
            
            vm.model = {
                p2InterBranchLevelGet: {},
                p2BusinessCoList: {
                    records: []
                },
                p2PriceList: {
                    records: []
                }
            };
            
            var loadP2InterBranchLevelGet = function () {
                interBranchLevelService.genericFunction(792, {
                    interbranchlevel_keyno: $stateParams.interbranchlevel_keyno
                }).then(function (data) {
                    vm.model.p2InterBranchLevelGet = angular.copy(data[0]);

                    loadP2BusinessCoList();
                    loadP2PriceList();
                });
            };
            
        	loadP2InterBranchLevelGet();
            
            var loadP2BusinessCoList = function () {
                interBranchLevelService.genericFunction(104, {}).then(function (data) {
                    angular.copy(data, vm.model.p2BusinessCoList.records);
                });
            };
            
            var loadP2PriceList = function () {
                interBranchLevelService.genericFunction(627, {}).then(function (data) {
                    angular.copy(data, vm.model.p2PriceList.records);
                });
            };
            
            vm.saveInterBranchLevel = function () {
                interBranchLevelService.genericFunction(793, vm.model.p2InterBranchLevelGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();