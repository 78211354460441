(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('srResourceView', {
        templateUrl: 'views/components/views/srResourceView/srResourceView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'watcherFactory', 'srResourceViewService', 'rememberService', function ($timeout, stateService, utilityService, watcherFactory, srResourceViewService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var watcher = watcherFactory.$create(vm);
            var onDestroy = [];

            var timer = {
                filtervalue: null
            };

            var variableNames = {
                main_resource_id: '',
                sub_resource_id: '',
                show_deactivated_resources: '',
                filtervalue: ''
            };

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'srresource':
                        go({
                            resource_id_belongto: vm.model.setting.sub_resource_id,
                            resource_id: 'NEW'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                btnListFilterValue: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                setting: {},
                selectListMainResources: [],
                selectListSubResources: [],
                itemsListViewResoures: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadSrResourceView = function () {
                srResourceViewService.getSrResourceView().then(function (data) {
                    angular.copy(data[0], vm.model.setting);

                    loadSrSubResources();
                    loadSrResourceViews();
                });
            };

            loadSrResourceView();

            srResourceViewService.listSrMainResources().then(function (data) {
                angular.copy(data, vm.model.selectListMainResources);
            });

            var loadSrSubResources = function () {
                if (utilityService.validateParmsValue(vm.model.setting.main_resource_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_deactivated_resources) !== true) return;

                var parms = {
                    main_resource_id: vm.model.setting.main_resource_id,
                    show_deactivated_resources: vm.model.setting.show_deactivated_resources
                };

                srResourceViewService.listSrSubResoures(parms).then(function (data) {
                    angular.copy(data, vm.model.selectListSubResources);
                });
            };

            var loadSrResourceViews = function () {
                if (utilityService.validateParmsValue(vm.model.setting.sub_resource_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.show_deactivated_resources) !== true) return;

                var parms = {
                    sub_resource_id: vm.model.setting.sub_resource_id,
                    show_deactivated_resources: vm.model.setting.show_deactivated_resources
                };

                vm.model.itemsListViewResoures = [];

                srResourceViewService.listSrResourceViews(parms).then(function (data) {
                    angular.copy(data, vm.model.itemsListViewResoures);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                if (angular.isDefined(timer[id])) {
                    $timeout.cancel(timer[id]);
                }

                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'main_resource_id':
                        if (utilityService.validateParmsValue(vm.model.setting.main_resource_id) !== true) return;

                        variableValue = vm.model.setting.main_resource_id;
                        break;
                    case 'sub_resource_id':
                        if (utilityService.validateParmsValue(vm.model.setting.sub_resource_id) !== true) return;

                        variableValue = vm.model.setting.sub_resource_id;
                        break;
                    case 'show_deactivated_resources':
                        if (utilityService.validateParmsValue(vm.model.setting.show_deactivated_resources) !== true) return;

                        variableValue = vm.model.setting.show_deactivated_resources;
                        break;
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue) !== true) return;

                        variableValue = vm.model.setting.filtervalue;
                        break;
                    default:
                        break;
                }

                if (vm.model.setting.filtervalue > '') {
                    timer[id] = $timeout(function () {
                        rememberService.remember(variableNames[id], variableValue);
                    }, 1000);
                } else {
                    rememberService.remember(variableNames[id], variableValue);
                }
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncFilterValue = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.filtervalue = '';
                        break;
                    default:
                        break;
                }
            };

		    // #endregion BUTTON LIST FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'main_resource_id':
                        vm.model.setting.main_resource_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.main_resource_id) !== true) return;

                        rememberFunc('main_resource_id');
                        loadSrSubResources();
                        break;
                    case 'sub_resource_id':
                        vm.model.setting.sub_resource_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.sub_resource_id) !== true) return;

                        rememberFunc('sub_resource_id');
                        loadSrResourceViews();
                        break;
                    case 'show_deactivated_resources':
                        vm.model.setting.show_deactivated_resources = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_deactivated_resources) !== true) return;

                        rememberFunc('show_deactivated_resources');
                        loadSrSubResources();
                        loadSrResourceViews();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }

            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region WATCH FUNCTIONS

            watcher.$watch(function () { return vm.model.setting.filtervalue; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

                rememberFunc('filtervalue');
            }, onDestroy);

		    // #endregion WATCH FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

		    // #endregion DESTROY FUNCTION
        }]
    });
})();
