(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('travelWageSetRates', {
        templateUrl: 'views/components/views/travelWageSetRates/travelWageSetRates.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'travelWageSetRatesService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, travelWageSetRatesService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let travelWageSetKeyno = $stateParams.travelwageset_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                inputListRates: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                travelWageSetRatesService.getEdit({ travelwageset_keyno: travelWageSetKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadTravelWageRates();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadTravelWageRates = function () {
                if (utilityService.validateParmsValue(vm.model.edit.travelwageset_keyno) !== true) return;
                
                return travelWageSetRatesService.listRates({ travelwageset_keyno: vm.model.edit.travelwageset_keyno }).then(function (list) {
                    angular.copy(list, vm.model.inputListRates);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let mergeObj = vm.model.edit;
                mergeObj.records = vm.model.inputListRates;

                travelWageSetRatesService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
