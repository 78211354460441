(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2ExportProcMenuService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2ExportProcMenuGet: function (p2_exportproc_keyno) {
                return p2DataTaskService.call(1891, {
                    p2_exportproc_keyno: p2_exportproc_keyno
                });
            }
        };

        return service;
    }]);
})();