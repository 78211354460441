(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('labourJobNote', {
        templateUrl: 'views/components/views/labourJobNote/labourJobNote.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'labourJobNoteService', function($stateParams, stateService, labourJobNoteService) {
            var vm = this;
            
            vm.model = {
                p2WLabourJobNoteGet: {}
            };
            
            labourJobNoteService.loadP2WLabourJobNoteGet($stateParams.labour_no).then(function () {
                vm.model.p2WLabourJobNoteGet = labourJobNoteService.p2WLabourJobNoteGet;
            });
            
            vm.saveP2WLabourJobNote = function () {
                labourJobNoteService.saveP2WLabourJobNote(vm.model.p2WLabourJobNoteGet).then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();