(function () {
    'use strict';

    angular.module("imApp").factory("custactCustomersService", ['$ihttp', function ($ihttp) {
        let service = {
            listCustomers: function (parms) {
                return $ihttp.post({
                    method: 1169,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
