(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('tailorAccessMenu', {
        templateUrl: 'views/components/views/tailorAccessMenu/tailorAccessMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'tailorAccessMenuService', function ($stateParams, $q, stateService, utilityService, tailorAccessMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let tailorAccessKeyNo = $stateParams.tailoraccess_keyno;

            vm.model = {
                tailorAccessNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('tailoraccesslang', { tailoraccess_keyno: vm.model.menuTailorAccess.tailoraccess_keyno }) }
                ],
                menuTailorAccess: {},
                itemsListTailoredAccessDatas: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                let deferred = $q.defer();

                tailorAccessMenuService.getTailorAccessMenu({ tailoraccess_keyno: tailorAccessKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.menuTailorAccess);

                    $q.all([
                        loadTailorAccessDatas()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadTailorAccessDatas = function () {
                if (utilityService.validateParmsValue(vm.model.menuTailorAccess.tailoraccess_keyno) !== true) return;

                vm.model.itemsListTailoredAccessDatas = [];

                return tailorAccessMenuService.listTailorAccessDatas({ tailoraccess_keyno: vm.model.menuTailorAccess.tailoraccess_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListTailoredAccessDatas);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'tailoraccess':
                        go({ tailoraccess_keyno: vm.model.menuTailorAccess.tailoraccess_keyno });
                        break;
                    case 'tailoraccessdata':
                        go({
                            tailoraccessdata_keyno: '0',
                            tailoraccess_keyno: vm.model.menuTailorAccess.tailoraccess_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
