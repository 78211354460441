/**
 * Registry over components that can be used as modals opened from the grid. Key is the name provided to the `@GridModalComponent` decorator.
 */
export const GridModalRegistry: Map<string, any> = new Map();

/**
 * Decorator for components to be used as a modal opened from buttons configured in colschema. Place above the ``@Component()`` decorator.
 * The value passed to this decorator is the value to refer to it from colschema.
 *
 * @param key the name to index the component as, this is the value to refer to the component with in colschema.
 * @returns function which sets the component in the grid-modal registry.
 * @example
 * ``` @GridModalComponent('GridBookingModal)```
 */
export const GridModalComponent = (key: string): any => {
    return (componentClass: any) => {
        GridModalRegistry.set(key, componentClass);
    };
};
