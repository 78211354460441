(function() {
    'use strict';

    angular.module("imApp").factory("searchLogComponentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            search: function () {
                console.log('return from searchLogComponentService.search()');
            }
        }

        console.dir(service);

        return service;
    }]);
})();
