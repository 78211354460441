(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListItemTw', {
        templateUrl: 'views/components/views/checkListItemTw/checkListItemTw.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'checkListItemTwService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, checkListItemTwService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let checkListItemTwKeyno = $stateParams.checklistitemtw_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                checkListItemTwKeyno: checkListItemTwKeyno,
                edit: {},
                selectListLinksToForms: [],
                selectListCheckListItems: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                const deferred = $q.defer();

                checkListItemTwService.getEdit({
                    argtype: argType,
                    argvalue: argValue,
                    checklistitemtw_keyno: checkListItemTwKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadCheckListItems();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCheckLists = function () {
                checkListItemTwService.listCheckLists().then(function (list) {
                    angular.copy(list, vm.model.selectListLinksToForms);
                });
            };
            
            let loadCheckListItems = function () {
                if (utilityService.validateParmsValue(vm.model.edit.checklist_keyno) !== true) return;
                
                return checkListItemTwService.listCheckListItems({ checklist_keyno: vm.model.edit.checklist_keyno }).then(function (list) {
                    angular.copy(list, vm.model.selectListCheckListItems);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                checkListItemTwService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    checkListItemTwService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.onChecklistKeynoChanged = function (value) {
                vm.model.edit.checklist_keyno = value;

                if (value) loadCheckListItems();
            }

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCheckLists();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
