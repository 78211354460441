import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { DataTaskService } from '../../core/services/data-task.service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '../../core/services/translate.service';
import { WordlangDialogInput } from '../../model/wordlang-dialog-input.model';

@Component({
  selector: 'tt-wordlang-dialog',
  templateUrl: './wordlang-dialog.component.html',
  styleUrls: ['./wordlang-dialog.component.css']
})
export class WordlangDialogComponent implements OnInit {
    wordId?: string;
    autoSave?: boolean;
    description: string = 'tt_translations';

    form = this.fb.group({
        wordId: [this.wordId, Validators.required],
        //category: [this.wordlang.category, Validators.required],
        //releasedAt: [new Date(), Validators.required],
        //longDescription: [this.wordlang.longDescription, Validators.required]
    });

    constructor(private fb: FormBuilder,
        private ts: TranslateService,
        private ls: LanguageService,
        @Inject(MAT_DIALOG_DATA) private parms: WordlangDialogInput,
        private dialogRef: MatDialogRef<WordlangDialogComponent>) {

        this.wordId = parms.wordId;
        this.autoSave = parms.autoSave;
    }

    async ngOnInit() {
        let languages = await this.ls.List();

        console.dir(languages);
    }

    close() {

        this.dialogRef.close();

    }

    save() {

        this.dialogRef.close(this.form.value);

    }
}

export function openWordlangDialog(dialog: MatDialog, parms: WordlangDialogInput) {
    const config = new MatDialogConfig();

    config.disableClose = true;
    config.autoFocus = true;
    config.panelClass = "modal-panel";
    config.backdropClass = "backdrop-modal-panel";
    config.width = '90%';

    config.data = {
        ...parms
    };

    const dialogRef = dialog.open(WordlangDialogComponent, config);

    return dialogRef.afterClosed();
}
