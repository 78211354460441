import { Component, Input } from '@angular/core';
import { ButtonComponent } from '../button/button.component';

@Component({
    selector: 'tt-button-save',
    templateUrl: './button-save.component.html',
    styleUrls: ['./button-save.component.css'],
})
export class ButtonSaveComponent extends ButtonComponent {
    @Input()
    public override ttText: string = 'save';

    @Input()
    public override ttLoadingText: string = 'saving';

    @Input()
    override ttType: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'link' = 'success';

    @Input()
    public override ttIcon: string = 'far fa-arrow-to-bottom';
}
