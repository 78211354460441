(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("fabricsGrpService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadFabricsGrpGet: function (fabricsgrp_id) {
                return p2DataTaskService.call(1241, {
                    fabricsgrp_id: fabricsgrp_id
                });
            },
            save: function (fabricsGroup) {
                return p2DataTaskService.call(1242, fabricsGroup);
            }
        };

        return service;
    }]);
})();