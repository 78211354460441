(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('consignmentDelivery', {
        templateUrl: 'views/components/views/consignmentDelivery/consignmentDelivery.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'consignmentDeliveryService', 'ieScreenBlockService', function ($timeout, consignmentDeliveryService, ieScreenBlockService) {
            var vm = this;

            function getSettings() {
                consignmentDeliveryService.get().then(function (data) {
                    vm.model.deliverymethod_no = data[0].cd_deliverymethod_no;
                    vm.model.active_deliverymethod_no = data[0].active_deliverymethod_no;

                    if (parseInt(vm.model.active_deliverymethod_no) > 0){
                        vm.model.deliverymethod_no = vm.model.active_deliverymethod_no;
                    } else {
                        vm.model.active_deliverymethod_no = 0;
                    }
                });
            }

            function loadDeliveryMethods() {
                consignmentDeliveryService.listDeliveryMethods()
                    .then(function (data) {
                        angular.copy(data, vm.model.deliveryMethods);

                        if (vm.model.deliveryMethods.length > 0) {
                            loadConsignmentDeliveries();
                        }
                    });
            }

            function loadConsignmentDeliveries() {
                if (vm.model.deliverymethod_no > 0) {
                    ieScreenBlockService.start('message');
                    ieScreenBlockService.setMessage('Laster informasjon');

                    consignmentDeliveryService.listConsignmentDeliveries(vm.model.deliverymethod_no, vm.model.btnGroup.item_selected).then(function (data) {
                        ieScreenBlockService.stop();
                        angular.copy(data, vm.model.consignmentDeliveryData);
                    });
                }
            }

            function onDeliveryMethodChange() {
                $timeout(function () {
                    consignmentDeliveryService.remember(vm.model.variableName, vm.model.deliverymethod_no).then(function () { });
                    loadConsignmentDeliveries();
                }, 50);
            }

            function btnGroupBtnClick(item) {
                //console.log('btnGroup: ',item);
                vm.model.btnGroup.item_selected = item;
                loadConsignmentDeliveries();
            }

            vm.model = {
                variableName: 'cd_deliverymethod_no',
                deliveryMethods: [],
                deliverymethod_no: 0,
                active_deliverymethod_no: 0,
                consignmentDeliveryData: [],
                btnGroup: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'active', item_func: 'active' },
                        { item_id: '1', item_name: 'delivered', item_func: 'delivered' }
                    ]
                },
                loadDeliveryMethods: loadDeliveryMethods,
                loadConsignmentDeliveries: loadConsignmentDeliveries,
                onDeliveryMethodChange: onDeliveryMethodChange
            };

            vm.btnGroupBtnClick = btnGroupBtnClick;

            getSettings();
            loadDeliveryMethods();
        }]
    });
})();