(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListEquipmentsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListEquipmentsGet: function (checklistdata_keyno, srrefundtype_id) {
                return p2DataTaskService.call(1798, {
                    checklistdata_keyno: checklistdata_keyno,
                    srrefundtype_id: srrefundtype_id
                });
            },
            loadSrCheckListEquipmentsList: function (settingCheckList) {
                return p2DataTaskService.call(1803, settingCheckList);
            }
        };

        return service;
    }]);
})();