(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('timeProjects', {
        templateUrl: 'views/components/views/timeProjects/timeProjects.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['timeProjectsService', function (timeProjectsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                itemsListTimeProjects: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadTimeProjects = function () {
                timeProjectsService.listTimeProjects({
                    cust_name: 'ALL',
                    project_keyno: '0',
                    ok: '0'
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListTimeProjects);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTimeProjects();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
