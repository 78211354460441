(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('accountingPl', {
        templateUrl: 'views/components/views/accountingPl/accountingPl.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'accountingPlService', 'logisticService', 'accountingBsService', 'rememberService', 'typeaheadService', 'departmentsService', 'ttPopoverModel', function (stateService, utilityService, accountingPlService, logisticService, accountingBsService, rememberService, typeaheadService, departmentsService, ttPopoverModel) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            var variableNames = {
                businessco_no: '',
                acreportlayout_keyno: '',
                show_12months: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                accounting_year: '',
                show_accounts: '',
                project_keynos: '',
                department_ids: '',
                equipment_ids: '',
                labour_nos: '',
            };

            vm.model = {
                setting: {},
                projectsButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => { vm.handleFieldChanged('', 'vm.model.setting.project_keynos'); loadGrid() } },
                    { id: 'select', icon: 'fas fa-list', type: 'primary', color: 'primary', onClick: () => console.log('open popover') }
                ],
                departmentsButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => { vm.handleFieldChanged('', 'vm.model.setting.department_ids'); loadGrid() } },
                    { id: 'select', icon: 'fas fa-list', type: 'primary', color: 'primary', onClick: () => console.log('open popover') }
                ],
                equipmentButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => { vm.handleFieldChanged('', 'vm.model.setting.equipment_ids'); loadGrid() } },
                    { id: 'select', icon: 'fas fa-list', type: 'primary', color: 'primary', onClick: () => console.log('open popover') }
                ],
                labourButtons: [
                    { id: 'erase', icon: 'fas fa-eraser', type: 'danger', color: 'danger', onClick: () => { vm.handleFieldChanged('', 'vm.model.setting.labour_nos'); loadGrid() } },
                    { id: 'select', icon: 'fas fa-list', type: 'primary', color: 'primary', onClick: () => console.log('open popover') }
                ],
                selectListCompanies: [],
                selectListReportLayouts: [],
                selectListAccountingYears: [],
                gridReady: false,
                grid12mReady: false
            };

            vm.popoverProjects = new ttPopoverModel('accountingplprojectsselect')
                .addChecklist({
                    title: 'new Title',
                    required: false,
                    list: [],
                    onChecklistChanged: (list, selectedIds) => vm.model.setting.project_keynos = createSelectedString(selectedIds)

                })
                .onClose(() => loadGrid());

            vm.popoverDepartments = new ttPopoverModel('accountingpldepartmentsselect')
                .addChecklist({
                    title: 'new Title',
                    required: false,
                    list: [],
                    onChecklistChanged: (list, selectedIds) => vm.model.setting.department_ids = createSelectedString(selectedIds)

                })
                .onClose(() => loadGrid());

            vm.popoverEquipments = new ttPopoverModel('accountingplequipmentsselect')
                .addChecklist({
                    title: 'new Title',
                    required: false,
                    list: [],
                    onChecklistChanged: (list, selectedIds) => vm.model.setting.equipment_ids = createSelectedString(selectedIds)

                })
                .onClose(() => loadGrid());

            vm.popoverLabours = new ttPopoverModel('accountingpllaboursselect')
                .addChecklist({
                    title: 'new Title',
                    required: false,
                    list: [],
                    onChecklistChanged: (list, selectedIds) => vm.model.setting.labour_nos = createSelectedString(selectedIds)

                })
                .onClose(() => loadGrid());

            function createSelectedString(list) {
                let selectedString = '';
                list.forEach((id) => selectedString += id + ',')
                return selectedString;
            }

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.grid12m = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            accountingPlService.getAccountingProfitLoss().then(function (result) {
                angular.copy(result[0], vm.model.setting);

                loadAccountingYears();
                loadProjects();
                loadDepartments();
                loadEquipments();
                loadLabours();

                if (utilityService.validateParmsValue(vm.model.setting.grid_std_view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.setting.grid_std_view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.grid_12m_view_id) === true) {
                    vm.grid12m.dataTask.rememberId = vm.model.setting.grid_12m_view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.grid_std_p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.grid_std_p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.grid_12m_p2_datatask_keyno) === true) {
                    vm.grid12m.dataTask.loadData = {
                        method: vm.model.setting.grid_12m_p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;
                vm.model.grid12mReady = true;
            });

            var loadGrid = function () {
                if (vm.model.setting.show_12months === '0') {
                    vm.grid.gridfunc.rebind();
                }

                if (vm.model.setting.show_12months === '1') {
                    vm.grid12m.gridfunc.rebind();
                }
            };

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListCompanies);
            });

            accountingPlService.listAcReportLayouts({ res: '1' }).then(function (result) {
                angular.copy(result, vm.model.selectListReportLayouts);
            });

            var loadAccountingYears = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                accountingBsService.listAccountingYears({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListAccountingYears);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                            variableNames[key] = 'w_accountingprocessing' + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'acreportlayout_keyno':
                    case 'show_12months':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'accounting_year':
                    case 'show_accounts':
                    case 'project_keynos':
                    case 'department_ids':
                    case 'equipment_ids':
                    case 'labour_nos':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };


            vm.fetchData = function (model) {
                loadGrid();
            }

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadAccountingYears();
                        loadProjects();
                        loadDepartments();
                        loadEquipments();
                        loadLabours();
                        loadGrid();
                        break;
                    case 'acreportlayout_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.acreportlayout_keyno) !== true) return;

                        vm.model.setting.acreportlayout_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.acreportlayout_keyno, true) !== true) return;

                        rememberFunc('acreportlayout_keyno');
                        loadGrid();
                        break;
                    case 'show_12months':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_12months) !== true) return;

                        vm.model.setting.show_12months = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_12months, true) !== true) return;

                        rememberFunc('show_12months');
                        loadGrid();
                        break;
                    case 'accounting_year':
                        if (utilityService.validateWatchValue(value, vm.model.setting.accounting_year) !== true) return;

                        vm.model.setting.accounting_year = value;

                        if (utilityService.validateParmsValue(vm.model.setting.accounting_year, true) !== true) return;

                        rememberFunc('accounting_year');
                        loadGrid();
                        break;
                    case 'show_accounts':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_accounts) !== true) return;

                        vm.model.setting.show_accounts = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_accounts, true) !== true) return;

                        rememberFunc('show_accounts');
                        loadGrid();
                        break;
                    case 'project_keynos':
                        console.dir('project keynos change');
                        if (utilityService.validateWatchValue(value, vm.model.setting.project_keynos) === true) {

                            vm.model.setting.project_keynos = value;

                            //const seperator = value.match(/\D/)?.[0] ?? ' ';

                            //let selectedIds = vm.model.setting.project_keynos.split(seperator);


                            //vm.popoverProjects.data[0].data.list.forEach((item) => {

                            //    if (selectedIds.length > 0 && selectedIds.includes(item.item_id)) {
                            //        console.log('item is selected');
                            //        item.is_selected = true;
                            //    } else if (selectedIds.length === 0 && item.item_id === value) {
                            //        item.is_selected = true;
                            //    } else {
                            //        item.is_selected = false;
                            //    }
                            //});
                            selectIdsInList(vm.popoverProjects.data[0].data.list, value);

                            if (utilityService.validateParmsValue(vm.model.setting.project_keynos, true) !== true) return;

                            rememberFunc('project_keynos');
                            //loadGrid();
                        }
                        break;
                    case 'department_ids':
                        if (utilityService.validateWatchValue(value, vm.model.setting.department_ids) === true) {


                            vm.model.setting.department_ids = value;

                            //const seperator = value.match(/\D/)?.[0] ?? ' ';

                            //let selectedIds = vm.model.setting.department_ids.split(seperator);
                            //console.log(selectedIds);

                            //vm.popoverDepartments.data[0].data.list.forEach((item) => {

                            //    if (selectedIds.length > 0 && selectedIds.includes(item.item_id)) {
                            //        console.log('item is selected');
                            //        item.is_selected = true;
                            //    } else if (selectedIds.length === 0 && item.item_id === value) {
                            //        console.log('item is selected but no list');
                            //        item.is_selected = true;
                            //    } else {
                            //        item.is_selected = false;
                            //    }
                            //});
                            selectIdsInList(vm.popoverDepartments.data[0].data.list, value);

                            if (utilityService.validateParmsValue(vm.model.setting.department_ids, true) !== true) return;

                            rememberFunc('department_ids');
                            //loadGrid();
                        }
                        break;
                    case 'equipment_ids':
                        if (utilityService.validateWatchValue(value, vm.model.setting.equipment_ids) === true) {
                            vm.model.setting.equipment_ids = value;

                            selectIdsInList(vm.popoverEquipments.data[0].data.list, value);

                            if (utilityService.validateParmsValue(vm.model.setting.equipment_ids, true) !== true) return;

                            rememberFunc('equipment_ids');
                        }
                        break;
                    case 'labour_nos':
                        if (utilityService.validateWatchValue(value, vm.model.setting.labour_nos) !== true) return;

                        vm.model.setting.labour_nos = value;
                        selectIdsInList(vm.popoverLabours.data[0].data.list, value);

                        if (utilityService.validateParmsValue(vm.model.setting.labour_nos, true) !== true) return;

                        rememberFunc('labour_nos');
                        //loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            function selectIdsInList(list, ids) {
                const seperator = ids.match(/\D/)?.[0] ?? ' ';
                let selectedIds = ids.split(seperator);

                list.forEach((item) => {
                    if (selectedIds.length > 0 && selectedIds.includes(item.item_id)) {
                        console.log('item is selected');
                        item.is_selected = true;
                    } else if (selectedIds.length === 0 && item.item_id === ids) {
                        console.log('item is selected but no list');
                        item.is_selected = true;
                    } else {
                        item.is_selected = false;
                    }
                });

                return list;
            }

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');

                if (vm.model.setting.date_tom) rememberFunc('date_tom');

                if (vm.model.setting.dateselector_index && vm.model.setting.dateselector_index !== -1) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadGrid();
                }
            }


            async function loadProjects() {
                vm.popoverProjects.data[0].data.list = (await typeaheadService.lookUpProjectNames({ "bg_projects_activestatus": "ALL", businessco_no: vm.model.setting.businessco_no, "searchtext": '', "argtype": "0", "argvalue": "0" })).map((item) => ({ ...item, is_selected: false }));
            }

            async function loadDepartments() {
                vm.popoverDepartments.data[0].data.list = (await departmentsService.listDepartments({ businessco_no: vm.model.setting.businessco_no })).map((item) => ({ ...item, is_selected: false }));
            }

            async function loadEquipments() {
                vm.popoverEquipments.data[0].data.list = (await accountingPlService.getEquipments({ businessco_no: vm.model.setting.businessco_no })).map((item) => ({ ...item, is_selected: false }));
            }

            async function loadLabours() {
                vm.popoverLabours.data[0].data.list = (await accountingPlService.getLabours({ businessco_no: vm.model.setting.businessco_no })).map((item) => ({ ...item, is_selected: false }));
            }

            vm.$onInit = function () {

            }

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
