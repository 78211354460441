(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('capTaskResource', {
        templateUrl: 'views/components/views/capTaskResource/capTaskResource.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'capTaskResourceService', function ($stateParams, stateService, modalService, capTaskResourceService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            vm.model = {
                capTaskResourceKeyNo: $stateParams.captaskresource_keyno,
                bgCapTaskResourceMain: {
                    selected: 'BASIS',
                    buttons: [
                        { id: 'BASIS', label: 'gb_captaskresource_main_basis', color: 'primary', onClick: function () { vm.model.bgCapTaskResourceMain.selected = 'BASIS'; } },
                        { id: 'OTHER', label: 'gb_captaskresource_main_other', color: 'primary', onClick: function () { vm.model.bgCapTaskResourceMain.selected = 'OTHER'; } }
                    ]
                },
                editResource: {},
                lockedWorking: false,
                lockedPlanned: false,
                lockedFinish: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadCapTaskResource = function () {
                capTaskResourceService.getCapTaskResource(vm.model.capTaskResourceKeyNo).then(function (data) {
                    angular.copy(data[0], vm.model.editResource);
                });
            };

            loadCapTaskResource();

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE PROCEDURE FUNCTION CALL

            var workingNewStatus = function () {
                vm.model.lockedWorking = true;
                
                var parms = {
                    captaskresource_keyno: vm.model.editResource.captaskresource_keyno,
                    new_postatus_no: '200'
                };

                capTaskResourceService.newStatus(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedWorking = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedWorking = false;
                        loadCapTaskResource();
                    }
                });
            };

            var plannedNewStatus = function () {
                vm.model.lockedPlanned = true;

                var parms = {
                    captaskresource_keyno: vm.model.editResource.captaskresource_keyno,
                    new_postatus_no: '100'
                };

                capTaskResourceService.newStatus(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPlanned = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedPlanned = false;
                        loadCapTaskResource();
                    }
                });
            };

            var finishNewStatus = function () {
                vm.model.lockedFinish = true;

                var parms = {
                    captaskresource_keyno: vm.model.editResource.captaskresource_keyno,
                    new_postatus_no: '300'
                };

                capTaskResourceService.newStatus(parms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedFinish = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedFinish = false;
                        loadCapTaskResource();
                    }
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCapTaskResourceMain = function (item) {
                vm.model.bgCapTaskResourceMain.selected = item;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'equipmentedit':
                        go({
                            equipment_id: vm.model.editResource.equipment_id
                        });
                        break;
                    case 'po':
                        go({
                            po_keyno: vm.model.editResource.po_keyno
                        });
                        break;
                    case 'captaskplans':
                        go({
                            argtype: 'captaskresource_keyno',
                            argvalue: vm.model.editResource.captaskresource_keyno
                        });
                        break;
                    case 'orderhead':
                        go({
                            order_internal_no: vm.model.editResource.order_internal_no
                        });
                        break;
                    case 'pofinishprod':
                        go({
                            po_keyno: vm.model.editResource.po_keyno,
                            p2_pofinishprod_keyno: '0'
                        });
                        break;
                    case 'stockcontrolloclist':
                        go({
                            stocklocation_keyno: vm.model.editResource.stocklocation_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.goToFuncCapJob = function () {
                stateService.go('documents', {
                    argtype: 'prod_id',
                    argvalue: vm.model.editResource.capjob_prod_keyno
                });
            };

            vm.goToFuncCapTask = function () {
                stateService.go('documents', {
                    argtype: 'prod_id',
                    argvalue: vm.model.editResource.captask_prod_keyno
                });
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'working':
                        workingNewStatus();
                        break;
                    case 'planned':
                        plannedNewStatus();
                        break;
                    case 'finish':
                        finishNewStatus();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
            	angular.forEach(onDestroy, function (fn) {
            		if (angular.isFunction(fn) === true) {
            			fn();
            		}
            	});
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
