(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("furnitureFabricsService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadFurnitureFabricsGet: function (furniturefabrics_id) {
                return p2DataTaskService.call(1182, {
                    furniturefabrics_id: furniturefabrics_id
                });
            },
            loadProdTypeList: function () {
                return p2DataTaskService.call(219, {});
            },
            loadProdGrpList: function () {
                return p2DataTaskService.call(915, {});
            },
            loadCollectionList: function () {
                return p2DataTaskService.call(1218, {});
            },
            loadColorGrpViewList: function () {
                return p2DataTaskService.call(1204, {});
            },
            loadProductChoiceList: function () {
                return p2DataTaskService.call(1159, {});
            },
            loadFabricsGrpList: function () {
                return p2DataTaskService.call(1240, {});
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1217,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
