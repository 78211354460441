(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('travelWageSets', {
        templateUrl: 'views/components/views/travelWageSets/travelWageSets.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'travelWageSetsService', function (stateService, travelWageSetsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            
            vm.model = {
                itemsListTravelWageSets: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadTravelWageSets = function () {
                travelWageSetsService.listTravelWageSets().then(function (list) {
                    angular.copy(list, vm.model.itemsListTravelWageSets);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'travelwageset':
                        go({ travelwageset_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadTravelWageSets();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
