import { Injectable } from '@angular/core';
import { DataServiceBase, IData } from '../framework/data-service-base';
import { DataTaskService } from './data-task.service';

export interface INotification {
    item_id?: string,
    item_name?: string,
    item_path?: string,
    item_state?: string,
    item_parms?: string,
    item_name_sub1?: string,
    item_glyphicon?: string,
    item_glyphicon_color?: string,
    orderby?: number,
    item_filtervalue?: string
}

export interface INotificationSettings {
    searchtext?: string
}

export interface INotificationEventArgs {
    type: string,
    value: number
}

type NotificationEventHandler = (args: INotificationEventArgs) => void;

@Injectable({
    providedIn: 'root',
})
export class NotificationsService extends DataServiceBase {
    private _eventHandlers: Record<string, NotificationEventHandler> = {};

    constructor(private dt: DataTaskService) {
        super();
    }

    public async getSettings(): Promise<INotificationSettings> {
        const data = await this.dt.Post(1471);

        return data[0];
    };

    public async loadUnreadNotifications(): Promise<IData<INotification>> {
        const data = await this.dt.Post(345);

        this.broadcast('unread', data.length);

        return {
            records: data
        }
    };

    public async loadReadNotifications(): Promise<IData<INotification>> {
        const data = await this.dt.Post(339, { statusid: '1' });

        this.broadcast('read', data.length);

        return {
            records: data
        }
    }

    public setAsRead(item: INotification): Promise<INotification> {
        return this.dt.Post(340, { p2_notification_keyno: item.item_id });
    }

    public subscribe(id: string, handler: NotificationEventHandler) {
        if (this._eventHandlers.hasOwnProperty(id)) return;
        if (typeof handler !== 'function') return;

        this._eventHandlers[id] = handler;
    }

    public unsubscribe(id: string) {
        if (!this._eventHandlers.hasOwnProperty(id)) return;

        delete this._eventHandlers[id];
    }

    private broadcast(type: string, value: number) {
        for (const id in this._eventHandlers) {
            if (this._eventHandlers.hasOwnProperty(id)) {
                this._eventHandlers[id]({
                    type: type,
                    value: value
                });
            }
        }
    }
}
