(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchaseCreate', {
        templateUrl: 'views/components/views/purchaseCreate/purchaseCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'purchaseCreateService', 'modalService', function ($stateParams, purchaseCreateService, modalService) {
            var vm = this;

            // #region VARIABLES & DEFINITIONS

            vm.grid = {
                dataTask: {
                    rememberId: 'w_purchasecreate',                     // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2269,
                        parameters: { textilemodel_id: $stateParams.textilemodel_id }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            { name: 'createProduction', text: 'create_production', func: function (data) { createProduction(data); }, icon: 'track-changes-enable', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    //console.log('optionfunc - OnCellClose');
                    //console.dir(data);
                    //var rows = vm.grid.gridfunc.getAllRows();
                    //console.dir(rows);
                    //console.dir(vm.grid.gridfunc.getDataSource());
                    //console.dir(vm.grid.gridfunc.getDataSource().aggregates());
                    //if (data.cval === "suggestion") {
                    //}

                    if (data.cval === "production_markup_pst") {
                        if (angular.isDefined(data.rdata)) {
                            if (data.rdata.disp < 0) {
                                data.rdata.suggestion = Math.ceil(- 1 * (-1 * data.rdata.stock_outgoing + (-1 * data.rdata.stock_outgoing * data.rdata.production_markup_pst / 100)));
                            } else {
                                data.rdata.suggestion = Math.ceil(data.rdata.stock_outgoing + (data.rdata.stock_outgoing * data.rdata.production_markup_pst / 100));
                                //data.rdata.suggestion = data.rdata.production_markup_pst;
                            }
                        }
                    }

                    if (angular.isDefined(data.rdata)) {
                        data.rdata.available = data.rdata.disp + data.rdata.suggestion;
                    }

                    vm.grid.gridfunc.redrawRow(null, data.rdata);
                    vm.grid.gridfunc.refreshAggregates();

                //    vm.grid.gridfunc.refresh();
                }

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            var createProduction = function () {
                //console.log('Create Production');
                //console.dir(vm.grid.gridfunc.getAllRows());
                var callData = [];
                angular.forEach(vm.grid.gridfunc.getAllRows(), function (dataItem) {
                    //if (dataItem.dirty === true) dirty.push(dataItem);
                    callData.push({
                        textilemodel_id: dataItem.textilemodel_id,
                        textilecolor_id: dataItem.textilecolor_id,
                        textilesize_id: dataItem.textilesize_id,
                        suggestion: dataItem.suggestion
                    });
                });
                //var index = $scope.gridOptions.gridfunc.addRowBefore();
                //calculationTextilemodelService.pricelistUpdate().then()
                purchaseCreateService.createProduction(callData).then(function (data) {
                    //console.log('Done');
                    //console.dir(data);

                    if (data[0].errorcode === '0') {
                        modalService.show({
                            type: 'success',
                            title: 'OK',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-success',
                                action: function (modalInstance) {
                                    vm.grid.gridfunc.rebind();
                                    modalInstance.close();
                                }
                            }]
                        });
                    } else {
                        modalService.show({
                            type: 'danger',
                            title: 'ERROR',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-danger',
                                action: function (modalInstance) {
                                    modalInstance.close();
                                }
                            }]
                        });
                    }
                });
            };

            var testItems = function () {
                console.log('Test run successfully');
                //var index = $scope.gridOptions.gridfunc.addRowBefore();
            };

            vm.testFunc = function () {
                console.log('testFunc runs');
            }

            // #endregion OPTIONFUNC
        }]
    });
})();
