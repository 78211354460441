(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acReportLayouts', {
        templateUrl: 'views/components/views/acReportLayouts/acReportLayouts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'accountingPlService', function (stateService, accountingPlService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                itemsListReportLayouts: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            accountingPlService.listAcReportLayouts({ res: 'a' }).then(function (result) {
                angular.copy(result, vm.model.itemsListReportLayouts);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'acreportlayout':
                        stateService.go(state, {
                            acreportlayout_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();