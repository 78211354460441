(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderSpecialGridDelivery', {
        templateUrl: 'views/components/views/orderSpecial/orderSpecial.template.grid.delivery.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$q', 'layoutService', 'translateService', 'orderSpecialService', 'modalService', function ($q, layoutService, translateService, orderSpecialService, modalService) {
            var vm = this;

            var onDestroy = [];

            vm.delivery = {
                data: {
                    deliverymethod_no: '',
                    deliverymethod_name: '',
                    phone: '',
                    equipment_id: '',
                    equipment_name: '',
                    labour_no: '',
                    labour_name: ''
                },
                selected: {
                    field: '',
                    key: ''
                },
                methodsList: [],
                equipmentList: [],
                labourersList: []
            };

            vm.fontSize = '14px';

            vm.translations = {
                tt_new_order_special_delivery_driver: '',
                //tt_new_order_special_delivery_driver_update_warning: '',
                tt_generic_modal_warning_title: ''
            };

            vm.finish = function () {
                orderSpecialService.updateDriver({
                    argtype: '',
                    argvalue: '',
                    deliverymethod_no: vm.delivery.data.deliverymethod_no,
                    delivery_phone: vm.delivery.data.phone,
                    equipment_id: vm.delivery.data.equipment_id,
                    labour_no: vm.delivery.data.labour_no
                }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: vm.translations.tt_generic_modal_warning_title,
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        vm.modalInstance.close(vm.delivery.data);
                    }
                });
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.handleLabourerChange = function (value) {
                vm.delivery.data.labour_no = value;

                for (let i = 0; i < vm.delivery.labourersList.length; i++) {
                    if (vm.delivery.labourersList[i].labour_no === value) {
                        vm.delivery.data.labour_name = vm.delivery.labourersList[i].labour_name;
                        break;
                    }
                }
            };

            vm.handleDeliveryMethodChange = function (value) {
                vm.delivery.data.deliverymethod_no = value;

                for (let i = 0; i < vm.delivery.methodsList.length; i++) {
                    if (vm.delivery.methodsList[i].deliverymethod_no === value) {
                        vm.delivery.data.phone = vm.delivery.methodsList[i].phone;
                        vm.delivery.data.deliverymethod_name = vm.delivery.methodsList[i].deliverymethod_name;
                        break;
                    }
                }
            };

            // #region LOOKUPSEARCH FUNCTIONS

            vm.selectItem = function (item, model) {
                if (angular.isDefined(item) && angular.isDefined(item.equipment_id) && item.equipment_id !== null && item.equipment_id !== '') {
                    vm.delivery.selected.field = item.equipment_name;
                    vm.delivery.data.equipment_id = item.equipment_id;
                    vm.delivery.data.equipment_name = item.equipment_name;
                }
            };

            // #endregion LOOKUPSEARCH FUNCTIONS

            function prepareDelivery() {
                let deferred = $q.defer();

                let promises = [];

                promises.push(orderSpecialService.getDeliveryMethods());
                promises.push(orderSpecialService.getEquipmentList());
                promises.push(orderSpecialService.getLabourers());
                promises.push(orderSpecialService.getCurretLabourer());

                $q.all(promises).then(function (response) {
                    angular.copy(response[0], vm.delivery.methodsList);
                    angular.copy(response[1], vm.delivery.equipmentList);
                    //angular.copy(response[2], vm.delivery.labourersList);
                    angular.forEach(response[2], function (data) {
                        if (angular.isDefined(data) && angular.isDefined(data.item_id)) {
                            vm.delivery.labourersList.push({ labour_no: data.item_id, labour_name: data.item_name });
                            if (response[3][0].labour_no === data.item_id) {
                                vm.delivery.data.labour_no = data.item_id;
                                vm.delivery.data.labour_name = data.item_name;
                            }
                        }
                    });

                    deferred.resolve(response);
                }, function (error) {
                    console.log('error! error! error! error! error!');

                    if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                        console.log(error.data.message);

                        if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                            console.log(error.data.data.message);
                        }
                    } else {
                        if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                            console.log(error.data.data.message);
                        }
                    }

                    console.dir(error);

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                prepareDelivery().then(function (data) {
                    //console.dir(data);
                    //for (let l = 0; l < vm.delivery.labourersList.length; l++) {
                    //    if (vm.delivery.labourersList[l].labour_no === vm.delivery.data.labour_no) {
                    //        break;
                    //    }
                    //}
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
