(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srTravelBillSpecService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrTravelBillSpecGet: function (travelbill_no, argtype, argvalue) {
                return p2DataTaskService.call(1618, {
                    travelbill_no: travelbill_no,
                    argtype: argtype,
                    argvalue: argvalue
                });
            },
            loadSrTravelBillSpecSummaryList: function (specifyRepayment) {
                return p2DataTaskService.call(1619, specifyRepayment);
            }
        };

        return service;
    }]);
})();