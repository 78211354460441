(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlansDeliverySettings', {
        templateUrl: 'views/components/views/consignmentPlansDeliverySettings/consignmentPlansDeliverySettings.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'consignmentPlansDeliverySettingsService', function (stateService, consignmentPlansDeliverySettingsService) {
            var vm = this;

            vm.model = {
                deliverymethods: [],
                portalusers: [],
                stocklocations: [],
                stocklocation_keyno: '',
                deliverymethod_no: '',
                portal_user_keyno: '',
                loaded: false            
            };
            
            vm.saveSettings = function () {
                //consignmentPlansDeliverySettingsService.remember('consignmentplansdelivery.deliverymethod_no', vm.model.deliverymethod_no);
                consignmentPlansDeliverySettingsService.remember('consignmentplansdelivery.portal_user_keyno', vm.model.portal_user_keyno);
                consignmentPlansDeliverySettingsService.remember('consignmentplansdelivery.stocklocation_keyno', vm.model.stocklocation_keyno);
                stateService.back();
            };
            
            consignmentPlansDeliverySettingsService.loadSettings().then(function (data) {
                vm.model.stocklocation_keyno = data.stocklocation_keyno;
                //vm.model.deliverymethod_no = data.deliverymethod_no;
                vm.model.portal_user_keyno = data.portal_user_keyno;

                /*if (!parseInt(vm.model.portaluser_keyno) > 0){
                    vm.model.settings_mode = 1;
                }*/
            });
            
            consignmentPlansDeliverySettingsService.loadPortalUsers().then(function (data) {
                angular.copy(data, vm.model.portalusers);
            });
            
            consignmentPlansDeliverySettingsService.loadStockLocations().then(function (data) {
                angular.copy(data, vm.model.stocklocations);
            });
        }]
    });
})();