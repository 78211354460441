(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('poFinishProdWorks', {
        templateUrl: 'views/components/views/poFinishProdWorks/poFinishProdWorks.template.html?v=' + module.version, 
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishProdWorksService', function ($stateParams, poFinishProdWorksService) {
            let vm = this;
            let p2PoFinishProdKeyno = $stateParams.p2_pofinishprod_keyno;

            vm.model = {
                itemsListProductWorks: []
            };

            let loadProductWorks = function () {
                poFinishProdWorksService.listProductWorks({ p2_pofinishprod_keyno: p2PoFinishProdKeyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListProductWorks);
                });
            };
            
            vm.$onInit = function () {
                loadProductWorks();
            };
        }]
    });
})();
