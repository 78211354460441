(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("personService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loadPersonGet: function (cust_no, pers_no) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 72,
                    parameters: {
                        cust_no: cust_no,
                        pers_no: pers_no
                    }
                }).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            loadPersonPhonesList: function (pers_no) {
                return $ihttp.post({
                    method: 73,
                    parameters: {
                        pers_no: pers_no
                    }
                });
            },
            listPersonFacts: function (parms) {
                return $ihttp.post({
                    method: 2904,
                    parameters: parms || {}
                });
            },
            savePerson: function (person) {
                return $ihttp.post({
                    method: 74,
                    parameters: person
                })
            },
            deletePerson: function () {
                return $ihttp.post({
                    method: 75,
                    parameters: {
                        pers_no: $stateParams.pers_no
                    }
                });
            }
        }

        return service;
    }]);
})();
