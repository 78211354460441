(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactMemberEdit', {
        templateUrl: 'views/components/views/custactMemberEdit/custactMemberEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'custactMemberEditService', 'typeaheadService', function ($stateParams, stateService, translateService, modalService, custactMemberEditService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;
            let custactMemberKeyno = $stateParams.custact_member_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                custactMemberKeyno: custactMemberKeyno,
                personButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('person', {
                            cust_no: vm.model.edit.cust_no,
                            pers_no: vm.model.edit.pers_no
                        })
                    }
                ],
                customerNameButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('customersmenu', { cust_no: vm.model.edit.cust_no })
                    }
                ],
                edit: {},
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION

            let loadEdit = function () {
                custactMemberEditService.getActivityMember({
                    custact_no: custactNo,
                    custact_member_keyno: custactMemberKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.lookUpInitiatedByPersonNames({
                    searchtext: value || '',
                    onlyperson: '1'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.edit.pers_no = item?.pkey ?? '0';
                    vm.model.edit.pers_name = item?.item_name ?? '';
                } else {
                    vm.onClear();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChanged = function (value, item) {
                vm.model.edit.pers_name = value;

                if (value) {
                    if (item?.pkey) {
                        vm.model.edit.pers_no = item.pkey;
                    }
                } else {
                    vm.onClear();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                vm.model.edit.pers_no = '0';
                vm.model.edit.pers_name = '';
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                custactMemberEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    custactMemberEditService.deleteItem(vm.model.edit).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
