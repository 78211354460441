(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('businesscoSettingSvea', {
        templateUrl: 'views/components/views/businesscoSettingSvea/businesscoSettingSvea.template.html?v=' + imApp.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'businesscoSettingSveaService', 'translateService', function ($stateParams, stateService, modalService, businesscoSettingSveaService, translateService) {

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            var vm = this;

            vm.model = {
                edit: {},
                svea_get: {},
                testing: false,
                saving: false,
                hasUnsavedData: false
            };

            let translations = {
                svea_test_modal_header_warning: 'Feil',
                svea_test_modal_header_success: 'Suksess',
                svea_test_modal_label_ok: 'OK'
            };

            // #region LOAD PROCEDURE FUNCTION CALLS

            vm.loadSettings = function () {
                businesscoSettingSveaService.loadP2BusinessCoSettingSveaGet($stateParams.businessco_no).then(function (result) {
                    vm.model.svea_get = angular.copy(result[0]);
                    vm.model.edit = angular.copy(result[0]);
                    console.dir(vm.model);
                });
            };

            vm.$onInit = function () {
                vm.loadSettings();
            }

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            const hasUnsavedData = function () {
                return JSON.stringify(vm.model.edit) !== JSON.stringify(vm.model.svea_get);
            }

            vm.changeValue = function (key, value) {
                vm.model.edit[key] = value;
                vm.model.hasUnsavedData = hasUnsavedData();
            }

            vm.testCredentials = function () {
                vm.testing = true;
                businesscoSettingSveaService.testCredentials(vm.model.edit.svea_sender_id, vm.model.edit.svea_api_key).then(function (response) {
                    modalService.show({
                        type: response[0].errorcode === '0' ? 'success' : 'danger',
                        title: response[0].errorcode === '0' ? translations.svea_test_modal_header_success : translations.svea_test_modal_header_warning ,
                        message: response[0].errormessage,
                        buttons: [{
                            label: translations.svea_test_modal_label_ok,
                            cssClass: 'btn-success',
                            action: function (dialogItself) {
                                dialogItself.close();
                                vm.testing = false;
                            }
                        }]
                    });
                });
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            vm.resetChanges = function () {
                vm.model.edit = vm.model.svea_get;
                hasUnsavedData = false;
            }

            vm.saveChanges = function () {
                businesscoSettingSveaService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
		}]
	});
})();
