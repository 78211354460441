(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('prePickCreate', {
        templateUrl: 'views/components/views/prePickCreate/prePickCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'prePickCreateService', function ($stateParams, stateService, modalService, prePickCreateService) {
            var vm = this;
            
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                getPrePickCreate: {},
                listSelectStockLocationPrePick: []
            };
            
            prePickCreateService.loadPrePickCreateGet(vm.model.argType, vm.model.argValue).then(function (data) {
    			vm.model.getPrePickCreate = angular.copy(data[0]);
                
                loadSelectStockLocationPrePickList();
    		});
    		
            var loadSelectStockLocationPrePickList = function () {
                //vm.model.listSelectStockLocationPrePick = [];
                
                prePickCreateService.loadSelectStockLocationPrePickList(vm.model.getPrePickCreate).then(function (data) {
                    angular.copy(data, vm.model.listSelectStockLocationPrePick);
                });
            };
            
            vm.goToPreConsignment = function (item) {
                prePickCreateService.saveToPrePickCreate(vm.model.argType, vm.model.argValue, item.item_id).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        var ok = 'OK';
                        
                        modalService.show({
                            type: 'warning',
                            title: 'Error',
                            message: data[0].errormessage,
                            buttons: [{
                                label: ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                    }
                });
            };
        }]
    });
})();