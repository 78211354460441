(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('dispatchPlanning', {
        templateUrl: 'views/components/views/dispatchPlanning/dispatchPlanning.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'dispatchPlanningService', function ($timeout, stateService, dispatchPlanningService) {
            const vm = this;
            
            var resizing = false;
            var editing = false;

			var settingsLoaded = false;

            vm.model = {
                setting: {
                    deliverymethod_no: "0",
                    date_fom: "",
                    date_tom: "",
                    dateselector_index: "1"
                },
                deliverymethods: [],
                loaded: false,
                consolVisible: false,
                consolSplitVisible: false,
                clickDec: false,
				filter_customer: '',
				filter_deliverymethod_no: ''                
            };

			function corebuttons (){
				return [
					//{ name: 'customStock', text: '', func: function () { vm.customStock(); }, icon: 'categorize' },
					{ name: 'customFilter', text: '', func: function () { customFilter(); }, icon: 'filter' },
					{ name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' },
					{ name: 'customConsolidate', text: '', func: function () { customConsolidate(); }, icon: 'hyperlink' },
					//{ name: 'customConsolidateRemove', text: '', func: function () { customConsolidateRemove(); }, icon: 'hyperlink-remove' },
				];
			}

			vm.grid = {
				setup: {
					remember: 'dispatchplanning.list.grid',
					columns: [],
					format: [],
					editableCols: [],
					filter: { enabled: false, mode: false },
					//toolbar: false,
					toolbar: { //alternative -> toolbar: false,
						hidden: false,
						fullToolbar: false,
						tBarSave: false,
						emptyToolbar: true,
						hideExports: true,
						buttons: corebuttons ()
						},
					pager: true,
					autosave: true,
					localData: true,
					translate: true,
					selectable: false,
					behaviour: {
						selectable: "multiple",
						rowClick: false
					}
				},
				data: [],
				selectedCount: 0,
				dataRaw: [],
				saveData: [],
				grid: null,
				gridfunc: null
			};

			vm.gridOptionFunc = gridOptionFunc;
			
			vm.onDeliveryMethodsChange = onDeliveryMethodsChange;
            vm.customFilter = customFilter;
			vm.customClean = customClean;
            
            var selectecedAll = false;

            function gridOptionFunc(data) {
				if (angular.isDefined(data.type)) {
					$timeout(function(){
                        vm.grid.selectedCount = 0;

						try {
							vm.grid.selectedCount = vm.grid.gridfunc.getSelectedRowsCount();						
						} catch (error) {}						
						
						if (vm.grid.selectedCount === 1){
							var rows = vm.grid.gridfunc.getSelectedRows();
							if (vm.model.filter_customer == ''){
								vm.model.filter_customer = rows.data[0].dp_customer;
								vm.model.filter_deliverymethod_no = rows.data[0].dp_carrier;
								reRenderData();
							}													
						}
                    }, 100);

					switch (data.type) {
						case 'init':
							vm.grid.grid = data.grid;
							
							break;
						case 'dataBoundRow':
							var cols = {};
                            data.grid.columns;
                            var cellIndex = 0;

                            angular.forEach(data.grid.columns,function(item){
                                item.cellIndex = cellIndex;
                                cols[item.field] = item;
                                cellIndex += 1;
                            });

							data.row.find('td:eq('+cols['grid_glyphs'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                e.preventDefault();
								
								if (data.dataItem.item_dispatch_count > 1){
                                    var dispatch_no = data.dataItem.dp_dispatch_no;

									dispatchPlanningService.splitDispatch(dispatch_no).then(function(data){
										vm.model.filter_customer = '';
                                        vm.model.filter_deliverymethod_no = '';

										loadOrderQued();
									});
								}
                            });

							data.row.find('td:eq('+cols['dp_order_no'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                e.preventDefault();
								
								stateService.go('dispatchplanningorderlines', { argtype: 'order_internal_no', argvalue: data.dataItem.item_order_internal_no, counter: 2 });
                            });

							data.row.find('td:eq('+cols['dp_dispatch_no'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                e.preventDefault();
								
								stateService.go('dispatchplanningconsignment', { dispatch_no: data.dataItem.dp_dispatch_no });
                            });

							data.row.find('td:eq('+cols['dp_status_stock'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                e.preventDefault();
								
								stateService.go('productionhtopscomponents', { argtype: 'order_internal_no', argvalue: data.dataItem.item_order_internal_no, counter: 2 });
                            });

							data.row.find('td:eq('+cols['dp_status_blockorder'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                e.preventDefault();
								
								stateService.go('dispatchplanningblockorder', { order_internal_no: data.dataItem.item_order_internal_no });
                            });
							
							break;
						default:
							break;
					}
				}
			}

			function customFilter(){
                var m = 0;

				if (vm.grid.setup.filter.mode == true){
					vm.grid.setup.filter.mode = false;
					m = 0;
				} else {
					vm.grid.setup.filter.mode = false;
					m = 1;
                }

				savePageSetting('dispatchplanning.list.grid.filter',m,function(){
					stateService.go('dispatchplanning',{},{ reload: true, inherit: false });
				});
			}

			function customClean(){
				if (vm.model.filter_customer !== ''){
					vm.model.filter_customer = '';
					vm.model.filter_deliverymethod_no = '';
					
					reRenderData();
				}				
			}

			function customConsolidate(){
				var is_ok = null;
				var rows = vm.grid.gridfunc.getSelectedRows();
				var cust = '';
				var car = '';
				var records = [];
				
				if (rows.data.length > 0){
					angular.forEach(rows.data,function(item){
						if ((car != '') && (cust != '')){
							if ((car == item.dp_carrier) && (cust == item.dp_customer)) {
								if (is_ok != false){
									is_ok = true;									
								}
							} else {
								is_ok = false;
							}							
                        }

						car = item.dp_carrier;
                        cust = item.dp_customer;

                        var sendItem = {
                            item_delivery_keyno: item.item_delivery_keyno
                        };

						records.push(sendItem);
					});
                }

				if (is_ok == true){
                    customClean();

					dispatchPlanningService.consolidateSelected({items: records}).then(function(data){
						loadOrderQued();
					});
				}
			}
            
            function getDeliveryMethods () {
                dispatchPlanningService.getDeliveryMethods().then(function (data) {
                    angular.copy(data, vm.model.deliverymethods);
                    loadPageSettings();
                });
            };

            getDeliveryMethods();

            function loadPageSettings() {
                settingsLoaded = false;

                dispatchPlanningService.loadPageSettings().then(function (data) {
                    angular.copy(data[0], vm.model.setting);

					if (vm.model.setting.filter == 0){
						vm.grid.setup.filter = {enabled: false, mode: false};
						vm.grid.setup.height =  620;
					} else {
						vm.grid.setup.filter = {enabled: true, mode: true};
						vm.grid.setup.height =  580;
                    }

                    settingsLoaded = true;
                    loadOrderQued();
                });
            }

            var canRun616 = function (variableName, VariableValue) {
                if (angular.isUndefined(variableName) === true) return false;
                if (variableName === null) return false;
                if (variableName === '') return false;
                if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
                if (vm.model.setting[parm] === null) return false;
                return true;
            };

            function savePageSetting(variableName, variableValue, optionalFunc) {
                dispatchPlanningService.savePageSetting({
                    variablename: variableName,
                    variablevalue: variableValue
                }).then(function (data) {
                    if (angular.isFunction(optionalFunc)) {
						optionalFunc();
					}
                });
            }

			function reRenderData(){
				var buffer = [];
				var counter = 0;
				vm.grid.data = []; //test
				
				angular.forEach(vm.grid.dataRaw, function (orgItem) {
					var item = {};
					angular.copy(orgItem,item);
					item.is_visible = 1;

                    if ((vm.model.filter_customer !== '') && (vm.model.filter_deliverymethod_no !== '')) {
                        if ((item.dp_customer == vm.model.filter_customer) && (item.dp_carrier == vm.model.filter_deliverymethod_no)) {
							item.is_visible = 1;
						} else {
							item.is_visible = 0;
						}
					} else {
						item.is_visible = 1;
                    }

                    if (item.is_visible > 0) {
						vm.grid.data.push(item);												
					}
				});

				if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
					var options = vm.grid.grid.getOptions();
					options.dataSource.data = [];
					angular.copy(vm.grid.data, options.dataSource.data);
					vm.grid.grid.setOptions(options);
				}
			}

            function loadOrders() {
                settingsLoaded = false;
				vm.grid.saveData = [];
				vm.grid.data = [];
				vm.grid.dataRaw = [];

                var parms = {
                    deliverymethod_no: vm.model.setting.deliverymethod_no,
                    date_fom: vm.model.setting.date_fom,
                    date_tom: vm.model.setting.date_tom
                };

                dispatchPlanningService.listOrders(parms).then(function (data) {
					if (data.length > 3) {
						angular.copy(data[3].editable_columns, vm.grid.setup.editableCols);
					}

					if (data[2].rows.length > 0) {
						if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
							vm.grid.setup.columns = [];
                            vm.grid.setup.format = [];

							angular.copy(data[0].columns, vm.grid.setup.columns);
                            angular.copy(data[1].format, vm.grid.setup.format);

							vm.grid.data = [];
							vm.grid.dataRaw = [];
							vm.grid.data.push(data[2].rows[0]);

							$timeout(function () {
								vm.grid.setup.columns = [];
								vm.grid.setup.format = [];

								angular.copy(data[0].columns, vm.grid.setup.columns);
                                angular.copy(data[1].format, vm.grid.setup.format);

								vm.grid.data = [];
								vm.grid.dataRaw = [];
								angular.copy(data[2].rows, vm.grid.dataRaw);
								
								reRenderData();
                                settingsLoaded = true;
							}, 100);

						} else {
							vm.grid.data = [];
							vm.grid.dataRaw = [];
							vm.grid.setup.columns = [];
                            vm.grid.setup.format = [];

							angular.copy(data[0].columns, vm.grid.setup.columns);
							angular.copy(data[1].format, vm.grid.setup.format);
							angular.copy(data[2].rows, vm.grid.dataRaw);

							reRenderData();
                            settingsLoaded = true;
						}
					} else {
						settingsLoaded = true;
					}
				});
			};

            vm.illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            }

			var timer = null;

			function loadOrderQued(){
				if (angular.isDefined(timer)){
					$timeout.cancel(timer);
				}
				timer = $timeout(function(){
					loadOrders();
				},300);
			}

			function onDeliveryMethodsChange(value) {
                vm.model.setting.deliverymethod_no = value;

                if (settingsLoaded){
					savePageSetting('dispatchplanning.deliverymethod_no',vm.model.setting.deliverymethod_no);
					loadOrderQued();
				}
            }

            // #region DATE RANGE FIELD CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval === '-1' ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) {
                    savePageSetting('dispatchplanning.date_fom', vm.model.setting.date_fom);
                    loadOrderQued();
                }

                if (vm.model.setting.date_tom) {
                    savePageSetting('dispatchplanning.date_tom', vm.model.setting.date_tom);
                    loadOrderQued();
                }

                if (vm.model.setting.dateselector_index) {
                    savePageSetting('dispatchplanning.dateselector_index', vm.model.setting.dateselector_index);
                    loadOrderQued();
                }
            };

            // #endregion DATE RANGE FIELD CHANGE FUNCTION

        }]
    });
})();
