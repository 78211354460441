(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('travelBillLogs', {
        templateUrl: 'views/components/views/travelBillLogs/travelBillLogs.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'travelBillLogsService', function($stateParams, travelBillLogsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                travelBillNo: $stateParams.travelbill_no,
                itemsListLogs: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            travelBillLogsService.loadTravelBillLogsList(vm.model.travelBillNo).then(function (data) {
                angular.copy(data, vm.model.itemsListLogs);
            });
        }]
    });
})();