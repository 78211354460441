(function () {
    'use strict';

    angular.module("imApp").factory("consignmentCarrierOneService", ['$ihttp', function ($ihttp) {
        var service = {
            getConsignmentCarrierOne: function (parms) {
                return $ihttp.post({
                    method: 2669,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 2670,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();