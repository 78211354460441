(function () {
    'use strict';

    angular.module('imApp').directive('ttItemManager', ['$rootScope', 'stateService', 'utilityService', 'ttItemService', 'layoutService', 'p2WebpageService', function ($rootScope, stateService, utilityService, ttItemService, layoutService, p2WebpageService) {
        var directive = {
            restrict: 'A',
            transclude: true,
            scope: {
                id: '@ttItemManager',
            },
            template: '<div ng-if="model.showLayoutControls" class="sp-0"><button class="sp-5 pull-right  tt-item-control" style="border: 0;" ng-click="toggleActive()"><span class="fa fa-eye" ng-style="model.iconStyle"></span></button></div>',
            link: function ($scope) {
                $scope.model = {
                    iconStyle: {},
                    canEditLayout: false
                }

                var userCanEditLayout = false;
                var showLayoutControls = false;

                var stateName = stateService.getCurrentName();

                p2WebpageService.details(stateName).then(function (response) {
                    showLayoutControls = utilityService.toBoolean(response.show_layout_controls);

                    $scope.model.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                var dereg = $rootScope.$on('p2WebpageService:showLayoutControlsChanged', function (data) {
                    showLayoutControls = utilityService.toBoolean(data.show_layout_controls);

                    $scope.model.showLayoutControls = userCanEditLayout && showLayoutControls;
                });

                $scope.$on('$destroy', function () {
                    if (angular.isFunction(dereg)) {
                        dereg();
                    }
                });

                $scope.toggleActive = function () {
                    ttItemService.toggleActive($scope.id);
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    $scope.model.iconStyle.fontSize = info.fontSizes.textSize;

                    userCanEditLayout = info.userCanEditLayout;

                    $scope.model.showLayoutControls = userCanEditLayout && showLayoutControls;
                });
            }
        };
 
        return directive;
    }]);
})();