(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productCompositions', {
        templateUrl: 'views/components/views/productCompositions/productCompositions.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'productCompositionsService', function (stateService, productCompositionsService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                itemsListProductCompositions: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            productCompositionsService.listProductCompositions().then(function (result) {
                angular.copy(result, vm.model.itemsListProductCompositions);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'productcomposition':
                        stateService.go(state, {
                            productcomposition_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();