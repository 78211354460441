(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockBatchSearch', {
        templateUrl: 'views/components/views/stockBatchSearch/stockBatchSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'stockBatchSearchService', 'logisticService', 'rememberService', 'ttGridFactory', function ($q, stateService, utilityService, stockBatchSearchService, logisticService, rememberService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                businessco_no: '',
                stockplace_id: '',
                searchtext: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                gridStockBatchesReady: false
            };

            vm.gridStockBatches = new ttGridFactory({
                rememberId: 'w_stockbatchsearch_stockbatch_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	const deferred = $q.defer();

            	stockBatchSearchService.getSetting().then(function (info) {
            		angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => {
                        loadGridStockBatches();

                        deferred.resolve();
                    });
            	});

            	return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (list) {
                    angular.copy(list, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridStockBatches = function () {
                vm.gridStockBatches.dataTask.loadData = {
                    method: 3415,
                    parameters: vm.model.setting
                };

                vm.model.gridStockBatchesReady = true;

                if (vm.gridStockBatches.gridfunc !== null) vm.gridStockBatches.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadGridStockBatches();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region SEARCH FUNCTION

            vm.searchItems = function () {
                rememberFunc('searchtext');
                loadGridStockBatches();
            };

            // #endregion SEARCH FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        loadGridStockBatches();
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadGridStockBatches();
                        }
                        break;
                    default:
                        //utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
