(function () {
    'use strict';

    angular.module("imApp").factory("docPackDocAddService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3293,
                    parameters: parms || {}
                });
            },
            addItems: function (mergeObj) {
                return $ihttp.post({
                    method: 3295,
                    parameters: mergeObj
                });
            },
            addDocuments: function (mergeObj) {
                return $ihttp.post({
                    method: 3303,
                    parameters: mergeObj
                });
            },
            addLabourDocuments: function (mergeObj) {
                return $ihttp.post({
                    method: 3308,
                    parameters: mergeObj
                });
            },
            addDocArcs: function (mergeObj) {
                return $ihttp.post({
                    method: 3310,
                    parameters: mergeObj
                });
            },
            updateItems: function (setting) {
                return $ihttp.post({
                    method: 3297,
                    parameters: setting
                });
            },
            updateLabours: function (setting) {
                return $ihttp.post({
                    method: 3305,
                    parameters: setting
                });
            },
            deleteItems: function (mergeObj) {
                return $ihttp.post({
                    method: 3302,
                    parameters: mergeObj
                });
            },
            deleteLabours: function (mergeObj) {
                return $ihttp.post({
                    method: 3306,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
