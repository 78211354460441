(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkList', {
        templateUrl: 'views/components/views/checkList/checkList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'checkListService', 'economyService', function ($stateParams, $q, stateService, utilityService, checkListService, economyService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let checkListKeyno = $stateParams.checklist_keyno;
            
            vm.model = {
                checkListKeyno: checkListKeyno,
                bgCheckListSelection: {
                    selected: 'HEAD',
                    buttons: [
                        { id: 'HEAD', label: 'checklist_head', color: 'primary', onClick: () => vm.model.bgCheckListSelection.selected = 'HEAD' },
                        { id: 'FIELDS', label: 'checklist_fields', color: 'primary', onClick: () => vm.model.bgCheckListSelection.selected = 'FIELDS' }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.searchtext = '' }
                ],
                edit: {
                    searchtext: ''
                },
                selectListAutoCreateTables: [],
                selectListActivityTypes: [],
                itemsListCheckItems: [],
                lockedSave: false,
                renumbering: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                const deferred = $q.defer();

                checkListService.getEdit({ checklist_keyno: checkListKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadCheckItems();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadAutoCreateTables = function () {
                checkListService.listAutoCreateTables().then(function (list) {
                    angular.copy(list, vm.model.selectListAutoCreateTables);
                });
            };

            let loadActivitityTypes = function () {
                economyService.listActivitityTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListActivityTypes);
                });
            };

            let loadCheckItems = function () {
                if (utilityService.validateParmsValue(vm.model.edit.checklist_keyno) !== true) return;

                vm.model.itemsListCheckItems = [];

                return checkListService.listCheckItems({ checklist_keyno: vm.model.edit.checklist_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCheckItems);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCheckListSelection = function (value) {
                vm.model.bgCheckListSelection.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'checklistitem':
                        go({
                            checklistitem_keyno: '0',
                            checklist_keyno: vm.model.edit.checklist_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON RENUMBER PROCEDURE FUNCTION CALL

            vm.renumberChanges = function () {
                vm.model.renumbering = true;

                checkListService.renumberCheckItems({ checklist_keyno: vm.model.edit.checklist_keyno }).then(function () {
                    vm.model.renumbering = false;
                    loadCheckItems();
                }, function () {
                    vm.model.renumbering = false;
                });
            };

            // #endregion BUTTON RENUMBER PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                checkListService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadAutoCreateTables();
                loadActivitityTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
