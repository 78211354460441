(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('priceList', {
        templateUrl: 'views/components/views/priceList/priceList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'priceListService', function ($stateParams, stateService, priceListService) {

            //##########################
            //## VARIABLES & DEFINITIONS
            //##########################

            var vm = this;
            
            vm.model = {
                parm: $stateParams.pricelist_id,
                p2PriceListGet: {},
                p2BusinessCoList: {
                    records: []
                },
                p2ValutaList: {
                    records: []
                },
                p2TaxList: {
                    records: []
                },
                p2PriceListList: {
                    records: []
                }
            };
            
            //##################
            //## LOAD PROCEDURES
            //##################
            
            vm.loadP2PriceListGet = function () {
                priceListService.genericFunction(650, {
                    pricelist_id: $stateParams.pricelist_id
                }).then(function (data) {
                    vm.model.p2PriceListGet = angular.copy(data[0]);
                });
            };
            
            vm.loadP2PriceListGet();
            
            vm.loadP2BusinessCoList = function () {
                priceListService.genericFunction(104, {}).then(function (data) {
                    angular.copy(data, vm.model.p2BusinessCoList.records);
                });
            };
            
            vm.loadP2BusinessCoList();
            
            vm.loadP2ValutaList = function () {
                priceListService.genericFunction(373, {}).then(function (data) {
                    angular.copy(data, vm.model.p2ValutaList.records);
                });
            };
            
            vm.loadP2ValutaList();
            
            vm.loadP2TaxList = function () {
                priceListService.genericFunction(640, {}).then(function (data) {
                    angular.copy(data, vm.model.p2TaxList.records);
                });
            };
            
            vm.loadP2TaxList();
            
            vm.loadP2PriceListList = function () {
                priceListService.genericFunction(627, {
                    factorbased: '1',
                    pricelist_id: $stateParams.pricelist_id
                }).then(function (data) {
                    angular.copy(data, vm.model.p2PriceListList.records);
                });
            };
            
            vm.loadP2PriceListList();
            
            //#######################
            //## SAVE BUTTON FUNCTION
            //#######################
            
            vm.saveP2PriceList = function () {
                priceListService.genericFunction(660, vm.model.p2PriceListGet).then(function (data) {
                    stateService.back();
                });
            };
            
            //#############################
            //## DIRECTION BUTTON FUNCTIONS
            //#############################
            
            vm.goToPriceListPrice = function (index) {
                stateService.go('pricelistprice', {
                    argtype: 'pricelist_id',
                    argvalue: $stateParams.pricelist_id
                });
            };
            
            vm.goToPriceListBusCos = function (index) {
                stateService.go('pricelistbuscos', {
                    pricelist_id: $stateParams.pricelist_id
                });
            };
        }]
    });
})();