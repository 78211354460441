(function() {
    'use strict';

    angular.module("imApp").factory("orderpickService", ['$q', '$timeout', '$ihttp', function ($q, $timeout, $ihttp) {
        var service = {
            init: function () {
                return $ihttp.post({
                    method: 2497,
                    parameters: {
                        state: 'init'
                    }
                });
            },

            generateOrderpick: function (parameters) {
                return $ihttp.post({
                    method: 2475,
                    parameters: parameters
                });
            }
        }

        return service;
    }]);
})();
