(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListItem', {
        templateUrl: 'views/components/views/checkListItem/checkListItem.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'checkListItemService', function ($stateParams, stateService, translateService, modalService, checkListItemService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let checkListItemKeyno = $stateParams.checklistitem_keyno;
            let checkListKeyno = $stateParams.checklist_keyno;

            let translations = {
                dependency_1: '',
                dependency_2: '',
                confirm_title: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };
            
            vm.model = {
                checkListItemKeyno: checkListItemKeyno,
                elementTypeButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => goToFunc('checklistitemitems') }
                ],
                dependency1Buttons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', type: 'danger', color: 'danger', onClick: () => clearDependency1() }
                ],
                dependency2Buttons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', type: 'danger', color: 'danger', onClick: () => clearDependency2() }
                ],
                translations: {
                    dependency_1: '',
                    dependency_2: ''
                },
                edit: {},
                selectListElementTypes: [],
                selectListDependencies: [],
                selectListDependencyValues: [],
                selectListDependencyValues2: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION FUNCTION

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                        vm.model.translations.dependency_1 = translations.dependency_1;
                        vm.model.translations.dependency_2 = translations.dependency_2;
            		}
            	});
            });

            // #endregion TRANSLATION FUNCTION

            // #region LOAD PROCEDURE CALL

            async function loadDependencies() {
                vm.model.selectListDependencies = await checkListItemService.listDependencies({ checklistitem_keyno: checkListItemKeyno });
            }

            async function loadElementTypes() {
                vm.model.selectListElementTypes = await checkListItemService.listElementTypes();
            }

            async function loadEdit() {
                return vm.model.edit = (await checkListItemService.getEdit({
                    checklistitem_keyno: checkListItemKeyno,
                    checklist_keyno: checkListKeyno
                }))[0];
            }

            async function loadDependencyValues() {
                if (vm.model.edit.checklistitem_keyno_dep?.trim()) {
                    return vm.model.selectListDependencyValues = await checkListItemService.listDependencyValues({ checklistitem_keyno: vm.model.edit.checklistitem_keyno_dep });
                }
            };

            async function loadDependencyValues2() {
                if (vm.model.edit.checklistitem_keyno_dep2?.trim()) {
                    return vm.model.selectListDependencyValues2 = await checkListItemService.listDependencyValues({ checklistitem_keyno: vm.model.edit.checklistitem_keyno_dep2 });
                }
            };

            // #endregion LOAD PROCEDURE CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            let goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'checklistitemitems':
                        checkListItemService.saveObj(vm.model.edit).then(function () {
                            go({ checklistitem_keyno: vm.model.edit.checklistitem_keyno });
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ERASE BUTTON FUNCTIONS

            function clearDependency1() {
                vm.model.edit.checklistitem_keyno_dep = '';
                vm.model.edit.checklistitem_depvalue = '';
            }

            function clearDependency2() {
                vm.model.edit.checklistitem_keyno_dep2 = '';
                vm.model.edit.checklistitem_depvalue2 = '';
            };

            // #endregion ERASE BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                checkListItemService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: vm.model.edit.deletewarning,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    vm.model.lockedDelete = false;
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.onDependency1Changed = function (value) {
                vm.model.edit.checklistitem_keyno_dep = value;

                if (value) loadDependencyValues();
            }

            vm.onDependency2Changed = function (value) {
                vm.model.edit.checklistitem_keyno_dep2 = value;

                if (value) loadDependencyValues2();
            }

            vm.$onInit = async function () {
                loadElementTypes();
                loadDependencies();
                await loadEdit();
                loadDependencyValues();
                loadDependencyValues2();
            }
        }]
    });
})();
