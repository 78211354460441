(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bsp', {
        templateUrl: 'views/components/views/bsp/bsp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'bspService', function ($stateParams, stateService, modalService, translateService, bspService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let bspKeyno = $stateParams.bsp_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
            	bspService.getEdit({ bsp_keyno: bspKeyno }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);
            	});
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                bspService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
