(function () {
    'use strict';

    angular.module("imApp").factory("pickJobGrpBatchAddrService", ['$ihttp', function ($ihttp) {
        var service = {
            getPickJobGroupBatchAddress: function (parms) {
                return $ihttp.post({
                    method: 2278,
                    parameters: parms || {}
                });
            },
            addRequisition: function (mergeObj) {
                return $ihttp.post({
                    method: 2285,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();