(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttAlert', {
        templateUrl: 'views/components/directives/ttAlert/ttAlert.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttModel: '@',
            ttTranslate: '@',
            ttStyle: '<',
            ttType: '@'
        },
        controller: ['eventService', 'translateService', 'layoutService', 'utilityService', 'ttDirectivesService', function (eventService, translateService, layoutService, us, ttDirectivesService) {
            var vm = this;

            vm.text = '';
            vm.class = '';
            vm.translate = false;
            vm.style = {
                color: 'black'
            };

            var onDestroy = [];

            var translate = function () {
                if (vm.translate !== true)
                    return;
                if (angular.isUndefined(vm.text))
                    return;
                if (us.isStringValue(vm.text, true) !== true)
                    return;

                translateService.translate(vm.text).then(function (translation) {
                    vm.text = translation;
                });
            };

            var customFontSize = false;

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (customFontSize === true) return;
                if (angular.isUndefined(info)) return;

                vm.style.fontSize = info.fontSizes.textSize;
            });

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttModel) && us.isStringValue(changes.ttModel.currentValue) && changes.ttModel.currentValue !== changes.ttModel.previousValue) {
                    vm.text = changes.ttModel.currentValue;

                    translate();
                }

                if (angular.isDefined(changes.ttTranslate)) {
                    vm.translate = us.toBoolean(changes.ttTranslate.currentValue);

                    translate();
                }

                if (angular.isDefined(changes.ttStyle) && angular.isObject(changes.ttStyle.currentValue)) {
                    angular.forEach(changes.ttStyle.currentValue, function (value, key) {
                        if (key === 'fontSize') {
                            customFontSize = true;
                        }

                        vm.style[key] = value;
                    });
                }

                if (angular.isDefined(changes.ttType) && angular.isString(changes.ttType.currentValue)) {
                    vm.class = 'alert-' + changes.ttType.currentValue;
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
