(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListItemCvs', {
        templateUrl: 'views/components/views/checkListItemCvs/checkListItemCvs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'modalService', 'stateService', 'utilityService', 'translateService', 'checkListItemCvsService', function ($stateParams, $q, modalService, stateService, utilityService, translateService, checkListItemCvsService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let checkListItemKeyno = $stateParams.checklistitem_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListCalculationAndValidations: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTIONS

            let loadSetting = function () {
                const deferred = $q.defer();

                checkListItemCvsService.getSetting({ checklistitem_keyno: checkListItemKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadCalculationAndValidations();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
    		let loadCalculationAndValidations = function () {
    			if (utilityService.validateParmsValue(vm.model.setting.checklistitem_keyno) !== true) return;
                
                return checkListItemCvsService.listCalculationAndValidations({ checklistitem_keyno: vm.model.setting.checklistitem_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCalculationAndValidations);
    			});
    		};

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region TOGGLE CHOSEN FUNCTION

            vm.toggleChosen = function (item) {
                if (utilityService.validateParmsValue(item.item_id) !== true) return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #endregion TOGGLE CHOSEN FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                let selectedItems = [];

                angular.forEach(vm.model.itemsListCalculationAndValidations, function (item) {
                    if (angular.isUndefined(item.item_filtervalue) === true) return;
                    if (item.item_filtervalue.toLowerCase().indexOf(vm.model.setting.searchtext.toLowerCase()) === '-1') return;
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0') return;

                    selectedItems.push({
                        item_id: item.item_id,
                        item_is_selected: item.item_is_selected
                    });
                });

                checkListItemCvsService.saveObj({
                    checklistitem_keyno: vm.model.setting.checklistitem_keyno,
                    records: selectedItems
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
