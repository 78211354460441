(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('paymentPlan', {
        templateUrl: 'views/components/views/paymentPlan/paymentPlan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'paymentPlanService', 'economyService', 'translateService', function ($stateParams, $q, $timeout, stateService, utilityService, modalService, paymentPlanService, economyService, translateService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let paymentPlanKeyno = $stateParams.paymentplan_keyno;
            let timerCalculations = null;

            let translations = {
                error: '',
                ok: ''
            };

            vm.version = module.version;

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'invoicehead':
                        stateService.go(state, {
                            invoice_no: vm.model.edit.invoice_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                mobileSize: window.matchMedia('(min-width: 768px)'),
                paymentPlanKeyno: paymentPlanKeyno,
                invoiceButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'invoicehead' } }
                ],
                edit: {},
                selectListPayments: [],
                selectListTaxes: [],
                creatingInvoice: false,
                lockedSave: false,
                lockedDelete: false
            };

            vm.options = {
                amount: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            vm.style = {
                basic: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                paymentPlanService.getPaymentPlan({
                    argtype: argType,
                    argvalue: argValue,
                    paymentplan_keyno: paymentPlanKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadTaxes();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadPayments = function () {
                economyService.listPayments().then(function (list) {
                    angular.copy(list, vm.model.selectListPayments);
                });
            };

            let loadTaxes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.country_id, true) !== true) return;

                return economyService.listTaxes({ country_id: vm.model.edit.country_id }).then(function (list) {
                    angular.copy(list, vm.model.selectListTaxes);
                });
            };

            let retrieveDueDate = function () {
                if (utilityService.validateParmsValue(vm.model.edit.planned_invoicing_date, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.payment_id, true) !== true) return;

                return paymentPlanService.getDueDate({
                    planned_invoicing_date: vm.model.edit.planned_invoicing_date,
                    payment_id: vm.model.edit.payment_id
                }).then(function (response) {
                    vm.model.edit.duedate = response[0].duedate
                });
            };

            let loadCalculations = function (colname) {
                if (angular.isDefined(timerCalculations)) {
                    $timeout.cancel(timerCalculations);
                }

                var mergeObj = vm.model.edit;
                mergeObj.changed_colname = colname;

                timerCalculations = $timeout(function () {
                    paymentPlanService.calculatePaymentPlan(mergeObj).then(function (result) {
                        if (colname !== 'amount_ordval') vm.model.edit.amount_ordval = result[0].amount_ordval;
                        if (colname !== 'percentage') vm.model.edit.percentage = result[0].percentage;
                        if (colname !== 'amount_ordval_total_ex_tax') vm.model.edit.amount_ordval_total_ex_tax = result[0].amount_ordval_total_ex_tax;
                        if (colname !== 'amount_ordval_total_incl_tax') vm.model.edit.amount_ordval_total_incl_tax = result[0].amount_ordval_total_incl_tax;
                        if (colname !== 'outstanding_pst') vm.model.edit.outstanding_pst = result[0].outstanding_pst;
                        if (colname !== 'outstanding_amount_ex_tax') vm.model.edit.outstanding_amount_ex_tax = result[0].outstanding_amount_ex_tax;
                        if (colname !== 'outstanding_amount_incl_tax') vm.model.edit.outstanding_amount_incl_tax = result[0].outstanding_amount_incl_tax;
                        if (colname !== 'amount_ordval_ex_tax') vm.model.edit.amount_ordval_ex_tax = result[0].amount_ordval_ex_tax;
                    });
                }, 500);
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'amount_ordval_total_ex_tax':
                        if (vm.model.edit.amount_ordval_total_ex_tax === value) return;

                        vm.model.edit.amount_ordval_total_ex_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.amount_ordval_total_ex_tax, true) !== true) return;

                        loadCalculations('amount_ordval_total_ex_tax');
                        break;
                    case 'amount_ordval_total_incl_tax':
                        if (vm.model.edit.amount_ordval_total_incl_tax === value) return;

                        vm.model.edit.amount_ordval_total_incl_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.amount_ordval_total_incl_tax, true) !== true) return;

                        loadCalculations('amount_ordval_total_incl_tax');
                        break;
                    case 'outstanding_pst':
                        if (vm.model.edit.outstanding_pst === value) return;

                        vm.model.edit.outstanding_pst = value;

                        if (utilityService.validateParmsValue(vm.model.edit.outstanding_pst, true) !== true) return;

                        loadCalculations('outstanding_pst');
                        break;
                    case 'outstanding_amount_ex_tax':
                        if (vm.model.edit.outstanding_amount_ex_tax === value) return;

                        vm.model.edit.outstanding_amount_ex_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.outstanding_amount_ex_tax, true) !== true) return;

                        loadCalculations('outstanding_amount_ex_tax');
                        break;
                    case 'outstanding_amount_incl_tax':
                        if (vm.model.edit.outstanding_amount_incl_tax === value) return;

                        vm.model.edit.outstanding_amount_incl_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.outstanding_amount_incl_tax, true) !== true) return;

                        loadCalculations('outstanding_amount_incl_tax');
                        break;
                    case 'amount_ordval_ex_tax':
                        if (vm.model.edit.amount_ordval_ex_tax === value) return;

                        vm.model.edit.amount_ordval_ex_tax = value;

                        if (utilityService.validateParmsValue(vm.model.edit.amount_ordval_ex_tax, true) !== true) return;

                        loadCalculations('amount_ordval_ex_tax');
                        break;
                    case 'planned_invoicing_date':
                        vm.model.edit.planned_invoicing_date = value;

                        if (utilityService.validateParmsValue(vm.model.edit.planned_invoicing_date, true) !== true) return;

                        retrieveDueDate();
                        break;
                    case 'amount_ordval':
                        if (vm.model.edit.amount_ordval === value) return;

                        vm.model.edit.amount_ordval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.amount_ordval, true) !== true) return;

                        loadCalculations('amount_ordval');
                        break;
                    case 'payment_id':
                        vm.model.edit.payment_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.payment_id, true) !== true) return;

                        retrieveDueDate();
                        break;
                    case 'percentage':
                        if (vm.model.edit.percentage === value) return;

                        vm.model.edit.percentage = value;

                        if (utilityService.validateParmsValue(vm.model.edit.percentage, true) !== true) return;

                        loadCalculations('percentage');
                        break;
                    case 'tax_keyno':
                        if (vm.model.edit.tax_keyno === value) return;

                        vm.model.edit.tax_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.tax_keyno, true) !== true) return;

                        loadCalculations('tax_keyno');
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            //vm.onChanged = function (value, id) {
            //    switch (id) {
            //        case 'amount_ordval_total_ex_tax':
            //        case 'amount_ordval_total_incl_tax':
            //        case 'outstanding_pst':
            //        case 'outstanding_amount_ex_tax':
            //        case 'outstanding_amount_incl_tax':
            //        case 'amount_ordval_ex_tax':
            //        case 'amount_ordval':
            //        case 'percentage':
            //        case 'tax_keyno':
            //            if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

            //            vm.model.edit[id] = value;

            //            if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

            //            loadCalculations(id);
            //            break;
            //        case 'planned_invoicing_date':
            //        case 'payment_id':
            //            if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

            //            vm.model.edit[id] = value;

            //            if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

            //            retrieveDueDate();
            //            break;
            //        default:
            //            utilityService.setValueToVariable(value);
            //            break;
            //    }
            //};

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.creatingInvoice = true;

                paymentPlanService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingInvoice = false;
                                }
                            }]
                        });
                    } else {
                        paymentPlanService.createObj(vm.model.edit).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.creatingInvoice = false;
                                        }
                                    }]
                                });
                            } else {
                                vm.model.creatingInvoice = false;
                                loadEdit();
                            }
                        });
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                paymentPlanService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                paymentPlanService.deleteItem(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPayments();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
