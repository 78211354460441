(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custGrp', {
        templateUrl: 'views/components/views/custGrp/custGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custGrpService', 'customerEditService', 'typeaheadService', function ($stateParams, stateService, custGrpService, customerEditService, typeaheadService) {
            const vm = this;
            let custGrpId = $stateParams.custgrp_id;
			
            vm.model = {
                custGrpId: custGrpId,
                edit: {},
                selectListDiscGroupCustomers: [],
                searchingDefaultCustName: false,
                lockedSave: false
            };

            let loadEdit = function () {
                custGrpService.getCustomerGroup({ custgrp_id: custGrpId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadDiscGroupCustomers = function () {
                customerEditService.listDiscGroupCustomers().then(function (list) {
                    angular.copy(list, vm.model.selectListDiscGroupCustomers);
                });
            };

            vm.typeaheadSearchDefaultCustName = function (value) {
                vm.model.searchingDefaultCustName = true;

                return typeaheadService.searchCustomer({
                    cust_name: vm.model.edit.default_cust_name,
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'C',
                    lastselect: 'SEARCH'
                });
            };
            
            vm.onDefaultCustNameCleared = function () {
                vm.model.edit.default_cust_no = '0';
                vm.model.edit.default_cust_name = '';
            };

            vm.onDefaultCustNameSelected = function (item) {
                vm.model.edit.default_cust_no = item.item_id;
                vm.model.edit.default_cust_name = item.item_name;
            };

            vm.onDefaultCustNameChanged = function (value) {
                vm.model.edit.default_cust_name = value;

                if (!value) {
                    vm.onDefaultCustNameCleared();
                } else {
                    let customer = typeaheadService.selectListCustomers.find((item) => item.item_name === vm.model.edit.default_cust_name);

                    if (customer) {
                        vm.onDefaultCustNameSelected(customer);
                    }
                }
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                custGrpService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
                loadDiscGroupCustomers();
            };
        }]
    });
})();
