(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectLabourPu', {
        templateUrl: 'views/components/views/selectLabourPu/selectLabourPu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectLabourPuService', function ($stateParams, selectLabourPuService) {
            var vm = this;
            
            selectLabourPuService.loadP2SelectLabourPuList($stateParams.webpagename).then(function () {
                vm.p2_select_labour_pu_list = selectLabourPuService.p2_select_labour_pu_list;
            });
            
            vm.saveToPreP2SelectLabourPu = function (item) {
                if ($stateParams.webpagename == 'oeedata') {
                    selectLabourPuService.savingToPreP2SelectLabourPuSave = true;
                    selectLabourPuService.saveToPreP2SelectLabourPu(item);
                } else {
                    selectLabourPuService.saveToPreP2SelectLabourPu(item);
                }
            };
        }]
    });
})();