(function () {
    'use strict';

    class ImageComponent {
        error = false;
        model = '';

        id = {
            img: crypto.randomUUID(),
            buttons: crypto.randomUUID(),
            imgContainer: crypto.randomUUID(),
        };

        style = {
            container: {},
            base: {},
            label: {},
            img: {},
            popup: {},
            placeholder: {},
            placeholderIcon: {
                color: '#666',
            },
            placeholderText: {
                color: '#666',
            },
            buttons: {},
            button: {}
        };

        class = {
            base: '',
            label: '',
            img: '',
        };

        translations = {
            ttLabel: '',
        };

        #onDestroy = [];

        #elements = {
            popover: null,
            buttons: null,
            imageContainer: null,
            image: null,
        };

        #setCssPositionBound;

        static $inject = ['$sce', '$document', 'translateService', 'layoutService', 'utilityService', 'ttDirectivesService'];
        constructor($sce, $document, translateService, layoutService, utilityService, ttDirectivesService) {
            Object.assign(this, { $sce, $document, translateService, layoutService, utilityService, ttDirectivesService });
            this.#setCssPositionBound = this.#setCssPosition.bind(this);
        }

        canShowImage() {
            return (this.ttModel !== undefined && this.ttModel !== null) && this.error === false;
        }

        onButtonClick(button) {
            this.ttDirectivesService.onButtonClick({ button: button, controller: this });
        };

        onError() {
            this.error = true;
        }

        onButtonKeyPressed(event) {
            if (event.key === 'Tab' && this.#isPopoverOpen() && !this.#shouldShowButtonsOnMainImage() && ((event.shiftKey && event.target === this.#elements.buttons.firstElementChild) || (!event.shiftKey && event.target === this.#elements.buttons.lastElementChild))) {
                event.preventDefault();

                if (!this.#elements.imageContainer) {
                    this.#elements.imageContainer = document.getElementById(this.id.imgContainer);
                }

                this.#elements.imageContainer.focus();
                this.#hidePopover();
            }
        }

        onButtonMouseenter(button) {
            button.tooltipOpen = true;
        }

        onButtonMouseleave(event, button) {
            if (!this.#isCoordinatedInImageScope(event.clientX, event.clientY)) {
                this.#addButtonsToImageContainer();
                this.#hidePopover();
            }

            button.tooltipOpen = false;
        }

        onButtonFocus(button) {
            button.tooltipOpen = true;
        }

        onButtonBlur(button) {
            button.tooltipOpen = false;
        }

        onImageBlur(event) {
            if (!event.relatedTarget || event.relatedTarget.closest('.tt-image-popover')) return;

            this.#hidePopover();
            this.#addButtonsToImageContainer();
        }

        onKeyPressed(event) {
            if (event.key === 'Tab' && this.#isPopoverOpen() && !this.#shouldShowButtonsOnMainImage() && !this.#shouldShowButtonsOnMainImage() && this.ttButtons) {
                event.preventDefault();

                this.#elements.buttons.firstElementChild.focus();
                return;
            }

            if (event?.key === 'Tab' || event?.key === 'Shift' || event.target.closest('.tt-image__btn')) return;

            event.preventDefault();

            if (event?.key === 'Enter') {
                this.#clickHandler(event, this.ttClick);
            } else if (event?.key === ' ' && !this.#isPopoverOpen()) {
                this.#openPopover();

                if (!this.#shouldShowButtonsOnMainImage()) {
                    this.#addButtonsToPopover();
                }
            } else if (event?.key === ' ' && this.#isPopoverOpen()) {
                if (this.#shouldShowButtonsOnMainImage()) {
                    this.#addButtonsToImageContainer();
                }

                this.#hidePopover();
            }
        }

        onClick(event) {
            if (event.target.closest('.tt-image__btns')) return;

            if (this.canShowImage() && event?.originalEvent?.pointerType === 'mouse') {
                this.#clickHandler(event, this.ttClick);
            } else if (this.canShowImage() && event.originalEvent.pointerType !== 'mouse' && !this.#isPopoverOpen()) {
                this.#openPopover();
                this.#addButtonsToPopover();
            } else if (this.canShowImage() && event.originalEvent.pointerType !== 'mouse' && this.#isPopoverOpen()) {
                this.#hidePopover();
                this.#addButtonsToImageContainer();
            } else if (!this.canShowImage()) {
                this.#clickHandler(event, this.ttPlaceholderClick);
            }
        };

        onMouseOver(event) {
            if (!event.originalEvent.sourceCapabilities.firesTouchEvents) {
                this.#openPopover();

                if (this.#shouldShowButtonsOnMainImage() && !this.#areButtonsOnImage()) {
                    this.#addButtonsToImageContainer();
                } else if (!this.#shouldShowButtonsOnMainImage()) {
                    this.#addButtonsToPopover();
                }
            }
        }

        #areButtonsOnImage() {
            if (!this.#elements.buttons) {
                this.#elements.buttons = document.getElementById(this.id.buttons);
            }

            return this.#elements.buttons.parentElement.id === this.id.imgContainer
        }

        onMouseOut(event) {
            if (!event.originalEvent.sourceCapabilities.firesTouchEvents) {
                this.#hidePopover();
            }

            if (!this.#isPopoverOpen()) this.#addButtonsToImageContainer();
        }

        onPopoverClick(event) {
            if (this.canShowImage() && this.ttClick && angular.isFunction(this.ttClick) && event.originalEvent.pointerType !== 'mouse') {
                const b = this.ttClick();

                if (angular.isFunction(b)) {
                    b(e);
                }
            }
        }

        onPopoverMouseOver(event) {
            this.#elements.popover.css('display', 'block');
            this.#addButtonsToPopover();
        };

        onPopoverMouseOut(event) {
            if (this.#isCoordinatedInImageScope(event.clientX, event.clientY)) return;
            if (event.toElement.closest('.tt-image-popover')) return;

            this.#addButtonsToImageContainer();
            this.#hidePopover();
        };

        #clickHandler(event, callback) {
            if (callback && angular.isFunction(callback)) {

                const b = callback();

                if (angular.isFunction(b)) {
                    b(event);
                }
            }
        }

        #openPopover() {
            if (!this.#elements.popover) {
                this.#elements.popover = angular.element(`<div class="tt-image-popover"><img src="${this.ttModel}" class="tt-image-popover" /></div>`);
                this.#elements.popover.find('img').on('mouseover', this.onPopoverMouseOver.bind(this));
                this.#elements.popover.find('img').on('mouseleave', this.onPopoverMouseOut.bind(this));
                this.#elements.popover.find('img').on('click', this.onPopoverClick.bind(this));
                this.$document.find('body').prepend(this.#elements.popover);

                this.#onDestroy.push(() => this.#elements.popover.remove());
            }

            this.#setCssPosition();

            this.#elements.popover.children()[0].src = this.ttModel;
            this.#elements.popover.css('display', 'block');
        }

        #addButtonsToPopover() {
            if (this.#elements.popover) {
                if (!this.#elements.buttons) {
                    this.#elements.buttons = document.getElementById(this.id.buttons);
                    this.#elements.buttons.style.display = 'block';
                }

                this.style.buttons.display = 'flex';
                this.#elements.popover.append(this.#elements.buttons);
            }
        }

        #addButtonsToImageContainer() {
            if (!this.#elements.imageContainer) {
                this.#elements.imageContainer = document.getElementById(this.id.imgContainer);
            }

            if (!this.#elements.buttons) {
                this.#elements.buttons = document.getElementById(this.id.buttons);
            }

            this.#elements.imageContainer.prepend(this.#elements.buttons);
        }

        #hidePopover() {
            if (!this.#shouldShowButtonsOnMainImage()) {
                this.style.buttons.display = 'none';
                this.#addButtonsToImageContainer();
            }
            if (this.#elements.popover) {
                this.#elements.popover.css('display', 'none');
            }
        }

        #isPopoverOpen() {
            return this.#elements.popover && this.#elements.popover?.css('display') === 'block';
        }

        #isCoordinatedInImageScope(x, y) {
            let imageRect = this.#elements.image.getBoundingClientRect();
            let popoverRect = this.#elements.popover[0].children[0].getBoundingClientRect();

            let inImageScope = x >= imageRect.left && x <= imageRect.right && y >= imageRect.top && y <= imageRect.bottom;
            let inPopoverScope = x >= popoverRect.left && x <= popoverRect.right && y >= popoverRect.top && y <= popoverRect.bottom;

            return inImageScope || inPopoverScope;
        }

        #shouldShowButtonsOnMainImage() {
            let maxWidthForButtons = 160;
            if (!this.#elements.image) {
                this.#elements.image = document.getElementById(this.id.img);
            }

            if (!this.#elements.image) {
                return false;
            }

            return this.#elements.image.width >= maxWidthForButtons;
        }

        #setCssPosition() {
            if (!this.ttModel) return;
            let element = document.getElementById(this.id.img);
            let cssPosition = this.#getCssPosition(element);

            for (const cssProperty in cssPosition) {
                this.#elements.popover?.css(cssProperty, cssPosition[cssProperty]);
            }

            if (this.ttButtons) {
                if (!this.#elements.buttons) {
                    this.#elements.buttons = document.getElementById(this.id.buttons);
                }

                this.#elements.buttons.style.left = cssPosition['left'] + this.#elements.popover?.width - 5;
            }
        }

        #getCssPosition = function (element) {
            const rect = element.getBoundingClientRect();

            let translateX = -rect.x.toFixed() / window.innerWidth * 100 + '%';
            let translateY = 0;
            let bottomPosition = rect.bottom.toFixed();
            let leftPosition = rect.left.toFixed();

            if (window.innerHeight - rect.bottom <= this.#elements.popover?.height() ?? 400) {
                translateY = -this.#elements.popover?.height() - parseInt(rect.height) + 'px';
            }

            return {
                transform: `translate(${translateX}, ${translateY})`,
                left: `${leftPosition}px`,
                top: `${bottomPosition}px`
            }
        }

        #setClasses(labelAlwaysOnTop) {
            this.class.base = this.ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: this.ttLabelView });
        }

        #setStyle(ttStyle = this.ttStyle) {
            angular.copy(this.ttDirectivesService.setStyle({ style: this.style, ttStyle: ttStyle, textAlign: this.ttTextAlign, mainElement: 'img' }), this.style);
        }

        #setLabel() {
            if (this.ttLabel) {
                if (this.ttTranslate === 'false') {
                    this.translations.ttLabel = this.ttLabel;
                } else {
                    this.translateService.translate(this.ttLabel).then((translation) => this.translations.ttLabel = translation);
                }
            }
        }

        #onLayoutChanged(info) {
            if (info) {
                this.style.label.fontSize = info.fontSizes.textSizeS;
                this.style.placeholderText.fontSize = info.fontSizes.textSize;
                this.style.button.fontSize = info.fontSizes.textSize;
                this.style.button.height = info.height;

                this.#setStyle(this.ttStyle);
                this.#setClasses(info.labelAlwaysOnTop || this.style.labelAlwaysOnTop);
            }
        }

        // #region ANGULAR LIFECYCLE

        $onInit() {
            this.#setLabel();
            this.#setStyle(this.ttStyle);
            this.layoutService.onLayoutChanged(this.#onDestroy, this.#onLayoutChanged.bind(this));
            document.addEventListener('scroll', this.#setCssPositionBound);
        }

        $onChanges(changes) {
            if (changes.ttLabel?.currentValue && this.utilityService.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel?.currentValue !== changes.ttLabel?.previousValue) {
                this.#setLabel();
            }

            if (changes.ttModel?.currentValue || changes.ttModel?.currentValue === '') {
                this.error = false;
                this.model = this.$sce.trustAsResourceUrl(changes.ttModel.currentValue);

                setTimeout(() => {
                    if (this.#shouldShowButtonsOnMainImage()) {
                        this.style.buttons.display = 'flex';
                    } else {
                        this.style.buttons.display = 'none';
                    }
                }, 250);
            }

            if (this.ttClick && angular.isFunction(this.ttClick)) {
                this.style.img.cursor = 'pointer';
            } else {
                this.style.img.cursor = 'initial';
            }

            if (this.ttPlaceholderClick && angular.isFunction(this.ttPlaceholderClick)) {
                this.style.placeholder.cursor = 'pointer';
            } else {
                this.style.placeholder.cursor = 'initial';
            }

            if (changes.ttStyle?.currentValue && angular.isObject(changes.ttStyle.currentValue)) {
                this.#setStyle(changes.ttStyle.currentValue);
            }
        }

        $onDestroy() {
            document.removeEventListener('scroll', this.#setCssPositionBound);
            this.ttDirectivesService.onDestroy(this.#onDestroy);
        }

        // #endregion ANGULAR LIFECYCLE
    }

    let module = angular.module('imApp');

    module.component('ttImage', {
        templateUrl: 'views/components/directives/ttImage/ttImage.template.html?v=' + module.version,
        bindings: {
            ttLabel: '@',               // the primary label of the message.
            ttLabelView: '@?',          // the position of the primary label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttModel: '<',               // the content of the message.
            ttTitle: '@',               // title of the image, is displayed when the image is hovered.
            ttAltText: '@',             // alternative text required on all img.
            ttClick: '&?',              // optional click event for the image.
            ttPlaceholderClick: '&?',   // optional click event for the placeholder.
            ttStyle: '<?',              // custom styling to apply to the message.
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttButtons: '<?'             // list of `ImageButton` buttons to display in the top right corner of the image.
        },
        controllerAs: 'vm',
        controller: ImageComponent
    });

    /**
     * @typedef {Object} ImageButton represents a button displayed on a image.
     * @example `{ id: 'copy', icon: 'far fa-copy', label: 'Copy image-url', onClick: () => console.log('Copy url') }`
     * @property {string} id the id of the button.
     * @property {string} icon the icon classes for the icon to display on the image button.
     * @property {string} label a concise description for the action of the button, used for aria-label and tooltip.
     * @property {() => any} onClick callback function to call when the image button is clicked. 
     */
})();
