(function () {
    'use strict';

    let module = angular.module('imApp');

    module.factory("modalDynamicViewFactory", ['$q', '$uibModal', function ($q, $uibModal) {
        function modalDynamicView(options) {
            options = options || {};

            this.model = {
                type: options.type ? 'type-' + options.type : '',
                title: options.title || '',
                cancel: options.cancel || false,
                size: options.size || '',
                url: options.url || 'dynamicViewModal',
                data: options.data || [],
                footer: options.footer || false,
                buttons: options.buttons || [],
                onClose: options.onClose || undefined
            };

            this.instance = null;
        };

        modalDynamicView.prototype.show = function () {
            var deferred = $q.defer();

            var vm = this;

            var tempUrl = 'views/modal/' + vm.model.url + '.html?v=' + module.version;

            this.instance = $uibModal.open({
                backdrop: true,
                animation: true,
                keyboard: false,
                size: vm.model.size,
                //templateUrl: 'views/modal/popupTableModal.html',
                templateUrl: tempUrl,
                controller: ['$scope', '$uibModalInstance', 'translateService', function ($scope, $uibModalInstance, translateService) {
                    $scope.model = vm.model;
                    $scope.model.filterplaceholder = 'filterplaceholder';

                    translateService.translate('filterplaceholder').then(function (response) {
                        $scope.model.filterplaceholder = response;
                    });

                    $scope.cancel = function (reason) {
                        $uibModalInstance.dismiss();

                        deferred.reject(reason);
                    };

                    $scope.close = function () {
                        $uibModalInstance.close();

                        deferred.resolve(vm.model.data);
                    };

                    $scope.isListChecked = function (data) {
                        var hasUnchecked = false;

                        angular.forEach(data.list, function (row) {
                            if (hasUnchecked) return;
                            angular.forEach(row, function (value, key) {
                                if (hasUnchecked || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                                var val = value.toLowerCase();
                                var fltr = data.filter.toLowerCase();

                                if (val.includes(fltr)) {
                                    if (angular.isUndefined(row.is_selected) || row.is_selected === false) {
                                        hasUnchecked = true;
                                    }
                                }
                            });
                        });

                        data.hasUnchecked = hasUnchecked;

                        return !hasUnchecked;
                    };

                    $scope.goCheckToggleAll = function (e, data) {
                        angular.forEach(data.list, function (row) {
                            if (data.filter !== '') {
                                var continueLoop = true;
                                angular.forEach(row, function (value, key) {
                                    if (!continueLoop || typeof value !== 'string' || key === '$$hashKey' && key === 'is_selected') return;
                                    var val = value.toLowerCase();
                                    var fltr = data.filter.toLowerCase();

                                    if (val.includes(fltr)) {
                                        row.is_selected = data.hasUnchecked;
                                        continueLoop = false;
                                    }
                                });
                            } else {
                                row.is_selected = data.hasUnchecked;
                            }
                        });
                    };

                    $scope.goDown = function (e, item, index) {
                        //e.preventDefault();
                        e.stopPropagation();
                        item.value--;
                        item.field = item.value.toString();
                    };

                    $scope.goUp = function (e, item, index) {
                        e.stopPropagation();
                        //e.preventDefault();
                        item.value++;
                        item.field = item.value.toString();
                    };

                    $scope.goCheckToggle = function (e, item, index) {
                        item.is_selected = angular.isUndefined(item.is_selected) ? true : !item.is_selected;
                    };

                    $scope.goListFunc = function (e, item, index) {
                        console.log('listFunc clicked');
                    };

                    //$scope.onChange = function (item) {
                    //    console.log('change');
                    //    console.dir(item);
                    //};

                    $scope.buttonClicked = function (index) {
                        if (angular.isFunction($scope.model.buttons[index].action)) {
                            $scope.model.buttons[index].action($scope);
                        }
                        $scope.close();
                        deferred.resolve();
                    };

                    $scope.$on('modal.closing', function (event, reason) {
                        switch (reason) {
                            case "backdrop click":
                            case "escape key press":
                            case "cancel":
                                event.preventDefault();
                                if ($scope.model.cancel === 'all') {
                                    $scope.cancel(reason);
                                } else {
                                    $scope.close();
                                }
                                break;
                        }
                    });
                }]
            });

            return deferred.promise;
        };

        modalDynamicView.prototype.hide = function () {
            if (angular.isUndefined(this.instance)) return;
            if (this.instance === null) return;

            this.instance.close();

            this.instance = null;
        };

        modalDynamicView.$create = function (options) {
            return new modalDynamicView(options);
        };

        return modalDynamicView;
    }]);
})();
