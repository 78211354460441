(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("docArticleTopUserGroupsService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadDocArticleTopUserGroupsList: function (docarticle_keyno_top) {
				return p2DataTaskService.call(1382, {
					docarticle_keyno_top: docarticle_keyno_top
				});
			}
		};

		return service;
	}]);
})();