(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('furnitureFabricsMenu', {
        templateUrl: 'views/components/views/furnitureFabricsMenu/furnitureFabricsMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'furnitureFabricsMenuService', function ($stateParams, stateService, furnitureFabricsMenuService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                furnitureFabricsId: $stateParams.furniturefabrics_id,
                menu: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            furnitureFabricsMenuService.getFurnitureFabricsMenu({furniturefabrics_id: vm.model.furnitureFabricsId}).then(function (data) {
            	angular.copy(data[0], vm.model.menu);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'furniturefabrics':
                    case 'furniturefabricscolors':
                        go({ furniturefabrics_id: vm.model.menu.furniturefabrics_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
        }]
    });
})();
