(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('factoringBunt', {
        templateUrl: 'views/components/views/factoringBunt/factoringBunt.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'base64', 'factoringBuntComponentService', function ($stateParams, stateService, translateService, modalService, base64, factoringBuntComponentService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let factoringBuntKeyno = $stateParams.factoringbunt_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedSave: false,
                creatingFile: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                factoringBuntComponentService.getSetting({ factoringbunt_keyno: factoringBuntKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                factoringBuntComponentService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.createFile = function () {
                vm.model.creatingFile = true;
                
                factoringBuntComponentService.exportFile(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingFile = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.creatingFile = false;

                        if (response[0].filecontent > '' || response[0].filename > '') {
                            var elem = document.getElementById('formFactoringBuntLink');
                            var text = base64.urldecode(response[0].filecontent);

                            var textData = new Blob([text], { type: 'text/txt' });
                            var textUrl = URL.createObjectURL(textData);

                            elem.setAttribute('href', textUrl);
                            elem.setAttribute('download', response[0].filename);
                            elem.click();
                        }

                        if (response[0].filecontent2 > '' || response[0].filename2 > '') {
                            var elem = document.getElementById('formFactoringBuntLink');
                            var text = base64.urldecode(response[0].filecontent2);

                            var textData = new Blob([text], { type: 'text/txt' });
                            var textUrl = URL.createObjectURL(textData);

                            elem.setAttribute('href', textUrl);
                            elem.setAttribute('download', response[0].filename2);
                            elem.click();
                        }
                    }
                });
            };

            // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
