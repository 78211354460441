(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("biReportsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            list: function () {
                return p2DataTaskService.call(2660, {})
            }
        }

        return service;
    }]);
})();
