(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('timeSheetsStatus', {
        templateUrl: 'views/components/views/timeSheetsStatus/timeSheetsStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'timeSheetsStatusService', 'rememberService', 'watcherFactory', function ($q, stateService, utilityService, timeSheetsStatusService, rememberService, watcherFactory) {
            
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            
    		var watcher = watcherFactory.$create(vm);

            var reload = false;

            let variableNames = {
                date_fom: '',
                date_tom: '',
                dateselector_index: ''
            };

            vm.model = {
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.filtervalue = '' }
                ],
                setting: {},
                itemsListStatuses: []
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                timeSheetsStatusService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStatuses()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadStatuses = function () {
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                vm.model.itemsListStatuses = [];

                return timeSheetsStatusService.listStatuses(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListStatuses);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');
                if (vm.model.setting.date_tom) rememberFunc('date_tom');
                if (vm.model.setting.dateselector_index) rememberFunc('dateselector_index');
                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) loadStatuses();
            };

            // #endregion FIELD DATE RANGE CHANGE FUNCTION

            // ## WATCH FUNCTIONS ##
            
            // Reload/update funksjon lagt til 17.03.2017
            reload = timeSheetsStatusService.reloadP2TimeSheetStatusList();
            
            watcher.$watch(function () {
                return timeSheetsStatusService.reloaded;
            }, function (newValue) {
                if (newValue == false) return;
                
                if (vm.model.setting.timer_on === '1') {
                    reload = timeSheetsStatusService.reloadP2TimeSheetStatusList();
                    loadStatuses();
                }
            });

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();