(function () {
    'use strict';

    angular.module("imApp").factory("acReminderProposalService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3088,
                    parameters: parms || {}
                });
            },
            updateGrid: function (edit) {
                return $ihttp.post({
                    method: 3090,
                    parameters: edit
                });
            },
            approveGridSelected: function (grid) {
                return $ihttp.post({
                    method: 3100,
                    parameters: grid
                });
            }
        };

        return service;
    }]);
})();