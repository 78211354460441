(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("laboursService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadLaboursSettings: function () {
                return p2DataTaskService.call(1168, {});
            }
        };

        return service;
    }]);
})();
