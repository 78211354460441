(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactCalcAdjustDisc', {
        templateUrl: 'views/components/views/custactCalcAdjustDisc/custactCalcAdjustDisc.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'custactCalcAdjustDiscService', 'rememberService', function ($stateParams, stateService, utilityService, translateService, modalService, custactCalcAdjustDiscService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;

            let variableNames = {
                cost_pst_add: ''
            };

            let timer = {
                cost_pst_add: null
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedExecute: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'cost_pst_add':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON EXECUTE PROCEDURE FUNCTION CALL

            vm.executeChanges = function () {
                vm.model.lockedExecute = true;

                custactCalcAdjustDiscService.executeObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedExecute = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedExecute = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON EXECUTE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'cost_pst_add':
                        if (utilityService.validateWatchValue(value, vm.model.edit.cost_pst_add) !== true) return;

                        vm.model.edit.cost_pst_add = value;

                        if (utilityService.validateParmsValue(vm.model.edit.cost_pst_add, true) !== true) return;
                        if (timer[id]) clearTimeout(timer[id]);

                        timer[id] = setTimeout(() => {
                            rememberFunc('cost_pst_add');
                        }, 350);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            vm.$onInit = function () {
                custactCalcAdjustDiscService.getEdit({ custact_no: custactNo }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            }
        }]
    });
})();
