(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockPlanning', {
        templateUrl: 'views/components/views/stockPlanning/stockPlanning.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'utilityService', 'stockPlanningService', 'logisticService', 'rememberService', 'ieScreenBlockService', 'modalService', function ($stateParams, $timeout, $q, stateService, utilityService, stockPlanningService, logisticService, rememberService, ieScreenBlockService, modalService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            var timer = null;
            
            var variableNames = {
                portal_user_keyno_purchaser: ''
            };

            vm.model = {
                selectedCount: 0,
                stockplaces: [],
                show_setting: 0,
                stockplace_id: '',
                businesscos: [],
                businessco_no: 0,
                portal_user_keyno_purchaser: 0,
                supplier_no: '0',
                futuremanko_only: 0,
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                products: [],
                productsRaw: [],
                checkedCount: 0,
                parmCounter: 0,
                checkState: 0,
                prod_id: '',
                stock_date: moment(new Date()).format('YYYY-MM-DD'),
                clickDec: false,
                isLoaded: false
            };

            vm.selectListPortalUsers = [];
            vm.selectListSuppliers = [];

            function gridButtons() {
                return [
                    { name: 'customCreatePurchase', text: '', func: function () { vm.createPurchase() }, icon: 'save', disabled: function () { return disable(true); } }
                ];
            }

			// #endregion VARIABLES & DEFINITIONS

            // #region GRID

            vm.gridOld = {
                setup: {
                    remember: 'stockplanning.list.grid',
                    columns: [],
                    format: [],
                    editableCols: [],
                    filter: { enabled: false, mode: false },
                    toolbar: { // alternative -> toolbar: false,
                        hidden: false,
                        fullToolbar: false,
                        tBarSave: false,
                        emptyToolbar: true,
                        hideExports: true,
                        buttons: gridButtons()
                    },
                    pager: { //alternative -> pager: false,
                        pageSizes: false,
                        pageSize: 1000,
                    },
                    autosave: true,
                    localData: true,
                    translate: true,
                    selectable: false,
                    behaviour: {
                        //selectable: "multiple",
                        rowClick: false
                    }
                },
                data: [],
                dataRaw: [],
                saveData: [],
                grid: null,
                gridfunc: null
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'stockplanning.list.grid',			// Id used to retrieve user configs for the grid
                    loadData: {                                         // Method id of the get data procedure
                        method: 2001,
                        parameters: {
                            prod_id: '', // vm.model.prod_id,
                            supplier_no: vm.model.supplier_no,
                            stock_date: vm.model.stock_date.replace(':::', '').trim().slice(0, 10),
                            future_manko_only: vm.model.futuremanko_only,
                            stockplace_id: vm.model.stockplace_id,
                            businessco_no: vm.model.businessco_no,
                            portal_user_keyno_purchaser: vm.model.portal_user_keyno_purchaser
                        }
                    },
                    addRow: {                                           //
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        //
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: false,                               // Adds a predefined btn for exporting grid to pdf
                        excelExport: false,                             // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        //buttons: gridButtons()
                        buttons: [
                            //{ name: 'customCreatePurchase', text: '', func: function () { vm.createPurchase() }, icon: 'save', disabled: function () { return disable(true); } }
                            { name: 'customCreatePurchase', text: '', func: function () { vm.createPurchase() }, icon: 'save', disabled: function () { return disable(false); } }
                        ]
                    },
                    fixedHeader: false,                                 // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } },
                            //{ name: 'test2', text: '', func: function (data) { testItems(data); }, icon: 'fas-save', disabled: function (data) { return test2Hidden(data); } }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: false,                                   // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

			// #endregion GRID

			// #region OTHER

			var optionfunc = function (data) {
				//console.log('optionfunc');
				if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
					//console.log('optionfunc - CellClickHandler');
					//console.dir(data);
				}

				if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
					//console.log('optionfunc - OnCellClose');
					//console.dir(data);
				}

				if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
					//console.log('optionfunc - LookupCellEditor');
					//console.dir(data);
				}
			};

            vm.loadProducts = loadProducts;
            vm.savePageSetting = savePageSetting;
            vm.gridOptionFunc = gridOptionFunc;
            vm.purchaseLines = purchaseLines;
            vm.customFilter = customFilter;
            vm.createPurchase = createPurchase;
            vm.selectedCount = selectedCount;
            vm.handleStockPlaceChange = handleStockPlaceChange;
            vm.handleBusinesscoChange = handleBusinesscoChange;
            vm.handleSupplierChange = handleSupplierChange;
            vm.handlePurchaserChange = handlePurchaserChange;
            vm.goTo = goTo;

            //loadPageSettings();

			// #endregion OTHER

            // #region FUNCTIONS

            let disable = function (state) {
                return state;
            };

            function prepareGrid() {
                let deferred = $q.defer();

                let promises = [];

                stockPlanningService.loadPageSettings().then(function (data) {
                    vm.model.stockplaces = data.stockplaces;
                    vm.model.businesscos = data.businesscos;
                    vm.model.stockplace_id = data.stockplace_id;
                    vm.model.businessco_no = data.businessco_no;
                    vm.model.supplier_no = data.supplier_no;
                    vm.model.portal_user_keyno_purchaser = data.portal_user_keyno_purchaser;
                    vm.model.show_setting = data.show_setting;

                    //if (data.filter == 0) {
                    //    vm.gridOld.setup.filter = { enabled: false, mode: false };
                    //} else {
                    //    vm.gridOld.setup.filter = { enabled: true, mode: true };
                    //}

                    vm.selectListPortalUsers = [];
                    vm.selectListSuppliers = [];

                    let listPortalUserPurchasesParms = {
                        businessco_no: vm.model.businessco_no,
                        add_all: '1'
                    };

                    let listSuppliersParms = {
                        portal_user_keyno_purchaser: vm.model.portal_user_keyno_purchaser,
                        businessco_no: vm.model.businessco_no,
                        add_all: '0'
                    };

                    promises.push(logisticService.listPortalUserPurchases(listPortalUserPurchasesParms));
                    promises.push(logisticService.listSuppliers(listSuppliersParms));

                    $q.all(promises).then(function (response) {
                        angular.copy(response[0], vm.selectListPortalUsers);
                        angular.copy(response[1], vm.selectListSuppliers);

                        deferred.resolve();
                    }, function (error) {
                        console.log('error! error! error! error! error!');

                        if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                            console.log(error.data.message);

                            if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                                console.log(error.data.data.message);
                            }
                        } else {
                            if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                                console.log(error.data.data.message);
                            }
                        }

                        console.dir(error);

                        deferred.reject(error);
                    });
                });

                return deferred.promise;
            }

            function handleSupplierChange(value) {
                console.log('handleSupplierChange --- value: ' + value);
                if (value === null) return;
                console.log('setter verdi til supplier_no = value');
                vm.model.supplier_no = value;
                vm.savePageSetting('stockplanning.supplier_no', value);
                loadQueue();
            }

            function handleStockPlaceChange(value) {
                vm.model.stockplace_id = value;
                vm.savePageSetting('stockplanning.stockplace_id', value);
                loadQueue();
            }

            function handleBusinesscoChange(value) {
                vm.model.businessco_no = value;
                vm.savePageSetting('stockplanning.businessco_no', value);
                loadPortalUserPurchases();
                loadSuppliers();
                loadQueue();
            }

            function handlePurchaserChange(value) {
                vm.model.portal_user_keyno_purchaser = value;

                rememberFunc('portal_user_keyno_purchaser');
                console.log('Setter verdien 0 til supplier_no');
                vm.model.supplier_no = '0';
                console.log('supplier_no: ' + vm.model.supplier_no);
                loadSuppliers();
                loadQueue();
            }

            function customFilter(){
                console.log('Custom Filter');
				var m = 0;
				if (vm.gridOld.setup.filter.mode == true){
					vm.gridOld.setup.filter.mode = false;
					m = 0;
				} else {
					vm.gridOld.setup.filter.mode = true;
					m = 1;
				}				
				vm.savePageSetting('stockplanning.list.grid.filter', m, function(){
					stateService.go('stockplanning',{},{ reload: true, inherit: false });
				});
			}

            function showMessage(title, message, type) {
                return modalService.show({
                    type: type || 'danger',
                    title: title || 'default_error',
                    message: message || 'default_error_message',
                    buttons: [{
                        label: 'ok',
                        cssClass: 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                        }
                    }]
                });
            }

            function loadPageSettings() {
                stockPlanningService.loadPageSettings().then(function (data) {
                    vm.model.stockplaces = data.stockplaces;
                    vm.model.businesscos = data.businesscos;
                    
                    vm.model.stockplace_id = data.stockplace_id;
                    vm.model.businessco_no = data.businessco_no;
                    console.log('loadPageSettings -- data.supplier_no: ' + data.supplier_no);
                    vm.model.supplier_no = data.supplier_no;
                    console.log('loadPageSettings -- vm.model.supplier_no: ' + vm.model.supplier_no);
                    vm.model.portal_user_keyno_purchaser = data.portal_user_keyno_purchaser;
                    vm.model.show_setting = data.show_setting;
                    
                    if (data.filter == 0) {
                        vm.gridOld.setup.filter = { enabled: false, mode: false };
                    } else {
                        vm.gridOld.setup.filter = { enabled: true, mode: true };
                    }

                    console.log(vm.model);
                    console.log('vm.model.businessco_no: ' + vm.model.businessco_no);

                    loadPortalUserPurchases();
                    loadSuppliers();

                    $timeout(function(){
                        console.log('Loading products')
                        loadProducts();
                    },300);

                    vm.model.isLoaded = true;
                });
            }

            var loadSuppliers = function () {
                //if (utilityService.validateParmsValue(vm.model.portal_user_keyno_purchaser) !== true) return;
                //if (utilityService.validateParmsValue(vm.model.businessco_no) !== true) return;

                var parms = {
                    portal_user_keyno_purchaser: vm.model.portal_user_keyno_purchaser,
                    businessco_no: vm.model.businessco_no,
                    add_all: '0'
                };

                vm.selectListSuppliers = [];

                logisticService.listSuppliers(parms).then(function (data) {
                    angular.copy(data, vm.selectListSuppliers);
                });
            };

            var loadPortalUserPurchases = function () {
                //if (utilityService.validateParmsValue(vm.model.businessco_no) !== true) return;

                var parms = {
                    businessco_no: vm.model.businessco_no,
                    add_all: '1'
                };

                logisticService.listPortalUserPurchases(parms).then(function (data) {
                    console.log('HIT');
                    angular.copy(data, vm.selectListPortalUsers);
                });
            };

            function loadProducts() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Laster informasjon');

                var aDate = vm.model.stock_date.replace(':::', '').trim();

                if (aDate.length > 10) {
                    aDate = aDate.slice(0, 10);
                }

                vm.gridOld.saveData = [];
                vm.gridOld.data = [];
                vm.gridOld.dataRaw = [];

                if (angular.isDefined(vm.gridOld.grid) && vm.gridOld.grid !== null) {
                    var options = vm.gridOld.grid.getOptions();
                    options.dataSource.data = [];
                    vm.gridOld.grid.setOptions(options);
                }

                stockPlanningService
                    .loadProducts({
                        prod_id: '', // vm.model.prod_id,
                        supplier_no: vm.model.supplier_no,
                        stock_date: aDate,
                        future_manko_only: vm.model.futuremanko_only,
                        stockplace_id: vm.model.stockplace_id,
                        businessco_no: vm.model.businessco_no,
                        portal_user_keyno_purchaser: vm.model.portal_user_keyno_purchaser
                    })
                    .then(function (data) {
                        if (data.length > 3) {
                            angular.copy(data[3].editable_columns, vm.gridOld.setup.editableCols);
                        }

                        if (data[2].rows.length > 0) {
                            if (angular.isDefined(vm.gridOld.grid) && vm.gridOld.grid !== null) {
                                vm.gridOld.setup.columns = [];
                                vm.gridOld.setup.format = [];
                                angular.copy(data[0].columns, vm.gridOld.setup.columns);
                                angular.copy(data[1].format, vm.gridOld.setup.format);
                                vm.gridOld.data = [];
                                vm.gridOld.dataRaw = [];
                                vm.gridOld.data.push(data[2].rows[0]);

                                $timeout(function () {
                                    vm.gridOld.setup.columns = [];
                                    vm.gridOld.setup.format = [];
                                    angular.copy(data[0].columns, vm.gridOld.setup.columns);
                                    angular.copy(data[1].format, vm.gridOld.setup.format);
                                    vm.gridOld.data = [];
                                    vm.gridOld.dataRaw = [];
                                    angular.copy(data[2].rows, vm.gridOld.dataRaw);
                                    angular.copy(data[2].rows, vm.gridOld.data);

                                    var options = vm.gridOld.grid.getOptions();
                                    options.dataSource.data = [];
                                    angular.copy(data[2].rows, options.dataSource.data);
                                    vm.gridOld.grid.setOptions(options);

                                    vm.model.isLoaded = true;
                                }, 300);

                            } else {
                                vm.gridOld.data = [];
                                vm.gridOld.dataRaw = [];
                                vm.gridOld.setup.columns = [];
                                vm.gridOld.setup.format = [];
                                angular.copy(data[0].columns, vm.gridOld.setup.columns);
                                angular.copy(data[1].format, vm.gridOld.setup.format);
                                angular.copy(data[2].rows, vm.gridOld.data);
                                angular.copy(data[2].rows, vm.gridOld.dataRaw);
                                vm.model.isLoaded = true;
                            }
                        } else {
                            vm.model.isLoaded = true;
                        }

                        vm.model.isLoaded = true;
                        ieScreenBlockService.stop();
                    })
                    .catch(function (error) {
                        vm.model.isLoaded = true;
                        ieScreenBlockService.stop();
                        showMessage(null, error);
                    });
            }

            function savePageSetting(variableName, variableValue, optionalFunc) {
                optionalFunc = optionalFunc || null;
                if (angular.isDefined(variableValue)) {
                    stockPlanningService.savePageSetting({
                        variablename: variableName,
                        variablevalue: variableValue
                    })
                        .then(function (data) {
                            if (angular.isFunction(optionalFunc)) {
                                optionalFunc();
                            }
                        })
                        .catch(function (err) {
                            console.error(err);
                        });
                }
            }

            function purchaseLines() {
                var lines = [];
                var selectedLines = [];
                
                selectedLines = vm.grid.gridfunc.getAllRows();//getSelectedRows();
                
                if (selectedLines.length > 0) {
                    angular.forEach(selectedLines, function (item) {
                        var item_stock_keyno = item.sp_stock_keyno;
                        var item_ordered_quantity = item.sp_purch_proposal;
                        var item_purchase_price = item.sp_purchprice_default;

                        var item_requested_date = item.sp_requested_date;

                        if (item_ordered_quantity > 0) {
                            lines.push({
                                stock_keyno: item_stock_keyno,
                                ordered_quantity: item_ordered_quantity,
                                purchase_price: item_purchase_price,
                                requested_date: moment(item_requested_date).format('YYYY-MM-DD')
                            });
                        }
                    });
                }

                return lines;
            }

            function createPurchase() {
                ieScreenBlockService.start('message');
                ieScreenBlockService.setMessage('Lagrer innkjøp');

                var aDate = vm.model.stock_date.replace(':::', '').trim();

                if (aDate.length > 10) {
                    aDate = aDate.slice(0, 10);
                }

                var lines = vm.purchaseLines();

                if (lines.length > 0) {
                    var purchaseLines = {
                        stockplace_id: vm.model.stockplace_id,
                        businessco_no: vm.model.businessco_no,
                        supplier_no: vm.model.supplier_no,
                        purchase_date: aDate,
                        purchase_lines: lines
                    };

                    stockPlanningService
                        .createPurchase(purchaseLines)
                        .then(function (data) {
                            ieScreenBlockService.stop();
                            stateService.go('purchhead', { purch_internal_no: data[0].purch_internal_no });
                        })
                        .catch(function (err) {
                            ieScreenBlockService.stop();
                            console.error(err);
                        });
                } else {
                    showMessage('Feil', 'Ingen antall angitt!')
                    ieScreenBlockService.stop();
                }
            }

            // TODO: Not implemented
            function goTo(state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case '':
                        go({});
                        break;
                    default:
                        go({
                            argtype: '',
                            argvalue: ''
                        });
                        break;
                }
            }

            // TODO: Not implemented
            function selectedCount() {
                vm.model.selectedCount = 0;
                angular.forEach(vm.model.products, function (item) {
                    if (item.item_is_selected != '0') {
                        vm.model.selectedCount += 1;
                    }
                });
                return vm.model.selectedCount;
            }

            function loadQueue() {
                if (angular.isDefined(timer)) {
                    $timeout.cancel(timer);
                }

                timer = $timeout(function () {
                    vm.updateGridParameters();
                }, 200);
            }

            vm.updateGridParameters = function () {
                vm.grid.dataTask.loadData.parameters = {
                    prod_id: '', // vm.model.prod_id,
                    supplier_no: vm.model.supplier_no,
                    stock_date: vm.model.stock_date.replace(':::', '').trim().slice(0, 10),
                    future_manko_only: vm.model.futuremanko_only,
                    stockplace_id: vm.model.stockplace_id,
                    businessco_no: vm.model.businessco_no,
                    portal_user_keyno_purchaser: vm.model.portal_user_keyno_purchaser
                };

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            };

            function gridOptionFunc(data) {
                if (angular.isDefined(data.type)) {


                    $timeout(function () {
                        vm.gridOld.selectedCount = vm.gridOld.gridfunc.getSelectedRowsCount();
                        if (vm.gridOld.selectedCount == 1) {
                            var row = vm.gridOld.gridfunc.getSelectedRows();
                            if (vm.model.supplier_no == 0) {
                                console.log('gridOptionFunc -- supplier_no: ' + row.data[0].sp_supplier_no);
                                vm.model.supplier_no = row.data[0].sp_supplier_no;
                            }
                        }
                    }, 300);


                    switch (data.type) {
                        case 'init':
                            console.log('Init');
                            vm.gridOld.grid = data.grid;
                            break;
                        case 'dataBoundRow':
                            var cols = {};
                            data.grid.columns;
                            var cellIndex = 0;

                            angular.forEach(data.grid.columns, function (item) {
                                item.cellIndex = cellIndex;
                                cols[item.field] = item;
                                cellIndex += 1;
                            });

                            data.row.find('td:eq(' + cols['sp_prod_name'].cellIndex + ')').addClass('h_point').on('click', function (e) {
                                stateService.go('productprices', { prod_id: data.dataItem.sp_prod_parameter, selected_button: 1 });
                            });

                            break;
                        default:

                            break;
                    }
                }
            }

			// #endregion FUNCTIONS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'portal_user_keyno_purchaser':
                        if (utilityService.validateParmsValue(vm.model.portal_user_keyno_purchaser) !== true) return;

                        variableValue = vm.model.portal_user_keyno_purchaser;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region ANGULAR

            vm.$onInit = function () {
                prepareGrid().then(function () {
                    vm.grid.dataTask.loadData.parameters = {
                        prod_id: '', // vm.model.prod_id,
                        supplier_no: vm.model.supplier_no,
                        stock_date: vm.model.stock_date.replace(':::', '').trim().slice(0, 10),
                        future_manko_only: vm.model.futuremanko_only,
                        stockplace_id: vm.model.stockplace_id,
                        businessco_no: vm.model.businessco_no,
                        portal_user_keyno_purchaser: vm.model.portal_user_keyno_purchaser
                    };

                    vm.model.gridReady = true;
                });
            };

			// #endregion ANGULAR
        }]
    });
})();
