(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('stockPlaceDelTime', {
        templateUrl: 'views/components/views/stockPlaceDelTime/stockPlaceDelTime.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'stockPlaceDelTimeService', 'pickJobGrpsService', function ($stateParams, $q, stateService, utilityService, stockPlaceDelTimeService, pickJobGrpsService) {
            const vm = this;
            let stockPlaceDelTimeKeyno = $stateParams.stockplacedeltime_keyno;

            vm.model = {
                pickJobGroupsButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('pickjobgrps', { stockplace_id: vm.model.edit.stockplace_id_from }) }
                ],
                edit: {},
                selectListPickJobGroups: [],
                lockedSave: false
            };
            
            let loadEdit = function () {
                const deferred = $q.defer();

                stockPlaceDelTimeService.getEdit({ stockplacedeltime_keyno: stockPlaceDelTimeKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                    
                    loadPickJobGroups();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadPickJobGroups = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id_from) !== true) return;
                
                return pickJobGrpsService.listPickJobGroups({ stockplace_id: vm.model.edit.stockplace_id_from }).then(function (list) {
                    angular.copy(list, vm.model.selectListPickJobGroups);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockPlaceDelTimeService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
