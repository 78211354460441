(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("imHeaderService", ['$q', '$timeout', 'translateService', function ($q, $timeout, translateService) {
        var events = {
            stateChanged: {}
        }

        var service = {
            header: null,

            on: function (event, handler) {
                if (angular.isUndefined(events[event])) return function () { };

                var id = uuid();

                events[event][id] = {
                    handler: handler
                };

                if (event === 'stateChanged') {
                    handler(service.header);
                }

                return function () {
                    if (angular.isDefined(events[event][id])) {
                        delete events[event][id];
                    }
                }
            },

            setDocumentTitle: async function (title, translate) { // if title nullish or '', use (un)translated, otherwise, use (un)translated title
                if (!title) {
                    if (!service?.header) return;

                    if (service.header.translate === true) {
                        return translateService.translate(service.header.text).then(function (response) {
                            return document.title = response;
                        });
                    } else {
                        return document.title = service.header.text;
                    }
                } else {
                    if (title === '') return;
                    else {
                        if (translate) {
                            return translateService.translate(title).then(function (response) {
                                return document.title = response;
                            });
                        } else {
                            return document.title = title;
                        }
                    }
                }
            },

            getDocumentTitle: function () {
                return document.title;
            },

            stateChanged: function (header) {
                service.header = header;

                angular.forEach(events.stateChanged, function (value, key) {
                    if (angular.isFunction(value.handler)) {
                        value.handler(service.header);
                    }
                });
            },

            getCurrentHeader: function () {
                return service.header;
            },

            setTitleText: function (text, translate, visible) {
                service.header.text = text;

                service.setTitleTranslate(translate);
                service.setTitleVisibility(visible);
            },

            setTitleTranslate: function (translate) {
                if (angular.isUndefined(translate)) return;

                service.header.translate = translate;
            },

            setTitleVisibility: function (visible) {
                if (angular.isUndefined(visible)) return;

                service.header.visible = visible;
            },

            setBackButtonText: function (text, translate, visible) {
                service.header.backButton.text = text;

                service.setBackButtonTranslate(translate);
                service.setBackButtonVisibility(visible);
            },

            setBackButtonTranslate: function (translate) {
                if (angular.isUndefined(translate)) return;

                service.header.backButton.translate = translate;
            },

            setBackButtonVisibility: function (visible) {
                if (angular.isUndefined(visible)) return;

                service.header.backButton.visible = visible;
            }
        };

        return service;
    }]);
})();