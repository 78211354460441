import { module } from "angular";

(function () {
    'use strict';

    angular.module('imApp').directive('ttItemUsergroupManager', ['$rootScope', '$uibModal', 'utilityService', 'layoutService', 'eventService', function ($rootScope, $uibModal, utilityService, layoutService, eventService) {
        var directive = {
            restrict: 'A',
            scope: {
                ttInfo: '=ttItemUsergroupManager'
            },
            template: '<button class="sp-0 pull-right tt-item-control" style="border: 0; padding-top: 5px; padding-right: 5px; padding-bottom: 5px;"><span class="fa fa-users" ng-style="{\'fontSize\': model.fontSize}"></span></button>',
            link: function ($scope, $element, $attr) {
                if (angular.isUndefined($scope.model)) {
                    $scope.model = {};
                }

                layoutService.onLayoutChanged($scope, function (layoutInfo) {
                    if (angular.isUndefined(layoutInfo)) return;

                    $scope.model.fontSize = layoutInfo.fontSizes.textSize;
                });

                var toggleGroupClass = function (usergroups) {
                    if (usergroups.length > 0) {
                        $attr.$addClass('item-active-on-group-icon');
                    } else {
                        $attr.$removeClass('item-active-on-group-icon');
                    }
                };

                var toggle = function (info) {
                    if (info.group_id !== $scope.ttInfo.group_id)
                        return;
                    if (info.field_id !== $scope.ttInfo.field_id)
                        return;

                    $scope.ttInfo.enabled = info.enabled;

                    if (info.usergroups !== $scope.ttInfo.usergroups) {
                        angular.copy(info.usergroups, $scope.ttInfo.usergroups);
                    }

                    if (utilityService.toBoolean(info.enabled)) {
                        $attr.$removeClass('ng-hide');
                    } else {
                        $attr.$addClass('ng-hide');
                    }

                    toggleGroupClass(info.usergroups);
                };

                toggle($scope.ttInfo);

                var dereg = $rootScope.$on('ttItemService:fieldInfoToggle', function (_, info) {
                    toggle(info);
                });

                $scope.$on('$destroy', function () {
                    if (angular.isFunction(dereg)) {
                        dereg();
                    }
                });

                $element.find('button').on('click', function (event) {
                    event.stopPropagation();
                    event.preventDefault();

                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: 'views/modal/fieldInfoUsergroups.html?v=' + angular.module('imApp').version,
                        controller: ['$scope', 'translateService', 'usergroupService', 'layoutService', function ($uibScope, translateService, usergroupService, layoutService) {
                            $uibScope.model = {
                                translations: {
                                    fig_title: '',
                                    fig_close: ''
                                },
                                usergroups: [],
                                fontSize: ''
                            };

                            var ugIndex = {};

                            layoutService.onLayoutChanged($uibScope, function (layoutInfo) {
                                if (angular.isUndefined(layoutInfo)) return;

                                $uibScope.model.fontSize = layoutInfo.fontSizes.textSize;

                                var groupIndex = {};

                                angular.forEach($scope.ttInfo.usergroups, function (ug, index) {
                                    groupIndex['_' + ug.usergroup_keyno] = index;
                                });

                                for (var i = 0; i < $uibScope.model.usergroups.length; i++) {
                                    if (angular.isDefined(groupIndex['_' + $uibScope.model.usergroups[i].usergroup_keyno])) {
                                        $uibScope.model.usergroups[i].glyphstyle = { 'color': 'green', 'fontSize': layoutInfo.fontSizes.textSize };
                                    } else {
                                        $uibScope.model.usergroups[i].glyphstyle = { 'color': 'red', 'fontSize': layoutInfo.fontSizes.textSize };
                                    }
                                };
                            });

                            var deregTranslations = translateService.on($uibScope.model.translations, 'changed', function (result) {
                                deregTranslations();

                                angular.copy(result, $uibScope.model.translations);
                            });

                            usergroupService.list().then(function (usergroups) {
                                $uibScope.model.usergroups = [];

                                var groupIndex = {};

                                angular.forEach($scope.ttInfo.usergroups, function (ug, index) {
                                    groupIndex['_' + ug.usergroup_keyno] = index;
                                });

                                angular.forEach(usergroups, function (ug, index) {
                                    ugIndex['_' + ug.usergroup_keyno] = index;

                                    if (angular.isDefined(groupIndex['_' + ug.usergroup_keyno])) {
                                        ug.glyphicon = 'glyphicon-check';
                                        ug.glyphstyle = { 'color': 'green', 'fontSize': $uibScope.model.fontSize };
                                    } else {
                                        ug.glyphicon = 'glyphicon-unchecked';
                                        ug.glyphstyle = { 'color': 'red', 'fontSize': $uibScope.model.fontSize };
                                    }
                                });

                                angular.copy(usergroups, $uibScope.model.usergroups);
                            });

                            $uibScope.toggle = function (usergroup) {
                                var ttItemId = angular.isDefined($scope.ttInfo.ttItemId)
                                    ? $scope.ttInfo.ttItemId
                                    : $scope.ttInfo.group_id + '.' + $scope.ttInfo.field_id;

                                usergroupService.toggleFieldInfoUsergroup(ttItemId, $scope.ttInfo.keyno, usergroup.usergroup_keyno).then(function (data) {
                                    var info = data[0];

                                    var enabled = false;

                                    if (info.p2_tt_layout_fieldinfo_usergroup_keyno === '0') {
                                        var i = 0;

                                        for (; i < $scope.ttInfo.usergroups.length; i++) {
                                            if ($scope.ttInfo.usergroups[i].usergroup_keyno.toString() === info.usergroup_keyno.toString())
                                                break;
                                        }

                                        if (i < $scope.ttInfo.usergroups.length) {
                                            $scope.ttInfo.usergroups.splice(i, 1);
                                        }
                                    } else {
                                        $scope.ttInfo.usergroups.push({ usergroup_keyno: info.usergroup_keyno });

                                        enabled = true;
                                    }

                                    $uibScope.model.usergroups[ugIndex['_' + info.usergroup_keyno]].glyphicon = enabled ? 'glyphicon-check' : 'glyphicon-unchecked';
                                    $uibScope.model.usergroups[ugIndex['_' + info.usergroup_keyno]].glyphstyle = enabled ? { 'color': 'green', 'fontSize': $uibScope.model.fontSize } : { 'color': 'red', 'fontSize': $uibScope.model.fontSize };

                                    toggleGroupClass($scope.ttInfo.usergroups);

                                    eventService.trigger('ttItemUsergroupManager:toggle', {
                                        usergroup: $uibScope.model.usergroups[ugIndex['_' + info.usergroup_keyno]],
                                        field: $scope.ttInfo
                                    });
                                });
                            };

                            $uibScope.close = function () {
                                $rootScope.$broadcast('ttItemUsergroupManager:close', {
                                    field: $scope.ttInfo
                                });

                                modalInstance.close();
                            };
                        }]
                    });
                });
            }
        };
 
        return directive;
    }]);
})();
