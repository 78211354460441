(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2Terminals', {
        templateUrl: 'views/components/views/p2Terminals/p2Terminals.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['p2TerminalsService', 'stateService', function (p2TerminalsService, stateService) {
            var vm = this;
            
            vm.model = {
                itemsListTerminals: []
            };
            
            p2TerminalsService.listTerminals().then(function (result) {
                angular.copy(result, vm.model.itemsListTerminals);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2terminal':
                        stateService.go(state, {
                            p2_terminal_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();