(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('workGrps', {
        templateUrl: 'views/components/views/workGrps/workGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'workGrpsService', function (stateService, workGrpsService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            vm.model = {
                itemsListCapacityGroups: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadWorkGroupsView = function () {
                workGrpsService.listWorkGroupsView().then(function (data) {
                    angular.copy(data, vm.model.itemsListCapacityGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'workgrp':
                        go({ workgrp_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadWorkGroupsView();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
