(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("gisPositionAddService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadGisPositionAddGet: function (argtype, argvalue, lat, lng) {
                return p2DataTaskService.call(1284, {
                    argtype: argtype,
                    argvalue: argvalue,
                    lat: lat,
                    lng: lng
                });
            },
            save: function (add) {
                return p2DataTaskService.call(1285, add);
            }
        };

        return service;
    }]);
})();