import { Injectable, Inject } from '@angular/core';
import { StateParams, TransitionOptions } from '@uirouter/angularjs';

export interface IStateService {
    /**
     * Goes to the given state using the given parameters.
     *
     * @param to the state to go to,
     * @param parms the parameters to use for going to the state. Either an object or string seperated with `/` like `'custact_no/60502'`.
     * @param options see [ui router documentation](https://ui-router.github.io/ng1/docs/latest/interfaces/transition.transitionoptions.html).
     */
    go<TType = any>(to: string, parms?: Record<string | symbol, TType> | string | null, options?: Partial<TransitionOptions>): void;

    /**
     * Goes to the url with `$location`, used by pinned tabs.
     *
     * @param url the url to go to.
     */
    pinnedGo(url: string): void;

    /**
     * Goes back to the given state instance in the state chain.
     *
     * @param state the state instance to go back to.
     */
    goBackTo(state: StateInstance): void;

    /**
     * Signs out of the application.
     */
    signOut(): void;

    /**
     * Goes back to the previous state if no `to` is given. If given a number, will jump back the given number in the state chain.
     * If given a string will go back to the given statechain path (like `'/workdetails/605000'`), and back to the mainmenu if the path is not found.
     *
     * @param to the number of steps to jump back in the state chain, or the state chain path to go back to.
     */
    back(to?: number | string): void;

    /**
     * Opens the given state in a new tab using the given parameters.
     *
     * @param state the state to open in a new tab.
     * @param parms the parameters to use for the state to open in a new tab.
     */
    newTab<TType = any>(state: string, parms?: Record<string | symbol, TType> | string | null): void;

    /**
     * Retrieves the current state name excluding parameters, like `'workdetails'`.
     */
    getCurrentName(): string;

    /**
     * Retrieves the current path, excluding hash like `'/documents/custact_no/60500'`.
     */
    getCurrentPath(): string;

    /**
     * Retrieves an object over parameters used on the page route.
     * Equivelant to the `$stateParams` services in angularJs.
     */
    getStateParams(): { [key: string]: string };

    /**
     * Retrieves the current host-name excluding `'https://` and any parameters, like `'test.touchtime.app'`.
     */
    getHost(): string;

    /**
     * Sets the given list over pinned states to the pinned list.
     *
     * @param list the list to set as the pinned list.
     */
    setPinnedList(list: PinnedState[]): void;

    /**
     * Sets the given state instance as pinned or not pinned according to the given pinned value.
     *
     * @param state the state instance to pin.
     * @param pinned whether the state instance should be pinned or unpinned.
     */
    setPin(state: StateInstance, pinned: any): void;

    /**
     * Overrides the document title with the given title.
     *
     * @param title the title to override the document title with.
     * @param translate whether to translate the given title before overriding.
     */
    setTitleOverride(title: string, translate: boolean): void;
}

/**
 * Represents a state instance.
 */
export interface StateInstance {
    /**
     * The name of the state.
     */
    name: string;

    /**
     * The page title to use for the state instance.
     */
    pageTitle: string;

    /**
     * The complete relative path of the state instance (excluding hash) like `'/workdetails/60500'`.
     */
    path: string;

    /**
     * The title used in breadcrumbs/tabs.
     */
    title: string;

    /**
     * The state configuration object set in app.routes.
     */
    state: any;

    /**
     * The parameters applied to the state instance.
     */
    parms: Record<string | symbol, unknown>;

    /**
     * The date this state instance was created.
     */
    date: string;

    /**
     * Hash of the state instance.
     */
    $$hashKey: string;
}

/**
 * Represents a pinned state.
 */
export interface PinnedState {
    /**
     * The title of the pinned tab.
     */
    pinned_name: string;

    /**
     * The path of the pinned tab like `'/documents/custact_no/60500'`.
     */
    pinned_url: string;

    /**
     * The key of the pinned tab.
     */
    webpagepinned_keyno: string;
}

@Injectable({
    providedIn: 'root',
})
export class StateService implements IStateService {
    constructor(@Inject('stateService') private stateService: IStateService, @Inject('$stateParams') private stateParams: StateParams) {}

    go = <TType = any>(to: string, parms?: Record<string | symbol, TType> | string | null, options?: any): void => this.stateService.go(to, parms, options);

    pinnedGo = (url: string): void => this.stateService.pinnedGo(url);

    goBackTo = (state: any): void => this.stateService.goBackTo(state);

    signOut = (): void => this.stateService.signOut();

    back = (to?: any): void => this.stateService.back(to);

    newTab = <TType = any>(state: string, parms?: Record<string | symbol, TType> | string | null): void => this.stateService.newTab(state, parms);

    getCurrentName = (): string => this.stateService.getCurrentName();

    getCurrentPath = (): string => this.stateService.getCurrentPath();

    getHost = (): string => this.stateService.getHost();

    getStateParams = (): { [key: string]: string } => this.stateParams;

    setPinnedList = (list: any): void => this.stateService.setPinnedList(list);

    setPin = (state: any, pinned: any): void => this.stateService.setPin(state, pinned);

    setTitleOverride = (title: string, translate: boolean = false): void => this.stateService.setTitleOverride(title, translate);
}
