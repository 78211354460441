(function () {
    'use strict';

    angular.module("imApp").factory("docPackService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 3289,
                    parameters: parms || {}
                });
            },
            getChapter: function (parms) {
                return $ihttp.post({
                    method: 3311,
                    parameters: parms || {}
                });
            },
            generatePdf: function (edit) {
                return $ihttp.post({
                    method: 3316,
                    parameters: edit
                });
            },
            updateHtml: function (edit) {
                return $ihttp.post({
                    method: 3314,
                    parameters: edit
                });
            },
            updateHtmlChapter: function (edit) {
                return $ihttp.post({
                    method: 3315,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 3313,
                    parameters: edit
                });
            },
            saveChapter: function (chapterInfo) {
                return $ihttp.post({
                    method: 3312,
                    parameters: chapterInfo
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 3317,
                    parameters: edit
                });
            },
            deleteGridRows: function (mergeObject) {
                return $ihttp.post({
                    method: 3292,
                    parameters: mergeObject
                });
            }
        };

        return service;
    }]);
})();
