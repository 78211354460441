(function () {
    'use strict';

    angular.module("imApp").factory("factoryMenuService", ['$ihttp', function ($ihttp) {
        var service = {
            getFactoryMenu: function (factoryId) {
                return $ihttp.post({
                    method: 2026,
                    parameters: {
                        factory_id: factoryId
                    }
                });
            }
        };

        return service;
    }]);
})();