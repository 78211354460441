(function () {
    'use strict';

    angular.module("imApp").factory("workGrpService", ['$ihttp', function ($ihttp) {
        let service = {
            getWorkGroup: function (parms) {
                return $ihttp.post({
                    method: 2022,
                    parameters: parms || {}
                });
            },
            listFactories: function (businessCoNo) {
                return $ihttp.post({
                    method: 2024,
                    parameters: {
                        businessco_no: businessCoNo || ''
                    }
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2023,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
