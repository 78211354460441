(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srOperationResource', {
        templateUrl: 'views/components/views/srOperationResource/srOperationResource.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'srOperationResourceService', function($stateParams, stateService, modalService, srOperationResourceService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                specialHrsCustactResourceKeyNo: $stateParams.special_hrs_custactresource_keyno,
                resource: {},
                lockedDelete: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srOperationResourceService.loadSrOperationResourceGet(vm.model.specialHrsCustactResourceKeyNo).then(function (data) {
                vm.model.resource = angular.copy(data[0]);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srcrews':
                        go({
                            argtype: 'resource_id',
                            argvalue: vm.model.resource.resource_id
                        });
                        break;
                }
            };
    
            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteSrOperationResource = function () {
                vm.model.lockedDelete = true;
                
                srOperationResourceService.deleteObj(vm.model.resource).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();