(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productSetting', {
        templateUrl: 'views/components/views/productSetting/productSetting.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'productSettingService', 'productService', 'logisticService', 'ttGridFactory', function ($stateParams, $q, stateService, utilityService, productSettingService, productService, logisticService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let productSettingKeyno = $stateParams.productsetting_keyno;
            
            vm.model = {
                discGroupProductNameButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.discgrpprod_keyno = '0' }
                ],
                discGroupProductNameButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.discgrpprod_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('discgrpprods', {}) }
                ],
                edit: {},
                selectListProductTypes: [],
                selectListUnits: [],
                selectListProductGroups: [],
                selectListCompanies: [],
                selectListCompletionMethods: [],
                selectListCompletionPrinciples: [],
                selectListProductionOrderMethods: [],
                selectListPlanPolicies: [],
                selectListDiscGroupProducts: [],
                selectListProductionPlaces: [],
                gridReady: false,
                lockedSave: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'productsetting_productsettingmcs_grid',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({
                wrapping: true
            }).setCustomToolbarButtons([{
                name: 'productsettingmc_new',
                text: 'productsettingmc_new',
                func: function () {
                    stateService.go('productsettingmc', {
                        productsetting_keyno: vm.model.edit.productsetting_keyno,
                        productsettingmc_keyno: '0'
                    });
                },
                icon: 'fa-plus',
                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                translate: true
            }]);

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                const deferred = $q.defer();

                productSettingService.getEdit({ productsetting_keyno: productSettingKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadGrid();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadProductTypes = function () {
                productService.listProductTypes().then(function (list) {
                    angular.copy(list, vm.model.selectListProductTypes);
                });
            };

            let loadUnits = function () {
                logisticService.listUnits().then(function (list) {
                    angular.copy(list, vm.model.selectListUnits);
                });
            };

            let loadProductGroups = function () {
                logisticService.listProductGroups().then(function (list) {
                    angular.copy(list, vm.model.selectListProductGroups);
                });
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (list) {
                    angular.copy(list, vm.model.selectListCompanies);
                });
            };
            
            let loadCompletionMethods = function () {
                logisticService.listCompletionMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListCompletionMethods);
                });
            };
            
            let loadCompletionPrinciples = function () {
                logisticService.listCompletionPrinciples().then(function (list) {
                    angular.copy(list, vm.model.selectListCompletionPrinciples);
                });
            };

            let loadProductionOrderMethods = function () {
                logisticService.listProductionOrderMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListProductionOrderMethods);
                });
            };

            let loadPlanPolicies = function () {
                logisticService.listPlanPolicies().then(function (list) {
                    angular.copy(list, vm.model.selectListPlanPolicies);
                });
            };
            
            let loadDiscGroupProducts = function () {
                productService.listDiscGroupProducts().then(function (list) {
                    angular.copy(list, vm.model.selectListDiscGroupProducts);
                });
            };

            let loadProductionPlaces = function () {
                logisticService.listProductionPlaces().then(function (list) {
                    angular.copy(list, vm.model.selectListProductionPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.edit.productsetting_keyno) !== true) return;
                
                vm.grid.dataTask.loadData = {
                    method: 3061,
                    parameters: { productsetting_keyno: vm.model.edit.productsetting_keyno }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productSettingService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadProductTypes();
                loadUnits();
                loadProductGroups();
                loadCompanies();
                loadCompletionMethods();
                loadCompletionPrinciples();
                loadProductionOrderMethods();
                loadPlanPolicies();
                loadDiscGroupProducts();
                loadProductionPlaces();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
