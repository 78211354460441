import { Injectable } from '@angular/core';
import { Providers, Msal2Provider, LoginType, TemplateHelper } from '@microsoft/mgt';
import { PublicClientApplication, AccountInfo, InteractionType } from '@azure/msal-browser';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
import { Client } from '@microsoft/microsoft-graph-client';


@Injectable({
    providedIn: 'root',
})

export class GraphAuthService {

    private graphClient?: Client;

    constructor() {
        //console.log('graph-auth.service');
        //this.initialize();
    }

    private readonly config = {
        auth: {
            clientId: "c90067f7-0ccf-43ee-a05c-30b601f5e721"//,
            //redirectUri: 'https://hybridtest.touchtime.app/index.html',
            //postLogoutRedirectUri: 'https://hybridtest.touchtime.app/index.html'
        },
    }

    private readonly scopes = ['User.ReadWrite', 'Mail.ReadWrite', 'Mail.ReadWrite.Shared', 'Mail.Send', 'Calendars.ReadWrite'];

    private initState: InitState = InitState.Uninitialized;

    public async initialize() {
        //console.log('grap-auth.service.initialize');
        //console.log('initstate: ' + this.initState);

        switch (this.initState) {
            case InitState.Initialized:
                return;
            case InitState.Initializing:
                function timeout() {
                    return new Promise(resolve => setTimeout(resolve, 50));
                }

                while (this.initState === InitState.Initializing) {
                    await timeout();
                }

                return;
        }

        this.initState = InitState.Initializing;

        let pca = new PublicClientApplication(this.config);

        await pca.initialize();

        Providers.globalProvider = new Msal2Provider({
            loginType: LoginType.Popup,
            publicClientApplication: pca,
            clientId: this.config.auth.clientId,
            scopes: this.scopes
        });
        TemplateHelper.setBindingSyntax('[[', ']]');

        const currentAccount = (Providers.globalProvider as Msal2Provider).publicClientApplication.getAllAccounts()[0];

        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider((Providers.globalProvider as Msal2Provider).publicClientApplication, {
            account: currentAccount,
            scopes: this.scopes,
            interactionType: InteractionType.Popup
        });

        this.graphClient = Client.initWithMiddleware({ authProvider: authProvider });

        this.initState = InitState.Initialized;
    }

    /**
     * Returns the graph client.
     * 
     * @returns the graph client.
     */
    public async getGraphClient(): Promise<Client | undefined> {
        if (!this.graphClient) {
            await this.initialize();
        }

        return this.graphClient;
    }

    public async getAccount(): Promise<AccountInfo> {
        await this.initialize();

        let accounts = (Providers.globalProvider as Msal2Provider).publicClientApplication.getAllAccounts();

        if (accounts.length < 1) {
            throw new Error("No user accounts found.");
        }

        return (Providers.globalProvider as Msal2Provider).publicClientApplication.getAllAccounts()[0];
    }

    public async getAccessToken(account?: AccountInfo): Promise<string> {
        if (account === null) {
            account = await this.getAccount();
        }

        const accessTokenRequest = {
            scopes: this.scopes,
            account: account,
        };

        await this.initialize();

        let response = await (Providers.globalProvider as Msal2Provider).publicClientApplication.acquireTokenSilent(accessTokenRequest);

        return response.accessToken;
    }
}

enum InitState {
    Uninitialized,
    Initializing,
    Initialized
}
