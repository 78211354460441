(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDeliveryService", ['$q', '$ihttp', 'p2DataTaskService', 'utilityService', function ($q, $ihttp, p2DataTaskService, utilityService) {
        var service = {
            loaded: false,
            p2_batchdelivery_get: {
                batchdelivery_keyno: '',
                reg_datetime: '',
                stockplace_name: '',
                freight_eu: '',
                freight_ex_eu: '',
                customs_eu: '',
                customs_ex_eu: '',
                divcost: '',
                sumcosts: '',
                show_approve_batchdelivery: '',
                show_add_purchline_button: '',
                show_delete_button: '',
                show_save_button: '',
                stocklocation_keyno: ''
            },
            p2_batchdelpurchline_list: {
                item_glyphicon: '',
                item_id: '',
                item_name: '',
                item_name_sub1: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            p2BatchDelPurchLinesBList: {
                records: []
            },
            p2ConsignmentScan: {},

            loadStockLocationList: function (stockzoneclass_keyno, stockplace_id) {
                return p2DataTaskService.call(472, {
                    stockzoneclass_keyno: stockzoneclass_keyno,
                    stockplace_id: stockplace_id
                });
            },

            loadP2BatchDeliveryGet: function (batchdelivery_keyno) {
                var deferred = $q.defer();

                var parms_p2_batchdelivery_get = {
                    method: 279,
                    parameters: {
                        batchdelivery_keyno: batchdelivery_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelivery_get).then(function (data) {
                    service.p2_batchdelivery_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            serviceTest: function (ms) {
                var deferred = $q.defer();
                sleep(ms);
                service.loaded = true;
                deferred.resolve();
                return deferred.promise;
            },
            loadP2BatchDelPurchLineList: function (batchdelivery_keyno) {
                var deferred = $q.defer();

                var parms_p2_batchdelpurchline_list = {
                    method: 282,
                    parameters: {
                        batchdelivery_keyno: batchdelivery_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelpurchline_list).then(function (data) {
                    angular.copy(data, service.p2_batchdelpurchline_list.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2BatchDelPurchLinesBList: function (batchdelpurchline_keyno, batchdelivery_keyno) {
                var deferred = $q.defer();

                var parmsP2BatchDelPurchLinesBList = {
                    method: 288,
                    parameters: {
                        batchdelpurchline_keyno: batchdelpurchline_keyno,
                        batchdelivery_keyno: batchdelivery_keyno
                    }
                };

                $ihttp.post(parmsP2BatchDelPurchLinesBList).then(function (data) {
                    angular.copy(data, service.p2BatchDelPurchLinesBList.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2BatchDelivery: function () {
                var deferred = $q.defer();

                var parms_p2_batchdelivery_save = {
                    method: 289,
                    parameters: {
                        batchdelivery_keyno: service.p2_batchdelivery_get.batchdelivery_keyno,
                        reg_datetime: service.p2_batchdelivery_get.reg_datetime,
                        stockplace_name: service.p2_batchdelivery_get.stockplace_name,
                        freight_eu: service.p2_batchdelivery_get.freight_eu,
                        freight_ex_eu: service.p2_batchdelivery_get.freight_ex_eu,
                        customs_eu: service.p2_batchdelivery_get.customs_eu,
                        customs_ex_eu: service.p2_batchdelivery_get.customs_ex_eu,
                        divcost: service.p2_batchdelivery_get.divcost,
                        packing_slip_id: service.p2_batchdelivery_get.packing_slip_id,
                        deliverynote: service.p2_batchdelivery_get.deliverynote,
                        arrival_date: utilityService.replaceAll(service.p2_batchdelivery_get.arrival_date, ':', '') + '00:00:00',
                        supplier_invoice_received: service.p2_batchdelivery_get.supplier_invoice_received,
                        supplier_invoice_id: service.p2_batchdelivery_get.supplier_invoice_id,
                        supplier_invoice_date: service.p2_batchdelivery_get.supplier_invoice_date,
                        supplier_invoice_reference: service.p2_batchdelivery_get.supplier_invoice_reference,
                        stocklocation_keyno: service.p2_batchdelivery_get.stocklocation_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelivery_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 281,
                    parameters: edit
                });
            },
            //deleteP2BatchDelivery: function () {
            //    var deferred = $q.defer();

            //    var parms_p2_batchdelivery_delete = {
            //        method: 281,
            //        parameters: {
            //            batchdelivery_keyno: service.p2_batchdelivery_get.batchdelivery_keyno
            //        }
            //    };

            //    $ihttp.post(parms_p2_batchdelivery_delete).then(function () {
            //        deferred.resolve();
            //    });

            //    return deferred.promise;
            //},
            approveP2BatchDeliverySubSupplier: function () {
                var deferred = $q.defer();

                var parmsP2BatchDeliverySubSupplierApprove = {
                    method: 425,
                    parameters: {
                        batchdelivery_keyno: service.p2_batchdelivery_get.batchdelivery_keyno
                    }
                };

                $ihttp.post(parmsP2BatchDeliverySubSupplierApprove).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            approveP2BatchDelivery: function () {
                var deferred = $q.defer();

                var parms_p2_batchdelivery_approve = {
                    method: 292,
                    parameters: {
                        batchdelivery_keyno: service.p2_batchdelivery_get.batchdelivery_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdelivery_approve).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            searchP2ConsignmentScan: function (barcode, batchdelivery_keyno) {
                var deferred = $q.defer();

                var parmsP2ConsignmentScan = {
                    method: 321,
                    parameters: {
                        barcode: barcode,
                        batchdelivery_keyno: batchdelivery_keyno
                    }
                };

                $ihttp.post(parmsP2ConsignmentScan).then(function (data) {
                    service.p2ConsignmentScan = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            rollback: function (batchdelivery_keyno) {
                return p2DataTaskService.call(1721, {
                    batchdelivery_keyno: batchdelivery_keyno
                });
            }
        };

        return service;
    }]);
})();