(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactCompService", ['$q', '$ihttp', '$stateParams', 'p2DataTaskService', function ($q, $ihttp, $stateParams, p2DataTaskService) {
        var service = {
            loaded: false,
            selectingProduct: false,
            p2CustactCompAdd: {},
            p2CustactCompAddProduct: {},
            p2CustactCompGetPrice: {},
            p2ProductProdsizeList: {
                records: []
            },
            p2ProductProdchoiceList: {
                records: []
            },
            p2ValutaList: {
                records: []
            },
            loadP2CustactCompAdd: function (custact_no, custactcomp_keyno) {
                var deferred = $q.defer();

                var parmsP2CustactCompAdd = {
                    method: 364,
                    parameters: {
                        custact_no: custact_no,
                        custactcomp_keyno: custactcomp_keyno
                    }
                };

                $ihttp.post(parmsP2CustactCompAdd).then(function (data) {
                    service.p2CustactCompAdd = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2CustactCompAddProduct: function (prod_id) {
                var deferred = $q.defer();

                var parmsP2CustactCompAddProduct = {
                    method: 365,
                    parameters: {
                        prod_id: prod_id
                    }
                };

                $ihttp.post(parmsP2CustactCompAddProduct).then(function (data) {
                    service.p2CustactCompAddProduct = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2ProductProdsizeList: function (prod_id) {
                var deferred = $q.defer();

                var parmsP2ProductProdsizeList = {
                    method: 135,
                    parameters: {
                        prod_id: prod_id
                    }
                };

                $ihttp.post(parmsP2ProductProdsizeList).then(function (data) {
                    angular.copy(data, service.p2ProductProdsizeList.records);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2ProductProdchoiceList: function (prod_id) {
                var deferred = $q.defer();

                var parmsP2ProductProdchoiceList = {
                    method: 366,
                    parameters: {
                        prod_id: prod_id
                    }
                };

                $ihttp.post(parmsP2ProductProdchoiceList).then(function (data) {
                    angular.copy(data, service.p2ProductProdchoiceList.records);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2ValutaList: function () {
                var deferred = $q.defer();

                var parmsP2ValutaList = {
                    method: 373
                };

                $ihttp.post(parmsP2ValutaList).then(function (data) {
                    angular.copy(data, service.p2ValutaList.records);
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2CustactCompGetPrice: function (prod_id, prodsize_keyno, prodchoice_keyno, custact_no, unit_no) {
                var deferred = $q.defer();

                var parmsP2CustactCompGetPrice = {
                    method: 374,
                    parameters: {
                        prod_id: prod_id,
                        prodsize_keyno: prodsize_keyno,
                        prodchoice_keyno: prodchoice_keyno,
                        custact_no: custact_no,
                        unit_no: unit_no
                    }
                };

                $ihttp.post(parmsP2CustactCompGetPrice).then(function (data) {
                    service.p2CustactCompGetPrice = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },

            saveNewP2CustactCompAdd: function (p2CustactCompAdd) {
                var deferred = $q.defer();

                var parmsP2CustactCompAddSaveNew = {
                    method: 367,
                    parameters: p2CustactCompAdd
                };

                $ihttp.post(parmsP2CustactCompAddSaveNew).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            },
            deleteP2CustactComp: function () {
                var deferred = $q.defer();

                var parmsP2CustactCompDelete = {
                    method: 370,
                    parameters: {
                        custact_no: $stateParams.custact_no,
                        custactcomp_keyno: $stateParams.custactcomp_keyno
                    }
                };

                $ihttp.post(parmsP2CustactCompDelete).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();