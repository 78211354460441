(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travelWageData', {
        templateUrl: 'views/components/views/travelWageData/travelWageData.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'travelWageDataService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, travelWageDataService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let travelBillNo = $stateParams.travelbill_no;
            let travelWageDataKeyno = $stateParams.travelwagedata_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                travelWageButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('travelwages') }
                ],
                travelWageButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('travelwages') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('travelwagemenu', { travelwage_id: vm.model.edit.travelwage_id }) }
                ],
                edit: {},
                selectListTravelWageTypes: [],
                selectListTravelWages: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            let loadEdit = function () {
                let deferred = $q.defer();
                
                travelWageDataService.getTravelWageData({
                    travelbill_no: travelBillNo,
                    travelwagedata_keyno: travelWageDataKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadTravelWages()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadTravelWageTypes = function () {
                travelWageDataService.listTravelWageTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListTravelWageTypes);
                });
            };
            
            let loadTravelWages = function () {
                if (utilityService.validateParmsValue(vm.model.edit.travelbill_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.travelwagedata_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.travelwagetype_id) !== true) return;
                
                return travelWageDataService.listTravelWages({
                    travelbill_no: vm.model.edit.travelbill_no,
                    travelwagedata_keyno: vm.model.edit.travelwagedata_keyno,
                    travelwagetype_id: vm.model.edit.travelwagetype_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListTravelWages);
                });
            };
            
            let changeTravelWage = function () {
                let deferred = $q.defer();

                if (utilityService.validateParmsValue(vm.model.edit.travelwage_id) !== true) return;
                
                return travelWageDataService.travelWageChanged(vm.model.edit).then(function (response) {
                    vm.model.edit.rate = response[0].rate;

                    $q.all([
                        recalcAmount()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            // ## RECALC FUNCTIONS ##
            
            let recalcAmount = function () {
                vm.model.edit.amount = utilityService.parseNumber(vm.model.edit.number) * utilityService.parseNumber(vm.model.edit.rate);
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                travelWageDataService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'travelwagetype_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.travelwagetype_id) !== true) return;

                        vm.model.edit.travelwagetype_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.travelwagetype_id, true) !== true) return;

                        loadTravelWages();

                        vm.model.edit.travelwage_id = '';
                        vm.model.edit.rate = '0';
                        vm.model.edit.amount = '0';
                        break;
                    case 'travelwage_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.travelwage_id) !== true) return;

                        vm.model.edit.travelwage_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.travelwage_id, true) !== true) return;

                        changeTravelWage();
                        break;
                    case 'number':
                        if (utilityService.validateWatchValue(value, vm.model.edit.number) !== true) return;

                        vm.model.edit.number = value;

                        if (utilityService.validateParmsValue(vm.model.edit.number, true) !== true) return;

                        recalcAmount();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadTravelWageTypes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
