(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListCrewsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListCrewsGet: function (checklistdata_keyno) {
                return p2DataTaskService.call(1631, {
                    checklistdata_keyno: checklistdata_keyno
                });
            },
            loadSrCheckListCrewsList: function (checklistdata_keyno) {
                return p2DataTaskService.call(1782, {
                    checklistdata_keyno: checklistdata_keyno
                });
            }
        };

        return service;
    }]);
})();