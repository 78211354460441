(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prodGrpMenu', {
        templateUrl: 'views/components/views/prodGrpMenu/prodGrpMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'prodGrpMenuService', function ($stateParams, $q, stateService, utilityService, prodGrpMenuService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodGrpId = $stateParams.prodgrp_id;

            vm.model = {
                productGroupNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrplangs', { prodgrp_id: vm.model.menu.prodgrp_id }) }
                ],
                menu: {},
                treeList: [],
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_prodgrpmenu_prodgrp2_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'prodgrp2_new',
                                text: 'prodgrp2_new',
                                func: function () {
                                    stateService.go('prodgrp2', {
                                        prodgrp_id: vm.model.menu.prodgrp_id,
                                        prodgrp2_id: '+'
                                    });
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
            	let deferred = $q.defer();

            	prodGrpMenuService.getMenu({ prodgrp_id: prodGrpId }).then(function (result) {
            		angular.copy(result[0], vm.model.menu);

                    $q.all([
                        loadTreeProductGroups(),
            			loadGrid()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            let loadTreeProductGroups = function () {
                if (utilityService.validateParmsValue(vm.model.menu.prodgrp_id) !== true) return;

                return prodGrpMenuService.treeListProductGroups({ prodgrp_id: vm.model.menu.prodgrp_id }).then(function (result) {
                    angular.copy(result, vm.model.treeList);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3069,
                    parameters: { prodgrp_id: vm.model.menu.prodgrp_id }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            };
            
            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'prodgrp':
                        go({ prodgrp_id: vm.model.menu. prodgrp_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region TREE SELECTION FUNCTION

            vm.selectTreeItem = function (item) {
                stateService.go(item.item_state, item.item_parms);
            };

            // #endregion TREE SELECTION FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadMenu();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
