(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("consignmentPlanService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
		var service = {
			selectEquipmentList: [],
			isSearchingEquipments: false,
			loadConsignmentPlanGet: function (custact_no ) {
				return p2DataTaskService.call(1362, {
					custact_no: custact_no
				});
			},
			loadEquipmentList: function () {
				return p2DataTaskService.call(30, {});
			},
			loadStockPlaceList: function () {
				return p2DataTaskService.call(453, {});
			},
			loadActStatusList: function (activitytype_no) {
				return p2DataTaskService.call(50, {
					activitytype_no: activitytype_no
				});
			},
			loadTransportAreaList: function (add_all, stockplace_id) {
				return p2DataTaskService.call(1397, {
					add_all: add_all,
					stockplace_id: stockplace_id
				});
			},
			loadConsignmentPlanOnList: function (custact_no_consignmentplan) {
				return p2DataTaskService.call(1416, {
					custact_no_consignmentplan: custact_no_consignmentplan
				});
			},
			updateOrder: function (parms) {
				var deferred = $q.defer();

				var parms_genericFunction = {
					method: 1442,
					parameters: parms
				};

				$ihttp.post(parms_genericFunction).then(function (data) {
					service.loaded = true;
					deferred.resolve(data);
				});

				return deferred.promise;
			},
			searchEquipments: function (equipmentName, businessCoNo) {
				var deferred = $q.defer();

				service.isSearchingEquipments = true;

				$ihttp.post({
					method: 1365, parameters: {
						equipment_name: equipmentName,
						businessco_no: businessCoNo || ''
					}
				}).then(function (data) {
					angular.copy(data, service.selectEquipmentList);

					service.isSearchingEquipments = false;

					deferred.resolve(data);
				});

				return deferred.promise;
			},
			save: function (plan) {
				return p2DataTaskService.call(1363, plan);
			}
		};

		return service;
	}]);
})();