(function () {
    'use strict';

    angular.module("imApp").factory("projectActivityTypesService", ['$ihttp', function ($ihttp) {
        let service = {
            getProjectActivityTypes: function (parms) {
                return $ihttp.post({
                    method: 2183,
                    parameters: parms || {}
                });
            },
            listProjectActivityTypes: function (parms) {
                return $ihttp.post({
                    method: 2184,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
