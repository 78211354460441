(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('emailAttachment', {
        templateUrl: 'views/components/views/email/email-attachment.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttModel: '<?',
            ttClick: '&?'
        },
        controller: ['$element', '$sce', '$timeout', 'translateService', 'layoutService', 'utilityService', 'ttDirectivesService', function ($element, $sce, $timeout, translateService, layoutService, utilityService, ttDirectivesService) {
            let vm = this;
            let onDestroy = [];
            vm.icon = 'fad fa-file';
            vm.filename = '';

            vm.style = {
                icon: {
                    color: 'darkgrey',
                },
                text: {},
            }

            layoutService.onLayoutChanged(onDestroy, function (info) {
                vm.style.icon.fontSize = info.fontSizes.textSizeXL;
                vm.style.text.fontSize = info.fontSizes.textSize;
            })

            function setIcon(file) {
                if (file.contentType.includes('pdf')) {
                    vm.icon = 'fad fa-file-pdf';
                    vm.style.icon.color = 'red';
                } else if (file.contentType.includes('image')) {
                    vm.icon = 'fad fa-file-image';
                    vm.style.icon.color = 'blue';
                } else if (file.contentType.includes('video')) {
                    vm.icon = 'fad fa-file-video';
                    vm.style.icon.color = 'rebeccapurple';
                } else if (file.contentType.includes('audio')) {
                    vm.icon = 'fad fa-file-audio';
                } else if (file.contentType.includes('opendocument.text') || file.contentType.includes('msword') || file.contentType.includes('wordprocessing')) {
                    vm.icon = 'fad fa-file-word';
                    vm.style.icon.color = 'dodgerblue';
                } else if (file.contentType.includes('spreadsheet') || file.contentType.includes('excel')) {
                    vm.icon = 'fad fa-file-spreadsheet';
                    vm.style.icon.color = 'forestgreen';
                } else if (file.contentType.includes('presentation') || file.contentType.includes('powerpoint')) {
                    vm.icon = 'fad fa-file-powerpoint';
                    vm.style.icon.color = 'orangered';
                } else if (file.contentType.includes('csv')) {
                    vm.icon = 'fad fa-file-csv';
                } else if (file.contentType.includes('html') || file.contentType.includes('css') || file.contentType.includes('javascript') || file.contentType.includes('xml')) {
                    vm.icon = 'fad fa-file-code';
                    vm.style.icon.color = 'darkturquoise';
                } else if (file.contentType.includes('zip') || file.contentType.includes('compressed') || file.contentType.includes('x-tar') || file.contentType.includes('vnd.rar')) {
                    vm.icon = 'fad fa-file-archive';
                    vm.style.icon.color = 'goldenrod';
                }
            }

            vm.$onChanges = function (changes) {
                if (changes?.ttModel?.currentValue?.name) {
                    setIcon(changes?.ttModel?.currentValue);
                    vm.filename = changes.ttModel.currentValue.name;
                } else if (changes?.ttModel?.currentValue?.item_name) {
                    if (changes.ttModel.currentValue?.item_glyphicon) {
                        vm.icon = `fad ${changes.ttModel.currentValue.item_glyphicon.replace('fas', 'fa')}`;
                    }

                    if (changes.ttModel.currentValue?.item_glyphicon_color) {
                        vm.style.icon.color = changes.ttModel.currentValue.item_glyphicon_color;
                    }

                    if (changes.ttModel.currentValue?.item_name) {
                        vm.filename = changes.ttModel.currentValue.item_name;
                    }
                }
            }
        }]
    });
})();
