(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('hrMenu', {
        templateUrl: 'views/components/views/hrMenu/hrMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', function (stateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                name: stateService.getCurrentName()
            };

            // #endregion VARIABLES & DEFINITIONS
        }]
    });
})();