(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('poFinishProd', {
        templateUrl: 'views/components/views/poFinishProd/poFinishProd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'poFinishProdService', 'poMenuService', 'pocfService', 'poFinishCompListService', 'poFinishProdListService', function ($stateParams, stateService, modalService, translateService, poFinishProdService, poMenuService, pocfService, poFinishCompListService, poFinishProdListService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let translations = {
                pofinishprod_confirm_title: '',
                pofinishprod_confirm_message: '',
                pofinishprod_confirm_ok_label: '',
                pofinishprod_confirm_cancel_label: '',
                confirm_title: '',
                confirm_abort_production_order_finish_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                labourNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectlabourpu', { webpagename: stateService.getCurrentName() }) }
                ],
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.editProductionOrder.prod_id }) }
                ],
                suppliersBatchButtons: [
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => suppliersBatchNoChanged() }
                ],
                labourList: [],
                editProductionOrder: {},
                editFinished: {},
                editStatus: {},
                itemsListLastFinished: [],
                assigningCarrier: false,
                lockedPoFinish: false,
                lockedSave: false,
                lockedCreate: false,
                lockedPickUp: false,
                lockedAbort: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATION FUNCTION

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

		    // #endregion TRANSLATION FUNCTION

            // #region PROCEDURE CALLS
            
            let loadProductionOrder = function () {
                poMenuService.getProductionOrderMenu({ po_keyno: $stateParams.po_keyno }).then(function (info) {
                    angular.copy(info[0], vm.model.editProductionOrder);

                    if ($stateParams.p2_pofinishprod_keyno > 0) {
                        loadFinishedProductionOrder();
                    }

                    //Dersom vm.model.editProductionOrder.p2_pofinishprod_keyno > 0 skal du gå til den siden automatisk
                    //if (vm.model.editProductionOrder.p2_pofinishprod_keyno > 0) {
                    //    retrieveFinishedProductionOrder();
                    //} else if ($stateParams.p2_pofinishprod_keyno === '0') {
                    //    retrieveFinishedProductionOrder();
                    //}

                    vm.model.editProductionOrder.p2_pofinishprod_keyno = $stateParams.p2_pofinishprod_keyno;
                });
            };

            let loadFinishedProductionOrder = function () {
                poFinishProdService.getFinishedProductionOrder({ p2_pofinishprod_keyno: $stateParams.p2_pofinishprod_keyno }).then(function (info) {
                    angular.copy(info[0], vm.model.editFinished);
                    vm.model.editProductionOrder.quantity_finish_now = vm.model.editFinished.quantity;
                    vm.model.editFinished.quantity_wreck_original = info[0].quantity_wreck;
                });
            };

            let loadProductionOrderStatus = function () {
                poFinishProdService.getProductionOrderStatus({ p2_pofinishprod_keyno: $stateParams.p2_pofinishprod_keyno }).then(function (info) {
                    angular.copy(info[0], vm.model.editStatus);
                });
            };

            let loadLastFinished = function () {
                poFinishProdService.listLastFinished({ po_keyno: $stateParams.po_keyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListLastFinished);
                });
            };

            let loadLabourList = function () {
                poFinishProdService.listLabours(stateService.getCurrentName()).then(function (list) {
                    angular.copy(list, vm.model.labourList);
                });
                
            }

            // #endregion PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'pofinishprod':
                        vm.model.lockedCreate = true;

                        pocfService.createPoFinish({
                            po_keyno: $stateParams.po_keyno,
                            p2_pofinish_keyno: vm.model.editProductionOrder.p2_pofinish_keyno,
                            p2_pofinishprod_keyno: $stateParams.p2_pofinishprod_keyno,
                            quantity_finish_now: vm.model.editProductionOrder.quantity_finish_now,
                            quantity_wreck: vm.model.editFinished.quantity_wreck
                        }).then(function (response) {
                            vm.model.lockedCreate = false;

                            go({
                                po_keyno: $stateParams.po_keyno,
                                //po_keyno: response[0].p2_pofinish_keyno,
                                p2_pofinishprod_keyno: response[0].p2_pofinishprod_keyno
                            });
                        });
                        break;
                    case 'pofinishcomplist':
                        vm.model.lockedPickUp = true;

                        pocfService.createPoFinish({
                            po_keyno: $stateParams.po_keyno,
                            p2_pofinish_keyno: vm.model.editProductionOrder.p2_pofinish_keyno,
                            p2_pofinishprod_keyno: $stateParams.p2_pofinishprod_keyno,
                            quantity_finish_now: vm.model.editProductionOrder.quantity_finish_now,
                            quantity_wreck: vm.model.editFinished.quantity_wreck
                        }).then(function (response) {
                            vm.model.lockedPickUp = false;

                            go({ p2_pofinishprod_keyno: response[0].p2_pofinishprod_keyno });
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON ASSIGN PROCEDURE FUNCTION CALL

            vm.assignChanges = function () {
                vm.model.assigningCarrier = true;

                poFinishProdService.assignCarrier({ equipment_id: vm.model.editFinished.equipment_id }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.assigningCarrier = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.assigningCarrier = false;

                        loadFinishedProductionOrder();
                    }
                });
            };

            // #endregion BUTTON ASSIGN PROCEDURE FUNCTION CALL

            let retrieveFinishedProductionOrder = function () {
                pocfService.createPoFinish({
                    po_keyno: $stateParams.po_keyno,
                    p2_pofinish_keyno: vm.model.editProductionOrder.p2_pofinish_keyno,
                    p2_pofinishprod_keyno: $stateParams.p2_pofinishprod_keyno,
                    quantity_finish_now: vm.model.editProductionOrder.quantity_finish_now,
                    quantity_wreck: vm.model.editFinished.quantity_wreck
                }).then(function (response) {
                    stateService.go('pofinishprod', {
                        po_keyno: response[0].p2_pofinish_keyno,
                        p2_pofinishprod_keyno: response[0].p2_pofinishprod_keyno
                    });
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.onChange = function (key, value) {
                switch (key) {
                    case 'labour_no':
                        vm.model.lockedSave = true;
                        vm.model.editProductionOrder.labour_no = value;
                        poFinishProdService.saveLabour(value).then(function () {
                            vm.model.lockedSave = false;
                        });
                        break;
                    default:
                        break;

                }
            }

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                    pocfService.createPoFinish({
                        po_keyno: $stateParams.po_keyno,
                        p2_pofinish_keyno: vm.model.editProductionOrder.p2_pofinish_keyno,
                        p2_pofinishprod_keyno: $stateParams.p2_pofinishprod_keyno,
                        quantity_finish_now: vm.model.editProductionOrder.quantity_finish_now,
                        quantity_wreck: vm.model.editFinished.quantity_wreck
                    }).then(function () {
                        vm.model.lockedSave = false;

                        loadProductionOrderStatus();
                        loadFinishedProductionOrder();
                    });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.finishChanges = function () {
                vm.model.lockedPoFinish = true;

                poFinishCompListService.finishProductionOrder({
                    finishfromkey: 'p2_pofinishprod_keyno',
                    keyvalue: $stateParams.p2_pofinishprod_keyno,
                    from_webpagename: stateService.getCurrentName(),
                    from_parms: $stateParams.p2_pofinishprod_keyno,
                    carriers_keyno: vm.model.editFinished.carriers_keyno,
                    captaskresource_keyno: vm.model.editFinished.captaskresource_keyno,
                    suppliers_batchno: vm.model.editFinished.suppliers_batchno,
                    stocklocation_keyno: vm.model.editFinished.stocklocation_keyno
                }).then(function (response) {
                    vm.model.lockedPoFinish = false;

                    if (response[0].webpagename === 'pofinishprodpack') {
                        stateService.go(response[0].webpagename, {
                            poh_keyno_top: response[0].webpage_parms.replace('/', ''),
                            counter: 0
                        });
                    } else if (response[0].webpagename === 'createcase') {
                        stateService.go(response[0].webpagename, response[0].webpage_parms);;
                    } else {
                        stateService.back('/' + response[0].webpagename + '/' + response[0].webpage_parms);
                    }
                });
            };

            let suppliersBatchNoChanged = function () {
                modalService.confirm({
                    type: 'warning',
                    title: translations.pofinishprod_confirm_title,
                    message: translations.pofinishprod_confirm_message,
                    okLabel: translations.pofinishprod_confirm_ok_label,
                    cancelLabel: translations.pofinishprod_confirm_cancel_label
                }).then(function () {
                    poFinishProdService.getNextSupplierBatchNo(vm.model.editFinished).then(function (response) {
                        vm.model.editFinished.suppliers_batchno = response[0].suppliers_batchno;
                    });
                });
            };

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.abortValues = function () {
                vm.model.lockedAbort = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_abort_production_order_finish_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    poFinishProdListService.abortPoFinish({ p2_pofinish_keyno: vm.model.editProductionOrder.p2_pofinish_keyno || $stateParams.p2_pofinishprod_keyno }).then(function (response) {
                        vm.model.lockedAbort = false;

                        if (response[0].webpage_parms > '') {
                            stateService.go(response[0].webpagename, response[0].webpage_parms);
                        } else if (response[0].webpage_parms === '') {
                            stateService.go(response[0].webpagename);
                        }
                    }, function () {
                        vm.model.lockedAbort = false;
                    });
                }, function () {
                    vm.model.lockedAbort = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadProductionOrder();
                loadProductionOrderStatus();
                loadLastFinished();
                loadLabourList();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
