(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("consignmentPlanBuildService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadConsignmentPlanBuildGet: function (custact_no) {
				return p2DataTaskService.call(1389, {
					custact_no: custact_no
				});
			},
			loadConsignmentPlanningStockPlacesList: function () {
				return p2DataTaskService.call(453, {});
			},
			loadDeliveryMethodSelectList: function (add_all) {
				return p2DataTaskService.call(1391, {
					add_all: add_all
				});
			},
			loadTransportAreaList: function (add_all, stockplace_id) {
				return p2DataTaskService.call(1397, {
					add_all: add_all,
					stockplace_id: stockplace_id
				});
			},
			loadConsignmentPlanBuildOnList: function (custact_no_consignmentplan) {
				return p2DataTaskService.call(1414, {
					custact_no_consignmentplan: custact_no_consignmentplan
				});
			},
			loadConsignmentPlanBuildList: function (build) {
				return p2DataTaskService.call(1390, build);
			},
			loadConsignmentPlanBuildSplitList: function (build) {
				return p2DataTaskService.call(1439, build);
            },
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			save: function (custact_no_consignmentplan, records) {
				return p2DataTaskService.call(1410, {
					custact_no_consignmentplan: custact_no_consignmentplan,
					records: records
				});
			},
			remove: function (custact_no_consignmentplan, records) {
				return p2DataTaskService.call(1415, {
					custact_no_consignmentplan: custact_no_consignmentplan,
					records: records
				});
			}
		};

		return service;
	}]);
})();