(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("docArticlePorusService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadDocArticlePorusList: function (docarticle_keyno) {
				return p2DataTaskService.call(1379, {
					docarticle_keyno: docarticle_keyno
				});
			}
		};

		return service;
	}]);
})();