(function () {
    'use strict';

    var module = angular.module('imApp');

    module.directive('imBtnGroupDirective', ['$rootScope', 'layoutService', 'ttItemService', 'eventService', function ($rootScope, layoutService, ttItemService, eventService) {
        var directive = {
            restrict: 'E',
            scope: {
                label: '@?',            //Used for translation of label                                 
                plabel: '=?',           //Used instead of label when property is required
                autotranslate: '@?',    //Used if auto translation of item_name should be turned off        //Defaults to 'true', (translation is on by default)
                typevar: '@?',          //Used to add 'q' for required
                ptypevar: '=?',         //Used instead of typevar when property is required
                grpsize: '@',           //Added as class and will determine the size of the grp with ability to add one or more sizes xs, sm, md, lg. Ex col-xs-8 col-lg-4
                divider: '=',           //Used to manually set the divider
                listItems: '<',         //
                activeBtn: '<',         //Used to determin an active Button                                 //Requires an index 'Number' (can be number in string form)
                optionfunc: '&',        //Used as reference to funtion in Controller
                icons: '@',             //Used to determine the group to only contain icons, without text.  //NOT IMPLEMENTED
                ttItemId: '@'           // BJS 20210630
            },
            template:
                //'<div class="col-xs-12 sp-0" ng-class="addGroup()" style="margin-left: 0; margin-right: 0;">' +
                //    '<label ng-if="label !== undefined && label !== \'\'" class="name-label col-xs-12 col-sm-2 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}" im-translate="{{label}}" when-ready="whenReady()" wait-for-interpolation="true">' +
                //        '<label ng-if="typevar.indexOf(\'q\') > -1" class="name-label-required col-xs-12 sp-0" ng-style="{\'fontSize\': fontSizes.textSize}">*</label>' +
                //    '</label>' +
                //    '<label ng-if="plabel !== undefined && plabel !== \'\'" class="name-label col-xs-12 col-sm-2 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}" when-ready="whenReady()">'+
                //        '{{plabel}}' +
                //        '<label ng-if="typevar.indexOf(\'q\') > -1" class="name-label-required col-xs-12 sp-0" ng-style="{\'fontSize\': fontSizes.textSize}">*</label>' +
                //    '</label>' +
                //    '<div class="col-xs-12 sp-0" ng-class="addClass()" id="{{model.mId}}">' +
                //        '<div class="col-xs-12 im-no-pad" ng-repeat="line in model.lines" when-ready="whenReady()">' +
                //            '<div class="im-btn-group col-xs-12 im-no-pad" ng-repeat="item in line.list" ng-style="{\'width\': line.gwidth}" when-ready="whenReady()" wait-for-interpolation="true">' +
                //                '<button class="col-xs-12 sp-0" type="{{model.type}}" ng-class="addBtnClass($index, line.row);" ng-click="selectButton($index, line.row); itemFunc(item)" ng-style="model.buttonStyle">' +
                //                //'<button type="{{model.type}}" ng-class="addBtnClass($index, line.row);" ng-click="selectButton($index, line.row); itemFunc(item)" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                //                    '<span ng-if="autotranslate.indexOf(\'true\') > -1 || autotranslate === undefined" ng-class="activeState($index, line.row) ? \'im-active-btn-txt\' : \'spin-text\'" ng-style="{\'fontSize\': fontSizes.textSize}" im-translate="{{item.item_name}}" when-ready="whenReady()" wait-for-interpolation="true"></span>' +
                //                    '<span ng-if="autotranslate.indexOf(\'false\') > -1" ng-class="activeState($index, line.row) ? \'im-active-btn-txt\' : \'spin-text\'" ng-style="{\'fontSize\': fontSizes.textSize}" when-ready="whenReady()" wait-for-interpolation="true">{{item.item_name}}</span>' +
                //                '</button>' +
                //            '</div>' +
                //        '</div>' +
                //    '</div>' +
                //'</div>',
                `<tt-buttons-radio tt-model="activeBtn" tt-buttons="model.buttons" tt-change="activeBtnChanged($value)"></tt-buttons-radio>`,
            link: function ($scope, $element, $attr) {
                ttItemService.register($scope, $element, $attr);

                $scope.model = {
                    mId: uuid(),
                    type: 'button',
                    minHeight: '20px',
                    oldHeight: 0,
                    runByList: false,
                    heightInitialized: false,
                    activeBtn: { index: null, row: null },
                    lines: [{ list: [], gwidth: '', row: '' }],
                    buttons: [],
                    buttonStyle: {       // BJS 20210522
                        fontSize: '',
                        height: ''
                    }
                };

                $scope.whenReady = function () {
                    eventService.trigger('element:ready');
                };

                $scope.activeState = function (index, row) {
                    return $scope.model.activeBtn.index === index + hiddenItems() && $scope.model.activeBtn.row === row;
                };

                $scope.addGroup = function () {
                    var allClass = 'form-group'; //default

                    if (angular.isDefined($scope.grpsize)) {
                        allClass += ' ' + $scope.grpsize;
                    }

                    return allClass;
                };

                $scope.addClass = function () {
                    var allClass = 'btn-group col-xs-12 im-no-pad'; //default

                    if ((angular.isDefined($scope.label) && $scope.label !== '') || (angular.isDefined($scope.plabel) && $scope.plabel !== '')) {
                        allClass = 'btn-group col-xs-12 col-sm-10 im-no-pad';
                    }

                    return allClass;
                };

                $scope.addBtnClass = function (index, row) {
                    var allClass = 'btn btn-block btn-lg im-btn'; //default

                    if (angular.isDefined($scope.btnstyle) && $scope.btnstyle !== null) {
                        allClass = 'btn ' + $scope.btnstyle + ' btn-block btn-lg im-btn';
                    }

                    if (index === 0) {
                        allClass += ' im-group-btn-1';
                    } else if (index === ($scope.model.lines[row].list.length - 1)) {
                        allClass += ' im-group-btn-3';
                    } else {
                        allClass += ' im-group-btn-2';
                    }

                    if ($scope.model.activeBtn.index === index + hiddenItems() && $scope.model.activeBtn.row === row) {
                        allClass += ' im-active-btn';
                    }

                    return allClass;
                };

                $scope.selectButton = function (index, row) {
                    $scope.model.activeBtn = { index: index + hiddenItems(), row: row };
                };

                $scope.itemFunc = function (item) {
                    //console.dir(item);
                    $scope.optionfunc({ item: item.item_func });
                };

                $scope.fontSizes = {
                    textSize: '',
                    textSizeS: '',
                    textSizeSs: ''
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    angular.copy(info.fontSizes, $scope.fontSizes);

                    $scope.model.fontSize = info.fontSizes.textSize;

                    $scope.model.buttonStyle.fontSize = info.fontSizes.textSize;
                    $scope.model.buttonStyle.height = info.height + 'px';
                    $scope.model.buttonStyle.padding = '0px 5px'
                });

                var isNumber = function (n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                };

                var hiddenItems = function () {
                    var hiddenItems = 0;
                    var d = angular.isNumber($scope.activeBtn) ? $scope.activeBtn : parseInt($scope.activeBtn);
                    for (var h = 0; h < $scope.listItems.length; h++) {
                        if (angular.isDefined($scope.listItems[h]) && angular.isDefined($scope.listItems[h].hidden) && $scope.listItems[h].hidden === true && h <= d) {
                            hiddenItems++;
                        }
                        if (h >= d) {
                            break;
                        }
                    }

                    return hiddenItems;
                };

                $scope.activeBtnChanged = function (value) {
                    $scope.activeBtn = value;
                    $scope.optionfunc({ item: value });
                }

                // BJS 20190127 - Moved this code out to separate function because same code was used twice.
                //                Also modified so $scope.model.activeBnt.index is an int not a string.
                var activeIndexChanged = function () {
                    // BJS 20180524 - Endret litt for å kunne støtte integer som activeBtn
                    if (angular.isUndefined($scope.activeBtn)) return;
                    if ($scope.activeBtn === null) return;
                    if ($scope.activeBtn === '') return;

                    $scope.model.activeBtn.index = angular.isNumber($scope.activeBtn) ? $scope.activeBtn : parseInt($scope.activeBtn);

                    var divider = $scope.listItems.length < 7 ? 3 : 4;
                    var groupNum = Math.ceil($scope.listItems.length / divider);
                    var inUse = 0;

                    for (var g = 0; g < groupNum; g++) {
                        for (var ii = 0; ii < divider; ii++) {
                            if (inUse === $scope.model.activeBtn.index) {
                                inUse++;
                                $scope.model.activeBtn.row = g;
                                break;
                            } else {
                                inUse++;
                            }
                        }
                    }

                    if (angular.isDefined($scope.listItems[$scope.model.activeBtn.index]) && angular.isDefined($scope.listItems[$scope.model.activeBtn.index].hidden) && $scope.listItems[$scope.model.activeBtn.index].hidden === true) return;

                    if (angular.isDefined($scope.listItems[$scope.model.activeBtn.index])) {
                        $scope.optionfunc({ item: $scope.listItems[$scope.model.activeBtn.index].item_func });
                    }
                };

                //$scope.$watch(function () {
                //    return $scope.ptypevar;
                //}, function () {
                //    if (angular.isUndefined($scope.typevar) && angular.isDefined($scope.ptypevar))
                //        $scope.typevar = $scope.ptypevar.toString();
                //});

                $scope.$watch(function () {
                    return $scope.activeBtn;
                }, function () {
                    if ($scope.activeBtn) {
                        //if (vm.model.runByList !== false) return;
                        let active = $scope.listItems.find((item) => item.item_id === $scope.activeBtn);

                        if (active && active?.item_func) {
                            $scope.activeBtnChanged(active.item_func);
                        }
                    }

                    //if ($scope.model.runByList !== false) return;

                    //activeIndexChanged();
                });

                $scope.$watch(function () {
                    return $scope.listItems;
                }, function () {
                    if (angular.isDefined($scope.listItems) && $scope.listItems.length > 0) {

                        $scope.model.buttons = $scope.listItems.map((button) => {
                            return { id: button.item_func, label: button.item_name };
                        })

                        if ($scope.model.buttons && !$scope.activeBtn) {
                            $scope.activeBtnChanged($scope.model.buttons[0].id);
                        }

                        //var dividerSmall = angular.isDefined($scope.divider) && isNumber($scope.divider) ? $scope.divider : 3;
                        //var dividerLarge = angular.isDefined($scope.divider) && isNumber($scope.divider) ? $scope.divider : 4;

                        //var divider = $scope.listItems.length < 7 ? dividerSmall : dividerLarge;

                        //var hiddenItems = 0;
                        //angular.forEach($scope.listItems, function (data) {
                        //    if (angular.isDefined(data) && angular.isDefined(data.hidden) && data.hidden === true) {
                        //        hiddenItems++;
                        //    }
                        //});

                        //var groupNum = Math.ceil(($scope.listItems.length - hiddenItems) / divider);
                        //var inUse = 0;

                        //for (var g = 0; g < groupNum; g++) {
                        //    var tempList = [];

                        //    for (var i = 0; i < divider; i++) {
                        //        if (inUse >= $scope.listItems.length) {
                        //            break;
                        //        }

                        //        if (angular.isUndefined($scope.listItems[inUse].hidden) || $scope.listItems[inUse].hidden !== true) {
                        //            tempList.push($scope.listItems[inUse]);
                        //        }

                        //        inUse++;
                        //    }

                        //    var gw = (100 / tempList.length).toString() + '%';
                        //    $scope.model.lines[g] = { list: tempList, gwidth: gw, row: g };
                        //}
                    }

                    //$scope.model.runByList = true;

                    //activeIndexChanged();

                    //$scope.model.runByList = false;
                }, true);
            }
        };

        return directive;
    }]);
})();
