(function() {
    'use strict';

	angular.module("imApp").factory("stockPlanningService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadProducts: function (parms) {
				return p2DataTaskService.call(2001, parms);
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			updateColumnResize: function (parms) {
				return p2DataTaskService.call(1457, parms);
			},
			createPurchase: function (parms) {
				console.log('Saving purchase: ');
				console.dir(parms);
				return p2DataTaskService.call(1650, parms);
			},
			loadPageSettings: function () {
				return p2DataTaskService.call(1651, {});
			},
			savePageSetting: function (parms) {
				return p2DataTaskService.call(616, parms);
			}
		};

        return service;
    }]);
})();
