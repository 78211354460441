(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("htmlReportService", ['$ihttp', function ($ihttp) {
        let service = {
            getReport: function (htmlreport_keyno, parms) {
                return $ihttp.post({
                    method: 3362,
                    parameters: {
                        htmlreport_keyno: htmlreport_keyno,
                        jsonwhere: parms ?? {}
                    }
                });
            },
            getHtmlReportHtml: function (parms) {
                return $ihttp.post({
                    method: 3370,
                    parameters: parms
                });
            },
            listWebPages: function (parms) {
                return $ihttp.post({
                    method: 4,
                    parameters: parms || {}
                });
            },
            loadHtmlProcList: function (htmlreport_keyno) {
                return $ihttp.post({
                    method: 3389,
                    parameters: {
                        htmlreport_keyno: htmlreport_keyno
                    }
                });
            },
            saveReport: function (htmlReport) {
                return $ihttp.post({
                    method: 3363,
                    parameters: htmlReport
                });
            },
            duplicateReport: function (htmlreport_keyno) {
                return $ihttp.post({
                    method: 3402,
                    parameters: {
                        htmlreport_keyno: htmlreport_keyno
                    }
                });
            },
            getList: function (parms) {
                return $ihttp.post({
                    method: 3385,
                    parameters: parms || {}
                });
            },
            deleteReport: function (htmlreport_keyno) {
                return $ihttp.post({
                    method: 3364,
                    parameters: {
                        htmlreport_keyno: htmlreport_keyno
                    }
                });
            }
        };

        return service;
    }]);
})();
