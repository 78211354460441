(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2SettingEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_setting_edit: {
                p2_setting_keyno: '',
                word_id: '',
                input_type: '',
                default_value: '',
                choicedata: '',
                setting_name: ''
            },
            loadP2SettingEdit: function (p2_setting_keyno) {
                var deferred = $q.defer();

                var parms_p2setting_edit = {
                    method: 7,
                    parameters: {
                        p2_setting_keyno: p2_setting_keyno || '0'
                    }
                };

                $ihttp.post(parms_p2setting_edit).then(function (data) {
                    service.p2_setting_edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2SettingEdit: function () {
                var deferred = $q.defer();

                var parms_p2setting_save = {
                    method: 8,
                    parameters: {
                        p2_setting_keyno: service.p2_setting_edit.p2_setting_keyno,
                        word_id: service.p2_setting_edit.word_id,
                        input_type: service.p2_setting_edit.input_type,
                        default_value: service.p2_setting_edit.default_value,
                        choicedata: service.p2_setting_edit.choicedata
                    }
                };

                $ihttp.post(parms_p2setting_save).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();