/// <reference path="../services/userService.js" />
(function () {
    'use strict';

    angular.module('imApp')
        .directive('imButtonDirective', ['$timeout', 'utilityService', 'stateService', 'layoutService', 'ttItemService', 'eventService', function ($timeout, us, stateService, layoutService, ttItemService, eventService) {
            var directive = {
                restrict: 'E',
                priority: 1000,
                scope: {
                    name: '@',          //The text on the button
                    pname: '=',         //The text on the button, by reference will not translate
                    lname: '@',         //The text on the locked-button if not default
                    type: '@',          //Changes the type
                    badge: '=',         //If it has value, creates a badge behind "name" displaying a value
                    btnsize: '@',       //Added as class and will determine the size of the btn with ability to add one or more sizes xs, sm, md, lg. Ex col-xs-8 col-lg-4
                    glyphs: '@',        //Added as class and will determine the glyphicons to be used, also need pull-left or pull-right for corresponding alignment
                    glyphpos: '@',      //Accepts left if the glyphs should be at the left
                    optionfunc: '&',    //Used as reference to function in Controller
                    btnstyle: '@',      //Used to add a different style to the button
                    locked: '=',        //Adds a state where the button is locked //Accepts "0" or "1"
                    group: '@',         //Adds a state where the button is supposed to be part of a group
                    setting: '=',       //Adds a state where the button is a settings button
                    spin: '=',          //Adds a state where the refresh glyphicon will spin
                    ttItemId: '@',      // BJS 20210630
                    ttTextAlign: '@'    // BJS 20210707
                },
                template:
                    '<div ng-class="addGroup()" id="{{model.mId}}" ng-style="model.divButtonStyle">' +
                        '<div ng-show="!showSetting() && setting===undefined" ng-class="addClass()">' +
                            '<div ng-if="model.locked !== true" class="col-xs-12 sp-0">' +
                                '<button ng-if="model.locked !== true" ng-click="goTo()" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding: 5px;" ng-style="model.buttonStyle">' +
                                    '<span ng-if="glyphpos === \'left\'" ng-class="addGlyph()"></span>' +
                                    //'<span ng-if="glyphpos === \'left\'"  ng-style="model.buttonStyle"> </span>' +
                                    '<span ng-if="name !== undefined" im-translate="{{name}}"></span>' +
                                    '<span ng-if="pname !== undefined"  ng-style="model.buttonStyle">{{pname}}</span>' +
                                    '<span ng-if="badge !== undefined && badge !== \'\'"  ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span class="badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{badge}}</span>' +
                                    '<span ng-if="glyphpos !== \'left\' && glyphs !== undefined"  ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span ng-if="glyphpos !== \'left\'" ng-class="addGlyph()"></span>' +
                                '</button>' +
                            '</div>' +
                            '<div ng-if="model.locked === true" class="col-xs-12 sp-0">' +
                                '<button ng-if="model.locked === true" type="submit" class="btn btn-success btn-lg disabled col-xs-12" ng-style="model.lockedButtonStyle">' +
                                    '<span id="spin_text" ng-style="model.buttonStyle">' +
                                        '<i class="glyphicon glyphicon-lock" ng-style="model.buttonStyle"></i> ' +
                                        '<span ng-if="lname !== undefined" ng-style="model.buttonStyle" im-translate="{{lname}}"></span>' +
                                        '<span ng-if="lname === undefined" ng-style="model.buttonStyle" im-translate="locked"></span>' +
                                    '</span>' +
                                '</button>' +
                            '</div>' +
                        '</div>' +
                            '<div ng-show="showSetting()" ng-class="addClass()" when-ready="whenReady()" wait-for-interpolation="true">' +
                                '<button ng-click="goToWebpageSettingMenu()" type="{{model.type}}" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding: 5px;" ng-style="model.buttonStyle">' +
                                    '<span ng-if="glyphpos === \'left\'" ng-class="addGlyph()"></span>' +
                                    '<span ng-if="glyphpos === \'left\'"  ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span ng-if="name !== undefined"  ng-style="model.buttonStyle" im-translate="{{name}}"></span>' +
                                    '<span ng-if="pname !== undefined"  ng-style="model.buttonStyle">{{pname}}</span>' +
                                    '<span ng-if="badge !== undefined && badge !== \'\'"  ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span class="badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{badge}}</span>' +
                                    '<span ng-if="glyphpos !== \'left\' && glyphs !== undefined"  ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span ng-if="glyphpos !== \'left\'" ng-class="addGlyph()"></span>' +
                                '</button>' +
                            '</div>' +
                        '</div>' +
                    '</div>',
                link: function ($scope, $element, $attr) {
                    ttItemService.register($scope, $element, $attr);

                    $scope.model = {
                        mId: uuid(),
                        name: '',
                        badge: '',
                        type: 'button',
                        textalign: 'left',
                        locked: false,
                        setting: false,
                        spin: false,
                        divButtonStyle: {},
                        lockedButtonStyle: {},
                        buttonStyle: {}
                    };

                    $timeout(function () {
                        if (angular.isDefined($scope.type) && $scope.type !== null) {
                            $scope.model.type = $scope.type;
                        } else {
                            $scope.model.type = 'button';
                        }
                    }, 0);

                    $scope.whenReady = function () {
                        eventService.trigger('element:ready');
                    };

                    var isStringValue = function (id) {
                        if (angular.isString($scope[id]) !== true)
                            return false;
                        if ($scope[id] === null)
                            return false;
                        if ($scope[id].length < 1)
                            return false;

                        return true;
                    };

                    var hasValidTextAlign = function () {
                        if (isStringValue('ttTextAlign') !== true)
                            return false;
                        if ($scope.ttTextAlign !== 'center' && $scope.ttTextAlign !== 'left' && $scope.ttTextAlign !== 'right' && $scope.ttTextAlign !== 'justify')
                            return false;

                        return true;
                    };

                    // BJS 20210707
                    if (hasValidTextAlign()) {
                        $scope.model.textalign = $scope.ttTextAlign;
                    } else {
                        if (isStringValue('btnstyle') === true) {
                            $scope.model.textalign = 'center';
                        } else {
                            $scope.model.textalign = 'left';
                        }
                    }

                    $scope.addGroup = function () {
                        var allClass = 'form-group im-no-mar'; //default

                        if (angular.isDefined($scope.group)) {
                            allClass = $scope.group;
                        }

                        return allClass;
                    };

                    $scope.addClass = function () {
                        var allClass = 'col-lg-3 col-md-4 col-sm-6 col-xs-12 im-no-pad'; //default

                        if (angular.isDefined($scope.btnsize) && $scope.btnsize !== null) {
                            allClass = $scope.btnsize;
                        }

                        return allClass;
                    };

                    $scope.displayNewGotoButton = function () {
                        return $scope.model.textalign === 'left' && $scope.btnstyle !== 'btn-warning' && $scope.btnstyle !== 'btn-danger' && $scope.btnstyle !== 'btn-success' && $scope.model.setting !== true;
                    }

                    $scope.addBtnClass = function () {
                        if ($scope.displayNewGotoButton() === false) {

                            var allClass = 'btn btn-primary btn-block btn-lg im-btn'; //default

                            if (angular.isDefined($scope.btnstyle) && $scope.btnstyle !== null) {
                                const type = $scope.btnstyle.split('-')[$scope.btnstyle.split('-').length - 1];
                                allClass = 'col-xs-12 sp-0 tt-button tt-button--' + type;
                                //allClass = 'btn ' + $scope.btnstyle + ' btn-block btn-lg im-btn';
                                if ($scope.showSetting() === true) {
                                    allClass = 'col-xs-12 sp-0 tt-button tt-button--warning';
                                }
                            } else if ($scope.showSetting() === true) {
                                allClass = 'col-xs-12 sp-0 tt-button tt-button--warning';
                            }

                            return allClass;
                        } else {
                            $scope.glyphpos = 'left';
                            return 'col-xs-12 sp-0 tt-button tt-button--link';
                        }
                    };

                    $scope.goTo = function () {
                        $scope.optionfunc();
                    };

                    $scope.goToWebpageSettingMenu = function () {
                        stateService.go('webpagesettingmenu', {
                            webpage_name: stateService.getCurrentName()
                        });
                    };

                    $scope.showSetting = function () {
                        if (($scope.getSetting.showSettings === '1' || $scope.getSetting.showSettings === true) && ($scope.model.setting === '1' || $scope.model.setting === true)) {
                            return true;
                        } else {
                            return false;
                        }
                    };

                    $scope.addGlyph = function () {
                        var allClass = 'far fa-chevron-right tt-button__icon--pull-right'; //default

                        if ($scope.displayNewGotoButton() === false) {
                            if (angular.isDefined($scope.glyphs) && $scope.glyphs !== null) {
                                if ($scope.glyphs === 'glyphicon-chevron-right') {
                                    allClass = 'fa-fw far fa-arrow-right';
                                } else {
                                    allClass = 'glyphicon ' + $scope.glyphs;
                                }

                                if ($scope.model.spin === true) {
                                    allClass += ' glyphicon-refresh-animate';
                                }
                            }

                            return allClass;
                        } else {
                            if ($scope.glyphs?.includes('glyphicon-plus')) {
                                return 'fa-fw far fa-plus';
                            } else if ($scope.glyphs?.includes('glyphicon-chevron-right')) {
                                return 'fa-fw far fa-arrow-right';
                            } else {
                                return $scope.glyphs !== undefined ? 'glyphicon ' + $scope.glyphs : 'fa-fw far fa-arrow-right';
                            }
                        }
                    };

                    $scope.fontSizes = {
                        textSize: '',
                        textSizeS: '',
                        textSizeSs: ''
                    };

                    $scope.getSetting = {
                        showSettings: ''
                    };

                    layoutService.onLayoutChanged($scope, function (info) {
                        if (angular.isUndefined(info)) return;

                        $scope.getSetting.showSettings = info.showSettings;

                        angular.copy(info.fontSizes, $scope.fontSizes);

                        $scope.model.buttonStyle.fontSize = info.fontSizes.textSize;
                        $scope.model.buttonStyle.height = info.height + 'px';
                        $scope.model.buttonStyle.padding = '0px 5px'

                        angular.copy($scope.model.buttonStyle, $scope.model.lockedButtonStyle);

                        $scope.model.lockedButtonStyle.padding = '5px';
                        $scope.model.lockedButtonStyle.color = 'white';

                        $scope.model.divButtonStyle.marginBottom = info.margin.bottom + 'px';
                    });

                    $scope.$watch(function () {
                        return $scope.locked;
                    }, function () {
                        $scope.model.locked = us.toBoolean($scope.locked, false);
                    });

                    $scope.$watch(function () {
                        return $scope.spin;
                    }, function () {
                        if (angular.isDefined($scope.spin) && $scope.spin !== null) {
                            $scope.model.spin = $scope.spin;
                        }
                    });

                    $scope.$watch(function () {
                        return $scope.setting;
                    }, function () {
                        if (angular.isDefined($scope.setting) && $scope.setting !== null) {
                            $scope.model.setting = $scope.setting;
                        }
                    });
                }
            };

            return directive;
        }]);
})();
