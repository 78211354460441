(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("emailAddService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadEmailAddGet: function (argtype, argvalue, functionname) {
                return p2DataTaskService.call(1118, {
                    argtype: argtype,
                    argvalue: argvalue,
                    functionname: functionname
                });
            },
            loadEmailAddSuggestionList: function (getEmailAdd) {
                return p2DataTaskService.call(1119, getEmailAdd);
            },
            loadEmailAddSearchList: function (getEmailAdd) {
                return p2DataTaskService.call(1120, getEmailAdd);
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            }
        };

        return service;
    }]);
})();