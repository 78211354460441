(function () {
    'use strict';

    angular.module("imApp").factory("powTimesService", ['$ihttp', function ($ihttp) {
        let service = {
            getPowTimes: function (parms) {
                return $ihttp.post({
                    method: 2126,
                    parameters: parms || {}
                });
            },
            listLabours: function (parms) {
                return $ihttp.post({
                    method: 1531,
                    parameters: parms || {}
                });
            },
            createFromOffDutyCode: function (parms) {
                return $ihttp.post({
                    method: 2152,
                    parameters: parms
                });
            }
        };

        return service;
    }]);
})();
