import { Component } from '@angular/core';
import { Grid } from '../../../core/components/grid/grid.model';
import { GridOptions } from '../../../core/components/grid/grid.types';
import { StateService } from '../../../core/services/state.service';

@Component({
  selector: 'tt-my-activities',
  templateUrl: './my-activities.component.html',
  styleUrls: ['./my-activities.component.css']
})
export class MyActivitiesComponent {
    model =  {
        rememberId: 'myactivities_grid',
        loadData: {
            method: 2866,
            parameters: null
        }
    };

    grid?: GridOptions;

    constructor(private state: StateService) {
        this.grid = new Grid()
            .setLoadDataFunction({
                method: this.model.loadData.method, parameters: this.model.loadData.parameters
            })
            .setRememberId(this.model.rememberId)
            .setCustomToolbarButtons([
                {
                    name: 'newActivity',
                    text: 'new_activity',
                    func: () => this.navigateToNewActivity(),
                    icon: 'fas fa-plus',
                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                    translate: true,
                    disabled: undefined,
                },
                {
                    name: 'refreshGrid',
                    text: 'refresh',
                    func: () => this.refreshGrid(),
                    icon: 'fas fa-sync',
                    cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                    translate: true,
                    disabled: undefined,
                }
            ]);
    }

    private refreshGrid() {
        return this.grid?.gridfunc?.rebind();
    }

    private navigateToNewActivity() {
        return this.state.go('custactedit', {
            custact_no: '0',
            filterfield: '-',
            filtervalue: '-'
        });
    }
}
