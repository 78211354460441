(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('p2ImportProcs', {
        templateUrl: 'views/components/views/p2ImportProcs/p2ImportProcs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'p2ImportProcsService', function (stateService, p2ImportProcsService) {
            var vm = this;
            
            vm.model = {
                itemsListImportProcedures: []
            };

            vm.model.edit = {
                show_in_excelimport: 'A'
            };

            p2ImportProcsService.listImportProcedures(vm.model.edit).then(function (result) {
                angular.copy(result, vm.model.itemsListImportProcedures);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'p2importproc':
                        stateService.go(state, {
                            p2_importproc_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();
