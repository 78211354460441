(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockTransferService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
			loadStockTransferGet: function (stocktransfer_keyno) {
				return p2DataTaskService.call(1035, {
					stocktransfer_keyno: stocktransfer_keyno
				});
            },
            loadStockTransferLoadedList: function (getStockTransfer) {
                return p2DataTaskService.call(1041, getStockTransfer);
            },
            loadStockTransferUnloadedList: function (getStockTransfer) {
                return p2DataTaskService.call(1042, getStockTransfer);
            },
            validateStockTransferGet: function (stockTransferGet) {
                stockTransferGet.stocklocation_name_trolley = stockTransferGet.stocklocation_name_trolley || '';
                stockTransferGet.reg_by_portal_user_name = stockTransferGet.reg_by_portal_user_name || '';
                stockTransferGet.reg_datetime = stockTransferGet.reg_datetime || '';

                return stockTransferGet;
            },
            finishedStockTransferUnload: function (getStockTransfer) {
                return p2DataTaskService.call(1063, getStockTransfer);
            },
            allStockTransferUnload: function (getStockTransfer) {
                return p2DataTaskService.call(1064, getStockTransfer);
            },
            deleteItem: function (setting) {
                return $ihttp.post({
                    method: 2178,
                    parameters: setting
                });
            },
            printConsignmentPackingLabelTrolley(stocktransfer_keyno, p2_printer_keyno) {
                return $ihttp.post({
                    method: 3146,
                    parameters: {
                        stocktransfer_keyno: stocktransfer_keyno,
                        p2_printer_keyno: p2_printer_keyno
                    }
                });
            }
        };

        return service;
    }]);
})();
