(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ediSourcesService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2EdiSourceList: function () {
                return p2DataTaskService.call(978, {});
			}
        };

        return service;
    }]);
})();