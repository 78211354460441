(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ftpAccountsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2FtpAccountList: function () {
				return p2DataTaskService.call(968, {});
			}
        };

        return service;
    }]);
})();