(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('seasonality', {
        templateUrl: 'views/components/views/seasonality/seasonality.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'seasonalityService', function ($stateParams, $q, stateService, utilityService, seasonalityService) {
            const vm = this;
            let seasonalityKeyno = $stateParams.seasonality_keyno;
    		
            vm.model = {
                edit: {},
                inputListMonths: []
            };
            
            let loadEdit = function () {
                let deferred = $q.defer();

                seasonalityService.getEdit({ seasonality_keyno: seasonalityKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadMonths()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadMonths = function () {
                if (utilityService.validateParmsValue(vm.model.edit.seasonality_keyno) !== true) return;

                return seasonalityService.listMonths({ seasonality_keyno: vm.model.edit.seasonality_keyno }).then(function (result) {
                    angular.copy(result, vm.model.inputListMonths);
                });
            };
            
            let recalcTotal = function () {
                var total = 0;
                
                angular.forEach(vm.model.inputListMonths, function (item) {
                    total += utilityService.parseNumber(item.pst);
                });

                vm.model.edit.total = total;
            };
            
            vm.saveChanges = function () {
                var saveObject = vm.model.edit;
                saveObject.records = vm.model.inputListMonths;

                seasonalityService.saveObj(saveObject).then(function () {
                    stateService.back();
                });
            };

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlurMonths = function (item) {
                if (utilityService.validateParmsValue(item.pst, true) !== true) return;

                recalcTotal();
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
