(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("stockTransAllocationsService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1868,
                    parameters: parms || {}
                });
            },
            listAllocations: function (parms) {
                return $ihttp.post({
                    method: 1870,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
