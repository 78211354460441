(function () {
	'use strict';
	var module = angular.module("imApp");

	module.factory("economyService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
		var service = {
            getCustomerActivity: function (parms) {
                return $ihttp.post({
                    method: 28,
                    parameters: parms || {}
                });
            },
            listProjectProcesses: function (parms) {
                return $ihttp.post({
                    method: 47,
                    parameters: parms || {}
                });
            },
			loadActivityTypeList: function (onweb, extra_record_for_all) {
				return p2DataTaskService.call(49, {
					onweb: onweb || '',
					extra_record_for_all: extra_record_for_all || ''
				});
			},
            listActivitityTypes: function (parms) {
                return $ihttp.post({
					method: 49,
                    parameters: parms || {}
                });
            },
            listActivityStatuses: function (parms) {
                return $ihttp.post({
					method: 50,
                    parameters: parms || {}
                });
            },
			getCustomerEdit: function (parms) {
				return $ihttp.post({
					method: 67,
					parameters: parms || {}
				});
			},
			getCustomer: function (parms) {
				return $ihttp.post({
					method: 68,
                    parameters: parms || {} // cust_no
				});
            },
            listCustomerGroups: function (parms) {
                return $ihttp.post({
                    method: 69,
                    parameters: parms || {}
                });
            },
            listCustomerPersons: function (parms) {
				return $ihttp.post({
					method: 71,
                    parameters: parms || {} // cust_no
				});
			},
            listLabours: function (parms) {
            	return $ihttp.post({
            		method: 101,
                    parameters: parms || {} // activestatus
            	});
            },
			listValutas: function () {
				return p2DataTaskService.call(373, {});
            },
            listCurrencies: function (parms) {
                return $ihttp.post({
                    method: 373,
                    parameters: parms || {}
                });
            },
            listSalaryTypes: function (parms) {
                return $ihttp.post({
                    method: 481,
                    parameters: parms || {}
                });
            },
			listOffDutyCodes: function (parms) {
				return $ihttp.post({
					method: 488,
					parameters: parms || {}
				});
			},
			listShifts: function (parms) { // only_active
				return $ihttp.post({
					method: 516,
					parameters: parms || {}
				});
			},
			listPayments: function (parms) {
				return $ihttp.post({
					method: 624,
					parameters: parms || {}
				});
			},
			listCustFactorings: function () {
				return $ihttp.post({
					method: 625,
					parameters: {}
				});
			},
			listPrices: function (parms) {
				return $ihttp.post({
					method: 627,
					parameters: parms || {}
				});
			},
			listTaxes: function (parms) {
				return $ihttp.post({
					method: 640,
					parameters: parms || {}
				});
			},
			getPriceList: function (parms) {
				return $ihttp.post({
					method: 650,
					parameters: parms || {}
				});
			},
            listSellers: function (parms) {
				return $ihttp.post({
					method: 628,
                    parameters: parms || {}
				});
			},
            getTax: function (parms) {
                return $ihttp.post({
					method: 732,
                    parameters: parms || {}
                });
            },
            listRoundOff: function (parms) {
                return $ihttp.post({
                    method: 742,
                    parameters: parms || {}
                });
			},
            listPriceMethods: function (parms) {
                return $ihttp.post({
					method: 823,
                    parameters: parms || {}
                });
            },
            listSelectProjects: function (parms) {
                return $ihttp.post({
                    method: 1108,
                    parameters: parms || {}
                });
            },
			listBusinessSites: function (parms) {
				return $ihttp.post({
					method: 1655,
					parameters: parms || {}
				});
			}
		};

		return service;
	}]);
})();
