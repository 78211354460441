(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("custactProcessService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadCustactProcessList: function (custact_no) {
                return p2DataTaskService.call(1855, {
                    custact_no: custact_no
                });
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1856, mergeObj);
            }
        };

        return service;
    }]);
})();