(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poFinishCompListService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            selectStockList: [],
            p2PoFinishProdCompList: {
                item_id: '',
                item_name: '',
                item_path: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                locked: '',
                records: []
            },
            p2_pofinishprod_status: {
                finishlocked: '',
                locked: ''
            },
            p2_pofinish_finish: {
                webpagename: '',
                webpage_parms: ''
            },
            loadPoFinishCompListGet: function (p2_pofinishprod_keyno) {
                return p2DataTaskService.call(1298, {
                    p2_pofinishprod_keyno: p2_pofinishprod_keyno
                });
            },
            search: function (productsearch_searchtext, functionname, stockplace_id) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 462, parameters: {
                        productsearch_searchtext: productsearch_searchtext,
                        functionname: functionname,
                        stockplace_id: stockplace_id
                    }
                }).then(function (data) {
                    angular.copy(data, service.selectStockList);

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadP2PoFinishProdCompList: function (p2_pofinishprod_keyno, selector) {
                var deferred = $q.defer();

                var parmsP2PoFinishProdCompList = {
                    method: 166,
                    parameters: {
                        p2_pofinishprod_keyno: p2_pofinishprod_keyno,
                        selector: selector
                    }
                };

                $ihttp.post(parmsP2PoFinishProdCompList).then(function (data) {
                    service.p2PoFinishProdCompList.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2PoFinishProdCompList.records.push(data[i]);
                        service.p2PoFinishProdCompList.records[i].item_test = '0/5';
                        // 2024-06-01 EO: Commented out these. What use did they have?
                        //if (i !== 2 && i !== 3 && i !== 6 && i !== 7)
                        //    service.p2PoFinishProdCompList.records[i].item_name_sub1 = 'Undertext';
                        //if (i !== 4 && i !== 5 && i !== 6 && i !== 7)
                        //    service.p2PoFinishProdCompList.records[i].item_name_sub2 = 'Verdi';
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2PoFinishProdStatus: function (p2_pofinishprod_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinishprod_status = {
                    method: 176,
                    parameters: {
                        p2_pofinishprod_keyno: p2_pofinishprod_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinishprod_status).then(function (data) {
                    service.p2_pofinishprod_status = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            searchP2PoFinishProdComp: function (scantotype, scantokey, scanvalue) {
                var deferred = $q.defer();

                var parms_p2_pofinishprodcomp_scan = {
                    method: 171,
                    parameters: {
                        scantotype: scantotype,
                        scantokey: scantokey,
                        scanvalue: scanvalue
                    }
                };

                $ihttp.post(parms_p2_pofinishprodcomp_scan).then(function (data) {
                    service.p2_pofinishprodcomp_scan = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            finishProductionOrder: function (setting) {
                return $ihttp.post({
                    method: 197,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();
