(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentsService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            p2ConsignmentList: {
                records: []
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };
                
                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            loadStockTransferCreateTrolleySelectList: function (stockplace_id, addall) {
                return p2DataTaskService.call(1058, {
                    stockplace_id: stockplace_id,
                    addall: addall
                });
            },
            loadConsignmentsBusinessCoNoChanged: function (businessco_no_from) {
                return p2DataTaskService.call(1090, {
                    businessco_no_from: businessco_no_from
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            loadConsignmentShipmentSelectList: function (stockplace_id, add_all, add_none) {
                return p2DataTaskService.call(1107, {
                    stockplace_id: stockplace_id,
                    add_all: add_all,
                    add_none: add_none
                });
            }
        };

        return service;
    }]);
})();