(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2ImportProcsService", ['$ihttp', function ($ihttp) {
        var service = {
            listImportProcedures: function (parms, config) {
                return $ihttp.post({
                    method: 877,
                    parameters: parms || {},
                    config: config || {}
                });
            }
        };

        return service;
    }]);
})();
