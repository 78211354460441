(function() {
    'use strict';

    angular.module("imApp").factory("bonusTypesOverviewService", ['$ihttp', function ($ihttp) {
        let call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        let utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        let service = {
            getCustomersKeynameList: function (parameters) {
                //return call(-1, parameters || {});
                return [];
            },
            getOrdersKeynameList: function (parameters) {
                //return call(-1, parameters || {});
                return [];
            },
            remember: function (method, rememberId, value, isBase) {
                return call(angular.isDefined(method) && method !== null ? method : 616, { // for use if need to have a different method
                    is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (method, rememberId) {
                return call(angular.isDefined(method) && method !== null ? method : 973, { variablename: rememberId }); // for use if need to have a different method
            }
        }

        return service;
    }]);
})();
