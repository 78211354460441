(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderHeadCreate', {
        templateUrl: 'views/components/views/orderHeadCreate/orderHeadCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'stateService', 'modalService', 'translateService', 'rememberService', 'orderHeadCreateService', 'logisticService', 'economyService', 'typeaheadService', function ($stateParams, utilityService, stateService, modalService, translateService, rememberService, orderHeadCreateService, logisticService, economyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let orderTypeId = $stateParams.ordertype_id;

            let variableNames = {
                new_customer_in_progress: '',
                ordertype_id: '',
                stockplace_id: ''
            };

            let translations = {
                warning: '',
                ok: ''
            };

            vm.model = {
                statCustNoButtons: [
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => { rememberFunc('new_customer_in_progress'); stateService.go('customeredit', { cust_no: '0' }); } }
                ],
                statCustNoButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('stat_cust_no') },
                    { id: 'new', icon: 'fa fa-solid fa-plus', color: 'primary', type: 'primary', onClick: () => { rememberFunc('new_customer_in_progress'); stateService.go('customeredit', { cust_no: '0' }); } }
                ],
                sellerButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.seller_no = '0' }
                ],
                btnListServiceObjects: [{
                    id: 'list', icon: 'fa fa-solid fa-bars', type: 'primary', onClick: () => {
                        stateService.go('serviceobjects', {
                            argtype: '-',
                            argvalue: '-'
                        });
                    }
                }],
                edit: {},
                selectListOrderTypes: [],
                selectListCompanies: [],
                selectListStockPlaces: [],
                selectListProjects: [],
                selectListDeliveryCustomers: [],
                selectListCustomerPersons: [],
                selectListCoops: [],
                selectListBusinesses: [],
                selectListDepartments: [],
                selectListDeliveryMethods: [],
                selectListSellers: [],
                lockedCreate: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                orderHeadCreateService.getOrderHeadCreate({
                    argtype: argType,
                    argvalue: argValue,
                    ordertype_id: orderTypeId
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                    
                    loadStockPlaces();
                    loadSelectProjects();
                    loadSellers();
                    loadDeliveryMethods();
                    
                    if (vm.model.edit.stat_cust_no > 0) {
                        loadDeliveryCustomers();
                        loadCustomerPersons();
                    }
                });
            };
            
            let loadCoops = function () {
                logisticService.listCoops().then(function (result) {
                    angular.copy(result, vm.model.selectListCoops);
                });
            };
            
            let loadBusinesses = function () {
                logisticService.listBusinesses().then(function (result) {
                    angular.copy(result, vm.model.selectListBusinesses);
                });
            };

            let loadDepartments = function () {
                logisticService.listDepartment().then(function (result) {
                    angular.copy(result, vm.model.selectListDepartments);
                });
            };

            let loadDeliveryMethods = function () {
                logisticService.listDeliveryMethods({ businessco_no: vm.model.edit.businessco_no }).then(function (result) { // 20231123 EO - Viser nå kun aktive leveringmetoder for den valgte virksomheten
                    angular.copy(result, vm.model.selectListDeliveryMethods);
                });
            };

            let loadOrderTypes = function () {
                orderHeadCreateService.listOrderTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListOrderTypes);
                });
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            let loadSellers = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return economyService.listSellers({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListSellers);
                });
            };
            
            let loadBusinessCoChangedStockPlaceIds = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return orderHeadCreateService.getStockPlaceId({ businessco_no: vm.model.edit.businessco_no }).then(function (response) {
                    vm.model.edit.stockplace_id = response[0].stockplace_id;
                });
            };
            
            let loadSelectProjects = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stat_cust_no) !== true) return;

                return economyService.listSelectProjects({ cust_no: vm.model.edit.stat_cust_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListProjects);
                });
            };

            let loadDeliveryCustomers = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stat_cust_no, true) !== true) return;

                return orderHeadCreateService.listDeliveryCustomers({ cust_no: vm.model.edit.stat_cust_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListDeliveryCustomers);
                });
            };
            
            let loadCustomerPersons = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stat_cust_no) !== true) return;

                return economyService.listCustomerPersons({ cust_no: vm.model.edit.stat_cust_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListCustomerPersons);
                });
            };

            let loadCustomer = function () {
                return economyService.getCustomer({ cust_no: vm.model.edit.stat_cust_no }).then(function (response) {
                    vm.model.edit.cust_address = response[0].cust_address;
                    vm.model.edit.delivery_cust_no = response[0].delivery_cust_no;
                    vm.model.edit.deliverymethod_no = response[0].cust_delm_no;

                    if (response[0].seller_no > 0) {
                        vm.model.edit.seller_no = response[0].seller_no;
                    }

                    if (vm.model.edit.show_popup_note_on_customer === '1' && response[0].cust_note > '') {
                        modalService.show({
                            type: 'warning',
                            title: translations.warning,
                            message: response[0].cust_note,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    }
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'new_customer_in_progress':
                            variableNames[key] = 'customer.new_customer_in_progress';
                            break;
                        case 'ordertype_id':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                        case 'stockplace_id':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key + '_' + vm.model.edit.businessco_no;
                            break;
                    	default:
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'new_customer_in_progress':
                        variableValue = '1';
                        break;
                    case 'ordertype_id':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearchCustomer = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'C',
                    lastselect: 'SEARCH'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.edit.stat_cust_no = item?.item_id ?? '0';
                    vm.model.edit.searchtext_customer = item?.item_name ?? '';

                    if (vm.model.edit.stat_cust_no > '') {
                        loadCustomer();

                        loadSelectProjects();
                        loadDeliveryCustomers();
                        loadCustomerPersons();

                        vm.model.edit.project_keyno = '0';
                    }
                } else {
                    vm.onClear();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChanged = function (value, item) {
                vm.model.edit.searchtext_customer = value;

                if (value) {
                    if (item?.item_id) {
                        vm.model.edit.stat_cust_no = item.item_id;

                        if (vm.model.edit.stat_cust_no > '') {
                            loadCustomer();

                            loadSelectProjects();
                            loadDeliveryCustomers();
                            loadCustomerPersons();

                            vm.model.edit.project_keyno = '0';
                        }
                    }
                } else {
                    vm.onClear();
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                vm.model.edit.stat_cust_no = '0';
                vm.model.edit.searchtext_customer = '';
                vm.model.edit.cust_address = '';
                vm.model.selectListDeliveryCustomers = [];
            };

		    // #endregion CLEAR VALUE FUNCTION

            vm.typeaheadSearchServiceObjectName = function (value) {
                return typeaheadService.lookUpServiceObjects({ searchtext: vm.model.edit.serviceobject_name });
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'stat_cust_no':
                        vm.model.edit.stat_cust_no = '0';
                        vm.model.edit.searchtext_customer = '';
                        vm.model.edit.cust_address = '';
                        vm.model.selectListDeliveryCustomers = [];
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            vm.onServiceObjectNameCleared = function () {
                vm.model.edit.serviceobject_keyno = '0';
                vm.model.edit.serviceobject_name = '';
            };

            vm.onServiceObjectNameSelected = function (item) {
                vm.model.edit.serviceobject_keyno = item.item_id;
                vm.model.edit.serviceobject_name = item.item_name;
            };

            vm.onServiceObjectNameChanged = function (value) {
                vm.model.edit.serviceobject_name = value;

                if (!value) {
                    vm.onServiceObjectNameCleared();
                } else {
                    let serviceObject = typeaheadService.selectListServiceObjects.find((item) => item.item_name === vm.model.edit.serviceobject_name);
                    if (serviceObject) {
                        vm.onServiceObjectNameSelected(serviceObject);
                    }
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                orderHeadCreateService.createObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.lockedCreate = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedCreate = false;
                        stateService.go(response[0].webpagename, response[0].parms);
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                switch (id) {
                    case 'ordertype_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.ordertype_id) !== true) return;

                        vm.model.edit.ordertype_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.ordertype_id, true) !== true) return;
                        
                        rememberFunc('ordertype_id');
                        break;
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no, true) !== true) return;

                        loadStockPlaces();
                        loadSellers();
                        loadBusinessCoChangedStockPlaceIds();
                        loadDeliveryMethods();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stockplace_id) !== true) return;

                        vm.model.edit.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stockplace_id, true) !== true) return;

                        rememberFunc('stockplace_id');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCompanies();
                loadOrderTypes();
                loadCoops();
                loadBusinesses();
                loadDepartments();
                // loadDeliveryMethods(); 20231123 EO - Flyttet til loadEdit
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
