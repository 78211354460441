import { ODataQueryParms } from "../model/odata-query-parms.model";
import { QueryOptions } from '../model/query-options.model';
import { GraphApiEndpointType } from '../enum/graph-api-endpoint-type.enum';
import { GraphAuthService } from "../auth/services/graph-auth.service";
import { NullableOption } from "@microsoft/microsoft-graph-types";

export class GraphQuery {
    private _url: string;
    private _method: string;
    private _ODataQueryParms?: ODataQueryParms;
    private _parms: Map<string, string>;
    private _first: boolean = true;
    private _authService: NullableOption<GraphAuthService> = null;

    public values?: Array<any>;

    public constructor(url: string, odata?: ODataQueryParms, method?: string) {
        this._url = url;
        this._method = method === undefined ? 'GET' : method;
        this._ODataQueryParms = odata;

        this._parms = new Map<string, string>();
        this._authService = new GraphAuthService();
    }

    public async previous(accessToken: string) {
        this._ODataQueryParms?.previous();

        await fetch(accessToken);
    }

    public async next(accessToken?: string) {
        console.log('next');

        if (!this._first) {
            this._ODataQueryParms?.next();
        }

        this._first = false;

        await this.fetch(accessToken);
    }

    private addParameter(name: string, value: string) {
        this._parms.set(name, value);
    }

    private async fetch(accessToken?: string) {
        console.log('fetch: ' + accessToken);

        if (accessToken === undefined || accessToken === null) {
            console.log('before getAccessToken');
            accessToken = await this._authService?.getAccessToken();
        }

        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        console.log(bearer);

        headers.append("Authorization", bearer);

        const options = {
            method: this._method,
            headers: headers
        };

        let qp = this._ODataQueryParms?.toQueryParms();
        let url = '';

        if (this._parms.size > 0) {
            for (let [key, value] of this._parms) {

                url = url + (url.length > 0 ? '&' : '') + key + '=' + value;
            }
        }

        if (qp !== undefined && qp.length > 0) {
            url = url + (qp.length > 0 ? '&' : '') + qp;
        }

        url = this._url + (url.length > 0 ? '?' : '') + url;

        const response = await fetch(url, options);

        let data = (await response.json());

        if (data['@odata.nextLink'] !== undefined) {
            const nextLink = new URL(data['@odata.nextLink']);

            if (nextLink.searchParams.has('$skip') && nextLink.searchParams.has('$top')) {
                if (this._ODataQueryParms === undefined) {
                    this._ODataQueryParms = new ODataQueryParms()
                }

                let top = nextLink.searchParams.get('$top');
                let skip = nextLink.searchParams.get('$skip');

                if (top !== null && skip !== null) {
                    this._ODataQueryParms.top = +top;
                    this._ODataQueryParms.skip = +skip - this._ODataQueryParms.top;
                }
            }
        }

        this.values = data.value;
    }
}
