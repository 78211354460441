(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("consignmentPlansConsignmentContentService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
		var service = {
			loadDeliveryMethods: function () {
				return p2DataTaskService.call(1693, {});
			},
			loadSettings: function (parms) {
				return p2DataTaskService.call(1692, parms);
			},
			loadLines: function (parms) {
				return p2DataTaskService.call(1681, parms);
            },
            loadLabourers: function () {
                return p2DataTaskService.call(1790, {});
            },
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			completeDelivery: function(lines,note){
				return p2DataTaskService.call(1712, {
					test: 'Dette er en test',
					delivery_note: note,
					records: lines
				});
			},
			temporaryQuantities: function(lines){				
				return p2DataTaskService.call(2174, {
					test: 'Dette er en test',
					records: lines
				});
			},
			updateColumnResize: function (parms) {
                return p2DataTaskService.call(1457, parms);
			},
            switchModus: function (parms) {
                return $ihttp.post({
					method: 2381,
                    parameters: parms
                });
            },
            printPicklist(order_internal_no, p2_printer_keyno){
                return $ihttp.post({
                    method: 3144,
                    parameters: {
                        order_internal_no: order_internal_no,
                        p2_printer_keyno: p2_printer_keyno
                    }
                });

                
            }

		};

		return service;
	}]);
})();
