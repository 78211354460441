(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('printTestPrinterDetails', {
        templateUrl: 'views/components/views/printTestPrinterDetails/printTestPrinterDetails.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'printService', function($stateParams, printService) {
    
            var vm = this;
    
    
            vm.model = {
                printClientKeyno: $stateParams.printClientKeyno,
                printClientName: $stateParams.printClientName,
                printerName: $stateParams.printerName,
                printer: {}
            };
    
            var load = function () {
                printService.getPrinterDetails({
                    printClientKeyno: vm.model.printClientKeyno,
                    printerName: vm.model.printerName
                }).then(function (data) {
                    angular.copy(data, vm.model.printer);
                });
            };
    
            load();
        }]
    });
})();
