(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('docArticleEdit', {
        templateUrl: 'views/components/views/docArticleEdit/docArticleEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'base64', 'modalService', 'docArticleEditService', function ($stateParams, stateService, base64, modalService, docArticleEditService) {
            var vm = this;

            vm.model = {
                docArticleKeyNo: $stateParams.docarticle_keyno,
                edit: {},
                lockedSave: false
            };

            vm.editor = {
                ready: true,
                style: {
                    div: {
                        minHeight: '150px',
                        height: '100vh',
                        //maxHeight: 'min(calc(100vh - 220px), 700px)',
                        maxHeight: 'calc(100vh - 220px)',
                    },
                },
                class: {
                    base: 'col-xs-12 col-sm-12 col-xl-12 col-xxl-12 sp-0',
                    label: 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1 sp-0',
                    input: 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11 sp-0',
                }
            };

            vm.onChange = function (value) {
                vm.model.edit.html_content_base64 = value;
            }

            docArticleEditService.loadDocArticleGet(vm.model.docArticleKeyNo).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
                vm.model.edit.html_content_base64 = base64.urldecode(data[0].html_content_base64);
            });

            vm.saveDocArticleEdit = function () {
                vm.model.lockedSave = true;

                var editDocArticle = {
                    docarticle_keyno: vm.model.edit.docarticle_keyno,
                    title: vm.model.edit.title,
                    html_content: vm.model.edit.html_content_base64,
                    html_content_base64: base64.urlencode(vm.model.edit.html_content_base64),
                    docarticle_keyno_belongto: vm.model.edit.docarticle_keyno_belongto,
                    reg_portal_user_name: vm.model.edit.reg_portal_user_name,
                    reg_date: vm.model.edit.reg_date,
                    mod_portal_user_name: vm.model.edit.mod_portal_user_name,
                    mod_date: vm.model.edit.mod_date,
                    nbr_of_read: vm.model.edit.nbr_of_read,
                    docarticle_keyno_top: vm.model.edit.docarticle_keyno_top
                };

                docArticleEditService.save(editDocArticle).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });

                vm.model.lockedSave = false;
            };

            vm.deleteDocArticleEdit = function () {
                vm.model.lockedDelete = true;

                docArticleEditService.delete(vm.model.edit.docarticle_keyno).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back('2');
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();