
/******************************************************************
auth: bhw
description:
mods:       
*******************************************************************/
(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentPlansService", ['$timeout', 'p2DataTaskService', function ($timeout, p2DataTaskService) {
        var service = {
            loaded: false,
            reloaded: false,
            loadConsignmentPlansGet: function () {
                return p2DataTaskService.call(1361, {});
            },
            loadConsignmentPlansList: function (consignment_plan_status, view_id, date_fom, date_tom) {
                return p2DataTaskService.call(454, {
                    consignment_plan_status: consignment_plan_status,
                    view_id: view_id,
                    date_fom: date_fom,
                    date_tom: date_tom
                });
            },
            reloadConsignmentPlansList: function () {
                service.reloaded = false;

                $timeout(function () {
                    service.reloaded = true;
                    console.log('15sec wait');
                }, 15000);

                return service.reloaded;
            },
            updateConsignmentPlans: function (parms) {
                return p2DataTaskService.call(1457, parms);
            }
        };

        return service;
    }]);
})();
