(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeDataCurrent', {
        templateUrl: 'views/components/views/oeeDataCurrent/oeeDataCurrent.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'oeeDataCurrentService', 'stateService', function($stateParams, oeeDataCurrentService, stateService) {
            var vm = this;
            
            vm.oeedata_selection = {
                item_id: '',
                item_name: '',
                item_default: '0',
                item_selected: 'basic_info',
                records: [
                    { item_id: '1', item_name: 'basic_info', item_func: 'basic_info' },
                    { item_id: '2', item_name: 'detail_info', item_func: 'detail_info' }
                ]
            };
            
            oeeDataCurrentService.redirectP2OeeDataCurrentGet().then(function () {
                vm.p2_oeedata_current_redirect_get = oeeDataCurrentService.p2_oeedata_current_redirect_get;
                stateService.go(vm.p2_oeedata_current_redirect_get.webpagename, vm.p2_oeedata_current_redirect_get.parms);
            });
            
            oeeDataCurrentService.loadP2OeeDataGet($stateParams.oeedata_keyno).then(function () {
                vm.p2_oeedata_get = oeeDataCurrentService.p2_oeedata_get;
            });
            
            vm.selectOeeData = function (l_s_oeedata_status) {
                vm.oeedata_selection.item_selected = l_s_oeedata_status;
            };
            
            vm.goToOeeData = function (index) {
                stateService.go('oeedata', {
                    oeedata_keyno: '0'
                });
            };
            
            vm.goToOeeStateLog = function (index) {
                stateService.go('oeestatelog', {
                    equipment_id: vm.p2_oeedata_get.equipment_id
                });
            };
            
            vm.saveP2OeeData = function () {
                oeeDataCurrentService.saveP2OeeData();
            };
        }]
    });
})();