(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockTransferMonitor', {
        templateUrl: 'views/components/views/stockTransferMonitor/stockTransferMonitor.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stockTransferMonitorService', 'rememberService', function ($q, stockTransferMonitorService, rememberService) {
            var vm = this;

            var rememberIdGrid = 'stMonitorGrid';
            var rememberIdSelect = 'stMonitorSelect';

            vm.select = {
                stockplaceId: '',
                dates: {
                    from: null,
                    to: null,
                    bindex: 1
                },
                statusId: 0
            };

            vm.stockplaces = [];
            vm.statuses = [];

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2426,
                        parameters: function () {
                            return {
                                stockplace_id: vm.select.stockplaceId,
                                date_from: vm.select.dates.from,
                                date_to: vm.select.dates.to,
                                status_id: vm.select.statusId
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [/*{
                            name: 'resend',
                            text: '',
                            icon: 'fa-paper-plane',
                            func: function (data) {
                                console.log('data');
                                console.dir(data);

                                var message = msg03;

                                if (angular.isDefined(data.errormessage) && angular.isString(data.errormessage) && data.errormessage !== null && data.errormessage.length > 0) {
                                    message = msg01 + ' "' + data.errormessage + '". ' + msg02;
                                }

                                var modalInstance = $uibModal.open({
                                    animation: true,
                                    backdrop: 'static',
                                    component: 'ttConfirm',
                                    resolve: {
                                        message: function () {
                                            return message;
                                        }
                                    }
                                });

                                modalInstance.result.then(function (confirmed) {
                                    if (confirmed !== true) return;

                                    ieScreenBlockService.start();

                                    invoicesService.resend(data.item_parms).then(function (result) {
                                        ieScreenBlockService.stop();

                                        vm.gridOptions.gridfunc.read();
                                    });
                                });
                            },
                            disabled: function (data) {
                                //return data.errorcode !== '0';
                                return false;
                            }
                        }*/]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: true
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            var remember = function () {
                rememberService.remember(rememberIdSelect, vm.select);

                vm.gridOptions.gridfunc.read();
            };

            var load = function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdSelect, true));
                promises.push(stockTransferMonitorService.init());

                $q.all(promises).then(function (response) {
                    if (response[0] !== null) {
                        vm.select.stockplaceId = response[0].stockplaceId;
                        vm.select.dates.from = response[0].dates.from;
                        vm.select.dates.to = response[0].dates.to;
                        vm.select.dates.bindex = response[0].dates.bindex;
                        vm.select.statusId = response[0].statusId;
                    }

                    angular.copy(response[1].stockplaces, vm.stockplaces);
                    angular.copy(response[1].statuses, vm.statuses);

                    vm.gridOptions.gridfunc.rebind();
                });

                return deferred.promise;
            };

            vm.onSelectChanged = function (value, id) {
                switch (id) {
                    case 'vm.select.stockplaceId':
                        vm.select.stockplaceId = value;
                    case 'vm.select.statusId':
                        vm.select.statusId = value;
                }

                remember();
            };

            vm.onSelectedDateChanged = function (fom, tom, bindex) {
                vm.select.dates.from = fom;
                vm.select.dates.to = tom;

                if (bindex >= 0) {
                    vm.select.dates.bindex = bindex;
                }

                remember();
            };

            vm.$onInit = function () {
                load();
            };
        }]
    });
})();
