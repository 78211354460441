<!-- 1 COLUMN -->
<!--<div *ngIf="!model.mobileSize.matches">-->
    <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
        <div class="col-xs-12 well well-sm">
            <!--<div>
        <div class="col-xs-6 sp-0">
            <a href="#" (click)="setFavourites()" class="btn btn-default btn-md pull-left col-xs-1 sp-0" [ngStyle]="style.history">
                <span [ngClass]="model.setting.show_suggestions === '1' ? 'fas fa-bars' : 'fas fa-history'" style="width: 14px"></span>
            </a>
        </div>

        <div *ngIf="model.setting.show_global_search === '1' && !model.miniMobileSize.matches">
            Global Search Logic
        </div>
    </div>-->

            <!--<div *ngIf="model.setting.show_gdpr_message === '1'">-->
                <im-input-directive [plabel]="model.setting.gdpr_title" [field]="model.setting.gdpr_message" [type]="'message'"></im-input-directive>
                <tt-button-save class="col-xs-12 sp-0" [ttText]="'gdpr_approve'" [ttLocked]="model.lockedApprove" (ttClick)="approve()" ttTextAlign="center"></tt-button-save>
            <!--</div>-->
        </div>

        <!--<div [hidden]="model.setting.show_gdpr_message === '1' || model.setting.show_suggestions === '1'">
            <ng-container *ngTemplateOutlet="mainMenuTemplate"></ng-container>
        </div>

        <div class="col-xs-12 sp-0" [hidden]="model.setting.show_gdpr_message === '1' || model.setting.show_suggestions === '0'">
            <tt-list class="col-xs-6 sp-0" [listItems]="model.itemsListMyMenus" [designVar]="'s12t0'" [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
            <tt-list class="col-xs-6 sp-0" [listItems]="model.criticalProcesses" [designVar]="'s12t0'" [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
        </div>-->
    </form>
<!--</div>-->

<!-- 2 0R MORE COLUMNS -->
<!--<div *ngIf="model.mobileSize.matches">
    <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
        <div class="well well-sm col-xs-12" *ngIf="model.setting.show_gdpr_message === '1'">
            <im-input-directive [plabel]="model.setting.gdpr_title" [field]="model.setting.gdpr_message" [type]="'message'"></im-input-directive>
            <tt-button-save class="col-xs-12 sp-0" [ttText]="'gdpr_approve'" [ttLocked]="model.lockedApprove" (ttClick)="approve()" ttTextAlign="center"></tt-button-save>
        </div>

        <div [hidden]="model.setting.show_gdpr_message === '1'">
            <ng-container *ngTemplateOutlet="mainMenuListAllTemplate"></ng-container>

            <div class="col-xs-12 sp-0" [hidden]="model.setting.show_suggestions === '0'">
                <tt-list class="col-xs-6 sp-0" [listItems]="model.itemsListMyMenus" [designVar]="'s12t0'" [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                <tt-list class="col-xs-6 sp-0" [listItems]="model.criticalProcesses" [designVar]="'s12t0'" [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
            </div>
        </div>
    </form>
</div>-->




<ng-template #mainMenuTemplate>
    <!-- 1 COLUMN -->
    <div *ngIf="!model.mobileSize.matches">
        <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
            <div class="col-xs-12 well well-sm">
                <div>
                    <div class="col-xs-6 sp-0">
                        <a href=""
                           (click)="setFavourites()"
                           class="btn btn-default btn-md pull-left col-xs-1 sp-0"
                           [ngStyle]="style.history">
                            <span [ngClass]="
                model.setting.show_suggestions === '1'
                  ? 'fas fa-bars'
                  : 'fas fa-history'
              "
                                  style="width: 14px"></span>
                        </a>
                    </div>

                    <!-- Global Search -->
                    <div *ngIf="model.setting.show_global_search === '1' && !model.miniMobileSize.matches">
                        <!-- Include your global search component or template here -->
                    </div>
                </div>

                <!-- GDPR Message -->
                <div *ngIf="model.setting.show_gdpr_message === '1'">
                    <tt-message [ttLabel]="model.setting.gdpr_title"
                                [ttModel]="model.setting.gdpr_message"
                                [ttIsHtml]="model.isHtml"
                                [ttTranslate]="false"></tt-message>
                    <im-input-directive [plabel]="model.setting.gdpr_title"
                                        sublabel=""
                                        [field]="model.setting.gdpr_message"
                                        myuser=""
                                        type="message"></im-input-directive>
                    <tt-button-save class="col-xs-12 sp-0"
                                    [ttText]="'gdpr_approve'"
                                    [ttLocked]="model.lockedApprove"
                                    (ttClick)="approve()"
                                    [ttTextAlign]="'center'"></tt-button-save>
                </div>
            </div>

            <!-- Main Menu List -->
            <div *ngIf="model.setting.show_gdpr_message !== '1' && model.setting.show_suggestions !== '1'">
                <!-- Replace ng-include with component or ng-template -->
                <app-main-menu-list></app-main-menu-list>
            </div>

            <!-- Suggestions Lists -->
            <div class="col-xs-12 sp-0"
                 *ngIf="model.setting.show_gdpr_message !== '1' && model.setting.show_suggestions !== '0'">
                <tt-list class="col-xs-6 sp-0"
                         [listItems]="model.itemsListMyMenus"
                         badge=""
                         ltype=""
                         [designVar]="'s12t0'"
                         [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                <tt-list class="col-xs-6 sp-0"
                         [listItems]="model.criticalProcesses"
                         badge=""
                         ltype=""
                         [designVar]="'s12t0'"
                         [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
            </div>
        </form>
    </div>

    <!-- 2 OR MORE COLUMNS -->
    <div *ngIf="model.mobileSize.matches">
        <form class="form-horizontal im-no-pad" name="mainMenu" novalidate method="get" role="form">
            <!-- GDPR Message -->
            <div class="well well-sm col-xs-12" *ngIf="model.setting.show_gdpr_message === '1'">
                <tt-message [ttLabel]="model.setting.gdpr_title"
                            [ttModel]="model.setting.gdpr_message"
                            [ttIsHtml]="model.isHtml"
                            [ttTranslate]="false"></tt-message>
                <im-input-directive [plabel]="model.setting.gdpr_title"
                                    sublabel=""
                                    [field]="model.setting.gdpr_message"
                                    myuser=""
                                    type="message"></im-input-directive>
                <tt-button-save class="col-xs-12 sp-0"
                                [ttText]="'gdpr_approve'"
                                [ttLocked]="model.lockedApprove"
                                (ttClick)="approve()"
                                [ttTextAlign]="'center'"></tt-button-save>
            </div>

            <!-- Main Menu List All -->
            <div *ngIf="model.setting.show_gdpr_message !== '1'">
                <!-- Replace ng-include with component or ng-template -->
                <app-main-menu-list-all></app-main-menu-list-all>

                <!-- Suggestions Lists -->
                <div class="col-xs-12 sp-0" *ngIf="model.setting.show_suggestions !== '0'">
                    <tt-list class="col-xs-6 sp-0"
                             [listItems]="model.itemsListMyMenus"
                             badge=""
                             ltype=""
                             [designVar]="'s12t0'"
                             [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                    <tt-list class="col-xs-6 sp-0"
                             [listItems]="model.criticalProcesses"
                             badge="badge"
                             ltype=""
                             [designVar]="'s12t0'"
                             [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
                </div>
            </div>
        </form>
    </div>
</ng-template>




<ng-template #mainMenuListAllTemplate>
    <div class="col-xs-12 well well-sm" style="position: sticky; z-index: 10; padding-bottom: 0;" [ngStyle]="style.search">
        <div class="col-xs-4 sp-0" style="display: flex; gap: 5px;">
            <a href="#" (click)="setFavourites()" class="btn btn-default btn-md pull-lef " [ngStyle]="style.history">
                <span [ngClass]="model.setting.show_suggestions === '1' ? 'fas fa-bars' : 'fas fa-history'"></span>
            </a>
            <tt-input-buttons class="col-xs-11 sp-0"
                           [ttLabel]="''"
                           [ttHideLabel]="true"
                           [ttFocus]="true"
                           [(ttModel)]="model.setting.menuitem_filtervalue"
                           [ttPlaceholder]="'menusearch_placeholder'"
                           (ttChange)="model.setting.menuitem_filtervalue = $event"
                           (ttModelChange)="model.setting.menuitem_filtervalue = $event"
                           [ttButtons]="model.filterValueButtons">
            </tt-input-buttons>
        </div>
        <span class="col-xs-5 sp-0"></span>
        <div class="col-xs-3 sp-0" *ngIf="model.setting.show_global_search !== '0'">
        </div>
    </div>

    <div class="col-xs-12 sp-0" *ngIf="model.setting.show_gdpr_message !== '1' && model.setting.show_suggestions !== '1'">
        <div *ngFor="let menu of model.menus">
            <div class="col-xs-12 sp-0" [ngStyle]="style.list">
                <tt-list [listItems]="menu" badge="" ltype="" designVar="s12t0" [filterVal]="model.setting.menuitem_filtervalue"></tt-list>
            </div>
        </div>
    </div>
</ng-template>
