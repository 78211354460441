(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttGridSelector', {
        templateUrl: 'views/components/dynamics/components/ttGridSelector/ttGridSelector.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttRouteId: '<',
            ttDynamicpropertyKeyno: '<',
            ttModel: '<',
            ttLabel: '@',
            ttChange: '&'
        },
        controller: ['$timeout', '$element', 'translateService', 'layoutService', 'ttDynamicGridManager', 'utilityService', function ($timeout, $element, translateService, layoutService, ttDynamicGridManager, us) {
            var vm = this;

            var onDestroy = [];

            vm.grids = [];

            vm.selected = 0;

            vm.id = {
                select: uuid()
            };

            vm.translations = {
                label: '',
                tt_none: ''
            };

            vm.style = {
                label: {},
                select: {}
            };

            vm.class = {
                label: '',
                input: '',
                select: ''
            }

            vm.onChanged = function () {
                if (angular.isFunction(vm.ttChange)) {
                    let grid = null;

                    for (let i = 0; i < vm.grids.length; i++) {
                        if (vm.grids[i].tt_dynamic_keyno.toString() === vm.selected.toString()) {
                            grid = vm.grids[i];
                            break;
                        }
                    }

                    vm.ttChange({ $value: grid.tt_dynamic_keyno, $grid: grid, $modelId: us.getModelId($element) });
                }
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.style.label.fontSize = info.fontSizes.textSizeS;

                vm.style.select.fontSize = info.fontSizes.textSize;
                vm.style.select.height = info.height + 'px';
                vm.style.select.paddingTop = info.padding.top + 'px';
                vm.style.select.paddingBottom = info.padding.bottom + 'px';
                vm.style.select.paddingLeft = info.padding.left + 'px';
                vm.style.select.paddingRight = info.padding.right + 'px';

                vm.class.label = info.labelAlwaysOnTop === true ? 'col-xs-12 pb-5' : 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1';
                vm.class.input = vm.hideLabel
                    ? 'col-xs-12'
                    : info.labelAlwaysOnTop === true
                        ? 'col-xs-12'
                        : 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11';
            });

            vm.$onInit = function () {
                var setLabel = false;

                if (angular.isString(vm.ttLabel) && angular.isUndefined(vm.translations[vm.ttLabel])) {
                    vm.translations[vm.ttLabel] = '';

                    setLabel = true;
                }

                translateService.translateBatch(vm.translations).then(function (translations) {
                    angular.forEach(translations, function (val, key) {
                        vm.translations[key] = val;
                    });

                    if (setLabel === true) {
                        vm.translations.label = translations[vm.ttLabel];
                    }

                    addAll(vm.grids);
                });

                if (angular.isDefined(vm.ttModel) && vm.ttModel > 0) {
                    vm.selected = vm.ttModel;
                }

                ttDynamicGridManager.list(vm.ttRouteId, 0, vm.ttDynamicpropertyKeyno).then(function (response) {
                    addAll(response);

                    angular.copy(response, vm.grids);

                    if (angular.isDefined(vm.ttModel) && vm.ttModel > 0) {
                        for (let i = 0; i < vm.grids.length; i++) {
                            if (vm.grids[i].tt_dynamic_keyno.toString() === vm.ttModel.toString()) {
                                vm.selected = vm.grids[i].tt_dynamic_keyno;

                                break;
                            }
                        }
                    }
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            function addAll(grids) {
                if (grids.length < 1) return;

                if (grids[0].tt_dynamic_keyno === 0) {
                    grids[0].name = vm.translations.tt_none;

                    return;
                }

                grids.unshift({
                    tt_dynamic_keyno: 0,
                    name: vm.translations.tt_none
                });
            };
        }]
    });
})();
