(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('visitor', {
        templateUrl: 'views/components/views/visitor/visitor.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'visitorService', function (utilityService, visitorService) {
            let vm = this;
            
            vm.model = {
                bgVisited: {
                    selected: 'IN',
                    buttons: [
                        { id: 'IN', label: 'in', color: 'primary', onClick: function () { vm.model.bgVisited.selected = 'IN'; } },
                        { id: 'OUT', label: 'out', color: 'primary', onClick: function () { vm.model.bgVisited.selected = 'OUT'; } }
                    ]
                },
                personNameButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('pers_name') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ], 
                setting: {
                    pers_name: '',
                    inout: 'IN'
                },
                itemsListPersons: [],
                itemsListCheckOut: [],
                searching: false,
                searchingFromBtn: false
            };
    
            let loadFindPersons = function () {
                if (utilityService.validateParmsValue(vm.model.setting.pers_name) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.inout) !== true) return;
    
                vm.model.itemsListPersons = [];
    
                visitorService.listFindPersons({
                    pers_name: vm.model.setting.pers_name,
                    inout: vm.model.setting.inout
                }).then(function (result) {
                    vm.model.searching = false;
                    vm.model.searchingFromBtn = false;
                    angular.copy(result, vm.model.itemsListPersons);
                });
            };
    
            let loadVisitorOuts = function () {
                if (utilityService.validateParmsValue(vm.model.setting.inout) !== true) return;
    
                vm.model.itemsListCheckOut = [];
    
                visitorService.listVisitorOuts({ inout: vm.model.setting.inout }).then(function (result) {
                    angular.copy(result, vm.model.itemsListCheckOut);
                });
            };

            let reset = function (id) {
                switch (id) {
                    case 'pers_name':
                        vm.model.setting.pers_name = '';
                        vm.model.itemsListPersons = [];
                        break;
                    default:
                        break;
                }
            };

            vm.selectBgVisited = function (value) {
                vm.model.bgVisited.selected = value;
                vm.model.setting.inout = vm.model.bgVisited.selected;

                if (vm.model.setting.inout === 'OUT') {
                    loadVisitorOuts();
                }
            };
            
            vm.searchItems = function () {
                if (vm.model.setting.inout === 'IN') {
                    if (vm.model.setting.pers_name.length > 3) {
                        vm.model.searching = true;
                        loadFindPersons();
                    }
                }
            };
            
            vm.searchBtn = function () {
                if (vm.model.setting.inout === 'IN') {
                    if (vm.model.setting.pers_name.length > 3) {
                        vm.model.searchingFromBtn = true;
                        loadFindPersons();
                    }
                }
            };

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'pers_name':
                        if (utilityService.validateWatchValue(value, vm.model.setting.pers_name) !== true) return;

                        vm.model.setting.pers_name = value;

                        if (utilityService.validateParmsValue(vm.model.setting.pers_name, true) !== true) return;

                        if (vm.model.setting.inout === 'IN') {
                            if (vm.model.setting.pers_name === '') {
                                vm.model.itemsListPersons = [];
                            }
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };
        }]
    });
})();
