(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poPlansProd', {
        templateUrl: 'views/components/views/poPlansProd/poPlansProd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'poPlansProdService', function ($stateParams, stateService, poPlansProdService) {
            var vm = this;

            vm.model = {
                poPlansProdGet: {}
            };
            
            poPlansProdService.loadPoPlansProdGet($stateParams.stock_keyno, $stateParams.date_to).then(function () {
        		vm.model.poPlansProdGet = poPlansProdService.poPlansProdGet;
        	});
            
            vm.saveFunction = function () {
                poPlansProdService.poPlansProdSave($stateParams.stock_keyno, $stateParams.date_to, vm.model.poPlansProdGet.quantity_tostart).then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();