(function() {
    'use strict';

    angular.module("imApp").factory("orderConfirmationService", ['$ihttp', function ($ihttp) {
        var service = {
            resend: function (orderInternalNo) {
                return $ihttp.post({
                    method: 3052,
                    parameters: {
                        order_internal_no: orderInternalNo
                    }
                });
            },

            getStatusSelectList: function (parms) {
                return $ihttp.post({
                    method: 3056,
                    parameters: parms || {}
                });
            }


        }




        return service;
    }]);
})();
