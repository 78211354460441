(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('furnitureFabrics', {
        templateUrl: 'views/components/views/furnitureFabrics/furnitureFabrics.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'furnitureFabricsService', 'logisticService', 'typeaheadService', function ($stateParams, stateService, utilityService, modalService, translateService, furnitureFabricsService, logisticService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                productGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrps', {}) }
                ],
                collectionButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('collections', {}) }
                ],
                colourGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('colorgrps', {}) }
                ],
                fabricGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('fabricsgrps', {}) }
                ],
                furnitureFabricsId: $stateParams.furniturefabrics_id,
                edit: {},
                selectListProductTypes: [],
                selectListUnits: [],
                selectListProductGroups: [],
                selectListCompanies: [],
                selectListProductionPlaces: [],
                selectListStockPlaces: [],
                selectListCollections: [],
                selectListColourGroups: [],
                selectListProdMeoMethods: [],
                selectListProductChoices: [],
                selectListFabricGroups: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##
            
            furnitureFabricsService.loadFurnitureFabricsGet(vm.model.furnitureFabricsId).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
                
                loadStocks();
            });
            
            furnitureFabricsService.loadProdTypeList().then(function (data) {
                angular.copy(data, vm.model.selectListProductTypes);
            });
            
            let loadUnits = function () {
                logisticService.listUnits().then(function (result) {
                    angular.copy(result, vm.model.selectListUnits);
                });
            };
            
            furnitureFabricsService.loadProdGrpList().then(function (data) {
                angular.copy(data, vm.model.selectListProductGroups);
            });
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadProductionPlaces = function () {
                logisticService.listProductPlaces().then(function (result) {
                    angular.copy(result, vm.model.selectListProductionPlaces);
                });
            };

            furnitureFabricsService.loadCollectionList().then(function (data) {
                angular.copy(data, vm.model.selectListCollections);
            });
            
            furnitureFabricsService.loadColorGrpViewList().then(function (data) {
                angular.copy(data, vm.model.selectListColourGroups);
            });
            
            let loadProdBeoMethods = function () {
                logisticService.listProdBeoMethods().then(function (result) {
                    angular.copy(result, vm.model.selectListProdMeoMethods);
                });
            };

            furnitureFabricsService.loadProductChoiceList().then(function (data) {
                angular.copy(data, vm.model.selectListProductChoices);
            });
            
            furnitureFabricsService.loadFabricsGrpList().then(function (data) {
                angular.copy(data, vm.model.selectListFabricGroups);
            });
            
            let loadStocks = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                return logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            // #region TYPEAHEAD SEARCH MULTI PROCEDURE FUNCTION CALLS

            vm.typeaheadSearch = function (value) {
                if (utilityService.validateParmsValue(vm.model.edit.supplier_name, true) !== true) return;

                var parms = {
                    cust_name: vm.model.edit.supplier_name,
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'S',
                    lastselect: 'SEARCH'
                };

                return typeaheadService.searchCustomer(parms);
            };

            vm.onSuplierNameCleared = function () {
                vm.model.edit.supplier_no = '0';
                vm.model.edit.supplier_name = '';
            };

            vm.onSuplierNameSelected = function (item) {
                vm.model.edit.supplier_no = item.item_id;
                vm.model.edit.supplier_name = item.item_name;
            };

            vm.onSuplierNameChanged = function (value) {
                vm.model.edit.supplier_name = value;
                if (!value) {
                    vm.onSuplierNameCleared();
                } else {
                    let suplier = typeaheadService.selectListCustomers.find((item) => item.iten_name === vm.model.edit.supplier_name);
                    if (suplier) {
                        vm.onSuplierNameSelected(suplier);
                    }
                }
            };

            // #endregion TYPEAHEAD SEARCH MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                furnitureFabricsService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'custactsearch':
                        go({
                            filterfield: 'furniturefabrics_id',
                            filtervalue: vm.model.edit.furniturefabrics_id,
                            custacttype: 'A'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        loadStocks();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadUnits();
                loadCompanies();
                loadProductionPlaces();
                loadProdBeoMethods();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
