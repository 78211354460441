(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderLineService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 723,
                    parameters: parms || {}
                });
            },
            listPositions: function (parms) {
                return $ihttp.post({
                    method: 1113,
                    parameters: parms || {}
                });
            },
            listKits: function (parms) {
                return $ihttp.post({
                    method: 2077,
                    parameters: parms || {}
                });
            },
            loadOrderLineBarcodeScan: function (barcode) {
                return p2DataTaskService.call(1141, {
                    barcode: barcode
                });
            },
            changeProduct: function (edit) {
                return $ihttp.post({
                    method: 725,
                    parameters: edit
                });
            },
            getPrice: function (edit) {
                return $ihttp.post({
                    method: 726,
                    parameters: edit
                });
            },
            cancelItem: function (edit) {
                return $ihttp.post({
                    method: 2673,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 727,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 728,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
