import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { TextAlign } from 'chart.js';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { FormButton, FormButtonEvent } from '../form-field-button/form-field-button.component';
import { NumberInput, coerceNumberProperty } from '@angular/cdk/coercion';
import { ListboxPopupDirective, ListboxSelectEvent } from '@app/core/directives/listbox-popup/listbox-popup.directive';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

export type SearchItem = { [key: string]: any };

@Component({
    selector: 'tt-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
})
export class SearchComponent<TType extends Record<string | symbol, unknown> = SearchItem> extends FormFieldBaseComponent implements FormFieldButtons {
    /**
     * The model of the input field.
     */
    @Input()
    ttModel: string = '';

    /**
     * Event emitted when the model value changes from the component, $event is the new ttModel value.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * The text align for the form-field.
     */
    @Input()
    ttTextAlign?: TextAlign;

    /**
     * A function returning a promise containing list of items to display in a listbox.
     * If `ttData` and `ttSearch` are both inputted, `ttSearch` will take precende
     */
    @Input()
    ttSearch?: (parms?: { [key: string]: any }) => Promise<TType[]>;

    /**
     * Static data to filter over.
     * If `ttData` and `ttSearch` are both inputted, `ttSearch` will take precende
     */
    @Input()
    ttData?: TType[];

    /**
     * The key for the name of the objects in the data set to display in the listbox.
     * Default is `'item_name'`.
     */
    @Input()
    ttDataName: keyof TType = 'item_name' as keyof TType;

    /**
     * The key for the id value of the objects in the data set to display in the listbox.
     * Default is `'item_id'`.
     */
    @Input()
    ttDataId: keyof TType = 'item_id' as keyof TType;

    @Input()
    ttSearchParm: any = {};

    @Input()
    ttSearchParmName: string = 'value';

    /**
     * **Only works for static lists.**
     * Whether to filter the list or sort the list.
     */
    @Input()
    ttFilterOperation: 'filter' | 'sort' | 'none' = 'none';

    @Input()
    ttButtons?: FormButton[] | undefined;

    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;

    /**
     * The time to wait after a user has finished typing before calling `ttData` if `ttData` is
     * a promise returning a resultset.
     */
    @Input()
    get ttDebounceTime(): number {
        return this._debounceTime;
    }
    set ttDebounceTime(value: NumberInput) {
        this._debounceTime = coerceNumberProperty(value);
    }
    _debounceTime = 500;

    /**
     * The minimum required length needed for the listbox to display and start requesting
     * live data if `ttSearch` input is used.
     */
    @Input()
    get ttMinLength(): number {
        return this._minLength;
    }
    set ttMinLength(value: NumberInput) {
        this._minLength = coerceNumberProperty(value);
    }
    private _minLength = 3;

    /**
     * Event emitted when an item has been selected.
     */
    @Output()
    ttOnSelected = new EventEmitter<TType>();

    /**
     * Event emitted when the seach input field has been cleared.
     */
    @Output()
    ttOnClear = new EventEmitter<FormButtonEvent>();

    /**
     * Event emitted when the search input-field loses focus.
     */
    @Output()
    ttOnBlur = new EventEmitter<string>();

    /**
     * Reference to the base component.
     */
    @ViewChild('baseRef')
    baseRef?: FormFieldBaseComponent;

    /**
     * Reference to the input element.
     */
    @ViewChild('inputRef')
    inputRef?: ElementRef;

    /**
     * Reference to the listbox-popup directive.
     */
    @ViewChild('listboxPopupRef')
    listboxPopupRef?: ListboxPopupDirective;

    /**
     * Emits `ttModelChanged` when theres a new value for `ttModel`.
     *
     * @param event the new value of `ttModel`
     */
    onModelChanged(event: string) {
        this.ttModelChange.emit(event);
    }

    /**
     * Id of elements in the component.
     */
    id = {
        input: crypto.randomUUID(),
    };

    /**
     * Whether a http search is currently happening or not.
     */
    searching = false;

    constructor(layoutService: LayoutService, coreComponentService: CoreComponentService, translateService: TranslateService) {
        super(layoutService, coreComponentService, translateService);

        layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.coreComponentService.setLayoutStyle(this.style, info);
            }
        });
    }

    public override style: Style = {
        input: {},
    };

    public override setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, textAlign: this.ttTextAlign, mainElement: 'input' });
    }

    /**
     * Emits `ttOnSelected` event and changed the `ttModel` value to the
     * value from the `ttDataName` key of the item selected.
     *
     * @param event the item selected.
     */
    public onSelect(event: ListboxSelectEvent<TType>) {
        this.ttModel = `${event.item?.[this.ttDataName] ?? ''}`;
        this.onModelChanged(`${event.item[this.ttDataName] ?? ''}`);

        this.ttOnSelected.emit(event.item);
    }

    /**
     * Forces a search with the current values.
     */
    public async manualSearch() {
        this.searching = true;
        await this.listboxPopupRef?.forceSearch();
        this.inputRef?.nativeElement.focus();
        this.searching = false;
    }

    /**
     * Clears the `ttModel` value and emits `ttModelChange`, then emits the `ttOnClear` event.
     * Refocuses the input element.
     *
     * @param event form button event.
     */
    public onClear(event: FormButtonEvent) {
        this.ttModel = '';
        this.onModelChanged('');
        this.ttOnClear.emit(event);

        if (this.inputRef?.nativeElement) {
            this.inputRef.nativeElement.focus();
        }
    }

    /**
     * Returns the data set as a promise, `ttSearch` takes presedence over `ttData`.
     *
     * @returns the data set as a promise.
     */
    public onSearch: () => Promise<TType[]> = async () => {
        let result: TType[] = [];
        this.searching = true;

        if (this.ttSearch instanceof Function) {
            const parms: { [key: string]: string } = {};
            parms[this.ttSearchParmName] = this.ttModel;

            result = await this.ttSearch(this.ttSearchParm);
        } else if (this.ttData) {
            result = this.ttData;
        }

        this.searching = false;

        return result;
    };

    /**
     * Emits the `ttOnBlur` event when the input field loses focus.
     *
     * @param _ unused focus event.
     */
    public onBlur(_: FocusEvent) {
        this.ttOnBlur.emit(this.ttModel);
    }

    selectText() {
        if (this.inputRef) {
            this.inputRef.nativeElement.select();
        }
    }
}
