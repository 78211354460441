(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory6Lang', {
        templateUrl: 'views/components/views/textileModelCategory6Lang/textileModelCategory6Lang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'textileModelCategory6LangService', function ($stateParams, stateService, textileModelCategory6LangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var textileModelCategory6Keyno = $stateParams.textilemodelcategory6_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory6LangService.listTextileModelCategory6Languages({ textilemodelcategory6_keyno: textileModelCategory6Keyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                textileModelCategory6LangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();