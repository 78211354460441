<tt-page>
    <tt-page-heading ttModel="p2_m_sirvsetup" ttTranslate></tt-page-heading>
    <div class="col-xs-12 sp-0" style="max-width: 180rem">
        <tt-card class="col-xs-12 col-md-6 p-5" [ttHeading]="'Oppsett'" ttIcon="fal fa-wrench">
            <tt-input class="col-xs-12 sp-2" ttLabel="sirv_company_url" [(ttModel)]="edit.sirv_company_url"></tt-input>
            <tt-input class="col-xs-12 sp-2" ttLabel="sirv_client_id" [(ttModel)]="edit.sirv_client_id"></tt-input>
            <tt-input class="col-xs-12 sp-2" ttLabel="sirv_client_secret" [(ttModel)]="edit.sirv_client_secret"></tt-input>
            <tt-button class="col-xs-6 sp-2" ttText="sirvsetup_test_connection" (ttClick)="testConnection()" ttIcon="far fa-vial" [ttLoading]="locks.test"></tt-button>
            <tt-button-save class="col-xs-6 sp-2" ttText="sirvsetup_save" (ttClick)="saveSetup()" [ttLoading]="locks.save"></tt-button-save>
        </tt-card>
        <tt-card class="col-xs-12 col-md-6 p-5" ttHeading="Test" ttIcon="fal fa-vial">
            <tt-file-uploader class="col-xs-12 sp-0" ttLabel="sirvsetup_select_image" [(ttFiles)]="files" ttDropZone ttMultiple="false"></tt-file-uploader>
            <tt-button *ngIf="files.length > 0" class="col-xs-12 sp-0" ttText="sirvsetup_upload_image" (ttClick)="uploadImage()" ttIcon="far fa-cloud-upload" [ttLoading]="locks.upload"></tt-button>
        </tt-card>
    </div>
</tt-page>

<!-- <div class="col-xs-12 sp-0">
    <div class="row-fluid">
        <form class="form-horizontal im-no-pad" name="formSirvSetup" novalidate method="get" role="form">
            <div class="col-xs-12 well well-sm">
                <tt-input class="col-xs-12 sp-0" tt-label="sirv_company_url" tt-model="vm.model.edit.sirv_company_url" tt-change="vm.model.edit.sirv_company_url = $value" tt-readonly="false"></tt-input>
                <tt-input class="col-xs-12 sp-0" tt-label="sirv_client_id" tt-model="vm.model.edit.sirv_client_id" tt-change="vm.model.edit.sirv_client_id = $value" tt-readonly="false"></tt-input>
                <tt-input class="col-xs-12 sp-0" tt-label="sirv_client_secret" tt-model="vm.model.edit.sirv_client_secret" tt-change="vm.model.edit.sirv_client_secret = $value" tt-readonly="false"></tt-input>
                <tt-button-save class="col-xs-12 sp-0" tt-text="sirvsetup_save" tt-locked="vm.model.lockedSave" tt-click="vm.saveChanges()" tt-text-align="center"></tt-button-save>
                <im-button-directive name="sirvsetup_test_connection" optionfunc="vm.testConnection()" spin="vm.model.testingConnection" btnstyle="btn-primary" glyphs="glyphicon-refresh" btnsize="col-xs-12 sp-0"></im-button-directive>
                <div class="col-xs-12 sp-0" style="margin-bottom: 10px;">
                    <label for="sirvsetup_select_image" class="col-xs-12 btn btn-lg btn-primary">
                        <span style="color: white;" im-translate="sirvsetup_select_image">
                            <span class="glyphicon glyphicon-upload" style="color: white;"></span>
                        </span>
                        <span style="color: white;" ng-show="vm.model.selectedImageName.length > 0">: {{vm.model.selectedImage}}</span>
                    </label>

                    <input id="sirvsetup_select_image" ng-hide="true" type="file" ng-model="vm.model.uploadImage" ngf-select multiple />
                </div>
                <div class="col-xs-12 sp-0" ng-show="vm.model.uploadImage.length > 0">
                    <im-button-directive name="sirvsetup_upload_image" badge="" optionfunc="" btnstyle="btn-primary" btnsize="col-xs-12 sp-0" glyphs="glyphicon-upload" ng-click="vm.uploadImage()"></im-button-directive>
                </div>
            </div>
        </form>
    </div>
</div> -->
