(function () {
    'use strict';

    angular.module("imApp").factory("pickJobGrpBatchService", ['$ihttp', function ($ihttp) {
        var service = {
            getPickJobGrpBatch: function (parms) {
                return $ihttp.post({
                    method: 2247,
                    parameters: parms || {}
                });
            },
            activateStockTransfer: function (parms) {
                return $ihttp.post({
                    method: 2333,
                    parameters: parms
                });
            },
            setFinished: function (parms) {
                return $ihttp.post({
                    method: 2334,
                    parameters: parms
                });
            },
            removeItems: function (mergeObj) {
                return $ihttp.post({
                    method: 2277,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();