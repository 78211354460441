(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListExpenses', {
        templateUrl: 'views/components/views/srCheckListExpenses/srCheckListExpenses.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'srCheckListExpensesService', function($stateParams, stateService, srCheckListExpensesService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                menuExpenses: {},
                itemsListExpenses: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListExpensesService.loadSrCheckListExpensesGet(vm.model.checkListDataKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.menuExpenses);
            });
    
            srCheckListExpensesService.loadSrCheckListExpensesList(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListExpenses);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srchecklistexpense':
                        go({
                            checklistdata_keyno: vm.model.menuExpenses.checklistdata_keyno,
                            srrefundinput_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();