(function () {
    'use strict';

    angular.module('imApp').factory('productHazardLabelsService', ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadProductHazardLabelsList: function (prod_keyno) {
                return p2DataTaskService.call(3177, {
                    prod_keyno: prod_keyno
                });
            },
            saveProductHazardLabels: function (prod_keyno, items) {
                console.log(prod_keyno);
                console.log(items);
                return p2DataTaskService.call(3178, {
                    prod_keyno: prod_keyno,
                    items: items
                });
            }
        };

        return service;
    }]);
})();
