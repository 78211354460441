(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poCreate', {
        templateUrl: 'views/components/views/poCreate/poCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'poCreateService', 'logisticService', 'rememberService', 'typeaheadService', function ($stateParams, stateService, utilityService, modalService, translateService, poCreateService, logisticService, rememberService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            var variableNames = {
                businessco_no: '',
                stockplace_id: '',
                p2_printer_keyno: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                stockKeyno: $stateParams.stock_keyno,
                poKeynoLastButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('po', { po_keyno: vm.model.edit.po_keyno_last }) }
                ],
                edit: {},
                selectListBusinessCompanies: [],
                selectListStockPlaces: [],
                selectListProductSizes: [],
                selectListProductChoices: [],
                selectListPrinters: [],
                lockedCreate: false,
                lockedCreateAndBack: false,
                lockedSave: false,
                lockedSaveAndBack: false
            };

            // #endregion VARIABLES & DEFINITIONS
            
            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            var loadGet = function () {
                poCreateService.getPoCreate({ stock_keyno: vm.model.stockKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    loadStockPlaces();
                });
            };

            loadGet();

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListBusinessCompanies);
            });

            logisticService.listPrinters().then(function (result) {
                angular.copy(result, vm.model.selectListPrinters);
            });

            var loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            var loadProductSizes = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                vm.model.selectListProductSizes = [];

                logisticService.listProductSizes({ prod_id: vm.model.edit.prod_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductSizes);
                });
            };

            var loadProductChoices = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;

                vm.model.selectListProductChoices = [];

                logisticService.listProductChoices({ prod_id: vm.model.edit.prod_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductChoices);
                });
            };

            var loadChangedProdId = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prod_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prod_name, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.nbr_of_batches, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.quantity, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.lastdt_finish) !== true) return;

                poCreateService.changedProdId(vm.model.edit).then(function (response) {
                    vm.model.edit.prodsize_keyno = response[0].prodsize_keyno;
                    vm.model.edit.prodchoice_keyno = response[0].prodchoice_keyno;
                    vm.model.edit.stock_keyno = response[0].stock_keyno;
                    vm.model.edit.stockquantity_pr_batch = response[0].stockquantity_pr_batch;
                    vm.model.edit.nbr_of_prodsize = response[0].nbr_of_prodsize;
                    vm.model.edit.nbr_of_prodchoice = response[0].nbr_of_prodchoice;
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        variableValue = vm.model.edit.businessco_no;
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.edit.stockplace_id) !== true) return;

                        variableValue = vm.model.edit.stockplace_id;
                        break;
                    case 'p2_printer_keyno':
                        if (utilityService.validateParmsValue(vm.model.edit.p2_printer_keyno) !== true) return;

                        variableValue = vm.model.edit.p2_printer_keyno;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchProduct({ prod_name: value || '' });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SET VALUE FUNCTION

            vm.setProdId = function (item) {
                vm.model.edit.prod_id = angular.isUndefined(item) ? '' : item.item_id;
                vm.model.edit.prod_name = angular.isUndefined(item) ? '' : item.item_name;

                if (vm.model.edit.prod_id > '') {
                    loadChangedProdId();
                    loadProductSizes();
                    loadProductChoices();
                } else {
                    vm.model.edit.prodsize_keyno = '0';
                    vm.model.selectListProductSizes = [];
                    vm.model.edit.prodchoice_keyno = '0';
                    vm.model.selectListProductChoices = [];
                    vm.model.edit.stock_keyno = '0';
                    vm.model.edit.stockquantity_pr_batch = '0.00';
                    vm.model.edit.nbr_of_prodsize = '0';
                    vm.model.edit.nbr_of_prodchoice = '0';
                }
            };

		    // #endregion SET VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                poCreateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCreate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCreate = false;

                        poCreateService.getPoCreate({ stock_keyno: vm.model.stockKeyno }).then(function (result) {
                            angular.copy(result[0], vm.model.edit);
                        });
                    }
                });
            };

            vm.createAndBackChanges = function () {
                vm.model.lockedCreateAndBack = true;

                poCreateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCreateAndBack = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCreateAndBack = false;
                        stateService.back();
                    }
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                poCreateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                    vm.model.lockedSaveAndBack = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        
                        poCreateService.getPoCreate({ stock_keyno: vm.model.stockKeyno }).then(function (result) {
                            angular.copy(result[0], vm.model.edit);
                        });
                    }
                });
            };

            vm.saveAndBackChanges = function () {
                vm.model.lockedSaveAndBack = true;

                poCreateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                    vm.model.lockedSaveAndBack = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSaveAndBack = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
            // #region RECALCULATION FUNCTIONS

            var recalcQuantity = function () {
                vm.model.edit.quantity = utilityService.parseNumber(vm.model.edit.stockquantity_pr_batch) * utilityService.parseNumber(vm.model.edit.nbr_of_batches);
            };

		    // #endregion RECALCULATION FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'prodsize_keyno':
                        vm.model.edit.prodsize_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;

                        loadChangedProdId();
                        break;
                    case 'prodchoice_keyno':
                        vm.model.edit.prodchoice_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodchoice_keyno) !== true) return;

                        loadChangedProdId();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stockplace_id) !== true) return;

                        vm.model.edit.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        loadChangedProdId();
                        break;
                    case 'nbr_of_batches':
                        if (utilityService.validateWatchValue(value, vm.model.edit.nbr_of_batches) !== true) return;

                        vm.model.edit.nbr_of_batches = value;

                        if (utilityService.validateParmsValue(vm.model.edit.nbr_of_batches) !== true) return;

                        recalcQuantity();
                        break;
                    case 'p2_printer_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.p2_printer_keyno) !== true) return;

                        vm.model.edit.p2_printer_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.p2_printer_keyno) !== true) return;

                        rememberFunc('p2_printer_keyno');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
