(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('domObjectType', {
        templateUrl: 'views/components/views/domObjectType/domObjectType.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'domObjectTypeService', function ($stateParams, stateService, utilityService, translateService, domObjectTypeService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let domObjectTypeKeyno = $stateParams.domobjecttype_keyno;
            
            let translations = {
            	error: '',
            	ok: ''
            };
            
            vm.model = {
                domObjectTypeButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('domobjecttypes', {}) }
                ],
                edit: {},
                selectListDomObjectTypes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
            	domObjectTypeService.getDomObjectType({ domobjecttype_keyno: domObjectTypeKeyno }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);
            	});
            };
            
            let loadDomObjectTypes = function () {
                domObjectTypeService.listDomObjectTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListDomObjectTypes);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                domObjectTypeService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadDomObjectTypes();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
