(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('docArticlePorus', {
        templateUrl: 'views/components/views/docArticlePorus/docArticlePorus.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'docArticlePorusService', function($stateParams, docArticlePorusService) {
            var vm = this;
            
    		vm.model = {
    			docArticleKeyNo: $stateParams.docarticle_keyno,
    			itemsListPorus: []
    		};
            
    		docArticlePorusService.loadDocArticlePorusList(vm.model.docArticleKeyNo).then(function (data) {
    			angular.copy(data, vm.model.itemsListPorus);
    		});
    	}]
    });
})();