(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("transportAreaPpService", ['$ihttp', function ($ihttp) {
		let service = {
            getPostPlace: function (parms) {
                return $ihttp.post({
                    method: 1405,
                    parameters: parms || {}
                });
            },
            listTransportAreas: function (parms) {
                return $ihttp.post({
                    method: 1397,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1406,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
