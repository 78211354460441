(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderPriceUpdate', {
        templateUrl: 'views/components/views/orderPriceUpdate/orderPriceUpdate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$timeout', 'layoutService', 'orderPriceUpdateService', function ($q, $timeout, layoutService, orderPriceUpdateService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];
            let timerOrderSearch = null;
            vm.gridReady = false;

            vm.fontSize = '14px';

            vm.orderSearchText = '';

            vm.customers = {
                isReady: false,
                searchingCustomers: false,
                rememberId: 'w_orderpriceupdate.customers',
                selected: {
                    field: '',
                    key: ''
                },
                list: []
            };

            //vm.orders = {
            //    isReady: false,
            //    searchingOrders: false,
            //    rememberId: 'w_orderpriceupdate.orders',
            //    selected: {
            //        field: '',
            //        key: ''
            //    },
            //    list: []
            //};

            vm.grid = {
                dataTask: {
                    rememberId: 'w_orderpriceupdate.grid',              // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        //method: 1000012,
                        //parameters: { p2_exportproc_keyno: '1000008' }
                        method: 2616,
                        parameters: {
                            order_order_no: vm.orderSearchText,
                            stat_cust_no: vm.customers.selected.key
                        }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            { name: 'updatePrice', text: 'tt_update_price', func: function (data) { updatePrice(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling, 'limit' will trigger only headers, and not toolbar
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            let optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region TOOLBAR BUTTON VALIDATION

            let getIsSelectedItems = function () {
                let dirtyItems = vm.grid.gridfunc.getDirtyRows();
                let isSelectedItems = [];

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                });

                return isSelectedItems;
            }

            let hasDirty = function () {
                let parmItems = getIsSelectedItems();

                if (parmItems.length > 0) return false;

                return true;
            };

            let disable = function () {
                return true;
                //if (isSaveRunning) return true;
            };

            // #endregion TOOLBAR BUTTON VALIDATION

            // #region TOOLBAR BUTTON FUNCTIONS

            let updatePrice = function () {
                console.log('updatePrice');

                orderPriceUpdateService.updatePrice({ items: getIsSelectedItems() }).then(function (result) {
                    if (result[0].errorcode === '0') {
                        vm.grid.gridfunc.rebind();
                    }
                });
            }

            // #endregion TOOLBAR BUTTON FUNCTIONS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            vm.rememberFunc = function (parm) {
                //vm.model.searchText = parm;
                //orderPriceUpdateService.remember(vm.customers.rememberId, vm.customers.selected.key);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region LOOKUPSEARCH FUNCTIONS

            vm.selectCustomer = function (item, model) {
                if (angular.isDefined(item) && angular.isDefined(item.cust_no) && item.cust_no !== null && item.cust_no !== '' && item.cust_no !== vm.customers.selected.key) {
                    console.dir(item);
                    vm.customers.selected.field = item.cust_keyname;
                    vm.customers.selected.key = item.cust_no;
                    vm.grid.dataTask.loadData.parameters = {
                        order_order_no: vm.orderSearchText,
                        stat_cust_no: vm.customers.selected.key
                    };

                    reload();
                }

                //rememberFunc();
            };

            //vm.selectOrder = function (item, model) {
            //    //if (angular.isDefined(item) && angular.isDefined(item.shipmentcontainer_keyno) && item.shipmentcontainer_keyno !== null && item.shipmentcontainer_keyno !== '') {
            //    //    console.dir(item);
            //    //    vm.containers.selected.field = item.shipmentcontainer_keyname;
            //    //    vm.containers.selected.key = item.shipmentcontainer_keyno;
            //    //    vm.containers.selected.status = item.shipmentcontainerstatus_keyno;
            //    //    vm.containers.selected.edistatus = item.edistatus;
            //    //    vm.grid.dataTask.loadData.parameters = { shipmentcontainer_keyno: vm.containers.selected.key };
            //    //}

            //    //if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();

            //    //rememberFunc();
            //};

            vm.updateSearch = function (orderNo) {
                if (angular.isDefined(orderNo) && orderNo !== null && orderNo !== vm.orderSearchText) {
                    if (angular.isDefined(timerOrderSearch)) {
                        $timeout.cancel(timerOrderSearch);
                    }

                    timerOrderSearch = $timeout(function () {
                        vm.orderSearchText = orderNo;

                        vm.grid.dataTask.loadData.parameters = {
                            order_order_no: vm.orderSearchText,
                            stat_cust_no: vm.customers.selected.key
                        };

                        reload();
                    }, 500);
                }
            };

            function reload() {
                if (!vm.gridReady) {
                    vm.gridReady = true;
                } else if (vm.grid.gridfunc !== null) {
                    if (vm.orderSearchText.length > 0 || vm.customers.selected.key > 0) {
                        vm.grid.gridfunc.rebind();
                    }
                }
            }

            // #endregion LOOKUPSEARCH FUNCTIONS

            // #region LAYOUT FUNCTIONS

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            // #endregion LAYOUT FUNCTIONS

            // #region ANGULAR FUNCTIONS

            function prepareLists() {
                let deferred = $q.defer();

                let promises = [];

                promises.push(orderPriceUpdateService.getCustomersKeynameList());
                //promises.push(orderPriceUpdateService.getOrdersKeynameList());

                $q.all(promises).then(function (response) {
                    angular.copy(response[0], vm.customers.list);
                    //angular.copy(response[1], vm.orders.list);

                    deferred.resolve(response);
                }, function (error) {
                    console.log('error! error! error! error! error!');

                    if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                        console.log(error.data.message);

                        if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                            console.log(error.data.data.message);
                        }
                    } else {
                        if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                            console.log(error.data.data.message);
                        }
                    }

                    console.dir(error);

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            vm.$onInit = function () {
                prepareLists().then(function (data) {
                    //console.dir(data);
                    //vm.gridReady = true;
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
