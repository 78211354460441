(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productEans', {
        templateUrl: 'views/components/views/productEans/productEans.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productEansService', function ($stateParams, stateService, productEansService) {
            let vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                itemsListProductEans: []
            };

            let loadProductEans = function () {
                productEansService.listProductEans({ prod_id: prodId }).then(function (result) {
                    angular.copy(result, vm.model.itemsListProductEans);
                });
            };
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'productean':
                        stateService.go(state, {
                            prod_id: prodId,
                            productean_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadProductEans();
            };
        }]
    });
})();
