(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('freightRateSet', {
        templateUrl: 'views/components/views/freightRateSet/freightRateSet.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'freightRateSetService', function ($stateParams, $q, freightRateSetService) {
            const vm = this;
            let freightRateSetKeyno = $stateParams.freightrateset_keyno;
            var editing = false;
            
            vm.model = {
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        //vm.resizeColumnsRates();
                                    }

                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
                        
                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                editing = true;
                            } else {
                                //vm.resizeColumnsRates();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', enabled: true, show: false },
                        { item_id: '1', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '2', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '4', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '5', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '6', item_name: 'edit', glyph: 'fa-edit', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                setting: {},
                itemsListRates: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                freightRateSetService.getSetting({ freightrateset_keyno: freightRateSetKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadFreightRateSets();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadFreightRateSets = function () {
                return freightRateSetService.listFreightRateSets(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListRates);
                });
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
