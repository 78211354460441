(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("crmMenuService", [function () {               
        let service = {};

        return service;
    }]);
})();
