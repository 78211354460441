(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customerDiscGrpProd', {
        templateUrl: 'views/components/views/customerDiscGrpProd/customerDiscGrpProd.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'customerDiscGrpProdService', function ($stateParams, customerDiscGrpProdService) {
            const vm = this;
            let custNo = $stateParams.cust_no;

            vm.model = {
                inputListCustomerDiscounts: []
            };

            let loadCustomerDiscounts = function () {
                customerDiscGrpProdService.listCustomerDiscounts({ cust_no: custNo }).then(function (list) {
                    angular.copy(list, vm.model.inputListCustomerDiscounts);
                });
            };

            vm.$onInit = function () {
                loadCustomerDiscounts();
            };
        }]
    });
})();
