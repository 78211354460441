(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockCorrection', {
        templateUrl: 'views/components/views/stockCorrection/stockCorrection.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'stockCorrectionService', 'stateService', function ($stateParams, utilityService, stockCorrectionService, stateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;
            
            vm.model = {
                volumeM3Buttons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('prodsizeedit', { prodsize_keyno: vm.model.edit.prodsize_keyno }) }
                ],
                eanListButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('producteans', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                edit: {},
                selectListCodes: []
            };
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
            
            stockCorrectionService.loadP2StockStockCorrectionNew($stateParams.stock_keyno, $stateParams.stocklocation_keyno).then(function () {
                vm.model.edit = stockCorrectionService.edit;
            });
            
            stockCorrectionService.loadStockCorrCodeList().then(function (data) {
                angular.copy(data, vm.model.selectListCodes);
            });
            
            // ################
            // HELPER FUNCTIONS
            // ################
            
            vm.parseFloat = function (str) {
                if (typeof str == 'string') {
                    str = str.replace(',', '.');
                    str = str.replace(' ', '');
                    if (str == '') {
                        str = '0';
                    }
                }

                var out = parseFloat(str);

                if (isNaN(out)) {
                    out = 0;
                }

                if (!isFinite(out)) {
                    out = 0;
                }

                return out;
            };
            
            // ##################
            // CALCULATE FUNCTION
            // ##################
    
            vm.calcStockStockValue = function () {
                vm.model.edit.stock_stockvalue_change = vm.parseFloat(vm.model.edit.stock_stockvalue_new) - vm.parseFloat(vm.model.edit.stock_stockvalue_old);
            };
            
            vm.calcStockValueNew = function () {
                //stock_stockvalue_new = stock_on_shelf_new + stockbatch_qty_assigned
                vm.model.edit.stock_stockvalue_new = vm.parseFloat(vm.model.edit.stock_onshelf_new) + vm.parseFloat(vm.model.edit.stockbatch_qty_assigned);
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockget':
                        go({ stock_keyno: vm.model.edit.stock_keyno });
                        break;
                    case 'picturebanks':
                        go({
                            argtype: 'stock_keyno',
                            argvalue: vm.model.edit.stock_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // ####################
            // SAVE BUTTON FUNCTION
            // ####################

            vm.saveChanges = function () {
                stockCorrectionService.saveP2StockStockCorrectionNew(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stock_onshelf_new':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stock_onshelf_new) !== true) return;

                        vm.model.edit.stock_onshelf_new = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stock_onshelf_new, true) !== true) return;

                        vm.calcStockValueNew();
                        break;
                    case 'stock_stockvalue_new':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stock_stockvalue_new) !== true) return;

                        vm.model.edit.stock_stockvalue_new = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stock_stockvalue_new, true) !== true) return;

                        vm.calcStockStockValue();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
