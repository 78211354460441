(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("trStartService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_labour_list_tr: {
                item_id: '',
                item_name: '',
                item_parms: '',
                item_state: '',
                records: []
            },
            p2_w_trstart: {
                show_labourlist: '',
                show_setting: ''
            },
            p2_labour_select_scan: {
                webpagename: '',
                labour_no: '',
                barcode: ''
            },
            loadP2LabourListTr: function () {
                var deferred = $q.defer();

                var parms_p2_labour_list_tr = {
                    method: 121
                };

                $ihttp.post(parms_p2_labour_list_tr).then(function (data) {
                    service.p2_labour_list_tr.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_labour_list_tr.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2WTrStart: function () {
                var deferred = $q.defer();

                var parms_p2_w_trstart = {
                    method: 259
                };

                $ihttp.post(parms_p2_w_trstart).then(function (data) {
                    service.p2_w_trstart = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            searchP2LabourSelectScan: function (barcode, webpage_from) {
                var deferred = $q.defer();

                var parms_p2_labour_select_scan = {
                    method: 193,
                    parameters: {
                        barcode: barcode,
                        webpage_from: webpage_from
                    }
                };

                $ihttp.post(parms_p2_labour_select_scan).then(function (data) {
                    service.p2_labour_select_scan = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();