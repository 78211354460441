import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { FormButton } from '../form-field-button/form-field-button.component';
import { Style } from '@app/core/services/core-component.service';

@Component({
    selector: 'tt-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.css'],
})
export class TextareaComponent extends FormFieldBaseComponent implements FormFieldButtons {
    /**
     * **Reactive** - The value to display in the text area.
     */
    @Input()
    public ttModel: string = '';

    /**
     * Event emitted when the value in the text area changes.
     */
    @Output()
    public ttModelChange = new EventEmitter<string>();

    /**
     * Event emitted when the textarea element in the component loses focus.
     */
    @Output()
    public ttOnBlur = new EventEmitter();

    @Input()
    public ttButtonParms?: { [key: string]: any };

    @Input()
    public ttButtons?: FormButton[] = [];

    /**
     * Reference to the input element.
     */
    @ViewChild('inputRef')
    inputRef?: ElementRef<HTMLTextAreaElement>;

    /**
     * Ids of elements in the component.
     */
    public id = {
        textarea: crypto.randomUUID(),
    };

    override style: Style = {
        textarea: {},
    };

    /**
     * Handles changes of the textarea value.
     *
     * @param event the new value.
     */
    onModelChange(event: string) {
        this.ttModelChange.emit(event);
    }

    /**
     * Selects all content in the textarea.
     */
    selectText() {
        if (this.inputRef) {
            this.inputRef.nativeElement.select();
        }
    }
}
