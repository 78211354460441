(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("transportAreaPpAddService", ['$ihttp', function ($ihttp) {
		let service = {
            getAddPostPlace: function (parms) {
                return $ihttp.post({
                    method: 1402,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1403,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
