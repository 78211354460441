(function() {
    'use strict';

    angular.module("imApp").factory("ttDatasourceEditService", ['$ihttp', function($ihttp) {
        var service = {
            init: function (ttDatasourceKeyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'initDatasource',
                        tt_datasource_keyno: ttDatasourceKeyno
                    }
                });
            },

            update: function (datasource) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'updateDatasource',
                        datasource: datasource
                    }
                });
            }
        }

        return service;
    }]);
})();
