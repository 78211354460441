(function () {
    'use strict';

    angular.module('imApp').directive('imGridDevDirective', ['$q', '$ihttp', '$window', '$stateParams', '$timeout', '$rootScope', '$compile', 'translateService', 'userService', 'stateService', 'responsive', 'modalService', 'p2DataTaskService', function ($q, $ihttp, $window, $stateParams, $timeout, $rootScope, $compile, translateService, userService, stateService, responsive, modalService, p2DataTaskService) {
        var directive = {
            restrict: 'E',
            //transclude: true,
            scope: {
                data: '=',          // Used for linking a list to the directive
                setup: '=',         // Used for linking the setup variables
                optionfunc: '&?',   // Used as reference to funtion in Controller
                gridfunc: '=?',     // Used for linking grid functions back to the Controller
            },
            template:
                //'<button ng-click="goOptionFunc()">Load</button>' +
                '<div id="{{model.mId}}" kendo-grid k-options="gridOptions" ng-show="model.initialized"></div>',
            link: function ($scope, elem) {
                $scope.model = {
                    mId: uuid(),
                    grid: null,
                    gridSetup: {},
                    gridDataSource: {data: [], schema: {}, pageSize:25},
                    gridDataSourceData: [],
                    gridDataSourceSchema: {},
                    clickedCell: { dataItem: {}, column: {}},
                    columnCheckBoxes: {},
                    //nonReordableColumn: [],
                    selectedRows: {index:[], data:[]},
                    toggle: {headers:false},
                    isReady: { setup: false, data: false },
                    initialized: false,
                    autosave: true,
                    translations: {},
                    navigateDirection: 0,
                    hasBools: false,
                    //bhw
                    enterKeyPressed: 0
                };

                // ###############################
                // #region remember
                // ###############################

                var setRememberSetup = function () {
                    //console.log('setRememberSetup');
                    //console.dir($scope.setup);

                    //console.dir($scope.model.grid.dataSource.filter())
                    //console.dir($scope.model.grid.dataSource.sort());

                    var rememberSetup = {
                        columns: [],
                        //filters: $scope.model.grid.dataSource.filter().filters,
                        //sort: $scope.model.grid.dataSource.sort(),
                        localData: $scope.setup.localData,
                        translate: $scope.setup.translate
                        //behaviour: $scope.setup.behaviour
                    };

                    if (angular.isDefined($scope.setup.filter)) {
                        var options = $scope.model.grid.getOptions();
                        //console.log('setRememberSetup');
                        //console.dir(options.filterable);
                        var filter = {};
                        filter.enabled = $scope.setup.filter.enabled;
                        if (options.filterable === false) {
                            filter.mode = false;
                        } else {
                            filter.mode = true;
                        }
                        rememberSetup['filter'] = filter;
                    }

                    angular.forEach($scope.model.grid.columns, function (col) {
                        if (col.field !== 'grid_functions') {
                            var remCol = {
                                field: col.field
                            };

                            //if (col.filterable === false || col.filterable === true) {
                            //    remCol.filterable = col.filterable;
                            //} else {
                            //    if (angular.isDefined(col.filterable.cell.operator)) remCol.filterable.cell.operator = col.filterable.cell.operator;
                            //    if (angular.isDefined(col.filterable.cell.suggestionOperator)) remCol.filterable.cell.suggestionOperator = col.filterable.cell.suggestionOperator;
                            //}

                            if (angular.isDefined(col.hidden)) remCol.hidden = col.hidden;
                            if (angular.isDefined(col.width)) remCol.width = col.width;

                            rememberSetup.columns.push(remCol);
                        }
                    });

                    if (angular.isDefined($scope.model.grid.dataSource.filter())) {
                        angular.forEach($scope.model.grid.dataSource.filter().filters, function (filter) {
                            for (var c = 0; c < rememberSetup.columns.length; c++) {
                                if (filter.field === rememberSetup.columns[c].field) {
                                    rememberSetup.columns[c].filter = { operator: filter.operator, value: filter.value };
                                    break;
                                }
                            }
                        });
                    }

                    if (angular.isDefined($scope.model.grid.dataSource.sort())) {
                        angular.forEach($scope.model.grid.dataSource.sort(), function (sort) {
                            for (var c = 0; c < rememberSetup.columns.length; c++) {
                                if (sort.field === rememberSetup.columns[c].field) {
                                    rememberSetup.columns[c].sort = { dir: sort.dir };
                                    break;
                                }
                            }
                        });
                    }

                    return rememberSetup;
                };


                var rememberCall = function () {
                    // 20220128 PAH - Utility function for base64encode with unicode support
                    var utoa = function (data) {
                        return btoa(unescape(encodeURIComponent(data)));
                    }

                    var rememberSetup = setRememberSetup();

                    return p2DataTaskService.call(616, {
                        //variablename: 'gridsetup.' + $scope.setup.remember,
                        is_base64: 1,
                        variablename: $scope.setup.remember,
                        variablevalue: utoa(angular.toJson(rememberSetup))
                    });
                };

                var timerRemember = null;

                $scope.rememberFunc = function () {
                    if (angular.isDefined(timerRemember)) {
                        $timeout.cancel(timerRemember);
                    }

                    //var variableNameSearchText = 'w.checklists.searchtext';

                    //if (canRun616(variableNameSearchText, 'searchtext') !== true) return;

                    //if (angular.isUndefined($scope.setup) || angular.isUndefined($scope.setup.rememberName) || $scope.setup.rememberName === '') return;
                    if (angular.isUndefined($scope.setup) || angular.isUndefined($scope.setup.remember) || $scope.setup.remember === '') return;
                    //if (angular.isUndefined($scope.setup.rememberValue)) $scope.setup.rememberValue = '';

                    timerRemember = $timeout(function () {
                        rememberCall()
                    }, 500);
                };

                // ###############################
                // #endregion remember
                // ###############################

                // ###############################
                // #region functions
                // ###############################

                $scope.goTo = function (item) {
                    if (angular.isUndefined(item)) return;
                    if (angular.isUndefined(item.item_path) && (angular.isUndefined(item.item_state) || angular.isUndefined(item.item_parms))) return;
                    if (item.item_path === '' && (item.item_state === '' || item.item_parms === '')) return;

                    stateService.go(item.item_state, item.item_parms);
                };

                $scope.goOptionFunc = function (func) {
                    //console.log('goOptionFunc');
                    if (angular.isFunction($scope.optionfunc)) {
                        //console.dir($scope.model.grid);
                        $scope.data = $scope.model.grid.dataSource.data();
                        $timeout(function () {
                            $scope.optionfunc({ data: func });
                        }, 10);
                    }
                };

                $scope.goShowHideColumns = function (mode) {
                    //var options = $scope.model.grid.getOptions();
                    //$scope.model.grid.hideColumn(1);
                    //console.dir(options);
                    //modalService.showHideColumns(options.columns).then(function (value) {
                    modalService.gridColumnsModal($scope.model.grid, mode).then(function (value) {
                        //console.log('value');
                        //console.dir(value);
                        //$scope.model.field = value;
                        //if (angular.isFunction($scope.optionfunc) === true) {
                        //    $timeout(function () {
                        //        $scope.goTo();
                        //    }, 100);
                        //} else {
                        //    $timeout(function () {
                        //        $scope.goChange();
                        //    }, 100);
                        //}
                    });
                };

                $scope.goGridFunc = function (e, func) {
                    //console.log('goGridFunc - ' + func);
                    //console.log('goGridFunc');
                    //console.dir(e);
                    //console.dir(e.model);
                    //console.dir($scope.model.grid.dataSource.get(e.model.id));
                    //console.dir($scope.model.grid.dataItem($(e.target).closest("tr")));
                    //var dataItem = this.dataItem($(e.currentTarget).closest("tr"));
                    var dataItem = $scope.model.grid.dataItem($(e.target).closest("tr"));
                    //console.dir(dataItem);
                    //console.dir(dataItem);
                    //console.log('clicked on row: ' + dataItem[0].rowIndex);

                    $scope.goGridFuncDataItem(dataItem, func);

                    //var options = $scope.model.grid.getOptions();
                    ////console.dir(options);
                    ////console.log('the page is: ' + options.dataSource.page);
                    ////console.dir($scope.gridOptions);
                    ////console.dir($scope.model.gridDataSourceData);

                    //var item = $scope.model.gridDataSourceData[options.dataSource.pageSize * (options.dataSource.page - 1) + dataItem[0].rowIndex];
                    ////console.dir(item);

                    //if (func === 'goTo') $scope.goTo(item);
                    //$scope.model.grid.select();
                    //if (angular.isFunction($scope.optionfunc)) {
                    //    $scope.optionfunc();
                    //}
                };

                $scope.goGridFuncDataItem = function (dataItem, func) {
                    //var options = $scope.model.grid.getOptions();
                    //console.dir(options);
                    //console.dir(dataItem);

                    //var item = $scope.model.gridDataSourceData[options.dataSource.pageSize * (options.dataSource.page - 1) + dataItem[0].rowIndex];

                    if (func === 'goTo') $scope.goTo(dataItem);
                };

                $scope.gridToolbarBtnClick = function (name) {
                    //console.log('toolbarBtn Clicked - ' + name);

                    var options = $scope.model.grid.getOptions();
                    if (name === 'filter') {
                        //console.log('filter');
                        //var gridFilterRowHeight = 39;
                        //console.dir(options);
                        if (angular.isDefined(options.filterable.mode)) {
                            //console.log('filter if');
                            $scope.model.grid.dataSource.filter({});
                            options.dataSource.filter = undefined;
                            options.filterable = false;
                            options.excel.filterable = false;
                            options.pdf.filterable = false;
                            //options.height += gridFilterRowHeight;
                        } else {
                            //console.log('filter else');
                            options.filterable = { mode: "row" };
                            options.excel.filterable = true;
                            options.pdf.filterable = true;
                            //options.height -= gridFilterRowHeight;
                        }
                        //console.log('filter end');
                        //options.height = newHeight;
                        //options.columns = $scope.model.gridSetup.columns;
                        //options.dataSource.schema = $scope.model.gridDataSourceSchema;
                        $scope.model.grid.setOptions(options);
                        if ($scope.model.autosave) {
                            $scope.rememberFunc();
                        }
                    } else if (name === 'filterClear') {
                        //console.dir(options);
                        //console.dir($scope.model.grid.dataSource);
                        $scope.model.grid.dataSource.filter({});
                        options.dataSource.filter = undefined;
                        $scope.model.grid.setOptions(options);
                    } else if (name === 'columns') {
                        //console.dir(options);
                        //console.dir($scope.model.grid.dataSource);
                        $scope.goShowHideColumns();
                    } else if (name === 'colShowHide') {
                        //console.dir(options);
                        //console.dir($scope.model.grid.dataSource);
                        $scope.goShowHideColumns('colShowHide');
                        //$scope.model.grid.dataSource.filter({});
                        //options.dataSource.filter = undefined;
                        //$scope.model.grid.setOptions(options);
                    } else if (name === 'colEdit') {
                        $scope.goShowHideColumns('colEdit');
                    } else if (name === 'colLock') {
                        $scope.goShowHideColumns('colLock');
                    } else if (name === 'toggleHeaders') {
                        if (angular.isDefined($scope.setup.translate) && $scope.setup.translate === true) {
                            //console.dir($scope.model.gridSetup.columns);
                            //console.dir(options.columns);
                            //if ($scope.model.toggle.headers) {
                            //    angular.forEach($scope.model.gridSetup.columns, function (col) {
                            //        col.title = $scope.model.translations[col.field];
                            //        //if (angular.isDefined(col.hidden)) col.title = $scope.model.translations[col.field];
                            //    });
                            //} else {
                            //    angular.forEach($scope.model.gridSetup.columns, function (col) {
                            //        col.title = col.field;
                            //    });
                            //}
                            if ($scope.model.toggle.headers) {
                                angular.forEach(options.columns, function (col) {
                                    col.title = $scope.model.translations[col.field];
                                });
                            } else {
                                angular.forEach(options.columns, function (col) {
                                    col.title = col.field;
                                });
                            }
                            //options.columns = $scope.model.gridSetup.columns;
                            //$scope.model.gridSetup.columns = options.columns;
                            $scope.model.grid.setOptions(options);
                            $scope.model.toggle.headers = !$scope.model.toggle.headers;
                        }
                    } else if (name === 'save') {
                        $scope.rememberFunc();
                    } else if (name === 'refresh') {
                        $scope.model.grid.refresh();
                    }
                };

                $scope.customGridToolbarBtnClick = function (name) {
                    //console.log('custom toolbarBtn Clicked - ' + name);

                    var options = $scope.model.grid.getOptions();
                    for (var f = 0; f < $scope.setup.toolbar.buttons.length; f++) {
                        if ($scope.setup.toolbar.buttons[f].name === name) {
                            //console.dir($scope.setup.toolbar.buttons[f]);
                            if (angular.isFunction($scope.setup.toolbar.buttons[f].func)) {
                                $scope.setup.toolbar.buttons[f].func();
                            }
                            break;
                        }
                    }
                };

                // ###############################
                // #endregion functions
                // ###############################

                // ###############################
                // #region gridfunc
                // ###############################

                var gridAddRow = function (atIndex, dataItem, addBefore) {
                    var selectedRowsCount = $scope.model.selectedRows.index.length;
                    var insertIndex = 0;

                    if (angular.isDefined(atIndex) && isNumber(atIndex)) {
                        insertIndex = atIndex;
                    } else if (selectedRowsCount > 0) {
                        if (angular.isDefined(addBefore) && addBefore === true) {
                            insertIndex = $scope.model.selectedRows.index[selectedRowsCount - 1];
                        } else {
                            insertIndex = $scope.model.selectedRows.index[selectedRowsCount - 1] + 1;
                        }
                    } else {
                        if (angular.isDefined(addBefore) && addBefore === true) {
                            insertIndex = 0;
                        } else {
                            insertIndex = $scope.model.grid.dataSource.data().length;
                        }
                    }

                    dataItem = angular.isDefined(dataItem) ? dataItem : {};

                    if (angular.isUndefined($scope.setup.behaviour) || angular.isUndefined($scope.setup.behaviour.keepSortOnAdd) || $scope.setup.behaviour.keepSortOnAdd !== true) clearSorting();

                    var row = $scope.model.grid.dataSource.insert(insertIndex, dataItem);

                    var td = '';
                    var fields = $scope.model.grid.getOptions().dataSource.schema.model.fields;
                    var columns = $scope.model.grid.columns;
                    var editableKeys = [];

                    angular.forEach(fields, function (dataItem, key) {
                        if (angular.isDefined(dataItem.editable) && dataItem.editable === true) {
                            editableKeys.push(key);
                        }
                    });

                    var foundEditable = false;

                    for (var k = 0; k < editableKeys.length; k++) {
                        for (var c = 0; c < columns.length; c++) {
                            if (columns[c].hidden !== true && columns[c].field === editableKeys[k]) {
                                foundEditable = true;
                                td = ' td:eq(' + c + ')';
                                break;
                            }
                        }
                        if (foundEditable) {
                            break;
                        }
                    }

                    var contact = $scope.model.grid.table.find('tr[data-uid="' + row.uid + '"]' + td);
                    $scope.model.grid.editCell(contact);
                };

                var getDirtyRows = function () {
                    var dirty = [];
                    angular.forEach($scope.model.grid.dataSource.data(), function (dataItem) {
                        //console.log('dataItem');
                        //console.dir(dataItem);
                        if (dataItem.dirty === true) dirty.push(dataItem);
                    });
                    return dirty;
                };

                var getSelectedRows = function () {
                    console.log('getSelectedRows');
                    //console.dir($scope.model.grid);
                    //console.dir(e);
                    //console.dir(e.sender);
                    //var rows = e.sender.select();
                    //console.dir(rows);
                    //console.dir($scope.model.grid.select());
                    var rows = $scope.model.grid.select();
                    $scope.model.selectedRows.index = [];
                    $scope.model.selectedRows.data = [];
                    rows.each(function (r) {
                        var dataItem = $scope.model.grid.dataItem(this);
                        var index = $scope.model.grid.dataSource.indexOf(dataItem);

                        $scope.model.selectedRows.index.push(index);
                        $scope.model.selectedRows.data.push(dataItem);
                    });
                    //bhw
                    if (angular.isDefined($scope.optionfunc)) {
                        $scope.optionfunc({ data: { type: 'selectedCount', selectedCount: $scope.model.selectedRows.data.length } });
                    }
                    //console.dir($scope.model.selectedRows.data);
                    //console.dir($scope.model.selectedRows.index);
                };

                var clearSorting = function () {
                    if (angular.isUndefined($scope.model.grid.dataSource.sort()) || $scope.model.grid.dataSource.sort().length === 0) return;

                    var options = $scope.model.grid.getOptions();
                    options.dataSource.sort = [];
                    $scope.model.grid.setOptions(options);
                };

                // ###############################
                // #endregion gridfunc
                // ###############################

                // ###############################
                // #region onGridFunctions
                // ###############################

                $scope.onBeforeEdit = function (e) {
                    //console.log("Before Edit :: " + e);
                    //console.dir(e);
                    //e.preventDefault();
                    //console.dir(e.model);
                   
                };

                $scope.onCancel = function (e) {
                    console.log("Canceled :: " + e);
                    console.dir(e);
                };

                $scope.onCellClose = function (e) {
                    e.preventDefault();
                    //console.log("Cell Close :: " + e);
                    //console.dir(e);
                    var items = e.sender.items();
                    var current = e.sender.current() || [];
                    var cellIndex = 0;
                    var rowIndex = 0;
                    var dataItem = undefined;
                    if (current[0]) {
                        var options = $scope.model.grid.getOptions();
                        cellIndex = current.index();
                        rowIndex = current.parent().index();

                        var column = $scope.model.grid.columns[cellIndex];

                        dataItem = column;//e.sender.dataItem(current);//e.sender.dataItem(items[rowIndex]);

                        if ($scope.model.navigateDirection > 0) {
                            for (var i = cellIndex + $scope.model.navigateDirection; i < options.columns.length; i++) {
                                cellIndex += $scope.model.navigateDirection;
                                if (angular.isUndefined(options.columns[i]) || angular.isUndefined(options.columns[i].hidden) || options.columns[i].hidden === false) {
                                    break;
                                }
                            }
                        } else if ($scope.model.navigateDirection < 0) {
                            cellIndex--;
                            for (var j = cellIndex + $scope.model.navigateDirection; j >= 0; j--) {
                                if (angular.isUndefined(options.columns[j]) || angular.isUndefined(options.columns[j].hidden) || options.columns[j].hidden === false) {
                                    break;
                                }
                                cellIndex += $scope.model.navigateDirection;
                            }
                        }
                        

                        //$scope.model.gridDataSourceData = $scope.data;
                        $timeout(function () {
                            //console.log($scope.model.grid.dataSource.data()[0].accounttype_db);
                            //console.log($scope.data[0].accounttype_db);
                            //$scope.model.grid.setDataSource($scope.data);
                            //console.log("Data set");
                            //console.log($scope.model.grid.dataSource.data()[0].accounttype_db);
                            //console.log($scope.data[0].accounttype_db);
                            //console.dir($scope.model.grid.dataSource.data());
                            //console.dir($scope.data);
                            //$scope.model.grid.refresh();

                            getSelectedRows();
                        }, 10);
                    }
                    
                    $timeout(function () {
                        try {
                            e.sender.current(e.sender.tbody.children().eq(rowIndex).children().eq(cellIndex));
                        } catch (error) {}                        
                        //bhw
                        if (angular.isDefined($scope.optionfunc)) {
                            $scope.optionfunc({ data: {type: 'onCellClose', row: rowIndex, cell: cellIndex, dataItem: dataItem, grid: $scope.model.grid, enterKeyPressed: $scope.model.enterKeyPressed } });
                            $timeout(function () {
                                $scope.model.enterKeyPressed = 0;
                            },10);
                        }
                        
                    }, 10);
                };

                $scope.onChange = function (e) {
                    //console.log("Grid change :: " + e);
                    //console.dir(e);
                    //console.dir(e.sender);
                    getSelectedRows();
                };

                $scope.onColumnHide = function (e) {
                    console.log("Column hide :: " + e);
                    console.dir(e);
                    if ($scope.model.autosave) {
                        $scope.rememberFunc();
                    }
                };

                $scope.onColumnLock = function (e) {
                    console.log("Column lock :: " + e);
                    console.dir(e);
                };

                $scope.onColumnMenuInit = function (e) {
                    console.log("Column menu init :: " + e);
                    console.dir(e);
                };

                $scope.onColumnMenuOpen = function (e) {
                    console.log("Column menu open :: " + e);
                    console.dir(e);
                };

                $scope.onColumnReorder = function (e) {
                    console.log("Column Reorder :: " + e);
                    console.dir(e);
                    //var grid = e.sender;

                    //setTimeout(function (e) {
                    //    grid.reorderColumn(0, $scope.model.nonReordableColumn);
                    //}, 1);
                    if ($scope.model.autosave) {
                        $scope.rememberFunc();
                    }
                };

                $scope.onColumnResize = function (e) {
                    console.log("Column Resize :: " + e);
                    console.dir(e);
                    console.dir($scope.model.grid);
                    //console.dir($scope.model.grid);
                    if ($scope.model.autosave) {
                        $scope.rememberFunc();
                    }
                };

                $scope.onColumnShow = function (e) {
                    console.log("Column Show :: " + e);
                    console.dir(e);
                    if ($scope.model.autosave) {
                        $scope.rememberFunc();
                    }
                };

                $scope.onColumnStick = function (e) {
                    console.log("Column Stick :: " + e);
                    console.dir(e);
                };

                $scope.onColumnUnlock = function (e) {
                    console.log("Column Unlock :: " + e);
                    console.dir(e);
                };

                $scope.onColumnUnstick = function (e) {
                    console.log("Column Unstick :: " + e);
                    console.dir(e);
                };

                $scope.onDataBinding = function (e) {
                    //console.log("Grid data binding");
                    //console.dir(e.sender);
                    //var i = 0;
                    //var items = e.sender.items();
                    //var columns = e.sender.columns;
                    //var schema = e.sender.dataSource.options.schema;
                    //console.dir(items);
                    //items.each(function () {
                    //    var row = $(this);
                    //    var dataItem = e.sender.dataItem(row);

                    //    if (i < 1) {
                    //        console.dir(row);
                    //        console.dir(dataItem);
                    //        //console.log(dataItem.over_zero);
                    //        console.dir(row.find(".k-grid-gridGlyphs"));
                    //        i++;
                    //    }
                    //});
                };

                $scope.onDataBound = function (e) {
                    //console.log("Grid data bound");
                    //console.dir(e.sender);
                    var i = 0;
                    var items = e.sender.items();
                    var columns = e.sender.columns;
                    var schema = e.sender.dataSource.options.schema;
                    //console.dir(items);
                    //console.dir($scope.model.gridDataSourceData);
                    var row_number = 0;
                    items.each(function () {
                        var row = $(this);
                        var dataItem = e.sender.dataItem(row);

                        //bhw
                        if (angular.isDefined($scope.optionfunc)) {
                            /*console.log('Columns:');
                            console.dir(columns);
                            console.log('DataItem:');
                            console.dir(dataItem);*/
                            $scope.optionfunc({ data: { type: 'dataBoundRow', row: row, rowNumber: row_number, dataItem: dataItem, grid: $scope.model.grid } });
                        }
                        row_number += 1;

                        //if (i < 1) {
                        //    console.dir(row);
                        //    console.dir(dataItem);
                        //    //console.log(dataItem.over_zero);
                        //    console.dir(row.find(".k-grid-gridGlyphs"));
                        //    i++;
                        //}
                        if (!dataItem.hasChildren) {
                            row.find(".k-hierarchy-cell").html("");
                            //row.find(".k-hierarchy-cell a").removeClass("k-icon");
                            //row.find(".k-hierarchy-cell a").removeClass("k-plus");
                            //row.find(".k-hierarchy-cell a").removeClass("k-i-expand");
                            //row.find(".k-hierarchy-cell a").removeAttr("href");
                        }

                        if (angular.isDefined(dataItem.item_glyphicon)) {
                            //console.dir(row.find(".k-grid-gridGlyphs"));
                            //console.dir(row.find(".k-grid-gridGlyphs")[0].children);
                            //console.dir(row.find(".k-grid-gridGlyphs").children());
                            //console.dir(row.find(".k-grid-gridGlyphs").children(0));
                            //row.find(".k-grid-gridGlyphs").children(0).addClass($scope.addGridGlyphPrefix(dataItem.item_glyphicon) + dataItem.item_glyphicon);
                            //<span class=""></span>
                            //row.find(".k-grid-gridGlyphs").children(0).addClass($scope.addGridGlyph(dataItem.item_glyphicon) + dataItem.item_glyphicon);
                            var glyphlist = dataItem.item_glyphicon.split(' ');
                            var glyphlistColor = angular.isDefined(dataItem.item_glyphicon_color) ? dataItem.item_glyphicon_color.split(' ') : [];

                            var glyphCell = row.find(".k-grid-gridGlyphs");

                            for (var g = 0; g < glyphlist.length; g++) {
                                var style = (glyphlistColor.length > 0) ? ' style="color: ' + glyphlistColor[g] + '; width: 16px; padding: 0px 2px;"' : '';
                                //var span = glyphCell.find('span');

                                //width: 20px;

                                //setTimeout(function () {
                                //    //if (!span.length) {
                                //        $('<span class="' + $scope.addGridGlyph(glyphlist[g]) + '"' + style + '></span>').appendTo(glyphCell);
                                //    //}
                                //});
                                $('<span class="' + $scope.addGridGlyph(glyphlist[g]) + '"' + style + '></span>').appendTo(glyphCell);
                                //if (glyphlistColor.length>0) row.find(".k-grid-gridGlyphs").children(0).css('color', dataItem.item_glyphicon_color);
                            }

                            //.children(0).addClass($scope.addGridGlyph(dataItem.item_glyphicon) + dataItem.item_glyphicon);
                            //if (angular.isDefined(dataItem.item_glyphicon_color)) row.find(".k-grid-gridGlyphs").children(0).css('color', dataItem.item_glyphicon_color);
                        }
                    });

                    if (angular.isDefined($scope.model.grid) && $scope.model.grid !== null) {
                        if ($scope.model.hasBools) {
                            var gridRadio = e.sender.element.find('.im-grid-radio');
                            if (angular.isUndefined(gridRadio) || gridRadio.length === 0) {
                                var radioLabels = e.sender.element.find('.k-filter-row label>input[type=radio]');

                                if (angular.isDefined(radioLabels) && radioLabels.length > 0 && gridRadio.length === 0) {
                                    radioLabels.each(function (r) {
                                        if (r % 2 === 0) {
                                            radioLabels[r].insertAdjacentHTML("beforebegin", '<i class="glyphicon glyphicon-check im-grid-radio"></i>');
                                        } else {
                                            radioLabels[r].insertAdjacentHTML("beforebegin", '<i class="glyphicon glyphicon-unchecked im-grid-radio"></i>');
                                        }
                                    });
                                }
                            } 
                        }
                    }

                    //$(this.thead).css('display', 'none');

                    //if (angular.isDefined($scope.model.gridSetup.pager) && $scope.model.gridSetup.pager === false) {
                    //    newHeight -= pagerHeight;
                    //}
                    if (angular.isDefined($scope.model.grid) && $scope.model.grid !== null && e.sender.dataSource.total() <= e.sender.dataSource.pageSize()) {
                        //e.sender.pager.element.hide();
                        setTimeout(function () {
                            if (angular.isDefined($scope.model.gridSetup.pager) && $scope.model.gridSetup.pager === false) {
                                //var gridHeight = $scope.model.grid.element.height();
                                var pagerHeight = $(".k-grid-pager").height();
                                //var newHeight = gridHeight - pagerHeight;
                                //console.dir(e.sender);
                                if (pagerHeight > 0) {
                                    //console.log('gridHeight: ' + gridHeight);
                                    //console.log('pagerHeight: ' + pagerHeight);
                                    //console.log('newHeight: ' + newHeight);
                                    e.sender.pager.element.css('display', 'none');
                                    //$scope.model.grid.element.css("height", newHeight);
                                }
                            }
                            $scope.model.grid.resize();
                        }, 5);
                    //} else {
                    //    //e.sender.pager.element.show();
                    //    e.sender.pager.element.css('display', 'inherit');
                    }

                    if (angular.isDefined($scope.model.grid) && $scope.model.grid !== null) {
                        //setTimeout(function () {
                            if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.hidden)) {
                                if ($scope.setup.toolbar.hidden === true) {
                                    $('.k-grid-toolbar').hide();
                                    //console.dir($('.k-grid-toolbar'));
                                } else if ($scope.setup.toolbar.hidden === false) {
                                    $('.k-grid-toolbar#' + $scope.model.mId).show();
                                }
                            }
                        //}, 5);
                    }

                    var headerCells = e.sender.element.find('th');
                    headerCells.each(function (i, e) {
                        if (angular.isDefined(columns) && angular.isDefined(columns[i]) && angular.isDefined(columns[i].field)
                            && angular.isDefined(schema) && angular.isDefined(schema.model) && angular.isDefined(schema.model.fields)) {
                            var fields = schema.model.fields;
                            //console.dir(fields);
                            if (angular.isDefined(fields[columns[i].field]) && fields[columns[i].field].editable === true) {
                                var headerCell = $(this);
                                var link = headerCell.find("a.k-link");
                                var icon = link.find('span.k-i-edit');

                                //setTimeout(function () {
                                    if (!icon.length) {
                                        if (fields[columns[i].field].type === 'boolean' || fields[columns[i].field].type === 'bit') {
                                            if (angular.isUndefined($scope.model.columnCheckBoxes[columns[i].field]))
                                                $scope.model.columnCheckBoxes[columns[i].field] = false;

                                            //console.log($scope.model.columnCheckBoxes[columns[i - 1].field]);
                                            //bhw
                                            link.prepend('<input type="checkbox" #= ' + $scope.model.columnCheckBoxes[columns[i].field] + ' ? \'checked=true\' : "" # class="im-grid-checkbox" onclick="angular.element(this).scope().checkboxBoxClickAll(event)"/> ');
                                        }
                                        link.prepend('<span class="k-icon k-i-edit"></span> ');
                                    }
                                //}, 5);
                            }
                        }
                    });

                    var r = document.querySelector(':root');
                    //var rs = getComputedStyle(r);
                    //r.style.setProperty('--im-k-alt-bg-color', 'lightblue');
                    if (angular.isUndefined($scope.setup.css) || angular.isUndefined($scope.setup.css.altColor) || $scope.setup.css.altColor === true) {
                        //e.sender.element.style.setProperty('--im-k-alt-bg-color', '#E0E0E0');
                        r.style.setProperty('--im-k-alt-bg-color', '#E0E0E0');
                    } else if ($scope.setup.css.altColor === false) {
                        //e.sender.element.style.setProperty('--im-k-alt-bg-color', undefined);
                        r.style.setProperty('--im-k-alt-bg-color', undefined);
                    } else {
                        //e.sender.element.style.setProperty('--im-k-alt-bg-color', $scope.setup.css.altColor);
                        r.style.setProperty('--im-k-alt-bg-color', $scope.setup.css.altColor);
                    }

                    var wrapper = this.wrapper,
                        header = wrapper.find(".k-grid-header");

                    function resizeFixed() {
                        var paddingRight = parseInt(header.css("padding-right"));
                        header.css("width", wrapper.width() - paddingRight);
                    }

                    function scrollFixed() {
                        var offset = $(this).scrollTop(),
                            tableOffsetTop = wrapper.offset().top,
                            tableOffsetBottom = tableOffsetTop + wrapper.height() - header.height();
                        if (offset < tableOffsetTop || offset > tableOffsetBottom) {
                            header.removeClass("fixed-header");
                        } else if (offset >= tableOffsetTop && offset <= tableOffsetBottom && !header.hasClass("fixed")) {
                            header.addClass("fixed-header");
                        }
                    }

                    var headerRefreshTimer = null;

                    var headerRefresh = function () {
                        if (angular.isDefined(headerRefreshTimer)) {
                            $timeout.cancel(headerRefreshTimer);
                        }

                        headerRefreshTimer = $timeout(function () {
                            if ($scope.model.initialized) {
                                $scope.model.grid.refresh();
                            } else {
                                headerRefresh();
                            }
                        }, 125);
                    };

                    if (angular.isDefined($scope.setup) && angular.isDefined($scope.setup.behaviour) && angular.isDefined($scope.setup.behaviour.fixedHeader) && $scope.setup.behaviour.fixedHeader === true) {
                        //console.log('headerFixed');
                        resizeFixed();
                        $(window).resize(resizeFixed);
                        $(window).scroll(scrollFixed);
                        if (!$scope.model.initialized) headerRefresh();
                    }
                };

                $scope.onDetailCollapse = function (e) {
                    console.log("Detail collapse :: " + e);
                    console.dir(e);
                };

                $scope.onDetailExpand = function (e) {
                    console.log("Detail expand :: " + e);
                    console.dir(e);
                };

                $scope.onDetailInit = function (e) {
                    console.log("Detail init :: " + e);
                    console.dir(e);
                };

                $scope.onEdit = function (e) {
                    console.log("Grid edit :: " + e);
                    //console.dir(e);

                    $scope.model.enterKeyPressed = 0;

                    // var input = e.container.find("input");
                    // setTimeout(function () {
                    //     input.select();
                    // }, 10);

                    $scope.model.navigateDirection = 0;

                    //console.dir($(e.container));
                    $(e.container).keydown(function (e) {
                        //console.dir(e);
                        if (e.keyCode === 9) {
                            //console.log("Tab was pressed");
                            if (e.shiftKey) {
                                $scope.model.navigateDirection = -1;
                            } else {
                                $scope.model.navigateDirection = 1;
                            }

                            
                        }

                        //bhw
                        else if (e.keyCode === 13) {
                            $scope.model.enterKeyPressed = 1;
                        }
                    });

                    getSelectedRows();
                };

                $scope.onExcelExport = function (e) {
                    console.log("Excel Export :: " + e);
                    console.dir(e);
                };

                $scope.onFilter = function (e) {
                    console.log("Grid filter :: " + e);
                    console.dir(e);
                    if ($scope.model.autosave) {
                        $scope.rememberFunc();
                    }
                };

                $scope.onFilterMenuInit = function (e) {
                    console.log("Filter menu init:: " + e);
                    console.dir(e);
                };

                $scope.onFilterMenuOpen = function (e) {
                    console.log("Filter menu open:: " + e);
                    console.dir(e);
                };

                $scope.onGroup = function (e) {
                    console.log("Grid group :: " + e);
                    console.dir(e);
                };

                $scope.onGroupCollapse = function (e) {
                    console.log("Group collapse :: " + e);
                    console.dir(e);
                };

                $scope.onGroupExpand = function (e) {
                    console.log("Group expand :: " + e);
                    console.dir(e);
                };

                $scope.onNavigate = function (e) {
                    //console.log(kendo.format("navigate:: view:{0};", e.view));
                    //console.dir(e);
                    //console.dir(e.element[0].firstChild.classList);

                    if (angular.isDefined($scope.setup) && angular.isDefined($scope.setup.behaviour) && angular.isDefined($scope.setup.behaviour.rowClick) && $scope.setup.behaviour.rowClick === true) {
                        var el = $(e.element[0]);

                        if (el[0].nodeName !== 'TH') {
                            e.preventDefault();
                            var dataItem = el.closest("tr");
                            $scope.goGridFuncDataItem(dataItem, 'goTo');
                        }
                    }

                    //$scope.goGridFunc(e, 'goTo');
                    //var dataItem = $(e.element[0]).closest("tr");
                    //$scope.goGridFuncDataItem(dataItem, 'goTo');
                    //console.dir(elemen);
                    //console.dir(dataItem);
                    //////console.log('clicked on row: ' + dataItem[0].rowIndex);

                    //var options = $scope.model.grid.getOptions();
                    ////console.dir(options);
                    ////console.log('the page is: ' + options.dataSource.page);
                    ////console.dir($scope.gridOptions);
                    ////console.dir($scope.model.gridDataSourceData);

                    //var item = $scope.model.gridDataSourceData[options.dataSource.pageSize * (options.dataSource.page - 1) + dataItem[0].rowIndex];
                    //////console.dir(item);

                    //$scope.goTo(item);
                };

                $scope.onPage = function (e) {
                    console.log("Grid page :: " + e);
                    console.dir(e);
                };

                $scope.onPdfExport = function (e) {
                    console.log("Pdf Export :: " + e);
                    console.dir(e);
                };

                $scope.onRemove = function (e) {
                    console.log("Grid remove :: " + e);
                    console.dir(e);
                };

                $scope.onSave = function (e) {
                    //console.log("Grid save :: " + e); //Saved
                    //console.dir(e);
                    //e.preventDefault();
                };

                $scope.onSaveChanges = function (e) {
                    console.log("Grid save changes :: " + e); //Saved
                    console.dir(e);
                };

                $scope.onSort = function (e) {
                    console.log("Grid sort :: " + e); //Saved
                    console.dir(e);
                    if ($scope.model.autosave) {
                        $scope.rememberFunc();
                    }
                };

                // ###############################
                // #endregion onGridFunctions
                // ###############################

                // ###############################
                // #region utilityFunctions
                // ###############################

                //function isInArray(value, array) {
                //    return array.indexOf(value) > -1;
                //}

                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                function whatType(value, formatType) {
                    var type = 'string'; //default

                    if (angular.isDefined(formatType)) {
                        switch (formatType) {
                            case 'decimal':
                            case 'number':
                                type = 'number';
                                break;
                            case 'date':
                            case 'time':
                            case 'datetime':
                            case 'timestamp':
                                type = 'date';
                                //type = 'string';
                                break;
                            case 'bit':
                            case 'bool':
                            case 'boolean':
                                type = 'boolean';
                                break;
                            default:
                                //console.log('No case for this type, default is string');
                                type = 'string';

                        }
                        return type;
                    }

                    if (angular.isUndefined(value) || value === null || value === '') {
                        type = 'string';
                    } else if (isNumber(value)) {
                        type = 'number';
                    } else if (value === 'true' || value === 'false') {
                        type = 'boolean';
                    }

                    return type;
                }

                function numberWithSpaces(x, deci) {
                    deci = angular.isDefined(deci) ? deci : 0;
                    //return Number.parseFloat(x).toFixed(deci);
                    x = Number(Math.round(parseFloat(x + 'e' + deci)) + 'e-' + deci).toFixed(deci)
                    return kendo.toString(x, "##.#,##").replace(/./g, " ");
                }

                // ###############################
                // #endregion utilityFunctions
                // ###############################

                // ###############################
                // #region gridOptions
                // ###############################

                $scope.gridOptions = {
                    dataSource: $scope.model.gridDataSource,
                    //height: 680,
                    //bhw
                    
                    height: (($scope.setup.height > 0) ? $scope.setup.height : 600),
                    //toolbar: ["excel", "pdf", "search"],
                    toolbar: $scope.toolbar,
                    excel: {
                        allPages: true,
                        filterable: true
                    },
                    pdf: {
                        allPages: true,
                        filterable: true
                    },
                    editable: "incell",
                    scrollable: true,
                    sortable: true,
                    selectable: true,
                    navigatable: true,
                    resizable: true,
                    reorderable: true,
                    groupable: false,
                    filterable: {
                        mode: "row"
                    },
                    //filterable: true,
                    //filterable: false,
                    pageable: {
                        pageSize: 25,
                        input: true,
                        numeric: false
                    },
                    columns: [],
                    beforeEdit: $scope.onBeforeEdit,
                    cancel: $scope.onCancel,
                    cellClose: $scope.onCellClose,
                    change: $scope.onChange,
                    columnHide: $scope.onColumnHide,
                    columnLock: $scope.onColumnLock,
                    columnMenuInit: $scope.onColumnMenuInit,
                    columnMenuOpen: $scope.onColumnMenuOpen,
                    columnReorder: $scope.onColumnReorder,
                    columnResize: $scope.onColumnResize,
                    columnShow: $scope.onColumnShow,
                    columnStick: $scope.onColumnStick,
                    columnUnlock: $scope.onColumnUnlock,
                    columnUnstick: $scope.onColumnUnstick,
                    dataBinding: $scope.onDataBinding,
                    dataBound: $scope.onDataBound,
                    detailCollapse: $scope.onDetailCollapse,
                    detailExpand: $scope.onDetailExpand,
                    //detailInit: $scope.onDetailInit,
                    edit: $scope.onEdit,
                    excelExport: $scope.onExcelExport,
                    filter: $scope.onFilter,
                    filterMenuInit: $scope.onFilterMenuInit,
                    filterMenuOpen: $scope.onFilterMenuOpen,
                    group: $scope.onGroup,
                    groupCollapse: $scope.onGroupCollapse,
                    groupExpand: $scope.onGroupExpand,
                    navigate: $scope.onNavigate,
                    page: $scope.onPage,
                    pdfExport: $scope.onPdfExport,
                    remove: $scope.onRemove,
                    save: $scope.onSave,
                    saveChanges: $scope.onSaveChanges,
                    sort: $scope.onSort
                };

                // ###############################
                // #endregion gridOptions
                // ###############################

                // ###############################
                // #region toolbar templates
                // ###############################

                $scope.gridToolbar = ["excel", "pdf"];

                $scope.showToolbarBtn = function (name) {
                    if (name === 'filter') {
                        return true;
                    } else if (name === 'filterClear') {
                        var options = $scope.model.grid.getOptions();
                        if (angular.isDefined(options) && angular.isDefined(options.dataSource) && angular.isDefined(options.dataSource.filter) && angular.isDefined(options.dataSource.filter.filters)) {
                            return true;
                        } else return false;
                    } else {
                        return true;
                    }
                };

                $scope.makeToolbarBtnTemplate = function (name, text, func, icon, cssClass) {
                    if (angular.isUndefined(name)) name = 'customBtn' + $scope.gridToolbar.length;
                    if (angular.isUndefined(text)) text = name;
                    if (angular.isUndefined(func)) func = 'gridToolbarBtnClick(\'' + name + '\')';
                    if (angular.isUndefined(icon)) icon = 'warning';
                    var show = 'showToolbarBtn(\'' + name + '\')';

                    var containsBtnName = null;
                    for (var t = 0; t < $scope.gridToolbar.length; t++) {
                        if (angular.isDefined($scope.gridToolbar[t].name) && $scope.gridToolbar[t].name === name) {
                            if (name.indexOf('customBtn') <= -1) {
                                containsBtnName = true;
                                break;
                            }
                            if (containsBtnName === true) {
                                break;
                            } else if (containsBtnName === false) {
                                containsBtnName = true;
                            } else {
                                containsBtnName = false;
                            }
                            name = 'customBtn' + ($scope.gridToolbar.length + 1);
                            t = -1;
                        }
                    }

                    if (containsBtnName === true) {
                        console.log('btnName already exists');
                        return null;
                    }

                    var glyph = '';

                    if (name === 'filter') {
                        //glyph = ' k-i-filter';
                        glyph += '<span class="k-icon im-k-icon k-i-filter"></span>'
                    } else if (name === 'filterClear') {
                        glyph += '<span class="k-icon im-k-icon k-i-filter-clear"></span>'
                    } else if (name === 'columns' || name === 'colShowHide') {
                        glyph += '<span class="k-icon im-k-icon k-i-columns"></span>'
                    } else if (name === 'colEdit') {
                        glyph += '<span class="k-icon im-k-icon k-i-edit"></span>'
                    } else if (name === 'colLock') {
                        glyph += '<span class="k-icon im-k-icon k-i-lock"></span>'
                    } else if (name === 'toggleHeaders') {
                        glyph += '<span class="k-icon im-k-icon k-i-group-box"></span>'
                    } else if (name === 'save') {
                        glyph += '<span class="k-icon im-k-icon k-i-star"></span>'
                    } else if (name === 'config') {
                        glyph += '<span class="k-icon im-k-icon k-i-gear"></span>'
                    } else if (name === 'refresh') {
                        glyph += '<span class="k-icon im-k-icon k-i-refresh-sm"></span>'
                    //} else if (name === 'lock') {
                    //    glyph += '<span class="k-icon im-k-icon k-i-refresh-sm"></span>'
                    } else {
                        if (icon !== ''){
                            glyph += '<span class="k-icon im-k-icon k-i-'+icon+'"></span>'
                        }                        
                    }

                    var toolbarBtn = {
                        name: name,
                        text: text,
                        //template: '<a ng-click="' + func + '" class="k-button k-button-icontext' + glyph + '" href="\\#">' + text + '</a>'
                        //template: '<a ng-click="' + func + '" class="k-button im-grid-toolbar-btn-i" href="\\#" ng-show="' + show + '">' + glyph + text + '</a>'
                        template: '<a ng-click="' + func + '" class="' +((cssClass !== '') ? cssClass : 'k-button im-grid-toolbar-btn-i') + '" ng-show="' + show + '">' + glyph + text + '</a>'
                    };

                    return toolbarBtn;
                };

                function generateGridToolbar() {
                    if (angular.isDefined($scope.setup.toolbar) && $scope.setup.toolbar === false) {
                        $scope.gridToolbar = [];
                    } else {
                        if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.hideExports) && $scope.setup.toolbar.hideExports === true) {
                            $scope.gridToolbar = [];
                        } else {
                            $scope.gridToolbar = [{ name: 'excel', text: '', template: '<a class="k-button k-button-icontext k-grid-excel im-grid-toolbar-btn-i" href="\\#"><span class="k-icon im-k-icon k-i-excel im-no-mar"></span></a>' },
                                                { name: 'pdf', text: '', template: '<a class="k-button k-button-icontext k-grid-pdf im-grid-toolbar-btn-i" href="\\#"><span class="k-icon im-k-icon k-i-pdf im-no-mar"></span></a>' }];
                        }
                        //var btn = $scope.makeToolbarBtnTemplate();
                        //if(btn !== null) $scope.gridToolbar.push(btn);
                        var btns = null;
                        if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.emptyToolbar) && $scope.setup.toolbar.emptyToolbar === true) {
                            btns = [];
                        } else if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.fullToolbar) && $scope.setup.toolbar.fullToolbar === true) {
                            btns = ['filter', 'filterClear', 'colShowHide', 'colEdit', 'colLock', 'toggleHeaders', 'save'];
                        } else {
                            btns = ['filter', 'filterClear', 'colShowHide', 'toggleHeaders'];
                            if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.tBarSave) && $scope.setup.toolbar.tBarSave === true) {
                                btns.push('save');
                            }
                        }
                        //btns.push('refresh'); //ONLY FOR TESTING
                        angular.forEach(btns, function (name) {
                            var btn = $scope.makeToolbarBtnTemplate(name, '');
                            if (btn !== null) $scope.gridToolbar.push(btn);
                        });
                        //btn = $scope.makeToolbarBtnTemplate('filterClear', '');
                        //if (btn !== null) $scope.gridToolbar.push(btn);

                        if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.buttons) && $scope.setup.toolbar.buttons.length > 0) {
                            //custom button
                            angular.forEach($scope.setup.toolbar.buttons, function (button) {
                                var btn = $scope.makeToolbarBtnTemplate(button.name, angular.isDefined(button.text) ? button.text : '', 'customGridToolbarBtnClick(\'' + button.name + '\')', button.icon, (angular.isDefined(button.cssClass) ? button.cssClass : ''));
                                if (btn !== null) $scope.gridToolbar.push(btn);
                            });
                        }
                    }
                }

                //$scope.generateGridToolbar = function () {
                //    $scope.gridToolbar = [{ name: 'excel', text: '', template: '<a class="k-button k-button-icontext k-grid-excel im-grid-toolbar-btn-i" href="\\#"><span class="k-icon im-k-icon k-i-excel im-no-mar"></span></a>' },
                //        { name: 'pdf', text: '', template: '<a class="k-button k-button-icontext k-grid-pdf im-grid-toolbar-btn-i" href="\\#"><span class="k-icon im-k-icon k-i-pdf im-no-mar"></span></a>' }];
                //    //var btn = $scope.makeToolbarBtnTemplate();
                //    //if(btn !== null) $scope.gridToolbar.push(btn);
                //    var btns = null;
                //    if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.emptyToolbar) && $scope.setup.toolbar.emptyToolbar === true) {
                //        btns = [];
                //    } else if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.fullToolbar) && $scope.setup.toolbar.fullToolbar === true) {
                //        btns = ['filter', 'filterClear', 'colShowHide', 'colEdit', 'colLock', 'toggleHeaders', 'save'];
                //    } else {
                //        btns = ['filter', 'filterClear', 'colShowHide', 'toggleHeaders'];
                //        if (angular.isDefined($scope.setup.toolbar) && angular.isDefined($scope.setup.toolbar.tBarSave) && $scope.setup.toolbar.tBarSave === true) {
                //            btns.push('save');
                //        }
                //    }
                //    //btns.push('refresh'); //ONLY FOR TESTING
                //    angular.forEach(btns, function (name) {
                //        var btn = $scope.makeToolbarBtnTemplate(name, '');
                //        if (btn !== null) $scope.gridToolbar.push(btn);
                //    });
                //    //btn = $scope.makeToolbarBtnTemplate('filterClear', '');
                //    //if (btn !== null) $scope.gridToolbar.push(btn);
                //};

                function generatePageSizes(options) {
                    if (isNumber($scope.setup.pager.pageSizes)) {
                        var pageSizes = [];
                        var numberOfSplits = isNumber($scope.setup.pager.pageSizes) && $scope.setup.pager.pageSizes > 0 ? $scope.setup.pager.pageSizes : 10;
                        var dataLength = options.dataSource.data.length;
                        var splitSize = Math.floor((dataLength / numberOfSplits) + 0.5);
                        splitSize = splitSize >= 1 ? splitSize : 1;
                        //console.log('generating');
                        //console.dir(options.dataSource.data);

                        for (var p = 0; p < numberOfSplits; p++) {
                            var nextNum = (splitSize + (p * splitSize));
                            pageSizes.push(nextNum);
                        }
                        options.pageable.pageSizes = pageSizes;
                    } else {
                        if ($scope.setup.pager.pageSizes === true) {
                            options.pageable.pageSizes = [10, 25, 50, 100];
                        } else {
                            options.pageable.pageSizes = $scope.setup.pager.pageSizes;
                        }
                    }
                }

                // ###############################
                // #endregion toolbar templates
                // ###############################

                // ###############################
                // #region checkbox functions
                // ###############################

                $scope.checkboxBoxClickAll = function (e) {
                    //console.log('checkboxBoxClickAll');
                    //console.dir(e);
                    e.stopPropagation();
                    var dataColIdx = $scope.model.grid.current().index();
                    var options = $scope.model.grid.getOptions();
                    var key = options.columns[dataColIdx - 1].field;

                    if (angular.isUndefined($scope.model.columnCheckBoxes[key])) return;

                    $scope.model.columnCheckBoxes[key] = !$scope.model.columnCheckBoxes[key];

                    angular.forEach($scope.model.grid.dataSource.options.data, function (item) {
                        item[key] = $scope.model.columnCheckBoxes[key];
                    });
                    $scope.model.grid.refresh();
                };

                $scope.checkboxBoxClick = function () {
                    console.log('checkboxBoxClick');
                    var dataRow = $scope.model.grid.current().closest("tr");
                    var dataColIdx = $scope.model.grid.current().index();
                    var dataItem = $scope.model.grid.dataItem(dataRow);
                    var options = $scope.model.grid.getOptions();
                    var key = options.columns[dataColIdx - 1].field;

                    checkboxCellEditor(dataItem, key);
                };

                //$scope.toBool = function (key) {
                //    console.log('key before: ' + key);
                //    if (key === 0) {
                //        key = false;
                //    } else if (key === 1) {
                //        key = true;
                //    } else if (key === '0') {
                //        key = true;
                //    } else if (key === '1') {
                //        key = true;
                //    }
                //    console.log('key after: ' + key);
                //    return key;
                //};

                function checkboxCellEditor(e, key) {
                    console.dir(e);
                    console.dir(key);
                    console.log('value: ' + e[key]);
                    var checked = e[key] === true ? false : true;
                    e.set(key, checked);
                    //console.log('new value: ' + e[key]);
                }

                // ###############################
                // #endregion checkbox functions
                // ###############################

                // ###############################
                // #region grid cell functions
                // ###############################

                function loadLookupSearch(options, method, searchfield, searchtext, row) {
                    //console.log('loadLookupSearch :: - ' + searchtext);
                    //console.dir(options);
                    //console.dir(method);
                    //console.dir(row);

                    p2DataTaskService.call(method, {
                        searchfield: searchfield,
                        searchtext: searchtext,
                        row: row
                    }).then(function (result) {
                        return options.success(result);
                    });
                }

                function lookupCellEditor(container, options, eCol, source) {
                    //console.log('lookupCellEditor');
                    if (angular.isUndefined(container) || angular.isUndefined(options)) return;

                    var editorCellId = uuid();
                    var delay = 500;
                    var method = eCol.lookup;

                    $('<input id="' + editorCellId+'" required data-value-field="' + options.field + '" data-bind="value:' + options.field + '" style="color: #515967;"/>').appendTo(container).kendoAutoComplete({
                        dataSource: new kendo.data.DataSource({
                            serverFiltering: true,
                            transport: {
                                read: function (read) {
                                    //console.log('read');
                                    //console.dir(options);
                                    var rowData = {};
                                    angular.forEach(source, function (property, key) {
                                        if (angular.isDefined(options.model[key])) {
                                            rowData[key] = options.model[key];
                                        }
                                    });
                                    //console.dir(read);
                                    var thisElem = $("#" + editorCellId);
                                    //console.log(thisElem.val());
                                    return loadLookupSearch(read, method, options.field, thisElem.val(), rowData);
                                }
                            },
                            change: function (e) {
                                //console.log('change');
                                //var thisElem = $("#" + editorCellId);
                                //console.log(thisElem.val());
                                //console.log('change end');
                            },
                        }),
                        dataTextField: "item_name",
                        //dataValueField: "item_id",
                        filter: "contains",
                        minLength: 1,
                        delay: delay,
                        //open: function (e) {
                        //    console.log('open');
                        //    console.dir(e);
                        //},
                        select: function (e) {
                            //var dataItem = $scope.model.grid.dataItem($scope.model.grid.current().closest("tr"));
                            console.log('select');
                            //console.dir(e);
                            //console.dir(container);
                            //console.dir(options);
                            //console.log('dataRowIdx :: ' + dataRowIdx);

                            if (angular.isDefined(eCol.relations)) {
                                angular.forEach(eCol.relations, function (rel) {
                                    if (angular.isDefined(options.model[rel.key]) && angular.isDefined(e.dataItem[rel.value])) {
                                        var type = whatType(e.dataItem[rel.value]);
                                        if (type === 'number') {
                                            options.model[rel.key] = parseInt(e.dataItem[rel.value]);
                                        } else {
                                            options.model[rel.key] = e.dataItem[rel.value];
                                        }
                                    }
                                });
                            }

                            if (angular.isDefined(eCol.optionfunc) && eCol.optionfunc === true) {
                                var item = {};
                                //console.dir(e.dataItem);
                                angular.forEach(e.dataItem, function (property, key) {
                                    if (!['parent', 'uid', '_event', '_handlers', '__proto__'].includes(key)) {
                                        item[key] = property;
                                    }
                                });
                                var dataRow = $scope.model.grid.current().closest("tr");
                                var dataRowIdx = $("tr", $scope.model.grid.tbody).index(dataRow);
                                $scope.goOptionFunc({ field: options.field, item: item, rowIdx: dataRowIdx });
                            }
                        }
                    });

                    $timeout(function () {
                        $("#" + editorCellId).select();
                    }, 10);
                }

                // ###############################
                // #endregion grid cell lookup
                // ###############################

                // ###############################
                // #region grid button templates
                // ###############################

                $scope.gridGlyphs = function () {
                    //console.log('gridGlyphs');
                    //console.dir($scope.model.grid.dataItem($(this).closest("tr")));
                    var allClass =
                        //'<a ng-class="addBtnStyle('+name+')" href="\\#">' +
                        //'<a ng-class="addBtnStyle()" href="\\#">' +
                        //'<a class="k-grid-gridGlyphs" style="min-width:8px;"><span class="glyphicon glyphicon-chevron-right"></span></a>';
                        //'<a class="k-grid-gridGlyphs" style="min-width:8px;"><span class="fas fas-check"></span></a>';
                        //'<a class="k-grid-gridGlyphs" style="min-width:8px;"><span class=""></span></a>';
                        '<a class="k-grid-gridGlyphs" style="min-width:8px;"></a>';
                        //'<a ng-class="glyphicon glyphicon-chevron-right">'
                        //'<a ng-class="addBtnStyle()">' +
                        //    '<span ng-class="addGlyph()" class="" type="button" style="color: white;"></span>' +
                        //'</a>';

                    return allClass;
                };

                $scope.makeBtnTemplate = function (name) {
                    var allClass =
                        //'<a ng-class="addBtnStyle('+name+')" href="\\#">' +
                        //'<a ng-class="addBtnStyle()" href="\\#">' +
                        '<a ng-class="addBtnStyle()">' +
                            '<span ng-class="addGlyph()" class="" type="button" style="color: white;"></span>' +
                        '</a>';

                    return allClass;
                };

                $scope.addBtnStyle = function (name) {
                    //var allClass = 'input-group-addon btn btn-lg'; //btn-success
                    //var allClass = 'k-grid-'+name+' btn btn-primary btn-sm'; //btn-success
                    //var allClass = 'k-grid-gridFunc btn btn-primary btn-sm'; //btn-success
                    var allClass = 'k-grid-gridFunc btn btn-primary im-grid-btn-xs pull-right'; //btn-success

                    //if (angular.isDefined(index) && angular.isDefined($scope.typevar) && $scope.typevar.indexOf('o')) {
                    //    if (angular.isDefined($scope.model.lines) && $scope.model.lines.length > 0 && angular.isDefined($scope.model.lines[index].color)) {
                    //        if ($scope.model.lines[index].color === 'default') {
                    //            allClass = 'input-group-addon btn btn-default btn-lg';
                    //        }
                    //        if ($scope.model.lines[index].color === 'primary') {
                    //            allClass = 'input-group-addon btn btn-primary btn-lg';
                    //        }
                    //        if ($scope.model.lines[index].color === 'success') {
                    //            allClass = 'input-group-addon btn btn-success btn-lg';
                    //        }
                    //        if ($scope.model.lines[index].color === 'info') {
                    //            allClass = 'input-group-addon btn btn-info btn-lg';
                    //        }
                    //        if ($scope.model.lines[index].color === 'warning') {
                    //            allClass = 'input-group-addon btn btn-warning btn-lg';
                    //        }
                    //        if ($scope.model.lines[index].color === 'danger') {
                    //            allClass = 'input-group-addon btn btn-danger btn-lg';
                    //        }
                    //    }
                    //}

                    return allClass;
                };

                $scope.addGlyph = function (specific) {
                    var allClass = 'glyphicon glyphicon-chevron-right'; //default

                    //if (angular.isDefined($scope.glyphs) && $scope.glyphs !== null) {
                    //    allClass = 'glyphicon ' + $scope.glyphs;
                    //}

                    //if (angular.isDefined(specific) && specific === 'down') {
                    //    allClass = 'glyphicon ' + 'glyphicon-minus';
                    //}

                    //if (angular.isDefined(specific) && specific === 'up') {
                    //    allClass = 'glyphicon ' + 'glyphicon-plus';
                    //}

                    //if (angular.isDefined(specific) && specific === 'remove') {
                    //    allClass = 'glyphicon glyphicon-erase';
                    //    //allClass = 'glyphicon glyphicon-remove-sign';
                    //}

                    //if (angular.isDefined(specific) && specific === 'calc') {
                    //    allClass = 'fa fa-calculator';
                    //}

                    //if (angular.isDefined(specific) && specific === 'barcode') {
                    //    allClass = 'glyphicon glyphicon-barcode';
                    //}

                    //if (angular.isDefined(specific) && angular.isDefined($scope.typevar) && $scope.typevar.indexOf('o')) {
                    //    if (angular.isDefined($scope.model.lines) && $scope.model.lines.length > 0) {

                    //        var prefix = 'glyphicon ';

                    //        if ($scope.model.lines[specific].glyph.startsWith('fa-')) {
                    //            prefix = 'fa ';
                    //        } else if ($scope.model.lines[specific].glyph.startsWith('fab-')) {
                    //            prefix = 'fab ';
                    //        } else if ($scope.model.lines[specific].glyph.startsWith('fad-')) {
                    //            prefix = 'fad ';
                    //        } else if ($scope.model.lines[specific].glyph.startsWith('fal-')) {
                    //            prefix = 'fal ';
                    //        } else if ($scope.model.lines[specific].glyph.startsWith('far-')) {
                    //            prefix = 'far ';
                    //        } else if ($scope.model.lines[specific].glyph.startsWith('fas-')) {
                    //            prefix = 'fas ';
                    //        }

                    //        if (angular.isDefined($scope.spin) && $scope.model.lines[specific].glyph.indexOf('glyphicon-search') > -1) {
                    //            if ($scope.spin === true) {
                    //                allClass = 'fa fa-spinner fa-spin listview-spin spin-text';
                    //            } else {
                    //                allClass = prefix + $scope.model.lines[specific].glyph;
                    //            }
                    //        } else {
                    //            allClass = prefix + $scope.model.lines[specific].glyph;
                    //        }
                    //    }
                    //}

                    //if (angular.isDefined(specific) && specific === 'icons' && angular.isDefined($scope.icons)) {
                    //    if ($scope.icons.length > 0) {
                    //        var glyphStr = $scope.icons;

                    //        if ($scope.icons.startsWith('fa-')) {
                    //            allClass = 'fa ' + glyphStr;
                    //        } else if ($scope.icons.startsWith('fab-')) {
                    //            allClass = 'fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                    //        } else if ($scope.icons.startsWith('fad-')) {
                    //            allClass = 'fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                    //        } else if ($scope.icons.startsWith('fal-')) {
                    //            allClass = 'fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                    //        } else if ($scope.icons.startsWith('far-')) {
                    //            allClass = 'far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                    //        } else if ($scope.icons.startsWith('fas-')) {
                    //            allClass = 'fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                    //        } else {
                    //            allClass = 'glyphicon ' + glyphStr;
                    //        }
                    //    }
                    //}

                    return allClass;
                };

                //$scope.addGridGlyphPrefix = function (glyph) {
                $scope.addGridGlyph = function (glyph) {
                    //var allClass = 'glyphicon glyphicon-none'; //default
                    var allClass = ''; //default

                    if (angular.isDefined(glyph)) {
                        allClass = 'glyphicon ' + glyph;

                        if (glyph.startsWith('fa-')) {
                            allClass = 'fa ';
                        } else if (glyph.startsWith('fab-')) {
                            allClass = 'fab ' + glyph.slice(0, 2) + glyph.slice(3);
                        } else if (glyph.startsWith('fad-')) {
                            allClass = 'fad ' + glyph.slice(0, 2) + glyph.slice(3);
                        } else if (glyph.startsWith('fal-')) {
                            allClass = 'fal ' + glyph.slice(0, 2) + glyph.slice(3);
                        } else if (glyph.startsWith('far-')) {
                            allClass = 'far ' + glyph.slice(0, 2) + glyph.slice(3);
                        } else if (glyph.startsWith('fas-')) {
                            allClass = 'fas ' + glyph.slice(0, 2) + glyph.slice(3);
                        }
                    }

                    return allClass;
                };

                // ###############################
                // #endregion grid button templates
                // ###############################

                // ###############################
                // #region events
                // ###############################

                $(document).on('click', '.CellClickHandler', function (e) {
                    if (angular.isFunction($scope.optionfunc)) {
                        //console.log("I was clicked");
                        //var grid = $("#grid").data("kendoGrid");
                        var row = $(this).closest('tr');
                        var col = $(this).closest('td');
                        var dataItem = $scope.model.grid.dataItem(row);
                        var cellIndex = col[0].cellIndex;
                        var column = $scope.model.grid.columns[cellIndex];
                        //console.log("Contact Title: ", dataItem.ContactTitle);
                        //console.dir(e);
                        //console.log('cellIndex: ' + e.target.cellIndex);
                        //console.dir(row);
                        //console.dir(col[0].cellIndex);
                        //console.log('cellIndex: ' + cellIndex);
                        //console.log('column: ' + column.field);
                        //console.dir(dataItem);
                        //console.dir(column);
                        $scope.model.clickedCell = { dataItem: dataItem, column: column };
                        //console.dir($scope.model.clickedCell);

                        //$scope.goOptionFunc();
                        $scope.optionfunc({ data: { type: 'onCellClick', clickedCell: $scope.model.clickedCell } });
                        //$timeout(function () {
                        //    $scope.optionfunc({ data: { func: 'CellClickHandler', clickedCell: $scope.model.clickedCell } });
                        //}, 10);
                    }
                });

                //var applyColMinWidth = function (_, data) {
                //    console.dir(data);
                //    //$scope.model.currentSize = data.current;
                //    console.dir($scope.model.grid);
                //    console.dir($scope.model.grid.element[0].clientWidth);
                //    //console.dir($scope.model.grid);

                //    //hierachy column 27px
                //    var headerCells = $scope.model.grid.element.find('th');
                //    var columns = $scope.model.grid.columns;
                //    console.dir(headerCells);
                //    headerCells.each(function (i, e) {
                //        var headerCell = $(this);
                //        if (angular.isDefined(headerCell) && angular.isDefined(headerCell[0]) && angular.isDefined(headerCell[0].id) && headerCell[0].id.trim().length > 0) {

                //            for (var c = columns.length-1; c < columns.length; c++) {
                //                if (angular.isDefined(columns[c]) && angular.isDefined(columns[c].headerAttributes) && angular.isDefined(columns[c].headerAttributes.id) && columns[c].headerAttributes.id === headerCell[0].id) {
                //                    console.dir(headerCell[0]);
                //                    console.dir(columns[c]);
                //                }
                //            }
                //        }
                //        //if (angular.isDefined(columns) && angular.isDefined(columns[i - 1]) && angular.isDefined(columns[i - 1].field)
                //        //    && angular.isDefined(schema) && angular.isDefined(schema.model) && angular.isDefined(schema.model.fields)) {
                //        //    var fields = schema.model.fields;
                //        //    //console.dir(fields);
                //        //    if (angular.isDefined(fields[columns[i - 1].field]) && fields[columns[i - 1].field].editable === true) {
                //        //        var headerCell = $(this);
                //        //        var link = headerCell.find("a.k-link");
                //        //        var icon = link.find('span.k-i-edit');

                //        //        //setTimeout(function () {
                                    
                //        //        //}, 5);
                //        //    }
                //        //}
                //    });
                //};

                //var dereg = $rootScope.$on('event:responsive-changed', applyColMinWidth);

                //$scope.$on('$destroy', function () {
                //    if (angular.isFunction(dereg) !== true) return;

                //    dereg();
                //});

                // ###############################
                // #endregion events
                // ###############################

                // ###############################
                // #region watchers
                // ###############################

                $scope.$watchGroup(['setup', 'data.length'], function (newValues, oldValues, scope) {
                    if (angular.isUndefined($scope.setup) || $scope.setup === null) return;
                    if (angular.isUndefined($scope.data) || $scope.data === null) return;
                    //if (newValues[1] === 0 && oldValues[1] === 0) return;

                    //console.log('WatchGroup :: old :: new');
                    //console.dir(oldValues);
                    //console.dir(newValues);
                    //console.dir(scope);
                    //console.dir($scope.model);
                    //console.dir($scope.setup);
                    //console.dir($scope.data);
                    //console.dir($scope.data.length);
                    //console.log($scope.model.grid);

                    if ($scope.model.grid === null) {
                        $scope.model.grid = $("#" + $scope.model.mId).data("kendoGrid");
                    }

                    var remSetupCols = [];
                    angular.copy($scope.setup.columns, remSetupCols);
                    $scope.model.gridSetup = $scope.setup;
                    //bhw
                    /*if (angular.isDefined($scope.setup.pageSize)){   
                        $scope.gridOptions.pageable.pageSize  =  $scope.setup.pageSize;
                        $scope.model.gridDataSource.pageSize =  $scope.setup.pageSize;
                    }*/

                    $scope.model.gridDataSourceData = $scope.data;
                    $scope.model.hasBools = false;

                    if (newValues[1] === 0 && oldValues[1] > 0) {
                        var preOptions = $scope.model.grid.getOptions();
                        if (angular.isDefined(preOptions)) {
                            preOptions.dataSource.data = $scope.model.gridDataSourceData;
                            $scope.model.grid.setOptions(preOptions);
                        }
                    }

                    if (angular.isUndefined($scope.model.gridDataSourceData[0])) return;

                    var windowHeight = $(window).height();
                    //var windowHeight = $scope.model.grid.element[0].parentElement.height();
                    var navbarHeight = 50;
                    var gridFilterRowHeight = 39;
                    var pagerHeight = 42;
                    //var totalDependentHeight = navbarHeight + gridFilterRowHeight+2;
                    var totalDependentHeight = navbarHeight + 2;
                    var newHeight = 0;

                    if (angular.isDefined($scope.model.gridSetup.height) && $scope.model.gridSetup.height !== null && $scope.model.gridSetup.height !== '') {
                        newHeight = $scope.model.gridSetup.height;
                    } else {
                        newHeight = windowHeight - totalDependentHeight;
                    }

                    //if (angular.isDefined($scope.model.gridSetup.pager) && $scope.model.gridSetup.pager === false) {
                    //    newHeight -= pagerHeight;
                    //}

                    //console.log('windowHeight: ' + windowHeight);
                    //console.log('totalDependentHeight: ' + totalDependentHeight);
                    //console.log('newheight: ' + newHeight);

                    var filter = [];
                    angular.forEach($scope.setup.columns, function (col) {
                        if (angular.isDefined(col.filter)) {
                            filter.push({ field: col.field, operator: col.filter.operator, value: col.filter.value });
                        }
                    });

                    var sort = [];
                    angular.forEach($scope.setup.columns, function (col) {
                        if (angular.isDefined(col.sort)) {
                            sort.push({ field: col.field, dir: col.sort.dir });
                        }
                    });

                    var referenceOrder = {};
                    for (var i = 0; i < remSetupCols.length; i++) {
                        referenceOrder[remSetupCols[i].field] = i;
                    }

                    //var colOrder = [];
                    //angular.copy(remSetupCols, colOrder);
                    //console.dir('referenceOrder');
                    //console.dir(angular.toJson(remSetupCols));
                    //console.dir(referenceOrder);
                    //console.dir($scope.setup.format);

                    if ((angular.isDefined($scope.setup.localData) && $scope.setup.localData === true)) {
                        var tempSchema = { model: { fields: {} } };
                        var cols = [];
                        var col = {};
                        var hasWidthless = false;
                        var hasFunctions = false;
                        var dataGlyphCount = 0;
                        var colCount = Object.keys($scope.model.gridDataSourceData[0]).length;
                        var onCol = 0;
                        if (angular.isDefined($scope.model.gridDataSourceData[0].item_glyphicon)) {
                            angular.forEach($scope.model.gridDataSourceData, function (dataRow) {
                                if (angular.isDefined(dataRow.item_glyphicon)) {
                                    var glyphListLength = dataRow.item_glyphicon.split(' ').length;
                                    dataGlyphCount = dataGlyphCount < glyphListLength ? glyphListLength : dataGlyphCount;
                                }
                            });
                        } 
                        angular.forEach($scope.model.gridDataSourceData[0], function (property, key) {
                            onCol++;
                            if (key === 'item_glyphicon' || key === 'item_glyphicon_color') {
                                if (angular.isUndefined($scope.model.translations['grid_glyphs'])) {
                                    $scope.model.translations['grid_glyphs'] = '';
                                    col = {
                                        field: 'grid_glyphs',
                                        command: [{ name: 'gridGlyphs', template: $scope.gridGlyphs() }],
                                        title: $scope.model.translations['grid_glyphs'],
                                        filterable: false,
                                        //width: "19px",
                                        //width: $scope.model.glyphCount,
                                        width: (2+(dataGlyphCount*20))+'px',
                                        //width: (dataGlyphCount*1)+0.07+'em',
                                        attributes: {
                                            //style: "padding: 0.2em 0.5em; text-overflow: clip"
                                            //style: "padding: 0.24em; text-overflow: clip; text-align: left;"
                                            'class': 'CellClickHandler',
                                            style: "padding: 0em; text-overflow: clip; text-align: left;"
                                        }
                                    }
                                    cols.push(col);
                                } else {
                                    var containsGlyphsCol = false;
                                    for (var g = 0; g < cols.length; g++) {
                                        if (cols[g].field === 'grid_glyphs') {
                                            containsGlyphsCol = true;
                                            break;
                                        }
                                    }
                                    if (!containsGlyphsCol) {
                                        col = {
                                            field: 'grid_glyphs',
                                            command: [{ name: 'gridGlyphs', template: $scope.gridGlyphs() }],
                                            title: $scope.model.translations['grid_glyphs'],
                                            filterable: false,
                                            width: (2 + (dataGlyphCount * 20)) + 'px',
                                            attributes: {
                                                'class': 'CellClickHandler',
                                                style: "padding: 0em; text-overflow: clip; text-align: left;"
                                            }
                                        }
                                        cols.push(col);
                                    }
                                }
                                //console.log('Skip this col');
                            } else if (key === 'item_state' || key === 'item_parms' || key === 'item_path') {
                                if (angular.isUndefined($scope.model.translations['grid_functions'])) {
                                    $scope.model.translations['grid_functions'] = '';
                                    col = {
                                        field: 'grid_functions',
                                        command: [{ name: 'gridFunc', template: $scope.makeBtnTemplate('gridFunc'), click: function (e) { $scope.goGridFunc(e, 'goTo') } }],
                                        title: $scope.model.translations['grid_functions'],
                                        filterable: false,
                                        width: "80px",
                                        attributes: {
                                            style: "padding: 0.2em 0.5em"
                                        }
                                    }
                                    cols.push(col);
                                    hasFunctions = true;
                                } else {
                                    var containsFunctionsCol = false;
                                    for (var c = 0; c < cols.length; c++) {
                                        if (cols[c].field === 'grid_functions') {
                                            containsFunctionsCol = true;
                                            break;
                                        }
                                    }
                                    if (!containsFunctionsCol) {
                                        col = {
                                            field: 'grid_functions',
                                            command: [{ name: 'gridFunc', template: $scope.makeBtnTemplate('gridFunc'), click: function (e) { $scope.goGridFunc(e, 'goTo') } }],
                                            title: $scope.model.translations['grid_functions'],
                                            filterable: false,
                                            width: "80px",
                                            attributes: {
                                                style: "padding: 0.2em 0.5em"
                                            }
                                        }
                                        cols.push(col);
                                        hasFunctions = true;
                                    }
                                }
                            } else if (angular.isDefined(key)) {
                                var currentCol = {};
                                for (var i = 0; i < remSetupCols.length; i++) {
                                    if (remSetupCols[i].field === key) {
                                        angular.copy(remSetupCols[i], currentCol);
                                        remSetupCols.splice(i, 1);
                                        break;
                                    }
                                }

                                var format = null;
                                var align = null;
                                var formatType = null;

                                if (angular.isDefined($scope.setup.format)) {
                                    for (var f = 0; f < $scope.setup.format.length; f++) {
                                        if ($scope.setup.format[f].field === key) {
                                            formatType = $scope.setup.format[f].type;
                                            if ($scope.setup.format[f].type === 'decimal') {
                                                //console.log(numberWithSpaces(40934209.0525, 2));
                                                var deci = angular.isDefined($scope.setup.format[f].decimals) && $scope.setup.format[f].decimals !== '' ? $scope.setup.format[f].decimals : '0';
                                                var zeros = '';
                                                for (var d = 0; d < parseInt(deci); d++) {
                                                    zeros += '0';
                                                }
                                                format = '{0:#,##.' + zeros + '}';
                                                //format = '{0:c' + deci + '}';
                                            } else if ($scope.setup.format[f].type === 'date') {
                                                format = '{0:dd/MM/yyyy}';
                                            } else if ($scope.setup.format[f].type === 'datetime' || $scope.setup.format[f].type === 'timestamp') {
                                                format = '{0:dd/MM/yyyy hh:mm:ss}';
                                            } else if ($scope.setup.format[f].type === 'boolean') {
                                                //format = '{0:dd/MM/yyyy}';
                                            }
                                            switch ($scope.setup.format[f].align) {
                                                case 'l':
                                                    align = 'left';
                                                    break;
                                                case 'c':
                                                    align = 'center';
                                                    break;
                                                case 'r':
                                                    align = 'right';
                                                    break;
                                                default:
                                                    align = null;
                                            }
                                            break;
                                        }
                                    }
                                }

                                var attr = {}
                                var fltr = {}
                                var type = whatType(property, formatType);
                                if (type === 'number') {
                                    align = align !== null ? align : 'right';
                                    attr = { 'class': 'table-cell CellClickHandler', style: 'text-align: ' + align + ';' };
                                    fltr = {
                                        cell: {
                                            operator: "gte"
                                        }
                                    };
                                    fltr.cell.template = function (args) {
                                        args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                    };
                                } else if (type === 'date') {
                                    //if (align !== null) {
                                    //    attr = { 'class': 'table-cell', style: 'text-align: ' + align + ';' }
                                    //}
                                    fltr = {
                                        cell: {
                                            operator: "gte",
                                            //template: function (args) {
                                            //    args.element.kendoDatePicker({
                                            //        format: '{0:dd-MM-yyyy}'
                                            //    });
                                            //}
                                            template: function (args) {
                                                args.element.kendoDatePicker({
                                                    format: "dd/MM/yyyy"
                                                });
                                                args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                            }
                                        }
                                    };
                                } else if (type === 'boolean') {
                                    if (align !== null) {
                                        attr = { 'class': 'table-cell CellClickHandler', style: 'text-align: ' + align + ';' };
                                    }
                                    fltr = {
                                        messages: {
                                            isTrue: '',
                                            isFalse: ''
                                        }
                                    };
                                    //fltr = {
                                    //    template: function (args) {
                                    //        console.dir(args);
                                    //        //args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                    //    }
                                    //    //cell: {
                                    //    //    template: function (args) {
                                    //    //        console.dir(args);
                                    //    //        //args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                    //    //    }
                                    //    //}
                                    //};
                                    //fltr = {
                                    //    //cell: {
                                    //    //    operator: "gte",
                                    //    //    template: function (args) {
                                    //    //        args.element.kendoDatePicker({
                                    //    //            format: "dd/MM/yyyy"
                                    //    //        });
                                    //    //        args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                    //    //    }
                                    //    //}
                                    //}
                                    //currentCol.filter.operator
                                    //$scope.data
                                    angular.forEach($scope.model.gridDataSourceData, function (item) {
                                        //console.dir(item);
                                        if (angular.isDefined(item[key])) {
                                            if (item[key] === true || item[key] === 'true' || item[key] === '1' || item[key] === 1) {
                                                item[key] = true;
                                            } else {
                                                item[key] = false;
                                            }
                                        }
                                    });
                                } else {
                                    if (align !== null) {
                                        attr = { 'class': 'table-cell', style: 'text-align: ' + align + ';' }
                                    }
                                    fltr = {
                                        cell: {
                                            operator: "contains",
                                            suggestionOperator: "contains"
                                        }
                                    }
                                    fltr.cell.template = function (args) {
                                        args.element.css("width", "100%").addClass("k-textbox").attr("data-value-update", "input");
                                    }
                                }
                                if (angular.isDefined(currentCol.filter) && angular.isDefined(fltr.cell)) {
                                    if (angular.isDefined(currentCol.filter.operator)) fltr.cell.operator = currentCol.filter.operator;
                                    if (angular.isDefined(currentCol.filter.suggestionOperator)) fltr.cell.suggestionOperator = currentCol.filter.suggestionOperator;
                                    //if (angular.isDefined(currentCol.filter.value)) fltr.value = currentCol.filter.value;
                                }
                                var editor = null;
                                //var headerTemplate = null;
                                var editable = false;
                                if (angular.isDefined($scope.setup.editableCols)) {
                                    for (var e = 0; e < $scope.setup.editableCols.length; e++) {
                                        if (angular.isDefined($scope.setup.editableCols[e].key)) {
                                            if ($scope.setup.editableCols[e].key === key) {
                                                editable = true;
                                                if (type === 'boolean') {
                                                    editor = function cbEditor(ev) { checkboxCellEditor(ev, key); };
                                                } else if (angular.isDefined($scope.setup.editableCols[e].lookup)) {
                                                    editor = function colEditor(container, options) {
                                                        lookupCellEditor(container, options, $scope.setup.editableCols[e], $scope.model.gridDataSourceData[0]);
                                                    };
                                                }
                                                break;
                                            }
                                        } else {
                                            if ($scope.setup.editableCols[e] === key) {
                                                editable = true;
                                                if (type === 'boolean') {
                                                    editor = function cbEditor(ev) { checkboxCellEditor(ev, key); };
                                                }
                                                break;
                                            }
                                        }
                                    }
                                }

                                tempSchema.model.fields[key] = { from: key, type: type, editable: editable };

                                if (angular.isDefined($scope.setup.translate) && $scope.setup.translate === true) {
                                    $scope.model.translations[key] = '';
                                    col = {
                                        field: key,
                                        title: $scope.model.translations[key],
                                        attributes: attr,
                                        filterable: fltr,
                                    };
                                    //if (editable) col.headerAttributes = { "class": "k-icon k-i-edit" };
                                } else {
                                    col = {
                                        field: key,
                                        title: key,
                                        attributes: attr,
                                        filterable: fltr
                                    };
                                    //if (editable) col.headerTemplate = key + ' <span class="k-icon k-i-edit" ></span>';
                                }
                                if (type === 'boolean') {
                                    $scope.model.hasBools = true;
                                    if (editor !== null) {
                                        col.editable = editor;
                                        //col.template = '<input type="checkbox" #= ' + key + ' ? \'checked=true\' : "" # class="im-grid-checkbox" />';
                                        //col.template = '<input type="checkbox" #= ' + key + ' ? \'checked=true\' : "" # class="im-grid-checkbox" ng-click="checkboxBoxClick(' + key + ')" />';
                                        //col.headerTemplate: '<input type="checkbox" id="chkStatusDtls" /> Show all Status Details',

                                        //if (angular.isUndefined($scope.model.columnCheckBoxes[key]))
                                        //    $scope.model.columnCheckBoxes[key] = true;
                                        col.template = '<input type="checkbox" #= ' + key + ' ? \'checked=true\' : "" # class="im-grid-checkbox" ng-click="checkboxBoxClick()" />';
                                    } else {
                                        // ng-true-value="{{model.trueValue}}" ng-false-value="{{model.falseValue}}"
                                        //template: function(dataItem) {
                                        //    return dataItem.isActive ? "Active" : "Inactive";
                                        //}
                                        //col.template = function (dataItem) {
                                        //    var checked = dataItem[key] ? 'checked=true' : '';
                                        //    return '<input type="checkbox" ' + checked + ' class="im-grid-checkbox" ng-click="checkboxBoxClick()" ng-disabled="true" />';
                                        //};
                                        col.template = '<input type="checkbox" #= ' + key + ' ? \'checked=true\' : "" # class="im-grid-checkbox" ng-click="checkboxBoxClick()" ng-disabled="true" />';
                                        //col.template = '<input type="checkbox" #= ' + key + ' ? \'ng-checked="checkbox == 1"\' : "" # class="im-grid-checkbox" ng-click="checkboxBoxClick()" ng-disabled="true" />';
                                        //col.template = '<input type="checkbox" #= ' + col.field + ' # ng-checked="checkbox == true || checkbox == 1 || checkbox == \'1\'" class="im-grid-checkbox" ng-click="checkboxBoxClick()" ng-disabled="true" />';
                                    }
                                    //col.template = '<input type="checkbox" #= ' + key + ' ? \'checked=true\' : "" # class="chkbx k-checkbox" />';
                                } else if (editor !== null) col.editor = editor;
                                if (format !== null) col.format = format;
                                if (angular.isDefined(currentCol.hidden)) col.hidden = currentCol.hidden;
                                if (angular.isDefined(currentCol.width)) {
                                    col.width = currentCol.width;
                                } else {
                                    if (angular.isUndefined(currentCol.hidden) || currentCol.hidden === false) hasWidthless = true;
                                }
                                cols.push(col);
                            }

                            //bhw
                            if (angular.isDefined($scope.optionfunc)) {
                                $scope.optionfunc({ data: { type: 'init', grid: $scope.model.grid } });
                            }
                        });

                        cols.sort(function (a, b) {
                            return referenceOrder[a.field] - referenceOrder[b.field];
                        });

                        //console.log('cols');
                        //console.dir(cols);

                        var first = 'grid_glyphs';
                        cols.sort(function (x, y) { return x.field === first ? -1 : y.field === first ? 1 : 0; }); // Makes sure the column is first

                        if (hasFunctions) {
                            if (cols[cols.length - 1].field !== 'grid_functions') {
                                for (var s = 0; s < cols.length; s++) {
                                    if (cols[s].field === 'grid_functions') {
                                        cols.push(cols.splice(s, 1)[0]);
                                        break;
                                    }
                                }
                            }
                        }

                        var emptyCol = {
                            field: 'grid_empty_col',
                            command: [],
                            title: ' ',
                            filterable: false,
                            //locked:true,
                            width: undefined,
                            attributes: {
                                style: "padding: 0.2em 0.5em"
                            }
                        }
                        cols.push(emptyCol);

                        var b = $scope.setup.behaviour;
                        if (angular.isDefined(b) && 
                            angular.isDefined(b.selectable) && 
                            (b.selectable === true || (typeof b.selectable === 'string' && (b.selectable.indexOf('row') > -1 || b.selectable.indexOf('multiple') > -1)))
                            ) {
                            var selectColKey = 'grid_select_col';
                            var selectCol = {
                                field: selectColKey,
                                //command: [],
                                title: ' ',
                                filterable: false,
                                //locked:true,
                                selectable: true,
                                resizable: false,
                                reorderable: false,
                                width: "34px",
                                sortable: false,
                                attributes: {
                                    //style: "padding: 0.2em 0.5em"
                                    //style: "padding: 0.2em 0em 0em 0em"
                                    style: "padding: 0.2em 0em 0em 0.6em"
                                }
                            }
                            cols.unshift(selectCol);
                            tempSchema.model.fields[selectColKey] = { from: selectColKey };
                        }
                        //$scope.model.nonReordableColumn = cols[cols.length-1];

                        //if (!hasWidthless) {
                        //    cols[cols.length - 1].width = undefined;
                        //}

                        //var reorderedCols = [];
                        //angular.forEach(colOrder, function (col) {
                        //    for (var c = 0; c < cols.length; )
                        //    if (angular.isDefined(col.field)) {
                        //        sort.push({ field: col.field, dir: col.sort.dir });
                        //    }
                        //});

                        angular.copy(cols, $scope.model.gridSetup.columns);
                        angular.copy(tempSchema, $scope.model.gridDataSourceSchema);
                    }

                    //$scope.generateGridToolbar();
                    generateGridToolbar();
                    console.dir($scope.setup.behaviour);

                    if (angular.isDefined($scope.setup.translate) && $scope.setup.translate === true) {
                        var deregTranslations = translateService.on($scope.model.translations, 'changed', function (result) {
                            angular.copy(result, $scope.model.translations);

                            angular.forEach($scope.model.gridSetup.columns, function (col) {
                                if (col.field !== 'grid_empty_col') col.title = $scope.model.translations[col.field];
                            });

                            var options = $scope.model.grid.getOptions();
                            //if (angular.isDefined(options.filterable.mode)) {}
                            options.toolbar = $scope.gridToolbar;
                            //options.height = newHeight;
                            //bhw
                            /*if ($scope.setup.height > 0) {
                                options.height = $scope.setup.height;
                            } else {
                                options.height = $scope.model.gridSetup.height;
                            }*/                            
                            options.columns = $scope.model.gridSetup.columns;
                            options.dataSource.schema = $scope.model.gridDataSourceSchema;
                            options.dataSource.data = $scope.model.gridDataSourceData;
                            if (angular.isDefined($scope.setup.pager)) {
                                if (angular.isDefined($scope.setup.pager.pageSizes)) generatePageSizes(options);
                                if (angular.isDefined($scope.setup.pager.pageSize)) options.pageable.pageSize = $scope.setup.pager.pageSize;
                            }
                            if (sort.length > 0) options.dataSource.sort = sort;
                            if (filter.length > 0) options.dataSource.filter = filter;
                            if (angular.isDefined($scope.setup.behaviour.selectable)) options.selectable = $scope.setup.behaviour.selectable;
                            if (angular.isDefined($scope.setup.filter)) {
                                if (angular.isDefined($scope.setup.filter.mode)) {
                                    if ($scope.setup.filter.mode) {
                                        options.filterable = { mode: "row" };
                                        options.excel.filterable = true;
                                        options.pdf.filterable = true;
                                    } else {
                                        $scope.model.grid.dataSource.filter({});
                                        options.dataSource.filter = undefined;
                                        options.filterable = false;
                                        options.excel.filterable = false;
                                        options.pdf.filterable = false;
                                    }
                                }
                            }
                            //$scope.model.grid.dataSource.data($scope.model.gridDataSourceData);
                            console.dir(options);
                            $scope.model.grid.setOptions(options);
                            $scope.model.initialized = true;

                            if (angular.isDefined($scope.setup.autosave)) {
                                $scope.model.autosave = $scope.setup.autosave;
                            } else $scope.model.autosave = true;

                            deregTranslations();

                            //$timeout(function () {
                            //}, 100);
                        });
                    } else {
                        var options = $scope.model.grid.getOptions();
                        options.toolbar = $scope.gridToolbar;
                        //options.height = newHeight;
                        //bhw
                        /*console.log('Setup height',$scope.setup.height);
                        if ($scope.setup.height > 0) {
                            options.height = $scope.setup.height;
                        } else {
                            options.height = $scope.model.gridSetup.height;
                        }*/
                        options.columns = $scope.model.gridSetup.columns;
                        options.dataSource.schema = $scope.model.gridDataSourceSchema;
                        options.dataSource.data = $scope.model.gridDataSourceData;
                        if (angular.isDefined($scope.setup.pager)) {
                            if (angular.isDefined($scope.setup.pager.pageSizes)) generatePageSizes(options);
                            if (angular.isDefined($scope.setup.pager.pageSize)) options.pageable.pageSize = $scope.setup.pager.pageSize;
                        }
                        if (sort.length > 0) options.dataSource.sort = sort;
                        if (filter.length > 0) options.dataSource.filter = filter;
                        if (angular.isDefined($scope.setup.behaviour.selectable)) options.selectable = $scope.setup.behaviour.selectable;
                        if (angular.isDefined($scope.setup.filter)) {
                            if (angular.isDefined($scope.setup.filter.mode)) {
                                if ($scope.setup.filter.mode) {
                                    options.filterable = { mode: "row" };
                                    options.excel.filterable = true;
                                    options.pdf.filterable = true;
                                } else {
                                    $scope.model.grid.dataSource.filter({});
                                    options.dataSource.filter = undefined;
                                    options.filterable = false;
                                    options.excel.filterable = false;
                                    options.pdf.filterable = false;
                                }
                            }
                        }
                        //$scope.model.grid.dataSource.data($scope.model.gridDataSourceData);
                        $scope.model.grid.setOptions(options);
                        $scope.model.initialized = true;

                        if (angular.isDefined($scope.setup.autosave)) {
                            $scope.model.autosave = $scope.setup.autosave;
                        } else $scope.model.autosave = true;

                        //$timeout(function () {
                        //}, 100);
                    }

                    //console.log('watch done');
                    //$timeout(function () {
                    //    console.log('watch refreshed');
                    //    $scope.model.grid.refresh();
                    //}, 200);

                    //e.preventDefault();
                    //var grid = $scope.model.grid;
                    //var selectedRows = grid.select();
                    //var uidArray = [];

                    //$.each(selectedRows, function () {
                    //    uidArray.push($(this).attr("data-uid"));
                    //});

                    //$.each(uidArray, function (index, value) {
                    //    var dataItem = grid.dataItem($('tr[data-uid=' + value + ']'));
                    //    grid.dataSource.remove(dataItem);
                    //});
                    //grid.dataSource.sync();
                    var qt = null;
                    if (angular.isDefined($scope.gridfunc)) {
                        console.log('gridfunc set');
                        $scope.gridfunc = {
                            refresh: function () { $scope.model.grid.refresh(); },
                            clearSorting: function () { clearSorting(); },
                            addRowBefore: function (atIndex, dataItem) { gridAddRow(atIndex, dataItem, true); },
                            addRowAfter: function (atIndex, dataItem) { gridAddRow(atIndex, dataItem); },
                            removeRow: function (dataItem) { $scope.model.grid.dataSource.remove(dataItem); $scope.model.grid.dataSource.sync(); getSelectedRows();},
                            getSelectedRows: function () { return $scope.model.selectedRows; },
                            //bhw
                            getSelectedRowsCount: function () { 
                                var rows = $scope.model.grid.select();
                                $scope.model.selectedRows.index = [];
                                $scope.model.selectedRows.data = [];
                                rows.each(function (r) {
                                    var dataItem = $scope.model.grid.dataItem(this);
                                    var index = $scope.model.grid.dataSource.indexOf(dataItem);

                                    $scope.model.selectedRows.index.push(index);
                                    $scope.model.selectedRows.data.push(dataItem);
                                });
                                return $scope.model.selectedRows.data.length; 
                            },
                            setButtons: function(btns){
                                if (Array.isArray(btns)){
                                    angular.forEach(btns, function (button) {
                                        var btn = $scope.makeToolbarBtnTemplate(button.name, angular.isDefined(button.text) ? button.text : '', 'customGridToolbarBtnClick(\'' + button.name + '\')', button.icon, (angular.isDefined(button.cssClass) ? button.cssClass : ''));
                                        if (btn !== null) $scope.gridToolbar.push(btn);
                                    });
                                } else {
                                    var button = btns;
                                    var btn = $scope.makeToolbarBtnTemplate(button.name, angular.isDefined(button.text) ? button.text : '', 'customGridToolbarBtnClick(\'' + button.name + '\')', button.icon, (angular.isDefined(button.cssClass) ? button.cssClass : ''));
                                    if (btn !== null) $scope.gridToolbar.push(btn);
                                }
                            },
                            clearButtons: function(){
                                $scope.gridToolbar = [];
                            },
                            //bhw
                            selectAll: function() {
                                $timeout(function(){
                                    $('.k-checkbox').each(function(index,elem){
                                        //elem.click();
                                        if (index === 0){
                                            elem.click();    
                                        }
                                        console.dir(elem);
                                    });
                                },500);                                
                            },
                            hideSelectAll: function(){
                                if (angular.isDefined(qt)){
                                    $timeout.cancel(qt);
                                }
                                qt = $timeout(function(){
                                    $('.k-checkbox').each(function(index,elem){
                                        //elem.click();
                                        if (index == 0){
                                            console.log('elem:',elem.id);
                                            console.dir(elem);
                                            //$('.'+elem.id).hide();
                                            $('.'+elem.id).attr('style', 'display:none;');
                                        }
                                        
                                    });
                                },1000);                                
                            },
                            getRows: function () { return $scope.model.grid.select(); },
                            getGrid: function () { return $scope.model.grid; },
                            getItems: function () { return $scope.model.grid.dataItems(); },
                            //getSelectedRows: function () { return getSelectedRows(); },
                            getDirtyRows: function () { return getDirtyRows(); },
                            getAllRows: function () { return $scope.model.grid.dataSource.data(); }
                        };
                    }
                });

                // ###############################
                // #endregion watchers
                // ###############################
            }
        };

        return directive;
    }]);
})();