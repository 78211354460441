(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("diaryService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var service = {
            loaded: false,
            listPortalUsers: function (parms) {
                return $ihttp.post({
                    method: 842,
                    parameters: parms || {}
                });
            },
            deleteCustact: function (custactNo) {
                return $ihttp.post({
                    method: 136,
                    parameters: {
                        custact_no: custactNo
                    }
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            updateItem: function (data) {
                data.webpagename = 'diary';
                return call(849, data);
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            }
        };

        return service;
    }]);
})();
