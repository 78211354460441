(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2WebpageEdit', {
        templateUrl: 'views/components/views/p2WebpageEdit/p2WebpageEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2WebpageEditService', 'stateService', function($stateParams, p2WebpageEditService, stateService) {
    
            var vm = this;
    
    
    
            // #######################
            // VARIABLES & DEFINITIONS
            // #######################
    
            var isSaving = false;
            
            vm.lockingP2WebpageEditSaveButton = {
                locked: '0'
            };
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            p2WebpageEditService.loadP2WebpageEdit($stateParams.p2_webpage_keyno || '0').then(function () {
                vm.p2_webpage_edit = p2WebpageEditService.p2_webpage_edit;
            });
    
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
    
            vm.saveP2WebpageEdit = function () {
                if (isSaving == true || vm.lockingP2WebpageEditSaveButton.locked == '1') return;
    
                isSaving = true;
                vm.lockingP2WebpageEditSaveButton.locked = '1';
    
                p2WebpageEditService.saveP2WebpageEdit().then(function () {
                    stateService.back();
                });
    
                isSaving = false;
            };
    
            // ##########################
            // DIRECTION BUTTON FUNCTIONS
            // ##########################
    
            vm.goToWebpageLink = function () {
                stateService.go('webpagelink', {
                    p2_webpage_keyno: vm.p2_webpage_edit.p2_webpage_keyno
                });
            };
    
            vm.goToWebpageSetting = function () {
                stateService.go('webpagesetting', {
                    argtype: 'p2_webpage_keyno',
                    argvalue: vm.p2_webpage_edit.p2_webpage_keyno
                });
            };
    
            vm.goToP2WebpageTable = function () {
                stateService.go('p2webpagetable', {
                    argtype: 'p2_webpage_keyno',
                    argvalue: vm.p2_webpage_edit.p2_webpage_keyno
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##		// Tip prefix types: get | set
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'webpagesubscription':
                        go({
                            argtype: 'p2_webpage_keyno',
                            argvalue: vm.p2_webpage_edit.p2_webpage_keyno
                        });
                        break;
                }
            };
        }]
    });
})();