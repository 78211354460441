import { Component, OnDestroy, OnInit } from "@angular/core";
import { ComponentBaseComponent } from "../../../core/components/component-base/component-base.component";
import { RememberService } from "../../../core/services/remember.service";
import { StateService } from "../../../core/services/state.service";
import { UtilityService } from "../../../core/services/utility.service";
import {
    SettingsGetResult,
    SettingsService,
    WebPageMenuListResult,
} from "./settings.service";


@Component({
    selector: "tt-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.css"],
})
export class SettingsComponent
    extends ComponentBaseComponent
    implements OnInit, OnDestroy {

    model: any = {};

    /*
    model: {
        rememberId: string
        filterValueButtons: FilterValueButtons[];
        settings: SettingsGetResult;
        itemsListSettings: WebPageMenuListResult[];
    } = {
            rememberId: 'w_settings',
            filterValueButtons: [],
            settings: {
                filterval: ''
            },
            itemsListSettings: [],
        };
    */

    getSettings: Pick<SettingsGetResult, 'filterval'> | undefined = undefined;
    listSettings: WebPageMenuListResult[] = [];


    variableNames = {
        filterval: '',
    };

    rememberConfig = {
        filterval: ''
    }

    constructor(
        private settingsService: SettingsService,
        private stateService: StateService,
        private utilityService: UtilityService,
        private rememberService: RememberService
    ) {
        super();
    }

    ngOnInit() {
        this.initModel();
        this.loadData();

        // Test
        //const test = this.createRememberIdFromObject(this.rememberConfig, this.stateService.getCurrentName());
        //const test = this.createRememberIdFromObject(this.rememberConfig);
    }

    ngOnDestroy() { }

    private initModel() {
        this.model = {
            rememberId: 'w_settings',
            filterValueButtons: [
                {
                    id: "erase",
                    icon: "fa fa-solid fa-eraser",
                    color: "danger",
                    type: "danger",
                    onClick: () => (this.model.setting.filterval = ''),
                }
            ],
            setting: {
                filterval: ''
            },
            itemsListSettings: [],
        };
    }

    private loadData() {
        this.loadGetSettings();
        this.loadListSettings();
    }

    private async loadGetSettings() {
        const data = await this.settingsService.getSettings();
        this.model.setting = Array.isArray(data) ? data[0] : data;
    }

    private async loadListSettings() {
        this.model.itemsListSettings = await this.settingsService.listSettings({
            webpage_name: this.stateService.getCurrentName(),
        });
    this.validateParmsWithValue
    }
    private reset(id: string): void {
        this.model.setting.filterval = '';
        this.rememberService.remember(this.model.rememberId + '.filterval', this.model.setting.filterval);

        /*
        switch (id) {
            case "filterval":
                this.model.setting.filterval = "";
                if (this.model.setting.filterval === "") this.rememberFunc("filterval");
                break;
            default:
                break;
        }
        */
    }





/*
    private async rememberFunc(id: any) {
        Object.keys(this.variableNames).forEach((key) => {
            (this.variableNames as Record<string, string>)[key] = "w_" + this.stateService.getCurrentName() + "." + key
        });

        if (this.utilityService.validateParmsValue((this.model.setting as string)[id], true) !== true) return;
    
        let variableValue: string | null = null;

        switch (id) {
            case 'filterval':
                if (this.utilityService.validateParmsValue((this.model.setting as string)[id], true) !== true) return;
    
                variableValue = (this.model.setting as string)[id];
                break;
            default:
                break;
        }
    
        return this.rememberService.remember((this.variableNames as Record<string, string>)[id], variableValue);
    }
    */

    onChanged(value: any, id: any) {
    /*
        switch (id) {
            case 'filterval':
                if (utilityService.validateWatchValue(value, vm.model.setting.filterval) !== true) return;

                vm.model.setting.filterval = value;

                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                if (vm.model.setting.filterval === '') rememberFunc('filterval');
                break;
            default:
                utilityService.setValueToVariable(value);
                break;
        }
    */
    }

    onBlur(id: any) {
    /*
        switch (id) {
            case 'filterval':
                if (utilityService.validateParmsValue(vm.model.setting.filterval, true) !== true) return;

                if (vm.model.setting.filterval > '') rememberFunc('filterval');
                break;
            default:
                break;
        }
    */
    }

    private createRememberIdFromObject(object: Record<string, any>, idString?: string) {
        const rememberIdList: Array<any> = [];
        const rememberIdString = idString !== undefined ? idString : btoa(this.serializeObject(object));

        Object.keys(object).forEach(key => rememberIdList.push(`${rememberIdString}.${key}`));

        return rememberIdList;
    }

    private serializeObject(object: Record<string, any>): string {
        const keys = Object.keys(object).sort();
        const parts = keys.map(key => `${key}:${this.serializeObject(object[key])}`);

        return `{${parts.join(',')}}`;
    }


}
