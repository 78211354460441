(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderLineEdit', {
        templateUrl: 'views/components/views/orderLineEdit/orderLineEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'orderlineEditService', 'stateService', 'searchService', function($stateParams, orderlineEditService, stateService, searchService) {
            var vm = this;
            vm.orderline_edit = orderlineEditService.orderline_edit;
            vm.propertylist_get = orderlineEditService.propertylist_get;
            vm.prodchoicelist_get = orderlineEditService.prodchoicelist_get;
            vm.prodsize_values = orderlineEditService.prodsize_values;
            vm.saveOrderline = orderlineEditService.saveOrderline;
            
            orderlineEditService.loadOrderline($stateParams.orderline_keyno, $stateParams.argtype, $stateParams.argvalue).then(function () {
                if (orderlineEditService.selectingProduct !== true && orderlineEditService.selectingProdsize !== true) return;
                
                if (orderlineEditService.selectingProduct === true && searchService.productOrderLine.selectedProdId.length > 0) {
                    orderlineEditService.selectingProduct = false;
                    vm.orderline_edit.prod_id = searchService.productOrderLine.selectedProdId;
                    vm.orderline_edit.prod_name = searchService.productOrderLine.selectedProdName;
                }
                
                if (vm.orderline_edit.prod_id !== '') {
                    orderlineEditService.loadPropertyList();
                    
                    orderlineEditService.loadProdChoiceList().then(function () {
                        orderlineEditService.prodchoicelist_get.choosed_prod_name = searchService.selProdChoice.selectedProdChoiceSearch;
                        //('Her skal listen være hentet.......Antall=' + vm.prodchoicelist_get.records.length)
                    });
                    
                    orderlineEditService.productUnitGet();
                }
                
                if (orderlineEditService.selectingProdsize === true && searchService.prodsize.selectedProdsizeKeyno.length > 0) {
                    orderlineEditService.selectingProdsize = false;
                    
                    //('Skal ha fått verdi i searchService.prodsize.selectedProdsizeKeyno no: ' + searchService.prodsize.selectedProdsizeKeyno)
                    vm.orderline_edit.prodsize_keyno = searchService.prodsize.selectedProdsizeKeyno;
                    orderlineEditService.getProdsizeValues();
                }
            });
            
            if (orderlineEditService.selectingProdChoice === true) {
                orderlineEditService.selectingProdChoice = false;
                
                if (angular.isDefined(searchService.prodChoiceSelected)) {
                    for (var i = 0; i < orderlineEditService.prodchoicelist_get.records.length; i++) {
                        if (orderlineEditService.prodchoicelist_get.records[i].choice_keyno !== searchService.prodChoiceSelected.choice_keyno) continue;
                        
                        orderlineEditService.prodchoicelist_get.records[i].choosed_prod_id = searchService.prodChoiceSelected.prod_id;
                        orderlineEditService.prodchoicelist_get.records[i].choosed_prod_name = searchService.prodChoiceSelected.prod_name;
                        break;
                    }
                }
            }
            
            vm.goToSelectSearch = function () {
                orderlineEditService.selectingProduct = true;
                
                stateService.go('selectproduct', {
                    webpage_name: stateService.getCurrentName(),
                    parm1: $stateParams.argvalue,
                    parm2: $stateParams.orderline_keyno
                });
            };
            
            vm.goToSelectProdsize = function () {
                orderlineEditService.selectingProdsize = true;
                
                stateService.go('selectprodsize', {
                    webpage_name: stateService.getCurrentName(),
                    parm1: $stateParams.argvalue,
                    parm2: $stateParams.orderline_keyno,
                    prod_id: vm.orderline_edit.prod_id
                });
            };
            
            vm.goToSelectProdChoice = function (index) {
                orderlineEditService.selectingProdChoice = true;
                
                stateService.go('selectprodchoice', {
                    choice_keyno: vm.prodchoicelist_get.records[index].choice_keyno,
                    argtype: 'orderline_keyno',
                    argvalue: '0'
                });
            };
        }]
    });
})();