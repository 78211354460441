(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectTypeInts', {
        templateUrl: 'views/components/views/serviceObjectTypeInts/serviceObjectTypeInts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'serviceObjectTypeIntsService', function($stateParams, stateService, serviceObjectTypeIntsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                serviceObjectTypeKeyNo: $stateParams.serviceobjecttype_keyno,
                settingIntervals: {},
                itemsListIntervals: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            serviceObjectTypeIntsService.loadServiceObjectTypeIntsGet(vm.model.serviceObjectTypeKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.settingIntervals);
            });
    
            serviceObjectTypeIntsService.loadServiceObjectTypeIntsList(vm.model.serviceObjectTypeKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListIntervals);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'serviceobjecttypeint':
                        go({
                            serviceobjecttype_keyno: vm.model.serviceObjectTypeKeyNo,
                            serviceobjecttypeint_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();