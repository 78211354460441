(function() {
    'use strict';

    angular.module('imApp').factory('pictureBankService', ['$ihttp', function ($ihttp) {
        let service = {
            getPictureBank: function (parms) {
                return $ihttp.post({
                    method: 2534,
                    parameters: parms || {}
                });
            },

            deletePictureBank: function (parms) {
                return $ihttp.post({
                    method: 2537,
                    parameters: parms || {}
                });
            },

            savePictureBank: function (parms) {
                return $ihttp.post({
                    method: 2541,
                    parameters: parms || {}
                });
            },
        }

        return service;
    }]);
})();
