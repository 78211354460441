
/******************************************************************
auth: ODL
description: beskrivelse
mods:       
*******************************************************************/
(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("bcrService", ['$q', '$ihttp', 'consoleService', function($q, $ihttp, consoleService) {
        var service = {
            loaded: false,
            p2WBcrGet: {},
            loadP2WBcrGet: function (barcode) {
                var deferred = $q.defer();

                var parmsP2WBcrGet = {
                    method: 501,
                    parameters: {
                        barcode: barcode
                    }
                };

                consoleService.log('parmsP2WBcrGet');
                consoleService.log(angular.toJson(parmsP2WBcrGet));

                // Procedure sp_ws_p2_w_bcr_get
                $ihttp.post(parmsP2WBcrGet).then(function (data) {
                    consoleService.log('sp_ws_p2_w_bcr_get');
                    consoleService.log(angular.toJson(data));

                    service.p2WBcrGet = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        }
        return service;
    }]);
})();
