(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('labours', {
        templateUrl: 'views/components/views/labours/labours.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['laboursService', 'stateService', 'utilityService', 'rememberService', 'idServerService', function (laboursService, stateService, utilityService, rememberService, idServerService) {
            var vm = this;

            let variableNames = {
                activestatus: '',
                gb_labourtype: '',
                searchtext: ''
            };

            vm.model = {
                bgTypes: {
                    selected: 'ACT',
                    buttons: [
                        { id: 'ACT', label: 'active', color: 'primary', onClick: () => setTypesView('ACT') },
                        { id: 'NOT_ACT', label: 'not_active', color: 'primary', onClick: () => setTypesView('NOT_ACT') },
                        { id: 'ALL', label: 'all', color: 'primary', onClick: () => setTypesView('ALL') }
                    ]
                },
                bgLabourType: {
                    selected: 'INTERNAL',
                    buttons: [
                        { id: 'INTERNAL', label: 'gb_labourtype_internal', color: 'primary', onClick: () => setLabourTypeView('INTERNAL') },
                        { id: 'EXTERNAL', label: 'gb_labourtype_external', color: 'primary', onClick: () => setLabourTypeView('EXTERNAL') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchLabours() }
                ],
                service: laboursService,
                setting: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) { };
            
            let setTypesView = function (id) {
                vm.model.bgTypes.selected = vm.model.setting.activestatus = id;
            };
            
            let setLabourTypeView = function (id) {
                vm.model.bgLabourType.selected = vm.model.setting.gb_labourtype = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

		    // #endregion INIT LOAD DONE TIMEOUT FUNCTION

            laboursService.loadLaboursSettings().then(function (data) {
                vm.model.setting = angular.copy(data[0]);

                setTypesView(vm.model.setting.activestatus);
                setLabourTypeView(vm.model.setting.gb_labourtype);

                loadGrid();
            });

            var loadGrid = function () {
                idServerService.currentUserDetails().then(function (loggedInUser) {
                    if (angular.isObject(loggedInUser) !== true) return;
                    if (angular.isDefined(loggedInUser.userName) !== true) return;
                    if (loggedInUser.canAccessClient !== true) return;
                    if (loggedInUser.isCompanyAdmin !== true) return;
                });

                vm.grid.dataTask.rememberId = 'w_labours.grid';

                if (utilityService.validateParmsValue(vm.model.setting.activestatus) === true
                    && utilityService.validateParmsValue(vm.model.setting.gb_labourtype) === true
                    && utilityService.validateParmsValue(vm.model.setting.searchtext, true) === true) {
                    vm.grid.dataTask.loadData = {
                        method: '2492',
                        parameters: vm.model.setting
                    };
                }
                
                vm.model.gridReady = true;
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'activestatus':
                    case 'gb_labourtype':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        variableValue = vm.model.setting.searchtext;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            vm.grid.gridfunc.rebind();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.searchLabours = function () {
                rememberFunc('searchtext');
                vm.grid.gridfunc.rebind();
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'labouredit':
                        go({ labour_no: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgTypes = function (value) {
                vm.model.bgTypes.selected = value;
                vm.model.setting.activestatus = vm.model.bgTypes.selected;

                rememberFunc('activestatus');
                vm.grid.gridfunc.rebind();
            };
            
            vm.selectBgLabourType = function (value) {
                vm.model.bgLabourType.selected = value;
                vm.model.setting.gb_labourtype = vm.model.bgLabourType.selected;

                rememberFunc('gb_labourtype');
                vm.grid.gridfunc.rebind();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') {
                            rememberFunc('searchtext');
                            vm.grid.gridfunc.rebind();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                        
                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            vm.grid.gridfunc.rebind();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
