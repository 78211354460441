(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("dndService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,

            load_from_service: 0,
            


            
            timescale_records: [],
            workdata_records: [],
            
            data1: [],
            data1_labels: [],

            data2: [],
            data2_labels: [],

            data3: [],
            data3_labels: [],



            reset: function () {
                
                this.timescale_records = [];
                this.workdata_records = [];
                this.data1 = [];
                this.data1_labels = [];

                this.data2 = [];
                this.data2_labels = [];

                this.data3 = [];
                this.data3_labels = [];
            }
        }
        return service;
    }]);

})();