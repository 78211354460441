(function () {
    'use strict';

    angular.module("imApp").factory("webpageSubscriptionService", ['$ihttp', function ($ihttp) {
        let service = {
            getWebPageSubscription: function (parms) {
                return $ihttp.post({
                    method: 1183,
                    parameters: parms || {}
                });
            },
            listWebPageSubscriptions: function (parms) {
                return $ihttp.post({
                    method: 82,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
