(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButtonSave', {
        templateUrl: 'views/components/directives/ttButtonSave/ttButtonSave.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttClick: '&',
            ttText: '@',
            ttTextAlign: '@',
            ttIconAlign: '@',
            ttIconPullRight: '@',
            ttLocked: '<',
            ttLockedText: '@',
            ttBadge: '@',
            ttType: '@',
            ttButtonType: '@',
            ttIcon: '@',
            ttDisabled: '<',
            ttItemId: '@',
            ttStyle: '<',
        },
        controller: ['eventService', 'utilityService', function (eventService, us) {
            var vm = this;

            vm.text = 'save'
            vm.type = 'success';
            vm.icon = 'far fa-arrow-to-bottom';

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttType) && us.isStringValue(changes.ttType.currentValue)) {
                    vm.type = changes.ttType.currentValue;
                }

                if (angular.isDefined(changes.ttText) && us.isStringValue(changes.ttText.currentValue)) {
                    vm.text = changes.ttText.currentValue;
                }

                if (angular.isDefined(changes.ttIcon) && us.isStringValue(changes.ttIcon.currentValue)) {
                    vm.icon = changes.ttIcon.currentValue;
                }
            };

            vm.onClick = function () {
                if (angular.isFunction(vm.ttClick)) {
                    vm.ttClick();
                }
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };
        }]
    });
})();
