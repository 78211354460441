(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('mapView', {
        templateUrl: 'views/components/views/mapView/mapView.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'mapViewService', function($stateParams, stateService, mapViewService) {
            var vm = this;
            
            vm.model = {
                center: [],
                fullscreen: false,
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                pageInfo: {},
                gis: []
            };
            
            mapViewService.loadMapViewGet(vm.model.argType, vm.model.argValue).then(function (data) {
                vm.model.pageInfo = angular.copy(data[0]);
            });
            
            mapViewService.loadGisObjectsGet(vm.model.argType, vm.model.argValue).then(function (data) {
                vm.model.gis = angular.copy(data);
                
                //if (vm.model.gis.length > 0) {
                //    vm.mapOptions.center = [vm.model.gis[0].locations.lat, vm.model.gis[0].locations.lng];
                //}
            });
            
            vm.addPos = function (location) {
                stateService.go('gispositionadd', {
                    argtype: vm.model.pageInfo.argtype,
                    argvalue: vm.model.pageInfo.argvalue,
                    lat: location.lat,
                    lng: location.lng
                });
            };
            
            vm.selectPos = function (marker) {
                stateService.go('gisposition', {
                    //argtype: vm.model.pageInfo.argtype,
                    //argvalue: vm.model.pageInfo.argvalue,
                    //gisposition_keyno: marker.id
                    gisposition_keyno: marker.parms
                });
            };
        }]
    });
})();