(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("msgCreateService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadMsgCreateGet: function () {
                return p2DataTaskService.call(1683, {});
            },
            save: function (settingMessage) {
                return p2DataTaskService.call(1684, settingMessage);
            }
        };

        return service;
    }]);
})();