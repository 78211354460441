(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectMenu', {
        templateUrl: 'views/components/views/serviceObjectMenu/serviceObjectMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'stateService', 'modalService', 'translateService', 'rememberService', 'serviceObjectMenuService', 'typeaheadService', function ($stateParams, utilityService, stateService, modalService, translateService, rememberService, serviceObjectMenuService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            let variableNames = {
                bg_serviceobjectmenu_main: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                serviceObjectKeyNo: $stateParams.serviceobject_keyno,
                bgServiceObjectMenuMain: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'bg_serviceobjectmenu_main_basic', color: 'primary', onClick: () => setServiceObjectMenuMainView('BASIC') },
                        { id: 'CUSTACTS', label: 'bg_serviceobjectmenu_main_custacts', color: 'primary', onClick: () => setServiceObjectMenuMainView('CUSTACTS') },
                        { id: 'LINKS', label: 'bg_serviceobjectmenu_main_links', color: 'primary', onClick: () => setServiceObjectMenuMainView('LINKS') }
                    ]
                },
                vehicleRegisterNumberButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.menu.vehicle_url) }
                ],
                imoNumberButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.menu.imo_url) }
                ],
                customerNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('customersmenu', { cust_no: vm.model.menu.cust_no }) }
                ],
                dateButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', onClick: { click: function (item, state) { vm.goTo(state, item); }, state: 'serviceobjectsop' } }
                ],
                prodNameSearchButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', onClick: { click: function (item, state) { vm.goTo(state, item); }, state: 'serviceobjectsop' } }
                ],
                clButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', onClick: { click: function (item, state) { vm.goTo(state, item); }, state: 'serviceobjectsop' } }
                ],
                numberButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', onClick: { click: function (item, state) { vm.goTo(state, item); }, state: 'serviceobjectsop' } }
                ],
                noteButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', onClick: { click: function (item, state) { vm.goTo(state, item); }, state: 'serviceobjectsop' } }
                ],
                menu: {},
                itemsListCustacts: [],
                itemsListMenuLinks: [],
                itemsListSops: [],
                inputListFields: {
                    fields: []
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setServiceObjectMenuMainView = function (id) {
                vm.model.bgServiceObjectMenuMain.selected = vm.model.menu.bg_serviceobjectmenu_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##
            
            serviceObjectMenuService.loadServiceObjectMenuGet(vm.model.serviceObjectKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.menu);
                setServiceObjectMenuMainView(vm.model.menu.bg_serviceobjectmenu_main);
                
                loadServiceObjectsSops();
                loadServiceObjectMenuFields();
            });
            
            serviceObjectMenuService.loadServiceObjectsCustactList(vm.model.serviceObjectKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListCustacts);
            });
    
            serviceObjectMenuService.loadServiceObjectMenuLinkList(vm.model.serviceObjectKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListMenuLinks);
            });
    
            var loadServiceObjectsSops = function () {
                if (utilityService.validateParmsValue(vm.model.menu.serviceobject_keyno) !== true) return;
    
                vm.model.itemsListSops = [];
    
                serviceObjectMenuService.loadServiceObjectsSopsList(vm.model.menu.serviceobject_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListSops);
                });
            };
    
            var loadServiceObjectMenuFields = function () {
                if (utilityService.validateParmsValue(vm.model.menu.serviceobject_keyno) !== true) return;
    
                vm.model.inputListFields = {};
    
                serviceObjectMenuService.listServiceObjectMenuFields({ serviceobject_keyno: vm.model.menu.serviceobject_keyno }).then(function (data) {
                    angular.copy(data[0], vm.model.inputListFields);
                });
            };
    
            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL
    
            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchProduct({ prod_name: value || '' });
            };
    
            vm.setProdId = function (item, index) {
                vm.model.inputListFields.fields[index].textvalue = angular.isUndefined(item) ? '0' : item.item_id;
                vm.model.inputListFields.fields[index].prod_name = angular.isUndefined(item) ? '' : item.item_name;

                savingField(item, index);
            };
            
            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL
            
            // ## REMEMBER VALUE FUNCTIONS ##
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'bg_serviceobjectmenu_main':
                        if (utilityService.validateParmsValue(vm.model.menu.bg_serviceobjectmenu_main) !== true) return;
                        
                        variableValue = vm.model.menu.bg_serviceobjectmenu_main;
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgServiceObjectMenuMain = function (value) {
                vm.model.bgServiceObjectMenuMain.selected = value;
                vm.model.menu.bg_serviceobjectmenu_main = vm.model.bgServiceObjectMenuMain.selected;

                rememberFunc('bg_serviceobjectmenu_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state, item) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'serviceobjectsop':
                        go({
                            serviceobjectsop_keyno: item.serviceobjectsop_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'serviceobject':
                    case 'serviceobjectnewact':
                        go({ serviceobject_keyno: vm.model.menu.serviceobject_keyno });
                        break;
                    case 'picturebanks':
                    case 'pictures':
                    case 'documents':
                        go({
                            argtype: 'serviceobject_keyno',
                            argvalue: vm.model.menu.serviceobject_keyno
                        });
                        break;
                    case 'productlinks':
                        go({
                            argtype: 'serviceobject_keyno',
                            argvalue: vm.model.menu.serviceobject_keyno,
                            productlinktype_keyno: '5'
                        });
                        break;
                    case 'orderheadcreate':
                        go({
                            argtype: 'serviceobject_keyno',
                            argvalue: vm.model.menu.serviceobject_keyno,
                            ordertype_id: '0'
                        });
                        break;
                    case 'custactedit':
                        go({
                            custact_no: '0',
                            filterfield: 'serviceobject_keyno',
                            filtervalue: vm.model.menu.serviceobject_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            let savingField = function (item, index) {
                serviceObjectMenuService.saveField({
                    serviceobjectsop_keyno: vm.model.inputListFields.fields[index].serviceobjectsop_keyno,
                    textvalue: vm.model.inputListFields.fields[index].textvalue
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id, index) {
                switch (id) {
                    case 'dateValue':
                    case 'selectValue':
                        if (utilityService.validateWatchValue(value, vm.model.inputListFields.fields[index].textvalue) !== true) return;

                        vm.model.inputListFields.fields[index].textvalue = value;

                        if (utilityService.validateParmsValue(vm.model.inputListFields.fields[index].textvalue) !== true) return;

                        savingField(id, index);
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id, index) {
                switch (id) {
                    case 'numberValue':
                    case 'noteValue':
                        if (utilityService.validateParmsValue(vm.model.inputListFields.fields[index].textvalue) !== true) return;

                        savingField(id, index);
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

        }]
    });
})();
