//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('printTest', {
//        templateUrl: 'views/components/views/printTest/printTest.template.html?v=' + module.version, 
//        controllerAs: 'vm',
//        controller: ['printService', function(printService) {
    
//            var vm = this;
    
    
//            vm.model = {
//                printClients: [],
//                page: 1,
//                pageSize: 10,
//                total: 0,
//                pageSizes: [5, 10, 15, 25, 50]
//            }
    
//            var load = function () {
//                printService.getClients({ page: vm.model.page, pageSize: vm.model.pageSize }).then(function (data) {
//                    vm.model.total = data.total;
    
//                    angular.copy(data.items, vm.model.printClients);
    
//                    var printClientIndex = {};
    
//                    for (var i = 0; i < data.items.length; i++) {
//                        vm.model.printClients[i].isReady = false;
//                        vm.model.printClients[i].modDateTime = moment(vm.model.printClients[i].modDateTime).format('YYYY-MM-DD HH:mm');
//                        vm.model.printClients[i].regDateTime = moment(vm.model.printClients[i].regDateTime).format('YYYY-MM-DD HH:mm');
    
//                        printClientIndex['a' + vm.model.printClients[i].printClientKeyno] = i;
    
//                        printService.getClientStatus({ printClientKeyno: vm.model.printClients[i].printClientKeyno }).then(function (status) {
//                            var index = printClientIndex['a' + status.printClientKeyno];
    
//                            vm.model.printClients[index].isRunning = status.isRunning;
//                            vm.model.printClients[index].machineName = status.machineName;
//                        });
//                    }
//                });
//            };
    
//            load();
    
//            vm.pageChanged = function () {
//                load();
//            };
    
//            vm.setPageSize = function (size) {
//                vm.model.pageSize = size;
    
//                load();
//            };
//        }]
//    });
//})();
