(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("consignmentLineSelectService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1434,
                    parameters: parms || {}
                });
            },
            listConsignmentLines: function (parms) {
                return $ihttp.post({
                    method: 1435,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1436,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
