(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDelPurchLinesBs', {
        templateUrl: 'views/components/views/batchDelPurchLinesBs/batchDelPurchLinesBs.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'batchDelPurchLinesBsService', 'stateService', function($stateParams, batchDelPurchLinesBsService, stateService) {
			var vm = this;

            vm.model = {
                batchDelPurchLineKeyno: $stateParams.batchdelpurchline_keyno,
                batchDeliveryKeyno: $stateParams.batchdelivery_keyno,
                bgBatchPurchaseLinesBs: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'batchdelivery_gb_simple', item_func: '0' },
                        { item_id: '1', item_name: 'batchdelivery_gb_details', item_func: '1' }
                    ]
                },
                setting: {},
                inputListBatchPurchaseLines: []
            };

            batchDelPurchLinesBsService.getBatchDeliveryPurchaseLinesBs({ batchdelivery_keyno: vm.model.batchDeliveryKeyno }).then(function (data) {
                angular.copy(data[0], vm.model.setting);
            });

            batchDelPurchLinesBsService.listBatchPurchaseLines({
                batchdelpurchline_keyno: vm.model.batchDelPurchLineKeyno,
                batchdelivery_keyno: vm.model.batchDeliveryKeyno
            }).then(function (data) {
                angular.copy(data, vm.model.inputListBatchPurchaseLines);
            });

            vm.selectBgBatchPurchaseLinesBs = function (item) {
                vm.model.bgBatchPurchaseLinesBs.item_selected = item;
            };

            vm.saveChanges = function () {
                var mergeObj = {
                    records: vm.model.inputListBatchPurchaseLines
                };

                batchDelPurchLinesBsService.saveObj(mergeObj).then(function (data) {
                    stateService.back();
                });
            };
		}]
	});
})();