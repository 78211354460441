(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('skillsCertificate', {
        templateUrl: 'views/components/views/skillsCertificate/skillsCertificate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'skillsCertificateService', 'typeaheadService', function ($stateParams, stateService, modalService, translateService, skillsCertificateService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            let translations = {
                error: '',
                ok: ''
            };
            
            vm.model = {
                labourNo: $stateParams.labour_no,
                skillsCertificateKeyno: $stateParams.skillscertificate_keyno,
                edit: {},
                selectListSkillsCertificateTypes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS
            
            skillsCertificateService.getSkillsCertificate({ 
                labour_no: vm.model.labourNo,
                skillscertificate_keyno: vm.model.skillsCertificateKeyno
            }).then(function (result) {
                angular.copy(result[0], vm.model.edit);
            });
            
            skillsCertificateService.listSkillsCertificateTypes().then(function (result) {
                angular.copy(result, vm.model.selectListSkillsCertificateTypes);
            });
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearchIssuerCustomers = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'S',
                    lastselect: 'SEARCH'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SET VALUE FUNCTION

            vm.setIssuerCustNo = function (item) {
                vm.model.edit.issuer_cust_no = angular.isUndefined(item) ? '0' : item.item_id;
                vm.model.edit.issuer_cust_name = angular.isUndefined(item) ? '' : item.item_name;
            };

		    // #endregion SET VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                skillsCertificateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'documents':
                    case 'pictures':
                    case 'checklistdatas':
                        skillsCertificateService.saveObj(vm.model.edit).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                go({
                                    argtype: 'skillscertificate_keyno',
                                    argvalue: vm.model.edit.skillscertificate_keyno
                                });
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
        }]
    });
})();
