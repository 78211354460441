(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCrewResourceViewService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCrewResourceViewGet: function () {
                return p2DataTaskService.call(1820, {});
            },
            loadSrMainResourceList: function () {
                return p2DataTaskService.call(1822, {});
            },
            loadSrSubResourceList: function (main_resource_id) {
                return p2DataTaskService.call(1823, {
                    main_resource_id: main_resource_id
                });
            },
            loadSrCrewResourceViewList: function (sub_resource_id) {
                return p2DataTaskService.call(1824, {
                    sub_resource_id: sub_resource_id
                });
            }
        };

        return service;
    }]);
})();