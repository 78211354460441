(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageLink', {
        templateUrl: 'views/components/views/webpageLink/webpageLink.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'webpageLinkService', function ($stateParams, stateService, webpageLinkService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2WebpageKeyno = $stateParams.p2_webpage_keyno;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListWebpageLinks: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS
            
            let loadSetting = function () {
                webpageLinkService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadWebpageLinks = function () {
                webpageLinkService.listWebpageLinks({ p2_webpage_keyno: p2WebpageKeyno }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebpageLinks);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'p2webpagelinkedit':
                        go({
                            p2_webpage_keyno_belongto: p2WebpageKeyno,
                            p2_webpagelink_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadWebpageLinks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
