(function () {
    'use strict';

    angular.module("imApp").factory("subscriptionService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 3214,
                    parameters: parms || {}
                });
            },
            listRepeatingIntervals: function (parms) {
                return $ihttp.post({
                    method: 3215,
                    parameters: parms || {}
                });
            },
            listProduces: function (parms) {
                return $ihttp.post({
                    method: 3216,
                    parameters: parms || {}
                });
            },
            listDocuments: function (parms) {
                return $ihttp.post({
                    method: 309,
                    parameters: parms || {}
                });
            },
            recalcColumn: function (edit) {
                return $ihttp.post({
                    method: 3243,
                    parameters: edit
                });
            },
            updateObj: function (edit) {
                return $ihttp.post({
                    method: 3241,
                    parameters: edit
                });
            },
            produceObj: function (edit) {
                return $ihttp.post({
                    method: 3242,
                    parameters: edit
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 3217,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
