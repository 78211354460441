(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stocksOnLocation', {
        templateUrl: 'views/components/views/stocksOnLocation/stocksOnLocation.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stocksOnLocationService', function ($stateParams, stocksOnLocationService) {
            let vm = this;
            
            vm.model = {
                stockLocationKeyno: $stateParams.stocklocation_keyno,
                setting: {},
                listStocksOnLocation: []
            };
            
            stocksOnLocationService.loadStocksOnLocationGet(vm.model.stockLocationKeyno).then(function (result) {
                angular.copy(result[0], vm.model.setting);
            });
            
    		stocksOnLocationService.loadStocksOnLocationList(vm.model.stockLocationKeyno).then(function (data) {
    			angular.copy(data, vm.model.listStocksOnLocation);
    		});
        }]
    });
})();