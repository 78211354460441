(function () {
    'use strict';

    angular.module('imApp')
        .directive('imImgDirective', ['layoutService', function (layoutService) {
        var directive = {
            restrict: 'E',
            scope: {
                label: '@',         // Used for translation of label                                                    //Optional, used for spacing mostly
                plabel: '=',        // Used instead of label when property is required                                  //Optional, used for spacing mostly
                field: '=',         // Links in the value                                                               //REQUIRED
                title: '@',         // Used for extra info about element and is shown as a tooltip text                 //Optional, default is empty
                ptitle: '=',        // Used instead of title when property is required                                  //Optional
                alt: '@',           // A required text value, an alternate text for img, if img cannot be displayed.    //Optional, has default
                palt: '=',          // Used instead of alt when property is required                                    //Optional
                hmin: '=',          // Used if min-height needs to be set                                               //Optional
                wmin: '=',          // Used if min-width needs to be set                                                //Optional
                hmax: '=',          // Used if max-height needs to be set                                               //Optional, has defaults
                wmax: '=',          // Used if max-width needs to be set                                                //Optional, has defaults
                cursor: '=',        // Used for different cursor styles                                                 //Optional, has defaults, must be defined to be active
                style: '=',         // Single variable to replace h-w-min/max, cursor and other style variables         //NOT IMPLEMENTED, //Consider implementing a single style scope
                optionfunc: '&?',   // Links a function from controller                                                 //NOT IMPLEMENTED
            },
            template:
                    '<div ng-if="field !== undefined && field !== \'\'" ng-class="model.groupClasses">' +
                        '<div ng-class="model.defaultClasses" id="{{model.mId}}">' +
                            '<label ng-if="label !== undefined && label !== \'\'" ng-class="model.nameClasses" ng-style="model.fontStyle" im-translate="{{label}}"></label>' +
                            '<label ng-if="plabel !== undefined && plabel !== \'\'" ng-class="model.nameClasses" ng-style="model.fontStyle">{{plabel}}</label>' +
                            '<label ng-if="(label == undefined || label == \'\') && (plabel == undefined || plabel == \'\')" ng-class="model.nameClasses" ng-style="model.fontStyle"></label>' +
                            '<div ng-class="model.imgDivClasses">' +
                                '<div ng-class="model.imgClasses" ng-click="goImg()" ng-style="model.cursorStyle">' +
                                    '<img ng-src="{{model.field}}" alt="{{model.alt}}" title="{{model.title}}" ng-style="model.imgStyle"/>' + //(pIndex <= -1 && isReadOnly ? ' ng-click="goTo()"' : '')
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>',
            link: function ($scope) {
                $scope.model = {
                    mId: uuid(),
                    title: '',
                    alt: 'image not found',
                    hmin: '0',
                    wmin: '0',
                    hmax: '220px',
                    wmax: '100%',
                    cursor: 'pointer',
                    fontStyle: {},
                    cursorStyle: {},
                    imgStyle: {},
                    defaultClasses: 'col-xs-12 im-no-pad',
                    groupClasses: 'form-group im-no-mar',
                    nameClasses: 'name-label col-xs-12 col-sm-2 im-no-pad',
                    imgDivClasses: 'col-xs-12 col-sm-10 im-no-pad',
                    imgClasses: 'col-xs-12 im-no-pad'
                };

                if (angular.isDefined($scope.grpsize)) {
                    $scope.model.groupClasses += ' ' + $scope.grpsize;
                }

                $scope.goImg = function () {
                    console.log('goImgClicked');
                };

                $scope.itemFunc = function (item) {
                    $scope.optionfunc({ item: item.item_func });
                };

                var onDataChanged = function () {
                    if (angular.isDefined($scope.cursor)) {
                        $scope.model.cursorStyle.cursor = $scope.model.cursor;
                    } else {
                        delete $scope.model.cursorStyle.cursor;
                    }

                    $scope.model.imgStyle.maxHeight = $scope.model.hmax;    //default values are used if not set in scope
                    $scope.model.imgStyle.maxWidth = $scope.model.wmax;    //default values are used if not set in scope

                    if (angular.isDefined($scope.hmin)) {
                        $scope.model.imgStyle.minHeight = $scope.model.hmin;
                    } else {
                        delete $scope.model.imgStyle.minHeight;
                    }

                    if (angular.isDefined($scope.wmin)) {
                        $scope.model.imgStyle.minWidth = $scope.model.wmin;
                    } else {
                        delete $scope.model.imgStyle.minWidth;
                    }
                };

                onDataChanged();

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    $scope.model.fontStyle.fontSize = info.fontSizes.textSizeS;
                });

                // ###############
                // WATCH START
                // ###############

                $scope.$watch(function () {
                    return $scope.field;
                }, function () {
                    $scope.model.field = $scope.field;
                });

                $scope.$watch(function () {
                    return $scope.title;
                }, function () {
                    if (angular.isDefined($scope.title)) {
                        $scope.model.title = $scope.title;
                    }
                });

                $scope.$watch(function () {
                    return $scope.ptitle;
                }, function () {
                    if (angular.isUndefined($scope.title) && angular.isDefined($scope.ptitle)) {
                        $scope.title = $scope.ptitle.toString();
                    }
                });

                $scope.$watch(function () {
                    return $scope.alt;
                }, function () {
                    if (angular.isDefined($scope.alt)) {
                        $scope.model.alt = $scope.alt;
                    }
                });

                $scope.$watch(function () {
                    return $scope.palt;
                }, function () {
                    if (angular.isUndefined($scope.alt) && angular.isDefined($scope.palt)) {
                        $scope.alt = $scope.palt.toString();
                    }
                });

                $scope.$watch(function () {
                    return $scope.hmin;
                }, function () {
                    if (angular.isDefined($scope.hmin)) {
                        $scope.model.hmin = $scope.hmin;
                    }

                    onDataChanged();
                });

                $scope.$watch(function () {
                    return $scope.wmin;
                }, function () {
                    if (angular.isDefined($scope.wmin)) {
                        $scope.model.wmin = $scope.wmin;
                    }

                    onDataChanged();
                });

                $scope.$watch(function () {
                    return $scope.hmax;
                }, function () {
                    if (angular.isDefined($scope.hmax)) {
                        $scope.model.hmax = $scope.hmax;
                    }

                    onDataChanged();
                });

                $scope.$watch(function () {
                    return $scope.wmax;
                }, function () {
                    if (angular.isDefined($scope.wmax)) {
                        $scope.model.wmax = $scope.wmax;
                    }

                    onDataChanged();
                });

                // ###############
                // WATCH END
                // ###############
            }
        };

        return directive;
    }]);
})();