(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('shiftPlan', {
        templateUrl: 'views/components/views/shiftPlan/shiftPlan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'shiftPlanService', function ($stateParams, stateService, shiftPlanService) {
            var vm = this;
            
            vm.model = {
            	dataSource: {}
            };
            
            shiftPlanService.getShiftPlan($stateParams.shiftplan_id).then(function(){
            	vm.model.dataSource = shiftPlanService.dataSource;
            });
            
            vm.saveShiftPlan = function () {
                shiftPlanService.saveShiftPlan(vm.model.dataSource).then(function () {
                    stateService.back();
                });
            };
            
            vm.deleteShiftPlan = function () {
                if (vm.model.dataSource.shiftplan_id != '') {
                    shiftPlanService.deleteShiftPlan(vm.model.dataSource.shiftplan_id).then(function () {
                        stateService.back();
                    });
                }
            };
        }]
    });
})();