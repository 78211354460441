(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttLbl', {
        templateUrl: 'views/components/directives/ttLbl/ttLbl.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttModel: '@',
            ttTranslate: '@',
            ttStyle: '<'
        },
        controller: ['eventService', 'translateService', 'layoutService', 'utilityService', 'ttDirectivesService', function (eventService, translateService, layoutService, us, ttDirectivesService) {
            var vm = this;

            vm.text = '';
            vm.translate = false;
            vm.style = {
                color: 'black'
            };

            var onDestroy = [];

            var translate = function () {
                if (vm.translate !== true)
                    return;
                if (angular.isUndefined(vm.text))
                    return;
                if (us.isStringValue(vm.text, true) !== true)
                    return;

                translateService.translate(vm.text).then(function (translation) {
                    vm.text = translation;
                });
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
            });

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttModel) && us.isStringValue(changes.ttModel.currentValue) && changes.ttModel.currentValue !== changes.ttModel.previousValue) {
                    vm.text = changes.ttModel.currentValue;

                    translate();
                }

                if (angular.isDefined(changes.ttTranslate)) {
                    vm.translate = us.toBoolean(changes.ttTranslate.currentValue);

                    translate();
                }

                if (angular.isDefined(changes.ttStyle) && angular.isObject(changes.ttStyle.currentValue)) {
                    angular.forEach(changes.ttStyle.currentValue, function (value, key) {
                        vm.style[key] = value;
                    });
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
