(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderHeadCapJob', {
        templateUrl: 'views/components/views/orderHeadCapJob/orderHeadCapJob.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'utilityService', 'modalService', 'orderHeadCapJobService', function ($stateParams, $timeout, stateService, utilityService, modalService, orderHeadCapJobService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                settingCapacityJob: {},
                gridReady: false,
                lockedCreate: false,
                lockedForward: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            orderHeadCapJobService.getOrderHeadCapJob(vm.model.orderInternalNo).then(function (data) {
                angular.copy(data[0], vm.model.settingCapacityJob);

		        if (utilityService.validateParmsValue(vm.model.settingCapacityJob.view_id) === true) {
		        	vm.grid.dataTask.rememberId = vm.model.settingCapacityJob.view_id;
		        }

		        if (utilityService.validateParmsValue(vm.model.settingCapacityJob.p2_datatask_keyno) === true) {
		        	vm.grid.dataTask.loadData = {
		        		method: vm.model.settingCapacityJob.p2_datatask_keyno,
		        		parameters: vm.model.settingCapacityJob
		        	};
		        }

		        vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'captasksview':
                        go({
                            argtype: 'order_internal_no',
                            argvalue: vm.model.settingCapacityJob.order_internal_no
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region PROGRESSBAR FUNCTION

            vm.activateProgressAdd = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';
                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].orderline_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;
                        
                        orderHeadCapJobService.addProduction(items[step].orderline_keyno, items[step].capjob_keyno, vm.model.settingCapacityJob.lastdt_production_finish, 'B').then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;
                                            doStep();
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            var progressFuncAdd = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;
                //var currentData = vm.grid.gridfunc.getSelectedRows();
                var currentData = vm.grid.gridfunc.getAllRows();

                vm.model.progressInfo.max = currentData.length;
                vm.activateProgressAdd(currentData);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        console.log('Aborted');
                    } else if (vm.model.runProgress === 'FINISH') {
                        console.log('Finished');

                        vm.grid.dataTask.loadData = {
                            method: vm.model.settingCapacityJob.p2_datatask_keyno,
                            parameters: vm.model.settingCapacityJob
                        };

                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            vm.activateProgressForward = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';
                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].orderline_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;
                        
                        orderHeadCapJobService.addProduction(items[step].orderline_keyno, items[step].capjob_keyno, vm.model.settingCapacityJob.lastdt_production_finish, 'F').then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;
                                            doStep();
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            var progressFuncForward = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;
                var currentData = vm.grid.gridfunc.getAllRows();

                vm.model.progressInfo.max = currentData.length;
                vm.activateProgressForward(currentData);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        console.log('Aborted');
                    } else if (vm.model.runProgress === 'FINISH') {
                        console.log('Finished');

                        vm.grid.dataTask.loadData = {
                            method: vm.model.settingCapacityJob.p2_datatask_keyno,
                            parameters: vm.model.settingCapacityJob
                        };

                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            // #endregion PROGRESSBAR FUNCTION

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'add':
                        if (utilityService.validateParmsValue(vm.model.settingCapacityJob.order_internal_no) !== true) return;

                        orderHeadCapJobService.deletePrevious({ order_internal_no: vm.model.settingCapacityJob.order_internal_no }).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                progressFuncAdd();
                            }
                        });
                        break;
                    case 'forward':
                        if (utilityService.validateParmsValue(vm.model.settingCapacityJob.order_internal_no) !== true) return;

                        orderHeadCapJobService.deletePrevious({ order_internal_no: vm.model.settingCapacityJob.order_internal_no }).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                progressFuncForward();
                            }
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS
        }]
    });
})();
