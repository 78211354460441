(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('gridBookingModal', {
        templateUrl: 'views/components/views/gridBookingModal/gridBookingModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['layoutService', 'translateService', '$ihttp', '$uibModal', 'modalService', 'accountingProcessingService', 'logisticService', 'rememberService', 'gridBookingModalService', 'ttGridFactory', function (layoutService, translateService, $ihttp, $uibModal, modalService, accountingProcessingService, logisticService, rememberService, gridBookingModalService, ttGridFactory) {
            const vm = this;

            vm.id = {
                fileinput: crypto.randomUUID(),
            };

            vm.grid = null;

            vm.model = {
                base64: '',
                ready: false,
                /** The grid row the modal was opened from. */
                dataItem: {},
                /** The new voucher for to create for write off.*/
                voucher: {},
                /** @type {File[}} */
                files: [],
                rows: [],
                departments: [],
                columnVisibility: {
                    transdate: true,
                    voucherdate: true,
                    accounttype_db: true,
                    account_name_db: true,
                    acvatcode_db: true,
                    accounttype_cr: true,
                    account_name_cr: true,
                    acvatcode_cr: true,
                    valuta_id: true,
                    amount_invval: true,
                    exchange_rate: true,
                    amount_locval: true,
                    exchange_factor: false,
                },
                transdateOptions: {
                    date: { show: true, disabled: false, align: 'left' },
                    hour: { show: false, disabled: false, align: 'right' },
                    minute: {
                        show: false,
                        disabled: false,
                        align: 'right',
                        interval: 1,
                    },
                },
                amountOptions: {
                    decimals: 2,
                },
                valutaOptions: {
                    decimals: 6,
                },
            };

            vm.overrideDatesToRows = function () {
                vm.model.rows = vm.model.rows.map((row) => ({ ...row, voucherdate: vm.model.voucher.voucherdate, transdate: vm.model.voucher.transdate }));
                vm.grid.gridfunc.setDataSource(vm.model.rows);
                // set the rows list equal to the one in grid component after mapping of ids.
                setTimeout(() => vm.model.rows = vm.grid.gridfunc.getAllRows());
            }

            vm.onVoucherDateChanged = function (value) {
                vm.model.voucher.voucherdate = value;
                vm.model.voucher.transdate = value;
            }

            vm.addRow = async function () {
                const data = await gridBookingModalService.addRow({ last_row: vm.model.rows[vm.model.rows.length - 1], dataitem: vm.model.dataItem });

                vm.model.rows.push({ ...data });
                vm.grid.gridfunc.setDataSource(vm.model.rows);
                // set the rows list equal to the one in grid component after mapping of ids.
                setTimeout(() => vm.model.rows = vm.grid.gridfunc.getAllRows());
            };

            vm.removeRow = function (row) {
                const deleteIndex = vm.model.rows.findIndex((rowToDelete) => rowToDelete._uuid === row._uuid);

                if (deleteIndex !== -1) {
                    vm.model.rows.splice(vm.model.rows.indexOf(row), 1);
                    vm.grid.gridfunc.setDataSource(vm.model.rows);
                    // set the rows list equal to the one in grid component after mapping of ids.
                    setTimeout(() => vm.model.rows = vm.grid.gridfunc.getAllRows());
                    refreshAmountAndVatAmount();
                }
            };

            vm.onRowSearchAccounts = async function (field, row) {
                if (!!row[field] && row?.businessco_no) {
                    return accountingProcessingService.searchAccounts(row[field], field, row);
                }
            };

            vm.onRowSearchVatCode = async function (field, row) {
                if (!!row[field] && row?.businessco_no) {
                    return accountingProcessingService.searchVatCode(row[field], field, row);
                }
            };

            vm.onRowSearchValutaId = async function (row) {
                if (!!row?.valuta_id) {
                    return accountingProcessingService.searchValutaId(row.valuta_id, 'valuta_id', row);
                }
            };

            vm.onRowAccountDbSelected = function (item, row) {
                row.accounttype_db = item?.accounttype ?? '';
                row.acvatcode_db = item?.acvatcode ?? '';
                row.account_name_db = item?.item_name ?? '';
                row.account_no_db = item?.item_id ?? '0';
                gridbookingColChanged('account_no_db', row);
            };

            vm.onRowAccountCrSelected = function (item, row) {
                row.accounttype_cr = item?.accounttype ?? '';
                row.acvatcode_cr = item?.acvatcode ?? '';
                row.account_name_cr = item?.item_name ?? '';
                row.account_no_cr = item?.item_id ?? '0';
                gridbookingColChanged('account_no_cr', row);
            };

            vm.onRowValutaIdSelected = function (item, row) {
                row.valuta_id = item?.item_id ?? '';
                gridbookingColChanged('valuta_id', row);
            };

            vm.onRowVatCodeDbSelected = function (item, row) {
                row.acvatcode_db = item?.acvatcode_keyno ?? '';
                gridbookingColChanged('acvatcode_db', row);
            };

            vm.onRowVatCodeCrSelected = function (item, row) {
                row.acvatcode_cr = item?.acvatcode_keyno ?? '';
                gridbookingColChanged('acvatcode_cr', row);
            };

            vm.translations = {};

            vm.onFileChange = async function (event) {
                console.log('on file change');
                vm.model.base64 = URL.createObjectURL(vm.model.files[0]);
                console.log(vm.model.base64);
            };

            vm.searchProject = function () {
                const parms = {
                    project_name: vm.model.voucher.project_name,
                    businessco_no: vm.model.dataItem.businessco_no,
                };

                return accountingProcessingService.lookUpSearchProjects(parms);
            };


            vm.onProjectSelected = function (item) {
                vm.model.voucher.project_name = item?.item_name ?? '';
                vm.model.voucher.project_keyno = item?.item_id ?? '';
            };

            vm.searchAccounts = function () {
                if (vm.model.voucher?.account_no && vm.model.dataItem) {
                    return accountingProcessingService.searchAccounts(vm.model.voucher.account_no, 'account_name_db', vm.model.dataItem);
                }
            };

            vm.onAccountSelected = function (item) {
                vm.model.voucher.account_no = item?.item_id ?? '';
                vm.model.voucher.account_name = item?.item_name ?? '';
                vm.model.voucher.accounttype = item?.accounttype ?? '';
                vm.model.voucher.acvatcode = item?.acvatcode ?? '';
            };

            vm.openGridBookingLayoutModal = function () {
                const modalRef = $uibModal.open({
                    component: 'gridBookingColumnsModal',
                    resolve: {
                        rememberId: function () {
                            return vm.model.gridRememberId;
                        },
                        label_transdate: function () {
                            return vm.model.get?.label_transdate;
                        },
                        label_voucherdate: function () {
                            return vm.model.get?.label_voucherdate;
                        },
                        label_acvatcode: function () {
                            return vm.model.get?.label_acvatcode;
                        },
                        show_transdate: function () {
                            return vm.model.get?.show_transdate;
                        },
                        show_voucherdate: function () {
                            return vm.model.get?.show_voucherdate;
                        },
                    },
                    size: 'lg',
                });

                modalRef.result.finally(refreshColumns);
            };

            async function getDepartments() {
                vm.model.departments = [];


                if (vm.model.dataItem?.businessco_no > '0') {
                    vm.model.departments = await logisticService.listDepartments(vm.model.dataItem.businessco_no);
                }
            };

            function toBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });
            };

            async function refreshColumns() {
                if (vm.model.gridRememberId) {
                    const response = await rememberService.getLastStatus(vm.model.gridRememberId + '.grid_booking_column_layout', true);

                    if (response) {
                        response.forEach((column) => {
                            vm.model.columnVisibility[column.item_id] = column.show;
                        });
                    }
                }
            };

            async function getPageData() {
                vm.model.get = (await gridBookingModalService.pageData({ grid_datatask_keyno: vm.model.loadData.method, grid_datatask_params: vm.model.loadData.parameters, dataitem: vm.model.dataItem, dataitems: vm.model.dataItem !== null ? [vm.model.dataItem] : vm.model.dataitems }))[0];
                vm.model.voucher.note = vm.model.get.note;
                vm.model.rows = angular.fromJson(vm.model.get.suggested_rows);

                refreshAmountAndVatAmount();
            };

            let refreshAmountsTimer = null;

            async function refreshAmountAndVatAmount() {
                if (refreshAmountAndVatAmount !== null) {
                    clearTimeout(refreshAmountsTimer);
                    refreshAmountsTimer = null;
                }

                setTimeout(async () => {
                    const data = (await gridBookingModalService.getAmountAndVatAmount({ rows: vm.model.rows }))[0];

                    vm.model.voucher.amount = new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.amount));
                    vm.model.voucher.amount_vat = new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(Number(data.amount_vat));
                }, 500);
            };

            vm.onRowChanged = function (event) {
                if (event.newValue !== event.oldValue) {
                    gridbookingColChanged(event.colId, event.row);
                }
            }

            vm.process = async function (buttonName) {
                if (changePromise !== null && changePromise instanceof Promise) {
                    await changePromise;
                }

                const filesBase64 = await Promise.all(vm.model.files.map(async (file) => ({ filename: file.name, base64: await toBase64(file) })));
                const data = await $ihttp.post({ method: vm.model.get.book_datatask_keyno, parameters: { button: buttonName, voucher: vm.model.voucher, rows: vm.model.rows, files: filesBase64 } });

                if (data[0].errorcode !== '0') {
                    modalService.show({
                        type: 'warning',
                        title: 'Advarsel',
                        message: data[0].errormessage,
                        buttons: [{
                            label: 'OK',
                            cssClass: 'btn-warning',
                            action: function (dialogItself) {
                                dialogItself.close();
                            }
                        }]
                    });
                } else {
                    vm.modalInstance.close()
                }
            };

            vm.defaultRowChanged = function (colname, value, row) {
                if (value === row[colname]) return;

                row[colname] = value;

                if (value) {
                    gridbookingColChanged(colname, row);
                };
            };

            let changePromise = null;

            async function gridbookingColChanged(colnameChanged, row) {
                if (!vm.model.ready) return;

                changePromise = new Promise(async (resolve) => {
                    const data = (await gridBookingModalService.gridbookingColChanged({ ...row, colname_changed: colnameChanged }))[0];
                    let rowToUpdate = vm.model.rows.find((r) => r._uuid === row._uuid);

                    if (!!rowToUpdate) {
                        vm.model.rows[vm.model.rows.indexOf(rowToUpdate)] = { ...row, ...data };

                        vm.grid.gridfunc.updateRow({ ...row, ...data });

                        refreshAmountAndVatAmount();
                    }

                    resolve();
                })
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.gridready = function () {
                vm.grid.gridfunc.getGridApi().setGridOption('sideBar', {
                    toolPanels: [
                        {
                            id: 'columns',
                            labelDefault: 'Columns',
                            labelKey: 'columns',
                            iconKey: 'columns',
                            toolPanel: 'agColumnsToolPanel',
                            toolPanelParams: {
                                supressPivots: true,
                                suppressPivotMode: true,
                                suppressRowGroups: true,
                                suppressValues: true,
                            },
                        },
                    ],
                    //defaultToolPanel: 'columns',
                    position: 'left'
                });

                vm.grid.gridfunc.setDataSource(vm.model.rows);
                // set the rows list equal to the one in grid component after mapping of ids.
                setTimeout(() => vm.model.rows = vm.grid.gridfunc.getAllRows());
            };

            vm.$onInit = async function () {
                console.log(vm.resolve);

                if (vm.resolve?.isSelectedRows) {
                    vm.model.dataitems = vm.resolve.isSelectedRows;
                }

                if (vm.resolve?.dataItem !== undefined) {
                    vm.model.dataItem = vm.resolve.dataItem;
                    vm.model.voucher.amount_locval_balance = vm.model.dataItem?.amount_locval_balance ?? '0';
                    vm.model.voucher.transdate = vm.model.dataItem?.transdate ?? '0';
                }

                if (vm.resolve?.loadData) {
                    vm.model.loadData = vm.resolve.loadData;
                }

                if (vm.resolve?.rememberId) {
                    vm.model.gridRememberId = vm.resolve.rememberId;
                    refreshColumns();
                    vm.grid = new ttGridFactory({ rememberId: vm.model.gridRememberId + '.gridbooking', loadData: { method: 3339, parameters: null } })
                        .setAddRowFunction({ method: () => { } })
                        .setRemoveRowFunction({ method: () => { } })
                        .setSaveDataFunction({ method: () => { } })
                        .setToolbar({ hidden: false, excelExport: false, pdfExport: false, filter: false, headers: false, layouts: false, read: false })
                        .setCustomToolbarButtons([
                            {
                                name: 'grid_booking_new_row',
                                text: 'new_row',
                                func: () => vm.addRow(),
                                icon: 'far fa-plus',
                                cssClass: 'tt-button tt-button--success im-grid-btn-xs',
                                translate: true,
                                disabled: () => false,
                            },
                        ])
                        .setSpecialFunc({
                            buttons: [{
                                name: 'grid_booking_delete_row',
                                text: '',
                                func: (event) => vm.removeRow(event),
                                icon: 'far fa-trash',
                                btn_type: 'danger',
                                cssClass: 'tt-button tt-button--danger im-grid-btn-xs',
                                translate: true,
                                disabled: false,
                            }]
                        })
                        .setKendoConfig({ filterable: false });
                }

                await getPageData();


                refreshAmountAndVatAmount();

                // let stuff process before becoming ready.
                setTimeout(() => vm.model.ready = true);

                getDepartments();
            };
        }]
    });
})();
