(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCheckListLdService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCheckListLdGet: function (checklistdata_keyno, srrefundinput_keyno) {
                return p2DataTaskService.call(1811, {
                    checklistdata_keyno: checklistdata_keyno,
                    srrefundinput_keyno: srrefundinput_keyno
                });
            },
            save: function (lossDamage) {
                return p2DataTaskService.call(1812, lossDamage);
            },
            deleteObj: function (lossDamage) {
                return p2DataTaskService.call(1813, lossDamage);
            }
        };

        return service;
    }]);
})();