(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockControlList', {
        templateUrl: 'views/components/views/stockControlList/stockControlList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', '$element', '$window', 'utilityService', 'stateService', 'rememberService', 'logisticService', 'stockControlListService', function ($stateParams, $q, $timeout, $element, $window, utilityService, stateService, rememberService, logisticService, stockControlListService) {

            // ## VARIABLES & DEFINITIONS ##

            const vm = this;
            let onDestroy = [];
            let stockPlaceId = $stateParams.stockplace_id;

            let variableNames = {
                stockzone_keyno: '',
                count_interval: '',
                restrict_stock_qty: '',
                stock_qty_fom: '',
                stock_qty_tom: '',
                searchtext: ''
            };

            vm.model = {
                mId: 'stockControlListCtrl',
                bgStockControlListMain: {
                    selected: 'LIST',
                    buttons: [
                        { id: 'LIST', label: 'bg_stockcontrollist_main_list', color: 'primary', onClick: () => vm.model.bgStockControlListMain.selected = 'LIST' },
                        { id: 'SETTINGS', label: 'bg_stockcontrollist_main_settings', color: 'primary', onClick: () => vm.model.bgStockControlListMain.selected = 'SETTINGS' }
                    ]
                },
                stockZoneButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('stockzone_keyno') }
                ],
                barcodeBtnList: [
                    { item_id: '0', item_name: 'scan', item_func: 'scan_item', glyph: 'glyphicon-barcode', color: 'primary' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchControl() }
                ],
                setting: {},
                selectListStockZones: [],
                itemsListControlLists: [],
                gridView: false,
                gridReady: false,
                scanningControls: false
            };
            
            vm.grid = {
                dataTask: {
                    rememberId: 'w_stockcontrollist_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();

                stockControlListService.getSetting({ stockplace_id: stockPlaceId }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockZones()
                    ]).then(() => {
                        loadControls();
                        loadGrid();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
    
            var loadGrid = function () {
                if (vm.model.gridView === false) return;
    
                vm.grid.dataTask.loadData = {
                    method: 2655,
                    parameters: vm.model.setting
                };
                
                vm.model.gridReady = true;
            };

            let loadStockZones = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                return logisticService.listStockZones({ stockplace_id: vm.model.setting.stockplace_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockZones);
                });
            };
            
            let loadControls = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.count_interval) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.restrict_stock_qty) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stock_qty_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stock_qty_tom) !== true) return;
                
                vm.model.itemsListControlLists = [];
                
                return stockControlListService.listControls(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListControlLists);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'stockzone_keyno':
                        case 'searchtext':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + vm.model.setting.stockplace_id + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'stockzone_keyno':
                    case 'count_interval':
                    case 'restrict_stock_qty':
                    case 'stock_qty_fom':
                    case 'stock_qty_tom':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'stockzone_keyno':
                        vm.model.setting.stockzone_keyno = '0';

                        rememberFunc('stockzone_keyno');

                        if (vm.model.gridView && vm.model.gridReady) {
                            if (vm.grid?.gridfunc) vm.grid.gridfunc.rebind();
                        }

                        loadControls();
                        break;
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadControls();
                            loadGrid();

                            if (vm.grid?.gridfunc) vm.grid.gridfunc.rebind();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ## SCAN FUNCTIONS ##
    
            var loadScan = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.barcode, true) !== true) return;
    
                stockControlListService.scan(vm.model.setting.stockplace_id, vm.model.setting.barcode).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.scanningControls = false;
                        });
                    } else {
                        vm.model.scanningControls = false;
                        stateService.go(data[0].item_state, data[0].item_parms);
                    }
                });
            };
    
            vm.scanBarcodes = function () {
                vm.model.scanningControls = true;
                loadScan();
            };
    
            //## MOCHASOFT SCAN FUNCTION ##
    
            var launchBarcode = function () {
                var wait = function () {
                    var val = $element.find('#' + vm.model.mId)[0].value;
    
                    if (angular.isString(val) && val !== null && val.length > 0) {
                        vm.model.setting.barcode = val;
    
                        loadScan();
    
                        $element.find('#' + vm.model.mId)[0].value = '';
                        return;
                    }
    
                    $timeout(wait, 250);
                };
    
                wait();
    
                window.location = "readbarcode://" + vm.model.mId;
            };
    
            // #region LAYOUT FUNCTIONS
    
            var onWindowResize = function () {
                if (window.innerWidth > 926 && vm.model.setting.floating_location === '1') {
                    if (vm.model.gridView === false) {
                        //console.log("swapping to desktop view");
                        vm.model.gridView = true;
    
                        if (vm.model.gridReady === false) {
                            loadGrid();
                        }
                    }
                } else {
                    if (vm.model.gridView === true) {
                        //console.log("swapping to mobile view");
                        vm.model.gridView = false;
                    }
                }
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockControlListMain = function (value) {
                vm.model.bgStockControlListMain.selected = value;

                if (vm.model.bgStockControlListMain.selected === 'LIST') loadControls();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## BUTTON LIST FUNCTIONS ##
            
            vm.barcodeBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'scan_item':
                        launchBarcode();
                        break;
                    case 'search_item':
                        vm.scanBarcodes();
                        break;
                    default:
                        break;
                }
            };
            
            // ## SEARCH FUNCTION ##
            
            vm.searchControl = function () {
                rememberFunc('searchtext');
                loadControls();
                loadGrid();

                if (vm.grid?.gridfunc) vm.grid.gridfunc.rebind();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockzone_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockzone_keyno) !== true) return;

                        vm.model.setting.stockzone_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockzone_keyno) !== true) return;

                        rememberFunc('stockzone_keyno');

                        if (vm.model.gridView && vm.model.gridReady) {
                            if (vm.grid?.gridfunc) vm.grid.gridfunc.rebind();
                        }

                        loadControls();
                        break;
                    case 'restrict_stock_qty':
                        if (utilityService.validateWatchValue(value, vm.model.setting.restrict_stock_qty) !== true) return;

                        vm.model.setting.restrict_stock_qty = value;

                        if (utilityService.validateParmsValue(vm.model.setting.restrict_stock_qty) !== true) return;

                        rememberFunc('restrict_stock_qty');
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') {
                            rememberFunc('searchtext');
                            loadControls();
                            loadGrid();

                            if (vm.grid?.gridfunc) vm.grid.gridfunc.rebind();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'count_interval':
                    case 'stock_qty_fom':
                    case 'stock_qty_tom':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            vm.$onInit = async function () {
                await loadSetting();
                onWindowResize();
                $window.addEventListener('resize', onWindowResize);
                onWindowResize();
            };

            vm.$onDestroy = function () {
                $window.removeEventListener('resize', onWindowResize);

                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
