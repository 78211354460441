(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('activityType', {
        templateUrl: 'views/components/views/activityType/activityType.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', 'modalService', 'translateService', 'custactEditService', 'activityTypeService', 'stateService', function ($stateParams, modalService, translateService, custactEditService, activityTypeService, stateService) {
            const vm = this;
            let activityTypeNo = $stateParams.activitytype_no;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                activityTypeNo: activityTypeNo,
                activityTypeNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('activitytypelangs', { activitytype_no: vm.model.edit.activitytype_no }) }
                ],
                edit: {},
                lockedSave: false,
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                custactEditService.getActivityType({ activitytype_no: activityTypeNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'activitytypedevtypes':
                    case 'activitytypeactstatuses':
                        go({
                            argtype: 'activitytype_no',
                            argvalue: vm.model.edit.activitytype_no
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                activityTypeService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    activityTypeService.deleteItem({ activitytype_no: vm.model.edit.activitytype_no }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

		}]
	});
})();
