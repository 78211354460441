/**
 * The HTML5 autofocus property can be finicky when it comes to dynamically loaded templates and such with AngularJS. 
 * This replaces the old one with one for AngularJS.
 *
 * Usage:
 * <input type="text" autofocus>
 * 
 */
(function () {
    'use strict';
 
    angular.module('imApp')
        .directive("afterRender", ["$timeout", function ($timeout) {
                var def = {
                    restrict: "A",
                    transclude: false,
                    link: function (scope, element, attrs) {
                        $timeout(scope.$eval(attrs.afterRender), 0); //Calling a scoped method
                    },
                };
                return def;
            },
        ]);
})();