(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('doDataImp', {
        templateUrl: 'views/components/views/doDataImp/doDataImp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$uibModal', 'stateService', 'utilityService', 'modalService', 'translateService', 'doDataImpService', 'economyService', 'translateListService', 'prodGrpsService', 'rememberService', function ($stateParams, $q, $uibModal, stateService, utilityService, modalService, translateService, doDataImpService, economyService, translateListService, prodGrpsService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let dataImpKeyno = $stateParams.dataimp_keyno;

            let variableNames = {
                pricelist_id: '',
                language_id: '',
                prodgrp_id: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                urlExcelTemplateButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-window-restore', color: 'primary', type: 'primary', onClick: () => window.open(vm.model.edit.url_excel_template) }
                ],
                priceButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('pricelist_id') }
                ],
                productGroupButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('prodgrp_id') }
                ],
                edit: {},
                selectListPrices: [],
                selectListLanguages: [],
                selectListProductGroups: [],
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'dodataimp_from_excel',
                                text: 'dodataimp_from_excel',
                                func: openImportModal,
                                icon: 'far fa-file-import',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //    if (vm.model.edit.proc_name_import > '') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            {
                                name: 'dodataimp_retrieve_from_db',
                                text: 'dodataimp_retrieve_from_db',
                                func: function () {
                                    retrieveChanges();
                                },
                                icon: 'fa-sync-alt',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                disabled: function () {
                                    if (vm.model.edit.proc_name_fill > '') {
                                		return false;
                                	} else {
                                		return 'hidden';
                                	}
                                }
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            function openImportModal() {
                const modalRef = $uibModal.open({
                    component: 'doDataImpImportModal',
                    resolve: {
                        tableNameDestination: function () {
                            return vm.model.edit.table_name_destination;
                        }
                    },
                    size: 'pst-ninety',
                });

                modalRef.closed.then(function () {
                    loadGrid();
                });
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
            	let deferred = $q.defer();

            	doDataImpService.getEdit({ dataimp_keyno: dataImpKeyno }).then(function (result) {
            		angular.copy(result[0], vm.model.edit);

            		$q.all([
            			loadGrid()
            		]).then(() => deferred.resolve());
            	});

            	return deferred.promise;
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (result) {
                    angular.copy(result, vm.model.selectListPrices);
                });
            };

            let loadLanguages = function () {
                translateListService.listLanguages().then(function (result) {
                    angular.copy(result, vm.model.selectListLanguages);
                });
            };

            let loadProductGroups = function () {
                prodGrpsService.listProductGroups().then(function (result) {
                    angular.copy(result, vm.model.selectListProductGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.rememberId = vm.model.edit.view_id_grid;

                vm.grid.dataTask.loadData = {
                    method: vm.model.edit.p2_datatask_keyno_grid,
                    parameters: vm.model.edit
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };
            
            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                    	case 'pricelist_id':
                        case 'language_id':
                        case 'prodgrp_id':
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + vm.model.edit.dataimp_keyno + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'pricelist_id':
                    case 'language_id':
                    case 'prodgrp_id':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'pricelist_id':
                    case 'prodgrp_id':
                        vm.model.edit[id] = '';

                        rememberFunc(id);
                        loadGrid();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            let retrieveChanges = function () {
                doDataImpService.retrieveFill(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        loadGrid();
                    }
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'pricelist_id':
                    case 'language_id':
                    case 'prodgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit[id]) !== true) return;

                        vm.model.edit[id] = value;

                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        rememberFunc(id);
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
                loadPrices();
                loadLanguages();
                loadProductGroups();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
