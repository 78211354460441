(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('forecasts', {
        templateUrl: 'views/components/views/forecasts/forecasts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'forecastsService', function (stateService, forecastsService) {
            var vm = this;
            
            vm.model = {
                itemsListForecasts: []
            };
            
            forecastsService.listForecasts().then(function (result) {
                angular.copy(result, vm.model.itemsListForecasts);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'forecast':
                        stateService.go(state, {
                            forecast_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();