(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('custactComplaintLine', {
        templateUrl: 'views/components/views/custactComplaintLine/custactComplaintLine.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'custactComplaintLineService', 'translateService', function ($stateParams, stateService, modalService, custactComplaintLineService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var custactProductKeyno = $stateParams.custactproduct_keyno;

            var translations = {
                custactcomplaintline_save_show_title: 'Varsel',
                custactcomplaintline_save_show_ok_label: 'OK'
            };

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'orderhead1':
                        stateService.go('orderhead', { order_internal_no: vm.model.edit.order_internal_no_origin });
                        break;
                    case 'orderhead2':
                        stateService.go('orderhead', { order_internal_no: vm.model.edit.order_internal_no_new });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                orderOrderNoOriginButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderhead1' } }
                ],
                orderOrderNoNewButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderhead2' } }
                ],
                edit: {},
                selectListSearchCauses: [],
                lockedSave: false
            };

            vm.options = {
                quantity: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            custactComplaintLineService.getCustactComplaintLine({ custactproduct_keyno: custactProductKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.edit);

                loadSearchCauses();
            });

            var loadSearchCauses = function () {
                custactComplaintLineService.listSearchCauses(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.selectListSearchCauses);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                custactComplaintLineService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.custactcomplaintline_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.custactcomplaintline_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

        }]
    });
})();