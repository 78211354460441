(function () {
    const module = angular.module('imApp');

    /**
     * @author JLR
     * @version 20231215
     */

    /**
     * @typedef {Object} CommentProps represents the properties of the ttComment component.
     * @property {string} ttAuthor the displayed name of the author of the comment.
     * @property {string?} ttDatetime the date and time of the comment was created.
     * @property {string} ttModel the content of the comment.
     * @property {'1' | '0'} ttIsCurrentUser whether the author of the comment is the current user.
     */

    module.component('ttComment', {
        // All actions to display next to the comment is places in transclude.
        transclude: true,
        templateUrl: 'views/components/directives/ttComment/ttComment.template.html?v=' + module.version,
        controllerAs: 'vm',
        /** @type CommentProps */
        bindings: {
            ttAuthor: '@',
            ttDatetime: '@?',
            ttModel: '<',
            ttIsCurrentUser: '<',
            ttMenuOptions: '<',
        },
        controller: [function () {
            const vm = this;

            vm.id = {
                content: crypto.randomUUID(),
            }

            let setInnerHtml = function (htmlString) {
                setTimeout(() => {
                    let element = document?.getElementById(vm.id.content);

                    if (element) {
                        element.innerHTML = htmlString;
                    }
                })
            }

            vm.$onChanges = function (changes) {
                if (changes?.ttModel?.currentValue && typeof changes.ttModel.currentValue === 'string') {
                    setInnerHtml(changes?.ttModel.currentValue);
                }
            }

        }]
    });
})();
