export class Result<T> {
    public isSuccess: boolean;
    public isFailure: boolean
    public error?: string | null | unknown;
    private _value?: T | null;

    private constructor(isSuccess: boolean, error?: string | null | unknown, value?: T | null) {
        if (isSuccess && error) {
            throw new Error(`InvalidOperation: A result cannot be 
        successful and contain an error`);
        }
        if (!isSuccess && !error) {
            throw new Error(`InvalidOperation: A failing result 
        needs to contain an error message`);
        }

        this.isSuccess = isSuccess;
        this.isFailure = !isSuccess;
        this.error = error;
        this._value = value;

        Object.freeze(this);
    }

    public getValue(): T {
        if (!this.isSuccess) {
            throw new Error(`Cant retrieve the value from a failed result.`)
        }

        return this._value as T;
    }

    public static ok<U>(value?: U): Result<U> {
        return new Result<U>(true, null, value);
    }

    public static fail<U>(error: string | unknown): Result<U> {
        return new Result<U>(false, error);
    }

    public static combine(results: Result<any>[]): Result<any> {
        for (let result of results) {
            if (result.isFailure) return result;
        }

        return Result.ok<any>();
    }
}
