(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productLinksChange', {
        templateUrl: 'views/components/views/productLinksChange/productLinksChange.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'productLinksChangeService', function ($stateParams, stateService, utilityService, modalService, translateService, productLinksChangeService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                productLinkTypeKeyNo: $stateParams.productlinktype_keyno,
                bgProductLinksChangeFunction: {
                    selected: 'ADD',
                    buttons: [
                        { id: 'ADD', label: 'bg_productlinkschange_function_add', color: 'primary', onClick: () => setProductLinksChangeFunctionView('ADD') },
                        { id: 'REMOVE', label: 'bg_productlinkschange_function_remove', color: 'primary', onClick: () => setProductLinksChangeFunctionView('REMOVE') }
                    ]
                },
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchChanges() }
                ],
                setting: {},
                itemsListChanges: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION

            let setProductLinksChangeFunctionView = function (id) {
                vm.model.bgProductLinksChangeFunction.selected = vm.model.setting.bg_productlinkschange_function = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## LOAD PROCEDURE CALLS ##
            
            productLinksChangeService.loadProductLinksChangeGet(vm.model.argType, vm.model.argValue, vm.model.productLinkTypeKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.setting);
                setProductLinksChangeFunctionView(vm.model.setting.bg_productlinkschange_function);

                loadProductLinksChanges();
            });
    
            var loadProductLinksChanges = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bg_productlinkschange_function) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
    
                vm.model.itemsListChanges = [];
    
                productLinksChangeService.loadProductLinksChangeList(vm.model.setting).then(function (data) {
                    angular.copy(data, vm.model.itemsListChanges);
                });
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') loadProductLinksChanges();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgProductLinksChangeFunction = function (value) {
                vm.model.bgProductLinksChangeFunction.selected = value;
                vm.model.setting.bg_productlinkschange_function = vm.model.bgProductLinksChangeFunction.selected;

                loadProductLinksChanges();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            // ## SEARCH FUNCTION ##
            
            vm.searchChanges = function () {
                loadProductLinksChanges();
            };
            
            // ## TOGGLE FUNCTIONS ##
    
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
    
                if (item.item_id === '0' || item.item_id === '-1') return;
    
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var selectedItems = [];
                
                angular.forEach(vm.model.itemsListChanges, function (item) {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0') return;
    
                    selectedItems.push({ item_id: item.item_id });
                });
                
                productLinksChangeService.saveObj({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue,
                    productlinktype_keyno: vm.model.setting.productlinktype_keyno,
                    bg_productlinkschange_function: vm.model.setting.bg_productlinkschange_function,
                    records: selectedItems
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') loadProductLinksChanges();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext) !== true) return;

                        if (vm.model.setting.searchtext > '') vm.searchChanges();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

        }]
    });
})();
