(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('printTestClientPrinters', {
        templateUrl: 'views/components/views/printTestClientPrinters/printTestClientPrinters.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'printService', function($stateParams, printService) {
    
            var vm = this;
    
    
            vm.model = {
                printClientKeyno: $stateParams.printClientKeyno,
                printClientName: $stateParams.printClientName,
                printers: [],
                page: 1,
                pageSize: 10,
                total: 0,
                pageSizes: [5, 10, 15, 25, 50]
            };
    
            var isLoading = false;
    
            var load = function () {
                if (isLoading === true) return;
    
                isLoading = true;
    
                printService.getClientPrinters({
                    printClientKeyno: vm.model.printClientKeyno,
                    page: vm.model.page,
                    pageSize: vm.model.pageSize
                }).then(function (data) {
                    vm.model.total = data.total;
    
                    for (var i = 0; i < data.items.length; i++) {
                        data.items[i].regDateTime = moment(data.items[i].regDateTime).format('YYYY-MM-DD HH:mm');
                    }
    
                    angular.copy(data.items, vm.model.printers);
    
                    isLoading = false;
                });
            };
    
            load();
    
            vm.refresh = function () {
                load();
            };
    
            vm.pageChanged = function () {
                load();
            };
    
            vm.setPageSize = function (size) {
                vm.model.pageSize = size;
    
                load();
            };
        }]
    });
})();
