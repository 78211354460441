(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("dispatchPlanningConsignmentService", ['$ihttp', function ($ihttp) {
        var service = {
            validateDispatch: function (dispatch) {
                return $ihttp.post({
                    method: 1577,
                    parameters: {
                        dispatch_no: dispatch
                    }
                });
            },
            createConsignment: function (dispatch) {
                return $ihttp.post({
                    method: 2270,
                    parameters: {
                        dispatch_no: dispatch
                    }
                });
            },
            transferConsignment: function (dnum) {
                return $ihttp.post({
                    method: 2283,
                    parameters: {
                        consignment_keyno: dnum
                    }
                });
            },
            loadCollies: function (dispatch) {
                return $ihttp.post({
                    method: 2233,
                    parameters: {
                        dispatch_no: dispatch
                    }
                });
            },
            loadOrderlines: function (dispatch) {
                return $ihttp.post({
                    method: 2282,
                    parameters: {
                        dispatch_no: dispatch
                    }
                });
            },
            loadSettings: function () {
                return $ihttp.post({
                    method: 2255,
                    parameters: {}
                });
            },
            loadPrinters: function () {
                return $ihttp.post({
                    method: 423,
                    parameters: {}
                });
			},
            remember: function (key, value) {
                return $ihttp.post({
                    method: 616, 
                    parameters: {
                        variablename: key,
                        variablevalue: value
                    }
				});
			},
            deleteCase: function (casekeyno) {
                return $ihttp.post({
                    method: 1895,
                    parameters: {
                        case_keyno: casekeyno
                    }
                });
            },
            mergeCollies: function (params) {
                return $ihttp.post({
                    method: 2280,
                    parameters: params
                });
            },
            splitCollies: function (params) {
                return $ihttp.post({
                    method: 2281,
                    parameters: params
                });
            },
        };

        return service;
    }]);
})();