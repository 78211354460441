(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockTransAllocations', {
        templateUrl: 'views/components/views/stockTransAllocations/stockTransAllocations.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stockTransAllocationsService', function ($stateParams, $q, stockTransAllocationsService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                setting: {},
                itemsListAllocations: []
            };

            let loadSetting = function () {
                const deferred = $q.defer();

                stockTransAllocationsService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadAllocations();

                    deferred.resolve();
                });

                return deferred.promise;
            };
            
            let loadAllocations = function () {
                vm.model.itemsListAllocations = [];
                
                return stockTransAllocationsService.listAllocations(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListAllocations);
                });
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
