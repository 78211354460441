(function () {
    'use strict';

    angular.module('imApp').component('ttHeader', {
        //template: '<div ng-class="vm.model.class" ng-style="vm.model.style">{{vm.model.label | uppercase}}</div>',
        template: '<div ng-class="vm.model.class" ng-style="vm.model.style">{{vm.model.label}}</div>',
        controllerAs: 'vm',
        bindings: {
            ttModel: '<',
            ttClass: '@',
            ttTranslate: '<',
            ttStyle: '<'
        },
        controller: ['translateService', 'layoutService', 'ttDirectivesService', function (translateService, layoutService, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.model = {
                label: '',
                class: 'tt-header col-xs-12',
                style: {

                    fontSize: '',
                }
            };

            async function translate(header) {
                vm.model.label = await translateService.translate(header);
            }

            var setLabel = function (header) {
                if (vm.ttTranslate || false) {
                    var translations = {};

                    translations[header] = '';

                    translate(header);

                    //var deregTranslations = translateService.on(translations, 'changed', function (result) {
                    //    vm.model.label = result[header];

                    //    deregTranslations();
                    //});
                } else {
                    vm.model.label = header;
                }
            };

            var setClass = function (classes) {
                if (classes) {
                    vm.model.class = classes + ' col-xs-12';
                }
            };

            var customFontSize = false;

            var setStyle = function (style) {
                if (angular.isUndefined(style)) return;
                if (style === null) return;

                if (angular.isObject(style) !== true) {
                    if (angular.isString(style) !== true) return;

                    style = style.trim();

                    if (style.substring(0, 1) !== '{') {
                        style = '{' + style + '}';
                    }

                    style = angular.fromJson(style);
                }

                if (angular.isUndefined(style.fontWeight)) {
                    style.fontWeight = vm.model.style.fontWeight;
                }

                if (angular.isDefined(style.fontSize)) {
                    customFontSize = true;
                } else {
                    style.fontSize = vm.model.style.fontsize;
                }

                angular.copy(style, vm.model.style);
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;
                if (customFontSize === true) return;

                //vm.model.style.fontSize = info.fontSizes.textSize;
                vm.model.style.fontSize = info.fontSizes.textSizeXXL;
            });

            vm.$onInit = function () {
                setLabel(vm.ttModel);
                setStyle(vm.ttStyle);
                setClass(vm.ttClass);
            };

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttModel) && angular.isString(changes.ttModel.currentValue)) {
                    setLabel(changes.ttModel.currentValue);
                }

                if (angular.isDefined(changes.ttStyle) && angular.isObject(changes.ttStyle.currentValue)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttClass) && angular.isObject(changes.ttClass.currentValue)) {
                    setClass(changes.ttClass.currentValue);
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
