(function () {
    'use strict';

    angular.module("imApp").factory("carriersService", ['$ihttp', function ($ihttp) {
        var service = {
            getCarriers: function () {
                return $ihttp.post({
                    method: 2664,
                    parameters: {}
                });
            },
            print: function (carrierKeyno, reportKeyno, printerKeyno, quantity) {
                console.dir(carrierKeyno, reportKeyno, printerKeyno, quantity);
                return $ihttp.post({
                    method: 2933,
                    parameters: {
                        carrier_keyno: carrierKeyno,
                        report_keyno: reportKeyno,
                        printer_keyno: printerKeyno,
                        quantity: quantity
                    }
                });
            }
        };

        return service;
    }]);
})();
