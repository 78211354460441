(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('timeSheetPowTimeList', {
        templateUrl: 'views/components/views/timeSheetPowTimeList/timeSheetPowTimeList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'timeSheetPowTimeListService', function ($stateParams, $q, utilityService, timeSheetPowTimeListService) {
            const vm = this;
            let labourNo = $stateParams.labour_no;
            let dateSelector = $stateParams.dateselector;
    		
            vm.model = {
                setting: {},
                itemsListPowTimes: []
            };
            
            let loadSetting = function () {
                let deferred = $q.defer();

                timeSheetPowTimeListService.getSetting({
                    labour_no: labourNo,
                    dateselector: dateSelector
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    $q.all([
                        loadPowTimes()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadPowTimes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.dateselector_index) !== true) return;
                
                vm.model.itemsListPowTimes = [];
                
                return timeSheetPowTimeListService.listPowTimes(vm.model.setting).then(function (list) {
                    angular.copy(list, vm.model.itemsListPowTimes);
                });
            };
            
            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) loadPowTimes();
            };
            
            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
