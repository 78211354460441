(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectPortalUser', {
        templateUrl: 'views/components/views/selectPortalUser/selectPortalUser.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectPortalUserService', function ($stateParams, selectPortalUserService) {
            var vm = this;
            vm.select_portal_user = selectPortalUserService.select_portal_user;
            
            vm.search_portal_user = {
                user_name: ''
            };
            
            vm.state = {
                loading: false
            };
            
            vm.searchPortalUser = function () {
                vm.state.loading = true;

                if (vm.search_portal_user.user_name !== null) {
                    selectPortalUserService.loadPortalUserSelectList($stateParams.arg1, vm.search_portal_user.user_name).then(function () {
                        vm.select_portal_user = selectPortalUserService.select_portal_user;
                        vm.state.loading = false;
                    });
                }
            };
            
            if (vm.search_portal_user.user_name == '') {
                vm.searchPortalUser();
            }
            
            vm.goToCustactMembers = function (item) {
                selectPortalUserService.goToCustactMembers(item);
            };
        }]
    });
})();