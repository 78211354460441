(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('dispatchPlanningOrderLines', {
        templateUrl: 'views/components/views/dispatchPlanningOrderLines/dispatchPlanningOrderLines.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', '$stateParams', 'dispatchPlanningOrderLinesService', function($timeout, $stateParams, dispatchPlanningOrderLinesService) {
            var vm = this;
            var resizing = false;
            var editing = false;
            
            vm.model = {
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item && vm.model.mconfig.records[r].canActive === true) {
                                    vm.model.mconfig.records[r].isActive = true;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {                    
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.orderlinesColumnResize();
                                    }
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item) && angular.isDefined(item.item_id)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        } else if (angular.isDefined(item)) {
                            for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                                if (vm.model.mconfig.records[r].item_name === item) {
                                    vm.model.mconfig.records[r].isActive = false;
                                    break;
                                }
                            }
                        } else {
                            //vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
    
                        /** UNCHECK ALL */
    
                        if (item.item_name === 'uncheck') {
                            vm.orderlinesColumnUncheckAll();
                        }
    
                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                            if (item.isActive) {
                                resizing = true;
                            } else {
                                vm.orderlinesColumnResize();
                            }
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, enabled: true, show: false },
                        { item_id: '2', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '4', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '5', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: true, show: false },
                        { item_id: '6', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', colorActive: 'warning', canActive: true, isActive: false, isIcon: false, enabled: false, show: true },
                        { item_id: '7', item_name: 'edit', glyph: 'fa-edit', color: 'primary', colorActive: 'warning', canActive: true, isActive: true, isIcon: false, enabled: true, show: false },
                        { item_id: '10', item_name: 'uncheck', glyph: 'glyphicon-unchecked', color: 'primary', show: false },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: false }
                    ]
                },
                argType: $stateParams.argtype,
    			argValue: $stateParams.argvalue,
                orderlines: [],
                orderlinesRaw: [],
                loaded: false,
                clickDec: false
            };
            
            dispatchPlanningOrderLinesService.loadOrderlines(vm.model.argType, vm.model.argValue).then(function (data) {
                var smallDataLoad = [];

                for (var i = 0; i < data.length; i++) {
                    data[i].item_id = vm.illegalCharRegExp(data[i].item_id);
                    smallDataLoad.push(data[i]);
                }

                angular.copy(smallDataLoad, vm.model.orderlines);
                angular.copy(smallDataLoad, vm.model.orderlinesRaw);
                vm.loaded = true; 
            });
            
            vm.illegalCharRegExp = function (str) {
                return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
            }
            
            vm.orderlinesColumnResize = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
                
                var updateList = [];
                
                if (angular.isDefined(vm.model.orderlines[0]) && angular.isDefined(vm.model.orderlines[0].item_secondary)) {
                    for (var r = 0; r < vm.model.orderlines[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.orderlines[0].item_col_width })
                        }

                        if (angular.isDefined(vm.model.orderlines[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.orderlines[0].item_secondary[r].item_col_width })
                        }
                    }
                }
                
                var updateParms = {
                    view_id: 'dispatchplanning.orderlines.list',
                    records: updateList
                };
                
                dispatchPlanningOrderLinesService.updateColumnResize(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
                        
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
                    
                    resizing = false;
                });
            };
        }]
    });
})();