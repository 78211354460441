(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pocf', {
        templateUrl: 'views/components/views/pocf/pocf.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'pocfService', 'typeaheadService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, pocfService, typeaheadService) {

            // ## VARIABLES & DEFINITIONS ##

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                labourNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => stateService.go('selectlabourpu', { webpagename: stateService.getCurrentName() }) }
                ],
                barcodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.barcode = '' },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator() },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchBarcode() }
                ],
                productIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                stockValueBeforeButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockget', { stock_keyno: vm.model.edit.stock_keyno }) }
                ],
                edit: {},
                itemsListComponents: [],
                lockedCreate: false,
                lockedSave: false,
                lockedAbort: false
            };
            
            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // ## LOAD PROCEDURE CALLS ##
    
            let loadEdit = function () {
                let deferred = $q.defer();

                pocfService.getEdit({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadComponents()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadComponents = function () {
                if (vm.model.edit.po_keyno > 0) {
                    vm.model.itemsListComponents = [];

                    return pocfService.listComponents(vm.model.edit).then(function (result) {
                        angular.copy(result, vm.model.itemsListComponents);
                    });
                }
            };

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function () {
                modalService.showCalculator(vm.model.edit.barcode).then(function (value) {
                    vm.model.edit.barcode = value;
                    vm.searchBarcode();
                });
            };

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            // ## SEARCH BUTTON FUNCTION ##
    
            vm.searchBarcode = function () {
                if (utilityService.validateParmsValue(vm.model.edit.labour_name) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.barcode) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.quantity) !== true) return;
                
                pocfService.scanBarcode(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.edit.barcode = '';
                                }
                            }]
                        });
                    } else {
                        vm.model.edit.barcode = '';
                        vm.model.edit.searchtext = response[0].searchtext;
                        vm.model.edit.stock_keyno = response[0].stock_keyno;
                    }
                });
            };
            
            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##
            
            vm.typeaheadSearch = function () {
                return typeaheadService.lookUpSuggestions(vm.model.edit);
            };

            // 20231011 KLN - upgrade iminput with search to ttsearch
            vm.onSearchTextCleared = function () {
                vm.model.edit.stock_keyno = '0';
                vm.model.edit.searchtext = '';
            };

            vm.onSearchTextSelected = function (item) {
                changedStockKeyNo(item.item_id);
            };

            vm.onSearchTextChanged = function (value) {
                vm.model.edit.searchtext = value;

                if (!value) {
                    vm.onSearchTextCleared();
                } else {
                    let searchItem = typeaheadService.selectListSuggestions.find((item) => item.item_name === vm.model.edit.searchtext);

                    if (searchItem) {
                        vm.onSearchTextSelected(searchItem);
                    }
                }
            };
            
            var changedStockKeyNo = function (stock_keyno) {
                vm.model.edit.stock_keyno = stock_keyno;
            };
            
            // ## CREATE BUTTON FUNCTION ##
            
            vm.createChanges = function () {
                vm.model.lockedCreate = true;
                
                pocfService.createObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedCreate = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedCreate = false;
                        vm.model.edit.po_keyno = response[0].po_keyno;

                        loadEdit();
                    }
                });
            };
            
            // ## SAVE BUTTON FUNCTION ##
            
            let loadSave = function () {
                pocfService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        vm.model.itemsListComponents = [];

                        loadEdit();
                    }
                });
            };
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                
                pocfService.createPoFinish(vm.model.edit).then(function (response) {
                    vm.model.edit.p2_pofinish_keyno = response[0].p2_pofinish_keyno;
                    vm.model.edit.p2_pofinishprod_keyno = response[0].p2_pofinishprod_keyno;
                    
                    loadSave();
                });
            };
            
            // ## DELETE BUTTON FUNCTION ##

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.abortValues = function () {
                vm.model.lockedAbort = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    pocfService.abortObj(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                                        vm.model.lockedAbort = false;
                        			}
                        		}]
                        	});
                        } else {
                            vm.model.lockedAbort = false;
                            vm.model.itemsListComponents = [];

                            loadEdit();
                        }
                    }, function () {
                        vm.model.lockedAbort = false;
                    });
                }, function () {
                    vm.model.lockedAbort = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
