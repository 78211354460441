(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("poFinishProdService", ['$ihttp', function ($ihttp) {
        let service = {
            getFinishedProductionOrder: function (parms) {
                return $ihttp.post({
                    method: 177,
                    parameters: parms || {}
                });
            },
            getProductionOrderStatus: function (parms) {
                return $ihttp.post({
                    method: 176,
                    parameters: parms || {}
                });
            },
            listLastFinished: function (parms) {
                return $ihttp.post({
                    method: 213,
                    parameters: parms || {}
                });
            },
            listLabours: function (webpagename) {
                return $ihttp.post({
                    method: 195,
                    parameters: {
                        webpagename: webpagename
                    }
                });
            },
            saveLabour: function (labour_no) {
                return $ihttp.post({
                    method: 196,
                    parameters: {
                        labour_no: labour_no
                    }
                });
            },
            assignCarrier: function (edit) {
                return $ihttp.post({
                    method: 2338,
                    parameters: edit
                });
            },
            getNextSupplierBatchNo: function (parms) {
                return $ihttp.post({
                    method: 2476,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
