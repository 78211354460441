(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prePayment', {
        templateUrl: 'views/components/views/prePayment/prePayment.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'prePaymentService', 'translateService', function ($stateParams, $q, stateService, prePaymentService, translateService) {
            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            let prePaymentKeyno = $stateParams.prepayment_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                invoiceButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('invoicehead', { invoice_no: vm.model.edit.invoice_no }) }
                ],
            	edit: {},
                selectListCashTypePayments: [],
                selectListPaymentPlans: [],
                lockedCreateInvoice: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                let deferred = $q.defer();
                
                prePaymentService.getPrepayment({
                    argtype: argType,
                    argvalue: argValue,
                    prepayment_keyno: prePaymentKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadPaymentPlans()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCashTypePayments = function () {
                prePaymentService.listCashTypePayments().then(function (result) {
                    angular.copy(result, vm.model.selectListCashTypePayments);
                });
            };

            let loadPaymentPlans = function () {
                prePaymentService.listPaymentPlans(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.selectListPaymentPlans);
                });
            };

            vm.createChanges = function () {
                vm.model.lockedCreateInvoice = true;

                prePaymentService.createObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                            title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                                label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedCreateInvoice = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedCreateInvoice = false;
                        loadEdit();
                    }
                });
            };

            vm.saveChanges = function () {
                vm.model.edit.argtype = argType;
                vm.model.edit.argvalue = argValue;

                prePaymentService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
            
            vm.deleteValues = function () {
                prePaymentService.deleteItem({ prepayment_keyno: vm.model.edit.prepayment_keyno }).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadEdit();
                loadCashTypePayments();
            };
        }]
    });
})();
