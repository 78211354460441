(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('metaColumn', {
        templateUrl: 'views/components/views/metaColumn/metaColumn.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'modalService', 'utilityService', 'metaColumnService', 'translateService', function ($stateParams, $timeout, stateService, modalService, utilityService, metaColumnService, translateService) {
            var vm = this;
            var timerColName = null;

            var translations = {
                metacolumn_confirm_title: 'Warning',
                //metacolumn_confirm_message: '',
                metacolumn_confirm_ok_label: 'OK',
                metacolumn_confirm_cancel_label: 'Cancel'
            };

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'translateedit':
                        go({
                            item_id: vm.model.edit.colname
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                metaTableKeyno: $stateParams.metatable_keyno,
                metaColumnKeyno: $stateParams.metacolumn_keyno,
                colNameTranslatedButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'translateedit' } }
                ],
                edit: {},
                selectListElementTypes: [],
                lockedSave: false,
                lockedDelete: false
            };

            metaColumnService.getMetaColumn({
                metatable_keyno: vm.model.metaTableKeyno,
                metacolumn_keyno: vm.model.metaColumnKeyno
            }).then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            metaColumnService.listElementTypes().then(function (data) {
                angular.copy(data, vm.model.selectListElementTypes);
            });

            var loadTranslatedColumnName = function () {
                if (angular.isDefined(timerColName)) {
                    $timeout.cancel(timerColName);
                }

                if (utilityService.validateParmsValue(vm.model.edit.colname) !== true) return;

                timerColName = $timeout(function () {
                    metaColumnService.getTranslatedColumnName({ colname: vm.model.edit.colname }).then(function (data) {
                        vm.model.edit.colname_translated = data[0].colname_translated;
                    });
                }, 500);
            };

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'colname':
                        vm.model.edit.colname = value;

                        if (utilityService.validateParmsValue(vm.model.edit.colname) !== true) return;

                        loadTranslatedColumnName();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                metaColumnService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.metacolumn_confirm_title,
                    message: vm.model.edit.delete_warning, //translations.metacolumn_confirm_message,
                    okLabel: translations.metacolumn_confirm_ok_label,
                    cancelLabel: translations.metacolumn_confirm_cancel_label
                }).then(function () {
                    metaColumnService.deleteObj(vm.model.edit).then(function (data) {
                        if (data[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: 'Varsel',
                                message: data[0].errormessage,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function() {
                    	vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });
        }]
    });
})();