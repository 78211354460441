(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("labourEditService", ['$q', '$ihttp', 'selectCityService', function ($q, $ihttp, selectCityService) {
        var validationResult = function (iserror, message) {
            return { isError: iserror, message: message };
        };

        var validateEmail = function (email) {
            var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            return re.test(email);
        };

        var isStringValue = function (str) {
            if (angular.isDefined(str) !== true) return false;
            if (angular.isString(str) !== true) return false;

            return str.trim().length > 0;
        };

        var service = {
            selectSearchTextSupplierList: [],

            cache: {
                labour: null
            },

            getCachedOrLoad: function (labour_no) {
                if (angular.isObject(service.cache.labour)) {
                    var labour = angular.copy(service.cache.labour);

                    service.cache.labour = null;

                    return $q.resolve(labour);
                } else {
                    return service.load(labour_no);
                }
            },

            load: function (labour_no) {
                var deferred = $q.defer();

                var parms = {
                    method: 102,
                    parameters: {
                        labour_no: labour_no
                    }
                };

                $ihttp.post(parms).then(function (data) {
                    deferred.resolve(data[0]);
                });

                return deferred.promise;
            },

            validate: function (labour) {
                if (angular.isString(labour.labour_name) !== true) return validationResult(true, 'missing_name');
                if (angular.isString(labour.password) !== true) return validationResult(true, 'missing_password');

                // Must have valid phone number or email
                if (isStringValue(labour.labour_email) === true && validateEmail(labour.labour_email) !== true) return validationResult(true, 'invalid_email');

                return validationResult(false, '');
            },
            
            save: function (labour) {
                var validationResult = service.validate(labour);

                if (validationResult.isError === true) {
                    return $q.resolve(null);
                }

                var validatePostplace = function (val1, val2) {
                    if (angular.isUndefined(val1) === true) return val2;
                    if (val1 === null) return val2;
                    if (angular.isString(val1) === false) return val2;
                    if (val1 === '') return val2;

                    return val1;
                };

                labour.labour_postplace_id = validatePostplace(labour.labour_postplace_id, selectCityService.selectedPostplace.id);
                labour.postplace_name = validatePostplace(labour.postplace_name, selectCityService.selectedPostplace.name);

                var deferred = $q.defer();

                $ihttp.post({ method: 106, parameters: labour }).then(function (data) {
                    var labourNo = labour.labour_no;

                    if (angular.isArray(data.messages) === true && data.messages.length > 0 && angular.isDefined(data.messages[0].data)) {
                        labourNo = data.messages[0].data;
                    }

                    service.load(labourNo).then(function (newLabour) {
                        deferred.resolve({ iserror: '0', labour: newLabour });
                    });
                }, function (error) {
                    deferred.resolve({ iserror: '1', errormessage: error.data.message });
                });

                return deferred.promise;
            },

            delete: function (labour) {
                return $ihttp.post({ method: 889, parameters: labour });
            }
        };

        return service;
    }]);
})();
