<mat-dialog-content>
    <!-- <p [ngStyle]="style['message']">{{ translations["message"] }}</p> -->
    <table mat-table [dataSource]="dataSource" [ngStyle]="style['table']">
        <!-- Position Column -->
        <ng-container *ngFor="let heading of headers">
            <ng-container [matColumnDef]="heading">
                <th [ngStyle]="style['cell']" *matHeaderCellDef>{{ heading }}</th>
                <td [ngStyle]="style['cell']" *matCellDef="let element">{{ element[heading] }}</td>
            </ng-container>
        </ng-container>

        <tr class="tt-popup-table__cell" mat-header-row *matHeaderRowDef="headers"></tr>

        <tr class="tt-popup-table__cell" mat-row (click)="onClick(row)" *matRowDef="let row; columns: headers" [ngClass]="selectedItem === row ? 'tt-popup__row--selected' : ''"></tr>
    </table>
</mat-dialog-content>
