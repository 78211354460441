(function () {
    'use strict';

    let module = angular.module('imApp');

    module.directive('imCalendarDirective', ['$rootScope', 'layoutService', 'eventService', 'ttDirectivesService', function ($rootScope, layoutService, eventService, ttDirectivesService) {
        var directive = {
            restrict: 'E',
            scope: {
                label: '@',         // Used for translation of label                                                //Optional, used for spacing if undefined/empty
                plabel: '=',        // Used instead of label when property is required                              //Optional, used for spacing if undefined/empty
                sublabel: '=?',     // Used if a sublabel is wanted                                                 //Optional, used if a sublabel is wanted
                field: '=',         // Links in the values                                                          //REQUIRED
                fom: '@',           // Used to replace the default value in model                                   //Optional, default is 'date_fom'
                hideFom: '@',       // Used to hide from field                                                      //Optional, default is false
                tom: '@',           // Used to replace the default value in model                                   //Optional, default is 'date_tom'
                bindex: '=',        // Used to replace the default value in model                                   //Optional, default is '1',      accepts '1', '2', ... , '6'
                //style: '=',         // Single variable used for styling                                             //NOT IMPLEMENTED
                optionfunc: '&?',    // Links a function from controller                                             //NOT IMPLEMENTED
                ttChange: '&'
            },
            templateUrl: './views/templates/directives/imCalendarDirective.html?v=' + module.version,
            link: function ($scope) {
                $scope.model = {
                    mId: uuid(),
                    fom: 'date_fom',
                    hideFom: false,
                    tom: 'date_tom',
                    dtdS: new Date(),    //Datetime date Start  //Ex. 2018-05-18
                    dtdE: new Date(),    //Datetime date End
                    openedS: false,
                    openedE: false,
                    lastBtnIndex: 1,   //Defaults to index '1' = name: day
                    labelAlwaysOnTop: false,
                    subLabelClasses: '',
                    groupStyle: {},
                    labelStyle: {
                        fontSize: ''
                    },
                    dateStyle: {
                        fontSize: '',
                        height: ''
                    },
                    buttonStyle: {
                        fontSize: '',
                        height: '',
                        top: '0'
                    },
                    chevronStyle: {},
                    buttonLabelStyle: {
                        fontSize: '',
                        top: '0'
                    },
                    classes: {
                        base: '',
                        group: '',
                        name: '',
                        datediv: '',
                        date: '',
                        btndiv: '',
                        btn: {
                            b0: '',
                            b1: '',
                            b2: '',
                            b3: '',
                            b4: '',
                            b5: '',
                            b6: '',
                            b7: ''
                        },
                        btntxt: {
                            b0: '',
                            b1: '',
                            b2: '',
                            b3: '',
                            b4: '',
                            b5: '',
                            b6: '',
                            b7: ''
                        }
                    }
                };

                $scope.style = {
                    base: {}
                }

                var onDateChanged = function (bindex) {
                    var fom = $scope.field[$scope.model.fom];
                    var tom = $scope.field[$scope.model.tom];

                    if (angular.isFunction($scope.ttChange) !== true) return;

                    if (angular.isUndefined(bindex)) { bindex = -1; }

                    if (bindex === null) { bindex = -1; }

                    switch (bindex) {
                        case '1':
                        case '2':
                        case '3':
                        case '4':
                        case '5':
                        case '6':
                            break;
                        default:
                            bindex = -1;
                    }

                    $scope.ttChange({ $fom: fom, $tom: tom, $bindex: bindex });
                };

                $scope.whenReady = function () {
                    eventService.trigger('element:ready');
                };

                $scope.dateOptions = {
                    formatYear: 'yy',
                    startingDay: 1
                };

                $scope.openS = function () {
                    $scope.model.openedS = true;
                };

                $scope.openE = function () {
                    $scope.model.openedE = true;
                };

                $scope.fontSizes = {
                    textSize: '',
                    textSizeS: '',
                    textSizeSs: '',
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    $scope.model.groupStyle.marginBottom = info.margin.bottom + 'px';

                    $scope.model.labelAlwaysOnTop = info.labelAlwaysOnTop;

                    angular.copy(info.fontSizes, $scope.fontSizes);

                    $scope.model.labelStyle.fontSize = $scope.fontSizes.textSizeS;
                    $scope.model.buttonLabelStyle.fontSize = $scope.fontSizes.textSize;


                    $scope.model.dateStyle.fontSize = $scope.fontSizes.textSize;
                    $scope.model.dateStyle.height = info.height + 'px';
                    $scope.model.dateStyle.paddingTop = info.padding.top + 'px';
                    $scope.model.dateStyle.paddingBottom = info.padding.bottom + 'px';
                    $scope.model.dateStyle.paddingLeft = info.padding.left + 'px';
                    $scope.model.dateStyle.paddingRight = info.padding.right + 'px';

                    var paddingTop = info.size < 15
                        ? 6
                        : info.size < 20
                            ? 5
                            : info.size < 28
                                ? 4
                                : 3;

                    $scope.model.buttonStyle.fontSize = $scope.fontSizes.textSize;
                    $scope.model.buttonStyle.height = info.height + 'px';
                    angular.copy($scope.model.buttonStyle, $scope.model.chevronStyle);


                    $scope.model.classes.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: info.labelAlwaysOnTop });
                });


                var setClasses = function () {
                    Object.keys($scope.model.classes.btn).forEach((key) => $scope.model.classes.btn[key] = 'tt-input__button  tt-input__button--primary tt-date-range__button');

                    $scope.model.classes.btn['b' + $scope.model.lastBtnIndex] += ' tt-date-range__button--active';

                    $scope.model.classes.btntxt.b0 = 'fa-fw fas fa-chevron-left ';

                    $scope.model.classes.btntxt.b7 = 'fa-fw fas fa-chevron-right ';

                }

                var setGroupClasses = function () {
                };

                setGroupClasses();

                $scope.$watch(function () {
                    return $scope.grpsize;
                }, setGroupClasses);

                $scope.datePickerChanged = function () {
                    // BJS 20220907 - Fixed bug where onDateChanged did not fire when one of the valus was null.
                    if ($scope.model.dtdS === null) {
                        $scope.field[$scope.model.fom] = null;
                    }

                    if ($scope.model.dtdE === null) {
                        $scope.field[$scope.model.tom] = null;
                    }

                    if (isDate($scope.model.dtdS) === true) {
                        $scope.field[$scope.model.fom] = $scope.model.dtdS.toISOString().substr(0, 10);
                    }

                    if (isDate($scope.model.dtdE) === true) {
                        $scope.field[$scope.model.tom] = $scope.model.dtdE.toISOString().substr(0, 10);
                    }

                    onDateChanged();
                }

                var goBtnFunc = function (first, last, bindex) {
                    bindex = bindex || '-1';

                    if (angular.isNumber(bindex)) {
                        bindex = bindex.toString();
                    }

                    $scope.field[$scope.model.fom] = first.toISOString().substr(0, 10);
                    $scope.field[$scope.model.tom] = last.toISOString().substr(0, 10);

                    onDateChanged(bindex);

                    if (bindex !== '-1') {
                        if (angular.isDefined($scope.bindex) && $scope.bindex !== '') {
                            $scope.bindex = bindex;
                        } else {
                            $scope.model.lastBtnIndex = parseInt(bindex, 10);

                            setClasses();
                        }
                    }
                }

                // ###############
                // ADD END
                // ###############

                // ###############
                // BTN START
                // ###############

                $scope.goBtn = function (btnIndex) {
                    if (angular.isDefined(btnIndex) && btnIndex !== '') {
                        if (isDate($scope.model.dtdS) == false) {
                            if (angular.isDefined($scope.field) && isDate($scope.field[$scope.model.fom]) == true) {
                                $scope.model.dtdS = new Date($scope.field[$scope.model.fom]);
                            } else {
                                $scope.model.dtdS = new Date();
                            }
                        }

                        switch (btnIndex) {
                            case 0: //down //previous
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth(), f = curr.getDate(), d = curr.getDay(),
                                    q = (Math.floor((m) / 3) * 3), b = (Math.floor((m) / 6) * 6),
                                    firstDay = new Date(), lastDay = new Date();

                                if ($scope.model.lastBtnIndex === 1) { // -1 Day
                                    firstDay = new Date(y, m, (f - 1), 2); // 1 day prior to this day
                                    lastDay = firstDay; // Same day as firstDay
                                } else if ($scope.model.lastBtnIndex === 2) { // -1 Week
                                    var first = f - d + 1; // First is the day of the month minus the day of the week
                                    var last = first + 6; // last is the first day + 6

                                    firstDay = new Date(y, m, (first - 7), 2); // 1 week prior to the first of this week
                                    lastDay = new Date(y, m, (last - 7), 2); // 1 week prior to the last day of this week
                                } else if ($scope.model.lastBtnIndex === 3) { // -1 Month
                                    firstDay = new Date(y, (m - 1), 1, 2); // 1 month prior to the first of this month
                                    lastDay = new Date(y, m, 0, 2); // 1 month prior to the last day of this month
                                } else if ($scope.model.lastBtnIndex === 4) { // -1 Quarter
                                    firstDay = new Date(y, (q - 3), 1, 2); // 3 months prior to the first of this quarter
                                    lastDay = new Date(y, q, 0, 2); // 3 months prior to the last day of this quarter
                                } else if ($scope.model.lastBtnIndex === 5) { // -1 Halfyear
                                    firstDay = new Date(y, (b - 6), 1, 2); // 6 months prior to the first of this half of the year
                                    lastDay = new Date(y, b, 0, 2); // 6 months prior to the last day of this half of the year
                                } else if ($scope.model.lastBtnIndex === 6) { // -1 Year
                                    firstDay = new Date((y - 1), 0, 1, 2); // 1st of previous year
                                    lastDay = new Date((y - 1), 12, 0, 2); // 1st of this year minus 1 day
                                }

                                goBtnFunc(firstDay, lastDay);

                                break;
                            case 1: //day
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth(), f = curr.getDate();
                                var firstDay = new Date(y, m, f, 2); // This day
                                var lastDay = firstDay;

                                goBtnFunc(firstDay, lastDay, btnIndex);

                                break;
                            case 2: //week
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth();
                                var first = curr.getDate() - curr.getDay() + 1; // First is the day of the month minus the day of the week
                                var last = first + 6; // last is the first day + 6

                                var firstDay = new Date(y, m, first, 2); // First of the week
                                var lastDay = new Date(y, m, last, 2); // Last of the week

                                goBtnFunc(firstDay, lastDay, btnIndex);

                                break;
                            case 3: //month
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth();
                                var firstDay = new Date(y, m, 1, 2); // 1st of this month
                                var lastDay = new Date(y, (m + 1), 0, 2); // 1st of next month minus 1 day

                                goBtnFunc(firstDay, lastDay, btnIndex);

                                break;
                            case 4: //quarter
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth(), q = (Math.floor((m) / 3) * 3);
                                var firstDay = new Date(y, q, 1, 2); // 1st of this quarter
                                var lastDay = new Date(y, (q + 3), 0, 2); // 1st of next quarter's month minus 1 day

                                goBtnFunc(firstDay, lastDay, btnIndex);

                                break;
                            case 5: //biannual
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth(), b = (Math.floor((m) / 6) * 6);
                                var firstDay = new Date(y, b, 1, 2); // 1st of this half of the year
                                var lastDay = new Date(y, (b + 6), 0, 2); // 1st of next half year's month minus 1 day

                                goBtnFunc(firstDay, lastDay, btnIndex);

                                break;
                            case 6: //yearly
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear();
                                var firstDay = new Date(y, 0, 1, 2); // 1st of this year
                                var lastDay = new Date(y, 12, 0, 2); // 1st of next year minus 1 day

                                goBtnFunc(firstDay, lastDay, btnIndex);

                                break;
                            case 7: //up //next
                                var curr = angular.copy($scope.model.dtdS), y = curr.getFullYear(), m = curr.getMonth(), f = curr.getDate(), d = curr.getDay(),
                                    q = (Math.floor((m) / 3) * 3), b = (Math.floor((m) / 6) * 6),
                                    firstDay = new Date(), lastDay = new Date();

                                if ($scope.model.lastBtnIndex === 1) { // +1 Day
                                    firstDay = new Date(y, m, (f + 1), 2); // 1 day subsequent to this day
                                    lastDay = firstDay; // Same day as firstDay
                                } else if ($scope.model.lastBtnIndex === 2) { // +1 Week
                                    var first = f - d + 1; // First is the day of the month minus the day of the week
                                    var last = first + 6; // last is the first day + 6

                                    firstDay = new Date(y, m, (first + 7), 2); // 1 week subsequent to the first of this week
                                    lastDay = new Date(y, m, (last + 7), 2); // 1 week subsequent to the last day of this week
                                } else if ($scope.model.lastBtnIndex === 3) { // +1 Month
                                    firstDay = new Date(y, (m + 1), 1, 2); // 1 month subsequent to the first of this month
                                    lastDay = new Date(y, (m + 2), 0, 2); // 1 month subsequent to the last day of this month
                                } else if ($scope.model.lastBtnIndex === 4) { // +1 Quarter
                                    firstDay = new Date(y, (q + 3), 1, 2); // 3 months subsequent to the first of this quarter
                                    lastDay = new Date(y, (q + 6), 0, 2); // 3 months subsequent to the last day of this quarter
                                } else if ($scope.model.lastBtnIndex === 5) { // +1 Halfyear
                                    firstDay = new Date(y, (b + 6), 1, 2); // 6 months subsequent to the first of this half of the year
                                    lastDay = new Date(y, (b + 12), 0, 2); // 6 months subsequent to the last day of this half of the year
                                } else if ($scope.model.lastBtnIndex === 6) { // +1 Year
                                    firstDay = new Date((y + 1), 0, 1, 2); // 1st of next year
                                    lastDay = new Date((y + 1), 12, 0, 2); // 1st of subsequent of next year minus 1 day
                                }

                                goBtnFunc(firstDay, lastDay);
                        }
                    } else {
                        console.log('goBtnClicked not defined');
                    }
                };

                // ###############
                // BTN END
                // ###############

                // ###############
                // FUNCTIONS START
                // ###############

                function isDate(d) {
                    if (Object.prototype.toString.call(d) === "[object Date]") {
                        // it is a date
                        if (isNaN(d.getTime())) {  // d.valueOf() could also work
                            return false; // date is not valid
                        }
                        else {
                            return true; // date is valid
                        }
                    }
                    else {
                        return false; // not a date
                    }
                }

                // BJS 20220907 - Fixed a bug where default value from parent would not be set because
                //                the watch on $scope.field fired before the watches on $scope.fom and $scope.tom.
                var setDates = function () {
                    if (angular.isUndefined($scope.field))
                        return;

                    if (angular.isDefined($scope.field[$scope.model.fom])) {
                        if ($scope.field[$scope.model.fom] === null) {
                            $scope.model.dtdS = null;
                        } else {
                            if ($scope.field[$scope.model.fom] !== '') {
                                $scope.model.dtdS = new Date($scope.field[$scope.model.fom]);
                            }
                        }
                    }

                    if (angular.isDefined($scope.field[$scope.model.tom])) {
                        if ($scope.field[$scope.model.tom] === null) {
                            $scope.model.dtdE = null;
                        } else {
                            if ($scope.field[$scope.model.tom] !== '') {
                                $scope.model.dtdE = new Date($scope.field[$scope.model.tom]);
                            }
                        }
                    }
                };

                // ###############
                // FUNCTIONS END
                // ###############

                // ###############
                // WATCH START
                // ###############


                $scope.$watch(function () {
                    return $scope.hideFom;
                }, function () {
                    $scope.model.hideFom = $scope.hideFom === 'true';
                });

                // BJS 20220719 - Fixed a bug that prevented values from being set with custom fom and tom values.
                $scope.$watch(function () {
                    return $scope.field;
                }, setDates, true);

                $scope.$watch(function () {
                    return $scope.fom;
                }, function () {
                    if (angular.isUndefined($scope.field))
                        return;

                    if (angular.isDefined($scope.field[$scope.fom]) && $scope.fom !== '') {
                        $scope.model.fom = $scope.fom;

                        // BJS 20220907
                        setDates();
                    }
                });

                $scope.$watch(function () {
                    return $scope.tom;
                }, function () {
                    if (angular.isUndefined($scope.field))
                        return;

                    if (angular.isDefined($scope.field[$scope.tom]) && $scope.tom !== '') {
                        $scope.model.tom = $scope.tom;

                        // BJS 20220907
                        setDates();
                    }
                });

                $scope.$watch(function () {
                    return $scope.bindex;
                }, function () {
                    if (angular.isDefined($scope.bindex) && $scope.bindex !== '') {
                        if ($scope.bindex === '1' || $scope.bindex === '2' || $scope.bindex === '3' || $scope.bindex === '4' || $scope.bindex === '5' || $scope.bindex === '6') {
                            $scope.model.lastBtnIndex = parseInt($scope.bindex, 10);
                        }
                    }

                    setClasses();
                });

                // ###############
                // WATCH END
                // ###############
            }
        };

        return directive;
    }]);
})();
