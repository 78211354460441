(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('stockControlLocStock', {
        templateUrl: 'views/components/views/stockControlLocStock/stockControlLocStock.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'stockControlLocStockService', function($stateParams, stateService, utilityService, stockControlLocStockService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                stockLocationKeyNo: $stateParams.stocklocation_keyno,
                stockKeyNo: $stateParams.stock_keyno,
                bgStockControlLocStockView: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'bg_stockcontrollocstock_view_control', item_func: 'CONTROL' },
                        { item_id: '1', item_name: 'bg_stockcontrollocstock_view_other', item_func: 'OTHER' }
                    ]
                },
                location: {},
                itemsListOthers: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            stockControlLocStockService.loadStockControlLocStockGet(vm.model.stockLocationKeyNo, vm.model.stockKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.location);
    
                loadStockControlLocStockOthers();
            });
    
            var loadStockControlLocStockOthers = function () {
                if (utilityService.validateParmsValue(vm.model.location.stocklocation_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.location.stock_keyno) !== true) return;
    
                vm.model.itemsListOthers = [];
    
                stockControlLocStockService.loadStockControlLocStockOtherList(vm.model.location.stocklocation_keyno, vm.model.location.stock_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListOthers);
                });
            };
    
            // ## BUTTON GROUP FUNCTIONS ##
    
            vm.selectBgStockControlLocStockView = function (item) {
                vm.model.bgStockControlLocStockView.item_selected = item;
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveStockControlLocStock = function () {
                vm.model.lockedSave = true;
                
                stockControlLocStockService.save(vm.model.location).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'prodsizeedit':
                        go({
                            prodsize_keyno: vm.model.location.prodsize_keyno
                        });
                        break;
                    case 'producteans':
                        go({
                            prod_id: vm.model.location.prod_keyno
                        });
                        break;
                    case 'stockget':
                        go({
                            stock_keyno: vm.model.location.stock_keyno
                        });
                        break;
                }
            };
        }]
    });
})();