(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("webpageSettingMenuService", ['$ihttp', function ($ihttp) {
        var service = {
            loadWebpageSettingMenuList: function (webpage_name) {
                return $ihttp.post({
                    method: 114,
                    parameters: {
                        webpage_name: webpage_name
                    }
                })
            },
        }

        return service;
    }]);
})();