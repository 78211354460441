(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('product', {
        templateUrl: 'views/components/views/product/product.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'modalService', 'translateService', 'rememberService', 'productService', 'productSettingsService', 'logisticService', 'translateListService', 'productCreateService', 'typeaheadService', 'stateService', 'fileService', 'ieScreenBlockService', function ($stateParams, $q, utilityService, modalService, translateService, rememberService, productService, productSettingsService, logisticService, translateListService, productCreateService, typeaheadService, stateService, fileService, ieScreenBlockService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodId = $stateParams.prod_id;

            let translations = {
                error: '',
                ok: ''
            };

            let variableNames = {
                bg_product_view: ''
            };

            vm.model = {
                mobileSize: window.matchMedia('(min-width: 990px)'),
                parmUrl: $stateParams.prod_id,
                applicationId: fileService.applicationId,
                productImages: [],
                productImageUrl: '',
                bgProductView: {
                    selected: 'BASIC',
                    buttons: [
                        { id: 'BASIC', label: 'bg_product_view_basic', color: 'primary', onClick: () => setProductView('BASIC') },
                        { id: 'OTHER', label: 'bg_product_view_other', color: 'primary', onClick: () => setProductView('OTHER') },
                        { id: 'FUNCTIONS', label: 'bg_product_view_functions', color: 'primary', onClick: () => setProductView('FUNCTIONS') }
                    ]
                },
                //dateFormat: {
                //    date: { show: true, disabled: false, align: 'left' }
                //},
                dateTimeFormat: {
                    date: { show: true, disabled: false, align: 'left' },
                    hour: { show: false },
                    minute: { show: false }
                },
                prodHazardClassButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('producthazardlabels', { prod_id: vm.model.edit.prod_keyno.substring(12) }) }
                ],
                prodNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('productlangs', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                productStockUnitButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('productmultiunit', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                priceGroupsInfoButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('choiceprodpricegrps', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                productGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrps', {}) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('prodgrpmenu', { prodgrp_id: vm.model.edit.prodgrp_id }) }
                ],
                modelNameButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.model_id = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('models', {}) }
                ],
                collectionButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.collection_keyno = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('collections', {}) }
                ],
                servicetypeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.servicetype_no = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('collections', {}) }
                ],
                bookinggrpButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.bookinggrp_keyno = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('collections', {}) }
                ],
                customsTarifButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.customstarif_id = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('customstarifs', {}) }
                ],
                eanListButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('producteans', { prod_id: vm.model.edit.prod_keyno }) }
                ],
                discGroupProductNameButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.discgrpprod_keyno = '0' }
                ],
                edit: {},
                selectListProductSettings: [],
                selectListCompletionMethods: [],
                selectListCompletionPrinciples: [],
                selectListUnits: [],
                selectListProductTypes: [],
                selectListPlanPolicies: [],
                selectListProductPlaces: [],
                selectListProductCycles: [],
                selectListProductGroups: [],
                selectListProductGroup2s: [],
                selectListProductGroup3s: [],
                selectListProductGroup4s: [],
                selectListProdBeoMethods: [],
                selectListModels: [],
                selectListColourGroups: [],
                selectListCollections: [],
                selectListBusinessCompanies: [],
                selectListTarifs: [],
                selectListAssortments: [],
                selectListServicetypes: [],
                selectListDiscGroupProducts: [],
                selectListLanguages: [],
                selectListHazardClasses: [],
                selectListTunnelCodes: [],
                selectListBookinggrps: [],
                inputListMcs: [],
                itemsListOwnKpis: [],
                lockedSave: false,
                lockedSavePimData: false,
                lockedDelete: false
            };

            vm.style = {
                basic: {},
                container: {
                },
                img: {
                    minHeight: `70px`
                },
                placeholder: {
                    minHeight: `70px`
                }
            };

            vm.options = {
                stock: {
                    autoselect: true,
                    decimals: 2,
                    //textAlign: 'right',
                    //readonly: false,
                    //placeholder: ''
                },
                production: {
                    autoselect: true,
                    decimals: 0
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region BUTTON GROUP SINGLE FUNCTION
            
            let setProductView = function (id) {
                vm.model.bgProductView.selected = vm.model.edit.bg_product_view = id;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();
                
                productService.getProduct({ prod_id: prodId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    setProductView(vm.model.edit.bg_product_view);
                    stateService.setTitleOverride(vm.model.edit.page_heading);

                    $q.all([
                        loadProductGroup2s(),
                        loadOwnKpis(),
                        loadProductSettingMcs()
                    ]).then(() => {
                        var product_main_image = vm.model.edit.product_main_image

                        if (typeof (product_main_image) === 'string' && product_main_image !== '') {
                            ieScreenBlockService.start();

                            fileService.getFileInfo({
                                selectOnlyFromFiles: true,
                                files: [{
                                    "fileName": product_main_image
                                }]//,
                                //page: 1,
                                //pageSize: 1
                            }).then(function (result) {
                                if (typeof (result) === 'object' && "files" in result && result.files.length > 0) {

                                    // We only want first image for the first version of this feature
                                    var fileData = result.files[0];
                                    var thumbUrl = fileData.thumbUrl;

                                    var decoded = decodeURIComponent(thumbUrl);

                                    var qsIndex = decoded.indexOf('{');

                                    var url = decoded.substr(0, qsIndex);

                                    var qs = decoded.substr(qsIndex);

                                    qs = qs.substr(0, qs.indexOf(',"width"')) + ',"width":800,"height":600}';
                                    qs = encodeURIComponent(qs);

                                    // Insert link to image for use in HTML
                                    vm.model.productImageUrl = url + qs;
                                } else {
                                    // NO IMAGE RETURNED FROM FILESERVER, HANDLE ERROR
                                }

                                ieScreenBlockService.stop();
                            }).catch(function () {
                                // ERROR API CALL, HANDLE ERROR

                                ieScreenBlockService.stop();
                            });
                        }

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadPohCompletionMethods = function () {
                logisticService.listPohCompletionMethods().then(function (result) {
                    angular.copy(result, vm.model.selectListCompletionMethods);
                });
            };

            let loadProductSettings = function () {
                productSettingsService.listProductSettings().then(function (result) {
                    angular.copy(result, vm.model.selectListProductSettings);
                });
            };

            let loadPohCompletionPrinciples = function () {
                logisticService.listPohCompletionPrinciples().then(function (result) {
                    angular.copy(result, vm.model.selectListCompletionPrinciples);
                });
            };

            let loadUnits = function () {
                logisticService.listUnits().then(function (result) {
                    angular.copy(result, vm.model.selectListUnits);
                });
            };

            let loadProductTypes = function () {
                productService.listProductTypes().then(function (result) {
                    angular.copy(result, vm.model.selectListProductTypes);
                });
            };

            let loadPlanPolicies = function () {
                logisticService.listPlanPolicies().then(function (result) {
                    angular.copy(result, vm.model.selectListPlanPolicies);
                });
            };

            let loadModels = function () {
                productService.listModels().then(function (result) {
                    angular.copy(result, vm.model.selectListModels);
                });
            };

            let loadColourGroups = function () {
                productService.listColourGroups().then(function (result) {
                    angular.copy(result, vm.model.selectListColourGroups);
                });
            };

            let loadHazardClasses = function () {
                productService.listHazardClasses().then(function (result) {
                    angular.copy(result, vm.model.selectListHazardClasses);
                });
            };

            let loadTunnelCodes = function () {
                productService.listTunnelCodes().then(function (result) {
                    angular.copy(result, vm.model.selectListTunnelCodes);
                });
            };

            let loadBookinggrps = function () {
                productService.listBookinggrps().then(function (result) {
                    angular.copy(result, vm.model.selectListBookinggrps);
                });
            };

            let loadCollections = function () {
                productService.listCollections({ isactive: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCollections);
                });
            };

            let loadCustomsTarifs = function () {
                productService.listCustomsTarifs().then(function (result) {
                    angular.copy(result, vm.model.selectListTarifs);
                });
            };

            let loadAssortments = function () {
                productService.listAssortments().then(function (result) {
                    angular.copy(result, vm.model.selectListAssortments);
                });
            };

            let loadServicetypes = function () {
                productService.listServicetypes().then(function (result) {
                    angular.copy(result, vm.model.selectListServicetypes);
                });
            };


            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListBusinessCompanies);
                });
            };

            let loadProductPlaces = function () {
                logisticService.listProductPlaces().then(function (result) {
                    angular.copy(result, vm.model.selectListProductPlaces);
                });
            };

            let loadProdBeoMethods = function () {
                logisticService.listProdBeoMethods().then(function (result) {
                    angular.copy(result, vm.model.selectListProdBeoMethods);
                });
            };

            let loadProductCycles = function () {
                logisticService.listProductCycles().then(function (result) {
                    angular.copy(result, vm.model.selectListProductCycles);
                });
            };

            let loadProductGroups = function () {
                productService.listProductGroups().then(function (result) {
                    angular.copy(result, vm.model.selectListProductGroups);
                });
            };

            let loadDiscGroupProducts = function () {
                productService.listDiscGroupProducts({ add_from_prodgrp: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListDiscGroupProducts);
                });
            };

            let loadLanguages = function () {
                translateListService.listLanguages().then(function (result) {
                    angular.copy(result, vm.model.selectListLanguages);
                });
            };

            let loadProductGroup2s = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodgrp_id, true) !== true) return;
                
                return productService.listProductGroup2s({ prodgrp_id: vm.model.edit.prodgrp_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductGroup2s);
                });
            };

            let loadProductGroup3s = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodgrp2_id, true) !== true) return;
                
                return productCreateService.listProductGroup3s({ prodgrp2_id: vm.model.edit.prodgrp2_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductGroup3s);
                });
            };

            let loadProductGroup4s = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodgrp3_id, true) !== true) return;

                return productCreateService.listProductGroup4s({ prodgrp3_id: vm.model.edit.prodgrp3_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductGroup4s);
                });
            };

            let loadProductSettingMcs = function () {
                if (vm.model.edit.show_pim_data === '1') {
                    if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.edit.productsetting_keyno, true) !== true) return;
                    if (utilityService.validateParmsValue(vm.model.edit.language_id, true) !== true) return;

                    return productService.listProductSettingMcs({
                        prod_id: vm.model.edit.prod_id,
                        productsetting_keyno: vm.model.edit.productsetting_keyno,
                        language_id: vm.model.edit.language_id
                    }).then(function (result) {
                        angular.copy(result, vm.model.inputListMcs);
                    });
                }
            };

            let loadOwnKpis = function () {
                vm.model.itemsListOwnKpis = [];

                return productService.listOwnKpis(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.itemsListOwnKpis);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bg_product_view':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgProductView = function (value) {
                vm.model.bgProductView.selected = value;
                vm.model.edit.bg_product_view = vm.model.bgProductView.selected;

                rememberFunc('bg_product_view');
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'searchstock':
                        productService.searchStock({
                            functionname: 'stock',
                            barcode: vm.model.edit.prod_id
                        }).then(function (response) {
                            if (e?.ctrlKey) {
                                stateService.newTab(response[0].webpagename, response[0].parms);
                            } else {
                                stateService.go(response[0].webpagename, response[0].parms);
                            }
                        });
                        break;
                    case 'selectreport':
                        go({
                            webpage_name: stateService.getCurrentName(),
                            argtype: 'prod_keyno',
                            argvalue: vm.model.edit.prod_keyno
                        });
                        break;
                    case 'choices':
                    case 'productbom':
                    case 'productpics':
                    case 'prodsizes':
                    case 'components':
                    case 'productcalc':
                    case 'productprices':
                        go({ prod_id: vm.model.edit.prod_keyno });
                        break;
                    case 'componentalternatives':
                        go({
                            prod_id: vm.model.edit.prod_keyno,
                            comp_keyno: '0'
                        });
                        break;
                    case 'custactsearch':
                        go({
                            filterfield: 'prod_id',
                            filtervalue: vm.model.edit.prod_keyno,
                            custacttype: 'A'
                        });
                        break;
                    case 'picturebanks':
                    case 'pictures':
                    case 'documents':
                    case 'checklistdatas':
                    case 'pricelistfactors':
                    case 'categorydatas':
                        go({
                            argtype: 'prod_id',
                            argvalue: vm.model.edit.prod_keyno
                        });
                        break;
                    case 'prodsizeedit':
                        go({ prodsize_keyno: vm.model.edit.prodsize_keyno });
                        break;
                    case 'stockforecast':
                        go({
                            prod_id: vm.model.edit.prod_keyno,
                            prodsize_keyno: '0',
                            prodchoice_keyno: '0',
                            stockplace_id: '-'
                        });
                        break;
                    case 'productLinksSpareParts':
                        if (e?.ctrlKey) {
                            stateService.newTab('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '1'
                            });
                        } else {
                            stateService.go('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '1'
                            });
                        }
                        break;
                    case 'productLinksSubstitutes':
                        if (e?.ctrlKey) {
                            stateService.newTab('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '2'
                            });
                        } else {
                            stateService.go('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '2'
                            });
                        }
                        break;
                    case 'productLinksAlsoBuys':
                        if (e?.ctrlKey) {
                            stateService.newTab('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '3'
                            });
                        } else {
                            stateService.go('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '3'
                            });
                        }
                        break;
                    case 'productLinksFits':
                        if (e?.ctrlKey) {
                            stateService.newTab('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '4'
                            });
                        } else {
                            stateService.go('productlinks', {
                                argtype: 'prod_id',
                                argvalue: vm.model.edit.prod_keyno,
                                productlinktype_keyno: '4'
                            });
                        }
                        break;
                    default:
                        break;
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                productService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.savePimDataChanges = function () {
                vm.model.lockedSavePimData = true;
                
                productService.savePimDataObj({
                    prod_id: vm.model.edit.prod_id,
                    language_id: vm.model.edit.language_id,
                    metacolumns: vm.model.inputListMcs
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSavePimData = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSavePimData = false;
                    	//stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.lookUpResources({ searchtext: value || '' });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SET VALUE FUNCTION

            vm.setSpecialProdNameView = function (item) {
                vm.model.edit.special_prod_id_view = angular.isUndefined(item) ? '' : item.item_id;
                vm.model.edit.special_prod_name_view = angular.isUndefined(item) ? '' : item.item_name;
            };

		    // #endregion SET VALUE FUNCTION

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                productService.deleteItem({ prod_id: vm.model.edit.prod_keyno }).then(function () {
                    vm.model.lockedDelete = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

		    // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'prodgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodgrp_id) !== true) return;

                        vm.model.edit.prodgrp_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodgrp_id, true) !== true) return;

                        loadProductGroup2s();

                        vm.model.edit.prodgrp2_id = '';
                        vm.model.edit.prodgrp3_id = '';
                        vm.model.edit.prodgrp4_id = '';
                        vm.model.selectListProductGroup3s = [];
                        vm.model.selectListProductGroup4s = [];
                        break;
                    case 'prodgrp2_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodgrp2_id) !== true) return;
                        
                        vm.model.edit.prodgrp2_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodgrp2_id, true) !== true) return;

                        loadProductGroup3s();

                        vm.model.edit.prodgrp3_id = '';
                        vm.model.edit.prodgrp4_id = '';
                        vm.model.selectListProductGroup4s = [];
                        break;
                    case 'prodgrp3_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.prodgrp3_id) !== true) return;

                        vm.model.edit.prodgrp3_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.prodgrp3_id, true) !== true) return;

                        loadProductGroup4s();

                        vm.model.edit.prodgrp4_id = '';
                        break;
                    case 'productsetting_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.productsetting_keyno) !== true) return;

                        vm.model.edit.productsetting_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.productsetting_keyno, true) !== true) return;

                        loadProductSettingMcs();
                        break;
                    case 'language_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.language_id) !== true) return;

                        vm.model.edit.language_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.language_id, true) !== true) return;

                        loadProductSettingMcs();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };
            
		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadProductSettings();
                loadPohCompletionMethods();
                loadPohCompletionPrinciples();
                loadUnits();
                loadProductTypes();
                loadPlanPolicies();
                loadModels();
                loadColourGroups();
                loadCollections();
                loadCustomsTarifs();
                loadAssortments();
                loadServicetypes();
                loadCompanies();
                loadProductPlaces();
                loadProdBeoMethods();
                loadProductCycles();
                loadProductGroups();
                loadDiscGroupProducts();
                loadLanguages();
                loadHazardClasses();
                loadTunnelCodes();
                loadBookinggrps();

            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
