(function () {
    'use strict';

    angular.module("imApp").factory("acLedgerService", ['$ihttp', function ($ihttp) {
        var service = {
            getAcLedger: function (parms) {
                return $ihttp.post({
                    method: 2787,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();