(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttSearch', {
        templateUrl: 'views/components/directives/ttSearch/ttSearch.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none' - null or undefined indicates auto. - JLR 20230622
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttItemId: '@',
            ttSearch: '&',
            ttSearchParm: '@',
            ttSearchId: '@',
            ttSearchName: '@',
            ttOnSelected: '&',
            ttOnClear: '&',
            ttOnBlur: '&',
            ttReadonly: '@',
            ttMinLength: '<',
            ttWaitMs: '<',
            ttButtons: '<',
            ttButtonsParm: '<',
            ttRequired: '@',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttStyle: '<'            // ODL 20230323
        },
        controller: ['$q', '$element', '$timeout', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($q, $element, $timeout, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            vm.required = false;

            vm.model = undefined;
            vm.ttSpin = false;
            vm.searchParm = 'value';
            vm.searchId = 'item_id';
            vm.searchName = 'item_name';
            vm.readonly = false;
            vm.minLength = 3;
            vm.waitMs = 500;

            var onDestroy = [];

            vm.style = {
                base: {},
                group: {},
                button: {},
                label: {},
                input: {},
                icon: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                input: ''
            };

            vm.id = {
                input: uuid()
            };

            vm.translations = {
                ttLabel: ''
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onSelected = function (item, model, label, event) {
                vm.model = label;

                vm.onModelChanged(label);

                if (angular.isFunction(vm.ttOnSelected)) {
                    vm.ttOnSelected({ $item: item, $model: model, $label: label, $event: event });
                }
            };

            vm.onModelChanged = function (value) {
                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'input' }), vm.style);

            vm.onSearch = function (value) {
                var deferred = $q.defer();

                if (angular.isFunction(vm.ttSearch)) {
                    vm.ttSpin = true;

                    var parms = {};

                    parms[vm.searchParm] = value;

                    vm.ttSearch(parms).then(function (response) {
                        vm.ttSpin = false;

                        deferred.resolve(response);
                    });
                }

                return deferred.promise;
            };

            var searchValue;

            vm.manualSearch = function (timeout) {
                if (angular.isUndefined(timeout) || timeout === true) {
                    $timeout(vm.manualSearch, 0, true, false);
                }

                var ctrl = $element.find('#' + vm.id.input).controller('ngModel');

                searchValue = vm.model;

                ctrl.$setViewValue(' ');

                vm.model = searchValue;

                ctrl.$setViewValue(vm.model);
            };

            vm.setFocus = function () {
                var element = $element.find('#' + vm.id.input)[0];

                if (angular.isUndefined(element))
                    return;

                element.focus();
            };

            vm.clearInput = function () {
                vm.model = '';
                vm.onModelChanged(vm.model);

                if (angular.isFunction(vm.ttOnClear)) {
                    vm.ttOnClear();
                }

                vm.setFocus();
            };

            // JLR 20230628
            vm.onBtnClick = (btn) => ttDirectivesService.onButtonClick({ button: btn, parameter: vm.ttButtonsParm, controller: vm });

            // ODL 20230323
            vm.$onInit = function () {
                setStyle(vm.ttStyle);
            };

            vm.$onChanges = function (changes) {
                // ODL 20230323
                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttModel)) {
                    vm.model = changes.ttModel.currentValue;
                }

                if (angular.isDefined(changes.ttSearchParm) && angular.isDefined(changes.ttSearchParm.currentValue)) {
                    vm.searchParm = changes.ttSearchParm.currentValue;
                }

                if (angular.isDefined(changes.ttSearchId) && angular.isDefined(changes.ttSearchId.currentValue)) {
                    vm.searchId = changes.ttSearchId.currentValue;
                }

                if (angular.isDefined(changes.ttSearchName) && angular.isDefined(changes.ttSearchName.currentValue)) {
                    vm.searchName = changes.ttSearchName.currentValue;
                }

                if (angular.isDefined(changes.ttReadonly) && angular.isDefined(changes.ttReadonly.currentValue)) {
                    //console.dir(changes.ttReadonly);
                    vm.readonly = vm.ttReadonly.toLowerCase() === 'true';
                }

                if (angular.isDefined(changes.ttMinLength) && angular.isDefined(changes.ttMinLength.currentValue)) {
                    vm.minLength = changes.ttMinLength.currentValue;
                }

                if (angular.isDefined(changes.ttWaitMs) && angular.isDefined(changes.ttWaitMs.currentValue)) {
                    vm.waitMs = changes.ttWaitMs.currentValue;
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
