(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButton', {
        templateUrl: 'views/components/directives/ttButton/ttButton.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttText: '@',
            ttTextAlign: '@',
            ttLocked: '<',
            ttLockedText: '@',
            ttClick: '&',
            ttIcon: '@',
            ttIconAlign: '@',
            ttIconPullRight: '@',
            ttBadge: '@',
            ttType: '@',
            ttButtonType: '@',      // the html button type, default is 'button', other options are 'submit' and 'revert'. JLR 20231201
            ttTitle: '@',           // If no text is visible, add title to have a tooltip and to make the button accessible. JLR 20231121
            ttSpin: '<',
            ttDisabled: '<',
            ttItemId: '@',
            ttStyle: '<',
            ttPrinter: '@'
        },
        controller: ['layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function (layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.showPrinter = false;
            vm.showText = false;
            vm.showBadge = false;
            vm.showIconLeft = false;
            vm.showIconRight = true;
            vm.disabled = false;
            vm.spin = false;
            vm.locked = false;
            vm.printer = '';
            vm.buttonType = 'button';

            vm.style = {
                button: {
                    padding: '0 5px',
                    paddingTop: '1px',
                    //color: 'white',
                    position: 'relative',
                },
                lockedButton: {
                    padding: '5px'
                },
                badge: {
                    //color: 'white'
                },
                icon: {
                    //color: 'white',
                    // FIXED IN BOOTSTRAP UPGRADE
                    //position: 'absolute',
                    //top: '50%',
                    //right: '5px',
                    //transform: 'translateY(-50%)',
                }
            };

            vm.class = {
                icon: '',
                noSpin: null,
                button: 'tt-button tt-button--primary'
            };

            vm.translations = {
                text: '',
                lockedText: ''
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onClick = function (e) {
                if (angular.isFunction(vm.ttClick)) {
                    const b = vm.ttClick();
                    if (angular.isFunction(b)) {
                        b(e);
                    }
                    //vm.ttClick()(e);
                }
            };

            let setStyle = (ttStyle = vm.ttStyle) => ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: ['button', 'lockedButton'] });

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);

                //vm.style.button.height = info.height + 'px';
                //vm.style.lockedButton.fontSize = info.fontSizes.textSize;
                //vm.style.badge.fontSize = info.fontSizes.textSizeSs;
                //vm.style.icon.fontSize = info.fontSizes.textSize;
                setStyle(vm.ttStyle);

                if (vm.ttType === 'icon') {
                    vm.style.button.fontSize = info.fontSizes.textSizeL;
                    vm.style.button.height = info.height + 'px';
                    vm.style.button.width = info.height + 'px';
                    vm.style.icon.fontSize = info.fontSizes.textSizeL;
                    vm.style.padding = 'none';
                }

            });

            vm.displayNewGotoButton = function () {
                return vm.ttTextAlign === 'left' && vm.ttType !== 'warning' && vm.ttType !== 'danger' && vm.ttType !== 'success';
            }

            function setButtonType(type) {
                if (!vm.displayNewGotoButton()) {
                    if (type !== 'default') {
                        vm.class.button = 'tt-button tt-button--' + (type ?? 'primary');
                    } else {
                        vm.class.button = 'tt-button tt-button--secondary';
                    }
                    //vm.class.button = 'btn-' + type;

                    //vm.style.button.color = type === 'default' ? 'black' : (type === 'link' ? '' : 'white');
                    //vm.style.icon.color = type === 'default' ? 'black' : (type === 'link' ? 'inherit' : 'white')
                    //vm.style.badge.color = type === 'default' ? 'black' : (type === 'link' ? 'inherit' : 'white')
                } else {
                    vm.class.button = 'tt-button tt-button--link';
                }
            }

            function setIcon(icon) {
                if (vm.displayNewGotoButton() === true && (vm.ttIcon?.includes('fa-chevron-right') || vm.ttIcon.includes('glyphicon-chevron-right'))) {
                    vm.class.icon = 'far fa-arrow-right';
                    vm.showIconLeft = true;
                    vm.showIconRight = !vm.showIconLeft;
                } else if (vm.ttIcon.includes('fa-chevron-right') || vm.ttIcon.includes('glyphicon-chevron-right')) {
                    vm.class.icon = 'far fa-chevron-right';
                } else {
                    vm.class.icon = icon;
                }

                vm.class.noSpin ??= vm.class.icon;
                vm.class.icon = vm.spin === true ? vm.class.icon + ' glyphicon-refresh-animate' : vm.class.noSpin;
            }

            vm.$onInit = function () {
                setButtonType(vm.ttType);
                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                var words = [];

                if (angular.isDefined(changes.ttPrinter) && us.isStringValue(changes.ttPrinter.currentValue, true) && changes.ttPrinter.currentValue !== changes.ttPrinter.previousValue) {
                    if (changes.ttPrinter.currentValue.length > 0) {
                        vm.showPrinter = true;
                        vm.style.button.whiteSpace = 'nowrap';
                        vm.style.button.textOverflow = 'ellipsis';
                        vm.style.button.overflow = 'hidden';
                    } else {
                        vm.showPrinter = false;
                        vm.style.button.whiteSpace = '';
                        vm.style.button.textOverflow = '';
                        vm.style.button.overflow = '';
                    }

                    vm.printer = changes.ttPrinter.currentValue;
                }

                if (angular.isDefined(changes.ttText) && us.isStringValue(changes.ttText.currentValue, true) && changes.ttText.currentValue !== changes.ttText.previousValue) {
                    words.push(changes.ttText.currentValue);

                    vm.showText = true;
                }

                if (angular.isDefined(changes.ttLockedText) && changes.ttLockedText.currentValue !== changes.ttLockedText.previousValue) {
                    var hasLockedText = us.isStringValue(changes.ttLockedText.currentValue, true);

                    if (hasLockedText) {
                        words.push(changes.ttLockedText.currentValue);
                    } else {
                        words.push('locked');
                    }
                }

                if (angular.isDefined(changes.ttTextAlign) && ttDirectivesService.isValidAlign(changes.ttTextAlign.currentValue)) {
                    vm.style.button.textAlign = changes.ttTextAlign.currentValue;
                    vm.style.lockedButton.textAlign = changes.ttTextAlign.currentValue;
                }

                if (angular.isDefined(changes.ttBadge) && us.isStringValue(changes.ttBadge.currentValue, true)) {
                    vm.showBadge = true;
                }

                if (angular.isDefined(changes.ttIcon) && us.isStringValue(changes.ttIcon.currentValue, true)) {
                    setIcon(changes.ttIcon.currentValue);
                    //vm.class.icon = changes.ttIcon.currentValue;

                    //vm.class.noSpin ??= vm.class.icon;

                    //vm.class.icon = vm.spin === true ? vm.class.icon + ' glyphicon-refresh-animate' : vm.class.noSpin; //'far fa-sync-alt' : '';
                }

                if (angular.isDefined(changes.ttIconAlign)) {
                    var iconAlign = us.validateString(changes.ttIconAlign.currentValue, 'right', true);

                    vm.showIconLeft = iconAlign === 'left';
                    vm.showIconRight = !vm.showIconLeft;

                    if (vm.displayNewGotoButton() === true && (!vm.ttIcon || vm.ttIcon?.includes('fa-chevron-right') || vm.ttIcon?.includes('glyphicon-chevron-right'))) {
                        vm.class.icon = 'far fa-arrow-right';
                        vm.showIconLeft = true;
                        vm.showIconRight = !vm.showIconLeft;
                    }
                }

                if (angular.isDefined(changes.ttSpin)) {
                    vm.spin = us.toBoolean(changes.ttSpin.currentValue, false);

                    vm.class.noSpin ??= vm.class.icon;

                    vm.class.icon = vm.spin === true ? vm.class.icon + ' glyphicon-refresh-animate' : vm.class.noSpin; //'far fa-sync-alt' : '';
                }

                if (angular.isDefined(changes.ttLocked)) {
                    vm.locked = us.toBoolean(changes.ttLocked.currentValue, false);
                }

                if (['button', 'submit', 'reset'].includes(changes?.ttButtonType?.currentValue)) {
                    vm.buttonType = changes?.ttButtonType?.currentValue;
                }

                if (angular.isDefined(changes.ttDisabled)) {
                    vm.disabled = us.toBoolean(changes.ttDisabled.currentValue, false);
                }

                if (angular.isDefined(changes.ttType) && us.isStringValue(changes.ttType.currentValue, true)) {
                    setButtonType(changes.ttType.currentValue);
                }

                if (angular.isDefined(changes.ttIconPullRight)) {
                    var pullRight = us.toBoolean(changes.ttIconPullRight.currentValue);

                    if (pullRight && !vm.displayNewGotoButton()) {
                        vm.class.icon += ' tt-button__icon--pull-right';
                        //vm.class.icon += ' pull-right';
                    }
                }

                if (changes.ttStyle?.currentValue) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (words.length > 0) {
                    translateService.translateBatch(words).then(function (response) {
                        angular.forEach(response, function (value, key) {
                            if (key === vm.ttText) {
                                vm.translations.text = value;
                            }

                            if (hasLockedText) {
                                if (key === vm.ttLockedText) {
                                    vm.translations.lockedText = value;
                                }
                            } else {
                                if (key === 'locked') {
                                    vm.translations.lockedText = value;
                                }
                            }
                        });

                        vm.whenReady();
                    });
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
