//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("custactCompAddService", ['$q', '$ihttp', '$stateParams', 'searchService', function ($q, $ihttp, $stateParams, searchService) {
//        var service = {
//            loaded: false,
//            selectingProduct: false,
//            p2CustactCompAdd: {},
//            p2CustactCompAddProduct: {},
//            p2CustactCompGetPrice: {},
//            p2ProductProdsizeList: {
//                records: []
//            },
//            p2ProductProdchoiceList: {
//                records: []
//            },
//            p2ValutaList: {
//                records: []
//            },
//            loadP2CustactCompAdd: function (custact_no, custactcomp_keyno) {
//                var deferred = $q.defer();

//                var parmsP2CustactCompAdd = {
//                    method: 364,
//                    parameters: {
//                        custact_no: custact_no,
//                        custactcomp_keyno: custactcomp_keyno
//                    }
//                };

//                //console.log('parmsP2CustactCompAdd');
//                //console.dir(parmsP2CustactCompAdd);
//                //console.log(angular.toJson(parmsP2CustactCompAdd));

//                // Prosedyre sp_ws_p2_custactcomp_add
//                $ihttp.post(parmsP2CustactCompAdd).then(function (data) {
//                    //console.log('sp_ws_p2_custactcomp_add');
//                    //console.dir(data);
//                    //console.log(angular.toJson(data));

//                    service.p2CustactCompAdd = angular.copy(data[0]);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            loadP2CustactCompAddProduct: function (prod_id) {
//                var deferred = $q.defer();

//                var parmsP2CustactCompAddProduct = {
//                    method: 365,
//                    parameters: {
//                        prod_id: prod_id
//                    }
//                };

//                //console.log('parmsP2CustactCompAddProduct');
//                //console.dir(parmsP2CustactCompAddProduct);
//                //console.log(angular.toJson(parmsP2CustactCompAddProduct));

//                // Prosedyre sp_ws_p2_custactcomp_add_product
//                $ihttp.post(parmsP2CustactCompAddProduct).then(function (data) {
//                    //console.log('sp_ws_p2_custactcomp_add_product');
//                    //console.dir(data);
//                    //console.log(angular.toJson(data));

//                    service.p2CustactCompAddProduct = angular.copy(data[0]);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            loadP2ProductProdsizeList: function (prod_id) {
//                var deferred = $q.defer();

//                var parmsP2ProductProdsizeList = {
//                    method: 135,
//                    parameters: {
//                        prod_id: prod_id
//                    }
//                };

//                //console.log('parmsP2ProductProdsizeList');
//                //console.dir(parmsP2ProductProdsizeList);
//                //console.log(angular.toJson(parmsP2ProductProdsizeList));

//                // Prosedyre sp_ws_p2_product_prodsize_list
//                $ihttp.post(parmsP2ProductProdsizeList).then(function (data) {
//                    //console.log('sp_ws_p2_product_prodsize_list');
//                    //console.dir(data);
//                    //console.log(angular.toJson(data));

//                    angular.copy(data, service.p2ProductProdsizeList.records);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            loadP2ProductProdchoiceList: function (prod_id) {
//                var deferred = $q.defer();

//                var parmsP2ProductProdchoiceList = {
//                    method: 366,
//                    parameters: {
//                        prod_id: prod_id
//                    }
//                };

//                //console.log('parmsP2ProductProdchoiceList');
//                //console.dir(parmsP2ProductProdchoiceList);
//                //console.log(angular.toJson(parmsP2ProductProdchoiceList));

//                // Prosedyre sp_ws_p2_product_prodchoice_list
//                $ihttp.post(parmsP2ProductProdchoiceList).then(function (data) {
//                    //console.log('sp_ws_p2_product_prodchoice_list');
//                    //console.dir(data);
//                    //console.log(angular.toJson(data));

//                    angular.copy(data, service.p2ProductProdchoiceList.records);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            loadP2ValutaList: function () {
//                var deferred = $q.defer();

//                var parmsP2ValutaList = {
//                    method: 373
//                };

//                //console.log('parmsP2ValutaList');
//                //console.dir(parmsP2ValutaList);
//                //console.log(angular.toJson(parmsP2ValutaList));

//                // Prosedyre sp_ws_p2_valuta_list
//                $ihttp.post(parmsP2ValutaList).then(function (data) {
//                    //console.log('sp_ws_p2_valuta_list');
//                    //console.dir(data);
//                    //console.log(angular.toJson(data));

//                    angular.copy(data, service.p2ValutaList.records);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            loadP2CustactCompGetPrice: function (prod_id, prodsize_keyno, prodchoice_keyno, custact_no) {
//                var deferred = $q.defer();

//                var parmsP2CustactCompGetPrice = {
//                    method: 374,
//                    parameters: {
//                        prod_id: prod_id,
//                        prodsize_keyno: prodsize_keyno,
//                        prodchoice_keyno: prodchoice_keyno,
//                        custact_no: custact_no
//                    }
//                };

//                //console.log('parmsP2CustactCompGetPrice');
//                //console.dir(parmsP2CustactCompGetPrice);
//                //console.log(angular.toJson(parmsP2CustactCompGetPrice));

//                // Prosedyre sp_ws_p2_custactcomp_getprice
//                $ihttp.post(parmsP2CustactCompGetPrice).then(function (data) {
//                    //console.log('sp_ws_p2_custactcomp_getprice');
//                    //console.dir(data);
//                    //console.log(angular.toJson(data));

//                    service.p2CustactCompGetPrice = angular.copy(data[0]);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            saveP2CustactCompAdd: function () {
//                var deferred = $q.defer();

//                var validate = function (val1, val2) {
//                    if (angular.isUndefined(val1) === true) return val2;
//                    if (val1 === null) return val2;
//                    if (angular.isString(val1) === false) return val2;
//                    if (val1 === '') return val2;

//                    return val1;
//                }

//                var parmsP2CustactCompAddSave = {
//                    method: 367,
//                    parameters: {
//                        custact_no: $stateParams.custact_no,
//                        custactcomp_keyno: $stateParams.custactcomp_keyno,
//                        prod_id: validate(searchService.custactCompAdd.selectedProdId, service.p2CustactCompAdd.prod_id),
//                        prodname: validate(searchService.custactCompAdd.selectedProdName, service.p2CustactCompAdd.prodname),
//                        prodsize_keyno: service.p2CustactCompAdd.prodsize_keyno,
//                        prodchoice_keyno: service.p2CustactCompAdd.prodchoice_keyno,
//                        quantity: service.p2CustactCompAdd.quantity,
//                        note: service.p2CustactCompAdd.note,
//                        cost_invval: service.p2CustactCompAdd.cost_invval,
//                        cost_locval: service.p2CustactCompAdd.cost_locval,
//                        cost_valuta_id: service.p2CustactCompAdd.cost_valuta_id,
//                        cost_price_invval: service.p2CustactCompAdd.cost_price_invval,
//                        cost_price_locval: service.p2CustactCompAdd.cost_price_locval,
//                        cost_exchangerate: service.p2CustactCompAdd.cost_exchangerate,
//                        cost_exchangefactor: service.p2CustactCompAdd.cost_exchangefactor,
//                        bus_valuta_id: service.p2CustactCompAdd.bus_valuta_id,
//                        ordval_price_brt: service.p2CustactCompAdd.ordval_price_brt,
//                        orderdiscount_pst: service.p2CustactCompAdd.orderdiscount_pst,
//                        ordval_price_net: service.p2CustactCompAdd.ordval_price_net,
//                        ordval_amount_net: service.p2CustactCompAdd.ordval_amount_net,
//                        locval_price_brt: service.p2CustactCompAdd.locval_price_brt,
//                        locval_price_net: service.p2CustactCompAdd.locval_price_net,
//                        locval_amount_net: service.p2CustactCompAdd.locval_amount_net,
//                        order_valuta_id: service.p2CustactCompAdd.order_valuta_id,
//                        ordval_exchangerate: service.p2CustactCompAdd.ordval_exchangerate,
//                        ordval_exchangefactor: service.p2CustactCompAdd.ordval_exchangefactor,
//                        db_locval: service.p2CustactCompAdd.db_locval,
//                        db_pst: service.p2CustactCompAdd.db_pst,
//                        olstatus: service.p2CustactCompAdd.olstatus,
//                        isselected: service.p2CustactCompAdd.isselected,
//                        reg_datetime: service.p2CustactCompAdd.reg_datetime,
//                        regby_portal_user_name: service.p2CustactCompAdd.regby_portal_user_name,
//                        mod_datetime: service.p2CustactCompAdd.mod_datetime,
//                        modby_portal_user_name: service.p2CustactCompAdd.modby_portal_user_name
//                    }
//                };

//                //console.log('parmsP2CustactCompAddSave');
//                //console.dir(parmsP2CustactCompAddSave);
//                //console.log(angular.toJson(parmsP2CustactCompAddSave));

//                // Prosedyre sp_ws_p2_custactcomp_add_save
//                $ihttp.post(parmsP2CustactCompAddSave).then(function () {
//                    //console.log('sp_ws_p2_custactcomp_add_save');
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            deleteP2CustactComp: function () {
//                var deferred = $q.defer();

//                var parmsP2CustactCompDelete = {
//                    method: 370,
//                    parameters: {
//                        custact_no: $stateParams.custact_no,
//                        custactcomp_keyno: $stateParams.custactcomp_keyno
//                    }
//                };

//                //console.log('parmsP2CustactCompDelete');
//                //console.dir(parmsP2CustactCompDelete);
//                //console.log(angular.toJson(parmsP2CustactCompDelete));

//                // Prosedyre sp_ws_p2_custactcomp_delete
//                $ihttp.post(parmsP2CustactCompDelete).then(function () {
//                    //console.log('sp_ws_p2_custactcomp_delete');
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            }
//        }

//        return service;
//    }]);
//})();