(function () {
	'use strict';

	var imApp = angular.module('imApp');

    imApp.component('deliveryMethod', {
        templateUrl: 'views/components/views/deliveryMethod/deliveryMethod.template.html?v=' + imApp.version,
        controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'deliveryMethodService', 'pickJobGrpsService', 'economyService', 'translateService', function ($stateParams, stateService, utilityService, modalService, deliveryMethodService, pickJobGrpsService, economyService, translateService) {
			var vm = this;
			
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

			var saveBeforeGoTo = function () {
				deliveryMethodService.deliveryMethodSave(vm.model.edit).then(function (response) {
					if (response[0].iserror !== '1') {
						modalService.show({
							type: 'warning',
                            title: translations.error,
							message: response[0].message,
							buttons: [{
                                label: translations.ok,
								cssClass: 'btn-warning',
								action: function (dialogItself) {
									dialogItself.close();
								}
							}]
						});
					} else {
                        stateService.go('deliverymethodservices', { deliverymethod_no: vm.model.edit.deliverymethod_no });
					}
				});
			};

			translateService.translateBatch(translations).then(function (data) {
				angular.forEach(translations, function (_, key) {
					if (angular.isDefined(data[key])) {
						translations[key] = data[key];
					}
				});
			});

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'deliverymethodbusinessco':
                        go({ deliverymethod_no: vm.model.edit.deliverymethod_no });
                        break;
                    default:
                        break;
                }
            };

			vm.model = {
				deliverymethod_no: $stateParams.deliverymethod_no,
                deliveryMethodNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('deliverymethodlangs', { deliverymethod_no: vm.model.edit.deliverymethod_no }) }
                ],
                pickJobGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('stockplaces') }
                ],
                pickJobGroupButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.pickjobgrp_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('stockplaces') }
                ],
				transcarrierServicesButtons: [
					{ id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: saveBeforeGoTo }
                ],
				edit: {},
				selectListTransCarriers: [],
				selectListTransCarrierProducts: [],
				selectListPickJobGroups: [],
				selectListDeliveryMethods: [],
                selectListBusinessSites: [],
                lockedSave: false
			};

			vm.deliveryMethodGet = function () {
				deliveryMethodService.deliveryMethodGet(vm.model.deliverymethod_no).then(function (result) {
					angular.copy(result[0], vm.model.edit);
					
					vm.transcarrierProductList();
				});
			};
			
			pickJobGrpsService.listPickJobGroups({ stockplace_id: 'ALL' }).then(function (data) {
				angular.copy(data, vm.model.selectListPickJobGroups);
			});

			economyService.listBusinessSites({ add_all: '1' }).then(function (data) {
				angular.copy(data, vm.model.selectListBusinessSites);
			});

			vm.returnDeliveryMethodsList = function () {
				deliveryMethodService.returnDeliveryMethodsList()
					.then(function (data) {
						angular.copy(data, vm.model.selectListDeliveryMethods);
						vm.deliveryMethodGet();
					});
			};

			vm.transcarrierList = function () {
				deliveryMethodService.transcarrierList().then(function (data) {
					angular.copy(data, vm.model.selectListTransCarriers);
					vm.returnDeliveryMethodsList();
				});
			};

			vm.transcarrierList();

			vm.transcarrierProductList = function () {
				deliveryMethodService.transcarrierProductList(vm.model.edit.transcarrier_keyno).then(function (data) {
					angular.copy(data, vm.model.selectListTransCarrierProducts);
				});
			};

			// uased by HTML
			vm.validateNumber = function (num) {
				var out = 0;
				try {
					out = parseInt(num);
				} catch (e) { }
				return out;
			};

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    deliveryMethodService.deliveryMethodDelete(vm.model.edit.deliverymethod_no).then(function (response) {
                        stateService.go('deliverymethods', {});
                    });
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                deliveryMethodService.deliveryMethodSave(vm.model.edit).then(function (response) {
                    if (response[0].iserror !== '1') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].message,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.go('deliverymethods', {});
                    }
				});
			};

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'transcarrier_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.transcarrier_keyno) !== true) return;

                        vm.model.edit.transcarrier_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.transcarrier_keyno) !== true) return;

                        vm.transcarrierProductList();
                        vm.model.edit.transcarrier_services = '';
                        break;
                    case 'transcarrier_product_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.transcarrier_product_keyno) !== true) return;

                        vm.model.edit.transcarrier_product_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.transcarrier_product_keyno) !== true) return;

                        vm.model.edit.transcarrier_services = '';
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

        }]
    });
})();
