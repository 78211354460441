(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('actStatus', {
        templateUrl: 'views/components/views/actStatus/actStatus.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'translateService', 'modalService', 'actStatusService', 'skillsCertificateTypeService', 'rememberService', function ($stateParams, stateService, utilityService, translateService, modalService, actStatusService, skillsCertificateTypeService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let actStatusKeyno = $stateParams.actstatus_keyno;

            let variableNames = {
                actstatus_name: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                glyphiconButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-left', color: 'primary', type: 'primary', onClick: () => goToFunc('selectglyphicon') }
                ],
                edit: {},
                selectListGlyphicons: [],
                selectListHexColours: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## LOAD PROCEDURE CALLS ##

            async function loadEdit() {
                vm.model.edit = (await actStatusService.getEdit({ actstatus_keyno: actStatusKeyno }))[0];
                loadGlyphicons();
                loadHexColors();
            }

            let loadGlyphicons = function () {
                skillsCertificateTypeService.listGlyphicons().then(function (result) {
                    angular.copy(result, vm.model.selectListGlyphicons);
                    //vm.model.selectListGlyphicons[0].item_name = vm.model.selectListGlyphicons[0].item_name + ' &#xf640;';
                });
            };

            let loadHexColors = function () {
                skillsCertificateTypeService.listHexColors().then(function (result) {
                    angular.copy(result, vm.model.selectListHexColours);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'actstatus_name':
                        if (utilityService.validateParmsValue(vm.model.edit[id], true) !== true) return;

                        variableValue = vm.model.edit[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // ## SHOW GLYPHICON SYMBOL FUNCTION ##

            vm.showGlyphiconSymbol = function (value) {
                angular.forEach(vm.model.selectListGlyphicons, function (glyph) {
                    if (vm.model.edit.glyphicon === glyph.item_id) {
                        vm.model.edit.glyphicon = glyph.item_glyphicon;

                        showColour();
                    }
                });
            };

            // ## SHOW COLOUR FUNCTION ##

            vm.showColour = function (value) {
                vm.model.edit.glyphicon_color = value;
                angular.forEach(vm.model.selectListHexColours, function (hex) {
                    if (vm.model.edit.glyphicon_color === hex.hexcolor) {
                        vm.model.edit.glyphicon_color = hex.hexcolor;
                    }
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            let goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectglyphicon':
                        rememberFunc('actstatus_name');
                        go();
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                actStatusService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
