import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forEach } from 'angular';
import { Observable, from, lastValueFrom, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { AppConfigService } from '../../services/appconfig.service';
import { AppSettingsService } from '../services/app-settings.service';
import { StateService } from '../services/state.service';

interface ErrorObject {
    status: number,
    title: string,
    detail: string,
    loginUrls: LoginUrls,
    userlink: string
}

interface LoginUrls {
    Login: string;
    UserLink: string;
    Credentials: string;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private appConfig: AppConfigService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 403) {
                    let data: ErrorObject | null;

                    if (typeof error.error === 'string') {
                        try {
                            data = JSON.parse(error.error);
                        } catch (e) {
                            console.error('Failed to parse error response JSON', e);
                            data = null;
                        }
                    } else {
                        data = error.error;
                    }

                    // Get the current URL
                    const currentUrl = window.location.href;

                    const parts = currentUrl.split('/');

                    const index = parts.indexOf('mainmenu');

                    var returnUrl = currentUrl;

                    let href = '/.auth/login';

                    if (data !== null) {
                        if (parts.length === index + 4) {
                            if (parts[index + 1] === 'userlink') {
                                // userlink login
                                href = data.loginUrls.UserLink + '/' + parts[index + 2] + '/' + parts[index + 3];
                            } else {
                                // assume this is login with credentials from app
                                href = data.loginUrls.Credentials + '/' + parts[index + 1] + '/' + parts[index + 2] + '/' + parts[index + 3];
                            }

                            parts.splice(index + 1, 3);
                            parts[1] = '/';
                            returnUrl = parts.join('/');
                        } else {
                            // "normal" login
                            href = data.loginUrls.Login;
                        }
                    }

                    const encodedUrl = encodeURIComponent(returnUrl);

                    href = `${href}?returnUrl=${encodedUrl}&csi=${this.appConfig.clientSessionId}`;

                    // Redirect the browser to the login page
                    window.location.href = href
                }
                return throwError(() => error);
            })
        );
    }
}
