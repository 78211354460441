(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinish', {
        templateUrl: 'views/components/views/poFinish/poFinish.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishService', 'stateService', function ($stateParams, poFinishService, stateService) {
            var vm = this;
            
            poFinishService.loadP2PoFinishGet($stateParams.p2_pofinish_keyno).then(function () {
                vm.p2_pofinish_get = poFinishService.p2_pofinish_get;
            });
            
            vm.goToPoFinishProdList = function (index) {
                stateService.go('pofinishprodlist', {
                    p2_pofinish_keyno: $stateParams.p2_pofinish_keyno
                });
            };
            
            vm.goToPoFinishCompList = function (index) {
                stateService.go('pofinishcomplist', {
                    p2_pofinishprod_keyno: vm.p2_pofinish_get.p2_pofinishprod_keyno
                });
            };
        }]
    });
})();