<button
    [attr.id]="id.button"
    [ngClass]="{
        'tt-overflow-menu__button--open': !ttType && open,
        'tt-overflow-menu__button': !ttType,
        'tt-button--primary': ttType === 'primary',
        'tt-button--secondary': ttType === 'secondary',
        'tt-button--success': ttType === 'success',
        'tt-button--danger': ttType === 'danger',
        'tt-button--warning': ttType === 'warning',
    }"
    class="tt-button tt-overflow-menu__trigger"
    (click)="toggleMenu()"
    [ngStyle]="style['button']"
    aria-label="Menu"
    aria-haspopup="menu"
    ttOutsideClick
    (onOutsideClick)="open = false"
    [attr.aria-expanded]="open"
>
    <span [ngStyle]="style['icon']" class="fa-fw far" [ngClass]="ttIcon ? ttIcon : 'fa-ellipsis-v'"></span>
    <ul *ngIf="open" class="tt-overflow__menu">
        <ng-content></ng-content>
    </ul>
</button>
