(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('brReg', {
		templateUrl: 'views/components/views/brReg/brReg.template.html?v=' + imApp.version,
		controllerAs: 'vm',
        controller: ['utilityService', 'base64', 'brRegService', function (utilityService, base64, brRegService) {
			
            // ## VARIABLES & DEFINITIONS ##

			var vm = this;

			vm.model = {
				companyNameSearchBtnList: [
					{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
					{ item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
				],
				naeringsKodeBtnList: [
					{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
				],
				naeringsKodeBtnList2: [
					{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
				],
				countyButtons: [
					{ id: 'erase', icon: 'glyphicon glyphicon-erase', type: 'danger', onClick: () => clearCounty() }
				],
				communityIdBtnList: [
					{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
				],
				bronnoysundRegister: {},
				selectListNaces: [],
				selectListCountiesNo: [],
				selectListCommunities: [],
				itemsListBrRegCompanies: [],
				searching: false,
				loading: false
			};

			// ## LOAD PROCEDURE CALLS ##

			brRegService.loadBrRegGet().then(function (data) {
				vm.model.bronnoysundRegister = angular.copy(data[0]);

				loadCommunities();
				vm.executeBrReg();
			});

			brRegService.loadNaceSelectList().then(function (data) {
				angular.copy(data, vm.model.selectListNaces);
			});

			brRegService.loadCountyNoList().then(function (data) {
				angular.copy(data, vm.model.selectListCountiesNo);
			});

			let loadCommunities = function () {
                if (utilityService.validateParmsValue(vm.model.bronnoysundRegister.county_id) !== true) return;

				return brRegService.loadCommunitySelectList(vm.model.bronnoysundRegister.county_id).then(function (data) {
					angular.copy(data, vm.model.selectListCommunities);
				});
			};

			// ## BUTTON LIST FUNCTIONS ##

			vm.companyNameSearchBtnListFunc = function (item_func) {
				if (item_func === 'erase_item') {
					vm.model.bronnoysundRegister.companyname_search = '';
					vm.model.itemsListBrRegCompanies = [];
				} else if (item_func === 'search_item') {
					vm.executeBrReg();
				}
			};

			vm.naeringsKodeBtnListFunc = function (item_func) {
				if (item_func === 'erase_item') {
					vm.model.bronnoysundRegister.naeringskode = '';
				}
			};

			vm.naeringsKodeBtnListFunc2 = function (item_func) {
				if (item_func === 'erase_item') {
					vm.model.bronnoysundRegister.naeringskode = '';
				}
			};

			function clearCounty() {
				vm.model.bronnoysundRegister.county_id = '';
				vm.model.bronnoysundRegister.comunity_id = '';
				vm.model.selectListCommunities = [];
			}

			vm.onCountyChanged = function (value) {
				vm.model.bronnoysundRegister.county_id = value;

				if (value) loadCommunities();
			}

			vm.communityIdBtnListFunc = function (item_func) {
				if (item_func === 'erase_item') {
					vm.model.bronnoysundRegister.comunity_id = '';
				}
			};

			// ## EXECUTE FUNCTIONS ##

			vm.getUrl = function () {
				brRegService.loadBrRegUrlGet(vm.model.bronnoysundRegister).then(function (data) {
					vm.model.loading = true;

					var searchUrl = data[0].search_url;
					var xhr = new XMLHttpRequest();

					xhr.open('GET', searchUrl, true);
					xhr.send();
					xhr.onreadystatechange = processRequest;

					function processRequest(e) {
						if (xhr.readyState == 4 && xhr.status == 200) {
							var response = xhr.responseText;
							vm.model.itemsListBrRegCompanies = [];

							brRegService.loadBrRegCompaniesList(base64.urlencode(response)).then(function (data) {
								angular.copy(data, vm.model.itemsListBrRegCompanies);
								vm.model.loading = false;
							});
						}
					}
				});
			};

			vm.executeBrReg = function () {
				vm.model.searching = true;

				var companySearch = 'https://data.brreg.no/enhetsregisteret/api/enheter?navn=' + vm.model.bronnoysundRegister.companyname_search;
				var xhr = new XMLHttpRequest();

				//xhr.open('GET', "https://ipinfo.io/json", true);
				xhr.open('GET', companySearch, true);
				xhr.send();
				xhr.onreadystatechange = processRequest;

				function processRequest(e) {
					if (xhr.readyState == 4 && xhr.status == 200) {
						var response = xhr.responseText;
						vm.model.itemsListBrRegCompanies = [];

						brRegService.loadBrRegCompaniesList(base64.urlencode(response)).then(function (data) {
							angular.copy(data, vm.model.itemsListBrRegCompanies);
							vm.model.searching = false;
						});
					}
				}
			};
		}]
	});
})();
