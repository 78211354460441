import { Component, ElementRef, Inject, OnInit, Pipe, PipeTransform, ViewChild, ViewEncapsulation, ViewRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GridModalData } from '@app/core/components/grid/modals/grid-modal.service';
import { Style } from '@app/core/services/core-component.service';
import { DataTaskService } from '@app/core/services/data-task.service';
import { LayoutService } from '@app/core/services/layout.service';
import { ModalService } from '@app/core/services/modal.service';
import { RememberService } from '@app/core/services/remember.service';
import { StateService } from '@app/core/services/state.service';
import { TranslateService } from '@app/core/services/translate.service';
import { GridBookingComponent } from '@app/modal/grid-booking/grid-booking.component';

@Component({
    selector: 'tt-bank-reconciliation',
    templateUrl: './bank-reconciliation.component.html',
    styleUrls: ['./bank-reconciliation.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class BankReconciliationComponent implements OnInit {
    model: BankReconciliationModel = {
        difference: 0,
        difference_label: '0,00',
        sum_selected_rows_accounting: 0,
        sum_selected_rows_accounting_label: '0,00',
        sum_selected_rows_bank: 0,
        sum_selected_rows_bank_label: '0,00',
        get: null,
        fetchingStatements: false,
        processingMatch: false,
        resettingMatch: false,
    };

    accountingRecords: AccountingRecord[] = [];

    bankTransactions: BankStatement[] = [];

    style: Style = {
        differenceLabel: {},
        warning: {},
        listHeading: { margin: '0', padding: '0', marginBottom: '1.6rem' },
    };

    public translations: Record<string | symbol, string> = {
        selected_rows_accounting: '',
        selected_rows_bank: '',
        difference: '',
        potential_match_indicator_right_side: '',
        potential_match_indicator_left_side: '',
    };

    constructor(private dialog: MatDialog, private layout: LayoutService, private datatask: DataTaskService, private state: StateService, private remember: RememberService, private translateService: TranslateService, private modal: ModalService) {
        this.layout.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['warning'].fontSize = info.fontSizes.textSize;
                this.style['differenceLabel'].fontSize = info.fontSizes.textSize;
                this.style['listHeading'].fontSize = info.fontSizes.textSizeL;
            }
        });
    }

    /**
     * Opens the grid booking modal.
     */
    public openGridBooking() {
        const dialogref = this.dialog.open(GridBookingComponent, {
            panelClass: 'modal-pst-ninetyfive',
            data: <GridModalData>{
                rememberId: 'w_bankreconciliation',
                loadData: {
                    method: 3376,
                    parameters: {
                        ...this.model.get,
                        bankrecords: this.bankTransactions.filter((item) => item.is_selected),
                        accountingrecords: this.accountingRecords.filter((item) => item.is_selected),
                    },
                },
                selectedRows: [],
                row: this.model.get,
            },
        });

        dialogref.afterClosed().subscribe((result) => {
            if (result?.errorcode === '0') {
                this.getStatements();
            }
        });
    }

    @ViewChild('bankTransactionsList')
    public bankTransactionsList?: ElementRef<HTMLDivElement>;

    @ViewChild('accountingRecordsList')
    public accountingRecordsList?: ElementRef<HTMLDivElement>;

    /**
     * Remembers the value of the show transaction details records.
     */
    public async rememberShowTransactionDetails() {
        if (!!this.model.get?.show_transaction_details) {
            this.remember.remember('w_bankreconciliation.show_transaction_details', this.model.get.show_transaction_details);
        }
    }

    public clearHighlights() {
        this.accountingRecords.forEach((value) => (value.highlight = false));
        this.bankTransactions.forEach((value) => (value.highlight = false));
    }

    public setHighlight(statement: BankStatement | AccountingRecord) {
        if (statement.is_selected !== true) {
            statement.highlight = true;
        }
    }

    public setHighlights() {
        this.accountingRecords.filter((value) => (+value.amount_locval).toFixed() === this.model.difference.toFixed()).forEach(this.setHighlight);

        this.bankTransactions.filter((value) => (+value.Amount).toFixed() === (-this.model.difference).toFixed()).forEach(this.setHighlight);
    }

    /**
     * Calculates the sum of the selected accounting records.
     */
    public sumAccountingRows() {
        this.model.sum_selected_rows_accounting = this.getAccountingRecordsSelectedSum();
        this.model.sum_selected_rows_accounting_label = this.formatNumber(this.model.sum_selected_rows_accounting);
        this.getDifference();
    }

    /**
     * Calculates the sum of the selected bank statements.
     */
    public sumBankRows() {
        this.model.sum_selected_rows_bank = this.getBankTransactionsSelectedSum();
        this.model.sum_selected_rows_bank_label = this.formatNumber(this.model.sum_selected_rows_bank);
        this.getDifference();
    }

    /**
     * Calculates the difference between selected bank statements and selected accounting records.
     */
    private getDifference() {
        this.model.difference = this.roundNumber(this.model.sum_selected_rows_bank - this.model.sum_selected_rows_accounting, 2);
        this.model.difference_label = this.formatNumber(this.model.difference);
    }

    /**
     * Calculates the sum of all selected accounting records and rounds the number to two decimals.
     *
     * @returns a number representing the sum of all selected accounting records.
     */
    private getAccountingRecordsSelectedSum(): number {
        return this.roundNumber(
            this.accountingRecords.filter((item) => item.is_selected === true).reduce((acc, item) => Number(item.amount_locval) + acc, 0),
            2
        );
    }

    /**
     * Calulates the sum of all selected bank transactions and rounds the number to two decimals.
     *
     * @returns a number representing the sum of all selected bank transactions.
     */
    private getBankTransactionsSelectedSum(): number {
        return this.roundNumber(
            this.bankTransactions.filter((item) => item.is_selected === true).reduce((acc, item) => Number(item.Amount) + acc, 0),
            2
        );
    }

    /**
     * Rounds the given number to the given number of decimals.
     *
     * @param number the number to round.
     * @param decimals the number of decimals to round to, default is none.
     * @returns the rounded number.
     */
    private roundNumber(number: number, decimals: number = 1) {
        const factor = 10 ** decimals;

        return Math.round(number * factor) / factor;
    }

    /**
     * Formats the given number and returns the string representation of the number in norwegian locale.
     *
     * @param number the number to format.
     * @returns string representation of the number according to norwegian locale.
     */
    private formatNumber(number: number): string {
        const formatter = new Intl.NumberFormat('nb-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        return formatter.format(number);
    }

    /**
     * Resets matches, only to use for developing purposes, not in production.
     */
    public async resetMatch() {
        try {
            this.model.resettingMatch = true;
            const response = await this.modal.openConfirmDialog({ type: 'danger', message: 'are_you_sure', title: 'confirm_reset_match' });

            if (response === true) {
                this.datatask.Post(3382, { ...this.model.get });
                this.getStatements();
            }
        } finally {
            this.model.resettingMatch = false;
        }
    }

    /**
     * Processes the match of the currently selected rows.
     */
    public async processMatch() {
        try {
            this.model.processingMatch = true;

            const response = (
                await this.datatask.Post(3380, {
                    ...this.model.get,
                    bankrecords: this.bankTransactions.filter((item) => item.is_selected),
                    accountingrecords: this.accountingRecords.filter((item) => item.is_selected),
                })
            )[0];

            if (response.errorcode !== '0') {
                this.modal.openConfirmDialog({ type: 'warning', title: '', message: response.errormessage });
            } else {
                this.accountingRecords = this.accountingRecords.filter((item) => item.is_selected !== true);
                this.bankTransactions = this.bankTransactions.filter((item) => item.is_selected !== true);
                this.selectPotentialMatch();
                this.sumAccountingRows();
                this.sumBankRows();
            }
        } finally {
            this.model.processingMatch = false;
        }
    }

    /**
     * Translates the translations object.
     */
    private async translate() {
        const response = await this.translateService.translateBatch(Object.keys(this.translations));

        Object.keys(this.translations).forEach((key) => {
            if (response[key]) {
                this.translations[key] = response[key];
            }
        });
    }
    filterAccountingRecords(event: string) {}
    filterBankTransactions(event: string) {}
    /**
     * Checks whether match button should be displayed. If the difference is 0 and atleast 1 item is selected, does not need to be selected on both sides, then match should show.
     *
     * @returns `true` if the match button should show, false if not.
     */
    public checkShowMatch() {
        if ((this.bankTransactions.filter((item) => item.is_selected === true).length > 0 || this.accountingRecords.filter((item) => item.is_selected === true).length > 0) && this.model.difference === 0) {
            return true;
        }
        return false;
    }

    public checkAccountingRecordsHighlight() {
        return this.accountingRecords.some((record) => record.highlight === true);
    }

    public checkBankTransactionsHighlight() {
        return this.bankTransactions.some((statement) => statement.highlight === true);
    }

    /**
     * Sets the first set of potential matches as selected in bank statements and accounting records.
     */
    private selectPotentialMatch() {
        if (this.bankTransactions[0].potential_acledger_keyno !== '0') {
            const potentialAccountRecord = this.accountingRecords.find((item) => item.acledger_keyno === this.bankTransactions[0].potential_acledger_keyno);

            if (potentialAccountRecord) {
                this.bankTransactions[0].is_selected = true;
                potentialAccountRecord.is_selected = true;
            }
        }
    }

    /**
     * Retrieves bank statements and accounting records, then refreshes sums and potential matches.
     */
    public async getStatements() {
        try {
            this.model.fetchingStatements = true;

            await Promise.all([this.getAccountingRecords(), this.getBankTransactions()]);

            this.selectPotentialMatch();
        } finally {
            this.sumAccountingRows();
            this.sumBankRows();
            this.model.fetchingStatements = false;
        }
    }

    /**
     * Retrieves the new balance.
     */
    public async getBalance() {
        const response = (await this.datatask.Post(3383, { ...this.model.get }))[0];

        this.model.get = { ...this.model.get, ...response };
        this.getStatements();
    }

    /**
     * Retireves accounting records based of the current get.
     */
    private async getAccountingRecords() {
        if (this.model.get !== null) {
            this.accountingRecords = (await this.datatask.Post(3377, this.model.get)).map((record: AccountingRecord) => ({ ...record, is_selected: false, highlight: false }));
        }
    }

    /**
     * Retriees bank transactions based of the current get.
     */
    private async getBankTransactions() {
        if (this.model.get !== null) {
            this.bankTransactions = (await this.datatask.Post(3378, this.model.get)).map((statement: BankStatement) => ({ ...statement, is_selected: false, highlight: false }));
        }
    }

    /**
     * Retrieves initial information for the page.
     */
    private async getPage() {
        this.model.get = (await this.datatask.Post(3376, { businessco_bankaccount_keyno: this.state.getStateParams()['businessco_bankaccount_keyno'] }))[0];
    }

    async ngOnInit(): Promise<void> {
        this.translate();
        await this.getPage();
        this.getBalance();
    }
}

interface BankStatement {
    /**
     * Unique key for statement. Type is string but value in string is number.
     */
    statement_keyno: string;

    /**
     * The valuta of the statement..
     */
    AccountCurrency: string;

    /**
     * Archive reference of the bank statement. Type is string but value in string is number.
     */
    ArchiveReference: string;

    /**
     * The amount of the bank statement. Type is string but value in string is number.
     */
    Amount: string;

    /**
     * The description provided for the bank statement.
     */
    Description: string;

    /**
     * The booking date of the bank statement.
     */
    BookingDate: string;

    /**
     * The value date of the bankstatement.
     */
    ValueDate: string;

    /**
     * Transaction count of the bank statement.
     */
    TransactionCount: string;

    /**
     * Transaction details of the bank statement.
     */
    transaction_details: string;

    /**
     * Order value of the list, type is string but the value is number.
     */
    orderby: string;

    /**
     * Whether the bank statement is selected or not.
     */
    is_selected: boolean;

    /**
     * The acledger keyno of the potential match.
     */
    potential_acledger_keyno: string;

    /**
     * Number of potential matches with this bank statement.
     */
    potential_match: string;

    highlight: boolean;
}

interface AccountingRecord {
    /**
     * Unique key for accounting record. Type is string but value is number.
     */
    acledger_keyno: string;

    /**
     * The amount of the record in local valuta. Type is string but value is number.
     */
    amount_locval: string;

    /**
     * The amount of the record in the records valuta. Type is string but value is number.
     */
    amount_invval: string;

    /**
     * The valuta of the accoutning record.
     */
    valuta_id: string;

    /**
     * The date of the accounting record.
     */
    transdate: string;

    /**
     * Description of the accounting record.
     */
    note: string;

    /**
     * Transaction details of the accounting record.
     */
    transaction_details: string;

    /**
     * The voucher id the accounting record in the connected with.
     */
    voucher_no: string;

    /**
     * Order value of the list, type is string but the value is number.
     */
    orderby: string;

    /**
     * Number of potential matches in the bank statements.
     */
    potential_match: '1';

    /**
     * Whether the bank statement is selected or not.
     */
    is_selected: boolean;

    highlight: boolean;
}

interface BankReconciliationModel {
    get: BankReconciliationGet | null;

    /**
     * The number value of the difference between bank statements and accounting records.
     */
    difference: number;

    /**
     * String representation of the difference number.
     */
    difference_label: string;

    /**
     * Sum of the selected bank statements as number.
     */
    sum_selected_rows_bank: number;

    /**
     * Sum of the selected accounting records as number.
     */
    sum_selected_rows_accounting: number;

    /**
     * String representation of the sum of selected bank statements.
     */
    sum_selected_rows_bank_label: string;

    /**
     * String representation of the sum of selected accounting records.
     */
    sum_selected_rows_accounting_label: string;

    /**
     * Whether bank statements and accounting records are currently being fetched.
     */
    fetchingStatements: boolean;

    /**
     * Whether match is currently processing.
     */
    processingMatch: boolean;

    /**
     * Whether matches are currently being reset.
     */
    resettingMatch: boolean;
}

interface BankReconciliationGet {
    /**
     * The business/company bankaccount id.
     */
    businessco_bankaccount_keyno: string;

    /**
     * The name of the bank account.
     */
    bankaccount_name: string;

    account_no_ocr: string;

    /**
     * The bank account, type is string but value is number.
     */
    bankaccount: string;

    /**
     * The business/company id. Type is string but value is number.
     */
    businessco_no: string;

    /**
     * The name of the business/company.
     */
    company_name: string;

    /**
     * The bank balance. Type is string but value is number.
     */
    balance_bank: string;

    /**
     * The accounting balance. Type is string but value is number.
     */
    balance_acledger: string;

    /**
     * The balance date.
     */
    balance_date: string;

    /**
     * The account number and name.
     */
    account_no_and_name: string;

    /**
     * Whether transaction details should be displayed in the accounting records list.
     */
    show_transaction_details: '1' | '0';

    /**
     * Accounting bank fees, type is string but value is number.
     */
    account_no_bank_fees: number;

    /**
     * Whether the reset match button should show.
     */
    show_reset_match: '1' | '0';

    /**
     * Warning message to display if any.
     */
    warning_message: '';

    bankreconciliation_start_date: '2024-09-13';

    searchtext_bank: string;

    searchtext_acledger: string;
}

@Pipe({
    name: 'ttBankReconciliationFilter',
    pure: false,
})
export class BankReconciliationFilter implements PipeTransform {
    transform<TType = BankStatement | AccountingRecord>(statements: TType[], filter?: string): TType[] {
        if (!filter) return statements;

        return statements.filter((statement: TType) => {
            // @ts-ignore
            const match = Object.values(statement).find((value) => `${value}`.toLowerCase().includes(filter.toLowerCase()));

            return !!match;
        });
    }
}
