(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('paperLessPo', {
        templateUrl: 'views/components/views/paperLessPo/paperLessPo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'stateService', 'paperLessPoService', function (utilityService, stateService, paperLessPoService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            vm.model = {
                btnListOrders: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'primary' }
                ],
                btnListProductOrders: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'primary' }
                ],
                setting: {},
                searchingOrders: false,
                searchingProductOrders: false,
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) { };

		    // #endregion VARIABLES & DEFINITIONS

            // #region PROCEDURE CALL & RETRIEVE
            
            paperLessPoService.getPaperLessPo().then(function (response) {
                angular.copy(response[0], vm.model.setting);

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
		        	vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

		        if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
		        	vm.grid.dataTask.loadData = {
		        		method: vm.model.setting.p2_datatask_keyno,
                        parameters: vm.model.setting
		        	};
		        }

		        vm.model.gridReady = true;
            });

            // #endregion PROCEDURE CALL & RETRIEVE

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncOrders = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.ordersearch = '';
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'search_item':
                        vm.model.searchingOrders = true;

                        vm.grid.gridfunc.rebind().then(function () {
                            vm.model.searchingOrders = false;
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.btnListFuncProductOrders = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.prodsearch = '';
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'search_item':
                        vm.model.searchingProductOrders = true;

                        vm.grid.gridfunc.rebind().then(function () {
                            vm.model.searchingProductOrders = false;
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion BUTTON LIST FUNCTIONS

            vm.searchOrders = function () {
                vm.model.searchingOrders = true;

                vm.grid.gridfunc.rebind().then(function () {
                    vm.model.searchingOrders = false;
                });
            };

            vm.searchProductOrders = function () {
                vm.model.searchingProductOrders = true;

                vm.grid.gridfunc.rebind().then(function () {
                    vm.model.searchingProductOrders = false;
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'pocreate':
                        stateService.go(state, {
                            stock_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();