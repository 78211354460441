(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srEquipments', {
        templateUrl: 'views/components/views/srEquipments/srEquipments.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srEquipmentsService', function($stateParams, stateService, utilityService, srEquipmentsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceId: $stateParams.resource_id,
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                settingEquipment: {},
                itemsListEquipments: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srEquipmentsService.loadSrEquipmentsGet(vm.model.resourceId).then(function (data) {
                angular.copy(data[0], vm.model.settingEquipment);
    
                loadSrEquipments();
            });
    
            var loadSrEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.settingEquipment.resource_id) !== true) return;
    
                srEquipmentsService.loadSrEquipmentsList(vm.model.settingEquipment.resource_id).then(function (data) {
                    angular.copy(data, vm.model.itemsListEquipments);
                });
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.settingEquipment.searchtext = '';
                        break;
                    default:
                        break;
                }
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srequipment':
                        go({
                            resource_id: vm.model.settingEquipment.resource_id,
                            srequipment_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();