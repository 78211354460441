(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deviationTypeMenu', {
        templateUrl: 'views/components/views/deviationTypeMenu/deviationTypeMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'deviationTypeMenuService', function ($stateParams, stateService, modalService, translateService, deviationTypeMenuService) {
    
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let deviationTypeKeyno = $stateParams.deviationtype_keyno;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                deviationTypeNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('deviationtypelangs', { deviationtype_keyno: vm.model.menu.deviationtype_keyno }) }
                ], 
                menu: {},
                lockedDelete: false
            };

            // #region TRANSLATION FUNCTION

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

		    // #endregion TRANSLATION FUNCTION

            // ## LOAD PROCEDURE CALLS ##

            let loadMenu = function () {
                deviationTypeMenuService.getDeviationTypeMenu({ deviationtype_keyno: deviationTypeKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'deviationtype':
                        go({ deviationtype_keyno: vm.model.menu.deviationtype_keyno });
                        break;
                    case 'activitytypedevtypes':
                        go({
                            argtype: 'deviationtype_keyno',
                            argvalue: vm.model.menu.deviationtype_keyno
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                deviationTypeMenuService.deleteItem({ deviationtype_keyno: vm.model.menu.deviationtype_keyno }).then(function (response) {
                	if (response[0].errorcode !== '0') {
                		modalService.show({
                            type: 'warning',
                			title: translations.error,
                			message: response[0].errormessage,
                			buttons: [{
                				label: translations.ok,
                                cssClass: 'btn-warning',
                				action: function (dialogItself) {
                					dialogItself.close();
                					vm.model.lockedDelete = false;
                				}
                			}]
                		});
                	} else {
                		vm.model.lockedDelete = false;
                        stateService.back();
                	}
                });
            };

		    // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
