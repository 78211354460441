(function () {
    'use strict';

    var module = angular.module('imApp');

    module.directive('imGanttDirective', ['$ihttp', '$stateParams', '$timeout', '$compile', 'userService', 'stateService', function ($ihttp, $stateParams, $timeout, $compile, userService, stateService) {
        var directive = {
            restrict: 'E',
            //transclude: true,
            scope: {
                field: '@',         //Used to determine the field to use as primary value
                chartType: '=',     //Used to change the chart type
                listItems: '=',     //Used for linking a list to the directive
                //listResource: '=',  //Used for linking resources to the directive
                optionfunc: '&',    //Used as reference to funtion in Controller
                //gotofunc: '&',      //Used as reference to goto function in Controller
            },
            template:
                    //'<hr>' +
                    //'<div>' +
                        '<div id="{{model.mId}}" kendo-gantt ' +
                            'k-options="ganttOptions">' +
                            //'k-rebind="model.ganttDataSource.length">' +
                        '</div>' +
                    //'</div>' +
                    //'<im-input-directive plabel="Test" field="note" type="input" typevar="r"></im-input-directive>' +
                    //'<im-btn-group-directive list-items="work_details_group.records" optionfunc="selectWorkDetailsView(item)" class="col-xs-12"></im-btn-group-directive>'
                    '<im-btn-group-directive list-items="model.toolBtnGrp" active-btn="model.active" optionfunc="ganttFunc(item)" class="col-xs-12"></im-btn-group-directive>' +
                    '<im-button-directive name="save" optionfunc="saveGantt()" glyphs="glyphicon-save" locked="model.locked" btnstyle="btn-success" btnsize="col-xs-12 im-no-pad"></im-button-directive>' + 
                    '<im-button-directive pname="model.selectedName" optionfunc="goTo()" locked="model.blocked" btnsize="col-xs-12 im-no-pad" ng-hide="model.blocked"></im-button-directive>',
                    //'<div class="well well-sm col-xs-12">' +
                    //'</div>',
            link: function ($scope, $element, $attr) {
                $scope.model = {
                    mId: uuid(),
                    active: '0',
                    activeGrp: '',
                    ganttDataSource: null,
                    ganttHeight: (window.innerHeight-240),
                    locked: true,
                    blocked: true,
                    lWidth: '20%',
                    selectedId: null,
                    selectedName: null,
                    firstDate: '01/01/2015',
                    lastDate: '01/01/2018',
                    hasData: false,
                    predefNumCols: 2,
                    toolBtnGrp: [],
                    toolCols: [],
                    //toolBtnGrp: [{ item_name: 'test' }, { item_name: 'test2' }]
                };

                //console.log('imGanttDirective');

                $scope.onChange = function (e) {
                    var gantt = e.sender;
                    var selection = gantt.select();

                    if (selection.length) {
                        var dataItem = gantt.dataItem(selection);
                        //console.log("Gantt selection change :: " + dataItem.title + ' ID: ' + dataItem.id);
                        $scope.model.selectedId = dataItem.id;
                        $scope.model.selectedName = dataItem.title;
                        $scope.model.blocked = false;
                    } else {
                        $scope.model.selectedId = null;
                        $scope.model.selectedName = null;
                        $scope.model.blocked = true;
                    }
                }

                $scope.onAdd = function (e) {
                    console.log("Task added: " + e.task.title);
                    $scope.model.locked = false;
                }

                $scope.onEdit = function (e) {
                    console.log("Task about to be edited :: " + e.task.title);
                }

                $scope.onCancel = function (e) {
                    console.log("Cancel task edit :: " + e.task.title);
                }

                $scope.onRemove = function (e) {
                    console.log("Task removed :: " + e.task.title);
                }

                $scope.onSave = function (e) {
                    console.log("Task saved :: " + e.task.title); //Saved
                    $scope.model.locked = false;
                }

                $scope.onDataBound = function (e) {
                    console.log("Gantt data bound");
                    //console.dir($scope.model.toolCols);
                    //console.log('THIS: ');
                    //console.dir(this);

                    //console.log('HEIGHT: ' + window.innerHeight);

                    var height = this.timeline.view()._slots.length * 2.5;
                    this.list.header.find("tr").height(height + "em");

                    var header = $($('#' + $scope.model.mId).find('.k-grid-header-wrap')[0]).find('th');

                    for (var i = 2; i < header.length; i++) {
                        //var headerText = header[i].innerText;     //Was used before, changed to line below.
                        var headerText = header[i].textContent;

                        header[i].innerHTML = '<span class="verticalText" ng-show="model.toolCols[' + (i - 2) + '].colgroup == model.activeGrp">' + headerText + '</span>';

                        $compile(header[i])($scope);
                    }

                    //var body = $($('#' + $scope.model.mId).find('.k-grid-content')[0]).find('td');

                    //for (var i = 2; i < body.length; i++) {
                    //    var bodyText = body[i].innerText;

                    //    console.log('bodyText');
                    //    console.dir(bodyText);

                    //    body[i].innerHTML = '<span ng-style="{color: \'red\'}">' + bodyText + '</span>';

                    //    $compile(body[i])($scope);
                    //}

                    $scope.ganttFunc($scope.model.activeGrp);

                    //document.getElementById('someStuff').innerHTML = "<div ng-controller='sheet'>{{stuff}}</div>";
                    //$compile(document.getElementById('someStuff'))($scope);

                    //this.list._adjustHeight();
                }

                $scope.onDataBinding = function () {
                    console.log("Gantt data binding");
                }

                $scope.onNavigate = function (e) {
                    console.log(kendo.format("navigate:: view:{0};", e.view));
                }

                $scope.onMoveStart = function (e) {
                    console.log("moveStart");
                }

                $scope.onMove = function (e) {
                    console.log("move");
                }

                $scope.onMoveEnd = function (e) {
                    console.log("moveEnd");
                }

                $scope.onResizeStart = function (e) {
                    console.log("resizeStart");
                }

                $scope.onResize = function (e) {
                    console.log("resize");
                }

                $scope.onResizeEnd = function (e) {
                    console.log("resizeEnd");
                }

                $scope.saveGantt = function () {
                    console.log('SAVE OF GANTT');

                    $scope.optionfunc();
                    $scope.model.locked = true;
                }

                $scope.goTo = function () {

                    if ($scope.model.locked == false) {
                        $scope.saveGantt();
                    }

                    stateService.go('workdetails', {
                        custact_no: $scope.model.selectedId
                    });
                }

                $scope.ganttFunc = function (item) {

                    //$scope.model.hasData = !$scope.model.hasData;

                    //console.log('GANTT FUNC');
                    //console.dir($scope.model.toolCols);
                    //console.dir(item);

                    for (var i = 0; i < $scope.model.toolCols.length; i++) {

                        var colwidth = 50; //Default minimum width of custom col

                        if (isNumber($scope.model.toolCols[i].colwidth) && Number($scope.model.toolCols[i].colwidth) > colwidth) {
                            colwidth = Number($scope.model.toolCols[i].colwidth);
                        }

                        $('.k-treelist .k-grid-header col:eq(' + ($scope.model.predefNumCols + i) + ')').css('width', colwidth); // auto
                        $('.k-treelist .k-grid-content col:eq(' + ($scope.model.predefNumCols + i) + ')').css('width', colwidth);

                        $scope.model.activeGrp = item;

                        if ($scope.model.toolCols[i].colgroup == item) continue;

                        $('.k-treelist .k-grid-header col:eq(' + ($scope.model.predefNumCols + i) + ')').css('width', 0);
                        $('.k-treelist .k-grid-content col:eq(' + ($scope.model.predefNumCols + i) + ')').css('width', 0);

                        //{
                        //    //$scope.model.activeGrp = $scope.model.toolBtnGrp[i].colgroup;
                        //    //$scope.listItems.length = 0;
                        //    //$scope.model.hasData = !$scope.model.hasData;
                        //    //$scope.ganttOptions.dataSource.transport.read();
                        //    //break;
                        //}
                    }
                    //console.log('ACTIVE');
                    //console.dir($scope.model.activeGrp);
                    //
                    //$scope.model.hasData = true;
                    //$scope.model.hasData = !$scope.model.hasData;
                    //
                    //$scope.optionfunc({ item: item.item_func });
                    //
                    //if ($scope.model.locked == false) {
                    //    $scope.saveGantt();
                    //}
                }

                //var depData = [ //Type: 0=EE, 1=ES, 2=SE, 3=SS
                //    {
                //        "ID": 2,
                //        "PredecessorID": 2,
                //        "SuccessorID": 22,
                //        "Type": 1
                //    },
                //    {
                //        "ID": 22,
                //        "PredecessorID": 22,
                //        "SuccessorID": 27,
                //        "Type": 1
                //    },
                //    {
                //        "ID": 27,
                //        "PredecessorID": 27,
                //        "SuccessorID": 47,
                //        "Type": 1
                //    }
                //];

                //var dependenciesDataSource = new kendo.data.GanttDependencyDataSource({
                //    data: depData,
                //    schema: {
                //        model: {
                //            id: "id",
                //            fields: {
                //                predecessorId: { from: "PredecessorID", type: "number" },
                //                successorId: { from: "SuccessorID", type: "number" },
                //                type: { from: "Type", type: "number" }
                //            }
                //        }
                //    }
                //});

                //var Strings = {};
                //Strings.orEmpty = function (entity) {
                //    return entity || "";
                //};

                //function stringOrEmpty(value) {
                //    if()
                //    return array.indexOf(value) > -1;
                //}

                function isInArray(value, array) {
                    return array.indexOf(value) > -1;
                }

                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                //$scope.$watch(function () {
                //    return $scope.listItems.length;
                //}, function (newValue) {
                //});

                //$scope.$watch(function () {
                //    return $scope.listItems.length;
                //}, function (newValue) {
                //});
                
                $scope.$watch(function () {
                    return $scope.listItems.length;
                }, function (newValue) {

                    if ($scope.listItems.length > 0) {
                        $scope.model.hasData = true;
                    } else {
                        $scope.model.hasData = false;
                    }
                });

                $scope.$watch(function () {
                    return $scope.model.hasData;
                }, function (newValue) {

                    if (newValue == true) {

                        //console.log('############################');
                        //console.log('##########NEW R#############');
                        //console.dir($scope.listItems[0]);

                        var tmpList = [];
                        var tmpColumns = [];
                        var tmpBtns = [];

                        if (angular.isDefined($scope.listItems[0].custacts)) {
                            tmpList = $scope.listItems[0].custacts;
                            //console.log('########## LIST ############');
                            //console.dir(tmpList);
                        }

                        if (angular.isDefined($scope.listItems[0].extracolumns)) {
                            tmpColumns = $scope.listItems[0].extracolumns;
                            //console.log('########## COL #############');
                            //console.dir(tmpColumns);
                            for (var i = 0; i < tmpColumns.length; i++) {
                                $scope.model.toolCols.push(angular.copy(tmpColumns[i]));
                            }
                        }

                        if (angular.isDefined($scope.listItems[0].groupbuttons)) {
                            tmpBtns = $scope.listItems[0].groupbuttons;
                            //console.log('########## BTNS #############');
                            //console.dir(tmpBtns);
                            for (var i = 0; i < tmpBtns.length; i++) {
                                //$scope.model.toolBtnGrp = $scope.listItems[0].groupbuttons;
                                $scope.model.toolBtnGrp.push(angular.copy(tmpBtns[i]));
                                $scope.model.toolBtnGrp[i].item_id = tmpBtns[i].colgroup_no;
                                $scope.model.toolBtnGrp[i].item_name = tmpBtns[i].colgroup_name;
                                $scope.model.toolBtnGrp[i].item_func = tmpBtns[i].colgroup;
                                //toolBtnGrp: [{ item_name: 'test' }, { item_name: 'test2' }]
                            }
                        }

                        var tempDataSource = [];
                        var dateList = [];

                        for (var i = 0; i < tmpList.length; i++) {
                            tempDataSource.push(angular.copy(tmpList[i]));
                            for (var d = 0; d < tmpColumns.length; d++) {
                                tempDataSource[i]['col' + tmpColumns[d].colid] = '';
                            }
                            if (angular.isDefined(tmpList[i].extracolvalues[0])) {
                                for (var d = 0; d < tmpList[i].extracolvalues.length; d++) {
                                    tempDataSource[i]['col' + tmpList[i].extracolvalues[d].colid] = tmpList[i].extracolvalues[d].colvalue;
                                }
                            }
                            if (angular.isDefined(tmpList[i].item_parent) && isNumber(tmpList[i].item_parent) && tmpList[i].item_parent !== '0') {
                                tempDataSource[i].item_parent = Number(tmpList[i].item_parent);
                            } else {
                                tempDataSource[i].item_parent = null;
                            }
                            //tempDataSource[i].expanded = true;
                            if (angular.isDefined(tmpList[i].expanded) && tmpList[i].expanded !== '') {
                                if (tmpList[i].expanded === 'true') {
                                    tempDataSource[i].expanded = true;
                                } else if (tmpList[i].expanded === 'false') {
                                    tempDataSource[i].expanded = false;
                                } else {
                                    tempDataSource[i].expanded = tmpList[i].expanded;
                                }
                            } else {
                                tempDataSource[i].expanded = true;
                            }

                            if (angular.isUndefined(tempDataSource[i].note)) {
                                tempDataSource[i].note = 'test';
                            }
                        }

                        //moment(options.data.datetime_start.toString().slice(0, 24), 'ddd MMM DD YYYY HH:mm:ss', true).format('YYYY-MM-DD HH:mm:ss')

                        for (var d = 0; d < tempDataSource.length; d++) {
                            //dateList.push(angular.copy(moment(tempDataSource[d].datetime_start).format('DD/MM/YYYY')));
                            //dateList.push(angular.copy(moment(tempDataSource[d].datetime_end).format('DD/MM/YYYY')));
                            dateList.push(angular.copy(moment(tempDataSource[d].datetime_start).format('YYYY')));
                            dateList.push(angular.copy(moment(tempDataSource[d].datetime_end).format('YYYY')));
                            //dateList.push(angular.copy(moment(tempDataSource[d].datetime_start).format('YYYY-MM-DD')));
                            //dateList.push(angular.copy(moment(tempDataSource[d].datetime_end).format('YYYY-MM-DD')));
                        }

                        $scope.model.firstDate = '01/01/' + dateList.reduce(function (pre, cur) {
                            return (Date.parse(pre) > Date.parse(cur) ? cur : pre);
                        });

                        $scope.model.lastDate = '01/01/' + (Number(dateList.reduce(function (pre, cur) {
                            return (Date.parse(pre) < Date.parse(cur) ? cur : pre);
                        })) + 1);

                        $scope.schemaFields = {};

                        $scope.schemaFields['id'] = { from: "custact_no", type: "number" };
                        $scope.schemaFields['orderId'] = { from: "orderby", type: "number", validation: { required: true } };
                        $scope.schemaFields['parentId'] = { from: "item_parent", type: "number", defaultValue: null, validation: { required: true } };
                        $scope.schemaFields['start'] = { from: "datetime_start", type: "date" };
                        $scope.schemaFields['end'] = { from: "datetime_end", type: "date" };
                        $scope.schemaFields['title'] = { from: "note", defaultValue: "", type: "string" };
                        $scope.schemaFields['percentComplete'] = { from: "complete_pst", type: "number" };
                        $scope.schemaFields['summary'] = { from: "summary", type: "boolean" };
                        $scope.schemaFields['expanded'] = { from: "expanded", type: "boolean", defaultValue: true };

                        for (var d = 0; d < tmpColumns.length; d++) {
                            $scope.schemaFields['col' + tmpColumns[d].colid] = { from: 'col' + tmpColumns[d].colid, type: "string", defaultValue: '' };
                        }

                        $scope.model.ganttDataSource = new kendo.data.GanttDataSource({
                            batch: false,
                            transport: {
                                read: function (options) {
                                    options.success(tempDataSource);
                                },
                                create: function (options) {
                                    var parms_custact_gantt_new = {
                                        method: 134,
                                        parameters: {
                                            custact_no: $stateParams.connectto_value,
                                            retrievetype: 'child',
                                            include_top: '1'
                                        }
                                    };

                                    $ihttp.post(parms_custact_gantt_new).then(function (data) {
                                        options.data.custact_no = data[0].custact_no;

                                        if (options.data.item_parent == null) {
                                            for (var j = 0; j < tmpList.length; j++) {
                                                if (tmpList[j].item_parent != '0') continue;
                                                options.data.item_parent = tmpList[j].custact_no;
                                                //options.data.datetime_start = tmpList[j].datetime_start;
                                                //options.data.datetime_end = tmpList[j].datetime_end;
                                                break;
                                            }
                                        }

                                        tmpList.push(data[0]);

                                        //console.log('CREATE OPTIONS: ');
                                        //console.dir(options.data);

                                        for (var i = 0; i < tmpList.length; i++) {
                                            if (tmpList[i].custact_no != options.data.custact_no) continue;

                                            if (options.data.item_parent === null) {
                                                tmpList[i].item_parent = "0";
                                                //for (var j = 0; j < tmpList.length; j++) {
                                                //    if (tmpList[j].item_parent != '0') continue;
                                                //    tmpList[i].item_parent = tmpList[j].custact_no;
                                                //    break;
                                                //}
                                            } else {
                                                tmpList[i].item_parent = options.data.item_parent.toString();
                                            }
                                            if (options.data.note === null) {
                                                tmpList[i].note = "New Item";
                                            } else {
                                                tmpList[i].note = options.data.note.toString();
                                            }
                                            if (options.data.datetime_start === null) {
                                                tmpList[i].datetime_start = "0";
                                            } else {
                                                tmpList[i].datetime_start = moment(options.data.datetime_start.toString().slice(0, 24), 'ddd MMM DD YYYY HH:mm:ss', true).format('YYYY-MM-DD HH:mm:ss');
                                            }
                                            if (options.data.datetime_end === null) {
                                                tmpList[i].datetime_end = "0";
                                            } else {
                                                tmpList[i].datetime_end = moment(options.data.datetime_end.toString().slice(0, 24), 'ddd MMM DD YYYY HH:mm:ss', true).format('YYYY-MM-DD HH:mm:ss');
                                            }
                                            if (options.data.complete_pst === null) {
                                                tmpList[i].complete_pst = "0";
                                            } else {
                                                tmpList[i].complete_pst = options.data.complete_pst.toString();
                                            }

                                            tmpList[i].custactlink_keyno = '0';
                                            tmpList[i].ok = '0';
                                            tmpList[i].order_by = tmpList.length.toString();
                                            tmpList[i].summary = 'false';

                                            break;
                                        }

                                        options.success(options.data);

                                    });
                                },
                                update: function (options) {
                                    
                                    //console.log('UPDATE OPTIONS: ');
                                    //console.log('UPDATE OPTIONS: ' + angular.toJson(options.data));
                                    //console.dir(options.data);
                                    //console.dir(tmpList);

                                    for (var i = 0; i < tmpList.length; i++) {
                                        if (tmpList[i].custact_no != options.data.custact_no) continue;

                                        //console.log('UPDATING: ');

                                        if (options.data.item_parent === null) {
                                            tmpList[i].item_parent = "0";
                                        } else {
                                            tmpList[i].item_parent = options.data.item_parent.toString();
                                        }
                                        if (options.data.note === null) {
                                            tmpList[i].note = "New Item";
                                        } else {
                                            tmpList[i].note = options.data.note.toString();
                                        }
                                        if (options.data.datetime_start === null) {
                                            tmpList[i].datetime_start = "0";
                                        } else {
                                            tmpList[i].datetime_start = moment(options.data.datetime_start.toString().slice(0, 24), 'ddd MMM DD YYYY HH:mm:ss', true).format('YYYY-MM-DD HH:mm:ss');
                                        }
                                        if (options.data.datetime_end === null) {
                                            tmpList[i].datetime_end = "0";
                                        } else {
                                            tmpList[i].datetime_end = moment(options.data.datetime_end.toString().slice(0, 24), 'ddd MMM DD YYYY HH:mm:ss', true).format('YYYY-MM-DD HH:mm:ss');
                                        }
                                        if (options.data.complete_pst === null) {
                                            tmpList[i].complete_pst = "0";
                                        } else {
                                            tmpList[i].complete_pst = options.data.complete_pst.toString();
                                        }
                                        break;
                                    }

                                    //console.log('HAS UPDATES: ' + $scope.model.ganttDataSource.hasChanges());
                                    //console.dir($scope.model.ganttDataSource.hasChanges());

                                    options.success(options.data);
                                },
                                destroy: function (options) {
                                    var parms_custact_gantt_delete = {
                                        method: 136,
                                        parameters: {
                                            custact_no: options.data.custact_no,
                                            retrievetype: 'child',
                                            include_top: '1'
                                        }
                                    };

                                    if (options.data.item_parent == null) {
                                        console.log('NULL PARENT');
                                    }

                                    $ihttp.post(parms_custact_gantt_delete).then(function (data) {
                                        //console.log('DELETE');
                                        if (angular.isDefined(data.messages)) {
                                            //console.dir(options.data);
                                            $scope.model.ganttDataSource.cancelChanges(options.data);
                                            //$scope.model.ganttDataSource.cancelChanges();
                                            options.success(options.data);
                                        } else {
                                            //console.log('DESTROY OPTIONS: ');
                                            //console.dir(options.data);

                                            options.success(options.data);
                                        }
                                    });
                                },
                            },
                            schema: {
                                model: {
                                    id: "id",
                                    fields: $scope.schemaFields
                                }
                            }
                        })

                        //console.log('$scope.model.ganttDataSource');
                        //console.dir($scope.model.ganttDataSource);

                        //var tmpRes = [{ "ID": 1, "Name": "Thomas Hardy", "Color": "#f44336   " },
                        //            { "ID": 2, "Name": "Elizabeth Lincoln", "Color": "#880e4f   " },
                        //            { "ID": 3, "Name": "Christina Berglund", "Color": "#ff4081   " },
                        //            { "ID": 18, "Name": "Documentation Team", "Color": "#26c6da   " }];

                        //var tmpRes = [];
                        //
                        //if (angular.isDefined($scope.listResources) && $scope.listResources.length > 0) {
                        //    for (var i = 0; i < $scope.listResources.length; i++) {
                        //        tmpRes.push(angular.copy($scope.listResources[i]));
                        //        if (angular.isDefined($scope.listResources[i].item_id) && isNumber($scope.listResources[i].item_id) && $scope.listResources[i].item_id !== '0') {
                        //            tmpRes[i].item_id = Number($scope.listResources[i].item_id);
                        //        } else {
                        //            tmpRes[i].item_id = null;
                        //        }
                        //    }
                        //}

                        //$scope.model.ganttResource = new kendo.data.GanttDataSource({
                        //    batch: false,
                        //    transport: {
                        //        read: function (options) {
                        //            options.success(tmpRes);
                        //        },
                        //        create: function (options) {
                        //            options.success(options.data);
                        //        },
                        //        update: function (options) {
                        //            options.success(options.data);
                        //        },
                        //        destroy: function (options) {
                        //            options.success(options.data);
                        //        },
                        //    },
                        //    schema: {
                        //        model: {
                        //            id: "id",
                        //            fields: {
                        //                id: { from: "ID", type: "number" }
                        //            }
                        //        }
                        //    }
                        //})

                        //var tmpAssign = [{ "ID": 1, "TaskID": 7, "ResourceID": 1, "Units": 1.00 },
                        //                { "ID": 2, "TaskID": 18, "ResourceID": 1, "Units": 1.00 },
                        //                { "ID": 3, "TaskID": 11, "ResourceID": 14, "Units": 0.50 },
                        //                { "ID": 33, "TaskID": 622041, "ResourceID": 1, "Units": 0.20 },
                        //                { "ID": 34, "TaskID": 622040, "ResourceID": 18, "Units": 0.50 },
                        //                { "ID": 35, "TaskID": 17, "ResourceID": 17, "Units": 1.00 },
                        //                { "ID": 36, "TaskID": 17, "ResourceID": 16, "Units": 0.50 },
                        //                { "ID": 37, "TaskID": 622041, "ResourceID": 18, "Units": 1.00 }];
                        
                        //$scope.model.ganttAssignment = new kendo.data.GanttDataSource({
                        //    batch: false,
                        //    transport: {
                        //        read: function (options) {
                        //            options.success(tmpAssign);
                        //        },
                        //        create: function (options) {
                        //            options.success(options.data);
                        //        },
                        //        update: function (options) {
                        //            options.success(options.data);
                        //        },
                        //        destroy: function (options) {
                        //            options.success(options.data);
                        //        },
                        //    },
                        //    schema: {
                        //        model: {
                        //            id: "ID",
                        //            fields: {
                        //                ID: { type: "number" },
                        //                ResourceID: { type: "number" },
                        //                Units: { type: "number" },
                        //                TaskID: { type: "number" }
                        //            }
                        //        }
                        //    }
                        //})

                        $scope.ganttColumns = [{ field: "id", title: "ID", width: 60 }, { field: "title", title: "Title", editable: true }];

                        for (var d = 0; d < tmpColumns.length; d++) {

                            var colwidth = 50; //Default minimum width of custom col

                            if (isNumber(tmpColumns[d].colwidth) && Number(tmpColumns[d].colwidth) > colwidth) {
                                colwidth = Number(tmpColumns[d].colwidth);
                            }

                            //console.log('Group & Active');
                            //console.dir(tmpColumns[d].colgroup);
                            //console.dir($scope.model.activeGrp);
                            //
                            //if (tmpColumns[d].colgroup == $scope.model.activeGrp) {
                            //    //console.log('Group & Active');
                            //    //console.dir(tmpColumns[d].colgroup);
                            //    //console.dir($scope.model.activeGrp);
                            //    $scope.ganttColumns.push({ field: ('col' + tmpColumns[d].colid), title: tmpColumns[d].colheading, width: colwidth, editable: false, hidden: true });
                            //}

                            $scope.ganttColumns.push({ field: ('col' + tmpColumns[d].colid), title: tmpColumns[d].colheading, width: colwidth, editable: false, hidden: true });
                            //$scope.ganttColumns.push({ field: ('col' + tmpColumns[d].colid), title: '<span class="verticalText">Text</span>', width: 60, editable: true });
                        }

                        //console.log('$scope.ganttColumns');
                        //console.dir($scope.ganttColumns);

                        kendo.pdf.defineFont({
                            "DejaVu Sans": "//kendo.cdn.telerik.com/2014.3.1314/styles/fonts/DejaVu/DejaVuSans.ttf",
                            "DejaVu Sans|Bold": "//kendo.cdn.telerik.com/2014.3.1314/styles/fonts/DejaVu/DejaVuSans-Bold.ttf",
                            "DejaVu Sans|Bold|Italic": "//kendo.cdn.telerik.com/2014.3.1314/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf",
                            "DejaVu Sans|Italic": "//kendo.cdn.telerik.com/2014.3.1314/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf"
                        });

                        $scope.ganttOptions = {
                            dataSource: $scope.model.ganttDataSource,
                            //dependencies: dependenciesDataSource,
                            //resources: {
                            //    field: "resources",
                            //    dataColorField: "Color",
                            //    dataTextField: "Name",
                            //    dataSource: $scope.model.ganttResource
                            //},
                            //assignments: {
                            //    dataTaskIdField: "TaskID",
                            //    dataResourceIdField: "ResourceID",
                            //    dataValueField: "Units",
                            //    dataSource: $scope.model.ganttAssignment
                            //},
                            views: [
                                "day",
                                { type: "week", selected: true },
                                "month",
                                { type: "kendo.ui.GanttCustomView", title: "Quarterly"}
                            ],
                            columns: $scope.ganttColumns,
                            listWidth: $scope.model.lWidth,
                            height: $scope.model.ganttHeight,
                            pdf: {
                                author: 'Robin'
                            },
                            toolbar: [{ name: 'append' }, { name: 'pdf' }],
                            //toolbar: [{ name: 'append' }, { name: 'pdf' }, { name: 'custom', template: '<a class="k-button" onclick="$scope.toolBtnClick()">Command</a>' }],
                            //toolbar: [{ name: 'append' }, { name: 'pdf' }, { name: 'custom', template: '<im-btn-group-directive list-items="$scope.toolBtnGrp" class="col-xs-12"></im-btn-group-directive>' }],
                            messages: {
                                actions: {
                                    pdf: "PDF"
                                    //custom: 'Test'
                                }
                            },

                            showWorkHours: false,
                            showWorkDays: false,

                            dataBinding: $scope.onDataBinding,
                            dataBound: $scope.onDataBound,
                            add: $scope.onAdd,
                            edit: $scope.onEdit,
                            cancel: $scope.onCancel,
                            change: $scope.onChange,
                            remove: $scope.onRemove,
                            save: $scope.onSave,
                            navigate: $scope.onNavigate,
                            moveStart: $scope.onMoveStart,
                            move: $scope.onMove,
                            moveEnd: $scope.onMoveEnd,
                            resizeStart: $scope.onResizeStart,
                            resize: $scope.onResize,
                            resizeEnd: $scope.onResizeEnd
                        };
                    }
                });

                // #####################
                // CUSTOM VIEWS
                // #####################
                
                //$scope.$watch(function () {
                //    return $scope.model.ganttDataSource;
                //}, function (newValue) {
                //    if ($scope.model.ganttDataSource == null || angular.isUndefined($scope.model.ganttDataSource)) return;
                //    console.log('Data');
                //    console.dir($scope.model.ganttDataSource);
                //    var dateList = [];
                //
                //    for (var d = 0; d < $scope.model.ganttDataSource._data.length; d++) {
                //        console.log('DD¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤: ');
                //        console.dir($scope.model.ganttDataSource._data[d]);
                //        dateList.add($scope.model.ganttDataSource._data[d].start);
                //        dateList.add($scope.model.ganttDataSource._data[d].end);
                //    }
                //
                //    console.log('DATES');
                //    console.dir(dateList);
                //});
                //
                //$scope.model.firstDate = $scope.dates[0];
                //
                //$scope.model.firstDate = dates.reduce(function (pre, cur) {
                //    return Date.parse(pre) > Date.parse(cur) ? cur : pre;
                //});
                //
                //$scope.model.lastDate = dates.reduce(function (pre, cur) {
                //    return Date.parse(pre) < Date.parse(cur) ? cur : pre;
                //});
                //
                //if (angular.isUndefined(kendo.insoft)) {
                //    kendo.insoft = {};
                //}
                //
                //if (angular.isUndefined(kendo.insoft.gantt)) {
                //    kendo.insoft.gantt = {
                //        getQuarter: function (month) {
                //            if (month < 3) return 0;
                //            if (month < 6) return 1;
                //            if (month < 9) return 2;
                //
                //            return 3
                //        }
                //    };
                //}

                kendo.ui.GanttCustomView = kendo.ui.GanttView.extend({ // $scope.model.ganttCustomView = 
                    name: "custom",
                    options: {
                        yearHeaderTemplate: kendo.template("#=kendo.toString(start, 'yyyy')#"),
                        //quarterHeaderTemplate: kendo.template("# return ['Q1', 'Q2', 'Q3', 'Q4'][kendo.insoft.gantt.getQuarter(start.getMonth())] #"),
                        quarterHeaderTemplate: kendo.template("# return ['Q1', 'Q2', 'Q3', 'Q4'][start.getMonth() / 3] #"),
                        //quarterHeaderTemplate: kendo.template("# return start #"),
                        monthHeaderTemplate: kendo.template("#=kendo.toString(start, 'MMM')#")
                    },
                    range: function (range) {
                        this.start = new Date($scope.model.firstDate);
                        this.end = new Date($scope.model.lastDate);
                    },
                    _generateSlots: function (incrementCallback, span) {
                        var slots = [];
                        var slotStart = new Date(this.start);
                        var slotEnd;

                        while (slotStart < this.end) {
                            slotEnd = new Date(slotStart);
                            incrementCallback(slotEnd);

                            slots.push({ start: slotStart, end: slotEnd, span: span });

                            slotStart = slotEnd;
                        }

                        return slots;
                    },
                    _createSlots: function () {
                        var slots = [];

                        slots.push(this._generateSlots(function (date) { date.setFullYear(date.getFullYear() + 1); }, 12));
                        slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 3); }, 3));
                        slots.push(this._generateSlots(function (date) { date.setMonth(date.getMonth() + 1); }, 1));

                        return slots;
                    },
                    _layout: function () {
                        var rows = [];
                        var options = this.options;

                        rows.push(this._slotHeaders(this._slots[0], kendo.template(options.yearHeaderTemplate)));
                        rows.push(this._slotHeaders(this._slots[1], kendo.template(options.quarterHeaderTemplate)));
                        rows.push(this._slotHeaders(this._slots[2], kendo.template(options.monthHeaderTemplate)));

                        return rows;
                    }
                });
            }
        };

        return directive;
    }]);
})();