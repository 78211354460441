(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryCustomers', {
        templateUrl: 'views/components/views/deliveryCustomers/deliveryCustomers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'deliveryCustomersService', function ($stateParams, $q, stateService, deliveryCustomersService) {
            const vm = this;
            let custNo = $stateParams.cust_no;
            
            vm.model = {
                setting: {},
                itemsListDeliveryCustomers: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();
                
                deliveryCustomersService.getDeliveryCustomers({ cust_no: custNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadDeliveryCustomers()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
    		
            let loadDeliveryCustomers = function () {
                return deliveryCustomersService.listDeliveryCustomers(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListDeliveryCustomers);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'deliverycustomer':
                        go({
                            deliverycustomer_keyno: '0',
                            cust_no: vm.model.setting.cust_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
