(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("categoryDatasService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loadP2CatagoryDataList: function (argtype, argvalue, category_keyno_top) {
                var deferred = $q.defer();

                var parmsP2CatagoryDataList = {
                    method: 346,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue,
                        category_keyno_top: category_keyno_top
                    }
                };

                $ihttp.post(parmsP2CatagoryDataList).then(function (data) {
                    for (var i = 0; i < data.length; i++) {
                        service.categoryDataListValidate(data, i);
                    }

                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            categoryDataListValidate: function (data, index) {
                var ls_itemvalue_new = data[index].selected;
                var ln_category_keyno = data[index].category_keyno;

                for (var i = index + 1; i < data.length; i++) {
                    if (data[i].category_keyno_belongto == ln_category_keyno) {
                        data[i].visible = ls_itemvalue_new;
                    }
                }
            },
            genericFunction: function (method_nr, parms) {
                return $ihttp.post({
                    method: method_nr,
                    parameters: parms
                });
            },
            saveP2CategoryData: function (p2CatagoryDataList) {
                return $ihttp.post({
                    method: 356,
                    parameters: {
                        argtype: $stateParams.argtype,
                        argvalue: $stateParams.argvalue,
                        records: p2CatagoryDataList.map(function (a) { return { "categorylink_keyno": a.categorylink_keyno, "category_keyno": a.category_keyno, "category_name": a.category_name, "selected": a.selected, "rectype": a.rectype }; })
                    }
                });
            }
        };

        return service;
    }]);
})();