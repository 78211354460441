(function() {
    'use strict';

    angular.module("imApp").factory("sizematrixService", ['$ihttp', function ($ihttp) {
        let call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        let service = {

        }

        return service;
    }]);
})();
