(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('docPack', {
        templateUrl: 'views/components/views/docPack/docPack.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: [
            '$stateParams',
            '$q',
            'stateService',
            'utilityService',
            'pdfService',
            'base64',
            'modalService',
            'translateService',
            'docPackService',
            'rememberService',
            'fileService',
            function ($stateParams, $q, stateService, utilityService, pdfService, base64, modalService, translateService, docPackService, rememberService, fileService) {
                // #region VARIABLES & DEFINITIONS

                const vm = this;
                let docPackKeyno = $stateParams.docpack_keyno;

                let translations = {
                    confirm_title: '',
                    confirm_message: '',
                    confirm_ok_label: '',
                    confirm_cancel_label: '',
                    error: '',
                    ok: '',
                    retrieving_pages: '',
                    retrieving_file_details_for: '',
                    converting_page: '',
                    to_pdf: '',
                    merging_pdfs: '',
                };

                vm.onGridBoxResized = function () {
                    vm.gridChapters?.gridfunc?.resize();
                    vm.gridChapterItems?.gridfunc?.resize();
                };

                vm.model = {
                    bgDocPackMain: {
                        selected: 'DOCUMENTS',
                        buttons: [
                            { id: 'DOCUMENTS', label: 'gb_docpack_main_documents', color: 'primary', onClick: () => (vm.model.bgDocPackMain.selected = 'DOCUMENTS') },
                            { id: 'FRONTPAGE', label: 'gb_docpack_main_frontpage', color: 'primary', onClick: () => (vm.model.bgDocPackMain.selected = 'FRONTPAGE') },
                            //{ id: 'INDEXPAGE', label: 'gb_docpack_main_indexpage', color: 'primary', onClick: () => (vm.model.bgDocPackMain.selected = 'INDEXPAGE') },
                        ],
                    },
                    bgDocPackDocuments: {
                        selected: 'DOCUMENTS',
                        buttons: [
                            { id: 'DOCUMENTS', label: 'gb_docpack_documents_documents', color: 'primary', onClick: () => (vm.model.bgDocPackDocuments.selected = 'DOCUMENTS') },
                            { id: 'FRONTPAGE', label: 'gb_docpack_documents_frontpage', color: 'primary', onClick: () => (vm.model.bgDocPackDocuments.selected = 'FRONTPAGE') },
                        ],
                    },
                    docPackViewBoxes: {
                        //height: '25vh',
                        rememberId: 'docpack.boxes',
                        boxes: [
                            { item_id: uuid(), item_name: 'docpack', type: 'h', size: '100', depth: 0 },
                            { item_id: uuid(), item_name: 'chaptersgrid', type: 'v', size: '35', depth: 1 },
                            { item_id: uuid(), item_name: 'itemsgrid', type: 'v', size: '35', depth: 1 },
                            { item_id: uuid(), item_name: 'document', type: 'v', size: '30', depth: 1 },
                        ],
                    },
                    edit: {},
                    chapterInfo: {},
                    readyHtmlTextFrontPage: true,
                    readyHtmlTextIndexPage: true,
                    readyHtmlText: true,
                    gridChaptersReady: false,
                    gridChapterItemsReady: false,
                    generatingPdf: false,
                    updatingHtml: false,
                    updatingHtmlChapter: false,
                    lockedSave: false,
                    lockedSaveChapter: false,
                    lockedDelete: false,
                };

                vm.editorHtmlText = {
                    ready: true,
                    style: {
                        div: {
                            minHeight: '30px',
                            height: '30vh',
                            maxHeight: 'calc(30vh - 50px)',
                        },
                    },
                    class: {
                        base: 'col-xs-12 col-sm-12 col-xl-12 col-xxl-12 sp-0',
                        label: 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1 sp-0',
                        input: 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11 sp-0',
                    },
                };

                vm.editor = {
                    ready: true,
                    style: {
                        div: {
                            minHeight: '50px',
                            height: '50vh',
                            //maxHeight: 'min(calc(100vh - 220px), 700px)',
                            maxHeight: 'calc(100vh - 220px)',
                        },
                    },
                    class: {
                        base: 'col-xs-12 col-sm-12 col-xl-12 col-xxl-12 sp-0',
                        label: 'col-xs-12 col-sm-2 col-xl-2 col-xxl-1 sp-0',
                        input: 'col-xs-12 col-sm-10 col-xl-10 col-xxl-11 sp-0',
                    },
                };

                vm.gridChapters = {
                    dataTask: {
                        rememberId: 'w_docpack_docpackchapter_grid',
                        loadSetupId: null,
                        loadData: {},
                        addRow: {
                            method: null,
                            parameters: null,
                            autoSave: false,
                            confirm: false,
                        },
                        removeRow: {
                            method: null,
                            parameters: null,
                            autoSave: false,
                            confirm: false,
                        },
                        saveData: {
                            method: null,
                            parameters: null,
                            autoSave: false,
                            confirm: false,
                        },
                    },
                    translations: [],
                    config: {
                        editColumns: [
                            //{ key: 'test_column' }
                            //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                        ],
                        toolbar: {
                            hidden: false,
                            pdfExport: true,
                            excelExport: true,
                            filter: true,
                            columnVisibility: true,
                            headers: true,
                            edit: false,
                            lock: false,
                            add: false,
                            delete: false,
                            save: false,
                            wrapping: true,
                            layouts: true,
                            refresh: false,
                            read: false,
                            rebind: false,
                            buttons: [
                                //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                                //{ name: 'test', text: '', func: function () { testItems(); } },
                                //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                                //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                                //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                                //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                            ],
                        },
                        fixedHeader: true,
                        keepSortOnAdd: false,
                        keepSortOnCheckbox: false,
                        keepSortOnIsSelected: false,
                        rowClick: false,
                        //navigation: {
                        //	altNav: true,
                        //	newLine: true
                        //},
                        specialFunc: {
                            newTab: true,
                            buttons: [
                                //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                            ],
                        },
                        css: {
                            altColor: true,
                            textWrapping: false,
                        },
                        onDataSourceChanges: function (e) {
                            //console.log('onDataSourceChanges');
                            //console.dir(e);
                        },
                        onDataChanged: function (changed, e) {
                            //console.log('onDataChanged');
                            //console.dir(e);
                        },
                    },
                    kendo: {
                        height: null,
                        sortable: true,
                        aggregate: true,
                        pager: false,
                        selectable: {
                            mode: 'row',
                            cellAggregates: false,
                            checkboxSelection: false,
                        }, //'multiple, row'
                        //persistSelection: false,
                        filterable: false,
                    },
                    onSelect: (event) => {
                        if (event.$item) {
                            vm.model.edit.docpackchapter_keyno_selected = event.$item.docpackchapter_keyno;

                            rememberService.remember('w_docpack.docpackchapter_keyno_selected', vm.model.edit.docpackchapter_keyno_selected);
                            retrieveChapter();
                            loadGridChapterItems();
                        }
                    },
                    gridfunc: null,
                };

                vm.gridChapterItems = {
                    dataTask: {
                        rememberId: 'w_docpack_docpackchapteritem_grid',
                        loadSetupId: null,
                        loadData: {},
                        addRow: {
                            method: null,
                            parameters: null,
                            autoSave: false,
                            confirm: false,
                        },
                        removeRow: {
                            method: null,
                            parameters: null,
                            autoSave: false,
                            confirm: false,
                        },
                        saveData: {
                            method: null,
                            parameters: null,
                            autoSave: false,
                            confirm: false,
                        },
                    },
                    translations: [],
                    config: {
                        editColumns: [
                            //{ key: 'test_column' }
                            //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                        ],
                        toolbar: {
                            hidden: false,
                            pdfExport: true,
                            excelExport: true,
                            filter: true,
                            columnVisibility: true,
                            headers: true,
                            edit: false,
                            lock: false,
                            add: false,
                            delete: false,
                            save: false,
                            wrapping: true,
                            layouts: true,
                            refresh: false,
                            read: false,
                            rebind: false,
                            buttons: [
                                {
                                    name: 'docpack_docpackchapter_upload',
                                    text: 'docpack_docpackchapter_upload',
                                    func: function () {
                                        stateService.go('documents', {
                                            argtype: 'docpackchapter_keyno',
                                            argvalue: vm.model.edit.docpackchapter_keyno_selected,
                                        });
                                    },
                                    icon: 'fa-plus',
                                    cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                    translate: true,
                                    //disabled: function () { return; }
                                    //disabled: function () {
                                    //	if (vm.model.edit.show === '1') {
                                    //		return false;
                                    //	} else {
                                    //		return 'hidden';
                                    //	}
                                    //}
                                },
                                {
                                    name: 'docpack_docpackchapteritem_add',
                                    text: 'docpack_docpackchapteritem_add',
                                    func: function () {
                                        stateService.go('docpackdocadd', { docpackchapter_keyno: vm.model.edit.docpackchapter_keyno_selected });
                                    },
                                    icon: 'fa-plus',
                                    cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                    translate: true,
                                    //disabled: function () { return; }
                                    //disabled: function () {
                                    //	if (vm.model.setting.show === '1') {
                                    //		return false;
                                    //	} else {
                                    //		return 'hidden';
                                    //	}
                                    //}
                                },
                                {
                                    name: 'docpack_docpackchapteritem_delete_selected',
                                    text: 'docpack_docpackchapteritem_delete_selected',
                                    func: function () {
                                        var currentData = vm.gridChapterItems.gridfunc.getDirtyRows();
                                        var selectedItems = [];

                                        angular.forEach(currentData, function (item) {
                                            if (item.docpackchapteritem_keyno === '0' || item.docpackchapteritem_keyno === false) return;

                                            selectedItems.push({ docpackchapteritem_keyno: item.docpackchapteritem_keyno });
                                        });

                                        var mergeObject = angular.copy(vm.model.edit);
                                        mergeObject.records = selectedItems;

                                        modalService
                                            .confirm({
                                                type: 'danger',
                                                title: translations.confirm_title,
                                                message: translations.confirm_message,
                                                okLabel: translations.confirm_ok_label,
                                                cancelLabel: translations.confirm_cancel_label,
                                            })
                                            .then(function () {
                                                docPackService.deleteGridRows(mergeObject).then(function (response) {
                                                    if (response[0].errorcode !== '0') {
                                                        modalService.show({
                                                            type: 'warning',
                                                            title: translations.error,
                                                            message: response[0].errormessage,
                                                            buttons: [
                                                                {
                                                                    label: translations.ok,
                                                                    cssClass: 'btn-warning',
                                                                    action: function (dialogItself) {
                                                                        dialogItself.close();
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    } else {
                                                        loadGridChapterItems();
                                                    }
                                                });
                                            });
                                    },
                                    icon: 'fa-trash',
                                    cssClass: 'btn btn-danger im-grid-btn-xs-r',
                                    translate: true,
                                    //disabled: function () { return; }
                                    //disabled: function () {
                                    //	if (vm.model.edit.show === '1') {
                                    //		return false;
                                    //	} else {
                                    //		return 'hidden';
                                    //	}
                                    //}
                                },
                                //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                                //{ name: 'test', text: '', func: function () { testItems(); } },
                                //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                                //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                                //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                                //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                            ],
                        },
                        fixedHeader: true,
                        keepSortOnAdd: false,
                        keepSortOnCheckbox: false,
                        keepSortOnIsSelected: false,
                        rowClick: false,
                        //navigation: {
                        //	altNav: true,
                        //	newLine: true
                        //},
                        specialFunc: {
                            newTab: true,
                            buttons: [
                                //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                            ],
                        },
                        css: {
                            altColor: true,
                            textWrapping: false,
                        },
                        onDataSourceChanges: function (e) {
                            //console.log('onDataSourceChanges');
                            //console.dir(e);
                        },
                        onDataChanged: function (changed, e) {
                            //console.log('onDataChanged');
                            //console.dir(e);
                        },
                    },
                    kendo: {
                        height: null,
                        sortable: true,
                        aggregate: true,
                        pager: false,
                        selectable: {
                            mode: 'row',
                            cellAggregates: false,
                            checkboxSelection: false,
                        }, //'multiple, row'
                        //persistSelection: false,
                        filterable: false,
                    },
                    onReady: () => setTimeout(() => vm.gridChapterItems?.gridfunc?.resize(), 250),
                    optionfunc: null,
                    gridfunc: null,
                };

                // #endregion VARIABLES & DEFINITIONS

                // #region TRANSLATE SERVICE

                translateService.translateBatch(translations).then(function (data) {
                    angular.forEach(translations, function (_, key) {
                        if (angular.isDefined(data[key])) {
                            translations[key] = data[key];
                        }
                    });
                });

                // #endregion TRANSLATE SERVICE

                // #region LOAD PROCEDURE FUNCTION CALLS

                let loadEdit = function () {
                    const deferred = $q.defer();

                    docPackService.getEdit({ docpack_keyno: docPackKeyno }).then(function (info) {
                        angular.copy(info[0], vm.model.edit);
                        vm.model.edit.html_text_frontpage = base64.urldecode(vm.model.edit.html_text_frontpage);
                        vm.model.edit.html_text_indexpage = base64.urldecode(vm.model.edit.html_text_indexpage);

                        retrieveChapter();
                        loadGridChapters();
                        loadGridChapterItems();

                        deferred.resolve();
                    });

                    return deferred.promise;
                };

                let retrieveChapter = function () {
                    //if (utilityService.validateParmsValue(vm.model.edit.docpackchapter_keyno_selected) !== true) return;

                    return docPackService.getChapter({ docpackchapter_keyno: vm.model.edit.docpackchapter_keyno_selected }).then(function (info) {
                        angular.copy(info[0], vm.model.chapterInfo);
                        vm.model.chapterInfo.html_text = base64.urldecode(vm.model.chapterInfo.html_text);
                    });
                };

                // #endregion LOAD PROCEDURE FUNCTION CALLS

                // #region GRID PROCEDURE FUNCTION CALLS

                let loadGridChapters = function () {
                    vm.gridChapters.dataTask.loadData = {
                        method: 3290,
                        parameters: { docpack_keyno: vm.model.edit.docpack_keyno },
                    };

                    vm.model.gridChaptersReady = true;

                    if (vm.gridChapters.gridfunc !== null) vm.gridChapters.gridfunc.read();
                };

                let loadGridChapterItems = function () {
                    vm.gridChapterItems.dataTask.loadData = {
                        method: 3291,
                        parameters: vm.model.edit,
                    };

                    vm.model.gridChapterItemsReady = true;

                    if (vm.gridChapterItems.gridfunc !== null) vm.gridChapterItems.gridfunc.read();
                };

                // #endregion GRID PROCEDURE FUNCTION CALLS

                vm.generatePdfChanges = async function () {
                    vm.model.generatingPdf = true;

                    try {
                        const progressInfo = {
                            label: '',
                            plabel: '',
                            field: translations.retrieving_pages,
                            remField: '',
                            min: 0,
                            max: 100,
                            step: 0,
                            finishclose: '',
                        };

                        modalService.showProgress(progressInfo);

                        const documents = (await docPackService.generatePdf({ docpack_keyno: vm.model.edit.docpack_keyno })).sort((a, b) => Number(a.filepages_keyno) - Number(b.filepages_keyno));

                        modalService.progressInfo.max = documents.length + 1;

                        const base64documents = await Promise.all(documents.map(async (document) => await getBase64Content(document)));

                        modalService.progressInfo.field = translations.merging_pdfs;

                        const mergedPdf = await pdfService.mergePDFs(base64documents);

                        modalService.progressInfo.step = documents.length + 1;

                        downloadBase64(mergedPdf);
                    } catch (error) {
                        console.error(error);
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: error,
                            buttons: [{ label: translations.ok, cssClass: 'btn-warning', action: (dialogItself) => dialogItself.close() }],
                        });
                    } finally {
                        vm.model.generatingPdf = false;
                    }
                };

                /**
                 * Downloads the provided base64 content as a file.
                 *
                 * @param {string} base64 the content of the file to download
                 */
                async function downloadBase64(base64) {
                    const response = await fetch(base64);
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');

                    a.href = url;
                    a.download = `${vm.model.edit.docpack_name || 'dockpack'}.pdf`;
                    a.click();
                }

                /**
                 * Retrieves base64 content of the provided document. Returns an object with the content and content-type.
                 *
                 * @param {*} document the document to retrieve base65 content for.
                 * @returns {Promise<{
                 *  contentType: 'application/pdf' | 'image/jpg' | 'image/jpeg' | 'image/png',
                 *  content: string | Uint8Array | ArrayBuffer
                 * }>}
                 */
                async function getBase64Content(document) {
                    if (document.filecontent_type === 'HTML') {
                        const pdf = await pdfService.convertHTMLToPDF(base64.urldecode(document.filecontent_base64));

                        modalService.progressInfo.field = translations.converting_page + ' ' + document.pagetitle + ' ' + translations.to_pdf;
                        modalService.progressInfo.step++;

                        return {
                            contentType: 'application/pdf',
                            content: pdf,
                            pagetitle: document.pagetitle,
                            level: document.level,
                        };
                    } else if (document.docarc_keyno !== '0' && !!document.filename) {
                        const filedetails = await fileService.getFileDetails({ fileName: document.filename });
                        const filelink = await fileService.getTokenLink(filedetails.imageLink);
                        const response = await fetch(filelink);
                        const blob = await response.blob();
                        const arraybuffer = await blob.arrayBuffer();

                        modalService.progressInfo.field = translations.retrieving_file_details_for + ' ' + document.pagetitle;
                        modalService.progressInfo.step++;

                        if (filedetails.contentType?.includes('jpg') || filedetails.contentType?.includes('jpeg')) {
                            return {
                                contentType: 'image/jpeg',
                                content: arraybuffer,
                                pagetitle: document.pagetitle,
                                level: document.level,
                            };
                        } else if (filedetails.contentType?.includes('png')) {
                            return {
                                contentType: 'image/png',
                                content: arraybuffer,
                                pagetitle: document.pagetitle,
                                level: document.level,
                            };
                        } else if (filedetails.contentType?.includes('pdf')) {
                            return {
                                contentType: 'application/pdf',
                                content: arraybuffer,
                                pagetitle: document.pagetitle,
                                level: document.level,
                            };
                        }
                    }

                    throw Error('Missing data, could not retrieve base 64 of document with filepages_keyno: ' + document.filepages_keyno);
                }

                // #region BUTTON UPDATE HTML PROCEDURE FUNCTION CALL

                vm.updateChanges = function () {
                    vm.model.updatingHtml = true;

                    let editAndUpdate = {};
                    editAndUpdate = angular.copy(vm.model.edit, editAndUpdate);

                    editAndUpdate.html_text_frontpage = base64.urlencode(editAndUpdate.html_text_frontpage);
                    editAndUpdate.html_text_indexpage = base64.urlencode(editAndUpdate.html_text_indexpage);

                    docPackService.updateHtml(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [
                                    {
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.updatingHtml = false;
                                        },
                                    },
                                ],
                            });
                        } else {
                            vm.model.updatingHtml = false;
                            loadEdit();
                        }
                    });
                };

                vm.updateHtmlChapterChanges = function () {
                    vm.model.updatingHtmlChapter = true;

                    docPackService.updateHtmlChapter({ docpackchapter_keyno: vm.model.chapterInfo.docpackchapter_keyno }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [
                                    {
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.updatingHtmlChapter = false;
                                        },
                                    },
                                ],
                            });
                        } else {
                            vm.model.updatingHtmlChapter = false;
                            retrieveChapter();
                        }
                    });
                };

                // #endregion BUTTON UPDATE HTML PROCEDURE FUNCTION CALL

                // #region BUTTON SAVE PROCEDURE FUNCTION CALL

                vm.saveChanges = function () {
                    vm.model.lockedSave = true;

                    let editAndSave = {};
                    editAndSave = angular.copy(vm.model.edit, editAndSave);

                    editAndSave.html_text_frontpage = base64.urlencode(editAndSave.html_text_frontpage);
                    editAndSave.html_text_indexpage = base64.urlencode(editAndSave.html_text_indexpage);

                    docPackService.saveObj(editAndSave).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [
                                    {
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedSave = false;
                                        },
                                    },
                                ],
                            });
                        } else {
                            vm.model.lockedSave = false;
                        }
                    });
                };

                vm.saveChapterChanges = function () {
                    vm.model.lockedSaveChapter = true;

                    let editAndSaveChapter = {
                        docpackchapter_keyno: vm.model.chapterInfo.docpackchapter_keyno,
                        docpacktypechapter_order: vm.model.chapterInfo.docpacktypechapter_order,
                        docpacktypechapter_name: vm.model.chapterInfo.docpacktypechapter_name,
                        isrequired: vm.model.chapterInfo.isrequired,
                        is_selected: vm.model.chapterInfo.is_selected,
                        nbr_of_documents: vm.model.chapterInfo.nbr_of_documents,
                        html_text: base64.urlencode(vm.model.chapterInfo.html_text),
                    };

                    docPackService.saveChapter(editAndSaveChapter).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [
                                    {
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                            vm.model.lockedSaveChapter = false;
                                        },
                                    },
                                ],
                            });
                        } else {
                            vm.model.lockedSaveChapter = false;
                        }
                    });
                };

                // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

                // #region BUTTON DELETE PROCEDURE FUNCTION CALL

                vm.deleteValues = function () {
                    vm.model.lockedDelete = true;

                    modalService
                        .confirm({
                            type: 'danger',
                            title: translations.confirm_title,
                            message: translations.confirm_message,
                            okLabel: translations.confirm_ok_label,
                            cancelLabel: translations.confirm_cancel_label,
                        })
                        .then(
                            function () {
                                docPackService.deleteItem({ docpack_keyno: vm.model.edit.docpack_keyno }).then(
                                    function (response) {
                                        if (response[0].errorcode !== '0') {
                                            modalService.show({
                                                type: 'warning',
                                                title: translations.error,
                                                message: response[0].errormessage,
                                                buttons: [
                                                    {
                                                        label: translations.ok,
                                                        cssClass: 'btn-warning',
                                                        action: function (dialogItself) {
                                                            dialogItself.close();
                                                            vm.model.lockedDelete = false;
                                                        },
                                                    },
                                                ],
                                            });
                                        } else {
                                            vm.model.lockedDelete = false;
                                            stateService.back();
                                        }
                                    },
                                    function () {
                                        vm.model.lockedDelete = false;
                                    }
                                );
                            },
                            function () {
                                vm.model.lockedDelete = false;
                            }
                        );
                };

                // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

                // #region BUTTON GROUP SINGLE FUNCTION

                vm.selectBgDocPackMain = function (value) {
                    vm.model.bgDocPackMain.selected = value;
                };

                vm.selectBgDocPackDocuments = function (value) {
                    vm.model.bgDocPackDocuments.selected = value;
                };

                // #endregion BUTTON GROUP SINGLE FUNCTION

                // #region ON INIT FUNCTION

                vm.$onInit = function () {
                    loadEdit();
                };

                // #endregion ON INIT FUNCTION
            },
        ],
    });
})();
