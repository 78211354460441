(function () {
    'use strict';

    angular.module('imApp').component('ttDatasourceEditor', {
        templateUrl: 'views/components/dynamics/views/ttDatasourceEditor/ttDatasourceEditor.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$uibModal', 'ttDatasourceEditorService', 'translateService', 'ieScreenBlockService', function ($uibModal, ttDatasourceEditorService, translateService, ieScreenBlockService) {
            var vm = this;

            let rememberIdGrid = 'ttDatasourceEditorGrid';

            let parameters = {};

            let translations = {
                tt_confirm_delete_datasource: ''
            };

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2436,
                        parameters: function () {
                            var parms = {
                                route_id: parameters.route_id
                            }

                            return parms;
                        }
                    },
                    addRow: {
                        autoSave: true,
                        pre: function (dataItem) {
                            dataItem.name = '';
                            dataItem.data_type = 'object';
                            dataItem.route_id = parameters.route_id;

                            return dataItem;
                        },
                        post: onEditRow
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: true,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        buttons: [
                            { name: 'lineDelete', text: '', func: onDeleteRow, icon: 'far-times', type: 'danger' },
                            { name: 'lineEdit', text: '', func: onEditRow, icon: 'far-pencil' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            function onEditRow(row) {
                parameters.row = row;

                var instance = $uibModal.open({
                    component: 'ttDatasourceEdit',
                    resolve: {
                        parameters: function () {
                            return parameters;
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                instance.result.then(vm.gridOptions.gridfunc.read, vm.gridOptions.gridfunc.read);
            };

            function onDeleteRow(row) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return translations.tt_confirm_delete_datasource;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        return;
                    }

                    ieScreenBlockService.start();

                    ttDatasourceEditorService.delete(row.tt_datasource_keyno).then(function () {
                        vm.gridOptions.gridfunc.read();

                        ieScreenBlockService.stop();
                    });
                });
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                angular.copy(vm.resolve.parameters, parameters);
            };
        }]
    });
})();