(function() {
    'use strict';

    angular.module("imApp").factory("stockLimitsService", ['$ihttp', function ($ihttp) {
        let call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        let utoa = function (data) { // Utility function for base64encode with unicode support
            return btoa(unescape(encodeURIComponent(data)));
        }

        let service = {
            searchProductKeyname: function (value) {
                return $ihttp.post({
                    method: 3110,
                    parameters: {
                        searchtext: value
                    }
                });
            },
            searchSupplierKeyname: function (value) {
                return $ihttp.post({
                    method: 3109,
                    parameters: {
                        searchtext: value
                    }
                });
            },
            remember: function (rememberId, value, isBase) {
                return call(616, {
                    is_base64: angular.isDefined(isBase) ? isBase : 1, //defaults to true
                    variablename: rememberId,
                    variablevalue: angular.isDefined(isBase) && isBase === false ? angular.toJson(value) : utoa(angular.toJson(value)) //defaults to true
                });
            },
            getRemember: function (rememberId) {
                return call(973, { variablename: rememberId }); // for use if need to have a different method
            }
        }

        return service;
    }]);
})();
