(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchHeadCreate', {
        templateUrl: 'views/components/views/purchHeadCreate/purchHeadCreate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'purchHeadCreateService', 'purchHeadsService', 'typeaheadService', 'logisticService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, purchHeadCreateService, purchHeadsService, typeaheadService, logisticService) {
            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                projectNameButtons: [
                    {
                        id: 'goto',
                        icon: 'glyphicon glyphicon-search',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('selectproject', {
                            argtype: '-',
                            argvalue: '-'
                        })
                    }
                ],
                edit: {},
                selectListPurchaseStatuses: [],
                selectListCompanies: [],
                selectListStockPlaces: [],
                selectListDepartments: [],
                lockedCreate: false
            };

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                let deferred = $q.defer();

                purchHeadCreateService.getEdit({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadStockPlaces(),
                        loadDepartment()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            let loadPurchaseStatuses = function () {
                purchHeadsService.listPurchaseStatuses({ createnew: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListPurchaseStatuses);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            let loadDepartment = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;
                
                return logisticService.listDepartment({ businessco_no: vm.model.edit.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListDepartments);
                });
            };
            
            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'searchtext_supplier':
                        return typeaheadService.searchCustomer({
                            cust_name: value || '',
                            filtertype: '',
                            filtervalue: '',
                            custtype: 'S',
                            lastselect: 'SEARCH'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelectedTypeahead = function (item, id) {
                switch (id) {
                    case 'searchtext_supplier':
                        if (item) {
                            vm.model.edit.supplier_no = item?.item_id ?? '0';
                            vm.model.edit.searchtext_supplier = item?.item_name ?? '';
                        } else {
                            vm.onClearTypeahead('searchtext_supplier');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onChangedTypeahead = function (value, item, id) {
                switch (id) {
                    case 'searchtext_supplier':
                        vm.model.edit.searchtext_supplier = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.supplier_no = item.item_id;
                            }
                        } else {
                            vm.onClearTypeahead('searchtext_supplier');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onClearTypeahead = function (id) {
                switch (id) {
                    case 'searchtext_supplier':
                        vm.model.edit.supplier_no = '0';
                        vm.model.edit.searchtext_supplier = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.createChanges = function () {
                vm.model.lockedCreate = true;

                purchHeadCreateService.createObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedCreate = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedCreate = false;
                        stateService.go(response[0].webpagename, response[0].parms);
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.edit.businessco_no) !== true) return;

                        vm.model.edit.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                        loadStockPlaces();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPurchaseStatuses();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
