(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("projectService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getProject: function (parms) {
                return $ihttp.post({
                    method: 904,
                    parameters: parms || {}
                });
            },
            getP2CustomerGetEdit: function (cust_no) {
                return p2DataTaskService.call(67, {
                    cust_no: cust_no
                });
            },
            listProjectManagers: function (parms) {
                return $ihttp.post({
                    method: 2747,
                    parameters: parms || {}
                });
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },
            loadTimeRegisterMinutesPrecision: function () {
                return p2DataTaskService.call(1426, {});
            },
            //saveP2Project: function (editProject) {
            //    return p2DataTaskService.call(1001, editProject);
            //}
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1001,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
