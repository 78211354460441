(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('workingOrders', {
        templateUrl: 'views/components/views/workingOrders/workingOrders.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'workingOrdersService', function ($stateParams, workingOrdersService) {
            
            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var ok = $stateParams.ok;

            vm.model = {
                itemsListActivities: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            workingOrdersService.listCustacts({
                cust_name: 'ALL',
                cust_no: '0',
                project_keyno: '0',
                ok: ok
            }).then(function (result) {
                angular.copy(result, vm.model.itemsListActivities);
            });

		    // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();