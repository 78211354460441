(function() {
    'use strict';

    angular.module("imApp").factory("epSchenkerOverviewService", ['$ihttp', function ($ihttp) {
        var service = {
            getEdi: function (shipmentContainerKeyno) {
                return $ihttp.post({
                    method: 2061,
                    parameters: {
                        shipmentcontainer_keyno: shipmentContainerKeyno
                    }
                });
            }
        }

        return service;
    }]);
})();
