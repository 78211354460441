(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('travelBill', {
        templateUrl: 'views/components/views/travelBill/travelBill.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'travelBillService', 'typeaheadService', function ($stateParams, stateService, utilityService, modalService, translateService, travelBillService, typeaheadService) {
            
            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                travelBillNo: $stateParams.travelbill_no,
                projectButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('projectmenu', { project_keyno: vm.model.edit.project_keyno }) }
                ],
                custactNoteButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('workdetails', { custact_no: vm.model.edit.custact_no }) }
                ],
                edit: {},
                selectListLabours: [],
                selectListProjects: [],
                selectListProjectProcesses: [],
                selectListSrResourcesFrom: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ## IF CONDITIONS FUNCTION ##
            
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.edit[parmWithValue]) === true) return false;
                if (vm.model.edit[parmWithValue] === null) return false;
                if (vm.model.edit[parmWithValue].trim().length < 1) return false;
                
                return true;
            };
            
            // ## LOAD PROCEDURE CALLS ##
            
            travelBillService.loadTravelBillGet(vm.model.argType, vm.model.argValue, vm.model.travelBillNo).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
                
                if (vm.model.edit.sr === '0') {
                    loadLabours();
                }
                
                loadProject();
                loadProjectProcess();
                loadTravelBillSrResourcesFroms();
            });
            
            var loadLabours = function () {
                travelBillService.loadLabourSelectList().then(function (data) {
                    angular.copy(data, vm.model.selectListLabours);
                });
            };
            
            var loadProject = function () {
                if (angular.isUndefined(vm.model.edit.cust_no) === true) return;
                if (vm.model.edit.cust_no === null) return;
                if (vm.model.edit.cust_no.trim().length < 1) return;
                
                travelBillService.loadProjectSelectList(vm.model.edit.cust_no).then(function (data) {
                    angular.copy(data, vm.model.selectListProjects);
                });
            };
            
            var loadProjectProcess = function () {
                if (angular.isUndefined(vm.model.edit.project_keyno) === true) return;
                if (vm.model.edit.project_keyno === null) return;
                if (vm.model.edit.project_keyno.trim().length < 1) return;
                
                travelBillService.loadProjectProcessSelectList(vm.model.edit.project_keyno, '1').then(function (data) {
                    angular.copy(data, vm.model.selectListProjectProcesses);
                });
            };
            
            var loadTravelBillSrResourcesFroms = function () {
                if (validateParmsWithValue('custact_no') !== true) return;
                
                vm.model.selectListSrResourcesFrom = [];
                
                travelBillService.loadTravelBillSrResourcesFromList(vm.model.edit.custact_no).then(function (data) {
                    angular.copy(data, vm.model.selectListSrResourcesFrom);
                });
            };
            
            var loadDietDaysInfo = function () {
                if (angular.isUndefined(vm.model.edit.start_dt) === true) return;
                if (vm.model.edit.start_dt === null) return;
                if (vm.model.edit.start_dt.trim().length < 1) return;
                
                if (angular.isUndefined(vm.model.edit.end_dt) === true) return;
                if (vm.model.edit.end_dt === null) return;
                if (vm.model.edit.end_dt.trim().length < 1) return;
                
                travelBillService.loadDietDaysInfoUpdate(vm.model.edit.start_dt, vm.model.edit.end_dt).then(function (data) {
                    vm.model.edit.diet_days = data[0].diet_days;
                    vm.model.edit.diet_hours = data[0].diet_hours;
                    vm.model.edit.nights = data[0].nights;
                    vm.model.edit.nbr_of_days = data[0].nbr_of_days;
                });
            };
            
            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value) {
                return typeaheadService.searchCustomer({
                    cust_name: value || '',
                    filtertype: '',
                    filtervalue: '',
                    custtype: 'C',
                    lastselect: 'SEARCH'
                });
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onSelected = function (item) {
                if (item) {
                    vm.model.edit.cust_no = item?.item_id ?? '0';
                    vm.model.edit.cust_name = item?.item_name ?? '';

                    loadProject();
                } else {
                    vm.onClear();
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id, item) {
                switch (id) {
                    case 'cust_name':
                        vm.model.edit.cust_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.cust_no = item.item_id;

                                loadProject();
                            }
                        } else {
                            vm.onClear();
                        }
                        break;
                    case 'project_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.project_keyno) !== true) return;

                        vm.model.edit.project_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.project_keyno, true) !== true) return;

                        loadProjectProcess();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region CLEAR VALUE FUNCTION

            vm.onClear = function () {
                vm.model.edit.cust_no = '0';
                vm.model.edit.project_keyno = '0';
                vm.model.edit.cust_name = '';
                vm.model.selectListProjects = [];
                vm.model.selectListProjectProcesses = [];
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'start_dt':
                    case 'end_dt':
                        if (utilityService.validateParmsValue(vm.model.edit[id]) !== true) return;

                        loadDietDaysInfo();
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                travelBillService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
            
        }]
    });
})();
