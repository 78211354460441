(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('projectMenu', {
        templateUrl: 'views/components/views/projectMenu/projectMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'projectMenuService', 'rememberService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, projectMenuService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let projectKeyno = $stateParams.project_keyno;

            let variableNames = {
                gb_projectmenu_main: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgProjectMenuMain: {
                    selected: 'EDIT',
                    buttons: [
                        { id: 'EDIT', icon: 'far fa-edit', label: 'gb_projectmenu_main_edit', color: 'primary', onClick: () => setProjectMenuMainView('EDIT') },
                        { id: 'CUSTACTS', icon: 'far fa-tasks', label: 'gb_projectmenu_main_custacts', color: 'primary', onClick: () => setProjectMenuMainView('CUSTACTS') },
                        { id: 'DOCUMENTS', icon: 'far fa-file-alt', label: 'gb_projectmenu_main_documents', color: 'primary', onClick: () => setProjectMenuMainView('DOCUMENTS') },
                        { id: 'ORDERHEADS', icon: 'far fa-file-contract', label: 'gb_projectmenu_main_orderheads', color: 'primary', onClick: () => setProjectMenuMainView('ORDERHEADS') },
                        { id: 'PURCHHEADS', icon: 'far fa-shopping-cart', label: 'gb_projectmenu_main_purchheads', color: 'primary', onClick: () => setProjectMenuMainView('PURCHHEADS') },
                        { id: 'INCOMING_INVOICE', icon: 'far fa-dollar-sign', label: 'gb_projectmenu_main_incoming_invoice', color: 'primary', onClick: () => setProjectMenuMainView('INCOMING_INVOICE') },
                        { id: 'POWTIMES', icon: 'far fa-clock', label: 'gb_projectmenu_main_powtimes', color: 'primary', onClick: () => setProjectMenuMainView('POWTIMES') },
                        { id: 'DOCPACKS', icon: 'far fa-box', label: 'gb_projectmenu_main_docpacks', color: 'primary', onClick: () => setProjectMenuMainView('DOCPACKS') },
                        { id: 'FUNCTIONS', icon: 'far fa-ellipsis-h', label: 'gb_projectmenu_main_functions', color: 'primary', onClick: () => setProjectMenuMainView('FUNCTIONS') }
                    ]
                },
                menu: {},
                gridActivityReady: false,
                gridDocumentsReady: false,
                gridOrdersReady: false,
                gridPurchasesReady: false,
                gridWorkingHoursReady: false,
                gridDocumentationPackagesReady: false
            };

            vm.gridActivity = {
                dataTask: {
                    rememberId: 'w_projectmenu.custacts_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridDocuments = {
                dataTask: {
                    rememberId: 'w_projectmenu.documents_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridOrders = {
                dataTask: {
                    rememberId: 'w_projectmenu.orderheads_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridPurchases = {
                dataTask: {
                    rememberId: 'w_projectmenu.purchheads_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridWorkingHours = {
                dataTask: {
                    rememberId: 'w_projectmenu.powtimes_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridDocumentationPackages = {
                dataTask: {
                    rememberId: 'projectmenu.docpack_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'projectmenu_docpacktype_create',
                                text: 'projectmenu_docpacktype_create',
                                func: function () {
                                    if (vm.model.menu.nbr_of_docpacktypes < 2) {
                                        retrieveDocumentPack();
                                    } else {
                                        stateService.go('docpackcreate', {
                                            argtype: 'project_keyno',
                                            argvalue: vm.model.menu.project_keyno
                                        });
                                    }
                                },
                                icon: 'fa-plus',
                                cssClass: 'btn btn-primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setProjectMenuMainView = function (id) {
                vm.model.bgProjectMenuMain.selected = vm.model.menu.gb_projectmenu_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALL
    
            let loadMenu = function () {
                const deferred = $q.defer();

                projectMenuService.getMenu({ project_keyno: projectKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                    stateService.setTitleOverride(vm.model.menu.webpage_heading);
                    setProjectMenuMainView(vm.model.menu.gb_projectmenu_main);

                    loadGridActivity();
                    loadGridDocuments();
                    loadGridOrders();
                    loadGridPurchases();
                    loadGridWorkingHours();
                    loadGridDocumentationPackages();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE CALL

            // #region CREATE PROCEDURE FUNCTION CALL

            let retrieveDocumentPack = function () {
                if (utilityService.validateParmsValue(vm.model.menu.docpacktype_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.menu.project_keyno) !== true) return;

                return projectMenuService.createDocumentPack({
                    docpacktype_keyno: vm.model.menu.docpacktype_keyno,
                    argtype: 'project_keyno',
                    argvalue: vm.model.menu.project_keyno
                }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        loadGridDocumentationPackages();
                    }
                });
            };

            // #endregion CREATE PROCEDURE FUNCTION CALL

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGridActivity = function () {
                vm.gridActivity.dataTask.loadData = {
                    method: 2452,
                    parameters: {
                        searchtext: '',
                        ok: '0',
                        filterfield: 'project_keyno',
                        filtervalue: vm.model.menu.project_keyno,
                        custacttype: 'A',
                        acttype: 'all',
                        activitytype_no: '0',
                        actstatus_keyno: '0',
                        bg_main: 'SEARCH',
                        bg_search: 'RESULT',
                        member_portal_user_keyno: '0',
                        reg_of_portal_user_keyno: '0',
                        cust_no: '0'
                    }
                };

                vm.model.gridActivityReady = true;

                if (vm.gridActivity.gridfunc !== null) vm.gridActivity.gridfunc.read();
            };

            let loadGridDocuments = function () {
                vm.gridDocuments.dataTask.loadData = {
                    method: 2972,
                    parameters: {
                        argtype: 'project_keyno',
                        argvalue: vm.model.menu.project_keyno,
                        searchtext: '',
                        doctype: 'd',
                        onlyFilenames: '0'
                    }
                };

                vm.model.gridDocumentsReady = true;

                if (vm.gridDocuments.gridfunc !== null) vm.gridDocuments.gridfunc.read();
            };
            
            let loadGridOrders = function () {
                vm.gridOrders.dataTask.loadData = {
                    method: 2852,
                    parameters: {
                        cust_no: '0',
                        searchtext_customer: '',
                        orderstatus_id: '0',
                        filterval: '',
                        cust_name: '',
                        gb_ordertype: '0',
                        gb_orderdate: '0',
                        gb_orderstatus: '0',
                        ordertype_id: 'All',
                        cust_no_and_name: '0',
                        businessco_no: '99999',
                        show_setting: '1',
                        argtype: 'project_keyno',
                        argvalue: vm.model.menu.project_keyno, //'11500',
                        show_all_orders: '1',
                        ordersearch: '',
                        show_new_order_button: '1',
                        page_heading: vm.model.menu.webpage_heading, //'00 Testprosjekt x',
                        gb_regby: 'ALL',
                        show_gb_regby: '1',
                        reg_by_portal_user_keyno: '0',
                        date_fom: '1901-01-01',
                        date_tom: '2999-12-31',
                        dateselector_index: '8',
                        show_selection_ordertype: '1',
                        show_dateselector: '1',
                        date_fomtom: '1901-01-012999-12-31',
                        selected_order_internal_no: '0',
                        search_confirmed_deliverydate: '1',
                        current_portal_user_keyno: '1',
                        page: 1,
                        pageSize: 10,
                        gb_orderheads_related: 'orderheads_custact_related',
                        webpage: stateService.getCurrentPath() //'orderheads/project_keyno/11500'
                    }
                };

                vm.model.gridOrdersReady = true;

                if (vm.gridOrders.gridfunc !== null) vm.gridOrders.gridfunc.read();
            };

            let loadGridPurchases = function () {
                vm.gridPurchases.dataTask.loadData = {
                    method: 3059,
                    parameters: {
                        argtype: 'project_keyno',
                        argvalue: vm.model.menu.project_keyno,
                        supplier_no: '0',
                        searchtext_supplier: '',
                        purchasestatus_id: '999',
                        filterval: '',
                        supplier_name: '',
                        supplier_no_and_name: '',
                        portal_user_keyno: '0',
                        gb_whos: '0',
                        gb_purchasetype: '0',
                        gb_purchasetype_purch: '0',
                        gb_purchdate: '',
                        webpage: stateService.getCurrentPath(),
                        businessco_no: '0',
                        searchtext: ''
                    }
                };

                vm.model.gridPurchasesReady = true;

                if (vm.gridPurchases.gridfunc !== null) vm.gridPurchases.gridfunc.read();
            };

            let loadGridWorkingHours = function () {
                vm.gridWorkingHours.dataTask.loadData = {
                    method: 2127,
                    parameters: {
                        businessco_no: '99999',
                        show_setting: '1',
                        argtype: '-',
                        argvalue: '-',
                        date_fom: '1901-01-01',
                        date_tom: '2999-12-31',
                        dateselector_index: '8',
                        view_id: 'w_powtimes.grid',
                        date_fomtom: '1901-01-012999-12-31',
                        labour_no: '0',
                        cust_no: '0',
                        cust_name: '',
                        project_keyno: vm.model.menu.project_keyno,
                        project_name: vm.model.menu.project_name
                    }
                };

                vm.model.gridWorkingHoursReady = true;

                if (vm.gridWorkingHours.gridfunc !== null) vm.gridWorkingHours.gridfunc.read();
            };

            let loadGridDocumentationPackages = function () {
                vm.gridDocumentationPackages.dataTask.loadData = {
                    method: 3282,
                    parameters: {
                        argtype: 'project_keyno',
                        argvalue: vm.model.menu.project_keyno,
                    }
                };

                vm.model.gridDocumentationPackagesReady = true;

                if (vm.gridDocumentationPackages.gridfunc !== null) vm.gridDocumentationPackages.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_projectmenu_main':
                        if (utilityService.validateParmsValue(vm.model.menu[id]) !== true) return;

                        variableValue = vm.model.menu[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgProjectMenuMain = function (value) {
                vm.model.bgProjectMenuMain.selected = value;
                vm.model.menu.gb_projectmenu_main = vm.model.bgProjectMenuMain.selected;

                rememberFunc('gb_projectmenu_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
