(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('serviceObjectTypeInt', {
        templateUrl: 'views/components/views/serviceObjectTypeInt/serviceObjectTypeInt.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'economyService', 'serviceObjectTypeIntService', function ($stateParams, stateService, utilityService, economyService, serviceObjectTypeIntService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
            vm.model = {
                serviceObjectTypeKeyNo: $stateParams.serviceobjecttype_keyno,
                serviceObjectTypeIntKeyNo: $stateParams.serviceobjecttypeint_keyno,
                interval: {},
                selectListActivities: [],
                lockedSave: false,
                lockedDelete: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            serviceObjectTypeIntService.loadServiceObjectTypeIntGet(vm.model.serviceObjectTypeKeyNo, vm.model.serviceObjectTypeIntKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.interval);
            });
    
            economyService.loadActivityTypeList().then(function (data) {
                angular.copy(data, vm.model.selectListActivities);
            });
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveServiceObjectTypeInt = function () {
                vm.model.lockedSave = true;
                
                serviceObjectTypeIntService.save(vm.model.interval).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteServiceObjectTypeInt = function () {
                vm.model.lockedDelete = true;
                
                serviceObjectTypeIntService.deleteObj(vm.model.interval.serviceobjecttypeint_keyno).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelete = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();