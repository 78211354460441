(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2DataTaskEdit', {
        templateUrl: 'views/components/views/p2DataTaskEdit/p2DataTaskEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2DataTaskEditService', 'stateService', function ($stateParams, p2DataTaskEditService, stateService) {
            var vm = this;
            var isSaving = false;
            
            vm.lockingP2DataTaskEditSaveButton = {
                locked: '0'
            };
            
            p2DataTaskEditService.loadP2DataTaskEdit($stateParams.p2_datatask_keyno || '0').then(function () {
                vm.p2_datatask_edit = p2DataTaskEditService.p2_datatask_edit;
            });
            
            vm.saveP2DataTaskEdit = function () {
                if (isSaving == true || vm.lockingP2DataTaskEditSaveButton.locked == '1') return;

                isSaving = true;
                vm.lockingP2DataTaskEditSaveButton.locked = '1';

                p2DataTaskEditService.saveP2DataTaskEdit().then(function () {
                    stateService.back();
                });

                isSaving = false;
            };
        }]
    });
})();