(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('factory', {
        templateUrl: 'views/components/views/factory/factory.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'factoryService', 'utilityService', 'logisticService', function ($stateParams, stateService, factoryService, utilityService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            vm.model = {
                factoryId: $stateParams.factory_id,
                editFactory: {},
                selectListBusinessCompanies: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            factoryService.getFactory(vm.model.factoryId).then(function (data) {
            	angular.copy(data[0], vm.model.editFactory);
            });

            logisticService.listBusinessCompanies().then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCompanies);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE PROCEDURE FUNCTION CALL

            var saveFactory = function () {
                vm.model.lockedSave = true;
                
                factoryService.saveObj(vm.model.editFactory).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion SAVE PROCEDURE FUNCTION CALL

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'save':
                        saveFactory();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
            	angular.forEach(onDestroy, function (fn) {
            		if (angular.isFunction(fn) === true) {
            			fn();
            		}
            	});
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
