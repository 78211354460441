import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ɵcoerceToBoolean } from '@angular/core';
import { isObject } from 'angular';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { WidgetTextConfig } from '../widget-text/widget-text.component';
import { WidgetService } from '../widget.service';

export interface WidgetChartConfig {
    options?: ChartOptions;
    chartType: ChartType;
    data: ChartData;
}

@Component({
    selector: 'tt-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.css'],
})
export class WidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() ttMainHeading?: string = '';
    @Input() ttSubHeading?: string = '';
    @Input() ttIcon?: string = '';
    @Input() ttModel?: string | WidgetChartConfig | WidgetTextConfig = '';
    @Input() ttUnit?: string = '';
    @Input() ttColor?: string = '';
    @Input() ttCanResize: boolean = false;
    @Input() ttCanMove: boolean = false;
    @Input() ttType: string = 'text';

    @Output() ttResize = new EventEmitter<WidgetResize>();
    @Output() ttResizeComplete = new EventEmitter<void>();
    @Output() ttMove = new EventEmitter<WidgetDOMRect>();
    @Output() ttMoveComplete = new EventEmitter<void>();

    constructor(public widgetService: WidgetService) {}

    id = {
        widget: crypto.randomUUID(),
    };

    style: { widget: Partial<CSSStyleDeclaration> } = {
        widget: {},
    };

    private elements: { widget: null | HTMLDivElement } = {
        widget: null,
    };

    anchors: Partial<ResizeDirection>[] = ['north', 'north-east', 'north-west', 'south', 'south-east', 'south-west', 'west', 'east'];

    cancelEvent = true;
    isPressing = false;
    lastAnchor?: ResizeDirection;
    isWidgetPressing = false;
    dragOffsetY = 0;
    dragOffsetX = 0;

    public onWidgetMove(event: PointerEvent | DragEvent) {
        if (this.isWidgetPressing === false || this.isPressing === true) return;
        const widgetDOMRect = this.getWidgetDOMRect();

        if (widgetDOMRect) {
            this.style.widget.position = 'fixed';
            this.style.widget.height = widgetDOMRect.height + 'px';
            this.style.widget.width = widgetDOMRect.width + 'px';
            this.style.widget.top = `${event.clientY - this.dragOffsetY}px`;
            this.style.widget.left = `${event.clientX - this.dragOffsetX}px`;
            this.style.widget.zIndex = '4';

            this.ttMove.emit({ ...widgetDOMRect, x: widgetDOMRect.x, y: widgetDOMRect.y, width: widgetDOMRect.width, height: widgetDOMRect.height, top: widgetDOMRect.top, right: widgetDOMRect.right, bottom: widgetDOMRect.bottom, left: widgetDOMRect.left, pointerX: event.clientY, pointerY: event.clientX, offsetX: event.offsetX, offsetY: event.offsetY });
        }
    }

    public onWidgetMouseDown(event: PointerEvent | DragEvent) {
        if (!this.ttCanMove || this.isPressing) return;

        this.isWidgetPressing = true;
        this.style.widget.cursor = 'grabbing';
        this.style.widget.userSelect = 'none';
        this.dragOffsetY = event.offsetY;
        this.dragOffsetX = event.offsetX;
    }

    public onPointerDown(event: PointerEvent, resizeDirection: ResizeDirection) {
        // console.dir(event);
        if (this.ttCanResize !== true) return;
        this.cancelEvent = false;
        this.isPressing = true;
        this.lastAnchor = resizeDirection;

        const widgetDOMRect = this.getWidgetDOMRect();

        if (widgetDOMRect) {
            // console.dir('setheight');
            this.style.widget.userSelect = 'none';
            this.style.widget.position = 'fixed';
            this.style.widget.height = `${widgetDOMRect.height}px`;
            this.style.widget.width = `${widgetDOMRect.width}px`;
            this.style.widget.top = resizeDirection.includes('south') ? `${event.clientY - widgetDOMRect.height}px` : event.clientY + 'px';

            this.style.widget.zIndex = '4';
        }
    }

    public onPointerCancel(event: Event) {
        this.cancelEvent = true;
    }

    private widgetAnchorResize(event: PointerEvent) {
        if (!this.cancelEvent && this.lastAnchor && this.isPressing && !this.isWidgetPressing) {
            const widgetDOMRect = this.getWidgetDOMRect();

            if (!widgetDOMRect) return;

            if (this.lastAnchor === 'east' || this.lastAnchor === 'north-east' || this.lastAnchor === 'south-east') {
                this.style.widget.width = `${event.clientX - (widgetDOMRect?.x ?? 0)}px`;
            }

            if (this.lastAnchor === 'west' || this.lastAnchor === 'north-west' || this.lastAnchor === 'south-west') {
                this.style.widget.width = `${(widgetDOMRect?.x ?? 0) - event.clientX + (widgetDOMRect?.width ?? 0)}px`;
                this.style.widget.left = event.clientX + 'px';
            }

            if (this.lastAnchor === 'south' || this.lastAnchor === 'south-east' || this.lastAnchor === 'south-west') {
                // console.log(event.pageY - (event.clientY - (widgetDOMRect?.y ?? 0)));
                // console.dir(event);
                this.style.widget.height = `${event.clientY - (widgetDOMRect?.y ?? 0)}px`;
                this.style.widget.top = `${event.pageY - (event.clientY - (widgetDOMRect?.y ?? 0))}px`;
            }

            if (this.lastAnchor === 'north' || this.lastAnchor === 'north-east' || this.lastAnchor === 'north-west') {
                this.style.widget.height = `${widgetDOMRect.y - event.clientY + widgetDOMRect.height}px`;
                this.style.widget.top = event.clientY + 'px';
            }

            if (widgetDOMRect) {
                this.ttResize.emit({ ...widgetDOMRect, x: widgetDOMRect.x, y: widgetDOMRect.y, width: widgetDOMRect.width, height: widgetDOMRect.height, top: widgetDOMRect.top, right: widgetDOMRect.right, bottom: widgetDOMRect.bottom, left: widgetDOMRect.left, pointerX: event.clientY, pointerY: event.clientX, offsetX: event.offsetX, offsetY: event.offsetY, resizeDirection: this.lastAnchor });
            }
        }
    }

    private getWidgetDOMRect() {
        if (!this.elements.widget) this.elements.widget = document.getElementById(this.id.widget) as HTMLDivElement | null;

        if (this.elements.widget) return this.elements.widget.getBoundingClientRect();

        return null;
    }

    @HostListener('document:pointermove', ['$event'])
    public onPointerMove(event: PointerEvent) {
        if (this.isPressing) {
            this.widgetAnchorResize(event);
        }

        if (this.isWidgetPressing) {
            this.onWidgetMove(event);
        }
    }

    @HostListener('document:pointerup', ['$event'])
    public onPointerUp(event: PointerEvent) {
        this.cancelEvent = true;
        if (this.isPressing || this.isWidgetPressing) {
            delete this.style.widget.position;
            delete this.style.widget.height;
            delete this.style.widget.width;
            delete this.style.widget.top;
            delete this.style.widget.left;
            delete this.style.widget.zIndex;
        }

        const widgetDOMRect = this.getWidgetDOMRect();

        if (this.isPressing) {
            this.isPressing = false;

            if (widgetDOMRect && this.lastAnchor) {
                this.ttResize.emit({ ...widgetDOMRect, x: widgetDOMRect.x, y: widgetDOMRect.y, width: widgetDOMRect.width, height: widgetDOMRect.height, top: widgetDOMRect.top, right: widgetDOMRect.right, bottom: widgetDOMRect.bottom, left: widgetDOMRect.left, pointerX: event.clientY, pointerY: event.clientX, offsetX: event.offsetX, offsetY: event.offsetY, resizeDirection: this.lastAnchor });
                this.ttResizeComplete.emit();
            }
        }

        if (this.isWidgetPressing) {
            this.isWidgetPressing = false;
            this.style.widget.cursor = 'grab';

            if (widgetDOMRect) {
                this.ttMove.emit({ ...widgetDOMRect, x: widgetDOMRect.x, y: widgetDOMRect.y, width: widgetDOMRect.width, height: widgetDOMRect.height, top: widgetDOMRect.top, right: widgetDOMRect.right, bottom: widgetDOMRect.bottom, left: widgetDOMRect.left, pointerX: event.clientY, pointerY: event.clientX, offsetX: event.offsetX, offsetY: event.offsetY });
                this.ttMoveComplete.emit();
            }
        }
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        // console.log('Widget changed');
        // console.dir(changes);
        if (changes?.['ttCanMove']?.currentValue !== null && changes?.['ttCanMove']?.currentValue !== undefined) {
            this.ttCanMove = changes['ttCanMove'].currentValue === true || changes['ttCanMove'].currentValue === 'true';

            if (this.ttCanMove) {
                this.style.widget.cursor = 'grab';
            } else {
                delete this.style.widget.cursor;
            }
        }
    }

    ngOnDestroy(): void {}
}

export interface WidgetDOMRect extends DOMRect {
    pointerX: number;
    pointerY: number;
    offsetX: number;
    offsetY: number;
}

export interface WidgetResize extends WidgetDOMRect {
    resizeDirection: ResizeDirection;
}

type ResizeDirection = 'north' | 'north-east' | 'north-west' | 'south' | 'south-east' | 'south-west' | 'west' | 'east';
