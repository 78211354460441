(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStateRemarks', {
        templateUrl: 'views/components/views/oeeStateRemarks/oeeStateRemarks.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'oeeStateRemarksService', function ($stateParams, stateService, oeeStateRemarksService) {
            var vm = this;
            
            oeeStateRemarksService.loadP2OeeStateRemarksList($stateParams.oeestate_keyno).then(function () {
                vm.p2_oeestateremarks_list = oeeStateRemarksService.p2_oeestateremarks_list;
            });
            
            vm.createNewOeeStateRemark = function () {
                stateService.go('oeestateremark', {
                    oeestate_keyno: $stateParams.oeestate_keyno,
                    oeestateremark_keyno: '0'
                });
            };
        }]
    });
})();