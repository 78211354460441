(function () {
	'use strict';

    var module = angular.module('imApp');

    module.component('powTimePayCheckLs', {
        templateUrl: 'views/components/views/powTimePayCheckLs/powTimePayCheckLs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'powTimePayCheckLsService', function ($stateParams, powTimePayCheckLsService) {

    		// ## VARIABLES & DEFINITIONS ##

            var vm = this;
            
    		vm.model = {
    			argType: $stateParams.argtype,
    			argValue: $stateParams.argvalue,
    			itemsListPowTimePayCheckLs: []
    		};
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		powTimePayCheckLsService.loadPowTimePayCheckLsList(vm.model.argType, vm.model.argValue).then(function (data) {
    			angular.copy(data, vm.model.itemsListPowTimePayCheckLs);
    		});
    	}]
    });
})();