import { ILoadingOverlayAngularComp } from '@ag-grid-community/angular';
import { ILoadingOverlayParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
    selector: 'tt-grid-loading-overlay',
    templateUrl: './grid-loading-overlay.component.html',
    styleUrls: ['./grid-loading-overlay.component.css'],
})
export class GridLoadingOverlayComponent implements ILoadingOverlayAngularComp {
    public params!: ILoadingOverlayParams & { loadingMessage: string };

    agInit(params: ILoadingOverlayParams & { loadingMessage: string }): void {
        this.params = params;
    }
}
