(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('textileSizes', {
        templateUrl: 'views/components/views/textileSizes/textileSizes.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['textileSizesService', 'ttGridFactory', function (textileSizesService, ttGridFactory) {
            var vm = this;

            vm.grid = new ttGridFactory({
                rememberId: 'w_textileSizes.grid',
                loadData: {
                    method: 3053,
                    parameters: {}
                }
            }).setOptionFunc((data) => optionfunc(data.data));

            vm.gridlang = new ttGridFactory({
                rememberId: 'w_textileSizeslang.grid',
                loadData: {
                    method: 3055,
                    parameters: {
                        textilesize_id: ''
                    }
                }
            });

            let optionfunc = function (data) {
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    if (data?.clickedCell?.dataItem?.textilesize_id && vm.gridlang?.gridfunc) {
                        if (vm.gridlang.dataTask.loadData.parameters.textilesize_id === data.clickedCell.dataItem.textilesize_id) return;

                        vm.gridlang.dataTask.loadData.parameters.textilesize_id = data.clickedCell.dataItem.textilesize_id;
                        vm.gridlang.gridfunc.rebind();
                    }
                }
            };
        }]
    });
})();
