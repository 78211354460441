(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('accountingBunts', {
        templateUrl: 'views/components/views/accountingBunts/accountingBunts.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['accountingBuntsComponentService', 'utilityService', 'stateService', 'modalService', 'translateService', 'logisticService', 'rememberService', function (accountingBuntsComponentService, utilityService, stateService, modalService, translateService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            var variableNames = {
                businessco_no: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                settingAccountingBunts: {},
                selectListBusinessCompanies: [],
                gridReady: false,
                creatingNew: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            accountingBuntsComponentService.getAccountingBunts().then(function (data) {
                angular.copy(data[0], vm.model.settingAccountingBunts);

		        if (utilityService.validateParmsValue(vm.model.settingAccountingBunts.view_id) === true) {
			        vm.grid.dataTask.rememberId = vm.model.settingAccountingBunts.view_id;
		        }

                if (utilityService.validateParmsValue(vm.model.settingAccountingBunts.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.settingAccountingBunts.businessco_no) === true) {
			        vm.grid.dataTask.loadData = {
				        method: vm.model.settingAccountingBunts.p2_datatask_keyno,
                        parameters: vm.model.settingAccountingBunts
			        };
                }

                vm.model.gridReady = true;
            });

            logisticService.listBusinessCompanies('1').then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCompanies);
            });

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.settingAccountingBunts.businessco_no) !== true) return;

                        variableValue = vm.model.settingAccountingBunts.businessco_no;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region ON FIELD CHANGED FUNCTION

            vm.onBusinessCoChanged = function (value, id) {
                vm.model.settingAccountingBunts.businessco_no = value;
                
                vm.grid.gridfunc.rebind();
                rememberFunc('businessco_no');
            };

		    // #endregion ON FIELD CHANGED FUNCTION

            // #region SAVE BUTTON FUNCTION

            vm.createNewChanges = function () {
                vm.model.creatingNew = true;
                
                accountingBuntsComponentService.createNew(vm.model.settingAccountingBunts).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                                    vm.model.creatingNew = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.creatingNew = false;
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

		    // #endregion SAVE BUTTON FUNCTION
        }]
    });
})();
