(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('eCommerceMenu', {
        templateUrl: 'views/components/views/eCommerceMenu/eCommerceMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$state', 'eCommerceMenuService', function($state, eCommerceMenuService) {
            var vm = this;
            
            vm.model = {
            	menu: {
            		records: []
            	}
            };
            
            var loadMenu = function () {
                eCommerceMenuService.genericFunction(53, { webpage_name: $state.current.name }).then(function (data) {
                    angular.copy(data, vm.model.menu.records);
                });
            };

    		loadMenu();
        }]
    });
})();