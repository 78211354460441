(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("jobQueueMonitorService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadJobQueueMonitorGet: function () {
                return p2DataTaskService.call(1723, {});
            }
        };

        return service;
    }]);
})();