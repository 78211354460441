(function() {
    'use strict';

    // SKAL DEKLARERE ANGULAR-MODUL
    var module = angular.module("imApp");

    // SKAL DEKLARERE SERVICE-NAMNET OG INJISERE INNEBYGDE ANGULAR-SERVICAR I BÅDE NAMN OG FUNKSJON SOM BLIR BRUKT I CRMMENUSERVICE
    module.factory("productMenuService", ['$q', '$ihttp', '$state', function($q, $ihttp, $state) {
        var service = {                 // OVERORDNA VARIABEL SOM SKAL INKLUDERE DEKLARERTE OBJEKT OG FUNKSJONAR
            loaded: false               // SKAL DEKLARE AV/PÅ-FUNKSJON FOR INNLASTING MED BOOLSKE VERDIAR
        }

        return service;     // SKAL RETURNERE TILBAKE TIL START I SERVICEN
    }]);
})();