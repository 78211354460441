(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productCategoryLang', {
        templateUrl: 'views/components/views/productCategoryLang/productCategoryLang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'productCategoryLangService', function ($stateParams, stateService, productCategoryLangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var productCategoryKeyno = $stateParams.productcategory_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            productCategoryLangService.listProductCategoryLanguages({ productcategory_keyno: productCategoryKeyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                productCategoryLangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();