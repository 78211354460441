(function() {
    'use strict';

    angular.module("imApp").factory("epShipmentCompletionScanService", ['$ihttp', '$q', function ($ihttp, $q) {
        var service = {
            scan: function (scandata, scKeyno) {
                return $ihttp.post({
                    method: 1947,
                    parameters: {
                        scandata: scandata,
                        shipmentcontainer_keyno: scKeyno
                    }
                });
            },

            loadShipments: function (scKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 1995,
                    parameters: {
                        shipmentcontainer_keyno: scKeyno
                    }
                }).then(function (result) {
                    var setStyle = function (colors, index) {
                        colors[index].style = {
                            backgroundColor: colors[index].b,
                            color: colors[index].b !== 'Default' ? 'white' : 'black',
                            padding: '3px 10px 3px 10px'
                        }
                    };

                    for (var i = 0; i < result.length; i++) {
                        if (angular.isDefined(result[i].Colors)) {
                            result[i].Colors = angular.fromJson(result[i].Colors);

                            setStyle(result[i].Colors, 0);
                            setStyle(result[i].Colors, 1);
                            setStyle(result[i].Colors, 2);
                        }
                    }

                    deferred.resolve(result);
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
