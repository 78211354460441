(function () {
	'use strict';

	let module = angular.module("imApp");

	module.factory("stockCountApproveService", ['p2DataTaskService', function (p2DataTaskService) {
		let service = {
			loadStockCountApproveGet: function () {
				return p2DataTaskService.call(1344, {});
			},
			loadStockCountApprovePossibleList: function (stockcount_keyno) {
				return p2DataTaskService.call(1328, {
					stockcount_keyno: stockcount_keyno
				});
			},
			loadStockCountApproveManualList: function (stockcount_keyno ) {
				return p2DataTaskService.call(1329, {
					stockcount_keyno: stockcount_keyno
				});
			},
			loadStockCountApprovedList: function (stockcount_keyno) {
				return p2DataTaskService.call(1343, {
					stockcount_keyno: stockcount_keyno
				});
			},
			loadStockCountApproveOne: function (stockcountdata_keyno) {
				return p2DataTaskService.call(1330, {
					stockcountdata_keyno: stockcountdata_keyno
				});
			}
		};

		return service;
	}]);
})();