(function () {
    'use strict';

    angular.module('imApp')
    .directive('imSchedulerDirective.old', ['$q', '$ihttp', '$stateParams', '$timeout', '$rootScope', '$compile', 'userService', 'stateService', 'responsive', function ($q, $ihttp, $stateParams, $timeout, $rootScope, $compile, userService, stateService, responsive) {
        var directive = {
            restrict: 'E',
            //transclude: true,
            scope: {
                field: '=',         // Links in the values                                                          //REQUIRED
                listItems: '=',     // Used for linking a list to the directive
                owners: '=',        // Used for linking a list to the directive
                setup: '=',         // Used for linking the setup variables 
                //optionfunc: '&',    // Used as reference to funtion in Controller
            },
            template:
                '<div ng-class="{\'lock-screen\': model.moving === true}" ' +
                'id="{{model.mId}}" kendo-scheduler ' +
                'k-options="schedulerOptions">' +
                //'k-options="schedulerOptions" ' +
                //'k-rebind="listItems.length">' +
                '</div>',
            link: function ($scope, $element) {
                $scope.model = {
                    mId: uuid(),
                    moving: false,
                    schedulerDataSource: [],
                    schedulerDataSourceOwners: [],
                    setup: {},
                    eventTemplate: '',
                    initiated: false,
                    reading: false,
                    hasData: { hasTasks: false, hasOwners: false, hasSetup: false, changeCounter: 0 },
                    previousView: 'week',
                    scheduler: {}
                    //saveNDResponse: [],
                };

                //$scope.saveNewDate = function (variablename, variablevalue) {
                //    var deferred = $q.defer();

                //    var parmsP2PortalUserVariableSetWs = {
                //        method: 616,
                //        parameters: {
                //            variablename: variablename,
                //            variablevalue: variablevalue
                //        }
                //    };

                //    // Prosedyre sp_ws_p2_portal_user_variable_set_ws
                //    $ihttp.post(parmsP2PortalUserVariableSetWs).then(function (data) {
                //        console.log('sp_ws_p2_portal_user_variable_set_ws data:',angular.toJson(data));

                //        $scope.model.saveNDResponse = angular.copy(data[0]);

                //        deferred.resolve();
                //    });

                //    return deferred.promise;
                //}

                $scope.onChange = function (e) {
                    console.log("Task changed: " + e);
                };

                $scope.onAdd = function (e) {
                    console.log("Task added: " + e);
                };

                $scope.onEdit = function (e) {
                    console.log("Task about to be edited :: " + e);
                };

                $scope.onCancel = function (e) {
                    console.log("Cancel task edit :: " + e);
                };

                $scope.onRemove = function (e) {
                    console.log("Task removed :: " + e);
                    console.dir(e);
                    if (e.event.editable === false) {
                        e.preventDefault();
                    }
                };

                $scope.onSave = function (e) {
                    console.log("Task saved :: " + e); //Saved
                    console.dir(e);
                    var sd = new Date(e.event.start);
                    var ed = new Date(e.event.end);

                    sd.setHours(sd.getHours() + 2);
                    ed.setHours(ed.getHours() + 2);

                    console.log('webpagename: ' + stateService.getCurrentName());
                    console.log('item_id: ' + e.event.id);
                    console.log('start_dt: ' + sd.toISOString().replace('T', ' ').replace('Z', ''));
                    console.log('end_dt: ' + ed.toISOString().replace('T', ' ').replace('Z', ''));

                    var parmsP2DiaryUpdateItem = {
                        method: 849,
                        parameters: {
                            webpagename: stateService.getCurrentName(),
                            item_id: e.event.id,
                            start_dt: sd.toISOString().replace('T', ' ').replace('Z', ''),
                            end_dt: ed.toISOString().replace('T', ' ').replace('Z', '')
                        }
                    };

                    // Prosedyre sp_ws_p2_diary_updateitem 
                    $ihttp.post(parmsP2DiaryUpdateItem).then(function (data) {
                        console.log('respons: ' + angular.toJson(data));
                        console.dir(data);
                        if (data.iserror === false) {
                            //stateService.go($scope.setup.item_state_new, $scope.setup.item_parms_new);
                        }
                    });

                    //angular.forEach($scope.listItems, function (data) {
                    //    if (angular.isDefined(data) && angular.isDefined(e) && angular.isDefined(e.event) && e.event.id == data.item_id) {
                    //        //console.log('This is all the dates:');
                    //        //console.log(e.event.end.toISOString().replace('T', ' ').replace('Z',''));
                    //        //console.log(data.end_dt);
                    //        //console.log(data.end_dt.toISOString());
                    //        //console.log(data.end_dt.toISOString().substr(0, 10) + ' ' + data.end_dt.toISOString().substr(11, 2) + ' ' + data.end_dt.toISOString().substr(14, 2));
                    //        //console.log(e.event.end.toISOString());
                    //        //console.log(e.event.end.toISOString().substr(0, 10) + ' ' + e.event.end.toISOString().substr(11, 2) + ' ' + e.event.end.toISOString().substr(14, 2));

                    //        var sd = new Date(e.event.start);
                    //        var ed = new Date(e.event.end);

                    //        sd.setHours(sd.getHours() + 2);
                    //        ed.setHours(ed.getHours() + 2);

                    //        data.start_dt = sd.toISOString().replace('T', ' ').replace('Z', '');
                    //        data.end_dt = ed.toISOString().replace('T', ' ').replace('Z', '');
                    //    }
                    //});
                };

                //document.addEventListener('touchstart', function (e) { console.log('touchstart from document') }, false);
                //document.addEventListener('touchmove', function (e) { console.log('touchmove from document') }, false);
                //document.addEventListener('touchend', function (e) { console.log('touchend from document') }, false);
                //document.addEventListener('mouseup', function (e) { console.log('mouseup from document') }, false);

                $scope.onDataBound = function (e) {
                    console.log("Gantt data bound");
                    //console.dir($scope.setup);
                    //console.dir($scope.listItems);
                    //console.log(stateService.getCurrentName());
                    //console.dir(e.sender.wrapper);

                    var scheduler = e.sender;

                    $scope.model.scheduler = scheduler;
                    //e.sender.wrapper.off("mouseup touchend").on("mouseup touchend", ".k-scheduler-table td, .k-event", function (e) {
                    e.sender.wrapper.off("mouseup").on("mouseup", ".k-scheduler-table td, .k-event", function (e) {

                        var target = jQuery(e.target);

                        if (target.hasClass('k-si-close') || target.hasClass('k-resize-handle')) {
                            return;
                        }

                        target = jQuery(e.currentTarget);
                        if (target.hasClass('k-event')) {
                            var event = scheduler.occurrenceByUid(target.data('uid'));
                            //scheduler.editEvent(event);

                            console.log('Clicked on event');
                            console.dir(event);

                            if (event.item_path === '' || $scope.model.moving === true) return;

                            stateService.go(event.item_state, event.item_parms);
                        } else {
                            var slot = scheduler.slotByElement(target[0]);
                            console.log("Clicked on empty slot");
                            console.dir(slot);
                            //scheduler.addEvent({
                            //    start: slot.startDate,
                            //    end: slot.endDate
                            //});

                            var sd = new Date(slot.startDate);
                            var ed = new Date(slot.endDate);

                            sd.setHours(sd.getHours() + 2);
                            ed.setHours(ed.getHours() + 2);

                            var parmsP2PortalUserVariableSetWs = {
                                method: 616,
                                parameters: {
                                    variablename: 'scheduler.datetimefrom',
                                    variablevalue: sd.toISOString().replace('T', ' ').replace('Z', '')
                                }
                            };

                            // Prosedyre sp_ws_p2_portal_user_variable_set_ws
                            $ihttp.post(parmsP2PortalUserVariableSetWs).then(function (data) {
                                if (data.iserror === false) {
                                    stateService.go($scope.setup.item_state_new, $scope.setup.item_parms_new);
                                }
                            });
                        }
                    });
                };

                //var timer = null;

                $scope.onDataBinding = function (e) {
                    console.log("Scheduler data binding");
                    //console.dir(e);
                    //console.dir($scope.setup);

                    //if (angular.isDefined(timer)) {
                    //    $timeout.cancel(timer);
                    //}

                    //timer = $timeout(function () {
                    //    $scope.model.rebindDone = true;
                    //}, 250);
                };

                $scope.onNavigate = function (e) {
                    console.log(kendo.format("navigate:: view:{0};", e.view));
                    console.dir(e);

                    if (e.action !== "changeView" && angular.isDefined(e.date) && e.date !== new Date($scope.setup.date_fom)) {
                        var curr = e.date, y = curr.getFullYear(), m = curr.getMonth(), f = curr.getDate();
                        if (e.view === 'agenda' && $scope.model.previousView !== 'day') {
                            e.preventDefault();
                            if (e.action === "previous") {
                                if ($scope.model.previousView === 'month') {
                                    m = curr.getMonth() - 1;
                                } else {
                                    f = curr.getDate() - 6;
                                }
                            } else if (e.action === "next") {
                                if ($scope.model.previousView === 'month') {
                                    m = curr.getMonth() + 1;
                                } else {
                                    f = curr.getDate() + 6;
                                }
                            }
                        }
                        var d = new Date(y, m, f, 2);

                        $scope.setup.date_fom = d.toISOString().substring(0, 10);
                        $scope.schedulerOptions.date = $scope.setup.date_fom;
                    }

                    if (e.view !== $scope.setup.selectedview) {
                        $scope.setup.selectedview = e.view;
                        if (e.view !== 'agenda') {
                            $scope.model.previousView = e.view;
                        }

                        angular.forEach($scope.schedulerOptions.views, function (data) {
                            if (angular.isDefined(data)) {
                                if (data.type === $scope.setup.selectedview) {
                                    data.selected = true;
                                } else {
                                    data.selected = false;
                                }
                            }
                        });
                    }
                };

                $scope.onMoveStart = function (e) {
                    console.log("moveStart");
                    $scope.model.moving = true;
                };

                $scope.onMove = function (e) {
                    console.log("move");
                    $scope.model.moving = true;
                };

                $scope.onMoveEnd = function (e) {
                    console.log("moveEnd");
                    console.dir(e);
                    $scope.model.moving = false;

                    //angular.forEach($scope.listItems, function (data) {
                    //    if (angular.isDefined(e.event) && angular.isDefined(e.event.id) && e.event.id == data.id) {
                    //        data.start_dt = e.event.start.toISOString().replace('T', ' ').replace('Z', '');
                    //        data.end_dt = e.event.end.toISOString().replace('T', ' ').replace('Z', '');
                    //    }
                    //});
                };

                $scope.onResizeStart = function (e) {
                    console.log("resizeStart");
                };

                $scope.onResize = function (e) {
                    console.log("resize");
                };

                $scope.onResizeEnd = function (e) {
                    console.log("resizeEnd");
                };

                //function isInArray(value, array) {
                //    return array.indexOf(value) > -1;
                //}

                //function isNumber(n) {
                //    return !isNaN(parseFloat(n)) && isFinite(n);
                //}

                var checkBreakpoint = function (event, data) {
                    //console.log('checking!!!!!!!!!!!!!!!!!!!!!!');
                    if (responsive.current === 'xx') {
                        $scope.model.eventTemplate = '<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(description)#</small></p></div>';
                    } else {
                        $scope.model.eventTemplate = '<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(start, "HH:mm")#</small>-<small>#=kendo.toString(end, "HH:mm")#</small><br><small>#=kendo.toString(description)#</small></p></div>';
                    }

                    //if (angular.isDefined($scope.model.scheduler) && angular.isFunction($scope.model.scheduler.refresh)) {
                    //    console.log('it clearly IS A FUNCTION!');
                    //    $scope.model.scheduler.dataSource.read();
                    //    $scope.model.scheduler.refresh();
                    //}
                };

                checkBreakpoint();

                $rootScope.$on('event:responsive-changed', checkBreakpoint);

                //var reading = false;

                $scope.$watch(function () {
                    return $scope.listItems.length;
                }, function (newValue) {
                    //console.log('in hasbeen');
                    //console.dir($scope.listItems);
                    if (angular.isUndefined($scope.listItems) || $scope.listItems == null) return;
                    //if (angular.isUndefined($scope.listItems) || $scope.listItems == null) {
                    //    $scope.model.hasData.hasTasks = false;
                    //} else {
                    //    $scope.model.hasData.hasTasks = true;
                    //}

                    if ($scope.listItems.length > 0) {
                        $scope.model.hasData.hasTasks = true;
                    } else {
                        $scope.model.hasData.hasTasks = false;
                    }

                    if (angular.isFunction($scope.model.scheduler.refresh)) {
                        //console.log('it clearly IS A FUNCTION!');
                        $scope.model.scheduler.dataSource.read();
                        $scope.model.scheduler.refresh();
                    }

                    //$scope.model.hasData.changeCounter++;

                    //console.log('in hasbeen 2: ' + $scope.model.hasData.hasTasks);

                    //if ($scope.model.initiated == true && $scope.model.reading == false) {
                    //    $scope.schedulerOptions.dataSource.read();
                    //    $scope.model.reading = true;
                    //    console.log('in hasbeen 2: electric bogaloo');
                    //    console.dir($scope.schedulerOptions.dataSource);
                    //    //$scope.schedulerOptions.dataSource.read().then(function () {
                    //        $scope.model.reading = false;
                    //    //    console.log('in hasbeen 3: Just THEN');
                    //    //    console.dir($scope.schedulerOptions.dataSource);
                    //    //});
                    //}

                    //if ($scope.model.initiated == true && reading == false) {
                    //    reading = true;
                    //    $scope.schedulerOptions.dataSource.read();
                    //    //$scope.model.initiated = false;
                    //    reading = false;
                    //}
                });

                $scope.$watch(function () {
                    return $scope.owners.length;
                }, function (newValue) {
                    if (angular.isUndefined($scope.owners) || $scope.owners === null) return;
                    //if (angular.isUndefined($scope.owners) || $scope.owners == null) {
                    //    $scope.model.hasData.hasOwners = false;
                    //} else {
                    //    $scope.model.hasData.hasOwners = true;
                    //}

                    if ($scope.owners.length > 0) {
                        $scope.model.hasData.hasOwners = true;
                    } else {
                        $scope.model.hasData.hasOwners = false;
                    }

                    //$scope.model.hasData.changeCounter++;

                    //if ($scope.model.initiated == true && reading == false) {
                    //    reading = true;
                    //    $scope.schedulerOptions.dataSource.read();
                    //    //$scope.model.initiated = false;
                    //    reading = false;
                    //}

                    //if ($scope.model.initiated == true && reading == false) {
                    //    reading = true;
                    //    $scope.schedulerOptions.dataSource.read().then(function () {
                    //        reading = false;
                    //    });
                    //}

                    //if ($scope.model.initiated == true) {
                    //    $scope.schedulerOptions.dataSource.read();
                    //    //$scope.model.initiated = false;
                    //}
                });

                $scope.$watch(function () {
                    return $scope.setup;
                }, function (newValue, oldValue) {
                    if (angular.isDefined($scope.setup) && angular.isDefined($scope.setup.date_fom) && $scope.setup.date_fom !== null) {
                        $scope.model.hasData.hasSetup = true;
                    } else {
                        $scope.model.hasData.hasSetup = false;
                    }

                    //$scope.model.hasData.changeCounter++;

                    //if (angular.isDefined(oldValue) && oldValue !== null && angular.isDefined(newValue) && newValue !== null && newValue.portal_user_keyno != oldValue.portal_user_keyno) {
                    //    if ($scope.model.initiated == true) {
                    //        $scope.schedulerOptions.dataSource.update();
                    //        console.log('re-read');
                    //    }
                    //}
                }, true);

                $scope.$watch(function () {
                    return $scope.model.hasData;
                }, function (newValue) {
                    if ($scope.model.hasData.hasTasks === false || $scope.model.hasData.hasOwners === false || $scope.model.hasData.hasSetup === false) return;

                    $scope.schemaFields = {};

                    $scope.schemaFields['id'] = { from: 'item_id', type: 'number' };
                    $scope.schemaFields['title'] = { from: 'item_name', type: 'string', defaultValue: 'No title', validation: { required: true } };
                    $scope.schemaFields['start'] = { from: 'start_dt', type: 'date' };
                    $scope.schemaFields['end'] = { from: 'end_dt', type: 'date' };
                    $scope.schemaFields['ownerId'] = { from: 'portal_user_keyno', type: 'number', defaultValue: '1', validation: { required: true } };

                    $scope.model.schedulerDataSource = new kendo.data.SchedulerDataSource({
                        batch: false,
                        transport: {
                            read: function (options) {
                                //console.log('OPTIONS');
                                //console.dir(options);
                                //options.success(angular.copy($scope.listItems));
                                options.success($scope.listItems);
                                //options.success(options.data);
                            },
                            create: function (options) {
                                options.success(options.data);
                            },
                            update: function (options) {
                                angular.forEach($scope.listItems, function (data) {
                                    if (angular.isDefined(data) && angular.isDefined(options) && angular.isDefined(options.data) && angular.isDefined(options.data.start) && angular.isDefined(options.data.end) && data.item_id == options.data.id) {
                                        data.start_dt = options.data.start.toISOString().replace('T', ' ').replace('Z', '');
                                        data.end_dt = options.data.end.toISOString().replace('T', ' ').replace('Z', '');
                                    }
                                });
                                options.success(options.data);
                            },
                            destroy: function (options) {
                                options.success(options.data);
                            }
                        },
                        schema: {
                            model: {
                                id: 'id',
                                fields: $scope.schemaFields
                            }
                        }
                    });

                    var tempOwners = [];

                    angular.forEach($scope.owners, function (data) {
                        if (angular.isDefined(data)) {
                            if (angular.isDefined(data.portal_user_name) && data.portal_user_name !== null) {
                                data.text = data.portal_user_name;
                            } else {
                                data.text = '';
                            }

                            if (angular.isDefined(data.portal_user_keyno) && data.portal_user_keyno !== null) {
                                data.value = data.portal_user_keyno;
                            } else {
                                data.text = 0;
                            }
                        }

                        tempOwners.push(data);
                    });

                    $scope.model.schedulerDataSourceOwners = new kendo.data.SchedulerDataSource({
                        batch: false,
                        transport: {
                            read: function (options) {
                                options.success(tempOwners);
                            },
                            create: function (options) {
                                options.success(options.data);
                            },
                            update: function (options) {
                                options.success(options.data);
                            },
                            destroy: function (options) {
                                options.success(options.data);
                            },
                        }
                    });

                    //THIS SETS THE DEFAULT VIEW TO 'Week'
                    if (angular.isUndefined($scope.setup.selectedview) || ($scope.setup.selectedview !== 'day' && $scope.setup.selectedview !== 'workWeek' && $scope.setup.selectedview !== 'week' && $scope.setup.selectedview !== 'month')) {
                        $scope.setup.selectedview = 'week';
                    }

                    $scope.schedulerOptions = {
                        allDaySlot: false,
                        date: $scope.setup.date_fom,
                        dateHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'ddd dd/MM\')#</strong>'),
                        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#</div>'),
                        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(start, "HH:mm")#</small>-<small>#=kendo.toString(end, "HH:mm")#</small> <small>#=kendo.toString(description)#</small></p></div>'),
                        eventTemplate: kendo.template($scope.model.eventTemplate),
                        //eventTemplate: kendo.template('<div class="k-event-template">#=kendo.toString(title)#<br><p><small>#=kendo.toString(description)#</small></p></div>'),
                        majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, \'HH:mm\')#</strong>'),
                        //height: 598,
                        height: 800,
                        showWorkHours: true,
                        views: [
                            { type: 'day', selected: $scope.setup.selectedview === 'day' ? true : false },
                            { type: 'workWeek', selected: $scope.setup.selectedview === 'workWeek' ? true : false },
                            { type: 'week', selected: $scope.setup.selectedview === 'week' ? true : false },
                            { type: 'month', selected: $scope.setup.selectedview === 'month' ? true : false },
                            { type: 'agenda', eventTimeTemplate: kendo.template('<span>#=kendo.toString(start, "HH:mm")#</span> - <span>#=kendo.toString(end, "HH:mm")#</span>') }
                        ],
                        dataSource: $scope.model.schedulerDataSource,
                        dataBinding: $scope.onDataBinding,
                        dataBound: $scope.onDataBound,
                        add: $scope.onAdd,
                        edit: $scope.onEdit,
                        cancel: $scope.onCancel,
                        change: $scope.onChange,
                        remove: $scope.onRemove,
                        save: $scope.onSave,
                        navigate: $scope.onNavigate,
                        moveStart: $scope.onMoveStart,
                        move: $scope.onMove,
                        moveEnd: $scope.onMoveEnd,
                        resizeStart: $scope.onResizeStart,
                        resize: $scope.onResize,
                        resizeEnd: $scope.onResizeEnd,
                        resources: [
                            {
                                field: 'ownerId',
                                title: 'Owner',
                                dataSource: $scope.model.schedulerDataSourceOwners
                            }
                        ]
                    };
                    $scope.model.initiated = true;

                    //console.log('GET READY TO READ!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
                    //$scope.schedulerOptions.dataSource.read();
                }, true);
            }
        };

        return directive;
    }]);
})();