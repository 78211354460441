(function() {
    'use strict';

    angular.module("imApp").factory("epBlockOrderEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loadData: function (pohKeyno) {
                return $ihttp.post({
                    method: 1996,
                    parameters: {
                        poh_keyno: pohKeyno
                    }
                });
            },

            saveData: function (data) {
                console.dir(data);

                return $ihttp.post({
                    method: 2004,
                    parameters: data
                });
            },

            getPoStatusList: function () {
                return $ihttp.post({
                    method: 2002,
                    parameters: {}
                });
            },

            getShipmentContainerList: function () {
                return $ihttp.post({
                    method: 2003,
                    parameters: {}
                });
            }
        }

        return service;
    }]);
})();
