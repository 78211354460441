(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srOperationResourceService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrOperationResourceGet: function (special_hrs_custactresource_keyno) {
                return p2DataTaskService.call(1476, {
                    special_hrs_custactresource_keyno: special_hrs_custactresource_keyno
                });
            },
            deleteObj: function (resource) {
                return p2DataTaskService.call(1477, resource);
            }
        };

        return service;
    }]);
})();