import { IDateAngularComp } from '@ag-grid-community/angular';
import { IDateParams } from '@ag-grid-community/core';
import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@app/core/services/translate.service';
import { UserStore } from '@app/core/services/user.store';

/**
 * Custom date selection component used in filters for dates.
 */
@Component({
    selector: 'tt-date-filter-selection',
    templateUrl: './date-filter-selection.component.html',
    styleUrls: ['./date-filter-selection.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class DateFilterSelectionComponent implements IDateAngularComp {
    /**
     * The placeholder for the date filter input.
     */
    public placeholder: string = '';

    /**
     * The date to filter with.
     */
    public value: Date | null = null;

    /**
     * Text to display when filter is disabled, (controlled by parent with types not supported to be edited in floating filter component).
     */
    public text: string = '';

    /**
     * The filter parameters provided.
     */
    public params?: IDateParams;

    /**
     * Whether this input field has focus or not, used to know if value should be set or not.
     */
    activeFocus = false;

    constructor(private user: UserStore, private translate: TranslateService) {
        this.user.dateFormatChanged.subscribe(async (format) => (this.placeholder = await this.translate.translate(format)));
    }

    /**
     * Updates the filter value and applies the filters.
     */
    public onDateChanged(event: Date | null) {
        this.value = event;
        if (this.value) {
            this.value.setHours(0, 0, 0, 0);
        }

        if (!!this.params && !Object.hasOwn(this.params.filterParams, 'filters')) {
            this.params?.onDateChanged();
        } else {
            // @ts-ignore
            this.params?.onDateChanged({ dateFrom: this.value });
        }
    }

    /**
     * Updates activefocus to true.
     */
    public onFocus() {
        this.activeFocus = true;
    }

    /**
     * Updates activefocus to false.
     */
    public onBlur() {
        this.activeFocus = false;
    }

    /**
     * Formats the date on enter key.
     *
     * @param event the key event.
     */
    public onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.value = !!this.value ? new Date(this.value) : null;
        }
    }

    agInit(params: IDateParams<any, any>): void {
        this.params = params;
    }

    getDate(): Date | null {
        return this.value;
    }

    setDate(date: Date | null): void {
        if (((!date && !!this.value) || (!this.value && !!date) || date?.toISOString().substring(0, 10) !== this.value?.toISOString().substring(0, 10)) && !this.activeFocus) {
            this.value = date;
        }
    }
}
