(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDelControlService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadBatchDelControlGet: function (batchdelivery_keyno, purch_internal_no) {
                return p2DataTaskService.call(1645, {
                    batchdelivery_keyno: batchdelivery_keyno,
                    purch_internal_no: purch_internal_no
                });
            },
            loadBatchDelReceiveLinesControlList: function (settingControl) {
                return p2DataTaskService.call(1603, settingControl);
            },
            loadBatchDelReceiveLinesControlSkuList: function (settingControl) {
                return p2DataTaskService.call(1647, settingControl);
            },
            approve: function (settingControl) {
                return p2DataTaskService.call(1646, settingControl);
            }
        };

        return service;
    }]);
})();