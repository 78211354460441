(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('price', {
        templateUrl: 'views/components/views/price/price.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'priceService', 'economyService', function ($stateParams, stateService, utilityService, priceService, economyService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let priceGrpKeyno = $stateParams.pricegrp_keyno;
            let priceKeyno = $stateParams.price_keyno;

            vm.model = {
                priceKeyno: priceKeyno,
                edit: {},
                selectListPrices: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                priceService.getPrice({
                    pricegrp_keyno: priceGrpKeyno,
                    price_keyno: priceKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadPrices = function () {
                economyService.listPrices().then(function (result) {
                    angular.copy(result, vm.model.selectListPrices);
                });
            };
            
            let loadPriceList = function () {
                if (utilityService.validateParmsValue(vm.model.edit.pricelist_id) !== true) return;

                return economyService.getPriceList({ pricelist_id: vm.model.edit.pricelist_id }).then(function (response) {
                    vm.model.edit.pricelist_valuta_id = response[0].pricelist_valuta_id;
                    vm.model.edit.tax_name = response[0].tax_name;
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'pricelist_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.pricelist_id) !== true) return;

                        vm.model.edit.pricelist_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.pricelist_id) !== true) return;

                        loadPriceList();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                priceService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                priceService.deleteItem(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPrices();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
