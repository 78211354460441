(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("labelPrintService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadLabelPrintGet: function (argtype, argvalue) {
                return p2DataTaskService.call(1832, {
                    argtype: argtype,
                    argvalue: argvalue
                });
            },
            loadLabelPrintList: function (setting) {
                return p2DataTaskService.call(1846, setting);
            }
        };

        return service;
    }]);
})();
