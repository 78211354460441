(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttDateRange', {
        templateUrl: 'views/components/directives/ttDateRange/ttDateRange.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',           // { string } - the label to display for the date range input fields.
            ttSublabel: '<?',       // { Object: string } - sublabel for the date range input, this is not translated in component.
            ttLabelView: '@',       // { 'top' | 'side' | 'auto' | 'hidden' | 'none' } - the position of the label - 'top', 'side', 'auto', 'hidden', 'none' - null or undefined indicates auto.
            ttStartDate: '<',       // { string } - the start date for the date range.
            ttEndDate: '<',         // { string } - the end date for the date range.
            ttDateIndex: '<',       // { '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' } - the index of the date interval chosen, a stringyfied number between '1' and '6' for [day, week, month, quater, biannual, year, yearToDate, minMax] respectively.
            ttModel: '<?',          // { { date_fom: string; date_tom: string; dateselector_index: string; } } - alternaive to using ttStartDate, ttEndDate and ttDateIndex, a configuration object containing information about the date range: { date_fom: '', date_tom: '', dateselector_index: '1' }.
            ttChange: '&',          // { ($startDate: string, $endDate: string, $dateIndex: string) => void } - callback for the when the values of the date range has changed, takes the parameters ($startDate, $endDate, $dateIndex).
            ttTextAlign: '@?',      // { 'left' | 'right' | 'center' | 'justify' } - the alignment of the text in the input fields.
            ttItemId: '@',          // { string } - custom id on tt-item attribute for the container element of the component.
            ttRequired: '@?',       // { string | boolean } - whether input in the date range is required.
            ttTranslate: '@',       // { boolean } default is true. Translates the ttLabel.
            ttUseShort: '@',        // { boolean } default is true. Decides wich translation of the buttons to use.
            ttStyle: '<?',          // { Object } - style object to override default styling.
        },
        controller: ['layoutService', 'translateService', 'utilityService', 'ttDirectivesService', function (layoutService, translateService, us, ttDirectivesService) {
            let vm = this;
            let onDestroy = [];

            vm.required = false;

            vm.activeIndex = '1';
            vm.startDate = new Date();
            vm.endDate = new Date();

            vm.startDatePickerOpened = false;
            vm.endDatePickerOpened = false;

            vm.openStartDatePicker = function () {
                vm.startDatePickerOpened = true;
            }

            vm.openEndDatePicker = function () {
                vm.endDatePickerOpened = true;
            }

            vm.startDateOptions = {
                formatYear: 'yy',
                startingDay: 1,
                minDate: null,
                maxDate: null,
            };

            vm.endDateOptions = {
                formatYear: 'yy',
                startingDay: 1,
                minDate: null,
                maxDate: null,
            };

            vm.intervals = [
                { id: 'tt_daterange_day', short_label: 'D', label: 'D', title: 'Day', index: '1', onClick: () => vm.onDateChanged('1') }, //tt_daterange_day
                { id: 'tt_daterange_week', short_label: 'W', label: 'W', title: 'Week', index: '2', onClick: () => vm.onDateChanged('2') },
                { id: 'tt_daterange_month', short_label: 'M', label: 'M', title: 'Month', index: '3', onClick: () => vm.onDateChanged('3') },
                { id: 'tt_daterange_quarter', short_label: 'Q', label: 'Q', title: 'Quarter', index: '4', onClick: () => vm.onDateChanged('4') },
                { id: 'tt_daterange_biannual', short_label: 'B', label: 'B', title: 'Biannual', index: '5', onClick: () => vm.onDateChanged('5') },
                { id: 'tt_daterange_year', short_label: 'Y', label: 'Y', title: 'Year', index: '6', onClick: () => vm.onDateChanged('6') },
                { id: 'tt_daterange_year_to_date', short_label: 'YTD', label: 'YTD', title: 'YearToDate', index: '7', onClick: () => vm.onDateChanged('7') },
                { id: 'tt_daterange_min_max', short_label: 'X', label: 'X', title: 'MinMax', index: '8', onClick: () => vm.onDateChanged('8') },
            ];

            vm.id = {
                startDate: uuid(),
            };

            vm.style = {
                base: {},
                label: {},
                sublabel: {},
                group: {},
                date: {},
                button: {},
                buttonLabel: {}
            };

            vm.class = {
                base: '',
                label: '',
                sublabel: '',
                date: '',
                button: ''
            };

            vm.translations = {
                ttLabel: '',
                buttons: {
                    tt_daterange_day_short: '',
                    tt_daterange_week_short: '',
                    tt_daterange_month_short: '',
                    tt_daterange_quarter_short: '',
                    tt_daterange_biannual_short: '',
                    tt_daterange_year_short: '',
                    tt_daterange_year_to_date_short: '',
                    tt_daterange_min_max_short: '',
                    tt_daterange_day: '',
                    tt_daterange_week: '',
                    tt_daterange_month: '',
                    tt_daterange_quarter: '',
                    tt_daterange_biannual: '',
                    tt_daterange_year: '',
                    tt_daterange_year_to_date: '',
                    tt_daterange_min_max: '',
                    tt_daterange_previous_title: 'Previous',
                    tt_daterange_day_title: '',
                    tt_daterange_week_title: '',
                    tt_daterange_month_title: '',
                    tt_daterange_quarter_title: '',
                    tt_daterange_biannual_title: '',
                    tt_daterange_year_title: '',
                    tt_daterange_year_to_date_title: '',
                    tt_daterange_min_max_title: '',
                    tt_daterange_next_title: 'Next',
                }
            };

            /**
             * Decreases the date with the date interval chosen.
             */
            vm.decreaseDateInterval = function () {
                const startDate = getDateValues((vm.startDate ?? vm.endDate) ?? new Date());
                const endDate = getDateValues(vm.endDate ?? new Date());

                switch (vm.activeIndex) {
                    case '1':   // Decrease Day
                        vm.startDate = new Date(startDate.year, startDate.month, (startDate.fullDate - 1), startDate.hours);
                        vm.endDate = angular.copy(vm.startDate);
                        break;
                    case '2':   // Decrease Week
                        vm.startDate = new Date(startDate.year, startDate.month, (startDate.firstDayOfWeek - 7), startDate.hours);
                        vm.endDate = new Date(startDate.year, startDate.month, (startDate.lastDayOfWeek - 7), startDate.hours);
                        break;
                    case '3':   // Decrease Month
                        vm.startDate = new Date(startDate.year, (startDate.month - 1), 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, startDate.month, 0, startDate.hours);
                        break;
                    case '4':   // Decrease Quarter
                        vm.startDate = new Date(startDate.year, (startDate.quarter - 3), 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, startDate.quarter, 0, startDate.hours);
                        break;
                    case '5':   // Decrease Biannual
                        vm.startDate = new Date(startDate.year, (startDate.biannual - 6), 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, startDate.biannual, 0, startDate.hours);
                        break;
                    case '6':   // Decrease Year
                        vm.startDate = new Date((startDate.year - 1), 0, 1, startDate.hours);
                        vm.endDate = new Date((startDate.year - 1), 12, 0, startDate.hours);
                        break;
                    case '7':   // Decrease Year but keep the end date the same
                        vm.startDate = new Date((startDate.year - 1), 0, 1, startDate.hours);
                        vm.endDate = new Date((endDate.year - 1), endDate.month, endDate.fullDate, endDate.hours);
                        break;
                    default:
                        break;
                }

                vm.onDateChanged();
            }

            /**
             * Increases the current date with the date interval chosen.
             */
            vm.increaseDateInterval = function () {
                const startDate = getDateValues((vm.startDate ?? vm.endDate) ?? new Date());
                const endDate = getDateValues(vm.endDate ?? new Date());

                switch (vm.activeIndex) {
                    case '1':   // Increase Day
                        vm.startDate = new Date(startDate.year, startDate.month, (startDate.fullDate + 1), startDate.hours);
                        vm.endDate = angular.copy(vm.startDate);
                        break;
                    case '2':   // Increase Week
                        vm.startDate = new Date(startDate.year, startDate.month, (startDate.firstDayOfWeek + 7), startDate.hours);
                        vm.endDate = new Date(startDate.year, startDate.month, (startDate.lastDayOfWeek + 7), startDate.hours);
                        break;
                    case '3':   // Increase Month
                        vm.startDate = new Date(startDate.year, (startDate.month + 1), 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, (startDate.month + 2), 0, startDate.hours);
                        break;
                    case '4':   // Increase Quarter
                        vm.startDate = new Date(startDate.year, (startDate.quarter + 3), 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, (startDate.quarter + 6), 0, startDate.hours);
                        break;
                    case '5':   // Increase Biannual
                        vm.startDate = new Date(startDate.year, (startDate.biannual + 6), 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, (startDate.biannual + 12), 0, startDate.hours);
                        break;
                    case '6':   // Increase Year
                        vm.startDate = new Date((startDate.year + 1), 0, 1, startDate.hours);
                        vm.endDate = new Date((startDate.year + 1), 12, 0, startDate.hours);
                        break;
                    case '7':   // Increase Year but keep the end date the same
                        vm.startDate = new Date((startDate.year + 1), 0, 1, startDate.hours);
                        vm.endDate = new Date((endDate.year + 1), endDate.month, endDate.fullDate, endDate.hours);
                        break;
                    default:
                        break;
                }

                vm.onDateChanged();
            }

            /**
             * Sets the start date, end date and date index to the component properties.
             *  
             * @param {string} index the date index.
             */
            vm.onDateChanged = function (index) {
                if (index) onDateIntervalChanged(index);

                //console.log('vm.startDate: ', vm.startDate);
                //console.log('vm.endDate: ', vm.endDate);

                if ((vm.ttStartDate || vm.ttStartDate?.trim() === '') && (vm.ttEndDate || vm.ttEndDate?.trim() === '')) {
                    vm.ttStartDate = dateToString(vm.startDate);
                    vm.ttEndDate = dateToString(vm.endDate);
                    vm.ttDateIndex = vm.activeIndex;
                }

                if (vm.ttModel) {
                    vm.ttModel.date_fom = dateToString(vm.startDate);
                    vm.ttModel.date_tom = dateToString(vm.endDate);
                    vm.ttModel.dateselector_index = vm.activeIndex;
                }

                let startDate = (vm.ttStartDate ? vm.ttStartDate : vm.ttModel?.date_fom) ?? '';
                let endDate = (vm.ttEndDate ? vm.ttEndDate : vm.ttModel?.date_tom) ?? '';
                let dateIndex = (vm.ttDateIndex ? vm.ttDateIndex : vm.ttModel?.dateselector_index) ?? vm.activeIndex;

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $startDate: startDate, $endDate: endDate, $dateIndex: dateIndex });
                }
            }

            /**
             * Gets date values from the given date.
             * 
             * @param {Date} date the date to retrieve values from.
             * @returns an object containing different values from the given date.
             */
            function getDateValues(date) {
                const dateCopy = angular.copy(date);

                return {
                    date: dateCopy,
                    year: dateCopy.getFullYear(),
                    month: dateCopy.getMonth(),
                    fullDate: dateCopy.getDate(),
                    day: dateCopy.getDay(),
                    firstDayOfWeek: (dateCopy.getDate() - dateCopy.getDay() + 1),
                    lastDayOfWeek: (dateCopy.getDate() - dateCopy.getDay() + 7),
                    quarter: (Math.floor((dateCopy.getMonth()) / 3) * 3),
                    biannual: (Math.floor((dateCopy.getMonth()) / 6) * 6),
                    hours: 3,
                }
            }

            /**
             * Changes the active index to the given date interval index.
             * 
             * @param {string} index the date interval index to change to.
             */
            function onDateIntervalChanged(index) {
                if (!index) return;

                const startDate = getDateValues((vm.startDate ?? vm.endDate) ?? new Date());
                const endDate = getDateValues(vm.endDate ?? new Date());
                const today = getDateValues(new Date());

                if (vm.activeIndex === '8') {
                    startDate.year = today.year;
                    endDate.year = today.year;
                }

                vm.activeIndex = index;

                switch (vm.activeIndex) {
                    case '1':   // Set to start date
                        vm.startDate = new Date(startDate.year, startDate.month, startDate.fullDate, startDate.hours);
                        vm.endDate = angular.copy(vm.startDate);
                        break;
                    case '2':   // Set to week of start date
                        vm.startDate = new Date(startDate.year, startDate.month, startDate.firstDayOfWeek, startDate.hours);
                        vm.endDate = new Date(startDate.year, startDate.month, startDate.lastDayOfWeek, startDate.hours);
                        break;
                    case '3':   // Set to month of start date
                        vm.startDate = new Date(startDate.year, startDate.month, 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, (startDate.month + 1), 0, startDate.hours);
                        break;
                    case '4':   // Set to quarter from start date
                        vm.startDate = new Date(startDate.year, startDate.quarter, 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, (startDate.quarter + 3), 0, startDate.hours);
                        break;
                    case '5':   // Set biannually from start date
                        vm.startDate = new Date(startDate.year, startDate.biannual, 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, (startDate.biannual + 6), 0, startDate.hours);
                        break;
                    case '6':   // Set to year from start date.
                        vm.startDate = new Date(startDate.year, 0, 1, startDate.hours);
                        vm.endDate = new Date(startDate.year, 12, 0, startDate.hours);
                        break;
                    case '7':   // Set to first day of end year and todays date.
                        vm.startDate = new Date(endDate.year, 0, 1, endDate.hours);
                        vm.endDate = new Date(endDate.year, today.month, today.fullDate, endDate.hours);
                        break;
                    case '8':   // Set to "min" and "max" date.
                        vm.startDate = new Date('1901', 0, 1, startDate.hours);
                        vm.endDate = new Date('2999', 12, 0, startDate.hours);
                        break;
                    default:
                        break;
                }
            }

            let setClasses = function (labelAlwaysOnTop) {
                vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });
            };

            let setStyle = function (ttStyle = vm.ttStyle) {
                angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, mainElement: 'date', textAlign: vm.ttTextAlign }), vm.style);
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                vm.style.group.height = '100%';

                vm.style.button.fontSize = info.fontSizes.textSize;
                vm.style.button.height = info.height + 'px';
                vm.style.button.paddingTop = info.padding.top + 'px';
                vm.style.button.paddingBottom = '0px';
                vm.style.button.paddingLeft = info.padding.left + 'px';
                vm.style.button.paddingRight = info.padding.right + 'px';

                vm.style.buttonLabel.fontSize = info.fontSizes.textSize;


                setStyle(vm.ttStyle);

                setClasses(info.labelAlwaysOnTop);
            });

            /**
             * Converts the given date to an iso string, returns an empty string if the date is invalid.
             * 
             * @param {Date} date the date to convert to string.
             * @returns the date as an iso string or an empty string if the date was invalid.
             */
            function dateToString(date) {
                if (date && date instanceof Date && date.toString !== 'Invalid Date') {
                    return date.toISOString().substring(0, 10);
                } else {
                    return '';
                }
            }

            /**
             * Initializes the internal date range component variables.
             */
            function setDateRange(startDate, endDate, dateIndex) {
                if (typeof startDate === 'string' && startDate?.length > 6) {
                    vm.startDate = new Date(startDate);
                } else {
                    vm.startDate = new Date();
                }

                if (typeof endDate === 'string' && endDate?.length > 6) {
                    vm.endDate = new Date(endDate);
                } else {
                    vm.endDate = new Date();
                }

                if (Number(dateIndex) >= 1 || Number(dateIndex) <= vm.intervals.length) {
                    vm.activeIndex = dateIndex;
                } else {
                    vm.activeIndex = vm.intervals[0].index;
                }
            }

            // ## ANGULAR LIFECYCLE

            vm.$onInit = function () {
                setStyle(vm.ttStyle);
                translateService.translateBatch(vm.translations.buttons).then((translations) => {
                    vm.translations.buttons = translations;

                    angular.forEach(vm.intervals, function (interval) {
                        let label_key = interval.id; 
                        let title_key = interval.id + '_title';
                        if (vm.ttUseShort === 'false')
                            interval.label = vm.translations.buttons[label_key];
                        else
                            interval.label = vm.translations.buttons[label_key + '_short'];
                        interval.title = vm.translations.buttons[title_key];
                    });
                });
            }

            vm.$onChanges = function (changes) {
                //console.log(changes);

                if (changes?.ttModel?.currentValue?.date_fom && changes?.ttModel?.currentValue?.date_tom) {
                    setDateRange(changes.ttModel.currentValue.date_fom, changes.ttModel.currentValue.date_tom, changes.ttModel.currentValue?.dateselector_index);
                }

                if ((changes?.ttStartDate?.currentValue || changes.ttStartDate?.currentValue?.trim() === '') || (changes?.ttEndDate?.currentValue || changes.ttEndDate?.currentValue?.trim() === '')) {

                    let newStart = changes?.ttStartDate?.currentValue ?? vm.ttStartDate;
                    let newEnd = changes?.ttEndDate?.currentValue ?? vm.ttEndDate;

                    setDateRange(newStart, newEnd, ((changes.ttDateIndex?.currentValue ?? vm.activeIndex) ?? '1'));
                }

                if (changes?.ttStyle?.currentValue) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (changes?.ttTextAlign?.currentValue) setStyle();

                if (changes?.ttLabel?.currentValue && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then((translation) => vm.translations.ttLabel = translation);
                    }
                }

                if (changes?.ttRequired?.currentValue) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                //setClasses();
            };

            vm.$onDestroy = function () {
                ttDirectivesService.onDestroy(onDestroy);
            };

            // ## END ANGULAR LIFECYCLE
        }]
    });
})();
