(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("travelBillService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadTravelBillGet: function (argtype, argvalue, travelbill_no) {
                return p2DataTaskService.call(1255, {
                    argtype: argtype,
                    argvalue: argvalue,
                    travelbill_no: travelbill_no
                });
            },
            loadLabourSelectList: function () {
                return p2DataTaskService.call(1252, {});
            },
            loadProjectSelectList: function (cust_no) {
                return p2DataTaskService.call(1108, {
                    cust_no: cust_no
                });
            },
            loadProjectProcessSelectList: function (project_keyno, show_only_timeentry_allowed) {
                return p2DataTaskService.call(47, {
                    project_keyno: project_keyno,
                    show_only_timeentry_allowed: show_only_timeentry_allowed
                });
            },
            loadTravelBillSrResourcesFromList: function (custact_no) {
                return p2DataTaskService.call(1461, {
                    custact_no: custact_no
                });
            },
            loadDietDaysInfoUpdate: function (start_dt, end_dt) {
                return p2DataTaskService.call(1264, {
                    start_dt: start_dt,
                    end_dt: end_dt
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1256,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();