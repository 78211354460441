(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('travExpTypes', {
        templateUrl: 'views/components/views/travExpTypes/travExpTypes.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'travExpTypesService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, travExpTypesService, logisticService, rememberService) {
            
            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            
            let variableNames = {
                businessco_no: '',
                searchtext: ''
            };

            vm.model = {
                businessButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('businessco_no') }
                ],
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') }
                ],
                setting: {},
                selectListCompanies: [],
                itemsListTravExpTypes: []
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();
                
                travExpTypesService.getTravelExpenseTypes().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadTravelExpenseTypes()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadTravelExpenseTypes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;
                
                vm.model.itemsListTravExpTypes = [];
                
                return travExpTypesService.listTravelExpenseTypes({
                    show_inactive: '1',
                    businessco_no: vm.model.setting.businessco_no
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListTravExpTypes);
                });
            };
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = '99999';

                        if (vm.model.setting.businessco_no === '99999') {
                            rememberFunc('businessco_no');
                            loadTravelExpenseTypes();
                        }
                        break;
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'travexptype':
                        go({ trextype_id: '+' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext > '') rememberFunc('searchtext');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadTravelExpenseTypes();
                        break;
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') rememberFunc('searchtext');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
