(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('epBlockOrderPlan', {
        templateUrl: 'views/components/views/epBlockOrderPlan/epBlockOrderPlan.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['$stateParams', '$localStorage', '$timeout', 'stateService', 'translateService', 'utilityService', 'ieScreenBlockService', 'printService', 'epBlockOrderPlanService', 'modalReportPreviewService', function ($stateParams, $localStorage, $timeout, stateService, translateService, us, ieScreenBlockService, printService, epBlockOrderPlanService, modalReportPreviewService) {
            var vm = this;
            vm.version = module.version;

            var pohKeyno = 0;

            vm.isModal = false;

            vm.report = {};

            var translations = {
                ep_message_printing_packinglist: '',
                ep_message_print_packinglist_completed: ''
            };

            var deregTranslation = translateService.on(translations, 'changed', function (result) {
                angular.copy(result, translations);

                deregTranslation();
            });

            var initialized = false;

            epBlockOrderPlanService.init().then(function (data) {
                angular.copy(data.report, vm.report);

                initialized = true;
            });

            us.setDefaultValues($localStorage, {
                externalProductionBlockOrderPlan: {
                    printer: {}
                }
            });

            vm.$storage = $localStorage.externalProductionBlockOrderPlan;

            var getStatusClass = function (quantity, quantityPacked) {
                quantity = quantity || 0;
                quantityPacked = quantityPacked || 0;

                if (quantityPacked > 0 && quantityPacked !== quantity) {
                    return 'bg-yellow';
                }

                if (quantityPacked === quantity) {
                    return 'bg-green';
                }

                return 'bg-default';
            };

            var gridControls;

            var setGridOptions = function () {
                vm.gridOptions = {
                    dataTaskKeyno: { load: 2005, save: 2006 },
                    parameters: {
                        poh_keyno: pohKeyno
                    },
                    rememberId: 'epBlockOrderPlan',
                    gridControlCallback: function (controls) {
                        gridControls = controls;
                    },
                    onSetup: function (schema, fields, columns) {
                        angular.forEach(fields, function (field) {
                            field.editable = false;
                        });

                        delete fields.weight.editable;
                        delete fields.volume.editable;

                        var cols = [
                            columns[0],
                            columns[1],
                            columns[2],
                            columns[4],
                            columns[12],
                            columns[6],
                            columns[7],
                            columns[8],
                            columns[11],
                            columns[9],
                            columns[10]
                        ];

                        angular.copy(cols, columns);
                    },
                    onDataBound: function (e) {
                        var rows = e.sender.content.find('tr');
                        var quantityIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "quantity" + "]").index();
                        var quantityPackedIndex = e.sender.wrapper.find(".k-grid-header [data-field=" + "quantity_packed" + "]").index();

                        rows.each(function (_, row) {
                            var dataItem = e.sender.dataItem(row);

                            var statusClass = getStatusClass(dataItem.quantity, dataItem.quantity_packed);

                            $(row).children('td:eq(' + quantityIndex + ')').addClass(statusClass);
                            $(row).children('td:eq(' + quantityIndex + ')').children('span').addClass(statusClass);
                            $(row).children('td:eq(' + quantityPackedIndex + ')').addClass(statusClass);
                            $(row).children('td:eq(' + quantityPackedIndex + ')').children('span').addClass(statusClass);
                        })
                    },
                    editable: true
                };
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve) && angular.isDefined(vm.resolve.pohKeyno)) {
                    // when opened as modal
                    vm.isModal = true;

                    pohKeyno = vm.resolve.pohKeyno;

                    setGridOptions();
                }
            };

            if (angular.isDefined($stateParams.pohKeyno)) {
                // when opened with state
                pohKeyno = $stateParams.pohKeyno;

                setGridOptions();
            }

            vm.cancel = function () {
                if (vm.isModal === true) {
                    vm.modalInstance.dismiss()
                } else {
                    stateService.back();
                }
            };

            vm.save = function () {
                if (angular.isObject(gridControls) !== true)
                    return;
                if (angular.isFunction(gridControls.save) !== true)
                    return;

                gridControls.save();
            };

            vm.onPrinterChanged = function (printer, changed) {
                if (changed !== true)
                    return;

                vm.$storage.printer = printer;
            };

            vm.print = function () {
                if (initialized !== true) {
                    $timeout(vm.print, 100);
                    return;
                }

                modalReportPreviewService.show({
                    reportKeyno: vm.report.keyno,
                    parameters: {
                        poh_keyno: pohKeyno
                    }
                });
            };
        }]
    });
})();
