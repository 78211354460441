import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrintModalComponent } from '../print-modal/print-modal.component';
import { GridService } from '../../grid.service';
import { LayoutService } from '@app/core/services/layout.service';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.css'],
})
export class ConfirmModalComponent {
    /**
     * Map of words to translate.
     */
    public translations: { [key: string]: string } = {
        title: '',
        message: '',
        ok: '',
        cancel: '',
    };

    /**
     * Styling to apply to the modal.
     */
    public style: Style = {
        title: {},
        message: {
            padding: '40px 50px',
            paddingBottom: '20px',
            color: 'var(--tt-body-text-color)',
        },
        button: {},
    };

    constructor(public dialogRef: MatDialogRef<PrintModalComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmModalData, private layoutService: LayoutService, private translateService: TranslateService) {
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['title'].fontSize = info.fontSizes.textSizeXL;
                this.style['message'].fontSize = `calc(${info.fontSizes.textSize} + 2px)`;
                this.style['button'].height = info.height + 4 + 'px';
            }
        });
    }

    /**
     * Translates all words defined in `this.translations`.
     */
    private async translate() {
        this.translations['title'] = await this.translateService.translate(this.data.title);
        this.translations['message'] = await this.translateService.translate(this.data.message);
        this.translations['ok'] = await this.translateService.translate(this.data.ok ?? 'ok');
        this.translations['cancel'] = await this.translateService.translate(this.data.cancel ?? 'cancel');
    }

    ngOnInit(): void {
        this.translate();
    }
}

/**
 * Represents data to configure the confirm modal with.
 */
export interface ConfirmModalData {
    /**
     * The type for the modal  (color for heading and buttons), if not defined will not use a type.
     */
    type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'none';

    /**
     * The title to display for the modal, will be translated.
     */
    title: string;

    /**
     * The message to explain what the user is confirming, will be translated.
     */
    message: string;

    /**
     * Ok text for the confirm button, will be translated.
     */
    ok?: string;

    /**
     * Cancel text for the cancel button, will be translated.
     */
    cancel?: string;
}
