(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productsConfig', {
        templateUrl: 'views/components/views/productsConfig/productsConfig.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'base64', 'modalService', 'utilityService', 'productsConfigService', 'stateService', 'watcherFactory', 'translateService', function ($stateParams, $timeout, $q, base64, modalService, utilityService, productsConfigService, stateService, watcherFactory, translateService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
    		var watcher = watcherFactory.$create(vm);
            
            vm.model = {
                argType: $stateParams.argtype,
                headNo: $stateParams.headno,
                lineNo: $stateParams.lineno,
                errorCode: '0',
                enableSave: '0',
                getProductConfig: {},
                listSelected: [],
                listProductConfigQuestion: [],
                depRules: {},
                listOrderLine: [],
                locked: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                }
            };

            var translations = {
                Varsel: '',
            };
    
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
    
            productsConfigService.loadProductsConfigGet(vm.model.argType, vm.model.headNo, vm.model.lineNo).then(function (data) {
                vm.model.getProductConfig = angular.copy(data[0]);
                
                var encodedSelected = '';//angular.copy(angular.toJson(vm.model.getProductConfig.productsconfig_selected));
                encodedSelected = data[0].productsconfig_selected;
                
                var ukryptert = '';
                ukryptert = atob(encodedSelected);
                
                vm.model.listSelected = angular.fromJson(ukryptert);
                
                loadQuestion();
            });
    
            var loadQuestion = function () {
                if (angular.isUndefined(vm.model.getProductConfig) === true) return;
    
                productsConfigService.loadProductConfigQuestionList(vm.model.getProductConfig).then(function (data) {
                    angular.copy(data, vm.model.listProductConfigQuestion);
    
                    angular.forEach(vm.model.listProductConfigQuestion, function (question) {
                        if (angular.isDefined(question) && angular.isDefined(question.selectlistrecords) && question.selectlistrecords.length > 0) {
                            question.item_default = '0';
                            var d = 0;
                            angular.forEach(question.selectlistrecords, function (record) {
                                if (angular.isDefined(record) && angular.isDefined(record.item_id) && record.item_id.length > 0) {
                                    record.item_func = record.item_id.toUpperCase();
                                    if (question.chosen_prod_id === record.item_id) {
                                        question.item_selected = d.toString();
                                        question.item_default = d.toString();
                                    }
                                    d++;
                                }
                            });
                            //if (question.selecttype === 'D' && question.nbr_of_choices < 4 && question.nbr_of_choices > 1) {
                            //    question.selecttype = 'R';
                            //}
                        }
                    });
                });
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });

            });
    
            // #############################################
            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS
            // #############################################
    
            vm.typeaheadSearch = function (value, item) {
                return productsConfigService.search(item.chosen_searchstring, item.choice_prod_id, item.restriction_prod_id);
            };
    
            vm.selectBgConfigQuestionGroup = function (item, i) {
                //var foundRecord = false;
                
                for (var r = 0; r < vm.model.listProductConfigQuestion[i].selectlistrecords.length; r++) {
                    if (angular.isDefined(vm.model.listProductConfigQuestion[i].selectlistrecords[r])
                        && angular.isDefined(vm.model.listProductConfigQuestion[i].selectlistrecords[r].item_func)
                        && vm.model.listProductConfigQuestion[i].selectlistrecords[r].item_func.length > 0) {
                        if (vm.model.listProductConfigQuestion[i].selectlistrecords[r].item_func === item) {
                            vm.model.listProductConfigQuestion[i].item_selected = item;
                            vm.model.listProductConfigQuestion[i].chosen_prod_id = vm.model.listProductConfigQuestion[i].selectlistrecords[r].item_id;
                            //foundRecord = true;
                            break;
                        }
                    }
                }
    
                //for (var q = 0; q < vm.model.listProductConfigQuestion.length; q++) {
                //    if (angular.isDefined(vm.model.listProductConfigQuestion[q])
                //        && angular.isDefined(vm.model.listProductConfigQuestion[q].selectlistrecords)
                //        && vm.model.listProductConfigQuestion[q].selectlistrecords.length > 0) {
                //        for (var r = 0; r < vm.model.listProductConfigQuestion[q].selectlistrecords.length; r++) {
                //            if (angular.isDefined(vm.model.listProductConfigQuestion[q].selectlistrecords[r])
                //                && angular.isDefined(vm.model.listProductConfigQuestion[q].selectlistrecords[r].item_func)
                //                && vm.model.listProductConfigQuestion[q].selectlistrecords[r].item_func.length > 0) {
                //                if (vm.model.listProductConfigQuestion[q].selectlistrecords[r].item_func === item) {
                //                    vm.model.listProductConfigQuestion[q].item_selected = item;
                //                    vm.model.listProductConfigQuestion[q].chosen_prod_id = vm.model.listProductConfigQuestion[q].selectlistrecords[r].item_id;
                //                    foundRecord = true;
                //                    break;
                //                }
                //            }
                //        }
                //        if (foundRecord) break;
                //    }
                //}
            };
    
            // ###############
            // WATCH FUNCTION
            // ###############
    
            var runningConfig = false;
    
            watcher.$watch(function () {
                return vm.model.listProductConfigQuestion;
            }, function (newValue, oldValue) {
                    if (angular.isUndefined(newValue) || angular.isUndefined(oldValue)) return;
                    for (var n = 0; n < newValue.length; n++) {
                        if (angular.isDefined(newValue[n]) && angular.isDefined(oldValue[n])) {
                            if (newValue[n].chosen_prod_id === oldValue[n].chosen_prod_id) {
                                //console.log('nothing new at ' + n);
                            } else {
                                if (newValue[n].question_type === 'N') {
                                    vm.model.enableSave = '0';
                                }
                            }
                        }
                    }
    
                if (angular.isUndefined(newValue) || newValue === oldValue || runningConfig === true) return;
                
                runningConfig = true;
                var i = 0;
    
                angular.forEach(vm.model.listProductConfigQuestion, function (q1) {
                    var conditionExists = true;
                    for (var r = 0; r < q1.dep_rules.length; r++) {
                        if (angular.isDefined(q1.dep_rules[r]) && angular.isDefined(q1.dep_rules[r].condition) && q1.dep_rules[r].condition.length > 0) {
                            for (var c = 0; c < q1.dep_rules[r].condition.length; c++) {
                                if (angular.isDefined(q1.dep_rules[r].condition[c]) && angular.isDefined(q1.dep_rules[r].condition[c].val)) {
                                    for (var o = 0; o < parseInt(q1.orderby); o++) {
                                        conditionExists = false;
                                        if (vm.model.listProductConfigQuestion[o].chosen_prod_id === q1.dep_rules[r].condition[c].val) {
                                            //vm.model.enableSave = '0';
                                            conditionExists = true;
                                            break;
                                        }
                                    }
                                    if (conditionExists) continue;
                                }
                            }
                            if (conditionExists) break;
                        }
                    }
    
                    if (conditionExists) {
                        q1.isvisible = '1';
                    } else {
                        q1.isvisible = '0';
                    }
    
                    if (angular.isDefined(oldValue[i]) && angular.isDefined(oldValue[i].chosen_searchstring)
                        && angular.isDefined(newValue[i]) && angular.isDefined(newValue[i].chosen_searchstring)
                        && newValue[i].chosen_searchstring !== oldValue[i].chosen_searchstring && newValue[i].chosen_searchstring.length > 0) {
                        angular.forEach(productsConfigService.selectProductsConfigSearchResultList, function (data) {
                            if (angular.isDefined(data)) {
                                if (data.item_name === q1.chosen_searchstring) {
                                    q1.chosen_prod_id = data.item_id;
                                    //vm.model.enableSave = '0';
                                }
                            }
                        });
                    }
                    if (angular.isDefined(oldValue[i]) && angular.isDefined(newValue[i]) && angular.isDefined(newValue[i].chosen_prod_id)
                        && newValue[i].chosen_prod_id !== oldValue[i].chosen_prod_id && newValue[i].question_type === 'A') {
                        angular.forEach(vm.model.listProductConfigQuestion, function (q2) {
                            if (angular.isDefined(q2) && q2.choice_prod_id === newValue[i].choice_prod_id && q2.question_type !== 'A') {
                                q2.chosen_prod_id = newValue[i].chosen_prod_id;
                                q2.chosen_searchstring = newValue[i].chosen_searchstring;
                                //vm.model.enableSave = '0';
                            }
                        });
                    }
                    if (angular.isDefined(oldValue[i]) && angular.isDefined(newValue[i]) && angular.isDefined(newValue[i].chosen_prod_id)
                        && newValue[i].chosen_prod_id !== oldValue[i].chosen_prod_id && newValue[i].question_type === 'S') {
                        if (newValue[i].chosen_prod_id.includes('__YN_y')) {
                            angular.forEach(vm.model.listProductConfigQuestion, function (q2) {
                                if (angular.isDefined(q2) && q2.choice_prod_id === newValue[i].from_choice_prod_id && q2.question_type === 'A') {
                                    angular.forEach(vm.model.listProductConfigQuestion, function (q3) {
                                        if (angular.isDefined(q3) && q3.choice_prod_id === q2.choice_prod_id && q3.question_type !== 'A') {
                                            q3.chosen_prod_id = q2.chosen_prod_id;
                                            q3.chosen_searchstring = q2.chosen_searchstring;
                                            //vm.model.enableSave = '0';
                                        }
                                    });
                                }
                            });
                        } else if (newValue[i].chosen_prod_id.includes('__YN_n')) {
                            angular.forEach(vm.model.listProductConfigQuestion, function (q2) {
                                if (angular.isDefined(q2) && q2.choice_prod_id === newValue[i].from_choice_prod_id && q2.question_type === 'A') {
                                    angular.forEach(vm.model.listProductConfigQuestion, function (q3) {
                                        if (angular.isDefined(q3) && q3.choice_prod_id === q2.choice_prod_id && q3.question_type !== 'A') {
                                            var foundChoice = false;
                                            for (var c = 0; c < q3.selectlistrecords.length; c++) {
                                                if (q3.selectlistrecords[c].item_id === q2.chosen_prod_id) {
                                                    q3.item_default = c;
                                                    //vm.model.enableSave = '0';
                                                    //q3.item_selected = q3.selectlistrecords[c].item_func;
                                                    foundChoice = true;
                                                    break;
                                                }
                                            }
    
                                            if (foundChoice) {
                                                q3.chosen_prod_id = q2.chosen_prod_id;
                                                //vm.model.enableSave = '0';
                                            } else if (q3.selectlistrecords.length > 0) {
                                                q3.item_default = 0;
                                                q3.chosen_prod_id = q3.selectlistrecords[0].item_id;
                                                //vm.model.enableSave = '0';
                                                //q3.item_selected = q3.selectlistrecords[0].item_func;
                                            } else {
                                                q3.item_default = '';
                                                q3.chosen_prod_id = '';
                                                q3.item_selected = '';
                                            }
                                            q3.chosen_searchstring = q2.chosen_searchstring;
                                        }
                                    });
                                }
                            });
                        }
                    }
                    i++;
                });
    
                $timeout(function () {
                    runningConfig = false;
                }, 50);
            }, true);
    
            // ###################
            // ## BUTTON FUNCTIONS
            // ###################
    
            vm.config = function () {
                vm.model.enableSave = '1';
    
                var parmsProductConfig = {
                    argtype: vm.model.getProductConfig.argtype,
                    headno: vm.model.getProductConfig.headno,
                    lineno: vm.model.getProductConfig.lineno
                };
                
                var mergeObj = parmsProductConfig;
                mergeObj.products = vm.model.listSelected;
                mergeObj.questions = vm.model.listProductConfigQuestion;
                
                productsConfigService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode === '-1') {
                        modalService.show({
                            type: 'danger',
                            title: translations.Varsel,
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.errorCode = data[0].errorcode;
                                }
                            }]
                        });
                    } else if (data[0].errorcode === '-2') {
                        modalService.show({
                            type: 'danger',
                            title: translations.Varsel,
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
    
                                    var returnrecordsencoded = '';
                                    returnrecordsencoded = data[0].returnrecords;
                                    
                                    var returnrecordsuncoded = '';
                                    returnrecordsuncoded = base64.urldecode(returnrecordsencoded);
                                    
                                    vm.model.listOrderLine = angular.fromJson(returnrecordsuncoded);
    
                                    vm.model.errorCode = data[0].errorcode;
                                }
                            }]
                        });
                    } else {
                        //stateService.back('/' + vm.model.getProductConfig.finish_item_state + '/' + vm.model.getProductConfig.finish_item_parms);
                        var returnrecordsencoded = '';
                        returnrecordsencoded = data[0].returnrecords;
                        
                        var returnrecordsuncoded = '';
                        returnrecordsuncoded = base64.urldecode(returnrecordsencoded);
                        
                        vm.model.listOrderLine = angular.fromJson(returnrecordsuncoded);
    
                        vm.model.errorCode = data[0].errorcode;
                    }
                });
            };
    
            function addConfig(item) {
                var deferred = $q.defer();
                //addConfig: function (prod_id, prodsize_keyno, prodchoice_keyno, quantity, input_price_ordval, input_amount_ordval, argtype, headno, lineno) {
                productsConfigService.addConfig(item.prod_id, item.prodsize_keyno, item.prodchoice_keyno, item.quantity, item.input_price_ordval, item.input_disc, item.input_amount_ordval, vm.model.getProductConfig.argtype, vm.model.getProductConfig.headno, vm.model.getProductConfig.lineno, vm.model.getProductConfig.kit_keyno).then(function (data) {
                    //if (vm.model.progressInfo.step >= vm.model.progressInfo.max || vm.model.progress === 'ABORT') {
                    //    vm.loadP2WInvoiceMakeMultiList();
                    //}
                    //ADD GLYPH CHECK COLOR GREEN TO SHOW DONE, GLYPH "X" COLOR RED IF ERROR
                    deferred.resolve(data);
                });
                return deferred.promise;
            }
    
            vm.configsProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;
    
                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';
                        if (vm.model.progress === 'ABORT') return;
                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            stateService.back('/' + vm.model.getProductConfig.finish_item_state + '/' + vm.model.getProductConfig.finish_item_parms);
                            return;
                        }
                        if (items[step].item_id === '0' || items[step].item_id === '9999') {
                            skip++;
                            step = vm.model.progressInfo.step + skip + failed;
                            doStep();
                        } else {
                            step = vm.model.progressInfo.step + skip + failed;
                            vm.model.progressInfo.field = items[step].item_id + ': ' + items[step].item_name + ' ' + items[step].item_name_sub1;
                            modalService.progressInfo.field = vm.model.progressInfo.field;
    
                            addConfig(items[step]).then(function (data) {
                                if (data[0].errorcode !== '0') {
                                    modalService.progressInfo.finishclose = '1';
    
                                    modalService.show({
                                        type: 'danger',
                                        title: translations.Varsel,
                                        message: data[0].errormessage,
                                        buttons: [{
                                            label: 'OK',
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                } else {
                                    //if data error, failed++;
                                    vm.model.progressInfo.step += increment;
                                    modalService.progressInfo.step = vm.model.progressInfo.step;
                                    modalService.progressInfo.finishclose = '1';
                                    doStep();
                                }
                            });
                        }
                    });
                };
                doStep();
            };
    
            vm.addConfigs = function () {
                productsConfigService.loadProductsConfigKitKeyNoGet().then(function (data) {
                    vm.model.getProductConfig.kit_keyno = data[0].kit_keyno;
                    
                    if (utilityService.validateParmsValue(vm.model.getProductConfig.kit_keyno) !== true) return;
                    
                    vm.model.progress = 'START';
                    vm.model.progressInfo.label = 'progressbar';
                    //vm.model.progressInfo.plabel = 'progressbar';
                    vm.model.progressInfo.field = '';
                    //vm.model.progressInfo.remField = '';
                    //vm.model.progressInfo.min = 0;
                    vm.model.progressInfo.max = 0;
                    vm.model.progressInfo.step = 0;
    
                    angular.forEach(vm.model.listOrderLine, function (item) {
                        if (item.item_id !== '0' && item.item_id !== '9999') {
                            vm.model.progressInfo.max++;
                        }
                    });
    
                    vm.configsProgress(vm.model.listOrderLine);
    
                    modalService.showProgress(vm.model.progressInfo).then(function (value) {
                        vm.model.progress = value;
                    });
                });
            };
    
            //vm.test = function () {
            //    var parmsProductConfig = {
            //        argtype: vm.model.getProductConfig.argtype,
            //        headno: vm.model.getProductConfig.headno,
            //        lineno: vm.model.getProductConfig.lineno
            //    };
            
            //    var mergeObj = parmsProductConfig;
            //    mergeObj.products = vm.model.listSelected;
            //    mergeObj.questions = vm.model.listProductConfigQuestion;
            //};
        }]
    });
})();
