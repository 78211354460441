(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderpick', {
        templateUrl: 'views/components/views/orderpick/orderpick.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$uibModal', 'rememberService', 'orderpickService', 'ieScreenBlockService', 'modalService', function ($uibModal, rememberService, orderpickService, ieScreenBlockService, modalService) {
            var vm = this;

            let rememberId = {
                grid:   'orderpick.grid',
                fields: 'orderpick.fields'
            };

            vm.fields = {
                stockplace_id: '',
                dates: {
                    from: null,
                    to: null,
                    bindex: 1
                }
            };

            vm.stockplaces = [];

            vm.grid = {
                dataTask: {
                    rememberId: rememberId.grid,
                    loadSetupId: null,
                    loadData: {
                        method: 2474,
                        parameters: function () {
                            return {
                                state: 'grid',
                                stockplace_id: vm.fields.stockplace_id,
                                from_date: vm.fields.dates.from,
                                to_date: vm.fields.dates.to
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'generateOrderpick', text: 'tt_generate_orderpick', func: function () { generateOrderpick(); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            vm.onDataChanged = function (value) {
                vm.fields.stockplace_id = value;

                rememberService.remember(rememberId.fields, vm.fields);

                vm.grid.gridfunc.read();
            };

            vm.onSelectedDateChanged = function (fom, tom, bindex) {
                vm.fields.dates.from = fom;
                vm.fields.dates.to = tom;
                vm.fields.dates.bindex = bindex;

                rememberService.remember(rememberId.fields, vm.fields);

                vm.grid.gridfunc.read();
            };

            function generateOrderpick() {
                var deliveries = [];
                var allRows = vm.grid.gridfunc.getAllRows();

                angular.forEach(allRows, function (item) {
                    if (item.is_selected) {
                        deliveries.push(item.delivery_keyno);
                    }
                });

                if (deliveries.length < 1) return;

                ieScreenBlockService.start();

                orderpickService.generateOrderpick({ deliveries: deliveries, tom: vm.fields.dates.to, fom: vm.fields.dates.from }).then(function (data) {
                    ieScreenBlockService.stop();

                    if (data.errorcode < 0) {
                        var instance = $uibModal.open({
                            component: 'deliveryOrderStatusError',
                            resolve: {
                                parameters: function () {
                                    return data;
                                }
                            },
                            size: 'pst-ninety',
                            backdrop: 'static'
                        });

                        instance.result.then(refresh, refresh);

                        instance.closed.then(refresh);
                    } else {
                        var translations = {
                            title: '',
                            msg: '',
                            ok: ''
                        };

                        angular.forEach(data.errormessage.translations, function (trans) {
                            translations[trans.id] = trans.value;
                        });

                        modalService.show({
                            type: 'success',
                            title: translations.title,
                            message: translations.msg,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-success',
                                action: function (modalInstance) {
                                    modalInstance.close();

                                    refresh();
                                }
                            }]
                        });
                    }
                });
            };

            var refresh = function () {
                vm.grid.gridfunc.read();
            };

            vm.$onInit = function () {
                rememberService.getLastStatus(rememberId.fields, true).then(function (response) {
                    vm.fields.stockplace_id = response.stockplace_id;
                });

                orderpickService.init().then(function (response) {
                    response.stockplaces.unshift(response.sp_all);

                    angular.copy(response.stockplaces, vm.stockplaces);
                });
            };
        }]
    });
})();
