(function () {
    'use strict';

    angular.module('imApp')
        .directive('imProgressDirective', ['layoutService', '$timeout', function (layoutService, $timeout) {
        var directive = {
            restrict: 'E',
            scope: {
                label: '@',         // Used for translation of label
                plabel: '=',        // Used instead of label when property is required
                field: '=',         //
                remField: '=',      // Used to to change the word translation of the remaining time field
                min: '=',           //
                max: '=',           //
                step: '=',          //
                finishclose: '=?',  //
                abortclose: '=?',   //
                onClose: '=',       //
                //size: '@',          // Added as class and will determine the size of the bar with ability to add one or more sizes xs, sm, md, lg. Ex col-xs-8 col-lg-4
                //optionfunc: '&',    // Used as reference to function in Controller
            },
            template:
            //'<div ng-class="addGroup()" id="{{model.mId}}" ng-style="{height: \'150px\'}">' +
            '<div ng-class="addGroup()" id="{{model.mId}}" ng-style="{overflow: \'auto\'}">' +
            //'<div ng-class="addGroup()" id="{{model.mId}}" ng-show="{{model.field}} !== 0">' +
                '<label ng-if="label !== undefined && label !== \'\'" class="name-label col-xx-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}" im-translate="{{label}}">' +
                '</label>' +
                '<label ng-if="plabel !== undefined && plabel !== \'\'" class="name-label col-xx-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">'+
                    '{{plabel}}' +
                '</label>' +
                '<div class="col-xx-12 im-no-pad">' +
                    //'<uib-progressbar class="progress-striped active" value="model.field" style="width:background-color:{{model.backgroundColor}}"></uib-progressbar>' +
                    '<div class="progress" ng-style="field !== undefined && field !== \'\' && {\'margin\': 0}">' +
                        '<div ng-class="addProgress()" role="progressbar"' +
                            'aria-valuenow="{{model.step}}" aria-valuemin="{{model.min}}" aria-valuemax="{{model.max}}" style="width:{{model.pst}}%; background-color:{{model.backgroundColor}}">' +
                            '{{model.pst}}% Complete' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<div ng-if="field !== undefined && field !== \'\'" class="col-xx-12 im-no-pad">' +
                    //'<div class="name-label col-sm-2 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">' +
                    //    //This is just here for some empty space
                    //'</div>' +
                    '<div class="col-xx-7 im-no-pad">' +
                        '<label class="name-label col-xx-12 im-no-pad" ng-style="model.css">' +
                            '{{step}} / {{max}}' +
                        '</label>' +
                    '</div>' +
                    '<div class="col-xx-5 im-no-pad">' +
                        '<label class="name-label col-xx-12 im-no-pad" ng-style="model.css" style="text-align:right" im-translate="{{model.remField}}">' +
                            ': {{model.remTime}}' +
                        '</label>' +
                    '</div>' +
                    '<div class="col-xx-12 im-no-pad">' +
                        '<label class="name-label col-xx-12 im-no-pad" ng-style="model.css">' +
                            '{{field}}' +
                        '</label>' +
                    '</div>' +
                '</div>' +
                '<div class="col-xx-12 im-no-pad" style="margin:10px 0 5px 0">' +
                    '<label class="name-label col-xx-12 im-no-pad input-text" ng-style="{\'fontSize\': fontSizes.textSizeS}" im-translate="finishclose">' +
                        ' ' + 
                        '<input class="im-checkbox input-text" type="checkbox" ng-model="model.checkbox" ng-true-value="\'1\'" ng-false-value="\'0\'" ng-style="{\'height\': fontSizes.textSizeS, \'width\': fontSizes.textSizeS}"/>' +
                    '</label>' +
                '</div>' +
                '<div class="col-xx-12 im-no-pad">' +
                    '<button ng-click="goClose()" type="button" ng-class="addBtnClass()" style="text-align:{{model.textalign}}; padding:0.5em; font-size:18px; min-height:43px;" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                        '<span ng-if="model.step !== model.max" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}" im-translate="{{model.btnAbortText}}"></span>' + 
                        '<span ng-if="model.step === model.max" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSize}" im-translate="{{model.btnFinishText}}"></span>' + 
                    '</button>' +
                '</div>' +
            '</div>',
            link: function ($scope) {
                $scope.model = {
                    mId: uuid(),
                    name: '',
                    field: '',
                    checkbox: '0',
                    cancel: '1',
                    btnAbortText: 'cancel',
                    btnFinishText: 'close',
                    min: 0,
                    max: 100,
                    pst: 0,
                    backgroundColor: '#337ab7',
                    transitionDefault: 'opacity 3s ease-out',
                    transition: 'opacity 3s ease-out',
                    css: null,
                    opacity: '1',
                    progress: 0,
                    textalign: 'center',
                    remDirty: false,
                    remField: 'remainingtime',
                    remTime: '0',
                    remTimeStart: 0
                };

                $scope.addGroup = function () {

                    var allClass = 'form-group im-no-mar'; //default

                    return allClass;
                };

                //$scope.addClass = function (size) {
                //    var allClass = 'col-lg-3 col-md-4 col-sm-6 col-xx-12 im-no-pad'; //default
                //    //var allClass = 'col-lg-3 col-md-3 col-sm-3 col-xs-3 col-xx-3 im-no-pad'; //default
                //    if (angular.isDefined($scope.size) && $scope.size !== null) {
                //        allClass = $scope.size;
                //    } else if (angular.isDefined(size) && size !== null) {
                //        //allClass = size;
                //    }
                //    return allClass;
                //}

                //#337ab7 blue
                //#5cb85c green
                $scope.addProgress = function (bar) {
                    var allClass = 'progress-bar progress-bar progress-bar-striped active'; //default

                    return allClass;
                };

                $scope.addBtnClass = function () {
                    var allClass = 'btn btn-primary btn-block btn-lg im-btn'; //default

                    if ($scope.model.step === $scope.model.max) {
                        allClass = 'btn btn-success btn-block btn-lg im-btn';
                    } else {
                        allClass = 'btn btn-danger btn-block btn-lg im-btn';
                    }

                    return allClass;
                };

                $scope.showButton = function () {
                    if ($scope.model.cancel === '0' || $scope.model.cancel === false) {
                        return true;
                    }
                    else if ($scope.model.cancel === '1' || $scope.model.cancel === true) {
                        return false;
                    }
                };

                $scope.goClose = function () {
                    if (angular.isFunction($scope.onClose)) {
                        if ($scope.model.step === $scope.model.max) {
                            $scope.onClose('FINISH');
                        } else {
                            $scope.onClose('ABORT');
                        }
                    }
                };

                $scope.fontSizes = { };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    angular.copy(info.fontSizes, $scope.fontSizes);
                });

                $scope.calcRemTime = function (step, max) {
                    var d = new Date();
                    
                    if ($scope.model.remTimeStart === 0) {
                        //$scope.model.remTimeStart = (d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString() + d.getMilliseconds().toString()).toString();
                        $scope.model.remTimeStart = ((d.getHours() * 3600) + (d.getMinutes() * 60) + d.getSeconds());
                    }

                    if (step !== 0 && angular.isDefined($scope.model.remTimeStart)) {
                        //var t = (parseInt(d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString() + d.getMilliseconds().toString()) - parseInt($scope.model.remTimeStart)) / step;
                        var t = (d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds() - $scope.model.remTimeStart) / step;
                        var t2 = Math.ceil((max - step) * t);

                        if (t2 >= 0) {
                            if (t2 >= 60) {
                                var t3 = Math.floor(t2 / 60);
                                $scope.model.remTime = t3 + 'm' + (t2 - t3*60) + 's';
                            } else {
                                $scope.model.remTime = t2 + 's';
                            }
                        }
                    }
                };

                $scope.calcPst = function (step, max) {
                    $scope.model.pst = Math.floor(step / max * 100);
                    $scope.calcRemTime(step, max);
                };

                $scope.$watch(function () {
                    return $scope.step;
                }, function () {
                    if (angular.isDefined($scope.step) && $scope.step !== null) {
                        $scope.model.step = $scope.step;
                        $scope.calcPst($scope.model.step, $scope.model.max);
                        if ($scope.model.step === $scope.model.max) {
                            $scope.model.remTimeStart = 0;
                            $scope.model.backgroundColor = '#5cb85c';
                            $scope.model.transition = $scope.model.transitionDefault;
                            $scope.model.opacity = '0';
                            
                            var fade = 0;
                            var fadeTime = 25;

                            var fadeStep = function () {
                                $timeout(function () {
                                    if (fade >= fadeTime) {
                                        $scope.model.css = { fontSize: $scope.fontSizes.textSizeS, transition: $scope.model.transition, opacity: $scope.model.opacity };
                                        //Close
                                        return;
                                    }

                                    fade += 1;

                                    fadeStep();
                                }, 100);
                            };

                            fadeStep();
                            //if (angular.isFunction($scope.onClose) && ($scope.model.checkbox === '1' || $scope.finishclose === '1')) {
                            if (angular.isFunction($scope.onClose) && $scope.model.checkbox === '1') {
                                $scope.onClose('FINISH');
                            }
                        } else {
                            $scope.model.backgroundColor = '#337ab7';
                            $scope.model.transition = 'opacity 0s';
                            $scope.model.opacity = '1';

                            $scope.model.css = { fontSize: $scope.fontSizes.textSizeS, transition: $scope.model.transition, opacity: $scope.model.opacity };
                        }
                    }
                });

                $scope.$watch(function () {
                    return $scope.min;
                }, function () {
                    if (angular.isDefined($scope.min) && $scope.min !== null) {
                        $scope.model.min = $scope.min;
                    }
                });

                $scope.$watch(function () {
                    return $scope.max;
                }, function () {
                    if (angular.isDefined($scope.max) && $scope.max !== null) {
                        $scope.model.max = $scope.max;
                        $scope.calcPst($scope.model.step, $scope.model.max);
                    }
                });

                $scope.$watch(function () {
                    return $scope.finishclose;
                }, function () {
                    if (angular.isDefined($scope.finishclose) && $scope.finishclose !== null && $scope.finishclose === '1' && $scope.model.step === $scope.model.max) {
                        $timeout(function () {
                            $scope.goClose();
                        }, 500);
                    } else if ($scope.finishclose && $scope.finishclose === '1' && $scope.model.step < $scope.model.max) {
                        $scope.model.checkbox = $scope.finishclose;
                    }
                });

                $scope.$watch(function () {
                    return $scope.abortclose;
                }, function () {
                    if (angular.isDefined($scope.abortclose) && $scope.abortclose !== null && $scope.abortclose === true) {
                        $timeout(function () {
                            $scope.goClose();
                        }, 500);
                    }
                });
            }
        };

        return directive;
    }]);
})();
