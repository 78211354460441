(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('edgeRelations', {
        templateUrl: 'views/components/views/edgeRelations/edgeRelations.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['edgeRelationsService', '$q', '$stateParams', '$uibModal', 'ieScreenBlockService', 'logisticService', 'rememberService', 'translateService', 'modalService', 'stateService', function (edgeRelationsService, $q, $stateParams, $uibModal, ieScreenBlockService, logisticService, rememberService, translateService, modalService, stateService)  {
            var vm = this;

            var rememberIdGrid = 'w_edgerelationsComponentGrid';

            var translations = [
            ];

            vm.arg = {
                type: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                value: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
            };

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 3209,
                        parameters: function () {
                            return {
                                argtype: vm.arg.type,
                                argvalue: vm.arg.value
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    //serverSideHandling: true
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            var remember = function () {
                rememberService.remember(rememberIdSelect, vm.select);

                vm.gridOptions.gridfunc.read();
            };


            var load = function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push(translateService.translateBatch(translations))

                $q.all(promises).then(function (response) {

                    vm.gridOptions.gridfunc.rebind();
                });

                return deferred.promise;
            };

            vm.$onInit = function () {
                load();
            };


            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'edgerelation':
                        go({ edgerelation_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };



        }]
    });
})();
