(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('accountingBs', {
        templateUrl: 'views/components/views/accountingBs/accountingBs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'accountingBsService', 'logisticService', 'accountingPlService', 'rememberService', function (stateService, utilityService, accountingBsService, logisticService, accountingPlService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var onDestroy = [];

            var variableNames = {
                businessco_no: '',
                acreportlayout_keyno: '',
                show_12months: '',
                date_fom: '',
                date_tom: '',
                dateselector_index: '',
                accounting_year: '',
                show_accounts: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                selectListReportLayouts: [],
                selectListAccountingYears: [],
                gridReady: false,
                grid12mReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.grid12m = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            accountingBsService.getAccountingBs().then(function (result) {
                angular.copy(result[0], vm.model.setting);

                loadAccountingYears();

                if (utilityService.validateParmsValue(vm.model.setting.grid_std_view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.setting.grid_std_view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.grid_12m_view_id) === true) {
                    vm.grid12m.dataTask.rememberId = vm.model.setting.grid_12m_view_id;
                }

                if (utilityService.validateParmsValue(vm.model.setting.grid_std_p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.setting.grid_std_p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                if (utilityService.validateParmsValue(vm.model.setting.grid_12m_p2_datatask_keyno) === true) {
                    vm.grid12m.dataTask.loadData = {
                        method: vm.model.setting.grid_12m_p2_datatask_keyno,
                        parameters: vm.model.setting
                    };
                }

                vm.model.gridReady = true;
                vm.model.grid12mReady = true;
            });

            var loadGrid = function () {
                if (vm.model.setting.show_12months === '0') {
                    vm.grid.gridfunc.rebind();
                }

                if (vm.model.setting.show_12months === '1') {
                    vm.grid12m.gridfunc.rebind();
                }
            };

            logisticService.listCompanies().then(function (result) {
                angular.copy(result, vm.model.selectListCompanies);
            });

            accountingPlService.listAcReportLayouts({ res: '0' }).then(function (result) {
                angular.copy(result, vm.model.selectListReportLayouts);
            });

            var loadAccountingYears = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                accountingBsService.listAccountingYears({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListAccountingYears);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'businessco_no':
                            variableNames[key] = 'w_accountingprocessing' + '.' + key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                    case 'acreportlayout_keyno':
                    case 'show_12months':
                    case 'date_fom':
                    case 'date_tom':
                    case 'dateselector_index':
                    case 'accounting_year':
                    case 'show_accounts':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadAccountingYears();
                        loadGrid();
                        break;
                    case 'acreportlayout_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.acreportlayout_keyno) !== true) return;

                        vm.model.setting.acreportlayout_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.acreportlayout_keyno, true) !== true) return;

                        rememberFunc('acreportlayout_keyno');
                        loadGrid();
                        break;
                    case 'show_12months':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_12months) !== true) return;

                        vm.model.setting.show_12months = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_12months, true) !== true) return;

                        rememberFunc('show_12months');
                        loadGrid();
                        break;
                    case 'accounting_year':
                        if (utilityService.validateWatchValue(value, vm.model.setting.accounting_year) !== true) return;

                        vm.model.setting.accounting_year = value;

                        if (utilityService.validateParmsValue(vm.model.setting.accounting_year, true) !== true) return;

                        rememberFunc('accounting_year');
                        loadGrid();
                        break;
                    case 'show_accounts':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_accounts) !== true) return;

                        vm.model.setting.show_accounts = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_accounts, true) !== true) return;

                        rememberFunc('show_accounts');
                        loadGrid();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                    //eval(id + " = '" + value + "'");
                    //break;
                }
            };

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = dateInterval;

                if (vm.model.setting.date_fom) rememberFunc('date_fom');

                if (vm.model.setting.date_tom) rememberFunc('date_tom');

                if (vm.model.setting.dateselector_index && vm.model.setting.dateselector_index !== -1) rememberFunc('dateselector_index');

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadGrid();
                }
            }

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region DESTROY FUNCTION

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion DESTROY FUNCTION
        }]
    });
})();
