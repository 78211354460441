(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('requisitionPlanView', {
        templateUrl: 'views/components/views/requisitionPlanView/requisitionPlanView.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'requisitionPlanViewService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, requisitionPlanViewService, logisticService, rememberService) {
            let vm = this;

            let variableNames = {
                stockplace_id_from: '',
                stockplace_id_to: '',
                productsearch_searchtext: ''
            };

            vm.model = {
                productSearchButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('productsearch_searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchItems() }
                ],
                setting: {},
                selectListStockPlacesFrom: [],
                selectListStockPlacesTo: [],
                itemsListViews: []
            };
            
            let loadSetting = function () {
                let deferred = $q.defer();

                requisitionPlanViewService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadRequisitionPlanViews()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadStocks = function () {
                logisticService.listStocks().then(function (result) {
                    angular.copy(result.sort((a, b) => a.stockplace_name > b.stockplace_name ? 1 : -1), vm.model.selectListStockPlacesFrom);
                    angular.copy(result.sort((a, b) => a.stockplace_name > b.stockplace_name ? 1 : -1), vm.model.selectListStockPlacesTo);
                });
            };
            
            let loadRequisitionPlanViews = function () {
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_from) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_to) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;

                vm.model.itemsListViews = [];
                
                return requisitionPlanViewService.listRequisitionPlanViews(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListViews);
                });
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'stockplace_id_from':
                    case 'stockplace_id_to':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    case 'productsearch_searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;

                        variableValue = vm.model.setting.productsearch_searchtext;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.searchItems = function () {
                rememberFunc('productsearch_searchtext');
                loadRequisitionPlanViews();
            };
            
            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'productsearch_searchtext':
                        vm.model.setting.productsearch_searchtext = '';

                        if (vm.model.setting.productsearch_searchtext === '') {
                            rememberFunc('productsearch_searchtext');
                            loadRequisitionPlanViews();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stockplace_id_from':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_from) !== true) return;

                        vm.model.setting.stockplace_id_from = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_from) !== true) return;

                        rememberFunc('stockplace_id_from');
                        loadRequisitionPlanViews();
                        break;
                    case 'stockplace_id_to':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id_to) !== true) return;

                        vm.model.setting.stockplace_id_to = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id_to) !== true) return;

                        rememberFunc('stockplace_id_to');
                        loadRequisitionPlanViews();
                        break;
                    case 'productsearch_searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.productsearch_searchtext) !== true) return;

                        vm.model.setting.productsearch_searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext, true) !== true) return;

                        if (vm.model.setting.productsearch_searchtext === '') {
                            rememberFunc('productsearch_searchtext');
                            loadRequisitionPlanViews();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'productsearch_searchtext':
                        if (utilityService.validateParmsValue(vm.model.setting.productsearch_searchtext) !== true) return;

                        if (vm.model.setting.productsearch_searchtext > '') {
                            rememberFunc('productsearch_searchtext');
                            loadRequisitionPlanViews();
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadStocks();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
