(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("travelBillLogsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadTravelBillLogsList: function (travelbill_no) {
                return p2DataTaskService.call(1623, {
                    travelbill_no: travelbill_no
                });
            }
        };

        return service;
    }]);
})();