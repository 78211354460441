(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("htmlProcService", ['$ihttp', function ($ihttp) {
        let service = {
            getHtmlProc: function (parms) {
                return $ihttp.post({
                    method: 3366,
                    parameters: parms || {}
                });
            },
            getHtmlProcHtml: function (parms) {
                return $ihttp.post({
                    method: 3374,
                    parameters: parms
                });
            },
            getHtmlProcList: function (htmlProcKeyno) {
                return $ihttp.post({
                    method: 3365,
                    parameters: { htmlproc_keyno_belongto: htmlProcKeyno }
                });
            },
            getPresentationTypeList: function () {
                return $ihttp.post({
                    method: 3368,
                    parameters: {}
                });
            },
            saveHtmlProc: function (htmlProc) {
                return $ihttp.post({
                    method: 3367,
                    parameters: htmlProc || {}
                });
            },
            deleteHtmlProc: function (htmlproc_keyno) {
                return $ihttp.post({
                    method: 3373,
                    parameters: { htmlproc_keyno: htmlproc_keyno }
                });
            }
        };

        return service;
    }]);
})();
