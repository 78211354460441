(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2WebpageTable', {
        templateUrl: 'views/components/views/p2WebpageTable/p2WebpageTable.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['p2WebpageTableService', '$stateParams', function(p2WebpageTableService, $stateParams) {
    
            var vm = this;
    
    
            vm.p2_webpage_table = p2WebpageTableService.p2_webpage_table;
    
            p2WebpageTableService.loadP2WebpageTable($stateParams.argtype, $stateParams.argvalue);
        }]
    });
})();