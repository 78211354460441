(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockCountRefresh', {
        templateUrl: 'views/components/views/stockCountRefresh/stockCountRefresh.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'stockCountRefreshService', function ($stateParams, stateService, stockCountRefreshService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
            
            vm.model = {
                spinRefresh: false,
                p2WStockCountRefreshGet: {}
            };
    
            // ######################
            // ## LOAD PROCEDURE CALL
            // ######################
            
            stockCountRefreshService.loadP2WStockCountRefreshGet($stateParams.stockcount_keyno).then(function (data) {
                vm.model.p2WStockCountRefreshGet = angular.copy(data[0]);
            });
            
            //##########################
            //## REFRESH BUTTON FUNCTION
            //##########################
            
            vm.refreshStock = function () {
                vm.model.spinRefresh = true;

                stockCountRefreshService.refreshP2WStockCountMenuStockCount($stateParams.stockcount_keyno).then(function (data) {
                    vm.model.spinRefresh = false;

                    stateService.back();
                });
            };
        }]
    });
})();