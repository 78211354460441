(function () {
    'use strict';
 
    angular.module('imApp')
    .filter('reverse', function () {
        return function (items, disable) {
            if (disable) return items;
            return items.slice().reverse();
        };
    })
    .filter('filterList', function () {
        return function (items, filterval, filterparm) {
            if (filterval === '') return items;

            var filtered = [];
            var individual = [];

            //Filterparm "strict" should make the filter check for the exact value that has been input, and not seperate on space
            if (angular.isDefined(filterparm) && filterparm.indexOf('strict') === true) {
                individual.push(filterval);
            } else {
                individual = filterval.split(' ');
            }

            angular.forEach(items, function (value) {
                var addToFilter = true;
                angular.forEach(individual, function (individualvalue) {
                    if (angular.isDefined(value.item_filtervalue) && value.item_filtervalue.toLowerCase().indexOf(individualvalue.toLowerCase()) === -1 && value.item_id !== '0' && value.item_id !== '-1') {
                        addToFilter = false;
                    }
                });

                angular.forEach(filtered, function (f_val) {
                    if (f_val === value) {
                        addToFilter = false;
                    }
                });

                if (addToFilter === true) {
                    filtered.push(value);
                }
            });

            return filtered;
        };
    })
    .filter('filterSecondList', function () {
        //return function (items, parentItem, disable) {
        return function (items, parentItem) {

            var filtered = [];

            angular.forEach(items, function (value) {
                if (angular.isDefined(value.parent_id) && angular.isDefined(parentItem.item_id) && value.parent_id === parentItem.item_id) {
                    filtered = value;
                }
            });

            //if (disable) return filtered;

            return filtered.slice().reverse();
        };
    })
    .filter('filterGlyph', function () {
        return function (glyphs, item) {
            var filtered = [];

            for (var i = 0; i < glyphs.length; i++) {
                if (angular.isDefined(glyphs[i]) && angular.isDefined(glyphs[i][0]) && angular.isDefined(glyphs[i][0].item_parent_id) && angular.isDefined(item.item_id) && glyphs[i][0].item_parent_id === item.item_id) {
                    angular.forEach(glyphs[i], function (g) {
                        filtered.push(g);
                    });
                    break;
                }
            }

            return filtered;
        };
    })
        .directive('imListDirective', ['$rootScope', '$timeout', '$compile', 'stateService', 'layoutService', 'appConfig', 'responsive', 'utilityService', 'userService', function ($rootScope, $timeout, $compile, stateService, layoutService, appConfig, responsive, utilityService, userService) {
        var directive = {
            restrict: 'E',
            scope: {
                ttChange: '&',          // BJS 20210806 - Fires when listItems is modified
                ttClickable: '<',       // BJS 20220419 - Used to disable what happens on click
                ttClick: '&',           // BJS 20220419 - Used in combination with ttClickable to run custom action on all clicks.
                ttEditmode: '<',        // BJS 20220731 - Used to put list into "edit mode".
                ttHideGoto: '<',          // BJS 20230323 - Used to hide "buttons" to the right
                ttDeleteitem: '&',      // BJS 20220731 - Callback for deleting list item.
                listItems: '=',         //Required and referances a list                                                // Supports several glyphs for single items, seperated by space
                sortItems: '=?',        //Used as an addition to the main list for sorting it into different groups     // Required only if sorting between multiple groups
                //menuItems: '=?',        //Used if custom menu items for multi-list will be used                         // Usable only with "multi", accepts a list of objects
                dataid: '@?',           //Used in conjunction with sortItems to specify the list key                    // Required with sortItems
                dataname: '@?',         //Used in conjunction with sortItems to specify the visible text                // Required with sortItems
                backitem: '@',          //Set to true if the items should lead back to the previous screen
                ltype: '@',             //Defines how the list core functions                                           // Accepted values "check", "check+", "back", "mark", "multi", "sortable", "custom", "default", if undefined or "default" it functions in a default way //Dual changed to multi and works
                typevar: '@',           //Used for different predefined functionality                                   // Accepts 'g' for goto
                ptypevar: '=',          //Used instead of typevar when property is required                             //
                mconfig: '=?',          //Can be used with ltype 'multi' to configure the list and what it shows        // Also allows custom menu items, accepts a list of objects
                badge: '@',             //If it has value, creates a badge behind "name" displaying a value
                optionfunc: '&',        //Links a function from controller
                sortfunc: '&',          //Links a function from controller, optional function after moving              // REQUIRES sortItems, optional, fires after item is dragged
                designVar: '@',         //Allows the user to activate 1 or several additional infolayers to the list    // 's' followed by a number '1' and/or '2' will add sublines, not amount but which one
                orderby: '@',           //Used to determine which variable to order the list by                         // NOT IMPLEMENTED
                revOrder: '@',          //Used for reversing the order of the list                                      // Accepts only true
                filterVal: '=',         //Used for adding a filter                                                      // Any value is searchable by default, filter type can limit this.
                filterParm: '@',        //Used for setting filter parameters                                            // NOT IMPLEMENTED,
                useDocIcon: '@',        //Specifies using icons instead of glyphicons for showing document types        // 
                hideIcon: '<'
            },
            template:
            //'<div ng-if="mconfig.show === true" class="well well-sm col-xs-12" style="padding-left: 3.2px;">' +
            '<div ng-if="mconfig.show === true" class="well well-sm col-xs-12">' +
                '<div ng-if="model.ltype !== \'default\'" class="col-xs-1 sp-0">' +
                    '<div ng-class="addMenuGlyph(\'switch\')" type="button" ng-click="goSwitch(\'switch\')"></div>' +
                '</div>' +
                '<div ng-if="model.ltype === \'default\'" class="col-xs-1 sp-0">' +
                '</div>' +
                '<div ng-if="mconfig.records === undefined" class="col-xs-11 sp-0">' +
                    //'<div ng-class="addMenuGlyph(\'trash\')" type="button" ng-click="goSwitch(\'trash\')"></div>' +
                    //'<div ng-class="addMenuGlyph(\'minus\')" type="button" ng-click="goSwitch(\'minus\')"></div>' +
                    //'<div ng-class="addMenuGlyph(\'plus\')" type="button" ng-click="goSwitch(\'plus\')"></div>' +
                    '<div ng-class="addMenuGlyph(\'sort\')" type="button" ng-click="goSwitch(\'sort\')"></div>' +
                    '<div ng-class="addMenuGlyph(\'resize\')" type="button" ng-click="goSwitch(\'resize\')"></div>' +
                    '<div ng-class="addMenuGlyph(\'columns\')" type="button" ng-click="goSwitch(\'columns\')"></div>' +
                    '<div ng-class="addMenuGlyph(\'edit\')" type="button" ng-click="goSwitch(\'edit\')"></div>' +
                '</div>' +
                '<div ng-if="mconfig.records.length > 0" class="col-xs-11 sp-0">' +
                    '<div ng-repeat="mitem in mconfig.records|reverse:model.disableFilter">' +
                        '<div ng-if="mitem.show === undefined || mitem.show === true" ng-class="addMenuGlyph(mitem)" type="button" ng-click="goSwitch(mitem)"></div>' +
                    '</div>' +
                '</div>' +
            '</div>' +
            //'<div ng-if="model.resizeMode === true" class="well well-sm col-xs-12" style="padding-left: 3.2px;">' +
            //'<div ng-if="model.resizeMode === true" class="well well-sm col-xs-12">' +
            '<div ng-if="model.resizeMode === true" class="well well-sm col-xs-12" ng-style="{\'paddingRight\': \'.2em\'}">' +
                //'<input class="form-control input-lg input-text" ng-model="model.field" ng-change="" ng-style="{\'fontSize\': fontSizes.textSize, \'width\': model.field}" style="width:40px; min-width: 40px;">' +
                //'<input class="form-control input-lg input-text" ng-model="model.field" ng-change="" ng-style="{\'fontSize\': fontSizes.textSize, \'width\': \'40px\'}" style="min-width: 40px;">' +
                //'<input class="form-control input-lg input-text" ng-model="model.fieldWidth" ng-change="" ng-style="addStyle(\'resize\')">' +
                //'<div ng-repeat="mitem in mconfig.records|reverse:model.disableFilter">' +
                //    '<div ng-if="mitem.show === undefined || mitem.show === true" ng-class="addMenuGlyph(mitem)" type="button" ng-click="goSwitch(mitem)"></div>' +
                //'</div>' +
                '<div ng-class="addColArea(\'leftAreaSlider\')"></div>' +
                //'<div ng-class="addColArea(\'centerAreaSlider\')" ng-style="{\'paddingLeft\': \'5px\'}">' +
                '<div ng-class="addColArea(\'centerAreaSlider\')">' +
                    '<div class="sp-0" ya-no-ui-slider="slider.options" ya-no-ui-slider-events="slider.events"></div>' +
                '</div>' +
                '<div ng-class="addColArea(\'rightAreaSlider\', item)"></div>' +
            '</div>' +
            '<div class="col-xs-12" style="padding: 0;">' +
                '<div ng-if="ltype !== \'sortable\' && model.sortMode === false" ng-repeat="item in listItems|reverse:model.disableFilter|filterList:model.filterVal:model.filterParm">' +
                    '<div ng-if="backitem === \'true\' && model.ltype === \'default\'" class="col-xs-12 im-no-pad">' +
                        '<button data-testid="item_button_{{item.item_id}}" ng-click="goBack(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<div class="glyphicon glyphicon-chevron-left grayish pull-left" style="padding: 0; text-align:left; color:#B8B8B8"></div> ' +
                            '<div ng-repeat="gitem in model.glyphlist|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            '<div class="col-xs-11 listview_text" ng-style="model.listViewTextStyle">' +
                                '{{item.item_name}} ' +
                                '<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                '<span class="badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                            '</div>' +
                        '</button>' +
                        '<button ng-if="((designVar === undefined || designVar === \'\') || designVar.indexOf(\'t\') > -1) && (ttHideGoto) !== true" data-testid="item_button_{{item.item_id}}_newtab" ng-click="goTab(item)" ng-style="model.listViewTextStyle" type="button" class="fas fa-window-restore pull-right im-newtab">' +
                            '<span ng-if="designVar === undefined || designVar.indexOf(\'t0\') <= -1" class="im-tooltiptext" im-translate="new_tab"></span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-if="backitem !== \'true\' && model.ltype === \'default\'" class="col-xs-12 im-no-pad">' +
                        '<button style="padding: 0; width: 100%;" data-testid="item_button_{{item.item_id}}" ng-click="goTo(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12 im-list-btn--flex" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                        //'<button data-testid="item_button_{{item.item_id}}" ng-click="goTo(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-class="addThumbClass(item)" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<div ng-if="item.item_thumb !== undefined && item.item_thumb !== null && item.item_thumb.length > 0">' +
                                '<img ng-if="item.item_thumb !== undefined && item.item_thumb !== null && item.item_thumb.length > 0" style="margin-left: 9px" ng-src="{{item.item_thumb}}?thumbnail={{fontSizes.thumbnail}}" ng-style="model.thumbnailStyle" ></img>' +
                            //'</div>' +

                            '<div class="im-list-btn--flex" style="z-index: 1; padding: .5em .2em .5em .2em; width: 100%";>' +
                                '<div ng-if="hideIcon !== true && useDocIcon !== \'true\'" ng-repeat="gitem in model.glyphlist|filterGlyph:item" style="z-index: 1;">' +
                                    '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                                '</div>' +
                                // BJS 20171017 - Added use of icon for docfile type.
                                '<img ng-if="hideIcon !== true && useDocIcon === \'true\'" class="pull-left" ng-src="{{model.appRoot}}/images/icons/32px/{{item.extension}}.png" style="margin:auto; padding-left: 5px; margin-top: -6px; height: 32px;">' +
                                '<div class="listview_text" ng-style="model.listViewTextStyle" style="width: 100%;">' +
                                    '<div class="col-xs-11 im-no-pad" ng-style="{\'overflow\': \'inherit\'}">' +
                                        '{{item.item_name}} ' +
                                        '<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                        '<span class="badge im-list-badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                                        '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                        '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                                    '</div>' +
                                    '<div class="col-xs-1 im-no-pad">' +
                                        // BJS 20220731 - Addded button used for deleting list item.
                                        '<div class="col-xs-12 sp-0" ng-if="(ttHideGoto || false) !== true">' +
                                            '<div ng-if="(ttEditmode || false) === true" ng-click="onDeleteItem(item)" ng-style="model.deleteButtonStyle" type="button" class="btn btn-danger btn-sm glyphicon glyphicon-remove pull-right"></div>' +
                                            //'<div ng-if="(designVar === undefined || designVar === \'\' || designVar === \'t0\') && item.item_state !== \'customaction\' && ttClickable === true" class="glyphicon glyphicon-chevron-right grayish pull-right im-chevron"></div> ' +
                                            //'<div ng-if="designVar.indexOf(\'s\') > -1 && ((designVar.indexOf(\'1\') > -1 && designVar.indexOf(\'2\') <= -1) || (designVar.indexOf(\'2\') > -1 && designVar.indexOf(\'1\') <= -1))" class="glyphicon glyphicon-chevron-right grayish pull-right im-chevron-2"></div> ' +
                                            //'<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1 && designVar.indexOf(\'2\') > -1" class="glyphicon glyphicon-chevron-right grayish pull-right im-chevron"></div> ' +
                                        '</div>' +
                                    '</div>' +
                                '</div>' +
                            '</div>' +
                        '</button>' +
                        '<button ng-if="((designVar === undefined || designVar === \'\') || designVar.indexOf(\'t\') > -1) && item.item_state !== \'customaction\' && ttClickable === true && (ttHideGoto || false) !== true" data-testid="item_button_{{item.item_id}}_newtab" ng-click="goTab(item)" ng-style="model.listViewTextStyle" type="button" class="fas fa-window-restore pull-right im-newtab">' +
                            '<span ng-if="designVar === undefined || designVar.indexOf(\'t0\') <= -1" class="im-tooltiptext" im-translate="new_tab"></span>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-if="ltype === \'check\' && item.item_id !== \'0\' && item.item_id !== \'-1\'" class="col-xs-12 im-no-pad">' +
                        '<button ng-click="goCheck(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<div ng-if="item.item_is_selected !== \'1\'" ng-repeat="gitem in model.glyphlist|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            '<div ng-if="item.item_is_selected === \'1\'" ng-repeat="gitem in model.glyphlist2|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            '<div class="listview_text" ng-style="model.listViewTextStyle">' +
                                '<div class="col-xs-11 im-no-pad" ng-style="{\'overflow\': \'inherit\'}">' +
                                    '{{item.item_name}} ' +
                                    '<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span class="badge im-list-badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                                '</div>' +
                            '</div>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-if="ltype === \'mark\' && item.item_id !== \'0\' && item.item_id !== \'-1\'" class="col-xs-12 im-no-pad">' +
                        '<button ng-click="goMark(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-class="addMark(item)" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<div ng-if="item.item_is_selected !== \'1\'" ng-repeat="gitem in model.glyphlist|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            '<div ng-if="item.item_is_selected === \'1\'" ng-repeat="gitem in model.glyphlist2|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            '<div class="listview_text" ng-style="model.listViewTextStyle">' +
                                '<div class="col-xs-11 im-no-pad" ng-style="{\'overflow\': \'inherit\'}">' +
                                    '{{item.item_name}} ' +
                                    '<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span class="badge im-list-badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                                '</div>' +
                            '</div>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-if="ltype === \'multi\' && model.sortMode === false" class="col-xs-12 im-ct im-no-pad">' +
                        //'<button ng-click="goCustom(item)" type="button" ng-class="removeHover(item)" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{\'fontSize\': fontSizes.textSize, \'paddingBottom\': \'0\' }">' +
                        '<button ng-click="goCustom(item, $event)" type="button" ng-class="removeHover(item)" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="addStyleMultiListBtn(item)">' +
                            '<div ng-class="addColArea(\'leftArea\')">' +
                                '<div ng-if="item.item_id === \'0\' && mconfig.enabled === true && mconfig.show === false">' +
                                    '<div ng-class="addGlyph(\'switch\')" type="button" ng-click="goSwitch(\'switch\')"></div>' +
                                '</div>' +
                                //'<div ng-if="(item.item_id === \'0\' && (mconfig.enabled === false || mconfig.show === true)) || (item.item_id !== \'0\' && (mconfig.enabled === false || mconfig.show === true))">' +
                                '<div ng-if="mconfig === undefined || mconfig.enabled === false || mconfig.show === true || item.item_id !== \'0\'">' +
                                    '<div ng-if="item.item_is_selected !== \'1\'" ng-repeat="gitem in model.glyphlist|filterGlyph:item">' +
                                        '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                                    '</div>' +
                                    '<div ng-if="item.item_is_selected === \'1\'" ng-repeat="gitem in model.glyphlist2|filterGlyph:item">' +
                                        '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                                    '</div>' +
                                '</div>' +
                            '</div>' +
                            '<div ng-class="addColArea(\'centerArea\')">' +
                                //'<div id="imp-{{item.item_id}}" ng-class="addColItem(\'main\')" ng-style="addStyleCenterAreaItem(item)">' + //ng-style="{\'fontSize\': fontSizes.textSize; \'overflow\': \'inherit\'; \'width\': item.item_col_width}">' +
                                '<div ng-class="addColItem(\'main\')" ng-style="addStyleCenterAreaItem(item)">' + //ng-style="{\'fontSize\': fontSizes.textSize; \'overflow\': \'inherit\'; \'width\': item.item_col_width}">' +
                                    '<div ng-if="item.item_id === \'0\' || item.item_id === \'-1\'" class="multi-list-col-text">' +
                                        '<b>{{item.item_name}} </b>' +
                                    '</div>' +
                                    '<div ng-if="item.item_id !== \'0\' && item.item_id !== \'-1\'" class="multi-list-col-text">' +
                                        //'{{item.item_name}} ' +
                                        '<div ng-hide="thisFocus(item, \'imp\', \'{{item.item_id}}\')">{{item.item_name}} </div>' +
                                        '<input ng-hide="!thisFocus(item, \'imp\', \'{{item.item_id}}\')" id="{{item.item_id}}-imp-{{item.item_id}}-{{model.mId}}" value="{{item.item_name}}" ng-focus="inputFocus($event)" ng-blur="inputBlur($event)" ng-style="addInputStyle()"/>' +
                                    '</div>' +
                                    '<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span class="badge im-list-badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1 && model.inlineEditMode === false" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                '</div>' +
                                '<div ng-if="model.secondary !== undefined && model.secondary.length > 0" ng-class="addColItem(sitem)" ng-style="addStyleCenterAreaItem(sitem, item)" ng-repeat="sitem in model.secondary|reverse:model.disableFilter|filterSecondList:item">' +
                                    '<div ng-if="item.item_id === \'0\'" class="multi-list-col-text">' +
                                        '<b>{{sitem.item_col}} </b>' +
                                    '</div>' +
                                    '<div ng-if="item.item_id !== \'0\'" class="multi-list-col-text">' +
                                        '<div ng-hide="thisFocus(item, \'ims\', {{item.item_secondary.length-$index-1}})">{{sitem.item_col}} </div>' +
                                        '<input ng-hide="!thisFocus(item, \'ims\', {{item.item_secondary.length-$index-1}})" id="{{item.item_secondary.length-$index-1}}-ims-{{item.item_id}}-{{model.mId}}" value="{{sitem.item_col}}" ng-focus="inputFocus($event)" ng-blur="inputBlur($event)" ng-style="addInputStyle()"/>' +
                                    '</div>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1 && model.inlineEditMode === false" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                                '</div>' +
                            '</div>' +
                            '<div ng-class="addColArea(\'rightArea\', item)">' +
                                //'<div ng-if="item.item_id !== \'0\' && item.item_id !== \'-1\'">' +
                                //    '<div ng-if="designVar === undefined || designVar === \'\'" ng-class="addCol(\'rightGlyph\')"></div> ' +
                                //    '<div ng-if="designVar.indexOf(\'s\') > -1 && ((designVar.indexOf(\'1\') > -1 && designVar.indexOf(\'2\') <= -1) || (designVar.indexOf(\'2\') > -1 && designVar.indexOf(\'1\') <= -1))" ng-class="addCol(\'rightGlyph\')"></div> ' +
                                //    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1 && designVar.indexOf(\'2\') > -1" ng-class="addCol(\'rightGlyph\')"></div> ' +
                                //'</div>' +
                            '</div>' +
                        '</button>' +
                    '</div>' +
                    '<div ng-if="ltype === \'custom\'" class="col-xs-12 im-no-pad">' +
                        '<button ng-click="goCustom(item)" type="button" class="btn btn-default listview btn-block btn-lg col-xs-12" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<div ng-if="backitem === \'true\'" class="glyphicon glyphicon-chevron-left grayish pull-left" style="padding: 0; text-align:left; color:#B8B8B8"></div> ' +
                            '<div ng-if="item.item_is_selected !== \'1\'" ng-repeat="gitem in model.glyphlist|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            '<div ng-if="item.item_is_selected === \'1\'" ng-repeat="gitem in model.glyphlist2|filterGlyph:item">' +
                                '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            '</div>' +
                            //'<div ng-repeat="gitem in model.glyphlist|filterGlyph:item">' +
                            //    '<div class="pull-left" ng-style="addStyle(gitem)" ng-class="addGlyph(gitem)"></div>' +
                            //'</div>' +
                            '<div class="listview_text" ng-style="model.listViewTextStyle">' +
                                '<div class="col-xs-11 im-no-pad" ng-style="{\'overflow\': \'inherit\'}">' +
                                    '{{item.item_name}} ' +
                                    '<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                    '<span class="badge im-list-badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                    '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                                '</div>' +
                            '</div>' +
                        '</button>' +
                    '</div>' +
                '</div>' +
                '<div id="{{model.mId}}" ng-if="ltype === \'sortable\'" class="col-xs-12 im-no-pad">' +
                    '<div ng-repeat="group in model.sortlist">' +
                        '<div ui-sortable="sortableOptions" ng-class="addClass()" ng-style="addStyleWell(group)" ng-model="group.items">' +
                            '<div class="pull-left name-label sortable__label col-xs-1-5 im-sort-pad" ng-style="addStyleSortlist(group)" ng-class="addGlyph(group)"></div>' +
                            '<label class="name-label sortable__label col-xs-10-5 im-sort-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{group.value}}</label>' +
                            '<div class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="goSort(item)" ng-repeat="item in group.items" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                                '{{item.item_name}} ' +
                                //'<span ng-if="badge !== undefined && badge !== \'\'" class="spin-text" ng-style="{\'fontSize\': fontSizes.textSizeSs}"> </span>' +
                                //'<span class="badge im-list-badge" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item[badge]}}</span>' +
                                '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                            '</div>' +
                            //'<div class="sort-list-placeholder" ng-click="goSort()"></div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<div id="{{model.mId}}" ng-if="(ltype === \'multi\' || model.ltype === \'default\') && model.sortMode === true" class="col-xs-12 im-no-pad">' +
                    //'<div ng-repeat="group in model.sortlist">' +
                        //'<div ui-sortable="sortableOptions" ng-class="addClass()" ng-style="addStyleWell(listItems)" ng-model="listItems">' +
                        '<div ui-sortable="sortableOptions" ng-class="addClass()" ng-style="addStyleWell()" ng-model="listItems">' +
                        //'<div ui-sortable="sortableOptions" ng-class="addClass()" ng-style="addStyleWell()" ng-model="group.items">' +
                            //'<div class="pull-left name-label col-xs-1-5 im-sort-pad" ng-style="addStyleSortlist()" ng-class="addGlyph(\'sort\')"></div>' +
                            //'<div class="pull-left name-label col-xs-1-5 im-sort-pad" ng-style="" ng-class=""></div>' +
                            //'<label class="name-label col-xs-10-5 im-sort-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{group.value}}</label>' +
                            //'<label class="name-label col-xs-10-5 im-sort-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">Test</label>' +
                            //'<div class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="goSort(item)" ng-repeat="item in group.items" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<div class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="goSort(item)" ng-repeat="item in listItems" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<div ng-class="addItemClass(item)" ng-click="goSort(item)" ng-repeat="item in listItems" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<div ng-show="item.item_type !== \'info\'" ng-class="addItemClass(item)" ng-click="goSort(item)" ng-repeat="item in listItems" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            //'<div ng-show="item.item_type !== \'info\'" class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="goSort(item)" ng-repeat="item in group.items" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                            '<div ng-show="item.item_type !== \'info\'" class="sort-list-item btn btn-default listview btn-block btn-lg col-xs-12" ng-click="goSort(item)" ng-repeat="item in listItems" ng-style="{\'fontSize\': fontSizes.textSize}">' +
                                //'<div class="pull-left " ng-style="addStyleSortlist()" ng-class="addGlyph(\'sort\')"></div>' +
                                '{{item.item_name}} ' +
                                //'Test ' +
                                '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'1\') > -1" class="col-xs-12 im-no-pad" ng-style="{\'fontSize\': fontSizes.textSizeS}">{{item.item_name_sub1}}</div>' +
                                '<div ng-if="designVar.indexOf(\'s\') > -1 && designVar.indexOf(\'2\') > -1" class="col-xs-12 im-no-pad" style="color:#909090;" ng-style="{\'fontSize\': fontSizes.textSizeSs}">{{item.item_name_sub2}}</div>' +
                            '</div>' +
                            //'<div class="sort-list-placeholder" ng-click="goSort()"></div>' +
                        '</div>' +
                    //'</div>' +
                '</div>' +
            '</div>',
            link: function ($scope, $element) {
                $scope.model = {
                    mId: uuid(),
                    disableFilter: true,
                    listIsDragged: false,
                    didUpdate: false,
                    sortMode: false,
                    resizeMode: false,
                    inlineEditMode: false,
                    isKeypress: false,
                    prosessingKeypress: false,
                    gridClicked: false,
                    currentInline: null,
                    activeInlineId: null,
                    elemInFocus: null,
                    filterVal: '',
                    filterParm: '',
                    field: '',
                    fieldWidth: '',
                    fieldWidthMin: '8',
                    //fieldWidthPadding: '5',
                    sliderMinDefault: 8,
                    sliderMin: 8,
                    slidersChanging: false,
                    colWidthChanging: false,
                    validatingEditable: false,
                    editableValidated: false,
                    inlineEditUpdate: false,
                    label: '',
                    ltype: 'default',
                    //showMenu: false,
                    initialGlyphColor: '',
                    itemLoc: { itemId: null, listFromId: null, listToId: null, listFromIndex: null, listToIndex: null },
                    menuList: [],
                    sortlist: [],
                    glyphlist: [],
                    glyphlist2: [],
                    secondary: [],
                    colList: [],
                    numCol: 0,
                    currentSize: responsive.current,
                    appRoot: appConfig.appRoot,
                    listViewTextStyle: {
                        fontSize: '',
                        paddingLeft: ''
                    },
                    deleteButtonStyle: {
                        fontSize: ''
                    },
                    thumbnailStyle: {
                        aspectRatio: '1 / 1',
                        width: '',
                        objectFit: 'cover',
                    },
                };

                // BJS 20220928
                if (angular.isUndefined($scope.hideIcon)) {
                    $scope.hideIcon = false;
                }

                var preventCustomClick = false;

                $scope.onDeleteItem = function (item) {
                    preventCustomClick = true;

                    if (angular.isFunction($scope.ttDeleteitem)) {
                        $scope.ttDeleteitem({$item: item});
                    }

                    $timeout(function () { preventCustomClick = false; });
                };

                $scope.combineId = function (index, role) {
                    var combinedId = index + '-' + role + '-' + uuid();
                    return combinedId;
                };

                if (angular.isDefined($scope.revOrder) && $scope.revOrder === 'true') {
                    $scope.model.disableFilter = false;
                }

                $scope.sortableOptions = {
                    placeholder: "sort-list-placeholder",
                    connectWith: ".sort-list-container",
                    tolerance: 'pointer',
                    //ui-preserve-size: true,
                    start: function () {
                        //console.log('start');
                    
                    },
                    update: function (e, ui) {
                        $scope.model.listIsDragged = true;

                        $scope.model.didUpdate = true;

                        if (angular.isUndefined(ui.item.sortable.model)) {
                            ui.item.sortable.cancel();
                        }

                        //var log = "model: " + JSON.stringify(ui.item.sortable.model)
                        //        + "\nindex: " + ui.item.sortable.index
                        //        //+ "\nsource: " + JSON.stringify(ui.item.sortable.source)
                        //        //+ "\nsourceList: " + ui.item.sortable.sourceList
                        //        //+ "\nsourceModel: " + JSON.stringify(ui.item.sortable.sourceModel)
                        //        //+ "\ncancel: " + ui.item.sortable.cancel
                        //        //+ "\nisCanceled: " + ui.item.sortable.isCanceled
                        //        //+ "\nisCustomHelperUsed: " + ui.item.sortable.isCustomHelperUsed
                        //        + "\ndropindex: " + ui.item.sortable.dropindex
                        //        //+ "\ndroptarget: " + JSON.stringify(ui.item.sortable.droptarget)
                        //        //+ "\ndroptargetList: " + JSON.stringify(ui.item.sortable.droptargetList);
                        //        + "\ndroptargetModel: " + JSON.stringify(ui.item.sortable.droptargetModel);

                        $scope.model.itemLoc.itemId = ui.item.sortable.model.item_id;
                        $scope.model.itemLoc.listFromIndex = ui.item.sortable.index;
                        $scope.model.itemLoc.listToIndex = ui.item.sortable.dropindex;
                        $scope.model.itemLoc.listFromId = ui.item.sortable.model.item_sort_id;
                        //findItemById($scope.model.itemLoc, true);
                    },
                    stop: function (e, ui) {
                        // this callback has the changed model
                        if ($scope.model.didUpdate === true) {
                            findItemById($scope.model.itemLoc, false);

                            if ($scope.model.itemLoc.listFromId !== $scope.model.itemLoc.listToId) {
                                updateOrder($scope.model.itemLoc.listFromId);
                                updateItemlist($scope.model.itemLoc);
                            }
                            updateOrder($scope.model.itemLoc.listToId);

                            if (angular.isFunction($scope.sortfunc)) {
                                $scope.sortfunc({ item: $scope.model.itemLoc });
                            }
                        }

                        $scope.model.listIsDragged = false;
                        $scope.model.didUpdate = false;
                    },
                    //items: "div:not(.not-sortable)"
                };

                $scope.slider = {
                    min: $scope.model.sliderMin,
                    options: {
                        start: [20, 70],
                        connect: true,
                        margin: $scope.model.sliderMin,
                        //limit: 40,
                        //padding: [10, 15],
                        padding: $scope.model.sliderMin,
                        step: 0.5,
                        //orientation: 'vertical', //vertical, horizontal
                        //direction: 'rtl', //rtl, ltr
                        tooltips: [true, true], //wNumb({ decimals: 1 }) formatting is possible, just not like this
                        range: { min: 0, max: 100 }
                    },
                    events: {
                        update: function (values, handle, unencoded) {
                            //$scope.model.slidersChanging = true;

                            //$scope.model.slidersChanging = false;
                        },
                        slide: function (values, handle, unencoded) {
                            $scope.model.slidersChanging = true;

                            var colCount = $scope.model.colList.length;
                            var nextValue = 100;
                            var skip = 0;

                            if (angular.isDefined(unencoded[handle + 1])) {
                                nextValue = unencoded[handle + 1];
                            }

                            if (handle === 0) {
                                //$scope.listItems[0].item_col_width = nextValue - unencoded[handle];
                                $scope.listItems[0].item_col_width = unencoded[handle];

                                for (var col = 0; col < colCount; col++) {
                                    if ($scope.model.colList[col].visible === false) {
                                        skip++;
                                        continue;
                                    } else if (col - skip === handle) {
                                        $scope.listItems[0].item_secondary[col].item_col_width = nextValue - unencoded[handle];
                                        break;
                                    }
                                }
                            } else {
                                for (var sec = 0; sec < colCount; sec++) {
                                    if ($scope.model.colList[sec].visible === false) {
                                        skip++;
                                        continue;
                                    } else if (sec - skip+1 === handle) {
                                        $scope.listItems[0].item_secondary[sec].item_col_width = unencoded[handle] - unencoded[handle - 1];

                                        skip = 0;

                                        for (var nxt = sec+1; nxt < colCount; nxt++) {
                                            if ($scope.model.colList[nxt].visible === false) {
                                                skip++;
                                                continue;
                                            } else if (nxt - skip === handle) {
                                                $scope.listItems[0].item_secondary[nxt].item_col_width = nextValue - unencoded[handle];
                                                break;
                                            }
                                        }
                                        break;
                                    }

                                    //if ($scope.model.colList[sec].visible === false) {
                                    //    skip++;
                                    //    continue;
                                    //}
                                }

                                //var next = 0;
                                //var colCount = $scope.model.colList.length;

                                //for (var col2 = 0; col < colCount; col++) {
                                //    if ($scope.model.colList[col].visible === false) {
                                //        skip++;
                                //        continue;
                                //    } else if (col - skip === handle) {
                                //        $scope.listItems[0].item_secondary[handle].item_col_width = nextValue - unencoded[handle];

                                //        for (var sec = handle; sec < colCount; sec++) {
                                //            //if ($scope.model.colList[sec].visible === false) {
                                //            //    next++;
                                //            //    continue;
                                //            //} else {
                                //            //    $scope.listItems[0].item_secondary[sec].item_col_width = nextValue - unencoded[handle];
                                //            //    break;
                                //            //}
                                //            if ($scope.model.colList[sec].visible === true) {
                                //                next++;
                                //                continue;
                                //            } else {
                                //                $scope.listItems[0].item_secondary[sec].item_col_width = nextValue - unencoded[handle];
                                //                break;
                                //            }

                                //        }

                                //        break;
                                //    }
                                //}
                            }

                            $scope.model.slidersChanging = false;
                        },
                        set: function (values, handle, unencoded) { },
                        change: function (values, handle, unencoded) { }
                    },
                    functions: {
                        setStarts: function () {
                            if (angular.isUndefined($scope.listItems[0]) && angular.isUndefined($scope.listItems[0].item_secondary)) return;
                            if ($scope.model.colList.length <= 0) return;
                            //var colCount = $scope.listItems[0].item_secondary.length;
                            var colCount = $scope.model.colList.length;
                            //var visibleColCount = 0;
                            var totalLength = 0;
                            var sliderStarts = [];
                            //var fullWidth = function (col) {
                            //    return $scope.model.colList[col].width + totalLength;
                            //}

                            $scope.model.slidersChanging = true;

                            for (var col = 0; col < colCount; col++) {
                                if (col === 0) {
                                    //visibleColCount++;
                                    if (angular.isDefined($scope.listItems[0].item_col_width) && isNumber($scope.listItems[0].item_col_width)) {
                                        if (parseFloat($scope.listItems[0].item_col_width) > 100) {
                                            $scope.listItems[0].item_col_width = 100;
                                        }
                                        sliderStarts.push(parseFloat($scope.listItems[0].item_col_width));
                                        totalLength += parseFloat($scope.listItems[0].item_col_width);
                                    } else {
                                        sliderStarts.push($scope.slider.min);
                                        totalLength += $scope.slider.min;
                                    }

                                    if (totalLength >= 100) {
                                        break;
                                    }
                                }

                                if ($scope.model.colList[col].visible === true) {
                                    //visibleColCount++;
                                    if ($scope.model.colList[col].width > 100) {
                                        $scope.model.colList[col].width = 100;
                                    } else if ($scope.model.colList[col].width < $scope.slider.min) {
                                        $scope.model.colList[col].width = $scope.slider.min;
                                    }

                                    if (col !== colCount - 1) {
                                        sliderStarts.push(($scope.model.colList[col].width + totalLength));
                                    }

                                    totalLength += $scope.model.colList[col].width;
                                    //if (fullWidth(col) > 100) {
                                    //if ($scope.model.colList[col].width + totalLength > 100) {
                                    //    var canShrink = false;
                                    //    var needShrink = false;
                                    //    var totalShrinkage = 0;
                                    //    //do {
                                    //    //    canShrink = false;
                                    //    //    needShrink = false;
                                    //    //    if ($scope.model.colList[col].width > $scope.slider.min) {
                                    //    //        $scope.model.colList[col].width -= $scope.slider.options.step;
                                    //    //        totalShrinkage += $scope.slider.options.step;
                                    //    //        if ($scope.model.colList[col].width + totalLength - totalShrinkage > 100) {
                                    //    //            needShrink = true;
                                    //    //        }
                                    //    //        if (needShrink && $scope.model.colList[col].width > $scope.slider.min) {
                                    //    //            canShrink = true;
                                    //    //        }
                                    //    //    }
                                    //    //    //if (fullWidth(col) > 100) {
                                    //    //    if (needShrink) {
                                    //    //        for (var ss = 0; ss <= sliderStarts.length; ss++) {
                                    //    //            if (sliderStarts[ss] <= $scope.slider.min) continue;
                                    //    //            sliderStarts[ss] -= $scope.slider.options.step;
                                    //    //            totalShrinkage += $scope.slider.options.step;

                                    //    //            needShrink = false;

                                    //    //            if ($scope.model.colList[col].width + totalLength - totalShrinkage > 100) {
                                    //    //                needShrink = true;
                                    //    //            }
                                    //    //            if (needShrink && $scope.model.colList[col].width > $scope.slider.min) {
                                    //    //                canShrink = true;
                                    //    //            }

                                    //    //            if (!needShrink) break;
                                    //    //        }
                                    //    //    }
                                    //    //} while (needShrink && canShrink);

                                    //    if (col !== colCount - 1) {
                                    //        sliderStarts.push(($scope.model.colList[col].width + totalLength - totalShrinkage));
                                    //    }

                                    //    totalLength += $scope.model.colList[col].width - totalShrinkage;
                                    //} else {
                                    //    if (col !== colCount - 1) {
                                    //        sliderStarts.push(($scope.model.colList[col].width + totalLength));
                                    //    }

                                    //    totalLength += $scope.model.colList[col].width;
                                    //}
                                }
                            }

                            //if (totalLength + $scope.slider.options.step < 100) {
                            //    var remainderLength = 100 - totalLength;

                            //    var colLengthAdjustment = Math.floor(remainderLength / visibleColCount);

                            //    var additionalAdjustments = (remainderLength - (colLengthAdjustment * visibleColCount)) / $scope.slider.options.step;

                            //    for (col = 0; col < colCount; col++) {
                            //        if (col === 0) {
                            //            $scope.listItems[0].item_col_width = parseFloat($scope.listItems[0].item_col_width) + colLengthAdjustment;
                            //            totalLength += colLengthAdjustment;
                            //        }

                            //        if ($scope.model.colList[col].visible === true) {
                            //            $scope.model.colList[col].width += colLengthAdjustment;
                            //            totalLength += colLengthAdjustment;
                            //        }
                            //    }

                            //    while (additionalAdjustments > 0) {
                            //        for (col = colCount; col >= 0; col--) {
                            //            if (col === 0) {
                            //                $scope.listItems[0].item_col_width = parseFloat($scope.listItems[0].item_col_width) + $scope.slider.options.step;
                            //                totalLength += $scope.slider.options.step;
                            //                additionalAdjustments--;
                            //            } else if ($scope.model.colList[col-1].visible === true) {
                            //                $scope.model.colList[col-1].width += $scope.slider.options.step;
                            //                totalLength += $scope.slider.options.step;
                            //                additionalAdjustments--;
                            //            }

                            //            if (additionalAdjustments <= 0) break;
                            //        }
                            //    }

                            //    var skip = 0;
                            //    var adjustedLength = 0;

                            //    for (col = 0; col < colCount; col++) {
                            //        $scope.listItems[0].item_secondary[col].item_col_width = $scope.model.colList[col].width;
                            //        if (col === 0) {
                            //            sliderStarts[col] = parseFloat($scope.listItems[0].item_col_width) + adjustedLength;
                            //            adjustedLength += parseFloat($scope.listItems[0].item_col_width)
                            //        } else {
                            //            if ($scope.model.colList[col - 1].visible === true) {
                            //                sliderStarts[col - skip] = $scope.model.colList[col-1].width + adjustedLength;
                            //                adjustedLength += $scope.model.colList[col-1].width
                            //            } else {
                            //                skip++;
                            //            }
                            //        }
                            //    }
                            //}

                            $scope.slider.options.start = sliderStarts;
                            $scope.model.slidersChanging = false;
                        },
                        setConnections: function () {
                            var sliderCount = $scope.slider.options.start.length;
                            var sliderConnections = [];

                            for (var col = 0; col <= sliderCount; col++) {
                                sliderConnections.push(true);
                            }

                            $scope.slider.options.connect = sliderConnections;
                        },
                        setTooltips: function () {
                            var sliderCount = $scope.slider.options.start.length;
                            var sliderTooltips = [];

                            for (var col = 0; col < sliderCount; col++) {
                                sliderTooltips.push(true);
                            }

                            $scope.slider.options.tooltips = sliderTooltips;
                        }
                    }
                };

                $scope.slider.functions.setConnections();

                //var addSliderConnections = function () {
                //    var sliderCount = $scope.slider.options.start.length;
                //    var sliderConnections = [];

                //    for (var col = 0; col <= sliderCount; col++) {
                //        sliderConnections.push(true);
                //    }

                //    $scope.slider.options.connect = sliderConnections;
                //}

                //$("#" + $scope.model.mId).sortable();
                //$("#" + $scope.model.mId).disableSelection();

                var findItemById = function (itemLoc, idFrom) {
                    var foundItem = false;
                    if (angular.isDefined($scope.model.sortlist)) {
                        for (var i = 0; i < $scope.model.sortlist.length; i++) {
                            if (angular.isDefined($scope.model.sortlist[i].items)) {
                                for (var l = 0; l < $scope.model.sortlist[i].items.length; l++) {
                                    if ($scope.model.sortlist[i].items[l].item_id === itemLoc.itemId) {
                                        if (idFrom === true) {
                                            itemLoc.listFromId = $scope.model.sortlist[i].key;
                                        } else {
                                            itemLoc.listToId = $scope.model.sortlist[i].key;
                                        }
                                        foundItem = true;
                                        break;
                                    }
                                }
                            }

                            if (foundItem === true) {
                                break;
                            }
                        }
                    }
                };

                var updateOrder = function (listId) {
                    if (angular.isDefined($scope.model.sortlist)) {
                        for (var i = 0; i < $scope.model.sortlist.length; i++) {
                            if ($scope.model.sortlist[i].key === listId && angular.isDefined($scope.model.sortlist[i].items)) {
                                for (var l = 0; l < $scope.model.sortlist[i].items.length; l++) {
                                    $scope.model.sortlist[i].items[l].orderby = i*100 + l;
                                }
                            }
                        }
                    }
                };

                var updateItemlist = function (itemLoc) {
                    if (angular.isDefined($scope.listItems) && $scope.listItems !== null && angular.isDefined($scope.listItems.records) && $scope.listItems.records !== null) {
                        for (var i = 0; i < $scope.listItems.records.length; i++) {
                            if ($scope.listItems.records[i].item_id === itemLoc.itemId) {
                                $scope.listItems.records[i].item_sort_id = itemLoc.listToId;
                                break;
                            }
                        }
                    } else if (angular.isDefined($scope.listItems) && $scope.listItems !== null) {
                        for (var j = 0; j < $scope.listItems.length; j++) {
                            if ($scope.listItems[j].item_id === itemLoc.itemId) {
                                $scope.listItems[j].item_sort_id = itemLoc.listToId;
                                break;
                            }
                        }
                    }
                };

                // BJS 20220419
                var isCustomClick = function (item) {
                    if (preventCustomClick === true) return true; // BJS 20220731
                    if (angular.isUndefined($scope.ttClickable)) return false;
                    if ($scope.ttClickable === null) return false;
                    if ($scope.ttClickable === true) return false;

                    if (angular.isFunction($scope.ttClick)) {
                        $scope.ttClick({item: item});
                    }
                    return true;
                };

                $scope.goTo = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    // BJS 20210607 - Added customaction to enable using listitem as a button.
                    if (l_item.item_state === 'customaction') {
                        if (angular.isFunction(l_item.item_action) === true) {
                            l_item.item_action(l_item);
                        }
                    } else {
                        if (l_item.item_path === '') return;

                        stateService.go(l_item.item_state, l_item.item_parms);
                    }
                };

                $scope.goTab = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_state === '') return;

                    stateService.newTab(l_item.item_state, l_item.item_parms);
                };

                $scope.goBack = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_id === '') return;

                    if (angular.isFunction($scope.optionfunc)) {
                        $scope.optionfunc({ item: l_item });
                    }

                    stateService.back();
                };

                $scope.goCheck = function (item) {
                    if (isCustomClick(item)) return;

                    if (item.item_id === '0' || item.item_id === '-1') return;

                    if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color === $scope.model.initialGlyphColor) {
                        if (angular.isDefined(item.item_glyphicon_2) && item.item_glyphicon_2 !== null) {
                            item.item_is_selected = '1';
                        }
                        item.item_glyphicon_color = 'green';
                    } else if ($scope.model.initialGlyphColor !== '') {
                        item.item_glyphicon_color = $scope.model.initialGlyphColor;
                        item.item_is_selected = '0';
                    } else {
                        item.item_glyphicon_color = 'red';
                        item.item_is_selected = '0';
                    }
                };

                $scope.goMark = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_id === '' || l_item.item_id === '0' || l_item.item_id === '-1') return;

                    if (l_item.item_is_selected === '0') {
                        l_item.item_is_selected = '1';
                    } else {
                        l_item.item_is_selected = '0';
                    }

                    if (angular.isFunction($scope.optionfunc)) {
                        $scope.optionfunc({ item: l_item });
                    }
                };

                $scope.goSwitch = function (item) {
                    if (isCustomClick(item)) return;
                    //console.log('switchbtn clicked');
                    //console.dir(item);

                    if (angular.isDefined(item) && angular.isDefined(item.item_id) && angular.isFunction($scope.mconfig.function)) {
                        //console.log('Function');
                        if (item.item_name === 'sort') {
                            $scope.model.sortMode = !$scope.model.sortMode;
                            //populateSortlistFromMenu();
                            //console.dir($scope.listItems);
                        }
                        if (item.item_name === 'resize') {
                            $scope.model.resizeMode = !$scope.model.resizeMode;
                            //populateSortlistFromMenu();
                            //console.dir($scope.listItems);

                            if ($scope.model.inlineEditMode === true) {
                                $scope.model.inlineEditMode = false;
                                $scope.mconfig.deactivate('edit');
                            }

                            if ($scope.model.resizeMode === true) {
                                $scope.slider.functions.setStarts();
                                $scope.slider.functions.setConnections();
                                $scope.slider.functions.setTooltips();
                            }
                        }
                        if (item.item_name === 'columns') {
                            //$scope.model.resizeMode = !$scope.model.resizeMode;
                            //populateSortlistFromMenu();
                            //console.dir($scope.listItems);
                        }
                        if (item.item_name === 'edit') {
                            $scope.model.inlineEditMode = !$scope.model.inlineEditMode;
                            //populateSortlistFromMenu();

                            if ($scope.model.resizeMode === true) {
                                $scope.model.resizeMode = false;
                                $scope.mconfig.deactivate('resize');
                            }

                            if (!$scope.model.inlineEditMode) {
                                $scope.model.editableValidated = false;
                            }
                        }
                        $scope.mconfig.function(item);
                    } else if (item === 'switch') {
                        $scope.mconfig.show = !$scope.mconfig.show;
                        if ($scope.mconfig.show === false) {
                            $scope.model.sortMode = false;
                            $scope.model.resizeMode = false;
                            $scope.model.inlineEditMode = false;
                            $scope.model.editableValidated = false;
                            //$scope.mconfig.function('deactivate');
                            $scope.mconfig.deactivate('all');
                        } else if ($scope.mconfig.show === true) {
                            if ($scope.mconfig.records.length > 1) {
                                var recCount = 0;
                                var lastEnabledRec = null;
                                var didActivateARec = false;
                                for (var r = 0; r < $scope.mconfig.records.length; r++) {
                                    if ((angular.isUndefined($scope.mconfig.records[r].isIcon) || $scope.mconfig.records[r].isIcon === false)
                                        && (angular.isDefined($scope.mconfig.records[r].show) && $scope.mconfig.records[r].show === true)
                                        && (angular.isDefined($scope.mconfig.records[r].enabled) && $scope.mconfig.records[r].enabled === true)) {
                                        recCount++;
                                        lastEnabledRec = $scope.mconfig.records[r];
                                        if (lastEnabledRec.isActive === true) {
                                            didActivateARec = true;
                                            $scope.mconfig.activate(lastEnabledRec);
                                            switch (lastEnabledRec.item_name) {
                                                case 'option':
                                                    break;
                                                case 'sort':
                                                    $scope.model.sortMode = true;
                                                    break;
                                                case 'resize':
                                                    $scope.model.resizeMode = true;
                                                    $scope.slider.functions.setStarts();
                                                    $scope.slider.functions.setConnections();
                                                    $scope.slider.functions.setTooltips();
                                                    break;
                                                case 'edit':
                                                    $scope.model.inlineEditMode = true;
                                                    break;
                                            }
                                        }
                                    }
                                }
                                if (recCount === 1 && !didActivateARec) {
                                    $scope.mconfig.activate(lastEnabledRec);
                                    switch (lastEnabledRec.item_name) {
                                        case 'option':
                                            break;
                                        case 'sort':
                                            $scope.model.sortMode = true;
                                            break;
                                        case 'resize':
                                            $scope.model.resizeMode = true;
                                            $scope.slider.functions.setStarts();
                                            $scope.slider.functions.setConnections();
                                            $scope.slider.functions.setTooltips();
                                            break;
                                        case 'edit':
                                            $scope.model.inlineEditMode = true;
                                            break;
                                    }
                                    $scope.mconfig.function(lastEnabledRec);
                                }
                            }
                        }
                    } else if (angular.isDefined(item)) {
                        console.log('Defined as: ' + item);
                        console.dir(item);
                    } else {
                        console.log('item undefined');
                    }
                };

                $scope.goSort = function (item) {
                    if (isCustomClick(item)) return;

                    if ($scope.model.listIsDragged === true || $scope.model.sortMode === true || item.item_id === '') {
                        console.log('click canceled');
                        return;
                    }

                    if (angular.isFunction($scope.optionfunc)) {
                        $scope.optionfunc({ item: item });
                    }

                    if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('g') > -1) {
                        if (item.item_path === '') return;
                        stateService.go(item.item_state, item.item_parms);
                    }
                };

                $scope.goCustom = function (l_item, e) {
                    if (isCustomClick(l_item)) return;
                    if (l_item.item_id === '') return;

                    var child = null;

                    if ($scope.model.inlineEditMode === true) {
                        //console.log('inline mode is currently active');
                        //console.dir($scope.listItems);
                        $(this).blur();
                        $scope.model.currentInline = l_item;
                        if (angular.isUndefined(e)) return;
                        //console.log('e.client');
                        //console.dir(e);
                        //console.dir(e.clientX);
                        //console.dir(e.currentTarget.children);
                        //console.dir(e.currentTarget.children[1]);
                        //console.dir(e.currentTarget.children[1].offsetLeft);
                        //console.dir(e.clientX - e.currentTarget.children[1].offsetLeft);
                        child = findClosestChild(e.currentTarget.children[1], e.clientX - e.currentTarget.children[1].offsetLeft);
                        //console.dir(child);
                        //inlineEditClick(child);
                        gridClick(child, 'edit');
                        return;
                    }

                    if ($scope.ltype.indexOf('custom') <= -1 && (angular.isUndefined($scope.typevar) || $scope.typevar.indexOf('g') <= -1) && $scope.model.inlineEditMode === false) {
                        //console.log('inline mode is currently active');
                        //console.dir($scope.listItems);
                        //$(this).blur();
                        $scope.model.currentInline = l_item;
                        if (angular.isUndefined(e)) return;
                        child = findClosestChild(e.currentTarget.children[1], e.clientX - e.currentTarget.children[1].offsetLeft);
                        //inlineEditClick(child);
                        //console.log('goCustom');
                        //console.dir(child);
                        gridClick(child, 'item_func');
                        if ($scope.model.gridClicked) {
                            return;
                        }
                    }

                    if (angular.isFunction($scope.optionfunc)) {
                        $scope.optionfunc({ item: l_item });
                    }

                    if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('g') > -1) {
                        if (l_item.item_path === '') return;
                        stateService.go(l_item.item_state, l_item.item_parms);
                    }
                };

                $scope.addMark = function (item) {
                    var markClass = '';

                    if (item.item_is_selected === '1') {
                        //markClass = 'gold';
                    }

                    return markClass;
                };

                var cuttingPoints = [
                    { first: 'col-md-11-25 col-sm-11 col-xs-10-75 col-xx-10-25', second: 'col-xx-0', view: 'col-md-0-25 col-sm-0-25 col-xs-0-25 col-xx-0-25', noGlyphs: 'col-md-11-5 col-sm-11-5 col-xs-11-25 col-xx-11', moreGlyphs: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5' },
                    { first: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5', second: 'col-xx-0', view: 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75', noGlyphs: 'col-md-11 col-sm-11 col-xs-10-5 col-xx-10-25', moreGlyphs: 'col-md-10-75 col-sm-10-25 col-xs-9-25 col-xx-8-75' },
                    { first: 'col-md-10-75 col-sm-10 col-xs-9-5 col-xx-8-75', second: 'col-xx-0', view: 'col-md-0-5 col-sm-1 col-xs-1 col-xx-1-5', noGlyphs: 'col-md-11 col-sm-10-5 col-xs-10 col-xx-9-5', moreGlyphs: 'col-md-10-5 col-sm-9-75 col-xs-8-75 col-xx-8' },
                    { first: 'col-md-10-5 col-sm-9-5 col-xs-9 col-xx-8', second: 'col-xx-0', view: 'col-md-0-75 col-sm-1-5 col-xs-1-5 col-xx-2-25', noGlyphs: 'col-md-10-75 col-sm-10 col-xs-9-5 col-xx-8-75', moreGlyphs: 'col-md-10-25 col-sm-9-25 col-xs-8-25 col-xx-7-25' },
                    { first: 'col-md-10-25 col-sm-9 col-xs-8-5 col-xx-7-25', second: 'col-xx-0', view: 'col-md-1 col-sm-2 col-xs-2 col-xx-3', noGlyphs: 'col-md-10-5 col-sm-9-5 col-xs-9 col-xx-8', moreGlyphs: 'col-md-10 col-sm-8-75 col-xs-7-75 col-xx-6-5' },
                    { first: 'col-md-10 col-sm-8-5 col-xs-8 col-xx-6-5', second: 'col-xx-0', view: 'col-md-1-25 col-sm-2-5 col-xs-2-5 col-xx-3-75', noGlyphs: 'col-md-10-25 col-sm-9 col-xs-8-5 col-xx-7-25', moreGlyphs: 'col-md-9-75 col-sm-8-25 col-xs-7-25 col-xx-5-75' },
                    { first: 'col-md-9-75 col-sm-8 col-xs-7-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-1-5 col-sm-3 col-xs-3 col-xx-4-5', noGlyphs: 'col-md-10 col-sm-8-5 col-xs-8 col-xx-6-5', moreGlyphs: 'col-md-9-5 col-sm-7-75 col-xs-6-75 col-xx-5' },
                    { first: 'col-md-9-5 col-sm-7-5 col-xs-7 col-xx-5-75', second: 'col-xx-0', view: 'col-md-1-75 col-sm-3-5 col-xs-3-5 col-xx-4-5', noGlyphs: 'col-md-9-75 col-sm-8 col-xs-7-5 col-xx-6-5', moreGlyphs: 'col-md-9-25 col-sm-7-25 col-xs-6-25 col-xx-5' },
                    { first: 'col-md-9-25 col-sm-7 col-xs-6-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2 col-sm-4 col-xs-4 col-xx-4-5', noGlyphs: 'col-md-9-5 col-sm-7-5 col-xs-7 col-xx-6-5', moreGlyphs: 'col-md-9 col-sm-6-75 col-xs-5-75 col-xx-5' },
                    { first: 'col-md-9 col-sm-6-5 col-xs-6 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-25 col-sm-4-5 col-xs-4-5 col-xx-4-5', noGlyphs: 'col-md-9-25 col-sm-7 col-xs-6-5 col-xx-6-5', moreGlyphs: 'col-md-8-75 col-sm-6-25 col-xs-5-25 col-xx-5' },
                    { first: 'col-md-8-75 col-sm-6 col-xs-5-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-5 col-sm-5 col-xs-5 col-xx-4-5', noGlyphs: 'col-md-9 col-sm-6-5 col-xs-6 col-xx-6-5', moreGlyphs: 'col-md-8-5 col-sm-5-75 col-xs-4-75 col-xx-5' },
                    { first: 'col-md-8-5 col-sm-5-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-2-75 col-sm-5-5 col-xs-5-5 col-xx-4-5', noGlyphs: 'col-md-8-75 col-sm-6 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-8-25 col-sm-5-25 col-xs-4-25 col-xx-5' },
                    { first: 'col-md-8-25 col-sm-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-md-3 col-sm-6 col-xs-6 col-xx-4-5', noGlyphs: 'col-md-8-5 col-sm-5-5 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-8 col-sm-4-75 col-xs-4-75 col-xx-5' },
                    { first: 'col-md-4-75 col-sm-5 col-xs-5 col-xx-5-75', second: 'col-xx-0', view: 'col-xx-0', noGlyphs: 'col-md-5 col-sm-5-5 col-xs-5-5 col-xx-6-5', moreGlyphs: 'col-md-4-5 col-sm-4-5 col-xs-4-25 col-xx-5' }
                ];

                var findCuttingPoint = function (cuttingLength, condition, noGlyphs, moreGlyphs, colView) {
                    var index = Math.floor(cuttingLength / 2 + 0.5);
                    
                    if (index < 1 || index > 12) {
                        index = 13;
                    }

                    var first = moreGlyphs === true ? 'moreGlyphs' : noGlyphs === true ? 'noGlyphs' : 'first';
                    var second = colView === true ? 'view' : 'second';

                    var cuttingPoint1st = cuttingPoints[index][first];
                    var cuttingPoint2nd = cuttingPoints[index][second];

                    if (condition === 'cuttingPoint1st') return cuttingPoint1st;

                    return condition === 'cuttingPoint2nd' ? cuttingPoint2nd : '';
                };

                $scope.addCol = function (condition, item) {
                    var allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
                    var noGlyphs = true;
                    var moreGlyphs = false;
                    var cuttingPoint1st = '';
                    var cuttingPoint2nd = '';

                    if (angular.isDefined($scope.model.glyphlist) && $scope.model.glyphlist.length > 0) {
                        for (var i = 0; i < $scope.model.glyphlist.length; i++) {
                            if ($scope.model.glyphlist[i].length > 0 && angular.isDefined($scope.model.glyphlist[i][0])) {
                                if ($scope.model.glyphlist[i][0].item_glyphicon === '') continue;

                                noGlyphs = false;

                                if ($scope.model.glyphlist[i].length > 1) {
                                    moreGlyphs = true;

                                    break;
                                }
                            }
                        }
                    }

                    if (angular.isDefined($scope.listItems) && $scope.listItems.length > 0) {
                        var columnNo = 0;
                        var foundRowCol = false;

                        for (var k = 0; k < $scope.model.item_secondary.length; k++) {
                            for (var j = 0; j < $scope.model.secondary[k].length; j++) {
                                if (item === $scope.model.secondary[k][j]) {
                                    columnNo = j;

                                    foundRowCol = true;

                                    break;
                                }
                            }
                            if (foundRowCol === true) {
                                break;
                            }
                        }

                        var fullLength = 0;
                        var tempLength = 0;

                        $scope.model.colList.sort(function (a, b) {
                            return parseFloat(a.priority) - parseFloat(b.priority);
                        });

                        for (var l = 0; l < $scope.model.colList.length; l++) {
                            if ($scope.model.colList[l].visible !== true) continue;

                            if ($scope.model.colList[l].colLength % 2 === 0) {
                                tempLength += $scope.model.colList[l].colLength;
                            } else {
                                tempLength += $scope.model.colList[l].colLength + 1;
                            }

                            $scope.model.colList[l].viewXX = false;
                            $scope.model.colList[l].viewXS = false;
                            $scope.model.colList[l].viewSM = false;
                            $scope.model.colList[l].viewMD = false;
                            $scope.model.colList[l].viewLG = false;

                            switch (true) {
                                case tempLength < 12:
                                    $scope.model.colList[l].viewXX = true;
                                    $scope.model.colList[l].viewXS = true;
                                    $scope.model.colList[l].viewSM = true;
                                    $scope.model.colList[l].viewMD = true;
                                    $scope.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 20:
                                    $scope.model.colList[l].viewXS = true;
                                    $scope.model.colList[l].viewSM = true;
                                    $scope.model.colList[l].viewMD = true;
                                    $scope.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 28:
                                    $scope.model.colList[l].viewSM = true;
                                    $scope.model.colList[l].viewMD = true;
                                    $scope.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 45:
                                    $scope.model.colList[l].viewMD = true;
                                    $scope.model.colList[l].viewLG = true;
                                    break;
                                case tempLength < 60:
                                    $scope.model.colList[l].viewLG = true;
                                    break;
                            }
                        }

                        $scope.model.colList.sort(function (a, b) {
                            return parseFloat(a.orderby) - parseFloat(b.orderby);
                        });

                        for (var m = 0; m < $scope.model.colList.length; m++) {
                            var checkSize = function (size) {
                                if ($scope.model.currentSize !== size) return;

                                $scope.model.colList[m].view = $scope.model.colList[m]['view' + size.toUpperCase()] || false;
                            };

                            checkSize('xx');
                            checkSize('xs');
                            checkSize('sm');
                            checkSize('md');
                            checkSize('lg');
                        }

                        if (condition === 'main') {
                            for (var n = 0; n < $scope.model.colList.length; n++) {
                                if ($scope.model.colList[n].view === true && $scope.model.colList[n].visible === true) {
                                    if ($scope.model.colList[n].colLength % 2 === 0) {
                                        fullLength += $scope.model.colList[n].colLength;
                                    } else {
                                        fullLength += $scope.model.colList[n].colLength + 1;
                                    }
                                }
                            }
                        } else {
                            fullLength = $scope.model.colList[columnNo].colLength;
                        }

                    }

                    cuttingPoint2nd = findCuttingPoint(fullLength, 'cuttingPoint2nd', noGlyphs, moreGlyphs, $scope.model.colList[columnNo].view);
                    cuttingPoint1st = findCuttingPoint(fullLength, 'cuttingPoint1st', noGlyphs, moreGlyphs, false);

                    if (condition === 'leftGlyph') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
                        if (noGlyphs === true && (angular.isUndefined($scope.mconfig) || $scope.mconfig.enabled === false)) {
                            allClass = 'col-xx-0 im-no-pad';
                        }
                        if (moreGlyphs === true || (angular.isDefined($scope.mconfig) && $scope.mconfig.enabled === true)) {
                            allClass = 'col-md-0-5 col-sm-0-75 col-xs-1-25 col-xx-1-5 im-no-pad';
                        }
                    } else if (condition === 'rightGlyph') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';

                        if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('g') > -1) {
                            allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 grayish pull-right im-chevron';
                        }
                    } else if (condition === 'rightGlyph2') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 grayish pull-right im-chevron-2';
                    } else if (condition === 'nonGlyph') {
                        allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';
                    } else if (condition === 'main') {
                        allClass = cuttingPoint1st + ' im-no-pad listview_text';
                    } else if (condition === 'secondary') {
                        if ($scope.model.colList[columnNo].alignment === 'left') {
                            allClass = cuttingPoint2nd + ' im-no-pad im-l-c-l pull-right';
                        } else if ($scope.model.colList[columnNo].alignment === 'center') {
                            allClass = cuttingPoint2nd + ' im-no-pad im-l-c-c pull-right';
                        } else {
                            allClass = cuttingPoint2nd + ' im-no-pad im-l-c-r pull-right';
                        }
                    }

                    return allClass;
                };

                $scope.addColArea = function (condition, item) {
                    //var allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
                    var allClass = '';
                    var menuBtnInList = false;
                    var glyphCount = 0;
                    var leftArea = 'im-no-pad';
                    var centerArea = 'im-no-pad';
                    var rightArea = ''; //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75

                    //$scope.mconfig.enabled === true && $scope.mconfig.show === false

                    if (angular.isDefined($scope.mconfig) && $scope.mconfig.enabled === true && $scope.mconfig.show === false) {
                        menuBtnInList = true;
                    }

                    if (angular.isDefined($scope.model.glyphlist) && $scope.model.glyphlist.length > 0) {
                        for (var g = 0; g < $scope.model.glyphlist.length; g++) {
                            if ($scope.model.glyphlist[g].length > 0 && angular.isDefined($scope.model.glyphlist[g][0])) {
                                if ($scope.model.glyphlist[g][0].item_glyphicon === '') continue;

                                if ($scope.model.glyphlist[g].length > glyphCount) {
                                    glyphCount = $scope.model.glyphlist[g].length;
                                }
                            }
                        }
                    }

                    if (menuBtnInList === true && glyphCount < 2) {
                        glyphCount = 2;
                    }

                    switch ($scope.model.currentSize) {
                        case 'xx': // 0-75 per glyph //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                            if (glyphCount === 0) {
                                leftArea += ' col-xx-0';
                                centerArea += ' col-xx-11-25';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-xx-0-75';
                                centerArea += ' col-xx-10-5';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-xx-1-5';
                                centerArea += ' col-xx-9-75';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-xx-2-25';
                                centerArea += ' col-xx-9';
                            } else {
                                leftArea += ' col-xx-3';
                                centerArea += ' col-xx-8-25';
                            }
                            rightArea += 'col-xx-0-75';
                            break;
                        case 'xs': // 0-75 per glyph, 0-5 per glyph, alternating //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                            if (glyphCount === 0) {
                                leftArea += ' col-xs-0';
                                centerArea += ' col-xs-11-5';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-xs-0-75';
                                centerArea += ' col-xs-10-75';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-xs-1-25';
                                centerArea += ' col-xs-10-25';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-xs-2';
                                centerArea += ' col-xs-9-5';
                            } else {
                                leftArea += ' col-xs-2-5';
                                centerArea += ' col-xs-9';
                            }
                            rightArea += 'col-xs-0-5';
                            break;
                        case 'sm': // 0-5, then 0-25, then 0-25, then 0-5 //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                            if (glyphCount === 0) {
                                leftArea += ' col-sm-0';
                                centerArea += ' col-sm-11-5';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-sm-0-5';
                                centerArea += ' col-sm-11';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-sm-0-75';
                                centerArea += ' col-sm-10-75';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-sm-1';
                                centerArea += ' col-sm-10-5';
                            } else {
                                leftArea += ' col-sm-1-5';
                                centerArea += ' col-sm-10';
                            }
                            rightArea += 'col-sm-0-5';
                            break;
                        case 'md':
                        case 'lg': // 0-25 per glyph //col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75
                        default:
                            if (glyphCount === 0) {
                                leftArea += ' col-md-0';
                                centerArea += ' col-md-11-75';
                            } else if (glyphCount === 1) {
                                leftArea += ' col-md-0-25';
                                centerArea += ' col-md-11-5';
                            } else if (glyphCount === 2) {
                                leftArea += ' col-md-0-5';
                                centerArea += ' col-md-11-25';
                            } else if (glyphCount === 3) {
                                leftArea += ' col-md-0-75';
                                centerArea += ' col-md-11';
                            } else {
                                leftArea += ' col-md-1';
                                centerArea += ' col-md-10-75';
                            }
                            rightArea += 'col-md-0-25';
                    }

                    if (condition === 'leftArea' || condition === 'leftAreaSlider') {
                        allClass = leftArea;
                    } else if (condition === 'centerArea' || condition === 'centerAreaSlider') {
                        allClass = centerArea;
                    } else if (condition === 'rightArea' || condition === 'rightAreaSlider') {
                        allClass = rightArea;

                        if (condition !== 'rightAreaSlider' && item.item_id !== '0' && item.item_id !== '-1') {
                            if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('g') > -1) {
                                allClass += ' glyphicon glyphicon-chevron-right grayish pull-right im-chevron';
                            } else {
                                allClass += ' glyphicon glyphicon-none grayish pull-right';
                            }
                        }
                    } else if (condition === 'nonGlyph') {
                        allClass = leftArea + ' glyphicon glyphicon-none grayish pull-right';
                    }

                    if ($scope.model.inlineEditMode) {
                        allClass += ' inline-edit-mode-parent';
                    }

                    //var noGlyphs = true;
                    //var moreGlyphs = false;
                    //var cuttingPoint1st = '';
                    //var cuttingPoint2nd = '';

                    //if (angular.isDefined($scope.model.glyphlist) && $scope.model.glyphlist.length > 0) {
                    //    for (var i = 0; i < $scope.model.glyphlist.length; i++) {
                    //        if ($scope.model.glyphlist[i].length > 0 && angular.isDefined($scope.model.glyphlist[i][0])) {
                    //            if ($scope.model.glyphlist[i][0].item_glyphicon === '') continue;

                    //            noGlyphs = false;

                    //            if ($scope.model.glyphlist[i].length > 1) {
                    //                moreGlyphs = true;

                    //                break;
                    //            }
                    //        }
                    //    }
                    //}

                    //if (angular.isDefined($scope.listItems) && $scope.listItems.length > 0) {
                    //    var columnNo = 0;
                    //    var foundRowCol = false;

                    //    for (var k = 0; k < $scope.model.secondary.length; k++) {
                    //        for (var j = 0; j < $scope.model.secondary[k].length; j++) {
                    //            if (item === $scope.model.secondary[k][j]) {
                    //                columnNo = j;

                    //                foundRowCol = true;

                    //                break;
                    //            }
                    //        }
                    //        if (foundRowCol === true) {
                    //            break;
                    //        }
                    //    }

                    //    var fullLength = 0;
                    //    var tempLength = 0;

                    //    $scope.model.colList.sort(function (a, b) {
                    //        return parseFloat(a.priority) - parseFloat(b.priority);
                    //    });

                    //    for (var l = 0; l < $scope.model.colList.length; l++) {
                    //        if ($scope.model.colList[l].visible !== true) continue;

                    //        if ($scope.model.colList[l].colLength % 2 === 0) {
                    //            tempLength += $scope.model.colList[l].colLength;
                    //        } else {
                    //            tempLength += $scope.model.colList[l].colLength + 1;
                    //        }

                    //        $scope.model.colList[l].viewXX = false;
                    //        $scope.model.colList[l].viewXS = false;
                    //        $scope.model.colList[l].viewSM = false;
                    //        $scope.model.colList[l].viewMD = false;
                    //        $scope.model.colList[l].viewLG = false;

                    //        switch (true) {
                    //            case tempLength < 12:
                    //                $scope.model.colList[l].viewXX = true;
                    //                $scope.model.colList[l].viewXS = true;
                    //                $scope.model.colList[l].viewSM = true;
                    //                $scope.model.colList[l].viewMD = true;
                    //                $scope.model.colList[l].viewLG = true;
                    //                break;
                    //            case tempLength < 20:
                    //                $scope.model.colList[l].viewXS = true;
                    //                $scope.model.colList[l].viewSM = true;
                    //                $scope.model.colList[l].viewMD = true;
                    //                $scope.model.colList[l].viewLG = true;
                    //                break;
                    //            case tempLength < 28:
                    //                $scope.model.colList[l].viewSM = true;
                    //                $scope.model.colList[l].viewMD = true;
                    //                $scope.model.colList[l].viewLG = true;
                    //                break;
                    //            case tempLength < 45:
                    //                $scope.model.colList[l].viewMD = true;
                    //                $scope.model.colList[l].viewLG = true;
                    //                break;
                    //            case tempLength < 60:
                    //                $scope.model.colList[l].viewLG = true;
                    //                break;
                    //        }
                    //    }

                    //    $scope.model.colList.sort(function (a, b) {
                    //        return parseFloat(a.orderby) - parseFloat(b.orderby);
                    //    });

                    //    for (var m = 0; m < $scope.model.colList.length; m++) {
                    //        var checkSize = function (size) {
                    //            if ($scope.model.currentSize !== size) return;

                    //            $scope.model.colList[m].view = $scope.model.colList[m]['view' + size.toUpperCase()] || false;
                    //        };

                    //        checkSize('xx');
                    //        checkSize('xs');
                    //        checkSize('sm');
                    //        checkSize('md');
                    //        checkSize('lg');
                    //    }

                    //    if (condition === 'main') {
                    //        for (var n = 0; n < $scope.model.colList.length; n++) {
                    //            if ($scope.model.colList[n].view === true && $scope.model.colList[n].visible === true) {
                    //                if ($scope.model.colList[n].colLength % 2 === 0) {
                    //                    fullLength += $scope.model.colList[n].colLength;
                    //                } else {
                    //                    fullLength += $scope.model.colList[n].colLength + 1;
                    //                }
                    //            }
                    //        }
                    //    } else {
                    //        fullLength = $scope.model.colList[columnNo].colLength;
                    //    }

                    //}

                    //cuttingPoint2nd = findCuttingPoint(fullLength, 'cuttingPoint2nd', noGlyphs, moreGlyphs, $scope.model.colList[columnNo].view);
                    //cuttingPoint1st = findCuttingPoint(fullLength, 'cuttingPoint1st', noGlyphs, moreGlyphs, false);

                    //if (condition === 'leftGlyph') {
                    //    allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 im-no-pad';
                    //    if (noGlyphs === true && (angular.isUndefined($scope.mconfig) || $scope.mconfig.enabled === false)) {
                    //        allClass = 'col-xx-0 im-no-pad';
                    //    }
                    //    if (moreGlyphs === true || (angular.isDefined($scope.mconfig) && $scope.mconfig.enabled === true)) {
                    //        allClass = 'col-md-0-5 col-sm-0-75 col-xs-1-25 col-xx-1-5 im-no-pad';
                    //    }
                    //} else if (condition === 'rightGlyph') {
                    //    //allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';
                    //    allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon';

                    //    if (angular.isDefined($scope.typevar) && $scope.typevar.indexOf('g') > -1) {
                    //        //allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-chevron-right grayish pull-right im-chevron';
                    //        allClass += ' glyphicon-chevron-right grayish pull-right im-chevron';
                    //    } else {
                    //        allClass += ' glyphicon-none grayish pull-right';
                    //    }
                    ////} else if (condition === 'rightGlyph2') {
                    ////    allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-chevron-right grayish pull-right im-chevron-2';
                    //} else if (condition === 'nonGlyph') {
                    //    allClass = 'col-md-0-25 col-sm-0-5 col-xs-0-5 col-xx-0-75 glyphicon glyphicon-none grayish pull-right';
                    //} else if (condition === 'main') {
                    //    allClass = cuttingPoint1st + ' im-no-pad listview_text';
                    //} else if (condition === 'secondary') {
                    //    if ($scope.model.colList[columnNo].alignment === 'left') {
                    //        allClass = cuttingPoint2nd + ' im-no-pad im-l-c-l pull-right';
                    //    } else if ($scope.model.colList[columnNo].alignment === 'center') {
                    //        allClass = cuttingPoint2nd + ' im-no-pad im-l-c-c pull-right';
                    //    } else {
                    //        allClass = cuttingPoint2nd + ' im-no-pad im-l-c-r pull-right';
                    //    }
                    //}

                    return allClass;
                };

                $scope.addColItem = function (item) {
                    var allClass = '';

                    if (item === 'main') {
                        allClass = 'listview_text im-no-pad';

                        if ($scope.model.inlineEditMode) {
                            allClass += ' inline-edit-mode';
                            //if (angular.isDefined(item.editable)) {
                            //    allClass += ' inline-editable';
                            //} else if (angular.isDefined(item.item_editable)) {
                            //    allClass += ' inline-editable';
                            //}
                        }
                    } else if (angular.isDefined($scope.listItems) && $scope.listItems.length > 0) {
                        var columnNo = 0;
                        var foundRowCol = false;

                        for (var k = 0; k < $scope.model.secondary.length; k++) {
                            for (var j = 0; j < $scope.model.secondary[k].length; j++) {
                                if (item === $scope.model.secondary[k][j]) {
                                    columnNo = j;

                                    foundRowCol = true;

                                    break;
                                }
                            }
                            if (foundRowCol === true) {
                                break;
                            }
                        }

                        if ($scope.model.colList[columnNo].alignment === 'left') {
                            //allClass = 'multi-list-col-text im-no-pad im-l-c-l pull-right';
                            allClass = 'im-no-pad im-l-c-l pull-right';
                            //allClass = 'im-no-pad im-l-c-l';
                        } else if ($scope.model.colList[columnNo].alignment === 'center') {
                            //allClass = 'multi-list-col-text im-no-pad im-l-c-c pull-right';
                            allClass = 'im-no-pad im-l-c-c pull-right';
                            //allClass = 'im-no-pad im-l-c-c';
                        } else {
                            //allClass = 'multi-list-col-text im-no-pad im-l-c-r pull-right';
                            allClass = 'im-no-pad im-l-c-r pull-right';
                            //allClass = 'im-no-pad im-l-c-r';
                        }

                        if ($scope.model.inlineEditMode) {
                            allClass += ' inline-edit-mode';
                            //if ($scope.model.colList[columnNo].editable === 'true') {
                            //    allClass += ' inline-editable';
                            //}
                        }
                    }

                    return allClass;
                };

                $scope.addMenuGlyph = function (item) {
                    var allClass = ''; //default

                    if (item === 'switch') {
                        allClass = 'glyphicon glyphicon-list-alt btn btn-primary btn-sm multi-list-btn';
                    } else if (item === 'plus') {
                        allClass = 'glyphicon glyphicon-plus btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (item === 'minus') {
                        allClass = 'glyphicon glyphicon-minus btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (item === 'trash') {
                        allClass = 'glyphicon glyphicon-trash btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (item === 'sort') {
                        allClass = 'glyphicon glyphicon-sort btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (item === 'resize') {
                        allClass = 'glyphicon glyphicon-resize-horizontal btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (item === 'columns') {
                        allClass = 'fa fa-columns imp-12 btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (item === 'edit') {
                        //allClass = 'glyphicon glyphicon-edit btn btn-primary btn-sm multi-list-menu-btn pull-right';
                        allClass = 'fa fa-edit imp-12 btn btn-primary btn-sm multi-list-menu-btn pull-right';
                    } else if (angular.isDefined(item.glyph) && item.glyph !== null) {
                        var glyphStr = item.glyph;
                        if (item.glyph.startsWith('fa-')) {
                            allClass = 'im-no-mar imp-12 fa ' + glyphStr;
                        } else if (item.glyph.startsWith('fab-')) {
                            allClass = 'im-no-mar imp-12 fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                        } else if (item.glyph.startsWith('fad-')) {
                            allClass = 'im-no-mar imp-12 fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                        } else if (item.glyph.startsWith('fal-')) {
                            allClass = 'im-no-mar imp-12 fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                        } else if (item.glyph.startsWith('far-')) {
                            allClass = 'im-no-mar imp-12 far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                        } else if (item.glyph.startsWith('fas-')) {
                            allClass = 'im-no-mar imp-12 fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                        } else {
                            allClass = 'glyphicon ' + glyphStr;
                        }

                        if (item.isIcon === true) {
                            if (item.animate === true) {
                                allClass += ' fadeinout-animate btn btn-light btn-sm multi-list-menu-icon-warning pull-right no-h';
                            } else if (item.animate === false) {
                                allClass = 'glyphicon glyphicon-saved fadeout-animate btn btn-light btn-sm multi-list-menu-icon-success pull-right no-h';
                            } else {
                                allClass = 'glyphicon glyphicon-none btn btn-light btn-sm multi-list-menu-icon pull-right no-h';
                            }
                        } else {
                            if (item.canActive === true && item.isActive === true) {
                                allClass += ' btn btn-' + item.colorActive;
                            } else {
                                if (angular.isDefined(item.color)) {
                                    allClass += ' btn btn-' + item.color;
                                } else {
                                    allClass += ' btn btn-primary';
                                }
                            }

                            allClass += ' btn-sm multi-list-menu-btn pull-right';
                        }
                    }

                    return allClass;
                };

                $scope.addGlyph = function (item) {
                    var allClass = ''; //default

                    if (item === 'switch') {
                        allClass = 'glyphicon glyphicon-list-alt btn btn-primary btn-sm multi-list-btn';
                    } else if (item === 'sort') {
                        allClass = 'glyphicon glyphicon-sort';
                    } else if (angular.isDefined($scope.ltype) && $scope.ltype === 'check' && (angular.isUndefined(item.item_glyphicon) || item.item_glyphicon === null || item.item_glyphicon === '')) {
                        item.item_glyphicon = 'glyphicon-save';
                    }

                    //if (angular.isDefined($scope.ltype) && $scope.ltype === 'check' && (angular.isUndefined(item.item_glyphicon) || item.item_glyphicon === null || item.item_glyphicon === '')) {
                    //    item.item_glyphicon = 'glyphicon-save';
                    //}

                    if (angular.isDefined(item.item_glyphicon) && item.item_glyphicon !== null) {
                        var glyphStr = '';
                        if (angular.isDefined(item.item_glyphicon_2) && item.item_glyphicon_2 !== null) {
                            glyphStr = item.item_glyphicon_2;
                            if (item.item_glyphicon_2.startsWith('fa-')) {
                                allClass = 'im-no-mar fa ' + glyphStr;
                            } else if (item.item_glyphicon_2.startsWith('fab-')) {
                                allClass = 'im-no-mar fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon_2.startsWith('fad-')) {
                                allClass = 'im-no-mar fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon_2.startsWith('fal-')) {
                                allClass = 'im-no-mar fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon_2.startsWith('far-')) {
                                allClass = 'im-no-mar far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon_2.startsWith('fas-')) {
                                allClass = 'im-no-mar fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else {
                                allClass = 'glyphicon ' + glyphStr;
                            }
                        } else {
                            glyphStr = item.item_glyphicon;
                            if (item.item_glyphicon.startsWith('fa-')) {
                                allClass = 'im-no-mar fa ' + glyphStr;
                            } else if (item.item_glyphicon.startsWith('fab-')) {
                                allClass = 'im-no-mar fab ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon.startsWith('fad-')) {
                                allClass = 'im-no-mar fad ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon.startsWith('fal-')) {
                                allClass = 'im-no-mar fal ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon.startsWith('far-')) {
                                allClass = 'im-no-mar far ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else if (item.item_glyphicon.startsWith('fas-')) {
                                allClass = 'im-no-mar fas ' + glyphStr.slice(0, 2) + glyphStr.slice(3);
                            } else {
                                allClass = 'glyphicon ' + glyphStr;
                            }
                        }
                    }

                    return allClass;
                };

                $scope.addClass = function () {
                    var allClass = '';

                    allClass = 'well well-sq sort-list-container col-xs-12';

                    if (angular.isDefined($scope.model.sortlist) && $scope.model.sortlist !== null && $scope.model.sortlist.length > 0) {
                        if ($scope.model.sortlist.length === 2) {
                            allClass = 'well well-sq sort-list-container col-xs-6';
                        } else if ($scope.model.sortlist.length === 3) {
                            allClass = 'well well-sq sort-list-container col-xx-4';
                        } else if ($scope.model.sortlist.length === 4) {
                            allClass = 'well well-sq sort-list-container col-xx-6 col-xs-6 col-sm-3 col-lg-3';
                        //} else if ($scope.model.sortlist.length === 5) {
                        //    allClass = 'well well-sq sort-list-container col-xs-2-4';
                        } else if ($scope.model.sortlist.length === 6) {
                            allClass = 'well well-sq sort-list-container col-xx-4 col-xs-2';
                        } else if ($scope.model.sortlist.length === 8) {
                            allClass = 'well well-sq sort-list-container col-xx-1-5';
                        } else if ($scope.model.sortlist.length === 12) {
                            allClass = 'well well-sq sort-list-container col-xs-1';
                        } else {
                            allClass = 'well well-sq sort-list-container col-xs-12';
                        }
                    }

                    return allClass;
                };

                $scope.addThumbClass = function (item) {
                    var allClass = '';

                    if (angular.isDefined(item.item_thumb) && item.item_thumb !== null && item.item_thumb.length > 0) {

                        allClass = 'im-list-btn--flex';
                    }

                    return allClass;
                };

                $scope.addItemClass = function (item) {
                    var allClass = '';

                    allClass = 'sort-list-item btn btn-default listview btn-block btn-lg col-xs-12';

                    if (angular.isDefined(item) && item.item_type === 'info') {
                        allClass += ' not-sortable';
                    }

                    return allClass;
                };

                $scope.addStyle = function (item) {
                    var allStyle = { color: 'black', width: '19px' }; //default
                    //var allStyle = { width: '19px' }; //default

                    if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color !== null && item.item_glyphicon_color !== "black") {
                        allStyle = { color: item.item_glyphicon_color, width: '19px' };
                        if ($scope.model.initialGlyphColor === '') {
                            $scope.model.initialGlyphColor = item.item_glyphicon_color;
                        }
                    }

                    if (item === 'resize') {
                        if (angular.isUndefined($scope.model.fieldWidth) || !isNumber($scope.model.fieldWidth) || parseInt($scope.model.fieldWidth) < parseInt($scope.model.fieldWidthMin)) {
                            allStyle = { fontSize: $scope.fontSizes.textSize, width: $scope.model.fieldWidthMin + '%', minWidth: $scope.model.fieldWidthMin + '%', maxWidth: '100%', textOverflow: 'ellipsis', textAlign: 'center' };
                        //} else if (parseInt($scope.model.fieldWidth) > 100) {
                        //    allStyle = { fontSize: $scope.fontSizes.textSize, width: '100%', minWidth: $scope.model.fieldWidthMin + '%', textOverflow: 'ellipsis', textAlign: 'center' };
                        } else {
                            allStyle = { fontSize: $scope.fontSizes.textSize, width: $scope.model.fieldWidth + '%', minWidth: $scope.model.fieldWidthMin + '%', maxWidth: '100%', textOverflow: 'ellipsis', textAlign: 'center' };
                        }
                    }

                    return allStyle;
                };

                $scope.addStyleMultiListBtn = function (item) {
                    //var allStyle = { fontSize: $scope.fontSizes.textSize, display: 'inline-block', border: '1px solid #ccc' }; //default
                    var allStyle = { fontSize: $scope.fontSizes.textSize, paddingBottom: 0 }; //default

                    if (angular.isDefined(item) && angular.isDefined($scope.mconfig)) {
                        if (item.item_id === '0' && $scope.mconfig.enabled === true && $scope.mconfig.show === false) {
                            allStyle = { fontSize: $scope.fontSizes.textSize };
                        }
                    }

                    return allStyle;
                };

                $scope.addStyleCenterAreaItem = function (item, parentItem) {
                    //var allStyle = { fontSize: $scope.fontSizes.textSize, display: 'inline-block', border: '1px solid #ccc' }; //default
                    var allStyle = { fontSize: $scope.fontSizes.textSize, display: 'inline-block' }; //default

                    if (angular.isDefined(item)) {
                        if (item.item_id === '0') {
                            if (angular.isUndefined(item.item_col_width) || !isNumber(item.item_col_width) || parseInt(item.item_col_width) < parseInt($scope.model.fieldWidthMin)) {
                                allStyle = Object.assign(allStyle, { width: $scope.model.fieldWidthMin + '%', minWidth: $scope.model.fieldWidthMin + '%' });
                            } else {
                                allStyle = Object.assign(allStyle, { width: item.item_col_width + '%', minWidth: $scope.model.fieldWidthMin + '%' });
                            }

                            //allStyle = Object.assign(allStyle, { lineHeight: '1' });

                            //if ($scope.model.inlineEditMode && (angular.isDefined(item.editable) && item.editable === 'true' || angular.isDefined(item.item_editable) && item.item_editable === 'true')) {
                            //    allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue'});
                            //}
                        } else {
                            var columnNo = 0;
                            var foundRowCol = false;

                            for (var k = 0; k < $scope.model.secondary.length; k++) {
                                for (var j = 0; j < $scope.model.secondary[k].length; j++) {
                                    if (item === $scope.model.secondary[k][j]) {
                                        columnNo = j;

                                        foundRowCol = true;

                                        break;
                                    }
                                }
                                if (foundRowCol === true) {
                                    break;
                                }
                            }

                            if (foundRowCol) {
                                if (angular.isUndefined($scope.model.colList[columnNo].width) || !isNumber($scope.model.colList[columnNo].width) || parseInt($scope.model.colList[columnNo].width) < parseInt($scope.model.fieldWidthMin)) {
                                    //allStyle = Object.assign(allStyle, { width: 'calc(' + $scope.model.fieldWidthMin + '% - 5px)', minWidth: 'calc(' + $scope.model.fieldWidthMin + '% - 5px)' });
                                    allStyle = Object.assign(allStyle, { width: $scope.model.fieldWidthMin + '%', minWidth: $scope.model.fieldWidthMin + '%' });
                                } else {
                                    //allStyle = Object.assign(allStyle, { width: 'calc(' + $scope.model.colList[columnNo].width + '% - 5px)', minWidth: 'calc(' + $scope.model.fieldWidthMin + '% - 5px)' });
                                    allStyle = Object.assign(allStyle, { width: $scope.model.colList[columnNo].width + '%', minWidth: $scope.model.fieldWidthMin + '%' });
                                }

                                if (angular.isUndefined($scope.model.colList[columnNo].visible) || $scope.model.colList[columnNo].visible === false) {
                                    //allStyle = Object.assign(allStyle, { contentVisibility: 'hidden' }); //Only available from chrome 85
                                    allStyle = Object.assign(allStyle, { display: 'none' });
                                } else {
                                    //allStyle = Object.assign(allStyle, { contentVisibility: 'visible' }); //Only available from chrome 85
                                    //allStyle = Object.assign(allStyle, { display: 'inline-table' });
                                    allStyle = Object.assign(allStyle, { display: 'inline-block' });
                                }

                                //if ($scope.model.inlineEditMode && (angular.isDefined($scope.model.colList[columnNo].editable) && $scope.model.colList[columnNo].editable === 'true' || angular.isDefined($scope.model.colList[columnNo].item_editable) && $scope.model.colList[columnNo].item_editable === 'true')) {
                                //    allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                                //}

                            } else {
                                if (angular.isUndefined($scope.listItems[0].item_col_width) || !isNumber($scope.listItems[0].item_col_width) || parseInt($scope.listItems[0].item_col_width) < parseInt($scope.model.fieldWidthMin)) {
                                    //allStyle = Object.assign(allStyle, { width: 'calc(' + $scope.model.fieldWidthMin + '% - 5px)', minWidth: 'calc(' + $scope.model.fieldWidthMin + '% - 5px)' });
                                    allStyle = Object.assign(allStyle, { width: $scope.model.fieldWidthMin + '%', minWidth: $scope.model.fieldWidthMin + '%' });
                                } else {
                                    //allStyle = Object.assign(allStyle, { width: 'calc(' + $scope.listItems[0].item_col_width + '% - 5px)', minWidth: 'calc((' + $scope.model.fieldWidthMin + '%) - 5px)' });
                                    allStyle = Object.assign(allStyle, { width: $scope.listItems[0].item_col_width + '%', minWidth: $scope.model.fieldWidthMin + '%' });
                                }

                                //if ($scope.model.inlineEditMode && (angular.isDefined($scope.listItems[0].editable) && $scope.listItems[0].editable === 'true' || angular.isDefined($scope.listItems[0].item_editable) && $scope.listItems[0].item_editable === 'true')) {
                                //    allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                                //}
                            }
                        }

                        if (angular.isDefined(parentItem)) {
                            //if ($scope.model.inlineEditMode && parentItem.item_id !== '0' && parentItem.item_id !== '-1' && ((angular.isDefined(item.editable) && item.editable === 'true') || (angular.isDefined(item.item_editable) && item.item_editable === 'true'))) {
                            if ($scope.model.inlineEditMode && parentItem.item_id !== '0' && parentItem.item_id !== '-1' && angular.isDefined(item.item_editable) && item.item_editable === 'true') {
                                allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                            }
                        } else {
                            if ($scope.model.inlineEditMode && item.item_id !== '0' && item.item_id !== '-1' && angular.isDefined(item.editable) && item.editable === 'true') {
                                allStyle = Object.assign(allStyle, { backgroundColor: 'lightblue' });
                            }
                        }

                        if ($scope.model.inlineEditMode) {
                            allStyle = Object.assign(allStyle, { lineHeight: '1' });
                        }
                    }

                    return allStyle;
                };

                $scope.addStyleWell = function (item) {
                    var allStyle = { background: '#f9f9f9', color: 'black' }; //default

                    //if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color !== null && item.item_glyphicon_color !== 'black' && item.item_glyphicon_color !== '#000000') {
                    if (angular.isDefined(item) && angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color !== null) {
                        //allStyle = { background: item.item_glyphicon_color, width: '19px' };
                        allStyle = { background: item.item_glyphicon_color, color: 'white' };
                        //allStyle = { background: item.item_background_color, color: item.item_glyphicon_color, font-color: item.item_font_color };
                        if ($scope.model.initialGlyphColor === '') {
                            $scope.model.initialGlyphColor = item.item_glyphicon_color;
                        }
                    }

                    return allStyle;
                };

                $scope.addStyleSortlist = function (item) {
                    //var allStyle = { color: 'white', width: '19px' }; //default
                    var allStyle = { minWidth: '19px' }; //default

                    //if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color !== null && item.item_glyphicon_color === "white") {
                    //    allStyle = { color: item.item_glyphicon_color, width: '19px' };
                    //    if ($scope.model.initialGlyphColor === '') {
                    //        $scope.model.initialGlyphColor = item.item_glyphicon_color;
                    //    }
                    //}

                    return allStyle;
                };

                $scope.removeHover = function (item) {
                    var allClass = ''; //default

                    if (angular.isDefined(item) && angular.isDefined(item.item_id) && (item.item_id === '0' || item.item_id === '-1')) {
                        allClass = 'no-h';
                    } else if ($scope.model.inlineEditMode) {
                        allClass = 'inline-edit-mode-div';
                    }

                    return allClass;
                };

                $scope.addInputStyle = function () {
                    var allStyle = ''; //default

                    if (angular.isUndefined($scope.model.activeInlineId) || $scope.model.activeInlineId === null) return;

                    if ($scope.model.activeInlineId.indexOf('-imp-') > -1) {
                        if (angular.isUndefined($scope.model.currentInline.alignment)) return;
                        allStyle = { textAlign: $scope.model.currentInline.alignment };
                    } else if ($scope.model.activeInlineId.indexOf('-ims-') > -1) {
                        var sIdx = parseInt($scope.model.activeInlineId.slice(0, $scope.model.activeInlineId.indexOf('-ims-')));
                        if (angular.isUndefined($scope.model.currentInline.item_secondary[sIdx].alignment)) return;
                        allStyle = { textAlign: $scope.model.currentInline.item_secondary[sIdx].alignment };
                    }

                    return allStyle;
                };

                var sizeChanged = function (_, data) {
                    $scope.model.currentSize = data.current;
                };

                var dereg = $rootScope.$on('event:responsive-changed', sizeChanged);

                $scope.$on('$destroy', function () {
                    // BJS 20220724 - Jeg kan ikke helt se hvorfor denne "unbindes" her siden der ikke finnes noen
                    //                tilsvarende "bind" i directivet. Denne skaper problemer dersom denne befinner seg i ett modal
                    //                vindu som åpnes fra et annet vindu som også har binding til "click" eventen.
                    //                fjerner derfor denne.
                    //$(document).unbind('click');

                    if (angular.isFunction(dereg) === true) {
                        dereg();
                    }
                });

                $scope.thisFocus = function (item, role, index) {
                    if (angular.isUndefined(item) || item === null
                        || angular.isUndefined($scope.model.activeInlineId) || $scope.model.activeInlineId === null
                        || angular.isUndefined($scope.model.currentInline) || $scope.model.currentInline === null) return false;
                    //|| angular.isUndefined($scope.model.currentInline.item_id) || $scope.model.currentInline.item_id === null) return false;

                    if (item.item_id === $scope.model.currentInline.item_id && ($scope.model.activeInlineId.slice(0, ($scope.model.activeInlineId.indexOf('-' + role + '-') + 5)) === (index + '-' + role + '-').toString())) {
                        return true;
                    } else return false;
                };

                $scope.inputFocus = function (e) {
                    //console.dir('inputFocus event: ');
                    //console.dir(e);

                    if (angular.isUndefined($scope.model.elemInFocus) || $scope.model.elemInFocus === null) return;
                    $scope.model.elemInFocus.setSelectionRange(0, $scope.model.elemInFocus.value.length);
                };

                $scope.inputBlur = function (e) {
                    if ($scope.model.currentInline === null || $scope.model.activeInlineId === null) {
                        return;
                    }
                    $scope.model.inlineEditUpdate = true;
                    var foundItem = false;
                    let newVal = $(e.target).val();

                    for (var r = 0; r < $scope.listItems.length; r++) {
                        if ($scope.model.currentInline.item_id === $scope.listItems[r].item_id) {
                            if ($scope.model.activeInlineId.indexOf('-imp-') > -1) {
                                $scope.listItems[r].item_name = newVal;
                                foundItem = true;
                            } else if ($scope.model.activeInlineId.indexOf('-ims-') > -1) {
                                var id = $scope.model.activeInlineId.slice(0, $scope.model.activeInlineId.indexOf('-ims-'));
                                $scope.listItems[r].item_secondary[id].item_col = newVal;
                                foundItem = true;
                            }

                            if (foundItem) {
                                e.target.setAttribute('value', newVal);
                                e.target.previousSibling.innerText = newVal;
                                break;
                            }
                        }
                    }

                    //if (!foundItem) console.log('DID NOT FIND ITEM');
                    //if (foundItem) console.log('DID FIND ITEM');

                    $scope.model.elemInFocus = null;
                    $scope.model.currentInline = null;
                    $scope.model.activeInlineId = null;
                    $scope.model.isKeypress = false;
                };

                $scope.fontSizes = {
                    textSize: '',
                    textSizeS: '',
                    textSizeSs: '',
                    thumbnail: '50'
                };

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    angular.copy(info.fontSizes, $scope.fontSizes);

                    $scope.model.deleteButtonStyle.fontSize = info.fontSizes.textSizeSs;
                    $scope.model.listViewTextStyle.fontSize = info.fontSizes.textSize;
                    $scope.model.listViewTextStyle.paddingLeft = info.paddingLeft + 'px';
                    $scope.model.thumbnailStyle.width = info.fontSizes.thumbnail + 'px';
                    //$scope.model.thumbnailStyle.height = info.fontSizes.thumbnail + 'px';

                });

                var populateSortlistFromMenu = function () {
                    //console.log('populateSortlistFromMenu');
                    //if (angular.isUndefined($scope.sortItems) || $scope.sortItems === null || $scope.sortItems.length === 0) return;
                    //console.log('$scope.sortItems is defined');

                    $scope.model.sortlist.length = 0;

                    var didChange = false;

                    //angular.forEach($scope.sortItems, function (item) {
                    //    if (angular.isDefined(item) && angular.isDefined($scope.dataid) && angular.isDefined($scope.dataname) && angular.isDefined(item[$scope.dataid]) && angular.isDefined(item[$scope.dataname]) && item[$scope.dataid] !== '0') {
                    //        $scope.model.sortlist.push({ key: item[$scope.dataid], keyName: $scope.dataid, value: item[$scope.dataname], valueName: $scope.dataname, item_glyphicon: item.glyphicon, item_glyphicon_color: item.glyphicon_color, items: [] });
                    //    }
                    //});

                    $scope.model.sortlist.push({ key: '0', keyName: 'sortlist', value: null, valueName: null, item_glyphicon: null, item_glyphicon_color: null, items: [] });

                    if (angular.isUndefined($scope.listItems) || $scope.listItems === null) return;
                    //console.log('$scope.listItems is defined');

                    if (angular.isDefined($scope.listItems) && $scope.listItems !== null && angular.isDefined($scope.listItems.records) && $scope.listItems.records !== null) {
                        angular.forEach($scope.listItems.records, function (item) {
                            if (angular.isDefined(item)) {
                                for (var i = 0; i < $scope.model.sortlist.length; i++) {
                                    //if ($scope.model.sortlist[i].key === item.item_sort_id) {
                                    if ($scope.model.sortlist[i].key === '0') {
                                        $scope.model.sortlist[i].items.push(item);
                                        didChange = true;
                                        break;
                                    }
                                }
                            }
                        });
                    } else if (angular.isDefined($scope.listItems) && $scope.listItems !== null) {
                        //console.log('ELSE IF: model, and has items');
                        //console.dir($scope.model);
                        //console.dir($scope.sortItems);
                        //console.dir($scope.listItems);

                        angular.forEach($scope.listItems, function (item) {
                            if (angular.isDefined(item)) {
                                for (var i = 0; i < $scope.model.sortlist.length; i++) {
                                    //if ($scope.model.sortlist[i].key === item.item_sort_id) {
                                    if ($scope.model.sortlist[i].key === '0') {
                                        $scope.model.sortlist[i].items.push(item);
                                        didChange = true;
                                        break;
                                    }
                                }
                            }
                        });
                    }

                    if (didChange) {
                        //console.log('populateSortlist and the items');
                        //console.dir($scope.sortItems);
                        //console.dir($scope.listItems);
                        //console.dir($scope.model.sortlist);

                        //$("#" + $scope.model.mId).sortable();
                        //$("#" + $scope.model.mId).disableSelection();
                    }
                };

                var populateSortlist = function () {
                    //console.log('populateSortlist');
                    if (angular.isUndefined($scope.sortItems) || $scope.sortItems === null || $scope.sortItems.length === 0) return;
                    //console.log('$scope.sortItems is defined');

                    $scope.model.sortlist.length = 0;

                    var didChange = false;

                    angular.forEach($scope.sortItems, function (item) {
                        if (angular.isDefined(item) && angular.isDefined($scope.dataid) && angular.isDefined($scope.dataname) && angular.isDefined(item[$scope.dataid]) && angular.isDefined(item[$scope.dataname]) && item[$scope.dataid] !== '0') {
                            $scope.model.sortlist.push({ key: item[$scope.dataid], keyName: $scope.dataid, value: item[$scope.dataname], valueName: $scope.dataname, item_glyphicon: item.glyphicon, item_glyphicon_color: item.glyphicon_color, items: [] });
                        }
                    });

                    if (angular.isUndefined($scope.listItems) || $scope.listItems === null) return;
                    //console.log('$scope.listItems is defined');

                    if (angular.isDefined($scope.listItems) && $scope.listItems !== null && angular.isDefined($scope.listItems.records) && $scope.listItems.records !== null) {
                        angular.forEach($scope.listItems.records, function (item) {
                            if (angular.isDefined(item)) {
                                for (var i = 0; i < $scope.model.sortlist.length; i++) {
                                    if ($scope.model.sortlist[i].key === item.item_sort_id) {
                                        $scope.model.sortlist[i].items.push(item);
                                        didChange = true;
                                        break;
                                    }
                                }
                            }
                        });
                    } else if (angular.isDefined($scope.listItems) && $scope.listItems !== null) {
                        //console.log('ELSE IF: model, and has items');
                        //console.dir($scope.model);
                        //console.dir($scope.sortItems);
                        //console.dir($scope.listItems);

                        angular.forEach($scope.listItems, function (item) {
                            if (angular.isDefined(item)) {
                                for (var i = 0; i < $scope.model.sortlist.length; i++) {
                                    if ($scope.model.sortlist[i].key === item.item_sort_id) {
                                        $scope.model.sortlist[i].items.push(item);
                                        didChange = true;
                                        break;
                                    }
                                }
                            }
                        });
                    }

                    if (didChange) {
                        //console.log('populateSortlist and the items');
                        //console.dir($scope.sortItems);
                        //console.dir($scope.listItems);
                        //console.dir($scope.model.sortlist);

                        //$("#" + $scope.model.mId).sortable();
                        //$("#" + $scope.model.mId).disableSelection();
                    }
                };

                // ###############
                // FUNCTIONS START
                // ###############

                function isNumber(n) {
                    return !isNaN(parseFloat(n)) && isFinite(n);
                }

                function isNullOrWhitespace(input) {
                    if (typeof input === 'undefined' || input === null) return true;

                    //return input.replace(/\s/g, '').length < 1;
                    return input.trim().length < 1;
                }

                function escapeRegExp(str) {
                    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                }

                function gridClick(child, gridMode) {
                    console.log('gridClick');
                    //console.dir(child);
                    $scope.model.gridClicked = false;
                    if (angular.isUndefined(child) || child === null) return;
                    var id = angular.isDefined(child.id) ? child.id : '';
                    if (id === '') {
                        if (angular.isUndefined(child.parentElement) || child.parentElement === null) return;
                        id = angular.isDefined(child.parentElement.id) ? child.parentElement.id : '';
                    }

                    if (id === '' || id === $scope.model.activeInlineId) return;

                    var sIdx = null;

                    if (gridMode === 'edit') {
                        if (id.indexOf('-imp-') > -1) {
                            if ($scope.model.currentInline.editable === 'false') return;
                        } else if (id.indexOf('-ims-') > -1) {
                            sIdx = parseInt(id.slice(0, id.indexOf('-ims-')));
                            if ($scope.model.currentInline.item_secondary[sIdx].item_editable === 'false') return;
                        }

                        $scope.model.activeInlineId = id;
                        contentEditable(child, id);
                    } else if (gridMode === 'item_func') {
                        $scope.model.gridClicked = true;
                        //console.log('item_func');
                        //console.dir(child);
                        //console.log(id);
                        if (id.indexOf('-imp-') > -1) {
                            //console.log('the item');
                            //console.log($scope.model.currentInline);
                            $scope.optionfunc({ item: { item: $scope.model.currentInline, col: null } });
                        } else if (id.indexOf('-ims-') > -1) {
                            sIdx = parseInt(id.slice(0, id.indexOf('-ims-')));
                            //console.log('the item');
                            //console.log($scope.model.currentInline.item_secondary[sIdx]);
                            $scope.optionfunc({ item: { item: $scope.model.currentInline, col: sIdx } });
                        }
                    } else {
                        console.log('gridMode not set');
                    }
                }

                function innerChild(child) {
                    var lastChild = null;

                    if (child.children.length > 1 && child.children[1].nodeName === 'INPUT') {
                        lastChild = innerChild(child.children[1]);
                    } else if (child.children.length > 0 && child.children[0].nodeName !== 'INPUT') {
                        lastChild = innerChild(child.children[0]);
                    } else {
                        lastChild = child;
                    }

                    return lastChild;
                }

                function shiftRowChild(child, rIdx, cIdx) {
                    var lastChild = null;

                    if (angular.isUndefined(child) || angular.isUndefined(child.offsetParent)) {
                        return lastChild;
                    } else if (child.className === 'col-xs-12' && child.nodeName === 'DIV' && child.children.length > 1) {
                        if (cIdx !== 0) cIdx = $scope.model.currentInline.item_secondary.length + 1 - cIdx;

                        lastChild = innerChild(child.children[rIdx].children[0].children[0].children[1].children[cIdx]);
                    } else {
                        if (child.offsetParent === null) {
                            lastChild = shiftRowChild(child.parentElement.offsetParent, rIdx, cIdx);
                        } else {
                            lastChild = shiftRowChild(child.offsetParent, rIdx, cIdx);
                        }
                    }

                    return lastChild;
                }

                function findClosestChild(target, point) {
                    var children = target.children;
                    var xPoint = point;
                    var closestChild = 0;
                    var foundClosest = false;

                    if (userService.sidemenuOpened === true) {
                        xPoint -= 180;
                    }

                    for (var c = 0; c < children.length; c++) {
                        //console.log(children[c].offsetLeft, ' <-- ', xPoint, ' --> ', children[c].offsetLeft + children[c].offsetWidth);
                        if (xPoint >= (children[c].offsetLeft) && xPoint <= (children[c].offsetLeft + children[c].offsetWidth)) {
                            closestChild = c;
                            foundClosest = true;
                            break;
                        }
                    }

                    //console.dir($scope.model.currentInline);

                    if (!foundClosest) {
                        console.log('didnt find closest');
                        return null;
                    } else {
                        //console.log('closest child is: ' + closestChild);

                        var lastChild = null;

                        lastChild = innerChild(children[closestChild]);
                        return lastChild;
                    }
                }

                function inlineEditClick(child, editmode) {
                    //console.log('inlineEditClick');
                    //console.dir(child);
                    if (angular.isUndefined(child) || child === null) return;
                    var id = angular.isDefined(child.id) ? child.id : '';
                    if (id === '') {
                        if (angular.isUndefined(child.parentElement) || child.parentElement === null) return;
                        id = angular.isDefined(child.parentElement.id) ? child.parentElement.id : '';
                    }

                    if (id === '' || id === $scope.model.activeInlineId) return;

                    if (id.indexOf('-imp-') > -1) {
                        if ($scope.model.currentInline.editable === 'false') return;
                    } else if (id.indexOf('-ims-') > -1) {
                        var sIdx = parseInt(id.slice(0, id.indexOf('-imp-')));
                        if ($scope.model.currentInline.item_secondary[sIdx].item_editable === 'false') return;
                    }

                    $scope.model.activeInlineId = id;
                    contentEditable(child, id);
                }

                function contentEditable(child, id) {
                    //var elem = $element.find('#' + id)[0];
                    var elem = angular.element('#' + id)[0];
                    $scope.model.elemInFocus = elem;
                    //console.dir(elem);
                    $timeout(function () {
                        elem.focus();
                    }, 10);

                    $element.find('#' + id).on('keydown', function (e) {
                        if ($scope.model.prosessingKeypress) return;
                        if ($scope.model.currentInline === null || $scope.model.activeInlineId === null) return;

                        //if (e.keyCode !== 16 && e.keyCode !== 18) console.dir(e);

                        var nextEditable = null;
                        var rIdx = null;
                        var cIdx = null;
                        var nIdx = null;
                        var nChild = null;
                        var gridMode = 'edit';
                        if (e.keyCode === 27) {
                            $scope.model.prosessingKeypress = true;
                            e.preventDefault();
                            //console.log('escape pressed');
                            $scope.model.isKeypress = true;
                            $scope.model.gridDirection = 'none';
                            $(this).blur();
                            //inputBlur($(this));
                            $scope.model.currentInline = null;
                            $scope.model.activeInlineId = null;
                            $scope.model.prosessingKeypress = false;
                        } else if ((e.shiftKey && e.keyCode === 9) || (e.shiftKey && e.keyCode === 13) || (e.altKey && e.keyCode === 37)) {
                            $scope.model.prosessingKeypress = true;
                            e.preventDefault();
                            //console.log('shift tab pressed');

                            nextEditable = findNextEditable($scope.model.currentInline, $scope.model.activeInlineId, 'left');
                            rIdx = nextEditable.row;
                            cIdx = nextEditable.col;

                            if (nextEditable.row === null || nextEditable.col === null) {
                                $scope.model.prosessingKeypress = false;
                                return;
                            }

                            nIdx = cIdx;
                            nChild = shiftRowChild(child, rIdx, nIdx);

                            $scope.model.isKeypress = true;
                            $scope.model.gridDirection = 'left';
                            $(this).blur();
                            $scope.model.currentInline = $scope.listItems[rIdx];
                            $timeout(function () {
                                //inlineEditClick(nChild);
                                gridClick(nChild, gridMode);
                                $scope.model.prosessingKeypress = false;
                            }, 10);
                        } else if (e.altKey && e.keyCode === 38) {
                            $scope.model.prosessingKeypress = true;
                            e.preventDefault();
                            //console.log('up pressed');

                            nextEditable = findNextEditable($scope.model.currentInline, $scope.model.activeInlineId, 'up');
                            rIdx = nextEditable.row;
                            cIdx = nextEditable.col;

                            if (nextEditable.row === null || nextEditable.col === null) {
                                $scope.model.prosessingKeypress = false;
                                return;
                            }

                            nIdx = cIdx;
                            nChild = shiftRowChild(child, rIdx, nIdx);

                            if (nChild === null) {
                                $scope.model.prosessingKeypress = false;
                                return;
                            }

                            $scope.model.isKeypress = true;
                            $scope.model.gridDirection = 'up';
                            $(this).blur();
                            $scope.model.currentInline = $scope.listItems[rIdx];
                            $timeout(function () {
                                //inlineEditClick(nChild);
                                gridClick(nChild, gridMode);
                                $scope.model.prosessingKeypress = false;
                            }, 10);
                        } else if ((e.keyCode === 9 || e.keyCode === 13) || (e.altKey && e.keyCode === 39)) {
                            $scope.model.prosessingKeypress = true;
                            e.preventDefault();
                            //console.log('tab pressed');

                            nextEditable = findNextEditable($scope.model.currentInline, $scope.model.activeInlineId, 'right');
                            rIdx = nextEditable.row;
                            cIdx = nextEditable.col;

                            if (nextEditable.row === null || nextEditable.col === null) {
                                $scope.model.prosessingKeypress = false;
                                return;
                            }

                            nIdx = cIdx;
                            nChild = shiftRowChild(child, rIdx, nIdx);

                            $scope.model.isKeypress = true;
                            $scope.model.gridDirection = 'right'
                            $(this).blur();
                            $scope.model.currentInline = $scope.listItems[rIdx];
                            $timeout(function () {
                                //inlineEditClick(nChild);
                                gridClick(nChild, gridMode);
                                $scope.model.prosessingKeypress = false;
                            }, 10);
                        } else if (e.altKey && e.keyCode === 40) {
                            $scope.model.prosessingKeypress = true;
                            e.preventDefault();
                            //console.log('down pressed');

                            nextEditable = findNextEditable($scope.model.currentInline, $scope.model.activeInlineId, 'down');
                            rIdx = nextEditable.row;
                            cIdx = nextEditable.col;

                            if (nextEditable.row === null || nextEditable.col === null) {
                                $scope.model.prosessingKeypress = false;
                                return;
                            }

                            nIdx = cIdx;
                            nChild = shiftRowChild(child, rIdx, nIdx);

                            if (nChild === null) {
                                $scope.model.prosessingKeypress = false;
                                return;
                            }

                            $scope.model.isKeypress = true;
                            $scope.model.gridDirection = 'down';
                            $(this).blur();
                            $scope.model.currentInline = $scope.listItems[rIdx];
                            $timeout(function () {
                                //inlineEditClick(nChild);
                                gridClick(nChild, gridMode);
                                $scope.model.prosessingKeypress = false;
                            }, 10);
                        }
                    });
                }

                function findNextEditable(currentItem, activeId, direction) {
                    //console.log('findNextEditable');

                    var isPrime = null;
                    var row = null;
                    var col = null;

                    for (var r = 0; r < $scope.listItems.length; r++) {
                        if (currentItem.item_id === $scope.listItems[r].item_id) {
                            row = r;
                            break;
                        }
                    }

                    if (activeId.indexOf('-imp-') > -1) {
                        col = 0;
                        isPrime = true;
                    } else if (activeId.indexOf('-ims-') > -1) {
                        col = parseInt(activeId.slice(0, activeId.indexOf('-ims-'))) + 1;
                        isPrime = false;
                    }

                    if (isPrime === null) return;

                    //console.log('click location (row,col): ' + row + ' : ' + col);

                    var numCols = $scope.listItems[0].item_secondary.length;
                    var rowAdjustment = 0;
                    var nextRow = null;
                    var nextCol = null;

                    switch (direction) {
                        case 'left':
                            col--;
                            if (angular.isDefined($scope.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined($scope.listItems[row + rowAdjustment]) || $scope.listItems[row + rowAdjustment].item_id === '0' || $scope.listItems[row + rowAdjustment].item_id === '-1') {
                                        console.log('left out of bounds');
                                        col = -2;
                                    } else if (col < 0) {
                                        col = numCols;
                                        rowAdjustment--;
                                    } else {
                                        if ((col === 0 && $scope.listItems[row + rowAdjustment].editable === 'true') || (col !== 0 && $scope.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true')) {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            col = -2;
                                        } else {
                                            col--;
                                        }
                                    }
                                } while (col >= -1);
                            } else {
                                console.log('left out of bounds');
                            }
                            break;
                        case 'up':
                            rowAdjustment--;
                            if (angular.isDefined($scope.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined($scope.listItems[row + rowAdjustment])) {
                                        console.log('up out of bounds');
                                        row = -1;
                                    } else if (col === 0) {
                                        if ($scope.listItems[row + rowAdjustment].item_id !== '0' && $scope.listItems[row + rowAdjustment].item_id !== '-1' && $scope.listItems[row + rowAdjustment].editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment--;
                                        }
                                    } else {
                                        if ($scope.listItems[row + rowAdjustment].item_id !== '0' && $scope.listItems[row + rowAdjustment].item_id !== '-1' && $scope.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment--;
                                        }
                                    }
                                } while (row >= 0);
                            } else {
                                console.log('up out of bounds');
                            }
                            break;
                        case 'right':
                            col++;
                            if (angular.isDefined($scope.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined($scope.listItems[row + rowAdjustment])) {
                                        console.log('right out of bounds');
                                        col = -2;
                                    } else if (col > numCols) {
                                        col = 0;
                                        rowAdjustment++;
                                    } else {
                                        if ((col === 0 && $scope.listItems[row + rowAdjustment].editable === 'true') || (col !== 0 && $scope.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true')) {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            col = -2;
                                        } else {
                                            col++;
                                        }
                                    }
                                } while (col >= -1);
                            } else {
                                console.log('right out of bounds');
                            }
                            break;
                        case 'down':
                            rowAdjustment++;
                            if (angular.isDefined($scope.listItems[row + rowAdjustment])) {
                                do {
                                    if (angular.isUndefined($scope.listItems[row + rowAdjustment])) {
                                        console.log('up out of bounds');
                                        row = -1;
                                    } else if (col === 0) {
                                        if ($scope.listItems[row + rowAdjustment].item_id !== '0' && $scope.listItems[row + rowAdjustment].item_id !== '-1' && $scope.listItems[row + rowAdjustment].editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment++;
                                        }
                                    } else {
                                        if ($scope.listItems[row + rowAdjustment].item_id !== '0' && $scope.listItems[row + rowAdjustment].item_id !== '-1' && $scope.listItems[row + rowAdjustment].item_secondary[col - 1].item_editable === 'true') {
                                            nextRow = row + rowAdjustment;
                                            nextCol = col;
                                            row = -1;
                                        } else {
                                            rowAdjustment++;
                                        }
                                    }
                                } while (row >= 0);
                            } else {
                                console.log('down out of bounds');
                            }
                    }

                    //console.log('next location (row,col): ' + nextRow + ' : ' + nextCol);
                    return { row: nextRow, col: nextCol };
                }

                function validatingEditable(list) {
                    //console.log('validatingEditable');
                    //console.dir(list);
                    if (angular.isUndefined(list) || list === null) return;

                    var headerItemIndex = null;

                    for (var i = 0; i < list.length; i++) {
                        if (list[i].item_id === '0') {
                            headerItemIndex = i;
                            break;
                        }
                    }

                    if (headerItemIndex === null) {
                        angular.forEach(list, function (item) {
                            if (angular.isDefined(item) && typeof item !== 'string' ) {
                                item.editable = 'false';

                                if (angular.isDefined(item.item_secondary)) {
                                    for (var s = 0; s < item.item_secondary.length; s++) {
                                        item.item_secondary[s].item_editable = 'false';
                                    }
                                }
                            }
                        });
                    } else {
                        angular.forEach(list, function (item) {
                            if (angular.isDefined(item) && item.item_id !== '0') {
                                if (item.editable === '') {
                                    item.editable = list[headerItemIndex].editable;
                                }

                                if (angular.isDefined(item.item_secondary)) {
                                    for (var s = 0; s < item.item_secondary.length; s++) {
                                        if (item.item_secondary[s].item_editable === '') {
                                            item.item_secondary[s].item_editable = list[headerItemIndex].item_secondary[s].item_editable;
                                        }
                                    }
                                }
                            }
                        });
                    }

                    $scope.model.editableValidated = true;
                }

                function checkModes() {
                    //console.log('checkModes');
                    if (angular.isUndefined($scope.mconfig) || $scope.mconfig.show !== true || angular.isUndefined($scope.mconfig.records)) return;
                    //console.log('checkModes 2');
                    for (var r = 0; r < $scope.mconfig.records.length; r++) {
                        if ((angular.isUndefined($scope.mconfig.records[r].isIcon) || $scope.mconfig.records[r].isIcon === false)
                            && (angular.isDefined($scope.mconfig.records[r].show) && $scope.mconfig.records[r].show === true)
                            && (angular.isDefined($scope.mconfig.records[r].enabled) && $scope.mconfig.records[r].enabled === true)
                            && (angular.isDefined($scope.mconfig.records[r].canActive) && $scope.mconfig.records[r].canActive === true)) {
                            //console.dir($scope.mconfig.records[r]);
                            if (angular.isDefined($scope.mconfig.records[r].isActive) && $scope.mconfig.records[r].isActive === true) {
                                $scope.mconfig.activate($scope.mconfig.records[r]);
                                //console.dir($scope.mconfig.records[r]);
                                switch ($scope.mconfig.records[r].item_name) {
                                    case 'option':
                                        break;
                                    case 'sort':
                                        $scope.model.sortMode = true;
                                        break;
                                    case 'resize':
                                        $scope.model.resizeMode = true;
                                        $scope.slider.functions.setStarts();
                                        $scope.slider.functions.setConnections();
                                        $scope.slider.functions.setTooltips();
                                        break;
                                    case 'edit':
                                        $scope.model.inlineEditMode = true;
                                        break;
                                }
                                $scope.mconfig.function($scope.mconfig.records[r]);
                            }
                        }
                    }
                };

                // ###############
                // FUNCTIONS END
                // ###############

                var groupTimer = null;

                $scope.$watchGroup(['listItems', 'sortItems'], function (newValues, oldValues, scope) {

                    if (angular.isUndefined($scope.sortItems) || angular.isUndefined($scope.listItems) || $scope.sortItems === null || $scope.listItems === null) return;

                    //console.log('WatchGroup');
                    //console.dir(newValues);
                    //console.dir(oldValues);
                    //console.dir(scope);

                    var cancelCount = 0;

                    if (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1]) {

                        var wait = function () {
                            if (angular.isDefined(newValues[0].records) && newValues[0].records.length > 0 && newValues[1].length > 0 || cancelCount > 50) {
                                populateSortlist();
                                return;
                            } else if (newValues[0].length > 0 && newValues[1].length > 0 || cancelCount > 50) {
                                populateSortlist();
                                return;
                            }

                            if (angular.isDefined(groupTimer)) {
                                cancelCount++;
                                $timeout.cancel(groupTimer);
                            }

                            groupTimer = $timeout(wait, 100);
                        };

                        wait();
                    }
                });

                $scope.$watch(function () { return $scope.mconfig; }, function () {
                    if (angular.isDefined($scope.mconfig) && $scope.mconfig.records.length > 0)
                        checkModes();
                });

                $scope.$watch(function () { return $scope.ltype; }, function (newValue) {
                    if (angular.isDefined($scope.ltype) && $scope.ltype !== null && $scope.ltype !== '')
                        $scope.model.ltype = newValue;
                });

                $scope.$watch(function () { return $scope.ptypevar; }, function () {
                    if (angular.isUndefined($scope.typevar) && angular.isDefined($scope.ptypevar))
                        $scope.typevar = $scope.ptypevar.toString();
                });

                $scope.$watch(function () { return $scope.filterVal; }, function (newValue) {
                    if (angular.isDefined($scope.filterVal) && $scope.filterVal !== null && $scope.filterVal !== '')
                        $scope.model.filterVal = newValue;
                    else
                        $scope.model.filterVal = '';
                });

                $scope.$watch(function () { return $scope.listItems; }, function (newValue, oldValue) {
                    // BJS 20210806
                    if (angular.isFunction($scope.ttChange)) {
                        if (angular.equals(oldValue, newValue) !== true) {
                            // BJS 20220727 - Added oldValue
                            $scope.ttChange({ $value: newValue, $oldValue: oldValue });
                        }
                    }

                    if ($scope.model.inlineEditUpdate) {
                        $scope.model.inlineEditUpdate = false;
                        return;
                    }
                    if ($scope.model.validatingEditable) return;
                    if ($scope.model.inlineEditMode) {
                        $scope.model.editableValidated = false;
                    }
                    //if ($scope.model.editableValidated && $scope.model.inlineEditMode) return;
                    if ($scope.model.colWidthChanging) return;
                    if ($scope.model.slidersChanging) return;
                    if (angular.isDefined($scope.listItems) && $scope.listItems.length > 0) {
                        var i = 0;
                        var j = 0;
                        
                        $scope.model.glyphlist.length = 0;
                        $scope.model.glyphlist2.length = 0;
                        $scope.model.secondary.length = 0;

                        if (!$scope.model.editableValidated && angular.isDefined($scope.listItems) && $scope.listItems.length > 0) {
                            $scope.model.validatingEditable = true;
                            validatingEditable($scope.listItems);
                            $scope.model.validatingEditable = false;
                        }
                        function isBlack(color) {
                            return color === 'black' || color === '#000000';
                        }
                        $scope.listItems.forEach(function (item, key) {
                            if (angular.isUndefined(item)) return;

                            var c = 0;
                            
                            if (angular.isDefined(item.item_glyphicon) && item.item_glyphicon !== null && item.item_glyphicon.length > 0) {
                                $scope.model.glyphlist.push([]);

                                var tmplist1 = item.item_glyphicon.split(' ');
                                var tmplistColor1 = angular.isDefined(item.item_glyphicon_color) ? item.item_glyphicon_color.split(' ') : $scope.model.initialGlyphColor.split(' ');

                                tmplist1.forEach(function (gitem) {
                                    $scope.model.glyphlist[i].push({ item_glyphicon: gitem, item_glyphicon_color: isBlack(tmplistColor1[c]) ? 'var(--tt-text-color)' : tmplistColor1[c], item_parent_id: item.item_id });

                                    if (c < tmplistColor1.length - 1) {
                                        c++;
                                    }
                                });

                                i++;
                            }

                            if (angular.isDefined(item.item_glyphicon_2)) {
                                $scope.model.glyphlist2.push([]);

                                var tmplist2 = item.item_glyphicon_2.split(' ');
                                var tmplistColor2 = angular.isDefined(item.item_glyphicon_2_color) ? item.item_glyphicon_2_color.split(' ') : angular.isDefined(item.item_glyphicon_color) ? item.item_glyphicon_color.split(' ') : $scope.model.initialGlyphColor.split(' ');
                                
                                c = 0;

                                tmplist2.forEach(function (gitem) {
                                    $scope.model.glyphlist2[j].push({ item_glyphicon: gitem, item_glyphicon_color: isBlack(tmplistColor2[c]) ? 'var(--tt-text-color)' : tmplistColor2[c], item_parent_id: item.item_id });

                                    if (c < tmplistColor2.length - 1) {
                                        c++;
                                    }
                                });

                                j++;
                            }

                            if (angular.isDefined(item.item_secondary)) {
                                if ($scope.listItems[key].item_secondary.length > $scope.model.numCol) {
                                    $scope.model.numCol = $scope.listItems[key].item_secondary.length;
                                }

                                angular.forEach(item.item_secondary, function (data) {
                                    if (angular.isDefined(data)) {
                                        if ($scope.model.secondary.length <= key) {
                                            $scope.model.secondary.push([]);
                                        }
                                        $scope.model.secondary[key].push(data);
                                        $scope.model.secondary[key].parent_id = item.item_id;
                                    }
                                });
                            }
                        });
                        //$scope.model.hasItems.items = true;
                    }

                    $scope.model.colList.length = 0;
                    
                    for (var o = 0; o < $scope.model.numCol; o++) {
                        var longest = 0;
                        var colVis = true;
                        var colPrio = o;
                        var colOrder = o;
                        var colAlign = 'right';
                        var colWidth = 0;
                        var colEditable = 'false';

                        for (var p = 0; p < $scope.model.secondary.length; p++) {
                            if (angular.isDefined($scope.model.secondary[p]) && angular.isDefined($scope.model.secondary[p][o])) {
                                if ($scope.model.secondary[p][o].item_col.length > longest) {
                                    longest = $scope.model.secondary[p][o].item_col.length;
                                }
                            }
                        }

                        if (angular.isDefined($scope.model.secondary[0]) && angular.isDefined($scope.model.secondary[0][o]) && angular.isDefined($scope.model.secondary[0][o].alignment)) {
                            colAlign = $scope.model.secondary[0][o].alignment;
                        }
                        if (angular.isDefined($scope.model.secondary[0]) && angular.isDefined($scope.model.secondary[0][o]) && angular.isDefined($scope.model.secondary[0][o].visible)) {
                            colVis = $scope.model.secondary[0][o].visible;
                        }
                        if (angular.isDefined($scope.model.secondary[0]) && angular.isDefined($scope.model.secondary[0][o]) && angular.isDefined($scope.model.secondary[0][o].priority)) {
                            colPrio = $scope.model.secondary[0][o].priority;
                        }
                        if (angular.isDefined($scope.model.secondary[0]) && angular.isDefined($scope.model.secondary[0][o]) && angular.isDefined($scope.model.secondary[0][o].orderby)) {
                            colOrder = $scope.model.secondary[0][o].orderby;
                        }
                        if (angular.isDefined($scope.model.secondary[0]) && angular.isDefined($scope.model.secondary[0][o]) && angular.isDefined($scope.model.secondary[0][o].item_editable)) {
                            colEditable = $scope.model.secondary[0][o].item_editable;
                        }
                        if (angular.isDefined($scope.model.secondary[0]) && angular.isDefined($scope.model.secondary[0][o]) && angular.isDefined($scope.model.secondary[0][o].item_col_width)) {
                            if (isNumber($scope.model.secondary[0][o].item_col_width))
                                colWidth = parseFloat($scope.model.secondary[0][o].item_col_width);
                        }

                        $scope.model.colList.push({ colId: o, colLength: longest, width: colWidth, alignment: colAlign, orderby: colOrder, editable: colEditable, priority: colPrio, view: false, viewXX: false, viewXS: false, viewSM: false, viewMD: false, viewLG: false, visible: colVis });
                    }

                    if ($scope.model.colList.length > 0 && angular.isDefined($scope.listItems[0])) {
                        var colCount = $scope.model.colList.length;
                        var visibleColCount = 0;
                        var totalLength = 0;
                        var allWidthsUndefined = true;

                        for (var col = 0; col < colCount; col++) {
                            if (col === 0 && angular.isDefined($scope.listItems[0].item_col_width) && isNumber($scope.listItems[0].item_col_width)) {
                                allWidthsUndefined = false;
                                break;
                            } else if (angular.isDefined($scope.listItems[0].item_secondary[col].item_col_width) && isNumber($scope.listItems[0].item_secondary[col].item_col_width)) {
                                allWidthsUndefined = false;
                                break;
                            }
                        }

                        $scope.model.slidersChanging = true;

                        if (allWidthsUndefined) {
                            var allWidths = [];
                            var longestItemName = 0;
                            for (var m = 0; m < $scope.listItems.length; m++) {
                                if (angular.isDefined($scope.listItems[m])) {
                                    if ($scope.listItems[m].item_name.length > longest) {
                                        longestItemName = $scope.listItems[m].item_name.length;
                                    }
                                }
                            }
                            allWidths.push(longestItemName);
                            for (col = 0; col < colCount; col++) {
                                allWidths.push($scope.model.colList[col].colLength);
                            }

                            var tempFullLength = 0;
                            var flooredLength = 0;
                            var awla = 0;
                            var awlb = 0;
                            var awlf = 0;
                            var minLength = 0;

                            switch ($scope.model.currentSize) {
                                case 'xx':
                                case 'xs':
                                    minLength = 0;
                                    break;
                                case 'sm':
                                    minLength = 3;
                                    break;
                                case 'md':
                                case 'lg':
                                    minLength = 6;
                            }

                            for (col = 0; col < allWidths.length; col++) {
                                tempFullLength += allWidths[col];
                            }

                            for (col = 0; col < allWidths.length; col++) {
                                if (col === 0) {
                                    awla = (allWidths[col] / tempFullLength) * 100;
                                    awlf = Math.floor(awla);
                                    $scope.listItems[0].item_col_width = awlf;
                                    flooredLength += awla - awlf;
                                } else {
                                    awla = (allWidths[col] / tempFullLength) * 100;
                                    awlb = ((allWidths[col] - minLength) / tempFullLength) * 100;
                                    if (awlb < $scope.model.sliderMinDefault) awlb = $scope.model.sliderMinDefault;
                                    awlf = Math.floor(awlb);
                                    $scope.listItems[0].item_secondary[col - 1].item_col_width = awlf;
                                    flooredLength += awla - awlf;
                                }
                            }

                            $scope.listItems[0].item_col_width += Math.floor(flooredLength);
                        }

                        for (col = 0; col < colCount; col++) {
                            if (col === 0) {
                                visibleColCount++;
                                if (angular.isDefined($scope.listItems[0].item_col_width) && isNumber($scope.listItems[0].item_col_width)) {
                                    if (parseFloat($scope.listItems[0].item_col_width) > 100) {
                                        $scope.listItems[0].item_col_width = 100;
                                    }
                                    totalLength += parseFloat($scope.listItems[0].item_col_width);
                                } else {
                                    $scope.listItems[0].item_col_width = $scope.slider.min;
                                    totalLength += $scope.slider.min;
                                }

                                if (totalLength >= 100) {
                                    break;
                                }
                            }

                            if ($scope.model.colList[col].visible === true) {
                                visibleColCount++;
                                if ($scope.model.colList[col].width > 100) {
                                    $scope.model.colList[col].width = 100;
                                } else if ($scope.model.colList[col].width < $scope.slider.min) {
                                    $scope.model.colList[col].width = $scope.slider.min;
                                }
                                if ($scope.model.colList[col].width + totalLength > 100) {
                                    var canShrink = false;
                                    var needShrink = false;
                                    var totalShrinkage = 0;
                                    do {
                                        canShrink = false;
                                        needShrink = false;
                                        if ($scope.model.colList[col].width > $scope.slider.min) {
                                            $scope.model.colList[col].width -= $scope.slider.options.step;
                                            totalShrinkage += $scope.slider.options.step;
                                            if ($scope.model.colList[col].width + totalLength - totalShrinkage > 100) {
                                                needShrink = true;
                                            }
                                            if (needShrink && $scope.model.colList[col].width > $scope.slider.min) {
                                                canShrink = true;
                                            }
                                        }
                                    } while (needShrink && canShrink);

                                    totalLength += $scope.model.colList[col].width - totalShrinkage;
                                } else {
                                    totalLength += $scope.model.colList[col].width;
                                }
                            }
                        }

                        if (totalLength + $scope.slider.options.step < 100) {
                            var remainderLength = 100 - totalLength;
                            var colLengthAdjustment = Math.floor(remainderLength / visibleColCount);
                            var additionalAdjustments = (remainderLength - (colLengthAdjustment * visibleColCount)) / $scope.slider.options.step;

                            for (col = 0; col < colCount; col++) {
                                if (col === 0) {
                                    $scope.listItems[0].item_col_width = parseFloat($scope.listItems[0].item_col_width) + colLengthAdjustment;
                                    totalLength += colLengthAdjustment;
                                }

                                if ($scope.model.colList[col].visible === true) {
                                    $scope.model.colList[col].width += colLengthAdjustment;
                                    totalLength += colLengthAdjustment;
                                }
                            }

                            while (additionalAdjustments > 0) {
                                for (col = colCount; col >= 0; col--) {
                                    if (col === 0) {
                                        $scope.listItems[0].item_col_width = parseFloat($scope.listItems[0].item_col_width) + $scope.slider.options.step;
                                        totalLength += $scope.slider.options.step;
                                        additionalAdjustments--;
                                    } else if ($scope.model.colList[col - 1].visible === true) {
                                        $scope.model.colList[col - 1].width += $scope.slider.options.step;
                                        totalLength += $scope.slider.options.step;
                                        additionalAdjustments--;
                                    }

                                    if (additionalAdjustments <= 0) break;
                                }
                            }

                            for (col = 0; col < colCount; col++) {
                                $scope.listItems[0].item_secondary[col].item_col_width = $scope.model.colList[col].width;
                            }
                        }

                        $scope.model.slidersChanging = false;
                    }

                    var columnsEqualized = false;

                    do {
                        columnsEqualized = true;

                        angular.forEach($scope.model.secondary, function (data) {
                            if (data.length < $scope.model.numCol) {
                                data.push({ item_col: '' });

                                columnsEqualized = false;
                            }
                        });
                    } while (columnsEqualized === false);
                }, true);
            }
        };
 
        return directive;
    }]);
})();
