(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("custactEditService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 48,
                    parameters: parms || {}
                });
            },
            listProjects: function (parms) {
                return $ihttp.post({
                    method: 46,
                    parameters: parms || {}
                });
            },
            listDeviationTypes: function (parms) {
                return $ihttp.post({
                    method: 43,
                    parameters: parms || {}
                });
            },
            listDeviationSources: function (parms) {
                return $ihttp.post({
                    method: 44,
                    parameters: parms || {}
                });
            },
            listDeviationSources2: function (parms) {
                return $ihttp.post({
                    method: 45,
                    parameters: parms || {}
                });
            },
            listSalesSizes: function (parms) {
                return $ihttp.post({
                    method: 1409,
                    parameters: parms || {}
                });
            },
            listSalesPossibilities: function (parms) {
                return $ihttp.post({
                    method: 1408,
                    parameters: parms || {}
                });
            },
            listRecurrings: function (parms) {
                return $ihttp.post({
                    method: 3145,
                    parameters: parms || {}
                });
            },
            getActivityType: function (parms) {
                return $ihttp.post({
                    method: 401,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 76,
                    parameters: mergeObj
                });
            },

            // BJS 20230904
            setGraphEventId: function (custactNo, eventId) {
                return $ihttp.post({
                    method: 76,
                    parameters: {
                        state: 'update_graph_event_id',
                        custact_no: custactNo,
                        event_id: eventId
                    }
                });
            }
        };

        return service;
    }]);
})();
