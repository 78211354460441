(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srNotCompletedNewService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrNotCompletedNewGet: function (resource_id) {
                return p2DataTaskService.call(1479, {
                    resource_id: resource_id
                });
            },
            loadSrNotCompletedNewResourceList: function () {
                return p2DataTaskService.call(1481, {});
            },
            loadSrNotCompletedNewEventList: function () {
                return p2DataTaskService.call(1480, {});
            },
            save: function (newNotcompleted) {
                return p2DataTaskService.call(1482, newNotcompleted);
            }
        };

        return service;
    }]);
})();