(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderSpecial', {
        templateUrl: 'views/components/views/orderSpecial/orderSpecial.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$uibModal', 'orderSpecialService', function ($uibModal, orderSpecialService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.gridModalOpen = false;

            vm.model = {
                rememberId: 'w_orderspecial.search',
                searchText: '',
                custList: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region GRID

            vm.openGrid = function (item) {
                $uibModal.open({
                    component: 'orderSpecialGrid',
                    resolve: {
                        selectedCustNo: function () {
                            return item.item_id
                        },
                        selectedCustName: function () {
                            return item.item_name
                        }
                    },
                    size: 'pst-ninety',
                    //backdrop: 'static'
                }).result.then(function () {
                    //vm.gridControls.refresh();
                });
            };

            // #endregion GRID

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            vm.rememberFunc = function (parm) {
                vm.model.searchText = parm;
                orderSpecialService.remember(vm.model.rememberId, vm.model.searchText);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                orderSpecialService.getRemember(vm.model.rememberId).then(function (value) {
                    if (angular.isDefined(value) && angular.isDefined(value[0]) && angular.isDefined(value[0].variablevalue) && value[0].variablevalue !== null && value[0].variablevalue !== '') {
                        vm.model.searchText = angular.fromJson(value[0].variablevalue);
                    } else {
                        vm.model.searchText = '';
                    }

                    orderSpecialService.getCustomerList().then(function (data) {
                        angular.copy(data, vm.model.custList);
                    });
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
