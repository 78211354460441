(function() {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockBatchTraceService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2StockBatchBatchTraceDownList: {
                records: []
            },
            p2StockBatchBatchTraceUpList: {
                records: []
            },
            loadP2StockBatchBatchTraceDownList: function (stockbatch_keyno, listdown) {
                var deferred = $q.defer();

                var parmsP2StockBatchBatchTraceDownList = {
                    method: 497,
                    parameters: {
                        stockbatch_keyno: stockbatch_keyno,
                        listdown: listdown
                    }
                };

                $ihttp.post(parmsP2StockBatchBatchTraceDownList).then(function (data) {
                    angular.copy(data, service.p2StockBatchBatchTraceDownList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2StockBatchBatchTraceUpList: function (stockbatch_keyno, listup) {
                var deferred = $q.defer();

                var parmsP2StockBatchBatchTraceUpList = {
                    method: 498,
                    parameters: {
                        stockbatch_keyno: stockbatch_keyno,
                        listup: listup
                    }
                };

                $ihttp.post(parmsP2StockBatchBatchTraceUpList).then(function (data) {
                    angular.copy(data, service.p2StockBatchBatchTraceUpList.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();