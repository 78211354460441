(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pickJobGrp', {
        templateUrl: 'views/components/views/pickJobGrp/pickJobGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'pickJobGrpService', 'logisticService', 'economyService', function ($stateParams, $q, stateService, utilityService, modalService, translateService, pickJobGrpService, logisticService, economyService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockPlaceId = $stateParams.stockplace_id;
            let pickJobGrpKeyNo = $stateParams.pickjobgrp_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListStockLocations: [],
                selectListTrolleys: [],
                selectListBusinessSites: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                let deferred = $q.defer();

                pickJobGrpService.getPickJobGroup({
                    stockplace_id: stockPlaceId,
                    pickjobgrp_keyno: pickJobGrpKeyNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadStockLocations(),
                        loadTrolleys()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadBusinessSites = function () {
                economyService.listBusinessSites({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListBusinessSites);
                });
            };

            let loadStockLocations = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id) !== true) return;

                return logisticService.listStockLocations({ stockplace_id: vm.model.edit.stockplace_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockLocations);
                });
            };

            let loadTrolleys = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stockplace_id) !== true) return;

                return logisticService.listTrolleys({ stockplace_id: vm.model.edit.stockplace_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListTrolleys);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                pickJobGrpService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadBusinessSites();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
