(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("checkListItemTwService", ['$ihttp', function ($ihttp) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 1566,
                    parameters: parms || {}
                });
            },
            listCheckLists: function (parms) {
                return $ihttp.post({
                    method: 1567,
                    parameters: parms || {}
                });
            },
            listCheckListItems: function (parms) {
                return $ihttp.post({
                    method: 1568,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1569,
                    parameters: edit
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 1571,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
