<tt-form-field-base
    [ttFieldId]="id.textarea"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttInvalid]="ttInvalid"
    [ttInvalidText]="ttInvalidText"
    [ttTranslateInvalidText]="ttTranslateInvalidText"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="textarea"
>
    <div class="grow-wrap" [attr.data-replicated-value]="ttModel">
        <textarea
            #inputRef
            [attr.id]="id.textarea"
            class="tt-input__form-control tt-input__form-control--textarea"
            (focus)="selectText()"
            (blur)="ttOnBlur.emit()"
            [(ngModel)]="ttModel"
            (ngModelChange)="onModelChange($event)"
            [readOnly]="ttReadonly"
            [disabled]="ttDisabled"
            [ngStyle]="style['textarea']"
        ></textarea>
    </div>
    <span *ngIf="ttInvalid" class="fa-fw fas fa-exclamation-circle tt-input__icon tt-input__icon--invalid tt-input__icon-textarea--invalid"></span>
    <tt-form-field-buttons buttons *ngIf="ttButtons" [ttButtons]="ttButtons" [ttButtonParms]="ttButtonParms"></tt-form-field-buttons>
</tt-form-field-base>
