import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonType } from '../form-field-buttons/form-field-buttons.component';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { TranslateService } from '@app/core/services/translate.service';

export interface FormButtonEvent {
    event: MouseEvent;
    buttonId: string;
    options?: { [key: string]: any };
}

@Component({
    selector: 'tt-form-field-button',
    templateUrl: './form-field-button.component.html',
    styleUrls: ['./form-field-button.component.css'],
})
export class FormFieldButtonComponent extends ComponentBaseComponent implements OnInit, OnChanges {
    @Input()
    ttButtonParms?: { [key: string]: any };

    @Input()
    ttId: string = '';

    @Input()
    ttTooltip?: string;

    @Input()
    ttTranslateTooltip?: boolean = false;

    @Input()
    ttIcon: string = '';

    @Input()
    ttType: ButtonType = 'primary';

    @Input()
    ttGoto?: string = '';

    @Input()
    ttGotoParms?: { [key: string]: string } | string = '';

    @Output()
    ttClick = new EventEmitter<FormButtonEvent>();

    classes = '';

    style: Style = {
        button: {},
        icon: {},
    };

    constructor(private translate: TranslateService) {
        super();
    }

    /**
     * Handles click event on the button.
     *
     * @param event the click event.
     */
    public onButtonClick(event: MouseEvent) {
        // if (this.ttButton?.goto && this.ttButton?.gotoParms) {
        //     // Handle goto here sometime
        // } else if (this.ttButton?.onClick && this.ttButton?.onClick instanceof Function) {
        //     this.ttButton.onClick({ event: event, button: this.ttButton, options: this.ttButtonParms });
        // }
        if (this.ttGoto && this.ttGotoParms) {
            // HAndle goto here when stateservice is converted.
        } else if (this.ttClick) {
            this.ttClick.emit({ event: event, buttonId: this.ttId, options: this.ttButtonParms });
        }
    }

    /**
     * Formats the button type.
     *
     * @param button the button to format.
     */
    setButtonType(type: ButtonType) {
        this.classes = 'tt-input__button--' + type;
    }

    async ngOnInit(): Promise<void> {
        this.setButtonType(this.ttType);

        if (!!this.ttTooltip && this.ttTranslateTooltip !== false) {
            this.ttTooltip = await this.translate.translate(this.ttTooltip);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttType']?.currentValue) {
            this.setButtonType(changes?.['ttType']?.currentValue);
        }
    }
}

/**
 * Represents a button on a form-control field.
 */
export interface FormButton {
    /** The id of the form field button, not unique. */
    id: string;

    /** The icon to display on the form field button. */
    icon: string;

    /**
     * Tooltip to display on the form button, default is that the tooltip gets translated.
     */
    tooltip?: string;

    /**
     * Whether the tooltip text should be translated, assumes `true` if not set.
     */
    translateTooltip?: boolean;

    /** The type of the button. */
    type: ButtonType;

    /** Functio to call when button is clicked, parameters include a object of the event, button and options (ButtonParms). */
    onClick?: (event: FormButtonEvent) => void;

    /** The route to goto. (NOT IMPLEMENTED) */
    goto?: 'string';

    /** The parameters to use for the route. (NOT IMPLEMENTED) */
    gotoParms?: { [key: string]: string } | string;

    /** Whether the button should be showed or not. */
    show?: boolean;

    /** Classes to apply to the button. */
    classes?: string;
}
