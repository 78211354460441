(function () {
    'use strict';

    angular.module("imApp").factory("orderLineCreditService", ['$ihttp', function ($ihttp) {
        var service = {
            getOrderLineCredit: function (parms) {
                return $ihttp.post({
                    method: 2758,
                    parameters: parms || {}
                });
            },
            listOrderLineCreditCauses: function (parms) {
                return $ihttp.post({
                    method: 2762,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2770,
                    parameters: edit
                });
            },
            approveObj: function (edit) {
                return $ihttp.post({
                    method: 2771,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();