(function() {
    'use strict';

    let module = angular.module('imApp');

    module.component('consignmentMenu', {
        templateUrl: 'views/components/views/consignmentMenu/consignmentMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', function (stateService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                name: stateService.getCurrentName()
            };

            // #endregion VARIABLES & DEFINITIONS
        }]
    });
})();
