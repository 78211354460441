(function () {
    'use strict';

    var module = angular.module('imApp');

	module.component('pPlanning', {
        templateUrl: 'views/components/views/pPlanning/pPlanning.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['pPlanningService', function (pPlanningService) {
            var vm = this;
            
    		var pad = function (num, size) {
    			var s = num + "";
    			while (s.length < size) s = "0" + s;
    			return s;
    		};
            
            vm.model = {
            	config: {
            		currentView: 'timeline',
            		currentViewDate: '',
            		currentDate: new Date(),
            		isNavigate: false,
            		isValidate: false
            	},
            	resources: [],
            	shifts: {},
            	dataSource: []
            };
            
            vm.renderData = function () {
                $("#scheduler").kendoScheduler({
                    date: vm.model.config.currentDate,
                    workDayStart: new Date("1970.01.01 06:00"),
                    workDayEnd: new Date("1970.01.01 20:00"),
                    showWorkHours: true,
                    mobile: true,
                    snap: false,
                    views: [
                        {
                            type: "timeline",
                            majorTick: 60,
                            minorTickCount: 4,
                            columnWidth: 16,
                            showWorkHours: true
                        },
                        {
                            type: "timelineWeek",
                            majorTick: 1440,
                            minorTickCount: 96,
                            columnWidth: 4,
                            showWorkHours: true
                        },
                        {
                            type: "timelineWorkWeek",
                            majorTick: 1440,
                            minorTickCount: 96,
                            columnWidth: 2,
                            showWorkHours: true
                        }
                    ],
                    group: {
                        resources: ["resources"],
                        orientation: "vertical"
                    },
                    selectable: false,
                    editable: {
                        create: true,
                        destroy: false,
                        update: false,
                        resize: true
                    },
                    navigate: function (e) {
                        vm.model.config.isNavigate = true;
                        var dato = e.date;
                        vm.model.config.currentDate = new Date(e.date);
                    },
                    dataBinding: function (e) { },
                    dataBound: function (e) {
                        var scheduler = this;
                        var viewDate = vm.fromDateToDateOnly(scheduler._selectedView._startDate) + '' + vm.fromDateToDateOnly(scheduler._selectedView._endDate);

                        if (vm.model.config.isNavigate) {
                            vm.model.config.isNavigate = false;

                            if (vm.model.config.currentView !== scheduler._selectedViewName) {
                                //view change only
                                vm.model.config.currentView = scheduler._selectedViewName;
                                vm.model.config.currentViewDate = viewDate;
                                vm.reloadData(vm.fromDateToDateOnly(scheduler._selectedView._startDate), vm.fromDateToDateOnly(scheduler._selectedView._endDate));
                            } else if (viewDate !== vm.model.config.currentDate) {
                                vm.model.config.currentViewDate = viewDate;
                                vm.reloadData(vm.fromDateToDateOnly(scheduler._selectedView._startDate), vm.fromDateToDateOnly(scheduler._selectedView._endDate));
                            }
                        }

                        vm.availableTime(this);
                    },
                    dataSource: vm.model.dataSource,
                    resources: [
                        {
                            field: "job_id",
                            name: "Jobs",
                            dataColorField: "key",
                            dataSource: [
                                { text: "Default", value: 1, key: "blue" }
                            ]
                        },
                        {
                            field: "labour_no",
                            name: "resources",
                            dataTextField: 'labour_name',
                            dataSource: vm.model.resources
                        }
                    ]
                });

                $("#scheduler").getKendoScheduler().view(vm.model.config.currentView);
            };
            
            vm.availableTime = function (scheduler) {
                $(".k-scheduler-table td").each(function (i, item) {
                    var slot = scheduler.slotByElement(item);
                    var resource = scheduler.resources[1].dataSource.options.data[slot.groupIndex];
                    var sDate = '_' + vm.fromDateToDateOnly(scheduler._selectedView._startDate);
                    var shift = vm.model.shifts[resource.labour_no + sDate];

                    if (angular.isDefined(shift)) {
                        var shiftStartDate = new Date('1970-01-01 ' + shift.start_time);
                        var shiftEndDate = new Date('1970-01-01 ' + shift.end_time)

                        if (slot.startDate.getHours() < parseInt(shiftStartDate.getHours())) {
                            $(item).addClass("customNonwork");
                            $(item).removeClass("k-today");
                        }

                        if (slot.startDate.getHours() > parseInt(shiftEndDate.getHours())) {
                            $(item).addClass("customNonwork");
                            $(item).removeClass("k-today");
                        }
                    } else {
                        $(item).addClass("customNonwork");
                        $(item).removeClass("k-today");
                    }
                });
            };
            
            vm.fromDateToDateOnly = function (d) {
                if (!angular.isDefined(d)) {
                    d = new Date();
                    return d.getFullYear() + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
                } else if (d === '') {
                    return '';
                } else {
                    try {
                        return d.getFullYear() + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
                    } catch (e) {
                        return '';
                    }
                }
            };

            vm.toDateToDateOnly = function (d) {
                if (!angular.isDefined(d)) {
                    d = new Date();
                    return d.getFullYear() + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
                } else if (d === '') {
                    return '';
                } else {
                    try {
                        return d.getFullYear() + '-' + pad(d.getMonth() + 1, 2) + '-' + pad(d.getDate(), 2);
                    } catch (e) {
                        return '';
                    }
                }
            };
            
            vm.reloadData = function (fromDate, toDate) {
                pPlanningService.genericFunction(591, {
                    from_date: fromDate,
                    to_date: toDate
                }).then(function (data) {
                    vm.model.resources = data;
                    vm.model.shifts = {};

                    angular.forEach(vm.model.resources, function (item) {
                        var shifts = JSON.parse(item.shifts);

                        angular.forEach(shifts, function (shift, key) {
                            if (key != '_') {
                                vm.model.shifts[item.labour_no + key] = shift;
                            }
                        });
                    });

                    var schedulerElement = $("#scheduler");
                    var scheduler = schedulerElement.getKendoScheduler();

                    if (scheduler) {
                        scheduler.destroy();
                        schedulerElement.empty();
                    }

                    vm.renderData();
                });
            };

            vm.reloadData(vm.fromDateToDateOnly(new Date()), vm.fromDateToDateOnly(new Date()));
        }]
    });
})();