(function () {
    'use strict';

    let module = angular.module('imApp');
    module.filter('formatDate', function () {
        return function (input) {
            return new Date(input).toLocaleString();
        };
    });

    /**
     * @typedef {Object} TouchListProps represents the properties used in ttTouchList component.
     * @property {object[]} ttList list of items to create a list for.
     * @property {(item) => void} ttClick optional callback for an item is clicked. 
     * @property {string} ttTemplateUrl the url for the template to use in the list (no default yet).
     * @property {string?} ttSkeletonTemplateUrl the url for the template to use when the list is in a loading state.
     * @property {number?} ttSkeletonCount the number of times to display the skeleton template when the list is in loading state, default is 8.
     * @property {boolean?} ttLoading whether or not the list is in a loading state.
     * @property {Button[]} ttButtons list of buttons to display when items in the list are selected.
     * @property {boolean?} ttSelectable whether the items in the list can be selected, defaults to false.
     */

    module.component('ttTouchList', {
        templateUrl: 'views/components/directives/ttTouchList/ttTouchList.template.html?v=' + module.version,
        controllerAs: 'vm',
        transclude: true,
        /** @type TouchListProps */
        bindings: {
            ttList: '<',
            ttSelectedItems: '=',
            ttClick: '&',
            ttTemplateUrl: '@',
            ttSkeletonTemplateUrl: '@',
            ttSkeletonCount: '@',
            ttLoading: '<',
            ttButtons: '<',
            ttSelectable: '@?'
        },
        controller: ['$scope', function ($scope) {
            let vm = this;

            vm.model = {
                listmode: 'default',
                mouseDelay: 0,
                mouseDownStart: null,
                selectedItems: [],
            }

            vm.onDoubleClick = function (event) {
               //console.dir(event);
                if (event.originalEvent.sourceCapabilities.firesTouchEvents === true) {
                    event.preventDefault();
                }
            };

            vm.onToolbarButtonClick = function (button) {
                button.onClick(vm.model.selectedItems);
            }

            vm.getRange = function (range) {
                return new Array(range);
            }

            let timer = null;

            vm.onClick = function (item) {
                if (vm.model.listmode === 'default') {
                    vm.ttClick({ $item: item });
                } else {
                    if (vm.model.listmode === 'select' && !vm.model.selectedItems.includes(item)) {
                       //console.log('select item')
                        vm.model.selectedItems.push(item);
                    } else if (vm.model.listmode === 'select' && vm.model.selectedItems.includes(item)) {
                       //console.log('deselect item')
                        vm.model.selectedItems = vm.model.selectedItems.filter((listitem) => listitem !== item);
                    }

                    if (vm.model.selectedItems.length === 0) {
                        vm.model.listmode = 'default';
                        $scope.$emit('ttTouchListModeChanged', vm.model.listmode);
                    }
                }
            }

            vm.selectItem = function (item) {
                if (vm.model.listmode === 'select' && !vm.model.selectedItems.includes(item)) {
                   //console.log('select item')
                    vm.model.selectedItems.push(item);
                } else if (vm.model.listmode === 'select' && vm.model.selectedItems.includes(item)) {
                   //console.log('deselect item')
                    vm.model.selectedItems = vm.model.selectedItems.filter((listitem) => listitem !== item);
                }

                if (vm.model.selectedItems.length === 0) {
                    vm.model.listmode = 'default';
                    $scope.$emit('ttTouchListModeChanged', vm.model.listmode);
                }
            }

            vm.deselectAll = function () {
                vm.model.selectedItems = [];
                vm.model.listmode = 'default';
                $scope.$emit('ttTouchListModeChanged', vm.model.listmode);
            }

            vm.initSelectMode = function (item) {
                //window.navigator.vibrate(1000);
                timer = setTimeout(() => {
                    vm.model.listmode = 'select';
                    vm.model.selectedItems.push(item);
                    $scope.$emit('ttTouchListModeChanged', vm.model.listmode);
                }, 500)
            }

            vm.cancelSelectModeInit = function (item) {
                clearTimeout(timer);
            }

            vm.$onInit = function () {
               //console.log('********* I am alive! **************');
            }

            vm.$onChanges = function (changes) {
               //console.log(changes);

                if (changes?.ttList?.currentValue) {
                    vm.deselectAll();
                }
            }
        }],
    });
})();
