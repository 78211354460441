(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('resetPassword', {
        templateUrl: 'views/components/views/resetPassword/resetPassword.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'idServerService', 'ieScreenBlockService', 'translateService', 'modalService', function ($stateParams, $q, stateService, idServerService, ieScreenBlockService, translateService, modalService) {
            var vm = this;
            
            vm.model = {
                userName: $stateParams.userName,
                password: '',
                passwordRepeat: '',
                translations: {
                    password_mismatch_title: '',
                    password_mismatch_message: '',
                    passwords_mismatch_close: '',
                    password_reset_ok_title: '',
                    password_reset_ok_message: '',
                    password_reset_ok_close: '',
                    password_reset_error_title: '',
                    password_reset_error_message: '',
                    password_reset_error_close: '',
                    password_reset_too_short_title: '',
                    password_reset_too_short_message: '',
                    password_reset_too_short_close: ''
                }
            };
            
            translateService.translateBatch(vm.model.translations).then(function (data) {
                angular.forEach(vm.model.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.model.translations[key] = data[key];
                    }
                });
            });
            
            var showMessage = function (message, title, buttonLabel, type) {
                var deferred = $q.defer();
    
                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: buttonLabel,
                        cssClass: type === 'error' ? 'btn-warning' : 'btn-default',
                        action: function (dialog) {
                            dialog.close();
    
                            deferred.resolve();
                        }
                    }]
                });
    
                return deferred.promise;
            };
    
            vm.updatePassword = function () {
                if (vm.model.password === undefined || vm.model.passwordRepeat === undefined)
                    return;
                if (vm.model.password === null || vm.model.passwordRepeat === null)
                    return;
    
                if (vm.model.password.length < 4) {
                    showMessage(vm.model.translations.password_reset_too_short_message, vm.model.translations.password_reset_too_short_title, vm.model.translations.password_reset_too_short_close);
    
                    return;
                }
    
                if (vm.model.password !== vm.model.passwordRepeat) {
                    showMessage(vm.model.translations.password_mismatch_message, vm.model.translations.password_mismatch_title, vm.model.translations.password_mismatch_close);
    
                    return;
                }
    
                ieScreenBlockService.start();
    
                idServerService.resetPassword(vm.model.userName, vm.model.password).then(function (result) {
                    ieScreenBlockService.stop();
    
                    if (result.isError !== true) {
                        vm.model.password = '';
                        vm.model.passwordRepeat = '';
    
                        showMessage(vm.model.translations.password_reset_ok_message, vm.model.translations.password_reset_ok_title, vm.model.translations.password_reset_ok_close, 'success').then(function () {
                            stateService.back();
                        });
                    } else {
                        showMessage(vm.model.translations.password_reset_error_message, vm.model.translations.password_reset_error_title, vm.model.translations.password_reset_error_close);
                    }
                }, function () {
                    ieScreenBlockService.stop();
    
                    showMessage(vm.model.translations.password_reset_error_message, vm.model.translations.password_reset_error_title, vm.model.translations.password_reset_error_close);
                });
            };
        }]
    });
})();
