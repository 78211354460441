(function () {
    'use strict';

    angular.module("imApp").factory("subOperationLangService", ['$ihttp', function ($ihttp) {
        let service = {
            listSubOperationLangs: function (parms) {
                return $ihttp.post({
                    method: 2467,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 2468,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
