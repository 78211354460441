(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('srOperations', {
        templateUrl: 'views/components/views/srOperations/srOperations.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'utilityService', 'rememberService', 'srOperationsService', 'watcherFactory', function($timeout, stateService, utilityService, rememberService, srOperationsService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
    		// ## VARIABLES & DEFINITIONS ##
    
    		vm.model = {
    			bgSrOperationsView: {
    				item_id: '',
    				item_name: '',
    				item_default: '0',
    				item_selected: '0',
    				records: [
    					{ item_id: '0', item_name: 'bg_sroperations_view_active', item_func: 'ACTIVE' },
    					{ item_id: '1', item_name: 'bg_sroperations_view_finished', item_func: 'FINISHED' }
    				]
    			},
    			resourceSearchTextBtnList: [
    				{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
    				{ item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
    			],
    			searchTextBtnList: [
    				{ item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
    				//{ item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
    			],
    			setting: {},
    			itemsListOperations: [],
    			//searchingResources: false,
    			searching: false
    		};
    
    		var reload = false;
    
    		// ## LOAD PROCEDURE CALLS ##
    
    		srOperationsService.loadSrOperationsGet().then(function (data) {
    			vm.model.setting = angular.copy(data[0]);
    
    			angular.forEach(vm.model.bgSrOperationsView.records, function (data) {
    				if (angular.isDefined(vm.model.setting.bg_sroperations_view) && angular.isDefined(data)) {
    					if (vm.model.setting.bg_sroperations_view === data.item_func) {
    						vm.model.bgSrOperationsView.item_default = data.item_id;
    					}
    				}
    			});
    			
    			loadOperations();
    
    			if (vm.model.setting.bg_sroperations_view === 'ACTIVE' && vm.model.setting.refresh_active === '1') {
    				reload = srOperationsService.reloadOperationsList();
    			}
    		});
    
    		var loadOperations = function () {
    			vm.model.searching = true;
    
    			if (angular.isUndefined(vm.model.setting.bg_sroperations_view) === true) return;
    			if (vm.model.setting.bg_sroperations_view === null) return;
    			if (vm.model.setting.bg_sroperations_view.trim().length < 1) return;
    
    			if (angular.isUndefined(vm.model.setting.date_fom) === true) return;
    			if (vm.model.setting.date_fom === null) return;
    
    			if (angular.isUndefined(vm.model.setting.date_tom) === true) return;
    			if (vm.model.setting.date_tom === null) return;
    
    			if (angular.isUndefined(vm.model.setting.dateselector_index) === true) return;
    			if (vm.model.setting.dateselector_index === null) return;
    
    			if (angular.isUndefined(vm.model.setting.resource_id) === true) return;
    			if (vm.model.setting.resource_id === null) return;
    
    			if (angular.isUndefined(vm.model.setting.resource_searchtext) === true) return;
    			if (vm.model.setting.resource_searchtext === null) return;
    
    			if (angular.isUndefined(vm.model.setting.searchtext) === true) return;
    			if (vm.model.setting.searchtext === null) return;
    
    			if (utilityService.validateParmsValue(vm.model.setting.refresh_active) !== true) return;
    		
    			vm.model.itemsListOperations = [];
    
    			srOperationsService.loadOperationsList(vm.model.setting).then(function (data) {
    				vm.model.searching = false;
    				angular.copy(data, vm.model.itemsListOperations);
    			});
    		};
    
    		// ## REMEMBER VALUE FUNCTIONS ##
    
    		var canRun616 = function (variableName, parm) {
    			if (angular.isUndefined(variableName) === true) return false;
    			if (variableName === null) return false;
    			if (variableName === '') return false;
    			if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
    			if (vm.model.setting[parm] === null) return false;
    
    			return true;
    		};
    
    		var rememberBgOperationsView = function () {
    			var variableNameBgOperationsView = 'w_sroperations.bg_sroperations_view';
    
    			if (canRun616(variableNameBgOperationsView, 'bg_sroperations_view') !== true) return;
    
    			srOperationsService.remember(variableNameBgOperationsView, vm.model.setting.bg_sroperations_view);
    		};
    
    		var rememberDateFom = function () {
    			var variableNameDateFom = 'w_sroperations.date_fom';
    
    			if (canRun616(variableNameDateFom, 'date_fom') !== true) return;
    
    			srOperationsService.remember(variableNameDateFom, vm.model.setting.date_fom);
    		};
    
    		var rememberDateTom = function () {
    			var variableNameDateTom = 'w_sroperations.date_tom';
    
    			if (canRun616(variableNameDateTom, 'date_tom') !== true) return;
    
    			srOperationsService.remember(variableNameDateTom, vm.model.setting.date_tom);
    		};
    
    		var rememberDateSelectorIndex = function () {
    			var variableNameDateSelectorIndex = 'w_sroperations.dateselector_index';
    
    			if (canRun616(variableNameDateSelectorIndex, 'dateselector_index') !== true) return;
    
    			srOperationsService.remember(variableNameDateSelectorIndex, vm.model.setting.dateselector_index);
    		};
    
    		var rememberResourceId = function () {
    			var variableNameResourceId = 'w_sroperations.resource_id';
    
    			if (canRun616(variableNameResourceId, 'resource_id') !== true) return;
    
    			srOperationsService.remember(variableNameResourceId, vm.model.setting.resource_id);
    		};
    
    		var timerSearchText = null;
    
    		var rememberSearchText = function () {
    			if (angular.isDefined(timerSearchText)) {
    				$timeout.cancel(timerSearchText);
    			}
    
    			var variableNameSearchText = 'w_sroperations.searchtext';
    
    			if (canRun616(variableNameSearchText, 'searchtext') !== true) return;
    
    			timerSearchText = $timeout(function () {
    				srOperationsService.remember(variableNameSearchText, vm.model.setting.searchtext);
    			}, 500);
    		};
    
            var rememberRefreshActive = function () {
    			var variableNameRefreshActive = 'w_sroperations.refresh_active';
    
                if (utilityService.validateParmsValue(variableNameRefreshActive) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.refresh_active) !== true) return;
    
                rememberService.remember(variableNameRefreshActive, vm.model.setting.refresh_active);
            };
    
    		// ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##
    
    		vm.typeaheadSearch = function (value) {
    			return srOperationsService.search(vm.model.setting.resource_searchtext);
    		};
    
    		var changedResourceId = function (resource_id) {
    			vm.model.setting.resource_id = resource_id;
    
    			loadOperations();
    			rememberResourceId();
    		};
    
    		// ## BUTTON GROUP FUNCTIONS ##
    
    		vm.selectBgSrOperationsView = function (item) {
    			vm.model.bgSrOperationsView.item_selected = item;
    			vm.model.setting.bg_sroperations_view = vm.model.bgSrOperationsView.item_selected;
    		};
    
    		// ## BUTTON LIST FUNCTIONS ##
    
    		vm.resourceSearchTextBtnListFunc = function (value, item_func) {
    			if (item_func === 'erase_item') {
    				vm.model.setting.resource_id = '';
    				vm.model.setting.resource_searchtext = '';
    
    				loadOperations();
    			} else if (angular.isDefined(value) && value !== '' && angular.isUndefined(item_func)) {
    				//vm.model.searchingResources = true;
    				return vm.typeaheadSearch(value);
    			} else if (item_func === 'search_item') {
    				//vm.model.searchingResources = true;
    				return vm.typeaheadSearch(value);
    			}
    		};
    
    		vm.searchTextBtnListFunc = function (item_func) {
    			if (item_func === 'erase_item') {
    				vm.model.setting.searchtext = '';
    				//vm.model.itemsListOperations = [];
    			}/* else if (item_func === 'search_item') {
    				vm.searchOperations();
    			}*/
    		};
    
    		// ## SEARCH FUNCTION ##
    
    		vm.searchOperations = function () {
    			loadOperations();
    		};
    
            // #region DIRECTION BUTTON FUNCTIONS
    
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
    				case 'sroperationmanualadd':
                        go();
                        break;
                    default:
                        break;
                }
            };
    
    		// #endregion DIRECTION BUTTON FUNCTIONS
    
    		// ## WATCH FUNCTIONS ##
    
    		watcher.$watch(function () { return vm.model.setting.bg_sroperations_view; }, function (newValue, oldValue) {
    			if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			loadOperations();
    
    			if (vm.model.setting.bg_sroperations_view === 'ACTIVE' && vm.model.setting.refresh_active === '1') {
    				reload = srOperationsService.reloadOperationsList();
    			}
    
    			rememberBgOperationsView();
    		});
    
    		watcher.$watch(function () {
    			return vm.model.setting.date_fom;
    		}, function (newValue, oldValue) {
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    
    			vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
    			rememberDateFom();
    		});
    
    		watcher.$watch(function () {
    			return vm.model.setting.date_tom;
    		}, function (newValue, oldValue) {
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    
    			vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
    			rememberDateTom();
    		});
    
    		watcher.$watch(function () {
    			return vm.model.setting.dateselector_index;
    		}, function (newValue, oldValue) {
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    
    			vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
    			rememberDateSelectorIndex();
    		});
    
    		watcher.$watch(function () {
    			return vm.model.setting.date_fomtom;
    		}, function (newValue, oldValue) {
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    
    			loadOperations();
    		});
    
    		watcher.$watch(function () {
    			return vm.model.setting.resource_searchtext;
    		}, function (newValue, oldValue) {
    			//vm.model.searchingResources = false;
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    
    			if (newValue === '') {
    				vm.model.setting.resource_id = '';
    				vm.model.setting.resource_searchtext = '';
    
    				rememberResourceId();
    				loadOperations();
    			} else {
    				angular.forEach(srOperationsService.typeaheadResourcesList, function (data) {
    					if (angular.isDefined(data)) {
    						if (data.item_name === vm.model.setting.resource_searchtext) {
    							changedResourceId(data.item_id);
    						}
    					}
    				});
    			}
    		});
    
    		watcher.$watch(function () {
    			return vm.model.setting.searchtext;
    		}, function (newValue, oldValue) {
    			if (angular.isUndefined(newValue) === true) return;
    			if (angular.isUndefined(oldValue) === true) return;
    			if (newValue === oldValue) return;
    			
    			rememberSearchText();
    		});
    
    		watcher.$watch(function () { return vm.model.setting.refresh_active; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
    			if (vm.model.setting.refresh_active === '1') {
    				loadOperations();
                }
    
    			if (vm.model.setting.bg_sroperations_view === 'ACTIVE' && vm.model.setting.refresh_active === '1') {
    				reload = srOperationsService.reloadOperationsList();
    			}
    
    			rememberRefreshActive();
    		});
    
    		watcher.$watch(function () { return srOperationsService.reloaded; }, function (newValue) {
    			if (newValue === false) return;
    
    			if (vm.model.setting.bg_sroperations_view === 'ACTIVE' && vm.model.setting.refresh_active === '1') {
    				reload = srOperationsService.reloadOperationsList();
    				loadOperations();
    			}
    		}); 
    	}]
    });
})();