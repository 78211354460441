(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ediSetupModal', {
        templateUrl: 'views/components/views/ediSetupModal/ediSetupModal.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['layoutService', 'translateService', 'validationService', function (layoutService, translateService, validationService) {
            var vm = this;

            var onDestroy = [];

            var dataItem = null;

            vm.edi = {
                p2EdisetupKeyno: 0,
                custNo: 0,
                businesscoNo: 0,
                custgrpId: '',
                ediTypeKeyno: 0,
                p2ReportdefKeyno: 0,
                p2ExportprocKeyno: 0,
                ediDestinationKeyno: 0,
                sendByEmail: '0',
                sendEdi: '0',
                remarks: ''
            };

            vm.fontSize = '14px';
            vm.data = {};
            vm.reportdefs = [];
            vm.exportprocs = [];

            // if true then we are editing, if false then we are creating new
            vm.editing = false;

            vm.translations = {
                tt_new_edirow: ''
            };

            vm.save = function () {
                if (vm.editing === true) {
                    var changes = [];

                    vm.edi.sendByEmail = vm.edi.sendByEmail === '1';
                    vm.edi.sendEdi = vm.edi.sendEdi === '1';

                    angular.forEach(vm.edi, function (value, key) {
                        if (angular.isDefined(dataItem[key])) {
                            if (dataItem[key] !== value) {
                                dataItem[key] = value;

                                changes.push(key);
                            }
                        }
                    });

                    if (changes.length > 0) {
                        angular.forEach(changes, function (value) {
                            dataItem.dirtyFields[value] = true;
                        });

                        dataItem.dirty = true;
                    }
                } else {
                    angular.forEach(vm.edi, function (value, key) {
                        dataItem[key] = value;
                    });
                }

                validationService.dynamicValidationModal(2604, dataItem).then(function (response) {
                    if (response.iserror !== true) {
                        vm.modalInstance.close({ delete: false });
                    }
                });
            };

            vm.delete = function () {
                vm.modalInstance.close({ delete: true });
            };

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            };

            vm.onEdiTypeChanged = function (ediTypeKeyno) {
                vm.edi.ediTypeKeyno = ediTypeKeyno;

                angular.copy(vm.data.ediTypes['ediType' + ediTypeKeyno].reportdefs, vm.reportdefs);
                angular.copy(vm.data.ediTypes['ediType' + ediTypeKeyno].exportprocs, vm.exportprocs);
            };

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                var parameters = vm.resolve.parameters;

                if (angular.isDefined(parameters.data)) {
                    vm.data = angular.copy(parameters.data);
                }

                if (angular.isDefined(parameters.argtype) && angular.isDefined(parameters.argvalue)) {
                    switch (parameters.argtype) {
                        case 'cust_no':
                            vm.edi.custNo = parseInt(parameters.argvalue, 10);
                            break;
                        case 'businessco_no':
                            vm.edi.businesscoNo = parseInt(parameters.argvalue, 10);
                            break;
                        case 'custgrp_id':
                            vm.edi.custgrpId = parameters.argvalue;
                            break;
                    }
                }

                if (angular.isDefined(parameters.dataItem)) {
                    dataItem = parameters.dataItem;

                    if (angular.isDefined(dataItem.p2EdisetupKeyno) && dataItem.p2EdisetupKeyno > 0) {
                        angular.forEach(vm.edi, function (_, key) {
                            if (angular.isDefined(dataItem[key])) {
                                vm.edi[key] = angular.copy(dataItem[key]);
                            }
                        });

                        vm.edi.sendByEmail = vm.edi.sendByEmail === true ? '1' : '0';
                        vm.edi.sendEdi = vm.edi.sendEdi === true ? '1' : '0';

                        vm.editing = true;

                        vm.onEdiTypeChanged(dataItem.ediTypeKeyno);
                    } else {
                        if (angular.isDefined(parameters.rows)) {
                            // remove existing edi types from edi type dropdown list
                            angular.forEach(parameters.rows, function (row) {
                                delete vm.data.ediTypes['ediType' + row.ediTypeKeyno];
                            });
                        }
                    }
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
