import { Injectable } from '@angular/core';
import { Base64UrlService } from '../../services/base64url.service';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    constructor(private base64url: Base64UrlService) {}

    // BJS 20210607
    public toBoolean(value: string | boolean | number | null | undefined, defaultValue: boolean): boolean {
        // BJS 20230315 - Modified to include more variants and make comparisons more precise.
        if (value === undefined) return defaultValue;
        if (value === null) return defaultValue;
        if (value === true) return true;
        if (value === false) return false;

        if (Number.isFinite(value)) {
            return value === 1 ? true : false;
        }

        if (typeof value === 'string') {
            switch (value.toLowerCase()) {
                case 'true':
                case '1':
                case 'yes':
                    return true;
                case 'false':
                case '0':
                case 'no':
                    return false;
            }
        }

        return defaultValue;
    }

    public isFunction(f: any) {
        return f instanceof Function;
    }

    public isString(s: any) {
        return typeof s === 'string' || s instanceof String ? true : false;
    }

    public isObject(obj: any) {
        return typeof obj === 'object' || obj instanceof Object ? true : false;
    }

    public isArray(arr: any) {
        return Array.isArray(arr);
    }

    /**
     * Base64 encodes a string, all strings in an object or all strings in an array
     * @param objectToEncode
     * @returns
     */
    public encodeObject(objectToEncode: any): any {
        if (objectToEncode === undefined) return objectToEncode;
        if (objectToEncode === null) return objectToEncode;
        if (this.isString(objectToEncode)) return this.base64url.Encode(objectToEncode);

        if (Array.isArray(objectToEncode)) {
            let arrayToEncode = objectToEncode as Array<any>;

            let encodedArray = [];

            for (let i = 0; i < arrayToEncode.length; i++) {
                encodedArray.push(this.encodeObject(arrayToEncode[i]));
            }

            return encodedArray;
        }

        if (this.isObject(objectToEncode)) {
            let encodedObject: any = {};

            let property: keyof typeof objectToEncode;

            for (property in objectToEncode) {
                encodedObject[property] = this.encodeObject(objectToEncode[property]);
            }

            return encodedObject;
        }

        return objectToEncode;
    }

    /**
     * Base64 decodes a string, all strings in an object or all strings in an array
     * @param objectToDecode
     * @returns
     */
    decodeObject(objectToDecode: any): any {
        try {
            if (objectToDecode === undefined) return objectToDecode;
            if (objectToDecode === null) return null;

            if (this.isString(objectToDecode)) {
                let retval = objectToDecode as string;

                if (retval.length <= 4) return objectToDecode;

                return this.base64url.Decode(retval);
            }

            if (Array.isArray(objectToDecode)) {
                var decodedArray = [];

                for (let i = 0; i < objectToDecode.length; i++) {
                    decodedArray.push(this.decodeObject(objectToDecode[i]));
                }

                return decodedArray;
            }

            if (this.isObject(objectToDecode)) {
                var decodedObject = {};

                let property: keyof typeof objectToDecode;

                for (property in objectToDecode) {
                    objectToDecode[property] = this.decodeObject(objectToDecode[property]);
                }

                return decodedObject;
            }
        } catch (err) {
            console.dir(err);
        }

        return objectToDecode;
    }

    /**
     * Checks whether the modifier key is pressed. `⌘` on mac or `Ctrl` on other devices.
     *
     * @param event the event to check if a modifier key was pressed on.
     * @returns `true` if a modifier key was pressed, `false` if not.
     */
    public isModifierKeyPressed(event: MouseEvent | KeyboardEvent) {
        const isMacintosh = /mac/i.test(navigator.userAgent);

        if (isMacintosh) {
            if (event.metaKey && !event.ctrlKey && !event.shiftKey) {
                return true;
            } else {
                return false;
            }
        } else if (event.ctrlKey && !event.shiftKey) {
            return true;
        } else {
            return false;
        }
    }
}
