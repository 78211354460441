(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('deliveryCustomer', {
        templateUrl: 'views/components/views/deliveryCustomer/deliveryCustomer.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'deliveryCustomerService', 'typeaheadService', 'economyService', function ($stateParams, stateService, utilityService, modalService, translateService, deliveryCustomerService, typeaheadService, economyService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let deliveryCustomerKeyno = $stateParams.deliverycustomer_keyno;
            let custNo = $stateParams.cust_no;
			
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                deliveryCustomerKeyno: deliveryCustomerKeyno,
                edit: {},
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            let loadEdit = function () {
                deliveryCustomerService.getEdit({
                    deliverycustomer_keyno: deliveryCustomerKeyno,
                    cust_no: custNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadCustomer = function () {
                if (utilityService.validateParmsValue(vm.model.edit.delivery_cust_no) !== true) return;

                return economyService.getCustomer({ cust_no: vm.model.edit.delivery_cust_no }).then(function (response) {
                    vm.model.edit.delivery_cust_no = response[0].cust_no;
                    vm.model.edit.delivery_address = response[0].cust_address;
                });
            };

            vm.typeaheadSearch = function (value) {
                if (angular.isDefined(vm.model.edit.search_contacts_only) && vm.model.edit.search_contacts_only === '1') {
                    return typeaheadService.searchCustomer({
                        cust_name: vm.model.edit.searchtext_customer,
                        filtertype: '',
                        filtervalue: '',
                        custtype: 'O',
                        lastselect: 'SEARCH'
                    });
                } else {
                    return typeaheadService.searchCustomer({
                        cust_name: vm.model.edit.searchtext_customer,
                        filtertype: '',
                        filtervalue: '',
                        custtype: 'C',
                        lastselect: 'SEARCH'
                    });
                }
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                deliveryCustomerService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    deliveryCustomerService.deleteItem(vm.model.edit).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                        	stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.onSearchtextCustomerCleared = function () {
                vm.model.edit.delivery_cust_no = '0';
                vm.model.edit.searchtext_customer = '';
                vm.model.edit.delivery_address = '';
            };
            vm.onSearchtextCustomerSelected = function (item) {
                vm.model.edit.delivery_cust_no = item.item_id;
                loadCustomer();
            };
            vm.onSearchtextCustomerChanged = function (value) {
                vm.model.edit.searchtext_customer = value;
                if (!value) {
                    vm.onSearchtextCustomerCleared();
                } else {
                    let customer = typeaheadService.selectListCustomers.find((item) => item.item_name === vm.model.edit.searchtext_customer);
                    if (customer) {
                        vm.onSearchtextCustomerSelected(customer);
                    }
                }
            };

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
