(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bankReconciliationsBtGetHistoryModal', {
        templateUrl: 'views/components/views/bankReconciliationsBt/bankReconciliationsBt.template.getHistoryModal.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<',
            close: '&',
        },
        controller: ['$stateParams', '$q', 'translateService', 'modalService', 'bankReconciliationsBtService', 'bankReconciliationsBtGetHistoryModalService', 'logisticService', function ($stateParams, $q, translateService, modalService, bankReconciliationsBtService, bankReconciliationsBtGetHistoryModalService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoBankAccountKeyno = $stateParams.businessco_bankaccount_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                setting: {},
                history: {},
                //selectListCompanies: [],
                lockedExecute: false
            };

            vm.cancel = function () {
                vm.modalInstance.close();
            }

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                bankReconciliationsBtService.getSetting({ businessco_bankaccount_keyno: businessCoBankAccountKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadHistory();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadHistory = function () {
                bankReconciliationsBtGetHistoryModalService.getHistory(vm.model.setting).then(function (info) {
                    angular.copy(info[0], vm.model.history);
                });
            };

            //let loadCompanies = function () {
            //    logisticService.listCompanies().then(function (list) {
            //        angular.copy(list, vm.model.selectListCompanies);
            //    });
            //};

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.executeChanges = function () {
                vm.model.lockedExecute = true;
                
                bankReconciliationsBtGetHistoryModalService.executeObj(vm.model.history).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedExecute = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedExecute = false;
                        vm.modalInstance.close();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                //loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
