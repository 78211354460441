(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderPurchService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2WOrderPurchSettings: function (order_internal_no) {
                return p2DataTaskService.call(993, {
					order_internal_no: order_internal_no
				});
            },
            loadP2WOrderPurchDeladronPurchList: function () {
                return p2DataTaskService.call(994, {});
            },
            loadP2WOrderPurchDisplayTypeList: function () {
                return p2DataTaskService.call(996, {});
            },
            loadP2WOrderPurchComponentsList: function (p2WOrderPurchSettings) {
                return p2DataTaskService.call(995, p2WOrderPurchSettings);
            },
            createP2WOrderPurchCreate: function (selected) {
                return p2DataTaskService.call(998, selected);
            },
            loadP2WOrderPurchMenuPurchHeadList: function (order_internal_no) {
                return p2DataTaskService.call(1000, {
                    order_internal_no: order_internal_no
                });
            }
        };

        return service;
    }]);
})();
