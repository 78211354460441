(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('textileModelCategory7Lang', {
        templateUrl: 'views/components/views/textileModelCategory7Lang/textileModelCategory7Lang.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'textileModelCategory7LangService', function ($stateParams, stateService, textileModelCategory7LangService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var textileModelCategory7Keyno = $stateParams.textilemodelcategory7_keyno;

            vm.model = {
                inputListLanguages: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            textileModelCategory7LangService.listTextileModelCategory7Languages({ textilemodelcategory7_keyno: textileModelCategory7Keyno }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                textileModelCategory7LangService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();