(function () {
	'use strict';

	let module = angular.module('imApp');

	module.component('actStatuses', {
		templateUrl: 'views/components/views/actStatuses/actStatuses.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['stateService', 'actStatusesService', function (stateService, actStatusesService) {
			let vm = this;

			vm.model = {
				itemsListActStatuses: []
			};

            let loadActStatuses = function () {
                actStatusesService.listActStatuses().then(function (data) {
                    angular.copy(data, vm.model.itemsListActStatuses);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'actstatus':
                        go({ actstatus_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadActStatuses();
            };
		}]
	});
})();
