(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('oeeDashBoard', {
        templateUrl: 'views/components/views/oeeDashBoard/oeeDashBoard.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['oeeDashBoardService', 'watcherFactory', function(oeeDashBoardService, watcherFactory) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

    		var watcher = watcherFactory.$create(vm);
            var reload = false;

            vm.model = {
                itemsListOeeDashboards: []
            };

            // #endregion VARIABLES & DEFINITIONS
            
            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadOeeDashboards = function () {
                oeeDashBoardService.listOeeDashboards().then(function (result) {
                    angular.copy(result, vm.model.itemsListOeeDashboards);
                });
            };
            
            // #endregion LOAD PROCEDURE FUNCTION CALLS

            reload = oeeDashBoardService.reloadP2OeeDashBoardList();
            
            watcher.$watch(function () {
                return oeeDashBoardService.reloaded;
            }, function (newValue) {
                if (newValue == false) return;

                reload = oeeDashBoardService.reloadP2OeeDashBoardList();
                
                loadOeeDashboards();
            });

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadOeeDashboards();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
