(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('userGroupEdit', {
        templateUrl: 'views/components/views/userGroupEdit/userGroupEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'userGroupEditService', function ($stateParams, stateService, userGroupEditService) {

            // #region VARIABLES & DEFINITIONS
            
            const vm = this;
            let userGroupKeyno = $stateParams.usergroup_keyno;
            
            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            let loadEdit = function () {
                userGroupEditService.getEdit({ usergroup_keyno: userGroupKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region SAVE BUTTON FUNCTION
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                userGroupEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
