(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('poFinishPoFinishComps', {
        templateUrl: 'views/components/views/poFinishPoFinishComps/poFinishPoFinishComps.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'poFinishPoFinishCompsService', 'stateService', function($stateParams, poFinishPoFinishCompsService, stateService) {
    
            var vm = this;
    
    
    
            // #######################
            // VARIABLES & DEFINITIONS
            // #######################
    
            vm.model = {
                bgPoFinishPoFinishCompsSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'pofinishcomps_mine', item_func: 'MINE' },
                        { item_id: '1', item_name: 'pofinishcomps_all', item_func: 'ALL' }
                    ]
                },
                p2PoFinishPoFinishProdCompList: {
                    records: []
                }
            };
    
            // ############
            // BUTTON GROUP
            // ############
    
            vm.selectBgPoFinishPoFinishComps = function (item) {
                vm.model.bgPoFinishPoFinishCompsSelection.item_selected = item;
                vm.retrieve();
            }
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            vm.retrieve = function () {
                poFinishPoFinishCompsService.loadP2PoFinishPoFinishProdCompList($stateParams.p2_pofinish_keyno, vm.model.bgPoFinishPoFinishCompsSelection.item_selected).then(function () {
                    vm.model.p2PoFinishPoFinishProdCompList = poFinishPoFinishCompsService.p2PoFinishPoFinishProdCompList;
                });
            }
        }]
    });
})();