(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srActivityService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrActivityGet: function () {
                return p2DataTaskService.call(1606, {});
            },
            loadSrActivityList: function (settingActivity) {
                return p2DataTaskService.call(1607, settingActivity);
            }
        };

        return service;
    }]);
})();