(function () {
    'use strict';

    angular.module("imApp").factory("ediImportService", ['p2DataTaskService', function (p2DataTaskService) {
        let service = {
            loadEdiImportGet: function (edi_import_keyno) {
                return p2DataTaskService.call(1153, {
                    edi_import_keyno: edi_import_keyno
                });
            },
            loadEdiSourceList: function () {
                return p2DataTaskService.call(978, {});
            },
            loadImportProcList: function (show_in_excelimport) {
                return p2DataTaskService.call(877, {
                    show_in_excelimport: show_in_excelimport
                });
            },
            loadEdiImportProcessManual: function (edi_import_keyno) {
                return p2DataTaskService.call(1156, {
                    edi_import_keyno: edi_import_keyno
                });
            },
            save: function (getEdiImport) {
                return p2DataTaskService.call(1154, getEdiImport);
            },
            delete: function (edi_import_keyno) {
                return p2DataTaskService.call(1155, {
                    edi_import_keyno: edi_import_keyno
                });
            }
        };

        return service;
    }]);
})();
