(function() {
    'use strict';

    angular.module("imApp").factory("ttWebPageMenuService", ['$ihttp', 'ttWebPageMenuEditorService', function ($ihttp, ttWebPageMenuEditorService) {
        var service = {
            load: function (webPageName, editMode) {
                return $ihttp.post({
                    method: 53,
                    parameters: {
                        webpage_name: webPageName,
                        edit_mode: editMode
                    }
                });
            },

            delete: ttWebPageMenuEditorService.delete
        }

        return service;
    }]);
})();
