(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('userUsergroupEdit', {
        templateUrl: 'views/components/views/userUsergroupEdit/userUsergroupEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'userUsergroupEditService', 'stateService', 'ieScreenBlockService', function ($stateParams, userUsergroupEditService, stateService, ieScreenBlockService) {
            const vm = this;
            
            vm.model = {
                userKeyno: $stateParams.p2_usergroup_portal_user_keyno,
                user: {},
                locked: false
            };
    
            ieScreenBlockService.start();
    
            userUsergroupEditService.loadUser(vm.model.userKeyno).then(function (user) {
                angular.copy(user, vm.model.user);
    
                ieScreenBlockService.stop();
            });
    
            vm.saveUser = function () {
                if (vm.model.locked === true) return;
    
                ieScreenBlockService.start();
    
                vm.model.locked = true;
    
                var saveCompleted = function () {
                    ieScreenBlockService.stop();
                    vm.model.locked = false;
                };
    
                userUsergroupEditService.saveUser(vm.model.user).then(function () {
                    saveCompleted();
                    stateService.back();
                }, saveCompleted);
            };
        }]
    });
})();
