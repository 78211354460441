(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("invoiceMakeOverviewService", ['$ihttp', function ($ihttp) {
        let service = {
            getInvoiceMakeOverviews: function (parms) {
                return $ihttp.post({
                    method: 1756,
                    parameters: parms || {}
                });
            },
            listInvoiceMakeOverviews: function (parms) {
                return $ihttp.post({
                    method: 1757,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
