(function () {
    'use strict';

    angular.module("imApp").factory("purchPlanningService", ['$ihttp', function ($ihttp) {
        var service = {
            getPurchPlanning: function (parms) {
                return $ihttp.post({
                    method: 2445,
                    parameters: parms || {}
                });
            },
            createPurchase: function (mergeObj) {
                return $ihttp.post({
                    method: 2447,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();