(function () {
    const module = angular.module('imApp');

    /**
     * @author JLR
     * @version 20231218
     */

    /**
     * @typedef {Object} OverflowMenuProps represents the properties of the overflow-menu component.
     * @property {string | undefined} ttIcon (optional) the icon classes for the icon to display, default is vertival ellipses.
     */

    module.component('ttOverflowMenu', {
        // All menu items is given through transclude.
        transclude: true,
        templateUrl: 'views/components/directives/ttOverflowMenu/ttOverflowMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        /** @type OverflowMenuProps */
        bindings: {
            ttIcon: '@?',
            ttSize: '@?',
        },
        controller: ['layoutService', function (layoutService) {
            const vm = this;

            vm.open = false;

            vm.id = {
                button: crypto.randomUUID(),
            }

            vm.style = {
                button: {},
                icon: {},
            }

            vm.toggleMenu = function () {
                vm.open = !vm.open;
            }

            /**
             * Checks whether the given element or its children is the given event target.
             * 
             * @param {HTMLElement} element the element and its children to check.
             * @param {EventTarget} target the target from an event to search for.
             * @returns true if the given element or any of its children is the event target, false if not.
             */
            function isTargetInElement(element, target) {
                if (element === target) return true;

                for (let i = 0; i < element?.children?.length; i++) {
                    let child = element.children[i];
                    let found = isTargetInElement(child, target);

                    if (found) {
                        return true;
                    }
                };

                return false;
            }

            function handeMenuClick(event) {
                const menu = document.getElementById(vm.id.button);

                if (!menu || isTargetInElement(menu, event.target) === false) {
                    vm.open = false;
                }
            }

            vm.$onInit = function () {
                vm.ttIcon ??= 'far fa-ellipsis-v';

                document.addEventListener('click', handeMenuClick);

                layoutService.onLayoutChanged([], (info) => {
                    vm.style.icon.fontSize = info.fontSizes.textSize;

                    if (vm.ttSize === 'md') {
                        vm.style.icon.fontSize = info.fontSizes.textSizeL;
                    } else if (vm.ttSize === 'lg') {
                        vm.style.icon.fontSize = info.fontSizes.textSizeXL;
                    } else {

                    }
                });
            }

            vm.$onDestroy = function () {
                document.removeEventListener('click', handeMenuClick);
            }
        }]
    });

})()
