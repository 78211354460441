(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('offDutyCode', {
        templateUrl: 'views/components/views/offDutyCode/offDutyCode.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'offDutyCodeService', function ($stateParams, stateService, modalService, offDutyCodeService) {   
            var vm = this;
            
            vm.model = {
            	dataSource: {},
            	colorList: {
                	records: []
                },
                salaryTypes: {
                	records: []
                },
                lockedSave: false
            };
            
            offDutyCodeService.getOffDutyCode($stateParams.offdutycode_keyno).then(function () {
                vm.model.dataSource = offDutyCodeService.dataSource;
            });

            offDutyCodeService.getColorList().then(function () {
                vm.model.colorList = offDutyCodeService.colorList;
            });

            offDutyCodeService.getSalaryTypes().then(function () {
                vm.model.salaryTypes = offDutyCodeService.salaryTypes;
            });
            
            vm.saveOffDutyCode = function () {
                vm.model.lockedSave = true;

                offDutyCodeService.saveObj(vm.model.dataSource).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };
            
            vm.deleteOffDutyCode = function () {
                offDutyCodeService.deleteOffDutyCode(vm.model.dataSource.offdutycode_keyno).then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();