(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockAll', {
        templateUrl: 'views/components/views/stockAll/stockAll.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockAllService', function ($stateParams, stockAllService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockKeyno = $stateParams.stock_keyno;

            vm.model = {
                itemsListStockAlls: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadStockAlls = function () {
                stockAllService.listStockAlls({ stock_keyno: stockKeyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListStockAlls);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadStockAlls();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
