(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('accountingProcessingImportModal', {
        templateUrl: 'views/components/views/accountingProcessing/accountingProcessing.template.importModal.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<',
            close: '&',
        },
        controller: ['base64', 'utilityService', 'modalService', 'excelImportService', 'typeaheadService', 'accountingProcessingImportModalService', function (base64, utilityService, modalService, excelImportService, typeaheadService, accountingProcessingImportModalService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];

            vm.cancel = function () {
                vm.modalInstance.close();
            }

            vm.model = {
                custactacKeyno: '',
                importProcesses: [],
                importSettings: {
                    p2_importproc_keyno: '0',
                    p2_exportproc_keyno: '0',
                    encodingtype: ''
                },
                showUpload: true,
                spinImport: false,
                locked: false,
                spreadsheetHeaders: {},
                spreadsheetData: {
                    filename: '',
                    encoding: '',
                    records: []
                },
            };


            vm.importData = function (verify) {
                vm.model.spinImport = true;

                if (vm.model.spreadsheetData.filename !== '') {
                    var stringified = '', order = [], keys = [], k;

                    angular.forEach(vm.model.spreadsheetHeaders, function (h) {
                        order.push(h.value);
                    });

                    keys = Object.keys(vm.model.spreadsheetData.records[0]);

                    keys.sort(function (a, b) {
                        return order.indexOf(a) - order.indexOf(b);
                    });

                    angular.forEach(vm.model.spreadsheetData.records, function (row) {
                        for (var i = 0; i < keys.length; i++) {
                            k = keys[i];
                            stringified += row[k] + '|§|';
                        }

                        stringified = stringified.slice(0, -3);
                        stringified += '|#|';
                    });

                    stringified = stringified.slice(0, -3);

                    excelImportService.genericFunction(876, {
                        p2_importproc_keyno: vm.model.importSettings.p2_importproc_keyno,
                        table_name_destination: vm.model.importSettings.table_name_destination,
                        show_table_name_destination: vm.model.importSettings.show_table_name_destination,
                        filename: vm.model.spreadsheetData.filename,
                        verify: verify,
                        headerdata: base64.encode(angular.toJson(order)),
                        records: base64.encode(stringified),
                        custactac_keyno_import: vm.model.custactacKeyno,
                        //records: base64.encode(angular.toJson(vm.model.spreadsheetData.records))
                        //records: vm.model.spreadsheetData.records
                    }).then(function (data) {
                        vm.model.spinImport = false;
                        vm.model.p2WExcelImportImport = angular.copy(data[0]);

                        var ok = 'OK';

                        if (angular.isDefined(vm.model.p2WExcelImportImport.exec_resultcode) && vm.model.p2WExcelImportImport.exec_resultcode == '-1') {
                            modalService.show({
                                type: 'danger',
                                title: 'Advarsel',
                                message: vm.model.p2WExcelImportImport.exec_message,
                                buttons: [{
                                    label: ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        } else {
                            modalService.show({
                                type: 'primary',
                                title: 'Informasjon',
                                message: vm.model.p2WExcelImportImport.exec_message,
                                buttons: [{
                                    label: ok,
                                    cssClass: 'btn-success',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                    }
                                }]
                            });
                        }
                    }, { escape: true, encodedParms: true }).then(function (data) {
                        if (verify == 0)
                            console.log('imported');
                        else if (verify == 1)
                            console.log('verified');
                        else
                            console.log('neither 1 or 0');
                    }).finally(() => {
                        vm.model.spinImport = false;
                        vm.model.locked = false;
                    });
                }
            };

            let loadImportProcedure = function () {
                if (utilityService.validateParmsValue(vm.model.importSettings.p2_importproc_keyno) !== true) return;

                return excelImportService.getImportProcedure({ p2_importproc_keyno: vm.model.importSettings.p2_importproc_keyno }).then(function (response) {
                    vm.model.importSettings.show_table_name_destination = response[0].show_table_name_destination;
                });
            };

            vm.typeaheadSearch = function (value) {
                return typeaheadService.lookUpTable({ table_name: value || '' });
            };

            vm.onImportProcessChanged = function (value) {
                vm.model.importSettings.p2_importproc_keyno = value;

                if (vm.model.spreadsheetData.records.length == 0 || vm.model.importSettings.p2_importproc_keyno == '' || vm.model.importSettings.p2_importproc_keyno == '0') {
                    vm.model.locked = true;
                } else {
                    vm.model.locked = false;
                }

                loadImportProcedure();
            }

            async function getImportProcesses() {
                return vm.model.importProcesses = await accountingProcessingImportModalService.getImportProcesses();
            }

            vm.$onInit = function () {
                getImportProcesses();
                if (vm.resolve?.custactAcKeyno) {
                    vm.model.custactacKeyno = vm.resolve.custactAcKeyno;
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS

        }]
    });
})();
