(function () {
    'use strict';

    var module = angular.module('imApp');

	module.component('gpximport', {
        templateUrl: 'views/components/views/gpximport/gpximport.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$rootScope', 'modalService', 'base64', '$stateParams', 'stateService', 'utilityService', 'gpximportService', '$q', 'fileService', 'docarcService', function ($rootScope, modalService, base64, $stateParams, stateService, utilityService, gpximportService, $q, fileService, docarcService) {

			// #region VARIABLES & DEFINITIONS

			var vm = this;
			var argType = $stateParams.argtype;
			var argValue = $stateParams.argvalue;

			vm.model = {
                setting: {},
                uploadFiles: [],
                fileName: '',
                fileContent: '',
                fileNameSyntax: '',
				loadingFile: false
			};

			// #endregion VARIABLES & DEFINITIONS


			// #region LOAD PROCEDURE FUNCTION CALLS
            gpximportService.getGpxImport({
                argtype: argType,
                argvalue: argValue
            }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

            }).then(function () {
                loadSyntaxGet();
            });


            var loadSyntaxGet = function () {
                gpximportService.loadP2DocArcFileNameSyntaxGet(vm.model.setting.argtype, vm.model.setting.argvalue).then(function (data) {
                    vm.model.fileNameSyntax = data;
                });
            };


            var uploadFile = function () {

                if (vm.model.uploadFiles.length < 1) return;

                var addToDocarc = function (data) {
                    var docarcDeferred = $q.defer();

                    var docarcParms = {
                        contentType: data.contentType || '',
                        filename: data.fileName,
                        localFileName: data.localFileName || '', // BJS 20211019
                        originalFileName: data.originalFileName || '',
                        originalDateTime: data.originalDateTime || '',
                        argType: $stateParams.argtype || '',
                        argValue: $stateParams.argvalue || '',
                        size: data.size || 0,
                        checksum: data.checksum || '',
                        latitude: data.latitude || 0,
                        longitude: data.longitude || 0,
                        altitude: data.altitude || 0,
                        heading: data.heading || 0,
                        checksum_md5: data.checksum_md5 || 0
                    };

                    docarcService.add(docarcParms, { checksum_md5: data.checksum_md5 }).then(function (docarcData) {
                        docarcDeferred.resolve();
                    });

                    return docarcDeferred.promise;
                };

                fileService.uploadFiles({
                    files: vm.model.uploadFiles,
                    fileNameSyntax: vm.model.fileNameSyntax,
                    preprocess: function (fileName, file) {
                        var ppDeferred1 = $q.defer();

                        fileService.hash(file).then(function (response) {
                            docarcService.exists(fileName, response.hash, $stateParams.argtype, $stateParams.argvalue).then(function (data) {
                                ppDeferred1.resolve({ cancelUpload: data.exists });
                            });
                        });

                        return ppDeferred1.promise;
                    },
                    postprocess: function (data) {
                        var ppDeferred2 = $q.defer();

                        if (data.fileItemKeyno > 0) {
                            addToDocarc(data).then(function () {
                                ppDeferred2.resolve();
                            });
                        } else {
                            if (data.isError === true) {
                            }
                            ppDeferred2.resolve();
                        }

                        return ppDeferred2.promise;
                    }
                }).then(function () {
                    vm.model.uploadFiles = [];
                    vm.model.loadingFile = false;

                });
            };




            var loadFileImport = function () {
                if (angular.isUndefined(vm.model.fileContent) === true) return;
                if (vm.model.fileContent === null) return;
                if (vm.model.fileContent.trim().length < 1) return;

                vm.model.loadingFile = true;
                //vm.model.fileContent = base64.urlencode(vm.model.fileContent);
                vm.model.fileContent = base64.urlencode('');

                gpximportService.loadFileImportGpx(vm.model.setting.argvalue, vm.model.fileName, vm.model.fileContent).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.loadingFile = false;
                                }
                            }]
                        });

                        vm.model.uploadFiles = [];

                    } else {
                        uploadFile()   
                    }



                });
            };

            // ## SELECT FILE FUNCTION ##

            vm.selectFile = function () {
                document.getElementById('fileInput').click();
            };

            // ## CHANGED FILE FUNCTION ##

            vm.fileChanged = function () {
                var reader = new FileReader();

                reader.onload = function (e) {
                    $rootScope.$apply(function () {
                        vm.model.fileContent = reader.result;
                        vm.model.fileName = gpxFile.name;
                        document.getElementById('fileInput').value = '';
                        loadFileImport();
                    });
                };

                var gpxFile = document.getElementById('fileInput').files[0];
                vm.model.uploadFiles.push(gpxFile);
                reader.readAsText(gpxFile);
                

            };



			// #endregion LOAD PROCEDURE FUNCTION CALLS








			

        }]
    });
})();