(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderlineEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            selectingProduct: false,
            selectingProdsize: false,
            selectingProdChoice: false,
            orderline_edit: {
                orderline_keyno: 0,
                prod_id: '',
                prod_name: '',
                prod_stock_unit_name: '',
                prodchoice_keyno: '',
                prodsize_keyno: '',
                ordered_quantity: '0',
                price_ordval: ''
            },
            propertylist_get: {
                prodprop_lineno: '',
                prod_id: '',
                property_id: '',
                property_name: '',
                numbervalue: '',
                textvalue: '',
                boolean: '',
                fixed: '',
                valuetype_no: '',
                unit_name: '',
                unit_no: '',
                property_lang_name: '',
                description: '',
                orderby: '',
                records: []
            },
            prodchoicelist_get: {
                choosed_prod_name: '',
                records: []
            },
            prodsize_values: {
                prodprop_lineno: '',
                number: '',
                text: '',
                boolean: '',
                records: []
            },
            loadOrderline: function (orderlineKeyno, argtype, argvalue) {
                if (service.loaded === true && service.orderline_edit.orderline_keyno == orderlineKeyno) return $q.resolve();

                var deferred = $q.defer();

                var parms_orderline_get = {
                    method: 127,
                    parameters: {
                        orderline_keyno: orderlineKeyno,
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parms_orderline_get).then(function (data) {
                    angular.copy(data[0], service.orderline_edit);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            getProdsizeValues: function () {
                var deferred = $q.defer();

                var parms_prodsize_list = {
                    method: 137,
                    parameters: {
                        prodsize_keyno: service.orderline_edit.prodsize_keyno
                    }
                };

                $ihttp.post(parms_prodsize_list).then(function (data) {
                    angular.copy(data, service.prodsize_values.records);
                    //Går i loop først på hver verdi som er hentet
                    //('Antall enheter på produktet er ' + service.propertylist_get.records.length)
                });

                return deferred.promise;
            }, 
            loadPropertyList: function () {
                var deferred = $q.defer();

                var parms_propertylist_get = {
                    method: 129,
                    parameters: {
                        orderline_keyno: service.orderline_edit.orderline_keyno,
                        prod_id: service.orderline_edit.prod_id
                    }
                };

                $ihttp.post(parms_propertylist_get).then(function (data) {
                    angular.copy(data, service.propertylist_get.records);

                    for (var i = 0; i < service.prodsize_values.records.length; i++) {
                        //('Loop nr 1-' + i)
                        //('service.prodsize_values.records[i].prodprop_lineno = ' + service.prodsize_values.records[i].prodprop_lineno)
                        //('service.prodsize_values.records[i].number = ' + service.prodsize_values.records[i].number)

                        for (var j = 0; j < service.propertylist_get.records.length; j++) {
                            //('Loop nr 2-' + j)
                            if (service.propertylist_get.records[j].prodprop_lineno === service.prodsize_values.records[i].prodprop_lineno) {
                                //('---INSIDE IF STATEMENT---')
                                service.propertylist_get.records[j].numbervalue = service.prodsize_values.records[i].number;
                            }
                        }
                    }
                });

                return deferred.promise;
            },
            loadProdChoiceList: function () {
                var deferred = $q.defer();

                var parms_prodchoicelist_get = {
                    method: 130,
                    parameters: {
                        orderline_keyno: service.orderline_edit.orderline_keyno,
                        prod_id: service.orderline_edit.prod_id
                    }
                };

                $ihttp.post(parms_prodchoicelist_get).then(function (data) {
                    angular.copy(data, service.prodchoicelist_get.records);

                    deferred.resolve();
                });

                return deferred.promise;
            },
            productUnitGet: function () {
                var parms_product_unit_get = {
                    method: 131,
                    parameters: {
                        prod_id: service.orderline_edit.prod_id
                    }
                };

                $ihttp.post(parms_product_unit_get).then(function (data) {
                    service.orderline_edit.prod_stock_unit_name = data[0].unit_name;
                });
            },
            saveOrderline: function () {
                var parms_save_orderline = angular.copy(service.orderline_edit);

                parms_save_orderline.properties = angular.copy(service.propertylist_get.records);
                parms_save_orderline.prodchoice = angular.copy(service.prodchoicelist_get.records);

                //(angular.toJson(parms_save_orderline))
            }
        }

        return service;
    }]);
})();