<p *ngIf="!widgetService.isWidgetTextLineArray(ttModel)" class="tt-widget__value">{{ viewValue }}</p>
<ng-container *ngIf="widgetService.isWidgetTextLineArray(ttModel)" class="tt-widget__value">
    <p
        class="tt-widget__value"
        *ngFor="let textLine of ttModel"
        [style.textAlign]="textLine.textAlign"
        [style.fontWeight]="textLine.fontWeight"
        [style.fontSize]="textLine.fontSize"
        [style.alignSelf]="textLine.alignSelf"
    >
        {{ textLine.viewValue }}
    </p>
</ng-container>
