(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("stockTransferLoadService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            getStockTransferLoad: function (parms) {
                return $ihttp.post({
                    method: 1036,
                    parameters: parms || {}
                });
            },
            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            pickFinishedObj: function (setting) {
                return $ihttp.post({
                    method: 1040,
                    parameters: setting
                });
            }
        };

        return service;
    }]);
})();