(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('accountingBunt', {
        templateUrl: 'views/components/views/accountingBunt/accountingBunt.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'base64', 'accountingBuntComponentService', 'ttGridFactory', function ($stateParams, $timeout, $q, stateService, utilityService, translateService, modalService, base64, accountingBuntComponentService, ttGridFactory) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let webPageName = stateService.getCurrentName();
            let accountingBuntKeyno = $stateParams.accountingbunt_keyno;
            var itemsListInvoices = [];

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                webPageName: webPageName,
                edit: {},
                gridReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: '',
                lockedSave: false,
                creatingFile: false
            };

            vm.grid = new ttGridFactory({
                rememberId: '',
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                const deferred = $q.defer();

                accountingBuntComponentService.getEdit({ accountingbunt_keyno: accountingBuntKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);

                    loadGrid();
                    loadAccountingBuntInvoices();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            var loadAccountingBuntInvoices = function () {
                if (utilityService.validateParmsValue(vm.model.edit.accountingbunt_keyno) !== true) return;

                accountingBuntComponentService.listAccountingBuntInvoices(vm.model.edit.accountingbunt_keyno).then(function (data) {
                    angular.copy(data, itemsListInvoices);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.edit.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.edit.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.edit.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.edit.sent_ok) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.edit.p2_datatask_keyno,
                        parameters: vm.model.edit
                    };
                }

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            let retrieveExport = function () {
                if (utilityService.validateParmsValue(vm.model.edit.accountingbunt_keyno) !== true) return;

                vm.model.creatingFile = true;

                accountingBuntComponentService.exportAccountingBunt({ accountingbunt_keyno: vm.model.edit.accountingbunt_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.creatingFile = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.creatingFile = false;
                        
                        var elem = document.getElementById('formAccountingBuntLink');
                        var text = base64.urldecode(response[0].filecontent);

                        var txtData = new Blob([text], { type: 'text/txt' });
                        var txtUrl = URL.createObjectURL(txtData);

                        elem.setAttribute('href', txtUrl);
                        elem.setAttribute('download', response[0].filename);
                        elem.click();
                    }
                });
            };

            // #region PROCEDURE FUNCTION

            function createDocument(item) {
                var deferred = $q.defer();

                accountingBuntComponentService.makeInvoice(item.item_id).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }

            // #endregion PROCEDURE FUNCTION

            // #region ACTIVATE PROGRESS FUNCTION

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';
                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                        	return;
                        }

                        if (items[step].item_id === '0') {
                            skip++;
                            step = vm.model.progressInfo.step + skip + failed;

                            doStep();
                        } else {
                            step = vm.model.progressInfo.step + skip + failed;
                            vm.model.progressInfo.field = items[step].item_id + ': ' + items[step].item_name;
                            modalService.progressInfo.field = vm.model.progressInfo.field;

                            createDocument(items[step]).then(function (data) {
                                if (data[0].errorcode !== '0') {
                                    utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                                        vm.model.progressInfo.step += increment;
                                        modalService.progressInfo.step = vm.model.progressInfo.step;
                                        modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                        doStep();
                                    });
                                } else {
                                    vm.model.progressInfo.step += increment;
                                    modalService.progressInfo.step = vm.model.progressInfo.step;
                                    modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                    doStep();
                                }
                            });
                        }
                    });
                };

                doStep();
            };

            // #endregion ACTIVATE PROGRESS FUNCTION

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            var progressFunc = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';

                angular.forEach(itemsListInvoices, function (item) {
                    if (item.item_id !== '0' || item.item_id !== '-1') {
                        vm.model.progressInfo.max++;
                    }
                });

                vm.activateProgress(itemsListInvoices);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;
                    
                    if (vm.model.runProgress === 'ABORT' || vm.model.runProgress === 'FINISH') {
                        loadEdit();
                        loadGrid();
                    }
                });
            };

		    // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            let saveAccountingBunt = function () {
                vm.model.lockedSave = true;

                accountingBuntComponentService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'save':
                        saveAccountingBunt();
                        break;
                    case 'createInvoice':
                    case 'updateInvoice':
                        progressFunc();
                        break;
                    case 'export':
                        retrieveExport();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case vm.model.edit.item_state:
                        go(vm.model.edit.item_parms);
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
