(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockTransferLoad', {
        templateUrl: 'views/components/views/stockTransferLoad/stockTransferLoad.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'stockTransferLoadService', function ($stateParams, stateService, modalService, translateService, stockTransferLoadService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let stockTransferKeyno = $stateParams.stocktransfer_keyno;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                setting: {},
                listStockTransferLoadOnLocation: [],
                lockedPickFinished: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            stockTransferLoadService.getStockTransferLoad({ stocktransfer_keyno: stockTransferKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.setting);
                
                if (angular.isDefined(vm.model.setting.p2_datatask_keyno_to_run) && vm.model.setting.p2_datatask_keyno_to_run > 0) {
                    stockTransferLoadService.genericFunction(vm.model.setting.p2_datatask_keyno_to_run, vm.model.setting).then(function (data) {
                        angular.copy(data, vm.model.listStockTransferLoadOnLocation);
                    });
                }
    		});

		    // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.pickFinishedChanges = function () {
                vm.model.lockedPickFinished = true;

                stockTransferLoadService.pickFinishedObj(vm.model.setting).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedPickFinished = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedPickFinished = false;
                    	stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();