import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { CoreComponentService, Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { Observable } from 'rxjs';
import { SearchItem } from '../search/search.component';
import { ComponentBaseComponent } from '../component-base/component-base.component';
import { ListboxSelectEvent } from '@app/core/directives/listbox-popup/listbox-popup.directive';

export interface Item extends Record<string | symbol, unknown> {
    item_id: string;
    item_name: string;
    item_name_sub1?: string;
    item_glyphicon?: string;
    item_glyphicon_color?: string;
    item_state?: string;
    item_parms?: string;
    item_path?: string;
    item_filtervalue?: string;
    item_thumb?: string;
    orderby?: string;
}

@Component({
    selector: 'tt-listbox',
    templateUrl: './listbox.component.html',
    styleUrls: ['./listbox.component.css'],
})
export class ListboxComponent<TType extends Record<string | symbol, unknown> = SearchItem> extends ComponentBaseComponent implements OnInit, OnChanges {
    @Input()
    ttLabel: string = '';

    /**
     * List of items to display in the listbox.
     */
    @Input()
    ttData: TType[] | Observable<TType[]> = [];

    /**
     * The key name for the property to use as view-value in the list.
     */
    @Input()
    ttDataName: keyof TType = 'item_name' as keyof TType;

    /**
     * The key name for the property to use as id in the list.
     */
    @Input()
    ttDataId: keyof TType = 'item_id' as keyof TType;

    /**
     * Event emitted when an item is selected, the selected item is given in the event.
     */
    @Output()
    ttSelect = new EventEmitter<ListboxSelectEvent<TType>>();

    /**
     * The width of the listbox, numbers will be used as pixels.
     */
    @Input()
    ttWidth: number | string | 'fill' | 'parent' = 'parent';

    /**
     * The size of the list item in the component. Auto will have a smaller version for desktop and a bigger for mobile.
     */
    ttItemSize: 'small' | 'medium' | 'auto' = 'auto';

    /**
     * The element to position relative to, if not specified the listbox is statically positioned.
     */
    @Input()
    ttRelativeElement?: ElementRef | string;

    /**
     * The filter value to highlight the items with.
     */
    @Input()
    ttFilterValue: string = '';

    /**
     * Whether or not the listbox should be displayed. Only takes effect if a relative element is given.
     */
    @Input()
    ttShow: boolean = true;

    @Input()
    ttMinWidth: string = '30rem';

    /**
     * Whether or not to make the parts of item_name which matches the filter value bold.
     */
    ttHighlight = true;

    /**
     * Reference to the listbox element.
     */
    @ViewChild('listboxRef')
    listboxRef?: ElementRef;

    /**
     * References to the options of the listbox.
     */
    @ViewChildren('optionRef')
    optionRefs?: QueryList<ElementRef>;

    /**
     * The index of the currently selected item.
     */
    @Input()
    ttSelectedIndex?: number = 0;

    /**
     * Whether or not the modal is displayed, reflects the value of `ttShow`.
     */
    show = true;

    /**
     * Parsen version of ttData. Used internally in component.
     */
    data: TType[] = [];

    /**
     * Observable list of the data.
     */
    data$!: Observable<TType[]>;

    /**
     * Number of items in the list.
     */
    numberOfItems = 0;

    /**
     * Ids of the elements of the component.
     */
    id = {
        overlay: crypto.randomUUID(),
        modal: crypto.randomUUID(),
    };

    @Input()
    ttStyle: Partial<ListboxStyle> = {
        modal: {},
        listbox: {},
        option: {},
        icon: {},
        selectedOption: {},
    };

    /**
     * Inline styles of the element in the component.
     */
    style: ListboxStyle = {
        modal: {},
        listbox: {},
        option: {},
        icon: {},
        selectedOption: {},
    };

    constructor(private layoutService: LayoutService, private core: CoreComponentService) {
        super();
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info && this.style['option']) {
                this.style['option'].fontSize = info.fontSizes.textSize;
            }
        });
    }

    /**
     * Repositions the modal if the document experiences any scroll event.
     *
     * @param event generic event.
     */
    @HostListener('document:scroll', ['$event'])
    onScroll(_: Event) {
        if (this.ttRelativeElement && this.style['modal']) {
            this.style['modal'].transition = 'none';
            this.setListboxRelativePosition(this.ttRelativeElement);
            this.style['modal'].transition = 'initial';
        }
    }

    /**
     * Repositions the modal if the document experiences any scroll event.
     *
     * @param event generic event.
     */
    @HostListener('window:resize', ['$event'])
    onResize(_: Event) {
        if (this.ttRelativeElement && this.style['modal']) {
            this.style['modal'].transition = 'none';
            this.setListboxRelativePosition(this.ttRelativeElement);
            this.style['modal'].transition = 'initial';
        }
    }

    /**
     * Emits ttSelect event and hides the listbox.
     *
     * @param item the selected item.
     */
    public onSelect(item: TType, event: MouseEvent) {
        this.ttSelectedIndex = this.data.indexOf(item);
        this.ttSelect.emit({ event: event, item: item });
    }

    /**
     * Scrolls the listbox so the selected value is in view.
     */
    private scrollListboxToSelected(): void {
        const listbox: HTMLDivElement = this.listboxRef?.nativeElement;
        const selectedOption: HTMLLIElement = this.ttSelectedIndex !== undefined && this.optionRefs?.get(this.ttSelectedIndex)?.nativeElement;

        if (listbox && selectedOption) {
            const optionHeight = selectedOption.offsetHeight;
            const optionTop = selectedOption.offsetParent === listbox ? selectedOption.offsetTop : selectedOption.offsetTop - listbox.offsetTop;
            const listboxScrollTop = listbox.scrollTop;

            if (optionTop < listboxScrollTop) {
                listbox.scrollTop = optionTop;
            } else if (optionTop + optionHeight > listboxScrollTop + listbox.clientHeight) {
                listbox.scrollTop = optionTop + optionHeight - listbox.clientHeight;
            }
        }
    }

    /**
     * Whether the given item is of type `Item` or not.
     *
     * @param item the item in question to check.
     * @returns `true` if the given item is `Item`
     */
    private isItem(item: unknown): item is Item {
        // TODO: implmement.
        return true;
    }

    /**
     * Returns a string of css classes to correctly display the icon of the given item.
     *
     * @param item the item to construct css classes for the icon of.
     * @returns a string of css classes to correctly the display the icon of the given item.
     */
    private getIconClassesFromItem(item: TType): string {
        if (this.isItem(item)) {
            if (item?.['item_glyphicon']?.startsWith('fa')) {
                return 'fad fa' + item['item_glyphicon'].replace(item['item_glyphicon'].split('-')[0], '');
            } else if (item?.['item_glyphicon']?.length && item['item_glyphicon'].length > 0) {
                return 'glyphicon ' + item.item_glyphicon;
            }
            return '';
        }
        return '';
    }

    /**
     * Tracks the given item by the item id key.
     *
     * @param _ the index of the item in the list.
     * @param item the item in the list.
     * @returns the item id value.
     */
    public trackById(_: number, item: TType) {
        return item[this.ttDataId];
    }

    /**
     * Parses the given data to display the list.
     *
     * @param data raw data items.
     */
    setData(data: Item[]) {
        this.data = data.map((item) => {
            return {
                ...item,
                item_glyphicon: this.getIconClassesFromItem(item as unknown as TType),
            };
        }) as unknown as TType[];

        this.numberOfItems = this.data.length;
    }

    public hasIcon(item: TType) {
        return Object.hasOwn(item, 'item_glyphicon');
    }

    /**
     * Sets the absolute position of the listbox relative to the given element.
     *
     * @param relativeElement the element to position the listbox relative to.
     */
    setListboxRelativePosition(relativeElement: ElementRef | string): void {
        let clientRect;
        if (relativeElement instanceof ElementRef) {
            clientRect = (relativeElement.nativeElement as HTMLElement)?.getBoundingClientRect();
        } else {
            clientRect = document.getElementById(relativeElement)?.getBoundingClientRect();
        }

        if (clientRect && this.style['modal']) {
            if (clientRect.bottom > window.innerHeight - 100) {
                this.style['modal'].position = 'fixed';
                this.style['modal'].top = 'initial';
                this.style['modal'].bottom = `${window.innerHeight - clientRect.top + 1}px`;
                this.style['modal'].left = clientRect.left - 1 + 'px';
                this.style['modal'].boxShadow = '0 -0.5rem 0.9rem rgba(0, 0, 0, 0.2)';
            } else {
                this.style['modal'].position = 'fixed';
                this.style['modal'].bottom = 'initial';
                this.style['modal'].top = clientRect.bottom + 1 + 'px';
                this.style['modal'].left = clientRect.left - 1 + 'px';
                this.style['modal'].boxShadow = '0 0.9rem 0.9rem rgba(0, 0, 0, 0.2)';
                this.style['modal'].maxHeight = '-webkit-fill-available';
            }

            if (this.ttWidth === 'parent') {
                this.style['modal'].width = clientRect.width + 2 + 'px';
            } else if (this.ttWidth === 'fill') {
                this.style['modal'].width = '100vw';
                this.style['modal'].maxWidth = '100vw';
                this.style['modal'].left = '0';
            } else if (isNaN(Number(this.ttWidth)) && typeof this.ttWidth === 'string') {
                this.style['modal'].width = this.ttWidth;
            } else if (!isNaN(Number(this.ttWidth))) {
                this.style['modal'].width = this.ttWidth + 'px';
            }
        }
    }

    /**
     * Roughly calculates the height of the listbox.
     *
     * @returns a rouch estimation of the height of the listbox.
     */
    private getHeightOfListbox() {
        // const totalVerticalPadding = this.getListItemVerticalPaddingInRem();
        // const fontSize = Number(this.style['option'].fontSize?.replace('px', ''));
        // return (this.convertRemToPixels(totalVerticalPadding) + fontSize) * this.numberOfItems;
    }

    /**
     * Returns the vertical padding of the listbox items.
     *
     * @returns the vertical padding of the listbox items.
     */
    private getListItemVerticalPaddingInRem(): number {
        if ((window.matchMedia('(min-width: 768px)').matches && this.ttItemSize === 'auto') || this.ttItemSize === 'small') {
            return 1.2;
        } else {
            return 1.6;
        }
    }

    /**
     * Converts the given rem value to pixels.
     *
     * @param rem the value to convert to pixels.
     * @returns the given rem value as it equivelant.
     */
    private convertRemToPixels(rem: number) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    /**
     * Checks if the given data is an observable or not.
     *
     * @param data the data to check if is observable.
     * @returns true if the data is of type `Observable`, false if not.
     */
    isDataObservable(data: TType[] | Observable<TType[]>): data is Observable<TType[]> {
        if (data instanceof Observable) return true;
        return false;
    }

    ngOnInit(): void {
        if (this.isDataObservable(this.ttData)) {
            this.data$ = this.ttData;
            this.data$.subscribe((data) => {
                this.numberOfItems = data.length;

                if (this.ttRelativeElement) {
                    this.setListboxRelativePosition(this.ttRelativeElement);
                }
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.['ttStyle']) {
            this.style = this.core.setStyle({ style: this.style, ttStyle: this.ttStyle, mainElement: 'div' }) as ListboxStyle;
        }

        if (changes?.['ttData']?.currentValue && changes['ttData'].currentValue instanceof Array) {
            this.setData(changes['ttData'].currentValue);
        }

        if (changes?.['ttRelativeElement'] || changes?.['ttShow']) {
            let wait = 50;

            // allow the dom time to settle
            if (changes?.['ttRelativeElement']?.firstChange || changes?.['ttShow']?.firstChange) wait = 750;

            setTimeout(() => {
                if (this.ttRelativeElement) {
                    this.setListboxRelativePosition(this.ttRelativeElement);
                } else {
                    this.style['modal'] = {};
                }
                if (changes?.['ttShow']) {
                    this.show = changes['ttShow'].currentValue;
                }
            }, wait);

            this.show = this.ttShow;
        }

        if (changes?.['ttSelectedIndex'] && !isNaN(Number(changes?.['ttSelectedIndex'].currentValue))) {
            this.scrollListboxToSelected();
        }
    }
}

@Pipe({
    name: 'ttListboxHighlight',
    pure: false,
})
export class ListboxHighlight implements PipeTransform {
    // highlight code borrowed from angularJs ui bootstrap.
    private escapeRegexp(queryToEscape: string) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    private containsHtml(matchItem: string) {
        return /<.*>/g.test(matchItem);
    }

    private match(matchItem: string, query: string) {
        if (!this.containsHtml(matchItem)) {
            matchItem = query ? ('' + matchItem).replace(new RegExp(this.escapeRegexp(query), 'gi'), '<strong>$&</strong>') : matchItem; // Replaces the capture string with a the same string inside of a "strong" tag
        }
        return matchItem;
    }

    transform(itemName: string, filter: string): string {
        return this.match(itemName, filter);
    }
}

export interface ListboxStyle extends Style {
    modal: Partial<CSSStyleDeclaration>;
    listbox: Partial<CSSStyleDeclaration>;
    option: Partial<CSSStyleDeclaration>;
    icon: Partial<CSSStyleDeclaration>;
    selectedOption: Partial<CSSStyleDeclaration>;
}
