(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('modelLangs', {
        templateUrl: 'views/components/views/modelLangs/modelLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modelLangsService', function ($stateParams, stateService, modelLangsService) {
            let vm = this;
            let modelId = $stateParams.model_id;
            
            vm.model = {
                inputListLanguages: []
            };

            let loadModelLanguages = function () {
                modelLangsService.listModelLanguages({ model_id: modelId }).then(function (result) {
                    angular.copy(result, vm.model.inputListLanguages);
                });
            };
            
            vm.saveChanges = function () {
                modelLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadModelLanguages();
            };
        }]
    });
})();
