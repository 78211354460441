//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('dndFilter', {
//        templateUrl: 'views/components/views/dndFilter/dndFilter.template.html?v=' + module.version,
//        controllerAs: 'vm',
//        controller: ['$stateParams', 'dndFilterService', function($stateParams, dndFilterService) { 
//            var vm = this;

//            vm.filter_type = dndFilterService.filter_type;
//        }]
//    });


//})();
