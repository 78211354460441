(function() {
    'use strict';

    angular.module("imApp").factory("jobqueueDetailsService", ['$ihttp', function ($ihttp) {
        var service = {
            load: function (jobqueueKeyno) {
                return $ihttp.post({
                    method: 2299,
                    parameters: {
                        jobqueue_keyno: jobqueueKeyno
                    }
                });
            }
        }

        return service;
    }]);
})();
