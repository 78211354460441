(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productPrices', {
        templateUrl: 'views/components/views/productPrices/productPrices.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'ieScreenBlockService', 'productPricesService', 'logisticService', function ($stateParams, $q, stateService, utilityService, ieScreenBlockService, productPricesService, logisticService) {
            let vm = this;
            let prodId = $stateParams.prod_id;
            
            vm.model = {
                bgPriceType: {
                    selected: 'PRICELIST',
                    buttons: [
                        { id: 'PRICELIST', label: 'productprices_pricelists', color: 'primary', onClick: () => vm.model.bgPriceType.selected = 'PRICELIST' },
                        { id: 'PURCHPRICE', label: 'productprices_purchprice', color: 'primary', onClick: () => vm.model.bgPriceType.selected = 'PURCHPRICE' }
                    ]
                },
                priceGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('colorgrps', {}) }
                ],
                setting: {},
                selectListProductSizes: [],
                selectListPriceGroups: [],
                itemsListRelatedToPriceGroups: [],
                itemsListRelatedToProductSizes: [],
                approving: false,
                rollbacking: false
            };
            
            let loadSetting = function () {
                let deferred = $q.defer();

                productPricesService.getSetting({ prod_id: prodId }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadPriceGroups()
                    ]).then(() => {
                        loadRelatedToPriceGroups();
                        loadRelatedToProductSizes();
                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadProductSizes = function () {
                logisticService.listProductSizes({ prod_id: prodId }).then(function (result) {
                    angular.copy(result, vm.model.selectListProductSizes);
                });
            };
            
            let loadProductPriceSettings = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno) !== true) return;

                return productPricesService.getProductPriceSettings({ prodsize_keyno: vm.model.setting.prodsize_keyno }).then(function (response) {
                    vm.model.setting.pricegrp_keyno = response[0].pricegrp_keyno;

                    if (vm.model.setting.pricegrp_keyno !== '') loadPriceGroups();
                });
            };
            
            let loadPriceGroups = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno) !== true) return;

                return productPricesService.listPriceGroups({ prodsize_keyno: vm.model.setting.prodsize_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListPriceGroups);
                });
            };
            
            let loadRelatedToPriceGroups = function () {
                if (utilityService.validateParmsValue(vm.model.setting.pricegrp_keyno, true) !== true) return;

                return productPricesService.listRelatedToPriceGroups({ pricegrp_keyno: vm.model.setting.pricegrp_keyno }).then(function (result) {
                    ieScreenBlockService.stop();
                    angular.copy(result, vm.model.itemsListRelatedToPriceGroups);
                });
            };
            
            let loadRelatedToProductSizes = function () {
                if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno) !== true) return;

                return productPricesService.listRelatedToProductSizes({ prodsize_keyno: vm.model.setting.prodsize_keyno }).then(function (result) {
                    ieScreenBlockService.stop();
                    angular.copy(result, vm.model.itemsListRelatedToProductSizes);
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPriceType = function (value) {
                vm.model.bgPriceType.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'prodsize_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.prodsize_keyno) !== true) return;

                        vm.model.setting.prodsize_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.prodsize_keyno) !== true) return;

                        ieScreenBlockService.start('message');

                        loadProductPriceSettings();
                        loadRelatedToProductSizes();
                        loadRelatedToPriceGroups();
                        break;
                    case 'pricegrp_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.pricegrp_keyno) !== true) return;

                        vm.model.setting.pricegrp_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.pricegrp_keyno, true) !== true) return;

                        ieScreenBlockService.start('message');

                        loadRelatedToPriceGroups();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            vm.approveChanges = function () {
                vm.model.approving = true;

                productPricesService.setActivePrice({
                    way: 'APPROVE',
                    listitems: vm.model.itemsListRelatedToPriceGroups
                }).then(function () {
                    vm.model.approving = false;
                    ieScreenBlockService.start('message');

                    loadRelatedToPriceGroups();
                });
            };
            
            vm.rollbackChanges = function () {
                vm.model.rollbacking = true;

                productPricesService.setActivePrice({
                    way: 'ROLLBACK',
                    listitems: vm.model.itemsListRelatedToPriceGroups
                }).then(function () {
                    vm.model.rollbacking = false;
                    ieScreenBlockService.start('message');

                    loadRelatedToPriceGroups();
                });
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'price':
                        go({
                            pricegrp_keyno: vm.model.setting.pricegrp_keyno,
                            price_keyno: '0'
                        });
                        break;
                    case 'purchprice':
                        go({
                            prodsize_keyno: vm.model.setting.prodsize_keyno,
                            purchprice_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadProductSizes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
