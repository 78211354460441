(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('metaTables', {
        templateUrl: 'views/components/views/metaTables/metaTables.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'metaTablesService', function (stateService, metaTablesService) {
            const vm = this;

            vm.model = {
                itemsListMetaTables: []
            };

            let loadMetaTables = function () {
                metaTablesService.listMetaTables().then(function (result) {
                    angular.copy(result, vm.model.itemsListMetaTables);
                });
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'metatable':
                        go({ metatable_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadMetaTables();
            };
        }]
    });
})();
