(function () {
    'use strict';

    angular.module("imApp").factory("equipmentGrpService", ['$ihttp', function ($ihttp) {
        var service = {
            getEquipmentGrp: function (equipmentGrpId) {
                return $ihttp.post({
                    method: 2049,
                    parameters: {
                        equipmentgrp_id: equipmentGrpId
                    }
                });
            },
            saveObj: function (editEquipmentGroup) {
                return $ihttp.post({
                    method: 2050,
                    parameters: editEquipmentGroup
                });
            }
        };

        return service;
    }]);
})();