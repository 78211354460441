<tt-form-field-base
    [ttFieldId]="id.input"
    [ttLabel]="ttLabel"
    [ttLabelView]="ttLabelView"
    [ttTranslateLabel]="ttTranslateLabel"
    [ttSublabel]="ttSublabel"
    [ttTranslateSublabel]="ttTranslateSublabel"
    [ttRequired]="ttRequired"
    [ttReadonly]="ttReadonly"
    [ttDisabled]="ttDisabled"
    [ttStyle]="ttStyle"
    ttInputType="file-uploader"
>
    <div style="display: flex; flex-direction: column; width: 100%; height: fit-content; gap: 0.4rem">
        <label *ngIf="!ttDropZone" class="tt-button tt-button--primary col-xs-2" style="padding: 0.6rem 0rem; text-align: center; margin: 0; min-width: 16rem">
            {{ filetranslations["select_files"] }}
            <input type="file" [hidden]="true" style="display: none" (change)="onFileChanged($event)" [multiple]="ttMultiple" [accept]="ttAccept" />
        </label>
        <div
            *ngIf="ttDropZone"
            class="tt-file-uploader__drop-zone"
            (dragover)="onDragover($event)"
            (dragleave)="onDragleave($event)"
            (dragend)="onDragleave($event)"
            (drop)="onFilesDropped($event)"
            [ngStyle]="style['dropzone']"
        >
            <label class="tt-file-uploader__drop-label" [ngStyle]="style['dropzone_label']">
                {{ filetranslations["drag_and_drop_or_click"] }}
                <input #inputRef type="file" [hidden]="true" style="display: none" (change)="onFileChanged($event)" [multiple]="ttMultiple" [accept]="ttAccept" />
            </label>
        </div>
        <ul class="tt-file-uploader__list">
            <li *ngFor="let file of files" class="tt-file-uploader__file" [matTooltip]="file.name" matTooltipShowDelay="1000">
                <span style="flex: 1; display: inline-block; padding: 0.7rem 1.4rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{ file.name }}</span>
                <button class="tt-button tt-file-uploader__remove" (click)="removeFile(file)" style="color: var(--tt-body-text-color)">
                    <span class="far fa-times"></span>
                </button>
            </li>
        </ul>
    </div>
</tt-form-field-base>
