(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCrewsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCrewsGet: function (argtype, argvalue) {
                return p2DataTaskService.call(1627, {
                    argtype: argtype,
                    argvalue: argvalue
                });
            },
            loadSrCrewsList: function (settingCrew) {
                return p2DataTaskService.call(1626, settingCrew);
            }
        };

        return service;
    }]);
})();