(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("sopsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSopsList: function () {
                return p2DataTaskService.call(1838, {});
            }
        };

        return service;
    }]);
})();