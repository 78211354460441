(function () {
    'use strict';

    angular.module('imApp').filter('noEmpty', ['utilityService', function (us) {
        return function (items, fMonth, fWeek, fDay) {
            var filtered = [];;
                
            angular.forEach(items, function (month) {
                angular.forEach(month, function (week) {
                    angular.forEach(week, function (day) {
                        if (us.objectLength(day.codes) > 0) {
                            var add = false;

                            if (fDay != '') {
                                add = false;
                                if (day.systemdate.trim() == fDay.trim()){
                                    add = true;
                                }
                            } else if (fWeek != '') {
                                add = false;
                                if (day.weekno.trim() == fWeek.trim()){
                                    add = true;
                                }
                            }  else if (fMonth != '') {
                                add = false;
                                if (day.month_no == fMonth){
                                    add = true;
                                }
                            } else {
                                add = true;
                            }

                            //Add day record
                            if (add){
                                filtered.push(day);
                            }
                        }
                    });
                });
            });                    
            return filtered;
        };
    }]);
})();    