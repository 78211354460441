(function () {
    'use strict';

    angular.module("imApp").factory("ttDynamicGridManager", ['$q', '$ihttp', '$timeout', function ($q, $ihttp, $timeout) {
        let cache = {};

        var service = {
            // Returns a list of grids associated with the assigned routeId.
            list: function (routeId, parent_keyno, dynamicproperty_keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'gridlist',
                        route_id: routeId || '',
                        parent_keyno: parent_keyno || 0,
                        dynamicproperty_keyno: dynamicproperty_keyno || 0
                    }
                });
            },

            details: function (tt_dynamic_keyno_grid) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'dynamicdetails',
                        keyno: tt_dynamic_keyno_grid
                    }
                });
            },

            getGrid: function (instanceId, gridName) {
                if (angular.isUndefined(cache[instanceId])) return null;
                if (angular.isUndefined(cache[instanceId][gridName])) return null;

                return cache[instanceId][gridName];
            },

            register: function (instanceId, gridName, grid) {
                ensureIsDefined(instanceId, gridName);

                // return if grid already registered.
                if (cache[instanceId][gridName].grid !== null) return;

                grid.optionfunc = optionfunc;

                cache[instanceId][gridName].grid = grid;
            },

            call: function (instanceId, gridName, func) {
                ensureIsDefined(instanceId, gridName);

                var deferred = $q.defer();

                let delayed = function (parms) {
                    if (cache[instanceId][gridName].grid === null || cache[instanceId][gridName].grid.gridfunc === null) {
                        $timeout(delayed, 50, true, parms)

                        return;
                    }

                    if (angular.isObject(cache[instanceId][gridName].grid.gridfunc) !== true) return;
                    if (angular.isFunction(cache[instanceId][gridName].grid.gridfunc[func]) !== true) return;

                    var result = cache[instanceId][gridName].grid.gridfunc[func].apply({}, parms);

                    if (result !== null && angular.isObject(result) && angular.isFunction(result.then)) {
                        result.then(function (response) {
                            deferred.resolve(response);
                        }, function (error) {
                            deferred.reject(error);
                        });
                    } else {
                        deferred.resolve(result);
                    }
                }

                delayed(Array.from(arguments).slice(3));

                return deferred.promise;
            },

            read: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'read');
            },

            refresh: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'refresh');
            },

            save: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'save');
            },

            isReady: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'isReady');
            },

            hasRows: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'hasRows');
            },

            selectRow: function (instanceId, gridName, index) {
                return service.call(instanceId, gridName, 'selectRow', index);
            },

            getSelectedRow: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'getSelectedRow');
            },

            getSelectedRows: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'getSelectedRows');
            },

            getRowAt: function (instanceId, gridName, index) {
                return service.call(instanceId, gridName, 'getRowAt', index);
            },

            getDirtyRows: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'getDirtyRows');
            },

            getAllRows: function (instanceId, gridName) {
                return service.call(instanceId, gridName, 'getAllRows');
            },

            onRowSelected: function (instanceId, gridName, callback) {
                if (angular.isString(instanceId) !== true) return false;
                if (angular.isString(gridName) !== true) return false;
                if (angular.isFunction(callback) !== true) return false;

                ensureIsDefined(instanceId, gridName);

                cache[instanceId][gridName].events.onRowSelected.push(callback);

                return true;
            }
        };

        function ensureIsDefined(instanceId, gridName) {
            if (angular.isString(instanceId) !== true) throw new Error("ttDynamicGridManager.ensureExists: instanceId is not a string.");
            if (angular.isString(gridName) !== true) throw new Error("ttDynamicGridManager.ensureExists: gridName is not a string.");

            if (angular.isUndefined(cache[instanceId])) {
                cache[instanceId] = {}
            }

            if (angular.isUndefined(cache[instanceId][gridName])) {
                cache[instanceId][gridName] = {
                    instanceId: instanceId,
                    name: gridName,
                    grid: null,
                    events: {
                        onRowSelected: []
                    }
                }
            }
        }

        function onRowSelected(options, dataItem) {
            if (angular.isObject(options) !== true) return;
            if (angular.isObject(options.dynamic) !== true) return;
            if (angular.isUndefined(dataItem)) return;

            if (angular.isUndefined(cache[options.dynamic.instanceId])) return;
            if (angular.isUndefined(cache[options.dynamic.instanceId][options.dynamic.name])) return;

            angular.forEach(cache[options.dynamic.instanceId][options.dynamic.name].events.onRowSelected, function (e) {
                e(dataItem);
            });
        };

        function optionfunc(event) {
            switch (event.data.func) {
                case 'CellClickHandler':
                    onRowSelected(event.data.options, event.data.clickedCell.dataItem);
                    break;
            }
        }

        return service;
    }]);
})();
