(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockTransferLoadItem', {
        templateUrl: 'views/components/views/stockTransferLoadItem/stockTransferLoadItem.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$element', '$timeout', 'stateService', 'utilityService', 'modalService', 'stockTransferLoadItemService', 'logisticService', function ($stateParams, $q, $element, $timeout, stateService, utilityService, modalService, stockTransferLoadItemService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let stockTransferKeyno = $stateParams.stocktransfer_keyno;
            let stockKeyno = $stateParams.stock_keyno;
            let stockBatchKeyno = $stateParams.stockbatch_keyno;
            let stockLocationKeynoFrom = $stateParams.stocklocation_keyno_from;
            let quantity = $stateParams.quantity;
            
            vm.model = {
                mId: 'stockTransferLoadItemCtrl',
                stockLocationFromNameButtons: [
                    {
                        id: 'barcodesearchauto',
                        icon: 'glyphicon glyphicon-barcode',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('scanstocklocation', {
                            argtype: 'stocktransfer_keyno',
                            argvalue: vm.model.edit.stocktransfer_keyno,
                            functionname: 'load',
                            stock_keyno: vm.model.edit.stock_keyno,
                            autoscan: '1'
                        })
                    },
                    {
                        id: 'barcodesearchmanual',
                        icon: 'far fa-solid fa-keyboard',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('scanstocklocation', {
                            argtype: 'stocktransfer_keyno',
                            argvalue: vm.model.edit.stocktransfer_keyno,
                            functionname: 'load',
                            stock_keyno: vm.model.edit.stock_keyno,
                            autoscan: '0'
                        })
                    },
                    {
                        id: 'locationsearch',
                        icon: 'glyphicon glyphicon-search',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('selectstocklocation', { stockplace_id: vm.model.edit.stockplace_id })
                    }
                ],
                stockLocationQuantityButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('stockbatchonlocation', {
                            stock_keyno: vm.model.edit.stock_keyno,
                            stocklocation_keyno: vm.model.edit.stocklocation_keyno_from,
                            functionname: 'stock'
                        })
                    }
                ],
                locked: false,
                hmax: '100px',
                edit: {},
                saveStockTransferLoadItem: {},
                selectListCorrectionCodes: [],
                selectListStockOnLocations: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALLS

            let loadEdit = function () {
                let deferred = $q.defer();

                stockTransferLoadItemService.getEdit({
                    stocktransfer_keyno: stockTransferKeyno,
                    stock_keyno: stockKeyno,
                    stockbatch_keyno: stockBatchKeyno,
                    stocklocation_keyno_from: stockLocationKeynoFrom,
                    quantity: quantity
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadStockOnLocations()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCorrectionCodes = function () {
                stockTransferLoadItemService.listCorrectionCodes().then(function (result) {
                    angular.copy(result, vm.model.selectListCorrectionCodes);
                });
            };
            
            let loadStockOnLocations = function () {
                if (utilityService.validateParmsValue(vm.model.edit.stock_keyno) !== true) return;
                
                return logisticService.listStockOnLocations({
                    stock_keyno: vm.model.edit.stock_keyno,
                    functionname: 'stock'
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockOnLocations);
                });
            };
            
            // #endregion LOAD PROCEDURE CALLS
            
            // #region SCAN BUTTON FUNCTION
    
            vm.scanBarcode = function () {
                if (angular.isDefined(vm.model.edit.quantity_picked) && angular.isDefined(vm.model.edit.barcode)/* && vm.model.edit.barcode !== ''*/) {
                    stockTransferLoadItemService.scanStockTransferLoadItem(vm.model.edit).then(function (data) {
                        if (data.length > 0) {
                            vm.model.edit.barcode = '';
                        }
                        
                        if (data[0].scanresulttype === 'stocklocation_keyno') {
                            vm.model.edit.stocklocation_keyno_from = data[0].stocklocation_keyno;
                            vm.model.edit.stocklocation_from_name = data[0].stocklocation_name;
                            vm.model.edit.stocklocation_quantity = data[0].quantity_max;
                        } else if (data[0].scanresulttype === 'stock_keyno') {
                            vm.model.edit.stocklocation_from_name = data[0].stocklocation_name;
                            vm.model.edit.stock_keyno = data[0].stock_keyno;
                            vm.model.edit.prod_id = data[0].prod_id;
                            vm.model.edit.prodname_long = data[0].prodname_long;
                            vm.model.edit.stocklocation_quantity = data[0].quantity_max;
                        }

                        loadStockOnLocations();
                        recalcStockQuantityCorrected();
                    });
                }
            };
    
            vm.scanBarcodeManual = function () {
                var wait = function () {
                    var val = $element.find('#' + vm.model.mId)[0].value;
    
                    if (angular.isString(val) && val !== null && val.length > 0) {
                        vm.model.edit.barcode = val;
                        $element.find('#' + vm.model.mId)[0].value = null;
                        vm.scanBarcode();
                        return;
                    }
    
                    $timeout(wait, 250);
                };
    
                wait();
    
                window.location = "readbarcode://" + vm.model.mId;
            };
    
            // #endregion SCAN BUTTON FUNCTION
    
            // #region RECALCULATION FUNCTIONS
            
            var recalcStockQuantityCorrected = function () {
                vm.model.edit.stock_quantity_corrected = utilityService.parseNumber(vm.model.edit.stocklocation_quantity) - utilityService.parseNumber(vm.model.edit.quantity_picked);
            };
    
            // #endregion RECALCULATION FUNCTIONS
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'stockonlocation':
                        go({
                            stock_keyno: vm.model.edit.stock_keyno,
                            functionname: 'stock'
                        });
                        break;
                    case 'stockbatchonlocation':
                        go({
                            stock_keyno: '0',
                            stocklocation_keyno: vm.model.edit.stocklocation_keyno_from,
                            functionname: 'stock'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region SAVE BUTTON FUNCTION
            
    		vm.saveStockTransferLoadItem = function () {
                vm.model.locked = true;
    
                stockTransferLoadItemService.saveStockTransferLoadItem(vm.model.edit).then(function (data) {
                    vm.model.saveStockTransferLoadItem = angular.copy(data[0]);
                    
                    if (vm.model.saveStockTransferLoadItem.errorcode === '-1') {
                        modalService.show({
                            type: 'warning',
                            title: 'Feilmelding',
                            message: vm.model.saveStockTransferLoadItem.errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else if (vm.model.saveStockTransferLoadItem.errorcode === '0') {
                        stateService.back();
                    }
    			});
            };
    
            // #endregion SAVE BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'stocklocation_keyno_from':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stocklocation_keyno_from) !== true) return;

                        vm.model.edit.stocklocation_keyno_from = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stocklocation_keyno_from) !== true) return;

                        vm.scanBarcode();
                        break;
                    case 'quantity_picked':
                        if (utilityService.validateWatchValue(value, vm.model.edit.quantity_picked) !== true) return;

                        vm.model.edit.quantity_picked = value;

                        if (utilityService.validateParmsValue(vm.model.edit.quantity_picked) !== true) return;

                        recalcStockQuantityCorrected();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadCorrectionCodes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
