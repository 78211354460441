(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('findStock', {
        templateUrl: 'views/components/views/findStock/findStock.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'findStockService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, findStockService, logisticService, rememberService) {
            const vm = this;
            
            let variableNames = {
                barcode: '',
                businessco_no: '',
                stockplace_id: ''
            };

            vm.model = {
                bgFindStockDisplay: {
                    selected: 'COUNTING',
                    buttons: [
                        { id: 'COUNTING', label: 'bg_findstockdisplay_counting', color: 'primary', onClick: () => vm.model.bgFindStockDisplay.selected = 'COUNTING' },
                        { id: 'OTHER', label: 'bg_findstockdisplay_other', color: 'primary', onClick: () => vm.model.bgFindStockDisplay.selected = 'OTHER' }
                    ]
                },
                stockValueButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockget', { stock_keyno: vm.model.scanResponse.stock_keyno }) }
                ],
                exAssignedButtons: [
                    {
                        id: 'goto',
                        icon: 'fa fa-solid fa-chevron-right',
                        color: 'primary',
                        type: 'primary',
                        onClick: () => stateService.go('stockcorrection', {
                            stock_keyno: vm.model.scanResponse.stock_keyno,
                            stocklocation_keyno: vm.model.scanResponse.stocklocation_keyno
                        })
                    }
                ],
                stockIncomingButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockincoming', { stock_keyno: vm.model.scanResponse.stock_keyno }) }
                ],
                stockOutgoingButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('stockoutgoing', { stock_keyno: vm.model.scanResponse.stock_keyno }) }
                ],
                eanListButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('producteans', { prod_id: vm.model.scanResponse.prod_id }) }
                ],
                setting: {},
                scanningResponse: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                scanning: false,
                lockedConfirm: false
            };
            
            let loadSetting = function () {
                const deferred = $q.defer();

                findStockService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => {
                        loadScanResponse('setting');

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };

            let loadScanResponse = function (id) {
                switch (id) {
                    case 'setting':
                        if (utilityService.validateParmsValue(vm.model.setting.barcode) !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        return findStockService.scanStock({
                            barcode: vm.model.setting.barcode,
                            stockplace_id: vm.model.setting.stockplace_id
                        }).then(function (response) {
                            angular.copy(response[0], vm.model.scanResponse);
                        });
                        break;
                    case 'barcode':
                        if (utilityService.validateParmsValue(vm.model.setting.barcode) !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        return findStockService.scanStock({
                            barcode: vm.model.setting.barcode,
                            stockplace_id: vm.model.setting.stockplace_id
                        }).then(function (response) {
                            vm.model.scanning = false;
                            angular.copy(response[0], vm.model.scanResponse);
                            reset('barcode');

                            if (vm.model.scanResponse.gotowebpage === '1') stateService.go(vm.model.scanResponse.webpagename, vm.model.scanResponse.parms);
                        });
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting.barcode) !== true) return;
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                        return findStockService.scanStock({
                            barcode: vm.model.setting.barcode,
                            stockplace_id: vm.model.setting.stockplace_id
                        }).then(function (response) {
                            angular.copy(response[0], vm.model.scanResponse);

                            if (vm.model.scanResponse.gotowebpage === '1') stateService.go(vm.model.scanResponse.webpagename, vm.model.scanResponse.parms);
                        });
                        break;
                    default:
                        break;
                }
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'barcode':
                    	case 'businessco_no':
                        case 'stockplace_id':
                    		variableNames[key] = stateService.getCurrentName() + '.' + key;
                    		break;
                    	default:
                    		variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                    		break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'barcode':
                    case 'businessco_no':
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'barcode':
                        vm.model.setting.barcode = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            vm.scanValues = function () {
                vm.model.scanning = true;
                rememberFunc('barcode');
                loadScanResponse('barcode');
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgFindStockDisplay = function (value) {
                vm.model.bgFindStockDisplay.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION
            
            vm.confirmValues = function () {
                vm.model.lockedConfirm = true;
                findStockService.confirmStockValue(vm.model.scanResponse).then(function () {
                    vm.model.lockedConfirm = false;
                }, function () {
                    vm.model.lockedConfirm = false;
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStockPlaces();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.stockplace_id) !== true) return;

                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        loadScanResponse('stockplace_id');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
