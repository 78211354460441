(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('requisitionPlanning', {
        templateUrl: 'views/components/views/requisitionPlanning/requisitionPlanning.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'requisitionPlanningService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, requisitionPlanningService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            let variableNames = {
                bg_listtype: '',
                bg_orderline: ''
            };

            vm.model = {
                bgListType: {
                    selected: 'STOCK',
                    buttons: [
                        { id: 'STOCK', label: 'bg_listtype_stock', color: 'primary', onClick: () => setListTypeView('STOCK') },
                        { id: 'ORDERLINE', label: 'bg_listtype_orderline', color: 'primary', onClick: () => setListTypeView('ORDERLINE') }
                    ]
                },
                bgOrderLine: {
                    selected: 'AUTO',
                    buttons: [
                        { id: 'AUTO', label: 'bg_orderline_auto', color: 'primary', onClick: () => setOrderLineView('AUTO') },
                        { id: 'MANUAL', label: 'bg_orderline_manual', color: 'primary', onClick: () => setOrderLineView('MANUAL') }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                selectListStockPlaces: [],
                itemsListStocks: [],
                itemsListAutos: [],
                itemsListManuals: [],
                lockedApprove: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            let setListTypeView = function (id) {
                vm.model.bgListType.selected = vm.model.setting.bg_listtype = id;
            };

            let setOrderLineView = function (id) {
                vm.model.bgOrderLine.selected = vm.model.setting.bg_orderline = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region PROCEDURE FUNCTIONS
            
            let loadSetting = function () {
                let deferred = $q.defer();

                requisitionPlanningService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    setListTypeView(vm.model.setting.bg_listtype);
                    setOrderLineView(vm.model.setting.bg_orderline);

                    $q.all([
                        loadStockPlaces()
                    ]).then(() => {
                        loadRequisitionStocks();
                        loadRequisitionOrderLines();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };
            
            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                
                return logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListStockPlaces);
                });
            };
            
            let loadRequisitionStocks = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.requisitionplanning_date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.only_shortcoming) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_listtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_orderline) !== true) return;

                vm.model.itemsListStocks = [];

                return requisitionPlanningService.listRequisitionStocks(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListStocks);
                });
            };
            
            let loadRequisitionOrderLines = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.requisitionplanning_date_tom) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.only_shortcoming) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_listtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.bg_orderline) !== true) return;

                vm.model.itemsListAutos = [];
                vm.model.itemsListManuals = [];
                
                return requisitionPlanningService.listRequisitionOrderLines(vm.model.setting).then(function (result) {
                    var d = [];
                    angular.copy(result, d);
                    
                    angular.forEach(d, function (item) {
                        var manualItem = Object.assign({}, item);
                        var autoItem = Object.assign({}, item);
                        
                        manualItem.item_state = 'olmrequisition';
                        manualItem.item_parms = manualItem.olm_keyno;
                        manualItem.item_path = 'olmrequisition/' + manualItem.olm_keyno;
                        vm.model.itemsListManuals.push(manualItem);
                        
                        if (autoItem.requisition_no === '0') {
                            autoItem.item_state = 'orderhead';
                            autoItem.item_path = 'orderhead/' + autoItem.item_parms;
    
                            vm.model.itemsListAutos.push(autoItem);
                        }
                    });
                });
            };

            // #endregion PROCEDURE FUNCTIONS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'bg_listtype':
                    case 'bg_orderline':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            vm.approveChanges = function () {
                vm.model.lockedApprove = true;

                requisitionPlanningService.approvePlan({ records: vm.model.itemsListAutos }).then(function () {
                    vm.model.lockedApprove = false;

                    loadRequisitionStocks();
                    loadRequisitionOrderLines();
                });
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        loadStockPlaces();
                        loadRequisitionStocks();
                        loadRequisitionOrderLines();
                        break;
                    case 'requisitionplanning_date_tom':
                    case 'only_shortcoming':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        loadRequisitionStocks();
                        loadRequisitionOrderLines();
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        loadRequisitionStocks();
                        loadRequisitionOrderLines();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgListType = function (value) {
                vm.model.bgListType.selected = value;
                vm.model.setting.bg_listtype = vm.model.bgListType.selected;

                rememberFunc('bg_listtype');
            };

            vm.selectBgOrderLine = function (value) {
                vm.model.bgOrderLine.selected = value;
                vm.model.setting.bg_orderline = vm.model.bgOrderLine.selected;

                rememberFunc('bg_orderline');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
