(function () {
    'use strict';

    angular.module("imApp").factory("usergroupSettingEditService", ['$q', '$ihttp', 'userService', 'layoutService', function ($q, $ihttp, userService, layoutService) {
        let service = {
            getEdit: function (parms) {
                return $ihttp.post({
                    method: 38,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                const deferred = $q.defer();

                $ihttp.post({
                    method: 39,
                    parameters: edit
                }).then(function (response) {
                    userService.init().then(function () {
                        layoutService.onUsergroupSettingChanged();

                        deferred.resolve(response[0]);
                    });
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
