(function() {
    'use strict';

	angular.module("imApp").factory("stockPlanningProductionService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			// NEW NAME?
			loadProducts: function (parms) {
				return p2DataTaskService.call(2057, parms);
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			updateColumnResize: function (parms) {
				return p2DataTaskService.call(1457, parms);
			},
			// NEW NAME?
			createPurchase: function (parms) {
				console.log('Saving purchase: ');
				console.dir(parms);
				return p2DataTaskService.call(2058, parms);
			},
			loadPageSettings: function () {
				return p2DataTaskService.call(2056, {});
			},
			savePageSetting: function (parms) {
				return p2DataTaskService.call(616, parms);
			},
		}

        return service;
    }]);
})();
