(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('consignmentDeliveryConfirm', {
        templateUrl: 'views/components/views/consignmentDeliveryConfirm/consignmentDeliveryConfirm.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'base64', 'consignmentDeliveryConfirmService', 'stateService', 'modalService', function ($stateParams, $timeout, base64, consignmentDeliveryConfirmService, stateService, modalService) {
            var vm = this;


            function getActivityInfo() {
                console.log('getActivityInfo');
                consignmentDeliveryConfirmService.get($stateParams.custact_no, $stateParams.order_order_no).then(function (data) {
                    angular.copy(data[0], vm.model.activity);
                    console.log('vm.model.activity');
                    console.dir(vm.model.activity);
                });
            }

            function getSettings() {
                consignmentDeliveryConfirmService.getSettings($stateParams.checklistdata_keyno).then(function (data) {
                    angular.copy(data[0], vm.model.settings);

                    if (vm.model.settings.report_html_base64 !== '') {
                        vm.model.settings.report_html = base64.urldecode(vm.model.settings.report_html_base64);
                    }
                });
            }

            function getChecklists() {
                consignmentDeliveryConfirmService.getChecklists($stateParams.checklistdata_keyno).then(function () {
                    vm.model.checklistdata = consignmentDeliveryConfirmService.data;
                });
            }

            function checkListDataOnChange(index) {
                var changeTimer = null;
                if (vm.model.updatingDataFromResponse) return;
                $timeout(function () {
                    checkListDataService.checkListDataValidate(index);
                }, 50);

                var cldList = vm.model.p2_checklistdata_get.checklistitems;
                if (angular.isUndefined(cldList) || angular.isUndefined(cldList[index])) return;

                var cldItem = cldList[index];
                if (angular.isUndefined(cldItem.cv) || cldItem.cv !== '1') return;

                var done = false;
                var timerMS = 0;

                switch (cldItem.elementtype) {
                    case 'DT':
                    case 'CL':
                    case 'CB':
                    case 'CS':
                        timerMS = 20;
                        break;
                    default:
                        timerMS = 500;
                }

                if (angular.isDefined(changeTimer)) {
                    done = false;
                    $timeout.cancel(changeTimer);
                }

                var finishedTyping = function () {
                    if (done) {
                        var cldConnectedData = [];

                        for (var c = 0; c < cldItem.cvs.length; c++) {
                            for (var d = 0; d < cldList.length; d++) {
                                if (cldItem.cvs[c].clik === cldList[d].checklistitem_keyno) {
                                    cldConnectedData.push({ cii: cldList[d].checklistitem_keyno, civ: cldList[d].itemvalue });
                                    break;
                                }
                            }
                        }

                        var cldCompactItem = { item_id: cldItem.checklistitem_keyno, item_value: cldItem.itemvalue, cvd: cldConnectedData }
                        var updateData = [];

                        checkListDataService.computeValidation(cldCompactItem).then(function (data) {
                            console.dir('Returndata: ');
                            console.dir(data);

                            if (data.errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data.errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            }

                            if (angular.isDefined(data.outputresult)) {
                                vm.model.updatingDataFromResponse = true;
                                updateData = data.outputresult;
                                for (var u = 0; u < updateData.length; u++) {
                                    for (var v = 0; v < cldList.length; v++) {
                                        if (updateData[u].clik === cldList[v].checklistitem_keyno && angular.isDefined(updateData[u].cliv)) {
                                            cldList[v].itemvalue = updateData[u].cliv;
                                            checkListDataService.checkListDataValidate(v);
                                            break;
                                        }
                                    }
                                }
                                vm.model.updatingDataFromResponse = false;
                            }
                        });
                        return;
                    }

                    done = true;

                    changeTimer = $timeout(finishedTyping, timerMS);
                };

                finishedTyping();
            }

            function handleSaveAndConfirm() {
                vm.model.lockedSave = true;

                consignmentDeliveryConfirmService.saveAndConfirm().then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        //stateService.back();
                        consignmentDeliveryConfirmService.get($stateParams.custact_no, $stateParams.order_order_no).then(function (data) {
                            stateService.go('consignmentdelivery',{});
                        });
                        vm.model.lockedSave = false;
                    }
                });
            }

            function handlePictureClick() {
                stateService.go('pictures', {
                    argtype: 'custact_no',
                    argvalue: $stateParams.custact_no
                });
            }
            

            function handleSaveAndConfirmWithDeviation() {
                vm.model.lockedSave = true;

                consignmentDeliveryConfirmService.saveAndConfirmWithDeviation().then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        //stateService.back();
                        consignmentDeliveryConfirmService.get($stateParams.custact_no, $stateParams.order_order_no).then(function (data) {
                            stateService.go('consignmentdelivery',{});
                        });                        
                        vm.model.lockedSave = false;
                    }
                });
            }

            vm.model = {
                updatingDataFromResponse: false,
                activity: {
                    heading: '',
                    checklist_keyno: 0,
                    checklistdata_keyno: 0,
                    is_confirmed: 0
                },
                settings: {},
                checklistdata: [],
                isLockedConfirm: false,
                isLockedConfirmDeviation: false,
                checkListDataOnChange: checkListDataOnChange,
                handleSaveAndConfirm: handleSaveAndConfirm,
                handleSaveAndConfirmWithDeviation: handleSaveAndConfirmWithDeviation
            };

            vm.handlePictureClick = handlePictureClick;
            
            getActivityInfo();
            getSettings();
            getChecklists();

            vm.goBack = function(){
                stateService.back();
            }
        }]
    });
})();
