(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prodPrice', {
        templateUrl: 'views/components/views/prodPrice.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'prodPriceService', 'stateService', 'watcherFactory', function ($stateParams, prodPriceService, stateService, watcherFactory) {
            var vm = this;

            var watcher = watcherFactory.$create(vm);

            // #region VARIABLES & DEFINITIONS

            //vm.gridReady = false;

            //vm.seasonyears = {
            //    isReady: false,
            //    rememberId: 'w_prodPrice.seasonyears',
            //    field: '',
            //    list: []
            //};

            vm.grid = {
                dataTask: {
                    rememberId: 'w_prodPrice',       // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2336,
                        parameters: { poh_keyno: $stateParams.poh_keyno }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'ediTranspReturn', text: 'tt_edi_transporter_return', func: function (data) { ediTranspReturn(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'planningReady', text: 'tt_po_sp_planning_ready', func: function (data) { planningReady(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                            //{ name: 'setProdplace', text: 'tt_po_sp_set_prodplace', func: function (data) { setProdplace(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            { name: 'goToShipment', text: '', func: function (data) { goToShipment(data); }, icon: 'far-truck-container', disabled: function (data) { return goToShipmentDisability(data); } },
                            //{ name: 'goToPoh', text: '', func: function (data) { goToPoh(data); }, icon: 'far-industry', disabled: function (data) { return goToPohDisability(data); } }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            var optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region UTILITY FUNCTIONS

            //var getIsSelectedItemsParm = function (key) {
            //    var dirtyItems = vm.grid.gridfunc.getDirtyRows();
            //    var isSelectedItems = [];

            //    if (angular.isUndefined(key)) key = 'shipmentcontainer_keyno';

            //    angular.forEach(dirtyItems, function (item) {
            //        if (angular.isDefined(item) && item.is_selected === true && angular.isDefined(item[key])) isSelectedItems.push(item[key]);
            //    });

            //    return isSelectedItems;
            //};

            //var hasDirty = function (data) {
            //    var parmItems = getIsSelectedItemsParm();

            //    if (parmItems.length > 0) return false;

            //    return true;
            //};

            var goTo = function (state, parms) {
                if (angular.isUndefined(state) || state === null || state === '') return;
                if (angular.isUndefined(parms) || parms === null || parms === '') return;

                stateService.go(state, parms);
            };

            // #endregion UTILITY FUNCTIONS

            // #region TOOLBAR BUTTONFUNC

            //var planningReady = function () {
            //    var parmItems = getIsSelectedItemsParm('poh_keyno');

            //    if (parmItems.length > 0) {
            //        poShipmentPlanningService.setPlanningReady({ items: parmItems }).then(function (result) {
            //            if (result[0].errorcode === '0') {
            //                vm.grid.gridfunc.rebind();
            //            }
            //        });
            //    }
            //};

            //var setProdplace = function () {
            //    var parmItems = getIsSelectedItemsParm('poh_keyno');

            //    if (parmItems.length > 0) {
            //        poShipmentPlanningService.getProdplaces().then(function (data) {
            //            vm.grid.gridfunc.callPopupTable(data).then(function (value) {
            //                if (value !== null) {
            //                    poShipmentPlanningService.setProdplace({ prodplace_id: value.prodplace_id, items: parmItems }).then(function (result) {
            //                        if (result[0].errorcode === '0') {
            //                            vm.grid.gridfunc.rebind();
            //                        }
            //                    });
            //                }
            //            });
            //        });
            //    }
            //};

            // #endregion TOOLBAR BUTTONFUNC

            // #region ROW BUTTONFUNC

            var goToShipment = function (data) {
                console.log('Test run successfully');
                console.dir(data);

                goTo('shipment', data.shipmentcontainer_keyno);
            };

            var goToShipmentDisability = function (data) {
                if (angular.isUndefined(data) || angular.isUndefined(data.shipmentcontainer_keyno) || data.shipmentcontainer_keyno === null || data.shipmentcontainer_keyno === '') return true;

                return false;
            };

            //var goToPoh = function (data) {
            //    console.log('Test run successfully');
            //    console.dir(data);

            //    goTo('poh', data.poh_keyno);
            //};

            //var goToPohDisability = function (data) {
            //    if (angular.isUndefined(data) || angular.isUndefined(data.poh_keyno) || data.poh_keyno === null || data.poh_keyno === '') return true;

            //    return false;
            //};

            // #endregion ROW BUTTONFUNC

            // #region LOAD PROCEDURE CALLS

            angular.element(document).ready(function () {
                //poShipmentPlanningService.getRemember(null, vm.seasonyears.rememberId).then(function (value) {
                //    if (angular.isDefined(value) && angular.isDefined(value[0]) && angular.isDefined(value[0].variablevalue)) {
                //        if (value[0].variablevalue !== '') vm.seasonyears.field = angular.fromJson(value[0].variablevalue);
                //    }

                //    poShipmentPlanningService.getSeasonYearList().then(function (data) {
                //        //console.dir(data);
                //        angular.copy(data, vm.seasonyears.list);
                //        if (vm.seasonyears.list.length > 0 && vm.seasonyears.field === '') vm.seasonyears.field = vm.seasonyears.list[0].seasonyear_id;

                //        if (vm.seasonyears.field.length > 0) vm.grid.dataTask.loadData.parameters = { seasonyear_id: vm.seasonyears.field };

                //        vm.gridReady = true;
                //    });
                //});
            });

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            //var rememberFunc = function (parm) {
            //    poShipmentPlanningService.remember(null, vm.seasonyears.rememberId, vm.seasonyears.field);
            //};

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region WATCH FUNCTIONS

            //watcher.$watch(function () { return vm.seasonyears.field; }, function (newValue, oldValue) {
            //    if (vm.gridReady === false) return;
            //    if (angular.isUndefined(newValue) || newValue === null || newValue === '' || newValue === oldValue) return;

            //    if (vm.seasonyears.field.length > 0) vm.grid.dataTask.loadData.parameters = { seasonyear_id: vm.seasonyears.field };

            //    if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();

            //    rememberFunc();
            //});

            // #endregion WATCHFUNCTIONS
        }]
    });
})();
