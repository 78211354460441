(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttSelect', {
        templateUrl: 'views/components/directives/ttSelect/ttSelect.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttLabel: '@',
            ttLabelView: '@',       // the position of the label - 'top', 'side', 'auto', 'none', 'hidden' - null or undefined indicates auto. - JLR 20230622
            ttHideLabel: '@',
            ttHideArrow: '@',
            ttDisabled: '<',
            ttModel: '<',
            ttChange: '&',
            ttChangeArgs: '<',
            ttOnBlur: '&',
            ttData: '<',
            ttDataId: '@',
            ttDataName: '@',
            ttItemId: '@',
            ttRequired: '@',
            ttReadonly: '@',
            ttTranslate: '@',       // JLR 20230115 'true' or 'false', default is true. Translates the label.
            ttInvalid: '<',         // BJS 20221109
            ttStyle: '<'            // RHE 20230321
        },
        controller: ['$element', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($element, layoutService, eventService, translateService, us, ttDirectivesService) {
            var vm = this;

            var onDestroy = [];

            vm.isDisabled = false;
            vm.required = false;
            vm.hideLabel = false;
            vm.viewValue = '';

            vm.id = {
                select: uuid()
            };


            vm.style = {
                base: {},
                label: {},
                select: {},
                labelAlwaysOnTop: false     // Deprecated, use ttLabelView instead. - JLR 20230622
            };

            vm.class = {
                base: '',
                label: '',
                input: '',
                select: ''
            };

            vm.translations = {
                ttLabel: ''
            };

            let setClasses = (labelAlwaysOnTop) => vm.class.base = ttDirectivesService.getBaseClasses({ labelAlwaysOnTop: labelAlwaysOnTop, labelView: vm.ttLabelView, hideLabel: vm.hideLabel });

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: 'select' }), vm.style);

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onModelChanged = function (value) {
                vm.ttModel = value;

                //vm.viewValue = vm.ttData?.find((item) => item[vm.ttDataId] === value)?.[vm.ttDataName] ?? '';

                if (angular.isFunction(vm.ttChange)) {
                    vm.ttChange({ $value: value, $modelId: us.getModelId($element), $args: vm.ttChangeArgs });
                }
            };

            layoutService.onLayoutChanged(onDestroy, (info) => {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);
                setStyle(vm.ttStyle);
                setClasses(info.labelAlwaysOnTop || vm.style.labelAlwaysOnTop);
            });

            vm.$onInit = function () {
                // BJS 20221109
                if (angular.isDefined(vm.ttInvalid) && (vm.ttInvalid === true || vm.ttInvalid === 'true')) {
                    setInvalid();
                }


                if (vm?.ttData && vm.ttData instanceof Array) {
                    vm.viewValue = vm?.ttData?.find((item) => item[vm.ttDataId] === vm.ttModel)?.[vm.ttDataName] ?? '';
                }

                setStyle(vm.ttStyle);
            }

            vm.$onChanges = function (changes) {
                // ODL 20230321
                if (angular.isDefined(changes.ttStyle)) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (angular.isDefined(changes.ttLabel) && us.isStringValue(changes.ttLabel.currentValue) && changes.ttLabel.currentValue !== changes.ttLabel.previousValue) {
                    if (vm.ttTranslate === 'false') {
                        vm.translations.ttLabel = changes.ttLabel.currentValue;
                    } else {
                        translateService.translate(changes.ttLabel.currentValue).then(function (translation) {
                            vm.translations.ttLabel = translation;

                            vm.whenReady();
                        });
                    }
                }

                if (angular.isDefined(changes.ttDisabled) && changes.ttDisabled.currentValue !== changes.ttDisabled.previousValue) {
                    vm.isDisabled = us.toBoolean(changes.ttDisabled.currentValue);
                }

                if (changes?.ttModel?.currentValue && vm?.ttData && vm.ttData instanceof Array) {
                    vm.viewValue = vm?.ttData?.find((item) => item[vm.ttDataId] === vm.ttModel)?.[vm.ttDataName] ?? '';
                }

                if (angular.isDefined(changes.ttRequired)) {
                    vm.required = us.toBoolean(changes.ttRequired.currentValue);
                }

                if (angular.isDefined(changes.ttHideLabel)) {
                    vm.hideLabel = us.toBoolean(changes.ttHideLabel.currentValue);
                }

                if (angular.isDefined(changes.ttHideArrow)) {
                    if (us.toBoolean(changes.ttHideArrow.currentValue)) {
                        vm.class.select = 'tt-hide-arrow';
                    }
                }

                // BJS 20221109
                if (angular.isDefined(changes.ttInvalid)) {
                    if (angular.isDefined(changes.ttInvalid.currentValue) && (changes.ttInvalid.currentValue === true || vm.ttInvalid.currentValue === 'true')) {
                        setInvalid();
                    } else {
                        setValid();
                    }
                }
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);

            function setValid() {
                if (angular.isUndefined(vm.style)) return;
                if (angular.isUndefined(vm.style.select)) return;
                if (angular.isUndefined(vm.style.select.border)) return;

                delete vm.style.select.border;
            };

            function setInvalid() {
                vm.style.select.border = '1px solid red';
            };
        }]
    });
})();
