(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockBatchCorrQty', {
        templateUrl: 'views/components/views/stockBatchCorrQty/stockBatchCorrQty.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'stockBatchCorrQtyService', 'logisticService', 'rememberService', function ($stateParams, stateService, utilityService, modalService, translateService, stockBatchCorrQtyService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let stockBatchKeyno = $stateParams.stockbatch_keyno;
            
            let variableNames = {
                stockcorrcode_keyno: ''
            };
            
            let translations = {
                error: '',
                ok: ''
            };
            
            vm.model = {
                edit: {},
                selectListStockCorrectionCodes: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                stockBatchCorrQtyService.getStockBatchCorrQty({ stockbatch_keyno: stockBatchKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadStockCorrectionCodes = function () {
                logisticService.listStockCorrectionCodes().then(function (result) {
                    angular.copy(result, vm.model.selectListStockCorrectionCodes);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'stockcorrcode_keyno':
                        if (utilityService.validateParmsValue(vm.model.edit.stockcorrcode_keyno) !== true) return;

                        variableValue = vm.model.edit.stockcorrcode_keyno;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RECALCULATION FUNCTIONS

            let recalcQuantityStockChange = function () {
                vm.model.edit.quantity_stock_change = utilityService.parseNumber(vm.model.edit.quantity_stock_new) - utilityService.parseNumber(vm.model.edit.quantity_stock_now);
            };

		    // #endregion RECALCULATION FUNCTIONS
            
            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'quantity_stock_new':
                        if (utilityService.validateWatchValue(value, vm.model.edit.quantity_stock_new) !== true) return;

                        vm.model.edit.quantity_stock_new = value;

                        if (utilityService.validateParmsValue(vm.model.edit.quantity_stock_new, true) !== true) return;

                        recalcQuantityStockChange();
                        break;
                    case 'stockcorrcode_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.stockcorrcode_keyno) !== true) return;

                        vm.model.edit.stockcorrcode_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.stockcorrcode_keyno) !== true) return;

                        rememberFunc('stockcorrcode_keyno');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                stockBatchCorrQtyService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadStockCorrectionCodes();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();