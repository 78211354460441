(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('rfidConnect', {
        templateUrl: 'views/components/views/rfidConnect/rfidConnect.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'rfidConnectService', function($stateParams, stateService, utilityService, rfidConnectService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                settingRfid: {},
                itemsListRfids: [],
                lockedSave: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            rfidConnectService.loadRfidConnectGet(vm.model.argType, vm.model.argValue).then(function (data) {
                angular.copy(data[0], vm.model.settingRfid);
    
                loadRfidConnects();
            });
    
            var loadRfidConnects = function () {
                if (utilityService.validateParmsValue(vm.model.settingRfid.argtype) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingRfid.argvalue) !== true) return;
    
                vm.model.itemsListRfids = [];
    
                rfidConnectService.loadRfidConnectList(vm.model.settingRfid.argtype, vm.model.settingRfid.argvalue).then(function (data) {
                    angular.copy(data, vm.model.itemsListRfids);
                });
            };
    
            // ## TOGGLE FUNCTIONS ##
    
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
    
                if (item.item_id === '0' || item.item_id === '-1') return;
    
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveRfidConnect = function () {
                vm.model.lockedSave = true;
    
                var selectedItems = [];
    
                angular.forEach(vm.model.itemsListRfids, function (item) {
                    if (item.item_id === '0' || item.item_id === '-1') return;
                    if (item.item_is_selected === '0') return;
    
                    selectedItems.push(item);
                });
    
                var mergeObj = {
                    rfid: vm.model.settingRfid.rfid,
                    argtype: vm.model.settingRfid.argtype,
                    argvalue: vm.model.settingRfid.argvalue,
                    records: selectedItems.map(function (a) { return { "argtype": a.argtype, "argvalue": a.argvalue }; })
                };
    
                rfidConnectService.save(mergeObj).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();