(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('units', {
        templateUrl: 'views/components/views/units/units.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'unitsService', function (stateService, unitsService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;

            vm.model = {
                itemsListUnits: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadUnits = function () {
                unitsService.listUnits().then(function (result) {
                    angular.copy(result, vm.model.itemsListUnits);
                });
            };

		    // #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'unit':
                        go({ unit_no: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadUnits();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
