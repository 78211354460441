(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCrewResource', {
        templateUrl: 'views/components/views/srCrewResource/srCrewResource.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'srOperationsService', 'srCrewResourceService', 'watcherFactory', function($stateParams, stateService, utilityService, srOperationsService, srCrewResourceService, watcherFactory) {
    
            var vm = this;
    
    		var watcher = watcherFactory.$create(vm);
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                srCrewResourceKeyNo: $stateParams.srcrewresource_keyno,
                resourceId: $stateParams.resource_id,
                srCrewKeyNo: $stateParams.srcrew_keyno,
                resourceNameBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' },
                    { item_id: '1', item_name: 'search', item_func: 'search_item', glyph: 'glyphicon-search', color: 'success' }
                ],
                resource: {},
                searchingResources: srOperationsService.isSearching,
                lockedSave: false,
                lockedDelete: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCrewResourceService.loadSrCrewResourceGet(vm.model.srCrewResourceKeyNo, vm.model.resourceId, vm.model.srCrewKeyNo).then(function (data) {
            	angular.copy(data[0], vm.model.resource);
            });
    
            // ## TYPEAHEAD SEARCH FUNCTION ##
    
            vm.typeaheadSearchResourceName = function (value) {
                return srOperationsService.search(vm.model.resource.resource_name);
            };
    
            // ## BUTTON LIST FUNCTIONS ##
    
            vm.resourceNameBtnListFunc = function (item_func, value) {
                if (angular.isDefined(value) && value !== '' && angular.isUndefined(item_func)) {
                	return vm.typeaheadSearchResourceName(value);
                } else if (item_func === 'search_item') {
                    return vm.typeaheadSearchResourceName(vm.model.resource.resource_name);
                } else if (item_func === 'erase_item') {
                	vm.model.resource.resource_name = '';
                }
            };
    
            // ## SAVE BUTTON FUNCTION ##
    
            vm.saveSrCrewResource = function () {
                vm.model.lockedSave = true;
                
                srCrewResourceService.save(vm.model.resource).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedSave = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
    
            // ## DELETE BUTTON FUNCTION ##
    
            vm.deleteSrCrewResource = function () {
                vm.model.lockedDelete = true;
                
                srCrewResourceService.deleteObj(vm.model.resource).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                            vm.model.lockedDelete = false;
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
    
            // ## WATCH FUNCTIONS ##
    
            watcher.$watch(function () { return vm.model.resource.resource_name; }, function (newValue, oldValue) {
                if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;
    
                if (newValue === '') {
                    vm.model.resource.resource_id = '0';
                    vm.model.resource.resource_name = '';
                } else {
                    angular.forEach(srOperationsService.typeaheadResourcesList, function (data) {
                        if (angular.isDefined(data)) {
                            if (data.item_name === vm.model.resource.resource_name) {
                                vm.model.resource.resource_id = data.item_id;
                            }
                        }
                    });
                }
            });
    
            watcher.$watch(function () { return srOperationsService.isSearching; }, function () {
                vm.model.searchingResources = srOperationsService.isSearching;
            });
        }]
    });
})();