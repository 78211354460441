(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("consignmentPlansDeliveryLocationService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadLocationList: function () {
				return p2DataTaskService.call(1718, {});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			}
		};

		return service;
	}]);
})();