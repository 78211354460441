(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttSelectDatasource', {
        templateUrl: 'views/components/dynamics/components/ttSelectDatasource/ttSelectDatasource.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['base64', 'ttDatasourceManager', 'utilityService', function (base64, ttDatasourceManager, us) {
            var vm = this;

            vm.datasources = [];
            vm.selectedDatasource = null;

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve.parameters)) {
                    let excluded = {};

                    if (angular.isArray(vm.resolve.parameters.exclude)) {
                        exclude = vm.resolve.parameters.exclude;
                    } else {
                        let json = null;

                        if (angular.isString(vm.resolve.parameters.exclude)) {
                            json = vm.resolve.parameters.exclude;
                        } else {
                            if (angular.isObject(vm.resolve.parameters.exclude)) {
                                json = us.toBoolean(vm.resolve.parameters.exclude.isEncoded, false)
                                    ? base64.urldecode(vm.resolve.parameters.exclude.value)
                                    : vm.resolve.parameters.exclude.value;
                            }
                        }

                        if (json !== null && json.length > 0) {
                            console.log('json: "' + json + '"');

                            var data = angular.fromJson(json);

                            angular.forEach(data, function (ds) {
                                excluded[ds.datasource_id] = null;
                            });
                        }
                    }

                    let datasources = ttDatasourceManager.getDatasources(vm.resolve.parameters.instance_id);

                    angular.forEach(datasources, function (ds) {
                        if (angular.isUndefined(excluded[ds.datasource_id])) {
                            vm.datasources.push({ datasource_id: ds.datasource_id });
                        }
                    });

                    if (angular.isFunction(vm.resolve.parameters.response)) {
                        vm.modalInstance.closed.then(function () {
                            vm.resolve.parameters.response({ datasource_id: vm.selectedDatasource });
                        });
                    }
                }
            };
        }]
    });
})();
