(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('orderStatusLangs', {
        templateUrl: 'views/components/views/orderStatusLangs/orderStatusLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'orderStatusLangsService', function ($stateParams, stateService, orderStatusLangsService) {
            var vm = this;

            vm.model = {
                orderStatusId: $stateParams.orderstatus_id,
                inputListLanguages: [],
                lockedSave: false
            };

            orderStatusLangsService.listOrderStatusLanguages({ orderstatus_id: vm.model.orderStatusId }).then(function (result) {
            	angular.copy(result, vm.model.inputListLanguages);
            });

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                orderStatusLangsService.saveObj({ records: vm.model.inputListLanguages }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                });
            };
        }]
    });
})();