(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkPointHeads', {
        templateUrl: 'views/components/views/checkPointHeads/checkPointHeads.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'checkPointHeadsService', function ($stateParams, checkPointHeadsService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListCheckPoints: []
            };

            let loadCheckPoints = function () {
                checkPointHeadsService.listCheckPoints({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListCheckPoints);
                });
            };

            vm.$onInit = function () {
                loadCheckPoints();
            };
        }]
    });
})();
