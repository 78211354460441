(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('ediSource', {
        templateUrl: 'views/components/views/ediSource/ediSource.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'ftpAccountsService', 'ediSourceService', function($stateParams, stateService, ftpAccountsService, ediSourceService) {
            var vm = this;
            
            vm.model = {
                p2EdiSourceGet: {},
                p2EdiSourceTypeExtList: {
                    records: []
                },
                p2FtpAccountList: {
                    records: []
                }
            };
            
            ediSourceService.loadP2EdiSourceGet($stateParams.edi_source_keyno).then(function (data) {
                vm.model.p2EdiSourceGet = angular.copy(data[0]);
    
                vm.model.p2EdiSourceGet.ftp_remotefolder = atob(vm.model.p2EdiSourceGet.ftp_remotefolder);
                vm.model.p2EdiSourceGet.ftp_localfolder = atob(vm.model.p2EdiSourceGet.ftp_localfolder);
                vm.model.p2EdiSourceGet.folder_localfolder = atob(vm.model.p2EdiSourceGet.folder_localfolder);
            });
            
            ediSourceService.loadP2EdiSourceTypeExtList().then(function (data) {
                angular.copy(data, vm.model.p2EdiSourceTypeExtList.records);
            });
            
            ftpAccountsService.loadP2FtpAccountList().then(function (data) {
                angular.copy(data, vm.model.p2FtpAccountList.records);
            });
            
            vm.saveEdiSource = function () {
                ediSourceService.saveP2EdiSource(vm.model.p2EdiSourceGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();