(function() {
    'use strict';

    angular.module("imApp").factory("stockplaceAccountingService", ['$ihttp', function ($ihttp) {
        var service = {
            init: function () {
                return $ihttp.post({
                    method: 2432,
                    parameters: {
                        state: 'init'
                    }
                });
            },

            create: function (items) {
                return $ihttp.post({
                    method: 2432,
                    parameters: {
                        state: 'create',
                        items: items
                    }
                });
            }
        }

        return service;
    }]);
})();
