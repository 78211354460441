(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('collectionMenu', {
        templateUrl: 'views/components/views/collectionMenu/collectionMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'collectionMenuService', function ($stateParams, stateService, modalService, translateService, collectionMenuService) {
            let vm = this;
            let collectionKeyno = $stateParams.collection_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                collectionKeyno: collectionKeyno,
                collectionNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('collectionlangs', { collection_keyno: vm.model.menu.collection_keyno }) }
                ],
                menu: {},
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadMenu = function () {
                collectionMenuService.getCollectionMenu({ collection_keyno: collectionKeyno }).then(function (result) {
                    angular.copy(result[0], vm.model.menu);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'collection':
                        go({ collection_keyno: vm.model.menu.collection_keyno });
                        break;
                    default:
                        break;
                }
            };

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;
                
                collectionMenuService.deleteItem({ collection_keyno: vm.model.menu.collection_keyno }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
        }]
    });
})();
