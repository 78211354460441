(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('payment', {
        templateUrl: 'views/components/views/payment/payment.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'paymentService', function ($stateParams, stateService, modalService, paymentService) {

            // ##########################
            // ## VARIABLES & DEFINITIONS
            // ##########################

            var vm = this;
            
            vm.model = {
                paymentId: $stateParams.payment_id,
                btnListPaymentLangName: [
                    { item_id: '0', item_name: 'goto', item_func: 'goto_item', glyph: 'glyphicon-chevron-right', color: 'primary' }
                ],
                getPayment: {},
                locked: false,
                lockedDelete: false
            };
            
            // #######################
            // ## LOAD PROCEDURE CALLS
            // #######################
            
            paymentService.loadPaymentGet(vm.model.paymentId).then(function (data) {
                vm.model.getPayment = angular.copy(data[0]);
            });

            // #region BUTTON LIST FUNCTIONS

            vm.btnListFuncPaymentLangName = function (item_func) {
                switch (item_func) {
                    case 'goto_item':
                        vm.goToFunc('paymentlangs');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion BUTTON LIST FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'paymentlangs':
                        stateService.go(state, {
                            payment_id: vm.model.getPayment.payment_id
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #######################
            // ## SAVE BUTTON FUNCTION
            // #######################
            
            vm.savePayment = function () {
                vm.model.locked = true;
                
                paymentService.save(vm.model.getPayment).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.locked = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.locked = false;
                    }
                });
            };
            
            // #########################
            // ## DELETE BUTTON FUNCTION
            // #########################
            
            vm.deletePayment = function () {
                vm.model.lockedDelete = true;
                
                paymentService.delete(vm.model.getPayment).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
        }]
    });
})();