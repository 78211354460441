(function() {
    'use strict';

    angular.module("imApp").factory("prodPriceService", [function() {
        var service = {
            
        };

        return service;
    }]);
})();
