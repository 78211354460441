(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("subtaskSelectService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            p2CustactSubtasksList: {
                records: []
            },
            loadP2CustactSubtasksList: function (custact_no, retrievetype, include_top, functiontype) {
                var deferred = $q.defer();

                var parmsP2CustactSubtasksList = {
                    method: 361,
                    parameters: {
                        custact_no: custact_no,
                        retrievetype: retrievetype,
                        include_top: include_top,
                        functiontype: functiontype
                    }
                };

                //console.log('parmsP2CustactSubtasksList');
                //console.dir(parmsP2CustactSubtasksList);
                //console.log(angular.toJson(parmsP2CustactSubtasksList));

                // Prosedyre sp_ws_p2_custact_subtasks_selectlist
                $ihttp.post(parmsP2CustactSubtasksList).then(function (data) {
                    //console.log('sp_ws_p2_custact_subtasks_selectlist');
                    //console.dir(data);
                    //console.log(angular.toJson(data));

                    angular.copy(data, service.p2CustactSubtasksList.records);
                    
                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            addP2CustactSelectedSubtasks: function () {
                var deferred = $q.defer();

                var parmsP2CustactAddSelectedSubtasks = {
                    method: 362,
                    parameters: {
                        custact_no_from: $stateParams.argvalue,
                        custact_no_addto: $stateParams.custact_no_addto,
                        records: service.p2CustactSubtasksList.records.map(function (a) { return { "item_id": a.item_id, "item_name": a.item_name, "item_is_selected": a.item_is_selected }; })
                    }
                };

                //console.log('parmsP2CustactAddSelectedSubtasks');
                //console.dir(parmsP2CustactAddSelectedSubtasks);
                //console.log(angular.toJson(parmsP2CustactAddSelectedSubtasks));

                // Prosedyre sp_ws_p2_custact_add_selected_subtasks
                $ihttp.post(parmsP2CustactAddSelectedSubtasks).then(function (data) {
                    //console.log('sp_ws_p2_custact_add_selected_subtasks');
                    //console.dir(data);
                    //console.log(angular.toJson(data));

                    //angular.copy(data, service.p2CustactFindCategoryDataList.records);

                    //service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();