(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('collectionLangs', {
        templateUrl: 'views/components/views/collectionLangs/collectionLangs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'collectionLangsService', function ($stateParams, stateService, collectionLangsService) {
            let vm = this;
            let collectionKeyno = $stateParams.collection_keyno;

            vm.model = {
                inputListCollections: []
            };

            let loadEdit = function () {
                collectionLangsService.listCollectionLanguages({ collection_keyno: collectionKeyno }).then(function (result) {
                    angular.copy(result, vm.model.inputListCollections);
                });
            };
            
            vm.saveChanges = function () {
                collectionLangsService.saveObj({ records: vm.model.inputListCollections }).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
