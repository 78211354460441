(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('oeeStateLog', {
        templateUrl: 'views/components/views/oeeStateLog/oeeStateLog.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'oeeStateLogService', 'stateService', 'searchService', 'utilityService', function($stateParams, $timeout, oeeStateLogService, stateService, searchService, utilityService) {
            var vm = this;

            if (utilityService.returnObjectValueOr(searchService.oeeStateLogDefaults.equipment_id,'') != $stateParams.equipment_id){
                searchService.oeeStateLogDefaults.oeestatelogshow_item_default = '0';
                searchService.oeeStateLogDefaults.oeestatelog_item_default  = '0';
                searchService.oeeStateLogDefaults.equipment_id = $stateParams.equipment_id;
            }
            
            vm.model = {
                oeestatelogshow_selection: {
                    item_id: '',
                    item_name: '',
                    item_default: utilityService.returnObjectValueOr(searchService.oeeStateLogDefaults.oeestatelogshow_item_default,'0'),
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'oee_active', item_func: 'A' },
                        { item_id: '1', item_name: 'oee_history', item_func: 'H' }
                    ]
                },
                oeestatelog_selection: {
                    item_id: '',
                    item_name: '',
                    item_default: utilityService.returnObjectValueOr(searchService.oeeStateLogDefaults.oeestatelog_item_default,'0'),
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'oee_not_approved', item_func: 'not_confirmed' },
                        { item_id: '1', item_name: 'oee_approved', item_func: 'confirmed' },
                        { item_id: '2', item_name: 'all', item_func: 'all' }
                    ]
                },
                inprogress: false,
                loadCounter: 0
            };
            
            var timer = null;
            
            vm.retrieve = function () {
                vm.model.inprogress = true;

                oeeStateLogService.loadP2OeeStateLogList($stateParams.equipment_id, vm.model.oeestatelogshow_selection.item_selected, vm.model.oeestatelog_selection.item_selected).then(function () {
                    vm.p2_oeestatelog_list = oeeStateLogService.p2_oeestatelog_list;
                    vm.model.inprogress = false;
                    vm.model.loadCounter = 0;
                });
            };
            
            vm.lazyLoad = function () {
                if (timer) $timeout.cancel(timer);

                if (vm.model.inprogress) {
                    if (vm.model.loadCounter < 100) {
                        vm.model.loadCounter = vm.model.loadCounter + 1;
                        utilityService.sleep(50);
                        vm.lazyLoad();
                    }
                } else {
                    timer = $timeout(function () {
                        vm.retrieve();
                    }, 50);
                }
            };
            
            vm.groupButton1Click = function (item_selected) {
                vm.model.oeestatelogshow_selection.item_selected = item_selected;
                searchService.oeeStateLogDefaults.oeestatelogshow_item_default = utilityService.groupButtonCurrentId(vm.model.oeestatelogshow_selection);

                vm.lazyLoad();
            };
            
            vm.groupButton2Click = function (item_selected) {
                vm.model.oeestatelog_selection.item_selected = item_selected;
                searchService.oeeStateLogDefaults.oeestatelog_item_default = utilityService.groupButtonCurrentId(vm.model.oeestatelog_selection);

                vm.lazyLoad();
            };
            
            oeeStateLogService.loadP2WOeeStateLogSettings().then(function () {
                vm.p2_w_oeestatelog_settings = oeeStateLogService.p2_w_oeestatelog_settings;
            });
            
            oeeStateLogService.loadP2EquipmentGet($stateParams.equipment_id).then(function () {
                vm.p2_equipment_get = oeeStateLogService.p2_equipment_get;
            });
            
            vm.saveP2OeeApprove = function () {
                oeeStateLogService.saveP2OeeApprove().then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();