(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("poFinishProdCompListAccService", ['$q', '$ihttp', '$stateParams', 'stateService', function ($q, $ihttp, $stateParams, stateService) {
        var service = {
            loaded: false,
            p2_pofinishprodcompacc_last_list: {
                item_glyphicon: '',
                item_glyphicon_color: '',
                item_id: '',
                item_name: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            p2_pofinishprodcomplistacc_get: {
                prod_id: '',
                prod_name: '',
                prodname_long: '',
                prodsize_keyno: '',
                prodchoice_keyno: '',
                quantity_topick: '',
                quantity_calculated: '',
                show_finish_button: ''
            },
            p2_pofinish_status: {
                readytofinish: ''
            },
            p2_pofinish_abort: {
                webpagename: '',
                webpage_parms: ''
            },
            p2_pofinish_finish: {
                webpagename: '',
                webpage_parms: ''
            },
            loadP2PoFinishProdCompAccLastList: function (prodsize_keyno, prodchoice_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinishprodcompacc_last_list = {
                    method: 232,
                    parameters: {
                        prodsize_keyno: prodsize_keyno,
                        prodchoice_keyno: prodchoice_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinishprodcompacc_last_list).then(function (data) {
                    service.p2_pofinishprodcompacc_last_list.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_pofinishprodcompacc_last_list.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            listAccumulatedComponents: function (parms) {
                return $ihttp.post({
                    method: 184,
                    parameters: parms || {}
                });
            },
            getP2PoFinishProdCompListAcc: function (p2_pofinish_keyno, prodsize_keyno, prodchoice_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinishprodcomplistacc_get = {
                    method: 185,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno,
                        prodsize_keyno: prodsize_keyno,
                        prodchoice_keyno: prodchoice_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinishprodcomplistacc_get).then(function (data) {
                    service.p2_pofinishprodcomplistacc_get = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2PoFinishStatus: function (p2_pofinish_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinish_status = {
                    method: 211,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinish_status).then(function (data) {
                    service.p2_pofinish_status = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            finishP2PoFinish: function () {
                var deferred = $q.defer();

                var parms_p2_pofinish_finish = {
                    method: 197,
                    parameters: {
                        finishfromkey: 'p2_pofinish_keyno',
                        keyvalue: $stateParams.p2_pofinish_keyno,
                        from_webpagename: stateService.getCurrentName(),
                        from_parms: $stateParams.p2_pofinish_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinish_finish).then(function (data) {
                    service.p2_pofinish_finish = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            abortP2PoFinish: function (p2_pofinish_keyno, p2_pofinishprod_keyno) {
                var deferred = $q.defer();

                var parms_p2_pofinish_abort = {
                    method: 212,
                    parameters: {
                        p2_pofinish_keyno: p2_pofinish_keyno,
                        p2_pofinishprod_keyno: p2_pofinishprod_keyno
                    }
                };

                $ihttp.post(parms_p2_pofinish_abort).then(function (data) {
                    service.p2_pofinish_abort = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            updateP2PoFinishProdCompListAcc: function () {
                var deferred = $q.defer();

                var parms_p2_pofinishprodcomplistacc_update = {
                    method: 186,
                    parameters: {
                        p2_pofinish_keyno: $stateParams.p2_pofinish_keyno,
                        prodsize_keyno: service.p2_pofinishprodcomplistacc_get.prodsize_keyno,
                        prodchoice_keyno: service.p2_pofinishprodcomplistacc_get.prodchoice_keyno,
                        quantity_topick: service.p2_pofinishprodcomplistacc_get.quantity_topick
                    }
                };

                $ihttp.post(parms_p2_pofinishprodcomplistacc_update).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();