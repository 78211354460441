import { Injectable } from '@angular/core';
import { User } from "@microsoft/microsoft-graph-types";

@Injectable({
    providedIn: 'root',
})

export class GraphUserService {

    constructor() { }

    public async getUser(userId: string, accessToken: string): Promise<User> {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch('https://graph.microsoft.com/v1.0/users/' + userId, options);

        let user = (await response.json()) as User;

        return user;
    }
}
