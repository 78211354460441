(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("furnitureFabricsSService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadFurnitureFabricsGet: function () {
                return p2DataTaskService.call(1181, {});
            },
            loadFurnitureFabricsList: function (isactive, show_all) {
                return p2DataTaskService.call(1180, {
                    isactive: isactive,
                    show_all: show_all
                });
            }
        };

        return service;
    }]);
})();
