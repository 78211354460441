(function() {
    'use strict';

	angular.module("imApp").factory("timeSheetDiaryService", ['$ihttp', function ($ihttp) {
		var call = function (method, parameters) {
			return $ihttp.post({
				method: method,
				parameters: parameters || {}
			});
		};

		var service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1300,
                    parameters: parms || {}
                });
            },
            listPortalUsers: function (parms) {
                return $ihttp.post({
                    method: 1301,
                    parameters: parms || {}
                });
            },
            listTasks: function (parms) {
                return $ihttp.post({
                    method: 1302,
                    parameters: parms || {}
                });
            },
            listOwners: function (parms) {
                return $ihttp.post({
                    method: 1303,
                    parameters: parms || {}
                });
            },
			updateItem: function (diary) {
				return call(2317, diary);
			}
		};

		return service;
    }]);
})();
