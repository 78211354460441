// Warning: ttKanban is still in development, use imListDirective instead!
(function () {
    'use strict';

    let module = angular.module('imApp');

    module
        .component('ttKanban', {
            templateUrl: 'views/components/directives/ttKanban/ttKanban.template.html?v=' + module.version,
            controllerAs: 'vm',
            bindings: {
                ttChange: '&',          // BJS 20210806 - Fires when listItems is modified
                ttClickable: '<',       // BJS 20220419 - Used to disable what happens on click
                ttClick: '&',           // BJS 20220419 - Used in combination with ttClickable to run custom action on all clicks.
                ttEditmode: '<',        // BJS 20220731 - Used to put list into "edit mode".
                ttHideGoto: '<',        // BJS 20230323 - Used to hide "buttons" to the right
                ttDeleteitem: '&',      // BJS 20220731 - Callback for deleting list item.
                listItems: '=',         //Required and references a list                                                // Supports several glyphs for single items, seperated by space
                sortItems: '=?',        //Used as an addition to the main list for sorting it into different groups     // Required only if sorting between multiple groups
                //menuItems: '=?',      //Used if custom menu items for multi-list will be used                         // Usable only with "multi", accepts a list of objects
                dataid: '@?',           //Used in conjunction with sortItems to specify the list key                    // Required with sortItems
                dataname: '@?',         //Used in conjunction with sortItems to specify the visible text                // Required with sortItems
                backitem: '@',          //Set to true if the items should lead back to the previous screen
                typevar: '@',           //Used for different predefined functionality                                   // Accepts 'g' for goto
                ptypeVar: '=',          //Used instead of typeVar when property is required                             //
                mconfig: '=?',          //Can be used with ltype 'multi' to configure the list and what it shows        // Also allows custom menu items, accepts a list of objects
                badge: '@',             //If it has value, creates a badge behind "name" displaying a value
                optionfunc: '&',        //Links a function from controller
                sortfunc: '&',          //Links a function from controller, optional function after moving              // REQUIRES sortItems, optional, fires after item is dragged
               // designVar: '@',         //Allows the user to activate 1 or several additional infolayers to the list    // 's' followed by a number '1' and/or '2' will add sublines, not amount but which one
                orderby: '@',           //Used to determine which variable to order the list by                         // NOT IMPLEMENTED
                revOrder: '@',          //Used for reversing the order of the list                                      // Accepts only true
                filterVal: '=',         //Used for adding a filter                                                      // Any value is searchable by default, filter type can limit this.
                filterParm: '@',        //Used for setting filter parameters                                            // NOT IMPLEMENTED,
                useDocIcon: '@',        //Specifies using icons instead of glyphicons for showing document types        // 
                hideIcon: '<'
            },
            controller: ['$timeout', 'layoutService', 'appConfig', 'responsive', 'watcherFactory', function ($timeout, layoutService, appConfig, responsive, watcherFactory) {
                let vm = this;
                vm.first = 0;

                const watcher = watcherFactory.$create(vm);

                let onDestroy = [];

                vm.version = module.version;

                vm.restrict = 'E';

                vm.model = {
                    mId: uuid(),
                    listIsDragged: false,
                    didUpdate: false,      // Responsible for handing when to save the list
                    resizeMode: false,
                    inlineEditMode: false,
                    isKeypress: false,
                    prosessingKeypress: false,
                    gridClicked: false,
                    currentInline: null,
                    activeInlineId: null,
                    elemInFocus: null,
                    filterVal: '',
                    filterParm: '',
                    field: '',
                    fieldWidth: '',
                    fieldWidthMin: '8',
                    //fieldWidthPadding: '5',
                    sliderMinDefault: 8,
                    sliderMin: 8,
                    slidersChanging: false,
                    colWidthChanging: false,
                    validatingEditable: false,
                    editableValidated: false,
                    inlineEditUpdate: false,
                    label: '',
                    //showMenu: false,
                    initialGlyphColor: '',
                    itemLoc: { itemId: null, listFromId: null, listToId: null, listFromIndex: null, listToIndex: null },
                    menuList: [],
                    sortlist: [],
                    glyphlist: [],
                    glyphlist2: [],
                    secondary: [],
                    colList: [],
                    numCol: 0,
                    currentSize: responsive.current,
                    appRoot: appConfig.appRoot,
                    listViewTextStyle: {
                        fontSize: '',
                        paddingLeft: ''
                    },
                    deleteButtonStyle: {
                        fontSize: ''
                    },
                    thumbnailStyle: {
                        aspectRatio: '1 / 1',
                        width: '',
                        objectFit: 'cover'
                    },
                    mobileSize: window.matchMedia('(min-width: 992px)'),
                };


                vm.style = {
                    button: {
                        fontSize: ''
                    }
                };

                

            
                let preventCustomClick = false;

                vm.combineid = function (index, role) {
                    let combinedid = index + '-' + role + '-' + uuid();
                    return combinedid;
                };


                // Main fucntion of ttkanban component. Allows the user to drag kanban elements from one section to another.
                // Placeholder -> White, transparent square signaling ot the use where the element can be dragged
                // connectWith -> Kanban containers in which the item can connect to

                vm.sortableOptions = {

                    placeholder: "sort-list-placeholder",
                    connectWith: ".sort-list-container",
                    tolerance: 'pointer',
                    start: function () {
                        /* console.log("Start");*/
                    },
                    update: function (e, ui) {
                        vm.model.listIsDragged = true;
                        vm.model.didUpdate = true;

                        if (angular.isUndefined(ui.item.sortable.model)) {
                            ui.item.sortable.cancel();
                        }

                        vm.model.itemLoc.itemId = ui.item.sortable.model.item_id;
                        vm.model.itemLoc.listFromIndex = ui.item.sortable.index;
                        vm.model.itemLoc.listToIndex = ui.item.sortable.dropindex;
                        vm.model.itemLoc.listFromId = ui.item.sortable.model.item_sort_id;
                    },
                    stop: function (e, ui) {


                        // this callback has the changed model
                        if (vm.model.didUpdate === true) {
                            findItemById(vm.model.itemLoc, false);

                            if (vm.model.itemLoc.listFromId !== vm.model.itemLoc.listToId) {
                                updateOrder(vm.model.itemLoc.listFromId);
                                updateItemlist(vm.model.itemLoc);
                            }
                            updateOrder(vm.model.itemLoc.listToId);

                            if (angular.isFunction(vm.sortfunc)) {
                                vm.sortfunc({ item: vm.model.itemLoc });
                            }
                        }

                        vm.model.listIsDragged = false;
                        vm.model.didUpdate = false;
                    },
                };

                const findItemById = function (itemLoc, idFrom) {
                    console.log(idFrom);
                    let foundItem = false;
                    if (vm.model.sortlist?.length) {
                        for (let i = 0; i < vm.model.sortlist.length; i++) {
                            if (vm.model.sortlist[i]?.items?.length) {
                                for (let l = 0; l < vm.model.sortlist[i].items.length; l++) {
                                    if (vm.model.sortlist[i].items[l].item_id === itemLoc.itemId) {
                                        if (idFrom === true) {
                                            itemLoc.listFromId = vm.model.sortlist[i].key;
                                        } else {
                                            itemLoc.listToId = vm.model.sortlist[i].key;
                                        }
                                        foundItem = true;
                                        break;
                                    }
                                }
                            }

                            if (foundItem === true) {
                                break;
                            }
                        }
                    }
                };

                const updateOrder = function (listId) {
                    if (vm.model.sortlist?.length) {
                        for (let i = 0; i < vm.model.sortlist.length; i++) {
                            //console.log("list id: " + listId);
                            //console.log("vm model sort key: " + vm.model.sortlist[i].key)
                            if (vm.model.sortlist[i].key === listId && angular.isDefined(vm.model.sortlist[i].items)) {
                                for (let l = 0; l < vm.model.sortlist[i].items.length; l++) {
                                    vm.model.sortlist[i].items[l].orderby = i * 100 + l;
                                }
                            }
                        }
                    }
                };

                const updateItemlist = function (itemLoc) {
                    console.log(itemLoc);
                    if (vm.listItems) {
                        if (vm.listItems?.records?.length) {
                            for (let i = 0; i < vm.listItems.records.length; i++) {
                                if (vm.listItems.records[i].item_id === itemLoc.itemId) {
                                    vm.listItems.records[i].item_sort_id = itemLoc.listToId;
                                    break;
                                }
                            }
                        } else {
                            for (let i = 0; i < vm.listItems.length; i++) {
                                if (vm.listItems[i].item_id === itemLoc.itemId) {
                                    vm.listItems[i].item_sort_id = itemLoc.listToId;
                                    break;
                                }
                            }
                        }
                    }
                };


                // Allows the user to set custom actions on click
                const isCustomClick = function (item) {
                    if (preventCustomClick === true) return true; // BJS 20220731
                    if (angular.isUndefined(vm.ttClickable)) return false;
                    if (vm.ttClickable === null) return false;
                    if (vm.ttClickable === true) return false;

                    if (angular.isFunction(vm.ttClick)) {
                        vm.ttClick({ item: item });
                    }
                    return true;
                };

                // Handles the onclick event. If it is a click it will direct to the item's page
                // If it is a hold then it will sort if it has been changed

                vm.goSort = function (item) {
                    if (isCustomClick(item)) return;

                 
                    vm.optionfunc({ item: item });

                }

                vm.goTo = function (l_item, e) {
                    if (isCustomClick(l_item)) return;

                    // BJS 20210607 - Added customaction to enable using listitem as a button.
                    if (l_item.item_state === 'customaction') {
                        if (angular.isFunction(l_item.item_action) === true) {
                            l_item.item_action(l_item);
                        }
                    } else {
                        if (l_item.item_path === '') return;
                        // EO 20230712 - Added functionality for opening in new tab.
                        if (e.ctrlKey) {
                            stateService.newTab(l_item.item_state, l_item.item_parms);
                        } else {
                            stateService.go(l_item.item_state, l_item.item_parms);
                        }
                    }
                };

                // Places the item tab on a new tab on custom click

                vm.goTab = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_state === '') return;

                    stateService.newTab(l_item.item_state, l_item.item_parms);
                };

                vm.goBack = function (l_item) {
                    if (isCustomClick(l_item)) return;

                    if (l_item.item_id === '') return;

                    if (angular.isFunction(vm.optionfunc)) {
                        vm.optionfunc({ item: l_item });
                    }

                    stateService.back();
                };

                vm.goCheck = function (item) {
                    if (isCustomClick(item)) return;

                    if (item.item_id === '0' || item.item_id === '-1') return;

                    if (angular.isDefined(item.item_glyphicon_color) && item.item_glyphicon_color === vm.model.initialGlyphColor) {
                        if (angular.isDefined(item.item_glyphicon_2) && item.item_glyphicon_2 !== null) {
                            item.item_is_selected = '1';
                        }
                        item.item_glyphicon_color = 'green';
                    } else if (vm.model.initialGlyphColor !== '') {
                        item.item_glyphicon_color = vm.model.initialGlyphColor;
                        item.item_is_selected = '0';
                    } else {
                        item.item_glyphicon_color = 'red';
                        item.item_is_selected = '0';
                    }
                };


                // function responsible for add the glyphs to the kanban list sections
                vm.addGlyph = function (item) {
                    let allClass = ''; //default

                        allClass = 'glyphicon glyphicon-sort';

                    if (item.item_glyphicon?.length) {
                        const glyphStr = item.item_glyphicon_2 ?? item.item_glyphicon;

                        if (glyphStr.startsWith('fa') && [2, 3].includes(glyphStr.indexOf('-'))) {
                            allClass = `im-no-mar ${glyphStr.slice(0, glyphStr.indexOf('-'))} fa${glyphStr.slice(glyphStr.indexOf('-'))}`;
                        } else {
                            allClass = 'glyphicon ' + glyphStr;
                        }
                    }

                    return allClass;
                };

                vm.addStyleWell = function (item) {
                    vm.model.initialglyphcolor = vm.model.initialglyphcolor || item?.item_glyphicon_color || '';
                    return {
                        background: item?.item_glyphicon_color || 'purple',
                        color: item?.item_glyphicon_color ? 'white' : 'black'
                    };
                };

                // Function responsible for aligning the elements in the correct view
                // ADDED: Kanban is now vertical when mobile view is detected
                vm.addClass = function () {


                    let allClass = '';

                    allClass = 'well well-sq sort-list-container col-xs-12';

                    if (vm.model.mobileSize.matches) {
                        if (angular.isDefined(vm.model.sortlist) && vm.model.sortlist !== null && vm.model.sortlist.length > 0) {
                            if (vm.model.sortlist.length === 2) {
                                allClass = 'well well-sq sort-list-container col-xs-6';
                            } else if (vm.model.sortlist.length === 3) {
                                allClass = 'well well-sq sort-list-container col-xx-4';
                            } else if (vm.model.sortlist.length === 4) {
                                allClass = 'well well-sq sort-list-container col-xx-6 col-xs-6 col-sm-3 col-lg-3';
                            } else if (vm.model.sortlist.length === 6) {
                                allClass = 'well well-sq sort-list-container col-xx-4 col-xs-2';
                            } else if (vm.model.sortlist.length === 8) {
                                allClass = 'well well-sq sort-list-container col-xx-1-5';
                            } else if (vm.model.sortlist.length === 12) {
                                allClass = 'well well-sq sort-list-container col-xs-1';
                            } else {
                                allClass = 'well well-sq sort-list-container col-xs-12';
                            }
                        }
                    }

                    return allClass;
                };


                // Custom styling for kanban elemnts that override grid.css layout rules
                // For static style changes, add it inside the allstyle let declaration
                vm.addStyleSortlist = function (item) {
                    let allStyle = { minWidth: '5px', paddingTop: '6px', color: '#ffffff' }; //default

                    if (window.innerWidth >= 384 && window.innerWidth <= 767) {
                        allStyle.width = '25px';
                    } else {
                        allStyle.width = 'auto';
                    }


                    return allStyle;
                };




                // Handles formatting of the fonts and padding

                vm.fontSizes = {
                    textSize: '',
                    textSizeS: '',
                    textSizeSs: '',
                    thumbnail: '50'
                };

                layoutService.onLayoutChanged(onDestroy, function (info) {
                    if (angular.isUndefined(info)) return;
                    console.log("layout changed.");
                    angular.copy(info.fontSizes, vm.fontSizes);

                    vm.model.deleteButtonStyle.fontSize = info.fontSizes.textSizeSs;
                    vm.model.listViewTextStyle.fontSize = info.fontSizes.textSize;
                    vm.model.listViewTextStyle.paddingLeft = info.paddingLeft + 'px';
                    vm.model.thumbnailStyle.width = info.fontSizes.thumbnail + 'px';

                    vm.style.button.fontSize = info.fontSizes.textSize;
                });



                // Handles the populating of the kanban elements.
                let groupTimer;
                const populateSortlist = function (newValue, oldValue) {
                    if (newValue === oldValue || !angular.isObject(vm.sortItems) || !angular.isObject(vm.listItems)) return;
                    const doPopulate = function () {
                        vm.model.sortlist.length = 0;



                        angular.forEach(vm.sortItems, function (item) {
                            if (vm.dataid && vm.dataname && item?.[vm.dataid] && item[vm.dataname] && item[vm.dataid] !== '0') {
                                vm.model.sortlist.push({
                                    key: item[vm.dataid],
                                    keyName: vm.dataid,
                                    value: item[vm.dataname],
                                    valueName: vm.dataname,
                                    item_glyphicon: item.glyphicon,
                                    item_glyphicon_color: item.glyphicon_color,
                                    items: []
                                });
                            }
                        });

                        const addItemsToSortlist = function (array) {
                            angular.forEach(array, function (item) {
                                if (angular.isObject(item)) {
                                    for (let i = 0; i < vm.model.sortlist.length; i++) {
                                        if (vm.model.sortlist[i].key === item.item_sort_id) {
                                            vm.model.sortlist[i].items.push(item);

                                            break;
                                        }
                                    }
                                }
                            });
                        }

                        if (vm.listItems?.records?.length) {
                            addItemsToSortlist(vm.listItems.records);
                        } else if (vm.listItems?.length) {
                            addItemsToSortlist(vm.listItems);
                        }
                    }

                    if (groupTimer) $timeout.cancel(groupTimer);

                    groupTimer = $timeout(doPopulate, 0);


                };

                // TODO: Changethis watcher. Remove all elemetns not belonging to kanban list (sortable list / sort list)

                watcher.$watch(function () { return vm.listItems; }, function (newValue, oldValue) {
                    populateSortlist(newValue, oldValue);

                }, true);
            }]
        });
})();

