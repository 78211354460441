(function() {
    'use strict';

    angular.module("imApp").factory("stockCalcService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loaded: false,
            /*
            get: function () {
                // 1997 - sp_ws_p2_consignmentdelivery_get
                return p2DataTaskService.call(1997, {});
            },
            listDeliveryMethods: function () {
                // 1978 - sp_ws_p2_consignmentdelivery_deliverymethod_list
                return p2DataTaskService.call(1978, {});
            },
            listConsignmentDeliveries: function (deliverymethod_no) {
                // 1976 - sp_ws_p2_consignmentdelivery_list
                return p2DataTaskService.call(1976, { deliverymethod_no: deliverymethod_no });
            },
            updateColumnResize: function (parms) {
                // 1457 - sp_ws_p2_listdirective_multilist_update_width
                return p2DataTaskService.call(1457, parms);
            },
            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            }
            */
        }

        return service;
    }]);
})();
