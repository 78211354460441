(function () {
	'use strict';

	var module = angular.module("imApp");

	module.factory("docArticleEditService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadDocArticleGet: function (docarticle_keyno) {
				return p2DataTaskService.call(1376, {
					docarticle_keyno: docarticle_keyno
				});
			},
			save: function (editDocArticle) {
				return p2DataTaskService.call(1377, editDocArticle);
			},
			delete: function (docarticle_keyno) {
				return p2DataTaskService.call(1378, {
					docarticle_keyno: docarticle_keyno
				});
			}
		};

		return service;
	}]);
})();