(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("paymentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadPaymentGet: function (payment_id) {
                return p2DataTaskService.call(1131, {
                    payment_id: payment_id
                });
            },
            save: function (getPayment) {
                return p2DataTaskService.call(1132, getPayment);
            },
            delete: function (getPayment) {
                return p2DataTaskService.call(1133, getPayment);
            }
        };

        return service;
    }]);
})();