(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ftpAccounts', {
        templateUrl: 'views/components/views/ftpAccounts/ftpAccounts.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'ftpAccountsService', function(stateService, ftpAccountsService) {
            let vm = this;
            
            vm.model = {
                itemsListFtpAccounts: []
            };

            let loadFtpAccounts = function () {
                ftpAccountsService.loadP2FtpAccountList().then(function (result) {
                    angular.copy(result, vm.model.itemsListFtpAccounts);
                });
            };
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'ftpaccount':
                        go({ ftpaccount_keyno: '0' });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadFtpAccounts();
            };
        }]
    });
})();
