(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productionhTops', {
        templateUrl: 'views/components/views/productionhTops/productionhTops.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'modalService', 'ieScreenBlockService', 'productionhTopsService', 'dbPrinterService', 'printPreviewService', 'printService',
            function ($stateParams, $timeout, $q, stateService, modalService, ieScreenBlockService, productionhTopsService, dbPrinterService, printPreviewService, printService) {

                // #region VARIABLES & DEFINITIONS

                var vm = this;

                var reportDefinitions = {};

                vm.model = {
                    argType: $stateParams.argtype,
                    argValue: $stateParams.argvalue,
                    checkedCount: 0,
                    printer: '',
                    isolated_kit_keyno: '',
                    revert: false,
                    selectedItems: '',
                    progress: '',
                    progressInfo: {
                        label: '',
                        plabel: '',
                        field: '',
                        remField: '',
                        min: 0,
                        max: 100,
                        step: 0,
                        finishclose: ''
                    },
                    printers: [],
                    selectListPoStatus: [],
                    p2_reportdef_keyno: '',
                    settingsLoaded: false,
                    dataLoaded: false,
                    setting: {
                        filterval: '',
                        postatus_no: 10,
                        dateselector_index: 0,
                        date_fom: null,
                        date_tom: null,
                        printer: null,
                        reportName: '',
                        reportCopies: 1,
                        show_setting: 0
                    },
                    gridReady: false,
                    retrievingProductions: false
                };

                function corebuttons() {
                    return [
                        //{ name: 'saveAndPrint', text: 'save_and_print', func: function () { save(true, false); }, icon: 'fal fa-print', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return disable(true); } },
                        { name: 'customStock', text: '', func: function () { vm.customStock(); }, icon: 'categorize', disabled: function () { return disable(false); } },
                        //{ name: 'customFilter', text: '', func: function () { vm.customFilter(); }, icon: 'filter', disabled: function () { return disable(true); } },
                        { name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out', disabled: function () { return disable(false); } },
                        { name: 'customPrintAndPlan', text: '', func: function () { setNewStatus(false); }, icon: 'printer', disabled: function () { return disable('print'); } },
                        { name: 'customRegret', text: '', func: function () { setNewStatus(true); }, icon: 'undo', disabled: function () { return disable('regret'); } }
                    ];
                }

                vm.gridOld = {
                    setup: {
                        remember: 'productionhtops.list.grid',
                        columns: [],
                        format: [],
                        editableCols: [],
                        filter: { enabled: false, mode: false },
                        //toolbar: false,
                        toolbar: { //alternative -> toolbar: false,
                            hidden: false,
                            fullToolbar: false,
                            tBarSave: false,
                            emptyToolbar: true,
                            hideExports: true,
                            buttons: corebuttons()
                        },
                        //pager: true,
                        pager: { //alternative -> pager: false,
                            pageSizes: false,
                            pageSize: 1000,
                        },
                        autosave: true,
                        localData: true,
                        translate: true,
                        selectable: false,
                        behaviour: {
                            selectable: "multiple",
                            rowClick: false
                        }
                    },
                    data: [],
                    selectedCount: 0,
                    dataRaw: [],
                    saveData: [],
                    grid: null,
                    gridfunc: null
                };

                // #endregion VARIABLES & DEFINITIONS

                // #region GRID

                vm.grid = {
                    dataTask: {
                        rememberId: 'productionhtops.list.grid',			// Id used to retrieve user configs for the grid
                        loadData: {                                         // Method id of the get data procedure
                            method: 1985,
                            parameters: {
                                filterval: '',
                                postatus_no: 10,
                                dateselector_index: 0,
                                date_fom: null,
                                date_tom: null,
                                printer: null,
                                reportName: '',
                                reportCopies: 1,
                                show_setting: 0
                            }
                        },
                        addRow: {                                           //
                            method: null,
                            parameters: null
                        },
                        removeRow: {                                        //
                            method: null,
                            parameters: null
                        },
                        saveData: {
                            method: null,
                            parameters: null
                        }
                    },
                    translations: [],                                       // Comma separated list of words to be translated
                    config: {                                               // TouchTime parameters configuring the grid
                        toolbar: {                                          // alternative -> toolbar: false,
                            hidden: false,                                  // If true, hides the toolbar, default is false
                            pdfExport: false,                               // Adds a predefined btn for exporting grid to pdf
                            excelExport: false,                             // Adds a predefined btn for exporting grid to excel
                            filter: true,									// Adds a predefined btn for en-/disabling filtering
                            columnVisibility: true,                        // Adds a predefined btn for deciding which columns to show/hide
                            headers: true,                                 // Adds a predefined btn for switched between db column names and titles
                            edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                            lock: false,                                    // NOT IMPLEMENTED
                            add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                            delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                            save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                            wrapping: true,                                // Adds a predefined btn for en-/disabling wrapping of text in columns
                            layouts: true,                                 // Adds a predefined btn for adding, setting or removing predefined column layouts
                            buttons: corebuttons()
                            //buttons: []
                        },
                        fixedHeader: false,                                 // Enables header to stay visible when scrolling
                        keepSortOnAdd: false,                               // Clears sorting when adding a row
                        keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                        keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                        rowClick: false,                                    // Enables functionality for drilldown on clicked row
                        specialFunc: {                                      // Adds additional functionality to the grid_functions column
                            newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                            buttons: [
                                //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star', disabled: function (data) { return testHidden(data); } },
                                //{ name: 'test2', text: '', func: function (data) { testItems(data); }, icon: 'fas-save', disabled: function (data) { return test2Hidden(data); } }
                            ]
                        },
                        css: {
                            altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                            textWrapping: false                             // Enables text-wrapping in columns, default is false
                        },
                        onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                            //console.log('onDataSourceChanges');
                            //console.dir(e);
                        }
                    },
                    kendo: {                                                // Kendo parameters configuring the grid
                        height: null,                                       // Sets the height of the grid component
                        aggregate: false,                                   // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                        pager: false,                                       // NOT IMPLEMENTED
                        selectable: 'multiple',                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                        //persistSelection: false,                            // NOT IN USE
                        filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                    },
                    optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                    gridfunc: null                                          // Used for referencing functions in the Grid Component
                };

                // #endregion GRID

                // #region OTHER

                var optionfunc = function (data) {
                    //console.log('optionfunc');
                    if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                        //console.log('optionfunc - CellClickHandler');
                        //console.dir(data);

                        if (data.clickedCell.column.field === 'poh_orderno') {
                            vm.model.isolated_kit_keyno = data.clickedCell.dataItem.poh_kit_keyno;
                            savePageSetting('productionhtops.list.grid.isolated_kit_keyno', vm.model.isolated_kit_keyno, function () {
                                vm.grid.gridfunc.viewMatching('poh_kit_keyno', vm.model.isolated_kit_keyno);
                            });
                        } else if (data.clickedCell.column.field === 'poh_status') {
                            stateService.go('productionhtopscomponents', { argtype: 'poh_keyno', argvalue: data.clickedCell.dataItem.poh_po, counter: 1 });
                        } else if (data.clickedCell.column.field.indexOf('grid_') <= -1) {
                            stateService.go('pohtop', { poh_keyno_top: data.clickedCell.dataItem.poh_poh_keyno });
                        }
                    }

                    if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                        //console.log('optionfunc - OnCellClose');
                        //console.dir(data);
                    }

                    if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                        //console.log('optionfunc - LookupCellEditor');
                        //console.dir(data);
                    }
                };

                vm.gridOptionFunc = gridOptionFunc;
                vm.onPoStatusChange = onPoStatusChange;
                vm.loadData = loadData;

                vm.customClean = customClean;
                vm.customStock = customStock;
                vm.setNewStatus = setNewStatus;

                vm.onPrinterChange = onPrinterChange;

                // #endregion OTHER

                // #region FUNCTIONS

                let disable = function (state) {
                    if (state === 'print') {
                        if (vm.model.setting.postatus_no === '10' || vm.model.setting.postatus_no === '20') {
                            return false;
                        } else return 'hidden';
                    }

                    if (state === 'regret') {
                        if (vm.model.setting.postatus_no === '100') {
                            return false;
                        } else return 'hidden';
                    }

                    return state;
                };

                function customClean() {
                    if (vm.model.isolated_kit_keyno !== '') {
                        vm.model.isolated_kit_keyno = '';

                        savePageSetting('productionhtops.list.grid.isolated_kit_keyno', vm.model.isolated_kit_keyno, function () {
                            vm.grid.gridfunc.viewMatching('', vm.model.isolated_kit_keyno);
                        });
                    }
                }

                function setNewStatus(revert) {
                    if (vm.grid.gridfunc.getSelectedRows().length > 0) {
                        vm.model.revert = revert;
                        if (!revert) {
                            ieScreenBlockService.start('message');
                            ieScreenBlockService.setMessage('Oppdaterer status');
                            rememberSelectedItems();
                        } else {
                            ieScreenBlockService.start('message');
                            ieScreenBlockService.setMessage('Oppdaterer status');
                            rememberSelectedItems();
                        }
                    }
                }

                function rememberSelectedItems() {
                    var variableNameSelectedItems = 'w_pohtops.selected_items';
                    vm.model.selectedItems = '';
                    var selected_items = vm.grid.gridfunc.getSelectedRows();

                    if (selected_items.length > 0) {
                        angular.forEach(selected_items, function (item) {
                            if (vm.model.selectedItems.length > 1) {
                                vm.model.selectedItems += ',';
                            }

                            vm.model.selectedItems += item.poh_poh_keyno;
                        });
                    }

                    productionhTopsService.remember(variableNameSelectedItems, vm.model.selectedItems).then(function (data) {
                        setPohTopNewStatus();
                    });
                }

                function setPohTopNewStatus() {
                    if (vm.model.setting.postatus_no != '100') {
                        vm.model.setting.postatus_no_new = '100';
                    } else if (vm.model.setting.postatus_no == '100') {
                        vm.model.setting.postatus_no_new = '10';
                    }

                    productionhTopsService.setPohTopsNewStatus(vm.model.setting.postatus_no_new).then(function () {
                        if (vm.model.setting.postatus_no_new != '100') {
                            ieScreenBlockService.stop();
                            stateService.go('productionhtops', {
                                argtype: '-',
                                argvalue: '-',
                                counter: Date.now()
                            });
                        } else {
                            printAndPlanSelected();
                        }
                    });
                }

                function printAndPlanSelected() {
                    vm.model.progress = 'START';
                    vm.model.progressInfo.label = 'progressbar';
                    vm.model.progressInfo.field = '';
                    vm.model.progressInfo.max = 0;
                    vm.model.progressInfo.step = 0;

                    var selected_items = vm.grid.gridfunc.getSelectedRows();

                    ieScreenBlockService.setMessage('Printing');

                    var sortedArray = selected_items.sort(function (a, b) {
                        return a.poh_orderno > b.poh_orderno ? 1 : a.poh_orderno < b.poh_orderno ? -1 : 0;
                    });

                    printAndPlandSelectedProgress(sortedArray);
                }

                function printAndPlandSelectedProgress(items) {
                    var increment = 1, skip = 0, failed = 0, step = 0;
                    vm.model.progressInfo.step = 0;
                    var parms = {};

                    var doStep = function () {
                        $timeout(function () {
                            modalService.progressInfo.finishclose = '0';

                            if (angular.isUndefined(items[step])) {
                                ieScreenBlockService.stop();
                                stateService.go('productionhtops', {
                                    argtype: '-',
                                    argvalue: '-',
                                    counter: Date.now()
                                });
                            }

                            if (angular.isUndefined(items[step])) return;

                            vm.model.progressInfo.field = items[step].poh_orderno + ': ' + items[step].poh_po;
                            modalService.progressInfo.field = vm.model.progressInfo.field;

                            if (!parseInt(vm.model.setting.reportCopies) > 0) {
                                vm.model.setting.reportCopies = 1;
                            }

                            parms = {
                                reportKeyno: vm.model.p2_reportdef_keyno,
                                printerKeyno: vm.model.printer,
                                parameters: {
                                    poh_keyno_top: items[step].poh_po
                                },
                                printerProperties: {
                                    isLandscape: reportDefinitions.isLandscape,
                                    pageAutoSize: true,
                                    copies: vm.model.setting.reportCopies
                                }
                            };

                            if (reportDefinitions.paper_size_id === 'custom') {
                                parms.printerProperties.pageAutoSize = false;
                                parms.printerProperties.scale = 'none';
                                parms.printerProperties.paperSize = {
                                    width: reportDefinitions.width,
                                    height: reportDefinitions.height
                                };
                            }

                            ieScreenBlockService.setMessage('Printing ' + items[step].poh_orderno + ' - ( ' + (step + 1) + ' / ' + items.length + ' )');

                            printService.print(parms).then(function () {
                                step++;
                                doStep();
                            });
                        });
                    };
                    doStep();
                }

                function customStock() {
                    vm.model.progress = 'START';
                    vm.model.progressInfo.label = 'progressbar';
                    vm.model.progressInfo.field = '';
                    vm.model.progressInfo.max = 0;
                    vm.model.progressInfo.step = 0;

                    var allRows = vm.grid.gridfunc.getAllRows();

                    vm.model.progressInfo.max = allRows.length;
                    checkOrdersProgress(allRows);

                    modalService.showProgress(vm.model.progressInfo).then(function (value) {
                        vm.model.progress = value;
                    });
                }

                function checkOrdersProgress(items) {
                    var increment = 1, skip = 0, failed = 0, step = 0;
                    vm.model.progressInfo.step = 0;

                    var doStep = function () {
                        $timeout(function () {
                            modalService.progressInfo.finishclose = '0';

                            if (vm.model.progress === 'ABORT') return;
                            if (angular.isUndefined(items[step])) return;

                            step = vm.model.progressInfo.step + skip + failed;

                            if (angular.isUndefined(items[step])) return;

                            vm.model.progressInfo.field = items[step].poh_product;
                            modalService.progressInfo.field = vm.model.progressInfo.field;

                            addPohTops(items[step]).then(function (data) {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = '1';

                                doStep();

                                items[step].item_glyphicon_color = data.item_glyphicon_color;
                                items[step].poh_show = data.item_status_text;

                                //vm.grid.gridfunc.updateRow(null, step);
                                vm.grid.gridfunc.redrawRow(null, items[step]);
                            });
                        });
                    };
                    doStep();
                };

                function addPohTops(item) {
                    var deferred = $q.defer();

                    productionhTopsService.loadPohTopsCheckOrder(item.poh_poh_keyno).then(function (data) {
                        deferred.resolve(data[0]);
                    });

                    return deferred.promise;
                }

                function gridOptionFunc(data) {
                    if (angular.isDefined(data.type)) {
                        $timeout(function () {
                            vm.gridOld.selectedCount = vm.gridOld.gridfunc.getSelectedRowsCount();
                        }, 300);

                        switch (data.type) {
                            case 'init':
                                vm.gridOld.grid = data.grid;
                                break;
                            case 'dataBoundRow':
                                var cols = {};
                                data.grid.columns;
                                var cellIndex = 0;

                                angular.forEach(data.grid.columns, function (item) {
                                    item.cellIndex = cellIndex;
                                    cols[item.field] = item;
                                    cellIndex += 1;
                                });

                                data.row.find('td:eq(' + cols['poh_orderno'].cellIndex + ')').addClass('h_point').on('click', function (e) {
                                    vm.model.isolated_kit_keyno = data.dataItem.poh_kit_keyno;

                                    savePageSetting('productionhtops.list.grid.isolated_kit_keyno', vm.model.isolated_kit_keyno, function () {
                                        vm.gridOld.setup.toolbar.buttons.push({ name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' });
                                        vm.gridOld.grid.refresh();
                                        reRenderData();
                                    });
                                });

                                data.row.find('td:eq(' + cols['poh_status'].cellIndex + ')').addClass('h_point').on('click', function (e) {
                                    stateService.go('productionhtopscomponents', { argtype: 'poh_keyno', argvalue: data.dataItem.poh_po, counter: 1 });
                                });

                                var i = 0;

                                for (i = 0; i < vm.gridOld.setup.columns.length - 1; i++) {
                                    switch (i) {
                                        case 2:
                                            break;
                                        case 8:
                                            break;
                                        default:
                                            if (i > 1) {
                                                data.row.find('td:eq(' + i + ')').addClass('h_point').on('click', function (e) {
                                                    stateService.go('pohtop', { poh_keyno_top: data.dataItem.poh_poh_keyno });
                                                });
                                            }
                                            break;
                                    }
                                }

                                break;
                            default:

                                break;
                        }
                    }
                }

                function onPoStatusChange(value) {
                    vm.model.setting.postatus_no = value;

                    if (vm.model.gridReady) {
                        productionhTopsService.setLastStatus(vm.model.setting.postatus_no).then(function () {
                            loadDataQued();
                            vm.grid.gridfunc.refreshToolbarBtnDisability();
                        });
                    }
                }

                function onPrinterChange(value) {
                    vm.model.printer = value;
                    productionhTopsService.setDefaultPrinter(vm.model.printer);
                }

                function prepareGrid() {
                    let deferred = $q.defer();

                    let promises = [];

                    promises.push(dbPrinterService.list());
                    promises.push(productionhTopsService.loadPostStatusSelectList('1'));
                    promises.push(productionhTopsService.getSettings());

                    $q.all(promises).then(function (response) {
                        angular.copy(response[0], vm.model.printers);

                        vm.model.selectListPoStatus = [];

                        angular.forEach(response[1], function (item) {
                            if (angular.isDefined(item)) {
                                if (item.postatus_no.length > 0 && item.postatus_no !== 'ALL') {
                                    vm.model.selectListPoStatus.push(item);
                                }
                            }
                        });

                        vm.model.setting.postatus_no = response[2][0].status_no;
                        vm.model.setting.dateselector_index = response[2][0].dateselector_index;
                        vm.model.setting.date_fom = response[2][0].date_fom;
                        vm.model.setting.date_tom = response[2][0].date_tom;
                        vm.model.printer = response[2][0].default_printer;
                        vm.model.setting.reportName = response[2][0].report_name;
                        vm.model.setting.reportCopies = response[2][0].report_copies;
                        vm.model.isolated_kit_keyno = response[2][0].isolated_kit_keyno;
                        vm.model.setting.show_setting = parseInt(response[2][0].show_setting);

                        getReportByName();

                        deferred.resolve();
                    }, function (error) {
                        //console.log('error! error! error! error! error!');

                        if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
                            //console.log(error.data.message);

                            if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
                                //console.log(error.data.data.message);
                            }
                        } else {
                            if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
                                //console.log(error.data.data.message);
                            }
                        }

                        deferred.reject(error);
                    });

                    return deferred.promise;
                }

                function getReportByName() {
                    productionhTopsService.getReportDefn(vm.model.setting.reportName).then(function (data) {
                        vm.model.p2_reportdef_keyno = data[0].p2_reportdef_keyno;

                        printPreviewService.loadP2ReportDef(vm.model.p2_reportdef_keyno).then(function (data) {
                            reportDefinitions = angular.copy(data[0]);
                        });
                    })
                        .catch(function (error) {
                            console.dir(error);
                        });
                }

                function reRenderData() {
                    var buffer = [];
                    var counter = 0;
                    var lkit_keyno = '';
                    vm.gridOld.data = []; //test

                    angular.forEach(vm.gridOld.dataRaw, function (orgItem) {
                        var item = {};
                        angular.copy(orgItem, item);
                        item.is_visible = 1;

                        if (vm.model.isolated_kit_keyno !== '') {
                            if (item.poh_kit_keyno == vm.model.isolated_kit_keyno) {
                                item.is_visible = 1;
                            } else {
                                item.is_visible = 0;
                            }
                        } else {
                            if (parseInt(item.poh_kit_keyno) > 0) {
                                if ((buffer.length > 0) && (lkit_keyno != item.poh_kit_keyno)) {
                                    buffer = [];
                                }

                                if (buffer.length === 0) {
                                    item.poh_item_product_shortname = item.poh_product;
                                    //visible aid..
                                    item.poh_product = item.poh_pocollectdescr.substring(0, 50);
                                    buffer.push(item);
                                    lkit_keyno = item.poh_kit_keyno;
                                } else {
                                    item.is_visible = 0;
                                }
                            }
                        }
                        if (item.is_visible > 0) {
                            vm.gridOld.data.push(item);
                        }

                    });

                    if (vm.gridOld.data.length > 0) {
                        if (angular.isDefined(vm.gridOld.grid) && vm.gridOld.grid !== null) {
                            var options = vm.gridOld.grid.getOptions();
                            options.dataSource.data = [];
                            angular.copy(vm.gridOld.data, options.dataSource.data);
                            vm.gridOld.grid.setOptions(options);
                        }
                    } else {
                        customClean();
                    }
                }

                function loadData() {
                    vm.model.dataLoaded = false;

                    if (angular.isUndefined(vm.model.setting.date_fom) === true) return;
                    if (vm.model.setting.date_fom === null) return;
                    if (vm.model.setting.date_fom.trim().length < 1) return;

                    if (angular.isUndefined(vm.model.setting.date_tom) === true) return;
                    if (vm.model.setting.date_tom === null) return;
                    if (vm.model.setting.date_tom.trim().length < 1) return;

                    vm.gridOld.saveData = [];
                    vm.gridOld.data = [];
                    vm.gridOld.dataRaw = [];

                    productionhTopsService.loadPohTopsList(vm.model.setting).then(function (data) {
                        if (data.length > 3) {
                            angular.copy(data[3].editable_columns, vm.gridOld.setup.editableCols);
                        }

                        if (data[2].rows.length > 0) {
                            if (angular.isDefined(vm.gridOld.grid) && vm.gridOld.grid !== null) {
                                //poke grid
                                vm.gridOld.setup.columns = [];
                                vm.gridOld.setup.format = [];
                                angular.copy(data[0].columns, vm.gridOld.setup.columns);
                                angular.copy(data[1].format, vm.gridOld.setup.format);
                                vm.gridOld.data = [];
                                vm.gridOld.dataRaw = [];
                                vm.gridOld.data.push(data[2].rows[0]);

                                $timeout(function () {
                                    vm.gridOld.setup.columns = [];
                                    vm.gridOld.setup.format = [];
                                    angular.copy(data[0].columns, vm.gridOld.setup.columns);
                                    angular.copy(data[1].format, vm.gridOld.setup.format);
                                    vm.gridOld.data = [];
                                    vm.gridOld.dataRaw = [];
                                    angular.copy(data[2].rows, vm.gridOld.dataRaw);

                                    reRenderData();

                                    vm.model.dataLoaded = true;
                                }, 100);
                            } else {
                                vm.gridOld.data = [];
                                vm.gridOld.dataRaw = [];
                                vm.gridOld.setup.columns = [];
                                vm.gridOld.setup.format = [];
                                angular.copy(data[0].columns, vm.gridOld.setup.columns);
                                angular.copy(data[1].format, vm.gridOld.setup.format);
                                angular.copy(data[2].rows, vm.gridOld.dataRaw);

                                reRenderData();

                                vm.model.dataLoaded = true;
                            }
                        }
                    });
                };

                function illegalCharRegExp(str) {
                    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
                };

                function savePageSetting(variableName, variableValue, optionalFunc) {
                    productionhTopsService.remember(variableName, variableValue).then(function () {
                        if (angular.isFunction(optionalFunc)) {
                            optionalFunc();
                        }
                    });
                }

                function rememberDateFom() {
                    var variableNameDateFom = 'productionhtops.date_fom';

                    if (canRun616(variableNameDateFom, 'date_fom') !== true) return;

                    productionhTopsService.remember(variableNameDateFom, vm.model.setting.date_fom);
                };

                function rememberDateTom() {
                    var variableNameDateTom = 'productionhtops.date_tom';

                    if (canRun616(variableNameDateTom, 'date_tom') !== true) return;

                    productionhTopsService.remember(variableNameDateTom, vm.model.setting.date_tom);
                };

                function rememberDateSelectorIndex() {
                    var variableNameDateTom = 'productionhtops.dateselector_index';

                    if (canRun616(variableNameDateTom, 'dateselector_index') !== true) return;

                    productionhTopsService.remember(variableNameDateTom, vm.model.setting.dateselector_index);
                };

                function canRun616(variableName, parm) {
                    if (angular.isUndefined(variableName) === true) return false;
                    if (variableName === null) return false;
                    if (variableName === '') return false;
                    if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
                    if (vm.model.setting[parm] === null) return false;

                    return true;
                };

                let timer = null;

                function loadDataQued() {
                    if (angular.isDefined(timer)) {
                        $timeout.cancel(timer);
                    }

                    timer = $timeout(function () {
                        updateGridParameters();
                    }, 300);
                }

                function updateGridParameters() {
                    vm.grid.dataTask.loadData.parameters = vm.model.setting;

                    if (vm.grid.gridfunc !== null) {
                        vm.grid.gridfunc.rebind();
                    }
                };

                vm.retrieveChanges = function () {
                    vm.model.retrievingProductions = true;

                    vm.grid.gridfunc.rebind().then(function () {
                        vm.model.retrievingProductions = false;
                    });
                };

                // #endregion FUNCTIONS

                // #region FIELD DATE RANGE CHANGE FUNCTION

                vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                    vm.model.setting.date_fom = startDate;
                    vm.model.setting.date_tom = endDate;
                    vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                    if (vm.model.setting.date_fom) rememberDateFom();
                    if (vm.model.setting.date_tom) rememberDateTom();
                    if (vm.model.setting.dateselector_index) rememberDateSelectorIndex();

                    if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                        //vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                        loadDataQued();
                    }
                }

                // #endregion FIELD DATE RANGE CHANGE FUNCTION

                // #region ANGULAR

                vm.$onInit = function () {
                    prepareGrid().then(function () {
                        if (angular.isUndefined(vm.model.setting.date_fom) === true) return;
                        if (vm.model.setting.date_fom === null) return;
                        if (vm.model.setting.date_fom.trim().length < 1) return;

                        if (angular.isUndefined(vm.model.setting.date_tom) === true) return;
                        if (vm.model.setting.date_tom === null) return;
                        if (vm.model.setting.date_tom.trim().length < 1) return;

                        vm.grid.dataTask.loadData.parameters = vm.model.setting;

                        vm.model.gridReady = true;
                    });
                };

                // #endregion ANGULAR
            }]
    });
})();
