(function () {
	'use strict';
	
	angular.module("imApp").factory("cuttingPlansService", ['$ihttp', function ($ihttp) {
		var service = {
			getCuttingPlans: function (parms) {
				return $ihttp.post({
					method: 2878,
					parameters: parms || {}
				});
			},
			listStatuses: function (parms) {
				return $ihttp.post({
					method: 2879,
					parameters: parms || {}
				});
			}
		};

		return service;
	}]);
})();