(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('poFinishProdCompQty', {
        templateUrl: 'views/components/views/poFinishProdCompQty/poFinishProdCompQty.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'poFinishProdCompQtyService', function ($stateParams, stateService, poFinishProdCompQtyService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let p2PoFinishProdCompKeyno = $stateParams.p2_pofinishprodcomp_keyno;

            vm.model = {
                edit: {},
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTIONS

            let loadEdit = function () {
                poFinishProdCompQtyService.getEdit({ p2_pofinishprodcomp_keyno: p2PoFinishProdCompKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                poFinishProdCompQtyService.saveObj({
                    p2_pofinishprodcomp_keyno: vm.model.edit.p2_pofinishprodcomp_keyno,
                    quantity_topick: vm.model.edit.quantity_picked
                }).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
