(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('custactPlanning', {
        templateUrl: 'views/components/views/custactPlanning/custactPlanning.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$rootScope', '$stateParams', 'custactPlanningService', '$timeout', 'utilityService', 'layoutService', 'watcherFactory', 'stateService', function ($rootScope, $stateParams, custactPlanningService, $timeout, us, layoutService, watcherFactory, stateService) {

            var vm = this;

            var watcher = watcherFactory.$create(vm);

            // ## VARIABLES & DEFINITIONS ##

            vm.class = {
                main: ''
            };

            vm.model = {
                getCustactPlanning: {},
                timeScalesOptions: {
                    active: '7',
                    buttons: [
                        { id: '7', label: 'timescale_one_week', onClick: () => vm.selectTimscaleOptions('7') },
                        { id: '14', label: 'timescale_two_weeks', onClick: () => vm.selectTimscaleOptions('14') },
                        { id: '21', label: 'timescale_three_weeks', onClick: () => vm.selectTimscaleOptions('21') }
                    ]
                }
            };

            /**
                INIT
            */
            vm.display_labelitems = {};
            vm.display_timescales = {};

            vm.default_start_hour = '';
            vm.default_start_minute = '';
            vm.default_end_hour = '';
            vm.default_end_minute = '';
            vm.label_filter = '';
            vm.cell_filter = '';
            vm.child_filter = '';
            vm.filter_update = '';
            vm.filter_update_jobs = '';
            vm.filter_update_machines = '';
            vm.filter_update_labours = '';
            vm.display_data = [];
            vm.time_scale_option = 0;

            vm.lastMouseX = 0;
            vm.lastMouseY = 0;
            vm.dragMode = 'copy';

            vm.ElementLeft = 0;
            vm.ElementWidth = 0;
            vm.ElementIndex = 0;

            vm.options = {
                item_id: 0,
                item_name: '',
                item_default: '0',
                item_selected: '1',
                records: [
                    { item_id: '1', item_name: 'jobs_planning', item_func: '1' },
                    { item_id: '2', item_name: 'equipment_planning', item_func: '2' },
                    { item_id: '3', item_name: 'labours_planning', item_func: '3' },
                ],
            };

            vm.draggablesTab = {
                active: '1',
                buttons: [
                    { id: '1', label: 'equipment_planning', onClick: () => vm.draggablesWorkTab.active = '1' },
                    { id: '2', label: 'labours_planning', onClick: () => vm.draggablesWorkTab.active = '2' },
                ]
            };

            function setActiveDraggablesTab(id) {
                vm.draggablesTab.active = id;
            }

            function buildDraggablesTab() {
                if (vm.options.item_selected === '1') {
                    vm.draggablesTab.buttons = [
                        { id: '1', label: 'equipment_planning', onClick: () => setActiveDraggablesTab('1') },
                        { id: '2', label: 'labours_planning', onClick: () => setActiveDraggablesTab('2') },
                    ]
                } else if (vm.options.item_selected === '2') {
                    vm.draggablesTab.buttons = [
                        { id: '1', label: 'jobs_planning', onClick: () => setActiveDraggablesTab('1') },
                        { id: '2', label: 'labours_planning', onClick: () => setActiveDraggablesTab('2') },
                    ]
                } else if (vm.options.item_selected === '3') {
                    vm.draggablesTab.buttons = [
                        { id: '1', label: 'jobs_planning', onClick: () => setActiveDraggablesTab('1') },
                        { id: '2', label: 'equipment_planning', onClick: () => setActiveDraggablesTab('2') },
                    ]
                }

                vm.draggablesTab = { ...vm.draggablesTab };
            }

            vm.timescale_options = {
                item_id: 0,
                item_name: '',
                item_default: '0',
                item_selected: '7',
                records: [{ item_id: '7', item_name: 'timescale_one_week', item_func: '7' },
                { item_id: '14', item_name: 'timescale_two_weeks', item_func: '14' },
                { item_id: '21', item_name: 'timescale_three_weeks', item_func: '21' }]
            };

            vm.labours = {};
            vm.labour_labels = {};
            vm.jobs = {};
            vm.job_labels = {};
            vm.machines = {};
            vm.machine_labels = {};

            vm.jobtasks = [];

            vm.cells = {
                child_type: 1,
                records: []
            };

            vm.children = {
                child_type: 1,
                records: []
            };

            vm.style = {
                tableHeaders: {
                    fontSize: '',
                    width: vm.column_width,
                },
                nodeMainText: {
                    fontSize: '',
                    padding: '0',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
                nodeSubText: {
                    fontSize: '',
                },
                nodeIcons: {
                    fontSize: '',
                },
                buttonFooter: {},
            }

            var timer = null;

            vm.objectLength = us.objectLength;

            custactPlanningService.loadCustactPlanningGet().then(function (data) {
                vm.model.getCustactPlanning = angular.copy(data[0]);

                vm.default_start_hour = vm.model.getCustactPlanning.std_start_hh;
                vm.default_start_minute = vm.model.getCustactPlanning.std_start_mm;
                vm.default_end_hour = vm.model.getCustactPlanning.std_end_hh;
                vm.default_end_minute = vm.model.getCustactPlanning.std_end_mm;
                vm.filter_update_jobs = vm.model.getCustactPlanning.filter_update_jobs;
                vm.filter_update_machines = vm.model.getCustactPlanning.filter_update_machines;
                vm.filter_update_labours = vm.model.getCustactPlanning.filter_update_labours;

                vm.set_filter();
            });

            /**
                TIMESCALE
            */
            vm.load_timescales = function (count, fom_date, direction) {
                if (count < 7) {
                    count = custactPlanningService.store?.time_scale_option ?? 7;
                    vm.time_scale_option = `${count}`;
                    vm.model.timeScalesOptions.active = `${count}`;
                }

                fom_date ??= custactPlanningService.store?.display_timescales_key;

                vm.column_width = 100;

                console.dir('load timescales');

                console.dir(custactPlanningService.store?.display_timescales_key);
                console.dir(custactPlanningService.store?.time_scale_option);

                custactPlanningService.loadTimescales(count, fom_date, direction).then(function (data) {
                    angular.copy(data, vm.display_timescales);

                    vm.column_width = Math.round(100 / (us.objectLength(vm.display_timescales) + 1));

                    var key = Object.keys(vm.display_timescales)[0];

                    vm.load_jobtasks(vm.display_timescales[key].timescale_value);
                    vm.render_check();
                });
            };

            vm.load_timescales(vm.time_scale_option);

            vm.planning_move_down = function () {
                if (vm.ddForm.$dirty) {
                    vm.save_custact_planning();
                }

                var direction = 'DOWN';
                var key = Object.keys(vm.display_timescales)[0];

                console.dir(vm.display_timescales);

                vm.load_timescales(vm.time_scale_option, vm.display_timescales[key].timescale_value, direction);
            };

            vm.planning_move_up = function () {
                if (vm.ddForm.$dirty) {
                    vm.save_custact_planning();
                }

                var direction = 'UP';
                var key = Object.keys(vm.display_timescales)[0];

                vm.load_timescales(vm.time_scale_option, vm.display_timescales[key].timescale_value, direction);
            };

            custactPlanningService.loadJobs($stateParams.argtype, $stateParams.argvalue).then(function (data) {
                angular.copy(data.records, vm.jobs);
                angular.copy(data.labels, vm.job_labels);

                vm.render_check();
            });

            /**
                LABOURS
            */
            custactPlanningService.loadLabours().then(function (data) {
                angular.copy(data.records, vm.labours);
                angular.copy(data.labels, vm.labour_labels);

                vm.render_check();
            });

            /**
                MACHNES
            */
            custactPlanningService.loadMachines().then(function (data) {
                angular.copy(data.records, vm.machines);
                angular.copy(data.labels, vm.machine_labels);

                vm.render_check();
            });

            vm.load_jobtasks = function (fom_date) {
                vm.jobtasks.length = 0;

                custactPlanningService.loadJobTasks($stateParams.argtype, $stateParams.argvalue, fom_date).then(function (data) {
                    angular.copy(data, vm.jobtasks);
                    vm.render_check();
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var timerFilterUpdateJobs = null;
            var timerFilterUpdateMachines = null;
            var timerFilterUpdateLabours = null;

            var rememberFilterUpdateJobs = function () {
                if (angular.isDefined(timerFilterUpdateJobs)) {
                    $timeout.cancel(timerFilterUpdateJobs);
                }

                var variableNameFilterUpdateJobs = 'w_custactplanning.filter_update_jobs';

                if (angular.isUndefined(vm.filter_update_jobs) === true) return;
                if (vm.filter_update_jobs === null) return;

                timerFilterUpdateJobs = $timeout(function () {
                    custactPlanningService.remember(variableNameFilterUpdateJobs, vm.filter_update_jobs);
                }, 500);
            };

            var rememberFilterUpdateMachines = function () {
                if (angular.isDefined(timerFilterUpdateMachines)) {
                    $timeout.cancel(timerFilterUpdateMachines);
                }

                var variableNameFilterUpdateMachines = 'w_custactplanning.filter_update_machines';

                if (angular.isUndefined(vm.filter_update_machines) === true) return;
                if (vm.filter_update_machines === null) return;

                timerFilterUpdateMachines = $timeout(function () {
                    custactPlanningService.remember(variableNameFilterUpdateMachines, vm.filter_update_machines);
                }, 500);
            };

            var rememberFilterUpdateLabours = function () {
                if (angular.isDefined(timerFilterUpdateLabours)) {
                    $timeout.cancel(timerFilterUpdateLabours);
                }

                var variableNameFilterUpdateLabours = 'w_custactplanning.filter_update_labours';

                if (angular.isUndefined(vm.filter_update_labours) === true) return;
                if (vm.filter_update_labours === null) return;

                timerFilterUpdateLabours = $timeout(function () {
                    custactPlanningService.remember(variableNameFilterUpdateLabours, vm.filter_update_labours);
                }, 500);
            };

            vm.reset_task = function () {
                vm.current_task = {
                    jobtask_keyno: null,
                    jobtask_index: null,
                    job_no: null,
                    job_name: '',
                    labour_no: null,
                    labour_name: '',
                    equipment_id: null,
                    equipment_name: '',
                    jobtime_scalevalue: '',
                    jobtime_start_dt: '',
                    jobtime_end_dt: '',
                    deleted: 0,
                    changed: 0
                };
            };

            vm.reset_task();

            vm.update_current_from_task = function (index, task) {
                vm.current_task.jobtask_keyno = task.jobtask_keyno;
                vm.current_task.jobtask_index = index;
                vm.current_task.job_no = task.job_no;
                vm.current_task.job_name = ((task.job_no != null) ? vm.jobs[task.job_no].job_name : '');
                vm.current_task.labour_no = task.labour_no;
                vm.current_task.labour_name = ((task.labour_no != null) ? vm.labours[task.labour_no].labour_name : '');
                vm.current_task.equipment_id = task.equipment_id;
                vm.current_task.equipment_name = ((task.equipment_id != null) ? vm.machines[task.equipment_id].equipment_name : '');
                vm.current_task.jobtime_scalevalue = task.jobtime_scalevalue;
                if (!task.jobtime_start_dt != '') {
                    task.jobtime_start_dt = task.jobtime_scalevalue + ' ' + vm.default_start_hour + ':' + vm.default_start_minute;
                    task.jobtime_end_dt = task.jobtime_scalevalue + ' ' + vm.default_end_hour + ':' + vm.default_end_minute;
                }
                vm.current_task.jobtime_start_dt = task.jobtime_start_dt;
                vm.current_task.jobtime_end_dt = task.jobtime_end_dt;
                vm.current_task.deleted = task.deleted;
                vm.current_task.changed = task.changed;
                vm.current_task.note = task.note;
            };

            vm.update_task_from_current = function () {
                var index = vm.current_task.jobtask_index;

                vm.jobtasks[index].jobtask_keyno = vm.current_task.jobtask_keyno;
                vm.jobtasks[index].job_no = vm.current_task.job_no;
                vm.jobtasks[index].labour_no = vm.current_task.labour_no;
                vm.jobtasks[index].equipment_id = vm.current_task.equipment_id;
                vm.jobtasks[index].jobtime_scalevalue = vm.current_task.jobtime_scalevalue;
                vm.jobtasks[index].jobtime_start_dt = vm.current_task.jobtime_start_dt;
                vm.jobtasks[index].jobtime_end_dt = vm.current_task.jobtime_end_dt;
                vm.jobtasks[index].jobtime_timelabel = vm.extract_time(vm.jobtasks[index].jobtime_start_dt) + '-' + vm.extract_time(vm.jobtasks[index].jobtime_end_dt);
                vm.jobtasks[index].deleted = vm.current_task.deleted;
                vm.jobtasks[index].changed = vm.current_task.changed;
                vm.jobtasks[index].note = vm.current_task.note;
                vm.reset_task();
            };

            vm.extract_time = function (dt_string) {
                var out = vm.default_start_hour + ':' + vm.default_start_minute;
                var arr = dt_string.split(' ');

                if (arr.length > 0) {
                    out = arr[1].substr(0, 5);
                }

                return out;
            };

            vm.item_clicked = function (jobtask_index) {
                vm.update_current_from_task(jobtask_index, vm.jobtasks[jobtask_index]);

                $('#editor').toggle(); $('#viewer').toggle();
            };

            vm.save_task = function () {
                vm.current_task.changed = 1;
                vm.update_task_from_current();

                $('#editor').toggle(); $('#viewer').toggle();
            }

            vm.cancel_task = function () {
                vm.reset_task();

                $('#editor').toggle(); $('#viewer').toggle();
            }

            vm.delete_task = function () {
                $('#editor').toggle(); $('#viewer').toggle();

                var index = vm.current_task.jobtask_index;

                vm.delete_job_task(index);
                vm.reset_task();
            }

            vm.save_custact_planning = function () {
                custactPlanningService.saveJobTasks(vm.jobtasks).then(function () {
                    var key = Object.keys(vm.display_timescales)[0];

                    if (vm.display_timescales[key]) {
                        vm.load_jobtasks(vm.display_timescales[key].timescale_value);
                    } else {
                        vm.load_jobtasks();
                    }

                    vm.ddForm.$dirty = false;
                });
            };

            vm.set_filter = function () {
                timer = null;

                switch (vm.options.item_selected) {
                    case '3':
                        rememberFilterUpdateJobs();
                        rememberFilterUpdateMachines();
                        rememberFilterUpdateLabours();
                        vm.label_filter = vm.filter_update_labours;
                        vm.cell_filter = vm.filter_update_jobs;
                        vm.child_filter = vm.filter_update_machines;
                        break;
                    case '2':
                        rememberFilterUpdateJobs();
                        rememberFilterUpdateMachines();
                        rememberFilterUpdateLabours();
                        vm.label_filter = vm.filter_update_machines;
                        vm.cell_filter = vm.filter_update_jobs;
                        vm.child_filter = vm.filter_update_labours;
                        break;
                    default:
                        rememberFilterUpdateJobs();
                        rememberFilterUpdateMachines();
                        rememberFilterUpdateLabours();
                        vm.label_filter = vm.filter_update_jobs;
                        vm.cell_filter = vm.filter_update_machines;
                        vm.child_filter = vm.filter_update_labours;
                        break;
                }
            };

            vm.update_filter = function () {
                if (timer) $timeout.cancel(timer);

                timer = $timeout(vm.set_filter, 500);
            };

            vm.gotoWorkDetails = function (keyno) {
                stateService.go('workdetails', { custact_no: keyno });
            }

            vm.gotoEquipment = function (keyno) {
                stateService.go('equipmentedit', { equipment_id: keyno });
            }

            vm.gotoLabour = function (keyno) {
                stateService.go('labouredit', { labour_no: keyno });
            }

            vm.selectStatus = function (l_s_item_status) {
                switch (l_s_item_status) {
                    case '3':
                        angular.copy(vm.labour_labels, vm.display_labelitems);

                        vm.cells.child_type = 1;
                        vm.cells.records = vm.job_labels;

                        vm.children.child_type = 2;
                        vm.children.records = vm.machine_labels;
                        break;
                    case '2':
                        angular.copy(vm.machine_labels, vm.display_labelitems);

                        vm.cells.child_type = 1;
                        vm.cells.records = vm.job_labels;

                        vm.children.child_type = 3;
                        vm.children.records = vm.labour_labels;
                        break;
                    default:
                        angular.copy(vm.job_labels, vm.display_labelitems);

                        vm.cells.child_type = 2;
                        vm.cells.records = vm.machine_labels;

                        vm.children.child_type = 3;
                        vm.children.records = vm.labour_labels;
                        break;
                }
                vm.options.item_selected = l_s_item_status;
                buildDraggablesTab();
            }

            vm.selectTimscaleOptions = function (time_scale_option) {
                vm.model.timeScalesOptions.active = time_scale_option;

                if (vm.ddForm.$dirty) {
                    vm.save_custact_planning();
                }

                vm.time_scale_option = time_scale_option;
                vm.load_timescales(vm.time_scale_option);

            }

            vm.render_check = function () {
                var run = 0;

                if (us.objectLength(vm.labours) > 0) run += 1;
                if (us.objectLength(vm.machines) > 0) run += 1;
                if (us.objectLength(vm.jobs) > 0) run += 1;
                if (us.objectLength(vm.display_timescales) > 0) run += 1;

                if (!run >= 4) return 0;

                vm.selectStatus(vm.options.item_selected);
            };

            /*****************************************
            DRAG AND DROP
            *****************************************/
            vm.draggableHint = function (e) {
                var clone = $(e).clone();
                var my_index = $(e).attr('my_index');

                vm.ElementIndex = my_index;

                var w = $(e).context.clientWidth;

                vm.ElementLeft = $(e).context.offsetLeft;
                vm.ElementWidth = w;

                var lm = vm.lastMouseX - $(e).context.offsetLeft;

                clone.width(w);

                var dm = (w / 2);

                vm.dragMode = 'copy';

                if (lm > dm) {
                    if (my_index >= 0) {
                        vm.dragMode = 'move';
                    }
                }

                if (clone.hasClass('col-xs-12')) {
                    clone.removeClass('col-xs-12');
                }

                return clone;
            };

            vm.onDragEnd = function (e) { };

            vm.onDragStart = function (e) {
                vm.lastMouseX = e.originalEvent.x;

                if (!e.originalEvent.x) {
                    vm.lastMouseX = e.originalEvent.touches[0].clientX;
                }

                const draggedElementDOMRect = e.currentTarget[0].getBoundingClientRect();
                const draggedElementWidth = draggedElementDOMRect.width;
                const draggedElementXPosition = draggedElementDOMRect.x;

                if (vm.lastMouseX > draggedElementXPosition && vm.lastMouseX < (draggedElementXPosition + (draggedElementWidth / 2))) {
                    vm.dragMode = 'copy';
                } else {
                    vm.dragMode = 'move';
                }
            };

            vm.onDragEnter = function (e) {
                if ($(e.dropTarget).hasClass('is-cell')) {
                    if (vm.dragMode == 'move') {
                        $(e.dropTarget).attr('style', 'border:1px dotted #282828; background: #FFC1B6; height: 40px; width: ' + vm.column_width + '%');
                    } else {
                        $(e.dropTarget).attr('style', 'border:1px dotted #282828; background: #F0FFBD; height: 40px; width: ' + vm.column_width + '%');
                    }
                }
            };

            vm.onDragLeave = function (e) {
                if ($(e.dropTarget[0]).hasClass('is-cell')) {
                    $(e.dropTarget[0]).attr('style', 'border:1px dotted #282828; background: transparent; height: 40px; width: ' + vm.column_width + '%');
                }
            };

            vm.onDrop = function (e) {
                vm.ddForm.$dirty = true;
                vm.reset_all_cells();

                //RECORD DATA
                var is_child = $(e.draggable.element).attr('is_child');
                var child_type = $(e.draggable.element).attr('child_type');
                var my_index = $(e.draggable.element).attr('my_index');
                var my_id = $(e.draggable.element).attr('my_id');
                var drop_index = $(e.dropTarget).attr('my_index');
                var my_scale = $(e.dropTarget).attr('my_scale');
                var base_id = $(e.dropTarget).attr('base_id');
                var base_type = $(e.dropTarget).attr('base_type');

                if ($(e.dropTarget).hasClass('drag-delete')) {
                    if (my_index >= 0) {
                        //only modify data if actual record detected..
                        if (is_child > 0) {
                            //only update record
                            vm.jobtasks[my_index].changed = 1;

                            switch (child_type) {
                                case '1':
                                    vm.jobtasks[my_index].job_no = null;
                                    break;
                                case '2':
                                    vm.jobtasks[my_index].equipment_id = null;
                                    break;
                                case '3':
                                    vm.jobtasks[my_index].labour_no = null;
                                    break;

                            }
                        } else {
                            //remove entire record
                            vm.delete_job_task(my_index);

                        }
                    }
                } else {
                    if (drop_index >= 0) {
                        //linking
                        vm.jobtasks[drop_index].changed = 1;
                        vm.jobtasks[drop_index].deleted = 0;

                        switch (child_type) {
                            case '1':
                                vm.jobtasks[drop_index].job_no = my_id;
                                break;
                            case '2':
                                vm.jobtasks[drop_index].equipment_id = my_id;
                                break;
                            case '3':
                                vm.jobtasks[drop_index].labour_no = my_id;
                                break;

                        }
                    } else {
                        if ((my_index >= 0) && (vm.dragMode == 'move') && (my_scale)) {
                            vm.jobtasks[my_index].jobtime_scalevalue = my_scale;
                            vm.jobtasks[my_index].jobtime_scalevalue = my_scale;
                            vm.jobtasks[my_index].changed = 1;
                            vm.jobtasks[my_index].deleted = 0;

                            if (is_child == 0) {
                                if (my_index >= 0) {
                                    var c_task = vm.jobtasks[my_index];

                                    vm.jobtasks[my_index].note = c_task.note;

                                    switch (base_type) {
                                        case '1':
                                            vm.jobtasks[my_index].job_no = base_id;
                                            vm.jobtasks[my_index].equipment_id = c_task.equipment_id;
                                            vm.jobtasks[my_index].labour_no = c_task.labour_no;
                                            break;
                                        case '2':
                                            vm.jobtasks[my_index].equipment_id = base_id;
                                            vm.jobtasks[my_index].labour_no = c_task.labour_no;
                                            vm.jobtasks[my_index].job_no = c_task.job_no;
                                            break;
                                        case '3':
                                            vm.jobtasks[my_index].labour_no = base_id;
                                            vm.jobtasks[my_index].equipment_id = c_task.equipment_id;
                                            vm.jobtasks[my_index].job_no = c_task.job_no;
                                            break;
                                    }
                                }
                            } else {
                                switch (base_type) {
                                    case '1':
                                        vm.jobtasks[my_index].job_no = base_id;
                                        break;
                                    case '2':
                                        vm.jobtasks[my_index].equipment_id = base_id;
                                        break;
                                    case '3':
                                        vm.jobtasks[my_index].labour_no = base_id;
                                        break;
                                }

                                switch (child_type) {
                                    case '1':
                                        vm.jobtasks[my_index].job_no = my_id;
                                        break;
                                    case '2':
                                        vm.jobtasks[my_index].equipment_id = my_id;
                                        break;
                                    case '3':
                                        vm.jobtasks[my_index].labour_no = my_id;
                                        break;

                                }
                            }

                            if (!vm.jobtasks[my_index].jobtime_start_dt != '') {
                                vm.jobtasks[my_index].jobtime_start_dt = vm.jobtasks[my_index].jobtime_scalevalue + ' ' + vm.default_start_hour + ':' + vm.default_start_minute;
                                vm.jobtasks[my_index].jobtime_end_dt = vm.jobtasks[my_index].jobtime_scalevalue + ' ' + vm.default_end_hour + ':' + vm.default_end_minute;
                                vm.jobtasks[my_index].jobtime_timelabel = vm.extract_time(vm.jobtasks[my_index].jobtime_start_dt) + ' - ' + vm.extract_time(vm.jobtasks[my_index].jobtime_end_dt);
                            } else {
                                vm.jobtasks[my_index].jobtime_start_dt = vm.jobtasks[my_index].jobtime_scalevalue + ' ' + vm.extract_time(vm.jobtasks[my_index].jobtime_start_dt);
                                vm.jobtasks[my_index].jobtime_end_dt = vm.jobtasks[my_index].jobtime_scalevalue + ' ' + vm.extract_time(vm.jobtasks[my_index].jobtime_end_dt);
                                vm.jobtasks[my_index].jobtime_timelabel = vm.extract_time(vm.jobtasks[my_index].jobtime_start_dt) + ' - ' + vm.extract_time(vm.jobtasks[my_index].jobtime_end_dt);
                            }
                        } else {
                            //new record
                            if ((base_id != '') && (my_scale)) {
                                var nj_task = {
                                    jobtask_keyno: null,
                                    job_no: null,
                                    labour_no: null,
                                    equipment_id: null,
                                    jobtime_scalevalue: '',
                                    jobtime_start_dt: '',
                                    jobtime_end_dt: '',
                                    jobtime_timelabel: '',
                                    deleted: 0,
                                    changed: 1,
                                    note: ''
                                };

                                nj_task.jobtime_scalevalue = my_scale;

                                if (is_child == 0) {
                                    if (my_index >= 0) {
                                        var c_task = vm.jobtasks[my_index];

                                        //clone
                                        nj_task.note = c_task.note;
                                        nj_task.jobtime_start_dt = c_task.jobtime_start_dt;
                                        nj_task.jobtime_end_dt = c_task.jobtime_end_dt;
                                        nj_task.jobtime_timelabel = c_task.jobtime_timelabel;

                                        switch (base_type) {
                                            case '1':
                                                nj_task.job_no = base_id;
                                                nj_task.equipment_id = c_task.equipment_id;
                                                nj_task.labour_no = c_task.labour_no;
                                                break;
                                            case '2':
                                                nj_task.equipment_id = base_id;
                                                nj_task.labour_no = c_task.labour_no;
                                                nj_task.job_no = c_task.job_no;
                                                break;
                                            case '3':
                                                nj_task.labour_no = base_id;
                                                nj_task.equipment_id = c_task.equipment_id;
                                                nj_task.job_no = c_task.job_no;
                                                break;
                                        }
                                    }
                                } else {
                                    switch (base_type) {
                                        case '1':
                                            nj_task.job_no = base_id;
                                            break;
                                        case '2':
                                            nj_task.equipment_id = base_id;
                                            break;
                                        case '3':
                                            nj_task.labour_no = base_id;
                                            break;
                                    }

                                    switch (child_type) {
                                        case '1':
                                            nj_task.job_no = my_id;
                                            break;
                                        case '2':
                                            nj_task.equipment_id = my_id;
                                            break;
                                        case '3':
                                            nj_task.labour_no = my_id;
                                            break;

                                    }
                                }

                                if (!nj_task.jobtime_start_dt != '') {
                                    nj_task.jobtime_start_dt = nj_task.jobtime_scalevalue + ' ' + vm.default_start_hour + ':' + vm.default_start_minute;
                                    nj_task.jobtime_end_dt = nj_task.jobtime_scalevalue + ' ' + vm.default_end_hour + ':' + vm.default_end_minute;
                                    nj_task.jobtime_timelabel = vm.extract_time(nj_task.jobtime_start_dt) + ' - ' + vm.extract_time(nj_task.jobtime_end_dt);
                                } else {
                                    nj_task.jobtime_start_dt = nj_task.jobtime_scalevalue + ' ' + vm.extract_time(nj_task.jobtime_start_dt);
                                    nj_task.jobtime_end_dt = nj_task.jobtime_scalevalue + ' ' + vm.extract_time(nj_task.jobtime_end_dt);
                                    nj_task.jobtime_timelabel = vm.extract_time(nj_task.jobtime_start_dt) + ' - ' + vm.extract_time(nj_task.jobtime_end_dt);
                                }

                                vm.jobtasks.push(nj_task);
                            }
                        }
                    }
                }
                $rootScope.$digest();
            };

            vm.delete_job_task = function (index) {
                var keyno = vm.jobtasks[index].jobtask_keyno;
                if (keyno > 0) {
                    vm.jobtasks[index].deleted = 1;
                    vm.jobtasks[index].changed = 1;
                } else {
                    vm.jobtasks.splice(index, 1);
                }
            };

            vm.reset_all_cells = function () {
                $('.is-cell').each(function (i, elem) {
                    $(elem).attr('style', 'border:1px dotted #282828; background: transparent; height: 40px; width: ' + vm.column_width + '%');
                });
            };

            vm.setTableHeadersStyle = function () {
            }

            vm.$onInit = function () {
                buildDraggablesTab();
            }

            let onDestroy = [];

            layoutService.onLayoutChanged(onDestroy, function (info) {
                console.dir('leyoutChanged');
                console.dir(info);

                if (info.sidemenuOpened === false) {
                    vm.class.main = 'custact-planning__main--sidemenu-closed';
                } else {
                    vm.class.main = '';
                }

                vm.style.tableHeaders.fontSize = info.fontSizes.textSize;

                vm.setTableHeadersStyle = () => {
                    return { ...vm.style.tableHeaders, fontSize: info.fontSizes.textSize };
                };

                vm.style.nodeMainText.fontSize = info.fontSizes.textSize;
                vm.style.nodeSubText.fontSize = info.fontSizes.textSizeSs;
                vm.style.nodeIcons.fontSize = info.fontSizes.textSizeS;
            });

            // ## WATCH FUNCTIONS ##

            watcher.$watch(function () {
                return vm.current_task.jobtime_start_dt;
            }, function (newVal, oldVal) {
                if (vm.current_task.jobtime_start_dt > vm.current_task.jobtime_end_dt) {
                    vm.current_task.jobtime_end_dt = vm.current_task.jobtime_start_dt;
                }
            });

            watcher.$watch(function () {
                return vm.current_task.jobtime_end_dt;
            }, function (newVal, oldVal) {
                if (vm.current_task.jobtime_start_dt > vm.current_task.jobtime_end_dt) {
                    vm.current_task.jobtime_start_dt = vm.current_task.jobtime_end_dt;
                }
            });

            watcher.$watch(function () {
                return vm.options.item_selected;
            }, function (newValue, oldValue) {
                if (angular.isUndefined(newValue) === true) return;
                if (angular.isUndefined(oldValue) === true) return;
                if (newValue === oldValue) return;

                vm.set_filter();
            });

            vm.$onDestroy = function () {
                custactPlanningService.store.time_scale_option = vm.time_scale_option;
                custactPlanningService.store.display_timescales_key = Object.values(vm.display_timescales)[0].timescale_value;
                onDestroy.forEach((destruction) => {
                    if (angular.isFunction(destruction)) destruction();
                })
            }
        }]
    });
})();
