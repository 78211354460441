(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("salesProspectsService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_riskelement_list: {
                item_glyphicon: '',
                item_id: '',
                item_name: '',
                item_name_sub1: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },
            p2_riskelement_list_count: {
                probability_no: '',
                consequence_no: '',
                nbr: '',
                records: []
            },
            p2_riskelement_probability_labels: {
                records: []
            },
            loadP2RiskElementProbabilityLabels: function () {
                var deferred = $q.defer();

                var parms_p2_riskelement_probability_labels = {
                    method: 1408
                };

                $ihttp.post(parms_p2_riskelement_probability_labels).then(function (data) {
                    angular.copy(data, service.p2_riskelement_probability_labels.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            p2_riskelement_description: {
                records: []
            },
            loadP2RiskElementDescription: function () {
                var deferred = $q.defer();

                var parms_p2_riskelement_description = {
                    method: 1413
                };

                $ihttp.post(parms_p2_riskelement_description).then(function (data) {
                    angular.copy(data, service.p2_riskelement_description.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            p2_riskelement_consequence_labels: {
                records: [],
                indexed: []
            },
            loadP2RiskElementConsequenceLabels: function () {
                var deferred = $q.defer();

                var parms_p2_riskelement_consequence_labels = {
                    method: 1409
                };

                $ihttp.post(parms_p2_riskelement_consequence_labels).then(function (data) {
                    angular.copy(data, service.p2_riskelement_consequence_labels.records);
                    var counter = 1;

                    angular.forEach(service.p2_riskelement_consequence_labels.records, function (item) {
                        service.p2_riskelement_consequence_labels.indexed[counter] = item;
                        counter += 1;
                    });

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2RiskElementList: function (argtype, argvalue, sales_possibility_no, sales_size_no) {
                var deferred = $q.defer();

                var parms_p2_riskelement_list = {
                    method: 1412,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue,
                        sales_possibility_no: sales_possibility_no,
                        sales_size_no: sales_size_no
                    }
                };

                $ihttp.post(parms_p2_riskelement_list).then(function (data) {
                    angular.copy(data, service.p2_riskelement_list.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2RiskElementListCount: function (argtype, argvalue) {
                var deferred = $q.defer();

                var parms_p2_riskelement_list_count = {
                    method: 1411,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parms_p2_riskelement_list_count).then(function (data) {
                    angular.copy(data, service.p2_riskelement_list_count.records);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();