(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('fabricsGrp', {
        templateUrl: 'views/components/views/fabricsGrp/fabricsGrp.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'fabricsGrpService', function ($stateParams, stateService, fabricsGrpService) {
            var vm = this;
            
            vm.model = {
                fabricsGrpId: $stateParams.fabricsgrp_id,
                fabricsGroup: {}
            };
            
            fabricsGrpService.loadFabricsGrpGet(vm.model.fabricsGrpId).then(function (data) {
                vm.model.fabricsGroup = angular.copy(data[0]);
            });
            
            vm.saveFabricsGrp = function () {
                fabricsGrpService.save(vm.model.fabricsGroup).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();