(function () {
    'use strict';

    angular.module("imApp").factory("skillsCertificateTypeService", ['$ihttp', function ($ihttp) {
        var service = {
            getSkillsCertificateType: function (parms) {
                return $ihttp.post({
                    method: 2499,
                    parameters: parms || {}
                });
            },
            listGlyphicons: function (parms) {
                return $ihttp.post({
                    method: 1270,
                    parameters: parms || {}
                });
            },
            listHexColors: function (parms) {
                return $ihttp.post({
                    method: 491,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 2500,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();