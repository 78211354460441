(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2WebpageTableService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            p2_webpage_table: {
                item_id: '',
                item_name: '',
                item_parms: '',
                item_path: '',
                item_state: '',
                item_glyphicon: '',
                item_glyphicon_color: '',
                records: []
            },
            loadP2WebpageTable: function (argtype, argvalue) {
                var deferred = $q.defer();

                var parms_p2webpagetable_list = {
                    method: 21,
                    parameters: {
                        argtype: argtype,
                        argvalue: argvalue
                    }
                };

                $ihttp.post(parms_p2webpagetable_list).then(function (data) {
                    service.p2_webpage_table.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_webpage_table.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
        }

        return service;
    }]);
})();