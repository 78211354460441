(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("batchDeliverySelectPurchHeadService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            p2_batchdel_select_purchhead_list: {
                item_glyphicon: '',
                item_id: '',
                item_name: '',
                item_parms: '',
                item_state: '',
                orderby: '',
                records: []
            },

            genericFunction: function (method_nr, parms) {
                var deferred = $q.defer();

                var parms_genericFunction = {
                    method: method_nr,
                    parameters: parms
                };

                $ihttp.post(parms_genericFunction).then(function (data) {
                    service.loaded = true;
                    deferred.resolve(data);
                });

                return deferred.promise;
            },

            remember: function (variablename, variablevalue) {
                return p2DataTaskService.call(616, {
                    variablename: variablename,
                    variablevalue: variablevalue
                });
            },

            loadP2BatchDelSelectPurchHeadList: function (supplier_no, batchdelivery_keyno) {
                var deferred = $q.defer();

                var parms_p2_batchdel_select_purchhead_list = {
                    method: 285,
                    parameters: {
                        supplier_no: supplier_no,
                        batchdelivery_keyno: batchdelivery_keyno
                    }
                };

                $ihttp.post(parms_p2_batchdel_select_purchhead_list).then(function (data) {
                    angular.copy(data, service.p2_batchdel_select_purchhead_list.records);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();