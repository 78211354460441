(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ediExports', {
        templateUrl: 'views/components/views/ediExports/ediExports.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['ttGridFactory', function (ttGridFactory) {
            const vm = this;
            
            vm.model = {
                gridReady: false
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_ediimportsComponentGrid',
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({ wrapping: true });

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 3353,
                    parameters: {}
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            vm.$onInit = function () {
                loadGrid();
            };
        }]
    });
})();
