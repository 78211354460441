// NOT IN USE AND SHOULD NOT BE IN USE, JLR SRS 09102024

//(function () {
//    'use strict';

//    let module = angular.module('imApp');

//    module.component('collectionMining', {
//        templateUrl: 'views/components/views/collectionMining/collectionMining.template.html?v=' + module.version,
//        controllerAs: 'vm',
//        controller: ['$timeout', 'stateService', '$stateParams', 'collectionMiningService', 'ieScreenBlockService', 'modalService', function ($timeout, stateService, $stateParams, collectionMiningService, ieScreenBlockService, modalService) {
//            var vm = this;
//            var timer = null;
//            var onDestroy = [];

//            vm.model = {
//                cm_textilemodelcategory_name: '',
//                cm_textilemodel_name: '',
//                cm_textilecolor_name: '',
//                cm_textilesize_id: '',
//                selectedCount: 0,
//                stockplaces: [],
//                stockplace_id: '',
//                businesscos: [],
//                businessco_no: 0,
//                collections: [],
//                collection_keyno: 0,
//                suppliers: [],
//                supplier_no: 0,
//                futuremanko_only: 0,
//                argType: $stateParams.argtype,
//                argValue: $stateParams.argvalue,
//                products: [],
//                productsRaw: [],
//                checkedCount: 0,
//                parmCounter: 0,
//                checkState: 0,
//                prod_id: '',
//                stock_date: moment(new Date()).format('YYYY-MM-DD'),
//                clickDec: false,
//                isLoaded: false
//            };

//            vm.grid = {
//                setup: {
//                    remember: 'collectionmining.list.grid',
//                    columns: [],
//                    format: [],
//                    editableCols: [],
//                    filter: { enabled: false, mode: false },
//                    toolbar: { // alternative -> toolbar: false,
//                        hidden: false,
//                        fullToolbar: false,
//                        tBarSave: false,
//                        emptyToolbar: true,
//                        hideExports: true,
//                        buttons: gridButtons()
//                    },
//                    pager: { //alternative -> pager: false,
//                        pageSizes: false,
//                        pageSize: 10000,
//                    },
//                    autosave: true,
//                    localData: true,
//                    translate: true,
//                    selectable: false,
//                    behaviour: {
//                        //selectable: "multiple",
//                        rowClick: false
//                    }
//                },
//                data: [],
//                dataRaw: [],
//                saveData: [],
//                grid: null,
//                gridfunc: null
//            };

//            vm.load = load;
//            vm.savePageSetting = savePageSetting;
//            vm.gridOptionFunc = gridOptionFunc;
//            vm.purchaseLines = purchaseLines;
//            vm.customFilter = customFilter;
//            vm.create = create;
//            vm.selectedCount = selectedCount;
//            vm.handleStockPlaceChange = handleStockPlaceChange;
//            vm.handleBusinesscoChange = handleBusinesscoChange;
//            vm.handleSupplierChange = handleSupplierChange;
//            vm.goTo = goTo;

//            vm.handleCollectionChange = handleCollectionChange;

//            loadPageSettings();

//            function handleSupplierChange(value) {
//                vm.model.supplier_no = value;
//                vm.savePageSetting('collectionmining.supplier_no', value);
//                loadQueue();
//            }

//            function handleStockPlaceChange(value) {
//                vm.model.stockplace_id = value;
//                vm.savePageSetting('collectionmining.stockplace_id', value);
//                loadQueue();
//            }

//            function handleBusinesscoChange(value) {
//                vm.model.businessco_no = value;
//                vm.savePageSetting('collectionmining.businessco_no', value);
//                loadQueue();
//            }

//            function gridButtons() {
//                return [
//                    { name: 'customFilter', text: '', func: function () { vm.customFilter(); }, icon: 'filter' },
//                    { name: 'customCreate', text: '', func: function () { vm.create() }, icon: 'save' }
//                ];
//            }

//            function customFilter() {
//                console.log('Custom Filter');
//                var m = 0;
//                if (vm.grid.setup.filter.mode == true) {
//                    vm.grid.setup.filter.mode = false;
//                    m = 0;
//                } else {
//                    vm.grid.setup.filter.mode = true;
//                    m = 1;
//                }
//                vm.savePageSetting('collectionmining.list.grid.filter', m, function () {
//                    stateService.go('collectionmining', {}, { reload: true, inherit: false });
//                });

//            }

//            function showMessage(title, message, type) {
//                return modalService.show({
//                    type: type || 'danger',
//                    title: title || 'default_error',
//                    message: message || 'default_error_message',
//                    buttons: [{
//                        label: 'ok',
//                        cssClass: 'btn-warning',
//                        action: function (dialogItself) {
//                            dialogItself.close();
//                        }
//                    }]
//                });
//            }

//            function loadPageSettings() {
//                collectionMiningService.loadPageSettings().then(function (data) {
//                    vm.model.stockplaces = data.stockplaces;
//                    vm.model.businesscos = data.businesscos;
//                    vm.model.suppliers = data.suppliers;
//                    vm.model.stockplace_id = data.stockplace_id;
//                    vm.model.businessco_no = data.businessco_no;
//                    vm.model.supplier_no = data.supplier_no;
//                    //vm.model.stock_date = data.search_date;
//                    //vm.model.prod_id = data.searchstring;

//                    if (data.filter == 0) {
//                        vm.grid.setup.filter = { enabled: false, mode: false };
//                    } else {
//                        vm.grid.setup.filter = { enabled: true, mode: true };
//                    }

//                    console.log(vm.model);

//                    loadCollections();

//                    vm.model.isLoaded = true;
//                });
//            }

//            function handleCollectionChange(value) {
//                vm.model.collection_keyno = value;
//                $timeout(function () {
//                    console.log('Loading products')
//                    load();
//                }, 300);
//            }

//            function loadCollections() {
//                collectionMiningService
//                    .loadCollections()
//                    .then(function (data) {
//                        angular.copy(data, vm.model.collections);
//                        console.log('collections:');
//                        console.dir(data);
//                        $timeout(function () {
//                            console.log('Loading products')
//                            load();
//                        }, 300);
//                    });

//            }

//            function load() {
//                ieScreenBlockService.start('message');
//                ieScreenBlockService.setMessage('Laster informasjon');

//                vm.grid.saveData = [];
//                vm.grid.data = [];
//                vm.grid.dataRaw = [];

//                if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
//                    var options = vm.grid.grid.getOptions();
//                    options.dataSource.data = [];
//                    vm.grid.grid.setOptions(options);
//                }

//                collectionMiningService
//                    .load({
//                        // stockplace_id: vm.model.stockplace_id,
//                        // businessco_no: vm.model.businessco_no
//                        collection_keyno: vm.model.collection_keyno
//                    })
//                    .then(function (data) {
//                        vm.model.cm_textilemodelcategory_name = '';
//                        /*
//                        var smallDataLoad = [];
//                        for (var i = 0; i < data.length; i++) {
//                            data[i].item_id = vm.illegalCharRegExp(data[i].item_id);
//                            smallDataLoad.push(data[i]);
//                        }
//                        angular.copy(smallDataLoad, vm.model.products);
//                        angular.copy(smallDataLoad, vm.model.productsRaw);
//                        */

//                        if (data.length > 3) {
//                            angular.copy(data[3].editable_columns, vm.grid.setup.editableCols);
//                        }

//                        console.log('data from DB');
//                        console.dir(data);

//                        if (data[2].rows.length > 0) {
//                            if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
//                                vm.grid.setup.columns = [];
//                                vm.grid.setup.format = [];
//                                angular.copy(data[0].columns, vm.grid.setup.columns);
//                                angular.copy(data[1].format, vm.grid.setup.format);
//                                vm.grid.data = [];
//                                vm.grid.dataRaw = [];
//                                vm.grid.data.push(data[2].rows[0]);

//                                $timeout(function () {
//                                    vm.grid.setup.columns = [];
//                                    vm.grid.setup.format = [];
//                                    angular.copy(data[0].columns, vm.grid.setup.columns);
//                                    angular.copy(data[1].format, vm.grid.setup.format);
//                                    vm.grid.data = [];
//                                    vm.grid.dataRaw = [];
//                                    angular.copy(data[2].rows, vm.grid.dataRaw);
//                                    //angular.copy(data[2].rows, vm.grid.data);
//                                    rebuildData(true);



//                                    vm.model.isLoaded = true;
//                                    ieScreenBlockService.stop();
//                                }, 300);

//                            } else {
//                                vm.grid.data = [];
//                                vm.grid.dataRaw = [];
//                                vm.grid.setup.columns = [];
//                                vm.grid.setup.format = [];
//                                angular.copy(data[0].columns, vm.grid.setup.columns);
//                                angular.copy(data[1].format, vm.grid.setup.format);
//                                //angular.copy(data[2].rows, vm.grid.data);
//                                angular.copy(data[2].rows, vm.grid.dataRaw);
//                                rebuildData();
//                                vm.model.isLoaded = true;
//                                ieScreenBlockService.stop();
//                            }
//                        } else {
//                            vm.model.isLoaded = true;
//                            ieScreenBlockService.stop();
//                        }

//                        // vm.model.isLoaded = true;
//                        // ieScreenBlockService.stop();
//                    })
//                    .catch(function (error) {
//                        vm.model.isLoaded = true;
//                        ieScreenBlockService.stop();
//                        showMessage(null, error);
//                    });
//            }

//            function goclone(source) {
//                if (Object.prototype.toString.call(source) === '[object Array]') {
//                    var clone = [];
//                    for (var i = 0; i < source.length; i++) {
//                        clone[i] = goclone(source[i]);
//                    }
//                    return clone;
//                } else if (typeof (source) == "object") {
//                    var clone = {};
//                    for (var prop in source) {
//                        if (source.hasOwnProperty(prop)) {
//                            clone[prop] = goclone(source[prop]);
//                        }
//                    }
//                    return clone;
//                } else {
//                    return source;
//                }
//            }

//            function getSummary(row, level) {
//                var out = 0;
//                angular.forEach(vm.grid.dataRaw, function (item) {
//                    switch (level) {
//                        case 3:
//                            if ((row.cm_textilemodelcategory_name == item.cm_textilemodelcategory_name) &&
//                                (row.cm_textilemodel_name == item.cm_textilemodel_name) &&
//                                (row.cm_textilecolor_name == item.cm_textilecolor_name) &&
//                                (row.cm_textilesize_id == item.cm_textilesize_id)
//                            ) {
//                                out += parseFloat(item.cm_amount_net_ordval);
//                            }
//                            break;
//                        case 2:
//                            if ((row.cm_textilemodelcategory_name == item.cm_textilemodelcategory_name) &&
//                                (row.cm_textilemodel_name == item.cm_textilemodel_name) &&
//                                (row.cm_textilecolor_name == item.cm_textilecolor_name)
//                            ) {
//                                out += parseFloat(item.cm_amount_net_ordval);
//                            }
//                            break;
//                        case 1:
//                            if ((row.cm_textilemodelcategory_name == item.cm_textilemodelcategory_name) && (row.cm_textilemodel_name == item.cm_textilemodel_name)) {
//                                out += parseFloat(item.cm_amount_net_ordval);
//                            }
//                            break;
//                        case 0:
//                        default:
//                            if (row.cm_textilemodelcategory_name == item.cm_textilemodelcategory_name) {
//                                out += parseFloat(item.cm_amount_net_ordval);
//                            }
//                            break;
//                    }
//                });
//                return out.toFixed(2);
//            }

//            function rebuildData(withOptions) {
//                vm.grid.data = [];
//                var cm_textilemodelcategory_names = [];
//                var cm_textilemodel_names = [];
//                var cm_textilecolor_names = [];
//                var level = 0;
//                angular.forEach(goclone(vm.grid.dataRaw), function (row) {
//                    //var row = item.clone();
//                    //console.log('IndexOf:', cm_textilemodelcategory_names.indexOf(row.cm_textilemodelcategory_name));
//                    if (cm_textilemodelcategory_names.indexOf(row.cm_textilemodelcategory_name) == -1) {

//                        if (vm.model.cm_textilemodelcategory_name === row.cm_textilemodelcategory_name) {

//                            if (vm.model.cm_textilemodel_name === row.cm_textilemodel_name) {

//                                if (vm.model.cm_textilecolor_name === row.cm_textilecolor_name) {
//                                    cm_textilecolor_names.push(row.cm_textilecolor_name);
//                                    level = 3;
//                                } else {
//                                    level = 2;
//                                    //row.cm_textilesize_id = 'Flere størrelser';
//                                }

//                                cm_textilecolor_names.push(row.cm_textilecolor_name);
//                            } else {
//                                level = 1;
//                                row.cm_textilecolor_name = 'Flere Farger';
//                                row.cm_textilesize_id = 'Flere størrelser';
//                            }

//                            cm_textilemodel_names.push(row.cm_textilemodel_name);
//                        } else {
//                            level = 0;
//                            row.cm_textilemodel_name = 'Flere modeller';
//                            row.cm_textilecolor_name = 'Flere Farger';
//                            row.cm_textilesize_id = 'Flere størrelser';
//                        }


//                        row.cm_amount_net_ordval = getSummary(row, level);
//                        vm.grid.data.push(row);
//                        cm_textilemodelcategory_names.push(row.cm_textilemodelcategory_name);
//                    } else {

//                        //level 2
//                        if (vm.model.cm_textilemodelcategory_name === row.cm_textilemodelcategory_name) {
//                            if (cm_textilemodel_names.indexOf(row.cm_textilemodel_name) == -1) {
//                                level = 1;
//                                if (vm.model.cm_textilemodel_name === row.cm_textilemodel_name) {

//                                    if (vm.model.cm_textilecolor_name === row.cm_textilecolor_name) {
//                                        cm_textilecolor_names.push(row.cm_textilecolor_name);
//                                        level = 3;
//                                    } else {
//                                        level = 2;
//                                        //row.cm_textilesize_id = 'Flere størrelser';
//                                    }

//                                    cm_textilecolor_names.push(row.cm_textilecolor_name);
//                                } else {
//                                    //level = 1;
//                                    row.cm_textilecolor_name = 'Flere Farger';
//                                    row.cm_textilesize_id = 'Flere størrelser';
//                                }

//                                //cm_textilemodel_names.push(row.cm_textilemodel_name);
//                                row.cm_amount_net_ordval = getSummary(row, level);
//                                vm.grid.data.push(row);
//                                cm_textilemodel_names.push(row.cm_textilemodel_name);
//                            } else {
//                                //level 3
//                                if (vm.model.cm_textilemodel_name === row.cm_textilemodel_name) {
//                                    level = 2;
//                                    if (cm_textilecolor_names.indexOf(row.cm_textilecolor_name) == -1) {

//                                        if (vm.model.cm_textilecolor_name === row.cm_textilecolor_name) {

//                                            if (vm.model.cm_textilecolor_name === row.cm_textilecolor_name) {
//                                                //cm_textilecolor_names.push(row.cm_textilecolor_name);
//                                                level = 3;
//                                            } else {
//                                                level = 2;
//                                                row.cm_textilesize_id = 'Flere størrelser';
//                                            }

//                                            //cm_textilecolor_names.push(row.cm_textilecolor_name);
//                                        } else {
//                                            level = 2;
//                                            //row.cm_textilesize_id = 'Flere størrelser';
//                                        }

//                                        //cm_textilemodel_names.push(row.cm_textilemodel_name);
//                                        cm_textilecolor_names.push(row.cm_textilecolor_name);
//                                        //row.cm_textilecolor_name = row.cm_textilecolor_name+'('+row.cm_textilemodel_name+')';
//                                        row.cm_amount_net_ordval = getSummary(row, level);
//                                        vm.grid.data.push(row);

//                                    } else {
//                                        //level 4
//                                        //if (vm.model.cm_textilecolor_name === row.cm_textilecolor_name){
//                                        level = 3;
//                                        row.cm_amount_net_ordval = getSummary(row, level);
//                                        vm.grid.data.push(row);
//                                        //}                                        

//                                    }
//                                }


//                            }
//                        }

//                    }
//                });
//                console.log('rebuild data: ');
//                console.dir(vm.grid.data);
//                if (withOptions == true) {
//                    var options = vm.grid.grid.getOptions();
//                    options.dataSource.data = [];
//                    angular.copy(vm.grid.data, options.dataSource.data);
//                    vm.grid.grid.setOptions(options);
//                }
//            }

//            function savePageSetting(variableName, variableValue, optionalFunc) {
//                optionalFunc = optionalFunc || null;
//                if (angular.isDefined(variableValue)) {
//                    collectionMiningService.savePageSetting({
//                        variablename: variableName,
//                        variablevalue: variableValue
//                    })
//                        .then(function (data) {
//                            if (angular.isFunction(optionalFunc)) {
//                                optionalFunc();
//                            }
//                        })
//                        .catch(function (err) {
//                            console.error(err);
//                        });
//                }
//            }

//            function purchaseLines() {
//                var lines = [];
//                var selectedLines = [];
//                //try {
//                selectedLines = vm.grid.gridfunc.getAllRows();//getSelectedRows();
//                //} catch (error) {}
//                if (selectedLines.length > 0) {
//                    angular.forEach(selectedLines, function (item) {
//                        //console.log('line_item');
//                        //console.dir(item);
//                        var item_stock_keyno = item.cm_stock_keyno;
//                        var item_ordered_quantity = item.cm_purch_proposal;
//                        var item_purchase_price = item.cm_purchprice_default;

//                        var item_requested_date = item.cm_requested_date;

//                        if (item_ordered_quantity > 0) {
//                            lines.push({
//                                stock_keyno: item_stock_keyno,
//                                ordered_quantity: item_ordered_quantity,
//                                purchase_price: item_purchase_price,
//                                requested_date: moment(item_requested_date).format('YYYY-MM-DD')
//                            });
//                        }
//                    });
//                }

//                return lines;
//            }

//            function create() {
//                ieScreenBlockService.start('message');
//                ieScreenBlockService.setMessage('Lagrer innkjøp');

//                // console.dir(vm.model.products);

//                var aDate = vm.model.stock_date.replace(':::', '').trim();

//                if (aDate.length > 10) {
//                    aDate = aDate.slice(0, 10);
//                }

//                var lines = vm.purchaseLines();

//                if (lines.length > 0) {
//                    var purchaseLines = {
//                        stockplace_id: vm.model.stockplace_id,
//                        businessco_no: vm.model.businessco_no,
//                        supplier_no: vm.model.supplier_no,
//                        purchase_date: aDate,
//                        purchase_lines: lines
//                    };

//                    collectionMiningService
//                        .create(purchaseLines)
//                        .then(function (data) {
//                            ieScreenBlockService.stop();
//                            stateService.go('purchhead', { purch_internal_no: data[0].purch_internal_no });
//                        })
//                        .catch(function (err) {
//                            ieScreenBlockService.stop();
//                            console.error(err);
//                        });
//                } else {
//                    showMessage('Feil', 'Ingen antall angitt!')
//                    ieScreenBlockService.stop();
//                }
//            }

//            // TODO: Not implemented
//            function goTo(state) {
//                var go = function (parms) {
//                    stateService.go(state, parms);
//                };

//                switch (state) {
//                    case '':
//                        go({});
//                        break;
//                    default:
//                        go({
//                            argtype: '',
//                            argvalue: ''
//                        });
//                        break;
//                }
//            }

//            // TODO: Not implemented
//            function selectedCount() {
//                vm.model.selectedCount = 0;
//                angular.forEach(vm.model.products, function (item) {
//                    if (item.item_is_selected != '0') {
//                        vm.model.selectedCount += 1;
//                    }
//                });
//                return vm.model.selectedCount;
//            }

//            function loadQueue() {
//                if (angular.isDefined(timer)) {
//                    $timeout.cancel(timer);
//                }
//                timer = $timeout(function () {
//                    vm.load();
//                }, 200);
//            }

//            function gridOptionFunc(data) {
//                if (angular.isDefined(data.type)) {


//                    $timeout(function () {
//                        vm.grid.selectedCount = vm.grid.gridfunc.getSelectedRowsCount();
//                        if (vm.grid.selectedCount == 1) {
//                            var row = vm.grid.gridfunc.getSelectedRows();
//                            if (vm.model.supplier_no == 0) {
//                                vm.model.supplier_no = row.data[0].cm_supplier_no;
//                            }
//                        }
//                    }, 300);


//                    switch (data.type) {
//                        case 'init':
//                            console.log('Init');
//                            vm.grid.grid = data.grid;
//                            break;

//                        case 'dataBoundRow':
//                            var cols = {};
//                            data.grid.columns;
//                            var cellIndex = 0;
//                            angular.forEach(data.grid.columns, function (item) {
//                                item.cellIndex = cellIndex;
//                                cols[item.field] = item;
//                                cellIndex += 1;
//                            });
//                            var cellclass = 'h_point';
//                            var cellclass_tname = '';
//                            var cellclass_cname = '';
//                            var cellclass_sname = '';
//                            if (vm.model.cm_textilemodelcategory_name === data.dataItem.cm_textilemodelcategory_name) {
//                                cellclass = 'h_point_selected';
//                                cellclass_tname = 'h_point';
//                                if (vm.model.cm_textilemodel_name == '') {
//                                    cellclass_tname = 'h_point_next';
//                                }
//                            }
//                            if ((vm.model.cm_textilemodelcategory_name === data.dataItem.cm_textilemodelcategory_name) && (vm.model.cm_textilemodel_name === data.dataItem.cm_textilemodel_name)) {
//                                cellclass_tname = 'h_point_selected';
//                                cellclass_cname = 'h_point';
//                                if (vm.model.cm_textilecolor_name == '') {
//                                    cellclass_cname = 'h_point_next';
//                                }
//                            }
//                            if ((vm.model.cm_textilemodelcategory_name === data.dataItem.cm_textilemodelcategory_name) && (vm.model.cm_textilemodel_name === data.dataItem.cm_textilemodel_name) && (vm.model.cm_textilecolor_name === data.dataItem.cm_textilecolor_name)) {
//                                cellclass_cname = 'h_point_selected';
//                                cellclass_sname = 'h_point';
//                                if (vm.model.cm_textilesize_id == '') {
//                                    cellclass_sname = 'h_point_next';
//                                }
//                            }
//                            data.row.find('td:eq(' + cols['cm_textilemodelcategory_name'].cellIndex + ')').addClass(cellclass).on('click', function (e) {
//                                vm.model.cm_textilecolor_name = '';
//                                vm.model.cm_textilemodel_name = '';
//                                if (vm.model.cm_textilemodelcategory_name !== data.dataItem.cm_textilemodelcategory_name) {
//                                    vm.model.cm_textilemodelcategory_name = data.dataItem.cm_textilemodelcategory_name;
//                                    console.log('vm.model.cm_textilemodelcategory_name =', vm.model.cm_textilemodelcategory_name);
//                                    rebuildData(true);
//                                } else {
//                                    vm.model.cm_textilemodelcategory_name = '';
//                                    console.log('vm.model.cm_textilemodelcategory_name =', vm.model.cm_textilemodelcategory_name);
//                                    rebuildData(true);
//                                }

//                                // stateService.go('productionhtopscomponents', { argtype: 'poh_keyno', argvalue: data.dataItem.poh_po, counter: 1 });
//                                //stateService.go('productprices', { prod_id: data.dataItem.cm_prod_parameter, selected_button: 1 });
//                            });
//                            data.row.find('td:eq(' + cols['cm_textilemodel_name'].cellIndex + ')').addClass(cellclass_tname).on('click', function (e) {
//                                vm.model.cm_textilecolor_name = '';
//                                if (vm.model.cm_textilemodel_name !== data.dataItem.cm_textilemodel_name) {
//                                    vm.model.cm_textilemodel_name = data.dataItem.cm_textilemodel_name;
//                                    console.log('vm.model.cm_textilemodel_name =', vm.model.cm_textilemodel_name);
//                                    rebuildData(true);
//                                } else {
//                                    vm.model.cm_textilemodel_name = '';
//                                    console.log('vm.model.cm_textilemodel_name =', vm.model.cm_textilemodel_name);
//                                    rebuildData(true);
//                                }

//                                // stateService.go('productionhtopscomponents', { argtype: 'poh_keyno', argvalue: data.dataItem.poh_po, counter: 1 });
//                                //stateService.go('productprices', { prod_id: data.dataItem.cm_prod_parameter, selected_button: 1 });
//                            });

//                            data.row.find('td:eq(' + cols['cm_textilecolor_name'].cellIndex + ')').addClass(cellclass_cname).on('click', function (e) {
//                                if (vm.model.cm_textilecolor_name !== data.dataItem.cm_textilecolor_name) {
//                                    vm.model.cm_textilecolor_name = data.dataItem.cm_textilecolor_name;
//                                    console.log('vm.model.cm_textilecolor_name =', vm.model.cm_textilecolor_name);
//                                    rebuildData(true);
//                                } else {
//                                    vm.model.cm_textilecolor_name = '';
//                                    console.log('vm.model.cm_textilecolor_name =', vm.model.cm_textilecolor_name);
//                                    rebuildData(true);
//                                }

//                                // stateService.go('productionhtopscomponents', { argtype: 'poh_keyno', argvalue: data.dataItem.poh_po, counter: 1 });
//                                //stateService.go('productprices', { prod_id: data.dataItem.cm_prod_parameter, selected_button: 1 });
//                            });

//                            data.row.find('td:eq(' + cols['cm_textilesize_id'].cellIndex + ')').addClass(cellclass_sname)

//                            break;
//                        /*
//                        case 'onCellClick':
//                            console.log('onCellClick');
//                            console.dir(data);
//                        break;*/
//                        default:

//                            break;
//                    }
//                }
//            }

//            /*
//             * TODO: Remove... replaced with onChange function
//            watcher.$watch(function () {
//                return vm.model.stockplace_id;
//            }, function () {
//                if (vm.model.isLoaded) {
//                    loadQueue();
//                }
//            }, onDestroy);

//            watcher.$watch(function () {
//                return vm.model.businessco_no;
//            }, function () {
//                if (vm.model.isLoaded) {
//                    loadQueue();
//                }
//            }, onDestroy);

//            watcher.$watch(function () {
//                return vm.model.prod_id;
//            }, function () {
//                if (vm.model.isLoaded && vm.model.prod_id.length >= 3) {
//                    loadQueue();
//                }
//            }, onDestroy);

//            watcher.$watch(function () {
//                return vm.model.supplier_no;
//            }, function () {
//                if (vm.model.isLoaded) {
//                    loadQueue();
//                }
//            }, onDestroy);
//            */

//            vm.$onDestroy = function () {
//                angular.forEach(onDestroy, function (fn) {
//                    if (angular.isFunction(fn) === true) {
//                        fn();
//                    }
//                });
//            };

//        }]
//    });
//})();
