(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('categoryLinks', {
		templateUrl: 'views/components/views/categoryLinks/categoryLinks.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'categoryLinksService', function($stateParams, stateService, categoryLinksService) {
			var vm = this;

			vm.model = {
				categorylink_keyno: $stateParams.categorylink_keyno,
				bgEdit: {
					item_id: '',
					item_name: '',
					item_default: '0',
					item_selected: '0',
					records: [
						{ item_id: '0', item_name: 'structure', item_func: 'structure' },
						{ item_id: '1', item_name: 'edit', item_func: 'edit' }
					]
				},
				belongto: {},
				categorylink: {},
				dataSource: {
					records: []
				},
				translations: {
					records: []
				}
			};

			var loadBelongTo = function () {
				categoryLinksService.genericFunction(630, { category_keyno: $stateParams.category_keyno_belongto }).then(function (data) {
					vm.model.belongto = angular.copy(data[0]);

					if ($stateParams.categorylink_keyno > 0) {
						categoryLinksService.genericFunction(634, { categorylink_keyno: $stateParams.categorylink_keyno }).then(function (data) {
							vm.model.categorylink = angular.copy(data[0]);
						});
					}

					loadCategories($stateParams.category_keyno_belongto);
				});
			};

			loadBelongTo();

			var loadTranslations = function () {
				if (vm.model.belongto.category_keyno > 0) {
					categoryLinksService.genericFunction(704, { category_keyno: $stateParams.category_keyno_belongto }).then(function (data) {
						angular.copy(data, vm.model.translations.records);
					});
				}
			};

			var loadCategories = function (belongto) {
				categoryLinksService.genericFunction(629, { category_keyno_belongto: belongto }).then(function (data) {
					angular.copy(data, vm.model.dataSource.records);
					loadTranslations();
				});
			};

			vm.bgEditClick = function (selected) {
				vm.model.bgEdit.item_selected = selected;
			};

			vm.addNewCategory = function () {
				stateService.go('categorylinkadd', {
					category_keyno_belongto: $stateParams.category_keyno_belongto
				});
			};

			vm.deleteCategoryLink = function () {
				categoryLinksService.genericFunction(633, { categorylink_keyno: $stateParams.categorylink_keyno }).then(function (data) {
					stateService.back();
				});
			};

			vm.deleteCategory = function () {
				categoryLinksService.genericFunction(635, { category_keyno: $stateParams.category_keyno_belongto }).then(function (data) {
					stateService.back();
				});
			};

			vm.updateCategory = function () {
				categoryLinksService.genericFunction(637, { category_keyno: $stateParams.category_keyno_belongto, category_name: vm.model.belongto.category_name }).then(function (data) {
					categoryLinksService.genericFunction(705, vm.model.translations).then(function (data) {
						stateService.back();
					});
				});
			};
		}]
	});
})();