import { Component, EventEmitter, Injectable, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LayoutService } from '../../../core/services/layout.service';
import { StateService } from '@app/core/services/state.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataTaskService } from '@app/core/services/data-task.service';;
import { List } from '@microsoft/microsoft-graph-types';


// KANBAN COMPONENT
// HOW TO USE:
// <tt-kanban [ttKanbanList]="[this.model.custacts]"
//            [ttKanbanCategories] = "[this.model.activitiyStatuses]
//            [dataId] = "'actstatus_keyno'"
//            [dataName] = "'actstatus_name'" >
// </tt-kanban>
//
// ttKanbanList: all custom activity items the user has (or any other similar form to activity) || Same as in the list component
// ttKanbanCategories: all the sortable kanban posts you have (i.e: TBD, In Progress, Finished, etc)
// DATAID: Custom no for the type you want. Here it is actstatus_keyno as it wants the acitivity status
// DATANAME: custom name for the data you are reding / modifying. Here it is actstatus_name as we are working on acitivity statuses.
//
// If this component is not going to be used for more than activities, the dataid and dataname variables could be removed
//
// WANT A REFERENCE? see custactSearch.template.html - This is a angularJS component,but when it has been refactored it should work the same way
//
//
// THIS COMPONENT INCLUDES MODALSERVICE (ERROR SERVICE). WHEN THIS IS IMPLEMENTED, PLEASE REMOVE THE COMMENTED LINES MADE FOR IT ON
// LINE 240

interface ttKanbanItem {
    item_id: string;
    item_sort_id?: string;
}

interface ttKanbanListContainer {
    records?: ttKanbanItem[];
}

interface FontSizes {
    textSize: string;
    textSizeS: string;
    textSizeSs: string;
    thumbnail: string;
}

interface SortItem {
    key: string;
    keyName: string;
    value: string;
    valueName: string;
    item_glyphicon?: string;
    item_glyphicon_color?: string;
    items: any[];
    index: number;
}

@Component({
  selector: 'tt-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.css']
})

@Injectable({
     providedIn: 'root'
})
export class KanbanComponent implements OnInit, OnDestroy {
    //Currently will  only return false in fuctnion isCustomClick()
    @Input() ttClickable: boolean = true;
    @Input() ttKanbanList: any[] = [];
    @Input() ttKanbanCategories: any[] = [];
    @Input() dataId!: string;
    @Input() dataName!: string;

    // Output for custom tt-click, had no function in angularJS, but was a new implementation when the code
    // was refactored so this will be left usable if wanted
    @Output() ttClick = new EventEmitter<any>();
  
    private groupTimer: any;
    private preventCustomClick: boolean = false;
    public isMobile: boolean = false;

    model!: {
        initialGlyphColor: any;
        itemLoc: {
            listToId: any; itemId: any; listFromIndex: any; listToIndex: any; listFromId: any; };
        listIsDragged: boolean;
        didUpdate: boolean;
        sliderMinDefault: 8;
        mobileSize: MediaQueryList;
        sortlist: SortItem[];
        deleteButtonStyle: { fontSize: string; };
        listViewTextStyle: { fontSize: string; paddingLeft: string; };
        thumbnailStyle: { width: string; };
        containerSortList: any[];
    };

    fontSizes: FontSizes = {
        textSize: '17px',
        textSizeS: '15px',
        textSizeSs: '13px',
        thumbnail: '50'
    };

    constructor(
        private layoutService: LayoutService,
        private StateService: StateService,
        private DataTaskService: DataTaskService,
       
        
    ) {}

    ngOnInit() {
        this.model = {
            initialGlyphColor: null, 
            itemLoc: {
                listToId: null,         
                itemId: null,
                listFromIndex: null,
                listToIndex: null,
                listFromId: null
            },
            listIsDragged: false,
            didUpdate: false,
            sliderMinDefault: 8,
            mobileSize: window.matchMedia('(max-width: 767px)'),
            sortlist: [],            
            deleteButtonStyle: { fontSize: '' },
            listViewTextStyle: { fontSize: '', paddingLeft: '' },
            thumbnailStyle: { width: '' },
            containerSortList: []
            };


        // Handles the layout sizing based on user preference
        this.layoutService.layoutChanged.subscribe(info => {
            if (info) {
                this.fontSizes.textSize = info.fontSizes.textSize;
                this.fontSizes.textSizeSs = info.fontSizes.textSizeSs;
                this.fontSizes.textSizeS = info.fontSizes.textSizeS;
            }
        });
    }

    // Checks to see if ttKanbanCategories have been loaded, and changes on change
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['ttKanbanCategories']) {
            this.populateSortlist(this.ttKanbanList, changes['ttKanbanCategories'].currentValue);
        }
    }

    public getNumbers(count: number): number[] {
        return Array.from({ length: count }, (_, i) => i);
    }


    // On item dropped into new kanban category function
    // Updates the position and saves it to the database
    drop(event: CdkDragDrop<List[]>, groupIndex: number) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
            this.updateOrder(event.container.data);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex 
            );
            this.model.itemLoc.itemId = event.item.data.item_id;
            this.model.itemLoc.listFromIndex = parseInt(event.previousContainer.id.charAt(event.previousContainer.id.length - 1));
            this.model.itemLoc.listFromId = this.model.sortlist[this.model.itemLoc.listFromIndex].key;
            this.model.itemLoc.listToIndex = parseInt(event.container.id.charAt(event.container.id.length - 1));
            this.model.itemLoc.listToId = this.model.sortlist[this.model.itemLoc.listToIndex].key;
            this.updateOrder(event.container.data);
            this.updateItemList(this.model.itemLoc);
        }
        this.saveKanbanSort(this.model.itemLoc);
    }

    // Updates an individual kanban element so it corresponds in the future with the correct sort list
    updateItemList(itemLoc: any) {
        if (this.ttKanbanList) {
            const container = this.ttKanbanList as ttKanbanListContainer;

            if (container.records && container.records.length) {
                // If records exist, loop through them
                for (let i = 0; i < container.records.length; i++) {
                    if (container.records[i].item_id === itemLoc.itemId) {
                        container.records[i].item_sort_id = itemLoc.listToId;
                        break;
                    }
                }
            } else if (Array.isArray(this.ttKanbanList)) {
                // If ttKanbanList is just an array
                for (let i = 0; i < this.ttKanbanList.length; i++) {
                    const item = this.ttKanbanList[i] as ttKanbanItem;
                    if (item.item_id === itemLoc.itemId) {
                        item.item_sort_id = itemLoc.listToId;
                        break;
                    }
                }
            }
        }

    }

    // Sorts all the items in a list on drop
    // Sorting based on id number, lowest goes on top
    updateOrder(data: List[]) {
        this.model.containerSortList = data;
        this.model.containerSortList.sort((a, b) => {
            return Number(a.item_id) - Number(b.item_id);
        });
    }

    // Save a single kanban item
    // Returns an error if there was an error on saving
    // TODO: modalService er ikkke konvertert til TypeScript, Fjern kommenteringen nedenfor når det er gjort for å få
    // en innebygd error modal
    async saveKanbanSort(data: any): Promise<void> {
        ;
        if (data.itemId?.trim() && data.listToId?.trim() && data.listToIndex !== undefined && data.istToIndex !== null) {
            const response = await this.save(data.itemId, data.listToId, data.listToIndex);

            // TODO: Dette er en midlertidlig fix, modalService.js må også konverteres til TypeScript
            if (response[0].errorcode !== '0') {
                alert(response[0].errormessage);
            }

            //// TODO: fjern kommenteringen herfra når modalService er oversatt til typescript
            //// if (data[0].errorcode !== '0') {
            ////     this.modalService.show({
            ////         type: 'warning',
            ////         title: 'Varsel',
            ////         message: data[0].errormessage,
            ////         buttons: [
            ////             {
            ////                 label: 'OK',
            ////                 cssClass: 'btn-warning',
            ////                 action: (dialogItself: any) => dialogItself.close() // Adjust the type if necessary
            ////             },
            ////         ],
            ////     });
            //// }
        }
    }

    // Saves kanban item data to the database
    save(custact_no: string, actstatus_keyno: string, listToIndex: number): Promise<any> {
        return this.DataTaskService.Post(1417, {
            custact_no,
            actstatus_keyno,
            listToIndex
        });
    }

    // Function responsible for changing the page to the corrrect item onClick
    // Stateservice.go takes a 'workdetails' parameter, signalling which url it will go to
    // This can be changed if needed in future iterations
    async goToActivity(item: any): Promise<void> {
        if (this.isCustomClick(item)) return
        if (!item.item_parms) return;
        // Change 'workdetails' to any other page if fits your needs
        this.StateService.go('workdetails', item.item_parms);
    }

 
 
    goTo(l_item: any, e: any) {
        if (this.isCustomClick(l_item)) return;
        if (e.ctrlKey) {
            this.StateService.newTab(l_item.item_state, l_item.item_parms);
        } else {
            this.StateService.go(l_item.item_state, l_item.item_parms);
        }
    }

    // Checks if the click is a custom click
    // In it's current state it will awlays return false (2nd line in function).
    isCustomClick(item: any) {
        if (this.preventCustomClick) return true;
        if (this.ttClickable === null || this.ttClickable) return false;
        if (this.ttClick) {
            this.ttClick.emit({ item });
        }
        return true;
    }
    
    // Adds the glyph icon to the label 
    addGlyph(item: any) {
        let allClass = 'glyphicon glyphicon-sort';
        if (item.item_glyphicon?.length) {
            const glyphStr = item.item_glyphicon_2 ?? item.item_glyphicon;
            if (glyphStr.startsWith('fa') && [2, 3].includes(glyphStr.indexOf('-'))) {
                allClass = `im-no-mar ${glyphStr.slice(0, glyphStr.indexOf('-'))} fa${glyphStr.slice(glyphStr.indexOf('-'))}`;
            } else {
                allClass = 'glyphicon ' + glyphStr;
            }
        }
        return allClass;
    }

    // Handles color options
    addStyleWell(item: any) {
        this.model.initialGlyphColor = this.model.initialGlyphColor || item.item_glyphicon_color || '';
        return {
            background: item.item_glyphicon_color || 'purple',
            color: item.item_glyphicon_color ? 'white' : 'black'
        };
    }

    // Function responsible for handling the layout options
    // Could / Should be converted into a more flexible design without switch cases
    // Handles mobile design too (mobilSize.matches)
    addClass(): string {
        let allClass = 'well well-sq sort-list-container col-xs-12';
        if (!this.model.mobileSize.matches) {
            const sortListLength = this.model.sortlist.length;
            switch (sortListLength) {
                case 2:
                    allClass = 'well well-sq sort-list-container col-xs-6';
                    break;
                case 3:
                    allClass = 'well well-sq sort-list-container col-xx-4';
                    break;
                case 4:
                    allClass = 'well well-sq sort-list-container col-xx-6 col-xs-6 col-sm-3 col-lg-3';
                    break;
                case 6:
                    allClass = 'well well-sq sort-list-container col-xx-4 col-xs-2';
                    break;
                case 8:
                    allClass = 'well well-sq sort-list-container col-xx-1-5';
                    break;
                case 12:
                    allClass = 'well well-sq sort-list-container col-xs-1';
                    break;
                default:
                    allClass = 'well well-sq sort-list-container col-xs-12';
                    break;
            }
        }
        return allClass;
    }

    // Handles styling for mobile uinits
    addStyleSortlist(item: any): { [key: string]: string } {
        const allStyle = { minWidth: '5px', paddingTop: '6px', color: '#ffffff', width: 'auto' }; // default

        if (window.innerWidth >= 384 && window.innerWidth <= 767) {
            allStyle.width = '25px';
        } else {
            allStyle.width = 'auto';
        }

        return allStyle;
    }

    // Populates the entire kanban view with its elements
    populateSortlist(newValue: any, oldValue: any): void {
        console.log("check");
        if (newValue === oldValue || !this.isObject(this.ttKanbanCategories) || !this.isObject(this.ttKanbanList)) return;

        const doPopulate = () => {
            // Clear the sortlist
            this.model.sortlist.length = 0;

            // Check if ttKanbanCategories is defined and is an array before iterating
            let index = 0;
            if (Array.isArray(this.ttKanbanCategories)) {
                for (const item of this.ttKanbanCategories) {
                    if (
                        this.dataId &&
                        this.dataName &&
                        item[this.dataId] &&
                        item[this.dataName] &&
                        item[this.dataId] !== '0'
                    ) {
                        this.model.sortlist.push({
                            key: item[this.dataId],
                            keyName: this.dataId,
                            value: item[this.dataName],
                            valueName: this.dataName,
                            item_glyphicon: item.glyphicon,
                            item_glyphicon_color: item.glyphicon_color,
                            items: [],
                            index: index
                        });
                        index++;
                    }
                }
            }
            // Helper function to add items to the sortlist
            const addItemsToSortlist = (array: any[]) => {
                for (const item of array) {
                    if (this.isObject(item)) {
                        const sortItem = this.model.sortlist.find(s => s.key === item.item_sort_id);
                        if (sortItem) {
                            sortItem.items.push(item);
                        }
                    }
                }
            };

            // Populate items based on ttKanbanList records or length
            if (this.ttKanbanList?.length) {
                addItemsToSortlist(this.ttKanbanList);
            } else if (Array.isArray(this.ttKanbanList)) {
                addItemsToSortlist(this.ttKanbanList);
            }
        };

        if (this.groupTimer) {
            clearTimeout(this.groupTimer);
        }

        this.groupTimer = setTimeout(doPopulate, 0);
    }

    private isObject(value: any): boolean {
        return value && typeof value === 'object';
    }

    ngOnDestroy(): void {
        if (this.groupTimer) {
            clearTimeout(this.groupTimer);
        }
    }

}
