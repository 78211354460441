(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('workGrp', {
        templateUrl: 'views/components/views/workGrp/workGrp.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'workGrpService', 'labourPositionService', function ($stateParams, stateService, modalService, translateService, workGrpService, labourPositionService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let workGrpKeyNo = $stateParams.workgrp_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                factoryButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('factories') }
                ],
                edit: {},
                selectListFactories: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                workGrpService.getWorkGroup({ workgrp_keyno: workGrpKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };

            let loadSelectFactories = function () {
                labourPositionService.listSelectFactories().then(function (result) {
                    angular.copy(result, vm.model.selectListFactories);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                workGrpService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadSelectFactories();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
