(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2TableEdit', {
        templateUrl: 'views/components/views/p2TableEdit/p2TableEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2TableEditService', 'stateService', function ($stateParams, p2TableEditService, stateService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;
            var isSaving = false;
            
            vm.lockingP2TableEditSaveButton = {
                locked: '0'
            };
            
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
            
            p2TableEditService.loadP2TableEdit($stateParams.p2_table_keyno || '0').then(function () {
                vm.p2_table_edit = p2TableEditService.p2_table_edit;
            });
            
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
            
            vm.saveP2TableEdit = function () {
                if (isSaving == true || vm.lockingP2TableEditSaveButton.locked == '1') return;

                isSaving = true;
                vm.lockingP2TableEditSaveButton.locked = '1';

                p2TableEditService.saveP2TableEdit().then(function () {
                    stateService.back();
                });

                isSaving = false;
            };
            
            // #########################
            // DIRECTION BUTTON FUNCTION
            // #########################
            
            vm.goToP2WebpageTable = function () {
                stateService.go('p2webpagetable', {
                    argtype: 'p2_table_keyno',
                    argvalue: vm.p2_table_edit.p2_table_keyno
                });
            };
        }]
    });
})();