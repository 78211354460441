(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srNotCompleted', {
        templateUrl: 'views/components/views/srNotCompleted/srNotCompleted.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'srNotCompletedService', function($stateParams, stateService, srNotCompletedService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                resourceId: $stateParams.resource_id,
                setting: {},
    			p2SrReportListNoTokList: {
    				records: []
    			}
            };
    
            // ## IF CONDITIONS FUNCTIONS ##
    
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.setting[parmWithValue]) === true) return false;
                if (vm.model.setting[parmWithValue] === null) return false;
                if (vm.model.setting[parmWithValue].trim().length < 1) return false;
    
                return true;
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srNotCompletedService.loadSrNotCompletedGet(vm.model.resourceId).then(function (data) {
                vm.model.setting = angular.copy(data[0]);
    
                loadP2SrReportListNoTokList();
            });
    
            var loadP2SrReportListNoTokList = function () {
                if (validateParmsWithValue('resource_id') !== true) return;
    
    			vm.model.p2SrReportListNoTokList.records = [];
    
                srNotCompletedService.loadP2SrReportListNoTokList(vm.model.setting.resource_id).then(function (data) {
                    angular.copy(data, vm.model.p2SrReportListNoTokList.records);
    
                    //angular.forEach(vm.model.p2SrReportListNoTokList.records, function (rec) {
                    //    if (rec.data_type !== 'G') {
                    //        rec.badgeinfo = '';
                    //    }
                    //});
    			});
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srnotcompletednew':
                        go({
                            resource_id: vm.model.setting.resource_id
                        });
                        break;
                }
            };
        }]
    });
})();