(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("dispatchPlanningBlockOrderService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            listOrders: function (parms) {
                return p2DataTaskService.call(1572, parms);
            }
        };

        return service;
    }]);
})();