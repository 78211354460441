(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2ClientUpdateEdit', {
        templateUrl: 'views/components/views/p2ClientUpdateEdit/p2ClientUpdateEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2ClientUpdateEditService', function ($stateParams, p2ClientUpdateEditService) {
            var vm = this;
            vm.p2_client_update_edit = p2ClientUpdateEditService.p2_client_update_edit;
            
            p2ClientUpdateEditService.loadP2ClientUpdateEdit($stateParams.clientupdate_id).then(function () {
                vm.p2_client_update_edit = p2ClientUpdateEditService.p2_client_update_edit;
            });
            
            vm.refreshP2ClientUpdateEdit = function () {
                p2ClientUpdateEditService.loadP2ClientUpdateEdit($stateParams.clientupdate_id).then(function () {
                    vm.p2_client_update_edit = p2ClientUpdateEditService.p2_client_update_edit;
                });
            };
        }]
    });
})();