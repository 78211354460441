(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prePayments', {
        templateUrl: 'views/components/views/prePayments/prePayments.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'prePaymentsService', function ($stateParams, stateService, prePaymentsService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                setting: {},
                itemsListPrePayments: []
            };

            let loadSetting = function () {
                prePaymentsService.getPrePaymentSum({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadPrePayments = function () {
                prePaymentsService.listPrePayments({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListPrePayments);
                });
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'prepayment':
                        go({
                            argtype: argType,
                            argvalue: argValue,
                            prepayment_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
                loadPrePayments();
            };
        }]
    });
})();
