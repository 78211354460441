(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('confirmationTerminalScan', {
        templateUrl: 'views/components/views/confirmationTerminalScan/confirmationTerminalScan.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', '$timeout', 'stateService', 'confirmationTerminalScanService', 'modalDynamicViewFactory', function ($q, $stateParams, $timeout, stateService, confirmationTerminalScanService, modalDynamicViewFactory) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.scanDatatask = '';
            //vm.scanValue = '';
            vm.bgColor = '';
            vm.scan = {
                scanHistory: [],
                scanSequence: [],
                prevScanValue: '',
                currScanValue: ''
            }
            vm.scanResponse = {
                result: {},
                details: '',
                header: ''
            };

            //console.dir($stateParams);
            //console.dir(stateService);

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            // #endregion OPTIONFUNC

            // #region UTILITY FUNCTIONS

            var initDetailPopup = function () {
                var deferred = $q.defer();
                //var promises = [];

                //promises.push(invoiceCustomerBonusService.getStockplaces({}));
                //promises.push(invoiceCustomerBonusService.getCustomerGroups());
                //promises.push(invoiceCustomerBonusService.getCoops());
                //promises.push(invoiceCustomerBonusService.getProductGroups());

                //$q.all(promises).then(function (response) {
                var scanDetailPopup = modalDynamicViewFactory.$create({
                    //url: '',
                    data: [
                        {
                            name: 'scan',
                            info: {
                                label: 'scan_label',
                                placeholder: '',
                                type: 'updown',
                                translate: false,
                            },
                            data: {
                                field: '',
                                list: [{
                                    item_id: '0',
                                    item_name: 'Test 01',
                                    value: 0,
                                    decimal: 0,
                                    multiplier: 1,
                                    min: null,
                                    max: null,
                                    available: null
                                }],
                                id: 'item_id',
                                name: 'item_name',
                                filter: '',
                                allSelected: false
                            },
                            disabled: function () { return false; }
                        }
                    ]
                });

                scanDetailPopup.show().then(function (config) {
                    return deferred.resolve(config);
                });
                //});

                return deferred.promise;
            };

            function setHeader(value) {
                if (angular.isDefined(value) && value !== null) {
                    vm.scanResponse.header = value;
                } else {
                    switch ($stateParams.mode) {
                        case 'stocktransfer':
                            vm.scanResponse.header = 'Stock Transfer - Scan';
                            break;
                        case 'requisition':
                            vm.scanResponse.header = 'Requisition - Scan pallet';
                            break;
                        default:
                            vm.scanResponse.header = '';
                    }
                }
            }

            function decodeParm(parm) {
                if (angular.isUndefined(parm)) return null;
                var decoded = atob(unescape(decodeURIComponent(parm)));
                //console.dir(decoded);
                return decoded;
            }

            function getRandomInt(min, max) {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }

            // #endregion UTILITY FUNCTIONS

            // #region ONSCAN

            vm.onScan = function (value) {
                vm.scan.currScanValue = value;

                if (value.length === 0) return;

                switch ($stateParams.mode) {
                    case 'stocktransfer':
                        //vm.scanDatatask = 2325;
                        vm.scanDatatask = 2343;
                        break;
                    case 'requisition':
                        vm.scanDatatask = 2224;
                        break;
                    default:
                        vm.scanDatatask = null;
                }

                if (vm.scanDatatask === null) return;

                confirmationTerminalScanService.scan(vm.scanDatatask, { scan_sequence: vm.scan.scanSequence, prev_scan_value: vm.scan.prevScanValue, scan_value: vm.scan.currScanValue }).then(function (result) {
                    //console.log('confirmationTerminalScanService');
                    vm.scanResponse.result = result[0];

                    var decodedDetails = decodeParm(vm.scanResponse.result.item_details);

                    console.log('scan complete');
                    console.dir(vm.scan.currScanValue);
                    console.dir(vm.scanResponse);
                    console.dir(decodeParm(vm.scanResponse.result.item_details));

                    if (parseInt(vm.scanResponse.result.errorcode) === 0) {
                        vm.bgColor = 'btn-success';
                        if (angular.isDefined(vm.scanResponse.result.statuscode) && angular.isDefined(vm.scanResponse.result.statusmessage) && vm.scanResponse.result.statusmessage.length > 0) {
                            vm.scanResponse.header = vm.scanResponse.result.statusmessage;
                        }
                    } else {
                        vm.bgColor = 'btn-danger';
                    }

                    if (decodedDetails !== null) {
                        vm.scanResponse.details = decodedDetails;
                    }

                    vm.scan.prevScanValue = vm.scan.currScanValue;
                    vm.scan.scanSequence.push(vm.scan.currScanValue);

                    if (angular.isDefined(vm.scanResponse.result.statuscode) && vm.scanResponse.result.statuscode === '99') {
                        vm.scan.scanHistory.push(vm.scan.scanSequence);
                        vm.scan.scanSequence = [];
                        vm.scan.prevScanValue = '';
                    }

                    //console.dir(vm.scan.scanSequence);

                    $timeout(function () {
                        vm.scan.currScanValue = '';
                        $timeout(function () {
                            vm.bgColor = '';
                        }, 3500);
                    }, 500);
                });
            };

            vm.onScan3 = function (value) {
                vm.scan.currScanValue = value;

                if (value.length === 0) return;

                initDetailPopup().then(function (detail) {
                    console.log('detail');
                    console.dir(detail);
                    //vm.icbConfig.data = {
                    //    stockplace_id: config[0].data.field,
                    //    from_date: config[1].data.date_fom,
                    //    to_date: config[1].data.date_tom,
                    //    cust_grp_id: config[2].data.field,
                    //    cust_coop_id: config[3].data.field,
                    //    cust_no: config[4].data.field,
                    //    prod_grp_id: config[5].data.field
                    //};
                });

                var fakeresult = {

                }

                //if 

                $timeout(function () {
                    vm.scan.currScanValue = '';
                    $timeout(function () {
                        vm.bgColor = '';
                    }, 3500);
                }, 500);
            };

            vm.onScan2 = function (value) {
                vm.scan.currScanValue = value;

                if (value.length === 0) return;

                switch ($stateParams.mode) {
                    case 'stocktransfer':
                        //vm.scanDatatask = 2325;
                        vm.scanDatatask = 2325;
                        break;
                    case 'requisition':
                        vm.scanDatatask = 2224;
                }

                //confirmationTerminalScanService.requisitionScan(1000002, { carriers_keyno: vm.scanValue}).then(function (result) {
                //confirmationTerminalScanService.requisitionScan(2224, { carriers_keyno: vm.scanValue }).then(function (result) {
                //confirmationTerminalScanService.requisitionScan({ carriers_keyno: vm.scanValue }).then(function (result) {
                confirmationTerminalScanService.scan(vm.scanDatatask, { scan_sequence: vm.scan.scanSequence, prev_scan_value: vm.scan.prevScanValue, scan_value: vm.scan.currScanValue }).then(function (result) {
                    //console.log('confirmationTerminalScanService');
                    vm.scanResponse.result = result[0];

                    console.log('scan complete');
                    console.dir(vm.scan.currScanValue);
                    console.dir(vm.scanResponse);
                    console.dir(decodeParm(vm.scanResponse.result.item_details));

                    if (parseInt(vm.scanResponse.result.errorcode) === 0) {
                        vm.bgColor = 'btn-success';
                    } else {
                        vm.bgColor = 'btn-danger';
                    }

                    var from, to, carrier, choice, qty;

                    switch ($stateParams.mode) {
                        case 'stocktransfer':
                            from = angular.isDefined(vm.scanResponse.result.stockzone_from) ? vm.scanResponse.result.stockzone_from : '';
                            to = angular.isDefined(vm.scanResponse.result.stockzone_to) ? vm.scanResponse.result.stockzone_to : '';
                            carrier = angular.isDefined(vm.scanResponse.result.carriers_keyno) ? vm.scanResponse.result.carriers_keyno : vm.scan.currScanValue;
                            choice = '';
                            qty = angular.isDefined(vm.scanResponse.result.qty_sent) ? vm.scanResponse.result.qty_sent : '0';
                            break;
                        case 'requisition':
                            from = angular.isDefined(vm.scanResponse.result.sp_from) ? vm.scanResponse.result.sp_from : '';
                            to = angular.isDefined(vm.scanResponse.result.sp_to) ? vm.scanResponse.result.sp_to : '';
                            carrier = angular.isDefined(vm.scanResponse.result.carriers_keyno) ? vm.scanResponse.result.carriers_keyno : vm.scan.currScanValue;
                            choice = angular.isDefined(vm.scanResponse.result.choice) ? vm.scanResponse.result.choice : '';
                            qty = angular.isDefined(vm.scanResponse.result.qty_shipped) ? vm.scanResponse.result.qty_shipped : '0';
                    }

                    var line1 = parseInt(vm.scanResponse.result.errorcode) === 0 ? vm.scanResponse.result.statusmessage : vm.scanResponse.result.errormessage;
                    var line2 = '\n\nFROM: ' + from;
                    var line3 = ' --> TO: ' + to;
                    var line4 = '\n\nPallet: ' + carrier //'\n\nPallet: ' //'\n\nScanned Value: '
                    var line5 = '\nArticle: ' + vm.scanResponse.result.prod_id + ' - ' + vm.scanResponse.result.prod_name;
                    var line6 = '\n' + choice;
                    var line7 = '\n\nQuantity: ' + qty;

                    vm.scanResponse.header = line1;

                    vm.scanResponse.details = ''
                        + line2 + line3
                        + line4
                        + line5
                        + line6
                        + line7;
                    //vm.scanResponse = 'Requisition-Scan Pallet';

                    vm.scan.prevScanValue = vm.scan.currScanValue;
                    vm.scan.scanSequence.push(vm.scan.currScanValue);

                    //console.dir(vm.scan.scanSequence);

                    $timeout(function () {
                        vm.scan.currScanValue = '';
                        $timeout(function () {
                            vm.bgColor = '';
                        }, 3500);
                    }, 500);
                });
            };

            // #endregion ONSCAN

            // #region LOAD PROCEDURE CALLS

            angular.element(document).ready(function () {
                setHeader();
            });

            // #endregion LOAD PROCEDURE CALLS
        }]
    });
})();
