(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("transportAreaService", ['$ihttp', function ($ihttp) {
		let service = {
            getTransportArea: function (parms) {
                return $ihttp.post({
                    method: 1399,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 1400,
                    parameters: edit
                });
            }
		};

		return service;
	}]);
})();
