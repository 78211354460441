(function() {
    'use strict';

    angular.module("imApp").factory("ttDatasourceParameterEditService", ['$ihttp', function($ihttp) {
        var service = {
            getDatasourceParameterSources: function (datasourceKeyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'datasourceParameterSources',
                        tt_datasource_keyno: datasourceKeyno
                    }
                });
            },

            update: function (datasourceParameter) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'updateDatasourceParameter',
                        datasourceParameter: datasourceParameter
                    }
                });
            },

            deleteParameter: function (ttDatasourceParameterKeyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'deleteDatasourceParameter',
                        tt_datasourceparameter_keyno: ttDatasourceParameterKeyno
                    }
                });
            }
        }

        return service;
    }]);
})();
