(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentPlanning', {
        templateUrl: 'views/components/views/consignmentPlanning/consignmentPlanning.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$timeout', 'stateService', 'consignmentPlanningService', 'searchService', 'eventService', function ($timeout, stateService, consignmentPlanningService, searchService, eventService) {
            var vm = this;
            var initLoadDone = false;
            var timerPlanningDeliveryWeeksList = null;

            vm.model = {
                custactButtons: [
                    { item_id: '1', item_name: 'check', item_func: 'create_new', glyph: 'glyphicon-plus', color: 'success' },
                    { item_id: '2', item_name: 'warning', item_func: 'goto_custact', glyph: 'glyphicon-chevron-right', color: 'primary' }
                ],
                custactConsignmentPlanSelectedButtons: [
                    { id: 'create_new', icon: 'glyphicon glyphicon-plus', color: 'success', type: 'success', onClick: () => vm.addConsignmentPlan() },
                    { id: 'goto_custact', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: () => vm.goToSubTasks() },
                ],
                custactConsignmentPlanButtons: [
                    { id: 'create_new', icon: 'glyphicon glyphicon-plus', color: 'success', type: 'success', onClick: () => vm.addConsignmentPlan() },
                ],
                gbPlanningType: {
                    item_default: searchService.consignmentPlanningDefaults.gbPlanningType_item_default ?? '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignmentplanning_gb1_status', item_func: 'status' },
                        { item_id: '1', item_name: 'consignmentplanning_gb1_planning', item_func: 'planning' },
                        { item_id: '2', item_name: 'consignmentplanning_gb1_plans', item_func: 'plans' }
                    ]
                },
                gbPlanAction: {
                    item_default: searchService.consignmentPlanningDefaults.gbPlanAction_item_default ?? '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignmentplanning_gb2_not_planned', item_func: 'add' },
                        { item_id: '1', item_name: 'consignmentplanning_gb2_planned', item_func: 'planned' }
                    ]
                },
                gbPlansStatus: {
                    item_default: searchService.consignmentPlanningDefaults.gbPlansStatus_item_default ?? '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignmentplanning_editplan', item_func: 'editplan' },
                        { item_id: '1', item_name: 'consignmentplanning_statusplan', item_func: 'statusplan' },
                        { item_id: '2', item_name: 'consignmentplanning_sendplan', item_func: 'sendplan' }
                    ]
                },
                gbPlanStatus: {
                    item_default: searchService.consignmentPlanningDefaults.gbPlanStatus_item_default ?? '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'consignmentplanning_ready', item_func: 'ready' },
                        { item_id: '1', item_name: 'consignmentplanning_almost_ready', item_func: 'almost_ready' },
                        { item_id: '2', item_name: 'consignmentplanning_not_ready', item_func: 'not_ready' }
                    ]
                },
                btnListFilterVal: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                p2WConsignmentPlanning: {},
                p2ConsignmentPlanningDeliveryWeeksList: {
                    records: [],
                    records_indexed: {}
                },
                p2CustactConsignmentPlansList: {
                    records: []
                },
                selected: {
                    count: 0,
                    total_volum: 0,
                    total_weight: 0
                },
                removable: {
                    count: 0,
                },
                p2ConsignmentScan: {},
                p2ConsignmentPlanning: {
                    records_1: [],
                    records_2: [],
                    records_3: [],
                    records_not_planned: [],
                    records_planned: []
                },
                listSearch: {
                    filterVal: ''
                },
                deliveryMethods: {
                    records: [],
                    indexed_records: {}
                }
            };

            /*********************************
            other functions
            *********************************/

            vm.validateUpdateDrivingPlan = function () {
                if (vm.model.gbPlanningType.item_selected == 'plans' && vm.model.p2WConsignmentPlanning.custact_no_consignmentplan !== '0' && vm.model.p2WConsignmentPlanning.stockplace_id && vm.model.p2WConsignmentPlanning.deliveryweek && vm.model.p2WConsignmentPlanning.deliverymethod_no) {
                    if (vm.model.p2WConsignmentPlanning.deliverymethod_no == '') {
                        vm.model.p2WConsignmentPlanning.deliverymethod_no = '0';
                    }

                    consignmentPlanningService.loadP2ConsignmentPlans(vm.model.p2WConsignmentPlanning.stockplace_id, vm.model.p2WConsignmentPlanning.custact_no_consignmentplan, vm.model.p2WConsignmentPlanning.deliveryweek, vm.model.p2WConsignmentPlanning.deliverymethod_no).then(function () {
                        vm.model.p2ConsignmentPlans = consignmentPlanningService.p2ConsignmentPlans;
                    });
                }

                vm.validateCustactNoConsignmentPlan();
            };

            vm.dropdownTestFunc = function (item_func) {
                switch (item_func) {
                    case 'create_new':
                        vm.addConsignmentPlan();
                        break;
                    case 'goto_custact':
                        vm.goToSubTasks();
                        break;
                }
            };

            vm.goToSubTasks = function (index) {
                stateService.go('subtasks', {
                    custact_no: vm.model.p2WConsignmentPlanning.custact_no_consignmentplan,
                    retrievetype: 'child',
                    include_top: '1'
                });
            };

            vm.addConsignmentPlan = function () {
                stateService.go('custactedit', {
                    custact_no: '0',//vm.model.p2WConsignmentPlanning.custact_no_consignmentplan,
                    filterfield: 'cust_no',
                    filtervalue: vm.model.p2WConsignmentPlanning.businessco_cust_no
                });
            };

            vm.addP2ConsignmentPlanningOrders = function () {
                consignmentPlanningService.addP2ConsignmentPlanningOrders();
            };

            vm.removeP2ConsignmentPlanningOrders = function () {
                consignmentPlanningService.removeP2ConsignmentPlanningOrders();
            };

            vm.removeP2ConsignmentPlansOrders = function () {
                consignmentPlanningService.removeP2ConsignmentPlansOrders().then(function () {
                    vm.validateUpdateDrivingPlan();
                });
            };

            vm.searchP2ConsignmentScan = function (item) {
                consignmentPlanningService.searchP2ConsignmentScan('oi' + item.item_id).then(function () {
                    vm.model.p2ConsignmentScan = consignmentPlanningService.p2ConsignmentScan;

                    if (angular.isDefined(vm.model.p2ConsignmentScan)) {
                        stateService.go(vm.model.p2ConsignmentScan.webpagename, vm.model.p2ConsignmentScan.parms);
                    }
                });
            };

            vm.getCurrentDeliveryWeekListItem = function () {
                var out = false;

                if (angular.isDefined(vm.model.p2ConsignmentPlanningDeliveryWeeksList.records) && vm.model.p2ConsignmentPlanningDeliveryWeeksList.records.length > 0) {
                    if (angular.isDefined(vm.model.p2WConsignmentPlanning.deliveryweek) && vm.model.p2WConsignmentPlanning.deliveryweek !== '') {
                        out = vm.model.p2ConsignmentPlanningDeliveryWeeksList.records_indexed[vm.model.p2WConsignmentPlanning.deliveryweek];
                    }
                }

                return out;
            };

            function clearRecords() {
                vm.model.p2ConsignmentPlanning.records_1 = [];
                vm.model.p2ConsignmentPlanning.records_2 = [];
                vm.model.p2ConsignmentPlanning.records_3 = [];
                vm.model.p2ConsignmentPlanning.records_not_planned = [];
                vm.model.p2ConsignmentPlanning.records_planned = [];
                onRecordsNotPlannedChanged();
                onRecordsPlannedChanged();
            }

            vm.markRecord = function (item) {
                onRecordsNotPlannedChanged();
                onRecordsPlannedChanged();
            };

            vm.deliveryWeekChange = function () {
                if (vm.model.p2WConsignmentPlanning.stockplace_id && vm.model.p2WConsignmentPlanning.deliveryweek && vm.model.p2WConsignmentPlanning.deliverymethod_no) {
                    let item = vm.getCurrentDeliveryWeekListItem();//vm.model.p2ConsignmentPlanningDeliveryWeeksList.records_indexed[vm.model.p2WConsignmentPlanning.deliveryweek];

                    if (item?.date_fom && initLoadDone === true) {
                        consignmentPlanningService.loadP2ConsignmentPlanning(vm.model.p2WConsignmentPlanning.stockplace_id, item.date_fom, item.date_tom, vm.model.p2WConsignmentPlanning.deliveryweek, vm.model.p2WConsignmentPlanning.deliverymethod_no).then(function () {
                            vm.model.p2ConsignmentPlanning = consignmentPlanningService.p2ConsignmentPlanning;
                            onRecordsNotPlannedChanged();
                            onRecordsPlannedChanged();
                        });

                    } else {
                        clearRecords()
                    }
                }
            };

            vm.validateCustactNoConsignmentPlan = function () {
                if (vm.model.p2WConsignmentPlanning.custact_no_consignmentplan != '0') {
                    vm.model.custactButtons = [
                        { item_id: '1', item_name: 'check', item_func: 'create_new', glyph: 'glyphicon-plus', color: 'success' },
                        { item_id: '2', item_name: 'warning', item_func: 'goto_custact', glyph: 'glyphicon-chevron-right', color: 'primary' }
                    ];
                } else {
                    vm.model.custactButtons = [
                        { item_id: '1', item_name: 'check', item_func: 'create_new', glyph: 'glyphicon-plus', color: 'success' }
                    ];
                }
            };

            vm.getGroupButtonItemId = function (gb) {
                var s = gb.item_selected;
                var out = 0;

                if (angular.isDefined(gb.records)) {
                    if (gb.records.length > 0) {
                        angular.forEach(gb.records, function (item) {
                            if (item.item_func == s) {
                                out = item.item_id;
                            }
                        });
                    }
                }

                return out;
            };

            function loadConsignmentPlanning() {
                return consignmentPlanningService.loadP2WConsignmentPlanning().then(function () {
                    vm.model.p2WConsignmentPlanning = consignmentPlanningService.p2WConsignmentPlanning;
                    initLoadDone = true;
                    vm.loadP2ConsignmentPlanningDeliveryWeeksList();
                });
            }

            function loadStockplaces() {
                return consignmentPlanningService.p2StockplacesList().then(function () {
                    vm.model.p2Stockplaces = consignmentPlanningService.p2Stockplaces;
                });
            }

            function loadDeliveryMethods() {
                return consignmentPlanningService.deliveryMethodsList('0').then(function () {
                    vm.model.deliveryMethods = consignmentPlanningService.deliveryMethods;
                });
            }

            function loadConsignmentPlansList() {
                return consignmentPlanningService.loadP2CustactConsignmentPlansList('0').then(function () {
                    vm.model.p2CustactConsignmentPlansList = consignmentPlanningService.p2CustactConsignmentPlansList;

                    if (searchService.consignmentPlanningDefaults.custact_no_consignmentplan) {
                        vm.model.p2WConsignmentPlanning.custact_no_consignmentplan = searchService.consignmentPlanningDefaults.custact_no_consignmentplan;
                    }
                });
            }

            vm.loadP2ConsignmentPlanningDeliveryWeeksList = function () {
                if (angular.isDefined(timerPlanningDeliveryWeeksList)) {
                    $timeout.cancel(timerPlanningDeliveryWeeksList);
                }

                timerPlanningDeliveryWeeksList = $timeout(function () {
                    if (angular.isDefined(vm.model.p2WConsignmentPlanning.stockplace_id) && angular.isDefined(vm.model.p2WConsignmentPlanning.deliverymethod_no)) {
                        consignmentPlanningService.loadP2ConsignmentPlanningDeliveryWeeksList(vm.model.p2WConsignmentPlanning.stockplace_id, vm.model.p2WConsignmentPlanning.deliverymethod_no).then(function () {
                            vm.model.p2ConsignmentPlanningDeliveryWeeksList = consignmentPlanningService.p2ConsignmentPlanningDeliveryWeeksList;
                            vm.deliveryWeekChange();
                        });
                    }
                }, 200);
            };

            /*********************************
            button list function
            *********************************/

            vm.btnListFuncFilterVal = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.listSearch.filterVal = '';
                        break;
                    default:
                        break;
                }
            };

            /*********************************
            groupButton selects
            *********************************/

            vm.selectgbPlanningType = function (item) {
                vm.model.gbPlanningType.item_selected = item;

                if (vm.model.gbPlanningType.item_selected == 'plans') {
                    vm.validateUpdateDrivingPlan();
                }
            };

            vm.selectgbPlanStatus = function (item) {
                vm.model.gbPlanStatus.item_selected = item;
            };

            vm.selectgbPlanAction = function (item) {
                vm.model.gbPlanAction.item_selected = item;
            };

            vm.selectgbPlansStatus = function (item) {
                vm.model.gbPlansStatus.item_selected = item;
            };

            vm.onStockPlaceChanged = function (value) {
                vm.model.p2WConsignmentPlanning.stockplace_id = value;
                vm.loadP2ConsignmentPlanningDeliveryWeeksList();
            }

            vm.onDeliveryMethodChanged = function (value) {
                vm.model.p2WConsignmentPlanning.deliverymethod_no = value;
                vm.loadP2ConsignmentPlanningDeliveryWeeksList();
                vm.validateUpdateDrivingPlan();
            }

            function onRecordsNotPlannedChanged() {
                vm.model.selected.total_volum = 0;
                vm.model.selected.total_weight = 0;
                vm.model.selected.count = 0;

                angular.forEach(vm.model.p2ConsignmentPlanning.records_not_planned, function (item) {
                    if (item.item_is_selected !== '0') {
                        vm.model.selected.count += 1;
                        var vol = parseFloat(item.volum);
                        var wei = parseFloat(item.weight);

                        if (vol >= 0) {
                            vm.model.selected.total_volum += vol;
                        }

                        if (wei >= 0) {
                            vm.model.selected.total_weight += wei;
                        }
                    }
                });
            }

            function onRecordsPlannedChanged() {
                vm.model.removable.count = 0;

                angular.forEach(vm.model.p2ConsignmentPlanning.records_planned, function (item) {
                    if (item.item_is_selected !== '0') {
                        vm.model.removable.count += 1;
                    }
                });
            }

            vm.onCustactConsignmentPlanChanged = function (value) {
                vm.model.p2WConsignmentPlanning.custact_no_consignmentplan = value;
                vm.validateUpdateDrivingPlan();
            }

            vm.$onInit = async function () {
                loadStockplaces();
                loadDeliveryMethods();
                await loadConsignmentPlanning();
                await loadConsignmentPlansList();

                if (searchService.consignmentPlanningDefaults.gbPlanningType_item_default === '2') {
                    vm.selectgbPlanningType('plans');
                }
            }

            //onReady
            eventService.on('$viewContentLoaded', function () {
                //Load stuff (or reload stuff from search service...)
                vm.model.listSearch.filterVal = searchService.consignmentPlanningDefaults.filterVal;
            });

            //onLeave
            eventService.on('$stateChangeStart', function () {
                //Save stuff to search service?
                searchService.consignmentPlanningDefaults.gbPlanningType_item_default = vm.getGroupButtonItemId(vm.model.gbPlanningType);//.item_selected;
                searchService.consignmentPlanningDefaults.gbPlanStatus_item_default = vm.getGroupButtonItemId(vm.model.gbPlanStatus);
                searchService.consignmentPlanningDefaults.gbPlanAction_item_default = vm.getGroupButtonItemId(vm.model.gbPlanAction);
                searchService.consignmentPlanningDefaults.custact_no_consignmentplan = vm.model.p2WConsignmentPlanning.custact_no_consignmentplan;
                searchService.consignmentPlanningDefaults.gbPlansStatus_item_default = vm.getGroupButtonItemId(vm.model.gbPlansStatus);
                searchService.consignmentPlanningDefaults.filterVal = vm.model.listSearch.filterVal;
                //gbPlanAction
            });
        }]
    });
})();
