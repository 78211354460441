(function () {
    'use strict';

    angular.module("imApp").factory("workGrpsService", ['$ihttp', function ($ihttp) {
        let service = {
            listWorkGroupsView: function (parms) {
                return $ihttp.post({
                    method: 2020,
                    parameters: parms || {}
                });
            }
		};
        
        return service;
	}]);
})();
