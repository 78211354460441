(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentSplit', {
        templateUrl: 'views/components/views/consignmentSplit/consignmentSplit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'modalService', 'stateService', 'consignmentSplitService', function($stateParams, modalService, stateService, consignmentSplitService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;

            vm.model = {
                consignmentKeynoSplitFrom: $stateParams.consignment_keyno_splitfrom,
                split: {},
                itemsListSplits: [],
                lockedSave: false
            };
            
            // ## IF CONDITIONS FUNCTION ##
            
            var validateParmsWithValue = function (parmWithValue) {
                if (angular.isUndefined(vm.model.split[parmWithValue]) === true) return false;
                if (vm.model.split[parmWithValue] === null) return false;
                if (vm.model.split[parmWithValue].trim().length < 1) return false;
                
                return true;
            };
            
            // ## LOAD PROCEDURE CALLS ##
            
            consignmentSplitService.loadConsignmentSplitGet(vm.model.consignmentKeynoSplitFrom).then(function (data) {
                vm.model.split = angular.copy(data[0]);
                
                loadConsignmentSplits();
            });
            
            var loadConsignmentSplits = function () {
                if (validateParmsWithValue('consignment_keyno_splitfrom') !== true) return;
                
                vm.model.itemsListSplits = [];
                
                consignmentSplitService.loadConsignmentSplitList(vm.model.split.consignment_keyno_splitfrom).then(function (data) {
                    angular.copy(data, vm.model.itemsListSplits);
                });
            };
            
            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0') return;
                if (item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };
            
            // ## SAVE BUTTON FUNCTION ##
            
            vm.saveConsignmentSplit = function () {
                vm.model.lockedSave = true;
                
                var splitList = {
                    consignment_keyno_splitfrom: vm.model.split.consignment_keyno_splitfrom,
                    records: vm.model.itemsListSplits.map(function (a) { return { "item_id": a.item_id, "item_is_selected": a.item_is_selected }; }) 
                };
                
                consignmentSplitService.save(splitList).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();