(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("bulkReturnService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loaded: false,
            productLoopUp: function (prod_id) {
                return p2DataTaskService.call(1545, {
                    prod_id: prod_id
                });
            },
            stockbatchLoopUp: function (prod_id, batchid) {
                return p2DataTaskService.call(1547, {
                    prod_id: prod_id,
                    batchid: batchid
                });
            },
            createCrateForPrint: function(parms){
                return p2DataTaskService.call(1593, parms);
            },
            getBulkReturnReport: function(){
                return p2DataTaskService.call(1600, {});
            },
            getDefaultPrinter: function(){
                return p2DataTaskService.call(973, {variablename: 'bulkreturnPrinter'});
            },
            setDefaultPrinter: function(printer){
                return p2DataTaskService.call(616, {
                    variablename: 'bulkreturnPrinter',
                    variablevalue: printer
                });
            }
        };

        return service;
    }]);
})();