(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ediSourceService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2EdiSourceGet: function (edi_source_keyno) {
                return p2DataTaskService.call(979, {
					edi_source_keyno: edi_source_keyno
				});
            },
            loadP2EdiSourceTypeExtList: function () {
                return p2DataTaskService.call(980, {});
            },
            saveP2EdiSource: function (p2EdiSourceGet) {
                return p2DataTaskService.call(985, p2EdiSourceGet);
			}
        };

        return service;
    }]);
})();