import { Injectable } from '@angular/core';
import { ILayoutChangedEventArgs } from './layout.service';

/**
 * Shared logic between core-components.
 *
 * @author JLR
 * @version 15.02.2024
 */
@Injectable({
    providedIn: 'root',
})
export class CoreComponentService {
    constructor() {}

    /**
     * Merges the styles of the component style object of customizable ttStyle object. Returns a new object containing the styles.
     * The ttStyle style object will take precedence over shared properties between style and ttStyle.
     *
     * @param param object containing the style, ttStyle, property key of main element/s of the component and the components text-align.
     * @returns
     */
    public setStyle({ style, ttStyle, textAlign, mainElement }: SetStyleParams): Style {
        if (!ttStyle) ttStyle = { ...style };

        // Make sure styles are objects.
        if (typeof ttStyle === 'string') {
            ttStyle = this.stringToObject(ttStyle);
        } else if (typeof ttStyle !== 'object') {
            return { ...style };
        }

        // textAlign.
        if (mainElement && mainElement instanceof Array && mainElement.length > 0) {
            mainElement.forEach((element) => this.setTextAlignOnStyles({ style: style, ttStyle: ttStyle as Style, textAlign: textAlign, mainElement: element }));
        } else if (mainElement && typeof mainElement === 'string') {
            this.setTextAlignOnStyles({ style: style, ttStyle: ttStyle, textAlign: textAlign, mainElement: mainElement });
        } else {
            console.error(`Main element missing from coreComponentSerivce - setStyle`);
        }

        return this.mergeObjects(style, ttStyle as Style);
    }

    /**
     * Overrides the elements the classes of the components classes with the classes of the tt-classes.
     *
     * @param param the classes objects to merge, where tt-classes values takes precedence.
     * @returns a new class object with the merged classes.
     */
    public setClasses({ classes, ttClasses }: SetClassesParams): Class {
        if (!ttClasses) ttClasses = { ...classes };

        // Make sure styles are objects.
        if (typeof ttClasses === 'string') {
            ttClasses = this.stringToObject(ttClasses);
        } else if (typeof ttClasses !== 'object') {
            return { ...classes };
        }

        return this.mergeObjects(classes, ttClasses as Class);
    }

    /**
     * Sets the layout style from the layout changed info to the style of the component.
     *
     * @param style the component's style object.
     * @param info the layout changed info.
     */
    public setLayoutStyle(style: Style, info: ILayoutChangedEventArgs) {
        // Object.keys(style).forEach((key) => {
        // if (['input', 'date'].includes(key)) {
        /*style[key].fontSize = info.fontSizes.textSize;
                style[key].height = info.height + 'px';
                style[key].paddingTop = info.padding.top + 'px';
                style[key].paddingBottom = info.padding.bottom + 'px';
                style[key].paddingLeft = info.padding.left + 'px';
                style[key].paddingRight = info.padding.right + 'px';*/
        /*} else if (key === 'textarea') {
                style[key].fontSize = info.fontSizes.textSize;
                style[key].minHeight = '0';
                style[key].maxHeight = 'none';
                // style[key].height = info.height * 3 + 'px';
                style[key].minHeight = info.height * 3 + 'px';
                style[key].paddingTop = info.padding.top + 'px';
                style[key].paddingBottom = info.padding.bottom + 'px';
                style[key].paddingLeft = info.padding.left + 'px';
                style[key].paddingRight = info.padding.right + 'px';*/
        // } else if (key === 'select') {
        //     style[key].fontSize = info.fontSizes.textSize;
        //     style[key].height = info.height + 'px';
        //     style[key].paddingTop = info.padding.top + 'px';
        //     style[key].paddingBottom = info.padding.bottom + 'px';
        //     style[key].paddingLeft = info.padding.left + 'px';
        //     style[key].paddingRight = '2rem';
        /*} else if (key === 'checkbox') {
                style[key].height = info.fontSizes.textSize;
                style[key].width = info.fontSizes.textSize;
                style[key].fontSize = info.fontSizes.textSize;*/
        // } else if (key === 'iconButtonText') {
        //     style[key] = info.fontSizes.textSizeL;
        // } else if (key === 'icon' || key === 'buttonText') {
        //     style[key].fontSize = info.fontSizes.textSize;
        /*    } else if (key === 'label') {
                    style[key].fontSize = info.fontSizes.textSizeS; */
        /*} else if (key === 'sublabel' || key === 'badge' || key === 'invalidtext') {
                style[key].fontSize = info.fontSizes.textSizeSs;*/
        // } else if (key === 'button' || key === 'lockedButton') {
        //     style[key].fontSize = info.fontSizes.textSize;
        //     style[key].height = info.height + 'px';
        // } else if (key === 'group') {
        //     style[key].height = info.height + 'px';
        // }
        // });
    }

    /**
     * Calls all the functions in the list, used on a components `onDestory` lifecycle event to clean up component code.
     *
     * @param onDestroy the list of functions to call when the component is destroyed.
     */
    public onDestroy(onDestroy: (() => unknown)[]): void {
        onDestroy.forEach((destruction) => {
            if (destruction && destruction instanceof Function) {
                destruction();
            }
        });
    }

    /**
     * Checks if the given text align value is a valid css text align.
     *
     * @param textAlign the text align value to check if is valid css text align.
     * @returns `true` if the given text align value si a valid css text align, `false` if not.
     */
    public isValidTextAlign(textAlign: string): boolean {
        if (textAlign && typeof textAlign === 'string' && ['start', 'end', 'left', 'right', 'center', 'justify', 'justify-all', 'match-parent', 'inherit', 'initial'].includes(textAlign)) {
            return true;
        }
        return false;
    }

    /**
     * Returns the correct css class as string to append to the base form-field element to configure the labelview.
     *
     * @param labelAlwaysOnTop user setting for if the user always has labels on top.
     * @param labelView the component's own custom view, comes second to the user setting `labelAlwaysOnTop`.
     * @returns string class to append to base form-field element to configure labelview.
     */
    public getBaseLabelViewClass(labelAlwaysOnTop: boolean = false, labelView: LabelView = 'auto'): string {
        let baseClasses = '';

        switch (labelView) {
            case 'top':
                baseClasses += ' tt-input__base--label-top';
                break;
            case 'side':
                baseClasses += ' tt-input__base--label-side';
                break;
            case 'none':
                baseClasses += 'tt-input__base--label-none';
                break;
            case 'hidden':
                baseClasses += 'tt-input__base--label-hidden';
                break;
            case 'auto':
                baseClasses += '';
                break;
            default:
                baseClasses += '';
                break;
        }

        // user setting takes presedence.
        baseClasses += labelAlwaysOnTop === true ? ' tt-input__base--label-top' : '';
        return baseClasses;
    }

    /**
     * Merges the given two objects. Properties of the `mergeB` object will take precedence over properties in the `mergeA` object.
     *
     * @param mergeA object to merge. Any shared properties with mergeB will be overwritten.
     * @param mergeB object to merge. Any shared properties with mergeA will overwrite mergeA property.
     * @returns the merged objects of `mergeA` and `mergeB`.
     */
    public mergeObjects(mergeA: { [key: string]: any }, mergeB: { [key: string]: any }): { [key: string]: any } {
        let combined = { ...mergeA };

        Object.keys(mergeB).forEach((key) => {
            if (mergeB?.[key]) {
                if (mergeA?.[key] && typeof mergeB[key] === 'object') {
                    combined[key] = this.mergeObjects(mergeA[key], mergeB[key]);
                } else {
                    combined[key] = mergeB[key];
                }
            }
        });

        return combined;
    }

    /**
     * Parses the given string to a javascript object.
     *
     * @param {string} string the string to be parsed to a javascript object.
     * @returns the object created from the string.
     */
    public stringToObject(string: string): { [key: string]: any } {
        if (string && typeof string === 'string') {
            string = string.trim();

            string = string.replaceAll("'", '"');

            if ((string as string).substring(0, 1) !== '{') {
                string = '{' + string + '}';
            }
            return JSON.parse(string as string);
        }

        return {};
    }

    /**
     * Applies the given text-align to the given property with the given main-element key to the given style objects.
     *
     * @param param object containing style objects, main element key and text align value.
     */
    private setTextAlignOnStyles({ style, ttStyle, mainElement, textAlign }: SetTextAlignStyleParams): void {
        if (!textAlign || !this.isValidTextAlign(textAlign)) return;

        if (ttStyle && ttStyle.hasOwnProperty(mainElement.toString()) && !!ttStyle[mainElement.toString()]) {
            ttStyle[mainElement.toString()]!.textAlign = textAlign;
        } else if (style && style.hasOwnProperty(mainElement.toString())) {
            style[mainElement.toString()]!.textAlign = textAlign;
        }
    }
}

/**
 * Represents parameters used for setting the components style object.
 */
interface SetStyleParams {
    /** The components default style. */
    style: Style;

    /** The styles to override the default component style with, can be either a `JSON` or `Style` object. */
    ttStyle: string | Partial<Style>;

    /** The property key of the main element in the style object, or main elements of a component. */
    mainElement: PropertyKey | PropertyKey[];

    /** The text align set on the component. */
    textAlign?: TextAlign;
}

/**
 * Represents parameters used for setting the components style object with text align.
 */
interface SetTextAlignStyleParams extends SetStyleParams {
    /** The styles to override the default component style with, must be `Style` object. */
    ttStyle: Partial<Style>;

    mainElement: PropertyKey;
}

/**
 * Represents parameters used for setting the components classes.
 */
interface SetClassesParams {
    classes: Class;
    ttClasses?: string | Class;
}

/**
 * Represents available ways to set the view of a label to.
 */
export type LabelView = 'auto' | 'top' | 'side' | 'none' | 'hidden';

/**
 * Represents basic valid text-align values in CSS.
 */
export type TextAlign = 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'justify-all' | 'match-parent' | 'inherit' | 'initial';

/**
 * Represents a style object used to customize a components default style.
 */
export type Style = { [key: string]: Partial<CSSStyleDeclaration> };

/**
 * Represents a class object used to customize a components defalt classes.
 */
export type Class = { [key: string]: string | string[] };
