(function () {
    'use strict';

    var module = angular.module('imApp');
    
    module.component('stockMenu', {
        templateUrl: 'views/components/views/stockMenu/stockMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', function (stateService) {
            var vm = this;

            vm.model = {
                name: stateService.getCurrentName()
            };
        }]
    });
})();
