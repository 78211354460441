(function () {
    'use strict';

	let module = angular.module('imApp');

	module.component('timeSheetDiary', {
        templateUrl: 'views/components/views/timeSheetDiary/timeSheetDiary.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'timeSheetDiaryService', 'watcherFactory', function ($stateParams, $q, stateService, utilityService, timeSheetDiaryService, watcherFactory) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

			var watcher = watcherFactory.$create(vm);
			var onDestroy = [];

			vm.model = {
				schedulerReady: false,
				setting: {
					portal_user_keyno: undefined,
					date_fom: undefined,
					selectedview: undefined
				},
				selectListPortalUsers: [],
				taskList: {
					records: []
				},
				ownerList: {
					records: []
				}
			};

			var setupScheduleFunctions = function () {
				return [
					{ name: 'add', func: function (data) { calendarChange(this.name, data); } },
					{ name: 'update', func: function (data) { calendarChange(this.name, data); } },
					{ name: 'delete', func: function (data) { calendarChange(this.name, data); } },
					{ name: 'newDate', func: function (data) { calendarChange(this.name, data); } }
				];
			};

			var calendarChange = function (name, data) {
				switch (name) {
					case 'add':
						console.log('Item added to setting...');
						break;
					case 'update':
						timeSheetDiaryService.updateItem(data).then(function (_response) {});
						break;
					case 'delete':
						console.log('Item not added...? Deleted...?');
						break;
					case 'newDate':
						console.log('Item new date...');
						break;
					default:
						console.log('Item default action...');
						break;
				}
			};

            let loadSetting = function () {
                const deferred = $q.defer();

                timeSheetDiaryService.getSetting({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    vm.model.setting.functions = setupScheduleFunctions();

                    $q.all([
                        loadPortalUsers()
                    ]).then(() => {
                        loadTasks();
                        loadOwners();

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

			let loadPortalUsers = function () {
				if (utilityService.validateParmsValue(vm.model.setting.argtype) !== true) return;
				if (utilityService.validateParmsValue(vm.model.setting.argvalue) !== true) return;

                return timeSheetDiaryService.listPortalUsers({
                    argtype: vm.model.setting.argtype,
                    argvalue: vm.model.setting.argvalue
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListPortalUsers);
				});
			};

			let loadTasks = function () {
				if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
				if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno) !== true) return;
				if (utilityService.validateParmsValue(vm.model.setting.selectedview) !== true) return;

				vm.model.taskList.records = [];

                return timeSheetDiaryService.listTasks(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.taskList.records);
				});
			};

			let loadOwners = function () {
				if (utilityService.validateParmsValue(vm.model.setting.date_fom) !== true) return;
				if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno) !== true) return;
				if (utilityService.validateParmsValue(vm.model.setting.selectedview) !== true) return;

				vm.model.ownerList.records = [];

                return timeSheetDiaryService.listOwners(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.ownerList.records);
				});
			};

            vm.goToFunc = function (state) {
                switch (state) {
					case 'timeregister':
                        stateService.go(state, {
							custact_no: '0',
							powtime_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'portal_user_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.portal_user_keyno) !== true) return;

                        vm.model.setting.portal_user_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.portal_user_keyno) !== true) return;

                        loadTasks();
                        loadOwners();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

			watcher.$watch(function () {
				return vm.model.setting.date_fom;
			}, function (newValue, oldValue) {
				if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

				loadTasks();
				loadOwners();
			}, onDestroy);

			watcher.$watch(function () {
				return vm.model.setting.selectedview;
			}, function (newValue, oldValue) {
				if (utilityService.validateWatchValue(newValue, oldValue) !== true) return;

				/*console.log('selectedview: ' + vm.model.setting.selectedview);*/

				loadTasks();
				loadOwners();
			}, onDestroy);

			vm.$onDestroy = function () {
				angular.forEach(onDestroy, function (fn) {
					if (angular.isFunction(fn) === true) {
						fn();
					}
				});
            };

            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
