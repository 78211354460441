(function () {
    'use strict';

    let module = angular.module('imApp');

    module.factory("modalListSelectFactory", ['$q', '$uibModal', 'userService', 'utilityService', function ($q, $uibModal, userService, us) {
        function modalListSelect(options) {
            var vm = this;

            vm.options = options || {};

            options.list = options.list || [];
            options.showDismiss = us.toBoolean(options.showDismiss, false);
            options.title = options.title || '';
            options.searchable = us.toBoolean(options.searchable, true);
            options.selectable = us.toBoolean(options.selectable, true);
            options.hideIcon = us.toBoolean(options.hideIcon, false);

            vm.model = {
                list: [],
                index: {},
                showDismiss: options.showDismiss,
                title: options.title,
                searchable: options.searchable,
                selectable: options.selectable,
                hideIcon: options.hideIcon
            };

            var setIcon = function (item) {
                item.item_glyphicon = item.item_selected === true ? 'glyphicon-check' : 'glyphicon-unchecked';
                item.item_glyphicon_color = item.item_selected === true ? 'green' : 'red';

                return item;
            };

            if (angular.isUndefined(vm.options.convert)) {
                vm.model.list = vm.options.list || [];
                vm.options.convert = {
                    id: 'item_id',
                    name: 'item_name',
                    order: 'orderby',
                    selected: 'item_selected'
                };
            } else {
                vm.model.list = [];

                var getValue = function (item, name, defaultValue) {
                    return angular.isDefined(item[name])
                        ? item[name]
                        : defaultValue;
                };

                angular.forEach(vm.options.list, function (item) {
                    var convertedItem = {
                        item_id: getValue(item, vm.options.convert.id, ''),
                        item_name: getValue(item, vm.options.convert.name, ''),
                        item_selected: getValue(item, vm.options.convert.selected, false),
                        orderby: getValue(item, vm.options.convert.order, 0)
                    };

                    vm.model.list.push(convertedItem);
                });

                if (angular.isDefined(vm.options.sort) && vm.options.sort === true) {
                    vm.model.list = vm.model.list.sort(function (a, b) {
                        return a.item_name.localeCompare(b.item_name, userService.languageId, { sensitivity: 'base' });
                    });
                }
            }

            var index = 0;

            angular.forEach(vm.model.list, function (item) {
                setIcon(item);

                item.item_state = 'customaction';
                item.item_parms = '';
                item.item_action = function (clickedItem) {
                    clickedItem.item_selected = !clickedItem.item_selected;

                    vm.model.list[vm.model.index['ix_' + clickedItem.item_id]].item_selected = clickedItem.item_selected;

                    setIcon(clickedItem);
                    setIcon(vm.model.list[vm.model.index['ix_' + clickedItem.item_id]]);

                    if (angular.isDefined(options.onChange) && angular.isFunction(options.onChange)) {
                        options.onChange(clickedItem);
                    }
                };

                vm.model.index['ix_' + item.item_id] = index;

                index++;
            });

            vm.instance = null;
        };

        modalListSelect.prototype.show = function () {
            var deferred = $q.defer();

            var ivm = this;

            ivm.instance = $uibModal.open({
                backdrop: ivm.model.showDismiss === true ? 'static' : true,
                animation: true,
                keyboard: false,
                templateUrl: 'views/modal/listSelectModal.html?v=' + module.version,
                controllerAs: 'vm',
                controller: ['$timeout', '$scope', '$uibModalInstance', function ($timeout, $scope, $uibModalInstance) {
                    var vm = this;

                    var initializing = true;

                    vm.model = {
                        filteredList: angular.copy(ivm.model.list),
                        searchText: '',
                        title: ivm.model.title,
                        showDismiss: ivm.model.showDismiss,
                        searchable: ivm.model.searchable,
                        selectable: ivm.model.selectable,
                        hideIcon: ivm.model.hideIcon
                    };

                    var searchPromise = null;
                    var previousSearch = '';

                    var delaySearch = function () {
                        if (searchPromise !== null) {
                            $timeout.cancel(searchPromise);
                        }

                        if (vm.model.searchText !== previousSearch) {
                            previousSearch = vm.model.searchText;

                            searchPromise = $timeout(delaySearch, 300);
                        } else {
                            previousSearch = '';
                            searchPromise = null;

                            vm.model.filteredList = ivm.model.list.filter(function (item) {
                                return item.item_name.includes(vm.model.searchText);
                            });
                        }
                    };

                    vm.search = function (value) {
                        vm.model.searchText = value;

                        delaySearch();
                    };

                    var buildResult = function () {
                        var selectedItems = ivm.model.list.filter(function (item) {
                            return item.item_selected;
                        });

                        var selectedItemsIndex = {};

                        angular.forEach(selectedItems, function (item) {
                            selectedItemsIndex['ix_' + item.item_id] = item.item_id;
                        });

                        var result = ivm.options.list.filter(function (item) {
                            return angular.isDefined(selectedItemsIndex['ix_' + item[ivm.options.convert.id]]);
                        });

                        angular.forEach(result, function (item) {
                            item[ivm.options.convert.selected] = true;
                        });

                        return result;
                    }

                    vm.dismiss = function () {
                        deferred.resolve(buildResult());

                        ivm.instance.dismiss();
                    };

                    vm.close = function () {
                        deferred.resolve(buildResult());

                        $uibModalInstance.close();
                    };

                    if (vm.model.showDismiss !== true) {
                        $scope.$on('modal.closing', function (event, reason) {
                            switch (reason) {
                                case "backdrop click":
                                case "escape key press":
                                case "cancel":
                                    event.preventDefault();
                                    vm.close();
                                    break;
                            }
                        });
                    }

                    vm.$onInit = function () {
                        initializing = false;
                    }
                }]
            });

            return deferred.promise;
        };

        modalListSelect.prototype.hide = function () {
            if (angular.isUndefined(this.instance)) return;
            if (this.instance === null) return;

            this.instance.close();

            this.instance = null;
        };

        modalListSelect.$create = function (options) {
            return new modalListSelect(options);
        };

        return modalListSelect;
    }]);
})();
