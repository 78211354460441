import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataTaskService } from '@app/core/services/data-task.service';
import { StateService } from '@app/core/services/state.service';
import { ModalService } from '../../../core/services/modal.service';
import { SignaturePadModalComponent } from '../../../core/components/grid/modals/signature-pad-modal/signature-pad-modal.component';

@Component({
    selector: 'tt-student-test',
    templateUrl: './student-test.component.html',
    styleUrls: ['./student-test.component.css']
})
export class StudentTestComponent implements OnInit {
    @Input() item: any; // Adjust the type as necessary
    @Output() saveEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private DataTaskService: DataTaskService,
        // private modalService: ModalService
        private StateService: StateService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        // Initialization logic if needed
 

    }

    openSignaturePad() {
        var answer = this.modalService.openSignaturePadDialog();

        if (answer) {
            console.log(answer);
        }
    }

    // Function for onClick event of type activity
    // Gets item clicked as event 

}

