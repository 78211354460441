(function () {
    'use strict';

    angular.module("imApp").factory("paymentPlansService", ['$ihttp', function ($ihttp) {
        let service = {
            getPaymentPlans: function (parms) {
                return $ihttp.post({
                    method: 2736,
                    parameters: parms || {}
                });
            },
            getNextNumber: function (parms) {
                return $ihttp.post({
                    method: 2743,
                    parameters: parms
                });
            }
        };

        return service;
    }]);
})();
