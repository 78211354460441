(function () {
	'use strict';

    var module = angular.module('imApp');

    module.component('activityTypes', {
        templateUrl: 'views/components/views/activityTypes/activityTypes.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['stateService', 'activityTypesService', function (stateService, activityTypesService) {
			var vm = this;

            vm.model = {
                itemsListActivityTypes: []
            };

            activityTypesService.listActivityTypes({ onweb: 'ALL' }).then(function (result) {
                angular.copy(result, vm.model.itemsListActivityTypes);
            });

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'activitytype':
                        stateService.go(state, { activitytype_no: '0' });
                        break;
                    default:
                        break;
                }
            };
		}]
	});
})();