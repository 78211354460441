(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentColliDetailService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            loadConsignmentColli: function (params) {
                return p2DataTaskService.call(1427, params);
            },
            listConsignmentColliDetails: function (parms) {
                return $ihttp.post({
                    method: 2654,
                    parameters: parms || {}
                });
            },
            saveObj: function (mergeObj) {
                return $ihttp.post({
                    method: 1429,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();