import { GraphAuthService } from '../../../../../app/modules/microsoft-graph/auth/services/graph-auth.service';
import { GraphMailService } from '../../../../../app/modules/microsoft-graph/services/graph-mail.service';

(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('ttDocumentViewer', {
        //templateUrl: 'views/components/directives/ttDocumentViewer/ttDocumentViewer.template.html?v=' + module.version,
        template: `
            <div style="height: 100%;" class="col-xs-12 sp-0">
                <div style="height: 100%;" ng-if="vm.ttModel !== null && vm.ttModel !== undefined && (vm.ttModel.extension !== 'msg' || vm.ttIsEmail === 'false')" class="col-xs-12 sp-0" ie-document-viewer ie-show="vm.ttShowDocument" ie-options="vm.options" ie-details="vm.ttModel"></div>
                <div ng-if="vm.ttModel !== null && vm.ttModel !== undefined && (vm.ttModel.extension === 'msg' || vm.ttIsEmail === 'true')" class="col-xs-12 sp-0" style="height: 100%; display: flex; flex-direction: column; gap: 0.8rem;">
                    <div style="height: 100%; display: flex; flex-direction: column; gap: 0.6rem;"  class="well well-sm col-xs-12">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <mgt-person ng-if="vm.email.sender_email.length > 0 && vm.ttEmailUserSenderFallback !== 'true'" class="col-xs-12 sp-0" person-query="{{vm.email.sender_email}}" view="twoLines" line2-property="email">
                                <template data-type="no-data">
                                    <mgt-person class="col-xs-12 sp-0" fallback-details='{ "mail": "[[{{vm.email.sender_email}}]]", "displayName": "{{vm.email.sender_name}}" }' view="twoLines" line2-property="email"></mgt-person>
                                </template>
                            </mgt-person>
                            <mgt-person ng-if="vm.email.sender_email.length > 0 && vm.ttEmailUserSenderFallback === 'true'"" class="col-xs-12 sp-0" person-query="{{vm.email.sender_email}}" fallback-details='{ "mail": "{{vm.email.sender_email}}", "displayName": "{{vm.email.sender_name}}" }' view="twoLines" line2-property="email"></mgt-person>
                            <div ng-if="vm.model.darkmode === true">
                                <button ng-click="vm.setDarkMode(false)" uib-tooltip="{{vm.translations.emailview_light_mode}}" tooltip-placement="bottom-right" tooltip-append-to-body="true" class="tt-button tt-button--icon"><span class="fa-fw fas fa-sun"></span></button>
                            </div>
                            <div ng-if="vm.model.darkmode === false">
                                <button ng-click="vm.setDarkMode(true)" uib-tooltip="{{vm.translations.emailview_dark_mode}}" tooltip-placement="bottom-right" tooltip-append-to-body="true" class="tt-button tt-button--icon"><span class="fa-fw fas fa-moon"></span></button>
                            </div>
                            <button ng-if="vm.model.replyView === 'inline'" ng-click="vm.replyToEmail($event)" uib-tooltip="{{vm.translations.emailview_send_email}}" tooltip-placement="bottom-right" tooltip-append-to-body="true" class="tt-button tt-button--icon"><span class="fa-fw fas fa-reply"></span></button>
                            <button ng-repeat="button in vm.ttEmailButtons" uib-tooltip="{{button.translatedTitle}}" tooltip-placement="bottom" tooltip-append-to-body="true" class="tt-button tt-button--icon" ng-click="button.onClick()"><span class="fa-fw" ng-class="button.icon"></span></button>
                        </div>
                        <div ng-if="vm.ttEmailShowSentData !== 'false'" style="display: flex; justify-content: space-between; padding-left: 52px;" ng-style="{ fontSize: vm.style.fontSizes.textSize }">
                            <span><span style="font-weight: 600;" im-translate="to">: </span>{{vm.email.recipient_email}}</span>
                            <span><span style="font-weight: 600;" im-translate="datetime_sent">: </span>{{vm.email.datetime_sent}}</span>
                        </div>
                        <h2 class="w-email__heading--subject" style="margin: 0;" ng-style="{ fontSize: vm.style.fontSizes.textSizeL }">{{vm.email.heading}}</h2>
                        <div ng-if="vm.model.attachments.length > 0 && vm.model.attachmentsView === 'inline'" style="display: flex; flex-wrap: wrap;">
                            <email-attachment ng-repeat="attachment in vm.model.attachments" tt-model="attachment" tt-click="vm.ttEmailAttachmentClick({attachment: attachment})"></email-attachment>
                        </div>
                        <iframe style="border: none;" height="100%" width="100%" id="{{vm.id.iframe}}"></iframe>
                    </div>
                    <div ng-if="vm.model.attachmentsView === 'bottom' && vm.model.attachments.length > 0" class="col-xs-12 sp-0">
                        <tt-header tt-translate="false" tt-model="vm.translations.attachment" tt-style="{ fontSize: vm.style.fontSizes.textSizeXL}"></tt-header>
                        <im-list-directive list-items="vm.model.attachments" badge="" ltype="" design-var="s12t0"></im-list-directive>
                    </div>
                    <div ng-if="vm.model.replyView === 'bottom'" class="well well-sm col-xs-12">
                        <tt-button-goto tt-text="emailview_send_email" tt-goto-func="vm.replyToEmail()" tt-text-align="left"></tt-button-goto>
                    </div>
                </div>
            </div>
        `,
        controllerAs: 'vm',
        /** @type DocumentViewerProps */
        bindings: {
            ttModel: '<',
            ttIsEmail: '@?',
            ttEmailShowSentData: '@?',
            ttEmailReplyView: '@?',      // 'inline' | 'bottom' | 'none' 
            ttEmailAttachmentsView: '@?',      // 'inline' | 'bottom' | 'none'
            ttEmailAttachments: '<?',
            ttEmailAttachmentClick: '&?',
            ttEmailUserSenderFallback: '@?',
            ttEmailButtons: '<?',
            ttShowDocument: '='
        },
        controller: ['base64', 'stateService', 'layoutService', 'translateService', 'companyService', 'emailViewService', 'graphAuthService', function (base64, stateService, layoutService, translateService, companyService, emailViewService, graphAuthService) {
            const vm = this;

            vm.id = {
                iframe: crypto.randomUUID(),
            };

            vm.translations = {
                emailview_send_email: '',
                emailview_light_mode: '',
                emailview_dark_mode: '',
                attachment: '',
            };

            vm.model = {
                iframeDocument: null,
                attachmentsView: 'none',
                attachments: [],
                replyView: 'none',
                darkmode: false,
            };

            vm.email = null;

            vm.options = {
                wopiServerUrl: companyService.fileServerUrl ?? '',
                documentHeight: '100%',
                maxWidth: 800,
                maxHeight: '100%',
                canConvert: true,
                officeControl: {},
                showIcons: false
            };

            vm.replyToEmail = function (event) {
                if (event?.ctrlKey === true) {
                    return stateService.newTab('emailcreate', {
                        webpage: 'emailview',
                        argtype: 'email_keyno',
                        argvalue: vm.email.email_keyno
                    });
                } else {
                    return stateService.go('emailcreate', {
                        webpage: 'emailview',
                        argtype: 'email_keyno',
                        argvalue: vm.email.email_keyno
                    });
                }
            };

            vm.setDarkMode = function (darkmode) {
                if (!vm.model.iframeDocument) return;
                if (darkmode === true) {
                    vm.model.darkmode = true;
                    vm.model.iframeDocument.querySelector('html').style.colorScheme = 'dark';
                } else if (darkmode === false) {
                    vm.model.darkmode = false;
                    vm.model.iframeDocument.querySelector('html').style.colorScheme = 'light';
                }
            }

            function setContentToIframe() {
                const iframe = document.getElementById(vm.id.iframe);

                if (iframe && (vm.email?.body || vm.email?.email_body_html)) {

                    let bodyContent = '';

                    if (vm.email?.body) {
                            bodyContent = base64.urldecode(vm.email.body);
                    } else if (vm.email?.email_body_html) {
                            bodyContent = base64.urldecode(vm.email.email_body_html);
                    }

                    vm.model.iframeDocument = iframe?.contentDocument ?? iframe.contentWindow.document;

                    vm.model.iframeDocument.open();
                    vm.model.iframeDocument.write(bodyContent);
                    vm.model.iframeDocument.close();

                    if (document?.getElementsByTagName('html')?.[0]?.classList?.contains('dark')) {
                        vm.setDarkMode(true);
                    } else {
                        vm.setDarkMode(false);
                    }

                    setTimeout(() => iframe.style.height = vm.model.iframeDocument.documentElement.querySelector('body').scrollHeight + 'px', 50);
                }
            };

            async function _translate() {
                const data = await translateService.translateBatch(vm.translations)

                Object.keys(vm.translations).forEach((key) => {
                    if (data?.[key]) {
                        vm.translations[key] = data[key];
                    }
                })
            };

            vm.style = {
                fontSizes: {}
            }

            vm.$onInit = function () {
                layoutService.onLayoutChanged([], (info) => {
                    vm.style.fontSizes = info.fontSizes;
                })
                _translate();
            }

            vm.$onChanges = async function (changes) {
                const words = {}

                if (changes?.ttModel?.currentValue && (changes.ttModel.currentValue?.extension === 'msg' || vm.ttIsEmail === 'true')) {
                    await graphAuthService.initialize();
                    vm.email = changes.ttModel.currentValue;

                    if (!vm.email?.body && !vm.email?.email_body_html && vm.email?.extension === 'msg') {
                        vm.email = (await emailViewService.getEmailView(changes.ttModel.currentValue.item_id))[0];
                    }

                    if (vm.email?.body || vm.email?.email_body_html) setTimeout(setContentToIframe, 100);
                }

                if (changes?.ttEmailButtons?.currentValue && changes.ttEmailButtons.currentValue instanceof Array && changes.ttEmailButtons.currentValue.length > 0) {
                    changes.ttEmailButtons.currentValue.forEach((button) => words[button.title] = button.id);

                    const data = await translateService.translateBatch(words)

                    changes.ttEmailButtons.currentValue.forEach((button) => button.translatedTitle = data[button.title]);
                }

                if (changes?.ttEmailReplyView?.currentValue && changes.ttEmailReplyView.currentValue !== changes.ttEmailReplyView.previousValue && ['inline', 'bottom', 'none'].includes(changes.ttEmailReplyView.currentValue)) {
                    vm.model.replyView = changes.ttEmailReplyView.currentValue;
                }

                if (changes?.ttEmailAttachmentsView?.currentValue && changes.ttEmailAttachmentsView.currentValue !== changes.ttEmailAttachmentsView.previousValue && ['inline', 'bottom', 'none'].includes(changes.ttEmailAttachmentsView.currentValue)) {
                    vm.model.attachmentsView = changes.ttEmailAttachmentsView.currentValue;
                }

                if (changes?.ttEmailAttachments?.currentValue && changes?.ttEmailAttachments?.currentValue instanceof Array) {
                    vm.model.attachments = changes.ttEmailAttachments.currentValue;
                }
            };
        }]
    })
})();
