(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('poFinishProdCompsB', {
        templateUrl: 'views/components/views/poFinishProdCompsB/poFinishProdCompsB.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'poFinishProdCompsBService', function ($stateParams, stateService, translateService, modalService, poFinishProdCompsBService) {
            const vm = this;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                lockedDelete: false
            };

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            let loadEdit = function () {
                poFinishProdCompsBService.getEdit({ p2_pofinishprodcompsb_keyno: $stateParams.p2_pofinishprodcompsb_keyno }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };
            
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    poFinishProdCompsBService.deleteItem({ p2_pofinishprodcompsb_keyno: vm.model.edit.p2_pofinishprodcompsb_keyno }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
