(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("translateModalService", ['$q', '$uibModal', function ($q, $uibModal) {
        var service = {
            showModal: function (word_id, autoSave) {
                var deferred = $q.defer();

                var instance = $uibModal.open({
                    component: 'modalWordlang',
                    resolve: {
                        word_id: function () {
                            return word_id;
                        },
                        autoSave: function () {
                            return autoSave;
                        }
                    },
                    size: 'pst-ninety',
                    backdrop: true
                });

                instance.result.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.resolve(response);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();


/*(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("translateService", ['$q', '$ihttp', '$timeout', '$uibModal', function ($q, $ihttp, $timeout, $uibModal) {
        let translations = {};
        let waitCallbacks = []
        let waiting = [];
        let waitCount = 0;
        let waitPromise;

        function ensureIsStringArray(parms) {
            // Must be able to handle the following parameter formats
            //  'wordId'
            //  {wordId: ''}
            //  {wordId1: '', wordId2: '')
            //  [wordId1, wordId2]
            //  [{wordId: ''}, {wordId: ''}]

            let retval = [];

            function addValue(val) {
                if (angular.isString(val)) {
                    retval.push(val);
                } else if (angular.isArray(val)) {
                    for (let i = 0; i < val.length; i++) {
                        addValue(val[i]);
                    }
                } else if (angular.isObject(val)) {
                    let keys = Object.keys(val);
                    
                    for (let i = 0; i < keys.length; i++) {
                        retval.push(keys[i]);
                    }
                }
            }

            addValue(parms);

            return retval;
        }

        function commonTranslate(parms) {
            let ids = ensureIsStringArray(parms);
            
            if (ids.length < 1) return $q.resolve({});
            
            let callback = {
                remaining: ids,
                remainingIx: {},
                translations: {},
                handler: null
            };

            let mustTranslate = [];
            
            angular.forEach(ids, function (id) {
                callback.remainingIx[id] = '';
                callback.translations[id] = '';
                
                if (angular.isUndefined(translations[id])) {
                    translations[id] = {
                        id: id,
                        translation: id,
                        isTranslated: false
                    };
                    
                    mustTranslate.push(id);
                } else {
                    if (translations[id].isTranslated === true) {
                        callback.translations[id] = translations[id].translation;
                        
                        callback.remaining = callback.remaining.filter(r => r !== id);
                        
                        delete callback.remainingIx[id];
                    }
                }
            });
            
            if (callback.remaining.length === 0) {
                return $q.resolve(callback.translations);
            }

            callback.handler = $q.defer();
            
            waitCallbacks.push(callback);
            
            if (mustTranslate.length > 0) {
                // Some ids are not cached and not beeing translated
                waiting = waiting.concat(mustTranslate);
                
                translateWaiting();
            }
            
            return callback.handler.promise;
        };

        function translateWaiting() {
            if (waitCount !== waiting.length) {
                waitCount = waiting.length;

                if (angular.isDefined(waitPromise)) {
                    $timeout.cancel(waitPromise);
                }

                waitPromise = $timeout(translateWaiting, 250);
                return;
            }
            
            let ids = waiting.splice(0);
            
            waitCount = waiting.length;
            waitPromise = undefined;
            
            $ihttp.post({ method: 3, parameters: { timestamp: 0, words: ids } }).then(function (response) {
                angular.forEach(response, function (value) {
                    translations[value.id].translation = value.wl;
                    translations[value.id].isTranslated = true;

                    for (let i = 0; i < waitCallbacks.length; i++) {
                        if (angular.isUndefined(waitCallbacks[i].remainingIx[value.id])) continue;
                        
                        waitCallbacks[i].translations[value.id] = value.wl;
                        
                        waitCallbacks[i].remaining = waitCallbacks[i].remaining.filter(r => r !== value.id);
                        
                        delete waitCallbacks[i].remainingIx[value.id];
                    }
                });

                let callbacks = waitCallbacks.splice(0);

                waitCallbacks = [];

                for (let i = 0; i < callbacks.length; i++) {
                    if (callbacks[i].remaining.length > 0) {
                        waitCallbacks.push(callbacks[i]);
                        continue;
                    }
                    
                    callbacks[i].handler.resolve(callbacks[i].translations);
                }
                
            });
        };

        var service = {
            showModal: function (word_id, autoSave) {
                var deferred = $q.defer();

                var instance = $uibModal.open({
                    component: 'modalWordlang',
                    resolve: {
                        word_id: function () {
                            return word_id;
                        },
                        autoSave: function () {
                            return autoSave;
                        }
                    },
                    size: 'pst-ninety',
                    backdrop: true
                });

                instance.result.then(function (response) {
                    deferred.resolve(response);
                }, function (response) {
                    deferred.resolve(response);
                });

                return deferred.promise;
            },

            update: function (translationId, translatedValue) {
                if (angular.isDefined(translations[translationId])) {
                    translations[translationId].isTranslated = true;
                    translations[translationId].translation = translatedValue;
                } else {
                    translations[translationId] = {
                        id: translationId,
                        isTranslated: true,
                        translation: translatedValue
                    }
                }
            },

            on: function (translate, _, handler) {
                commonTranslate(translate).then(function (response) {
                    let result;

                    if (angular.isString(translate) === true) {
                        result = response[Object.keys(response)[0]];
                    } else {
                        result = response;
                    }

                    handler(result);
                });

                return function () { };
            },

            translate: function (translationId) {
                var deferred = $q.defer();

                commonTranslate(translationId).then(function (response) {
                    deferred.resolve(response[Object.keys(response)[0]]);
                });

                return deferred.promise;
            },

            translateBatch: function (translationIds) {
                return commonTranslate(translationIds);
            }
        };

        return service;
    }]);
})();*/
