(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('fabricsGrps', {
        templateUrl: 'views/components/views/fabricsGrps/fabricsGrps.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'fabricsGrpsService', function (stateService, fabricsGrpsService) {
            var vm = this;
            
            vm.model = {
                searchTextBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                setting: {},
                items: []
            };
            
            fabricsGrpsService.loadFabricsGrpsSettings().then(function (data) {
                vm.model.setting = angular.copy(data[0]);
            });
            
            fabricsGrpsService.loadFabricsGrpList().then(function (data) {
                angular.copy(data, vm.model.items);
            });
            
            vm.searchTextBtnListFunc = function (item_func) {
                if (item_func === 'erase_item') {
                    vm.model.setting.searchtext = '';
                }
            };
            
            vm.createNew = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'fabricsgrp':
                        go({ fabricsgrp_id: '0' });
                        break;
                }
            };
        }]
    });
})();