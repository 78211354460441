(function () {
    'use strict';

    angular.module('imApp').component('ttDynamicEditor', {
        templateUrl: 'views/components/dynamics/views/ttDynamicEditor/ttDynamicEditor.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<'
        },
        controller: ['base64', '$uibModal', 'ttDynamicEditorService', 'ttDatasourceManager', 'translateService', 'ieScreenBlockService', function (base64, $uibModal, ttDynamicEditorService, ttDatasourceManager, translateService, ieScreenBlockService) {
            var vm = this;

            let instanceId = uuid();

            let rememberIdGrid = 'ttDynamicEditorGrid';

            let parms = {};

            let translations = {
                tt_generate_items_from_datasource: '',
                tt_confirm_delete_dynamiccomponent: '',
                // preloading for modal
                tt_confirm: '',
                tt_cancel: ''
            };

            translateService.translateBatch(translations).then(function (response) {
                angular.copy(response, translations);
            });

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2448,
                        parameters: function () {
                            return angular.copy(parms);
                        }
                    },
                    addRow: {
                        autoSave: true,
                        pre: function (dataItem) {
                            dataItem.parent_keyno = parms.parent_keyno;
                            dataItem.route_id = parms.route_id;

                            return dataItem;
                        },
                        post: onEditRow
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: true,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'generateItems', text: 'tt_generate_items_from_datasource', translate: true, func: function () { generateItems(); }, icon: 'far fa-wand-magic gt-icon-white', cssClass: 'btn btn-primary gt-btn im-grid-btn-xs-r' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        buttons: [
                            { name: 'lineDelete', text: '', func: onDeleteRow, icon: 'far-times', type: 'danger' },
                            { name: 'lineEdit', text: '', func: onEditRow, icon: 'far-pencil' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false,
                    datasource: {
                        schema: {
                            parse: function (data) {
                                $.each(data, function (_, elem) {
                                    elem.remarks = base64.urldecode(elem.remarks);
                                });
                            }
                        }
                    }
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            function generateItems() {
                var datasources = ttDatasourceManager.getDatasources(parms.instance_id);

                function onDatasourceSelected(ds) {
                    ieScreenBlockService.start();

                    ttDynamicEditorService.generateDynamicsFromDatasource(ds.tt_datasource_keyno).then(function () {
                        vm.gridOptions.gridfunc.read();
                        ieScreenBlockService.stop();
                    });
                };

                $uibModal.open({
                    component: 'ttDatasourceModal',
                    resolve: {
                        parameters: function () {
                            return {
                                datasources: datasources,
                                callback: onDatasourceSelected
                            };
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });
            };

            function onEditRow(row) {
                var rowParms = angular.copy(parms);

                rowParms.tt_dynamic_keyno = row.tt_dynamic_keyno;
                rowParms.tt_component_keyno = row.tt_component_keyno;
                rowParms.order = row.order;
                rowParms.remarks = row.remarks;

                var instance = $uibModal.open({
                    component: 'ttDynamicEdit',
                    resolve: {
                        parameters: function () {
                            return rowParms;
                        }
                    },
                    animation: true,
                    size: 'pst-ninetyfive',
                    backdrop: true
                });

                instance.result.then(vm.gridOptions.gridfunc.read, vm.gridOptions.gridfunc.read);
            };

            function onDeleteRow(row) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return translations.tt_confirm_delete_dynamiccomponent;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        return;
                    }

                    ieScreenBlockService.start();

                    ttDynamicEditorService.delete(row.tt_dynamic_keyno).then(function () {
                        vm.gridOptions.gridfunc.read();

                        ieScreenBlockService.stop();
                    });
                });
            };

            vm.$onInit = function () {
                if (angular.isUndefined(vm.resolve) || angular.isUndefined(vm.resolve.parameters)) {
                    vm.modalInstance.dismiss();
                }

                angular.copy(vm.resolve.parameters, parms);

                if (angular.isUndefined(parms.parent_keyno)) {
                    parms.parent_keyno = 0;
                }

                if (angular.isUndefined(parms.route_id)) {
                    parms.route_id = '';
                }
            };
        }]
    });
})();