(function() {
    'use strict';

    angular.module("imApp").factory("consignmentDeliveryActivityService", ['p2DataTaskService', function(p2DataTaskService) {
        var service = {
            loaded: false,
            get: function (custact_no, order_order_no) {
                // 1979 - sp_ws_p2_consignmentdeliveryactivity_get
                return p2DataTaskService.call(1979, {
                    custact_no: custact_no,
                    order_order_no: order_order_no
                });
            },
            load: function (custact_no, order_order_no) {
                // 1977 - sp_ws_p2_consignmentdeliveryactivity_list
                return p2DataTaskService.call(1977, {
                    custact_no: custact_no,
                    order_order_no: order_order_no
                });
            },
            confirm: function (custact_no, checklist_keyno) {
                // 261 - sp_ws_p2_select_checklist_save
                return p2DataTaskService.call(261, {
                    argtype: "custact_no",
                    argvalue: custact_no,
                    checklist_keyno: checklist_keyno
                });
            }
        }

        return service;
    }]);
})();
