(function () {
    'use strict';

    angular.module("imApp").factory("ttNavbarService", ['$ihttp', 'stateService', function ($ihttp, stateService) {
        let service = {
            get: function (parms) {
                return $ihttp.post({
                    method: 2978,
                    parameters: parms || {}
                });
            },

            /**
            * @typedef {Object} Page
            * @property webpagepinned_keyno {number}
            * @property pinned_url
            * @property pinned_name
            */

            /**
             * Retrieves a list of pinned pages.
             * 
             * @returns {Promise<{data: Page[]}>}
             */
            getPinnedPages: async function () {
                const pinned =await  $ihttp.post({ method: 3263, parameters: null });
                stateService.setPinnedList(pinned);
                return pinned;
            },


            /**
             * Saves the given page as a pinned page.
             * 
             * @param {Page} page the page to pin.
             * @returns a promise containing errormessage and errorcode.
             */
            savePagePinned: function (page) {
                return $ihttp.post({ method: 3261, parameters: page });
            },

            /**
            * Removes the given page from pinned pages.
            * 
            * @param {Page} page the page to remove from pinned pages.
            * @returns a promise containing errormessage and errorcode.
            */
            savePageUnpinned: function (page) {
                return $ihttp.post({ method: 3262, parameters: page });
            },

            saveThemeSetting: function (screensize, themeKeyno) {
                return $ihttp.post({
                    method: 3094,
                    parameters: {
                        screensize: screensize,
                        theme_keyno: themeKeyno
                    }
                });
            },
        };

        return service;
    }]);
})();
