(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttDynamicDetails', {
        templateUrl: 'views/components/directives/ttDynamicDetails/ttDynamicDetails.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttOptions: '<'
        },
        controller: ['ttDynamicDetailsService', function (ttDynamicDetailsService) {
            var vm = this;

            vm.$onInit = function () {

            };

            vm.$onChanges = function () {

            };
        }]
    });
})();
