(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('freightRateSets', {
        templateUrl: 'views/components/views/freightRateSets/freightRateSets.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'freightRateSetsService', function ($stateParams, stateService, freightRateSetsService) {
            const vm = this;
            let custNo = $stateParams.cust_no;
            
            vm.model = {
                setting: {},
                itemsListRateSets: []
            };

            let loadSetting = function () {
                freightRateSetsService.getSetting({ cust_no: custNo }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadViews = function () {
                freightRateSetsService.listViews({ cust_no: custNo }).then(function (list) {
                    angular.copy(list, vm.model.itemsListRateSets);
                });
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'freightratesetcreate':
                        go({ cust_no: custNo });
                        break;
                    default:
                        break;
                }
            };

            vm.$onInit = function () {
                loadSetting();
                loadViews();
            };
        }]
    });
})();
