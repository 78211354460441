(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('p2SettingEdit', {
        templateUrl: 'views/components/views/p2SettingEdit/p2SettingEdit.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'p2SettingEditService', 'stateService', function($stateParams, p2SettingEditService, stateService) {
    
            var vm = this;
    
    
    
            // #######################
            // VARIABLES & DEFINITIONS
            // #######################
    
            var isSaving = false;
            
            vm.lockingP2SettingEditSaveButton = {
                locked: '0'
            };
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            p2SettingEditService.loadP2SettingEdit($stateParams.p2_setting_keyno || '0').then(function (data) {
                vm.p2_setting_edit = p2SettingEditService.p2_setting_edit;
            });
    
            // ####################
            // SAVE BUTTON FUNCTION
            // ####################
    
            vm.saveP2SettingEdit = function () {
                if (isSaving == true || vm.lockingP2SettingEditSaveButton.locked == '1') return;
    
                isSaving = true;
                vm.lockingP2SettingEditSaveButton.locked = '1';
    
                p2SettingEditService.saveP2SettingEdit().then(function () {
                    stateService.back();
                });
    
                isSaving = false;
            }
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'translateedit':
                        go({ item_id: vm.p2_setting_edit.word_id });
                        break;
                }
            };
    
            vm.goToWebpageSetting = function () {
                stateService.go('webpagesetting', {
                    argtype: 'p2_setting_keyno',
                    argvalue: vm.p2_setting_edit.p2_setting_keyno
                });
            }
        }]
    });
})();