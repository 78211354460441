(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('operationEquipments', {
        templateUrl: 'views/components/views/operationEquipments/operationEquipments.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'modalService', 'operationEquipmentsService', 'utilityService', function ($stateParams, $timeout, modalService, operationEquipmentsService, utilityService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;

            vm.model = {
                argType: $stateParams.argtype,
                argValue: $stateParams.argvalue,
                settingOperationEquipments: {},
                gridReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: '',
                    abortclose: false
                }
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        { key: 'is_selected' },
                        { key: 'prod_name' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            { name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'fa-save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };
            
            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            operationEquipmentsService.getOperationEquipments(vm.model.argType, vm.model.argValue).then(function (data) {
                angular.copy(data[0], vm.model.settingOperationEquipments);

		        if (utilityService.validateParmsValue(vm.model.settingOperationEquipments.view_id) === true) {
		        	vm.grid.dataTask.rememberId = vm.model.settingOperationEquipments.view_id;
		        }

                if (utilityService.validateParmsValue(vm.model.settingOperationEquipments.p2_datatask_keyno) === true && utilityService.validateParmsValue(vm.model.settingOperationEquipments.argtype) === true && utilityService.validateParmsValue(vm.model.settingOperationEquipments.argvalue) === true) {
		        	vm.grid.dataTask.loadData = {
		        		method: vm.model.settingOperationEquipments.p2_datatask_keyno,
                        parameters: {
                            argtype: vm.model.settingOperationEquipments.argtype,
                            argvalue: vm.model.settingOperationEquipments.argvalue
                        }
		        	};
		        }

                vm.model.gridReady = true;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID FUNCTIONS

            var saveItems = function () {
                console.log('Item Saved');
                progressFuncSave();
            };

            // #endregion GRID FUNCTIONS

            // #region PROGRESSBAR FUNCTION

            vm.activateProgressSave = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';
                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].equipment_id + ' : ' + items[step].equipment_name;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        operationEquipmentsService.saveOperationEquipment(items[step], vm.grid.gridfunc).then(function (data) {
                            if (data[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: 'Varsel',
                                    message: data[0].errormessage,
                                    buttons: [{
                                        label: 'OK',
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;
                                            doStep();
                                            dialogItself.close();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            var progressFuncSave = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;
                //var currentData = vm.grid.gridfunc.getAllRows();
                var currentData = vm.grid.gridfunc.getDirtyRows();
                var selectedItems = [];

                angular.forEach(currentData, function (item) {
                    //if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push(item);
                });

                vm.model.progressInfo.max = selectedItems.length;
                vm.activateProgressSave(currentData);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        console.log('Aborted');
                    } else if (vm.model.runProgress === 'FINISH') {
                        console.log('Finished');
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

            // #endregion PROGRESSBAR FUNCTION
        }]
    });
})();
