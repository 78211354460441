(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('operationMenu', {
        templateUrl: 'views/components/views/operationMenu/operationMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'operationMenuService', function ($stateParams, stateService, operationMenuService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let operationId = $stateParams.operation_id;
            
            vm.model = {
                operationNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('operationlangs', { operation_id: vm.model.menuOperation.operation_id }) }
                ],
                prodIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.menuOperation.prod_keyno }) }
                ],
                menuOperation: {}
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadMenu = function () {
                operationMenuService.getOperationMenu({ operation_id: operationId }).then(function (result) {
                    angular.copy(result[0], vm.model.menuOperation);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'operationfactories':
                    case 'operationequipments':
                        go({
                            argtype: 'operation_id',
                            argvalue: vm.model.menuOperation.operation_id
                        });
                        break;
                    case 'operation':
                    case 'suboperations':
                        go({ operation_id: vm.model.menuOperation.operation_id });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadMenu();
            };

		    // #endregion ON INIT FUNCTION

        }]
    });
})();
