(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pictureBank', {
        templateUrl: 'views/components/views/pictureBank/pictureBank.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$window', 'stateService', 'modalService', 'layoutService', 'translateService', 'pictureBankService', 'sirvImageService', function ($stateParams, $window, stateService, modalService, layoutService, translateService, pictureBankService, sirvImageService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let imageMaxWidth = 1000;

            vm.model = {
                picturebankKeyno: $stateParams.picturebank_keyno,
                setting: {},
                imageUrl: '',
                imageWidth: $window.innerWidth,
                imageStyle: {
                    width: '100%',
                    maxWidth: imageMaxWidth + 'px',
                },
                author: '',
                createdAt: '',
                filename: '',
                filesize: '',
                lockedDelete: false,
                lockedSave: false,
                picturebankUrlButtonGroup: [
                    { item_id: '0', item_name: 'copy', item_func: 'copy_item', glyph: 'glyphicon-link', color: 'primary' },
                    { item_id: '1', item_name: 'open', item_func: 'open_item', glyph: 'glyphicon-chevron-right', color: 'primary' }
                ],
            };

            // #endregion VARIABLES & DEFINITIONS


            // #region TRANSLATIONS

            let translations = {
                picturebank_delete_warning: '',
                picturebank_save_warning: '',
            };

            // #endregion TRANSLATIONS


            // #region LOAD PROCEDURE FUNCTION CALLS

            pictureBankService.getPictureBank({
                picturebank_keyno: vm.model.picturebankKeyno
            }).then((response) => {
                angular.copy(response[0], vm.model.setting);
                vm.model.imageUrl = stylefulSirvUrl();
                //vm.model.imageUrl = `${vm.model.setting.full_url}?w=${vm.model.imageWidth}&frame.style=solid&frame.color=adadad&frame.width=3`;
                vm.model.author = vm.model.setting.reg_by_portal_user_name;
                vm.model.createdAt = vm.model.setting.reg_datetime;
                vm.model.filename = extractFilenameFromUrl(vm.model.setting.full_url);
                vm.model.filesize = vm.model.setting.filesize_mb;
                vm.model.pictureMain = vm.model.setting.picture_main;
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            angular.element($window).bind('resize', function () {
                let resizeMargin = vm.model.imageWidth + 100;
                let maxWidth = imageMaxWidth;

                if (resizeMargin < $window.innerWidth && vm.model.imageWidth < maxWidth) {
                    vm.model.imageWidth = $window.innerWidth;
                    vm.model.imageUrl = stylefulSirvUrl();
                }
            });

            /**
            * Extracts and returns the filepath in Sirv from the given url string
            * 
            * @param {String} url the url to extract filepath from.
            * @return the filepath from in Sirv from the given url.
            */
            function extractFilepathFromUrl(url) {
                let encodedFilePath = url.substring(url.indexOf('/', 10));
                let filepath = decodeURIComponent(encodedFilePath.replaceAll('/', '%2F'));

                return filepath;
            }

            /**
             * Extracts only the filename from the given url.
             * 
             * @param {String} url the url to extract the filename from.
             * @return the filename from given url.
             */
            function extractFilenameFromUrl(url) {
                let splittedUrl = extractFilepathFromUrl(url).split('/');
                let filenameWithType = splittedUrl[splittedUrl.length - 1];
                let filename = decodeURIComponent(filenameWithType).split('.')[0];

                return filename;
            }

            /**
             * Adds styling paramteres to the full url and return the new url with style.
             */
            function stylefulSirvUrl() {
                return `${vm.model.setting.full_url}?w=${vm.model.imageWidth}&frame.style=solid&frame.color=adadad&frame.width=3`
            }

            vm.savePicture = function () {
                vm.model.lockedSave = true;

                pictureBankService.savePictureBank(vm.model.setting).then((response) => {

                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.picturebank_save_warning,
                            message: response[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }

                });
            };

            vm.deletePicture = function () {
                let filepath = extractFilepathFromUrl(vm.model.setting.full_url);
                vm.model.lockedDelete = true;
                pictureBankService.deletePictureBank({ picturebank_keyno: vm.model.picturebankKeyno }).then((response) => {

                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.picturebank_delete_warning,
                            message: response[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        sirvImageService.deleteImage(filepath).then((_) => {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        });
                    }
                });
            }

            /**
             * Copies the given text to clipboard.
             * 
             * @param {String} text the text to be copied to clipboard.
             */
            function copyTextToClipboard(text) {
                navigator.clipboard.writeText(text);
            }

            /**
             * Opens the given url in a new a new tab.
             * 
             * @param {String} url the url to be opened.
             */
            function openUrlInNewTab(url) {
                window.open(url, '_blank');
            }

            vm.picturebankUrlButtonGroup = function (item_func) {
                if (item_func === 'copy_item') {
                    copyTextToClipboard(vm.model.setting.full_url);
                } else if (item_func === 'open_item') {
                    openUrlInNewTab(vm.model.setting.full_url);
                }
            }
        }]
    });
})();
