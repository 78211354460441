(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("consignmentShipmentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadConsignmentShipmentGet: function (consignmentshipment_keyno, stockplace_id) {
                return p2DataTaskService.call(1104, {
                    consignmentshipment_keyno: consignmentshipment_keyno,
                    stockplace_id: stockplace_id
                });
            },
            save: function (getConsignmentShipment) {
                return p2DataTaskService.call(1105, getConsignmentShipment);
            },
            delete: function (getConsignmentShipment) {
                return p2DataTaskService.call(1106, getConsignmentShipment);
            } 
        };

        return service;
    }]);
})();