(function() {
    'use strict';

	angular.module("imApp").factory("productionhTopsService", ['p2DataTaskService', function (p2DataTaskService) {
		var service = {
			loadPohTopsGet: function (argtype, argvalue) {
				return p2DataTaskService.call(1347, {
					argtype: argtype,
					argvalue: argvalue
				});
			},
			loadPostStatusSelectList: function (add_all) {
				return p2DataTaskService.call(1348, {
					add_all: add_all
				});
			},
			loadPohTopsList: function (setting) {
				return p2DataTaskService.call(1985, setting);

			},
			loadPohTopsCheckOrder: function (poh_keyno_top) {
				return p2DataTaskService.call(1638, {
					poh_keyno_top: poh_keyno_top
				});
			},
			setPohTopsNewStatus: function (postatus_no_new) {
				return p2DataTaskService.call( /*1573*/ 1875, {
					postatus_no_new: postatus_no_new
				});
			},
			setNewDate: function (olkeyno, oldate) {
				return p2DataTaskService.call(1911, {
					orderline_keyno: olkeyno,
					confirmed_date: oldate
				});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			updateColumnResize: function (parms) {
				return p2DataTaskService.call(1457, parms);
			},
			getDefaultPrinter: function () {
				return p2DataTaskService.call(973, { variablename: 'productionhtops.default_printer' });
			},
			setDefaultPrinter: function (printer) {
				return p2DataTaskService.call(616, {
					variablename: 'productionhtops.default_printer',
					variablevalue: printer
				});
			},
			getSettings: function () {
				return p2DataTaskService.call(1660, {});
			},
			getLastStatus: function () {
				return p2DataTaskService.call(973, { variablename: 'productionhtops.status_no' });
			},
			setLastStatus: function (status) {
				return p2DataTaskService.call(616, {
					variablename: 'productionhtops.status_no',
					variablevalue: status
				});
			},
			setPohKeynoTops: function (commastr) {
				return p2DataTaskService.call(616, {
					variablename: 'productionhtops.poh_keyno_tops',
					variablevalue: commastr
				});
			},
			getReportDefn: function (fname) {
				return p2DataTaskService.call(1624, { report_filename: fname });
			},
		};

        return service;
    }]);
})();
