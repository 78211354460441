(function() {
    'use strict';

    angular.module("imApp").factory("ttButtonactionsService", ['$ihttp', function($ihttp) {
        var service = {
            delete: function (tt_buttonaction_keyno) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'deletebuttonaction',
                        tt_buttonaction_keyno: tt_buttonaction_keyno
                    }
                });
            },

            update: function (buttonaction) {
                return $ihttp.post({
                    method: 2416,
                    parameters: {
                        state: 'updatebuttonaction',
                        buttonaction: buttonaction
                    }
                });
            }
        }

        return service;
    }]);
})();
