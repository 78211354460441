(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('webpageSetting', {
        templateUrl: 'views/components/views/webpageSetting/webpageSetting.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'webpageSettingService', function ($stateParams, webpageSettingService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.searchtext = '' }
                ],
                setting: {},
                itemsListWebpageSettings: []
            };

            let loadSetting = function () {
                webpageSettingService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadWebpageSettings = function () {
                webpageSettingService.listWebpageSettings({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListWebpageSettings);
                });
            };

            vm.$onInit = function () {
                loadSetting();
                loadWebpageSettings();
            };
        }]
    });
})();
