(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2WebpageService", ['$rootScope', '$q', '$ihttp', function ($rootScope, $q, $ihttp) {
        var detailsDeferred = {};

        var webpageCache = {};

        var service = {
            preloadDetails: function (webpageName) {
                service.details(webpageName);
            },

            details: function (webpageName) {
                if (angular.isDefined(webpageCache[webpageName]))
                    return $q.resolve(webpageCache[webpageName]);

                var deferred = $q.defer();

                if (angular.isArray(detailsDeferred[webpageName]) !== true) {
                    detailsDeferred[webpageName] = [];
                }

                detailsDeferred[webpageName].push(deferred);

                if (detailsDeferred[webpageName].length === 1) {
                    $ihttp.post({ method: 1882, parameters: { webpage_name: webpageName } }, { context: { webpageName: webpageName } }).then(function (response) {
                        webpageCache[response.context.webpageName] = response.data[0]

                        var defers = detailsDeferred[response.context.webpageName].splice(0);

                        delete detailsDeferred[response.context.webpageName];

                        angular.forEach(defers, function (def, index) {
                            def.resolve(webpageCache[response.context.webpageName]);
                        });
                    });
                }

                return deferred.promise;
            },

            toogleShowLayoutControls: function (webpageName) {
                var deferred = $q.defer();

                $ihttp.post({ method: 1883, parameters: { webpage_name: webpageName } }).then(function (response) {
                    deferred.resolve(response[0]);

                    if (angular.isDefined(webpageCache[webpageName])) {
                        webpageCache[webpageName] = response[0];
                    }

                    $rootScope.$broadcast('p2WebpageService:showLayoutControlsChanged', response[0]);
                });

                return deferred.promise;
            },

            loadWebPageGet: function () {
                return $ihttp.post({ method: 1184 });
            },

            loadP2Webpage: function () {
                return $ihttp.post({ method: 4 });
            }
        }

        return service;
    }]);
})();