(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('subtaskSelect', {
        templateUrl: 'views/components/views/subtaskSelect/subtaskSelect.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'subtaskSelectService', 'stateService', function($stateParams, subtaskSelectService, stateService) {
    
            var vm = this;
    
    
    
            // #######################
            // VARIABLES & DEFINITIONS
            // #######################
            
            vm.model = {
                p2CatagoryDataSelectSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '1', item_name: 'categorydataselect_select', item_func: 'S' },
                        { item_id: '2', item_name: 'categorydataselect_components', item_func: 'C' },
                        { item_id: '3', item_name: 'categorydataselect_goto', item_func: 'G' }
                    ]
                },
                p2CustactSubtasksList: {
                    records: []
                }
            };
    
            // ######################
            // BUTTON GROUP FUNCTIONS
            // ######################
    
            vm.selectP2CatagoryDataSelectView = function (lsP2CatagoryDataSelectItem) {
                //console.log('selectP2CatagoryDataSelectView --- lsP2CatagoryDataSelectItem = ' + lsP2CatagoryDataSelectItem);
                vm.model.p2CatagoryDataSelectSelection.item_selected = lsP2CatagoryDataSelectItem;
                //console.log('selectP2CatagoryDataSelectView --- vm.model.p2CatagoryDataSelectSelection.item_selected = ' + vm.model.p2CatagoryDataSelectSelection.item_selected);
                vm.retrieve();
            }
    
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
    
            vm.retrieve = function () {
                vm.model.p2CustactSubtasksList.records = [];
    
                subtaskSelectService.loadP2CustactSubtasksList($stateParams.argvalue, 'child', '0', vm.model.p2CatagoryDataSelectSelection.item_selected).then(function () {
                    vm.model.p2CustactSubtasksList = subtaskSelectService.p2CustactSubtasksList;
                });
            };
    
            // ######################
            // VALIDATION FUNCTIONS
            // ######################
    
            vm.validateCheckItem = function (item) {
                if (item.item_is_selected == '1') {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                    item.item_glyphicon = 'glyphicon-unchecked';
                } else {
                    item.item_is_selected = '1';
                    item.item_glyphicon_color = 'green';
                    item.item_glyphicon = 'glyphicon-check';
                }
            }
            
            vm.is_checked = 1;
    
            vm.checkUncheckSubtasksList = function () {
                if (vm.is_checked == 0) {
                    angular.forEach(vm.model.p2CustactSubtasksList.records, function (item) {
                        item.item_is_selected = '1';
                        item.item_glyphicon_color = 'green';
                        item.item_glyphicon = 'glyphicon-check';
                        //console.log('item = ' + angular.toJson(item));
                    });
    
                    vm.is_checked = 1;
                } else {
                    angular.forEach(vm.model.p2CustactSubtasksList.records, function (item) {
                        item.item_is_selected = '0';
                        item.item_glyphicon_color = 'black';
                        item.item_glyphicon = 'glyphicon-unchecked';
                    });
    
                    vm.is_checked = 0;
                }
            }
    
            // ######################
            // CHECK FUNCTION
            // ######################
    
            vm.addP2CustactSelectedSubtasks = function () {
                //console.log('vm.model.p2CustactSubtasksList.records');
                //console.dir(vm.model.p2CustactSubtasksList.records);
                //console.log(angular.toJson(vm.model.p2CustactSubtasksList.records));
    
                subtaskSelectService.addP2CustactSelectedSubtasks().then(function () {
                    //vm.model.p2CustactFindCategoryDataList = subtaskSelectService.p2CustactFindCategoryDataList;
    
                    stateService.back(2);
    
                    //stateService.go('custactcalc', {
                    //    argtype: 'custact',
                    //    argvalue: $stateParams.argvalue
                    //});
                });
            }
        }]
    });
})();