// BJS 20220421
(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('textileModels', {
        templateUrl: 'views/components/views/textileModels/textileModels.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['rememberService', 'textileModelsService', function (rememberService, textileModelsService) {
            var vm = this;

            var optionsRememberId = 'textileModels.options';

            vm.collectionKeyno = 0;
            vm.collectionKeynoButtons = [
                //{ id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', onClick: () => { if (vm.collections?.[0]?.collection_keyno ) vm.collectionKeyno = vm.collections[0].collection_keyno; } }
                //{ id: 'erase', icon: 'glyphicon glyphicon-erase', type: 'danger', onClick: () => { if (vm.collections?.[0]?.collection_keyno) vm.onCollectionChanged(vm.collections[0].collection_keyno); } }
                { id: 'erase', icon: 'glyphicon glyphicon-erase', type: 'danger', onClick: () => { if (vm.collections?.[0]?.collection_keyno) vm.onCollectionChanged(0); } }
            ];

            vm.collections = [];

            vm.textilemodel = {
                //isReady: false,
                //searchingTextilemodels: false,
                //rememberId: 'textilemodels.textilemodel',
                selected: {
                    field: '',
                    textilemodel_id: '',
                    textilemodel_name: ''
                },
                list: []
            };

            vm.ttGrid = {
                dataTask: {
                    rememberId: 'textilemodels.ttGrid',
                    loadData: {
                        method: 2260,
                        parameters: {
                            collection_keyno: vm.collectionKeyno,
                            textilemodel_id: vm.textilemodel.selected.textilemodel_id
                        }
                    },
                    addRow: {
                        method: null,
                        parameters: null,
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                    },
                    saveData: {
                        method: null,
                        parameters: {},
                    }
                },
                config: {
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        wrapping: true,
                        layouts: true,
                        buttons: [ ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false
                    },
                    onDataChanged: function () { },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: '100%',                                       // Sets the height of the grid component
                    aggregate: [],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: true
                },
                optionfunc: function (parms) { },
                gridfunc: null
            };

            var reloadGrid = function () {
                if (vm.collectionKeyno === 0 && vm.textilemodel.selected.textilemodel_id === '') return;
                vm.ttGrid.dataTask.loadData.parameters.collection_keyno = vm.collectionKeyno;
                vm.ttGrid.dataTask.loadData.parameters.textilemodel_id = vm.textilemodel.selected.textilemodel_id;

                vm.ttGrid.gridfunc.rebind();
            };

            var rememberOptions = function () {
                rememberService.remember(optionsRememberId, angular.toJson({
                    collectionKeyno: vm.collectionKeyno,
                    textilemodel: vm.textilemodel.selected
                }))
            };

            vm.onCollectionChanged = function (collectionKeyno) {
                vm.collectionKeyno = collectionKeyno;
               
                rememberOptions();

                reloadGrid();
            };

            rememberService.getLastStatus(optionsRememberId).then(function (savedState) {
                if (angular.isUndefined(savedState)) return;
                if (angular.isArray(savedState) !== true) return;
                if (savedState.length < 1) return;
                if (angular.isUndefined(savedState[0].variablevalue)) return;

                var lastStatus = angular.fromJson(savedState[0].variablevalue);

                if (angular.isUndefined(lastStatus)) return;
                if (lastStatus === null) return;
                if (angular.isUndefined(lastStatus.collectionKeyno)) return;

                vm.collectionKeyno = lastStatus.collectionKeyno;
                vm.textilemodel.selected = lastStatus.textilemodel || {
                    field: '',
                    textilemodel_id: '',
                    textilemodel_name: ''
                };

                reloadGrid();

            });

            textileModelsService.loadCollections().then(function (collections) {
                angular.copy(collections, vm.collections);
            });

            // #region LOOKUPSEARCH FUNCTIONS

            vm.typeaheadSearch = function (value, collection) {
                return textileModelsService.searchTextilemodelsKeyname(value, collection);
            };

            vm.onSearchChange = function (value) {
                vm.textilemodel.selected.textilemodel_id = value;

                rememberOptions();

                reloadGrid();
                
            }

            vm.selectTextilemodel = function (item) {
                vm.textilemodel.selected.field = item.textilemodel_keyname;
                vm.textilemodel.selected.textilemodel_id = item.textilemodel_id;
                vm.textilemodel.selected.textilemodel_name = item.textilemodel_name;
                vm.ttGrid.dataTask.loadData.parameters.textilemodel_id = vm.textilemodel.selected.textilemodel_id;

                rememberOptions();

                reloadGrid();
            }


            // #endregion LOOKUPSEARCH FUNCTIONS


        }]
    });
})();
