(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("srCrewService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSrCrewGet: function (resource_id, srcrew_keyno) {
                return p2DataTaskService.call(1628, {
                    resource_id: resource_id,
                    srcrew_keyno: srcrew_keyno
                });
            },
            save: function (crew) {
                return p2DataTaskService.call(1629, crew);
            }
        };

        return service;
    }]);
})();