export abstract class ServiceBase {
    protected isUndefined(value: any): boolean {
        return typeof value === 'undefined';
    }

    protected isObject(value: any): boolean {
        return value !== null && typeof value === 'object';
    }

    protected isFunction(value: any): boolean {
        return value !== null && typeof value === 'function';
    }

    protected isArray(value: any): boolean {
        return Array.isArray(value);
    }

    protected convertToJsonIfObject(value: any): string {
        if (this.isObject(value) || this.isFunction(value)) {
            try {
                return JSON.stringify(value);
            } catch(error) {
                console.error('Error converting object to JSON:', error);
            }
        }

        return String(value);
    }

    protected validateParmsValue(parmWithValue: any, allowEmptyString?: boolean): boolean {
        if (this.isUndefined(parmWithValue)) return false;
        if (parmWithValue === null) return false;

        if (this.isUndefined(allowEmptyString) || allowEmptyString !== true) {
            // Ensure parmWithValue has a trim function and validate its length
            if (!this.isFunction(parmWithValue.trim) || parmWithValue.trim().length < 1) {
                return false;
            }
        }

        return true;
    }

    protected validateParmsWithValue(parmWithValue: any): boolean {
        if (this.isUndefined(parmWithValue)) return false;
        if (parmWithValue === null) return false;
        if (!this.isFunction(parmWithValue.trim) || parmWithValue.trim().length < 1) return false;

        return true;
    }

    protected validateParmsEmptyValue(parmEmptyValue: any): boolean {
        if (this.isUndefined(parmEmptyValue)) return false;
        if (parmEmptyValue === null) return false;

        return true;
    }
}
