import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { TextAlign } from 'chart.js';
import { FormFieldButtons } from '../form-field-buttons/form-field-buttons.component';
import { Style } from '@app/core/services/core-component.service';
import { FormButton, FormButtonEvent } from '../form-field-button/form-field-button.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ListboxPopupDirective } from '@app/core/directives/listbox-popup/listbox-popup.directive';

@Component({
  selector: 'tt-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.css']
})
export class PasswordInputComponent extends FormFieldBaseComponent implements FormFieldButtons {
    /**
     * The model of the input field.
     */
    @Input()
    ttModel: string = '';

    /**
     * Event emitted when the model value changes from the component, $event is the new ttModel value.
     */
    @Output()
    ttModelChange = new EventEmitter<string>();

    /**
     * If true = hide then hide visibility button.
     */
    @Input()
    get ttHideToggleButton(): boolean {
        return this._hideToggleButton;
    }
    set ttHideToggleButton(value: BooleanInput) {
        this._hideToggleButton = coerceBooleanProperty(value);
    }
    private _hideToggleButton: boolean = false;

    /**
     * The text align for the form-field.
     */
    @Input()
    ttTextAlign?: TextAlign;
    
    @Input()
    ttButtons?: FormButton[] | undefined;

    @Input()
    ttButtonParms?: { [key: string]: any } | undefined;
    
    /**
     * Event emitted when the search input-field loses focus.
     */
    @Output()
    ttOnBlur = new EventEmitter<string>();

    /**
     * Reference to the base component.
     */
    @ViewChild('baseRef')
    baseRef?: FormFieldBaseComponent;

    /**
     * Reference to the input element.
     */
    @ViewChild('inputRef')
    inputRef?: ElementRef;
    
    /**
     * Emits `ttModelChanged` when theres a new value for `ttModel`.
     *
     * @param event the new value of `ttModel`
     */
    onModelChanged(event: string) {
        this.ttModelChange.emit(event);
    }
    
    toddleVisibility() {
        if (this.inputMode === 'password') {
            this.inputMode = 'text';
        } else {
            this.inputMode = 'password';
        }
    }

    inputMode: 'password' | 'text' = 'password';

    /**
     * Id of elements in the component.
     */
    id = {
        input: crypto.randomUUID(),
    };
    
    public override style: Style = {
        input: {},
    };

    public override setStyle(ttStyle = this.ttStyle) {
        this.style = this.coreComponentService.setStyle({ style: this.style, ttStyle: ttStyle ?? {}, textAlign: this.ttTextAlign, mainElement: 'input' });
    }
    
    /**
     * Emits the `ttOnBlur` event when the input field loses focus.
     *
     * @param _ unused focus event.
     */
    public onBlur(_: FocusEvent) {
        this.ttOnBlur.emit(this.ttModel);
    }
}

