(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srCheckListCrews', {
        templateUrl: 'views/components/views/srCheckListCrews/srCheckListCrews.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'srCheckListCrewsService', function($stateParams, stateService, srCheckListCrewsService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                checkListDataKeyNo: $stateParams.checklistdata_keyno,
                crews: {},
                itemsListCrews: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srCheckListCrewsService.loadSrCheckListCrewsGet(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.crews);
            });
    
            srCheckListCrewsService.loadSrCheckListCrewsList(vm.model.checkListDataKeyNo).then(function (data) {
                angular.copy(data, vm.model.itemsListCrews);
            });
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'srcrews':
                        go({
                            argtype: 'resource_id',
                            argvalue: vm.model.crews.resource_id
                        });
                        break;
                    case 'srchecklistcrewchange':
                        go({
                            checklistdata_keyno: vm.model.crews.checklistdata_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();