(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('acReportLayout', {
        templateUrl: 'views/components/views/acReportLayout/acReportLayout.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'acReportLayoutService', 'translateService', function ($stateParams, stateService, utilityService, modalService, acReportLayoutService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var acReportLayoutKeyno = $stateParams.acreportlayout_keyno;

            var translations = {
                acreportlayout_save_show_title: 'Varsel',
                acreportlayout_save_show_ok_label: 'OK'
            };

            vm.model = {
                edit: {},
                selectListAcReportLayouts: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            acReportLayoutService.getAcReportLayout({ acreportlayout_keyno: acReportLayoutKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.edit);

                loadAcReportLayouts();
            });

            var loadAcReportLayouts = function () {
                if (utilityService.validateParmsValue(vm.model.edit.acreportlayout_keyno, true) !== true) return;

                acReportLayoutService.listAcReportLayouts({ acreportlayout_keyno: vm.model.edit.acreportlayout_keyno }).then(function (result) {
                    angular.copy(result, vm.model.selectListAcReportLayouts);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                acReportLayoutService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.acreportlayout_save_show_title,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.acreportlayout_save_show_ok_label,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();		
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL
        }]
    });
})();