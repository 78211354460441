(function () {
    'use strict';

    angular.module("imApp").factory("stockTransferUnloadService", ['$ihttp', function ($ihttp) {
        let service = {
            getStockTransferUnload: function (parms) {
                return $ihttp.post({
                    method: 1043,
                    parameters: parms || {}
                });
            },
            selectLocation: function (edit) {
                return $ihttp.post({
                    method: 1048,
                    parameters: edit
                });
            },
            confirmObj: function (edit) {
                return $ihttp.post({
                    method: 1044,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
