(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("invoiceMethodLangsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadInvoiceMethodLangsList: function (invoicemethod_no) {
                return p2DataTaskService.call(1765, {
                    invoicemethod_no: invoicemethod_no
                });
            },
            save: function (mergeObj) {
                return p2DataTaskService.call(1767, mergeObj);
            }
        };

        return service;
    }]);
})();