(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('usergroupTable', {
        templateUrl: 'views/components/views/usergroupTable/usergroupTable.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'userGroupTableService', function ($stateParams, userGroupTableService) {
            
            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;

            vm.model = {
                itemsListUserGroupTables: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            userGroupTableService.listUserGroupTables({
                argtype: argType,
                argvalue: argValue
            }).then(function (result) {
                angular.copy(result, vm.model.itemsListUserGroupTables);
            });

		    // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();