(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("productBulkEditService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            searchInfo: '',
            editValues: {
                records: []
            },
            categoryLinks: {
                records: []
            },
            loadSearchInfo: function () {
                var deferred = $q.defer();

                var parms_loadSearchInfo = {
                    method: 713,
                    parameters: {}
                };

                $ihttp.post(parms_loadSearchInfo).then(function (data) {
                    service.searchInfo = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadEditValues: function () {
                var deferred = $q.defer();

                var parms_loadEditValues = {
                    method: 717,
                    parameters: {}
                };

                $ihttp.post(parms_loadEditValues).then(function (data) {
                    angular.copy(data, service.editValues.records);
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadCategoryList: function () {
                var deferred = $q.defer();

                var parms_loadCategoryList = {
                    method: 714,
                    parameters: {}
                };

                $ihttp.post(parms_loadCategoryList).then(function (data) {
                    angular.copy(data, service.categoryLinks.records);
                    deferred.resolve();
                });

                return deferred.promise;
            },
            productBulkEditRetrieve: function (searchInfo) {
                var deferred = $q.defer();

                var parmsP2ProductBulkEditSearch = {
                    method: 715,
                    parameters: searchInfo
                };

                $ihttp.post(parmsP2ProductBulkEditSearch).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            },
            productBulkEditUpdate: function (searchInfo, records) {
                var deferred = $q.defer();

                var parmsP2ProductBulkEditUpdate = {
                    method: 716,
                    parameters: {
                        info: searchInfo,
                        records: records
                    }
                };

                $ihttp.post(parmsP2ProductBulkEditUpdate).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();