(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('dispatchPlanningBlockOrder', {
        templateUrl: 'views/components/views/dispatchPlanningBlockOrder/dispatchPlanningBlockOrder.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'dispatchPlanningBlockOrderService', function($stateParams, dispatchPlanningBlockOrderService) {
            var vm = this;
            
            vm.model = {
                order_internal_no: $stateParams.order_internal_no,
                blockOrderList: []
            };
            
            dispatchPlanningBlockOrderService.listOrders({order_internal_no: vm.model.order_internal_no}).then(function(data){
                angular.copy(data,vm.model.blockOrderList);
            });
        }]
    });
})();