(function () {
    'use strict';

    angular.module("imApp").factory("campaignEditComponentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            get: function (campaignKeyNo) {
                return p2DataTaskService.call(1952, { campaign_keyno: campaignKeyNo });
            },
            save: function (editCampaign) {
                return p2DataTaskService.call(1958, editCampaign);
            }
        }

        return service;
    }]);
})();