(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('choices', {
        templateUrl: 'views/components/views/choices/choices.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'choicesService', function ($stateParams, choicesService) {
            const vm = this;
            let prodId = $stateParams.prod_id;

            vm.model = {
                setting: {},
                itemsListChoices: []
            };

            let loadSetting = function () {
                choicesService.getSetting({ prod_id: prodId }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);
                });
            };

            let loadChoices = function () {
                choicesService.listChoices({ prod_id: prodId }).then(function (list) {
                    angular.copy(list, vm.model.itemsListChoices);
                });
            };

            vm.$onInit = function () {
                loadSetting();
                loadChoices();
            };
        }]
    });
})();
