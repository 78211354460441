(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('myFontSize', {
        templateUrl: 'views/components/views/myFontSize/myFontSize.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'utilityService', 'userStore', 'userService', 'translateService', 'myFontSizeService', 'themeService', function (stateService, utilityService, userStore, userService, translateService, myFontSizeService, themeService) {
            const vm = this;

            vm.model = {
                get: {},
                lock: false
            };

            vm.translations = {
                user: '',
                myfontsize_fontsize: '',
                myfontsize_mobile_fontsize: '',
                tt_input_style_underline: '',
                tt_input_style_frames: '',
                desktop_settings: '',
                mobile_settings: ''
            };

            vm.onFontSizeChanged = function () {
                if (userService?.userId === vm.model.get?.portal_user_keyno && vm.model.get?.fontsize !== '') {
                    updateFontsizeVariables();
                }
            };

            vm.onFontSizeMobileChanged = function () {
                if (userService?.userId === vm.model.get?.portal_user_keyno && vm.model.get?.fontsize_mobile_slider !== '') {
                    updateFontsizeVariables();
                }
            };

            vm.onInputStyleChanged = function (value) {
                vm.model.get.input_style = value;
                userService.updateInputStyles(value);
            }

            vm.onThemeChanged = function (value) {
                vm.model.get.theme_keyno = value;
                userService.updateTheme(value);
            }

            vm.onThemeMobileChanged = function (value) {
                vm.model.get.theme_keyno_mobile = value;

                if (window.matchMedia("(min-width: 768px)").matches === false) {
                    userService.updateMobileTheme(value);
                }
            }

            vm.onUseAgGridChanged = function (value) {
                vm.model.get.use_ag_grid = value;

                userStore.setUseAgGrid(value === '1');
            }

            function updateFontsizeVariables() {
                vm.model.get.fontsize_mobile = vm.model.get.fontsize_mobile_slider - vm.model.get.fontsize;
                userStore.setFontSize(vm.model.get.fontsize)
                userStore.setFontSizeMobile(vm.model.get.fontsize_mobile.toString());
            }

            vm.saveMyFontSize = async function () {
                vm.model.lock = true;
                //console.dir(vm.model.get);
                try {
                    //console.log('SAVING FONT SIZES');
                    //vm.model.get.fontsize_mobile = vm.model.get.fontsize_mobile_slider - vm.model.get.fontsize;
                    //console.log(vm.model.get.fontsize_mobile);
                    const data = await myFontSizeService.save(vm.model.get);
                    if (data[0].errorcode !== '0') {
                        utilityService.showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning');
                    } else {
                        stateService.back();
                    }
                } finally {
                    vm.model.lock = false;
                }
            };

            async function getPageLoad() {
                vm.model.get = (await myFontSizeService.loadMyFontSizeGet())[0];
                vm.model.get.fontsize_mobile_slider = Number(vm.model.get.fontsize) + Number(vm.model.get.fontsize_mobile);
            };

            async function getThemes() {
                vm.model.themes = await themeService.getThemes();
            };

            async function translate() {
                const data = await translateService.translateBatch(vm.translations);
                Object.keys(vm.translations).forEach((key) => {
                    if (data?.[key]) vm.translations[key] = data[key];
                });
            };

            vm.$onInit = async function () {
                await Promise.all([getPageLoad(), translate()]);
                vm.model.heading = vm.translations.user + ': ' + vm.model.get.user_name;
                getThemes();
                vm.model.inputStyles = [
                    { item_id: '0', item_name: vm.translations.tt_input_style_underline },
                    { item_id: '1', item_name: vm.translations.tt_input_style_frames },
                ]
            };
        }]
    });
})();
