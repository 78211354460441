(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('productWaste', {
        templateUrl: 'views/components/views/productWaste/productWaste.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['productWasteService', '$q', 'rememberService', function (productWasteService, $q, rememberService) {
            var vm = this;

            vm.model = {};
            vm.model.edit = {};
            vm.model.edit.prodtype_no = 0;
            vm.model.edit.searchtext = '';
            vm.model.edit.grid_prod_id_filter = '';

            vm.model.selectListProdType = [];

            var rememberIdGrid = 'w_productWasteGrid';
            var rememberIdModelEdit = 'w_productWasteModelEdit';

            vm.grid = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 3419,
                        parameters: vm.model.edit || {}
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: true,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    //serverSideHandling: true
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };



            var remember = function () {
                rememberService.remember(rememberIdModelEdit, vm.model.edit);
                vm.grid.gridfunc.rebind();
            };


            var loadSelectListProdType = function () {
                productWasteService.listProdType(vm.model.edit).then(function (result) {
                    angular.copy(result, vm.model.selectListProdType);
                });
            };

            vm.onChangeProdType = function (prodtype_no) {
                vm.model.edit.prodtype_no = prodtype_no;
                remember();
            };

            vm.prodSearch = function (value, searchparam) {
                vm.model.edit.grid_prod_id_filter = value;
                vm.model.edit.searchtext = value;
                remember();
                return productWasteService.prodSearch(vm.model.edit);
            };

            vm.prodSelectItem = function (item, model) {
                if (item?.prod_id && item.prod_id !== '') {
                    vm.model.edit.prod_id = item.prod_id;
                    vm.model.edit.prod_name = item.prod_name;
                    vm.model.edit.prod_keyname = item.prod_keyname;
                    vm.model.edit.grid_prod_id_filter = item.prod_id;
                    remember();
                }
            };

            vm.OnChangeProdSearch = function (prod_id) {
                if (prod_id === '') {
                    vm.model.edit.grid_prod_id_filter = '';
                    vm.model.edit.prod_id = '';
                    vm.model.edit.prod_name = '';
                    vm.model.edit.prod_keyname = '';
                    remember();
                }
            };


            var load = function () {

                var deferred = $q.defer();

                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdModelEdit, true));

                $q.all(promises).then(function (response) {
                    vm.model.edit.prodtype_no = response[0]?.prodtype_no || '0';
                    vm.model.edit.prod_id = response[0]?.prod_id || '';
                    vm.model.edit.prod_name = response[0]?.prod_name || '';
                    vm.model.edit.prod_keyname = response[0]?.prod_keyname || '';
                    vm.model.edit.grid_prod_id_filter = response[0]?.grid_prod_id_filter || '';
                    loadSelectListProdType();
                    vm.gridReady = true;
                });

                return deferred.promise;
            };


            vm.$onInit = function () {
                load();
            };




        }]
    });
})();
