import { Injectable } from '@angular/core';
import { DataTaskService } from './data-task.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AppSettingsService } from './app-settings.service';
import { IUserDetails, UserDetails } from '../models/user-details';
import { UserSetting } from '../models/user-setting';
import { UserItem } from '../models/user-item';

export interface IResponse<T> {
    isError: boolean,
    code: number,
    message: string,
    data: T
}

export interface ISaveResponse {
    ws_result: string,
    ws_result_message: string
}

export interface IGenerateLinkResponse {
    isError: false,
    errorCode: number,
    message: string,
    result: IGenerateLinkData
}

export interface IGenerateLinkData {
    payload: string,
    hash: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
    constructor(private appSettings: AppSettingsService, private ds: DataTaskService, private http: HttpClient) { }

    public async currentUserDetails(force: boolean = false): Promise<UserDetails> {
        if (!this.appSettings) return {} as UserDetails
;
        if (force !== true && this.appSettings.settings.user !== undefined) {
            return this.appSettings.settings.user as UserDetails;
        }

        var user = (await this.ds.Post(58, { username_get: -1 }))[0];

        this.appSettings.settings.user = new UserDetails(user);
        
        return this.appSettings.settings.user;
    }

    public async userDetails(portalUserKeyno: number, labourNo: number): Promise<IUserDetails> {
        const response = await this.ds.Post(
            58,
            {
                username_get: portalUserKeyno,
                labour_no: labourNo || 0
            }
        );

        return response[0];
    }
    
    public getSettings(portalUserKeyno: number): Promise<UserSetting[]> {
        return this.ds.Post(1881, { portal_user_keyno: portalUserKeyno });
    }

    public getList(activeStatus: string, userType: string, filterValue: string): Promise<UserItem[]> {
        return this.ds.Post(63, {
                activestatus: activeStatus,
                usertype: userType,
                filtervalue: filterValue
            }
        );
    }

    public async save(user: IUserDetails): Promise<ISaveResponse> {
        const response = await this.ds.Post(
            59,
            user
        );

        return response as ISaveResponse;
    }

    public async generateUserLink(userName: string): Promise<IGenerateLinkData> {
        if (!this.appSettings) return {} as IGenerateLinkData;

        var url = this.appSettings?.settings.baseUrl;

        if (url.endsWith('/') !== true) {
            url += '/';
        }

        url += 'ul-api/generate';

        const parms = { userName: userName };

        const headers = {
            'Content-Type': 'application/json'
        };

        const requestOptions = {
            headers: new HttpHeaders(headers)
        };

        const data$ = this.http.post(url, parms, requestOptions);

        const response = await lastValueFrom(data$) as IGenerateLinkResponse;

        return response.result as IGenerateLinkData;
    }
}
