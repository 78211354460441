(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockCorrectionService", ['$q', '$ihttp', 'p2DataTaskService', function ($q, $ihttp, p2DataTaskService) {
        var service = {
            loaded: false,
            edit: {},
            loadP2StockStockCorrectionNew: function (stockKeyNo, stockLocationKeyNo) {
                var deferred = $q.defer();

                var parmsP2StockStockCorrectionNew = {
                    method: 440,
                    parameters: {
                        stock_keyno: stockKeyNo,
                        stocklocation_keyno: stockLocationKeyNo
                    }
                };

                $ihttp.post(parmsP2StockStockCorrectionNew).then(function (data) {
                    service.edit = angular.copy(data[0]);

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadStockCorrCodeList: function () {
                return p2DataTaskService.call(1071, {});
            },
            saveP2StockStockCorrectionNew: function (params) {
                var deferred = $q.defer();

                var parmsP2StockStockCorrectionNewSave = {
                    method: 441,
                    parameters: params
                };

                $ihttp.post(parmsP2StockStockCorrectionNewSave).then(function (data) {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
