(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderPurchMenu', {
        templateUrl: 'views/components/views/orderPurchMenu/orderPurchMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'orderPurchMenuService', function ($stateParams, $q, stateService, orderPurchMenuService) {
            let vm = this;
            let setting = {};
            
            vm.model = {
    			itemsListPurchaseHeads: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();

                orderPurchMenuService.getSetting({ order_internal_no: $stateParams.order_internal_no }).then(function (result) {
                    angular.copy(result[0], setting);

                    $q.all([
                        loadPurchaseHeads()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
    		
    		let loadPurchaseHeads = function () {
                return orderPurchMenuService.listPurchaseHeads(setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListPurchaseHeads);
    			});
    		};
            
            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'orderpurch':
                        go({ order_internal_no: setting.order_internal_no });
                        break;
                    default:
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadSetting();
            };
        }]
    });
})();
