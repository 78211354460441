(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('crmselection', {
        templateUrl: 'views/components/views/crmselection/crmselection.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'modalService', 'translateService', 'crmSelectionService', 'economyService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, modalService, translateService, crmSelectionService, economyService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let variableNames = {
                facts_id: '',
                pers_facts_id: '',
                custgrp_id: '',
                country_id: '',
                county_id: '',
                gb_crmselection: '',
                bulkmail_keyno: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgCrmSelection: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'gb_crmselection_cust', item_func: 'CUST' },
                        { item_id: '1', item_name: 'gb_crmselection_pers', item_func: 'PERS' }
                    ]
                }, 
                factsButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('facts_id') }
                ],
                personFactsButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('pers_facts_id') }
                ],
                customerGroupsButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('custgrp_id') }
                ],
                countryButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('country_id') }
                ],
                countyButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: () => reset('county_id') }
                ],
                bulkMailButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('bulkmail_keyno') },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('bulkmails', {}) }
                ],
                bulkMailButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('bulkmail_keyno') },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('bulkmails', {}) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('bulkmailmenu', { bulkmail_keyno: vm.model.setting.bulkmail_keyno }) }
                ],
                setting: {},
                selectListFacts: [],
                selectListPersonFacts: [],
                selectListCustomerGroups: [],
                selectListCountries: [],
                selectListCounties: [],
                selectListBulkMails: [],
                gridCustomersReady: false,
                gridPersonsReady: false
            };

            vm.gridCustomers = {
                dataTask: {
                    rememberId: 'w_crmselection_cust_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridPersons = {
                dataTask: {
                    rememberId: 'w_crmselection_pers_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            {
                                name: 'crmselection_remove_selected_to_bulkmail',
                                text: 'crmselection_remove_selected_to_bulkmail',
                                func: function () {
                                    removeItems();
                                },
                                icon: 'fa-trash',
                                cssClass: 'tt-button tt-button--danger im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            {
                                name: 'crmselection_add_selected_to_bulkmail',
                                text: 'crmselection_add_selected_to_bulkmail',
                                func: function () {
                                    addItems();
                                },
                                icon: 'fa-plus',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                disabled: function () {
                                    if (vm.model.setting.bulkmail_keyno > 0) {
                                		return false;
                                	} else {
                                		return 'hidden';
                                	}
                                }
                            },
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadCrmSelection = function () {
                let deferred = $q.defer();

            	crmSelectionService.getCrmSelection().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    vm.model.bgCrmSelection.item_default = utilityService.groupButtonIdOf(vm.model.bgCrmSelection, vm.model.setting.gb_crmselection); 

                    $q.all([
                        loadCounties()
                    ]).then(() => {
                        vm.gridCustomers.dataTask.loadData = {
                            method: 2910,
                            parameters: vm.model.setting
                        };

                        vm.gridPersons.dataTask.loadData = {
                            method: 2911,
                            parameters: vm.model.setting
                        };

                        vm.model.gridCustomersReady = true;
                        vm.model.gridPersonsReady = true;

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadFacts = function () {
                crmSelectionService.listFacts().then(function (result) {
                    angular.copy(result, vm.model.selectListFacts);
                });
            };

            let loadPersonFacts = function () {
                crmSelectionService.listPersonFacts().then(function (result) {
                    angular.copy(result, vm.model.selectListPersonFacts);
                });
            };

            let loadBulkMails = function () {
                crmSelectionService.listBulkMails().then(function (result) {
                    angular.copy(result, vm.model.selectListBulkMails);
                });
            };

            let loadCustomerGroups = function () {
                economyService.listCustomerGroups({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCustomerGroups);
                });
            };

            let loadCountries = function () {
                logisticService.listCountries({ add_all: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListCountries);
                });
            };

            let loadCounties = function () {
                if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;
                
                return logisticService.listCounties({
                    add_all: '1',
                    country_id: vm.model.setting.country_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListCounties);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'facts_id':
                    case 'pers_facts_id':
                    case 'custgrp_id':
                    case 'country_id':
                    case 'county_id':
                    case 'gb_crmselection':
                    case 'bulkmail_keyno':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let addItems = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bulkmail_keyno) !== true) return;

                let currentData = vm.gridPersons.gridfunc.getAllRows();
                let selectedItems = [];

                angular.forEach(currentData, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({
                        pers_no: item.pers_no,
                        person_name: item.person_name,
                        pers_email: item.pers_email
                    });
                });

                let mergeObj = {
                    bulkmail_keyno: vm.model.setting.bulkmail_keyno,
                    records: selectedItems
                };

                crmSelectionService.addBulkEmails(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        vm.gridPersons.gridfunc.rebind();
                    }
                });
            };

            let removeItems = function () {
                if (utilityService.validateParmsValue(vm.model.setting.bulkmail_keyno) !== true) return;

                let currentData = vm.gridPersons.gridfunc.getAllRows();
                let selectedItems = [];

                angular.forEach(currentData, function (item) {
                    if (item.is_selected === '0' || item.is_selected === false) return;

                    selectedItems.push({ pers_no: item.pers_no });
                });

                let mergeObj = {
                    bulkmail_keyno: vm.model.setting.bulkmail_keyno,
                    records: selectedItems
                };

                crmSelectionService.removeBulkEmails(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        vm.gridPersons.gridfunc.rebind();
                    }
                });
            };

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgCrmSelection = function (item) {
                if (utilityService.validateWatchValue(item, vm.model.bgCrmSelection.item_selected) !== true) return;

                vm.model.bgCrmSelection.item_selected = item;
                vm.model.setting.gb_crmselection = vm.model.bgCrmSelection.item_selected;

                rememberFunc('gb_crmselection');
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region RESET BUTTON FUNCTION

            let reset = function (keyno) {
                switch (keyno) {
                    case 'facts_id':
                    case 'pers_facts_id':
                    case 'custgrp_id':
                    case 'county_id':
                        vm.model.setting[keyno] = 'ALL';

                        rememberFunc(keyno);

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'country_id':
                        vm.model.setting.country_id = 'ALL';
                        vm.model.setting.county_id = 'ALL';

                        rememberFunc('country_id');
                        rememberFunc('county_id');
                        loadCounties();

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'bulkmail_keyno':
                        vm.model.setting.bulkmail_keyno = '0';

                        rememberFunc('bulkmail_keyno');
                        break;
                    default:
                        break;
                }
            };

		    // #endregion RESET BUTTON FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                let parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'facts_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.facts_id) !== true) return;

                        vm.model.setting.facts_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.facts_id, true) !== true) return;

                        rememberFunc('facts_id');

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'pers_facts_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.pers_facts_id) !== true) return;

                        vm.model.setting.pers_facts_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.pers_facts_id, true) !== true) return;

                        rememberFunc('pers_facts_id');

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'custgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.custgrp_id) !== true) return;

                        vm.model.setting.custgrp_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.custgrp_id, true) !== true) return;

                        rememberFunc('custgrp_id');

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'country_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.country_id) !== true) return;

                        vm.model.setting.country_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;

                        rememberFunc('country_id');
                        loadCounties();

                        vm.model.setting.county_id = 'ALL';

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'county_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.county_id) !== true) return;

                        vm.model.setting.county_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.county_id, true) !== true) return;

                        rememberFunc('county_id');

                        if (vm.model.bgCrmSelection.item_selected === 'CUST') {
                            vm.gridCustomers.gridfunc.rebind();
                        } else {
                            vm.gridPersons.gridfunc.rebind();
                        }
                        break;
                    case 'bulkmail_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.bulkmail_keyno) !== true) return;

                        vm.model.setting.bulkmail_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.bulkmail_keyno, true) !== true) return;

                        rememberFunc('bulkmail_keyno');
                        vm.gridPersons.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadCrmSelection();
                loadFacts();
                loadPersonFacts();
                loadCustomerGroups();
                loadCountries();
                loadBulkMails();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
