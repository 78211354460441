(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactWork', {
        templateUrl: 'views/components/views/custactWork/custactWork.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'translateService', 'custactCompsService', 'custactWorkService', 'logisticService', 'eventService', function ($stateParams, $q, $timeout, stateService, utilityService, modalService, translateService, custactCompsService, custactWorkService, logisticService, eventService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let custactNo = $stateParams.custact_no;
            let custactWorkKeyno = $stateParams.custactwork_keyno;
    		
            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                custactWorkKeyno: custactWorkKeyno,
                equipmentsButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('equipment_id') }
                ],
                p2CustactGet: {},
                edit: {},
                selectListOperations: [],
                selectListEquipments: [],
                lockedSaveAndNew: false,
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // ###################
            // LOAD PROCEDURE CALL
            // ###################

            let loadP2CustactGet = function () {
                custactCompsService.loadP2CustactGet(custactNo).then(function () {
                    vm.model.p2CustactGet = custactCompsService.p2CustactGet;
                });
            };
            
            let loadEdit = function () {
                let deferred = $q.defer();

                custactWorkService.getEdit({
                    custact_no: custactNo,
                    custactwork_keyno: custactWorkKeyno
                }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    $q.all([
                        loadEquipments()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadOperations = function () {
                logisticService.listOperations({ direct: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListOperations);
                });
            };
            
            let loadEquipments = function () {
                if (utilityService.validateParmsValue(vm.model.edit.operation_id) !== true) return;
                
                return logisticService.listEquipments({ operation_id: vm.model.edit.operation_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListEquipments);
                });
            };

            let loadGetProduct = function () {
                if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;
                
                custactWorkService.getProduct({
                    operation_id: vm.model.edit.operation_id,
                    equipment_id: vm.model.edit.equipment_id
                }).then(function (response) {
                    vm.model.edit.prod_id = response[0].prod_id;
                    vm.model.edit.prodsize_keyno = response[0].prodsize_keyno;
                    vm.model.edit.prodchoice_keyno = response[0].prodchoice_keyno;

                    loadGetPrice();
                });
            };
            
            let loadGetPrice = function () {
                if (utilityService.validateParmsValue(vm.model.edit.custact_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.equipment_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prod_id) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.prodchoice_keyno) !== true) return;
                
                custactWorkService.getPrice({
                    custact_no: vm.model.edit.custact_no,
                    operation_id: vm.model.edit.operation_id,
                    equipment_id: vm.model.edit.equipment_id,
                    prod_id: vm.model.edit.prod_id,
                    prodsize_keyno: vm.model.edit.prodsize_keyno,
                    prodchoice_keyno: vm.model.edit.prodchoice_keyno
                }).then(function (response) {
                    vm.model.edit.cost_price_invval = response[0].cost_price_invval;
                    vm.model.edit.ordval_price_brt = response[0].ordval_price_brt;

                    calculateCost();
                    calculatePrice();
                });
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'equipment_id':
                        vm.model.edit.equipment_id = '';

                        loadGetProduct();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ##############
            // CALC FUNCTIONS
            // ##############
            
            let calculateCost = function () {
                vm.model.edit.cost_locval = utilityService.parseNumber(vm.model.edit.cost_price_invval) * utilityService.parseNumber(vm.model.edit.quantity_hours);
            };
            
            let calculatePrice = function () {
                vm.model.edit.ordval_amount_net = utilityService.parseNumber(vm.model.edit.ordval_price_brt) * utilityService.parseNumber(vm.model.edit.quantity_hours);
            };
            
            let calculateExchangeAmount = function () {
                vm.model.edit.locval_amount_net = utilityService.parseNumber(vm.model.edit.ordval_amount_net) * utilityService.parseNumber(vm.model.edit.ordval_exchangefactor);
            };
            
            let calculateLocValAmount = function () {
                vm.model.edit.locval_db = utilityService.parseNumber(vm.model.edit.ordval_amount_net) - utilityService.parseNumber(vm.model.edit.cost_locval);
            };

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'operation':
                        go({ operation_id: vm.model.edit.operation_id });
                        break;
                    case 'equipmentedit':
                        go({ equipment_id: vm.model.edit.equipment_id });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveAndNewChanges = function () {
                vm.model.lockedSaveAndNew = true;

                custactWorkService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSaveAndNew = false;

                    stateService.go('custactwork', {
                        custact_no: vm.model.edit.custact_no,
                        custactwork_keyno: '0'
                    });

                    loadEdit();
                    vm.setOperationFocus();
                }, function () {
                    vm.model.lockedSaveAndNew = false;
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                custactWorkService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back('/custactworks/' + vm.model.edit.custact_no);
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    custactWorkService.deleteItem({ custactwork_keyno: $stateParams.custactwork_keyno }).then(function () {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
            
            vm.setOperationFocus = function () {
                // sets focus on first enabled, visible, not readonly element.
                $(':input:enabled:visible:not([readonly]):first').focus();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'quantity_hours':
                        if (utilityService.validateWatchValue(value, vm.model.edit.quantity_hours) !== true) return;

                        vm.model.edit.quantity_hours = value;

                        if (utilityService.validateParmsValue(vm.model.edit.quantity_hours) !== true) return;

                        calculateCost();
                        calculatePrice();
                        break;
                    case 'operation_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.operation_id) !== true) return;

                        vm.model.edit.operation_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.operation_id) !== true) return;

                        loadEquipments();
                        loadGetProduct();
                        break;
                    case 'equipment_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.equipment_id) !== true) return;

                        vm.model.edit.equipment_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.equipment_id) !== true) return;

                        loadGetProduct();
                        break;
                    case 'ordval_amount_net':
                        if (utilityService.validateWatchValue(value, vm.model.edit.ordval_amount_net) !== true) return;

                        vm.model.edit.ordval_amount_net = value;

                        if (utilityService.validateParmsValue(vm.model.edit.ordval_amount_net) !== true) return;

                        calculateExchangeAmount();
                        calculateLocValAmount();
                        break;
                    case 'cost_locval':
                        if (utilityService.validateWatchValue(value, vm.model.edit.cost_locval) !== true) return;

                        vm.model.edit.cost_locval = value;

                        if (utilityService.validateParmsValue(vm.model.edit.cost_locval) !== true) return;

                        calculateLocValAmount();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
            //onReady
            eventService.on('$viewContentLoaded', function () {
                $timeout(function () {
                    vm.setOperationFocus();
                }, 50);
            });

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadP2CustactGet();
                loadEdit();
                loadOperations();
            };

            // #endregion ON INIT FUNCTION
            
        }]
    });
})();
