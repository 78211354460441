(function () {
    'use strict';

    angular.module("imApp").factory("campaignsComponentService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            get: function () {
                return p2DataTaskService.call(1931, {})
            },
            list: function (campaign) {
                return p2DataTaskService.call(1949, { campaign_selected: campaign });
            }
        };

        return service;
    }]);
})();