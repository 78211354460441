(function () {
	'use strict';

	let module = angular.module("imApp");

    module.factory("actStatusMenuService", ['$ihttp', function ($ihttp) {
        let service = {
            getMenu: function (parms) {
                return $ihttp.post({
                    method: 1332,
                    parameters: parms || {}
                });
            }
		};

		return service;
	}]);
})();
