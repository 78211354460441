import { ODataQueryParms } from "../model/odata-query-parms.model";
import { GraphQuery } from './graph-query.object';
import { NullableOption } from "@microsoft/microsoft-graph-types";
import { GraphAuthService } from "../auth/services/graph-auth.service";

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
}

export abstract class GraphServiceBase {
    protected readonly _rootUrl = 'https://graph.microsoft.com/v1.0';
    private _authService: NullableOption<GraphAuthService> = null;

    constructor() {
        this._authService = new GraphAuthService();
    }

    protected updateUrl(urlTemplate: string, userId?: string, itemId?: string, groupId?: string, itemId2?: string): string {
        urlTemplate = this.replaceUrl(urlTemplate, '{userId}', userId);
        urlTemplate = this.replaceUrl(urlTemplate, '{groupId}', groupId);
        urlTemplate = this.replaceUrl(urlTemplate, '{itemId}', itemId);
        urlTemplate = this.replaceUrl(urlTemplate, '{itemId2}', itemId2);

        return this._rootUrl + urlTemplate;
    }

    protected replaceUrl(urlTemplate: string, id: string, value: string | undefined): string {
        if (value === undefined) return urlTemplate;
        if (value === null) return urlTemplate;
        if (value.trim().length === 0) return urlTemplate;

        return urlTemplate.replace(id, value);
    }

    private hasValue(item: string | null | undefined): boolean {
        if (item === undefined) return false;
        if (item === null) return false;
        if (item.trim().length === 0) return false;

        return true;
    }

    protected checkValues(userId?: string | null, itemId?: string | null, groupId?: string | null, itemId2?: string | null): { userId: boolean, itemId: boolean, groupId: boolean, itemId2: boolean } {
        return {
            userId: this.hasValue(userId),
            itemId: this.hasValue(itemId),
            groupId: this.hasValue(groupId),
            itemId2: this.hasValue(itemId2)
        };
    }

    protected async getData(url: string, accessToken?: string): Promise<any> {
        return this.send(HttpMethod.GET, url, undefined, accessToken);
    }

    protected async postData(url: string, body: any, accessToken?: string): Promise<any> {
        return this.send(HttpMethod.POST, url, body, accessToken);
    }

    protected async putData(url: string, body: any, accessToken?: string): Promise<any> {
        return this.send(HttpMethod.PUT, url, body, accessToken);
    }

    protected async patchData(url: string, body: any, accessToken?: string): Promise<any> {
        return this.send(HttpMethod.PATCH, url, body, accessToken);
    }

    protected async deleteData(url: string, accessToken?: string): Promise<any> {
        return this.send(HttpMethod.DELETE, url, undefined, accessToken);
    }

    protected async send(method: HttpMethod, url: string, body?: any, accessToken?: string): Promise<any> {
        const headers = new Headers();

        if (typeof accessToken !== 'string' || (accessToken?.length ?? 0 < 1)) {
            accessToken = await this._authService?.getAccessToken();
        }

        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        headers.append("Prefer", 'IdType="ImmutableId"')

        let options: any;

        if (typeof body !== undefined && body !== null) {
            options = {
                method: method,
                headers: headers,
                body: JSON.stringify(body)
            };
        } else {
            options = {
                method: method,
                headers: headers
            };
        }

        const rawResponse = await fetch(url, options);

        if (rawResponse.status === 204) return undefined;

        let data = (await rawResponse.json());

        return data;
    }
}
