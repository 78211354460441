import { ValueSetterParams } from '@ag-grid-community/core';

(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('work', {
        templateUrl: 'views/components/views/work/work.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'translateService', 'workService', 'workGrpService', 'equipmentEditService', 'operationFactoryService', 'logisticService', function ($stateParams, stateService, utilityService, modalService, translateService, workService, workGrpService, equipmentEditService, operationFactoryService, logisticService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                rememberId: 'w_work',
                prodId: $stateParams.prod_id,
                workKeyno: $stateParams.work_keyno,
                factoryButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('factories') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('factorymenu', { factory_id: vm.model.edit.factory_id }) }
                ],
                operationButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('operations') }
                ],
                operationButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('operations') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('operationmenu', { operation_id: vm.model.edit.operation_id }) }
                ],
                workGroupButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.workgrp_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('workgrps') }
                ],
                workGroupButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.workgrp_keyno = '0' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('workgrps') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('workgrpmenu', { workgrp_keyno: vm.model.edit.workgrp_keyno }) }
                ],
                equipmentButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('equipments') }
                ],
                equipmentButtons2: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.edit.equipment_id = '' },
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('equipments') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('equipmentedit', { equipment_id: vm.model.edit.equipment_id }) }
                ],
                unitButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('units') }
                ],
                unitButtons2: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('units') },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('unit', { unit_no: vm.model.edit.unit_no }) }
                ],
                edit: {},
                selectListFactories: [],
                selectListOperations: [],
                selectListWorkGroups: [],
                selectListEquipments: [],
                selectListUnits: [],
                selectListLinkTypes: [],
                gridReady: false,
                lockedSave: false,
                lockedDelete: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        if (Object.hasOwn(changed, 'colDef')) {
                            onDataChangedAgGrid(changed);
                        } else {
                            onDataChangedKendo(changed, e);
                        }
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            function onDataChangedKendo(changed, e) {
                console.log('onDataChanged');
                console.dir(e);
                let var_quantity = 0.00000;
                let var_time_adjust_factor = 0.00;

                if (e.values.hasOwnProperty('quantity')) {
                    var_quantity = e.values.quantity;
                    var_time_adjust_factor = e.model.time_adjust_factor;
                }
                if (e.values.hasOwnProperty('time_adjust_factor')) {
                    var_quantity = e.model.quantity;
                    var_time_adjust_factor = e.values.time_adjust_factor;
                }

                console.log('var_quantity=', var_quantity);
                console.log('var_time_adjust_factor=', var_time_adjust_factor);

                if (e.values.hasOwnProperty('quantity') || e.values.hasOwnProperty('time_adjust_factor')) {
                    console.log('hit');
                    e.model.quantity_multiplied_by_std_time = (var_quantity * e.model.std_time)// .toFixed(4);
                    e.model.quantity_in_work_units = (var_quantity * e.model.std_time_in_work_units)//.toFixed(4);
                    e.model.adjusted_quantity_in_work_units = (e.model.quantity_in_work_units * var_time_adjust_factor) //.toFixed(5);
                    console.log('time_adjust_factor: ' + e.model.time_adjust_factor);
                    console.log('time_adjust_factor: ' + e.model.time_adjust_factor);
                    e.model.dirtyFields.quantity_multiplied_by_std_time = true;
                    e.model.dirtyFields.quantity_in_work_units = true;

                    const grid = vm.grid.gridfunc.getGrid();
                    const data = grid.dataSource.data();
                    let total = 0;

                    angular.forEach(data, function (item) {
                        total += parseFloat(item.quantity_in_work_units);
                    });

                    total = total.toFixed(4);
                    //total = total.replace(".", ",");

                    angular.forEach(grid.columns, function (col) {
                        if (col.field === 'quantity_in_work_units') {
                            col.footerTemplate = '<div style=\"text-align: right; font-size: 12px;\">' + total + '</div>';
                        }
                    });

                    total = 0;

                    angular.forEach(data, function (item) {
                        total += parseFloat(item.adjusted_quantity_in_work_units);
                    });

                    total = total.toFixed(5);
                    //total = total.replace(".", ",");

                    angular.forEach(grid.columns, function (col) {
                        if (col.field === 'adjusted_quantity_in_work_units') {
                            col.footerTemplate = '<div style=\"text-align: right; font-size: 12px;\">' + total + '</div>';
                        }
                    });


                    // calling setOptions to force rerender.
                    grid.setOptions({
                        columns: grid.columns
                    });
                }
            }

            /**
             *
             * 
             * @param {ValueSetterParams} event
             */
            function onDataChangedAgGrid(event) {
                let var_quantity = 0.00000;
                let var_time_adjust_factor = 0.00;

                if (event.column.colId === 'quantity') {
                    var_quantity = event.newValue;
                    var_time_adjust_factor = event.data.time_adjust_factor;
                }
                if (event.column.colId === 'time_adjust_factor') {
                    var_quantity = event.data.quantity;
                    var_time_adjust_factor = event.newValue;
                }

                if (event.data.hasOwnProperty('quantity') || event.data.hasOwnProperty('time_adjust_factor')) {
                    event.data.quantity_multiplied_by_std_time = (var_quantity * event.data.std_time)// .toFixed(4);
                    event.data.quantity_in_work_units = (var_quantity * event.data.std_time_in_work_units)//.toFixed(4);
                    event.data.adjusted_quantity_in_work_units = (event.data.quantity_in_work_units * var_time_adjust_factor) //.toFixed(5);

                    vm.grid.gridfunc.updateRow({ ...event.data, _dirty: true });

                    vm.grid.gridfunc.refreshAggregates();
                }
            }

            var optionfunc = function (data) {
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            vm.rememberFunc = function (value) {
                workService.remember(vm.model.rememberId, value);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                //
            };

            // #endregion ANGULAR FUNCTIONS

            // ## LOAD PROCEDURE CALLS ##

            workService.loadWorkGet(vm.model.prodId, vm.model.workKeyno).then(function (data) {
                vm.model.edit = angular.copy(data[0]);

                loadFactories();
                loadOperations();
                loadUnits();
                loadSelectWorkGroups();
                loadSelectEquipents();

                if (utilityService.validateParmsValue(vm.model.edit.view_id) === true) {
                    vm.grid.dataTask.rememberId = vm.model.edit.view_id;
                }

                if (utilityService.validateParmsValue(vm.model.edit.p2_datatask_keyno) === true) {
                    vm.grid.dataTask.loadData = {
                        method: vm.model.edit.p2_datatask_keyno,
                        parameters: vm.model.edit
                    };
                }

                vm.model.gridReady = true;
            });

            workService.listLinkTypes().then(function (data) {
                angular.copy(data, vm.model.selectListLinkTypes);
            });

            let loadOperations = function () {
                if (utilityService.validateParmsValue(vm.model.edit.factory_id) !== true) return;

                return logisticService.listOperations({
                    direct: '1',
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListOperations);
                });
            };

            var loadUnits = function () {
                workService.loadUnitList('1', '1').then(function (data) {
                    angular.copy(data, vm.model.selectListUnits);
                });
            };

            var loadFactories = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                vm.model.selectListFactories = [];

                workGrpService.listFactories(vm.model.edit.businessco_no).then(function (data) {
                    angular.copy(data, vm.model.selectListFactories);

                    if (vm.model.edit.work_keyno === '0') { 

                        workService.getRemember(vm.model.rememberId).then(function (value) {
                            if (angular.isDefined(value) && angular.isDefined(value[0]) && angular.isDefined(value[0].variablevalue) && value[0].variablevalue !== null && value[0].variablevalue !== '') {
                                vm.model.edit.factory_id = angular.fromJson(value[0].variablevalue);

                                loadSelectWorkGroups();
                                loadSelectEquipents();
                                loadOperations();
                            }
                        });

                    }
                });
            };

            let loadSelectWorkGroups = function () {
                if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.factory_id) !== true) return;

                return equipmentEditService.listSelectWorkGroups({
                    workgrp_keyno: vm.model.edit.workgrp_keyno,
                    operation_id: vm.model.edit.operation_id,
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListWorkGroups);
                });
            };

            let loadSelectEquipents = function () {
                if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.factory_id) !== true) return;

                return operationFactoryService.listSelectEquipments({
                    workgrp_keyno: vm.model.edit.workgrp_keyno,
                    operation_id: vm.model.edit.operation_id,
                    factory_id: vm.model.edit.factory_id
                }).then(function (result) {
                    angular.copy(result, vm.model.selectListEquipments);
                });
            };

            var loadOperation = function () {
                if (angular.isUndefined(vm.model.edit.operation_id) === true) return;
                if (vm.model.edit.operation_id === null) return;
                if (vm.model.edit.operation_id.trim().length < 1) return;

                workService.loadOperationGet(vm.model.edit.operation_id).then(function (data) {
                    vm.model.edit.unit_no = data[0].unit_no;
                    vm.model.edit.adjustment_unit_name = data[0].adjustment_unit_name;
                });
            };

            var loadOperationChanged = function () {
                if (utilityService.validateParmsValue(vm.model.edit.factory_id, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.edit.operation_id, true) !== true) return;

                workService.changeOperation({
                    factory_id: vm.model.edit.factory_id,
                    operation_id: vm.model.edit.operation_id
                }).then(function (response) {
                    vm.model.edit.workgrp_keyno = response[0].workgrp_keyno;
                    vm.model.edit.equipment_id = response[0].equipment_id;
                });
            };

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;
                var mergeObj = vm.model.edit;

                mergeObj.records = vm.grid.gridfunc.getAllRows().map(function (a) {
                    return {
                        "suboperation_keyno": a.suboperation_keyno,
                        "quantity": a.quantity,
                        "quantity_in_work_units": a.quantity_in_work_units,
                        "time_adjust_factor": a.time_adjust_factor,
                        "adjusted_quantity_in_work_units": a.adjusted_quantity_in_work_units
                    };
                });

                workService.saveObj(mergeObj).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    workService.deleteItem({ work_keyno: vm.model.edit.work_keyno }).then(function (response) {
                        if (response[0].errorcode !== '0') {
                            modalService.show({
                                type: 'warning',
                                title: translations.error,
                                message: response[0].errormessage,
                                buttons: [{
                                    label: translations.ok,
                                    cssClass: 'btn-warning',
                                    action: function (dialogItself) {
                                        dialogItself.close();
                                        vm.model.lockedDelete = false;
                                    }
                                }]
                            });
                        } else {
                            vm.model.lockedDelete = false;
                            stateService.back();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'factory_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.factory_id) !== true) return;

                        vm.model.edit.factory_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.factory_id) !== true) return;

                        vm.rememberFunc(vm.model.edit.factory_id);

                        loadSelectWorkGroups();
                        loadSelectEquipents();
                        loadOperations();
                        break;
                    case 'operation_id':
                        if (utilityService.validateWatchValue(value, vm.model.edit.operation_id) !== true) return;

                        vm.model.edit.operation_id = value;

                        if (utilityService.validateParmsValue(vm.model.edit.operation_id) !== true) return;

                        loadOperation();
                        loadSelectWorkGroups();
                        loadSelectEquipents();
                        loadOperationChanged();
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'workgrp_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.edit.workgrp_keyno) !== true) return;

                        vm.model.edit.workgrp_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.edit.workgrp_keyno) !== true) return;

                        loadSelectEquipents();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

        }]
    });
})();
