(function() {
    'use strict';

	angular.module("imApp").factory("productionPoService", ['p2DataTaskService', "$ihttp", function (p2DataTaskService, $ihttp) {
		var service = {
			getSettings: function () {
				return p2DataTaskService.call(2092, {});
			},
			loadProductionPoList: function (setting) {
				return p2DataTaskService.call(2093, setting);

			},
			getReportDefn: function (fname) {
				return p2DataTaskService.call(1624, { report_filename: fname });
			},
			setDefaultPrinter: function (printer) {
				return p2DataTaskService.call(616, {
					variablename: 'productionpo.default_printer',
					variablevalue: printer
				});
			},
			loadPostStatusSelectList: function (add_all) {
				return p2DataTaskService.call(1348, {
					add_all: add_all
				});
			},
			loadOperationsSelectList: function (parms) {
				return $ihttp.post({
					method: 2112,
					parameters: parms || {}  // add_all
				});
			},
			remember: function (variablename, variablevalue) {
				return p2DataTaskService.call(616, {
					variablename: variablename,
					variablevalue: variablevalue
				});
			},
			setPohKeynoTops: function (commastr) {
				return p2DataTaskService.call(616, {
					variablename: 'productionpo.poh_keyno_tops',
					variablevalue: commastr
				});
			},
			beforePrint: function (parms) {
				return p2DataTaskService.call(  2109,parms);
			},
			setOperationStatus: function (postatus_no_new) {
				return p2DataTaskService.call(  2110, {    // 1573
					postatus_no_new: postatus_no_new
				});
			},

			getDefaultPrinter: function () {
				return p2DataTaskService.call(973, { variablename: 'productionpo.default_printer' });
			},
			getLastStatus: function () {
				return p2DataTaskService.call(973, { variablename: 'productionpo.status_no' });
			}
		};

        return service;
    }]);
})();
