(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('emailtemplate', {
        templateUrl: 'views/components/views/emailtemplate/emailtemplate.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'emailTemplateService', function ($stateParams, stateService, modalService, translateService, emailTemplateService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let emailTemplateKeyno = $stateParams.emailtemplate_keyno;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                edit: {},
                selectListWebPages: [],
                readyEmailBody: true,
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            emailTemplateService.getEmailTemplate({ emailtemplate_keyno: emailTemplateKeyno }).then(function (result) {
                angular.copy(result[0], vm.model.edit);
            });

            emailTemplateService.listWebPages().then(function (result) {
                angular.copy(result, vm.model.selectListWebPages);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                emailTemplateService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                emailTemplateService.deleteItem(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedDelete = false;
                        stateService.back();
                    }
                });
            };

		    // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL
        }]
    });
})();
