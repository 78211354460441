(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('textileModel', {
        templateUrl: 'views/components/views/textileModel/textileModel.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$rootScope', '$q', '$stateParams', 'rememberService', 'textileModelService', 'ieScreenBlockService', function ($rootScope, $q, $stateParams, rememberService, textileModelService, ieScreenBlockService) {
            var vm = this;
            vm.version = module.version;

            vm.textileModelId = $stateParams.textilemodel_id;

            vm.textileModel = {};
            vm.productCategory = [];
            vm.productComposition = [];
            vm.textileModelCategory3 = [];
            vm.textileModelCategory4 = [];
            vm.textileModelCategory5 = [];
            vm.textileModelCategory6 = [];
            vm.textileModelCategory7 = [];
            vm.textileModelCategory8 = [];
            vm.thermalclo = [];
            vm.thermaltemp = [];
            vm.prodgrp3 = [];
            vm.countries = [];
            vm.translations = [];
            vm.freightrates = [];
            vm.customsrates = [];

            vm.showPrices = false;
            vm.showSizes = false;

            vm.lockedSave = false;

            var optionsRememberId = 'textileModel.options';

            vm.heading = vm.textileModelId;

            vm.selectedButton = 'modelinfo';

            vm.radioButtons = [
                { id: 'modelinfo', label: 'tt_modelinfo', color: 'primary', onClick: function () { } },
                { id: 'prices', label: 'tt_prices', color: 'primary', onClick: function () { } },
                { id: 'sizes', label: 'tt_sizes', color: 'primary', onClick: function () { } },
                { id: 'translations', label: 'tt_translations', color: 'primary', onClick: function () { } }
            ];

            vm.ttPricesGrid = {
                dataTask: {
                    rememberId: 'textilemodel.ttPricesGrid',
                    loadData: {
                        method: 2263,
                        parameters: function () {
                            return { prodprice_id: vm.textileModelId };
                        }
                    },
                    addRow: {
                        method: null,
                        parameters: null,
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                    },
                    saveData: {
                        method: null,
                        parameters: {},
                    }
                },
                config: {
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: []
                    },
                    //serverSideHandling: true,                         // If this property is set to boolean true default values will be used for property names.
                    //serverSideHandling: {                               // Enables serverside sorting, paging and filtering.
                    //    enabled: true,                                  // When using server side handling loaddata must return a json object with 2 properties
                    //    data: 'data',                                   // Name of first property on the json object. This contains the row data. Default value is "items"
                    //    total: 'total'                                  // Name of second property. This contains total number of rows after filtering.
                    //},                           
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    onDataChanged: function () { },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: [],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: true
                },
                optionfunc: function (parms) { },
                gridfunc: null
            };

            vm.ttSizesGrid = {
                dataTask: {
                    rememberId: 'textilemodel.ttSizesGrid',
                    loadData: {
                        method: 2271,
                        parameters: function () {
                            return { textilemodel_id: vm.textileModelId };
                        }
                    },
                    addRow: {
                        method: null,
                        parameters: null,
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                    },
                    saveData: {
                        method: null,
                        parameters: {},
                    }
                },
                config: {
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    onDataChanged: function () { },
                    onDataSourceChanges: function (e) { }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: [],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: true
                },
                optionfunc: function (parms) { },
                gridfunc: null
            };

            var rememberOptions = function () {
                rememberService.remember(optionsRememberId, angular.toJson({
                    selectedButton: vm.selectedButton
                }))
            };

            var load = function () {
                var deferred = $q.defer();

                textileModelService.load(vm.textileModelId).then(function (loadResponse) {
                    //console.dir(loadResponse);

                    angular.copy(loadResponse.textileModel, vm.textileModel);
                    angular.copy(loadResponse.productCategory, vm.productCategory);
                    angular.copy(loadResponse.productComposition, vm.productComposition);
                    angular.copy(loadResponse.textileModelCategory3, vm.textileModelCategory3);
                    angular.copy(loadResponse.textileModelCategory4, vm.textileModelCategory4);
                    angular.copy(loadResponse.textileModelCategory5, vm.textileModelCategory5);
                    angular.copy(loadResponse.textileModelCategory6, vm.textileModelCategory6);
                    angular.copy(loadResponse.textileModelCategory7, vm.textileModelCategory7);
                    angular.copy(loadResponse.textileModelCategory8, vm.textileModelCategory8);
                    angular.copy(loadResponse.thermalclo, vm.thermalclo);
                    angular.copy(loadResponse.thermaltemp, vm.thermaltemp);
                    angular.copy(loadResponse.prodgrp3, vm.prodgrp3);
                    angular.copy(loadResponse.countries, vm.countries);
                    angular.copy(loadResponse.translations, vm.translations);
                    angular.copy(loadResponse.freightrates, vm.freightrates);
                    angular.copy(loadResponse.customsrates, vm.customsrates);

                    vm.heading = vm.textileModel.textileModelNameLang;

                    deferred.resolve();
                });

                return deferred.promise;
            };

            vm.onRadioButtonsChanged = function (value) {
                vm.selectedButton = value;

                // BJS 20220426 - Workaround to make headers appear correct, need to use ng-if when first showing grid.
                switch (value) {
                    case 'prices':
                        vm.showPrices = true;
                        break;
                    case 'sizes':
                        vm.showSizes = true;
                        break;
                }

                rememberOptions();
            };

            vm.typeaheadSearchProdGrp3 = function (value, type) {
                var items = [];

                angular.forEach(vm.prodgrp3, function (pg3) {
                    if (pg3 !== undefined && pg3 !== null && pg3[type] !== undefined && pg3[type] !== null && pg3[type].includes(value)) {
                        items.push(pg3);
                    }
                });

                return $q.resolve(items);
            };

            vm.typeaheadSerchProdGrp3Selected = function (item) {
                vm.textileModel.prodGrp3Id = item.key;
                vm.textileModel.prodGrp3Name = item.name;
            };

            vm.textileModelProdGrp3Change = function (value) {
                if (value !== '') return;

                vm.textileModel.prodGrp3Id = '';
                vm.textileModel.prodGrp3Name = '';
            };

            vm.textileModelProdGrp3Clear = function () {
                vm.textileModel.prodGrp3Id = '';
                vm.textileModel.prodGrp3Name = '';
            };

            vm.saveChanges = function () {
                ieScreenBlockService.start();

                textileModelService.save(vm.textileModel, vm.translations).then(function () {
                    load().then(function () {
                        ieScreenBlockService.stop();

                        // Need to run adjust on next digest cycle
                        $timeout(function () { $rootScope.$broadcast('elastic:adjust') });
                    });
                });
            };

            vm.onPricatClicked = function () {
                ieScreenBlockService.start();

                textileModelService.pricat(vm.textileModelId).then(function () {
                    ieScreenBlockService.stop();
                });
            }

            rememberService.getLastStatus(optionsRememberId).then(function (savedState) {
                if (angular.isUndefined(savedState)) return;
                if (angular.isArray(savedState) !== true) return;
                if (savedState.length < 1) return;
                if (angular.isUndefined(savedState[0].variablevalue)) return;

                var lastStatus = angular.fromJson(savedState[0].variablevalue);

                if (angular.isUndefined(lastStatus)) return;
                if (lastStatus === null) return;
                if (angular.isUndefined(lastStatus.selectedButton)) return;

                vm.onRadioButtonsChanged(lastStatus.selectedButton);
            });

            load();
        }]
    });
})();
