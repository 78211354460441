(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('poFinishCompList', {
        templateUrl: 'views/components/views/poFinishCompList/poFinishCompList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'poFinishCompListService', function ($stateParams, stateService, utilityService, modalService, poFinishCompListService) {

            // #region VARIABLES & DEFINITIONS

            // filter_pofinishcomp_scan-variabelen lagt inn under model-variabelen av Oystein 04.09.2018.
            // Button group-variabelen lagt inn under vm.model-variabelen av Oystein 05.09.2018.
            // p2PoFinishProdCompList-variabelen lagt inn under vm.model-variabelen av Oystein 05.09.2018.
            // Også namneendring av variabelnamn (p2PoFinishProdCompList) av Oystein 05.09.2018.

            var vm = this;
            
            vm.model = {
                bgPofinishCompSelection: {
                    selected: '0',
                    buttons: [
                        { id: '0', label: 'componentpick_not_picked', color: 'primary', onClick: () => vm.model.bgPofinishCompSelection.selected = '0' },
                        { id: '1', label: 'componentpick_picked', color: 'primary', onClick: () => vm.model.bgPofinishCompSelection.selected = '1' },
                        { id: 'A', label: 'componentpick_all', color: 'primary', onClick: () => vm.model.bgPofinishCompSelection.selected = 'A' }
                    ]
                },
                barcodeButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.setting.barcode = '' },
                    { id: 'calculator', icon: 'fa fa-calculator', color: 'primary', type: 'primary', onClick: () => openCalculator('barcode') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => vm.searchPoFinishCompScan() }
                ],
                setting: {
                    barcode: ''
                },
                p2PoFinishProdCompList: {
                    records: []
                },
                lockedFinish: false
            };
            
            vm.p2_pofinishprod_status = poFinishCompListService.p2_pofinishprod_status;
            
            // #endregion VARIABLES & DEFINITIONS

            // ###############
            // PROCEDURE CALLS
            // ###############
            
            poFinishCompListService.loadPoFinishCompListGet($stateParams.p2_pofinishprod_keyno).then(function (data) {
                vm.model.setting = angular.copy(data[0]);

                loadFinishedComponents();
            });

            poFinishCompListService.loadP2PoFinishProdStatus($stateParams.p2_pofinishprod_keyno).then(function () {
                vm.p2_pofinishprod_status = poFinishCompListService.p2_pofinishprod_status;

                if (vm.p2_pofinishprod_status.finishlocked === '0') {
                    vm.p2_pofinishprod_status.locked = '1';
                }
            });

            var loadFinishedComponents = function () {
                if (utilityService.validateParmsValue(vm.model.setting.p2_pofinishprod_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.bgPofinishCompSelection.selected) !== true) return;

                poFinishCompListService.loadP2PoFinishProdCompList(vm.model.setting.p2_pofinishprod_keyno, vm.model.bgPofinishCompSelection.selected).then(function () {
                    vm.model.p2PoFinishProdCompList = poFinishCompListService.p2PoFinishProdCompList;
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgPofinishCompSelection = function (value) {
                vm.model.bgPofinishCompSelection.selected = value;

                loadFinishedComponents();
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ###########################
            // SCAN FIELD & CLEAR FUNCTION // clearField-funksjonen og state.loading-funksjonen vart lagt globalt av Oystein 04.09.2018.
            // ###########################
            
            vm.searchPoFinishCompScan = function () {
                if (utilityService.validateParmsValue(vm.model.setting.barcode, true) !== true) return;

                poFinishCompListService.searchP2PoFinishProdComp('p2_pofinishprod_keyno', $stateParams.p2_pofinishprod_keyno, vm.model.setting.barcode).then(function () {
                    clearField();
                });

                clearField();
            };

            // #region OPEN CALCULATOR DIALOGUE BOX FUNCTION

            let openCalculator = function (id) {
                modalService.showCalculator(vm.model.setting[id]).then(function (value) {
                    vm.model.setting[id] = value;

                    vm.searchPoFinishCompScan();
                });
            };

            // #endregion OPEN CALCULATOR DIALOGUE BOX FUNCTION

            var clearField = function () {
                if (vm.model.setting.barcode > '') {
                    vm.model.setting.barcode = '';
                    
                    loadFinishedComponents();

                    poFinishCompListService.loadP2PoFinishProdStatus($stateParams.p2_pofinishprod_keyno).then(function () {
                        vm.p2_pofinishprod_status = poFinishCompListService.p2_pofinishprod_status;

                        if (vm.p2_pofinishprod_status.finishlocked === '0') {
                            vm.p2_pofinishprod_status.locked = '1';
                        }
                    });
                }
            };

            // ## SEARCH SUGGESTION FUNCTION PROCEDURE CALLS ##

            vm.typeaheadSearch = function (value) {
                return poFinishCompListService.search(vm.model.setting.searchtext, 'stock', vm.model.setting.stockplace_id);
            };

            // 20231011 KLN - upgrade iminput with search to ttsearch
            vm.onSearchTextCleared = function () {
                vm.model.setting.searchtext = '';
            };

            vm.onSearchTextSelected = function (item) {
                //changeStockKeyno(item.stock_keyno);
                //vm.model.setting.searchtext = '';
                // do nothing, handled in onchanged
            };

            vm.onSearchTextChanged = function (value) {
                vm.model.setting.searchtext = value;

                if (!value) {
                    vm.onSearchTextCleared();
                } else {
                    let searchItem = poFinishCompListService.selectStockList.find((item) => item.item_name === vm.model.setting.searchtext);

                    if (searchItem) {
                        //vm.onSearchTextSelected(searchItem);
                        changeStockKeyno(searchItem.stock_keyno);
                        vm.model.setting.searchtext = '';
                    }
                }
            };

            var changeStockKeyno = function (stock_keyno) {
                vm.model.setting.stock_keyno = stock_keyno;
                var scanValue = 'st' + vm.model.setting.stock_keyno;
                
                poFinishCompListService.searchP2PoFinishProdComp('p2_pofinishprod_keyno', vm.model.setting.p2_pofinishprod_keyno, scanValue).then(function () {
                    clearField();
                    loadFinishedComponents();
                });
            };
            
            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.finishChanges = function () {
                vm.model.lockedFinish = true;

                poFinishCompListService.finishProductionOrder({
                    finishfromkey: 'p2_pofinishprod_keyno',
                    keyvalue: $stateParams.p2_pofinishprod_keyno,
                    from_webpagename: stateService.getCurrentName(),
                    from_parms: $stateParams.p2_pofinishprod_keyno
                }).then(function (response) {
                    vm.model.lockedFinish = false;
                    stateService.back('/' + response[0].webpagename + '/' + response[0].webpage_parms);
                }, function () {
                    vm.model.lockedFinish = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

        }]
    });
})();
