(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('acReminderProposal', {
        templateUrl: 'views/components/views/acReminderProposal/acReminderProposal.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$timeout', 'base64', 'stateService', 'utilityService', 'modalService', 'translateService', 'acReminderProposalService', 'logisticService', 'rememberService', 'emailCreateService', 'graphAuthService', 'graphMailService', function ($q, $timeout, base64, stateService, utilityService, modalService, translateService, acReminderProposalService, logisticService, rememberService, emailCreateService, graphAuthService, graphMailService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;

            let variableNames = {
                gb_acreminderproposal_main: ''
            };

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                bgAcReminderProposalMain: {
                    selected: 'ACCUSTTRANS',
                    buttons: [
                        { id: 'ACCUSTTRANS', label: 'gb_acreminderproposal_main_accusttrans', color: 'primary', onClick: () => setAcReminderProposalMainView('ACCUSTTRANS') },
                        { id: 'ACREMINDER', label: 'gb_acreminderproposal_main_acreminder', color: 'primary', onClick: () => setAcReminderProposalMainView('ACREMINDER') }
                    ]
                },
                setting: {},
                selectListCompanies: [],
                gridReady: false,
                gridAcReminderReady: false,
                updating: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runProgress: ''
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_acreminderproposal_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridAcReminder = {
                dataTask: {
                    rememberId: 'w_acreminderproposal_acreminder_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        refresh: false,
                        read: false,
                        rebind: false,
                        buttons: [
                            {
                                name: 'acreminderproposal_approve_selected',
                                text: 'acreminderproposal_approve_selected',
                                func: function () {
                                    progressFunc();
                                },
                                icon: 'fa-sync-alt',
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                translate: true,
                                //disabled: function () { return; }
                                //disabled: function () {
                                //	if (vm.model.setting.show === '1') {
                                //		return false;
                                //	} else {
                                //		return 'hidden';
                                //	}
                                //}
                            },
                            {
                                name: 'acreminderproposal_send_to_selected',
                                text: 'acreminderproposal_send_to_selected',
                                translate: true,
                                cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r',
                                icon: 'fa-reply-all',
                                func: sendReminderToSelectedRows,
                                disabled: sendReminderToSelectedDisabled,
                                //disabled: () => { return 'hidden' }
                            }
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                    },
                kendo: {
                    height: '100%',
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            function checkToolbarButtonsVisibility() {
                vm.gridAcReminder?.gridfunc?.refreshToolbarBtnDisability();
                vm.grid?.gridfunc?.refreshToolbarBtnDisability();
            }

            // #endregion VARIABLES & DEFINITIONS

            async function sendReminderToSelectedDisabled() {
                try {
                    await graphAuthService.getAccount();
                } catch (error) {
                    console.dir(error);
                    return 'hidden';
                }

                if (getSelectedRows()?.length > 0) {
                    return false;
                }

                return true;
            }


            function sendReminderToSelectedRows() {
                let progress = {
                    runProgress: 'START',
                    label: 'progressbar',
                    field: '',
                    max: getSelectedRows()?.length ?? 0,
                    min: 0,
                    step: 0,
                    finishclose: '1',
                    abortclose: false,
                }

                modalService.progressInfo.max = getSelectedRows()?.length;
            
                modalService.showProgress(progress).then(function (value) {
                    progress.runProgress = value;

                    if (progress.runProgress === 'ABORT') {
                        console.log('ABORT');
                    } else if (progress.runProgress === 'FINISH') {
                        loadGridAcReminder();
                    }
                });

                getSelectedRows()?.forEach(async (row) => {
                    let email = await getEmailContent(row.acreminder_keyno);
                    
                    email.reports = [];
                    email.documents = [];
                    email.send_by_client = '1'; 

                    await sendEmail(email);
                    modalService.progressInfo.step++;
                    progress.step++;
                })
            }

            function getSelectedRows() {
                return vm.gridAcReminder?.gridfunc?.getDirtyRows().filter((dirtyRow) => dirtyRow.is_selected === true) ?? [];
            }

            async function getEmailContent(acReminderKeyno) {
                return (await emailCreateService.getEmailCreate({ webpage: 'acreminderproposal', argtype: 'acreminder_keyno', argvalue: acReminderKeyno }))[0];
            }

            async function sendEmail(ttEmail) {
                try {
                    ttEmail.send_by_client = '1';
                    ttEmail.email_heading = base64.urlencode(ttEmail.email_heading); 
                    ttEmail.email_signature = base64.urlencode(ttEmail.email_signature); 
                    const data = await emailCreateService.sendEmailOld(ttEmail);

                    if (data?.mailinfo && data.mailinfo.length > 0) {
                        data.mailinfo.forEach((mailinfo) => {
                            graphMailService.sendMessage({
                                subject: base64.urldecode(mailinfo.subject),
                                content: base64.urldecode(mailinfo.bodyHtml),
                                recipients: mailinfo.to,
                                ccRecipients: mailinfo.cc,
                                bccRecipients: mailinfo.bcc,
                                attachments: mailinfo.attachments,
                                userId: mailinfo.from
                            });
                        })
                    }
                } catch (error) {

                }

            }

            // #region BUTTON GROUP SINGLE FUNCTION

            let setAcReminderProposalMainView = function (id) {
                vm.model.bgAcReminderProposalMain.selected = vm.model.setting.gb_acreminderproposal_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();

                acReminderProposalService.getSetting().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setAcReminderProposalMainView(vm.model.setting.gb_acreminderproposal_main);

                    $q.all([
                        loadGrid(),
                        loadGridAcReminder()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                return logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                vm.grid.dataTask.loadData = {
                    method: 3089,
                    parameters: { businessco_no: vm.model.setting.businessco_no }
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();
            };

            let loadGridAcReminder = function () {
                vm.gridAcReminder.dataTask.loadData = {
                    method: 3099,
                    parameters: { businessco_no: vm.model.setting.businessco_no }
                };

                vm.model.gridAcReminderReady = true;

                if (vm.gridAcReminder.gridfunc !== null) vm.gridAcReminder.gridfunc.rebind();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    switch (key) {
                        case 'gb_acreminderproposal_main':
                            variableNames[key] = key;
                            break;
                        default:
                            variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                            break;
                    }
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_acreminderproposal_main':
                        if (utilityService.validateParmsValue(vm.model.setting.gb_acreminderproposal_main) !== true) return;

                        variableValue = vm.model.setting.gb_acreminderproposal_main;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.updateChanges = function () {
                vm.model.updating = true;

                acReminderProposalService.updateGrid({ businessco_no: vm.model.setting.businessco_no }).then(function (response) {
                    if (response[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updating = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.updating = false;
                        loadGrid();
                        loadGridAcReminder();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].acreminder_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        var parms = {
                            acreminder_keyno: items[step].acreminder_keyno
                        };

                        acReminderProposalService.approveGridSelected(parms).then(function (response) {
                            if (response[0].errorcode !== '0') {
                                modalService.show({
                                    type: 'warning',
                                    title: translations.error,
                                    message: response[0].errormessage,
                                    buttons: [{
                                        label: translations.ok,
                                        cssClass: 'btn-warning',
                                        action: function (dialogItself) {
                                            dialogItself.close();

                                            modalService.progressInfo.finishclose = '1';
                                            vm.model.runProgress = 'ABORT';
                                            modalService.progressInfo.abortclose = true;

                                            doStep();
                                        }
                                    }]
                                });
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            var progressFunc = function () {
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                var selectedRows = [];
                var allRows = vm.gridAcReminder.gridfunc.getAllRows();

                angular.forEach(allRows, function (item) {
                    if (item.is_selected === '1' || item.is_selected === true) {
                        selectedRows.push(item);
                    }
                });

                if (selectedRows.length === 0) return;

                vm.model.progressInfo.max = selectedRows.length;
                vm.activateProgress(selectedRows);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        console.log('ABORT');
                    } else if (vm.model.runProgress === 'FINISH') {
                        loadGridAcReminder();
                    }
                });
            };

            // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgAcReminderProposalMain = function (value) {
                vm.model.bgAcReminderProposalMain.selected = value;
                vm.model.setting.gb_acreminderproposal_main = vm.model.bgAcReminderProposalMain.selected;

                rememberFunc('gb_acreminderproposal_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        loadGrid();
                        loadGridAcReminder();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
                document.querySelector('mgt-login').addEventListener('loginCompleted', checkToolbarButtonsVisibility);
            };

            vm.$onDestroy = function () {
                document.querySelector('mgt-login').removeEventListener('loginCompleted', checkToolbarButtonsVisibility);
            }

            // #endregion ON INIT FUNCTION
        }]
    });
})();
