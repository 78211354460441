(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('carriers', {
        templateUrl: 'views/components/views/carriers/carriers.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'carriersService', 'printService', 'translateService', function ($stateParams, stateService, modalService, carriersService, printService, translateService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                carrier_keyno: 0,
                p2_reportdef_keyno: 0,
                printer_keyno: 0,
                quantity: 1,
                carriers: [],
                printers: [],
                reports: [],
                lockedSave: false
            };

            var translations = {
                carrier_save_show_title: 'Varsel',
                carrier_save_show_ok_label: 'OK'
            };

            vm.printReports = function () {
                vm.model.lockedSave = true;
                carriersService.print(vm.model.carrier_keyno, vm.model.p2_reportdef_keyno, vm.model.printer_keyno, vm.model.quantity).then(function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            carriersService.getCarriers().then(function (data) { angular.copy(data, vm.model.carriers) });
            printService.getPrinters().then(function (data) { angular.copy(data.items, vm.model.printers) });
            printService.getReports().then(function (data) {
                angular.copy(data.items.filter(e => e.isActive === true).filter(e => e.reportId.includes('NorcoCarriersLabel')), vm.model.reports);
                console.dir(vm.model.reports);
            });

            // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();
