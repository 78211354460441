(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('productBulkEdit', {
        templateUrl: 'views/components/views/productBulkEdit/productBulkEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['productBulkEditService', function (productBulkEditService) {
            var vm = this;
            
            vm.model = {
                checked: false,
                searchInfo: {},
                editValues: {},
                categoryLinks: {},
                listButtons: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                bulkEditViewDefault: '0',
                bulkEditViewSelected: '0',
                bulkEditView: {
                    records: [
                        { item_id: '0', item_name: 'bulkEditSelect', item_func: 'BULK_EDIT_SELECT' },
                        { item_id: '1', item_name: 'bulkEditGoTo', item_func: 'BULK_EDIT_GOTO' }
                    ]
                },
                productBulkEditList: {
                    records: [],
                    recordsCopy: []
                },
                spin: false,
                spin2: false,
            };
            
            // ######################
            // ERASE BUTTON FUNCTIONS
            // ######################
            
            vm.eraseListItem = function (item_func) {
                vm.model.searchInfo.search_categorylink_keyno = '';
            };
            
            vm.eraseListItem2 = function (item_func) {
                vm.model.searchInfo.categorylink_keyno_new = '';
            };
            
            // ######################
            // PAGE BUTTON FUNCTIONS
            // ######################
            
            vm.checkToggle = function () {
                var atLeastOne = false;

                if (vm.model.checked === true) {
                    angular.forEach(vm.model.productBulkEditList.records, function (data) {
                        if (angular.isDefined(data) && angular.isDefined(data.item_is_selected)) {
                            if (atLeastOne === false && data.item_is_selected === '1') {
                                atLeastOne = true;
                            }

                            data.item_is_selected = '0';
                        }
                    });
                } else {
                    angular.forEach(vm.model.productBulkEditList.records, function (data) {
                        if (angular.isDefined(data) && angular.isDefined(data.item_is_selected)) {
                            if (atLeastOne === false && data.item_is_selected === '0') {
                                atLeastOne = true;
                            }

                            data.item_is_selected = '1';
                        }
                    });
                }

                vm.model.checked = !vm.model.checked;

                if (atLeastOne === false) {
                    vm.checkToggle();
                }
            };
            
            vm.productBulkEditRetrieve = function () {
                vm.model.spin = true;

                productBulkEditService.productBulkEditRetrieve(vm.model.searchInfo).then(function (data) {
                    angular.copy(data, vm.model.productBulkEditList.records);
                    angular.copy(data, vm.model.productBulkEditList.recordsCopy);

                    angular.forEach(vm.model.productBulkEditList.recordsCopy, function (data) {
                        if (angular.isDefined(data) && angular.isDefined(data.item_glyphicon) && data.item_glyphicon !== '') {
                            data.item_glyphicon = '';
                        }
                    });

                    vm.model.checked = true;

                    angular.forEach(vm.model.productBulkEditList.records, function (data) {
                        if (vm.model.checked === true && angular.isDefined(data) && angular.isDefined(data.item_is_selected) && data.item_is_selected === '0') {
                            vm.model.checked = false;
                        }

                        vm.model.spin = false;
                        vm.model.spin2 = false;
                    });
                });
            };
            
            vm.productBulkEditUpdate = function () {
                var itemsToUpdate = [];
                vm.model.spin2 = true;

                angular.forEach(vm.model.productBulkEditList.records, function (data) {
                    if (angular.isDefined(data) && angular.isDefined(data.item_is_selected) && data.item_is_selected === '1') {
                        itemsToUpdate.push(data);
                    }
                });

                productBulkEditService.productBulkEditUpdate(vm.model.searchInfo, itemsToUpdate).then(function (data) {
                    //angular.copy(data, vm.model.productBulkEditList.records);
                    vm.productBulkEditRetrieve();
                    //vm.model.spin2 = false;   //Alternative placement of when to end the spin
                });
            };
            
            // ######################
            // GROUP BUTTON FUNCTIONS
            // ######################
            
            vm.bulkEditView = function (item) {
                if (item === 'BULK_EDIT_SELECT') {
                    vm.model.bulkEditViewSelected = '0';
                } else {
                    vm.model.bulkEditViewSelected = '1';
                }
            };

            vm.whenReady = function () {
                productBulkEditService.loadEditValues().then(function () {
                    vm.model.editValues = productBulkEditService.editValues.records;
                });

                productBulkEditService.loadCategoryList().then(function () {
                    vm.model.categoryLinks = productBulkEditService.categoryLinks.records;
                });

                productBulkEditService.loadSearchInfo().then(function () {
                    vm.model.searchInfo = productBulkEditService.searchInfo;

                    if ((angular.isDefined(vm.model.searchInfo.search_text) && vm.model.searchInfo.search_text !== '') || (angular.isDefined(vm.model.searchInfo.search_categorylink_keyno) && vm.model.searchInfo.search_categorylink_keyno !== '')) {
                        vm.productBulkEditRetrieve();
                    }
                });
            };

            vm.whenReady();
        }]
    });
})();