(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('dataMiningList', {
        templateUrl: 'views/components/views/dataMiningList/dataMiningList.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['dataMiningListService', function (dataMiningListService) {
            const vm = this;
            
            vm.model = {
                itemsListDataMinings: []
            };
            
            let loadDataMinings = function () {
                dataMiningListService.listDataMinings().then(function (list) {
                    angular.copy(list, vm.model.itemsListDataMinings);
                });
            };
            
            vm.$onInit = function () {
                loadDataMinings();
            };
        }]
    });
})();
