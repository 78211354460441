(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceMakeMulti', {
        templateUrl: 'views/components/views/invoiceMakeMulti/invoiceMakeMulti.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'utilityService', 'invoiceMakeMultiService', 'rememberService', 'modalService', function ($stateParams, $timeout, $q, stateService, utilityService, invoiceMakeMultiService, rememberService, modalService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;

            let variableNames = {
                deliverydate_tom: '',
                invoice_date: ''
            };

            vm.model = {
                businessCoNo: $stateParams.businessco_no,
                invoiceMethodNo: $stateParams.invoicemethod_no,
                invoiceValutaId: $stateParams.invoice_valuta_id,
                bgInvoiceMakeMultiMain: {
                    selected: 'SELECT',
                    buttons: [
                        { id: 'SELECT', label: 'bg_invoicemakemulti_main_select', color: 'primary', onClick: () => vm.model.bgInvoiceMakeMultiMain.selected = 'SELECT' },
                        { id: 'VIEW', label: 'bg_invoicemakemulti_main_view', color: 'primary', onClick: () => vm.model.bgInvoiceMakeMultiMain.selected = 'VIEW' }
                    ]
                },
                setting: {},
                p2WInvoiceMakeMultiList: {
                    records: []
                },
                p2WInvoiceMakeMultiListEdited: {
                    records: []
                },
                lockedCreate: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            var loadP2WInvoiceMakeMultiSettings = function () {
                invoiceMakeMultiService.genericFunction(829, {
                    businessco_no: vm.model.businessCoNo,
                    invoicemethod_no: vm.model.invoiceMethodNo,
                    invoice_valuta_id: vm.model.invoiceValutaId
                }).then(function (data) {
                    vm.model.setting = angular.copy(data[0]);

                    loadP2WInvoiceMakeMultiList();
                });
            };
            
        	loadP2WInvoiceMakeMultiSettings();
            
            var loadP2WInvoiceMakeMultiList = function () {
                if (angular.isDefined(vm.model.setting.businessco_no) && vm.model.setting.businessco_no !== '' && angular.isDefined(vm.model.setting.deliverydate_tom) && vm.model.setting.deliverydate_tom !== '' && angular.isDefined(vm.model.setting.invoice_date) && vm.model.setting.invoice_date !== '') {
                    vm.model.p2WInvoiceMakeMultiList.records = [];

                    invoiceMakeMultiService.genericFunction(830, vm.model.setting).then(function (data) {
                        angular.copy(data, vm.model.p2WInvoiceMakeMultiList.records);
                    });
                }
            };

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'deliverydate_tom':
                    case 'invoice_date':
                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS
            
            function createInvoice(item) {
                var deferred = $q.defer();
                
                invoiceMakeMultiService.createInvoice({
                    invoicemethod_no: vm.model.setting.invoicemethod_no,
                    invoice_date: vm.model.setting.invoice_date,
                    businessco_no: vm.model.setting.businessco_no,
                    order_internal_no: item.order_internal_no,
                    item_id: item.item_id
                }).then(function (data) {
                    if (vm.model.progressInfo.step >= vm.model.progressInfo.max || vm.model.progress === 'ABORT') {
                        loadP2WInvoiceMakeMultiList();
                    }
                    
                    deferred.resolve(data);
                });
                
                return deferred.promise;
            }
            
            vm.createMultiInvoice = function (items) {
                var increment = 1, skip = 0, step = 0;
                vm.model.progressInfo.step = 0;
                
                var doStep = function () {
                    $timeout(function () {
                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max || vm.model.progress === 'ABORT') return;
                        
                        if (items[step].item_id === '0' || items[step].item_id === '-1') {
                            skip++;
                            step = vm.model.progressInfo.step + skip;
                            doStep();
                        } else {
                            step = vm.model.progressInfo.step + skip;
                            vm.model.progressInfo.field = items[step].item_name;
                            vm.model.progressInfo.step += increment;
                            
                            modalService.progressInfo.step = vm.model.progressInfo.step;
                            modalService.progressInfo.field = vm.model.progressInfo.field;
                            
                            createInvoice(items[step]).then(function (data) {
                                doStep();
                            });
                        }
                    });
                };
                
                doStep();
            };
            
            vm.createInvoices = function () {
                vm.model.lockedCreate = true;
                vm.model.progress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.p2WInvoiceMakeMultiListEdited.records = [];
                
                angular.forEach(vm.model.p2WInvoiceMakeMultiList.records, function (item) {
                    if ( item.item_is_selected === '1') {
                        vm.model.p2WInvoiceMakeMultiListEdited.records.push(item);
                    }
                });
                
                angular.forEach(vm.model.p2WInvoiceMakeMultiListEdited.records, function (item) {
                    if ((item.order_internal_no !== '0' || item.order_internal_no !== '-1') && item.item_is_selected === '1') {
                        vm.model.progressInfo.max++;
                    }
                });
                
                vm.createMultiInvoice(vm.model.p2WInvoiceMakeMultiListEdited.records);
                
                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.progress = value;
                    
                    if (vm.model.progress === 'ABORT' || vm.model.progress === 'FINISH') {
                        vm.model.lockedCreate = false;
                    }
                });
            };
            
            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgInvoiceMakeMultiMain = function (value) {
                vm.model.bgInvoiceMakeMultiMain.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // ## TOGGLE FUNCTIONS ##
            
            vm.toggleChosen = function (item) {
                item = angular.isDefined(item.item) ? item.item : item;
                
                if (item.item_id === '0' || item.item_id === '-1') return;
                
                if (item.item_is_selected === '0') {
                    item.item_is_selected = '1';
                } else if (item.item_is_selected === '1') {
                    item.item_is_selected = '0';
                }
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'deliverydate_tom':
                        if (utilityService.validateWatchValue(value, vm.model.setting.deliverydate_tom) !== true) return;

                        vm.model.setting.deliverydate_tom = value;

                        if (utilityService.validateParmsValue(vm.model.setting.deliverydate_tom) !== true) return;

                        rememberFunc('deliverydate_tom');
                        loadP2WInvoiceMakeMultiList();
                        break;
                    case 'invoice_date':
                        if (utilityService.validateWatchValue(value, vm.model.setting.invoice_date) !== true) return;

                        vm.model.setting.invoice_date = value;

                        if (utilityService.validateParmsValue(vm.model.setting.invoice_date) !== true) return;

                        rememberFunc('invoice_date');
                        loadP2WInvoiceMakeMultiList();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
