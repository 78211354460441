(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('subscriptionUsergroup', {
        templateUrl: 'views/components/views/subscriptionUsergroup/subscriptionUsergroup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'subscriptionUsergroupService', function ($stateParams, subscriptionUsergroupService) {
            
            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListSubscriptionUserGroups: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSubscriptionUserGroups = function () {
                subscriptionUsergroupService.listSubscriptionUserGroups({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (list) {
                    angular.copy(list, vm.model.itemsListSubscriptionUserGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSubscriptionUserGroups();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
