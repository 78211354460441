import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ComponentBaseComponent } from '../../component-base/component-base.component';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { TranslateService } from '@app/core/services/translate.service';
import { LayoutService } from '@app/core/services/layout.service';
import { Style } from '@app/core/services/core-component.service';

@Component({
    selector: 'tt-page-heading',
    templateUrl: './page-heading.component.html',
    styleUrls: ['./page-heading.component.css'],
})
export class PageHeadingComponent extends ComponentBaseComponent implements OnChanges {
    /**
     * The value to display in the heading. Either a text value or a translatable word. By default this value is not translated.
     */
    @Input()
    ttModel: string = '';
    _model = '';

    /**
     * Whether to translate the `ttModel` value or not.
     */
    @Input()
    set ttTranslate(value: BooleanInput) {
        this._translate = coerceBooleanProperty(value);
    }
    get ttTranslate(): boolean {
        return this._translate;
    }
    private _translate: boolean = false;

    loading: boolean = false;

    style: Style = {
        heading: {},
        skeleton: {},
    };

    constructor(private translateService: TranslateService, private layoutService: LayoutService) {
        super();
        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['heading'].fontSize = info.fontSizes.textSizeXXL;
                this.style['skeleton'].height = info.fontSizes.textSizeXXL;
            }
        });
    }

    async setHeading() {
        if (this.ttTranslate) {
            this.loading = true;

            this._model = await this.translateService.translate(this.ttModel);

            this.loading = false;
        } else {
            this._model = this.ttModel;
        }
    }

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes['ttTranslate']) {
            this.ttTranslate = changes['ttTranslate'].currentValue;
        }

        if (changes['ttModel'] || changes['ttTranslate']) {
            this.setHeading();
        }
    }
}
