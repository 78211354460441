(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('labourPositions', {
        templateUrl: 'views/components/views/labourPositions/labourPositions.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'labourPositionsService', 'labourPositionService', 'equipmentEditService', 'rememberService', function ($stateParams, $q, stateService, utilityService, labourPositionsService, labourPositionService, equipmentEditService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            let variableNames = {
                factory_id: '',
                workgrp_keyno: ''
            };

            let resetWorkGroup = function () {
                vm.model.setting.workgrp_keyno = '0';

                rememberFunc('workgrp_keyno');

                if (vm.model.bgLabourPositionsMain.selected === 'LABOURS') {
                    vm.grid.gridfunc.rebind();
                } else {
                    vm.gridActivePo.gridfunc.rebind();
                }
            };

            vm.model = {
                bgLabourPositionsMain: {
                    selected: 'LABOURS',
                    buttons: [
                        { id: 'LABOURS', label: 'gb_labourpositions_main_labours', color: 'primary', onClick: function () { vm.model.bgLabourPositionsMain.selected = 'LABOURS'; } },
                        { id: 'POS', label: 'gb_labourpositions_main_pos', color: 'primary', onClick: function () { vm.model.bgLabourPositionsMain.selected = 'POS'; } }
                    ]
                },
                workGroupButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: { click: resetWorkGroup } }
                ],
                setting: {},
                selectListFactories: [],
                selectListWorkGroups: [],
                gridReady: false,
                gridActivePoReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            vm.gridActivePo = {
                dataTask: {
                    rememberId: 'w_labourpositions_active_pojob_grid',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        //{ key: 'test_column' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    //navigation: {
                    //	altNav: true,
                    //	newLine: true
                    //},
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    },
                    onDataChanged: function (changed, e) {
                        //console.log('onDataChanged');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false, //'multiple, row'
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: null,
                gridfunc: null
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                let deferred = $q.defer();
                
                labourPositionsService.getLabourPositions({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    vm.grid.dataTask.rememberId = 'w_labourpositions_grid';

                    $q.all([
                        loadSelectWorkGroups()
                    ]).then(() => {
                        vm.grid.dataTask.loadData = {
                            method: 2562,
                            parameters: vm.model.setting
                        };

                        vm.gridActivePo.dataTask.loadData = {
                            method: 2890,
                            parameters: vm.model.setting
                        };

                        vm.model.gridReady = true;
                        vm.model.gridActivePoReady = true;

                        deferred.resolve();
                    });
                });

                return deferred.promise;
            };

            let loadSelectFactories = function () {
                labourPositionService.listSelectFactories().then(function (result) {
                    angular.copy(result, vm.model.selectListFactories);
                });
            };

            let loadSelectWorkGroups = function () {
                if (utilityService.validateParmsValue(vm.model.setting.factory_id, true) !== true) return;

                return equipmentEditService.listSelectWorkGroups({ factory_id: vm.model.setting.factory_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListWorkGroups);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'factory_id':
                        if (utilityService.validateParmsValue(vm.model.setting.factory_id, true) !== true) return;

                        variableValue = vm.model.setting.factory_id;
                        break;
                    case 'workgrp_keyno':
                        if (utilityService.validateParmsValue(vm.model.setting.workgrp_keyno, true) !== true) return;

                        variableValue = vm.model.setting.workgrp_keyno;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgLabourPositionsMain = function (value) {
                vm.model.bgLabourPositionsMain.selected = value;
            };

		    // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                switch (id) {
                    case 'factory_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.factory_id) !== true) return;

                        vm.model.setting.factory_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.factory_id, true) !== true) return;

                        rememberFunc('factory_id');
                        loadSelectWorkGroups();

                        if (vm.model.bgLabourPositionsMain.selected === 'LABOURS') {
                            vm.grid.gridfunc.rebind();
                        } else {
                            vm.gridActivePo.gridfunc.rebind();
                        }
                        break;
                    case 'workgrp_keyno':
                        if (utilityService.validateWatchValue(value, vm.model.setting.workgrp_keyno) !== true) return;

                        vm.model.setting.workgrp_keyno = value;

                        if (utilityService.validateParmsValue(vm.model.setting.workgrp_keyno, true) !== true) return;
                        
                        rememberFunc('workgrp_keyno');

                        if (vm.model.bgLabourPositionsMain.selected === 'LABOURS') {
                            vm.grid.gridfunc.rebind();
                        } else {
                            vm.gridActivePo.gridfunc.rebind();
                        }
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'labourposition':
                        stateService.go(state, {
                            labourposition_keyno: '0',
                            argtype: vm.model.setting.argtype,
                            argvalue: vm.model.setting.argvalue
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadSelectFactories();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
