(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("priceListPriceService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 661,
                    parameters: parms || {}
                });
            },
            listPriceTypes: function (parms) {
                return $ihttp.post({
                    method: 664,
                    parameters: parms || {}
                });
            },
            listValueTypes: function (parms) {
                return $ihttp.post({
                    method: 662,
                    parameters: parms || {}
                });
            },
            listPriceListFactors: function (parms) {
                return $ihttp.post({
                    method: 669,
                    parameters: parms || {}
                });
            },
            listSelectPurchaseLines: function (parms) {
                return $ihttp.post({
                    method: 733,
                    parameters: parms || {}
                });
            },
            listPriceRelatedToPriceLists: function (parms) {
                return $ihttp.post({
                    method: 663,
                    parameters: parms || {}
                });
            },
            recalculateItems: function (list) {
                return $ihttp.post({
                    method: 665,
                    parameters: list
                });
            },
            approveProductPrices: function (list) {
                return $ihttp.post({
                    method: 734,
                    parameters: list
                });
            }
        };

        return service;
    }]);
})();
