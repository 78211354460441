(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('stockTransMenu', {
        templateUrl: 'views/components/views/stockTransMenu/stockTransMenu.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'stockTransMenuService', function($stateParams, stateService, utilityService, stockTransMenuService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            vm.model = {
                stockTransKeyNo: $stateParams.stocktrans_keyno,
                menuStockTrans: {},
                itemsListAllocations: []
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            stockTransMenuService.loadStockTransMenuGet(vm.model.stockTransKeyNo).then(function (data) {
                angular.copy(data[0], vm.model.menuStockTrans);
    
                loadStockTransMenuAllocs();
            });
    
            var loadStockTransMenuAllocs = function () {
                if (utilityService.validateParmsValue(vm.model.menuStockTrans.stocktrans_keyno) !== true) return;
    
                vm.model.itemsListAllocations = [];
    
                stockTransMenuService.loadStockTransMenuAllocList(vm.model.menuStockTrans.stocktrans_keyno).then(function (data) {
                    angular.copy(data, vm.model.itemsListAllocations);
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'requisition':
                        go({
                            requisition_no: vm.model.menuStockTrans.requisition_no
                        });
                        break;
                    case 'orderhead':
                        go({
                            order_internal_no: vm.model.menuStockTrans.order_internal_no
                        });
                        break;
                    case 'purchhead':
                        go({
                            purch_internal_no: vm.model.menuStockTrans.purch_internal_no
                        });
                        break;
                    case 'stockget':
                        go({
                            stock_keyno: vm.model.menuStockTrans.stock_keyno
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();