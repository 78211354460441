(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('consignmentLinesBs', {
        templateUrl: 'views/components/views/consignmentLinesBs/consignmentLinesBs.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'consignmentLinesBsService', function ($stateParams, consignmentLinesBsService) {
            const vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListPicked: []
            };
            
            let loadPicked = function () {
                consignmentLinesBsService.listPicked({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListPicked);
                });
            };
            
            vm.$onInit = function () {
                loadPicked();
            };
        }]
    });
})();
