<div role="presentation" class="ag-cell-editor ag-labeled ag-label-align-left ag-date-field ag-input-field" ref="eInput">
    <div ref="eLabel" class="ag-input-field-label ag-label ag-hidden ag-date-field-label" aria-hidden="true" role="presentation" id="ag-526-label"></div>
    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-date-field-input-wrapper ag-picker-field-wrapper" role="presentation">
        <input
            #inputRef
            matInput
            [matDatepicker]="picker"
            class="ag-input-field-input ag-date-field-input"
            type="text"
            tabindex="0"
            step="any"
            [placeholder]="placeholder"
            aria-label="Input Editor"
            [ngModel]="value"
            (ngModelChange)="onDateChanged($event)"
        />
        <mat-datepicker-toggle matIconSuffix [for]="picker" [style.fontSize]="'var(--ag-font-size)'" [style.height]="'100%'">
            <span matDatepickerToggleIcon class="far fa-calendar" [style.fontSize]="'var(--ag-font-size)'"></span>
        </mat-datepicker-toggle>
        <mat-datepicker #picker [panelClass]="'ag-custom-component-popup'">
            <!-- <mat-datepicker-actions>
                <div style="width: 100%; display: flex; justify-content: space-between">
                    <button  mat-button matDatepickerCancel (click)="restoreFocus = true">Lukk</button>
                    <div style="display: flex; gap: 0.4rem">
                        <button [ngStyle]="{ fontSize: style['date'].fontSize }" mat-button (click)="picker.select(today); picker.close()">I dag</button>
                        <button [ngStyle]="{ fontSize: style['date'].fontSize }" mat-raised-button color="primary" matDatepickerApply>Velg</button>
                    </div>
                </div>
            </mat-datepicker-actions> -->
        </mat-datepicker>
    </div>
</div>
