(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("currencyTypesService", ['$ihttp', function ($ihttp) {
        let service = {
            getCurrencyType: function (parms) {
                return $ihttp.post({
                    method: 1018,
                    parameters: parms || {}
                });
            },
            listCurrencyTypes: function (parms) {
                return $ihttp.post({
                    method: 1019,
                    parameters: parms || {}
                });
            }
        };

        return service;
    }]);
})();
