(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('batchDelReceiveLines', {
        templateUrl: 'views/components/views/batchDelReceiveLines/batchDelReceiveLines.template.html?v=' + module.version,
		controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'utilityService', 'stateService', 'rememberService', 'batchDelReceiveLinesService', function ($stateParams, $timeout, utilityService, stateService, rememberService, batchDelReceiveLinesService) {

            // ## VARIABLES & DEFINITIONS ##

            var vm = this;
            var timerSearchText = null;

            vm.model = {
                batchDeliveryKeyNo: $stateParams.batchdelivery_keyno,
                purchInternalNo: $stateParams.purch_internal_no,
                purchLineKeyNo: $stateParams.purchline_keyno,
                prodSizeColliKeyNo: $stateParams.prodsizecolli_keyno,
                stockLocationKeyNo: $stateParams.stocklocation_keyno,
                controlled: $stateParams.controlled,
                placed: $stateParams.placed,
                searchButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', onClick: () => vm.onSearchTextChange('') }
                ],
                settingReceiveLines: {},
                itemsListReceiveLines: []
            };

            // ## LOAD PROCEDURE CALLS ##

            batchDelReceiveLinesService.loadBatchDelReceiveLinesGet(vm.model.batchDeliveryKeyNo, vm.model.purchInternalNo, vm.model.purchLineKeyNo, vm.model.prodSizeColliKeyNo, vm.model.stockLocationKeyNo, vm.model.controlled, vm.model.placed).then(function (data) {
                vm.model.settingReceiveLines = angular.copy(data[0]);

                loadBatchDelReceiveLines();
            });

            var loadBatchDelReceiveLines = function () {
                if (utilityService.validateParmsValue(vm.model.settingReceiveLines.batchdelivery_keyno) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingReceiveLines.searchtext, true) !== true) return;

                vm.model.itemsListReceiveLines = [];

                batchDelReceiveLinesService.loadBatchDelReceiveLinesList(vm.model.settingReceiveLines).then(function (data) {
                    angular.copy(data, vm.model.itemsListReceiveLines);
                });
            };

            // ## REMEMBER VALUE FUNCTIONS ##

            var rememberSearchText = function () {
                var variableNameSearchText = 'w_batchdelreceivelines.searchtext';

                if (utilityService.validateParmsValue(variableNameSearchText) !== true) return;
                if (utilityService.validateParmsValue(vm.model.settingReceiveLines.searchtext, true) !== true) return;

                if (angular.isDefined(timerSearchText)) {
                    $timeout.cancel(timerSearchText);
                }

                if (vm.model.settingReceiveLines.searchtext !== '') {
                    timerSearchText = $timeout(function () {
                        rememberService.remember(variableNameSearchText, vm.model.settingReceiveLines.searchtext);
                    }, 5000);
                } else {
                    rememberService.remember(variableNameSearchText, vm.model.settingReceiveLines.searchtext);
                }
            };

            // ## BUTTON LIST FUNCTIONS ##

            vm.searchTextBtnListFunc = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.settingReceiveLines.searchtext = '';
                        break;
                }
            };

            // ## DIRECTION BUTTON FUNCTIONS ##

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'deviations':
                        go({
                            argtype: 'batchdelivery_keyno',
                            argvalue: vm.model.settingReceiveLines.batchdelivery_keyno
                        });
                        break;
                }
            };

            vm.onSearchTextChange = function (value) {
                vm.model.settingReceiveLines.searchtext = value;
                rememberSearchText();
            }
		}]
	});
})();