<!-- <div class="bootstrap-dialog">
    <div class="modal-content col-xs-12 sp-0" style="max-height: 95vh; height: -webkit-fill-available; display: flex; flex-direction: column">
        <div class="modal-header col-xs-12" style="position: sticky; left: 0; top: 0; z-index: 231232; background-color: var(--tt-modal--color)">
            <div class="bootstrap-dialog-header">
                <h3 *ngIf="!!model.get" style="padding: 0; margin: 0; display: inline-block">{{ model.get.heading }}</h3>
                <div class="bootstrap-dialog-close-button" style="display: block">
                    <button class="close" ng-click="vm.dismiss()" ng-style="{ fontSize: vm.fontSize }">×</button>
                </div>
            </div>
        </div> -->
<h2 mat-dialog-title class="tt-secondary-background" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary=".cdk-overlay-container" cdkDragHandle style="color: white">
    {{ model.get?.heading || "" }}
</h2>

<mat-dialog-content>
    <!-- <div class="modal-body col-xs-12" style="overflow: auto; height: -webkit-fill-available; padding-top: 0"> -->
    <div class="col-xs-12 sp-0" style="position: sticky; top: 0; left: 0; z-index: 12333; background-color: var(--tt-modal--color); padding-top: 15px">
        <div class="col-xs-8 sp-8">
            <!-- <tt-search
                *ngIf="!!model.voucher"
                class="col-xs-6 sp-2"
                ttLabel="project_keyno"
                ttLabelView="top"
                [(ttModel)]="model.voucher.project_name"
                [ttSearch]="searchProject"
                (ttOnSelected)="onProjectSelected($event)"
                (ttOnClear)="onProjectSelected()"
            ></tt-search>
            <tt-select
                *ngIf="!!model.voucher"
                class="col-xs-6 sp-2"
                ttLabel="department_id"
                ttLabelView="top"
                [(ttModel)]="model.voucher.department_id"
                [ttData]="model.departments"
                ttDataId="department_id"
                ttDataName="department_name"
            ></tt-select> -->
            <div *ngIf="!!model.voucher" class="col-xs-12 sp-0" style="display: flex; align-items: flex-end; gap: 5px">
                <tt-datetime class="col-xs-2 sp-0" ttLabel="voucherdate" [(ttModel)]="model.voucher.voucherdate" (ttModelChange)="onVoucherDateChanged($event)" ttHideTime></tt-datetime>
                <tt-datetime class="col-xs-2 sp-0" ttLabel="transdate" [(ttModel)]="model.voucher.transdate" ttHideTime></tt-datetime>
                <tt-button class="col-xs-3 sp-0" ttText="update_dates_to_rows" (ttClick)="overrideDatesToRows()"></tt-button>
            </div>
            <tt-textarea *ngIf="!!model.voucher" class="col-xs-12 sp-2" ttLabel="note" ttLabelView="top" [(ttModel)]="this.model.voucher.note"></tt-textarea>
        </div>
        <tt-file-uploader class="col-xs-4 sp-8" ttLabel="attachment" ttLabelView="top" ttDropZone [(ttFiles)]="model.files" ttMultiple></tt-file-uploader>
        <hr class="k-hr" />
        <div class="col-xs-11 sp-0" style="display: flex; align-items: flex-end">
            <tt-input *ngIf="!!model.voucher" class="col-xs-1 sp-2" ttLabel="accountingprocessing_sum_dbcr" ttTextAlign="right" [ttModel]="model.voucher.amount" ttReadonly></tt-input>
            <tt-input *ngIf="!!model.voucher" class="col-xs-1 sp-2" ttLabel="amount_vat" ttTextAlign="right" [ttModel]="model.voucher.amount_vat" ttReadonly></tt-input>
        </div>
    </div>
    <tt-grid class="col-xs-12 sp-0" *ngIf="!!grid" [ttOptions]="grid" (ttRowDataChange)="onRowChanged($event)" (ttReady)="onGridReady()"></tt-grid>
    <!-- </div> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
    <tt-button class="col-xs-2 sp-2 pull-right" ttText="create_acvoucher" ttType="success" (ttClick)="process('create_acvoucher')"></tt-button>
    <tt-button class="col-xs-2 sp-2 pull-right" [ttText]="model.get?.label_bookkeep || ''" [ttTranslateText]="false" ttType="primary" (ttClick)="process('bookkeep')"></tt-button>
</mat-dialog-actions>
<!-- <div class="modal-footer col-xs-12" style="display: block; position: sticky; bottom: 0; right: 0; z-index: 12">
        <tt-button class="col-xs-2 sp-2 pull-right" tt-text="accountingprocessing_bookkeep" tt-type="primary" tt-click="vm.process('bookkeep')"></tt-button>
        <tt-button class="col-xs-2 sp-2 pull-right" tt-text="create_acvoucher" tt-type="success" tt-click="vm.process('create_acvoucher')"></tt-button>
    </div>
</div>
</div> -->
