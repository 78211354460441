(function() {
    'use strict';

    angular.module("imApp").factory("textileModelService", ['$q', '$ihttp', 'base64', function ($q, $ihttp, base64) {
        var service = {
            load: function (textileModelId) {
                return $ihttp.post({
                    method: 2262,
                    parameters: {
                        textilemodel_id: textileModelId
                    }
                });
            },
            save: function (textileModel, translations) {
                return $ihttp.post({
                    method: 2267,
                    parameters: {
                        textilemodel: base64.urlencode(angular.toJson(textileModel)),
                        translations: base64.urlencode(angular.toJson(translations))
                    }
                });
            },
            pricat: function (textileModelId) {
                return $ihttp.post({
                    method: 2275,
                    parameters: {
                        textilemodel_id: textileModelId
                    }
                });
            }
        }

        return service;
    }]);
})();
