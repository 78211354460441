(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectProdsize', {
        templateUrl: 'views/components/views/selectProdsize/selectProdsize.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectProdsizeService', 'searchService', function ($stateParams, selectProdsizeService, searchService) {
            var vm = this;
            
            vm.filter_search_prodsize = {
                prodsize_name: ''
            };
            
            vm.state = {
                loading: false
            };
            
            vm.searchProdsize = function () {
                vm.state.loading = true;

                selectProdsizeService.searchP2ProductProdsizeList(vm.filter_search_prodsize.prodsize_name, $stateParams.webpage_name, $stateParams.parm1, $stateParams.parm2, $stateParams.prod_id).then(function () {
                    vm.p2_product_prodsize_list = selectProdsizeService.p2_product_prodsize_list;
                    vm.state.loading = false;
                });
            };
            
            vm.searchProdsize();
            
            vm.preOrderLineEdit = function (item) {
                searchService.prodsize.selected = true;
                searchService.prodsize.selectedProdsizeKeyno = item.item_id;
            };
        }]
    });
})();