import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BislabService {
    /** The base url for api requests to bislab. */
    private _baseUrl = `https://api.bislab.no`;

    /** Represents configuration for the bislab api. */
    private _apiConfig = {
        lang: 'no',
        version: 'v1',
    };

    constructor() { }

    /**
     * Gets credit rating score about the company with the given organizational id.
     * 
     * @param orgNo the organizational id for the company to perform credit rating on.
     * @returns promise containing the credit rating data, if request failed a regular js response is returned.
     * @throws {Error| BislabEndpointError}
     */
    public async getCompanyCreditRatingScore({ orgNo, token, test }: { orgNo: string; token: string; test: boolean }): Promise<BislabCompanyScoreResponse | Response> {
        try {
            const endpoint = this._buildEndpoint({ type: 'company', id: orgNo });

            return await this._sendApiRequest({ endpoint: endpoint, token: token, test: test ?? false });
        } catch (error) {
            throw error;
        }
    }

    /**
     * Gets credit rating score and additional information about the company with the given organizational id.
     * 
     * @param orgNo the organizational id for the company to get credit rating and info on.
     * @returns promise containing the credit rating data, if request failed a regular js response is returned.
     * @throws {Error| BislabEndpointError}
     */
    public async getCompanyCreditRatingScoreInfo({ orgNo, token, test }: { orgNo: string; token: string; test?: boolean }): Promise<BislabCompanyScoreInfoResponse | Response> {
        try {
            const endpoint = this._buildEndpoint({ type: 'companyinfo', id: orgNo });

            return await this._sendApiRequest({ endpoint: endpoint, token: token, test: test ?? false });
        } catch (error) {
            throw error;
        }
    }

    /**
     * Gets credit rating score for the person with the given national identification number.
     * 
     * @param id the national identification number for the person to get credit rating score for.
     * @returns promise containing the credit rating data, if request failed a regular js response is returned.
     * @throws {Error| BislabEndpointError}
     */
    public async getPersonCreditRatingScore(id: string, test?: boolean): Promise<BislabScoreResponse | Response> {
        try {
            const endpoint = this._buildEndpoint({ type: 'person', id: id });

            return await this._sendApiRequest({ endpoint: endpoint, test: test ?? false });
        } catch (error) {
            throw error;
        }
    }

    /**
     * Sends a test request to bislab verifying the token and connection.
     * 
     * @param token the token to test.
     * @returns mock data from the test request.
     * @throws {Error | BislabEndpointError}
     */
    public async testBearerToken(token: string): Promise<Response> {
        try {
            const endpoint = this._buildEndpoint({ type: 'test' });

            return await this._sendApiRequest({ endpoint: endpoint, token: token, test: true });
        } catch (error) {
            throw error;
        }
    }

    /**
     * Sends an api request to bislab with the given api request configurations.
     * 
     * @param config the api request configurations for the bislap request.
     * @returns a promise with the bislab response, or a js Response if the request did not return ok.
     * @throws {Error} if an error with the api request occured.
     */
    private async _sendApiRequest(config: BislabApiRequest) {
        console.log('sending api request');
        try {
            console.dir(config);
           
            const request: RequestInit = {
                method: config?.method ?? 'GET',
                headers: {
                    'Accept': 'text/plain',
                    'Authorization': `Bearer ${config.token}`
                },
            };

            const response = await fetch(`${this._baseUrl}${config.endpoint}${config?.test === true ? '?test=true' : ''}`, request);

            if (response.ok && config?.test !== true) {
                return response.json();
            } else {
                return response;
            }
        } catch (error) {
            throw error;
        }
    }

    /**
     * Builds endpoint for bislab request based on the given configuration.
     * 
     * @param config the configuration for the endpoint.
     * @returns the endpoint based on the configuration.
     * @throws {BislabEndpointError} if invalid configurations for endpoint is given.
     */
    private _buildEndpoint(config: BislabCreditRatingRequest) {
        if (config.type !== 'test' && !config?.id) throw new BislabEndpointError('Missing parameter');

        switch (config.type) {
            case 'test':
                return `/business/${this._apiConfig.lang}/${this._apiConfig.version}/company/999572542/score`;
            case 'company':
                return `/business/${this._apiConfig.lang}/${this._apiConfig.version}/company/${config.id}/score`;
            case 'companyinfo':
                return `/business/${this._apiConfig.lang}/${this._apiConfig.version}/company/${config.id}/score/info`;
            case 'person':
                return `/consumer/${this._apiConfig.lang}/${this._apiConfig.version}/person/${config.id}/score`;
            default:
                throw new BislabEndpointError('Invalid endpoint');
        }
    }
}

/** Represents parameters for a bislab credit rating request.  */
interface BislabCreditRatingRequest {
    /** The type of credit rating request, id is required for company and person. */
    type: 'company' | 'companyinfo' | 'person' | 'test';

    /** The id of the entity to perform credit rating request for, required if type is `company` or `person` */
    id?: string;
}

/** Represents configuration for bislab api requests. */
interface BislabApiRequest {
    /** The endpoint to send a request to. */
    endpoint: string;

    /** The method for the request. */
    method?: 'GET' | 'POST' | 'PUT';

    /** (Optional) token to run request with, this will override the default token, used for testing. */
    token?: string;

    /** Whether the request should be a test request. */
    test?: boolean;
}

/** Represents the credit score response from bislab api. */
interface BislabScoreResponse {
    /** Credit score for the company. Scale 1-100. Where 100 is best. */
    score: number;

    /** Creditrating grade where AAA is best and D is a bankrupt company. IR is insufficient information.  */
    scoreKarakter: 'AAA' | 'AA' | 'A' | 'B' | 'C' | 'D' | 'IR';

    /** Explanation for the credit rating. */
    scoreForklaring: string;

    /** Suggested maximum credit limit. */
    kredittgrense: string;
}

/** Represents the credit score response for a company from bislab api. */
interface BislabCompanyScoreResponse extends BislabScoreResponse {
    /** The organizational number of the company that was retrieved information on. */
    organisasjonsnummer: string;
}

/** Represents the credit score response for a company from bislab api. */
interface BislabCompanyScoreInfoResponse extends BislabScoreResponse {
    /** Legal company name. */
    foretaksnavn: string;

    /** Organizational form/legal structure. */
    organisasjonsform: string;

    /** Number of employees. */
    antallAnsatte: number;

    /** Business activity code description. */
    naeringskode: string;

    /** Date of incorporation. */
    stiftelsesdato: string;

    /** Business address municipality. */
    forretningsadresseKommune: string;

    /** Business address postal code. */
    forretningsadressePostnummer: string;

    /** Business street address. */
    forretningsadresseAdresse: string;

    /** Name of managing director. */
    dagligLederNavn: string;

    /** Fiscal year for latest updated financial reporting figures. */
    regnskapsaar: number;

    /** Operating revenues. */
    driftsinntekter: number;

    /** Operating expenses. */
    driftskostnader: number;

    /** Operating income/loss. */
    driftsresultat: number;

    /** Total assets. */
    sumEiendeler: number;

    /** Total liabilities. */
    sumGjeld: number;

    /** Total equity. */
    sumEgenkapital: number;
}

/** Represents an error with the endpoint for the bislab api. */
class BislabEndpointError extends Error {
    constructor(message: string) {
        super(message);
    }
}
