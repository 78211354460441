(function () {
	'use strict';

	var imApp = angular.module('imApp');

	imApp.component('myPage', {
        templateUrl: 'views/components/views/myPage/myPage.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['myPageService', function (myPageService) {
            var vm = this;
            
    		vm.model = {
    			itemsListWebPageMenu: []
    		};
            
    		myPageService.loadWebPageMenuList('mypage').then(function (data) {
    			angular.copy(data, vm.model.itemsListWebPageMenu);
    		});
    	}]
    });
})();