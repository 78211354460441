(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('operations', {
        templateUrl: 'views/components/views/operations/operations.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'operationsService', 'logisticService', 'rememberService', function ($q, stateService, utilityService, operationsService, logisticService, rememberService) {
            const vm = this;
            
            let variableNames = {
                show_only_active: ''
            };

            vm.model = {
                bgOperationsSelection: {
                    selected: '1',
                    buttons: [
                        { id: '1', label: 'operation_direct', color: 'primary', onClick: function () { vm.model.bgOperationsSelection.selected = '1'; } },
                        { id: '0', label: 'operation_indirect', color: 'primary', onClick: function () { vm.model.bgOperationsSelection.selected = '0'; } }
                    ]
                },
                setting: {},
                itemsListOperations: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();

                operationsService.getOperations().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadOperations()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadOperations = function () {
                if (utilityService.validateParmsValue(vm.model.setting.show_only_active, true) !== true) return;

                vm.model.itemsListOperations = [];

                return logisticService.listOperations({
                    direct: vm.model.bgOperationsSelection.selected,
                    show_only_active: vm.model.setting.show_only_active
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListOperations);
                });
            };

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'show_only_active':
                        if (utilityService.validateParmsValue(vm.model.setting.show_only_active, true) !== true) return;

                        variableValue = vm.model.setting.show_only_active;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };

            vm.selectBgOperationsSelection = function (item) {
                vm.model.bgOperationsSelection.selected = item;

                loadOperations();
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'operation':
                        go({ operation_id: '0' });
                        break;
                    default:
                        break;
                }
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'show_only_active':
                        if (utilityService.validateWatchValue(value, vm.model.setting.show_only_active) !== true) return;

                        vm.model.setting.show_only_active = value;

                        if (utilityService.validateParmsValue(vm.model.setting.show_only_active, true) !== true) return;

                        rememberFunc('show_only_active');
                        loadOperations();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
