(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('priceListBusCo', {
        templateUrl: 'views/components/views/priceListBusCo/priceListBusCo.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'priceListBusCoService', function ($stateParams, stateService, priceListBusCoService) {
            var vm = this;
            
            vm.model = {
                p2PriceListBusCoGet: {}
            };
            
            vm.loadP2PriceListBusCoGet = function () {
                priceListBusCoService.genericFunction(667, {
                    pricelist_id: $stateParams.pricelist_id,
                    businessco_no: $stateParams.businessco_no
                }).then(function (data) {
                    vm.model.p2PriceListBusCoGet = angular.copy(data[0]);
                });
            };
            
            vm.loadP2PriceListBusCoGet();
            
            vm.saveP2PriceListBusCoGet = function () {
                priceListBusCoService.genericFunction(668, vm.model.p2PriceListBusCoGet).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();