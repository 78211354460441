(function () {
    'use strict';

    let module = angular.module('imApp');

    module.factory("modalLayoutsFactory", ['$q', '$uibModal', function ($q, $uibModal) {
        function modalLayouts(options) {
            options = options || {};

            this.model = {
                datatask_keyno: options.datatask_keyno || '',
                placeholder: options.placeholder || '',
                placeholderError: options.placeholderError || '',
                placeholderNoLayout: options.placeholderNoLayout || '',
                messageHasDuplicate: options.messageHasDuplicate || '',
                //currentLayout: options.currentLayout || {},
                currentLayoutData: options.currentLayoutData || {},
                activeLayout: options.activeLayout || null,
                list: options.list || []
            };

            this.instance = null;
        };

        modalLayouts.prototype.show = function () {
            var deferred = $q.defer();

            var vm = this;

            this.instance = $uibModal.open({
                backdrop: true,
                animation: true,
                keyboard: false,
                templateUrl: 'views/modal/layoutsModal.html?v=' + module.version,
                controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                    $scope.model = vm.model;
                    $scope.model.field = '';
                    //console.log($scope.model.activeLayout);
                    $scope.model.hasCurrentLayout = false;
                    //$scope.model.hasCurrentLayoutIndex = null;

                    //if ($scope.model.activeLayout !== null)

                    for (var l = 0; l < $scope.model.list.length; l++) {
                        if ($scope.model.list[l].isCurrent === true) {
                            $scope.model.hasCurrentLayout = true;
                            break;
                        }
                    }

                    //for (var l = 0; l < $scope.model.list.length; l++) {
                    //    if ($scope.model.list[l].layout_schema === $scope.model.currentLayoutData) {
                    //        $scope.model.hasCurrentLayout = true;
                    //        $scope.model.hasCurrentLayoutIndex = l;
                    //        break;
                    //    }
                    //}

                    var unactivate = function () {
                        for (var i = 0; i < $scope.model.list.length; i++) {
                            if ($scope.model.list[i].isActive) {
                                $scope.model.list[i].isActive = false;
                                break;
                            }
                        }
                    };

                    $scope.close = function () {
                        $uibModalInstance.close();

                        deferred.resolve(vm.model);
                    };

                    $scope.addToLayouts = function () {
                        var nameIsUnique = true;

                        for (var f = 0; f < $scope.model.list.length; f++) {
                            if ($scope.model.list[f].gridlayout_name === $scope.model.field) {
                                nameIsUnique = false;
                                break;
                            }
                        }

                        if ($scope.model.field.length > 0 && nameIsUnique === true) {
                            $scope.model.list.unshift({
                                gridlayout_keyno: '',
                                p2_datatask_keyno: $scope.model.datatask_keyno,
                                gridlayout_name: $scope.model.field,
                                layout_schema: $scope.model.currentLayoutData,
                                orderby: 0,
                                willGetDeleted: false,
                                isActive: false,
                                isCurrent: false,
                                hasUpdates: false
                            });

                            //$scope.model.activeLayout = $scope.model.list[0];
                            $scope.model.hasCurrentLayout = true;
                            //$scope.model.hasCurrentLayoutIndex = 0;

                            //$scope.close();
                            $scope.setActive(null, $scope.model.list[0]);
                        } else {
                            console.log('Name is not unique or too small.');
                        }
                    };

                    $scope.toggleDeleteFromLayouts = function (e, item) {
                        e.stopPropagation();
                        item.willGetDeleted = !item.willGetDeleted;
                    };

                    $scope.setActive = function (e, item) {
                        unactivate();
                        item.isActive = true;
                        $scope.model.activeLayout = item;

                        $scope.close();
                    };

                    $scope.updateActive = function (e, item, index) {
                        e.stopPropagation();

                        item.gridlayout_name = $scope.model.field.length > 0 ? $scope.model.field : item.gridlayout_name;
                        item.layout_schema = $scope.model.currentLayoutData;
                        item.hasUpdates = true;

                        $scope.model.hasCurrentLayout = true;
                        //$scope.model.hasCurrentLayoutIndex = index;
                        $scope.model.field = '';

                        //$scope.close();


                        $scope.setActive(e, item);
                    };

                    $scope.$on('modal.closing', function (event, reason) {
                        switch (reason) {
                            case "backdrop click":
                            case "escape key press":
                            case "cancel":
                                event.preventDefault();
                                $scope.close();
                                break;
                        }
                    });
                }]
            });

            return deferred.promise;
        };

        modalLayouts.prototype.hide = function () {
            if (angular.isUndefined(this.instance)) return;
            if (this.instance === null) return;

            this.instance.close();

            this.instance = null;
        };

        modalLayouts.$create = function (options) {
            return new modalLayouts(options);
        };

        return modalLayouts;
    }]);
})();
