(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('priceLists', {
        templateUrl: 'views/components/views/priceLists/priceLists.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'priceListsService', function (stateService, priceListsService) {
            var vm = this;
            
            vm.model = {
                p2PriceListList: {
                    records: []
                }
            };
            
            var loadP2PriceListList = function () {
                priceListsService.genericFunction(627, {}).then(function (data) {
                    angular.copy(data, vm.model.p2PriceListList.records);
                });
            };
            
        	loadP2PriceListList();
            
            vm.createNewPriceList = function () {
                stateService.go('pricelist', {
                    pricelist_id: '0'
                });
            };
        }]
    });
})();