(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('prodGrps', {
        templateUrl: 'views/components/views/prodGrps/prodGrps.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'prodGrpsService', function (stateService, prodGrpsService) {
            
    		// #region VARIABLES & DEFINITIONS

            var vm = this;

            vm.model = {
                btnListSearchText: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                setting: {},
                itemsListProductGroups: []
            };
            
    		// #endregion VARIABLES & DEFINITIONS
            
            // #region LOAD PROCEDURE FUNCTION CALLS
            
            prodGrpsService.getProductGroupSetting().then(function (result) {
                angular.copy(result[0], vm.model.setting);
            });

            prodGrpsService.listProductGroups().then(function (result) {
                angular.copy(result, vm.model.itemsListProductGroups);
            });

    		// #endregion LOAD PROCEDURE FUNCTION CALLS
            
            // #region BUTTON LIST SINGLE FUNCTION
            
            vm.btnListFuncSearchText = function (item_func) {
                switch (item_func) {
                    case 'erase_item':
                        vm.model.setting.searchtext = '';
                        break;
                    default:
                        break;
                }
            };
            
    		// #endregion BUTTON LIST SINGLE FUNCTION

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state) {
                switch (state) {
                    case 'prodgrp':
                        stateService.go(state, {
                            prodgrp_id: '+'
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS
        }]
    });
})();