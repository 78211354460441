import { Injectable } from '@angular/core';
import { DataServiceBase } from '../framework/data-service-base';
import { DataTaskService } from "../services/data-task.service";

interface ResponseItem {
    variablevalue: string;
    [key: string]: any; // Other properties if needed
}

@Injectable({
    providedIn: 'root',
})
export class RememberService extends DataServiceBase {
    constructor(protected _dataTaskService: DataTaskService) { super(); }

    public async remember(variableName: string, variableValue: any): Promise<void> {
        const value = this.convertToJsonIfObject(variableValue);

        await this._dataTaskService.Post(616, { variablename: variableName, variablevalue: value });
    }

    public async getLastStatus(variablename: string, isObject: boolean = false) {
        const response = await this._dataTaskService.Post(973, { variablename: variablename });

        return isObject ? this.getResponseObject(response) : response;
    }

    private getResponseObject(response: ResponseItem[]): any | null {
        if (!this.isArray(response)) return null;
        if (response.length < 1) return null;
        if (response[0].variablevalue === undefined) return null;
        if (typeof response[0].variablevalue !== 'string') return null;
        if (response[0].variablevalue.length < 1) return null;

        try {
            return JSON.parse(response[0].variablevalue);
        } catch (error) {
            console.error('Error parsing JSON:', error);
            return null;
        }
    }
}
