(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("stockCountRefreshService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2WStockCountRefreshGet: function (stockcount_keyno) {
                return p2DataTaskService.call(982, {
					stockcount_keyno: stockcount_keyno
				});
            },
            refreshP2WStockCountMenuStockCount: function (stockcount_keyno) {
                return p2DataTaskService.call(981, {
                    stockcount_keyno: stockcount_keyno
                });
            }
        };

        return service;
    }]);
})();