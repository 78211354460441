(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('productionPo', {
        templateUrl: 'views/components/views/productionPo/productionPo.template.html?v=' + module.version,
        controllerAs: 'vm',
		controller: ['$stateParams', '$timeout', '$q', 'stateService', 'modalService', 'ieScreenBlockService', 'productionPoService', 'dbPrinterService', 'printPreviewService', 'printService', 
		function ($stateParams, $timeout, $q, stateService, modalService, ieScreenBlockService, productionPoService, dbPrinterService, printPreviewService, printService) {
            var vm = this;
			var reportDefinitions = {};

			vm.model = {
				argType: $stateParams.argtype,
				argValue: $stateParams.argvalue,
				checkedCount: 0,
				printer: '',
				isolated_kit_keyno: '',
				revert: false,
				selectedItems: '',
				progress: '',
				progressInfo: {
					label: '',
					plabel: '',
					field: '',
					remField: '',
					min: 0,
					max: 100,
					step: 0,
					finishclose: ''
				},
				printers: [],
				selectListPoStatus: [],
				selectListOperation: [],
				p2_reportdef_keyno: '',
				settingsLoaded: false,
				dataLoaded: false,
				setting: {
					filterval: '',
					postatus_no: 10,
					dateselector_index: 0,
					date_fom: null,
					date_tom: null,
					printer: null,
					reportName: '',
					show_setting: 0,
					is_debug: 0
				}
			};

			function corebuttons (){
				return [
					// { name: 'customStock', text: '', func: function () { vm.customStock(); }, icon: 'categorize' },
					{ name: 'customFilter', text: '', func: function () { vm.customFilter(); }, icon: 'filter' },
					{ name: 'customPrintAndPlan', text: '', func: function () { vm.customPrintAndPlan(); }, icon: 'printer' }
					// { name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' }
				];
			}

			vm.grid = {
				setup: {
					remember: 'productionpo.list.grid',
					columns: [],
					format: [],
					editableCols: [],
					filter: { enabled: false, mode: false },
					//toolbar: false,
					toolbar: { //alternative -> toolbar: false,
						hidden: false,
						fullToolbar: false,
						tBarSave: false,
						emptyToolbar: true,
						hideExports: true,
						buttons: corebuttons()
					},
					//pager: true,
					pager: { //alternative -> pager: false,
						pageSizes: false,
						pageSize: 1000,
					},
					autosave: true,
					localData: true,
					translate: true,
					selectable: false,
					behaviour: {
						selectable: "multiple",
						rowClick: false
					}
				},
				data: [],
				selectedCount: 0,
				dataRaw: [],
				saveData: [],
				grid: null,
				gridfunc: null
			};

			vm.gridOptionFunc = gridOptionFunc;
			vm.onPoStatusChange = onPoStatusChange;
			vm.loadData = loadData;
			vm.customFilter = customFilter;
			vm.customClean = customClean;
			vm.customStock = customStock;
			vm.setNewStatus = setNewStatus;
			vm.customPrintAndPlan = customPrintAndPlan;
			vm.customRegret = customRegret;
			vm.onPrinterChange = onPrinterChange;
			
			function customRegret (){
				setNewStatus(true);
			}

			function customPrintAndPlan (){
				setNewStatus(false);
			}

			function customClean(){
				if (vm.model.isolated_kit_keyno !== ''){
					vm.model.isolated_kit_keyno = '';
					savePageSetting('productionpo.list.grid.isolated_kit_keyno',vm.model.isolated_kit_keyno,function(){
						//vm.grid.setup.toolbar.buttons = corebuttons ();
						//vm.grid.grid.refresh();
						reRenderData();
					});
				}				
			}

			function setNewStatus (revert) {
				if (vm.grid.gridfunc.getSelectedRowsCount() > 0){
					vm.model.revert = revert;
					ieScreenBlockService.start('message');			
					ieScreenBlockService.setMessage('Oppdaterer status');
					rememberSelectedItems();
				}				
			}

			function rememberSelectedItems () {
				var variableNameSelectedItems = 'w_prodpo.selected_items';
				vm.model.selectedItems = '';

				var selected_items = vm.grid.gridfunc.getSelectedRows();

				//console.log('SELECTED_ITEMS:');
				//console.dir(selected_items);

				if (selected_items.data.length > 0 ){
					angular.forEach(selected_items.data,function(item){
						if (vm.model.selectedItems.length > 1) {
							vm.model.selectedItems += ',';
						}
						vm.model.selectedItems += item.ppo_poh_keyno;
					});
				}
	
				//console.log('Selected ppo_keyno_top:',vm.model.selectedItems);
				
				productionPoService.remember(variableNameSelectedItems, vm.model.selectedItems).then(function (data) {
					//console.log('Result Saving selected_items..');
					//console.dir(data);
					//console.log('Loading new satus...');
					ieScreenBlockService.setMessage('Variabler lagret oppdaterer status');
					setOperationStatus();
				});
			}

			function setOperationStatus () {
				/*if (vm.model.setting.postatus_no != '100'){
					vm.model.setting.postatus_no_new = '100';
				} else if (vm.model.setting.postatus_no == '100'){
					vm.model.setting.postatus_no_new = '10';				
				}*/

				productionPoService.setOperationStatus(vm.model.setting.postatus_no_new).then(function (data) {
					//console.log('Error result setting new status');
					//console.dir(data);
					
					/*if (vm.model.setting.postatus_no_new != '100'){
						ieScreenBlockService.stop();
						stateService.go('productionhtops',{
							argtype: '-',
							argvalue: '-',
							counter: Date.now()
						});
					} else {*/
					ieScreenBlockService.setMessage('Status satt skriver ut');
					printAndPlanSelected();
					//}
				});	
				
			}

			function printAndPlanSelected () {
				//console.log('Knappen er trykt ned og vi starter loop');
				vm.model.progress = 'START';
				vm.model.progressInfo.label = 'progressbar';
				vm.model.progressInfo.field = '';
				vm.model.progressInfo.max = 0;
				vm.model.progressInfo.step = 0;
	
				if (vm.grid.gridfunc.getSelectedRowsCount() > 0){
					var selected_items = vm.grid.gridfunc.getSelectedRows();
					
					//console.log('Items to sort');
					//console.dir(selected_items.data);
					
					ieScreenBlockService.setMessage('Printing');
					
					var sortedArray = selected_items.data.sort(function(a,b){
						return a.ppo_orderno > b.ppo_orderno ? 1 : a.ppo_orderno < b.ppo_orderno ? -1 : 0;
					});

					//console.log('sortedArray');
					//console.dir(sortedArray);
					//ieScreenBlockService.stop();
					printAndPlandSelectedProgress(sortedArray);
				}
			}

			function printAndPlandSelectedProgress (items) {
				var increment = 1, skip = 0, failed = 0, step = 0;
				vm.model.progressInfo.step = 0;
				var parms = {};
	
				//console.log('Items To Print:');
				//console.dir(items);
	
				var doStep = function () {
					
					$timeout(function () {
						modalService.progressInfo.finishclose = '0';
						
						if (angular.isUndefined(items[step])) {
							ieScreenBlockService.stop();
							stateService.go('productionpo',{
								argtype: '-',
								argvalue: '-',
								counter: Date.now()
							},{ reload:true, inherit: false});
						}
							
						if (angular.isUndefined(items[step])) return;
						vm.model.progressInfo.field = items[step].ppo_orderno + ': ' + items[step].ppo_po;
						modalService.progressInfo.field = vm.model.progressInfo.field;

						parms = {
							reportKeyno: vm.model.p2_reportdef_keyno,
							printerKeyno: vm.model.printer,
							parameters: {
								po_keyno: items[step].ppo_po_keyno
							},
							printerProperties: {
								isLandscape: reportDefinitions.isLandscape,
								pageAutoSize: true,
								copies: vm.model.setting.reportCopies
							}
						};
						
						if (reportDefinitions.paper_size_id === 'custom') {
							parms.printerProperties.pageAutoSize = false;
							parms.printerProperties.scale = 'none';
							parms.printerProperties.paperSize = {
								width: reportDefinitions.width,
								height: reportDefinitions.height
							};
						}
						
						//console.log('Befor print item:');
						//console.dir(items);
						ieScreenBlockService.setMessage('Before Print ' + items[step].ppo_po_keyno + ' - ( ' + (step + 1) + ' / ' + items.length + ' )' );

						productionPoService.beforePrint({po_keyno: items[step].ppo_po_keyno}).then(function (data) {
							//console.log('Befor print result:');
							//console.dir(data);
							ieScreenBlockService.setMessage('Printing ' + items[step].ppo_po_keyno + ' - ( ' + (step + 1) + ' / ' + items.length + ' )' );
							
							if (vm.model.setting.is_debug == 1){
								$timeout(function () {
									step++;
									doStep();
								}, 1000);
							} else {
								printService.print(parms)
								.then(function () {							
									step++;
									doStep();
									
								});
							}							
						});						
					});
				};
				doStep();
			}

			function customStock(){
				//console.log('Knappen er trykt ned og vi starter loop');
				vm.model.progress = 'START';
				vm.model.progressInfo.label = 'progressbar';
				vm.model.progressInfo.field = '';
				vm.model.progressInfo.max = 0;
				vm.model.progressInfo.step = 0;

				var filteredList = [];
				if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
					/*var Grid = vm.grid.gridfunc.getGrid();
					console.log('Grid:');
					console.dir(Grid);
					var items = vm.grid.gridfunc.getItems();
					console.log('items:');
					console.dir(items);
					var options = vm.grid.grid.getOptions();
					console.log('OPTIONS:');
					console.dir(options);*/
					var items = vm.grid.gridfunc.getItems();
					angular.copy(items, filteredList);
				} else {
					/*var Grid = vm.grid.gridfunc.getGrid();
					console.log('Grid:');
					console.dir(Grid);
					var items = vm.grid.gridfunc.getItems();
					console.log('items:');
					console.dir(items);*/
					angular.copy(vm.grid.data, filteredList);
				}
				vm.model.progressInfo.max = filteredList.length;
				checkOrdersProgress(filteredList);

				modalService.showProgress(vm.model.progressInfo).then(function (value) {
					vm.model.progress = value;
				});

			}

			function checkOrdersProgress (items) {
				var increment = 1, skip = 0, failed = 0, step = 0;
				vm.model.progressInfo.step = 0;
	
				var doStep = function () {
					$timeout(function () {
						modalService.progressInfo.finishclose = '0';
						if (vm.model.progress === 'ABORT') return;
						if (angular.isUndefined(items[step])) return;
	
						step = vm.model.progressInfo.step + skip + failed;
						if (angular.isUndefined(items[step])) return;
						vm.model.progressInfo.field = items[step].ppo_product;
						modalService.progressInfo.field = vm.model.progressInfo.field;
	
						addPohTops(items[step]).then(function (data) {
							
							vm.model.progressInfo.step += increment;
							modalService.progressInfo.step = vm.model.progressInfo.step;
							modalService.progressInfo.finishclose = '1';

							doStep();

							var options = vm.grid.grid.getOptions();
							options.dataSource.data = [];
							angular.copy(vm.grid.data, options.dataSource.data);
							vm.grid.grid.setOptions(options);
							vm.grid.grid.refresh();
						});
					});
				};
				doStep();
			}
	
			function addPohTops(item) {
				var deferred = $q.defer();
	
				productionPoService.loadPohTopsCheckOrder(item.ppo_poh_keyno).then(function (data) {
					angular.forEach(vm.grid.data, function (poh) {
	
						if (poh.item_record === item.item_record) { 
							//console.log('sjekk data:');
							//console.dir(data[0]);
							poh.item_glyphicon_color = data[0].item_glyphicon_color;
							poh.ppo_show = data[0].item_status_text;
						}
					});
	
					deferred.resolve(data);
				});
	
				return deferred.promise;
			}

			function customFilter(){
				var m = 0;
				if (vm.grid.setup.filter.mode == true){
					vm.grid.setup.filter.mode = false;
					m = 0;
				} else {
					vm.grid.setup.filter.mode = false;
					m = 1;
				}				
				savePageSetting('productionpo.list.grid.filter',m,function(){
					stateService.go('productionpo',{ argtype: '-',argvalue: '-',counter: '-'},{ reload: true, inherit: false });
				});
			}

			function gridOptionFunc(data) {
				if (angular.isDefined(data.type)) {
					$timeout(function () {
						if (vm.grid.gridfunc !== null) vm.grid.selectedCount = vm.grid.gridfunc.getSelectedRowsCount();						
					}, 0);	//Changed from 300 - RHE 20230210
					switch (data.type) {
						case 'init':
							//console.log('Init');
							vm.grid.grid = data.grid;
						break;						
						case 'dataBoundRow':
							var cols = {};
                            data.grid.columns;
                            var cellIndex = 0;
                            angular.forEach(data.grid.columns,function(item){
                                item.cellIndex = cellIndex;
                                cols[item.field] = item;
                                cellIndex += 1;
                            });

							/*
                            data.row.find('td:eq('+cols['ppo_orderno'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                vm.model.isolated_kit_keyno = data.dataItem.ppo_kit_keyno;
								savePageSetting('productionpo.list.grid.isolated_kit_keyno',vm.model.isolated_kit_keyno,function(){
									vm.grid.setup.toolbar.buttons.push({ name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' });
									vm.grid.grid.refresh();
									//vm.grid.gridfunc.setButtons({ name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' });
									reRenderData();
								});
                            });
							*/

							/*
							data.row.find('td:eq('+cols['ppo_orderno'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                vm.model.isolated_kit_keyno = data.dataItem.ppo_kit_keyno;
								savePageSetting('productionpo.list.grid.isolated_kit_keyno',vm.model.isolated_kit_keyno,function(){
									vm.grid.setup.toolbar.buttons.push({ name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' });
									vm.grid.grid.refresh();
									//vm.grid.gridfunc.setButtons({ name: 'customClean', text: '', func: function () { vm.customClean(); }, icon: 'zoom-out' });
									reRenderData();
								});
                            });
							*/

							/*
							data.row.find('td:eq('+cols['ppo_status'].cellIndex+')').addClass('h_point').on('click', function (e) {
                                stateService.go('productionhtopscomponents', { argtype: 'ppo_keyno', argvalue: data.dataItem.ppo_po, counter: 1 });
                            });
							*/

							var i = 0;
							for (i = 0; i < vm.grid.setup.columns.length - 1; i++){
								switch (i){
									case 2:	
									case 8:
									case 15:
									break;
									default:
										if (i > 1){
											data.row.find('td:eq('+i+')').addClass('h_point').on('click', function(e) {
												stateService.go('pohtop', { poh_keyno_top: data.dataItem.ppo_poh_keyno },{ reload: true, inherit: false });
											});
										}
									break;
								}
							}
							
							break;
						default:
							break;
					}
				}
			}
			
			function onPoStatusChange(value) {
				vm.model.setting.postatus_no = value;
				loadDataQued();
			}
            
			function onPrinterChange(value) {
				vm.model.printer = value;
				productionPoService.setDefaultPrinter(vm.model.printer);
			}

			function loadSettingsAndInitiate() {
				productionPoService.loadOperationsSelectList().then(function (data) {
					vm.model.selectListOperation = [];
					
					angular.forEach(data, function (item) {
						if (angular.isDefined(item)) {
							vm.model.selectListOperation.push(item);
						}
					});

					loadDataQued();
				});

				dbPrinterService.list().then(function (data) {
					//console.log('Printers:');
					//console.dir(data);
				 	angular.copy(data, vm.model.printers);

					productionPoService.getSettings().then(function (data) {
						//console.log('Setting loaded:');
						//console.dir(data);
						vm.model.setting.postatus_no = data[0].status_no;
						vm.model.setting.dateselector_index = data[0].dateselector_index;
						vm.model.setting.date_fom = data[0].date_fom;
						vm.model.setting.date_tom = data[0].date_tom;
						vm.model.printer = data[0].default_printer;
						vm.model.setting.reportName = data[0].report_name;
						vm.model.setting.reportCopies = data[0].report_copies;
						vm.model.isolated_kit_keyno = data[0].isolated_kit_keyno;
						vm.model.setting.show_setting = parseInt(data[0].show_setting);
						vm.model.setting.is_debug = parseInt(data[0].is_debug);

						if (data[0].filter == 0){
							vm.grid.setup.filter = {enabled: false, mode: false};
							vm.grid.setup.height =  620;
						} else {
							vm.grid.setup.filter = {enabled: true, mode: true};
							vm.grid.setup.height =  580;
						}
						getReportByName();
						vm.grid.setup.toolbar.buttons = corebuttons();

						if (vm.model.setting.postatus_no == '10'){
							vm.grid.setup.toolbar.buttons.push({ name: 'customPrintAndPlan', text: '', func: function () { vm.customPrintAndPlan(); }, icon: 'printer' });	
						} else if (vm.model.setting.postatus_no == '100'){
							vm.grid.setup.toolbar.buttons.push({ name: 'customRegret', text: '', func: function () { vm.customRegret(); }, icon: 'undo' });	
						} 
							
						vm.model.settingsLoaded = true;

						loadDataQued();
					});
				});

			}
			// START
			loadSettingsAndInitiate();

			function getReportByName(){
				productionPoService.getReportDefn(vm.model.setting.reportName)
					.then(function(data){
						//console.log('Reportdefn_keyno');
						//console.dir(data);
						vm.model.p2_reportdef_keyno = data[0].p2_reportdef_keyno;
						
						printPreviewService.loadP2ReportDef(vm.model.p2_reportdef_keyno).then(function (data) {
							//console.log('Reportdefn');
							//console.dir(data);
							reportDefinitions = angular.copy(data[0]);
						});
					})
					.catch(function(error){
						//console.dir(error);
					});
			}

			function reRenderData(){
				var buffer = [];
				var counter = 0;
				var lkit_keyno = '';
				vm.grid.data = []; //test
				
				angular.forEach(vm.grid.dataRaw, function (orgItem) {
					var item = {};
					angular.copy(orgItem,item);
					//console.dir(item);
					item.is_visible = 1;

					if (vm.model.isolated_kit_keyno !== ''){
						if (item.ppo_kit_keyno == vm.model.isolated_kit_keyno){
							//console.log(item.ppo_kit_keyno,'=',vm.model.isolated_kit_keyno);
							item.is_visible = 1;
						} else {
							item.is_visible = 0;
						}
					} else {
						if (parseInt(item.ppo_kit_keyno) > 0){
							if ((buffer.length > 0) && (lkit_keyno != item.ppo_kit_keyno)){
								buffer = [];
							}
							if (buffer.length === 0){
								item.ppo_item_product_shortname = item.ppo_product;
								//visible aid..
								item.ppo_product = item.ppo_pocollectdescr.substring(0,50);													
								buffer.push(item);
								lkit_keyno = item.ppo_kit_keyno;
							} else {								
								item.is_visible = 0;
							}
						}
					}
					if (item.is_visible > 0){
						vm.grid.data.push(item);												
					}
					
				});

				//console.log('Rendering data:');
				//console.dir(vm.grid.data);
				if (vm.grid.data.length > 0){
					if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
						var options = vm.grid.grid.getOptions();
						options.dataSource.data = [];
						angular.copy(vm.grid.data, options.dataSource.data);
						vm.grid.grid.setOptions(options);
					} else {
	
					}
				} else {
					customClean();
				}				
			}

			function loadData() {
				vm.model.dataLoaded = false;
				if (angular.isUndefined(vm.model.setting.date_fom) === true) return;
				if (vm.model.setting.date_fom === null) return;
				if (vm.model.setting.date_fom.trim().length < 1) return;

				if (angular.isUndefined(vm.model.setting.date_tom) === true) return;
				if (vm.model.setting.date_tom === null) return;
				if (vm.model.setting.date_tom.trim().length < 1) return;

				//console.log('Loading data.. validated');

				vm.grid.saveData = [];
				vm.grid.data = [];
				vm.grid.dataRaw = [];

				//console.log('Loading data.. requesting data');
				productionPoService.loadProductionPoList(vm.model.setting).then(function (data) {
					//console.log('Data from db:');
					//console.dir(data);
					if (data.length > 3) {
						angular.copy(data[3].editable_columns, vm.grid.setup.editableCols);
					}

					if (data[2].rows.length > 0) {
						if (angular.isDefined(vm.grid.grid) && vm.grid.grid !== null) {
							//poke grid
							vm.grid.setup.columns = [];
							vm.grid.setup.format = [];
							angular.copy(data[0].columns, vm.grid.setup.columns);
							angular.copy(data[1].format, vm.grid.setup.format);
							vm.grid.data = [];
							vm.grid.dataRaw = [];
							vm.grid.data.push(data[2].rows[0]);

							$timeout(function () {
								vm.grid.setup.columns = [];
								vm.grid.setup.format = [];
								angular.copy(data[0].columns, vm.grid.setup.columns);
								angular.copy(data[1].format, vm.grid.setup.format);
								vm.grid.data = [];
								vm.grid.dataRaw = [];
								angular.copy(data[2].rows, vm.grid.dataRaw);
								//angular.copy(data[2].rows, vm.grid.data);

								reRenderData();

								vm.model.dataLoaded = true;
							}, 100);

						} else {
							vm.grid.data = [];
							vm.grid.dataRaw = [];
							vm.grid.setup.columns = [];
							vm.grid.setup.format = [];
							angular.copy(data[0].columns, vm.grid.setup.columns);
							//console.log('columns:');
							//console.dir(vm.grid.setup.columns);
							angular.copy(data[1].format, vm.grid.setup.format);
							//console.log('format:');
							//console.dir(vm.grid.setup.format);
							//angular.copy(data[2].rows, vm.grid.data);
							angular.copy(data[2].rows, vm.grid.dataRaw);

							reRenderData();

							vm.model.dataLoaded = true;
						}
					}
				});
			};

			function illegalCharRegExp(str) {
				return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "-");
			}

			function savePageSetting (variableName, variableValue,optionalFunc) {

				productionPoService.remember(variableName,variableValue).then(function (data) {
                    //console.log('Settings saved: ', variableName, ' / ', variableValue);
                    //console.dir(data);
					if (angular.isFunction(optionalFunc)){
						optionalFunc();
					}
                });
            }

			function rememberDateFom() {
				var variableNameDateFom = 'productionpo.date_fom';
				if (canRun616(variableNameDateFom, 'date_fom') !== true) return;
				productionPoService.remember(variableNameDateFom, vm.model.setting.date_fom);
			}

			function rememberDateTom() {
				var variableNameDateTom = 'productionpo.date_tom';
				if (canRun616(variableNameDateTom, 'date_tom') !== true) return;
				productionPoService.remember(variableNameDateTom, vm.model.setting.date_tom);
			};

			function rememberDateSelectorIndex() {
				var variableNameDateTom = 'productionpo.dateselector_index';
				if (canRun616(variableNameDateTom, 'dateselector_index') !== true) return;
				productionPoService.remember(variableNameDateTom, vm.model.setting.dateselector_index);
			}

			function canRun616(variableName, parm) {
				if (angular.isUndefined(variableName) === true) return false;
				if (variableName === null) return false;
				if (variableName === '') return false;
				if (angular.isUndefined(vm.model.setting[parm]) === true) return false;
				if (vm.model.setting[parm] === null) return false;
				return true;
			}

			var timer = null;

			function loadDataQued(){
				if (angular.isDefined(timer)){
					$timeout.cancel(timer);
				}
				timer = $timeout(function(){
					loadData();
				}, 300);
			}

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom) rememberDateFom();
                if (vm.model.setting.date_tom) rememberDateTom();
                if (vm.model.setting.dateselector_index) rememberDateSelectorIndex();

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    //vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    loadDataQued();
                }
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION
            
        }]
    });
})();
