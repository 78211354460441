(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentScan', {
        templateUrl: 'views/components/views/consignmentScan/consignmentScan.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['consignmentScanService', 'stateService', function(consignmentScanService, stateService) {
            var vm = this;

            vm.model = {
                filterConsignmentScan: {
                    barcode: '',
                    records: []
                },
                p2ConsignmentScan: {}
            };
            
            vm.state = {
                loading: false
            };
            
            vm.searchP2ConsignmentScan = function () {
                if (angular.isUndefined(vm.model.filterConsignmentScan.barcode) || vm.model.filterConsignmentScan.barcode.length == 0) return;

                vm.state.loading = true;

                vm.model.filterConsignmentScan.records.unshift({
                    barcode: vm.model.filterConsignmentScan.barcode
                });

                consignmentScanService.searchP2ConsignmentScan(vm.model.filterConsignmentScan.records[0].barcode).then(function () {
                    vm.state.loading = false;
                    vm.model.p2ConsignmentScan = consignmentScanService.p2ConsignmentScan;

                    if (angular.isDefined(vm.model.p2ConsignmentScan)) {
                        stateService.go(vm.model.p2ConsignmentScan.webpagename, vm.model.p2ConsignmentScan.parms);
                        clearField();
                    } else {
                        clearField();
                    }

                    clearField();
                });
            };
            
            var clearField = function () {
                if (vm.model.filterConsignmentScan.barcode !== '' && vm.model.filterConsignmentScan.records.length > 0 && vm.state.loading == false) {
                    vm.model.filterConsignmentScan.barcode = '';
                }
            };
            
            vm.goToConsignments = function () {
                stateService.go('consignments', {
                    argtype: '-',
                    argvalue: '-'
                });
            };
        }]
    });
})();