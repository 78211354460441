(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("deliveryPlanningService", ['$ihttp', function ($ihttp) {
        let service = {
            getSetting: function (parms) {
                return $ihttp.post({
                    method: 1085,
                    parameters: parms || {}
                });
            },
            listDeliveryPlanningTypes: function (parms) {
                return $ihttp.post({
                    method: 1088,
                    parameters: parms || {}
                });
            },
            listConsignmentShipments: function (parms) {
                return $ihttp.post({
                    method: 1107,
                    parameters: parms || {}
                });
            },
            listDeliveryPlannings: function (parms) {
                return $ihttp.post({
                    method: 1086,
                    parameters: parms || {}
                });
            },
            changeStockPlaceId: function (setting) {
                return $ihttp.post({
                    method: 1095,
                    parameters: setting
                });
            },
            createObj: function (mergeObj) {
                return $ihttp.post({
                    method: 1087,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
