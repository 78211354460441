(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('projectProcess', {
        templateUrl: 'views/components/views/projectProcess/projectProcess.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'projectProcessService', function ($stateParams, stateService, projectProcessService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let projectKeyno = $stateParams.project_keyno;
            let projectProsessKeyno = $stateParams.projectprosess_keyno;
            
            vm.model = {
                projectProsessKeyno: projectProsessKeyno,
                edit: {},
                selectListPriceMethods: [],
                lockedSave: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS
            
            let loadEdit = function () {
                projectProcessService.getEdit({
                    project_keyno: projectKeyno,
                    projectprosess_keyno: projectProsessKeyno
                }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            let loadPriceMethods = function () {
                projectProcessService.listPriceMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListPriceMethods);
                });
            };
            
    		// #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region SAVE BUTTON FUNCTION
            
            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                projectProcessService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion SAVE BUTTON FUNCTION

            // #region DELETE BUTTON FUNCTION
            
            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                projectProcessService.deleteItem(vm.model.edit).then(function () {
                    vm.model.lockedDelete = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };


            // #endregion DELETE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
                loadPriceMethods();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
