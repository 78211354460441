(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("emailViewService", ['$ihttp', 'p2DataTaskService', function ($ihttp, p2DataTaskService) {
        var service = {
            getEmailView: function (emailKeyNo) {
                return p2DataTaskService.call(1013, {
                    email_keyno: emailKeyNo
				});
			},
            listEmailViewAttachments: function (emailKeyNo) {
                return p2DataTaskService.call(1014, {
                    email_keyno: emailKeyNo
				});
            },
            getReplyToList: function (emailKeyno) {
                return p2DataTaskService.call(2963, {
                    email_keyno: emailKeyno
                });
            },
            deleteItem: function (edit) {
                return $ihttp.post({
                    method: 3002,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
