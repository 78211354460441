import { coerceBooleanProperty, BooleanInput } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';
import * as uuid from 'uuid';
import { DataTaskService } from '../../services/data-task.service';
import { TranslateService } from '../../services/translate.service';
import { ComponentBaseComponent } from '../component-base/component-base.component';

@Component({
    selector: 'tt-input-tt',
    standalone: true,
    templateUrl: './tt-input.component.html',
    styleUrls: ['./tt-input.component.css'],
    imports: [CommonModule],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TtInputComponent),
            multi: true,
        },
    ],
})
export class TtInputComponent extends ComponentBaseComponent implements ControlValueAccessor, OnInit {
    constructor(private dt: DataTaskService, private ts: TranslateService) {
        super();
    }

    value: string = '';

    onChange: (value: string) => void = noop;

    onTouched = () => {};

    touched = false;
    disabled = false;

    @Input('tt-required')
    get required(): boolean {
        return this._required;
    }
    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }
    private _required: boolean = false;

    //@Input({ transform: coerceBooleanProperty }) required: boolean = false;
    @Input('tt-label')
    label: string = '';
    @Input()
    readonly = false;
    @Input()
    maxLength = 524288; //Webstandard default
    @Input()
    inputType: string = 'text';
    @Input()
    inputMode = 'text';

    @Input() onBlur: (param: any) => void = () => {};

    id = {
        input: uuid.v4(),
    };

    style = {
        base: {},
        label: {},
        input: {},
        labelAlwaysOnTop: false, // Deprecated, use ttLabelView instead. - JLR 20230622
    };

    class = {
        base: '',
        label: '',
        input: '',
    };

    translations = {
        ttLabel: '',
        ttPlaceholder: '',
    };

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(disabled: boolean): void {
        this.disabled = disabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();

            this.touched = true;
        }
    }

    async ngOnInit(): Promise<void> {
        if (this.label.length > 0) {
            this.translations.ttLabel = (await this.ts.translate(this.label)) as string;
        }
    }
}
