(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('businessCoBankAccount', {
        templateUrl: 'views/components/views/businessCoBankAccount/businessCoBankAccount.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'translateService', 'modalService', 'businessCoBankAccountService', 'economyService', 'typeaheadService', function ($stateParams, stateService, translateService, modalService, businessCoBankAccountService, economyService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let businessCoNo = $stateParams.businessco_no;
            let businessCoBankAccountKeyno = $stateParams.businessco_bankaccount_keyno;

            let translations = {
                error: '',
                ok: ''
            };

            vm.model = {
                edit: {},
                selectListCurrencies: [],
                lockedSave: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadEdit = function () {
                businessCoBankAccountService.getEdit({
                    businessco_no: businessCoNo,
                    businessco_bankaccount_keyno: businessCoBankAccountKeyno
                }).then(function (info) {
            		angular.copy(info[0], vm.model.edit);
            	});
            };

            let loadCurrencies = function () {
                economyService.listCurrencies().then(function (list) {
                    angular.copy(list, vm.model.selectListCurrencies);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'account_name_ocr':
                    case 'account_name_agio':
                    case 'account_name_disagio':
                    case 'account_name_bank_fees':
                        vm.model.edit[id] = value;

                        return typeaheadService.lookUpSearchAccounts({
                            searchtext: vm.model.edit[id] || '',
                            businessco_no: vm.model.edit.businessco_no,
                            onlyaccounttype: 'a'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'account_name_ocr':
                        if (item) {
                            vm.model.edit.account_no_ocr = item?.item_id ?? '0';
                            vm.model.edit.account_name_ocr = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('account_name_ocr');
                        }
                        break;
                    case 'account_name_agio':
                        if (item) {
                            vm.model.edit.account_no_agio = item?.item_id ?? '0';
                            vm.model.edit.account_name_agio = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('account_name_agio');
                        }
                        break;
                    case 'account_name_disagio':
                        if (item) {
                            vm.model.edit.account_no_disagio = item?.item_id ?? '0';
                            vm.model.edit.account_name_disagio = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('account_name_disagio');
                        }
                        break;
                    case 'account_name_bank_fees':
                        if (item) {
                            vm.model.edit.account_no_bank_fees = item?.item_id ?? '0';
                            vm.model.edit.account_name_bank_fees = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('account_name_bank_fees');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'account_name_ocr':
                        vm.model.edit.account_name_ocr = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.account_no_ocr = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('account_name_ocr');
                        }
                        break;
                    case 'account_name_agio':
                        vm.model.edit.account_name_agio = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.account_no_agio = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('account_name_agio');
                        }
                        break;
                    case 'account_name_disagio':
                        vm.model.edit.account_name_disagio = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.account_no_disagio = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('account_name_disagio');
                        }
                        break;
                    case 'account_name_bank_fees':
                        vm.model.edit.account_name_bank_fees = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.edit.account_no_bank_fees = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('account_name_bank_fees');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'account_name_ocr':
                        vm.model.edit.account_no_ocr = '0';
                        vm.model.edit.account_name_ocr = '';
                        break;
                    case 'account_name_agio':
                        vm.model.edit.account_no_agio = '0';
                        vm.model.edit.account_name_agio = '';
                        break;
                    case 'account_name_disagio':
                        vm.model.edit.account_no_disagio = '0';
                        vm.model.edit.account_name_disagio = '';
                        break;
                    case 'account_name_bank_fees':
                        vm.model.edit.account_no_bank_fees = '0';
                        vm.model.edit.account_name_bank_fees = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                businessCoBankAccountService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
            	loadEdit();
                loadCurrencies();
            };

            // #endregion ON INIT FUNCTION
        }]
    });
})();
