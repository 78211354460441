<div class="col-xs-12 sp-0">
    <div class="row-fluid">
        <form class="form-horizontal im-no-pad" name="formP2ReportDefs" novalidate method="get" role="form">
            <tt-card class="col-xs-12 sp-0">
                <tt-input class="col-xs-12 sp-0"
                          ttLabel="htmlreport_keyno"
                          [(ttModel)]="this.model.htmlReport.htmlreport_keyno"
                          name="htmlreport_keyno">
                </tt-input>

                <tt-input class="col-xs-12 sp-0"
                          ttLabel="htmlreport_name"
                          [(ttModel)]="this.model.htmlReport.htmlreport_name"
                          [readonly]="false"
                          name="htmlreport_name">
                </tt-input>



                <tt-input class="col-xs-12 sp-0"
                          ttLabel="proc_name"
                          [(ttModel)]="this.model.htmlReport.proc_name"
                          name="proc_name">
                </tt-input>

                <tt-input class="col-xs-12 sp-0"
                          ttLabel="header_proc_name"
                          [(ttModel)]="this.model.htmlReport.header_proc_name"
                          name="header_proc_name">
                </tt-input>

                <tt-input class="col-xs-12 sp-0"
                          ttLabel="footer_proc_name"
                          [(ttModel)]="this.model.htmlReport.footer_proc_name"
                          name="footer_proc_name">
                </tt-input>


                <!-- Add more inputs or select fields here -->

                <tt-button-save class="col-xs-12 sp-0"
                                [text]="'save'"
                                (click)="this.save()">
                </tt-button-save>


                <ng-container *ngIf="this.model.documentOverviewMap !== null">
                        <ng-container *ngFor="let htmlproc of this.model.documentOverviewMap.keys() ?? []; let i = index">
                            <tt-button *ngIf="htmlproc.htmlproc_keyno_belongto === '0'" [ttText]="htmlproc.proc_name" ttTextAlign="left"
                            ttType="link"
                            (click)="goToProcedure(htmlproc.htmlproc_keyno,htmlproc.htmlproc_keyno_belongto)">

                            </tt-button>
                        </ng-container>
                </ng-container>
                <tt-button ttTextAlign="left" ttType="link"
                ttIcon="far fa-plus"
                        (click)="newProcedure()"
                ></tt-button>
            </tt-card>
            <script src="../button-multi/button-multi.component.js"></script>
            <tt-card class="col-xs-12 sp-0" *ngIf="this.model.htmlReport.htmlreport_keyno > 0">
                <h3>{{ translations["procedures"] }}</h3>
                <!-- <tt-list [listItems]="this.model.htmlProcList"></tt-list> -->
                <!--  <tt-button *ngFor="let item of this.model.htmlProcList" ></tt-button> -->

                <tt-card class="col-xs-1 sp-0">
                    <tt-button-goto class="col-xs-12 sp-0"
                                    [text]="'htmlproc_new'"
                                    [goto]="'htmlproc'"
                                    [gotoParams]="[this.model.htmlReport.htmlreport_keyno, '0', '0']">
                    </tt-button-goto>
                </tt-card>
            </tt-card>

            <tt-card class="col-xs-12 sp-0">
                <h3>{{ translations["preview"]}}</h3>
                <tt-select class="col-xs-12 sp-0"
                           [ttLabel]="'language_id'"
                           [(ttModel)]="this.model.language_id"
                           name="language_id"
                           [data]="this.model.languageList"
                           [dataId]="'item_id'"
                           [dataName]="'item_name'"
                           (change)="this.onChange('language_id', $event)">
                </tt-select>

                <tt-button class="col-xs-12 sp-0"
                           [text]="'refresh_html'">
                </tt-button>

                <div class="htmlReportPreviewWrapper" *ngIf="this.model.isReady"
                    >
                    <br />
                    <!--PROPERTIES PANEL, FOR EDITING EITHER PROCCOLS OR THE TABLES / FORMS THEMSELVES-->
                    <div class="hierarchy">
                        <tt-button ttType="link" ttText="Show All" (click)="showAll()">

                        </tt-button>
                        <ng-container *ngFor="let htmlproc of this.model.hierarchy">
                            <tt-button [ngStyle]="{'margin-left.px': htmlproc.indentations * 20,
                                                    'font-weight': htmlproc.htmlproc_keyno == 'this.model.focusedKeyno' ? 'bold' : 'normal'}"
                                       (click)="toggleFocus(htmlproc.htmlproc_keyno)" ttType="link" ttText="{{htmlproc.name}}">

                            </tt-button>
                        </ng-container>

                    </div>

                    <ng-container *ngIf="this.model.documentOverviewMap !== null">
                        <div id="htmlReportPreview" style="transform: scale({{ this.model.scale }}); transform-origin: top center;"
                             cdkDropList
                             (cdkDropListDropped)="dropSection($event)"
                             [ngStyle]="{ 'display': this.model.isFocus ? 'block' : 'none' }">
                            <!--THIS IS A SECTION THAT CONTAINS THE VISIBLE FORM AND THE INVISIBLE ELEMENTS ON THE RIGHT-->
                            <div class="htmlproc_section" style="display:flex;"
                                 cdkDropListGroup *ngFor="let htmlproc of this.model.documentOverviewMap.keys() ?? []; let i = index"
                                 cdkDrag cdkDragLockAxis="y"
                                 [ngStyle]="{ 'display': isFocused(htmlproc.htmlproc_keyno) ? 'flex' : 'none' }">



                                <div class="SectionOptions" >
                                    <!-- <i [ngClass]="this.model.isFocus ? 'far fa-eye-slash' : 'far fa-eye'"
                                       (click)="toggleFocus(htmlproc.htmlproc_keyno)"></i> -->

                                    <i class="far fa-plus"
                                    (click)="createNewSubProcedure(htmlproc.htmlproc_keyno)"></i>
                                </div>
                                 <div class="drag-handle" cdkDragHandle>☰</div>
                                <ng-container *ngIf="htmlproc.presentation_type === 'form'">
                                    <!--THIS IS A SINGLE FORM-->
                                    <div style="padding-bottom: 1rem; width: 100%; display: grid;"
                                         [ngStyle]="{
                                                'grid-template-columns': 'repeat(' + htmlproc.col_count + ', 1fr)',
                                                'grid-template-rows': 'repeat(' + htmlproc.row_count + ', 1fr)',
                                                'align-self': htmlproc.is_header === '1' ? 'center' : 'flex-start',
                                                'box-shadow': htmlproc.is_header === '1' ? '0 5px 5px -5px rgba(0, 0, 0, 0.1),5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)' : '5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)',
                                                'padding-bottom': htmlproc.has_padding_bottom === '1' ? '1rem' : '0'

                                                    }"
                                         class="htmlForm">
                                        <!--THIS IS A SINGLE PLACE HOLDER CELL, THIS IS MADE THE SAME AMOUNT OF TIMES AS THERE ARE CELLS IN THE GRID-->

                                        <div *ngFor="let placeholderIndex of [].constructor(+ htmlproc.col_count * + htmlproc.row_count); let j = index"
                                             [attr.data-index]="j + 1"
                                             [class.list-with-border]="model.isDragging && model.currentHtmlProc === htmlproc"
                                             cdkDropList
                                             [cdkDropListData]="getPlaceholderData(htmlproc, j +1) || []"
                                             (cdkDropListDropped)="drop($event, i, htmlproc)"
                                             [ngStyle]="{
                                                        'width': '100%',
                                                        'height': '100%',
                                                        'background-color': getPlaceholderData(htmlproc, j + 1)?.[0]?.bg_color || 'transparent',
                                                        'border': model.isDragging && model.currentHtmlProc === htmlproc
                                                        ? '2px dashed #3f51b5'
                                                           : (htmlproc.has_border === '1' ? '1px solid black' : 'none'),

                                                    }">


                                            &nbsp;
                                            <ng-container *ngFor="let item of getPlaceholderData(htmlproc,j +1);">
                                                <label class="htmlInvisibleLabel"
                                                       cdkDrag
                                                       (cdkDragStarted)="dragStarted(htmlproc)"
                                                       (cdkDragEnded)="dragEnded()"
                                                       [cdkDragData]="item"
                                                       (click)="updateEditItemContent(item.stringvalue)"
                                                       [ngStyle]="{
                                                                    fontWeight: item.bold === '1' ? 'bold' : 'normal',
                                                                    fontStyle: item.italic === '1' ? 'italic' : 'normal',
                                                                    textAlign: item.textalign,
                                                                    fontSize: item.fontsize,
                                                                    color: item.color || 'black',
                                                                    backgroundColor: item.bg_color || 'transparent',
                                                                    fontFamily: getFontStyle(item)
                                                                  }">



                                                    <!-- Conditionally show the image if has_image is 1 -->
                                                    <ng-container *ngIf="item.is_image === '1'; else textContentShowForm">
                                                        <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                            <img [src]="item.stringvalue" alt="Image" style="max-width: 100%; max-height: 100%;">
                                                        </ng-container>
                                                    </ng-container>

                                                    <!-- Else show the text content -->
                                                    <ng-template #textContentShowForm>
                                                        <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                            <span [innerHTML]="sanitizeHTML(item.stringvalue)"></span>
                                                        </ng-container>
                                                    </ng-template>
                                                </label>

                                            </ng-container>
                                            <tt-context-menu-report *ngIf="this.model.contextMenuVisible"
                                                                    [item]="this.model.selectedItem"
                                                                    [position]="this.model.contextMenuPosition"
                                                                    (close)="this.model.contextMenuVisible = false"
                                                                    (update)="updateItem($event)">
                                            </tt-context-menu-report>
                                        </div>
                                    </div>

                                </ng-container>
                                <ng-container *ngIf="htmlproc.presentation_type === 'table'">
                                    <div class="tablePreviewContainer" style="display:flex; flex-direction: column; width:100%; background-color: white; max-width: 21cm;">
                                        <!-- THIS IS A SINGLE TABLE-->
                                        <ng-container *ngIf="htmlproc.has_header === '1'">

                                            <div class="tableContainer"
                                                 [class.list-with-border]="model.isDragging && model.currentHtmlProc === htmlproc"
                                                 cdkDropList
                                                 cdkDropListOrientation="horizontal"
                                                
                                                 [cdkDropListData]="getPlaceholderData(htmlproc, 1) || []"
                                                 (cdkDropListDropped)="drop($event, i, htmlproc)"
                                                 [ngStyle]="{'align-self': htmlproc.is_header === '1' ? 'flex-end' : 'flex-start',
                                        'box-shadow': htmlproc.is_header === '1' ? '0 5px 5px -5px rgba(0, 0, 0, 0.1),5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)' : '5px 0 5px -5px rgba(0, 0, 0, 0.1), -5px 0 5px -5px rgba(0, 0, 0, 0.1)',
                                            'padding-bottom': htmlproc.has_padding_bottom === '1' ? '1rem' : '0'
                                            }">

                                                <ng-container *ngIf="!getPlaceholderData(htmlproc, 0)">
                                                    &nbsp;
                                                </ng-container>
                                                <ng-container *ngFor="let item of getPlaceholderData(htmlproc,1);">
                                                    <ng-container *ngIf="item.visible === '1'">
                                                        <ng-container *ngIf="item.colname_lang && item.colname_lang.trim() !== ''">
                                                            <label cdkDrag
                                                                   (cdkDragStarted)="dragStarted(htmlproc)"
                                                                   (cdkDragEnded)="dragEnded()"
                                                                   [cdkDragData]="item"
                                                                   (contextmenu)="showContextMenu($event, item)"
                                                                   [ngStyle]="{
                                                                    padding: '0.5rem',
                                                                    fontStyle: item.italic === '1' ? 'italic' : 'normal',
                                                                    textAlign: item.textalign,
                                                                    fontSize: item.fontsize,
                                                                    color: item.color || 'black',
                                                                    flexGrow: (+item.width || 1),
                                                                    backgroundColor: item.bg_color || 'transparent',
                                                                    fontFamily: getFontStyle(item),
                                                                    minWidth: item.is_image === '1' ? (+item.width * 114) + 'px' : '0',
                                                                    'border': htmlproc.has_border === '1' ? '1px solid black' : 'none',
                                                                  }">

                                                                <span [innerHTML]="sanitizeHTML(item.colname_lang)"></span>
                                                            </label>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>

                                            </div>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="this.model.tablePreviewMap && this.model.tablePreviewMap.get(htmlproc)">
                                            <div class="tablePreview" id="tablePreview" [innerHTML]="sanitizeHTML(this.model.tablePreviewMap!.get(htmlproc)!)">
                                            </div>
                                        </ng-container>
                                    </div>
                                    
                                </ng-container>
                                <!--SIDE SECTION WHERE ALL THE UNUSED VALUES ARE STORED-->
                                <ng-container *ngIf="this.model.isFocus">

                                    <div class="rightSidePreview">
                                        <label>Proc Instillinger</label>
                                       
                                        <div class="sectionSettings">
                                            <tt-input ttLabel="htmlproc_keyno"
                                                      [(ttModel)]="htmlproc.htmlproc_keyno"
                                                      name="htmlproc_keyno"
                                                      ttReadonly>
                                            </tt-input>
                                            <tt-input ttLabel="htmlproc_name"
                                                      [(ttModel)]="htmlproc.proc_name"
                                                      name="proc_name"
                                                      ttReadonly>
                                            </tt-input>
                                            <tt-input ttLabel="presentation_type"
                                                      [(ttModel)]="htmlproc.presentation_type"
                                                      name="presentation_type"
                                                      ttReadonly>
                                            </tt-input>
                                            <tt-input *ngIf="htmlproc.presentation_type === 'table'"
                                                      ttLabel="Columns"
                                                      [ttModel]="getVisibleItemCount(htmlproc)"
                                                      name="columns"
                                                      (change)="updateFormGrid(htmlproc)">
                                            </tt-input>
                                            <tt-input *ngIf="htmlproc.presentation_type === 'form'"
                                                      ttLabel="Columns"
                                                      [(ttModel)]="htmlproc.col_count"
                                                      name="columns"
                                                      (change)="updateFormGrid(htmlproc)">
                                            </tt-input>
                                            <tt-input *ngIf="htmlproc.presentation_type === 'form'"
                                                      ttLabel="Rows"
                                                      [(ttModel)]="htmlproc.row_count"
                                                      name="rows"
                                                      (change)="updateFormGrid(htmlproc)">
                                            </tt-input>
                                            <tt-checkbox *ngIf="htmlproc.presentation_type === 'table'" style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_header" ttLabel="has_header">
                                            </tt-checkbox>
                                            <tt-checkbox *ngIf="htmlproc.presentation_type === 'table'" style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_alternating_color" ttLabel="has_alternating_color">
                                            </tt-checkbox>
                                            <tt-checkbox style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_border" ttLabel="has_border">
                                            </tt-checkbox>
                                            <tt-checkbox style="color:#555; text-transform:uppercase; font-weight:700; font-size:13px" [(ttModel)]="htmlproc.has_padding_bottom" ttLabel="has_padding_bottom">
                                            </tt-checkbox>

                                            <tt-button-save ttText="Save" (click)="saveReport(htmlproc)"></tt-button-save>
                                        </div>
                                        <label>Ubrukte Elementer</label>
                                        <div class="htmlReportInvisible">
                                            <div class="htmlSection" cdkDropList
                                                 [class.list-with-border]="model.isDragging && model.currentHtmlProc === htmlproc"
                                                 [cdkDropListData]="getPlaceholderData(htmlproc,0)  || []"
                                                 (cdkDropListDropped)="drop($event, i, htmlproc)">
                                                <ng-container *ngFor="let item of getPlaceholderData(htmlproc,0);">
                                                    <ng-container *ngIf="item.visible === '0'">
                                                        <label class="htmlInvisibleLabel"
                                                               (cdkDragStarted)="dragStarted(htmlproc)"
                                                               (cdkDragEnded)="dragEnded()"
                                                               cdkDrag
                                                               [cdkDragData]="item"
                                                               [ngStyle]="{
                                                                'font-weight': item.bold === '1' ? 'bold' : 'normal',
                                                                'font-style': item.italic === '1' ? 'italic' : 'normal',
                                                                'text-align': item.textalign,
                                                                'font-size': item.fontsize,
                                                                'color': item.color || 'black',
                                                                'background-color': item.bg_color || 'transparent',
                                                                'font-family': item.font
                                                              }">
                                                            <!-- Conditionally show the image if has_image is 1 -->
                                                            <ng-container *ngIf="item.is_image === '1'; else textContent">
                                                                <ng-container *ngIf="htmlproc.presentation_type === 'form'">
                                                                    <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                                        <img [src]="item.stringvalue" alt="Image" style="max-width: 100%; max-height: 100%;">
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container *ngIf="htmlproc.presentation_type === 'table'">
                                                                    <span [innerHTML]="sanitizeHTML(item.colname_lang)"></span>

                                                                </ng-container>
                                                            </ng-container>

                                                            <!-- Else show the text content -->
                                                            <ng-template #textContent>
                                                                <ng-container *ngIf="htmlproc.presentation_type === 'table'">
                                                                    <span [innerHTML]="sanitizeHTML(item.colname_lang)"></span>

                                                                </ng-container>
                                                                <ng-container *ngIf="htmlproc.presentation_type === 'form'">
                                                                    <ng-container *ngIf="item.stringvalue && item.stringvalue.trim() !== ''">
                                                                        <span [innerHTML]="sanitizeHTML(item.stringvalue)"></span>
                                                                    </ng-container>

                                                                </ng-container>
                                                                
                                                            </ng-template>
                                                        </label>
                                                    </ng-container>

                                                </ng-container>

                                            </div>

                                        </div>

                                    </div>


                                </ng-container>


                            </div>


                        </div>
                        <div id="htmlStaticPreview" [ngStyle]="{ 'display': this.model.isFocus ? 'none' : 'block' }"
                             class="htmlForm">
                        </div>
                        <div class="editPanel">
                            <label id="editItem" [innerHTML]="this.model.sanitizedStringValue"></label>
                        </div>
                    </ng-container>
                </div>

            </tt-card>
            <tt-button class="col-xs-12 sp-0"
                       [text]="'print'"
                       (click)="this.save()">
            </tt-button>
        </form>
    </div>
</div>

