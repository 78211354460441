(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customerEdit', {
        templateUrl: 'views/components/views/customerEdit/customerEdit.template.html?v=' + module.version,
        bindings: {
            modalInstance: '<?',
            resolve: '<?'
        },
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'base64', 'utilityService', 'modalService', 'orderHeadService', 'customerEditService', 'stateService', 'economyService', 'logisticService', 'typeaheadService', 'modalListSelectFactory', function ($q, $stateParams, base64, utilityService, modalService, orderHeadService, customerEditService, stateService, economyService, logisticService, typeaheadService, modalListSelectFactory) {
            var vm = this;
            vm.version = module.version;

            vm.invalid = {};

            // BJS 20220928
            var addContactsAfterNewCustomer = false;

            vm.model = {
                custNo: $stateParams.cust_no,
                mobileSize: window.matchMedia('(min-width: 992px)'),
                lapTopSize: window.matchMedia('(min-width: 1440px)'),
                defaultCustNameBtnList: [
                    { id: 'customeredit', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: goTo } },
                ],
                //custGrpIdBtnList: [
                //    { id: 'list', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: () => gotoCustomerGroups() } },
                //],
                custBusinessIdBtnList: [
                    { id: 'list_item', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: { click: () => gotoBusinesss() } },
                ],
                accCustNoBtnList: [
                    { id: 'calculator', icon: 'fas fa-calculator', color: 'primary', type: 'primary', onClick: { click: () => goCalcAccCustNo() } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoCustomerbuscos },
                ],
                accSuppNoBtnList: [
                    { id: 'calculator', icon: 'fas fa-calculator', color: 'primary', type: 'primary', onClick: { click: () => goCalcAccSuppNo() } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoCustomerbuscos },
                ],
                btnListSellers: [
                    { id: 'sellers', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: goTo },
                ],
                btnListSearchStreet: [
                    { id: 'postplace', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: { click: goTo } },
                ],
                btnListSearchMail: [
                    { id: 'postplace', icon: 'glyphicon glyphicon-plus', color: 'primary', type: 'primary', onClick: { click: goTo } },
                ],
                customerGroupButtons: [
                    { id: 'list', icon: 'fa fa-solid fa-bars', color: 'primary', type: 'primary', onClick: () => stateService.go('custgrps', {}) },
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('custgrpmenu', { custgrp_id: vm.model.customer.custgrp_id }) }
                ],
                discGroupCustomerButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => vm.model.customer.discgrpcust_keyno = '0' }
                ],
                customerWorldWideWebButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-window-restore', color: 'primary', type: 'primary', onClick: () => openNewWindow() }
                ],
                bgCustomerEditMain: {
                    selected: 'ADDRESS',
                    buttons: [
                        { id: 'ADDRESS', label: 'bg_customeredit_main_address', color: 'primary', onClick: () => vm.model.bgCustomerEditMain.selected = 'ADDRESS' },
                        { id: 'TERMS', label: 'bg_customeredit_main_terms', color: 'primary', onClick: () => vm.model.bgCustomerEditMain.selected = 'TERMS' },
                        { id: 'OTHER', label: 'bg_customeredit_main_other', color: 'primary', onClick: () => vm.model.bgCustomerEditMain.selected = 'OTHER' }
                    ]
                },
                customer: {},
                custGroupList: [],
                countryList: [],
                valutaList: [],
                priceList: [],
                paymentList: [],
                taxList: [],
                listDeliveryMethod: [],
                listBusiness: [],
                listDeliveryTerm: [],
                p2LanguageList: {
                    records: []
                },
                selectListCoops: [],
                selectListBusinessCos: [],
                selectListInvoiceMethods: [],
                selectListSellers: [],
                selectListFactorings: [],
                selectListPortalUsers: [],
                selectListCustomerBonusDeals: [],
                selectListDiscGroupCustomers: [],
                selectListGroupingMethods: [],
                p2CounterNextNumberGet: {},
                loadComplete: false,
                autoInvoiceMethods: [],
                stockplaces: [],
                users: [],
                invoiceintervals: []
            };

            vm.options = {
                deliverytime_from_supplier: {
                    autoselect: true,
                    decimals: 0,
                    textAlign: 'right',
                },
                supplier_handling_days: {
                    autoselect: true,
                    decimals: 0,
                    textAlign: 'right',
                },
                bankaccount: {
                    autoselect: true,
                    decimals: 0,
                    textAlign: 'right',
                },
            }

            // #########################
            // ## BUTTON GROUP FUNCTIONS
            // #########################

            function createResultString(result) {
                let resultstring = result.navn;

                if (result?.forretningsadresse?.postnummer && result?.forretningsadresse?.poststed) {
                    resultstring += `, ${result.forretningsadresse?.postnummer} ${result.forretningsadresse?.poststed}`;
                }

                return `${resultstring}, Org.nr: ${result.organisasjonsnummer}`;
                //return `${result.navn}, ${result.forretningsadresse?.postnummer} ${result.forretningsadresse?.poststed}, Org.nr: ${result.organisasjonsnummer}`;
            }

            vm.dismiss = function () {
                vm.modalInstance.dismiss();
            }

            vm.searchBrreg = async function () {
                let data = [];

                if (parseInt(vm.model.customer.brreg_find_customer).toString() !== 'NaN' && vm.model.customer.brreg_find_customer.length > 8) {
                    data = (await (await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${parseInt(vm.model.customer.brreg_find_customer)}`))?.json())?._embedded?.enheter ?? [];
                } else {
                    data = (await (await fetch(`https://data.brreg.no/enhetsregisteret/api/enheter?navn=${vm.model.customer.brreg_find_customer}`))?.json())?._embedded?.enheter ?? [];
                }

                data.map((result) => result.resultnavn = createResultString(result));
                return data;
            }

            vm.onOrganisationSelected = function (item) {
                if (!item) return;

                vm.model.customer.cust_name = item.navn;
                vm.model.customer.cust_name_sort = item.navn;
                vm.model.customer.org_no = item.organisasjonsnummer;
                vm.model.customer.country_id = item.forretningsadresse.landkode.toLowerCase();
                vm.model.customer.cust_www = item?.hjemmeside ?? ''


                if (item?.postadresse) {
                    vm.model.customer.cust_adr_mail = item.postadresse.adresse?.[0] ?? '';
                    vm.model.customer.postplace_id = item.postadresse.postnummer;
                    vm.model.customer.postplace_name = item.postadresse.poststed;
                    vm.model.customer.postplace_search_mail = `${item.postadresse.postnummer} ${item.postadresse.poststed}`;
                } else if (item?.forretningsadresse) {
                    vm.model.customer.cust_adr_mail = item.forretningsadresse.adresse?.[0] ?? '';
                    vm.model.customer.postplace_id = item.forretningsadresse.postnummer;
                    vm.model.customer.postplace_name = item.forretningsadresse.poststed;
                    vm.model.customer.postplace_search_mail = `${item.forretningsadresse.postnummer} ${item.forretningsadresse.poststed}`;
                }

                if (item?.forretningsadresse) {
                    vm.model.customer.cust_adr_street = item.forretningsadresse.adresse?.[0] ?? '';
                    vm.model.customer.postplace_id_street = item.forretningsadresse.postnummer;
                    vm.model.customer.postplace_name_street = item.forretningsadresse.poststed;
                    vm.model.customer.postplace_search_street = `${item.forretningsadresse.postnummer} ${item.forretningsadresse.poststed}`;
                }
            }

            vm.selectBgCustomerEditMain = function (value) {
                vm.model.bgCustomerEditMain.selected = value;
            };

            customerEditService.loadCustGroupList().then(function (data) {
                angular.copy(data, vm.model.custGroupList);
            });

            customerEditService.loadDeliveryMethodList().then(function (data) {
                angular.copy(data, vm.model.listDeliveryMethod);
            });

            // JLR 20240613 made it into a function.
            async function getCustomer() {
                if (vm.model.custNo === undefined || vm.model.custNo === null) return;

                const data = await customerEditService.load(vm.model.custNo);
                // BJS 20220928
                angular.forEach(data, function (_, key) {
                    vm.invalid[key] = false;
                });

                if (angular.isUndefined(data.locked) === true) {
                    data.locked = '0'; // locked må vere definert 0 viss den ikkje er definert i ei prosedyre på sida
                }

                angular.copy(data, vm.model.customer);

                loadPortalUserPurchases();
                loadSellers();
                //loadInvoiceMethods();
                //vm.loadP2CounterNextNumberGet(true);
            }

            getCustomer();

            orderHeadService.loadInvoiceMethodSelectList().then(function (data) {
                angular.copy(data, vm.model.selectListInvoiceMethods);
            });

            //var loadInvoiceMethods = function () {
            //    vm.model.selectListInvoiceMethods = [];

            //    customerEditService.loadInvoiceMethodList().then(function (data) {
            //        angular.copy(data, vm.model.selectListInvoiceMethods);
            //    });
            //};

            // (21.12.2016)
            customerEditService.loadP2CountryList().then(function (data) {
                angular.copy(data, vm.model.countryList);
            });

            customerEditService.loadP2TaxList().then(function (data) {
                angular.copy(data, vm.model.taxList);
            });

            customerEditService.loadValutaList().then(function (data) {
                angular.copy(data, vm.model.valutaList);
            });

            customerEditService.loadPriceList().then(function (data) {
                angular.copy(data, vm.model.priceList);
            });

            customerEditService.loadPaymentList().then(function (data) {
                angular.copy(data, vm.model.paymentList);
            });

            customerEditService.loadBusinessList().then(function (data) {
                angular.copy(data, vm.model.listBusiness);
            });

            customerEditService.loadDeliveryTermList().then(function (data) {
                angular.copy(data, vm.model.listDeliveryTerm);
            });

            customerEditService.loadCoopList().then(function (data) {
                angular.copy(data, vm.model.selectListCoops);
            });

            customerEditService.loadBusinessCoList().then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCos);
            });

            economyService.listCustFactorings().then(function (data) {
                angular.copy(data, vm.model.selectListFactorings);
            });

            customerEditService.listCustomerBonusDeals().then(function (result) {
                angular.copy(result, vm.model.selectListCustomerBonusDeals);
            });

            var loadP2LanguageList = function () {
                customerEditService.loadP2LanguageList().then(function (data) {
                    angular.copy(data, vm.model.p2LanguageList.records);
                });
            };

            loadP2LanguageList();

            let loadDiscGroupCustomers = function () {
                customerEditService.listDiscGroupCustomers({ add_from_custgrp: '1' }).then(function (result) {
                    angular.copy(result, vm.model.selectListDiscGroupCustomers);
                });
            };

            let loadEhfGroupingMethods = function () {
                customerEditService.listEhfGroupingMethods().then(function (list) {
                    angular.copy(list, vm.model.selectListGroupingMethods);
                });
            };

            let loadSellers = function () {
                if (utilityService.validateParmsValue(vm.model.customer.businessco_no) !== true) return;

                return economyService.listSellers({ businessco_no: vm.model.customer.businessco_no }).then(function (result) {
                    angular.copy(result, vm.model.selectListSellers);
                });
            };

            var loadPortalUserPurchases = function () {
                if (utilityService.validateParmsValue(vm.model.customer.businessco_no) !== true) return;

                var parms = {
                    businessco_no: vm.model.customer.businessco_no
                };

                vm.model.selectListPortalUsers = [];

                logisticService.listPortalUserPurchases(parms).then(function (data) {
                    angular.copy(data, vm.model.selectListPortalUsers);
                });
            };

            let openNewWindow = function () {
                if (vm.model.customer.cust_www > '') {
                    if (vm.model.customer.cust_www.substr(0, 7) === 'http://' || vm.model.customer.cust_www.substr(0, 8) === 'https://') {
                        window.open(vm.model.customer.cust_www);
                    } else {
                        window.open('https://' + vm.model.customer.cust_www);
                    }
                }
            };

            // ## IF CONDITIONS FUNCTION ##

            var validateParms = function (parm) {
                if (angular.isUndefined(vm.model.customer[parm]) === true) return false;
                if (vm.model.customer[parm] === null) return false;
                if (vm.model.customer[parm].trim().length < 1) return;

                return true;
            };

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            var changeCustGroupId = function () {
                if (validateParms('custgrp_id') !== true) return;

                customerEditService.changeCustGrpId(vm.model.customer).then(function (data) {
                    vm.model.customer.cust_order_valuta_id = data[0].cust_order_valuta_id;
                    vm.model.customer.cust_invoice_valuta_id = data[0].cust_invoice_valuta_id;
                    vm.model.customer.pricelist_id = data[0].pricelist_id;
                    vm.model.customer.pricelist_id2 = data[0].pricelist_id2;
                    vm.model.customer.purchase_pricelist_id = data[0].purchase_pricelist_id;
                    vm.model.customer.purchase_pricelist_id2 = data[0].purchase_pricelist_id2;
                    vm.model.customer.cust_paym_id = data[0].cust_paym_id;
                    vm.model.customer.tax_keyno = data[0].tax_keyno;
                    vm.model.customer.cust_delm_no = data[0].cust_delm_no;
                    vm.model.customer.cust_delt_no = data[0].cust_delt_no;
                });
            };

            vm.typeaheadSearchCustomers = function (value, field) {
                console.dir(vm.model.customer);
                return typeaheadService.searchCustomer({
                    cust_name: value,
                    filtertype: '',
                    filtervalue: '',
                    custtype: field === 'delivery_cust_name' ? vm.model.customer.delivery_customer_search_type ?? 'C' : 'C',
                    lastselect: 'SEARCH'
                });
            };

            vm.typeaheadSearch = function (value, type) {
                var deferred = $q.defer();

                customerEditService.search(value).then(function (response) {
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].postplace_id === value || response[i].postplace_name === value || response[i].postplace_id_and_name === value) {
                            if (type === 'street') {
                                console.log('street search');
                                vm.onPostplaceStreetSearchSelected(response[i]);
                            } else {
                                vm.onPostplaceMailSearchSelected(response[i]);
                            }
                            break;
                        }
                    }

                    deferred.resolve(response);
                });

                return deferred.promise;
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                if (vm.model.customer.locked === '1') return;
                vm.model.customer.locked = '1';

                angular.forEach(vm.invalid, function (_, key) {
                    vm.invalid[key] = false;
                });

                // BJS 20221109
                var isNewCustomer = vm.model.customer.cust_no === '0';

                customerEditService.save(vm.model.customer).then(function (data) {
                    if (data.error === true) {
                        angular.forEach(data.errors, function (value) {
                            vm.invalid[value.column] = true;
                        });

                        var modalListSelect = modalListSelectFactory.$create({
                            list: data.errors,
                            convert: {
                                id: 'key',
                                name: 'message',
                                order: 'key'
                            },
                            title: 'Error',//vm.title.modal,
                            sort: true,
                            searchable: false,
                            selectable: false,
                            hideIcon: true
                        });

                        modalListSelect.show().then(function () {
                            vm.model.customer.locked = '0';
                        });
                    } else {
                        if (vm.model.customer.show_confirm_save_ok_always === '1') {
                            modalService.show({
                                type: 'success',
                                title: 'Melding',
                                message: data.message,
                                buttons: [{
                                    label: 'OK',
                                    cssClass: 'btn-success',
                                    action: function (dialogItself) {
                                        dialogItself.close();

                                        vm.model.customer.locked = '0';

                                        if (isNewCustomer === true && addContactsAfterNewCustomer === true) {
                                            stateService.go('customerpersons', { cust_no: data.cust_no, new_customer: true });
                                        } else {
                                            if (stateService.getCurrentName() === 'customeredit') {
                                                stateService.back();
                                            }
                                        }
                                    }
                                }]
                            });
                        } else {
                            vm.model.customer.locked = '0';

                            if (!!vm.modalInstance) {
                                vm.modalInstance.close(data.cust_no);
                            } else if  (isNewCustomer === true && addContactsAfterNewCustomer === true) {
                                stateService.go('customerpersons', { cust_no: data.cust_no, new_customer: true });
                            } else {
                                if (stateService.getCurrentName() === 'customeredit') {
                                    stateService.back();
                                }
                            }
                        }
                    }
                });
            };

            var countAccCustNo = function () {
                if (vm.model.customer.is_customer === '1' && (vm.model.customer.acc_cust_no === '' || vm.model.customer.acc_cust_no === '0')) {
                    customerEditService.getNextCounterNumber('acc_cust_no', vm.model.customer.cust_no).then(function (data) {
                        vm.model.customer.acc_cust_no = data[0].nextnumber;
                    });
                }
            };

            let retrieveNewAccCustNo = function () {
                if (vm.model.customer.is_customer === '1') {
                    customerEditService.assignNewAccCustNo(vm.model.customer).then(function (response) {
                        vm.model.customer.acc_cust_no = response[0].acc_cust_no;
                    });
                }
            };

            var countAccSuppNo = function () {
                if (vm.model.customer.is_supplyer === '1' && (vm.model.customer.acc_supp_no === '' || vm.model.customer.acc_supp_no === '0')) {
                    customerEditService.getNextCounterNumber('acc_supp_no', vm.model.customer.cust_no).then(function (data) {
                        vm.model.customer.acc_supp_no = data[0].nextnumber;
                    });
                }
            };

            // ## SHOW CALCULATOR FUNCTIONS	##

            var goCalcAccCustNo = function () {
                modalService.showCalculator(vm.model.customer.acc_cust_no).then(function (value) {
                    vm.model.customer.acc_cust_no = value;
                });
            };

            var goCalcAccSuppNo = function () {
                modalService.showCalculator(vm.model.customer.acc_supp_no).then(function (value) {
                    vm.model.customer.acc_supp_no = value;
                });
            };

            // #region BUTTON LIST FUNCTIONS

            function gotoCustomerbuscos(_, e) {
                let state = 'customerbuscos';

                console.log('click');
                console.dir(_);
                console.dir(e);

                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                go({ cust_no: vm.model.customer.cust_no });
            }

            let gotoBusinesss = function () {
                stateService.go('businesss', {});
            }

            let gotoCustomerGroups = function () {
                stateService.go('custgrps', {});
            }

            // #endregion BUTTON LIST FUNCTIONS

            // ## DIRECTION BUTTON FUNCTIONS ##

            function goTo(btn, e) {
                let state = btn.id;

                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'customeredit':
                        go({ cust_no: vm.model.customer.default_cust_no });
                        break;
                    case 'sellers':
                        go();
                        break;
                    case 'postplace':
                        go({ postplace_id: '0' });
                        break;
                }
            };

            vm.$onInit = function () {
                if (vm.resolve) {
                    console.log(vm.resolve);
                    vm.model.custNo = vm.resolve.custNo;
                    getCustomer();
                }

                customerEditService.init().then(function (response) {
                    addContactsAfterNewCustomer = response.addContactsAfterNewCustomer;

                    angular.forEach(response.autoinvoicemethods, function (value) {
                        value.autoinvoicemethod_keyno = value.autoinvoicemethod_keyno.toString();
                        value.autoinvoicemethod_name = base64.urldecode(value.autoinvoicemethod_name);
                    });

                    angular.forEach(response.stockplaces, function (value) {
                        value.stockplace_name = base64.urldecode(value.stockplace_name);
                    });

                    angular.forEach(response.users, function (value) {
                        value.user_name = base64.urldecode(value.user_name);
                    });

                    angular.forEach(response.invoiceintervals, function (value) {
                        value.invoiceinterval_keyno = value.invoiceinterval_keyno.toString();
                        value.invoiceinterval_name = base64.urldecode(value.invoiceinterval_name);
                    });

                    angular.copy(response.autoinvoicemethods, vm.model.autoInvoiceMethods);
                    angular.copy(response.stockplaces, vm.model.stockplaces);
                    angular.copy(response.users, vm.model.users);
                    angular.copy(response.invoiceintervals, vm.model.invoiceintervals);
                });

                loadDiscGroupCustomers();
                loadEhfGroupingMethods();
            };

            // BJS 20221109
            vm.onCustomerChanged = function (value, modelId) {
                var parts = modelId.split('.');

                var id = parts[parts.length - 1];

                vm.model.customer[id] = value;
            };

            vm.onBusinessCompanyChanged = function (value) {
                vm.model.customer.businessco_no = value;

                if (value) {
                    loadSellers();
                    loadPortalUserPurchases();
                }
            }

            vm.onCustomerGroupChanged = function (value) {
                vm.model.customer.custgrp_id = value;

                if (value) changeCustGroupId();
            }

            vm.onCustomerNameChanged = function (value) {
                vm.model.customer.cust_name = value;

                if (value) {
                    vm.model.customer.cust_name_sort = value;
                }
            }

            vm.onIsCustomerChanged = function (value) {
                vm.model.customer.is_customer = value;

                //if (value) countAccCustNo();
                if (value) retrieveNewAccCustNo();
            }

            vm.onIsSupplyerChanged = function (value) {
                vm.model.customer.is_supplyer = value;

                if (value) countAccSuppNo();
            }

            vm.onPostplaceStreetSearchSelected = function (item) {
                console.dir(item);
                vm.model.customer.postplace_id_street = item.postplace_id;
                vm.model.customer.postplace_name_street = item.postplace_name;
                vm.model.customer.postplace_search_street = item.postplace_id_and_name;

                if (vm.model.customer.postplace_id === '') {
                    vm.model.customer.postplace_id = item.postplace_id;
                    vm.model.customer.postplace_name = item.postplace_name;
                    vm.model.customer.postplace_search_mail = item.postplace_id_and_name;
                }
            }

            vm.onPostplaceMailSearchSelected = function (item) {
                vm.model.customer.postplace_id = item.postplace_id;
                vm.model.customer.postplace_name = item.postplace_name;
                vm.model.customer.postplace_search_mail = item.postplace_id_and_name;

                if (vm.model.customer.postplace_id_street === '') {
                    vm.model.customer.postplace_id_street = item.postplace_id;
                    vm.model.customer.postplace_name_street = item.postplace_name;
                    vm.model.customer.postplace_search_street = item.postplace_id_and_name;
                }
            }

            vm.onCustomerInvoiceNameClear = function () {
                vm.model.customer.invoice_cust_no = '0';
                vm.model.customer.invoice_cust_name = '';
            }

            vm.onCustomerInvoiceSelected = function (item) {
                vm.model.customer.invoice_cust_no = item.item_id;
                vm.model.customer.invoice_cust_name = item.item_name;
            }

            vm.onDeliveryCustomerNameClear = function () {
                vm.model.customer.delivery_cust_no = '0';
                vm.model.customer.delivery_cust_name = '';
            }

            vm.onDeliveryCustomerNameSelected = function (item) {
                vm.model.customer.delivery_cust_name = item.item_name;
                vm.model.customer.delivery_cust_no = item.item_id;
            }

            // #endregion WATCH FUNCTIONS
        }]
    });
})();
