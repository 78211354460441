(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('selectTextileModel', {
        templateUrl: 'views/components/views/selectTextileModel/selectTextileModel.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'utilityService', 'selectTextileModelService', function ($stateParams, $q, utilityService, selectTextileModelService) {

            // ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let orderInternalNo = $stateParams.order_internal_no;
            let orderLineKeyNo = $stateParams.orderline_keyno;
            
            vm.model = {
                searchTextButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('searchtext') },
                    { id: 'search', icon: 'glyphicon glyphicon-search', color: 'primary', type: 'primary', onClick: () => loadTextileModels() }
                ],
                setting: {},
                itemsListTextiles: []
            };
            
            // ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                let deferred = $q.defer();

                selectTextileModelService.getSetting({
                    order_internal_no: orderInternalNo,
                    orderline_keyno: orderLineKeyNo
                }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadTextileModels()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };
            
            let loadTextileModels = function () {
                if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;
                
                vm.model.itemsListTextiles = [];
                
                return selectTextileModelService.listTextileModels(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListTextiles);
                });
            };

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'searchtext':
                        vm.model.setting.searchtext = '';

                        if (vm.model.setting.searchtext === '') loadTextileModels();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // ## BUTTON LIST FUNCTIONS ##
            
            vm.searchTextiles = function () {
                loadTextileModels();
            };

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'searchtext':
                        if (utilityService.validateWatchValue(value, vm.model.setting.searchtext) !== true) return;

                        vm.model.setting.searchtext = value;

                        if (utilityService.validateParmsValue(vm.model.setting.searchtext, true) !== true) return;

                        if (vm.model.setting.searchtext === '') loadTextileModels();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
