(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("printService", ['$q', 'iePrintService', function ($q, iePrintService) {
        var methodProxy = function (printService, method, params) {
            var deferred = $q.defer();

            params = params || {};
            params.url = params.url || '';

            if (params.url.length < 1) {
                params.url = printService.url;
            }
            
            iePrintService[method].apply(iePrintService, [params]).then(function (result) {
                deferred.resolve(result);
            });

            return deferred.promise;
        };

        var service = {
            url: '',
            
            base64Report: function (params) {
                return methodProxy(service, 'base64report', params);
            },

            // ***********************************************************************************
            // If you are not using paging there is no need for a params object.
            //  params: { 
            //      page: 1,        optional
            //      pageSize: 10    optional
            //  }
            // ***********************************************************************************
            getReports: function (params) {
                var deferred = $q.defer();

                methodProxy(service, 'getReports', params).then(function (data) {
                    deferred.resolve(data);
                });

                return deferred.promise;
                //return methodProxy(service, 'getReports', params);
            },

            // ***********************************************************************************
            // If you are not using paging there is no need for a params object.
            //  params: { 
            //      page: 1,        optional
            //      pageSize: 10    optional
            //  }
            // ***********************************************************************************
            getPrinters: function (params) {
                return methodProxy(service, 'getPrinters', params);
            },

            // ***********************************************************************************
            // Params must contain the keyno of the report you want to get the parameters for.
            //  params: { 
            //      reportKeyno: 1234   required
            //  }
            // ***********************************************************************************
            getParameters: function (params) {
                return methodProxy(service, 'getParameters', params);
            },

            preview: function (params) {
                return methodProxy(service, 'preview', params);
            },

            previewBook: function (params) {
                return methodProxy(service, 'previewBook', params);
            },

            // ***********************************************************************************
            //  params: { 
            //      reportKeyno: 1234,      required
            //      printerKeyno:   1234,   required
            //      parameters: {},         optional/required depending on reportKeyno
            //      printerProperties: {}   optional, see Insoft.Print -> Reporting PdfPrintProperties for possible properties.
            //  }
            // ***********************************************************************************
            print: function (params) {
                return methodProxy(service, 'print', params);
            },

            // ***********************************************************************************
            //  reportKeyno and reportId are both optional but one of them is required.
            //  params: { 
            //      reportKeyno: 1234,      optional
            //      reportId: 'abc.trdp'    optional
            //      instanceId: 'abcd'      optional
            //      parameters: {},         optional/required depending on reportKeyno/reportId
            //      printerProperties: {}   optional, see PrintCommon -> Reporting PdfPrintProperties for possible properties.
            //  }
            // ***********************************************************************************
            archive: function (params) {
                return methodProxy(service, 'archive', params);
            },

            // ***********************************************************************************
            // Params must contain the keyno of a file that is already printed.
            //  params: { 
            //      fileKeyno: 1234     required
            //  }
            // ***********************************************************************************
            getReportUrl: function (params) {
                return methodProxy(service, 'getReportUrl', params);
            },

            // ***********************************************************************************
            // If you are not using paging there is no need for a params object.
            //  params: { 
            //      page: 1,        optional
            //      pageSize: 10    optional
            //  }
            // ***********************************************************************************
            getClients: function (params) {
                return methodProxy(service, 'getClients', params);
            },

            getClientDetails: function (params) {
                return methodProxy(service, 'getClientDetails', params);
            },

            getClientPrinters: function (params) {
                return methodProxy(service, 'getClientPrinters', params);
            },

            getClientStatus: function (params) {
                return methodProxy(service, 'getClientStatus', params);
            },

            getPrinterDetails: function (params) {
                return methodProxy(service, 'getPrinterDetails', params);
            }
        };
        
        return service;
    }]);
})();
