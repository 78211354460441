(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("selectLabourService", ['$q', '$ihttp', function ($q, $ihttp) {
        var service = {
            loaded: false,
            select_labour: {
                item_id: '',
                item_name: '',
                item_parms: '',
                item_state: '',
                records: []
            },
            loadSelectLabourList: function (selectedItem) {
                var deferred = $q.defer();

                var parms = {
                    method: 101,
                    parameters: {
                        activestatus: selectedItem
                    }
                };

                $ihttp.post(parms).then(function (data) {
                    service.select_labour.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.select_labour.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();