(function () {
	'use strict';

    var imApp = angular.module('imApp');

    imApp.component('batchDelReceiveLine', {
        templateUrl: 'views/components/views/batchDelReceiveLine/batchDelReceiveLine.template.html?v=' + imApp.version,
		controllerAs: 'vm',
		controller: ['$stateParams', 'stateService', 'modalService', 'batchDelReceiveLineService', function($stateParams, stateService, modalService, batchDelReceiveLineService) {
            var vm = this;

            vm.model = {
                batchDelReceiveLineKeyNo: $stateParams.batchdelreceiveline_keyno,
                receiveLine: {},
                lockedDelete: false
            };

            batchDelReceiveLineService.loadBatchDelReceiveLineGet(vm.model.batchDelReceiveLineKeyNo).then(function (data) {
                vm.model.receiveLine = angular.copy(data[0]);
            });

            vm.deleteBatchDelReceiveLine = function () {
                vm.model.lockedDelete = true;
                
                batchDelReceiveLineService.deleteObj(vm.model.receiveLine).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedDelete = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedDelete = false;
                    }
                });
            };
		}]
	});
})();