(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('srTravelBill', {
        templateUrl: 'views/components/views/srTravelBill/srTravelBill.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'modalService', 'consignmentPlansService', 'srTravelBillService', function($stateParams, stateService, utilityService, modalService, consignmentPlansService, srTravelBillService) {
    
            var vm = this;
    
    
    
            // ## VARIABLES & DEFINITIONS ##
    
            var resizing = false;
    
            vm.model = {
                travelBillNo: $stateParams.travelbill_no,
                //bgSrTravelBillView: {
                //    item_id: '',
                //    item_name: '',
                //    item_default: '0',
                //    item_selected: '0',
                //    records: [
                //        { item_id: '0', item_name: 'gb_srtravelbill_summary', item_func: 'SUMMARY' },
                //        { item_id: '1', item_name: 'srtravelbill_prreport', item_func: 'PRREPORT' }
                //    ]
                //},
                mconfig: {
                    enabled: true,
                    show: true,
                    activate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = true;
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = true;
                        } else {
                            vm.model.mconfig.records[4].isActive = true;
                        }
                    },
                    deactivate: function (item) {
                        if (item === 'all') {
                            angular.forEach(vm.model.mconfig.records, function (data) {
                                if (angular.isDefined(data)) {
                                    if (data.canActive === true) {
                                        data.isActive = false;
                                    }
    
                                    /* RESIZING COLUMNS */
                                    if (data.item_name === 'resize' && data.isActive) {
                                        vm.resizeSummary();
                                    }
                                }
                            });
                        } else if (angular.isDefined(item)) {
                            vm.model.mconfig.records[item.item_id].isActive = false;
                        //} else if (angular.isDefined(item)) {
                        //	for (var r = 0; r < vm.model.mconfig.records.length; r++) {
                        //		if (vm.model.mconfig.records[r].item_name === item) {
                        //			vm.model.mconfig.records[r].isActive = false;
                        //			break;
                        //		}
                        //	}
                        } else {
                            vm.model.mconfig.records[4].isActive = false;
                        }
                    },
                    function: function (item) {
                        if (item.canActive === true) {
                            item.isActive = !item.isActive;
                        }
    
                        /* RESIZING COLUMNS */
                        if (item.item_name === 'resize') {
                        	if (item.isActive) {
                        		resizing = true;
                        	} else {
                                vm.resizeSummary();
                        	}
                        }
    
                        /* EDITING COLUMNS */
                        //if (item.item_name === 'edit') {
                        //	if (item.isActive) {
                        //		editing = true; //var editing = false;
                        //	} else {
                        //		vm.saveChanges();
                        //	}
                        //}
    
                        /* OPEN & CLOSE TOOLBAR */
                        if (item.item_id === '0') {
                        	if (item.isActive === true) {
                        		vm.model.mconfig.records[1].show = true;
                        		vm.model.mconfig.records[2].show = true;
                        	} else {
                        		vm.model.mconfig.records[1].show = false;
                        		vm.model.mconfig.records[2].show = false;
                        	}
                        }
                    },
                    records: [
                        { item_id: '0', item_name: 'option', glyph: 'glyphicon-option-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', enabled: true, show: false },
                        { item_id: '1', item_name: 'plus', glyph: 'glyphicon-plus', color: 'primary', show: false },
                        { item_id: '2', item_name: 'minus', glyph: 'glyphicon-minus', color: 'primary', show: false },
                        { item_id: '3', item_name: 'trash', glyph: 'glyphicon-trash', color: 'primary', show: false },
                        { item_id: '4', item_name: 'sort', glyph: 'glyphicon-sort', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: true, show: true },
                        { item_id: '5', item_name: 'resize', glyph: 'glyphicon-resize-horizontal', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '6', item_name: 'edit', glyph: 'fa-edit', color: 'primary', canActive: true, isActive: false, colorActive: 'warning', isIcon: false, enabled: false, show: true },
                        { item_id: '99', item_name: 'save', glyph: 'glyphicon-save', color: 'primary', animate: null, isIcon: true, enabled: false, show: true }
                    ]
                },
                claimRepayment: {},
                itemsListSummary: [],
                lockedApprove: false,
                lockedPay: false,
                updatingRepayment: false
            };
    
            // ## LOAD PROCEDURE CALLS ##
    
            srTravelBillService.loadSrTravelBillGet(vm.model.travelBillNo).then(function (data) {
                vm.model.claimRepayment = angular.copy(data[0]);
    
                loadSrExpensesSummary();
            });
    
            var loadSrExpensesSummary = function () {
                if (utilityService.validateParmsValue(vm.model.claimRepayment.travelbill_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.claimRepayment.view_id) !== true) return;
    
                vm.model.itemsListSummary = [];
    
                srTravelBillService.loadSrExpensesSummaryList(vm.model.claimRepayment.travelbill_no, vm.model.claimRepayment.view_id).then(function (data) {
                    angular.copy(data, vm.model.itemsListSummary);
                });
            };
    
            // ## APPROVE BUTTON FUNCTION ##
    
            vm.saveApprovement = function () {
                vm.model.lockedApprove = true;
    
                srTravelBillService.approve(vm.model.claimRepayment.travelbill_no).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedApprove = false;
                                }
                            }]
                        });
                    } else {
                        stateService.back();
                        vm.model.lockedApprove = false;
                    }
                });
            };
    
            // ## PAY BUTTON FUNCTION ##
    
            vm.savePayment = function () {
                vm.model.lockedPay = true;
    
                srTravelBillService.pay(vm.model.claimRepayment.travelbill_no).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedPay = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedPay = false;
                        stateService.back();
                    }
                });
            };
    
            // ## UPDATE BUTTON FUNCTION ##
    
            vm.updateRepayment = function () {
                vm.model.updatingRepayment = true;
    
                srTravelBillService.update(vm.model.claimRepayment.travelbill_no).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.updatingRepayment = false;
                                    loadSrExpensesSummary();
                                }
                            }]
                        });
                    } else {
                        vm.model.updatingRepayment = false;
                        loadSrExpensesSummary();
                    }
                });
            };
    
            // ## DIRECTION BUTTON FUNCTIONS ##
    
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
    
                switch (state) {
                    case 'sroperation':
                        go({
                            custact_no: vm.model.claimRepayment.custact_no
                        });
                        break;
                    case 'travelbilllogs':
                        go({
                            travelbill_no: vm.model.claimRepayment.travelbill_no
                        });
                        break;
                    case 'travelbillreject':
                        go({
                            travelbill_no: vm.model.claimRepayment.travelbill_no
                        });
                        break;
                    case 'srreportmenu':
                        go({
                            checklistdata_keyno: vm.model.claimRepayment.checklistdata_keyno
                        });
                        break;
                    case 'srrefundcreate':
                        go({
                            custact_no: vm.model.claimRepayment.custact_no,
                            checklistdata_keyno: vm.model.claimRepayment.checklistdata_keyno
                        });
                        break;
                }
            };
    
            // ## BUTTON GROUP FUNCTIONS ##
    
            //vm.selectBgSrTravelBillView = function (item) {
            //    vm.model.bgSrTravelBillView.item_selected = item;
            //};
    
            // ## RESIZE COLUMN WIDTH FUNCTION PROCEDURE CALLS ##
    
            vm.resizeSummary = function () {
                for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                    if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                        vm.model.mconfig.records[m].animate = true;
                        break;
                    }
                }
    
                var updateList = [];
    
                if (angular.isDefined(vm.model.itemsListSummary[0]) && angular.isDefined(vm.model.itemsListSummary[0].item_secondary)) {
                    for (var r = 0; r < vm.model.itemsListSummary[0].item_secondary.length; r++) {
                        if (r === 0) {
                            updateList.push({ item_id: r, item_col_width: vm.model.itemsListSummary[0].item_col_width })
                        }
                        if (angular.isDefined(vm.model.itemsListSummary[0].item_secondary[r])) {
                            updateList.push({ item_id: r + 1, item_col_width: vm.model.itemsListSummary[0].item_secondary[r].item_col_width })
                        }
                    }
                }
    
                var updateParms = {
                    view_id: vm.model.claimRepayment.view_id,
                    records: updateList
                };
    
                consignmentPlansService.updateConsignmentPlans(updateParms).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                }
                            }]
                        });
                    } else {
                        for (var m = 0; m < vm.model.mconfig.records.length; m++) {
                            if (angular.isDefined(vm.model.mconfig.records[m]) && vm.model.mconfig.records[m].item_id === '99') {
                                vm.model.mconfig.records[m].animate = false;
                                break;
                            }
                        }
    
                        $timeout(function () {
                            vm.model.mconfig.records[m].animate = null;
                        }, 2000);
                    }
    
                    resizing = false;
                });
            };
        }]
    });
})();