(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttItemSizeManager', {
        templateUrl: 'views/components/directives/ttItemSizeManager/ttItemSizeManager.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttInfo: '<',
        },
        controller: ['$element', '$uibModal', 'layoutService', 'eventService', 'utilityService', function ($element, $uibModal, layoutService, eventService, us) {
            var vm = this;

            var onDestroy = [];

            vm.layout = {
                icon: {
                    style: {
                        fontSize: ''
                    }
                }
            };

            var toggleButtonClass = function () {
                if (angular.isUndefined(vm.ttInfo.sizes)) {
                    vm.ttInfo.sizes = '';
                }

                if (vm.ttInfo.sizes.length > 0) {
                    $element.addClass('item-active-on-group-icon');
                } else {
                    $element.removeClass('item-active-on-group-icon');
                }
            };

            var toggle = function (info) {
                if (info.group_id !== vm.ttInfo.group_id)
                    return;
                if (info.field_id !== vm.ttInfo.field_id)
                    return;

                vm.ttInfo.enabled = info.enabled;

                if (info.sizes !== vm.ttInfo.sizes) {
                    angular.copy(info.sizes, vm.ttInfo.sizes);
                }

                if (us.toBoolean(info.enabled)) {
                    $element.removeClass('ng-hide');
                } else {
                    $element.addClass('ng-hide');
                }

                toggleButtonClass();
            };

            onDestroy.push(eventService.on('ttItemService:fieldInfoToggle', function (info) {
                toggle(info);
            }));

            $element.find('button').on('click', function (event) {
                event.stopPropagation();
                event.preventDefault();

                var uibOnClose = [];

                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: 'views/components/directives/ttItemSizeManager/ttItemSizeManager.modal.html',
                    controllerAs: 'uib',
                    controller: ['translateService', 'eventService', 'layoutService', function (translateService, eventService, layoutService) {
                        var uib = this;

                        uib.translations = {
                            itemsize_title: '',
                            itemsize_close: ''
                        };

                        uib.sizes = [
                            { id: 'xx', icon: '', style: '' },
                            { id: 'xs', icon: '', style: '' },
                            { id: 'sm', icon: '', style: '' },
                            { id: 'md', icon: '', style: '' },
                            { id: 'lg', icon: '', style: '' },
                            { id: 'xl', icon: '', style: '' },
                            { id: 'xxl', icon: '', style: '' },
                        ];

                        uib.fontSize = '';

                        var setIconAndStyle = function () {
                            if (angular.isUndefined(vm.ttInfo.sizes)) {
                                vm.ttInfo.sizes = '';
                            }

                            var partsIndex = {};
                            var parts = vm.ttInfo.sizes.split(' ');

                            angular.forEach(parts, function (size, index) {
                                partsIndex[size] = index;
                            });

                            angular.forEach(uib.sizes, function (size) {
                                var color = 'red';
                                var icon = 'glyphicon-unchecked';

                                if (angular.isDefined(partsIndex[size.id])) {
                                    color = 'green';
                                    icon = 'glyphicon-check';
                                }

                                size.icon = icon;
                                size.style = { 'color': color, 'fontSize': uib.fontSize };
                            });
                        };

                        layoutService.onLayoutChanged(uibOnClose, function (layoutInfo) {
                            if (angular.isUndefined(layoutInfo)) return;

                            uib.fontSize = layoutInfo.fontSizes.textSize;

                            setIconAndStyle();
                        });

                        var deregTranslations = translateService.on(uib.translations, 'changed', function (result) {
                            deregTranslations();

                            angular.copy(result, uib.translations);
                        });

                        uib.toggle = function (size) {
                            var ttItemId = angular.isDefined(vm.ttInfo.ttItemId)
                                ? vm.ttInfo.ttItemId
                                : vm.ttInfo.group_id + '.' + vm.ttInfo.field_id;

                            if (angular.isUndefined(vm.ttInfo.sizes)) {
                                vm.ttInfo.sizes = '';
                            }

                            var parts = vm.ttInfo.sizes.split(' ');

                            var sizes = '';
                            var exists = false;

                            for (var i = 0; i < parts.length; i++) {
                                if (parts[i] === size.id) {
                                    exists = true;

                                    continue;
                                }

                                if (sizes.length > 0) {
                                    sizes += ' ';
                                }

                                sizes += parts[i];
                            }

                            if (exists !== true) {
                                if (sizes.length > 0) {
                                    sizes += ' ';
                                }

                                sizes += size.id;
                            }

                            vm.ttInfo.sizes = sizes;

                            layoutService.toggleItemSizeFieldInfo(ttItemId, vm.ttInfo.sizes).then(function () {
                                setIconAndStyle();

                                toggleButtonClass();

                                eventService.trigger('ttItemSizeManager:toggle', {
                                    size: size,
                                    field: vm.ttInfo
                                });
                            });
                        };

                        uib.close = function () {
                            modalInstance.close();
                        };
                    }]
                });

                var onModalClose = function () {
                    eventService.trigger('ttItemSizeManager:close', {
                        field: vm.ttInfo
                    });

                    angular.forEach(uibOnClose, function (fn) {
                        if (angular.isFunction(fn) === true) {
                            fn();
                        }
                    });
                };

                modalInstance.result.then(onModalClose, onModalClose);
            });

            layoutService.onLayoutChanged(onDestroy, function (layoutInfo) {
                if (angular.isUndefined(layoutInfo)) return;

                vm.layout.fontSize = layoutInfo.fontSizes.textSize;
            });

            vm.$onChanges = function (changes) {
                if (angular.isDefined(changes.ttInfo)) {
                    toggle(vm.ttInfo);
                }
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };
        }]
    });
})();
