(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custactWorks', {
        templateUrl: 'views/components/views/custactWorks/custactWorks.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'custactWorksService', 'stateService', 'economyService', function ($stateParams, custactWorksService, stateService, economyService) {
            let vm = this;
            let custactNo = $stateParams.custact_no;
            
            vm.model = {
                setting: {},
                itemsListJobs: []
            };
            
            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                economyService.getCustomerActivity({ custact_no: custactNo }).then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                });
            };

            let loadJobs = function () {
                custactWorksService.listJobs({ custact_no: custactNo }).then(function (result) {
                    angular.copy(result, vm.model.itemsListJobs);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'custactwork':
                        go({
                            custact_no: custactNo,
                            custactwork_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS
            
            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadJobs();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
