(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('prodSizeEdit', {
        templateUrl: 'views/components/views/prodSizeEdit/prodSizeEdit.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'prodSizeEditService', function ($stateParams, $q, stateService, utilityService, prodSizeEditService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let prodSizeKeyNo = $stateParams.prodsize_keyno;
            
            vm.model = {
                bgColli: {
                    selected: 'COLLIINPUT',
                    buttons: [
                        { id: 'COLLIINPUT', label: 'bg_colli_colliinput', color: 'primary', onClick: () => vm.model.bgColli.selected = 'COLLIINPUT' },
                        { id: 'COLLIEXAMPLE', label: 'bg_colli_colliexample', color: 'primary', onClick: () => vm.model.bgColli.selected = 'COLLIEXAMPLE' }
                    ]
                },
                edit: {},
                itemsListCollis: [],
                itemsListNobbs: [],
                itemsListColliExamples: [],
                lockedSave: false,
                creating: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL

            let loadEdit = function () {
                const deferred = $q.defer();

                prodSizeEditService.getEdit({ prodsize_keyno: prodSizeKeyNo }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);

                    if (vm.model.edit.nbr_of_prodsizecolli === '0' && vm.model.edit.auto_goto_prodsizecolli === '1') {
                        stateService.go('prodsizecolli', {
                            prodsize_keyno: vm.model.edit.prodsize_keyno,
                            prodsizecolli_keyno: '0'
                        });
                    }

                    loadCollis();
                    loadNobbs();
                    loadColliExamples();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadCollis = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;

                return prodSizeEditService.listCollis({ prodsize_keyno: vm.model.edit.prodsize_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListCollis);
                });
            };

            let loadNobbs = function () {
                if (utilityService.validateParmsValue(vm.model.edit.prodsize_keyno) !== true) return;

                return prodSizeEditService.listNobbs({ prodsize_keyno: vm.model.edit.prodsize_keyno }).then(function (result) {
                    angular.copy(result, vm.model.itemsListNobbs);
                });
            };

            let loadColliExamples = function () {
                if (utilityService.validateParmsValue(vm.model.edit.colliexampleqty, true) !== true) return;

                vm.model.itemsListColliExamples = [];

                return prodSizeEditService.listColliExamples(vm.model.edit).then(function (result) {
                    vm.model.creating = false;
                    angular.copy(result, vm.model.itemsListColliExamples);
                });
            };

            // #endregion LOAD PROCEDURE CALL

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgColli = function (value) {
                vm.model.bgColli.selected = value;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                prodSizeEditService.saveObj(vm.model.edit).then(function () {
                    vm.model.lockedSave = false;
                    stateService.back();
                }, function () {
                    vm.model.lockedSave = false;
                });
            };

            // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON CREATE COLLI EXAMPLE PROCEDURE FUNCTION CALL

            vm.createColliExample = function () {
                vm.model.creating = true;
                loadColliExamples();
            };

            // #endregion BUTTON CREATE COLLI EXAMPLE PROCEDURE FUNCTION CALL

            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'prodsizecolli':
                        go({
                            prodsize_keyno: vm.model.edit.prodsize_keyno,
                            prodsizecolli_keyno: '0'
                        });
                        break;
                    default:
                        break;
                }
            };

            // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadEdit();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
