(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('stockBatchTrace', {
        templateUrl: 'views/components/views/stockBatchTrace/stockBatchTrace.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stockBatchTraceService', function ($stateParams, stockBatchTraceService) {

            // #######################
            // VARIABLES & DEFINITIONS
            // #######################

            var vm = this;
            
            vm.model = {
                bgP2StockBatchTraceSelection: {
                    item_default: '0',
                    item_selected: '',
                    records: [
                        { item_id: '0', item_name: 'stockbatchtrace_down', item_func: 'DOWN' },
                        { item_id: '1', item_name: 'stockbatchtrace_up', item_func: 'UP' }
                    ]
                },
                p2StockBatchBatchTraceDownList: {
                    records: []
                },
                p2StockBatchBatchTraceUpList: {
                    records: []
                }
            };
            
            // #####################
            // BUTTON GROUP FUNCTION
            // #####################
            
            vm.bgP2StockBatchTrace = function (item) {
                vm.model.bgP2StockBatchTraceSelection.item_selected = item;

                if (vm.model.bgP2StockBatchTraceSelection.item_selected == 'DOWN') {
                    vm.loadP2StockBatchBatchTraceDownList();
                }

                if (vm.model.bgP2StockBatchTraceSelection.item_selected == 'UP') {
                    vm.loadP2StockBatchBatchTraceUpList();
                }
            };
            
            // ###################
            // LOAD PROCEDURE CALL
            // ###################
            
            vm.loadP2StockBatchBatchTraceDownList = function () {
                stockBatchTraceService.loadP2StockBatchBatchTraceDownList($stateParams.stockbatch_keyno, vm.model.bgP2StockBatchTraceSelection.item_selected).then(function () {
                    vm.model.p2StockBatchBatchTraceDownList = stockBatchTraceService.p2StockBatchBatchTraceDownList;
                });
            };
            
            vm.loadP2StockBatchBatchTraceUpList = function () {
                stockBatchTraceService.loadP2StockBatchBatchTraceUpList($stateParams.stockbatch_keyno, vm.model.bgP2StockBatchTraceSelection.item_selected).then(function () {
                    vm.model.p2StockBatchBatchTraceUpList = stockBatchTraceService.p2StockBatchBatchTraceUpList;
                });
            };
            
            //if (vm.model.bgP2StockBatchTraceSelection.item_selected == 'DOWN') {
            //    vm.loadP2StockBatchBatchTraceDownList();
            //}
        }]
    });
})();