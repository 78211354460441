(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("orderDeliveryInfoService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            getOrderDeliveryInfo: function (argtype, argvalue) {
                return p2DataTaskService.call(1909, {
                    argtype: argtype,
                    argvalue: argvalue
                });
            }
        };

        return service;
    }]);
})();