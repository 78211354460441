(function() {
    'use strict';

    angular.module("imApp").factory("ttWebPageMenuEditorService", ['$q', '$ihttp', '$uibModal', 'base64', 'ieScreenBlockService', function ($q, $ihttp, $uibModal, base64, ieScreenBlockService) {
        var service = {
            load: function (webpageKeyno) {
                var deferred = $q.defer();

                $ihttp.post({
                    method: 2252,
                    parameters: {
                        p2_webpage_keyno: webpageKeyno
                    }
                }).then(function (response) {
                    deferred.resolve(angular.fromJson(base64.urldecode(response)));
                });

                return deferred.promise;
            },

            updateUsergroup: function (p2_webpage_subscription_keyno, usergroup_keyno, subscribed) {
                return $ihttp.post({
                    method: 2397,
                    parameters: {
                        state: 'usergroup',
                        p2_webpage_subscription_keyno: p2_webpage_subscription_keyno,
                        usergroup_keyno: usergroup_keyno,
                        subscribed: subscribed
                    }
                });
            },

            updateSubscription: function (p2_webpage_subscription_keyno, access) {
                return $ihttp.post({
                    method: 2397,
                    parameters: {
                        state: 'subscription',
                        p2_webpage_subscription_keyno: p2_webpage_subscription_keyno,
                        access: access
                    }
                });
            },

            updateIcon: function (p2_webpage_keyno, icon) {
                return $ihttp.post({
                    method: 2397,
                    parameters: {
                        state: 'icon',
                        p2_webpage_keyno: p2_webpage_keyno,
                        icon: icon
                    }
                });
            },

            updateOrderBy: function (p2_webpagelink_keyno, orderby) {
                return $ihttp.post({
                    method: 2397,
                    parameters: {
                        state: 'orderby',
                        p2_webpagelink_keyno: p2_webpagelink_keyno,
                        orderby: orderby
                    }
                });
            },

            updateWordId: function (p2_webpagelink_keyno, word_id) {
                return $ihttp.post({
                    method: 2397,
                    parameters: {
                        state: 'word_id',
                        p2_webpagelink_keyno: p2_webpagelink_keyno,
                        word_id: word_id
                    }
                });
            },

            updateApproute: function (p2_webpagelink_keyno, approute) {
                return $ihttp.post({
                    method: 2397,
                    parameters: {
                        state: 'approute',
                        p2_webpagelink_keyno: p2_webpagelink_keyno,
                        p2_approute_keyno: approute.p2_approute_keyno,
                        route_id: approute.route_id
                    }
                });
            },

            delete: function (p2_webpagelink_keyno, confirmDeleteMessage) {
                var deferred = $q.defer();

                var modalInstance = $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    component: 'ttConfirm',
                    resolve: {
                        message: function () {
                            return confirmDeleteMessage;
                        }
                    }
                });

                modalInstance.result.then(function (confirmed) {
                    if (confirmed !== true) {
                        deferred.resolve();
                        return;
                    }

                    ieScreenBlockService.start();

                    $ihttp.post({
                        method: 2397,
                        parameters: {
                            state: 'delete',
                            p2_webpagelink_keyno: p2_webpagelink_keyno
                        }
                    }).then(function () {
                        ieScreenBlockService.stop();

                        deferred.resolve();

                        vm.modalInstance.dismiss();
                    });
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
