(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("modalParametersService", ['$uibModal', '$q', function ($uibModal, $q) {
        var service = {
            getParameterValues: function (config) {
                var deferred = $q.defer();

                var modalInstance = $uibModal.open({
                    backdrop: true,
                    animation: true,
                    templateUrl: 'views/modal/parameters.html?v=' + module.version,
                    controller: ['$scope', function ($uibScope) {
                            $uibScope.model = { };

                            angular.copy(config, $uibScope.model);

                            $uibScope.model.data = {};

                            $uibScope.ok = function () {
                                deferred.resolve($uibScope.model.data);

                                modalInstance.close();
                            };

                            $uibScope.cancel = function () {
                                modalInstance.close();
                            };
                        }
                    ]
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();
