(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('stockLimits', {
        templateUrl: 'views/components/views/stockLimits/stockLimits.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stockLimitsService', 'ttGridFactory', function (stockLimitsService, ttGridFactory) {
            let vm = this;
            let onDestroy = [];

            //vm.model = {
            //    product: {
            //        rememberId: 'w_stocklimits.product',
            //        selected: {
            //            field: '',
            //            key: ''
            //        },
            //        list: []
            //    },
            //    supplier: {
            //        rememberId: 'w_stocklimits.supplier',
            //        selected: {
            //            field: '',
            //            key: ''
            //        },
            //        list: []
            //    }
            //};

            vm.model = {
                rememberId: 'w_stocklimits.model',
                selected: {
                    product: {
                        field: '',
                        key: ''
                    },
                    supplier: {
                        field: '',
                        key: ''
                    }
                },
                productList: [],
                supplierList: []
            };

            vm.grid = new ttGridFactory({
                rememberId: 'w_stocklimits.grid',
                loadData: {
                    method: 3108,
                    parameters: {
                        prod_id: '',
                        supplier_no: 0
                    }
                }
            }).setToolbar({ wrapping: true });

            vm.setLoadDataParms = function () {
                vm.grid.dataTask.loadData.parameters = {
                    prod_id: vm.model.selected.product.key,
                    supplier_no: vm.model.selected.supplier.key
                };
            };

            // #region SEARCH FUNCTIONS

            vm.productSearch = function (value) {
                return stockLimitsService.searchProductKeyname(value);
            };

            vm.supplierSearch = function (value) {
                return stockLimitsService.searchSupplierKeyname(value);
            };

            vm.productSelect = function (item, model) {
                if (item?.prod_id && item.prod_id !== '') {
                    //console.dir(item);
                    vm.model.selected.product.field = item.prod_keyname;
                    vm.model.selected.product.key = item.prod_id;
                    vm.setLoadDataParms();
                }

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();

                rememberFunc();
            };

            vm.supplierSelect = function (item, model) {
                if (item?.supplier_no && item.supplier_no !== '') {
                    //console.dir(item);
                    vm.model.selected.supplier.field = item.supplier_keyname;
                    vm.model.selected.supplier.key = item.supplier_no;
                    vm.setLoadDataParms();
                }

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();

                rememberFunc();
            };

            vm.productClear = function () {
                vm.model.selected.product.field = '';
                vm.model.selected.product.key = '';
                vm.setLoadDataParms();

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();

                rememberFunc();
            };

            vm.supplierClear = function () {
                vm.model.selected.supplier.field = '';
                vm.model.selected.supplier.key = 0;
                vm.setLoadDataParms();

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();

                rememberFunc();
            };

            // #endregion SEARCH FUNCTIONS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            let rememberFunc = function () {
                stockLimitsService.remember(vm.model.rememberId, vm.model.selected);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region ANGULAR FUNCTIONS

            vm.$onInit = function () {
                stockLimitsService.getRemember(vm.model.rememberId).then(function (response) {
                    if (response?.[0]?.variablevalue && response[0].variablevalue !== '') {
                        vm.model.selected = angular.copy(angular.fromJson(response[0].variablevalue));

                        vm.grid.dataTask.loadData.parameters.prod_id = vm.model.selected.product.key;
                        vm.grid.dataTask.loadData.parameters.supplier_no = vm.model.selected.supplier.key;

                        if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
                    }
                });
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
