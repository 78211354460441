(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('holidays', {
        templateUrl: 'views/components/views/holidays/holidays.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'holidaysService', 'logisticService', 'holidaysViewService', function ($stateParams, stateService, utilityService, holidaysService, logisticService, holidaysViewService) {
            const vm = this;
            let year = $stateParams.year;
            let countryId = $stateParams.country_id;
            
            vm.model = {
                setting: {
                    year: year,
                    country_id: countryId
                },
                selectListYears: [],
                selectListCountries: [],
                itemsListHolidays: []
            };

            let loadCountries = function () {
                logisticService.listCountries().then(function (result) {
                    angular.copy(result, vm.model.selectListCountries);
                });
            };

            let loadHolidayYears = function () {
                if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;

                return holidaysViewService.listHolidayYears({ country_id: vm.model.setting.country_id }).then(function (result) {
                    angular.copy(result, vm.model.selectListYears);
                });
            };

            let loadHolidays = function () {
                if (utilityService.validateParmsValue(vm.model.setting.year, true) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;

                vm.model.itemsListHolidays = [];

                return holidaysService.listHolidays({
                    year: vm.model.setting.year,
                    country_id: vm.model.setting.country_id
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListHolidays);
                });
            };

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'holiday':
                        go({
                            p2_holyday_keyno: '0',
                            country_id: vm.model.setting.country_id
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'country_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting.country_id) !== true) return;

                        vm.model.setting.country_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.country_id, true) !== true) return;

                        loadHolidayYears();
                        loadHolidays();
                        break;
                    case 'year':
                        if (utilityService.validateWatchValue(value, vm.model.setting.year) !== true) return;

                        vm.model.setting.year = value;

                        if (utilityService.validateParmsValue(vm.model.setting.year, true) !== true) return;

                        loadHolidays();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            vm.$onInit = function () {
                loadCountries();
                loadHolidayYears();
                loadHolidays();
            };
        }]
    });
})();
