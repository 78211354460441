(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('bonusTypesOverview', {
        templateUrl: 'views/components/views/bonusTypesOverview/bonusTypesOverview.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'layoutService', 'translateService', 'bonusTypesOverviewService', 'modalService', function ($q, layoutService, translateService, bonusTypesOverviewService, modalService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let onDestroy = [];
            vm.gridReady = false;

            vm.fontSize = '14px';

            vm.translations = {
                tt_delete_grid_row_title: '',
                tt_delete_grid_row_warning: '',
                tt_delete_grid_row_confirm: '',
                tt_delete_grid_row_abort: ''
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_bonustypeoverview.grid',              // Id used to retrieve user configs for the grid
                    loadData: {                                         // Method id of the get data procedure
                        method: 2623,
                        parameters: null
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null,
                        pre: function () {
                            vm.grid.gridfunc.clearFilter();
                        }
                        //post: function () {
                        //    vm.grid.gridfunc.editCell(0);
                        //}
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        method: null,
                        parameters: null
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons
                            //{ name: 'updateBonusType', text: 'tt_update_bonus_type', func: function (data) { updateBonusType(data); }, icon: 'none', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling, 'limit' will trigger only headers, and not toolbar
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: false,                                  // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            {
                                name: 'customEdit',
                                text: '',
                                icon: 'far-trash-alt',
                                type: 'danger',
                                func: function (data) {
                                    modalService.show({
                                        type: 'warning',
                                        title: vm.translations.tt_delete_grid_row_title,
                                        message: vm.translations.tt_delete_grid_row_warning,
                                        buttons: [{
                                            label: vm.translations.tt_delete_grid_row_abort,
                                            cssClass: 'btn-warning',
                                            action: function (dialogItself) {
                                                dialogItself.close();
                                            }
                                        }, {
                                            label: vm.translations.tt_delete_grid_row_confirm,
                                            cssClass: 'btn-danger',
                                            action: async function (dialogItself) {
                                                await vm.grid.gridfunc.removeRow(data);
                                                vm.grid.gridfunc.saveChanges(false);
                                                dialogItself.close();
                                            }
                                        }]
                                    });
                                }
                            }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: null,                                       // Sets the height of the grid component
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { optionfunc(data.data); }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region OPTIONFUNC

            let optionfunc = function (data) {
                //console.log('optionfunc');
                //if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                //    console.log('optionfunc - CellClickHandler');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                //    console.log('optionfunc - OnCellClose');
                //    console.dir(data);
                //}

                //if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                //    console.log('optionfunc - LookupCellEditor');
                //    console.dir(data);
                //}
            };

            // #endregion OPTIONFUNC

            // #region TOOLBAR BUTTON VALIDATION

            let getIsSelectedItems = function () {
                let dirtyItems = vm.grid.gridfunc.getDirtyRows();
                let isSelectedItems = [];

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true) isSelectedItems.push(item);
                });

                return isSelectedItems;
            }

            let hasDirty = function () {
                let parmItems = getIsSelectedItems();

                if (parmItems.length > 0) return false;

                return true;
            };

            let disable = function () {
                return true;
                //if (isSaveRunning) return true;
            };

            // #endregion TOOLBAR BUTTON VALIDATION

            // #region TOOLBAR BUTTON FUNCTIONS

            let updateBonusType = function () {
                console.log('updateBonusType');
            }

            // #endregion TOOLBAR BUTTON FUNCTIONS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            vm.rememberFunc = function (parm) {
                //vm.model.searchText = parm;
                //bonusTypesOverviewService.remember(vm.model.rememberId, vm.model.searchText);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region LAYOUT FUNCTIONS

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                vm.fontSize = info.fontSizes.textSize;
            });

            // #endregion LAYOUT FUNCTIONS

            // #region TRANSLATION

            translateService.translateBatch(vm.translations).then(function (data) {
                angular.forEach(vm.translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        vm.translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATION

            // #region ANGULAR FUNCTIONS

            //function prepareLists() {
            //    let deferred = $q.defer();
            //    let promises = [];

            //    promises.push(bonusTypesOverviewService.getCustomersKeynameList());
            //    promises.push(bonusTypesOverviewService.getOrdersKeynameList());

            //    $q.all(promises).then(function (response) {
            //        angular.copy(response[0], vm.customers.list);
            //        angular.copy(response[1], vm.orders.list);

            //        deferred.resolve(response);
            //    }, function (error) {
            //        console.log('error! error! error! error! error!');

            //        if (angular.isDefined(error.data) && angular.isString(error.data.message) && error.data.message.length > 0) {
            //            console.log(error.data.message);

            //            if (angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.message !== error.data.data.message) {
            //                console.log(error.data.data.message);
            //            }
            //        } else {
            //            if (angular.isDefined(error.data) && angular.isDefined(error.data.data) && angular.isString(error.data.data.message) && error.data.data.message.length > 0) {
            //                console.log(error.data.data.message);
            //            }
            //        }

            //        console.dir(error);

            //        deferred.reject(error);
            //    });

            //    return deferred.promise;
            //}

            vm.$onInit = function () {
                //prepareLists().then(function (data) {
                //    //console.dir(data);
                //    vm.gridReady = true;
                //});

                vm.gridReady = true;
            };

            vm.$onDestroy = function () {
                angular.forEach(onDestroy, function (fn) {
                    if (angular.isFunction(fn) === true) {
                        fn();
                    }
                });
            };

            // #endregion ANGULAR FUNCTIONS
        }]
    });
})();
