(function () {
    'use strict';

    angular.module("imApp").factory("capTasksViewService", ['$ihttp', function ($ihttp) {
        var call = function (method, parameters) {
            return $ihttp.post({
                method: method,
                parameters: parameters || {}
            });
        };

        var encoder = function (data, key, format, encodeAll) {
            var returnData = null;
            if (angular.isDefined(encodeAll) && encodeAll === true) {
                returnData = btoa(data);
            } else {
                for (var e = 0; e < format.length; e++) {
                    if (format[e].field === key) {
                        if (format[e].type === 'char' || format[e].type === 'varchar' || format[e].type === 'long varchar') {
                            returnData = btoa(data);
                            break;
                        } else {
                            returnData = data;
                            break;
                        }
                    }
                }
            }
            return returnData;
        };

        var service = {
            loadCapTasksViewGet: function (argtype, argvalue) {
                return call(1458, { argtype: argtype, argvalue: argvalue });
            },
            loadCapTasksViewData: function (argtype, argvalue) {
                return call(1459, { argtype: argtype, argvalue: argvalue });
            },
            updateMovedItem: function (data) {
                return call(2078, data);
            }
        };

        return service;
    }]);
})();