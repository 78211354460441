(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("p2ExportProcUserGroupsService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2ExportProcUserGroupsGet: function (p2_exportproc_keyno) {
                return p2DataTaskService.call(1892, {
                    p2_exportproc_keyno: p2_exportproc_keyno
                });
            },
            loadExportProcUserGroupsList: function (p2_exportproc_keyno) {
                return p2DataTaskService.call(1893, {
                    p2_exportproc_keyno: p2_exportproc_keyno
                });
            },
            saveObj: function (mergeObj) {
                return p2DataTaskService.call(1894, mergeObj);
            }
        };

        return service;
    }]);
})();