(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('prodGrp', {
        templateUrl: 'views/components/views/prodGrp/prodGrp.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'prodGrpService', 'productService', function ($stateParams, stateService, prodGrpService, productService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
    
            vm.model = {
                p2WProdGrpGet: {},
                selectListDiscGroupProducts: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE CALL
            
            prodGrpService.loadP2WProdGrpGet($stateParams.prodgrp_id).then(function (data) {
                vm.model.p2WProdGrpGet = angular.copy(data[0]);
            });

            let loadDiscGroupProducts = function () {
                productService.listDiscGroupProducts().then(function (result) {
                    angular.copy(result, vm.model.selectListDiscGroupProducts);
                });
            };

            // #endregion LOAD PROCEDURE CALL

    		// #region SAVE BUTTON FUNCTION
    		
            vm.saveProdGrp = function () {
                prodGrpService.saveP2ProdGrp(vm.model.p2WProdGrpGet).then(function (data) {
                    stateService.back();
                });
            };

            // #endregion SAVE BUTTON FUNCTION

    		// #region DELETE BUTTON FUNCTION
    		
            vm.deleteProdGrp = function () {
                prodGrpService.deleteP2ProdGrp(vm.model.p2WProdGrpGet).then(function (data) {
                    stateService.back();
                });
            };

            // #endregion DELETE BUTTON FUNCTION

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadDiscGroupProducts();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
