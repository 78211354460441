(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockPlanningPo', {
        templateUrl: 'views/components/views/stockPlanningPo/stockPlanningPo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$timeout', 'stateService', 'utilityService', 'modalService', 'stockPlanningPoService', 'logisticService', 'rememberService', function ($q, $timeout, stateService, utilityService, modalService, stockPlanningPoService, logisticService, rememberService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            
            var variableNames = {
                businessco_no: '',
                stockplace_id: '',
                operation_id: '',
                prodgrp_id: '',
                prodbeomethod_no: ''
            };

            vm.model = {
                operationButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('operation_id') }
                ],
                productGroupButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('prodgrp_id') }
                ],
                setting: {},
                selectListBusinessCompanies: [],
                selectListStockPlaces: [],
                selectListOperations: [],
                selectListProductGroups: [],
                selectListProdBeoMethods: [],
                gridReady: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remfield: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                },
                runprogress: '',
                lockedCreate: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: '',
                    loadSetupId: null,
                    loadData: {},
                    addRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    removeRow: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    },
                    saveData: {
                        method: null,
                        parameters: null,
                        autoSave: false,
                        confirm: false
                    }
                },
                translations: [],
                config: {
                    editColumns: [
                        { key: 'po_proposal_quantity' },
                        { key: 'po_proposal_date' },
                        { key: 'stock_minimum' },
                        { key: 'stock_std_orderquantity' },
                        { key: 'stock_maximum' },
                        { key: 'is_selected' }
                        //'prod_name', 'stock_stockvalue', 'is_selected', { key: 'test_column', lookup: 1900, relations: [{ key: 'stockplace_name', value: 'item_id' }], optionfunc: true }, 'reg_date'
                    ],
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            //{ name: 'cancel', text: '', func: function () { console.log('this is a test with default icon'); } },
                            //{ name: 'test', text: '', func: function () { testItems(); } },
                            //{ name: 'customSave', text: '', func: function () { saveItems(); }, icon: 'save' },
                            //{ name: 'customAddBefore', text: '', func: function () { addItemBefore(); }, icon: 'insert-up' },
                            //{ name: 'customAddAfter', text: '', func: function () { addItemAfter(); }, icon: 'insert-down' },
                            //{ name: 'customDelete', text: '', func: function () { deleteItem(); }, icon: 'delete' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: true,
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) {
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    //persistSelection: false,
                    filterable: false
                },
                optionfunc: function (data) { optionfunc(data.data); },
                gridfunc: null
            };

            var optionfunc = function (data) {
                console.log('optionfunc');
                if (angular.isDefined(data.func) && data.func === 'CellClickHandler') {
                    console.log('optionfunc - CellClickHandler');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'OnCellClose') {
                    console.log('optionfunc - OnCellClose');
                    console.dir(data);
                }

                if (angular.isDefined(data.func) && data.func === 'LookupCellEditor') {
                    console.log('optionfunc - LookupCellEditor');
                    console.dir(data);
                }
            };

            // #endregion VARIABLES & DEFINITIONS

            //#region SHOW DIALOGUE BOX FUNCTION

            var showMessage = function (type, title, message, label, cssClass) {
                var deferred = $q.defer();

                modalService.show({
                    type: type || 'warning',
                    title: title || '',
                    message: message,
                    buttons: [{
                        label: label || 'OK',
                        cssClass: cssClass || 'btn-warning',
                        action: function (dialogItself) {
                            dialogItself.close();
                            deferred.resolve();
                        }
                    }]
                });

                return deferred.promise;
            };

            //#endregion SHOW DIALOGUE BOX FUNCTION
            
            // #region LOAD PROCEDURE FUNCTION CALLS

            stockPlanningPoService.getStockPlanningPo().then(function (data) {
                angular.copy(data[0], vm.model.setting);

		        if (utilityService.validateParmsValue(vm.model.setting.view_id) === true) {
		        	vm.grid.dataTask.rememberId = vm.model.setting.view_id;
		        }

		        if (utilityService.validateParmsValue(vm.model.setting.p2_datatask_keyno) === true) {
                    loadGrid();
		        }

		        vm.model.gridReady = true;

                loadStocks();
            });

            var loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: vm.model.setting.p2_datatask_keyno,
                    parameters: vm.model.setting
                };
            };

            logisticService.listCompanies().then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCompanies);
            });

            stockPlanningPoService.listProdBeoMethods().then(function (data) {
                angular.copy(data, vm.model.selectListProdBeoMethods);
            });

            stockPlanningPoService.listOperations({ add_all: '1' }).then(function (data) {
                angular.copy(data, vm.model.selectListOperations);
            });

            logisticService.listProductGroups({ add_all: '1' }).then(function (data) {
                angular.copy(data, vm.model.selectListProductGroups);
            });

            var loadStocks = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                logisticService.listStocks({ businessco_no: vm.model.setting.businessco_no }).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            var rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                var variableValue = null;

                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        variableValue = vm.model.setting.businessco_no;
                        break;
                    case 'stockplace_id':
                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        variableValue = vm.model.setting.stockplace_id;
                        break;
                    case 'operation_id':
                        if (utilityService.validateParmsValue(vm.model.setting.operation_id) !== true) return;

                        variableValue = vm.model.setting.operation_id;
                        break;
                    case 'prodgrp_id':
                        if (utilityService.validateParmsValue(vm.model.setting.prodgrp_id) !== true) return;

                        variableValue = vm.model.setting.prodgrp_id;
                        break;
                    case 'prodbeomethod_no':
                        if (utilityService.validateParmsValue(vm.model.setting.prodbeomethod_no) !== true) return;

                        variableValue = vm.model.setting.prodbeomethod_no;
                        break;
                    default:
                        break;
                }

                rememberService.remember(variableNames[id], variableValue);
            };

		    // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            vm.activateProgressCreate = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;

                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';

                        if (vm.model.runProgress === 'ABORT') return;

                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            return;
                        }

                        step = vm.model.progressInfo.step + skip + failed;
                        vm.model.progressInfo.field = 'ID: ' + items[step].stock_keyno;
                        modalService.progressInfo.field = vm.model.progressInfo.field;

                        stockPlanningPoService.createProductionOrder(items[step], vm.grid.gridfunc).then(function (data) {
                            if (data[0].errorcode !== '0') {
                            	showMessage('warning', 'Varsel', data[0].errormessage, 'OK', 'btn-warning').then(function () {
                                    modalService.progressInfo.finishclose = '1';
                                    vm.model.runProgress = 'ABORT';
                                    modalService.progressInfo.abortclose = true;

                                    doStep();
                            	});
                            } else {
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = vm.model.progressInfo.finishclose;

                                doStep();
                            }
                        });
                    });
                };

                doStep();
            };

            var progressFuncCreate = function () {
                vm.model.lockedCreate = true;
                vm.model.runProgress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;
                vm.model.progressInfo.finishclose = '1';
                vm.model.progressInfo.abortclose = false;

                /*var currentData = vm.grid.gridfunc.getAllRows();*/
                var currentData = vm.grid.gridfunc.getDirtyRows();
                var selectedItems = [];
                var tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                
                angular.forEach(currentData, function (item) {
                    if (item.is_selected === true) {
                        item.po_proposal_date = (new Date(item.po_proposal_date - tzo)).toISOString().replace('T', ' ').slice(0, -1);
                        selectedItems.push(item);
                    }
                });

                vm.model.progressInfo.max = selectedItems.length;
                vm.activateProgressCreate(selectedItems);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.runProgress = value;

                    if (vm.model.runProgress === 'ABORT') {
                        vm.model.lockedCreate = false;
                    } else if (vm.model.runProgress === 'FINISH') {
                        vm.model.lockedCreate = false;
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                    }
                });
            };

		    // #endregion SHOW PROGRESSBAR DIALOGUE BOX FUNCTION

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        rememberFunc('businessco_no');
                        loadStocks();
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'stockplace_id':
                        vm.model.setting.stockplace_id = value;

                        if (utilityService.validateParmsValue(vm.model.setting.stockplace_id) !== true) return;

                        rememberFunc('stockplace_id');
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'prodbeomethod_no':
                        vm.model.setting.prodbeomethod_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.prodbeomethod_no, true) !== true) return;

                        rememberFunc('prodbeomethod_no');
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    case 'only_shortcoming':
                        vm.model.setting.only_shortcoming = value;

                        if (utilityService.validateParmsValue(vm.model.setting.only_shortcoming, true) !== true) return;
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };

		    // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'operation_id':
                    case 'prodgrp_id':
                        vm.model.setting[id] = 'ALL';

                        rememberFunc(id);
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region ACTIVATE MULTI BUTTON FUNCTIONS

            vm.activateFunc = function (action) {
                switch (action) {
                    case 'create':
                        progressFuncCreate();
                        break;
                    default:
                        break;
                }
            };

		    // #endregion ACTIVATE MULTI BUTTON FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'operation_id':
                    case 'prodgrp_id':
                        if (utilityService.validateWatchValue(value, vm.model.setting[id]) !== true) return;

                        vm.model.setting[id] = value;

                        if (utilityService.validateParmsValue(vm.model.setting[id]) !== true) return;

                        rememberFunc(id);
                        loadGrid();
                        vm.grid.gridfunc.rebind();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

        }]
    });
})();
