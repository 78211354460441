(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('choicePossRulesRun', {
        templateUrl: 'views/components/views/choicePossRulesRun/choicePossRulesRun.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$q', '$timeout', 'stateService', 'modalService', 'choicePossRulesRunService', function ($q, $timeout, stateService, modalService, choicePossRulesRunService) {
            var vm = this;

            // ## VARIABLES & DEFINITIONS ##

            vm.model = {
                choiceProdIdBtnList: [
                    { item_id: '0', item_name: 'erase', item_func: 'erase_item', glyph: 'glyphicon-erase', color: 'danger' }
                ],
                choiceProductButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearChoiceProduct },
                ],
                modelButtons: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearModel },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoModels }
                ],
                modelButtonsGoto: [
                    { id: 'erase', icon: 'glyphicon glyphicon-erase', color: 'danger', type: 'danger', onClick: clearModel },
                    { id: 'goto', icon: 'glyphicon glyphicon-menu-hamburger', color: 'primary', type: 'primary', onClick: gotoModels },
                    { id: 'goto-item', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: gotoModelMenu }
                ],
                bgChoicePossRulesRun: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'gb_choicepossrulesrun_count', item_func: 'COUNT' },
                        { item_id: '1', item_name: 'gb_choicepossrulesrun_products', item_func: 'PRODUCTS' }
                    ]
                },
                get: {},
                listProductChoice: [],
                listModelSelect: [],
                listCount: [],
                listProduct: [],
                loading: false,
                progressInfo: {
                    label: '',
                    plabel: '',
                    field: '',
                    remField: '',
                    min: 0,
                    max: 100,
                    step: 0,
                    finishclose: ''
                }
            };

            // ## LOAD PROCEDURE CALLS ##

            var loadCount = function () {
                if (angular.isUndefined(vm.model.get.choice_prod_id) === true) return;
                if (vm.model.get.choice_prod_id === null) return;
                if (vm.model.get.choice_prod_id.trim().length < 1) return;

                if (angular.isUndefined(vm.model.get.model_id) === true) return;
                if (vm.model.get.model_id === null) return;
                if (vm.model.get.model_id.trim().length < 1) return;

                vm.model.listCount = [];

                choicePossRulesRunService.loadCountList(vm.model.get).then(function (data) {
                    angular.copy(data, vm.model.listCount);
                });
            };

            var loadProducts = function () {
                if (angular.isUndefined(vm.model.get.choice_prod_id) === true) return;
                if (vm.model.get.choice_prod_id === null) return;
                if (vm.model.get.choice_prod_id.trim().length < 1) return;

                if (angular.isUndefined(vm.model.get.model_id) === true) return;
                if (vm.model.get.model_id === null) return;
                if (vm.model.get.model_id.trim().length < 1) return;

                vm.model.listProduct = [];

                choicePossRulesRunService.loadProductList(vm.model.get).then(function (data) {
                    angular.copy(data, vm.model.listProduct);
                });
            };

            // ## BUTTON GROUP FUNCTIONS ##

            vm.selectBgChoicePossRulesRun = function (item) {
                vm.model.bgChoicePossRulesRun.item_selected = item;
            };

            // ## EXECUTE FUNCTION WITH PROGRESSBAR ##

            function addExecute(item) {
                var deferred = $q.defer();

                //vm.model.loading = true;

                var mergeObj = vm.model.get;
                mergeObj.prod_id = item.item_id;

                choicePossRulesRunService.loadExecuteOne(mergeObj).then(function (data) {
                    //ADD GLYPH CHECK COLOR GREEN TO SHOW DONE, GLYPH "X" COLOR RED IF ERROR
                    console.log('addExecute(item): ' + angular.toJson(data));

                    //if (data[0].errorcode !== '0') {
                    //    modalService.show({
                    //        type: 'warning',
                    //        title: 'Varsel',
                    //        message: data[0].errormessage,
                    //        buttons: [{
                    //            label: 'OK',
                    //            cssClass: 'btn-warning',
                    //            action: function (dialogItself) {
                    //                dialogItself.close();
                    //                //vm.model.progress === 'ABORT';
                    //                //vm.model.loading = false;
                    //            }
                    //        }]
                    //    });
                    //} else {
                    //    //vm.model.progress === 'ABORT';
                    //    //vm.model.loading = false;
                    //}

                    deferred.resolve(data);
                });
                return deferred.promise;
            }

            vm.executesProgress = function (items) {
                var increment = 1, skip = 0, failed = 0, step = 0;
                vm.model.progressInfo.step = 0;

                var doStep = function () {
                    $timeout(function () {
                        modalService.progressInfo.finishclose = '0';
                        if (vm.model.progress === 'ABORT') return;
                        if (vm.model.progressInfo.step >= vm.model.progressInfo.max) {
                            //stateService.back('/' + vm.model.get.finish_item_state + '/' + vm.model.get.finish_item_parms);
                            return;
                        }
                        if (items[step].item_id === '0' || items[step].item_id === '9999') {
                            skip++;
                            step = vm.model.progressInfo.step + skip + failed;
                            doStep();
                        } else {
                            step = vm.model.progressInfo.step + skip + failed;
                            vm.model.progressInfo.field = /*items[step].item_id + ': ' +*/ items[step].item_name /*+ ' ' + items[step].item_name_sub1*/;
                            modalService.progressInfo.field = vm.model.progressInfo.field;

                            addExecute(items[step]).then(function (data) {
                                //if data error, failed++;
                                vm.model.progressInfo.step += increment;
                                modalService.progressInfo.step = vm.model.progressInfo.step;
                                modalService.progressInfo.finishclose = '1';
                                doStep();
                            });
                        }
                    });
                };
                doStep();
            };

            vm.addExecutes = function () {
                vm.model.progress = 'START';
                vm.model.progressInfo.label = 'progressbar';
                //vm.model.progressInfo.plabel = 'progressbar';
                vm.model.progressInfo.field = '';
                //vm.model.progressInfo.remField = '';
                //vm.model.progressInfo.min = 0;
                vm.model.progressInfo.max = 0;
                vm.model.progressInfo.step = 0;

                angular.forEach(vm.model.listProduct, function (item) {
                    if (item.item_id !== '0' && item.item_id !== '9999') {
                        vm.model.progressInfo.max++;
                    }
                });

                vm.executesProgress(vm.model.listProduct);

                modalService.showProgress(vm.model.progressInfo).then(function (value) {
                    vm.model.progress = value;
                });
            };

            vm.onChoiceProductChanged = function (value) {
                vm.model.get.choice_prod_id = value;

                if (value || value === '') {
                    loadCount();
                    loadProducts();
                }
            }

            vm.onModelChanged = function (value) {
                vm.model.get.model_id = value;

                if (value || value === '') {
                    loadCount();
                    loadProducts();
                }
            }

            function clearModel() {
                vm.model.get.model_id = 'ALL';
                loadCount();
                loadProducts();

            }

            function gotoModels() {
                stateService.go('models', {});
            }

            function gotoModelMenu() {
                stateService.go('modelmenu', { model_id: vm.model.get.model_id });
            }

            function clearChoiceProduct() {
                vm.model.get.choice_prod_id = 'ALL';
                loadCount();
                loadProducts();
            }

            async function loadChoihcePossRulesRunGet() {
                return vm.model.get = (await choicePossRulesRunService.loadChoicePossRulesRunGet())[0];
            }

            async function loadProductChoiceList() {
                return vm.model.listProductChoice = await choicePossRulesRunService.loadProductChoiceList('1');
            }

            async function loadModelSelectList() {
                return vm.model.listModelSelect = await choicePossRulesRunService.loadModelSelectList('1', '0');
            }

            vm.$onInit = async function () {
                loadProductChoiceList();
                loadModelSelectList();
                await loadChoihcePossRulesRunGet();
                loadCount();
                loadProducts();
            }
        }]
    });
})();
