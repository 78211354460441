(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('custGrpMenu', {
        templateUrl: 'views/components/views/custGrpMenu/custGrpMenu.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'custGrpMenuService', function ($stateParams, stateService, custGrpMenuService) {
            const vm = this;
            let custGrpId = $stateParams.custgrp_id;
            
            vm.model = {
                customerGroupNameButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('custgrplangs', { custgrp_id: vm.model.menu.custgrp_id }) }
                ],
                menu: {}
            };

            let loadMenu = function () {
                custGrpMenuService.getCustomerGroupMenu({ custgrp_id: custGrpId }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };
    		
            vm.deleteValues = function () {
                custGrpMenuService.deleteItem(vm.model.menu).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadMenu();
            };
        }]
    });
})();
