(function () {
    'use strict';

    var module = angular.module('imApp');

    module.directive('ttHeader', ['translateService', 'layoutService', function (translateService, layoutService) {
        var directive = {
            restrict: 'A',
            scope: {
                ttHeader: '@',
                ttTranslate: '@'
            },
            template: '<div class="col-xs-12 sp-0" ng-style="{\'fontSize\': model.fontSize}">{{model.label | uppercase}}</div>',
            link: function ($scope) {
                $scope.model = {
                    fontSize: '',
                    label: ''
                };

                var setLabel = function() {
                    if ($scope.ttTranslate || false) {
                        var translations = { };

                        translations[$scope.ttHeader] = '';

                        var deregTranslations = translateService.on(translations, 'changed', function (result) {
                            $scope.model.label = result[$scope.ttHeader];

                            deregTranslations();
                        });
                    } else {
                        $scope.model.label = $scope.ttHeader;
                    }
                };

                //if (angular.isUndefined($scope.ttHeader) || $scope.ttHeader === null || $scope.ttHeader.length < 1) {
                    var deregWatch = $scope.$watch(
                        function() { return $scope.ttHeader; },
                        function() {
                            if (angular.isDefined($scope.ttHeader) && $scope.ttHeader.length > 0) {
                                setLabel();
                                //deregWatch();
                            }
                        }
                    );
                //} else {
                //    setLabel();
                //}

                layoutService.onLayoutChanged($scope, function (info) {
                    if (angular.isUndefined(info)) return;

                    $scope.model.fontSize = info.fontSizes.textSize;
                });

                $scope.$on('$destroy', function () {
                    if (angular.isFunction(deregWatch)) {
                        deregWatch();
                    }
                });
            }
        };
 
        return directive;
    }]);
})();