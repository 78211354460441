(function () {
    'use strict';

    let  module = angular.module("imApp");

    module.factory("custactPlansService", ['$ihttp', function ($ihttp) {
        let  service = {
            loadCustactPlans: function () {
                return $ihttp.post({ method: 797, parameters: {} });
            },
            loadLabourList: function () {
                return $ihttp.post({ method: 101, parameters: { activestatus: 'ACT' } });
            },
            loadCustPlansList: function (custactPlans) {
                return $ihttp.post({ method: 798, parameters: custactPlans });
            }
        };

        return service;
    }]);
})();
