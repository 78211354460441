(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('checkListDatas', {
        templateUrl: 'views/components/views/checkListDatas/checkListDatas.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'checkListDatasService', function ($stateParams, stateService, checkListDatasService) {
            
            // #region VARIABLES & DEFINITIONS

            let vm = this;
            let argType = $stateParams.argtype;
            let argValue = $stateParams.argvalue;

            vm.model = {
                itemsListCheckListDatas: []
            };

		    // #endregion VARIABLES & DEFINITIONS

            let loadCheckListDatas = function () {
                checkListDatasService.listCheckListDatas({
                    argtype: argType,
                    argvalue: argValue
                }).then(function (result) {
                    angular.copy(result, vm.model.itemsListCheckListDatas);
                });
            };
            
            // #region MULTI DIRECTION BUTTON FUNCTIONS

            vm.goToFunc = function (state, e) {
                let go = function (parms) {
                    if (e?.ctrlKey) {
                        stateService.newTab(state, parms);
                    } else {
                        stateService.go(state, parms);
                    }
                };

                switch (state) {
                    case 'selectchecklistadd':
                        go({
                            argtype: argType,
                            argvalue: argValue
                        });
                        break;
                    default:
                        break;
                }
            };

		    // #endregion MULTI DIRECTION BUTTON FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadCheckListDatas();
            };

		    // #endregion ON INIT FUNCTION
        }]
    });
})();
