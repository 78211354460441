<div *ngIf="ready === true" style="position: relative; width: 100%">
    <div *ngIf="editMode === true" class="dashboard__grid" [style.gridTemplateColumns]="getColumnsCSSValue()" [style.gridTemplateRows]="getRowsCSSValue()">
        <div
            *ngFor="let count of [].constructor(numberOfColumns * numberOfRows); index as i"
            class="dashboard__cell"
            attr.data-col="{{ getColumnForIndex(i) }}"
            attr.data-row="{{ getRowForIndex(i) }}"
        ></div>
    </div>
    <div class="dashboard__grid" [style.gridTemplateColumns]="getColumnsCSSValue()" [style.gridTemplateRows]="getRowsCSSValue()">
        <tt-widget
            *ngFor="let widget of widgets; trackBy: trackByFn"
            class="dashboard__widget"
            ttOutsideClick
            (onOutsideClick)="onWidgetBlur($event, widget)"
            [style.gridRowStart]="widget.rowStart?.toString() ?? 'auto'"
            [style.gridRowEnd]="'span ' + widget.rowSpan"
            [style.gridColumnStart]="widget.columnStart?.toString() ?? 'auto'"
            [style.gridColumnEnd]="'span ' + widget.columnSpan"
            [ttCanResize]="widget.canResize || false"
            [ttCanMove]="widget.canMove || false"
            (click)="onWidgetClick(widget)"
            (blur)="widget.canMove = false; widget.canResize = false"
            (ttResize)="onWidgetResize($event, widget.id)"
            (ttMove)="onWidgetMove($event, widget.id)"
            [ttMainHeading]="widget.mainHeading"
            [ttSubHeading]="widget.subHeading"
            [ttModel]="widget.value"
            [ttIcon]="widget.icon"
            [ttColor]="widget.iconColor"
            [ttType]="widget.type"
        ></tt-widget>
    </div>
</div>
