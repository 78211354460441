import { BislabService } from '@app/modules/bislab/services/bislab.service.ts';

(function () {
    'use strict';

    const module = angular.module('imApp');

    module.component('bislabSetup', {
        templateUrl: 'views/components/views/bislabSetup/bislabSetup.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'bislabService', 'modalService', 'translateService', 'stateService', 'bislabSetupService', function ($stateParams, bislabService, modalService, translateService, stateService, bislabSetupService) {

            const vm = this;

            vm.model = {
                bislab_apikey: '',
                get: {}
            }

            vm.locks = {
                test: false,
                save: false,
                delete: false
            }

            vm.translations = {
                w_bislab_setup_heading: '',
                bislab_token_test_success_title: '',
                bislab_token_test_success_message: '',
                bislab_token_test_fail_title: '',
                bislab_token_test_fail_message: '',
                ok: ''
            }

            vm.testToken = async function () {
                const response = await bislabService.testBearerToken(vm.model.bislab_apikey);

                if (response.ok === true) {
                    await _showModal({ type: 'success', title: vm.translations.bislab_token_test_success_title, message: vm.translations.bislab_token_test_success_message });
                } else {
                    await _showModal({ type: 'danger', title: vm.translations.bislab_token_test_fail_title, message: vm.translations.bislab_token_test_fail_message });
                }
            }

            vm.saveToken = async function () {
                vm.model.get.bislab_apikey = vm.model.bislab_apikey;
                vm.locks.save = true;
                const response = await bislabSetupService.save(vm.model.get);
                vm.locks.save = false;

                if (response[0].errorcode !== '0') {
                    await _showModal({ type: 'danger', title: '', message: response[0].errormessage });
                } else {
                    stateService.back();
                }
            }

            function _showModal({ type, message, title, buttons }) {
                return new Promise((resolve) => {
                    modalService.show({
                        type: type,
                        title: title,
                        message: message,
                        buttons: [{
                            label: vm.translations.ok,
                            cssClass: 'btn-' + type,
                            action: (modalRef) => {
                                modalRef.close()
                                return resolve();
                            }
                        },
                        ...(buttons ?? [])
                        ]
                    });
                });
            }

            async function _translate() {
                const data = await translateService.translateBatch(vm.translations);
                Object.keys(vm.translations).forEach((key) => {
                    if (data?.[key]) vm.translations[key] = data[key];
                });
            }

            async function getPageload() {
                vm.model.get = (await bislabSetupService.get($stateParams.businessco_no))[0];
            }

            vm.$onInit = async function () {
                _translate();
                await getPageload();
                vm.model.bislab_apikey = vm.model.get.bislab_apikey;
            }
        }]
    });
})();
