//(function () {
//'use strict';

//    let module = angular.module('imApp');

//    module.component('powtimeMenu', {
//        templateUrl: 'views/components/views/powtimeMenu/powtimeMenu.template.html?v=' + module.version, 
//        controllerAs: 'vm',
//        controller: ['$stateParams', 'stateService', function($stateParams, stateService) {
    
//            var vm = this;
    
    
//        vm.arg = {
//            cust_no: $stateParams.cust_no,
//            project_keyno: $stateParams.project_keyno,
//            projectprosess_keyno: $stateParams.projectprosess_keyno,
//            custact_no: $stateParams.custact_no,
//            date_start: $stateParams.date_start,
//            date_end: $stateParams.date_end,
//            grouptype: $stateParams.grouptype,
//            equipment_id: $stateParams.equipment_id,
//            labour_no: $stateParams.labour_no,
//            prod_id: $stateParams.prod_id
//        };
    
//        vm.menuitems = {
//            item_name: '',
//            item_path: '',
//            records: []
//        };
    
//        var menuItemsList = [ {
//            item_name: 'Alle timer',
//            item_path: 'powtimegroup/' + vm.arg.cust_no + '/' + vm.arg.project_keyno + '/' + vm.arg.projectprosess_keyno + '/' + vm.arg.custact_no + '/' + vm.arg.date_start + '/' + vm.arg.date_end + '/M/' + vm.arg.equipment_id + '/' + vm.arg.labour_no + '/' + vm.arg.prod_id,
//            item_state: 'powtimegroup',
//            item_parms: vm.arg.cust_no + '/' + vm.arg.project_keyno + '/' + vm.arg.projectprosess_keyno + '/' + vm.arg.custact_no + '/' + vm.arg.date_start + '/' + vm.arg.date_end + '/M/' + vm.arg.equipment_id + '/' + vm.arg.labour_no + '/' + vm.arg.prod_id
//        }, {
//            item_name: 'Pr Ansatt',
//            item_path: 'powtimegroup/' + vm.arg.cust_no + '/' + vm.arg.project_keyno + '/' + vm.arg.projectprosess_keyno + '/' + vm.arg.custact_no + '/' + vm.arg.date_start + '/' + vm.arg.date_end + '/L/' + vm.arg.equipment_id + '/' + vm.arg.labour_no + '/' + vm.arg.prod_id,
//            item_state: 'powtimegroup',
//            item_parms: vm.arg.cust_no + '/' + vm.arg.project_keyno + '/' + vm.arg.projectprosess_keyno + '/' + vm.arg.custact_no + '/' + vm.arg.date_start + '/' + vm.arg.date_end + '/L/' + vm.arg.equipment_id + '/' + vm.arg.labour_no + '/' + vm.arg.prod_id
//        }, {
//            item_name: 'Pr utstyr',
//            item_path: 'powtimegroup/' + vm.arg.cust_no + '/' + vm.arg.project_keyno + '/' + vm.arg.projectprosess_keyno + '/' + vm.arg.custact_no + '/' + vm.arg.date_start + '/' + vm.arg.date_end + '/E/' + vm.arg.equipment_id + '/' + vm.arg.labour_no + '/' + vm.arg.prod_id,
//            item_state: 'powtimegroup',
//            item_parms: vm.arg.cust_no + '/' + vm.arg.project_keyno + '/' + vm.arg.projectprosess_keyno + '/' + vm.arg.custact_no + '/' + vm.arg.date_start + '/' + vm.arg.date_end + '/E/' + vm.arg.equipment_id + '/' + vm.arg.labour_no + '/' + vm.arg.prod_id
//        }];
    
//        vm.menuitems.records.length = 0;
    
//        for (var i = 0; i < menuItemsList.length; i++) {
//            vm.menuitems.records.push(menuItemsList[i]);
//        }
    
//        vm.goToPowtimeGroup = function (l_s_grouptype) {
//            stateService.go('powtimegroup', {
//                cust_no: vm.arg.cust_no,
//                project_keyno: vm.arg.project_keyno,
//                projectprosess_keyno: vm.arg.projectprosess_keyno,
//                custact_no: vm.arg.custact_no,
//                date_start: vm.arg.date_start,
//                date_end: vm.arg.date_end,
//                grouptype: l_s_grouptype,
//                equipment_id: vm.arg.equipment_id,
//                labour_no: vm.arg.labour_no,
//                prod_id: vm.arg.prod_id
//            });
//        }
//    }]
//    });
//})();
