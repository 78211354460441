//(function () {
//    'use strict';

//    var module = angular.module("imApp");

//    module.factory("pohCompAddQuantService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
//        var service = {
//            loaded: false,
//            p2_pohcompaddquant_get: {
//                prod_id: '',
//                prod_name: '',
//                quantity: ''
//            },
//            loadP2PohCompAddQuantGet: function (pohcomp_keyno) {
//                var deferred = $q.defer();

//                var parms_p2_pohcompaddquant_get = {
//                    method: 159,
//                    parameters: {
//                        pohcomp_keyno: pohcomp_keyno
//                    }
//                };

//                $ihttp.post(parms_p2_pohcompaddquant_get).then(function (data) {
//                    service.p2_pohcompaddquant_get = angular.copy(data[0]);

//                    service.loaded = true;

//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            deletePohCompAddQuant: function () {
//                var deferred = $q.defer();

//                var parms_pohcompaddquant_delete = {
//                    method: 161,
//                    parameters: {
//                        pohcomp_keyno: $stateParams.pohcomp_keyno,
//                        prod_id: service.p2_pohcompaddquant_get.prod_id,
//                        prod_name: service.p2_pohcompaddquant_get.prod_name,
//                        quantity: service.p2_pohcompaddquant_get.quantity
//                    }
//                };

//                $ihttp.post(parms_pohcompaddquant_delete).then(function () {
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            },
//            savePohCompAddQuant: function () {
//                var deferred = $q.defer();

//                var parms_pohcompaddquant_save = {
//                    method: 160,
//                    parameters: {
//                        pohcomp_keyno: $stateParams.pohcomp_keyno,
//                        prod_id: service.p2_pohcompaddquant_get.prod_id,
//                        prod_name: service.p2_pohcompaddquant_get.prod_name,
//                        quantity: service.p2_pohcompaddquant_get.quantity
//                    }
//                };

//                $ihttp.post(parms_pohcompaddquant_save).then(function () {
//                    deferred.resolve();
//                });

//                return deferred.promise;
//            }
//        };

//        return service;
//    }]);
//})();
