(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('selectProdChoice', {
        templateUrl: 'views/components/views/selectProdChoice/selectProdChoice.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'selectProdChoiceService', 'searchService', function ($stateParams, selectProdChoiceService, searchService) {
            var vm = this;
            
            vm.filter_search_prod_choice = {
                prodchoice_search: ''
            };
            
            vm.state = {
                loading: false
            };
            
            vm.searchSelectProdChoice = function () {
                vm.state.loading = true;

                selectProdChoiceService.searchP2ProdChoiceSelectList(vm.filter_search_prod_choice.prodchoice_search, $stateParams.choice_keyno, $stateParams.argtype, $stateParams.argvalue).then(function () {
                    vm.p2_prodchoice_select_list = selectProdChoiceService.p2_prodchoice_select_list;
                    vm.state.loading = false;
                });
            };
            
            vm.searchSelectProdChoice();
            
            vm.preOrderLineEdit = function (item) {
                searchService.prodChoiceSelected = {
                    prod_id: item.item_id,
                    prod_name: item.item_name,
                    choice_keyno: $stateParams.choice_keyno
                };
            };
        }]
    });
})();