(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('purchasePlanning', {
        templateUrl: 'views/components/views/purchasePlanning/purchasePlanning.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'purchasePlanningService', 'stateService', function (utilityService, purchasePlanningService, stateService) {

            // #region VARIABLES & DEFINITIONS

            let vm = this;
            
            //vm.collections = {
            //    isReady: false,
            //    rememberId: 'w_purchaseplanning.collection',
            //    selectedCollectionId: '',
            //    selectedCollectionDescription: null,
            //    collectionsList: []
            //};

            vm.gridReady = false;

            vm.seasonyears = {
                isReady: false,
                rememberId: 'w_purchaseplanning.seasonyear',
                field: '',
                list: []
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'w_purchaseplanning',                   // Id used to retrieve user configs for the grid
                    loadSetupId: null,                                  // Uses default if null or undefined
                    loadData: {                                         // Method id of the get data procedure
                        method: 2268,
                        parameters: { seasonyear_id: vm.seasonyears.field }
                    },
                    addRow: {                                           // 
                        method: null,
                        parameters: null
                    },
                    removeRow: {                                        // 
                        method: null,
                        parameters: null
                    },
                    saveData: {
                        autoSave: true
                    }
                },
                translations: [],                                       // Comma separated list of words to be translated
                config: {                                               // TouchTime parameters configuring the grid
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        add: false,                                     // Adds predefined btns for running an add function     // Needs addRow
                        delete: false,                                  // Adds a predefined btn for running a delete function  // Needs removeRow
                        save: false,                                    // Adds a predefined btn for running a save function    // Needs saveData
                        wrapping: true,                                 // Adds a predefined btn for en-/disabling wrapping of text in columns
                        layouts: true,                                  // Adds a predefined btn for adding, setting or removing predefined column layouts
                        buttons: [                                      // List of custom buttons } },
                            { name: 'setProdplace', text: 'tt_po_sp_set_prodplace', func: function (data) { setProdplace(data); }, icon: 'none', cssClass: 'tt-button tt-button--primary im-grid-btn-xs-r', translate: true, disabled: function () { return hasDirty(); } },
                        ]
                    },
                    fixedHeader: true,                                  // Enables header to stay visible when scrolling
                    keepSortOnAdd: false,                               // Clears sorting when adding a row
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    specialFunc: {                                      // Adds additional functionality to the grid_functions column
                        newTab: true,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            //{ name: 'test', text: '', func: function (data) { testItems(data); }, icon: 'far-star' }
                            { name: 'goToPurchaseCreate', text: '', func: function (data) { goToPurchaseCreate(data); }, icon: 'far-industry', disabled: function (data) { return goToPurchaseCreateDisability(data); } },
                            { name: 'goToOrderOverviewStyle', text: '', func: function (data) { goToOrderOverviewStyle(data); }, icon: 'far-layer-group', disabled: function (data) { return goToOrderOverviewStyleDisability(data); } },
                            { name: 'goToProdTmSizeStockplace', text: '', func: function (data) { goToProdTmSizeStockplace(data); }, icon: 'far-warehouse', style: 'padding-left: 6px;' }
                        ]
                    },
                    css: {
                        altColor: true,                                 // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                        textWrapping: false                             // Enables text-wrapping in columns, default is false
                    },
                    onDataSourceChanges: function (e) {                 // Triggers when changes happen to the dataSource
                        //console.log('onDataSourceChanges');
                        //console.dir(e);
                    }
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: '100%',
                    aggregate: true,                                    // accepts -> true, false or list of objects (i.e: [{ field: 'amount_invval', aggregate: 'sum' } ])
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: false,                                  // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    //persistSelection: false,                            // NOT IN USE
                    filterable: false                                   // Adds/Removes filter row, default is set to { mode: "row" }
                },
                optionfunc: function (data) { }, // Used for referencing functions in the current Component
                gridfunc: null                                          // Used for referencing functions in the Grid Component
            };

            // #endregion VARIABLES & DEFINITIONS

            var getIsSelectedItemsParm = function (key) {
                var dirtyItems = vm.grid.gridfunc.getDirtyRows();
                var isSelectedItems = [];

                if (angular.isUndefined(key)) key = 'textilemodel_id';

                angular.forEach(dirtyItems, function (item) {
                    if (angular.isDefined(item) && item.is_selected === true && angular.isDefined(item[key])) isSelectedItems.push(item[key]);
                });

                return isSelectedItems;
            };

            var setProdplace = function () {
                var parmItems = getIsSelectedItemsParm();

                if (parmItems.length > 0) {
                    purchasePlanningService.getProdplaces().then(function (data) {
                        vm.grid.gridfunc.callPopupTable(data).then(function (value) {
                            if (value !== null) {
                                purchasePlanningService.setProdplace({ prodplace_id: value.prodplace_id, items: parmItems }).then(function (result) {
                                    if (result[0].errorcode === '0') {
                                        vm.grid.gridfunc.rebind();
                                    }
                                });
                            }
                        });
                    });
                }
            };

            var hasDirty = function () {
                var parmItems = getIsSelectedItemsParm();

                if (parmItems.length > 0) return false;

                return true;
            };

            // #region UTILITY FUNCTIONS

            var goTo = function (state, parms) {
                if (angular.isUndefined(state) || state === null || state === '') return;
                if (angular.isUndefined(parms) || parms === null || parms === '') return;

                stateService.go(state, parms);
            };

            // #endregion UTILITY FUNCTIONS

            // #region ROW BUTTONFUNC

            var goToPurchaseCreate = function (data) {
                goTo('purchasecreate', data.textilemodel_id);
            };

            var goToPurchaseCreateDisability = function (data) {
                if (angular.isUndefined(data) || angular.isUndefined(data.textilemodel_id) || data.textilemodel_id === null || data.textilemodel_id === '') return true;

                return false;
            };

            var goToOrderOverviewStyle = function (data) {
                goTo('purchaseplanningorderoverviewstyle', data.textilemodel_id);
            };

            var goToOrderOverviewStyleDisability = function (data) {
                if (angular.isUndefined(data) || angular.isUndefined(data.textilemodel_id) || data.textilemodel_id === null || data.textilemodel_id === '') return true;

                return false;
            };

            var goToProdTmSizeStockplace = function (data) {
                goTo('prodtmsizestockplace', data.textilemodel_id);
            };

            // #endregion ROW BUTTONFUNC

            // #region LOAD PROCEDURE CALLS

            angular.element(document).ready(function () {
                //purchasePlanningService.getRemember(null, vm.collections.rememberId).then(function (value) {
                purchasePlanningService.getRemember(null, vm.seasonyears.rememberId).then(function (value) {
                    if (angular.isDefined(value) && angular.isDefined(value[0]) && angular.isDefined(value[0].variablevalue)) {
                        if (value[0].variablevalue !== '') vm.seasonyears.field = angular.fromJson(value[0].variablevalue);
                        vm.grid.dataTask.loadData.parameters = { seasonyear_id: vm.seasonyears.field };
                    }

                    //purchasePlanningService.getCollectionsList().then(function (data) {
                    purchasePlanningService.getSeasonYearList().then(function (data) {
                        //angular.copy(data, vm.collections.collectionsList);
                        angular.copy(data, vm.seasonyears.list);

                        //if (vm.collections.selectedCollectionId !== '') vm.grid.gridfunc.rebind();
                        if (vm.seasonyears.field !== '') vm.grid.gridfunc.rebind();
                    });
                }).finally(() => vm.gridReady = true);
            });

            // #endregion LOAD PROCEDURE CALLS

            // #region REMEMBER VALUE PROCEDURE FUNCTIONS

            var rememberFunc = function (parm) {
                purchasePlanningService.remember(null, vm.seasonyears.rememberId, vm.seasonyears.field);
            };

            // #endregion REMEMBER VALUE PROCEDURE FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'field':
                        if (utilityService.validateWatchValue(value, vm.seasonyears.field) !== true) return;

                        vm.seasonyears.field = value;

                        if (utilityService.validateParmsValue(vm.seasonyears.field) !== true) return;

                        vm.grid.dataTask.loadData.parameters = { seasonyear_id: vm.seasonyears.field };

                        if (vm.seasonyears.field !== '' && vm.grid.gridfunc !== null) vm.grid.gridfunc.rebind();

                        rememberFunc();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS
            
        }]
    });
})();
