(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('invoiceMakeOverview', {
        templateUrl: 'views/components/views/invoiceMakeOverview/invoiceMakeOverview.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stateService', 'utilityService', 'rememberService', 'logisticService', 'invoiceMakeOverviewService', function ($q, stateService, utilityService, rememberService, logisticService, invoiceMakeOverviewService) {
            let vm = this;
    		
            let variableNames = {
                businessco_no: ''
            };

            vm.model = {
                setting: {},
                selectListCompanies: [],
                itemsListOverviews: []
            };

            let loadSetting = function () {
                let deferred = $q.defer();
                
                invoiceMakeOverviewService.getInvoiceMakeOverviews().then(function (result) {
                    angular.copy(result[0], vm.model.setting);

                    $q.all([
                        loadInvoiceMakeOverviews()
                    ]).then(() => deferred.resolve());
                });

                return deferred.promise;
            };

            let loadCompanies = function () {
                logisticService.listCompanies().then(function (result) {
                    angular.copy(result, vm.model.selectListCompanies);
                });
            };
            
            let loadInvoiceMakeOverviews = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;
                
                vm.model.itemsListOverviews = [];
                
                return invoiceMakeOverviewService.listInvoiceMakeOverviews(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListOverviews);
                });
            };
            
            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });
                
                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;
                
                let variableValue = null;
                
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateParmsValue(vm.model.setting[id], true) !== true) return;
                        
                        variableValue = vm.model.setting[id];
                        break;
                    default:
                        break;
                }
                
                return rememberService.remember(variableNames[id], variableValue);
            };
            
            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'businessco_no':
                        if (utilityService.validateWatchValue(value, vm.model.setting.businessco_no) !== true) return;

                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no, true) !== true) return;

                        rememberFunc('businessco_no');
                        loadInvoiceMakeOverviews();
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };
            
            vm.$onInit = function () {
                loadSetting();
                loadCompanies();
            };
        }]
    });
})();
