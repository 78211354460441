import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Style } from '@app/core/services/core-component.service';
import { LayoutService } from '@app/core/services/layout.service';
import { TranslateService } from '@app/core/services/translate.service';

@Component({
    selector: 'tt-popup-table',
    templateUrl: './popup-table.component.html',
    styleUrls: ['./popup-table.component.css'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class PopupTableComponent {
    public headers: string[] = [];

    public dataSource: Record<string, unknown>[] = [];

    /**
     * Map of words to translate.
     */
    public translations: { [key: string]: string } = {
        title: '',
        message: '',
        ok: '',
        cancel: '',
    };

    public selectedItem: Record<string, unknown> | null = null;

    /**
     * Styling to apply to the modal.
     */
    public style: Style = {
        table: {
            background: 'transparent',
        },
        cell: {
            padding: '0rem 0.2rem',
            border: '0.1rem solid rgba(var(--tt-body-text-color-rgb), 0.1)',
            color: 'var(--tt-body-text-color)',
        },
    };

    constructor(public dialogRef: MatDialogRef<PopupTableComponent>, @Inject(MAT_DIALOG_DATA) data: Record<string, unknown>[], private layoutService: LayoutService, private translateService: TranslateService) {
        this.headers = Object.keys(data[0]);
        this.dataSource = data;

        this.layoutService.layoutChanged.subscribe((info) => {
            if (info) {
                this.style['cell'].fontSize = info.fontSizes.textSize;
            }
        });
    }

    /**
     * Translates all words defined in `this.translations`.
     */
    private async translate() {
        // this.translations['title'] = await this.translateService.translate(this.data.title);
        // this.translations['message'] = await this.translateService.translate(this.data.message);
        // this.translations['ok'] = await this.translateService.translate(this.data.ok ?? 'ok');
        // this.translations['cancel'] = await this.translateService.translate(this.data.cancel ?? 'cancel');
    }

    public onClick(row: any) {
        if (row !== this.selectedItem) {
            this.selectedItem = row;
        } else {
            this.dialogRef.close(this.selectedItem);
        }
    }

    ngOnInit(): void {
        this.translate();
    }
}

/**
 * Represents data to configure the confirm modal with.
 */
export interface ConfirmModalData {
    /**
     * The type for the modal  (color for heading and buttons), if not defined will not use a type.
     */
    type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'none';

    /**
     * The title to display for the modal, will be translated.
     */
    title: string;

    /**
     * The message to explain what the user is confirming, will be translated.
     */
    message: string;

    /**
     * Ok text for the confirm button, will be translated.
     */
    ok?: string;

    /**
     * Cancel text for the cancel button, will be translated.
     */
    cancel?: string;
}
