(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('pickJobGrpBatchesPicker', {
        templateUrl: 'views/components/views/pickJobGrpBatchesPicker/pickJobGrpBatchesPicker.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['utilityService', 'pickJobGrpBatchesPickerService', 'logisticService', 'pickJobGrpBatchesService', function (utilityService, pickJobGrpBatchesPickerService, logisticService, pickJobGrpBatchesService) {
            var vm = this;

            vm.model = {
                bgPickJobGroupBatchesPicker: {
                    selected: 'JOBS',
                    buttons: [
                        { id: 'JOBS', label: 'bg_pickjobgrpbatchespicker_jobs', color: 'primary', onClick: function () { vm.model.bgPickJobGroupBatchesPicker.selected = 'JOBS'; } },
                        { id: 'OTHER', label: 'bg_pickjobgrpbatchespicker_other', color: 'primary', onClick: function () { vm.model.bgPickJobGroupBatchesPicker.selected = 'OTHER'; } },
                    ]
                },
                setting: {},
                selectListBusinessCompanies: [],
                selectListStockPlaces: [],
                itemsListPickers: []
            };

            pickJobGrpBatchesPickerService.getPickJobGroupBatchesPicker().then(function (data) {
                angular.copy(data[0], vm.model.setting);

                loadStockPlaces();
                loadPickJobGroupBatchesPickers();
            });

            logisticService.listCompanies().then(function (data) {
                angular.copy(data, vm.model.selectListBusinessCompanies);
            });

            var loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                pickJobGrpBatchesService.listStockPlaces({ businessco_no: vm.model.setting.businessco_no }).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            var loadPickJobGroupBatchesPickers = function () {
                if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;
                if (utilityService.validateParmsValue(vm.model.setting.stockplace_id, true) !== true) return;

                vm.model.itemsListPickers = [];

                pickJobGrpBatchesPickerService.listPickJobGroupBatchesPickers(vm.model.setting).then(function (data) {
                    angular.copy(data, vm.model.itemsListPickers);
                });
            };

            vm.handleFieldChanged = function (value, id) {
                if (id.startsWith('vm.') !== true) return;

                var parts = id.split('.');

                switch (parts[parts.length - 1]) {
                    case 'businessco_no':
                        vm.model.setting.businessco_no = value;

                        if (utilityService.validateParmsValue(vm.model.setting.businessco_no) !== true) return;

                        loadStockPlaces();
                        break;
                    default:
                        utilityService.setValueToVariable(vm, parts, value);
                        //eval(id + " = '" + value + "'");
                        //break;
                }
            };
        }]
    });
})();
