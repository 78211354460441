(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("portalUserSignatureService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadP2WPortalUserSignatureGet: function (portal_user_keyno) {
                return p2DataTaskService.call(975, {
                    portal_user_keyno: portal_user_keyno
				});
			},
            saveP2WPortalUserSignature: function (edit) {
                return p2DataTaskService.call(976, edit);
			}
        };

        return service;
    }]);
})();
