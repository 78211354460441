(function () {
    'use strict';

    angular.module("imApp").factory("orderLineCreditsService", ['$ihttp', function ($ihttp) {
        var service = {
            getOrderLineCredits: function (parms) {
                return $ihttp.post({
                    method: 2756,
                    parameters: parms || {}
                });
            },
            listSelectionIds: function (parms) {
                return $ihttp.post({
                    method: 2759,
                    parameters: parms || {}
                });
            }
            //selectItem: function (edit) {
            //    return $ihttp.post({
            //        method: 2759,
            //        parameters: edit || {}
            //    });
            //}
        };

        return service;
    }]);
})();