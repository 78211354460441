(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('orderLineBom', {
        templateUrl: 'views/components/views/orderLineBom/orderLineBom.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$q', 'stateService', 'utilityService', 'translateService', 'modalService', 'orderLineBomService', 'typeaheadService', function ($stateParams, $q, stateService, utilityService, translateService, modalService, orderLineBomService, typeaheadService) {

            // #region VARIABLES & DEFINITIONS

            const vm = this;
            let orderLineKeyno = $stateParams.orderline_keyno;

            let translations = {
                confirm_title: '',
                confirm_message: '',
                confirm_ok_label: '',
                confirm_cancel_label: '',
                error: '',
                ok: ''
            };

            vm.model = {
                prodIdButtons: [
                    { id: 'goto', icon: 'fa fa-solid fa-chevron-right', color: 'primary', type: 'primary', onClick: () => stateService.go('product', { prod_id: vm.model.menu.prod_keyno }) }
                ],
                setting: {},
                menu: {},
                treeListBoms: [],
                lockedSwitch: false,
                lockedAdd: false,
                lockedDelete: false
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region TRANSLATE SERVICE

            translateService.translateBatch(translations).then(function (data) {
                angular.forEach(translations, function (_, key) {
                    if (angular.isDefined(data[key])) {
                        translations[key] = data[key];
                    }
                });
            });

            // #endregion TRANSLATE SERVICE

            // #region LOAD PROCEDURE CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                orderLineBomService.getSetting({ orderline_keyno: orderLineKeyno }).then(function (info) {
                    angular.copy(info[0], vm.model.setting);

                    loadBoms();
                    loadMenu();

                    deferred.resolve();
                });

                return deferred.promise;
            };

            let loadBoms = function () {
                if (utilityService.validateParmsValue(vm.model.setting.orderline_keyno) !== true) return;
                
                vm.model.treeListBoms = [];
                
                return orderLineBomService.listBoms({ orderline_keyno: vm.model.setting.orderline_keyno }).then(function (list) {
                    angular.copy(list, vm.model.treeListBoms);
                });
            };
            
            let loadMenu = function () {
                if (utilityService.validateParmsValue(vm.model.setting.olm_keyno_top) !== true) return;
                
                vm.model.menu = {};
                
                return orderLineBomService.getMenu({ olm_keyno: vm.model.setting.olm_keyno_top }).then(function (info) {
                    angular.copy(info[0], vm.model.menu);
                });
            };

            // #endregion LOAD PROCEDURE CALLS

            // #region TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            vm.typeaheadSearch = function (value, id) {
                switch (id) {
                    case 'new_prod_name':
                        vm.model.menu[id] = value;

                        return typeaheadService.searchProduct({ prod_name: vm.model.menu[id] || '' });
                        break;
                    default:
                        break;
                }
            };

            // #endregion TYPEAHEAD SEARCH SINGLE PROCEDURE FUNCTION CALL

            // #region SELECTED VALUE FUNCTION

            vm.onTypeaheadSelected = function (item, id) {
                switch (id) {
                    case 'new_prod_name':
                        if (item) {
                            vm.model.menu.new_prod_id = item?.item_id ?? '';
                            vm.model.menu.new_prod_name = item?.item_name ?? '';
                        } else {
                            vm.onTypeaheadClear('new_prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion SELECTED VALUE FUNCTION

            // #region CHANGED VALUE FUNCTION

            vm.onTypeaheadChanged = function (value, item, id) {
                switch (id) {
                    case 'new_prod_name':
                        vm.model.menu.new_prod_name = value;

                        if (value) {
                            if (item?.item_id) {
                                vm.model.menu.new_prod_id = item.item_id;
                            }
                        } else {
                            vm.onTypeaheadClear('new_prod_name');
                        }
                        break;
                    default:
                        break;
                }
            };

            // #endregion CHANGED VALUE FUNCTION

            // #region CLEAR VALUE FUNCTION

            vm.onTypeaheadClear = function (id) {
                switch (id) {
                    case 'new_prod_name':
                        vm.model.menu.new_prod_id = '';
                        vm.model.menu.new_prod_name = '';
                        break;
                    default:
                        break;
                }
            };

            // #endregion CLEAR VALUE FUNCTION

            // #region TREE SELECTION FUNCTION
            
            vm.selectTreeItem = function (item) {
                vm.model.setting.olm_keyno_top = item.item_id;
                loadMenu();
            };

            // #endregion TREE SELECTION FUNCTION

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.switchChanges = function () {
                vm.model.lockedSwitch = true;

                orderLineBomService.switchObj(vm.model.menu).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSwitch = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedSwitch = false;
                        loadSetting();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON SAVE PROCEDURE FUNCTION CALL

            vm.addChanges = function () {
                vm.model.lockedAdd = true;

                orderLineBomService.addObj(vm.model.menu).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                            title: translations.error,
                            message: response[0].errormessage,
                            buttons: [{
                                label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedAdd = false;
                    			}
                    		}]
                    	});
                    } else {
                        vm.model.lockedAdd = false;
                        loadSetting();
                    }
                });
            };

		    // #endregion BUTTON SAVE PROCEDURE FUNCTION CALL

            // #region BUTTON DELETE PROCEDURE FUNCTION CALL

            vm.deleteValues = function () {
                vm.model.lockedDelete = true;

                modalService.confirm({
                    type: 'danger',
                    title: translations.confirm_title,
                    message: translations.confirm_message,
                    okLabel: translations.confirm_ok_label,
                    cancelLabel: translations.confirm_cancel_label
                }).then(function () {
                    orderLineBomService.deleteItem(vm.model.menu).then(function (response) {
                        if (response[0].errorcode !== '0') {
                        	modalService.show({
                        		type: 'warning',
                        		title: translations.error,
                        		message: response[0].errormessage,
                        		buttons: [{
                        			label: translations.ok,
                        			cssClass: 'btn-warning',
                        			action: function (dialogItself) {
                        				dialogItself.close();
                        				vm.model.lockedDelete = false;
                        			}
                        		}]
                        	});
                        } else {
                        	vm.model.lockedDelete = false;
                            loadSetting();
                        }
                    }, function () {
                        vm.model.lockedDelete = false;
                    });
                }, function () {
                    vm.model.lockedDelete = false;
                });
            };

            // #endregion BUTTON DELETE PROCEDURE FUNCTION CALL

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
            };

            // #endregion ON INIT FUNCTION

        }]
    });
})();
