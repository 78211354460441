(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('accessInfo', {
        templateUrl: 'views/components/views/accessInfo/accessInfo.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'utilityService', 'accessInfoService', function ($stateParams, utilityService, accessInfoService) {

            // #region VARIABLES & DEFINITIONS

            var vm = this;
            var argType = $stateParams.argtype;
            var argValue = $stateParams.argvalue;

            vm.model = {
                setting: {},
                itemsListSubscriptions: []
            };

            // #endregion VARIABLES & DEFINITIONS

            // #region LOAD PROCEDURE FUNCTION CALLS

            accessInfoService.getAccessInfo({
                argtype: argType,
                argvalue: argValue
            }).then(function (result) {
                angular.copy(result[0], vm.model.setting);

                loadSubscriptions();
            });

            var loadSubscriptions = function () {
                if (utilityService.validateParmsValue(vm.model.setting.page_heading) !== true) return;

                vm.model.itemsListSubscriptions = [];

                accessInfoService.listSubscriptions(vm.model.setting).then(function (result) {
                    angular.copy(result, vm.model.itemsListSubscriptions);
                });
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS
        }]
    });
})();