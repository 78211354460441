(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('customsTarif', {
        templateUrl: 'views/components/views/customsTarif/customsTarif.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'translateService', 'customsTarifService', function ($stateParams, stateService, modalService, translateService, customsTarifService) {
            const vm = this;
            let customsTarifId = $stateParams.customstarif_id;

            let translations = {
            	error: '',
            	ok: ''
            };

            vm.model = {
                customsTarifId: customsTarifId,
                edit: {},
                lockedSave: false
            };

            translateService.translateBatch(translations).then(function (data) {
            	angular.forEach(translations, function (_, key) {
            		if (angular.isDefined(data[key])) {
            			translations[key] = data[key];
            		}
            	});
            });

            let loadEdit = function () {
                customsTarifService.getTarif({ customstarif_id: customsTarifId }).then(function (info) {
                    angular.copy(info[0], vm.model.edit);
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                customsTarifService.saveObj(vm.model.edit).then(function (response) {
                    if (response[0].errorcode !== '0') {
                    	modalService.show({
                    		type: 'warning',
                    		title: translations.error,
                    		message: response[0].errormessage,
                    		buttons: [{
                    			label: translations.ok,
                    			cssClass: 'btn-warning',
                    			action: function (dialogItself) {
                    				dialogItself.close();
                    				vm.model.lockedSave = false;
                    			}
                    		}]
                    	});
                    } else {
                    	vm.model.lockedSave = false;
                    	stateService.back();
                    }
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
