(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("ohDirectDeliveryService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadOhDirectDeliveryGet: function (order_internal_no) {
                return p2DataTaskService.call(1694, {
                    order_internal_no: order_internal_no
                });
            },
            loadOhDirectDeliveryList: function (settingDirectDelivery) {
                return p2DataTaskService.call(1695, settingDirectDelivery);
            },
            deliver: function (mergeObj) {
                return p2DataTaskService.call(1696, mergeObj);
            }
        };

        return service;
    }]);
})();