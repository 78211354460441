(function() {
    'use strict';

    angular.module("imApp").factory("modalWordlangService", ['$ihttp', function ($ihttp) {
        var service = {
            load: function (word_id) {
                return $ihttp.post({
                    method: 2396,
                    parameters: {
                        state: 'load',
                        word_id: word_id
                    }
                });
            },

            update: function (word_id, translations) {
                return $ihttp.post({
                    method: 2396,
                    parameters: {
                        state: 'update',
                        word_id: word_id,
                        translations: translations
                    }
                });
            },

            updateKeeptext: function (word_id, language_id, keeptext) {
                return $ihttp.post({
                    method: 2396,
                    parameters: {
                        state: 'update_keeptext',
                        word_id: word_id,
                        language_id: language_id,
                        keeptext: keeptext
                    }
                });
            },

            updateWordlangName: function (word_id, language_id, wordlangName) {
                return $ihttp.post({
                    method: 2396,
                    parameters: {
                        state: 'update_wordlang_name',
                        word_id: word_id,
                        language_id: language_id,
                        wordlang_name: wordlangName
                    }
                });
            },

            new: function (word_id) {
                return $ihttp.post({
                    method: 2396,
                    parameters: {
                        state: 'new',
                        word_id: word_id
                    }
                });
            }
        }

        return service;
    }]);
})();
