(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('invoiceHead', {
        templateUrl: 'views/components/views/invoiceHead/invoiceHead.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'invoiceHeadService', function ($stateParams, stateService, modalService, invoiceHeadService) {
            var vm = this;

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'customeredit1':
                        stateService.go('customeredit', {
                            cust_no: vm.model.p2InvoiceHeadGet.stat_cust_no
                        });
                        break;
                    case 'customeredit2':
                        stateService.go('customeredit', {
                            cust_no: vm.model.p2InvoiceHeadGet.delivery_cust_no
                        });
                        break;
                    case 'customeredit3':
                        stateService.go('customeredit', {
                            cust_no: vm.model.p2InvoiceHeadGet.invoice_cust_no
                        });
                        break;
                    case 'customersmenu1':
                        stateService.go('customersmenu', {
                            cust_no: vm.model.p2InvoiceHeadGet.stat_cust_no
                        });
                        break;
                    case 'customersmenu2':
                        stateService.go('customersmenu', {
                            cust_no: vm.model.p2InvoiceHeadGet.delivery_cust_no
                        });
                        break;
                    case 'customersmenu3':
                        stateService.go('customersmenu', {
                            cust_no: vm.model.p2InvoiceHeadGet.invoice_cust_no
                        });
                        break;
                    case 'tracesearch':
                        stateService.go('tracesearch', {
                            argtype: 'invoice_no',
                            argvalue: vm.model.p2InvoiceHeadGet.invoice_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                gbMainView: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'invoicehead_invoiceline', item_func: 'INVOICELINE' },
                        { item_id: '1', item_name: 'invoicehead_invoicehead', item_func: 'INVOICEHEAD' },
                        { item_id: '2', item_name: 'invoicehead_functions', item_func: 'FUNCTIONS' }
                    ]
                },
                invoiceHeadHeadMainButtonGrp: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'invoicehead_address', item_func: 'ADDRESS' },
                        { item_id: '1', item_name: 'invoicehead_terms', item_func: 'TERMS' },
                        { item_id: '2', item_name: 'invoicehead_stat', item_func: 'STAT' }
                    ]
                },
                invoiceHeadHeadAddressButtonGrp: {
                    item_id: '',
                    item_name: '',
                    item_default: '0',
                    item_selected: '0',
                    records: [
                        { item_id: '0', item_name: 'invoicehead_stat_address', item_func: 'ADRSTAT' },
                        { item_id: '1', item_name: 'invoicehead_delivery_address', item_func: 'ADRDEL' },
                        { item_id: '2', item_name: 'invoicehead_invoice_address', item_func: 'ADRINV' }
                    ]
                },
                statCustNoButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customeredit1' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customersmenu1' } }
                ],
                deliveryCustNoButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customeredit2' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customersmenu2' } }
                ],
                invoiceCustNoButtons: [
                    { id: 'edit', icon: 'glyphicon glyphicon-edit', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customeredit3' } },
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customersmenu3' } }
                ],
                p2InvoiceHeadGet: {},
                p2InvoiceLineList: {
                    records: []
                },
                p2PaymentList: {
                    records: []
                },
                p2FactoringList: {
                    records: []
                },
                sendingInvoice: false,
                lockedSave: false
            };
            
            vm.selectGbMainView = function (item) {
                vm.model.gbMainView.item_selected = item;
            };
            
            vm.selectInvoiceHeadHeadMainButtonGrp = function (item) {
                vm.model.invoiceHeadHeadMainButtonGrp.item_selected = item;
            };
            
            vm.selectInvoiceHeadHeadAddressButtonGrp = function (item) {
                vm.model.invoiceHeadHeadAddressButtonGrp.item_selected = item;
            };
            
            vm.loadP2InvoiceHeadGet = function () {
                invoiceHeadService.genericFunction(751, {
                    invoice_no: $stateParams.invoice_no
                }).then(function (data) {
                    vm.model.p2InvoiceHeadGet = angular.copy(data[0]);
                });
            };
            
            vm.loadP2InvoiceHeadGet();
            
            vm.loadP2InvoiceLineList = function () {
                invoiceHeadService.genericFunction(752, {
                    invoice_no: $stateParams.invoice_no
                }).then(function (data) {
                    angular.copy(data, vm.model.p2InvoiceLineList.records);
                });
            };
            
            vm.loadP2InvoiceLineList();
            
            vm.loadP2PaymentList = function () {
                invoiceHeadService.genericFunction(624, {}).then(function (data) {
                    angular.copy(data, vm.model.p2PaymentList.records);
                });
            };
            
        	vm.loadP2PaymentList();
            
            vm.loadP2FactoringList = function () {
                invoiceHeadService.genericFunction(625, {}).then(function (data) {
                    angular.copy(data, vm.model.p2FactoringList.records);
                });
            };
            
            vm.loadP2FactoringList();

            vm.goToSelectReport = function (index) {
                stateService.go('selectreport', {
                    webpage_name: stateService.getCurrentName(),
                    argtype: 'invoice_no',
                    argvalue: $stateParams.invoice_no
                });
            };
            
            vm.goToInvoiceHeadEhf = function () {
                stateService.go('invoiceheadehf', {
                    invoice_no: $stateParams.invoice_no
                });
            };
            
            vm.goToDocuments = function () {
                stateService.go('documents', {
                    argtype: 'invoice_no',
                    argvalue: vm.model.p2InvoiceHeadGet.invoice_no
                });
            };
            
            vm.goToEmailCreate = function () {
                stateService.go('emailcreate', {
                    webpage: stateService.getCurrentName(),
                    argtype: 'invoice_no',
                    argvalue: vm.model.p2InvoiceHeadGet.invoice_no
                });
            };
            
            vm.setPrintedOk = function () {
                invoiceHeadService.genericFunction(828, {
                    invoice_no: $stateParams.invoice_no
                }).then(function (data) {
                    stateService.back();
                });
            };

            vm.sendChanges = function () {
                vm.model.sendingInvoice = true;

                invoiceHeadService.sendInvoice({ invoice_no: vm.model.p2InvoiceHeadGet.invoice_no }).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.sendingInvoice = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.sendingInvoice = false;
                        stateService.back();
                    }
                });
            };

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                invoiceHeadService.saveObj(vm.model.p2InvoiceHeadGet).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                    }
                });
            };
        }]
    });
})();