(function () {
    'use strict';

    angular.module("imApp").factory("stockMinMaxAdjustService", ['$ihttp', function ($ihttp) {
        let service = {
            getStockMinMaxAdjust: function (parms) {
                return $ihttp.post({
                    method: 2211,
                    parameters: parms || {}
                });
            },
            saveGrid: function (mergeObj) {
                return $ihttp.post({
                    method: 2213,
                    parameters: mergeObj
                });
            }
        };

        return service;
    }]);
})();
