(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('emailSmartTags', {
        templateUrl: 'views/components/views/emailSmartTags/emailSmartTags.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', 'stateService', 'emailInboxService', 'emailService', 'utilityService', function($stateParams, $timeout, stateService, emailInboxService, emailService, us) {
            var vm = this;
            var timer = null;

            if ($stateParams.account_keyno !== ''){
                emailService.defaultAccount = $stateParams.account_keyno;
            }
            
            vm.listMenu = [];
            vm.checkMenu = [];
            vm.messages = [];
            vm.categories = [];
            vm.mm_customer = [];
            vm.mm_supplier = [];
            vm.mm_unknown = [];
            vm.mm_spam = [];
            vm.mm_archive = [];
            vm.mm_all = [];
            vm.mm = [];
            vm.lm = [];
            vm.cm = [];
            vm.filter = '';
            vm.selectedCount = 0;
            vm.showChecked = false;
            vm.email_edit_label = 'email_edit_off';
            
            vm.resetMenus = function () {
                vm.mm_customer = [];
                vm.mm_supplier = [];
                vm.mm_unknown = [];
                vm.mm_spam = [];
                vm.mm_archive = [];
                vm.mm_all = [];
            };
            
            vm.resetMessages = function () {
                vm.lm = [];
                vm.cm = [];
            };
            
            vm.handleFilter = function () {
                vm.resetMenus();

                var customer_emails = {
                    jangunnarhole_gmail_com: 'Insoft Norge AS',
                    bhw_vintervev_no: 'Insoft Norge AS'
                };

                var supplier_emails = {
                    jangunnarhole_gmail_com: 'Insoft Norge AS',
                    bhw_vintervev_no: 'Insoft Norge AS',
                    winsjansen_gmail_com: 'Insoft Norge AS'
                };

                angular.forEach(vm.messages, function (msg) {
                    if (us.emailString(msg.msgFrom) in customer_emails) {
                        vm.mm_customer.push(msg);
                    } else if (us.emailString(msg.msgFrom) in supplier_emails) {
                        vm.mm_supplier.push(msg);
                    } else {
                        vm.mm_supplier.push(msg);
                    }

                    vm.mm_all.push(msg);
                });

                vm.mm = [];

                vm.mm.push({
                    item_id: 1,
                    item_name: 'Alle',
                    item_parms: 1,
                    item_glyphicon_color: 'black',
                    message_count: us.objectLength(vm.mm_all)
                });

                vm.mm.push({
                    item_id: 2,
                    item_name: 'Kunder',
                    item_parms: 2,
                    item_glyphicon_color: 'black',
                    message_count: us.objectLength(vm.mm_customer)
                });

                vm.mm.push({
                    item_id: 3,
                    item_name: 'Leverandører',
                    item_parms: 3,
                    item_glyphicon_color: 'black',
                    message_count: us.objectLength(vm.mm_supplier)
                });

                vm.mm.push({
                    item_id: 4,
                    item_name: 'Ukjente',
                    item_parms: 4,
                    item_glyphicon_color: 'black',
                    message_count: us.objectLength(vm.mm_unknown)
                });

                vm.mm.push({
                    item_id: 5,
                    item_name: 'Autoarkivert',
                    item_parms: 5,
                    item_glyphicon_color: 'black',
                    message_count: us.objectLength(vm.mm_archive)
                });

                vm.mm.push({
                    item_id: 6,
                    item_name: 'Spam',
                    item_parms: 6,
                    item_glyphicon_color: 'black',
                    message_count: us.objectLength(vm.mm_spam)
                });
            };
            
            vm.setFilterDisplay = function () {
                $('#categories').show();
                $('#messages').hide();
            };
            
            vm.setFilter = function (item) {
                vm.resetMessages();

                switch (item.item_id) {
                    case 2:
                        angular.forEach(vm.mm_customer, function (msg) {
                            var lm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon_color: 'black'
                            };

                            var cm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon: 'glyphicon-unchecked',
                                item_glyphicon_2: 'glyphicon-check',
                                item_is_selected: '0',
                                item_glyphicon_color: 'black'
                            };

                            vm.lm.push(lm);
                            vm.cm.push(cm);
                        });
                        break;
                    case 3:
                        angular.forEach(vm.mm_supplier, function (msg) {
                            var lm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon_color: 'black'
                            };

                            var cm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon: 'glyphicon-unchecked',
                                item_glyphicon_2: 'glyphicon-check',
                                item_is_selected: '0',
                                item_glyphicon_color: 'black'
                            };

                            vm.lm.push(lm);
                            vm.cm.push(cm);
                        });
                        break;
                    case 4:
                        angular.forEach(vm.mm_unknown, function (msg) {
                            var lm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon_color: 'black'
                            };

                            var cm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon: 'glyphicon-unchecked',
                                item_glyphicon_2: 'glyphicon-check',
                                item_is_selected: '0',
                                item_glyphicon_color: 'black'
                            };

                            vm.lm.push(lm);
                            vm.cm.push(cm);
                        });
                        break;
                    case 5:
                        angular.forEach(vm.mm_archive, function (msg) {
                            var lm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon_color: 'black'
                            };

                            var cm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon: 'glyphicon-unchecked',
                                item_glyphicon_2: 'glyphicon-check',
                                item_is_selected: '0',
                                item_glyphicon_color: 'black'
                            };

                            vm.lm.push(lm);
                            vm.cm.push(cm);
                        });
                        break;
                    case 6:
                        angular.forEach(vm.mm_spam, function (msg) {
                            var lm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon_color: 'black'
                            };

                            var cm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon: 'glyphicon-unchecked',
                                item_glyphicon_2: 'glyphicon-check',
                                item_is_selected: '0',
                                item_glyphicon_color: 'black'
                            };

                            vm.lm.push(lm);
                            vm.cm.push(cm);
                        });
                        break;
                    case 1:
                    default:
                        angular.forEach(vm.mm_all, function (msg) {
                            var lm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon_color: 'black'
                            };

                            var cm = {
                                item_id: msg.msgUid,
                                item_name: msg.msgFromName,
                                item_name_sub1: msg.msgSubject,
                                item_name_sub2: msg.msgPlainText,
                                item_state: 'emailedit',
                                item_parms: msg.msgUid,
                                item_glyphicon: 'glyphicon-unchecked',
                                item_glyphicon_2: 'glyphicon-check',
                                item_is_selected: '0',
                                item_glyphicon_color: 'black'
                            };

                            vm.lm.push(lm);
                            vm.cm.push(cm);
                        });
                        break;
                }

                emailInboxService.lm = vm.lm;
                emailInboxService.cm = vm.cm;

                stateService.go('emailinbox', {
                    account_keyno: ''
                });

                //$('#categories').hide();
                //v$('#messages').show();
            };

            vm.reloadEmails = function () {
                vm.messageList = [];

                emailInboxService.loadVvAccount(emailService.defaultAccount).then(function () {
                    vm.messages = [];
                    vm.messages = emailInboxService.vvMessage.records;

                    vm.handleFilter();

                    /*vm.messageList = emailInboxService.vvMessage.records;
                    vm.lm = emailInboxService.vvMessage.listMenu;
                    vm.cm = emailInboxService.vvMessage.checkMenu;*/
                });
            };

            vm.reloadEmails();
            
            vm.messageOptions = {
                item_id: 0,
                item_name: '',
                item_default: '-1',
                item_selected: '-1',
                records: [
                    { item_id: '1', item_name: 'email_list_action_accounts', item_func: '-1' },
                    { item_id: '2', item_name: 'email_list_action_mark', item_func: '2' }
                ]
            };
            
            vm.checkButtonClick = function () {
                if (vm.email_edit_label == 'email_edit_off') {
                    vm.email_edit_label = 'email_edit_on';
                    vm.showChecked = true;
                    timer = $timeout(vm.checkSelectedCount, 500);
                } else {
                    vm.email_edit_label = 'email_edit_off';
                    vm.showChecked = false;
                    if (timer) $timeout.cancel(timer);
                    vm.unselectAll();
                }

                //vm.showChecked = !vm.showChecked;

                /*if (vm.showChecked){
                    $('#formEmailInbox-check').removeClass('btn-default');
                    $('#formEmailInbox-check').addClass('btn-primary'); 
                    $('#formEmailInbox-check').text('AVBRYT');
                    timer = $timeout(vm.checkSelectedCount,500);
                } else {
                    $('#formEmailInbox-check').removeClass('btn-primary');
                    $('#formEmailInbox-check').addClass('btn-default');
                    $('#formEmailInbox-check').text('REDIGER');
                    if (timer) $timeout.cancel(timer);
                    vm.unselectAll();
                }*/
            };
            
            vm.checkSelectedCount = function () {
                vm.selectedCount = 0;

                angular.forEach(vm.cm, function (item) {
                    if (item.item_is_selected > 0) {
                        vm.selectedCount += 1;
                    }
                });

                timer = $timeout(vm.checkSelectedCount, 500);
            };

            vm.deleteButtonClick = function () {
                var deleteCount = 0;
                var ids = [];

                angular.forEach(vm.cm, function (item) {
                    if (item.item_is_selected > 0) {
                        ids.push(item.item_id);
                        deleteCount += 1;
                    }
                });

                emailInboxService.deleteVvMessages(ids).then(function () {
                    vm.email_edit_label = 'email_edit_off';
                    vm.showChecked = false;

                    if (timer) $timeout.cancel(timer);

                    vm.unselectAll();
                    vm.reloadEmails();
                });
            };
            
            vm.unselectAll = function () {
                angular.forEach(vm.cm, function (item) {
                    item.item_is_selected = '0';
                    item.item_glyphicon_color = 'black';
                });

                vm.checkSelectedCount();
            };
            
            vm.accountButtonClick = function () {
                emailService.defaultAccount = '';
                emailService.messageActive = false;

                stateService.go('email', {});
            };
    	}]
    });
})();    