(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('ttButtonMulti', {
        templateUrl: 'views/components/directives/ttButtonMulti/ttButtonMulti.template.html?v=' + module.version,
        controllerAs: 'vm',
        bindings: {
            ttButtons: '<',
            ttLocked: '<',
            ttLockedText: '@',
            ttClick: '&',
            ttType: '@',
            ttDisabled: '<',
            ttItemId: '@',
            ttStyle: '<',
            ttClass: '@'
        },
        controller: ['$document', '$element', '$timeout', 'layoutService', 'eventService', 'translateService', 'utilityService', 'ttDirectivesService', function ($document, $element, $timeout, layoutService, eventService, translateService, us, ttDirectivesService) {
            let vm = this;

            //vm.buttons = [
            //    {
            //        label: "accountingprocessing_approve",
            //        isMain: true,
            //        disabled: function () { return true; },
            //        onClick: () => console.log('IM ALIVE')
            //    },
            //    {
            //        label: "2",
            //        icon: 'glyphicon glyphicon-eye-open',
            //        isSecondary: true,
            //        disabled: function () { return true; },
            //        hidden: true,
            //        onClick: () => console.log('IM ALIVE x2')
            //    },
            //    {
            //        label: "another label",
            //        icon: 'glyphicon glyphicon-chevron-down',
            //        isSecondary: true,
            //        disabled: function () { return true; },
            //        hidden: false,
            //        onClick: () => console.log('IM ALIVE x3')
            //    },
            //    {
            //        label: "another label",
            //        icon: 'glyphicon glyphicon-comment',
            //        isSecondary: true,
            //        isDropdown: true,
            //        disabled: function () { return false; },
            //        hidden: true,
            //        onClick: () => console.log('IM ALIVE x4')
            //    },
            //    {
            //        label: "another labelll",
            //        icon: 'glyphicon glyphicon-plane',
            //        isDropdown: true,
            //        disabled: function () { return false; },
            //        hidden: false,
            //        onClick: () => console.log('IM ALIVE x6')
            //    },
            //]

            let onDestroy = [];
            vm.dropdownHidden = true;

            vm.mainButton = {};
            vm.secondaryButtons = [];
            vm.dropdownButtons = [];
            vm.ttButtons = [];
            vm.showButtonContent = false;
            vm.showBadge = false;
            vm.disabled = false;
            vm.locked = false;

            vm.id = {
                dropdownMenu: uuid(),
            };

            vm.style = {
                button: { padding: '0 1.1rem' },
                lockedButton: { },
                badge: {},
                icon: {}
            };

            vm.class = {
                icon: '',
                button: 'tt-button---primary'
            };

            vm.translations = {
                text: '',
                lockedText: 'locked',
                mainButton: '',
                secondaryButtons: [],
                dropdownButtons: [],
            };

            vm.whenReady = function () {
                eventService.trigger('element:ready');
            };

            vm.onClick = function () {
                if (angular.isFunction(vm.ttClick)) {
                    vm.ttClick();
                }
            };

            vm.clickOutside = function (element, onClickOutside) {
                if (vm.dropdownButtons.length !== 0) {

                    $document.on('click', (event) => {
                        if (element === event.target || element[0].contains(event.target) === true) return;

                        onClickOutside();
                    });
                }
            }

            let setStyle = (ttStyle = vm.ttStyle) => angular.copy(ttDirectivesService.setStyle({ style: vm.style, ttStyle: ttStyle, textAlign: vm.ttTextAlign, mainElement: ['button', 'lockedButton'] }), vm.style);

            layoutService.onLayoutChanged(onDestroy, function (info) {
                if (angular.isUndefined(info)) return;

                ttDirectivesService.setLayoutStyle(vm.style, info);

                setStyle(vm.ttStyle);
            });

            vm.$onInit = function () {
                if (angular.isDefined(vm.ttType) && vm.ttType !== null && vm.ttType.length > 0) {
                    vm.class.button = 'tt-button--' + vm.ttType;
                }

                setStyle(vm.ttStyle);
            }

            vm.$onChanges = async function (changes) {
                //let words = [];

                if (changes?.ttStyle?.currentValue) {
                    setStyle(changes.ttStyle.currentValue);
                }

                if (changes?.ttButtons?.currentValue) {
                    changes.ttButtons.currentValue.forEach((button) => {
                        if (button.isMain === true) {
                            vm.mainButton = button;
                            vm.translations.mainButton = button.label;
                        }

                        if (button.isSecondary === true) {
                            vm.secondaryButtons.push(button);
                            vm.translations.secondaryButtons.push(button.label);
                        }

                        if (button.isDropdown === true) {
                            vm.dropdownButtons.push(button);
                            vm.translations.dropdownButtons.push(button.label);
                        }
                    });

                    for (let word in vm.translations) {
                        if (angular.isString(vm.translations[word]) && vm.translations[word] !== null) {

                            translateService.translate(vm.translations[word]).then((translation) => vm.translations[word] = translation);
                        } else if (vm.translations[word] instanceof Array) {
                            vm.translations[word].forEach((item) => translateService.translate(item).then((translation) => item = translation));
                        }

                        console.dir(vm.translations);
                    }

                    vm.showButtonContent = true;
                }

                $timeout(() => vm.clickOutside($element.find('#' + vm.id.dropdownMenu), () => vm.dropdownHidden = true));
                //if (angular.isDefined(changes.ttLockedText) && changes.ttLockedText.currentValue !== changes.ttLockedText.previousValue) {
                //    let hasLockedText = isStringValue(changes.ttLockedText.currentValue);

                //    if (hasLockedText) {
                //        words.push(changes.ttLockedText.currentValue);
                //    } else {
                //        words.push('locked');
                //    }
                //}

                //if (angular.isDefined(changes.ttTextAlign) && isValidTextAlign(changes.ttTextAlign.currentValue)) {
                //    vm.style.button.textAlign = changes.ttTextAlign.currentValue;
                //    vm.style.lockedButton.textAlign = changes.ttTextAlign.currentValue;
                //}

                if (angular.isDefined(changes.ttBadge) && us.isStringValue(changes.ttBadge.currentValue)) {
                    vm.showBadge = true;
                }

                //if (angular.isDefined(changes.ttIcon) && isStringValue(changes.ttIcon.currentValue)) {
                //    vm.class.icon = changes.ttIcon.currentValue;
                //}

                console.dir(changes);

                if (angular.isDefined(changes.ttLocked)) {
                    console.dir(changes.ttLocked);
                    vm.locked = us.toBoolean(changes.ttLocked.currentValue, false);
                }

                if (angular.isDefined(changes.ttDisabled)) {
                    vm.disabled = us.toBoolean(changes.ttDisabled.currentValue, false);
                }

                if (angular.isDefined(changes.ttType) && us.isStringValue(changes.ttType.currentValue)) {
                    vm.class.button = 'tt-button--' + changes.ttType.currentValue;

                }

                if (angular.isDefined(changes.ttIconPullRight)) {
                    let pullRight = us.toBoolean(changes.ttIconPullRight.currentValue);

                    if (pullRight) {
                        vm.class.icon += ' pull-right';
                    }
                }

                //if (words.length > 0) {
                //    translateService.translateBatch(words).then(function (response) {
                //        angular.forEach(response, function (value, key) {
                //            if (key === vm.ttText) {
                //                vm.translations.text = value;
                //            }

                //            if (hasLockedText) {
                //                if (key === vm.ttLockedText) {
                //                    vm.translations.lockedText = value;
                //                }
                //            } else {
                //                if (key === 'locked') {
                //                    vm.translations.lockedText = value;
                //                }
                //            }
                //        });

                //        vm.whenReady();
                //    });
                //}
            };

            vm.$onDestroy = () => ttDirectivesService.onDestroy(onDestroy);
        }]
    });
})();
