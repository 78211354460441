(function () {
    'use strict';

    var module = angular.module('imApp');

    module.factory('$ihttp', ['$rootScope', '$http', '$isHttp', '$q', 'base64', 'appConfig', 'companyService', function ($rootScope, $http, $isHttp, $q, base64, appConfig, companyService) {
        var postRequestBuffer = [];
        var clientIp = '';
        var clientIpReady = false;

        var encodeObject = function (objectToEncode) {
            if (objectToEncode === undefined) return objectToEncode;
            if (objectToEncode === null) return null;
            if (angular.isString(objectToEncode)) return base64.urlencode(objectToEncode);

            if (angular.isArray(objectToEncode)) {
                var encodedArray = [];

                for (var i = 0; i < objectToEncode.length; i++) {
                    encodedArray.push(encodeObject(objectToEncode[i]));
                }

                return encodedArray;
            }
            
            if (angular.isObject(objectToEncode)) {
                var encodedObject = {};

                angular.forEach(objectToEncode, function (value, key) {
                    encodedObject[key] = encodeObject(value);
                });

                return encodedObject;
            }

            return objectToEncode;
        };

        var decodeObject = function (objectToDecode) {
            try {
                if (objectToDecode === undefined) return objectToDecode;

                if (objectToDecode === null) return null;

                if (angular.isString(objectToDecode)) {
                    if (objectToDecode.length <= 4) return objectToDecode;

                    return base64.urldecode(objectToDecode);
                }

                if (angular.isArray(objectToDecode)) {
                    var decodedArray = [];

                    for (var i = 0; i < objectToDecode.length; i++) {
                        decodedArray.push(decodeObject(objectToDecode[i]));
                    }

                    return decodedArray;
                }

                if (angular.isObject(objectToDecode)) {
                    var decodedObject = {};

                    angular.forEach(objectToDecode, function(value, key) {
                        decodedObject[key] = decodeObject(value);
                    });

                    return decodedObject;
                }
            } catch (err) {
                console.dir(err);
            }

            return objectToDecode;
        };

        var post = function (url, data, config, deferred) {
            if (angular.isString(data)) {
                data = angular.fromJson(data);
            }

            url = (url || '').length > 0 ? url : companyService.dataTaskUrl;
            data = data || {};
            config = config || {};
            config.escape = config.escape || false;
            config.encodedParms = config.encodedParms || false;
            config.encodedData = config.encodedData || false;
            
            config.clientId = appConfig.client.id;

            config.headers = config.headers || {};
            config.headers['X-INSOFT-PATH'] = 'database-' + companyService.custId;
            
            data.clientip = clientIp;

            if (config.escape === true) {
                if (angular.isDefined(data.parameters) === true) {
                    angular.forEach(data.parameters, function (value, key) {
                        if (angular.isString(data.parameters[key]) === true) {
                            data.parameters[key] = value.replace(/\'/g, "''");
                        }
                    });
                }
            }

            if (config.encodedParms === true && angular.isDefined(data.parameters)) {
                data.parameters = encodeObject(data.parameters);
            }

            data = angular.toJson(data);

            config.datarequest = true;
            config.deferred = deferred;
            config.isRawData = true;
            config.suppressErrorMessage = true;

            $isHttp.post(url, data, config).then(function (response) {
                if (angular.isDefined(response.config.encodedData) && response.config.encodedData === true) {
                    response.data = decodeObject(response.data);
                }

                var responseData = response.config && response.config.ieRawData === true ? response : response.data;

                if (angular.isDefined(response.config.context) === true) {
                    response.config.deferred.resolve({ data: responseData, context: response.config.context });
                } else {
                    response.config.deferred.resolve(responseData);
                }
            }).catch(function (response) {
                response.config.deferred.reject(response);
            });
        };

        var executeBufferedRequests = function () {
            if (companyService.isReady !== true || clientIpReady !== true) return;

            var localBuffer = postRequestBuffer.splice(0, postRequestBuffer.length);

            for (var i = 0; i < localBuffer.length; i++) {
                post(localBuffer[i].url, localBuffer[i].data, localBuffer[i].config, localBuffer[i].deferred);
            }
        };

        var service = {
            get: function (parms, config, url) {
                if (angular.isString(parms)) {
                    url = parms;
                }

                parms = parms || {};
                config = config || {};
                url = (url || '').length > 0 ? url : companyService.dataTaskUrl;
                config.encodedParms = config.encodedParms || false;
                config.encode = config.encode || false;
                config.encodedData = config.encodedData || false;

                if (config.encode === true) {
                    config.encodedParms = true;
                }

                config.clientId = appConfig.client.id;

                config.headers = config.headers || {};
                config.headers['x-insoft-path'] = 'database-' + companyService.custId.toLowerCase();

                // BJS 20190618 - Added possibility for base64url encoding entire parms string and sending a custom
                //                header to tell server to first decode the parms.
                if (config.encodedParms === true && angular.isDefined(parms)) {
                    parms = config.encode === true ?
                        base64.urlencode(angular.toJson(parms)) :
                        encodeObject(parms);

                    config.headers['x-insoft-encoding'] = 'base64url';
                }

                if (angular.isObject(parms) === true) {
                    angular.forEach(parms, function (value) {
                        if (url.substring(url.length - 1) !== '/') {
                            url = url + '/';
                        }

                        url = url + value;
                    });
                }

                // BJS 20190618
                if (angular.isString(parms) === true) {
                    if (url.substring(url.length - 1) !== '/') {
                        url = url + '/';
                    }

                    url = url + parms;
                }

                config.datarequest = true;
                config.isRawData = true;
                config.suppressErrorMessage = true;

                var deferred = $q.defer();

                $isHttp.get(url, config).then(function (response) {
                    if (angular.isDefined(response.config.encodedData) && response.config.encodedData === true) {
                        response.data = decodeObject(response.data);
                    }

                    var responseData = response.config && response.config.ieRawData === true ? response : response.data;
                    
                    deferred.resolve(responseData);
                });

                return deferred.promise;
            },

            post: function (data, config, url) {
                var deferred = $q.defer();

                if (companyService.isReady !== true || clientIpReady !== true) {
                    postRequestBuffer.push({
                        url: url,
                        data: data,
                        config: config,
                        deferred: deferred
                    });
                } else {
                    post(url, data, config, deferred);
                }

                return deferred.promise;
            }
        };

        // BJS 20170602 - Henter client ip via extern tjeneste fordi vi ikke alltid klarer å finne
        //                riktig ip fra serveren.
        // BJS 20201208 - Endret til å bruke vår custom webservice.
        $http.get('https://ipify.insoft.net').then(function (result) {
            clientIp = result.data.ip;
            clientIpReady = true;

            executeBufferedRequests();
        }, function () {
            clientIp = '';
            clientIpReady = true;

            executeBufferedRequests();
        });
        
        //companyService.getInfo(data.user.profile.company_id);

        //companyService.on('datachanged', function () {
        //    executeBufferedRequests();
        //});

        return service;
    }]);
})();
