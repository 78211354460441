import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    selector: 'tt-list' // Use the selector of the AngularJS component
})
export class TtListDirective extends UpgradeComponent {
    // Inputs
    @Input() ttClickable!: boolean;
    @Input() ttEditmode!: boolean;
    @Input() ttHideGoto!: boolean;
    @Input() backitem!: string;
    @Input() badge!: string;
    @Input() dataid?: string;
    @Input() dataname?: string;
    @Input() designVar!: string;
    @Input() filterParm!: string;
    @Input() hideIcon!: boolean;
    @Input() listItems!: any;
    @Input() ltype!: string;
    @Input() mconfig?: any;
    @Input() orderby!: string;
    @Input() ptypeVar!: any;
    @Input() revOrder!: string;
    @Input() sortItems?: any;
    @Input() typevar!: string;
    @Input() useDocIcon!: string;
    @Input() filterVal!: any;

    // Outputs for two-way bindings
    @Output() listItemsChange = new EventEmitter<any>();
    @Output() sortItemsChange = new EventEmitter<any>();
    @Output() ptypeVarChange = new EventEmitter<any>();
    @Output() mconfigChange = new EventEmitter<any>();
    @Output() filterValChange = new EventEmitter<any>();

    // Outputs (from '&' bindings)
    @Output() ttChange = new EventEmitter<any>();
    @Output() ttClick = new EventEmitter<any>();
    @Output() ttDeleteitem = new EventEmitter<any>();
    @Output() sortfunc = new EventEmitter<any>();

    // Function Inputs (if any)
    @Input() optionfunc!: (args?: any) => any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('ttList', elementRef, injector);
    }
}
