(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('consignmentOl', {
        templateUrl: 'views/components/views/consignmentOl/consignmentOl.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'consignmentOlService', 'stateService', function($stateParams, consignmentOlService, stateService) {
            var vm = this;

            vm.model = {
                p2ConsignmentOlGet: {}
            };
            
            consignmentOlService.loadP2ConsignmentOlGet($stateParams.consignmentol_keyno).then(function () {
                vm.model.p2ConsignmentOlGet = consignmentOlService.p2ConsignmentOlGet;
            });
            
            vm.goToConsignmentLines = function (index) {
                stateService.go('consignmentlines', {
                    argtype: 'consignmentol_keyno',
                    argvalue: $stateParams.consignmentol_keyno
                });
            };
            
            vm.goTo = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };
                
                switch (state) {
                    case 'orderhead':
                        go({ order_internal_no: vm.model.p2ConsignmentOlGet.order_internal_no });
                        break;
                }
            };
        }]
    });
})();