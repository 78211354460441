import { SymbolCellRendererComponent } from '../../../../../app/core/components/grid/cell-renderers/symbol-cell-renderer/symbol-cell-renderer.component';

(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('jobqueue', {
        templateUrl: 'views/components/views/jobqueue/jobqueue.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', '$stateParams', 'stateService', 'jobqueueService', 'ttGridFactory', function ($q, $stateParams, stateService, jobqueueService, ttGridFactory) {
            const vm = this;

            vm.model = {
                setting: {},
                gridReady: false
            };

            vm.grid = {
                dataTask: {
                    rememberId: 'jobqueue.ttGridOptions',
                    loadData: {
                        method: 2276,
                        parameters: function () {
                            return {
                                argtype: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                                argvalue: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
                                date_fom: vm.model.setting.date_fom,
                                date_tom: vm.model.setting.date_tom
                            };
                        }
                    }
                },
                config: {
                    toolbar: {                                          // alternative -> toolbar: false,
                        hidden: false,                                  // If true, hides the toolbar, default is false
                        pdfExport: true,                                // Adds a predefined btn for exporting grid to pdf
                        excelExport: true,                              // Adds a predefined btn for exporting grid to excel
                        filter: true,                                   // Adds a predefined btn for en-/disabling filtering
                        columnVisibility: true,                         // Adds a predefined btn for deciding which columns to show/hide
                        headers: true,                                  // Adds a predefined btn for switched between db column names and titles
                        edit: false,                                    // Adds a predefined btn for en-/disabling editing in columns
                        lock: false,                                    // NOT IMPLEMENTED
                        save: false,                                    // Adds a predefined btn for running a save function // Needs saveData
                        buttons: [],
                        layouts: true
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,                          // Clears sorting on any clicked checkbox if false
                    keepSortOnIsSelected: false,                        // Clears sorting on only is_selected checkbox if false
                    rowClick: false,                                    // Enables functionality for drilldown on clicked row
                    css: {
                        altColor: true                                  // accepts -> true, false or hexbased color (i.e: '#A1B2C3')
                    },
                    specialFunc: {
                        newTab: false,                                   // If data has path, adds predefined "New tab"-button to functions
                        buttons: [
                            {
                                name: 'details',
                                text: '',
                                icon: 'far-chevron-right',
                                func: function (data) {
                                    stateService.go('jobqueuedetails', { jobqueue_keyno: data.jobqueue_keyno });
                                }
                            },
                            {
                                name: 'goto',
                                text: '',
                                icon: 'far-hammer',
                                func: function (data) {
                                    if (data.from_state !== null && angular.isString(data.from_state) === true) {
                                        stateService.go(data.from_state, data.from_state_args);
                                    }
                                },
                                disabled: function (data) {
                                    if (data.from_state === null) return true;
                                    if (angular.isString(data.from_state) !== true) return true;

                                    return data.from_state.length < 1;
                                }
                            }
                        ]
                    },
                    onDataChanged: function () { },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: true
                },
                kendo: {                                                // Kendo parameters configuring the grid
                    height: '100%',                                       // Sets the height of the grid component
                    aggregate: [],
                    pager: false,                                       // NOT IMPLEMENTED
                    selectable: 'multiple, row',                        // accepts -> true, false, 'row', 'multiple' or 'multiple, row'
                    filterable: false
                },
                optionfunc: function (parms) { },
                gridfunc: null,
                onSetup: function (schema, fields, columns, translations) {
                    if (Object.hasOwn(columns[0], 'colId')) {
                        let columnIndex = columns.findIndex((col) => col.colId === 'process_status_icon');
    
                        if (columnIndex !== -1) {
                            columns[columnIndex] = {
                                ...columns[columnIndex],
                                filter: false,
                                cellRenderer: SymbolCellRendererComponent,
                                cellRendererParams: (params) => ({ ...params, iconKeyname: 'psiIcon', iconColorKeyname: 'psiColor' })
                            }
                        }
                    } else {
                        angular.forEach(columns, function (col) {
                            switch (col.field) {
                                case 'process_status_icon':
                                    col.filterable = false;
                                    col.sortable = false;

                                    col.template = function (dataItem) {
                                        return '<div class="col-xs-12 sp-0"><span class="' + dataItem.psiIcon + '" style="color: ' + dataItem.psiColor + '; display: block; text-align: center;"></span></div>';
                                    }

                                    break;
                            }
                        });
                    }
                },
                onDataBound: function (e) {
                },
                onDataBinding: function (e) {
                    if (e.type === "modelUpdated") {

                        e.api.forEachNode((node) => {
                            try {
                                let status = statusList.find((status) => (('' + status.status_keyno) === ('' + node?.data?.['process_status'])));

                                if (status && node.data) {
                                    node.data = { ...node.data, psiIcon: status.status_icon, psiColor: status.status_color + ' ' + status.status_color };
                                        node.setData(node.data);
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }, false)
                    } else {
                        try {
                            angular.forEach(e.items, function (item) {
                                if (angular.isString(item.processing_message) && item.processing_message.length > 0) {
                                    var pm = angular.fromJson(item.processing_message);

                                    if (angular.isDefined(pm.iserror)) {
                                        if (pm.iserror === true) {
                                            item.process_status = 4;
                                        }
                                    }
                                }

                                let status = statusList.find((status) => ('' + status.status_keyno) === ('' + item.process_status));

                                item.psiColor = status?.status_color;
                                item.psiIcon = status?.status_icon;
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }
            };

            //vm.grid = new ttGridFactory({
            //    rememberId: 'jobqueue.ttGridOptions',
            //    kendo: {
            //        height: null,
            //        sortable: true,
            //        aggregate: true,
            //        pager: false,
            //        selectable: false,
            //        filterable: false
            //    }
            //}).setToolbar({
            //    hidden: false,
            //    pdfExport: true,
            //    excelExport: true,
            //    filter: true,
            //    columnVisibility: true,
            //    headers: true,
            //    edit: false,
            //    lock: false,
            //    save: false,
            //    buttons: [],
            //    layouts: true,
            //    wrapping: true
            //}).setSpecialFunc({
            //    newTab: false,
            //    buttons: [
            //        {
            //            name: 'details',
            //            text: '',
            //            func: function (data) {
            //                stateService.go('jobqueuedetails', { jobqueue_keyno: data.jobqueue_keyno });
            //            }
            //        },
            //        {
            //            name: 'goto',
            //            text: '',
            //            icon: 'far-hammer',
            //            func: function (data) {
            //                if (data.from_state !== null && angular.isString(data.from_state) === true) {
            //                    stateService.go(data.from_state, data.from_state_args);
            //                }
            //            },
            //            disabled: function (data) {
            //                if (data.from_state === null) return true;
            //                if (angular.isString(data.from_state) !== true) return true;

            //                return data.from_state.length < 1;
            //            }
            //        }
            //    ]
            //});

            // #region LOAD PROCEDURE FUNCTION CALLS

            let loadSetting = function () {
                const deferred = $q.defer();

                jobqueueService.getSetting().then(function (info) {
                    angular.copy(info[0], vm.model.setting);


                    deferred.resolve();
                });

                return deferred.promise;
            };

            // #endregion LOAD PROCEDURE FUNCTION CALLS

            // #region GRID PROCEDURE FUNCTION CALLS

            let loadGrid = function () {
                vm.grid.dataTask.loadData = {
                    method: 2276,
                    parameters: () => ({
                        argtype: angular.isDefined($stateParams.argtype) ? $stateParams.argtype : '',
                        argvalue: angular.isDefined($stateParams.argvalue) ? $stateParams.argvalue : '',
                        date_fom: vm.model.setting.date_fom,
                        date_tom: vm.model.setting.date_tom,
                        dateselector_index: vm.model.setting.dateselector_index
                    })
                };

                vm.model.gridReady = true;

                if (vm.grid.gridfunc !== null) vm.grid.gridfunc.read();
            };

            // #endregion GRID PROCEDURE FUNCTION CALLS

            // #region FIELD DATE RANGE CHANGE FUNCTION

            vm.onDateRangeChanged = function (startDate, endDate, dateInterval) {
                vm.model.setting.date_fom = startDate;
                vm.model.setting.date_tom = endDate;
                vm.model.setting.dateselector_index = +dateInterval <= 0 ? vm.model.setting.dateselector_index : dateInterval;

                if (vm.model.setting.date_fom && vm.model.setting.date_tom && vm.model.setting.dateselector_index) {
                    vm.model.setting.date_fomtom = vm.model.setting.date_fom + vm.model.setting.date_tom;
                    //vm.grid.dataTask.loadData.parameters.date_fom = vm.model.setting.date_fom;
                    //vm.grid.dataTask.loadData.parameters.date_tom = vm.model.setting.date_tom;
                    //vm.grid.dataTask.loadData.parameters.dateselector_index = vm.model.setting.dateselector_index;
                    vm.grid.gridfunc?.read();
                    //loadGrid();
                }
            };

            async function loadStatusList() {
                statusList = (await jobqueueService.load()).jobqueueStatusList;
            }

            // #endregion FIELD DATE RANGE CHANGE FUNCTION
            let statusList = [];
            vm.$onInit = async function () {
                var promises = [];

                await Promise.all([loadSetting(), loadStatusList()]);

                loadGrid();

                //promises.push(jobqueueService.load());
            };
        }]
    });
})();
