(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderHeadCreateIbPurch', {
        templateUrl: 'views/components/views/orderHeadCreateIbPurch/orderHeadCreateIbPurch.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'utilityService', 'orderHeadCreateIbPurchService', 'logisticService', function ($stateParams, stateService, utilityService, orderHeadCreateIbPurchService, logisticService) {
            var vm = this;
    		
            vm.model = {
                orderInternalNo: $stateParams.order_internal_no,
                edit: {},
                selectListStockPlaces: []
            };

            orderHeadCreateIbPurchService.getOrderHeadCreateIbPurch({ order_internal_no: vm.model.orderInternalNo }).then(function (data) {
                angular.copy(data[0], vm.model.edit);
                
                loadStockPlaces();
            });

            var loadStockPlaces = function () {
                if (utilityService.validateParmsValue(vm.model.edit.businessco_no) !== true) return;

                vm.model.selectListStockPlaces = [];

                logisticService.listStocks({ businessco_no: vm.model.edit.businessco_no }).then(function (data) {
                    angular.copy(data, vm.model.selectListStockPlaces);
                });
            };

            vm.createOrderHeadCreateIbPurch = function () {
                orderHeadCreateIbPurchService.createObj(vm.model.edit).then(function (data) {
                    stateService.back();
                });
            };
        }]
    });
})();