import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormButton } from '@app/core/components/form-field-button/form-field-button.component';
import { DataTaskService } from '@app/core/services/data-task.service';
import { ModalService } from '@app/core/services/modal.service';
import { StateService } from '@app/core/services/state.service';
import { TranslateService } from '@app/core/services/translate.service';
import { SirvService } from '@app/services/sirv.service';

@Component({
    selector: 'tt-picture-bank',
    templateUrl: './picture-bank.component.html',
    styleUrls: ['./picture-bank.component.css'],
})
export class PictureBankComponent implements OnInit, OnChanges {
    /**
     * The id of the picturebank displayed.
     */
    @Input()
    public pictureBankKeyno?: string;

    @Output()
    public pictureBankSave = new EventEmitter<string>();

    @Output()
    public pictureBankDeleted = new EventEmitter<string>();

    /**
     * The picturebank details.
     */
    public get = { page_heading: '', picturebank_keyno: '', picture_main: '0', filesize_mb: 0, full_url: '', reg_datetime: '', reg_by_portal_user_name: '' };

    /**
     * The name of the picture bank file.
     */
    public filename = '';

    /**
     * The url for the sirv image width style parameters.
     */
    public url = '';

    /**
     * The width of the image, used for retrieving adequately sized image from sirv for the preview image.
     */
    public imageWidth = 1000;

    /**
     * Input buttons for the input field of the full_url variable.
     */
    public urlInputButtons: FormButton[] = [
        { id: 'link', icon: 'far fa-copy', tooltip: 'copy_img_url', type: 'primary', onClick: () => navigator.clipboard.writeText(this.get.full_url) },
        { id: 'link', icon: 'far fa-external-link', tooltip: 'open_in_new_tab', type: 'primary', onClick: () => window.open(this.get.full_url, '_blank') },
    ];

    /**
     * Buttons locks.
     */
    public locks = {
        save: false,
        delete: false,
    };

    private translations: { [key: string]: string } = {
        picturebank_delete_warning: '',
        picturebank_save_warning: '',
    };

    constructor(private datatask: DataTaskService, private state: StateService, private modal: ModalService, private sirv: SirvService, private translate: TranslateService) {}

    /**
     * Saves the picture bank changes.
     */
    public async save() {
        this.locks.save = true;

        try {
            const response = await this.savePictureBank();

            if (response.errorcode !== '0') {
                await this.modal.show({ type: 'danger', title: '', message: response.errormessage });
            } else {
                if (!this.inlineComponent) {
                    this.state.back();
                } else {
                    this.pictureBankSave.emit(this.get.picturebank_keyno);
                }
            }
        } finally {
            this.locks.save = false;
        }
    }

    /**
     * Deletes the image frm the db and from sirv cdn.
     */
    public async deleteImage() {
        this.locks.delete = true;

        try {
            const response = await this.deletePictureBank();

            if (response.errorcode !== '0') {
                await this.modal.show({ type: 'danger', title: '', message: response.errormessage });
            } else {
                await this.deleteSirvImage();

                if (!this.inlineComponent) {
                    this.state.back();
                } else {
                    this.pictureBankDeleted.emit(this.get.picturebank_keyno);
                    this.ready = false;
                    this.get = { page_heading: '', picturebank_keyno: '', picture_main: '0', filesize_mb: 0, full_url: '', reg_datetime: '', reg_by_portal_user_name: '' };
                }
            }
        } finally {
            this.locks.delete = false;
        }
    }

    /**
     * Adds sirv url styling parameters to the given url.
     *
     * @param url the url to add sirv styling parameters to.
     * @returns the styled sirv url.
     */
    private getStyledSirvUrl(url: string) {
        return `${url}?w=${this.imageWidth}&frame.style=solid&frame.color=adadad&frame.width=3`;
    }

    /**
     * Extracts filename from the given uri encoded sirv image url.
     *
     * @param url the sirv image url to extract filename from.
     * @returns the extracted filename.
     */
    private extractFilenameFromUrl(url: string): string {
        let splittedUrl = this.extractFilepathFromUrl(url).split('/');
        let filenameWithType = splittedUrl[splittedUrl.length - 1];
        let filename = decodeURIComponent(filenameWithType).split('.')[0];

        return filename;
    }

    /**
     * Extracts the filepath from the given sirv uri encoded url.
     *
     * @param url the sirv image url to extract filepath from.
     * @returns the extracted filepath.
     */
    private extractFilepathFromUrl(url: string): string {
        let encodedFilePath = url.substring(url.indexOf('/', 10));
        let filepath = decodeURIComponent(encodedFilePath.replaceAll('/', '%2F'));

        return filepath;
    }

    /**
     * Deletes the image from the sirv cdn.
     */
    private deleteSirvImage() {
        const filepath = this.extractFilepathFromUrl(this.get.full_url);
        return this.sirv.deleteImage(filepath);
    }

    /**
     * Retrieves the page data.
     */
    private async getPictureBank() {
        if (!!this.pictureBankKeyno) {
            return (await this.datatask.Post(2534, { picturebank_keyno: this.pictureBankKeyno }))[0];
        }

        throw Error('Missing picturebank keyno');
    }

    /**
     * Saves the changes to the picture bank object to the db.
     */
    private async savePictureBank() {
        if (!!this.pictureBankKeyno) {
            return (await this.datatask.Post(2541, this.get))[0];
        }

        throw Error('Missing picturebank keyno');
    }

    /**
     * Deletes the picturebank record from the db.
     */
    private async deletePictureBank(): Promise<{ errorcode: string; errormessage: string }> {
        if (!!this.pictureBankKeyno) {
            return (await this.datatask.Post(2537, { picturebank_keyno: this.pictureBankKeyno }))[0];
        }

        throw Error('Missing picturebank keyno');
    }

    /**
     * Translates the words.
     */
    private async translateWords() {
        const response: typeof this.translations = await this.translate.translateBatch(Object.keys(this.translations));

        for (let [key, word] of Object.entries(response)) {
            if (word !== undefined && word !== null) {
                this.translations[key] = word;
            }
        }
    }

    public inlineComponent = false;

    public ready = false;

    async loadPictureBank() {
        this.ready = false;

        this.get = await this.getPictureBank();
        this.filename = this.extractFilenameFromUrl(this.get.full_url);
        this.url = this.getStyledSirvUrl(this.get.full_url);
        this.ready = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['pictureBankKeyno']?.currentValue) {
            this.inlineComponent = true;
            this.loadPictureBank();
        }
    }

    async ngOnInit(): Promise<void> {
        if (!this.pictureBankKeyno) {
            this.pictureBankKeyno = this.state.getStateParams()['picturebank_keyno'];
        }
        this.imageWidth = Math.min(window.innerWidth, 1200);
        this.translateWords();
        this.loadPictureBank();
    }
}
