(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("sopService", ['p2DataTaskService', function (p2DataTaskService) {
        var service = {
            loadSopGet: function (sop_keyno) {
                return p2DataTaskService.call(1835, {
                    sop_keyno: sop_keyno
                });
            },
            loadSopFieldTypesList: function () {
                return p2DataTaskService.call(1837, {});
            },
            save: function (settingServiceObjectProperty) {
                return p2DataTaskService.call(1836, settingServiceObjectProperty);
            }
        };

        return service;
    }]);
})();