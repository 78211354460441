import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { default as imAppModule } from '../ng-app/app.module.ajs';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { MicrosoftGraphModule } from './modules/microsoft-graph/microsoft-graph.module';

import { AppComponent } from './app.component';
import { AppConfigModule } from './appconfig.module';
import { AppConfigService } from './services';
import { GraphAuthService } from './modules/microsoft-graph/auth/services/graph-auth.service';
import { CalendarService } from './modules/microsoft-graph/services/graph-calendar.service';
import { GraphUserService } from './modules/microsoft-graph/services/graph-user.service';
import { BjsTestComponent } from './views/components/bjs-test/bjs-test.component';
import { GoogleTranslateService } from './services/google-translate.service';
import { DataTaskService } from './core/services/data-task.service';
import { GraphMailService } from './modules/microsoft-graph/services/graph-mail.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { WordlangDialogComponent } from './modal/wordlang-dialog/wordlang-dialog.component';
import { TtInputComponent } from './core/components/tt-input/tt-input.component';

/**
 * IMPORTANT: RxDB creates rxjs observables outside of angulars zone
 * So you have to import the rxjs patch to ensure changedetection works correctly.
 * @link https://www.bennadel.com/blog/3448-binding-rxjs-observable-sources-outside-of-the-ngzone-in-angular-6-0-2.htm
 */
import 'zone.js/plugins/zone-patch-rxjs';
import { CardComponent } from './core/components/card/card.component';
import { PageComponent } from './core/components/page/page.component';
import { JlrTestComponent } from './views/components/jlr-test/jlr-test.component';
import { WidgetComponent } from './views/components/jlr-test/widget/widget.component';
import { DashboardComponent } from './views/components/jlr-test/dashboard/dashboard.component';
import { ResponsiveBreakpointsComponent } from './core/components/responsive-breakpoints/responsive-breakpoints.component';
import { ResponsiveService } from './core/services/responsive.service';
import { ResponsiveBridgeService } from './core/services/responsive-bridge.service';
import { NgChartsModule } from 'ng2-charts';
import { WidgetChartComponent } from './views/components/jlr-test/widget-chart/widget-chart.component';
import { WidgetTextComponent } from './views/components/jlr-test/widget-text/widget-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from './core/services/user.service';
import { UserStore } from './core/services/user.store';
import { AppSettingsService } from './core/services/app-settings.service';
import { OutsideClickDirective } from './core/directives/outside-click/outside-click.directive';
import { InputNumberComponent } from './core/components/input-number/input-number.component';
import { FormFieldBaseComponent } from './core/components/form-field-base/form-field-base.component';
import { FormFieldButtonsComponent } from './core/components/form-field-buttons/form-field-buttons.component';
import { CheckboxComponent } from './core/components/checkbox/checkbox.component';
import { ListboxComponent, ListboxHighlight } from './core/components/listbox/listbox.component';
import { ListboxPopupDirective } from './core/directives/listbox-popup/listbox-popup.directive';
import { SearchComponent } from './core/components/search/search.component';
import { FormFieldButtonComponent } from './core/components/form-field-button/form-field-button.component';
import { ComponentBaseComponent } from './core/components/component-base/component-base.component';
import { TabsComponent } from './core/components/tabs/tabs.component';
import { DatetimeComponent } from './core/components/datetime/datetime.component';
import { MatInputModule, MatInput } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCalendar, MatCalendarHeader, MatDatepickerModule } from '@angular/material/datepicker';
import { MatTable, MatTableModule } from '@angular/material/table';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { PasswordInputComponent } from './core/components/password-input/password-input.component';
import { MultiSelectComponent, MutliSelectFilter } from './core/components/multi-select/multi-select.component';
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip';
import { FieldGroupComponent } from './core/components/field-group/field-group.component';
import { OverflowMenuComponent } from './core/components/overflow-menu/overflow-menu.component';
import { OverflowMenuItemComponent } from './core/components/overflow-menu/overflow-menu-item/overflow-menu-item.component';
import { PageHeadingComponent } from './core/components/typography/page-heading/page-heading.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { GridComponent } from './core/components/grid/grid.component';
import { GridLoadingOverlayComponent } from './core/components/grid/grid-loading-overlay/grid-loading-overlay.component';
import { DateTimeCellEditorComponent } from './core/components/grid/cell-editors/date-time-cell-editor/date-time-cell-editor.component';
import { LookupCellEditorComponent } from './core/components/grid/cell-editors/lookup-cell-editor/lookup-cell-editor.component';
import { NumberCellEditorComponent } from './core/components/grid/cell-editors/number-cell-editor/number-cell-editor.component';
import { CheckboxFloatingFilterComponent } from './core/components/grid/floating-filters/checkbox-floating-filter/checkbox-floating-filter.component';
import { SymbolCellRendererComponent } from './core/components/grid/cell-renderers/symbol-cell-renderer/symbol-cell-renderer.component';
import { ColumnLayoutModalComponent } from './core/components/grid/modals/column-layout-modal/column-layout-modal.component';
import { FileUploaderComponent } from './core/components/file-uploader/file-uploader.component';
import { GridFunctionsCellRendererComponent } from './core/components/grid/cell-renderers/grid-functions-cell-renderer/grid-functions-cell-renderer.component';
import { SelectComponent } from './core/components/select/select.component';
import { TextareaComponent } from './core/components/textarea/textarea.component';
import { ButtonComponent } from './core/components/button/button.component';
import { PrintModalComponent } from './core/components/grid/modals/print-modal/print-modal.component';
import { registerLocaleData } from '@angular/common';
import LocaleNo from '@angular/common/locales/no';
import { ConfirmModalComponent } from './core/components/grid/modals/confirm-modal/confirm-modal.component';
import { LinkCellRendererComponent } from './core/components/grid/cell-renderers/link-cell-renderer/link-cell-renderer.component';
import { ProgressBarComponent } from './core/components/progress-bar/progress-bar.component';
import { ProgressModalComponent } from './core/components/grid/modals/progress-modal/progress-modal.component';
registerLocaleData(LocaleNo);
import { ButtonSaveComponent } from './core/components/button-save/button-save.component';
import { ButtonMultiComponent } from './core/components/button-multi/button-multi.component';
import { PopoverComponent } from './core/components/popover/popover.component';
import { PopoverDirective } from './core/components/popover/popover.directive';
import { InputComponent } from './core/components/input/input.component';
import { CheckboxColumnHeaderComponent } from './core/components/grid/column-headers/checkbox-column-header/checkbox-column-header.component';
import { ThumbnailCellRendererComponent } from './core/components/grid/cell-renderers/thumbnail-cell-renderer/thumbnail-cell-renderer.component';
import { RowEditModalComponent } from './core/components/grid/modals/row-edit-modal/row-edit-modal.component';
import { PrintService } from './core/services/print.service';
import { PopupTableComponent } from './core/components/grid/modals/popup-table/popup-table.component';
import { DateCellEditorComponent } from './core/components/grid/cell-editors/date-cell-editor/date-cell-editor.component';
import { DATE_FORMATS, TTDateAdapter } from './core/models/date-adapter';
import { DateFloatingFilterComponent } from './core/components/grid/floating-filters/date-floating-filter/date-floating-filter.component';
import { DateFilterSelectionComponent } from './core/components/grid/filters/date-filter-selection/date-filter-selection.component';
import { ErrorModalComponent } from './core/components/grid/modals/error-modal/error-modal.component';
import { MessageDialogComponent } from './core/services/modal.service';
import { MainMenuComponent } from './views/components/mainmenu/mainmenu.component';
import { BankReconciliationComponent, BankReconciliationFilter } from './views/components/bank-reconciliation/bank-reconciliation.component';
import { BankReconciliationItemComponent } from './views/components/bank-reconciliation/bank-reconciliation-item/bank-reconciliation-item.component';
import { GridBookingComponent } from './modal/grid-booking/grid-booking.component';
import { StudentTestComponent } from './views/components/student-test/student-test.component';
import { KanbanComponent } from './core/components/kanban/kanban.component';
import { ModalService } from './core/services/modal.service';
import { SignaturePadModalComponent } from './core/components/grid/modals/signature-pad-modal/signature-pad-modal.component';
import { SignaturePadComponent } from './core/components/signature-pad/signature-pad.component';
import { MyActivitiesComponent } from './views/components/my-activities/my-activities.component';
import { TtInputButtonsDirective } from './core/directives/tt-input-buttons/tt-input-buttons.directive';
import { TtListDirective } from './core/directives/tt-list/tt-list.directive';
import { ImInputDirective } from './core/directives/im-input/im-input.directive';
import { SettingsComponent } from './views/components/settings/settings.component';
import { HtmlReportComponent } from './core/components/html-report/html-report.component';
import { HtmlReportService } from './services/html-report.service';
import { ContextMenuReportComponent } from './core/components/context-menu-report/context-menu-report.component';
import { HtmlProcEditorComponent } from './core/components/html-proc-editor/html-proc-editor.component';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { CommonInterceptor } from './core/interceptors/common-interceptor';
import { SirvSetupComponent } from './views/components/sirv-setup/sirv-setup.component';
import { PictureBankComponent } from './views/components/picture-bank/picture-bank.component';
import { PictureBanksComponent } from './views/components/picture-banks/picture-banks.component';

@NgModule({
    declarations: [
        AppComponent,
        BjsTestComponent,
        JlrTestComponent,
        WordlangDialogComponent,
        CardComponent,
        PageComponent,
        WidgetComponent,
        DashboardComponent,
        ResponsiveBreakpointsComponent,
        WidgetChartComponent,
        WidgetTextComponent,
        OutsideClickDirective,
        InputNumberComponent,
        FormFieldBaseComponent,
        FormFieldButtonsComponent,
        CheckboxComponent,
        ListboxComponent,
        ListboxHighlight,
        BankReconciliationFilter,
        ListboxPopupDirective,
        SearchComponent,
        FormFieldButtonComponent,
        TabsComponent,
        DatetimeComponent,
        MultiSelectComponent,
        PasswordInputComponent,
        FieldGroupComponent,
        OverflowMenuComponent,
        OverflowMenuItemComponent,
        PageHeadingComponent,
        FileUploaderComponent,
        SelectComponent,
        TextareaComponent,
        ButtonComponent,
        GridComponent,
        GridLoadingOverlayComponent,
        DateTimeCellEditorComponent,
        LookupCellEditorComponent,
        NumberCellEditorComponent,
        CheckboxFloatingFilterComponent,
        SymbolCellRendererComponent,
        ColumnLayoutModalComponent,
        FileUploaderComponent,
        GridFunctionsCellRendererComponent,
        PrintModalComponent,
        ConfirmModalComponent,
        LinkCellRendererComponent,
        ProgressBarComponent,
        ProgressModalComponent,
        ButtonSaveComponent,
        ButtonMultiComponent,
        CheckboxColumnHeaderComponent,
        PopoverComponent,
        PopoverDirective,
        InputComponent,
        ThumbnailCellRendererComponent,
        RowEditModalComponent,
        PopupTableComponent,
        DateCellEditorComponent,
        DateFloatingFilterComponent,
        DateFilterSelectionComponent,
        ErrorModalComponent,
        MainMenuComponent,
        StudentTestComponent,
        KanbanComponent,
        SignaturePadModalComponent,
        SignaturePadComponent,
        BankReconciliationComponent,
        BankReconciliationItemComponent,
        GridBookingComponent,
        TtInputButtonsDirective,
        MyActivitiesComponent,
        TtListDirective,
        ImInputDirective,
        SettingsComponent,
        HtmlReportComponent,
        ContextMenuReportComponent,
        HtmlProcEditorComponent,
        MessageDialogComponent,
        SirvSetupComponent,
        PictureBankComponent,
        PictureBanksComponent,
    ],
    imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }), HttpClientModule, UpgradeModule, UIRouterUpgradeModule.forRoot(), AppConfigModule, MicrosoftGraphModule, BrowserAnimationsModule, MatDialogModule, TtInputComponent, FormsModule, NgChartsModule, MatInputModule, MatDatepickerModule, MatNativeDateModule, MatButtonModule, MutliSelectFilter, MatTooltipModule, AgGridModule, ReactiveFormsModule, MatAutocompleteModule, MatInputModule, DragDropModule, MatTableModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        AppConfigService,
        GraphAuthService,
        CalendarService,
        GraphUserService,
        GraphMailService,
        GoogleTranslateService,
        DataTaskService,
        ModalService,
        HtmlReportService,
        ResponsiveService,
        ResponsiveBridgeService,
        UserService,
        UserStore,
        NativeDateAdapter,
        PrintService,
        { provide: DateAdapter, useClass: TTDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
        { provide: LOCALE_ID, useValue: 'en' },
        {
            provide: APP_INITIALIZER,
            useFactory: (userStore: UserStore, dataTask: DataTaskService, appSettings: AppSettingsService, modal: ModalService) => async () => {
                await appSettings.ensureIsReady();
                await dataTask.init(modal);
                await userStore.init(appSettings);
            },
            deps: [UserStore, DataTaskService, AppSettingsService, ModalService],
            multi: true,
        },
        {
            provide: 'stateService',
            useFactory: (i: any) => i.get('stateService'),
            deps: ['$injector'],
        },
        {
            provide: '$stateParams',
            useFactory: (i: any) => i.get('$stateParams'),
            deps: ['$injector'],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CommonInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    entryComponents: [WordlangDialogComponent],
})
export class AppModule {
    constructor(private upgrade: UpgradeModule) {}

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.documentElement, [imAppModule.name], { strictDi: true });
    }
}
