(function () {
    'use strict';

    angular.module("imApp").factory("discGrpProdLangsService", ['$ihttp', function ($ihttp) {
        let service = {
            listLanguages: function (parms) {
                return $ihttp.post({
                    method: 3115,
                    parameters: parms || {}
                });
            },
            saveObj: function (edit) {
                return $ihttp.post({
                    method: 3116,
                    parameters: edit
                });
            }
        };

        return service;
    }]);
})();
