(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('stockplaceAccounting', {
        templateUrl: 'views/components/views/stockplaceAccounting/stockplaceAccounting.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$q', 'stockplaceAccountingService', 'rememberService', 'ieScreenBlockService', function ($q, stockplaceAccountingService, rememberService, ieScreenBlockService) {
            var vm = this;
            var rememberIdGrid = 'stAccountingGrid';
            var rememberIdSelect = 'stAccountingSelect';

            vm.select = {
                businesscoNo: 0
            };

            vm.companies = [];

            vm.gridOptions = {
                dataTask: {
                    rememberId: rememberIdGrid,
                    loadData: {
                        method: 2433,
                        parameters: function () {
                            return {
                                businessco_no: vm.select.businesscoNo
                            };
                        }
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: true,
                        excelExport: true,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: false,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: [
                            { name: 'createStockplaceAccounting', translate: true, text: 'tt_create_stockplace_accounting', func: function () { createStockplaceAccounting(); }, icon: 'checkbox-checked', cssClass: 'btn btn-primary im-grid-btn-xs-r' }
                        ]
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        newTab: false,
                        buttons: []
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            var remember = function () {
                rememberService.remember(rememberIdSelect, vm.select);

                vm.gridOptions.gridfunc.read();
            };

            var load = function () {
                var deferred = $q.defer();

                var promises = [];

                promises.push(rememberService.getLastStatus(rememberIdSelect, true));
                promises.push(stockplaceAccountingService.init());

                $q.all(promises).then(function (response) {
                    if (response[0] !== null) {
                        vm.select.businesscoNo = response[0].businesscoNo;
                    }

                    angular.copy(response[1].companies, vm.companies);

                    vm.gridOptions.gridfunc.rebind();
                });

                return deferred.promise;
            };

            vm.onSelectChanged = function (value, id) {
                switch (id) {
                    case 'vm.select.businesscoNo':
                        vm.select.businesscoNo = value;
                }

                remember();
            };

            vm.$onInit = function () {
                load();
            };

            function createStockplaceAccounting() {
                var allRows = vm.gridOptions.gridfunc.getAllRows();

                var selectedRows = allRows.filter(r => r.is_selected === true);

                if (selectedRows.length < 1) return;

                ieScreenBlockService.start();

                // BJS 20221012 - Need to convert datetime to string before it is sent to service and
                //                converted to json. If not the datetime will be auto converted to utc
                //                time when being converted to json and we will get the wrong date (previous 
                //                day if user is in Norway).
                angular.forEach(selectedRows, function (row) {
                    var tzo = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds

                    row.accounting_date = (new Date(new Date(row.accounting_date) - tzo)).toISOString().replace('T', ' ').slice(0, -1);
                });

                stockplaceAccountingService.create(selectedRows).then(function () {
                    ieScreenBlockService.stop();

                    vm.gridOptions.gridfunc.read();
                }, function (error) {
                    console.log('error');
                    console.dir(error);

                    ieScreenBlockService.stop();
                });
            }
        }]
    });
})();
