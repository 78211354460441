(function () {
	'use strict';

    let module = angular.module('imApp');

    module.component('stockCountApprove', {
        templateUrl: 'views/components/views/stockCountApprove/stockCountApprove.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', '$timeout', '$q', 'stateService', 'utilityService', 'modalService', 'stockCountApproveService', 'rememberService', function ($stateParams, $timeout, $q, stateService, utilityService, modalService, stockCountApproveService, rememberService) {
            
    		// ## VARIABLES & DEFINITIONS ##

            let vm = this;
            let stockCountKeyno = $stateParams.stockcount_keyno;
            
            let variableNames = {
                gb_stockcountapprove_main: '',
                filtervalue: ''
            };

    		vm.model = {
                bgStockCountApproveMain: {
                    selected: 'POSSIBLE',
                    buttons: [
                        { id: 'POSSIBLE', label: 'gb_stockcountapprove_main_possible', color: 'primary', onClick: () => setStockCountApproveMainView('POSSIBLE') },
                        { id: 'MANUAL', label: 'gb_stockcountapprove_main_manual', color: 'primary', onClick: () => setStockCountApproveMainView('MANUAL') },
                        { id: 'APPROVED', label: 'gb_stockcountapprove_main_approved', color: 'primary', onClick: () => setStockCountApproveMainView('APPROVED') }
                    ]
                },
                filterValueButtons: [
                    { id: 'erase', icon: 'fa fa-solid fa-eraser', color: 'danger', type: 'danger', onClick: () => reset('filtervalue') }
                ],
    			setting: {},
    			itemsListPossibles: [],
    			itemsListManuals: [],
    			itemsListApproved: [],
    			progress: '',
    			progressInfo: {
    				label: '',
    				plabel: '',
    				field: '',
    				remField: '',
    				min: 0,
    				max: 100,
    				step: 0,
    				finishclose: ''
                },
                approving: false
    		};

            // #region BUTTON GROUP SINGLE FUNCTION

            let setStockCountApproveMainView = function (id) {
                vm.model.bgStockCountApproveMain.selected = vm.model.setting.gb_stockcountapprove_main = id;
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// ## LOAD PROCEDURE CALLS ##

            let loadSetting = function () {
                stockCountApproveService.loadStockCountApproveGet().then(function (result) {
                    angular.copy(result[0], vm.model.setting);
                    setStockCountApproveMainView(vm.model.setting.gb_stockcountapprove_main);
                });
            };
            
            let loadPossibles = function () {
    			return stockCountApproveService.loadStockCountApprovePossibleList(stockCountKeyno).then(function (result) {
    				angular.copy(result, vm.model.itemsListPossibles);
    			});
    		};
            
            let loadManuals = function () {
    			return stockCountApproveService.loadStockCountApproveManualList(stockCountKeyno).then(function (result) {
    				angular.copy(result, vm.model.itemsListManuals);
    			});
    		};
            
            let loadApproved = function () {
    			return stockCountApproveService.loadStockCountApprovedList(stockCountKeyno).then(function (result) {
    				angular.copy(result, vm.model.itemsListApproved);
    			});
    		};
            
            // #region REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            let rememberFunc = function (id) {
                angular.forEach(variableNames, function (_, key) {
                    variableNames[key] = 'w_' + stateService.getCurrentName() + '.' + key;
                });

                if (utilityService.validateParmsValue(variableNames[id]) !== true) return;

                let variableValue = null;

                switch (id) {
                    case 'gb_stockcountapprove_main':
                        if (utilityService.validateParmsValue(vm.model.setting.gb_stockcountapprove_main) !== true) return;

                        variableValue = vm.model.setting.gb_stockcountapprove_main;
                        break;
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        variableValue = vm.model.setting.filtervalue;
                        break;
                    default:
                        break;
                }

                return rememberService.remember(variableNames[id], variableValue);
            };
            
            // #endregion REMEMBER VALUE MULTI PROCEDURE FUNCTION CALLS

            // #region RESET BUTTON FUNCTION

            let reset = function (id) {
                switch (id) {
                    case 'filtervalue':
                        vm.model.setting.filtervalue = '';

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion RESET BUTTON FUNCTION

            // #region BUTTON GROUP SINGLE FUNCTION

            vm.selectBgStockCountApproveMain = function (value) {
                vm.model.bgStockCountApproveMain.selected = value;
                vm.model.setting.gb_stockcountapprove_main = vm.model.bgStockCountApproveMain.selected;
                
                rememberFunc('gb_stockcountapprove_main');
            };

            // #endregion BUTTON GROUP SINGLE FUNCTION

    		// ## APPROVE BUTTON FUNCTION ##
    
    		function addPossibles(item) {
    			var deferred = $q.defer();
    			
    			stockCountApproveService.loadStockCountApproveOne(item.item_id).then(function (data) {
    				deferred.resolve(data);
    			});
    
    			return deferred.promise;
    		}
    
    		vm.approveProgress = function (items) {
    			var increment = 1, skip = 0, failed = 0, step = 0;
    			vm.model.progressInfo.step = 0;
    
    			var doStep = function () {
    				$timeout(function () {
    					modalService.progressInfo.finishclose = '0';
    					if (vm.model.progress === 'ABORT') return;
    					
    					if (items[step].item_id === '0' || items[step].item_id === '9999') {
    						skip++;
    						step = vm.model.progressInfo.step + skip + failed;
    						doStep();
    					} else {
    						step = vm.model.progressInfo.step + skip + failed;
    						vm.model.progressInfo.field = items[step].item_id + ': ' + items[step].item_name;
    						modalService.progressInfo.field = vm.model.progressInfo.field;
    
    						addPossibles(items[step]).then(function (data) {
    							console.log('kallar i loop');
    							//console.log('data[0].errorcode = ' + data[0].errorcode);
    							if (data[0].errorcode !== '0') {
    								modalService.progressInfo.finishclose = '1';
    								//console.log('modalService.progressInfo.finishclose = ' + modalService.progressInfo.finishclose);
    
    								modalService.show({
    									type: 'warning',
    									title: 'Varsel',
    									message: data[0].errormessage,
    									buttons: [{
    										label: 'OK',
    										cssClass: 'btn-warning',
    										action: function (dialogItself) {
                                                dialogItself.close();
                                                vm.model.approving = false;
    										}
    									}]
    								});
                                } else {
                                    vm.model.approving = false;
    								//if data error, failed++;
    								vm.model.progressInfo.step += increment;
    								modalService.progressInfo.step = vm.model.progressInfo.step;
    								modalService.progressInfo.finishclose = '1';
    
    								doStep();
    							}
    						});
    					}
    				});
    			};
    			doStep();
    		};
    
            vm.approveChanges = function () {
                console.log('Knappen er trykt ned og vi starter loop');
                vm.model.approving = true;
    			vm.model.progress = 'START';
    			vm.model.progressInfo.label = 'progressbar';
    			vm.model.progressInfo.field = '';
    			vm.model.progressInfo.max = 0;
    			vm.model.progressInfo.step = 0;
    
    			angular.forEach(vm.model.itemsListPossibles, function (item) {
    				if (item.item_id !== '0' && item.item_id !== '9999') {
    					vm.model.progressInfo.max++;
    				}
    			});
    
    			vm.approveProgress(vm.model.itemsListPossibles);
    
    			modalService.showProgress(vm.model.progressInfo).then(function (value) {
    				vm.model.progress = value;
    				//if (vm.model.progress === 'ABORT') {
    				//	//run if manually closed before finishing
    				//}
    				//if (vm.model.progress === 'FINISH') {
    				//	//run if finished and closed
    				//}

                    //this will run when closed regardless of 'ABORT' || 'FINISH'
                    vm.model.approving = false;
    				loadPossibles();
    				loadManuals();
    				loadApproved();
    			});
    		};
    
    		// ## DIRECTION BUTTON FUNCTIONS ##
    
    		vm.goTo = function (item) {
    			var variableNameItemManualList = 'w_stockcountapprove.manual_stockcountdata_keyno';
    
                if (utilityService.validateParmsValue(item.item_id) !== true) return;
    
                rememberService.remember(variableNameItemManualList, item.item_id);
    
    			stateService.go(item.item_state, item.item_parms);
    		};

            // #region ON BLUR MULTI FUNCTIONS

            vm.onBlur = function (id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue) !== true) return;

                        if (vm.model.setting.filtervalue > '') rememberFunc('filtervalue');
                        break;
                    default:
                        break;
                }
            };

            // #endregion ON BLUR MULTI FUNCTIONS

            // #region FIELD CHANGE MULTI FUNCTIONS

            vm.onChanged = function (value, id) {
                switch (id) {
                    case 'filtervalue':
                        if (utilityService.validateWatchValue(value, vm.model.setting.filtervalue) !== true) return;

                        vm.model.setting.filtervalue = value;

                        if (utilityService.validateParmsValue(vm.model.setting.filtervalue, true) !== true) return;

                        if (vm.model.setting.filtervalue === '') rememberFunc('filtervalue');
                        break;
                    default:
                        utilityService.setValueToVariable(value);
                        break;
                }
            };

            // #endregion FIELD CHANGE MULTI FUNCTIONS

            // #region ON INIT FUNCTION

            vm.$onInit = function () {
                loadSetting();
                loadPossibles();
                loadManuals();
                loadApproved();
            };

            // #endregion ON INIT FUNCTION

    	}]
    });
})();