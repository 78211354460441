(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('model', {
        templateUrl: 'views/components/views/model/model.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modelService', function ($stateParams, stateService, modelService) {
            let vm = this;
            let modelId = $stateParams.model_id;
            
            vm.model = {
                modelId: modelId,
                edit: {}
            };

            let loadEdit = function () {
                modelService.getModel({ model_id: modelId }).then(function (result) {
                    angular.copy(result[0], vm.model.edit);
                });
            };
            
            vm.saveChanges = function () {
                modelService.saveObj(vm.model.edit).then(function () {
                    stateService.back();
                });
            };

            vm.$onInit = function () {
                loadEdit();
            };
        }]
    });
})();
