(function () {
    'use strict';

    let module = angular.module("imApp");

    module.factory("categoryDataSelectService", ['$ihttp', function ($ihttp) {
        function call(method, params) {
            return $ihttp.post({ method: method, parameters: params });
        }

        let service = {

            /**
             * Gets the list of categories.
             * 
             * @param {string} argtype the argtype to get category data for.
             * @param {string | number} argvalue the argvalue to get category data for.
             * @returns list of categories.
             */
            getCategoryData: function (argtype, argvalue) {
                return call(358, { argtype: argtype, argvalue, argvalue });
            },

            /**
             * Retrieves a list of existing category selection data.
             * 
             * @param {string} argtype the argtype to get selected categories for.
             * @param {string | number} argvalue the argvalue to get the selected categories for.
             * @returns list of existing category selections.
             */
            getSelectedCategoryData: function (argtype, argvalue) {
                return call(603, { argtype: argtype, argvalue, argvalue })
            },

            /**
             * Retrieves list of custacts connected to the given categories, custact no and tab id.
             * 
             * @param {[]} categories the list of categories to find custacts connected to.
             * @param {string | number} custactNo the custact no to find connected custacts to.
             * @param {'S' | 'G'} tabId the id for the currently chosen tab.
             * @returns a list of custacts.
             */
            getCustactsWithCategories: function (categories, custactNo, tabId) {
                let params = {
                    custact_no: custactNo,
                    functiontype: tabId,
                    records: categories.map((category) => {
                        return {
                            category_keyno: category.category_keyno,
                            category_keyno_belongto: category.category_keyno_belongto,
                            visible: category.visible,
                        }
                    })
                }

                return call(360, params);
            },
        };

        return service;
    }]);
})();
