(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('offDutyCodes', {
        templateUrl: 'views/components/views/offDutyCodes/offDutyCodes.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['stateService', 'offDutyCodesService', function(stateService, offDutyCodesService) {
            var vm = this;
            
        	vm.model = {
        		dataSource: {
        			records: []
        		}
        	};
        	
        	offDutyCodesService.loadOffDutyCodes().then(function(){
        		vm.model.dataSource = offDutyCodesService.dataSource;
        	});
        	
            vm.createNewOffDutyCode = function () {
                stateService.go('offdutycode', {
                    offdutycode_keyno: '0'
                })
            };
        }]
    });
})();