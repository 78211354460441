(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('shiftPlans', {
        templateUrl: 'views/components/views/shiftPlans/shiftPlans.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['stateService', 'shiftPlansService', function (stateService, shiftPlansService) {
            var vm = this;
            
            vm.model = {
            	dataSource: {
            		records: []
            	}
            };
            
            shiftPlansService.shiftPlansList().then(function(){
            	vm.model.dataSource = shiftPlansService.dataSource;
            });
            
            vm.goToFunc = function (state) {
                switch (state) {
                    case 'shiftplan':
                        stateService.go(state, {
                            shiftplan_id: ''
                        });
                        break;
                    default:
                        break;
                }
            };
        }]
    });
})();