(function () {
    'use strict';


    var module = angular.module("imApp");

    module.factory("printDialogueService", ['$q', 'dbReportService', 'dbPrinterService', 'printService', function ($q, dbReportService, dbPrinterService, printService) {
        var service = {
            load: function (reportDefKeyno) {
                var promises = [];

                promises.push(dbReportService.details(reportDefKeyno));
                
                promises.push(dbPrinterService.list());
                
                promises.push(dbReportService.getReport(reportDefKeyno));
                
                promises.push(printService.getReports());
                
                var deferred = $q.defer();
                
                $q.all(promises).then(function (data) {
                    var reportName = data[2][0].report_filename.toLowerCase();
                    var reportKeyno = 0;
                    
                    var reports = data[3].items;
                    
                    for (var i = 0; i < reports.length; i++) {
                        if ((reports[i].reportId ?? '').toLowerCase() !== reportName) continue;
                        
                        reportKeyno = reports[i].reportKeyno;
                        
                        break;
                    }
                    
                    deferred.resolve({
                        dbReport: data[2][0],
                        report: data[0],
                        printers: data[1],
                        reportKeyno: reportKeyno
                    });
                });

                return deferred.promise;
            }
        }

        return service;
    }]);
})();
