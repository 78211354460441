(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('portalUserSignature', {
        templateUrl: 'views/components/views/portalUserSignature/portalUserSignature.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'portalUserSignatureService', function ($stateParams, stateService, portalUserSignatureService) {
            var vm = this;
            
            vm.model = {
                edit: {},
                readyEmailSignature: true
            };
            
            portalUserSignatureService.loadP2WPortalUserSignatureGet($stateParams.portal_user_keyno).then(function (data) {
                vm.model.edit = angular.copy(data[0]);
            });
            
            vm.saveChanges = function () {
                portalUserSignatureService.saveP2WPortalUserSignature(vm.model.edit).then(function () {
                    stateService.back();
                });
            };
        }]
    });
})();
