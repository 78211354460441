(function () {
    'use strict';

    var imApp = angular.module('imApp');

    imApp.component('orderCustCom', {
        templateUrl: 'views/components/views/orderCustCom/orderCustCom.template.html?v=' + imApp.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'stateService', 'modalService', 'orderCustComService', function ($stateParams, stateService, modalService, orderCustComService) {
            var vm = this;

            vm.goToFunc = function (state) {
                var go = function (parms) {
                    stateService.go(state, parms);
                };

                switch (state) {
                    case 'orderhead':
                        go({
                            order_internal_no: vm.model.edit.order_internal_no
                        });
                        break;
                    case 'customersmenu':
                        go({
                            cust_no: vm.model.edit.cust_no
                        });
                        break;
                    case 'emailcreate':
                        go({
                            webpage: 'orderhead',
                            argtype: 'order_internal_no',
                            argvalue: vm.model.edit.order_internal_no
                        });
                        break;
                    default:
                        break;
                }
            };

            vm.model = {
                orderCustComKeyno: $stateParams.ordercustcom_keyno,
                orderOrderNoButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'orderhead' } }
                ],
                statCustNameButtons: [
                    { id: 'goto', icon: 'glyphicon glyphicon-chevron-right', color: 'primary', type: 'primary', onClick: { click: vm.goToFunc, state: 'customersmenu' } }
                ],
                edit: {},
                lockedSave: false
            };

            orderCustComService.getOrderCustCom({ ordercustcom_keyno: vm.model.orderCustComKeyno }).then(function (data) {
            	angular.copy(data[0], vm.model.edit);
            });

            vm.saveChanges = function () {
                vm.model.lockedSave = true;

                orderCustComService.saveObj(vm.model.edit).then(function (data) {
                    if (data[0].errorcode !== '0') {
                        modalService.show({
                            type: 'warning',
                            title: 'Varsel',
                            message: data[0].errormessage,
                            buttons: [{
                                label: 'OK',
                                cssClass: 'btn-warning',
                                action: function (dialogItself) {
                                    dialogItself.close();
                                    vm.model.lockedSave = false;
                                }
                            }]
                        });
                    } else {
                        vm.model.lockedSave = false;
                        stateService.back();
                    }
                });
            };
        }]
    });
})();