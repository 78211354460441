(function () {
    'use strict';

    angular.module('imApp').component('ttButtonactionEdit', {
        templateUrl: 'views/components/dynamics/components/ttButtonactionEdit/ttButtonactionEdit.template.html',
        controllerAs: 'vm',
        bindings: {
            modalInstance: '<',
            resolve: '<',
            close: '&'
        },
        controller: ['base64', '$q', '$uibModal', 'ttButtonactionEditService', 'ttDynamicGridManager', function (base64, $q, $uibModal, ttButtonactionEditService, ttDynamicGridManager) {
            var vm = this;

            vm.instanceId = '';

            vm.tt_dynamicproperty_keyno = 0;
            vm.buttonactiontypes = [];
            vm.gridfuncs = [];
            vm.selectedDatatask = {};

            vm.buttonaction = {};

            vm.dtGridOptions = {
                dataTask: {
                    rememberId: 'ttButtonactionEdit.dtGrid',
                    loadData: {
                        method: 2570,
                        parameters: function () {
                            var parms = {
                                griddata_base64: vm.buttonaction.parms_base64
                            }

                            return parms;
                        }
                    },
                    addRow: {
                        autoSave: false,
                        pre: onAddRow,
                        post: onAddedRow
                    }
                },
                translations: [],
                config: {
                    toolbar: {
                        hidden: false,
                        pdfExport: false,
                        excelExport: false,
                        filter: true,
                        columnVisibility: true,
                        headers: true,
                        edit: false,
                        lock: false,
                        add: true,
                        delete: false,
                        save: false,
                        wrapping: true,
                        layouts: true,
                        buttons: []
                    },
                    fixedHeader: true,
                    keepSortOnAdd: false,
                    keepSortOnCheckbox: false,
                    keepSortOnIsSelected: false,
                    rowClick: false,
                    specialFunc: {
                        buttons: [
                            { name: 'lineDelete', text: '', func: onDeleteRow, icon: 'far-times', type: 'danger' }
                        ]
                    },
                    css: {
                        altColor: true,
                        textWrapping: false
                    },
                    onDataSourceChanges: function (e) { },
                    serverSideHandling: false
                },
                kendo: {
                    height: null,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                },
                optionfunc: function (data) { },
                gridfunc: null
            };

            vm.onDataChanged = function (value, modelId, other) {
                var parts = modelId.split('.');

                var id = parts[parts.length - 1].toLowerCase();

                switch (id) {
                    case 'selectedgrid':
                        vm.selectedGrid = other;
                        vm.buttonaction.grid_name = other.name;

                        break;

                    case 'selecteddatatask':
                        vm.selectedDatatask = value;
                        vm.buttonaction.p2_datatask_keyno = value.p2_datatask_keyno;
                        break;
                    default:
                        vm.buttonaction[parts[2]] = value;
                        break;
                }
            };

            vm.$onInit = function () {
                if (angular.isDefined(vm.resolve.parameters)) {
                    vm.instanceId = vm.resolve.parameters.instance_id;

                    angular.copy(vm.resolve.parameters.dataItem, vm.buttonaction);

                    if (angular.isString(vm.buttonaction.tt_buttonactiontype_keyno)) {
                        vm.buttonaction.tt_buttonactiontype_keyno = parseInt(vm.buttonaction.tt_buttonactiontype_keyno);
                    }

                    vm.tt_dynamicproperty_keyno = vm.resolve.parameters.tt_dynamicproperty_keyno;

                    if (angular.isString(vm.buttonaction.grid_name) && vm.buttonaction.grid_name !== null && vm.buttonaction.grid_name.length > 0) {
                        let gridInfo = ttDynamicGridManager.getGrid(vm.instanceId, vm.buttonaction.grid_name);

                        if (gridInfo !== null) {
                            vm.selectedGrid = gridInfo.grid.dynamic.tt_dynamic_keyno;
                        }
                    }
                }

                ttButtonactionEditService.init(vm.buttonaction.tt_dynamicproperty_keyno).then(function (response) {
                    angular.copy(response.buttonactiontypes, vm.buttonactiontypes);
                    angular.copy(response.gridfuncs, vm.gridfuncs);
                });
            };

            vm.$onDestroy = function () {
                if (angular.isFunction(vm.resolve.parameters.response)) {
                    vm.resolve.parameters.response(vm.buttonaction);
                }
            };

            function onAddRow(dataItem) {
                let deferred = $q.defer();

                $uibModal.open({
                    component: 'ttSelectDatasource',
                    resolve: {
                        parameters: {
                            response: function (response) {
                                if (angular.isUndefined(response) ||
                                    angular.isString(response.datasource_id) !== true ||
                                    response.datasource_id.length < 1) deferred.reject();

                                dataItem.datasource_id = response.datasource_id;

                                deferred.resolve();
                            },
                            instance_id: vm.instanceId,
                            dataItem: dataItem,
                            exclude: {
                                value: vm.buttonaction.parms_base64,
                                isEncoded: true
                            }
                        }
                    },
                    animation: true,
                    backdrop: true
                });

                return deferred.promise;
            };

            function onAddedRow() {
                let rows = vm.dtGridOptions.gridfunc.getAllRows();

                let toJson = [];

                angular.forEach(rows, function (r) {
                    toJson.push({"datasource_id": r.datasource_id});
                });

                vm.buttonaction.parms_base64 = base64.urlencode(angular.toJson(toJson));     
            };

            function onDeleteRow(dataItem) {
                let data = angular.fromJson(base64.urldecode(vm.buttonaction.parms_base64))

                data = data.filter(d => d.datasource_id !== dataItem.datasource_id);

                vm.buttonaction.parms_base64 = base64.urlencode(angular.toJson(data));

                vm.dtGridOptions.gridfunc.read();
            };      
        }]
    });
})();