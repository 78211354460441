(function () {
    'use strict';

    var module = angular.module('imApp');

    module.component('priceListBusCos', {
        templateUrl: 'views/components/views/priceListBusCos/priceListBusCos.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['$stateParams', 'priceListBusCosService', function ($stateParams, priceListBusCosService) {
            var vm = this;
            
            vm.model = {
                p2PriceListBusCoList: {
                    records: []
                }
            };
            
            var loadP2PriceListBusCoList = function () {
                priceListBusCosService.genericFunction(666, {
                    pricelist_id: $stateParams.pricelist_id
                }).then(function (data) {
                    angular.copy(data, vm.model.p2PriceListBusCoList.records);
                });
            };
            
        	loadP2PriceListBusCoList();
        }]
    });
})();