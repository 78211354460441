(function () {
    'use strict';

    let module = angular.module('imApp');

    module.component('activityManager', {
        templateUrl: 'views/components/views/activityManager/activityManager.template.html?v=' + module.version,
        controllerAs: 'vm',
        controller: ['ttGridFactory', function (ttGridFactory) {
            const vm = this;

            //vm.grid = new ttGridFactory({
            //    dataTask: {
            //        rememberId: 'activity.manager',
            //        loadData: {
            //            method: 2867,
            //            parameters: {}
            //        }
            //    },
            //    config: {
            //        toolbar: {
            //            //toggles: [
            //            //    {
            //            //        name: 'test',
            //            //        text: 'test',
            //            //        state: 0,
            //            //        states: [
            //            //            { text: 'test0', func: function () { testToggle(0) }, icon: 'fal fa-ballot-check' },
            //            //            { text: 'test1', func: function () { testToggle(1) }, icon: 'fal fa-ballot' },
            //            //            { text: 'test2', func: function () { testToggle(2) }, icon: 'fal fa-ballot-check' },
            //            //            { text: 'test3', func: function () { testToggle(3) }, icon: 'fal fa-ballot' },
            //            //        ],
            //            //        icon: 'fal fa-ballot-check',
            //            //        disabled: function () { return false; }
            //            //    }
            //            //]
            //        }
            //    },
            //});

            vm.inputMulti = {
                inputs: [
                    { type: 'input', model: 'test', classSizes: 'col-xs-6 col-md-3' },
                    { type: 'input', model: 'test2' }
                ],
                buttons: [
                    {
                        id: 'sendemail',
                        icon: 'fas fa-envelope',
                        type: 'primary',
                        onClick: () => console.log('clicked')
                    },
                    {
                        id: 'refresh',
                        icon: 'fas fa-sync',
                        type: 'primary',
                        onClick: () => console.log('clicked')
                    },
                    {
                        id: 'gotodoc',
                        icon: 'fas fa-search',
                        type: 'primary',
                        onClick: () => console.log('clicked')
                    },
                    {
                        id: 'gotodocarc',
                        icon: 'fas fa-chevron-right',
                        type: 'primary',
                        onClick: () => console.log('clicked')
                    }
                ]
            }

            vm.model = { value: '' };
            vm.model.date = {};

            vm.onChange = function (event) {
                vm.model.value = event;
            }

            vm.myBoolean = false;
            vm.toggleMyBoolean = function () {
                vm.myBoolean = !vm.myBoolean;
            }

            vm.grid = new ttGridFactory({
                rememberId: 'activity.manager.grid',
                loadData: {
                    method: 2867,
                    parameters: {}
                },
                kendo: {
                    height: null,
                    sortable: true,
                    aggregate: true,
                    pager: false,
                    selectable: false,
                    filterable: false
                }
            }).setToolbar({
                pdfExport: false,
                excelExport: false,
                layouts: false,
                wrapping: true,
                buttons: [
                    //{ name: 'showProgress', text: 'progress', func: function () { showProgress() }, icon: 'print', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return false; } },
                    //{ name: '§print', text: 'print', func: function () { console.log('printTest'); return 2865; }, icon: 'print', cssClass: 'k-button tt-grid-btn-xs', translate: true, disabled: function () { return false; } },
                    //{ name: '§print2', text: 'print', func: function () { console.log('printTest'); return 2865; }, icon: 'fal fa-print', cssClass: 'btn btn-primary im-grid-btn-xs-r', translate: true, disabled: function () { return false; } }
                ]
                //toggles: [
                //    {
                //        name: 'test',
                //        text: 'test',
                //        state: 0,
                //        states: [
                //            { text: 'test0', func: function () { testToggle(0) }, icon: 'fal fa-ballot-check' },
                //            { text: 'test1', func: function () { testToggle(1) }, icon: 'fal fa-ballot' },
                //            { text: 'test2', func: function () { testToggle(2) }, icon: 'fal fa-ballot-check' },
                //            { text: 'test3', func: function () { testToggle(3) }, icon: 'fal fa-ballot' },
                //        ],
                //        icon: 'fal fa-ballot-check',
                //        disabled: function () { return false; }
                //    }
                //]
            }).setKendoConfig({
                height: '100%'
            });
            
            //const testToggle = function (index) {
            //    console.log('Test run successfully - ', index);

            //    //(dirty, filtered, sorted, viewOnly);
            //    switch (index) {
            //        case 0:
            //            console.dir(vm.grid.gridfunc.getRows());
            //            break;
            //        case 1:
            //            console.dir(vm.grid.gridfunc.getRows(true, false, true));
            //            break;
            //        case 2:
            //            console.dir(vm.grid.gridfunc.getRows(false, false, false, true));
            //            break;
            //        case 3:
            //            console.dir(vm.grid.gridfunc.getRows(false, true));
            //            break;
            //    }
            //};

            //const showProgress = function () {
            //    console.log('Prog run successfully');

            //    vm.grid.gridfunc.saveChanges();
            //};
        }]
    });
})();
