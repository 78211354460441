(function () {
    'use strict';

    var module = angular.module("imApp");

    module.factory("oeeStateLogService", ['$q', '$ihttp', '$stateParams', function ($q, $ihttp, $stateParams) {
        var service = {
            loaded: false,
            p2_w_oeestatelog_settings: {},
            p2_equipment_get: {
                equipment_id: '',
                equipment_name: '',
                equipment_not_in_use: '',
                businessco_no: '',
                newrecord: ''
            },
            p2_oeestatelog_list: {
                item_id: '',
                item_name: '',
                item_state: '',
                item_name_sub1: '',
                item_parms: '',
                orderby: '',
                item_glyphicon: '',
                records: []
            },
            loadP2WOeeStateLogSettings: function () {
                var deferred = $q.defer();

                var parms_p2_w_oeestatelog_settings = {
                    method: 325
                };

                $ihttp.post(parms_p2_w_oeestatelog_settings).then(function (data) {
                    service.p2_w_oeestatelog_settings = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2EquipmentGet: function (equipment_id) {
                var deferred = $q.defer();

                var parms_p2_equipment_get = {
                    method: 239,
                    parameters: {
                        equipment_id: equipment_id
                    }
                };

                $ihttp.post(parms_p2_equipment_get).then(function (data) {
                    service.p2_equipment_get = angular.copy(data[0]);
                    service.loaded = true;
                    deferred.resolve();
                });

                return deferred.promise;
            },
            loadP2OeeStateLogList: function (equipment_id, mainselector, selector) {
                var deferred = $q.defer();

                var parms_p2_oeestatelog_list = {
                    method: 257,
                    parameters: {
                        equipment_id: equipment_id,
                        mainselector: mainselector,
                        selector: selector
                    }
                };

                $ihttp.post(parms_p2_oeestatelog_list).then(function (data) {
                    service.p2_oeestatelog_list.records.length = 0;

                    for (var i = 0; i < data.length; i++) {
                        service.p2_oeestatelog_list.records.push(data[i]);
                    }

                    service.loaded = true;

                    deferred.resolve();
                });

                return deferred.promise;
            },
            saveP2OeeApprove: function () {
                var deferred = $q.defer();

                var parms_p2_oeedata_approve = {
                    method: 258,
                    parameters: {
                        oeedata_keyno: '0',
                        equipment_id: $stateParams.equipment_id
                    }
                };

                $ihttp.post(parms_p2_oeedata_approve).then(function () {
                    deferred.resolve();
                });

                return deferred.promise;
            }
        };

        return service;
    }]);
})();